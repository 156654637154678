import React, { useEffect, useState } from 'react';
import staffImage from '../../../../../assets/img/icons/staff.fw.png';
import { useStaff } from '../../../../../hooks/shared/staff/useStaff';

const TotalStaff = () => {
  const { total } = useStaff();

  return (
    <div className="col-lg-12">
      <div className="boxes">
        <div className="text-center mt-4">
          <img src={staffImage} alt="" />
        </div>

        <div className="count">{total}</div>

        <h3 style={{ color: '#f37705' }}>Total Staff</h3>
      </div>
    </div>
  );
};

export default TotalStaff;
