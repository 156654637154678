/* eslint-disable react/prop-types */
import React from 'react';
import caution from '../../../../assets/img/icons/Caution.png';

const tabIndex = -1;
const BulkUninstall = ({ handleBulkUninstall }) => {
  const bulkUninstallCustomers = (e) => {
    e.preventDefault();
    handleBulkUninstall();
  };

  return (
    <div
      className="modal fade"
      id="bulk_uninstall"
      tabIndex={tabIndex}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content mt-5 py-4">
          <div className="text-center">
            <img src={caution} alt="" width="60px" />
          </div>
          <div className="modal-body p-3">
            <div className="row">
              <div className="col">
                <form className="form-horizontal text-center" onSubmit={bulkUninstallCustomers}>
                  <div className="col">
                    <p>
                      <h5>Are you sure you want to Uninstall selected customers?</h5>
                    </p>
                  </div>
                  <div className="mt-5">
                    <button type="button" className="btn btn-secondary mr-2" data-dismiss="modal">
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-green" onClick={bulkUninstallCustomers}>
                      Confirm
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkUninstall;
