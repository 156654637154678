import React from 'react';
import { Link } from 'react-router-dom';
import Search from './Search';
import PATHS from '../../../../../routes/urls';
import PercentageCompleted from './table/PercentageCompleted';
import InstallationsOverviewTable from './table/InstallationsOverviewTable';
import { OPERATIONS, SUPER_ADMIN, TECHNICAL_SUPPORT } from '../../../../../utils/departments/helpers';

const InstallationsOverviewBody = () => {
  const departmentId = sessionStorage.getItem('departmentId');

  return (
    <div className="col-12 after-summary">
      <Search />
      <div className="ml-2 d-flex">
        {(departmentId === `${OPERATIONS}` ||
          departmentId === `${TECHNICAL_SUPPORT}` ||
          departmentId === `${SUPER_ADMIN}`) && (
          <Link to={PATHS.FSE_CLUSTERED_INSTALLATIONS} target="_self">
            <button type="button" className="btn btn-info">
              Clustered Installations
            </button>
          </Link>
        )}
        <PercentageCompleted />
      </div>
      <InstallationsOverviewTable />
    </div>
  );
};

export default InstallationsOverviewBody;
