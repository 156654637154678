/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { API_URL } from '../../../../../utils/config';
import Escalate from '../../../shared/escalate/Escalate';
import Logout from '../../../shared/auth/Logout';
import mailblast from '../../../../../assets/img/icons/mailblast.svg';
import CustomerSupportSideBar from '../../../../layouts/customer-support/CustomerSupportSideBar';

export default class CustomerSupportEscalate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      role: '',
    };
  }

  componentDidMount() {
    this.getUserDetails();
  }

  getUserDetails() {
    axios({
      method: 'get',
      url: `${API_URL}/employee/loggedOnEmployee`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    }).then((res) => {
      if (res.data) {
        this.setState({
          firstName: res.data.firstName,
        });
      }
    });
  }

  render() {
    const { firstName, role } = this.state;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <Link className="s-sidebar__trigger" to="#">
                <i className="fa fa-bars mr-3" />
              </Link>
              <CustomerSupportSideBar />

              <h3 className="col-lg-6 overview-part">Escalate</h3>

              <div className="d-flex profile-mail">
                <Link
                  to="/sales/mail-blast"
                  className=" d-none d-md-flex"
                  style={{ color: 'gray', textDecoration: 'none' }}
                >
                  <img src={mailblast} alt="" className="logout mr-2" />

                  <h6 className="mt-2 mr-3">
                    <strong>MailBlast</strong>
                  </h6>
                </Link>
                <span className="mt-1">
                  <Logout />
                </span>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row mt-5">
              <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-4">
                <Escalate dept={firstName} />
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
