/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from 'react';
import ProfileContext from '../../../../../context/shared/lte/profile/ProfileContext';

const CreateProfile = () => {
  const value = useContext(ProfileContext);
  const { profile, onChange, onSubmit, creating } = value;
  const { title, k, am, opc, downlink, uplink, apn, qci, arp, description, direction, addr, addr6 } = profile;

  const submitForm = () =>
    title.length > 0 &&
    k.length > 0 &&
    am.length > 0 &&
    opc.length > 0 &&
    downlink.length > 0 &&
    uplink.length > 0 &&
    apn.length > 0 &&
    qci.length > 0 &&
    arp.length > 0;

  return (
    <div
      id="lteModal"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myModalLabel">
              Create Customer LTE Account
            </h4>
            <button type="button" className="close custom-close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body detail" style={{ padding: '0', textAlign: 'justify' }}>
            <div className="row">
              <form
                className="form-horizontal col-12"
                onSubmit={(e) => {
                  e.preventDefault();
                  onSubmit(profile);
                }}
              >
                <div className="form-group mt-4">
                  <div className="col-12 d-flex mb-3">
                    <div className="col-6">
                      <label className="copy-font m-0 p-0 text-left">Title</label>
                      <input
                        type="text"
                        placeholder="Title"
                        className="form-control pl-2"
                        name="title"
                        onChange={onChange}
                        value={title}
                      />
                    </div>
                    <div className="col-6">
                      <label className="copy-font m-0 p-0 text-left">K</label>
                      <input
                        type="text"
                        placeholder="00112233445566778899AABBCCDDEEFF"
                        className="form-control pl-2"
                        name="k"
                        onChange={onChange}
                        value={k}
                      />
                    </div>
                  </div>

                  <div className="col-12 d-flex mb-3">
                    <div className="col-6">
                      <label className="copy-font m-0 p-0 text-left">AM</label>
                      <input
                        type="text"
                        placeholder="Title"
                        className="form-control pl-2"
                        name="am"
                        onChange={onChange}
                        value={am}
                      />
                    </div>
                    <div className="col-6">
                      <label className="copy-font m-0 p-0 text-left">OPC</label>
                      <input
                        type="text"
                        placeholder="000102030405060708090A0B0C0D0E0F"
                        className="form-control pl-2"
                        name="opc"
                        onChange={onChange}
                        value={opc}
                      />
                    </div>
                  </div>

                  <div className="col-12 d-flex mb-3">
                    <div className="col-6">
                      <label className="copy-font m-0 p-0 text-left">Downlink</label>
                      <input
                        type="text"
                        placeholder="Title"
                        className="form-control pl-2"
                        name="downlink"
                        onChange={onChange}
                        value={downlink}
                      />
                    </div>
                    <div className="col-6">
                      <label className="copy-font m-0 p-0 text-left">Uplink</label>
                      <input
                        type="text"
                        placeholder="Uplink"
                        className="form-control pl-2"
                        name="uplink"
                        onChange={onChange}
                        value={uplink}
                      />
                    </div>
                  </div>

                  <div className="col-12 d-flex mb-3">
                    <div className="col-6">
                      <label className="copy-font m-0 p-0 text-left">Apn</label>
                      <input
                        type="text"
                        placeholder="Title"
                        className="form-control pl-2"
                        name="apn"
                        onChange={onChange}
                        value={apn}
                      />
                    </div>
                    <div className="col-6">
                      <label className="copy-font m-0 p-0 text-left">QCI</label>
                      <input
                        type="text"
                        placeholder="K"
                        className="form-control pl-2"
                        name="qci"
                        onChange={onChange}
                        value={qci}
                      />
                    </div>
                  </div>

                  <div className="col-12 d-flex mb-3">
                    <div className="col-6">
                      <label className="copy-font m-0 p-0 text-left">Arp</label>
                      <input
                        type="text"
                        placeholder="Title"
                        className="form-control pl-2"
                        name="arp"
                        onChange={onChange}
                        value={arp}
                      />
                    </div>
                    <div className="col-6">
                      <label className="copy-font m-0 p-0 text-left">Description</label>
                      <input
                        type="text"
                        placeholder="description"
                        className="form-control pl-2"
                        name="description"
                        onChange={onChange}
                        value={description}
                      />
                    </div>
                  </div>

                  <div className="col-12 d-flex mb-3">
                    <div className="col-6">
                      <label className="copy-font m-0 p-0 text-left">Direction</label>
                      <input
                        type="text"
                        placeholder="Title"
                        className="form-control pl-2"
                        name="direction"
                        onChange={onChange}
                        value={direction}
                      />
                    </div>
                    <div className="col-6">
                      <label className="copy-font m-0 p-0 text-left">Addr</label>
                      <input
                        type="text"
                        placeholder="addr"
                        className="form-control pl-2"
                        name="addr"
                        onChange={onChange}
                        value={addr}
                      />
                    </div>
                  </div>

                  <div className="col-12 d-flex mb-3">
                    <div className="col-6">
                      <label className="copy-font m-0 p-0 text-left">Addr6</label>
                      <input
                        type="text"
                        placeholder="Title"
                        className="form-control pl-2"
                        name="addr6"
                        onChange={onChange}
                        value={addr6}
                      />
                    </div>
                  </div>

                  <div className="text-center">
                    {creating ? (
                      <button className="btn btn-green" type="submit" disabled={creating}>
                        Creating...
                      </button>
                    ) : (
                      <button className="btn btn-green" type="submit" disabled={!submitForm()}>
                        Create
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateProfile;
