import React from 'react';
import FieldSupportNavBar from '../../../../layouts/field-support/FieldSupportNavBar';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import { OverviewProvider } from '../../../../../context/shared/installations/overview/OverviewContext';
import ClusteredInstallationsBody from '../../sales/clustered-installs/ClusteredInstallationsBody';

const FieldSupportClusteredInstalls = () => {
  const { profile } = useUser();

  return (
    <div className="s-layout">
      <OverviewProvider>
        <main className="s-layout__content col-lg-10 p-0">
          <FieldSupportNavBar currentPage="Clustered Installations" position={profile.firstName} />
          <ClusteredInstallationsBody />
        </main>
      </OverviewProvider>
    </div>
  );
};

export default FieldSupportClusteredInstalls;
