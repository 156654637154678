/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { useCallLog } from '../../../../../hooks/customer-support/call-logs/useCallLog';
import CallLogsTable from './CallLogsTable';
import useInstallationZones from '../../../shared/installations/zones/hooks/useInstallationZones';

const CustomerSupportCallLogsBody = () => {
  const { list, items, pages, load, count, getCallLog, searchUsingKeyword } = useCallLog();

  const [keyword, setKeyword] = useState('');
  const [option, setOption] = useState('');
  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);

  const [page] = useState(1);
  const { loading, zones } = useInstallationZones();
  const [reloadPage, setReloadPage] = useState(page);

  const searchFirstName = { firstName: keyword.trim() };
  const searchLastName = { lastName: keyword.trim() };
  const searchPhone = { phone: keyword.trim() };

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    setReloadPage(selectedPage);

    if (option === 'firstName') return searchUsingKeyword(selectedPage, searchFirstName);
    if (option === 'lastName') return searchUsingKeyword(selectedPage, searchLastName);
    if (option === 'phone') return searchUsingKeyword(selectedPage, searchPhone);

    return getCallLog(selectedPage);
  };

  const filterUsingKeyword = (e) => {
    e.preventDefault();
    switch (option) {
      case 'firstName':
        return searchUsingKeyword(page, searchFirstName);
      case 'lastName':
        return searchUsingKeyword(page, searchLastName);
      case 'phone':
        return searchUsingKeyword(page, searchPhone);

      default:
        return null;
    }
  };

  useEffect(() => {
    getCallLog(page);
  }, []);

  return (
    <div className="col-12 p-0 after-summary">
      <div className="row mx-2">
        <div className="mb-1">
          <div className="smallTop d-flex mt-4">
            <form onSubmit={filterUsingKeyword} className="form-inline ml-3">
              <div className="form-group">
                <select name="chooseField" onChange={(e) => setOption(e.target.value)} className="form-control">
                  <option value="">--Choose Search Field--</option>
                  <option value="firstName">FirstName</option>
                  <option value="lastName">LastName</option>
                  <option value="phone">Phone</option>
                </select>
              </div>

              <div className="form-group ml-2">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Input Search Term"
                  style={{ width: '230px' }}
                  name="keyword"
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                />
              </div>

              <div className="form-group ml-2">
                <button className="btn btn-green" type="submit">
                  Search
                </button>
              </div>
            </form>

          
          </div>
        </div>

        <div className="mt-2 mb-3 col-12">
          {list.length ? (
            <div className="col-12 baby-fonts">
              Number of Requests: <span className="badge badge-dark payslipfont">{count}</span>
            </div>
          ) : null}
        </div>

        <div className="card x-panel p-0">
          <CallLogsTable
            lists={list}
            load={load}
            getCallLog={getCallLog}
            page={reloadPage}
            setEditModal={setEditModal}
            editModal={editModal}
            loading={loading}
              zones={zones}
          />

          <div className="px-3">
            {pages > 1 ? (
              <ReactPaginate
                previousLabel="previous"
                nextLabel="next"
                breakLabel="..."
                breakClassName="break-me"
                pageCount={pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName="pagination"
                // subContainerClassName="pages pagination"
                activeClassName="active"
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerSupportCallLogsBody;
