/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { searchForRouter } from '../../../../../actions/procurement/ProcurementActions';
import RoutersTableBody from './RoutersTableBody';

// eslint-disable-next-line no-shadow
const RoutersTable = ({ searchForRouter, router }) => {
  const [query, setQuery] = useState('');

  const onChange = (e) => {
    setQuery(e.target.value);
  };
  const searchRouter = (e) => {
    if (e.key === 'Enter') {
      const data = {
        router_tag: query,
      };

      searchForRouter(data);
    }
  };

  return (
    // <div className="page-wrapper col-lg-10 col-md-10">
    <div className="container">
      <div className="row mt-5">
        <div className="col-lg-12 col-md-12 mt-4 mb-3 d-flex">
          <form className="form-inline inner-addon left-addon">
            <input
              value={query}
              onChange={onChange}
              onKeyDown={searchRouter}
              className="form-control mr-sm-2 empty mt-1"
              id="iconified"
              type="text"
              placeholder="Search"
            />
            <span className="fa fa-search" />
          </form>
        </div>

        <div className="card x-panel">
          <div className="table-responsive content col-lg-12 col-md-12 col-sm-12">
            <table className="table col-lg-12 col-md-12 col-sm-12">
              <thead>
                <tr>
                  <th> Name</th>
                  <th>Address</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Mac ID</th>
                  <th>Radio Tag</th>
                  <th>Router</th>
                  <th>Router Tag</th>
                </tr>
              </thead>

              <RoutersTableBody router={router} />
            </table>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

const mapStateToProps = (state) => ({
  router: state.routers.router,
});

RoutersTable.propTypes = {
  searchForRouter: PropTypes.func,
  router: PropTypes.shape,
};
export default connect(mapStateToProps, {
  searchForRouter,
})(RoutersTable);
