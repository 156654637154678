import * as load from '../../../constants/types/shared/LoadingTypes';

// eslint-disable-next-line import/prefer-default-export
export const payslipReducer = (state, action) => {
  const { type } = action;
  switch (type) {
    case 'GETTING_EMPLOYEE_PAYSLIPS':
      return { ...state, gettingPayslip: true };
    case 'GET_EMPLOYEE_PAYSLIPS':
      return { ...state, payslips: action.payload, gettingPayslip: false };
    case load.LOADING_STARTS:
      return {
        ...state,
        creating: true,
      };

    case load.LOADING_STOPS:
      return {
        ...state,
        creating: false,
      };

    default:
      return state;
  }
};
