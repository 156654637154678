import { useState, useReducer, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import * as url from '../../../services/api/shared/requests/RequestsUrl';
import { requestsReducer } from '../../../reducers/administration/requests/requestsReducer';
import * as types from '../../../constants/types/administration/RequestTypes';
import * as load from '../../../constants/types/shared/LoadingTypes';
import {
  FILTER_REQUESTS_URL,
  GET_REQUESTS_URL,
  GET_STAFF_IN_ADMIN_DEPARTMENT_URL,
  UPDATE_REQUEST_URL,
} from '../../../services/api/administration/requests/RequestsUrl';
import { ADMIN_DEPARTMENT } from '../../../utils/departments/helpers';

// eslint-disable-next-line import/prefer-default-export
export const useAdminRequests = () => {
  const requestState = {
    category: '',
    description: '',
    code: '',
  };

  const updateState = {
    staffId: '',
    status: '',
    reason: '',
  };

  const [request, setRequest] = useState(requestState);
  const [option, setOption] = useState('');
  const [users, setUsers] = useState([]);
  const [update, setUpdate] = useState(updateState);

  const [show, setShow] = useState(false);

  const [{ isLoadingRequest, isLoading, requests, isUpdatingStatus }, dispatch] = useReducer(requestsReducer, {
    isLoadingRequest: false,
    isLoading: false,
    requests: [],
    isUpdatingStatus: false,
  });

  const onUpdateChange = (e) => {
    const { name, value } = e.target;
    setUpdate({
      ...update,
      [name]: value,
    });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setRequest({
      ...request,
      [name]: value,
    });
  };

  const onFilterChange = (e) => {
    const { value } = e.target;
    setOption(value);
  };

  const getToShow = () => {
    setShow(!show);
  };

  const filterStatus = async (e) => {
    dispatch({ type: load.LOADING_STARTS });

    e.preventDefault();
    const data = {
      filterBy: 'status',
      value: option,
    };
    try {
      const response = await axios.post(`${FILTER_REQUESTS_URL}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_REQUESTS,
          payload: response.data,
        });
      }
    } catch (error) {
      if (error) {
        dispatch({ type: load.LOADING_STOPS });
      }
    }
  };

  const makeRequest = async (data) => {
    dispatch({
      type: types.START_CREATE_REQUEST,
    });
    try {
      const response = await axios.post(`${url.CREATE_REQUEST_URL}`, data, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
      });

      if (response.data.id) {
        toast.success('Request has been logged!');
        setRequest(requestState);
        dispatch({ type: types.CREATE_REQUEST, payload: response.data });
      } else {
        toast.info('Unable to log request logged!');

        dispatch({
          type: types.STOP_CREATE_REQUEST,
        });
      }
    } catch (e) {
      if (e) {
        dispatch({
          type: types.STOP_CREATE_REQUEST,
        });
      }
    }
  };

  const getRequests = async () => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.get(`${GET_REQUESTS_URL}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
      });
      if (response.data) {
        dispatch({
          type: types.GET_REQUESTS,
          payload: response.data,
        });
      }
    } catch (error) {
      if (error) {
        dispatch({
          type: load.LOADING_STOPS,
        });
      }
    }
  };

  const getStaffInAdminDepartment = async () => {
    try {
      const response = await axios.get(`${GET_STAFF_IN_ADMIN_DEPARTMENT_URL}/${ADMIN_DEPARTMENT}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
      });
      if (response.data) {
        setUsers(response.data);
      }
    } catch (e) {
      if (e) {
        toast.info('Unable to get employees in this department');
      }
    }
  };

  const updateStatus = async (data, id, display) => {
    console.log(display);
    dispatch({ type: types.START_UPDATING_STATUS });
    try {
      const response = await axios.post(`${UPDATE_REQUEST_URL}/${id}`, data, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
      });

      if (response.data) {
        toast.success(`Status updated!`);
        dispatch({ type: types.STATUS_UPDATED, payload: id });
        setUpdate(updateState);
        setShow(false);
      } else {
        toast.info(`Unable to update status`);
        dispatch({ type: types.STOP_UPDATING_STATUS });
      }
    } catch (error) {
      console.log(error.response);
      if (error) {
        toast.info(`Something went wrong!`);
        dispatch({ type: types.STOP_UPDATING_STATUS });
      }
    }
  };

  useEffect(() => {
    getRequests();
    getStaffInAdminDepartment();
  }, []);

  return {
    makeRequest,
    onChange,
    request,
    isLoadingRequest,
    isLoading,
    requests,
    onFilterChange,
    filterStatus,
    users,
    onUpdateChange,
    updateStatus,
    update,
    isUpdatingStatus,
    show,
    getToShow,
  };
};
