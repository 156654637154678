/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useReleaseReport } from '../../../../hooks/procurement/release-report/useReleaseReport';
import { useStaff } from '../../../../hooks/shared/staff/useStaff';
import { useDepartments } from '../../../../hooks/shared/departments/useDepartments';
import useTracklist from '../tracklist/hooks/useTracklist';
import useFetch from '../../../../hooks/shared/custom/data/useFetch';
import { API_URL } from '../../../../utils/config';
import CustomMultiSelect from '../forms/CustomMultiSelect';
import CustomButton from '../forms/CustomButton';

const AddReleaseItem = ({ log }) => {
  const { data: optionsArray, loading } = useFetch(`${API_URL}/tracklistcategory`);
  const { releaseItem, handleChange, addReleaseItem, selectedOption, handleSelect, addingReport } = useReleaseReport();

  const { list } = useTracklist();
  const { staff } = useStaff();
  const { departments } = useDepartments();
  const [options, setOptions] = useState([]);

  const label = selectedOption.tracklist.label;


  const { requestedDepartment, staffReleasedTo, staffApproved, tracklist } = selectedOption;

  const [departmentsArr] = useState([]);
  const [tracklistArr] = useState([]);
  const [staffArr] = useState([]);

  useEffect(() => {
    staff.map((person) =>
      staffArr.push({ value: person.employee_auth_id, label: `${person.firstName} ${person.lastName}` }),
    );
  }, [staff, staffArr]);

  useEffect(() => {
    departments.map((department) => departmentsArr.push({ value: department.id, label: department.name }));
  }, [departments, departmentsArr]);

  useEffect(() => {
    list.map((OneItem) =>
      tracklistArr.push({
        value: OneItem.id,
        label: OneItem.item,
        classID: OneItem.resource_classification_id,
        remainder: OneItem.available_quantity,
      }),
    );
  }, [list, tracklistArr]);

  useEffect(() => {
    if (optionsArray && optionsArray.length) {
      setOptions(
        optionsArray.map((el) => ({
          value: el.id,
          label: el.name,
          remainder: el.available_quantity,
        })),
      );
    }
  }, [optionsArray]);

  const onSubmit = (e) => {
    e.preventDefault();
    const { quantity, destination, tag } = releaseItem;
    const data = {
      subtotal: '??', // whats this
      item: tracklist.label,
      request_by_id: requestedDepartment.value,
      resource_classification_id: tracklist.value,
      released_to_id: staffReleasedTo.value,
      quantity,
      destination,
      tag,
      approved_by_id: staffApproved.value,

      tracklist_category_id: tracklist.value,
    };
    addReleaseItem(data);
  };
  const showOptions = () => {
    return loading ? (
      <p>Fetching Items </p>
    ) : (
      <CustomMultiSelect
        value={tracklist}
        handleChange={handleSelect}
        name="tracklist"
        options={options}
        classname=""
        isMulti={false}
      />
    );
  };
  return (
    <div id="add_release" className="modal fade mt-5" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header shadow">
            <h4 className="modal-title update_asset">Release Item</h4>
            <button type="button" className="btn btn-outline-info btn-circle btn-close" data-dismiss="modal">
              x
            </button>
          </div>
          <div className="modal-body detail mb-4 p-0">
            <form onSubmit={onSubmit} className="col-md-12 p-0">
              <div className="d-flex">
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font">Item Name</label>
                  {showOptions()}
                </div>
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font">Requested By</label>
                  <Select
                    value={requestedDepartment}
                    onChange={handleSelect}
                    name="requestedDepartment"
                    options={departmentsArr}
                  />
                </div>
              </div>

              <div className="d-flex">
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font">Released To</label>
                  <Select value={staffReleasedTo} onChange={handleSelect} name="staffReleasedTo" options={staffArr} />
                </div>
              </div>

              <div className="d-flex">
                <div className="col-6 m-0 p-0 d-flex">
                  <div className="form-group pr-1 col-6">
                    <label className="m-0 p-0 copy-font" htmlFor="quantity">
                      Unit(s)
                    </label>
                    <input
                      type="number"
                      className="form-control border border-info p-2"
                      id="quantity"
                      name="quantity"
                      placeholder="Unit(s)"
                      onChange={handleChange}
                    />
                  </div>
                  {/* <div className="form-group pl-1 col-6">
                    <label className="m-0 p-0 copy-font" htmlFor="remainder">
                      Remainder
                    </label>
                    <input
                      type="number"
                      className="form-control border border-info p-2"
                      id="remainder"
                      placeholder="Remainder"
                      value={remainder}
                      // value={tracklist.remainder}
                      disabled
                    />
                  </div> */}
                </div>
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="tag_no">
                    Tag No.
                  </label>
                  <input
                    type="text"
                    className="form-control border border-info p-2"
                    placeholder="Tag No."
                    id="tag_no"
                    name="tag"
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="d-flex">
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font">Approved By</label>
                  <Select value={staffApproved} onChange={handleSelect} name="staffApproved" options={staffArr} />
                </div>
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="destination">
                    Destination
                  </label>
                  <input
                    type="text"
                    className="form-control border border-info p-2"
                    placeholder="Destination"
                    id="destination"
                    name="destination"
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="text-center mt-3">
                <CustomButton
                  classname="btn btn-green px-3"
                  name={addingReport ? 'Submitting' : 'Add Item'}
                  disabled={addingReport}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddReleaseItem;
