/* eslint-disable react/require-default-props */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import PropTypes from 'prop-types';
import logo from '../../../../../assets/img/logo/Tizeti-Logo.png';
import { forgotPassword } from '../../../../../actions/auth/AuthActions';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  onSubmit(e) {
    e.preventDefault();
    const { history } = this.props;
    const { email } = this.state;

    const forgot = {
      email,
    };

    // eslint-disable-next-line react/destructuring-assignment
    this.props.forgotPassword(forgot, history);
  }

  render() {
    const { email } = this.state;
    const { loading } = this.props;

    return (
      <div className="main-wrapper">
        <div className="col-lg-12 carrier d-flex">
          <div className="col-lg-6 col-md-6 explain d-none d-sm-none d-md-block d-lg-block">
            <div className="intro">
              <h3 style={{ color: 'white' }}>Welcome To Tizeti OS </h3>
              <p className="copy-font">
                We built Tizeti OS with the aim of building a better experience when performing your daily tasks. We are
                currently roling out more features that would further enhance your work.
                <br />
                <br />
                If you have any complaints or suggestions as to how this platform will be better, please send us an
                email to rd@tizeti.com
                <br />
                <br />- Research and Development Team
              </p>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="login col-sm-12 col-lg-12 col-md-12">
              <div className="tizeti-logo">
                <img src={logo} alt="" />
              </div>

              <form onSubmit={this.onSubmit} className="form-horizontal login-form col-sm-12 col-lg-12 col-md-12">
                <div className="form-group col-lg-9 col-sm-12">
                  <div className="mb-3">
                    <label className="label2 copy-font">Email Address</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email your Tizeti email address"
                      name="email"
                      id="email"
                      value={email}
                      onChange={this.onChange}
                    />
                  </div>
                  <br />
                  <div className="wrapper-button mb-3">
                    {loading ? (
                      <button
                        className="btn btn-green copy-font"
                        disabled={loading}
                        style={{
                          backgroundColor: '#036D61',
                        }}
                      >
                        Submitting...
                      </button>
                    ) : (
                      <button
                        className="btn btn-green"
                        style={{
                          backgroundColor: '#036D61',
                        }}
                      >
                        RESET
                      </button>
                    )}
                  </div>
                  <div className="already-have">
                    <p className="text-center copy-font">
                      Remember your login details? <Link to="/login">Login</Link>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.auth.forgot,
});

ForgotPassword.propTypes = {
  forgotPassword: PropTypes.func,
  history: PropTypes.shape(),
  loading: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  { forgotPassword },
)(ForgotPassword);
