/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
import React, { Component } from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import toastr from 'toastr';
import { API_URL } from '../../../../../utils/config';
import '../../../../../styles/style.css';
import '../../../../../styles/basestation.css';
import AccountsNavBar from '../../../../layouts/accounts/AccountsNavBar';
import { ACCOUNTS, SUPER_ADMIN } from '../../../../../utils/departments/helpers';

class AccountsBaseStations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      basestations: [],
      filtered: [],
      move: false,
      out: false,
      role: '',
    };

    this.onKeyPress = this.onKeyPress.bind(this);
  }

  componentWillMount() {
    this.fetchBaseStations();

    if (!sessionStorage.getItem('token')) {
      this.setState({
        move: true,
      });
    }

    this.fetchLoggedOnUser();
    this.getUser();
    this.getUserDetails();
  }

  componentWillReceiveProps() {
    this.fetchLoggedOnUser();
  }

  onKeyPress = (e) => {
    const { basestations } = this.state;
    if (e.which === 13) {
      // eslint-disable-next-line array-callback-return
      const base = basestations.filter((basestation) => {
        if (basestation.name.indexOf(e.target.value) !== -1) {
          return basestation;
        }
        if (basestation.address.indexOf(basestation) !== -1) {
          return basestation;
        }
      });

      this.setState({
        filtered: base,
      });
    }
  };

  getUserDetails() {
    axios({
      method: 'get',
      url: `${API_URL}/employee/loggedOnEmployee`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        this.setState({
          user: data.data,
        });
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  }

  getUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employee/loggedOnEmployee`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        this.setState({
          user: data.data,
        });
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  }

  renderMap = () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    loadScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyD1DrDBUd6GNL2EIBCxK-K0OjkTny8kbuA&callback=initMap');
    window.initMap = this.initMap;
  };

  initMap = () => {
    const map = new window.google.maps.Map(document.getElementById('map'), {
      center: { lat: 6.548653, lng: 3.54882 },
      zoom: 11,
      maxZoom: 0, // 18
      minZoom: 0, // 16
      zoomControlOptions: {
        style: window.google.maps.ZoomControlStyle.SMALL,
      },
      scaleControl: true, // enable scale control
      mapTypeId: window.google.maps.MapTypeId.HYBRID, // google map type
    });

    const infowindow = new window.google.maps.InfoWindow();
    const { filtered } = this.state;
    // eslint-disable-next-line array-callback-return
    filtered.map((basestation) => {
      const contentString = `${basestation.name}`;

      const marker = new window.google.maps.Marker({
        position: { lat: basestation.latitude, lng: basestation.longitude },
        map,
        // icon: 'https://res.cloudinary.com/tizeti/image/upload/v1542971012/Group-1.png',
        icon: 'http://www.my9ja.com/estatewifi/icons/pin_green.png',
        title: basestation.name,
        // animation: window.google.maps.Animation.BOUNCE
      });

      const circle = new window.google.maps.Circle({
        map,
        radius: 2000, // 2 kilometeres in metres
        fillColor: '#00FF00',
        // strokeColor: '#FF0000',
        strokeOpacity: 0.2,
        strokeWeight: 0.12,
        fillOpacity: 0.25,
      });

      circle.bindTo('center', marker, 'position');

      // eslint-disable-next-line func-names
      marker.addListener('click', function() {
        // chnage content
        infowindow.setContent(contentString);
        infowindow.open(map, marker);
      });
    });
  };

  fetchLoggedOnUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employeeReg/logged`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        this.setState({
          role: data.data.role_id,
        });
        if (data.data.department_id !== `${ACCOUNTS}` && data.data.department_id !== `${SUPER_ADMIN}`) {
          sessionStorage.setItem('data', '');
          sessionStorage.clear();
          this.props.history.push('/login');
          this.setState({ out: true });
          toastr.info('You are not allowed to view this page');

          // const { from } = this.props.location.state || { from: { pathname: '/' } };
          // console.log(this.props.history.goBack);
          // window.previousLocation = this.props.location;
          // console.log(window.previousLocation);
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  }

  fetchBaseStations() {
    axios({
      method: 'get',
      url: `${API_URL}/baseStation`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    }).then((res) => {
      this.setState(
        {
          basestations: res.data,
          filtered: res.data,
        },
        this.renderMap(),
      );
    });
  }

  render() {
    const { user, out, move, role } = this.state;
    const { firstName } = user;

    if (out) {
      return <Redirect to="/staff/login" />;
    }

    if (move) {
      return <Redirect to="/staff/login" />;
    }

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <AccountsNavBar currentPage="BaseStations" position={firstName} role_id={role} />

          <div className="container">
            <div className="row mt-5">
              <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-4">
                <div id="map" />
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

function loadScript(url) {
  const index = window.document.getElementsByTagName('script')[0];
  const script = window.document.createElement('script');
  script.src = url;
  script.async = true;
  script.defer = true;
  index.parentNode.insertBefore(script, index);
}

export default AccountsBaseStations;
