/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../../../utils/loader/loadingHelper';

const colSpan = '6';
const InstallationsPerDateRangeTable = ({ loading, installs }) => {
  const { lists, totalInstall } = installs;

  const tableData =
    lists.length && lists ? (
      lists.map((list, i) => {
        const { basestationame, zone, region_location, country, installation_count } = list;
        return (
          <tr className="text-nowrap" key={i}>
            <td>{i + 1}</td>
            <td>{basestationame}</td>
            <td>{zone}</td>
            <td>{region_location}</td>
            <td>{country}</td>
            <td>{installation_count}</td>
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan={colSpan} text="No Installation" />
    );

  return (
    <div className="pt-4 col-12">
      Total Installation: {totalInstall}
      <div className="card x-panel mt-2">
        <div className="table-responsive content col-12 px-1">
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Basestation</th>
                <th>Zone</th>
                <th>Location</th>
                <th>Country</th>
                <th>Count</th>
              </tr>
            </thead>
            <tbody>{loading ? <TizetiGIFLogoTable colSpan={colSpan} /> : tableData}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default InstallationsPerDateRangeTable;
