export const ACCOUNTS = '17s4UkNfeYmtgCUHN1oLuZiAma8UMFeHs';
export const ADMIN_DEPARTMENT = '1JGvHLhyuNb29Oyxbloz9ga4a7Q00KmnX';
export const CUSTOMER_SUPPORT = '1x68q2_54otWbkimsiCnU5Lkcqhae4_cL';
export const EXPRESS_WIFI = '1N_otg5SZJBauiGgUtAjmXk8LEjQ1OJti';
export const FIELD_SERVICES = '1L_pMQW6Ak3Ss4f0-RylN_DF5mvcOCQXn';
export const SALES = '1ehvfz45FmTOh2VqMlsDmEk-RSKGgSOWy';
export const NOC = '1vmQdt869d8vNwlh59Aghp_APBhDc_Dab';
export const PROCUREMENT = '1LVYPT61LLggRA0r4IHSF5VcrlzaFT9ky';
export const RESEARCH_AND_DEVELOPMENT = '123jLRt1Q0CuJfYn2zA2qwVaK87BemFTo';
export const WIFICALL = '1Jtp3RCzKi2mPIksAzKMjXFNlMSiK84OF';
