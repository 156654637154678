import React from 'react';
import { kpiPerMonthYears } from '../components/tempData';

function AvailableYears({ selectedYear, onYearChange }) {
  const handleSelectChange = (event) => {
    onYearChange(event.target.value);
  };

  return (
    <div className="container mx-auto mt-8">
      {/* <h2 className="text-1xl mb-4">Select Year</h2> */}
      <div className="relative w-40">
        <select id="dateInput" value={selectedYear} onChange={handleSelectChange}>
          <option value="">Select Year</option>
          {/* Generate options for years, adjust the range as needed */}
          {Array.from({ length: 10 }, (_, index) => {
            const year = new Date().getFullYear() - index;
            return (
              <option key={year} value={year}>
                {year}
              </option>
            );
          })}
        </select>
        <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none"></div>
      </div>
      {selectedYear && <div className="my-4">Selected Year: {selectedYear}</div>}
    </div>
  );
}

export default AvailableYears;
