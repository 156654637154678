/* eslint-disable react/button-has-type */
import React, { useContext } from 'react';
import GeniatechContext from '../../../../../../context/GeniatechContext';
import StaticAccessPointsTable from './StaticAccessPointsTable';
import StaticAccessPointTable from './static-access-point/StaticAccessPointTable';
import AccessPointsPerformanceResults from './access-points-performance-results/AccessPointsPerformanceResults';

const StaticAccessPointBody = () => {
  const { searchStatus, reset, performanceSearchStatus, resetPerformanceSearch } = useContext(GeniatechContext);
  const display = () => {
    if (searchStatus) {
      return <StaticAccessPointTable />;
    }
    if (performanceSearchStatus) {
      return <AccessPointsPerformanceResults />;
    }
    return <StaticAccessPointsTable />;
  };
  return (
    <div className="container">
      <div className="row mt-5">
        <div className="mt-4 col-lg-12 col-md-12 col-sm-12">
          <div className="container">
            <div className="row mt-5">
              {searchStatus && (
                <div className="form-group ml-2">
                  <button onClick={reset} className="btn btn-green">
                    See All Access Points
                  </button>
                </div>
              )}
              {searchStatus && performanceSearchStatus ? (
                <>
                  <div className="form-group ml-2">
                    <button onClick={reset} className="btn btn-green">
                      See All Access Points
                    </button>
                  </div>
                  <div className="form-group ml-2">
                    <button onClick={resetPerformanceSearch} className="btn btn-green">
                      See Access Point For Tower
                    </button>
                  </div>
                </>
              ) : null}
              {performanceSearchStatus && (
                <>
                  <div className="form-group ml-2">
                    <button onClick={reset} className="btn btn-green">
                      See All Access Points
                    </button>
                  </div>
                  <div className="form-group ml-2">
                    <button onClick={resetPerformanceSearch} className="btn btn-green">
                      See Access Point For Tower
                    </button>
                  </div>
                </>
              )}
              {display()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaticAccessPointBody;
