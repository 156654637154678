/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-named-as-default */
import { useEffect, useReducer } from 'react';
import axios from 'axios';
import { LOGGED_IN_USER_PROFILE_URL } from '../../../services/api/shared/UserUrl';
import * as types from '../../../constants/types/UserTypes';
import { userReducer } from '../../../reducers/user/userReducer';
import { LOGGED_IN_USER_URL } from '../../../services/api/auth/AuthUrl';
import { LOADING_STARTS } from '../../../constants/types/shared/LoadingTypes';

export const useUser = () => {
  const [{ profile, load, user }, dispatch] = useReducer(userReducer, {
    profile: [],
    user: [],
    load: false,
  });

  const getUserProfile = async () => {
    dispatch({ type: LOADING_STARTS });
    try {
      const response = await axios.get(`${LOGGED_IN_USER_PROFILE_URL}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.data.id) {
        sessionStorage.setItem('officialEmail', response.data.email);
        sessionStorage.setItem('firstName', response.data.firstName);
        sessionStorage.setItem('lastName', response.data.lastName);
        sessionStorage.setItem('country', response.data.country);
        dispatch({
          type: types.GET_LOGGED_IN_USER_PROFILE,
          payload: response.data,
        });
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  const getLoggedInUser = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data) {
        dispatch({
          type: types.GET_LOGGED_IN_USER,
          payload: response.data,
        });
      }
    } catch (e) {
      // eslint-disable-next-line no-empty
      if (e) {
      }
    }
  };

  useEffect(() => {
    getUserProfile();
    getLoggedInUser();
  }, []);


  return { profile, user, load };
};
