/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import Moment from 'react-moment';

const ViewRelocation = ({
  request: {
    firstName,
    lastName,
    email,
    phoneNumber,
    old_address,
    new_address,
    note,
    created_at,
    payment_ref,
    relocation_status,
  },
}) => {
  // console.log('request', request);
  return (
    <div id="viewRelocation" className="modal fade mt-5" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title my-2">Request Details</h4>
            <button type="button" className="mr-2 close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div className="modal-body detail text-left" style={{ padding: '0' }}>
            <div className="row">
              <div className="col-lg-12 d-flex">
                <div className="col-lg-3 detail-label1">
                  <label className="mt-1">Name</label>
                  <label>Phone</label>
                  <label>Email</label>
                </div>
                <div className="col-lg-9 detail-result1 item-font">
                  <p className="mt-4 mb-2">{`${firstName} ${lastName}`}</p>
                  <p className="mb-2">{phoneNumber}</p>
                  <p className="mb-2">{email}</p>
                </div>
              </div>
              <div className="col-lg-12 d-flex">
                <div className="col-lg-3 detail-label2">
                  <label>Old Address</label>
                  <label>New Address</label>
                </div>
                <div className="col-lg-9 detail-result2 item-font">
                  <p className="mt-4 mb-1">{old_address}</p>
                  <p className="mb-1">{new_address}</p>
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-3 detail-label1 text-nowrap">
                  <label className="mt-1">Note</label>
                  <label>Payment REF</label>
                  <label>Status</label>
                </div>
                <div className="col-lg-9 detail-result2 item-font">
                  <p className="mt-4 mb-2">{note || 'EMPTY'}</p>
                  <p className="mb-1">{payment_ref || 'N/A'}</p>
                  <p className="mb-1 text-uppercase">{relocation_status}</p>
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-3 detail-label3">
                  <label className="mt-1">Date</label>
                </div>
                <div className="col-lg-9 detail-result1 item-font">
                  <p className="mt-4 mb-2">
                    <Moment format="DD-MM-YYYY hh:mm" date={created_at} />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewRelocation;
