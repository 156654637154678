import axios from 'axios';
import {
  SET_EMPLOYEES_LEAVE_DAYS,
  GET_EMPLOYEES_LEAVE_DAYS,
  EDIT_EMPLOYEES_LEAVE_DAYS,
} from '../../services/api/shared/employees/EmployeesUrl';
import { toast } from 'react-toastify';

// const headers = {
//   'Content-Type': 'application/x-www-form-urlencoded',
//   Authorization: `Bearer ${sessionStorage.getItem('token')}`,
// };
export const getLeaveDays = async (employeeId) => {
  const formData = new URLSearchParams({
    employee_auth_id: employeeId,
  }).toString();
  try {
    const response = await axios.post(GET_EMPLOYEES_LEAVE_DAYS, formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
    toast.error('Error fetching leave days');
  }
};

export const setLeaveDays = async (employeeId, leaveDays, cb) => {
  const formData = new URLSearchParams({
    employee_auth_id: employeeId,
    days: leaveDays,
  }).toString();
  console.log('🚀 ~ setLeaveDays ~ formData:', formData);
  try {
    const response = await axios.post(SET_EMPLOYEES_LEAVE_DAYS, formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      // toast.success('Leave days set successfully');
      if (cb && typeof cb === 'function') {
        cb(response.data);
      }
      return response.data;
    }
  } catch (e) {
    console.log(e);
    toast.error('Error setting leave days');
    if (cb) {
      cb(null, e);
    }
  }
};

export const editLeaveDays = async (employeeId, leaveDays, cb) => {
  const formData = new URLSearchParams({
    days: leaveDays,
  }).toString();
  try {
    const response = await axios.patch(`${EDIT_EMPLOYEES_LEAVE_DAYS}/${employeeId}`, formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      // toast.success('Leave days updated successfully');
      if (cb && typeof cb === 'function') {
        cb(response.data);
      }
      return response.data;
    }
  } catch (e) {
    console.log(e);
    toast.error('Error updating leave days');
    if (cb) {
      cb(null, e);
    }
  }
};
