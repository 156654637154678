/* eslint-disable import/prefer-default-export */
export const ghanaCities = [
  'Abelemkpe',
  'Ablekuma',
  'Abokobi',
  'Abossey Okai',
  'Accra Central',
  'Accra Newtown',
  'Achimota',
  'Adenta',
  'Agbogba',
  'Airport',
  'Amasaman',
  'Ashongman',
  'Asylum Down',
  'Baatsona - Spintex',
  'Burma Camp',
  'Cantonment',
  'Dansoman',
  'Darkuman',
  'Dawhenya',
  'Dodowa',
  'Dome',
  'Dzorwulu',
  'East Legon',
  'Gbawe',
  'Haatso',
  'Kanda',
  'Kaneshie',
  'Kasoa',
  'Kissieman',
  'Kokomlemle',
  'Korle Bu',
  'Korle Gonno',
  'Kpone',
  'Kwabenya',
  'Kwashieman',
  'Labadi',
  'Labone',
  'Lapaz',
  'Lashibi',
  'Legon',
  'Madina',
  'Mallam',
  'Michel Camp',
  'Nima',
  'North Ridge',
  'Nungua',
  'Odorkor',
  'Osu',
  'Oyarifa',
  'Pantang',
  'Prampram',
  'Roman Ridge',
  'Sakumono',
  'Santa Maria',
  'Sowutuom',
  'Taifa',
  'Tema',
  'Tema New Town',
  'Tesano',
  'Teshie',
  'Tieman',
  'Weija',
  'West Legon',
];
