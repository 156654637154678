import React from 'react';
import '../../../../../styles/style.css';
import '../../../../../styles/vehicle.css';
import PropTypes from 'prop-types';
import ProcurementNavBar from '../../../../layouts/procurement/ProcurementNavBar';
import RetrievalsBody from './RetrievalsBody';
import { PROCUREMENT, SUPER_ADMIN } from '../../../../../utils/departments/helpers';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import useCheckTwoDepartments from '../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';

const ProcurementRetrievals = ({ history }) => {
  useCheckTwoDepartments(`${PROCUREMENT}`, `${SUPER_ADMIN}`, history);
  const { profile } = useUser();
  const { firstName } = profile;

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <ProcurementNavBar currentPage="Retrievals" position={firstName} />
        <RetrievalsBody />
      </main>
    </div>
  );
};

ProcurementRetrievals.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default ProcurementRetrievals;
