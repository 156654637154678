/* eslint-disable no-empty */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNoLos } from '../../../../../hooks/shared/no-los/useNoLos';
import { GET_REGIONS_URL, NIGERIAN_STATES_URL } from '../../../../../services/api/field-support/LosUrl';
// import { STATES_URL } from '../../../../../utils/config';

const AddNoLos = () => {
  const { los, onChange, addLos } = useNoLos();
  const [states, setStates] = useState(null);
  const [regions, setRegions] = useState(null);

  const { address, latitude, longitude, notes, state, country, loading } = los;

  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      address,
      latitude,
      longitude,
      notes,
      state,
      country,
    };

    addLos(data);
  };

  const showStates = () => {
    if (country === 'ng') {
      return (
        <select name="state" id="" onChange={onChange}>
          <option value="">Choose State</option>
          {states &&
            states.map((stat) => {
              return <option value={stat.Name}>{stat.Name}</option>;
            })}
        </select>
      );
    }

    return (
      <select name="state" id="" onChange={onChange}>
        <option value="">Choose State</option>
        {regions &&
          regions.map((region) => {
            return (
              <option key={region.state} value={region.state}>
                {region.state}
              </option>
            );
          })}
      </select>
    );
  };

  useEffect(() => {
    const getStates = async () => {
      try {
        const response = await axios.get(`${NIGERIAN_STATES_URL}`);
        setStates(response.data.data);
      } catch (e) {
        // console.log(e.response);
      }
    };

    const getRegions = async () => {
      try {
        const response = await axios.get(`${GET_REGIONS_URL}`);
        // console.log(response.data);
        setRegions(response.data);
      } catch (e) {}
    };

    getStates();
    getRegions();
  }, []);

  return (
    <div
      id="station"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" style={{ margin: '6% auto' }}>
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Add LOS
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-xs-12 col-md-12 col-lg-12">
                <form onSubmit={onSubmit}>
                  <div className="form-group">
                    <div className="col-lg-12 d-flex">
                      <div className="col-lg-6">
                        <label className="col-lg-12 text-justify copy-font">Latitude</label>

                        <input
                          type="number"
                          className="form-control"
                          placeholder="Latitude"
                          name="latitude"
                          value={latitude}
                          onChange={onChange}
                          required
                        />
                      </div>

                      <div className="col-lg-6">
                        <label className="col-lg-12 text-justify copy-font">Longitude</label>

                        <input
                          type="number"
                          className="form-control"
                          placeholder="Longitude"
                          name="longitude"
                          value={longitude}
                          onChange={onChange}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 d-flex">
                      <div className="col-lg-6">
                        <label className="col-lg-12 text-justify copy-font">Notes</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Notes"
                          name="notes"
                          value={notes}
                          onChange={onChange}
                          required
                        />
                      </div>

                      <div className="col-lg-6">
                        <label className="col-lg-12 text-justify copy-font">Address</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Address of basestation"
                          name="address"
                          value={address}
                          onChange={onChange}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 d-flex">
                      <div className="col-lg-6">
                        <label className="col-lg-12 text-justify copy-font">Country</label>
                        <select name="country" id="" onChange={onChange}>
                          <option value="">Choose Country</option>

                          <option value="ng">Nigeria</option>
                          <option value="gh">Ghana</option>
                        </select>
                      </div>

                      <div className="col-lg-6">
                        <label className="col-lg-12 text-justify copy-font">States</label>
                        {showStates()}
                      </div>
                    </div>
                  </div>

                  <div className="text-center mt-4">
                    {loading ? (
                      <button className="btn btn-green copy-font">
                        <i className="fa fa-circle-o-notch fa-spin" />
                      </button>
                    ) : (
                      <button className="btn btn-green">Add No LOS</button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNoLos;
