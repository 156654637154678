import { useEffect, useState } from 'react';
import { FIELD_SUPPORT, TECHNICAL_SUPPORT, API_URL } from '../../../../../../utils/config';
import useFetch from '../../../../../../hooks/shared/custom/data/useFetch';

const useGetEmployees = () => {
  const [employeesInFieldSupport, setEmployeesInFieldSupport] = useState([]);
  const { data: FieldSupportEmployees, loading: loadField } = useFetch(
    `${API_URL}/department/employees/${FIELD_SUPPORT}`,
  );
  const { data: TechSupportEmployees, loading: loadTech } = useFetch(
    `${API_URL}/department/employees/${TECHNICAL_SUPPORT}`,
  );
  useEffect(() => {
    if (FieldSupportEmployees && TechSupportEmployees && !loadField && !loadTech) {
      setEmployeesInFieldSupport(
        FieldSupportEmployees.concat(TechSupportEmployees).filter((e) => e.employees.length > 0),
      );
    }
  }, [FieldSupportEmployees, TechSupportEmployees, loadField, loadTech]);
  return { employeesInFieldSupport };
};
export default useGetEmployees;
