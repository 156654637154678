/* eslint-disable react/require-default-props */
import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import useCallLogs from '../../hooks/customer-support/call-logs/useCallLogs';

export const CallLogContext = createContext({});
export const CallLogProvider = ({ children }) => {
  const { submitting, callLogs, loading, callLog, onChange, createCallLog } = useCallLogs();

  return (
    <CallLogContext.Provider
      value={{
        submitting,
        callLogs,
        loading,
        callLog,
        onChange,
        createCallLog,
      }}
    >
      {children}
    </CallLogContext.Provider>
  );
};

CallLogProvider.propTypes = {
  children: PropTypes.shape(),
};
