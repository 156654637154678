/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTracklistCategory } from './hooks/useTracklistCategory';

const UpdateTracklistCategory = ({ oneItem, oneItem: { id, name } }) => {
  const { updateTracklistCategory, isLoading } = useTracklistCategory();
  // const { id, name } = oneItem;

  const [one, setOne] = useState({ categoryName: '' });
  const { categoryName } = one;

  useEffect(() => {
    const result = { categoryName: name || '' };
    setOne({ ...result });
  }, [oneItem]);

  const handleChange = (e) => {
    const { value } = e.target;
    setOne({ ...one, [e.target.name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const data = { name: categoryName };
    updateTracklistCategory(data, id);
  };

  return (
    <div id="update_category" className="modal fade" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header shadow">
            <h4 className="modal-title">Update Item</h4>
            <button type="button" className="btn btn-outline-info btn-circle btn-close" data-dismiss="modal">
              x
            </button>
          </div>
          <div className="modal-body detail mb-4 p-0">
            <form onSubmit={onSubmit} className="col-md-12">
              <div className="d-flex">
                <div className="p-2 pr-3 form-group col-md-12">
                  <label className="m-0 p-0 copy-font" htmlFor="categoryName">
                    Item Name
                  </label>
                  <input
                    type="text"
                    className="form-control border border-info py-2"
                    id="categoryName"
                    name="categoryName"
                    value={categoryName}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="text-center mt-3">
                {isLoading ? (
                  <button type="button" className="btn btn-green py-2 px-5" disabled>
                    Updating...
                  </button>
                ) : (
                  <button type="submit" className="btn btn-green py-2 px-5" disabled={categoryName.length <= 0}>
                    Update
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

UpdateTracklistCategory.propTypes = {
  oneItem: PropTypes.objectOf.isRequired,
};

export default UpdateTracklistCategory;
