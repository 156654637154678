/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import Logout from '../../../shared/auth/Logout';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import { SALES } from '../../../../../utils/config';
import { getUser } from '../../../../../actions/shared/user/UserActions';
import { getLoggedInUser } from '../../../../../actions/user/UserActions';
import SalesSideBar from '../../../../layouts/sales/SalesSideBar';
import Requisitions from '../../../shared/requisition/shared/Requisitions';
import { SUPER_ADMIN, VICE_PRESIDENT_SALES } from '../../../../../utils/departments/helpers';

class SalesRequisition extends Component {
  componentDidMount() {
    this.checkDepartment();
    // eslint-disable-next-line react/destructuring-assignment
    this.props.getLoggedInUser();
  }

  componentWillReceiveProps() {
    this.checkDepartment();
  }

  checkDepartment = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (
        response.data.department_id !== `${SALES}` &&
        response.data.department_id !== `${SUPER_ADMIN}` &&
        response.data.department_id !== `${VICE_PRESIDENT_SALES}`
      ) {
        // eslint-disable-next-line react/destructuring-assignment
        this.props.history.push('/');

        sessionStorage.setItem('token', '');
        sessionStorage.setItem('email', '');
        sessionStorage.clear();

        toastr.info('You are not allowed to view this page');
      }
    } catch (e) {
      toastr.info('Something went wrong!');
    }
  };

  searchRequisitions = (e) => {
    const keyword = e.target.value;

    if (keyword !== '') {
      // const list = this.state.requisitions.filter((requisition) => {
      //   if (requisition.name.toLowerCase().indexOf(keyword) !== -1) {
      //     return requisition;
      //   }
      // });
      // this.setState({
      //   filtered: list,
      //   keyword: e.target.value,
      // });
    } else {
      // this.setState({
      //   filtered: this.state.requisitions,
      //   keyword,
      // });
    }
  };

  render() {
    const { user } = this.props;
    const { firstName } = user;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <Link className="s-sidebar__trigger" to="#">
                <i className="fa fa-bars mr-3" />
              </Link>
              <SalesSideBar />

              <h3 className="col-lg-6 overview-part">Requisition</h3>
              <span className="navbar-text">
                <h6 className="admin">
                  {firstName}
                  <Logout />
                </h6>
              </span>
            </div>
          </div>
          <div className="after-summary">
            <Requisitions />
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  role: state.auth.auth,
});

SalesRequisition.propTypes = {
  // user: PropTypes.shape(),
  user: PropTypes.array,
  getLoggedInUser: PropTypes.func,
  history: PropTypes.shape(),
};

export default connect(mapStateToProps, {
  getUser,
  getLoggedInUser,
})(SalesRequisition);
