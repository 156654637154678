/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect } from 'react';
import CustomerSupportScheduleTable from './CustomerSupportScheduleTable';
import useSchedule from '../../../../../hooks/customer-support/schedule/useSchedule';

const CustomerSupportScheduleBody = () => {
  const { getSchedule, users, isLoading } = useSchedule();

  useEffect(() => {
    getSchedule();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mt-5 p-3">
      <div className="container-fluid pt-4">
        <div className="row">
          <CustomerSupportScheduleTable users={users} isLoading={isLoading} />
        </div>
      </div>
    </div>
  );
};

export default CustomerSupportScheduleBody;
