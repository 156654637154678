/* eslint-disable react/require-default-props */
import React from 'react';

const CustomInput = ({
  classname,
  name,
  value,
  onChange,
  readOnly,
  type,
  placeholder,
  id,
  list,
  autocomplete,
  onInput,
  required = false,
}) => {
  return (
    <input
      className={classname}
      name={name}
      value={value}
      onChange={onChange}
      readOnly={readOnly}
      placeholder={placeholder}
      type={type}
      id={id}
      list={list}
      autocomplete={autocomplete}
      onInput={onInput}
      required={required}
    />
  );
};

export default CustomInput;
