export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const START_CREATE_CATEGORY = 'START_CREATE_CATEGORY';
export const STOP_CREATE_CATEGORY = 'STOP_CREATE_CATEGORY';
export const START_CREATE_REQUEST = 'START_CREATE_REQUEST';
export const STOP_CREATE_REQUEST = 'STOP_CREATE_REQUEST';
export const CREATE_REQUEST = 'CREATE_REQUEST';
export const GET_REQUESTS = 'GET_REQUESTS';
export const START_UPDATING_STATUS = 'START_UPDATING_STATUS';
export const STOP_UPDATING_STATUS = 'STOP_UPDATING_STATUS';
export const STATUS_UPDATED = 'STATUS_UPDATED';
