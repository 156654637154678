import React from 'react';

const RequestsTableHead = () => {
  return (
    <thead>
      <tr>
        <th>#</th>
        <th nowrap="no-wrap">Category</th>
        <th nowrap="no-wrap">Description</th>
        <th nowrap="no-wrap">Date Requested</th>
        <th nowrap="no-wrap">Handled By</th>
        <th nowrap="no-wrap">Requested By</th>

        {/* <th nowrap="no-wrap">Date Handled</th> */}
        <th>Status</th>
        <th style={{ paddingLeft: '5px' }}>Details</th>
      </tr>
    </thead>
  );
};

export default RequestsTableHead;
