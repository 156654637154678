import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TurboConnectStatisticsOverview from './TurboConnect/TurboConnectStatisticsOverview';
import KpiPerMonthOverview from './FieldServiceKpiPerMonth/KpiPerMonthOverview';
import BizDevOverview from './BizDev/BizDevOverview';
import InstallationAssetByZone from './InstByAssetZone/InstallationAssetByZone';
import InstallationTargetByState from './InstTargetByState/InstallationTargetByState';
import ActiveExpiredTurboByZone from './ActiveInactiveTurboByZone/ActiveExpiredTurboByZone';
import ActiveExpiredUsersByAssetZone from './ActiveExpiredUsersByAssetZone/ActiveExpiredUsersByAssetZone';
import ChangedInExpired from './ChangeInExpired/ChangedInExpired';
import SupportCallsByZone from './support-calls/SupportCallsByZone';
import DowntimeLogger from './downtime-logger/DowntimeLogger.Index';
import SupportVisitIndex from './support-visit/SupportVisitIndex';
const StatisticsOverviewBody = () => {
  const [value, setValue] = React.useState('one');

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // console.log(value);
    // console.log(event);
  };

  function truncateText(text) {
    if (text.length > 20) {
      return text.substring(0, 20) + '...';
    }
    return text;
  }

  const componentMap = {
    one: TurboConnectStatisticsOverview,
    two: BizDevOverview,
    three: KpiPerMonthOverview,
    four: InstallationAssetByZone,
    five: InstallationTargetByState,
    six: ActiveExpiredTurboByZone,
    seven: ActiveExpiredUsersByAssetZone,
    eight: ChangedInExpired,
    nine: SupportCallsByZone,
    ten: DowntimeLogger,
    eleven: SupportVisitIndex,
  };

  const ComponentToRender = componentMap[value];

  return (
    <>
      <Box sx={{ width: '95%', mt: 10 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          // textColor="primary"
          // indicatorColor="primary"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="coo-analytics-tabs-wrapped"
        >
          <Tab value="one" label={truncateText('Turbo connect and turbo connect Pro Tracker (Nigeria)')} wrapped />
          <Tab value="two" label={truncateText('Business Development Installations')} wrapped />
          <Tab value="three" label={truncateText('Field Service Kpi Per Month')} wrapped />
          <Tab value="four" label={truncateText('Installation by Asset zone')} wrapped />
          <Tab value="five" label={truncateText('Installation Target by state')} wrapped />
          <Tab value="six" label={truncateText('Active and Expired Turbo by Asset Zone')} wrapped />
          <Tab value="seven" label={truncateText('Active and Expired Customers by Asset Zone')} wrapped />
          <Tab value="eight" label={'Change In Expired'} wrapped />
          <Tab value="nine" label={truncateText('Support Calls by Asset Zone')} wrapped />
          <Tab value="ten" label={truncateText('Product Uptime Logger by Asset Zone')} wrapped />
          <Tab value="eleven" label={truncateText('Support Visits')} wrapped />
        </Tabs>
      </Box>
      <Box>{ComponentToRender && <ComponentToRender />}</Box>
    </>
  );
};
export default StatisticsOverviewBody;
