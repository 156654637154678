import React, { Component } from 'react';
import toastr from 'toastr';
import axios from 'axios';
import URLS from '../../../../../../../services/api/hotspot/HotspotAdminUrls';

export default class FundWallet extends Component {
  updateAmount(e) {
    e.preventDefault();
    const amount = this.amount.value;
    const accountid = this.accountid.value;

    console.log(
      `here are the retailer information${amount}${accountid}`,
    );

    if (!this.validateInput(amount)) {
      toastr.warning(
        'Please enter a valid amount , minimum of 200 Naira',
      );
      return;
    }
    axios
      .post(URLS.base_URL_LOCAL + URLS.fund_Retailer, {
        accountid,
        amount,
      })
      .then(function(response) {
        if (response.data.status) {
          // console.log("RetailerDetails is the "+JSON.stringify(response.data.data));
          // this.setState({
          //   retailerResponse : response.data.data
          // })

          toastr.success(response.data.message);

          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          toastr.error(response.data.message);
        }
      })
      .catch(function(error) {
        // console.log(error);
        toastr.error(
          'ERROR, Please check your internet connection',
        );
      });
  }

  validateInput(amount) {
    if (isNaN(amount)) {
      return false;
    }
    if (amount < 200) {
      return false;
    }
    return true;
  }

  constructor(props) {
    super(props);
    this.updateAmount = this.updateAmount.bind(this);
  }

  render() {
    return (
      <div
        id="fundwallet"
        className="modal fade in"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
        style={{ overflow: 'auto' }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4
                className="modal-title text-center"
                id="myModalLabel"
              >
                Fund Wallet
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                ×
              </button>
            </div>
            <div className="modal-body">
              <form
                className="form-horizontal"
                onSubmit={this.updateAmount}
              >
                <div className="form-group">
                  <div className="col-12 d-flex mb-3">
                    <div className="col-6">
                      {/* fullname={this.state.profileFullName}
>>>>>>> hotspotadmin
                businessName={this.state.profileBusinessName}
                phone={this.state.profilePhone}
                email={this.state.profileEmail}
                balance={this.state.profileBalance}
                accountid={this.state.accountid}/> */}

                      <label className="copy-font p-0 m-0">
                        Fullname
                      </label>
                      <input
                        type="text"
                        disabled
                        value={this.props.fullname}
                        className="form-control"
                      />
                    </div>

                    <div className="col-6">
                      <label className="copy-font p-0 m-0">
                        Business name
                      </label>
                      <input
                        type="text"
                        disabled
                        value={this.props.businessName}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-12 d-flex mb-3">
                    <div className="col-6">
                      <div className="mb-2">
                        <label className="copy-font p-0 m-0">
                          Phone number
                        </label>
                        <input
                          type="tel"
                          placeholder="Phone number"
                          disabled
                          value={this.props.phone}
                          className="form-control"
                        />
                      </div>

                      <div>
                        <label className="copy-font p-0 m-0">
                          AccountID
                        </label>
                        <input
                          type="text"
                          ref={(accountid) =>
                            (this.accountid = accountid)
                          }
                          disabled
                          value={this.props.accountid}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-12 d-flex mb-3">
                    <div className="col-6">
                      <label className="copy-font p-0 m-0">
                        Email Address
                      </label>
                      <input
                        type="email"
                        disabled
                        value={this.props.email}
                        placeholder="Email Address"
                        className="form-control"
                      />
                    </div>

                    <div className="col-6">
                      <label className="copy-font p-0 m-0">
                        Amount
                      </label>
                      <input
                        type="number"
                        ref={(amount) =>
                          (this.amount = amount)
                        }
                        placeholder="Add amount to fund(min N200)"
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-12 mb-3 text-center">
                    <button
                      type="submit"
                      className="btn second-button"
                    >
                      Fund Wallet
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
