import React, { useEffect, useState } from 'react';
import qs from 'qs';
import ExpressWifiCustomersSignupsTable from './ExpressWifiCustomersSignupsTable';
import useExpressWifiAmbassadors from '../../../../../hooks/express-wifi/useExpressWifiAmbassadors';
import AddNewCustomer from './AddNewExpressCustomer';
import ExpressWifiCustomerSignupsBulkUpload from './ExpressWifiCustomerSignupsBulkUpload';
import { token } from '../../../../../utils/config';

const ExpressWifiCustomersSignupsBody = () => {
  const { getListOfCustomers, items, isLoading } = useExpressWifiAmbassadors();
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [show, setShow] = useState(false);

  const data = qs.stringify({
    action: 'get_list_of_customers',
    from: `${from}`,
    to: `${to}`,
  });

  useEffect(() => {
    getListOfCustomers(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    getListOfCustomers(data);
  };

  return (
    <div className="mt-5 p-3">
      <div className="container-fluid pt-4">
        <div className="row">
          <div className="col-lg-12 mt-3 mb-3">
            <form className="form-inline mt-4 inner-addon d-flex" onSubmit={onSubmit}>
              <div className="form-group mx-2">
                <label className="mt-2 p-0 copy-font">From: </label>
                <input
                  className="form-control mr-2"
                  name="from"
                  value={from}
                  readOnly={false}
                  onChange={(e) => setFrom(e.target.value)}
                  type="date"
                />

                <label className="mt-2 p-0 copy-font">To: </label>
                <input
                  className="form-control mr-2"
                  name="to"
                  value={to}
                  readOnly={false}
                  onChange={(e) => setTo(e.target.value)}
                  type="date"
                />
              </div>

              <div className="mb-3">
                <button className="btn btn-green" style={{ height: '39px' }}>
                  Search
                </button>
              </div>

              <div className="mb-3  pl-5">
                <button
                  type="button"
                  className="btn btn-green"
                  data-toggle="modal"
                  data-target="#addNewCustomer"
                  data-placement="right"
                >
                  Add New Customer
                </button>
                <AddNewCustomer to={to} />
              </div>
              <div className="mb-3  pl-3">
                <button type="button" className="btn btn-green" data-toggle="modal" data-target="#uploadCustomer">
                  Upload CSV
                </button>
                <ExpressWifiCustomerSignupsBulkUpload />
              </div>
            </form>
          </div>
        </div>
        <div className="row">
          <ExpressWifiCustomersSignupsTable items={items} isLoading={isLoading} />
        </div>
      </div>
    </div>
  );
};

export default ExpressWifiCustomersSignupsBody;
