/* eslint-disable import/prefer-default-export */
import { useReducer } from 'react';
import qs from 'qs';
import axios from 'axios';
import toastr from 'toastr';
import { LOADING_STOPS } from '../../constants/types/shared/LoadingTypes';
import DedicatedCustomersReducer from '../../reducers/business-development/DedicatedCustomersReducer';
import { GET_DEDICATED_CUSTOMERS_URL } from '../../services/api/business-development/DedicatedCustomersUrl';

export const useUpdateDedicatedCustomers = () => {
  const [{ items, count, pages, loading, lists }, dispatch] = useReducer(DedicatedCustomersReducer, {
    items: [],
    lists: [],
    count: 1,
    pages: 1,
    monthlyCount: 0,
    isLoading: false,
    tracker: false,
  });

  // const [page] = useState(1);

  const UpdateDecommissioned = async (data) => {
    try {
      const response = await axios.post(`https://api.tizeti.com:8443/api/v1/users/bizDevDecommisioned`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data) {
        // dispatch({
        //   type: GET_INSTALLATIONS,
        //   payload: response.data,
        // });
        toastr.success('Update Successful');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (e) {
      if (e) {
        toastr.info('Response not sent!');
        // dispatch({
        //   type: FETCHING_STOPS,
        //   payload: false,
        // });
      }
    }
  };

  const UpdateDedicatedCustomersStatus = async (data) => {
    const config = {
      method: 'post',
      action: 'update_dedicated_customer_status_subscription_type',
      url: `${GET_DEDICATED_CUSTOMERS_URL}`,
      data: qs.stringify(data),
    };

    await axios(config)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === true) {
          // console.log('true');
          toastr.success('Update Successful');
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((response) => {
        if (response.data.status === false) {
          toastr.info('Response not sent!');
          dispatch({ type: LOADING_STOPS });
        }
      });
  };

  return {
    items,
    pages,
    count,
    loading,
    UpdateDecommissioned,
    lists,
    UpdateDedicatedCustomersStatus,
  };
};
