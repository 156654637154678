/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable prettier/prettier */
/* eslint-disable react/require-default-props */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-case-declarations */
import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useCustomer } from '../../../../../hooks/shared/customers/useCustomers';
import { usePayments } from '../../../../../hooks/shared/customers/usePayments';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import ViewPassword from '../../../shared/customers/passwords/ViewPassword';
import ExtendCustomer from '../../../shared/customers/extensions/ExtendCustomer';
import PaymentsHistory from '../../../shared/customers/payments/PaymentsHistory';
import view from '../../../../../assets/img/icons/view.png';
import CustomerDetails from '../../../shared/customers/shared/CustomerDetails';
import edit from '../../../../../assets/img/icons/edit.png';
import EditDetails from './EditDetails';
import { getLoggedInUser } from '../../../../../actions/user/UserActions';
import { displayCountryDropdown } from '../../../../../utils/geniatech/Geniatech';
import GetInstallationDate from '../../../shared/customers/GetInstallationDate';
import { useInstallations } from '../../../../../hooks/shared/installations/useInstallations';

// eslint-disable-next-line no-shadow
const CustomersTable = ({ role }) => {
  console.log(sessionStorage.getItem('roleId'));
  // useEffect(() => {
  //   getLoggedInUser();
  // }, []);

  const role_id = sessionStorage.getItem('roleId');
  const {
    search,
    onSearchChange,
    searchForUserByEmail,
    searchByPhoneOrMac,
    searchByName,
    searchByReference,
    customers,
    isLoading,
    option,
    onOptionChange,
    getPassword,
    gettingPassword,
    password,
    getUserDetails,
    gettingDetails,
    details,
    address,
    itemprice,
    session,

    country,
    onCountryChange,
    onShowModal,
    gettingDate,
    installationDate,
  } = useCustomer();

  const { payments, gettingPayments, getCustomerPayments } = usePayments();
  const { getCalledStation, calledstation, radacct, isFetching } = useInstallations();

  const [mac, setMac] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [customerRef, setCustomerRef] = useState('');

  const searchForUser = (e) => {
    e.preventDefault();
    switch (option) {
      case 'email':
        const emailSearch = {
          email: search.trim(),
        };
        searchForUserByEmail(emailSearch);
        break;

      case 'name':
        const nameSearch = {
          name: search.trim(),
          country,
        };
        searchByName(nameSearch);
        break;

      case 'phone':
        const phoneSearch = {
          phoneNumber: search.trim(),
          macAddress: '12',
        };
        searchByPhoneOrMac(phoneSearch);
        break;

      case 'macid':
        const macidSearch = {
          macAddress: search.trim(),
          phoneNumber: 'N/A',
        };
        searchByPhoneOrMac(macidSearch);
        break;
        
        case 'reference':
        const refernceNumberSearch = {
          reference: search.trim(),
          phoneNumber: 'N/A',
          macAddress: 'N/A',
          action: 'getUserInformationWithPhoneOrMac',
          category: 'reference',
        };
        searchByReference(refernceNumberSearch);
        break;

      default:
        return null;
    };
    
    
    //  default:
    //     return null; 
    };

  const getUserPassword = (customer) => {
    const data = {
      action: 'getCustomerPassword',
      username: customer.username,
    };
    getPassword(data);
  };

  const getUser = (customer) => {
    setMac(customer.username);
    setName(customer.name);
    setEmail(customer.mail);
    setPhone(customer.address);
    setCustomerRef(customer.customer_ref);

    getCalledStation(customer.username);
  };

  const getUserPayments = (customer) => {
    const data = {
      macid: customer.username,
    };
    getCustomerPayments(data);
  };

  const getCustomerDetails = (customer) => {
    setName(customer.name);
    setEmail(customer.mail);
    setCustomerRef(customer.customer_ref);
    getUserDetails(customer.username);
  };



  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12 mt-5 mb-3">
          <form onSubmit={searchForUser} className="form-inline inner-addon d-flex">
            <div className="input-group mb-3 mr-3">
              <select name="keyword" onChange={onOptionChange} className="form-control mr-auto empty mt-1">
                <option value="">Choose fields to search from</option>
                <option value="email">Email</option>
                <option value="name">Name</option>
                <option value="phone">Phone</option>
                <option value="macid">MAC ID</option>
                <option value="reference">Account Number</option>
              </select>
            </div>

            {displayCountryDropdown(option, onCountryChange)}

            <div className="input-group mb-3 mr-3">
              <input
                className="form-control mr-auto empty pl-5"
                type="text"
                placeholder="search for user"
                name="search"
                value={search}
                onChange={onSearchChange}
              />
              <span className="fa fa-search" />
            </div>

            <div className="mb-3">
              <button className="btn btn-green" style={{ height: '39px' }}>
                Search
              </button>
            </div>
          </form>
        </div>
        <div className="card x-panel">
          <div className="table-responsive content">
            <table className="table copy-font">
              <thead>
                <tr>
                  <th>#</th>
                  <th>MAC ID</th>
                  <th nowrap="no-wrap">Name</th>
                  <th>Email</th>
                  <th nowrap="no-wrap">Show Password</th>
                  <th nowrap="no-wrap">Phone Number</th>
                  {/* {Number(role_id) > 1 ? <th className="text-center">Extend</th> : null} */}
                  <th nowrap="no-wrap">Expiry Date</th>
                  {Number(role_id) > 1 && Number(role_id) !== 6 ? <th className="text-center">Edit</th> : null}
                  <th style={{ paddingLeft: '24px' }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <img src={loader} alt="loading gif" />
                ) : (
                  customers.map((customer, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{customer.username}</td>
                      <td nowrap="no-wrap">{customer.name}</td>
                      <td>{customer.mail}</td>
                      <td className="ml-2">
                        <button
                          data-toggle="modal"
                          data-target="#view-password"
                          className="ml-3"
                          onClick={() => getUserPassword(customer)}
                        >
                          <i className="view1 fa fa-ellipsis-v" style={{ fontSize: '20px' }} />
                        </button>
                        {/* {get ? <h1>loading</h1> : <PaymentHistory usersTransactions={this.state.transactions}/>} */}
                        {/* <PaymentHistory usersTransactions={this.state.transactions} get={get}/> */}
                        <ViewPassword password={password} load={gettingPassword} />
                      </td>
                      <td>{customer.address}</td>
                      {/* {Number(role_id) > 1 ? (
                        <td
                          className="text-center img-pointer"
                          data-target="#extend"
                          onClick={() => getUser(customer)}
                          data-toggle="modal"
                          style={{ width: '40%' }}
                          // eslint-disable-next-line prettier/prettier
                        >
                          {' '}
                          <span className="view2">
                            <img src={edit} alt="" />
                          </span>{' '}
                        </td>
                      ) : null} */}
                      <ExtendCustomer username={mac} name={name} />

                      <td>{customer.value}</td>
                      {Number(role_id) > 1 && Number(role_id) !== 6 ? (
                        <td
                          className="text-center img-pointer"
                          data-target="#edit-details"
                          onClick={() => getUser(customer)}
                          data-toggle="modal"
                          style={{ width: '40%' }}
                        >
                          <span className="view2">
                            <img src={edit} alt="" data-toggle="tooltip" data-placement="top" title="Edit" />
                          </span>
                        </td>
                      ) : null}
                      <EditDetails
                        username={mac}
                        oldEmail={email}
                        oldName={name}
                        oldPhone={phone}
                        role_id={role_id}
                        calledstation={calledstation}
                        radacct={radacct}
                        isFetching={isFetching}
                      />
                      <td className="ml-1">
                        {/* <button
                          data-toggle="modal"
                          data-target="#payment-history"
                          className="ml-3"
                          onClick={() => getUserPayments(customer)}
                        >
                          <i className="view1 fa fa-ellipsis-v" style={{ fontSize: '20px' }} />
                        </button>
                        <PaymentsHistory load={gettingPayments} transactions={payments} /> */}

                        <button
                          type="button"
                          style={{
                            border: '0',
                            backgroundColor: 'white',
                          }}
                          data-toggle="tooltip"
                          data-placement="left"
                          title="View"
                          onClick={() => getCustomerDetails(customer)}
                        >
                          <span className="view1 ml-4">
                            <img src={view} alt="" data-toggle="modal" data-target="#cus-details" />
                          </span>
                        </button>
                        <CustomerDetails
                          load={gettingDetails}
                          address={address}
                          details={details}
                          session={session}
                          user={customer}
                          name={name}
                          mail={email}
                          customerRef={customerRef}
                          itemprice={itemprice}
                          installationDate={installationDate}
                        />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* <RetentionScript/> */}
      <ToastContainer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  role: state.auth.auth,
});

CustomersTable.propTypes = {
  role: PropTypes.shape(),
  getLoggedInUser: PropTypes.func,
};

export default connect(mapStateToProps, { getLoggedInUser })(CustomersTable);
