/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import CustomerSupportSideBar from '../../../../layouts/customer-support/CustomerSupportSideBar';
import Logout from '../../../shared/auth/Logout';
import SalesLogsTable from '../../sales/logs/SalesLogsTable';
import { API_URL, customer_support } from '../../../../../utils/config';
import mail from '../../../../../assets/img/icons/mailblast.svg';
import CustomerServiceScript from '../../../shared/CustomerServiceScript';
import RetentionScript from '../../../shared/customer-support/RetentionScript';

class CustomerSupportCallog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      log: [],
      filtered: [],
      loading: false,
      user: '',
      role: '',
    };
    //  this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.getUser();
    this.getCallLogs();
    // this.fetchLoggedOnUser();
  }

  onChange = (e) => {
    [e.target.name] = e.target.value;
  };

  getUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employee/loggedOnEmployee`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
      .then((res) => {
        this.setState({
          user: res.data.firstName,
        });
      })
      .catch(() => {});
  }

  getCallLogs() {
    this.setState({
      loading: true,
    });

    axios({
      method: 'get',
      url: `${API_URL}/call_logs/${customer_support}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
      .then((res) => {
        this.setState({
          log: res.data.logs,
          filtered: res.data.logs,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  }

  searchCallLog(e) {
    const keyword = e.target.value;
    // console.log(keyword);
    if (keyword !== '') {
      const list = this.state.log.filter((logs) => {
        if (logs.firstName.toLowerCase().indexOf(keyword) !== -1) {
          return logs;
        }

        if (logs.lastName.toLowerCase().indexOf(keyword) !== -1) {
          return logs;
        }

        if (logs.email.toLowerCase().indexOf(keyword) !== -1) {
          return logs;
        }

        if (logs.phoneNumber.indexOf(keyword) !== -1) {
          return logs;
        }
      });

      this.setState({
        filtered: list,
        keyword: e.target.value,
      });
    } else {
      this.setState({
        filtered: this.state.log,
        keyword,
      });
    }
  }

  render() {
    const { log, loading, user, role } = this.state;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              {/* <Link className="s-sidebar__trigger" to={"#"}>
                                <i className="fa fa-bars mr-3"></i>
                            </Link> */}
              <CustomerSupportSideBar role={role} />

              <h3 className="col-md-5 overview-part">Call Log</h3>

              <div className="ml-5 d-flex profile-mail">
                <Link
                  to="/technical-support/mail-blast"
                  className="d-none d-md-flex"
                  style={{
                    color: 'gray',
                    textDecoration: 'none',
                  }}
                >
                  {/* <Link to={"/sales/mail-blast"} className="d-flex" style={{ color: "gray", textDecoration: "none" }}> */}
                  <img src={mail} alt="" className="logout mr-2" />
                  <span>
                    <h6 className="m-2">
                      <strong>MailBlast</strong>
                    </h6>
                  </span>
                </Link>

                <h6 className="mr-0 d-flex">
                  <i className="small mr-2 d-none d-md-block">{user}</i>
                  <Logout />
                </h6>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row mt-4">
              <div className="col-lg-12 col-md-12 col-sm-12 mt-5 d-flex flex-wrap">
                <form className="col-6 form-inline d-flex mt-3 inner-addon left-addon mr-auto" action="#">
                  <input
                    className="form-control mr-sm-2 empty w-100 mt-1"
                    name="keyword"
                    type="text"
                    placeholder="Search by name, email, phone number"
                    value={this.state.keyword}
                    onChange={(e) => this.searchCallLog(e)}
                  />
                  <span className="fa fa-search" />
                </form>

                {/* <p className="fliter copy-font d-flex mt-3 mr-3"> */}
                <p className="fliter copy-font d-flex mt-3">
                  <small>Filter By:</small>
                  <span>
                    <select className="form-control">
                      <option>Status</option>
                    </select>
                  </span>
                </p>

                <p className="fliter copy-font d-flex mt-3">
                  <span>
                    <select className="form-control">
                      <option>Customer support Staff Name</option>
                    </select>
                  </span>
                </p>

                {/* </p> */}
              </div>

              <div className="card x-panel logs">
                <div className="table-responsive content">
                  <table className="table">
                    <thead className="copy-font">
                      <tr>
                        <th>#</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Status</th>
                        {/* <th>Base station</th>
                                        <th>Comments</th>
                                        <th>Date Updated</th>
                                        <th>Assign to</th> */}
                        <th style={{ paddingLeft: '5px' }}>Actions</th>
                      </tr>
                    </thead>
                    <SalesLogsTable logs={this.state.filtered} load={loading} />
                  </table>
                </div>
              </div>
            </div>

            <span className="d-none d-md-flex">
              <RetentionScript />
              <CustomerServiceScript />
            </span>
          </div>
        </main>
      </div>
    );
  }
}

export default CustomerSupportCallog;
