/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useBasestations } from '../../../../hooks/shared/basestations/useBasestation';

const EditCustomerBasestation = ({ oldBasestation, macID, userID, expiryDate }) => {
  const [basestationID, setBasestationID] = useState('');
  const { basestations, changeUserBasestation } = useBasestations();

  const onSubmit = (e) => {
    e.preventDefault();
    changeUserBasestation(userID, basestationID);
  };

  return (
    <div
      id="edit-details"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title mx-auto" id="myModalLabel">
              Edit Customer Basestation
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={onSubmit}>
              <div className="d-flex">
                <div className="p-0 mt-2">
                  <div>
                    <label className="text-nowrap p-1 copy-font">MacID:</label>
                  </div>
                  <div className="mt-1">
                    <label className="text-nowrap p-1 copy-font">Expiry Date:</label>
                  </div>
                  <div className="mt-1">
                    <label className="text-nowrap p-1 copy-font">Current Basestation:</label>
                  </div>
                  <div className="mt-1">
                    <label htmlFor="newBasestation" className="text-nowrap p-1 copy-font">
                      New Basestation:
                    </label>
                  </div>
                </div>
                <div className="p-0">
                  <div className="m-1">
                    <input type="text" className="form-control" value={macID} readOnly />
                  </div>
                  <div className="m-1 mt-2">
                    <input type="text" className="form-control" value={expiryDate} readOnly />
                  </div>
                  <div className="m-1 mt-2">
                    <input type="text" className="form-control" value={oldBasestation.toUpperCase()} readOnly />
                  </div>
                  <div className="m-1 mt-2">
                    <select
                      className="form-control"
                      id="newBasestation"
                      onChange={(e) => setBasestationID(e.target.value)}
                      name="basestation"
                    >
                      <option>Select Basestation</option>
                      {basestations.map((basestation) => {
                        return (
                          <option key={basestation.id} name={basestation.name} value={basestation.id}>
                            {basestation.name.toUpperCase()}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="mt-2 text-center">
                <button className="btn btn-green shadow-md" type="submit">
                  Proceed
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

EditCustomerBasestation.propTypes = {
  oldBasestation: PropTypes.string.isRequired,
  userID: PropTypes.string.isRequired,
  macID: PropTypes.string.isRequired,
  expiryDate: PropTypes.string.isRequired,
};
export default EditCustomerBasestation;
