/* eslint-disable import/prefer-default-export */
import { useReducer } from 'react';
import qs from 'qs';
import axios from 'axios';
import toastr from 'toastr';
import { SEARCH_LEAD_URL } from '../../../../../services/api/shared/leads/LeadsUrl';
import { GET_LEAD_REQUEST } from './LeadTypes';
import { LOADING_STARTS, LOADING_STOPS } from '../../../../../constants/types/shared/LoadingTypes';
import { LeadsReducers } from './LeadsReducers';

export const useSearchLeads = () => {
  const [{ lists, isLoading }, dispatch] = useReducer(LeadsReducers, {
    lists: [],
    isLoading: false,
  });

  const searchLead = async (data) => {
    dispatch({ type: LOADING_STARTS });
    const config = {
      method: 'post',
      url: `${SEARCH_LEAD_URL}`,
      data: qs.stringify(data),
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response.data) {
          dispatch({
            type: GET_LEAD_REQUEST,
            payload: {
              lists: response.data.users,
            },
          });
        }
      })
      .catch(() => {
        toastr.info('Something went wrong!');
        dispatch({ type: LOADING_STOPS });
      });
  };

  return {
    lists,
    isLoading,
    searchLead,
  };
};
