/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-is-valid */
import '../../../../../styles/style.css';
import '../../../../../styles/callog.css';
import { Link } from 'react-router-dom';
import React from 'react';
import Logout from '../../../shared/auth/Logout';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import useCheckTwoDepartments from '../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';
import { ACCOUNTS, SUPER_ADMIN } from '../../../../../utils/departments/helpers';
import AccountsAddLteBody from './AccountsAddLteBody';
import AccountsSideBar from '../../../../layouts/accounts/sidebar/AccountsSideBarClass';

const AccountsAddLte = ({ history }) => {
  const { profile } = useUser();
  useCheckTwoDepartments(`${ACCOUNTS}`, `${SUPER_ADMIN}`, history);

  return (
    <div className="s-layout ">
      <main className="s-layout__content col-lg-10 p-0">
        <div className="d-flex col-lg-12 p-2 top-header">
          <div className="mt-7 d-flex col-lg-12 p-0">
            <Link className="s-sidebar__trigger" to="#">
              <i className="fa fa-bars mr-3" />
            </Link>
            <AccountsSideBar />

            <h3 className="col-lg-6 overview-part pt-2">Add LTE User</h3>

            <div className="d-flex profile-mail col-6">
              <h6>
                <i className="mr-auto">{profile.firstName} </i>
                <Logout />
              </h6>
            </div>
          </div>
        </div>
        <AccountsAddLteBody />
      </main>
    </div>
  );
};

export default AccountsAddLte;
