import React from 'react';
import { Link } from 'react-router-dom';
import TizetiLogo from '../../../../../../assets/img/logo/Tizeti-Logo.png';
import Logout from '../../../../shared/auth/Logout';
import SuperAdminSideBar from '../../../../../layouts/super-admin/SuperAdminSideBar';
import useCheckDepartment from '../../../../../../hooks/shared/custom/check-department/useCheckDepartment';
import { LOGGED_IN_USER_URL } from '../../../../../../services/api/auth/AuthUrl';
import { useUser } from '../../../../../../hooks/shared/user/useUser';
import ApprovedTable from './ApprovedTable';
import { admin } from '../../../../../../utils/config';

// eslint-disable-next-line react/prop-types
const AllApprovedRequsitions = ({ history }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { result } = useCheckDepartment(`${LOGGED_IN_USER_URL}`, `${admin}`, history);
  const { profile } = useUser();
  const { firstName } = profile;

  return (
    <div className="main-wrapper">
      <header className="topbar shade bg-white fixed-top">
        <nav className="navbar navbar-expand-lg navbar-light top-navbar fixed-top">
          <Link className="navbar-brand" to="/accounts">
            <img src={TizetiLogo} alt="homepage" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse mt-3" id="navbarText">
            <ul className="navbar-nav">
              <li className="nav-item active">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link className="nav-link" to="#">
                  <h3>
                    All Approved Requisition
                    <span className="sr-only">(current)</span>
                  </h3>
                </Link>
              </li>
            </ul>
            <span className="navbar-text">
              <div className="d-flex">
                <h6 className="mr-0 mt-1">
                  {firstName}
                  <Logout />
                </h6>
              </div>
            </span>
          </div>
        </nav>
      </header>

      <div className="all-sides col-lg-12">
        <SuperAdminSideBar />
        <div className="page-wrapper col-lg-10">
          <div className="container-fluid">
            <div className="row">
              {' '}
              <ApprovedTable />{' '}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllApprovedRequsitions;
