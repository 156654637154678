/* eslint-disable react/require-default-props */
import React from 'react';
import Logout from '../../../shared/auth/Logout';
import CustomerSupportSideBar from '../../../../layouts/customer-support/CustomerSupportSideBar';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import Archives from '../../../shared/customer-support/archives/Archives';

const CustomerSupportArchives = () => {
  const { profile } = useUser();
  const { firstName } = profile;

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <div className="d-flex col-lg-12 p-2 top-header">
          <div className="mt-3 d-flex col-lg-12 p-0">
            <CustomerSupportSideBar />

            <h4 className="col-md-5 overview-part">Archives</h4>

            <div className="ml-5 d-flex profile-mail">
              <h6 className="mr-0 d-flex">
                <i className="small mr-2 d-none d-md-block">{firstName}</i>
                <Logout />
              </h6>
            </div>
          </div>
        </div>

        <div className="mt-5 p-3">
          <Archives />
        </div>
      </main>
    </div>
  );
};

export default CustomerSupportArchives;
