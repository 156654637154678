import React from 'react';
import BusinessDevelopmentNavBar from '../../../../layouts/business-development/BusinessDevelopmentNavBar';
import BusinessDevelopmentDedicatedConnectedUsersBody from './BusinessDevelopmentDedicatedConnectedUsersBody';

const BusinessDevelopmentDedicatedConnectedUsers = () => {
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <BusinessDevelopmentNavBar currentPage="Dedicated Connected Users" />
        <BusinessDevelopmentDedicatedConnectedUsersBody />
      </main>
    </div>
  );
};

export default BusinessDevelopmentDedicatedConnectedUsers;
