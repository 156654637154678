import React from 'react';
import tizetiProducts from '../../../../../../assets/img/logo/tizeti-products.jpeg';

const OverviewBody = () => {
  return (
    <div className="pt-5">
      <div className="" style={{ marginTop: '250px' }}>
        <img src={tizetiProducts} className="mx-auto d-block" width="1200px" height="200px" alt="" />
      </div>
    </div>
  );
};

export default OverviewBody;
