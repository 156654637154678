import * as types from '../../../constants/types/asset-team/AssetTeamTypes';
import * as load from '../../../constants/types/shared/LoadingTypes';

export const expiredZoneReducer = (state, action) => {

  const {type, payload} = action;
  switch (type) {

    case load.LOADING_STARTS:
      return {
        ...state,
        isLoading: true,
      };

    case load.LOADING_STOPS:
      return {
        ...state,
        isLoading: false,
      };

    case types.GET_EXPIRED_CUSTOMER_ZONE_DETAILS:
      return {
        ...state,
        time: payload.time,
        items: payload.items,
        total: payload.total,
        isLoading: false

      }

    default:
      return state;
  }
}