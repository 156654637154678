/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { GET_USER_PROFILE } from '../../../constants/types/UserTypes';
import { LOGGED_IN_USER_PROFILE_URL } from '../../../services/api/shared/UserUrl';

export const getUser = () => async (dispatch) => {
  try {
    const response = await axios.get(`${LOGGED_IN_USER_PROFILE_URL}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    if (response.data.id) {
      dispatch({
        type: GET_USER_PROFILE,
        payload: response.data,
      });
    }
  } catch (e) {}
};
