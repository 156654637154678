/* eslint-disable react/require-default-props */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

const CustomerDetails = ({ user, onChange, editUserMac, mac, submitFormToEditMacId }) => {
  const [editMac, setEditMac] = useState(false);
  // const [editFirstName, setEditFirstName] = useState(false);
  // const [editLastName, setEditLastName] = useState(false);
  // const [editEmail, setEditEmail] = useState(false);
  // const [editPhone, setEditPhone] = useState(false);
  // const [editUserName, setEditUserName] = useState(false);

  // function
  const editMacId = () => {
    setEditMac(!editMac);
  };

  const editTheMac = (data) => {
    submitFormToEditMacId(data);
  };

  return (
    <div
      id="crm-details"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" style={{ width: '600px' }}>
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Customer Details
            </h4>
            <button type="button" className="close" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body">
            <form>
              <div className="form-group">
                <label htmlFor="recipient-name" className="col-form-label">
                  <p style={{ textAlign: 'left' }}>{user.extra ? user.extra[0].account_ref : null}</p>
                </label>
                <small onClick={editMacId}>click to edit macid</small>
                {/* <label htmlFor="recipient-name" className="col-form-label">Name:</label> */}
                {editMac ? (
                  <Fragment>
                    <input
                      type="text"
                      className="form-control"
                      id="recipient-name"
                      name="mac"
                      onChange={onChange}
                      value={mac}
                    />
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => editUserMac(mac, user.extra[0].account_ref)}
                      >
                        Submit Name
                      </button>
                    </div>
                  </Fragment>
                ) : null}
              </div>
            </form>
            {/* <div className="row"> */}

            {/* <form className="form-horizontal col-12"> */}
            {/*  <div className="form-group"> */}
            {/*    <div className="col-12 d-flex mb-3"> */}
            {/*      <div className="col-6"> */}
            {/*        <label className="m-0 p-0 copy-font">First Name</label> */}
            {/*        <p>{user.firstname}</p> */}
            {/*        <small onClick={() => setEditFirstName(true)}>Edit</small> */}
            {/*        {editFirstName ? ( */}
            {/*          <Fragment> */}
            {/*            <input */}
            {/*              type="text" */}
            {/*              placeholder="Duration" */}
            {/*              value="" */}
            {/*              className="form-control pl-2" */}
            {/*            /> */}
            {/*            <button on>Submit</button> */}
            {/*          </Fragment> */}
            {/*        ) : null} */}
            {/*      </div> */}
            {/*      <div className="col-6"> */}
            {/*        <label className="m-0 p-0 copy-font">Last Name</label> */}
            {/*        <p>{user.lastname}</p> */}
            {/*        <small onClick={() => setEditLastName(true)}>Edit</small> */}
            {/*        {editLastName ? ( */}
            {/*          <Fragment> */}
            {/*            <input */}
            {/*              type="text" */}
            {/*              placeholder="Duration" */}
            {/*              className="form-control pl-2" */}
            {/*            /> */}
            {/*            <button>submit</button> */}
            {/*          </Fragment> */}
            {/*        ) : null} */}
            {/*      </div> */}
            {/*    </div> */}

            {/*    <div className="col-12 d-flex mb-3"> */}
            {/*      <div className="col-6"> */}
            {/*        <label className="m-0 p-0 copy-font">Email</label> */}
            {/*        <p>{user.email}</p> */}
            {/*        <small onClick={() => setEditEmail(true)}>Edit</small> */}
            {/*        {editEmail ? ( */}
            {/*          <Fragment> */}
            {/*            <input */}
            {/*              type="text" */}
            {/*              placeholder="Duration" */}
            {/*              className="form-control pl-2" */}
            {/*            /> */}
            {/*            <p>submit</p> */}
            {/*          </Fragment> */}
            {/*        ) : null} */}
            {/*      </div> */}
            {/*      <div className="col-6"> */}
            {/*        <label className="m-0 p-0 copy-font"> */}
            {/*          Required action */}
            {/*        </label> */}
            {/*        <p>{user.mobile}</p> */}
            {/*        <small onClick={() => setEditPhone(true)}>Edit</small> */}
            {/*        {editPhone ? ( */}
            {/*          <Fragment> */}
            {/*            <input */}
            {/*              type="text" */}
            {/*              placeholder="Duration" */}
            {/*              className="form-control pl-2" */}
            {/*            /> */}
            {/*            <p>submit</p> */}
            {/*          </Fragment> */}
            {/*        ) : null} */}
            {/*      </div> */}
            {/*    </div> */}

            {/*    <div className="col-12 d-flex mb-3"> */}
            {/*      <div className="col-6"> */}
            {/*        <label className="m-0 p-0 copy-font">Mac</label> */}
            {/*        <p>{user.extra ? user.extra[0].account_ref : null}</p> */}
            {/*        <small onClick={() => setEditMac(true)}>Edit</small> */}
            {/*        {editMac ? ( */}
            {/*          <Fragment> */}
            {/*            <input */}
            {/*              type="text" */}
            {/*              placeholder="Duration" */}
            {/*              className="form-control pl-2" */}
            {/*            /> */}
            {/*            <button>submit</button> */}
            {/*          </Fragment> */}
            {/*        ) : null} */}
            {/*      </div> */}
            {/*    </div> */}
            {/*  </div> */}
            {/* </form> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

CustomerDetails.propTypes = {
  user: PropTypes.shape(),
  mac: PropTypes.string,
  onChange: PropTypes.func,
  editUserMac: PropTypes.func,
};
export default CustomerDetails;
