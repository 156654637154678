/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable import/prefer-default-export */
import { useReducer, useState } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import qs from 'qs';
import * as url from '../../../../../services/api/procurement/TracklistURL';
import * as types from '../../../../../constants/types/procurement/ProcurementTypes';
import * as load from '../../../../../constants/types/shared/LoadingTypes';
import { tracklistCategoryReducer } from '../../../../../reducers/procurement/tracklist/tracklistCategoryReducer';

export const useTracklistCategory = () => {
  const [
    { lists, tracklistPerCategory, pagesPerCategory, countPerCategory, isLoading, isFetching },
    dispatch,
  ] = useReducer(tracklistCategoryReducer, {
    lists: [],
    tracklistPerCategory: [],
    pagesPerCategory: 1,
    countPerCategory: 1,
    isLoading: false,
    isFetching: false,
  });

  const categoryState = {
    categoryName: '',
  };

  const [category, setCategory] = useState(categoryState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategory({ ...category, [name]: value });
  };

  const getTracklistCategory = async () => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.get(`${url.GET_TRACKLIST_CATEGORY_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_TRACKLIST_CATEGORY,
          payload: response.data,
        });
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    } finally {
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const addTracklistCategory = async (data) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(`${url.GET_TRACKLIST_CATEGORY_URL}`, qs.stringify(data), {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        toastr.success('Category Created!');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    } finally {
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const updateTracklistCategory = async (data, id) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.patch(`${url.GET_TRACKLIST_CATEGORY_URL}/${id}`, qs.stringify(data), {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data[0] === 1) {
        toastr.success('Update Successful');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    } finally {
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const searchPerCategory = async (id, data) => {
    dispatch({ type: load.FETCHING_STARTS });
    const config = {
      method: 'get',
      url: `${url.SEARCH_TRACKLIST_CATEGORY_URL}/${id}}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      data: qs.stringify(data),
    };
    axios(config)
      .then((response) => {
        if (response.data) {
          dispatch({
            type: types.GET_TRACKLIST_BY_CATEGORY,
            payload: {
              lists: response.data.list,
              count: response.data.count,
              pages: response.data.pages,
            },
          });
        }
      })
      .catch(() => {
        toastr.info('Something went wrong!');
        dispatch({ type: load.FETCHING_STOPS });
      });
  };

  return {
    lists,
    category,
    isLoading,
    isFetching,
    tracklistPerCategory,
    pagesPerCategory,
    countPerCategory,
    handleChange,
    getTracklistCategory,
    addTracklistCategory,
    updateTracklistCategory,
    // handlePageClick,
    searchPerCategory,
  };
};
