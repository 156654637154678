/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import profileImage from '../../../assets/img/icons/Profile.png';
import mailblast from '../../../assets/img/icons/mailblast.svg';
import Logout from '../../staff/shared/auth/Logout';
import VpSalesSideBar from './VpSalesSideBar';

const VpSalesNavBar = ({ currentPage, position }) => {
  const roleID = sessionStorage.getItem('roleId');
  return (
    <nav className="d-flex col-lg-12 p-2 top-header">
      <div className="mt-3 d-flex col-lg-12 p-0">
        <Link className="s-sidebar__trigger" to="#">
          <i className="fa fa-bars mr-3" />
        </Link>
        <VpSalesSideBar />

        <h4 className="col-lg-6 overview-part">
          {currentPage}
          <span className="sr-only">(current)</span>
        </h4>

        <div className="d-flex profile-mail">
          {Number(roleID) > 2 && (
            <div>
              <Link
                to="/super-admin/mail-blast"
                className="d-none d-md-flex"
                style={{ color: 'gray', textDecoration: 'none' }}
              >
                <img src={mailblast} alt="" className="logout mb-2 mr-2" />

                <h6 className="mt-2">
                  <strong>MailBlast</strong>
                </h6>
              </Link>
            </div>
          )}

          <div>
            <Link to="#" data-toggle="modal" data-target="#add-employee">
              <img src={profileImage} alt="" className="logout mr-3" />
            </Link>
          </div>

          <div className="mr-2 d-none d-md-block">
            <h6 className="small">{position}</h6>
          </div>

          <div className="">
            <Logout />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default VpSalesNavBar;
