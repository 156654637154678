import axios from 'axios';

const auth = sessionStorage.getItem('token');

const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${auth}`,
};
export const getDeviceData = async () => {
  try {
    const response = await axios.get('https://api.tizeti.com/api/v1/getDessMonitorDeviceTypes', { headers });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getDeviceVoltage = async (deviceType = 'Eleganza DataLogger', page = 1, limit = 10) => {
  try {
    const response = await axios.get(
      `https://api.tizeti.com/api/v1/getDeviceVoltageValues?device_name=${deviceType}&page=${page}&limit=${limit}`,
      {
        headers,
      },
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
