/* eslint-disable import/prefer-default-export */
import { API_URL } from '../../../../utils/config';

export const CREATE_PURCHASE_ORDER_URL = `${API_URL}/purchaseOrder`;
export const GET_STAFF_PURCHASE_ORDER_URL = `${API_URL}/purchaseOrder/own`;
export const GET_DEPT_PURCHASE_ORDER_URL = `${API_URL}/purchaseOrder/departmentManager`;
export const UPDATE_PURCHASE_ORDER_URL = `${API_URL}/purchaseOrder/manager`;
export const TRACK_PURCHASE_ORDER_URL = `${API_URL}/purchaseOrder/tracker`;
export const PROCUREMENT_PURCHASE_ORDER_DECISION_URL = `${API_URL}/purchaseOrder/procurement`;
export const ACCOUNT_PURCHASE_ORDER_URL = `${API_URL}/purchaseOrder/account`;
export const ACCOUNTS_APPROVED_PURCHASE_ORDER_URL = `${API_URL}/purchaseOrder/account_approved`;
