/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, SetStateAction, useContext } from 'react';
import edit from '../../../../../../assets/img/icons/edit.png';
import loader from '../../../../../../assets/img/icons/TizetiLoaderColored.gif';
import helpers from '../../../../../../utils/helpers';
import view from '../../../../../../assets/img/icons/view.png';
import ViewBasestation from './ViewBasestation';
// import { SubBasestationProps } from './types/SubBasestationProps';
import AppContext from '../../../../../../context/shared/AppContext';

const SubBasestationTable = () => {
  const { loading, data } = useContext(AppContext);
  const [one, setOne] = useState({
    address: '',
    created_at: '',
    date_of_commissioning: '',
    dimension: '',
    id: '',
    installer: '',
    latitude: '',
    longitude: '',
    name: '',
    no_of_subscribers: '',
    setup_amount: '',
  });
  const onClick = (params) => {
    setOne(params);
  };
  const tableData =
    data && data.length
      ? data.map((row, i) => (
          <tr className="text-nowrap" key={row.id}>
            <td>{i + 1}</td>
            <td>{row.name}</td>
            <td>{row.latitude}</td>
            <td>{row.longitude}</td>
            <td>{row.address}</td>
            <td>{row.dimension}</td>
            <td>{helpers.formatDateAndTime(row.date_of_commissioning)}</td>
            <td>{row.installer}</td>
            <td>{row.no_of_subscribers}</td>
            <td>{row.setup_amount}</td>
            <td>{helpers.formatDateAndTime(row.created_at)}</td>
            <td>
              <div className="text-center">
                <button
                  type="submit"
                  className="mr-2"
                  data-toggle="modal"
                  data-placement="right"
                  data-target="#view_access-point"
                  onClick={() => onClick(row)}
                >
                  <span className="view">
                    <img src={view} data-toggle="tooltip" alt="" data-placement="top" title="View" />
                  </span>
                </button>
                <button
                  type="submit"
                  className="mr-2"
                  data-toggle="modal"
                  data-placement="right"
                  data-target="#update_item"
                  // onClick={() => getItem(list)}
                >
                  <span className="view">
                    <img src={edit} data-toggle="tooltip" alt="" data-placement="top" title="Edit" />
                  </span>
                </button>
              </div>
              <ViewBasestation view={one} />
              {/* <EditRetrievalItem OneItem={one} /> */}
            </td>
          </tr>
        ))
      : 'no facebook site';
  return (
    <div className="card x-panel mt-2 px-0">
      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th className="text-nowrap">Latitude</th>
              <th>LOngitude</th>
              <th>Address</th>
              <th>Dimension</th>
              <th>Date of Comissioning</th>
              <th>Insttaller</th>
              <th>No of Susbcribers</th>
              <th className="text-nowrap">SetUp Amount</th>
              <th className="text-nowrap">Date Added</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <img src={loader} alt="loading gif" />
              </tr>
            ) : (
              tableData
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SubBasestationTable;
