/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import HotspotAdminLogo from '../../../../../../layouts/HotspotAdminLogo/HotspotAdminLogo';
import AdminSideBar from '../../../../../../layouts/HotspotAdminSideBar/AdminSideBar';
import CreateRetailer from './CreateRetailer';
import Logout from '../../../../../shared/auth/Logout';
import useRetailers from '../../../../../../../hooks/new-products/products/hotspot/retailers/useRetailers';
import ActivatedTable from './activated/ActivatedTable';
import PendingTable from './pending/PendingTable';

const HotSpotAdminRetailers = () => {
  const { loadingPending, loadingActivated, activated, pending } = useRetailers();
  //   const filterList = (e) => {
  //     const keyword = e.target.value;
  //     keyword.toLowerCase();

  //     if (keyword !== '') {
  //       const list = this.state.activatedList.filter((retailer) => {
  //         const email = retailer.email.toLowerCase();
  //         const phone = retailer.phone.toLowerCase();
  //         const businessName = retailer.businessName.toLowerCase();
  //         const accountid = retailer.accountid.toLowerCase();

  //         if (email.indexOf(keyword) !== -1) {
  //           return retailer;
  //         }
  //         if (phone.indexOf(keyword) !== -1) {
  //           return retailer;
  //         }
  //         if (businessName.indexOf(keyword) !== -1) {
  //           return retailer;
  //         }
  //         if (accountid.indexOf(keyword) !== -1) {
  //           return retailer;
  //         }
  //       });

  //       const listData = this.state.filteredPendingList.filter((pending) => {
  //         if (pending.email.toLowerCase().indexOf(keyword) !== -1) {
  //           return pending;
  //         }
  //         // if(activation.reference.toLowerCase().indexOf(keyword)!== -1){
  //         //     return activation;
  //         // }
  //         if (pending.businessName.toLowerCase().indexOf(keyword) !== -1) {
  //           return pending;
  //         }
  //         if (pending.phone.toLowerCase().indexOf(keyword) !== -1) {
  //           return pending;
  //         }
  //       });

  //       if (list.length <= 0) {
  //         this.setState({
  //           loading: 'There are currently no activated retailer(s)',
  //         });
  //       } else {
  //         this.setState({
  //           loading: '',
  //         });
  //       }

  //       if (listData.length <= 0) {
  //         this.setState({
  //           loading: 'There are currently no retailer awaiting activation',
  //         });
  //       } else {
  //         this.setState({
  //           loading: '',
  //         });
  //       }

  //       this.setState({
  //         filtered: list,
  //         filteredPendingList: listData,
  //         keyword: e.target.value,
  //       });
  //     } else {
  //       if (this.state.activatedList.length <= 0) {
  //         this.setState({
  //           loading: 'There are currently no retailer awaiting activation',
  //         });
  //       } else {
  //         this.setState({
  //           loading: '',
  //         });
  //       }

  //       this.setState({
  //         filtered: this.state.activatedList,
  //         filteredPendingList: this.state.pendingList,
  //         keyword,
  //       });
  //     }
  //   };

  return (
    <div className="container-fluid">
      <header className="topbar shade bg-white fixed-top">
        <nav className="navbar navbar-expand-lg navbar-light top-navbar fixed-top" id="nav">
          <HotspotAdminLogo />
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse mt-3 wifii" id="navbarText">
            <ul className="navbar-nav">
              <li className="nav-item active">
                <Link className="nav-link" to="#">
                  <h3>
                    Retailers
                    <span className="sr-only">(current)</span>
                  </h3>
                </Link>
              </li>
            </ul>
            <span className="navbar-text">
              <h6 className="admin">
                Admin
                <Logout />
              </h6>
            </span>
          </div>
        </nav>
      </header>

      <div className="all-sides col-lg-12 col-md-12 col-sm-12">
        <AdminSideBar />
        <div className="page-wrapper col-lg-10 col-md-9 col-sm-9">
          <div className="container-fluid">
            <div className="col-12 d-flex p-0" style={{ marginTop: '9%' }}>
              <div className="col-6 p-0 mr-auto">
                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                  <li className="nav-item shadow-sm mr-4">
                    <a
                      className="nav-link active"
                      id="pills-home-tab"
                      data-toggle="pill"
                      href="#pills-home"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Pending
                    </a>
                  </li>
                  <li className="nav-item  shadow-sm">
                    <a
                      className="nav-link"
                      id="pills-profile-tab"
                      data-toggle="pill"
                      href="#pills-profile"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Activated
                    </a>
                  </li>
                </ul>
              </div>

              {/* <div className="col-4 d-flex">
                <small className="mr-2">Search By:</small>
                <span>
                  <input
                    type="text"
                    className="form-control p-2"
                    // value={keyword}
                    name="keyword"
                    // onChange={(e) => filterList(e)}
                    placeholder="Phone number/Business name/Email"
                  />
                </span>
              </div> */}

              <div className="text-center">
                {/* // eslint-disable-next-line react/button-has-type */}
                <button className="btn btn-green" data-toggle="modal" data-target="#create-retailer">
                  Create Retailer
                </button>
              </div>
              <CreateRetailer />
            </div>

            <div className="col-12 tab-content shadow-sm p-0" id="pills-tabContent">
              <PendingTable fetch={loadingPending} pending={pending} />
              <ActivatedTable load={loadingActivated} activated={activated} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotSpotAdminRetailers;
