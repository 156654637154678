import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Calendar from 'react-calendar';
import Button from '@mui/material/Button';
import ChartTypeSelect from '../components/ChartTypeSelect';
import LineCharts from './LineChart';
import AreaChartsComponent from './AreaCharts';
import BarChartComponent from './BarCharts';

export function FormatDate(todayDate) {
  const formattedDate = new Date(todayDate);
  formattedDate.setMinutes(formattedDate.getMinutes() - formattedDate.getTimezoneOffset());
  const stringFormattedDate = formattedDate.toISOString().split('T')[0];
  return stringFormattedDate;
}
export default function DowntimeLogger() {
  const todayDate = new Date().toISOString().slice(0, 10);
  const rawTodayDate = new Date();
  const [fromDate, setFromDate] = React.useState(todayDate);
  const [toDate, setToDate] = React.useState(todayDate);
  const [calenderOpen, setCalenderOpen] = React.useState(false);
  const [chartType, setChartType] = React.useState('Bar-Chart');
  const whoRef = React.useRef(null);
  const valueRef = React.useRef(null);

  const handleDateChange = (date) => {
    if (whoRef.current !== null && whoRef.current === 'from') {
      setFromDate(FormatDate(date));
      setCalenderOpen(true);
    } else if (whoRef.current === 'to') {
      setToDate(FormatDate(date));
      setCalenderOpen(true);
    }
  };

  // function renderDateIfTabsValueChanges() {
  //   if (value === 0) {
  //     setFromDate(FormatDate(todayDate));
  //     setToDate(FormatDate(todayDate));
  //     return toDate;
  //   } else if (value === 7) {
  //     const last7Days = new Date(todayDate);
  //     last7Days.setDate(last7Days.getDate() - 7);
  //     setFromDate(FormatDate(last7Days));
  //     setToDate(FormatDate(todayDate));
  //     return toDate;
  //   } else {
  //     const last30Days = new Date(todayDate);
  //     last30Days.setDate(last30Days.getDate() - 30);
  //     setFromDate(FormatDate(last30Days));
  //     setToDate(FormatDate(todayDate));
  //     return toDate;
  //   }
  // }

  return (
    <Box>
      <Box
        sx={{
          mx: 2,
          my: 3,
          minHeight: '100vh',
          opacity: calenderOpen ? '0.3' : 1,
          pointerEvents: calenderOpen ? 'none' : 'auto',
        }}
      >
        <Box>
          <Typography variant="h4">Product Uptime</Typography>
        </Box>

        <BarChartComponent
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          todayDate={todayDate}
          handleDateChange={handleDateChange}
          whoRef={whoRef}
        />
      </Box>
      {calenderOpen && (
        <Box position={'absolute'} sx={{ left: '25%', top: '25%', transformOrigin: '-25%,-25%' }}>
          <Button variant="contained" color="error" onClick={() => setCalenderOpen(false)} sx={{ float: 'right' }}>
            Close
          </Button>
          <Calendar onChange={handleDateChange} value={rawTodayDate} maxDate={new Date()} />
        </Box>
      )}
    </Box>
  );
}
