/* eslint-disable import/prefer-default-export */
import { useState, useReducer, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import * as types from '../../../constants/types/noc/NocTypes';
import { NOC } from '../../../utils/config';
import { ticketsReducer } from '../../../reducers/tickets/ticketsReducer';
import * as load from '../../../constants/types/shared/LoadingTypes';
import * as url from '../../../services/api/noc/tickets/TicketsUrl';
import { EMPLOYEES_BY_DEPARTMENT_URL } from '../../../services/api/shared/employees/EmployeesUrl';

export const useTickets = () => {
  const ticketState = {
    baseStationId: '',
    type: '',
    severity: '',
    time: '',
    owner: '',
    cause: '',
  };

  const [ticket, setTicket] = useState(ticketState);
  const [personnel, setPersonnel] = useState('');
  const [status, setStatus] = useState('');
  const [{ employees, isLoading, tickets, isCreating }, dispatch] = useReducer(ticketsReducer, {
    employees: [],
    isLoading: false,
    isCreating: false,
    tickets: [],
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setTicket({
      ...ticket,
      [name]: value,
    });
  };

  const handleStatusFilterChange = (e) => {
    const { value } = e.target;
    if (value) {
      setStatus(value);
    }
  };

  const handlePersonnelChange = (e) => {
    const { value } = e.target;

    if (value) {
      setPersonnel(value);
    }
  };

  const getAllTickets = async () => {
    dispatch({
      type: load.FETCHING_STARTS,
    });
    try {
      const response = await axios.get(`${url.GET_ALL_TICKETS_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.FETCH_ALL_TICKETS,
          payload: response.data.users,
        });
      }
    } catch (e) {
      if (e) {
        dispatch({
          type: load.FETCHING_STOPS,
        });
      }
    }
  };

  const createTicket = async (data) => {
    dispatch({
      type: load.LOADING_STARTS,
    });

    try {
      const response = await axios.post(`${url.CREATE_TICKET_URL}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.id) {
        setTicket(ticketState);
        toast.success('Ticket Created');
        dispatch(getAllTickets());
        // dispatch({
        //   type: types.TICKET_CREATED,
        //   payload: response.data,
        // });
      }
    } catch (err) {
      dispatch({
        type: load.LOADING_STOPS,
      });
    }
  };

  const getEmployeesInNoc = async () => {
    try {
      const response = await axios.get(`${EMPLOYEES_BY_DEPARTMENT_URL}/${NOC}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        }
      });
      if (response.data) {
        dispatch({
          type: types.GET_ALL_EMPLOYEES_IN_NOC,
          payload: response.data,
        });
      }
    } catch (e) {
      toast.info('Unable to get employees in noc');
    }
  };

  const filterTickets = async (data) => {
    dispatch({
      type: load.LOADING_STARTS,
    });

    try {
      const response = await axios({
        method: 'post',
        url: `${url.FILTER_TICKETS_URL}`,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
        data,
      });

      if (response.data) {
        dispatch({
          type: types.FETCH_ALL_TICKETS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: load.LOADING_STOPS,
        });
      }
    } catch (e) {
      if (e) {
        dispatch({
          type: load.LOADING_STOPS,
        });
      }
    }
  };

  useEffect(() => {
    getEmployeesInNoc();
    getAllTickets();
  }, []);

  return {
    ticket,
    onChange,
    createTicket,
    employees,
    isLoading,
    handleStatusFilterChange,
    handlePersonnelChange,
    tickets,
    filterTickets,
    personnel,
    status,
    isCreating,
  };
};
