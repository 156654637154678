/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable import/prefer-default-export */
import { useReducer, useEffect, useState } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import qs from 'qs';
import { GET_TRACKLIST_URL } from '../../../../../services/api/procurement/TracklistURL';
import * as types from '../../../../../constants/types/procurement/ProcurementTypes';
import * as load from '../../../../../constants/types/shared/LoadingTypes';
import { tracklistReducer } from '../../../../../reducers/procurement/tracklist/tracklistReducer';

const useTracklist = () => {
  const [{ list, pages, count, isLoading }, dispatch] = useReducer(tracklistReducer, {
    list: [],
    count: 1,
    pages: 1,
    isLoading: false,
  });

  const itemState = {
    dateReceived: '',
    itemName: '',
    quantity: '',
    location: '',
    vendor: '',
    invoiceNumber: '',
    cost: '',
    currency: 'NGN',
    invoiceStatus: '',
    remainder: '',
  };

  const [commodity, setCommodity] = useState(itemState);
  const [page] = useState(1);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCommodity({ ...commodity, [name]: value });
    console.log(commodity);
  };

  const [selectedOption, setSelectedOption] = useState({
    tracklistCategory: '',
  });

  const handleSelect = (selected, action) => {
    setSelectedOption({ [action.name]: selected });
  };

  const getTracklist = async (currentPage) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.get(`${GET_TRACKLIST_URL}?page=${currentPage}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_TRACKLIST,
          payload: {
            list: response.data.list,
            pages: response.data.pages,
            count: response.data.count,
          },
        });
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const AddTracklist = async (data) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(`${GET_TRACKLIST_URL}`, qs.stringify(data), {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        toastr.success('Item Added!');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    } finally {
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const updateTracklist = async (data, id) => {
    const {
      itemName,
      seller,
      dateReceived,
      price,
      total,
      location,
      invoiceNumber,
      invoiceStatus,
      paymentStatus,
      remainder,
      deliveryStatus,
      classificationID,
    } = data;

    const result = {
      item: itemName,
      vendor: seller,
      date: dateReceived,
      cost: price,
      quantity: total,
      location: location,
      invoice_no: invoiceNumber,
      available_quantity: remainder,
      invoice_submission_status: invoiceStatus,
      payment_status: paymentStatus,
      delivery_status: deliveryStatus,
      resource_classification_id: classificationID,
    };
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.patch(`${GET_TRACKLIST_URL}/${id}`, qs.stringify(result), {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data[0] === 1) {
        toastr.success('Update Successful');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    } finally {
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const searchUsingKeyword = async (data) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(`${GET_TRACKLIST_URL}/search`, qs.stringify(data), {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_TRACKLIST,
          payload: {
            list: response.data.rows,
            count: response.data.count,
          },
        });
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  useEffect(() => {
    getTracklist(page);
  }, []);

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    getTracklist(selectedPage);
  };

  return {
    list,
    pages,
    count,
    commodity,
    isLoading,
    handleChange,
    handleSelect,
    selectedOption,
    getTracklist,
    AddTracklist,
    updateTracklist,
    handlePageClick,
    searchUsingKeyword,
  };
};
export default useTracklist;
