import axios from 'axios';
import toastr from 'toastr';
import { toast } from 'react-toastify';
import {
  SEARCH_WIFICALL_CUSTOMERS_WITH_EMAIL_URL,
  SEARCH_WIFICALL_CUSTOMERS_WITH_PHONE_URL,
} from '../../../services/api/wificall/CustomersUrl';
import { GET_WIFICALL_USERS, GET_WIFICALL_USERS_COUNT } from '../../../constants/types/wificall/WificallTypes';
import { WIFICALL_USERS_URL } from '../../../services/api/wificall/WificallUrl';
import { WIFICALL_ADMIN_EDIT_USER_URL } from '../../../services/api/wificall/UsersUrl';

export const getCustomers = () => async (dispatch) => {
  try {
    const response = await axios.get(`${WIFICALL_USERS_URL}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      dispatch({
        type: GET_WIFICALL_USERS,
        payload: {
          customers: response.data.users,
          pages: response.data.pages,
          data: response.data,
        },
      });
    }
  } catch (e) {
    toastr.info('Something went wrong!');
  }
};

export const getWificallUsersCount = () => async (dispatch) => {
  const response = await axios.get(WIFICALL_USERS_URL, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
  });

  if (response.data.users) {
    dispatch({
      type: GET_WIFICALL_USERS_COUNT,
      payload: response.data.count,
    });
  }
};

export const searchWithEmail = (data) => async (dispatch) => {
  try {
    const response = await axios.post(`${SEARCH_WIFICALL_CUSTOMERS_WITH_EMAIL_URL}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      dispatch({
        type: GET_WIFICALL_USERS,
        payload: {
          data: { users: response.data },
          customers: response.data,
          pages: 0,
        },
      });
    }

    if (response.data.length < 1) {
      toastr.info('No user with that email exists');
    }
  } catch (e) {
    toastr.info('Something went wrong!');
  }
};

export const searchWithPhone = (data) => async (dispatch) => {
  try {
    const response = await axios.post(`${SEARCH_WIFICALL_CUSTOMERS_WITH_PHONE_URL}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    if (response.data) {
      dispatch({
        type: GET_WIFICALL_USERS,
        payload: {
          data: { users: response.data },
          customers: response.data,
          pages: 0,
        },
      });
    }
  } catch (e) {
    toastr.info('Something went wrong!');
  }
};

export const getPaginatedUsers = (data) => async (dispatch) => {
  try {
    const response = await axios.get(`${WIFICALL_USERS_URL}/${data}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      dispatch({
        type: GET_WIFICALL_USERS,
        payload: {
          customers: response.data.users,
          pages: response.data.pages,
        },
      });
    }
  } catch (e) {
    toastr.info('Something went wrong!');
  }
};

export const editUser = (data, id) => {
  return async () => {
    try {
      const response = await axios.patch(`${WIFICALL_ADMIN_EDIT_USER_URL}/${id}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        toast.success('Details Changed!');
      }
    } catch (e) {
      toastr.info('Something went wrong!');
    }
  };
};
