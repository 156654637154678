import { useState, useReducer } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { CREATE_REQUEST_URL } from '../../../../services/api/administration/requests/RequestsUrl';
import * as types from '../../../../constants/types/administration/RequestTypes';
import { requestsReducer } from '../../../../reducers/administration/requests/requestsReducer';

// eslint-disable-next-line import/prefer-default-export
export const useCategory = () => {
  const [category, setCategory] = useState('');
  const [{ isLoadingCategory }, dispatch] = useReducer(requestsReducer, {
    isLoadingCategory: false,
  });

  const onFormChange = (e) => {
    const { value } = e.target;
    setCategory(value);
  };

  const createCategory = async (value) => {
    dispatch({ type: types.START_CREATE_CATEGORY });

    try {
      const response = await axios.post(`${CREATE_REQUEST_URL}`, value, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      const { data } = response;
      // console.log(data);
      if (data.message === 'successfull') {
        toast.success('Category added successfully!');
        dispatch({ type: types.STOP_CREATE_CATEGORY, payload: data.response });
        setCategory('');
      }
    } catch (e) {
      if (e) {
        dispatch({ type: types.STOP_CREATE_CATEGORY });
      }
    }
  };

  return { category, onFormChange, createCategory, isLoadingCategory };
};
