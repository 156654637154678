import React, { Component } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import * as $ from 'jquery';
import { API_URL } from '../../../../../utils/config';

window.jQuery = window.$ = $;

class AddInventory extends Component {
  constructor() {
    super();

    this.state = {
      name: '',
      manufacturer: '',
      model: '',
      serialnumber: '',
      description: '',
      macid: '',
      firmware: '',
      vendor: '',
      date: '',
      warranty: '',
      configversion: '',
      loading: false,
      /*      installer: '' */ add: false,
      success: false,
      working: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    this.setState({
      loading: true,
    });
    e.preventDefault();

    axios({
      method: 'post',
      url: `${API_URL}/inventory`,
      data: {
        configVersion: this.state.configversion,
        /* employee_id: this.state.installer, */
        date_of_purchase: this.state.date,
        firmware: this.state.firmware,
        description: this.state.description,
        macid: this.state.macid,
        manufacturer: this.state.manufacturer,
        model: this.state.model,
        name: this.state.name,
        serialNumber: this.state.serialnumber,
        vendor: this.state.vendor,
        warranty: this.state.warranty,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
      .then((res) => {
        if (res.data.id) {
          window.location.reload();
          //                this.props.newInventory(res.data);
          toastr.success('Operation Successful');
          //              /*$('#add-inventories').hide();*/
          $('#add-inventories').hide();
        }
      })
      .catch((err) => {});
  }

  render() {
    const { loading } = this.state;

    return (
      <div className="modal-body">
        <form onSubmit={this.onSubmit} className="form-horizontal form-material">
          <div className="form-group invent">
            <div className="col-md-12 mb-1 d-flex">
              <div className="col-lg-6">
                <label className="copy-font">Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Item name"
                  name="name"
                  onChange={this.onChange}
                  required
                />
              </div>

              <div className="col-lg-6">
                <label className="copy-font">Manufacturer</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Manufacturer name"
                  name="manufacturer"
                  onChange={this.onChange}
                  required
                />
              </div>
            </div>

            <div className="col-md-12 mb-1 d-flex">
              <div className="col-lg-6">
                <div className="mb-4">
                  <label className="copy-font">Model</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Item Model"
                    name="model"
                    onChange={this.onChange}
                    required
                  />
                </div>

                <div>
                  <label className="copy-font">Serial number</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Item serial number"
                    name="serialnumber"
                    onChange={this.onChange}
                  />
                </div>
              </div>

              <div className="col-lg-6">
                <label className="copy-font">Description</label>
                <textarea name="description" className="form-control" rows="5" cols="20" onChange={this.onChange} />
              </div>
            </div>

            <div className="col-md-12 mb-1 d-flex">
              <div className="col-lg-6">
                <label className="copy-font">MAC ID</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Item MAC ID is a string"
                  name="macid"
                  onChange={this.onChange}
                  required
                />
              </div>

              <div className="col-lg-6">
                <label className="copy-font">Firmware</label>
                <input
                  name="firmware"
                  type="text"
                  className="form-control"
                  placeholder="Item Firmware"
                  onChange={this.onChange}
                />
              </div>
            </div>

            <div className="col-md-12 mb-1 d-flex">
              <div className="col-lg-6">
                <label className="copy-font">Vendor</label>
                <input
                  type="text"
                  name="vendor"
                  className="form-control"
                  placeholder="Item Vendor"
                  onChange={this.onChange}
                  required
                />
              </div>

              <div className="col-lg-6">
                <label className="copy-font">Date of purchase</label>
                <input
                  name="date"
                  type="date"
                  className="form-control"
                  onChange={this.onChange}
                  placeholder=""
                  required
                />
              </div>
            </div>

            <div className="col-md-12 mb-1 d-flex">
              <div className="col-lg-6">
                <label className="copy-font">Warranty</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Item Warranty"
                  name="warranty"
                  onChange={this.onChange}
                  required
                />
              </div>

              <div className="col-lg-6">
                <label className="copy-font">Config Version</label>
                <input
                  type="text"
                  name="configversion"
                  className="form-control"
                  placeholder="Item Config Version"
                  onChange={this.onChange}
                  required
                />
              </div>
            </div>

            {/* <div className="col-md-12 mb-1 d-flex">
                            <div className="col-lg-6">
                                <label>Installer</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Item Installer is INTEGER"
                                    name="installer"
                                    onChange={this.onChange}
                                    required
                                />
                            </div>
                        </div> */}
          </div>
          <div className="text-center">
            {loading ? (
              <button className="btn btn-green copy-font" id="login">
                <i className="fa fa-circle-o-notch fa-spin" />
              </button>
            ) : (
              <button className="btn btn-green">Add Inventory</button>
            )}
          </div>
        </form>
      </div>
    );
  }
}

export default AddInventory;
