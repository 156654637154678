import React, {Component} from 'react';
import moment from "moment";
import toastr from 'toastr';
import {
    createIncident,
    getIncidentReports
} from "../../../../../actions/noc/incident-reports/IncidentReportActions";
import { connect } from "react-redux";
import axios from "axios";
import { ADD_INCIDENT_REPORT_URL } from "../../../../../services/api/noc/incident-reports/IncidentReportUrl";

class AddIncidentReport extends Component {

    constructor(props) {
        super(props);

        this.state = {

            description: '',
            affected: '',
            impact: '',
            source: '',
            start: '',
            end: '',
            root: '',
            fix: '',
            changes: '',
            confirmation: '',
            cause: '',
            prevention: '',
            additional: '',
            team: '',
            loading: false

        };

        this.onChange = this.onChange.bind(this);
        this.addIncident = this.addIncident.bind(this);

    }

    submitForm = () => {

        const {description, affected, impact, source, start, end, root, fix, changes, confirmation, cause, prevention, additional, team } = this.state;

        return (

          description.length > 0 &&
          affected.length > 0 &&
          impact.length > 0 &&
          source.length > 0 &&
          start.length > 0 &&
          end.length > 0 &&
          root.length > 0 &&
          fix.length > 0 &&
          changes.length > 0 &&
          confirmation.length > 0 &&
          cause.length > 0 &&
          prevention.length > 0 &&
          additional.length > 0 &&
          team.length > 0

        )


    };

    addIncident = async e => {

        e.preventDefault();

        this.setState({

            loading: true

        });

        const {description, affected, impact, source, start, end, root, fix, changes, cause, confirmation, prevention, additional, team } = this.state;

        if(confirmation === ""){

            toastr.info("Please enter resolution status!")

        }

        const incident = {

            description,
            system_affected : affected,
            impact ,
            incident_notification_source : source,
            duration_start_time : start,
            duration_end_time : end,
            root_cause : cause,
            fix_actions : fix,
            changes_made : changes,
            confirmation_of_resolution : confirmation,
            cause_of_incident : cause,
            incidence_prevention : prevention,
            additional_action : additional,
            resolution_team : team,
            date : moment(Date.now()).format("YYYY-MM-DD"),

        };

        //this.props.createIncident(incident);

        try {

            const response = await axios.post(`${ADD_INCIDENT_REPORT_URL}`, incident, {

                headers: {

                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`

                }

            });

            if(response.data.id){

                this.setState({

                    modalShow: !this.state.modalShow
                }, (props) => {
                    // this.props.modalshow = this.state.modalShow;
                    console.log("hello", this.state.modalShow, this.props.modalshow(this.state.modalShow));
                });
                this.props.getIncidentReports();

                toastr.success(
                  'Incident created successfully', {
                      timeOut: 2000,
                      fadeOut: 2000
                  }
                );

            } else {

                toastr.info("Something went wrong");

            }

        } catch (e) {

            console.log(e);

        }

    };

    onChange = e => this.setState({

        [e.target.name]: e.target.value

    });

    componentWillReceiveProps(nextProps, nextContext) {

        if(nextProps.incident.id){

            this.setState({

                modalShow: !this.state.modalShow
            }, (props) => {
                // this.props.modalshow = this.state.modalShow;
                console.log("hello", this.state.modalShow, this.props.modalshow(this.state.modalShow));
            });
            this.props.getIncidentReports();

            toastr.success(
              'Incident created successfully', {
                  timeOut: 2000,
                  fadeOut: 2000
              }
            );

        }

    }

    render() {

        const {description, affected, impact, source, start, end, root, fix, changes, cause, prevention, additional, team } = this.state;

        const isEnabled = this.submitForm();

        return (
            <div className="modal-body">
                <div className="row">
                    <form className="form-horizontal col-12" onSubmit={this.addIncident}>
                        <div className="form-group">
                            <div className="col-12 d-flex mb-3">
                                <div className="col-6">
                                    <label className="m-0 p-0 copy-font">Description</label>
                                    <input type="text"
                                           placeholder="Incident description"
                                           className="form-control pl-2"
                                           name="description"
                                           value={description}
                                           onChange={this.onChange}
                                    />
                                </div>

                                    <div className="col-6">
                                        <label className="m-0 p-0 copy-font">Notification source</label>
                                        <input type="text"
                                               placeholder="Notification source"
                                               className="form-control pl-2"
                                               name="source"
                                               value={source}
                                               onChange={this.onChange}
                                        />
                                    </div>
                            </div>

                            <div className="col-12 d-flex mb-3">
                                <div className="col-6">
                                    <label className="m-0 p-0 copy-font">System affected</label>
                                    <input type="text"
                                           placeholder="System affected"
                                           className="form-control pl-2"
                                           name="affected"
                                           value={affected}
                                           onChange={this.onChange}
                                    />
                                </div>
                                <div className="col-6">
                                    <label className="m-0 p-0 copy-font">Impact</label>
                                    <input type="text"
                                           placeholder="Incident impact"
                                           className="form-control pl-2"
                                           name="impact"
                                           value={impact}
                                           onChange={this.onChange}
                                    />
                                </div>
                            </div>

                            <div className="col-12 d-flex mb-3">
                                <div className="col-6">
                                    <label className="m-0 p-0 copy-font">Start Duration</label>
                                    <input type="time"
                                           placeholder="Start Duration"
                                           className="form-control pl-2"
                                           name="start"
                                           value={start}
                                           onChange={this.onChange}
                                    />
                                </div>

                                <div className="col-6">
                                    <label className="m-0 p-0 copy-font">End Time</label>
                                    <input type="time"
                                           placeholder="Duration"
                                           className="form-control pl-2"
                                           name="end"
                                           value={end}
                                           onChange={this.onChange}
                                    />
                                </div>

                            </div>

                            <div className="col-12 d-flex mb-3">
                                <div className="col-6">
                                    <label className="m-0 p-0 copy-font">Root cause</label>
                                    <input type="text"
                                           placeholder="Root cause"
                                           className="form-control pl-2"
                                           name="root"
                                           value={root}
                                           onChange={this.onChange}
                                    />
                                </div>
                                <div className="col-6">
                                    <label className="m-0 p-0 copy-font">Fix actions</label>
                                    <input type="text"
                                           placeholder="Fix actions"
                                           className="form-control pl-2"
                                           name="fix"
                                           value={fix}
                                           onChange={this.onChange}
                                    />
                                </div>
                            </div>

                            <div className="col-12 d-flex mb-3">
                                <div className="col-6">
                                    <label className="m-0 p-0 copy-font">Changes made</label>
                                    <input type="text"
                                           placeholder="Changes made"
                                           className="form-control pl-2"
                                           name="changes"
                                           value={changes}
                                           onChange={this.onChange}
                                    />
                                </div>
                                <div className="col-6">
                                    <label className="m-0 p-0 copy-font">Confirmation</label>
                                    <select name="confirmation" onChange={this.onChange} className="form-control">
                                        <option value="">Choose One</option>
                                        <option value="true">Resolved</option>
                                        <option value="false">Not Resolved</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-12 d-flex mb-3">
                                <div className="col-6">
                                    <label className="m-0 p-0 copy-font">Cause of incident</label>
                                    <input type="text"
                                           placeholder="Cause of incident"
                                           className="form-control pl-2"
                                           name="cause"
                                           value={cause}
                                           onChange={this.onChange}
                                    />
                                </div>
                                <div className="col-6">
                                    <label className="m-0 p-0 copy-font">Prevention</label>
                                    <input type="text"
                                           placeholder="Prevention"
                                           className="form-control pl-2"
                                           name="prevention"
                                           value={prevention}
                                           onChange={this.onChange}
                                    />
                                </div>
                            </div>

                            <div className="col-12 d-flex mb-3">
                                <div className="col-6">
                                    <label className="m-0 p-0 copy-font">Additional actions</label>
                                    <input type="text"
                                           placeholder="Additional actions"
                                           className="form-control pl-2"
                                           name="additional"
                                           value={additional}
                                           onChange={this.onChange}
                                    />
                                </div>
                                <div className="col-6">
                                    <label className="m-0 p-0 copy-font">Resolution Team</label>
                                    <input type="text"
                                           placeholder="Staff name"
                                           className="form-control pl-2"
                                           name="team"
                                           value={team}
                                           onChange={this.onChange}
                                    />
                                </div>
                            </div>

                            <div className="text-center">
                                <button className="btn btn-green ml-2 shadow-md" disabled={!isEnabled}>
                                    <i className="fa fa-spinner"></i>
                                    Add Report
                                </button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({

    incident: state.incidentReport.incident

});

export default connect(mapStateToProps, {createIncident, getIncidentReports})(AddIncidentReport);