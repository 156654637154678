export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const GET_USER = 'GET_USER';
export const START_FORGOT_PASSWORD =
  'START_FORGOT_PASSWORD';
export const STOP_FORGOT_PASSWORD = 'STOP_FORGOT_PASSWORD';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const START_RESET_PASSWORD = 'START_RESET_PASSWORD';
export const STOP_RESET_PASSWORD = 'STOP_RESET_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
