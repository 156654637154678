/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
// import { CambiumLicenseKeysProps } from './interfaces/CambiumLicenseKeysProps';

const ViewCambiumLicenseKeys = ({ view }) => {
  return (
    <div id="view_access-point" className="modal fade mt-5" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title my-2">Details</h4>
            <button type="button" className="mr-2 close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div className="modal-body detail p-0">
            <div className="row">
              <div className="col-lg-12 d-flex">
                <div className="col-lg-5 detail-label1">
                  <label className="mt-1">Entitlement Activation Code</label>
                  <label className="mt-1">Ethernet Mac Address</label>

                  <label className="mt-1">Basestation</label>
                </div>
                <div className="col-lg-7 detail-result1 item-font">
                  <p className="mt-4 mb-2">{view.entitlement_activation_code}</p>
                  <p className="mb-2">{view.ethernet_mac_address}</p>

                  <p className="mb-2">{view.basestation}</p>
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-5 detail-label3">
                  <label>License Keys</label>
                  {/* <label>Public IP Block</label> */}
                  {/* <label>Assigned Public IP</label> */}
                </div>
                <div className="col-lg-7 detail-result2 item-font">
                  <p className="mt-4 mb-2">{view.license_keys}</p>
                  {/* <p className="mb-2">{view.device_name}</p> */}
                  {/* <p className="mb-2">{view.device_type}</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewCambiumLicenseKeys;
