/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/button-has-type */
import React from 'react';
import qs from 'qs';
import PropTypes from 'prop-types';
import { showDepartment } from '../../../../../utils/lib';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import { useCustomer } from '../../../../../hooks/shared/customers/useCustomers';

const DeleteAccount = ({ username }) => {
  const { deleteCustomerAccount, onDeleteChange, reason, deleting } = useCustomer();
  const { user } = useUser();

  const { department_id } = user;
  const onSubmit = (e) => {
    e.preventDefault();

    const data = qs.stringify({
      action: 'deleteCustomerDevice',
      staffEmailAddress: 'ifeanyi.okonkwo@tizeti.com',
      department: showDepartment(department_id),
      role_id: Number(sessionStorage.getItem('roleId')),
      macaddress: username,

      reason,
    });

    deleteCustomerAccount(data);
  };

  const isEnabled = () => reason.length > 0;
  return (
    <div
      id="deleteAccount"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Delete Customer
            </h4>
            <button type="button" className="close custom-close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body">
            <form className="form-horizontal" onSubmit={onSubmit}>
              <div className="form-group">
                <div className="col-12 mb-3 d-flex">
                  <input type="text" className="form-control" placeholder={username} value={username} readOnly />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Reason for deleting..."
                    name="reason"
                    value={reason}
                    onChange={onDeleteChange}
                  />
                </div>

                {deleting ? (
                  <div className="col-12 mb-3 text-center">
                    <button className="btn btn-green" disabled={deleting}>
                      Deleting...
                    </button>
                  </div>
                ) : (
                  <div className="col-12 mb-3 text-center">
                    <button className="btn btn-green" disabled={!isEnabled()}>
                      Delete Customer
                    </button>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

DeleteAccount.propTypes = {
  username: PropTypes.string,
};

export default DeleteAccount;
