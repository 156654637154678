/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../../../utils/loader/loadingHelper';
import AddRetailerComment from './AddRetailerComment';
import UpdateXWFRetailerComment from './UpdateXWFRetailerComment';
import helpers from '../../../../../../utils/helpers';
import { useUserProfile } from '../../../../../../hooks/shared/user/useUserProfile';

const XWFRetailerComment = ({ oneRetailer: { id }, retailerComments, isFetching }) => {
  const { getUserProfile, profile } = useUserProfile();

  useEffect(() => {
    getUserProfile();
  }, [getUserProfile]);

  // console.log(profile.email);

  const colSpan = '10';
  const [oneComment, setOneComment] = useState({
    comment: '',
    comment_status: '',
    created_at: '',
    added_by: '',
  });

  const getOneComment = (item) => setOneComment(item);

  const tableData =
    retailerComments && retailerComments.length ? (
      retailerComments.map((retailerComment, i) => {
        const { comment, comment_status, created_at, added_by = profile.email } = retailerComment;
        return (
          <tr className="" key={i}>
            <td>{i + 1}</td>
            <td>{comment_status}</td>
            <td>{comment}</td>
            <td>{added_by}</td>
            <td>{helpers.formatDate(created_at)}</td>
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan={colSpan} text="No Comment(s)" />
    );

  return (
    <>
      <div id="getRetailerComment" className="modal fade mt-5">
        <div className="modal-dialog modal-lg large-modal">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title update_asset">Retailer Comments</h4>
              <button type="button" className="btn btn-outline-info btn-circle btn-close" data-dismiss="modal">
                x
              </button>
            </div>
            <div className="modal-body mb-4 p-0">
              <AddRetailerComment retailerID={id} oneComment="" operation="add" />
              <div>
                <div className="table-responsive content col-12">
                  <table className="table table-striped">
                    <thead>
                      <tr className="">
                        <th> S/N</th>
                        <th>Comment Status</th>
                        <th>Comment</th>
                        <th>Added By</th>
                        <th>Created At</th>
                      </tr>
                    </thead>
                    <tbody>{isFetching ? <TizetiGIFLogoTable colSpan={colSpan} /> : tableData}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UpdateXWFRetailerComment retailerID={id} oneComment={oneComment} />
    </>
  );
};

export default XWFRetailerComment;
