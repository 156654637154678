import React, { Component } from 'react';
import toastr from 'toastr';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Logout from '../../../../../shared/auth/Logout';
import HotspotAdminLogo from '../../../../../../layouts/HotspotAdminLogo/HotspotAdminLogo';
import AdminSideBar from '../../../../../../layouts/HotspotAdminSideBar/AdminSideBar';
import RetailerFundRows from '../ui-components/RetailerFundRows';
import { GET_ALL_RETAILERS_URL } from '../../../../../../../services/api/hotspot/HotspotUrls';

class HotSpotAdminFundWallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      selectedRetailer: '',
      profileBusinessName: '',
      profileEmail: '',
      profileFullName: '',
      profilePhone: '',
      profileLat: '',
      profileLng: '',
      retailerResponse: [],
      filtered: [],
    };
  }

  filterList(e) {
    const keyword = e.target.value;

    if (keyword !== '') {
      const list = this.state.retailerResponse.filter((retailer) => {
        if (retailer.fullname.indexOf(keyword) !== -1) {
          return retailer;
        }
        if (retailer.email.indexOf(keyword) !== -1) {
          return retailer;
        }
        if (retailer.phone.indexOf(keyword) !== -1) {
          return retailer;
        }
        if (retailer.businessName.indexOf(keyword) !== -1) {
          return retailer;
        }
        if (retailer.accountid.indexOf(keyword) !== -1) {
          return retailer;
        }
      });

      // if (listData.length<=0){
      //     this.setState({
      //         loading : "There are currently no retailer awaiting activation"
      //     })
      // }
      // else{
      //     this.setState({
      //         loading : ""
      //     })
      // }

      this.setState({
        filtered: list,
        keyword: e.target.value,
      });
    } else {
      // if (this.state.activatedList.length<=0){
      //     this.setState({
      //         loading : "There are currently no retailer awaiting activation"
      //     })
      // }
      // else{
      //     this.setState({
      //         loading : ""
      //     })
      // }

      this.setState({
        filtered: this.state.retailerResponse,
        keyword,
      });
    }
  }

  componentDidMount() {
    // console.log(URLS.base_URL+URLS.fetch_Retailers);
    axios({
      method: 'get',
      url: `${GET_ALL_RETAILERS_URL}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      }
    })
      .then((response) => {
        console.log(response);
        if (response.data.status) {
          this.setState({
            retailerResponse: response.data.data,
            filtered: response.data.data,
          });
        }
      })
      .catch((error) => {
        toastr.error('Please check your internet connection');
        // toastr.error(error)
      });
  }

  render() {
    return (
      <div className="container-fluid">
        <header className="topbar shade bg-white fixed-top">
          <nav className="navbar navbar-expand-lg navbar-light top-navbar fixed-top" id="nav">
            <HotspotAdminLogo />
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse mt-3 wifii" id="navbarText">
              <ul className="navbar-nav">
                <li className="nav-item active">
                  <Link className="nav-link" to="#">
                    <h3>
                      Fund Wallet
                      <span className="sr-only">(current)</span>
                    </h3>
                  </Link>
                </li>
              </ul>
              <span className="navbar-text">
                <h6 className="admin">
                  Admin <Logout />
                </h6>
              </span>
            </div>
          </nav>
        </header>

        <div className="all-sides col-lg-12 col-md-12 col-sm-12">
          <AdminSideBar />
          <div className="page-wrapper col-lg-10 col-md-9 col-sm-9">
            <div className="container-fluid">
              <div className="col-12 d-flex p-0" style={{ marginTop: '9%' }}>
                <div className="col-6 d-flex p-0">
                  <small className="mr-2">Search By:</small>
                  <span>
                    <input
                      type="text"
                      className="form-control p-2"
                      placeholder="Name"
                      value={this.state.keyword}
                      onChange={(e) => this.filterList(e)}
                    />
                  </span>
                </div>
              </div>
              <div className="mt-3 shadow-sm p-3">
                <table className="table">
                  <thead>
                    <tr className="copy-font">
                      <th scope="col">#</th>
                      <th scope="col">Full Name</th>
                      <th scope="col">Business Name</th>
                      <th scope="col">Phone Number </th>
                      <th scope="col">Email Address</th>
                      <th scope="col">Balance</th>
                      <th scope="col">Accountid</th>
                      <th scope="col">Fund Wallet</th>
                    </tr>
                  </thead>

                  <tbody>
                    <RetailerFundRows retailerAccount={this.state.filtered} />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HotSpotAdminFundWallet;
