/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import Moment from 'react-moment';

const ViewClusteredInstallation = ({
  item: { customer_name, date, address, zone, distance, unit, latitude, longitude, status },
}) => {
  return (
    <div id="viewCluster" className="modal fade mt-5" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title my-2">Installation Details</h4>
            <button type="button" className="mr-2 close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div className="modal-body detail p-0">
            <div className="row">
              <div className="col-lg-12 d-flex">
                <div className="col-lg-5 detail-label1">
                  <label className="mt-1">Name</label>
                  <label>Zone</label>
                  <label>Address</label>
                </div>
                <div className="col-lg-7 detail-result1 item-font">
                  <p className="mt-4 mb-2">{customer_name}</p>
                  <p className="mb-2">{zone}</p>
                  <p className="mb-2">{address}</p>
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-5 detail-label2">
                  <label>Distance</label>
                  <label className="mt-1">Latitude</label>
                  <label className="mt-1">Longitude</label>
                </div>
                <div className="col-lg-7 detail-result2 item-font">
                  <p className="mt-4 mb-1 text-capitalize">{`${distance} ${unit}`}</p>
                  <p className="mb-2">{latitude}</p>
                  <p className="mb-2">{longitude}</p>
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-5 detail-label1">
                  <label className="mt-1">Status</label>
                  <label>Date</label>
                </div>
                <div className="col-lg-7 detail-result1 item-font">
                  <p className="mt-4 mb-2 text-uppercase">{status}</p>
                  <p className="mb-2">
                    <Moment format="DD-MMM-YYYY" date={date} />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewClusteredInstallation;
