import React, { useContext, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { stringify } from 'qs';
import AppContext from '../../../../../context/shared/AppContext';
import CustomButton from '../../forms/CustomButton';
import CustomInput from '../../forms/CustomInput';
import legacy from '../../../../../services/api/axios';

const DownloadTrackerData = () => {
  const { onChange, params, data } = useContext(AppContext);
  const [downloading, setDownloading] = useState(false);
  const [activeData, setActiveData] = useState([]);
  const [expiredData, setExpiredData] = useState([]);
  const [subcribtionData, setSubcribtionData] = useState([]);
  const csvLink = useRef();
  const activeLink = useRef();
  const expiredLink = useRef();

  const { date, type, country } = params;
  const validateSubmit = () => {
    return date.length > 0;
  };
  const downloadData = async () => {
    setDownloading(true);
    const param = {
      country,
      type,
      date,
      action: 'tizeti_asset_retension_daily_chart_download',
    };
    await legacy.post('/index.php', stringify(param)).then((response) => {
      if (type === 'Active') {

        console.log('response', response.data);

        setActiveData(response.data.Active_users);
      } else {
        setExpiredData(response.data.Expiration_users);
      }
      setDownloading(false);
      setSubcribtionData(response.data.subscription_users);
    });

    console.log('active data', activeData);
    console.log('expired data', expiredData);
    console.log('subcribtionData data', subcribtionData);
    if (type === 'Active') {
      activeLink.current.link.click();
    } else {
      expiredLink.current.link.click();
    }
    csvLink.current.link.click();
  };

  useEffect(() => {
    console.log('active data', activeData);
    console.log('expired data', expiredData);
    console.log('subcribtionData data', subcribtionData);
  }, [subcribtionData])

  return (
    <div className="col-12 mb-3">
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="col-12">
          <div className="col-12 d-flex">
            <div className="form-check mr-2">
              <CustomInput
                name="date"
                readOnly={false}
                placeholder=""
                type="date"
                onChange={onChange}
                value={date}
                classname="form-control border border-info py-2"
              />
            </div>

            <div className=" text-center ml-3">
              <CustomButton
                classname="btn btn-green"
                disabled={downloading || !validateSubmit()}
                onClick={() => downloadData()}
                name={downloading ? `Downloading ${type} Customers...` : `Download ${type} Customers`}
              />
              <CSVLink
                data={subcribtionData}
                filename={`${country} subscription users.csv`}
                className="hidden"
                ref={csvLink}
                target="_blank"
              />
              <CSVLink
                filename={`${country} Users_total_active.csv`}
                className="hidden"
                ref={activeLink}
                target="_blank"
                data={activeData}
              />
              <CSVLink
                filename={`${country} Expired_Users_total_expired.csv`}
                className="hidden"
                ref={expiredLink}
                target="_blank"
                data={expiredData}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DownloadTrackerData;
