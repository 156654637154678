/* eslint-disable import/prefer-default-export */
import * as types from '../../../constants/types/asset-team/AssetTeamTypes';
import * as load from '../../../constants/types/shared/LoadingTypes';

export const assetTeamTableReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case load.LOADING_STARTS:
      return {
        ...state,
        isLoading: true,
      };

    case load.LOADING_STOPS:
      return {
        ...state,
        isLoading: false,
        address: '',
      };

    case types.GET_ADDRESS:
      return {
        ...state,
        address: payload,
        isLoading: false,
      };

    default:
      return state;
  }
};
