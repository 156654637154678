import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { getDeviceVoltage } from '../../../../../actions/device-data-logger/DeviceLogger';

const columns = [
  { id: 'serial', label: 'S/N', minWidth: 50 },
  { id: 'name', label: 'Name', minWidth: 170 },
  //   { id: 'ipAddress', label: 'Ip\u00a0Address', minWidth: 100 },
  {
    id: 'value',
    label: 'Value',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'created_at',
    label: 'CreatedAt',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
];

function createData(serial, name, value, created_at) {
  return { serial, name, value, created_at };
}

const convertDate = (date) => {
  const dateObj = new Date(date);

  return dateObj.toLocaleString('en-US');
  //   return dateObj.toString();
};

export default function DeviceLoggerTable({ zoneDataLogger, rowsData, setRowsData }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = React.useState(10);
  //   const [rowsData, setRowsData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(page);
  };

  React.useEffect(() => {
    setLoading(true); // Set loading to true before making the API call

    getDeviceVoltage(zoneDataLogger, page + 1, rowsPerPage)
      .then((data) => {
        if (data) {
          console.log(data);
          setRowsData(data.data);
          setCount(data.count);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [zoneDataLogger, page, rowsPerPage]);
  console.log('🚀 ~ React.useEffect ~ zoneDataLogger:', zoneDataLogger);

  const rows =
    rowsData &&
    rowsData.map((row, index) => {
      return createData(index + 1, row.name, row.value, convertDate(row.created_at));
    });

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      {loading ? (
        'loading...'
      ) : (
        <TableContainer sx={{ maxHeight: 740 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading &&
                rows.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number' ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
