/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useContext } from 'react';
import loader from '../../../../../../../assets/img/icons/TizetiLoaderColored.gif';
// import { PerformancesProps } from '../types/PerformancesProps';
import GeniatechContext from '../../../../../../../context/GeniatechContext';
import helpers from '../../../../../../../utils/helpers';
import PerformanceTableHead from './PerformanceTableHead';

const PerformanceTable = () => {
  const { loading, data } = useContext(GeniatechContext);
  const tableData =
    data && data.length
      ? data.map((row, i) => (
          <tr className="text-nowrap" style={{ cursor: 'pointer' }} key={i + 1}>
            <td>{i + 1}</td>
            <td>{row.mac}</td>
            <td>{row.mode}</td>
            <td>{row.managed_account}</td>
            <td>{row.name}</td>
            <td>{row.network}</td>
            <td>{row.site}</td>
            <td>{row.online_duration}</td>
            <td>{row.uptime}</td>
            <td>{helpers.formatDateAndTime(row.timestamp)}</td>
            <td>{row.type}</td>
          </tr>
        ))
      : 'no performance';
  return (
    <div className="card x-panel mt-2 px-0">
      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <PerformanceTableHead />
          <tbody>
            {loading ? (
              <tr>
                <img src={loader} alt="loading gif" />
              </tr>
            ) : (
              tableData
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PerformanceTable;
