import React, { useEffect } from 'react';
import { useDedicatedInvoice } from '../../../../../hooks/accounts/dedicated-invoice/useDedicatedInvoice';

const EditDedicatedInvoice = ({ invoice }) => {
  const { one, setOne, handleUpdate, updateDedicatedInvoice } = useDedicatedInvoice();

  const { expiryDate } = one;

  useEffect(() => {
    const result = { expiryDate: invoice.expiration_date };
    setOne({ ...result });
  }, [invoice]);

  const onSubmit = (e) => {
    e.preventDefault();
    const data = { expiration_date: expiryDate };
    updateDedicatedInvoice(data, invoice.id);
  };

  return (
    <div id="update_invoice" className="modal fade in" tabIndex="-1" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Update Invoice</h4>
            <button type="button" className="close mr-2" data-dismiss="modal" aria-hidden="true">
              &times;
            </button>
          </div>
          <div className="modal-body my-4 p-0">
            <form onSubmit={onSubmit} className="mx-5">
              <div className="text-center col-12">
                <div className="form-group d-flex mx-3">
                  <label className="p-0 copy-font m-2" htmlFor="expiryDate">
                    Expiry Date:
                  </label>
                  <input
                    type="date"
                    className="form-control border border-info p-2 mr-5"
                    id="expiryDate"
                    name="expiryDate"
                    value={expiryDate}
                    onChange={handleUpdate}
                  />
                </div>
              </div>

              <div className="text-center mt-3">
                <button type="submit" className="btn btn-green px-3">
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDedicatedInvoice;
