/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';

const PaymentHistoryTable = ({ items, isLoading }) => {
  const tableData =
    items.length && items
      ? items.map((item, i) => {
          const { Trans_no, Tran_date, Charge, Payment, Description, Reference } = item;

          return (
            <tr className="text-nowrap" key={i}>
              <td>{i + 1}</td>
              <td>{Trans_no}</td>
              <td>{Tran_date}</td>
              <td>{Charge || Payment}</td>
              <td>{Description}</td>
              <td>{Reference}</td>
            </tr>
          );
        })
      : 'No record';

  return (
    <div className="table-responsive content col-12 px-1">
      <table className="table">
        <thead>
          <tr className="text-nowrap">
            <th>#</th>
            <th>Transaction No</th>
            <th>Transaction Date</th>
            <th>Payment </th>
            <th> Description</th>
            <th> Reference </th>
          </tr>
        </thead>

        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan="8" className="text-center">
                <img src={loader} alt="loading gif" />
              </td>
            </tr>
          ) : (
            tableData
          )}
        </tbody>
      </table>
    </div>
  );
};
PaymentHistoryTable.propTypes = {
  items: PropTypes.arrayOf.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
export default PaymentHistoryTable;
