import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import format from 'date-fns/format';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Skeleton from '@mui/material/Skeleton';
import useInstallationsSummary from '../../../../../../hooks/field-support/installations-summary/useInstallationsSummary';
import TargetChartContainer from '../InstTargetByState/TargetChartContainer';
import { get_support_calls } from '../../../../../../actions/coo-statistics/SupportCalls';
import SupportCallContainer from './SupportCallContainer';

export default function SupportCallsByZone() {
  // Create a new Date object
  const currentDate = new Date();
  // Get year, month, and day
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(currentDate.getDate()).padStart(2, '0');

  // Form the date string in "YYYY-MM-DD" format
  const formattedDate = `${year}-${month}-${day}`;
  const [isLoading, setIsLoading] = React.useState(false);
  const [fromDate, setFromDate] = React.useState(`${formattedDate}`);
  const [toDate, setToDate] = React.useState(`${formattedDate}`);
  const [supportData, setSupportData] = React.useState([]);

  // initial support call state
  const initialSupportCallState = {
    from: fromDate,
    to: toDate,
  };
  const fetchSupportCalls = async () => {
    const supportCalls = await get_support_calls(initialSupportCallState);
    setSupportData(supportCalls);
    setIsLoading(false);
  };
  React.useEffect(() => {
    setIsLoading(true);
    fetchSupportCalls();
  }, []);
  //handl fetching of support calls for the search button
  const handleSearch = () => {
    setIsLoading(true);
    fetchSupportCalls();
  };
  return (
    <Box sx={{ ml: '2rem', mt: '2rem', mr: '2rem' }}>
      <Typography variant="h4">Support Calls by Asset Zone</Typography>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          {isLoading ? (
            <>
              <Skeleton width={300} height={100} />
              <Skeleton width={250} />
              <Skeleton width={250} />
            </>
          ) : (
            <div className="d-flex align-items-center">
              <div className="">
                <label>From -</label>
                <input
                  type="date"
                  name="fromDate"
                  id="fromDate"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  className="block w-full mt-2 px-4 py-2 border rounded appearance-none focus:border-blue-500 focus:outline-none"
                />
              </div>
              <div className="flex flex-col">
                <label>To -</label>
                <input
                  type="date"
                  name="toDate"
                  id="toDate"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  className="block w-full mt-2 px-4 py-2 border rounded appearance-none focus:border-blue-500 focus:outline-none"
                />
              </div>
              <div>
                <button className="btn btn-green" onClick={handleSearch}>
                  Search
                </button>
              </div>
            </div>
          )}
        </div>
      </Box>
      <Box sx={{ mt: 5 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bolder' }}>Zone</TableCell>
                {supportData.map((target, index) => (
                  <TableCell sx={{ fontWeight: 'bolder' }} key={index}>
                    {target.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bolder' }}>Calls Count</TableCell>
                {supportData.map((target, index) => (
                  <TableCell sx={{ fontWeight: 'bolder' }} key={index}>
                    {target.totalCallCount}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box>
        <SupportCallContainer data={supportData} isLoading={isLoading} />
      </Box>
    </Box>
  );
}
