/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from 'react';
import SubscriberContext from '../../../../../context/lte/subscribers/SubscriberContext';
import useProfile from '../../../../../hooks/shared/noc/lte/profiles/useProfile';

const CreateSubscriber = () => {
  const value = useContext(SubscriberContext);
  const { profiles } = useProfile();
  // console.log(profiles);
  const { onChange, onSubmit, subscriber } = value;
  const { imsi, id } = subscriber;

  const submitForm = () => imsi.length > 0 && id.length > 0;

  return (
    <div
      id="lteModal"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myModalLabel">
              Create Customer LTE Account
            </h4>
            <button type="button" className="close custom-close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body detail" style={{ padding: '0', textAlign: 'justify' }}>
            <div className="row">
              <form className="form-horizontal col-12" onSubmit={onSubmit}>
                <div className="form-group mt-4">
                  <div className="col-12 d-flex mb-3">
                    <div className="col-6">
                      <label className="copy-font m-0 p-0 text-left">IMSI</label>
                      <input
                        name="imsi"
                        value={imsi}
                        onChange={onChange}
                        type="number"
                        placeholder="200010000000055"
                        className="form-control pl-2"
                      />
                    </div>
                    <div className="col-6">
                      <label className="copy-font m-0 p-0 text-left">State</label>
                      <select name="id" className="form-control" onChange={onChange}>
                        {profiles.map((profile) => (
                          // eslint-disable-next-line no-underscore-dangle
                          <option value={profile._id}>{profile.baicells}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="text-center">
                    {/* eslint-disable-next-line react/button-has-type */}
                    <button className="btn btn-green" disabled={!submitForm()}>
                      Create Subscriber
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateSubscriber;
