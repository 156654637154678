/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect } from 'react';
import { useBandwidth } from '../../../../../hooks/partnership/bandwidth/useBandwidth';
import PartnershipBandwidthScheduleTable from './PartnershipBandwidthScheduleTable';
import CustomInput from '../../../shared/forms/CustomInput';

const PartnershipBandwidthBody = () => {
  const [view, setView] = useState(false);
  const [file, setFile] = useState('');

  const {
    deleteBandwidth,
    addBandwidthCsv,
    getBandwidthSchedule,
    deleteBandwidthSchedule,
    load,
    schedules: lists,
    isLoading,
    addBandwidthSchedule,
  } = useBandwidth();

  const reload = () => {
    getBandwidthSchedule();
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    const params = new FormData();
    params.append('form', file);

    addBandwidthCsv(params, reload, setFile);
  };

  return (
    <div className="col-12 p-0 after-summary">
      <form onSubmit={onSubmit} className="form-inline mt-4 inner-addon d-flex">
        <div className="form-group col-md-12">
          <CustomInput
            type="file"
            className="form-control border border-info py-2"
            id="file"
            name="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={(e) => setFile(e.target.files[0])}
          />
        </div>

        <div className="col-12 d-flex mt-2">
          {/* {loading ? (
              <button type="submit" className="btn btn-green py-2 px-5" disabled>
                Submitting{' '}
              </button>
            ) : ( */}
          <button type="submit" className="btn btn-green py-2 px-5">
            Submit{' '}
          </button>
          {/* )} */}
        </div>
      </form>
      <div className="row mx-2">
        <div className="col-12 d-flex mt-2">
          <button
            className="btn btn-green mr-3"
            type="button"
            data-toggle="modal"
            // data-target="#add_item"
            onClick={() => setView(true)}
          >
            Add Partnership Bandwidth
          </button>
        </div>

        <div className="col-12 d-flex mt-2">
          <button
            className="btn btn-green mr-3"
            type="button"
            data-toggle="modal"
            // data-target="#add_item"
            // disabled
            onClick={() => deleteBandwidth(reload)}
          >
            Delete All
          </button>
        </div>

        <div className="card x-panel p-0">
          <PartnershipBandwidthScheduleTable
            view={view}
            setView={setView}
            getBandwidthSchedule={getBandwidthSchedule}
            load={load}
            isLoading={isLoading}
            deleteBandwidthSchedule={deleteBandwidthSchedule}
            lists={lists}
            addBandwidthSchedule={addBandwidthSchedule}
          />
        </div>
      </div>
    </div>
  );
};

export default PartnershipBandwidthBody;
