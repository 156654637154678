import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class WifiCallLogo extends Component {
  render() {
    return (
      <Link className="navbar-brand" to="/wifi-call" id="control">
        <img
          src="https://res.cloudinary.com/teewhy/image/upload/v1545148837/wifi-dropdown.fw.png"
          alt="homepage"
          data-toggle="collapse"
          data-target="#demo-profile"
          className="hotspot-profile"
        />

        <ul id="demo-profile" className="collapse">
          <li className="copy-font">
            <Link className="navbar-brand" to="/super-admin">
              <img
                src="https://res.cloudinary.com/tizeti/image/upload/v1542971047/Tizeti-Logo.png"
                alt="homepage"
                style={{ width: '90%' }}
              />
            </Link>
          </li>
        </ul>
      </Link>
    );
  }
}
