import React, { Component } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { API_URL } from '../../../../utils/config';

class EditDedicated extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      ap: '',
      ssid: '',
      device: '',
      password: '',
    };

    this.onChange = this.onChange.bind(this);
    this.editDedicated = this.editDedicated.bind(this);
  }

  editDedicated = (e) => {
    e.preventDefault();

    const data = {
      ap: this.state.ap,
      device: this.state.device,
      name: this.state.name,
      password: this.state.password,
      ssid: this.state.ssid,
    };

    const url = `${API_URL}/dedicated/${this.state.id}`;

    axios({
      method: 'put',
      url,
      data,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
      .then((res) => {
        console.log(res.data);
        if (res.data[0]) {
          toastr.success('Edited Successfully!!', 'Dedicated Customer', {
            timeOut: 1000,
            fadeOut: 1000,
            onHidden() {
              window.location.reload();
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(url);

    console.log(data);
  };

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  componentWillReceiveProps(nextProps) {
    if (nextProps.user) {
      this.setState({
        name: nextProps.user.name,
        ap: nextProps.user.ap,
        ssid: nextProps.user.ssid,
        device: nextProps.user.device,
        password: nextProps.user.device,
        id: nextProps.user.id,
      });
    }
  }

  render() {
    // EmployeeAuth: {id: "5ed7d0d6-768d-4931-bf4f-b056cab1eec0", email: "nocmanager@tizeti.com", department_id: "6e072411-d6eb-413d-be6c-c4b5bc812320", staff: Array(0)}

    return (
      <div
        id="editDedicated"
        className="modal fade in"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
        style={{ overflow: 'auto' }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center" id="myModalLabel">
                Edit Dedicated Customer
              </h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
            </div>
            <div className="modal-body">
              <form className="form-horizontal form-material" onSubmit={this.editDedicated}>
                <div className="form-group invent">
                  <div className="col-md-12 mb-3 d-flex">
                    <div className="col-lg-6">
                      <label className="copy-font">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Customer Name"
                        name="name"
                        value={this.state.name}
                        onChange={this.onChange}
                        required
                      />
                    </div>

                    <div className="col-lg-6">
                      <label className="copy-font">AP</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="AP "
                        name="ap"
                        onChange={this.onChange}
                        value={this.state.ap}
                        required
                      />
                    </div>
                  </div>

                  <div className="col-md-12 mb-3 d-flex">
                    <div className="col-lg-6">
                      <label className="copy-font">SSID</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="WIFI SSID"
                        name="ssid"
                        onChange={this.onChange}
                        value={this.state.ssid}
                      />
                    </div>

                    <div className="col-lg-6">
                      <label className="copy-font m-0 p-0">Device</label>
                      <input
                        type="text"
                        className="form-control pl-2"
                        placeholder="Device Name"
                        name="device"
                        onChange={this.onChange}
                        value={this.state.device}
                      />
                    </div>
                  </div>

                  <div className="col-md-12 mb-3 d-flex">
                    <div className="col-lg-6">
                      <label className="copy-font">Password</label>
                      <div className="d-flex">
                        <input className="mt-1" type="radio" name="password" value="YES" onChange={this.onChange} />
                        <p className="copy-font ml-2 mr-2" style={{ fontSize: '14px' }}>
                          Yes
                        </p>
                        <input className="mt-1" type="radio" name="password" value="NO" onChange={this.onChange} />
                        <p className="copy-font ml-2" style={{ fontSize: '14px' }}>
                          No
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <button className="btn btn-second">Update</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditDedicated;
