/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import toastr from 'toastr';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getLeads } from '../../../../../actions/shared/leads/LeadsActions';
import { API_URL, STATES_URL } from '../../../../../utils/config';

class AddLead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      states: [],
      firstname: '',
      lastname: '',
      email: '',
      alternateEmail: '',
      phoneNumber: '',
      gender: '',
      birthday: '',
      address: '',
      state: '',
      // lga: '',
      modalShow: false,
      alternatePhoneNumber: '',
    };

    this.addLead = this.addLead.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.getStates();
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  getStates() {
    axios({
      method: 'get',
      url: `${STATES_URL}`,
    }).then((response) => {
      if (response.data) {
        this.setState({
          states: response.data,
        });
      }
    });
  }

  addLead(e) {
    e.preventDefault();
    const {
      firstname,
      lastname,
      email,
      alternateEmail,
      address,
      phoneNumber,
      alternatePhoneNumber,
      gender,
      birthday,
      state,
    } = this.state;

    const data = {
      firstName: firstname,
      lastName: lastname,
      email,
      alternateEmail,
      address,
      phoneNumber,
      alternatePhoneNumber,
      gender,
      birthday,
      password: email,
      state,
      // lga: this.state.lga
    };

    if (firstname === '') {
      toastr.error('First Name field can not be empty', {
        timeOut: 1000,
        fadeOut: 1000,
      });
    } else if (lastname === '') {
      toastr.error('Last Name field can not be empty', {
        timeOut: 1000,
        fadeOut: 1000,
      });
    } else if (email === '') {
      toastr.error('Email field can not be empty', {
        timeOut: 1000,
        fadeOut: 1000,
      });
    } else if (address === '') {
      toastr.error('Address field can not be empty', {
        timeOut: 1000,
        fadeOut: 1000,
      });
    } else if (this.state.phoneNumber === '') {
      toastr.error('Phone Number field can not be empty', {
        timeOut: 1000,
        fadeOut: 1000,
      });
    } else if (this.state.gender === '') {
      toastr.error('Gender field can not be empty', {
        timeOut: 1000,
        fadeOut: 1000,
      });
    } else if (this.state.birthday === '') {
      toastr.error('Select a birthday date', {
        timeOut: 1000,
        fadeOut: 1000,
      });
    } else if (this.state.state === '') {
      toastr.error('Select a state', {
        timeOut: 1000,
        fadeOut: 1000,
      });
    }
    // else if (this.state.lga === "") {
    //     toastr.error(
    //         'Lga field can not be empty',
    //         {
    //             timeOut: 1000,
    //             fadeOut: 1000,
    //         }
    //     );
    // }
    else if (this.state.alternatePhoneNumber === '') {
      toastr.error('Alternate Phone Number field can not be empty', {
        timeOut: 1000,
        fadeOut: 1000,
      });
    } else {
      axios({
        method: 'post',
        url: `${API_URL}/users`,
        data,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        }
      }).then((response) => {
        if (response.data.id) {
          // window.location.reload();
          const { modalShow } = this.state;
          this.setState(
            {
              firstname: '',
              lastname: '',
              email: '',
              alternateEmail: '',
              phoneNumber: '',
              gender: '',
              birthday: '',
              address: '',
              state: '',
              // lga: '',
              alternatePhoneNumber: '',

              modalShow: !modalShow,
            },
            // eslint-disable-next-line no-unused-vars
            (props) => {
              // this.props.modalshow = this.state.modalShow;
            },
          );
          this.props.getLeads();

          toastr.success('Lead created successfully', {
            timeOut: 2000,
            fadeOut: 2000,
          });
        }
      });
    }
  }

  showButton() {
    if (this.state.loading) {
      return (
        <div className="col-12 mb-3 text-center">
          <button className="btn btn-green" disabled>
            Create Lead
          </button>
        </div>
      );
    }
    return (
      <div className="col-12 mb-3 text-center">
        <button className="btn btn-green">Create Lead</button>
      </div>
    );
  }

  // eslint-disable-next-line consistent-return
  showStates() {
    const { states } = this.state;
    if (states.length) {
      return (
        <select className="form-control col-lg-11" onChange={this.onChange} name="state" value={this.state.state}>
          <option value="">E.g Lagos</option>
          {states.map((state) => {
            return (
              <option value={state.name} required>
                {state.name}
              </option>
            );
          })}
        </select>
      );
    }
  }

  render() {
    const { firstname, lastname, email, alternateEmail, phoneNumber, alternatePhoneNumber, address } = this.state;

    return (
      <div className="modal-body">
        <form className="form-horizontal" onSubmit={this.addLead}>
          <div className="form-group">
            <div className="col-md-12 d-flex mb-3">
              <div className="col-md-6">
                <label className="copy-font m-0 p-0">First name</label>
                <input
                  type="text"
                  className="form-control"
                  name="firstname"
                  placeholder="Enter First Name"
                  onChange={this.onChange}
                  value={firstname}
                />
              </div>

              <div className="col-md-6">
                <label className="copy-font m-0 p-0">Last name</label>
                <input
                  type="text"
                  className="form-control"
                  name="lastname"
                  placeholder="Enter Last Name"
                  onChange={this.onChange}
                  value={lastname}
                />
              </div>
            </div>

            <div className="col-md-12 d-flex mb-3">
              <div className="col-lg-6">
                <label className="copy-font m-0 p-0">Email Address</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="Enter Email Address"
                  onChange={this.onChange}
                  value={email}
                />
              </div>
              <div className="col-md-6">
                <label className="copy-font m-0 p-0">Alternate Email Address</label>
                <input
                  type="email"
                  className="form-control"
                  name="alternateEmail"
                  placeholder="Alternate Email Address"
                  onChange={this.onChange}
                  value={alternateEmail}
                />
              </div>
            </div>

            <div className="col-md-12 d-flex mb-3">
              <div className="col-md-6">
                <label className="copy-font m-0 p-0">Phone number</label>
                <input
                  type="number"
                  className="form-control"
                  name="phoneNumber"
                  placeholder="Enter Phone number"
                  onChange={this.onChange}
                  value={phoneNumber}
                />
              </div>
              <div className="col-md-6">
                <label className="copy-font m-0 p-0">Alternate Phone number</label>
                <input
                  type="number"
                  className="form-control"
                  name="alternatePhoneNumber"
                  placeholder="Alternate Phone number"
                  onChange={this.onChange}
                  value={alternatePhoneNumber}
                />
              </div>
            </div>

            <div className="col-md-12  d-flex mb-3">
              <div className="col-lg-6">
                <label className="copy-font m-0 p-0">Gender</label>
                <div className="col-lg-6 d-flex" style={{ marginLeft: '-7%' }}>
                  <input type="radio" name="gender" value="female" onChange={this.onChange} />
                  <span className="ml-2 mr-2 cf">Female</span>
                  <input type="radio" name="gender" value="male" onChange={this.onChange} />{' '}
                  <span className="ml-2 cf">Male</span>
                </div>
              </div>

              {/* <div className="col-md-6">
                                                <label className="copy-font m-0 p-0">Birthday</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    name="birthday"
                                                    value={this.state.birthday}
                                                    onChange={this.onChange}

                                                />
                                            </div> */}
            </div>

            <div className="col-md-12 d-flex mb-3">
              <div className="col-md-6">
                <label className="copy-font m-0 p-0">Address</label>
                <input
                  type="text"
                  className="form-control"
                  name="address"
                  placeholder="Enter Address"
                  value={address}
                  onChange={this.onChange}
                />
              </div>

              <div className="col-lg-6">
                <label className="copy-font m-0 p-0">State</label>

                {this.showStates()}
              </div>
            </div>

            {/* <div className="col-md-12 d-flex mb-3"> */}
            {/* <div className="col-md-6">
                                                <label className="copy-font m-0 p-0">Password</label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    name="password"
                                                    placeholder="Password"
                                                    onChange={this.onChange}
                                                    value={this.state.password}

                                                />
<<<<<<< HEAD
                                            </div>
                                            </div> */}
            <div className="col-md-12 d-flex mb-3">
              <div className="col-md-6">
                <label className="copy-font m-0 p-0">Source</label>
                <select className="form-control" name="source" onChange={this.onChange}>
                  <option value="Google Ads">Other</option>
                  <option value="Facebook">Facebook</option>
                  <option value="Flyer">Flyer</option>
                  <option value="Billboard">Billboard</option>
                  <option value="Mobile Advert">Mobile Advert</option>
                  <option value="Friend">Friend</option>
                  <option value="Online">Online</option>
                  <option value="Television">Television</option>
                  <option value="Radio">Radio</option>
                  <option value="Other">Google Ads</option>
                </select>
              </div>

              <div className="col-md-6">
                <label className="copy-font m-0 p-0">Notes </label>
                <textarea
                  rows="4"
                  cols="200"
                  type="text"
                  className="form-control"
                  placeholder="Comment about the call"
                />
              </div>
            </div>
            {/*                                    <div className="col-md-6">
                                                <label>Confirm Password</label>
                                                <input
                                                    type="pwd"
                                                    className="form-control"
                                                    name=""
                                                    placeholder="Confirm Password"
                                                />
                                            </div> */}
            {/* </div> */}

            {/* {this.showButton()} */}
            <div className="col-12 mb-3 text-center">
              <button className="btn btn-green">Create Lead</button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

AddLead.propTypes = {
  getLeads: PropTypes.func,
};

export default connect(null, { getLeads })(AddLead);
