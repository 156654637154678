import axios from 'axios';
import { toast } from 'react-toastify';
import {
  CREATE_RETAILER_URL,
  GET_ALL_RETAILERS_URL,
  GET_RETAILER_LOCATIONS_URL,
} from '../../services/api/hotspot/HotspotUrls';
import {
  FETCHING_STARTS,
  FETCHING_STOPS,
  GET_ALL_RETAILERS,
  GET_RETAILER_LOCATIONS,
} from '../../constants/types/hotspot/HotspotTypes';

export const getAllRetailers = () => async (dispatch) => {
  dispatch({
    type: FETCHING_STARTS,
    payload: true,
  });

  try {
    const response = await axios.get(`${GET_ALL_RETAILERS_URL}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data.status === true) {
      dispatch({
        type: GET_ALL_RETAILERS,
        payload: response.data.data,
      });
    }
  } catch (e) {}
};

export const createRetailer = (data) => async (dispatch) => {
  dispatch({
    type: FETCHING_STARTS,
    payload: true,
  });

  try {
    const response = await axios.post(`${CREATE_RETAILER_URL}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data.status === true) {
      toast.success('Retailer Account Created Successfully!');
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  } catch (e) {
    if (e.response) {
      e.response.data.errors.map((msg) => {
        toast.error(msg.msg);
      });

      dispatch({
        type: FETCHING_STOPS,
        payload: false,
      });
    }
  }
};

export const getRetailerLocations = () => async (dispatch) => {
  const response = await axios.get(`${GET_RETAILER_LOCATIONS_URL}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
  });

  if (response.data.status === true) {
    dispatch({
      type: GET_RETAILER_LOCATIONS,
      payload: response.data.data,
    });
  }
};
