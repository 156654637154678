import React, { Component } from "react";
import {
    accounts,
    ADMIN_DEPARTMENT,
    customer_support,
    NEW_PRODUCTS,
    NOC,
    operations,
    PROCUREMENT, RESEARCH_AND_DEVELOPMENT,
    SALES, TECHNICAL_SUPPORT
} from "../../../../../utils/config";
import toastr from "toastr"
import { updateFeatureRequest } from "../../../../../actions/research-and-development/feature-requests/FeatureRequestsActions";
import { connect } from "react-redux";

class UpdateRequest extends Component {

    constructor(props) {
        super(props);

        this.state = {

            status: '',
            load: false

        };

        this.onChange = this.onChange.bind(this);
        this.updateRequest = this.updateRequest.bind(this);

    };

    onChange = e => this.setState({

        [e.target.name]: e.target.value

    });

    updateRequest = e => {

        e.preventDefault();

        this.setState({

            load: true

        });

        const {status} = this.state;
        const {feature} = this.props;

        if(status === ""){

            toastr.info("Select a status for this feature");
            this.setState({

                load: false

            })

        } else {

            const data = {

                status

            };
            this.props.updateFeatureRequest(data, feature);

        }

    };

    render() {

      const {feature} = this.props;
      const {load} = this.state;

    return (
      <div id="updateRequest" className="modal fade in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
           aria-hidden="true">
          <div className="modal-dialog">
              <div className="modal-content">
                  <div className="modal-header">
                      <h4 className="modal-title text-center" id="myModalLabel">Update Feature Request</h4>
                      <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                  </div>
                  <div className="modal-body">
                      <form className="form-horizontal" onSubmit={this.updateRequest}>
                          <div className="form-group">
                              <div className="col-12 d-flex mb-3 p-0">
                                  <div className="col-6">
                                      <label className="copy-font m-0 p-0">Department</label>
                                      <div> {feature.department_id === `${SALES}` ? <strong>SALES</strong> :
                                        (feature.department_id === `${accounts}` ? <strong>ACCOUNTS</strong> :
                                          (feature.department_id === `${customer_support}` ? <strong>CUSTOMER SUPPORT</strong> :
                                            (feature.department_id === `${ADMIN_DEPARTMENT}` ? <strong>ADMINISTRATION</strong> :
                                              (feature.department_id === `${NOC}` ? <strong>NOC</strong> :
                                                (feature.department_id === `${operations}` ? <strong>OPERATIONS</strong> :
                                                  (feature.department_id === `${NEW_PRODUCTS}` ? <strong>NEW PRODUCTS</strong> :
                                                    (feature.department_id === `${PROCUREMENT}` ? <strong>PROCUREMENT</strong> :
                                                      (feature.department_id === `${RESEARCH_AND_DEVELOPMENT}` ? <strong>RESEARCH AND DEVELOPMENT</strong> :
                                                        (feature.department_id === `${TECHNICAL_SUPPORT}` ? <strong>TECHNICAL SUPPORT</strong> : null
                                                        )))))))))}</div>
                                  </div>

                                  <div className="col-6">
                                      <label className="copy-font m-0 p-0">Subject</label>
                                      <input type="text" placeholder={feature.subject} className="form-control" readOnly/>
                                  </div>
                              </div>

                              <div className="col-12 mb-3 d-flex p-0">
                                  <div className="col-6 p-0">
                                      {/*<div className="col-12 mb-4">*/}
                                      {/*<label className="copy-font m-0 p-0">Submitted By</label>*/}
                                      {/*<input type="text" className="form-control" placeholder="Olawale Tolu"*/}
                                      {/*readOnly/>*/}
                                      {/*</div>*/}
                                      <div className="col-12">
                                          <select className="form-control w-100" onChange={this.onChange} name="status">
                                              <option value={feature.status} defaultChecked={feature.status} aria-readonly={true}>{feature.status}</option>
                                              {feature.status === "in-queue" ? <option value="completed">Done</option> : <option value="in-queue">In Progress</option>}
                                          </select>
                                      </div>
                                  </div>
                                  <div className="col-6">
                                      <label className="copy-font m-0 p-0">Details</label>
                                      {/* <input type="text" placeholder="IP Address" className="form-control" /> */}
                                      <textarea rows="5" cols="20" name="description" className="form-control"
                                                placeholder={feature.details} readOnly>
                                            </textarea>
                                  </div>
                              </div>

                              <div className="col-12 text-center mt-3">
                                  {load ? <button className="btn btn-green" style={{ backgroundColor: "#FFA800" }} disabled={true}><i className="fa fa-spinner">Updating...</i></button> :  <button className="btn btn-green" style={{ backgroundColor: "#FFA800" }}>Update</button> }
                              </div>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
      </div>
    );
  }
}

export default connect(null, {updateFeatureRequest})(UpdateRequest);