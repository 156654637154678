import React from 'react';
import AssetTeamNavBar from '../../../layouts/asset-team/AssetTeamNavBar';
import { useUser } from '../../../../hooks/shared/user/useUser';
import profileImage from '../../../../assets/img/icons/Profile.png';
import CustomerSupportNavBar from '../../../layouts/customer-support/CustomerSupportNavBar';
import { CUSTOMER_SUPPORT, ASSET_TEAM } from '../../../../utils/departments/helpers';
import StatisticsOverviewBody from '../../departments/super-admin/statistics-dashboard/StatisticsOverviewBody';

export default function ProductUptime() {
  const { profile } = useUser();
  const roleId = sessionStorage.getItem('role_id');
  const departmentId = sessionStorage.getItem('departmentId');
  // console.log(profile);
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        {departmentId === ASSET_TEAM && (
          <AssetTeamNavBar currentPage="STATISTICS" profileImage={profileImage} position={profile.firstName} />
        )}
        {departmentId === CUSTOMER_SUPPORT &&
          (profile.email === 'oluwayemisi.olude@tizeti.com' || profile.email === 'customersupportDept@tizeti.com') && (
            <CustomerSupportNavBar
              currentPage="STATISTICS"
              roleID={roleId}
              profileImage={profileImage}
              position={profile.firstName}
            />
          )}

        <StatisticsOverviewBody />
      </main>
    </div>
  );
}
