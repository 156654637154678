/* eslint-disable import/prefer-default-export */
import { useReducer, useState } from 'react';
import qs from 'qs';
import axios from 'axios';
import toastr from 'toastr';
import * as load from '../../../constants/types/shared/LoadingTypes';
import { referralReducer } from '../../../reducers/sales/referral/referralReducer';
import { EDIT_REFERRAL_REQUEST_URL, GET_REFERRAL_SEARCH_URL } from '../../../services/api/sales/referral/ReferralUrl';
import { GET_REFERRAL_REQUEST } from '../../../constants/types/sales/customers/ReferralTypes';

export const useReferral = () => {
  const [{ lists, count, pages, isLoading, tracker, monthlyCount }, dispatch] = useReducer(referralReducer, {
    lists: [],
    count: 1,
    pages: 1,
    monthlyCount: 0,
    isLoading: false,
    tracker: false,
  });

  const [page] = useState(1);

  const getReferralRequest = async (selectedPage) => {
    dispatch({ type: load.LOADING_STARTS });
    const config = {
      method: 'post',
      url: `${GET_REFERRAL_SEARCH_URL}/${selectedPage}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response.data) {
          console.log(response.data.data);
          dispatch({
            type: GET_REFERRAL_REQUEST,
            payload: {
              lists: response.data.data,
              count: response.data.count,
              pages: response.data.pages,
            },
          });
        }
      })
      .catch(() => {
        toastr.info('Something went wrong!');
        dispatch({ type: load.LOADING_STOPS });
      });
  };

  const searchUsingKeyword = async (selectedPage, data) => {
    dispatch({ type: load.LOADING_STARTS });
    const config = {
      method: 'post',
      url: `${GET_REFERRAL_SEARCH_URL}/${selectedPage}`,
      data: qs.stringify(data),
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response.data) {
          dispatch({
            type: GET_REFERRAL_REQUEST,
            payload: {
              lists: response.data.data,
              count: response.data.count,
              monthlyCount: response.data.count,
              pages: response.data.pages,
            },
          });
        }
      })
      .catch(() => {
        toastr.info('Something went wrong!');
        dispatch({ type: load.LOADING_STOPS });
      });
  };

  const editReferralRequest = async (id, data) => {
    const config = {
      method: 'patch',
      url: `${EDIT_REFERRAL_REQUEST_URL}/${id}`,
      data: qs.stringify(data),
      headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
    };

    await axios(config)
      .then((response) => {
        if (response.data[0] === 1) {
          // console.log('true');
          toastr.success('Update Successful');
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((error) => {
        if (error) {
          toastr.info('Something went wrong!');
          dispatch({ type: load.LOADING_STOPS });
        }
      });
  };

  // useEffect(() => {
  //   getRelocationRequest(page);
  // }, []);

  return {
    lists,
    pages,
    count,
    tracker,
    isLoading,
    monthlyCount,
    searchUsingKeyword,
    getReferralRequest,
    editReferralRequest,
  };
};
