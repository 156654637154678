import { GET_JOBS_APPLICATIONS } from '../../../constants/types/shared/recruitment/ApplicationsTypes';

const initialState = {
  applications: [],
  load: true,
};

export const ApplicationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_JOBS_APPLICATIONS:
      return {
        ...state,
        applications: action.payload,
        load: false,
      };

    default:
      return state;
  }
};

export default ApplicationsReducer;
