import React from 'react';
import CreateAccount from './CreateAccount';
import AccountsTable from './AccountsTable';
import NocNavBar from '../../../../../layouts/noc/NocNavBar';

const NoclteAccounts = () => {
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <NocNavBar currentPage="LTE Accounts" />
        <div className="container">
          <div className="row mt-5">
            <div className="mt-5 col-lg-12 col-md-12 col-sm-12">
              <div className="d-flex employ inner-addon left-addon">
                <p className="op copy-font d-flex mr-auto">
                  <small className="mt-2 mr-2"> Filter:</small>
                  <span className="mr-2">
                    <select className="form-control">
                      <option>Nigeria</option>
                      <option>Lagos</option>
                      <option>Abeokuta</option>
                      <option>PortHarcouth</option>
                    </select>
                  </span>

                  <span>
                    <select className="form-control">
                      <option>Ghana</option>
                    </select>
                  </span>
                </p>

                <div>
                  <button type="submit" className="btn btn-green" data-toggle="modal" data-target="#lteModal">
                    Create
                  </button>
                  <CreateAccount />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <AccountsTable />
          </div>
        </div>
      </main>
    </div>
  );
};

export default NoclteAccounts;
