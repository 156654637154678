/* eslint-disable react/button-has-type */
import React, { useContext } from 'react';
import AlarmsTable from './table/AlarmsTable';
import GeniatechContext from '../../../../../../context/GeniatechContext';
import SearchResults from './table/SearchResults';

const AlarmsBody = () => {
  const { searchStatus, searchData, search, onChange } = useContext(GeniatechContext);

  return (
    <div className="container">
      <div className="row mt-5">
        <div className="mt-4 col-lg-12 col-md-12 col-sm-12">
          <div className="container">
            {' '}
            <div className="row mt-5">
              <form
                className="form-inline mb-2 ml-3 col-12"
                onSubmit={(e) => {
                  e.preventDefault();
                  searchData({
                    action: 'get_alarms',
                    offset: '1',
                    limit: '100',
                    severity: search,
                  });
                }}
              >
                <div className="form-group">
                  <select name="option" onChange={onChange} className="form-control">
                    <option value="">--Choose Search Field--</option>
                    <option value="critical">Critical</option>
                    <option value="major">Major</option>
                    <option value="minor">Minor</option>
                  </select>
                </div>
                <div className="form-group ml-2">
                  <button className="btn btn-green">Search</button>
                </div>
              </form>
              {searchStatus ? <SearchResults /> : <AlarmsTable />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlarmsBody;
