/* eslint-disable react/require-default-props */
import React, { createContext } from 'react';
import useInstallationZones from '../hooks/useInstallationZones';

export const ZonesContext = createContext({});

export const ZonesProvider = ({ children }) => {
  const {
    onZoneChange,
    onSubZoneChange,
    zones,
    subZones,
    loading,
    setQuery,
    query,
    zone,
    onSubmitZone,
    loadingForm,
    subZone,
    onSubmitSubZone,
    onShowModal,
    showDeleteModal,
    deleteZone,
    deletingZone,
    deleteSubZone,
  } = useInstallationZones();
  return (
    <ZonesContext.Provider
      value={{
        loading,
        zones,
        zone,
        onSubmitZone,
        loadingForm,
        onZoneChange,
        onSubZoneChange,
        setQuery,
        query,
        subZone,
        onSubmitSubZone,
        subZones,
        onShowModal,
        showDeleteModal,
        deleteZone,
        deletingZone,
        deleteSubZone,
      }}
    >
      {children}
    </ZonesContext.Provider>
  );
};
