import React, { Component } from 'react';
import '../../../../../styles/style.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MailBlast from '../../../../../assets/img/icons/mailblast.svg';
import { getUser } from '../../../../../actions/shared/user/UserActions';
import {
  searchCustomersUsageWithEmail,
  searchCustomersUsageWithName,
  searchCustomersUsageWithPhone,
} from '../../../../../actions/shared/customers/usage-history/UsageHistoryActions';
import FieldSupportSideBar from '../../../../layouts/field-support/side-bar/FieldSupportSideBar';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import UsageHistoryTable from '../../../shared/usage-history/UsageHistoryTable';
import TechLogo from '../../../../layouts/tech-support/TechLogo';
import Logout from '../../../shared/auth/Logout';
import { admin, operations, TECHNICAL_SUPPORT } from '../../../../../utils/config';

class FieldSupportUsageHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      keyword: '',
      search: '',
    };

    this.onChange = this.onChange.bind(this);
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { keyword, search } = this.state;

    switch (keyword) {
      case 'email':
        const email = {
          email: search,
        };

        this.props.searchCustomersUsageWithEmail(email);
        break;

      case 'phone':
        const phone = {
          phoneNumber: search,
        };

        this.props.searchCustomersUsageWithPhone(phone);
        break;

      case 'name':
        const name = {
          name: search,
        };

        this.props.searchCustomersUsageWithName(name);
        break;

      default:
        return null;
    }
  };

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  componentDidMount() {
    this.checkDepartment();
    this.props.getUser();
  }

  static propTypes = {
    getUser: PropTypes.func.isRequired,
    searchCustomersUsageWithEmail: PropTypes.func.isRequired,
    searchCustomersUsageWithName: PropTypes.func.isRequired,
    searchCustomersUsageWithPhone: PropTypes.func.isRequired,
  };

  componentWillReceiveProps() {
    this.checkDepartment();
  }

  checkDepartment = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (
        response.data.department_id !== `${operations}` &&
        response.data.department_id !== `${TECHNICAL_SUPPORT}` &&
        response.data.department_id !== `${admin}`
      ) {
        this.props.history.push('/');
        sessionStorage.setItem('token', '');
        sessionStorage.clear();
        toast.info('You are not allowed to view this page');
      }
    } catch (e) {
      toast.info(`Error Fetching Data`);
    }
  };

  submitForm = () => {
    const { search } = this.state;
    return search.length > 0;
  };

  render() {
    const { keyword, search } = this.state;
    const placeholder = `search with ${keyword}`;
    const isEnabled = this.submitForm();
    const { usage, load, user } = this.props;
    const { firstName } = user;

    return (
      <div className="main-wrapper">
        <header className="topbar shade bg-white fixed-top">
          <nav className="navbar navbar-expand-lg navbar-light top-navbar fixed-top">
            <TechLogo />
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse mt-3" id="navbarText">
              <ul className="navbar-nav">
                <li className="nav-item active">
                  <Link className="nav-link" to="#">
                    <h3>
                      Usage History<span className="sr-only">(current)</span>
                    </h3>
                  </Link>
                </li>
              </ul>
              <span className="navbar-text">
                <div className="d-flex">
                  <Link
                    to="/technical-support/mail-blast"
                    className="d-flex"
                    style={{ color: 'gray', textDecoration: 'none' }}
                  >
                    <img src={MailBlast} className="logout mr-2 mt-0 mb-2" />
                    <span>
                      <h6 className="mt-1 mr-3">
                        <strong>MailBlast</strong>
                      </h6>
                    </span>
                  </Link>
                  <h6 className="mr-0 mt-1">
                    {firstName}
                    <Logout />
                  </h6>
                </div>
              </span>
            </div>
          </nav>
        </header>

        <div className="all-sides col-lg-12">
          <FieldSupportSideBar />

          <div className="page-wrapper col-lg-10">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 mt-3 mb-3">
                  <form onSubmit={this.onSubmit} className="form-inline inner-addon d-flex">
                    <select name="keyword" id="" className="form-control mr-auto empty mt-1" onChange={this.onChange}>
                      <option value="">Choose fields to search from</option>
                      <option value="email">Email</option>
                      <option value="name">Name</option>
                      <option value="phone">Phone</option>
                    </select>
                    <input
                      className="form-control col-6 mr-auto empty mt-1 pl-5"
                      type="text"
                      name="search"
                      placeholder={placeholder}
                      value={search}
                      onChange={this.onChange}
                    />
                    <span className="fa fa-search right-addon" />

                    <div className="d-flex">
                      <button className="btn btn-green mr-2" style={{ height: '39px' }} disabled={!isEnabled}>
                        Search{' '}
                      </button>
                    </div>
                  </form>
                </div>
                <div className="card x-panel">
                  <div className="table-responsive content">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>MAC ID</th>
                          <th nowrap="no-wrap">Name</th>
                          <th>Email</th>
                          <th nowrap="no-wrap">Phone Number</th>
                          <th nowrap="no-wrap">Expiry Date</th>
                          <th style={{ paddingLeft: '24px' }}>Usage History</th>
                        </tr>
                      </thead>
                      <UsageHistoryTable customers={usage} />

                      {/*
                                    {this.state.loading ? <img
                                            src="https://res.cloudinary.com/teewhy/image/upload/v1546957729/TizetiLoaderColored.gif"/> :
                                        <tbody>
                                        <tr>
                                            <td>{this.state.id}</td>
                                            <td>{this.state.name}</td>
                                            <td>{this.state.email}</td>
                                            <td>{this.state.expiry}</td>
                                            <td>{this.state.usage}</td>
                                            <td>{this.state.upload}</td>
                                        </tr>

                                        </tbody>
                                    } */}
                    </table>
                    <ToastContainer />
                  </div>
                </div>

                {/* <AdminPaginate /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  usage: state.customerUsage.usage,
  load: state.customerUsage.load,
  user: state.user.user,
});

export default connect(
  mapStateToProps,
  {
    searchCustomersUsageWithEmail,
    searchCustomersUsageWithPhone,
    searchCustomersUsageWithName,
    getUser,
  },
)(FieldSupportUsageHistory);
