/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import AssetTeamSideBarItem from './AssetTeamSideBarItem';
import PATHS from '../../../../routes/urls';
import { AES } from 'crypto-js';
import useCheckTwoDepartments from '../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';
import { ASSET_TEAM, SUPER_ADMIN } from '../../../../utils/departments/helpers';

const AssetTeamSideBar = () => {
  const history = useHistory();
  const officialEmail = sessionStorage.getItem('officialEmail');

  const detailsState = {
    name: 'React',
    messagetoken: sessionStorage.getItem('token'),
    messagerole: sessionStorage.getItem('roleId'),
    messagedepartment: sessionStorage.getItem('departmentId'),
    secret: 'Wifirocks123',
    cipher: '',
  };
  const [details, setDetails] = useState(detailsState);
  const { name, messagetoken, messagerole, messagedepartment, secret, cipher } = details;

  const obj = {
    login: messagetoken,
    pass: messagerole,
    department: messagedepartment,
  };

  const ciph = AES.encrypt(JSON.stringify(obj), secret).toString();

  useCheckTwoDepartments(`${ASSET_TEAM}`, `${SUPER_ADMIN}`, history);

  const allowedEmails = ['assetDept@tizeti.com', 'nelson.ananyi@tizeti.com'];

  // const displayContent = () => {
  //   if (allowedEmails.includes(officialEmail)) {
  //     return ;
  //   }
  //   return null;
  // };
  return (
    <div className="s-layout__sidebar">
      <Link className="s-sidebar__trigger" to="#">
        <i className="fa fa-bars mr-3" />
      </Link>

      <nav className="s-sidebar__nav">
        <Link className="navbar-brand-logo" to="{/closed-leads}">
          <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971047/Tizeti-Logo.png" alt="homepage" />
        </Link>

        <ul>
          <span className="p-0 mt-3">
            <li className="s-sidebar__nav-link">
              {' '}
              <a href={`https://os.tizeti.com/main/#/overview?ciph=${ciph}`} target="_blank" className="make-bold">
                <span>
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923169/configr_d.fw.png" alt="img" />
                </span>
                <small>New system (Beta v2.0)</small>
              </a>
            </li>
          </span>
          <AssetTeamSideBarItem url={PATHS.ASSET_TEAM_GENIATECH} name="Customers" />
          <span className="p-0 mt-3">
            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/closed-leads" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923169/idontknow.fw.png" alt="img" />
                </span>
                Closed Leads
              </Link>
            </li>
            <AssetTeamSideBarItem url={PATHS.ASSET_TEAM_ACTIVE_AND_INACTIVE} name="Active And Inactive" />
            <AssetTeamSideBarItem url={PATHS.ASSET_TEAM_KPI_MONITOR} name="KPI Monitor" />
            <AssetTeamSideBarItem url="/shared/statistics" name="STATISTICS" />

            <span className="s-sidebar__nav-link d-none d-md-block" data-toggle="collapse" data-target="#active">
              <AssetTeamSideBarItem
                url="#"
                name="ACTIVE"
                image="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-6.png"
              />
            </span>
            <span id="active" className="collapse base-li top-bottom">
              <AssetTeamSideBarItem url={PATHS.ASSET_TEAM_ACTIVE_CUSTOMERS} name="Active Customers" />
              <AssetTeamSideBarItem
                url="/asset-team/active-per-zone"
                name={<span style={{ fontSize: '13px' }}>Active Customers Per Zone</span>}
              />
              <AssetTeamSideBarItem
                url={PATHS.ASSET_TEAM_ACTIVE_PER_COUNTRY}
                name={<span style={{ fontSize: '13px' }}>Active Customers Per Country</span>}
              />
            </span>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/shared/installation-feedback" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923430/r_d-envelop.fw.png" alt="img" />
                </span>{' '}
                Installation Feedback
              </Link>
            </li>
            {/* <AssetTeamSideBarItem
              url="/shared/installations"
              name={<span style={{ fontSize: '15px' }}>Send Feedback</span>}
            /> */}

            <AssetTeamSideBarItem
              url="/asset-team/dedicated-customers"
              name={<span style={{ fontSize: '15px' }}>Dedicated Customers</span>}
            />
            <AssetTeamSideBarItem url={PATHS.QA_INSTALLATION_REPORT} name="QA Installation Report" />

            <AssetTeamSideBarItem
              url="/shared/customer-details"
              name={<span style={{ fontSize: '15px' }}>Customer Details</span>}
            />

            <span className="s-sidebar__nav-link d-none d-md-block" data-toggle="collapse" data-target="#expired">
              <AssetTeamSideBarItem
                url="#"
                name="EXPIRED"
                image="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-6.png"
              />
            </span>
            <span id="expired" className="collapse base-li top-bottom">
              <AssetTeamSideBarItem url="/expired" name={<span style={{ fontSize: '13px' }}>Expired Customer</span>} />
              <AssetTeamSideBarItem
                url="/asset-team/expired"
                name={<span style={{ fontSize: '13px' }}>Expired 7 Days Ago</span>}
              />
              <AssetTeamSideBarItem
                url="/asset-team/expired-zones"
                name={<span style={{ fontSize: '13px' }}>Expired Customers Per Zone</span>}
              />

              <AssetTeamSideBarItem
                url={PATHS.ASSET_TEAM_EXPIRED_PER_COUNTRY}
                name={<span style={{ fontSize: '13px' }}>Expired Customers Per Country</span>}
              />
            </span>

            {/* <AssetTeamSideBarItem url="/shared/basestations" name="All Basestations" /> */}

            <li className="s-sidebar__nav-link">
              <Link to={PATHS.ASSET_TEAM_INSTALLATIONS_PER_BASESTATIONS} target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923169/idontknow.fw.png" alt="img" />
                </span>
                Installations Per Basestations
              </Link>
            </li>
            <AssetTeamSideBarItem url="asset-team/relocation" name="Relocation" />
            <AssetTeamSideBarItem url={PATHS.ASSET_TEAM_INSTALLED_PER_COUNTRY} name="Installations Per Country" />
            <AssetTeamSideBarItem url={PATHS.ASSET_TEAM_EXPIRED_LTE_CUSTOMERS} name="LTE Expired Customers" />
            <AssetTeamSideBarItem url={PATHS.ASSET_TEAM_ACTIVE_LTE_CUSTOMERS} name="LTE Active Customers" />
            <AssetTeamSideBarItem url={PATHS.ASSET_TEAM_LTE_INSTALLATIONS} name="LTE Installation Tracker" />
            <AssetTeamSideBarItem url={PATHS.ASSET_TEAM_DEVICE_RETRIEVAL} name="Device Retrieval" />
            <AssetTeamSideBarItem url={PATHS.ASSET_TEAM_TRACKER} name="Customers Tracker" />
            <AssetTeamSideBarItem url={PATHS.ASSET_TEAM_PAYSLIP} name="PAYSLIP" />
          </span>
        </ul>
      </nav>
    </div>
  );
};

export default AssetTeamSideBar;
