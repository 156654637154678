import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Logout from '../../../shared/auth/Logout';
import Requests from '../../../shared/requests/non-admin/Requests';
import NetworkOperationsSideBar from '../../../../layouts/noc/NetworkOperationsSideBar';
import { NOC, SUPER_ADMIN } from '../../../../../utils/departments/helpers';
import useCheckTwoDepartments from '../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';

const NocRequests = ({ history }) => {
  useCheckTwoDepartments(`${NOC}`, `${SUPER_ADMIN}`, history);

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <div className="d-flex col-lg-12 p-2 top-header">
          <div className="mt-3 d-flex col-lg-12 p-0">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link className="s-sidebar__trigger" to="#">
              <i className="fa fa-bars mr-3" />
            </Link>
            <NetworkOperationsSideBar />
            <h3 className="col-lg-6 col-md-6 col-sm-6 overview-part">Admin Request</h3>

            <div className="d-flex profile-mail col-5">
              <h6>
                <Logout />
              </h6>
            </div>
          </div>
        </div>
        <Requests />
      </main>
    </div>
  );
};

NocRequests.propTypes = {
  // eslint-disable-next-line react/require-default-props
  history: PropTypes.shape(),
};

export default NocRequests;
