
/* eslint-disable import/prefer-default-export */
export const GET_DEVICE_TYPES_URL = `https://api.tizeti.com/api/v1/account/getUsersbyDeviceType`;
export const FETCH_DEVICE_TYPES_URL = `https://api.tizeti.com/api/v1/account/getDeviceTypes`;







