/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import '../../../../../styles/style.css';
import '../../../../../styles/inventory.css';
import axios from 'axios';
import toastr from 'toastr';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AccessPointsTable from './AccessPointsTable';
import { admin, NOC } from '../../../../../utils/config';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import AddAccessPoint from './AddAccessPoint';
import {
  getAccessPoints,
  getAccessPointsByIP,
  getAccessPointsBySsid,
  getPaginatedAccessPoints,
} from '../../../../../actions/noc/access-points/AccessPointActions';
import { getUser } from '../../../../../actions/shared/user/UserActions';
import NocNavBar from '../../../../layouts/noc/NocNavBar';

class AccessPoints extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
      keyword: '',
    };

    this.onChange = this.onChange.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.getUser();
    this.checkDepartment();
    // eslint-disable-next-line react/destructuring-assignment
    this.props.getAccessPoints();
  }

  componentWillReceiveProps() {
    this.checkDepartment();
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  handlePageClick = (pageNumber) => {
    const selected = pageNumber.selected + 1;
    // eslint-disable-next-line react/destructuring-assignment
    this.props.getPaginatedAccessPoints(selected);
  };

  checkDepartment = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.department_id !== `${NOC}` && response.data.department_id !== `${admin}`) {
        // eslint-disable-next-line react/destructuring-assignment
        this.props.history.push('/');

        sessionStorage.setItem('token', '');
        sessionStorage.clear();

        toastr.info('You are not allowed to view this page');
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  onSubmit = (e) => {
    e.preventDefault();

    const { keyword, search } = this.state;

    if (keyword === '') {
      toastr.info('Select category to search from!');
    } else {
      if (keyword === 'ssid') {
        this.setState({
          loading: true,
          // eslint-disable-next-line react/no-unused-state
          paginate: false,
        });

        const data = {
          ssid: search,
        };

        // eslint-disable-next-line react/destructuring-assignment
        this.props.getAccessPointsBySsid(data);
      }

      if (keyword === 'ip') {
        this.setState({
          loading: true,
          // eslint-disable-next-line react/no-unused-state
          paginate: false,
        });

        const data = {
          ip: search,
        };

        // eslint-disable-next-line react/destructuring-assignment
        this.props.getAccessPointsByIP(data);
      }
    }
  };

  render() {
    const { loading, search } = this.state;
    // eslint-disable-next-line react/destructuring-assignment
    const { access, pageCount } = this.props.points;
    const { user } = this.props;
    const { firstName } = user;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <NocNavBar currentPage="Access Points" position={firstName} />

          <div className="container">
            <div className="row mt-5">
              <div className="mt-3 d-flex flex-wrap col-lg-12 col-md-12 col-sm-12">
                <form onSubmit={this.onSubmit} className="form-inline mt-4 d-flex">
                  <select name="keyword" id="" onChange={this.onChange} className="form-control mt-2 mr-3">
                    <option value="">Choose fields to search from</option>
                    <option value="ip">IP</option>
                    <option value="ssid">AP SSID</option>
                  </select>
                  <div className="input-group mt-2 mr-2">
                    <div className="input-group-prepend">
                      <span className="input-group-text fa fa-search" />
                    </div>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search with IP or AP SSID"
                      name="search"
                      value={search}
                      onChange={this.onChange}
                    />
                  </div>
                  <div className="mb-1 mt-2">
                    {/* eslint-disable-next-line react/button-has-type */}
                    <button className="btn btn-green mr-2" style={{ height: '39px' }}>
                      Search{' '}
                    </button>
                  </div>
                </form>
                <div className="mb-1 mt-2">
                  {/* eslint-disable-next-line react/button-has-type */}
                  <button
                    className="btn btn-green mr-2"
                    type="submit"
                    data-target="#points"
                    data-toggle="modal"
                    style={{ height: '39px' }}
                  >
                    Add Access Point
                  </button>
                  <AddAccessPoint />
                </div>
              </div>

              <div className="card x-panel mt-3">
                <div className="table-responsive content col-lg-12 col-md-12 col-sm-12">
                  <table className="table col-lg-12 col-md-12 col-sm-12">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>AP SSID</th>
                        <th>IP Address</th>
                        <th>Https</th>
                        <th>Radio Type</th>
                        <th>Frequency</th>
                        <th>Country</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <AccessPointsTable points={access} load={loading} />
                  </table>
                </div>
              </div>

              {/* <AdminPaginate/> */}

              {pageCount > 0 ? (
                <ReactPaginate
                  previousLabel="previous"
                  nextLabel="next"
                  breakLabel="..."
                  // breakClassName={'break-me'}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={this.handlePageClick}
                  containerClassName="pagination"
                  //       subContainerClassName={'pages pagination'}
                  activeClassName="active"
                />
              ) : null}
            </div>
          </div>
        </main>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  points: state.accessPoints.access,
  user: state.user.user,
});

AccessPoints.propTypes = {
  firstName: PropTypes.string.isRequired,
  getUser: PropTypes.func.isRequired,
  getAccessPoints: PropTypes.func.isRequired,
  getPaginatedAccessPoints: PropTypes.func.isRequired,
  user: PropTypes.shape(),
  history: PropTypes.shape(),
  points: PropTypes.shape(),
  getAccessPointsBySsid: PropTypes.func.isRequired,
  getAccessPointsByIP: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  getAccessPoints,
  getPaginatedAccessPoints,
  getUser,
  getAccessPointsBySsid,
  getAccessPointsByIP,
})(AccessPoints);
