/* eslint-disable react/prop-types */
import React from 'react';

const AccountsManagerTableHead = ({ status, setCheckAllRequisition }) => {
  return (
    <thead>
      <tr>
        <th>#</th>
        <th>
          <input type="checkbox" value="checkedall" onChange={setCheckAllRequisition} />
        </th>
        <th>Amount In Digits</th>
        <th>Amount In Words</th>
        <th>Account Name</th>
        <th>Account Number</th>
        <th>Bank Name</th>
        <th>Accounts Approval Status </th>
        <th>Manager Approval Status </th>
        <th>C LEVEL Approval Status </th>
        <th>CEO/COO LEVEL Approval Status </th>
        <th>In Favor Of</th>
        <th>Requires C Level Approval?</th>
        <th>Requires CEO/COO Level Approval?</th>
        <th>Created At</th>
        <th>Purpose</th>
        {status === 'approved' || status === 'rejected' ? null : (
          <>
            <th>Approve</th>
            <th>Reject</th>
          </>
        )}
        <th>View Uploaded File</th>
        <th>View Details</th>
      </tr>
    </thead>
  );
};

export default AccountsManagerTableHead;
