import React, { Component, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { API_URL } from '../../../../utils/config';
import { showDepartmentName } from '../../../../utils/departments/helpers';

class Employee extends Component {
  constructor(props) {
    super(props);

    this.state = {
      department: '',
    };
  }

  /* componentWillReceiveProps(nextProps) {

        this.setState({
            id: nextProps.employee.id

        });
{showDepartmentName.get(department_id)}
    }
 */
  render() {
    const {
      firstName,
      lastName,
      gender,
      address,
      email,
      alternateEmail,
      officialEmail,
      phoneNumber,
      department_id,
      alternatePhoneNumber,
      extension,
      state,
      date_of_resumption,
      birthday,
      beneficiary,
      nationality,
      country,
      referee,
      role,
      EmployeeAuth,
      guarantor,
      level,
      reporting_line,
    } = this.props.employee;

    // if (this.props.employee.department_id) {
    //   axios({
    //     method: 'get',
    //     url: `${API_URL}/department/${this.props.employee.department_id}`,
    //     headers: {
    //       Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    //     },
    //   }).then((res) => {
    //     if (res.data) {
    //       const department = res.data.data.name;
    //       this.setState({
    //         department,
    //       });
    //     }
    //   });
    // }

    const em = this.props.employee.department;
    let deptName;
    em ? (deptName = em.name) : (deptName = null);

    const roles = {
      1: 'Personnel',
      2: 'Lead',
      3: 'Manager',
      4: 'COO',
      5: 'CEO',
      6: 'Intern',
      7: 'CSO',
      8: 'CTO',
      9: 'CFO',
      10: 'VP',
    };

    // const rl = this.props.employee.role;
    // let role;
    // if (rl === '') {
    //   role = 'N/A';
    // } else {
    //   role = rl;
    // }

    return (
      <div
        id="customer_detail"
        className="modal fade in"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center" id="myModalLabel">
                Employee Details
              </h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
            </div>
            <div className="modal-body detail" style={{ padding: 0 }}>
              <div className="row">
                <div className="col-lg-12 d-flex">
                  <div className="col-lg-4 detail-label1">
                    <label id="fn">First Name</label>
                    <label id="ln">Last Name</label>
                    <label id="gen">Gender</label>
                    <label id="gen">DOB</label>
                    <label id="gen">Nationality</label>
                  </div>

                  <div className="col-lg-8 detail-result1">
                    <p className="mt-4 mb-1">{firstName || 'N/A'}</p>
                    <p className="mb-1">{lastName || 'N/A'}</p>
                    <p className="mb-1">{gender || 'N/A'}</p>
                    <p className="mb-1">{birthday || 'N/A'}</p>
                    <p className="mb-1">{nationality || 'N/A'}</p>
                  </div>
                </div>
                <hr />
                <div className="col-lg-12 d-flex">
                  <div className="col-lg-4 detail-label2">
                    <label id="address">Address</label>
                    <label id="office-mail">Official Email</label>
                    <label id="alt-email">Alternate Email</label>
                    <label id="phone1">Phone number</label>
                    <label id="country">Country Assigned </label>

                    <label id="state">State</label>
                  </div>

                  <div className="col-lg-8 detail-result2">
                    <p className="mt-4 mb-1">{address || 'N/A'}</p>
                    <p className="mb-1">{email || 'N/A'}</p>
                    <p className="mb-1">{alternateEmail || 'N/A'}</p>
                    <p className="mb-1">{phoneNumber + ' ' + '/' + ' ' + alternatePhoneNumber || 'N/A'}</p>
                    <p className="mb-1">{country || 'N/A'}</p>
                    <p className="mb-1">{state || 'N/A'}</p>

                    {/* <p className="mb-1">{role || 'DDA'}</p> */}
                  </div>
                </div>

                <div className="col-lg-12 d-flex">
                  <div className="col-lg-4 detail-label3">
                    <label id="dep">Department</label>
                    <label id="date_of_resumption">Date of Resumption</label>
                    <label id="beneficiary">Beneficiary</label>
                    <label id="guarantor">Guarantor</label>
                    <label id="referee">Referee</label>
                  </div>

                  <div className="col-lg-8 detail-result3">
                    <p className="mt-4 mb-1">{showDepartmentName.get(department_id) || 'N/A'}</p>
                    <p className="mb-1">
                      {(date_of_resumption && moment(date_of_resumption).format('MM/DD/YYYY')) || 'N/A'}
                    </p>
                    <p className="mb-1">{beneficiary || 'N/A'}</p>
                    <p className="mb-1">{guarantor || 'N/A'}</p>
                    <p className="mb-1">{referee || 'N/A'}</p>
                  </div>
                </div>

                <div className="col-lg-12 d-flex">
                  <div className="col-lg-4 detail-label4">
                    <label id="extension">Extension</label>
                    <label id="role">Role / Designation</label>
                    <label id="level" style={{ display: 'block' }}>
                      Level
                    </label>
                    <label id="reporting_line" style={{ display: 'block' }}>
                      Reporting Line
                    </label>
                    {/* <label id="employee-id" style={{ display: 'block' }}>
                      Employee ID
                    </label> */}
                  </div>

                  <div className="col-lg-8 detail-result4">
                    {/* <p className="mt-4 mb-1">{roles[role] || 'N/A'}</p> */}
                    <p className=" mt-4 mb-1">{extension || 'N/A'}</p>
                    <p className=" mb-1">{(EmployeeAuth && EmployeeAuth.role.name) || 'N/A'}</p>
                    <p className="mb-1">{level || 'N/A'} </p>
                    <p className="mb-1">{reporting_line || 'N/A'}</p>
                    {/* <p className="mb-1">{this.props.employee.id || 'N/A'}</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Employee;
