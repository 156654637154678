/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useContext } from 'react';
import edit from '../../../../../../assets/img/icons/edit.png';
import helpers from '../../../../../../utils/helpers';
import view from '../../../../../../assets/img/icons/view.png';
import ViewEnbSiteIpAllocation from './ViewEnbSiteIpAllocation';
// import { EnbSiteIpAllocationProps } from './types/EnbSiteIpAllocationProps';
import { EnbSiteIpAllocationContext } from './context/EnbSiteIpAllocationContext';
import loader from '../../../../../../assets/img/icons/TizetiLoaderColored.gif';

const EnbSiteIpAllocationTable = () => {
  const { data, loading } = useContext(EnbSiteIpAllocationContext);
  const [one, setOne] = useState({
    created_at: '',
    deleted_at: '',
    id: '',
    updated_at: '',
    eNB_vendor: '',
    site: '',
    ip_address: '',
  });
  const onClick = (params) => {
    setOne(params);
  };
  const tableData =
    data && data.length
      ? data.map((row, i) => (
          <tr className="text-nowrap" key={row.id}>
            <td>{i + 1}</td>
            <td>{row.site}</td>
            <td>{row.eNB_vendor}</td>
            <td>{row.ip_address}</td>
            <td>{helpers.formatDateAndTime(row.created_at)}</td>
            <td>
              <div className="text-center">
                <button
                  type="submit"
                  className="mr-2"
                  data-toggle="modal"
                  data-placement="right"
                  data-target="#view_access-point"
                  onClick={() => onClick(row)}
                >
                  <span className="view">
                    <img src={view} data-toggle="tooltip" alt="" data-placement="top" title="View" />
                  </span>
                </button>
                <button
                  type="submit"
                  className="mr-2"
                  data-toggle="modal"
                  data-placement="right"
                  data-target="#update_item"
                  // onClick={() => getItem(list)}
                >
                  <span className="view">
                    <img src={edit} data-toggle="tooltip" alt="" data-placement="top" title="Edit" />
                  </span>
                </button>
              </div>
              <ViewEnbSiteIpAllocation view={one} />
              {/* <EditRetrievalItem OneItem={one} /> */}
            </td>
          </tr>
        ))
      : 'no enp site ip';
  return (
    <div className="card x-panel mt-2 px-0">
      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Site</th>
              <th className="text-nowrap">Enb Vendor</th>
              <th>IP Address</th>
              <th>Date Created</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{loading ? <img src={loader} alt="gif" /> : tableData}</tbody>
        </table>
      </div>
    </div>
  );
};

export default EnbSiteIpAllocationTable;
