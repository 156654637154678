import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer as ResCompontent } from 'recharts';
import Skeleton from '@mui/material/Skeleton';
import SimpleBackdrop from '../ActiveExpiredUsersByAssetZone/Backdrop';
function getRandomColor() {
  const minBrightness = 64; // Minimum brightness to avoid black
  const maxBrightness = 200; // Maximum brightness to avoid white

  function randomChannel() {
    return Math.floor(Math.random() * 256);
  }

  function isColorTooDark(r, g, b) {
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness < minBrightness;
  }

  function isColorTooLight(r, g, b) {
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness > maxBrightness;
  }

  let color;
  do {
    const red = randomChannel();
    const green = randomChannel();
    const blue = randomChannel();
    color = `rgb(${red},${green},${blue})`;
  } while (isColorTooDark(color) || isColorTooLight(color));

  return color;
}

export default function PieChartDisplay({ getZones, assetZoneData, isLoading }) {
  const [getData, setGetData] = React.useState([]);
  // const [randomColors, setRandomColors] = React.useState([]);

  // Generate 35 random colors using useMemo
  const randomColors = React.useMemo(() => {
    const numberOfColors = 35;
    return Array.from({ length: numberOfColors }, getRandomColor);
  }, []);

  React.useEffect(() => {
    if (getZones.length > 0 && assetZoneData.length) {
      const refinedData = getZones.map((state, index) => ({
        name: state.name,
        InstallationCount: assetZoneData[index] && assetZoneData[index].count,
      }));

      if (refinedData[getZones.length - 1].InstallationCount !== undefined) {
        console.log(refinedData);
        const filterDataToGetValuesGreaterThanZeroOnly = refinedData.filter((val) => val.InstallationCount > 0);
        setGetData(filterDataToGetValuesGreaterThanZeroOnly);
      }
    }
  }, [assetZoneData.length]);

  return (
    <Box sx={{ mt: 5 }}>
      {assetZoneData.length < getZones.length && isLoading ? (
        <Box>
          <Skeleton
            width={550}
            height={550}
            variant="circular"
            sx={{
              bgcolor: '#8884d87b',
            }}
          />
          <SimpleBackdrop isLoading />
        </Box>
      ) : (
        <Box width="100%" height="100%">
          <PieChart width={550} height={550}>
            <Pie
              dataKey="InstallationCount"
              isAnimationActive={true}
              data={getData}
              cx="50%"
              cy="50%"
              outerRadius={240}
              fill="#908cdb"
              label
            >
              {getData.map((entry, index) => (
                <Cell key={`${entry.name}-${entry.InstallationCount}`} fill={randomColors[index]} />
              ))}
            </Pie>

            <Tooltip />
          </PieChart>

          <Box maxWidth={650}>
            <Typography variant="h6"> Key </Typography>
            <Box display="flex" justifyContent="center" alignItems="center" gap={2} flexWrap="wrap">
              {getData.map((entry, index) => (
                <Box key={index} display="flex" justifyContent="center" alignItems="center" gap={1}>
                  <Box sx={{ width: 15, height: 15, borderRadius: '50%', background: randomColors[index] }}></Box>
                  <Typography>
                    {entry.name} - {entry.InstallationCount}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
