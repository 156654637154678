import axios from 'axios';
import { toast } from 'react-toastify';
import { useReducer } from 'react';
import { GET_USAGE_STATISTICS } from '../../../constants/types/shared/customers/UsageHistoryTypes';
import { GET_CUSTOMERS_USAGE_STATISTICS_URL } from '../../../services/api/shared/customers/CustomersUrl';
import UsageStatisticsReducer from '../../../reducers/shared/customers/usage-statistics/UsageStatisticsReducer';
import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';

const useUsageStatistics = () => {
  const [{ items, totalPages, currentPage, allPages, isLoading }, dispatch] = useReducer(UsageStatisticsReducer, {
    items: [],
    totalPages: '',
    allPages: '',
    currentPage: '',
    isLoading: false,
  });

  const getCustomersUsage = async (data) => {
    dispatch({ type: LOADING_STARTS });
    try {
      const response = await axios.post(`${GET_CUSTOMERS_USAGE_STATISTICS_URL}`, data);
      if (response.data) {
        dispatch({
          type: GET_USAGE_STATISTICS,
          payload: {
            items: response.data.items,
            totalPages: response.data.total_records,
            allPages: response.data.total_pages,
            currentPage: response.data.current_page,
          },
        });
      }
    } catch (e) {
      toast.info('Something went wrong!');
      if (e.response) {
        dispatch({ type: LOADING_STOPS });
      }
    }
  };

  return { getCustomersUsage, items, totalPages, allPages, currentPage, isLoading };
};

export default useUsageStatistics;
