/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './create-customer.css';
import useCheckTwoDepartments from '../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';
import { admin, customer_support } from '../../../../../utils/config';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import CustomerSupportNavBar from '../../../../layouts/customer-support/CustomerSupportNavBar';
import CreateCustomerForm from './CreateCustomerForm';

const CreateCustomer = ({ history }) => {
  const { profile, user } = useUser();
  useCheckTwoDepartments(`${customer_support}`, `${admin}`, history);

  return (
    <div className="s-layout" data-testid="create-customer">
      <main className="s-layout__content col-lg-10 p-0">
        <CustomerSupportNavBar currentPage="Create Customer" roleID={user.role_id} position={profile.firstName} />
        <CreateCustomerForm />
      </main>
    </div>
  );
};
export default CreateCustomer;
