/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createPosting } from '../../../../../actions/shared/recruitment/RecruitmentActions';
import { getUser } from '../../../../../actions/shared/user/UserActions';
import { getLoggedInUser } from '../../../../../actions/user/UserActions';

class NewPosting extends Component {
  static propTypes = {
    department: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    getUser: PropTypes.func.isRequired,
    createPosting: PropTypes.func.isRequired,
    getLoggedInUser: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      title: '',
      responsibility: '',
      qualification: '',
      deadline: '',
      extra: '',
      loading: false,
      location: '',
    };

    this.createJob = this.createJob.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.props.getUser();
    this.props.getLoggedInUser();
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  createJob = (e) => {
    this.setState({
      loading: true,
    });

    e.preventDefault();

    const { title, responsibility, qualification, deadline, extra, location } = this.state;
    const { department_id } = this.props.department;

    const job = {
      job_title: title,
      responsibilities: responsibility,
      minimum_qualifications: qualification,
      deadline,
      department_id,
      extra,
      location,
    };

    this.props.createPosting(job);
  };

  submitForm = () => {
    const { extra, deadline, qualification, responsibility, title, location } = this.state;

    return (
      extra.length > 0 &&
      deadline.length > 0 &&
      qualification.length > 0 &&
      responsibility.length > 0 &&
      title.length > 0 &&
      location.length > 0
    );
  };

  render() {
    const isEnabled = this.submitForm();

    const {
      title,
      responsibility,
      qualification,
      deadline,
      extra,
      loading,
      //   location,
    } = this.state;

    return (
      <div
        id="Newposting"
        className="modal fade in"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center" id="myModalLabel">
                Add Job Posting
              </h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
            </div>
            <div className="modal-body">
              <form className="form-horizontal" onSubmit={this.createJob}>
                <div className="form-group">
                  <div className="col-12 d-flex p-0 mb-3">
                    <div className="col-6">
                      <label className="copy-font m-0 p-0">Job Title</label>
                      <input
                        type="text"
                        name="title"
                        onChange={this.onChange}
                        value={title}
                        className="form-control"
                        placeholder="Job Title"
                      />
                    </div>
                  </div>

                  <div className="col-12 mb-3">
                    <div>
                      <label className="copy-font m-0 p-0">Responsibilities</label>
                      <br />
                      <textarea
                        rows="5"
                        cols="45"
                        name="responsibility"
                        onChange={this.onChange}
                        value={responsibility}
                        placeholder="Job Responsibilities"
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-12 mb-3">
                    <label className="copy-font m-0 p-0">Minimum Qualifications</label>
                    <br />
                    <textarea
                      rows="5"
                      cols="45"
                      placeholder="Minimum Qualifications"
                      name="qualification"
                      onChange={this.onChange}
                      value={qualification}
                      className="form-control"
                    />
                  </div>

                  <div className="col-12 d-flex p-0 mb-3">
                    <div className="col-6">
                      <label className="copy-font m-0 p-0">Extras</label>
                      <textarea
                        rows="5"
                        cols="45"
                        name="extra"
                        onChange={this.onChange}
                        value={extra}
                        placeholder="extras"
                        className="form-control"
                      />
                    </div>

                    <div className="col-6">
                      <label className="copy-font m-0 p-0">Application Deadline</label>
                      <input
                        type="date"
                        className="form-control"
                        name="deadline"
                        onChange={this.onChange}
                        value={deadline}
                      />
                    </div>
                  </div>

                  <div className="col-12 d-flex p-0 mb-3">
                    <div className="col-6">
                      <label className="copy-font m-0 p-0">Location</label>
                      <select className="form-control" name="location" onChange={this.onChange}>
                        <option value="">Select Coutnry</option>
                        <option value="ng">Nigeria</option>
                        <option value="gh">Ghana</option>
                      </select>
                    </div>
                  </div>
                </div>
                {loading ? (
                  <div className="text-center mt-5">
                    <button className="btn btn-green" type="button" disabled>
                      <i className="fa fa-spinner fa-spin" />
                    </button>
                  </div>
                ) : (
                  <div className="text-center mt-5">
                    <button className="btn btn-green" type="submit" disabled={!isEnabled}>
                      Add Posting
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // departments: state.userDepartment.departments,
  department: state.auth.auth,
});

export default connect(mapStateToProps, {
  createPosting,
  getUser,
  getLoggedInUser,
})(NewPosting);
