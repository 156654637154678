/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import { messageToBeSent } from './utils/helpers';

const SalesSendBulkSms = ({ show, onClose, sendSms, status, setStatus, loading }) => {
  const { profile } = useUser();
  const onSubmit = (e) => {
    e.preventDefault();

    sendSms();
  };
  const submitForm = () => {
    return status.length > 0;
  };

  const displayButton = () => {
    if (loading) {
      return (
        <button type="submit" className="btn btn-green" disabled={loading}>
          Sending SMS...
        </button>
      );
    }
    return (
      <button type="submit" className="btn btn-green" disabled={!submitForm()}>
        Send SMS
      </button>
    );
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ paddingLeft: '2rem' }}>Send Bulk SMS</Modal.Title>
      </Modal.Header>
      <form className="form-horizontal" onSubmit={onSubmit}>
        <div className="form-group">
          <div className="col-12 mb-3">
            <div className="col-12">
              <select
                id="status"
                name="status"
                onChange={(e) => setStatus(e.target.value)}
                className="form-control border border-info py-2"
              >
                <option value="">--Select Status--</option>
                <option value="inform">Inform</option>
                <option value="promo">Promo</option>
                <option value="follow-up">Follow Up</option>
              </select>
            </div>
          </div>

          {status.length > 0 && (
            <div className="col-12 mb-3">
              <div className="col-12">
                <textarea
                  style={{ height: '200px' }}
                  className="form-control border border-info py-2"
                  value={messageToBeSent(status, profile.firstName)}
                />
              </div>
            </div>
          )}
          <div className="col-12 mb-3 text-center">{displayButton()}</div>
        </div>
      </form>
    </Modal>
  );
};

export default SalesSendBulkSms;
