import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';
import { UPLOADING_INSTALLATION_IMAGES } from '../../../constants/types/operations/installations/InstallationsTypes';

const initialState = {
  load: false,
  loaded: 0,
};

export const OperationsInstallationsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOADING_STARTS:
      return {
        ...state,
        load: payload,
      };

    case UPLOADING_INSTALLATION_IMAGES:
      return {
        ...state,
        loaded: payload,
      };

    case LOADING_STOPS:
      return {
        ...state,
        load: false,
      };

    default:
      return state;
  }
};

export default OperationsInstallationsReducer;
