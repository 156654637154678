/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import EditDevice from './EditDevice';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import edit from '../../../../../assets/img/icons/edit.png';

class SolarCountTable extends Component {
  render() {
    const { solarcount } = this.props;

    /* EmployeeAuth: {id: "b65b82bb-b2f5-4484-b767-523973efefd1", email: "nocpersonnel@tizeti.com", department_id: "6e072411-d6eb-413d-be6c-c4b5bc812320", staff: Array(0)}

       */

    if (this.props.load) {
      return (
        <tbody>
          <tr>
            <td>
              <img src={loader} alt="" />
            </td>
          </tr>
        </tbody>
      );
    }

    const solarList = solarcount.length ? (
      solarcount.map((solar, i) => (
        <tr key={i + 1}>
          <td>{i + 1}</td>
          <td>{solar.baseStation.name}</td>
          <td>{solar.ebn}</td>
          <td nowrap="no-wrap">{solar.gracia}</td>
          <td nowrap="no-wrap">{solar.solar_panels}</td>
          <td>
            {' '}
            <div>
              <button
                type="submit"
                data-toggle="modal"
                data-target="#editDevice"
                style={{
                  border: '0',
                  backgroundColor: 'white',
                }}
              >
                <span className="view">
                  <img src={edit} alt="" data-toggle="tooltip" data-placement="top" title="Edit" />
                </span>
              </button>
              <EditDevice />
            </div>
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td>
          <p>No Batteries!</p>
        </td>
      </tr>
    );

    return <tbody id="reduce-length">{solarList}</tbody>;
  }
}

export default SolarCountTable;
