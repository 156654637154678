/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import toastr from 'toastr';
import axios from 'axios';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import { admin, operations, TECHNICAL_SUPPORT } from '../../../../../utils/config';
import Logout from '../../../shared/auth/Logout';
import { getUser } from '../../../../../actions/shared/user/UserActions';
import FieldSupportSideBar from '../../../../layouts/field-support/side-bar/FieldSupportSideBar';
import { getLoggedInUser } from '../../../../../actions/user/UserActions';
import Requisitions from '../../../shared/requisition/shared/Requisitions';

class FieldSupportRequisitions extends Component {
  componentDidMount() {
    this.checkDepartment();
    this.props.getUser();
    this.props.getLoggedInUser();
  }

  componentWillReceiveProps() {
    this.checkDepartment();
  }

  checkDepartment = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (
        response.data.department_id !== `${operations}` &&
        response.data.department_id !== `${TECHNICAL_SUPPORT}` &&
        response.data.department_id !== `${admin}`
      ) {
        this.props.history.push('/login');
        sessionStorage.setItem('token', '');
        sessionStorage.setItem('email', '');
        sessionStorage.clear();
        toastr.info('You are not allowed to view this page');
      }
    } catch (e) {
      toastr.info(`Error Fetching Data`);
    }
  };

  render() {
    const { user } = this.props;
    const { firstName } = user;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <Link className="s-sidebar__trigger" to="#">
                <i className="fa fa-bars mr-3" />
              </Link>
              <FieldSupportSideBar />

              <h3 className="col-lg-6 overview-part">Requisition</h3>
              <span className="navbar-text">
                <h6 className="admin">
                  {firstName}
                  <Logout />
                </h6>
              </span>
            </div>
          </div>
          <div className="after-summary">
            <Requisitions />
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  role: state.auth.auth,
});

FieldSupportRequisitions.propTypes = {
  getUser: PropTypes.func.isRequired,
  getLoggedInUser: PropTypes.func,
  user: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  history: PropTypes.shape(),
};

export default connect(
  mapStateToProps,
  {
    getUser,
    getLoggedInUser,
  },
)(FieldSupportRequisitions);
