/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getAllRetailers } from '../../../../../../../actions/hotspot/RetailersActions';

class TotalRetailers extends Component {
  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.getAllRetailers();
  }

  render() {
    const { retailers, load } = this.props;

    return (
      <div className="col-lg-3 col-md-3 col-sm-4">
        <div className="boxes border-box1">
          <div className="d-flex mt-3">
            <div className="mt-4 ml-3 mr-2">
              <img
                src="https://res.cloudinary.com/teewhy/image/upload/v1547546149/hotspotadminidk.fw.png"
                alt="retailer-icon"
                className="w-75 mt-3"
              />
            </div>
            <div className="mt-4">
              <div className="count">
                {load ? (
                  <small>loading...</small>
                ) : (
                  retailers.length
                )}
              </div>
              <h6 className="baby-fonts">
                Total Retailers
              </h6>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  retailers: state.retailers.retailers,
  load: state.retailers.load,
});

TotalRetailers.propTypes = {
  getAllRetailers: PropTypes.func.isRequired,
  retailers: PropTypes.shape(),
  load: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, {
  getAllRetailers,
})(TotalRetailers);
