/* eslint-disable no-case-declarations */
/* eslint-disable import/prefer-default-export */
import * as types from '../../../constants/types/shared/requisition/RequisitionTypes';
import * as load from '../../../constants/types/shared/LoadingTypes';

export const requisitionReducer = (state, action) => {
  const { payload, type } = action;
  switch (type) {
    case load.FETCHING_STARTS:
      return {
        ...state,
        isFetching: true,
        isFetchingExecRequisitions: true,
      };

    case load.FETCHING_STOPS:
      return {
        ...state,
        isFetching: false,
      };

    case types.GET_REQUISITIONS:
      return {
        ...state,
        requisitions: payload,
      };

    case load.LOADING_STARTS:
      return {
        ...state,
        isLoading: true,
      };

    case load.LOADING_STOPS:
      return {
        ...state,
        isLoading: false,
      };

    case types.START_APPROVE_OR_DISAPPROVE:
      return {
        ...state,
        isApprovingOrDisapproving: true,
      };

    case types.STOP_APPROVE_OR_DISAPPROVE:
      return {
        ...state,
        isApprovingOrDisapproving: false,
      };

    case types.APPROVE_OR_DISAPPROVE_SUCCESS:
      return {
        ...state,
        isApprovingOrDisapproving: false,
        managerRequisitions: state.managerRequisitions.filter((req) => req.id !== payload),
        executiveRequisitions: state.executiveRequisitions.rows.filter((req) => req.id !== payload),
        // executiveRequisitions: state.executiveRequisitions.map((req) =>
        //   req.id === payload ? payload : req,
        // ),
      };

    case types.GET_MANAGERS_REQUISITIONS:
      return {
        ...state,
        managerRequisitions: payload.managerRequisitions,
        totalPages: payload.totalPages,
        isFetching: false,
      };

    case types.GET_MANAGER_REQUISITIONS:
      return {
        ...state,
        managerRequisitions: payload,
        isFetching: false,
      };

    case types.GET_EXECUTIVES_REQUISITIONS:
      return {
        ...state,
        executiveRequisitions: payload,
        isFetchingExecRequisitions: false,
      };

    case types.GET_EMPLOYEES_REQUISITIONS:
      return {
        ...state,
        employeesRequisitions: payload.employeesRequisitions,
        employeesPages: payload.employeesPages,
        isFetching: false,
      };

    case types.APPROVE_SUCCESS:
      return {
        ...state,
      };

    case types.REQUISITION_FILLED:
      return {
        ...state,
        isLoading: false,
        // employeesRequisitions: [...state.employeesRequisitions, payload]
        // employeesRequisitions:  Object.assign({ }, ...state.employeesRequisitions, payload)
        // var clone = {...this.state.myObj};
        //
        // managerRequisitions: [payload, ...state.managerRequisitions],
        // executivesRequsitions: [payload, ...state.executivesRequsitions],
      };

    default:
      return state;
  }
};
