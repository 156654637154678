/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import ExpiredCustomersPerCountryBody from '../../../shared/customers/ExpiredCustomersPerCountryBody';
import CustomerSupportNavBar from '../../../../layouts/customer-support/CustomerSupportNavBar';
import { useUser } from '../../../../../hooks/shared/user/useUser';

const CustomerSupportExpiredCustomersPerCountry = () => {
  const { profile, user } = useUser();
  const { firstName } = profile;
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <CustomerSupportNavBar currentPage="Expired Customers Per Country" roleID={user.role_id} position={firstName} />
        <ExpiredCustomersPerCountryBody profile={profile} />
      </main>
    </div>
  );
};

export default CustomerSupportExpiredCustomersPerCountry;
