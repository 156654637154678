import { useState } from 'react';
import axios from 'axios';

const useLatencyGraph = () => {
  const [lat, setLat] = useState('');
  const [latency, setLatency] = useState(null);

  const showLatency = async (e) => {
    const { value } = e.target;
    setLat(value);
    if (lat) {
      const url = `http://127.0.0.1:5000/api/v1/latency?latency=0.9098&base_station_id=${value}`;
      try {
        const response = await axios.get(`${url}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });
        setLatency(response.data);
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }
  };

  return { showLatency, latency };
};

export default useLatencyGraph;
