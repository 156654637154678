/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/style-prop-object */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */

/* eslint-disable @typescript-eslint/camelcase */
import React, { useContext, useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import AppContext from '../../../../context/shared/AppContext';
import EditIcon from '../../../../assets/img/icons/edit.png';
import view from '../../../../assets/img/icons/view.png';
import erase from '../../../../assets/img/icons/delete.png';
// import ViewRetrieval from './ViewRetrieval';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../utils/loader/loadingHelper';
// import CustomButton from '../forms/CustomButton';
// import DeviceConfirmation from '../../departments/field-support/device-retrieval/DeviceConfirmation';
import MoveToProcurement from '../device-retrieval/MoveToProcurement';
import ReturnRetrieval from './ReturnRetrieval';
import ConfirmDeleteRetrieval from '../device-retrieval/ConfirmDeleteRetrieval';
import ViewDeviceRetrieval from '../device-retrieval/types/ViewDeviceRetrieval';

const colSpan = 14;
const RetrievalTable = ({ lists, load }) => {
  const { pages, getDeviceRetrieval, onOpenMoveToProcurement } = useContext(AppContext);
  const currentPage = 1;
  const [one, setOne] = useState({});

  useEffect(() => {
    getDeviceRetrieval(currentPage);
  }, []);

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    getDeviceRetrieval(selectedPage);
  };

  const getItem = (list) => setOne(list);

  const tableData =
    lists && lists.length ? (
      lists.map((list, i) => {
        return (
          <tr className="">
            <td>{i + 1}</td>
            <td>{list.name}</td>
            <td>{list.mac}</td>
            <td>{list.email}</td>
            <td>{list.phone}</td>
            <td>{list.customer_number || 'No Number'}</td>
            {/* <td>
              <div className="text-center">
                <CustomButton
                  classname="btn btn-green"
                  disabled={false}
                  onClick={() => {
                    getItem(list);
                    onOpenDeviceConfirmation();
                  }}
                  name="Device Confirmation"
                />
              </div>
            </td> */}
            <td>
              <div className="d-flex">
                <button
                  type="button"
                  onClick={() => {
                    getItem(list);
                    onOpenMoveToProcurement();
                  }}
                  className="noBorder"
                >
                  <span className="view1">
                    <img src={EditIcon} alt="move to procurement" title="Move To Procurement" />
                  </span>
                </button>

                <button
                  type="submit"
                  className="noBorder"
                  data-toggle="modal"
                  data-placement="right"
                  data-target="#view_device_retrieval"
                  onClick={() => {
                    getItem(list);
                  }}
                >
                  <span className="view">
                    <img
                      src={view}
                      alt="view device retrieval"
                      data-toggle="tooltip"
                      data-placement="left"
                      title="View Device Retrieval"
                    />
                  </span>
                </button>

                <button
                  type="button"
                  data-toggle="modal"
                  data-placement="right"
                  data-target="#confirm_item"
                  onClick={() => getItem(list)}
                  className="mx-2 noBorder"
                >
                  <span className="view">
                    <i
                      className="fa fa-arrow-circle-right"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Return To Retention"
                    />
                  </span>
                </button>
                {Number(sessionStorage.getItem('roleId')) > 2 && (
                  <button
                    type="button"
                    className="mr-2 noBorder"
                    data-target="#confirm_delete"
                    onClick={() => getItem(list)}
                    data-toggle="modal"
                  >
                    <span className="view">
                      <img src={erase} alt="" data-toggle="tooltip" data-placement="top" title="Delete" />
                    </span>
                  </button>
                )}
              </div>
            </td>
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan={colSpan} text="No Customer" />
    );

  return (
    <div className="card x-panel mt-2 px-0">
      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <thead>
            <tr className="">
              <th>#</th>
              <th className="text-nowrap">Name</th>
              <th>MAC ID</th>
              <th>Email</th>
              <th>Phone</th>
              <th className="text-nowrap">Customer Number</th>
              <th>Action</th>
              {/* <th>Move To Procurement</th> */}
            </tr>
          </thead>
          <tbody>{load ? <TizetiGIFLogoTable colSpan={colSpan} /> : tableData}</tbody>
        </table>
      </div>
      {/* <DeviceConfirmation show={openDeviceConfirmation} onClose={onOpenDeviceConfirmation} customer={one} /> */}
      <MoveToProcurement one={one} />
      <ViewDeviceRetrieval one={one} />
      <ReturnRetrieval oneItem={one} />
      <ConfirmDeleteRetrieval one={one} />
      {pages > 1 ? (
        <ReactPaginate
          previousLabel="previous"
          nextLabel="next"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={pages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName="pagination"
          activeClassName="active"
        />
      ) : null}
    </div>
  );
};

export default RetrievalTable;
