/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { SALES, SUPER_ADMIN, VICE_PRESIDENT_SALES } from '../../../../../utils/departments/helpers';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import PurchaseOrderBody from '../../../shared/purchase-order/PurchaseOrderBody';
import SalesNavBar from '../../../../layouts/sales/SalesNavBar';
import useCheckFiveDepartments from '../../../../../hooks/shared/custom/check-department/useCheckFiveDepartments';

const SalesPurchaseOrder = ({ history }) => {
  useCheckFiveDepartments(`${SALES}`, `${SUPER_ADMIN}`, `${VICE_PRESIDENT_SALES}`, history);
  const {
    profile: { firstName, department_id },
  } = useUser();

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <SalesNavBar currentPage="Purchase Order" position={firstName} />
        <div className="container">
          <PurchaseOrderBody deptID={department_id} />
        </div>
      </main>
    </div>
  );
};

SalesPurchaseOrder.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default SalesPurchaseOrder;
