import { useReducer, useEffect } from 'react';
import axios from 'axios';
import qs from 'qs';
import toastr from 'toastr';
import * as load from '../../../../constants/types/shared/LoadingTypes';
import * as types from '../../../../constants/types/asset-team/AssetTeamTypes';
import { expiredZoneReducer } from '../../../../reducers/asset-team/expired-zone/expiredZoneReducer';
import { GET_ROUTER_URL } from '../../../../services/api/shared/BasestationsUrl';

export const useExpiredZones = () => {
  const [{ items, total, isLoading, time }, dispatch] = useReducer(expiredZoneReducer, {
    items: [],
    total: 0,
    isLoading: false,
    time: '',
  });

  useEffect(() => {
    async function getExpiredZones() {
      const params = {
        action: 'tizeti_asset_zone_expiration_count',
      };
      const config = {
        method: 'post',
        url: `${GET_ROUTER_URL}`,
        data: qs.stringify(params),
      };

      await axios(config)
        .then((response) => {
          if (response.data !== '') {
            dispatch({
              type: types.GET_EXPIRED_CUSTOMER_ZONE_DETAILS,
              payload: {
                items: response.data.total_expired_per_zone.items,
                time: response.data.readable_last_updated_at,
                total: response.data.total_expired,
              },
            });
          }
        })
        .catch((error) => {
          if (error) {
            toastr.info('Something went wrong!');
            dispatch({ type: load.LOADING_STOPS });
          }
        });
    }
    getExpiredZones();
  }, []);

  return {
    time,
    items,
    total,
    isLoading,
  };
};
