import React from 'react';
import { CSVLink } from 'react-csv';

const Download = ({ data, headers, filename, classname, textcolor, text }) => {
  return (
    <CSVLink data={data} headers={headers} filename={filename} className={classname} style={{ color: textcolor }}>
      {text}
    </CSVLink>
  );
};

export default Download;
