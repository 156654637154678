/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from '../../installations/NewDesign.module.css';
import { useEditZone } from '../../../shared/installations/zones/hooks/useEditZone';

const NewDesignEditZone = ({ request, request: { name, no_of_installations, id } }) => {
  const { loading, editZone } = useEditZone();
  const [one, setOne] = useState({
    zone: '',
    no_install: '',
  });
  const { zone, no_install } = one;

  useEffect(() => {
    const result = {
      zone: name,
      no_install: no_of_installations,
    };
    setOne({ ...result });
  }, [request]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOne({ ...one, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      name: zone,
      no_of_installations: no_install,
    };
    editZone(id, data);
  };

  //   const submitForm = () => {
  //     return no_install.length > 0;
  //   };

  return (
    <div id="edit-zone" className="modal fade" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header shadow">
            <h4 className="modal-title update_asset">Edit Zone</h4>
            <button type="button" className="close mr-2" data-dismiss="modal">
              x
            </button>
          </div>
          <div className="modal-body detail mb-4 p-0">
            <form onSubmit={onSubmit} className="col-md-12">
              <div className="col-12 mb-3 d-flex">
                <div className="col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="firstName">
                    Zone
                  </label>
                  <input id="zone" name="zone" placeholder="Enter Zone" value={zone} onChange={handleChange} />
                </div>
                <div className="col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="firstName">
                    No of installations
                  </label>
                  <select id="no_install" name="no_install" value={no_install} onChange={handleChange} required>
                    <option value="">Select an option</option>
                    <option value="18">18</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="8">8</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                  </select>
                </div>
              </div>

              <div className="text-center mt-3">
                {loading ? (
                  <button type="button" className={styles.drop} disabled>
                    Updating...
                  </button>
                ) : (
                  <button type="submit" className={styles.drop}>
                    Update
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

NewDesignEditZone.propTypes = {
  request: PropTypes.objectOf.isRequired,
};

export default NewDesignEditZone;
