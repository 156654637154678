import { useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import voltageMonitorReducer from '../../reducers/shared/noc/voltage-monitor/voltageMonitorReducer';
import { LOADING_STARTS, LOADING_STOPS } from '../../constants/types/shared/LoadingTypes';
import { GET_VOLTAGE_MONITOR_URL } from '../../services/api/shared/noc/VoltageMonitorUrl';
import { GET_VOLTAGES, GET_LOW_VOLTAGES } from '../../constants/types/shared/noc/VoltageMonitorTypes';

const useVoltageMonitor = () => {
  const [threshold] = useState('');
  const [value, setValue] = useState('');
  const [keyword, setKeyword] = useState('');

  const [{ voltages, loading, lowVoltage, filtered }, dispatch] = useReducer(voltageMonitorReducer, {
    lowVoltage: [],
    voltages: [],
    loading: false,
    filtered: [],
  });

  const onChange = (e) => {
    // const { name, value } = e.target;
    // setKeyword({
    //   ...keyword,
    //   [name]: value
    // })
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setValue(threshold);
    localStorage.setItem('valu', value);
  };

  const getVoltageMonitor = async () => {
    dispatch({ type: LOADING_STARTS });
    try {
      const response = await axios.get(`${GET_VOLTAGE_MONITOR_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        }
      });
      if (response.data) {
        dispatch({ type: GET_VOLTAGES, payload: response.data });
      } else {
        dispatch({
          types: LOADING_STOPS,
        });
      }
    } catch (e) {
      if (e) {
        dispatch({
          types: LOADING_STOPS,
        });
      }
    }
  };
  const searchVoltage = (e) => {
    setKeyword({
      ...keyword,
      [e.target.name]: e.target.value,
    });
    setKeyword(e.target.value);
    if (keyword !== '') {
      const list = voltages.filter((ips) => {
        if (ips.name.toLowerCase().indexOf(keyword) !== -1) {
          return ips;
        }
        if (ips.ip_address.toLowerCase().indexOf(keyword) !== -1) {
          return ips;
        }
      });
      setKeyword(e.target.value);
      // setFiltered(list);
      dispatch({ type: GET_VOLTAGES, payload: list });
    } else {
      // setFiltered(voltages);
      dispatch({ type: GET_VOLTAGES, payload: voltages });
      setKeyword(keyword);
    }
  };

  const getLowVoltages = async () => {
    dispatch({ type: LOADING_STARTS });
    try {
      const res = await axios.get(`${GET_VOLTAGE_MONITOR_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (res.data) {
        const lowVolts = res.data.filter((basestation) => basestation.value < 22);
        if (res.data) {
          dispatch({
            type: GET_LOW_VOLTAGES,
            payload: lowVolts,
          });
        }
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: LOADING_STOPS });
    }
  };

  useEffect(() => {
    getVoltageMonitor();
    getLowVoltages();
  }, []);

  return {
    loading,
    value,
    keyword,
    voltages,
    filtered,
    threshold,
    lowVoltage,
    onChange,
    onSubmit,
    searchVoltage,
  };
};
export default useVoltageMonitor;
