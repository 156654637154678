import React from 'react';
import useHotspotServer from '../hooks/useHotspotServer';
import { HotspotServerContext } from './HotspotServerContext';

const HotspotServerProvider = ({ children }) => {
  const { data, loading, paginateChange, loadingForm, onChange, formValue, onSubmit, pages } = useHotspotServer();
  return (
    <HotspotServerContext.Provider
      value={{
        data,
        loading,
        paginateChange,
        loadingForm,
        onChange,
        formValue,
        onSubmit,
        pages,
      }}
    >
      {children}
    </HotspotServerContext.Provider>
  );
};
export default HotspotServerProvider;
