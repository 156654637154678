import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import ChartTypeSelect from '../components/ChartTypeSelect';
import LineCharts from './LineCharts';
import VerticalBarChart from './BarChart';
import AreaCharts from './AreaCharts';

export default function ChartsContainer({ getZones, ActiveInactiveData, ActiveData, InActiveData }) {
  const [chartType, setChartType] = React.useState('Bar-Chart');

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flexStart" sx={{ mt: 10 }}>
      <ChartTypeSelect setChartType={setChartType} chartType={chartType} />

      {chartType === 'Line-Chart' ? (
        <LineCharts
          getZones={getZones}
          ActiveInactiveData={ActiveInactiveData}
          ActiveData={ActiveData}
          InActiveData={InActiveData}
        />
      ) : chartType === 'Bar-Chart' ? (
        <VerticalBarChart
          getZones={getZones}
          ActiveInactiveData={ActiveInactiveData}
          ActiveData={ActiveData}
          InActiveData={InActiveData}
        />
      ) : (
        chartType === 'Area-Chart' && (
          <AreaCharts
            getZones={getZones}
            ActiveInactiveData={ActiveInactiveData}
            ActiveData={ActiveData}
            InActiveData={InActiveData}
          />
        )
      )}
    </Box>
  );
}
