import React from 'react';
import loader from '../../assets/img/icons/TizetiLoaderColored.gif';
import BarChart from '../../components/staff/shared/components/BarChart';
import CustomCsvDownload from '../../components/staff/shared/CustomCsvDownload';

export const AssetTeamHeader = (items, time, total, fetchTime) => {
  const ghost = items.filter((item) => item.zone === 'ghost' || item.basestationame === 'ghost');
  return time ? (
    <p className="h5" style={{ fontFamily: 'Rubik' }}>
      <span>UPDATED AT: </span> {fetchTime()} <br />
      <span style={{ textDecoration: 'none' }}>
        TOTAL: <span className="badge badge-success mx-2">{total}</span> <br />
        TOTAL WITHOUT GHOST:
        <span className="badge badge-success mx-2">
          {total - (ghost[0].active_count ? ghost[0].active_count : ghost[0].expired_count)}
        </span>
      </span>
    </p>
  ) : (
    'Fetching Time...'
  );
};

export const AssetTeamHelperGraph = (data) => {
  return (
    <BarChart
      data={data}
      options={{
        maintainAspectRatio: true,
        responsive: true,
        scales: {
          yAxes: [
            {
              scaleLabel: { display: true, labelString: 'COUNT', fontStyle: 'bold', fontSize: 17 },
            },
          ],
          xAxes: [
            {
              ticks: { fontStyle: 'bold' },
              scaleLabel: { display: true, labelString: 'BASESTATION NAME', fontStyle: 'bold', fontSize: 17 },
            },
          ],
        },
      }}
    />
  );
};

export const AssetTeamGeneralButton = (items, headers, title) => {
  return items.length ? (
    <div className="d-flex justify-content-end">
      <CustomCsvDownload
        data={items}
        headers={headers}
        filename={title}
        classname="btn btn-green shadow-md"
        textcolor="white"
        text="Download Data"
      />
    </div>
  ) : null;
};

export const AssetTeamHelperTable = (isLoading, showView, table) => {
  return (
    <div className="card x-panel">
      <div className="table-responsive content col-12">
        <table className="table table-striped">
          <thead>
            <tr className="">
              <th>S/N</th>
              <th>Basestation</th>
              <th>Count</th>
              {showView ? <th>View</th> : null}
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="4" className="text-center">
                  <img src={loader} alt="loading..." />
                </td>
              </tr>
            ) : (
              table
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export const AssetTeamBottomTotal = (total) => {
  return (
    <button type="button" className="btn btn-dark">
      TOTAL:
      <span style={{ fontSize: '15px' }} className="badge badge-success mx-2">
        {total}
      </span>
    </button>
  );
};
