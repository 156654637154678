/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useShared from '../../../departments/asset-team/shared/hooks/useShared';
import { RETENSION_SMS } from '../../../../../services/api/shared/installations/InstallationsUrl';
import Helpers from '../../customers/helpers/utils';

const tabIndex = -1;
const SendSms = ({ profile, oneItem }) => {
  
  const [status, setStatus] = useState('');
  const { onSubmit: submit, loading } = useShared(`${RETENSION_SMS}`, 'Message sent', 'Unable to send');

  var mobile = oneItem.Mobile.replace(/ /g, '');
  const onSubmit = (e) => {
    e.preventDefault();
    const newP = [];
    newP.push([oneItem.Mail, Number(oneItem.Mobile)]);
    if (oneItem.Mobile.slice(0, 1) == '+') {
      mobile = mobile.substring(4);
      // console.log(mobile);
      const newPs = [];
      newPs.push([oneItem.Mail, Number(mobile)]);
      submit({
        action: 'retension_sms_helper',
        staff_email: profile.officialEmail,
        message: Helpers.messageToBeSent(status),
        customer_emails_phone: newPs,
      });
    } else if (!profile.officialEmail) {
      toast.info('Please create a profile');
    } else {
      submit({
        action: 'retension_sms_helper',
        staff_email: profile.officialEmail,
        message: Helpers.messageToBeSent(status),
        customer_emails_phone: newP,
      });
    }
  };
  const submitForm = () => {
    return status.length > 0;
  };
  return (
    <div
      id="sendMessage"
      className="modal fade in mt-5"
      tabIndex={tabIndex}
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Send SMS
            </h4>
            <button type="button" className="close custom-close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body">
            <div className="py-3">
              <form className="form-horizontal" onSubmit={onSubmit}>
                <div className="form-group">
                  <div className="col-12 mb-3">
                    <div className="col-12">
                      <select
                        id="status"
                        name="status"
                        onChange={(e) => setStatus(e.target.value)}
                        className="form-control border border-info py-2"
                      >
                        <option value="">--Select Status--</option>
                        <option value="NEW INSTALLS">NEW INSTALLS</option>
                        <option value="ACTIVE CUSTOMERS">ACTIVE CUSTOMERS</option>
                        <option value="EXPIRED CUSTOMERS">EXPIRED CUSTOMERS</option>
                      </select>
                    </div>
                  </div>
                  {status.length > 0 && (
                    <div className="col-12 mb-3">
                      <div className="col-12">
                        <textarea
                          className="form-control border border-info py-2"
                          value={Helpers.messageToBeSent(status)}
                        />
                      </div>
                    </div>
                  )}
                  <div className="col-12 mb-3 text-center">
                    {loading ? (
                      <button type="submit" className="btn btn-green" disabled={loading}>
                        Sending...
                      </button>
                    ) : (
                      <button type="submit" className="btn btn-green" disabled={!submitForm()}>
                        Send Message
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendSms;
