import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import toastr from 'toastr';
import moment from 'moment';
import { toast } from 'react-toastify';
import Logout from '../../../shared/auth/Logout';
import OpsLogo from '../../../../layouts/operations/OpsLogo';
import mailblast from '../../../../../assets/img/icons/mailblast.svg';
import { admin, operations, TECHNICAL_SUPPORT } from '../../../../../utils/config';
import tizeti, { API } from '../../../../../services/api/tizeti';
import add from '../../../../../assets/img/icons/Add.svg';
import view from '../../../../../assets/img/icons/view.png';
import edit from '../../../../../assets/img/icons/edit.png';
import cancel from '../../../../../assets/img/icons/cancel-img.png';
import FieldSupportSideBar from '../../../../layouts/field-support/side-bar/FieldSupportSideBar';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';

class FieldSupportMailBlast extends Component {
  constructor() {
    super();
    this.state = {
      campaigns: [],
      loading: false,
      load: false,
      name: '',
      subject: '',
      body: '',
      fromname: '',
      fromemail: '',
      list: '',
      lists: [],
      one: {},
      id: '',
      listName: '',
    };

    this.createCampaign = this.createCampaign.bind(this);
    this.onChange = this.onChange.bind(this);
    this.deleteOne = this.deleteOne.bind(this);
  }

  componentWillReceiveProps() {
    this.checkDepartment();
  }

  checkDepartment = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      // console.log(response.data);

      if (
        response.data.department_id !== `${operations}` &&
        response.data.department_id !== `${TECHNICAL_SUPPORT}` &&
        response.data.department_id !== `${admin}`
      ) {
        sessionStorage.setItem('token', '');
        sessionStorage.clear();

        this.props.history.push('/');

        toast.info('You are not allowed to view this page');
      }
    } catch (e) {}
  };

  componentDidMount() {
    this.getCampaigns();
    this.getLists();
    this.checkDepartment();
  }

  createCampaign(e) {
    e.preventDefault();

    const campaign = {
      name: this.state.name,
      subject: this.state.subject,
      body: this.state.body,
      from_name: this.state.fromname,
      from_email: this.state.fromemail,
      list_id: this.state.list,
    };

    // console.log(campaign);
    API.post(`/mail/campaigns`, campaign).then((res) => {
      // console.log(res.data);
      if (res.data.data.id) {
        this.setState({
          name: '',
          subject: '',
          body: '',
          fromname: '',
          fromemail: '',
          list: '',
        });

        toastr.success('Campaign created successfully!');
        this.getCampaigns();
      }
    });
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  async getLists() {
    this.setState({
      load: true,
    });

    const response = await API.get(`/mail/list`);
    if (response.data.data) {
      this.setState({
        lists: response.data.data,
        load: false,
      });
    }
  }

  async getCampaigns() {
    this.setState({
      loading: true,
    });

    const response = await API.get(`/mail/campaigns`);

    if (response.data.data) {
      this.setState({
        campaigns: response.data.data,
        loading: false,
      });
    }
  }

  getOne = (list) =>
    this.setState(
      {
        one: list.attributes,
        id: list.id,
        listName: list.attributes.name,
      },
      () => console.log(list.attributes),
    );

  deleteOne = (list) => {
    const data = {
      name: list.attributes.name,
    };
    // console.log(data);

    const url = `https://api.tizeti.com/api/v1/mail/list/${list.id}`;
    // console.log(url);

    axios({
      method: 'delete',
      url,
      data,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      }
    });

    toastr.success('Deleted!', 'List', {
      timeOut: 1000,
      fadeOut: 1000,
      onHidden() {
        window.location.reload();
      },
    });
  };

  render() {
    const { campaigns, loading, lists, load, one, id, listName } = this.state;

    return (
      <div className="main-wrapper">
        <header className="topbar shade bg-white fixed-top">
          <nav className="navbar navbar-expand-lg navbar-light top-navbar fixed-top">
            {/* <Link className="navbar-brand" to={'/operations'}> */}
            {/* <img */}
            {/* src="https://res.cloudinary.com/tizeti/image/upload/v1542971047/Tizeti-Logo.png" */}
            {/* alt="homepage" */}
            {/* /> */}
            {/* </Link> */}
            <OpsLogo />
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse mt-3" id="navbarText">
              <ul className="navbar-nav">
                <li className="nav-item active">
                  <Link className="nav-link" to="#">
                    <h3>
                      Send Mail<span className="sr-only">(current)</span>
                    </h3>
                  </Link>
                </li>
              </ul>
              <span className="navbar-text d-flex">
                <Link
                  to="/operations/mail-blast"
                  className="d-flex mr-4"
                  style={{ color: 'gray', textDecoration: 'none' }}
                >
                  <img src={mailblast} alt="" className="logout mr-2" />
                  <span>
                    <h6 className="mt-2">
                      <strong>MailBlast</strong>
                    </h6>
                  </span>
                </Link>
                <h6 className="admin mt-2">
                  <i />
                  <Logout />
                </h6>
              </span>
            </div>
          </nav>
        </header>

        <div className="all-sides col-lg-12 col-md-12 col-sm-12">
          <FieldSupportSideBar />
        </div>
      </div>
    );
  }
}

export default FieldSupportMailBlast;
