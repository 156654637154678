import React, { useContext, useState } from 'react';
import moment from 'moment';
import RequisitionContext from '../../../../../context/shared/requisitions/RequsitionContext';
import view from '../../../../../assets/img/icons/view.png';
import ViewReceipt from '../shared/ViewReceipt';
import CommonRequisitionDetail from '../shared/CommonRequistionDetails';
import helpers from '../../../../../utils/requisition/helpers';

const EmployeesRequisitionsBody = () => {
  const value = useContext(RequisitionContext);
  const { employeesRequisitions } = value;

  const [requisit, setRequisit] = useState({});
  const [viewFile, setViewFile] = useState({});

  const getRequsition = (req) => {
    setRequisit(req);
  };

  const getFileRequsition = (req) => {
    setViewFile(req);
    if (req.imageUpload.split('.').pop() === 'document' || req.imageUpload.split('.').pop() === 'pdf') {
      window.open(req.imageUpload, '_blank');
    }
  };

  const requsitionList =
    employeesRequisitions && employeesRequisitions.length
      ? employeesRequisitions.map((requsition, i) => {
          return (
            <tr key={requsition.id}>
              <td>{i + 1}</td>
              {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
              <td>
                {helpers.displayCurrency(requsition.currency)}
                {requsition.amount_in_digits}
              </td>
              <td>{requsition.amount_in_words}</td>
              <td>{requsition.account_approval}</td>
              <td>{requsition.manager_approval}</td>
              <td>{helpers.showCLevelApprovalStatus(requsition.c_level_approval)}</td>
              <td>{requsition.ceo_or_coo_approval}</td>
              <td>{requsition.in_favour_of}</td>
              <td>{requsition.requires_c_level_approval === false ? 'False' : 'True'}</td>
              <td>{requsition.requires_ceo_or_coo_approval === false ? 'False' : 'True'}</td>
              {requsition.created_at ? <td>{moment(requsition.created_at).format('MM/DD/YYYY')}</td> : 'N/A'}

              <td>{requsition.purpose}</td>

              <td>
                <div className="mr-2 ml-2">
                  <button
                    data-target="#viewReceipt"
                    data-toggle="modal"
                    type="submit"
                    onClick={() => getFileRequsition(requsition)}
                    style={{
                      border: '0',
                      backgroundColor: 'white',
                    }}
                  >
                    <span className="view2">
                      <img src={view} alt="view" data-toggle="tooltip" data-placement="left" title="View" />
                    </span>
                  </button>
                  <ViewReceipt requisit={viewFile} />
                </div>
              </td>
              {requsition.account_approval === 'approved' ? (
                <td>
                  <span className="badge badge-success" style={{ backgroundColor: 'green' }}>
                    Paid
                  </span>
                </td>
              ) : null}

              <td>
                <div className="mr-2 ml-2 d-flex">
                  <button
                    data-target="#requisition"
                    data-toggle="modal"
                    type="submit"
                    onClick={() => getRequsition(requsition)}
                    style={{
                      border: '0',
                      backgroundColor: 'white',
                    }}
                  >
                    <span className="view2">
                      <img src={view} alt="view" data-toggle="tooltip" data-placement="left" title="View" />
                    </span>
                  </button>
                  <CommonRequisitionDetail requisit={requisit} />
                </div>
              </td>
            </tr>
          );
        })
      : 'No requisitions';
  return <tbody>{requsitionList}</tbody>;
};

export default EmployeesRequisitionsBody;
