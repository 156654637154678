import React from 'react';
import { Link } from 'react-router-dom';
import CustomerSupportSideBar from '../../../../layouts/customer-support/CustomerSupportSideBar';
import mailblast from '../../../../../assets/img/icons/mailblast.svg';
import Logout from '../../../shared/auth/Logout';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import QualityAssuranceBody from '../../../shared/customer-support/quality-assurance/QualityAssuranceBody';

const CustomerSupportQualityAssurance = () => {
  const { profile } = useUser();
  const { firstName } = profile;
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <div className="d-flex col-lg-12 p-2 top-header">
          <div className="mt-3 d-flex col-lg-12 p-0">
            <CustomerSupportSideBar />

            <h3 className="col-md-5 overview-part text-wrap">Quality Assurance</h3>

            <div className="ml-4 d-flex profile-mail">
              <Link
                to="/technical-support/mail-blast"
                className="d-none d-md-flex"
                style={{
                  color: 'gray',
                  textDecoration: 'none',
                }}
              >
                <img src={mailblast} alt="" className="logout mr-2" />
                <span>
                  <h6 className="m-2">
                    <strong>MailBlast</strong>
                  </h6>
                </span>
              </Link>

              <h6 className="mr-0 d-flex">
                <i className="small mr-2 d-none d-md-block">{firstName}</i>
                <Logout />
              </h6>
            </div>
          </div>
        </div>
        <QualityAssuranceBody />
      </main>
    </div>
  );
};

export default CustomerSupportQualityAssurance;
