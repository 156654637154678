/* eslint-disable react/button-has-type */
import React from 'react';

const CustomButton = ({ classname, name, disabled, onClick }) => {
  return (
    <button onClick={onClick} className={classname} disabled={disabled}>
      {name}
    </button>
  );
};

export default CustomButton;
