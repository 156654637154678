/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import CustomersTrackerBody from '../../../shared/customers/tracker/CustomersTrackerBody';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import CustomerSupportNavBar from '../../../../layouts/customer-support/CustomerSupportNavBar';

const CustomerSupportTracker = () => {
  const {
    profile: { firstName },
  } = useUser();
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <CustomerSupportNavBar currentPage="Daily Subscription Tracker" position={firstName} />
        <CustomersTrackerBody />
      </main>
    </div>
  );
};

export default CustomerSupportTracker;
