import React, { useState } from 'react';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../../utils/loader/loadingHelper';
import helpers from '../../../../../utils/installations/helpers';
import view from '../../../../../assets/img/icons/view.png';
import UpdateCustomerDetailsShared from '../../../shared/customer-details/UpdateCustomerDetailsShared';
import CustomerDetailsSharedModal from '../../../shared/customer-details/CustomerDetailsSharedModal';
import SendFeedback from '../../field-support/feedback/SendFeedback';

const CustomisationInstallationReportTableBody = ({
  load,
  allInstallations,
  setAllInstallations,
  recordsPerPage,
  currentPage,  
}) => {
  const [request, setRequest] = useState({
    id: '',
    user_kyc: '',
    contact_information_image: '',
    pole_image: '',
    img_4: '',
    sla: '',
    user: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      address: '',
      installations: [
        {
          installationUploads: [
            {
              contact_information_image: '',
              pole_image: '',
              img_4: '',
              sla: '',
              user_kyc: '',
            },
          ],
        },
      ],
    },
  });

  const [install, setInstall] = useState({});
  
  const getOneInstallation = (i) => {
    setInstall(i);
  };

  const showFeedbackButton = (param) => {
    return (
      <td>
        <button
          className="text-center noBorder"
          type="button"
          onClick={() => {
            getOneInstallation(param);
          }}
          data-target="#sendFeedback"
          data-toggle="modal"
        >
          <img
            src="https://res.cloudinary.com/teewhy/image/upload/v1550751069/add-imgs.png"
            alt="Activate"
            data-toggle="tooltip"
            data-placement="left"
            title="sendFeedback"
          />
        </button>
        <SendFeedback account={install} />
      </td>
    );
  };

  const getRequest = (item) => {
    setRequest({
      id: item.installations[0].installationUploads.length ? item.installations[0].installationUploads[0].id : 'N/A',
      user_kyc: item.installations[0].installationUploads.length
        ? item.installations[0].installationUploads[0].user_kyc
        : 'N/A',
      contact_information_image: item.installations[0].installationUploads.length
        ? item.installations[0].installationUploads[0].contact_information_image
        : 'N/A',
      pole_image: item.installations[0].installationUploads.length
        ? item.installations[0].installationUploads[0].pole_image
        : 'N/A',
      img_4: item.installations[0].installationUploads.length
        ? item.installations[0].installationUploads[0].img_4
        : 'N/A',
      sla: item.installations[0].installationUploads.length ? item.installations[0].installationUploads[0].sla : 'N/A',
      user: item,
    });
  };
  // console.log('here', request);
  const colSpan = 18;
  const showInstallations = () =>
    allInstallations && allInstallations.length ? (
      allInstallations.map((installation, i) => {
        const { username, mac_id, user } = installation;

        return (
          <tr key={i + 1}>
            <td> {(currentPage - 1) * recordsPerPage + i + 1}</td>
            <td>{user ? `${`${user.firstName} ${user.lastName}`}` : 'N/A'}</td>
            <td>{user ? mac_id : 'N/A'}</td>
            <td>{user ? `${`${user.email}`}` : 'N/A'}</td>
            <td>{user ? `${`${user.phoneNumber}`}` : 'N/A'}</td>
            <td>{user ? `${`${user.address}`}` : 'N/A'}</td>
            <td>
              {user.installations.length ? helpers.installationStatusLabels(user.installations[0].status) : 'N/A'}
            </td>
            <td>{ user.installations.length ?showFeedbackButton(installation) : "N/A"}</td>
            <td>{user.installations.length ? user.installations[0].payment_reference : 'N/A'}</td>
            <td>{user.installations.length ? user.installations[0].sub_zone.name : 'N/A'}</td>
            <td>{user.installations.length ? user.installations[0].zone.name : 'N/A'}</td>
            <td>
              <button
                type="submit"
                className="mx-1 noBorder"
                data-toggle="modal"
                data-placement="left"
                data-target="#viewcs"
                onClick={() => getRequest(user)}
              >
                <span className="view">
                  <img src={view} alt="" data-toggle="tooltip" data-placement="left" title="Edit" />
                </span>
              </button>
              <CustomerDetailsSharedModal request={request} />
            </td>
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan={colSpan} text="No Installations" />
    );
  const displayData = () => {
    if (load) return <TizetiGIFLogoTable colSpan={colSpan} />;
    return showInstallations();
  };
  return <tbody> {displayData()}</tbody>;
};

export default CustomisationInstallationReportTableBody;
