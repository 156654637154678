/* eslint-disable import/prefer-default-export */
import { useReducer } from 'react';
import qs from 'qs';
import axios from 'axios';
import toastr from 'toastr';

import { EDIT_ZONE_URL } from '../../../../../../services/api/shared/installations/ZonesUrl';
import { LOADING_STOPS, REFETCH } from '../../../../../../constants/types/shared/LoadingTypes';
import installationsReducer from '../../../../../../reducers/shared/installations/installationsReducer';

export const useEditZone = () => {
  const [{ loading }, dispatch] = useReducer(installationsReducer, {
    loading: false,
  });

  const editZone = async (id, data) => {
    const config = {
      method: 'patch',
      url: `${EDIT_ZONE_URL}/${id}`,
      data: qs.stringify(data),
      headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
    };

    await axios(config)
      .then((response) => {
        if (response.data[0] === 1) {
          // console.log('true');
          toastr.success('Update Successful');
          // setTimeout(() => {
          //   window.location.assign('https://os.tizeti.com/login#/new-design/installation-zones');
          // }, 1000);
          dispatch({ type: REFETCH, payload: response.data });
        }
      })
      .catch((error) => {
        if (error) {
          toastr.info('Something went wrong!');
          dispatch({ type: LOADING_STOPS });
        }
      });
  };

  return {
    loading,
    editZone,
  };
};
