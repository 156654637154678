import React from 'react';
import SalesNavBar from '../../../../layouts/sales/SalesNavBar';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import ClusteredInstallationsBody from './ClusteredInstallationsBody';

const SalesClusteredInstallations = () => {
  const { profile } = useUser();

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <SalesNavBar currentPage="Clustered Installations" position={profile.firstName} />
        <ClusteredInstallationsBody />
      </main>
    </div>
  );
};

export default SalesClusteredInstallations;
