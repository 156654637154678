import React from 'react';
import SearchLteInstallation from './SearchLteInstallation';
import LTEInstallationsTable from './LTEInstallationsTable';

const LteInstallationsBody = () => {
  return (
    <div className="card x-panel logs">
      <SearchLteInstallation />
      <div className="table-responsive content">
        <LTEInstallationsTable />
      </div>
    </div>
  );
};

export default LteInstallationsBody;
