import React, { Fragment } from 'react';
import ReactPaginate from 'react-paginate';
import UnverifiedCustomersTable from './UnverifiedCustomersTable';
import { useCustomer } from '../../../../../../hooks/shared/customers/useCustomer';
import { useUser } from '../../../../../../hooks/shared/user/useUser';

const Body = () => {
  const { unverified, pages, fetching, handlePageClick } = useCustomer();
  const {
    profile: { officialEmail },
  } = useUser();

  return (
    <div className="mt-5 p-3">
      <div className="container-fluid">
        <div className="row">
          <div className="card x-panel">
            <div className="table-responsive content">
              <table className="table copy-font">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>First Name</th>
                    <th nowrap="no-wrap">Last Name</th>
                    <th>Email</th>
                    <th nowrap="no-wrap">Phone Number</th>
                    <th nowrap="no-wrap">Address</th>
                    <th nowrap="no-wrap">Country</th>
                    <th>Date Created</th>
                    {(officialEmail === 'blessing.okwedi@tizeti.com' ||
                      Number(`${sessionStorage.getItem('roleId')}`) > 2) &&
                    Number(`${sessionStorage.getItem('roleId')}`) !== 6 ? (
                      <Fragment>
                        <th>Edit</th>
                        <th>Verify</th>
                      </Fragment>
                    ) : null}
                  </tr>
                </thead>
                {fetching ? (
                  <small>loading...</small>
                ) : (
                  <UnverifiedCustomersTable customers={unverified} officialEmail={officialEmail} />
                )}
              </table>
              {pages > 1 ? (
                <ReactPaginate
                  previousLabel="previous"
                  nextLabel="next"
                  breakLabel="..."
                  breakClassName="break-me"
                  pageCount={pages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName="pagination"
                  subContainerClassName="pages pagination"
                  activeClassName="active"
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
