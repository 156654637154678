/* eslint-disable react/button-has-type */
import React, { useContext } from 'react';
import NetworksTable from './NetworksTable';
import GeniatechContext from '../../../../../../context/GeniatechContext';
import NetworkTable from './network/NetworkTable';

const NetworksBody = () => {
  const { searchStatus, reset } = useContext(GeniatechContext);
  return (
    <div className="container">
      <div className="row mt-5">
        <div className="mt-4 col-lg-12 col-md-12 col-sm-12">
          <div className="container">
            <div className="row mt-5">
              {searchStatus && (
                <div className="form-group ml-2">
                  <button onClick={reset} className="btn btn-green">
                    See All Networks
                  </button>
                </div>
              )}
              {searchStatus ? <NetworkTable /> : <NetworksTable />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NetworksBody;
