import { FETCHING_STARTS, FETCHING_STOPS } from '../../../../constants/types/shared/LoadingTypes';
import * as types from '../../../../constants/types/noc/NocTypes';

const initialState = {
  updating: false,
  adding: false,
};

export const StaticIpReducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case FETCHING_STARTS:
      return {
        ...state,
        updating: true,
      };

    case FETCHING_STOPS:
      return {
        ...state,
        updating: false,
      };

    case types.UPDATE_STATIC_IP_ADDRESS:
      return {
        ...state,
        updating: false,
      };

    case types.START_ADDING_STATIC_IP:
      return {
        adding: true,
      };

    case types.STOP_ADDING_STATIC_IP:
      return {
        ...state,
        adding: false,
      };

    case types.ADDED_STATIC_IP:
      return {
        ...state,
        adding: false,
      };

    default:
      return state;
  }
};

export default StaticIpReducer;
