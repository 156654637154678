/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import logoutImage from '../../../../assets/img/icons/logout.png';

const Logout = () => {
  const [logOut, setLogOut] = useState(false);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const logout = () => {
    sessionStorage.setItem('token', '');
    sessionStorage.setItem('email', '');
    sessionStorage.setItem('officialEmail', '');  
    sessionStorage.setItem('firstName', '');
    sessionStorage.setItem('lastName', '');
    sessionStorage.setItem('departmentId', '');
    sessionStorage.setItem('roleId', '');    
    sessionStorage.setItem('country', '');

    sessionStorage.clear();
    setLogOut(true);
  };

  if (logOut) {
    return <Redirect to="/login" />;
  }
  return (
    <h6 className="mt-2">
      <img
        src={logoutImage}
        className="logout"
        onClick={logout}
        style={{ cursor: 'pointer', fontSize: '15px' }}
        alt="img"
      />
      <strong> Logout </strong>
    </h6>
  );
};

export default Logout;
