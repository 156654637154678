import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import CustomInput from '../../../shared/forms/CustomInput';
import CustomButton from '../../../shared/forms/CustomButton';
import TextArea from '../../../shared/forms/TextArea';
import { SALES } from '../../../../../utils/config';
import { OPERATIONS, SUPER_ADMIN } from '../../../../../utils/departments/helpers';
import useUtils from '../../../shared/refund/hooks/useUtils';

// eslint-disable-next-line react/prop-types
const RefundForm = ({ install, onChange, refund, show, handleClose, onSubmit, load }) => {
  // console.log(install);
  const { profile } = useUser();
  // eslint-disable-next-line react/prop-types
  const { address, email, firstName, installationId, lastName, phoneNumber } = install;

  const { states, banks, loading } = useUtils();
  const [formdata, setFormdata] = useState({
    bank_name: '',
    account_name: '',
    account_number: '',
    state: '',
    country: '',
  });

  const countries = [
    { value: 'nigeria', label: 'Nigeria' },
    { value: 'cotevoire', label: "Cote d'Ivoire" },
    { value: 'ghana', label: 'Ghana' },
  ];
  const handleOnChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormdata((values) => ({ ...values, [name]: value }));
  };
  const onMakeRequest = (e) => {
    e.preventDefault();
    const { bank_name, account_name, account_number, state, country } = formdata;
    let salesFseApproval = null;
    let targetMail;

    if (profile.department_id === `${SALES}`) {
      salesFseApproval = true;
      targetMail = 'sales@tizeti.com';
    }

    if (profile.department_id === `${OPERATIONS}`) {
      targetMail = 'fse mail';
    }

    if (profile && profile.id) {
      const data = {
        address,
        email,
        firstName,
        lastName,
        phoneNumber,
        bank_name,
        account_name,
        account_number,
        state,
        targetMail,
        fse_approval: salesFseApproval,
        // eslint-disable-next-line react/prop-types
        note: refund.note,
        // eslint-disable-next-line @typescript-eslint/camelcase
        installation_id: installationId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        employee_id: profile.id,
      };
      // console.log(data);
      // return;
      onSubmit(data, setFormdata);
    } else {
      toast('Kindly create your profile');
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Refund Form</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body detail mb-4 p-0">
          <form onSubmit={onMakeRequest} className="col-md-12">
            <div className="d-flex">
              <div className="pr-3 form-group col-md-6">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
                <label className="m-0 p-0 copy-font" htmlFor="customerName">
                  First Name
                </label>
                <CustomInput
                  classname="form-control border border-info py-2"
                  name="firstName"
                  value={firstName}
                  onChange={null}
                  readOnly
                  type="text"
                />
              </div>
              <div className="pr-3 form-group col-md-6">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
                <label className="m-0 p-0 copy-font" htmlFor="customerName">
                  Last Name
                </label>
                <CustomInput
                  classname="form-control border border-info py-2"
                  name="lastName"
                  value={lastName}
                  onChange={null}
                  readOnly
                  type="text"
                />
              </div>
            </div>
            <div className="d-flex">
              <div className="pr-3 form-group col-md-6">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
                <label className="m-0 p-0 copy-font" htmlFor="customerName">
                  Bank Name
                </label>
                {DropDown('banks')(
                  {
                    classname: 'form-control border border-info py-2',
                    name: 'bank_name',
                    value: formdata.bank_name,
                    placeholder: 'Search banks',
                    onChange: handleOnChange,
                    type: 'text',
                    list: 'dropdown-search',
                    autocomplete: 'off',
                  },
                  banks,
                  loading,
                )}
              </div>
              <div className="pr-3 form-group col-md-6">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
                <label className="m-0 p-0 copy-font" htmlFor="customerName">
                  Account Name
                </label>
                <CustomInput
                  classname="form-control border border-info py-2"
                  name="account_name"
                  value={formdata.account_name}
                  onChange={handleOnChange}
                  type="text"
                />
              </div>
            </div>
            <div className="d-flex">
              <div className="pr-3 form-group col-md-6">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
                <label className="m-0 p-0 copy-font" htmlFor="customerName">
                  Account Number
                </label>

                <CustomInput
                  classname="form-control border border-info py-2"
                  name="account_number"
                  value={formdata.account_number}
                  onChange={handleOnChange}
                  type="number"
                  onInput={(e) => {
                    if (e.target.value >= 10) {
                      e.target.value = e.target.value.slice(0, 10);
                    }
                  }}
                />
              </div>
              <div className="pr-3 form-group col-md-6">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
                <label className="m-0 p-0 copy-font" htmlFor="customerName">
                  Email
                </label>
                <CustomInput
                  classname="form-control border border-info py-2"
                  name="email"
                  value={email}
                  onChange={null}
                  readOnly
                  type="text"
                />
              </div>
            </div>

            <div className="d-flex">
              <div className="pr-3 form-group col-md-6">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
                <label className="m-0 p-0 copy-font" htmlFor="Country">
                  Country
                </label>
                <select
                  id="country"
                  name="country"
                  value={formdata.country}
                  onChange={handleOnChange}
                  className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                  required
                >
                  <option value="">Select a Country</option>
                  {countries.map((country) => (
                    <option key={country.value} value={country.value}>
                      {country.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="pr-3 form-group col-md-6">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
                <label className="m-0 p-0 copy-font" htmlFor="customerName">
                  State
                </label>

                {DropDown('states')(
                  {
                    classname: 'form-control border border-info py-2',
                    name: 'state',
                    value: formdata.state,
                    placeholder: 'Search states',
                    onChange: handleOnChange,
                    type: 'text',
                    list: 'dropdown-states',
                    autocomplete: 'off',
                    required: true,
                  },
                  states,
                  loading,
                )}
              </div>
            </div>

            <div className="d-flex">
              <div className="pr-3 form-group col-md-6">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
                <label className="m-0 p-0 copy-font" htmlFor="customerName">
                  Phone Number
                </label>
                <CustomInput
                  classname="form-control border border-info py-2"
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={null}
                  readOnly
                  type="text"
                />
              </div>
              <div className="pr-3 form-group col-md-6">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
                <label className="m-0 p-0 copy-font" htmlFor="customerName">
                  Address
                </label>
                <CustomInput
                  classname="form-control border border-info py-2"
                  name="address"
                  value={address}
                  onChange={null}
                  readOnly
                  type="text"
                />
              </div>
            </div>

            <div className="d-flex w-full">
              <div className="pr-3 form-group col-md-12 w-full">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
                <label className="m-0 p-0 copy-font" htmlFor="customerName">
                  Note
                </label>
                <TextArea
                  classname="form-control border border-info py-2"
                  name="note"
                  placeholder="Enter Note"
                  onChange={onChange}
                  value={refund.note}
                />
              </div>
            </div>

            <div className="text-center mt-3">
              <CustomButton
                classname="btn btn-green py-2 px-5"
                name={load ? 'Making Refund Request...' : 'Make Refund Request'}
                disabled={load}
              />
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RefundForm;

const DropDown = (type) => {
  if (type === 'states') {
    return (props, items, loading) => (
      <>
        <CustomInput {...props} />
        {loading && <h5>loading...</h5>}
        <datalist id={props.list} className="">
          {items.map((item, index) => (
            <option value={item.state} key={index} />
          ))}
        </datalist>
      </>
    );
  }
  return (props, items, loading) => (
    <>
      <CustomInput {...props} />
      {loading && <h5>loading...</h5>}
      <datalist id={props.list} className="">
        {items.map((item, index) => (
          <div className="d-flex">
            <img
              src={item.logo}
              style={{
                width: '40px',
                height: '40px',
              }}
            />
            <option value={item.name} key={index} />
          </div>
        ))}
      </datalist>
    </>
  );
};
