/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
// /* eslint-disable react/require-default-props */
// /* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import '../../../../../styles/style.css';
import '../../../../../styles/sales_overview.css';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import chart from '../../../../../assets/img/icons/pie_chart.fw.png';
import mailblast from '../../../../../assets/img/icons/mailblast.svg';
import Profile from '../../../../../assets/img/icons/comment-profile.fw.png';
import AdminDeptLogo from '../../../../layouts/admin-department/AdminDeptLogo';
import Logout from '../../../shared/auth/Logout';
import AdminDepartmentSideBar from '../../../../layouts/admin-department/AdminDepartmentSideBar';
import ViewUserProfile from '../../../shared/staff/ViewUserProfile';
import TotalStaff from './TotalStaff';
import Departments from './Departments';
import NewStaff from './NewStaff';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import { ADMIN_DEPARTMENT } from '../../../../../utils/departments/helpers';
import useCheckDepartment from '../../../../../hooks/shared/custom/check-department/useCheckDepartment';
import AdminDeptNavBar from '../../../../layouts/admin-department/AdminDeptNavBar';

const AdminDepartmentOverview = ({ history }) => {
  // not quite sure what to pull out since im not usiing anyting and all
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { result } = useCheckDepartment(`${LOGGED_IN_USER_URL}`, `${ADMIN_DEPARTMENT}`, history);

  return (
    <div className="s-layout">
    <main className="s-layout__content col-lg-10 p-0">
      <AdminDeptNavBar />
        <div className="page-wrapper col-lg-10 col-md-10 col-sm-12">
          <div className="row">
            <div className="container-fluid col-lg-11" style={{ marginLeft: '0' }}>
              <div className="card-body col-lg-12" style={{ padding: '17px' }}>
                <div className="form-inline" style={{ width: '100%' }}>
                  <div className="col-lg-12 d-flex sales-summary">
                    <p className="col-lg-8 summary copy-font">HR Summary</p>
                    <p className="sales-opt copy-font col-lg-4 d-flex">
                      <small className="mt-2"> Filter By:</small>
                      <span>
                        <select className="form-control">
                          <option>Month</option>
                        </select>
                      </span>

                      <span>
                        <select className="form-control">
                          <option>Year</option>
                        </select>
                      </span>
                    </p>

                    <h6>Showing statistics for the </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 side d-flex">
              <div className="col-lg-2">
                <TotalStaff />

                <Departments />

                <NewStaff />
              </div>

              <div className="col-lg-10 d-flex">
                <div className="col-lg-5 mr-5">
                  <div className="pie-chart" style={{ marginTop: '33%' }}>
                    <h6>Number of Staff by Department </h6>
                    <img src={chart} alt="" />
                  </div>
                </div>

                <div className="col-lg-5 ml-3 pie-chart">
                  <h6>Recruitment Data</h6>
                  <h6>No of staff recruited</h6>
                  <div id="AdminOverview" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

AdminDepartmentOverview.propTypes = {
  history: PropTypes.shape(),
};
export default AdminDepartmentOverview;
