import {
  FETCH_PAST_EXPIRED,
  GET_ABOUT_TO_EXPIRE,
} from '../../../constants/types/customer-support/CustomerSupportTypes';
import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';

const initialState = {
  expired: [],
  loading: false,
  about: [],
};

const RetentionReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case FETCH_PAST_EXPIRED:
      return {
        ...state,
        expired: payload,
        loading: false,
      };

    case GET_ABOUT_TO_EXPIRE:
      return {
        ...state,
        about: payload,
        loading: false,
      };

    case LOADING_STARTS:
      return {
        ...state,
        loading: payload,
      };

    case LOADING_STOPS:
      return {
        ...state,
        loading: payload,
      };

    default:
      return state;
  }
};

export default RetentionReducer;
