/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../../utils/loader/loadingHelper';
import moment from 'moment';

const CallLogsTable = ({ lists, load }) => {
  const colSpan = '8';

  const tableData =
    lists && lists.length ? (
      lists.map((list, i) => {
        return (
          <tr className="" key={i}>
            <td>{i + 1}</td>
            <td className="text-nowrap">{`${list.name}`}</td>
            <td>{list.phoneNumber}</td>
            <td>{list.email}</td>
            <td>{list.cin}</td>
            <td>{list.zone}</td>
            <td className="text-nowrap">{list.issue}</td>
            <td className="text-wrap">{list.resolution || 'N/A'}</td>
            <td>{list.note}</td>
            <td>{moment(list.created_at).format('MM/DD/YYYY')}</td>
            <td>{moment(list.updated_at).format('MM/DD/YYYY')}</td>
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan={colSpan} text="There are no records" />
    );

  return (
    <div className="table-responsive content col-12">
      <table className="table table-striped">
        <thead>
          <tr className="">
            <th>S/N</th>
            <th>Name</th>
            <th>Phone Number</th>
            <th>Email</th>
            <th>Customer Identification Number</th>
            <th>Zone</th>
            <th>Issue</th>
            <th>Resolution</th>
            <th>Note</th>
            <th>Created At</th>
            <th>Updated At</th>
          </tr>
        </thead>
        <tbody>{load ? <TizetiGIFLogoTable colSpan={colSpan} /> : tableData}</tbody>
      </table>
    </div>
  );
};

export default CallLogsTable;
