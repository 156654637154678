import React, { useContext } from 'react';
import { TizetiGIFLogoTable } from '../../../../../../utils/loader/loadingHelper';
import AppContext from '../../../../../../context/shared/AppContext';
import LteCustomersTableBody from './LteCustomersTableBody';

const LteCustomersTable = () => {
  const { loadingForm } = useContext(AppContext);
  return (
    <div className="table-responsive content col-12 px-1">
      <table className="table">
        <thead>
          <tr className="text-nowrap">
            <th>#</th>
            <th>IMSI</th>
            <th>Expire At</th>
            <th>No OF Months</th>
            <th>Status</th>
            <th>Date Created</th>
            <th>User Name</th>
            <th>Address</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>Plan</th>
            <th>Action/Extend</th>
          </tr>
        </thead>

        {loadingForm ? <TizetiGIFLogoTable colSpan={12} /> : <LteCustomersTableBody />}
      </table>
    </div>
  );
};

export default LteCustomersTable;
