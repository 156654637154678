/* eslint-disable import/prefer-default-export */
import { useState } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { API_URL } from '../../utils/config';

export const useVehicles = () => {
  const [vehicles, setVehicles] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [page] = useState(1);

  const fetchVehicles = async () => {
    setIsLoading(true);
    const config = {
      method: 'get',
      url: `${API_URL}/vehicle`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response.data) {
          setVehicles(response.data);
          setIsLoading(false);
        }
      })
      .catch(() => {
        toastr.info('Something went wrong!');
        setIsLoading(false);
      });
  };

  // useEffect(() => {
  //   getRelocationRequest(page);
  // }, []);

  return {
    vehicles,
    isLoading,
    fetchVehicles,
    setVehicles,
  };
};
