/* eslint-disable import/prefer-default-export */
import { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../../../../../utils/config';
import { WIFICALL_PLANS_URL } from '../../../../../services/api/wificall/PlansUrls';

export const usePlans = () => {
  const [plans, setPlans] = useState({});

  const getWificallPlans = async () => {
    try {
      const response = await axios.get(`${API_URL}/${WIFICALL_PLANS_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        setPlans(response.data);
      }
    } catch (e) {
      console.log(e.response);
    }
  };

  useEffect(() => {
    getWificallPlans();
  }, []);

  return { plans };
};
