/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Activate from '../Activate';

const LteKycTableBody = ({ customers }) => {
  const [user, setUser] = useState({});

  const getSingleUser = (params) => {
    setUser(params);
  };
  const customersList =
    customers && customers.length > 1
      ? customers.map((customer, i) => (
          <tr key={customer.id}>
            <td>{i + 1}</td>
            <td>{customer.firstName}</td>
            <td>{customer.lastName}</td>
            <td>{customer.phoneNumber}</td>
            <td>{customer.address}</td>
            <td
              className="img-pointer"
              data-target="#activate"
              data-toggle="modal"
              onClick={() => getSingleUser(customer)}
            >
              <img src="https://res.cloudinary.com/teewhy/image/upload/v1550751069/add-imgs.png" alt="" />
            </td>
            <Activate user={user} />
          </tr>
        ))
      : 'No Customers';
  return <tbody>{customersList}</tbody>;
};

LteKycTableBody.propTypes = {
  // eslint-disable-next-line react/require-default-props
  customers: PropTypes.shape(),
};
export default LteKycTableBody;
