import React from 'react';
import LteTable from '../../customers/lte/expired/LteTable';

const ExpiredLteCustomersBody = () => {
  return (
    <div className="row">
      <div className="card x-panel logs">
        <div className="table-responsive content">
          <LteTable />
        </div>
      </div>
    </div>
  );
};

export default ExpiredLteCustomersBody;
