import React, { Component } from 'react';
import '../../../../../styles/style.css';
import '../../../../../styles/sales_overview.css';
import axios from 'axios';
import toastr from 'toastr';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import Mailblast from '../../../../../assets/img/icons/mailblast.svg';
import view from '../../../../../assets/img/icons/view.png';
import edit from '../../../../../assets/img/icons/edit.png';
import FeaturesDetail from './FeaturesDetail';
import UpdateRequest from '../requests/UpdateRequest';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import {
  TECHNICAL_SUPPORT,
  ADMIN_DEPARTMENT,
  operations,
  accounts,
  customer_support,
  NOC,
  NEW_PRODUCTS,
  PROCUREMENT,
  RESEARCH_AND_DEVELOPMENT,
  SALES,
} from '../../../../../utils/config';
import ResearchLogo from '../../../../layouts/research-and-development/ResearchLogo';
import Logout from '../../../shared/auth/Logout';
import ResearchAndDevelopmentSideBar from '../../../../layouts/research-and-development/ResearchAndDevelopmentSideBar';
import { getUser } from '../../../../../actions/shared/user/UserActions';
import {
  getFeatureRequests,
  searchRequestsByDepartments,
} from '../../../../../actions/research-and-development/feature-requests/FeatureRequestsActions';
import { getLoggedInUser } from '../../../../../actions/user/UserActions';
import { getDepartments } from '../../../../../actions/shared/departments/DepartmentsActions';
import { SUPER_ADMIN } from '../../../../../utils/departments/helpers';

class ResearchFeatureRequest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      one: {},
      department: '',
    };
  }

  componentDidMount() {
    this.props.getUser();
    this.checkDepartment();
    this.props.getFeatureRequests();
    this.props.getDepartments();
  }

  static propTypes = {
    role: PropTypes.object.isRequired,
    getLoggedInUser: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    firstName: PropTypes.string.isRequired,
    getFeatureRequests: PropTypes.func.isRequired,
    features: PropTypes.object.isRequired,
    getDepartments: PropTypes.func.isRequired,
    departments: PropTypes.object.isRequired,
    searchRequestsByDepartments: PropTypes.func.isRequired,
  };

  checkDepartment = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (
        response.data.department_id !== `${RESEARCH_AND_DEVELOPMENT}` &&
        response.data.department_id !== `${SUPER_ADMIN}`
      ) {
        this.props.history.push('/');

        sessionStorage.setItem('token', '');
        sessionStorage.clear();

        toastr.info('You are not allowed to view this page');
      }
    } catch (e) {
      // console.log(e.response)
    }
  };

  getFeature = (feature) =>
    this.setState({
      one: feature,
    });

  componentWillReceiveProps(nextProps, nextContext) {
    this.checkDepartment();
  }

  getRequestsByDepartment = (department) => {
    this.props.searchRequestsByDepartments(department);
  };

  render() {
    const { role_id } = this.props.role;
    const { features, load, user, departments } = this.props;
    const { firstName } = user;

    const { one } = this.state;

    const featuresBody = features
      ? features.map((feature, i) => (
          <tr>
            <td>{i + 1}</td>
            <td>
              {feature.department_id === `${SALES}` ? (
                <strong>SALES</strong>
              ) : feature.department_id === `${accounts}` ? (
                <strong>ACCOUNTS</strong>
              ) : feature.department_id === `${customer_support}` ? (
                <strong>CUSTOMER SUPPORT</strong>
              ) : feature.department_id === `${ADMIN_DEPARTMENT}` ? (
                <strong>ADMINISTRATION</strong>
              ) : feature.department_id === `${NOC}` ? (
                <strong>NOC</strong>
              ) : feature.department_id === `${operations}` ? (
                <strong>OPERATIONS</strong>
              ) : feature.department_id === `${NEW_PRODUCTS}` ? (
                <strong>NEW PRODUCTS</strong>
              ) : feature.department_id === `${PROCUREMENT}` ? (
                <strong>PROCUREMENT</strong>
              ) : feature.department_id === `${RESEARCH_AND_DEVELOPMENT}` ? (
                <strong>RESEARCH AND DEVELOPMENT</strong>
              ) : feature.department_id === `${TECHNICAL_SUPPORT}` ? (
                <strong>TECHNICAL SUPPORT</strong>
              ) : null}
            </td>

            <td>{feature.subject}</td>
            <td>{feature.details}</td>
            {/* <td>{feature.employee_auth_id}</td> */}
            <td>{moment(feature.created_at).format('DD-MM-YYYY')}</td>
            <td>
              <span className={feature.status === 'completed' ? 'badge badge-success' : 'badge badge-danger'}>
                {feature.status}
              </span>
            </td>
            <td onClick={() => this.getFeature(feature)}>
              <div className="d-flex ml-3">
                <button
                  type="submit"
                  onClick={() => this.getFeature(feature)}
                  data-toggle="modal"
                  data-placement="right"
                  data-target="#features"
                  style={{
                    border: '0',
                    backgroundColor: 'white',
                  }}
                >
                  <span className="view1">
                    <img src={view} data-toggle="tooltip" data-placement="right" title="View" />
                  </span>
                </button>
                <FeaturesDetail feature={one} />

                <button
                  type="submit"
                  onClick={() => this.getFeature(feature)}
                  data-toggle="modal"
                  data-target="#updateRequest"
                  style={{
                    border: '0',
                    backgroundColor: 'white',
                  }}
                >
                  <span className="view">
                    <img src={edit} data-toggle="tooltip" data-placement="top" title="Edit" />
                  </span>
                </button>
                <UpdateRequest feature={one} />
              </div>
            </td>
          </tr>
        ))
      : features.map((feature, i) => (
          <tr>
            <td>{i + 1}</td>
            <td>
              {feature.department_id === `${SALES}` ? (
                <strong>SALES</strong>
              ) : feature.department_id === `${accounts}` ? (
                <strong>ACCOUNTS</strong>
              ) : feature.department_id === `${customer_support}` ? (
                <strong>CUSTOMER SUPPORT</strong>
              ) : feature.department_id === `${ADMIN_DEPARTMENT}` ? (
                <strong>ADMINISTRATION</strong>
              ) : feature.department_id === `${NOC}` ? (
                <strong>NOC</strong>
              ) : feature.department_id === `${operations}` ? (
                <strong>OPERATIONS</strong>
              ) : feature.department_id === `${NEW_PRODUCTS}` ? (
                <strong>NEW PRODUCTS</strong>
              ) : feature.department_id === `${PROCUREMENT}` ? (
                <strong>PROCUREMENT</strong>
              ) : feature.department_id === `${RESEARCH_AND_DEVELOPMENT}` ? (
                <strong>RESEARCH AND DEVELOPMENT</strong>
              ) : feature.department_id === `${TECHNICAL_SUPPORT}` ? (
                <strong>TECHNICAL SUPPORT</strong>
              ) : null}
            </td>

            <td>{feature.subject}</td>
            <td>{feature.details}</td>
            {/* <td>{feature.employee_auth_id}</td> */}
            <td>{moment(feature.created_at).format('DD-MM-YYYY')}</td>
            <td>
              <span className={feature.status === 'completed' ? 'badge badge-success' : 'badge badge-danger'}>
                {feature.status}
              </span>
            </td>
            <td onClick={() => this.getFeature(feature)}>
              <div className="d-flex ml-3">
                <button
                  type="submit"
                  onClick={() => this.getFeature(feature)}
                  data-toggle="modal"
                  data-placement="right"
                  data-target="#features"
                  style={{
                    border: '0',
                    backgroundColor: 'white',
                  }}
                >
                  <span className="view1">
                    <img src={view} data-toggle="tooltip" data-placement="right" title="View" />
                  </span>
                </button>
                <FeaturesDetail feature={one} />

                <button
                  type="submit"
                  onClick={() => this.getFeature(feature)}
                  data-toggle="modal"
                  data-target="#updateRequest"
                  style={{
                    border: '0',
                    backgroundColor: 'white',
                  }}
                >
                  <span className="view">
                    <img src={edit} data-toggle="tooltip" data-placement="top" title="Edit" />
                  </span>
                </button>
                <UpdateRequest feature={one} />
              </div>
            </td>
          </tr>
        ));

    const departmentOptions = departments
      ? departments.map((department) => <option value={department.id}>{department.name}</option>)
      : null;

    return (
      <div className="main-wrapper">
        <header className="topbar shade bg-white fixed-top">
          <nav className="navbar navbar-expand-lg navbar-light top-navbar fixed-top">
            <ResearchLogo />
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse mt-3" id="navbarText">
              <ul className="navbar-nav" style={{ marginRight: 'auto' }}>
                <li className="nav-item active">
                  <Link className="nav-link" to="#">
                    <h3>
                      Feature Requests<span className="sr-only">(current)</span>
                    </h3>
                  </Link>
                </li>
              </ul>
              <span className="navbar-text">
                <div className="d-flex">
                  {Number(role_id) > 2 ? (
                    <Link
                      to="/research-and-development/mail-blast"
                      className="d-flex"
                      style={{ color: 'gray', textDecoration: 'none' }}
                    >
                      <img src={Mailblast} className="logout mr-2 mb-2" alt="" />
                      <span>
                        <h6 className="mr-2">
                          <strong>MailBlast</strong>
                        </h6>
                      </span>
                    </Link>
                  ) : null}
                  <h4 className="admin">
                    {firstName}
                    <Logout />
                  </h4>
                </div>
              </span>
            </div>
          </nav>
        </header>

        <div className="all-sides col-lg-12 col-md-12 col-sm-12">
          <ResearchAndDevelopmentSideBar />
          <div className="page-wrapper col-lg-10 col-md-10 col-sm-12">
            <div className="row">
              <div className="container-fluid col-lg-12">
                <div className="card-body col-lg-12" style={{ padding: '17px' }}>
                  <div className="form-inline" action="javascript:void(0)">
                    <div className="col-12 mb-3 p-0 d-flex">
                      <div>
                        <p className="copy-font d-flex mt-4">
                          <small className="mr-2 mt-3"> Filter By Department:</small>
                          <span className="mt-2">
                            <select
                              className="shadow-sm p-2 mr-4 form-control"
                              onChange={(e) => this.getRequestsByDepartment(e.target.value)}
                              name="department"
                            >
                              <option>Departments</option>
                              {departmentOptions}
                            </select>
                          </span>
                        </p>
                      </div>

                      {/* <button className="btn btn-green m-4 p-2" data-target="#request" data-toggle="modal">Add Request</button> */}
                    </div>
                    {/* <AddRequest /> */}

                    <div className="col-12 m-0 p-0">
                      <div className="card x-panel w-100 ml-0">
                        <div className="table-responsive content">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Department</th>
                                <th>Subject</th>
                                <th>Details</th>
                                {/* <th>Submitted By</th> */}
                                <th>Date</th>
                                <th>Status</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>{load ? <img src={loader} alt="" /> : featuresBody}</tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.auth,
  user: state.user.user,
  role: state.auth.auth,
  features: state.features.features,
  load: state.features.load,
  departments: state.helpers.helpers,
});

export default connect(mapStateToProps, {
  getUser,
  getLoggedInUser,
  getFeatureRequests,
  getDepartments,
  searchRequestsByDepartments,
})(ResearchFeatureRequest);
