/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useContext } from 'react';
import CustomButton from '../../../../shared/forms/CustomButton';
import CustomInput from '../../../../shared/forms/CustomInput';
import SheetDedicatedCustomerContext from './context/SheetDedicatedCustomerContext';
import Label from '../../../../shared/forms/Label';

const AddSheetDedicatedCustomer = () => {
  const { loadingForm, onChange, formValue, onSubmit } = useContext(SheetDedicatedCustomerContext);
  const { pptp_ip, private_ip, public_ip, tizeti_site_id } = formValue;
  const submitForm = () => {
    return pptp_ip.length > 0 && private_ip.length > 0 && public_ip.length > 0 && tizeti_site_id.length > 0;
  };
  return (
    <div id="add-access-point" className="modal fade mt-5" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header shadow">
            <h4 className="modal-title update_asset">Facebook Site</h4>
            <button type="button" className="btn btn-outline-info btn-circle btn-close" data-dismiss="modal">
              x
            </button>
          </div>
          <div className="modal-body detail mb-4 p-0">
            <form
              className="col-md-12 p-0"
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              <div className="d-flex">
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="Tizeti Site Id" htmlfor="customerName" />
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="tizeti_site_id"
                    value={tizeti_site_id}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="e.g (fc_sweetsensa)"
                    type="text"
                    id="customerName"
                  />
                </div>
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="Public IP" htmlfor="customerEmail" />

                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="ip_address"
                    value={public_ip}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="154.113.89.138"
                    type="text"
                    id="customerEmail"
                  />
                </div>
              </div>

              <div className="d-flex">
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="Private IP" htmlfor="router_id" />

                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="private_ip"
                    value={private_ip}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="e.g (21.1)"
                    type="text"
                    id="router_id"
                  />
                </div>
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="pptp_ip" htmlfor="radioMACID" />

                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="pptp_ip"
                    value={pptp_ip}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="PPTP IP"
                    type="text"
                    id="radioMACID"
                  />
                </div>
              </div>

              <div className="text-center mt-3">
                {loadingForm ? (
                  <CustomButton classname="btn btn-green px-3" name="Adding" disabled={loadingForm} />
                ) : (
                  <CustomButton classname="btn btn-green px-3" name="Add" disabled={!submitForm()} />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSheetDedicatedCustomer;
