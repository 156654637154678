import React, { useContext } from 'react';
// import SearchNocSheets from '../SearchNocSheets';
import FacbookSitesTable from './FacbookSitesTable';
import AddFacebookSite from './AddFacebookSite';
import PaginatePage from '../common/PaginatePage';
import FacebookSiteContext from './context/FacebookSiteContext';

const FacebookSiteBody = () => {
  const { loading, pages, paginateChange } = useContext(FacebookSiteContext);
  return (
    <div className="container">
      <div className="row mt-5">
        {/* <SearchCnMaestro /> */}
        <div className="form-group ml-2">
          <button className="btn btn-green" type="button" data-toggle="modal" data-target="#add-access-point">
            Add Facebook Site
          </button>
        </div>
        <AddFacebookSite />
        <FacbookSitesTable />
        {!loading && <PaginatePage pages={pages} paginateChange={paginateChange} />}
      </div>
    </div>
  );
};

export default FacebookSiteBody;
