/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import ProcurementNavBar from '../../../../layouts/procurement/ProcurementNavBar';
import useCheckDepartment from '../../../../../hooks/shared/custom/check-department/useCheckDepartment';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import { PROCUREMENT, SUPER_ADMIN } from '../../../../../utils/departments/helpers';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import PurchaseOrderBody from '../../../shared/purchase-order/PurchaseOrderBody';
import useCheckTwoDepartments from '../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';

const ProcurementPurchaseOrder = ({ history }) => {
  useCheckTwoDepartments(`${PROCUREMENT}`, `${SUPER_ADMIN}`, history);
  const {
    profile: { firstName, department_id },
  } = useUser();

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <span className="d-none d-md-block">
          <ProcurementNavBar currentPage="Procurement Purchase Order" position={firstName} />
        </span>
        <span className="d-md-none d-block">
          <ProcurementNavBar currentPage="Procurement PO" position={firstName} />
        </span>
        <PurchaseOrderBody deptID={department_id} />
      </main>
    </div>
  );
};

ProcurementPurchaseOrder.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default ProcurementPurchaseOrder;
