import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { ZonesContext } from '../../../shared/installations/zones/provider/ZonesContext';
// import { ZoneProps } from './ZonesTable';

const DeleteZoneModal = ({ zone, type }) => {
  const { onShowModal, showDeleteModal, deleteZone, deletingZone, deleteSubZone } = useContext(ZonesContext);
  const showButton = () => {
    if (deletingZone) {
      return (
        <Button variant="danger" disabled>
          Deleting Zone...
        </Button>
      );
    }
    return (
      <Button variant="danger" onClick={() => deleteZone(zone.id)}>
        Delete Zone
      </Button>
    );
  };

  const showSubzoneButton = () => {
    if (deletingZone) {
      return (
        <Button variant="danger" disabled>
          Deleting SubZone...
        </Button>
      );
    }
    return (
      <Button variant="danger" onClick={() => deleteSubZone(zone.id)}>
        Delete SubZone
      </Button>
    );
  };

  return (
    <Modal show={showDeleteModal} onHide={onShowModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete {zone.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete `{zone.name}` {type}?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onShowModal}>
          Close
        </Button>
        {type === 'subzone' ? showSubzoneButton() : showButton()}
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteZoneModal;
