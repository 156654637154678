import React from 'react';
import AppContext from '../../../../../context/shared/AppContext';
import useTeams from './hooks/useTeams';

const TeamsProvider = ({ children }) => {
  const {
    teams,
    fetching,
    loadingForm,
    onTeamChange,
    team,
    onSubmitTeam,
    openCreateModal,
    closeCreateModal,
    showCreateModal,
    deleteTeam,
    getTeams,
  } = useTeams();
  return (
    <AppContext.Provider
      value={{
        teams,
        fetching,
        loadingForm,
        onTeamChange,
        team,
        onSubmitTeam,
        openCreateModal,
        closeCreateModal,
        showCreateModal,
        deleteTeam,
        getTeams,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export default TeamsProvider;
