import React from 'react';
import SupportCallsByZone from '../../departments/super-admin/statistics-dashboard/support-calls/SupportCallsByZone';
const SharedSupportCalls = () => {
  // const { user, profile } = useUser();
  const getDepartmentId = sessionStorage.getItem('departmentId');
  return (
    <div>
      <div className="">
        <SupportCallsByZone />
      </div>
    </div>
  );
};

export default SharedSupportCalls;
