/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/button-has-type */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable import/no-extraneous-dependencies */
import React, { Component } from 'react';
import axios from 'axios';
import edit from '../../../../../assets/img/icons/edit.png';
import AddBasestation from '../../../departments/noc/basestations/AddBasestation';
import bell from '../../../../../assets/img/icons/Noc/notification_bell.png';
import NocVoltageTable from '../../../departments/noc/voltage/NOCVoltageTable';
import { API_URL } from '../../../../../utils/config';
import LowVoltageAlert from '../../../departments/noc/voltage/LowVoltageAlert';
import CustomButton from '../../forms/CustomButton';
// import 'morris.js/morris.css';
// import 'morris.js/morris.js';
import './index.css';
import { PULL_VOLTAGE_MONITOR_URL } from './voltageMonitorUrl';

class VoltageMonitor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      voltage: [],
      loading: false,
      threshold: '',
      value: [],
      filtered: [],
      keyword: '',
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.getVoltage();
  }

  onSubmit(e) {
    const { threshold, value } = this.state;
    e.preventDefault();
    this.setState({
      value: threshold,
    });

    localStorage.setItem('valu', value);
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  getVoltage() {
    this.setState({
      loading: true,
    });
    axios({
      method: 'get',
      url: `${API_URL}/getlogs`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
      .then((response) => {
        this.setState({
          voltage: response.data,
          filtered: response.data,
          loading: false,
        });
      })
      .catch((error) => {
        if (error) {
          this.setState({
            loading: false,
          });
        }
      });
  }

  searchVoltage = (e) => {
    const keyword = e.target.value;
    const { voltage } = this.state;

    if (keyword !== '') {
      const list = voltage.filter((ips) => {
        if (ips.name.toLowerCase().indexOf(keyword) !== -1) {
          return ips;
        }
        if (ips.ip_address.toLowerCase().indexOf(keyword) !== -1) {
          return ips;
        }
      });
      this.setState({
        filtered: list,
        keyword: e.target.value,
      });
    } else {
      this.setState({
        filtered: voltage,
        keyword,
      });
    }
  };

  pullVoltage = async () => {
    try {
      const response = await axios.get(PULL_VOLTAGE_MONITOR_URL);
      console.log(response);
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const { voltage, loading, value, filtered, keyword, threshold } = this.state;
    return (
      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-12 col-md-12 col-sm-12 d-flex mt-4">
            <form className="form-inline col-md inner-addon left-addon">
              <input
                className="form-control mr-sm-2 w-100 empty mt-1"
                type="text"
                placeholder="Search using Basestation name"
                value={keyword}
                onChange={(e) => this.searchVoltage(e)}
              />
              <span className="fa fa-search" />
            </form>

            <div className="col-md">
              <h6 className="mt-3">
                <strong>Voltage Threshold</strong>
                <button
                  className="ml-2"
                  type="submit"
                  data-toggle="modal"
                  data-target="#set"
                  style={{
                    border: '0',
                    backgroundColor: 'white',
                  }}
                >
                  <span className="view">
                    <img src={edit} data-toggle="tooltip" data-placement="right" alt="" title="Edit" />
                  </span>
                </button>
              </h6>
              {/* <SetThresold/> */}
              <div
                id="set"
                className="modal fade in"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="myModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title text-center" id="myModalLabel">
                        Set Thresold
                      </h4>
                      <button type="button" className="close custom-close" data-dismiss="modal" aria-hidden="true">
                        ×
                      </button>
                    </div>

                    <div className="modal-body">
                      <form className="form-horizontal" onSubmit={this.onSubmit}>
                        <div className="form-group">
                          <div className="col-md-12 mb-3">
                            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                            <label className="copy-font p-0 m-0">Set Voltage Thresold</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Set Voltage Thresold"
                              value={threshold}
                              name="threshold"
                              onChange={this.onChange}
                            />
                          </div>
                          <div className="text-center">
                            <button className="btn btn-green">Set</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md m-0 p-0 text-end voltage-btns">
              {/* eslint-disable-next-line react/button-has-type */}
              <button className="btn btn-green" data-target="#noc-base" data-toggle="modal">
                Add Basestation
              </button>
              <AddBasestation />

              <CustomButton
                onClick={this.pullVoltage}
                classname="btn btn-green"
                name="Pull Voltage Monitor"
                disabled={false}
              />
            </div>

            <div className="col-md d-flex dropdown m-0 p-0" id="notification">
              <img src={bell} alt="logo" className="dropdown-toggle" data-toggle="dropdown" />
              <LowVoltageAlert />
            </div>

            <div
              id="add-employee"
              className="modal fade in"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="myModalLabel"
              aria-hidden="true"
              style={{ overflow: 'auto' }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title text-center" id="myModalLabel">
                      Add Access Point
                    </h4>
                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                      ×
                    </button>
                  </div>

                  {/* <div className="modal-footer">
                                                    <a href="#success2" data-toggle="modal" data-dismiss="modal"></a>
                                                </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="card x-panel mt-3">
            <div className="table-responsive content col-lg-12 col-md-12 col-sm-12">
              <table className="table col-lg-12 col-md-12 col-sm-12">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Basestation Name</th>
                    <th>IP ADDRESS</th>
                    <th>Date Created</th>
                    <th>Time Created</th>
                    <th>VOLT</th>
                    <th>Status</th>
                    {/* <th>I2</th>
                                                <th className="text-center">Temperature</th>
                                                <th>Status</th> */}
                    {/* <th className="text-center">VOLT</th> */}
                    {/* <th>I2</th> */}
                    {/* <th className="text-center">Temperature</th> */}
                    {/* <th className="text-center">Status</th> */}
                    {/* <th>View</th> */}
                  </tr>
                </thead>
                {voltage ? <NocVoltageTable volt={filtered} thresh={value} load={loading} /> : null}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VoltageMonitor;
