/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import FieldSupportNavBar from '../../../../layouts/field-support/FieldSupportNavBar';
import CleanLTEBody from './CleanLTEBody';

const FieldSupportCleanLTE = () => {
  const { profile, user } = useUser();
  const { firstName } = profile;

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <FieldSupportNavBar currentPage="Clean LTE" roleID={user.role_id} position={firstName} />
        <CleanLTEBody />
      </main>
    </div>
  );
};

export default FieldSupportCleanLTE;
