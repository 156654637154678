/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useContext } from 'react';
import CustomButton from '../../../../shared/forms/CustomButton';
import AppContext from '../../../../../../context/shared/AppContext';
import Label from '../../../../shared/forms/Label';
import CustomInput from '../../../../shared/forms/CustomInput';
import CustomDropdownOption from '../../../../shared/forms/CustomDropdownOption';
import useFetch from '../../../../../../hooks/shared/custom/data/useFetch';
import { GET_BASESTATIONS_URL } from '../../../../../../services/api/shared/installations/InstallationsUrl';

const AddCambiumLicenseKeys = () => {
  const { loadingForm, onChange, formValue, onSubmit } = useContext(AppContext);
  const { data } = useFetch(`${GET_BASESTATIONS_URL}`);
  const { ethernet_mac_address, basestation, entitlement_activation_code, license_keys } = formValue;
  const submitForm = () => {
    return (
      license_keys.length > 0 && entitlement_activation_code.length > 0 && ethernet_mac_address.length > 0
      // basestation.length > 0
    );
  };
  return (
    <div id="add-access-point" className="modal fade mt-5" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header shadow">
            <h4 className="modal-title update_asset">Cambium Licence Keys</h4>
            <button type="button" className="btn btn-outline-info btn-circle btn-close" data-dismiss="modal">
              x
            </button>
          </div>
          <div className="modal-body detail mb-4 p-0">
            <form
              className="col-md-12 p-0"
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              <div className="d-flex">
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="Site Name" />
                  <select
                    className="form-control border border-info p-2"
                    id="class"
                    name="basestation"
                    style={{ borderRadius: '0' }}
                    onChange={onChange}
                  >
                    <option>--Basestation--</option>
                    {data.map((bases) => {
                      return <CustomDropdownOption value={bases.name} name={bases.name} />;
                    })}
                  </select>
                </div>
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="Ethernet Mac Address" />
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="ethernet_mac_address"
                    value={ethernet_mac_address}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="Enter Ethernet Mac Address"
                    type="text"
                    id="customerEmail"
                  />
                </div>
              </div>

              <div className="d-flex">
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="Entitlement Activation Code" />
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="entitlement_activation_code"
                    value={entitlement_activation_code}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="Enter Entitlement Activation Code"
                    type="text"
                    id="customerEmail"
                  />
                </div>
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="License Keys" />
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="license_keys"
                    value={license_keys}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="Enter License Keys"
                    type="text"
                    id="customerEmail"
                  />
                </div>
              </div>

              <div className="text-center mt-3">
                {loadingForm ? (
                  <CustomButton classname="btn btn-green px-3" name="Adding..." disabled={loadingForm} />
                ) : (
                  <CustomButton classname="btn btn-green px-3" name="Add" disabled={!submitForm()} />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCambiumLicenseKeys;
