import React from 'react';

const TotalUsers = () => {
  return (
    <div className="mr-auto col mb-3">
      <div className="boxes">
        <div className="d-flex mt-3">
          <div className="mt-4 pl-2">
            <img src="https://res.cloudinary.com/teewhy/image/upload/v1545151448/book4.fw.png" alt="" />
          </div>
          <div className="mt-3 p-2">
            {/* {loading ? <img src={loader} alt=""/> : <div className="count">{count.toLocaleString()}</div>} */}
            <h3>Total Users</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalUsers;
