/* eslint-disable import/prefer-default-export */
import { useState, useReducer, useEffect } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import qs from 'qs';
import * as load from '../../../constants/types/shared/LoadingTypes';
import { GET_ALL_DEDICATED_INVOICE } from '../../../constants/types/accounts/DedicatedInvoiceTypes';
import * as url from '../../../services/api/accounts/dedicated-invoice/DedicatedInvoiceURL';
import { dedicatedInvoiceReducer } from '../../../reducers/accounts/dedicated-invoice/dedicateInvoiceReducer';
import { LEGACY_GENIATECH_URL } from '../../../services/api/shared/installations/InstallationsUrl';

export const useSalesQuotation = (mac) => {

    const today = new Date()

    const validUntil = new Date()

    validUntil.setMinutes(validUntil.getMinutes() + 1440)

    const invoiceState = {
        date: today.toISOString().split('T')[0],
        name: '',
        email: '',
        address: '',
        valid_until: validUntil.toISOString().split('T')[0],
        item_code: '',
        item_description: '',
        discount: '',
        quantity: 1,
        units: '',
        price: '',
        memoPrice: '',
        total: '',
        sub_total: '',
        total_ex_vat: '',
        total_with_vat: ''
    };

    const [invoice, setInvoice] = useState(invoiceState)
    const [isLoading, setIsLoading] = useState(false)


    const handleChange = (e) => {
        setInvoice({ ...invoice, [e.target.name]: e.target.value })
    }

    const handleChangePrice = (e) => {

        const calculated = Number(e.target.value) > 0 || e.target.value !== "" ? Number(e.target.value) * Number(invoice.price) : invoice.memoPrice
        setInvoice({
            ...invoice,
            [e.target.name]: e.target.value,
            discount: e.target.value !== "" ? invoice.discount : "",
            price: calculated,
            total: calculated,
            sub_total: calculated,
            total_ex_vat: calculated,
            total_with_vat: calculated
        })
    }

    const handleDiscountChange = (e) => {
        const calculated = Number(e.target.value) > 0 || e.target.value !== "" ?
            ((Number(invoice.quantity) * Number(invoice.memoPrice)) - (Number(e.target.value) / 100) * (Number(invoice.quantity) * Number(invoice.memoPrice)))
            : invoice.memoPrice
        setInvoice({
            ...invoice,
            [e.target.name]: e.target.value,
            quantity: e.target.value !== "" ? invoice.quantity : 1,
            price: calculated,
            total: calculated,
            sub_total: calculated,
            total_ex_vat: calculated,
            total_with_vat: calculated
        })
    }


    useEffect(() => {

        if (mac) {
            (async () => {
                setIsLoading(true)
                const params = new FormData();
                params.append('action', 'get_dedicated_customer_quotation_details');
                params.append('mac_id', mac);

                try {

                    const response = await axios.post(LEGACY_GENIATECH_URL, params);
                    if (response.status === 200) {
                        setInvoice({
                            ...invoice,
                            ...response.data,
                            quantity: 1,
                            total: response.data.price,
                            sub_total: response.data.price,
                            memoPrice: response.data.price,
                            total_ex_vat: response.data.price,
                            total_with_vat: response.data.price
                        })
                    }

                } catch (error) {

                }

                setIsLoading(false)
            })()
        }

    }, [mac])


    return {
        invoice,
        isLoading,
        setInvoice,
        handleChange,
        handleChangePrice,
        handleDiscountChange
    };
};
