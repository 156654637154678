/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useState } from 'react';
import moment from 'moment';
import view from '../../../../../assets/img/icons/view.png';
import ViewReceipt from '../shared/ViewReceipt';
import CommonRequisitionDetail from '../shared/CommonRequistionDetails';
import RequisitionContext from '../../../../../context/shared/requisitions/RequsitionContext';
import RejectRequisition from '../shared/RejectRequisition';
import ApproveRequsition from '../shared/ApproveRequsition';
import helpers from '../../../../../utils/requisition/helpers';

const ManagersRequisitionBody = () => {
  const value = useContext(RequisitionContext);
  const { managerRequisitions } = value;
  const [requisit, setRequisit] = useState({});
  const [viewFile, setViewFile] = useState({});

  const getRequsition = (req) => {
    setRequisit(req);
  };

  const getFileRequsition = (req) => {
    setViewFile(req);
    if (req.imageUpload.split('.').pop() === 'document'  || req.imageUpload.split('.').pop() === 'pdf') {
      window.open(req.imageUpload, '_blank');
    }
  };

  const requsitionList =
    managerRequisitions && managerRequisitions.length > 0 ? (
      managerRequisitions.map((requsition, i) => {
        return (
          <tr key={requsition.id}>
            <td>{i + 1}</td>
            <td>
              {helpers.displayCurrency(requsition.currency)}
              {requsition.amount_in_digits}
            </td>
            <td>{requsition.amount_in_words}</td>
            <td>{requsition.account_approval}</td>
            <td>{requsition.manager_approval}</td>
            <td>{requsition.ceo_or_coo_approval}</td>
            <td>{requsition.in_favour_of}</td>
            <td>{requsition.requires_c_level_approval === false ? 'False' : 'True'}</td>
            <td>{requsition.requires_ceo_or_coo_approval === false ? 'False' : 'True'}</td>
            {requsition.created_at ? (
              <td className="text-nowrap">{moment(requsition.created_at).format('DD-MM-YYYY')}</td>
            ) : (
              'N/A'
            )}

            <td>{requsition.purpose}</td>
            <td>
              {/* <small onClick={() => giveManagerApproval(requsition.id)} style={{ cursor: 'default' }}> */}
              {/*  <button className="btn btn-green">Approve</button> */}
              {/* </small> */}
              <small style={{ cursor: 'default' }}>
                <button
                  className="btn btn-green"
                  data-toggle="modal"
                  data-target="#approve"
                  onClick={() => getRequsition(requsition)}
                >
                  Approve
                </button>

                <ApproveRequsition requisit={requisit} />
              </small>
            </td>
            <td>
              {/* <small onClick={() => giveManagerDisApproval(requsition.id)} style={{ cursor: 'default' }}> */}
              <small style={{ cursor: 'default' }}>
                <button
                  className="btn btn-danger"
                  data-toggle="modal"
                  data-target="#reject"
                  onClick={() => getRequsition(requsition)}
                >
                  Reject
                </button>

                <RejectRequisition requisit={requisit} />
              </small>
            </td>

            <td>
              <div className="mr-2 ml-2">
                <button
                  data-target="#viewReceipt"
                  data-toggle="modal"
                  type="submit"
                  onClick={() => getFileRequsition(requsition)}
                  style={{
                    border: '0',
                    backgroundColor: 'white',
                  }}
                >
                  <span className="view2">
                    <img src={view} alt="view" data-toggle="tooltip" data-placement="left" title="View" />
                  </span>
                </button>
                <ViewReceipt requisit={viewFile} />
              </div>
            </td>
            <td>
              <div
              style={{width: '100%', fontSize: '12px'}}
                className={`badge badge-${
                  requsition.account_approval === 'approved' ? 'success' : 'secondary'
                } text-uppercase`}
              >
                {requsition.account_approval === 'approved' ? 'Paid' : 'UnPaid'}
              </div>
            </td>

            <td>
              <div className="mr-2 ml-2 d-flex">
                <button
                  data-target="#requisition"
                  data-toggle="modal"
                  type="submit"
                  onClick={() => getRequsition(requsition)}
                  style={{
                    border: '0',
                    backgroundColor: 'white',
                  }}
                >
                  <span className="view2">
                    <img src={view} alt="view" data-toggle="tooltip" data-placement="left" title="View" />
                  </span>
                </button>
                <CommonRequisitionDetail requisit={requisit} />
              </div>
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td>no requsitions</td>
      </tr>
    );

  return <tbody>{requsitionList}</tbody>;
};

export default ManagersRequisitionBody;
