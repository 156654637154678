/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const CustomerSessionTable = ({ value }) => {
  return (
    <Fragment>
      {value !== undefined ? (
        <tr>
          <td>{value.ipaddress ? <a href={`http://${value.ipaddress}`}>{value.ipaddress}</a> : null}</td>
          <td>{value.onlinestatus ? value.onlinestatus : null}</td>
          <td>{value.lastconnected ? value.lastconnected : null}</td>
          <td>{value.staticIpaddress ? value.staticIpaddress : null}</td>
        </tr>
      ) : null}
    </Fragment>
  );
};

CustomerSessionTable.propTypes = {
  value: PropTypes.shape(),
};
export default CustomerSessionTable;
