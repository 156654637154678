/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React from 'react';
import Moment from 'react-moment';

const ViewCustomerDetails = ({
  oneItem: {
    username,
    Expiration_date,
    Name,
    Mail,
    Mobile,
    Customer_Number,
    created_at,
    zone,
    basestation,
    country,
    region,
    agent,
    subscription_status,
  },
  address,
  isLoading,
}) => {
  return (
    <div id="view_item" className="modal fade mt-5" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title my-2">Customer Details</h4>
            <button type="button" className="mr-2 close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div className="modal-body detail p-0">
            <div className="row">
              <div className="col-lg-12 d-flex">
                <div className="col-lg-5 detail-label1">
                  <label className="mt-1">Name</label>
                  <label className="mt-1">Email</label>
                  <label className="mt-1">Phone</label>
                </div>
                <div className="col-lg-7 detail-result1 item-font">
                  <p className="mt-4 mb-2">{Name}</p>
                  <p className="mb-2">{Mail}</p>
                  <p className="mb-2">{Mobile}</p>
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-5 detail-label3">
                  <label>MACID</label>
                  <label className="mt-1">Customer Number</label>
                  <label className="mt-1">Basestation</label>
                  <label className="mt-1">Zone</label>
                  <label className="mt-1">STATUS</label>
                </div>
                <div className="col-lg-7 detail-result2 item-font">
                  <p className="mt-4 mb-2">{username}</p>
                  <p className="mb-2">{Customer_Number}</p>
                  <p className="mb-2 text-uppercase">{basestation}</p>
                  <p className="mb-2">{zone}</p>
                  <p className="mb-2 text-uppercase">{subscription_status}</p>
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-5 detail-label2">
                  <label>Created Date</label>
                  <label className="mt-1">Expiration Date</label>
                  <label className="mt-1">Agent</label>
                </div>
                <div className="col-lg-7 detail-result2 item-font">
                  <p className="mt-4 mb-2 text-capitalize">
                    <Moment format="DD MMM YYYY">{created_at}</Moment>
                  </p>
                  <p className="mb-2">{Expiration_date}</p>
                  <p className="mb-3">{agent}</p>
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-5 detail-label3">
                  <label>Country</label>
                  <label className="mt-1">State</label>
                  <label className="mt-1">Address</label>
                </div>
                <div className="col-lg-7 detail-result2 item-font">
                  <p className="mt-4 mb-2 text-capitalize">{country}</p>
                  <p className="mb-2">{region}</p>
                  <p className="mb-3">{isLoading ? 'loading...' : address}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ViewCustomerDetails);
