/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useState } from 'react';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../../utils/loader/loadingHelper';
import AppContext from '../../../../../context/shared/AppContext';
import helpers from '../../../../../utils/helpers';
import CleanRadioModal from './CleanRadioModal';
import Edit from '../../../../../assets/img/icons/edit.png';

const colSpan = '6';
const CleanRadioTable = () => {
  const { cleanRadiosData, fetching, onOpenModal } = useContext(AppContext);
  const [detail, setDetail] = useState({
    account_approval: '',
    account_comment: '',
    comment: '',
    created_at: '',
    customer_email: '',
    customer_name: '',
    customer_number: '',
    item: '',
    procurement_approval: '',
    procurement_comment: '',
    radio_mac_id: '',
    radio_tag_no: '',
    retrieval_date: '',
    updated_at: '',
  });
  const getOne = (param) => {
    setDetail(param);
    onOpenModal();
  };
  const tableData =
    cleanRadiosData && cleanRadiosData.length ? (
      cleanRadiosData.map((dat, i) => (
        <tr className="text-nowrap">
          <td>{i + 1}</td>
          <td>{dat.item}</td>
          <td>{dat.procurement_approval}</td>
          <td>{dat.procurement_comment}</td>
          <td>{dat.account_approval}</td>
          <td>{dat.account_comment}</td>
          <td>{dat.customer_number || 'No Number'}</td>
          <td>{dat.radio_mac_id}</td>
          <td>{dat.customer_name}</td>
          <td>{dat.customer_email}</td>
          <td>{dat.radio_tag_no}</td>
          <td>{dat.retrieval_date}</td>
          <td>{dat.comment || 'No comment'}</td>
          <td>{helpers.formatDateAndTime(dat.created_at)}</td>
          <td>{helpers.formatDateAndTime(dat.updated_at)}</td>
          <td>
            <img onClick={() => getOne(dat)} src={Edit} alt="clean radio" />
          </td>
        </tr>
      ))
    ) : (
      <NoItemRow colSpan="13" text="No Radios To Be Cleaned" />
    );
  return (
    <table className="table">
      <thead>
        <tr>
          <th>#</th>
          <th>Item</th>
          <th>Procurement Approval</th>
          <th>Procurement Comment</th>
          <th>Account Approval</th>
          <th>Account Comment</th>
          <th>Customer Number</th>
          <th>Radio Mac ID</th>
          <th>Customer Name</th>
          <th>Customer Email</th>
          <th>Radio Tag Number</th>
          <th>Retrieval Date</th>
          <th>Comment</th>
          <th>Created Date</th>
          <th>Last Updated Date</th>
          <th>Action</th>
          <CleanRadioModal details={detail} />
        </tr>
      </thead>
      <tbody>{fetching ? <TizetiGIFLogoTable colSpan={colSpan} /> : tableData}</tbody>
    </table>
  );
};

export default CleanRadioTable;
