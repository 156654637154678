export const UPLOADING_INSTALLATION_IMAGES = 'UPLOADING_INSTALLATION_IMAGES';
// export const UPLOADING_IMAGES_SUCCESSFUL = "UPLOADING_INSTALLATION_IMAGES";
export const GET_ZONES = 'GET_ZONES';
export const GET_SUB_ZONES = 'GET_SUB_ZONES';
export const CLUSTERING_STARTS = 'CLUSTERING_STARTS';
export const CLUSTER_INSTALLATION = 'CLUSTER_INSTALLATION';
export const CLUSTERING_STOPS = 'CLUSTERING_STOPS';
export const ROUTE_INSTALLATION = 'ROUTE_INSTALLATION';
export const GET_INSTALLATIONS = 'GET_INSTALLATIONS';
export const GET_INSTALLATION = 'GET_INSTALLATION';
export const GET_RESCHEDULED_INSTALLATIONS = 'GET_RESCHEDULED_INSTALLATIONS';
export const START_ASSIGN = 'START_ASSIGN';
export const STOP_ASSIGN = 'STOP_ASSIGN';
export const DELETE_ZONE = 'DELETE_ZONE';
export const GET_ASSIGNED_STAFF = 'GET_ASSIGNED_STAFF';
export const DELETE_INSTALLATION = 'DELETE_INSTALLATION';
