/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import moment from 'moment';
import cancel from '../../../../../assets/img/icons/Noc/noc-cancel.fw.png';
import useVoltageMonitor from '../../../../../hooks/shared/useVoltageMonitor';

const LowVoltageAlert = () => {
  const { lowVoltage } = useVoltageMonitor();

  return (
    <>
      <span className="badge badge-warning copy-font" id="small-badge">
        {lowVoltage.length}
      </span>
      <ul className="dropdown-menu" id="follow-tru">
        {lowVoltage.length > 0 ? (
          lowVoltage.sort((a, b) => a.value - b.value) &&
          lowVoltage.map((voltage, i) => {
            const { name, value, ip_address, created_at } = voltage;
            return (
              <li key={i}>
                <div className="col-12 d-flex">
                  <div className="mr-2">
                    <img src={cancel} alt="noc" />
                  </div>
                  <div>
                    <p className="copy-font mb-0">
                      <span className="mr-1">{i + 1})</span>
                      <strong>{name}</strong> - <small className="copy-font">{ip_address}</small>
                    </p>
                    <p className="p-0 m-0 copy-font">{value}V</p>
                    <p className="p-0 copy-font">{moment(created_at).format('DD-MMM-YYYY hh:mm A')}</p>
                  </div>
                </div>
                <hr />
              </li>
            );
          })
        ) : (
          <li className="mx-2">No Critical Volts</li>
        )}
      </ul>
    </>
  );
};

export default LowVoltageAlert;
