/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useCustomer } from '../../../../../hooks/shared/customers/useCustomers';
import { usePayments } from '../../../../../hooks/shared/customers/usePayments';
import ViewPassword from '../../../shared/customers/passwords/ViewPassword';
import EditCustomerDetails from './EditCustomerDetails';
import PaymentsHistory from '../../../shared/customers/payments/PaymentsHistory';
import view from '../../../../../assets/img/icons/view.png';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import ColonlessCustomerDetail from './ColonlessCustomerDetail';

const ProcurementCustomerColonless = ({ isLoading, customers }) => {
  const {
    getPassword,
    gettingPassword,
    password,
    getUserDetails,
    gettingDetails,
    details,
    itemprice,
    address,
    session,
  } = useCustomer();

  const [mac] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone] = useState('');
  const [customerRef, setCustomerRef] = useState('');

  const { payments, gettingPayments, getCustomerPayments } = usePayments();

  const getUserPassword = (customer) => {
    const data = {
      action: 'getCustomerPassword',
      username: customer.columnless_mac_id,
    };
    getPassword(data);
  };

  const getUserPayments = (customer) => {
    const data = {
      macid: customer.columnless_mac_id,
    };
    getCustomerPayments(data);
  };

  const getCustomerDetails = (customer) => {
    setName(customer.username);
    setEmail(customer.user.email);
    setCustomerRef(customer.customer_ref);
    getUserDetails(customer.mac_id);
  };

  const displayCustomers = () => {
    if (customers.id) {
      return (
        <tr>
          <td>1</td>
          <td>{customers.columnless_mac_id}</td>
          <td nowrap="no-wrap">{customers.user ? `${customers.user.firstName} ${customers.user.lastName}` : 'N/A'}</td>
          <td>{customers.user ? customers.user.email : 'N/A'}</td>
          <td className="ml-2">
            <button
              data-toggle="modal"
              data-target="#view-password"
              className="ml-3"
              onClick={() => getUserPassword(customers)}
            >
              <i className="view1 fa fa-ellipsis-v" style={{ fontSize: '20px' }} />
            </button>
            <ViewPassword password={password} load={gettingPassword} />
          </td>
          <td>{customers.user ? customers.user.phoneNumber : 'N/A'}</td>

          <td nowrap="no-wrap">N/A</td>

          <EditCustomerDetails username={mac} oldEmail={email} oldName={name} oldPhone={phone} />

          <td className="ml-2">
            <button
              data-toggle="modal"
              data-target="#payment-history"
              className="ml-3"
              onClick={() => getUserPayments(customers)}
            >
              <i className="view1 fa fa-ellipsis-v" style={{ fontSize: '20px' }} />
            </button>
            <PaymentsHistory load={gettingPayments} transactions={payments} />
            <button
              type="submit"
              style={{
                border: '0',
                backgroundColor: 'white',
                width: '0%',
              }}
              data-toggle="tooltip"
              data-placement="left"
              title="View"
              onClick={() => getCustomerDetails(customers)}
            >
              <span className="view1 ml-4">
                <img src={view} alt="" data-toggle="modal" data-target="#col-details" />
              </span>
            </button>
            <ColonlessCustomerDetail
              load={gettingDetails}
              user={customers}
              name={customers.user}
              mail={email}
              customerRef={customerRef}
              itemprice={itemprice}
              address={address}
              details={details}
              session={session}
            />
          </td>
        </tr>
      );
    }
    return null;
  };

  return <tbody>{isLoading ? <img src={loader} alt="loading gif" /> : displayCustomers()}</tbody>;
};

ProcurementCustomerColonless.propTypes = {
  // eslint-disable-next-line react/require-default-props
  isLoading: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  customers: PropTypes.shape(),
};

export default ProcurementCustomerColonless;
