/* eslint-disable import/prefer-default-export */
import { useState, useReducer } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import qs from 'qs';
import * as load from '../../../constants/types/shared/LoadingTypes';
import { GET_ALL_DEDICATED_INVOICE } from '../../../constants/types/accounts/DedicatedInvoiceTypes';
import * as url from '../../../services/api/accounts/dedicated-invoice/DedicatedInvoiceURL';
import { dedicatedInvoiceReducer } from '../../../reducers/accounts/dedicated-invoice/dedicateInvoiceReducer';

export const useDedicatedInvoice = () => {
  const [{ lists, count, pages, isLoading }, dispatch] = useReducer(dedicatedInvoiceReducer, {
    lists: [],
    count: 1,
    pages: 1,
    isLoading: false,
  });

  const invoiceState = {
    customerName: '',
    customerEmail: '',
    address: '',
    macID: '',
    subTotal: '',
    total: '',
    totalExVAT: '',
    totalWithVAT: '',
    price: '',
    itemDescription: '',
    quantity: '',
    unit: '',
    discount: '',
    expiryDate: '',
  };

  const [one, setOne] = useState({});
  const [invoice, setInvoice] = useState(invoiceState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInvoice({ ...invoice, [name]: value });
  };

  const handleUpdate = (e) => {
    const { name, value } = e.target;
    setOne({ ...one, [name]: value });
  };

  const createDedicatedInvoice = async (data) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(`${url.GET_DEDICATED_INVOICE_URL}`, qs.stringify(data), {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.id) {
        toastr.success('Created Successfully!');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const getAllDedicatedInvoice = async (page) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.get(`${url.GET_DEDICATED_INVOICE_URL}/${page}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: GET_ALL_DEDICATED_INVOICE,
          payload: {
            list: response.data.list,
            count: response.data.count,
            pages: response.data.pages,
          },
        });
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const updateDedicatedInvoice = async (data, id) => {
    try {
      const response = await axios.patch(`${url.UPDATE_DEDICATED_INVOICE_URL}/${id}`, qs.stringify(data), {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data[0] === 1) {
        toastr.success('Update Successful!');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    getAllDedicatedInvoice(selectedPage);
  };

  return {
    lists,
    pages,
    count,
    isLoading,
    one,
    invoice,
    setOne,
    handleChange,
    handleUpdate,
    handlePageClick,
    getAllDedicatedInvoice,
    updateDedicatedInvoice,
    createDedicatedInvoice,
  };
};
