import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  LabelList,
} from 'recharts';
import Box from '@mui/material/Box';
import { TableCellMonths } from '../components/tempData';
export default function BarCharts({ data, isLoading }) {
  const [getData, setGetData] = React.useState([]);

  React.useEffect(() => {
    if (data.length > 0) {
      const refinedData = TableCellMonths.map((months, index) => ({
        name: months,
        '2023-total-biz-inst-records': data[0].values[index],
      }));

      setGetData(refinedData);
    }
  }, [data]);

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flexStart">
      {!isLoading && (
        <ResponsiveContainer width="100%" height={600}>
          <BarChart
            data={getData}
            margin={{
              top: 5,
              right: 10,
              left: 30,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis>
              <Label
                value="Business Dev Installation Count"
                position="insideLeft"
                angle={-90}
                style={{ textAnchor: 'start' }}
                offset={20}
              />
            </YAxis>
            <Tooltip />
            <Legend />

            <Bar type="monotone" dataKey="2023-total-biz-inst-records" fill="#4cc0d4">
              <LabelList dataKey="2023-total-biz-inst-records" position="top" />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
    </Box>
  );
}
