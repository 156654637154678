/* eslint-disable react/require-default-props */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export const BudgetSideBar = ({ link, name }) => {
  return (
    <li className="s-sidebar__nav-link">
      {' '}
      <Link to={link} target="_self" className="text-danger make-bold">
        <span className="mr-2">
          <img src="https://res.cloudinary.com/teewhy/image/upload/v1557141046/Schedule.png" alt="img" />
        </span>
        {name}
      </Link>
    </li>
  );
};
BudgetSideBar.propTypes = {
  name: PropTypes.string,
  link: PropTypes.string,
};
export default BudgetSideBar;

export const PurchaseOrderTab = ({ link, name }) => {
  return (
    <li className="s-sidebar__nav-link">
      {' '}
      <Link to={link} target="_self" className="">
        <span className="mr-2">
          <img src="https://res.cloudinary.com/teewhy/image/upload/v1557141046/Schedule.png" alt="img" />
        </span>
        {name}
      </Link>
    </li>
  );
};

PurchaseOrderTab.propTypes = {
  name: PropTypes.string,
  link: PropTypes.string,
};
