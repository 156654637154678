import React from 'react';
import usePublicIpAllcation from '../hooks/usePublicIpAllcation';
import AppContext from '../../../../../../../context/shared/AppContext';
import { PUBLIC_IP_ALLOCATIONS_URL } from '../../../../../../../services/api/noc/PublicIpUrl';

const PublicIpAllcationProvider = ({ children }) => {
  const { data, loading, paginateChange, loadingForm, onChange, formValue, onSubmit, pages } = usePublicIpAllcation(
    `${PUBLIC_IP_ALLOCATIONS_URL}`,
  );

  return (
    <AppContext.Provider
      value={{
        data,
        loading,
        paginateChange,
        loadingForm,
        onChange,
        formValue,
        onSubmit,
        pages,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export default PublicIpAllcationProvider;
