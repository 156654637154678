import React from 'react';
import StaffPayslipsTable from './StaffPayslipsTable';
import SearchStaffPayslip from './SearchStaffPayslip';

const StaffPayslipsBody = () => {
  return (
    <div className="container">
      <div className="row mt-5">
        <div className="mt-4 col-lg-12 col-md-12 col-sm-12">
          <div className="container">
            <div className="row mt-5">
              <SearchStaffPayslip />
              <StaffPayslipsTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffPayslipsBody;
