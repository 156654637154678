import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import EmployeesRequisitionsBody from './EmployeesRequisitionsBody';
import RequisitionContext from '../../../../../context/shared/requisitions/RequsitionContext';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';

const EmployeesRequisitions = ({ handlePageClick, totalPages }) => {
  const value = useContext(RequisitionContext);
  const { isFetching } = value;

  return (
    <div className="card x-panel logs">
      <div className="table-responsive content">
        <table className="table copy-font">
          <thead>
            <tr>
              <th>#</th>
              <th>Amount In Digits</th>
              <th>Amount In Words</th>
              <th>Accounts Approval Status </th>
              <th>Manager Approval Status </th>
              <th>C Level Approval Status</th>
              <th>CEO/COO LEVEL Approval Status </th>
              <th>In Favor Of</th>
              <th>Requires C Level Approval?</th>
              <th>Requires CEO/COO Level Approval?</th>
              <th>Created At</th>
              <th>Purpose</th>
              <th>View Uploaded File</th>
              <th>View Details</th>
            </tr>
          </thead>
          {isFetching ? <img src={loader} alt="loading gif" /> : <EmployeesRequisitionsBody />}
        </table>
      </div>

      {totalPages > 1 ? (   
        <ReactPaginate
          previousLabel="previous"
          nextLabel="next"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName="pagination"
          subContainerClassName="pages pagination"
          activeClassName="active"
        />
      ) : null}
    </div>
  );
};

EmployeesRequisitions.propTypes = {
  handlePageClick: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export default EmployeesRequisitions;
