/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable camelcase *
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-lone-blocks */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import '../../../../../styles/style.css';
import '../../../../../styles/callog.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import toastr from 'toastr';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { stringify } from 'qs';
import AddCallLog from './AddLog';
import SalesLogsTable from './SalesLogsTable';
import { getUser } from '../../../../../actions/shared/user/UserActions';
import { getLoggedInUser } from '../../../../../actions/user/UserActions';
import {
  getCountries,
  getLogs,
  getLogsByParams,
  getPaginatedCallLogs,
  searchLog,
  getCities,
  getStates,
} from '../../../../../actions/sales/logs/LogsActions';
import { getDepartments } from '../../../../../actions/shared/departments/DepartmentsActions';
import { getEmployeesInSales } from '../../../../../actions/shared/call-logs/CallLogsActions';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import SalesSideBar from '../../../../layouts/sales/SalesSideBar';
import Logout from '../../../shared/auth/Logout';
import { SALES } from '../../../../../utils/config';
import CustomCsvDownload from '../../../shared/CustomCsvDownload';
import { filename, salesLogHeader } from '../../../../../utils/field-support/installations/helpers';
import { SUPER_ADMIN, VICE_PRESIDENT_SALES } from '../../../../../utils/departments/helpers';
import CustomInput from '../../../shared/forms/CustomInput';
import './index.css';

const searchFieldOptions = [
  { name: 'Email', value: 'email' },
  { name: 'First Name', value: 'firstName' },
  { name: 'Last Name', value: 'lastName' },
  { name: 'Phone Number', value: 'phoneNumber' },
  { name: 'State', value: 'state' },
  { name: 'Country', value: 'country' },
  { name: 'City', value: 'city' },
];
const statesArray = [
  {
    state: {
      name: 'Abia',
      id: 1,
    },
  },
  {
    state: {
      name: 'Adamawa',
      id: 2,
    },
  },
  {
    state: {
      name: 'Akwa Ibom',
      id: 3,
    },
  },
  {
    state: {
      name: 'Anambra',
      id: 4,
    },
  },
  {
    state: {
      name: 'Bauchi',
      id: 5,
    },
  },
  {
    state: {
      name: 'Bayelsa',
      id: 6,
    },
  },
  {
    state: {
      name: 'Benue',
      id: 7,
    },
  },
  {
    state: {
      name: 'Borno',
      id: 8,
    },
  },
  {
    state: {
      name: 'Cross River',
      id: 9,
    },
  },
  {
    state: {
      name: 'Delta',
      id: 10,
    },
  },
  {
    state: {
      name: 'Ebonyi',
      id: 11,
    },
  },
  {
    state: {
      name: 'Edo',
      id: 12,
    },
  },
  {
    state: {
      name: 'Ekiti',
      id: 13,
    },
  },
  {
    state: {
      name: 'Enugu',
      id: 14,
    },
  },

  {
    state: {
      name: 'Gombe',
      id: 16,
    },
  },
  {
    state: {
      name: 'Imo ',
      id: 17,
    },
  },
  {
    state: {
      name: 'Jigawa',
      id: 18,
    },
  },
  {
    state: {
      name: 'Kaduna',
      id: 19,
    },
  },
  {
    state: {
      name: 'Kano',
      id: 20,
    },
  },
  {
    state: {
      name: 'Katsina',
      id: 21,
    },
  },
  {
    state: {
      name: 'Kebbi',
      id: 22,
    },
  },
  {
    state: {
      name: 'Kogi',
      id: 23,
    },
  },
  {
    state: {
      name: 'Kwara',
      id: 24,
    },
  },
  {
    state: {
      name: 'Lagos',
      id: 25,
    },
  },
  {
    state: {
      name: 'Nasarawa',
      id: 26,
    },
  },
  {
    state: {
      name: 'Niger',
      id: 27,
    },
  },
  {
    state: {
      name: 'Ogun',
      id: 28,
    },
  },
  {
    state: {
      name: 'Ondo',
      id: 29,
    },
  },
  {
    state: {
      name: 'Osun',
      id: 30,
    },
  },
  {
    state: {
      name: 'Oyo',
      id: 31,
    },
  },
  {
    state: {
      name: 'Plateau',
      id: 32,
    },
  },
  {
    state: {
      name: 'Rivers',
      id: 33,
    },
  },
  {
    state: {
      name: 'Sokoto',
      id: 34,
    },
  },
  {
    state: {
      name: 'Taraba',
      id: 35,
    },
  },
  {
    state: {
      name: 'Yobe',
      id: 36,
    },
  },
  {
    state: {
      name: 'Zamfara',
      id: 37,
    },
  },
];
class SalesLogs extends Component {
  static propTypes = {
    getUser: PropTypes.func.isRequired,
    // eslint-disable-next-line react/require-default-props
    firstName: PropTypes.string,
    // firstName: PropTypes.string.isRequired,
    getEmployeesInSales: PropTypes.func.isRequired,
    // getCallLogsByParams: PropTypes.func.isRequired,
    getDepartments: PropTypes.func.isRequired,
    // getCallLogsByStaff: PropTypes.func.isRequired,
    // getSalesCallLogs: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      keyword: '',
      modalShow: false,
      params: '',
      fromParams: '',
      toParams: '',
      pageCount: '',
      show: '',
      status: '',
      fromStatus: '',
      toStatus: '',
      fromStaff: '',
      toStaff: '',
      staff: '',
      state: '',
    };

    this.handlePageClick = this.handlePageClick.bind(this);
  }

  componentDidMount() {
    this.checkDepartment();
    this.props.getUser();
    this.props.getEmployeesInSales();
    this.props.getDepartments();
    this.props.getLoggedInUser();
    this.props.getLogs();
    this.props.getCountries();
    this.props.getStates();
  }

  componentWillReceiveProps() {
    this.checkDepartment();
  }

  getModalStatus(i) {
    this.setState(
      {
        modalShow: !i,
      },
      () => {
        if (this.state.modalShow === true) {
          this.setState({
            modalShow: !this.state.modalShow,
          });
        }
      },
    );
  }

  handlePageClick = async (log) => {
    const selected = log.selected + 1;

    this.props.getPaginatedCallLogs(selected);
  };

  checkDepartment = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.department_id !== `${SALES}` && response.data.department_id !== `${SUPER_ADMIN}` && response.data.department_id !== `${VICE_PRESIDENT_SALES}`) {
        this.props.history.push('/');

        sessionStorage.setItem('token', '');
        sessionStorage.clear();

        toastr.info('You are not allowed to view this page');
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  // search with email, phone number and last name
  searchWithStatus = (e) => {
    e.preventDefault();

    const { status } = this.state;

    const data = {
      status,
    };

    this.props.searchLog(data);
  };

  searchForLogByStaff = (e) => {
    if (e) {
      const data = {
        employee_auth_id: e.target.value,
      };

      this.props.searchLog(data);
    }
  };

  searchByParams = (e) => {
    e.preventDefault();
    const { params, keyword } = this.state;
    switch (keyword) {
      case 'email':
        this.props.searchLog({
          email: params.trim(),
        });
        break;
      case 'lastName':
        this.props.searchLog({
          lastName: params,
        });
        break;
      case 'firstName':
        this.props.searchLog({
          firstName: params,
        });
        break;
      case 'phoneNumber':
        this.props.searchLog({
          phone: params,
        });
        break;
      case 'state':
        this.props.searchLog({
          state: params,
        });
        break;
      case 'country':
        this.props.searchLog({
          country: params,
        });
        break;
      case 'city':
        this.props.searchLog({
          city: params,
        });
        break;
      default:
        return null;
    }
  };

  searchWithDate = (e) => {
    e.preventDefault();
    const { fromParams, toParams } = this.state;
    this.props.searchLog({ from: fromParams, to: toParams });
  };

  onPlaceSearchChange = (e) => {
    if (e.target.value) {
      this.props.getCities(e.target.value);
    }
  };

  citiesDropdown = () => {
    return (
      <select name="params" onChange={this.onChange} className="form-control">
        <option>Select City</option>
        {this.props.cities.map((city) => (
          <option value={city.city}>{city.city}</option>
        ))}
      </select>
    );
  };

  displayCities = () => {
    if (this.props.gettingCities) {
      return <p className="fetching-cities">Fetching Cities</p>;
    }
    return this.citiesDropdown();
  };

  showSearchDisplay = () => {
    const { params, keyword } = this.state;
    switch (keyword) {
      case 'country':
        return (
          <div style={{ width: '50%' }} className="input-group mb-3 mr-3">
            <select name="params" onChange={this.onChange} className="form-control">
              <option>Select Country</option>
              {/* {this.props.countries
                .filter((item, index, i) => i.findIndex((t) => t.country === item.country) === index)
                .map((country) => ( */}
              <option value="ng"> Nigeria</option>
              <option value="gh"> Ghana</option>
              <option value="cv"> Cote d'Ivoire </option>
              {/* ))} */}
            </select>
          </div>
        );
      case 'state':
        return (
          <div style={{ width: '50%' }} className="input-group mb-3 mr-3">
            <select name="params" onChange={this.onChange} className="form-control">
              <option>Select State</option>
              {statesArray.map((state) => (
                <option value={state.state.name}>{state.state.name}</option>
              ))}
            </select>
          </div>
        );
      case 'city':
        return (
          <div style={{ width: '50%' }} className="input-group mb-3 mr-3">
            <select name="state" onChange={this.onPlaceSearchChange} className="form-control">
              <option>Select State</option>
              {this.props.countries.map((state) => (
                <option value={state.state}>{state.state}</option>
              ))}

             
            </select>
            {this.displayCities()}
          </div>
        );
      default:
        return (
          <div style={{ width: '50%' }} className="input-group mb-3 mr-3">
            <input
              className="form-control mr-auto empty pl-5"
              type="text"
              placeholder="Search"
              name="params"
              value={params}
              onChange={this.onChange}
            />
            <span className="fa fa-search" />
          </div>
        );
    }
  };

  render() {
    const { user, load, salesLogs, sales, role } = this.props;

    const { role_id } = role;
    const { pages, users } = salesLogs;
    const { fromParams, toParams, status } = this.state;
    const { firstName } = user;

    return (
      <div className="col-12 after-summary">
        <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-5">
          <div style={{ display: 'flex', justifyContent: 'space-between' }} className="text-right">
            <span className="mt-2">
              <CustomCsvDownload
                data={users || []}
                headers={salesLogHeader}
                filename={filename(`${status} Logs`)}
                classname="btn btn-green shadow-md"
                textcolor="white"
                text="Download Logs"
              />
            </span>
            {/* <button
                  className="btn btn-green mb-2"
                  type="submit"
                  onClick={() => {
                    this.setState(
                      {
                        modalShow: !this.state.modalShow,
                      },
                      () => {},
                    );
                  }}
                >
                  Add log
                </button> */}
          </div>
          <div className={!this.state.modalShow ? 'nodisp' : ' modal-backdrop fade show'}> </div>
          <div
            id="add-employee"
            className={this.state.modalShow ? 'modal fade in show d-block' : 'nodisp'}
            data-backdrop="false"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title text-center" id="myModalLabel">
                    Add Log
                  </h4>
                  <button
                    type="button"
                    className="close"
                    onClick={() => {
                      // if(this.state.modalShow === false) {

                      this.setState(
                        {
                          modalShow: !this.state.modalShow,
                        },
                        () => {},
                      );
                      // }
                    }}
                  >
                    ×
                  </button>
                </div>

                <AddCallLog modalshow={this.getModalStatus.bind(this)} />
              </div>
              {/* modal-content */}
            </div>
            {/* modal-dialog */}
          </div>
          <div className="col-lg-12">
            <form onSubmit={this.searchByParams} className="form-inline inner-addon d-flex">
              <div className="input-group mb-3 mr-3">
                <select onChange={this.onChange} name="keyword" className="form-control mr-auto empty mt-1">
                  <option value="">Choose fields to search from</option>
                  {searchFieldOptions.map((option) => (
                    <option value={option.value}>{option.name}</option>
                  ))}
                </select>
              </div>
              {this.showSearchDisplay()}
              <div className="mb-3">
                <button className="btn btn-green" style={{ height: '39px' }}>
                  Search
                </button>
              </div>
            </form>
          </div>
          <div className="d-none d-md-flex">
            <form onSubmit={this.searchWithStatus} className="form-inline inner-addon left-addon d-flex">
              <p className="fliter copy-font d-flex">
                <small className="mt-2 mr-2">Filter By:</small>
                <span>
                  <select className="form-control" name="status" onChange={this.onChange} required>
                    <option value="">Select Status</option>
                    <option value="log">Log</option>
                    <option value="paid">Paid</option>
                    <option value="expecting_payment">Expecting Payment</option>
                    <option value="revert_back">Revert</option>
                    <option value="not_interested">Not Interested</option>
                    <option value="no_coverage">No Coverage</option>
                    <option value="month_end">Month End</option>
                  </select>
                </span>
              </p>

              <button className="btn btn-green ml-3 w-25">Search</button>
            </form>
          </div>
        </div>
        <div className="col-7 d-none d-md-flex">
          <p className="fil d-flex mt-3 mr-5 copy-font">
            <small className="mr-3">From:</small>
            <CustomInput
              placeholder=""
              readOnly={false}
              type="date"
              classname="form-control"
              name="fromParams"
              value={fromParams}
              onChange={this.onChange}
            />
            <small className="mr-3 ml-3">To:</small>
            <CustomInput
              placeholder=""
              readOnly={false}
              type="date"
              classname="form-control"
              name="toParams"
              value={toParams}
              onChange={this.onChange}
            />
          </p>
          <div>
            <button onClick={this.searchWithDate} type="button" className="btn btn-green mt-3">
              Search
            </button>
          </div>
        </div>

        <div className="col-lg-12 col-md-9 col-sm-12 d-flex">
          {Number(role_id) > 2 ? (
            <form className="form-inline inner-addon left-addon d-flex" onSubmit={this.searchByStaff}>
              <p className="fliter copy-font d-flex">
                <small className="mt-2 mr-2">Filter By:</small>
                <span>
                  <select className="form-control" name="staff" onChange={this.searchForLogByStaff}>
                    <option>Sales Staff Name</option>
                    {sales.map((staff) => (
                      <option key={staff.employee_auth_id} value={staff.employee_auth_id}>
                        {staff.firstName} {staff.lastName}
                      </option>
                    ))}
                  </select>
                </span>
              </p>
            </form>
          ) : null}
        </div>

        <div className="card x-panel logs">
          <div className="table-responsive content">
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th nowrap="no-wrap">First Name</th>
                  <th nowrap="no-wrap">Last Name</th>
                  <th>Phone</th>
                  <th nowrap="no-wrap">Email address</th>
                  <th>Address</th>
                  <th>State</th>
                  <th>Date</th>
                  {/* <th>Entered By</th> */}
                  {/* <th>Assign to</th> */}
                  <th>Status</th>
                  {/* <th>Schedule</th> */}
                  {/* <th nowrap="no-wrap">Convert to Lead</th> */}
                  <th style={{ paddingLeft: '5px' }}>Actions</th>
                </tr>
              </thead>
              <SalesLogsTable load={load} logs={users} />
            </table>
          </div>
        </div>

        {/* <AdminPaginate/> */}
        {pages > 1 ? (
          <ReactPaginate
            previousLabel="previous"
            nextLabel="next"
            breakLabel="..."
            breakClassName="break-me"
            pageCount={pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick}
            containerClassName="pagination"
            subContainerClassName="pages pagination"
            activeClassName="active"
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  salesLogs: state.salesLogs.logs,
  load: state.salesLogs.get,
  user: state.user.user,
  sales: state.employeesByDepartment.employeesByDepartment,
  departments: state.departments.departments,
  role: state.auth.auth,
  countries: state.salesLogs.countries,
  cities: state.salesLogs.cities,
  gettingCities: state.salesLogs.gettingCities,
  states: state.salesLogs.states,
});

export default connect(mapStateToProps, {
  getUser,
  getLoggedInUser,
  getEmployeesInSales,
  getLogsByParams,
  getDepartments,
  getPaginatedCallLogs,
  getLogs,
  searchLog,
  getCountries,
  getCities,
  getStates,
})(SalesLogs);
