/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useCustomerDetails from '../../../../hooks/customer-support/customer-details/useCustomerDetails';

const NewDesignKycUpload = ({ request, request: { item } }) => {
  const { isLoading, KycUpload } = useCustomerDetails();

  const [one, setOne] = useState({
    userid: '',
  });
  const { userid } = one;

  useEffect(() => {
    const result = {
      userid: item.installationId,
    };
    setOne({ ...result });
  }, [request]);

  console.log(item.installationId);
  const [doc, setDoc] = useState({ file: '', show: '' }); // customer  picture
  const [pole, setPole] = useState({ file: '', showpole: '' }); // pole image
  const [kyc, setKyc] = useState({ file: '', showkyc: '' }); // kyc image
  const [radio, setRadio] = useState({ file: '', showradio: '' }); // radio image

  const onFileChange = (e) => {
    const { files } = e.target;
    const filesArray = Array.from(e.target.files);
    if (files.length > 1) {
      setDoc({
        file: files,
        show: filesArray.map((filer) => filer.name),
      });
    } else {
      setDoc({
        file: files,
        show: files[0].name,
      });
    }
  };

  const onPoleChange = (e) => {
    const { files } = e.target;
    const filesArray = Array.from(e.target.files);
    if (files.length > 1) {
      setPole({
        file: files,
        showpole: filesArray.map((filer) => filer.name),
      });
    } else {
      setPole({
        file: files,
        showpole: files[0].name,
      });
    }
  };

  const onKycChange = (e) => {
    const { files } = e.target;
    const filesArray = Array.from(e.target.files);
    if (files.length > 1) {
      setKyc({
        file: files,
        showkyc: filesArray.map((filer) => filer.name),
      });
    } else {
      setKyc({
        file: files,
        showkyc: files[0].name,
      });
    }
  };

  const onRadioChange = (e) => {
    const { files } = e.target;
    const filesArray = Array.from(e.target.files);
    if (files.length > 1) {
      setRadio({
        file: files,
        showradio: filesArray.map((filer) => filer.name),
      });
    } else {
      setRadio({
        file: files,
        showradio: files[0].name,
      });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    for (let x = 0; x < doc.file.length && pole.file.length && radio.file.length && kyc.file.length; x++) {
      data.append('file', doc.file[x]);
      data.append('file', pole.file[x]);
      data.append('file', radio.file[x]);
      data.append('file', kyc.file[x]);
    }
    data.append('installation_id', userid);
    KycUpload(data);
  };

  const submitForm = () => {
    return doc.file.length > 0 && pole.file.length > 0 && kyc.file.length > 0 && radio.file.length > 0;
  };

  return (
    <div id="uploadKyc" className="modal fade" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header shadow">
            <h4 className="modal-title text-center" id="myModalLabel">
              Upload KYC
            </h4>
            <button type="button" className="close mr-2" data-dismiss="modal">
              x
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={onSubmit} className="form-horizontal form-material">
              <div className="form-group invent">
                <div className="col-12 mb-3 d-flex">
                  <div className="col-6">
                    <label className="copy-font m-0 p-0">Upload Customer Picture </label>
                    <input type="file" className="form-control fs-input" name="file" onChange={onFileChange} />
                    {doc.show ? <small>{doc.show}</small> : null}
                  </div>

                  <div className="col-6">
                    <label className="copy-font m-0 p-0">Passport for KYC </label>
                    <input type="file" className="form-control fs-input" name="file" onChange={onKycChange} />
                    {kyc.showkyc ? <small>{kyc.showkyc}</small> : null}
                  </div>
                </div>

                <div className="col-12 mb-3 d-flex">
                  <div className="col-6">
                    <label className="copy-font m-0 p-0">Pole Image</label>
                    <input type="file" className="form-control fs-input" name="file" onChange={onPoleChange} />
                    {pole.showpole ? <small>{pole.showpole}</small> : null}
                  </div>

                  <div className="col-6">
                    <label className="copy-font m-0 p-0">Radio Screenshot</label>
                    <input type="file" className="form-control fs-input" name="file" onChange={onRadioChange} />
                    {radio.showradio ? <small>{radio.showradio}</small> : null}
                  </div>
                </div>

                <div className="text-center">
                  {isLoading ? (
                    <button type="button" className="btn btn-green py-2 px-5" disabled>
                      Submitting...
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-green py-2 px-5" disabled={!submitForm()}>
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

NewDesignKycUpload.propTypes = {
  request: PropTypes.objectOf.isRequired,
};

export default NewDesignKycUpload;
