/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useState } from 'react';
import { NoItemRow } from '../../../../../../utils/loader/loadingHelper';
import AppContext from '../../../../../../context/shared/AppContext';
import helpers from '../../../../../../utils/helpers';
import editIcon from '../../../../../../assets/img/icons/edit.png';
import ExtendLteCustomer from './ExtendLteCustomer';

const LteCustomersTableBody = () => {
  const { data, onOpenExtendModal } = useContext(AppContext);
  const [customer, setCustomer] = useState({});
  const onClick = (param) => {
    setCustomer(param);
  };
  return data && data.user ? (
    <tbody>
      <tr onClick={() => onClick(data)}>
        <td>{data.id}</td>
        <td>{data.imsi}</td>
        <td>{data.expired_at}</td>
        <td>{data.no_of_months}</td>
        <td>{data.status}</td>
        <td>{helpers.formatDateAndTime(data.created_at)}</td>
        <td className="text-nowrap">{`${data.user.firstName} ${data.user.lastName}`}</td>
        <td className="text-nowrap">{data.user.address}</td>
        <td>{data.user.email}</td>
        <td>{data.user.phoneNumber}</td>
        <td>{data.user.plan}</td>
        <td>
          <div className="text-center">
            <span className="view" onClick={onOpenExtendModal}>
              <img src={editIcon} alt="edit" title="extend customers" />
            </span>
            <ExtendLteCustomer customer={customer} />
          </div>
        </td>
      </tr>
    </tbody>
  ) : (
    <NoItemRow colSpan={5} text="" />
  );
};

export default LteCustomersTableBody;
