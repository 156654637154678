import axios from 'axios';
import { toast } from 'react-toastify';
import { GET_ROLES } from '../../../constants/types/SignUpTypes';
import { GET_ROLES_URL, SIGN_UP_USER_URL } from '../../../services/api/auth/AuthUrl';
import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';

export const getRoles = () => async (dispatch) => {
  try {
    const response = await axios.get(`${GET_ROLES_URL}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    // console.log(response.data);

    if (response.data.message === 'success') {
      dispatch({
        type: GET_ROLES,
        payload: response.data.data,
      });
    }
  } catch (e) {
    toast.info('Something went wrong while fetching the roles. Please, try again.');
  }
};

export const signUp = (data, history) => async (dispatch) => {
  dispatch({
    type: LOADING_STARTS,
    payload: true,
  });

  try {
    const response = await axios.post(`${SIGN_UP_USER_URL}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data.id) {
      dispatch({
        type: LOADING_STOPS,
        payload: false,
      });

      toast.success('Registration successful. Please wait for your account to be verified.');

      history.push('/');
    }
  } catch (e) {
    if (e.response) {
      if (e.response.data.status === 'failed') {
        toast.error(e.response.data.message);
        dispatch({
          type: LOADING_STOPS,
          payload: false,
        });
      }

      if (e.response.data.message.errors) {
        toast.error(e.response.data.message.errors[0].message);
        dispatch({
          type: LOADING_STOPS,
          payload: false,
        });
      }

      // data:
      //   message:
      //     errors
    }
  }
};
