import React from 'react';
import CustomButton from '../../../../forms/CustomButton';

const ExecutiveButtons = ({
  approving,
  giveExecutiveApproval,
  giveExecutiveDisApproval,
  disapproving,
  cLevelApproval,
}) => {
  const showButtons = () => {
    switch (cLevelApproval) {
      case 'yes':
        return (
          <CustomButton
            classname="btn btn-green btn-size"
            name={disapproving ? 'Disapproving...' : 'Disapprove'}
            disabled={disapproving}
            onClick={giveExecutiveDisApproval}
          />
        );

      case 'no':
        return (
          <CustomButton
            classname="btn btn-green btn-size"
            name={approving ? 'Approving...' : 'Approve'}
            disabled={approving}
            onClick={giveExecutiveApproval}
          />
        );

      default:
        return (
          <div className="submit-buttons">
            <CustomButton
              classname="btn btn-green"
              name={approving ? 'Approving...' : 'Approve'}
              disabled={approving}
              onClick={giveExecutiveApproval}
            />
            <CustomButton
              classname="btn btn-green"
              name={disapproving ? 'Disapproving...' : 'Disapprove'}
              disabled={disapproving}
              onClick={giveExecutiveDisApproval}
            />
          </div>
        );
    }
  };
  return <div className="col-12 mt-5">{showButtons()}</div>;
};

export default ExecutiveButtons;
