/* eslint-disable react/require-default-props */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const AccountsBarItem = ({ url, name, image }) => {
  return (
    <li className="s-sidebar__nav-link">
      <Link to={url} target="_self">
        <span className="mr-2">
          <img src={image} alt="" />
        </span>
        {name}
      </Link>
    </li>
  );
};
AccountsBarItem.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string,
  image: PropTypes.string,
};
export default AccountsBarItem;
