/* eslint-disable import/prefer-default-export */
import { useState } from 'react';
import qs from 'qs';
import axios from 'axios';
import toastr from 'toastr';
import {
  EDIT_BADNWIDTH_URL,
  GET_BADNWIDTH_DROPDOWN_URL,
  GET_BADNWIDTH_URL,
  GET_BADNWIDTH_SCHEDULE_URL,
  ADD_BANDNWIDTH_SCHEDULE_URL,
  DELETE_BANDNWIDTH_SCHEDULE_URL,
  DELETE_BANDNWIDTH_URL,
  ADD_BANDNWIDTH_CSV_URL,
} from '../../../services/api/partnership/bandwidth/BandwidthUrl';

export const useBandwidth = () => {
  const [lists, setLists] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [items, setItems] = useState([]);
  const [count, setCount] = useState(1);
  const [pages, setPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);

  const getBandwidth = async () => {
    setIsLoading(true);
    const config = {
      method: 'get',
      url: `${GET_BADNWIDTH_URL}`,
      //   headers: {
      //     Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      //   },
    };
    axios(config)
      .then((response) => {
        if (response.data) {
          setIsLoading(false);
          setLists(response.data);
        }
      })
      .catch(() => {
        toastr.info('Something went wrong!');
        setIsLoading(false);
      });
  };
  const deleteBandwidth = async (reload) => {
    setLoading(true);
    const config = {
      method: 'get',
      url: `${DELETE_BANDNWIDTH_URL}`,
      //   headers: {
      //     Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      //   },
    };
    axios(config)
      .then((response) => {
        if (response.data) {
          toastr.success('Successfully Deleted');
          reload();
          setLoading(false);
        }
      })
      .catch(() => {
        toastr.info('Something went wrong!');
        setLoading(false);
      });
  };

  const getBandwidthSchedule = async () => {
    try {
      setIsLoading(true);
      const config = {
        method: 'get',
        // url: `${GET_BADNWIDTH_URL}`,
        url: `${GET_BADNWIDTH_SCHEDULE_URL}`,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      };
      const response = await axios(config);

      if (response.status === 200) {
        setIsLoading(false);
        setSchedules(response.data);
      }
    } catch (error) {
      toastr.info('Something went wrong!');
    } finally {
      setIsLoading(false);
    }
  };

  const getBandwidthBasestations = async () => {
    setLoader(true);
    const config = {
      method: 'get',
      url: `${GET_BADNWIDTH_DROPDOWN_URL}`,
      //   headers: {
      //     Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      //   },
    };
    axios(config)
      .then((response) => {
        if (response.data) {
          setLoader(false);

          setItems(response.data);
        }
      })
      .catch(() => {
        toastr.info('Something went wrong!');
        setLoader(false);
      });
  };

  const addBandwidth = async (data, handleClose, reload, setOne) => {
    setLoad(true);

    const config = {
      method: 'post',
      url: `${EDIT_BADNWIDTH_URL}`,
      data: data,
      headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
    };

    axios(config)
      .then((response) => {
        if (response.data.status === true) {
          toastr.success('Logged Successful');
          setOne('');
          handleClose();
          reload();
        }
      })
      .catch((error) => {
        if (error) {
          toastr.info('Something went wrong!');
        }
      })
      .finally(() => {
        setLoad(false);
      });
  };

  const addBandwidthCsv = async (data, reload, setFile) => {
    setLoad(true);

    const config = {
      method: 'post',
      url: `${ADD_BANDNWIDTH_CSV_URL}`,
      data: data,
      headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
    };

    axios(config)
      .then((response) => {
        if (response.data.status === true) {
          toastr.success('Logged Successful');
          setFile('');
          // handleClose();
          reload();
        }
      })
      .catch((error) => {
        if (error) {
          toastr.info('Something went wrong!');
        }
      })
      .finally(() => {
        setLoad(false);
      });
  };

  const addBandwidthSchedule = async (data, setOne, setSchedule, handleClose) => {
    setLoad(true);
    try {
      const config = {
        method: 'post',
        url: `${ADD_BANDNWIDTH_SCHEDULE_URL}`,
        data: data,
        headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
      };

      const response = await axios(config);

      if (response.status === 200) {
        setLoad(false);
        setOne({
          base: '',
          ip_address: '',
          day: '',
          inst: '',
          time: '',
        });
        setSchedule({});
        handleClose();
        toastr.success('Schedule Created Successfully');

        await getBandwidthSchedule();
      }
    } catch (error) {
      setLoad(false);
      toastr.info('Something went wrong!');
    } finally {
      setLoad(false);
    }
  };

  const deleteBandwidthSchedule = async (data) => {
    setLoad(true);
    try {
      const config = {
        method: 'delete',
        url: `${DELETE_BANDNWIDTH_SCHEDULE_URL}`,
        data: data,
        headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
      };
      const response = await axios(config);

      if (response.status === 200) {
        toastr.success('Schedule Deleted Successfully');
        await getBandwidthSchedule();
      }
    } catch (error) {
      toastr.info('Something went wrong!');
    } finally {
      setLoad(false);
    }
  };

  const editBandwidth = async (data, handleClose, reload, index) => {
    setLoad(true);
    const config = {
      method: 'patch',
      url: `${EDIT_BADNWIDTH_URL}`,
      data: data,
      //   headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
    };

    await axios(config)
      .then((response) => {
        if (response.data.status === true) {
          setLoad(false);
          toastr.success('Update Successful');
          handleClose();
          reload();

          // lists[index] = {
          //   ...lists[index],
          //   rxspeed: data.download_speed,
          //   rxtotalaverage: data.download_total_average
          // }
        }
      })
      .catch((error) => {
        if (error) {
          toastr.info('Something went wrong!');
          setLoad(false);
        }
      });
  };

  return {
    lists,
    items,
    count,
    loader,
    load,
    isLoading,
    loading,
    schedules,
    getBandwidth,
    deleteBandwidth,
    addBandwidth,
    addBandwidthSchedule,
    deleteBandwidthSchedule,
    getBandwidthBasestations,
    addBandwidthCsv,
    editBandwidth,
    getBandwidthSchedule,
  };
};
