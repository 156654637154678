/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import Scheduling from './Scheduling';
import useCheckDepartment from '../../../../../hooks/shared/custom/check-department/field-support/useCheckDepartment';
import FieldSupportNavBar from '../../../../layouts/field-support/FieldSupportNavBar';

const FieldSupportSchedule = ({ history }) => {
  useCheckDepartment(history);
  const { profile } = useUser();
  const { firstName } = profile;

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <FieldSupportNavBar currentPage="Scheduling" position={firstName} />
        <Scheduling />
      </main>
    </div>
  );
};

FieldSupportSchedule.propTypes = {
  history: PropTypes.shape(),
};

export default FieldSupportSchedule;
