/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useProfile } from '../../../../hooks/shared/user/profile/useProfile';

import useTeams from '../../departments/field-support/team/hooks/useTeams';
import { useUser } from '../../../../hooks/shared/user/useUser';
import useFetch from '../../../../hooks/shared/custom/data/useFetch';
import moment from 'moment';

const ViewUserProfile = () => {
  const { userProfile, onChange, button, editProfile, addProfile, load, handleChange } = useProfile();
  let states = [];

  const { profile } = useUser();
  const { teams } = useTeams();
  const employee_id = profile.id;

  const { data, loading } = useFetch(`https://api.tizeti.com/api/v1/legacy/get_list_of_regions`);

  // data?.map((value, index)=>{
  //   if(country = "NG"){
  //     console.log(value)
  //   }else{
  //     console.log("empty")
  //   }
  // })

  const roleData = [
    {
      name: 'PERSONNEL',
      value: '1',
    },
    {
      name: 'LEAD',
      value: '2',
    },
    {
      name: 'MANAGER',
      value: '3',
    },
    {
      name: 'C00',
      value: '4',
    },
    {
      name: 'CEO',
      value: '5',
    },
    {
      name: 'INTERN',
      value: '6',
    },
    {
      name: 'CSO',
      value: '7',
    },
    {
      name: 'CTO',
      value: '8',
    },
    {
      name: 'CFO',
      value: '9',
    },
    {
      name: 'VP',
      value: '11',
    },
    {
      name: 'CIO',
      value: '12',
    },
  ];

  const {
    address,
    country,
    state,
    alternateEmail,
    officialEmail,
    alternatePhoneNumber,
    birthday,
    date_of_resumption,
    extension,
    firstName,
    lastName,
    gender,
    phoneNumber,
    beneficiary,
    referee,
    designation,
    guarantor,
    nationality,
    role,
  } = userProfile;

  const [gend, setGend] = useState('');

  useEffect(() => {
    setGend(gender);
  }, [gender]);

  const onSubmit = (e) => {
    e.preventDefault();

    // const {
    //   address,
    //   country,
    //   state,
    //   alternateEmail,
    //   alternatePhoneNumber,
    //   birthday,
    //   date_of_resumption,
    //   extension,
    //   firstName,
    //   officialEmail,
    //   lastName,
    //   phoneNumber,
    //   team_id,
    //   beneficiary,
    //   referee,
    //   designation,
    //   guarantor,
    //   nationality,
    // } = userProfile;

    e.preventDefault();

    if (button === 'Add Profile') {
      const data = {
        address,
        country,
        state,
        alternateEmail,
        alternatePhoneNumber,
        officialEmail,
        birthday,
        date_of_resumption,
        extension,
        firstName,
        gender: gend,
        lastName,
        phoneNumber,
        role_id: designation,
        department_id: `${sessionStorage.getItem('departmentId')}`,
        // role: this.state.role,
        nationality,
        beneficiary,
        referee,
        guarantor,
        role,
      };
      addProfile(data);
    } else {
      const data = {
        address,
        country,
        state,
        alternateEmail,
        alternatePhoneNumber,
        birthday,
        date_of_resumption,
        extension,
        officialEmail,
        firstName,
        gender: gend,
        lastName,
        phoneNumber,
        beneficiary,
        referee,
        role_id: designation,
        guarantor,
        nationality,
        role,
      };
      editProfile(data, employee_id);
    }
  };

  const civ = {
    mo: "Cote d'Ivoire",
  };

  if (country === 'NG') {
    states = data && data.filter((country) => country.country === 'Nigeria').map((state) => state.state);
  } else if (country === 'GH') {
    states = data && data.filter((country) => country.country === 'Ghana').map((state) => state.state);
  } else if (country === 'CIV') {
    states = data && data.filter((country) => country.country === civ.mo).map((state) => state.state);
  }

  const dateOfResumption = moment(userProfile.date_of_resumption).format('YYYY-MM-DD');

  const showButton = () => {
    if (load) {
      return (
        <button disabled={load} type="submit" className="btn btn-green waves-effect">
          {button === 'Add Profile' ? 'Adding Profile...' : 'Editing Profile...'}
        </button>
      );
    }
    return (
      <button type="submit" className="btn btn-green waves-effect">
        {button}
      </button>
    );
  };

  return (
    <div
      id="add-employee"
      className="modal fade in"
      // tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
      style={{ overflow: 'auto' }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Profile Details
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body">
            <form className="form-horizontal" onSubmit={onSubmit}>
              <div className="form-group viewprofile">
                <div className="col-md-12 mb-3 d-flex">
                  <div className="col-lg-6">
                    <label className="copy-font">First Name</label>
                    <input
                      type="text"
                      onChange={onChange}
                      name="firstName"
                      defaultValue={firstName}
                      required={true}
                      className="form-control"
                      placeholder="First Name"
                    />
                  </div>

                  <div className="col-lg-6">
                    <label className="copy-font">Last Name</label>
                    <input
                      type="text"
                      onChange={onChange}
                      name="lastName"
                      defaultValue={lastName}
                      required={true}
                      className="form-control"
                      placeholder="Last Name"
                    />
                  </div>
                </div>

                <div className="col-md-12 mb-3 d-flex">
                  <div className="col-lg-6">
                    <label className="copy-font">Gender</label>
                    <div className="d-flex items-center">
                      <div className="mt-1">
                        <input
                          className=""
                          type="radio"
                          value={gend === 'female'}
                          name="gender"
                          id="female"
                          onChange={() => setGend('female')}
                          onClick={() => setGend('female')}
                          checked={gend === 'female'}
                        />
                        <label className="copy-font ml-2 mr-2" style={{ fontSize: '14px' }} htmlFor="female">
                          Female
                        </label>
                      </div>

                      <div className="mt-1">
                        <input
                          className=""
                          type="radio"
                          value={gend === 'male'}
                          name="gender"
                          id="male"
                          onChange={() => setGend('male')}
                          onClick={() => setGend('male')}
                          checked={gend === 'male'}
                        />
                        <label className="copy-font ml-2 mr-2" style={{ fontSize: '14px' }} htmlFor="male">
                          Male
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <label className="copy-font">Address</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={onChange}
                      name="address"
                      defaultValue={address}
                      required={true}
                      placeholder="Permanent home address"
                    />
                  </div>
                </div>

                <div className="col-md-12 mb-3 d-flex">
                  <div className="col-lg-6">
                    <label className="copy-font">Alternate email address</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Alternate email address"
                      name="alternateEmail"
                      value={alternateEmail}
                      onChange={onChange}
                    />
                  </div>

                  <div className="col-lg-6">
                    <label className="copy-font">Country Assigned</label>
                    <select onChange={onChange} required={true} value={country} name="country" className="form-control">
                      <option value="">Select an option</option>
                      <option value="NG" selected={nationality === 'NG'}>
                        Nigeria{' '}
                      </option>
                      <option value="CIV" selected={nationality === 'CIV'}>
                        Côte d'Ivoire{' '}
                      </option>
                      <option value="GH" selected={nationality === 'GH'}>
                        Ghana{' '}
                      </option>
                    </select>
                  </div>
                </div>

                <div className="col-md-12 mb-3 d-flex">
                  <div className="col-lg-6">
                    <label className="copy-font">State Assigned</label>
                    <select onChange={onChange} name="state" className="form-control" value={state}>
                      <option value="" selected={state === ''}>
                        Choose State
                      </option>
                      {states !== undefined &&
                        states.map((state, index) => (
                          <option key={index} value={state}>
                            {state}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="col-lg-6">
                    <label className="copy-font">Nationality</label>
                    <select onChange={onChange} name="nationality" className="form-control" value={nationality}>
                      <option>Choose Nationality</option>

                      <option value="Nigerian" selected={nationality === 'NG'}>
                        Nigerian{' '}
                      </option>
                      <option value="Ivorian" selected={nationality === 'CIV'}>
                        Ivorian{' '}
                      </option>
                      <option value="Ghanian" selected={nationality === 'GH'}>
                        Ghanian{' '}
                      </option>
                    </select>
                  </div>
                </div>

                <div className="col-md-12 mb-3 d-flex">
                  <div className="col-lg-6">
                    <label className="copy-font">Official Email</label>

                    <input
                      type="text"
                      name="officialEmail"
                      onChange={onChange}
                      defaultValue={officialEmail}
                      className="form-control"
                      placeholder="email"
                    />
                  </div>
                  <div className="col-lg-6">
                    <label className="copy-font">Role</label>
                    <select onChange={onChange} name="role" value={role} className="form-control" required={true}>
                      <option value="" selected={state === ''}>
                        Choose Role
                      </option>
                      {roleData.map((state, index) => (
                        <option key={index} value={state.value}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-md-12 mb-3 d-flex">
                  <div className="col-lg-6">
                    <label className="copy-font">Phone number 1</label>
                    <input
                      type="text"
                      name="phoneNumber"
                      defaultValue={phoneNumber}
                      onChange={onChange}
                      maxLength={14}
                      className="form-control"
                      placeholder="Phone number 1"
                    />
                  </div>

                  <div className="col-lg-6">
                    <label className="copy-font">Phone number 2</label>
                    <input
                      type="text"
                      className="form-control"
                      name="alternatePhoneNumber"
                      defaultValue={alternatePhoneNumber}
                      onChange={onChange}
                      maxLength={14}
                      placeholder="Phone number 2"
                    />
                  </div>
                </div>

                <div className="col-md-12 mb-3 d-flex">
                  <div className="col-lg-6">
                    <label className="copy-font">Extension</label>
                    <input
                      type="text"
                      className="form-control"
                      name="extension"
                      defaultValue={extension}
                      onChange={onChange}
                      placeholder="Phone number extension"
                    />
                  </div>

                  <div className="col-lg-6">
                    <label className="copy-font">Beneficiary</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={onChange}
                      name="beneficiary"
                      defaultValue={beneficiary}
                      required={true}
                      placeholder="Beneficiary"
                    />
                  </div>
                </div>

                <div className="col-md-12 mb-3 d-flex">
                  <div className="col-lg-6">
                    <label className="copy-font">Referee</label>
                    <input
                      type="text"
                      className="form-control"
                      name="referee"
                      defaultValue={referee}
                      onChange={onChange}
                      required={true}
                      placeholder="Referee"
                    />
                  </div>

                  <div className="col-lg-6">
                    <label className="copy-font">Guarantor </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={onChange}
                      name="guarantor"
                      defaultValue={guarantor}
                      required={true}
                      placeholder="Guarantor"
                    />
                  </div>
                </div>

                <div className="col-md-12 mb-3 d-flex">
                  <div className="col-lg-6">
                    <label className="copy-font">Date of resumption</label>
                    <input
                      type="date"
                      className="form-control"
                      name="date_of_resumption"
                      required={true}
                      value={dateOfResumption}
                      onChange={onChange}
                    />
                  </div>

                  <div className="col-lg-6">
                    <label className="copy-font">Birthday</label>
                    <input
                      type="date"
                      className="form-control"
                      required={true}
                      name="birthday"
                      value={birthday}
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>
              {/* 
              <div className="col-md-12 mb-3 d-flex">
                <div className="col-lg-6">
                  <label className="copy-font">Team</label>
                  <select onChange={onChange} name="team_id" className="form-control">
                    <option>Choose Team</option>
                    {teams.map((team) => (
                      <option value={team.id}>{team.name}</option>
                    ))}
                  </select>
                </div>

              
              </div> */}

              <div className="text-center" style={{ marginTop: '2rem' }}>
                {' '}
                {showButton()}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewUserProfile;
