/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/button-has-type */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import DeleteSVG from '../../../../../assets/svg/delete-button.svg';
import helpers from '../../../../../utils/helpers';
import DeleteZoneModal from '../../../departments/field-support/zones/DeleteZoneModal';
import useInstallationZones from '../../../shared/installations/zones/hooks/useInstallationZones';
import NewDesignEditZone from './NewDesignEditZone';
import styles from '../../installations/NewDesign.module.css';

const NewDesignInstallationZoneAction = () => {
  const location = useLocation();
  const { id } = useParams();
  const { subZones, onShowModal } = useInstallationZones();

  // console.log(location.state.detail, 'line 19');

  let initialZoneDetailState = null;

  if (location.state) {
    initialZoneDetailState = {
      item: location.state.detail || null,
      sub_zones: location.state.detail.sub_zones || null,
      name: location.state.detail.name || null,
      id: location.state.detail.id || null,
      no_of_installations: location.state.detail.no_of_installations || null,
      created_at: location.state.detail.created_at || null,
    };
    localStorage.setItem(`ZoneAction-${id}`, JSON.stringify(initialZoneDetailState));
  } else {
    initialZoneDetailState = JSON.parse(localStorage.getItem(`ZoneAction-${id}`));
  }

  const [details, setDetails] = useState(initialZoneDetailState);

  // console.log(details.name, 'line 42');
  useEffect(() => {
    console.log(location, 'line 44'); // result: 'some_value'
  }, [location]);

  const getOneZone = (i) => {
    setDetails(i);
  };

  const getSubZone = (param) => {
    onShowModal();
    setDetails(param);
  };

  const showEditButton = (param) => {
    return (
      <>
        <div>
          <h1
            className="links"
            type="button"
            onClick={() => {
              getOneZone(param);
            }}
            data-target="#edit-zone"
            data-toggle="modal"
          >
            Edit Zone
          </h1>
          <NewDesignEditZone request={details} dept="FSE" />
        </div>
      </>
    );
  };

  return (
    <div className="container">
      <>
        <div className="row no-gutters">
          <div className={`${styles.action_title} col-12 col-sm-6 col-md-8`}>
            <h4>{details.name}</h4>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h6>Created . {details.created_at}</h6>
              {/* <h6> {helpers.formatDateAndTimes(details.date)}</h6> */}
            </div>
          </div>
          {/* 
          <div className=" action-button row">{showActivateButton()}</div> */}
        </div>
        <hr />
        <div className="row">
          <div className="col-md-6 mt-4">
            <h4> Zone Details</h4>
            <hr />
            <div className={`${styles.action_details} mt-3`}>
              <div className={styles.section}>
                <h6 className="text-nowrap">Date Created</h6>
                <h6 className="text-nowrap">Number of Installs per day</h6>
                <h6 className="text-nowrap">Number of Subzone(s)</h6>
                {showEditButton(details)}
              </div>

              <div className={styles.asides}>
                <h6 className="text-nowrap">{details.created_at || 'N/A'}</h6>
                <h6 className="text-nowrap">{details.no_of_installations || 'N/A'}</h6>
                <h6 className="text-nowrap">{details.sub_zones.length || 'N/A'}</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mt-4">
            <h4> Subzone Information</h4>
            <hr />
            <div className={`${styles.action_details} mt-3`}>
              <div className={styles.asides}>
                <h6 className="text-wrap">{details.sub_zones.map((sub) => sub.name).join(' , ' + ' ') || 'N/A'}</h6>
                <div className={`${styles.action_button} row`}>
                  {details.sub_zones.map((sub) => (
                    <div className="col-xs-4 pl-2 pt-2">
                      <button className={styles.drop_white} type="button">
                        {sub.name}
                        <img
                          // onClick={() => getSubZone(sub)}
                          src={DeleteSVG}
                          alt="delete-button"
                          width="14px"
                          className="img-pointer pl-1"
                        />
                      </button>
                      {/* <DeleteZoneModal zone={details} type="subzone" /> */}
                    </div>
                  ))}
                </div>

                {/* {(showUpdateUserButton(details))} */}
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default NewDesignInstallationZoneAction;
