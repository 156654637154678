/* eslint-disable react/button-has-type */
import React, { useContext } from 'react';
import StatisticsTable from './table/StatisticsTable';
import GeniatechContext from '../../../../../../context/GeniatechContext';
import StatisticTable from './table/statistic/StatisticTable';

const StatisticsBody = () => {
  const { searchStatus, reset } = useContext(GeniatechContext);
  const display = searchStatus ? (
    <div className="row mt-5">
      <div className="form-group ml-2">
        <button onClick={reset} className="btn btn-green">
          See All Statistics
        </button>
      </div>
      <StatisticTable />
    </div>
  ) : (
    <div className="row mt-5">
      <StatisticsTable />
    </div>
  );
  return (
    <div className="container">
      <div className="row mt-5">
        <div className="mt-4 col-lg-12 col-md-12 col-sm-12">
          <div className="container">{display}</div>
        </div>
      </div>
    </div>
  );
};

export default StatisticsBody;
