/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import { ToastContainer } from 'react-toastify';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { editUser } from '../../../../../actions/wificall/customers/CustomersActions';

class EditCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planId: '',
      first_name: '',
      last_name: '',
      email_address: '',
      phone: '',
    };
  }

  editPlan = (e) => {
    const { editUser, user } = this.props;
    e.preventDefault();
    const { planId } = this.state;
    const { id } = user;
    const data = {
      plan: planId,
    };
    editUser(data, id);
  };

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  editFirstName = (e) => {
    const { editUser, user } = this.props;
    e.preventDefault();
    const { first_name } = this.state;
    const { id, lastName, phoneNumber, email, user_sip } = user;
    const data = {
      firstName: first_name,
      email,
      workgroup: user_sip.workgroup,
      username: user_sip.username,
      lastName,
      phoneNumber,
    };
    editUser(data, id);
  };

  editLastName = (e) => {
    const { editUser, user } = this.props;
    e.preventDefault();
    const { last_name } = this.state;
    const { id, phoneNumber, firstName, email, user_sip } = user;
    const data = {
      lastName: last_name,

      firstName,
      email,
      workgroup: user_sip.workgroup,
      username: user_sip.username,
      phoneNumber,
    };
    editUser(data, id);
  };

  editEmail = (e) => {
    const { editUser, user } = this.props;
    e.preventDefault();
    const { email_address } = this.state;
    const { id, firstName, lastName, phoneNumber, user_sip } = user;
    const data = {
      email: email_address,

      firstName,

      workgroup: user_sip.workgroup,
      username: user_sip.username,
      lastName,
      phoneNumber,
    };
    editUser(data, id);
  };

  editPhone = (e) => {
    const { editUser, user } = this.props;
    e.preventDefault();
    const { phone } = this.state;
    const { id, firstName, email, lastName, user_sip } = user;
    const data = {
      phoneNumber: phone,

      firstName,
      email,
      workgroup: user_sip.workgroup,
      username: user_sip.username,
      lastName,
    };
    editUser(data, id);
  };

  editingFlag = () => {
    return (
      <button className="btn btn-second" disabled>
        Editing...
      </button>
    );
  };

  render() {
    const load = false;
    const { user, plans } = this.props;
    const { firstName, lastName, email, phoneNumber } = user;
    const { first_name, last_name, email_address, phone } = this.state;
    const selectPlan = plans.map((plan) => {
      return (
        <option key={plan.id} value={plan.id}>
          {plan.name}
        </option>
      );
    });
    return (
      <div
        id="editcustomer"
        className="modal fade in"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center" id="myModalLabel">
                Edit Lead Details
              </h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
            </div>
            <div className="modal-body">
              <form className="col-12" onSubmit={this.editFirstName}>
                <label className="copy-font p-0">First Name</label>
                <div className="d-flex">
                  <div className="m-1">
                    <input type="text" className="form-control" value={firstName} onChange={this.onChange} readOnly />
                  </div>
                  <div className="m-1">
                    <input
                      type="text"
                      className="form-control"
                      name="first_name"
                      value={first_name}
                      onChange={this.onChange}
                    />
                  </div>
                </div>
                <div className="text-center">
                  {load ? this.editingFlag() : <button className="btn btn-info">Edit First Name</button>}
                </div>
              </form>

              <form className="col-12" onSubmit={this.editLastName}>
                <label className="copy-font p-0">Last Name</label>
                <div className="d-flex">
                  <div className="m-1">
                    <input type="text" className="form-control" value={lastName} readOnly />
                  </div>
                  <div className="m-1">
                    <input
                      type="text"
                      className="form-control"
                      name="last_name"
                      value={last_name}
                      onChange={this.onChange}
                    />
                  </div>
                </div>
                <div className="text-center">
                  {load ? this.editingFlag() : <button className="btn btn-info">Edit Last Name</button>}
                </div>
              </form>

              <form className="col-12" onSubmit={this.editEmail}>
                <label className="copy-font p-0">Email</label>
                <div className="d-flex">
                  <div className="m-1">
                    <input type="text" className="form-control" value={email} readOnly />
                  </div>
                  <div className="m-1">
                    <input
                      type="text"
                      className="form-control"
                      name="email_address"
                      value={email_address}
                      onChange={this.onChange}
                    />
                  </div>
                </div>
                <div className="text-center">
                  {load ? this.editingFlag() : <button className="btn btn-info">Edit Email</button>}
                </div>
              </form>

              <form className="col-12" onSubmit={this.editPhone}>
                <label className="copy-font p-0">Phone</label>
                <div className="d-flex">
                  <div className="m-1">
                    <input type="text" className="form-control" value={phoneNumber} readOnly />
                  </div>
                  <div className="m-1">
                    <input type="text" className="form-control" name="phone" value={phone} onChange={this.onChange} />
                  </div>
                </div>
                <div className="text-center">
                  {load ? this.editingFlag() : <button className="btn btn-info">Edit Phone</button>}
                </div>
              </form>

              <form className="col-12" onSubmit={this.editPlan}>
                <label className="copy-font p-0">Plan</label>
                <div className="d-flex">
                  <select name="planId" onChange={this.onChange}>
                    <option value="">Select Plan</option>
                    {selectPlan}
                  </select>
                </div>
                <div className="text-center">
                  {load ? this.editingFlag() : <button className="btn btn-info">Edit Plan</button>}
                </div>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

export default connect(
  null,
  { editUser },
)(EditCustomer);
