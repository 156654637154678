import { WIFICALL_DIRECTORY_URL } from "../../../services/api/wificall/WificallUrl";
import axios from 'axios';
import { GET_WIFICALL_DIRECTORY_COUNT } from "../../../constants/types/wificall/WificallTypes";

export const getDirectoryCount = () => async dispatch => {

  const response = await axios.get(WIFICALL_DIRECTORY_URL, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
  });

  if(response.data){

    dispatch({

      type: GET_WIFICALL_DIRECTORY_COUNT,
      payload: response.data["Total Count"]

    })
  }
};