/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import Moment from 'react-moment';
import PropTypes from 'prop-types';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';

const LogsTable = ({ users, load }) => {
  const tableData =
    users.length && users ? (
      users.map((user, i) => {
        const { code1, code, call_status, created_at, phoneNumber } = user;
        return (
          <tr className="text-nowrap" key={i}>
            <td>{i + 1}</td>
            <td>{phoneNumber}</td>
            <td>{code1}</td>
            <td>{code}</td>
            <td>{call_status}</td>
            <td>
              <Moment format="DD-MM-YYYY">{created_at}</Moment>
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="6" className="text-center">
          There are no customers
        </td>
      </tr>
    );

  return (
    <div className="col-12 m-0 p-0 after-summary">
      <div className="row ml-3 mt-3">
        <div className="card x-panel m-0 p-0">
          <div className="table-responsive content col-12 m-0 p-0">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>S/N</th>
                  <th>Phone</th>
                  <th>Generated Code</th>
                  <th>User Code Input</th>
                  <th>Status</th>
                  <th>Created Date</th>
                </tr>
              </thead>
              <tbody>
                {load ? (
                  <tr>
                    <td colSpan="6" className="text-center">
                      <img src={loader} alt="loading gif" />
                    </td>
                  </tr>
                ) : (
                  tableData
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

LogsTable.propTypes = {
  load: PropTypes.bool.isRequired,
  users: PropTypes.arrayOf.isRequired,
};

export default LogsTable;
