import React from 'react';
import { ToastContainer } from 'react-toastify';
import { KycProvider } from '../../../../../context/lte/kyc/KycContext';
import LteTable from '../LteTable';

const LteKyc = () => {
  return (
    <div className="card x-panel" style={{ marginTop: '9%' }}>
      <KycProvider>
        <LteTable />
        <ToastContainer />
      </KycProvider>
    </div>
  );
};

export default LteKyc;
