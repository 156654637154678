/* eslint-disable react/prop-types */
import React from 'react';
import PurchaseOrderTable from './PurchaseOrderTable';
import loader from '../../../../assets/img/icons/TizetiLoaderColored.gif';

const ManagerPurchaseOrderBody = ({ tracker, isLoading, lists }) => {
  const role = 'manager';

  return (
    <div className="card x-panel p-0">
      <div className="table-responsive content col-12 px-0">
        <table className="table table-striped">
          <thead>
            <tr className="">
              <th>S/N</th>
              <th>Item</th>
              <th>Quantity</th>
              <th>Comment</th>
              <th>Requested By</th>
              {tracker ? (
                <>
                  <th>Manager Approval</th>
                  <th>Procurement Approval</th>
                  <th>CFO Approval</th>
                </>
              ) : null}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="10" className="text-center">
                  <img src={loader} alt="loading gif" />
                </td>
              </tr>
            ) : (
              <PurchaseOrderTable lists={lists} tracker={tracker} role={role} />
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManagerPurchaseOrderBody;
