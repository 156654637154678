/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { EXPRESS_WIFI, SUPER_ADMIN } from '../../../../../utils/departments/helpers';
import ExpressWifiNavBar from '../../../../layouts/expresswifi/ExpressWifiNavBar';
import useCheckTwoDepartments from '../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';
import { useUserProfile } from '../../../../../hooks/shared/user/useUserProfile';
import XWFCustomerBody from './XWFCustomersBody';

const ExpressWifiCustomers = ({ history }) => {
  useCheckTwoDepartments(`${EXPRESS_WIFI}`, `${SUPER_ADMIN}`, history);
  const { getUserProfile, profile } = useUserProfile();

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <ExpressWifiNavBar currentPage="Customers" position={profile.firstName} />
        <div className="container-fluid">
          <XWFCustomerBody />
        </div>
      </main>
    </div>
  );
};

export default ExpressWifiCustomers;
