import React, { Component } from 'react'
import Delete from '../../../../../assets/img/icons/delete.png';
import view from '../../../../../assets/img/icons/view.png';

export default class ApplicantTable extends Component {
  render() {
    return (
    <tbody>
        <tr>
            <td>1</td>
            <td>John</td>
            <td>Doe</td>
            <td>Accounts</td>
            <td>Account Personnel</td>
            <td>Intern</td>
            <td>May 27, 2019</td>
            <td><img src="https://res.cloudinary.com/teewhy/image/upload/v1555588840/View_COVER_L.png" alt="" style={{width:"12%", cursor:"pointer"}} className="ml-4"/></td>
            <td><img src="https://res.cloudinary.com/teewhy/image/upload/v1555327943/View_CV.png" alt="" style={{width:"12%", cursor:"pointer"}} className="ml-4"/></td>
            {/* <td>
                <div className="d-flex">
                            <button
                                    type="submit"
                                    data-toggle="modal"
                                    data-placement="right"
                                    data-target="#"
                                    style={{
                                        border: '0',
                                        backgroundColor: 'white',
                                    }}
                                >
                                <span className="view">
                                <img
                                    src={view}
                                    data-toggle="tooltip"
                                    data-placement="left"
                                    title="View" alt="view"
                                />
                                </span>

                                </button>
                                <button
                                    type="submit"
                                    data-toggle="modal"
                                    // data-target="#edit"
                                    style={{
                                        border: '0',
                                        backgroundColor: 'white',
                                    }}
                                >
                                    <span className="view">
                                    <img
                                        src="https://res.cloudinary.com/teewhy/image/upload/v1543579893/edit.png"
                                        data-toggle="tooltip"
                                        data-placement="top" alt="edit"
                                        title="Edit"
                                    />
                                    </span>
                                </button>

                        <button
                            type="submit"
                            data-toggle="modal"
                            // data-target="#cancel"
                            style={{ border:'0',
                                backgroundColor: 'white'}}
                        >
                            <span className="view"><img
                                src={Delete}
                                data-toggle="tooltip"
                                data-placement="right"
                                title="Delete"
                            /></span></button>

                    </div>
                </td> */}
            </tr>
    </tbody>
    )
  }
}
