/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { stringify } from 'qs';
import CustomButton from '../forms/CustomButton';

export const commentOptions = [
  { name: 'Bad Customer Service' },
  { name: 'Bad Device' },
  { name: 'Busied calls' },
  { name: 'Call Rescheduled' },
  { name: 'Closed' },
  { name: 'Delay Visit' },
  { name: 'Delayed response' },
  { name: 'Escalated Issues' },
  { name: 'Frequent Downtime' },
  { name: 'No / Delayed response' },
  { name: 'No compensation' },
  { name: 'No follow - up' },
  { name: 'No Issue' },
  { name: 'No LOS' },
  { name: 'No Record' },
  { name: 'Not Interested' },
  { name: 'Not Reachable / Responding' },
  { name: 'Relocated' },
  { name: 'Scheduled' },
  { name: 'Slow Internet' },
  { name: 'To Delete' },
  { name: 'Too expensive' },
  { name: 'Traveled' },
  { name: 'Wrong Number' },
];
const AddComment = ({ profile, show, onClose, comment: param, onSubmit, onChange, customer, addingComment }) => {
  const { comment, details } = param;
  const submitForm = () => {
    return comment.length > 0 && details.length > 0;
  };
  const displayButton = () => {
    if (addingComment) {
      return <CustomButton classname="btn btn-green" disabled={addingComment} name="Adding Comment" />;
    }
    return <CustomButton classname="btn btn-green" disabled={!submitForm()} name="Add Comment" />;
  };
  const onSubmitForm = (e) => {
    e.preventDefault();

    onSubmit(
      stringify({
        action: 'add_retention_comment',
        customer_number: customer.Customer_Number,
        staff_email: profile.email,
        comment,
        details,
      }),
    );
  };
  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ paddingLeft: '2rem' }}>Add Comment</Modal.Title>
      </Modal.Header>

      <form className="form-horizontal" onSubmit={onSubmitForm}>
        <div className="form-group">
          <div className="col-12 mb-3">
            <div className="col-12">
              <select name="comment" onChange={onChange} className="form-control border border-info py-2">
                <option value="">--Select Comment--</option>
                {commentOptions.map((opt) => (
                  <option value={opt.name}>{opt.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-12 mb-3">
            <div className="col-12">
              <textarea
                placeholder="Details"
                className="form-control shadow-sm borderRadius0"
                name="details"
                value={details}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="col-12 mb-3 text-center">{displayButton()}</div>
        </div>
      </form>
    </Modal>
  );
};
export default AddComment;
