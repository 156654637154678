import {
  FETCHED_CUSTOMER_PAYMENTS,
  START_FETCHING_CUSTOMER_PAYMENTS,
  STOP_FETCHING_CUSTOMER_PAYMENTS,
} from '../../../../constants/types/shared/customers/payments/PaymentsTypes';

const initialState = {
  payments: [],
  load: false,
};

export const PaymentsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case START_FETCHING_CUSTOMER_PAYMENTS:
      return {
        ...state,
        load: payload,
      };

    case STOP_FETCHING_CUSTOMER_PAYMENTS:
      return {
        ...state,
        load: payload,
      };

    case FETCHED_CUSTOMER_PAYMENTS:
      return {
        ...state,
        payments: payload,
        load: false,
      };

    default:
      return state;
  }
};

export default PaymentsReducer;
