import { useReducer, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import qs from 'qs';
import formReducer from '../../../../../reducers/shared/custom/formReducer';
import { LOADING_STARTS, LOADING_STOPS } from '../../../../../constants/types/shared/LoadingTypes';

const useSearch = (url, param, success, error) => {
  const [{ loadingForm }, dispatch] = useReducer(formReducer, {
    loadingForm: false,
  });
  const [formValue, setFormValues] = useState(param);
  const [data, setData] = useState([]);
  const onChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValue, [name]: value });
  };
  const onSubmit = async () => {
    try {
      dispatch({ type: LOADING_STARTS });
      const response = await axios.post(url, qs.stringify(formValue));

      if (response.data) {
        // setFormValues(param);
        setData(response.data);
        dispatch({ type: LOADING_STOPS });
        toast.success(success);
      } else {
        toast.info(error);
        dispatch({ type: LOADING_STOPS });
      }
    } catch (e) {
      dispatch({ type: LOADING_STOPS });
      toast.info('Something went wrong!');
    }
  };
  return { loadingForm, onChange, formValue, onSubmit, data };
};

export default useSearch;
