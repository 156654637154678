import { useState, useEffect, useReducer } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import qs from 'qs';
import * as load from '../../../constants/types/shared/LoadingTypes';
import * as types from '../../../constants/types/procurement/ProcurementTypes';
import { releaseReducer } from '../../../reducers/procurement/release-report/releaseReducer';
import { GET_PROCUREMENT_RELEASE_URL } from '../../../services/api/procurement/release-report/ReleaseReportURL';

export const useReleaseReport = () => {
  const [{ list, count, pages, isLoading }, dispatch] = useReducer(releaseReducer, {
    list: [],
    count: 0,
    pages: 1,
    isLoading: false,
  });

  const itemState = {
    quantity: '',
    destination: '',
    tag: '',
  };

  const selectState = {
    requestedDepartment: '',
    staffReleasedTo: '',
    staffApproved: '',
    tracklist: '',
  };

  const [releaseItem, setReleaseItem] = useState(itemState);
  const [selectedOption, setSelectedOption] = useState(selectState);
  const [addingReport, setAddingReport] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setReleaseItem({ ...releaseItem, [name]: value });
  };

  const handleSelect = (selected, action) => setSelectedOption({ ...selectedOption, [action.name]: selected });

  const getReleaseReport = async () => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(
        `${GET_PROCUREMENT_RELEASE_URL}/1`,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );
      if (response.data) {
        dispatch({
          type: types.GET_PROCUREMENT_RELEASE,
          payload: {
            list: response.data.list,
            count: response.data.count,
            pages: response.data.pages,
          },
        });
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const addReleaseItem = async (data) => {
    // dispatch({ type: load.LOADING_STARTS });
    setAddingReport(true);
    try {
      const response = await axios.post(`${GET_PROCUREMENT_RELEASE_URL}`, qs.stringify(data), {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        toastr.success('Item Added!');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (e) {
      toastr.warning(e.response.data.message);
      setAddingReport(false);

      // dispatch({ type: load.LOADING_STOPS });
    }
  };

  const filterReleaseItemByDate = async (data, page) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(`${GET_PROCUREMENT_RELEASE_URL}/${page}`, qs.stringify(data), {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_PROCUREMENT_RELEASE,
          payload: {
            list: response.data.list,
            count: response.data.count,
            pages: response.data.pages,
          },
        });
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  useEffect(() => {
    getReleaseReport();
  }, []);

  return {
    list,
    count,
    pages,
    isLoading,
    handleChange,
    releaseItem,
    addReleaseItem,
    filterReleaseItemByDate,
    selectedOption,
    handleSelect,
    addingReport,
  };
};

export default useReleaseReport;
