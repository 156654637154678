/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import AdminSideBar from '../../../../layouts/admin-department/AdminDepartmentSideBar';
import AdminDeptLogo from '../../../../layouts/admin-department/AdminDeptLogo';
import Logout from '../../../shared/auth/Logout';
import NewPosting from '../../../shared/recruitment/admin/NewPosting';

const AllApplications = () => {
  return (
    <div className="main-wrapper">
      <header className="topbar shade bg-white fixed-top">
        <nav className="navbar navbar-expand-lg navbar-light top-navbar fixed-top">
          <AdminDeptLogo />
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse mt-3" id="navbarText">
            <ul className="navbar-nav">
              <li className="nav-item active">
                <Link className="nav-link" to="#">
                  <h3>
                    All Applications<span className="sr-only">(current)</span>
                  </h3>
                </Link>
              </li>
            </ul>
            <span className="navbar-text">
              <h6>
                <i>User</i>
                <Logout />
              </h6>
            </span>
          </div>
        </nav>
      </header>

      <div className="all-sides col-lg-12 col-md-12 col-sm-12">
        <AdminSideBar />
        <div className="page-wrapper col-lg-10 col-md-9 col-sm-9">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 mt-4">
                <div className="col-6 d-flex">
                  <div className="tab-stuff col-10 p-0">
                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                      <li className="nav-item mr-auto">
                        <a
                          className="nav-link active"
                          id="pills-home-tab"
                          data-toggle="pill"
                          href="#pills-home"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                        >
                          Job Postings
                        </a>
                      </li>
                      <li className="nav-item mr-auto">
                        <a
                          className="nav-link"
                          id="pills-profile-tab"
                          data-toggle="pill"
                          href="#pills-profile"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="false"
                        >
                          Applications
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="tab-content col-12" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    {/* <form className="form-horizontal"> */}
                    <div className="form-group mt-4">
                      <div className="col-12 d-flex p-0">
                        <small>Filter By:</small>
                        <div className="d-flex">
                          <span className="ml-2 mr-3">
                            <select
                              className="form-control"
                              // onChange={(e) => this.getJobsByDate(e.target.value)}
                              name="department"
                            >
                              <option value="">All Departments</option>
                            </select>
                          </span>

                          {/* <span className="mr-3"> */}
                          {/* <input type="date" className="form-control"></input> */}
                          {/* </span> */}

                          {/* <button className="btn btn-green"> */}
                          {/* Submit */}
                          {/* </button> */}
                        </div>

                        <div>
                          <button className="btn btn-green" data-target="#Newposting" data-toggle="modal">
                            New Posting
                          </button>

                          <NewPosting />
                        </div>
                      </div>
                    </div>
                    {/* </form> */}

                    <div className="col-lg-12 p-0">
                      <div className="card x-panel w-100 ml-0 p-2 mt-4">
                        <div className="table-responsive content col-lg-12 col-md-12 col-sm-12 p-0">
                          <table className="table col-lg-12 col-md-12 col-sm-12 copy-font">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Job Title</th>
                                <th>Department</th>
                                {/* <th>Type</th> */}
                                {/* <th>Posting Date</th> */}
                                <th>Deadline</th>
                                {/* <th>Number of Applicants</th> */}
                                <th>Actions</th>
                              </tr>
                            </thead>
                            {/* {loading ? <small>loading</small>: <RecruitmentTable all={jobs}/>} */}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade show"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <div className="col-6 d-flex p-0">
                      <small>Filter By:</small>
                      <div className="d-flex">
                        <span className="ml-2 mr-3">
                          <select className="form-control">
                            <option>Job Title</option>
                          </select>
                        </span>

                        <span className="ml-2 mr-3">
                          <select className="form-control">
                            <option>Course of study</option>
                          </select>
                        </span>

                        <span className="ml-2 mr-3">
                          <select className="form-control">
                            <option>Class of degree</option>
                          </select>
                        </span>

                        {/* <span className="mr-3">
                                                    <input type="date" className="form-control"></input>
                                                </span> */}
                      </div>
                    </div>

                    <div className="col-lg-12 p-0">
                      <div className="card x-panel w-100 ml-0 p-2 mt-4">
                        <div className="table-responsive content col-lg-12 col-md-12 col-sm-12 p-0">
                          <table className="table col-lg-12 col-md-12 col-sm-12 copy-font">
                            <thead>
                              <tr>
                                <th>Shortlist</th>
                                <th>#</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Department</th>
                                <th>Job Description</th>
                                <th>Type</th>
                                <th>Application Date</th>
                                <th>View Cover Letter</th>
                                <th>View CV</th>
                                {/* <th>Actions</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              <td className="text-center">
                                <input type="checkbox" />
                              </td>
                              <td>1</td>
                              <td>John</td>
                              <td>Doe</td>
                              <td>Accounts</td>
                              <td>Account Personnel</td>
                              <td>Intern</td>
                              <td>May 27, 2019</td>
                              <td>
                                <img
                                  src="https://res.cloudinary.com/teewhy/image/upload/v1555588840/View_COVER_L.png"
                                  alt=""
                                  style={{ width: '12%', cursor: 'pointer' }}
                                  className="ml-4"
                                />
                              </td>
                              <td>
                                <img
                                  src="https://res.cloudinary.com/teewhy/image/upload/v1555327943/View_CV.png"
                                  alt=""
                                  style={{ width: '12%', cursor: 'pointer' }}
                                  className="ml-4"
                                />
                              </td>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllApplications;
