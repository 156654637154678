/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/require-default-props */
/* eslint-disable no-shadow */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
// import { updateStatus } from '../../../../../actions/shared/requisitions/RequisitionActions';
import view from '../../../../../assets/img/icons/view.png';
import CommonRequisitionDetail from '../../../shared/requisition/shared/CommonRequistionDetails';
import ViewReceipt from '../../../shared/requisition/shared/ViewReceipt';
import {
  ACCOUNTS,
  ADMIN,
  ADMIN_DEPARTMENT,
  CUSTOMER_SUPPORT,
  EXPRESS_WIFI,
  NEW_PRODUCTS,
  NOC,
  OPERATIONS,
  PROCUREMENT,
  RESEARCH_AND_DEVELOPMENT,
  SALES,
  TECHNICAL_SUPPORT,
} from '../../../../../utils/departments/helpers';

class CeoAndCooRequisitionTableBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requistionId: {},
    };
  }

  getRequsition(requisition) {
    this.setState({
      requistionId: requisition,
    });
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // approveOrDisapprove = (req, id) => {
  //   const { updateStatus } = this.props;
  //   updateStatus(req, id);
  // };

  render() {
    const { requist, giveCeoCooApproval, giveCeoCooDisApproval } = this.props;

    const { requistionId } = this.state;

    const department = new Map([
      [`${ACCOUNTS}`, 'Accounts'],
      [`${ADMIN}`, 'ADMIN'],
      [`${ADMIN_DEPARTMENT}`, 'ADMIN_DEPARTMENT'],
      [`${CUSTOMER_SUPPORT}`, 'CUSTOMER SUPPORT'],
      [`${EXPRESS_WIFI}`, 'EXPRESS WIFI'],
      [`${NEW_PRODUCTS}`, 'NEW PRODUCTS'],
      [`${NOC}`, 'NOC'],
      [`${OPERATIONS}`, 'OPERATIONS'],
      [`${PROCUREMENT}`, 'PROCUREMENT'],
      [`${RESEARCH_AND_DEVELOPMENT}`, 'RESEARCH AND DEVELOPMENT'],
      [`${SALES}`, 'SALES'],
      [`${TECHNICAL_SUPPORT}`, 'TECHNICAL SUPPORT'],
    ]);

    const getDepartment = (type) => <strong>{department.get(type)}</strong> || null;

    const requsitionList = requist ? (
      requist.map((requsition, i) => {
        return (
          <tr>
            <td>{i + 1}</td>
            {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
            <td>N{requsition.amount_in_digits} </td>
            <td>{requsition.amount_in_words} </td>
            <td>{requsition ? `${requsition.EmployeeAuth.employees[0].email}` : null}</td>
            <td>{requsition.in_favour_of}</td>
            <td>{requsition.purpose}</td>

            <td>{getDepartment(requsition.department_id)}</td>
            {requsition.created_at ? <td>{moment(requsition.created_at).format('MM/DD/YYYY')}</td> : 'N/A'}

            <td>
              <div className="mr-2 ml-2">
                <button
                  data-target="#viewReceipt"
                  data-toggle="modal"
                  type="submit"
                  onClick={() => this.getRequsition(requsition)}
                  style={{
                    border: '0',
                    backgroundColor: 'white',
                  }}
                >
                  <span className="view2">
                    <img src={view} alt="view" data-toggle="tooltip" data-placement="left" title="View" />
                  </span>
                </button>
                <ViewReceipt requisit={requistionId} />
              </div>
            </td>
            <td>
              <small onClick={() => giveCeoCooApproval(requsition.id)}>Approve</small>
            </td>
            <td>
              <small onClick={() => giveCeoCooDisApproval(requsition.id)}>Reject</small>
            </td>
            <td>
              <div className="mr-2 ml-2">
                <button
                  data-target="#requisition"
                  data-toggle="modal"
                  type="submit"
                  onClick={() => this.getRequsition(requsition)}
                  style={{
                    border: '0',
                    backgroundColor: 'white',
                  }}
                >
                  <span className="view2">
                    <img src={view} alt="view" data-toggle="tooltip" data-placement="left" title="View" />
                  </span>
                </button>
                <CommonRequisitionDetail requisit={requistionId} />
              </div>
            </td>
            <ToastContainer />
          </tr>
        );
      })
    ) : (
      <tr>
        <td>no requsitions</td>
      </tr>
    );

    return <tbody>{requsitionList}</tbody>;
  }
}

// const mapStateToProps = (state) => ({
//   loading: state.requisitions.form,
// });

CeoAndCooRequisitionTableBody.propTypes = {
  // updateStatus: PropTypes.func,
  requist: PropTypes.shape(),
  giveCeoCooApproval: PropTypes.func,
  giveCeoCooDisApproval: PropTypes.func,
};
export default CeoAndCooRequisitionTableBody;
