import { GET_ANALYTICS } from '../../../constants/types/business-development/overview/AnalyticsTypes';
import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';

const initialState = {
  totalActive: '',
  totalExpired: '',
  isLoading: false,
};

const AnalyticsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  // console.log(payload)
  switch (type) {
    case LOADING_STARTS:
      return {
        ...state,
        isLoading: true,
      };

    case GET_ANALYTICS:
      return {
        ...state,
        totalExpired: payload.totalExpired,
        totalActive: payload.totalActive,

        isLoading: false,
      };

    case LOADING_STOPS:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default AnalyticsReducer;
