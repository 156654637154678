/* eslint-disable react/button-has-type */
import React, { useContext } from 'react';
import { OverviewContext } from '../../../../context/shared/installations/overview/OverviewContext';
import useFetch from '../../../../hooks/shared/custom/data/useFetch';
import { GET_ZONES_URL } from '../../../../services/api/shared/installations/ZonesUrl';
import { StatusData } from '../../../../utils/field-support/installations/helpers';
import CustomInput from '../../shared/forms/CustomInput';
import NewDesignDownload from './NewDesignDownload';
import styles from './NewDesign.module.css';

const NewDesignInstallationSearch = () => {
  const { data } = useFetch(`${GET_ZONES_URL}`);
  const { onSearchChange, search, searchInstallations, installations } = useContext(OverviewContext);

  const currentPage = 1;

  const handleSearchWithParams = () => searchInstallations(currentPage);

  const { from, to } = search;
  return (
    <>
      <div className={`col-xs-4 pl-3 ${styles.drops}`}>
        <div>
          <button className={`${styles.drop_green} p-2 px-3`}>
            Filter
            <i className="fa fa-caret-down ml-2" />
          </button>
        </div>
        <div className={styles.dropdown_content}>
          <div>
            <h6 className={styles.title}> Custom date range</h6>
            <div className="row m-2">
              <div className="col-xs">
                <input
                  placeholder=""
                  readOnly={false}
                  type="date"
                  className={styles.custom_select}
                  name="from"
                  value={from}
                  onChange={onSearchChange}
                />
              </div>
            </div>
            <hr />
            <div className="row m-2">
              <div className="col-xs ">
                <input
                  placeholder=""
                  readOnly={false}
                  type="date"
                  className={styles.custom_select}
                  name="to"
                  value={to}
                  onChange={onSearchChange}
                />
              </div>
            </div>
            <hr />
            <h6 className={styles.title}> Status</h6>
            <div className="row m-2">
              <div className="col-xs">
                <select className={styles.custom_select} onChange={onSearchChange} name="status">
                  <option value="">--Status--</option>
                  {StatusData.map(({ value, name }) => (
                    <option value={value}>{name}</option>
                  ))}
                </select>
              </div>
            </div>
            <hr />
            <h6 className={styles.title}> Zones</h6>
            <div className="row m-2">
              <div className="col-xs">
                <select className={styles.custom_select} onChange={onSearchChange} name="zone_id">
                  <option value="">--Zones--</option>
                  {data && data.length ? data.map((zon) => <option value={zon.id}>{zon.name}</option>) : null}
                </select>
              </div>
            </div>

            <div style={{ float: 'right' }}>
              <button type="button" className={styles.drop_green} onClick={handleSearchWithParams}>
                Apply Filter
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xs-4 pl-3">
        <button className={styles.drop}>{installations.length > 0 && <NewDesignDownload />}</button>
      </div>
    </>
  );
};

export default NewDesignInstallationSearch;
