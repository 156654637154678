import React from 'react';
import AssetTeamNavBar from '../../../../../layouts/asset-team/AssetTeamNavBar';
import { useUser } from '../../../../../../hooks/shared/user/useUser';
import ExpiredBody from '../../../../shared/customers/expired-customers/seven/ExpiredBody';

const AssetTeamExpired = () => {
  const {
    profile: { firstName },
  } = useUser();

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <AssetTeamNavBar currentPage="Expired 7 Days Ago" position={firstName} />
        <ExpiredBody />
      </main>
    </div>
  );
};

export default AssetTeamExpired;
