/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import view from '../../../../../../assets/img/icons/view.png';
import { useExpired } from '../../../../../../hooks/shared/asset-team/expired/useExpired';
import ViewExpiredUsers from '../../../../departments/asset-team/customers/expired/ViewExpiredUsers';

const ExpiredTable = ({ items }) => {
  const { getExpired7DaysAgo, customers, isLoading } = useExpired();
  const [zone, setZone] = useState('');

  const getDetails = (customer) => {
    setZone(customer.basestationame);
    const data = {
      action: 'tizeti_asset_expiration_days_users',
      days_ago: 7,
      calledstationid: customer.basestationame,
    };
    getExpired7DaysAgo(data);
  };

  const tableData =
    items && items.length ? (
      items.map((item, i) => {
        const { basestationame, expired_count } = item;
        return (
          <tr className="text-nowrap" key={i}>
            <td>{i + 1}</td>
            <td>{basestationame.toUpperCase()}</td>
            <td>{expired_count}</td>
            <td>
              <button
                type="submit"
                data-toggle="tooltip"
                data-placement="left"
                title="View"
                onClick={() => getDetails(item)}
              >
                <span className="view1 ml-4">
                  <img src={view} alt="" data-toggle="modal" data-target="#viewDetails" />
                </span>
              </button>
              <ViewExpiredUsers zone={zone} users={customers} isLoading={isLoading} />
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="4" className="text-center">
          No Expired Customers Seven Days Ago
        </td>
      </tr>
    );

  return tableData;
};

export default ExpiredTable;
