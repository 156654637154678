import {
  FETCHING_STARTS,
  FETCHING_STOPS,
  LOADING_STARTS,
  LOADING_STOPS,
} from '../../../constants/types/shared/LoadingTypes';
import {
  GET_BILLING_ESTATES,
  GET_FAILED_INSTALLATIONS,
  GET_INSTALLATIONS,
} from '../../../constants/types/sales/installations/InstallationsTypes';

const initialState = {
  installations: [],
  loading: false,
  estates: [],
  load: false,
  failed: [],
};

export const InstallationsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCHING_STARTS:
      return {
        ...state,
        loading: payload,
      };

    case FETCHING_STOPS:
      return {
        ...state,
        loading: payload,
      };

    case GET_INSTALLATIONS:
      return {
        ...state,
        installations: payload,
        loading: false,
      };

    case GET_FAILED_INSTALLATIONS:
      return {
        ...state,
        failed: payload,
        loading: false,
      };

    case GET_BILLING_ESTATES:
      return {
        ...state,
        estates: payload,
      };

    case LOADING_STARTS:
      return {
        ...state,
        load: payload,
      };

    case LOADING_STOPS:
      return {
        ...state,
        load: payload,
      };

    default:
      return state;
  }
};

export default InstallationsReducer;
