/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/require-default-props */
/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useCustomer } from '../../../../../hooks/shared/customers/useCustomer';
import { showDepartmentName } from '../../../../../utils/departments/helpers';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import CalledStation from '../../../shared/called-station/CalledStation';

const EditDetails = ({ username, oldEmail, oldName, oldPhone, calledstation, radacct, isFetching }) => {
  const {
    editMacChange,
    mac,
    editMacDetails,
    isLoading,
    editEmailDetails,
    email,
    editEmailChange,
    isLoadingEmail,
    editNameDetails,
    isLoadingName,
    editFibreMacDetails,
    editNameChange,
    editRetrievedDetails,
    loader,
    loaders,
    account,
    phoneNumber,
    editPhoneDetails,
    isLoadingPhone,
    editPhoneChange,
  } = useCustomer();

  const [devicetype, setDeviceType] = useState('');

  const devices = [
    { name: 'CAMBIUM', value: 'cambium' },
    { name: 'UBIQUITI', value: 'ubiquiti' },
    { name: 'TERRAGRAPH', value: 'terragraph' },
    { name: 'LTE', value: 'LTE' },
    { name: 'FIBRE', value: 'fibre' },
    { name: 'PERASO', value: 'peraso' },
    { name: 'Wave Nano', value: 'Wave Nano' },
  ];

  const { type, first, last } = account;
  const { profile, user } = useUser();
  const { officialEmail } = profile;
  const [macId, setMacId] = useState('');
  const [salesType, setSalesType] = useState('');
  const { department_id, role_id } = user;
  const [types, setTypes] = useState([]);
  const [retrieved, setRetrieved] = useState('');
  const [retrievedEmail, setRetrievedEmail] = useState(false);
  const [isLoadingTypes, setIsLoadingTypes] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const [editName, setEditName] = useState(false);
  const [editPhone, setEditPhone] = useState(false);

  const fetchTypes = async (data) => {
    setIsLoadingTypes(type);
    const config = {
      method: 'post',
      url: `https://legacy.tizeti.com/frontaccountapi/account.php`,
      data: data,
    };
    axios(config)
      .then((res) => {
        // console.log(res);
        setTypes(res.data.types);
      })
      .catch((err) => {
        // console.log(err);
      })
      .finally(() => {
        setIsLoadingTypes(false);
      });
  };

  const onSubmitName = (e) => {
    e.preventDefault();

    const data = {
      email: oldEmail,
      accountType: type,
      macaddress: username,
      firstName: first,
      lastName: last,
      roleId: Number(role_id),
      department: showDepartmentName.get(department_id),
      staffemail: officialEmail,
    };

    editNameDetails(data);
  };

  const onSubmitRetrieved = (e) => {
    e.preventDefault();
    const data = {
      email: retrieved,

      mac: username,
    };
    editRetrievedDetails(data);
  };
  const onSubmitEmail = (e) => {
    e.preventDefault();

    const data = {
      email: oldEmail,
      newEmail: email,
      macaddress: username,
      roleId: Number(role_id),
      department: showDepartmentName.get(department_id),
      staffemail: officialEmail,
    };
    editEmailDetails(data);
  };

  const onSubmitPhone = (e) => {
    e.preventDefault();
    const data = {
      phoneNumber: oldPhone,
      newPhoneNumber: phoneNumber,
      email: oldEmail,
      macAddress: username,
      roleId: Number(role_id),
      department: showDepartmentName.get(department_id),
      staffemail: officialEmail,
    };
    editPhoneDetails(data);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      oldmac: username,
      newmac: mac,
      newmac_device_type: devicetype,
      staffemail: officialEmail,
      roleid: Number(role_id),
      department: showDepartmentName.get(department_id),
    };
    editMacDetails(data);
  };
  const onFibreSubmit = (e) => {
    e.preventDefault();
    const data = {
      oldmac: username,
      newmac: macId,
      newmac_device_type: 'fibre',
      staffemail: officialEmail,
      roleid: Number(role_id),
      sales_type: salesType,
      department: showDepartmentName.get(department_id),
    };
    editFibreMacDetails(data);
  };
  const submitForm = () => {
    return mac.length > 0 && devicetype.length > 0;
  };

  const submitForme = () => {
    return retrieved.length > 0;
  };
  const submitForms = () => {
    return macId.length > 0 && salesType.length > 0;
  };
  const changeEmailStatus = () => {
    setEditEmail(!editEmail);
  };

  const changeRetrievedStatus = () => setRetrievedEmail(!retrievedEmail);

  const changeNameStatus = () => {
    setEditName(!editName);
  };

  const changePhoneStatus = () => {
    setEditPhone(!editPhone);
  };

  const submitNameForm = () => {
    return type.length > 0 && first.length > 0 && last.length > 0;
  };
  return (
    <div
      id="edit-details"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Edit Customer Details
            </h4>
            <button type="button" className="close mr-3" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body">
            {Number(role_id) > 1 ? (
              <form className="form-horizontal" onSubmit={onSubmit}>
                <div className="form-group">
                  <div className="col-12 mb-3 d-flex">
                    <div className="col-6">
                      <input type="text" className="form-control" value={username} readOnly />
                    </div>
                    <div className="col-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="new mac id"
                        name="mac"
                        value={mac}
                        onChange={editMacChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 mb-3 d-flex">
                    <div className="col-6">
                      <select
                        name="devicetype"
                        className="form-control"
                        onChange={(e) => setDeviceType(e.target.value)}
                      >
                        <option value="">--Select Device Type--</option>
                        {devices.length ? (
                          devices.map((item) => {
                            return (
                              <option value={item.value} name={item.name}>
                                {item.name}
                              </option>
                            );
                          })
                        ) : (
                          <option value="">Fetching Devices...</option>
                        )}
                      </select>
                    </div>
                  </div>

                  {isLoading ? (
                    <div className="col-12 mb-3 text-center">
                      <button className="btn btn-green" disabled={isLoading}>
                        Editing...
                      </button>
                    </div>
                  ) : (
                    <div className="col-12 mb-3 text-center">
                      <button className="btn btn-green" disabled={!submitForm()}>
                        Edit Details
                      </button>
                    </div>
                  )}
                </div>
              </form>
            ) : null}

            <CalledStation macid={username} calledstation={calledstation} radacct={radacct} isFetching={isFetching} />
            <h5 className="text-center" onClick={changeEmailStatus}>
              Click here to edit Fibre{' '}
            </h5>
            {editEmail && (
              <form className="form-horizontal" onSubmit={onFibreSubmit}>
                <div className="form-group">
                  <div className="col-12 mb-3 d-flex">
                    <div className="col-6">
                      <input type="text" className="form-control" value={username} readOnly />
                    </div>
                    <div className="col-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="new mac id"
                        name="macId"
                        value={macId}
                        onChange={(e) => setMacId(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12 mb-3 d-flex">
                    <div className="col-6">
                      <select name="sales_type" className="form-control" onChange={(e) => setSalesType(e.target.value)}>
                        <option value="">--Select Sales Type--</option>

                        <option value="fibre-residential">Fibre Residential</option>
                        <option value="fibre-business">Fibre Business</option>
                        <option value="fibre-dedicated">Fibre Dedicated</option>
                      </select>
                    </div>
                  </div>

                  {loaders ? (
                    <div className="col-12 mb-3 text-center">
                      <button className="btn btn-green" disabled={isLoading}>
                        Editing...
                      </button>
                    </div>
                  ) : (
                    <div className="col-12 mb-3 text-center">
                      <button className="btn btn-green" disabled={!submitForms()}>
                        Edit Details
                      </button>
                    </div>
                  )}
                </div>
              </form>
            )}
            <h5 className="text-center" onClick={changeRetrievedStatus}>
              Click to revert retrieved Email{' '}
            </h5>
            {retrievedEmail && (
              <form className="form-horizontal" onSubmit={onSubmitRetrieved}>
                <div className="form-group">
                  <div className="col-12 mb-3 d-flex">
                    <div className="col-6">
                      <input type="text" className="form-control" value={username} readOnly />
                    </div>

                    <div className="col-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        onChange={(e) => setRetrieved(e.target.value)}
                        value={retrieved}
                      />
                    </div>
                  </div>

                  {loader ? (
                    <div className="col-12 mb-3 text-center">
                      <button className="btn btn-green" disabled>
                        Editing...
                      </button>
                    </div>
                  ) : (
                    <div className="col-12 mb-3 text-center">
                      <button className="btn btn-green" disabled={!submitForme()}>
                        Edit Details
                      </button>
                    </div>
                  )}
                </div>
              </form>
            )}
            {/* {editEmail ? (
              <form className="form-horizontal mt-3" onSubmit={onSubmitEmail}>
                <div className="text-center col-12 d-flex">
                  <div className="col-6 text-left">
                    <h5>Edit Email</h5>
                  </div>
                  <div className="col-6">
                    <h5 style={{ textAlign: 'right', cursor: 'pointer' }} onClick={changeEmailStatus}>
                      <b>x</b>
                    </h5>
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-12 mb-3 d-flex">
                    <div className="col-6">
                      <input type="email" className="form-control" value={oldEmail} readOnly />
                    </div>
                    <div className="col-6">
                      <input type="text" className="form-control" placeholder="Email" onChange={editEmailChange} />
                    </div>
                  </div>
                  {isLoadingEmail ? (
                    <div className="col-12 mb-3 text-center">
                      <button className="btn btn-green" disabled={isLoadingEmail}>
                        Editing...
                      </button>
                    </div>
                  ) : (
                    <div className="col-12 mb-3 text-center">
                      <button className="btn btn-green">Edit</button>
                    </div>
                  )}
                </div>
              </form>
            ) : (
              <div className="text-center">
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events 
                <h5 style={{ cursor: 'pointer' }} onClick={changeEmailStatus}>
                  Click To Edit Email
                </h5>
              </div>
            )} */}

            {/* {editName ? (
              <form className="form-horizontal mt-3" onSubmit={onSubmitName}>
                <div className="text-center col-12 d-flex">
                  <div className="col-6 text-left">
                    <h5>Edit Name</h5>
                  </div>
                  <div className="col-6">
                    <h5 style={{ textAlign: 'right', cursor: 'pointer' }} onClick={changeNameStatus}>
                      <b>x</b>
                    </h5>
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-12 mb-3 d-flex">
                    <div className="col-6">
                      <input type="text" className="form-control" value={oldName} readOnly />
                    </div>
                    <div className="col-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Account Type"
                        value={type}
                        onChange={editNameChange}
                        name="type"
                      />
                    </div>
                  </div>

                  <div className="col-12 mb-3 d-flex">
                    <div className="col-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                        value={first}
                        onChange={editNameChange}
                        name="first"
                      />
                    </div>
                    <div className="col-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        value={last}
                        onChange={editNameChange}
                        name="last"
                      />
                    </div>
                  </div>

                  <div className="col-12 mb-3 text-center">
                    {isLoadingName ? (
                      <button className="btn btn-green" disabled={isLoadingName}>
                        Editing...
                      </button>
                    ) : (
                      <button className="btn btn-green" disabled={!submitNameForm()}>
                        Edit
                      </button>
                    )}
                  </div>
                </div>
              </form>
            ) : (
              <div className="text-center">
                <h5 style={{ cursor: 'pointer' }} onClick={changeNameStatus}>
                  Click To Edit Name
                </h5>
              </div>
            )} */}

            {/* {editPhone ? (
              <form className="form-horizontal mt-3" onSubmit={onSubmitPhone}>
                <div className="text-center col-12 d-flex">
                  <div className="col-6 text-left">
                    <h5>Edit Phone Number</h5>
                  </div>
                  <div className="col-6">
                    <h5 style={{ textAlign: 'right', cursor: 'pointer' }} onClick={changePhoneStatus}>
                      <b>x</b>
                    </h5>
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-12 mb-3 d-flex">
                    <div className="col-6">
                      <input type="text" className="form-control" value={oldPhone} readOnly />
                    </div>
                    <div className="col-6">
                      <input
                        type="text"
                        className="form-control"
                        value={phoneNumber}
                        name="phoneNumber"
                        onChange={editPhoneChange}
                      />
                    </div>
                  </div>

                  <div className="col-12 mb-3 text-center">
                    {isLoadingPhone ? (
                      <button className="btn btn-green" disabled={isLoadingPhone}>
                        Editing..
                      </button>
                    ) : (
                      <button className="btn btn-green">Edit</button>
                    )}
                  </div>
                </div>
              </form>
            ) : (
              <div className="text-center">
                <h5 style={{ cursor: 'pointer' }} onClick={changePhoneStatus}>
                  Click To Edit Phone Number
                </h5>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

EditDetails.propTypes = {
  username: PropTypes.string,
  oldEmail: PropTypes.string,
  oldName: PropTypes.string,
  oldPhone: PropTypes.string,
  calledstation: PropTypes.arrayOf,
  radacct: PropTypes.arrayOf,
  isFetching: PropTypes.bool,
};
export default EditDetails;
