/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import '../../../../../../styles/style.css';
import axios from 'axios';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import view from '../../../../../../assets/img/icons/view.png';
import edit from '../../../../../../assets/img/icons/edit.png';
import Loader from '../../../../../../assets/img/icons/TizetiLoaderColored.gif';
// import { activateCustomer } from '../../../../../../actions/wificall/customers/UnverifiedCustomersActions';
// import { getWificallPlans } from '../../../../../../actions/wificall/plans/PlansActions';
import { useCustomers } from '../../../../../../hooks/new-products/products/wificall/customers/useCustomers';
import ViewCustomer from '../ViewCustomer';
import EditCustomer from '../EditCustomer';
import {
  GET_CUSTOMER_WIFICALL_NUMBER_URL,
  GET_WIFICALL_USER_BY_ID_URL,
  GET_WIFICALL_USER_SIP_DETAILS_URL,
} from '../../../../../../services/api/wificall/UsersUrl';
import AddCustomers from '../AddCustomers';
import PushNotification from '../PushNotification';
import BulkPushNotification from '../BulkPushNotification';

const VerifiedCustomersTable = ({ customers, load }) => {
  const { plans } = useCustomers();
  const [wallet, setWallet] = useState('');
  const [one, setOne] = useState({});
  const [user, setUser] = useState({});
  const [assignedNumber, setAssignedNumber] = useState('');
  const [allCustomers, setAllCustomers] = useState([]);

  useEffect(() => {
    if (customers && customers.length)
      return setAllCustomers(
        customers.map((cust) => {
          return {
            select: false,
            id: cust.id,
            firstName: cust.firstName,
            lastName: cust.lastName,
            email: cust.email,
            phoneNumber: cust.phoneNumber,
            planType: cust.planType,
            source: cust.source,
            user_sip: cust.user_sip,
          };
        }),
      );
  }, [customers]);

  const getCustomer = async (customer) => {
    setOne(customer);
    try {
      const res = await axios.get(`${GET_WIFICALL_USER_BY_ID_URL}/${customer.id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (res.data.username) {
        const response = await Promise.all([
          axios.get(`${GET_WIFICALL_USER_SIP_DETAILS_URL}/${res.data.username}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
          }),
          axios.get(`${GET_CUSTOMER_WIFICALL_NUMBER_URL}/${res.data.cardid}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            }
          }),
        ]);

        if (response[0].data.status === true && response[1].data.status === true) {
          setWallet(response[0].data.result[0].credit);
          setAssignedNumber(response[1].data.result[0].did);
        } else {
          toastr.info(`Unable to get user details!`);
        }

        // try {
        //   const response = await axios.get(`${GET_WIFICALL_USER_SIP_DETAILS_URL}/${res.data.username}`, {
        //     headers: {
        //       Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        //     },
        //   });
        //   console.log(response.data);

        //   if (response.data.status === true) {
        //     this.setState({
        //       wallet: response.data.result[0].credit,
        //     });
        //   }
        // } catch (e) {}
      }
    } catch (e) {
      if (e) {
        toastr.info('Something went wrong.Please try again later!');
      }
    }
  };

  const getSelectedCustomer = (selectedCustomer) => {
    setOne(selectedCustomer);
  };
  const getCustomerForEdit = (customer) => setUser(customer);

  const customersList =
    customers.length > 0 ? (
      allCustomers.map((customer, index) => {
        return (
          <tr key={index}>
            <td className="text-center">
              <input
                type="checkbox"
                checked={customer.select}
                onChange={(e) => {
                  const { checked } = e.target;
                  setAllCustomers(
                    allCustomers.map((data) => {
                      if (customer.user_sip.cardid === data.user_sip.cardid) {
                        data.select = checked;
                      }
                      return data;
                    }),
                  );
                }}
              />
            </td>
            <td>{index + 1}</td>
            {customer.firstName ? <td>{customer.firstName}</td> : <td>N/A</td>}
            {customer.lastName ? <td>{customer.lastName}</td> : <td>N/A</td>}
            {customer.email ? <td>{customer.email}</td> : <td>N/A</td>}
            {customer.phoneNumber ? <td>{customer.phoneNumber}</td> : <td>N/A</td>}
            <td className="text-center">{customer.user_sip ? customer.user_sip.cardid : <small>N/A</small>}</td>
            <td className="text-center">{customer.planType === null ? <small>N/A</small> : customer.planType}</td>
            <td className="text-center">{customer.source === null ? <small>N/A</small> : customer.source}</td>
            <td>
              <span className="badge badge-success">{customer.status}</span>
            </td>
            <td>
              <div className="d-flex">
                <button
                  type="button"
                  className="mr-2"
                  onClick={() => getSelectedCustomer(customer)}
                  data-toggle="modal"
                  data-placement="left"
                  data-target="#singlepush"
                >
                  <span className="view1">
                    <i
                      className="fa fa-comment-o"
                      aria-hidden="true"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Single Push"
                    />
                  </span>
                </button>
                <PushNotification customer={one} />
                <button
                  data-id={customer.id}
                  type="submit"
                  className="mx-1"
                  onClick={() => getCustomer(customer)}
                  data-toggle="modal"
                  data-placement="center"
                  data-target="#customer_detail"
                >
                  <span className="view1">
                    <img src={view} alt="" data-toggle="tooltip" data-placement="right" title="View" />
                  </span>
                </button>
                <ViewCustomer customer={one} wallet={wallet} number={assignedNumber} />
                <button
                  type="submit"
                  data-toggle="modal"
                  data-target="#editcustomer"
                  onClick={() => getCustomerForEdit(customer)}
                >
                  <span className="view">
                    <img src={edit} data-toggle="tooltip" data-placement="top" title="Edit" alt="edit" />
                  </span>
                </button>
                <EditCustomer user={user} plans={plans} />
              </div>
            </td>
            {/* <td
            className="text-center"
            onClick={() => this.activateCustomer(customer)}
          >
            <img
              src="https://res.cloudinary.com/teewhy/image/upload/v1545642324/checked.png"
              className="w-25"
              alt="checked"
            />
          </td> */}
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="11" className="text-center">
          There are no customers
        </td>
      </tr>
    );

  return (
    <>
      <div className="my-2 col-12 ml-3 d-flex">
        <button type="button" className="btn btn-green mr-1" data-toggle="modal" data-target="#addcustomer">
          Add Customers
        </button>
        <AddCustomers />
        {customers && customers.length ? (
          <div className="d-flex">
            <CSVLink data={customers} filename="WificallCustomers.csv">
              <button type="button" className="btn btn-green mr-1">
                Download
              </button>
            </CSVLink>
            <button type="button" className="btn btn-green mr-1" data-toggle="modal" data-target="#bulkpush">
              Bulk Push
            </button>
            <BulkPushNotification allCustomers={allCustomers} />
          </div>
        ) : null}
      </div>

      <div className="card x-panel">
        <div className="table-responsive content">
          <table className="table">
            <thead>
              <tr>
                <th className="text-nowrap">
                  <input
                    type="checkbox"
                    className="mr-1"
                    onChange={(e) => {
                      const { checked } = e.target;
                      setAllCustomers(
                        allCustomers.map((data) => {
                          data.select = checked;
                          return data;
                        }),
                      );
                    }}
                  />
                  Select
                </th>
                <th>#</th>
                <th className="text-nowrap">First Name</th>
                <th className="text-nowrap">Last Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th className="text-nowrap">Card ID</th>
                <th className="text-nowrap">Account Type</th>
                <th>Source</th>
                {/* <th>Balance</th> */}
                {/* <th>Account</th> */}
                <th>Status</th>
                <th>Actions</th>
                {/* <th>Activate</th> */}
              </tr>
            </thead>
            {load ? (
              <tr>
                <td colSpan="9" className="text-center">
                  <img src={Loader} alt="" />
                </td>
              </tr>
            ) : (
              <tbody>{customersList}</tbody>
            )}
          </table>
        </div>
      </div>
    </>
  );
};

VerifiedCustomersTable.propTypes = {
  customers: PropTypes.arrayOf.isRequired,
  load: PropTypes.bool.isRequired,
};

export default VerifiedCustomersTable;
