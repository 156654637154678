/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { NoItemRow } from '../../../../../utils/loader/loadingHelper';

const InstalledUsersTable = ({ users }) => {
  const tableData =
    users && users.length ? (
      users.map((user, i) => {
        const { Name, username, Expiration_date } = user;
        return (
          <tr className="text-nowrap" key={i}>
            <td>{i + 1}</td>
            <td>{Name}</td>
            <td>{username}</td>
            <td>{Expiration_date}</td>
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan="4" text="No Installation" />
    );

  return tableData;
};

export default InstalledUsersTable;
