import { GET_WIFICALL_PLANS } from '../../constants/types/wificall/WificallTypes';

const initialState = {
  plans: [],
};

export const PlansReducer = (state = initialState, action) => {
  if (action.type === GET_WIFICALL_PLANS) {
    return {
      ...state,
      plans: action.payload,
    };
  }
  return state;
};

export default PlansReducer;
