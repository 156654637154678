import { useReducer } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { customReducer } from '../../../reducers/shared/custom/CustomReducer';
import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';

const useFetchBulkDetails = () => {
  const [{ loading }, dispatch] = useReducer(customReducer, { loading: false });

  const fetchDetails = async (url, params) => {
    dispatch({ type: LOADING_STARTS });
    try {
      const response = await axios.post(url, params);
      // console.log(response.status, response.data);
      // const { status } = response.data;
      if (response.status === 200) {
        toastr.success('Bulk Details Fetched');
        dispatch({ type: LOADING_STOPS });
        return {
          ...response.data,
        };
      }
    } catch (e) {
      dispatch({ type: LOADING_STOPS });
      if (e.response.data) {
        const { message } = e.response.data;
        if (message) return toastr.info(message);
      }
      return toastr.info('Error Fetching Details');
    }
    return fetchDetails;
  };

  return { loading, fetchDetails };
};

export default useFetchBulkDetails;
