/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useUpdateDedicatedCustomers } from '../../../../hooks/business-development/useUpdateDedicatedCustomers';

const UpdateDedicatedCustomers = ({ request, request: { username, to_be_decommissioned } }) => {
  const { loading, UpdateDedicatedCustomersStatus, UpdateDecommissioned } = useUpdateDedicatedCustomers();
  // const profile = useUser();

  //   const [subscription_type, setSubscriptionType] = useState('');
  //   const [customer_status, setCustomerStatus] = useState('');
  // const [option, setOption] = useState('');
  const [one, setOne] = useState({
    action: 'update_dedicated_customer_status_subscription_type',
    action_key: '',
    mac_id: '',
    action_value: '',
  });
  const { action_value, action_key, mac_id } = one;

  // const staffEmail = `${profile.email}`;

  useEffect(() => {
    const result = {
      mac_id: username,
      decommissioned: to_be_decommissioned,
    };
    setOne({ ...result });
  }, [request]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOne({ ...one, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(action_value);
    if (action_value === 'true') {
      const data = {
        decommissionStatus: 'true',
        // staff_email: staffEmail,
        macid: mac_id,
      };
      UpdateDecommissioned(data);
    } else if  (action_value === 'false') {
      const data = {
        decommissionStatus: 'false',
        // staff_email: staffEmail,
        macid: mac_id,
      };
      UpdateDecommissioned(data);
    }
    
    else{
      const data = {
        action: 'update_dedicated_customer_status_subscription_type',
        key: action_key,
        value: action_value,
        // staff_email: staffEmail,
        username: mac_id,
      };
      UpdateDedicatedCustomersStatus(data);
    }
  };

  //   const submitForm = () => {
  //     return action_key.length > 0 && action_value.length > 0 && staffEmail.length > 0 && mac_id.length > 0;
  //   };

  return (
    <div id="updateDedicated" className="modal fade" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header shadow">
            <h4 className="modal-title text-center" id="myModalLabel">
              Update Dedicated Customers
            </h4>
            <button type="button" className="close mr-2" data-dismiss="modal">
              x
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={onSubmit} className="form-horizontal form-material">
              <div className="form-group invent">
               
                  <div className="col-md-12 mb-3 d-flex">
                    <div className="col-lg-6">
                      <label className="m-0 p-0 copy-font" htmlFor="firstName">
                        Update By
                      </label>
                      <select name="action_key" value={action_key} onChange={handleChange}>
                        <option value="">Select an option</option>
                        <option value="customer_status">Customer Status</option>
                        <option value="subscription_type">Subscription Type</option>
                      </select>
                    </div>
                    {action_key !== 'subscription_type' ? (
                      <div className="col-lg-6">
                        <select name="action_value" value={action_value} onChange={handleChange}>
                          <option value="">Select an option</option>
                          {/* <option value="expired"> Expired</option>
                        <option value="active"> Active</option> */}
                          {one.decommissioned === 'true' ? (
                            <option value="false">Commission User</option>
                          ) : (
                            <option value="true">Decommission User</option>
                          )}
                        </select>
                      </div>
                    ) : (
                      <>
                        <div className="col-lg-6">
                          <select name="action_value" value={action_value} onChange={handleChange}>
                            <option value="">Select an option</option>
                            <option value="1-month"> 1-Month</option>
                            <option value="2-months"> 2-Months</option>
                            <option value="3-months"> 3-Months</option>
                            <option value="4-months"> 4-Months</option>
                            <option value="5-months"> 5-Months</option>
                            <option value="6-months"> 6-Months</option>
                            <option value="7-months"> 7-Months</option>
                            <option value="8-months"> 8-Months</option>
                            <option value="9-months"> 9-Months</option>
                            <option value="10-months"> 10-Months</option>
                            <option value="11-months"> 11-Months</option>
                            <option value="12-months"> 12-Months</option>
                          </select>
                        </div>
                      </>
                    )}
                  </div>
              
                <div className="col-md-12 mb-3 d-flex">
                  <div className="col-lg-6">
                    <label className="copy-font" htmlFor="mac">
                      Mac ID
                    </label>
                    <input id="name" name="mac_id" className="form-control" value={mac_id} onChange={handleChange} />
                  </div>
                  {/* 
                  <div className="col-lg-6">
                    <label className="copy-font" htmlFor="mail">
                      Email
                    </label>
                    <input
                      id="name"
                      name="staffemail"
                      className="form-control"
                      value={staffEmail}
                      onChange={handleChange}
                    />
                  </div> */}
                </div>

                <div className="text-center">
                  {loading ? (
                    <button type="button" className="btn btn-green py-2 px-5" disabled>
                      Updating...
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-green py-2 px-5">
                      Update
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

UpdateDedicatedCustomers.propTypes = {
  request: PropTypes.objectOf.isRequired,
};

export default UpdateDedicatedCustomers;
