/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import NocNavBar from '../../../../layouts/noc/NocNavBar';
import Schedule from '../../../shared/noc/schedule/Schedule';
import useCheckTwoDepartments from '../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';
import { NOC, SUPER_ADMIN } from '../../../../../utils/departments/helpers';

const NocSchedule = ({ history }) => {
  useCheckTwoDepartments(`${NOC}`, `${SUPER_ADMIN}`, history);
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <NocNavBar currentPage="Night/Sunday" />
        <Schedule />
      </main>
    </div>
  );
};

NocSchedule.propTypes = {
  history: PropTypes.shape(),
};

export default NocSchedule;
