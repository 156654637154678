import React, { Component } from 'react';
import toastr from 'toastr';
import axios from 'axios';
import { connect } from 'react-redux';
import { API_URL } from '../../../../utils/config';
import { getDedicatedCustomers } from '../../../../actions/noc/dedicated-customers/DedicatedCustomersActions';

class AddDedicated extends Component {
  constructor() {
    super();

    this.state = {
      name: '',
      ap: '',
      ssid: '',
      password: '',
      device: '',
      loading: false,
      modalShow: false,
    };

    this.onChange = this.onChange.bind(this);
    this.addDedicated = this.addDedicated.bind(this);
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  addDedicated = (e) => {
    this.setState({
      loading: true,
    });

    e.preventDefault();

    if (!this.state.name) {
      toastr.info('Please enter the name');
      this.setState({
        loading: false,
      });
    }

    if (!this.state.ap) {
      toastr.info('Please enter the ap');
      this.setState({
        loading: false,
      });
    }

    if (!this.state.ssid) {
      toastr.info('Please enter the ssid');
      this.setState({
        loading: false,
      });
    }

    if (!this.state.name) {
      toastr.info('Please enter the name');
      this.setState({
        loading: false,
      });
    }

    if (!this.state.password) {
      toastr.info('Please select the password');
      this.setState({
        loading: false,
      });
    }

    if (!this.state.device) {
      toastr.info('Please enter the device power');
      this.setState({
        loading: false,
      });
    }

    const data = {
      name: this.state.name,
      ap: this.state.ap,
      ssid: this.state.ssid,
      password: this.state.password,
      device: this.state.device,
    };

    axios({
      method: 'post',
      data,
      url: `${API_URL}/dedicated/customer/add`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    }).then((res) => {
      if (res.data.id) {
        this.setState(
          {
            modalShow: !this.state.modalShow,
            name: '',
            ap: '',
            ssid: '',
            password: '',
            device: '',
            loading: false,
          },
          (props) => {
            // this.props.modalshow = this.state.modalShow;
            // console.log("hello", this.state.modalShow, this.props.modalshow(this.state.modalShow));
          },
        );
        this.props.getDedicatedCustomers();

        toastr.success('Dedicated Customer added successfully', {
          timeOut: 2000,
          fadeOut: 2000,
        });
      } else {
        toastr.info('Something went wrong! Please try again later🙄!');
      }
    });
  };

  render() {
    return (
      <div className="modal-body">
        <form className="form-horizontal form-material" onSubmit={this.addDedicated}>
          <div className="form-group invent">
            <div className="col-md-12 mb-3 d-flex">
              <div className="col-lg-6">
                <label className="copy-font m-0 p-0">Name</label>
                <input
                  type="text"
                  className="form-control pl-2"
                  placeholder="Customer Name"
                  name="name"
                  onChange={this.onChange}
                  value={this.state.name}
                  required
                />
              </div>

              <div className="col-lg-6">
                <label className="copy-font m-0 p-0">AP</label>
                <input
                  type="text"
                  className="form-control pl-2"
                  placeholder="AP IP Address"
                  name="ap"
                  onChange={this.onChange}
                  value={this.state.ap}
                  required
                />
              </div>
            </div>

            <div className="col-md-12 mb-3 d-flex">
              {/* <div className="col-lg-6">
                                            <label className="copy-font m-0 p-0">Station</label>
                                    <div className="col-lg-6">
                                            <label className="copy-font">Station</label>
>>>>>>> 1b8e6db2971cd3be4cf239e2653c860d08c751ba
                                            <input
                                                type="text"
                                                className="form-control pl-2"
                                                placeholder="Station IP Address"
                                                name="router"
                                            />
                                        </div> */}

              <div className="col-lg-6">
                <label className="copy-font m-0 p-0">SSID</label>
                <input
                  type="text"
                  className="form-control  pl-2"
                  placeholder="WIFI SSID"
                  name="ssid"
                  onChange={this.onChange}
                  value={this.state.ssid}
                />
              </div>

              <div className="col-lg-6">
                <label className="copy-font m-0 p-0">Password</label>
                <div className="d-flex">
                  <input className="mt-1" type="radio" name="password" value="Yes" onChange={this.onChange} />
                  <p className="copy-font ml-2 mr-2 mt-1" style={{ fontSize: '14px' }}>
                    Yes
                  </p>
                  <input className="mt-1" type="radio" name="password" value="No" onChange={this.onChange} />
                  <p className="copy-font ml-2 mt-1" style={{ fontSize: '14px' }}>
                    No
                  </p>
                </div>
              </div>
            </div>

            {/* /!* <div className="col-lg-6"> */}
            {/* <label className="copy-font m-0 p-0">Expiration Date</label> */}
            {/* /!* <div className="col-lg-6"> */}
            {/* <label className="copy-font">Expiration Date</label> */}
            {/* <input */}
            {/* type="date" */}
            {/* className="form-control  pl-2" */}
            {/* placeholder="Country" */}
            {/* name="country" */}
            {/* /> */}
            {/* </div> *!/ */}
            {/* </div> */}

            <div className="col-md-12">
              <div className="col-12">
                <label className="copy-font m-0 p-0">Device Power</label>
                <input
                  type="text"
                  className="form-control pl-2"
                  placeholder="Device power"
                  name="device"
                  onChange={this.onChange}
                  value={this.state.device}
                />
              </div>
            </div>
          </div>
          {this.state.loading ? (
            <div className="text-center">
              <button className="btn btn-green" disabled>
                Add
              </button>
            </div>
          ) : (
            <div className="text-center">
              <button className="btn btn-green">Add</button>
            </div>
          )}
        </form>
      </div>
    );
  }
}

export default connect(null, { getDedicatedCustomers })(AddDedicated);
