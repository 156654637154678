import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import WifiCallSidebar from '../../../layouts/wificall-sidebar/WifiCallSidebar';
import WifiCallLogo from '../../../layouts/wificall-logo/WifiCallLogo';
import Logout from '../../shared/auth/Logout';
import { API_URL } from '../../../../utils/config';

export default class WifiCallFeature extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
    };
  }

  componentDidMount() {
    this.getUserDetails();
  }

  getUserDetails() {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };

    axios
      .get(`${API_URL}/employee/loggedOnEmployee`, config)
      .then((res) => {
        if (res.data) {
          this.setState({
            firstName: res.data.firstName,
          });
        }
      })
      .catch((err) => {});
  }

  render() {
    const { firstName } = this.state;

    return (
      <div className="main-wrapper">
        <header className="topbar shade bg-white fixed-top">
          <nav className="navbar navbar-expand-lg navbar-light top-navbar fixed-top">
            <WifiCallLogo />
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse mt-3" id="navbarText">
              <ul className="navbar-nav" style={{ marginRight: 'auto' }}>
                <li className="nav-item active">
                  <a className="nav-link" href="#">
                    <h3>
                      Request a Feature<span className="sr-only">(current)</span>
                    </h3>
                  </a>
                </li>
              </ul>
              <span className="navbar-text">
                <div className="d-flex">
                  <h6 className="mr-0 mt-1">
                    {firstName}
                    <Logout />
                  </h6>
                </div>
              </span>
            </div>
          </nav>
        </header>

        <div className="all-sides col-lg-12 col-md-12 col-sm-12">
          <WifiCallSidebar />
          <div className="page-wrapper col-lg-10 col-md-10 col-sm-12">
            <div className="row">
              <div className="container-fluid col-lg-7">
                <form action="javascript:void(0)" className="form-horizontal mt-5">
                  <div className="form-group text-left">
                    <div className="col-md-12 mb-5 d-flex p-0">
                      <div className="col-lg-6">
                        <label className="copy-font p-0 m-0">Subject</label>
                        <input type="text" className="form-control" placeholder="Subject" />
                      </div>
                    </div>

                    <div className="col-md-12 mb-5 d-flex">
                      <div>
                        <label className="copy-font p-0 mb-0">Details</label>
                        <textarea
                          className="form-control"
                          name="detail"
                          rows="5"
                          cols="50"
                          placeholder="Details of the Feature"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-12 mt-5">
                    <button type="button" className="btn btn-green" data-dismiss="modal">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
