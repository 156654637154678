import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { OverviewContext } from '../../../../../context/shared/installations/overview/OverviewContext';

const DeleteInstallationModal = ({ install }) => {
  const {
    onShowDeleteInstallationModal,
    showDeleteInstallationModal,
    deleteInstallation,
    deletingInstallation,
  } = useContext(OverviewContext);

  const showButton = () => {
    if (deletingInstallation) {
      return (
        <Button variant="danger" disabled>
          Deleting Installation...
        </Button>
      );
    }
    return (
      <Button variant="danger" onClick={() => deleteInstallation(install.installationId)}>
        Delete Zone
      </Button>
    );
  };

  return (
    <Modal show={showDeleteInstallationModal} onHide={onShowDeleteInstallationModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete {install.firstName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete {install.firstName} installation ?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onShowDeleteInstallationModal}>
          Close
        </Button>
        {showButton()}
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteInstallationModal;
