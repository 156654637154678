import axios from 'axios';
import toastr from 'toastr';
import * as url from '../../../services/api/wificall/CustomersUrl';
import * as types from '../../../constants/types/wificall/WificallTypes';
import * as load from '../../../constants/types/shared/LoadingTypes';

export const getVerifiedCustomers = () => async (dispatch) => {
  dispatch({
    type: load.FETCHING_STARTS,
  });

  try {
    const response = await axios.get(`${url.GET_VERIFIED_CUSTOMER_URL}/1`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      dispatch({
        type: types.GET_WIFICALL_USERS,
        payload: {
          customers: response.data.users,
          pages: response.data.pages,
        },
      });
    }
  } catch (e) {
    toastr.info('Something went wrong!');
    dispatch({
      type: load.FETCHING_STOPS,
    });
  }
};

export const getPaginatedVerifiedUsers = (data) => async (dispatch) => {
  dispatch({
    type: load.FETCHING_STARTS,
    payload: true,
  });

  try {
    const response = await axios.get(`${url.GET_VERIFIED_CUSTOMER_URL}/${data}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      dispatch({
        type: types.GET_WIFICALL_USERS,
        payload: {
          customers: response.data.users,
          pages: response.data.pages,
        },
      });
    }
  } catch (e) {
    toastr.info('Something went wrong!');
  }

  // axios.get(`${API_URL}/wificall/users/${selected}`, {
  //
  //   headers: {
  //
  //     'Authorization': `Bearer ${sessionStorage.getItem('token')}`
  //
  //   }
  //
  // }).then(response => {
  //
  //   this.setState({
  //
  //     users: response.data.users,
  //     loading: false,
  //     pageCount: response.data.pages
  //
  //   })
  //
  // })
};

export const searchWithEmail = (data) => async (dispatch) => {
  dispatch({
    type: load.LOADING_STARTS,
    payload: true,
  });

  try {
    const response = await axios.post(`${url.SEARCH_WIFICALL_CUSTOMERS_WITH_EMAIL_URL}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      dispatch({
        type: types.GET_VERIFIED_WIFICALL_USERS,
        payload: {
          users: response.data,
          pages: 0,
        },
      });
    }

    if (response.data.length < 1) {
      toastr.info('No user with that email exists');
    }
  } catch (e) {
    toastr.info('Something went wrong!');
  }
};

export const searchWithPhone = (data) => async (dispatch) => {
  dispatch({
    type: load.LOADING_STARTS,
    payload: true,
  });

  try {
    const response = await axios.post(`${url.SEARCH_WIFICALL_CUSTOMERS_WITH_PHONE_URL}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      dispatch({
        type: types.GET_VERIFIED_WIFICALL_USERS,
        payload: {
          users: response.data,
          pages: 0,
        },
      });
    }
  } catch (e) {
    toastr.info('Something went wrong!');
  }
};
