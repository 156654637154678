import { admin } from '../../utils/config';
import { DELETE_DEPARTMENT, GET_DEPARTMENTS } from '../../constants/types/shared/DepartmentsTypes';

const initialState = {
  departments: [],
};

const DepartmentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DEPARTMENTS:
      return {
        ...state,
        departments: action.payload.filter((dept) => dept.id !== `${admin}`),
      };

    case DELETE_DEPARTMENT:
      return {
        ...state,
        departments: state.departments.filter((department) => department.id !== action.payload),
      };

    default:
      return state;
  }
};

export default DepartmentsReducer;
