import React from 'react';
import base from '../../../../../assets/img/icons/base.png';
import useExpiredCustomers from '../../../../../hooks/shared/customers/useExpiredCustomers';

const ExpiredLTECustomers = () => {
  const {
    state: { LTEExpired },
  } = useExpiredCustomers();

  return (
    <div className="col-lg col-md col-sm">
      <div className="boxes">
        <div className="text-center">
          <div className="text-center mt-3">
            <img src={base} alt="LTE" width="30px" height="30px" />
          </div>
          <div className="count">{LTEExpired}</div>
          <h3 className="color-three">LTE Expired</h3>
        </div>
      </div>
    </div>
  );
};

export default ExpiredLTECustomers;
