/* eslint-disable import/no-named-as-default */
/* eslint-disable import/prefer-default-export */
import { useReducer, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import RequsitionReducer from '../../../reducers/shared/requistion/RequsitionReducer';
import * as load from '../../../constants/types/shared/LoadingTypes';
import * as url from '../../../services/api/shared/requisitions/RequisitionsUrl';
import * as types from '../../../constants/types/shared/requisition/RequisitionTypes';
import {
  GET_LEADS_REQUISITIONS_URL,
  PAGINATE_LEADS_REQUISITIONS_URL,
  UPDATE_REQUISITION_STATUS_URL,
} from '../../../services/api/accounts/requisition/RequisitionUrl';

export const useRequisition = (amounts = { from: '', to: '' }) => {
  const [
    { ceoAndCooRequisitions, isLoading, approved, approvedLoading, approvedPages, totalApproved },
    dispatch,
  ] = useReducer(RequsitionReducer, {
    ceoAndCooRequisitions: [],
    isLoading: false,
    approved: [],
    approvedLoading: false,
    approvedPages: 0,
    totalApproved: 0,
  });

  const fetchQueuedCeoAndCooRequisitions = async () => {
    dispatch({
      type: load.LOADING_STARTS,
    });

    try {
      const response = await axios.get(`${url.GET_CEO_AND_COO_REQUISITION_URL}/1?status=queued`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data) {
        dispatch({
          type: types.GET_REQUISITIONS,
          payload: response.data,
        });
      }
    } catch (e) {
      if (e) {
        dispatch({
          type: load.LOADING_STOPS,
        });
      }
    }
  };

  const fetchRequisitionByStatus = async (data) => {
    dispatch({
      type: load.LOADING_STARTS,
    });

    try {
      const response = await axios.get(`${url.GET_CEO_AND_COO_REQUISITION_URL}/1?status=${data}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data) {
        dispatch({
          type: types.GET_REQUISITIONS,
          payload: response.data,
        });
      }
    } catch (e) {
      if (e) {
        dispatch({
          type: load.LOADING_STOPS,
        });
      }
    }
  };

  const giveCeoCooApproval = async (id) => {
    const data = {
      approval: 'approved',
    };
    dispatch({
      type: types.START_APPROVE_OR_DISAPPROVE,
    });
    try {
      const response = await axios.patch(`${url.CEO_COO_UPDATE_REQUISITIONS_STATUS}/${id}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data) {
        toast.success('Requsition Approved');
        dispatch(fetchQueuedCeoAndCooRequisitions());

        // dispatch({
        //   type: types.APPROVE_OR_DISAPPROVE_SUCCESS,
        //   payload: id,
        // });
      }
    } catch (e) {
      if (e) {
        toast.info(e.response.data.message);
        dispatch({
          type: types.STOP_APPROVE_OR_DISAPPROVE,
        });
      }
    }
  };

  const giveCeoCooDisApproval = async (id) => {
    const data = {
      approval: 'rejected',
    };

    dispatch({
      type: types.START_APPROVE_OR_DISAPPROVE,
    });
    try {
      const response = await axios.patch(`${url.CEO_COO_UPDATE_REQUISITIONS_STATUS}/${id}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data) {
        toast.success('Requsition Rejected');

        // dispatch({
        //   type: types.APPROVE_OR_DISAPPROVE_SUCCESS,
        //   payload: id,
        // });

        dispatch(fetchQueuedCeoAndCooRequisitions());
      }
    } catch (e) {
      if (e) {
        toast.info(e.response.data.message);
        dispatch({
          type: types.STOP_APPROVE_OR_DISAPPROVE,
        });
      }
    }
  };
  const getAllApprovedRequisition = async () => {
    dispatch({
      type: types.LOADING_APPROVED_REQUISITIONS,
    });
    try {
      let LEADS_URL =
        amounts.from || amounts.to
          ? `${GET_LEADS_REQUISITIONS_URL}/?from=${amounts.from}&to=${amounts.to}`
          : GET_LEADS_REQUISITIONS_URL;
      const response = await axios.get(LEADS_URL, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.status === 200) {
        dispatch({
          type: types.GET_APPROVED_REQUISITIONS,
          payload: response.data,
        });
      }
    } catch (e) {
      dispatch({
        type: types.LOADING_APPROVED_REQUISITIONS_STOPS,
      });
      return e;
    }
  };
  const paginateApprovedRequisition = async (data) => {
    dispatch({
      type: types.LOADING_APPROVED_REQUISITIONS,
    });
    const pageNumber = data.selected + 1;
    try {
      let LEADS_URL =
        amounts.from || amounts.to
          ? `${PAGINATE_LEADS_REQUISITIONS_URL}/${pageNumber}/?from=${amounts.from}&to=${amounts.to}`
          : `${PAGINATE_LEADS_REQUISITIONS_URL}/${pageNumber}`;
      const response = await axios.get(LEADS_URL, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_APPROVED_REQUISITIONS,
          payload: response.data,
        });
      }
    } catch (e) {
      toast.info('Something went wrong');
      dispatch({
        type: types.LOADING_APPROVED_REQUISITIONS_STOPS,
      });
    }
  };

  const payUser = async (params) => {
    const data = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      payment_status: 'paid',
    };
    try {
      const response = await axios.patch(`${UPDATE_REQUISITION_STATUS_URL}/${params}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        toast.success('Successful!');
        // window.location.reload()
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000);
        getAllApprovedRequisition();
        // dispatch({
        //   type: 'REMOVE_PAID',
        //   payload: params,
        // });
      }
    } catch (e) {
      if (e) {
        toast.info('Unable to update status!');
      }
    }
  };

  useEffect(() => {
    fetchQueuedCeoAndCooRequisitions();
    getAllApprovedRequisition();
  }, []);

  return {
    ceoAndCooRequisitions,
    approved,
    isLoading,
    fetchRequisitionByStatus,
    giveCeoCooApproval,
    giveCeoCooDisApproval,
    approvedLoading,
    approvedPages,
    totalApproved,
    paginateApprovedRequisition,
    payUser,
    getAllApprovedRequisition,
  };
};
