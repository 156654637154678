/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React, { ReactNode } from 'react';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../utils/loader/loadingHelper';

const UsageHistoryTable = ({ customer, load }) => {
  const tableData =
    customer && customer.length ? (
      customer.map((item, i) => {
        const { username, date, upload_gb, download_gb } = item;

        return (
          <tr className="" key={i}>
            <td>{i + 1}</td>
            <td>{username}</td>
            <td>{date}</td>
            <td>{upload_gb}</td>
            <td>{download_gb}</td>
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan="20" text="There Are No Customers" />
    );
  return (
    <div className="table-responsive content col-12 px-1">
      <table className="table">
        <thead>
          <tr className="text-nowrap">
            <th>#</th>
            <th>MAC ID</th>
            <th>Session Date</th>
            <th> Upload(GB)</th>
            <th> Download(GB)</th>
          </tr>
        </thead>

        <tbody>{load ? <TizetiGIFLogoTable colSpan="20" /> : tableData}</tbody>
      </table>
    </div>
  );
};

export default UsageHistoryTable;
