import {
  GET_BANDWIDTH,
  GET_DEDICATED_CUSTOMERS,
  START_LOADING_BANDWIDTH,
  START_LOADING_DEDICATED_CUSTOMERS,
  STOP_LOADING_BANDWIDTH,
  STOP_LOADING_DEDICATED_CUSTOMERS,
} from '../../../constants/types/noc/NocTypes';
import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';

const initialState = {
  dedicated: [],
  load: false,
  getting: false,
  bandwidth: [],
};

export const DedicatedCustomersReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case GET_DEDICATED_CUSTOMERS:
      return {
        ...state,
        dedicated: payload,
        load: false,
      };

    case START_LOADING_DEDICATED_CUSTOMERS:
      return {
        ...state,
        load: payload,
      };

    case STOP_LOADING_DEDICATED_CUSTOMERS:
      return {
        ...state,
        load: payload,
      };

    case LOADING_STARTS:
      return {
        ...state,
        isLoading: true,
      };

    case LOADING_STOPS:
      return {
        ...state,
        isLoading: false,
      };

    case START_LOADING_BANDWIDTH:
      return {
        ...state,
        getting: true,
      };

    case STOP_LOADING_BANDWIDTH:
      return {
        ...state,
        getting: false,
      };

    case GET_BANDWIDTH:
      return {
        ...state,
        getting: false,
        bandwidth: payload,
      };

    default:
      return state;
  }
};

export default DedicatedCustomersReducer;
