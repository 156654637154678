/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import PATHS from '../../../routes/urls';
import WificallSideBarItem from './WificallSideBarItem';

const WifiCallSidebar = () => {
  return (
    <div className="s-layout__sidebar">
      <Link className="s-sidebar__trigger" to="#">
        <i className="fa fa-bars mr-3" />
      </Link>
      <nav className="s-sidebar__nav">
        <Link className="navbar-brand-logo" to="/wifi-call" id="control" style={{ width: '17%' }}>
          <img
            src="https://res.cloudinary.com/teewhy/image/upload/v1545148837/wifi-dropdown.fw.png"
            alt="homepage"
            data-toggle="collapse"
            data-target="#demo-profile"
            className="hotspot-profile"
          />

          <ul id="demo-profile" className="collapse">
            <li className="copy-font">
              <Link className="navbar-brand-logo" to="/wifi-call" style={{ width: '16%', marginTop: '-2%' }}>
                <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971047/Tizeti-Logo.png" alt="homepage" />
              </Link>
            </li>
          </ul>
        </Link>
        <ul>
          <li className="p-0 mt-3">
            <li className="s-sidebar__nav-link">
              <Link to="/wifi-call" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971013/Group-3.png" alt="img" />
                </span>
                <small>Overview</small>
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/wifi-call/plans" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1545212009/plans.fw.png" alt="img" />
                </span>
                <small>Plans</small>
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              <Link to="/wifi-call/active-inactive-customers" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-6.png" alt="img" />
                </span>
                <small>Active/Inactive</small>
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              <Link to="/wifi-call/assigned-unassigned" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-6.png" alt="img" />
                </span>
                <small>Assigned/Unassigned</small>
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/wifi-call/customers" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-6.png" alt="img" />
                </span>
                <small>Customers</small>
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/wifi-call/unverified-customers" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-6.png" alt="img" />
                </span>
                <small>Unverified Customers</small>
              </Link>
            </li>

            <WificallSideBarItem
              src="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-6.png"
              url={PATHS.WIFICALL_FREE_SETUP_SIGNUP}
              name="Business Call"
            />
            <WificallSideBarItem
              src="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-6.png"
              url={PATHS.WIFICALL_OPEN_BLOCKED_USERS}
              name="Blocked and Open Users"
            />
            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/wifi-call/login-logs" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1545212008/directory.fw.png" alt="img" />
                </span>
                <small>Login Logs</small>
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/wifi-call/payments" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1545212993/paymentt.fw.png" alt="img" />
                </span>
                <small>Payments</small>
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/wifi-call/verify" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1550653705/Verify.png" alt="img" />
                </span>
                <small>Verify</small>
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/wifi-call/directory" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1545212008/directory.fw.png" alt="img" />
                </span>
                <small>Directory</small>
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/wifi-call/count" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1550653705/Verify.png" alt="img" />
                </span>
                <small>Click Count</small>
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/wifi-call/escalate" target="_self" className="text-danger make-bold">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1557141046/Schedule.png" alt="img" />
                </span>
                Escalate
              </Link>
            </li>
          </li>

          <li className="s-sidebar__nav-link">
            <Link to="/wifi-call/learning-portal" target="_self">
              <span className="mr-2">
                <img src="https://res.cloudinary.com/teewhy/image/upload/v1557141046/Schedule.png" alt="img" />
              </span>
              Learning Portal
            </Link>
          </li>

          <li className="s-sidebar__nav-link">
            <Link to="/wifi-call/purchase-order" target="_self" className="">
              <span className="mr-2">
                <img src="https://res.cloudinary.com/teewhy/image/upload/v1557141046/Schedule.png" alt="img" />
              </span>
              Purchase Order
            </Link>
          </li>

          <li className="s-sidebar__nav-link">
            <Link to="/wifi-call/frequency" target="_self" className="">
              <span className="mr-2">
                <img src="https://res.cloudinary.com/teewhy/image/upload/v1557141046/Schedule.png" alt="img" />
              </span>
              Frequency History
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default WifiCallSidebar;
