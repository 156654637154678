/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import useGeniatech from '../../../../../../../hooks/shared/geniatech/useGeniatech';
import GeniatechContext from '../../../../../../../context/GeniatechContext';
import { STATISTICS_URL } from '../api/StatisticsUrl';

const StatisticsProvider = ({ children }) => {
  const { data, loading, pages, search, searchData, onChange, searchStatus, reset, fetched } = useGeniatech(
    {
      action: 'statistics',
      offset: '0',
      limit: '100',
      network: 'tz-xwf',
      tower: 'Agege',
    },
    `${STATISTICS_URL}`,
  );
  return (
    <GeniatechContext.Provider
      value={{
        data,
        loading,
        search,
        searchData,
        pages,
        onChange,
        searchStatus,
        reset,
        fetched,
      }}
    >
      {children}
    </GeniatechContext.Provider>
  );
};
export default StatisticsProvider;
