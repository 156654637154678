/* eslint-disable react/button-has-type */
import React, { useContext } from 'react';
import ProfileContext from '../../../../../context/shared/lte/profile/ProfileContext';
import ProfilesTableBody from './ProfilesTableBody';

const ProfilesTable = () => {
  const value = useContext(ProfileContext);
  const { profiles, loading } = value;
  return (
    <div className="card x-panel logs">
      <div className="table-responsive content">
        <table className="table copy-font">
          <thead>
            <tr>
              <th>#</th>
              <th>Profile Title</th>
              <th>Security</th>
              <th>AMBR</th>
              <th>pre_emption</th>
              <th>QCI</th>
              <th>TYPE</th>
              <th>PCC RULW</th>
              <th>APN</th>
            </tr>
          </thead>
          {loading ? <small>loading...</small> : <ProfilesTableBody profiles={profiles} />}
        </table>
      </div>
    </div>
  );
};

export default ProfilesTable;
