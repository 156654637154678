/* eslint-disable @typescript-eslint/camelcase */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import RequisitionContext from '../../../../../context/shared/requisitions/RequsitionContext';

const ApproveRequsition = ({ requisit }) => {
  const value = useContext(RequisitionContext);
  const { approve, onChangeApprove, giveManagerApproval, isApprovingOrDisapproving } = value;

  const onSubmit = (e) => {
    e.preventDefault();
    const { id } = requisit;
    const data = {
      manager_approval: 'approved',
      budget_code: approve,
    };

    if (
      Number(`${sessionStorage.getItem('roleId')}`) === 3 ||
      +`${sessionStorage.getItem('roleId')}` === 12 ||
      +`${sessionStorage.getItem('roleId')}` === 11
    ) {
      giveManagerApproval(id, data);
    }
    return null;
  };

  const submitForm = () => {
    return approve.length > 0;
  };

  return (
    <div
      id="approve"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Approve Requisition
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <form onSubmit={onSubmit}>
            <div className="modal-body p-0">
              <div className="m-3">
                <input
                  className="form-control"
                  placeholder="Enter Budget Code"
                  rows="4"
                  cols="50"
                  name="approve"
                  value={approve}
                  onChange={onChangeApprove}
                />
              </div>
              <div className="text-center my-2">
                {/* eslint-disable-next-line react/button-has-type */}
                {isApprovingOrDisapproving ? (
                  <button type="submit" className="btn btn-info" disabled={isApprovingOrDisapproving}>
                    Submitting...
                  </button>
                ) : (
                  <button type="submit" className="btn btn-info" disabled={!submitForm()}>
                    Submit
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

ApproveRequsition.propTypes = {
  // eslint-disable-next-line react/require-default-props
  requisit: PropTypes.shape(),
};
export default ApproveRequsition;
