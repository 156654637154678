/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/require-default-props */
/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import qs from 'qs';
import { useCustomer } from '../../../../../hooks/shared/customers/useCustomer';
import { useUser } from '../../../../../hooks/shared/user/useUser';

const EditCustomerDetails = ({ username }) => {
  const { mac, isLoading, editMacChange, editMacId } = useCustomer();
  const { profile, user } = useUser();
  // eslint-disable-next-line @typescript-eslint/camelcase
  const { role_id } = user;
  const { officialEmail } = profile;

  //
  //
  // logged in user staff id
  //
  //
  //   [Research and Development, Super Admin and Customer Support

  const onSubmitMac = (e) => {
    e.preventDefault();
    const data = qs.stringify({
      action: 'tizetiosCrmChangeMacAddress',
      oldmacaddress: username,

      newmacaddress: mac,
      roleid: Number(role_id),
      staffemail: officialEmail || 'UserHasNoProfile',
      department: 'Procurement',
    });

    editMacId(data);
  };

  // const submitForm = () => {
  //   return type.length > 0 && first.length > 0 && last.length > 0;
  // };

  return (
    <div
      id="edit-details"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Edit Customer Details
            </h4>
            <button type="button" className="close custom-close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body">
            <form className="form-horizontal mt-3" onSubmit={onSubmitMac}>
              <div className="form-group">
                <div className="col-12 mb-3 d-flex">
                  <div className="col-6">
                    <input type="text" className="form-control" value={username} readOnly />
                  </div>
                  <div className="col-6">
                    <input type="text" className="form-control" value={mac} name="mac" onChange={editMacChange} />
                  </div>
                </div>

                <div className="col-12 mb-3 text-center">
                  {isLoading ? (
                    <button className="btn btn-green" disabled={isLoading}>
                      Editing..
                    </button>
                  ) : (
                    <button className="btn btn-green">Edit Mac</button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

EditCustomerDetails.propTypes = {
  username: PropTypes.string,
};
export default EditCustomerDetails;
