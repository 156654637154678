/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validateIP from 'validate-ip-node';
import { API_URL } from '../../../../../utils/config';

class AddIP extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ipaddress: '',
      privateip1: '',
      privateip2: '',
      privateip3: '',
      loading: false,
      stations: [],
      stationid: '',
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount() {
    this.getBaseStations();
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();

    this.setState({
      loading: true,
    });

    if (this.state.stationid === '') {
      toast.warn('Please select a base station!');
      this.setState({
        loading: false,
      });
    }

    if (validateIP(this.state.ipaddress) !== true) {
      toast.warn('invalid Public IP Address Entered');
      this.setState({
        loading: false,
      });
    }
    if (validateIP(this.state.privateip1) !== true) {
      toast.warn('Invalid Private IP 1 Address Entered');
      this.setState({
        loading: false,
      });
    }
    if (validateIP(this.state.privateip2) !== true) {
      toast.warn('Invalid Private IP 2 Address Entered');
      this.setState({
        loading: false,
      });
    }
    if (validateIP(this.state.privateip3) !== true) {
      toast.warn('Invalid Private IP 3 Address Entered');
      this.setState({
        loading: false,
      });
    }

    const data = {
      base_station_id: this.state.stationid,
      ip_Address: this.state.ipaddress,
      private_Ip1: this.state.privateip1,
      private_Ip2: this.state.privateip2,
      private_Ip3: this.state.privateip3,
    };

    axios({
      method: 'post',
      url: `${API_URL}/ip`,
      data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
        if (response.data) {
          this.setState({
            loading: false,
          });

          window.location.reload();
          toast.success('IP Added Successfully!');
        } else {
          toast.info('Something went wrong! Please try again!');
          this.setState({
            loading: false,
          });
        }
      })
      .catch(() => {});
  }

  getBaseStations() {
    axios
      .get(`${API_URL}/baseStation`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      })
      .then((response) => {
        this.setState({
          stations: response.data,
        });
      })
      .catch(() => {});
  }

  render() {
    const { stations } = this.state;

    return (
      <div
        id="AddIP"
        className="modal fade in"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center" id="myModalLabel">
                Add IP Address
              </h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
            </div>
            <div className="modal-body">
              <form className="form-horizontal" onSubmit={this.onSubmit}>
                <div className="form-group">
                  <div className="col-lg-12 d-flex mb-3">
                    <div className="col-lg-6">
                      {/* <label className="col-lg-12 text-justify copy-font">Basestation Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="apssid"
                                        placeholder="Access Point SSID"
                                        required={true}
                                        value={this.state.apssid}
                                        onChange={this.onChange}
                                    /> */}
                      <label className="col-lg-12 text-justify copy-font">Basestation Name</label>
                      <select className="form-control form-dropdown" onChange={this.onChange} name="stationid">
                        <option>Select Basestation</option>
                        {stations.map((station) => {
                          return (
                            <option key={station.id} value={station.id} required>
                              {station.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="col-lg-6">
                      <label className="col-lg-12 text-justify copy-font">Public IP Address</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Public IP Address"
                        name="ipaddress"
                        required
                        value={this.state.ipaddress}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 d-flex">
                    <div className="col-lg-6">
                      <label className="col-lg-12 text-justify copy-font">Private IP 1 Address</label>
                      <input
                        type="text"
                        min="0"
                        className="form-control"
                        placeholder="Private IP 1 Address"
                        required
                        name="privateip1"
                        onChange={this.onChange}
                        value={this.state.privateip1}
                      />
                    </div>

                    <div className="col-lg-6">
                      <label className="col-lg-12 text-justify copy-font">Private IP Address 2</label>
                      <input
                        type="text"
                        min="0"
                        className="form-control"
                        placeholder="Private IP Address 2"
                        name="privateip2"
                        onChange={this.onChange}
                        value={this.state.privateip2}
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 d-flex">
                    <div className="col-lg-6">
                      <label className="col-lg-12 text-justify copy-font">Private IP Address 3</label>
                      <input
                        type="text"
                        min="0"
                        className="form-control"
                        placeholder="Private IP Address"
                        name="privateip3"
                        onChange={this.onChange}
                        value={this.state.ipaddress3}
                      />
                    </div>
                  </div>
                </div>

                {this.state.loading ? (
                  <div className="text-center mt-4">
                    <button className="btn btn-green" type="button">
                      <i className="fa fa-circle-o-notch fa-spin" />
                    </button>
                  </div>
                ) : (
                  <div className="text-center mt-4">
                    <button className="btn btn-green" type="button">
                      Add IP Address(es)
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

export default AddIP;
