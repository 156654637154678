import React, { Component } from 'react';

class EditDevice extends Component {
  render() {
    return (
      <div
        id="editDevice"
        className="modal fade in text-left"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center" id="myModalLabel">
                Edit Device
              </h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <form className="form-horizontal col-12">
                  <div className="form-group">
                    <div className="col-12 d-flex mb-3">
                      <div className="col-6">
                        <label className="copy-font m-0 p-0">Select Basestation</label>
                        <select className="form-control">
                          <option />
                        </select>
                      </div>
                      <div className="col-6">
                        <label className="copy-font m-0 p-0">Device Name</label>
                        <input type="text" className="form-control pl-2" placeholder="Device Name" />
                      </div>
                    </div>

                    <div className="col-12 d-flex mb-3">
                      <div className="col-6">
                        <label className="copy-font m-0 p-0">Quantity</label>
                        <select className="form-control">
                          <option />
                        </select>
                      </div>
                      <div className="col-6">
                        <label className="copy-font m-0 p-0">Solar Panel</label>
                        <select className="form-control" placeholder="Quantity">
                          <option />
                        </select>
                      </div>
                    </div>

                    <div className="text-center">
                      <button className="btn btn-second">Update</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditDevice;
