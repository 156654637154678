import React, { Component } from 'react'

class AddProject extends Component {

    constructor(props) {
        super(props);

        this.state = {

            name: '',
            project: '',
            start: '',
            end: '',
            loading: false
        }

    };

    onChange(e){

        this.setState({

            [e.target.name]: e.target.value

        })

    }

    addProject = e => {

        e.preventDefault();

        this.setState({

            loading: true

        });

    };

    render() {

        const { loading } = this.state;

    return (
        <div id="project" className="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title text-center" id="myModalLabel">Add Project</h4>
                        <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                            <form className="form-horizontal" onSubmit={this.addProject}>
                                <div className="form-group">
                                    <div className="col-12 d-flex mb-3 p-0">
                                        <div className="col-6">
                                            <label className="copy-font m-0 p-0">Project Name</label>
                                            <input
                                                type="text"
                                                placeholder="Project Name"
                                                className="form-control"
                                                name="name"
                                                onChange={this.onChange}
                                                value={this.state.name}
                                            />
                                        </div>

                                        <div className="col-6">
                                            <label className="copy-font m-0 p-0">Project User</label>
                                            <input
                                                type="text"
                                                placeholder="Project User"
                                                className="form-control"
                                                name="project"
                                                onChange={this.onChange}
                                                value={this.state.project}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-12 mb-3 d-flex p-0">
                                        <div className="col-6 p-0">
                                            <div className="col-12 mb-4">
                                                <label className="copy-font m-0 p-0">Start Date</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    name="start"
                                                    value={this.state.start}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                            <div className="col-12">
                                                <label className="copy-font m-0 p-0">End Date</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    name="end"
                                                    value={this.state.end}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <label className="copy-font m-0 p-0">Project Description</label>
                                            {/* <input type="text" placeholder="IP Address" className="form-control" /> */}
                                            <textarea
                                                rows="5"
                                                cols="20"
                                                name="description"
                                                className="form-control"
                                                value={this.state.description}
                                                onChange={this.onChange}
                                            >
                                            </textarea>
                                        </div>
                                    </div>

                                    <div className="col-12 text-center mt-3">
                                        {loading? <button className="btn btn-green"><i className="fa fa-circle-o-notch fa-spin"></i></button> : <button className="btn btn-green">Add Project</button>}
                                    </div>
                                </div>
                            </form>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}

export default AddProject;
