import React, { useContext } from 'react';
import CustomPagination from '../../../../shared/CustomPagination';
import { PtpContext } from './context/PtpContext';

const PaginatePage = () => {
  const { pages, paginateChange } = useContext(PtpContext);
  const pageChange = (params) => {
    const { selected } = params;
    paginateChange(selected + 1);
  };

  return (
    <CustomPagination pagesCount={pages} marginPagesDisplayed={5} pageRangeDisplayed={5} onPageChange={pageChange} />
  );
};

export default PaginatePage;
