import React from 'react';
import PropTypes from 'prop-types';
import RetailerSms from '../../../../shared/express-wifi/retailer-sms/RetailerSms';
import ExpressWifiNavBar from '../../../../../layouts/expresswifi/ExpressWifiNavBar';
import useCheckDepartment from '../../../../../../hooks/shared/custom/check-department/useCheckDepartment';
import { LOGGED_IN_USER_URL } from '../../../../../../services/api/auth/AuthUrl';
import { EXPRESS_WIFI } from '../../../../../../utils/departments/helpers';
import { useUser } from '../../../../../../hooks/shared/user/useUser';
import NewRetailerSMS from '../../../../shared/express-wifi/retailer-sms/newRetailerSMS.jsx';

const ExpressWifiRetailerSms = ({ history }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { result } = useCheckDepartment(`${LOGGED_IN_USER_URL}`, `${EXPRESS_WIFI}`, history);
  const { profile } = useUser();
  const { firstName } = profile;

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <ExpressWifiNavBar currentPage="Sms" position={firstName} />
        {/* <RetailerSms /> */}
        <NewRetailerSMS/>
      </main>
    </div>
  );
};

ExpressWifiRetailerSms.propTypes = {
  // eslint-disable-next-line react/require-default-props
  history: PropTypes.shape(),
};

export default ExpressWifiRetailerSms;
