import { GET_ANDROID_COUNT, GET_IOS_COUNT, GET_WEB_COUNT } from "../../../constants/types/link-count/LinkCountTypes";

const initialState = {

  web: '',
  android: '',
  ios: ''

};

export const LinkCountReducer=(state=initialState, action) => {
  switch (action.type) {
    case GET_WEB_COUNT:
      return {

        ...state,
        web: action.payload

      };

    case GET_ANDROID_COUNT:
      return {

        ...state,
        android: action.payload

      };

    case GET_IOS_COUNT:
      return {

        ...state,
        ios: action.payload

      };

    default:
      return state;

  }

};

export default LinkCountReducer;