import React from 'react';

export const displaySource = (handleSource) => {
  return (
    <select className="form-control mr-2" name="source" onChange={handleSource}>
      <option selected={false} value="NA">
        -- Source --
      </option>
      <option selected={false} value="android">
        Android
      </option>
      <option selected={false} value="ios">
        iOS
      </option>
      <option selected={false} value="web">
        Web
      </option>
      <option selected={false} value="all">
        All
      </option>
    </select>
  );
};

export const displayAccountType = (handleAccountType) => {
  return (
    <select className="form-control mr-2" name="planType" onChange={handleAccountType}>
      <option value="NA" selected>
        -- Account Type --
      </option>
      <option value="personal">Personal</option>
      <option value="business">Business</option>
    </select>
  );
};
