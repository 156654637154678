import { GET_STATES } from '../constants/types/shared/StatesTypes';

const initialState = {
  states: [],
};

export const StatesReducer = (state = initialState, action) => {
  const { payload, type } = action;
  if (type === GET_STATES) {
    return {
      ...state,
      states: payload,
    };
  }
  return state;
};

export default StatesReducer;
