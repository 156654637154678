/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { SALES } from '../../../../../utils/config';
import DedicatedCustomers from './DedicatedCustomers';
import SalesNavBar from '../../../../layouts/sales/SalesNavBar';
import useCheckTwoDepartments from '../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';
import { SUPER_ADMIN, VICE_PRESIDENT_SALES } from '../../../../../utils/departments/helpers';
import useCheckFiveDepartments from '../../../../../hooks/shared/custom/check-department/useCheckFiveDepartments';

const SalesDedicatedCustomers = ({ history }) => {
  useCheckFiveDepartments(`${SALES}`, `${SUPER_ADMIN}`, `${VICE_PRESIDENT_SALES}`, history);
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <SalesNavBar currentPage="Dedicated Customers" />
        <DedicatedCustomers />
      </main>
    </div>
  );
};

SalesDedicatedCustomers.propTypes = {
  history: PropTypes.shape(),
};
export default SalesDedicatedCustomers;
