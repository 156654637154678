/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ToastContainer } from 'react-toastify';
import mailblast from '../../../../../../assets/img/icons/mailblast.svg';
import view from '../../../../../../assets/img/icons/view.png';
import CustomerSupportSideBar from '../../../../../layouts/customer-support/CustomerSupportSideBar';
import {
  getEmployeesInCustomerSupport,
  getScore,
} from '../../../../../../actions/customer-support/quality-assurance/AgentsActions';
import { getUser } from '../../../../../../actions/shared/user/UserActions';
import PersonnelSendReport from './PersonnelSendReport';
import Logout from '../../../../shared/auth/Logout';
import ScoreDetails from '../../../../shared/customer-support/quality-assurance/ScoreDetails';

class PersonnelQualityAssurance extends Component {
  static propTypes = {
    getEmployeesInCustomerSupport: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    getScore: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      score: [],
      user: {},
      details: {},
      fromDate: '',
      to: '',
      ext: '',
    };
  }

  componentDidMount() {
    this.props.getEmployeesInCustomerSupport();
    this.props.getUser();
  }

  getScore = async (id, name) => {
    // to -do
    // search with date
    const data = {};
    this.props.getScore(id, name, data);
  };

  getScoreDetails = (feedback) =>
    this.setState({
      details: feedback,
    });

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  render() {
    const { profile, score } = this.props;
    const { firstName, lastName, extension, id } = profile;
    const { details, fromDate, to } = this.state;

    const scoreBody = score ? (
      score.map((feedback) => (
        <tr>
          <td>{moment(feedback.created_at).format('DD-MM-YYYY')}</td>
          <td>{feedback.greetings + feedback.knowledge + feedback.resolution}</td>
          {/* <td>Needs to improve on the welcome messages</td> */}
          <td onClick={() => this.getScoreDetails(feedback)}>
            <div className="view1 ml-2 img-pointer" data-target="#score-details" data-toggle="modal">
              <img src={view} alt="" />
            </div>
            <ScoreDetails details={details} />
          </td>
        </tr>
      ))
    ) : (
      <small>no feedback</small>
    );

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <CustomerSupportSideBar />

              <h3 className="col-md-5 overview-part text-nowrap">Quality Assurance</h3>

              <div className="d-flex profile-mail">
                <Link
                  to="/sales/mail-blast"
                  className="d-none d-md-flex"
                  style={{ color: 'gray', textDecoration: 'none' }}
                >
                  <img src={mailblast} alt="" className="logout mr-1" />
                  <span>
                    <h6 className="m-2">
                      <strong>MailBlast</strong>
                    </h6>
                  </span>
                </Link>

                <h6 className="mr-0 d-flex">
                  <i className="small mr-2 d-none d-md-block">{firstName}</i>
                  <Logout />
                </h6>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              {/* <div className="col-lg-12 col-md-12 col-sm-12 mt-5 d-flex flex-wrap"> */}
              <div className="col-12 mt-5 d-flex m-0 p-0">
                <div className="d-flex mt-5 ml-3">
                  <div>
                    <button
                      className="btn btn-green mt-2"
                      type="submit"
                      data-toggle="modal"
                      data-target="#send-report"
                      style={{ backgroundColor: '#17c2ae' }}
                    >
                      Send Report
                    </button>
                  </div>
                  <PersonnelSendReport />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 p-0 page-title mt-4 mb-3 d-flex">
                <div className="col-lg-6 p-0">
                  <div className="main-content col-12">
                    <h6 className="mb-1">
                      <strong>{firstName}</strong>
                    </h6>
                    <div className="card x-panel m-0 p-2">
                      <div className="table-responsive content">
                        <table className="table copy-font">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>First Name</th>
                              <th>Last Name</th>
                              <th>Extension</th>
                              <th>View Rating</th>
                            </tr>
                          </thead>
                          <tbody>
                            {firstName ? (
                              <tr>
                                <td>1</td>
                                <td>{firstName}</td>
                                <td>{lastName}</td>
                                <td>{extension}</td>
                                <td onClick={() => this.getScore(id, firstName)}>
                                  <div className="view1 ml-2 img-pointer">
                                    <img src={view} alt="" />
                                  </div>
                                </td>
                              </tr>
                            ) : null}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                {score.length > 0 ? (
                  <div className="col-6 p-0">
                    <div className="main-content col-12">
                      <h6 className="mb-1">
                        <strong>{firstName}</strong>
                      </h6>
                      <div className="card x-panel m-0 p-2">
                        <div className="table-responsive content">
                          <table className="table copy-font">
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Score</th>
                                {/* <th>Feedback</th> */}
                                <th>Details</th>
                              </tr>
                            </thead>
                            <tbody>{scoreBody}</tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <ToastContainer />
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  agents: state.employeesByDepartment.employeesByDepartment,
  profile: state.user.user,
  score: state.qa.score,
});

export default connect(
  mapStateToProps,
  { getEmployeesInCustomerSupport, getUser, getScore },
)(PersonnelQualityAssurance);
