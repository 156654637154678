import React, { useContext } from 'react';
import AppContext from '../../../../../../context/shared/AppContext';
import DevicesTable from './table/DevicesTable';
import SearchDevicesTable from './table/device/SearchDevicesTable';
import SearchDevices from './SearchDevices';

const DevicesBody = () => {
  const { searchStatus } = useContext(AppContext);
  return (
    <div className="container">
      <div className="row mt-5">
        <div className="mt-4 col-lg-12 col-md-12 col-sm-12">
          <div className="container">
            <SearchDevices />
            {searchStatus ? <SearchDevicesTable /> : <DevicesTable />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DevicesBody;
