import React from 'react';
import PropTypes from 'prop-types';
import NocNavBar from '../../../../layouts/noc/NocNavBar';
import IncidentReportsBody from './IncidentReportsBody';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import useCheckTwoDepartments from '../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';
import { NOC, SUPER_ADMIN } from '../../../../../utils/departments/helpers';

const IncidentReport = ({ history }) => {
  useCheckTwoDepartments(`${NOC}`, `${SUPER_ADMIN}`, history);
  const { profile } = useUser();
  const { firstName } = profile;

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <NocNavBar currentPage="Incident Report" position={firstName} />
        <IncidentReportsBody />
      </main>
    </div>
  );
};

IncidentReportsBody.propTypes = {
  history: PropTypes.shape(),
};

export default IncidentReport;
