/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

const TextArea = ({ name, value, onChange, placeholder }) => {
  return (
    <textarea
      className="form-control"
      name={name}
      value={value}
      onChange={onChange}
      rows="5"
      cols="50"
      placeholder={placeholder}
    />
  );
};

TextArea.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.string,
  placeholder: PropTypes.func,
};
export default TextArea;
