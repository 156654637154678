/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import helpers from '../../../../../utils/helpers';
import edit from '../../../../../assets/img/icons/edit.png';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../../utils/loader/loadingHelper';
import AccountsLteAddUser from './AccountsLteAddUser';

const AccountsAddLteTable = ({ lists, isLoading }) => {
  const colSpan = '8';
  const [request, setRequest] = useState({
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    id: '',
    created_at: '',
  });
  const getRequest = (item) => setRequest(item);

  const tableData =
    lists && lists.length ? (
      lists.map((list, i) => {
        return (
          <tr className="" key={i}>
            <td>{i + 1}</td>
            <td className="text-nowrap">{`${list.firstName}`}</td>
            <td className="text-nowrap">{`${list.lastName}`}</td>
            <td>{list.phoneNumber}</td>
            <td>{list.email}</td>
            <td>{helpers.formatDateAndTime(list.created_at)}</td>

            <td>
              <div className="d-flex">
                <button
                  type="submit"
                  className="mx-1 noBorder"
                  data-toggle="modal"
                  data-placement="left"
                  data-target="#addLTE"
                  onClick={() => getRequest(list)}
                >
                  <span className="view">
                    <img src={edit} alt="" data-toggle="tooltip" data-placement="left" title="Edit" />
                  </span>
                </button>
              </div>
            </td>
            <AccountsLteAddUser request={request} />
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan={colSpan} text="There are no records" />
    );

  return (
    <div className="table-responsive content col-12">
      <table className="table table-striped">
        <thead>
          <tr className="">
            <th> S/N</th>
            <th>FirstName</th>
            <th>LastName</th>
            <th className="text-nowrap">Phone Number</th>
            <th className="text-nowrap">Email</th>
            <th className="text-nowrap">Date</th>
            <th className="text-nowrap">Action</th>
          </tr>
        </thead>
        <tbody>{isLoading ? <TizetiGIFLogoTable colSpan={colSpan} /> : tableData}</tbody>
      </table>
    </div>
  );
};

export default AccountsAddLteTable;
