import React, { Component } from 'react';
import toastr from 'toastr';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { NEW_PRODUCTS } from '../../../../../utils/config';
import mail from '../../../../../assets/img/icons/mailblast.svg';
import NewProductSideBar from '../../../../layouts/new-products/NewProductsSideBar';
import NewProductsLogo from '../../../../layouts/new-products/NewProductsLogo';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import { getUser } from '../../../../../actions/shared/user/UserActions';
import { getLoggedInUser } from '../../../../../actions/user/UserActions';
import Extensions from '../../../shared/customers/extensions/Extensions';
import Logout from '../../../shared/auth/Logout';

class NewProductsCustomerExtension extends Component {
  componentDidMount() {
    this.props.getUser();
    this.checkDepartment();
    this.props.getLoggedInUser();
  }

  static propTypes = {
    getUser: PropTypes.func.isRequired,
    getLoggedInUser: PropTypes.func.isRequired,
  };

  componentWillReceiveProps(nextProps) {
    this.checkDepartment();
  }

  checkDepartment = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.department_id !== `${NEW_PRODUCTS}`) {
        this.props.history.push('/');

        sessionStorage.setItem('token', '');
        sessionStorage.clear();

        toastr.info('You are not allowed to view this page');
      }
    } catch (e) {
      console.log(e.response);
    }
  };

  render() {
    const { role_id } = this.props.role;
    const { firstName } = this.props.user;

    return (
      <div className="main-wrapper">
        <header className="topbar shade bg-white fixed-top">
          <nav className="navbar navbar-expand-lg navbar-light top-navbar fixed-top">
            <Link className="navbar-brand" to="/new-products">
              <img src={NewProductsLogo} alt="homepage" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse mt-3" id="navbarText">
              <ul className="navbar-nav">
                <li className="nav-item active">
                  <Link className="nav-link" to="#">
                    <h3>
                      Geniatech Customers Extensions Logs<span className="sr-only">(current)</span>
                    </h3>
                  </Link>
                </li>
              </ul>
              <span className="navbar-text">
                <div className="d-flex">
                  {Number(role_id) > 2 ? (
                    <Link
                      to="/new-products/mail-blast"
                      className="d-flex"
                      style={{ color: 'gray', textDecoration: 'none' }}
                    >
                      <img src={mail} className="logout mr-2 mt-0 mb-2" alt="" />
                      <span>
                        <h6 className="mt-1 mr-3">
                          <strong>MailBlast</strong>
                        </h6>
                      </span>
                    </Link>
                  ) : null}
                  <h6 className="mr-0 mt-1">
                    {firstName}
                    <Logout />
                  </h6>
                </div>
              </span>
            </div>
          </nav>
        </header>

        <div className="all-sides col-lg-12 col-md-12 col-sm-12">
          <NewProductSideBar />
          <Extensions />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  role: state.auth.auth,
});

export default connect(
  mapStateToProps,
  { getUser, getLoggedInUser },
)(NewProductsCustomerExtension);
