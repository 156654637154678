/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/prop-types */
import React from 'react';
import caution from '../../../../assets/img/icons/Caution.png';
import { useRetrievals } from '../../../../hooks/shared/retrievals/useRetrievals';
import { ACCOUNTS, PROCUREMENT } from '../../../../utils/departments/helpers';

const tabIndex = -1;
const ConfirmDeleteRetrieval = ({ one }) => {
  const { deleteRetrieval, deleteProcurementRetrieval } = useRetrievals();

  const confirmDelete = (e) => {
    e.preventDefault();
    if (
      sessionStorage.getItem('departmentId') === `${PROCUREMENT}` ||
      sessionStorage.getItem('departmentId') === `${ACCOUNTS}`
    )
      return deleteProcurementRetrieval(one.id);
    return deleteRetrieval(one.id);
  };

  return (
    <div
      className="modal fade"
      id="confirm_delete"
      tabIndex={tabIndex}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content mt-5 py-4">
          <div className="text-center ">
            <img src={caution} alt="" width="60px" />
          </div>
          <div className="modal-body p-3">
            <div className="row">
              <div className="col">
                <form className="form-horizontal text-center" onSubmit={confirmDelete}>
                  <div className="col">
                    <p>
                      <h5>
                        Confirm Delete of <strong>{`${one.mac || one.radio_mac_id}`}</strong> for
                        <br />
                        <strong>{one.name || one.customer_name}</strong>?
                      </h5>
                    </p>
                  </div>
                  <div className="mt-5">
                    <button type="button" className="btn btn-secondary mr-2" data-dismiss="modal">
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-danger" onClick={confirmDelete}>
                      Delete
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeleteRetrieval;
