/* eslint-disable import/prefer-default-export */
import { useReducer, useState } from 'react';
import qs from 'qs';
import axios from 'axios';
import toastr from 'toastr';
import * as load from '../../../constants/types/shared/LoadingTypes';
import * as types from '../../../constants/types/sales/customers/RelocationTypes';
import * as url from '../../../services/api/sales/customers/RelocationURL';
import * as typed from '../../../constants/types/sales/installations/InstallationsTypes';
import * as link from '../../../services/api/shared/installations/InstallationsUrl';
import { relocationReducer } from '../../../reducers/sales/customers/relocationReducer';
import { GET_ALL_INSTALLATIONS } from '../../../constants/types/sales/installations/InstallationsTypes';

export const useRelocation = () => {
  const [
    {
      lists,
      items,
      rows,
      count,
      pages,
      isLoading,
      loader,
      loads,
      tracker,
      monthlyCount,
      completedInstalls,
      completedCount,
      installationCount,
      completedInstallPages,
    },
    dispatch,
  ] = useReducer(relocationReducer, {
    lists: [],
    rows: [],
    items: [],
    completedInstalls: [],
    installationCount: [],
    completedCount: 0,
    completedInstallPages: 0,
    count: 1,
    pages: 1,
    monthlyCount: 0,
    isLoading: false,
    loader: false,
    loads: false,
    tracker: false,
  });

  // const [page] = useState(1);

  const addRelocationRequest = async (data) => {
    dispatch({ type: load.LOADING_STARTS });
    const config = {
      method: 'post',
      url: `${url.ADD_RELOCATION_REQUEST_URL}`,
      data: qs.stringify(data),
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response.data.status === 'success') {
          toastr.success('Added Successfully');
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else dispatch({ type: load.LOADING_STOPS });
      })
      .catch(() => {
        toastr.info('Something went wrong!');
        dispatch({ type: load.LOADING_STOPS });
      });
  };

  const getRelocationRequest = async (selectedPage) => {
    dispatch({ type: load.LOADING_STARTS });
    const config = {
      method: 'post',
      url: `${url.GET_RELOCATION_REQUEST_URL}/${selectedPage}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response.data) {
          dispatch({
            type: types.GET_RELOCATION_REQUEST,
            payload: {
              lists: response.data.rows,
              count: response.data.count,
              pages: response.data.count / 20,
            },
          });
        }
      })
      .catch(() => {
        toastr.info('Something went wrong!');
        dispatch({ type: load.LOADING_STOPS });
      });
  };

  const searchInstallations = async (data) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(`${link.SEARCH_INSTALLATIONS_URL}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        console.log(response.data);
        dispatch({
          type: typed.GET_ALL_INSTALLATIONS,
          payload: {
            items: response.data.list,
            // rows: response.data.list,
            count: response.data.count,
          },
        });
      }
    } catch (e) {
      toastr.info(e.response.data.message + 'hii');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const searchCompletedInstallations = async (page, data) => {
    try {
      const response = await axios.post(`${link.SEARCH_INSTALLATION_URL}/${page}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        console.log(response.data);
        dispatch({
          type: typed.GET_COMPLETED_INSTALLATIONS,
          payload: {
            completedInstalls: response.data.list,
            completedCount: response.data.count,
            completedInstallPages: response.data.pages,
          },
        });
      }
    } catch (e) {
      toastr.info(e.response.data.message + 'hii');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const getInstallationCounter = async (data) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(`${link.GET_INSTALLATION_COUNTER}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        console.log(response.data);
        dispatch({
          type: typed.GET_INSTALLATION_COUNTER,
          payload: {
            installationCount: response.data,
          },
        });
      }
    } catch (e) {
      toastr.info(e.response.data.message);
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const searchfailedInstallations = async (data) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(`${link.SEARCH_INSTALLATIONS_URL}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        console.log(response.data);
        dispatch({
          type: typed.GET_FAILED_INSTALLATIONS,
          payload: {
            // items: response.data.list,
            rows: response.data.list,
            count: response.data.count,
          },
        });
      }
    } catch (e) {
      toastr.info(e.response.data.message + 'hii');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const searchUsingKeyword = async (selectedPage, data) => {
    dispatch({ type: load.LOADING_STARTS });
    const config = {
      method: 'post',
      url: `${url.GET_RELOCATION_REQUEST_URL}/${selectedPage}`,
      data: qs.stringify(data),
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response.data) {
          dispatch({
            type: types.GET_RELOCATION_REQUEST,
            payload: {
              lists: response.data.rows,
              count: response.data.count,
              monthlyCount: response.data.count,
              pages: response.data.pages,
            },
          });
        }
      })
      .catch(() => {
        toastr.info('Something went wrong!');
        dispatch({ type: load.LOADING_STOPS });
      });
  };

  const editRelocationRequest = async (id, data) => {
    const config = {
      method: 'patch',
      url: `${url.EDIT_RELOCATION_REQUEST_URL}/${id}`,
      data: qs.stringify(data),
      headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
    };

    await axios(config)
      .then((response) => {
        if (response.data[0] === 1) {
          toastr.success('Edit Successful');
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((error) => {
        if (error) {
          toastr.info('Something went wrong!');
          dispatch({ type: load.LOADING_STOPS });
        }
      });
  };

  // useEffect(() => {
  //   getRelocationRequest(page);
  // }, []);

  return {
    lists,
    items,
    rows,
    pages,
    count,
    tracker,
    isLoading,
    loads,
    loader,
    monthlyCount,
    completedInstalls,
    completedCount,
    installationCount,
    completedInstallPages,
    searchUsingKeyword,
    searchInstallations,
    searchCompletedInstallations,
    getInstallationCounter,
    getRelocationRequest,
    searchfailedInstallations,
    editRelocationRequest,
    addRelocationRequest,
  };
};
