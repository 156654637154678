import React from 'react';
import {Link} from "react-router-dom";

const TizetiLogo = () => (

    <Link className="navbar-brand" to={"#"} id="control">
        <img src="https://res.cloudinary.com/teewhy/image/upload/v1545234940/Group_6.png"
             alt="homepage"
             style={{width:'70%'}}
             data-toggle="collapse"
             data-target="#demo-profile"
             className="hotspot-profile"/>

        <ul id="demo-profile"
            className="collapse">
            <li className="copy-font">
            <Link className="navbar-brand"
                  to={"/wifi-call"}>
                <img src="https://res.cloudinary.com/teewhy/image/upload/v1545234921/wifi-without-dropdown.fw.png"
                     alt="homepage"
                     style={{width:'90%'}}/>
            </Link>
            </li>
        </ul>
        </Link>
);

export default TizetiLogo;