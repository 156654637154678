import React from 'react';
import SearchAndAddKpi from './SearchAndAddKpi';
import KpiMonitorTable from './KpiMonitorTable';
import useKpiMonitor from './hooks/useKpiMonitor';
import KpisMonitorTable from './KpisMonitorTable';

const ManagerAndAboveKpiBody = () => {
  const { kpis, isLoading } = useKpiMonitor();
  return (
    <div className="row">
      <SearchAndAddKpi />
      <KpisMonitorTable isLoading={isLoading} kpis={kpis && kpis.logs && kpis.logs.length > 0 ? kpis.logs : []} />
    </div>
  );
};

export default ManagerAndAboveKpiBody;
