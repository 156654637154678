import React from 'react';
import CustomersTable from './CustomersTable';

const ActiveLteCustomersBody = () => {
  return (
    <div className="container">
      <div className="row mt-5">
        <div className="mt-4 col-lg-12 col-md-12 col-sm-12">
          <div className="container-fluid">
            <div className="row">
              <div className="card x-panel mt-3">
                <div className="table-responsive content">
                  <table className="table copy-font">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Expiry Date</th>
                        <th>Installation Date</th>
                        <th>Username</th>
                      </tr>
                    </thead>
                    <CustomersTable />
                  </table>
                </div>
              </div>
            </div>
            {/* <div className="pagination pull-right"> */}
            {/*  <ReactPaginate */}
            {/*    previousLabel="previous" */}
            {/*    nextLabel="next" */}
            {/*    breakLabel="..." */}
            {/*    breakClassName="break-me" */}
            {/*    //  pageCount={pageCount} */}
            {/*    pageCount="2" */}
            {/*    marginPagesDisplayed={2} */}
            {/*    pageRangeDisplayed={3} */}
            {/*    //  onPageChange={this.handlePageClick} */}
            {/*    containerClassName="pagination" */}
            {/*    subContainerClassName="pages pagination" */}
            {/*    activeClassName="active" */}
            {/*  /> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActiveLteCustomersBody;
