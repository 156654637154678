import React from 'react';
import { useUser } from '../../../../../../hooks/shared/user/useUser';
import CustomerSupportNavBar from '../../../../../layouts/customer-support/CustomerSupportNavBar';
import PaymentHistoryBody from '../../../../shared/customers/payments/PaymentHistoryBody';

const CustomerSupportPaymentHistory = () => {
  const { user, profile } = useUser();
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <CustomerSupportNavBar currentPage="Payment History" roleID={user.role_id} position={profile.firstName} />
        <PaymentHistoryBody />
      </main>
    </div>
  );
};

export default CustomerSupportPaymentHistory;
