import axios from 'axios';
import toastr from 'toastr';

export const tizeti_asset_active_inactive_users = async (initialState) => {
  try {
    const url = 'https://legacy.tizeti.com/geniatechapi/index.php';

    const formData = new URLSearchParams(initialState).toString();
    const res = await axios.post(url, formData);
    if (res.data) {
      // console.log(res.data);
      return res.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error('error fetching data');
  }
};
