import { API_URL } from '../../../../utils/config';

export const GET_ALL_REQUISITIONS_URL = `https://api.tizeti.com/api/v1/requisition/fetch/requisation/requests`;
export const APPROVE_OR_REJECT_REQUISITION_URL = `https://api.tizeti.com/api/v1/requisition/fetch/requisition/attended`;
export const GET_ACCOUNTS_CASH_REQUISITIONS_URL = `${API_URL}/requisition2/accountCash`;
export const GET_ACCOUNTS_CHEQUE_REQUISITIONS_URL = `${API_URL}/requisition2/accountCheque`;
export const GET_REQUISITIONS_BY_DEPARTMENTS_URL = `${API_URL}/requisition2/department`;
export const ACCOUNT_MANAGER_UPDATE_REQUSITION_STATUS_URL = `${API_URL}/requisition2/accountApproval`;
export const ACCOUNT_MANAGER_BULK_UPDATE_REQUSITION_STATUS_URL = `${API_URL}/requisition2/bulkaccountapproval`;
export const GET_LEADS_REQUISITIONS_URL = `${API_URL}/requisition2/paymentlist/1`;
export const UPDATE_REQUISITION_STATUS_URL = `${API_URL}/requisition2/update_payment_status`;
export const GET_ACCOUNTS_REQUSITION_BY_STATUS_URL = `${API_URL}/requisition2/account_departmental_filter`;
export const PAGINATE_LEADS_REQUISITIONS_URL = `${API_URL}/requisition2/paymentlist`;
export const ACCOUNTS_PURCHASE_ORDER_URL = `${API_URL}/requisition2/account_purchase`;
export const SEARCH_REQUISIITONS_URL = `${API_URL}/requisition2/search/searchbypurpose`;
export const SEARCH_REQUISIITONS_BY_DATE_URL = `${API_URL}/requisition2`;
export const SEARCH_REQUISIITONS_BY_SEVEN_AND_THIRTY_DAYS_URL = `${API_URL}/requisition2/range`;
export const SEARCH_REQUISIITONS_AFTER_THIRTY_DAYS_URL = `${API_URL}/requisition2`;
