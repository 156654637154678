import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { API_URL } from '../../../../../../utils/config';

class EditPayslip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      basic_salary: '',
      arrears: '',
      housing_allowances: '',
      lunch_subsidy: '',
      id: '',
      editing: false,
    };

    this.onChange = this.onChange.bind(this);
    this.editPayslip = this.editPayslip.bind(this);
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  editPayslip = async (e) => {
    e.preventDefault();
    this.setState({ editing: true });
    const URL = `${API_URL}/payslip/${this.state.id}`;
    axios
      .patch(URL, this.state, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        }
      })
      .then((response) => {
        if (response.data[0] === 1) {
          this.setState({ editing: false });
          toast.success('Payslip Edited');
        }
      })
      .catch(() => {
        toast.error('Unable to edit payslip');
      });
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      basic_salary: nextProps.payslip.basic_salary,
      arrears: nextProps.payslip.arrears,
      housing_allowances: nextProps.payslip.housing_allowances,
      lunch_subsidy: nextProps.payslip.lunch_subsidy,
      id: nextProps.payslip.id,
    });
  }

  render() {
    const { basic_salary, arrears, housing_allowances, lunch_subsidy, editing } = this.state;
    return (
      <div
        id="editPayslip"
        className="modal fade in"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center" id="myModalLabel">
                Edit Payslip
              </h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
            </div>
            <div className="modal-body">
              <form className="form-horizontal" onSubmit={this.editPayslip}>
                <div className="form-group">
                  <div className="col-12 d-flex p-0 mb-3">
                    <div className="col-6">
                      <label className="copy-font m-0 p-0">Basic Salary</label>
                      <input
                        type="text"
                        className="form-control"
                        value={basic_salary}
                        name="basic_salary"
                        onChange={this.onChange}
                      />
                    </div>

                    <div className="col-6">
                      <label className="copy-font m-0 p-0">Arrears</label>
                      <input
                        type="text"
                        className="form-control"
                        value={arrears}
                        name="arrears"
                        onChange={this.onChange}
                      />
                    </div>
                  </div>

                  <div className="col-12 d-flex p-0 mb-3 mr-3">
                    <div className="col-6">
                      <label className="copy-font m-0 p-0">Housing Allowances</label>
                      <input
                        type="text"
                        className="form-control"
                        value={housing_allowances}
                        name="housing_allowances"
                        onChange={this.onChange}
                      />
                    </div>

                    <div className="col-6">
                      <label className="copy-font m-0 p-0">Lunch Subsidy</label>
                      <input
                        type="text"
                        className="form-control"
                        value={lunch_subsidy}
                        name="lunch_subsidy"
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="text-center mt-5">
                  {editing ? (
                    <button type="button" className="btn btn-second" disabled={editing}>
                      Editing...
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-second">
                      Edit Payslip
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditPayslip;
