import React, { useContext } from 'react';
import SearchCustomersTracker from './SearchCustomersTracker';
import BarChart from '../../components/BarChart';
import AppContext from '../../../../../context/shared/AppContext';
import DownloadTrackerData from './DownloadTrackerData';

const Tracker = () => {
  const { options, data } = useContext(AppContext);
  return (
    <div className="row my-5">
      <div className="col-12 my-5">
        <SearchCustomersTracker />
        <BarChart data={data} options={options} />
        <DownloadTrackerData />
      </div>
    </div>
  );
};

export default Tracker;
