/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import AdminSideBar from '../../../../../../layouts/HotspotAdminSideBar/AdminSideBar';
import HotspotAdminLogo from '../../../../../../layouts/HotspotAdminLogo/HotspotAdminLogo';
import Logout from '../../../../../shared/auth/Logout';
import TotalRetailers from './TotalRetailers';
import TotalRevenue from './TotalRevenue';
import TotalLocations from './TotalLocations';
import TotalCustomers from './TotalCustomers';

const HotSpotAdminOverview = () => {
  return (
    <div className="container-fluid">
      <header className="topbar shade bg-white fixed-top">
        <nav className="navbar navbar-expand-lg navbar-light top-navbar fixed-top" id="nav">
          <HotspotAdminLogo />
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse mt-3 wifii" id="navbarText">
            <ul className="navbar-nav">
              <li className="nav-item active">
                <Link className="nav-link" to="#">
                  <h3>
                    Overview
                    <span className="sr-only">(current)</span>
                  </h3>
                </Link>
              </li>
            </ul>
            <span className="navbar-text">
              <h6 className="admin">
                Admin
                <Logout />
              </h6>
            </span>
          </div>
        </nav>
      </header>

      <div className="all-sides col-lg-12 col-md-12 col-sm-12">
        <AdminSideBar />
        <div className="page-wrapper col-lg-10 col-md-9 col-sm-9">
          <div className="container-fluid">
            <div className="col-lg-12 page-title mb-3 p-0" />
            <div className="row" id="wifi-row">
              <TotalRetailers />

              <TotalRevenue />

              <TotalLocations />

              <TotalCustomers />
            </div>

            {/* <div className="card-group mt-1 d-flex" id="group-card"> */}
            {/*    <div className="col-12 m-0 p-0"> */}
            {/*        <h5><strong>Recent Signups</strong> */}
            {/*            <br/> */}
            {/*        </h5> */}

            {/*        <div className="card o-income card-body"> */}
            {/*            <table className="table"> */}
            {/*                <thead> */}
            {/*                <tr className="copy-font"> */}
            {/*                    <th scope="col">#</th> */}
            {/*                    <th scope="col">Name</th> */}
            {/*                    <th scope="col">Account ID</th> */}
            {/*                    <th scope="col">Phone Number</th> */}
            {/*                    <th scope="col">Email Address</th> */}
            {/*                    <th scope="col">Business Name</th> */}
            {/*                    <th scope="col">Address</th> */}
            {/*                </tr> */}
            {/*                </thead> */}

            {/*                <tbody> */}
            {/*                {<TableRows */}
            {/*                  retailers = {this.state.retailerArray} />} */}
            {/*                </tbody> */}
            {/*            </table> */}
            {/*        </div> */}

            {/*        <div className="page col-lg-12 col-md-12 col-sm-12 d-flex"> */}
            {/*            <Pagination */}
            {/*              hideDisabled */}
            {/*              activePage={this.state.activePage} */}
            {/*              itemsCountPerPage={this.state.itemsCountPerPage} */}
            {/*              totalItemsCount={this.state.totalItemsCount} */}
            {/*              pageRangeDisplayed={this.state.pageRangeDisplayed} */}
            {/*              onChange={this.handlePageChange} */}
            {/*              itemClass='page-item' */}
            {/*              hideFirstLastPages */}
            {/*              activeLinkClass="active"  /> */}
            {/*        </div> */}

            {/*    </div> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotSpotAdminOverview;
