/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import NocNavBar from '../../../../layouts/noc/NocNavBar';
import useCheckTwoDepartments from '../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';
import { NOC, SUPER_ADMIN } from '../../../../../utils/departments/helpers';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import FibreLinks from '../../../shared/noc/fibre-links/FibreLinks';

const NocFibreLinks = ({ history }) => {
  useCheckTwoDepartments(`${NOC}`, `${SUPER_ADMIN}`, history);
  const { profile } = useUser();
  const { firstName } = profile;
  return (
    <main className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <NocNavBar currentPage="Fibre Links" position={firstName} />
        <FibreLinks />
      </main>
    </main>
  );
};

NocFibreLinks.propTypes = {
  history: PropTypes.shape(),
};
export default NocFibreLinks;
