/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import useXWFCustomers from '../../../../../hooks/express-wifi/customers/useXWFCustomers';
import SearchXWFCustomers from './SearchXWFCustomers';
import XWFCustomersTable from './XWFCustomersTable';

const XWFCustomerBody = () => {
  const {
    customers,
    pages,
    isLoading,
    handlePageClick,
    getXWFCustomers,
    searchCustomers,
    details,
    onOptionChange,
  } = useXWFCustomers();

  const page = 1;

  useEffect(() => {
    getXWFCustomers(page);
  }, []);

  return (
    <div className="after-summary">
      <SearchXWFCustomers searchCustomers={searchCustomers} details={details} onOptionChange={onOptionChange} />
      <XWFCustomersTable customers={customers} isLoading={isLoading} pages={pages} handlePageClick={handlePageClick} />
    </div>
  );
};

export default XWFCustomerBody;
