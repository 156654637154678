import React from 'react';
import NocNavBar from '../../../../layouts/noc/NocNavBar';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import SolarCount from '../../../shared/noc/solar-count/SolarCount';

const NocSolarCount = () => {
  const { profile } = useUser();
  const { firstName } = profile;

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <NocNavBar currentPage="Solar Count" position={firstName} />
        <SolarCount />
      </main>
    </div>
  );
};

export default NocSolarCount;
