/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
// import { NoItemRow } from '../../../../../utils/loader/loadingHelper';

const BusinessDevelopmentDedicatedConnectedUsersTable = ({ items, isLoading }) => {
  //   const colSpan = '8';
  const tableData =
    items && items.length ? (
      items.map((item, i) => {
        return (
          <tr className="text-nowrap" key={i}>
            <td>{i + 1}</td>
            <td>{item.basestation}</td>
            <td>{item.connected_dedicated_users}</td>
            <td>{item.status}</td>
          </tr>
        );
      })
    ) : (
      <>
        <tr className="text-nowrap">
          <td> 1 </td>
          <td> lekki_gardens</td>
          <td> 5 </td>
          <td>online</td>{' '}
        </tr>
        <tr className="text-nowrap">
          <td> 2 </td>
          <td> yaba</td>
          <td> 10 </td>
          <td>online</td>
        </tr>
      </>
      //   <NoItemRow colSpan={colSpan} text="There are no records" />
    );
  return (
    <div className="card x-panel mt-2 px-0">
      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <thead>
            <tr className="">
              <th>#</th>
              <th>Basestation</th>
              <th>Number of Connected Dedicated Users</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td className="text-center">
                  <img src={loader} alt="loading gif" />
                </td>
              </tr>
            ) : (
              tableData
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BusinessDevelopmentDedicatedConnectedUsersTable;
