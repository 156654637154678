/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useState } from 'react';
import moment from 'moment';
import { ToastContainer } from 'react-toastify';
import ExecutiveRequisitionTableHead from './ExecutiveRequisitionTableHead';
import view from '../../../../../../assets/img/icons/view.png';
import ViewReceipt from '../../shared/ViewReceipt';
import CommonRequisitionDetail from '../../shared/CommonRequistionDetails';
import {
  ACCOUNTS,
  ADMIN,
  ADMIN_DEPARTMENT,
  CUSTOMER_SUPPORT,
  EXPRESS_WIFI,
  NEW_PRODUCTS,
  NOC,
  OPERATIONS,
  PROCUREMENT,
  RESEARCH_AND_DEVELOPMENT,
  SALES,
  TECHNICAL_SUPPORT,
} from '../../../../../../utils/departments/helpers';
import RequisitionContext from '../../../../../../context/shared/requisitions/RequsitionContext';
import RejectRequisition from '../../shared/RejectRequisition';
import { ASSET_TEAM, BUSINESS_DEVELOPMENT, PARTNERSHIP } from '../../../../../../utils/config';

const ExecutivesRequistion = () => {
  const [requisit, setRequisit] = useState({});

  const { executiveRequisitions, isFetching, giveExecutiveApproval } = useContext(RequisitionContext);
  // const { executiveRequisitions, isFetching, giveExecutiveApproval } = value;
  const { rows } = executiveRequisitions;
  const getRequsition = (req) => {
    setRequisit(req);
  };

  const department = new Map([
    [`${ACCOUNTS}`, 'Accounts'],
    [`${ADMIN}`, 'ADMIN'],
    [`${ADMIN_DEPARTMENT}`, 'ADMIN_DEPARTMENT'],
    [`${CUSTOMER_SUPPORT}`, 'CUSTOMER SUPPORT'],
    [`${EXPRESS_WIFI}`, 'EXPRESS WIFI'],
    [`${NEW_PRODUCTS}`, 'NEW PRODUCTS'],
    [`${NOC}`, 'NOC'],
    [`${OPERATIONS}`, 'OPERATIONS'],
    [`${PROCUREMENT}`, 'PROCUREMENT'],
    [`${RESEARCH_AND_DEVELOPMENT}`, 'RESEARCH AND DEVELOPMENT'],
    [`${SALES}`, 'SALES'],
    [`${BUSINESS_DEVELOPMENT}`, 'BUSINESS DEVELOPMENT'],
    [`${ASSET_TEAM}`, 'ASSET TEAM'],
    [`${PARTNERSHIP}`, 'PARTNERSHIP'],
    [`${TECHNICAL_SUPPORT}`, 'TECHNICAL SUPPORT'],
  ]);

  const getDepartment = (type) => <strong>{department.get(type)}</strong> || "N/A";
  const requsitionList = rows ? (
    rows.map((requsition, i) => {
      return (
        <tr>
          <td>{i + 1}</td>
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          <td>N{requsition.amount_in_digits} </td>
          <td>{requsition.amount_in_words}</td>
          <td>{requsition.account_approval}</td>
          <td>{requsition.manager_approval}</td>
          <td>{requsition.c_level_approval === 'yes' ? 'Approved' : null}</td>
          <td>{requsition.ceo_or_coo_approval}</td>
          <td>{requsition.in_favour_of}</td>
          <td>{getDepartment(requsition.department_id)}</td>
          <td>{requsition.requires_c_level_approval === true ? 'Yes' : 'No'}</td>
          <td>{requsition.requires_ceo_or_coo_approval === true ? 'Yes' : 'No'}</td>

          <td>
            <small onClick={() => giveExecutiveApproval(requsition.id)} style={{ cursor: 'default' }}>
              <button className="btn btn-green">Approve</button>
            </small>
          </td>
          <td>
            <small style={{ cursor: 'default' }}>
              {/* <small onClick={() => giveExecutiveDisApproval(requsition.id)} style={{ cursor: 'default' }}> */}
              <button
                className="btn btn-danger"
                data-toggle="modal"
                data-target="#reject"
                onClick={() => getRequsition(requsition)}
              >
                Reject
              </button>
              <RejectRequisition requisit={requisit} />
            </small>
          </td>
          {requsition.created_at ? <td>{moment(requsition.created_at).format('MM/DD/YYYY')}</td> : 'N/A'}

          <td>{requsition.purpose}</td>

          <td>
            <div className="mr-2 ml-2">
              <button
                data-target="#viewReceipt"
                data-toggle="modal"
                type="submit"
                onClick={() => getRequsition(requsition)}
                style={{
                  border: '0',
                  backgroundColor: 'white',
                }}
              >
                <span className="view2">
                  <img src={view} alt="view" data-toggle="tooltip" data-placement="left" title="View" />
                </span>
              </button>
              <ViewReceipt requisit={requisit} />
            </div>
          </td>
          <td>
            <div className="mr-2 ml-2">
              <button
                data-target="#requisition"
                data-toggle="modal"
                type="submit"
                onClick={() => getRequsition(requsition)}
                style={{
                  border: '0',
                  backgroundColor: 'white',
                }}
              >
                <span className="view2">
                  <img src={view} alt="view" data-toggle="tooltip" data-placement="left" title="View" />
                </span>
              </button>
              <CommonRequisitionDetail requisit={requisit} />
            </div>
          </td>
        </tr>
      );
    })
  ) : (
    <p>No requisitions</p>
  );

  return (
    <div className="card x-panel logs">
      <div className="table-responsive content">
        <table className="table copy-font">
          <ExecutiveRequisitionTableHead />
          {isFetching ? <small>loading</small> : requsitionList}
        </table>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ExecutivesRequistion;
