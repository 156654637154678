/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import ViewPassword from '../../../shared/customers/passwords/ViewPassword';
import EditCustomerDetails from './EditCustomerDetails';
import PaymentsHistory from '../../../shared/customers/payments/PaymentsHistory';
import view from '../../../../../assets/img/icons/view.png';
import CustomerDetails from '../../../shared/customers/shared/CustomerDetails';
import { usePayments } from '../../../../../hooks/shared/customers/usePayments';
import { useCustomer } from '../../../../../hooks/shared/customers/useCustomers';

const ProcurementCustomersTableBody = ({ isLoading, customers }) => {
  const {
    getPassword,
    gettingPassword,
    password,
    getUserDetails,
    gettingDetails,
    details,
    itemprice,
    address,
    session,
  } = useCustomer();

  const [mac, setMac] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [customerRef, setCustomerRef] = useState('');

  const { payments, gettingPayments, getCustomerPayments } = usePayments();

  const getUserPassword = (customer) => {
    const data = {
      action: 'getCustomerPassword',
      username: customer.username,
    };
    getPassword(data);
  };

  const getUserPayments = (customer) => {
    const data = {
      macid: customer.username,
    };
    getCustomerPayments(data);
  };

  const getCustomerDetails = (customer) => {
    setName(customer.name);
    setEmail(customer.mail);
    setCustomerRef(customer.customer_ref);
    getUserDetails(customer.username);
  };

  const getUser = (customer) => {
    setMac(customer.username);
    setName(customer.name);
    setEmail(customer.mail);
    setPhone(customer.address);
  };

  const displayCustomers = () => {
    if (customers.length) {
      return customers.map((customer, i) => (
        <tr>
          <td>{i + 1}</td>
          <td>{customer.username}</td>
          <td nowrap="no-wrap">{customer.name}</td>
          <td>{customer.mail}</td>
          <td className="ml-2">
            <button
              data-toggle="modal"
              data-target="#view-password"
              className="ml-3"
              onClick={() => getUserPassword(customer)}
            >
              <i className="view1 fa fa-ellipsis-v" style={{ fontSize: '20px' }} />
            </button>
            {/* {get ? <h1>loading</h1> : <PaymentHistory usersTransactions={this.state.transactions}/>} */}
            {/* <PaymentHistory usersTransactions={this.state.transactions} get={get}/> */}
            <ViewPassword password={password} load={gettingPassword} />
          </td>
          <td>{customer.address}</td>

          {/* <td>{moment(customer.Expiration).format('Do MMMM YYYY')}</td> */}
          <td nowrap="no-wrap">{customer.value}</td>
          {/* {Number(sessionStorage.getItem('roleId')) > 1 ? ( */}
          {/*  <td */}
          {/*    className="text-center img-pointer" */}
          {/*    data-target="#edit-details" */}
          {/*    onClick={() => getUser(customer)} */}
          {/*    data-toggle="modal" */}
          {/*    style={{ width: '40%' }} */}
          {/*  > */}
          {/*    <span className="view2"> */}
          {/*      <img src={edit} alt="" data-toggle="tooltip" data-placement="top" title="Edit" /> */}
          {/*    </span> */}
          {/*  </td> */}
          {/* ) : null} */}
          <EditCustomerDetails username={mac} oldEmail={email} oldName={name} oldPhone={phone} />

          {/* {isNaN(Date.parse(customer.value)) ? <td>N/A</td> : <td>{customer.value}</td> } */}

          <td className="ml-2">
            {/* eslint-disable-next-line react/button-has-type */}
            {/* <button
              data-toggle="modal"
              data-target="#payment-history"
              className="ml-3"
              onClick={() => getUserPayments(customer)}
            >
              <i className="view1 fa fa-ellipsis-v" style={{ fontSize: '20px' }} />
            </button>

            <PaymentsHistory load={gettingPayments} transactions={payments} /> */}

            <button
              type="submit"
              style={{
                border: '0',
                backgroundColor: 'white',
                width: '0%',
              }}
              data-toggle="tooltip"
              data-placement="left"
              title="View"
              onClick={() => getCustomerDetails(customer)}
            >
              <span className="view1 ml-4">
                <img src={view} alt="" data-toggle="modal" data-target="#cus-details" />
              </span>
            </button>
            <CustomerDetails
              load={gettingDetails}
              user={customer}
              name={name}
              mail={email}
              customerRef={customerRef}
              itemprice={itemprice}
              address={address}
              details={details}
              session={session}
            />
          </td>
        </tr>
      ));
    }
    return null;
  };

  return <tbody>{isLoading ? <img src={loader} alt="loading gif" /> : displayCustomers()}</tbody>;
};

ProcurementCustomersTableBody.propTypes = {
  // eslint-disable-next-line react/require-default-props
  isLoading: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  customers: PropTypes.shape(),
};

export default ProcurementCustomersTableBody;
