/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import useCheckDepartment from '../../../../../hooks/shared/custom/check-department/useCheckDepartment';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import { EXPRESS_WIFI } from '../../../../../utils/departments/helpers';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import PurchaseOrderBody from '../../../shared/purchase-order/PurchaseOrderBody';
import ExpressWifiNavBar from '../../../../layouts/expresswifi/ExpressWifiNavBar';

const ExpressWifiPurchaseOrder = ({ history }) => {
  useCheckDepartment(`${LOGGED_IN_USER_URL}`, `${EXPRESS_WIFI}`, history);
  const {
    profile: { firstName, department_id },
  } = useUser();

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <ExpressWifiNavBar currentPage="Purchase Order" position={firstName} />
        <div className="container">
          <PurchaseOrderBody deptID={department_id} />
        </div>
      </main>
    </div>
  );
};

ExpressWifiPurchaseOrder.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default ExpressWifiPurchaseOrder;
