import React from 'react';
import useKpiMonitor from './hooks/useKpiMonitor';

const KpiPercentage = () => {
  const { kpiPercentage } = useKpiMonitor();
  return (
    <div className="col-lg-3 p-0 mr-auto">
      <div className="shadow-sm">
        <div className="change-chart">
          <svg viewBox="0 0 36 36" className="mt-3 change-color">
            <path
              className="circle-bg"
              d="M18 2.0845
                                              a 15.9155 15.9155 0 0 1 0 31.831
                                              a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <path
              className="circle"
              strokeDasharray={kpiPercentage}
              d="M18 2.0845
                                              a 15.9155 15.9155 0 0 1 0 31.831
                                              a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <text x="18" y="21" className="percentage">
              {kpiPercentage}%
            </text>
          </svg>
        </div>
        <div className="text-center pb-1" style={{ color: 'green' }}>
          <h5 className="copy-font">
            <strong>Percentage Completion</strong>
          </h5>
        </div>
      </div>
    </div>
  );
};

export default KpiPercentage;
