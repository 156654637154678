/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect } from 'react';
import { useReschedule } from '../../../../../hooks/sales/rescheduled-installations/useReschedule';
import RescheduledInstallationsTable from './RescheduledInstallationsTable';

const RescheduledInstallationsBody = () => {
  const { lists, pages, count, isLoading, onSubmit } = useReschedule();

  const [page] = useState(1);
  const [date, setDate] = useState({ reschedule_date: 'date_variable' });

  const handleDate = (e) => {
    const { name, value } = e.target;
    setDate({ ...date, [name]: value });
  };

  const currentPage = 1;
  const onFormSubmit = (e) => {
    e.preventDefault();
    onSubmit(currentPage);
  };

  useEffect(() => {
    onSubmit(page);
  }, []);

  return (
    <div className="col-12 p-0 after-summary">
      <div className="row mx-2">
        <div className="mb-1">
          <div className="smallTop d-flex">
            <form onSubmit={onFormSubmit} className="form-inline ml-3">
              <div className="form-group d-flex ml-2">
                <small className="mr-1">Date: </small>
                <input type="date" className="form-control" name="reschedule_date" onChange={handleDate} />
              </div>
              <div className="form-group ml-2">
                <button type="submit" className="btn btn-green mt-3">
                  Search
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="card x-panel p-0">
          <RescheduledInstallationsTable lists={lists} isLoading={isLoading} />
        </div>
      </div>
    </div>
  );
};

export default RescheduledInstallationsBody;
