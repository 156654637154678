/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import SalesNavBar from '../../../../layouts/sales/SalesNavBar';
import SalesBBNBody from './SalesBBNBody';

const SalesBBNSignups = () => {
  const { profile, user } = useUser();
  const { firstName } = profile;

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <SalesNavBar currentPage="BBN Signups" roleID={user.role_id} position={firstName} />
        <SalesBBNBody />
      </main>
    </div>
  );
};

export default SalesBBNSignups;
