import React, { useContext } from 'react';
// import SearchNocSheets from '../SearchNocSheets';
import SnmpCactiTable from './SnmpCactiTable';
import AddSnmpCacti from './AddSnmpCacti';
import PaginatePage from '../common/PaginatePage';
import SnmpCactiContext from './context/SnmpCactiContext';

const SnmpCactiBody = () => {
  const { loading, pages, paginateChange } = useContext(SnmpCactiContext);
  return (
    <div className="container">
      <div className="row mt-5">
        {/* <SearchCnMaestro /> */}
        <div className="form-group ml-2">
          <button className="btn btn-green" type="button" data-toggle="modal" data-target="#add-access-point">
            Add Snmp Cacti
          </button>
        </div>
        <AddSnmpCacti />
        <SnmpCactiTable />
        {!loading && <PaginatePage pages={pages} paginateChange={paginateChange} />}
      </div>
    </div>
  );
};

export default SnmpCactiBody;
