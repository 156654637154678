import axios from 'axios';
import { toast } from 'react-toastify';
import toastr from 'toastr';
import {
  GET_BILLING_ESTATES_URL,
  GET_INSTALLATIONS_URL,
  EDIT_INSTALLATION_STATUS_URL,
  SEARCH_INSTALLATION_URL,
  SEARCH_FOR_CUSTOMER_URL,
} from '../../../services/api/shared/installations/InstallationsUrl';
import {
  FETCHING_STARTS,
  FETCHING_STOPS,
  LOADING_STARTS,
  LOADING_STOPS,
} from '../../../constants/types/shared/LoadingTypes';
import {
  GET_BILLING_ESTATES,
  GET_FAILED_INSTALLATIONS,
  GET_INSTALLATIONS,
} from '../../../constants/types/sales/installations/InstallationsTypes';
import { GET_FAILED_INSTALLATIONS_URL } from '../../../services/api/sales/Installations/InstallationsUrl';

export const getInstallations = () => async (dispatch) => {
  dispatch({
    type: FETCHING_STARTS,
    payload: true,
  });

  try {
    const response = await axios.get(`${GET_INSTALLATIONS_URL}/1`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      dispatch({
        type: GET_INSTALLATIONS,
        payload: response.data,
      });
    }
  } catch (e) {
    if (e) {
      dispatch({
        type: FETCHING_STOPS,
        payload: false,
      });
    }
  }
};

export const searchInstallationsByUser = (page, data) => async (dispatch) => {
  dispatch({ type: FETCHING_STARTS, payload: true });
  try {
    const response = await axios.post(`${SEARCH_FOR_CUSTOMER_URL}/${page}`, data, {
      headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
    });
    if (response.data) {
      dispatch({ type: GET_INSTALLATIONS, payload: response.data });
    }
  } catch (e) {
    if (e.response) {
      toastr.info(e.response.data.message);
    } else toastr.info('Something went wrong!');
    dispatch({ type: FETCHING_STOPS, payload: false });
  }
};

export const searchInstallations = (page, data) => async (dispatch) => {
  dispatch({
    type: FETCHING_STARTS,
    payload: true,
  });
  try {
    const response = await axios.post(`${SEARCH_INSTALLATION_URL}/${page}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    if (response.data) {
      dispatch({
        type: GET_INSTALLATIONS,
        payload: response.data,
      });
    }
  } catch (e) {
    if (e.response) {
      toastr.info(e.response.data.message);
    } else toastr.info('Something went wrong!');
    dispatch({
      type: FETCHING_STOPS,
      payload: false,
    });
  }
};

export const getBillingEstates = () => async (dispatch) => {
  const response = await axios.get(`${GET_BILLING_ESTATES_URL}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
  });
  if (response.data) {
    dispatch({
      type: GET_BILLING_ESTATES,
      payload: response.data.estates,
    });
  }
};

export const editInstallation = (id, data) => async (dispatch) => {
  dispatch({
    type: LOADING_STARTS,
    payload: true,
  });

  try {
    const response = await axios.patch(`${EDIT_INSTALLATION_STATUS_URL}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data[0] === 1) {
      toast.success('Installation status has been updated!');

      dispatch({
        type: LOADING_STOPS,
        payload: false,
      });

      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  } catch (e) {
    if (e) {
      toast.info(e.response.data.message);

      dispatch({
        type: FETCHING_STOPS,
        payload: false,
      });
    }
  }
};

export const getFailedInstallations = () => async (dispatch) => {
  dispatch({
    type: FETCHING_STARTS,
    payload: true,
  });

  try {
    const response = await axios.get(`${GET_FAILED_INSTALLATIONS_URL}/1`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    if (response.data) {
      dispatch({
        type: GET_FAILED_INSTALLATIONS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: FETCHING_STOPS,
        payload: true,
      });
    }
  } catch (e) {
    if (e.response) {
      dispatch({
        type: FETCHING_STOPS,
        payload: true,
      });
    }
  }
};
