/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import { ToastContainer } from 'react-toastify';
import { useUser } from '../../../../hooks/shared/user/useUser';
import { OverviewProvider } from '../../../../context/shared/installations/overview/OverviewContext';
import NewDesignSideBar from '../../../layouts/new-design/NewDesignSideBar';
import NewDesignInstallationBody from './NewDesignInstallationBody';

const NewDesignInstallation = () => {
  const { profile } = useUser();
  return (
    <div className="s-layout">
      <OverviewProvider>
        <main className="s-layout__content col-sm p-0">
          <NewDesignSideBar currentPage="Installations" position={profile.firstName} />
          <div className="container-fluid ">
            <NewDesignInstallationBody />
          </div>
        </main>
      </OverviewProvider>
      <ToastContainer />
    </div>
  );
};

export default NewDesignInstallation;
