import React, { Component } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import AddDevice from './AddDevice';
import SolarCountTable from './SolarCountTable';
import { API_URL } from '../../../../../utils/config';

class SolarCount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalShow: false,
      loading: false,
      solars: [],
      keyword: '',
    };

    this.getDevices = this.getDevices.bind(this);
  }

  componentDidMount() {
    this.getDevices();
  }

  getDevices() {
    this.setState({
      loading: true,
    });

    axios({
      method: 'get',
      url: `${API_URL}/batteries/getlist/1`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    }).then((response) => {
      if (response.data) {
        this.setState({
          solars: response.data.logs,
          loading: false,
        });
      } else {
        toastr.info('Something went wrong!');
      }
    });
  }

  getModalStatus(i) {
    this.setState(
      {
        modalShow: !i,
      },
      () => {
        if (this.state.modalShow === true) {
          this.setState({
            modalShow: !this.state.modalShow,
          });
        }
      },
    );
  }

  render() {
    const { solars, loading, keyword, modalShow } = this.state;

    return (
      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="col-lg-12 mt-4 mb-3 d-flex flex-wrap inner-addon left-addon">
              <input
                className="form-control mr-auto col-lg-5 mt-3"
                type="text"
                placeholder="Search by AP SSID"
                onChange={(e) => this.searchSolars(e)}
                value={keyword}
                name="keyword"
              />
              <span className="fa fa-search ml-3 mt-3" />
              <div className="mt-3">
                <button
                  className="btn btn-green ml-2 shadow-md"
                  type="submit"
                  onClick={() => {
                    this.setState(
                      {
                        modalShow: !this.state.modalShow,
                      },
                      () => {},
                    );
                  }}
                >
                  {' '}
                  Add Device
                </button>
              </div>

              <div className={!modalShow ? 'nodisp' : ' modal-backdrop fade show'} />
              <div
                id="addDevice"
                /* className="modal fade in" */
                className={modalShow ? 'modal fade in show d-block' : 'nodisp'}
                data-backdrop="false"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title text-center" id="myModalLabel">
                        Add Device
                      </h4>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        onClick={() => {
                          // if(this.state.modalShow === false) {

                          this.setState(
                            {
                              modalShow: !modalShow,
                            },
                            () => {},
                          );
                        }}
                        aria-hidden="true"
                      >
                        ×
                      </button>
                    </div>
                    <AddDevice modalshow={this.getModalStatus.bind(this)} get={this.getDevices} />
                  </div>
                </div>
              </div>
            </div>

            <div className="card x-panel">
              <div className="table-responsive content">
                <table className="table copy-font">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Basestation</th>
                      <th>EBN Battery</th>
                      <th>Gracia Battery</th>
                      <th>Solar Panels</th>
                      <th>Actions</th>
                    </tr>
                  </thead>

                  <SolarCountTable solarcount={solars} load={loading} />
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SolarCount;
