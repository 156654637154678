/* eslint-disable @typescript-eslint/camelcase */
import { useReducer, useState } from 'react';
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { toast } from 'react-toastify';
import { payslipReducer } from '../../../reducers/shared/payslip/payslipReducer';
import * as loading from '../../../constants/types/shared/LoadingTypes';
import PayslipUrl from '../../../services/api/shared/payslip/Payslip';
import { showDepartment } from '../../../utils/lib';
import { API_URL } from '../../../utils/config';

const usePayslip = () => {
  const paySlipState = {
    basic_salary: '',
    arrears: '',
    housing_allowances: '',
    lunch_subsidy: '',
    medical_allowances: '',
    utilities_allowances: '',
    dressing_allowance: '',
    transport_allowances: '',
    tax: '',
    pension: '',
    national_housing_fund: '',
    loan: '',
    employee_id: '',
    others: '',
    leave_bonus: '',
  };
  const searchState = {
    from: '',
    to: '',
    employee_id: '',
  };
  const [payslip, setPayslip] = useState(paySlipState);
  const [search, setSearch] = useState(searchState);
  const [{ creating, payslips, gettingPayslip }, dispatch] = useReducer(payslipReducer, {
    creating: false,
    payslips: [],
    gettingPayslip: false,
  });
  const onChange = (e) => {
    const { name, value } = e.target;
    setPayslip({
      ...payslip,
      [name]: value,
    });
  };

  const searchChange = (e) => {
    const { name, value } = e.target;
    setSearch({
      ...search,
      [name]: value,
    });
  };

  const createPayslip = async (data) => {
    dispatch({
      type: loading.LOADING_STARTS,
    });
    try {
      const response = await axios.post(`${PayslipUrl.CREATE_PAYSLIP_URL}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.id) {
        setPayslip(paySlipState);
        toast.success('New AccountPayslip created!');
        dispatch({
          type: loading.LOADING_STOPS,
        });
      } else {
        toast.info('Unable to create!');
        dispatch({
          type: loading.LOADING_STOPS,
        });
      }
    } catch (error) {
      if (error.response.data.status === 'failed') {
        toast.error(error.response.data.message);
        dispatch({
          type: loading.LOADING_STOPS,
        });
      } else {
        toast.info('Something went wrong!');
        dispatch({
          type: loading.LOADING_STOPS,
        });
      }
    }
  };

  const searchPayslip = async (data, id) => {
    const { from, to } = data;
    const value = {
      from,
      to,
      employee_id: id,
    };

    try {
      const response = await axios.post(`${PayslipUrl.SEARCH_PAYSLIP_URL}`, value, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      console.log(response.data);
      if (response.data) {
        // const pdf = new jsPDF('p', 'mm', 'a4');
        // eslint-disable-next-line new-cap
        const doc = new jsPDF({
          orientation: 'landscape',
        });

        const columns = [
          // { title: 'Date Added', dataKey: 'created_at' },
          { title: 'Basic Salary', dataKey: 'basic_salary' },
          { title: 'Arrears', dataKey: 'arrears' },
          { title: 'Housing Allowances', dataKey: 'housing_allowances' },
          { title: 'Lunch Subsidy', dataKey: 'lunch_subsidy' },
          { title: 'Medical Allowances', dataKey: 'medical_allowances' },
          { title: 'Utilities Allowances', dataKey: 'utilities_allowances' },
          { title: 'Dressing Allowance', dataKey: 'dressing_allowance' },
          { title: 'Transport Allowances', dataKey: 'transport_allowances' },
          { title: 'Tax', dataKey: 'tax' },
          { title: 'National Housing Fund', dataKey: 'national_housing_fund' },
          { title: 'Loan', dataKey: 'loan' },
          { title: 'Month', dataKey: 'month' },
          { title: 'Year', dataKey: 'year' },
        ];
        const rows = response.data;
        doc.setFontSize(12);
        doc.setFontStyle('arial');

        doc.autoTable(columns, rows, {
          margin: { top: 20 },
          width: 170,
          // addPageContent(data) {
          //   doc.text(`${res.data.firstName} ${res.data.lastName}`, 20, 10);
          // },
          styles: {
            cellPadding: 0.5,
            fontSize: 12,
            margin: 20,
            width: 20,
            cellWidth: 19,
          },
          bodyStyles: {
            margin: 40,
            fontSize: 10,
            lineWidth: 0.2,
            // lineColor: [0, 0, 0]
          },
        });
        doc.save(`payslip`);

        // const options = {
        //   beforePageContent: header,
        //   afterPageContent: footer,
        //   theme: 'grid',
        //   columnStyles: {
        //     count: { columnWidth: 30 },
        //     c1: { columnWidth: 50 },
        //     c2: { columnWidth: 80 },
        //     c3: { columnWidth: 30 },
        //     c4: { columnWidth: 50, align: 'right' },
        //   },
        //
        //   headStyles: { fillColor: 'white', textColor: 'black' },
        //   style: { cellWidth: 'auto' },
        //   margin: { top: 150, bottom: 100, horizontal: 10 },
        // };
        //
        // // Data Processing
        // this.state.array1.map((item, index) => {
        //   const b = {
        //     count,
        //     c1: item.c1,
        //     c2: item.c2,
        //     c3: item.c3,
        //     c4: item.c4,
        //   };
        //
        //   count++;
        //   data.push(b);
        // });
        //
        // doc.setFontSize(12);
        // doc.line(0, 145, width, 145);
        //
        // doc.autoTable(data, options);
        //
        // doc.save('Generate.pdf');
        //
      } else {
        toast.info(`Unable to download user payslip`);
      }
    } catch (error) {
      if (error) {
        toast.error('Something went wrong!');
      }
    }
  };
  const searchForEmployeePayslip = ({ from, to }, query) => {
    dispatch({ type: 'GETTING_EMPLOYEE_PAYSLIPS', payload: true });

    axios
      .post(`${API_URL}/employee/searchEmployee`, query, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        }
      })
      .then((response) => {
        const value = {
          from,
          to,
          employee_id: response.data.rows[0].id,
        };
        axios
          .post(`${PayslipUrl.SEARCH_PAYSLIP_URL}`, value, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
          })
          .then((resp) => {
            dispatch({ type: 'GET_EMPLOYEE_PAYSLIPS', payload: resp.data });
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downloadUserPayslip = async (params, profile) => {
    // created_at: "2020-03-27T00:00:00.000Z"
    const { firstName, lastName, officialEmail, department_id } = profile;

    // console.log(
    //   `${PayslipUrl.DOWNLOAD_USER_PAYSLIP_URL}?name=${firstName} ${lastName}&role=${showRole(
    //     Number(sessionStorage.getItem('roleId')),
    //   )}&month=${`${params.month}&year=${params.year}`}&basic=${params.basic_salary}&arrears=${
    //     params.arrears
    //   }&transport_allowances=${params.transport_allowances}&housing_allowances=${
    //     params.housing_allowances
    //   }&lunch_subsidy=${params.lunch_subsidy}&medical_allowances=${params.medical_allowances}&dressing_allowance=${
    //     params.dressing_allowance
    //   }&tax=${params.tax}&paye=TizetiNetworkLimited&pension=${params.pension}&national_housing_fund=${
    //     params.national_housing_fund
    //   }&utilities_allowances=${params.utilities_allowances} &others=${params.others}&loan=${
    //     params.loan
    //   }&email=${officialEmail}&department=${showDepartment(department_id)}`,
    // );

    window.open(
      `${PayslipUrl.DOWNLOAD_USER_PAYSLIP_URL}?name=${firstName} ${lastName}&year=${params.year}&month=${
        params.month
      }&basic_salary=${params.basic_salary}&arrears=${params.arrears}&housing_allowances=${
        params.housing_allowances
      }&lunch_subsidy=${params.lunch_subsidy} &medical_allowances=${params.medical_allowances}&utilities_allowances=${
        params.utilities_allowances
      }&dressing_allowance=${params.dressing_allowance} &transport_allowances=${params.transport_allowances} &tax=${
        params.tax
      }&pension=${params.pension}&national_housing_fund=${params.national_housing_fund}&loan=${params.loan}&others=${
        params.others
      }&email=${officialEmail}&department=${showDepartment(department_id)}`,
      '_blank',
    );

    // window.open(
    //   `${PayslipUrl.DOWNLOAD_USER_PAYSLIP_URL}?name=${firstName} ${lastName}&role=${showRole(
    //     Number(sessionStorage.getItem('roleId')),
    //   )}&date=${`${params.month}- ${params.year}`}&basic=${params.basic_salary}&transport=${
    //     params.transport_allowances
    //   }&housing=${params.housing_allowances}&paye=TizetiNetworkLimited&pension=${params.pension}&nhs=${
    //     params.national_housing_fund
    //   }&otherdeductions=${params.others}&gross=0&totaldeductions=0&net=0`,
    //   '_blank',
    // );
  };

  return {
    payslip,
    onChange,
    createPayslip,
    creating,
    searchPayslip,
    searchChange,
    search,
    downloadUserPayslip,
    searchForEmployeePayslip,
    payslips,
    gettingPayslip,
  };
};

export default usePayslip;
