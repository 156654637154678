/* eslint-disable consistent-return */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/prefer-default-export */
import { useState, useEffect, useReducer, useCallback } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import * as load from '../../constants/types/shared/LoadingTypes';
import * as url from '../../services/api/accounts/requisition/RequisitionUrl';
import * as types from '../../constants/types/accounts/RequisitionTypes';
import requisitionReducer from '../../reducers/accounts/requistions/requisitionReducer';
import { EXECUTIVE_UPDATE_REQUSITION_STATUS_URL } from '../../services/api/shared/requisitions/RequisitionsUrl';
import { GET_FEATURE_REQUESTS_BY_DEPARTMENTS_URL } from '../../services/api/research-and-development/FeatureRequestUrl';

export const useRequsitions = () => {
  const today = new Date();

  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  const formattedToday = yyyy + '-' + mm + '-' + dd;
  // nnhjjhhj
  const approveState = { paymentDate: formattedToday, comment: '', accountapproval: '' };
  const [approveRequisition, setApproveRequisition] = useState(approveState);
  const [{ isFetching, requistions, leadRequistions, totalPages, approving, showModal }, dispatch] = useReducer(
    requisitionReducer,
    {
      isFetching: false,
      requistions: [],
      leadRequistions: [],
      totalPages: 1,
      approving: false,
      showModal: false,
    },
  );

  const [currentPage] = useState(1);
  const onClickShowModal = () => {
    dispatch({ type: types.SHOW_MODAL });
  };

  const onApproveChange = (e) => {
    const { name, value } = e.target;
    setApproveRequisition({
      ...approveRequisition,
      [name]: value,
    });
  };

  const getChequeRequisition = async () => {
    dispatch({
      type: load.FETCHING_STARTS,
    });
    try {
      const response = await axios.get(
        `${url.GET_ACCOUNTS_CHEQUE_REQUISITIONS_URL}?paginate=true&page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );
      if (response.data) {
        dispatch({
          type: types.GET_ALL_REQUISITION,
          payload: {
            requistions: response.data.list,
            totalPages: response.data.pages,
          },
        });
      } else {
        dispatch({
          type: load.FETCHING_STOPS,
        });
      }
    } catch (e) {
      if (e) {
        dispatch({
          type: load.FETCHING_STOPS,
        });
      }
    }
  };

  const getCashRequisition = useCallback(async () => {
    dispatch({
      type: load.FETCHING_STARTS,
    });
    try {
      const response = await axios.get(`${url.GET_ACCOUNTS_CASH_REQUISITIONS_URL}?page=${currentPage}&paginate=true`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_ALL_REQUISITION,
          payload: {
            requistions: response.data.list,
            totalPages: response.data.pages,
          },
        });
      } else {
        dispatch({
          type: load.FETCHING_STOPS,
        });
      }
    } catch (e) {
      if (e) {
        dispatch({
          type: load.FETCHING_STOPS,
        });
      }
    }
  }, [currentPage]);

  const setCheckAllRequisition = (e) => {
    const { checked } = e.target;
    dispatch({
      type: types.SET_CHECK_ALL_REQUISTION,
      payload: checked,
    });
  };

  const setCheckOneOfAllRequisition = (e) => {
    const { checked, id } = e.target;
    dispatch({
      type: types.SET_CHECK_ONE_OF_ALL_REQUISTION,
      payload: { id, checked },
    });
  };

  const getCashOrCheque = (e) => {
    const { value } = e.target;
    switch (value) {
      case 'cash':
        getCashRequisition();
        break;

      case 'cheque':
        getChequeRequisition();
        break;

      default:
        return null;
    }
  };

  const getRequsitionByDepartment = async (data) => {
    const { department, status } = data;
    const search = {
      status,
    };
    dispatch({
      type: load.FETCHING_STARTS,
    });

    try {
      const response = await axios.post(`${url.GET_REQUISITIONS_BY_DEPARTMENTS_URL}/${department}`, search, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_ALL_REQUISITIONS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: load.FETCHING_STOPS,
        });
      }
    } catch (e) {
      if (e) {
        dispatch({
          type: load.FETCHING_STOPS,
        });
      }
    }
  };

  const giveManagerApproval = async (id, params, reloadFunction, onCloseModal) => {
    const { paymentDate, comment } = params;
    const data = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      account_approval: 'approved',
      // eslint-disable-next-line @typescript-eslint/camelcase
      payment_date: paymentDate,
      comment,
    };
    dispatch({
      type: types.START_APPROVE_OR_DISAPPROVE,
    });
    try {
      const response = await axios.patch(`${url.ACCOUNT_MANAGER_UPDATE_REQUSITION_STATUS_URL}/${id}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        toast.success('Requisition Approved');
        onCloseModal();
        reloadFunction();
        // setApproveRequisition(approveState);

        // dispatch({
        //   type: types.APPROVE_OR_DISAPPROVE_SUCCESS,
        //   payload: id,
        // });
      }
    } catch (e) {
      if (e) {
        toast.info(e.response.data.message);
        dispatch({
          type: types.STOP_APPROVE_OR_DISAPPROVE,
        });
      }
    }
  };

  const giveManagerDisApproval = async (id, reloadFunction) => {
    const data = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      account_approval: 'rejected',
    };

    dispatch({
      type: types.START_APPROVE_OR_DISAPPROVE,
    });
    try {
      const response = await axios.patch(`${url.ACCOUNT_MANAGER_UPDATE_REQUSITION_STATUS_URL}/${id}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        toast.success('Requsition Rejected');
        dispatch({
          type: types.APPROVE_OR_DISAPPROVE_SUCCESS,
          payload: id,
        });

        reloadFunction();
      }
    } catch (e) {
      if (e) {
        toast.info(e.response.data.message);
        dispatch({
          type: types.STOP_APPROVE_OR_DISAPPROVE,
        });
      }
    }
  };

  const giveExecutiveApproval = async (id, reloadFunction) => {
    const data = {
      status: 'yes',
      comment: 'approved',
    };
    dispatch({
      type: types.START_APPROVE_OR_DISAPPROVE,
    });
    try {
      const response = await axios.post(`${EXECUTIVE_UPDATE_REQUSITION_STATUS_URL}/${id}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        toast.success('Requisition Approved');
        dispatch({
          type: types.APPROVE_OR_DISAPPROVE_SUCCESS,
          payload: id,
        });
        onClickShowModal();
        reloadFunction();
      }
    } catch (e) {
      if (e && e.response.data.message) {
        toast.info(e.response.data.message);
        dispatch({
          type: types.STOP_APPROVE_OR_DISAPPROVE,
        });
      } else {
        toast('Something went wrong');
      }
    }
  };

  const giveExecutiveDisApproval = async (id) => {
    const data = {
      status: 'no',
    };

    dispatch({
      type: types.START_APPROVE_OR_DISAPPROVE,
    });
    try {
      const response = await axios.post(`${EXECUTIVE_UPDATE_REQUSITION_STATUS_URL}/${id}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        toast.success('Requsition Rejected');

        dispatch({
          type: types.APPROVE_OR_DISAPPROVE_SUCCESS,
          payload: id,
        });
      }
    } catch (e) {
      if (e) {
        toast.info(e.response.data.message);
        dispatch({
          type: types.STOP_APPROVE_OR_DISAPPROVE,
        });
      }
    }
  };

  const getLeadsRequisition = async (data) => {
    dispatch({
      type: load.FETCHING_STARTS,
    });
    try {
      const response = await axios.get(`${url.GET_LEADS_REQUISITIONS_URL}?country=${data || 'ng'}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_LEADS_REQUISITIONS,
          payload: response.data.list.rows,
        });
      }
    } catch (e) {
      if (e) {
        dispatch({
          type: load.FETCHING_STOPS,
        });
      }
    }
  };

  const payUser = async (params) => {
    const data = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      payment_status: 'paid',
    };
    try {
      const response = await axios.patch(`${url.UPDATE_REQUISITION_STATUS_URL}/${params}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        toast.success('Successful!');

        dispatch({
          type: types.APPROVE_OR_DISAPPROVE_SUCCESS,
          payload: params,
        });
      }
    } catch (e) {
      if (e) {
        toast.info('Unable to update status!');
      }
    }
  };

  const getAccountsRequsitionByStatus = async (data, page) => {
    dispatch({
      type: load.FETCHING_STARTS,
    });

    try {
      const response = await axios.post(`${url.GET_ACCOUNTS_REQUSITION_BY_STATUS_URL}?page=${page}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_ALL_REQUISITION,
          payload: {
            requistions: response.data.list.rows,
            totalPages: response.data.pages,
          },
        });
      } else {
        dispatch({
          type: load.FETCHING_STOPS,
        });
      }
    } catch (e) {
      if (e) {
        dispatch({
          type: load.FETCHING_STOPS,
        });
      }
    }
  };
  const searchByItem = async (data) => {
    dispatch({
      type: load.FETCHING_STARTS,
    });

    try {
      const response = await axios.post(
        `${url.SEARCH_REQUISIITONS_URL}/${currentPage}`,
        { item: data },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );
      if (response.data) {
        dispatch({
          type: types.GET_ALL_REQUISITIONS,
          payload: response.data.result.list,
        });
      } else {
        dispatch({
          type: load.FETCHING_STOPS,
        });
      }
    } catch (e) {
      if (e) {
        dispatch({
          type: load.FETCHING_STOPS,
        });
      }
    }
  };
  const searchByDateRange = async (data) => {
    dispatch({
      type: load.FETCHING_STARTS,
    });

    try {
      const response = await axios.post(`${url.SEARCH_REQUISIITONS_BY_DATE_URL}/${currentPage}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_ALL_REQUISITIONS,
          payload: response.data.list,
        });
      } else {
        dispatch({
          type: load.FETCHING_STOPS,
        });
      }
    } catch (e) {
      if (e) {
        dispatch({
          type: load.FETCHING_STOPS,
        });
      }
    }
  };

  const getBetweenSevenAndThirtyDays = async () => {
    dispatch({
      type: load.FETCHING_STARTS,
    });

    try {
      const response = await axios.get(`${url.SEARCH_REQUISIITONS_BY_SEVEN_AND_THIRTY_DAYS_URL}/${currentPage}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_ALL_REQUISITIONS,
          payload: response.data.list,
        });
      } else {
        dispatch({
          type: load.FETCHING_STOPS,
        });
      }
    } catch (e) {
      if (e) {
        dispatch({
          type: load.FETCHING_STOPS,
        });
      }
    }
  };
  const getThirtyDays = async () => {
    dispatch({
      type: load.FETCHING_STARTS,
    });

    try {
      const response = await axios.get(`${url.SEARCH_REQUISIITONS_AFTER_THIRTY_DAYS_URL}/${currentPage}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_ALL_REQUISITIONS,
          payload: response.data.list,
        });
      } else {
        dispatch({
          type: load.FETCHING_STOPS,
        });
      }
    } catch (e) {
      if (e) {
        dispatch({
          type: load.FETCHING_STOPS,
        });
      }
    }
  };

  const BulkApproveOrRejectRequisitions = async (approveRequisition, bulkIds) => {
    const { comment, paymentDate, accountapproval } = approveRequisition;

    const data = {
      account_approval: accountapproval,
      payment_date: paymentDate,
      comment,
      ids: bulkIds,
    };
    dispatch({
      type: types.START_APPROVE_OR_DISAPPROVE,
    });
    try {
      const response = await axios.patch(`${url.ACCOUNT_MANAGER_BULK_UPDATE_REQUSITION_STATUS_URL}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        toast.success(response.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }

      // console.log(data, url.ACCOUNT_MANAGER_BULK_UPDATE_REQUSITION_STATUS_URL);
    } catch (e) {
      if (e) {
        toast.info(e.response.data.message);
        dispatch({
          type: types.STOP_APPROVE_OR_DISAPPROVE,
        });
      }
    }
  };
  useEffect(() => {
    getCashRequisition();
    getLeadsRequisition();
    // getAccountsRequsitionByStatus()
  }, [getCashRequisition]);

  return {
    getCashOrCheque,
    isFetching,
    requistions,
    getRequsitionByDepartment,
    giveManagerDisApproval,
    giveManagerApproval,
    giveExecutiveApproval,
    giveExecutiveDisApproval,
    leadRequistions,
    payUser,
    getAccountsRequsitionByStatus,
    totalPages,
    getLeadsRequisition,
    onApproveChange,
    approveRequisition,
    approving,
    showModal,
    onClickShowModal,
    searchByItem,
    searchByDateRange,
    getBetweenSevenAndThirtyDays,
    getThirtyDays,
    setCheckAllRequisition,
    setCheckOneOfAllRequisition,
    BulkApproveOrRejectRequisitions,
  };
};
