/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-shadow */
/* eslint-disable no-undef */
/* eslint-disable react/require-default-props */
/* eslint-disable react/button-has-type */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { connect } from 'react-redux';
import Loader from '../../../../assets/img/icons/TizetiLoaderColored.gif';
import { fetchPastExpired } from '../../../../actions/shared/retention/RetentionActions';

class Expired extends Component {
  constructor(props) {
    super(props);

    this.state = {
      from: '',
      to: '',
      country: '',
      category: '',
    };
  }

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  // eslint-disable-next-line no-undef
  onSubmit = () => {
    const { fetchPastExpired } = this.props;
    const { from, to, country, category } = this.state;
    const data = qs.stringify({
      action: 'getUserExpirationDate_from_to',
      from,
      to,
      category,
      country: country === 'Ghana' ? 'Ghana' : 'Nigeria',
    });
    fetchPastExpired(data);
  };

  // eslint-disable-next-line no-undef
  submitForm = () => {
    const { from, to, category } = this.state;
    return from.length > 0 && to.length > 0 && category.length > 0;
  };

  render() {
    const { expired, loading } = this.props;
    const { from, to } = this.state;
    const isEnabled = this.submitForm();

    const expiredList = expired ? (
      expired.map((customer, i) => (
        <tr>
          <td>{i + 1}</td>
          <td>{customer.customer_id}</td>
          <td>{customer.customer_name}</td>
          <td>{customer.customer_username}</td>
          <td>{customer.customer_email}</td>
          <td>{customer.mobile}</td>
          <td>{customer.expiration_date}</td>
          {/* {isNaN(Date.parse(customer.value)) ? <td>N/A</td> : <td>{customer.value}</td> } */}
        </tr>
      ))
    ) : (
      <p>No expired customer</p>
    );

    return (
      <div className="row">
        <div className="col-md-12 mt-5 mb-3">
          <div className="col-8 mt-5 d-md-flex">
            <div className="d-sm-flex">
              {/* <div className="fil mt-3 mr-5 copy-font"> */}
              <div className="input-group mb-3 mr-5">
                <small className="mr-2 font-weight-bold">From:</small>
                <input type="date" className="form-control" value={from} onChange={this.onChange} name="from" />
              </div>

              <div className="input-group mb-3 mr-5">
                <small className="mr-2 font-weight-bold">To:</small>
                <input type="date" className="form-control" name="to" value={to} onChange={this.onChange} />
              </div>
            </div>

            <div className="d-sm-flex" style={{ marginRight: '2em' }}>
              {/* <div className="fil mt-3 mr-5 copy-font"> */}
              {/* <div className="input-group mb-3 mr-5">
                <small className="mr-2 font-weight-bold">Country:</small> */}
              <select name="category" onChange={this.onChange}>
                <option>Select Category</option>
                <option value="hotspot">Hotspot</option>
                <option value="internet">Internet</option>
                <option value="teleport">Teleport</option>
                <option value="all">All</option>
              </select>
              {/* </div> */}
            </div>

            <div className="d-sm-flex" style={{ marginRight: '2em' }}>
              {/* <div className="fil mt-3 mr-5 copy-font"> */}
              {/* <div className="input-group mb-3 mr-5">
                <small className="mr-2 font-weight-bold">Country:</small> */}
              <select name="country" onChange={this.onChange}>
                <option>Select Country</option>
                <option value="Nigeria">Nigeria</option>
                <option value="Ghana">Ghana</option>
              </select>
              {/* </div> */}
            </div>

            <div className="mb-3">
              <button className="btn btn-green" onClick={this.onSubmit} disabled={!isEnabled}>
                Search With Date
              </button>
            </div>
          </div>
        </div>

        <div className="card x-panel">
          <div className="table-responsive content">
            <table className="table copy-font">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Customer ID</th>
                  <th>Name</th>
                  <th>MAC ID</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Expiry Date</th>
                </tr>
              </thead>
              <tbody>{loading ? <img src={Loader} alt="" /> : expiredList}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  expired: state.retention.expired,
  loading: state.retention.loading,
});

Expired.propTypes = {
  fetchPastExpired: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  expired: PropTypes.array,
};

export default connect(
  mapStateToProps,
  {
    fetchPastExpired,
  },
)(Expired);
