/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import RequestContext from '../../../../../context/shared/requests/admin/RequestContext';

const Show = ({ one }) => {
  const value = useContext(RequestContext);

  const { description, id, budget_code } = one;
  // const value = useContext(RequestContext);
  const { users, onUpdateChange, updateStatus, update, isUpdatingStatus, show, getToShow } = value;
  const { staffId, status, reason } = update;
  const submitForm = (e) => {
    e.preventDefault();
    const data = {
      assigned_to_id: staffId,
      status,
      reason_for_rejection: reason,
    };
    updateStatus(data, id, show);
  };

  return (
    <div
      style={{
        position: 'absolute',
        width: '90%',
      }}
    >
      <div
        style={{
          margin: '-12% auto',
          width: '50%',
          backgroundColor: 'whitesmoke',
          padding: '3%',
        }}
      >
        <div className="d-flex">
          <h4 className="text-center col-11 mr-auto">Update Request</h4>
          <button type="button" className="close" aria-hidden="true" onClick={getToShow}>
            ×
          </button>
        </div>

        <div>
          <form className="form-horizontal" onSubmit={submitForm}>
            <div className="form-group text-left">
              <div className="col-md-12 mb-3 d-flex">
                <div>
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
                  <label className="copy-font p-0 mb-0">Description</label>
                  <textarea
                    className="form-control"
                    name="details"
                    rows="5"
                    value={description}
                    cols="50"
                    placeholder="Request Description "
                    readOnly
                  />
                </div>
              </div>

              <div className="col-md-12 mb-3 d-flex">
                <div className="w-100">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
                  <label className="copy-font p-0 mb-0">Budget Code</label>
                  <input
                    className="form-control"
                    name="details"
                    // eslint-disable-next-line camelcase
                    value={budget_code || 'No budget code filled by user'}
                    readOnly
                  />
                </div>
              </div>

              <div className="col-md-12 mb-3 d-flex p-0">
                <div className="col-lg-12">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
                  <label className="copy-font p-0 mb-0">Assign To Staff</label>
                  <select className="form-control" name="staffId" onChange={onUpdateChange}>
                    <option value="">Select Employee</option>
                    {users.map((emp) => {
                      return (
                        <option value={emp.id}>
                          {emp.employees
                            ? emp.employees.map((e) => {
                                return `${e.firstName} ${e.lastName}`;
                              })
                            : 'This user has no profile'}

                          {/* {emp.employees ? `${`${emp.employees[0].firstName} ${emp.employees[0].lastName}`}` : 'N/A'} */}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-12 mb-3 d-flex p-0">
                <div className="col-lg-12">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
                  <label className="copy-font p-0 mb-0">Status</label>
                  <select className="form-control" name="status" required onChange={onUpdateChange}>
                    <option value="">Select Status</option>
                    <option value="pending">Pending</option>
                    <option value="rejected">Rejected</option>
                    <option value="approved">Approved</option>
                  </select>
                </div>
              </div>

              <div className="col-md-12 mb-3 d-flex">
                {status === 'rejected' ? (
                  <div>
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
                    <label className="copy-font p-0 mb-0">Reason for Rejection</label>
                    <textarea
                      className="form-control"
                      name="reason"
                      rows="5"
                      value={reason}
                      cols="50"
                      onChange={onUpdateChange}
                      placeholder="Reason for rejection"
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-12 mt-5 text-center">
              {isUpdatingStatus ? (
                <button className="btn btn-second" type="submit" disabled={isUpdatingStatus}>
                  Updating...
                </button>
              ) : (
                <button className="btn btn-second" type="submit">
                  Update
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

Show.propTypes = {
  // eslint-disable-next-line react/require-default-props
  one: PropTypes.shape(),
};
export default Show;
