/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-empty */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import toastr from 'toastr';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Logout from '../../../shared/auth/Logout';
import { API_URL, operations, TECHNICAL_SUPPORT } from '../../../../../utils/config';
import NewPosting from '../../../shared/recruitment/managers/NewPosting';
import RecruitmentTable from '../../../shared/recruitment/RecruitmentTable';
import { getOpsJobs } from '../../../../../actions/shared/recruitment/RecruitmentActions';
import ApplicationsTableHead from '../../../shared/recruitment/applications/ApplicationsTableHead';
import { getJobsApplications } from '../../../../../actions/shared/recruitment/ApplicationsActions';
import ApplicantTable from '../../../shared/recruitment/applications/ApplicantTable';
import FieldSupportSideBar from '../../../../layouts/field-support/side-bar/FieldSupportSideBar';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';

class FieldSupportRecruitment extends Component {
  static propTypes = {
    jobs: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    departments: PropTypes.array,
    getOpsJobs: PropTypes.func.isRequired,
    getDepartments: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      pages: [],
      logs: [],
      department: '',
      loading: false,
      firstname: '',
    };
  }

  componentDidMount() {
    this.props.getOpsJobs();
    this.getUser();
    this.checkDepartment();
  }

  componentWillReceiveProps(nextProps) {
    this.checkDepartment();
    if (nextProps.jobs) {
      this.setState({
        logs: nextProps.jobs.logs,
        pages: nextProps.jobs.pages,
        count: nextProps.jobs.count,
      });
    }
  }

  getDepartmentApplications = (job) => {
    // console.log(job);

    this.props.getJobsApplications(job);
  };

  getUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employee/loggedOnEmployee`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    }).then((res) => {
      if (res.data) {
        this.setState({
          firstname: res.data.firstName,
        });
      }
    });
  }

  checkDepartment = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      // console.log(response.data);

      if (response.data.department_id !== `${operations}` && response.data.department_id !== `${TECHNICAL_SUPPORT}`) {
        sessionStorage.setItem('token', '');
        sessionStorage.clear();

        this.props.history.push('/');

        toast.info('You are not allowed to view this page');
      }
    } catch (e) {}
  };

  handlePageChange = (pageNumber) => {
    const selected = pageNumber.selected + 1;

    const url = `${API_URL}/recruitment/listing/?page=${selected}`;

    axios({
      method: 'get',
      url,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      }
    }).then((response) => {
      if (response.data) {
        this.setState({
          logs: response.data.logs,
          pages: response.data.pages,
        });
      } else {
        toastr.info('No data!');
      }
    });
  };

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  getJobsByDate = (department) => {
    // console.log(department);
    if (department) {
      this.setState({
        loading: true,
      });

      const url = `${API_URL}/recruitment/listing/${department}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        })
        .then((response) => {
          this.setState({
            loading: false,
            logs: response.data.logs,
            pages: response.data.pages,
          });
        })
        .catch(() => {
          //   console.log(err.response);
        });
    }
  };

  fetchLoggedOnUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employeeReg/logged`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        if (data.data.department_id !== `${operations}`) {
          this.props.history.push('/login');

          toastr.info('You are not allowed to view this page');
        }
      })
      .catch(() => {});
  }

  render() {
    const { loading, firstname } = this.state;
    const { logs } = this.props.jobs;
    const { applications } = this.props;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <Link className="s-sidebar__trigger" to="#">
                <i className="fa fa-bars mr-3" />
              </Link>
              <FieldSupportSideBar />

              <h3 className="col-lg-6 overview-part">Recruitment</h3>
              <span className="navbar-text">
                <h6>
                  {firstname}
                  <Logout />
                </h6>
              </span>
            </div>
          </div>
        </main>

        <div className="col-12 after-summary">
          <div className="row">
            <div className="col-12 mt-4">
              <div className="col-6 d-flex">
                <div className="tab-stuff col-10 p-0">
                  <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li className="nav-item mr-auto">
                      <a
                        className="nav-link active"
                        id="pills-home-tab"
                        data-toggle="pill"
                        href="#pills-home"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        Job Postings
                      </a>
                    </li>
                    <li className="nav-item mr-auto">
                      <a
                        className="nav-link"
                        id="pills-profile-tab"
                        data-toggle="pill"
                        href="#pills-profile"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        Applications
                      </a>
                    </li>
                    <button className="btn btn-green" type="button" data-target="#Newposting" data-toggle="modal">
                      New Posting
                    </button>

                    <NewPosting />
                  </ul>
                </div>
              </div>

              <div className="tab-content col-12" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div className="col-lg-12 p-0">
                    <div className="card x-panel w-100 ml-0 p-2 mt-4">
                      <div className="table-responsive content col-lg-12 col-md-12 col-sm-12 p-0">
                        <table className="table col-lg-12 col-md-12 col-sm-12 copy-font">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Job Title</th>
                              <th>Department</th>
                              {/* <th>Type</th> */}
                              {/* <th>Posting Date</th> */}
                              <th>Deadline</th>
                              {/* <th>Number of Applicants</th> */}
                              <th>Actions</th>
                            </tr>
                          </thead>
                          {loading ? <small>loading</small> : <RecruitmentTable all={logs} />}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade show"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <div className="col-6 d-flex p-0">
                    <small>Filter By:</small>
                    <div className="d-flex">
                      <span className="ml-2 mr-3">
                        <select
                          className="form-control"
                          onChange={(e) => this.getDepartmentApplications(e.target.value)}
                          name="application"
                        >
                          <option>Jobs</option>
                          {logs ? logs.map((job) => <option value={job.id}>{job.job_title}</option>) : 'no jobs'}
                        </select>
                      </span>

                      <span className="mr-3">
                        <input type="date" className="form-control" />
                      </span>
                    </div>
                  </div>

                  <div className="col-6 d-flex p-0 mt-3">
                    <small>Filter By:</small>
                    <div className="d-flex">
                      <span className="ml-2 mr-3">
                        <select
                          className="form-control"
                          onChange={(e) => this.getDepartmentApplications(e.target.value)}
                          name="application"
                        >
                          <option>Country</option>
                          <option value="gh">Ghana</option>
                          <option value="ng">Nigeria</option>
                        </select>
                      </span>
                    </div>
                  </div>

                  <div className="col-lg-12 p-0">
                    <div className="card x-panel w-100 ml-0 p-2 mt-4">
                      <div className="table-responsive content col-lg-12 col-md-12 col-sm-12 p-0">
                        <table className="table col-lg-12 col-md-12 col-sm-12 copy-font">
                          <ApplicationsTableHead />
                          <ApplicantTable applications={applications} />
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {this.state.pages > 1 ? (
                <ReactPaginate
                  previousLabel="previous"
                  nextLabel="next"
                  breakLabel="..."
                  breakClassName="break-me"
                  pageCount={this.state.pages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={this.state.pageRangeDisplayed}
                  onPageChange={this.handlePageChange}
                  containerClassName="pagination"
                  subContainerClassName="pages pagination"
                  activeClassName="active"
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  jobs: state.opsjobs.opsjobs,
  applications: state.applications.applications,
});

export default connect(
  mapStateToProps,
  {
    getOpsJobs,
    getJobsApplications,
  },
)(FieldSupportRecruitment);
