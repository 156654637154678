/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ASSET_TEAM, CUSTOMER_SUPPORT, SALES } from '../../../../../../utils/departments/helpers';
import { useUser } from '../../../../../../hooks/shared/user/useUser';
import SalesNavBar from '../../../../../layouts/sales/SalesNavBar';
import useCheckTwoDepartments from '../../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';
import PendingRelocationBody from './PendingRelocationBody';
import CustomerSupportNavBar from '../../../../../layouts/customer-support/CustomerSupportNavBar';
import AssetTeamNavBar from '../../../../../layouts/asset-team/AssetTeamNavBar';

const PendingRelocation = ({ history }) => {
  const {
    user: { department_id, role_id },
    profile: { firstName },
  } = useUser();

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        {department_id === SALES ? (
          <SalesNavBar currentPage="Pending Relocation" position={firstName} />
        ) : department_id === CUSTOMER_SUPPORT ? (
          <CustomerSupportNavBar currentPage="Pending Relocation" roleID={role_id} position={firstName} />
        ) : (
          department_id === ASSET_TEAM && <AssetTeamNavBar currentPage="Pending Relocation" position={firstName} />
        )}
        <PendingRelocationBody />
      </main>
    </div>
  );
};

export default PendingRelocation;
