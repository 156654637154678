/* eslint-disable @typescript-eslint/camelcase */
// InvoiceParamsTable
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const InvoiceParamsTable = ({ addToTable, tableData }) => {
  // const { itemCode, quantity, price } = invoiceDetails;

  // const { invoice } = useInvoice();
  const [items, setItems] = useState([]);
  const [payload, setPayload] = useState({
    itemCode: '',
    quantity: 0,
    price: 0,
  });
  // const [item, setItem] = useState({
  //   item_code: '',
  //   price: 0,
  //   item_description: '',
  //   quantity: '',
  // });

  // const itemDescription = `${item.item_description}`;

  const fetchItems = async () => {
    const response = await axios.post('https://legacy.tizeti.com/geniatechapi/index.php', {
      action: 'sales_items_prices',
    });
    const { items: data } = response.data;
    setItems(data);
  };

  const handleItemChange = (event) => {
    // console.log(event.target.value);
    const data = items.find((x) => x.item_code === event.target.value);
    // console.log(data);
    // setItem(data);

    setPayload({
      ...payload,
      itemCode: data.item_code,
      quantity: data.quantity,
      price: data.price,
      itemDescription: data.item_description,
    });

    // updateInvoiceDetials({
    //   ...invoiceDetails,
    //   itemCode: data.item_code,
    //   quantity: data.quantity,
    //   price: data.price,
    // });

    // invoiceDetails.itemCode = data.item_code;
    // invoiceDetails.quantity = data.quantity;
    // invoiceDetails.price = data.price;
    // invoiceDetails.
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPayload({ ...payload, [name]: value });
  };

  useEffect(() => {
    fetchItems();
  }, []);
  return (
    <div className="card my-5 p-3">
      <form className="card-body">
        <div className="col-12 d-flex">
          <div className="col-7 d-flex">
            <label className="m-0 p-0 payslipfont col-3 text-left col-form-label" htmlFor="itemDescription">
              Item Description
            </label>
            <div className="mb-2">
              {/* <input
                type="text"
                className="form-control border border-info py-2"
                id="itemDescription"
                name="itemDescription"
                value={itemDescription}
                onChange={handleChange}
              /> */}

              <select onChange={handleItemChange}>
                {items.map((x) => (
                  <option key={x.item_code} value={x.item_code}>
                    {x.item_description}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-4 d-flex">
            <label className="m-0 p-0 payslipfont col-3 text-left col-form-label" htmlFor="quantity">
              Quantity
            </label>
            <div className="mb-2">
              <input
                type="number"
                min="1"
                id="quantity"
                name="quantity"
                value={payload.quantity}
                className="form-control border border-info py-2"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="col-12 d-flex">
          <div className="col-7 d-flex">
            <label className="m-0 p-0 payslipfont col-3 text-left col-form-label" htmlFor="itemCode">
              Item Code:
            </label>
            <div className="mb-2">
              <input
                type="text"
                id="itemCode"
                name="itemCode"
                value={payload.itemCode}
                className="form-control col-12 border border-info py-2"
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="col-4 d-flex">
            <label className="m-0 p-0 payslipfont col-3 text-left col-form-label" htmlFor="price">
              Price
            </label>
            <div className="mb-2">
              <input
                type="number"
                min="1"
                id="price"
                name="price"
                value={payload.price}
                className="form-control border border-info py-2"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="text-right">
          <button type="button" className="btn btn-warning" onClick={() => addToTable(payload)}>
            Enter Table
          </button>
        </div>
      </form>

      <div className="table-responsive content col-12 px-3">
        <table className="table">
          <thead>
            <tr>
              <th>Item Code</th>
              <th>Item Description</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>{tableData}</tbody>
        </table>
      </div>
    </div>
  );
};

export default InvoiceParamsTable;
