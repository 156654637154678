import React, { useContext } from 'react';
import { SchedulingContext } from '../../../../../context/field-support/SchedulingContext';

const ZoneOptions = () => {
  const { zones, registerFieldServiceZone, register, loading, registerChange } = useContext(SchedulingContext);
  return (
    <div className="col-lg-12 col-md-12 col-sm-12 mb-3 d-flex flex-wrap">
      <p className="fliter copy-font d-flex mt-3 mr-3 d-flex">
        <span>
          <select className="form-control" onChange={registerChange}>
            <option>Pick Zone</option>
            {zones
              ? zones.map((zone) => (
                  <option value={zone.id} key={zone.id} name="register">
                    {zone.name}
                  </option>
                ))
              : null}
          </select>
        </span>
      </p>
      <div className="mt-3">
        {loading ? (
          <button
            type="button"
            className="btn btn-green"
            onClick={() => registerFieldServiceZone(register)}
            disabled={loading}
          >
            Registering for zone...
          </button>
        ) : (
          <button
            type="button"
            className="btn btn-green"
            onClick={() => registerFieldServiceZone(register)}
            disabled={loading}
          >
            Register For Zone
          </button>
        )}
      </div>
    </div>
  );
};

export default ZoneOptions;
