/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import '../../../../../styles/style.css';
import '../../../../../styles/inventory.css';
import axios from 'axios';
import toastr from 'toastr';
import ReactPaginate from 'react-paginate';
import AddInventory from '../../super-admin/inventories/AddInventory';
import InventoriesTable from '../../super-admin/inventories/InventoriesTable';
import { API_URL } from '../../../../../utils/config';
import AccountsNavBar from '../../../../layouts/accounts/AccountsNavBar';
import { ACCOUNTS, SUPER_ADMIN } from '../../../../../utils/departments/helpers';
import { toast } from 'react-toastify';
import TizetiLoader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
class AccountInventories extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inventories: [],
      inventoryId: {},
      activePage: 1,
      itemsCountPerPage: 1,
      keyword: '',
      totalItemsCount: 1,
      move: false,
      user: {},
      filtered: [],
      isLoading: false,
      out: false,
      role: '',
      page: 1,
      allpages: ''
    };

    this.handlePageClick = this.handlePageClick.bind(this)
  }

  componentWillMount() {
    this.fetchLoggedOnUser();
    this.fetchInventories();
    this.getUserDetails();
  }

  componentWillReceiveProps() {
    this.fetchLoggedOnUser();
  }

  getUserDetails() {
    const token = sessionStorage.getItem('token');

    axios({
      method: 'get',
      url: `${API_URL}/employee/loggedOnEmployee`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        this.setState({
          user: data.data,
        });
      })
      .catch((err) => {});
  }

  getUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employee/loggedOnEmployee`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        this.setState({
          user: data.data,
        });
      })
      .catch((err) => {});
  }

  searchCustomer = (e) => {
    const keyword = e.target.value;

    if (keyword !== '') {
      const list = this.state.filtered.filter((customer) => {
        if (customer.name.indexOf(keyword) !== -1) {
          return customer;
        }

        if (customer.model.indexOf(keyword) !== -1) {
          return customer;
        }

        if (customer.manufacturer.indexOf(keyword) !== -1) {
          return customer;
        }

        if (customer.vendor.indexOf(keyword) !== -1) {
          return customer;
        }

        if (customer.serialNumber.indexOf(keyword) !== -1) {
          return customer;
        }
      });
      this.setState({
        filtered: list,
        keyword: e.target.value,
      });
    } else {
      this.setState({
        filtered: this.state.inventories,
        keyword,
      });
    }
  };

  fetchLoggedOnUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employeeReg/logged`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        this.setState({
          role: data.data.role_id,
        });

        if (data.data.department_id !== `${ACCOUNTS}` && data.data.department_id !== `${SUPER_ADMIN}`) {
          sessionStorage.setItem('data', '');
          sessionStorage.clear();
          this.props.history.push('/login');
          this.setState({ out: true });
          toastr.info('You are not allowed to view this page');

          // const { from } = this.props.location.state || { from: { pathname: '/' } };
          // console.log(this.props.history.goBack);
          // window.previousLocation = this.props.location;
          // console.log(window.previousLocation);
        }
      })
      .catch((err) => {});
  }

  fetchInventories(page = 1) {
    try {
      this.setState({
        isLoading: true,
      });
  
      axios({
        method: 'get',
        url: `${API_URL}/inventory/all/${page}`,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        }
      }).then((res) => {
        this.setState({
          inventories: res.data.data,
          isLoading: false,
          filtered: res.data.data,
          allpages: res.data.pages,
          page: page
        });
      });
    } catch (error) {
       toast.error('Something went wrong...') 
    }
    
  }

  handlePageClick(event) {
  this.fetchInventories(event.selected + 1)
  }



  render() {
    const { user, role } = this.state;
    const { firstName } = user;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <AccountsNavBar currentPage="Inventory" position={firstName} role_id={role} />

          <div className="container">
            <div className="row mt-5">
              <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-4 d-flex flex-wrap">
                <div className="col-lg-10 col-md-10 col-sm-12 mt-2">
                  <form className="form-inline inner-addon left-addon" action="#">
                    <input
                      value={this.state.keyword}
                      onChange={(e) => this.searchCustomer(e)}
                      className="form-control mr-sm-2 empty mt-1"
                      id="iconified"
                      type="text"
                      placeholder="Search"
                    />
                    <span className="fa fa-search" />
                  </form>
                </div>

                <div className="col-lg-2 col-md-2 col-sm-12 mt-2">
                  <button className="btn btn-green" type="submit" data-toggle="modal" data-target="#add-employee">
                    Add Inventory
                  </button>
                </div>

                <div
                  id="add-employee"
                  className="modal fade in"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="myModalLabel"
                  aria-hidden="true"
                  style={{ overflow: 'auto' }}
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title text-center" id="myModalLabel">
                          Add Inventory
                        </h4>
                        <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                          ×
                        </button>
                      </div>
                      <AddInventory />

                      <div className="modal-footer">
                        <a href="#success2" data-toggle="modal" data-dismiss="modal" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="success2"
                className="modal fade in"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="myModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body text-center">
                      <span className="fa fa-check-circle" />
                      <p>
                        <b>Inventory Added Successfully!</b>
                      </p>
                      <a href="#add-employee" data-toggle="modal" data-dismiss="modal">
                        <button
                          type="submit"
                          className="btn btn-green waves-effect"
                          data-toggle="modal"
                          data-target="#add-employee"
                        >
                          Add Another
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card x-panel">
                <div className="table-responsive content col-lg-12 col-md-12 col-sm-12">
                  <table className="table col-lg-12 col-md-12 col-sm-12">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Manufacturer</th>
                        <th>Model</th>
                        <th>Vendor</th>
                        <th>Serial No</th>
                        <th>Warranty</th>
                        <th>Firmware</th>
                        <th>Date</th>
                        {/* <th>Employee</th> */}
                        {/* <th>Assign To </th> */}
                        <th>Actions</th>
                      </tr>
                    </thead>
                    {this.state.isLoading ? <img src={TizetiLoader} alt='loader'/> : <InventoriesTable inventories={this.state.filtered} page={this.state.page}/>}
                  </table>
                  {this.state.filtered.length > 0 && 
                        <ReactPaginate
                            previousLabel="previous"
                            nextLabel="next"
                            breakLabel="..."
                            breakClassName="break-me"
                            pageCount={this.state.allpages}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={4}
                            onPageChange={this.handlePageClick}
                            containerClassName="pagination"
                            subContainerClassName="pages pagination"
                            activeClassName="active"
                        />}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default AccountInventories;
