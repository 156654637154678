import React from 'react'
import UsageHistoryBody from '../../customer-support/usage-history/UsageHistoryBody';
import SalesNavBar from '../../../../layouts/sales/SalesNavBar';

const SalesUsageHistory = () => {
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <SalesNavBar currentPage="Usage History" />
        <div  className="mt-5 py-5">
          <UsageHistoryBody />
        </div>
      </main>
    </div>
  );
}

export default SalesUsageHistory;