import React, { Component } from 'react';
import '../../../../../styles/style.css';
import '../../../../../styles/sales_overview.css';
import toastr from 'toastr';
import axios from 'axios';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import ViewUserProfile from '../../../shared/staff/ViewUserProfile';
import { EXPRESS_WIFI } from '../../../../../utils/config';
import ExpressWifiNavBar from '../../../../layouts/expresswifi/ExpressWifiNavBar';

class ExpressWifiOverview extends Component {
  componentDidMount() {
    this.checkDepartment();
  }

  componentWillReceiveProps() {
    this.checkDepartment();
  }

  checkDepartment = async () => {
    const { history } = this.props;

    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.department_id !== `${EXPRESS_WIFI}`) {
        history.push('/');

        sessionStorage.setItem('token', '');
        sessionStorage.clear();

        toastr.info('You are not allowed to view this page');
      }
    } catch (e) {
      console.log(e.response);
    }
  };

  render() {
    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <ExpressWifiNavBar currentPage="Overview" profileText="profile" />

          <div className="container">
            <div className="row mt-5">
              <div className="col-lg-12 col-md-12 col-sm-12 mt-5">
                <ViewUserProfile />
                <h1 style={{ textAlign: 'center' }}>Express Wifi</h1>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default ExpressWifiOverview;
