import React, { Component } from 'react';
import '../../../../../styles/basestation.css';
import axios from 'axios';
import { Redirect, Link } from 'react-router-dom';
import toastr from 'toastr';
import mail from '../../../../../assets/img/icons/mailblast.svg';
import CustomerServiceScript from '../../../shared/CustomerServiceScript';
import RetentionScript from '../../../shared/customer-support/RetentionScript';
import CustomerSupportSideBar from '../../../../layouts/customer-support/CustomerSupportSideBar';
import Logout from '../../../shared/auth/Logout';
import CustomerSupportLogo from '../../../../layouts/customer-support/CustomerSupportLogo';
import { API_URL } from '../../../../../utils/config';

class CustomerSupportBaseStations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      basestations: [],
      basestation: '',
      filtered: [],
      isLoading: false,
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      move: false,
      out: false,
      role: '',
    };

    this.onKeyPress = this.onKeyPress.bind(this);
  }

  onKeyPress = (e) => {
    if (e.which === 13) {
      this.setState({
        isLoading: true,
      });

      const base = this.state.basestations.filter((basestation) => {
        if (basestation.name.indexOf(e.target.value) !== -1) {
          return basestation;
        }
        if (basestation.address.indexOf(basestation) !== -1) {
          return basestation;
        }
      });

      this.setState({
        filtered: base,
      });
    }
  };

  componentWillMount() {
    this.fetchBaseStations();

    if (!sessionStorage.getItem('token')) {
      this.setState({
        move: true,
      });
    }

    this.fetchLoggedOnUser();
    this.getUserDetails();
  }

  renderMap = () => {
    loadScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyD1DrDBUd6GNL2EIBCxK-K0OjkTny8kbuA&callback=initMap');
    window.initMap = this.initMap;
  };

  fetchBaseStations() {
    axios({
      method: 'get',
      url: `${API_URL}/baseStation`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    }).then((res) => {
      this.setState(
        {
          basestations: res.data,
          filtered: res.data,
        },
        this.renderMap(),
      );
    });
  }

  componentWillReceiveProps() {
    this.fetchLoggedOnUser();
  }

  getUserDetails() {
    const token = sessionStorage.getItem('token');

    axios({
      method: 'get',
      url: `${API_URL}/employee/loggedOnEmployee`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        this.setState({
          user: data.data,
        });
      })
      .catch((err) => {});
  }

  fetchLoggedOnUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employeeReg/logged`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        if (data.data) {
          this.setState({
            role: data.data.role_id,
          });
        }

        if (data.data.department_id !== '874d238d-5019-4890-81a1-09ec83d36306') {
          sessionStorage.setItem('data', '');
          sessionStorage.clear();
          this.setState({
            out: true,
          });

          toastr.info('You are not allowed to view this page');

          // const { from } = this.props.location.state || { from: { pathname: '/' } };
          // console.log(this.props.history.goBack);
          // window.previousLocation = this.props.location;
          // console.log(window.previousLocation);
        }
      })
      .catch((err) => {});
  }

  initMap = () => {
    const map = new window.google.maps.Map(document.getElementById('map'), {
      center: { lat: 6.548653, lng: 3.54882 },
      zoom: 11,
      maxZoom: 0, // 18
      minZoom: 0, // 16
      zoomControlOptions: {
        style: window.google.maps.ZoomControlStyle.SMALL,
      },
      scaleControl: true, // enable scale control
      mapTypeId: window.google.maps.MapTypeId.HYBRID, // google map type
    });

    const infowindow = new window.google.maps.InfoWindow();

    this.state.filtered.map((basestation) => {
      const contentString = `${basestation.name}`;

      const marker = new window.google.maps.Marker({
        position: { lat: basestation.latitude, lng: basestation.longitude },
        map,
        // icon: 'https://res.cloudinary.com/tizeti/image/upload/v1542971012/Group-1.png',
        icon: 'http://www.my9ja.com/estatewifi/icons/pin_green.png',
        title: basestation.name,
        // animation: window.google.maps.Animation.BOUNCE
      });

      const circle = new window.google.maps.Circle({
        map,
        radius: 2000, // 2 kilometeres in metres
        fillColor: '#00FF00',
        // strokeColor: '#FF0000',
        strokeOpacity: 0.2,
        strokeWeight: 0.12,
        fillOpacity: 0.25,
      });

      circle.bindTo('center', marker, 'position');

      marker.addListener('click', function() {
        // chnage content
        infowindow.setContent(contentString);
        infowindow.open(map, marker);
      });
    });
  };

  render() {
    const { user, role } = this.state;
    const { firstName } = user;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <Link className="s-sidebar__trigger" to="#">
                <i className="fa fa-bars mr-3" />
              </Link>
              <CustomerSupportSideBar />

              <h3 className="col-lg-5 overview-part">BaseStations</h3>

              <div className="ml-5 d-flex profile-mail pr-5">
                <Link
                  to="/customer-support/mail-blast"
                  className="d-none d-md-flex"
                  style={{ color: 'gray', textDecoration: 'none' }}
                >
                  <img src={mail} alt="" className="logout mr-2" />
                  <span>
                    <h6 className="m-2">
                      <strong>MailBlast</strong>
                    </h6>
                  </span>
                </Link>

                <h6 className="mr-0 d-flex">
                  <i className="small mr-2 d-none d-md-block">{firstName}</i>
                  <Logout />
                </h6>
              </div>
            </div>
          </div>

          <div className="after-summary">
            <div className="container m-0">
              <div className="row">
                <div className="col-lg-12 col-md-12 mt-3 d-flex p-4">
                  <div id="map" />
                </div>
              </div>

              <span className="d-none d-md-block">
                <RetentionScript />
                <CustomerServiceScript />
              </span>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

function loadScript(url) {
  const index = window.document.getElementsByTagName('script')[0];
  const script = window.document.createElement('script');
  script.src = url;
  script.async = true;
  script.defer = true;
  index.parentNode.insertBefore(script, index);
}

export default CustomerSupportBaseStations;
