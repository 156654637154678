/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-empty */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import { LOGGED_IN_USER_URL } from '../../../../services/api/auth/AuthUrl';
import { API_URL } from '../../../../utils/config';
import { OPERATIONS, SUPER_ADMIN, TECHNICAL_SUPPORT } from '../../../../utils/departments/helpers';
import profile from '../../../../assets/img/icons/Profile.png';
import ViewUserProfile from '../../shared/staff/ViewUserProfile';
import group from '../../../../assets/img/icons/Group.png';
import { getInstallations } from '../../../../actions/shared/installations/InstallationsActions';
import anchor from '../../../../assets/img/icons/anchor.fw.png';
import sales from '../../../../assets/img/icons/sales.png';
import TotalInstallations from './overview/TotalInstallations';
import FieldSupportNavBar from '../../../layouts/field-support/FieldSupportNavBar';
import { useInstallationsSummary } from '../../../../hooks/field-support/installations-summary/useInstallationsSummary';

const withUseInstallation = (Component) => {
    return (props) => {
      const [numZone, setNumZone] = useState(0)
      const {
        lagosMonthlyCompleted, 
        ogunMonthlyCompleted, 
        riversMonthlyCompleted, 
        edoMonthlyCompleted, 
        oyoMonthlyCompleted,
        lagosDailyCompleted, 
        ogunDailyCompleted, 
        riversDailyCompleted,  
        edoDailyCompleted, 
        oyoDailyCompleted
      } = useInstallationsSummary()
    
      const dailyCompleted =
        lagosDailyCompleted + ogunDailyCompleted + riversDailyCompleted + edoDailyCompleted + oyoDailyCompleted;
      const monthlyCompleted =
        lagosMonthlyCompleted + ogunMonthlyCompleted + riversMonthlyCompleted + edoMonthlyCompleted + oyoMonthlyCompleted;

        const getNumZone = async () => {
          try {
            const result = await axios.get(`${API_URL}/zones`, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
              }
            });

            if (result.status === 200) {
              setNumZone(result.data.length)
            }
            
          } catch (error) {
            console.log(error);
          }
        }

        const computedState = {...props, dailyCompleted, monthlyCompleted, getNumZone, numZone}

      return (
          <Component {...computedState}/>
      )
    }
}

class FieldSupportOverview extends Component {
  static propTypes = {
    getInstallations: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.checkDepartment();
    this.props.getInstallations();
    this.props.getNumZone()
  }

  componentWillReceiveProps() {
    this.checkDepartment();
  }

  checkDepartment = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (
        response.data.department_id !== `${OPERATIONS}` &&
        response.data.department_id !== `${TECHNICAL_SUPPORT}` &&
        response.data.department_id !== `${SUPER_ADMIN}`
      ) {
        sessionStorage.setItem('token', '');
        sessionStorage.clear();

        this.props.history.push('/');

        toast.info('You are not allowed to view this page');
      }
    } catch (e) {}
  };

  render() {
    console.log(this.props);
    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <FieldSupportNavBar currentPage="Overview" profileImage={profile} position />
          </div>

          <div className="col-12 after-summary">
            <ViewUserProfile />
            <div className="row">
              <div className="col-lg-12 p-0">
                <div className="col-lg-12 p-3">
                  <div className="col-lg-12 d-flex p-0">
                    <h6 className="col-lg-8 mr-auto">Operations Summary</h6>
                    <p className="ops-opt copy-font d-none d-md-flex ">
                      <small className="mr-2"> Filter By:</small>
                      <span>
                        <select className="form-control">
                          <option>Month</option>
                        </select>
                      </span>

                      <span>
                        <select className="form-control">
                          <option>Year</option>
                        </select>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 side d-flex">
                <div className="col-12 col-lg-2">
                  <TotalInstallations monthlyCompleted={this.props.monthlyCompleted}/>
                  <div className="col-lg-12">
                    <div className="boxes" id="boxx">
                      <div className="text-center mt-2">
                        <img src={anchor} alt="" />
                      </div>
                      <div className="count">0</div>
                      <h3
                        style={{
                          color: '#673FBB',
                          fontWeight: 'bold',
                        }}
                      >
                        Total Issues Resolved
                      </h3>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="boxes">
                      <div className="text-center mt-4">
                        <img src={sales} alt="" />
                      </div>
                      <div className="count">{this.props.dailyCompleted}</div>
                      <h3 style={{ color: '#3BB427' }}>
                        <b>Newly Installed</b>
                      </h3>
                    </div>
                  </div>

                  <div className="col-12 col-lg-12">
                    <div className="boxes">
                      <div className="opsview text-center">
                        <img src={group} alt="" />
                      </div>
                      <div className="count">{this.props.numZone}</div>
                      <h3 style={{ color: '#F37705' }}>Installation Zones</h3>
                    </div>
                  </div>
                </div>

                <div className="col-lg-10 d-none d-md-block">
                  <div className="card-group mt-1">
                    <div className="card o-income">
                      <div className="card-body">
                        <div className="d-flex m-b-30 no-block ml-2">
                          <h4 className="card-title m-b-0 align-self-center">Total Customer Account Type</h4>
                        </div>

                        <div className="d-flex col-lg-12 graph">
                          <div className="col-lg-6">
                            <small>Records for October, 2018.</small>
                          </div>
                          <div className="colors d-flex col-lg-7">
                            <p className="color" />
                            <p className="copy-font">Unlimited Internet</p>
                            <p className="c" />
                            <p className="copy-font"> Dedicated Internet</p>
                          </div>
                        </div>

                        <div id="ops" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  installations: state.installs.installations,
});

export default connect(mapStateToProps, {
  getInstallations,
})(withUseInstallation(FieldSupportOverview));
