/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import usePost from '../../../../../hooks/shared/custom/data/geniatech/usePost';
import { LEGACY_GENIATECH_URL } from '../../../../../services/api/shared/installations/InstallationsUrl';

const SearchClusteredInstallations = ({ searchClusters, keyword, setKeyword, zoneSearch, setZoneSearch }) => {
  const { data, getDataLegacy } = usePost();

  const { searchBy, searchValue } = zoneSearch;

  const [option, setOption] = useState('date');

  const handleFilterDropdown = (e) => {
    const { value } = e.target;
    setOption(value);
    setKeyword('');
  };

  const handleZoneSearch = (e) => {
    const { name, value } = e.target;
    const actionParam = { action: 'list_of_zones' };
    setZoneSearch({ ...zoneSearch, [name]: value });
    if (value === 'zone') getDataLegacy(`${LEGACY_GENIATECH_URL}`, actionParam);
  };

  return (
    <form className="form-inline ml-3" onSubmit={searchClusters}>
      <div className="form-group">
        <select name="chooseField" value={option} onChange={handleFilterDropdown} className="form-control">
          <option disabled>--Filter By--</option>
          <option value="details">Details</option>
          <option value="date">Date</option>
          <option value="">All</option>
        </select>
      </div>
      {option === 'date' ? (
        <div className="form-group d-flex ml-2">
          <input type="date" className="form-control" value={keyword} onChange={(e) => setKeyword(e.target.value)} />
        </div>
      ) : option === 'details' ? (
        <div className="form-group ml-2">
          <input
            className="form-control"
            type="text"
            placeholder="Search By Customer Name or Reference Point"
            style={{ width: '250px' }}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
          />
        </div>
      ) : null}
      <div className="form-group">
        <select name="searchBy" value={searchBy} onChange={handleZoneSearch} className="form-control">
          <option disabled>--Zone Search--</option>
          <option value="none">All</option>
          <option value="zone">Zone</option>
        </select>
      </div>
      {searchBy === 'zone' && (
        <div className="form-group d-flex ml-2">
          <select name="searchValue" value={searchValue} onChange={handleZoneSearch} className="form-control">
            <option value="">--Select Zones--</option>
            {data.length ? (
              data.map((zone) => <option value={zone.name}>{zone.name}</option>)
            ) : (
              <option>Fetching...</option>
            )}
          </select>
        </div>
      )}
      <div className="form-group ml-2">
        <button className="btn btn-green" type="submit">
          Search
        </button>
      </div>
    </form>
  );
};

export default SearchClusteredInstallations;
