/* eslint-disable @typescript-eslint/camelcase */
import { useEffect, useReducer } from 'react';
import axios from 'axios';
import { LOADING_STARTS, LOADING_STOPS } from '../../../../../../../constants/types/shared/LoadingTypes';
import { GET_DATA } from '../../../../../../../constants/types/shared/CustomTypes';
import useForm from '../../../../../../../hooks/shared/custom/form/no-reload/useForm';
import enbSiteIpAllocationReducer from '../reducer/enbSiteIpAllocationReducer';
import { ENB_SITE_ALLOCATION_URL } from '../../../../../../../services/api/noc/sheets/EnbSiteAllocaitonUrl';

const useEnbSiteIpAllocation = () => {
  const [{ data, loading, pages }, dispatch] = useReducer(enbSiteIpAllocationReducer, {
    data: [],
    loading: false,
    pages: 0,
  });
  const { loadingForm, onChange, formValue, onSubmit, returned } = useForm(
    `${ENB_SITE_ALLOCATION_URL}`,
    {
      site: '',
      ip_address: '',
      eNB_vendor: '',
    },
    'Enb Site Ip Allocation Added',
  );
  const paginateChange = async (pagenumber) => {
    dispatch({
      type: LOADING_STARTS,
    });
    try {
      const response = await axios.get(`${ENB_SITE_ALLOCATION_URL}/${pagenumber}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.result) {
        dispatch({
          type: GET_DATA,
          payload: response.data.result,
        });
      } else {
        dispatch({ type: LOADING_STOPS });
      }
    } catch (e) {
      dispatch({ type: LOADING_STOPS });
    }
  };
  useEffect(() => {
    async function getData() {
      dispatch({
        type: LOADING_STARTS,
      });
      try {
        return await axios.get(`${ENB_SITE_ALLOCATION_URL}/1`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });
      } catch (e) {
        return e;
      }
    }
    getData()
      .then((response) => {
        dispatch({
          type: GET_DATA,
          payload: response.data.result,
        });
      })
      .catch(() => dispatch({ type: LOADING_STOPS }));
    if (returned.data) {
      // dispatch({ type: REFETCH, payload: returned.data });
      getData()
        .then((response) => {
          dispatch({
            type: GET_DATA,
            payload: response.data.result,
          });
        })
        .catch(() => dispatch({ type: LOADING_STOPS }));
    }
  }, [returned.data]);
  return { data, loading, paginateChange, loadingForm, onChange, formValue, onSubmit, pages };
};

export default useEnbSiteIpAllocation;
