/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { connect } from 'react-redux';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import { admin, NOC } from '../../../../../utils/config';
import { getUser } from '../../../../../actions/shared/user/UserActions';
import TicketsBody from './TicketsBody';
import NocNavBar from '../../../../layouts/noc/NocNavBar';

class Tickets extends Component {
  componentDidMount() {
    this.checkDepartment();
    this.props.getUser();
  }

  componentWillReceiveProps() {
    this.checkDepartment();
  }

  checkDepartment = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.department_id !== `${NOC}` && response.data.department_id !== `${admin}`) {
        this.props.history.push('/');

        sessionStorage.setItem('token', '');
        sessionStorage.clear();

        toastr.info('You are not allowed to view this page');
      }
    } catch (e) {
      // console.log(e.response);
    }
  };

  render() {
    const { user } = this.props;
    const { firstName } = user;
    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <NocNavBar currentPage="Ticket" position={firstName} />

          <TicketsBody />
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect(
  mapStateToProps,
  { getUser },
)(Tickets);
