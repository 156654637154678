import { useState, useEffect } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import * as url from '../url/RefundUrl';

const useRefund = () => {
  const refundFormState = {
    note: '',
  };
  const [refund, setRefund] = useState(refundFormState);
  const [loadingForm, setLoadingForm] = useState(false);
  const [show, setShow] = useState(false);

  const [lists, setLists] = useState([]);
  const [items, setItems] = useState([]);

  const [count, setCount] = useState(0);
  const [page] = useState(1);
  const [fetching, setFetching] = useState(false);
  const [editRefundModal, setEditRefundModal] = useState(false);
  const [bulkRefundModal, setBulkRefundModal] = useState(false);
  const [refunding, setRefunding] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [option, setOption] = useState('');

  const openEditRefundModal = () => {
    setEditRefundModal(!editRefundModal);
    setConfirmationModal(false);
  };

  const openBulkEditModal = () => {
    setBulkRefundModal(!bulkRefundModal);
  };

  const openConfirmationModal = () => {
    setConfirmationModal(!confirmationModal);
  };

  const onUpdateRefund = (e) => {
    setOption(e.target.value);
  };

  const updateRefund = async (params, id, reload, page, item, option) => {
    setRefunding(true);
    try {
      const response = await axios.patch(`${url.UPDATE_REFUND_URL}/${id}`, params, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.status === 200) {
        setRefunding(false);
        setEditRefundModal(false);
        toastr.success(`Refund status updated`);

        reload(page, item, option);
      } else {
        toastr.info('Unable to update Refund');
      }
    } catch (error) {
      setRefunding(false);
      toastr.info('Something went wrong');
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setRefund({
      ...refund,
      [name]: value,
    });
  };
  const handleClose = () => setShow(false);

  const handleShow = () => {
    setShow(true);
  };

  const onSubmit = async (param, setFormdata) => {
    setLoadingForm(true);

    try {
      const response = await axios.post(`${url.MAKE_REFUND_REQUEST_URL}`, param, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.status === 'success') {
        toastr.success('Refund Request Submitted');
        setLoadingForm(false);
        setFormdata({ bank_name: '', account_name: '', account_number: '', state: '' });
        setShow(false);
      } else {
        toastr.error('Unable to make Refund Request');
      }
    } catch (e) {
      // setLoadingForm(false);
      setLoadingForm(false);
      toastr.error('Something went wrong');
    }
    // return;

    //send email notification
    try {
      const result = await axios.post(
        `${url.SEND_REFUND_EMAIL_NOTIFICATION}`,
        { name: param.firstName, product: 'installation', target: param.target },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );
      if (result.data.status !== 'failed') {
        toastr.success('Email notification sent successfully');
      }
    } catch (e) {
      toastr.error('Something went wrong with email notification');
    }
  };

  const searchUsingKeyword = async (selectedPage, data) => {
    setFetching(true);

    const config = {
      method: 'post',
      url: `${url.SEARCH_REFUND_REQUEST_URL}/${selectedPage}`,
      data,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response.data) {
          setFetching(false);

          setItems(response.data.rows);
          setLists(response.data.rows);
          setCount(response.data.count);
        }
        // setIsLoading(false);
      })
      .catch(() => {
        setFetching(false);

        toastr.info('Something went wrong!');
      });
  };

  const getRefundRequests = (selectedPage) => {
    setFetching(true);
    const config = {
      method: 'post',
      url: `${url.GET_REFUND_REQUEST_URL}/${selectedPage}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response.data) {
          setFetching(false);
          setLists(response.data.rows);
          setCount(response.data.count);
        }
      })
      .catch(() => toastr('Unable to get refund requests as something went wrong'));
  };

  return {
    onChange,
    refund,
    show,
    getRefundRequests,
    handleClose,
    handleShow,
    onSubmit,
    loadingForm,
    searchUsingKeyword,
    lists,
    count,
    page,
    fetching,
    editRefundModal,
    openEditRefundModal,
    openBulkEditModal,
    bulkRefundModal,
    updateRefund,
    refunding,
    openConfirmationModal,
    confirmationModal,
    onUpdateRefund,
    items,
    option,
  };
};
export default useRefund;
