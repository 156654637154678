/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import moment from 'moment';
import axios from 'axios';
import toastr from 'toastr';
import { ToastContainer, toast } from 'react-toastify';
import view from '../../../../../assets/img/icons/view.png';
import NetPromoterScoreDetails from '../net-promoter-score/NetPromoterScoreDetails';
import PublicModal from '../PublicModal';
import { API_URL, token } from '../../../../../utils/config';

class Surveys extends Component {
  constructor(props) {
    super(props);

    this.state = {
      surveys: [],
      start: '',
      end: '',
      survey: '',
      percentage: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      surveys: nextProps.all,
    });
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  getPercentage = (e) => {
    e.preventDefault();

    const data = {
      from: this.state.start,
      to: this.state.end,
      campaign_id: this.state.survey,
    };

    if (this.state.from || this.state.from || this.state.survey) {
      axios({
        method: 'post',
        url: `${API_URL}/nps/score`,
        data,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      }).then((res) => {
        // console.log(res.data);
        // percentage_detractors: null
        // percentage_promoters: null
        // score: null
        this.setState({
          percentage: res.data,
        });

        if (res.data.score === null) {
          toast.info('There is no feedback from this survey. Check again later.');
        }
      });
    } else {
      toastr.info('Select all fields!');
    }

    // console.log(data);
  };

  // showPercentage(){
  //
  //   console.log(this.state.survey);
  //   if(this.state.survey){
  //
  //     // axios.get(`${API_URL}/`)
  //     return(
  //       <div className="col-lg-3 p-0">
  //         <div className="flex-wrapper">
  //           <div className="single-chart ml-3">
  //             <svg viewBox="0 0 36 36"
  //                  className="circular-chart orange mt-3">
  //               <path className="circle-bg"
  //                     d="M18 2.0845
  //                                   a 15.9155 15.9155 0 0 1 0 31.831
  //                                   a 15.9155 15.9155 0 0 1 0 -31.831"
  //               />
  //               <path className="circle"
  //                     stroke-dasharray="20, 100"
  //                     d="M18 2.0845
  //                                   a 15.9155 15.9155 0 0 1 0 31.831
  //                                   a 15.9155 15.9155 0 0 1 0 -31.831"
  //               />
  //               <text x="18" y="19" className="percentage">20
  //                 <tspan x="19" y="24" className="percentage-words">Net Promoter Score</tspan>
  //               </text>
  //             </svg>
  //           </div>
  //         </div>
  //       </div>
  //     )
  //
  //   }
  //
  // }

  // eslint-disable-next-line consistent-return
  showSurveys() {
    if (this.state.surveys) {
      return (
        <select className="form-control mb-3" name="survey" onChange={this.onChange}>
          <option>Select Survey</option>
          {this.state.surveys.map((survey, i) => (
            <option key={survey.id} value={survey.id}>
              {survey.name}
            </option>
          ))}
        </select>
      );
    }
  }

  render() {
    const { surveys, percentage } = this.state;
    const all = percentage.percentage_promoters + percentage.percentage_detractors;
    const passive = 100 - all;

    const surveysList = surveys.length ? (
      surveys.map((survey, i) => (
        <tr>
          <td>{i + 1}</td>
          <td>doewills@gmail.com</td>
          <td>{survey.name}</td>
          <td> This app allows me enjoy all the benefits of having a.....</td>
          <td>{moment(survey.created_at).format('Do MM YYYY')}</td>
          <td>
            <span className="badge badge-success">Promoter</span>{' '}
          </td>
          <td>
            <div className="view1 ml-2 img-pointer" data-target="#nps-details" data-toggle="modal">
              <img alt="" src={view} />
            </div>
          </td>
          <NetPromoterScoreDetails />
        </tr>
      ))
    ) : (
      <p>No surveys!</p>
    );

    return (
      <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
        <div className="col-lg-12 p-0 page-title mt-4 mb-3 d-flex">
          <ToastContainer />
          <form onSubmit={this.getPercentage} className="form-horizontal col-lg-4">
            <div className="form-group col-lg-12 p-0">
              <div className="col-lg-12 d-flex mb-2 p-0">{this.showSurveys()}</div>
              <div>
                <input
                  type="date"
                  className="form-control mb-2 shadow-sm"
                  name="start"
                  value={this.state.start}
                  onChange={this.onChange}
                />
                <small className="mt-2 text-center">to</small>
                <input
                  type="date"
                  className="form-control mt-2 mb-3 shadow-sm"
                  name="end"
                  value={this.state.end}
                  onChange={this.onChange}
                  id="test"
                />
              </div>

              <button type="button" className="btn btn-green">
                submit
              </button>
            </div>
          </form>

          <div className="col-lg-7 p-0 d-flex" style={{ marginLeft: '22%', marginTop: '-4%' }}>
            <div className="col-lg-8 p-0">
              <div className="flex-wrapper">
                <div className="single-chart ml-3">
                  <svg viewBox="0 0 36 36" className="circular-chart orange mt-3">
                    <path
                      className="circle-bg"
                      d="M18 2.0845
                                      a 15.9155 15.9155 0 0 1 0 31.831
                                      a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <path
                      className="circle"
                      // stroke-dasharray={percentage.score}
                      strokeDasharray={percentage.score}
                      d="M18 2.0845
                                      a 15.9155 15.9155 0 0 1 0 31.831
                                      a 15.9155 15.9155 0 0 1 0 -31.831"
                    />

                    <text x="18" y="19" className="percentage">
                      {percentage.score}
                      <tspan x="19" y="24" className="percentage-words">
                        Net Promoter Score
                      </tspan>
                    </text>

                    {/* <text x="40" y="40" className="percentage">{percentage.score} */}
                    {/* <tspan x="60" y="50" className="percentage-words">Net Promoter Score</tspan> */}
                    {/* </text> */}
                  </svg>
                </div>
              </div>
            </div>

            {/* <div className="col-lg-9 mt-3"> */}
            {/* <div className="col-lg-12 d-flex mb-3"> */}
            {/* <div className="mr-2"> */}
            {/* <label className="copy-font progress-label m-0 p-0 text-right ml-2"><strong>{percentage.percentage_promoters ? Number(percentage.percentage_promoters.toFixed(2)) : null}</strong><br /><small>PROMOTERS</small></label> */}
            {/* </div> */}
            {/* <div className="progress col-lg-11 p-0"> */}
            {/* <div className="progress-bar active" */}
            {/* role="progressbar" aria-valuenow={percentage.percentage_promoters} aria-valuemin="0" */}
            {/* aria-valuemax="100" style={{width: percentage.percentage_promoters, backgroundColor:'#28a745'}}> */}
            {/* </div> */}
            {/* </div> */}
            {/* </div> */}
            {/* <div className="col-lg-12 d-flex mb-3"> */}
            {/* <div className="mr-2"> */}
            {/* <label className="copy-font progress-label m-0 p-0 text-right"><strong>{percentage.percentage_detractors ? Number(percentage.percentage_detractors.toFixed(2)) : null}</strong><br /><small>DETRACTORS</small></label> */}
            {/* </div> */}
            {/* <div className="progress col-lg-11 p-0"> */}
            {/* <div className="progress-bar active" */}
            {/* role="progressbar" aria-valuenow="60" aria-valuemin="0" */}
            {/* aria-valuemax="100" style={{width: percentage.percentage_detractors, backgroundColor:'#de091d'}}> */}
            {/* </div> */}
            {/* </div> */}
            {/* </div> */}
            {/* <div className="col-lg-12 d-flex"> */}
            {/* <div className="mr-2"> */}
            {/* <label className="copy-font progress-label p-0 text-right ml-4"><strong>{passive ? passive : null}</strong><br /><small>PASSIVES</small></label> */}
            {/* </div> */}
            {/* <div className="progress col-lg-11 p-0"> */}
            {/* <div className="progress-bar active" */}
            {/* role="progressbar" aria-valuenow="45" aria-valuemin="0" */}
            {/* aria-valuemax="100" style={{width: passive,backgroundColor:"orange"}}> */}
            {/* </div> */}
            {/* </div> */}
            {/* </div> */}
            {/* </div> */}
          </div>

          {/* <div className="col-lg-12 p-0 mt-5 mb-2 d-flex"> */}
          {/* <div className="col-lg-6 mr-auto mt-2"> */}
          {/* <h6><strong>Latest responses</strong></h6> */}
          {/* </div> */}

          {/* <div className="col-lg-6 ml-auto "> */}
          {/* <div className="d-flex justify-content-end"> */}
          {/* <small className="mr-2">FilterByCountry By:</small> */}
          {/* <select className="form-control w-25"> */}
          {/* <option>Promoter</option> */}
          {/* </select> */}
          {/* </div> */}
          {/* </div> */}
          {/* </div> */}

          {/* <div className="col-lg-12 p-0"> */}
          {/* <div className="card x-panel"> */}
          {/* <div className="table-responsive content"> */}
          {/* <table className="table copy-font"> */}
          {/* <thead> */}
          {/* <tr> */}
          {/* <th>#</th> */}
          {/* <th>Email</th> */}
          {/* <th>Survey</th> */}
          {/* <th>Response</th> */}
          {/* <th>Date</th> */}
          {/* <th>Type</th> */}
          {/* <th>View</th> */}
          {/* </tr> */}
          {/* </thead> */}
          {/* <tbody> */}

          {/* {surveysList} */}

          {/* </tbody> */}
          {/* </table> */}
          {/* </div> */}
          {/* </div> */}
          {/* </div> */}

          {/* <div className="pop-up col-lg-12" data-toggle="modal" data-target="#publicModal"> */}
          {/* <button className="btn btn-green">NPS Tester</button> */}
          {/* </div> */}
          <PublicModal />
        </div>
      </div>
    );
  }
}
export default Surveys;
