import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

class Inventory extends Component {
  render() {
    const {
      name,
      manufacturer,
      model,
      vendor,
      serialNumber,
      warranty,
      firmware,
      date_of_purchase,
      employee_id,
      description,
      configVersion,
      macid,
    } = this.props.inventory;

    return (
      <div
        id="customer_detail"
        className="modal fade in"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center" id="myModalLabel">
                Inventory Details
              </h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
            </div>
            <div className="modal-body detail" style={{ padding: '0' }}>
              <div className="row">
                <div className="col-lg-12 d-flex">
                  <div className="col-lg-4 detail-label1">
                    <label>Name</label>
                    <label style={{ display: 'block' }}>Description</label>
                    <label>Manufacturer</label>
                  </div>

                  <div className="col-lg-8 detail-result1">
                    <p className="mt-4 mb-1">{name}</p>
                    {description ? <p className="mb-1">{description}</p> : <p className="mb-1">N/A</p>}
                    {manufacturer ? <p className="mb-1">{manufacturer}</p> : <p className="mb-1">N/A</p>}
                  </div>
                </div>

                <div className="col-lg-12 d-flex">
                  <div className="col-lg-4 detail-label2">
                    <label>Model</label>
                    <label style={{ display: 'block' }}>Serial Number</label>
                    <label>Mac ID</label>
                    <label style={{ display: 'block' }}>Firmware</label>
                    <label>Vendor</label>
                  </div>

                  <div className="col-lg-8 detail-result2">
                    <p className="mt-4 mb-1">{model}</p>
                    <p className="mb-1">{serialNumber}</p>
                    <p className="mb-1">{macid}</p>
                    <p className="mb-1">{firmware}</p>
                    <p className="mb-1">{vendor}</p>
                  </div>
                </div>

                <div className="col-lg-12 d-flex">
                  <div className="col-lg-4 detail-label3">
                    <label>Date purchased</label>
                    <label>Warranty</label>
                    <label>Config Version</label>
                  </div>

                  <div className="col-lg-8 detail-result3">
                    <p className="mt-4 mb-1">{moment(date_of_purchase).format('MM/DD/YYYY')}</p>
                    <p className="mb-1">{warranty}</p>
                    <p className="mb-1">{configVersion}</p>
                  </div>
                </div>

                {/* <div className="col-lg-12 d-flex">
                                    <div className="col-lg-4 detail-label4">
                                        <label style={{ display: 'block'}}>Employee ID</label>
                                        <label style={{ display: 'block'}}>Employee Name</label>
                                    </div>

                                    <div className="col-lg-8 detail-result4">
                                        <p className="mt-4">{employee_id}</p>
                                        <p>YES</p>
                                    </div>
                                </div> */}
                <div className="modal-footer" style={{ marginLeft: '40%' }}>
                  <Link to="#edit" data-toggle="modal" data-dismiss="modal">
                    <button type="button" style={{ backgroundColor: '#FFA800' }} className="btn btn-green waves-effect">
                      Edit
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Inventory;
