import { GET_LEADS } from '../../../constants/types/shared/leads/LeadsTypes';
import { FETCHING_STARTS, LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';

const initialState = {
  leads: [],
  load: false,
  loadForm: false,
};

export const LeadsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCHING_STARTS:
      return {
        ...state,
        load: payload,
      };

    case GET_LEADS:
      return {
        ...state,
        leads: payload,
        load: false,
      };

    case LOADING_STARTS:
      return {
        ...state,
        loadForm: payload,
      };

    case LOADING_STOPS:
      return {
        ...state,
        loadForm: payload,
      };

    default:
      return state;
  }
};

export default LeadsReducer;
