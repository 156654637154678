/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { Link } from 'react-router-dom';

const InProgress = () => {
  return (
    <div className="container mt-5 pt-4">
      <div className="row mt-5 pt-4">
        <div className="col-12 mt-5 pt-4">
          <div className="text-center mt-5">
            {/* <img src={oops} alt="" /> */}
            <h1 style={{ color: '#50B5AF' }}> In Progress </h1>
            <h5 className="" style={{ color: '#50B5AF' }}>
              The page you requested is coming soon, check back later!
            </h5>
            {/* <div className="ml-2">
            <Link to="/business-development/overview" target="_self">
              <button type="button" className="btn btn-primary">
                Pending Relocation
              </button>
            </Link>
          </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InProgress;
