import axios from 'axios';
import { toast } from 'react-toastify';
import { GET_ZONES_URL } from '../../../services/api/shared/installations/ZonesUrl';
import { GET_ZONES } from '../../../constants/types/shared/zones/ZonesTypes';
import { FETCHING_STARTS } from '../../../constants/types/shared/LoadingTypes';

export const getZones = () => async (dispatch) => {
  dispatch({
    type: FETCHING_STARTS,
    payload: true,
  });

  try {
    const response = await axios.get(`${GET_ZONES_URL}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      dispatch({
        type: GET_ZONES,
        payload: response.data,
      });
    } else {
      toast.info('Something went wrong while fetching the zones');
    }
  } catch (e) {
    console.log(e.response);
  }
};
