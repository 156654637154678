/* eslint-disable */

import React, { useEffect } from 'react';
// import { ToastContainer } from 'react-toastify';
import { useUser } from '../../../../hooks/shared/user/useUser';
import NewDesignSideBar from '../../../layouts/new-design/NewDesignSideBar';
// import NewDesignInstallationCounterBody from './NewDesignInstallationCounterBody';

const NewDesignInstallationCounter = () => {
  const { profile } = useUser();

  return (
    <div className="s-layout">
      <main className="s-layout__content col-sm p-0">
        <NewDesignSideBar currentPage="Installation Counter" position={profile.firstName} />
        <div>{/* <NewDesignInstallationCounterBody /> */}</div>
      </main>
    </div>
  );
};

export default NewDesignInstallationCounter;
