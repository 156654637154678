import React, { useContext } from 'react';
import ListOfBasestationTable from './ListOfBasestationTable';
import AddListOfBasestation from './AddListOfBasestation';
import CustomPagination from '../../../../shared/CustomPagination';
import AppContext from '../../../../../../context/shared/AppContext';

const ListOfBasestationsBody = () => {
  const { loading, paginateChange, pages } = useContext(AppContext);
  console.log(pages);
  const pageChange = (params) => {
    const { selected } = params;
    paginateChange(selected + 1);
  };
  return (
    <div className="container">
      <div className="row mt-5">
        {/* <SearchCnMaestro /> */}
        <div className="form-group ml-2">
          <button className="btn btn-green" type="button" data-toggle="modal" data-target="#add-access-point">
            Add List Of Basestation
          </button>
        </div>
        <AddListOfBasestation />
        <ListOfBasestationTable />
        {!loading && (
          <CustomPagination
            pagesCount={pages}
            marginPagesDisplayed={5}
            pageRangeDisplayed={5}
            onPageChange={pageChange}
          />
        )}
      </div>
    </div>
  );
};

export default ListOfBasestationsBody;
