import React, { useContext } from 'react';
import CustomButton from '../../forms/CustomButton';
import AppContext from '../../../../../context/shared/AppContext';

const SearchCustomersTracker = () => {
  const { loading, onChange, onSubmit, validateSubmit } = useContext(AppContext);

  return (
    <div className="col-12 mb-3">
      <form onSubmit={onSubmit}>
        <div className="col-12">
          <div className="col-12 d-flex">
            {/* <Label classname="copy-font p-0 mr-3" name="Filter By" /> */}
            <div className="form-check mr-2">
              <select name="country" onChange={onChange} className="form-control border border-info py-2">
                <option value="">--Select Country--</option>
                <option value="Ghana">Ghana</option>
                <option value="Nigeria">Nigeria</option>
              </select>
            </div>

            <div className="form-check mr-2">
              <select onChange={onChange} name="type" className="form-control border border-info py-2">
                <option value="">--Select Status--</option>
                <option value="Active">Active</option>
                <option value="Expiration">Expiration</option>
              </select>
            </div>

            {/* <div className="form-check mr-2"> */}
            {/*  <CustomInput */}
            {/*    name="date" */}
            {/*    readOnly={false} */}
            {/*    placeholder="" */}
            {/*    type="date" */}
            {/*    onChange={onChange} */}
            {/*    value={params.date} */}
            {/*    classname="form-control border border-info py-2" */}
            {/*  /> */}
            {/* </div> */}

            <div className=" text-center ml-3">
              {loading ? (
                <CustomButton disabled={loading} classname="btn btn-secondary copy-font" name="Searching..." />
              ) : (
                <CustomButton disabled={!validateSubmit()} classname="btn btn-primary copy-font" name="Search" />
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SearchCustomersTracker;
