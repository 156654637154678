/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import view from '../../../../../../assets/img/icons/view.png';
import ViewReceipt from '../../../../shared/requisition/shared/ViewReceipt';
import CommonRequisitionDetail from '../../../../shared/requisition/shared/CommonRequistionDetails';
import AccountsPersonnelTableHead from './AccountsPersonnelTableHead';
import helpers from '../../../../../../utils/requisition/helpers';

const AccountsPersonnelRequistions = ({ requistions, load }) => {
  const [requisit, setRequisit] = useState({});

  const getRequsition = (req) => {
    setRequisit(req);
  };

  const requsitionList =
    requistions && requistions.length ? (
      requistions.map((requsition, i) => {
        return (
          <tr key={requsition.id}>
            <td>{i + 1}</td>
            {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
            <td>
              {helpers.displayCurrency(requsition.currency)}
              {requsition.amount_in_digits}
            </td>
            <td>{requsition.amount_in_words}</td>
            <td>{requsition.account_approval}</td>
            <td>{requsition.manager_approval}</td>
            <td>{helpers.showCLevelApprovalStatus(requsition.c_level_approval)}</td>
            <td>{requsition.ceo_or_coo_approval}</td>
            <td>{requsition.in_favour_of}</td>
            <td>{requsition.requires_c_level_approval === false ? 'False' : 'True'}</td>
            <td>{requsition.requires_ceo_or_coo_approval === false ? 'False' : 'True'}</td>
            {requsition.created_at ? <td>{moment(requsition.created_at).format('MM/DD/YYYY')}</td> : 'N/A'}
            <td>{requsition.purpose}</td>
            <td>
              <div className="mr-2 ml-2">
                <button
                  data-target="#viewReceipt"
                  data-toggle="modal"
                  type="submit"
                  onClick={() => getRequsition(requsition)}
                  style={{
                    border: '0',
                    backgroundColor: 'white',
                  }}
                >
                  <span className="view2">
                    <img src={view} alt="view" data-toggle="tooltip" data-placement="left" title="View" />
                  </span>
                </button>
                <ViewReceipt requisit={requisit} />
              </div>
            </td>
            {requsition.account_approval === 'approved' ? (
              <td>
                <span className="badge badge-success" style={{ backgroundColor: 'green' }}>
                  Paid
                </span>
              </td>
            ) : null}

            <td>
              <div className="mr-2 ml-2 d-flex">
                <button
                  data-target="#requisition"
                  data-toggle="modal"
                  type="submit"
                  onClick={() => getRequsition(requsition)}
                  style={{
                    border: '0',
                    backgroundColor: 'white',
                  }}
                >
                  <span className="view2">
                    <img src={view} alt="view" data-toggle="tooltip" data-placement="left" title="View" />
                  </span>
                </button>
                <CommonRequisitionDetail requisit={requisit} />
              </div>
            </td>
          </tr>
        );
      })
    ) : (
      <p>No requisitions</p>
    );
  return (
    <div className="card x-panel logs">
      <div className="table-responsive content">
        <table className="table copy-font">
          <AccountsPersonnelTableHead />
          {load ? <small>loading...</small> : requsitionList}
        </table>
      </div>
    </div>
  );
};

AccountsPersonnelRequistions.propTypes = {
  load: PropTypes.bool,
  requistions: PropTypes.shape(),
};
export default AccountsPersonnelRequistions;
