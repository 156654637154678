import React, { Component } from 'react';
import axios from 'axios';
import { API_URL } from '../../../../../utils/config';
import AddRequestFeature from '../../../shared/features/AddRequestFeature';
import NocNavBar from '../../../../layouts/noc/NocNavBar';

export default class NetworkOperationsFeature extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
    };
  }

  componentDidMount() {
    this.getUserDetails();
  }

  getUserDetails() {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };
    axios
      .get(`${API_URL}/employee/loggedOnEmployee`, config)
      .then((res) => {
        if (res.data) {
          this.setState({
            firstName: res.data.firstName,
          });
        }
      })
      .catch((err) => {});
  }

  render() {
    const { firstName } = this.state;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <NocNavBar currentPage="Request A Feature" position={firstName} />

          <div className="container">
            <div className="row mt-5">
              <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-4">
                <AddRequestFeature />
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
