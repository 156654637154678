import React from 'react';
import BusinessDevelopmentDedicatedCustomersBody from '../../../shared/dedicated-customers/BusinessDevelopmentDedicatedCustomersBody';
import CustomerSupportNavBar from '../../../../layouts/customer-support/CustomerSupportNavBar';

const CustomerSupportDedicatedCustomer = () => {
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        < CustomerSupportNavBar currentPage="Dedicated Customers" />
        <BusinessDevelopmentDedicatedCustomersBody />
      </main>
    </div>
  );
};

export default CustomerSupportDedicatedCustomer;