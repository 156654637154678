import React from 'react';
import moment from 'moment';

const IncidentDetailsNew = ({ incident }) => {
  return (
    <div
      id="incidentDetail"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Incident Report Details
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body detail" style={{ padding: '0', textAlign: 'justify' }}>
            <div className="row">
              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label1">
                  <label id="des">Description</label>
                  <label id="affected">System Affected</label>
                  <label id="impact">Impact</label>
                  <label id="duration">Start Time</label>
                  <label id="duration">End Time</label>
                  <label id="date">Date</label>
                  <label id="resolution">Resolution Team</label>
                </div>

                <div className="col-lg-8 detail-result1">
                  <p className="mt-4 mb-1">{incident.description}</p>
                  <p className="mb-1">{incident.system_affected}</p>
                  <p className="mb-1">{incident.impact}</p>
                  <p className="mb-1">{incident.duration_start_time}</p>
                  <p className="mb-1">{incident.duration_end_time}</p>
                  <p className="mb-1">{moment(incident.created_at).format('YYYY-MM-DD')}</p>
                  <p className="mb-1">{incident.resolution_team}</p>
                </div>
              </div>
              <hr />

              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label3">
                  <label id="plan">Root Cause</label>
                  <label id="source">Fix Actions</label>
                  <label id="notes">Changes Made</label>
                  <label id="confirmation">Confirmation</label>
                </div>

                <div className="col-lg-8 detail-result3">
                  <p className="mt-4 mb-1">{incident.root_cause}</p>
                  <p className="mb-1">{incident.fix_actions}</p>
                  <p className="mb-1">{incident.changes_made}</p>
                  <p className="mb-1">
                    {incident.confirmation_of_resolution ? 'Resolved' : 'Not Resolved'}
                  </p>
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label4">
                  <label id="date">Cause of Incident</label>
                  <label id="prevention">Prevention</label>
                  <label id="additional">Additional Actions</label>
                </div>

                <div className="col-lg-8 detail-result4">
                  <p className="mt-4 mb-1">{incident.cause_of_incident}</p>
                  <p className="mb-1">{incident.incidence_prevention}</p>
                  <p className="mb-1">{incident.additional_action}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncidentDetailsNew;
