/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-named-as-default */
import { useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { userReducer } from '../../../../reducers/user/userReducer';
import { ADD_PROFILE_URL, EDIT_PROFILE_URL } from '../../../../services/api/user/UserUrl';
import { LOADING_STARTS, LOADING_STOPS } from '../../../../constants/types/shared/LoadingTypes';
import { useUser } from '../useUser';
import moment from 'moment';
export const useProfile = () => {
  const profileState = {
    department: '',
    address: '',
    alternateEmail: '',
    alternatePhoneNumber: '',
    birthday: '',
    date_of_resumption: '',
    extension: '',
    firstName: '',
    gender: '',
    lastName: '',
    phoneNumber: '',
    team_id: '',
    beneficiary: '',
    referee: '',
    guarantor: '',
    level: '',
    reporting_line: '',
    email: '',
    officialEmail: '',
    nationality: '',
    country: '',
    state: '',
    role: '',
  };

  const [{ load }, dispatch] = useReducer(userReducer, {
    load: false,
  });

  const { profile } = useUser();

  const [userProfile, setUserProfile] = useState(profileState);

  const [button, setButton] = useState('');

  const [gender, setGender] = useState('');

  useEffect(() => {
    const {
      address,
      // departments,
      alternateEmail,
      alternatePhoneNumber,
      birthday,
      date_of_resumption,
      extension,
      firstName,
      lastName,
      phoneNumber,
      team_id,
      beneficiary,
      referee,
      guarantor,
      designation,
      level,
      reporting_line,
      gender,
      email,
      officialEmail,
      nationality,
      country,
      state,
      role,
    } = profile;

    if (firstName) {
      setButton('Edit Profile');
      setUserProfile({
        address,
        alternateEmail,
        alternatePhoneNumber,
        birthday,
        date_of_resumption,
        extension,
        firstName,
        gender,
        lastName,
        phoneNumber,
        team_id,
        beneficiary,
        referee,
        guarantor,
        designation,
        level,
        reporting_line,
        email,
        officialEmail,
        nationality,
        country,
        state,
        role,
      });
    } else {
      setButton('Add Profile');
    }
  }, [gender, profile]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setUserProfile({
      ...userProfile,
      [name]: value,
    });
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setGender({
      // eslint-disable-next-line react/no-access-state-in-setstate
      ...gender,
      gender: value,
    });
  };

  // eslint-disable-next-line consistent-return
  const addProfile = async (data) => {
    dispatch({
      type: LOADING_STARTS,
    });
    try {
      const response = await axios.post(`${ADD_PROFILE_URL}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      // status: "failed"
      // message: "Please enter lastname"

      if (response.data.status === 'failed') {
        toastr.error(response.data.message);
        dispatch({
          type: LOADING_STOPS,
        });
      } else if (response.data.message === 'success') {
        toastr.success('Profile added');
        dispatch({
          type: LOADING_STOPS,
        });
      } else {
        toastr.info('Unable to create your profile!');
        dispatch({
          type: LOADING_STOPS,
        });
      }
    } catch (e) {
      if (e) {
        toastr.error(`Something went wrong while adding profile`);
        dispatch({
          type: LOADING_STOPS,
        });
      }
    }
  };

  const editProfile = async (data, employee_id) => {
    // console.log(data.gender.gender);
    dispatch({
      type: LOADING_STARTS,
    });
    try {
      const response = await axios.put(
        `${EDIT_PROFILE_URL}`,
        { ...data, employee_id },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );

      if (response.data.status === 'success') {
        toastr.success(`Editing profile successful!`);
        setTimeout(() => {
          // navigate to another location
          window.location.reload();
        }, 1000);
        dispatch({
          type: LOADING_STOPS,
        });
      } else {
        toastr.info(`Unable to edit profile!`);
        dispatch({
          type: LOADING_STOPS,
        });
      }
    } catch (e) {
      if (e) {
        toastr.error(`Something went wrong while trying to edit profile!`);

        dispatch({
          type: LOADING_STOPS,
        });
      }
    }
  };

  // const handleInputChange = event => {
  //   const { name, value } = event.target
  //
  //   setUser({ ...user, [name]: value })
  // }

  // const handleChange = (e) => {
  //     this.setState({
  //       ...this.state,
  //       gender: event.target.value,
  //     });
  //
  // }

  // const handleChange = (e) => {
  //   const { value } = e.target;
  //   setGender({
  //     // eslint-disable-next-line react/no-access-state-in-setstate
  //     ...gender,
  //     gender: value,
  //   });
  // };

  return { onChange, userProfile, addProfile, editProfile, handleChange, gender, button, load };
};
