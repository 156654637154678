import axios from 'axios';
import toastr from 'toastr';
import { toast } from 'react-toastify';
import {
  ADD_FEATURE_REQUEST,
  GET_FEATURE_REQUESTS,
  START_FETCHING_FEATURE_REQUESTS,
  STOP_FETCHING_FEATURE_REQUESTS,
} from '../../../constants/types/research-and-development/feature-request/FeatureRequestTypes';
import {
  ADD_FEATURE_REQUEST_URL,
  GET_FEATURE_REQUESTS_BY_DEPARTMENTS_URL,
  GET_FEATURE_REQUESTS_URL,
  UPDATE_FEATURE_REQUEST_URL,
} from '../../../services/api/research-and-development/FeatureRequestUrl';

export const getFeatureRequests = () => {
  return async (dispatch) => {
    dispatch({
      type: START_FETCHING_FEATURE_REQUESTS,
      payload: true,
    });

    try {
      const response = await axios.get(`${GET_FEATURE_REQUESTS_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.status === 'success') {
        dispatch({
          type: GET_FEATURE_REQUESTS,
          payload: response.data.data,
        });
      } else {
        toast.info('Unable to get features!');
        dispatch({
          type: STOP_FETCHING_FEATURE_REQUESTS,
          payload: false,
        });
      }
    } catch (e) {
      if (e) {
        toast.info('Something went wrong. Try again later!');
        dispatch({
          type: STOP_FETCHING_FEATURE_REQUESTS,
          payload: false,
        });
      }
    }
  };
};

export const searchRequestsByDepartments = (data) => {
  return async (dispatch) => {
    dispatch({
      type: START_FETCHING_FEATURE_REQUESTS,
      payload: true,
    });

    try {
      const response = await axios.get(`${GET_FEATURE_REQUESTS_BY_DEPARTMENTS_URL}/${data}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.status === 'success') {
        dispatch({
          type: GET_FEATURE_REQUESTS,
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: STOP_FETCHING_FEATURE_REQUESTS,
          payload: false,
        });
      }
    } catch (e) {
      if (e) {
        dispatch({
          type: STOP_FETCHING_FEATURE_REQUESTS,
          payload: false,
        });
      }
    }
  };
};

export const updateFeatureRequest = (data, feature) => async (dispatch) => {
  try {
    const response = await axios.patch(`${UPDATE_FEATURE_REQUEST_URL}/${feature.id}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data.status === 'success') {
      toastr.success('Updated!', 'Status', {
        timeOut: 1000,
        fadeOut: 1000,
        onHidden() {
          window.location.reload();
        },
      });
    } else {
      toastr.info('Try Again!', 'Please', {
        timeOut: 1000,
        fadeOut: 1000,
        onHidden() {
          window.location.reload();
        },
      });
    }
  } catch (e) {
    console.log(e);
  }
};

export const addFeatureRequest = (data) => async (dispatch) => {
  const response = await axios.post(`${ADD_FEATURE_REQUEST_URL}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
  });

  if (response.data.status === 'success') {
    toastr.success('Feature Request Added!');

    dispatch({
      type: ADD_FEATURE_REQUEST,
      payload: response.data.status,
    });
  } else {
    toastr.info('Something went wrong!');
  }
};
