/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import '../../../../../styles/radio.css';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { stringify } from 'qs';
import { addCallLog, getCallLogs } from '../../../../../actions/shared/call-logs/CallLogsActions';
import { addLog, getCountries, getLogs, getCities } from '../../../../../actions/sales/logs/LogsActions';
import { getLoggedInUser } from '../../../../../actions/user/UserActions';

class AddLog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      first: '',
      last: '',
      address: '',
      email: '',
      alternateEmail: '',
      phone: '',
      alternatePhone: '',
      referral: '',
      state: '',
      source: '',
      comment: '',
      country: '',
      status: '',
      about: '',
      plan: '',
      // dateOfBirth: '',
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    // this.props.getStates();
    this.props.getLoggedInUser();
    this.props.getCountries();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.status === 'success') {
      this.setState({
        first: '',
        last: '',
        address: '',
        email: '',
        phone: '',
        alternatePhone: '',
        alternateEmail: '',
        referral: '',
        state: '',
        source: '',
        comment: '',
        country: '',
        status: '',
        about: '',
        // dateOfBirth: '',
      });

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  handleSpaceBar = (e) => {
    if (e.keyCode === 32) e.preventDefault();
  };

  handleNumber = (e) => {
    const { name, value } = e.target;
    if (isNaN(Number(value))) return;
    this.setState({ [name]: value });
  };

  createLog = (e) => {
    e.preventDefault();

    const {
      first,
      last,
      address,
      email,
      alternateEmail,
      phone,
      alternatePhone,
      referral,
      state,
      comment,
      country,
      status,
      about,
      plan,
      // dateOfBirth,
    } = this.state;
    const { employee } = this.props;
    const { id } = employee;

    const data = {
      firstName: first,
      lastName: last,
      address,
      email,
      alternateEmail,
      phoneNumber: phone,
      alternatePhoneNumber: alternatePhone,
      // date_of_birth: dateOfBirth,
      referral,
      language: 'english',
      state,
      comment,
      country: country || 'ng',
      status,
      about_us: about,
      allowEmail: true,
      plan,
      get_email: 'yes',
      employee_auth_id: id,
    };

    this.props.addLog(data);
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.setState({
      loading: true,
    });

    const today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1; // January is 0!
    // const yyyy = today.getFullYear();

    if (dd < 10) {
      dd = `0${dd}`;
    }

    if (mm < 10) {
      mm = `0${mm}`;
    }

    // const today = mm + '/' + dd + '/' + yyyy;

    let date;
    let TimeType;
    let hour;
    let minutes;
    // let seconds;
    let fullTime;

    date = new Date();
    hour = date.getHours();

    // Checking if the Hour is less than equals to 11 then Set the Time format as AM.
    if (hour <= 11) {
      TimeType = 'AM';
    } else {
      // If the Hour is Not less than equals to 11 then Set the Time format as PM.
      TimeType = 'PM';
    }

    // IF current hour is grater than 12 then minus 12 from current hour to make it in 12 Hours Format.
    if (hour > 12) {
      hour -= 12;
    }

    // If hour value is 0 then by default set its value to 12, because 24 means 0 in 24 hours time format.
    if (hour === 0) {
      hour = 12;
    }

    // Getting the current minutes from date object.
    minutes = date.getMinutes();

    // Checking if the minutes value is less then 10 then add 0 before minutes.
    if (minutes < 10) {
      minutes = `0${minutes.toString()}`;
    }

    // Adding all the variables in fullTime variable.
    fullTime = `${hour.toString()}:${minutes.toString()} ${TimeType.toString()}`;

    const {
      firstName,
      lastName,
      email,
      status,
      phone,
      notes,
      source,
      department,
      plan,
      address,
      reference,
      othernumber,
    } = this.state;

    const newCallLog = {
      firstName,
      lastName,
      email,
      status,
      phoneNumber: phone,
      note: notes,
      source,
      department_id: department,
      plan,
      address,
      reference_number: reference,
      alternate_phone_number: othernumber,
    };

    this.props.addCallLog(newCallLog);
  };

  submitForm = () => {
    const { first, last, address, email, phone, source, plan, status, country, state } = this.state;
    return (
      first.length > 0 &&
      last.length > 0 &&
      address.length > 0 &&
      email.length > 0 &&
      phone.length > 0 &&
      status.length > 0 &&
      source.length > 0 &&
      country.length > 0 &&
      state.length > 0 &&
      // dateOfBirth.length > 0 &&
      plan.length > 0
    );
  };

  static propType = {
    addLog: PropTypes.func.isRequired,
    // getStates: PropTypes.func.isRequired,
  };

  onStateRegionChange = (e) => {
    if (e.target.value) {
      this.props.getCities(
        stringify({
          state: e.target.value,
        }),
      );
    }
  };

  displayStateOrRegion = () => {
    if (this.state.country === 'gh') {
      return (
        <div className="col-lg-6">
          <label className="copy-font">City</label>
          <select
            name="state"
            onChange={(e) => {
              this.onChange(e);
              this.onStateRegionChange(e);
            }}
            className="form-control"
            required
          >
            <option value="">--Choose--</option>
            {this.props.countries
              .filter((country) => country.country === 'Ghana')
              .map((state) => (
                <option value={state.state}>{state.state}</option>
              ))}
          </select>
        </div>
      );
    }

    return (
      <div className="col-lg-6">
        <label className="copy-font">State</label>
        <select
          required
          name="state"
          onChange={(e) => {
            this.onChange(e);
            this.onStateRegionChange(e);
          }}
          className="form-control"
        >
          <option value="">--Choose State--</option>
          {this.props.countries
            .filter((country) => country.country === 'Nigeria')
            .map((state) => (
              <option value={state.state}>{state.state}</option>
            ))}
        </select>
      </div>
    );
  };

  displayCities = () => {
    if (this.props.gettingCities) {
      return <small style={{ margin: 'auto', fontSize: '16px' }}>Getting Cities</small>;
    }
    return (
      <div className="col-lg-6">
        <label className="copy-font">Select City</label>
        <select name="city" onChange={this.onChange} className="form-control" required>
          <option>Select City</option>
          {this.props.cities.map((city) => (
            <option value={city.city}>{city.city}</option>
          ))}
        </select>
      </div>
    );
  };

  render() {
    const {
      first,
      last,
      address,
      email,
      alternateEmail,
      phone,
      alternatePhone,
      comment,
      plan,
      country,
      // dateOfBirth,
    } = this.state;

    const { load } = this.props;

    const isEnabled = this.submitForm();

    return (
      <div className="modal-body p-0">
        <form onSubmit={this.createLog} className="form-horizontal pb-3">
          <div className="form-group add-log">
            <div className="col-md-12 mb-1 d-flex">
              <div className="col-lg-6 mb-3">
                <label className="copy-font">First Name</label>
                <input
                  type="text"
                  name="first"
                  onChange={this.onChange}
                  className="form-control"
                  value={first}
                  placeholder="First Name"
                  required
                />
              </div>

              <div className="col-lg-6 mb-3">
                <label className="copy-font">Last Name</label>
                <input
                  onChange={this.onChange}
                  value={last}
                  type="text"
                  className="form-control"
                  name="last"
                  placeholder="Last Name"
                  required
                />
              </div>
            </div>

            <div className="col-md-12 mb-3 d-flex">
              <div className="col-lg-6">
                <label className="copy-font">Email address</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email address"
                  name="email"
                  value={email}
                  onChange={this.onChange}
                  required
                />
              </div>

              <div className="col-lg-6">
                <label className="copy-font">Alternate Email address</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email address"
                  name="alternateEmail"
                  value={alternateEmail}
                  onChange={this.onChange}
                />
              </div>
            </div>

            <div className="col-md-12 mb-3 d-flex">
              <div className="col-lg-12">
                <label className="copy-font">Address</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={this.onChange}
                  name="address"
                  value={address}
                  placeholder="Permanent home address"
                  required
                />
              </div>
            </div>

            <div className="col-md-12 mb-1 d-flex">
              <div className="col-lg-6">
                <div className="mb-2 copy-font">
                  <label className="copy-font">Plan</label>
                  <div className="d-flex">
                    <input
                      id="radio-button"
                      checked={plan === 'home'}
                      onChange={this.onChange}
                      name="plan"
                      value="home"
                      className="check"
                      type="radio"
                      style={{
                        marginTop: '3px',
                        verticalAlign: 'text-bottom',
                      }}
                    />
                    <span className="ml-1">Home</span>
                    <input
                      id="radio-button"
                      className="check"
                      onChange={this.onChange}
                      name="plan"
                      value="office"
                      checked={plan === 'office'}
                      type="radio"
                      style={{
                        marginTop: '3px',
                        marginLeft: '10%',
                        verticalAlign: 'text-bottom',
                      }}
                    />
                    <span className="ml-1">Office</span>
                    <input
                      id="radio-button"
                      className="check"
                      checked={plan === 'enterprise'}
                      onChange={this.onChange}
                      name="plan"
                      value="enterprise"
                      type="radio"
                      style={{
                        marginTop: '3px',
                        marginLeft: '10%',
                        verticalAlign: 'text-bottom',
                      }}
                    />
                    <span className="ml-1">Enterprise</span>
                    <input
                      id="radio-button"
                      className="check"
                      checked={plan === 'LTE'}
                      onChange={this.onChange}
                      name="plan"
                      value="LTE"
                      type="radio"
                      style={{
                        marginTop: '3px',
                        marginLeft: '10%',
                        verticalAlign: 'text-bottom',
                      }}
                    />
                    <span className="ml-1">LTE</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 mb-3 d-flex">
              <div className="col-lg-6">
                <label className="copy-font">Status</label>
                <select name="status" onChange={this.onChange} className="form-control" required>
                  <option value="">--Select Status--</option>
                  <option value="lead">Paid </option>
                  <option value="expecting_payment">Expecting Payment</option>
                  <option value="revert_back">Revert</option>
                  <option value="no_coverage">No Coverage</option>
                  <option value="not_interested">Not Interested</option>
                  <option value="month_end">Month End</option>
                </select>
              </div>

              <div className="col-lg-6">
                <label className="copy-font">Referral</label>
                <select className="form-control" name="source" onChange={this.onChange} required>
                  <option value="">--Select Referral--</option>
                  <option value="Google Ads">Google Ads</option>
                  <option value="Facebook">Facebook</option>
                  <option value="Flyer">Flyer</option>
                  <option value="Billboard">Billboard</option>
                  <option value="Mobile Advert">Mobile Advert</option>
                  <option value="Friend">Friend</option>
                  <option value="Online">Online</option>
                  <option value="Television">Television</option>
                  <option value="Radio">Radio</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>

            <div className="col-md-12 mb-3 d-flex">
              <div className="col-lg-6">
                <label className="copy-font">Country</label>
                <select name="country" onChange={this.onChange} className="form-control" required>
                  <option value="">--Choose Country--</option>
                  {this.props.countries
                    .filter((item, index, i) => i.findIndex((t) => t.country === item.country) === index)
                    .map((country) => (
                      <option value={country.country === 'Ghana' ? 'gh' : 'ng'}>{country.country}</option>
                    ))}
                </select>
              </div>
              {/* <div className="col-6">
                      <label className="copy-font m-0 p-0">State</label>
                      <select name="state" className="form-control" onChange={this.onChange}>
                        <option value="">Select State</option>
                        <option value="lagos">Lagos</option>
                        <option value="ogun">Ogun</option>
                        <option value="rivers">Rivers</option>
                        <option value="edo">Edo</option>
                        <option value="oyo">Oyo</option>

                        {states ? states.map((state) => <option value={state.name}>{state.name}</option>) : null}
                      </select>
                     
                    </div> */}
              {this.displayStateOrRegion()}
            </div>

            <div className="col-md-12 mb-3 d-flex">{this.displayCities()}</div>

            <div className="col-md-12 mb-3 d-flex">
              <div className="col-lg-6">
                <label className="copy-font">Phone number</label>
                <input
                  type="text"
                  name="phone"
                  className="form-control"
                  placeholder={country === 'gh' ? '02xxxxxxxx' : '08012345678'}
                  maxLength="11"
                  minLength="10"
                  value={phone}
                  onKeyDown={this.handleSpaceBar}
                  onChange={this.handleNumber}
                  pattern={country === 'gh' ? '0[2-9]{1}[0-9]{8}' : '0[789]{1}[01]{1}[0-9]{8}'}
                  title={
                    country === 'gh'
                      ? 'Please enter a valid Ghanaian phone number without country code (0....)'
                      : 'Please enter a valid Nigerian phone number without country code (0....)'
                  }
                  required
                />
              </div>

              <div className="col-lg-6">
                <label className="copy-font">Alternate Phone number</label>
                <input
                  type="text"
                  name="alternatePhone"
                  value={alternatePhone}
                  maxLength="11"
                  minLength="10"
                  onKeyDown={this.handleSpaceBar}
                  onChange={this.handleNumber}
                  className="form-control"
                  placeholder={country === 'gh' ? '02xxxxxxxx' : '08012345678'}
                />
              </div>
            </div>

            {/* <div className="col-md-12 mb-3 d-flex">
              <div className="col-lg-6">
                <label className="copy-font">Date Of Birth</label>
                <input type="date" name="dateOfBirth" className="form-control" onChange={this.onChange} value={dateOfBirth} required />
              </div>
            </div> */}

            <div className="col-md-12 mb-3 d-flex">
              <div className="col-lg-12">
                <label className="copy-font">Comment</label>
                <textarea
                  rows="2"
                  cols="200"
                  value={comment}
                  name="comment"
                  onChange={this.onChange}
                  className="form-control"
                  placeholder="Comment"
                />
              </div>
            </div>
          </div>

          <div className="text-center">
            {load ? (
              <button type="button" className="btn btn-green copy-font" disabled={load}>
                <i className="fa fa-spinner" />
              </button>
            ) : (
              <button className="btn btn-green waves-effect" disabled={!isEnabled}>
                Add Log
              </button>
            )}
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  load: state.salesLogs.load,
  status: state.salesLogs.success,
  states: state.states.states,
  employee: state.auth.auth,
  countries: state.salesLogs.countries,
  cities: state.salesLogs.cities,
  gettingCities: state.salesLogs.gettingCities,
});

export default connect(mapStateToProps, {
  getCallLogs,
  getLogs,
  addCallLog,
  addLog,
  // getStates,
  getCountries,
  getLoggedInUser,
  getCities,
})(AddLog);
