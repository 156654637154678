import React, { Component } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { API_URL } from '../../../../../utils/config';

class EditInventory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      id: '',
      manufacturer: '',
      model: '',
      serialnumber: '',
      description: '',
      macid: '',
      firmware: '',
      vendor: '',
      date: '',
      warranty: '',
      configversion: '',
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  componentWillReceiveProps(nextProps) {
    this.setState({
      name: nextProps.inventory.name,
      id: nextProps.inventory.id,
      manufacturer: nextProps.inventory.manufacturer,
      model: nextProps.inventory.model,
      serialnumber: nextProps.inventory.serialNumber,
      description: nextProps.inventory.description,
      macid: nextProps.inventory.macid,
      firmware: nextProps.inventory.firmware,
      vendor: nextProps.inventory.vendor,
      date: nextProps.inventory.date_of_purchase,
      warranty: nextProps.inventory.warranty,
      configversion: nextProps.inventory.configVersion,
    });
  }

  onSubmit(e) {
    e.preventDefault();

    const { id } = this.state;

    this.setState({
      loading: true,
    });

    const data = {
      configVersion: this.state.configversion,
      /* employee_id: this.state.installer, */
      date_of_purchase: this.state.date,
      firmware: this.state.firmware,
      description: this.state.description,
      macid: this.state.macid,
      manufacturer: this.state.manufacturer,
      model: this.state.model,
      name: this.state.name,
      serialNumber: this.state.serialnumber,
      vendor: this.state.vendor,
      warranty: this.state.warranty,
    };

    axios({
      method: 'put',
      url: `${API_URL}/inventory/${id}`,
      data,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
      .then((res) => {
        if (res.data.message === 'success') {
          toastr.success('Operation Successful');
          window.location.reload();
        }
      })
      .catch((err) => {});
  }

  render() {
    const {
      /* name,
        id,
        manufacturer,
        model,
        vendor,
        serialNumber,
        warranty,
        firmware,
        date_of_purchase,
        employee_id,
        description,
        configVersion,
        macid */
    } = this.props.inventory;
    const { loading } = this.state;

    return (
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Edit Inventory
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={this.onSubmit} className="form-horizontal form-material">
              <div className="form-group invent">
                <div className="col-md-12 mb-3 d-flex">
                  <div className="col-lg-6">
                    <label className="copy-font">Name</label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      value={this.state.name}
                      onChange={this.onChange}
                    />
                  </div>

                  <div className="col-lg-6">
                    <label className="copy-font">Manufacturer</label>
                    <input
                      type="text"
                      name="manufacturer"
                      className="form-control"
                      value={this.state.manufacturer}
                      onChange={this.onChange}
                    />
                  </div>
                </div>

                <div className="col-md-12 mb-3 d-flex">
                  <div className="col-lg-6">
                    <div className="mb-4">
                      <label className="copy-font">Model</label>
                      <input
                        type="text"
                        className="form-control"
                        name="model"
                        value={this.state.model}
                        onChange={this.onChange}
                      />
                    </div>

                    <div>
                      <label className="copy-font">Serial number</label>
                      <input
                        type="text"
                        className="form-control"
                        name="serialnumber"
                        value={this.state.serialnumber}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <label className="copy-font">Description</label>
                    <textarea
                      className="form-control"
                      rows="5"
                      cols="20"
                      name="description"
                      value={this.state.description}
                      onChange={this.onChange}
                    />
                  </div>
                </div>

                <div className="col-md-12 mb-3 d-flex">
                  <div className="col-lg-6">
                    <label className="copy-font">MAC ID</label>
                    <input
                      type="text"
                      className="form-control"
                      name="macid"
                      value={this.state.macid}
                      onChange={this.onChange}
                    />
                  </div>

                  <div className="col-lg-6">
                    <label className="copy-font">Firmware</label>
                    <input
                      type="text"
                      className="form-control"
                      name="firmware"
                      value={this.state.firmware}
                      onChange={this.onChange}
                    />
                  </div>
                </div>

                <div className="col-md-12 mb-3 d-flex">
                  <div className="col-lg-6">
                    <label className="copy-font">Vendor</label>
                    <input
                      type="text"
                      className="form-control"
                      name="vendor"
                      value={this.state.vendor}
                      onChange={this.onChange}
                    />
                  </div>

                  <div className="col-lg-6">
                    <label className="copy-font">Date of purchase</label>
                    <input type="date" name="date" value={this.state.date} onChange={this.onChange} />
                  </div>
                </div>

                <div className="col-md-12 mb-3 d-flex">
                  <div className="col-lg-6">
                    <label className="copy-font">Warranty</label>
                    <input
                      type="text"
                      name="warranty"
                      value={this.state.warranty}
                      onChange={this.onChange}
                      className="form-control"
                    />
                  </div>

                  <div className="col-lg-6">
                    <label className="copy-font">Config Version</label>
                    <input
                      type="text"
                      name="configversion"
                      onChange={this.onChange}
                      className="form-control"
                      value={this.state.configversion}
                    />
                  </div>
                </div>
              </div>

              <div className="text-center">
                {loading ? (
                  <button className="btn btn-green copy-font" id="login">
                    <i className="fa fa-circle-o-notch fa-spin" />
                  </button>
                ) : (
                  <button className="btn btn-green">Edit Inventory</button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default EditInventory;
