/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import edit from '../../../../../assets/img/icons/edit.png';
import UpdateAccessPoints from './UpdateAccessPoints';
import view from '../../../../../assets/img/icons/view.png';

const AccessPointsTable = ({ points }) => {
  const styles = {
    editStyles: {
      border: '0',
      backgroundColor: 'white',
    },
    updateStyles: {
      overflow: 'auto',
    },
  };
  const [one, setOne] = useState({});

  const getPoint = (point) => {
    setOne(point);
  };
  const accessList = points ? (
    points.map((point, i) => (
      <tr key={i + 1}>
        <td>{i + 1}</td>
        <td>{point.ap_ssid}</td>
        <td>{point.ip_address}</td>
        <td>{point.https}</td>
        <td className="text-nowrap">{point.radio_type}</td>
        <td>{point.frequency}</td>
        <td>{point.country}</td>
        <td className="text-center">
          <button type="submit" data-toggle="modal" data-target="#updateAccess" style={styles.editStyles}>
            <span className="view">
              <img
                src={edit}
                alt=""
                onClick={() => getPoint(point)}
                data-toggle="tooltip"
                data-placement="top"
                title="Edit"
              />
            </span>
          </button>
          <div
            id="updateAccess"
            className="modal fade in"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myModalLabel"
            aria-hidden="true"
            style={styles.updateStyles}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title text-center" id="myModalLabel">
                    Edit Access Point
                  </h4>
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    ×
                  </button>
                </div>
                <UpdateAccessPoints one={one} />
              </div>
            </div>
          </div>

          <button type="submit" data-toggle="modal" data-target="#viewDetails" className="ml-1">
            <span className="view">
              <img
                src={view}
                alt="view"
                // onClick={() => this.getPoint(point)}
                data-toggle="tooltip"
                data-placement="top"
                title="View"
              />
            </span>
          </button>

          <div className="modal mt-4" id="viewDetails">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title text-center">View</h4>
                  <button type="button" className="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>

                <div className="modal-body">
                  <div className="row mb-3">
                    <div className="col-lg-12 d-flex text-left">
                      <div className="col-lg-4 detail-label2">
                        <label id="">Username:</label>
                        <label id="">Password:</label>
                      </div>

                      <div className="col-lg-8 detail-result1">
                        <p className="mt-4 mb-2">{point.username}</p>
                        <p className="mb-1">{point.password}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td>
        <p>No access points</p>
      </td>
    </tr>
  );
  return <tbody>{accessList}</tbody>;
};

AccessPointsTable.propTypes = {
  points: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
export default AccessPointsTable;
