/* eslint-disable @typescript-eslint/camelcase */
import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as config from '../../../../../../utils/config';
import {
  EXECUTIVE_UPDATE_REQUSITION_STATUS_URL,
  MANAGER_UPDATE_REQUSITION_STATUS_URL,
} from '../../../../../../services/api/shared/requisitions/RequisitionsUrl';
import { ACCOUNT_MANAGER_UPDATE_REQUSITION_STATUS_URL } from '../../../../../../services/api/accounts/requisition/RequisitionUrl';

const useViewRequsition = () => {
  const { id } = useParams();
  const [approving, setApproving] = useState(false);
  const [disapproving, setDisapproving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const giveManagerApproval = async (param) => {
    setApproving(true);
    try {
      await axios
        .patch(`${MANAGER_UPDATE_REQUSITION_STATUS_URL}/${id}`, param, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        })
        .then((response) => {
          if (response.data) {
            toast('Requisition Approved');
            setApproving(false);
          }
        })
        .catch((e) => {
          if (e) {
            toast.info(e.response.data.message);
            setApproving(false);
          }
        });
    } catch (e) {
      toast('Something went wrong while approving');
    }
  };
  const giveManagerDisApproval = async (param) => {
    setDisapproving(true);
    await axios
      .patch(`${MANAGER_UPDATE_REQUSITION_STATUS_URL}/${id}`, param, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      })
      .then((response) => {
        if (response.data) {
          setDisapproving(false);
          toast.success('Requisition Rejected');
        }
      })
      .catch((e) => {
        if (e) {
          toast.info('Requisition Rejected');
          setDisapproving(false);
        }
      });
  };
  const refetch = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${config.API_URL}/requisition2/search/searchbypurpose/1`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );
      if (response.data) {
        setData(response.data);
        setLoading(false);
      } else {
        toast.error('Unable to get data');
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      toast.error('Something went wrong');
    }
  };
  const accountManagerApproval = async (params) => {
    setApproving(true);
    try {
      const response = await axios.patch(`${ACCOUNT_MANAGER_UPDATE_REQUSITION_STATUS_URL}/${id}`, params, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        setApproving(true);
        toast.success('Requisition Approved');
      }
    } catch (e) {
      if (e) {
        toast.info(e.response.data.message);
        setApproving(false);
      }
    }
  };
  const accountManagerRejection = async (params) => {
    setDisapproving(true);

    try {
      const response = await axios.patch(`${ACCOUNT_MANAGER_UPDATE_REQUSITION_STATUS_URL}/${id}`, params, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        toast.success('Requsition Rejected');
        setDisapproving(false);
      }
    } catch (e) {
      if (e) {
        toast.info(e.response.data.message);
        setDisapproving(false);
      }
    }
  };

  const giveExecutiveApproval = async (params) => {
    setApproving(true);
    try {
      const response = await axios.post(`${EXECUTIVE_UPDATE_REQUSITION_STATUS_URL}/${id}`, params, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        toast.success('Requisition Approved');
        setApproving(false);
      }
    } catch (e) {
      if (e && e.response.data.message) {
        toast.info(e.response.data.message);
        setApproving(false);
      } else {
        toast('Something went wrong');
      }
    }
  };

  const giveExecutiveDisApproval = async (params) => {
    setDisapproving(true);

    try {
      const response = await axios.post(`${EXECUTIVE_UPDATE_REQUSITION_STATUS_URL}/${id}`, params, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        toast.success('Requsition Rejected');
        setDisapproving(false);
      }
    } catch (e) {
      if (e) {
        toast.info(e.response.data.message);
        setDisapproving(false);
      }
    }
  };
  useEffect(() => {
    async function getData() {
      setLoading(true);
      try {
        return await axios.post(
          `${config.API_URL}/requisition2/search/searchbypurpose/1`,
          { id },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        );
      } catch (e) {
        return e;
      }
    }
    getData().then((response) => {
      if (response.data) {
        setData(response.data);
        setLoading(false);
      } else {
        toast.error('Unable to get data');
        setLoading(false);
      }
    });
  }, [id]);
  return {
    giveExecutiveDisApproval,
    giveExecutiveApproval,
    giveManagerDisApproval,
    giveManagerApproval,
    disapproving,
    approving,
    refetch,
    data,
    loading,
    accountManagerApproval,
    accountManagerRejection,
  };
};
export default useViewRequsition;
