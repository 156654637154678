/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';

export default class EditStatic extends Component {
  render() {
    return (
      <div
        id="editStatic"
        className="modal fade in"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center" id="myModalLabel">
                Edit Static IP
              </h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
            </div>
            <div className="modal-body">
              <form className="form-horizontal" onSubmit={this.onSubmit}>
                <div className="form-group">
                  <div className="col-lg-12 d-flex mb-3">
                    <div className="col-lg-6">
                      <label className="col-lg-12 text-justify copy-font m-0 p-0">Name</label>
                      <input type="text" className="form-control" placeholder="Name p-2" name="ipaddress" required />
                    </div>

                    <div className="col-lg-6">
                      <label className="col-lg-12 text-justify copy-font m-0 p-0">Public IP Address</label>
                      <input
                        type="text"
                        className="form-control p-2"
                        placeholder="Public IP Address"
                        name="ipaddress"
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 d-flex">
                    <div className="col-lg-6">
                      <label className="col-lg-12 text-justify copy-font m-0 p-0">Private IP</label>
                      <input type="text" min="0" className="form-control p-2" placeholder="Private IP Address" />
                    </div>
                  </div>
                </div>

                <div className="text-center mt-4">
                  <button className="btn btn-second" type="button">
                    Edit Static IP
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
