/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-shadow */
/* eslint-disable react/require-default-props */
/* eslint-disable no-undef */
/* eslint-disable react/button-has-type */
/* eslint-disable no-case-declarations */
import React from 'react';
import useSearch from '../../../../../hooks/shared/custom/data/geniatech/useSearch';
import CallReportTable from '../../../shared/call-report/CallReportTable';
import { RETENSION_DOWNLOAD_CALL_REPORT_URL } from './api/CallReportUrl';
import CustomCsvDownload from '../../../shared/CustomCsvDownload';
import { filename, callReportHeaders } from '../../../../../utils/field-support/installations/helpers';
import CustomInput from '../../../shared/forms/CustomInput';

const CallReportBody = () => {
  const { loadingForm, onChange, formValue, onSubmit, data } = useSearch(
    `${RETENSION_DOWNLOAD_CALL_REPORT_URL}`,
    {
      action: 'retension_download_call_report',
      from: '',
      to: '',
    },
    'Fetched customer call report',
    'Unable to get customer data',
  );
  const { from, to } = formValue;
  const customer = data.items;
  console.log(customer);
  const onFormSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12 mt-3 mb-3">
          <form className="form-inline mt-4 inner-addon d-flex" onSubmit={onFormSubmit}>
            <div className="form-group mx-2">
              <label className="mt-2 p-0 copy-font">From: </label>
              <CustomInput
                classname="form-control mr-2"
                name="from"
                value={from}
                onChange={onChange}
                readOnly={false}
                type="date"
              />

              <label className="mt-2 p-0 copy-font">To: </label>
              <CustomInput
                classname="form-control mr-2"
                name="to"
                value={to}
                onChange={onChange}
                readOnly={false}
                type="date"
              />
            </div>

            <div className="mb-3">
              <button className="btn btn-green" style={{ height: '39px' }}>
                Search
              </button>
            </div>
          </form>
          <div className="col-12 d-flex mt-2">
            <CustomCsvDownload
              data={data.items || []}
              headers={callReportHeaders}
              filename={filename(`Call_Report`)}
              classname="btn btn-green shadow-md"
              textcolor="white"
              text="Download Data"
            />
          </div>
        </div>
      </div>
      <CallReportTable customer={data.items} load={loadingForm} />
    </div>
  );
};

export default CallReportBody;
