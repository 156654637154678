import React, { useContext } from 'react';
import OpenAndBlockedUsersTable from './OpenAndBlockedUsersTable';
import SearchOpenAndBlockedUsers from './SearchOpenAndBlockedUsers';
import CustomPagination from '../../../shared/CustomPagination';
import AppContext from '../../../../../context/shared/AppContext';

const OpenAndBlockedUsersBody = () => {
  const { loading, pageCount, handlePageChange } = useContext(AppContext);
  return (
    <div className="row mt-5">
      <SearchOpenAndBlockedUsers />
      <OpenAndBlockedUsersTable />
      {!loading && (
        <CustomPagination
          pagesCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default OpenAndBlockedUsersBody;
