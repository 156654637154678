import { useReducer, useState } from 'react';
import axios from 'axios';
import qs, { stringify } from 'qs';
import { toast } from 'react-toastify';
import geniatechReducer from '../../../../../../../reducers/shared/geniatech/geniatechReducer';
import { LOADING_STOPS } from '../../../../../../../constants/types/shared/LoadingTypes';
import { LEGACY_GENIATECH_URL } from '../../../../../../../services/api/shared/installations/InstallationsUrl';
import * as types from '../../../../../../../constants/types/shared/cnmaestro/CnMaestroTypes';
import { ACCESS_PERFORMANCE_URL } from '../api/StaticAccessPointUrl';

const useStaticAccessPoints = () => {
  const [{ loading, pages, performanceSearchStatus, searchStatus }, dispatch] = useReducer(geniatechReducer, {
    loading: false,
    pages: 0,
    searchStatus: false,
    performanceSearchStatus: false,
  });
  const [fetching, setFetching] = useState(false);
  const [fetched, setFetched] = useState([]);
  const [performanceResults, setPerformanceResults] = useState([]);
  const searchPerformance = async (searchParams) => {
    dispatch({ type: types.PERFORMANCE_SEARCH_STATUS, payload: true });
    dispatch({ type: types.SET_SEARCH_STATUS, payload: false });
    setFetching(true);
    try {
      const response = await axios.post(`${ACCESS_PERFORMANCE_URL}`, stringify(searchParams));
      if (response.data) {
        setPerformanceResults(response.data.data);
        setFetching(false);
      }
    } catch (e) {
      dispatch({ type: LOADING_STOPS });
      setFetching(false);
    }
  };
  const searchData = async (searchParams) => {
    dispatch({ type: types.SET_SEARCH_STATUS, payload: true });
    setFetching(true);
    try {
      const response = await axios.post(`${LEGACY_GENIATECH_URL}`, qs.stringify(searchParams));
      if (response.data.status) {
        setFetching(false);
        setFetched(response.data);
      } else {
        setFetching(false);
        setFetched([]);
        toast(`No search result for ${searchParams.tower}`);
      }
    } catch (e) {
      dispatch({ type: LOADING_STOPS });
    }
  };
  const reset = () => {
    dispatch({ type: types.SET_SEARCH_STATUS, payload: false });
    dispatch({ type: types.PERFORMANCE_SEARCH_STATUS, payload: false });
  };
  const resetPerformanceSearch = () => {
    dispatch({ type: types.PERFORMANCE_SEARCH_STATUS, payload: false });
    dispatch({ type: types.SET_SEARCH_STATUS, payload: true });
  };
  const rebootAccessPoints = async (data) => {
    const response = await axios.post(`${LEGACY_GENIATECH_URL}`, data);
    if (response.data.status) {
      toast.success('Alert sent');
    } else {
      toast.error('Alert not sent');
    }
  };
  return {
    loading,
    pages,
    searchData,
    reset,
    fetched,
    fetching,
    performanceSearchStatus,
    searchPerformance,
    searchStatus,
    resetPerformanceSearch,
    performanceResults,
    rebootAccessPoints,
  };
};

export default useStaticAccessPoints;
