/* eslint-disable react/no-array-index-key */
import React from 'react';

const CreatedTimesTable = ({ zones }) => {
  const tableData =
    zones && zones.length ? (
      zones.map((zone, i) => {
        const { name, availableZoneTimes } = zone;
        return (
          <tr className="text-nowrap" key={i}>
            <td>{i + 1}</td>
            <td>{name}</td>
            <td>
              {availableZoneTimes && availableZoneTimes.length ? (
                availableZoneTimes.map((r) => <span className="mx-3">*{r.time}</span>)
              ) : (
                <span className="mx-3">No Allocated Time Slot</span>
              )}
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="5" className="text-center">
          No Zones With Time Slot
        </td>
      </tr>
    );

  return tableData;
};

export default CreatedTimesTable;
