/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import edit from '../../../../assets/img/icons/edit.png';
import Delete from '../../../../assets/img/icons/delete.png';
import EditPosting from './EditPosting';
import { deleteJob } from '../../../../actions/shared/recruitment/RecruitmentActions';
import {
  accounts,
  ADMIN_DEPARTMENT,
  customer_support,
  NEW_PRODUCTS,
  NOC,
  operations,
  PROCUREMENT,
  RESEARCH_AND_DEVELOPMENT,
  SALES,
  TECHNICAL_SUPPORT,
} from '../../../../utils/config';
import loader from '../../../../assets/img/icons/TizetiLoaderColored.gif';

class RecruitmentTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      one: {},
    };
  }

  deleteJob = (job) => {
    const jobId = job.id;
    this.props.deleteJob(jobId);
  };

  getJob = (job) => {
    this.setState({
      one: job,
    });
  };

  render() {
    const { all } = this.props;
    const { one } = this.state;

    const jobsList = all ? (
      all.map((job, i) => (
        <tr key={i + 1}>
          <td>{i + 1}</td>
          <td>{job.job_title}</td>
          <td>
            {job.department_id === `${SALES}` ? (
              <strong>SALES</strong>
            ) : job.department_id === `${accounts}` ? (
              <strong>ACCOUNTS</strong>
            ) : job.department_id === `${customer_support}` ? (
              <strong>CUSTOMER SUPPORT</strong>
            ) : job.department_id === `${ADMIN_DEPARTMENT}` ? (
              <strong>ADMINISTRATION</strong>
            ) : job.department_id === `${NOC}` ? (
              <strong>NOC</strong>
            ) : job.department_id === `${operations}` ? (
              <strong>OPERATIONS</strong>
            ) : job.department_id === `${NEW_PRODUCTS}` ? (
              <strong>NEW PRODUCTS</strong>
            ) : job.department_id === `${PROCUREMENT}` ? (
              <strong>PROCUREMENT</strong>
            ) : job.department_id === `${RESEARCH_AND_DEVELOPMENT}` ? (
              <strong>RESEARCH AND DEVELOPMENT</strong>
            ) : job.department_id === `${TECHNICAL_SUPPORT}` ? (
              <strong>TECHNICAL SUPPORT</strong>
            ) : null}
          </td>
          {/* <td>Intern</td> */}
          {/* <td>{job.cre}</td> */}
          <td>{moment(job.deadline).format('DD-MM-YYYY')}</td>
          {/* <td> <span className="badge badge-info ml-5"> 20 </span> */}
          {/* </td> */}
          <td>
            <div className="d-flex">
              <button
                onClick={() => this.getJob(job)}
                type="submit"
                data-toggle="modal"
                data-target="#Editposting"
                style={{
                  border: '0',
                  backgroundColor: 'white',
                }}
              >
                <span className="view mr-2">
                  <img src={edit} data-toggle="tooltip" data-placement="top" title="Edit" alt="edit" />
                </span>
              </button>

              <EditPosting one={one} id={job.department_id} />

              <button
                type="submit"
                data-toggle="modal"
                onClick={() => this.deleteJob(job)}
                // data-target="#cancel"
                style={{
                  border: '0',
                  backgroundColor: 'white',
                }}
              >
                <span className="view">
                  <img src={Delete} data-toggle="tooltip" data-placement="right" title="Delete" alt="delete" />
                </span>
              </button>
            </div>
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td>
          <img src={loader} alt="" />
        </td>
      </tr>
    );
    return <tbody>{jobsList || <small>loading</small>}</tbody>;
  }
}

export default connect(null, { deleteJob })(RecruitmentTable);
