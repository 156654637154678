import React, { Component } from 'react';

export default class Warning extends Component {
  render() {
    return (
      <div
        id="cancel"
        className="modal fade in"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body text-center">
              <span className="fa fa-exclamation-circle" />
              <p className="copy-font">
                <b>Are you sure you want to delete this contact?</b>
              </p>
              <button type="submit" className="btn btn-green waves-effect" data-toggle="modal" data-dismiss="modal">
                Yes, Delete
              </button>
              <button type="submit" className="btn btn-success waves-effect" data-toggle="modal" data-dismiss="modal">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
