import { useReducer, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import formReducer from '../../../../../reducers/shared/custom/formReducer';
import { LOADING_STARTS, LOADING_STOPS } from '../../../../../constants/types/shared/LoadingTypes';

const useForm = (url, param, message) => {
  const [{ loadingForm }, dispatch] = useReducer(formReducer, {
    loadingForm: false,
  });
  const [formValue, setFormValues] = useState(param);
  const [returned, setReturned] = useState({});
  const onChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValue, [name]: value });
  };
  const onSubmit = async () => {
    try {
      dispatch({ type: LOADING_STARTS });
      const response = await axios.post(url, formValue, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        setFormValues(param);
        setReturned(response.data);
        dispatch({ type: LOADING_STOPS });
        toast.success(`${message}`);
      } else {
        toast.info(`Unable to add ${message}`);
        dispatch({ type: LOADING_STOPS });
      }
    } catch (e) {
      dispatch({ type: LOADING_STOPS });
      toast.info('Something went wrong!');
    }
  };
  return { loadingForm, onChange, formValue, onSubmit, returned };
};

export default useForm;
