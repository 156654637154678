/* eslint-disable import/no-named-as-default */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AES } from 'crypto-js';
import RequisitionNotification from '../../staff/shared/layouts/sidebar/manager-and-executives/RequisitionNotification';
import PATHS from '../../../routes/urls';
import BudgetSideBar from '../../staff/shared/layouts/sidebar/manager-and-executives/BudgetSideBar';
import SalesNavBarItem from './SalesNavBarItem';

const span13 = (name) => <span style={{ fontSize: '13px' }}>{name}</span>;

const SalesSideBar = () => {
  const role_id = Number(sessionStorage.getItem('roleId'));
  const country = sessionStorage.getItem('country');
  const detailsState = {
    name: 'React',
    messagetoken: sessionStorage.getItem('token'),
    messagerole: sessionStorage.getItem('roleId'),
    messagedepartment: sessionStorage.getItem('departmentId'),
    secret: 'Wifirocks123',
    cipher: '',
  };
  const [details, setDetails] = useState(detailsState);
  const { name, messagetoken, messagerole, messagedepartment, secret, cipher } = details;

  const obj = {
    login: messagetoken,
    pass: messagerole,
    department: messagedepartment,
  };

  const ciph = AES.encrypt(JSON.stringify(obj), secret).toString();

  return (
    <div className="s-layout__sidebar">
      <Link className="s-sidebar__trigger" to="#">
        <i className="fa fa-bars mr-3" />
      </Link>

      <nav className="s-sidebar__nav">
        <Link className="navbar-brand-logo" to="/sales">
          <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971047/Tizeti-Logo.png" alt="homepage" />
        </Link>
        <ul>
          <span className="p-0 mt-3">
            <li className="mt-3 s-sidebar__nav-link">
              <Link to="/sales" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971013/Group-3.png" alt="img" />
                </span>
                <small>Overview</small>
              </Link>
            </li>
            <li className="s-sidebar__nav-link">
              {' '}
              <a href={`https://os.tizeti.com/main/#/overview?ciph=${ciph}`} target="_blank" className="make-bold">
                <span>
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923169/configr_d.fw.png" alt="img" />
                </span>
                <small>New system (Beta v2.0)</small>
              </a>
            </li>
            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/sales/logs" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1544459675/call_log_icon.png" alt="img" />
                </span>
                <small>Logs</small>
              </Link>
            </li>{' '}
            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/sales/leads" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1542990759/Group-2.png" alt="img" />
                </span>
                <small>Paid Leads</small>
              </Link>
            </li>
            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/sales/customers" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                </span>
                <small>Customers</small>
              </Link>
            </li>
            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/sales/bbn-signups" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1544459675/call_log_icon.png" alt="img" />
                </span>
                <small>BBN Signups</small>
              </Link>
            </li>
            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/sales/usage-history" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-4.png" alt="img" />
                </span>
                <small>Usage History</small>
              </Link>
            </li>
            <span className="s-sidebar__nav-link d-none d-md-block" data-toggle="collapse" data-target="#invoice">
              <SalesNavBarItem
                url="#"
                name="INVOICE"
                image="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-6.png"
              />
            </span>
            <span id="invoice" className="collapse base-li top-bottom">
              <SalesNavBarItem
                url={PATHS.SALES_CUSTOMER_INVOICE}
                name={span13(' New Customer Invoice')}
                image="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg"
              />
              <SalesNavBarItem
                url={PATHS.SALES_EXISTING_INVOICE}
                name={span13(' Existing Customer Invoice')}
                image="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg"
              />
            </span>
            <span className="s-sidebar__nav-link d-none d-md-block" data-toggle="collapse" data-target="#installs">
              <SalesNavBarItem
                url="#"
                name="INSTALLATIONS"
                image="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-6.png"
              />
            </span>
            <span id="installs" className="collapse base-li top-bottom">
              <SalesNavBarItem
                url="/sales/installations"
                name={span13('Installations')}
                image="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg"
              />
              <SalesNavBarItem
                url={PATHS.SALES_INSTALLATIONS_COUNTER_SUMMARY}
                name={span13('Installation Counter')}
                image="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg"
              />
              <SalesNavBarItem
                url={PATHS.SALES_CLUSTERED_INSTALLATIONS}
                name={span13('Clustered Installations')}
                image="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg"
              />
              {/* <SalesNavBarItem
        url={PATHS.SALES_RESCHEDULED_INSTALLATIONS}
        name={span13('Rescheduled Installations')}
        image="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg"
      /> */}
              <SalesNavBarItem
                url="/failed-installations"
                name={span13('Failed Installations')}
                image="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg"
              />
              <SalesNavBarItem
                url={PATHS.SALES_INSTALLATIONS_PER_BASESTATION}
                name={span13('Installations Per Basestation')}
                image="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg"
              />
            </span>
            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/sales/relocation" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-6.png" alt="img" />
                </span>
                <small>Relocation</small>
              </Link>
            </li>
            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/sales/referral" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-6.png" alt="img" />
                </span>
                <small>Referral</small>
              </Link>
            </li>
            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/sales/basestations" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971012/Group-1.png" alt="img" />
                </span>
                <small>BaseStations</small>
              </Link>
            </li>
            <li className="s-sidebar__nav-link">
              {' '}
              <Link to={PATHS.SALES_REFUND} target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-6.png" alt="img" />
                </span>
                <small>Refunds</small>
              </Link>
            </li>
            <li className="s-sidebar__nav-link">
              {' '}
              <Link to={PATHS.ACCOUNTS_REFUNDS_ANALYTICS} target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-6.png" alt="img" />
                </span>
                <small>Refunds Analytics</small>
              </Link>
            </li>
            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/shared/installations/reschedule" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-6.png" alt="img" />
                </span>
                <small>Rescheduled Installations</small>
              </Link>
            </li>
            {country != 'CIV' ? (
              <>
                <li className="s-sidebar__nav-link">
                  {' '}
                  <Link to="/sales/jackets" target="_self">
                    <span>
                      <img
                        src="https://res.cloudinary.com/teewhy/image/upload/v1544459675/call_log_icon.png"
                        alt="img"
                      />
                    </span>
                    <small>Jacket Logs</small>
                  </Link>
                </li>
                <li className="s-sidebar__nav-link">
                  {' '}
                  <Link to="/sales/wifi-events" target="_self">
                    <span>
                      <img
                        src="https://res.cloudinary.com/teewhy/image/upload/v1544459675/call_log_icon.png"
                        alt="img"
                      />
                    </span>
                    <small>Events</small>
                  </Link>
                </li>

                <li className="s-sidebar__nav-link">
                  {' '}
                  <Link to="/sales/connected-customers" target="_self">
                    <span>
                      <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971012/Group-1.png" alt="img" />
                    </span>
                    <small>Connected Customers</small>
                  </Link>
                </li>
                <li className="s-sidebar__nav-link">
                  {' '}
                  <Link to={PATHS.SALES_KYC} target="_self">
                    <span>
                      <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971012/Group-1.png" alt="img" />
                    </span>
                    <small>Lte Kyc</small>
                  </Link>
                </li>
                <span className="s-sidebar__nav-link d-none d-md-block" data-toggle="collapse" data-target="#assetTeam">
                  <SalesNavBarItem
                    url="#"
                    name="ASSET TEAM"
                    image="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-6.png"
                  />
                </span>
                <span id="assetTeam" className="collapse base-li top-bottom">
                  <SalesNavBarItem
                    url={PATHS.SALES_ASSET_TEAM_INSTALLATIONS}
                    name={span13('Installations')}
                    image="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg"
                  />
                </span>
                {Number(role_id) > 2 ? (
                  <li className="d-none d-md-block">
                    {' '}
                    <a href={PATHS.SALES_RECRUITMENT} target="_blank" className="">
                      <span>
                        <img
                          src="https://res.cloudinary.com/teewhy/image/upload/v1555319728/recruitment_1.svg"
                          alt="img"
                        />
                      </span>{' '}
                      Recruitment
                    </a>
                  </li>
                ) : (
                  ''
                )}

                <span className="p-0 mt-5 d-none d-md-block">
                  <li className="s-sidebar__nav-link">
                    {' '}
                    <Link to="/sales/admin-request" target="_self" className="make-bold">
                      <span>
                        <img
                          src="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg"
                          alt="img"
                        />
                      </span>
                      <small>Admin Request</small>
                    </Link>
                  </li>

                  <li className="s-sidebar__nav-link">
                    {' '}
                    <Link to="/sales/purchase-order" target="_self" className="">
                      <span>
                        <img src="https://res.cloudinary.com/teewhy/image/upload/v1557141046/Schedule.png" alt="img" />
                      </span>
                      <small>Purchase Order</small>
                    </Link>
                  </li>

                  <li className="s-sidebar__nav-link">
                    {' '}
                    <Link to={PATHS.SALES_PAYSLIP} target="_self" className="make-bold">
                      <span>
                        <img
                          src="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg"
                          alt="img"
                        />
                      </span>
                      <small>Payslip</small>
                    </Link>
                  </li>

                  <li className="s-sidebar__nav-link">
                    {' '}
                    <Link to="/sales/kpi-monitor" target="_self" className="make-bold">
                      <span>
                        <img
                          src="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg"
                          alt="img"
                        />
                      </span>
                      <small>KPI Monitor</small>
                    </Link>
                  </li>
                  <li className="s-sidebar__nav-link">
                    {' '}
                    <Link to={PATHS.SALES_DEDICATED_CUSTOMERS} target="_self" className="make-bold">
                      <span>
                        <img
                          src="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg"
                          alt="img"
                        />
                      </span>
                      <small>Dedicated Customers</small>
                    </Link>
                  </li>

                  {Number(role_id) > 1 ? (
                    <li className="s-sidebar__nav-link">
                      {' '}
                      <Link to="/sales/escalate" target="_self" className="text-danger make-bold">
                        <span className="mr-2">
                          <img
                            src="https://res.cloudinary.com/teewhy/image/upload/v1557141046/Schedule.png"
                            alt="img"
                          />
                        </span>
                        Escalate
                      </Link>
                    </li>
                  ) : null}
                  {Number(role_id) > 2 ? <BudgetSideBar link={PATHS.SALES_BUDGET} name="Budget" /> : null}
                  <li className="s-sidebar__nav-link">
                    {' '}
                    <Link to="/sales/Issue" target="_self" className="text-danger make-bold">
                      <span>
                        <img
                          src="https://res.cloudinary.com/teewhy/image/upload/v1548923430/r_d-envelop.fw.png"
                          alt="img"
                        />
                      </span>
                      <small>Report an Issue</small>
                    </Link>
                  </li>

                  <li className="s-sidebar__nav-link">
                    <Link to="/sales/learning-portal" target="_self" className="make-bold">
                      <span>
                        <img
                          src="https://res.cloudinary.com/teewhy/image/upload/v1548923169/configr_d.fw.png"
                          alt="img"
                        />
                      </span>
                      <small>Learning Portal</small>
                    </Link>
                  </li>

                  {Number(role_id) > 2 ? (
                    <li className="s-sidebar__nav-link">
                      {' '}
                      <Link to="/sales/request-feature" target="_self" className="make-bold">
                        <span>
                          <img
                            src="https://res.cloudinary.com/teewhy/image/upload/v1548923169/configr_d.fw.png"
                            alt="img"
                          />
                        </span>
                        <small>Request a Feature</small>
                      </Link>
                    </li>
                  ) : null}
                </span>
              </>
            ) : null}
            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/sales/requisition" target="_self" className="d-flex">
                <span>
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1550844527/requisition.png" alt="img" />
                </span>
                <small>Requisition</small>
                <RequisitionNotification />
              </Link>
            </li>
          </span>
        </ul>
      </nav>
    </div>
  );
};

export default SalesSideBar;
