/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-plusplus */
import { useState, useCallback, useEffect } from 'react';
import moment from 'moment';

export const FormatTime = (time) => {
  const fetchTime = () => {
    const newTime = moment(time)
      .add(1, 'hours')
      .format('DD-MMM-YYYY hh:mm A');
    return newTime;
  };
  return { fetchTime };
};

export const AssetTeamHelperExpired = (items, itemKey) => {
  const [data, setData] = useState({});
  const [count] = useState([]);
  const [basestation] = useState([]);

  const fetchData = useCallback(async () => {
    if (items.length > 0) {
      items.sort((a, b) => a.expired_count - b.expired_count);
      for (let i = 0; i < items.length; i++) {
        count.push(items[i].expired_count);
        if (itemKey === 'zone') basestation.push(items[i].zone);
        else basestation.push(items[i].basestationame);
      }
      setData({
        labels: basestation,
        datasets: [
          {
            label: 'count',
            data: count,
            backgroundColor: 'rgb(115, 255, 136)',
          },
        ],
      });
    }
  }, [basestation, count, items, itemKey]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { basestation, count, data };
};

export const AssetTeamHelperActive = (items, itemKey) => {
  const [data, setData] = useState({});
  const [count] = useState([]);
  const [basestation] = useState([]);

  const fetchData = useCallback(async () => {
    if (items.length > 0) {
      items.sort((a, b) => a.active_count - b.active_count);
      for (let i = 0; i < items.length; i++) {
        count.push(items[i].active_count);
        if (itemKey === 'zone') basestation.push(items[i].zone);
        else basestation.push(items[i].basestationame);
      }
      setData({
        labels: basestation,
        datasets: [
          {
            label: 'count',
            data: count,
            backgroundColor: 'rgb(115, 255, 136)',
          },
        ],
      });
    }
  }, [basestation, count, items, itemKey]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { basestation, count, data };
};
