import { LOADING_STARTS, LOADING_STOPS } from '../../constants/types/shared/LoadingTypes';

const initialState = {
  loading: false,
};

export const LoadingReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOADING_STARTS:
      return {
        ...state,
        loading: payload,
      };

    case LOADING_STOPS:
      return {
        ...state,
        loading: payload,
      };

    default:
      return state;
  }
};

export default LoadingReducer;
