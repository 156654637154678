/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';

export default {
  kpiStatuslabels(percentage_completion) {
    switch (percentage_completion) {
      case 100:
        return <span className="badge badge-success">COMPLETED</span>;

      case null:
        return <span className="">no status</span>;

      default:
        return <span className="">{percentage_completion}</span>;
    }
  },
};
