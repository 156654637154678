/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import usePost from '../../../../../hooks/shared/custom/data/geniatech/usePost';
import { LEGACY_GENIATECH_URL } from '../../../../../services/api/shared/installations/InstallationsUrl';
import { clusteredInstallationHeaders } from '../../../../../utils/field-support/installations/helpers';
import CustomCsvDownload from '../../../shared/CustomCsvDownload';
import ClusteredInstallationsTable from './ClusteredInstallationsTable';
import SearchClusteredInstallations from './SearchClusteredInstallations';

const ClusteredInstallationsBody = () => {
  const { data, loading, getData, getDataLegacy } = usePost();

  const [keyword, setKeyword] = useState(
    new Date(new Date().getTime() - 0 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10),
  );
  const [filteredCluster, setFilteredCluster] = useState('');
  const [zoneSearch, setZoneSearch] = useState({ searchBy: '', searchValue: '' });

  const { searchBy, searchValue } = zoneSearch;

  const params = {
    action: 'search_clustered_installation',
    value: keyword,
    search_by: searchBy,
    search_value: searchValue,
  };

  const searchClusters = (e) => {
    e.preventDefault();
    getDataLegacy(`${LEGACY_GENIATECH_URL}`, params);
  };

  useEffect(() => {
    if (data && data.items) setFilteredCluster(data.items);
  }, [data]);

  useEffect(() => {
    getDataLegacy(`${LEGACY_GENIATECH_URL}`, params);
  }, []);

  return (
    <div className="col-12 p-0 after-summary">
      <div className="row mx-2">
        <div className="d-flex justify-content-between pt-4 mr-2">
          <SearchClusteredInstallations
            searchClusters={searchClusters}
            keyword={keyword}
            setKeyword={setKeyword}
            zoneSearch={zoneSearch}
            setZoneSearch={setZoneSearch}
          />
          {filteredCluster && !data.message && (
            <CustomCsvDownload
              data={filteredCluster}
              headers={clusteredInstallationHeaders}
              filename="Clustered Installation.csv"
              classname="btn btn-green shadow-md mx-3"
              textcolor="white"
              text="Download Data"
            />
          )}
        </div>
        <ClusteredInstallationsTable lists={filteredCluster} data={data} isLoading={loading} />
      </div>
    </div>
  );
};

export default ClusteredInstallationsBody;
