import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { get_all_basestation_zones } from '../../../../../../actions/shared/basestations/GetAllBasestationsZones';
import { tizeti_asset_active_inactive_users } from '../../../../../../actions/coo-statistics/ActiveExpiredTurboUsers';
import CheckboxesGroup from './CheckBox';
import ActiveInactiveUsersByZoneTable from './ActiveExpiredUsersTable';
import ChartsContainer from './ChartsContainer';
import CompareByMonthsForActiveInactiveUsers from './CompareByMonths/CompareByMonthsForActiveInactiveUsers';
import InsightsForActiveInactiveUser from './InsightsForActiveInactiveUser';

const today = new Date();
const previousDay = new Date(today);
previousDay.setDate(today.getDate() - 1);
const formattedPreviousDay = previousDay.toISOString().slice(0, 10);
// const formattedTodayDate = today.toISOString().slice(0, 10);
// const firstDayOfMonth = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-01`;

export const initialActiveUsersPerAssetZone = {
  action: 'get_active_expired_wifi_subscribers',
  from: formattedPreviousDay,
  to: formattedPreviousDay,
  // country: 'Nigeria',
  type: 'active',
  zone: 'kasoa',
};

export default function ActiveExpiredUsersByAssetZone() {
  const [getZones, setGetZones] = useState([]);
  const [getAllActiveUsers, setGetAllActiveUsers] = useState([]);
  const [getAllInActiveUsers, setGetAllInActiveUsers] = useState([]);
  const [sumOfActiveAndInactiveUsers, setSumOfActiveAndInactiveUsers] = useState([]);
  const [state, setState] = React.useState({
    active: true,
    inactive: true,
    activeInactive: false,
  });

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  useEffect(() => {
    const fetchAllActiveUsersData = async () => {
      try {
        const zones = await get_all_basestation_zones();
        setGetZones(zones);

        const allActiveCustomers = await Promise.all(
          zones.map(async (zone) => {
            const data = await tizeti_asset_active_inactive_users({
              ...initialActiveUsersPerAssetZone,
              zone: zone.name,
            });
            return { data };
          }),
        );

        setGetAllActiveUsers(allActiveCustomers);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    const fetchAllExpiredUsersData = async () => {
      try {
        const zones = await get_all_basestation_zones();
        setGetZones(zones);

        const allInActiveCustomers = await Promise.all(
          zones.map(async (zone) => {
            const data = await tizeti_asset_active_inactive_users({
              ...initialActiveUsersPerAssetZone,
              zone: zone.name,
              type: 'expiration',
            });
            return { data };
          }),
        );

        setGetAllInActiveUsers(allInActiveCustomers);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchAllActiveUsersData();
    fetchAllExpiredUsersData();
  }, []);

  const getAllActiveUsersCount = () => {
    if (getZones.length === getAllActiveUsers.length) {
      const getAllActiveUsersCountData = getAllActiveUsers.map((data) =>
        data.data.status === true && data.data.data.length > 0 ? data.data.data[0].count : 0,
      );
      return getAllActiveUsersCountData;
    }
  };
  const getAllInActiveUsersCount = () => {
    if (getZones.length === getAllActiveUsers.length) {
      const getAllInActiveUsersCountData = getAllInActiveUsers.map((data) =>
        data.data.status === true && data.data.data.length > 0 ? data.data.data[0].count : 0,
      );
      return getAllInActiveUsersCountData;
    }
  };
  const getAllActiveUsersCountValue = React.useMemo(() => getAllActiveUsersCount(), [getAllActiveUsers]);
  const getAllInActiveUsersCountValue = React.useMemo(() => getAllInActiveUsersCount(), [getAllInActiveUsers]);

  useEffect(() => {
    if (
      getAllActiveUsersCountValue !== undefined &&
      getAllInActiveUsersCountValue !== undefined &&
      getAllActiveUsersCountValue.length > 0 &&
      getAllInActiveUsersCountValue.length > 0
    ) {
      const summedArray = getAllActiveUsersCountValue.reduce((acc, value, index) => {
        acc.push(value + getAllInActiveUsersCountValue[index]);
        return acc;
      }, []);
      setSumOfActiveAndInactiveUsers(summedArray);
    }
  }, [getAllActiveUsersCountValue, getAllInActiveUsersCountValue]);

  return (
    <Box
      sx={{ ml: '2rem', mt: '2rem', mr: '2rem' }}
      display="flex"
      justifyContent="space-between"
      flexDirection={'column'}
    >
      <Typography variant="h4">Active and Expired users by asset zone</Typography>

      <Box sx={{ border: '0.1px solid #706f6f', p: 2, borderRadius: 8, width: '100%', my: 3 }}>
        <Typography variant="h5">Insights</Typography>
        <InsightsForActiveInactiveUser
          getZones={getZones}
          getAllActiveUsersCountValue={getAllActiveUsersCountValue}
          getAllInActiveUsersCountValue={getAllInActiveUsersCountValue}
        />
      </Box>

      <ActiveInactiveUsersByZoneTable
        getZones={getZones}
        sumOfActiveAndInactiveUsers={sumOfActiveAndInactiveUsers}
        getAllActiveUsersCountValue={getAllActiveUsersCountValue}
        getAllInActiveUsersCountValue={getAllInActiveUsersCountValue}
      />

      <CheckboxesGroup state={state} handleChange={handleChange} />
      <ChartsContainer
        getZones={getZones}
        state={state}
        sumOfActiveAndInactiveUsers={sumOfActiveAndInactiveUsers}
        getAllActiveUsersCountValue={getAllActiveUsersCountValue}
        getAllInActiveUsersCountValue={getAllInActiveUsersCountValue}
      />

      <Divider sx={{ my: 5 }} />
      <Box>
        <CompareByMonthsForActiveInactiveUsers
          getZones={getZones}
          state={state}
          sumOfActiveAndInactiveUsers={sumOfActiveAndInactiveUsers}
          getAllActiveUsersCountValue={getAllActiveUsersCountValue}
          getAllInActiveUsersCountValue={getAllInActiveUsersCountValue}
        />
      </Box>
    </Box>
  );
}
