import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import SimpleBackdrop from '../ActiveExpiredUsersByAssetZone/Backdrop';

export default function ActiveInactiveTurboByZoneTable({
  getZones,
  ActiveInactiveData,
  ActiveData,
  InActiveData,
  isLoading,
}) {
  return (
    <Box sx={{ mr: 5, my: 5, width: { xs: '95vw', md: 'calc(100vw - 300px)' } }}>
      {isLoading ? (
        <SimpleBackdrop isLoading />
      ) : (
        <TableContainer component={Paper} width={'100%'} sx={{ overflowX: 'auto' }}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bolder' }}>Zones</TableCell>
                {getZones.map((row) => (
                  <TableCell key={row.id}>{row.name}</TableCell>
                ))}
              </TableRow>
            </TableHead>

            {/* sx={{ '&:last-child td, &:last-child th': { border: 0 } }}> */}
            <TableBody>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>Active & Inactive/Expired Turbo Count</TableCell>
                {getZones.map((row, index) => (
                  <TableCell key={row.id}>{!ActiveInactiveData[index] ? '...' : ActiveInactiveData[index]}</TableCell>
                ))}
              </TableRow>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>Active Turbo Count</TableCell>
                {getZones.map((row, index) => (
                  <TableCell key={row.id}>
                    {' '}
                    {!ActiveData[index]
                      ? '...'
                      : typeof ActiveData[index] === 'number'
                      ? ActiveData[index]
                      : ActiveData[index].length}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>InActive Turbo Count</TableCell>
                {getZones.map((row, index) => (
                  <TableCell key={row.id}> {!InActiveData[index] ? '...' : InActiveData[index].length}</TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}
