import { useState, SetStateAction } from 'react';
import { toast } from 'react-toastify';
import { EditorState } from 'draft-js';
import legacy from '../../../../../../services/api/axios';
import axios from 'axios';

const useMailBlast = () => {
  const [params, setParams] = useState({ type: '' });
  const [customers, setCustomers] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [subject, setSubject] = useState('');
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [body, setBody] = useState('');
  const [country, setCountry] = useState('');
  const [canned, setCanned] = useState('');
  const onSubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const onCannedChange = (e) => {
    setCanned(e.target.value);
  };
  const onCountryChange = (e) => {
    setCountry(e.target.value);
  };
  const onTextChange = (e) => {
    setBody(e.target.value);
  };

  const onChange = (e) => {
    setParams({ ...params, [e.target.name]: e.target.value });
  };

  const handleChange = (option) => {
    setSelectedOption(option.map((opt) => opt.value));
  };
  const onSubmit = async () => {
    setFetching(true);

    legacy
      .post('/index.php', {
        action: 'tizeti_asset_active_inactive_users_by_basestation',
        type: params.type,
        basestations: selectedOption,
      })
      .then((response) => {
        if (response.data.status) {
          setFetching(false);
          setCustomers(response.data.items);
        }
      })
      .catch(() => {
        setFetching(false);
        toast('Something went wrong!');
      });
  };
  const sendEmail = async (param) => {
    setSendingEmail(true);
    try {
      const response = await axios.post('https://api.tizeti.com/api/v1/mail/mailblast', param, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        }
      });
      if (response.status === 200) {
        toast.success('Sent Successfully');
        setEditorState(EditorState.createEmpty());
        setSubject('');
        setSendingEmail(false);
      } else {
        toast.info('Unable to send message');
        setSendingEmail(false);
      }
    } catch (error) {
      toast('Something went wrong. Try later!');
      setSendingEmail(false);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const submitForm = () => {};

  const onEditorStateChange = (editorStat) => {
    setEditorState(editorStat);
  };
  return {
    onChange,
    onSubmit,
    handleChange,
    customers,
    fetching,
    sendEmail,
    sendingEmail,
    onEditorStateChange,
    submitForm,
    editorState,
    subject,
    onSubjectChange,
    onTextChange,
    body,
    country,
    onCountryChange,
    canned,
    onCannedChange,
  };
};
export default useMailBlast;
