/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';

const FrequencyLogTable = ({ load, frequent }) => {
  if (load) {
    return (
      <tbody>
        <tr>
          <td>
            <img src={loader} alt="" />
          </td>
        </tr>
      </tbody>
    );
  }

  const frequentList = frequent ? (
    frequent.map((fre, i) => {
      return (
        <tr key={i + 1}>
          <td>{i + 1}</td>
          <td nowrap="nowrap">
            <a href={`https://${fre.ip_address}`} target="_blank" style={{ textDecoration: 'none' }}>
              {fre.access_point_ssid}
            </a>
          </td>
          <td>
            <a href={`https://${fre.ip_address}`} target="_blank" style={{ textDecoration: 'none' }}>
              {fre.ip_address}
            </a>
          </td>
          <td nowrap="no-wrap">{fre.date}</td>
          <td nowrap="no-wrap">{fre.time}</td>
          <td className="text-center">{fre.frequency}</td>
          <td className="text-center">{fre.new_frequency}</td>

          {fre.EmployeeAuth.employees[0] ? (
            <td>
              {fre.EmployeeAuth.employees[0].firstName} {fre.EmployeeAuth.employees[0].lastName}
            </td>
          ) : (
            <td>{fre.EmployeeAuth.email}</td>
          )}

          <td className="ml-1">{fre.comments}</td>
        </tr>
      );
    })
  ) : (
    <p>None</p>
  );

  return <tbody>{frequentList}</tbody>;
};

export default FrequencyLogTable;
