/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
// /* eslint-disable jsx-a11y/label-has-for */
// /* eslint-disable jsx-a11y/label-has-associated-control */
// /* eslint-disable react/button-has-type */
// import React, { Component } from 'react';
// import axios from 'axios';
// import { ToastContainer, toast } from 'react-toastify';
// import { API_URL } from '../../../../../../../utils/config';
// import 'react-toastify/dist/ReactToastify.min.css';
// import { ADD_WIFICALL_USER_URL } from '../../../../../../../services/api/wificall/UsersUrl';

// class AddCustomers extends Component {
//   constructor(props) {
//     super(props);

//   componentDidMount() {
//     this.getPlans();
//   }

//   onSubmit(e) {
//     e.preventDefault();

//     this.setState({
//       loading: true,
//     });

//     const {
//       firstName,
//       lastName,
//       address,
//       gender,
//       email,
//       phone,
//       plan,
//     } = this.state;

//     if (firstName === '') {
//       toast.info('Please enter a first name');
//       this.setState({
//         loading: false,
//       });
//     }

//     if (lastName === '') {
//       toast.info('Please enter a first name');
//       this.setState({
//         loading: false,
//       });
//     }

//     if (address === '') {
//       toast.info('Please enter a last name');
//       this.setState({
//         loading: false,
//       });
//     }

//     if (gender === '') {
//       toast.info('Please select a gender');
//       this.setState({
//         loading: false,
//       });
//     }

//     if (email === '') {
//       toast.info('Please enter an email');
//       this.setState({
//         loading: false,
//       });
//     }

//     if (phone === '') {
//       toast.info('Please enter a phone number');
//       this.setState({
//         loading: false,
//       });
//     }

//     if (plan === '') {
//       toast.info('Please select a plan');
//       this.setState({
//         loading: false,
//       });
//     }

//     const customer = {
//       firstName,
//       lastName,
//       address,
//       gender,
//       email,
//       phoneNumber: phone,
//       password: 'password',
//       plans_id: plan,
//     };

//     axios({
//       method: 'post',
//       url: `${ADD_WIFICALL_USER_URL}`,
//       data: customer,
//     })
//       .then((response) => {
//         if (response.data.id) {
//           toast.success('Operation Successful!');
//           window.location.reload();
//         } else {
//           toast.info('Something went wrong. Please try again');
//         }
//       })
//       .catch((error) => {
//           console.log(error)
//       });
//   }

//   onChange(e) {
//     this.setState({
//       [e.target.name]: e.target.value,
//     });
//   }

//   getPlans() {
//     axios({
//       method: 'get',
//       url: `${API_URL}/planlist`,
//     }).then((response) => {
//       this.setState({
//         plans: response.data,
//       });
//     });
//   }

//   handleChange = (event) => {
//     this.setState({
//       gender: event.target.value,
//     });
//   };

//   render() {
//     const {
//       loading,
//       plans,
//       email,
//       instagram,
//       address,
//       firstName,
//       lastName,
//       phone,
//       twitter,
//     } = this.state;

//   }
// }

// export default AddCustomers;

import React from 'react';
import { ToastContainer } from 'react-toastify';
import { usePlans } from '../../../../../hooks/new-products/products/wificall/plans/usePlans';
import { useCustomers } from '../../../../../hooks/new-products/products/wificall/customers/useCustomers';

const AddCustomers = () => {
  const { plans } = usePlans();
  const { customer, onChange, onSubmit } = useCustomers();
  const { firstName, lastName, address, email, phone, referral, about, source, plan, load, gender } = customer;

  const submitForm = () => {
    return (
      firstName.length > 0 &&
      lastName.length > 0 &&
      email.length > 0 &&
      address.length > 0 &&
      phone.length > 0 &&
      plan.length > 0
    );
  };

  const createUser = (e) => {
    e.preventDefault();
    const data = {
      firstName,
      lastName,
      email,
      address,
      phoneNumber: phone,
      plan_id: plan,
      password: 'password',
      gender,
      referral_code: referral,
      about_us: about,
      source,
    };
    onSubmit(data);
  };

  return (
    <div className="modal" id="addcustomer">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Add Customer</h4>
            <button type="button" className="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <div className="modal-body">
            <form className="form-horizontal" onSubmit={createUser}>
              <div className="form-group text-left">
                <div className="col-md-12 mb-3 d-flex">
                  <div className="col-lg-6">
                    <label className="copy-font">First Name</label>
                    <input
                      type="text"
                      name="firstName"
                      value={firstName}
                      onChange={onChange}
                      className="form-control"
                      placeholder="First Name"
                    />
                  </div>

                  <div className="col-lg-6">
                    <label className="copy-font">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      name="lastName"
                      value={lastName}
                      onChange={onChange}
                    />
                  </div>
                </div>

                <div className="col-md-12 mb-3 d-flex">
                  <div className="col-lg-6">
                    <label className="copy-font">Email address</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email address"
                      name="email"
                      value={email}
                      onChange={onChange}
                    />
                  </div>

                  <div className="col-lg-6">
                    <label className="copy-font">Plans</label>
                    <select onChange={onChange} name="plan" className="form-control">
                      <option>Select Plans</option>
                      {plans && plans.length
                        ? plans.map((plan) => {
                            return <option value={plan.id}>{plan.name}</option>;
                          })
                        : null}
                    </select>
                  </div>
                </div>

                <div className="col-md-12 mb-3 d-flex">
                  <div className="col-lg-6">
                    <label className="copy-font">Phone Number</label>
                    <input
                      type="number"
                      name="phone"
                      value={phone}
                      onChange={onChange}
                      className="form-control"
                      placeholder="Phone Number"
                    />
                  </div>
                </div>

                <div className="col-md-12 mb-3 d-flex">
                  <div className="col-lg-6">
                    <label className="copy-font">Address</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Permanent home address"
                      name="address"
                      value={address}
                      onChange={onChange}
                    />
                  </div>
                </div>

                <div className="col-md-12 mb-3 d-flex">
                  <div className="col-lg-6">
                    <label className="copy-font">About</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="about us"
                      name="about"
                      value={about}
                      onChange={onChange}
                    />
                  </div>

                  <div className="col-lg-6">
                    <label className="copy-font">Source</label>
                    <input type="text" className="form-control" name="source" value={source} onChange={onChange} />
                  </div>
                </div>

                <div className="col-md-12 mb-3 d-flex">
                  <div className="col-lg-6">
                    <label className="copy-font">Referral</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="enter referaal code"
                      name="referral"
                      value={referral}
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>

              <div className="text-center">
                {load ? (
                  <button className="btn btn-green" disabled={load}>
                    <i className="fa fa-circle-o-notch fa-spin" />
                  </button>
                ) : (
                  <button className="btn btn-green" disabled={!submitForm()}>
                    Add Customer
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddCustomers;
