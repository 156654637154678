/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable consistent-return */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable array-callback-return */
/* eslint-disable no-use-before-define */
/* eslint-disable no-empty */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import '../../../../../styles/style.css';
import '../../../../../styles/basestation.css';
import axios from 'axios';
import moment from 'moment';
import { toastr } from 'toastr';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AddBaseStation from '../../super-admin/basestations/AddBaseStation';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import { getUser } from '../../../../../actions/shared/user/UserActions';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import { admin, API_URL, NOC } from '../../../../../utils/config';
import NocNavBar from '../../../../layouts/noc/NocNavBar';

class NetworkOperationsBaseStation extends Component {
  static propTypes = {
    getUser: PropTypes.func.isRequired,
    firstName: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      basestations: [],
      keyword: '',
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      filtered: [],
      isLoading: false,
    };

    this.onKeyPress = this.onKeyPress.bind(this);
  }

  componentDidMount() {
    this.fetchBaseStations();
    this.checkDepartment();
    this.props.getUser();
  }

  componentWillReceiveProps() {
    this.checkDepartment();
  }

  checkDepartment = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.department_id !== `${NOC}` && response.data.department_id !== `${admin}`) {
        sessionStorage.setItem('token', '');
        sessionStorage.clear();
        this.props.history.push('/');
        toastr.info('You are not allowed to view this page');
      }
    } catch (e) {}
  };

  renderMap = () => {
    loadScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyD1DrDBUd6GNL2EIBCxK-K0OjkTny8kbuA&callback=initMap');
    window.initMap = this.initMap;
  };

  fetchBaseStations = async () => {
    try {
      const response = await axios.get(`${API_URL}/baseStation`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data) {
        this.setState(
          {
            basestations: response.data,
          },
          this.renderMap(),
        );
      }
    } catch (e) {
      //   console.log(e.response);
    }
  };

  initMap = () => {
    const map = new window.google.maps.Map(document.getElementById('map'), {
      center: { lat: 6.548653, lng: 3.54882 },
      zoom: 11,
      maxZoom: 0, // 18
      minZoom: 0, // 16
      zoomControlOptions: {
        style: window.google.maps.ZoomControlStyle.SMALL,
      },
      scaleControl: true, // enable scale control
      mapTypeId: window.google.maps.MapTypeId.HYBRID, // google map type
    });

    const infowindow = new window.google.maps.InfoWindow();

    this.state.basestations.map((basestation) => {
      const contentString = `${basestation.name}`;

      const marker = new window.google.maps.Marker({
        position: {
          lat: basestation.latitude,
          lng: basestation.longitude,
        },
        map,
        // icon: 'https://res.cloudinary.com/tizeti/image/upload/v1542971012/Group-1.png',
        icon: 'http://www.my9ja.com/estatewifi/icons/pin_green.png',
        title: basestation.name,
        // animation: window.google.maps.Animation.BOUNCE
      });

      // var input = document.getElementById('pac-input');
      // var searchBox = new window.google.maps.places.SearchBox(input);
      // map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);
      //           var searchBox = new window.google.maps.places.SearchBox(document.getElementById('pac-input'));

      // var searchBox = new window.google.maps.places.SearchBox(document.getElementById('pac-input'));

      const circle = new window.google.maps.Circle({
        map,
        radius: 2000, // 2 kilometeres in metres
        fillColor: '#00FF00',
        // strokeColor: '#FF0000',
        strokeOpacity: 0.2,
        strokeWeight: 0.12,
        fillOpacity: 0.25,
      });

      circle.bindTo('center', marker, 'position');

      marker.addListener('click', () => {
        // chnage content
        infowindow.setContent(contentString);
        infowindow.open(map, marker);
      });
    });
  };

  onKeyPress = (e) => {
    const keyword = e.target.value;

    if (e.which === 13) {
      if (keyword !== '') {
        this.setState({
          isLoading: true,
        });

        const base = this.state.basestations.filter((basestation) => {
          if (basestation.name.indexOf(keyword) !== -1) {
            return basestation;
          }
          if (basestation.address.indexOf(keyword) !== -1) {
            return basestation;
          }
          if (basestation.installer.indexOf(keyword) !== -1) {
            return basestation;
          }
        });

        /* this.geocodeAddress(base); */
        this.setState({
          filtered: base,
          isLoading: false,
        });
      } else {
        this.setState({
          filtered: this.state.basestations,
          keyword,
        });
      }
    }
  };

  render() {
    const { isLoading, filtered } = this.state;
    const { user } = this.props;
    const { firstName } = user;

    if (isLoading) {
      return <img src={loader} alt="" />;
    }

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <NocNavBar currentPage="Basestations" position={firstName} />

          <div className="container px-4">
            <div className="row mt-5">
              <div className="mt-5 col-lg-12 col-md-12 col-sm-12">
                {/* <button
                  className="btn btn-green mb-4 mt-3 bases pull-right"
                  type="submit"
                  data-toggle="modal"
                  data-target="#station"
                >
                  Add Basestation
                </button> */}
                {/* <AddBaseStation /> */}
                <input id="pac-input" className="controls" type="text" placeholder="Search Box" />
                <div id="map" />

                <div className="frame">
                  <div className="contain col-lg-12 col-md-12 col-sm-12">
                    <input
                      className="form-control mr-sm-2 empty"
                      id="iconified"
                      type="text"
                      placeholder="&#xF002;Search"
                      onKeyPress={this.onKeyPress}
                    />

                    {filtered.map((basestation) => (
                      <div>
                        <div className="info bg-white mt-3 p-3">
                          <label className="copy-font">BaseStation name</label>
                          <p className="copy-font">{basestation.name}</p>

                          <label className="copy-font">Address</label>
                          <p className="copy-font">{basestation.address}</p>

                          <label className="copy-font">Installed By</label>
                          <p className="copy-font">{basestation.installer}</p>
                          <label className="copy-font">Date of Commisioning</label>
                          <p className="copy-font">{moment(basestation.created_at).format('MM/DD/YYYY')}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

function loadScript(url) {
  const index = window.document.getElementsByTagName('script')[0];
  const script = window.document.createElement('script');
  script.src = url;
  script.async = true;
  script.defer = true;
  index.parentNode.insertBefore(script, index);
}

const mapStateToProps = (state) => ({
  user: state.auth.auth,
});

export default connect(
  mapStateToProps,
  { getUser },
)(NetworkOperationsBaseStation);
