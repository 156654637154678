import React, { useContext } from 'react';
import StaticIpCustomersTable from './StaticIpCustomersTable';
import AddStaticIpCustomer from './AddStaticIpCustomer';
import CustomPagination from '../../../../shared/CustomPagination';
import AppContext from '../../../../../../context/shared/AppContext';

const StaticIpCustomersBody = () => {
  const { loading, paginateChange, pages } = useContext(AppContext);
  const pageChange = (params) => {
    const { selected } = params;
    paginateChange(selected + 1);
  };
  return (
    <div className="container">
      <div className="row mt-5">
        {/* <SearchCnMaestro /> */}
        <div className="form-group ml-2">
          <button className="btn btn-green" type="button" data-toggle="modal" data-target="#add-access-point">
            Add Static Ip Customer
          </button>
        </div>
        <AddStaticIpCustomer />
        <StaticIpCustomersTable />
        {!loading && (
          <CustomPagination
            pagesCount={pages}
            marginPagesDisplayed={5}
            pageRangeDisplayed={5}
            onPageChange={pageChange}
          />
        )}
      </div>
    </div>
  );
};

export default StaticIpCustomersBody;
