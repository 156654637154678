import React, { Component } from 'react';
import '../../../../../styles/style.css';
import '../../../../../styles/sales_overview.css';
import axios from 'axios';
import toastr from 'toastr';
import { Link } from 'react-router-dom';
import UpdateIssue from './UpdateIssue';
import IssueDetail from './IssueDetail';
import ResearchLogo from '../../../../layouts/research-and-development/ResearchLogo';
import view from '../../../../../assets/img/icons/view.png';
import edit from '../../../../../assets/img/icons/edit.png';
import Mailblast from '../../../../../assets/img/icons/mailblast.svg';
import { API_URL } from '../../../../../utils/config';
import Logout from '../../../shared/auth/Logout';
import ResearchAndDevelopmentSideBar from '../../../../layouts/research-and-development/ResearchAndDevelopmentSideBar';

export default class IssueReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      move: false,
      out: false,
      redirect: false,
      role: '',
    };
  }

  componentWillMount() {
    this.fetchLoggedOnUser();
    this.getUser();
  }

  componentWillReceiveProps() {
    this.fetchLoggedOnUser();
  }

  getUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employee/loggedOnEmployee`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        this.setState({
          user: data.data,
        });
      })
      .catch((err) => {});
  }

  fetchLoggedOnUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employeeReg/logged`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        this.setState({
          role: data.data.role_id,
        });

        if (data.data.department_id !== '22dfe612-9f8b-4183-a75e-81a33595aa46') {
          sessionStorage.setItem('data', '');
          sessionStorage.clear();
          this.setState({
            out: true,
          });

          toastr.info('You are not allowed to view this page');
        }
      })
      .catch((err) => {});
  }

  render() {
    const { user, role } = this.state;
    const { firstName } = user;

    return (
      <div className="main-wrapper">
        <header className="topbar shade bg-white fixed-top">
          <nav className="navbar navbar-expand-lg navbar-light top-navbar fixed-top">
            <ResearchLogo />
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse mt-3" id="navbarText">
              <ul className="navbar-nav" style={{ marginRight: 'auto' }}>
                <li className="nav-item active">
                  <Link className="nav-link" to="#">
                    <h3>
                      Issue Reports<span className="sr-only">(current)</span>
                    </h3>
                  </Link>
                </li>
              </ul>
              <span className="navbar-text">
                <div className="d-flex">
                  {role > '2' ? (
                    <Link
                      to="/research-and-development/mail-blast"
                      className="d-flex"
                      style={{ color: 'gray', textDecoration: 'none' }}
                    >
                      <img src={Mailblast} className="logout mr-2 mb-2" alt="" />
                      <span>
                        <h6 className="mr-2">
                          <strong>MailBlast</strong>
                        </h6>
                      </span>
                    </Link>
                  ) : null}
                  <h4 className="admin">
                    {firstName}
                    <Logout />
                  </h4>
                </div>
              </span>
            </div>
          </nav>
        </header>

        <div className="all-sides col-lg-12 col-md-12 col-sm-12">
          <ResearchAndDevelopmentSideBar />
          <div className="page-wrapper col-lg-10 col-md-10 col-sm-12">
            <div className="row">
              <div className="container-fluid col-lg-12">
                <div className="card-body col-lg-12" style={{ padding: '17px' }}>
                  <div className="form-inline" action="javascript:void(0)">
                    <div className="col-12 mb-3 p-0 d-flex">
                      <form className="col-8 form-inline d-flex inner-addon left-addon">
                        <input
                          className="form-control mr-sm-2 empty mt-1 w-100"
                          type="text"
                          placeholder="Search......."
                        />
                        <span className="fa fa-search" />
                      </form>

                      <div>
                        <p className="copy-font d-flex mt-3">
                          <small className="mr-2 mt-3"> Filter By:</small>
                          <span className="mt-2">
                            <select className="shadow-sm p-2 mr-4 form-control">
                              <option>Status</option>
                            </select>
                          </span>
                        </p>
                      </div>
                    </div>
                    {/* <AddRequest /> */}

                    <div className="col-12 m-0 p-0">
                      <div className="card x-panel w-100 ml-0">
                        <div className="table-responsive content">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Department</th>
                                <th>Subject</th>
                                <th>Details</th>
                                <th>Submitted By</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>1</td>
                                <td>Sales</td>
                                <td>Call log</td>
                                <td>We would like to have a .......</td>
                                <td>Adekunle Kareem</td>
                                <td>Sept 8th, 2019 08:54 AM</td>
                                <td>
                                  <span className="badge badge-success">Done</span>
                                </td>
                                <td>
                                  <div className="d-flex ml-3">
                                    <button
                                      type="submit"
                                      data-toggle="modal"
                                      data-placement="right"
                                      data-target="#issue"
                                      style={{
                                        border: '0',
                                        backgroundColor: 'white',
                                      }}
                                    >
                                      <span className="view1">
                                        <img
                                          src={view}
                                          alt=""
                                          data-toggle="tooltip"
                                          data-placement="right"
                                          title="View"
                                        />
                                      </span>
                                    </button>
                                    <IssueDetail />

                                    <button
                                      type="submit"
                                      data-toggle="modal"
                                      data-target="#updateIssue"
                                      style={{
                                        border: '0',
                                        backgroundColor: 'white',
                                      }}
                                    >
                                      <span className="view">
                                        <img
                                          src={edit}
                                          alt=""
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Edit"
                                        />
                                      </span>
                                    </button>
                                    <UpdateIssue />
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td>2</td>
                                <td>Operations</td>
                                <td>Scheduker</td>
                                <td>We would like to have a .......</td>
                                <td>Olawale Tolu</td>
                                <td>Sept 8th, 2019 08:54 AM</td>
                                <td>
                                  <span className="badge text-white" style={{ backgroundColor: '#F37705' }}>
                                    In Progress
                                  </span>
                                </td>
                                <td>
                                  <div className="d-flex ml-3">
                                    <button
                                      type="submit"
                                      data-toggle="modal"
                                      data-placement="right"
                                      data-target="#issue"
                                      style={{
                                        border: '0',
                                        backgroundColor: 'white',
                                      }}
                                    >
                                      <span className="view1">
                                        <img
                                          src={view}
                                          alt=""
                                          data-toggle="tooltip"
                                          data-placement="right"
                                          title="View"
                                        />
                                      </span>
                                    </button>
                                    <IssueDetail />

                                    <button
                                      type="submit"
                                      data-toggle="modal"
                                      data-target="#updateIssue"
                                      style={{
                                        border: '0',
                                        backgroundColor: 'white',
                                      }}
                                    >
                                      <span className="view">
                                        <img
                                          src={edit}
                                          alt=""
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Edit"
                                        />
                                      </span>
                                    </button>
                                    <UpdateIssue />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
