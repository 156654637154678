/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import edit from '../../../../assets/img/icons/edit.png';
import loader from '../../../../assets/img/icons/TizetiLoaderColored.gif';
import helpers from '../../../../utils/helpers';
import Switch from 'react-switch';
import view from '../../../../assets/img/icons/view.png';
import { NoItemRow } from '../../../../utils/loader/loadingHelper';
import relocation_helpers from '../../../../utils/relocation/relocation_helpers';
import UpdateDedicatedCustomers from './UpdateDedicatedCustomers';
import { BUSINESS_DEVELOPMENT, PROCUREMENT, ACCOUNTS } from '../../../../utils/departments/helpers';
import { useCustomer } from '../../../../hooks/shared/customers/useCustomers';
import CustomerDetails from '../customers/shared/CustomerDetails';
import useInstallations from '../../../../hooks/shared/installations/useInstallations';
import EditCustomerDetails from '../../departments/customer-support/customers/EditCustomerDetails';
import { get_comment } from '../../../../actions/shared/basestations/GetAllBasestationsZones';
import SendSalesQuotation from '../../departments/business-development/sendSalesQuotation';

const BusinessDevelopmentDedicatedCustomersTable = ({ items, isLoading, filteredBy }) => {
  const colSpan = '9';
  const role_id = Number(sessionStorage.getItem('roleId'));
  const departmentID = sessionStorage.getItem('departmentId');

  const { installationDate, getUserDetails, gettingDetails, details, itemprice, address, session } = useCustomer();
  const { getCalledStation, isFetching, calledstation, radacct } = useInstallations();

  const [customerRef, setCustomerRef] = useState('');
  const [name1, setName1] = useState('');
  const [email1, setEmail1] = useState('');
  const [basestation1, setBasestation1] = useState('');
  const [zone, setZone] = useState('');

  const [name, setName] = useState('');
  const [mac, setMac] = useState('');
  const [email, setMail] = useState('');
  const [expiry, setExpiry] = useState('');

  const [data, setData] = useState(items);
  const [bizDevUserComment, setBizDevUserComment] = useState('');

  const [request, setRequest] = useState({
    username: '',
    name: '',
    mobile: '',
    email: '',
    expiration: '',
    basestation: '',
    basestation_status: '',
    country: '',
    price: '',
    status: '',
    subscription_freq: '',
    activation_status: '',
    customer_number: '',
    to_be_decommissioned: '',
  });
  const getRequest = (item) => setRequest(item);

  const getCustomerDetails = (item) => {
    // console.log(item);
    setName1(item.name);
    setEmail1(item.email);
    setZone(item.zone);
    setBasestation1(item.basestation);
    setCustomerRef(item.customer_ref);
    getUserDetails(item.username);
  };

  const getUser = (customer) => {
    setName(customer.name);
    getCalledStation(customer.username);
  };

  const tableData =
    items && items.length ? (
      items.map((item, i) => {
        return (
          <tr className="text-nowrap" key={i}>
            <td>{i + 1}</td>
            <td>{item.username}</td>
            <td>{item.name}</td>
            <td>{item.mobile}</td>
            <td>{item.email}</td>
            <td>{item.basestation}</td>
            <td>{helpers.formatDate(item.expiration)}</td>
            <td>{item.basestation_status}</td>
            <td>{relocation_helpers.relocationStatusLabels(item.status)}</td>
            <td>{item.subscription_freq}</td>
            <td>{item.expiration}</td>
            <td>{item.country}</td>
            <td>{item.price}</td>
            <td>{item.customer_number}</td>
            <td className="d-flex" style={{ gap: '5px' }}>
              <div className="d-flex">
                {departmentID === PROCUREMENT && (
                  <>
                    {role_id > 2 && role_id != 6 && filteredBy !== 'all' && (
                      <button
                        type="submit"
                        className="mx-1 noBorder"
                        data-toggle="modal"
                        data-placement="left"
                        data-target="#updateDedicated"
                        onClick={() => getRequest(item)}
                      >
                        <span className="view">
                          <img src={edit} alt="" data-toggle="tooltip" data-placement="left" title="Edit" />
                        </span>
                      </button>
                    )}
                  </>
                )}

                {departmentID === BUSINESS_DEVELOPMENT && (
                  <>
                    <button
                      type="submit"
                      className="mx-1 noBorder"
                      data-toggle="modal"
                      data-placement="left"
                      data-target="#edit-details"
                      onClick={() => {
                        setName(item.name);
                        setMail(item.email);
                        setMac(item.username);
                        setData(item);
                      }}
                    >
                      <span className="view">
                        <img src={edit} alt="" data-toggle="tooltip" data-placement="left" title="Edit" />
                      </span>
                    </button>
                  </>
                )}
              </div>

              <button
                type="submit"
                style={{
                  border: '0px',
                  backgroundColor: 'white',
                }}
                data-toggle="tooltip"
                data-placement="left"
                title="View"
                onClick={async () => {
                  getCustomerDetails(item);
                  setBizDevUserComment(await get_comment(item.customer_number));
                }}
              >
                <span className="view1 ml-4">
                  <img src={view} alt="" data-toggle="modal" data-target="#cus-details" />
                </span>
              </button>
              {departmentID === ACCOUNTS && (
                <button
                  onClick={() => {
                    setMac(item.username);
                    setExpiry(item.expiration);
                  }}
                  type="button"
                  className="btn-green ml-3"
                  data-toggle="modal"
                  data-target="#add_invoice"
                >
                  Send Invoice
                </button>
              )}
            </td>
            <td>
              <CustomerDetails
                load={gettingDetails}
                user={item}
                name={name1}
                mail={email1}
                customerRef={customerRef}
                itemprice={itemprice}
                address={address}
                details={details}
                session={session}
                zone={zone}
                basestation={basestation1}
                installationDate={installationDate}
                bizDevUserComment={bizDevUserComment}
              />
            </td>

            <UpdateDedicatedCustomers request={request} filteredBy={filteredBy} />
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan={colSpan} text="There are no records" />
    );
  return (
    <div className="table-responsive content col-12 px-1">
      <table className="table">
        <thead>
          <tr className="">
            <th>#</th>
            <th>Mac ID</th>
            <th>Name</th>
            <th>Phone</th>
            <th className="text-wrap">Email</th>
            <th>Base station</th>
            <th>Expiration Date</th>
            <th>Base station status</th>
            <th>Status</th>
            <th>Subscription Frequency</th>
            <th>Expiration Date</th>
            <th>Country</th>
            <th>Amount</th>
            <th>Customer No</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td className="text-center">
                <img src={loader} alt="loading gif" />
              </td>
            </tr>
          ) : (
            tableData
          )}
        </tbody>
      </table>
      <EditCustomerDetails
        username={mac}
        oldEmail={email}
        oldPhone=""
        oldName={name}
        item={data}
        calledstation={calledstation}
        radacct={radacct}
      />
      <SendSalesQuotation mac={mac} expiry={expiry} />
    </div>
  );
};

export default BusinessDevelopmentDedicatedCustomersTable;
