import React from 'react';
import { ToastContainer } from 'react-toastify';
import SnmpBody from './SnmpBody';
import SnmpProvider from './provider/SnmpProvider';
import useCheckTwoDepartments from '../../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';
import { admin, NOC } from '../../../../../../utils/config';
import { useUser } from '../../../../../../hooks/shared/user/useUser';
import NocNavBar from '../../../../../layouts/noc/NocNavBar';

const Snmp = ({ history }) => {
  const { profile } = useUser();
  const { firstName } = profile;
  useCheckTwoDepartments(`${NOC}`, `${admin}`, history);
  return (
    <div className="s-layout">
      <SnmpProvider>
        <main className="s-layout__content col-lg-10 p-0">
          <NocNavBar currentPage="NOC Sheet - SNMP" position={firstName} />
          <SnmpBody />
        </main>
        <ToastContainer />
      </SnmpProvider>
    </div>
  );
};

export default Snmp;
