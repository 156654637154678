/* eslint-disable react/prop-types */
import React from 'react';
import caution from '../../../../assets/img/icons/Caution.png';

const ConfirmDelete = ({ text, confirmDelete }) => {
  return (
    <div
      className="modal fade"
      id="confirm_delete"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content mt-5 py-4">
          <div className="text-center ">
            <img src={caution} alt="" width="60px" />
          </div>
          <div className="modal-body p-3">
            <div className="row">
              <div className="col">
                <form className="form-horizontal text-center" onSubmit={confirmDelete}>
                  <div className="col">
                    <p>{text}</p>
                  </div>
                  <div className="mt-5">
                    <button type="button" className="btn btn-secondary mr-2" data-dismiss="modal">
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-danger" data-dismiss="modal" onClick={confirmDelete}>
                      Delete
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDelete;
