/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useLTE } from '../../../../../hooks/sales/LTE/useLTE';

const AddLTEUser = ({ id }) => {
  const { isLoading, addLTEUser } = useLTE();

  const initialState = {
    imsi: '',
    expiredDate: '',
    month: 1,
  };

  const [user, setUser] = useState(initialState);
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const onSubmit = (e) => {
    e.preventDefault();
    const { imsi, month, expiredDate } = user;
    const data = {
      status: 'no',
      user_id: id,
      imsi,
      expired_at: expiredDate,
      no_of_months: month,
    };
    addLTEUser(data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  return (
    <div
      id="addLTE"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Add LTE User
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body">
            <form className="form-horizontal" onSubmit={onSubmit}>
              <div className="form-group">
                <div className="col-12 d-flex p-0 mb-3">
                  <div className="col-6">
                    <label className="copy-font m-0 p-0">Month(s)</label>
                    <select className="form-control" name="month" onChange={handleChange}>
                      <option>--Select Month(s)--</option>
                      {months.map((m) => (
                        <option key={m} value={m}>
                          {m}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-6">
                    <label className="copy-font m-0 p-0">Expiry Date</label>
                    <input type="date" name="expiredDate" className="form-control" onChange={handleChange} />
                  </div>
                </div>
                <div className="col-12 d-flex p-0 mb-3">
                  <div className="col-12">
                    <label className="copy-font m-0 p-0">IMSI</label>
                    <input type="text" name="imsi" className="form-control" onChange={handleChange} />
                  </div>
                </div>
              </div>
              <div className="text-center mt-5">
                {isLoading ? (
                  <button type="button" className="btn btn-second" disabled>
                    Adding...
                  </button>
                ) : (
                  <button type="submit" className="btn btn-green">
                    Add User
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddLTEUser;
