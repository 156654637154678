import React from 'react';
import Search from '../../installations/leads-and-managers/Search';
import PercentageCompleted from '../../installations/leads-and-managers/table/PercentageCompleted';
import CustomerSupportInstallationOverviewTable from './CustomerSupportInstallationOverviewTable';

const CustomerSupportInstallationOverviewBody = () => {
  return (
    <div className="col-12 after-summary">
      <Search />
      <div className="ml-2 d-flex">
        <PercentageCompleted />
      </div>
      <CustomerSupportInstallationOverviewTable />
    </div>
  );
};

export default CustomerSupportInstallationOverviewBody;
