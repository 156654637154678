import React from 'react';
import { Link } from 'react-router-dom';
import Logout from '../../../shared/auth/Logout';
import Payslip from '../../../shared/payslip/shared/Payslip';
import FieldSupportSideBar from '../../../../layouts/field-support/side-bar/FieldSupportSideBar';

const FieldSupportPayslip = () => {
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <div className="d-flex col-lg-12 p-2 top-header">
          <div className="mt-3 d-flex col-lg-12 p-0">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link className="s-sidebar__trigger" to="#">
              <i className="fa fa-bars mr-3" />
            </Link>
            <FieldSupportSideBar />

            <h3 className="col-lg-6 col-md-6 col-sm-6 overview-part">Payslip</h3>

            <div className="d-flex profile-mail col-5">
              <h6>
                {/* <i className="mr-auto"></i> */}
                <Logout />
              </h6>
            </div>
          </div>
        </div>
        <Payslip />
      </main>
    </div>
  );
};

export default FieldSupportPayslip;
