/* eslint-disable react/button-has-type */
/* eslint-disable import/no-named-as-default */
import React, { useState } from 'react';
import MakeRequisition from './MakeRequisition';

import ApprovedRequisition from './ApprovedRequisition';
import { useRequisition } from '../../../../../hooks/shared/requisition/useRequisition';
import { RequisitionProvider } from '../../../../../context/shared/requisitions/RequsitionContext';

import EmployeesRequisitions from '../employees/EmployeesRequisitions';
import FilterByCountry from './filters/manager/FilterByCountry';

import ExecutiveFilterByCountry from './filters/executive/ExecutiveFilterByCountry';
import { useRequsitions } from '../../../../../hooks/accounts/useRequisitions';
import ExecutivesRequistion from '../executives/v2/ExecutivesRequistion';
import ManagerRequisition from './ManagerRequisition';
import ManagersRequisition from '../managers/ManagersRequisition';

const Requisitions = () => {
  const {
    isFetching: fetching,
    requistions,
    getAccountsRequsitionByStatus,
    approving,
    showModal,
    onClickShowModal,
    setCheckAllRequisition,
    setCheckOneOfAllRequisition,
  } = useRequsitions();
  const userCountry = sessionStorage.getItem('country');
  const {
    managerRequisitions,
    totalPages,
    isFetching,
    onChangeRequisition,
    requisition,
    onFileChange,
    file,
    employeesPages,
    handlePageClick,
    handlePageClicks,
    setManagerApproval,
    makeRequisition,
    isLoading,
    executiveRequisitions,
    percentage,
    giveManagerApproval,
    giveManagerDisApproval,
    onChangeApproveOrDisapprove,
    status,
    isApprovingOrDisapproving,
    giveExecutiveApproval,
    giveExecutiveDisApproval,
    employeesRequisitions,
    // disapproveRequisition,
    onChangeDisapprove,
    disapprove,
    onChangeApprove,
    approve,
    department,
    onChange,
    getFieldSupportRequisition,
    account,
    onChangeAccountStatus,
    getRequisitionByAccountStatus,
    filterRequisitionByCountry,
    filterExecutiveRequisitionByCountry,
    getManagersRaisedRequisition,
    getRequsitionsByDepartment,
    getManagerApprovedRequsitionsByDepartment,
    getManagersRequisition,
  } = useRequisition();

  const [wificallFilter, setWificallFilter] = useState(false);

  const [page] = React.useState(1);
  const deptId = sessionStorage.getItem('departmentId');

  const checkRole = () => {
    if (Number(`${sessionStorage.getItem('roleId')}`) === 3) {
      return (
        <RequisitionProvider
          value={{
            managerRequisitions,
            totalPages,
            handlePageClick,
            isFetching,
            onChangeApproveOrDisapprove,
            status,
            isApprovingOrDisapproving,
            giveManagerApproval,
            giveManagerDisApproval,
            makeRequisition,
            isLoading,
            onChangeRequisition,
            requisition,
            onFileChange,
            file,
            percentage,
            // disapproveRequisition,
            onChangeDisapprove,
            disapprove,
            onChangeApprove,
            approve,
            department,
            onChange,
            getFieldSupportRequisition,
            account,
            onChangeAccountStatus,
            getRequisitionByAccountStatus,
            filterRequisitionByCountry,
            getManagersRaisedRequisition,
            getRequsitionsByDepartment,
            getManagerApprovedRequsitionsByDepartment,
            setManagerApproval,
          }}
        >
          <div className="row">
            <span className="col-12 d-flex justify-content-between">
              <div className="my-3 col-6">
                <FilterByCountry />
                <button
                  className="btn btn-info mt-1 copy-font ml-3"
                  type="submit"
                  onClick={async () => {
                    setManagerApproval(false);
                    await getManagersRequisition(1);
                  }}
                >
                  Default
                </button>
              </div>
              <div className="d-flex">
                <ApprovedRequisition />
                <ManagerRequisition />
                <MakeRequisition />
              </div>
            </span>
            <ManagersRequisition />
          </div>
        </RequisitionProvider>
      );
    }
    if (Number(`${sessionStorage.getItem('roleId')}`) > 3 && Number(`${sessionStorage.getItem('roleId')}`) !== 6) {
      if (wificallFilter === true) {
        return (
          <RequisitionProvider
            value={{
              managerRequisitions,
              totalPages,
              handlePageClick,
              isFetching,
              onChangeApproveOrDisapprove,
              status,
              isApprovingOrDisapproving,
              giveManagerApproval,
              giveManagerDisApproval,
              makeRequisition,
              isLoading,
              onChangeRequisition,
              requisition,
              onFileChange,
              file,
              percentage,
              // disapproveRequisition,
              onChangeDisapprove,
              disapprove,
              onChangeApprove,
              approve,
              department,
              onChange,
              getFieldSupportRequisition,
              account,
              onChangeAccountStatus,
              getRequisitionByAccountStatus,
              filterRequisitionByCountry,
              getManagersRaisedRequisition,
              getRequsitionsByDepartment,
              getManagerApprovedRequsitionsByDepartment,
              setManagerApproval,
            }}
          >
            <div className="row">
              <span className="col-12 d-flex justify-content-between">
                <div className="my-3 col-6">
                  <FilterByCountry />

                  {sessionStorage.getItem('officialEmail') === 'ikenna.uche@tizeti.com' ||
                  sessionStorage.getItem('officialEmail') === 'moyosore.james@tizeti.com' ||
                  sessionStorage.getItem('officialEmail') === 'cioDept@tizeti.com' ? (
                    <>
                      <button
                        className="btn btn-info copy-font"
                        type="submit"
                        onClick={() => {
                          setWificallFilter(false);
                          sessionStorage.setItem('deptFilter', '');
                        }}
                      >
                        Filter by Executives
                      </button>
                    </>
                  ) : (
                    <button
                      className="btn btn-info mt-1 copy-font ml-3"
                      type="submit"
                      onClick={async () => {
                        setManagerApproval(false);
                        await getManagersRequisition(1);
                      }}
                    >
                      Default
                    </button>
                  )}
                </div>
                <div className="d-flex">
                  <ApprovedRequisition />
                  <ManagerRequisition />
                  <MakeRequisition />
                </div>
              </span>
              <ManagersRequisition />
            </div>
          </RequisitionProvider>
        );
      } else if (wificallFilter === false) {
        return (
          <RequisitionProvider
            value={{
              isFetching,
              isApprovingOrDisapproving,
              makeRequisition,
              isLoading,
              onChangeRequisition,
              requisition,
              onFileChange,
              file,
              percentage,
              executiveRequisitions,
              giveExecutiveApproval,
              giveExecutiveDisApproval,

              onChangeDisapprove,
              disapprove,
              filterExecutiveRequisitionByCountry,
            }}
          >
            <div className="row">
              <span className="col-12 d-flex justify-content-between">
                <div className="col-6">
                  <MakeRequisition />
                  {(sessionStorage.getItem('officialEmail') === 'ikenna.uche@tizeti.com' ||
                    sessionStorage.getItem('officialEmail') === 'moyosore.james@tizeti.com' ||
                    sessionStorage.getItem('officialEmail') === 'cioDept@tizeti.com') && (
                    <>
                      <button
                        className="btn btn-info copy-font"
                        type="submit"
                        onClick={() => {
                          setWificallFilter(true);
                          sessionStorage.setItem('deptFilter', 'a4a0d87a-bc1f-41cb-ab97-fc9d6405b8f1');
                        }}
                      >
                        Filter by Wificall
                      </button>
                    </>
                  )}
                </div>
              </span>
              {/* <ExecutiveFilterByCountry /> */}
              <ExecutivesRequistion />
            </div>
          </RequisitionProvider>
        );
      }
    } else {
      return (
        <RequisitionProvider
          value={{
            isFetching,
            makeRequisition,
            isLoading,
            onChangeRequisition,
            requisition,
            onFileChange,
            file,
            percentage,
            employeesRequisitions,
          }}
        >
          <div className="row">
            <MakeRequisition />
            <EmployeesRequisitions totalPages={employeesPages} handlePageClick={handlePageClicks} />
          </div>
        </RequisitionProvider>
      );
    }
  };

  return (
    <div className="container-fluid">
      <div className="row mt-3">
        <div className="col-lg-12 col-md-12 col-sm-12 mt-5">{checkRole()}</div>
      </div>
    </div>
  );
};

export default Requisitions;
