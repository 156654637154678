import React, { Component } from 'react';

class AddDevices extends Component {
  render() {
    return (
      <div
        id="add-device"
        className="modal fade in"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myModalLabel">
                Add Device
              </h4>
              <button type="button" className="close custom-close" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
            </div>
            <div className="modal-body detail" style={{ padding: '0', textAlign: 'justify' }}>
              <div className="row">
                <form className="form-horizontal col-9 m-auto">
                  <div className="form-group mt-4">
                    <div className="col-12 mb-5">
                      <label className="copy-font m-0 p-0 text-left">Device Name</label>
                      <input type="number" placeholder="Device Name" className="form-control pl-2" />
                    </div>

                    <div className="col-12 mb-5">
                      <label className="copy-font m-0 p-0 text-left">Max Consumption</label>
                      <input type="number" placeholder="33" className="form-control pl-2" />
                    </div>

                    <div className="col-12 mb-5">
                      <label className="copy-font m-0 p-0 text-left">Max Power Supply</label>
                      <input type="number" placeholder="24" className="form-control pl-2" />
                    </div>

                    <div className="col-12 text-center">
                      <button className="btn btn-green">Add device</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddDevices;
