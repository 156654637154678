import React from 'react';
import { ACCOUNTS, OPERATIONS, SALES, SUPER_ADMIN, VICE_PRESIDENT_SALES } from '../../../../utils/departments/helpers';
import { useUser } from '../../../../hooks/shared/user/useUser';
import SalesNavBar from '../../../layouts/sales/SalesNavBar';
import RefundFlowBody from './RefundFlowBody';
import FieldSupportNavBar from '../../../layouts/field-support/FieldSupportNavBar';
import SuperAdminNavBar from '../../../layouts/super-admin/SuperAdminNavBar';
import AccountsNavBar from '../../../layouts/accounts/AccountsNavBar';

const RefundFlow = ({ history }) => {
  const {
    profile: { firstName },
  } = useUser();

  const fseData = {
    fse_approval: 'awaiting',
    sales_approval: 'awaiting',
    coo_approval: 'awaiting',
  };

  const salesData = {
    fse_approval: 'approved',
    sales_approval: 'awaiting',
    coo_approval: 'awaiting',
  };

  const cooData = {
    fse_approval: 'approved',
    sales_approval: 'approved',
    coo_approval: 'awaiting',
  };

  const accountData = {
    sales_approval: 'approved',
    fse_approval: 'approved',
    coo_approval: 'approved',
    refund_status: 'awaiting',
  };

  const deptId = sessionStorage.getItem('departmentId');

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        {(deptId === `${SALES}` || deptId === `${VICE_PRESIDENT_SALES}`) && (
          <>
            <SalesNavBar currentPage="Refund" position={firstName} />
            <RefundFlowBody data={salesData} deptId={deptId} />
          </>
        )}
        {deptId === `${OPERATIONS}` && (
          <>
            <FieldSupportNavBar currentPage="Refund" position={firstName} />
            <RefundFlowBody data={fseData} deptId={deptId} type="fse" />
          </>
        )}
        {deptId === `${SUPER_ADMIN}` && (
          <>
            <SuperAdminNavBar currentPage="Refund" position={firstName} />
            <RefundFlowBody data={cooData} deptId={deptId} />
          </>
        )}
        {deptId === `${ACCOUNTS}` && (
          <>
            <AccountsNavBar currentPage="Refund" position={firstName} />
            <RefundFlowBody data={accountData} deptId={deptId} />
          </>
        )}
      </main>
    </div>
  );
};

export default RefundFlow;
