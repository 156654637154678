/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/require-default-props */
/* eslint-disable react/button-has-type */
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import { useCustomer } from '../../../../../hooks/shared/customers/useCustomer';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import { showDepartmentName, CUSTOMER_SUPPORT, EXPRESS_WIFI } from '../../../../../utils/departments/helpers';
import CalledStation from '../../../shared/called-station/CalledStation';
import axios from 'axios';
import { BUSINESS_DEVELOPMENT, header } from '../../../../../utils/config';
import { SEARCH_FOR_CUSTOMER_URL } from '../../../../../services/api/operations/Customers/CustomersUrl';
import {
  get_all_basestation_zones,
  get_all_employees,
  get_all_basestations,
  get_comment,
} from '../../../../../actions/shared/basestations/GetAllBasestationsZones';
import { API_URL } from '../../../../../utils/config';

const EditCustomerDetails = ({
  username,
  oldEmail,
  oldName,
  oldPhone,
  calledstation,
  radacct,
  isFetching,
  item,
  bizdevUser,
  setEditUserData,
}) => {
  const [userID, setUserId] = useState('');
  const [types, setTypes] = useState([]);
  const [macId, setMacId] = useState('');
  const [salesType, setSalesType] = useState('');
  const [isLoadingTypes, setIsLoadingTypes] = useState(false);
  const [country, setCountry] = useState('');

  const searchUser = async (oldEmail) => {
    try {
      // console.log(oldEmail);

      const res = await axios.post(
        `https://api.tizeti.com/api/v1/users/searchuserByEmail`,
        { email: oldEmail },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );

      return res.status === 200 ? res.data.id : null;
    } catch (error) {
      return null;
    }
  };

  const fetchTypes = async (data) => {
    setIsLoadingTypes(type);
    const config = {
      method: 'post',
      url: `https://legacy.tizeti.com/frontaccountapi/account.php`,
      data: data,
    };
    axios(config)
      .then((res) => {
        // console.log(res);
        setTypes(res.data.types);
      })
      .catch((err) => {
        // console.log(err);
      })
      .finally(() => {
        setIsLoadingTypes(false);
      });
  };

  useEffect(() => {
    const data = new FormData();
    data.append('action', 'get_sales_type');
    data.append('country', country);
    fetchTypes(data);
  }, [country]);

  const {
    editMacChange,
    mac,
    editMacDetails,
    isLoading,
    editEmailDetails,
    email,
    editEmailChange,
    isLoadingEmail,
    editNameDetails,
    isLoadingName,
    editRetrievedDetails,
    loader,
    editNameChange,
    account,
    phoneNumber,
    loaders,
    editPhoneDetails,
    editFibreMacDetails,
    isLoadingPhone,
    editPhoneChange,
    editAddressChange,
    address,
    isLoadingAddress,
    editAddressDetails,
  } = useCustomer();
  const { type, first, last } = account;
  const { profile, user } = useUser();

  const [devicetype, setDeviceType] = useState('');

  const devices = [
    { name: 'CAMBIUM', value: 'cambium' },
    { name: 'UBIQUITI', value: 'ubiquiti' },
    { name: 'TERRAGRAPH', value: 'terragraph' },
    { name: 'LTE', value: 'LTE' },
    { name: 'FIBRE', value: 'fibre' },
    { name: 'PERASO', value: 'peraso' },
    { name: 'Wave Nano', value: 'Wave Nano' },
  ];

  const { officialEmail } = profile;
  const { department_id, role_id } = user;
  const [editEmail, setEditEmail] = useState(false);
  const [editFibre, setEditFibre] = useState(false);
  const [retrieved, setRetrieved] = useState('');
  const [retrievedEmail, setRetrievedEmail] = useState(false);
  const [editName, setEditName] = useState(false);
  const [editPhone, setEditPhone] = useState(false);
  const [editMac, setEditMac] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [createUser, setCreateUser] = useState(false);
  const [addOrEditComment, setAddOrEditComment] = useState(false);
  const [createUserLoader, setCreateUserLoader] = useState(false);

  const [employees, setEmployees] = useState([]);

  const [zones, setZones] = useState([]);

  const [basestations, setBasestations] = useState([]);

  const [comment, setComment] = useState('loading...');

  const [createUserData, setCreateUserData] = useState({
    user_info_id: '',
    name: '',
    mail: '',
    employee_auth_id: '',
    employee_name: '',
    zone_name: '',
    zone_id: '',
    base_station: '',
    product: '',
  });

  const sortNames = (a, b) => {
    let fa = a.firstName.toLowerCase(),
      fb = b.firstName.toLowerCase();

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  };

  const onSubmitName = (e) => {
    e.preventDefault();
    const data = {
      email: oldEmail,
      accountType: type,
      macaddress: username,
      firstName: first,
      lastName: last,
      roleId: Number(role_id),
      department: showDepartmentName.get(department_id),
      staffemail: officialEmail,
    };
    editNameDetails(data);
  };

  const onSubmitRetrieved = (e) => {
    e.preventDefault();
    const data = {
      email: retrieved,

      mac: username,
    };
    editRetrievedDetails(data);
  };

  const onFibreSubmit = (e) => {
    e.preventDefault();
    const data = {
      oldmac: username,
      newmac: macId,
      newmac_device_type: 'fibre',
      staffemail: officialEmail,
      roleid: Number(role_id),
      sales_type: salesType,
      department: showDepartmentName.get(department_id),
    };
    editFibreMacDetails(data);
  };

  const onSubmitEmail = async (e) => {
    e.preventDefault();

    const id = await searchUser(oldEmail);

    if (id) {
      const data = {
        user_id: id,
        email: oldEmail,
        newEmail: email,
        macaddress: username,
        roleId: Number(role_id),
        department: showDepartmentName.get(department_id),
        staffemail: officialEmail,
      };
      editEmailDetails(data);
      return;
    }

    toast.error(`Cannot get id`);
  };

  const onSubmitPhone = (e) => {
    e.preventDefault();
    const data = {
      phoneNumber: oldPhone,
      newPhoneNumber: phoneNumber,
      email: oldEmail,
      macAddress: username,
      roleId: Number(role_id),
      department: showDepartmentName.get(department_id),
      staffemail: officialEmail,
    };
    editPhoneDetails(data);
  };

  const onSubmitMac = (e) => {
    e.preventDefault();
    const data = {
      oldmac: username,
      newmac: mac,
      newmac_device_type: devicetype,
      staffemail: officialEmail,
      roleid: Number(role_id),
      department: showDepartmentName.get(department_id),
    };
    editMacDetails(data);
  };

  const onSubmitAddress = (e) => {
    e.preventDefault();
    const details = {
      email: oldEmail,
      macid: username,
      new_address: address,
      staffemail: officialEmail,
      roleid: Number(role_id),
      department: showDepartmentName.get(department_id),
    };
    editAddressDetails(details);
  };

  const submitForm = () => {
    return type.length > 0 && first.length > 0 && last.length > 0;
  };

  const submitForme = () => {
    return macId.length > 0 && salesType.length > 0;
  };
  const submitForms = () => {
    return retrieved.length > 0;
  };
  const validateMac = () => {
    return mac.length > 0 && devicetype.length > 0;
  };

  const handleCreateUserChange = (e) => {
    setCreateUserData({ ...createUserData, [e.target.name]: e.target.value });
  };

  const handleEditUserChange = (e) => {
    setEditUserData({ ...item, [e.target.name]: e.target.value });
  };

  const onCreateBizDevUser = async (e) => {
    e.preventDefault();
    setCreateUserLoader(true);

    try {
      const response = await axios.post(`${API_URL}/busdevuser`, createUserData, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.status === 200 || response.status === 201) {
        toast.info('Biz Dev User Created');
      }
    } catch (error) {
      toast.error(`Biz Dev User Creation Failed ${error}`);
    }

    setCreateUserLoader(false);
  };

  const onAddOrEditBizDevUserComment = async (e, id) => {
    e.preventDefault();
    setCreateUserLoader(true);

    try {
      const response = await axios.post(
        `${API_URL}/busdevuser/comment`,
        {
          user_info_id: id,
          comment,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );

      if (response.status === 200) {
        toast.info('Biz comment submitted');
      }
    } catch (error) {
      toast.error(`Something went wrong... ${error}`);
    }

    setCreateUserLoader(false);
  };

  const onEditBizDevUser = async (e) => {
    e.preventDefault();
    setCreateUserLoader(true);

    try {
      const response = await axios.patch(`${API_URL}/busdevuser/${item.id}`, item, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.status === 200) {
        toast.info('Biz Dev User Edited');
      }
    } catch (error) {
      toast.error(`Biz Dev User Edit Failed ${error}`);
    }

    setCreateUserLoader(false);
  };

  const changeEmailStatus = () => setEditEmail(!editEmail);
  const changeFibreStatus = () => setEditFibre(!editFibre);
  const changeRetrievedStatus = () => setRetrievedEmail(!retrievedEmail);

  const changeNameStatus = () => setEditName(!editName);

  const changePhoneStatus = () => setEditPhone(!editPhone);

  const changeAddressStatus = () => setEditAddress(!editAddress);

  const changeMacStatus = () => setEditMac(!editMac);

  const createBizDevUser = () => setCreateUser(!createUser);

  const addOrEditBizDevUserComment = () => setAddOrEditComment(!addOrEditComment);

  React.useEffect(() => {
    const fetchZones = async () => {
      const data = await get_all_basestation_zones();
      setZones(data);
    };

    const fetchEmployees = async () => {
      const data = await get_all_employees();
      setEmployees(data);
    };

    const fetchBasestations = async () => {
      setBasestations(await get_all_basestations());
    };

    if (createUser || bizdevUser) {
      fetchEmployees();
      fetchZones();
      fetchBasestations();
    }
  }, [createUser]);

  React.useEffect(() => {
    const fetchComment = async () => {
      const data = await get_comment(item.customer_number);
      setComment(data);
    };

    if (addOrEditComment) {
      fetchComment();
    }
  }, [addOrEditComment]);

  if (bizdevUser) {
    return (
      <div
        id="edit-details"
        className="modal fade in mt-5"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center" id="myModalLabel">
                Edit Business Dev User
              </h4>
              <button type="button" className="close custom-close" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
            </div>
            <div className="modal-body">
              <form className="form-horizontal mt-3" onSubmit={onEditBizDevUser}>
                <div className="form-group">
                  <div className="col-12 mb-3 d-flex">
                    {/* <div className="col-6">
                              <input type="text" className="form-control" value={oldName} readOnly />
                            </div> */}

                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="Name"
                      value={item.name}
                      onChange={handleEditUserChange}
                    />
                  </div>

                  <div className="col-12 mb-3 d-flex">
                    <input
                      type="text"
                      className="form-control"
                      name="mail"
                      placeholder="Mail"
                      value={item.mail}
                      onChange={handleEditUserChange}
                    />
                  </div>

                  <div className="col-12 mb-3 d-flex">
                    <div className="col-6">
                      <div className="form-group mr-2">
                        Zones
                        <select
                          id="zone"
                          className="form-control"
                          name="zone_name"
                          value={item.zone_name}
                          onChange={(e) => {
                            setEditUserData({
                              ...item,
                              zone_name: e.target.value,
                              zone_id: e.target[e.target.selectedIndex].getAttribute('data-zone-id'),
                            });
                          }}
                        >
                          <option value="">Select an option</option>
                          {zones.length === 0 && <option value="">loading...</option>}
                          {zones.map((getZone) => (
                            <option key={getZone.id} value={getZone.name} data-zone-id={getZone.id}>
                              {getZone.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="form-group mr-2">
                        Basestations
                        <select
                          id="base_station"
                          className="form-control"
                          name="base_station"
                          value={item.base_station}
                          onChange={handleEditUserChange}
                        >
                          <option value="">Select an option</option>
                          {basestations.sort().map((base, i) => (
                            <option key={i} value={base}>
                              {base}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 mb-3 d-flex">
                    <div className="col-6">
                      <div className="form-group mr-2">
                        Employees
                        <select
                          id="zone"
                          className="form-control"
                          name="employee_name"
                          value={item.employee_name}
                          onChange={(e) => {
                            setEditUserData({
                              ...item,
                              employee_name: e.target.value,
                              employee_id: e.target[e.target.selectedIndex].getAttribute('data-emp-id'),
                            });
                          }}
                        >
                          <option value="">Select an option</option>
                          {employees.length === 0 && <option value="">loading...</option>}
                          {employees.sort(sortNames).map((emp) => (
                            <option
                              key={emp.id}
                              value={`${emp.firstName + ' ' + emp.lastName}`}
                              data-emp-id={emp.id}
                            >{`${emp.firstName + ' ' + emp.lastName}`}</option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="form-group mr-2">
                        Products
                        <select
                          id="zone"
                          className="form-control"
                          name="product"
                          value={item.product}
                          onChange={handleEditUserChange}
                        >
                          <option value="">Select an option</option>

                          <option value="turbo_connect_lite">turbo_connect_lite</option>
                          <option value="turbo_connect_pro">turbo_connect_pro</option>
                          <option value="turbo_connect">turbo_connect</option>
                          <option value="dedicated">dedicated</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 mb-3 text-center">
                    {createUserLoader ? (
                      <button className="btn btn-green" disabled={createUserLoader}>
                        Editing...
                      </button>
                    ) : (
                      <button className="btn btn-green">Edit</button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      id="edit-details"
      className="modal fade in mt-5"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Edit Customer Details
            </h4>
            <button
              type="button"
              onClick={() => {
                setEditEmail(false);
                setEditName(false);
                setEditPhone(false);
                setEditMac(false);
                setEditAddress(false);
                setCreateUser(false);
                setAddOrEditComment(false);
              }}
              className="close custom-close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              ×
            </button>
          </div>
          <div className="modal-body">
            {department_id === `${EXPRESS_WIFI}` || Number(role_id) === 2 || Number(role_id) === 3 ? (
              <>
                {editEmail ? (
                  <form className="form-horizontal mt-3" onSubmit={onSubmitEmail}>
                    <div className="text-center col-12 d-flex">
                      <div className="col-6 text-left">
                        <h5>Edit Email</h5>
                      </div>
                      <div className="col-6">
                        <h5 style={{ textAlign: 'right', cursor: 'pointer' }} onClick={changeEmailStatus}>
                          <b>x</b>
                        </h5>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="col-12 mb-3 d-flex">
                        <div className="col-6">
                          <input type="email" className="form-control" value={oldEmail} readOnly />
                        </div>
                        <div className="col-6">
                          <input type="text" className="form-control" placeholder="Email" onChange={editEmailChange} />
                        </div>
                      </div>
                      {isLoadingEmail ? (
                        <div className="col-12 mb-3 text-center">
                          <button className="btn btn-green" disabled={isLoadingEmail}>
                            Editing...
                          </button>
                        </div>
                      ) : (
                        <>
                          {oldEmail.length ? (
                            <>
                              <div className="col-12 mb-3 text-center">
                                <button className="btn btn-green">Edit</button>
                              </div>
                            </>
                          ) : null}
                        </>
                      )}
                    </div>
                  </form>
                ) : (
                  <>
                    {address.length || department_id === `${CUSTOMER_SUPPORT}` ? (
                      <>
                        <div className="text-center">
                          <h5 style={{ cursor: 'pointer' }} onClick={changeEmailStatus}>
                            Click To Edit Email
                          </h5>
                        </div>
                      </>
                    ) : null}
                  </>
                )}

                <h5 className="text-center" onClick={changeFibreStatus}>
                  Click here to edit Fibre{' '}
                </h5>
                {editFibre && (
                  <form className="form-horizontal" onSubmit={onFibreSubmit}>
                    <div className="form-group">
                      <div className="col-12 mb-3 d-flex">
                        <div className="col-6">
                          <input type="text" className="form-control" value={username} readOnly />
                        </div>
                        <div className="col-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="new mac id"
                            name="macId"
                            value={macId}
                            onChange={(e) => setMacId(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-12 mb-3 d-flex">
                        <div className="col-6">
                          <select
                            name="sales_type"
                            className="form-control"
                            onChange={(e) => setSalesType(e.target.value)}
                          >
                            <option value="">--Select Sales Type--</option>

                            <option value="fibre-residential">Fibre Residential</option>
                            <option value="fibre-business">Fibre Business</option>
                            <option value="fibre-dedicated">Fibre Dedicated</option>
                          </select>
                        </div>
                      </div>

                      {loaders ? (
                        <div className="col-12 mb-3 text-center">
                          <button className="btn btn-green" disabled>
                            Editing...
                          </button>
                        </div>
                      ) : (
                        <div className="col-12 mb-3 text-center">
                          <button className="btn btn-green" disabled={!submitForme()}>
                            Edit Details
                          </button>
                        </div>
                      )}
                    </div>
                  </form>
                )}

                {oldName.length > 0 ? (
                  <span>
                    {editName ? (
                      <form className="form-horizontal mt-3" onSubmit={onSubmitName}>
                        <div className="text-center col-12 d-flex">
                          <div className="col-6 text-left">
                            <h5>Edit Name</h5>
                          </div>
                          <div className="col-6">
                            <h5 style={{ textAlign: 'right', cursor: 'pointer' }} onClick={changeNameStatus}>
                              <b>x</b>
                            </h5>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-12 mb-3 d-flex">
                            <div className="col-6">
                              <input type="text" className="form-control" value={oldName} readOnly />
                            </div>
                            <div className="col-6">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Account Type"
                                value={type}
                                onChange={editNameChange}
                                name="type"
                              />
                            </div>
                          </div>

                          <div className="col-12 mb-3 d-flex">
                            <div className="col-6">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="First Name"
                                value={first}
                                onChange={editNameChange}
                                name="first"
                              />
                            </div>
                            <div className="col-6">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Last Name"
                                value={last}
                                onChange={editNameChange}
                                name="last"
                              />
                            </div>
                          </div>

                          <div className="col-12 mb-3 text-center">
                            {isLoadingName ? (
                              <button className="btn btn-green" disabled={isLoadingName}>
                                Editing...
                              </button>
                            ) : (
                              <button className="btn btn-green" disabled={!submitForm()}>
                                Edit
                              </button>
                            )}
                          </div>
                        </div>
                      </form>
                    ) : (
                      <div className="text-center">
                        <h5 style={{ cursor: 'pointer' }} onClick={changeNameStatus}>
                          Click To Edit Name
                        </h5>
                      </div>
                    )}

                    <h5 className="text-center" onClick={changeRetrievedStatus}>
                      Click to revert retrieved Email{' '}
                    </h5>
                    {retrievedEmail && (
                      <form className="form-horizontal" onSubmit={onSubmitRetrieved}>
                        <div className="form-group">
                          <div className="col-12 mb-3 d-flex">
                            <div className="col-6">
                              <input type="text" className="form-control" value={username} readOnly />
                            </div>

                            <div className="col-6">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Email"
                                onChange={(e) => setRetrieved(e.target.value)}
                                value={retrieved}
                              />
                            </div>
                          </div>

                          {loader ? (
                            <div className="col-12 mb-3 text-center">
                              <button className="btn btn-green" disabled>
                                Editing...
                              </button>
                            </div>
                          ) : (
                            <div className="col-12 mb-3 text-center">
                              <button className="btn btn-green" disabled={!submitForms()}>
                                Edit Details
                              </button>
                            </div>
                          )}
                        </div>
                      </form>
                    )}
                    {createUser ? (
                      <form className="form-horizontal mt-3" onSubmit={onCreateBizDevUser}>
                        <div className="text-center col-12 d-flex">
                          <div className="col-6 text-left">
                            <h5>Create BizDev User</h5>
                          </div>
                          <div className="col-6">
                            <h5 style={{ textAlign: 'right', cursor: 'pointer' }} onClick={createBizDevUser}>
                              <b>x</b>
                            </h5>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="col-12 mb-3 d-flex">
                            {/* <div className="col-6">
                              <input type="text" className="form-control" value={oldName} readOnly />
                            </div> */}

                            <input type="text" className="form-control" placeholder="Name" value={item.name} readOnly />
                          </div>

                          <div className="col-12 mb-3 d-flex">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Mail"
                              value={item.email}
                              readOnly
                            />
                          </div>

                          <div className="col-12 mb-3 d-flex">
                            <div className="col-6">
                              <div className="form-group mr-2">
                                Zones
                                <select
                                  id="zone"
                                  className="form-control"
                                  name="zone_name"
                                  value={createUserData.zone_name}
                                  onChange={(e) => {
                                    setCreateUserData({
                                      ...createUserData,
                                      zone_name: e.target.value,
                                      zone_id: e.target[e.target.selectedIndex].getAttribute('data-zone-id'),
                                      name: item.name,
                                      mail: item.email,
                                      user_info_id: item.customer_number,
                                      base_station: item.basestation,
                                    });
                                  }}
                                  required
                                >
                                  <option value="">Select an option</option>
                                  {zones.length === 0 && <option value="">loading...</option>}
                                  {zones.map((getZone) => (
                                    <option key={getZone.id} value={getZone.name} data-zone-id={getZone.id}>
                                      {getZone.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="form-group mr-2">
                                Basestation
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="base_station"
                                  value={item.basestation}
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-12 mb-3 d-flex">
                            <div className="col-6">
                              <div className="form-group mr-2">
                                Employees
                                <select
                                  id="zone"
                                  className="form-control"
                                  name="employee_name"
                                  value={createUserData.employee_name}
                                  onChange={(e) => {
                                    setCreateUserData({
                                      ...createUserData,
                                      employee_name: e.target.value,
                                      employee_auth_id: e.target[e.target.selectedIndex].getAttribute('data-emp-id'),
                                    });
                                  }}
                                  required
                                >
                                  <option value="">Select an option</option>
                                  {employees.length === 0 && <option value="">loading...</option>}
                                  {employees
                                    .sort(sortNames)
                                    .filter((emp) => emp.department_id === BUSINESS_DEVELOPMENT)
                                    .map((emp) => (
                                      <option
                                        key={emp.id}
                                        value={`${emp.firstName + ' ' + emp.lastName}`}
                                        data-emp-id={emp.employee_auth_id}
                                      >{`${emp.firstName + ' ' + emp.lastName}`}</option>
                                    ))}
                                </select>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="form-group mr-2">
                                Products
                                <select
                                  id="zone"
                                  className="form-control"
                                  name="product"
                                  value={createUserData.product}
                                  onChange={handleCreateUserChange}
                                  required
                                >
                                  <option value="">Select an option</option>

                                  <option value="turbo_connect_lite">turbo_connect_lite</option>
                                  <option value="turbo_connect_pro">turbo_connect_pro</option>
                                  <option value="turbo_connect">turbo_connect</option>
                                  <option value="dedicated">dedicated</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 mb-3 text-center">
                            {createUserLoader ? (
                              <button className="btn btn-green" disabled={createUserLoader}>
                                Creating...
                              </button>
                            ) : (
                              <button className="btn btn-green">Create</button>
                            )}
                          </div>
                        </div>
                      </form>
                    ) : (
                      department_id === BUSINESS_DEVELOPMENT && (
                        <div className="text-center">
                          <h5 style={{ cursor: 'pointer' }} onClick={createBizDevUser}>
                            Click To Create Biz Dev User
                          </h5>
                        </div>
                      )
                    )}

                    {addOrEditComment ? (
                      <form
                        className="form-horizontal mt-3"
                        onSubmit={(e) => onAddOrEditBizDevUserComment(e, item.customer_number)}
                      >
                        <div className="text-center col-12 d-flex">
                          <div className="col-6 text-left">
                            <h5>Add / Edit Comment</h5>
                          </div>
                          <div className="col-6">
                            <h5 style={{ textAlign: 'right', cursor: 'pointer' }} onClick={addOrEditBizDevUserComment}>
                              <b>x</b>
                            </h5>
                          </div>
                        </div>
                        <div className="col-6">
                          <h5 style={{ textAlign: 'right', cursor: 'pointer' }} onClick={addOrEditBizDevUserComment}>
                            <b>x</b>
                          </h5>
                        </div>

                        <div className="form-group">
                          <div className="col-12 mb-3 d-flex">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Type a comment"
                              name="comment"
                              value={comment}
                              onChange={(e) => {
                                setComment(e.target.value);
                              }}
                            />
                          </div>

                          <div className="col-12 mb-3 text-center">
                            {createUserLoader ? (
                              <button className="btn btn-green" disabled={createUserLoader}>
                                Submit...
                              </button>
                            ) : (
                              <button
                                className="btn btn-green"
                                disabled={comment.includes('(Not a bizdev user)') || comment.includes('loading..')}
                              >
                                Submit
                              </button>
                            )}
                          </div>
                        </div>
                      </form>
                    ) : (
                      department_id === BUSINESS_DEVELOPMENT && (
                        <div className="text-center">
                          <h5 style={{ cursor: 'pointer' }} onClick={addOrEditBizDevUserComment}>
                            Click To Add or Edit Comment
                          </h5>
                        </div>
                      )
                    )}

                    {editPhone ? (
                      <form className="form-horizontal mt-3" onSubmit={onSubmitPhone}>
                        <div className="text-center col-12 d-flex">
                          <div className="col-6 text-left">
                            <h5>Edit Phone Number</h5>
                          </div>
                          <div className="col-6">
                            <h5 style={{ textAlign: 'right', cursor: 'pointer' }} onClick={changePhoneStatus}>
                              <b>x</b>
                            </h5>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-12 mb-3 d-flex">
                            <div className="col-6">
                              <input type="text" className="form-control" value={oldPhone} readOnly />
                            </div>
                            <div className="col-6">
                              <input
                                type="text"
                                className="form-control"
                                value={phoneNumber}
                                name="phoneNumber"
                                onChange={editPhoneChange}
                              />
                            </div>
                          </div>

                          <div className="col-12 mb-3 text-center">
                            {isLoadingPhone ? (
                              <button className="btn btn-green" disabled={isLoadingPhone}>
                                Editing..
                              </button>
                            ) : (
                              <button className="btn btn-green">Edit</button>
                            )}
                          </div>
                        </div>
                      </form>
                    ) : (
                      <>
                        {oldPhone.length || department_id === `${CUSTOMER_SUPPORT}` ? (
                          <>
                            <div className="text-center">
                              <h5 style={{ cursor: 'pointer' }} onClick={changePhoneStatus}>
                                Click To Edit Phone Number
                              </h5>
                            </div>
                          </>
                        ) : null}
                      </>
                    )}
                  </span>
                ) : null}
              </>
            ) : null}

            {username.length > 0 &&
              Number(role_id) > 1 &&
              Number(role_id) !== 6 &&
              (profile.officialEmail === 'kolawole.oyetunde@tizeti.com' ||
                profile.officialEmail === 'customersupportDept@tizeti.com' ||
                profile.officialEmail === 'innocent.awuja@tizeti.com') && (
                <span>
                  {editMac ? (
                    <form className="form-horizontal mt-3" onSubmit={onSubmitMac}>
                      <div className="text-center col-12 d-flex">
                        <div className="col-6 text-left">
                          <h5>Edit MAC ID</h5>
                        </div>
                        <div className="col-6">
                          <h5 style={{ textAlign: 'right', cursor: 'pointer' }} onClick={changeMacStatus}>
                            <b>x</b>
                          </h5>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="col-12 mb-3 d-flex">
                          <div className="col-6">
                            <input type="text" className="form-control" value={username} readOnly />
                          </div>
                          <div className="col-6">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="new mac id"
                              name="mac"
                              value={mac}
                              onChange={editMacChange}
                            />
                          </div>
                        </div>
                        <div className="col-12 mb-3 d-flex">
                          <div className="col-6">
                            <select
                              name="devicetype"
                              className="form-control"
                              onChange={(e) => setDeviceType(e.target.value)}
                            >
                              <option value="">--Select Device Type--</option>
                              {devices.length ? (
                                devices.map((item) => {
                                  return (
                                    <option value={item.value} name={item.name}>
                                      {item.name}
                                    </option>
                                  );
                                })
                              ) : (
                                <option value="">Fetching Devices...</option>
                              )}
                            </select>
                          </div>
                        </div>
                        <div className="col-12 mb-3 text-center">
                          {isLoading ? (
                            <button className="btn btn-green" disabled={isLoading}>
                              Editing...
                            </button>
                          ) : (
                            <button className="btn btn-green" disabled={!validateMac()}>
                              Edit MAC ID
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                  ) : (
                    <div className="text-center">
                      <h5 style={{ cursor: 'pointer' }} onClick={changeMacStatus}>
                        Click To Edit MAC
                      </h5>
                    </div>
                  )}
                </span>
              )}

            {oldName.length > 0 ? (
              <span>
                {editAddress ? (
                  <form className="form-horizontal mt-3" onSubmit={onSubmitAddress}>
                    <div className="text-center col-12 d-flex">
                      <div className="col-6 text-left">
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        <small>Edit {`${oldName}`}'s Address</small>
                      </div>
                      <div className="col-6">
                        <h5 style={{ textAlign: 'right', cursor: 'pointer' }} onClick={changeAddressStatus}>
                          <b>x</b>
                        </h5>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="col-12 mb-3 d-flex">
                        {/* <div className="col-6"> */}
                        {/*  <input type="text" className="form-control" value={oldPhone} readOnly /> */}
                        {/* </div> */}
                        <div className="col-12">
                          <input
                            type="text"
                            className="form-control"
                            value={address}
                            name="phoneNumber"
                            onChange={editAddressChange}
                          />
                        </div>
                      </div>

                      <div className="col-12 mb-3 text-center">
                        {isLoadingAddress ? (
                          <button className="btn btn-green" disabled={isLoadingAddress}>
                            Editing..
                          </button>
                        ) : (
                          <button className="btn btn-green">Edit Address</button>
                        )}
                      </div>
                    </div>
                  </form>
                ) : (
                  <>
                    {address.length || department_id === `${CUSTOMER_SUPPORT}` ? (
                      <>
                        <div className="text-center">
                          <h5 style={{ cursor: 'pointer' }} onClick={changeAddressStatus}>
                            Click To Edit Address
                          </h5>
                        </div>
                      </>
                    ) : null}
                  </>
                )}
              </span>
            ) : null}
            {department_id === `${CUSTOMER_SUPPORT}` ? (
              <CalledStation macid={username} calledstation={calledstation} radacct={radacct} isFetching={isFetching} />
            ) : null}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

EditCustomerDetails.propTypes = {
  username: PropTypes.string,
  oldEmail: PropTypes.string,
  oldName: PropTypes.string,
  oldPhone: PropTypes.string,
  isFetching: PropTypes.bool,
  calledstation: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  radacct: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
export default EditCustomerDetails;
