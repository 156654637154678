import React, { useContext, useState } from 'react';
import CustomButton from '../../../../shared/forms/CustomButton';
import AppContext from '../../../../../../context/shared/AppContext';

const SearchDevices = () => {
  const { searchData, onChange, search, data } = useContext(AppContext);
  const [mac, setMac] = useState('');
  return (
    <form
      className="form-inline mb-2 ml-3 col-12"
      onSubmit={(e) => {
        e.preventDefault();
        searchData({
          action: 'getdeviceinformation',
          offset: '0',
          limit: '100',
          status: search,
          network: 'tz-xwf',
          macaddress: mac,
        });
      }}
    >
      <div className="form-group">
        <select name="option" onChange={onChange} className="form-control">
          <option value="">--Choose Search Field--</option>
          <option value="online">Online</option>
          <option value="offline">Offline</option>
          <option value="onboarding">Onboarding</option>
        </select>
      </div>
      <div className="form-group">
        <select name="mac" onChange={(e) => setMac(e.target.value)} className="form-control">
          <option value="">--Select Device--</option>
          {data && data.length ? data.map((device) => <option value={device.mac}>{device.name}</option>) : null}
        </select>
      </div>
      <div className="form-group ml-2">
        <CustomButton classname="btn btn-green" name="Search" disabled={false} />
      </div>
    </form>
  );
};

export default SearchDevices;
