/* eslint-disable react/button-has-type */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import loader from '../../../../../../assets/img/icons/TizetiLoaderColored.gif';
import AppContext from '../../../../../../context/shared/AppContext';
import { useUser } from '../../../../../../hooks/shared/user/useUser';

const RebootTable = () => {
  const { loading: load, data, rebootAccessPoints, rebooting } = useContext(AppContext);
  const { profile } = useUser();
  const [devices, setDevices] = useState([]);
  useEffect(() => {
    if (data && data.length) {
      return setDevices(
        data.map((device) => {
          return {
            id: device.id,
            name: device.name,
            mac: device.mac,
            mode: device.mode,
            tower: device.tower,
            type: device.type,
            default_gateway: device.default_gateway,
            select: false,
          };
        }),
      );
    }
    return setDevices([]);
  }, [data]);
  const [check, setCheck] = useState(false);
  const onSelectAll = () => {
    if (!profile.email) {
      toast.info('Profile is required to reboot');
    } else {
      const macs = devices.map((device) => device.mac);
      rebootAccessPoints({
        action: 'reboot_access_points',
        staff_email: profile.email,
        mac_addresses: macs,
      });
    }
  };
  const onSelect = (e, row) => {
    setCheck(true);
    const { checked } = e.target;
    setDevices(
      devices.map((_item) => {
        if (row.id === _item.id) {
          const dev = Object.assign({}, _item);
          dev.select = checked;
          return dev;
        }
        return _item;
      }),
    );
  };
  const tableData =
    devices && devices.length
      ? devices.map((row, i) => (
          <tr className="text-nowrap" key={row.id}>
            <td>{i + 1}</td>
            <td className="text-center">
              <input
                type="checkbox"
                checked={row.select}
                onChange={(e) => {
                  onSelect(e, row);
                }}
              />
            </td>
            <td>{row.name}</td>
            <td>{row.mac}</td>
            <td>{row.mode}</td>
            <td>{row.tower}</td>
            <td>{row.type}</td>
            <td>{row.default_gateway}</td>
          </tr>
        ))
      : null;

  return (
    <>
      {check || rebooting ? (
        <div className="form-group ml-2">
          <button
            onClick={() =>
              profile.email
                ? rebootAccessPoints({
                    action: 'reboot_access_points',
                    staff_email: profile.email,
                    mac_addresses: devices.filter((v) => v.select === true).map((r) => r.mac),
                  })
                : toast.info('Profile is required')
            }
            className="btn btn-green"
          >
            {rebooting ? 'Rebooting...' : 'Reboot'}
          </button>
        </div>
      ) : null}
      <div className="card x-panel mt-2 px-0">
        <div className="table-responsive content col-12 px-1">
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th className="text-nowrap">
                  <input type="checkbox" className="mr-1" onChange={() => onSelectAll()} />
                  Select All
                </th>
                <th>Name</th>
                <th className="text-nowrap">MAC</th>
                <th>Mode</th>
                <th>Tower</th>
                <th>Type</th>
                <th>Default Gateway</th>
              </tr>
            </thead>
            <tbody>
              {load ? (
                <tr>
                  {/* <td colSpan="9" className="text-center"> */}
                  <img src={loader} alt="loading gif" />
                  {/* </td> */}
                </tr>
              ) : (
                tableData
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default RebootTable;
