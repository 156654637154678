/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import RequisitionNotification from '../../staff/shared/layouts/sidebar/manager-and-executives/RequisitionNotification';
import PATHS from '../../../routes/urls';
import { AES, enc } from 'crypto-js';
import ProcurementSidebarItem from './ProcurementSidebarItem';

const ProcurementSideBar = () => {
  const role_id = Number(sessionStorage.getItem('roleId'));
  const detailsState = {
    name: 'React',
    messagetoken: sessionStorage.getItem('token'),
    messagerole: sessionStorage.getItem('roleId'),
    messagedepartment: sessionStorage.getItem('departmentId'),
    secret: 'Wifirocks123',
    cipher: '',
  };
  const [details, setDetails] = useState(detailsState);
  const { name, messagetoken, messagerole, messagedepartment, secret, cipher } = details;

  const obj = {
    login: messagetoken,
    pass: messagerole,
    department: messagedepartment,
  };

  const ciph = AES.encrypt(JSON.stringify(obj), secret).toString();

  return (
    <div className="s-layout__sidebar">
      <Link className="s-sidebar__trigger" to="#">
        <i className="fa fa-bars mr-3" />
      </Link>

      <nav className="s-sidebar__nav">
        <Link className="navbar-brand-logo" to="/procurement">
          <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971047/Tizeti-Logo.png" alt="homepage" />
        </Link>

        <ul>
          <span className="p-0 mt-5">
            <li className="mt-4 s-sidebar__nav-link">
              <Link to="/procurement" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971013/Group-3.png" alt="img" />
                </span>
                Overview
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <a href={`https://os.tizeti.com/main/#/overview?ciph=${ciph}`} target="_blank" className="make-bold">
                <span>
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923169/configr_d.fw.png" alt="img" />
                </span>
                <small>New system (Beta v2.0)</small>
              </a>
            </li>

            {/* <li className="s-sidebar__nav-link"> */}
            {/*  <Link to="/procurement/customers" target="_self"> */}
            {/*    <span className="mr-2"> */}
            {/*      <img src="https://res.cloudinary.com/teewhy/image/upload/v1544459675/call_log_icon.png" alt="img" /> */}
            {/*    </span> */}
            {/*    Customers */}
            {/*  </Link> */}
            {/* </li> */}

            {/* <li className="s-sidebar__nav-link">
              <Link to="/procurement/assets" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                </span>
                Assets
              </Link>
            </li> */}

            <li className="s-sidebar__nav-link">
              <Link to="/procurement/inventories" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                </span>
                Inventory
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              <Link to="/procurement/customers" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                </span>
                Customers
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              <Link to="/procurement/vehicles" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1542990759/Group-2.png" alt="img" />
                </span>
                Vehicles
              </Link>
            </li>
            <li className="s-sidebar__nav-link">
              <Link to="/procurement/dedicated-customers" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923169/idontknow.fw.png" alt="img" />
                </span>
                Dedicated Customers
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              <Link to="/procurement/installation" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1542990759/Group-2.png" alt="img" />
                </span>
                Installation
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              <Link to="/procurement/account-reconciliation" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1542990759/Group-2.png" alt="img" />
                </span>
                Account Reconciliation
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              <Link to="/shared/tracklist" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                </span>
                Tracklist
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              <Link to="/shared/release-report" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                </span>
                Release Report
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              <Link to="/procurement/retrievals" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                </span>
                Retrievals
              </Link>
            </li>
            <ProcurementSidebarItem to={PATHS.PROCUREMENT_CLEAN_RADIO} name="Clean Radio" />
            {Number(`${sessionStorage.getItem('roleId')}`) > 2 ? (
              <li className="d-none d-md-block">
                {' '}
                <a href={PATHS.PROCUREMENT_RECRUITMENT} rel="noopener noreferrer" target="_blank" className="">
                  <span>
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1555319728/recruitment_1.svg" alt="img" />
                  </span>{' '}
                  Recruitment
                </a>
              </li>
            ) : null}
          </span>

          <span className="p-0 mt-5 d-none d-md-block">
            <li className="s-sidebar__nav-link">
              <Link to="/procurement/kpi-monitor" target="_self" className="make-bold">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg" alt="img" />
                </span>
                KPI Monitor
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              <Link to="/procurement/admin-requests" target="_self" className="make-bold">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg" alt="img" />
                </span>
                Admin Requests
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              <Link to={PATHS.PROCUREMENT_PAYSLIP} target="_self" className="make-bold">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg" alt="img" />
                </span>
                Payslip
              </Link>
            </li>

            <span className="s-sidebar__nav-link d-none d-md-block" data-toggle="collapse" data-target="#po_dropdown">
              <li>
                <Link to="#" target="_self" className="">
                  <span className="mr-2">
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1557141046/Schedule.png" alt="img" />
                  </span>
                  Purchase Order
                </Link>
              </li>
            </span>

            <span id="po_dropdown" className="collapse base-li top-bottom pl-2">
              <li className="s-sidebar__nav-link">
                <Link to="/procurement/purchase-order" target="_self" className="text-nowrap pr-2">
                  <span className="mr-2">
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1557141046/Schedule.png" alt="img" />
                  </span>
                  <span className="font13">Procurement Purchase Order</span>
                </Link>
              </li>

              <li className="s-sidebar__nav-link">
                <Link to="/procurement/departmental-purchase-order" target="_self" className="text-nowrap pr-2">
                  <span className="mr-2">
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1557141046/Schedule.png" alt="img" />
                  </span>
                  <span className="font13">InterDepartmental Purchase Order</span>
                </Link>
              </li>

              <li className="s-sidebar__nav-link">
                <Link to="/procurement/approved-purchase-order" target="_self" className="text-nowrap pr-2">
                  <span className="mr-2">
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1557141046/Schedule.png" alt="img" />
                  </span>
                  <span className="font13">Approved Purchase Order</span>
                </Link>
              </li>
            </span>

            <li className="s-sidebar__nav-link">
              <Link to="/procurement/requisition" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1550844527/requisition.png" alt="img" />
                </span>
                Requisition
                <RequisitionNotification />
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/procurement/escalate" target="_self" className="text-danger make-bold">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1557141046/Schedule.png" alt="img" />
                </span>
                Escalate
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              <Link to="/procurement/issue" target="_self" className="text-danger make-bold">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923430/r_d-envelop.fw.png" alt="img" />
                </span>
                Report an Issue
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              <Link to="/procurement/learning-portal" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923430/r_d-envelop.fw.png" alt="img" />
                </span>
                Learning Portal
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              <Link to="/procurement/feature" target="_self" className="make-bold">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923169/configr_d.fw.png" alt="img" />
                </span>
                Request a Feature
              </Link>
            </li>
          </span>
        </ul>
      </nav>
    </div>
  );
};

export default ProcurementSideBar;
