export const GET_JOBS = 'GET_JOBS';
export const GET_JOBS_BY_DEPARTMENT = 'GET_JOBS_BY_DEPARTMENT';
export const DELETE_JOB = 'DELETE_JOB';
export const GET_ACCOUNTS_JOBS = 'GET_ACCOUNTS_JOBS';
export const GET_CUSTOMER_SUPPORT_JOBS = 'GET_CUSTOMER_SUPPORT_JOBS';
export const GET_NEW_PRODUCTS_JOBS = 'GET_NEW_PRODUCTS_JOBS';
export const GET_NOC_JOBS = 'GET_NOC_JOBS';
export const GET_SALES_JOBS = 'GET_SALES_JOBS';
export const GET_OPS_JOBS = 'GET_OPS_JOBS';
export const GET_PROCUREMENT_JOBS = 'GET_PROCUREMENT_JOBS';
export const GET_RESEARCH_JOBS = 'GET_RESEARCH_JOBS';
export const GET_TECHSUPPORT_JOBS = 'GET_TECHSUPPORT_JOBS';
