/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import Moment from 'react-moment';
import view from '../../../../../assets/img/icons/view.png';
import edit from '../../../../../assets/img/icons/edit.png';
import EditDedicatedInvoice from './EditDedicatedInvoice';
import ViewDedicatedInvoice from './ViewDedicatedInvoice';

const DedicatedInvoiceBodyTable = ({ lists }) => {
  const [one, setOne] = useState({});

  const getInvoice = (list) => setOne(list);

  const tableData =
    lists.length && lists ? (
      lists.map((list, i) => {
        const { name, mac_id, email, unit, price, expiration_date, total_ex_vat, total_with_vat } = list;
        return (
          <tr className="text-nowrap" key={i}>
            <td>{i + 1}</td>
            <td>{mac_id}</td>
            <td>{name}</td>
            <td>{email}</td>
            <td>{unit}</td>
            <td>{total_ex_vat}</td>
            <td>{total_with_vat}</td>
            <td>{price}</td>
            <td>
              <Moment format="DD-MM-YYYY">{expiration_date}</Moment>
            </td>
            <td>
              <div className="">
                <button
                  type="submit"
                  className="mx-2"
                  data-toggle="modal"
                  data-placement="right"
                  data-target="#view_invoice"
                  onClick={() => getInvoice(list)}
                >
                  <span className="view">
                    <img src={view} alt="" data-toggle="tooltip" data-placement="left" title="View" />
                  </span>
                </button>
                <button
                  type="submit"
                  className="mr-2"
                  data-toggle="modal"
                  data-placement="right"
                  data-target="#update_invoice"
                  onClick={() => getInvoice(list)}
                >
                  <span className="view">
                    <img src={edit} data-toggle="tooltip" alt="" data-placement="top" title="Edit" />
                  </span>
                </button>
              </div>
              <EditDedicatedInvoice invoice={one} />
              <ViewDedicatedInvoice invoice={one} />
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="10" className="text-center">
          There are no invoices
        </td>
      </tr>
    );

  return tableData;
};

export default DedicatedInvoiceBodyTable;
