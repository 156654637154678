/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useUpdateAmbassadorsStatus } from '../../../../../hooks/express-wifi/useUpdateAmbassadorsStatus';

const UpdateAmbassadorsStatus = ({ request, request: { status, ambassadors_name } }) => {
  const { loading, updateAmbassadorsStatus } = useUpdateAmbassadorsStatus();
  const [one, setOne] = useState({
    action: 'change_status_of_ambassador',
    update_status: '',
    ambassadors_count_name: '',
  });
  const { update_status, ambassadors_count_name } = one;

  useEffect(() => {
    const result = {
      update_status: status,
      ambassadors_count_name: ambassadors_name,
    };
    setOne({ ...result });
  }, [request]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOne({ ...one, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      action: 'change_status_of_ambassador',
      status: update_status,
      name: ambassadors_count_name,
    };
    updateAmbassadorsStatus(data);
  };

  const submitForm = () => {
    return ambassadors_count_name.length > 0 && update_status.length > 0;
  };

  return (
    <div id="updateStatus" className="modal fade" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header shadow">
            <h4 className="modal-title update_asset">Update Ambassadors Status</h4>
            <button type="button" className="close mr-2" data-dismiss="modal">
              x
            </button>
          </div>
          <div className="modal-body detail mb-4 p-0">
            <form onSubmit={onSubmit} className="col-md-12">
              <div className="pt-2 d-flex">
                <div className="form-group d-flex mb-0 pb-0">
                  <label className="m-0 p-0 copy-font" htmlFor="firstName">
                    Status
                  </label>
                  <select id="status" name="update_status" value={update_status} onChange={handleChange}>
                    <option value="">Select an option</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </div>
                <label className="m-0 p-0 copy-font" htmlFor="firstName">
                  Ambassadors Name
                </label>
                <input id="name" name="ambassadors_count_name" value={ambassadors_count_name} onChange={handleChange} />
              </div>

              <div className="text-center mt-3">
                {loading ? (
                  <button type="button" className="btn btn-green py-2 px-5" disabled>
                    Updating...
                  </button>
                ) : (
                  <button type="submit" className="btn btn-green py-2 px-5" disabled={!submitForm()}>
                    Update
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

UpdateAmbassadorsStatus.propTypes = {
  request: PropTypes.objectOf.isRequired,
};

export default UpdateAmbassadorsStatus;
