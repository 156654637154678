/* eslint-disable no-console */
import axios from 'axios';
import { toast } from 'react-toastify';
import { useReducer } from 'react';
import { GENIATECH_GET_PAYMENT_HISTORY_URL } from '../../../../../services/api/shared/GeniatechUrl';
import { LOADING_STARTS, LOADING_STOPS } from '../../../../../constants/types/shared/LoadingTypes';
import PaymentHistoryReducer from '../../../../../reducers/shared/customers/payments/PaymentHistoryReducer';
import { GET_PAYMENT_HISTORY } from '../../../../../constants/types/shared/customers/CustomerTypes';

const usePayment = () => {
  const [{ items, totalPages, currentPage, allPages, isLoading }, dispatch] = useReducer(PaymentHistoryReducer, {
    items: [],
    totalPages: '',
    allPages: '',
    currentPage: '',
    isLoading: false,
  });

  const getPayment = async (data) => {
    dispatch({ type: LOADING_STARTS });
    try {
      const response = await axios.post(`${GENIATECH_GET_PAYMENT_HISTORY_URL}`, data);
      if (response.data) {
        console.log(response.data.items);
        dispatch({
          type: GET_PAYMENT_HISTORY,
          payload: {
            items: response.data.items,
            totalPages: response.data.total_records,
            allPages: response.data.total_pages,
            currentPage: response.data.current_page,
          },
        });
      }
    } catch (e) {
      toast.info('Something went wrong!');
      if (e.response) {
        dispatch({ type: LOADING_STOPS });
      }
    }
  };

  return { getPayment, items, totalPages, allPages, currentPage, isLoading };
};

export default usePayment;
