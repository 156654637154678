import axios from 'axios';
import { toast } from 'react-toastify';
import {
  GET_AVAILABLE_TIMES_URL,
  GET_CATEGORIES_FOR_INSTALLATION_URL,
  GET_SUBZONES_URL,
  SCHEDULE_USER_URL,
} from '../../../services/api/shared/installations/SchedulerUrl';
import {
  GET_AVAILABLE_TIMES,
  GET_CATEGORIES_FOR_INSTALLATIONS,
  GET_SUB_ZONES,
  START_LOADING_SUBZONES,
  START_LOADING_TIME,
  START_SCHEDULING,
  STOP_LOADING_SUBZONES,
  STOP_LOADING_TIME,
  STOP_SCHEDULING,
} from '../../../constants/types/sales/installations/SchedulerTypes';

export const scheduleInstallation = (data, history) => async (dispatch) => {
  dispatch({
    type: START_SCHEDULING,
    payload: true,
  });

  try {
    const response = await axios.post(`${SCHEDULE_USER_URL}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data.id) {
      toast.success('Scheduling Successful!');
      setTimeout(() => {
        history.goBack();
      }, 2000);
      // dispatch(getLeads)

      // setTimeout(() => {
      //
      //   window.location.reload();
      //
      // }, 2000);
    }

    if (response.data.status === 'failed') {
      toast.info(response.data.message);

      dispatch({
        type: STOP_SCHEDULING,
        payload: false,
      });
    }
  } catch (e) {
    if (e || e.response) {
      toast.info(e.response.data.message);

      dispatch({
        type: STOP_SCHEDULING,
        payload: false,
      });
    }
  }
};

export const getCategoryForInstallations = () => async (dispatch) => {
  try {
    const response = await axios.get(`${GET_CATEGORIES_FOR_INSTALLATION_URL}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      dispatch({
        type: GET_CATEGORIES_FOR_INSTALLATIONS,
        payload: response.data,
      });
    } else {
      toast.info('Unable to get categories for installations');
    }
  } catch (e) {}
};

export const getSubZones = (data) => async (dispatch) => {
  dispatch({
    type: START_LOADING_SUBZONES,
    payload: true,
  });

  try {
    const response = await axios.get(`${GET_SUBZONES_URL}/${data}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      dispatch({
        type: GET_SUB_ZONES,
        payload: response.data,
      });
    } else {
      dispatch({
        type: STOP_LOADING_SUBZONES,
        payload: true,
      });
    }
  } catch (e) {
    dispatch({
      type: STOP_LOADING_SUBZONES,
      payload: false,
    });
  }
};

export const getAvailableTimes = (data) => async (dispatch) => {
  dispatch({
    type: START_LOADING_TIME,
    payload: true,
  });

  try {
    const response = await axios.post(`${GET_AVAILABLE_TIMES_URL}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    console.log(response.data);
    if (response.data.length) {
      dispatch({
        type: GET_AVAILABLE_TIMES,
        payload: response.data,
      });
    } else {
      toast.info('no available times');
      dispatch({
        type: STOP_LOADING_TIME,
        payload: false,
      });
    }
  } catch (e) {
    if (e.response) {
      toast.info('something went wrong');

      dispatch({
        type: STOP_LOADING_TIME,
        payload: false,
      });
    }
  }
};
