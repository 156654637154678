/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useBasestationStatus from '../../../../../hooks/noc/basestation-status/useBasestationStatus';
import { useUser } from '../../../../../hooks/shared/user/useUser';

const UpdateBasestationStatus = ({ request, request: { status, basestation, zone } }) => {
  const { loading, updateBasestationStatus } = useBasestationStatus();
  const { profile } = useUser();
  const [one, setOne] = useState({
    action: 'update_current_basestation_status',
    basestation_status: '',
    basestation_name: '',
    basestation_zone: '',
  });
  const { basestation_status, basestation_name, basestation_zone } = one;

  const staffEmail = `${profile.email}`;

  useEffect(() => {
    const result = {
      basestation_status: status,
      basestation_name: basestation,
      basestation_zone: zone,
    };
    setOne({ ...result });
  }, [request]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOne({ ...one, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      action: 'update_current_basestation_status',
      status: basestation_status,
      basestation: basestation_name,
      zone: basestation_zone,
      staff_email: staffEmail,
    };
    updateBasestationStatus(data);
  };

  //   const submitForm = () => {
  //     return (
  //       basestation_status.length > 0 &&
  //       basestation_name.length > 0 &&
  //       basestation_zone.length > 0 &&
  //       staffEmail.length > 0
  //     );
  //   };

  return (
    <div id="updateBasestationStatus" className="modal fade" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header shadow">
            <h4 className="modal-title update_asset">Update Basestation Status</h4>
            <button type="button" className="close mr-2" data-dismiss="modal">
              x
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={onSubmit} className="form-horizontal form-material">
              <div className="col-md-12 mb-3 d-flex">
                <div className="col-lg-6">
                  <label className="copy-font" htmlFor="firstName">
                    Status
                  </label>
                  <div className="form-group ml-2">
                    <select id="status" name="basestation_status" value={basestation_status} onChange={handleChange}>
                      <option value="">Select an option</option>
                      <option value="online">Online</option>
                      <option value="offline">Offline</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <label className="copy-font" htmlFor="firstName">
                    Basestation
                  </label>
                  <input
                    id="basestation"
                    name="basestation"
                    className="form-control"
                    value={basestation_name}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-12 mb-3 d-flex">
                <div className="col-lg-6">
                  <label className="copy-font" htmlFor="zone">
                    Zone
                  </label>
                  <input
                    id="zone"
                    name="zone"
                    className="form-control"
                    value={basestation_zone}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-lg-6">
                  <label className="copy-font" htmlFor="mail">
                    Email
                  </label>
                  <input
                    id="email"
                    name="staffemail"
                    className="form-control"
                    value={staffEmail}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="text-center mt-3">
                {loading ? (
                  <button type="button" className="btn btn-green py-2 px-5" disabled>
                    Updating...
                  </button>
                ) : (
                  <button type="submit" className="btn btn-green py-2 px-5">
                    Update
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

UpdateBasestationStatus.propTypes = {
  request: PropTypes.objectOf.isRequired,
};

export default UpdateBasestationStatus;
