/* eslint-disable import/prefer-default-export */
import { useReducer, useState } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import * as load from '../../../constants/types/shared/LoadingTypes';
import { GET_REFERRAL_REQUEST } from '../../../constants/types/sales/customers/ReferralTypes';
import { logsReducer } from '../../../reducers/business-development/logs/logsReducer';
import { GET_LOGS_URL } from '../../../services/api/business-development/logs/LogsUrl';

export const useLogs = () => {
  const [{ lists, count, pages, isLoading }, dispatch] = useReducer(logsReducer, {
    lists: [],
    count: 1,
    pages: '',
    isLoading: false,
  });

  const [page] = useState(1);

  const getLogs = async (selectedPage) => {
    dispatch({ type: load.LOADING_STARTS });
    const config = {
      method: 'get',
      url: `${GET_LOGS_URL}/?plan=turbo_connect`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      }
    };
    axios(config)
      .then((response) => {
        if (response.data) {
          console.log(response.data.data);
          dispatch({
            type: GET_REFERRAL_REQUEST,
            payload: {
              lists: response.data.users,
              count: response.data.count,
              pages: response.data.pages,
            },
          });
        }
      })
      .catch(() => {
        toastr.info('Something went wrong!');
        dispatch({ type: load.LOADING_STOPS });
      });
  };

  return {
    lists,
    pages,
    count,
    isLoading,
    getLogs,
  };
};
