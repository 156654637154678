import React from 'react';
import usePtpVlanIp from '../hooks/usePtpVlanIp';
import { PTP_VLAN_IP_URL } from '../api/PtpVlanIpUrl';
import AppContext from '../../../../../../../context/shared/AppContext';

const PtpVlanIpProvider = ({ children }) => {
  const { data, loading, paginateChange, loadingForm, onChange, formValue, onSubmit, pages } = usePtpVlanIp(
    `${PTP_VLAN_IP_URL}`,
  );
  return (
    <AppContext.Provider
      value={{
        data,
        loading,
        paginateChange,
        loadingForm,
        onChange,
        formValue,
        onSubmit,
        pages,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export default PtpVlanIpProvider;
