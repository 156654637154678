/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React from 'react';

const SearchRetentionLogs = ({
  handleChange,
  searchLogs,
  filtered,
  logs: { type, customerEmail, country, fromDate, toDate, filterBy, filterValue },
}) => {
  const validateForm = () => country.length > 0 && type.length > 0;

  return (
    <div className="d-flex">
      <form className="form-inline ml-3" onSubmit={searchLogs}>
        <div className="form-group">
          <select defaultValue={country} name="country" onChange={handleChange} className="form-control">
            <option value="">--Choose Country--</option>
            <option value="Nigeria">Nigeria</option>
            <option value="Ghana">Ghana</option>
          </select>
        </div>
        <div className="form-group mr-2">
          <select defaultValue={type} name="type" onChange={handleChange} className="form-control">
            <option value="">--Type--</option>
            <option value="sms">SMS</option>
            <option value="email">Email</option>
            <option value="comment">Comment</option>
          </select>
        </div>
        <div className="form-group mr-2">
          <input
            className="form-control py-2"
            type="email"
            placeholder="Customer Email"
            name="customerEmail"
            value={customerEmail}
            onChange={handleChange}
          />
        </div>
        <div className="form-group mx-2">
          <label className="mt-2 p-0 copy-font">From: </label>
          <input type="date" className="form-control mr-2" name="fromDate" value={fromDate} onChange={handleChange} />
          <label className="mt-2 p-0 copy-font">To: </label>
          <input type="date" className="form-control" name="toDate" value={toDate} onChange={handleChange} />
        </div>
        <div className="form-group">
          <select
            value={filterBy}
            name="filterBy"
            onChange={handleChange}
            className="form-control"
            disabled={!country.length}
          >
            <option value="">--Filter By--</option>
            <option value="agent">Agent</option>
            <option value="basestation">Basestation</option>
            <option value="comment">Comment</option>
            <option value="zone">Zone</option>
            <option value="none">All</option>
          </select>
        </div>
        {filterBy !== 'none' && (
          <div className="form-group">
            <select defaultValue={filterValue} name="filterValue" onChange={handleChange} className="form-control">
              <option value="">--Value--</option>
              {filtered.items && filtered.items.length ? (
                filtered.items.map((filter) => (
                  <option key={filter} value={filter}>
                    {filter.toUpperCase()}
                  </option>
                ))
              ) : (
                <option>Loading...</option>
              )}
            </select>
          </div>
        )}
        <div className="form-group mx-2">
          <button
            className={`btn btn-${validateForm() ? 'green' : 'secondary'} `}
            type="submit"
            disabled={!validateForm()}
          >
            Search
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchRetentionLogs;
