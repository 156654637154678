/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import '../../../../styles/style.css';
import '../../../../styles/vehicle.css';
import ProcurementNavBar from '../../../layouts/procurement/ProcurementNavBar';
import ReleaseReportBody from './ReleaseReportBody';
import { NOC, OPERATIONS, PROCUREMENT, SUPER_ADMIN } from '../../../../utils/departments/helpers';
import { useUser } from '../../../../hooks/shared/user/useUser';
import useCheckTwoDepartments from '../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';
import NocNavBar from '../../../layouts/noc/NocNavBar';
import SuperAdminNavBar from '../../../layouts/super-admin/SuperAdminNavBar';
import FieldSupportNavBar from '../../../layouts/field-support/FieldSupportNavBar';

const ReleaseReport = ({ history }) => {
  // useCheckTwoDepartments(`${PROCUREMENT}`, `${SUPER_ADMIN}`, history);

  const departmentId = sessionStorage.getItem('departmentId');
  const { profile, user } = useUser();
  const { firstName } = profile;
  return (
    <div>
      {(departmentId === `${PROCUREMENT}` && (
        <div className="s-layout">
          <main className="s-layout__content col-lg-10 p-0">
            <span className="d-md-none">
              <ProcurementNavBar currentPage="Release Report" position={firstName} />
            </span>

            <ReleaseReportBody />
          </main>
        </div>
      )) ||
        (departmentId === `${NOC}` && (
          <div className="s-layout">
            <main className="s-layout__content col-lg-10 p-0">
              <NocNavBar currentPage="Release Report" roleID={user.role_id} position={firstName} />
       
              <ReleaseReportBody />
            </main>
          </div>
        )) ||
        (departmentId === `${OPERATIONS}` && (
          <div className="s-layout">
            <main className="s-layout__content col-lg-10 p-0">
              <FieldSupportNavBar currentPage="Release Report" roleID={user.role_id} position={firstName} />
              <ReleaseReportBody />
            </main>
          </div>
        )) ||
        (departmentId === `${SUPER_ADMIN}` && (
          <div className="s-layout">
            <main className="s-layout__content col-lg-10 p-0">
              <SuperAdminNavBar currentPage="Release Report" roleID={user.role_id} position={firstName} />
              <ReleaseReportBody />
            </main>
          </div>
        ))}
    </div>
  );
};

ReleaseReport.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default ReleaseReport;
