import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import toastr from 'toastr';
import PropTypes from 'prop-types';
import AccountSideBar from '../../../../../layouts/accounts/sidebar/AccountsSideBarClass';
import Logout from '../../../../shared/auth/Logout';
import Requests from '../../../../shared/requests/non-admin/Requests';
import { LOGGED_IN_USER_URL } from '../../../../../../services/api/auth/AuthUrl';
import { ACCOUNTS, SUPER_ADMIN } from '../../../../../../utils/departments/helpers';

const AccountRequest = ({ history }) => {
  const checkDepartment = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.department_id !== `${ACCOUNTS}`  && response.data.department_id !== `${SUPER_ADMIN}`) {
        sessionStorage.clear();
        sessionStorage.setItem('token', '');
        sessionStorage.setItem('email', '');
        sessionStorage.setItem('departmentId', '');
        sessionStorage.setItem('roleId', '');
        history.push('/');

        toastr.info('You are not allowed to view this page');
      }
    } catch (e) {
      // console.log(e.response);
    }
  };

  useEffect(() => {
    checkDepartment();

    return () => {
      checkDepartment();
    };
  });

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <div className="d-flex col-lg-12 p-2 top-header">
          <div className="mt-3 d-flex col-lg-12 p-0">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link className="s-sidebar__trigger" to="#">
              <i className="fa fa-bars mr-3" />
            </Link>
            <AccountSideBar />

            <h3 className="col-lg-6 col-md-6 col-sm-6 overview-part">Admin Request</h3>

            <div className="d-flex profile-mail col-5">
              <h6>
                {/* <i className="mr-auto"></i> */}
                <Logout />
              </h6>
            </div>
          </div>
        </div>
        <Requests />
      </main>
    </div>
  );
};

AccountRequest.propTypes = {
  // eslint-disable-next-line react/require-default-props
  history: PropTypes.shape(),
};

export default AccountRequest;
