/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
// import { LteEnbIpProps } from './interfaces/LteEnbIpProps';

const ViewLteEnbIp = ({ view }) => {
  return (
    <div id="view_access-point" className="modal fade mt-5" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title my-2">Details</h4>
            <button type="button" className="mr-2 close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div className="modal-body detail p-0">
            <div className="row">
              <div className="col-lg-12 d-flex">
                <div className="col-lg-5 detail-label1">
                  <label className="mt-1"> Site</label>
                  <label className="mt-1">IP Address</label>
                  <label className="mt-1">Sector ID</label>
                </div>
                <div className="col-lg-7 detail-result1 item-font">
                  <p className="mt-4 mb-2">{view.site}</p>
                  <p className="mb-2">{view.ip_address}</p>
                  <p className="mb-2">{view.sector_id}</p>
                </div>
              </div>

              {/* <div className="col-lg-12 d-flex"> */}
              {/*  <div className="col-lg-5 detail-label3"> */}
              {/*    <label>Latitude</label> */}
              {/*     <label>Public IP Block</label> */}
              {/*     <label>Assigned Public IP</label> */}
              {/*  </div> */}
              {/*  <div className="col-lg-7 detail-result2 item-font"> */}
              {/*    <p className="mt-4 mb-2">{view.latitude}</p> */}
              {/*     <p className="mb-2">{view.public_ip_block}</p> */}
              {/*     <p className="mb-2">{view.assigned_public_ip}</p> */}
              {/*  </div> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewLteEnbIp;
