import React from 'react';
import './index.css';
import MailBlastBodyTable from './MailBlastBodyTable';
import MailBlastBasestationCustomersFilter from './MailBlastBasestationCustomersFilter';
import MailBlastProvider from './MailBlastProvider';

const MailBlastBody = () => {
  return (
    <div className="mt-5 mailblast-body">
      <MailBlastProvider>
        <div className="container pt-5">
          <div className="row">
            <MailBlastBasestationCustomersFilter />
            <MailBlastBodyTable />
          </div>
        </div>
      </MailBlastProvider>
    </div>
  );
};

export default MailBlastBody;
