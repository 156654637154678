import axios from 'axios';
import { useEffect, useState, useReducer } from 'react';
import { toast } from 'react-toastify';
import * as url from '../../../services/api/shared/requests/RequestsUrl';
import { requestsReducer } from '../../../reducers/shared/requests/requestsReducer';
import * as types from '../../../constants/types/shared/requests/RequestTypes';
import * as load from '../../../constants/types/shared/LoadingTypes';

// eslint-disable-next-line import/prefer-default-export
export const useRequests = () => {
  const requestState = {
    category: '',
    description: '',
    code: '',
  };

  const [categories, setCategories] = useState({});

  const [request, setRequest] = useState(requestState);

  const [{ isLoadingRequest, requests, isLoading }, dispatch] = useReducer(requestsReducer, {
    isLoadingRequest: false,
    requests: [],
    isLoading: false,
  });

  const getEmployeeRequests = async () => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.get(`${url.GET_EMPLOYEE_REQUESTS_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_REQUESTS,
          payload: response.data,
        });
      }
    } catch (error) {
      if (error) {
        dispatch({ type: load.LOADING_STOPS });
      }
    }
  };

  const getCategories = async () => {
    try {
      const response = await axios.get(`${url.GET_CATEGORIES_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        setCategories(response.data);
      }
    } catch (error) {
      if (error) {
        toast.error('Unable to get categories');
      }
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setRequest({
      ...request,
      [name]: value,
    });
  };

  const makeRequest = async (data) => {
    dispatch({
      type: types.START_CREATE_REQUEST,
    });
    console.log(data);
    try {
      const response = await axios.post(`${url.CREATE_REQUEST_URL}`, data, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
      });
      console.log(response.data);

      if (response.data.id) {
        toast.success('Request has been logged!');
        setRequest(requestState);
        dispatch({ type: types.CREATE_REQUEST, payload: response.data });
      } else {
        toast.info('Unable to log request logged!');

        dispatch({
          type: types.STOP_CREATE_REQUEST,
        });
      }
    } catch (e) {
      if (e) {
        toast.info('Something went wrong while trying to make a request!');
        dispatch({
          type: types.STOP_CREATE_REQUEST,
        });
      }
    }
  };

  useEffect(() => {
    getCategories();
    getEmployeeRequests();
  }, []);

  return {
    makeRequest,
    onChange,
    request,
    isLoadingRequest,
    categories,
    requests,
    isLoading,
  };
};
