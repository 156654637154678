/* eslint-disable import/prefer-default-export */
import {
  START_ADD_CALL_LOG,
  STOP_ADD_CALL_LOG,
  GET_CALL_LOGS,
  REFETCH_CALL_LOGS,
} from '../../../constants/types/customer-support/CustomerSupportTypes';
import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';

export const callLogReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case START_ADD_CALL_LOG:
      return { ...state, submitting: true };
    case STOP_ADD_CALL_LOG:
      return { ...state, submitting: false };
    case GET_CALL_LOGS:
      return { ...state, callLogs: payload.list, submitting: false, loading: false };
    case REFETCH_CALL_LOGS:
      return { ...state, callLogs: payload, submitting: false };
    case LOADING_STARTS:
      return { ...state, loading: true };
    case LOADING_STOPS:
      return { ...state, loading: false };
    default:
      return state;
  }
};
