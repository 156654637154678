/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import moment from 'moment';
import EditLog from './EditLog';
import CallogDetails from './CallogDetails';
import Loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import view from '../../../../../assets/img/icons/view.png';
import edit from '../../../../../assets/img/icons/edit.png';
import Convert from './Convert';

class SalesLogsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      logId: {},
    };
  }

  getLog = (log) =>
    this.setState({
      logId: log,
    });

  render() {
    const { logs, load } = this.props;

    const { logId } = this.state;

    if (load) {
      return (
        <tbody>
          <tr>
            <td>
              <img src={Loader} alt="" />
            </td>
          </tr>
        </tbody>
      );
    }

    const salesList = logs ? (
      logs.map((log, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <tr key={index + 1}>
          {/* <img alt={pokemon} src={pokemon} /> */}
          <td>{index + 1}</td>
          <td>{log.firstName}</td>
          <td>{log.lastName}</td>
          <td>{log.phoneNumber}</td>
          <td>{log.email}</td>
          <td>{log.address}</td>
          <td>{log.state}</td>

          <td>{moment(log.created_at).format('MM/DD/YYYY')}</td>
          {/* <td align="center" className="selectdiv">
                            <select>
                                <option>Support</option>
                                <option>Sales</option>
                            </select>
                        </td> */}
          <td>
            <span className="badge badge-success" style={{ backgroundColor: 'red' }}>
              {log.status}
            </span>
          </td>
          {/* <td>

                        {log.status === "paid" ?
                          <div>
                            <img src="https://res.cloudinary.com/teewhy/image/upload/v1545666961/Scheduler.png"
                               width="30%"
                               style={{cursor:"pointer"}}
                               data-toggle="modal"
                               alt=""
                               className="w-25 ml-5"
                            // onClick={() => this.whatever(lead.id)}
                               onClick={() => this.getLog(log)}
                               data-target="#scheduler"/>
                               <Scheduler lead={logId}/>
                               </div>
                          :
                          null
                        }

                      </td> */}

          {/* <td>
            <div className="text-center">
              <img
                alt=""
                src="https://res.cloudinary.com/teewhy/image/upload/v1545642324/checked.png"
                className="w-25"
                onClick={() => this.getLog(log)}
                style={{ cursor: 'pointer' }}
                data-toggle="modal"
                data-target="#convert"
              />
              <Convert user={logId} />
            </div>
          </td> */}

          <td>
            <div className="d-flex">
              {/* view */}
              <div className="mr-2 ml-2" data-target="#callogdetails" data-toggle="modal">
                <button
                  type="submit"
                  onClick={() => this.getLog(log)}
                  style={{
                    border: '0',
                    backgroundColor: 'white',
                  }}
                >
                  <span className="view2">
                    <img src={view} alt="" data-toggle="tooltip" data-placement="left" title="View" />
                  </span>
                </button>
              </div>
              <CallogDetails log={logId} />

              {/* Edit */}
              <div data-target="#editcallog" data-toggle="modal">
                <button
                  type="submit"
                  onClick={() => this.getLog(log)}
                  style={{
                    border: '0',
                    backgroundColor: 'white',
                  }}
                >
                  <span className="view2">
                    <img src={edit} alt="" data-toggle="tooltip" data-placement="top" title="Edit" />
                  </span>
                </button>
              </div>
              <EditLog log={logId} />
            </div>
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td>You have no call logs!</td>
      </tr>
    );

    return <tbody>{salesList}</tbody>;
  }
}

export default SalesLogsTable;
