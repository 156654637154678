import { useEffect, useReducer } from 'react';
import axios from 'axios';
import qs from 'qs';
import toastr from 'toastr';
import * as load from '../../../constants/types/shared/LoadingTypes';
import * as types from '../../../constants/types/sales/installations/InstallationsTypes';
import * as url from '../../../services/api/shared/installations/InstallationsUrl';
import { API_URL } from '../../../utils/config';
import { activationReducer } from '../../../reducers/shared/installations/activationReducer';

export const useActivation = () => {
  const [
    {
      fetching,
      isActivating,
      isFetching,
      isChecking,
      fetchingSalesTypes,
      isValid,
      validMAC,
      estates,
      basestations,
      dimension1,
      dimension2,
      salesTypesArr,
    },
    dispatch,
  ] = useReducer(activationReducer, {
    fetching: false,
    isActivating: false,
    isFetching: false,
    isChecking: false,
    fetchingSalesTypes: false,
    isValid: '',
    validMAC: '',
    estates: [],
    basestations: [],
    dimension1: [],
    dimension2: [],
    salesTypesArr: [],
  });

  const AccessKey = `d$$jsdjujweneqwjuewhefbe`;

  const newActivateAccount = async (data, macid, password) => {
    // console.log(data, 'data');
    dispatch({ type: load.START_ACTIVATING_ACCOUNT });
    try {
      const response = await axios.post(`${url.NEW_CREATE_ACCOUNT_URL}`, data, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
      });
      console.log(response.data);
      if (
        response.data.data &&
        response.data.frontAccounting.status === true &&
        response.data.geniatech.status === true
      ) {
        toastr.success('User has been created!');
        toastr.success('Customer was successfully created on front accounting');
        toastr.success('Customer was successfully created on geniatech');
        const link = document.createElement('a');
        link.href = `http://www.tizeti.com/scripts/construction.php?w=us0&efa=${macid}&egoni=${password}&accesskey=${AccessKey}`;

        document.body.appendChild(link);
        link.click();
        dispatch({ type: load.STOP_ACTIVATING_ACCOUNT });
      } else {
        toastr.info('Unable to create user!');
        dispatch({ type: load.STOP_ACTIVATING_ACCOUNT });
      }
    } catch (e) {
      console.log(e.response);
      if (e.response) {
        if (e.response.data.message === 'jwt expired') {
          toastr.info('Kindly Logout and Login');
        } else toastr.info(e.response.data.message);
        toastr.info(e.response.data.status);
        dispatch({ type: load.STOP_ACTIVATING_ACCOUNT });
      } else {
        toastr.info('Something went wrong!');
        dispatch({ type: load.STOP_ACTIVATING_ACCOUNT });
      }
    }
  };

  const activateAccount = async (data, macid, password) => {
    console.log(data, 'data');
    dispatch({ type: load.START_ACTIVATING_ACCOUNT });
    try {
      const response = await axios.post(`${url.CREATE_ACCOUNT_URL}`, data, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
      });
      console.log(response.data);
      if (
        response.data.data &&
        response.data.frontAccounting.status === true &&
        response.data.geniatech.status === true
      ) {
        toastr.success('User has been created!');
        toastr.success('Customer was successfully created on front accounting');
        toastr.success('Customer was successfully created on geniatech');
        const link = document.createElement('a');
        link.href = `http://www.tizeti.com/scripts/construction.php?w=us0&efa=${macid}&egoni=${password}&accesskey=${AccessKey}`;

        document.body.appendChild(link);
        link.click();
        dispatch({ type: load.STOP_ACTIVATING_ACCOUNT });
      } else {
        toastr.info('Unable to create user!');
        dispatch({ type: load.STOP_ACTIVATING_ACCOUNT });
      }
    } catch (e) {
      console.log(e.response);
      if (e.response) {
        if (e.response.data.message === 'jwt expired') {
          toastr.info('Kindly Logout and Login');
        } else toastr.info(e.response.data.message);
        toastr.info(e.response.data.status);
        dispatch({ type: load.STOP_ACTIVATING_ACCOUNT });
      } else {
        toastr.info('Something went wrong!');
        dispatch({ type: load.STOP_ACTIVATING_ACCOUNT });
      }
    }
  };

  const getDimension1 = async () => {
    try {
      const data = { action: 'fetchZones' };
      const response = await axios.post(`${url.LEGACY_FRONTACCOUNT_URL}`, qs.stringify(data));
      if (response.data) {
        dispatch({
          type: types.GET_DIMENSION1,
          payload: response.data.items,
        });
      }
    } catch (e) {
      // toastr.info('Something went wrong!');
    }
  };

  const getDimension2 = async (zone) => {
    dispatch({ type: load.FETCHING_DIMENSION_STARTS });
    try {
      const data = { action: 'fetchZoneCalledStation', zone };
      const response = await axios.post(`${url.LEGACY_FRONTACCOUNT_URL}`, qs.stringify(data));
      if (response.data.status) {
        dispatch({
          type: types.GET_DIMENSION2,
          payload: response.data.items,
        });
      } else dispatch({ type: load.FETCHING_DIMENSION });
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.FETCHING_DIMENSION_STOPS });
    }
  };
  const checkLTE = async (mac) => {
    dispatch({ type: load.CHECKING_MAC_STARTS });
    try {
      const data = { imsi: mac };
      const response = await axios.post(`${url.SEARCHIMSI}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.data === null) {
        dispatch({
          type: types.CHECK_MAC_ID,
          payload: {
            isValid: true,
            validMAC: mac,
          },
        });

        setTimeout(() => {
          dispatch({
            type: types.CHECK_MAC_ID,
            payload: {
              isValid: '',
              validMAC: mac,
            },
          });
        }, 10000);
      } else {
        dispatch({
          type: types.CHECK_MAC_ID,
          payload: { isValid: false },
        });

        setTimeout(() => {
          dispatch({
            type: types.CHECK_MAC_ID,
            payload: { isValid: '' },
          });
        }, 10000);
      }
    } catch (e) {
      toastr.info(e.response.data.message);
      dispatch({ type: load.CHECKING_MAC_STOPS });
    }
  };

  const checkMACID = async (mac) => {
    dispatch({ type: load.CHECKING_MAC_STARTS });
    try {
      const data = { action: 'checkActivationStatus', mac };
      const response = await axios.post(`${url.LEGACY_GENIATECH_URL}`, qs.stringify(data));
      if (response.data.status) {
        dispatch({
          type: types.CHECK_MAC_ID,
          payload: {
            isValid: true,
            validMAC: response.data.macaddress,
          },
        });

        setTimeout(() => {
          dispatch({
            type: types.CHECK_MAC_ID,
            payload: {
              isValid: '',
              validMAC: response.data.macaddress,
            },
          });
        }, 10000);
      } else {
        dispatch({
          type: types.CHECK_MAC_ID,
          payload: { isValid: false },
        });

        setTimeout(() => {
          dispatch({
            type: types.CHECK_MAC_ID,
            payload: { isValid: '' },
          });
        }, 10000);
      }
    } catch (e) {
      toastr.info(e.response.data.message);
      dispatch({ type: load.CHECKING_MAC_STOPS });
    }
  };

  const sendMail = async (installationId) => {
    dispatch({ type: load.CHECKING_MAC_STARTS });
    try {
      const data = { installationId };
      const response = await axios.post(`${`https://api.tizeti.com/api/v1/installationSurvey`}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        // console.log(test);
        window.location.reload();

        toastr.info('Feedback Sent Successfully');

        dispatch({
          type: load.LOADING_STARTS,
        });
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const sendActivationMail = async (data, message) => {
    try {
      const response = await axios.post(`${API_URL}/users/bizdev`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      console.log(response);
      if (response.data === 'success') {
        toastr.success(`activation email sent for ${message}`);
      } else {
        toastr.error(`email could not be sent for ${message}`);
      }
    } catch (error) {
      console.log('something went wrong', error);
    }
  };

  const getBillingEstates = async () => {
    dispatch({ type: load.FETCHING_STARTS });
    try {
      const response = await axios.get(`${url.GET_BILLING_ESTATES_URL}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_BILLING_ESTATES,
          payload: response.data.estates,
        });
      }
    } catch (e) {
      // toastr.info('Error fetching Billing Estates');
      dispatch({ type: load.FETCHING_STOPS });
    }
  };

  const getBasestations = async () => {
    dispatch({ type: load.LOADING_STARTS });
    const response = await axios.get(`${url.GET_BASESTATIONS_URL}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    if (response.data) {
      dispatch({
        type: types.GET_BASESTATIONS,
        payload: response.data,
      });
    } else {
      // toastr.info('Error fetching Basestations');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const getSalesType = async (country) => {
    dispatch({ type: load.FETCH_SALES_TYPE_STARTS });
    try {
      const data = qs.stringify({ action: 'get_sales_type', country });
      const response = await axios.post(`${url.LEGACY_FRONTACCOUNT_URL}`, data);
      if (response.data) {
        dispatch({
          type: types.GET_SALES_TYPES,
          payload: response.data.types,
        });
      } else {
        dispatch({ type: load.FETCH_SALES_TYPE_STOPS });
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.FETCH_SALES_TYPE_STOPS });
    }
  };

  useEffect(() => {
    getBillingEstates();
    getBasestations();
    getDimension1();
  }, []);

  return {
    fetching,
    isActivating,
    isFetching,
    isChecking,
    fetchingSalesTypes,
    isValid,
    validMAC,
    estates,
    basestations,
    dimension1,
    dimension2,
    salesTypesArr,
    checkMACID,
    checkLTE,
    newActivateAccount,
    getSalesType,
    getBillingEstates,
    getBasestations,
    getDimension2,
    sendMail,
    activateAccount,
    sendActivationMail,
  };
};

export default useActivation;
