import React from 'react';
import { Link } from 'react-router-dom';

const NocLogo = () => {
  return (
    <Link className="navbar-brand" to="/static-access-point-operations">
      <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971047/Tizeti-Logo.png" alt="homepage" />
    </Link>
  );
};

export default NocLogo;
