/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from 'react';
import helpers from './helpers';
import useExpressWifiSMS from '../../../../../../hooks/express-wifi/useExpressWifiSMS';

const XWFBulkSMS = ({ users, profile }) => {
  const { sendBulkSMS } = useExpressWifiSMS();

  const [text, setText] = useState({
    ChurnedCustomers: helpers.ChurnedCustomers,
    ActiveCustomersAboutToChurn: helpers.ActiveCustomersAboutToChurn,
    NoCategory: helpers.NoCategory,
  });
  const [category, setCategory] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setText({ ...text, [name]: value });
  };

  const onSubmitBulk = (e) => {
    e.preventDefault();
    const phones = [];
    if (users) {
      users.map((user) => {
        if (user.isChecked === true) return phones.push(user.phoneNumber);
      });
    }
    const data = {
      action: 'expresswifi_sms_helper',
      staff_email: `${profile.officialEmail}`,
      message: `${helpers.XWFmessages(category, text)}`,
      customer_phone: phones,
    };
    sendBulkSMS(data);
  };

  return (
    <div
      className="modal fade"
      id="bulkSMS"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog mt-5" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="exampleModalLabel">
              Bulk SMS Notification
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form onSubmit={onSubmitBulk}>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="text" className="p-0 col-form-label">
                  Category:
                </label>
                <div className="col-12">
                  <select
                    name="category"
                    onChange={(e) => setCategory(e.target.value)}
                    className="form-control border border-info py-2"
                  >
                    <option value="">--Select Category--</option>
                    <option value="CHURNED CUSTOMERS">CHURNED CUSTOMERS</option>
                    <option value="ACTIVE CUSTOMERS ABOUT TO CHURN">ACTIVE CUSTOMERS ABOUT TO CHURN</option>
                  </select>
                </div>
              </div>
              {category.length > 0 && (
                <div className="form-group">
                  <label className="p-0 col-form-label">Message:</label>
                  <textarea
                    className="form-control"
                    name={helpers.XWFname(category)}
                    value={helpers.XWFmessages(category, text)}
                    rows={8}
                    onChange={handleChange}
                  />
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">
                Close
              </button>
              <button type="submit" className="btn btn-primary" disabled={!category.length}>
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default XWFBulkSMS;
