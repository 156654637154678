import moment from 'moment';

export default {
  emailIsValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  },
  validatePhoneNumber(input) {
    return !Number.isNaN(input);
  },
  phoneIsValid(phone) {
    // eslint-disable-next-line no-useless-escape
    return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(phone);
  },

  checkIfMacId(mac) {
    return /^([0-9A-F]{2}[:-]){5}([0-9A-F]{2})$/.test(mac);
  },

  addCommas(amount) {
    const str = amount.toString().split('.');
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return str.join('.');
  },

  formatDateAndTime(date) {
    return moment(date).format('DD/MM/YYYY');
  },

  formatDateAndTimes(date) {
    return moment.utc(date).format('DD-MM-YYYY HH:mm:ss');
  },
  formatCurrency(wallet) {
    return Number(wallet).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  },
  formatDate(date) {
    return moment(date).format('YYYY-MM-DD');
  },
  currentDate() {
    return moment(new Date()).format('YYYY-MM-DD');
  },
  formattedCurrentDate() {
    return moment().format('YYYY-MM-DD');
  },
  formatTime(date) {
    return moment.utc(date).format('HH:mm:ss');
  },
  daysArray(length) {
    const days = [];
    for (let day = 0; day < length; ) {
      days.push((day += 1));
    }
    return days;
  },
  capitalizeAllFirstLetters(str) {
    const pieces = str.split(' ');
    for (let i = 0; i < pieces.length; i++) {
      const j = pieces[i].charAt(0).toUpperCase();
      pieces[i] = j + pieces[i].substr(1);
    }
    return pieces.join(' ');
  },

  capitalizeAllFirstLetter(str) {
    if (str === 'approved') {
      const str = 'paid';
      const pieces = str.split(' ');
      for (let i = 0; i < pieces.length; i++) {
        const j = pieces[i].charAt(0).toUpperCase();
        pieces[i] = j + pieces[i].substr(1);
      }
      return pieces.join(' ');
    } else {
      const pieces = str.split(' ');
      for (let i = 0; i < pieces.length; i++) {
        const j = pieces[i].charAt(0).toUpperCase();
        pieces[i] = j + pieces[i].substr(1);
      }
      return pieces.join(' ');
    }
  },
};
