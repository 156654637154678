/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { ToastContainer } from 'react-toastify';
import useRetailer from '../../../../../hooks/shared/express-wifi/retailer/sms/useRetailer';
import RetailerSmsTable from './RetailerSmsTable';

const RetailerSms = () => {
  const {
    sms,
    loading,
    onChange,
    text,
    searchWithPhone,
    dateTime,
    onDateTimeChange,
    searchWithDateAndTimeRange,
  } = useRetailer();

  const { value } = text;
  const { fromdate, fromtime, todate, totime } = dateTime;
  const submitForm = () => fromdate.length > 0 && fromtime.length > 0 && todate.length > 0 && totime.length > 0;
  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col-lg-12">
          <div className="mt-3 d-flex flex-wrap col-lg-6 col-md-6 col-sm-6">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                searchWithPhone(text);
              }}
              className="form-inline mt-4 d-flex"
            >
              <div className="input-group mt-2 mr-2">
                <div className="input-group-prepend">
                  <span className="input-group-text fa fa-search" />
                </div>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Search with Phone number"
                  name="value"
                  value={value}
                  onChange={onChange}
                />
              </div>
              <div className="mb-1 mt-2">
                {/* eslint-disable-next-line react/button-has-type */}
                <button className="btn btn-green mr-2" style={{ height: '39px' }}>
                  Search{' '}
                </button>
              </div>
            </form>
          </div>
          <div className="mt-3 d-flex flex-wrap col-lg-12 col-md-12 col-sm-12">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                searchWithDateAndTimeRange(dateTime);
              }}
              className="form-inline mt-4 d-flex"
            >
              <div className="input-group mt-2 mr-2">
                {/* <div className="input-group-prepend"> */}
                From Time:
                {/* </div> */}
                <input
                  type="time"
                  name="fromtime"
                  value={fromtime}
                  onChange={onDateTimeChange}
                  min="00:00"
                  max="23:00"
                />
              </div>
              <div className="input-group mt-2 mr-2">
                To Time:
                <input
                  type="time"
                  id="appt"
                  min="00:00"
                  name="totime"
                  value={totime}
                  onChange={onDateTimeChange}
                  max="23:00"
                />
              </div>
              <div className="input-group mt-2 mr-2">
                From Date:
                <input type="date" id="appt" name="fromdate" value={fromdate} onChange={onDateTimeChange} />
              </div>
              <div className="input-group mt-2 mr-2">
                To Date:
                <input type="date" name="todate" value={todate} onChange={onDateTimeChange} />
              </div>

              <div className="mb-1 mt-2">
                <button className="btn btn-green mr-2" style={{ height: '39px' }} disabled={!submitForm()}>
                  Search With Time and Date
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="card x-panel mt-3">
          <div className="table-responsive content col-lg-12 col-md-12 col-sm-12">
            <table className="table col-lg-12 col-md-12 col-sm-12">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Customer Number</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              {loading ? <small>loading</small> : <RetailerSmsTable data={sms} />}
            </table>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default RetailerSms;
