import React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddDowntimeModal from './DowntimeModal';
import { get_all_downtime_hours } from '../../../../../actions/coo-statistics/DowntimeLogger';
import { TizetiGIFLogoTable } from '../../../../../utils/loader/loadingHelper';
import FilterPopover from './components/FilterPopover';
import ActionIcon from './icons/ActionIcon';
import Tooltip from '@mui/material/Tooltip';
import ViewMoreIcon from './icons/ViewMoreIcon';
import FullScreenDialog from './components/FullScreenDialogue';
import { FormatDate } from './components/FilterPopover';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F4F6F8',
    color: '#333333',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function DowntimeTables({ todayDate }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [zone, setZone] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [selectDate, setSelectDate] = React.useState(FormatDate(todayDate));
  const [openAddDowntimeModal, setOpenAddDowntimeModal] = React.useState(false);
  const [openEditDowntimeModal, setOpenEditDowntimeModal] = React.useState(false);
  const [openFullScreenDialog, setOpenFullScreenDialog] = React.useState(false);
  const [clickedIndex, setClickedIndex] = React.useState(null);
  const dateString = new Date(todayDate);
  const now = new Date().toISOString().split('T')[0];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const getAllZones = async () => {
    try {
      setIsLoading(true);
      const zones = await get_all_downtime_hours({ from: todayDate, to: todayDate }, todayDate);
      setZone(zones);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    getAllZones();
  }, []);

  function createData(name, date, down_hours, reason, action) {
    return { name, date, down_hours, reason, action };
  }

  const extractDate = (value) => {
    if (
      value.bstation_downtimes !== undefined &&
      value.bstation_downtimes.length > 0 &&
      Array.isArray(value.bstation_downtimes)
    ) {
      const date = value.bstation_downtimes[value.bstation_downtimes.length - 1].date;
      const convertDate = new Date(date).toDateString();
      return convertDate;
    } else return new Date(selectDate).toDateString();
  };
  const extractDownHours = (value) => {
    if (
      value.bstation_downtimes !== undefined &&
      value.bstation_downtimes.length > 0 &&
      Array.isArray(value.bstation_downtimes)
    ) {
      const downHours = value.bstation_downtimes[value.bstation_downtimes.length - 1].down_hours;
      return downHours;
    } else return '0 hours';
  };
  const extractReason = (value) => {
    if (
      value.bstation_downtimes !== undefined &&
      value.bstation_downtimes.length > 0 &&
      Array.isArray(value.bstation_downtimes)
    ) {
      const reason = value.bstation_downtimes[value.bstation_downtimes.length - 1].reason;
      return reason;
    } else return 'no reason recorded';
  };

  function rowsDataFunction() {
    if (isLoading === false && zone.length > 0) {
      const result = zone.map((val) => {
        return createData(val.name, extractDate(val), extractDownHours(val), extractReason(val), ActionIcon());
      });
      return result;
    }
  }

  const memoizedRowsData = React.useMemo(() => rowsDataFunction(), [zone, isLoading]);

  const handleClickOpen = (row) => {
    if (row.date === 'no downtime recorded' && row.down_hours === 'no downtime recorded') {
      setOpenEditDowntimeModal(false);
      setOpenAddDowntimeModal(true);
    } else {
      setOpenAddDowntimeModal(false);
      setOpenEditDowntimeModal(true);
    }
  };

  const handleEditOrPostAction = (row) => {
    if (row.date === 'no downtime recorded' && row.down_hours === 'no downtime recorded') {
      return 'Add downtime';
    } else return 'Edit downtime';
  };

  return (
    <Box sx={{ mt: 7 }}>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} sx={{ mb: 3 }}>
        <Box>
          <Typography variant="h5">Zones</Typography>
          <Typography>
            {memoizedRowsData !== undefined && memoizedRowsData.length > 0 ? memoizedRowsData.length : '...'} Zones
          </Typography>
        </Box>
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gap={'1rem'}>
          <FilterPopover
            todayDate={todayDate}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setZone={setZone}
            selectDate={selectDate}
            setSelectDate={setSelectDate}
          />
          <Button
            variant="contained"
            sx={{ background: '#828282', textTransform: 'capitalize', ml: 2 }}
            onClick={getAllZones}
          >
            {' '}
            Reload
          </Button>
        </Box>
      </Box>

      <AddDowntimeModal
        open={openAddDowntimeModal}
        setOpen={setOpenAddDowntimeModal}
        openEditDowntimeModal={openEditDowntimeModal}
        setOpenEditDowntimeModal={setOpenEditDowntimeModal}
        zoneClicked={zone[clickedIndex] || { id: '', name: '', bstation_downtimes: [] }}
        todayDate={todayDate}
        getAllZones={getAllZones}
      />

      <FullScreenDialog
        openFullScreenDialog={openFullScreenDialog}
        setOpenFullScreenDialog={setOpenFullScreenDialog}
        zoneClicked={zone[clickedIndex]}
      />

      {isLoading ? (
        <TizetiGIFLogoTable />
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ fontWeight: 700 }}>Asset Zone</StyledTableCell>
                <StyledTableCell align="right" sx={{ fontWeight: 700 }}>
                  Date
                </StyledTableCell>
                <StyledTableCell align="right" sx={{ fontWeight: 700 }}>
                  Down hours&nbsp;
                </StyledTableCell>
                <StyledTableCell align="right" sx={{ fontWeight: 700 }}>
                  Reason&nbsp;
                </StyledTableCell>
                <StyledTableCell align="right" sx={{ fontWeight: 700 }}>
                  Actions&nbsp;
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {memoizedRowsData !== undefined &&
                memoizedRowsData.length > 0 &&
                memoizedRowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell component="th" scope="row">
                      {row.name}
                    </StyledTableCell>
                    <StyledTableCell align="right">{row.date}</StyledTableCell>
                    <StyledTableCell align="right">
                      {isNaN(row.down_hours) ? row.down_hours : row.down_hours + ' hours'}{' '}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.reason.length > 40 ? row.reason.substring(0, 40) + ' ... ' : row.reason}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Box
                        component="div"
                        display={'flex'}
                        gap={'0.5rem'}
                        justifyContent={'flex-end'}
                        alignItems={'flex-end'}
                      >
                        {FormatDate(selectDate) === now && (
                          <Tooltip title={handleEditOrPostAction(row)}>
                            <div
                              onClick={() => {
                                handleClickOpen(row);
                                setClickedIndex(index);
                              }}
                            >
                              {row.action}
                            </div>
                          </Tooltip>
                        )}
                        <Tooltip title="View more">
                          <div
                            onClick={() => {
                              setOpenFullScreenDialog(true);
                              setClickedIndex(index);
                            }}
                          >
                            <ViewMoreIcon />
                          </div>
                        </Tooltip>
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Box sx={{ mt: 7 }}>
        <Button
          onClick={() => {
            handleChangePage('event', page - 1);
          }}
          variant="outlined"
          disabled={page === 0}
        >
          Previous
        </Button>
        <Button
          onClick={() => {
            handleChangePage('event', page + 1);
          }}
          variant="outlined"
          disabled={page >= Math.ceil(memoizedRowsData !== undefined && memoizedRowsData.length / rowsPerPage) - 1}
          sx={{ ml: 2 }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}
