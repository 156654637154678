/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import PaystackTransactionsBody from '../../../shared/paystack-transactions/PaystackTransactionsBody';
import CustomerSupportNavBar from '../../../../layouts/customer-support/CustomerSupportNavBar';
import { useUser } from '../../../../../hooks/shared/user/useUser';

const PaystackTransactions = () => {
  const { profile, user } = useUser();
  const { firstName } = profile;
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <CustomerSupportNavBar currentPage="Paystack Failed Transactions" roleID={user.role_id} position={firstName} />
        <PaystackTransactionsBody />
      </main>
    </div>
  );
};

export default PaystackTransactions;
