/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { ACCOUNTS, PROCUREMENT, SUPER_ADMIN } from '../../../../../../utils/departments/helpers';
import { useUser } from '../../../../../../hooks/shared/user/useUser';
import AccountsNavBar from '../../../../../layouts/accounts/AccountsNavBar';
import PurchaseOrderBody from '../../../../shared/purchase-order/PurchaseOrderBody';
import useCheckTwoDepartments from '../../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';

const CFOProcurementPurchaseOrder = ({ history }) => {
  useCheckTwoDepartments(`${ACCOUNTS}`, `${SUPER_ADMIN}`, history);
  const {
    profile: { firstName },
  } = useUser();

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <span className="d-none d-md-block">
          <AccountsNavBar currentPage="Procurement Purchase Order" position={firstName} />
        </span>
        <span className="d-md-none d-block">
          <AccountsNavBar currentPage="Procurement PO" position={firstName} />
        </span>
        <PurchaseOrderBody deptID={PROCUREMENT} />
      </main>
    </div>
  );
};

CFOProcurementPurchaseOrder.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default CFOProcurementPurchaseOrder;
