import { useEffect } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import { OPERATIONS, SUPER_ADMIN, TECHNICAL_SUPPORT } from '../../../../../utils/departments/helpers';

const useCheckDepartment = (history) => {
  useEffect(() => {
    const checkDepartment = async () => {
      try {
        const r = await axios.get(`${LOGGED_IN_USER_URL}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });
        if (
          r.data.department_id !== `${OPERATIONS}` &&
          r.data.department_id !== `${TECHNICAL_SUPPORT}` &&
          r.data.department_id !== `${SUPER_ADMIN}`
        ) {
          sessionStorage.clear();
          localStorage.clear();
          localStorage.setItem('token', '');
          sessionStorage.setItem('token', '');
          sessionStorage.setItem('email', '');
          sessionStorage.setItem('departmentId', '');
          sessionStorage.setItem('roleId', '');
          history.push('/login');
          toastr.info('You are not allowed to view this page');
        }
      } catch (e) {
        toastr.info('Error getting data');
      }
    };
    checkDepartment();
  }, [history]);
};
export default useCheckDepartment;
