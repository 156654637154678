/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import Moment from 'react-moment';
import PropTypes from 'prop-types';
import edit from '../../../../assets/img/icons/edit.png';
import view from '../../../../assets/img/icons/view.png';
import UpdateTracklistCategory from './UpdateTracklistCategory';
import ViewTracklistCategory from './ViewTracklistCategory';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../utils/loader/loadingHelper';
import { useTracklistCategory } from './hooks/useTracklistCategory';

const TracklistCategoryTable = ({ lists, load }) => {
  const {
    tracklistPerCategory,
    pagesPerCategory,
    countPerCategory,
    isFetching,
    searchPerCategory,
  } = useTracklistCategory();
  const colSpan = '6';
  const [one, setOne] = useState({});

  const getItem = (list) => setOne(list);

  const searchCategory = (list) => {
    const { id, name } = list;
    const data = { name };
    searchPerCategory(id, data);
  };

  const tableData =
    lists.length && lists ? (
      lists.map((list, i) => {
        const { available_quantity, created_at, updated_at, name } = list;
        return (
          <tr className="text-nowrap" key={i}>
            <td>{i + 1}</td>
            <td>{name}</td>
            {/* <td className="">{available_quantity || 0}</td> */}
            <td>
              <Moment format="DD-MM-YYYY">{created_at}</Moment>
            </td>
            <td>
              <Moment format="DD-MM-YYYY">{updated_at}</Moment>
            </td>
            <td>
              <div className="">
                <button
                  type="button"
                  className="mr-2"
                  data-toggle="modal"
                  data-placement="right"
                  data-target="#update_category"
                  onClick={() => getItem(list)}
                >
                  <span className="view">
                    <img src={edit} data-toggle="tooltip" alt="" data-placement="top" title="Edit" />
                  </span>
                </button>
                <button
                  type="button"
                  className="mr-2"
                  data-toggle="modal"
                  data-placement="right"
                  data-target="#perCategory"
                  onClick={() => searchCategory(list)}
                >
                  <span className="view">
                    <img src={view} data-toggle="tooltip" alt="" data-placement="top" title="View" />
                  </span>
                </button>
              </div>
              <UpdateTracklistCategory oneItem={one} />
              <ViewTracklistCategory
                tracklists={tracklistPerCategory}
                pages={pagesPerCategory}
                count={countPerCategory}
                isFetching={isFetching}
              />
            </td>
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan={colSpan} text="No Category" />
    );

  return (
    <div className="card x-panel mt-2 px-0">
      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Item Name</th>
              {/* <th>Quantity</th> */}
              <th>Created Date</th>
              <th>Updated Date</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>{load ? <TizetiGIFLogoTable colSpan={colSpan} /> : tableData}</tbody>
        </table>
      </div>
    </div>
  );
};

TracklistCategoryTable.propTypes = {
  load: PropTypes.bool.isRequired,
  lists: PropTypes.arrayOf.isRequired,
};

export default TracklistCategoryTable;
