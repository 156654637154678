import React from 'react';
import AppContext from '../../../../../../context/shared/AppContext';
import useCleanRadio from '../hooks/useCleanRadio';

const CleanRadioProvider = ({ children }) => {
  const {
    cleanRadiosData,
    fetching,
    pages,
    onPaginate,
    onOpenModal,
    showCleanModal,
    cleanRadio,
    cleaning,
  } = useCleanRadio();
  return (
    <AppContext.Provider
      value={{ cleanRadiosData, fetching, pages, onPaginate, onOpenModal, showCleanModal, cleanRadio, cleaning }}
    >
      {children}
    </AppContext.Provider>
  );
};
export default CleanRadioProvider;
