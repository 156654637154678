/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import edit from '../../../../../assets/img/icons/edit.png';
import { NoItemRow } from '../../../../../utils/loader/loadingHelper';
import BusinessDevelopmentConnectedCustomers from './BusinessDevelopmentConnectedCustomers';

const BusinessDevelopmentBasestationStatusTable = ({ items, isLoading }) => {
  const colSpan = '8';
  const [request, setRequest] = useState({
    zone: '',
    basestation: '',
    status: '',
    country: '',
    no_of_dedicated_users: '',
  });
  const getRequest = (item) => setRequest(item);

  const tableData =
    items && items.length ? (
      items.map((item, i) => {
        return (
          <tr className="text-nowrap" key={i}>
            <td>{i + 1}</td>
            <td>{item.zone}</td>
            <td>{item.basestation}</td>
            <td>{item.status}</td>
            <td> {item.no_of_dedicated_users}</td>
            <td>{item.country}</td>
            <td>
              <div className="d-flex">
                <button
                  type="submit"
                  className="mx-1 noBorder"
                  data-toggle="modal"
                  data-placement="left"
                  data-target="#updateDedicated"
                  onClick={() => getRequest(item)}
                >
                  <span className="view">
                    <img src={edit} alt="" data-toggle="tooltip" data-placement="left" title="Edit" />
                  </span>
                </button>
              </div>
            </td>
            <BusinessDevelopmentConnectedCustomers request={request} />
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan={colSpan} text="There are no records" />
    );
  return (
    <div className="card x-panel mt-2 px-0">
      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <thead>
            <tr className="">
              <th>#</th>
              <th>Zone</th>
              <th>Basestation</th>
              <th>Status</th>
              <th>Number of Users</th>
              <th>Country</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td className="text-center">
                  <img src={loader} alt="loading gif" />
                </td>
              </tr>
            ) : (
              tableData
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BusinessDevelopmentBasestationStatusTable;
