import React from 'react';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import ExpressWifiNavBar from '../../../../layouts/expresswifi/ExpressWifiNavBar';
import CustomerSupportCustomersTable from '../../customer-support/customers/CustomerSupportCustomersTable';

const ExpressWifiHotspotCustomers = () => {
  const { profile } = useUser();
  const { firstName } = profile;
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <ExpressWifiNavBar currentPage="Hotspot Customers" position={firstName} />
        <div className="pt-5">
          <CustomerSupportCustomersTable />
        </div>
      </main>
    </div>
  );
};

export default ExpressWifiHotspotCustomers;
