/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Logout from '../../../shared/auth/Logout';
import CustomerSupportSideBar from '../../../../layouts/customer-support/CustomerSupportSideBar';
import { API_URL } from '../../../../../utils/config';
import NetPromoter from '../../../shared/nps/net-promoter-score/NetPromoter';
import mailblast from '../../../../../assets/img/icons/mailblast.svg';

class CustomerSupportPromoterScore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      role: '',
    };
  }

  componentDidMount() {
    this.getUserDetails();
  }

  getUserDetails() {
    axios({
      method: 'get',
      url: `${API_URL}/employee/loggedOnEmployee`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    }).then((res) => {
      this.setState({
        firstName: res.data.firstName,
      });
    });
  }

  render() {
    const { firstName, role } = this.state;
    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <CustomerSupportSideBar role={role} />

              <h3 className="col-lg-5 overview-part text-wrap">Net Promoter Score</h3>

              <div className="ml-4 d-flex profile-mail">
                <Link
                  to="/customer-support/mail-blast"
                  className="d-none d-md-flex"
                  style={{
                    color: 'gray',
                    textDecoration: 'none',
                  }}
                >
                  <img src={mailblast} alt="" className="logout mr-2" />
                  <span>
                    <h6 className="m-2">
                      <strong>MailBlast</strong>
                    </h6>
                  </span>
                </Link>

                <h6 className="mr-0 d-flex">
                  <i className="small mr-2 d-none d-md-block">{firstName}</i>
                  <Logout />
                </h6>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <NetPromoter />
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default CustomerSupportPromoterScore;
