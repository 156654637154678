import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from '../../../../../../assets/img/icons/TizetiLoaderColored.gif';
import LteTableData from './LteTableData';
import useCustomers from '../../../../../../hooks/asset-team/customers/useCustomers';
import Download from '../../../Download';

const LteTable = () => {
  const {
    loading,
    expiredLteCustomers,
    getCurrentExpiredLteCustomer,
    currentExpiredLteCustomers,
    currentPage,
  } = useCustomers();
  const displayData = () => {
    if (loading) {
      return <img src={Loader} alt="" />;
    }
    return <LteTableData data={currentExpiredLteCustomers} />;
  };
  const headers = [
    { label: 'Name', key: 'customer_name' },
    { label: 'Phone', key: 'customer_mobile' },
    { label: 'Email', key: 'customer_email' },
    { label: 'Called Station', key: 'calledstation' },
    { label: 'Country', key: 'country' },
    { label: 'Zone', key: 'zone' },
    { label: 'Region', key: 'region' },
    { label: 'Agent', key: 'agent' },
    { label: 'Expired Date', key: 'expired_date' },
    { label: 'Installed Date', key: 'installation_date' },
  ];
  return (
    <div>
      {expiredLteCustomers.length > 0 && (
        <Download
          classname="btn btn-green shadow-md"
          textcolor="white"
          data={expiredLteCustomers}
          filename="Expired LTE Customers.csv"
          headers={headers}
          text="Download Expired LTE Customers"
        />
      )}
      <InfiniteScroll
        dataLength={currentExpiredLteCustomers.length}
        next={() => getCurrentExpiredLteCustomer(currentPage + 1)}
        hasMore={!(currentExpiredLteCustomers.length >= expiredLteCustomers.length)}
        loader={null}
        endMessage={
          !loading && (
            <p style={{ textAlign: 'center' }}>
              <b>Thats all!</b>
            </p>
          )
        }
      >
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Called Station</th>
              <th>Country</th>
              <th>Zone</th>
              <th>Region</th>
              <th>Agent</th>
              <th>Expired Date</th>
              <th>Installed Date</th>
            </tr>
          </thead>
          {displayData()}
        </table>
      </InfiniteScroll>
    </div>
  );
};

export default LteTable;
