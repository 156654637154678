import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getTotalRevenue } from '../../../../../../../actions/hotspot/HotspotActions';

class TotalRevenue extends Component {
  componentDidMount() {
    this.props.getTotalRevenue();
  }

  static propTypes = {
    getTotalRevenue: PropTypes.func.isRequired,
    revenue: PropTypes.object.isRequired,
    load: PropTypes.bool.isRequired,
  };

  render() {
    const { revenue, load } = this.props;

    return (
      <div className="col-lg-3 col-md-3 col-sm-4">
        <div className="boxes border-box2">
          <div className="d-flex mt-3">
            <div className="mt-4 ml-3 mr-2">
              <img
                src="https://res.cloudinary.com/teewhy/image/upload/v1547546149/hotspotadminmoney.fw.png"
                alt="money-icon"
                className="w-75 mt-3"
              />
            </div>
            <div className="mt-4">
              {/* <div className="count"><span className="saving"> {this.state.totalRevenue.toLocaleString()}</span></div> */}
              <div className="count">
                <span className="saving">
                  {' '}
                  {load ? (
                    <small>loading</small>
                  ) : (
                    revenue.actualcashreceived.toLocaleString()
                  )}
                </span>
              </div>

              <h6 className="baby-fonts">Total Revenue</h6>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  revenue: state.hotspotRevenue.revenue,
  load: state.loadHotspotRevenue.loadHotspotRevenue,
});

export default connect(mapStateToProps, {
  getTotalRevenue,
})(TotalRevenue);
