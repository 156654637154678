/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/require-default-props */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Budget from '../../../shared/budget/Budget';
import Logout from '../../../shared/auth/Logout';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import SalesSideBar from '../../../../layouts/sales/SalesSideBar';
import { SALES } from '../../../../../utils/config';
import PageName from '../../../shared/layouts/PageName';
import { SUPER_ADMIN } from '../../../../../utils/departments/helpers';
import useCheckTwoDepartments from '../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';

const SalesBudget = ({ history }) => {
  const { profile, user } = useUser();
  const { firstName } = profile;
  const { role_id } = user;
  useCheckTwoDepartments(`${SALES}`, `${SUPER_ADMIN}`, history);
  return (
    <div>
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <Link className="s-sidebar__trigger" to="#">
                <i className="fa fa-bars mr-3" />
              </Link>
              <SalesSideBar role={role_id} />

              <PageName name="Budget" />

              <div className="d-flex profile-mail">
                <h6>
                  <i className="mr-3">{firstName}</i>
                  <Logout />
                </h6>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row mt-5">
              <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-4">
                <Budget />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

SalesBudget.propTypes = {
  history: PropTypes.shape(),
};
export default SalesBudget;
