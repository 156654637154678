import React, { useContext } from 'react';
// import SearchNocSheets from '../SearchNocSheets';
import SectorAllocationTable from './SectorAllocationTable';
import AddSectorAllocation from './AddSectorAllocation';
import PaginatePage from '../common/PaginatePage';
import SectorAllocationContext from './context/SectorAllocationContext';

const SectorAllocationBody = () => {
  const { loading, pages, paginateChange } = useContext(SectorAllocationContext);
  return (
    <div className="container">
      <div className="row mt-5">
        {/* <SearchCnMaestro /> */}
        <div className="form-group ml-2">
          <button className="btn btn-green" type="button" data-toggle="modal" data-target="#add-access-point">
            Add Facebook Site
          </button>
        </div>
        <AddSectorAllocation />
        <SectorAllocationTable />
        {!loading && <PaginatePage pages={pages} paginateChange={paginateChange} />}
      </div>
    </div>
  );
};

export default SectorAllocationBody;
