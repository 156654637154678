import {
  ADD_FEATURE_REQUEST,
  GET_FEATURE_REQUESTS,
  GET_FEATURES_REQUESTS_BY_DEPARTMENT, START_FETCHING_FEATURE_REQUESTS, STOP_FETCHING_FEATURE_REQUESTS
} from "../../../constants/types/research-and-development/feature-request/FeatureRequestTypes";

const initialState = {

  features: [],
  load: false,
  featuresByDepartment: [],
  loading: false,
  addFeature: ''

};

export const FeatureRequestReducer = (state=initialState, action) => {

  const {type, payload} = action;
  switch (type) {
    case GET_FEATURE_REQUESTS:
      return {

        ...state,
        features: payload,
        load: false

      };

    case START_FETCHING_FEATURE_REQUESTS:
      return {

        ...state,
        load: payload

      };

    case STOP_FETCHING_FEATURE_REQUESTS:
      return {

        ...state,
        load: payload

      };

    case GET_FEATURES_REQUESTS_BY_DEPARTMENT:
      return {

        ...state,
        featuresByDepartment: payload,
        loading: false

      };

    case ADD_FEATURE_REQUEST:
      return {

        addFeature: action.payload

      };

    default:
      return state;

  }

};

export default FeatureRequestReducer;