import { GET_LOGS } from '../../../constants/types/shared/logs/LogsTypes';
import { FETCHING_STARTS, FETCHING_STOPS } from '../../../constants/types/shared/LoadingTypes';

const initialState = {
  logs: [],
  load: false,
};

export const LogsReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case FETCHING_STARTS:
      return {
        ...state,
        load: payload,
      };

    case FETCHING_STOPS:
      return {
        ...state,
        load: payload,
      };

    case GET_LOGS:
      return {
        ...state,
        logs: payload,
        load: false,
      };

    default:
      return state;
  }
};

export default LogsReducer;
