import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import ChartTypeSelect from '../components/ChartTypeSelect';
import LineCharts from './LineCharts';
import BarCharts from './BarCharts';
import AreaCharts from './AreaChart';

export default function ChartsContainer({ data, isLoading }) {
  const [chartType, setChartType] = React.useState('Bar-Chart');

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flexStart" sx={{ mt: 10 }}>
      {isLoading ? (
        <>
          <Skeleton width={300} height={100} />
        </>
      ) : (
        <ChartTypeSelect setChartType={setChartType} chartType={chartType} />
      )}

      {chartType === 'Line-Chart' ? (
        <LineCharts data={data} isLoading={isLoading} />
      ) : chartType === 'Bar-Chart' ? (
        <BarCharts data={data} isLoading={isLoading} />
      ) : (
        chartType === 'Area-Chart' && <AreaCharts data={data} isLoading={isLoading} />
      )}
    </Box>
  );
}
