import { GET_NO_LOS } from '../../../constants/types/shared/no-los/NoLosTypes';
import { LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';

// eslint-disable-next-line import/prefer-default-export
export const noLosReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_NO_LOS:
      return {
        ...state,
        noLos: payload.data,
        loading: false,
      };
    case LOADING_STOPS:
      return { ...state, loading: false };
    default:
      return state;
  }
};
