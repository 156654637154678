import React from 'react';
// import { RouteComponentProps } from 'react-router-dom';
import { CUSTOMER_SUPPORT, ASSET_TEAM } from '../../../../utils/departments/helpers';
import CustomerSupportSideBarItem from '../../../layouts/customer-support/sidebar/CustomerSupportSideBarItem';
import { useUser } from '../../../../hooks/shared/user/useUser';
import AssetTeamNavBar from '../../../layouts/asset-team/AssetTeamNavBar';
import CustomerSupportNavBar from '../../../layouts/customer-support/CustomerSupportNavBar';
import RelocationBody from '../relocation/RelocationBody';
import useCheckTwoDepartments from '../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';
const RelocationShared = ({ history }) => {
  useCheckTwoDepartments(`${CUSTOMER_SUPPORT}`, `${ASSET_TEAM}`, history);
  const {
    user: { role_id },
    profile: { firstName },
  } = useUser();

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        {sessionStorage.getItem('departmentId') === CUSTOMER_SUPPORT ? (
          <CustomerSupportNavBar currentPage="Relocation" roleID={role_id} position={firstName} />
        ) : (
          sessionStorage.getItem('departmentId') === ASSET_TEAM && (
            <AssetTeamNavBar currentPage="Relocation" position={firstName} />
          )
        )}
        <RelocationBody />
      </main>
    </div>
  );
};

export default RelocationShared;
