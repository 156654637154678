import axios from 'axios';
import { toast } from 'react-toastify';
import { GET_BASESTATIONS_URL } from '../../../services/api/shared/installations/InstallationsUrl';

export const edit_basestation = async (id, data) => {
  const res = await axios.put(`${GET_BASESTATIONS_URL}/${id}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
  });

  try {
    if (res.data) {
      // toast.success('Basestation successfully updated');
      setTimeout(() => {
        window.location.reload();
      }, 3000);
      return res.data;
    }
  } catch (error) {
    toast.error(error.message);
  }
};
