/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import { useCustomer } from '../../../../../hooks/shared/customers/useCustomer';
import { showDepartmentName } from '../../../../../utils/departments/helpers';

const CleanRadio = ({ username, one, searchForUserReload, option, search, show, onClose }) => {
  const { cleanRetrievedDevice, cleaning, cleanRadio } = useCustomer();

  // const [clean] = useState('');

  // const onChange = (e) => {
  //   setClean(e.target.value);
  // };
  const { profile, user } = useUser();
  const { officialEmail } = profile;

  const submitForm = (e) => {
    e.preventDefault();
    const accountsDecisionData = qs.stringify({
      account_approval: 'approved',
      // account_comment: approvalComment,
    });
    const cleanRadioData = qs.stringify({
      action: 'cleanCustomerDevice',
      staffEmailAddress: officialEmail,
      department: showDepartmentName.get(`${user.department_id}`),
      role_id: Number(sessionStorage.getItem('roleId')),
      macaddress: one ? one.modified_mac : username,
      delisted: 'yes',
    });
    if (one) return cleanRetrievedDevice(one.id, cleanRadioData, accountsDecisionData);
    return cleanRadio(cleanRadioData, searchForUserReload, option, search, onClose);
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title> Clean Radio</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="form-horizontal" onSubmit={submitForm}>
          <div className="form-group">
            <div className="col-12 mb-3 d-flex">
              <input
                type="text"
                className="form-control"
                name="duration"
                value={one ? one.radio_mac_id : username}
                readOnly
              />
              {/* <input type="text" className="form-control" name="clean" value={clean} onChange={onChange} /> */}
            </div>

            {cleaning ? (
              <div className="col-12 mb-3 text-center">
                <button type="button" className="btn btn-green" disabled={cleaning}>
                  Cleaning...
                </button>
              </div>
            ) : (
              <div className="col-12 mb-3 text-center">
                <button type="submit" className="btn btn-green">
                  Clean Radio
                </button>
              </div>
            )}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant="secondary" onClick={onClose}>
          Close
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
};

CleanRadio.propTypes = {
  one: PropTypes.string,
};
export default CleanRadio;
