/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/prefer-default-export */
import { useReducer } from 'react';
import qs from 'qs';
import axios from 'axios';
import toastr from 'toastr';
import * as load from '../../../constants/types/shared/LoadingTypes';
import { ADD_LTE_USER_URL } from '../../../services/api/sales/customers/CustomersUrl';
import { LTEReducer } from '../../../reducers/sales/LTE/LTEReducer';

export const useLTE = () => {
  const [{ isLoading }, dispatch] = useReducer(LTEReducer, { isLoading: false });

  const addLTEUser = async (data) => {
    dispatch({ type: load.LOADING_STARTS });
    const config = {
      method: 'post',
      url: `${ADD_LTE_USER_URL}`,
      data: qs.stringify(data),
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response.data.data.id) {
          toastr.success('Added LTE User Successfully');
          dispatch({ type: load.LOADING_STOPS });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch(() => {
        dispatch({ type: load.LOADING_STOPS });
        toastr.info('Something went wrong!');
      });
  };

  return { isLoading, addLTEUser };
};
