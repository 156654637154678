import React from 'react';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import ExpressWifiNavBar from '../../../../layouts/expresswifi/ExpressWifiNavBar';
import ExpressWifiAmbassadorsSignUpsBody from './ExpressWifiAmbassadorsSignUpsBody';

const ExpressWifiAmbassadorsSignUps = () => {
  const { profile } = useUser();
  const { firstName } = profile;

  const from_date = new Date(); // get today date
  from_date.setDate(from_date.getDate() - 7); // add 7 days
  const final_from_Date = `${from_date.getFullYear()}-${
    from_date.getMonth() + 1 < 10 ? '0' : ''
  }${from_date.getMonth() + 1}-${from_date.getDate()}`;

  const to_date = new Date(); // get today date
  to_date.setDate(to_date.getDate() + 1); // add 1 days
  const final_to_Date = `${to_date.getFullYear()}-${to_date.getMonth() + 1 < 10 ? '0' : ''}${to_date.getMonth() +
    1}-${to_date.getDate()}`;

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <ExpressWifiNavBar currentPage="Ambassadors SignUps Count" position={firstName} />
        <ExpressWifiAmbassadorsSignUpsBody from_date={final_from_Date} to_date={final_to_Date} />
      </main>
    </div>
  );
};

export default ExpressWifiAmbassadorsSignUps;
