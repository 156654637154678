import React from 'react';
import { NoItemRow } from '../../../../../utils/loader/loadingHelper';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import SalesWifiEventEditModal from './SalesWifiEventEditModal';
import edit from '../../../../../assets/img/icons/edit.png';

const SalesWifiEventTable = ({ items, isLoading, query }) => {
  const colSpan = '8';

  const tableData =
    items && items.length ? (
      items
        .filter((item, _) => {
          if (query === '') {
            return item;
          }
          if (item.status.toLowerCase().includes(query.toLowerCase())) {
            return item;
          }
        })
        .map((item, i) => {
          return (
            <tr className="text-nowrap" key={i}>
              <td>{i + 1}</td>
              <td>{item.eventName ? item.eventName : 'N/A'}</td>
              <td>{item.phoneNumber ? item.phoneNumber : 'N/A'}</td>
              <td>{item.email ? item.email : 'N/A'}</td>
              <td>{item.eventAddress ? item.eventAddress : 'N/A'}</td>
              <td>{item.attendanceDensity ? item.attendanceDensity : 'N/A'}</td>
              <td>{item.eventDate ? item.eventDate : 'N/A'}</td>
              <td>{item.comments ? item.comments : 'N/A'}</td>
              <td>{item.amount ? item.amount : 'N/A'}</td>
              <td>{item.status ? item.status : 'N/A'}</td>
              <td>
                <div className="d-flex">
                  <button
                    type="button"
                    className="mx-1 noBorder"
                    data-toggle="modal"
                    data-placement="left"
                    data-target="#editEventStatus"
                  >
                    <span className="view">
                      <img src={edit} alt="" data-toggle="tooltip" data-placement="left" title="Edit" />
                    </span>
                  </button>
                </div>
              </td>
              <SalesWifiEventEditModal id={item.id} />
            </tr>
          );
        })
    ) : (
      <NoItemRow colSpan={colSpan} text="There are no records" />
    );
  return (
    <div className="card x-panel mt-2 px-0">
      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Event Name</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Event Address</th>
              <th>Attendance Density</th>
              <th>Event date</th>
              <th>Comments</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td className="text-center">
                  <img src={loader} alt="loading gif" />
                </td>
              </tr>
            ) : (
              tableData
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SalesWifiEventTable;
