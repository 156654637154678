import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularWithValueLabel from './LoadingProgressSpinner';
// import { TableCellMonths } from '../components/tempData';
export default function LineCharts({
  getZones,
  state,
  sumOfActiveAndInactiveUsers,
  getAllActiveUsersCountValue,
  getAllInActiveUsersCountValue,
}) {
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    if (
      getZones.length > 0 &&
      sumOfActiveAndInactiveUsers.length &&
      getAllActiveUsersCountValue.length > 0 &&
      getAllInActiveUsersCountValue.length > 0
    ) {
      const refinedData = getZones.map((zone, index) => ({
        name: zone.name,
        'Active & Expired / Inactive Users': sumOfActiveAndInactiveUsers[index] && sumOfActiveAndInactiveUsers[index],
        Active: getAllActiveUsersCountValue[index] && getAllActiveUsersCountValue[index],
        'Expired / Inactive Users': getAllInActiveUsersCountValue[index] && getAllInActiveUsersCountValue[index],
      }));

      if (refinedData.length > 0) {
        const filterNonZeroValues = refinedData.filter((val) => val.Active > 0);
        setData(filterNonZeroValues);
      }
    }
  }, [sumOfActiveAndInactiveUsers.length]);

  return (
    <Box>
      {sumOfActiveAndInactiveUsers.length < 1 ? (
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 3 }} height={250}>
          <CircularWithValueLabel />
          <Typography>Loading Graph...</Typography>
        </Box>
      ) : (
        <Box sx={{ width: { xs: '90vw', md: 1000 }, overflowX: 'auto' }}>
          <Box sx={{ width: '100%', overflowX: 'scroll' }}>
            {/* <ResponsiveContainer width="100%" height={500}> */}
            <LineChart data={data} width={4200} height={550}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" type="category" scale="band" />
              <YAxis />
              <Tooltip />
              <Legend />

              {state.activeInactive && (
                <Line
                  type="monotone"
                  dataKey="Active & Expired / Inactive Users"
                  fill="#413ea0"
                  stroke="#8884d8"
                  strokeWidth={2}
                />
              )}
              {state.active && (
                <Line type="monotone" dataKey="Active" fill="#d8d284" stroke="#d584d8" strokeWidth={2} />
              )}
              {state.inactive && (
                <Line
                  type="monotone"
                  dataKey="Expired / Inactive Users"
                  fill="#ff7300"
                  stroke="#92d884"
                  strokeWidth={2}
                />
              )}
            </LineChart>
            {/* </ResponsiveContainer> */}
          </Box>
        </Box>
      )}
    </Box>
  );
}
