/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

const CreateAccount = () => {
  return (
    <div
      id="lteModal"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myModalLabel">
              Create Customer LTE Account
            </h4>
            <button type="button" className="close custom-close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body detail" style={{ padding: '0', textAlign: 'justify' }}>
            <div className="row">
              <form className="form-horizontal col-12">
                <div className="form-group mt-4">
                  <div className="col-12 d-flex mb-3">
                    <div className="col-6">
                      <label className="copy-font m-0 p-0 text-left">Full Name</label>
                      <input type="text" placeholder="Joe Dean" className="form-control pl-2" />
                    </div>
                    <div className="col-6">
                      <label className="copy-font m-0 p-0 text-left">Phone Number</label>
                      <input type="number" placeholder="xxxx-xxx-xxxx" className="form-control pl-2" />
                    </div>
                  </div>

                  <div className="col-12 d-flex mb-3">
                    <div className="col-6">
                      <label className="copy-font m-0 p-0 text-left">Address</label>
                      <input type="text" className="form-control pl-2" />
                    </div>

                    <div className="col-6">
                      <label className="copy-font m-0 p-0 text-left">State</label>
                      <select className="form-control">
                        <option>Abia</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-12 d-flex mb-3">
                    <div className="col-6">
                      <label className="copy-font m-0 p-0 text-left">IMSI</label>
                      <input type="number" placeholder="Not more than 15 digits" className="form-control pl-2" />
                    </div>

                    <div className="col-6">
                      <label className="copy-font m-0 p-0 text-left">Country</label>
                      <select className="form-control">
                        <option>Nigeria</option>
                        <option>Ghana</option>
                      </select>
                    </div>
                  </div>

                  <div className="text-center">
                    <button className="btn btn-green" type="submit">
                      Create
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;
