import React, { createContext } from 'react';
import useAccessPointConnection from '../hooks/useAccessPointConnection';

export const AccessPointConnectionContext = createContext({});

export const AccessPointConnectionProvider = ({ children }) => {
  const { data, loading, paginateChange, loadingForm, onChange, formValue, onSubmit } = useAccessPointConnection();
  return (
    <AccessPointConnectionContext.Provider
      value={{
        data,
        loading,
        paginateChange,
        loadingForm,
        onChange,
        formValue,
        onSubmit,
      }}
    >
      {children}
    </AccessPointConnectionContext.Provider>
  );
};
