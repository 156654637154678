import React from 'react';
import AssetTeamNavBar from '../../../../layouts/asset-team/AssetTeamNavBar';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import ActivePerCountryBody from '../../../shared/customers/ActivePerCountryBody';

const AssetTeamActivePerCountry = () => {
  const { profile } = useUser();
  const { firstName } = profile;

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <AssetTeamNavBar currentPage="Active Customers Per Country" position={firstName} />
        <ActivePerCountryBody profile={profile} />
      </main>
    </div>
  );
};

export default AssetTeamActivePerCountry;
