/* eslint-disable react/require-default-props */
import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import useDedicated from '../../hooks/sales/dedicated-customers/useDedicated';

export const DedicatedContext = createContext({});
export const DedicatedProvider = ({ children }) => {
  const { dedicatedCustomers, loading, onChange, dedicated, addDedicated, submitting } = useDedicated();

  return (
    <DedicatedContext.Provider
      value={{
        dedicatedCustomers,
        loading,
        onChange,
        dedicated,
        addDedicated,
        submitting,
      }}
    >
      {children}
    </DedicatedContext.Provider>
  );
};

DedicatedProvider.propTypes = {
  children: PropTypes.shape(),
};
