import React from 'react';
import ReactPaginate from 'react-paginate';
import loader from '../../../../../../../assets/img/icons/TizetiLoaderColored.gif';
import { usePaidRequisition } from '../../../../../../../hooks/accounts/paid-requisition/usePaidRequisition';
import PaidRequisitionTable from './PaidRequisitionTable';
import SearchPaidRequisition from './SearchPaidRequisition';

const PaidRequisitionBody = () => {
  const {
    paidRequisitions,
    searchPaidRequisition,
    handlePageClick,
    setPurpose,
    pages,
    isLoading,
  } = usePaidRequisition();

  return (
    <div className="col-12 pt-5">
      <div className="col-12 m-0 p-0 after-summary">
        <div className="row ml-3">
          <SearchPaidRequisition searchPaidRequisition={searchPaidRequisition} setPurpose={setPurpose} />
          <div className="card x-panel m-0 p-0 pt-3">
            <div className="table-responsive content col-12 mt-4">
              <table className="table">
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Name</th>
                    <th>Amount In Figures</th>
                    <th>Amount In Words</th>
                    <th>Purpose</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan="5" className="text-center">
                        <img src={loader} alt="loading gif" />
                      </td>
                    </tr>
                  ) : (
                    <PaidRequisitionTable paidRequisitions={paidRequisitions} />
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {pages > 1 ? (
        <ReactPaginate
          previousLabel="previous"
          nextLabel="next"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={pages}
          marginPagesDisplayed={5}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName="pagination"
          subContainerClassName="pages pagination"
          activeClassName="active"
        />
      ) : null}
    </div>
  );
};

export default PaidRequisitionBody;
