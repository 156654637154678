/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/no-array-index-key */
import React, { useContext } from 'react';
import GeniatechContext from '../../../../../../../context/GeniatechContext';
// import { AccessPointsPerformance } from '../types/StaticAccessPointsProps';
import loader from '../../../../../../../assets/img/icons/TizetiLoaderColored.gif';
import helpers from '../../../../../../../utils/helpers';

const AccessPointsPerformanceResults = () => {
  const { fetching, performanceResults } = useContext(GeniatechContext);
  const tableData =
    performanceResults && performanceResults.length > 0
      ? performanceResults.map((row, i) => (
          <tr className="text-nowrap" key={i + 1}>
            <td>{i + 1}</td>
            <td>
              <input type="checkbox" />
            </td>
            <td>{row.name}</td>
            <td style={{ color: row.status === 'online' ? 'green' : 'red' }}>{row.status}</td>
            <td>{row.connected_sms}</td>
            <td>{row.default_gateway}</td>
            <td>{row.gain}</td>
            <td>{row.gps_sync_state}</td>
            <td>{row.ip_dns}</td>
            <td>{row.ip_wan ? row.ip_wan : 'No IP WAN'}</td>
            <td>{row.lan_mode_status}</td>
            <td>{row.lan_speed_status}</td>
            <td>{row.lan_status}</td>
            <td>{helpers.formatDateAndTime(row.last_sync)}</td>
            <td>{row.mode}</td>
            <td>{row.name}</td>
            <td>{row.netmask}</td>
            <td>{row.network}</td>
            <td>{row.auth_type}</td>
            <td>{row.channel_width}</td>
            <td>{row.dfs_status}</td>
            <td>{row.dl_retransmits_pct}</td>
            <td>{row.frequency}</td>
            <td>{row.mac}</td>
            <td>{row.mode}</td>
            <td>{row.sessions_dropped}</td>
            <td>{row.ssid}</td>
            <td>{row.tdd_ratio}</td>
            <td>{row.tx_power}</td>
            <td>{row.ul_mcs}</td>
            <td>{row.ul_pkts}</td>
            <td>{row.wlan_status}</td>
            <td>{row.reboots}</td>
            <td>{row.session_drops}</td>
            <td>{row.status_time}</td>
            <td>{row.tower}</td>
            <td>{row.type}</td>
          </tr>
        ))
      : 'no access point performance result';
  // const [macs, setMacs] = React.useState([]);
  // const { profile } = useUser();

  // const selectAll = () => {
  //   setMacs(performanceResults.map((p: AccessPointsPerformance) => p.mac));

  //   const data = {
  //     action: 'reboot_access_points',
  //     mac_addresses: macs,
  //     staff_email: profile.email ? profile.email : 'noc@tizeti.com',
  //   };
  //   rebootAccessPoints(data);
  // };
  return (
    <div className="card x-panel mt-2 px-0">
      reboot
      <div className="table-responsive content col-12 px-1">
        reboot
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th className="text-nowrap">
                <input
                  type="checkbox"
                  className="mr-1"
                  // onChange={(e) => selectAll(e.target)}
                />
                Select All
              </th>

              <th>ID</th>
              <th className="text-nowrap">Name</th>
              <th>Mac</th>
              <th>Show Performance</th>
            </tr>
          </thead>
          <tbody>
            {fetching ? (
              <tr>
                <img src={loader} alt="loading gif" />
              </tr>
            ) : (
              tableData
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AccessPointsPerformanceResults;
