/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable no-redeclare */
/* eslint-disable block-scoped-var */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CustomerSupportSideBar from '../../../../layouts/customer-support/CustomerSupportSideBar';
import mailblast from '../../../../../assets/img/icons/mailblast.svg';
import { API_URL } from '../../../../../utils/config';
import UsageHistoryBody from './UsageHistoryBody';
import { getUser } from '../../../../../actions/shared/user/UserActions';
import { getLoggedInUser } from '../../../../../actions/user/UserActions';
import Logout from '../../../shared/auth/Logout';
import CustomerServiceScript from '../../../shared/CustomerServiceScript';
import RetentionScript from '../../../shared/customer-support/RetentionScript';

class CustomerSupportUsageHistory extends Component {
  componentDidMount() {
    this.props.getUser();
  }

  onSubmitt = (e) => {
    e.preventDefault();

    if (this.state.keyword === 'email') {
      this.setState({
        loading: true,
      });

      const data = {
        email: this.state.search,
      };

      axios({
        method: 'post',
        url: `${API_URL}/getUserInformation`,
        data,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      })
        .then((res) => {
          if (res.data === 'customers info could not be retreived') {
            this.setState({
              loading: false,
            });

            toast.info('No user found!');
          } else {
            const responseData = res.data;
            const customData = [];

            for (const i in responseData) {
              customData.push({
                username: responseData[i].username,
                name: responseData[i].name,
                mail: responseData[i].mail,
                address: responseData[i].address,
                UserPassword: responseData[i].value,
                Expiration: responseData[i].value,
              });
            }

            this.setState({
              customers: res.data,
              filteredCustomer: customData,
              loading: false,
            });
          }
        })
        .catch(() => {});
    }

    if (this.state.keyword === 'phone') {
      this.setState({
        loading: true,
      });

      const data = {
        phoneNumber: this.state.search,
      };

      axios({
        method: 'post',
        url: `${API_URL}/getUserInfoWithPhoneorMac`,
        data,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        }
      })
        .then((res) => {
          if (res.data === 'customers info could not be retreived') {
            this.setState({
              loading: false,
            });

            toast.info('No user found!');
          } else {
            const responseData = res.data;
            const customData = [];

            for (const i in responseData) {
              customData.push({
                username: responseData[i].username,
                name: responseData[i].name,
                mail: responseData[i].mail,
                address: responseData[i].address,
                UserPassword: responseData[i].value,
                Expiration: responseData[i].value,
              });
            }

            this.setState({
              customers: res.data,
              filteredCustomer: customData,
              loading: false,
            });
          }
        })
        .catch(() => {});
    }

    if (this.state.keyword === 'name') {
      this.setState({
        loading: true,
      });

      const data = {
        name: this.state.search,
      };

      axios({
        method: 'post',
        url: `${API_URL}/geniatech/getuserbyname`,
        data,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      })
        .then((res) => {
          if (res.data === 'customers info could not be retreived') {
            this.setState({
              loading: false,
            });

            toast.info('No user found!');
          }

          if (res.data) {
            // let customdata = this.removeDuplicated(res.data)

            const responseData = res.data;
            const customData = [];

            for (var i in responseData) {
              customData.push({
                username: responseData[i].username,
                name: responseData[i].name,
                mail: responseData[i].mail,
                address: responseData[i].address,
                UserPassword: responseData[i].value,
                Expiration: responseData[i].value,
              });
            }

            for (var i in res.data) {
              const data = {
                macaddress: res.data[i].username,
              };
            }

            this.setState({
              customers: res.data,
              loading: false,
              filteredCustomer: customData,
            });
          }
        })
        .catch((err) => {});
    }
  };

  render() {
    const { user } = this.props;
    const { firstName } = user;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <CustomerSupportSideBar />

              <h4 className="col-md-5 overview-part">Usage History</h4>

              <div className="ml-5 d-flex profile-mail">
                {Number(`${sessionStorage.getItem('roleId')}`) > 2 ? (
                  <Link
                    to="/customer-support/mail-blast"
                    className="d-none d-md-flex"
                    style={{
                      color: 'gray',
                      textDecoration: 'none',
                    }}
                  >
                    <img src={mailblast} alt="" className="logout mr-2" />
                    <span>
                      <h6 className="m-2">
                        <strong>MailBlast</strong>
                      </h6>
                    </span>
                  </Link>
                ) : null}

                <h6 className="mr-0 d-flex">
                  <i className="small mr-2 d-none d-md-block">{firstName}</i>
                  <Logout />
                </h6>
              </div>
            </div>
          </div>

          <div className="mt-5 p-3">
            <UsageHistoryBody />
          </div>

          <span className="d-none d-md-flex">
            <RetentionScript />
            <CustomerServiceScript />
          </span>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  role: state.auth.auth,
});

CustomerSupportUsageHistory.propTypes = {
  user: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  history: PropTypes.shape(),
};

export default connect(
  mapStateToProps,
  { getUser, getLoggedInUser },
)(CustomerSupportUsageHistory);
