import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUser } from '../../../../../actions/shared/user/UserActions';
import { getLoggedInUser } from '../../../../../actions/user/UserActions';
import Requisitions from '../../../shared/requisition/shared/Requisitions';
import ExpressWifiNavBar from '../../../../layouts/expresswifi/ExpressWifiNavBar';

class ExpressWifiRequisition extends Component {
  componentDidMount() {
    this.props.getUser();
    this.props.getLoggedInUser();
  }

  render() {
    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <ExpressWifiNavBar currentPage="Requisition" position="Admin" />

          <Requisitions />
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  role: state.auth.auth,
});

export default connect(mapStateToProps, { getUser, getLoggedInUser })(ExpressWifiRequisition);
