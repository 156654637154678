/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

const ConnectedCustomersTable = ({ count, basestation }) => {
  return (
    <tbody>
      <tr>
        <td>-</td>
        <td>{basestation}</td>
        <td>{count}</td>
      </tr>
    </tbody>
  );
};

ConnectedCustomersTable.propTypes = {
  // count: PropTypes.number,
  count: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  basestation: PropTypes.string,
};

export default ConnectedCustomersTable;
