/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
/* eslint-disable no-case-declarations */
import React from 'react';
import { ToastContainer } from 'react-toastify';
import { useCustomer } from '../../../../../hooks/shared/customers/useCustomers';
import { displayCountryDropdown } from '../../../../../utils/geniatech/Geniatech';
import ProcurementCustomersTableBody from './ProcurementCustomersTableBody';
import CustomersRouterTagTableBody from './CustomersRouterTagTableBody';
import ProcurementCustomerColonless from './ProcurementCustomerColonless';

const ProcurementCustomersTable = () => {
  const {
    search,
    onSearchChange,
    searchForUserByEmail,
    searchByPhoneOrMac,
    searchByName,
    customers,
    isLoading,
    option,
    onOptionChange,
    country,
    router,
    onCountryChange,
    searchByRouterTag,
    customer,
    searchWithColonlessMac,
  } = useCustomer();
  const searchForUser = (e) => {
    e.preventDefault();
    switch (option) {
      case 'email':
        const emailSearch = {
          email: search.trim(),
        };
        searchForUserByEmail(emailSearch);
        break;
      case 'name':
        const nameSearch = {
          name: search.trim(),
          country,
        };
        searchByName(nameSearch);
        break;
      case 'phone':
        const phoneSearch = {
          phoneNumber: search.trim(),
          macAddress: '12',
        };
        searchByPhoneOrMac(phoneSearch);
        break;
      case 'macid':
        const macidSearch = {
          macAddress: search.trim(),
          phoneNumber: 'N/A',
          macid: search.trim(),
        };
        searchByPhoneOrMac(macidSearch);
        break;
      case 'routertag':
        const routerSearch = {
          router_tag: router.trim(),
          mac_id: search.trim(),
        };
        searchByRouterTag(routerSearch);
        break;
      case 'radiotag':
        const radioSearch = {
          radio_tag: search.trim(),
        };
        searchByRouterTag(radioSearch);
        break;
      case 'colonless':
        const data = { columnless_mac_id: search.trim() };
        searchWithColonlessMac(data);
        break;
      default:
        return null;
    }
  };

  const displayTable = () => {
    if (option === 'routertag' || option === 'radiotag') {
      return <CustomersRouterTagTableBody isLoading={isLoading} customer={customer} />;
    }
    if (option === 'colonless') {
      return <ProcurementCustomerColonless isLoading={isLoading} customers={customers} />;
    }
    return <ProcurementCustomersTableBody isLoading={isLoading} customers={customers} />;
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-11 col-md-12 mt-5">
          <div className="mt-4">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 mt-5 mb-3">
                  <form onSubmit={searchForUser} className="form-inline inner-addon d-flex">
                    <div className="input-group mb-3 mr-3">
                      <select name="keyword" onChange={onOptionChange} className="form-control mr-auto empty mt-1">
                        <option value="">Choose fields to search from</option>
                        <option value="email">Email</option>
                        <option value="name">Name</option>
                        <option value="phone">Phone</option>
                        <option value="macid">MAC ID</option>
                        <option value="routertag">Router Tag</option>
                        <option value="radiotag">Radio Tag</option>
                        <option value="colonless">Colon-less MAC</option>
                      </select>
                    </div>

                    {displayCountryDropdown(option, onCountryChange)}

                    {/* {displayIfTypeRouterTag(option, router, onRouterChange)} */}

                    <div className="input-group mb-3 mr-3">
                      <input
                        className="form-control mr-auto empty pl-5"
                        type="text"
                        placeholder={option === 'routertag' ? 'enter macid to search' : 'search for user'}
                        name="search"
                        value={search}
                        onChange={onSearchChange}
                      />
                      <span className="fa fa-search" />
                    </div>

                    <div className="mb-3">
                      <button className="btn btn-green" style={{ height: '39px' }}>
                        Search
                      </button>
                    </div>
                  </form>
                </div>
                <div className="card x-panel">
                  <div className="table-responsive content">
                    <table className="table copy-font">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>MAC ID</th>
                          <th nowrap="no-wrap">Name</th>
                          <th>Email</th>
                          <th>Show Password</th>
                          <th nowrap="no-wrap">Phone Number</th>
                          {option === 'routertag' || option === 'radiotag' ? (
                            <>
                              <th>Radio Tag</th>
                              <th>Router Tag</th>
                              {Number(sessionStorage.getItem('roleId')) > 1 ? <th className="">Clean Radio</th> : null}
                            </>
                          ) : (
                            <th nowrap="no-wrap">Expiry Date</th>
                          )}
                          <th style={{ paddingLeft: '24px' }}>Actions</th>
                        </tr>
                      </thead>
                      {displayTable()}
                    </table>
                  </div>
                </div>
              </div>
              {/* <RetentionScript/> */}
              <ToastContainer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcurementCustomersTable;
