import { useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  FETCHING_STARTS,
  FETCHING_STOPS,
  LOADING_STARTS,
  LOADING_STOPS,
} from '../../../../../constants/types/shared/LoadingTypes';
import kycReducer from '../../../../../reducers/shared/noc/lte/kyc/kycReducer';
import LTE_KYC_URL from '../../../../../services/api/shared/noc/lte/kyc/KycUrl';
import { GET_LTE_KYC_CUSTOMERS, UPLOAD_LTE_KYC_CUSTOMER } from '../../../../../constants/types/shared/noc/LteTypes';

const useKyc = () => {
  const [imsi, setImsi] = useState('');
  const [files, setFiles] = useState([]);
  const [{ fetching, customers, pages, loading }, dispatch] = useReducer(kycReducer, {
    fetching: false,
    customers: [],
    pages: 0,
    loading: false,
  });

  const getLteKycCustomers = async () => {
    dispatch({
      type: FETCHING_STARTS,
    });
    try {
      const response = await axios.get(`${LTE_KYC_URL.GET_LTE_KYC_SUBSCRIBERS}/1`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.users) {
        dispatch({
          type: GET_LTE_KYC_CUSTOMERS,
          payload: {
            users: response.data.users,
            pages: response.data.pages,
          },
        });
      }
    } catch (e) {
      if (e) {
        dispatch({
          type: FETCHING_STOPS,
        });
      }
    }
  };

  const onFileChange = (e) => {
    // const {file} = e.target
    // this.setState({ files: [...this.state.files, ...e.target.files] })
    setFiles(e.target.files);
  };

  const onChange = (e) => {
    const { value } = e.target;
    setImsi(value);
  };

  const uploadLteKyc = async (f, u, i) => {
    const data = new FormData();
    // eslint-disable-next-line no-plusplus
    for (let x = 0; x < f.length; x++) {
      data.append('file', f[x]);
    }
    data.append('user_id', u);
    data.append('imsi', i);

    dispatch({
      type: LOADING_STARTS,
    });
    try {
      const response = await axios.post(`${LTE_KYC_URL.UPLOAD_LTE_KYC_URL}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.id) {
        toast.success('Kyc Uploaded!');
        setImsi('');
        setFiles([]);
        dispatch({
          type: UPLOAD_LTE_KYC_CUSTOMER,
        });
      } else {
        toast.error('Unable to upload kyc for this user');

        dispatch({
          type: LOADING_STOPS,
        });
      }
    } catch (e) {
      if (e) {
        toast.info('Something went wrong, Please try again!');
        dispatch({
          type: LOADING_STOPS,
        });
      }
    }
  };

  const handlePageClick = async (data) => {
    const pageNumber = data.selected + 1;
    dispatch({
      type: FETCHING_STARTS,
    });
    try {
      const response = await axios.get(`${LTE_KYC_URL.GET_LTE_KYC_SUBSCRIBERS}/${pageNumber}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.users) {
        dispatch({
          type: GET_LTE_KYC_CUSTOMERS,
          payload: {
            users: response.data.users,
            pages: response.data.pages,
          },
        });
      }
    } catch (e) {
      if (e) {
        dispatch({
          type: FETCHING_STOPS,
        });
      }
    }
  };

  useEffect(() => {
    getLteKycCustomers();
  }, []);
  return { fetching, customers, pages, onFileChange, imsi, files, onChange, uploadLteKyc, loading, handlePageClick };
};

export default useKyc;
