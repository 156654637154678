/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useRelocation } from '../../../../hooks/sales/relocation/useRelocation';

const AddRelocation = () => {
  const { isLoading, addRelocationRequest } = useRelocation();
  const [request, setRequest] = useState({
    f_name: '',
    l_name: '',
    e_mail: '',
    phone: '',
    o_address: '',
    n_address: '',
    paymentREF: '',
  });
  const { f_name, l_name, e_mail, phone, o_address, n_address, paymentREF } = request;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRequest({ ...request, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      firstName: f_name,
      lastName: l_name,
      email: e_mail,
      phoneNumber: phone,
      old_address: o_address,
      new_address: n_address,
      payment_ref: paymentREF,
    };
    addRelocationRequest(data);
  };

  const submitForm = () => {
    return (
      f_name.length > 0 &&
      l_name.length > 0 &&
      phone.length >= 10 &&
      e_mail.length > 0 &&
      paymentREF.length > 0 &&
      o_address.length > 0 &&
      n_address.length > 0
    );
  };

  return (
    <div id="addRelocation" className="modal fade" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header shadow">
            <h4 className="modal-title">Relocation Request</h4>
            <button type="button" className="close mr-2" data-dismiss="modal">
              x
            </button>
          </div>
          <div className="modal-body detail mb-4 p-0">
            <form onSubmit={onSubmit} className="col-md-12">
              <div className="pt-2 d-flex">
                <div className="pr-3 form-group col-md-6">
                  <label className="m-0 p-0 copy-font" htmlFor="firstName">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control border border-info py-2"
                    id="firstName"
                    name="f_name"
                    value={f_name}
                    onChange={handleChange}
                  />
                </div>
                <div className="pl-3 form-group col-md-6">
                  <label className="m-0 p-0 copy-font" htmlFor="lastName">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control border border-info py-2"
                    id="lastName"
                    name="l_name"
                    value={l_name}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="d-flex">
                <div className="pr-3 form-group col-md-12">
                  <label className="m-0 p-0 copy-font" htmlFor="phone">
                    Phone
                  </label>
                  <input
                    type="text"
                    className="form-control border border-info py-2"
                    id="phone"
                    name="phone"
                    value={phone}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="d-flex">
                <div className="pr-3 form-group col-md-12">
                  <label className="m-0 p-0 copy-font" htmlFor="email">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control border border-info py-2"
                    id="email"
                    name="e_mail"
                    value={e_mail}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="d-flex">
                <div className="pr-3 form-group col-md-12">
                  <label className="m-0 p-0 copy-font" htmlFor="paymentREF">
                    Payment REF
                  </label>
                  <input
                    type="text"
                    className="form-control border border-info py-2"
                    id="paymentREF"
                    name="paymentREF"
                    value={paymentREF}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="d-flex">
                <div className="pr-3 form-group col-md-12">
                  <label className="m-0 p-0 copy-font" htmlFor="oldAddress">
                    Old Address
                  </label>
                  <textarea
                    className="form-control border border-info py-2"
                    id="oldAddress"
                    name="o_address"
                    value={o_address}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="d-flex">
                <div className="pr-3 form-group col-md-12">
                  <label className="m-0 p-0 copy-font" htmlFor="newAddress">
                    New Address
                  </label>
                  <textarea
                    className="form-control border border-info py-2"
                    id="newAddress"
                    name="n_address"
                    value={n_address}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="text-center mt-3">
                {isLoading ? (
                  <button type="button" className="btn btn-green py-2 px-5" disabled>
                    Adding...
                  </button>
                ) : (
                  <button type="submit" className="btn btn-green py-2 px-5" disabled={!submitForm()}>
                    Add
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

AddRelocation.propTypes = {
  request: PropTypes.objectOf.isRequired,
};

export default AddRelocation;
