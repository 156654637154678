import React, { useContext, useState } from 'react';
import view from '../../../../../assets/img/icons/view.png';
import RequestDetails from '../shared/RequestDetails';
import RequestContext from '../../../../../context/shared/requests/non-admin/RequestContext';
import helpers from '../../../../../utils/requests/helpers';

const RequestsTableBody = () => {
  const value = useContext(RequestContext);

  const [request, setRequest] = useState('');

  const getRequest = (data) => {
    setRequest(data);
  };

  const { requests, isLoading } = value;

  const requestLists = requests.length ? (
    requests.map((req, i) => {
      return (
        <tr>
          <td>{i + 1}</td>
          <td>{req.request_category ? req.request_category.category : null}</td>
          <td>{req.description}</td>
          <td>{req.created_at}</td>
          <td>
            {req.assigned_to
              ? req.assigned_to.employees.map((emp) => {
                  return <small>{`${`${emp.firstName} ${emp.lastName}`}`}</small>;
                })
              : 'Yet to be assigned'}
          </td>
          {/* <td>May 8th,2019 09:34 AM</td> */}
          {/* <td>May 8th,2019 09:34 AM</td> */}
          {/* <td>
            <span className="badge badge-danger" style={{ backgroundColor: 'red' }}>
              {req.status}
            </span>
          </td> */}
          <td>{helpers.showRequestApprovalStatus(req.status)}</td>

          <td className="text-center">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <span
              onClick={() => getRequest(req)}
              className="view2 mr-2 ml-2"
              data-target="#requestDetails"
              data-toggle="modal"
            >
              <img
                src={view}
                alt=""
                data-toggle="tooltip"
                data-placement="left"
                title="View"
                style={{ cursor: 'pointer' }}
              />
            </span>
          </td>
          <RequestDetails one={request} />
        </tr>
      );
    })
  ) : (
    <small>You are yet to make any requests</small>
  );

  return <tbody>{isLoading ? <small>loading</small> : requestLists}</tbody>;
};

export default RequestsTableBody;
