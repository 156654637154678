import {
  FETCH_CUSTOMERS_DETAILS,
  GET_CUSTOMER_USAGE,
  GET_MONTHLY_EXPIRED_CUSTOMERS,
  START_FETCHING_CUSTOMER_DETAILS,
  STOP_FETCHING_CUSTOMER_DETAILS,
} from '../../../constants/types/customer-support/CustomerSupportTypes';
import { LOADING_STARTS } from '../../../constants/types/shared/LoadingTypes';

const initialState = {
  monthlyExpired: [],
  load: false,
  loading: false,
  customer: [],
};

export const CustomerSupportReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_MONTHLY_EXPIRED_CUSTOMERS:
      return {
        ...state,
        monthlyExpired: action.payload,
      };

    case LOADING_STARTS:
      return {
        ...state,
        load: payload,
      };

    case FETCH_CUSTOMERS_DETAILS:
      return {
        customer: payload,
        loading: false,
      };

    case STOP_FETCHING_CUSTOMER_DETAILS:
      return {
        ...state,
        loading: payload,
      };

    case START_FETCHING_CUSTOMER_DETAILS:
      return {
        ...state,
        loading: payload,
      };

    default:
      return state;
  }
};

export default CustomerSupportReducer;
