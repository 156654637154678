/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import toastr from 'toastr';
import SalesSideBar from '../../../../layouts/sales/SalesSideBar';
// import SalesLogo from '../../../../layouts/sales/SalesLogo';
import Logout from '../../../shared/auth/Logout';
import { API_URL, SALES } from '../../../../../utils/config';
import AddIssue from '../../../shared/issues/AddIssue';
import { SUPER_ADMIN } from '../../../../../utils/departments/helpers';

export default class SalesIssue extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      role: '',
    };
  }

  componentDidMount() {
    this.getUserDetails();
    this.fetchLoggedOnUser();
  }

  getUserDetails() {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };

    axios
      .get(`${API_URL}/employee/loggedOnEmployee`, config)
      .then((res) => {
        if (res.data) {
          this.setState({
            firstName: res.data.firstName,
          });
        }
      })
      .catch(() => {});
  }

  fetchLoggedOnUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employeeReg/logged`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((response) => {
        if (response.data.id) {
          this.setState({
            role: response.data.role_id,
          });

          if (response.data.department_id !== `${SALES}` && response.data.department_id !== `${SUPER_ADMIN}`) {
            this.props.history.push('/staff/login');
            toastr.info('You are not allowed to view this page');
          }
        }
      })
      .catch(() => {});
  }

  render() {
    const { firstName, role } = this.state;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <Link className="s-sidebar__trigger" to="#">
                <i className="fa fa-bars mr-3" />
              </Link>
              <SalesSideBar role={role} />

              <h3 className="col-lg-6 overview-part">Report Issue</h3>

              <div className="d-flex profile-mail">
                <h6>
                  <i className="mr-auto">{firstName}</i>
                  <Logout />
                </h6>
              </div>
            </div>
          </div>

          <div className="col-12 after-summary">
            <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-3">
              <div className="row">
                <AddIssue />
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
