import React, { useContext } from 'react';
import CleanRadioTable from './CleanRadioTable';
import CustomPagination from '../../../shared/CustomPagination';
import AppContext from '../../../../../context/shared/AppContext';

const CleanRadioBody = () => {
  const { pages, onPaginate } = useContext(AppContext);
  return (
    <div className="container pt-5">
      <div className="row mt-5">
        <div className="card x-panel mt-2 px-0">
          <div className="table-responsive content col-12 px-1">
            <CleanRadioTable />
          </div>
        </div>

        <CustomPagination
          pagesCount={pages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={onPaginate}
        />
      </div>
    </div>
  );
};

export default CleanRadioBody;
