/* eslint-disable import/prefer-default-export */
import { useState, useReducer } from 'react';
import axios from 'axios';
import { kpiReducer } from '../../../reducers/noc/kpi/kpiReducer';
import { GET_KPIS } from '../../../constants/types/noc/NocTypes';
import * as load from '../../../constants/types/shared/LoadingTypes';
import { GET_KPIS_URL } from '../../../services/api/noc/KpiUrl';

export const useKpi = () => {
  const [{ isLoading, kpis }, dispatch] = useReducer(kpiReducer, {
    isLoading: false,
    kpis: [],
  });

  const searchState = {
    from: '',
    to: '',
    baseStation: '',
  };

  const [date, setDate] = useState(searchState);

  const onChange = (e) => {
    const { name, value } = e.target;
    setDate({
      ...date,
      [name]: value,
    });
  };

  const getKpi = async (data) => {
    const { from, to, baseStation } = data;
    if (!from && !to) {
      const search = {
        base_station_id: baseStation,
      };
      dispatch({
        type: load.LOADING_STARTS,
      });

      try {
        const response = await axios.post(`${GET_KPIS_URL}`, search, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });
        dispatch({
          type: GET_KPIS,
          payload: response.data.rows,
        });
      } catch (error) {
        if (error) {
          dispatch({
            type: load.LOADING_STOPS,
          });
        }
      }
    } else {
      const search = {
        base_station_id: baseStation,
        from,
        to,
      };
      dispatch({
        type: load.LOADING_STARTS,
      });

      try {
        const response = await axios.post(`${GET_KPIS_URL}`, search, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });

        dispatch({
          type: GET_KPIS,
          payload: response.data.rows,
        });
      } catch (e) {
        dispatch({
          type: load.LOADING_STOPS,
        });
      }
    }
  };

  return {
    getKpi,
    isLoading,
    kpis,
    onChange,
    date,
  };
};
