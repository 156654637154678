/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useTracklistCategory } from './hooks/useTracklistCategory';

const CreateTracklistCategory = () => {
  const { category, addTracklistCategory, handleChange, isLoading } = useTracklistCategory();
  const { categoryName } = category;

  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      name: categoryName,
    };
    addTracklistCategory(data);
  };

  return (
    <div id="add_item" className="modal fade" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header shadow">
            <h4 className="modal-title">Tracklist Item</h4>
            <button type="button" className="btn btn-outline-info btn-circle btn-close" data-dismiss="modal">
              x
            </button>
          </div>
          <div className="modal-body detail mb-4 p-0">
            <form onSubmit={onSubmit} className="col-md-12">
              <div className="d-flex">
                <div className="p-2 pr-3 form-group col-md-12">
                  <label className="m-0 p-0 copy-font" htmlFor="categoryName">
                    Item Name
                  </label>
                  <input
                    type="text"
                    required
                    className="form-control border border-info py-2"
                    id="categoryName"
                    name="categoryName"
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="text-center mt-3">
                {isLoading ? (
                  <button type="button" className="btn btn-green py-2 px-5" disabled>
                    Creating...
                  </button>
                ) : (
                  <button type="submit" className="btn btn-green py-2 px-5" disabled={categoryName.length <= 0}>
                    Create
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateTracklistCategory;
