import React from 'react';
import useGeniatech from '../../../../../../../hooks/shared/geniatech/useGeniatech';
import { DEVICES_URL } from '../api/DevicesUrl';
import AppContext from '../../../../../../../context/shared/AppContext';

const DevicesProvider = ({ children }) => {
  const { data, loading, pages, search, searchData, onChange, searchStatus, reset, fetched } = useGeniatech(
    {
      action: 'get_devices',
      offset: '1',
      limit: '100',
      status: 'online',
      network: 'tz-xwf',
    },
    `${DEVICES_URL}`,
  );
  return (
    <AppContext.Provider
      value={{
        data,
        loading,
        search,
        searchData,
        pages,
        onChange,
        searchStatus,
        reset,
        fetched,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export default DevicesProvider;
