/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import toastr from 'toastr';
import { API_URL } from '../../../../utils/config';
import TizetiLoader from '../../../../assets/img/icons/TizetiLoaderColored.gif';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { ACCOUNTS, ADMIN_DEPARTMENT } from '../../../../utils/departments/helpers';

const UnconfirmedEmployees = ({ unconfirmed, isLoading }) => {
  const departmentId = sessionStorage.getItem('departmentId');
  const [filtered, setFiltered] = useState(unconfirmed);
  const [error, setError] = useState(null);

  const confirmEmployee = (id) => {
    const employeeID = id;
    const url = `${API_URL}/employeeReg/${employeeID}`;

    axios({
      method: 'patch',
      url,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
      .then((response) => {
        if (response.data.message === 'you are not authorised to perform this action') {
          toastr.info(response.data.message);
        }

        if (response.data.status === 'success') {
          toastr.info('Operation Successful!');
          window.location.reload();
        }
      })
      .catch((err) => {
        toastr.info('Something went wrong, Please try again later');
      });
  };

  const filteredEmails = [
    'AdminDept@tizeti.com',
    'assetDept@tizeti.com',
    'customersupportDept@tizeti.com',
    'nocDept@tizeti.com',
    'express.wifi@tizeti.com',
    'expresswifi@tizeti.com',
    'financeDept@tizeti.com',
    'newproduct@tizeti.com',
    'procurementDept@tizeti.com',
    'procurementmanager@tizeti.com',
    'researchanddevelopment@tizeti.com',
    'sales.manager@tizeti.com',
    'operationsDept@tizeti.com',
    'coo@tizeti.com',
    'partnership@tizeti.com',
    'busDev.manager@tizeti.com',
  ];

  const filteredUnConfirmedUsers = unconfirmed.filter((obj) => !filteredEmails.includes(obj.email));
  const tableData = isLoading ? (
    <td>
      <img src={TizetiLoader} alt="loader" />
    </td>
  ) : (
    <tbody>
      {filteredUnConfirmedUsers.map((employee, index) => (
        <tr key={employee.id}>
          <td>{index + 1}</td>
          <td>{employee.email}</td>
          <td>{moment(employee.created_at).format('MM/DD/YYYY')}</td>
          <td>
            {(departmentId === ADMIN_DEPARTMENT || departmentId === ACCOUNTS) && (
              <label className="switch">
                <input type="checkbox" />
                <span onClick={() => confirmEmployee(employee.id)} className="slider round" />
              </label>
            )}
          </td>
        </tr>
      ))}
    </tbody>
  );

  return <>{tableData}</>;
};

export default UnconfirmedEmployees;

// } else {
//   toastr.info('There are no unconfirmed staff');
// }

// const deleteEmployee = (id) => {
//   const employeeID = id;

//   axios({
//     method: 'delete',
//     url: `${API_URL}/employee/${id}`,
//     headers: {
//       Authorization: `Bearer ${sessionStorage.getItem('token')}`,
//     },
//   })
//     .then(() => {
//       window.location.reload();
//     })
//     .catch((err) => {});
// };

// const filterEmployees = (keywords) => {
//   const filteredList = employees.filter((employee) => {
//     return employee.firstName.includes(keywords);
//   });

//   setFiltered(filteredList);
// };

// const searchCustomer = (e) => {
//   const keyword = e.target.value;

//   if (keyword !== '') {
//     const list = employees.filter((employee) => {
//       if (
//         employee.lastName.includes(keyword) ||
//         employee.firstName.includes(keyword) ||
//         employee.email.includes(keyword)
//       ) {
//         return employee;
//       }
//     });

//     setFiltered(list);
//     setKeyword(keyword);
//   } else {
//     setFiltered(employees);
//     setKeyword('');
//   }
// };

// const fetchEmployees = () => {
//   // setIsLoading(true);

//   axios({
//     method: 'get',
//     url: `${API_URL}/employeeReg`,
//     headers: {
//       Authorization: `Bearer ${sessionStorage.getItem('token')}`,
//     },
//   })
//     .then((response) => {
//       setEmployees(response.data);
//       setFiltered(response.data);
//       // setIsLoading(false);
//     })
//     .catch((err) => {
//       setError(err);
//       // setIsLoading(false);
//     });
// };

// const [isLoading, setIsLoading] = useState(false);
