import React from 'react';

const DedicatedDetails = ({ one }) => {
  const { ap, device, name, password, ssid } = one;

  return (
    <div
      id="dedicated-details"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title ml-5" id="myModalLabel">
              Dedicated Customer Details
            </h4>
            <button type="button" className="close custom-close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body detail" style={{ padding: '0', textAlign: 'justify' }}>
            <div className="row">
              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label1">
                  <label id="fn">Name</label>
                  <label id="ln">AP</label>
                  {/* <label id="email">Station</label> */}
                </div>
                <div className="col-lg-8 detail-result1">
                  {name ? <p className="mt-4 mb-2">{name}</p> : <p className="mt-4">N/A</p>}
                  {ap ? <p className="mb-2">{ap}</p> : <p className="mt-4">N/A</p>}
                  {/* <p className="mb-2">192.128.83.70</p> */}
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label2">
                  <label>SSID</label>
                  <label>Password</label>
                  {/* <label >AF5U</label> */}
                </div>
                <div className="col-lg-8 detail-result2">
                  {ssid ? <p className="mt-4 mb-2">{ssid}</p> : <p className="mt-4">N/A</p>}
                  {password ? <p className="mb-2">{password}</p> : <p className="mt-4">N/A</p>}
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label3">
                  {/* <label >Expiration Date</label> */}
                  <label>Device</label>
                </div>
                <div className="col-lg-8 detail-result3">
                  {/* <p className="mt-4 mb-2">01/03/2019</p> */}
                  {device ? <p className="mt-4">{device}</p> : <p className="mt-4">N/A</p>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DedicatedDetails;
