import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import axios from 'axios';
import toastr from 'toastr';
import { connect } from 'react-redux';
import ScoreAgent from './ScoreAgent';
import SendReport from './SendReport';
import view from '../../../../../assets/img/icons/view.png';
import ScoreDetails from './ScoreDetails';
import {
  GET_AGENT_SCORE_URL,
  SEARCH_REPORT_BY_DATE_URL,
} from '../../../../../services/api/customer-support/quality-assurance/QualityAssuranceUrl';
import { getEmployeesInCustomerSupport } from '../../../../../actions/customer-support/quality-assurance/AgentsActions';
import { getUser } from '../../../../../actions/shared/user/UserActions';

class QualityAssuranceBody extends Component {
  static propTypes = {
    getEmployeesInCustomerSupport: PropTypes.func.isRequired,
    agents: PropTypes.array.isRequired,
    getUser: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      score: [],
      user: {},
      details: {},
      fromDate: '',
      to: '',
      ext: '',
    };

    this.searchReportsByDate = this.searchReportsByDate.bind(this);
  }

  componentDidMount() {
    this.props.getEmployeesInCustomerSupport();
    this.props.getUser();
  }

  getStaffScore = async (agent) => {
    try {
      const data = {};

      const response = await axios.post(`${GET_AGENT_SCORE_URL}/${agent.id}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      // console.log(response.data);

      if (response.data.length > 0) {
        this.setState({
          score: response.data,
        });
      } else {
        toastr.info(`${agent.firstName} is yet to be scored!`);
        this.setState({
          user: '',
          score: '',
        });
      }
    } catch (e) {
      // catch errors
    }
  };

  getScoreDetails = (feedback) =>
    this.setState({
      details: feedback,
    });

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  searchReportsByDate = async (e) => {
    e.preventDefault();

    const { fromDate, to } = this.state;

    const data = {
      from: moment(fromDate).format('MM-DD-YYYY'),
      to: moment(to).format('MM-DD-YYYY'),
    };

    try {
      const response = await axios.post(`${SEARCH_REPORT_BY_DATE_URL}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data) {
        // console.log(response.data[0].extension);

        this.props.agents.map((agent) => {
          // this.setState({
          //   ext: agent.extension
          // })
          if (response.data[0].extension) {
            if (agent.extension === response.data[0].extension) {
              this.setState(
                {
                  ext: agent.extension,
                  score: response.data,
                },
                () => console.log(this.state.ext),
              );
            }
          } else {
            /* if(agent.extension === response.data[0].extension){
              this.setState({
                score: response.data
              }, () => console.log(this.state.score))
            } */
            this.setState({
              score: '',
            });
          }
        });
      }
    } catch (e) {
      // console.log(e);
    }
  };

  render() {
    const { agents, user } = this.props;
    const { firstName } = user;
    const { score, details, fromDate, to } = this.state;
    const scoreBody = score ? (
      score.map((feedback) => (
        <tr>
          <td>{moment(feedback.created_at).format('DD-MM-YYYY')}</td>
          <td>{feedback.greetings + feedback.knowledge + feedback.resolution}</td>
          {/* <td>Needs to improve on the welcome messages</td> */}
          <td onClick={() => this.getScoreDetails(feedback)}>
            <div className="view1 ml-2 img-pointer" data-target="#score-details" data-toggle="modal">
              <img src={view} alt="" />
            </div>
            <ScoreDetails details={details} />
          </td>
        </tr>
      ))
    ) : (
      <small>no feedback</small>
    );

    const agentsList = agents ? (
      agents.map((agent, i) => (
        <tr key={i + 1}>
          <td>{i + 1}</td>
          <td>{agent.firstName}</td>
          <td>{agent.lastName}</td>
          <td>{agent.extension}</td>
          <td onClick={() => this.getStaffScore(agent)}>
            <div className="view1 ml-2 img-pointer">
              <img src={view} alt="" />
            </div>
          </td>
        </tr>
      ))
    ) : (
      <h1>no agents</h1>
    );
    return (
      <div className="after-summary">
        <div className="container m-0">
          <div className="row">
            <div className="col-lg-12 col-md-12 mt-3 d-flex p-4">
              <form className="form-inline mx-auto" onSubmit={this.searchReportsByDate}>
                <div className="form-group">
                  <small className="ml-2 mr-1">Show From:</small>
                  <input
                    type="date"
                    className="form-control"
                    onChange={this.onChange}
                    name="fromDate"
                    value={fromDate}
                  />
                </div>
                <br />
                <div className="form-group">
                  <small className="ml-5 mr-1">To:</small>
                  <input type="date" className="form-control ml-2" onChange={this.onChange} name="to" value={to} />
                </div>

                <div className="form-group">
                  <button type="submit" className="btn btn-success">
                    Search
                  </button>
                </div>
              </form>
            </div>

            <div className="col-lg-12 col-md-12 ml-3 d-flex p-4">
              <div>
                <button
                  className="btn btn-green mt-2 mr-3"
                  type="submit"
                  data-toggle="modal"
                  data-target="#score-agent"
                >
                  Score Agent
                </button>
              </div>
              <ScoreAgent />
              <div>
                <button
                  className="btn btn-green mt-2"
                  type="submit"
                  data-toggle="modal"
                  data-target="#send-report"
                  style={{ backgroundColor: '#17c2ae' }}
                >
                  Send Report
                </button>
              </div>
              <SendReport />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 p-0 page-title mt-4 mb-3 d-flex">
              <div className="col-lg-6 p-0">
                <div className="main-content col-12">
                  <h6 className="mb-1">
                    <strong>Customer support staff </strong>
                  </h6>
                  <div className="card x-panel m-0 p-2">
                    <div className="table-responsive content">
                      <table className="table copy-font">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Extension</th>
                            <th>View Rating</th>
                          </tr>
                        </thead>
                        <tbody>{agentsList}</tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              {score.length > 0 ? (
                <div className="col-6 p-0">
                  <div className="main-content col-12">
                    <h6 className="mb-1">
                      <strong>{user.firstName}</strong>
                    </h6>
                    <div className="card x-panel m-0 p-2">
                      <div className="table-responsive content">
                        <table className="table copy-font">
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Score</th>
                              {/* <th>Feedback</th> */}
                              <th>Details</th>
                            </tr>
                          </thead>
                          <tbody>{scoreBody}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  agents: state.employeesByDepartment.employeesByDepartment,
  user: state.user.user,
});

export default connect(
  mapStateToProps,
  {
    getEmployeesInCustomerSupport,
    getUser,
  },
)(QualityAssuranceBody);
