const helpers = {
  XWFname(category) {
    switch (category) {
      case 'CHURNED CUSTOMERS':
        return 'ChurnedCustomers';
      case 'ACTIVE CUSTOMERS ABOUT TO CHURN':
        return 'ActiveCustomersAboutToChurn';
      default:
        return `NoCategory`;
    }
  },

  XWFmessages(category, text) {
    const { ChurnedCustomers, ActiveCustomersAboutToChurn, NoCategory } = text;
    switch (category) {
      case 'CHURNED CUSTOMERS':
        return ChurnedCustomers;
      case 'ACTIVE CUSTOMERS ABOUT TO CHURN':
        return ActiveCustomersAboutToChurn;
      default:
        return NoCategory;
    }
  },

  ChurnedCustomers: `Thank you for using our network. Your data has expired. Click here https://bit.ly/2QB2mEW to buy data for N50 for 100MB and more or call 09087493003`,
  ActiveCustomersAboutToChurn: `Thank you for using our network. Your data is about to expire. Click here https://bit.ly/2QB2mEW to buy data for N50 for 100MB and more or call 09087493003`,
  NoCategory: `Hello From ExpressWifi`,
};
export default helpers;
