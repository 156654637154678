/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-nested-ternary */

import React, { useEffect, useState } from 'react';
import { QA_INSTALLATIONS_REPORTS } from '../../../../../services/api/shared/installations/InstallationsUrl';
import axios from 'axios';
import toastr from 'toastr';
import ReactPaginate from 'react-paginate';
import CustomisationInstallationReportTableBody from './CustomerInstallationReportTableBody';
import CustomInput from '../../../shared/forms/CustomInput';

const recordsPerPage = 20;

const CustomisationInstallationReportBody = () => {
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [allInstallationData, setAllInstallationData] = useState([]);
  const [dates, setDates] = useState({ from: '', to: '' });
  const [details, setDetails] = useState({ detail: '', searchTerm: '' });
  const [requestType, setRequestType] = useState('');

  const getQAInstallationReport = async (page) => {
    setLoading(true);
    setRequestType('');
    axios
      .post(
        `${QA_INSTALLATIONS_REPORTS}/${page}`,
        { paginate: true },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      )
      .then((response) => {
        setAllInstallationData(response.data.list);
        setPage(Math.ceil(response.data.pages));
      })
      .catch((e) => {
        if (e) {
          toastr.info('Something went wrong!');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const filterInstallationsByDate = async ({ from, to, page }) => {
    setRequestType('date');
    setLoading(true);
    try {
      const response = await axios.post(
        `${QA_INSTALLATIONS_REPORTS}/${page}`,
        {
          from,
          to,
          paginate: true,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );

      if (response.status === 200) {
        setAllInstallationData(response.data.list);
        setPage(response.data.pages);
      }
    } catch (error) {
      toastr.info('Something went wrong!');
    }
    setLoading(false);
  };

  const filterInstallationsByDetails = async ({ detail, searchTerm }) => {
    const data = { [detail]: searchTerm };

    setLoading(true);
    try {
      const response = await axios.post(`${QA_INSTALLATIONS_REPORTS}/1`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.status === 200) {
        setAllInstallationData(response.data.rows);
        setPage(1);
      }
    } catch (error) {
      toastr.info('Something went wrong!');
    }
    setLoading(false);
  };

  const onChangeDate = (e) => {
    e.preventDefault();
    const name = e.target.name;
    setDates({ ...dates, [name]: e.target.value });
  };

  const onChangeDetails = (e) => {
    e.preventDefault();
    const name = e.target.name;
    setDetails({ ...details, [name]: e.target.value });
  };

  const handlePageClick = (e) => {
    setCurrentPage(e.selected + 1);
    const nextPage = e.selected + 1;
    if (requestType === 'date') {
      filterInstallationsByDate({ ...dates, page: nextPage });
      return;
    }
    getQAInstallationReport(nextPage);
  };
  useEffect(() => {
    getQAInstallationReport(currentPage);
  }, []);

  return (
    <div>
      <div className="">
        <div className="mt-3 d-flex">
          <div className="col-7 d-none d-md-flex">
            <p className="fil d-flex mt-3 mr-5 copy-font">
              <small className="mr-3">From:</small>
              <CustomInput
                placeholder=""
                readOnly={false}
                type="date"
                classname="form-control"
                name="from"
                value={dates.from}
                onChange={onChangeDate}
              />
              <small className="mr-3 ml-3">To:</small>
              <CustomInput
                placeholder=""
                readOnly={false}
                type="date"
                classname="form-control"
                name="to"
                value={dates.to}
                onChange={onChangeDate}
              />
            </p>
            <div>
              <button
                disabled={!(dates.from && dates.to)}
                type="button"
                className="btn btn-green mt-3"
                onClick={() => {
                  setCurrentPage(1);
                  filterInstallationsByDate({ ...dates, page: 1 });
                }}
              >
                Search
              </button>
            </div>
          </div>
        </div>

        <div className="d-flex">
          <div className="col-9">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                filterInstallationsByDetails(details);
              }}
              className="inner-addon d-flex"
            >
              <div className="input-group mb-3 mr-3">
                <select
                  name="detail"
                  value={details.detail}
                  onChange={onChangeDetails}
                  className="form-control mr-auto empty mt-1"
                >
                  <option value="">Choose field to search from</option>
                  <option value="email">Email</option>
                  <option value="firstName">First Name</option>
                  <option value="lastName">Last Name</option>
                  <option value="phoneNumber">Phone</option>
                  <option value="mac_id">Mac ID</option>
                </select>
              </div>
              <div className="input-group mb-3 mr-3">
                <input
                  className="form-control mr-auto empty pl-5"
                  type="text"
                  placeholder="search for user"
                  name="searchTerm"
                  value={details.searchTerm}
                  onChange={onChangeDetails}
                />
                <span className="fa fa-search" />
              </div>
              <div className="my-1">
                <button disabled={!(details.detail && details.searchTerm)} type="submit" className="btn btn-green">
                  Search By User Details
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      < div className="table-responsive content">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Mac ID</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th> User Address</th>
              <th>Status</th>
              <th>Send Feedback</th>
              <th>Payment REF</th>
              <th>Sub Zone</th>
              <th>Asset Zone</th>
              <th>Actions</th>
            </tr>
          </thead>
          <CustomisationInstallationReportTableBody
            load={loading}
            currentPage={currentPage}
            recordsPerPage={recordsPerPage}
            allInstallations={allInstallationData}
            setAllInstallations={allInstallationData}
          />
        </table>
     
    
        {page > 1 ? (
          <ReactPaginate
            previousLabel="previous"
            nextLabel="next"
            breakLabel="..."
            breakClassName="break-me"
            pageCount={page}
            marginPagesDisplayed={3}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            subContainerClassName="pages pagination"
            activeClassName="active"
          />
        ) : null}
      </div>
    </div>
  );
};

export default CustomisationInstallationReportBody;
