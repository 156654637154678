/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import edit from '../../../../../assets/img/icons/edit.png';
import useSupportCall from '../../../../../hooks/customer-support/call-logs/useSupportCall';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../../utils/loader/loadingHelper';

const SupportCallsTable = ({ date, shift, isLoading, file, onSubmit }) => {
  const { createSupportCalls, isLoader, updateSupportCalls } = useSupportCall();
  const name = sessionStorage.getItem('firstName') + ' ' + sessionStorage.getItem('lastName');
  const getRequest = (item) => {
    const data = { date, shift, zone_name: item.name, zone_id: item.id, employee_name: name, call_count: '1' };
    createSupportCalls(data, onSubmit);
  };

  const updateCallCount = (item, items) => {
    const data = {
      date,
      shift,
      zone_name: item.name,
      zone_id: item.id,
      employee_name: name,
      call_count: '1',
      callId: items.id,
    };
    updateSupportCalls(data, onSubmit);
  };

  const tableData =
    file && file.length ? (
      file.map((item, i) => {
        return (
          <tr className="" key={i}>
            <td>{i + 1}</td>
            <td>{item.name}</td>

            {item.supportCalls && item.supportCalls.length ? (
              item.supportCalls.map((items, i) => {
                return (
                  <>
                    <td>{items.call_count}</td>
                    <td>
                      {' '}
                      <div className="d-flex">
                        <button type="submit" className="mx-1 noBorder" onClick={() => updateCallCount(item, items)}>
                          <span className="view">
                            <img
                              src="  https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-wJjRQpAZ1sMI3Vn6Jf9b5k8peDFptBMo0b76ibRPDw&s"
                              alt="Increment"
                              data-toggle="tooltip"
                              data-placement="left"
                              title="Increment"
                            />
                          </span>
                        </button>
                      </div>
                    </td>
                  </>
                );
              })
            ) : (
              <>
                <td>0</td>
                <td>
                  <div className="d-flex">
                    <button type="submit" className="mx-1 noBorder" onClick={() => getRequest(item)}>
                      <span className="view">
                        <img src={edit} alt="" data-toggle="tooltip" data-placement="left" title="Edit" />
                      </span>
                    </button>
                  </div>
                </td>
              </>
            )}

            {/* <CreateSuppportLogs request={request} date={date} shift={shift} /> */}
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan="20" text="There Are No Customers" />
    );

  return (
    <div className="table-responsive content col-12 px-1">
      <table className="table">
        <thead>
          <tr className="text-nowrap">
            <th>#</th>
            <th>Asset Zone</th>
            <th>Support Count</th>
            <th>Create Log</th>
          </tr>
        </thead>

        <tbody>{isLoading ? <TizetiGIFLogoTable colSpan="20" /> : tableData}</tbody>
      </table>
    </div>
  );
};

export default SupportCallsTable;
