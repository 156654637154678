import { CLEAN_LTE } from '../../../constants/types/field-support/CleanLTETypes';
import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';

const initialState = {
  lists: "",
  isLoading: false,
};

const CleanLTEReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOADING_STARTS:
      return {
        ...state,
        isLoading: true,
      };

    case CLEAN_LTE:
      return {
        ...state,
        lists: payload.lists,
      
        isLoading: false,
      };

    case LOADING_STOPS:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default CleanLTEReducer;
