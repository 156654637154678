/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import { getFibreLinks } from '../../../../../actions/noc/fibre-links/FibreLinksActions';

class FibreLinks extends Component {
  static propTypes = {
    getFibreLinks: PropTypes.func.isRequired,
    links: PropTypes.array.isRequired,
    load: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    this.props.getFibreLinks();
  }

  render() {
    const { links, load } = this.props;

    return (
      // <div className="page-wrapper col-lg-10 col-md-10">
      <div className="container">
        <div className="row mt-5">
          {/* <div className="col-11 mb-2 mt-2">

            </div> */}
          <div className="card x-panel mt-3">
            <div className="table-responsive content col-lg-12 col-md-12 col-sm-12">
              <table className="table col-lg-12 col-md-12 col-sm-12">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Latitude</th>
                    <th>Longitude</th>
                    <th className="mr-1">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {load ? (
                    <tr>
                      <td>
                        <img src={loader} alt="" />
                      </td>
                    </tr>
                  ) : (
                    links.map((link, i) => (
                      <tr key={i + 1}>
                        <td>{i + 1}</td>
                        <td>{link.name}</td>
                        <td>{link.latitude}</td>
                        <td>{link.longitude}</td>
                        {link.status === true ? <td>UP</td> : <td>DOWN</td>}
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  links: state.fibres.fibre,
  load: state.fibres.load,
});

export default connect(
  mapStateToProps,
  { getFibreLinks },
)(FibreLinks);
