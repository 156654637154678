import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Box from '@mui/material/Box';

export default function AreaCharts({
  getZones,
  state,
  sumOfActiveAndInactiveUsers,
  getAllActiveUsersCountValue,
  getAllInActiveUsersCountValue,
}) {
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    if (
      getZones.length > 0 &&
      sumOfActiveAndInactiveUsers.length &&
      getAllActiveUsersCountValue.length > 0 &&
      getAllInActiveUsersCountValue.length > 0
    ) {
      const refinedData = getZones.map((zone, index) => ({
        name: zone.name,
        'Active & Expired / Inactive Users': sumOfActiveAndInactiveUsers[index] && sumOfActiveAndInactiveUsers[index],
        Active: getAllActiveUsersCountValue[index] && getAllActiveUsersCountValue[index],
        'Expired / Inactive Users': getAllInActiveUsersCountValue[index] && getAllInActiveUsersCountValue[index],
      }));

      if (refinedData.length > 0) {
        const filterNonZeroValues = refinedData.filter((val) => val.Active > 0);
        setData(filterNonZeroValues);
      }
    }
  }, [sumOfActiveAndInactiveUsers.length]);

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flexStart">
      <Box sx={{ width: { xs: '90vw', md: 1000 }, overflowX: 'auto' }}>
        <Box sx={{ width: '100%', overflowX: 'scroll' }}>
          <AreaChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }} width={4200} height={550}>
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorLv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#ff7300" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#ff7300" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />

            {state.activeInactive && (
              <Area
                type="monotone"
                dataKey="Active & Expired / Inactive Users"
                fillOpacity={1}
                fill="url(#colorUv)"
                stroke="#8884d8"
              />
            )}

            {state.active && (
              <Area type="monotone" dataKey="Active" fillOpacity={1} fill="url(#colorPv)" stroke="#82ca9d" />
            )}
            {state.inactive && (
              <Area
                type="monotone"
                dataKey="Expired / Inactive Users"
                fillOpacity={1}
                fill="url(#colorLv)"
                stroke="#ff7300"
              />
            )}
          </AreaChart>
        </Box>
      </Box>
    </Box>
  );
}
