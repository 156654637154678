/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import '../../../../../styles/basestation.css';
import PropTypes from 'prop-types';
import useCheckTwoDepartments from '../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';
import { CUSTOMER_SUPPORT, SUPER_ADMIN } from '../../../../../utils/departments/helpers';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import RetentionScript from '../../customer-support/RetentionScript';
import CustomerServiceScript from '../../CustomerServiceScript';
import MonthlyUsers from './MonthlyUsers';
import CustomerSupportNavBar from '../../../../layouts/customer-support/CustomerSupportNavBar';

const MonthlyBasestationsUsers = ({ history }) => {
  useCheckTwoDepartments(`${CUSTOMER_SUPPORT}`, `${SUPER_ADMIN}`, history);
  const { profile } = useUser();
  const { firstName } = profile;
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <CustomerSupportNavBar currentPage="BaseStation Users Monthly Search" position={firstName} />
        <div className="after-summary">
          <div className="container m-0">
            <MonthlyUsers />
            <span className="d-none d-md-block">
              <RetentionScript />
              <CustomerServiceScript />
            </span>
          </div>
        </div>
      </main>
    </div>
  );
};

MonthlyBasestationsUsers.propTypes = {
  history: PropTypes.shape(),
};
export default MonthlyBasestationsUsers;
