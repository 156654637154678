/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { useFeedback } from '../../../../hooks/customer-support/feedback/useFeedback';
import { filename, feedbackHeaders } from '../../../../utils/field-support/installations/helpers';
import CustomCsvDownload from '../CustomCsvDownload';
import FeedbackTable from './FeedbackTable';

const FeedbackBody = () => {
  const { lists, pages, count, isLoading, getFeedback } = useFeedback();
  const [page] = useState(1);

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    return getFeedback(selectedPage);
  };

  useEffect(() => {
    getFeedback(page);
  }, []);

  return (
    <>
      <div className="col-12 d-flex mt-2">
        <CustomCsvDownload
          data={lists || []}
          headers={feedbackHeaders}
          filename={filename(`Feedback`)}
          classname="btn btn-green shadow-md"
          textcolor="white"
          text="Download Data"
        />
      </div>
      <div className="p-0 col-12 d-flex justify-content-between">
        {count > 0 ? (
          <div>
            Count: <span className="badge badge-secondary mr-4">{count}</span>
          </div>
        ) : null}
      </div>
      <div className="card x-panel p-0">
        <FeedbackTable lists={lists} isLoading={isLoading} />
        <div className="px-3">
          {30 > 1 ? (
            <ReactPaginate
              previousLabel="previous"
              nextLabel="next"
              breakLabel="..."
              breakClassName="break-me"
              pageCount={30}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName="pagination"
              // subContainerClassName="pages pagination"
              activeClassName="active"
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default FeedbackBody;
