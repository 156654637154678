/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from 'react';
import axios from 'axios';
import toastr from 'toastr';

const useCheckDepartment = (url, department, history) => {
  const [result, setResult] = useState(false);

  useEffect(() => {
    const checkDepartment = async () => {
      try {
        const response = await axios.get(`${url}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });
        if (response.data.department_id !== `${department}`) {
          sessionStorage.clear();
          sessionStorage.setItem('token', '');
          sessionStorage.setItem('email', '');
          sessionStorage.setItem('officialEmail', '');
          sessionStorage.setItem('firstName', '');
          sessionStorage.setItem('lastName', '');
          sessionStorage.setItem('departmentId', '');
          sessionStorage.setItem('roleId', '');

          sessionStorage.setItem('country', '');
          history.push('/login');

          toastr.info('You are not allowed to view this page');
        }
      } catch (e) {
        // console.log(e.response);
      }
    };
    checkDepartment();
  }, [url, department, history]);
  return { result };
};

export default useCheckDepartment;
