/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PATHS from '../../../routes/urls';
import { AES } from 'crypto-js';
import BudgetSideBar from '../../staff/shared/layouts/sidebar/manager-and-executives/BudgetSideBar';

const NewProductsSideBar = () => {
  const history = useHistory();
  const departmentId = sessionStorage.getItem('departmentId');
  const officialEmail = sessionStorage.getItem('officialEmail');
  const role_id = sessionStorage.getItem('roleId');
  const detailsState = {
    name: 'React',
    messagetoken: sessionStorage.getItem('token'),
    messagerole: sessionStorage.getItem('roleId'),
    messagedepartment: sessionStorage.getItem('departmentId'),
    secret: 'Wifirocks123',
    cipher: '',
  };
  const [details, setDetails] = useState(detailsState);
  const { name, messagetoken, messagerole, messagedepartment, secret, cipher } = details;

  const obj = {
    login: messagetoken,
    pass: messagerole,
    department: messagedepartment,
  };

  const ciph = AES.encrypt(JSON.stringify(obj), secret).toString();

  return (
    <div className="s-layout__sidebar">
      <Link className="s-sidebar__trigger" to="#">
        <i className="fa fa-bars mr-3" />
      </Link>

      <nav className="s-sidebar__nav">
        <Link className="navbar-brand-logo" to="/sales">
          <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971047/Tizeti-Logo.png" alt="homepage" />
        </Link>
        <ul>
          <li className="p-0 mt-3">
            <li className="s-sidebar__nav-link">
              <Link to="/new-products" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971013/Group-3.png" alt="img" />
                </span>
                <small>Overview</small>
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <a href={`https://os.tizeti.com/main/#/overview?ciph=${ciph}`} target="_blank" className="make-bold">
                <span>
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923169/configr_d.fw.png" alt="img" />
                </span>
                <small>New system (Beta v2.0)</small>
              </a>
            </li>

            <li className="s-sidebar__nav-link d-none d-md-block">
              <Link to="/new-products/geniatech" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                </span>
                <small>Geniatech</small>
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              <Link to="/products" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923169/idontknow.fw.png" alt="img" />
                </span>
                <small>Products</small>
              </Link>
            </li>

            {role_id > '2' ? (
              <li className="s-sidebar__nav-link">
                {' '}
                <Link to="/new-products/extensions" target="_self">
                  <span>
                    <img
                      src="https://res.cloudinary.com/teewhy/image/upload/v1547724068/frequency_graphs.png"
                      alt="img"
                    />
                  </span>
                  <small>Customer Extension</small>
                </Link>
              </li>
            ) : (
              ''
            )}

            {role_id < '2' ? null : (
              <li className="d-none d-md-block">
                {' '}
                <a href={PATHS.NEW_PRODUCTS_RECRUITMENT} target="_blank" className="">
                  <span>
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1555319728/recruitment_1.svg" alt="img" />
                  </span>{' '}
                  Recruitment
                </a>
              </li>
            )}
          </li>

          <li className="p-0 mt-5 d-none d-md-block">
            <li className="s-sidebar__nav-link">
              <Link to="/new-products/admin-requests" target="_self" className="make-bold">
                <span>
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg" alt="img" />
                </span>
                <small>Admin Requests</small>
              </Link>
            </li>
            {Number(sessionStorage.getItem('roleId')) > 2 ? (
              <BudgetSideBar link={PATHS.NEW_PRODUCTS_BUDGET} name="Budget" />
            ) : null}

            <li className="s-sidebar__nav-link">
              <Link to={PATHS.NEW_PRODUCTS_PAYSLIP} target="_self" className="make-bold">
                <span>
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg" alt="img" />
                </span>
                <small>Payslip</small>
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/new-products/kpi-monitor" target="_self" className="make-bold">
                <span>
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg" alt="img" />
                </span>
                <small>KPI Monitor</small>
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/new-products/requisition" target="_self" className="make-bold">
                <span>
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1550844527/requisition.png" alt="img" />
                </span>
                <small>Requisition</small>
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/new-products/issue" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923430/r_d-envelop.fw.png" alt="img" />
                </span>
              </Link>
              <small className="text-danger make-bold">Report an issue</small>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/new-products/feature" target="_self" className="make-bold">
                <span>
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923169/configr_d.fw.png" alt="img" />
                </span>
                <small>Request a Feature</small>
              </Link>
            </li>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default NewProductsSideBar;
