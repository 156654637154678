/* eslint-disable import/prefer-default-export */
import { useReducer } from 'react';
import qs from 'qs';
import axios from 'axios';
import toastr from 'toastr';
import { config } from 'process';
import AmbassadorsReducer from '../../reducers/express-wifi/ambassadors/AmbassadorsReducer';
import { GET_AMBASSADORS_URL } from '../../services/api/express-wifi/ambassadors/AmbassadorsUrl';
import { LOADING_STOPS, LOADING_STARTS } from '../../constants/types/shared/LoadingTypes';

const useAddNewCutomer = () => {
  const [{ items, count, pages, loading }, dispatch] = useReducer(AmbassadorsReducer, {
    items: [],
    count: 1,
    pages: 1,
    monthlyCount: 0,
    isLoading: false,
    tracker: false,
  });

  // const [page] = useState(1);

  const AddNewCustomer = async (data) => {
    const config = {
      method: 'post',
      action: 'add_new_customer',
      url: `${GET_AMBASSADORS_URL}`,
      data: qs.stringify(data),
    };

    await axios(config)
      .then((response) => {
        // console.log(response.data);
        if (response.data) {
          // console.log('true');
          toastr.success('Added Successfully');
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((error) => {
        if (error) {
          toastr.info('Something went wrong!');
          dispatch({ type: LOADING_STOPS });
        }
      });
  };
  const ExpressWifiCustomerSignupsBulkUpload = async (formData) => {
    dispatch({ type: LOADING_STARTS });
    const url = `${GET_AMBASSADORS_URL}`;
    try {
      const response = await axios.post(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (response) {
        console.log(response);
        toastr.success('Uploaded Successfully!');
        setTimeout(() => window.location.reload(), 1000);
      } else toastr.warn('Unable To Upload');
    } catch (e) {
      toastr.info('Error Uploading Customer Details...');
      dispatch({ type: LOADING_STOPS });
    }
  };

  return {
    items,
    pages,
    count,
    loading,
    AddNewCustomer,
    ExpressWifiCustomerSignupsBulkUpload,
  };
};

export default useAddNewCutomer;
