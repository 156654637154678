/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable react/button-has-type */
import React from 'react';
import './requisition.css';
import PropTypes from 'prop-types';

const DownloadRequisitions = ({ requistions }) => {
  // const requistions = [];
  const handleDownload = () => {
    // const downloadUrls = requistions.map((a) => a.imageUpload);
    // eslint-disable-next-line func-names
    // downloadUrls.forEach(function(value) {
    //   window.open(value, '_blank');
    // });
  };

  function convertToCSV(objArray) {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (const index in array[i]) {
        if (line !== '') line += ',';

        line += array[i][index];
      }

      str += `${line}\r\n`;
    }

    return str;
  }

  function exportCSVFile(headers, items, fileTitle) {
    if (headers) {
      items.unshift(headers);
    }

    // Convert Object to JSON
    const jsonObject = JSON.stringify(items);

    const csv = convertToCSV(jsonObject);

    const exportedFilenmae = `${fileTitle}.csv` || 'export.csv';

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', exportedFilenmae);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  const download = () => {
    const headers = {
      purpose: 'Purpose',
      amountInWords: 'Amount In Words',
      inFavourOf: 'In Favour Of',
      amountInDigits: 'Amount In Digits',
      currency: 'Currency',
      file1: 'File 1',
      file2: 'File 2',
      file3: 'File 3',
      file4: 'File 4',
      file5: 'File 5',
      file6: 'File 6',
      managerApproal: 'Manager Approval',
      accountApproval: 'Acount Approval',
      country: 'Country',
      accountPaymentStatus: 'Acount Payment Status',
    };

    const newData = [];
    // format the data
    requistions.forEach((item) => {
      newData.push({
        purpose: item.purpose.replace(/[^a-zA-Z ]/g, ''),
        amountInWords: item.amount_in_words.replace(/,/g, ''),
        inFavourOf: item.in_favour_of,
        amountInDigits: item.amount_in_digits,
        currency: item.currency,
        file1: item.imageUpload,
        file2: item.image2,
        file3: item.image3,
        file4: item.image4,
        file5: item.image5,
        file6: item.image6,
        managerApproal: item.manager_approval,
        accountApproval: item.account_approval,
        country: item.country,
        accountPaymentStatus: item.account_payment_status,
        // budgetCode: item.budget_code,
      });
    });

    const fileTitle = 'requsitions';
    exportCSVFile(headers, newData, fileTitle);
  };

  return (
    <button onClick={download} className="downloadButton">
      Download
    </button>
  );
};
DownloadRequisitions.propTypes = {
  // eslint-disable-next-line react/require-default-props
  requistions: PropTypes.shape(),
};
export default DownloadRequisitions;
