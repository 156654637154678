import React from 'react';
import LteCustomersBody from '../../../shared/customers/lte/lte-customers/LteCustomersBody';
import CustomerSupportNavBar from '../../../../layouts/customer-support/CustomerSupportNavBar';
import { useUser } from '../../../../../hooks/shared/user/useUser';

const CustomerSupportLteCustomers = () => {
  const { user, profile } = useUser();
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <CustomerSupportNavBar currentPage="LTE CUSTOMERS" roleID={user.role_id} position={profile.firstName} />
        <LteCustomersBody />
      </main>
    </div>
  );
};

export default CustomerSupportLteCustomers;
