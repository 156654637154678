/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import FieldSupportNavBar from '../../../../layouts/field-support/FieldSupportNavBar';
import CustomerDetailsSharedBody from '../../../shared/customer-details/CustomerDetailsSharedBody';

const FieldCustomerDetailsShared = () => {
  const { profile, user } = useUser();
  const { firstName } = profile;

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <FieldSupportNavBar currentPage="Customer Details" roleID={user.role_id} position={firstName} />
        <CustomerDetailsSharedBody />
      </main>
    </div>
  );
};

export default FieldCustomerDetailsShared;
