import axios from 'axios';
import { GET_FIBRE_LINKS_URL } from '../../../services/api/noc/fibre-links/FibreLinksUrl';
import { FETCHING_STARTS, FETCHING_STOPS } from '../../../constants/types/shared/LoadingTypes';
import { GET_FIBRE_LINKS } from '../../../constants/types/noc/NocTypes';

export const getFibreLinks = () => async (dispatch) => {
  dispatch({
    type: FETCHING_STARTS,
    payload: true,
  });

  const param = new URLSearchParams();
  param.append('action', 'pingbasestation');

  const response = await axios.post(`${GET_FIBRE_LINKS_URL}`, param);

  if (response.data) {
    dispatch({
      type: GET_FIBRE_LINKS,
      payload: response.data,
    });
  } else {
    dispatch({
      type: FETCHING_STOPS,
      payload: false,
    });
  }
};
