/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/button-has-type */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import toastr from 'toastr';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import { getJobs, getJobsByDepartment } from '../../../../../actions/shared/recruitment/RecruitmentActions';
import ApplicantTable from './ApplicantTable';
import { ADMIN_DEPARTMENT, API_URL } from '../../../../../utils/config';
import AdminSideBar from '../../../../layouts/admin-department/AdminDepartmentSideBar';
import AdminDeptLogo from '../../../../layouts/admin-department/AdminDeptLogo';
import { getUser } from '../../../../../actions/shared/user/UserActions';
import { GET_JOBS_URL } from '../../../../../services/api/shared/recruitment/RecruitmentUrl';
import { getDepartments } from '../../../../../actions/shared/departments/DepartmentsActions';
import Logout from '../../../shared/auth/Logout';
import NewPosting from '../../../shared/recruitment/admin/NewPosting';
import RecruitmentTable from '../../../shared/recruitment/RecruitmentTable';

class AdminRecruitment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      role: '',
      pages: [],
      jobs: [],
      department: '',
      loading: false,
    };
  }

  componentDidMount() {
    this.fetchLoggedOnUser();
    this.getUser();
    this.props.getJobsByDepartment();
    this.props.getUser();
    this.getJobs();
  }

  componentWillReceiveProps(nextProps) {
    this.fetchLoggedOnUser();

    if (nextProps.departmentjobs.count !== 0) {
      this.setState({
        jobs: nextProps.departmentjobs.logs,
        pages: nextProps.departmentjobs.pages,
      });
    }
  }

  getJobs = async () => {
    const response = await axios.get(`${GET_JOBS_URL}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    this.setState({
      jobs: response.data.logs,
    });
  };

  handlePageChange = (pageNumber) => {
    const selected = pageNumber.selected + 1;

    const url = `${API_URL}/recruitment/listing/?page=${selected}`;

    axios({
      method: 'get',
      url,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      }
    }).then((response) => {
      if (response.data) {
        this.setState({
          pages: response.data.pages,
        });
      } else {
        toastr.info('No data!');
      }
    });
  };

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  getJobsByDate = (department) => {
    this.props.getJobsByDepartment(department);
  };

  fetchLoggedOnUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employeeReg/logged`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        if (data.data.department_id !== `${ADMIN_DEPARTMENT}`) {
          const { history } = this.props;
          history.push('/login');

          toastr.info('You are not allowed to view this page');
        }
      })
      .catch((err) => {
        if (err) {
          toastr.info('Something went wrong!');
        }
      });
  }

  render() {
    const { loading, jobs, pages } = this.state;
    // eslint-disable-next-line react/prop-types
    const { departments, user, pageRangeDisplayed } = this.props;
    const { firstName } = user;

    return (
      <div className="main-wrapper">
        <header className="topbar shade bg-white fixed-top">
          <nav className="navbar navbar-expand-lg navbar-light top-navbar fixed-top">
            <AdminDeptLogo />
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse mt-3" id="navbarText">
              <ul className="navbar-nav">
                <li className="nav-item active">
                  <Link className="nav-link" to="#">
                    <h3>
                      Recruitment<span className="sr-only">(current)</span>
                    </h3>
                  </Link>
                </li>
              </ul>
              <span className="navbar-text">
                <h6>
                  <i>{firstName}</i>
                  <Logout />
                </h6>
              </span>
            </div>
          </nav>
        </header>

        <div className="all-sides col-lg-12 col-md-12 col-sm-12">
          <AdminSideBar />
          <div className="page-wrapper col-lg-10 col-md-9 col-sm-9">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 mt-4">
                  <div className="col-6 d-flex">
                    <div className="tab-stuff col-10 p-0">
                      <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li className="nav-item mr-auto">
                          <a
                            className="nav-link active"
                            id="pills-home-tab"
                            data-toggle="pill"
                            href="#pills-home"
                            role="tab"
                            aria-controls="pills-home"
                            aria-selected="true"
                          >
                            Job Postings
                          </a>
                        </li>
                        <li className="nav-item mr-auto">
                          <a
                            className="nav-link"
                            id="pills-profile-tab"
                            data-toggle="pill"
                            href="#pills-profile"
                            role="tab"
                            aria-controls="pills-profile"
                            aria-selected="false"
                          >
                            Applications
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="tab-content col-12" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >
                      {/* <form className="form-horizontal"> */}
                      <div className="form-group mt-4">
                        <div className="col-12 d-flex p-0">
                          <small>Filter By:</small>
                          <div className="d-flex">
                            <span className="ml-2 mr-3">
                              <select
                                className="form-control"
                                onChange={(e) => this.getJobsByDate(e.target.value)}
                                name="department"
                              >
                                <option value="">All Departments</option>
                                {departments.map((department) => {
                                  return <option value={department.id}>{department.name}</option>;
                                })}
                              </select>
                            </span>

                            {/* <span className="mr-3"> */}
                            {/* <input type="date" className="form-control"></input> */}
                            {/* </span> */}

                            {/* <button className="btn btn-green"> */}
                            {/* Submit */}
                            {/* </button> */}
                          </div>

                          <div>
                            <button className="btn btn-green" data-target="#Newposting" data-toggle="modal">
                              New Posting
                            </button>

                            <NewPosting />
                          </div>
                        </div>
                      </div>
                      {/* </form> */}

                      <div className="col-lg-12 p-0">
                        <div className="card x-panel w-100 ml-0 p-2 mt-4">
                          <div className="table-responsive content col-lg-12 col-md-12 col-sm-12 p-0">
                            <table className="table col-lg-12 col-md-12 col-sm-12 copy-font">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Job Title</th>
                                  <th>Department</th>
                                  {/* <th>Type</th> */}
                                  {/* <th>Posting Date</th> */}
                                  <th>Deadline</th>
                                  {/* <th>Number of Applicants</th> */}
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              {loading ? <small>loading</small> : <RecruitmentTable all={jobs} />}
                            </table>
                          </div>
                        </div>
                      </div>
                      {pages > 1 ? (
                        <ReactPaginate
                          previousLabel="previous"
                          nextLabel="next"
                          breakLabel="..."
                          breakClassName="break-me"
                          // eslint-disable-next-line react/destructuring-assignment
                          pageCount={this.state.pages}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={pageRangeDisplayed}
                          onPageChange={this.handlePageChange}
                          containerClassName="pagination"
                          subContainerClassName="pages pagination"
                          activeClassName="active"
                        />
                      ) : null}
                    </div>
                    <div
                      className="tab-pane fade show"
                      id="pills-profile"
                      role="tabpanel"
                      aria-labelledby="pills-profile-tab"
                    >
                      <div className="col-6 d-flex p-0">
                        <small>Filter By:</small>
                        <div className="d-flex">
                          <span className="ml-2 mr-3">
                            <select className="form-control">
                              <option>Job Title</option>
                            </select>
                          </span>

                          {/* <span className="ml-2 mr-3"> */}
                          {/* <select className="form-control"> */}
                          {/* <option>Course of study</option> */}
                          {/* </select> */}
                          {/* </span> */}

                          {/* <span className="ml-2 mr-3"> */}
                          {/* <select className="form-control"> */}
                          {/* <option>Class of degree</option> */}
                          {/* </select> */}
                          {/* </span> */}

                          {/* <span className="mr-3">
                                                    <input type="date" className="form-control"></input>
                                                </span> */}
                        </div>

                        <div>
                          <button className="btn btn-green" data-target="#Newposting" data-toggle="modal">
                            Send Mail
                          </button>
                        </div>
                      </div>

                      <div className="col-lg-12 p-0">
                        <div className="card x-panel w-100 ml-0 p-2 mt-4">
                          <div className="table-responsive content col-lg-12 col-md-12 col-sm-12 p-0">
                            <table className="table col-lg-12 col-md-12 col-sm-12 copy-font">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>First Name</th>
                                  <th>Last Name</th>
                                  <th>Department</th>
                                  <th>Job Description</th>
                                  <th>Type</th>
                                  <th>Application Date</th>
                                  <th>View Cover Letter</th>
                                  <th>View CV</th>
                                  {/* <th>Actions</th> */}
                                </tr>
                              </thead>
                              <ApplicantTable />
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  jobs: state.jobs.jobs,
  departments: state.helpers.helpers,
  departmentjobs: state.departmentjobs.departmentjobs,
  user: state.user.user,
});

AdminRecruitment.propTypes = {
  departments: PropTypes.array.isRequired,
  getJobs: PropTypes.func.isRequired,
  getDepartments: PropTypes.func.isRequired,
  getJobsByDepartment: PropTypes.func.isRequired,
  departmentjobs: PropTypes.shape(),
  user: PropTypes.string.isRequired,
  getUser: PropTypes.func.isRequired,
  jobs: PropTypes.shape(),
};
export default connect(
  mapStateToProps,
  { getJobs, getDepartments, getJobsByDepartment, getUser },
)(AdminRecruitment);
