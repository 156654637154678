import React, { useContext } from 'react';
import Loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import AppContext from '../../../../../context/shared/AppContext';
import LteInstallationSummary from './LteInstallationSummary';
import helpers from '../../../../../utils/helpers';

const LteInstallationsTable = () => {
  const { loading, searchType, data } = useContext(AppContext);

  const display =
    data && data.length
      ? data.map((item, i) => {
          return (
            <tr>
              <td>{i + 1}</td>
              <td>{item ? item.username : ''}</td>
              <td>{item.Name}</td>
              <td>{item.Mail}</td>
              <td>{item.Mobile}</td>
              <td>{item.Expiration_date}</td>
              <td>{helpers.formatDateAndTime(item.created_at)}</td>
            </tr>
          );
        })
      : 'no data';

  return (
    <div>
      <LteInstallationSummary />
      <br />
      <br />
      <h4> LTE Installed {searchType} </h4>
      <table className="table">
        <thead>
          <tr>
            <th>#</th>
            <th>Username</th>
            <th>Name</th>
            <th>Mail </th>
            <th>Mobile</th>
            <th>Expiration Date</th>
            <th>Installed Date </th>
          </tr>
        </thead>
        {loading ? <img src={Loader} alt="" /> : display}
      </table>
      <br />
      <hr />
    </div>
  );
};

export default LteInstallationsTable;
