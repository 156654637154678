/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import toastr from 'toastr';
import * as url from '../../../../../services/api/shared/installations/InstallationsUrl';
import { editInstallation } from '../../../../../actions/shared/installations/InstallationsActions';
import { EDIT_LOG_URL } from '../../../../../services/api/sales/logs/LogsUrl';
import { TEAM_URL } from '../../../departments/field-support/team/api/TeamsUrl';
import { SALES } from '../../../../../utils/config';

class EditInstallation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: '',
      email: '',
      address: '',
      userAddress: '',
      status: '',
      id: '',
      reference: '',
      date: '',
      edit: false,
      teams: [],
      teamId: '',
      assignedStaffCount: 0,
    };
  }

  componentDidMount() {
    this.getTeams();
  }

  componentWillReceiveProps(nextProps) {
    const { install } = nextProps;
    const {
      user,
      firstName,
      lastName,
      phoneNumber,
      email,
      address,
      userAddress,
      userId,
      installationId,
      payment_reference,
      date,
      status,
      teamId,
    } = install;
    this.setState({
      first: user ? user.firstName : firstName || null,
      last: user ? user.lastName : lastName || null,
      phone: user ? user.phoneNumber : phoneNumber || null,
      email: user ? user.email : email || null,
      userAddress: user ? user.address : userAddress || null,
      userId: user ? user.id : userId || null,
      teamId: teamId || '',
      address,
      id: nextProps.install.id || installationId,
      reference: payment_reference,
      date,
      status,
      // user
    });
    this.getAssignedStaff(nextProps.install.id);
  }

  getTeams = async () => {
    try {
      const response = await axios.get(`${TEAM_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        this.setState({ teams: response.data });
      }
    } catch (e) {
      toast.info('Unable to get teams');
    }
  };

  onChange = (e) => {
    const { name, value } = e.target;
    const { assignedStaffCount, teamId } = this.state;
    if (name === 'status' && value === 'completed' && (assignedStaffCount.length < 1 || !teamId))
      toastr.warning('Assign To Individual/Team First!');
    else this.setState({ [name]: value });
  };

  updateUser = async (e) => {
    e.preventDefault();
    this.setState({
      edit: true,
    });
    const { userId, first, last, phone, email, userAddress } = this.state;
    const data = {
      firstName: first,
      lastName: last,
      phoneNumber: phone,
      email,
      address: userAddress,
    };
    try {
      const response = await axios.patch(`${EDIT_LOG_URL}/${userId}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.editStatus === 'success') {
        toast.success(`${first} details has been updated`);
        setTimeout(() => {
          window.location.assign('https://os.tizeti.com/login#/new-design/installations');
        }, 2000);
      }
    } catch (err) {
      toast.info(`Something went wrong`);
    }
  };

  updateStatus = async (e) => {
    e.preventDefault();
    this.setState({
      load: true,
    });
    const { status, id, reference, address, date, teamId } = this.state;
    if (!reference) {
      const data = {
        status,
        payment_reference: reference,
        address,
        date,
        team_id: teamId,
      };
      try {
        const response = await axios.patch(`${url.EDIT_INSTALLATION_STATUS_URL}/${id}`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });
        if (response.data) {
          toast.success('Installation status has been updated!');
          this.setState({
            load: false,
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      } catch (err) {
        if (err) {
          toast.info(e.response.data.message);
          this.setState({
            load: false,
          });
        }
      }
    } else {
      const data = {
        status,
        payment_reference: reference,
        address,
        date,
        team_id: teamId,
      };
      try {
        const response = await axios.patch(`${url.EDIT_INSTALLATION_STATUS_URL}/${id}`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });
        if (response.data) {
          toast.success('Installation status has been updated!');
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      } catch (er) {
        if (er) {
          toast.info(e.response.data.message);
          this.setState({
            load: false,
          });
        }
      }
    }
  };

  getAssignedStaff = async (id) => {
    try {
      const response = await axios.get(`${url.ASSIGN_INSTALLATION_URL}/installation/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        this.setState({ assignedStaffCount: response.data.data.count });
      } else toast.info(`Unable To Fetch Assigned Staff`);
    } catch (e) {
      toast.info('Error Fetching Assigned Staff!');
    }
  };

  render() {
    const {
      address,
      teams,
      edit,
      date,
      status,
      teamId,
      reference,
      load,
      first,
      last,
      phone,
      email,
      userAddress,
    } = this.state;
    return (
      <div
        id="edit-acct"
        className="modal fade in"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <ToastContainer />
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center" id="myModalLabel">
                Edit Installation
              </h4>
              <button type="button" className="close custom-close" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
            </div>
            <div className="modal-body">
              <form className="form-horizontal" onSubmit={this.updateStatus}>
                <div className="form-group">
                  <div className="col-12 mb-3 d-flex">
                    <div className="col-6 mb-2">
                      <label className="copy-font m-0 p-0">Address</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={this.onChange}
                        name="address"
                        value={address}
                      />
                    </div>

                    <div className="col-6">
                      <label className="copy-font m-0 p-0">Installation Status</label>
                      <select className="form-control" name="status" value={status} onChange={this.onChange}>
                        <option value="">Status</option>
                        <option value="queued">Queued</option>
                        <option value="on-going">On Going</option>
                        <option value="completed">Completed</option>
                        <option value="not completed">Not Completed</option>
                        <option value="failed">Failed</option>
                        <option value="awaiting_account_creation">Awaiting Account Creation</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-12 mb-3 d-flex">
                    <div className="col-6">
                      <label className="copy-font m-0 p-0">Payment Reference</label>
                      <input
                        className="form-control"
                        type="text"
                        name="reference"
                        value={reference}
                        onChange={this.onChange}
                      />
                    </div>

                    <div className="col-6">
                      <label className="copy-font m-0 p-0">Date</label>
                      <input className="form-control" type="date" name="date" value={date} onChange={this.onChange} />
                    </div>
                  </div>
                  {sessionStorage.getItem('departmentId') === SALES ? null : (
                    <div className="col-12 mb-3 d-flex">
                      <div className="col-6">
                        <label className="copy-font m-0 p-0">Assign To Team</label>
                        <select name="teamId" value={teamId} onChange={this.onChange}>
                          <option value="">Select Team</option>
                          {teams.length ? (
                            teams.map((tea) => (
                              <option key={tea.id} value={tea.id}>
                                {tea.name.toUpperCase()}
                              </option>
                            ))
                          ) : (
                            <option>Fetching Teams...</option>
                          )}
                        </select>
                      </div>
                    </div>
                  )}

                  <div className="col-12 mb-3 text-center">
                    {load ? (
                      <button type="button" className="btn btn-second" disabled>
                        Updating...
                      </button>
                    ) : (
                      <button type="submit" className="btn btn-second">
                        Update
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  load: state.installs.load,
});

export default connect(mapStateToProps, { editInstallation })(EditInstallation);
