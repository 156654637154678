import React from 'react';
import AssetTeamNavBar from '../../../../../layouts/asset-team/AssetTeamNavBar';
import { useUser } from '../../../../../../hooks/shared/user/useUser';
import ActiveCustomersPerZoneBody from './ActiveCustomersPerZoneBody';

const AssetTeamActiveCustomersPerZone = () => {
  const {
    profile: { firstName },
  } = useUser();

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <AssetTeamNavBar currentPage="Active Customers Per Zone" position={firstName} />
        <ActiveCustomersPerZoneBody />
      </main>
    </div>
  );
};

export default AssetTeamActiveCustomersPerZone;
