/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import loader from '../../../../assets/img/icons/TizetiLoaderColored.gif';
import helpers from '../../../../utils/helpers';
import Switch from 'react-switch';
import view from '../../../../assets/img/icons/view.png';
import edit from '../../../../assets/img/icons/edit.png';
import { NoItemRow } from '../../../../utils/loader/loadingHelper';
import relocation_helpers from '../../../../utils/relocation/relocation_helpers';
import UpdateDedicatedCustomers from './UpdateDedicatedCustomers';
import { BUSINESS_DEVELOPMENT, PROCUREMENT } from '../../../../utils/departments/helpers';
import { useCustomer } from '../../../../hooks/shared/customers/useCustomers';
import CustomerDetails from '../customers/shared/CustomerDetails';
import useInstallations from '../../../../hooks/shared/installations/useInstallations';
import EditCustomerDetails from '../../departments/customer-support/customers/EditCustomerDetails';

const BusinessDevelopmentDedicatedCustomersTable = ({ items, isLoading, filteredBy }) => {
  const colSpan = '9';
  const role_id = Number(sessionStorage.getItem('roleId'));
  const departmentID = sessionStorage.getItem('departmentId');



  const [editUserData, setEditUserData] = useState({})

  const [name, setName] = useState('');
  const [mac, setMac] = useState('');
  const [email, setMail] = useState('');

  const [request, setRequest] = useState({
    username: '',
    name: '',
    mobile: '',
    email: '',
    expiration: '',
    basestation: '',
    basestation_status: '',
    country: '',
    price: '',
    status: '',
    subscription_freq: '',
    activation_status: '',
    customer_number: '',
    to_be_decommissioned: '',
  });

  const tableData =
    items && items.length ? (
      items.map((item, i) => {
        return (
          <tr className="text-nowrap" key={i}>
            <td>{i + 1}</td>
            <td>{item.name}</td>
            <td>{item.mail}</td>
            <td>{item.zone_name}</td>
            <td>{item.base_station}</td>
            <td>{item.product}</td>
            <td>{item.employee_name}</td>
            <td>
              <>
                <button
                  type="submit"
                  className="mx-1 noBorder"
                  data-toggle="modal"
                  data-placement="left"
                  data-target="#edit-details"
                  onClick={() => {
                    setName(item.name);
                    setMail(item.mail);
                    setMac(item.username);
                    setEditUserData(item)
                  }}
                >
                  <span className="view">
                    <img src={edit} alt="" data-toggle="tooltip" data-placement="left" title="Edit" />
                  </span>
                </button>


              </>
            </td>
            {/* <td>
              <div className="d-flex">
                {departmentID === PROCUREMENT && (
                  <>
                    {role_id > 2 && role_id != 6 && filteredBy !== 'all' && (
                      <button
                        type="submit"
                        className="mx-1 noBorder"
                        data-toggle="modal"
                        data-placement="left"
                        data-target="#updateDedicated"
                        onClick={() => getRequest(item)}
                      >
                        <span className="view">
                          <img src={edit} alt="" data-toggle="tooltip" data-placement="left" title="Edit" />
                        </span>
                      </button>
                    )}
                  </>
                )}


                {departmentID === BUSINESS_DEVELOPMENT && (
                  <>
                    <button
                      type="submit"
                      className="mx-1 noBorder"
                      data-toggle="modal"
                      data-placement="left"
                      data-target="#edit-details"
                      onClick={() => {
                        setName(item.name);
                        setMail(item.email);
                        setMac(item.username);
                      }}
                    >
                      <span className="view">
                        <img src={edit} alt="" data-toggle="tooltip" data-placement="left" title="Edit" />
                      </span>
                    </button>

                    <EditCustomerDetails username={mac} oldEmail={email} oldPhone="" oldName={name} />
                  </>
                )}

                <button
                  type="submit"
                  style={{
                    border: '0',
                    backgroundColor: 'white',
                    width: '0%',
                  }}
                  data-toggle="tooltip"
                  data-placement="left"
                  title="View"
                  onClick={() => getCustomerDetails(item)}
                >
                  <span className="view1 ml-4">
                    <img src={view} alt="" data-toggle="modal" data-target="#cus-details" />
                  </span>
                </button>
              </div>
            </td> */}
            {/* <td>
              <CustomerDetails
                load={gettingDetails}
                user={item}
                name={name1}
                mail={email1}
                customerRef={customerRef}
                itemprice={itemprice}
                address={address}
                details={details}
                session={session}
                zone={zone}
                basestation={basestation1}
                installationDate={installationDate}
              />
            </td> */}

            {/* <UpdateDedicatedCustomers request={request} filteredBy={filteredBy} /> */}
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan={colSpan} text="There are no records" />
    );
  return (
    <div className="table-responsive content col-12">
      <table className="table">
        <thead>
          <tr className="">
            <th>#</th>
            <th>Name</th>
            <th className="text-wrap">Email</th>
            <th>Zone</th>
            <th>Base station</th>
            <th>Product</th>
            <th>Employee Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td className="text-center">
                <img src={loader} alt="loading gif" />
              </td>
            </tr>
          ) : (
            tableData
          )}
        </tbody>
      </table>
      <EditCustomerDetails username={mac} oldEmail={email} oldPhone="" oldName={name} item={editUserData} bizdevUser setEditUserData={setEditUserData} />
    </div>

  );
};

export default BusinessDevelopmentDedicatedCustomersTable;
