import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import { CSVLink } from 'react-csv';
import edit from '../../../../assets/img/icons/edit.png';
import IconButton from '@mui/material/IconButton';
import { ACCOUNTS, NOC, OPERATIONS } from '../../../../utils/departments/helpers';
import { styled } from '@mui/material/styles';
import DeleteDialogue from './DeleteDialogue';
import EditDialogue from './EditBasestionDialogue';
import { get_all_basestation_zones } from '../../../../actions/shared/basestations/GetAllBasestationsZones';
import Button from '@mui/material/Button';
import CreateBasestation from './CreateBasestation';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import BasestationImage from '../../../../assets/img/icons/Noc/antena2.fw.png';
import { Typography, Box } from '@mui/material';
import TextField from '@mui/material/TextField';
export default function GetAllBasestaionTable({ data, loading, getDepartmentId, roleId }) {
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rowClickedAction, setRowClickedAction] = React.useState();
  const [openDeleteDialogue, setOpenDeleteDialogue] = React.useState(false);
  const [openEditDialogue, setOpenEditDialogue] = React.useState(false);
  const [openCreateBasestationDialogue, setOpenCreateBasestationDialogue] = React.useState(false);
  const [getZones, setGetZones] = React.useState({});
  const [searchValue, setSearchValue] = React.useState('');

  React.useEffect(() => {
    const fetchZones = async () => {
      setGetZones(await get_all_basestation_zones());
    };
    fetchZones();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const formatNumber = (value) => {
    return new Intl.NumberFormat().format(value);
  };

  const columns = [
    { id: 'number', label: '#', minWidth: 70 },
    { id: 'name', label: 'Name', minWidth: 100 },
    { id: 'address', label: 'Address', minWidth: 270 },
    { id: 'installer', label: 'Installer', minWidth: 100 },
    { id: 'latitude', label: 'Latitude', minWidth: 100 },
    { id: 'longitude', label: 'Longitude', minWidth: 100 },
    { id: 'distance', label: 'Distance', minWidth: 100 },
    { id: 'zone', label: 'Zone', minWidth: 120 },
    { id: 'subzone', label: 'SubZone', minWidth: 120 },
    { id: 'dateCommisioned', label: 'DateCommisioned', minWidth: 100 },
    { id: 'rentAmount', label: 'Rent Amount', minWidth: 100 },
    { id: 'otherCharges', label: 'Other Charges', minWidth: 100 },
    { id: 'rentCommencement', label: 'Rent Commencement', minWidth: 100 },
    { id: 'rentExpiration', label: 'Rent Expiration', minWidth: 100 },
  ];

  //reason for seperating this columns from the main columns is coz i do not want u to download these columns when exporting as a csv file
  const actionColumns = [
    {
      id: 'edit',
      label: 'Edit',
      minWidth: 40,
    },
    {
      id: 'delete',
      label: 'Delete',
      minWidth: 40,
    },
  ];

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#1B3F43',
      color: theme.palette.common.white,
      padding: '0.5rem 0',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const formattedDate = (timestamp) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(timestamp).toLocaleString('en-US', options);
  };

  React.useEffect(() => {
    const mappedData = data.map((d, i) => {
      const rowObj = {
        id: d.id,
        number: i + 1,
        name: d.name,
        address: d.address,
        installer: d.installer,
        latitude: d.latitude,
        distance: formatNumber(d.distance),
        longitude: d.longitude,
        zone: d.zone !== null ? d.zone.name : 'N/A',
        zone_id: d.zone.id,
        sub_zone_id: d.sub_zone_id,
        sub_zone: d.sub_zone !== null ? d.sub_zone.name : 'N/A',
        date_of_commissioning: formattedDate(d.date_of_commissioning),
        setup_amount: +d.setup_amount === 0 || d.setup_amount === null ? 'N/A' : formatNumber(d.setup_amount),
        rent_commencement: d.rent_commencement === null ? 'N/A' : formattedDate(d.rent_commencement),
        rent_end_date: d.rent_end_date === null ? 'N/A' : formattedDate(d.rent_end_date),
        other_charges: d.other_charges === null ? 'N/A' : formatNumber(d.other_charges),
      };

      return rowObj;
    });
    setRows(mappedData);

    if (searchValue.length > 0) {
      const getQueryResult = mappedData.filter(
        (values) =>
          values.name.includes(searchValue) ||
          values.zone.includes(searchValue) ||
          values.sub_zone.includes(searchValue),
      );

      setRows(getQueryResult);
    }
  }, [data, searchValue]);

  const shouldEditBasestation = () => {
    return (
      roleId > 2 &&
      roleId !== 6 &&
      (getDepartmentId === `${NOC}` || getDepartmentId === `${OPERATIONS}` || getDepartmentId === `${ACCOUNTS}`)
    );
  };

  return (
    <div>
      {loading ? (
        <CircularProgress color="success" sx={{ position: 'relative', left: '50%', top: '10rem' }} />
      ) : (
        <>
          <Tooltip title="Basestation Count">
            <Badge badgeContent={rows.length} color="success" max={999}>
              <img src={BasestationImage} alt="img" />
            </Badge>
          </Tooltip>

          <CSVLink data={rows} filename={'All-Basestations.csv'}>
            {' '}
            <button className="btn btn-green my-5 ml-3">Download Data</button>
          </CSVLink>
          {getDepartmentId === NOC || ACCOUNTS ? (
            <Button
              variant="contained"
              sx={{ ml: 3, textTransform: 'capitalize' }}
              onClick={() => {
                setOpenCreateBasestationDialogue(true);
              }}
            >
              Add Basestation
            </Button>
          ) : null}

          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="standard-search"
              label="Search field"
              type="search"
              variant="standard"
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
          </Box>

          <Paper sx={{ width: '100%', minHeight: '90vh', overflow: 'hidden' }}>
            <TableContainer sx={{ minHeight: '100%' }}>
              <Table stickyHeader aria-label="All Base Stations Table" size="small">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <StyledTableCell
                        key={column.id}
                        align="center"
                        style={{ minWidth: column.minWidth, fontWeight: 'bolder' }}
                      >
                        {column.label}
                      </StyledTableCell>
                    ))}
                    {/* noc and operations have certain edit features */}
                    {shouldEditBasestation() && (
                      <StyledTableCell
                        align="center"
                        style={{ minWidth: actionColumns[0].minWidth, fontWeight: 'bolder' }}
                      >
                        {actionColumns[0].label}
                      </StyledTableCell>
                    )}

                    {/* only noc can delete a basestation */}
                    {getDepartmentId === `${NOC}` && (
                      <StyledTableCell
                        align="center"
                        style={{ minWidth: actionColumns[0].minWidth, fontWeight: 'bolder' }}
                      >
                        {actionColumns[1].label}
                      </StyledTableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      <TableCell>{row.number}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.address}</TableCell>
                      <TableCell>{row.installer}</TableCell>
                      <TableCell>{row.latitude}</TableCell>
                      <TableCell>{row.longitude}</TableCell>
                      <TableCell>{row.distance}</TableCell>
                      <TableCell>{row.zone}</TableCell>
                      <TableCell>{row.sub_zone}</TableCell>
                      <TableCell>{row.date_of_commissioning}</TableCell>
                      <TableCell>{row.setup_amount}</TableCell>
                      <TableCell>{row.other_charges}</TableCell>
                      <TableCell>{row.rent_commencement}</TableCell>
                      <TableCell>{row.rent_end_date}</TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="edit"
                          onClick={() => {
                            setRowClickedAction(row);
                            setOpenEditDialogue(true);
                          }}
                        >
                          {shouldEditBasestation() && <img src={edit} alt="edit icon" />}
                        </IconButton>
                      </TableCell>

                      {getDepartmentId === `${NOC}` && (
                        <TableCell>
                          <IconButton
                            aria-label="delete"
                            onClick={() => {
                              setRowClickedAction(rows[row.number - 1]);
                              setOpenDeleteDialogue(true);
                            }}
                          >
                            <img
                              src="https://res.cloudinary.com/teewhy/image/upload/v1543579893/delete.png"
                              alt="delete icon"
                            />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100, { label: 'All', value: -1 }]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          <DeleteDialogue
            deleteAction={rowClickedAction}
            openDeleteDialogue={openDeleteDialogue}
            setOpenDeleteDialogue={setOpenDeleteDialogue}
          />
          <EditDialogue
            editAction={rowClickedAction}
            openEditDialogue={openEditDialogue}
            setOpenEditDialogue={setOpenEditDialogue}
            getDepartmentId={getDepartmentId}
            getZones={getZones}
            roleId={roleId}
          />

          {getDepartmentId === NOC || ACCOUNTS ? (
            <CreateBasestation
              openCreateBasestationDialogue={openCreateBasestationDialogue}
              setOpenCreateBasestationDialogue={setOpenCreateBasestationDialogue}
              getZones={getZones}
            />
          ) : null}
        </>
      )}
    </div>
  );
}
