import React from 'react';
import Moment from 'react-moment';

const ViewPurchaseOrder = ({
  order: { item, quantity, comment, manager_approval, manager_approval_timestamp, manager_comment },
}) => {
  return (
    <div id="viewPO" className="modal fade mt-5" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title my-2">Order Details</h4>
            <button type="button" className="mr-2 close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div className="modal-body detail p-0">
            <div className="row">
              <div className="col-lg-12 d-flex">
                <div className="col-lg-5 detail-label1">
                  <label className="mt-1">Order Name</label>
                  <label className="mt-1">Quantity</label>
                  <label className="mt-1">Comment</label>
                </div>
                <div className="col-lg-7 detail-result1 item-font">
                  <p className="mt-4 mb-2">{item}</p>
                  <p className="mb-2">{quantity}</p>
                  <p className="mb-2">{comment || 'NO COMMENT'}</p>
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-5 detail-label2">
                  <label>Manager Approval</label>
                  <label className="mt-1">Manager Timestamp</label>
                  <label className="mt-1">Manager Comment</label>
                </div>
                <div className="col-lg-7 detail-result2 item-font">
                  <p className="mt-4 mb-1 text-capitalize">{manager_approval}</p>
                  <p className="mb-2">
                    {manager_approval_timestamp ? (
                      <Moment format="DD-MM-YYYY hh:mm" date={manager_approval_timestamp} />
                    ) : (
                      'N/A'
                    )}
                  </p>
                  <p className="mb-2">{manager_comment || 'NO COMMENT'}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPurchaseOrder;
