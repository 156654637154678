/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import OpenAndBlockedUsersProvider from './context/OpenAndBlockedUsersProvider';
import OpenAndBlockedUsersBody from './OpenAndBlockedUsersBody';
import WifiCallSidebar from '../../../../layouts/wificall-sidebar/WifiCallSidebar';
import PageName from '../../../shared/layouts/PageName';
import Logout from '../../../shared/auth/Logout';
import { useUser } from '../../../../../hooks/shared/user/useUser';

const OpenAndBlockedUsers = () => {
  const {
    profile: { firstName },
  } = useUser();
  return (
    <div className="s-layout">
      <OpenAndBlockedUsersProvider>
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <Link className="s-sidebar__trigger" to="#">
                <i className="fa fa-bars mr-3" />
              </Link>
              <WifiCallSidebar />
              <PageName name="Active & Blocked Users" />
              <div className="d-flex">
                <h6 className="mr-0 mt-1">
                  {firstName}
                  <Logout />
                </h6>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row mt-5">
              <div className="mt-4 col-lg-12 col-md-12 col-sm-12">
                <div className="container">
                  <OpenAndBlockedUsersBody />
                </div>
              </div>
            </div>
          </div>
        </main>
        <ToastContainer />
      </OpenAndBlockedUsersProvider>
    </div>
  );
};

export default OpenAndBlockedUsers;
