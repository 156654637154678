/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import loader from '../../../../assets/img/icons/TizetiLoaderColored.gif';
import { usePurchaseOrder } from '../../../../hooks/shared/purchase-order/usePurchaseOrder';
import PurchaseOrderTable from './PurchaseOrderTable';

const EmployeePurchaseOrderBody = () => {
  const { getStaffPurchaseOrder, isLoading, lists, tracker, pages } = usePurchaseOrder();
  const [page] = useState(1);

  useEffect(() => {
    getStaffPurchaseOrder(page);
  }, []);

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    getStaffPurchaseOrder(selectedPage);
  };

  return (
    <div className="card x-panel p-0">
      <div className="table-responsive content col-12 px-0">
        <table className="table table-striped">
          <thead>
            <tr className="">
              <th>S/N</th>
              <th>Item</th>
              <th>Quantity</th>
              <th>Comment</th>
              <th>Manager Approval</th>
              <th>Procurement Approval</th>
              <th>CFO Approval</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="8" className="text-center">
                  <img src={loader} alt="loading..." />
                </td>
              </tr>
            ) : (
              <PurchaseOrderTable lists={lists} tracker={tracker} />
            )}
          </tbody>
        </table>
      </div>
      <div className="px-3">
        {pages > 1 ? (
          <ReactPaginate
            previousLabel="previous"
            nextLabel="next"
            breakLabel="..."
            breakClassName="break-me"
            pageCount={pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            subContainerClassName="pages pagination"
            activeClassName="active"
          />
        ) : null}
      </div>
    </div>
  );
};

export default EmployeePurchaseOrderBody;
