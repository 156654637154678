export function createData(name, ...values) {
  return { name, values };
}

export const years = ['2023', '2024'];
export const kpiPerMonthYears = ['2023'];
export const turboConnectYears = ['2023'];
export const bizDevYears = ['2023'];

export const TableCellMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

export const states = [
  'Lagos',
  'Abuja',
  'Ogun',
  'Enugu',
  'Delta',
  'Rivers',
  'Edo',
  'Oyo',
  'Kano',
  'Kaduna',
  'Imo',
  'Abia',
  'Anambra',
];
export const mockData = [
  {
   zones: [  'Magodo',
   'Eleganza',
   'Lekki Gardens',
   'Festac',
   'Gbagada',
   'Akoka',
   'Surulere',
   'Kano',
   'Treasure',
   'Kaduna',
   'Agungi',
   'Oshodi',
   'Ikorodu',
   'Ikeja',
   'Awolowo',
   'Abia',
   'Abuja',
   'Delta',
   'Rivers',
   'Enugu',
   'Imo',
   'Ogun',
   'Oyo',
   'Anambra',
   'Edo',],
   september: [1100, 300, 500, 600, 387, 498, 287, 900, 800, 789, 456, 876, 875, 324,765,234,758, 987, 267,800,657,876,435, 700, 657],
   october: [700, 100, 400, 900, 287, 198, 187, 500, 200, 489, 256, 476, 375, 124,865,134,558, 587, 67,500,357,476,235, 300, 357],
  }
 ]
export const mockExpiredData = [{
   zones: [  'Magodo',
   'Eleganza',
   'Lekki Gardens',
   'Festac',
   'Gbagada',
   'Akoka',
   'Surulere',
   'Kano',
   'Treasure',
   'Kaduna',
   'Agungi',
   'Oshodi',
   'Ikorodu',
   'Ikeja',
   'Awolowo',
   'Abia',
   'Abuja',
   'Delta',
   'Rivers',
   'Enugu',
   'Imo',
   'Ogun',
   'Oyo',
   'Anambra',
   'Edo',],
   september: [1100, 300, 500, 600, 387, 498, 287, 900, 800, 789, 456, 876, 875, 324,765,234,758, 987, 267,800,657,876,435, 700, 657],
   october: [700, 100, 400, 900, 287, 198, 187, 500, 200, 489, 256, 476, 375, 124,865,134,558, 587, 67,500,357,476,235, 300, 357],
  }
 ]
export const capitalizedStates = states.map((state) => state.charAt(0).toUpperCase() + state.slice(1));
