import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Box from '@mui/material/Box';
import { TableCellMonths } from '../components/tempData';
export default function BarCharts({ data, isLoading, isTotalActiveSubsChecked, isTotalExpiredChecked }) {
  const [getData, setGetData] = React.useState([]);

  React.useEffect(() => {
    if (data.length > 0) {
      const refinedData = TableCellMonths.map((months, index) => ({
        name: months,
        'Total active / paying subs': data[0].values[index],
        'Total number of new Installs': data[1].values[index],
        'Total Expired (actual)': data[2].values[index],
      }));

      setGetData(refinedData);
    }
  }, [data]);

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flexStart">
      {!isLoading && (
        <ResponsiveContainer width="100%" height={600}>
          <BarChart
            data={getData}
            margin={{
              top: 5,
              right: 10,
              left: 0,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />

            <Bar type="monotone" dataKey="Total number of new Installs" fill="#8884d8" />
            {isTotalActiveSubsChecked && <Bar type="monotone" dataKey="Total active / paying subs" fill="#d88492" />}
            {isTotalExpiredChecked && <Bar type="monotone" dataKey="Total Expired (actual)" fill="#b3864c" />}
          </BarChart>
        </ResponsiveContainer>
      )}
    </Box>
  );
}
