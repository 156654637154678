/* eslint-disable import/prefer-default-export */
import { useReducer, useState } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import * as load from '../../../constants/types/shared/LoadingTypes';
import { GET_REFERRAL_REQUEST } from '../../../constants/types/sales/customers/ReferralTypes';
import { GET_FEEDBACK_URL } from '../../../services/api/customer-support/feedback/FeedbackUrl';
import { feedbackReducer } from '../../../reducers/customer-support/feedback/feedbackReducer';

export const useFeedback = () => {
  const [{ lists, count, pages, isLoading }, dispatch] = useReducer(feedbackReducer, {
    lists: [],
    count: 1,
    pages: 1,
    isLoading: false,
  });

  const getFeedback = async (selectedPage) => {
    dispatch({ type: load.LOADING_STARTS });
    const config = {
      method: 'post',
      url: `${GET_FEEDBACK_URL}/${selectedPage}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      }
    };
    axios(config)
      .then((response) => {
        if (response.data) {
          console.log(response.data.data);
          dispatch({
            type: GET_REFERRAL_REQUEST,
            payload: {
              lists: response.data.rows,
              count: response.data.count,
              //   pages: response.data.pages,
            },
          });
        }
      })
      .catch(() => {
        toastr.info('Something went wrong!');
        dispatch({ type: load.LOADING_STOPS });
      });
  };

  return {
    lists,
    pages,
    count,
    isLoading,
    getFeedback,
  };
};
