import React, { useEffect, useState } from 'react';

import { NoItemRow, TizetiGIFLogoTable } from '../../../../utils/loader/loadingHelper';
import { usePaystack } from '../../../../hooks/customer-support/paystack-transactions/usePaystack';
import { Modal } from 'react-bootstrap';

const PaystackTransactionsTable = ({ lists, isLoading, getTransactions, reloadGetTransactions }) => {
  const colSpan = 12;
  const { editPaystackTransactions, payload, loader } = usePaystack();

  const [view, setView] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const [comment, setComment] = useState('');
  const [cust, setCust] = useState({ id: null, name: '' });

  useEffect(() => {
    getTransactions(payload);
  }, []);

  const tableData =
    lists && lists.length ? (
      lists.map((list, i) => {
        return (
          <tr className="" key={i}>
            <td>{i + 1}</td>
            <td className="text-nowrap">{`${list.name}`}</td>
            <td>{list.email}</td>
            <td>{list.phone}</td>
            <td>{list.reference}</td>
            <td>{list.status}</td>
            <td>{list.amount}</td>
            <td className="text-nowrap">{new Date(list.date).toLocaleString()}</td>
            <td>
              {list.called ? (
                <span className="p-1 badge badge-pill badge-success text-sm">called</span>
              ) : (
                <span className="p-1 badge badge-pill badge-danger text-sm">not called</span>
              )}
            </td>
            <td className="text-nowrap">{new Date(list.exp_date).toLocaleDateString()}</td>

            {list.comment ? (
              <td>
                <button
                  className="btn btn-gray"
                  type="button"
                  data-placement="right"
                  data-toggle="modal"
                  data-target="#comment_modal_tab"
                  onClick={() => {
                    setComment(list.comment);
                    setShowComment(true);
                  }}
                >
                  comment
                </button>
              </td>
            ) : (
              <span className="mx-auto my-auto">N/A</span>
            )}

            <td>
              <button
                disabled={list.called}
                className="view btn btn-light btn-sm"
                style={{ cursor: list.called ? 'not-allowed' : 'pointer' }}
                onClick={() => {
                  setView(true);
                  setCust({ id: list.id, name: list.name });
                }}
              >
                <img
                  src="https://res.cloudinary.com/teewhy/image/upload/v1543579893/edit.png"
                  data-toggle="tooltip"
                  data-placement="right"
                  alt="delete"
                  title="Delete"
                />
              </button>
            </td>
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan={colSpan} text="No Records Found..." />
    );

  return (
    <div className="table-responsive content col-12 mt-4">
      <table className="table table-striped">
        <thead>
          <tr className="">
            <th> S/N</th>
            <th className="text-nowrap">Customer Name</th>
            <th className="text-nowrap">Email</th>
            <th className="text-nowrap">Phone</th>
            <th className="text-nowrap"> Reference</th>
            <th className="text-nowrap"> Transaction status</th>
            <th className="text-nowrap"> Amount</th>
            <th className="text-nowrap"> Transaction Date</th>
            <th className="text-nowrap"> Called</th>
            <th className="text-nowrap">Account Expiration Date</th>
            <th className="text-nowrap">Comment</th>
            <th className="text-nowrap"></th>
          </tr>
        </thead>
        <tbody>{isLoading ? <TizetiGIFLogoTable colSpan={colSpan} /> : tableData}</tbody>
      </table>
      <EditFailedPaystack
        view={view}
        setView={setView}
        editPaystackTransactions={editPaystackTransactions}
        loader={loader}
        cust={cust}
        reloadGetTransactions={reloadGetTransactions}
        payload={payload}
      />
      <ViewComment showComment={showComment} setShowComment={setShowComment} comment={comment} />
    </div>
  );
};

export default PaystackTransactionsTable;

const EditFailedPaystack = ({
  view,
  setView,
  editPaystackTransactions,
  loader,
  cust,
  reloadGetTransactions,
  payload,
}) => {
  const [comment, setComment] = useState('');
  const handleClose = () => {
    setView(false);
  };
  return (
    <Modal show={view} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ paddingLeft: '2rem' }}>Confirm Call</Modal.Title>
      </Modal.Header>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          editPaystackTransactions(cust.id, handleClose, reloadGetTransactions, payload, comment);
        }}
        className="col-md-12"
      >
        <h5 className="text-center mt-3">
          Click on button bellow to confirm <strong>{cust.name}</strong> has been called
        </h5>

        <div className="col-12 mb-3 mt-3 ">
          <h6 className="copy-font ml-0"> Add Comment</h6>
          <textarea
            name="comment"
            id="comment"
            className="form-control mx-auto"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            rows={3}
            required
          ></textarea>
        </div>

        <div className="text-center my-3">
          {loader ? (
            <button type="button" className="btn btn-green py-2 px-5 p-28" disabled>
              Confirming...
            </button>
          ) : (
            <button type="submit" className="btn btn-green py-2 px-5 p-2">
              Confirm
            </button>
          )}
        </div>
      </form>
    </Modal>
  );
};

const ViewComment = ({ comment, showComment, setShowComment }) => {
  const handleClose = () => {
    setShowComment(false);
  };
  return (
    <Modal show={showComment} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ paddingLeft: '2rem' }}>Comment</Modal.Title>
      </Modal.Header>
      <h6 className=" m-3 mt-3">{comment}</h6>
    </Modal>
  );
};
