import React from 'react';
import useDummy from '../hooks/useDummy';
import DummyContext from './DummyContext';

const DummyProvider = ({ children }) => {
  const { data, loading, paginateChange, loadingForm, onChange, formValue, onSubmit, pages } = useDummy();
  return (
    <DummyContext.Provider
      value={{
        data,
        loading,
        paginateChange,
        loadingForm,
        onChange,
        formValue,
        onSubmit,
        pages,
      }}
    >
      {children}
    </DummyContext.Provider>
  );
};
export default DummyProvider;
