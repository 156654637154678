import React from 'react';
import { ToastContainer } from 'react-toastify';
import NocNavBar from '../../../../../layouts/noc/NocNavBar';
import IpAllocationBody from './IpAllocationBody';
import useCheckTwoDepartments from '../../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';
import { admin, NOC } from '../../../../../../utils/config';
import { useUser } from '../../../../../../hooks/shared/user/useUser';
import IpAllocationProvider from './provider/IpAllocationProvider';

const IpAllocation = ({ history }) => {
  const { profile } = useUser();
  const { firstName } = profile;
  useCheckTwoDepartments(`${NOC}`, `${admin}`, history);
  return (
    <div className="s-layout">
      <IpAllocationProvider>
        <main className="s-layout__content col-lg-10 p-0">
          <NocNavBar currentPage="NOC Sheet - IP Allocation" position={firstName} />
          <div className="container">
            <div className="row mt-5">
              <div className="mt-4 col-lg-12 col-md-12 col-sm-12">
                <IpAllocationBody />
              </div>
            </div>
          </div>
        </main>
        <ToastContainer />
      </IpAllocationProvider>
    </div>
  );
};

export default IpAllocation;
