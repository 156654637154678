import React from 'react';

export default {
  showRequestApprovalStatus(status) {
    if (status === 'pending') {
      // return <small>Approved</small>;
      return (
        <span className="badge badge-primary" style={{ backgroundColor: '#FFA800' }}>
          {status}
        </span>
      );
    }
    if (status === 'rejected') {
      return (
        <span className="badge badge-danger" style={{ backgroundColor: 'red' }}>
          {status}
        </span>
      );
    }

    if (status === 'approved') {
      return (
        <span className="badge badge-success" style={{ backgroundColor: 'green' }}>
          {status}
        </span>
      );
    }

    if (status === 'resolved') {
      return (
        <span className="badge badge-success" style={{ backgroundColor: 'green' }}>
          {status}
        </span>
      );
    }
    return null;
  },
};
