/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import useInstallationOverview from '../../../../../hooks/field-support/installation/leads/overview/useInstallationOverview';
import useGetEmployees from '../../field-support/installations/hooks/useGetEmployees';

const AssignInstallationToStaff = ({ installationID }) => {
  const { employeesInFieldSupport } = useGetEmployees();
  const { loadingForm, assignInstallation, anInstallation, getAnInstallation } = useInstallationOverview();

  const [assignedEmployee, setAssignedEmployee] = useState('');

  const { date } = anInstallation;

  useEffect(() => {
    if (installationID) getAnInstallation(installationID);
  }, [installationID]);

  const assignInstall = () => {
    if (date) {
      const params = { employee_id: assignedEmployee, date };
      assignInstallation(params, installationID).then(() => setAssignedEmployee(''));
    }
  };

  return (
    <div>
      <form className="form-horizontal">
        <div className="form-group">
          <div className="col-12 mb-3 d-flex">
            <div className="col-12 mb-2">
              <label className="copy-font m-0 p-0">Assign To Employee(s)</label>
              <select
                className="form-control"
                name="assignedEmployee"
                value={assignedEmployee}
                onChange={(e) => setAssignedEmployee(e.target.value)}
              >
                <option value="">--Select Employee--</option>
                {employeesInFieldSupport && employeesInFieldSupport
                  ? employeesInFieldSupport.map((employee) => (
                      <option
                        key={employee.employees[0].id}
                        value={employee.employees[0].id}
                      >{`${employee.employees[0].firstName} ${employee.employees[0].lastName}`}</option>
                    ))
                  : null}
              </select>
            </div>
          </div>
          <div className="col-12 mb-3 text-center">
            {loadingForm ? (
              <button type="button" className="btn btn-info" disabled>
                Assigning...
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-info"
                onClick={() => assignInstall()}
                disabled={assignedEmployee.length <= 0}
              >
                Assign
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default AssignInstallationToStaff;
