/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PATHS from '../../../routes/urls';
import RequisitionNotification from '../../staff/shared/layouts/sidebar/manager-and-executives/RequisitionNotification';
import AssetTeamSideBarItem from '../asset-team/sidebar/AssetTeamSideBarItem';
import { AES, enc } from 'crypto-js';
import { useUser } from '../../../hooks/shared/user/useUser';

const BusinessDevelopmentSideBar = () => {

  const userCountry = sessionStorage.getItem('country');
  const email = sessionStorage.getItem('officialEmail');
  const role_id = Number(sessionStorage.getItem('roleId'));
  const detailsState = {
    name: 'React',
    messagetoken: sessionStorage.getItem('token'),
    messagerole: sessionStorage.getItem('roleId'),
    messagedepartment: sessionStorage.getItem('departmentId'),
    secret: 'Wifirocks123',
    cipher: '',
  };
  const [details, setDetails] = useState(detailsState);
  const { name, messagetoken, messagerole, messagedepartment, secret, cipher } = details;

  const obj = {
    login: messagetoken,
    pass: messagerole,
    department: messagedepartment,
  };

  const ciph = AES.encrypt(JSON.stringify(obj), secret).toString();

  const checkCountry = () => {
    if (userCountry === 'GH') {
      return (
        <li className="s-sidebar__nav-link">
          <Link to={PATHS.BUS_DEV_GHANA_REQUISITION} target="_self" className="d-flex">
            <span>
              <img src="https://res.cloudinary.com/teewhy/image/upload/v1550844527/requisition.png" alt="img" />
            </span>
            Ghana Requisitions
            {email === 'lydia.asogwa@ghanawifi.com' && Number(`${sessionStorage.getItem('roleId')}`) === 3 && (
              <RequisitionNotification />
            )}
          </Link>
        </li>
      );
    }
    return (
      <li className="s-sidebar__nav-link">
        <Link to={PATHS.BUS_DEV_NIGERIA_REQUISITION} target="_self" className="d-flex">
          <span>
            <img src="https://res.cloudinary.com/teewhy/image/upload/v1550844527/requisition.png" alt="img" />
          </span>
          Requisition
          {Number(`${sessionStorage.getItem('roleId')}`) === 11 && <RequisitionNotification />}
        </Link>
      </li>
    );
  };

  return (
    <div className="s-layout__sidebar">
      <Link className="s-sidebar__trigger" to="#">
        <i className="fa fa-bars mr-3" />
      </Link>
      <nav className="s-sidebar__nav">
        <Link className="navbar-brand-logo" to="/business-development">
          <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971047/Tizeti-Logo.png" alt="homepage" />
        </Link>
        <ul>
          <span className="p-0 mt-3">
            <AssetTeamSideBarItem url="/business-development" name="Overview" />
          
              <li className="s-sidebar__nav-link">
                {' '}
                <a href={`https://os.tizeti.com/main/#/overview?ciph=${ciph}`} target="_blank" className="make-bold">
                  <span>
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923169/configr_d.fw.png" alt="img" />
                  </span>
                  <small>New system (Beta v2.0)</small>
                </a>
              </li>
      
            <span className="s-sidebar__nav-link d-none d-md-block" data-toggle="collapse" data-target="#active">
              <AssetTeamSideBarItem url="#" name="CUSTOMERS" image="" />
            </span>
            <span id="active" className="collapse base-li top-bottom">
              <AssetTeamSideBarItem url="#" name="Non-Dedicated Customers" />
              <AssetTeamSideBarItem url="/business-development/dedicated-customers" name="Dedicated Customers" />
              <AssetTeamSideBarItem url="/business-development/call-card" name="Call Card" />
              <AssetTeamSideBarItem url="/business-development/delisted-customers" name="Delisted Customers" />
              <AssetTeamSideBarItem url="/business-development/bizdev-users" name="Bizdev Users" />
            </span>
            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/business-development/logs" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1544459675/call_log_icon.png" alt="img" />
                </span>
                <small>Logs</small>
              </Link>
            </li>
            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/business-development/usage-history" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-4.png" alt="img" />
                </span>
                <small>Usage History</small>
              </Link>
            </li>
            <AssetTeamSideBarItem url="/business-development/invoice" name="Invoice" />
            <AssetTeamSideBarItem url="/business-development/kpi-monitor" name="Kpi Monitor" />
            {/* <AssetTeamSideBarItem
              url="/business-development/connected-dedicated-customers"
              name="Connected Dedicated Customers"
            /> */}
            <AssetTeamSideBarItem url="/business-development/basestation-status" name="Basestation Status" />
            <AssetTeamSideBarItem url="/business-development/customer-revenue" name="Customer Revenue" />
            <AssetTeamSideBarItem url={PATHS.BUSINESS_DEVELOPMENT_PAYSLIP} name="Payslip" />
            {checkCountry()}
          </span>
        </ul>
      </nav>
    </div>
  );
};

export default BusinessDevelopmentSideBar;
