/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable import/no-named-as-default */
/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import moment from 'moment';
import AssetTeamInstallationsTableBody from './AssetTeamInstallationsTableBody';
import useFetch from '../../../../../hooks/shared/custom/data/useFetch';
import { GET_SUBZONES_URL } from '../../../../../services/api/shared/installations/SchedulerUrl';
import helpers from '../../../../../utils/helpers';
import useInstallations from '../../../../../hooks/shared/installations/useInstallations';

const AssetTeamInstallationsBody = () => {
  const { data } = useFetch(`${GET_SUBZONES_URL}`);
  const [to] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const { searchInstallations, isLoading, installations } = useInstallations();
  const state = { frequency: '', sub: '' };
  const [search, setSearch] = useState(state);
  const onChange = (e) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
  };
  const { frequency } = search;
  const onSubmit = () => {
    if (frequency === 'daily') {
      searchInstallations({
        sub_zone_id: search.sub,
        status: 'completed',
        from: helpers.formatDate(
          moment()
            .subtract(1, 'days')
            .format(),
        ),
        to,
      });
    }
    if (frequency === 'weekly') {
      searchInstallations({
        sub_zone_id: search.sub,
        status: 'completed',
        from: helpers.formatDate(
          moment()
            .subtract(7, 'days')
            .format(),
        ),
        to,
      });
    }
    if (frequency === 'monthly') {
      searchInstallations({
        sub_zone_id: search.sub,
        status: 'completed',
        from: helpers.formatDate(
          moment()
            .subtract(1, 'month')
            .format(),
        ),
        to,
      });
    }
  };
  return (
    <div className="container">
      <div className="row mt-5">
        <div className="mt-4 col-lg-12 col-md-12 col-sm-12">
          <div className="text-right">
            <div className="d-none d-md-flex">
              <form onSubmit={(e) => e.preventDefault()} className="form-inline inner-addon left-addon d-flex">
                <p className="fliter copy-font d-flex">
                  <span>
                    <select className="form-control" name="frequency" onChange={onChange}>
                      <option value="">Select Daily/Weekly/Monthly</option>
                      <option value="daily">Daily</option>
                      <option value="weekly">Weekly</option>
                      <option value="monthly">Monthly</option>
                    </select>
                  </span>
                </p>
                <p className="fliter copy-font d-flex">
                  <span>
                    <select className="form-control" name="sub" onChange={onChange}>
                      <option value="">Select Zones</option>
                      {data && data.length ? data.map((zone) => <option value={zone.id}>{zone.name}</option>) : null}
                    </select>
                  </span>
                </p>
                <button onClick={onSubmit} className="btn btn-green ml-3 w-25">
                  Search
                </button>
              </form>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="card x-panel mt-3">
                <div className="table-responsive content">
                  <table className="table copy-font">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>User Name</th>
                        <th>User Email</th>
                        <th>User Phone</th>
                        <th>User Address</th>
                        <th>Installed Address</th>
                        <th>Payment Ref</th>
                        <th>Status</th>
                        <th>Nearest Basestation</th>
                      </tr>
                    </thead>
                    <AssetTeamInstallationsTableBody loading={isLoading} data={installations} />
                  </table>
                </div>
              </div>
            </div>
            {/* <div className="pagination pull-right"> */}
            {/*  <ReactPaginate */}
            {/*    previousLabel="previous" */}
            {/*    nextLabel="next" */}
            {/*    breakLabel="..." */}
            {/*    breakClassName="break-me" */}
            {/*    //  pageCount={pageCount} */}
            {/*    pageCount="2" */}
            {/*    marginPagesDisplayed={2} */}
            {/*    pageRangeDisplayed={3} */}
            {/*    //  onPageChange={this.handlePageClick} */}
            {/*    containerClassName="pagination" */}
            {/*    subContainerClassName="pages pagination" */}
            {/*    activeClassName="active" */}
            {/*  /> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetTeamInstallationsBody;
