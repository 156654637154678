import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Box from '@mui/material/Box';

const LineCharts = ({ data, isLoading }) => {

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flexStart">
      {!isLoading && (
        <ResponsiveContainer width="100%" height={500}>
        <LineChart
          data={data}
          margin={{
            top: 5,
            right: 10,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="state" />
          <YAxis />
          <Tooltip />
          <Legend />

          <Line type="monotone" dataKey="percentage" fill="#8884d8" />
        </LineChart>
        </ResponsiveContainer>
      )}
    </Box>
  );
};

export default LineCharts;
