import React from 'react';
import { useUser } from '../../../../hooks/shared/user/useUser';
import { get_all_basestations_read_only } from '../../../../actions/shared/basestations/GetAllBasestations';
import GetAllBasestaionTable from './GetAllBasestaionTable';
import { OPERATIONS, NOC, ACCOUNTS } from '../../../../utils/departments/helpers';
import AccountsNavBar from '../../../layouts/accounts/AccountsNavBar';
import FieldSupportNavBar from '../../../layouts/field-support/FieldSupportNavBar';
import NocNavBar from '../../../layouts/noc/NocNavBar';

export default function AllBasestation() {
  const { user, profile } = useUser();
  const [isLoading, setIsLoading] = React.useState(false);
  const [getAllBaseStations, setGetAllBaseStations] = React.useState([]);

  React.useEffect(() => {
    setIsLoading(true);

    async function fetchBaseStationData() {
      try {
        const getData = await get_all_basestations_read_only();
        setGetAllBaseStations(getData);
        setIsLoading(false);
        // console.log(getData);
      } catch (e) {
        console.error(e.message);
        setIsLoading(false);
      }
    }

    fetchBaseStationData();
  }, []);

  // console.log(useUser());
  const getDepartmentId = sessionStorage.getItem('departmentId');
  const role_id = +sessionStorage.getItem('roleId');

  const accountAllowedEmails = ['amanosi.okhakhu@tizeti.com', 'financeDept@tizeti.com', 'hakeem.balogun@tizeti.com'];

  return (
    <div>
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          {getDepartmentId === `${ACCOUNTS}` && accountAllowedEmails.includes(profile.email) ? (
            <AccountsNavBar currentPage="All BaseStations" position={profile.firstName} role_id={user.role_id} />
          ) : getDepartmentId === `${OPERATIONS}` ? (
            <FieldSupportNavBar currentPage="All Basestations" position={profile.firstName} role_id={user.role_id} />
          ) : (
            getDepartmentId === `${NOC}` && (
              <NocNavBar currentPage="All Basestations" position={profile.firstName} role_id={user.role_id} />
            )
          )}

          <div className="container">
            <div className="row mt-5">
              <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-4">
                <div>
                  <GetAllBasestaionTable
                    data={getAllBaseStations}
                    loading={isLoading}
                    getDepartmentId={getDepartmentId}
                    roleId={role_id}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
