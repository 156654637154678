/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from 'react';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../utils/loader/loadingHelper';
import edit from '../../../../assets/img/icons/edit.png';
import KpiDetails from './UpdateKpiDetails';
import kpihelpers from '../../../../utils/kpi/kpihelpers';
// import view from '../../../../assets/img/icons/view.png';
// import useKpiMonitor from './hooks/useKpiMonitor';

const KpisMonitorTable = ({ kpis, isLoading }) => {
  const [request, setRequest] = useState({
    id: '',
    name: '',
    description: '',
    start_date: '',
    end_date: '',
    EmployeeAuth: '',
    status: '',
    percentage_completion: '',
  });
  const getRequest = (kpi) => setRequest(kpi);

  const checkRoleForTableHead = () => {
    if (Number(sessionStorage.getItem('roleId')) === 3 && Number(sessionStorage.getItem('roleId')) !== 6) {
      return <th>Action</th>;
    }
    return null;
  };
  const checkRoleForTableBody = (kpi) => {
    if (Number(sessionStorage.getItem('roleId')) === 3 && Number(sessionStorage.getItem('roleId')) !== 6) {
      return (
        <>
          <td>
            <div className="d-flex">
              <button
                type="submit"
                className="mx-1 noBorder"
                data-toggle="modal"
                data-placement="left"
                data-target="#kpi-details"
                onClick={() => getRequest(kpi)}
              >
                <span className="view">
                  <img src={edit} alt="" data-toggle="tooltip" data-placement="left" title="Edit" />
                </span>
              </button>
            </div>
          </td>
          <KpiDetails request={request} />
        </>
      );
    }
    return null;
  };
  const kpiData = () => {
    if (kpis && kpis.length > 0) {
      return kpis.map((kpi, i) => (
        <tr>
          <td>{i + 1}</td>
          <td>{kpi.name}</td>
          <td>{kpi.description}</td>
          <td>{moment(kpi.start_date).format('DD-MM-YYYY')}</td>
          <td>{moment(kpi.end_date).format('DD-MM-YYYY')}</td>
          <td>{kpi.status}</td>
          {/* <td>{kpi.percentage_completion}</td> */}
          <td>{kpihelpers.kpiStatuslabels(kpi.percentage_completion)}</td>
          <td>{`${kpi.EmployeeAuth.employees[0].firstName} ${kpi.EmployeeAuth.employees[0].lastName}`}</td>
          {checkRoleForTableBody(kpi)}
        </tr>
      ));
    }
    return <NoItemRow colSpan="6" text="No Kpis" />;
  };

  return (
    <>
      <div className="col-lg-12 p-0">
        <div className="card x-panel">
          <div className="table-responsive content">
            <table className="table copy-font">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Status</th>
                  <th>Percentage Completion</th>
                  <th>Name</th>
                  {checkRoleForTableHead()}
                </tr>
              </thead>
              {isLoading ? <TizetiGIFLogoTable colSpan="8" /> : <tbody>{kpiData()}</tbody>}
            </table>
          </div>
        </div>
      </div>
      {!isLoading && (
        <ReactPaginate
          previousLabel="previous"
          nextLabel="next"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={kpis.pages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          // onPageChange={this.handlePageClick}
          containerClassName="pagination"
          // subContainerClassName="pages pagination"
          activeClassName="active"
        />
      )}
    </>
  );
};

export default KpisMonitorTable;
