import React from 'react';
import useSubBasestation from '../hooks/useSubBasestation';
import AppContext from '../../../../../../../context/shared/AppContext';

const SubBasestationProvider = ({ children }) => {
  const { loadingForm, onChange, formValue, onSubmit, data, loading } = useSubBasestation();
  return (
    <AppContext.Provider value={{ loadingForm, onChange, formValue, onSubmit, data, loading }}>
      {children}
    </AppContext.Provider>
  );
};
export default SubBasestationProvider;
