import React from 'react';

export default {
  showCLevelApprovalStatus(approval) {
    if (approval === 'yes') {
      return <small>Approved</small>;
    }
    if (approval === 'no') {
      return <small>Not Approved</small>;
    }
    if (approval === null) {
      return <small>N/A</small>;
    }
    return null;
  },
  displayCountry(value) {
    if (value === 'ng') {
      return 'Nigeria';
    } else if (value === 'cv') {
      const cote = "Cote d'Ivoire";
      return cote;
    }
    return 'Ghana';
  },
  displayCurrency(currency) {
    if (currency === 'USD') {
      return '$';
    }
    if (currency === 'NGN') {
      return '₦';
    }
    if (currency === 'CFA') {
      return 'CFA';
    }
    return 'CEDIS';
  },
};
