/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { toast } from 'react-toastify';
import qs from 'qs';
import { useEffect, useReducer } from 'react';
import * as url from '../../../services/api/shared/BasestationsUrl';
import * as types from '../../../constants/types/shared/basestations/BasestationsTypes';
import { BasestationsReducer } from '../../../reducers/shared/BasestationsReducer';
import * as loading from '../../../constants/types/shared/LoadingTypes';

export const useBasestations = () => {
  const [
    { basestations, routers, basestationUsers, basestationCount, load, monthlyUsers, callstations },
    dispatch,
  ] = useReducer(BasestationsReducer, {
    basestations: [],
    routers: [],
    basestationUsers: [],
    basestationCount: '',
    load: false,
    monthlyUsers: [],
    callstations: [],
  });

  const getListOfUsersOnBasestation = async (id) => {
    dispatch({
      type: loading.LOADING_STARTS,
      payload: true,
    });
    try {
      const options = {
        method: 'POST',
        url: `${url.GET_CONNECTED_USERS_URL}${id}`,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      };
      axios(options).then((res) => {
        if (res.data) {
          dispatch({
            type: types.GET_CONNECTED_USERS,
            payload: {
              basestationUsers: res.data.rows,
              basestationCount: res.data.count,
            },
          });
        }
      });
    } catch (e) {
      toast.info('Something went wrong!');
      dispatch({
        type: loading.LOADING_STOPS,
        payload: false,
      });
    }
  };

  const getListOfExpiredUsersOnBasestation = async (id) => {
    dispatch({
      type: loading.LOADING_STARTS,
      payload: true,
    });
    try {
      const options = {
        method: 'POST',
        url: `${url.GET_CONNECTED_USERS_URL}${id}`,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      };
      axios(options).then((res) => {
        const filteredDate = res.data.rows.filter(
          (basestationUser) => new Date(basestationUser.expiration[1]) - new Date() < 0,
        );
        if (res.data) {
          dispatch({
            type: types.GET_CONNECTED_USERS,
            payload: {
              basestationUsers: filteredDate,
              basestationCount: filteredDate.length,
            },
          });
        }
      });
    } catch (e) {
      toast.info('Something went wrong!');
      dispatch({
        type: loading.LOADING_STOPS,
        payload: false,
      });
    }
  };

  const changeUserBasestation = async (id, basestationID) => {
    const data = {
      base_station_id: basestationID,
    };
    dispatch({
      type: loading.LOADING_STARTS,
      payload: true,
    });
    try {
      const response = await axios.patch(`${url.CHANGE_USER_BASESTATION_URL}/${id}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data[1] === 1) {
        toast.success('Basestation Changed Successfully');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (e) {
      toast.info('Something went wrong!');
      dispatch({
        type: loading.LOADING_STOPS,
        payload: false,
      });
    }
  };

  const getBasesatations = async () => {
    try {
      const response = await axios.get(`${url.GET_BASESTATIONS_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data) {
        dispatch({
          type: types.GET_BASESTATIONS,
          payload: response.data,
        });
      } else {
        toast.info('Something went wrong!');
      }
    } catch (e) {
      toast.info('Something went wrong!');
    }
  };

  const getRouters = async () => {
    dispatch({
      type: loading.LOADING_STARTS,
      payload: true,
    });
    try {
      const params = new URLSearchParams();
      params.append('action', 'getRouterList');
      const response = await axios.post(`${url.GET_ROUTER_URL}`, params);
      if (response.data) {
        dispatch({
          type: types.GET_ROUTERS,
          payload: response.data.routers,
        });
      } else {
        toast.info('Something went wrong!');
      }
    } catch (e) {
      toast.info('Something went wrong!');
      dispatch({
        type: loading.LOADING_STOPS,
        payload: false,
      });
    }
  };

  const getUsersByMonth = async (basestation, month) => {
    dispatch({ type: loading.LOADING_STARTS, payload: true });
    try {
      const data = qs.stringify({
        action: 'customers_connected_to_basestation',
        basestation: `${basestation}`,
        duration: `${month}`,
      });

      const response = await axios.post(`${url.GET_ROUTER_URL}`, data);

      if (response.data.status === true) {
        dispatch({
          type: types.GET_MONTHLY_USERS,
          payload: response.data.results,
        });
      } else {
        toast.info('Unable to get monthly users');
        dispatch({ type: loading.LOADING_STOPS });
      }
    } catch (e) {
      toast.info('Something went wrong!');
      dispatch({ type: loading.LOADING_STOPS });
    }
  };

  const getAllCallStations = async () => {
    try {
      const response = await axios.get(`${url.GET_ALL_CALL_STATIONS}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_ALL_CALL_STATIONS,
          payload: response.data,
        });
      } else toast.info('Something went wrong!');
    } catch (e) {
      toast.info('Something went wrong!');
    }
  };

  useEffect(() => {
    getBasesatations();
    getAllCallStations();
    getRouters();
  }, []);

  return {
    basestations,
    callstations,
    routers,
    getListOfUsersOnBasestation,
    getRouters,
    getListOfExpiredUsersOnBasestation,
    getUsersByMonth,
    changeUserBasestation,
    basestationUsers,
    basestationCount,
    load,
    monthlyUsers,
  };
};
