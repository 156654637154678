import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';
import {
  START_CREATING_ACCOUNT,
  STOP_CREATING_ACCOUNT,
  SUCCESS_MESSAGE,
} from '../../../constants/types/operations/accounts/AccountsTypes';

const initialState = {
  account: [],
  load: false,
  status: null,
};

export const AccountsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case START_CREATING_ACCOUNT:
      return {
        ...state,
        load: payload,
      };

    case SUCCESS_MESSAGE:
      return {
        ...state,
        status: payload,
        load: false,
      };

    case STOP_CREATING_ACCOUNT:
      return {
        ...state,
        load: payload,
      };

    default:
      return state;
  }
};

export default AccountsReducer;
