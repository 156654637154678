/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import moment from 'moment';
import view from '../../../../../assets/img/icons/view.png';
import { NoItemRow } from '../../../../../utils/loader/loadingHelper';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import SalesJacketModal from './SalesJacketModal';

const SalesJacketTable = ({ items, isFetching, query }) => {
  const colSpan = '8';
  const [request, setRequest] = useState();

  console.log(query);

  const getRequest = (item) => setRequest(item);
  const tableData =
    items && items.length ? (
      items
        .filter((item, _) => {
          if (
            (query === 'false' && item.PaymentStatus === false) ||
            query === '' ||
            (query === 'true' && item.PaymentStatus === true)
          ) {
            return item;
          }
        })
        .map((item, i) => {
          const qty = item.quantity.replace(/(\r\n|\n|\r)/gm, ' ').toString();
          const arr = JSON.parse(qty);
          // arr = arr.map((x) => {
          //   return { value: x };
          // });
          console.log(arr);
          //   })
          // }

          // var value = [
          //   [1234213, 1234231],
          //   [1234213, 1234231],
          // ];
          // var dato = qty;
          // console.log(dato.toString());

          // let temp = [];
          // qty.forEach((x) => {
          //   temp.push({ value: x });
          // });

          // const quant = temp;

          // const result = quant.map((value) => ({ value }));

          // const dataWithDeviceType = qty.map((item) => {
          //   // const user = userState.find((acc) => acc.mac_id === item.username);

          //   return {
          //     ...item,

          //     // device_type: user ? user.device_type : null,
          //   };
          // });
          return (
            <tr className="text-nowrap" key={i}>
              <td>{i + 1}</td>
              <td>{item.fullName}</td>
              <td>{item.email}</td>
              <td>{item.phoneNumber}</td>
              <td>{item.address}</td>
              <td>
               { <div className="d-flex">
                  <button
                    type="submit"
                    className="mx-1 noBorder"
                    data-toggle="modal"
                    data-placement="left"
                    data-target="#viewc"
                    onClick={() => getRequest(arr)}
                  >
                    <span className="view">
                      <img src={view} alt="" data-toggle="tooltip" data-placement="left" title="Edit" />
                    </span>
                  </button>
                </div>}
              </td>

              <td>{item.price}</td>
              <td>{item.reference ? item.reference : 'N/A'}</td>
              <td>{item.PaymentStatus === false ? 'LOG' : item.PaymentStatus === true ? 'PAID' : 'N/A'}</td>
              <td>{moment(item.created_at).format('MM/DD/YYYY')}</td>
              <SalesJacketModal request={request} />
            </tr>
          );
        })
    ) : (
      <NoItemRow colSpan={colSpan} text="There are no records" />
    );
  return (
    <div className="card x-panel mt-2 px-0">
      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <thead>
            <tr className="">
              <th>#</th>
              <th>Name</th>
              <th className="text-wrap">Email</th>
              <th>Phone</th>
              <th>Address</th>
              <th>Quantity</th>
              {/* <th>Size</th> */}
              <th>Price</th>
              <th>Reference</th>
              <th>Status</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {isFetching ? (
              <tr>
                <td className="text-center">
                  <img src={loader} alt="loading gif" />
                </td>
              </tr>
            ) : (
              tableData
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SalesJacketTable;
