/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import CallLogsTable from './CallLogsTable';
import { IconButton } from '@mui/material';
import CloseIcon from '../../noc/downtime-logger/icons/CloseIcon';
import { useCallLog } from '../../../../../hooks/customer-support/call-logs/useCallLog';
import ReactPaginate from 'react-paginate';
import CallLogCustomerDetails from './CallLogCustomerDetails';

const ViewCustomerDetailsLog = ({ setShow, show, list, from, to, logs, isLoading, pages, getEmployeeLogs }) => {
  console.log(list);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '180vh',
    height: '100vh',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    // boxShadow: 24,
    p: 10,
  };

  const handleClose = () => {
    setShow(false);
  };

  const handlePageClick = (e) => {
    console.log(list);
    const selectedPage = e.selected + 1;
    const data = {
      from: from,
      to: to,
      employee_auth_id: list.employee_auth_id,
    };
    getEmployeeLogs(data, selectedPage);
  };

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ ml: 5 }}>
          View Employee Log Details
          <IconButton className="float-right" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Typography>

        <Typography id="modal-modal-description">
          <div className="card x-panel">
            <CallLogCustomerDetails lists={logs} load={isLoading} />

            <div className="px-3">
              {pages > 1 ? (
                <ReactPaginate
                  previousLabel="previous"
                  nextLabel="next"
                  breakLabel="..."
                  breakClassName="break-me"
                  pageCount={pages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName="pagination"
                  // subContainerClassName="pages pagination"
                  activeClassName="active"
                />
              ) : null}
            </div>
          </div>
        </Typography>
      </Box>
    </Modal>
  );
};

export default ViewCustomerDetailsLog;
