/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { deviceRetrievalStatus } from '../../../utils/asset-team/helper';
import useDeviceRetrieval from './device-retrieval/hooks/useDeviceRetrieval';

const MoveToBucket = ({ oneItem, profile: { firstName, lastName }, address, operation }) => {
  const { loading, addDeviceRetrieval, updateDeviceRetrieval } = useDeviceRetrieval();

  const detailsState = { status: '', comment: '' };
  const [details, setDetails] = useState(detailsState);
  const { status, comment } = details;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };

  const submitForm = () => status.length > 0;

  const onSubmit = (e) => {
    e.preventDefault();
    const { username, Expiration_date, Name, Mail, Mobile, mac, id, Customer_Number } = oneItem;
    const updateParams = { mac, status, comment, agent: `${firstName} ${lastName}` };
    const data = {
      name: Name,
      phone: Mobile,
      address,
      mac: username,
      email: Mail,
      agent: `${firstName} ${lastName}`,
      expiration_date: Expiration_date,
      status,
      comment,
      details: comment,
      customer_number: Customer_Number,
    };
    if (operation === 'update') return updateDeviceRetrieval(id, updateParams);
    return addDeviceRetrieval(data);
  };

  return (
    <div id="moveToBucket" className="modal fade mt-5" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title my-2">Move To Bucket</h4>
            <button type="button" className="mr-2 close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <div className="modal-body detail mb-4 p-0">
            <form onSubmit={onSubmit} className="col-md-12">
              <div className="d-flex">
                <div className="pl-3 form-group col-md-6">
                  <label className="m-0 p-0 copy-font" htmlFor="status">
                    Status
                  </label>
                  <div className="input-group">
                    <select
                      id="status"
                      name="status"
                      onChange={handleChange}
                      className="form-control border border-info py-2"
                    >
                      <option value="">--Select Status--</option>
                      {deviceRetrievalStatus.map((deviceStatus) => (
                        <option key={deviceStatus.value} value={deviceStatus.value}>
                          {deviceStatus.name.toUpperCase()}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="form-group col-md-12">
                  <label className="m-0 p-0 copy-font">Comment</label>
                  <textarea
                    className="form-control border border-info"
                    rows="3"
                    cols="80"
                    name="comment"
                    value={comment}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="text-center mt-3">
                {loading ? (
                  <button type="button" className="btn btn-secondary py-2 px-5" disabled>
                    {operation === 'update' ? 'Update' : 'Add'}
                  </button>
                ) : (
                  <button type="submit" className="btn btn-green py-2 px-5" disabled={!submitForm()}>
                    {operation === 'update' ? 'Update' : 'Add'}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoveToBucket;
