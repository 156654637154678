import React from 'react';
import ghana from '../../../../../assets/img/icons/country/ghana.png';
import useExpiredCustomers from '../../../../../hooks/shared/customers/useExpiredCustomers';

const ExpiredGhanaCustomers = () => {
  const {
    state: { ghanaExpired },
  } = useExpiredCustomers();

  return (
    <div className="col-lg col-md col-sm">
      <div className="boxes">
        <div className="text-center">
          <div className="text-center mt-3">
            <img src={ghana} alt="Ghana" width="40px" height="30px" />
          </div>
          <div className="count">{ghanaExpired}</div>
          <h3 className="color-one">Ghana Expired</h3>
        </div>
      </div>
    </div>
  );
};
export default ExpiredGhanaCustomers;
