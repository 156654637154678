/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FieldSupportNavBar from '../../../../layouts/field-support/FieldSupportNavBar';
import HandleCluster from './HandleCluster';
import useCheckDepartment from '../../../../../hooks/shared/custom/check-department/field-support/useCheckDepartment';
import { OverviewProvider } from '../../../../../context/shared/installations/overview/OverviewContext';
import { useUserProfile } from '../../../../../hooks/shared/user/useUserProfile';

const FieldSupportClustering = ({ history, location }) => {
  useCheckDepartment(history);
  const { query: { clusterState, allInstallations = {} } = {} } = location;
  const { getUserProfile, profile } = useUserProfile();

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <div className="s-layout">
      <OverviewProvider>
        <main className="s-layout__content col-lg-10 p-0">
          <FieldSupportNavBar currentPage="Clustering/Routing" position={profile.firstName} />
          <div className="container">
            {location.query ? (
              <HandleCluster allInstallations={allInstallations} clusterState={clusterState} />
            ) : (
              history.goBack()
            )}
          </div>
        </main>
      </OverviewProvider>
    </div>
  );
};

FieldSupportClustering.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default FieldSupportClustering;
