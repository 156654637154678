import React, { useState } from 'react';

const SearchDummy = () => {
  const [keyword, setKeyword] = useState('');
  const [option, setOption] = useState('');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');

  return (
    <form className="form-inline mb-2 ml-3 col-12">
      <div className="form-group">
        <select name="option" onChange={(e) => setOption(e.target.value)} className="form-control">
          <option value="">--Choose Search Field--</option>
          <option value="itemName">Item Name</option>
          <option value="routerMACID">Router MAC ID</option>
          <option value="radioMACID">Radio MAC ID</option>
          <option value="customerName">Customer Name</option>
          <option value="customerEmail">Customer Email</option>
        </select>
      </div>
      <div className="form-group ml-2">
        <input
          className="form-control"
          type="text"
          placeholder="Input Search Term"
          style={{ width: '230px' }}
          name="keyword"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
      </div>
      <div className="form-group d-flex ml-2">
        <small className="mr-1">From: </small>
        <input
          type="date"
          className="form-control"
          name="from"
          value={from}
          onChange={(e) => setFrom(e.target.value)}
        />
      </div>
      <div className="form-group d-flex ml-2">
        <small className="mr-1">To: </small>
        <input type="date" className="form-control" name="to" value={to} onChange={(e) => setTo(e.target.value)} />
      </div>
      <div className="form-group ml-2">
        <button type="button" className="btn btn-green">
          Search
        </button>
      </div>
    </form>
  );
};

export default SearchDummy;
