/* eslint-disable react/no-array-index-key */
import React, { useContext } from 'react';
import loader from '../../../../../../../../assets/img/icons/TizetiLoaderColored.gif';
// import { StatisticsProps } from '../../types/StatisticsProps';
import GeniatechContext from '../../../../../../../../context/GeniatechContext';
import StatisticsTableHead from '../StatisticsTableHead';

const StatisticTable = () => {
  const { loading, fetched } = useContext(GeniatechContext);
  const tableData =
    fetched && fetched.length
      ? fetched.map((row, i) => (
          <tr className="text-nowrap" style={{ cursor: 'pointer' }} key={i + 1}>
            <td>{i + 1}</td>
            <td>{row.config_version}</td>
            <td>{row.connected_sms}</td>
            <td>{row.gain}</td>
            <td>{row.gps_sync_state}</td>
            <td>{row.last_sync}</td>
            <td>{row.mac}</td>
            <td>{row.managed_account}</td>
            <td>{row.mode}</td>
            <td>{row.name}</td>
            <td>{row.network}</td>
            <td>{row.reboots}</td>
            <td>{row.session_drops}</td>
            <td>{row.status}</td>
            <td>{row.status_time}</td>
            <td>{row.tower}</td>
            <td>{row.type}</td>
            <td>{row.vlan}</td>
            <td>{row.default_gateway}</td>
            <td>{row.ip_dns}</td>
            <td>{row.ip_wan ? row.ip_wan : 'NO IP WAN'}</td>
            <td>{row.lan_mode_status}</td>
            <td>{row.lan_speed_status}</td>
            <td>{row.lan_status}</td>
            <td>{row.netmask}</td>
            <td>{row.radio.auth_mode}</td>
            <td>{row.radio.auth_type}</td>
            <td>{row.radio.channel_width}</td>
            <td>{row.radio.dfs_status}</td>
            <td>{row.radio.dl_mcs}</td>
            <td>{row.radio.dl_pkts}</td>
            <td>{row.radio.dl_retransmits}</td>
            <td>{row.radio.dl_retransmits_pct}</td>
            <td>{row.radio.dl_throughput}</td>
            <td>{row.radio.frequency}</td>
            <td>{row.radio.mac}</td>
            <td>{row.radio.mode}</td>
            <td>{row.radio.sessions_dropped}</td>
            <td>{row.radio.ssid}</td>
            <td>{row.radio.tdd_ratio}</td>
            <td>{row.radio.tx_power}</td>
            <td>{row.radio.ul_mcs}</td>
            <td>{row.radio.ul_pkts}</td>
            <td>{row.radio.ul_snr ? row.radio.ul_snr : 'NO UL SNR'}</td>
            <td>{row.radio.ul_throughput}</td>
            <td>{row.radio.wlan_status}</td>
          </tr>
        ))
      : 'no alarm';
  return (
    <div className="card x-panel mt-2 px-0">
      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <StatisticsTableHead />
          <tbody>
            {loading ? (
              <tr>
                <img src={loader} alt="loading gif" />
              </tr>
            ) : (
              tableData
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StatisticTable;
