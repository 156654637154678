import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { API } from '../../../../../../services/api/tizeti';
import { TEAM_URL } from '../api/TeamsUrl';
import { TeamProps } from '../TeamTable';

const useTeams = () => {
  const [teams, setTeams] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [team, setTeam] = useState({ name: '' });
  const [showCreateModal, setShowCreateModal] = useState(false);

  const onTeamChange = (e) => {
    setTeam({ ...team, [e.target.name]: e.target.value });
  };

  const openCreateModal = () => {
    setShowCreateModal(true);
  };

  const closeCreateModal = () => {
    setShowCreateModal(false);
  };

  const onSubmitTeam = async () => {
    setLoadingForm(true);
    try {
      const response = await API.post(`${TEAM_URL}`, team);
      if (response.data.data.id) {
        setLoadingForm(false);
        setTeam({ name: '' });
        setTeams([response.data.data, ...teams]);
      }
    } catch (e) {
      setLoadingForm(false);
      toast.error('Something went wrong');
    }
  };

  const getTeams = async () => {
    setFetching(true);
    try {
      const response = await axios.get(`${TEAM_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        setFetching(false);
        setTeams(response.data);
      }
    } catch (e) {
      setFetching(false);
      toast.error('Error Fetching Team');
    }
  };

  const deleteTeam = async (id) => {
    setFetching(true);
    try {
      const response = await API.delete(`${TEAM_URL}/${id}`);
      if (response.data[0] === 1) {
        setFetching(false);
        getTeams();
      }
    } catch (e) {
      setFetching(false);
      toast.error('Error Deleting Team');
    }
  };

  return {
    team,
    teams,
    fetching,
    loadingForm,
    onTeamChange,
    onSubmitTeam,
    openCreateModal,
    closeCreateModal,
    showCreateModal,
    deleteTeam,
    getTeams,
  };
};
export default useTeams;
