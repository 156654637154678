/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import useCheckDepartment from '../../../../../hooks/shared/custom/check-department/useCheckDepartment';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import { RESEARCH_AND_DEVELOPMENT, SUPER_ADMIN } from '../../../../../utils/departments/helpers';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import RDNavBar from '../../../../layouts/research-and-development/RDNavBar';
import PurchaseOrderBody from '../../../shared/purchase-order/PurchaseOrderBody';
import useCheckTwoDepartments from '../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';

const RDPurchaseOrder = ({ history }) => {
  useCheckTwoDepartments(`${RESEARCH_AND_DEVELOPMENT}`, `${SUPER_ADMIN}`, history);

  const {
    profile: { firstName, department_id },
  } = useUser();

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <RDNavBar currentPage="Purchase Order" position={firstName} />
        <div className="container">
          <PurchaseOrderBody deptID={department_id} />
        </div>
      </main>
    </div>
  );
};

RDPurchaseOrder.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default RDPurchaseOrder;
