/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React, { ReactNode } from 'react';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../utils/loader/loadingHelper';

const CallReportTable = ({ customer, load }) => {
  const tableData =
    customer && customer.length ? (
      customer.map((item, i) => {
        const {
          id,
          unique_call_id,
          customer_cin,
          customer_phone,
          notes,
          staff_email,
          calling_ext,
          status,
          created,
          updated,
          duration_in_seconds,
        } = item;

        return (
          <tr className="" key={i}>
            <td>{i + 1}</td>
            <td>{id}</td>
            <td>{unique_call_id}</td>
            <td>{customer_cin}</td>
            <td>{customer_phone}</td>
            <td>{notes}</td>
            <td>{staff_email}</td>
            <td>{calling_ext}</td>
            <td>{status}</td>
            <td>{created}</td>
            <td>{updated}</td>
            <td>{duration_in_seconds}</td>
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan="20" text="There Are No Customers" />
    );
  return (
    <div className="table-responsive content col-12 px-1">
      <table className="table">
        <thead>
          <tr className="text-nowrap">
            <th>#</th>
            <th>ID</th>
            <th>Unique ID</th>
            <th>Customer CIN</th>
            <th>Phone</th>
            <th>Notes</th>
            <th> Staff Email</th>
            <th> Calling Ext</th>
            <th> Status</th>
            <th> Date/Time Created</th>
            <th> Date/Time Updated</th>
            <th> Duration(s)</th>
          </tr>
        </thead>

        <tbody>{load ? <TizetiGIFLogoTable colSpan="20" /> : tableData}</tbody>
      </table>
    </div>
  );
};

export default CallReportTable;
