import { useState, useEffect, useReducer } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { ADD_ACCESS_POINT_URL } from '../../../services/api/noc/access-points/AccessPointsUrl';
import { COUNTRIES_URL } from '../../../utils/config';
import { AccessPointsReducer } from '../../../reducers/noc/access-points/AccessPointsReducer';
import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';

// eslint-disable-next-line import/prefer-default-export
export const useAccessPoint = () => {
  const accessPointState = {
    ssid: '',
    address: '',
    http: '',
    type: '',
    frequency: '',
    country: '',
    security: '',
    username: '',
    password: '',
  };

  const [access, setAccess] = useState(accessPointState);
  const [countries, setCountries] = useState({});

  const [{ isLoading }, dispatch] = useReducer(AccessPointsReducer, {
    isLoading: false,
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setAccess({
      ...access,
      [name]: value,
    });
  };

  const addAccessPoint = async (data) => {
    console.log(data);
    dispatch({
      type: LOADING_STARTS,
    });
    try {
      const response = await axios.post(`${ADD_ACCESS_POINT_URL}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.id) {
        toastr.info('Access Point Added Successfully!');
        dispatch({
          type: LOADING_STOPS,
        });
        // window.location.reload();
      } else {
        toastr.info('Unable to add access point!');
        dispatch({
          type: LOADING_STOPS,
        });
      }
    } catch (error) {
      if (error) {
        console.log(error.response);
        toastr.info('Something went wrong!');
        dispatch({
          type: LOADING_STOPS,
        });
      }
    }
  };

  const getCountries = async () => {
    try {
      const response = await axios.get(`${COUNTRIES_URL}`);
      if (response.data) {
        setCountries(response.data);
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  useEffect(() => {
    getCountries();
  }, []);

  return { onChange, access, addAccessPoint, countries, isLoading };
};
