import React, { Component } from 'react';

class AssignDevice extends Component {
  render() {
    return (
      <div
        id="assign-device"
        className="modal fade in"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title ml-5" id="myModalLabel">
                Assign Device to Basestation
              </h4>
              <button type="button" className="close custom-close" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
            </div>
            <div className="modal-body detail" style={{ padding: '0', textAlign: 'justify' }}>
              <div className="row">
                <form className="form-horizontal col-12">
                  <div className="form-group mt-4">
                    <div className="col-12 d-flex mb-3">
                      <div className="col-6">
                        <label className="copy-font m-0 p-0 text-left">Basestation Name</label>
                        <select className="form-control">
                          <option>North Point</option>
                        </select>
                      </div>
                      <div className="col-6">
                        <label className="copy-font m-0 p-0 text-left">Select Device</label>
                        <select className="form-control">
                          <option>CCR1036-12G-2S</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-12 d-flex mb-3">
                      <div className="col-6">
                        <label className="copy-font m-0 p-0 text-left">Quantity</label>
                        <select className="form-control">
                          <option />
                        </select>
                      </div>

                      <div className="col-6">
                        <label className="copy-font m-0 p-0 text-left">Max Consumption</label>
                        <input type="number" placeholder="76" className="form-control pl-2" readOnly />
                      </div>
                    </div>

                    <div className="col-12 mb-3">
                      <div className="col-6">
                        <label className="copy-font m-0 p-0 text-left">Total for this device</label>
                        <input type="number" placeholder="146" className="form-control pl-2" readOnly />
                      </div>
                    </div>

                    <div className="text-center">
                      <button className="btn btn-success">Assign Device</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AssignDevice;
