/* eslint-disable @typescript-eslint/camelcase */
import { useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { LOADING_STARTS, LOADING_STOPS } from '../../../../../constants/types/shared/LoadingTypes';
import {
  ADD_KPI_URL,
  EDIT_KPI_URL,
  GET_KPI_PERCENTAGE_URL,
  GET_KPIS_URL,
  GET_ALL_KPIS_URL,
  SEARCH_KPI_URL,
} from './kpi-monitor.url';
import { GET_EMPLOYEES_ASSIGNED_KPIS, GET_KPIS } from '../KpiTypes';
import kpiReducer from '../reducer/kpiReducer';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import { KpiInterface } from '../KpiMonitorTable';

const useKpiMonitor = () => {
  const { profile } = useUser();
  const kpiState = {
    name: '',
    description: '',
    status: '',
    department_id: `${sessionStorage.getItem('departmentId')}`,
    start_date: '',
    end_date: '',
    employee_id: '',
  };
  const [kpi, setKpi] = useState(kpiState);
  const [addingKpi, setAddingKpi] = useState(false);
  const [kpiPercentage, setKpiPercentage] = useState(0);
  const [{ kpis, isLoading, employeeAssignedKpis }, dispatch] = useReducer(kpiReducer, {
    isLoading: false,
    kpis: [],
    employeeAssignedKpis: [],
  });
  const onChange = (e) => {
    const { name, value } = e.target;
    setKpi({
      ...kpi,
      [name]: value,
    });
  };
  const onSubmit = async () => {
    setAddingKpi(true);
    try {
      const response = await axios.post(`${ADD_KPI_URL}`, kpi, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.status === 200) {
        setAddingKpi(false);
        setKpi(kpi);
        toast.success('Kpi Created Successfully!');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (e) {
      setAddingKpi(false);
      toast.info('Sonething went wrng');
    }
  };

  const updateKpiStatus = async (id, params) => {
    dispatch({ type: LOADING_STARTS });
    try {
      const response = await axios.put(`${EDIT_KPI_URL}/${id}`, params, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data[1] === 1) {
        toast.success('Update Successful!');
        setTimeout(() => window.location.reload(), 1000);
      } else toast.warn('Unable To Update');
    } catch (e) {
      toast.info('Error Updating ...');
      dispatch({ type: LOADING_STOPS });
    }
  };

  useEffect(() => {
    async function getData() {
      dispatch({
        type: LOADING_STARTS,
      });
      try {
        return await axios.post(
          `${GET_KPIS_URL}${sessionStorage.getItem('departmentId')}?page=1`,
          // `${GET_ALL_KPIS_URL}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
          },
        );
      } catch (e) {
        return e;
      }
    }
    async function getKpiPercentage() {
      try {
        return await axios.post(
          `${GET_KPI_PERCENTAGE_URL}${sessionStorage.getItem('departmentId')}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
          },
        );
      } catch (e) {
        return e;
      }
    }
    async function getEmployeeAssignedKpis() {
      try {
        return await axios.post(
          `${SEARCH_KPI_URL}`,
          { employee_id: profile.id },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
          },
        );
      } catch (e) {
        return e;
      }
    }
    getEmployeeAssignedKpis()
      .then((response) => {
        if (response.data.logs) {
          dispatch({
            type: GET_EMPLOYEES_ASSIGNED_KPIS,
            payload: response.data,
          });
        }
        dispatch({ type: LOADING_STOPS });
      })
      .catch(() => dispatch({ type: LOADING_STOPS }));
    getData()
      .then((response) => {
        if (response.data.logs) {
          dispatch({
            type: GET_KPIS,
            payload: response.data,
          });
        }
        dispatch({ type: LOADING_STOPS });
      })
      .catch(() => dispatch({ type: LOADING_STOPS }));

    getKpiPercentage()
      .then((response) => {
        if (response.data.percentage) {
          setKpiPercentage(response.data.percentage);
        }
      })
      .catch(() => toast.info('Unable to get'));
  }, []);
  return {
    kpis,
    isLoading,
    onChange,
    onSubmit,
    kpi,
    addingKpi,
    kpiPercentage,
    employeeAssignedKpis,
    updateKpiStatus,
  };
};
export default useKpiMonitor;
