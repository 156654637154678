/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { Link } from 'react-router-dom';
import Logout from '../../../shared/auth/Logout';
import MailBlast from '../../../../../assets/img/icons/mailblast.svg';
import { API_URL, ADMIN_DEPARTMENT } from '../../../../../utils/config';
import AdminDeptLogo from '../../../../layouts/admin-department/AdminDeptLogo';
import AdminDepartmentSideBar from '../../../../layouts/admin-department/AdminDepartmentSideBar';
import Escalate from '../../../shared/escalate/Escalate';

class AdminDepartmentEscalate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      role: '',
    };
  }

  componentDidMount() {
    this.getUserDetails();
    this.fetchLoggedOnUser();
  }

  getUserDetails() {
    axios({
      method: 'get',
      url: `${API_URL}/employee/loggedOnEmployee`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    }).then((res) => {
      if (res.data) {
        this.setState({
          firstName: res.data.firstName,
        });
      }
    });
  }

  fetchLoggedOnUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employeeReg/logged`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((response) => {
        this.setState({
          role: response.data.role_id,
        });

        if (response.data.id) {
          if (response.data.department_id !== `${ADMIN_DEPARTMENT}`) {
            this.props.history.push('/staff/login');
            toastr.info('You are not allowed to view this page');
          }
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch((err) => {});
  }

  render() {
    const { firstName, role } = this.state;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <header className="topbar shade bg-white fixed-top">
            <nav className="navbar navbar-expand-lg navbar-light top-navbar fixed-top">
              <div style={{ width: '200px' }}>
                <AdminDeptLogo />
              </div>

              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarText"
                aria-controls="navbarText"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div className="collapse navbar-collapse mt-3" id="navbarText">
                <ul className="navbar-nav" style={{ marginRight: 'auto' }}>
                  <li className="nav-item active">
                    <Link className="nav-link ml-5" to="#">
                      <h3 className="">
                        Escalate<span className="sr-only">(current)</span>
                      </h3>
                    </Link>
                  </li>
                </ul>
                <span className="navbar-text">
                  <div className="d-flex">
                    {role > '2' ? (
                      <Link
                        to="/research-and-development/mail-blast"
                        className="d-flex"
                        style={{ color: 'gray', textDecoration: 'none' }}
                      >
                        <h6>
                          <strong>MailBlast</strong>
                        </h6>
                        <img src={MailBlast} className="logout ml-1 mr-2 mb-2" alt="" />
                      </Link>
                    ) : null}
                    <h4 className="admin">
                      {firstName}
                      <Logout />
                    </h4>
                  </div>
                </span>
              </div>
            </nav>
          </header>

          <div className="all-sides col-lg-12 col-md-12 col-sm-12">
            <AdminDepartmentSideBar />
            <div className="container">
              <div className="row mt-5">
                <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-4">
                  <Escalate dept={firstName} />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default AdminDepartmentEscalate;
