import React from 'react';
import BusinessDevelopmentDedicatedCustomersBody from '../../../shared/dedicated-customers/BusinessDevelopmentDedicatedCustomersBody';
import ProcurementNavBar from '../../../../layouts/procurement/ProcurementNavBar';

const ProcurementDedicatedCustomers = () => {
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        < ProcurementNavBar currentPage="Dedicated Customers" />
        <BusinessDevelopmentDedicatedCustomersBody />
      </main>
    </div>
  );
};

export default ProcurementDedicatedCustomers;
