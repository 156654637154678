import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import AppContext from '../../../../../context/shared/AppContext';
import CustomInput from '../../../shared/forms/CustomInput';
import CustomButton from '../../../shared/forms/CustomButton';
import Label from '../../../shared/forms/Label';

const CreateTeam = () => {
  const { closeCreateModal, showCreateModal, onSubmitTeam, team, onTeamChange, loadingForm } = useContext(AppContext);
  const submitForm = () => {
    return team.name.length > 0;
  };
  const submit = (e) => {
    e.preventDefault();
    onSubmitTeam();
  };
  return (
    <Modal show={showCreateModal} onHide={closeCreateModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Create Team</Modal.Title>
      </Modal.Header>
      <form className="form-horizontal" onSubmit={submit}>
        <div className="form-group">
          <div className="col-12 mb-3">
            <div className="col-12">
              <Label classname="col-lg-12 text-justify copy-font m-0 p-0" name="Team Name" />
              <CustomInput
                classname="form-control shadow-sm borderRadius0"
                name="name"
                value={team.name}
                onChange={onTeamChange}
                readOnly={false}
                type="text"
              />
            </div>
          </div>
          <div className="col-12 mb-3 text-center">
            {loadingForm ? (
              <CustomButton classname="btn btn-green" name="Creating Team" disabled={loadingForm} />
            ) : (
              <CustomButton classname="btn btn-green" name="Create Team" disabled={!submitForm()} />
            )}
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default CreateTeam;
