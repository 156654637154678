/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';

const InstallationDuration = ({ days, onChange, isLoading, getInstallations }) => {
  const params = { action: 'tizeti_asset_installed_dynamic_days_count_get_cron', days };

  const onSubmit = (e) => {
    e.preventDefault();
    getInstallations(params);
  };

  useEffect(() => {
    getInstallations(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="col-12 mb-3">
      <form onSubmit={onSubmit}>
        <div className="col-12">
          <div className="col-12 d-flex">
            <label className="copy-font p-0 mr-3">Filter By:</label>
            <div className="form-check mr-2">
              <input
                className="form-check-input"
                type="radio"
                name="days"
                id="daily"
                value="1"
                checked={days == 1}
                onChange={onChange}
              />
              <label className="form-check-label p-0" style={{ borderBottom: '0' }} htmlFor="daily">
                Daily
              </label>
            </div>
            <div className="form-check mr-2">
              <input
                className="form-check-input"
                type="radio"
                name="days"
                id="weekly"
                value="7"
                checked={days == 7}
                onChange={onChange}
              />
              <label className="form-check-label p-0" style={{ borderBottom: '0' }} htmlFor="weekly">
                Weekly
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="days"
                id="monthly"
                value="30"
                checked={days == 30}
                onChange={onChange}
              />
              <label className="form-check-label p-0" style={{ borderBottom: '0' }} htmlFor="monthly">
                Monthly
              </label>
            </div>

            <div className=" text-center ml-3">
              {isLoading ? (
                <button type="button" className="btn btn-secondary copy-font" style={{ height: '29px' }} disabled>
                  Searching...
                </button>
              ) : (
                <button type="submit" className="btn btn-primary copy-font" style={{ height: '29px' }}>
                  Search
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default InstallationDuration;
