import axios from 'axios';
import { toast } from 'react-toastify';
import { useReducer } from 'react';
import DedicatedConnectedUsersReducer from '../../../reducers/business-development/dedicated-connected-users/DedicatedConnectedUsersReducer';
import { GET_DEDICATED_CONNECTED_USERS } from '../../../constants/types/business-development/dedicated-connected-users/DedicatedConnectedUsersTypes';
import { GET_DEDICATED_CONNECTED_USERS_URL } from '../../../services/api/business-development/dedicated-connected-users/DedicatedConnectedUsersUrl';
import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';

const useDedicatedConnectedUsers = () => {
  const [{ items, totalPages, currentPage, allPages, isLoading }, dispatch] = useReducer(
    DedicatedConnectedUsersReducer,
    {
      items: [],
      totalPages: '',
      allPages: '',
      currentPage: '',
      isLoading: false,
    },
  );

  const getDedicatedConnectedUsers = async (data) => {
    dispatch({ type: LOADING_STARTS });
    try {
      const response = await axios.post(`${GET_DEDICATED_CONNECTED_USERS_URL}`, data);
      if (response.status === 200) {
        // console.log('hi');
        dispatch({
          type: GET_DEDICATED_CONNECTED_USERS,
          payload: {
            items: response.data.items,
            totalPages: response.data.total_records,
            allPages: response.data.total_pages,
            currentPage: response.data.current_page,
          },
        });
      }
    } catch (e) {
      toast.info('Something went wrong!');
      if (e.response) {
        dispatch({ type: LOADING_STOPS });
      }
    }
  };

  return {
    getDedicatedConnectedUsers,
    items,
    totalPages,
    allPages,
    currentPage,
    isLoading,
  };
};

export default useDedicatedConnectedUsers;
