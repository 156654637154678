/* eslint-disable react/require-default-props */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getUser } from '../../../../../../actions/shared/user/UserActions';
import { getLoggedInUser } from '../../../../../../actions/user/UserActions';
import AccountsHrRequisitions from '../../../../shared/requisition/shared/accounts-hr/AccountsHrRequisitions';
import AccountsNavBar from '../../../../../layouts/accounts/AccountsNavBar';

class AccountsRequisition extends Component {
  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.getUser();
  }
  //supposedly the accounts requisition

  render() {
    const { user } = this.props;
    const { firstName } = user;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <AccountsNavBar currentPage="Requisition" position={firstName} />
          <AccountsHrRequisitions />
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

AccountsRequisition.propTypes = {
  getUser: PropTypes.func.isRequired,
  user: PropTypes.shape(),
};

export default connect(mapStateToProps, {
  getUser,
  getLoggedInUser,
})(AccountsRequisition);
