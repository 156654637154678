export const GET_PENDING_RETAILERS =
  'GET_PENDING_RETAILERS';
export const GET_ACTIVATED_RETAILERS =
  'GET_ACTIVATED_RETAILERS';
export const START_LOADING_PENDING_RETAILERS =
  'START_LOADING_PENDING_RETAILERS';

export const START_LOADING_ACTIVATED_RETAILERS =
  'START_LOADING_ACTIVATED_RETAILERS';
export const STOP_LOADING_PENDING_RETAILERS =
  'STOP_LOADING_PENDING_RETAILERS';
export const STOP_LOADING_ACTIVATED_RETAILERS =
  'STOP_LOADING_ACTIVATED_RETAILERS';
export const GET_RETAILER = 'GET_RETAILER';
