/* eslint-disable import/prefer-default-export */
import * as types from '../../../constants/types/shared/budget/BudgetTypes';

export const budgetReducer = (state, action) => {
  const { type } = action;
  switch (type) {
    case types.CREATE_BUDGET:
      return { ...state, loading: true };
    case types.CREATED_BUDGET:
      return { ...state, loading: false };
    case types.CREATE_BUDGET_FAILED:
      return { ...state, loading: false };
    default:
      return state;
  }
};
