import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      sessionStorage.getItem('token') ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

// import React from "react";
// import { Redirect,Route } from "react-router-dom";
// import {connect} from 'react-redux';
// import PropTypes from 'prop-types';
//
// const PrivateRoute = ({component: Component, auth, ...rest}) => {
//   return (
//     <Route
//       {...rest}
//       render={props => {
//         if(auth.isLoading){
//           return <small>loading...</small>
//         } else if(!auth.isAuthenticated){
//           return <Redirect to="/login"/>
//         } else {
//           return <Component {...props}/>
//         }
//       }}
//     />
//   );
// };
//
// const mapStateToProps = state => ({
//
//   auth: state.auth
//
// });
//
// export default connect(mapStateToProps)(PrivateRoute);
