/* eslint-disable react/prop-types */
import React from 'react';

const ViewInvoice = ({ order: { invoice, invoice1, invoice2, invoice3, invoice4 } }) => {
  const showInvoice = (viewInvoice) => {
    if (viewInvoice) {
      if (viewInvoice.split('.').pop() === 'pdf')
        return <embed src={viewInvoice} width="100%" height="100%" type="application/pdf" />;
      return (
        <embed className="border border-secondary" alt="invoice" width="100%" height="100%" src={viewInvoice || null} />
      );
    }
    return null;
  };

  return (
    <div
      id="viewInvoice"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Proforma Invoice
            </h4>
            <button type="button" className="close mr-3" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body">
            <div className="col-12 p-0">
              <div className="p-2">{showInvoice(invoice)}</div>
              <div className="p-2">{showInvoice(invoice1)}</div>
              <div className="p-2">{showInvoice(invoice2)}</div>
              <div className="p-2">{showInvoice(invoice3)}</div>
              <div className="p-2">{showInvoice(invoice4)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewInvoice;
