import React, { Component } from 'react';

export default class CustomerServiceScript extends Component {
  render() {
    return (
      <div id="customerscript">
        <img
          src="https://res.cloudinary.com/teewhy/image/upload/v1557501425/Customer_service_Script.png"
          alt="Feedback"
          style={{ width: '60%' }}
        />
        <div id="slideout_outer">
          <div className="retention text-center">
            <h5 className="p-2">CUSTOMER SERVICE SCRIPT</h5>
          </div>
          <p className="copy-font p-2 text-justify">Good Morning/Afternoon/Evening Welcome to Tizeti</p>

          <p className="copy-font p-2 text-justify">My name is First Name & Last Name How may I help you</p>

          <p className="copy-font">
            Can I have your account details? Kindly hold on a few seconds whilst I pull up your account Open CRM tool to
            bring up customer details. Reiterate what the customer has said the issue is. Using your discretion:- 1)
            Check details:
            <br />
            a) Has customer called in before <br />
            b) I see your account expires on ...
            <br />
            Basically build small talk
          </p>

          <div className="text-center mb-4">
            <img
              src="https://res.cloudinary.com/teewhy/image/upload/v1557504858/CustomerServiceImg.png"
              className="w-75"
            />
          </div>

          <div className="side-flow">
            <h6 className="mt-1">Scenario 1:</h6>
          </div>
          <p className="copy-font p-2 text-justify">
            I have looked into your issue and I have had this resolved it for you Would there be any other thing I could
            help you with today? No Do note you can make payment via the following options
            <br />
            1. Transfer to our Zenith Bank Account #<br />
            2. Pay via pay.tizeti.com
            <br />
            3. Pay via tizeti.com/customer
            <br />
            If you would like a to raise a support issue send us an email to internet@wifi.com.ng or call us on 0908 947
            3040 or 0908 947 3050
            <br />
            Do enjoy the rest of your day
            <br />
            Wait for the customer to drop the phone
            <br />
            Email is sent to the customer what was discussed OTP and issue was resolved
          </p>

          <div className="side-flow">
            <h6>Scenario 2:</h6>
          </div>

          <p className="copy-font p-2 text-justify">
            I have looked into your account and everything seems fine from my end but to help resolve the issue I would
            transfer you to the department who will help resolve this. Agent transfers to example accounts using the
            following warms transfer steps -Accounts phone rings and is picked up -Agent speaks with the accounts
            personnel and provides all information and issues stated by the customer. Agent now hands customer to
            accounts agent ( Example Mary I will pass you on to Kike who will help resolve the issue) and ends with
            thanks for choosing Tizeti.
          </p>
        </div>
      </div>
    );
  }
}
