import axios from 'axios';
import { toast } from 'react-toastify';
import { useState } from 'react';
import {
  CREATE_SUPPORT_CALLS,
  GET_SUPPORT_CALLS,
  UPDATE_SUPPORT_CALLS,
} from '../../../services/api/customer-support/call-logs/CallLogUrl';

const useSupportCall = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [callCount, setCallCount] = useState('');

  const getSupportCalls = async (data) => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${GET_SUPPORT_CALLS}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data) {
        setIsLoading(false);

        setCallCount(response.data);
      }
    } catch (e) {
      toast.info('Something went wrong!');
      if (e.response) {
      }
    }
  };

  const createSupportCalls = async (data, onSubmit) => {
    setIsLoader(true);
    try {
      const response = await axios.post(`${CREATE_SUPPORT_CALLS}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.data.id) {
        setIsLoader(false);
        toast.success(response.data.message);
        const datas = {
          from: data.date,
          to: data.date,
          shift: data.shift,
        };
        onSubmit(datas);
      }
    } catch (e) {
      toast.info('Something went wrong!');
      if (e.response) {
      }
    }
  };

  const updateSupportCalls = async (data, onSubmit) => {
    setIsLoading(true);
    const callId = data.callId;
    const config = {
      method: 'patch',
      url: `${UPDATE_SUPPORT_CALLS}/${callId}`,
      headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
    };

    await axios(config)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          toast.success(response.data.message);
          const datas = {
            from: data.date,
            to: data.date,
            shift: data.shift,
          };
          onSubmit(datas);
        }
      })
      .catch((error) => {
        toast.info('Something went wrong!');
        if (error.response) {
        }
      });
  };

  return {
    getSupportCalls,
    callCount,
    createSupportCalls,
    isLoader,
    isLoading,
    updateSupportCalls,
  };
};

export default useSupportCall;
