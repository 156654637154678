/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import { useSearchLeads } from '../../customer-support/leads/useSearchLeads';
import AccountsAddLteTable from './AccountsAddLteTable';



const AccountsAddLteBody = () => {
  const { lists, isLoading, searchLead } = useSearchLeads();

  const [keyword, setKeyword] = useState('');
  const [option, setOption] = useState('');

  const searchEmail = { email: keyword.trim() };
  const searchName = { firstName: keyword.trim() };
  const searchPhone = { phoneNumber: keyword.trim() };

  const filterUsingKeyword = (e) => {
    e.preventDefault();
    switch (option) {
      case 'firstName':
        return searchLead(searchName);
      case 'email':
        return searchLead(searchEmail);

      case 'phoneNumber':
        return searchLead(searchPhone);

      default:
        return null;
    }
  };

  return (
    <div className="col-12 p-0 after-summary pt-4">
      <div className="row mx-2">
        <div className="mb-1">
          <div className="smallTop d-flex">
            <form onSubmit={filterUsingKeyword} className="form-inline ml-3">
              <div className="form-group">
                <select name="chooseField" onChange={(e) => setOption(e.target.value)} className="form-control">
                  <option value="">--Choose Search Field--</option>
                  <option value="firstName">Firstname</option>
                  <option value="email">Email</option>
                  <option value="phoneNumber">Phone</option>
                </select>
              </div>
              {option !== 'date' ? (
                <div className="form-group ml-2">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Input Search Term"
                    style={{ width: '230px' }}
                    name="keyword"
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                  />
                </div>
              ) : (
                <>{null}</>
              )}
              <div className="form-group ml-2">
                <button className="btn btn-green" type="submit">
                  Search
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="card x-panel p-0">
          <AccountsAddLteTable lists={lists} isLoading={isLoading} />
        </div>
      </div>
    </div>
  );
};

export default AccountsAddLteBody;
