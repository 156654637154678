/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import useFetch from '../../../../hooks/shared/custom/data/useFetch';
import { API_URL } from '../../../../utils/config';
import useKpiMonitor from './hooks/useKpiMonitor';

const AddKpi = () => {
  const { data, loading } = useFetch(`${API_URL}/department/employees/${sessionStorage.getItem('departmentId')}`);
  const { onChange, onSubmit, kpi, addingKpi } = useKpiMonitor();
  const numbers = [];
  for (let digit = 1; digit < 100; ) {
    numbers.push((digit += 1));
  }
  const { description, end_date, name, start_date } = kpi;
  return (
    <div
      id="add-kpi"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Add KPI
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body">
            <form
              className="form-horizontal"
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              <div className="form-group">
                <div className="col-lg-12 d-flex mb-3">
                  <div className="col-lg-6 p-0">
                    <div className="col-lg-12 mb-3">
                      <label className="m-0 p-0 copy-font">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="1000 Sales target on Island"
                        name="name"
                        onChange={onChange}
                        value={name}
                        required
                      />
                    </div>
                    <div className="col-lg-12">
                      <label className="m-0 p-0 copy-font">Start Date</label>
                      <input
                        type="date"
                        className="form-control"
                        name="start_date"
                        onChange={onChange}
                        value={start_date}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label className="m-0 p-0 copy-font">Description</label>
                    <textarea
                      rows="5"
                      cols="50"
                      className="form-control"
                      placeholder="KPI Description"
                      name="description"
                      onChange={onChange}
                      value={description}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12 d-flex mb-3">
                  <div className="col-lg-6">
                    <label className="m-0 p-0 copy-font">End Date</label>
                    <input
                      type="date"
                      className="form-control"
                      name="end_date"
                      onChange={onChange}
                      value={end_date}
                      required
                    />
                  </div>

                  <div className="col-lg-6">
                    <label className="m-0 p-0 copy-font">Status</label>
                    <select onChange={onChange} name="status" className="form-control" required>
                      <option>Choose Status</option>
                      {numbers.map((number) => {
                        return (
                          <option key={number} value={number.id}>
                            {number}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-lg-12 d-flex mb-3">
                  <div className="col-lg-6">
                    <label className="m-0 p-0 copy-font">Staff</label>
                    <select className="form-control" name="employee_id" onChange={onChange} required>
                      <option value="">--Select Employee--</option>
                      {!loading && data
                        ? data
                            .filter((e) => e.employees.length > 0)
                            .map((employee) => (
                              <option
                                key={employee.employees[0].id}
                                value={employee.employees[0].id}
                              >{`${employee.employees[0].firstName} ${employee.employees[0].lastName}`}</option>
                            ))
                        : null}
                    </select>
                  </div>
                </div>

                <div className="text-center">
                  <button disabled={addingKpi} type="submit" className="btn btn-green">
                    {addingKpi ? 'Submitting' : ' Add KPI'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddKpi;
