import axios from 'axios';
import Auth from '../../components/staff/shared/auth/presentational/Auth';

export const fetchAllBanksByCountry = async (country = 'nigeria') => {
  try {
    const response = await axios.get(`https://api.paystack.co/bank?country=${country}`);
    if (response.status === 200 && response.data.data.length > 0) {
      return response.data.data;
    }
  } catch (error) {
    console.log('error fetching available banks');
  }
};

export const getAccountDetails = async (accountNumber, bankCode) => {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: `Bearer sk_test_9818683fcbb7d36db80034243b495ee738b2b7d6`,
  };
  try {
    const response = await axios.get(
      `https://api.paystack.co/bank/resolve?account_number=${accountNumber}&bank_code=${bankCode}`,
      { headers: headers },
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log('error fetching account details');
  }
};
