import React from 'react';
import NocNavBar from '../../../../../layouts/noc/NocNavBar';
import Subscribers from '../../../../shared/lte/subscribers/expired/Subscribers';

const NocExpiredSubscribers = () => {
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <NocNavBar currentPage="LTE Expired Subscribers" />
        <Subscribers />
      </main>
    </div>
  );
};

export default NocExpiredSubscribers;
