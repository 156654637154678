import React, { Component } from 'react';

export default class AddPlans extends Component {
  render() {
    return (
      <div
        className="modal fade"
        id="plan"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title mt-1">Add Plan</h4>
              <button type="button" className="close" data-dismiss="modal">
                ×
              </button>
            </div>

            <div className="modal-body" id="add-plan">
              <form action="javascript:void(0)" className="form-horizontal">
                <div className="form-group text-left">
                  <div className="col-md-12 mb-3 d-flex">
                    <div className="col-lg-6">
                      <label className="copy-font">Name</label>
                      <input type="text" className="form-control" placeholder="Plan Name" />
                    </div>

                    <div className="col-lg-6">
                      <label className="copy-font">Amount</label>
                      <input type="text" className="form-control" placeholder="Amount" />
                    </div>
                  </div>

                  <div className="col-md-12 mb-3 d-flex">
                    <div className="col-lg-6">
                      <label className="copy-font">Time Allocated(seconds)</label>
                      <input type="text" className="form-control" placeholder="Time Allocated(seconds)" />
                    </div>

                    <div className="col-lg-6">
                      <label className="copy-font">Maximum no of users</label>
                      <input type="text" className="form-control" placeholder="Maximum no of users" />
                    </div>
                  </div>

                  <div className="col-md-12 mb-3 d-flex">
                    <div className="col-lg-6">
                      <label className="copy-font">Call Recording Durations(days)</label>
                      <input type="text" className="form-control" placeholder="Call Recording Durations" />
                    </div>

                    <div className="col-lg-6">
                      <label className="copy-font">Duration (Months)</label>
                      <input type="text" className="form-control" placeholder="Duration" />
                    </div>
                  </div>
                </div>

                <div className="text-center mt-5">
                  <button type="button" className="btn btn-green" data-dismiss="modal">
                    Add Plan
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
