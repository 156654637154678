/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import group from '../../../../../assets/img/icons/Group.png';
import { useStaff } from '../../../../../hooks/shared/staff/useStaff';

const NewStaff = () => {
  const { newStaff } = useStaff();
  return (
    <div className="col-lg-12">
      <div className="boxes">
        <div className="text-center mt-3">
          <img src={group} />
        </div>

        <div className="count">{newStaff ? newStaff.length : null}</div>

        <h3 style={{ color: '#5cc14b' }}>New Staff</h3>
      </div>
    </div>
  );
};

export default NewStaff;
