import React from 'react';
// import ReactPaginate from 'react-paginate';
import CustomersTable from './CustomersTable';
import AddDedicatedCustomer from './AddDedicatedCustomer';
import { DedicatedProvider } from '../../../../../context/sales/DedicatedContext';

const DedicatedCustomers = () => {
  return (
    <DedicatedProvider>
      <div className="container">
        <div className="row mt-5">
          <div className="mt-4 col-lg-12 col-md-12 col-sm-12">
            <div className="text-right">
              <button
                className="btn btn-green mb-2"
                type="submit"
                style={{ cursor: 'pointer' }}
                data-toggle="modal"
                data-target="#addDedicated"
              >
                Add Dedicated Customer
              </button>
              <AddDedicatedCustomer />
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="card x-panel mt-3">
                  <div className="table-responsive content">
                    <table className="table copy-font">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Expiration Date</th>
                          <th>Price</th>
                          <th>Address</th>
                          <th>Item</th>
                          <th>Mac ID</th>

                          <th>Actions</th>
                        </tr>
                      </thead>

                      <CustomersTable />
                    </table>
                  </div>
                </div>
              </div>
              {/* <div className="pagination pull-right"> */}
              {/*  <ReactPaginate */}
              {/*    previousLabel="previous" */}
              {/*    nextLabel="next" */}
              {/*    breakLabel="..." */}
              {/*    breakClassName="break-me" */}
              {/*    //  pageCount={pageCount} */}
              {/*    pageCount="2" */}
              {/*    marginPagesDisplayed={2} */}
              {/*    pageRangeDisplayed={3} */}
              {/*    //  onPageChange={this.handlePageClick} */}
              {/*    containerClassName="pagination" */}
              {/*    subContainerClassName="pages pagination" */}
              {/*    activeClassName="active" */}
              {/*  /> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </DedicatedProvider>
  );
};
export default DedicatedCustomers;
