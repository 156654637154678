/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';

const HotspotAdminLogo = () => {
  return (
    // eslint-disable-next-line no-script-url
    <Link
      className="navbar-brand"
      to="javascript:void(0)"
      id="control"
      style={{ marginTop: '-1%' }}
    >
      <img
        src="https://res.cloudinary.com/teewhy/image/upload/v1548240848/Hotspot_Retailer_Admin_Logo.png"
        alt="homepage"
        data-toggle="collapse"
        data-target="#demo-profile"
        className="hotspot-profile"
        style={{ width: '60%' }}
      />

      <ul id="demo-profile" className="collapse">
        <li className="copy-font">
          <Link className="navbar-brand" to="/admin">
            <img
              src="https://res.cloudinary.com/tizeti/image/upload/v1542971047/Tizeti-Logo.png"
              alt="homepage"
              style={{ width: '90%' }}
            />
          </Link>
        </li>
        <li>
          <Link
            className="navbar-brand"
            to="/admin/wifi-call"
          >
            <img
              src="https://res.cloudinary.com/teewhy/image/upload/v1545234921/wifi-without-dropdown.fw.png"
              alt="homepage"
              style={{ width: '90%' }}
            />
          </Link>
        </li>
      </ul>
    </Link>
  );
};

export default HotspotAdminLogo;
