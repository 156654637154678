import React from 'react';

export default {
  referralStatusLabels(status) {
    switch (status) {
      case 'approved':
        return <span className="badge badge-success">{status.toUpperCase()}</span>;

      case 'paid':
        return <span className="badge badge-success">{status.toUpperCase()}</span>;

      case 'uploaded':
        return <span className="badge badge-warning">{status.toUpperCase()}</span>;

      case 'awaiting':
        return <span className="badge badge-primary">{status.toUpperCase()}</span>;

      case 'rejected':
        return <span className="badge badge-danger">{status.toUpperCase()}</span>;

      default:
        return 'no status';
    }
  },
};
