/* eslint-disable import/prefer-default-export */
import * as load from '../../../constants/types/shared/LoadingTypes';
import { GET_EXPIRED_CUSTOMERS } from '../../../constants/types/customer-support/CustomerSupportTypes';

export const expiredReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case load.LOADING_STARTS:
      return {
        ...state,
        isLoading: true,
      };

    case load.LOADING_STOPS:
      return {
        ...state,
        isLoading: false,
      };

    case GET_EXPIRED_CUSTOMERS:
      return {
        ...state,
        nigeriaExpired: payload.nigeriaExpired,
        ghanaExpired: payload.ghanaExpired,
        LTEExpired: payload.LTEExpired,
        totalExpired: payload.totalExpired,
        isLoading: false,
      };

    default:
      return state;
  }
};
