/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import axios from 'axios';
import toastr from 'toastr';
import PropTypes from 'prop-types';
import { LOGGED_IN_USER_URL } from '../../../../../../services/api/auth/AuthUrl';
import { ADMIN, NEW_PRODUCTS, SUPER_ADMIN } from '../../../../../../utils/departments/helpers';
import Helper from '../../../../../../utils/helpers';
import { useCustomers } from '../../../../../../hooks/new-products/products/wificall/customers/useCustomers';
import { useUser } from '../../../../../../hooks/shared/user/useUser';
import WifiCallNavBar from '../../../../../layouts/wificall/WifiCallNavBar';
import UnverifiedCustomersBody from './UnverifiedCustomersBody';

const UnverifiedCustomers = ({ history }) => {
  const {
    unverified,
    pages,
    isLoading,
    filteredPages,
    source,
    handleSource,
    planType,
    handlePlanType,
    handlePageClick,
    filterBySource,
    filterByAccountType,
    searchWithEmail,
    searchWithPhone,
  } = useCustomers();
  const { profile } = useUser();
  const { firstName } = profile;
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    const checkDepartment = async () => {
      try {
        const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });
        if (
          response.data.department_id !== `${ADMIN}` &&
          response.data.department_id !== `${NEW_PRODUCTS}` &&
          response.data.department_id !== `${SUPER_ADMIN}`
        ) {
          sessionStorage.clear();
          sessionStorage.setItem('token', '');
          sessionStorage.setItem('email', '');
          sessionStorage.setItem('departmentId', '');
          sessionStorage.setItem('roleId', '');
          history.push('/login');
          toastr.info('You are not allowed to view this page');
        }
      } catch (e) {
        // console.log('error', e.response);
      }
    };
    checkDepartment();
  }, [history]);

  const searchWithParams = (e) => {
    e.preventDefault();
    if (Helper.emailIsValid(keyword)) {
      const data = {
        email: keyword,
      };
      searchWithEmail(data);
    } else {
      const data = {
        phone: keyword,
      };
      searchWithPhone(data);
    }
  };

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <WifiCallNavBar currentPage="Unverified Customers" position={firstName} />

        <div className="pt-5">
          <div className="row">
            <div className="col-12 mt-5">
              <form onSubmit={searchWithParams} className="form-inline pb-2 pl-4">
                <input
                  className="form-control mr-3 empty mt-1"
                  value={keyword}
                  name="keyword"
                  id="iconified"
                  type="text"
                  placeholder="Search using phone number and email"
                  onChange={(e) => setKeyword(e.target.value)}
                />
              </form>
            </div>
            <UnverifiedCustomersBody
              customers={unverified}
              isLoading={isLoading}
              filterBySource={filterBySource}
              filterByAccountType={filterByAccountType}
              filteredPages={filteredPages}
              source={source}
              handleSource={handleSource}
              planType={planType}
              handlePlanType={handlePlanType}
            />

            <div className="px-3">
              {pages > 1 ? (
                <ReactPaginate
                  previousLabel="previous"
                  nextLabel="next"
                  breakLabel="..."
                  breakClassName="break-me"
                  pageCount={pages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName="pagination"
                  subContainerClassName="pages pagination"
                  activeClassName="active"
                />
              ) : null}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

UnverifiedCustomers.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default UnverifiedCustomers;
