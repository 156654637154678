import React from 'react';
import NewDesignInstallationZoneTable from './NewDesignInstallationZoneTable';

// import Search from './Search';

const NewDesignInstallationZoneBody = () => {
  return (
    <div>
      <div className="col-lg-12 col-md-12 col-sm-12 mb-3 d-flex mt-3 flex-wrap actions" />
      <div className="card x-panel">
        <NewDesignInstallationZoneTable />
      </div>
    </div>
  );
};

export default NewDesignInstallationZoneBody;
