/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import SalesSideBar from '../../../../layouts/sales/SalesSideBar';
import Logout from '../../../shared/auth/Logout';
import LteKyc from '../../../shared/lte/kyc/LteKyc';

const SalesLteKyc = () => {
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <div className="d-flex col-lg-12 p-2 top-header">
          <div className="mt-3 d-flex col-lg-12 p-0">
            <Link className="s-sidebar__trigger" to="#">
              <i className="fa fa-bars mr-3" />
            </Link>
            <SalesSideBar />

            <h3 className="col-lg-6 overview-part">Lte Kyc</h3>

            <div className="d-flex profile-mail">
              <h6 className="mr-0 mt-1">Sales</h6>
              <Logout />
            </div>
          </div>
        </div>
        <LteKyc />
      </main>
    </div>
  );
};

export default SalesLteKyc;
