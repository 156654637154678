/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-empty */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import axios from 'axios';
import { Toast as toastr } from 'react-toastify';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import { admin } from '../../../../../utils/config';
import { getUser } from '../../../../../actions/shared/user/UserActions';
import { getLoggedInUser } from '../../../../../actions/user/UserActions';
import Retention from '../../../shared/retentions/Retention';
import SuperAdminNavBar from '../../../../layouts/super-admin/SuperAdminNavBar';

class AdminRetention extends Component {
  static propTypes = {
    getLoggedInUser: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    getAboutToExpire: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.getUser();
    this.props.getLoggedInUser();
    this.checkDepartment();
  }

  checkDepartment = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.department_id !== `${admin}`) {
        this.props.history.push('/');

        sessionStorage.setItem('token', '');
        sessionStorage.clear();

        toastr.info('You are not allowed to view this page');
      }
    } catch (e) {}
  };

  render() {
    const { user, role } = this.props;
    const { role_id } = role;
    const { firstName } = user;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <SuperAdminNavBar currentPage="Retention" roleID={role_id} position={firstName} />
          <Retention />
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  role: state.auth.auth,
});

export default connect(
  mapStateToProps,
  { getUser, getLoggedInUser },
)(AdminRetention);
