/* eslint-disable react/require-default-props */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import Approve from './Approve';
import {
  ACCOUNTS,
  ADMIN,
  ADMIN_DEPARTMENT,
  CUSTOMER_SUPPORT,
  EXPRESS_WIFI,
  NEW_PRODUCTS,
  NOC,
  OPERATIONS,
  PROCUREMENT,
  RESEARCH_AND_DEVELOPMENT,
  SALES,
} from '../../../../../../utils/departments/helpers';
import view from '../../../../../../assets/img/icons/view.png';
import ViewReceipt from '../../../../shared/requisition/shared/ViewReceipt';
import CommonRequisitionDetail from '../../../../shared/requisition/shared/CommonRequistionDetails';
import ExecutivesTableHead from '../../../../shared/requisition/executives/v1/ExecutivesTableHead';
import helpers from '../../../../../../utils/requisition/helpers';
import sharedHelpers from '../../../../../../utils/helpers';
import { ASSET_TEAM, BUSINESS_DEVELOPMENT, PARTNERSHIP } from '../../../../../../utils/config';

const ExecutivesRequsitions = ({
  requistions,
  reloadFunction,
  handlePageClick,
  totalPages,
  load,
  giveExecutiveApproval,
  giveExecutiveDisApproval,
  approving,
  showModal,
  onClickShowModal,
  setCheckAllRequisition,
  setCheckOneOfAllRequisition,
}) => {
  //this is for account only
  const [requisit, setRequisit] = useState({});
  const [viewFile, setViewFile] = useState({});
  const getRequsition = (req) => {
    setRequisit(req);
  };
  const getFileRequsition = (req) => {
    setViewFile(req);
    if (req.imageUpload.split('.').pop() === 'document' || req.imageUpload.split('.').pop() === 'pdf') {
      window.open(req.imageUpload, '_blank');
    }
  };
  const department = new Map([
    [`${ACCOUNTS}`, 'Accounts'],
    [`${ADMIN}`, 'ADMIN'],
    [`${ADMIN_DEPARTMENT}`, 'ADMIN_DEPARTMENT'],
    [`${CUSTOMER_SUPPORT}`, 'CUSTOMER SUPPORT'],
    [`${EXPRESS_WIFI}`, 'EXPRESS WIFI'],
    [`${NEW_PRODUCTS}`, 'NEW PRODUCTS'],
    [`${NOC}`, 'NOC'],
    [`${OPERATIONS}`, 'OPERATIONS'],
    [`${PROCUREMENT}`, 'PROCUREMENT'],
    [`${RESEARCH_AND_DEVELOPMENT}`, 'RESEARCH AND DEVELOPMENT'],
    [`${SALES}`, 'SALES'],
    [`${BUSINESS_DEVELOPMENT}`, 'BUSINESS DEVELOPMENT'],
    [`${ASSET_TEAM}`, 'ASSET TEAM'],
    [`${PARTNERSHIP}`, 'PARTNERSHIP'],
  ]);
  function getDepartment(name) {
    return <strong>{department.get(name)}</strong> || 'null';
  }
  const requsitionList =
    requistions && requistions.length ? (
      requistions.map((requsition, i) => {
        return (
          <tr key={requsition.id}>
            <td>{i + 1}</td>

            <td>
              {helpers.displayCurrency(requsition.currency)}
              {requsition.amount_in_digits}
            </td>
            <td>{requsition.amount_in_words}</td>
            <td>{requsition.in_favour_of}</td>
            <td>{requsition.purpose}</td>
            <td>{requsition.account_approval}</td>
            <td>{requsition.manager_approval}</td>
            <td>{helpers.showCLevelApprovalStatus(requsition.c_level_approval)}</td>
            <td>{requsition.ceo_or_coo_approval}</td>
            <td>{requsition.requires_c_level_approval === false ? 'False' : 'True'}</td>
            <td>{requsition.requires_ceo_or_coo_approval === false ? 'False' : 'True'}</td>
            <td>{getDepartment(requsition.department_id)}</td>
            {requsition.created_at ? (
              <td className="text-nowrap">{sharedHelpers.formatDateAndTime(requsition.created_at)}</td>
            ) : (
              'No Date'
            )}
            <td>
              {/* <small onClick={() => giveExecutiveApproval(requsition.id)}>
                <button className="btn btn-green">Approve</button>
              </small> */}
              {/* <button
                className="btn btn-green"
                data-toggle="modal"
                data-target="#approveRequsition"
                onClick={() => getRequsition(requsition)}
              >
                Approve
              </button> */}
              {/* this */}
              <small onClick={() => getRequsition(requsition)} style={{ cursor: 'default' }}>
                <button onClick={() => onClickShowModal()} className="btn btn-green">
                  Approve
                </button>

                {showModal ? (
                  <Approve
                    giveCfoApproval={giveExecutiveApproval}
                    reloadFunction={reloadFunction}
                    onClickShowModal={onClickShowModal}
                    approving={approving}
                    requisit={requisit}
                  />
                ) : null}
              </small>
            </td>

            <td>
              <small onClick={() => giveExecutiveDisApproval(requsition.id)}>
                <button className="btn btn-danger">Reject</button>
              </small>
            </td>

            <td>
              <div className="mr-2 ml-2">
                <button
                  data-target="#viewReceipt"
                  data-toggle="modal"
                  type="submit"
                  onClick={() => getFileRequsition(requsition)}
                  style={{
                    border: '0',
                    backgroundColor: 'white',
                  }}
                >
                  <span className="view2">
                    <img src={view} alt="view" data-toggle="tooltip" data-placement="left" title="View" />
                  </span>
                </button>
                <ViewReceipt requisit={viewFile} />
              </div>
            </td>
            <td>
              <div className="mr-2 ml-2 d-flex">
                <button
                  data-target="#requisition"
                  data-toggle="modal"
                  type="submit"
                  onClick={() => getRequsition(requsition)}
                  style={{
                    border: '0',
                    backgroundColor: 'white',
                  }}
                >
                  <span className="view2">
                    <img src={view} alt="view" data-toggle="tooltip" data-placement="left" title="View" />
                  </span>
                </button>
                <CommonRequisitionDetail requisit={requisit} />
              </div>
            </td>
          </tr>
        );
      })
    ) : (
      <p>No requisitions</p>
    );

  return (
    <div className="card x-panel logs">
      <div className="table-responsive content">
        <table className="table copy-font">
          <ExecutivesTableHead setCheckAllRequisition={setCheckAllRequisition} />
          {load ? <small>loading...</small> : <tbody>{requsitionList}</tbody>}
        </table>
      </div>

      {totalPages > 1 ? (
        <ReactPaginate
          previousLabel="previous"
          nextLabel="next"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName="pagination"
          subContainerClassName="pages pagination"
          activeClassName="active"
        />
      ) : null}
    </div>
  );
};

ExecutivesRequsitions.propTypes = {
  requistions: PropTypes.array,
  load: PropTypes.bool,
  giveExecutiveApproval: PropTypes.func,
  giveExecutiveDisApproval: PropTypes.func,
  handlePageClick: PropTypes.func,
  totalPages: PropTypes.number,
  approving: PropTypes.bool,
  onClickShowModal: PropTypes.func,
  showModal: PropTypes.bool,
};

export default ExecutivesRequsitions;
