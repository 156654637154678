/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import NocNavBar from '../../../../layouts/noc/NocNavBar';
import { NOC, SUPER_ADMIN } from '../../../../../utils/departments/helpers';
import VoltageMonitor from '../../../shared/noc/voltage-monitor/VoltageMonitor';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import useCheckTwoDepartments from '../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';

const NetworkOperationsVoltage = ({ history }) => {
  useCheckTwoDepartments(`${NOC}`, `${SUPER_ADMIN}`, history);
  const firstName = sessionStorage.getItem('firstName');

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <NocNavBar currentPage="Voltage Monitor" position={firstName} />
        <VoltageMonitor />
      </main>
    </div>
  );
};

NetworkOperationsVoltage.propTypes = {
  history: PropTypes.shape(),
};

export default NetworkOperationsVoltage;
