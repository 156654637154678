/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import GeniatechContext from '../../../../../../../context/GeniatechContext';
import useGeniatech from '../../../../../../../hooks/shared/geniatech/useGeniatech';
import { TOWER_URL } from '../../towers/api/TowerUrl';
import useStaticAccessPoints from '../hooks/useStaticAccessPoints';

const StaticAccessPointProvider = ({ children }) => {
  const {
    fetching,
    searchData,
    reset,
    fetched,
    performanceSearchStatus,
    searchPerformance,
    searchStatus,
    resetPerformanceSearch,
    performanceResults,
    rebootAccessPoints,
  } = useStaticAccessPoints();

  const { data, loading, pages } = useGeniatech(
    { action: 'towers', offset: '0', limit: '100', network_id: 'tz-xwf' },
    TOWER_URL,
  );

  return (
    <GeniatechContext.Provider
      value={{
        data,
        loading,
        searchData,
        pages,
        searchStatus,
        reset,
        fetched,
        fetching,
        performanceSearchStatus,
        searchPerformance,
        resetPerformanceSearch,
        performanceResults,
        rebootAccessPoints,
      }}
    >
      {children}
    </GeniatechContext.Provider>
  );
};
export default StaticAccessPointProvider;
