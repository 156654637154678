import React from 'react';
import KpiMonitorTable from './KpiMonitorTable';
import useKpiMonitor from './hooks/useKpiMonitor';

const PersonnelAssignedKpis = () => {
  const { isLoading, employeeAssignedKpis } = useKpiMonitor();
  return (
    <div className="row">
      <KpiMonitorTable
        isLoading={isLoading}
        kpis={
          employeeAssignedKpis && employeeAssignedKpis.logs && employeeAssignedKpis.logs.length > 0
            ? employeeAssignedKpis.logs
            : []
        }
      />
    </div>
  );
};

export default PersonnelAssignedKpis;
