import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import { get_all_basestation_zones } from '../../../../../../actions/shared/basestations/GetAllBasestationsZones';
import { get_bizdev_active_inactive_by_zone } from '../../../../../../actions/coo-statistics/GetAllActiveInactiveDedicatedCustomers';
import ActiveInactiveTurboByZoneTable from './ActiveInactiveTurboByZoneTable';
import ChartsContainer from './ChartsContainer';

const initialDedicatedInstalls = {
  action: 'get_all_dedicated_customers',
  pageno: '1',
  country: 'Nigeria',
  no_of_records_per_page: '1000',
  filterby: 'zone',
  zone: '',
};

const DemoPaper = styled(Paper)(({ theme }) => ({
  width: 170,
  height: 170,
  padding: theme.spacing(2),
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'space-evenly',
  justifyContent: 'center',
}));
export default function ActiveExpiredTurboByZone() {
  const options = [
    { label: 'Active & Inactive', value: 1 },
    { label: 'Active', value: 2 },
    { label: 'Inactive', value: 3 },
  ];

  const [getZones, setGetZones] = useState([]);
  const [getAllCustomers, setGetAllCustomers] = useState([]);
  const [stateWithHighestActiveCount, setStateWithHighestActiveCount] = useState('');
  const [stateWithHighestInActiveCount, setStateWithHighestInActiveCount] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const zones = await get_all_basestation_zones();
        setGetZones(zones);

        const allCustomers = await Promise.all(
          zones.map(async (zone) => {
            const data = await get_bizdev_active_inactive_by_zone({
              ...initialDedicatedInstalls,
              zone: zone.name,
            });
            return { data };
          }),
        );

        setGetAllCustomers(allCustomers);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array to run only on mount

  // console.log(getZones);
  // console.log(getAllCustomers);

  const ActiveInactiveData = getAllCustomers.map((data) => +data.data.items.length);
  const ActiveData = getAllCustomers.map((data) =>
    data.data.items.length > 0 ? data.data.items.filter((active) => active.status === 'active') : 0,
  );
  const InActiveData = getAllCustomers.map((data) =>
    data.data.items.length > 0 ? data.data.items.filter((inactive) => inactive.status === 'expired') : 0,
  );

  const sumOfActiveInactiveData = ActiveInactiveData.reduce((sum, value) => sum + value, 0);

  const getAllNonZeroActive = ActiveData.filter((data) => Array.isArray(data)).map((data) => data.length);
  const getAllNonZeroInActive = InActiveData.filter((data) => Array.isArray(data)).map((data) => data.length);
  const sumOfActiveData = getAllNonZeroActive.reduce((sum, value) => sum + value, 0);
  const sumOfInActiveData = getAllNonZeroInActive.reduce((sum, value) => sum + value, 0);
  const HighestNumOfActiveTurbo = Math.max(...getAllNonZeroActive);
  const HighestNumOfInActiveTurbo = Math.max(...getAllNonZeroInActive);

  useEffect(() => {
    if (HighestNumOfActiveTurbo !== -Infinity && !isNaN(HighestNumOfActiveTurbo) && ActiveData.length > 0) {
      // console.log(ActiveData);
      const findIndexOfState = ActiveData.findIndex(
        (data) => Array.isArray(data) && data.length === HighestNumOfActiveTurbo,
      );
      // console.log(findIndexOfState);

      if (findIndexOfState && getZones.length > 0) {
        const getStateWithHighestActiveCount = getZones[findIndexOfState];
        setStateWithHighestActiveCount(getStateWithHighestActiveCount.name);
        // console.log(getStateWithHighestActiveCount.name);
      }
    }
    if (HighestNumOfInActiveTurbo !== -Infinity && !isNaN(HighestNumOfInActiveTurbo) && ActiveData.length > 0) {
      // console.log(ActiveData);
      const findIndexOfState = InActiveData.findIndex(
        (data) => Array.isArray(data) && data.length === HighestNumOfInActiveTurbo,
      );
      // console.log(findIndexOfState);

      if (findIndexOfState && getZones.length > 0) {
        const getStateWithHighestInActiveCount = getZones[findIndexOfState];
        setStateWithHighestInActiveCount(getStateWithHighestInActiveCount.name);
        // console.log(getStateWithHighestInActiveCount.name);
      }
    }
  }, [HighestNumOfActiveTurbo, HighestNumOfInActiveTurbo]);

  return (
    <Box sx={{ ml: '2rem', mt: '2rem', mr: '2rem' }} display="flex" justifyContent="space-between">
      <Box>
        <Typography variant="h4">Active and expired turbo by zone</Typography>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
          <Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'flex-start',
                flexWrap: 'wrap',
                gap: 2,
              }}
            >
              <DemoPaper square={false} sx={{ background: '#8884d8', color: 'white' }}>
                <Typography sx={{ fontWeight: 'bolder' }}>Total Active and Expired Turbo</Typography>
                <Divider flexItem />
                <Typography variant="h3" sx={{ color: 'white' }}>
                  {sumOfActiveInactiveData < 1 ? '...' : sumOfActiveInactiveData}
                </Typography>
              </DemoPaper>
              <DemoPaper square={false} sx={{ background: '#84d888', color: 'white' }}>
                <Typography sx={{ fontWeight: 'bolder' }}>Total Active Turbo</Typography>
                <Divider flexItem />
                <Typography variant="h3" sx={{ color: 'white' }}>
                  {sumOfActiveData < 1 ? '...' : sumOfActiveData}
                </Typography>
              </DemoPaper>
              <DemoPaper square={false} sx={{ background: '#d88784', color: 'white' }}>
                <Typography sx={{ fontWeight: 'bolder' }}>Total Inactive Turbo</Typography>
                <Divider flexItem />
                <Typography variant="h3" sx={{ color: 'white' }}>
                  {sumOfInActiveData < 1 ? '...' : sumOfInActiveData}
                </Typography>
              </DemoPaper>
              <DemoPaper square={false} sx={{ background: '#cac382', color: 'white' }}>
                <Typography sx={{ fontWeight: 'bolder' }}>Highest Active Turbo Users</Typography>
                <Divider flexItem />
                <Box>
                  <Typography variant="h3" sx={{ color: 'white' }}>
                    {HighestNumOfActiveTurbo === -Infinity ? '...' : HighestNumOfActiveTurbo}
                  </Typography>
                  <Typography sx={{ color: 'white' }}>{stateWithHighestActiveCount}</Typography>
                </Box>
              </DemoPaper>
              <DemoPaper square={false} sx={{ background: '#c482ca', color: 'white' }}>
                <Typography sx={{ fontWeight: 'bolder' }}>Highest Inactive Turbo Users</Typography>
                <Divider flexItem />
                <Box>
                  <Typography variant="h3" sx={{ color: 'white' }}>
                    {HighestNumOfInActiveTurbo === -Infinity ? '...' : HighestNumOfInActiveTurbo}
                  </Typography>
                  <Typography sx={{ color: 'white' }}>{stateWithHighestInActiveCount}</Typography>
                </Box>
              </DemoPaper>
            </Box>

            <ChartsContainer
              getZones={getZones}
              ActiveInactiveData={ActiveInactiveData}
              ActiveData={ActiveData}
              InActiveData={InActiveData}
            />
            <ActiveInactiveTurboByZoneTable
              getZones={getZones}
              ActiveInactiveData={ActiveInactiveData}
              ActiveData={ActiveData}
              InActiveData={InActiveData}
              isLoading={isLoading}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
