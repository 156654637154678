/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useContext, ReactNode } from 'react';
import DeleteSVG from '../../../../../assets/svg/delete-button.svg';
import { NoItemRow } from '../../../../../utils/loader/loadingHelper';
import { ZonesContext } from '../../../shared/installations/zones/provider/ZonesContext';
import DeleteZoneModal from '../zones/DeleteZoneModal';

const SubZonesTable = () => {
  const { subZones, onShowModal } = useContext(ZonesContext);
  const [subZone, setSubZone] = useState({ id: '', name: '' });

  const getSubZone = (param) => {
    onShowModal();
    setSubZone(param);
  };

  const subZonesTable =
    subZones && subZones.length ? (
      subZones.map((zone, i) => (
        <tr>
          <td>{i + 1}</td>
          <td>{zone.name}</td>
          <td>
            <div style={{ paddingLeft: '10px' }}>
              <img
                onClick={() => getSubZone(zone)}
                src={DeleteSVG}
                alt="delete-button"
                width="15px"
                className="img-pointer"
              />
            </div>
          </td>
        </tr>
      ))
    ) : (
      <NoItemRow colSpan="3" text="No Subzones" />
    );

  return (
    <>
      {subZonesTable}
      <DeleteZoneModal zone={subZone} type="subzone" />
    </>
  );
};

export default SubZonesTable;
