import React from 'react';
import PropTypes from 'prop-types';
import caution from '../../../../../../assets/img/icons/Caution.png';
import { showDepartmentName } from '../../../../../../utils/departments/helpers';
import { usePurchaseOrder } from '../../../../../../hooks/shared/purchase-order/usePurchaseOrder';

const ConfirmDepartmentsPO = ({ order: { item, quantity, department_id, amount, currency, id } = {} }) => {
  const {
    accountsPODecision,
    handlePODecision,
    PODecision: { approvalStatus, approvalComment },
  } = usePurchaseOrder();

  const confirmOrder = (e) => {
    e.preventDefault();
    const data = {
      account_approval: approvalStatus,
      account_comment: approvalComment,
    };
    accountsPODecision(id, data);
  };

  return (
    <div
      className="modal fade"
      id="confirmPO"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content mt-5 py-4">
          <div className="text-center">
            <img src={caution} alt="" width="60px" />
          </div>
          <div className="modal-body p-3">
            <div className="row">
              <form className="col-12 text-center">
                <div className="">
                  <h6 className="">
                    Confirm order of <strong>{`${quantity} ${item}`}</strong> for{' '}
                    <strong>{`${currency} ${amount}`}</strong> requested by{' '}
                    <strong>{showDepartmentName.get(department_id)}</strong>?
                  </h6>
                </div>
                <div className="justify-content-center py-3">
                  <div className="px-5">
                    <div className="px-5">
                      <select
                        className="form-control border border-secondary borderRadius0"
                        name="approvalStatus"
                        defaultValue={approvalStatus}
                        onChange={handlePODecision}
                      >
                        <option value="approved">Approve</option>
                        <option value="rejected">Reject</option>
                      </select>
                    </div>
                  </div>
                  <div className="my-2 px-5">
                    <textarea
                      placeholder="Comment"
                      className="form-control border border-secondary shadow-sm borderRadius0"
                      name="approvalComment"
                      value={approvalComment}
                      onChange={handlePODecision}
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <button type="button" className="btn btn-secondary mr-2" data-dismiss="modal">
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-green" onClick={confirmOrder}>
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ConfirmDepartmentsPO.propTypes = {
  order: PropTypes.objectOf.isRequired,
};

export default ConfirmDepartmentsPO;
