import React from 'react';
import { OverviewProvider } from '../../../../../context/shared/installations/overview/OverviewContext';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import SalesNavBar from '../../../../layouts/sales/SalesNavBar';
import RescheduledInstallationsBody from './RescheduledInstallationsBody';

const SalesRescheduledInstallations = () => {
  const { profile } = useUser();

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-10">
        <SalesNavBar currentPage="Rescheduled Installations" position={profile.firstName} />
        <OverviewProvider>
          <RescheduledInstallationsBody />
        </OverviewProvider>
      </main>
    </div>
  );
};

export default SalesRescheduledInstallations;
