import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const ShowBandwidth = ({ loading, bandwidth }) => {
  const displayBandwidth = () => {
    if (loading) {
      return (
        <div className="col-lg-12 d-flex">
          <small>loading...</small>
        </div>
      );
    }
    return (
      <Fragment>
        {bandwidth.map((band) => (
          <Fragment>
            <div className="col-lg-12 d-flex">
              <div className="col-lg-6 detail-label3">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
                <label id="email">Customer MacID</label>
              </div>
              <div className="col-lg-6 detail-result3">
                {/* eslint-disable-next-line @typescript-eslint/camelcase */}
                <p className="mt-4 mb-0">{band.customer_username}</p>
              </div>
            </div>

            <div className="col-lg-12 d-flex">
              <div className="col-lg-6 detail-label3">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
                <label id="email">Customer Bandwidth Value</label>
              </div>
              <div className="col-lg-6 detail-result3">
                {/* eslint-disable-next-line @typescript-eslint/camelcase */}
                <p className="mt-4 mb-0">{band.value}</p>
              </div>
            </div>

            <div className="col-lg-12 d-flex">
              <div className="col-lg-6 detail-label3">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label id="email">Customer Bandwidth Group Name</label>
              </div>
              <div className="col-lg-6 detail-result3">
                {/* eslint-disable-next-line @typescript-eslint/camelcase */}
                <p className="mt-4 mb-0">{band.groupname}</p>
              </div>
            </div>
          </Fragment>
        ))}
      </Fragment>
    );
  };

  return (
    <div
      id="show-bandwidth-details"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" style={{ width: '600px' }}>
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Customer Bandwidth Details
            </h4>
            <button type="button" className="close" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body detail" style={{ padding: '0', textAlign: 'justify' }}>
            <div className="row">{displayBandwidth()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.dedicatedCustomers.getting,
  bandwidth: state.dedicatedCustomers.bandwidth,
});

ShowBandwidth.propTypes = {
  // eslint-disable-next-line react/require-default-props
  loading: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  bandwidth: PropTypes.shape(),
};

export default connect(
  mapStateToProps,
  null,
)(ShowBandwidth);
