import { GET_DATA } from '../../../../../../constants/types/shared/CustomTypes';
import { LOADING_STARTS, LOADING_STOPS } from '../../../../../../constants/types/shared/LoadingTypes';

const lteInstallationReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_DATA:
      return {
        ...state,
        loading: false,
        data: payload,
        // pages: payload.pages,
      };
    case LOADING_STARTS:
      return {
        ...state,
        loading: true,
      };
    case LOADING_STOPS:
      return {
        ...state,
        loading: false,
      };
    case 'RESET_SEARCH_STATUS':
      return { ...state, searchStatus: false };
    case 'SET_SEARCH_STATUS':
      return { ...state, searchStatus: true };
    case 'FETCH_DATA':
      return { ...state, loading: false, fetched: payload };
    case 'SEARCH_TYPE':
      return { ...state, searchType: payload };
    case 'LTE_INSTALLATION_COUNT':
      return { ...state, count: payload };
    default:
      return state;
  }
};
export default lteInstallationReducer;
