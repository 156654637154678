/* eslint-disable no-nested-ternary */
import React from 'react';
import UploadXWFCustomer from './UploadXWFCustomer';

const SearchXWFCustomers = ({ searchCustomers, details, onOptionChange }) => {
  const page = 1;
  const days = [7, 30];
  const { option, optionValue, fromDate, toDate } = details;

  const searchForUser = (e) => {
    e.preventDefault();
    searchCustomers(page);
  };

  return (
    <div className="col-lg-12 mt-5 mb-0 d-flex">
      <div>
        <form onSubmit={searchForUser} className="form-inline inner-addon d-flex">
          <div className="input-group mb-3 mr-3">
            <select name="option" onChange={onOptionChange} className="form-control mr-auto empty mt-1">
              <option value="">Choose fields to search from</option>
              <option value="companyName">Company Name</option>
              <option value="customerName">Customer Name</option>
              <option value="retailerName">Retailer</option>
              <option value="phoneNumber">Phone</option>
              <option value="duration">Duration</option>
              <option value="date">Date</option>
            </select>
          </div>
          {option === 'date' ? (
            <div className="input-group mb-3 mr-3">
              <small className="pt-2">From:</small>
              <input className="form-control" type="date" name="fromDate" value={fromDate} onChange={onOptionChange} />
              <small className="pt-2">To:</small>
              <input className="form-control" type="date" name="toDate" value={toDate} onChange={onOptionChange} />
            </div>
          ) : option === 'duration' ? (
            <div className="input-group mb-3 mr-3">
              <select className="form-control" name="optionValue" onChange={onOptionChange}>
                <option>--Select--</option>
                <option value="1-Day">1 Day</option>
                {days.map((day) => (
                  <option value={`${day}-Day`}>{day} Days</option>
                ))}
              </select>
            </div>
          ) : (
            <div className="input-group mb-3 mr-3">
              <input
                className="form-control mr-auto empty pl-5"
                type="text"
                placeholder="type search term"
                name="optionValue"
                value={optionValue}
                onChange={onOptionChange}
              />
              <span className="fa fa-search" />
            </div>
          )}
          <div className="mb-3">
            <button type="submit" className="btn btn-green">
              Search
            </button>
          </div>
        </form>
      </div>

      <div className="ml-2" style={{ marginTop: '1px' }}>
        <UploadXWFCustomer />
      </div>
    </div>
  );
};

export default SearchXWFCustomers;
