import { GET_CUSTOMER_REVENUE } from '../../../constants/types/business-development/customer-revenue/CustomerRevenueTypes';
import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';

const initialState = {
  items: [],
  totalPages: '',
  total: '',
  allPages: '',
  currentPage: '',
  isLoading: false,
};

const CustomerRevenueReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOADING_STARTS:
      return {
        ...state,
        isLoading: true,
      };

    case GET_CUSTOMER_REVENUE:
      return {
        ...state,
        items: payload.items,
        total: payload.total,
        totalPages: payload.totalPages,
        allPages: payload.allPages,
        currentPage: payload.currentPage,
        isLoading: false,
      };

    case LOADING_STOPS:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default CustomerRevenueReducer;
