/* eslint-disable react/require-default-props */
import React from 'react';
import { useCustomers } from '../../../../../hooks/new-products/products/wificall/customers/useCustomers';

const VerifiedAndUnverifiedCount = () => {
  const { verifiedCount, verified, unverified, unverifiedCount } = useCustomers();

  return (
    <div className="mr-auto col mb-3 d-none d-md-block">
      <div className="boxes">
        <div className="d-flex mt-3">
          <div className="mt-1 pl-1">
            <img
              src="https://res.cloudinary.com/teewhy/image/upload/v1545151448/book4.fw.png"
              alt=""
              className="w-75"
            />
          </div>
          <div className="p-2">
            <div className="count" id="count-resize">
              {unverified ? unverifiedCount : null}
            </div>
            <h3 id="count-resize-font">Unverified Users</h3>
          </div>
        </div>
        <div className="d-flex">
          <div className="mt-1 pl-1">
            <img
              src="https://res.cloudinary.com/teewhy/image/upload/v1545151448/book4.fw.png"
              alt=""
              className="w-75"
            />
          </div>
          <div className="p-2">
            <div className="count" id="count-resize">
              {verified ? verifiedCount.toLocaleString() : null}
            </div>
            <h3 id="count-resize-font">Verified Users</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifiedAndUnverifiedCount;
