import React from 'react';
import useFetch from '../../../../../hooks/shared/custom/data/useFetch';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import { CREATE_SUB_ZONE_URL } from '../../../../../services/api/shared/installations/ZonesUrl';
import CreatedTimesTable from './CreatedTimesTable';

const CreatedTimesBody = () => {
  const { data } = useFetch(`${CREATE_SUB_ZONE_URL}`);

  return (
    <div className="row">
      <div className="card x-panel pt-3">
        <div className="table-responsive content col-12">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th className="pl-4">Times</th>
              </tr>
            </thead>
            <tbody>
              {!data ? (
                <tr>
                  <td colSpan="3" className="text-center">
                    <img src={loader} alt="loading gif" />
                  </td>
                </tr>
              ) : (
                <CreatedTimesTable zones={data} />
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CreatedTimesBody;
