/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import caution from '../../../../assets/img/icons/Caution.png';
import useDeviceRetrieval from '../device-retrieval/hooks/useDeviceRetrieval';

const tabIndex = -1;
const ReturnRetrieval = ({ oneItem }) => {
  const { updateDeviceRetrieval } = useDeviceRetrieval();

  const confirmReturn = (e) => {
    e.preventDefault();
    const data = { status: 'uninstall' };
    updateDeviceRetrieval(oneItem.id, data);
  };

  return (
    <div
      className="modal fade"
      id="confirm_item"
      tabIndex={tabIndex}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content mt-5 py-4">
          <div className="text-center">
            <img src={caution} alt="" width="60px" />
          </div>
          <div className="modal-body p-3">
            <div className="row">
              <div className="col">
                <form className="form-horizontal text-center" onSubmit={confirmReturn}>
                  <div className="col">
                    <p>
                      <h5>
                        Move <strong>{`${oneItem.mac}`}</strong> from
                        <br />
                        <strong>{oneItem.name}</strong>
                        <br /> to Retention?
                      </h5>
                    </p>
                  </div>
                  <div className="mt-5">
                    <button type="button" className="btn btn-secondary mr-2" data-dismiss="modal">
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-green" onClick={confirmReturn}>
                      Confirm
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReturnRetrieval;
