import React, { useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import view from '../../../../../../assets/img/icons/view.png';
import Loader from '../../../../../../assets/img/icons/TizetiLoaderColored.gif';
import {
  GET_WIFICALL_USER_BY_ID_URL,
  GET_WIFICALL_USER_SIP_DETAILS_URL,
} from '../../../../../../services/api/wificall/UsersUrl';
import ViewCustomer from '../ViewCustomer';
import ConfirmModal from '../ConfirmModal';

const UnverifiedCustomersTable = ({ customers, load }) => {
  const [wallet, setWallet] = useState('');
  const [one, setOne] = useState({});

  const getCustomer = async (customer) => {
    setOne(customer);
    try {
      const res = await axios.get(`${GET_WIFICALL_USER_BY_ID_URL}/${customer.id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (res.data.username) {
        try {
          const response = await axios.get(`${GET_WIFICALL_USER_SIP_DETAILS_URL}/${res.data.username}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
          });
          if (response.data.status === true) {
            setWallet(response.data.result[0].credit);
          }
        } catch (e) {
          // console.log(e.response);
        }
      }
    } catch (e) {
      // console.log(e);
    }
  };

  const customersList =
    customers.length && customers ? (
      customers.map((customer, index) => (
        <tr key={customer.id}>
          <td>{index + 1}</td>
          {customer.firstName ? <td>{customer.firstName}</td> : <td>N/A</td>}
          {customer.lastName ? <td>{customer.lastName}</td> : <td>N/A</td>}
          {customer.email ? <td>{customer.email}</td> : <td>N/A</td>}
          {customer.phoneNumber ? <td>{customer.phoneNumber}</td> : <td>N/A</td>}
          <td className="text-center">{customer.planType ? customer.planType : 'N/A'}</td>
          <td className="text-center">{customer.source ? customer.source : 'N/A'}</td>
          {/* {customer.user_balance ? <td>{customer.user_balance}</td> : <td>N/A</td>} */}
          {/* <td className="selectdiv">
                  <select id="wifi">
                      <option value="unlimited" className="img1">Unlimited</option>
                      <option>Hotspot</option>
                      <option>Teleport</option>
                  </select>
              </td> */}
          <td>
            <span className="badge badge-success">{customer.status}</span>
          </td>
          <td>
            <div className="pl-1 d-flex justify-content-around">
              <button
                data-id={customer.id}
                type="submit"
                onClick={() => getCustomer(customer)}
                data-toggle="modal"
                data-placement="right"
                data-target="#customer_detail"
                style={{
                  border: '0',
                  backgroundColor: 'white',
                }}
              >
                <span className="view1">
                  <img src={view} alt="" data-toggle="tooltip" data-placement="right" title="View" />
                </span>
              </button>
              <ViewCustomer customer={one} wallet={wallet} />

              <button
                type="button"
                className=""
                onClick={() => getCustomer(customer)}
                data-toggle="modal"
                data-placement="right"
                data-target="#confirmModal"
              >
                <span className="view1">
                  <img
                    src="https://res.cloudinary.com/teewhy/image/upload/v1545642324/checked.png"
                    alt="Activate"
                    title="Activate"
                  />
                </span>
              </button>
              <ConfirmModal customer={one} />
              {/* <button type="submit" data-toggle="modal" data-target="#edit" style={{
                          border: '0',
                          backgroundColor: 'white'
                      }}><span className="view"><img
                          src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/edit.png"
                          data-toggle="tooltip" data-placement="top" title="Edit"/></span></button>

                      <button type="submit" data-toggle="modal" data-target="#cancel" style={{
                          border: '0',
                          backgroundColor: 'white'
                      }}><span className="view"><img
                          src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/delete.png"
                          data-toggle="tooltip" data-placement="right" title="Delete"/></span>
                      </button>
                      <div id="cancel" className="modal fade in" tabIndex="-1" role="dialog"
                           aria-labelledby="myModalLabel" aria-hidden="true">
                          <div className="modal-dialog">
                              <div className="modal-content">
                                  <div className="modal-body text-center">
                                      <span className="fa fa-exclamation-circle"></span>
                                      <p><b>Are you sure you want to delete this customer?</b></p>
                                      <button type="submit" className="btn btn-green waves-effect" data-toggle="modal"
                                              data-target="#edit">Yes Delete
                                      </button>
                                      <button type="submit" className="btn btn-green waves-effect" data-toggle="modal"
                                              data-target="#edit">Cancel
                                      </button>
                                  </div>
                              </div>

                          </div>

                      </div>
                      */}
            </div>
          </td>
        </tr>
      ))
    ) : (
      <p>there are no customers</p>
    );

  return (
    <tbody>
      {load ? (
        <tr>
          <td colSpan="9" className="text-center">
            <img src={Loader} alt="Loading" />
          </td>
        </tr>
      ) : (
        customersList
      )}
    </tbody>
  );
};

UnverifiedCustomersTable.propTypes = {
  load: PropTypes.bool.isRequired,
  customers: PropTypes.arrayOf.isRequired,
};

export default UnverifiedCustomersTable;
