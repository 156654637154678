import { useReducer } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { customReducer } from '../../../../reducers/shared/custom/CustomReducer';
import { LOADING_STARTS, LOADING_STOPS } from '../../../../constants/types/shared/LoadingTypes';

const useAccountExtension = () => {
  const [{ loading }, dispatch] = useReducer(customReducer, { loading: false });

  const extendAccount = async (url, params) => {
    dispatch({ type: LOADING_STARTS });
    try {
      const response = await axios.post(url, params);
      const { status } = response.data;
      if (status) return toastr.success('Account(s) Extended');
      dispatch({ type: LOADING_STOPS });
      return toastr.info('Rename File');
    } catch (e) {
      dispatch({ type: LOADING_STOPS });
      if (e.response.data) {
        const { message } = e.response.data;
        if (message) return toastr.info(message);
      }
      return toastr.info('Error Extending Account(s)');
    }
  };

  return { loading, extendAccount };
};

export default useAccountExtension;
