import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import toastr from 'toastr';
import axios from 'axios';
import Logout from '../../../shared/auth/Logout';
import WifiCallSidebar from '../../../../layouts/wificall-sidebar/WifiCallSidebar';
import { getUser } from '../../../../../actions/shared/user/UserActions';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import { admin, NEW_PRODUCTS } from '../../../../../utils/config';
import { SUPER_ADMIN } from '../../../../../utils/departments/helpers';

class Wallboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

 

  static propTypes = {
    getUser: PropTypes.func.isRequired,
    firstName: PropTypes.string.isRequired,
  };

  componentDidMount() {
    this.checkDepartment();
    this.props.getUser();
  }

  componentWillReceiveProps() {
    this.checkDepartment();
  }
   checkDepartment = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.department_id !== `${admin}` && response.data.department_id !== `${NEW_PRODUCTS}` && response.data.department_id !== `${SUPER_ADMIN}`) {
        this.props.history.push('/');

        sessionStorage.setItem('token', '');
        sessionStorage.clear();

        toastr.info('You are not allowed to view this page');
      }
    } catch (e) {
      console.log(e.response);
    }
  };

  render() {
    const { user } = this.props;
    const { firstName } = user;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <Link className="s-sidebar__trigger" to="#">
                <i className="fa fa-bars mr-3" />
              </Link>
              <WifiCallSidebar />

              <h3 className="col-lg-6 overview-part">Wallboard</h3>

              <div className="d-flex">
                <h6 className="mr-0 mt-1">
                  {firstName}
                  <Logout />
                </h6>
              </div>
            </div>
          </div>

          <div className="col-12 after-summary">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
                <div className="col-lg-8">
                  <p className="fil d-flex mt-3 mr-2 copy-font">
                    <h6 className="mt-2">Filter by:</h6>
                    <span>
                      <select className="form-control">
                        <option>Queue Number</option>
                        <option>Answered calls</option>
                      </select>
                    </span>
                  </p>
                </div>
                <div className="card x-panel">
                  <div className="table-responsive content">
                    <table className="table">
                      <thead>
                        <tr className="copy-font text-center">
                          <th scope="col">Queue Number</th>
                          <th scope="col">Calls in Queue</th>
                          <th scope="col">Answered Calls</th>
                          <th scope="col">Missed Calls</th>
                          <th scope="col">Avg Talk Time</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr className="text-center">
                          <td>6000</td>
                          <td> 0 </td>
                          <td> 1324 </td>
                          <td>23 </td>
                          <td> 87 </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect(
  mapStateToProps,
  { getUser },
)(Wallboard);
