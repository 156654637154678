/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/camelcase */
import { useReducer, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import toastr from 'toastr';
import { toast } from 'react-toastify';
import * as load from '../../../../../constants/types/shared/LoadingTypes';
import * as url from '../../../../../services/api/shared/installations/InstallationsUrl';
import * as types from '../../../../../constants/types/operations/installations/InstallationsTypes';
import { FETCHED_CUSTOMER } from '../../../../../constants/types/shared/customers/CustomerDetailsTypes';
import { API } from '../../../../../services/api/tizeti';
import rescheduleReducer from '../../../../../reducers/field-support/installation/overview/rescheduleReducer';

const useRescheduleOverview = () => {
  const [
    {
      loading,
      routing,
      clustering,
      installations,
      RescheduledInstallations,
      completedInstallations,
      clusteredInstalls,
      routedInstalls,
      LagosInstalls,
      RiversInstalls,
      OgunInstalls,
      EdoInstalls,
      assigning,
      pages,
      anInstallation,
      assignedStaffCount,
      assignedStaffRows,
    },
    dispatch,
  ] = useReducer(rescheduleReducer, {
    loading: false,
    routing: false,
    clustering: false,
    installations: [],
    RescheduledInstallations: [],
    completedInstallations: [],
    clusteredInstalls: [],
    routedInstalls: [],
    LagosInstalls: [],
    RiversInstalls: [],
    OgunInstalls: [],
    EdoInstalls: [],
    assigning: false,
    pages: 1,
    anInstallation: {},
    assignedStaffCount: 0,
    assignedStaffRows: [],
  });
  const country = sessionStorage.getItem('country');
  const searchState = {  status: '', from: '', to: '', zone_id: '', paginate: true, addZone: 'yes', reschedule_status: true };
  const searchStates = {  status: '', from: '', to: '', zone_id: '', paginate: true, addZone: 'yes', reschedule_status: true, country:"cv" };
  const searchUserState = { option: '', keyword: '' };
  const [search, setSearch] = useState(country === "CIV" ? searchStates : searchState);
  const [searchUser, setSearchUser] = useState(searchUserState);
  const [assign, setAssign] = useState(false);
  const assignState = { employee_id: '' };
  const [assignForm, setAssignForm] = useState(assignState);
  const [oneInstallation, setOneInstallation] = useState({});
  const [loadingForm, setLoadingForm] = useState(false);
  const [assignedStaff, setAssignedStaff] = useState(false);
  const [showDeleteInstallationModal, setShowDeleteInstallationModal] = useState(false);
  const [deletingInstallation, setDeletingInstallation] = useState(false);
  const onShowDeleteInstallationModal = () => {
    setShowDeleteInstallationModal(!showDeleteInstallationModal);
  };
  const handleSearchUser = (e) => {
    const { name, value } = e.target;
    setSearchUser({ ...searchUser, [name]: value });
  };
  const onSearchChange = (e) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
  };
  const searchInstallations = async (currentPage) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(`${url.SEARCH_INSTALLATION_URL}/${currentPage}`, search, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response.data.list) {
        dispatch({ type: types.GET_INSTALLATIONS, payload: response.data });
      } else {
        dispatch({ type: load.LOADING_STOPS });
        toast.info('Unable to get installations with those params');
      }
    } catch (e) {
      dispatch({ type: load.LOADING_STOPS });
      toast.info('Something went wrong');
    }
  };

  const searchRescheduledInstallations = async (currentPage) => {
    dispatch({ type: load.LOADING_STARTS });
    await axios({
      method: 'post',
      url: `${url.GET_RESCHEDULED_INSTALLATIONS_URL}/${currentPage}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
      .then((response) => {
        if (response.data) {
          dispatch({
            type: types.GET_RESCHEDULED_INSTALLATIONS,
            payload: {
              lists: response.data.rows,
              count: response.data.count,
              pages: response.data.pages,
            },
          });
        }
      })
      .catch(() => {
        toast.info('Something went wrong!');
        dispatch({ type: load.LOADING_STOPS });
      });
  };

  const searchByEmail = async (data, currentPage) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(`${url.SEARCH_FOR_CUSTOMER_URL}/${currentPage}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data === 'customer info could not be retreived') {
        dispatch({ type: load.LOADING_STOPS });
        toast.info('No user found!');
      } else {
        dispatch({
          type: FETCHED_CUSTOMER,
          payload: {
            users: response.data.users,
            pages: response.data.pages,
          },
        });
      }
    } catch (e) {
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const searchByName = async (data, currentPage) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(`${url.SEARCH_FOR_CUSTOMER_URL}/${currentPage}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.message === 'could not find any customer with this name') {
        dispatch({ type: load.LOADING_STOPS });
        toast.info('No user found!');
      } else if (typeof response.data === 'string') {
        dispatch({ type: load.LOADING_STOPS });
        toast.info('Unable to get this user');
      } else {
        dispatch({
          type: FETCHED_CUSTOMER,
          payload: {
            users: response.data.users,
            pages: response.data.pages,
          },
        });
      }
    } catch (e) {
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const searchByPhoneOrMac = async (data, currentPage) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(`${url.SEARCH_FOR_CUSTOMER_URL}/${currentPage}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        }
      });
      if (response.data === 'customer info could not be retreived') {
        toast.info('No user found!');
        dispatch({ type: load.LOADING_STOPS });
      } else {
        dispatch({
          type: FETCHED_CUSTOMER,
          payload: {
            users: response.data.users,
            pages: response.data.pages,
          },
        });
      }
    } catch (e) {
      toast.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const searchForUser = (currentPage) => {
    const { option, keyword } = searchUser;
    switch (option) {
      case 'email':
        const emailSearch = { email: keyword.trim() };
        return searchByEmail(emailSearch, currentPage);
      case 'firstName':
        const firstNameSearch = { firstName: keyword.trim() };
        return searchByName(firstNameSearch, currentPage);
      case 'lastName':
        const lastNameSearch = { lastName: keyword.trim() };
        return searchByName(lastNameSearch, currentPage);
      case 'phone':
        const phoneSearch = { phoneNumber: keyword.trim() };
        return searchByPhoneOrMac(phoneSearch, currentPage);
      default:
        return null;
    }
  };

  const clusterInstallation = async (data) => {
    dispatch({ type: types.CLUSTERING_STARTS });
    try {
      const response = await axios.post(`${url.CLUSTER_URL}`, data);
      if (response.data) {
        dispatch({
          type: types.CLUSTER_INSTALLATION,
          payload: response.data,
        });
      }
    } catch (e) {
      toast.info('Something went wrong!');
      dispatch({ type: types.CLUSTERING_STOPS });
    }
  };

  const postRoutedInstalls = async (data) => {
    dispatch({ type: load.ROUTING_STARTS });
    try {
      const { zone, startPoint, name, selectedInstallations } = data;
      const coordinatesData = {
        action: 'get_lat_long',
        address: startPoint,
      };
      if (startPoint.length > 0) {
        await axios
          .post(`${url.LEGACY_GENIATECH_URL}`, qs.stringify(coordinatesData))
          .then((response) => {
            const params = JSON.stringify({
              state: 'lagos',
              start_point: [response.data.latlng[0], response.data.latlng[1], startPoint, name],
              zone,
              installations: selectedInstallations,
            });
            if (response.data.status) {
              axios.post(`${url.CLUSTER_ROUTING_URL}`, params).then((result) => {
                dispatch({
                  type: types.ROUTE_INSTALLATION,
                  payload: result.data,
                });
              });
            }
          })
          .catch((err) => {
            if (err) toast.info('Please Add A Valid Google Start Address');
          });
      } else toast.info('Please Input A Start Address!');
    } catch (e) {
      toast.info('Something went wrong!');
      dispatch({ type: load.ROUTING_STOPS });
    }
  };

  const onAssignChange = (e) => setAssignForm({ ...assignForm, [e.target.name]: e.target.value });

  const getOneInstallation = (param) => {
    setOneInstallation(param);
  };
  const assignInstallation = async (data, id) => {
    try {
      setLoadingForm(true);
      const response = await axios.post(`${url.ASSIGN_INSTALLATION_URL}/${id}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        setLoadingForm(false);
        toastr.success(`Assigning done`);
      } else {
        toastr.info(`Unable to assign`);
        setLoadingForm(false);
      }
    } catch (e) {
      setLoadingForm(false);
      toastr.info('Something went wrong!');
    }
  };

  const getAssignedStaff = async (id) => {
    try {
      setAssignedStaff(true);
      dispatch({
        type: types.GET_ASSIGNED_STAFF,
        payload: {
          assignedStaffCount: 0,
          assignedStaffRows: [],
        },
      });
      const response = await axios.get(`${url.ASSIGN_INSTALLATION_URL}/installation/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_ASSIGNED_STAFF,
          payload: {
            assignedStaffCount: response.data.data.count,
            assignedStaffRows: response.data.data.rows,
          },
        });
      } else toast.info(`Unable To Fetch Assigned Staff`);
      setAssignedStaff(false);
    } catch (e) {
      setAssignedStaff(false);
      toast.info('Something went wrong!');
    }
  };

  const editInstallation = async (id, data) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.patch(`${url.EDIT_INSTALLATION_STATUS_URL}/${id}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) toastr.success('Updated successfully');
      else toastr.info('Unable to edit');
      dispatch({ type: load.LOADING_STOPS });
    } catch (e) {
      dispatch({ type: load.LOADING_STOPS });
      toastr.info('Something went wrong');
    }
  };

  const getAnInstallation = async (id) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.get(`${url.EDIT_INSTALLATION_STATUS_URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) dispatch({ type: types.GET_INSTALLATION, payload: response.data });
      else toastr.info('Unable To Fetch Installation');
      dispatch({ type: load.LOADING_STOPS });
    } catch (e) {
      dispatch({ type: load.LOADING_STOPS });
      toastr.info('Error Fetching Installation');
    }
  };

  const handlePageClick = async (e) => {
    const selectedPage = e.selected + 1;
    const { option, keyword } = searchUser;
    if (option === '' && keyword === '') searchInstallations(selectedPage);
    else searchForUser(selectedPage);
  };

  const deleteInstallation = async (install) => {
    setDeletingInstallation(true);
    try {
      const response = await API.delete(`${url.DELETE_INSTALLATION_URL}/${install}`);
      if (response.status === 200) {
        dispatch({ type: types.DELETE_INSTALLATION, payload: install });
        setDeletingInstallation(false);
        setShowDeleteInstallationModal(false);
        toast.success('Installation Deleted');
      } else {
        setDeletingInstallation(false);
        setShowDeleteInstallationModal(false);
        toast.error('Unable to delete installation');
      }
    } catch (e) {
      setDeletingInstallation(false);
      setShowDeleteInstallationModal(false);
      toast.error('Something went wrong while deleting installation');
    }
  };

  return {
    pages,
    installations,
    RescheduledInstallations,
    completedInstallations,
    loading,
    onSearchChange,
    search,
    searchInstallations,
    searchRescheduledInstallations,
    searchUser,
    handleSearchUser,
    searchForUser,
    routing,
    clustering,
    routedInstalls,
    clusteredInstalls,
    postRoutedInstalls,
    clusterInstallation,
    LagosInstalls,
    RiversInstalls,
    OgunInstalls,
    EdoInstalls,
    assign,
    setAssign,
    assignForm,
    setAssignForm,
    onAssignChange,
    assigning,
    oneInstallation,
    setOneInstallation,
    getOneInstallation,
    assignInstallation,
    loadingForm,
    handlePageClick,
    editInstallation,
    getAssignedStaff,
    assignedStaff,
    assignedStaffCount,
    assignedStaffRows,
    anInstallation,
    getAnInstallation,
    onShowDeleteInstallationModal,
    showDeleteInstallationModal,
    deleteInstallation,
    deletingInstallation,
  };
};
export default useRescheduleOverview;
