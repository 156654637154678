/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import edit from '../../../../assets/img/icons/edit.png';
import view from '../../../../assets/img/icons/view.png';
import loader from '../../../../assets/img/icons/TizetiLoaderColored.gif';
import { NoItemRow } from '../../../../utils/loader/loadingHelper';
import CustomerDetailsSharedModal from './CustomerDetailsSharedModal';
import UpdateCustomerDetailsShared from './UpdateCustomerDetailsShared';
import { useUserProfile } from '../../../../hooks/shared/user/useUserProfile';
import { OPERATIONS } from '../../../../utils/departments/helpers';
import { useUser } from '../../../../hooks/shared/user/useUser';

const CustomerDetailsSharedTable = ({ items, isLoading }) => {
  const { user, getUserProfile, getLoggedInUser } = useUserProfile();
  const { profile } = useUser();
  const { department_id } = user;
  useEffect(() => {
    getUserProfile();
    getLoggedInUser();
  }, []);
  const colSpan = '8';
  const [request, setRequest] = useState({
    id: '',
    user_kyc: '',
    contact_information_image: '',
    pole_image: '',
    img_4: '',
    sla: '',
    user: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      address: '',
      installations: [
        {
          installationUploads: [
            {
              contact_information_image: '',
              pole_image: '',
              img_4: '',
              sla: '',
              user_kyc: '',
            },
          ],
        },
      ],
    },
  });
  const getRequest = (item) => setRequest(item);
  const tableData =
    items && items.length ? (
      items.map((item, i) => {
        return (
          <tr className="text-nowrap" key={i}>
            <td>{i + 1}</td>
            <td>
              {item.user.firstName} {item.user.lastName}
            </td>
            <td>{item.user.phoneNumber}</td>
            <td>{item.user.email}</td>
            <td>{item.user.address}</td>

            <td>
              <div className="d-flex">
                {(department_id === `${OPERATIONS}` && Number(user.role_id) > 2) ||
                profile.email === 'lydia.anyimson@tizeti.com' ||
                profile.email === 'customersupportDept@tizeti.com' ? (
                  <>
                    <button
                      type="submit"
                      className="mx-1 noBorder"
                      data-toggle="modal"
                      data-placement="left"
                      data-target="#updateDedicated"
                      onClick={() => getRequest(item)}
                    >
                      <span className="view">
                        <img src={edit} alt="" data-toggle="tooltip" data-placement="left" title="Edit" />
                      </span>
                    </button>
                    <UpdateCustomerDetailsShared request={request} />
                  </>
                ) : null}

                <button
                  type="submit"
                  className="mx-1 noBorder"
                  data-toggle="modal"
                  data-placement="left"
                  data-target="#viewcs"
                  onClick={() => {
                    // console.log(item);
                    getRequest(item);
                  }}
                >
                  <span className="view">
                    <img src={view} alt="" data-toggle="tooltip" data-placement="left" title="Edit" />
                  </span>
                </button>
              </div>
            </td>
            <CustomerDetailsSharedModal request={request} />
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan={colSpan} text="There are no records" />
    );
  return (
    <div className="card x-panel mt-2 px-0">
      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <thead>
            <tr className="">
              <th>#</th>
              <th>Name</th>
              <th>Phone</th>
              <th className="text-wrap">Email</th>
              <th>Address</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td className="text-center">
                  <img src={loader} alt="loading gif" />
                </td>
              </tr>
            ) : (
              tableData
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CustomerDetailsSharedTable;
