import {
  GET_AVAILABLE_TIMES,
  GET_CATEGORIES_FOR_INSTALLATIONS,
  GET_SUB_ZONES,
  SCHEDULE_STARTS,
  SCHEDULE_STOPS,
  SCHEDULE_SUCCESS,
  START_LOADING_SUBZONES,
  START_LOADING_TIME,
  START_SCHEDULING,
  STOP_LOADING_SUBZONES,
  STOP_LOADING_TIME,
  STOP_SCHEDULING,
} from '../../../constants/types/sales/installations/SchedulerTypes';
import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';
import { GET_ZONES } from '../../../constants/types/shared/zones/ZonesTypes';

const initialState = {
  categories: [],
  zones: [],
  subzones: [],
  times: [],
  loadSub: false,
  loadSchedule: false,
  message: null,
  load: false,
  loadTime: false,
};

export const SchedulerReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case SCHEDULE_STOPS:
      return {
        ...state,
        loadSchedule: false,
      };

    case START_SCHEDULING:
      return {
        ...state,
        load: payload,
      };

    case STOP_SCHEDULING:
      return {
        ...state,
        load: payload,
      };

    case SCHEDULE_STARTS:
      return {
        ...state,
        loadSchedule: true,
      };

    case STOP_LOADING_SUBZONES:
      return {
        ...state,
        loadSub: payload,
      };

    case START_LOADING_SUBZONES:
      return {
        ...state,
        loadSub: payload,
      };

    case LOADING_STARTS:
      return {
        ...state,
        load: payload,
      };

    case STOP_LOADING_TIME:
      return {
        ...state,
        loadTime: payload,
      };

    case START_LOADING_TIME:
      return {
        ...state,
        loadTime: payload,
      };

    case LOADING_STOPS:
      return {
        ...state,
        load: payload,
      };

    case SCHEDULE_SUCCESS:
      return {
        ...state,
        message: payload,
      };

    case GET_ZONES:
      return {
        ...state,
        zones: payload,
      };

    case GET_SUB_ZONES:
      return {
        ...state,
        subzones: payload,
        loadSub: false,
      };

    case GET_AVAILABLE_TIMES:
      return {
        ...state,
        times: payload,
        loadTime: false,
      };

    case GET_CATEGORIES_FOR_INSTALLATIONS:
      return {
        ...state,
        categories: payload,
      };

    default:
      return state;
  }
};

export default SchedulerReducer;
