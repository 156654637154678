/* eslint-disable @typescript-eslint/camelcase */
import React, { useContext } from 'react';
import { CSVLink } from 'react-csv';
import ZoneOptions from './ZoneOptions';
import TableBody from './TableBody';
import { SchedulingContext } from '../../../../../context/field-support/SchedulingContext';

const SchedulingBody = () => {
  const { zones, search, onChange, handleChange, clusters, onSubmit, fetching, scheduled } = useContext(
    SchedulingContext,
  );

  const { date, sub_zone_id } = search;

  const isEnabled = () => sub_zone_id.length > 0;

  const headers = [
    { label: 'First Name', key: 'user.firstName' },
    { label: 'Last Name', key: 'user.lastName' },
    { label: 'Email', key: 'user.email' },
    { label: 'Phone Number', key: 'user.phoneNumber' },
    { label: 'Address', key: 'address' },
    { label: 'Payment Reference', key: 'payment_reference' },
  ];

  return (
    <div className="col-12 after-summary">
      <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
        <small>
          N.B: The <span style={{ color: 'red', fontWeight: 'bold' }}>Pick Zone</span> filter is important
        </small>
      </div>
      <ZoneOptions />
      <div className="col-12 d-none d-md-flex">
        <div className="d-flex">
          <p className="fil d-flex mr-5 copy-font">
            <span className="d-flex">
              <small className="mr-1">Filter By Zones:</small>
              <span>
                <select className="form-control" name="sub_zone_id" onChange={handleChange}>
                  <option>Pick Zone</option>
                  {zones
                    ? zones.map((zone) => (
                        <option value={zone.id} key={zone.id}>
                          {zone.name}
                        </option>
                      ))
                    : null}
                </select>
              </span>
            </span>
          </p>
          <p className="fil d-flex mr-5 copy-font">
            <span className="d-flex">
              <small className="mr-2">Cluster:</small>
              <span>
                <select className="form-control">
                  {clusters.map((cluster) => {
                    return <option key={cluster}>{cluster}</option>;
                  })}
                </select>
              </span>
            </span>
          </p>
          <p className="fil d-flex mr-5 copy-font">
            <small className="mr-3">From:</small>
            <input type="date" className="form-control" name="date" value={date} onChange={onChange} />
          </p>
        </div>

        <div>
          <button type="button" className="btn btn-green" onClick={() => onSubmit(search)} disabled={!isEnabled()}>
            Search
          </button>
        </div>
        {scheduled.length > 0 ? (
          <CSVLink data={scheduled} filename="scheduled" headers={headers} style={{ paddingLeft: '10%' }}>
            {/* eslint-disable-next-line react/button-has-type */}
            <button className="btn btn-green">Download</button>
          </CSVLink>
        ) : null}
      </div>
      <div className="card x-panel">
        <div className="table-responsive content">
          <table className="table" id="myTable">
            <thead>
              <tr>
                <th>#</th>
                <th>First name</th>
                <th>Last name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Address</th>
                <th>Payment REF</th>
              </tr>
            </thead>
            {fetching ? <small>loading...</small> : <TableBody data={scheduled} />}
          </table>
        </div>
      </div>
    </div>
  );
};

export default SchedulingBody;
