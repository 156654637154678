import * as loading from '../../../../../constants/types/shared/LoadingTypes';
import { GET_SUBSCRIBERS } from '../../../../../constants/types/shared/noc/LteTypes';

const subscriberReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case loading.LOADING_STARTS:
      return {
        ...state,
        loading: true,
      };

    case loading.LOADING_STOPS:
      return {
        ...state,
        loading: false,
      };

    case GET_SUBSCRIBERS:
      return {
        loading: false,
        subscribers: payload,
      };

    default:
      return state;
  }
};

export default subscriberReducer;
