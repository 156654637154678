import { useReducer } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import qs from 'qs';
import { customReducer } from '../../../../../reducers/shared/custom/CustomReducer';
import * as load from '../../../../../constants/types/shared/LoadingTypes';
import { GET_DATA, FILTER_DATA } from '../../../../../constants/types/shared/CustomTypes';

const usePost = () => {
  const [{ data, filtered, loading, fetching }, dispatch] = useReducer(customReducer, {
    data: [],
    filtered: [],
    loading: false,
    fetching: false,
  });

  const getData = async (url, params, successNotifier, errorNotifier) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(
        url,
        qs.stringify(params),
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
        // headers: {
        //   Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        // },
      );
      if (response.data) {
        dispatch({
          type: GET_DATA,
          payload: response.data,
        });
        if (successNotifier) toastr.success(successNotifier);
      } else dispatch({ type: load.LOADING_STOPS });
    } catch (e) {
      dispatch({ type: load.LOADING_STOPS });
      if (errorNotifier) toastr.info(errorNotifier);
      else toastr.info('Something went wrong!');
    }
  };

  const getDataLegacy = async (url, params, successNotifier, errorNotifier) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(url, qs.stringify(params));
      if (response.data) {
        dispatch({
          type: GET_DATA,
          payload: response.data,
        });
        if (successNotifier) toastr.success(successNotifier);
      } else dispatch({ type: load.LOADING_STOPS });
    } catch (e) {
      dispatch({ type: load.LOADING_STOPS });
      if (errorNotifier) toastr.info(errorNotifier);
      else toastr.info('Something went wrong!');
    }
  };

  const fetchData = async (url, params, successNotifier, errorNotifier) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(url, qs.stringify(params), {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: GET_DATA,
          payload: response.data,
        });
        if (successNotifier) toastr.success(successNotifier);
      } else dispatch({ type: load.LOADING_STOPS });
    } catch (e) {
      dispatch({ type: load.LOADING_STOPS });
      if (errorNotifier) toastr.info(errorNotifier);
      else toastr.info('Something went wrong!');
    }
  };

  const filterData = async (url, params) => {
    dispatch({ type: load.FETCHING_STARTS });
    try {
      const response = await axios.post(url, qs.stringify(params));
      if (response.data.status) {
        dispatch({
          type: FILTER_DATA,
          payload: response.data,
        });
      } else dispatch({ type: load.FETCHING_STOPS });
    } catch (e) {
      dispatch({ type: load.FETCHING_STOPS });
      toastr.info('Something went wrong!');
    }
  };

  const postDataWithoutStringify = async (url, params, successNotifier, errorNotifier) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(url, params);
      if (response.data) {
        dispatch({
          type: GET_DATA,
          payload: response.data,
        });
        if (successNotifier) toastr.success(successNotifier);
      } else dispatch({ type: load.LOADING_STOPS });
    } catch (e) {
      dispatch({ type: load.LOADING_STOPS });
      if (errorNotifier) toastr.info(errorNotifier);
      else toastr.info('Something went wrong!');
    }
  };

  const getDataWithConfig = async (url, params, successNotifier, errorNotifier) => {
    dispatch({ type: load.LOADING_STARTS });
    const config = {
      method: 'post',
      url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      data: params,
    };
    axios(config)
      .then((response) => {
        if (response.data) {
          dispatch({
            type: GET_DATA,
            payload: response.data,
          });
          if (successNotifier) toastr.success(successNotifier);
        } else dispatch({ type: load.LOADING_STOPS });
      })
      .catch((error) => {
        dispatch({ type: load.LOADING_STOPS });
        if (errorNotifier) toastr.info(errorNotifier);
        else toastr.info('Something went wrong!');
      });
  };

  return {
    data,
    filtered,
    loading,
    fetching,
    getData,
    getDataLegacy,
    fetchData,
    filterData,
    postDataWithoutStringify,
    getDataWithConfig,
  };
};

export default usePost;
