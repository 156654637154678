import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import ReleaseReportTable from './ReleaseReportTable';
import { useReleaseReport } from '../../../../hooks/procurement/release-report/useReleaseReport';
import AddReleaseItem from './AddReleaseItem';

const ReleaseReportBody = () => {
  const { list, pages, isLoading, filterReleaseItemByDate } = useReleaseReport();
  const [from, setFrom] = useState('');

  const [to, setTo] = useState('');
  const [page] = useState(1);

  const data = { from, to };

  const filterByDate = (e) => {
    e.preventDefault();
    filterReleaseItemByDate(data, page);
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    filterReleaseItemByDate(data, selectedPage);
  };

  return (
    <div className="container pt-5">
      <div className="row mt-5">
        <form className="form-inline mb-2 col-12">
          {/* <div className="form-group ml-2">
            <input
              className="form-control"
              style={{ width: '230px' }}
              type="text"
              placeholder="Search By Item Name, Tag No."
            />
          </div> */}
          {/* <div className="form-group d-flex ml-2">
            <small className="mr-1 text-nowrap"> Filter By:</small>
            <select className="form-control mr-2">
              <option>-- Class --</option>
              <option>Asset</option>
              <option>Expense</option>
              <option>Inventory</option>
            </select>
            <select className="form-control">
              <option>-- Status --</option>
              <option>New</option>
              <option>Posted</option>
              <option>Invoice Submitted</option>
            </select>
          </div> */}
          <div className="form-group d-flex ml-2">
            <small className="mr-1">From: </small>
            <input
              type="date"
              className="form-control"
              name="from"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
            />
          </div>
          <div className="form-group d-flex ml-2">
            <small className="mr-1">To: </small>
            <input type="date" className="form-control" name="to" value={to} onChange={(e) => setTo(e.target.value)} />
          </div>
          <div className="form-group ml-2">
            <button className="btn btn-green" type="submit" onClick={filterByDate}>
              Search
            </button>
          </div>
          <div className="form-group ml-2">
            <button className="btn btn-green" type="button" data-toggle="modal" data-target="#add_release">
              Add Item
            </button>
          </div>
        </form>
        <AddReleaseItem log={list} />
        <ReleaseReportTable lists={list} load={isLoading} />

        {pages > 1 ? (
          <ReactPaginate
            previousLabel="previous"
            nextLabel="next"
            breakLabel="..."
            breakClassName="break-me"
            pageCount={pages}
            marginPagesDisplayed={3}
            pageRangeDisplayed={4}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            subContainerClassName="pages pagination"
            activeClassName="active"
          />
        ) : null}
      </div>
    </div>
  );
};

export default ReleaseReportBody;
