import React from 'react';
import useRouterIpAddress from '../hooks/useRouterIpAddress';
import RouterIpAddressContext from './RouterIpAddressContext';

const RouterIpAddressProvider = ({ children }) => {
  const { data, loading, paginateChange, loadingForm, onChange, formValue, onSubmit, pages } = useRouterIpAddress();
  return (
    <RouterIpAddressContext.Provider
      value={{
        data,
        loading,
        paginateChange,
        loadingForm,
        onChange,
        formValue,
        onSubmit,
        pages,
      }}
    >
      {children}
    </RouterIpAddressContext.Provider>
  );
};
export default RouterIpAddressProvider;
