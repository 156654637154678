/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import SalesBBNTable from './SalesJacketTable';
import { useBBNSignups } from '../../../../../hooks/sales/bbn-signups/useBBNSignups';
import CustomCsvDownload from '../../../shared/CustomCsvDownload';
import { jacketsHeader, filename } from '../../../../../utils/field-support/installations/helpers';
import ReactPaginate from 'react-paginate';
import SalesJacketTable from './SalesJacketTable';

const SalesJacketBody = () => {
  const { getJackets, rows, isFetching } = useBBNSignups();
  // console.log(rows);
  const [query, setQuery] = useState('');

  const [pages] = useState('50');

  const [page] = useState(1);

  // const onSubmit = (e) => {
  //   e.preventDefault();
  //   searchLTE(data);
  // };
  const handlePageClick = async (e) => {
    const selectedPage = e.selected + 1;
    getJackets(selectedPage);
  };

  useEffect(() => {
    getJackets(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mt-5 p-3">
      <div className="container-fluid pt-4 ">
        <div className="row">
          <form className="form-group inner-addon d-flex">
            <div className="input-group ml-5">
              Filter By
              <select
                style={{ marginLeft: '1rem' }}
                name="query"
                className="form-control"
                onChange={(e) => setQuery(e.target.value)}
              >
                <option value="">Select Status</option>
                <option value={true}>Paid</option>
                <option value={false}>Log</option>
              </select>
            </div>
          </form>
          <div className="pl-5 col-12 d-flex justify-content-between">
            {rows.length > 0 ? (
              <div>
                Count: <span className="badge badge-secondary mr-4">{rows.length}</span>
              </div>
            ) : null}

            <div className="col-12 d-flex mt-2">
              <CustomCsvDownload
                data={rows || []}
                headers={jacketsHeader}
                filename={filename(`Tizeti Jackets`)}
                classname="btn btn-green shadow-md"
                textcolor="white"
                text="Download Data"
              />
            </div>
          </div>

          <SalesJacketTable items={rows} isFetching={isFetching} query={query} />
          {pages > 1 ? (
            <ReactPaginate
              previousLabel="previous"
              nextLabel="next"
              breakLabel="..."
              breakClassName="break-me"
              pageCount={pages}
              marginPagesDisplayed={3}
              pageRangeDisplayed={4}
              onPageChange={handlePageClick}
              containerClassName="pagination"
              subContainerClassName="pages pagination"
              activeClassName="active"
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SalesJacketBody;
