import React, { Component } from 'react';
import moment from 'moment';
// import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import toastr from 'toastr';
import { connect } from 'react-redux';
import {
  approveIdentityUpload,
  approveUtilityUpload,
  disapproveIdentityUpload,
  disapproveUtilityUpload,
} from '../../../../../actions/wificall/verification/VerificationActions';
import { API_URL } from '../../../../../utils/config';

class VerificationDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.oneuser) {
      this.setState({
        user: nextProps.oneuser,
      });
    }
  }

  approveUtility = (user) => {
    // console.log(`${API_URL}/wificall/user/${user.id}`);
    this.props.approveUtilityUpload(user);
  };

  disapproveUtility = (user) => {
    const data = {
      utility_bill_verification_status: 'rejected',
      verification_status: 'unapproved',
    };

    // console.log(data, user);

    this.props.disapproveUtilityUpload(data, user);
  };

  approveId = (user) => {
    this.props.approveIdentityUpload(user);
  };

  disapproveId = (user) => {
    this.props.disapproveIdentityUpload(user);
  };

  render() {
    const { user } = this.state;
    console.log(user);
    return (
      <div
        id="verify-detail"
        className="modal fade in"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center" id="myModalLabel">
                Verification Details
              </h4>
              <button type="button" className="close custom-close" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
            </div>
            <div className="modal-body detail" style={{ padding: '0', textAlign: 'justify' }}>
              <div className="row">
                <div className="col-12 p-0 d-flex">
                  <div className="col-5">
                    <div className="col-12 detail-label1">
                      <div>
                        <label className="copy-font p-0">First Name</label>
                        <p className="copy-font">{user.firstName}</p>
                      </div>

                      <div>
                        <label className="copy-font p-0">Last Name</label>
                        <p className="copy-font">{user.lastName}</p>
                      </div>

                      <div>
                        <label className="copy-font p-0">Email</label>
                        <p className="copy-font">{user.email}</p>
                      </div>

                      <div>
                        <label className="copy-font p-0">Phone number</label>
                        <p className="copy-font">{user.phoneNumber}</p>
                      </div>
                    </div>

                    <div className="col-12 detail-label2">
                      <div>
                        <label className="copy-font p-0">Nationality</label>
                        <p className="copy-font">{user.nationality}</p>
                      </div>

                      <div>
                        <label className="copy-font p-0">Address</label>
                        <p className="copy-font">{user.address}</p>
                      </div>
                    </div>

                    <div className="col-12 detail-label3">
                      <div>
                        <label className="copy-font p-0">Date Uploaded</label>
                        <p className="copy-font">{moment(user.created_at).format('Do-MM-YYYY')}</p>
                      </div>
                    </div>

                    <div className="detail-label4 col-12">
                      <div>
                        <label className="copy-font p-0">Verification Status</label>
                        <p className="copy-font">{user.verification_status}</p>
                      </div>
                    </div>

                    <div className="col-12 detail-label2">
                      <div>
                        <label className="copy-font p-0">utility bill verification status</label>
                        <p className="copy-font">{user.utility_bill_verification_status}</p>
                      </div>

                      <div>
                        <label className="copy-font p-0">id verification status</label>
                        <p className="copy-font">{user.id_verification_status}</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-7 mt-4 pl-0">
                    <div className="col-12 pl-0">
                      <div id="demo2" className="carousel slide" data-ride="carousel">
                        {/* <ul class="carousel-indicators">
                                            <li data-target="#demo" data-slide-to="0" class="active"></li>
                                            <li data-target="#demo" data-slide-to="1"></li>
                                            <li data-target="#demo" data-slide-to="2"></li>
                                            </ul> */}

                        <div className="carousel-inner">
                          <div className="carousel-item active">
                            <h5 className="text-center mb-3">Identity Documents</h5>
                            <img
                              src={
                                user.id_verification
                                  ? user.id_verification
                                  : 'https://res.cloudinary.com/teewhy/image/upload/v1550673323/Artboard_1_4x.png'
                              }
                              alt="Los Angeles"
                              className="w-100"
                              style={{ marginBottom: '25%' }}
                            />
                            <div className="col-12 d-flex p-0 carousel-caption">
                              {user.id_verification_status === 'approved' ? (
                                <div className="col-6 float-left p-0">
                                  <img
                                    src="https://res.cloudinary.com/teewhy/image/upload/v1550751069/cancel-img.png"
                                    alt="images"
                                    onClick={() => this.disapproveId(user)}
                                  />
                                </div>
                              ) : (
                                <div className="col-6 float-left p-0">
                                  <img
                                    src="https://res.cloudinary.com/teewhy/image/upload/v1550751069/add-imgs.png"
                                    alt="images"
                                    onClick={() => this.approveId(user)}
                                  />
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="carousel-item">
                            <h5 className="text-center mb-3">Utility Document</h5>
                            <img
                              src={
                                user.utility_bill
                                  ? user.utility_bill
                                  : 'https://res.cloudinary.com/teewhy/image/upload/v1550672222/View_Doc1.png'
                              }
                              alt="Chicago"
                              className="w-100"
                              style={{ marginBottom: '25%' }}
                            />
                            <div className="col-12 d-flex p-0 carousel-caption">
                              {user.utility_bill_verification_status === 'approved' ? (
                                <div className="col-6 float-left p-0">
                                  <img
                                    src="https://res.cloudinary.com/teewhy/image/upload/v1550751069/cancel-img.png"
                                    alt="images"
                                    onClick={() => this.disapproveUtility(user)}
                                  />
                                </div>
                              ) : (
                                <div className="col-6 float-left p-0">
                                  <img
                                    src="https://res.cloudinary.com/teewhy/image/upload/v1550751069/add-imgs.png"
                                    alt="images"
                                    onClick={() => this.approveUtility(user)}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <a className="carousel-control-prev" href="#demo2" data-slide="prev">
                          <span className="carousel-control-prev-icon" />
                        </a>
                        <a className="carousel-control-next" href="#demo2" data-slide="next">
                          <span className="carousel-control-next-icon" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

export default connect(null, {
  approveUtilityUpload,
  disapproveUtilityUpload,
  approveIdentityUpload,
  disapproveIdentityUpload,
})(VerificationDetails);
