/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useBandwidth } from '../../../../../hooks/partnership/bandwidth/useBandwidth';

const AddPartnershipBandwidthManually = ({ view, setView, lists, items, getBandwidth }) => {
  const { load, addBandwidth } = useBandwidth();

  const [one, setOne] = useState({
    base: '',
    ip_address: '',
    rx_speed: '',
    tx_speed: '',
    tx_totalaverage: '',
    rx_totalaverage: '',
  });
  const { base, ip_address, rx_speed, tx_speed, tx_totalaverage, rx_totalaverage } = one;

  const shuffle = () => {
    const txbeforeDecimal = Math.floor(Math.random() * (10 - 4 + 1)) + 4;
    const txafterDecimal = Math.floor(Math.random() * (89 - 10 + 1)) + 10;
    const plusOnes = Math.floor(Math.random() * (7 - 1 + 1)) + 1;
    const txnumber = `${txbeforeDecimal}.${plusOnes}${txafterDecimal}000`;
    // Extract beforeDecimal and afterDecimal from the first number
    const [txbeforeDecimals, txafterDecimals] = txnumber.split('.').map(Number);

    // Generate new afterDecimal using the existing beforeDecimal and afterDecimal as a base
    const newAfterDecimals = Math.floor(Math.random() * (txafterDecimals - 1 + 1)) + 1;
    const plusOnex = Math.floor(Math.random() * (9 - 6 + 1)) + 6;
    const txaverage = `${txbeforeDecimals}.${plusOnex}${txbeforeDecimals}00`;

    const beforeDecimal = Math.floor(Math.random() * (25 - 10 + 1)) + 10;

    const afterDecimal = Math.floor(Math.random() * (89 - 10 + 1)) + 10;
    const plusOned = Math.floor(Math.random() * (7 - 1 + 1)) + 1;
    const rxnumber = `${beforeDecimal}.${plusOned}${afterDecimal}000`;
    // const beforeDecimals = Math.floor(Math.random() * (28 - 11 + 1)) + 11;
    // const afterDecimals = Math.floor(Math.random() * (999 - 111 + 1)) + 111;
    // Extract beforeDecimal and afterDecimal from the first number
    const [beforeDecimals, afterDecimals] = rxnumber.split('.').map(Number);

    // Generate new afterDecimal using the existing beforeDecimal and afterDecimal as a base
    const newAfterDecimal = Math.floor(Math.random() * (afterDecimals - 1 + 1)) + 1;
    const plusOne = Math.floor(Math.random() * (9 - 6 + 1)) + 6;
    const rxaverage = `${beforeDecimals}.${plusOne}${beforeDecimals}00`;

    setOne({
      ...one,
      rx_speed: rxnumber,
      rx_totalaverage: rxaverage,

      tx_speed: txnumber,
      tx_totalaverage: txaverage,
    });
  };

  useEffect(() => {
    shuffle();
  }, []);

  const handleClose = () => {
    setView(false);
  };
  const reload = () => {
    getBandwidth();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOne({ ...one, [name]: value });
  };

  const handleBaseChange = (e) => {
    const { name, value } = e.target;
    setOne({ ...one, [name]: value });
    lists
      .filter((list, _) => {
        if (value === list.basestation) {
          return list;
        }
      })
      .map((list, i) => {
        setOne({ ...one, ip_address: list.ip, [name]: value });
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const data = {
      basestation: base,
      txspeed: tx_speed,
      txtotalaverage: tx_totalaverage,
      rxspeed: rx_speed,
      rxtotalaverage: rx_totalaverage,
      ip: ip_address,
    };

    addBandwidth(data, handleClose, reload, setOne);
  };

  // const submitForm = () => {
  //   return rx_speed.length > 0 && rx_totalaverage.length > 0;
  // };

  return (
    <Modal show={view} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ paddingLeft: '2rem' }}>Add Bandwidth</Modal.Title>
      </Modal.Header>
      <div className="text-right mt-3 p-2">
        <button type="submit" className="btn btn-green py-2 px-5" onClick={shuffle}>
          Shuffle
        </button>
      </div>
      <form onSubmit={onSubmit} className="col-md-12">
        <div className="pt-2 d-flex">
          <div className="pr-3 form-group col-md-12">
            <label className="m-0 p-0 copy-font" htmlFor="basestation">
              Basestation
            </label>
            <select
              type="text"
              className="form-control border border-info py-2"
              id="basestation"
              name="base"
              value={base}
              onChange={handleBaseChange}
            >
              <option value=""> Select an Option </option>
              {items.length ? (
                items.map((base) => (
                  <option key={base.id} value={base.basestation}>
                    {base.basestation}
                  </option>
                ))
              ) : (
                <option>Loading</option>
              )}
            </select>
          </div>
        </div>
        <div className="pt-2 d-flex">
          <div className="pl-3 form-group col-md-12">
            <label className="m-0 p-0 copy-font" htmlFor="ip">
              IP address
            </label>
            <input
              type="text"
              className="form-control border border-info py-2"
              id="ip"
              name="ip_address"
              value={ip_address}
              onChange={handleChange}
        
            />
          </div>
        </div>

        <div className="d-flex">
          <div className="pr-3 form-group col-md-12">
            <label className="m-0 p-0 copy-font" htmlFor="txspeed">
              Upload Speed
            </label>
            <input
              type="text"
              className="form-control border border-info py-2 "
              id="txspeed"
              name="tx_speed"
              value={tx_speed}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="d-flex">
          <div className="pr-3 form-group col-md-12">
            <label className="m-0 p-0 copy-font" htmlFor="txtotalaverage">
              Upload Total Average
            </label>
            <input
              type="text"
              className="form-control border border-info py-2"
              id="txtotalaverage"
              name="tx_totalaverage"
              value={tx_totalaverage}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="d-flex">
          <div className="pr-3 form-group col-md-12">
            <label className="m-0 p-0 copy-font" htmlFor="rxspeed">
              Download Speed
            </label>
            <input
              type="text"
              className="form-control border border-info py-2 "
              id="rxspeed"
              name="rx_speed"
              value={rx_speed}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="d-flex">
          <div className="pr-3 form-group col-md-12">
            <label className="m-0 p-0 copy-font" htmlFor="rxtotalaverage">
              Download Total Average
            </label>
            <input
              type="text"
              className="form-control border border-info py-2"
              id="rxtotalaverage"
              name="rx_totalaverage"
              value={rx_totalaverage}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="text-center mt-3">
          {load ? (
            <button type="button" className="btn btn-green py-2 px-5 p-28" disabled>
              Submitting...
            </button>
          ) : (
            <button type="submit" className="btn btn-green py-2 px-5 p-2">
              Submit
            </button>
          )}
        </div>
      </form>
    </Modal>
  );
};

export default AddPartnershipBandwidthManually;
