/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Logout from '../../../shared/auth/Logout';
import NewProductSideBar from '../../../../layouts/new-products/NewProductsSideBar';
import { NEW_PRODUCTS } from '../../../../../utils/config';
import { getLoggedInUser } from '../../../../../actions/user/UserActions';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import { getUser } from '../../../../../actions/shared/user/UserActions';
import {
  getUserDetails,
  searchByName,
  searchByPhoneOrMac,
  searchForUserByEmail,
} from '../../../../../actions/shared/customers/CustomersDetailsActions';
import { getPaymentHistory } from '../../../../../actions/shared/customers/payments/PaymentsActions';
import NewProductsCustomersTable from './NewProductsCustomersTable';

class NewProductGeniatech extends Component {
  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.getLoggedInUser();
    // eslint-disable-next-line react/destructuring-assignment
    this.props.getUser();
    // eslint-disable-next-line react/destructuring-assignment
    this.checkDepartment();
  }

  componentWillReceiveProps() {
    this.checkDepartment();
  }

  checkDepartment = async () => {
    const { history } = this.props;
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.department_id !== `${NEW_PRODUCTS}`) {
        history.push('/');

        sessionStorage.setItem('token', '');
        sessionStorage.clear();

        toastr.info('You are not allowed to view this page');
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  render() {
    const { name } = this.props;

    const { firstName } = name;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              {/* <Link className="s-sidebar__trigger" to={"#"}>
                                <i className="fa fa-bars mr-3"></i>
                            </Link> */}
              <NewProductSideBar />

              <h3 className="col-lg-6 overview-part">Customers</h3>

              <div className="d-flex profile-mail">
                <h6 className="mr-0 d-flex">
                  <i className="small mr-2 d-none d-md-block">{firstName}</i>
                  <Logout />
                </h6>
              </div>
            </div>
          </div>

          <NewProductsCustomersTable />
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  role: state.auth.auth,
  name: state.user.user,
  user: state.customerDetail.customer,
  load: state.customerDetail.load,
  loading: state.customerDetail.loading,
  detail: state.customerDetail.details,
  session: state.customerDetail.session,
});

NewProductGeniatech.propTypes = {
  name: PropTypes.shape(),
  getLoggedInUser: PropTypes.func,
  getUser: PropTypes.func,
  history: PropTypes.shape(),
};
export default connect(
  mapStateToProps,
  {
    searchForUserByEmail,
    getPaymentHistory,
    getUserDetails,
    searchByPhoneOrMac,
    searchByName,
    getLoggedInUser,
    getUser,
  },
)(NewProductGeniatech);
