import { API_URL } from '../../../../utils/config';

export const GET_UNCONFIRMED_EMPLOYEES_URL = `${API_URL}/employeeReg`;
export const GET_CONFIRMED_EMPLOYEES_URL = `${API_URL}/employee`;
export const GET_ONE_EMPLOYEE_URL = `${API_URL}/employee`;
export const EMPLOYEES_BY_DEPARTMENT_URL = `${API_URL}/department/employees`;
export const GET_EMPLOYEES_LEAVE_DAYS = `${API_URL}/leave/getLeaveDays`;
export const SET_EMPLOYEES_LEAVE_DAYS = `${API_URL}/leave/setLeaveDays`;
export const EDIT_EMPLOYEES_LEAVE_DAYS = `${API_URL}/leave/editLeaveDays`;
// export const EMPLOYEES_COLLEAGUES_BY_DEPARTMENT_URL = `${API_URL}department/employees/:department_id`;
