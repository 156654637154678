import * as types from '../../../../../constants/types/shared/noc/LteTypes';
import * as loading from '../../../../../constants/types/shared/LoadingTypes';

const profileReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.CREATE_PROFILE:
      return {
        ...state,
        creating: true,
      };

    case types.STOP_CREATE_PROFILE:
      return {
        ...state,
        creating: false,
      };

    case types.GET_PROFILES:
      return {
        ...state,
        profiles: payload,
        loading: false,
      };

    case loading.LOADING_STARTS:
      return {
        ...state,
        loading: true,
      };

    case loading.LOADING_STOPS:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default profileReducer;
