import { GET_PAID_CUSTOMERS } from '../../../constants/types/sales/customers/CustomersTypes';
import { FETCHING_STARTS } from '../../../constants/types/shared/LoadingTypes';

const initialState = {
  paid: [],
  load: false,
};

export const SalesCustomersReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case FETCHING_STARTS:
      return {
        ...state,
        load: true,
      };

    case GET_PAID_CUSTOMERS:
      return {
        ...state,
        paid: payload,
        load: false,
      };

    default:
      return state;
  }
};

export default SalesCustomersReducer;
