/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

const SearchDelistedCustomers = ({ handleChange, filterDelisted, search: { searchValue, fromDate, toDate } }) => {
  const validateForm = () => searchValue.length > 0;

  return (
    <div className="d-flex">
      <form className="form-inline ml-3" onSubmit={filterDelisted}>
        <div className="form-group mr-2">
          <input
            className="form-control py-2"
            type="text"
            placeholder="Search Value"
            name="searchValue"
            value={searchValue}
            onChange={handleChange}
          />
        </div>
        <div className="form-group mx-2">
          <label className="mt-2 p-0 copy-font">From: </label>
          <input type="date" className="form-control mr-2" name="fromDate" value={fromDate} onChange={handleChange} />
          <label className="mt-2 p-0 copy-font">To: </label>
          <input type="date" className="form-control" name="toDate" value={toDate} onChange={handleChange} />
        </div>
        <div className="form-group mx-2">
          <button
            className={`btn btn-${validateForm() ? 'green' : 'secondary'} `}
            type="submit"
            disabled={!validateForm()}
          >
            Search
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchDelistedCustomers;
