/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';

const AssetTeamViewUsersTable = ({ users, NotFoundText }) => {
  const tableData =
    users && users.length ? (
      users.map((user, i) => {
        const { Name, username, Expiration_date, Mail, Mobile } = user;
        return (
          <tr className="text-nowrap" key={i}>
            <td>{i + 1}</td>
            <td>{Name}</td>
            <td>{username}</td>
            <td>{Expiration_date}</td>
            <td>{Mail}</td>
            <td>{Mobile}</td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="4" className="text-center">
          {NotFoundText}
        </td>
      </tr>
    );

  return tableData;
};

export default AssetTeamViewUsersTable;
