import React from 'react';
import SuperAdminNavBar from '../../../../layouts/super-admin/SuperAdminNavBar';
import StatisticsOverviewBody from './StatisticsOverviewBody';

export default function StatisticsOverview() {
  return (
    <div className="s-layout">
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <SuperAdminNavBar />
          <StatisticsOverviewBody />
        </main>
      </div>
    </div>
  );
}
