/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';

import { NoItemRow, TizetiGIFLogoTable } from '../../../../../utils/loader/loadingHelper';
import ViewCustomerDetailsLog from './ViewCustomerDetailsLog';
import views from '../../../../../assets/img/icons/view.png';
import { useCallLog } from '../../../../../hooks/customer-support/call-logs/useCallLog';

const CallLogAnalyticsTable = ({ loaders, analytics, from, to }) => {
  const [view, setView] = useState(false);

  const [userState, setUserState] = useState('');
  const { logs, getEmployeeLogs, pages, page, isLoading } = useCallLog();
  const viewUser = async (item) => {
    console.log(item);

    setView(true);
    const data = {
      from: from,
      to: to,
      employee_auth_id: item.employee_auth_id,
    };
    getEmployeeLogs(data, page);
    setUserState(item);
  };

  const tableData =
    analytics && analytics.length ? (
      analytics.map((item, i) => {
        return (
          <tr className="" key={i}>
            <td>{i + 1}</td>
            {item.employeeData && item.employeeData.length ? (
              item.employeeData.map((items, i) => {
                return (
                  <>
                    <td>{items.firstName + ' ' + items.lastName}</td>
                    <td>{items.email}</td>
                    <td>{items.extension}</td>
                    <td>{item.logCount}</td>
                    <td
                      className="text-center img-pointer noBorder"
                      onClick={() => viewUser(item)}
                      // style={{ width: '40%' }}
                    >
                      <span className="view">
                        <img src={views} alt="" data-toggle="tooltip" data-placement="top" title="View" />
                      </span>
                    </td>
                  </>
                );
              })
            ) : (
              <NoItemRow colSpan="20" text="There is no employee data" />
            )}
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan="20" text="No Records" />
    );

  return (
    <div className="table-responsive content col-12 px-1">
      <table className="table">
        <thead>
          <tr className="text-nowrap">
            <th>#</th>
            <th>Full Name</th>
            <th>Email</th>
            <th>Extension</th>
            <th>Log Count</th>
            <th>Action</th>
          </tr>
        </thead>
        <ViewCustomerDetailsLog
          show={view}
          setShow={setView}
          from={from}
          to={to}
          list={userState}
          logs={logs}
          isLoading={isLoading}
          pages={pages}
          getEmployeeLogs={getEmployeeLogs}
        />
        <tbody>{loaders ? <TizetiGIFLogoTable colSpan="20" /> : tableData}</tbody>
      </table>
    </div>
  );
};

export default CallLogAnalyticsTable;
