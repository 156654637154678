/* eslint-disable func-names */
/* eslint-disable react/button-has-type */
import React, { Component } from 'react';
import axios from 'axios';
import AddNoLos from '../../noc/los/AddNoLos';
import { GET_LOS_URL } from '../../../../../services/api/field-support/LosUrl';

class Ghana extends Component {
  constructor(props) {
    super(props);

    this.state = {
      basestations: [],
      from: '',
      to: '',
    };
  }

  componentDidMount() {
    this.fetchBaseStations();
  }

  renderMap = () => {
    loadScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyD1DrDBUd6GNL2EIBCxK-K0OjkTny8kbuA&callback=initMap');
    window.initMap = this.initMap;
  };

  fetchBaseStations = async () => {
    try {
      const response = await axios.get(`${GET_LOS_URL}country=gh`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      console.log(response.data);
      if (response.data) {
        this.setState(
          {
            basestations: response.data.data,
          },
          this.renderMap(),
        );
      }
    } catch (e) {
      console.log(e.response);
    }
  };

  initMap = () => {
    const map = new window.google.maps.Map(document.getElementById('map'), {
      // center: { lat: 6.548653, lng: 3.54882 },
      center: { lat: 7.9465, lng: 1.0232 },
      zoom: 7,
      maxZoom: 0, // 18
      minZoom: 0, // 16
      zoomControlOptions: {
        style: window.google.maps.ZoomControlStyle.SMALL,
      },
      scaleControl: true, // enable scale control
      mapTypeId: window.google.maps.MapTypeId.HYBRID, // google map type
    });

    const infowindow = new window.google.maps.InfoWindow();
    const { basestations } = this.state;

    // eslint-disable-next-line array-callback-return
    basestations.map((basestation) => {
      const contentString = `${basestation.address}`;

      const marker = new window.google.maps.Marker({
        position: {
          lat: basestation.latitude,
          lng: basestation.longitude,
        },
        map,
        // icon: 'https://res.cloudinary.com/tizeti/image/upload/v1542971012/Group-1.png',
        icon: 'http://www.my9ja.com/estatewifi/icons/pin_red.png',
        title: basestation.name,
        // animation: window.google.maps.Animation.BOUNCE
      });

      const circle = new window.google.maps.Circle({
        map,
        radius: 2000, // 2 kilometeres in metres
        fillColor: 'red',
        // strokeColor: '#FF0000',
        strokeOpacity: 0.2,
        strokeWeight: 0.12,
        fillOpacity: 0.25,
      });

      circle.bindTo('center', marker, 'position');

      marker.addListener('click', function() {
        // chnage content
        infowindow.setContent(contentString);
        infowindow.open(map, marker);
      });
    });
  };

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  getLosWithDate = async () => {
    const { from, to } = this.state;

    try {
      const response = await axios.get(`${GET_LOS_URL}country=gh&from=${from}&to=${to}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.data) {
        this.setState(
          {
            basestations: response.data.data,
          },
          this.renderMap(),
        );
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  submitForm = () => {
    const { from, to } = this.state;
    return from.length > 0 && to.length > 0;
  };

  render() {
    const isEnabled = this.submitForm();

    const { from, to } = this.state;
    return (
      <div className="col-12">
        <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-3 stated">
          {/* <div> */}
          {/*  <span>From</span> */}
          {/*  <input */}
          {/*    type="date" */}
          {/*    name="from" */}
          {/*    value={from} */}
          {/*    onChange={this.onChange} */}
          {/*  /> */}
          {/*  <span>To</span> */}
          {/*  <input */}
          {/*    type="date" */}
          {/*    name="to" */}
          {/*    value={to} */}
          {/*    onChange={this.onChange} */}
          {/*  /> */}
          {/*  <button */}
          {/*    disabled={!isEnabled} */}
          {/*    className="btn btn-success" */}
          {/*    onClick={this.getLosWithDate} */}
          {/*  > */}
          {/*    Search With Date */}
          {/*  </button> */}
          {/* </div> */}
          <button
            className="btn btn-green mb-4 mt-3 bases pull-right"
            type="submit"
            data-toggle="modal"
            data-target="#station"
          >
            Add No LOS
          </button>
          <AddNoLos />
          <div id="map" />
        </div>
      </div>
    );
  }
}

function loadScript(url) {
  const index = window.document.getElementsByTagName('script')[0];
  const script = window.document.createElement('script');
  script.src = url;
  script.async = true;
  script.defer = true;
  index.parentNode.insertBefore(script, index);
}

export default Ghana;
