/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { useCustomers } from '../../../../../hooks/new-products/products/wificall/customers/useCustomers';

const BulkPushNotification = ({ allCustomers }) => {
  const { sendPushNotification } = useCustomers();
  const [text, setText] = useState('');
  const [title, setTitle] = useState('');

  const onSubmitBulk = (e) => {
    e.preventDefault();
    const data = qs.stringify(
      {
        action: 'sendWificallBulkPushNotification',
        title: `${title}`,
        message: `${text}`,
        card_id: allCustomers.map((cust) => {
          if (cust.select === true) return cust.user_sip.cardid;
        }),
      },
      { arrayFormat: 'brackets', encode: false },
    );
    sendPushNotification(data);
  };

  return (
    <div
      className="modal fade"
      id="bulkpush"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog mt-5" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="exampleModalLabel">
              Bulk Push Notification
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form onSubmit={onSubmitBulk}>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="title" className="copy-font p-0 col-form-label">
                  Title:
                </label>
                <input
                  type="text"
                  className="form-control border shadow"
                  id="title"
                  name="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="text" className="p-0 col-form-label">
                  Message:
                </label>
                <textarea
                  className="form-control"
                  id="text"
                  name="text"
                  value={text}
                  rows="12"
                  onChange={(e) => setText(e.target.value)}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">
                Close
              </button>
              <button type="submit" className="btn btn-primary">
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

BulkPushNotification.propTypes = {
  allCustomers: PropTypes.arrayOf.isRequired,
};

export default BulkPushNotification;
