/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import useKyc from '../../../../hooks/shared/noc/lte/kyc/useKyc';

const Activate = ({ user }) => {
  const { onFileChange, imsi, files, onChange, uploadLteKyc, loading } = useKyc();

  const submitForm = () => files.length > 1 && imsi.length > 0;

  const submitButton = () => {
    if (loading) {
      return (
        <div className="text-center">
          <button className="btn btn-green" disabled={loading}>
            Uploading...
          </button>
        </div>
      );
    }
    return (
      <div className="text-center">
        <button className="btn btn-green" disabled={!submitForm()}>
          Submit
        </button>
      </div>
    );
  };

  return (
    <div
      id="activate"
      className="modal fade in text-left"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Activate
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <form
                className="form-horizontal col-12"
                onSubmit={(e) => {
                  e.preventDefault();
                  uploadLteKyc(files, user.id, imsi);
                }}
              >
                <div className="form-group">
                  <div className="col-12 mb-3">
                    <label className="copy-font m-0 p-0">IMSI</label>
                    <input type="number" className="form-control pl-2" name="imsi" value={imsi} onChange={onChange} />
                  </div>

                  <div className="col-12 mb-3">
                    <label className="copy-font m-0 p-0">Upload Files</label>
                    <input type="file" className="form-control pl-2" name="files" onChange={onFileChange} multiple />
                  </div>
                  {submitButton()}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
Activate.propTypes = {
  user: PropTypes.shape(),
};
export default Activate;
