/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import view from '../../../../../../assets/img/icons/view.png';
import edit from '../../../../../../assets/img/icons/edit.png';
import { showDepartmentName } from '../../../../../../utils/departments/helpers';
import ConfirmDepartmentsPO from './ConfirmDepartmentsPO';
import ViewDepartmentsPO from './ViewDepartmentsPO';
import ViewInvoice from './ViewInvoice';

const DepartmentsPurchaseOrderTable = ({ lists }) => {
  const [order, setOrder] = useState({});
  const getOrder = (item) => setOrder(item);

  const tableData =
    lists.length && lists ? (
      lists.map((list, i) => {
        const {
          item,
          quantity,
          amount,
          currency,
          department_id,
          budget_code,
          manager_approval,
          procurement_approval,
        } = list;
        return (
          <tr key={i}>
            <td>{i + 1}</td>
            <td className="text-nowrap">{item}</td>
            <td>{quantity}</td>
            <td className="text-nowrap">{amount ? `${currency} ${amount}` : 'N/A'}</td>
            <td>{showDepartmentName.get(department_id)}</td>
            <td>{budget_code}</td>
            <td>
              <div className={`badge badge-${manager_approval === 'approved' ? 'success' : 'danger'} text-uppercase`}>
                {manager_approval}
              </div>
            </td>
            <td>
              <div
                className={`badge badge-${procurement_approval === 'approved' ? 'success' : 'danger'} text-uppercase`}
              >
                {procurement_approval}
              </div>
            </td>
            <td>
              <div className="d-flex text-center">
                <button
                  type="submit"
                  className="mx-2"
                  data-toggle="modal"
                  data-target="#viewInvoice"
                  onClick={() => getOrder(list)}
                >
                  <span className="view1">
                    <i className="fa fa-file-o" data-toggle="tooltip" data-placement="left" title="View Invoice" />
                  </span>
                </button>

                <button
                  type="submit"
                  className="mx-2"
                  data-toggle="modal"
                  data-target="#viewPO"
                  onClick={() => getOrder(list)}
                >
                  <span className="view">
                    <img src={view} alt="" data-toggle="tooltip" data-placement="left" title="View Details" />
                  </span>
                </button>

                {Number(sessionStorage.getItem('roleId')) === 9 ? (
                  <button
                    type="submit"
                    className="mr-2"
                    data-toggle="modal"
                    data-target="#confirmPO"
                    onClick={() => getOrder(list)}
                  >
                    <span className="view">
                      <img src={edit} data-toggle="tooltip" alt="" title="Edit" />
                    </span>
                  </button>
                ) : null}
              </div>
              <ViewInvoice order={order} />
              <ViewDepartmentsPO order={order} />
              <ConfirmDepartmentsPO order={order} />
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="10" className="text-center">
          There are no orders
        </td>
      </tr>
    );

  return tableData;
};

export default DepartmentsPurchaseOrderTable;
