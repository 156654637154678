/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React, { ReactNode } from 'react';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../../utils/loader/loadingHelper';
import moment from 'moment';

const UsageHistoryTable = ({ data, load }) => {
  const tableData =
    data && data.length ? (
      data.map((item, i) => {
        const {
          name,
          age,
          gender,
          email,
          address,
          state,
          mobile,
          uploadsMB,
          downloadsMB,
          created_at,
          UserName,
          acctstarttime,
        } = item;

        return (
          <tr className="" key={i}>
            <td>{i + 1}</td>
            <td>{name}</td>
            <td>{age}</td>
            <td>{gender}</td>
            <td> {moment(created_at).format('YYYY/MM/DD')}</td>
            <td>{email}</td>
            <td>{address}</td>
            <td>{state}</td>
            <td>{mobile}</td>
            <td>{UserName}</td>
            <td>{moment(acctstarttime).format('YYYY/MM/DD')}</td>
            <td>{uploadsMB}</td>
            <td>{downloadsMB}</td>
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan="20" text="There Are No Customers" />
    );
  return (
    <div className="table-responsive content col-12 px-1">
      <table className="table">
        <thead>
          <tr className="text-nowrap">
            <th>#</th>
            <th>Name</th>
            <th>Age</th>
            <th>Gender</th>
            <th>Date Created</th>
            <th>Email</th>
            <th>Address</th>
            <th>State</th>
            <th>Phone</th>
            <th>Mac ID</th>
            <th>Acct Start Time</th>
            <th> Upload(MB)</th>
            <th> Download(MB)</th>
          </tr>
        </thead>

        <tbody>{load ? <TizetiGIFLogoTable colSpan="20" /> : tableData}</tbody>
      </table>
    </div>
  );
};

export default UsageHistoryTable;
