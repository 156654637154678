import React from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import FieldSupportSideBar from '../../../../layouts/field-support/side-bar/FieldSupportSideBar';
import Logout from '../../../shared/auth/Logout';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import useCheckDepartment from '../../../../../hooks/shared/custom/check-department/field-support/useCheckDepartment';
import InstallationSubZones from './InstallationSubZones';

const FieldSupportSubZones = ({ history }) => {
  const { profile } = useUser();
  const { firstName } = profile;
  useCheckDepartment(history);
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <div className="d-flex col-lg-12 p-2 top-header">
          <div className="mt-3 d-flex col-lg-12 p-0">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link className="s-sidebar__trigger" to="#">
              <i className="fa fa-bars mr-3" />
            </Link>
            <FieldSupportSideBar />

            <h3 className="col-lg-6 overview-part d-none d-md-flex">Installation Sub Zones</h3>

            <h3 className="col-lg-6 overview-part d-sm-flex d-md-none">Zones</h3>

            <div className="d-flex profile-mail col-6">
              <h6>
                <i className="mr-auto">{firstName} </i>
                <Logout />
              </h6>
            </div>
          </div>
        </div>
        <InstallationSubZones />
      </main>
      <ToastContainer />
    </div>
  );
};

export default FieldSupportSubZones;
