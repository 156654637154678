/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import CustomerSupportNavBar from '../../../../layouts/customer-support/CustomerSupportNavBar';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import SupportCallsBody from './SupportCallsBody';

const SupportCalls = () => {
  const { profile, user } = useUser();
  const { firstName } = profile;
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <CustomerSupportNavBar currentPage="Support Calls" roleID={user.role_id} position={firstName} />
        <div className="mt-5 p-3">
          <SupportCallsBody />
        </div>
      </main>
    </div>
  );
};

export default SupportCalls;
