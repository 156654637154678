/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as url from '../../../../../services/api/shared/BasestationsUrl';
import { toast, ToastContainer } from 'react-toastify';
import { createBasestation } from '../../../../../actions/shared/basestations/BasestationsActions';
import axios from 'axios';

class AddBaseStation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      address: '',
      date: '',
      latitude: '',
      longitude: '',
      name: '',
      zones: [],
      installer: '',
      selectedZoneId: '',
      amount: '',
      subscribers: '',
    };

    this.selectZone = this.selectZone.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

  }

  componentDidMount() {
    this.getBasestationZones();
  }

  selectZone = (e) => {
    e.preventDefault();
    console.log(e.target.value);
    this.setState({ selectedZoneId: e.target.value });
    console.log(this.state.selectedZoneId);
  };

  getBasestationZones = async () => {
    try {
      const response = await axios.get(url.GET_BASESTATION_ZONES_URL, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.status === 200) {
        this.setState({ zones: response.data });
      }
      console.log(this.state.zones);
    } catch (error) {
      toast.error('Cannot get zones!');
    }
  };

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  onSubmit = (e) => {
    e.preventDefault();

    const { address, date, latitude, longitude, name, installer, amount, subscribers, selectedZoneId } = this.state;

    const data = {
      name,
      latitude,
      longitude,
      address,
      installer,
      date_of_commissioning: date,
      setup_amount: amount,
      zone_id: selectedZoneId,
      no_of_subscribers: subscribers,
    };

    // eslint-disable-next-line react/destructuring-assignment
    this.props.createBasestation(data);
  };

  submitForm() {
    const { address, date, latitude, longitude, name, installer, amount, selectedZoneId } = this.state;

    return (
      address.length > 0 &&
      date.length > 0 &&
      latitude.length > 0 &&
      longitude.length > 0 &&
      name.length > 0 &&
      installer.length > 0 &&
      selectedZoneId.length > 0 &&
      amount.length > 0
    );
  }

  render() {
    const isEnabled = this.submitForm();
    const { address, date, latitude, longitude, name, installer, selectedZoneId, amount, subscribers } = this.state;
    const { load } = this.props;

    return (
      <div
        id="station"
        className="modal fade in"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" style={{ margin: '6% auto' }}>
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center" id="myModalLabel">
                Add BaseStation
              </h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-xs-12 col-md-12 col-lg-12">
                  <form onSubmit={this.onSubmit}>
                    <div className="form-group">
                      <div className="col-lg-12 d-flex">
                        <div className="col-lg-6">
                          <label className="col-lg-12 text-justify copy-font">Basestation Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            value={name}
                            placeholder="Basestation name"
                            onChange={this.onChange}
                          />
                        </div>

                        <div className="col-lg-6">
                          <label className="col-lg-12 text-justify copy-font">Number of Subscribers</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Number of Subscribers"
                            name="subscribers"
                            value={subscribers}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>

                      <div className="col-lg-12 d-flex">
                        <div className="col-lg-6">
                          <label className="col-lg-12 text-justify copy-font">Latitude</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Latitude"
                            name="latitude"
                            value={latitude}
                            onChange={this.onChange}
                          />
                        </div>

                        <div className="col-lg-6">
                          <label className="col-lg-12 text-justify copy-font">Longitude</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Longitude"
                            name="longitude"
                            value={longitude}
                            onChange={this.onChange}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label className="col-lg-12 text-justify copy-font">Zone</label>
                          <select
                            name="selectedZoneId"
                            value={selectedZoneId}
                            className="form-control"
                            onChange={(e) => {
                              this.selectZone(e);
                            }}
                          >
                            <option value="">Select Zone</option>

                            {this.state.zones.map((zone) => (
                              <option key={zone.id} value={zone.id}>
                                {zone.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      {/* <div className="col-12 d-flex p-0 mb-3 mt-4"> */}

                      {/* </div> */}

                      <div className="col-lg-12 d-flex">
                        <div className="col-lg-6">
                          <label className="col-lg-12 text-justify copy-font">Name of Installer</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Installer"
                            name="installer"
                            value={installer}
                            onChange={this.onChange}
                          />
                        </div>

                        <div className="col-lg-6">
                          <label className="col-lg-12 text-justify copy-font">Setup amount</label>
                          <input
                            type="number"
                            min="0"
                            className="form-control"
                            placeholder="setup amount"
                            name="amount"
                            value={amount}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>

                      <div className="col-lg-12 d-flex">
                        <div className="col-lg-6">
                          <label className="col-lg-12 text-justify copy-font">Date of commissioning</label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Date of commissioning"
                            name="date"
                            value={date}
                            onChange={this.onChange}
                          />
                        </div>

                        <div className="col-lg-6">
                          <label className="col-lg-12 text-justify copy-font">Address</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Address of basestation"
                            name="address"
                            value={address}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="text-center mt-4">
                      {load ? (
                        // eslint-disable-next-line react/button-has-type
                        <button className="btn btn-green" disabled>
                          Adding BaseStation...
                        </button>
                      ) : (
                        <button className="btn btn-green" disabled={!isEnabled}>
                          Add BaseStation
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  load: state.basestation.load,
});

AddBaseStation.propTypes = {
  createBasestation: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  load: PropTypes.bool,
};

export default connect(mapStateToProps, { createBasestation })(AddBaseStation);
