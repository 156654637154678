import React, { useState } from 'react';
import PropTypes from 'prop-types';
import view from '../../../../../assets/img/icons/view.png';
import SmsDetails from './SmsDetails';

const RetailerSmsTable = ({ data }) => {
  const [one, setOne] = useState({});

  const getSms = (value) => {
    setOne(value);
  };
  const smsList = data
    ? data.map((sms, i) => (
        <tr>
          <td>{i + 1}</td>
          <td>{sms.to}</td>

          <td className="text-center">
            <button
              type="submit"
              data-toggle="modal"
              data-placement="right"
              onClick={() => getSms(sms)}
              data-target="#sms-details"
              style={{
                border: '0',
                backgroundColor: 'white',
              }}
            >
              <span className="view1">
                <img src={view} alt="" data-toggle="tooltip" data-placement="right" title="View" />
              </span>
            </button>
            <SmsDetails data={one} />
          </td>
        </tr>
      ))
    : null;
  return <tbody>{smsList}</tbody>;
};

RetailerSmsTable.propTypes = {
  // eslint-disable-next-line react/require-default-props
  data: PropTypes.shape(),
};
export default RetailerSmsTable;
