import * as types from '../../constants/types/shared/basestations/BasestationsTypes';
import { DELETE_BASESTATION, GET_LATEST_BASESTATIONS, GET_BASESTATION_BY_YEAR } from '../../constants/types/noc/NocTypes';
import { LOADING_STARTS, LOADING_STOPS } from '../../constants/types/shared/LoadingTypes';

const initialState = {
  basestations: [],
  routers: [],
  latestBasestations: [],
  basestationCountByYear: null,
  basestation: [],
  basestationUsers: [],
  basestationCount: '',
  load: false,
  users: [],
};

export const BasestationsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_BASESTATIONS:
      return {
        ...state,
        basestations: payload,
      };

    case types.GET_ROUTERS:
      return {
        ...state,
        routers: payload,
        load: false,
      };

    case types.GET_CONNECTED_USERS:
      return {
        ...state,
        basestationUsers: payload.basestationUsers,
        basestationCount: payload.basestationCount,
        load: false,
      };

    case GET_LATEST_BASESTATIONS:
      return {
        ...state,
        latestBasestations: payload,
      };
      case GET_BASESTATION_BY_YEAR:
      return {
        ...state,
        basestationCountByYear: payload,
      };

    case LOADING_STOPS:
      return {
        ...state,
        load: payload,
      };

    case LOADING_STARTS:
      return {
        ...state,
        load: payload,
      };

    case DELETE_BASESTATION:
      return {
        ...state,
        basestations: state.basestations.filter((base) => base.id !== payload),
      };

    case types.GET_COUNT_OF_USERS_CONNECTED:
      return {
        ...state,
        users: payload.count,
        name: payload.name,
      };

    case types.GET_MONTHLY_USERS:
      return { ...state, monthlyUsers: payload, load: false };

    case types.GET_ALL_CALL_STATIONS:
      return {
        ...state,
        callstations: payload,
        load: false,
      };

    default:
      return state;
  }
};

export default BasestationsReducer;
