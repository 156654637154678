/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect } from 'react';
import { filename, bandwidthHeaders } from '../../../../../utils/field-support/installations/helpers';
import CustomCsvDownload from '../../../shared/CustomCsvDownload';
import { useBandwidth } from '../../../../../hooks/partnership/bandwidth/useBandwidth';
import PartnershipBandwidthTable from './PartnershipBandwidthTable';
import AddPartnershipBandwidth from './AddPartnershipBandwidth';
import AddPartnershipBandwidthManually from './AddPartnershipBandwidthManually';

const PartnershipBandwidthBody = () => {
  const { lists, items, loader, load, isLoading, getBandwidthBasestations, getBandwidth } = useBandwidth();

  const [query, setQuery] = useState('');
  const [view, setView] = useState(false);

  useEffect(() => {
    getBandwidth();
    getBandwidthBasestations();
  }, []);

  return (
    <div className="col-12 p-0 after-summary">
      <div className="row mx-2">
        <form className="form-group inner-addon d-flex">
          <div className="input-group m-2">
            Filter By
            <select
              style={{ marginLeft: '1rem' }}
              name="query"
              className="form-control"
              onChange={(e) => setQuery(e.target.value)}
            >
              <option value=""> Select an Option </option>
              {items.length ? (
                items.map((base) => (
                  <option key={base.id} value={base.basestation}>
                    {base.basestation}
                  </option>
                ))
              ) : (
                <option>Loading</option>
              )}
            </select>
          </div>
        </form>
        <div className="col-12 d-flex mt-2">
          <button
            className="btn btn-green mr-3"
            type="button"
            data-toggle="modal"
            // data-target="#add_item"
            onClick={() => setView(true)}
          >
            Add Partnership Bandwidth Manually
          </button>

          <CustomCsvDownload
            data={lists || []}
            headers={bandwidthHeaders}
            filename={filename(`Bandwidth`)}
            classname="btn btn-green shadow-md"
            textcolor="white"
            text="Download Data"
          />
        </div>

        <div className="card x-panel p-0">
          <PartnershipBandwidthTable
            lists={lists}
            items={items}
            isLoading={isLoading}
            query={query}
            getBandwidth={getBandwidth}
          />
          {/* <div className="px-3">
            {pages > 1 ? (
              <ReactPaginate
                previousLabel="previous"
                nextLabel="next"
                breakLabel="..."
                breakClassName="break-me"
                pageCount={pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName="pagination"
                // subContainerClassName="pages pagination"
                activeClassName="active"
              />
            ) : null}
          </div> */}
          <AddPartnershipBandwidthManually
            view={view}
            setView={setView}
            lists={lists}
            items={items}
            getBandwidth={getBandwidth}
          />
        </div>
      </div>
    </div>
  );
};

export default PartnershipBandwidthBody;
