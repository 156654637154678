// /* eslint-disable @typescript-eslint/camelcase */
// /* eslint-disable jsx-a11y/label-has-for */
// /* eslint-disable jsx-a11y/label-has-associated-control */
// /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
// /* eslint-disable jsx-a11y/click-events-have-key-events */
// /* eslint-disable jsx-a11y/anchor-is-valid */
// /* eslint-disable react/no-unused-state */
// /* eslint-disable react/destructuring-assignment */
// /* eslint-disable camelcase */
// import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import axios from 'axios';
// import toastr from 'toastr';
// import CustomerSupportSideBar from '../../../../layouts/customer-support/CustomerSupportSideBar';
// import Logout from '../../../shared/auth/Logout';
// import logo from '../../../../../assets/img/logo/Tizeti-Logo.png';
// import mailblast from '../../../../../assets/img/icons/mailblast.svg';
// import { API_URL, customer_support } from '../../../../../utils/config';
// import KpiMonitorBody from './KpiMonitorBody';
//
// class CustomerSupportMonitor extends Component {
//   constructor(props) {
//     super(props);
//
//     this.state = {
//       kpis: [],
//       kpiId: {},
//       loading: false,
//       role: '',
//       pageCount: 0,
//       paginate: false,
//       percentage: 0,
//       firstName: '',
//     };
//     this.handlePageClick = this.handlePageClick.bind(this);
//   }
//
//   componentDidMount() {
//     this.getMonitors();
//     this.getPercentage();
//     this.fetchLoggedOnUser();
//     this.getUserDetails();
//   }
//
//   componentWillReceiveProps() {
//     this.fetchLoggedOnUser();
//   }
//
//   getPercentage() {
//     axios({
//       method: 'post',
//       url: `${API_URL}/kpi/count/count/${customer_support}?page=1`,
//       headers: {
//         Authorization: `Bearer ${sessionStorage.getItem('token')}`,
//       },
//     }).then((res) => {
//       this.setState({
//         percentage: res.data.percentage,
//       });
//
//       if (this.state.percentage === null) {
//         this.setState({
//           percentage: 0,
//         });
//       }
//     });
//   }
//   getMonitors() {
//     this.setState({
//       loading: true,
//       paginate: false,
//     });
//
//     const url = `${API_URL}/kpi/department/${customer_support}?page=1`;
//     // console.log(url);
//
//     axios({
//       method: 'post',
//       url,
//       headers: {
//         Authorization: `Bearer ${sessionStorage.getItem('token')}`,
//       },
//     })
//       .then((response) => {
//         // console.log(response.data);
//         if (response.data.logs) {
//           this.setState({
//             kpis: response.data.logs,
//             loading: false,
//             pageCount: response.data.pages,
//             paginate: true,
//           });
//         }
//         if (this.state.pageCount < 2) {
//           this.setState({
//             paginate: false,
//           });
//         } else {
//           this.setState({
//             paginate: true,
//           });
//         }
//       })
//       .catch((err) => {
//         if (err) {
//           toastr.info('Something went wrong!!');
//         }
//       });
//   }
//   getUserDetails() {
//     axios({
//       method: 'get',
//       url: `${API_URL}/employee/loggedOnEmployee`,
//       headers: {
//         Authorization: `Bearer ${sessionStorage.getItem('token')}`,
//       },
//     }).then((res) => {
//       this.setState({
//         firstName: res.data.firstName,
//       });
//     });
//   }
//   completeKpi = (kpi) => {
//     const url = `${API_URL}/kpi/user/${kpi.id}`;
//
//     const data = {
//       status: 100,
//     };
//
//     axios({
//       method: 'put',
//       url,
//       data,
//       headers: {
//         Authorization: `Bearer ${sessionStorage.getItem('token')}`,
//       },
//     })
//       .then((response) => {
//         if (response.data[1]) {
//           toastr.success('KPI Completed!');
//           this.getMonitors();
//           this.getPercentage();
//         }
//       })
//       .catch((err) => {
//         if (err) {
//           //   console.log(err);
//         }
//       });
//   };
//   getKpi = (kpi) => {
//     this.setState({
//       kpiId: kpi,
//     });
//   };
//   handlePageClick(pageNumber) {
//     const { selected } = pageNumber;
//
//     this.setState({
//       loading: true,
//       paginate: false,
//     });
//
//     axios({
//       method: 'post',
//       url: `${API_URL}/kpi/listkpi/${selected}`,
//       headers: {
//         Authorization: `Bearer ${sessionStorage.getItem('token')}`,
//       },
//     }).then((response) => {
//       if (response.data.logs) {
//         this.setState({
//           kpis: response.data.logs,
//           loading: false,
//           pageCount: response.data.pages,
//           paginate: true,
//         });
//       }
//       if (this.state.pageCount < 2) {
//         this.setState({
//           paginate: false,
//         });
//       } else {
//         this.setState({
//           paginate: true,
//         });
//       }
//     });
//   }
//
//   render() {
//     const { role, firstName } = this.state;
//
//     return (
//     );
//   }
// }
//
// export default CustomerSupportMonitor;

import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../../../assets/img/logo/Tizeti-Logo.png';
import mailblast from '../../../../../assets/img/icons/mailblast.svg';
import Logout from '../../../shared/auth/Logout';
import CustomerSupportSideBar from '../../../../layouts/customer-support/CustomerSupportSideBar';
import KpiMonitorBody from '../../../shared/kpis/KpiMonitorBody';
import { useUser } from '../../../../../hooks/shared/user/useUser';

const CustomerSupportKpiMonitor = () => {
  const { profile, user } = useUser();
  const { firstName } = profile;
  const { role_id } = user;
  return (
    <div className="main-wrapper">
      <header className="topbar shade bg-white fixed-top">
        <nav className="navbar navbar-expand-lg navbar-light top-navbar fixed-top">
          <Link className="navbar-brand" to="/customer-support">
            <img src={logo} alt="homepage" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse mt-3" id="navbarText">
            <ul className="navbar-nav">
              <li className="nav-item active">
                <Link className="nav-link ml-5" to="#">
                  <h3>
                    KPI Monitor <span className="sr-only">(current)</span>
                  </h3>
                </Link>
              </li>
            </ul>
            <span className="navbar-text d-flex">
              {Number(role_id) > 2 ? (
                <Link
                  to="/customer-support/mail-blast"
                  className="d-flex mr-4"
                  style={{
                    color: 'gray',
                    textDecoration: 'none',
                  }}
                >
                  <img src={mailblast} className="logout mr-2" alt="" />
                  <span>
                    <h6 className="mt-2">
                      <strong>MailBlast</strong>
                    </h6>
                  </span>
                </Link>
              ) : null}
              <h6 className="admin mt-2">
                {firstName}
                <Logout />
              </h6>
            </span>
          </div>
        </nav>
      </header>

      <div className="all-sides col-lg-12 col-md-12 col-sm-12">
        <CustomerSupportSideBar role={role_id} />
        <KpiMonitorBody />
      </div>
    </div>
  );
};

export default CustomerSupportKpiMonitor;
