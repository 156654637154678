import axios from 'axios';
import { API_URL, header } from '../../../utils/config';
import { WIFICALL_PLANS_URL } from '../../../services/api/wificall/PlansUrls';
import { GET_WIFICALL_PLANS } from '../../../constants/types/wificall/WificallTypes';

// eslint-disable-next-line import/prefer-default-export
export const getWificallPlans = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${API_URL}/${WIFICALL_PLANS_URL}`,
      header,
    );
    if (response.data) {
      dispatch({
        type: GET_WIFICALL_PLANS,
        payload: response.data,
      });
    }
  } catch (e) {}
};
