import React from 'react';
import { CAMBIUM_LICENSE_KEYS_URL } from '../api/CambiumLicenseKeysUrl';
import AppContext from '../../../../../../../context/shared/AppContext';
import useCambiumLicenseKeys from '../hooks/useCambiumLicenseKeys';

const PtpVlanIpProvider = ({ children }) => {
  const { data, loading, paginateChange, loadingForm, onChange, formValue, onSubmit, pages } = useCambiumLicenseKeys(
    `${CAMBIUM_LICENSE_KEYS_URL}`,
  );
  console.log(data);
  return (
    <AppContext.Provider
      value={{
        data,
        loading,
        paginateChange,
        loadingForm,
        onChange,
        formValue,
        onSubmit,
        pages,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export default PtpVlanIpProvider;
