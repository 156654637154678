import React from 'react';
import { ToastContainer } from 'react-toastify';
import NocNavBar from '../../../../../layouts/noc/NocNavBar';
import SitesBody from './SitesBody';
import { admin, NOC } from '../../../../../../utils/config';
import { useUser } from '../../../../../../hooks/shared/user/useUser';
import SitesProvider from './context/SitesProvider';
import useCheckTwoDepartments from '../../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';

const Sites = ({ history }) => {
  const { profile } = useUser();
  const { firstName } = profile;
  useCheckTwoDepartments(`${NOC}`, `${admin}`, history);
  return (
    <div className="s-layout">
      <SitesProvider>
        <main className="s-layout__content col-lg-10 p-0">
          <NocNavBar currentPage="CNMAESTRO - Sites" position={firstName} />
          <div className="container">
            <div className="row mt-5">
              <div className="mt-4 col-lg-12 col-md-12 col-sm-12">
                <div className="container">
                  <SitesBody />
                </div>
              </div>
            </div>
          </div>
        </main>
        <ToastContainer />
      </SitesProvider>
    </div>
  );
};

export default Sites;
