/* eslint-disable react/button-has-type */
import React from 'react';
import CustomerSessionDetails from './session/CustomerSessionDetails';
import CustomerUsage from './usage/CustomerUsage';

const Archives = () => {
  return (
    <div className="container-fluid">
      <CustomerSessionDetails />

      <CustomerUsage />
    </div>
  );
};

export default Archives;
