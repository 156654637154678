/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import edit from '../../../../../assets/img/icons/edit.png';
import Delete from '../../../../../assets/img/icons/delete.png';
import { getDepartments } from '../../../../../actions/shared/departments/DepartmentsActions';

class DepartmentTable extends Component {
  componentDidMount() {
    this.props.getDepartments();
  }

  render() {
    const { departments } = this.props;

    const departmentsTable = departments ? (
      departments.map((department, i) => (
        <tr>
          <td>{i + 1}</td>
          <td>{department.name}</td>
          {/* <td>Ola Johnson</td> */}
          {/* <td>Michael Wilson</td> */}
          {/* <td>sales@tizeti.com</td> */}
          <td>{moment(department.created_at).format('DD-MM-YYYY')}</td>
          <td>
            <div className="d-flex">
              <button
                type="submit"
                data-toggle="modal"
                //   data-target="#edit"
                style={{ border: '0', backgroundColor: 'white' }}
              >
                <span className="view mr-2">
                  <img src={edit} data-toggle="tooltip" data-placement="top" title="Edit" />
                </span>
              </button>

              <button
                type="submit"
                data-toggle="modal"
                // data-target="#cancel"
                style={{ border: '0', backgroundColor: 'white' }}
              >
                <span className="view">
                  <img src={Delete} data-toggle="tooltip" data-placement="right" title="Delete" />
                </span>
              </button>
            </div>
          </td>
        </tr>
      ))
    ) : (
      <small>no departments</small>
    );
    return <tbody>{departmentsTable}</tbody>;
  }
}

const mapStateToProps = (state) => ({
  departments: state.departments.departments,
});

DepartmentTable.propTypes = {
  getDepartments: PropTypes.func,
  departments: PropTypes.shape(),
};

export default connect(mapStateToProps, { getDepartments })(DepartmentTable);
