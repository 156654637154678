/* eslint-disable import/prefer-default-export */
import { API_URL } from '../../../../utils/config';

export const GET_BADNWIDTH_URL = `${API_URL}/legacy/bw_admin`;
export const GET_BADNWIDTH_SCHEDULE_URL = `${API_URL}/legacy/getBwSchedule`;
export const GET_BADNWIDTH_DROPDOWN_URL = `${API_URL}/legacy/bw_admin_basestations`;
export const EDIT_BADNWIDTH_URL = `${API_URL}/legacy/bw_admin`;

export const ADD_BANDNWIDTH_CSV_URL = `https://api.tizeti.com/api/v1/legacy/uploadCsvBwSchedule`;
export const ADD_BANDNWIDTH_SCHEDULE_URL = `${API_URL}/legacy/createBwSchedule`;
export const DELETE_BANDNWIDTH_SCHEDULE_URL = `${API_URL}/legacy/deleteBwSchedule`;
export const DELETE_BANDNWIDTH_URL = `https://api.tizeti.com/api/v1/legacy/truncateBwSchedulerTable`;
