/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { useUser } from '../../../../../../../hooks/shared/user/useUser';
import useCheckDepartment from '../../../../../../../hooks/shared/custom/check-department/useCheckDepartment';
import { LOGGED_IN_USER_URL } from '../../../../../../../services/api/auth/AuthUrl';
import { NEW_PRODUCTS, SUPER_ADMIN } from '../../../../../../../utils/departments/helpers';
import WifiCallNavBar from '../../../../../../layouts/wificall/WifiCallNavBar';
import AssignedAndUnassignedBody from './AssignedAndUnassignedBody';
import useCheckTwoDepartments from '../../../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';

const WifiCallAssignedAndUnassigned = ({ history }) => {
  useCheckTwoDepartments(`${NEW_PRODUCTS}`, `${SUPER_ADMIN}`, history);

  const { profile, user } = useUser();
  const { firstName } = profile;
  const { role_id } = user;

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <WifiCallNavBar currentPage="Assigned And Unassigned" position={firstName} role_id={role_id} />
        <div className="container">
          <div className="row mt-5">
            <AssignedAndUnassignedBody />
          </div>
        </div>
      </main>
    </div>
  );
};

WifiCallAssignedAndUnassigned.propTypes = {
  history: PropTypes.shape(),
};

export default WifiCallAssignedAndUnassigned;
