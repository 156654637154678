import React, { useState } from 'react';
import view from '../../../../../../assets/img/icons/view.png';
import edit from '../../../../../../assets/img/icons/edit.png';
import { showDepartmentName } from '../../../../../../utils/departments/helpers';
import ConfirmPurchaseOrder from './ConfirmPurchaseOrder';
import ViewPurchaseOrder from './ViewPurchaseOrder';

const PurchaseOrderTable = ({ lists }) => {
  const [order, setOrder] = useState({});
  const getOrder = (item) => setOrder(item);

  const tableData =
    lists.length && lists ? (
      lists.map((list, i) => {
        const { item, quantity, department_id, manager_approval } = list;
        return (
          <tr className="text-nowrap" key={i}>
            <td>{i + 1}</td>
            <td className="text-nowrap">{item}</td>
            <td>{quantity}</td>
            <td>{showDepartmentName.get(department_id)}</td>
            <td>
              <div className={`badge badge-${manager_approval ? 'success' : 'danger'} text-uppercase`}>
                {manager_approval}
              </div>
            </td>
            <td>
              <div className="d-flex text-center">
                <button
                  type="submit"
                  className="mx-2"
                  data-toggle="modal"
                  data-placement="right"
                  data-target="#viewPO"
                  onClick={() => getOrder(list)}
                >
                  <span className="view">
                    <img src={view} alt="" data-toggle="tooltip" data-placement="left" title="View Document" />
                  </span>
                </button>
                <button
                  type="submit"
                  className="mr-2"
                  data-toggle="modal"
                  data-placement="right"
                  data-target="#confirmPO"
                  onClick={() => getOrder(list)}
                >
                  <span className="view">
                    <img src={edit} data-toggle="tooltip" alt="" data-placement="top" title="Send To Accounts" />
                  </span>
                </button>
              </div>
              <ConfirmPurchaseOrder order={order} />
              <ViewPurchaseOrder order={order} />
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="8" className="text-center">
          There are no orders
        </td>
      </tr>
    );

  return tableData;
};

export default PurchaseOrderTable;
