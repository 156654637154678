import React, { Component } from 'react';
import '../../../../../styles/style.css';
import '../../../../../styles/basestation.css';
import axios from 'axios';
import moment from 'moment';
import { toastr } from 'toastr';
import { Link } from 'react-router-dom';
import xml2js from 'xml2js';
import { connect } from 'react-redux';
import AddBaseStation from '../../super-admin/basestations/AddBaseStation';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import { getUser } from '../../../../../actions/shared/user/UserActions';
import NocLogo from '../../../../layouts/noc/NocLogo';
import Logout from '../../../shared/auth/Logout';
import NetworkOperationsSideBar from '../../../../layouts/noc/NetworkOperationsSideBar';
import { admin, API_URL, NOC } from '../../../../../utils/config';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';

class NetworkOperationsBaseStationMonitoring extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      basestations: [],
      keyword: '',
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      filtered: [],
      isLoading: false,
      move: false,
      out: false,
      monitoring: [],
      icon: '',
    };

    this.onKeyPress = this.onKeyPress.bind(this);
  }

  parse = (data) => {
    const { parseString } = xml2js;
    const xml = data;
    parseString(xml, function(err, result) {
      // console.dir(result);

      this.setState({
        monitoring: result,
      });
    });
  };

  componentDidMount() {
    this.fetchBaseStations();
    this.getBasestationMonitoring();
    this.props.getUser();
  }

  getBasestationMonitoring() {
    axios({
      method: 'get',
      url: `${API_URL}/basestation/mainone`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      // url: 'https://www.my9ja.com/estatewifi/map_process?basestation=mainone'
    }).then((response) => {
      // console.log(response.data.elements[0].elements.attributes["name"]);
      // console.log(response.data.elements[0].elements);
      // console.log(response.data.elements[0].elements.attributes);

      if (response.data) {
        this.setState(
          {
            monitoring: response.data.elements[0].elements,
          },
          () => this.renderMap(),
        );
      }

      /* if(sessionStorage.getItem('monitor')){

                this.setState({

                    monitoring: JSON.parse(sessionStorage.getItem('monitor'))

                /!*}, () => sessionStorage.removeItem('monitor'));*!/
                }, this.renderMap(), () => sessionStorage.removeItem('monitor') );

            } */
    });
  }

  renderMap = () => {
    loadScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyD1DrDBUd6GNL2EIBCxK-K0OjkTny8kbuA&callback=initMap');
    window.initMap = this.initMap;
  };

  fetchBaseStations = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };

    axios
      .get(`${API_URL}/baseStation`, config)
      .then((res) => {
        this.setState(
          {
            basestations: res.data,
          },
          this.renderMap(),
        );
      })
      .catch((err) => {});
  };

  componentWillReceiveProps() {
    this.checkDepartment();
  }

  checkDepartment = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.department_id !== `${NOC}` && response.data.department_id !== `${admin}`) {
        this.props.history.push('/');

        sessionStorage.setItem('token', '');
        sessionStorage.clear();

        toastr.info('You are not allowed to view this page');
      }
    } catch (e) {
      // console.log(e.response);
    }
  };

  initMap = () => {
    const map = new window.google.maps.Map(document.getElementById('map'), {
      center: { lat: 6.548653, lng: 3.54882 },
      zoom: 11,
      maxZoom: 0, // 18
      minZoom: 0, // 16
      zoomControlOptions: {
        style: window.google.maps.ZoomControlStyle.SMALL,
      },
      scaleControl: true, // enable scale control
      mapTypeId: window.google.maps.MapTypeId.HYBRID, // google map type
    });

    const infowindow = new window.google.maps.InfoWindow();

    this.state.monitoring.map((basestation) => {
      const contentString = `${basestation.attributes.name}`;

      const marker = new window.google.maps.Marker({
        position: { lat: Number(basestation.attributes.lat), lng: Number(basestation.attributes.lng) },
        map,
        // icon: 'https://res.cloudinary.com/tizeti/image/upload/v1542971012/Group-1.png',
        // icon: 'http://www.my9ja.com/estatewifi/icons/pin_green.png',
        icon:
          basestation.attributes.mstatus === 'up'
            ? 'http://www.my9ja.com/estatewifi/icons/pin_green.png'
            : this.state.icon,
        title: basestation.attributes.name,
        // animation: window.google.maps.Animation.BOUNCE
      });

      const circle = new window.google.maps.Circle({
        map,
        radius: 2000, // 2 kilometeres in metres
        fillColor: basestation.attributes.mstatus === 'up' ? '#00FF00' : '#DC143C',
        // strokeColor: '#FF0000',
        strokeOpacity: 0.2,
        strokeWeight: 0.12,
        fillOpacity: 0.25,
      });

      circle.bindTo('center', marker, 'position');

      marker.addListener('click', function() {
        // chnage content
        infowindow.setContent(contentString);
        infowindow.open(map, marker);
      });
    });
  };

  onKeyPress = (e) => {
    const keyword = e.target.value;

    if (e.which === 13) {
      if (keyword !== '') {
        this.setState({
          isLoading: true,
        });

        const base = this.state.basestations.filter((basestation) => {
          if (basestation.name.indexOf(keyword) !== -1) {
            return basestation;
          }
          if (basestation.address.indexOf(keyword) !== -1) {
            return basestation;
          }
          if (basestation.installer.indexOf(keyword) !== -1) {
            return basestation;
          }
        });

        // console.log(base);

        /* this.geocodeAddress(base); */
        this.setState({
          filtered: base,
          isLoading: false,
        });
      } else {
        this.setState({
          filtered: this.state.basestations,
          keyword,
        });
      }
    }
  };

  changeIcon = (data) => {
    if (data.mstatus === 'up') {
      return 'http://www.my9ja.com/estatewifi/icons/pin_green.png';
    }
    return 'http://www.my9ja.com/estatewifi/icons/pin_green.png';
  };

  render() {
    const { isLoading, filtered } = this.state;
    const { user } = this.props;
    const { firstName } = user;

    if (isLoading) {
      return <img src={loader} alt="" />;
    }

    return (
      <div className="main-wrapper">
        <header className="topbar shade bg-white fixed-top">
          <nav className="navbar navbar-expand-lg navbar-light top-navbar fixed-top">
            <NocLogo />
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse mt-3" id="navbarText">
              <ul className="navbar-nav">
                <li className="nav-item active">
                  <Link className="nav-link" to="#">
                    <h3>
                      Basestations<span className="sr-only">(current)</span>
                    </h3>
                  </Link>
                </li>
              </ul>
              <span className="navbar-text">
                <h6>
                  <i>{firstName} </i>
                  <Logout />
                </h6>
              </span>
            </div>
          </nav>
        </header>
        <div className="all-sides col-lg-12 col-md-12 col-sm-12">
          <NetworkOperationsSideBar />

          <div className="page-wrapper col-lg-10">
            <div className="container-fluid state">
              <button
                className="btn btn-green mb-4 mt-3 bases pull-right"
                type="submit"
                data-toggle="modal"
                data-target="#station"
              >
                Add Basestation
              </button>
              <AddBaseStation />
              <div id="map" />

              <div className="frame">
                <div className="contain col-lg-12 col-md-12 col-sm-12">
                  {filtered.map((basestation) => (
                    <div>
                      <div className="info bg-white mt-3 p-3">
                        <label className="copy-font">BaseStation name</label>
                        <p className="copy-font">{basestation.name}</p>

                        <label className="copy-font">Address</label>
                        <p className="copy-font">{basestation.address}</p>

                        <label className="copy-font">Installed By</label>
                        <p className="copy-font">{basestation.installer}</p>
                        <label className="copy-font">Date of Commisioning</label>
                        <p className="copy-font">{moment(basestation.created_at).format('MM/DD/YYYY')}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        *
      </div>
    );
  }
}

function loadScript(url) {
  const index = window.document.getElementsByTagName('script')[0];
  const script = window.document.createElement('script');
  script.src = url;
  script.async = true;
  script.defer = true;
  index.parentNode.insertBefore(script, index);
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect(
  mapStateToProps,
  { getUser },
)(NetworkOperationsBaseStationMonitoring);
