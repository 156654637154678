/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import view from '../../../../../assets/img/icons/view.png';
import { NoItemRow } from '../../../../../utils/loader/loadingHelper';
import { useInstallationsPerBasestation } from './hooks/useInstallationsPerBasestation';
import InstalledUsers from './InstalledUsers';

const InstallationsTable = ({ days, items }) => {
  const { getInstalled7DaysAgo, customers, isLoading } = useInstallationsPerBasestation();
  const [basestationName, setBasestationName] = useState('');
  const getDetails = (customer) => {
    setBasestationName(customer.basestationame);
    const data = {
      action: 'tizeti_asset_installed_days_users',
      days_ago: days,
      calledstationid: customer.basestationame,
    };
    getInstalled7DaysAgo(data);
  };
  return items && items.length ? (
    items.sort().map((item, i) => {
      const { basestationame, zone, region_location, country, installation_count } = item;
      return (
        <tr className="text-nowrap" key={i}>
          <td>{i + 1}</td>
          <td>{basestationame.toUpperCase()}</td>
          <td>{zone}</td>
          <td>{region_location}</td>
          <td>{country}</td>
          <td>{installation_count}</td>
          <td>
            <button
              type="submit"
              data-toggle="tooltip"
              data-placement="left"
              title="View"
              className="noBorder"
              onClick={() => getDetails(item)}
            >
              <span className="view1 ml-4">
                <img src={view} alt="" data-toggle="modal" data-target="#viewDetails" />
              </span>
            </button>
          </td>
          <InstalledUsers zone={basestationName} users={customers} isLoading={isLoading} />
        </tr>
      );
    })
  ) : (
    <NoItemRow colSpan="5" text="No Installations" />
  );
};

export default InstallationsTable;
