import { API_URL, SALES } from "../../../../utils/config";

export const GET_EMPLOYEES_IN_SALES_URL = `${API_URL}/qa/department/${SALES}`;
export const GET_SALES_CALL_LOGS_URL = `${API_URL}/call_logs/${SALES}?from=2019-01-01&to=`;
export const GET_SALES_PERSONNEL_CALL_LOGS_URL = `${API_URL}/logs/list/employee?from=2019-01-01&to=`;
export const CONVERT_TO_USER_URL = `${API_URL}/users`;
export const ADD_LOG_URL = `${API_URL}/users`;
export const EDIT_LOG_URL = `${API_URL}/users`;
export const GET_LOGS_URL = `${API_URL}/users/logs`;
export const SEARCH_LOG_URL = `${API_URL}/users/searchuser`;


