/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import moment from 'moment';
import { ToastContainer } from 'react-toastify';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import AccountsManagerTableHead from './AccountsManagerTableHead';
import view from '../../../../../../assets/img/icons/view.png';
import ViewReceipt from '../../../../shared/requisition/shared/ViewReceipt';
import CommonRequisitionDetail from '../../../../shared/requisition/shared/CommonRequistionDetails';
import ApproveRequisition from '../shared/ApproveRequisition';
import helpers from '../../../../../../utils/requisition/helpers';
import { useRequisition } from '../../../../../../hooks/shared/requisition/useRequisition';

const AccountsManagerRequisitions = ({
  requistions,
  status,
  reloadFunction,
  totalPages,

  load,
  handlePageClick,

  // giveManagerApproval,
  giveManagerDisApproval,
  setCheckAllRequisition,
  setCheckOneOfAllRequisition,
}) => {
  const [requisit, setRequisit] = useState({});
  const [viewFile, setViewFile] = useState({});
  const { showApproved, onCloseModal, onOpenModal } = useRequisition();
  const getRequsition = (req) => {
    setRequisit(req);
    onOpenModal();
  };

  const getFileRequsition = (req) => {
    setViewFile(req);
    if (req.imageUpload.split('.').pop() === 'document' || req.imageUpload.split('.').pop() === 'pdf') {
      window.open(req.imageUpload, '_blank');
    }
  };

  const requsitionList =
    requistions && requistions.length ? (
      requistions.map((requsition, i) => {
        return (
          <tr key={requsition.id}>
            <td>{i + 1}</td>
            <td>
              <input
                type="checkbox"
                checked={requsition.isChecked}
                value={requsition.id}
                id={requsition.id}
                onChange={setCheckOneOfAllRequisition}
              />
            </td>
            <td>
              {helpers.displayCurrency(requsition.currency)}

              {requsition.amount_in_digits}
            </td>
            <td>{requsition.amount_in_words}</td>
            <td>
              {requsition.account_name === undefined || requsition.account_name === null
                ? 'N/A'
                : requsition.account_name}
            </td>
            <td>
              {requsition.account_number === undefined || requsition.account_number === null
                ? 'N/A'
                : requsition.account_number}
            </td>
            <td>
              {requsition.bank_name === undefined || requsition.bank_name === null ? 'N/A' : requsition.bank_name}
            </td>
            <td>{requsition.account_approval}</td>
            <td>{requsition.manager_approval}</td>
            <td>{helpers.showCLevelApprovalStatus(requsition.c_level_approval)}</td>
            <td>{requsition.ceo_or_coo_approval}</td>
            <td>{requsition.in_favour_of}</td>
            <td>{requsition.requires_c_level_approval === false ? 'False' : 'True'}</td>
            <td>{requsition.requires_ceo_or_coo_approval === false ? 'False' : 'True'}</td>
            {requsition.created_at ? <td>{moment(requsition.created_at).format('MM/DD/YYYY')}</td> : 'N/A'}
            <td>{requsition.purpose}</td>
            {requsition.account_approval === 'queued' && (
              <>
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                <td onClick={() => getRequsition(requsition)}>
                  <small
                  // onClick={() => giveManagerApproval(requsition.id)}
                  >
                    <button className="btn btn-green">Approve</button>
                  </small>
                </td>
                <ApproveRequisition
                  req={requisit}
                  reloadFunction={reloadFunction}
                  showApproved={showApproved}
                  onCloseModal={onCloseModal}
                  onOpenModal={onOpenModal}
                />
                <td>
                  <small onClick={() => giveManagerDisApproval(requsition.id, reloadFunction)}>
                    <button className="btn btn-danger">Reject</button>
                  </small>
                </td>
              </>
            )}
            <td>
              <div className="mr-2 ml-2">
                <button
                  data-target="#viewReceipt"
                  data-toggle="modal"
                  type="submit"
                  onClick={() => getFileRequsition(requsition)}
                  style={{
                    border: '0',
                    backgroundColor: 'white',
                  }}
                >
                  <span className="view2">
                    <img src={view} alt="view" data-toggle="tooltip" data-placement="left" title="View" />
                  </span>
                </button>
                <ViewReceipt requisit={viewFile} />
              </div>
            </td>
            {/* <td>
              <span
                className={`badge badge-${
                  requsition.account_payment_status === 'paid' ? 'success' : 'danger'
                } text-uppercase`}
              >
                {requsition.account_payment_status}
              </span>
            </td> */}
            <td>
              <div className="mr-2 ml-2 d-flex">
                <button
                  data-target="#requisition"
                  data-toggle="modal"
                  type="submit"
                  onClick={() => getRequsition(requsition)}
                  style={{
                    border: '0',
                    backgroundColor: 'white',
                  }}
                >
                  <span className="view2">
                    <img src={view} alt="view" data-toggle="tooltip" data-placement="left" title="View" />
                  </span>
                </button>
                <CommonRequisitionDetail requisit={requisit} />
              </div>
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td>
          <p>No requisitions</p>
        </td>
      </tr>
    );

  return (
    <div className="card x-panel logs">
      <div className="table-responsive content">
        <table className="table copy-font">
          <AccountsManagerTableHead status={status} setCheckAllRequisition={setCheckAllRequisition} />

          {load ? <tbody>loading...</tbody> : <tbody>{requsitionList}</tbody>}
        </table>
      </div>
      {totalPages > 1 ? (
        <ReactPaginate
          previousLabel="<<"
          nextLabel=">>"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName="pagination"
          subContainerClassName="pages pagination"
          activeClassName="active"
        />
      ) : null}
      <ToastContainer />
    </div>
  );
};

AccountsManagerRequisitions.propTypes = {
  requistions: PropTypes.array,
  load: PropTypes.bool,
  giveManagerDisApproval: PropTypes.func,
  page: PropTypes.number,
  handlePageClick: PropTypes.func,
  totalPages: PropTypes.number,
  status: PropTypes.string,
};

export default AccountsManagerRequisitions;
