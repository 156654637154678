/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-shadow */
/* eslint-disable react/require-default-props */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import 'toastr/build/toastr.min.css';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getRoles, signUp } from '../../../../../actions/auth/sign-up/SignUpActions';
import { getDepartments } from '../../../../../actions/shared/departments/DepartmentsActions';

class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      role: '',
      department: '',
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const { getDepartments, getRoles } = this.props;
    getDepartments();
    getRoles();
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  onSubmit = (e) => {
    e.preventDefault();

    const { email, role, password, department } = this.state;
    const { history, signUp } = this.props;
    const signup = {
      email,
      role_id: role,
      password,
      department_id: department,
    };

    signUp(signup, history);
  };

  showDepartments() {
    const { departments } = this.props;
    if (departments && departments.length) {
      return (
        <div>
          <label className="label2 copy-font">Department</label>
          <select className="form-control" onChange={this.onChange} name="department">
            <option>Select Department</option>
            {departments.map((department) => {
              return (
                <option value={department.id} key={department.id}>
                  {department.name.toUpperCase()}
                </option>
              );
            })}
          </select>
        </div>
      );
    }
    return (
      <div>
        <label className="label2 copy-font">Department</label>
        <small>No Departments to display</small>
      </div>
    );
  }

  submitForm() {
    const { email, role, password, department } = this.state;
    return email.length > 0 && role.length > 0 && password.length > 0 && department.length > 0;
  }

  render() {
    const { roles, load } = this.props;
    const { email, password } = this.state;
    const isEnabled = this.submitForm();

    return (
      <section id="content1">
        <form onSubmit={this.onSubmit} className="form-horizontal login-form col-sm-12 col-lg-12 col-md-12">
          <div className="form-group col-lg-9 col-sm-12">
            <div>
              <label className="label2 copy-font">Email Address</label>
              <input
                type="text"
                className="form-control"
                onChange={this.onChange}
                value={email}
                name="email"
                placeholder="Enter your Tizeti email address"
              />
            </div>

            <div>
              <label className="label2 copy-font">Role</label>
              <select className="form-control form-dropdown" onChange={this.onChange} name="role">
                <option value="">Select Role</option>
                {roles &&
                  roles.map((role) => (
                    <option value={role.id} key={role.id}>
                      {role.name.toUpperCase()}
                    </option>
                  ))}
              </select>
            </div>
            <div>
              <label className="label2 copy-font" htmlFor="department" aria-labelledby="department">
                Choose Department
              </label>

              {this.showDepartments()}
            </div>

            <div>
              <label className="label2 copy-font">Password</label>
              <input
                type="password"
                className="form-control"
                placeholder="Enter password"
                name="password"
                onChange={this.onChange}
                value={password}
              />
            </div>
            {/* <div className="d-flex remember col-sm-12 col-lg-12" style={{ width: '100%' }}>
                        <div className="col-sm-6 col-lg-6">
                            <input className="mt-1" type="radio"/>&nbsp;Remember me
                        </div>
                    </div> */}
            <ToastContainer />
          </div>
          <br />
          <br />
          <div className="wrapper-button mb-3">
            {load ? (
              <button className="btn btn-green copy-font" disabled style={{ backgroundColor: '#036D61' }}>
                Submitting...
              </button>
            ) : (
              <button className="btn btn-green" style={{ backgroundColor: '#036D61' }} disabled={!isEnabled}>
                SIGN UP
              </button>
            )}
          </div>
        </form>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  roles: state.roles.roles,
  departments: state.departments.departments,
  load: state.login.load,
});

SignUp.propTypes = {
  roles: PropTypes.instanceOf(Array),
  departments: PropTypes.instanceOf(Array),
  getRoles: PropTypes.func.isRequired,
  getDepartments: PropTypes.func.isRequired,
  signUp: PropTypes.func.isRequired,
  history: PropTypes.shape(),
  load: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  {
    getRoles,
    getDepartments,
    signUp,
  },
)(SignUp);
