/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { NOC, SALES } from '../../../../../utils/config';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';

const ColonlessCustomerDetail = ({
  load,
  details,
  session,
  address,
  name: { firstName, lastName },
  mail,
  customerRef,
  itemprice,
  createdDate,
  username,
  installationDate,
}) => {
  // eslint-disable-next-line @typescript-eslint/camelcase
  const { upload7days, upload30days, usage7days, usage30days, expiry_date } = details;
  const { ipaddress, lastconnected, onlinestatus, staticIpaddress } = session;
  return (
    <div
      id="col-details"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" style={{ width: '600px' }}>
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Customer Details
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body detail" style={{ padding: '0', textAlign: 'justify' }}>
            {load ? (
              <img src={loader} alt="" />
            ) : (
              <div className="row">
                <div className="col-lg-12 d-flex">
                  <div className="col-lg-6 detail-label1">
                    <label id="fn">Name</label>
                    {/* <label id="ln">Last Name</label> */}
                    <label id="email">Email</label>
                    <label id="ip">IP</label>
                    <label>Monthly Plan</label>
                    {/* {Number(role_id) > 2 ? <label id="static">Static IP</label> : ''} */}
                    {/* <label htmlFor="">Address</label> */}
                    {/* {role >'2' ? <label id="email">Static IP</label> : ''} */}
                  </div>

                  <div className="col-lg-6 detail-result1">
                    <p className="mt-4 mb-1">{firstName && lastName ? `${firstName} ${lastName}` : 'N/A'}</p>
                    <p className="mb-1">{mail || null}</p>
                    {ipaddress ? (
                      <p className="mb-1">
                        <a href={`http://${ipaddress}`} target="_blank">
                          {ipaddress}
                        </a>
                      </p>
                    ) : (
                      <p className="mb-1">N/A</p>
                    )}
                    {/* { role > '2' ? <p>198.168.2.1 </p> : '' } */}
                    {Number(`${sessionStorage.getItem('roleId')}`) > 1 ||
                    (staticIpaddress && `${sessionStorage.getItem('departmentId')}` === `${NOC}`) ? (
                      // eslint-disable-next-line react/jsx-indent
                      <p className="mb-1">{staticIpaddress}</p>
                    ) : null}
                    {itemprice >= 0 ? <p>{itemprice}</p> : <p>N/A</p>}
                  </div>
                </div>
                <hr />
                <div className="col-lg-12 d-flex">
                  <div className="col-lg-6 detail-label2">
                    <label id="bal">Expiry Date</label>
                    <label id="bal">Upload/Download(7 days) </label>
                    <label id="bal">Upload/Download(30 days) </label>
                    <label id="phone1">Address </label>
                  </div>

                  <div className="col-lg-6 detail-result2 mt-4">
                    {/* eslint-disable-next-line @typescript-eslint/camelcase */}
                    {expiry_date ? <p className="mb-1">{expiry_date}</p> : <p className="mb-1">N/A</p>}
                    {upload7days || usage7days ? (
                      <p className="mb-1">
                        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                        {upload7days}/{usage7days}
                      </p>
                    ) : (
                      <p className="mb-1">N/A</p>
                    )}
                    {upload30days || usage30days ? (
                      <p className="mb-1">
                        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                        {upload30days}/{usage30days}
                      </p>
                    ) : (
                      <p className="mb-1">N/A</p>
                    )}
                    {address ? <p className="mt-1">{address}</p> : <p className="mt-1">N/A</p>}
                  </div>
                </div>

                {onlinestatus === 'offline' ? (
                  <div className="col-lg-12 d-flex">
                    <div className="col-lg-6 detail-label3">
                      <label id="email">Last Connected</label>
                    </div>
                    <div className="col-lg-6 detail-result3">
                      <p className="mt-4 mb-0">{lastconnected}</p>
                    </div>
                  </div>
                ) : null}

                <div className="col-lg-12 d-flex">
                  <div className="col-lg-6 detail-label3">
                    <label id="email">Customer Account Number</label>
                    {`${sessionStorage.getItem('departmentId')}` === `${SALES}` ? (
                      <label id="email">Created Date</label>
                    ) : null}
                  </div>
                  <div className="col-lg-6 detail-result3">
                    <p className="mt-4 mb-0">{customerRef}</p>

                    {`${sessionStorage.getItem('departmentId')}` === `${SALES}` ? (
                      <p className="mt-1">{username ? <Moment format="D MMM YYYY">{createdDate}</Moment> : 'N/A'}</p>
                    ) : null}
                  </div>
                </div>

                <div className="col-lg-12 d-flex">
                  <div className="col-lg-6 detail-label3">
                    <label id="email">Installation Date</label>
                  </div>
                  <div className="col-lg-6 detail-result3">
                    <p className="mt-4 mb-0">
                      {installationDate && installationDate.user
                        ? new Date(installationDate.created_at).toLocaleDateString('en-gb')
                        : 'N/A'}
                    </p>
                  </div>
                </div>

                <div className="col-lg-12 d-flex">
                  <div className="col-lg-6 detail-label4">
                    <label id="status" style={{ display: 'block' }}>
                      Status
                    </label>
                  </div>

                  <div className="col-lg-6 detail-result4">
                    {onlinestatus === 'online' ? (
                      <p className="mt-4 mb-0">
                        <img
                          src="https://res.cloudinary.com/teewhy/image/upload/v1548342137/green_sign"
                          alt="connected-icon"
                        />
                        <span> Online </span>
                      </p>
                    ) : (
                      <p className="mb-0 mt-4">
                        <img
                          src="https://res.cloudinary.com/teewhy/image/upload/v1548342137/Red_sign"
                          alt="connected-icon"
                        />
                        <span> Offline </span>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
ColonlessCustomerDetail.propTypes = {
  load: PropTypes.bool,
  address: PropTypes.string,
  name: PropTypes.string,
  mail: PropTypes.string,
  customerRef: PropTypes.string,
  itemprice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  details: PropTypes.shape(),
  session: PropTypes.shape(),
  createdDate: PropTypes.string,
  username: PropTypes.string,
  installationDate: PropTypes.shape(),
};
export default ColonlessCustomerDetail;
