import React from 'react';
import Select from 'react-select';

const CustomMultiSelect = ({ handleChange, name, options, classname, isMulti, value }) => {
  return (
    <Select
      value={value}
      onChange={handleChange}
      isMulti={isMulti}
      name={name}
      options={options}
      className={classname}
    />
  );
};

export default CustomMultiSelect;
