/* eslint-disable react/button-has-type */
import React, { useContext } from 'react';
import SitesTable from './SitesTable';
import GeniatechContext from '../../../../../../context/GeniatechContext';
import SiteTable from './site/SiteTable';

const SitesBody = () => {
  const { searchStatus, reset } = useContext(GeniatechContext);
  return (
    <div className="row mt-5">
      {searchStatus && (
        <div className="form-group ml-2">
          <button onClick={reset} className="btn btn-green">
            See All Sites
          </button>
        </div>
      )}
      {searchStatus ? <SiteTable /> : <SitesTable />}
    </div>
  );
};

export default SitesBody;
