/* eslint-disable @typescript-eslint/camelcase */
import { useReducer, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { stringify } from 'qs';
import formReducer from '../../../../../../../reducers/shared/custom/formReducer';
import { LOADING_STARTS, LOADING_STOPS } from '../../../../../../../constants/types/shared/LoadingTypes';
import { SEARCH_LTE_USERS } from '../../apis/LteUrls';

const EXTEND_LTE_URL = '10.1.5.20:7000/api/v1/open5gs/reactivatesubscriber';
const useLteCustomers = () => {
  const [{ loadingForm }, dispatch] = useReducer(formReducer, {
    loadingForm: false,
  });
  const [search, setSearch] = useState({ imsi: '' });
  const [data, setData] = useState([]);
  const [showExtendModal, setShowExtendModal] = useState(false);
  const [extending, setExtending] = useState(false);
  const [extend, setExtend] = useState({ no_of_months: '', no_of_days: '', no_geniatech: '' });
  const [extensionType, setExtensionType] = useState('');
  const onExtensionTypeChange = (e) => setExtensionType(e.target.value);
  const handleChange = (e) => setExtend({ ...extend, [e.target.name]: e.target.value });
  const extendByMonth = async (monthExtension) => {
    const res = await axios.post(EXTEND_LTE_URL, monthExtension);
  };
  const extendByDay = async (dayExtension) => {
    const res = await axios.post(EXTEND_LTE_URL, dayExtension);
  };
  const onSubmit = async (param) => {
    setExtending(true);
    try {
      const response = await axios.post(`https://legacy.tizeti.com/geniatechapi/index.php`, stringify(param));
      if (response.data.status === true) {
        setExtend({ no_of_months: '', no_of_days: '', no_geniatech: '' });
        setShowExtendModal(!showExtendModal);
        setExtending(false);
        toast.success('User Account extended');
      } else {
        toast.info('Unable to extend User Account');
      }
    } catch (error) {
      toast.info('Something went wrong while extending user account');
    }

    // const geniatech = param.no_geniatech !== 'Transfer Payment' ? 'yes' : 'no';
    // if (extensionType === 'months') {
    //   await extendByMonth({
    //     imsi: 'imsi',
    //     no_of_months: param.no_of_months,
    //     no_geniatech: geniatech,
    //   });
    // } else {
    //   await extendByDay({ imsi: 'imsi', no_of_days: param.no_of_days, no_geniatech: geniatech });
    // }
  };
  const onOpenExtendModal = () => setShowExtendModal(!showExtendModal);
  const onChange = (e) => {
    const { name, value } = e.target;
    setSearch({ ...search, [name]: value });
  };
  const onSearch = async () => {
    try {
      dispatch({ type: LOADING_STARTS });
      const response = await axios.post(`${SEARCH_LTE_USERS}`, search, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        setData(response.data.data);
        dispatch({ type: LOADING_STOPS });
      } else {
        toast.info('Unable to search');
        dispatch({ type: LOADING_STOPS });
      }
    } catch (e) {
      dispatch({ type: LOADING_STOPS });
      toast.info('Something went wrong!');
    }
  };

  return {
    loadingForm,
    onChange,
    search,
    onSearch,
    data,
    showExtendModal,
    onOpenExtendModal,
    handleChange,
    extend,
    extending,
    onSubmit,
    onExtensionTypeChange,
    extensionType,
  };
};

export default useLteCustomers;
