import axios from 'axios';
import { toast } from 'react-toastify';
import { API_URL } from '../../../utils/config';

export const get_all_basestation_sub_zones = async (id) => {
  const res = await axios.get(`${API_URL}/subzone/${id}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
  });

  try {
    if (res.data) {
      // toast.success('Basestation successfully updated');
      return res.data;
    }
  } catch (error) {
    toast.error('error getting sub zones');
  }
};
