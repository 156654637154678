/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/require-default-props */
import React from 'react';
import { useUser } from '../../../../../../hooks/shared/user/useUser';
import ActiveCustomersGraph from '../../../../shared/customers/active-customers/ActiveCustomersGraph';
import CustomerSupportNavBar from '../../../../../layouts/customer-support/CustomerSupportNavBar';

const CustomerSupportActiveCustomers = () => {
  const { profile, user } = useUser();
  const { firstName } = profile;
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <CustomerSupportNavBar currentPage="Active Customers" roleID={user.role_id} position={firstName} />
        <div className="mt-5">
          <ActiveCustomersGraph />
        </div>
      </main>
    </div>
  );
};

export default CustomerSupportActiveCustomers;
