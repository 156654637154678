/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import qs from 'qs';
import ReactPaginate from 'react-paginate';
import CustomerDetailsSharedTable from './CustomerDetailsSharedTable';
import useCustomerDetails from '../../../../hooks/customer-support/customer-details/useCustomerDetails';

const CustomerDetailsSharedBody = () => {
  const { getCustomerDetails, pages, items, total, isLoading } = useCustomerDetails();
  console.log(items);
  const [Date, setDate] = useState('');
  const [mac, setMac] = useState('');

  const data = qs.stringify({
    installationDate: Date,
    mac_id: mac,
  });

  const [page] = useState(1);

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    getCustomerDetails(data, selectedPage);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    getCustomerDetails(data, page);
  };

  // useEffect(() => {
  //   getCustomerDetails(data);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <div className="mt-5 p-3">
      <div className="container-fluid pt-4 ">
        <div className="row">
          <form className="form-inline mt-4 inner-addon d-flex" onSubmit={onSubmit}>
            <div className="form-group mx-2">
              <label className="mt-2 p-0 copy-font">From: </label>
              <input
                className="form-control mr-2"
                name="date"
                value={Date}
                onChange={(e) => setDate(e.target.value)}
                readOnly={false}
                type="date"
              />

              <label className="mt-2 p-0 copy-font">Mac ID </label>
              <div className="input-group mb-3 mr-3">
                <input
                  className="form-control mr-auto empty pl-5"
                  type="text"
                  placeholder="search for user"
                  name="search"
                  value={mac}
                  onChange={(e) => setMac(e.target.value)}
                />
                <span className="fa fa-search" />
              </div>
            </div>

            <div className="mb-3">
              <button className="btn btn-green" style={{ height: '39px' }}>
                Search
              </button>
            </div>
          </form>
          <div className="p-0 col-12 d-flex justify-content-between">
            {total > 0 ? (
              <div>
                Count: <span className="badge badge-secondary mr-4">{total}</span>
              </div>
            ) : null}
          </div>
          <CustomerDetailsSharedTable items={items} isLoading={isLoading} />
          <div className="px-3">
            {pages > 1 ? (
              <ReactPaginate
                previousLabel="previous"
                nextLabel="next"
                breakLabel="..."
                breakClassName="break-me"
                pageCount={pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName="pagination"
                // subContainerClassName="pages pagination"
                activeClassName="active"
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDetailsSharedBody;
