/* eslint-disable react/require-default-props */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getDepartments } from '../../../../../actions/shared/departments/DepartmentsActions';
import dept from '../../../../../assets/img/icons/departments.png';

// eslint-disable-next-line no-shadow
const Departments = ({ getDepartments, departments }) => {
  useEffect(() => {
    getDepartments();
   
  }, [getDepartments]);
  return (
    <div className="col-lg-12">
      <div className="boxes">
        <div className="text-center mt-4">
          <img src={dept} alt="homepage" />
        </div>

        <div className="count">{departments ? departments.length : null}</div>
        

        <h3 style={{ color: '#01CBD8' }}>Departments</h3>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  departments: state.departments.departments,
});


Departments.propTypes = {
  departments: PropTypes.shape(),
  getDepartments: PropTypes.func,
};

export default connect(mapStateToProps, { getDepartments })(Departments);
