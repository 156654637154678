/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { useReferral } from '../../../../../hooks/sales/referral/useReferral';
import { referralHeaders, filename } from '../../../../../utils/field-support/installations/helpers';
import CustomCsvDownload from '../../../shared/CustomCsvDownload';
import ReferralTable from './ReferralTable';

const ReferralBody = () => {
  const { lists, pages, count, isLoading, getReferralRequest, searchUsingKeyword } = useReferral();
 
  
  const [keyword, setKeyword] = useState('');
  const [option, setOption] = useState('');
  const [page] = useState(1);
  const [date, setDate] = useState({ from: '', to: '' });

  const searchEmail = { email: keyword.trim() };
  const searchName = { name: keyword.trim() };
  const searchPhone = { phoneNumber: keyword.trim() };
  const searchStatus = { status: keyword.trim() };

  const handleDate = (e) => {
    const { name, value } = e.target;
    setDate({ ...date, [name]: value });
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    if (option === 'name') return searchUsingKeyword(selectedPage, searchName);
    if (option === 'email') return searchUsingKeyword(selectedPage, searchEmail);
    if (option === 'phone') return searchUsingKeyword(selectedPage, searchPhone);
    if (option === 'status') return searchUsingKeyword(selectedPage, searchStatus);
    if (option === 'date') return searchUsingKeyword(selectedPage, date);
    return getReferralRequest(selectedPage);
  };

  const filterUsingKeyword = (e) => {
    e.preventDefault();
    switch (option) {
      case 'name':
        return searchUsingKeyword(page, searchName);
      case 'email':
        return searchUsingKeyword(page, searchEmail);

      case 'phone':
        return searchUsingKeyword(page, searchPhone);
      case 'status':
        return searchUsingKeyword(page, searchStatus);

      case 'date':
        return searchUsingKeyword(page, date);

      default:
        return null;
    }
  };

  useEffect(() => {
    getReferralRequest(page);
  }, []);

  return (
    <div className="col-12 p-0 after-summary">
      <div className="row mx-2">
        <div className="mb-1">
          <div className="smallTop d-flex">
            <form onSubmit={filterUsingKeyword} className="form-inline ml-3">
              <div className="form-group">
                <select name="chooseField" onChange={(e) => setOption(e.target.value)} className="form-control">
                  <option value="">--Choose Search Field--</option>
                  <option value="name">Name</option>
                  <option value="email">Email</option>
                  <option value="phone">Phone</option>
                  <option value="status">Status</option>
                  <option value="date">Date</option>
                </select>
              </div>
              {option !== 'date' ? (
                <div className="form-group ml-2">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Input Search Term"
                    style={{ width: '230px' }}
                    name="keyword"
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                  />
                </div>
              ) : (
                <>
                  <div className="form-group d-flex ml-2">
                    <small className="mr-1">From: </small>
                    <input type="date" className="form-control" name="from" onChange={handleDate} />
                  </div>
                  <div className="form-group d-flex ml-2">
                    <small className="mr-1">To: </small>
                    <input type="date" className="form-control" name="to" onChange={handleDate} />
                  </div>
                </>
              )}
              <div className="form-group ml-2">
                <button className="btn btn-green" type="submit">
                  Search
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="col-12 d-flex mt-2">
          <CustomCsvDownload
            data={lists || []}
            headers={referralHeaders}
            filename={filename(`Referral`)}
            classname="btn btn-green shadow-md"
            textcolor="white"
            text="Download Data"
          />
        </div>

        <div className="card x-panel p-0">
          <ReferralTable lists={lists} isLoading={isLoading} />

          <div className="px-3">
            {pages > 1 ? (
              <ReactPaginate
                previousLabel="previous"
                nextLabel="next"
                breakLabel="..."
                breakClassName="break-me"
                pageCount={pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName="pagination"
                // subContainerClassName="pages pagination"
                activeClassName="active"
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralBody;
