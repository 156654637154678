/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import { admin } from '../../../../../utils/config';
import TizetiLogo from '../../../../../assets/img/logo/Tizeti-Logo.png';
import mailblast from '../../../../../assets/img/icons/mailblast.svg';
import { getUser } from '../../../../../actions/shared/user/UserActions';
import { getLoggedInUser } from '../../../../../actions/user/UserActions';
import SuperAdminSideBar from '../../../../layouts/super-admin/SuperAdminSideBar';
import Logout from '../../../shared/auth/Logout';
import CeoAndCooRequisitions from './CeoAndCooRequisitions';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';

class AdminRequisition extends Component {
  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.getUser();
    // eslint-disable-next-line react/destructuring-assignment
    this.props.getLoggedInUser();
    this.checkDepartment();
  }

  checkDepartment = async () => {
    try {
      const { history } = this.props;
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.department_id !== `${admin}`) {
        history.push('/');

        sessionStorage.setItem('token', '');
        sessionStorage.clear();

        toastr.info('You are not allowed to view this page');
      }
    } catch (e) {
      toastr.info('something went wrong!');
    }
  };

  render() {
    const { user, role } = this.props;
    const { role_id } = role;
    const { firstName } = user;

    return (
      <div className="main-wrapper">
        <header className="topbar shade bg-white fixed-top">
          <nav className="navbar navbar-expand-lg navbar-light top-navbar fixed-top">
            <Link className="navbar-brand" to="/accounts">
              <img src={TizetiLogo} alt="homepage" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse mt-3" id="navbarText">
              <ul className="navbar-nav">
                <li className="nav-item active">
                  <Link className="nav-link" to="#">
                    <h3>
                      Requisition
                      <span className="sr-only">(current)</span>
                    </h3>
                  </Link>
                </li>
              </ul>
              <span className="navbar-text">
                <div className="d-flex">
                  {Number(role_id) > 2 ? (
                    <Link
                      to="/admin/mail-blast"
                      className="d-flex"
                      style={{
                        color: 'gray',
                        textDecoration: 'none',
                      }}
                    >
                      <img src={mailblast} className="logout mr-2 mt-0 mb-2" />
                      <span>
                        <h6 className="mt-1 mr-3">
                          <strong>MailBlast</strong>
                        </h6>
                      </span>
                    </Link>
                  ) : null}
                  <h6 className="mr-0 mt-1">
                    {firstName}
                    <Logout />
                  </h6>
                </div>
              </span>
            </div>
          </nav>
        </header>

        <div className="all-sides col-lg-12">
          <SuperAdminSideBar />
          <div className="page-wrapper col-lg-10">
            <div className="container-fluid">
              <div className="row">
                {/* <select name="" id="">
                  Choose
                </select> */}
                {/* <MakeRequisitionButton /> */}
                <CeoAndCooRequisitions />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  role: state.auth.auth,
});

AdminRequisition.propTypes = {
  getLoggedInUser: PropTypes.func,
  getUser: PropTypes.func,
  role: PropTypes.shape(),
  history: PropTypes.shape(),
  user: PropTypes.shape(),
};

export default connect(
  mapStateToProps,
  {
    getUser,
    getLoggedInUser,
  },
)(AdminRequisition);
