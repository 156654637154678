/* eslint-disable react/no-array-index-key */
import React, { useContext } from 'react';
import loader from '../../../../../../../assets/img/icons/TizetiLoaderColored.gif';
import DevicesTableHead from './shared/DevicesTableHead';
import AppContext from '../../../../../../../context/shared/AppContext';
import helpers from '../../../../../../../utils/helpers';
// import { DevicesProps } from './shared/types/DevicesProps';

const DevicesTable = () => {
  const { loading, data } = useContext(AppContext);
  const tableData =
    data && data.length
      ? data.map((row, i) => (
          <tr className="text-nowrap" style={{ cursor: 'pointer' }} key={i + 1}>
            <td>{i + 1}</td>
            <td>{row.name}</td>
            <td>{row.mac}</td>
            <td>{row.config.variables.DISPLAY_NAME}</td>
            <td>{row.config.variables.LATITUDE}</td>
            <td>{row.config.variables.LONGITUDE}</td>
            <td>{row.config.version}</td>
            <td>{row.country}</td>
            <td>{row.description}</td>
            <td>{row.hardware_version}</td>
            <td>{row.ip}</td>
            <td>{row.location.gps}</td>
            <td>{row.location.coordinates}</td>
            <td>{row.location.type}</td>
            <td>{row.managed_account}</td>
            <td>{row.msn}</td>
            <td>{row.network}</td>
            <td>{row.network}</td>
            <td>{row.product}</td>
            <td>{helpers.formatDateAndTime(row.registration_date)}</td>
            <td>{row.software_version}</td>
            <td>{row.inactive_software_version}</td>
            <td>{row.status}</td>
            <td>{row.status_time}</td>
            <td>{row.last_reboot_reason}</td>
            <td>{row.tower}</td>
            <td>{row.type}</td>
          </tr>
        ))
      : 'no alarm';
  return (
    <div className="row mt-5">
      <div className="card x-panel mt-2 px-0">
        <div className="table-responsive content col-12 px-1">
          <table className="table">
            <DevicesTableHead />
            <tbody>
              {loading ? (
                <tr>
                  <img src={loader} alt="loading gif" />
                </tr>
              ) : (
                tableData
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DevicesTable;
