import React from 'react';
import useSnmp from '../hooks/useSnmp';
import { SNMP_URL } from '../api/SnmpUrl';
import AppContext from '../../../../../../../context/shared/AppContext';

const SnmpProvider = ({ children }) => {
  const { data, loading, paginateChange, loadingForm, onChange, formValue, onSubmit, pages } = useSnmp(`${SNMP_URL}`);

  return (
    <AppContext.Provider
      value={{
        data,
        loading,
        paginateChange,
        loadingForm,
        onChange,
        formValue,
        onSubmit,
        pages,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export default SnmpProvider;
