import React from 'react';
import Box from '@mui/material/Box';
import ChartTypeSelect from '../components/ChartTypeSelect';
import LineCharts from './LineCharts';
import BarCharts from './BarCharts';
import AreaCharts from './AreaCharts';

export default function ChartsContainer({
  getZones,
  state,
  sumOfActiveAndInactiveUsers,
  getAllActiveUsersCountValue,
  getAllInActiveUsersCountValue,
}) {
  const [chartType, setChartType] = React.useState('Bar-Chart');

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flexStart" sx={{ mt: 4 }}>
      <ChartTypeSelect setChartType={setChartType} chartType={chartType} />

      {chartType === 'Line-Chart' ? (
        <LineCharts
          getZones={getZones}
          state={state}
          sumOfActiveAndInactiveUsers={sumOfActiveAndInactiveUsers}
          getAllActiveUsersCountValue={getAllActiveUsersCountValue}
          getAllInActiveUsersCountValue={getAllInActiveUsersCountValue}
        />
      ) : chartType === 'Bar-Chart' ? (
        <BarCharts
          getZones={getZones}
          state={state}
          sumOfActiveAndInactiveUsers={sumOfActiveAndInactiveUsers}
          getAllActiveUsersCountValue={getAllActiveUsersCountValue}
          getAllInActiveUsersCountValue={getAllInActiveUsersCountValue}
        />
      ) : (
        chartType === 'Area-Chart' && (
          <AreaCharts
            getZones={getZones}
            state={state}
            sumOfActiveAndInactiveUsers={sumOfActiveAndInactiveUsers}
            getAllActiveUsersCountValue={getAllActiveUsersCountValue}
            getAllInActiveUsersCountValue={getAllInActiveUsersCountValue}
          />
        )
      )}
    </Box>
  );
}
