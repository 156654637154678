/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import '../../../../styles/style.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import toastr from 'toastr';
import callbutton from '../../../../assets/img/icons/Call_Button.png';
import profile from '../../../../assets/img/icons/Profile.png';
// import worldicon from '../../../../assets/img/icons/world_icon.fw.png';
// import sales from '../../../../assets/img/icons/sales_calls.png';
// import handle from '../../../../assets/img/icons/handle.fw.png';
import mailblast from '../../../../assets/img/icons/mailblast.svg';
// eslint-disable-next-line import/order
import { connect } from 'react-redux';
import CustomerServiceScript from '../../shared/CustomerServiceScript';
import RetentionScript from '../../shared/customer-support/RetentionScript';
import ViewUserProfile from '../../shared/staff/ViewUserProfile';
import { API_URL } from '../../../../utils/config';
import Logout from '../../shared/auth/Logout';
import { getLoggedInUser } from '../../../../actions/user/UserActions';
import CustomerSupportSideBar from '../../../layouts/customer-support/CustomerSupportSideBar';
import ExpiredNigeriaCustomers from './overview/ExpiredNigeriaCustomers';
import ExpiredGhanaCustomers from './overview/ExpiredGhanaCustomers';
import ExpiredLTECustomers from './overview/ExpiredLTECustomers';
import TotalExpiredCustomers from './overview/TotalExpiredCustomers';
import ExpiredNigeria90days from './overview/ExpiredNigeria90days';
import ExpiredGhana90days from './overview/ExpiredGhana90days';
import TotalExpired90days from './overview/TotalExpired90days';
import CreateProfilePrompt from '../../shared/CreateProfilePrompt';

class CustomerSupportOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dialler: '',
      dialled: '',
    };

    this.onChange = this.onChange.bind(this);
    this.call = this.call.bind(this);
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  componentDidMount() {
    this.getUserDetails();
  }

  getUserDetails() {
    axios({
      method: 'get',
      url: `${API_URL}/employee/loggedOnEmployee`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        this.setState({
          user: data.data,
        });
      })
      .catch((err) => {});
  }

  call(e) {
    e.preventDefault();
    const { dialler } = this.state;

    if (dialler === '') {
      toastr.error('Please enter a number');
    }

    if (dialler === '') {
      toastr.error('Please enter the number you want to call!');
    }

    // const data = {
    //   called: this.state.dialler,
    //   calling: this.state.dialled,
    //   username: sessionStorage.getItem('username'),
    // };
  }

  render() {
    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <Link className="s-sidebar__trigger" to="#">
                <i className="fa fa-bars mr-3" />
              </Link>
              <CustomerSupportSideBar />

              <h3 className="col-lg-6 overview-part">Overview</h3>

              <div className="d-flex profile-mail">
                <Link
                  to="/customer-support/mail-blast"
                  className=" d-none d-md-flex"
                  style={{ color: 'gray', textDecoration: 'none' }}
                >
                  <img src={mailblast} alt="" className="logout mr-2" />

                  <h6 className="mt-2 mr-3">
                    <strong>MailBlast</strong>
                  </h6>
                </Link>
                <Link to="#" data-toggle="modal" data-target="#add-employee">
                  <img src={profile} alt="" className="logout mt-1 mr-3" />
                </Link>

                <span className="mt-1">
                  <Logout />
                </span>
                <CreateProfilePrompt />
              </div>
            </div>
          </div>

          <div className="container mt-5">
            <ViewUserProfile />
            <div className="row mt-5">
              <div className="p-1 mt-5 col-lg-12 col-md-9 col-sm-12">
                <h6 className="m-2">Showing Statistics of Expired Customers</h6>
              </div>

              {/* <div className="col-lg col-md col-sm">
                <div className="boxes">
                  <div className="text-center">
                    <div className="text-center mt-3">
                      <img src={sales} alt="" />
                    </div>
                    <div className="count">0</div>
                    <h3 className="color-one">Avg. Calls/Hour</h3>
                  </div>
                </div>
              </div>

              <div className="col-lg col-md col-sm">
                <div className="boxes">
                  <div className="text-center">
                    <div className="text-center mt-2">
                      <img src={handle} alt="" />
                    </div>
                    <div className="count">0 Mins</div>
                    <h3 className="color-three">Avg. Handle Time</h3>
                  </div>
                </div>
              </div>
              <ExpiredCustomers /> */}

              <ExpiredNigeriaCustomers />
              <ExpiredGhanaCustomers />
              <ExpiredLTECustomers />
              <TotalExpiredCustomers />
              <div className="col-lg col-md col-sm">
                <div className="boxes">
                  <div className="text-center">
                    <div className="signs text-center mt-3">
                      <h6 className="text-center mb-0">
                        <strong>Dial </strong>
                      </h6>
                    </div>
                    <div className="col-12 d-flex mt-2">
                      <div className="col-9">
                        <div className="mb-2">
                          {/* <small className="p-0 m-0"><strong>To:</strong></small> */}
                          <input
                            type="tel"
                            className="shadow-sm form-control receive"
                            placeholder="To"
                            name="dialled"
                            onChange={this.onChange}
                            value={this.state.dialled}
                          />
                        </div>
                        <div className="mb-3">
                          {/* <small className="p-0 m-0"><strong>From:</strong></small> */}
                          <input
                            type="tel"
                            className="shadow-sm form-control receive"
                            placeholder="From"
                            name="dialler"
                            onChange={this.onChange}
                            value={this.state.dialler}
                          />
                        </div>
                      </div>

                      <div className="col-3 p-0 mt-3">
                        <img onClick={this.call} src={callbutton} alt="" className="call-button" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="p-1 mt-5 col-lg-12 col-md-9 col-sm-12">
                <h6 className="m-2">Showing Statistics of Expired Customers in the last 90 days</h6>
              </div>
              <ExpiredNigeria90days />
              <ExpiredGhana90days />
              <TotalExpired90days />
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card-group mt-1">
                  <div className="card o-income">
                    <div className="card-body col-lg-12 d-flex">
                      <div className="col-lg-12">
                        <div className="d-flex">
                          <h6 style={{ color: '#27A8B4' }}>Call Frequency by Services and Zones </h6>
                        </div>

                        <div className="d-flex col-lg-12 col-md-12 col-sm-12" style={{ padding: '0px' }}>
                          <div className="col-lg-12 col-sm-6">
                            <div className="d-flex" style={{ float: 'right' }}>
                              <p style={{ width: '23px', height: '18px', backgroundColor: '#FF7A00' }} />
                              <p className="copy-font" style={{ paddingLeft: '6px', paddingRight: '17px' }}>
                                Unlimited Internet
                              </p>
                              <p style={{ width: '23px', height: '18px', backgroundColor: '#01406D' }} />
                              <p className="copy-font" style={{ paddingLeft: '6px', paddingRight: '17px' }}>
                                Hotspot
                              </p>
                              <p style={{ width: '23px', height: '18px', backgroundColor: '#27A8B4' }} />
                              <p className="copy-font" style={{ paddingLeft: '5px' }}>
                                Teleport
                              </p>
                            </div>
                          </div>
                        </div>

                        <div id="customer-support" style={{ height: '300px' }} />
                      </div>
                    </div>
                  </div>
                </div>

                <span className="d-none d-md-flex">
                  <RetentionScript />
                  <CustomerServiceScript />
                </span>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  monthly: state.monthlyExpired.monthlyExpired,
  role: state.auth.auth,
});

export default connect(mapStateToProps, { getLoggedInUser })(CustomerSupportOverview);
