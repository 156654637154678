/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-prop-types */
import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import { connect } from 'react-redux';
import { API_URL, SALES } from '../../../../../utils/config';
import 'react-toastify/dist/ReactToastify.css';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import { getUser } from '../../../../../actions/shared/user/UserActions';
import SupportCustomersTable from '../../../shared/customers/shared/SupportCustomersTable';
import { getLoggedInUser } from '../../../../../actions/user/UserActions';
import SalesNavBar from '../../../../layouts/sales/SalesNavBar';
import { SUPER_ADMIN, VICE_PRESIDENT_SALES } from '../../../../../utils/departments/helpers';

class SalesCustomers extends Component {
  static propTypes = {
    getUser: PropTypes.func.isRequired,
    getLoggedInUser: PropTypes.func.isRequired,
    firstName: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    role: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  };

  componentDidMount() {
    this.props.getUser();
    this.checkDepartment();
  }

  componentWillReceiveProps(nextProps) {
    this.checkDepartment();
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  getCustomer(macid) {
    this.setState({
      isLoading: true,
    });

    const id = macid.username;

    const data = {
      macid: id,
    };

    axios({
      method: 'post',
      url: `${API_URL}/getUserauth`,
      data,

      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then((res) => {
      if (res.data.id) {
        this.setState({
          customer: res.data,
          isLoading: false,
        });
      }
    });
  }

  checkDepartment = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (
        response.data.department_id !== `${SALES}` &&
        response.data.department_id !== `${SUPER_ADMIN}` &&
        response.data.department_id !== `${VICE_PRESIDENT_SALES}`
      ) {
        this.props.history.push('/');

        sessionStorage.setItem('token', '');
        sessionStorage.clear();

        toastr.info('You are not allowed to view this page');
      }
    } catch (e) {
      // console.log(e.response);
    }
  };

  emailIsValid = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  // phoneIsValid = phone => /^[+]?(1\-|1\s|1|\d{3}\-|\d{3}\s|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/g.test(phone);

  render() {
    const { user } = this.props;
    const { firstName } = user;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <SalesNavBar currentPage="Customers" position={firstName} />

          <div className="col-lg-12 mt-5">
            <SupportCustomersTable />
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  users: state.users.users,
  role: state.auth.auth,
  firstName: state.user.user,
});

export default connect(mapStateToProps, {
  getUser,
  getLoggedInUser,
})(SalesCustomers);
