/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getEmployeesInCustomerSupport,
  scoreAgent,
} from '../../../../../actions/customer-support/quality-assurance/AgentsActions';

class ScoreAgent extends Component {
  static propTypes = {
    getEmployeesInCustomerSupport: PropTypes.func.isRequired,
    agents: PropTypes.array.isRequired,
    scoreAgent: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      employee: '',
      greet: '',
      welcome: '',
      name: '',
      detail: '',
      convenient: '',
      reconfirm: '',
      effective: '',
      previous: '',
      assistance: '',
      mile: '',
      empathise: '',
      talk: '',
      understand: '',
      procedure: '',
      root: '',
      resolve: '',
      upsell: '',
      help: '',
      enjoy: '',
      end: '',
      feedback: '',
      extension: '',
      rate: '',
      load: false,
    };

    this.onChange = this.onChange.bind(this);
    this.scoreAgent = this.scoreAgent.bind(this);
  }

  componentDidMount() {
    this.props.getEmployeesInCustomerSupport();
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  scoreAgent = (e) => {
    e.preventDefault();

    this.setState({
      load: true,
    });

    const { employee, extension, welcome, empathise, resolve, feedback } = this.state;

    // const rate = Number(welcome)  + Number(effective)  + Number(empathise) + Number(resolve) + Number(help);

    const score = {
      employee_id: employee,
      extension,
      greetings: welcome,
      knowledge: empathise,
      resolution: resolve,
      feedback,
    };

    this.props.scoreAgent(score);
  };

  submitForm = () => {
    const { employee, extension, welcome, empathise, resolve, feedback } = this.state;

    return (
      employee.length > 0 &&
      extension.length > 0 &&
      welcome.length > 0 &&
      empathise.length > 0 &&
      resolve.length > 0 &&
      feedback.length > 0
    );
  };

  render() {
    const { extension, welcome, empathise, resolve, load, feedback } = this.state;

    const { agents } = this.props;

    const score = [];
    for (let num = -1; num < 20; ) {
      score.push((num += 1));
    }

    const res = [];
    for (let num = -1; num < 60; ) {
      res.push((num += 1));
    } // so sure you'll re-write this lol
    // lmao i dont always re-write

    const resScore = res.map((num) => {
      return (
        <option key={num} value={num}>
          {num}
        </option>
      );
    });

    const scoreList = score.map((num) => {
      return (
        <option key={num} value={num}>
          {num}
        </option>
      );
    });

    const rate = Number(welcome) + Number(empathise) + Number(resolve);

    const isEnabled = this.submitForm();

    return (
      <div
        id="score-agent"
        className="modal fade in"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center" id="myModalLabel">
                Score Agent
              </h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
            </div>
            <form onSubmit={this.scoreAgent}>
              <div className="modal-body">
                <div className="col-6 d-flex mb-4">
                  <div className="mr-auto">
                    <label className="copy-font m-0 p-0">Agent Name</label>
                    <select className="form-control" onChange={this.onChange} name="employee">
                      <option value="">Agents</option>
                      {agents.map((agent) => {
                        return (
                          <option key={agent.id} value={agent.id}>
                            {agent.firstName.toUpperCase()} {agent.lastName.toUpperCase()}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div>
                    <label
                      //   label
                      className="copy-font m-0 p-0"
                    >
                      Extension
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      onChange={this.onChange}
                      name="extension"
                      value={extension}
                    />
                  </div>
                </div>
                <table className="table score-agent-table copy-font">
                  <thead>
                    <tr>
                      <th>Section</th>
                      <th>Description</th>
                      <th nowrap="no-wrap">Score (100%)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td nowrap="no-wrap">
                        <b>Section 1</b>
                      </td>
                      <td>OPENING AND CLOSING GREETINGS</td>
                      <td>
                        <select className="form-control" onChange={this.onChange} name="welcome">
                          {scoreList}
                        </select>
                      </td>
                    </tr>

                    <tr>
                      <td nowrap="no-wrap">
                        <b>Section 2</b>
                      </td>
                      <td>BUILDING RELATIONSHIP/COMMITMENT TO RESOLVING CUSTOMER’S ISSUES</td>
                      <td>
                        <select className="form-control" onChange={this.onChange} name="empathise">
                          {resScore}
                        </select>
                      </td>
                    </tr>

                    <tr>
                      <td nowrap="no-wrap">
                        <b>Section 3</b>
                      </td>
                      <td>KNOWLEDGE OF PRODUCTS AND SERVICES/OPERATIONS</td>
                      <td>
                        <select className="form-control" onChange={this.onChange} name="resolve">
                          {scoreList}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td />
                    </tr>

                    <tr>
                      <td />
                      <td className="mt-5">
                        <b>Total</b>
                      </td>
                      <td className="ml-5">
                        <b>{rate}%</b>
                      </td>
                      <td />
                    </tr>
                    <tr>
                      <th>Feedback</th>
                      <td>
                        <textarea
                          rows="8"
                          cols="50"
                          name="feedback"
                          onChange={this.onChange}
                          value={feedback}
                          placeholder="Write feedback about this rating"
                          className="form-control"
                        />
                      </td>
                    </tr>
                    {/* Submit */}
                    <tr>
                      <td />
                      {load ? (
                        <td className="text-center">
                          <button className="btn btn-green" type="submit" disabled>
                            Scoring...
                          </button>
                        </td>
                      ) : (
                        <td className="text-center">
                          <button className="btn btn-green" type="submit" disabled={!isEnabled}>
                            Score Agent
                          </button>
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  agents: state.employeesByDepartment.employeesByDepartment,
});

export default connect(
  mapStateToProps,
  {
    getEmployeesInCustomerSupport,
    scoreAgent,
  },
)(ScoreAgent);
