import React, { Component } from 'react';
import toastr from 'toastr';
import axios from 'axios';
import URLS from '../../../../../../../services/api/hotspot/HotspotAdminUrls';

export default class EditRetailer extends Component {
  constructor(props) {
    super(props);
    this.updateRetailer = this.updateRetailer.bind(this);
    this.state = {
      reload: [{}, {}],
    };
  }

  updateRetailer(e) {
    e.preventDefault();

    const lat = this.lat.value;
    const lng = this.lng.value;
    const address = this.address.value;
    const phoneNumber = this.phoneNumber.value;
    const accountid = this.accountid.value;

    axios
      .post(URLS.base_URL_LOCAL + URLS.updateRetailer, {
        lng,
        lat,
        address,
        phoneNumber,
        accountid,
      })
      .then(function(response) {
        if (response.data.status) {
          // console.log("RetailerDetails is the "+JSON.stringify(response.data.data));
          // this.setState({
          //   retailerResponse : response.data.data
          // })

          toastr.success(
            'Retailer was successfully updated, Page will automatically refresh',
          );

          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          toastr.error('Failed to update account');
        }
      })
      .catch(function(error) {
        console.log(error);
        // toastr.error("ERROR, Please check your internet connection")
      });
  }

  render() {
    return (
      <div
        id="edit-retailer"
        className="modal fade in"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
        style={{ overflow: 'auto' }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4
                className="modal-title text-center"
                id="myModalLabel"
              >
                Edit Retailer
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                ×
              </button>
            </div>
            <div className="modal-body">
              <form
                className="form-horizontal"
                onSubmit={this.updateRetailer}
              >
                <div className="form-group">
                  <div className="col-12 d-flex mb-3">
                    <div className="col-6">
                      <label className="copy-font p-0 m-0">
                        Fullname
                      </label>
                      <input
                        type="text"
                        value={this.props.fullname}
                        placeholder="Femi Adebayo"
                        className="form-control"
                      />
                    </div>

                    {/* the above div is actually for fullname input */}

                    <div className="col-6">
                      <label className="copy-font p-0 m-0">
                        Business name
                      </label>
                      <input
                        type="text"
                        value={this.props.businessName}
                        placeholder="Business name"
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-12 d-flex mb-3">
                    <div className="col-6">
                      <div className="mb-2">
                        <label className="copy-font p-0 m-0">
                          Phone number
                        </label>
                        <input
                          type="tel"
                          ref={(phoneNumber) =>
                            (this.phoneNumber = phoneNumber)
                          }
                          name="phoneNumber"
                          defaultValue={this.props.phone}
                          placeholder="Phone number"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-6">
                      <label className="copy-font p-0 m-0">
                        Address
                      </label>
                      {/* <textarea rows="4" type="text" cols="50" value= {this.props.address} name="address" placeholder="Business Address" className="form-control"/> */}
                      <input
                        rows="4"
                        ref={(address) =>
                          (this.address = address)
                        }
                        type="text"
                        cols="50"
                        defaultValue={this.props.address}
                        name="address"
                        placeholder="Business Address"
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-12 d-flex mb-3">
                    <div className="col-6">
                      <label className="copy-font p-0 m-0">
                        Email Address
                      </label>
                      <input
                        type="email"
                        value={this.props.email}
                        placeholder="Email Address"
                        className="form-control"
                      />
                    </div>

                    <div className="col-6">
                      <label className="copy-font p-0 m-0">
                        Latitude
                      </label>
                      <input
                        type="number"
                        ref={(lat) => (this.lat = lat)}
                        name="lat"
                        defaultValue={this.props.lat}
                        placeholder="Latitude"
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-12 d-flex mb-3">
                    <div className="col-6">
                      <label className="copy-font p-0 m-0">
                        Longitude
                      </label>
                      <input
                        type="number"
                        ref={(lng) => (this.lng = lng)}
                        name="lng"
                        placeholder="Longitude"
                        defaultValue={this.props.lng}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <input
                    type="hidden"
                    ref={(accountid) =>
                      (this.accountid = accountid)
                    }
                    name="accountid"
                    value={this.props.accountid}
                    placeholder="Longitude"
                    className="form-control"
                  />

                  <div className="col-12 mb-3 text-center">
                    <button
                      type="submit"
                      className="btn second-button"
                      style={{ backgroundColor: '#FFA800' }}
                    >
                      Update Retailer
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
