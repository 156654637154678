import React, { useContext } from 'react';
import RouterIpAddressTable from './RouterIpAddressTable';
import AddRouterIpAddress from './AddRouterIpAddress';
import PaginatePage from '../common/PaginatePage';
import RouterIpAddressContext from './context/RouterIpAddressContext';
// import SearchNocSheets from '../SearchNocSheets';

const RouterIpAddressBody = () => {
  const { loading, pages, paginateChange } = useContext(RouterIpAddressContext);
  return (
    <div className="container">
      <div className="row mt-5">
        {/* <SearchCnMaestro /> */}
        <div className="form-group ml-2">
          <button className="btn btn-green" type="button" data-toggle="modal" data-target="#add-access-point">
            Add Router Ip Address
          </button>
        </div>
        <AddRouterIpAddress />
        <RouterIpAddressTable />
        {!loading && <PaginatePage pages={pages} paginateChange={paginateChange} />}
      </div>
    </div>
  );
};

export default RouterIpAddressBody;
