/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import caution from '../../../../../assets/img/icons/Caution.png';
import { useRetrievals } from '../../../../../hooks/shared/retrievals/useRetrievals';

const ConfirmRetrievalItem = ({ oneItem: { id, item, customer_name } = {} }) => {
  const {
    procurementDecision,
    handleDecision,
    retrievalDecision: { approvalStatus, approvalComment },
  } = useRetrievals();

  const confirmOrder = (e) => {
    e.preventDefault();
    const data = {
      procurement_approval: approvalStatus,
      procurement_comment: approvalComment,
    };
    procurementDecision(id, data);
  };

  return (
    <div
      className="modal fade"
      id="confirm_item"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content mt-5 py-4">
          <div className="text-center">
            <img src={caution} alt="" width="60px" />
          </div>
          <div className="modal-body p-3">
            <div className="row">
              <div className="col">
                <form className="form-horizontal text-center" onSubmit={confirmOrder}>
                  <div className="col">
                    <p>
                      <h5>
                        Confirm Retrieval of <strong>{`${item}`}</strong> from
                        <br />
                        <strong>{customer_name}</strong>?
                      </h5>
                    </p>
                  </div>
                  <div className="justify-content-center">
                    <div className="" style={{ padding: '0px  170px' }}>
                      <select
                        className="form-control border border-secondary borderRadius0"
                        name="approvalStatus"
                        defaultValue={approvalStatus}
                        onChange={handleDecision}
                      >
                        <option value="approved">Approve</option>
                        <option value="rejected">Reject</option>
                      </select>
                    </div>
                    <div className="my-2" style={{ padding: '0px  70px' }}>
                      <textarea
                        placeholder="Comment"
                        className="form-control border border-secondary shadow-sm borderRadius0"
                        name="approvalComment"
                        value={approvalComment}
                        onChange={handleDecision}
                      />
                    </div>
                  </div>
                  <div className="mt-5">
                    <button type="button" className="btn btn-secondary mr-2" data-dismiss="modal">
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-green" onClick={confirmOrder}>
                      Confirm
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ConfirmRetrievalItem.propTypes = {
  oneItem: PropTypes.objectOf.isRequired,
};

export default ConfirmRetrievalItem;
