/* eslint-disable import/prefer-default-export */
import * as types from '../../../constants/types/procurement/ProcurementTypes';
import * as load from '../../../constants/types/shared/LoadingTypes';

export const tracklistCategoryReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case load.LOADING_STARTS:
      return {
        ...state,
        isLoading: true,
      };

    case load.LOADING_STOPS:
      return {
        ...state,
        isLoading: false,
      };

    case types.GET_TRACKLIST_CATEGORY:
      return {
        ...state,
        lists: payload,
        isLoading: false,
      };

    case load.FETCHING_STARTS:
      return {
        ...state,
        isFetching: true,
      };

    case load.FETCHING_STOPS:
      return {
        ...state,
        isFetching: false,
      };

    case types.GET_TRACKLIST_BY_CATEGORY:
      return {
        ...state,
        tracklistPerCategory: payload.lists,
        pagesPerCategory: payload.pages,
        countPerCategory: payload.count,
        isFetching: false,
      };

    default:
      return state;
  }
};
