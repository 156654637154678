import axios from 'axios';
import toastr from 'toastr';
import { toast } from 'react-toastify';
import { stringify } from 'qs';
import { GET_CALL_LOGS } from '../../../constants/types/shared/call-logs/CallLogsTypes';
import {
  ADD_LOG_URL,
  CONVERT_TO_USER_URL,
  GET_SALES_CALL_LOGS_URL,
  GET_SALES_PERSONNEL_CALL_LOGS_URL,
  SEARCH_LOG_URL,
} from '../../../services/api/sales/logs/LogsUrl';
import { LOGGED_IN_USER_URL } from '../../../services/api/auth/AuthUrl';
import {
  FETCHING_STARTS,
  FETCHING_STOPS,
  LOADING_STARTS,
  LOADING_STOPS,
} from '../../../constants/types/shared/LoadingTypes';
import { SUCCESS_MESSAGE } from '../../../constants/types/sales/logs/LogsTypes';
import { GET_CALL_LOGS_BY_PARAMS } from '../../../services/api/shared/call-logs/CallLogsUrl';
import { GET_LOGS_URL } from '../../../services/api/shared/logs/LogsUrls';
import * as type from '../../../constants/types/shared/logs/LogsTypes';


export const getLogs = () => async (dispatch) => {
  dispatch({
    type: FETCHING_STARTS,
    payload: true,
  });

  try {
    const response = await axios.get(`${GET_LOGS_URL}/1`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      dispatch({
        type: type.GET_LOGS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: FETCHING_STOPS,
        payload: false,
      });
    }
  } catch (e) {
    dispatch({
      type: FETCHING_STOPS,
      payload: false,
    });
  }
};

export const searchLog = (data) => async (dispatch) => {
  dispatch({
    type: FETCHING_STARTS,
    payload: true,
  });

  try {
    const response = await axios.post(`${SEARCH_LOG_URL}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      dispatch({
        type: type.GET_LOGS,
        payload: response.data,
      });
    } else {
      toastr.info('No result!');

      dispatch({
        type: FETCHING_STOPS,
        payload: false,
      });
    }
  } catch (e) {
    if (e) {
      dispatch({
        type: FETCHING_STOPS,
        payload: false,
      });
    }
  }
};

// export const getLogs = () => async dispatch => {
//
//   dispatch({
//
//     type: LOADING_STARTS,
//     payload: true
//
//   });
//
//   try {
//
//     const response = await axios.get(`${GET_LOGS_URL}`)
//   } catch (e) {
//
//   }
//
// }

export const getSalesCallLogs = () => async (dispatch) => {
  dispatch({
    type: LOADING_STARTS,
    payload: true,
  });

  const today = new Date();
  const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;

  try {
    const res = await axios.get(`${LOGGED_IN_USER_URL}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (res.data.role_id !== '3') {
      try {
        const response = await axios.get(`${GET_SALES_PERSONNEL_CALL_LOGS_URL}${date}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });

        if (response.data) {
          dispatch({
            type: GET_CALL_LOGS,
            payload: response.data,
          });
        } else {
          toastr.info('Somethign went wrong! Please try again!');
        }
      } catch (e) {
        toastr.info('Somethign went wrong! Please try again!');
      }
    } else {
      try {
        const response = await axios.get(`${GET_SALES_CALL_LOGS_URL}${date}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });

        if (response.data) {
          dispatch({
            type: GET_CALL_LOGS,
            payload: response.data,
          });
        } else {
          toastr.info('Somethign went wrong! Please try again!');
        }
      } catch (e) {
        toastr.info('Somethign went wrong! Please try again!');
      }
    }
  } catch (e) {
    toastr.info('Somethign went wrong! Please try again!');
  }
};

export const addLog = (data) => async (dispatch) => {
  dispatch({
    type: LOADING_STARTS,
    payload: true,
  });

  try {
    const response = await axios.post(`${ADD_LOG_URL}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    if (response.data.id) {
      toastr.success('Log Saved');

      dispatch({
        type: SUCCESS_MESSAGE,
        payload: 'success',
      });
    }
  } catch (e) {
    if (e.response.data.message === 'email already exist') {
      toastr.info(e.response.data.message);
      dispatch({
        type: LOADING_STOPS,
        payload: false,
      });
    } else {
      toastr.info('Something went wrong!');
      dispatch({
        type: LOADING_STOPS,
        payload: false,
      });
    }
  }
};

export const getPaginatedCallLogs = (data) => async (dispatch) => {
  // const today = new Date();
  // const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;

  try {
    const response = await axios.get(`${GET_LOGS_URL}/${data}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      dispatch({
        type: type.GET_LOGS,
        payload: response.data,
      });
    } else {
      toastr.info('Somethign went wrong! Please try again!');
    }
  } catch (e) {
    toastr.info('Somethign went wrong! Please try again!');
  }
};

export const convertToUser = (user, data) => async (dispatch) => {
  dispatch({
    type: LOADING_STARTS,
    payload: true,
  });

  try {
    const response = await axios.patch(`${CONVERT_TO_USER_URL}/${user.id}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data.editStatus === 'success') {
      toast.success(`${user.firstName} has been converted to a lead`);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  } catch (e) {
    if (e.response) {
      dispatch({
        type: LOADING_STARTS,
        payload: false,
      });
    }
  }
};

export const getLogsByParams = (data, date) => async (dispatch) => {
  dispatch({
    type: FETCHING_STARTS,
    payload: true,
  });

  const { fromStaff, toStaff } = date;

  try {
    const response = await axios.post(`${GET_CALL_LOGS_BY_PARAMS}=${fromStaff}&to=${toStaff}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      dispatch({
        type: type.GET_LOGS,
        payload: response.data,
      });
    } else {
      toastr.info('No result!');
      dispatch({
        type: FETCHING_STOPS,
        payload: false,
      });
    }
  } catch (e) {
    if (e) {
      dispatch({
        type: FETCHING_STOPS,
        payload: false,
      });
    }
  }
};

export const getCountries = () => async (dispatch) => {
  axios
    .get(`https://api.tizeti.com/api/v1/legacy/get_list_of_regions`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
    .then((res) => {
      if (res.data) {
        dispatch({
          type: type.GET_COUNTRIES,
          payload: res.data,
        });
      }
    })
    .catch(() => {
      toast.error('Unable to get cities');
    });
};

export const getCities = (data) => async (dispatch) => {
  dispatch({ type: type.GETTING_CITIES, payload: true });
  axios
    .get(`https://api.tizeti.com/api/v1/legacy/get_cities_by_state?state=${data}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        withCredentials: true,
      },
    })
    .then((res) => {
      if (res.data) {
        dispatch({
          type: type.GET_CITIES,
          payload: res.data,
        });
      }
    })
    .catch(() => {
      toast.info('Unable to get cities');
      dispatch({ type: type.GETTING_CITIES, payload: true });
    });
};

export const getStates = () => async (dispatch) => {
  try {
    const response = await axios.get(`http://locationsng-api.herokuapp.com/api/v1/states`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        withCredentials: true,
      },
    });

    if (response.data) {
      // console.log(response.data);
      dispatch({
        type: type.GET_STATES,
        payload: response.data,
      });
    } else {
      dispatch({
        type: FETCHING_STOPS,
        payload: false,
      });
    }
  } catch (e) {
    if (e) {
      dispatch({
        type: FETCHING_STOPS,
        payload: false,
      });
    }
  }
};
