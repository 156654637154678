/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import Moment from 'react-moment';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../utils/loader/loadingHelper';

const ViewTracklistCategory = ({ tracklists, isFetching }) => {
  const colSpan = '9';

  const tracklistsTable =
    tracklists && tracklists.length ? (
      tracklists.map((tracklist, i) => {
        const {
          item,
          date,
          resource_classification_id,
          quantity,
          vendor,
          cost,
          invoice_no,
          currency,
          available_quantity,
        } = tracklist;
        return (
          <tr key={i}>
            <td>{i + 1}</td>
            <td>{item}</td>
            <td>
              {resource_classification_id === 1 ? 'Inventory' : resource_classification_id === 2 ? 'Assets' : 'Expense'}
            </td>
            <td>{vendor}</td>
            <td className="text-center">{quantity}</td>
            <td className="text-center">{available_quantity}</td>
            <td>{invoice_no}</td>
            <td>
              {currency === 'USD' ? '$ ' : '₦ '}
              {cost}
            </td>
            <td>
              <Moment format="DD-MM-YYYY">{date}</Moment>
            </td>
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan={colSpan} text="No Tracklist" />
    );

  return (
    <div className="modal" id="perCategory">
      <div className="modal-dialog modal-lg large-modal mt-5" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="text-center">Tracklist Per Item</h3>
            <button type="button" className="close p-3" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div>
              <div className="table-responsive content">
                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Item Name</th>
                      <th>Class</th>
                      <th>Company/Vendor</th>
                      <th>Quantity</th>
                      <th>Remainder</th>
                      <th>Invoice No.</th>
                      <th>Cost</th>
                      <th>Date Received</th>
                    </tr>
                  </thead>
                  <tbody>{isFetching ? <TizetiGIFLogoTable colSpan={colSpan} /> : tracklistsTable}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewTracklistCategory;
