/* eslint-disable import/prefer-default-export */
import * as types from '../../../constants/types/express-wifi/ExpressWifiTypes';
import * as load from '../../../constants/types/shared/LoadingTypes';

export const XWFRetailersReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case load.LOADING_STARTS:
      return {
        ...state,
        isLoading: true,
      };

    case load.LOADING_STOPS:
      return {
        ...state,
        isLoading: false,
      };

    case types.GET_EXPRESSWIFI_RETAILERS:
      return {
        ...state,
        retailers: payload.retailers,
        count: payload.count,
        pages: payload.pages,
        isLoading: false,
      };

    case types.SEARCH_EXPRESSWIFI_RETAILERS:
      return {
        ...state,
        retailers: payload.retailers,
        count: payload.count,
        pages: payload.pages,
        searchRetailersToggle: true,
        isLoading: false,
      };

    case load.FETCHING_STARTS:
      return {
        ...state,
        isFetching: true,
      };

    case load.FETCHING_STOPS:
      return {
        ...state,
        isFetching: false,
      };

    case types.GET_XWF_RETAILER_COMMENTS:
      return {
        ...state,
        retailerComments: payload.retailerComments,
        count: payload.count,
        pages: payload.pages,
        isFetching: false,
      };

    default:
      return state;
  }
};
