/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import erase from '../../../../../assets/img/icons/delete.png';
import view from '../../../../../assets/img/icons/view.png';
import ViewRetrieval from '../../../shared/retrievals/ViewRetrieval';
import ConfirmDeleteRetrieval from '../../../shared/device-retrieval/ConfirmDeleteRetrieval';
import CleanRadio from '../../procurement/customers/CleanRadio';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../../utils/loader/loadingHelper';

const AccountsRetrievalsTable = ({ lists, load }) => {
  const colSpan = '10';
  const { profile } = useUser();
  const { officialEmail } = profile;

  const [one, setOne] = useState({});
  const getItem = (list) => setOne(list);

  const tableData =
    lists.length && lists ? (
      lists.map((list, i) => {
        const {
          item,
          radio_mac_id,
          router_mac_id,
          customer_name,
          customer_email,
          comment,
          EmployeeAuth,
          customer_number,
        } = list;
        return (
          <tr className="text-nowrap" key={i}>
            <td>{i + 1}</td>
            <td>{item}</td>
            <td>{radio_mac_id}</td>
            <td>{router_mac_id}</td>
            <td>{customer_name}</td>
            <td>{customer_email}</td>
            <td>{customer_number || 'NIL'}</td>
            <td>
              <div
                className={`badge badge-${
                  comment && comment.toLowerCase() === 'good' ? 'success' : 'danger'
                } w-100 text-uppercase`}
              >
                {comment}
              </div>
            </td>
            <td>
              {EmployeeAuth ? `${EmployeeAuth.employees[0].firstName} ${EmployeeAuth.employees[0].lastName}` : 'N/A'}
            </td>
            <td>
              <div className="text-center">
                <button
                  type="submit"
                  className="mr-2 noBorder"
                  data-toggle="modal"
                  data-placement="right"
                  data-target="#view_item"
                  onClick={() => getItem(list)}
                >
                  <span className="view">
                    <img src={view} data-toggle="tooltip" alt="" data-placement="top" title="View" />
                  </span>
                </button>
                {Number(sessionStorage.getItem('roleId')) > 2 && (
                  <button
                    type="button"
                    className="mr-2 noBorder"
                    data-target="#confirm_delete"
                    onClick={() => getItem(list)}
                    data-toggle="modal"
                  >
                    <span className="view">
                      <img src={erase} alt="" data-toggle="tooltip" data-placement="top" title="Delete" />
                    </span>
                  </button>
                )}
                {officialEmail === 'kikelomo.azeez@tizeti.com' ||
                officialEmail === 'hakeem.balogun@tizeti.com' ||
                officialEmail === 'amanosi.okhakhu@tizeti.com' ||
                officialEmail === 'cfo@tizeti.com' ? (
                  <button
                    type="submit"
                    className="btn btn-secondary mr-2"
                    data-target="#clean-radio"
                    onClick={() => getItem(list)}
                    data-toggle="modal"
                  >
                    Clean Radio
                  </button>
                ) : null}
                <CleanRadio one={one} />
              </div>
              <ViewRetrieval oneItem={one} />
              <ConfirmDeleteRetrieval one={one} />
            </td>
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan={colSpan} text="There are no Retrievals" />
    );

  return (
    <div className="card x-panel mt-2 px-0">
      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Item Name</th>
              <th className="text-nowrap">Radio Mac ID</th>
              <th>Router Mac ID</th>
              <th>Customer Name</th>
              <th>Email</th>
              <th>Customer Number</th>
              <th>Condition</th>
              <th>Retrieved By</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{load ? <TizetiGIFLogoTable colSpan={colSpan} /> : tableData}</tbody>
        </table>
      </div>
    </div>
  );
};

AccountsRetrievalsTable.propTypes = {
  load: PropTypes.bool.isRequired,
  lists: PropTypes.objectOf.isRequired,
};
export default AccountsRetrievalsTable;
