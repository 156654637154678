import * as types from '../../../constants/types/shared/requisition/RequisitionTypes';
import * as load from '../../../constants/types/shared/LoadingTypes';

const intialState = {
  banks: [],
  load: false,
  filter: false,
  requisitions: [],
  isSuccess: false,
  fetch: false,
  form: false,
  ceoAndCooRequisitions: [],
  isLoading: false,
};

export const RequsitionReducer = (state = intialState, action) => {
  const { payload, type } = action;

  // account_approval: "queued"
  // amount_in_digits: "12222222222"
  // amount_in_words: "1222"
  // ceo_or_coo_approval: "queued"
  // created_at: "2019-10-19T18:18:56.090Z"
  // department_id: "aaf924bf-e16f-4ddc-843b-be5f91c8b0d2"
  // employee_auth_id: "6dc7f2ab-de54-4fac-867e-859a71d06007"
  // id: 15
  // imageUpload: "https://wificallrecord.s3.amazonaws.com/tizetios/94320347978684783569.png"
  // in_favour_of: "Sales Manager"
  // manager_approval: "queued"
  // purpose: "22"
  // requires_c_level_approval: false
  // requires_ceo_or_coo_approval: true
  // updated_at: "2019-10-19T18:18:56.090Z"

  switch (type) {
    case types.GET_BANKS:
      return {
        ...state,
        banks: payload,
      };

    case types.REQUISITION_FILLED:
      // console.log(...state.requisitions);
      return {
        ...state,
        isLoading: false,
        // requisitions:state.requisitions.unshift(payload)
        // requisitions: [...state.requisitions, payload],
        // this.props.posts.unshift(nextProps.newPost);
        // requisitions: state.requisitions.unshift(payload)

        // campaigns: [action.payload.response, ...state.campaigns],

        // requisitions: state.requisitions.unshift(payload)
      };

    // return {
    //   ...state,
    //   isLoading: false,
    //   // requisitions:state.requisitions.unshift(payload)
    //   requisitions: [...state.requisitions, payload],
    //   // this.props.posts.unshift(nextProps.newPost);
    //   // requisitions: state.requisitions.unshift(payload)
    //
    //   // campaigns: [action.payload.response, ...state.campaigns],
    //
    //   // requisitions: state.requisitions.unshift(payload)
    // };

    case load.LOADING_STARTS:
      return {
        ...state,
        load: true,
        isLoading: true,
      };

    case types.LOADING_REQUISITIONS_STARTS:
      return {
        ...state,
        filter: payload,
        fetch: payload,
      };

    case types.LOADING_REQUISITIONS_STOPS:
      return {
        ...state,
        filter: payload,
        fetch: payload,
      };

    case types.GET_REQUISITIONS:
      return {
        ...state,
        requisitions: payload,
        fetch: false,
        ceoAndCooRequisitions: payload,
        isLoading: false,
      };

    case load.LOADING_STOPS:
      return {
        ...state,
        load: false,
        isLoading: false,
      };

    case types.FILTER_REQUISITIONS:
      return {
        ...state,
        requisitions: payload,
        filter: false,
      };

    case types.START_APPROVE_OR_DISAPPROVE:
      return {
        ...state,
        form: payload,
      };

    case types.STOP_APPROVE_OR_DISAPPROVE:
      return {
        ...state,
        form: payload,
      };

    case types.APPROVE_OR_DISAPPROVE_SUCCESS:
      return {
        ...state,
        form: false,
        ceoAndCooRequisitions: state.ceoAndCooRequisitions.filter((req) => req.id !== payload),
      };
    case types.GET_APPROVED_REQUISITIONS:
      return {
        ...state,
        approved: payload.list,
        approvedPages: payload.pages,
        approvedLoading: false,
        totalApproved: payload.count,
      };
    case 'REMOVE_PAID':

      // const
      // console.log(state.approved.filter((ap) => ap.id !== payload))
      // const newApproved = [...state.approved.filter((ap) => ap.id !== payload))]
      // const newApproved2 = [state.approved.filter((ap) => ap.id !== payload))]
      // console.log(newApproved);
      // console.log(newApproved2);
      // const newApproved = ...state
      return {
        ...state,
        approved: [...state.approved.filter((ap) => ap.id !== payload)],
      };

    case types.LOADING_APPROVED_REQUISITIONS:
      return {
        ...state,

        approvedLoading: true,
      };
    case types.LOADING_APPROVED_REQUISITIONS_STOPS:
      return {
        ...state,
        approvedLoading: false,
      };
    // case GET_APP

    // case GET_REQUISITIONS:
    //   return {
    //
    //     ...state,
    //     payload:
    //   }

    default:
      return state;
  }
};

export default RequsitionReducer;
