import axios from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify';
import toastr from 'toastr';

const useShared = (url, successMessage, failureMessage) => {
  const [loading, setLoading] = useState(false);
  const [sms, setSms] = useState('');
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('');
  const [notes, setNotes] = useState('');
  const [details, setDetails] = useState('');
  const [status, setStatus] = useState('');
  const onSubmit = (params) => {
    setLoading(true);
    axios
      .post(`${url}`, params)
      .then((response) => {
        // if (response.data.status === false && response.data.message === 'already sent this message') {
        //   toast.info('already sent this message');
        // } else {
        if (response.data.status === false) {
          toast.info(response.data.message);
          setLoading(false);
        } else {
          toastr.success(successMessage);
          setLoading(false);
          setMessage('');
          setSubject('');
          setNotes('');
          setDetails('');
          setStatus('');
          setSms('');
        }
        // }
      })
      .catch(() => {
        setLoading(false);
        toast.error(failureMessage);
      });
  };

  return {
    sms,
    setSms,
    onSubmit,
    loading,
    subject,
    setMessage,
    message,
    setSubject,
    notes,
    setNotes,
    details,
    setDetails,
    status,
    setStatus,
  };
};
export default useShared;
