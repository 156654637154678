/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect } from 'react';
import qs from 'qs';
import ExpressWifiAmbassadorsTable from './ExpressWifiActiveAmbassadorsTable';
import useExpressWifiAmbassadors from '../../../../../hooks/express-wifi/useExpressWifiAmbassadors';

const ExpressWifiAmbassadorsBody = () => {
  const { getActiveAmbassadors, items, isLoading } = useExpressWifiAmbassadors();

  const data = qs.stringify({
    action: 'get_list_of_active_ambassadors',
    type: 'active',
  });

  useEffect(() => {
    getActiveAmbassadors(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mt-5 p-3">
      <div className="container-fluid pt-4">
        <div className="row">
          <ExpressWifiAmbassadorsTable items={items} isLoading={isLoading} />
        </div>
      </div>
    </div>
  );
};

export default ExpressWifiAmbassadorsBody;
