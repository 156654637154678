import React from 'react';
import { useUser } from '../../../../hooks/shared/user/useUser';
import BusinessDevelopmentNavBar from '../../../layouts/business-development/BusinessDevelopmentNavBar';
import ViewUserProfile from '../../shared/staff/ViewUserProfile';

import BusinessDevelopmentOverviewBody from './BusinessDevelopmentOverviewBody';
import TotalActiveAnalytics from './overview/TotalActiveAnalytics';
import TotalExpiredAnalytics from './overview/TotalExpiredAnalytics';

const BusinessDevelopmentOverview = () => {
  const { user, profile } = useUser();
  // console.log(profile, user);
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <div className="d-flex col-lg-12 p-2 top-header">
          <BusinessDevelopmentNavBar currentPage="Overview" profileImage={profile.firstName} />
        </div>

        <div className="col-12 after-summary">
    
          <div className="row mt-5">
            <div className="p-1 mt-5 col-lg-12 col-md-9 col-sm-12">
              <h6 className="m-4 ml-4"> Statistics </h6>
            </div>

            <TotalExpiredAnalytics />
            {/* <TotalExpiredRevenue /> */}
            <TotalActiveAnalytics />
          </div>
          <div className="row mt-5">
            {/* <TotalActiveRevenue />
        <TotalChurnedAnalytics />
        <TotalChurnedRevenue /> */}
          </div>
          <div className="ml-2">
            <a href="https://legacy.tizeti.com/vtigercrm/index.php" target="_self">
              <button type="button" className="btn text-white ml-3" style={{ background: '#1E8E8B' }}>
                Vtigercrm
              </button>
            </a>
          </div>
        </div>
      </main>
    </div>
  );
};

export default BusinessDevelopmentOverview;
