import React from 'react';
import wifiLogo from '../../../../assets/img/wifi-icon/wifiLogo.png';
import book from '../../../../assets/img/wifi-icon/book.png';
import bro from '../../../../assets/img/wifi-icon/bro.png';
import plant from '../../../../assets/img/wifi-icon/Plant.png';
import stars from '../../../../assets/img/wifi-icon/Stars.png';

const Experience = () => {
  return (
    <div className="experience_page">
      <div className="experience__page--logo shadow py-2 px-5 bg-white">
        <img src={wifiLogo} alt="" />
      </div>
      <div className="experience__page--container--bgImage">
        <div className="col-lg-9 my-5 mx-auto bg-white p-0">
          <p className="px-4 py-2">Rate the experience</p>
          <div className="image__carriers">
            <div className="col-lg-12 d-flex">
              <div className="d-flex stars">
                <img src={stars} alt="" />
                <div>
                  <p>Feedbacks are important to us, so please rate your experience and the service. </p>
                  <img src={plant} alt="" />
                </div>
              </div>

              <div className="book">
                <img src={book} alt="" />
              </div>
              <div className="bro-image">
                <img src={bro} alt="" />
              </div>
            </div>
          </div>

          <div className="emojis">
            <div className="col-lg-8 mx-auto my-4">
              <div className="groupOne">
                <p className="my-3"> 1. How would you rate the engineer on site? </p>
                <div className="emoji--container d-flex">
                  <div>
                    <span>&#128554;</span>
                    <p>Terrible</p>
                  </div>
                  <div>
                    <span>&#128532;</span>
                    <p>Bad</p>
                  </div>
                  <div>
                    <span>&#128578;</span>
                    <p>Average</p>
                  </div>
                  <div>
                    <span>&#129303;</span>
                    <p>Very good</p>
                  </div>
                  <div>
                    <span>&#129321;</span>
                    <p>Excellent</p>
                  </div>
                </div>
              </div>

              <div className="groupOne">
                <p className="my-3">2. Kindly share your experience concerning the installation process.</p>
                <form>
                  <textarea className="p-2 experience-text" placeholder="Please share your experience" />
                  <div className="text-center my-4">
                    <button className="btn btn-color">SUBMIT</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;
