export const LOADING_STARTS = 'LOADING_STARTS';
export const LOADING_STOPS = 'LOADING_STOPS';
export const FETCHING_STARTS = 'FETCHING_STARTS';
export const FETCHING_STOPS = 'FETCHING_STOPS';
export const CHECKING_MAC_STARTS = 'CHECKING_MAC_STARTS';
export const SCHEDULE_SUCCESS = 'SCHEDULE_SUCCESS';
export const CHECKING_MAC_STOPS = 'CHECKING_MAC_STOPS';
export const FETCHING_DIMENSION_STARTS = 'FETCHING_DIMENSION_STARTS';
export const FETCHING_DIMENSION_STOPS = 'FETCHING_DIMENSION_STOPS';
export const FETCHING_DIMENSION = 'FETCHING_DIMENSION';
export const START_ACTIVATING_ACCOUNT = 'START_ACTIVATING_ACCOUNT';
export const STOP_ACTIVATING_ACCOUNT = 'STOP_ACTIVATING_ACCOUNT';
export const REFETCH = 'REFETCH';
export const ROUTING_STARTS = 'ROUTING_STARTS';
export const ROUTING_STOPS = 'ROUTING_STOPS';
export const FETCH_SALES_TYPE_STARTS = 'FETCH_SALES_TYPE_STARTS';
export const FETCH_SALES_TYPE_STOPS = 'FETCH_SALES_TYPE_STOPS';
