/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/require-default-props */
import React, { useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import RequisitionContext from '../../../../../context/shared/requisitions/RequsitionContext';
import ManagersRequisitionBody from './ManagersRequisitionBody';

const ManagersRequisition = () => {
  const { isFetching, handlePageClick, totalPages } = useContext(RequisitionContext);

  return (
    <div className="card x-panel logs">
      <div className="table-responsive content">
        <table className="table copy-font">
          <thead>
            <tr>
              <th>#</th>
              <th>Amount In Digits</th>
              <th className="text-nowrap">Amount In Words</th>
              <th>Accounts Approval Status </th>
              <th>Manager Approval Status </th>
              <th>CEO/COO LEVEL Approval Status </th>
              <th className="text-nowrap">In Favor Of</th>
              <th>Requires C Level Approval?</th>
              <th>Requires CEO/COO Level Approval?</th>
              <th className="text-nowrap">Created At</th>
              <th>Purpose</th>
              <th>Approve</th>
              <th>Reject</th>
              <th>View Uploaded File</th>
              <th>Status</th>
              <th>View Details</th>
            </tr>
          </thead>

          {isFetching ? (
            <tbody>
              <tr>
                <td>
                  <small>loading...</small>
                </td>
              </tr>
            </tbody>
          ) : (
            <ManagersRequisitionBody />
          )}
        </table>
      </div>
      {totalPages > 1 ? (
        <ReactPaginate
          previousLabel="previous"
          nextLabel="next"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName="pagination"
          subContainerClassName="pages pagination"
          activeClassName="active"
        />
      ) : null}
      <ToastContainer />
    </div>
  );
};

export default ManagersRequisition;
