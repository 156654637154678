import React from 'react';
import PropTypes from 'prop-types';

const TableBody = ({ data }) => {
  const displayData = () =>
    data && data.length
      ? data.map((customer, i) => (
          <tr key={customer.id}>
            <td>{i + 1}</td>
            <td>{customer.user.firstName}</td>
            <td>{customer.user.lastName}</td>
            <td>{customer.user.email}</td>
            <td>{customer.user.phoneNumber}</td>
            <td>{customer.user.address}</td>
            <td>{customer.payment_reference}</td>
          </tr>
        ))
      : 'no scheduled!';
  return <tbody>{displayData()}</tbody>;
};

TableBody.propTypes = {
  // eslint-disable-next-line react/require-default-props
  data: PropTypes.shape(),
};
export default TableBody;
