import React from 'react';
import { useUser } from '../../../../hooks/shared/user/useUser';
import PartnershipNavBar from '../../../layouts/partnership/PartnershipNavBar';


const PartnershipOverview = () => {
  const { user, profile } = useUser();

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <div className="d-flex col-lg-12 p-2 top-header">
          <PartnershipNavBar currentPage="Overview" profileImage={profile.firstName} />
        </div>

        <div className="col-12 after-summary">
          <div className="row mt-5">
            {/* <div className="p-1 mt-5 col-lg-12 col-md-9 col-sm-12">
            
            </div> */}
            Coming Soon
          </div>
        </div>
      </main>
    </div>
  );
};

export default PartnershipOverview;
