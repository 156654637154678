/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RetentionTable from './RetentionTable';
import { getAboutToExpire } from '../../../../actions/shared/retention/RetentionActions';

class Retention extends Component {
  constructor(props) {
    super(props);

    this.state = {
      country: '',
      day: '',
    };
  }

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  onKeyPress = (e) => {
    e.preventDefault();
    // if (e.which === 13) {
    const { country, day } = this.state;
    const data = {
      days: day,
      country,
    };
    this.props.getAboutToExpire(data);
  };

  // selectCountry = (e) => {
  //   const { name, value } = e.target;
  //   this.setState({ country: value });
  // };
  //
  // enterDay = (e) => {
  //   const { name, value } = e.target;
  //   this.setState({ day: value });
  // };

  submitForm = () => {
    const { day, country } = this.state;
    return day.length > 0 && country.length > 0;
  };

  render() {
    const { expired, loading } = this.props;
    const { day } = this.state;
    return (
      <div className="after-summary col-lg-12">
        <div className="row">
          <div className="col-lg-12 col-md-12 mt-2 mb-3 d-flex install">
            <form onSubmit={this.onKeyPress} className="form-inline ml-3">
              <div className="form-group">
                <select name="country" onChange={this.onChange} className="form-control">
                  <option value="">Select Country</option>
                  <option value="ng">Nigeria</option>
                  <option value="gh">Ghana</option>
                </select>
              </div>
              <div className="form-inline inner-addon left-addon w-75 mr-auto">
                <input
                  className="form-control mr-sm-2 empty mt-1"
                  id="iconified"
                  type="text"
                  placeholder="Enter the number of days to search by"
                  name="day"
                  onChange={this.onChange}
                  value={day}
                />
                <span className="fa fa-search" />
              </div>
              <div>
                <button type="submit" disabled={!this.submitForm()} className="btn btn-green">
                  Submit
                </button>
              </div>
            </form>
          </div>

          <div className="card x-panel">
            <div className="table-responsive content">
              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Customer ID</th>
                    <th>Username</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Expiry Date</th>
                  </tr>
                </thead>
                <RetentionTable load={loading} customers={expired} />
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  expired: state.retention.about,
  loading: state.retention.loading,
});

Retention.propTypes = {
  getAboutToExpire: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  loading: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  expired: PropTypes.shape(),
};

export default connect(
  mapStateToProps,
  { getAboutToExpire },
)(Retention);
