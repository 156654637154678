import React, { Component } from 'react';
import red from '../../../../../assets/img/icons/Red_sign.png';

class NetPromoterScoreDetails extends Component {
  render() {
    return (
      <div
        id="nps-details"
        className="modal fade in"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title ml-5" id="myModalLabel">
                Response Details
              </h4>
              <button type="button" className="close custom-close" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
            </div>
            <div className="modal-body detail" style={{ padding: '0', textAlign: 'justify' }}>
              <div className="row">
                <div className="col-lg-12 d-flex">
                  <div className="col-lg-6 detail-label1">
                    <label id="fn">Email</label>
                    <label id="ln">Survey</label>
                    <label id="email">Response</label>
                  </div>
                  <div className="col-lg-6 detail-result1">
                    <p className="mt-4 mb-2">doewills@gmail.com</p>
                    <p className="mb-1">Wificall Web</p>
                    <p className="mb-1">
                      Can we be able to get a feature where we can easily know the most recently scheduled customer
                    </p>
                  </div>
                </div>

                <div className="col-lg-12 d-flex">
                  <div className="col-lg-6 detail-label2">
                    <label>Date</label>
                  </div>
                  <div className="col-lg-6 detail-result2">
                    <p className="mt-4 mb-2">June 12th, 2019</p>
                  </div>
                </div>

                <div className="col-lg-12 d-flex">
                  <div className="col-lg-6 detail-label4">
                    <label>Type</label>
                  </div>
                  <div className="col-lg-6 detail-result4">
                    <div className="mt-4">
                      <img src={red} alt="connected-icon" />
                      <span className="ml-2">Detractor</span>
                    </div>

                    {/* <div className="mt-4"> */}
                    {/* <img src="https://res.cloudinary.com/teewhy/image/upload/v1548342137/green_sign.png" alt="connected-icon"/> */}
                    {/* <span className="ml-2">Promoter</span> */}
                    {/* </div> */}

                    {/* <div className="mt-4"> */}
                    {/* <img src="https://res.cloudinary.com/teewhy/image/upload/v1551362876/connec.png" alt="connected-icon"/> */}
                    {/* <span className="ml-2">Passive</span> */}
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NetPromoterScoreDetails;
