import React, { useContext } from 'react';
// import SearchNocSheets from '../SearchNocSheets';
import FiberPptpTable from './FiberPptpTable';
import AddFiberPptp from './AddFiberPptp';
import PaginatePage from '../common/PaginatePage';
import FiberPptpContext from './context/FiberPptpContext';

const FiberPptpBody = () => {
  const { loading, pages, paginateChange } = useContext(FiberPptpContext);
  return (
    <div className="container">
      <div className="row mt-5">
        {/* <SearchCnMaestro /> */}
        <div className="form-group ml-2">
          <button className="btn btn-green" type="button" data-toggle="modal" data-target="#add-access-point">
            Add Fiber Ptp
          </button>
        </div>
        <AddFiberPptp />
        <FiberPptpTable />
        {!loading && <PaginatePage pages={pages} paginateChange={paginateChange} />}
      </div>
    </div>
  );
};

export default FiberPptpBody;
