/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import Moment from 'react-moment';

const AccountReconciliationTable = ({ lists }) => {
  const tableData =
    lists && lists.length ? (
      lists.map((list, i) => {
        const {
          mac_id,
          created_at,
          tizetiOS_confirmation,
          tag_no,
          user: { firstName, lastName, email },
        } = list;
        return (
          <tr className="text-nowrap" key={i}>
            <td>{i + 1}</td>
            <td className="text-nowrap">{mac_id}</td>
            <td className="text-nowrap">{`${firstName} ${lastName}` || 'N/A'}</td>
            <td className="text-nowrap">{email || 'N/A'}</td>
            <td className="text-nowrap">{tag_no || 'N/A'}</td>
            <td>
              <Moment format="DD-MM-YYYY" date={created_at} />
            </td>
            <td>
              <div className={`badge badge-${tizetiOS_confirmation === 'true' ? 'success' : 'danger'} text-uppercase`}>
                {tizetiOS_confirmation || 'FALSE'}
              </div>
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="6" className="text-center">
          There are no installations
        </td>
      </tr>
    );

  return tableData;
};

export default AccountReconciliationTable;
