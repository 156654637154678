import React from 'react';
import AssetTeamNavBar from '../../../../layouts/asset-team/AssetTeamNavBar';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import InstalledCountryBody from '../../../shared/installations/InstalledCountryBody';

const AssetTeamInstalledCountry = () => {
  const { profile } = useUser();
  const { firstName } = profile;

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <AssetTeamNavBar currentPage="Installations Per Country" position={firstName} />

        <InstalledCountryBody profile={profile} />
      </main>
    </div>
  );
};

export default AssetTeamInstalledCountry;
