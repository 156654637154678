/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import '../../../../styles/style.css';
import '../../../../styles/sales_overview.css';
import axios from 'axios';
import toastr from 'toastr';
import TotalInventories from './inventories/TotalInventories';
import Profile from '../../../../assets/img/icons/Profile.png';
import devices from '../../../../assets/img/icons/TotalDevices.png';
import { API_URL } from '../../../../utils/config';
import ViewUserProfile from '../../shared/staff/ViewUserProfile';
import ProcurementNavBar from '../../../layouts/procurement/ProcurementNavBar';
import { PROCUREMENT, SUPER_ADMIN } from '../../../../utils/departments/helpers';

class ProcurementOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      redirect: false,
      move: false,
      out: false,
      role: '',
    };
  }

  componentWillMount() {
    this.fetchLoggedOnUser();
    this.getUserDetails();
  }

  componentWillReceiveProps() {
    this.fetchLoggedOnUser();
  }

  getUserDetails() {
    const token = sessionStorage.getItem('token');

    axios({
      method: 'get',
      url: `${API_URL}/employee/loggedOnEmployee`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        this.setState({
          user: data.data,
        });
      })
      .catch(() => {});
  }

  fetchLoggedOnUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employeeReg/logged`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        if (data.data) {
          this.setState({
            role: data.data.role_id,
          });
        }
        if (data.data.department_id !== `${PROCUREMENT}` && data.data.department_id !== `${SUPER_ADMIN}`) {
          sessionStorage.setItem('data', '');
          sessionStorage.clear();
          this.setState({
            out: true,
          });

          toastr.info('You are not allowed to view this page');

          // const { from } = this.props.location.state || { from: { pathname: '/' } };
          // console.log(this.props.history.goBack);
          // window.previousLocation = this.props.location;
          // console.log(window.previousLocation);
        }
      })
      .catch(() => {});
  }

  render() {
    const { role } = this.state;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-11 p-0">
          <ProcurementNavBar currentPage="Overview" role_id={role} profileImage={Profile} />

          <div className="container mt-5">
            <ViewUserProfile />
            <div className="row mt-5">
              <div className="p-1 mt-5 col-lg-12 col-md-12 col-sm-12">
                <h6 className="mb-3 ml-3 copy-font">Procurements Summary</h6>
              </div>

              <div className="col-lg col-md col-sm">
                <div className="boxes">
                  <div className="text-center">
                    <div className="text-center pt-3">
                      <img src={devices} alt="" />
                    </div>
                    <TotalInventories />
                    <h3 style={{ color: '#f37705' }}>Total Inventories</h3>
                  </div>
                </div>
              </div>

              <div className="col-lg-10">
                <div className="card-group mt-1">
                  <div className="card o-income">
                    <div className="card-body">
                      <div className="d-flex m-b-30 no-block ml-2">
                        <h4 className="card-title m-b-0 align-self-center">Total Purchases</h4>
                      </div>

                      <div className="d-flex col-lg-12 graph">
                        <div className="col-lg-6">
                          <p className="copy-font">Procurement ROI</p>
                        </div>
                        <div className="colors d-flex col-lg-7">
                          <p className="color" />
                          <p className="copy-font">Radio</p>
                          <p
                            className="color-dif"
                            style={{
                              backgroundColor: '#14B0B0',
                            }}
                          />
                          <p className="copy-font"> Router</p>
                        </div>
                      </div>

                      <div id="sales" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default ProcurementOverview;
