import React from 'react';
import BusinessDevelopmentNavBar from '../../../../layouts/business-development/BusinessDevelopmentNavBar';
import BusinessDevelopmentLogsBody from './BusinessDevelopmentLogsBody';

const BusinessDevelopmentLogs = () => {
  return (
    <div className="s-layout">
    <main className="s-layout__content col-lg-10 p-0">
        <BusinessDevelopmentNavBar currentPage="Logs" />
        <div className="mt-5 pt-5 px-3">
        <BusinessDevelopmentLogsBody />
        </div>
      </main>
    </div>
  );
};

export default BusinessDevelopmentLogs;
