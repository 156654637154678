import React from 'react';
import useListOfBasestation from '../hooks/useListOfBasestation';
import AppContext from '../../../../../../../context/shared/AppContext';
import { LIST_OF_BASESTATION_URL } from '../../../../../../../services/api/shared/BasestationsUrl';

const ListOfBasestationsProvider = ({ children }) => {
  const { data, loading, paginateChange, loadingForm, onChange, formValue, onSubmit, pages } = useListOfBasestation(
    `${LIST_OF_BASESTATION_URL}`,
  );

  return (
    <AppContext.Provider
      value={{
        data,
        loading,
        paginateChange,
        loadingForm,
        onChange,
        formValue,
        onSubmit,
        pages,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export default ListOfBasestationsProvider;
