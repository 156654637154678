import React from 'react';
import { useUser } from '../../../../hooks/shared/user/useUser';
import { ACCOUNTS, ADMIN_DEPARTMENT, PROCUREMENT } from '../../../../utils/departments/helpers';
import AccountsNavBar from '../../../layouts/accounts/AccountsNavBar';
import AdminDeptNavBar from '../../../layouts/admin-department/AdminDeptNavBar';
import AdminEmployeeBody from './AdminEmployeeBody';
import ProcurementNavBar from '../../../layouts/procurement/ProcurementNavBar';

const AdminEmployee = () => {
  const departmentId = sessionStorage.getItem('departmentId');
  // console.log(departmentId);
  const firstName = sessionStorage.getItem('firstName');
  return (
    <div>
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <>
          {departmentId === `${ADMIN_DEPARTMENT}` && (   <AdminDeptNavBar currentPage="Employee" position={firstName} />)}
          {departmentId === `${ACCOUNTS}` && (  <AccountsNavBar currentPage="Employee"  position={firstName} />  )} 
          {departmentId == `${PROCUREMENT}` && (  <ProcurementNavBar currentPage="Employee" position={firstName} /> )}
        </>
          <AdminEmployeeBody />
      </main>
    </div>
    </div>
  );
};
export default AdminEmployee;
