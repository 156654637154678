/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useInstallationsSummary } from '../../../../../hooks/field-support/installations-summary/useInstallationsSummary';
import { months } from '../../../../../utils/field-support/installations/helpers';

const AddInstallationTarget = () => {
  const { isLoading, addTarget } = useInstallationsSummary();

  const thisMonth = new Date().getMonth();
  const thisYear = new Date().getFullYear();

  const locationObject = {
    state: '',
    state_target: '',
    month: thisMonth + 1,
    year: thisYear,
  };
  const [location, setLocation] = useState(locationObject);
  const { state, state_target } = location;

  const handleTarget = (e) => {
    const { name, value } = e.target;
    setLocation({ ...location, [name]: value });
  };

  const onSubmitTarget = (e) => {
    e.preventDefault();
    addTarget(location);
  };

  const submitForm = () => state.length > 0 && state_target.length > 0;

  return (
    <div id="add_target" className="modal fade" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header shadow">
            <h3 className="modal-title text-center">
              Add Installation Target for {months[thisMonth]},{thisYear}
            </h3>
            <button type="button" className="btn btn-close" data-dismiss="modal">
              x
            </button>
          </div>
          <div className="modal-body detail mb-4 p-0">
            <form onSubmit={onSubmitTarget} className="col-md-12">
              <div className="d-flex">
                <div className="p-2 pl-3 form-group col-md-6">
                  <label className="m-0 p-0 copy-font" htmlFor="state">
                    State
                  </label>
                  <select
                    className="form-control border border-info py-2 borderRadius0"
                    id="state"
                    name="state"
                    onChange={handleTarget}
                  >
                    <option>--State--</option>
                    <option value="lagos">Lagos</option>
                    <option value="ogun">Ogun</option>
                    <option value="rivers">Rivers</option>
                    <option value="edo">Edo</option>
                    <option value="delta">Delta</option>
                    <option value="oyo">Oyo</option>
                    <option value="enugu">Enugu</option>
                    <option value="imo">Imo</option>
                    <option value="cross-river">Cross River</option>
                    <option value="anambra">Anambra</option>
                    <option value="abia">Abia</option>
                    <option value="abuja">Abuja</option>
                    <option value="akwaibom">Akwa Ibom</option>
                    <option value="kano">Kano</option>
                    <option value="kaduna">Kaduna</option>
                  </select>
                </div>
                <div className="p-2 pl-3 form-group col-md-6">
                  <label className="m-0 p-0 copy-font" htmlFor="state_target">
                    Target
                  </label>
                  <input
                    type="text"
                    className="form-control border border-info py-2"
                    id="state_target"
                    name="state_target"
                    value={state_target}
                    placeholder="Target"
                    onChange={handleTarget}
                  />
                </div>
              </div>

              <div className="text-center mt-3">
                {isLoading ? (
                  <button type="button" className="btn btn-secondary py-2 px-5">
                    Adding...
                  </button>
                ) : (
                  <button type="submit" className="btn btn-green py-2 px-5" disabled={!submitForm()}>
                    Add
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddInstallationTarget;
