/* eslint-disable import/prefer-default-export */
export const GET_EXPIRED = 'GET_EXPIRED';
export const GET_ADDRESS = 'GET_ADDRESS';
export const GET_EXPIRED_USERS = 'GET_EXPIRED_USERS';
export const GET_INSTALLATIONS = 'GET_INSTALLATIONS';
export const GET_INSTALLED_USERS = 'GET_INSTALLED_USERS';
export const GET_ACTIVE_ZONES = 'GET_ACTIVE_ZONES';
export const GET_ACTIVE_CUSTOMER = 'GET_ACTIVE_CUSTOMER';
export const GET_ACTIVE_CUSTOMER_DETAILS = 'GET_ACTIVE_CUSTOMER_DETAILS';
export const GET_EXPIRED_CUSTOMER = 'GET_EXPIRED_CUSTOMER';
export const GET_EXPIRED_CUSTOMER_DETAILS = 'GET_EXPIRED_CUSTOMER_DETAILS';
export const GET_EXPIRED_CUSTOMER_ZONE_DETAILS = 'GET_EXPIRED_CUSTOMER_ZONE_DETAILS';
