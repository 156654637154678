/* eslint-disable react/button-has-type */
import React from 'react';

const NotFound = () => {
  return (
    <div className="bg-white notfound">
      <div className="container-fluid">
        <div className="img-place mt-4 ml-4">
          <img src="https://res.cloudinary.com/teewhy/image/upload/v1548861545/404.fw.png" alt="Tizeti" />
        </div>

        <div className="text-center error-page">
          <img src="https://res.cloudinary.com/teewhy/image/upload/v1548861533/404_image.png" alt="404" />
          <h6>Sorry, this page does not exist </h6>
          <button style={{ color: 'red' }} onClick={() => window.history.back()}>
            Go Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
