/* eslint-disable import/prefer-default-export */
import * as types from '../../../constants/types/accounts/DedicatedInvoiceTypes';
import * as load from '../../../constants/types/shared/LoadingTypes';

export const dedicatedInvoiceReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case load.LOADING_STARTS:
      return {
        ...state,
        isLoading: true,
      };

    case load.LOADING_STOPS:
      return {
        ...state,
        isLoading: false,
      };

    case types.GET_ALL_DEDICATED_INVOICE:
      return {
        ...state,
        lists: payload.list,
        pages: payload.pages,
        count: payload.count,
        isLoading: false,
      };

    default:
      return state;
  }
};
