import {
  FETCHING_STARTS,
  FETCHING_STOPS,
  LOADING_STARTS,
  LOADING_STOPS,
} from '../../../constants/types/shared/LoadingTypes';
import { SUCCESS_MESSAGE } from '../../../constants/types/sales/logs/LogsTypes';
import { GET_CALL_LOGS } from '../../../constants/types/shared/call-logs/CallLogsTypes';
import * as types from '../../../constants/types/shared/logs/LogsTypes';

const initialState = {
  load: false,
  success: null,
  logs: [],
  get: false,
  countries: [],
  gettingCities: false,
  cities: [],
  states: [],
};

export const SalesLogsReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case LOADING_STARTS:
      return {
        ...state,
        load: payload,
      };

    case FETCHING_STARTS:
      return {
        ...state,
        get: payload,
      };

    case FETCHING_STOPS:
      return {
        ...state,
        get: payload,
      };

    // case

    case LOADING_STOPS:
      return {
        ...state,
        load: payload,
      };

    case SUCCESS_MESSAGE:
      return {
        ...state,
        success: payload,
        load: false,
      };

    case GET_CALL_LOGS:
      return {
        ...state,
        logs: payload,
        load: false,
      };

    case types.GET_LOGS:
      return {
        ...state,
        logs: payload,
        get: false,
      };

    case types.GET_COUNTRIES:
      return {
        ...state,
        countries: payload,
      };
    case types.GET_CITIES:
      return {
        ...state,
        cities: payload,
        gettingCities: false,
      };
    case types.GETTING_CITIES:
      return {
        ...state,
        gettingCities: payload,
      };
    case types.GET_STATES:
      return {
        ...state,
        states: payload,
      };

    default:
      return state;
  }
};

export default SalesLogsReducer;
