import React from 'react';
// import { RouteComponentProps } from 'react-router-dom';
import { SALES, SUPER_ADMIN, VICE_PRESIDENT_SALES } from '../../../../../utils/departments/helpers';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import SalesNavBar from '../../../../layouts/sales/SalesNavBar';
import RelocationBody from '../../../shared/relocation/RelocationBody';
import useCheckFiveDepartments from '../../../../../hooks/shared/custom/check-department/useCheckFiveDepartments';

const SalesRelocation = ({ history }) => {
  useCheckFiveDepartments(`${SALES}`, `${SUPER_ADMIN}`, `${VICE_PRESIDENT_SALES}`, history);
  const {
    profile: { firstName },
  } = useUser();

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <SalesNavBar currentPage="Relocation" position={firstName} />
        <RelocationBody />
      </main>
    </div>
  );
};

export default SalesRelocation;
