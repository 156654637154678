/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import helpers from '../../../../../utils/helpers';
import view from '../../../../../assets/img/icons/view.png';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../../utils/loader/loadingHelper';
import OperationVehicle from '../../operations/vehicles/Vehicle';

const AccountVehiclesTable = ({ lists, isLoading, keyword }) => {
  const colSpan = '8';
  const [request, setRequest] = useState({
    cost: '',
    department_id: '',
    chasis_no: '',
    plate_number: '',
    location: '',
    engine_no: '',
    transmission_type: '',
    registration_status: '',
    EmployeeAuth: '',
    handler: '',
    registration_number: '',
    document1: '',
    document2: '',
    document3: '',
    document4: '',
    document5: '',
  });
  const getRequest = (item) => setRequest(item);

  const tableData =
    lists && lists.length ? (
      lists
        .filter((list, _) => {
          if (keyword === '') {
            return list;
          }
          if (
            list.engine_no.toLowerCase().includes(keyword.toLowerCase()) ||
            list.model.toLowerCase().includes(keyword.toLowerCase()) ||
            list.color.toLowerCase().includes(keyword.toLowerCase()) ||
            list.chasis_no.toLowerCase().includes(keyword.toLowerCase())
          ) {
            return list;
          }
          // else if (list.make.toLowerCase().includes(keyword.toLowerCase())) {
          //   return list;
          // }
          // if (list.make.toLowerCase().includes(keyword.toLowerCase())) {
          //   return list;
        })
        .map((list, i) => {
          return (
            <tr className="" key={i}>
              <td>{i + 1}</td>
              <td className="text-nowrap">{`${list.color || 'N/A'}`}</td>
              <td>{list.make || 'N/A'}</td>
              <td>{list.model || 'N/A'}</td>
              <td className="text-nowrap">{list.engine_no || 'N/A'}</td>

              <td>{list.chasis_no || 'N/A'}</td>
              <td>{helpers.formatDateAndTime(list.year_of_purchase || 'N/A')}</td>
              <td className="text-nowrap">{list.plate_number || 'N/A'}</td>
              <td className="text-nowrap">{list.location || 'N/A'}</td>

              <td>
                <div className="d-flex">
                  <button
                    type="submit"
                    className="mx-1 noBorder"
                    data-toggle="modal"
                    data-placement="left"
                    data-target="#viewVehicles"
                    onClick={() => getRequest(list)}
                  >
                    <span className="view">
                      <img src={view} alt="" data-toggle="tooltip" data-placement="left" title="View Vehicles" />
                    </span>
                  </button>
                </div>
              </td>
              <OperationVehicle request={request} />
            </tr>
          );
        })
    ) : (
      <NoItemRow colSpan={colSpan} text="There are no records" />
    );

  return (
    <div className="table-responsive content col-12">
      <table className="table table-striped">
        <thead>
          <tr className="">
            <th> S/N</th>
            <th>Color</th>
            <th className="text-nowrap">Make</th>
            <th className="text-nowrap">Model</th>
            <th className="text-nowrap"> Engine no</th>
            <th className="text-nowrap"> Chasis No</th>
            <th className="text-nowrap"> Year of Purchase</th>
            <th className="text-nowrap">Plate Number</th>
            <th className="text-nowrap">Location</th>
            <th className="text-nowrap">Action</th>
          </tr>
        </thead>
        <tbody>{isLoading ? <TizetiGIFLogoTable colSpan={colSpan} /> : tableData}</tbody>
      </table>
    </div>
  );
};

export default AccountVehiclesTable;
