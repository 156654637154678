/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import RequisitionNotification from '../../staff/shared/layouts/sidebar/manager-and-executives/RequisitionNotification';
import PATHS from '../../../routes/urls';
// eslint-disable-next-line import/no-named-as-default
import BudgetSideBar from '../../staff/shared/layouts/sidebar/manager-and-executives/BudgetSideBar';
import NocSheetSideBar from './noc-sheets-sidebar/NocSheetSideBar';
import CnMaestroSidebar from './cnmaestro-sidebar/CnMaestroSidebar';
import NetworkSidebarItem from './shared/NetworkSidebarItem';
import useCheckTwoDepartments, {
  useCheckThreeDepartments,
} from '../../../hooks/shared/custom/check-department/useCheckTwoDepartments';
import { NOC, SUPER_ADMIN, OPERATIONS } from '../../../utils/departments/helpers';
import { AES, enc } from 'crypto-js';

const NetworkOperationsSideBar = () => {
  const history = useHistory();
  // useCheckThreeDepartments(`${NOC}`, `${SUPER_ADMIN}`, `${OPERATIONS}`, history);

  const role_id = Number(sessionStorage.getItem('roleId'));
  const email = sessionStorage.getItem('officialEmail');
  const detailsState = {
    name: 'React',
    messagetoken: sessionStorage.getItem('token'),
    messagerole: sessionStorage.getItem('roleId'),
    messagedepartment: sessionStorage.getItem('departmentId'),
    secret: 'Wifirocks123',
    cipher: '',
  };
  const [details, setDetails] = React.useState(detailsState);
  const { name, messagetoken, messagerole, messagedepartment, secret, cipher } = details;

  const obj = {
    login: messagetoken,
    pass: messagerole,
    department: messagedepartment,
  };
  const ciph = AES.encrypt(JSON.stringify(obj), secret).toString();

  return (
    <div className="s-layout__sidebar">
      <Link className="s-sidebar__trigger" to="#">
        <i className="fa fa-bars mr-3" />
      </Link>
      <nav className="s-sidebar__nav">
        <Link className="navbar-brand-logo" to="/network-operations">
          <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971047/Tizeti-Logo.png" alt="homepage" />
        </Link>
        <ul>
          <span className="p-0 mt-4">
            <li className="mt-3 s-sidebar__nav-link">
              <Link to="/network-operations" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971013/Group-3.png" alt="img" />
                </span>
                Overview
              </Link>
            </li>
            {/* 
            {email &&
            [
              'ademola.danmusa@tizeti.com',
              'owolabi.ogunjimi@tizeti.com',
              'david.waribiebo@tizeti.com',
              'olawande.arogundade@tizeti.com',
              'kennedy.agbaiko@tizeti.com',
              'pius.adesina@tizeti.com',
              'nocDept@tizeti.com',
            ].includes(email) ? ( */}
            <li className="s-sidebar__nav-link">
              {' '}
              <a href={`https://os.tizeti.com/main/#/overview?ciph=${ciph}`} target="_blank" className="make-bold">
                <span>
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923169/configr_d.fw.png" alt="img" />
                </span>
                <small>New system (Beta v2.0)</small>
              </a>
            </li>
            {/* ) : null} */}
            <li className="s-sidebar__nav-link">
              <Link to="/network-operations/public-ip" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1547725174/IP_Allocation.png" alt="img" />
                </span>
                Public IPs
              </Link>
            </li>
            <li className="s-sidebar__nav-link">
              <Link to="/network-operations/data-logger" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1547725174/IP_Allocation.png" alt="img" />
                </span>
                Monitor Devices Logger
              </Link>
            </li>
            <NocSheetSideBar />
            <CnMaestroSidebar />
            <li className="s-sidebar__nav-link">
              <Link to="/network-operations/bandwidth-graph" target="_self">
                <span className="mr-2">
                  <img
                    src="https://res.cloudinary.com/teewhy/image/upload/v1547724068/frequency_graphs.png"
                    alt="img"
                  />
                </span>
                Bandwidth Graph
              </Link>
            </li>
            <li className="s-sidebar__nav-link d-none d-md-flex">
              {' '}
              <Link to="/network-operations/frequency-log" target="_self">
                <span className="mr-2">
                  <img
                    src="https://res.cloudinary.com/teewhy/image/upload/v1547724068/frequency_graphs.png"
                    alt="img"
                  />
                </span>
                Frequency Logs
              </Link>
            </li>
            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/network-operations/access-points" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1552318332/Access_Points.png" alt="img" />
                </span>
                Access Points
              </Link>
            </li>
            {(email === 'nocDept@tizeti.com' ||
              email === 'idris.muhammed@tizeti.com' ||
              email === 'david.waribiebo@tizeti.com' ||
              email === 'owolabi.ogunjimi@tizeti.com' ||
              email === 'timilehin.ajisekola@tizeti.com' ||
              email === 'cosmos.ghartey@tizeti.com' ||
              email === 'matthew.uhuo@tizeti.com' ||
              email === 'gideon.osuji@tizeti.com' ||
              email === 'bartholomew.ocheke@tizeti.com') && (
              <li className="s-sidebar__nav-link">
                <Link to="/shared/release-report" target="_self">
                  <span className="mr-2">
                    <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                  </span>
                  Release Report
                </Link>
              </li>
            )}
            <span className="s-sidebar__nav-link d-none d-md-block" data-toggle="collapse" data-target="#lte">
              <li>
                {' '}
                <Link to="#" target="_self" className="">
                  <span className="mr-2">
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1547725174/IP_Allocation.png" alt="img" />
                  </span>
                  LTE
                </Link>
              </li>
            </span>

            <span id="lte" className="collapse base-li top-bottom">
              <li className="sidebar-nav-link-li">
                {' '}
                <Link to={PATHS.NOC_LTE_ACCOUNTS} target="_self" className="mt-1">
                  <span className="mr-2">
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1555319728/recruitment_1.svg" alt="img" />
                  </span>
                  Customer Accounts
                </Link>
              </li>
              <li className="sidebar-nav-link-li">
                <Link to={PATHS.NOC_LTE_ACTIVE_CUSTOMERS} target="_self" className="mt-1">
                  <span className="mr-2">
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1552318332/Access_Points.png" alt="img" />
                  </span>
                  Active Customers
                </Link>
              </li>
              <li className="sidebar-nav-link-li">
                {' '}
                <Link to={PATHS.NOC_LTE_EXPIRED_CUSTOMERS} target="_self" className="mt-1">
                  <span className="mr-2">
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1552318332/Access_Points.png" alt="img" />
                  </span>
                  Expired Customers
                </Link>
              </li>
              <li className="sidebar-nav-link-li">
                {' '}
                <Link to={PATHS.NOC_LTE_PROFILE} target="_self" className="mt-1">
                  <span className="mr-2">
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1555319728/recruitment_1.svg" alt="img" />
                  </span>
                  Profiles
                </Link>
              </li>

              <li className="sidebar-nav-link-li">
                {' '}
                <Link to={PATHS.NOC_LTE_SUBSCRIBERS} target="_self" className="mt-1">
                  <span className="mr-2">
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1555319728/recruitment_1.svg" alt="img" />
                  </span>
                  Subscribers
                </Link>
              </li>

              <li className="sidebar-nav-link-li">
                {' '}
                <Link to={PATHS.NOC_LTE_EXPIRED_SUBSCRIBERS} target="_self" className="mt-1">
                  <span className="mr-2">
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1555319728/recruitment_1.svg" alt="img" />
                  </span>
                  Expired Subscribers
                </Link>
              </li>
            </span>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/network-operations/customers" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                </span>
                Geniatech
              </Link>
            </li>
            <NetworkSidebarItem name="Completed Sites" to={PATHS.NOC_COMPLETED_SITES} />
            <NetworkSidebarItem name="Sub Basestation" to={PATHS.NOC_SUB_BASESTATION} />

            {Number(`${sessionStorage.getItem('roleId')}`) > 2 ? (
              <li className="s-sidebar__nav-link d-none d-md-block">
                {' '}
                <Link to="/network-operations/recruitment" target="_self">
                  <span className="mr-2">
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1555319728/recruitment_1.svg" alt="img" />
                  </span>
                  Recruitment
                </Link>
              </li>
            ) : null}

            {Number(sessionStorage.getItem('roleId')) > 2 ? (
              <BudgetSideBar link={PATHS.NOC_BUDGET} name="Budget" />
            ) : null}

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/network-operations/tickets" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1557141046/Schedule.png" alt="schedule" />
                </span>
                Tickets
              </Link>
            </li>

            <li className="s-sidebar__nav-link d-none d-md-block">
              {' '}
              <Link to="/network-operations/noc-kpi" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1557141046/Schedule.png" alt="" />
                </span>
                NOC KPI
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/network-operations/dedicated-customers" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                </span>
                Dedicated Customers
              </Link>
            </li>

            <li className="s-sidebar__nav-link d-none d-md-block">
              {' '}
              <Link to="/network-operations/static-ip" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                </span>
                Static IP
              </Link>
            </li>

            {/* <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/network-operations/incident-report" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1552309360/incidentReport.png" alt="img" />
                </span>
                Incident Reports
              </Link>
            </li> */}

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/network-operations/incident-report-new" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1552309360/incidentReport.png" alt="img" />
                </span>
                Incident Reports
              </Link>
            </li>

            <li className="s-sidebar__nav-link d-none d-md-block">
              {' '}
              <Link to="/network-operations/latency-graph" target="_self">
                <span className="mr-2">
                  <img
                    src="https://res.cloudinary.com/teewhy/image/upload/v1547724068/frequency_graphs.png"
                    alt="img"
                  />
                </span>
                Latency Graph
              </Link>
            </li>

            <li className="s-sidebar__nav-link d-none d-md-block">
              {' '}
              <Link to="/network-operations/voltage-monitor" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1547723759/Voltage_monitor.png" alt="img" />
                </span>
                Battery Voltage Monitor
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/network-operations/solar-count" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1557141046/SolarPanel.png" alt="img" />
                </span>
                Battery / Solar count
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/network-operations/schedule" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1557141046/Schedule.png" />
                </span>
                Schedule
              </Link>
            </li>

            <li className="s-sidebar__nav-link d-none d-md-block">
              {' '}
              <Link to="/network-operations/power-consumption" target="_self">
                <span className="mr-2">
                  <img
                    src="https://res.cloudinary.com/teewhy/image/upload/v1552309360/powerConsumption.png"
                    alt="img"
                  />
                </span>
                Power Consumption
              </Link>
            </li>
            <li className="s-sidebar__nav-link d-none d-md-block">
              {' '}
              <Link to="/downtime-logger" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971012/Group-1.png" alt="img" />
                </span>
                Downtime Logger
              </Link>
            </li>

            <span className="s-sidebar__nav-link d-none d-md-block" data-toggle="collapse" data-target="#admins">
              <li>
                {' '}
                <Link to="#" target="_self" className="">
                  <span className="mr-2">
                    <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971012/Group-1.png" alt="img" />
                  </span>
                  BASESTATIONS
                </Link>
              </li>
            </span>
            <span id="admins" className="collapse base-li top-bottom pl-2">
              <li className="sidebar-nav-link-li">
                <Link to="/network-operations/basestations" target="_self" className="mt-1">
                  <span className="mr-2">
                    <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971012/Group-1.png" alt="img" />
                  </span>
                  Basestations
                </Link>
              </li>

              <li className="sidebar-nav-link-li">
                <Link to="/network-operations/basestation-status" target="_self" className="mt-1">
                  <span className="mr-2">
                    <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971012/Group-1.png" alt="img" />
                  </span>
                  Basestation Status
                </Link>
              </li>

              <li className="sidebar-nav-link-li">
                <Link to="/shared/basestations" target="_self" className="mt-1">
                  <span className="mr-2">
                    <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971012/Group-1.png" alt="img" />
                  </span>
                  All Basestations
                </Link>
              </li>

              <li className="sidebar-nav-link-li">
                <Link to="/network-operations/no-los" target="_self" className="mt-1">
                  <span className="mr-2">
                    <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971012/Group-1.png" alt="" />
                  </span>
                  No LOS
                </Link>
              </li>

              <li className="sidebar-nav-link-li">
                {' '}
                <Link to="/basestations-monitoring" target="_self" className="mt-1">
                  <span className="mr-2">
                    <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971012/Group-1.png" alt="img" />
                  </span>
                  Monitoring
                </Link>
              </li>

              <li className="sidebar-nav-link-li mb-5">
                {' '}
                <Link to="/network-operations/frequency-usage" target="_self" className="mt-1">
                  <span className="mr-2">
                    <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971012/Group-1.png" alt="img" />
                  </span>
                  Usage Graph
                </Link>
              </li>
            </span>

            <li className="s-sidebar__nav-link d-none d-md-block">
              {' '}
              <Link to="/network-operations/promoter-score" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923430/r_d-envelop.fw.png" alt="img" />
                </span>
                Net Promoter Score
              </Link>
            </li>

            <li className="s-sidebar__nav-link d-none d-md-block">
              {' '}
              <Link to="/network-operations/retrieval" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923430/r_d-envelop.fw.png" alt="img" />
                </span>
                Retrievals
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/network-operations/fibre-links" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923430/r_d-envelop.fw.png" alt="img" />
                </span>
                Fibre Links
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/network-operations/dedicated-fibre" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923430/r_d-envelop.fw.png" alt="img" />
                </span>
                Dedicated Fibre Links
              </Link>
            </li>

            {Number(`${sessionStorage.getItem('roleId')}`) > 2 ? (
              <li className="s-sidebar__nav-link d-none d-md-block">
                {' '}
                <Link to="/network-operations/recruitment" target="_self" className="">
                  <span className="mr-2">
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1555319728/recruitment_1.svg" alt="img" />
                  </span>
                  Recruitment
                </Link>
              </li>
            ) : null}
          </span>

          <span className="p-0 mt-5 d-none d-md-block">
            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/network-operations/kpi-monitor" target="_self" className="make-bold">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg" alt="img" />
                </span>
                KPI Monitor
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/network-operations/admin-requests" target="_self" className="make-bold">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg" alt="img" />
                </span>
                Admin Requests
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/network-operations/requisitions" target="_self" className="d-flex">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1550844527/requisition.png" alt="img" />
                </span>
                Requisition
                <RequisitionNotification />
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              <Link to="/network-operations/purchase-order" target="_self" className="">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1557141046/Schedule.png" alt="img" />
                </span>
                Purchase Order
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/network-operations/payslip" target="_self" className="">
                <span>
                  <img
                    src="https://res.cloudinary.com/teewhy/image/upload/v1550844527/requisition.png"
                    alt="img"
                    className="mr-2"
                  />
                </span>
                PaySlip
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/network-operations/escalate" target="_self" className="text-danger make-bold">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1557141046/Schedule.png" alt="img" />
                </span>
                Escalate
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/network-operations/issue" target="_self" className="text-danger make-bold">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923430/r_d-envelop.fw.png" alt="img" />
                </span>
                Report Issue
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              <Link to="/network-operations/learning-portal" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923430/r_d-envelop.fw.png" alt="img" />
                </span>
                Learning Portal
              </Link>
            </li>

            {Number(`${sessionStorage.getItem('roleId')}`) > 2 ? (
              <li className="s-sidebar__nav-link">
                {' '}
                <Link to="/network-operations/request-feature" target="_self" className="make-bold">
                  <span className="mr-2">
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923169/configr_d.fw.png" alt="img" />
                  </span>
                  Request a Feature
                </Link>
              </li>
            ) : null}
          </span>
        </ul>
      </nav>
    </div>
  );
};

export default NetworkOperationsSideBar;
