/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, Fragment } from 'react';
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { API_URL, token } from '../../../../../../utils/config';
import edit from '../../../../../../assets/img/icons/edit.png';
import EditCustomerDetails from '../EditCustomerDetails';

const UnverifiedCustomersTable = ({ customers, officialEmail }) => {
  const [email, setEmail] = useState('');
  const [mac, setMac] = useState('');
  const [phone, setPhone] = useState('');

  const getUser = (customer) => {
    setEmail(customer.email);
    setMac(customer.accounts[0].mac_id);
    setPhone(customer.phoneNumber);
  };

  const verifyCustomer = async (customer) => {
    const data = { emailVerified: 'yes' };
    try {
      const response = await axios.patch(`${API_URL}/users/${customer.id}`, data, token);

      if (response.data.editStatus === 'success') {
        toast.success('User has been activated');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (e) {
      toast.info('Something went wrong!');
    }
  };

  const customersList =
    customers.length && customers
      ? customers.map((customer, i) => (
        <tr>
          <td>{i + 1}</td>
          <td>{customer.firstName}</td>
          <td>{customer.lastName}</td>
          <td>{customer.email}</td>
          <td>{customer.phoneNumber}</td>
          <td>{customer.address}</td>
          <td>{customer.country === 'ng' ? 'Nigeria' : 'Ghana'}</td>
          <td>{moment(customer.created_at).format('DD-MM-YYYY')}</td>
          {(officialEmail === 'blessing.okwedi@tizeti.com' || Number(`${sessionStorage.getItem('roleId')}`) > 2) &&
            Number(`${sessionStorage.getItem('roleId')}`) !== 6 ? (
            <Fragment>
              <td
                className="text-center img-pointer"

                onClick={() => getUser(customer)}
                data-toggle="modal"
              >
                <span className="view2">
                  <img src={edit} alt="Edit" data-toggle="tooltip" data-placement="top" title="Edit" />
                </span>
              </td>
              <EditCustomerDetails username={mac} oldEmail={email} oldPhone={phone} oldName="" item={{}} />

              <td className="text-center img-pointer" onClick={() => verifyCustomer(customer)}>
                <span className="view2">
                  <img
                    src="https://res.cloudinary.com/teewhy/image/upload/v1545642324/checked.png"
                    alt="Verify"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Verify"
                  />
                </span>
              </td>
            </Fragment>
          ) : null}
        </tr>
      ))
      : null;

  return <tbody>{customersList || null}</tbody>;
};

UnverifiedCustomersTable.propTypes = {
  // eslint-disable-next-line react/require-default-props
  customers: PropTypes.shape(),
  officialEmail: PropTypes.string.isRequired,
};
export default UnverifiedCustomersTable;
