import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import useAddNewCutomer from '../../../../../hooks/express-wifi/useAddNewCustomer';
import useExpressWifiAmbassadors from '../../../../../hooks/express-wifi/useExpressWifiAmbassadors';

const AddNewCustomer = (props) => {
  const { AddNewCustomer } = useAddNewCutomer();
  // const { getListOfCustomers, items} = useExpressWifiAmbassadors();

  const [one, setOne] = useState({
    cutomerName: '',
    customerPhone: '',
    ambassadorName: '',
    ambassadorPhone: '',
    ambassadorEmail: '',
  });

  const { customerName, customerPhone, ambassadorName, ambassadorPhone, ambassadorEmail } = one;

  const onFormSubmit = (e) => {
    e.preventDefault();
    const data = {
      action: 'add_new_customer',
      customer_name: customerName,
      customer_phone: customerPhone,
      ambassador_name: ambassadorName,
      ambassador_phone: ambassadorPhone,
      staff_email: ambassadorEmail,
    };
    AddNewCustomer(data);
  };
  // const data = qs.stringify({
  //   action: 'get_list_of_customers',
  //   from: `2021-04-01`,
  //   to: `${props.to}`,
  // });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOne({ ...one, [name]: value });
  };

  // useEffect(() => {
  //  getListOfCustomers(data);

  // }, [one])

  return (
    <div id="addNewCustomer" className="modal fade mt-5" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header shadow">
            <h4 className="modal-title update_asset">Customer Details</h4>
            <button type="button" className="btn btn-outline-info btn-circle btn-close" data-dismiss="modal">
              x
            </button>
          </div>
          <div className="modal-body detail mb-4 p-0">
            <form className="col-md-12 p-0" onSubmit={onFormSubmit}>
              <div className="d-flex">
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="companyName">
                    Cutomer Name
                  </label>
                  <input
                    type="text"
                    className="form-control border border-info pl-3 py-2"
                    id="customerName"
                    name="customerName"
                    value={customerName}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="customerName">
                    Customer Phone Number
                  </label>
                  <input
                    type="text"
                    className="form-control border border-info pl-3 py-2"
                    id="customerPhone"
                    name="customerPhone"
                    value={customerPhone}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <label className="justify-content-center">
                <h4 className="font-weight-bold">Added By</h4>
              </label>

              <div className="d-flex">
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="retailerName">
                    Ambassador Name <span className="font-weight-light pl-4">(Required)</span>
                  </label>
                  <input
                    type="text"
                    className="form-control border border-info pl-3 py-2"
                    id="ambassadorName"
                    name="ambassadorName"
                    value={ambassadorName}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="phone">
                    Ambassador Phone Number
                  </label>
                  <input
                    type="text"
                    className="form-control border border-info pl-3 py-2"
                    id="ambassadorPhone"
                    name="ambassadorPhone"
                    value={ambassadorPhone}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group col-6">
                <label className="m-0 p-0 copy-font" htmlFor="phone">
                  Ambassador Email
                </label>
                <input
                  type="text"
                  className="form-control border border-info pl-3 py-2"
                  id="ambassadorEmail"
                  name="ambassadorEmail"
                  value={ambassadorEmail}
                  onChange={handleChange}
                />
              </div>

              <div className="text-center mt-3">
                <button type="submit" className="btn btn-green px-3">
                  Add Customer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

AddNewCustomer.propTypes = {
  ambassadorName: PropTypes.string.isRequired,
};

export default AddNewCustomer;
