/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import Delete from '../../../../assets/img/icons/delete.png';
import View from '../../../../assets/img/icons/view.png';
import { API_URL } from '../../../../utils/config';

const ListFiles = ({ dept }) => {
  const [lists, setLists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [linkLoader, setLinkLoader] = useState(false);
  const [link, setLink] = useState('');
  const [message, setMessage] = useState('');

  const getList = async () => {
    setLoading(true);
    await axios({
      method: 'get',
      url: `${API_URL}/learning_portal/list/${dept}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
      .then((result) => {
        if (result.data.files.length > 0) {
          setLists(result.data.files);
          setLoading(false);
        } else {
          setMessage('Folder Empty');
        }
      })
      .catch(() => {
        setMessage('No File');
      });
  };

  useEffect(() => {
    getList();
  }, []);

  const downloadFile = async (list) => {
    await axios({
      method: 'get',
      url: `${API_URL}/learning_portal/download/${list.id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setLink(response.data.webContentLink);
          setLinkLoader(true);
          toastr.info('Preparing to Download...');
        }
      })
      .catch(() => toastr.info('Download Error'));
  };

  const deleteFile = async (list) => {
    await axios({
      method: 'delete',
      url: `${API_URL}/learning_portal/delete/${list.id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
      .then(() => {
        toastr.info('File Deleted');
        window.location.reload();
      })
      .catch(() => toastr.info('Unable To Delete'));
  };

  const tableData = () => {
    return lists.map((list, i) => {
      const { name, id, size, createdTime } = list;
      return (
        <tr key={id} className="text-left">
          <td>{i + 1}</td>
          <td>{name}</td>
          <td className="text-center">{Math.ceil(size / 1024)}</td>
          <td className="text-center">
            <Moment format="D MMM YYYY">{createdTime}</Moment>
          </td>
          <td>
            <button type="submit" className="mr-2" data-target="#" onClick={() => downloadFile(list)}>
              <span className="view">
                <img src={View} data-toggle="tooltip" data-placement="right" alt="Download" title="Download" />
              </span>
            </button>

            <button type="submit" data-target="#" onClick={() => deleteFile(list)}>
              <span className="view">
                <img src={Delete} data-toggle="tooltip" data-placement="right" alt="delete" title="Delete" />
              </span>
            </button>
          </td>
        </tr>
      );
    });
  };

  return (
    <div>
      {linkLoader ? window.location.assign(link) : null}
      <table className="table">
        <thead>
          {message ? (
            <tr>
              <td colSpan="3">{message}</td>
            </tr>
          ) : (
            <tr>
              <td>S/N</td>
              <td>File</td>
              <td className="text-center">Size (in KB)</td>
              <td className="text-center">Date</td>
              <td>Actions</td>
            </tr>
          )}
        </thead>
        <tbody>{message ? null : tableData()}</tbody>
      </table>
    </div>
  );
};

ListFiles.propTypes = {
  dept: PropTypes.string.isRequired,
};

export default ListFiles;
