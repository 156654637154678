/* eslint-disable import/prefer-default-export */
import { useReducer, useState } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import * as load from '../../../constants/types/shared/LoadingTypes';
import * as types from '../../../constants/types/sales/rescheduled-installations/RescheduledTypes';
import * as url from '../../../services/api/sales/rescheduled-installations/RescheduledUrl';
import { rescheduledReducer } from '../../../reducers/sales/rescheduled-installations/rescheduledReducer';

export const useReschedule = () => {
  const [{ lists, count, pages, isLoading }, dispatch] = useReducer(rescheduledReducer, {
    lists: [],
    count: 1,
    pages: 1,
    isLoading: false,
  });

  const [page] = useState(1);

  const onSubmit = async (selectedPage) => {
    dispatch({ type: load.LOADING_STARTS });
    const config = {
      method: 'post',
      url: `${url.GET_RESCHEDULED_REQUEST_URL}/${selectedPage}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response.data) {
          dispatch({
            type: types.GET_RESCHEDULED_REQUEST,
            payload: {
              lists: response.data.rows,
              count: response.data.count,
              pages: response.data.page,
            },
          });
          // console.log(response.data);
        }
      })
      .catch(() => {
        toastr.info('Something went wrong!');
        dispatch({ type: load.LOADING_STOPS });
      });
  };

  return {
    lists,
    pages,
    count,
    isLoading,
    onSubmit,
  };
};
