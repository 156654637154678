/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useContext } from 'react';
import edit from '../../../../../../assets/img/icons/edit.png';
import helpers from '../../../../../../utils/helpers';
import view from '../../../../../../assets/img/icons/view.png';
import ViewPtp from './ViewPtp';
import { PtpContext } from './context/PtpContext';
import loader from '../../../../../../assets/img/icons/TizetiLoaderColored.gif';

const PtpTable = () => {
  const { data, loading } = useContext(PtpContext);
  const [one, setOne] = useState({
    created_at: '',
    id: '',
    ap_end: '',
    station_end: '',
    device_name: '',
    ssid: '',
    device_type: '',
    security: '',
    frequency: '',
    link_distance: 0,
    capacity: 0,
    usage: 0,
  });
  const onClick = (params) => {
    setOne(params);
  };
  const tableData =
    data && data.length
      ? data.map((row, i) => (
          <tr className="text-nowrap" key={row.id}>
            <td>{i + 1}</td>
            <td>{row.ap_end}</td>
            <td>{row.station_end}</td>
            <td>{row.device_name}</td>
            <td>{row.ssid}</td>
            <td>{row.device_type}</td>
            <td>{row.security}</td>
            <td>{row.frequency}</td>
            <td>{row.link_distance}</td>
            <td>{row.capacity}</td>
            <td>{row.usage}</td>
            <td>{helpers.formatDateAndTime(row.created_at)}</td>
            <td>
              <div className="text-center">
                <button
                  type="submit"
                  className="mr-2"
                  data-toggle="modal"
                  data-placement="right"
                  data-target="#view_access-point"
                  onClick={() => onClick(row)}
                >
                  <span className="view">
                    <img src={view} data-toggle="tooltip" alt="" data-placement="top" title="View" />
                  </span>
                </button>
                <button
                  type="submit"
                  className="mr-2"
                  data-toggle="modal"
                  data-placement="right"
                  data-target="#update_item"
                  // onClick={() => getItem(list)}
                >
                  <span className="view">
                    <img src={edit} data-toggle="tooltip" alt="" data-placement="top" title="Edit" />
                  </span>
                </button>
              </div>
              <ViewPtp view={one} />
              {/* <EditRetrievalItem OneItem={one} /> */}
            </td>
          </tr>
        ))
      : 'no ptp';
  return (
    <div className="card x-panel mt-2 px-0">
      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>AP End</th>
              <th className="text-nowrap">Station End</th>
              <th>Device Name</th>
              <th>SSID</th>
              <th>Device Type</th>
              <th>Security</th>
              <th>Frequency</th>
              <th>Link Distance</th>
              <th>Capacity</th>
              <th>Usage</th>
              <th>Created At</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{loading ? <img src={loader} alt="gif" /> : tableData}</tbody>
        </table>
      </div>
    </div>
  );
};

export default PtpTable;
