import React from 'react';
import { Link } from 'react-router-dom';
import Search from './Search';
import PATHS from '../../../../../routes/urls';
import PercentageCompleted from './table/PercentageCompleted';
import { OPERATIONS, SUPER_ADMIN, TECHNICAL_SUPPORT } from '../../../../../utils/departments/helpers';
import RescheduleOverviewTable from './table/RescheduleOverviewTable';
import Searched from './Searched';
import RescheduledPercentageCompleted from './table/RescheduledPercentageCompleted';

const RescheduleOverviewBody = () => {
  const departmentId = sessionStorage.getItem('departmentId');

  return (
    <div className="col-12 after-summary">
      <Searched />
      <div className="ml-2 d-flex">
        {(departmentId === `${OPERATIONS}` ||
          departmentId === `${TECHNICAL_SUPPORT}` ||
          departmentId === `${SUPER_ADMIN}`) && (
          <Link to={PATHS.FSE_CLUSTERED_INSTALLATIONS} target="_self">
            <button type="button" className="btn btn-info">
              Clustered Installations
            </button>
          </Link>
        )}
        <RescheduledPercentageCompleted />
      </div>
      <RescheduleOverviewTable />
    </div>
  );
};

export default RescheduleOverviewBody;
