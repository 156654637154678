/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import DeviceRetrievalProvider from '../../../shared/device-retrieval/provider/DeviceRetrievalProvider';
import DeviceRetrievalBody from '../../../shared/device-retrieval/DeviceRetrievalBody';
import CustomerSupportNavBar from '../../../../layouts/customer-support/CustomerSupportNavBar';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import PageDataCount from '../../../shared/device-retrieval/PageDataCount';

const CustomerSupportDeviceRetrieval = () => {
  const { profile, user } = useUser();
  const { firstName } = profile;
  return (
    <div className="s-layout">
      <DeviceRetrievalProvider>
        <main className="s-layout__content col-lg-10 p-0">
          <CustomerSupportNavBar currentPage="Escalated Buckets" roleID={user.role_id} position={firstName} />
          <PageDataCount />
          <DeviceRetrievalBody />
        </main>
      </DeviceRetrievalProvider>
    </div>
  );
};

export default CustomerSupportDeviceRetrieval;
