/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
import React from 'react';
import { ToastContainer } from 'react-toastify';
import { useAccessPoint } from '../../../../../hooks/noc/access-points/useAccessPoint';
import { FORGOT_PASSWORD } from '../../../../../constants/types/AuthTypes';

//
// class AddAccessPoint extends Component {
//   constructor(props) {
//     super(props);
//
//     this.state = {
//       loading: false,
//
//     };
//
//     this.onSubmit = this.onSubmit.bind(this);
//     this.onChange = this.onChange.bind(this);
//   }
//
//   componentDidMount() {
//     this.getCountries();
//   }
//
//   onChange = (e) =>
//     this.setState({
//       [e.target.name]: e.target.value,
//     });
//

//
//
//

//
//   render() {
//     const { loading } = this.state;
//

//
//     return (
//
//     );
//   }
// }

const AddAccessPoint = () => {
  const { access, onChange, addAccessPoint, countries, isLoading } = useAccessPoint();

  const { address, country, frequency, http, security, ssid, type, username, password } = access;

  const allFrequencies = [];
  for (let freq = 4995; freq < 6000; ) {
    allFrequencies.push((freq += 5));
  }
  const freqList = allFrequencies.map((freq) => {
    return <option key={freq}>{freq}</option>;
  });

  const displayCountries = () => {
    if (countries.length) {
      return (
        <select className="form-control" onChange={onChange} name="country">
          <option value="">Choose Country</option>
          {countries.map((count) => {
            return <option value={count.name}>{count.name}</option>;
          })}
        </select>
      );
    }
    return null;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      ap_ssid: ssid,
      ip_address: address,
      https: http,
      radio_type: type,
      frequency,
      country,
      security,
      password,
      username,
    };

    addAccessPoint(data);
    console.log(data);
  };

  const isEnabled = () => {
    return (
      address.length > 0 &&
      country.length > 0 &&
      frequency.length > 0 &&
      http.length > 0 &&
      security.length > 0 &&
      ssid.length > 0 &&
      type.length > 0 &&
      username.length > 0 &&
      password.length > 0
    );
  };

  return (
    <div
      id="points"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" style={{ margin: '6% auto' }}>
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Add Access Points
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>

          <div className="modal-body">
            <form onSubmit={onSubmit} className="form-horizontal form-material">
              <div className="form-group invent">
                <div className="col-md-12 mb-3 d-flex">
                  <div className="col-lg-6">
                    <label className="copy-font">AP SSID</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="AP SSID"
                      name="ssid"
                      onChange={onChange}
                      value={ssid}
                    />
                  </div>

                  <div className="col-lg-6">
                    <label className="copy-font">IP Address</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="AP IP Address"
                      name="address"
                      onChange={onChange}
                      value={address}
                    />
                  </div>
                </div>

                <div className="col-md-12 mb-3 d-flex">
                  <div className="col-lg-6">
                    <label className="copy-font">Radio Type</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Radio Type"
                      name="type"
                      onChange={onChange}
                      value={type}
                    />
                  </div>

                  <div className="col-lg-6">
                    <label className="copy-font">Frequency</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Current Frequency"
                      name="frequency"
                      list="frequencies"
                      onChange={onChange}
                      value={frequency}
                    />
                    <datalist id="frequencies">
                      <select>{freqList}</select>
                    </datalist>
                  </div>
                </div>

                <div className="col-md-12 mb-3 d-flex">
                  <div className="col-lg-6">
                    <label className="copy-font">Https</label>
                    <div className="d-flex">
                      <input className="mt-1" type="radio" name="http" value="Enabled" onChange={onChange} />
                      <p className="copy-font ml-2 mr-2 mt-1" style={{ fontSize: '14px' }}>
                        Yes
                      </p>
                      <input className="mt-1" type="radio" name="http" value="Not Enabled" onChange={onChange} />
                      <p className="copy-font ml-2 mt-1" style={{ fontSize: '14px' }}>
                        No
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <label className="copy-font">Country</label>

                    {displayCountries()}
                  </div>
                </div>

                <div className="col-md-12 mb-3 d-flex">
                  <div className="col-lg-6">
                    <label className="copy-font">Username</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Username"
                      name="username"
                      onChange={onChange}
                      value={username}
                    />
                  </div>

                  <div className="col-lg-6">
                    <label className="copy-font">Password</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="AP IP Address"
                      name="password"
                      onChange={onChange}
                      value={password}
                    />
                  </div>
                </div>

                <div className="col-md-12 mb-3 d-flex">
                  <div className="col-lg-6">
                    <label className="copy-font">Security</label>
                    <div className="d-flex">
                      <input className="mt-1" type="radio" name="security" value="Yes" onChange={onChange} />
                      <p className="copy-font ml-2 mr-2 mt-1" style={{ fontSize: '14px' }}>
                        Yes
                      </p>
                      <input className="mt-1" type="radio" name="security" value="No" onChange={onChange} />
                      <p className="copy-font ml-2 mt-1" style={{ fontSize: '14px' }}>
                        No
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center">
                {isLoading ? (
                  <button disabled={isLoading} className="btn btn-green copy-font" id="login">
                    Adding...
                    {/* <i className="fa fa-circle-o-notch fa-spin" /> */}
                  </button>
                ) : (
                  <button className="btn btn-green" disabled={!isEnabled()}>
                    Add Access Point
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddAccessPoint;
