import { useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import profileReducer from '../../../../../reducers/shared/noc/lte/profiles/profileReducer';
import * as types from '../../../../../constants/types/shared/noc/LteTypes';
import ProfileUrl from '../../../../../services/api/shared/noc/lte/profile/ProfileUrl';
import * as load from '../../../../../constants/types/shared/LoadingTypes';

const useProfile = () => {
  const profileState = {
    title: '',
    k: '',
    am: '',
    opc: '',
    downlink: '',
    uplink: '',
    apn: '',
    qci: '',
    arp: '',
    description: '',
    direction: '',
    addr: '',
    addr6: '',
  };

  const [profile, setProfile] = useState(profileState);
  const [{ creating, profiles, loading }, dispatch] = useReducer(profileReducer, {
    creating: false,
    profiles: [],
    loading: false,
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setProfile({
      ...profile,
      [name]: value,
    });
  };

  const getProfiles = async () => {
    console.log('hello');
    console.log(`${ProfileUrl.GET_PROFILES_URL}`);
    dispatch({
      type: load.LOADING_STARTS,
    });
    try {
      const response = await axios.get(`${ProfileUrl.GET_PROFILES_URL}`, {
        'Content-Type': 'application/x-www-form-urlencoded',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        }
      });
      console.log(response.data);
      if (response.data) {
        dispatch({ type: types.GET_PROFILES, payload: response.data });
      } else {
        toast.info(`Unable to profiles`);
        dispatch({
          type: load.LOADING_STOPS,
        });
      }
    } catch (e) {
      console.log(e);
      if (e) {
        // toast.info(`Something went wrong!`);
        //
        // dispatch({
        //   type: load.LOADING_STOPS,
        // });
        // if (e.response) {
        toast.info(`Something went wrong!`);

        dispatch({
          type: load.LOADING_STOPS,
        });
      }
    }
  };

  const onSubmit = async (data) => {
    dispatch({
      type: types.CREATE_PROFILE,
    });
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response = await axios.post(`${ProfileUrl.CREATE_PROFILE_URL}`, data, {
        'Content-Type': 'application/x-www-form-urlencoded',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        }
      });

      if (response.data) {
        setProfile(profileState);
        toast.success('Profile Created');
        // dispatch({type: types.PROFILE_CREATED})
        dispatch(getProfiles());
      } else {
        toast.error('Unable to create profile!');
        dispatch({
          type: types.STOP_CREATE_PROFILE,
        });
      }
    } catch (error) {
      if (error) {
        toast.error('Something went wrong!');
        dispatch({
          type: types.STOP_CREATE_PROFILE,
        });
      }
    }
  };

  useEffect(() => {
    getProfiles();
  }, []);

  return { profile, onChange, onSubmit, creating, profiles, loading };
};

export default useProfile;
