import React from 'react';
import { useUser } from '../../../hooks/shared/user/useUser';

const CreateProfilePrompt = () => {
  const { profile, load } = useUser();
  const checkIfUserHasAProfile = () => {
    if (profile && profile.id) {
      return null;
    }
    return (
      <strong
        style={{
          margin: 'auto auto auto 8px',
          fontSize: '19px',
          color: 'red',
        }}
      >
        Create Your Profile
      </strong>
    );
  };
  const checkIfLoading = () => {
    if (load) {
      return null;
    }
    return checkIfUserHasAProfile();
  };
  return checkIfLoading();
};

export default CreateProfilePrompt;
