/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { SEARCH_FOR_ROUTER_URL } from '../../services/api/procurement/routers/RoutersUrl';
import { SEARCH_FOR_ROUTER } from '../../constants/types/procurement/ProcurementTypes';

export const searchForRouter = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${SEARCH_FOR_ROUTER_URL}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: SEARCH_FOR_ROUTER,
          payload: response.data,
        });
      }
    } catch (e) {}
  };
};
