/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

const GenerateInvoice = ({ generateInvoice, handleChange, invoiceDetails }) => {
  const { receiptNum } = invoiceDetails;

  return (
    <div className="card my-5 p-3">
      <label className="m-0 p-0 payslipfont col-3 text-left col-form-label" htmlFor="quantity">
        Generate Receipt
      </label>
      <form className="card-body" onSubmit={generateInvoice}>
        <div className="col-12 d-flex">
          <div className="col-7 d-flex">
            <label className="m-0 p-0 payslipfont col-form-label" htmlFor="receiptNum">
              Receipt No:
            </label>
            <div className="mb-2">
              <input
                type="text"
                className="form-control border border-info py-2"
                id="receiptNum"
                name="receiptNum"
                value={receiptNum}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="d-flex">
          <div className="text-right">
            <button className="btn btn-green mx-1" type="submit">
              Generate Receipt
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
export default GenerateInvoice;
