import React from 'react';

const countryOptions = ['Nigeria', 'Ghana'];

export default function CountrySelect({ selectedCountry, onCountryChange }) {
  const handleCountryChange = (e) => {
    onCountryChange(e.target.value);
  };

  // console.log(selectedCountry);
  return (
    <div className="container mx-4 mt-3">
      <div className="relative w-40">
        <select
          className="block w-full px-4 py-2 border rounded appearance-none focus:border-blue-500 focus:outline-none"
          value={selectedCountry}
          onChange={handleCountryChange}
        >
          <option value="" disabled>
            Select Country
          </option>
          {countryOptions.map((country) => (
            <option key={country} value={country}>
              {country}
            </option>
          ))}
        </select>
        <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none"></div>
      </div>
      {selectedCountry && <div className="my-2">Selected Country: {selectedCountry}</div>}
    </div>
  );
}
