import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import toastr from 'toastr';
import ConnectedCustomersTable from '../../departments/sales/basestations/customers/ConnectedCustomersTable';
import { getUser } from '../../../../actions/shared/user/UserActions';
import { getBasestations, getConnectedUsers } from '../../../../actions/shared/basestations/BasestationsActions';
import { LOGGED_IN_USER_URL } from '../../../../services/api/auth/AuthUrl';
import { SALES } from '../../../../utils/config';

class ConnectedCustomers extends Component {
  // eslint-disable-next-line no-undef
  state = {
    fromDate: '',
    toDate: '',
  };

  componentDidMount() {
    this.checkDepartment();
    // eslint-disable-next-line react/destructuring-assignment
    this.props.getUser();
    // eslint-disable-next-line react/destructuring-assignment
    this.props.getBasestations();
  }

  componentWillReceiveProps() {
    this.checkDepartment();
  }

  // eslint-disable-next-line no-undef
  numberOfUsers = async () => {
    const base = document.getElementById('elementId');
    const { text } = base.options[base.selectedIndex];
    const { fromDate, toDate, basestation } = this.state;
    const data = {
      from: fromDate,
      to: toDate,
    };
    // eslint-disable-next-line react/destructuring-assignment
    this.props.getConnectedUsers(data, basestation, text);
  };

  checkDepartment = async () => {
    const { history } = this.props;
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.department_id !== `${SALES}`) {
        history.push('/');

        sessionStorage.setItem('token', '');
        sessionStorage.clear();

        toastr.info('You are not allowed to view this page');
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const { user, basestations, users, name } = this.props;
    const { firstName } = user;
    const { fromDate, toDate, basestation } = this.state;

    const stations = basestations.map((basestation) => {
      return (
        <option key={basestation.id} value={basestation.id}>
          {basestation.name}
        </option>
      );
    });
    return (
      <div className="col-12 after-summary">
        <div className="col-lg-12 col-md-9 col-sm-12 d-flex flex-wrap">
          <div className="col-lg-4 col-md-4 col-sm-12 fliter copy-font d-flex mb-3">
            <small className="mt-2">Filter By Basestations:</small>
            <span>
              <select
                onChange={this.handleChange}
                id="elementId"
                className="form-control"
                name="basestation"
                value={basestation}
              >
                <option value="">Select Basestations</option>
                {stations}
              </select>
            </span>
          </div>

          <div className="input-group col-lg-3 col-md-3 col-sm-12 mr-3 my-3 copy-font">
            <span className="mt-2 mr-2">From:</span>
            <input type="date" name="fromDate" className="form-control" value={fromDate} onChange={this.handleChange} />
          </div>

          <div className="input-group col-lg-3 col-md-3 col-sm-12 mr-3 my-3 copy-font">
            <span className="mt-2 mr-2">To:</span>
            <input type="date" name="toDate" className="form-control" value={toDate} onChange={this.handleChange} />
          </div>

          <div className="mt-3">
            <button className="btn btn-info" onClick={this.numberOfUsers}>
              Submit
            </button>
          </div>
        </div>

        <div className="card x-panel logs">
          <div className="table-responsive content">
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th nowrap="no-wrap">Base Station</th>
                  <th nowrap="no-wrap">Number of Installed Users</th>
                </tr>
              </thead>
              <ConnectedCustomersTable count={users} basestation={name} />
            </table>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  basestations: state.basestations.basestations,
  users: state.basestations.users,
  name: state.basestations.name,
});

ConnectedCustomers.propTypes = {
  getUser: PropTypes.func.isRequired,
  // user: PropTypes.shape(),
  user: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  basestations: PropTypes.array,
  getBasestations: PropTypes.func,
  getConnectedUsers: PropTypes.func,
  history: PropTypes.shape(),
  // users: PropTypes.number,
  users: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  name: PropTypes.string,
};

export default connect(
  mapStateToProps,
  {
    getUser,
    getBasestations,
    getConnectedUsers,
  },
)(ConnectedCustomers);
