/* eslint-disable default-case */
import * as types from '../../../constants/types/UserTypes';

const initialState = {
  user: [],
};

export const UserReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === types.GET_USER_PROFILE) {
    return {
      ...state,
      user: payload,
    };
  } else {
    return state;
  }
};

export default UserReducer;
