import React from 'react';
import { CallLogProvider } from '../../../../../../context/customer-support/CallLogContext';
import AddCallLog from './AddCallLog';
import CustomersTable from './CustomersTable';

const CallLogsBody = () => {
  return (
    <CallLogProvider>
      <div className="container">
        <div className="row mt-5">
          <div className="mt-4 col-lg-12 col-md-12 col-sm-12">
            <div className="text-right">
              <button
                className="btn btn-green mb-2"
                type="submit"
                style={{ cursor: 'pointer' }}
                data-toggle="modal"
                data-target="#addCallLog"
              >
                Add Call Log
              </button>
              <AddCallLog />
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="card x-panel mt-3">
                  <div className="table-responsive content">
                    <table className="table copy-font">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Email</th>
                          <th>Phone Number</th>
                          <th>Note</th>
                          <th>Resolution</th>
                          <th>View</th>
                          <th>Date Created</th>
                        </tr>
                      </thead>
                      <CustomersTable />
                    </table>
                  </div>
                </div>
              </div>
              {/* <div className="pagination pull-right"> */}
              {/*  <ReactPaginate */}
              {/*    previousLabel="previous" */}
              {/*    nextLabel="next" */}
              {/*    breakLabel="..." */}
              {/*    breakClassName="break-me" */}
              {/*    //  pageCount={pageCount} */}
              {/*    pageCount="2" */}
              {/*    marginPagesDisplayed={2} */}
              {/*    pageRangeDisplayed={3} */}
              {/*    //  onPageChange={this.handlePageClick} */}
              {/*    containerClassName="pagination" */}
              {/*    subContainerClassName="pages pagination" */}
              {/*    activeClassName="active" */}
              {/*  /> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </CallLogProvider>
  );
};
export default CallLogsBody;
