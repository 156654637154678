/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';

const ViewPassword = ({ load, accounts, password }) => {
  return (
    <div
      id="view-password"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog mt-3">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Customer's Password
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body">
            <table className="col-12">
              <tr>
                <th>#</th>
                <th>Password</th>
              </tr>
              {load ? (
                <small>loading...</small>
              ) : (
                password.map((pass, i) => {
                  if (pass.password) {
                    return (
                      <tr key={i + 1}>
                        <td>{i + 1}</td>
                        <td>{pass.password}</td>
                      </tr>
                    );
                  }
                  return accounts.map((account, x) => (
                    <tr key={x + 1}>
                      <td>{x + 1}</td>
                      <td>{account.password ? account.password : 'N/A'}</td>
                    </tr>
                  ));
                })
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

ViewPassword.propTypes = {
  load: PropTypes.bool,
  password: PropTypes.shape(),
  accounts: PropTypes.shape(),
};

export default ViewPassword;
