/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import qs from 'qs';
import ReactPaginate from 'react-paginate';
import BusinessDevelopmentDedicatedCustomersTable from './BusinessDevelopmentDedicatedCustomersTable';
import useDedicatedCustomers from '../../../../hooks/business-development/useDedicatedCustomers';

import CustomCsvDownload from '../CustomCsvDownload';
import { dedicatedCustomersHeaders, filename } from '../../../../utils/field-support/installations/helpers';
import SearchDedicatedCustomer from './SearchDedicatedCustomer';
import { get_all_basestation_zones } from '../../../../actions/shared/basestations/GetAllBasestationsZones';

const BusinessDevelopmentDedicatedCustomersBody = () => {
  const { getDedicatedCustomers, searchUsingKeyword, items, allPages, isLoading } = useDedicatedCustomers();
  console.log(items)
  const [country, setCountry] = useState(`Nigeria`);
  const [filterby, setFilterBy] = useState(`all`);
  const [page] = useState(1);
const [getZones, setGetZones] = React.useState([]);

const [zone, setZone] = React.useState("");


   React.useEffect(() => {
    const fetchZones = async () => {
      setGetZones(await get_all_basestation_zones());
    };
    fetchZones();
  }, []);

  

const data = qs.stringify({
    action: 'get_all_dedicated_customers',
    pageno: `${page}`,
    country: `${country}`,
    no_of_records_per_page: '500',
    filterby: `${filterby}`,
  });
  

  useEffect(() => {
    getDedicatedCustomers(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();


  let filterdata;

  if (filterby === 'zone') {
    filterdata = qs.stringify({
    action: 'get_all_dedicated_customers',
    pageno: `${page}`,
    country: `${country}`,
    no_of_records_per_page: '500',
    filterby: `${filterby}`,
    zone: `${zone}`
  });
  }else {
    filterdata = qs.stringify({
    action: 'get_all_dedicated_customers',
    pageno: `${page}`,
    country: `${country}`,
    no_of_records_per_page: '500',
    filterby: `${filterby}`,
  });
  }
    getDedicatedCustomers(filterdata);
  };

  const handlePageClick = async (e) => {
    const selectedPage = e.selected + 1;
    const pageData = qs.stringify({
      action: 'get_all_dedicated_customers',
      pageno: `${selectedPage}`,
      country: `${country}`,
      no_of_records_per_page: '500',
      filterby: `${filterby}`,
    });
    getDedicatedCustomers(pageData);
  };

  
  return (
    <div className="mt-5 p-3">
      <div className="container-fluid pt-4">
        <div className="row">
          <div className="p-0 col-12 d-flex justify-content-between">
            <div className="d-flex">
              <SearchDedicatedCustomer searchUsingKeyword={searchUsingKeyword} />
            </div>
          </div>
          <form className="form-inline ml-3" onSubmit={onSubmit}>
            <div className="d-flex">
              <div className="form-group mr-2">
                Country
                <select
                  className="form-control"
                  id="country"
                  name="country"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                >
                  <option value="">Select an option</option>
                  <option value="Nigeria">Nigeria</option>
                  <option value="Ghana">Ghana</option>
                </select>
                <div className="form-group mr-2">
                  Filter By
                  <select
                    id="country"
                    className="form-control"
                    name="country"
                    value={filterby}
                    onChange={(e) => setFilterBy(e.target.value)}
                  >
                    <option value="">Select an option</option>
                    <option value="expired">Expired</option>
                    <option value="active">Active</option>
                    <option value="churned">Churned</option>
                    <option value="zone">Zone</option>
                    <option value="toBeDecommissioned">Decommissioned</option>
                    <option value="all">All</option>
                  </select>
                </div>

                {
                  filterby === 'zone' && (

                     <div className="form-group mr-2">
                  Zones
                  <select
                    id="zone"
                    className="form-control"
                    name="zone"
                    value={zone}
                    onChange={(e) => setZone(e.target.value)}
                  >
                    <option value="">Select an option</option>
                   {getZones.map(getZone => <option key={getZone.id} value={getZone.name}>{getZone.name}</option>)}
                   
                  </select>
                </div>

                  )
                }
                
              </div>
            </div>
            <div className="text-center mt-3">
              <button type="submit" className="btn btn-green py-2 px-5">
                Search
              </button>
            </div>
          </form>
          <div className="col-12 d-flex mt-2">
            <CustomCsvDownload
              data={items || []}
              headers={dedicatedCustomersHeaders}
              filename={filename(`Dedicated Customers`)}
              classname="btn btn-green shadow-md"
              textcolor="white"
              text="Download Data"
            />
          </div>
          <div className="p-0 col-12 d-flex justify-content-between">
            {items.length > 0 ? (
              <div>
                Count: <span className="badge badge-secondary mr-4">{items.length}</span>
              </div>
            ) : null}
          </div>
          <BusinessDevelopmentDedicatedCustomersTable items={items} isLoading={isLoading} filteredBy={filterby} />
          <div className="px-3">
            {allPages > 1 ? (
              <ReactPaginate
                previousLabel="previous"
                nextLabel="next"
                breakLabel="..."
                breakClassName="break-me"
                pageCount={allPages}
                marginPagesDisplayed={3}
                pageRangeDisplayed={4}
                onPageChange={handlePageClick}
                containerClassName="pagination"
                // subContainerClassName="pages pagination"
                activeClassName="active"
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessDevelopmentDedicatedCustomersBody;
