/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { useTickets } from '../../../../../hooks/noc/tickets/useTickets';
import { useBasestations } from '../../../../../hooks/shared/basestations/useBasestation';

const CreateTicket = ({ create, ticket, onChange, isCreating }) => {
  const { employees } = useTickets();
  const { basestations } = useBasestations();
  const { baseStationId, cause, owner, severity, time, type } = ticket;

  const renderEmployees = () => {
    if (employees) {
      return employees.map((staff) =>
        staff.employees.map((list) => {
          return (
            <option value={staff.id} key={staff.id}>
              {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
              {list.firstName} {list.lastName}
            </option>
          );
        }),
      );
    }
    return <option>unable to fetch personnel list</option>;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      base_station_id: baseStationId,
      incident_type: type,
      severity,
      time_of_incident: time,
      ticket_owner_id: owner,
      cause_of_incident: cause,
    };
    create(data);
  };

  const isEnabled = () => {
    return (
      cause.length > 0 &&
      severity.length > 0 &&
      time.length > 0 &&
      type.length > 0 &&
      baseStationId.length > 0 &&
      owner.length > 0
    );
  };

  return (
    <div id="create-ticket" className="modal fade in" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Create Ticket
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body">
            <form className="form-horizontal" onSubmit={onSubmit}>
              <div className="form-group employe-body">
                <div className="col-md-12 mb-3 d-flex" />

                <div className="col-md-12 mb-3 d-flex">
                  <div className="col-lg-6">
                    <label className="col-lg-12 text-justify copy-font m-0">Site Name</label>
                    <select className="form-control" name="baseStationId" onChange={onChange}>
                      <option>select basestation</option>
                      {basestations.map((basestation) => {
                        return (
                          <option value={basestation.id} key={basestation.id}>
                            {basestation.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="col-lg-6">
                    <label className="col-lg-12 text-justify copy-font m-0">Cause of Incident</label>
                    <input
                      type="text"
                      className="form-control p-2"
                      placeholder="Cause"
                      name="cause"
                      onChange={onChange}
                      value={cause}
                    />
                  </div>
                </div>

                <div className="col-md-12 mb-3 d-flex">
                  <div className="col-lg-6">
                    <label className="col-lg-12 text-justify copy-font m-0">Incident Type</label>

                    <input
                      type="text"
                      className="form-control p-2"
                      placeholder="Incident Type"
                      name="type"
                      onChange={onChange}
                      value={type}
                    />
                  </div>

                  <div className="col-lg-6">
                    <label className="col-lg-12 text-justify copy-font m-0">Severity</label>
                    <input
                      type="text"
                      name="severity"
                      onChange={onChange}
                      value={severity}
                      className="form-control p-2"
                      placeholder="Severity"
                    />
                  </div>
                </div>

                <div className="col-md-12 mb-3 d-flex">
                  <div className="col-lg-6">
                    <label className="col-lg-12 text-justify copy-font m-0">Time of Incident</label>
                    <input type="time" className="form-control p-2" name="time" onChange={onChange} value={time} />
                  </div>

                  <div className="col-lg-6">
                    <label className="col-lg-12 text-justify copy-font m-0">Owner of Ticket</label>
                    <select className="form-control" name="owner" onChange={onChange}>
                      <option>select personnel</option>
                      {renderEmployees()}
                    </select>
                  </div>
                </div>
              </div>

              <div className="text-center">
                {isCreating ? (
                  <button type="submit" className="btn btn-green waves-effect" disabled={isCreating}>
                    Creating Ticket...
                  </button>
                ) : (
                  <button type="submit" className="btn btn-green waves-effect" disabled={!isEnabled()}>
                    Create Ticket
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

CreateTicket.propTypes = {
  create: PropTypes.func,
  onChange: PropTypes.func,
  ticket: PropTypes.shape({
    baseStationId: PropTypes.string,
    cause: PropTypes.string,
    owner: PropTypes.string,
    severity: PropTypes.string,
    time: PropTypes.string,
    type: PropTypes.string,
  }),
  isCreating: PropTypes.bool,
};

export default CreateTicket;
