/* eslint-disable import/prefer-default-export */
import {
  START_ADD_DEDICATED,
  STOP_ADD_DEDICATED,
  GET_DEDICATED_CUSTOMERS,
} from '../../../constants/types/sales/customers/DedicatedTypes';
import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';

export const dedicatedReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case START_ADD_DEDICATED:
      return { ...state, submitting: true };
    case STOP_ADD_DEDICATED:
      return { ...state, submitting: false };
    case GET_DEDICATED_CUSTOMERS:
      return { ...state, dedicatedCustomers: payload, submitting: false, loading: false };
    case LOADING_STARTS:
      return { ...state, loading: true };
    case LOADING_STOPS:
      return { ...state, loading: false };
    default:
      return state;
  }
};
