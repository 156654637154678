import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getRequsitionByDepartment } from '../../../../../actions/shared/requisitions/RequisitionActions';

class ManagerCount extends Component {
  componentDidMount() {
    this.props.getRequsitionByDepartment();
  }

  render() {
    const { requisitions } = this.props;
    return <div style={{ color: 'red' }}>{requisitions.length}</div>;
  }
}

const mapStateToProps = (state) => ({
  requisitions: state.requisitions.requisitions,
});

export default connect(mapStateToProps, { getRequsitionByDepartment })(ManagerCount);
