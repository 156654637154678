/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-case-declarations */
import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import Select from 'react-select';
import { useTracklistCategory } from '../tracklist-category/hooks/useTracklistCategory';
import { Link } from 'react-router-dom';
import AddTracklistItem from './AddTracklistItem';
import TracklistTable from './TracklistTable';
import PATHS from '../../../../routes/urls';
import CustomPagination from '../CustomPagination';
import useTracklist from './hooks/useTracklist';
import CustomInput from '../forms/CustomInput';
import './index.css';
import CustomButton from '../forms/CustomButton';
import SearchTrackListWithDate from './SearchTrackListWithDate';
import { ACCOUNTS, PROCUREMENT } from '../../../../utils/departments/helpers';

const TracklistBody = () => {
  const departmentId = sessionStorage.getItem('departmentId');
  const { isLoading, list, pages, handlePageClick, searchUsingKeyword } = useTracklist();
  const [keyword, setKeyword] = useState('');
  const { lists, getTracklistCategory, isLoading: isListLoading } = useTracklistCategory();
  const [option, setOption] = useState('');
  const headers = [
    { label: 'Item', key: 'item' },
    { label: 'Company/Vendor', key: 'vendor' },
    { label: 'Initial Quantity', key: 'quantity' },
    // { label: 'Location', key: 'location' },
    // { label: 'Remainder', key: 'available_quantity' },
    { label: 'Invoice No', key: 'invoice_no' },
    { label: 'Currency', key: 'currency' },
    { label: 'Cost', key: 'cost' },
    { label: 'Date Received', key: 'date' },
    { label: 'Status', key: 'invoice_submission_status' },
  ];
  const filterUsingKeyword = (e) => {
    e.preventDefault();
    switch (option) {
      case 'vendor':
        const searchVendor = {
          vendor: keyword.trim(),
        };
        return searchUsingKeyword(searchVendor);

      case 'itemName':
        const searchItemName = {
          item: keyword.trim(),
        };
        return searchUsingKeyword(searchItemName);

      case 'invoiceNumber':
        const searchInvoiceNumber = {
          invoice_no: keyword.trim(),
        };
        return searchUsingKeyword(searchInvoiceNumber);

      case 'status':
        const searchbyStatus = {
          invoice_submission_status: keyword.trim(),
        };
        return searchUsingKeyword(searchbyStatus);

      // case 'location':
      //   const searchbyLocation = {
      //     location: keyword.trim(),
      //   };
      //   return searchUsingKeyword(searchbyLocation);

      default:
        return null;
    }
  };

  useEffect(() => {
    getTracklistCategory();
  }, []);

  return (
    <div className="container pt-5">
      <div className="row mt-5">
        <div className="p-0 mb-2 col-12 d-flex">
          <div>
            <form onSubmit={filterUsingKeyword} className="form-inline ml-3">
              <div className="form-group">
                <select name="chooseField" onChange={(e) => setOption(e.target.value)} className="form-control">
                  <option value="">--Choose Search Field--</option>
                  <option value="vendor">Vendor</option>
                  <option value="itemName">Item Name</option>
                  <option value="invoiceNumber">Invoice Number</option>
                  <option value="status">Device Status</option>
                  {/* <option value="location">Location</option> */}
                </select>
              </div>
              {option && option === 'status' && (
                <select
                  className="form-control border border-info py-2 borderRadius0"
                  id="status"
                  name="keyword"
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                >
                  <option>--Device Status--</option>
                  <option value="new">New</option>
                  <option value="retrieved">Retrieved</option>
                  <option value="not-available">Not available</option>
                  <option value="bad">Bad</option>
                </select>
              )}
              {option && option === 'itemName' && (
                <select
                  className="form-control border border-info py-2 borderRadius0"
                  id="status"
                  name="keyword"
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                >
                  {isListLoading ? (
                    <option value="">Loading items...</option>
                  ) : (
                    <>
                      <option value="">Select Item</option>
                      {lists.map((list) => {
                        return (
                          <option key={list.id} value={list.name}>
                            {list.name}
                          </option>
                        );
                      })}
                    </>
                  )}
                </select>
              )}

              {option && option !== 'status' && option !== 'itemName' && (
                <div className="form-group ml-2">
                  <CustomInput
                    classname="form-control keyword-input"
                    name="keyword"
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    readOnly={false}
                    placeholder="Input Search Term"
                    type="text"
                  />
                </div>
              )}
              <div className="form-group ml-2">
                <CustomButton classname="btn btn-green" name="Search" disabled={false} />
              </div>
            </form>
          </div>
          <div>
            <div className="form-group ml-2">
              <button className="btn btn-green" type="button" data-toggle="modal" data-target="#add_item">
                Add Item
              </button>

              {list.length ? (
                <CSVLink
                  data={list}
                  headers={headers}
                  filename="Tracklist.csv"
                  className="ml-2 btn btn-green"
                  style={{ color: 'white' }}
                >
                  Download Data
                </CSVLink>
              ) : null}
            </div>
          </div>
        </div>
        <AddTracklistItem />
        <div className="search-tracklist">
          <SearchTrackListWithDate searchWithDate={searchUsingKeyword} />
          {departmentId === `${PROCUREMENT}` || departmentId === `${ACCOUNTS}` ? (
            <div className="ml-2">
              <Link
                to={
                  Number(sessionStorage.getItem('roleId')) === 9
                    ? PATHS.ACCOUNTS_TRACKLIST_CATEGORY
                    : PATHS.PROCUREMENT_TRACKLIST_CATEGORY
                }
                target="_self"
              >
                <button type="button" className="btn btn-primary">
                  Tracklist Items
                </button>
              </Link>
            </div>
          ) : null}
        </div>
        <TracklistTable lists={list} load={isLoading} />

        <div className="px-3">
          {pages > 1 && (
            <CustomPagination
              pagesCount={pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TracklistBody;
