/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-shadow */
/* eslint-disable react/require-default-props */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/button-has-type */
/* eslint-disable no-case-declarations */
import React, { useEffect, useState } from 'react';
import CustomInput from '../../../shared/forms/CustomInput';
import { useCallLog } from '../../../../../hooks/customer-support/call-logs/useCallLog';
import CallLogAnalyticsTable from './CallLogAnalyticsTable';

const CallLogAnalyticsBody = () => {
  const today = new Date();
  const formattedDate = today.toISOString().slice(0, 10);

  const [from, setFrom] = useState(formattedDate);
  const [to, setTo] = useState(formattedDate);

  const { getCallLogAnalytics, loaders, analytics } = useCallLog();

  const onSubmit = async (e) => {
    e.preventDefault();
    const data = {
      from: from,
      to: to,
    };
    getCallLogAnalytics(data);
  };

  useEffect(() => {
    const data = {
      from: from,
      to: to,
    };
    getCallLogAnalytics(data);
  }, []);

  const submitForm = () => {
    return from.length > 0 && to.length > 0;
  };

  return (
    <div className="container-fluid pt-5">
      <form className="form-inline mt-4 inner-addon d-flex" onSubmit={onSubmit}>
        <div className="form-group mx-2">
          <label className="mt-2 p-0 copy-font">From: </label>
          <CustomInput
            classname="form-control mr-2"
            name="from"
            value={from}
            onChange={(e) => setFrom(e.target.value)}
            type="date"
          />
        </div>

        <div className="form-group mx-2">
          <label className="mt-2 p-0 copy-font">To: </label>
          <CustomInput
            classname="form-control mr-2"
            name="to"
            value={to}
            onChange={(e) => setTo(e.target.value)}
            type="date"
          />
        </div>

        <div className="mb-3">
          {loaders ? (
            <button className="btn btn-green" style={{ height: '39px' }} disabled>
              Searching
            </button>
          ) : (
            <button className="btn btn-green" style={{ height: '39px' }} disabled={!submitForm()}>
              Search
            </button>
          )}
        </div>
      </form>
      {/* <div className="col-12 d-flex mt-5">
        <CustomCsvDownload
          data={tableData || []}
          headers={bulkCustomerDetailsHeaders}
          filename={filename(`Bulk_Customer_Details`)}
          classname="btn btn-green shadow-md"
          textcolor="white"
          text="Download Data"
        />
      </div> */}
      <CallLogAnalyticsTable loaders={loaders} analytics={analytics} from={from} to={to} s/>
    </div>
  );
};

export default CallLogAnalyticsBody;
