export const SCORE_AGENT = 'SCORE_AGENT';
export const GET_USER_SCORE = 'GET_USER_SCORE';
export const GET_MONTHLY_EXPIRED_CUSTOMERS = 'GET_MONTHLY_EXPIRED_CUSTOMERS';
export const GET_EXPIRED_CUSTOMERS = 'GET_EXPIRED_CUSTOMERS';
export const GET_CUSTOMER_USAGE = 'GET_CUSTOMER_USAGE';
export const FETCH_PAST_EXPIRED = 'FETCH_PAST_EXPIRED';
export const GET_ABOUT_TO_EXPIRE = 'GET_ABOUT_TO_EXPIRE';
export const START_FETCHING_CUSTOMER_DETAILS = 'START_FETCHING_CUSTOMER_DETAILS';
export const STOP_FETCHING_CUSTOMER_DETAILS = 'STOP_FETCHING_CUSTOMER_DETAILS';
export const FETCH_CUSTOMERS_DETAILS = 'FETCH_CUSTOMERS_DETAILS';
export const GET_CUSTOMER_SESSION_DETAILS = 'GET_CUSTOMER_SESSION_DETAILS';
export const START_GETTING_CUSTOMER_SESSION_DETAILS = 'START_GETTING_CUSTOMER_SESSION_DETAILS';
export const STOP_GETTING_CUSTOMER_SESSION_DETAILS = 'STOP_GETTING_CUSTOMER_SESSION_DETAILS';

export const GET_CUSTOMER_USAGE_WITH_DATE = 'GET_CUSTOMER_USAGE_WITH_DATE';
export const START_GETTING_CUSTOMER_USAGE_WITH_DATE = 'START_GETTING_CUSTOMER_USAGE_WITH_DATE';
export const STOP_GETTING_CUSTOMER_USAGE_WITH_DATE = 'STOP_GETTING_CUSTOMER_USAGE_WITH_DATE';
export const START_ADD_CALL_LOG = 'START_ADD_CALL_LOG';
export const STOP_ADD_CALL_LOG = 'STOP_ADD_CALL_LOG';
export const GET_CALL_LOGS = 'GET_CALL_LOGS';
export const REFETCH_CALL_LOGS = 'REFETCH_CALL_LOGS';
export const GET_SCHEDULE = 'GET_SCHEDULE';
