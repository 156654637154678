/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import useGeniatech from '../../../../../../../hooks/shared/geniatech/useGeniatech';
import GeniatechContext from '../../../../../../../context/GeniatechContext';
import { NETWORK_URL } from '../api/NetworkUrl';

const NetworkProvider = ({ children }) => {
  const { data, loading, pages, search, searchData, onChange, searchStatus, reset, fetched } = useGeniatech(
    {
      action: 'get_networks',
      offset: '0',
      limit: '100',
    },
    `${NETWORK_URL}`,
  );
  return (
    <GeniatechContext.Provider
      value={{
        data,
        loading,
        search,
        searchData,
        pages,
        onChange,
        searchStatus,
        reset,
        fetched,
      }}
    >
      {children}
    </GeniatechContext.Provider>
  );
};
export default NetworkProvider;
