/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-case-declarations */
import React, { useContext, useState, useEffect } from 'react';
import AppContext from '../../../../context/shared/AppContext';
import PageDataCount from '../device-retrieval/PageDataCount';
// import ReactPaginate from 'react-paginate';
import RetrievalTable from './RetrievalTable';

const RetrievalBody = () => {
  const { data, pages, loading, onSubmit } = useContext(AppContext);

  // const [from, setFrom] = useState('');
  // const [to, setTo] = useState('');

  const detailsState = {
    status: 'escalated',
    option: '',
    optionValue: '',
  };
  const [details, setDetails] = useState(detailsState);
  const { status, option, optionValue } = details;
  const currentPage = 1;

  useEffect(() => {
    onSubmit({ status }, currentPage);
  }, []);

  // const filterByDate = (e: { preventDefault: () => void }) => {
  //   e.preventDefault();
  //   const data = { from, to };
  //   searchRetrievals(data);
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };

  const searchForUser = (e) => {
    e.preventDefault();
    switch (option) {
      case 'email':
        const emailSearch = { status, email: optionValue.trim() };
        return onSubmit(emailSearch, currentPage);

      case 'customerName':
        const nameSearch = { status, name: optionValue.trim() };
        return onSubmit(nameSearch, currentPage);

      case 'phone':
        const phoneSearch = { status, phone: optionValue.trim() };
        return onSubmit(phoneSearch, currentPage);

      case 'mac':
        const macSearch = { status, mac: optionValue.trim() };
        return onSubmit(macSearch, currentPage);

      case 'customerNumber':
        const custNumSearch = { status, customer_number: optionValue.trim() };
        return onSubmit(custNumSearch, currentPage);

      default:
        return onSubmit({ status }, currentPage);
    }
  };

  return (
    <div className="container pt-5">
      <div className="row mt-5">
        <form onSubmit={searchForUser} className="form-inline mb-2 ml-3 col-12">
          <div className="form-group">
            <select name="option" onChange={handleChange} className="form-control">
              <option value="">--Choose Search Field--</option>
              <option value="email">Email</option>
              <option value="mac">MAC ID</option>
              <option value="phone">Phone</option>
              <option value="customerName">Name</option>
              <option value="customerNumber">Customer Number</option>
            </select>
          </div>
          <div className="form-group ml-2">
            <input
              className="form-control"
              type="text"
              placeholder={`input search ${option}`}
              style={{ width: '230px' }}
              name="optionValue"
              value={optionValue}
              onChange={handleChange}
            />
          </div>
          {/* <div className="form-group d-flex ml-2">
            <small className="mr-1">From: </small>
            <input
              type="date"
              className="form-control"
              name="from"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
            />
          </div>
          <div className="form-group d-flex ml-2">
            <small className="mr-1">To: </small>
            <input type="date" className="form-control" name="to" value={to} onChange={(e) => setTo(e.target.value)} />
          </div> */}
          <div className="form-group ml-2">
            <button
              type="submit"
              className="btn btn-green"
              // onClick={filterByDate}
            >
              Search
            </button>
          </div>
          <div className="p-0 col-12 d-flex justify-content-between">
            <PageDataCount />
          </div>

          {/* <div className="form-group ml-2">
            <button className="btn btn-green" type="button" data-toggle="modal" data-target="#add_item">
              Add Item
            </button>
          </div> */}
        </form>
        {/* <AddRetrievalItem load={isLoading} /> */}
        <RetrievalTable lists={data} load={loading} />

        {/* {pages > 1 && (
          <ReactPaginate
            previousLabel="previous"
            nextLabel="next"
            breakLabel="..."
            breakClassName="break-me"
            pageCount={pages}
            marginPagesDisplayed={3}
            pageRangeDisplayed={4}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            activeClassName="active"
          />
        )} */}
      </div>
    </div>
  );
};

export default RetrievalBody;
