import React from 'react';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Skeleton from '@mui/material/Skeleton';
import ChartTypeSelect from '../components/ChartTypeSelect';
import LineCharts from './LineCharts';
import BarCharts from './BarChart';
import AreaCharts from './AreaChart';

export default function ChartsContainer({ data, isLoading }) {
  const [chartType, setChartType] = React.useState('Bar-Chart');
  const [isTotalActiveSubsChecked, setIsTotalActiveSubsChecked] = React.useState(false);
  const [isTotalExpiredChecked, setIsTotalExpiredChecked] = React.useState(false);

  const handleToggleTotalActiveSubs = (e) => {
    const inputElement = e.target;

    // console.log(inputElement.checked);
    const isChecked = inputElement.checked;

    setIsTotalActiveSubsChecked(isChecked);
  };

  const handleToggleTotalExpired = (e) => {
    const inputElement = e.target;

    console.log(inputElement.checked);
    const isChecked = inputElement.checked;
    setIsTotalExpiredChecked(isChecked);
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flexStart" sx={{ mt: 10 }}>
      {isLoading ? (
        <>
          <Skeleton width={300} height={100} />
        </>
      ) : (
        <ChartTypeSelect setChartType={setChartType} chartType={chartType} />
      )}

      <FormGroup row aria-label="form group position">
        {isLoading ? (
          <Skeleton width={150} sx={{ mr: 5 }} />
        ) : (
          <FormControlLabel disabled control={<Switch defaultChecked size="small" />} label="Installation Growth" />
        )}
        {isLoading ? (
          <Skeleton width={150} sx={{ mx: 5 }} />
        ) : (
          <FormControlLabel
            control={<Switch onChange={handleToggleTotalActiveSubs} size="small" />}
            label="Total active subs"
          />
        )}

        {isLoading ? (
          <Skeleton width={150} sx={{ mx: 5 }} />
        ) : (
          <FormControlLabel
            control={<Switch onChange={handleToggleTotalExpired} size="small" />}
            label="Total Expired"
          />
        )}
      </FormGroup>

      {chartType === 'Line-Chart' ? (
        <LineCharts
          data={data}
          isLoading={isLoading}
          isTotalActiveSubsChecked={isTotalActiveSubsChecked}
          isTotalExpiredChecked={isTotalExpiredChecked}
        />
      ) : chartType === 'Bar-Chart' ? (
        <BarCharts
          data={data}
          isLoading={isLoading}
          isTotalActiveSubsChecked={isTotalActiveSubsChecked}
          isTotalExpiredChecked={isTotalExpiredChecked}
        />
      ) : (
        chartType === 'Area-Chart' && (
          <AreaCharts
            data={data}
            isLoading={isLoading}
            isTotalActiveSubsChecked={isTotalActiveSubsChecked}
            isTotalExpiredChecked={isTotalExpiredChecked}
          />
        )
      )}
    </Box>
  );
}
