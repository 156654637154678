import * as load from '../../../constants/types/shared/LoadingTypes';
import { GET_ZONES } from '../../../constants/types/shared/zones/ZonesTypes';
import * as types from '../../../constants/types/sales/installations/InstallationsTypes';

export const installationsReducer = (state, action) => {
  const { payload, type } = action;
  switch (type) {
    case load.LOADING_STARTS:
      return {
        ...state,
        isLoading: true,
      };

    case load.LOADING_STOPS:
      return {
        ...state,
        isLoading: false,
      };

    case types.GET_INSTALLATIONS:
      return {
        ...state,
        count: payload,
      };

    case types.GET_ALL_INSTALLATIONS:
      return {
        ...state,
        installations: payload.installations,
        pages: payload.pages,
        isLoading: false,
      };

    case GET_ZONES:
      return {
        ...state,
        zones: payload,
      };

    case load.FETCHING_STARTS:
      return {
        ...state,
        isFetching: true,
      };

    case load.FETCHING_STOPS:
      return {
        ...state,
        isFetching: false,
      };

    case load.FETCHING_DIMENSION_STARTS:
      return {
        ...state,
        fetching: true,
      };

    case load.FETCHING_DIMENSION_STOPS:
      return {
        ...state,
        fetching: false,
      };

    case load.FETCHING_DIMENSION:
      return {
        ...state,
        dimension2: 'EMPTY',
        fetching: false,
      };

    case types.GET_DIMENSION1:
      return {
        ...state,
        dimension1: payload,
      };

    case types.GET_DIMENSION2:
      return {
        ...state,
        dimension2: payload,
        fetching: false,
      };

    case types.GET_CALL_STATION:
      return {
        ...state,
        calledstation: payload.calledstation,
        radacct: payload.radacct,
        isFetching: false,
      };

    case types.GET_ALL_CALL_STATIONS:
      return {
        ...state,
        allCalledStations: payload,
        isFetching: false,
      };

    case load.CHECKING_MAC_STARTS:
      return {
        ...state,
        isChecking: true,
      };

    case load.CHECKING_MAC_STOPS:
      return {
        ...state,
        isChecking: false,
      };

    case types.CHECK_MAC_ID:
      return {
        ...state,
        isValid: payload.isValid,
        validMAC: payload.validMAC,
        isChecking: false,
      };

    default:
      return state;
  }
};

export default installationsReducer;
