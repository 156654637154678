import React from 'react';
import NocNavBar from '../../../../../../layouts/noc/NocNavBar';
import ActiveCustomersBody from './ActiveCustomersBody';

const ActiveCustomers = () => {
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <NocNavBar currentPage="Active Customers" />

        <ActiveCustomersBody />
      </main>
    </div>
  );
};

export default ActiveCustomers;
