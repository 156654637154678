/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
// import { SubBasestationProps } from './types/SubBasestationProps';
import helpers from '../../../../../../utils/helpers';

const ViewBasestation = ({ view }) => {
  return (
    <div id="view_access-point" className="modal fade mt-5" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title my-2">Details</h4>
            <button type="button" className="mr-2 close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div className="modal-body detail p-0">
            <div className="row">
              <div className="col-lg-12 d-flex">
                <div className="col-lg-5 detail-label1">
                  <label className="mt-1">Name</label>
                  <label className="mt-1">Latitude</label>
                  <label className="mt-1">Longitude</label>
                </div>
                <div className="col-lg-7 detail-result1 item-font">
                  <p className="mt-4 mb-2">{view.name}</p>
                  <p className="mb-2">{view.latitude}</p>
                  <p className="mb-2">{view.longitude}</p>
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-5 detail-label3">
                  <label>Address</label>
                  <label>Dimension</label>
                  <label>Date Of Commissioning</label>
                </div>
                <div className="col-lg-7 detail-result2 item-font">
                  <p className="mt-4 mb-2">{view.address}</p>
                  <p className="mb-2">{view.dimension}</p>
                  <p className="mb-2">{helpers.formatDateAndTime(view.date_of_commissioning)}</p>
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-5 detail-label1">
                  <label className="mt-1">Installer</label>
                  <label className="mt-1">No of Subscribers</label>
                  <label className="mt-1">Setup Amount</label>
                </div>
                <div className="col-lg-7 detail-result1 item-font">
                  <p className="mt-4 mb-2">{view.installer}</p>
                  <p className="mb-2">{view.no_of_subscribers}</p>
                  <p className="mb-2">{view.setup_amount}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewBasestation;
