/* eslint-disable @typescript-eslint/camelcase */
import { useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import { stringify } from 'qs';
import { toast } from 'react-toastify';
import { LOADING_STARTS, LOADING_STOPS } from '../../../../../../constants/types/shared/LoadingTypes';
import { GET_DATA } from '../../../../../../constants/types/shared/CustomTypes';
import { LEGACY_GENIATECH_URL } from '../../../../../../services/api/shared/installations/InstallationsUrl';
import openAndBlockedUsersReducer from '../reducer/openAndBlockedUsersReducer';
import legacy from '../../../../../../services/api/axios';

const useOpenAndBlockedUsers = () => {
  const [{ data, loading, pageCount }, dispatch] = useReducer(openAndBlockedUsersReducer, {
    data: [],
    loading: false,
    pageCount: 0,
  });
  const [searchDetails, setSearchDetails] = useState({ searchdetails: '' })
  const [status, setStatus] = useState('');
  const [blockingOrUnblocking, setBlockingOrUnblocking] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const search = async (params) => {
    dispatch({
      type: LOADING_STARTS,
    });
    try {
      return await axios.post(`${LEGACY_GENIATECH_URL}`, params);
    } catch (e) {
      return e;
    }
  };
  const searchStatus = (params) => {
    search(params)
      .then((response) => {
        if (response.data.status) {
          dispatch({
            type: GET_DATA,
            payload: response.data,
          });
        }
      })
      .catch(() => {
        dispatch({ type: LOADING_STOPS });
      });
  };
  const onChange = (e) => {
    setStatus(e.target.value);
  };

  const onChangeSetByDetails = (e) => {

    setSearchDetails({ ...searchDetails, [e.target.name]: e.target.value, })
  }

  const searchByEmail = (params) => {
    search(params)
      .then((response) => {
        if (response.data.status) {
          dispatch({
            type: GET_DATA,
            payload: response.data,
          });
        }
        dispatch({
          type: GET_DATA,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({ type: LOADING_STOPS });
        toast(err);
      });

  }

  const handlePageChange = async (pageNumber) => {
    const selected = pageNumber.selected + 1;
    setCurrentPage(pageNumber.selected + 1);

    search(
      stringify({
        action: 'wificall_users_blocked_status',
        no_of_records_per_page: '500',
        pageno: selected,
        status,
      }),
    )
      .then((response) => {
        if (response.data.status) {
          dispatch({
            type: GET_DATA,
            payload: response.data,
          });
        }
      })
      .catch(() => {
        dispatch({ type: LOADING_STOPS });
      });
  };
  const blockOrUnblockUser = async (params) => {
    setBlockingOrUnblocking(true);
    toast('Carrying out operation');
    legacy
      .post('/index.php', params)
      .then((response) => {
        if (response.data.status) {
          setBlockingOrUnblocking(false);
          toast(response.data.message);
        } else {
          setBlockingOrUnblocking(false);
          toast('Unable to carry out action');
        }
      })
      .catch((err) => {
        setBlockingOrUnblocking(false);
        toast('Something went wrong');
      });
  };
  useEffect(() => {
    async function getData() {
      dispatch({
        type: LOADING_STARTS,
      });
      try {
        return await axios.post(
          `${LEGACY_GENIATECH_URL}`,
          stringify({
            action: 'wificall_users_blocked_status',
            no_of_records_per_page: '500',
            pageno: '1',
            status: 'open',
          }),
        );
      } catch (e) {
        return e;
      }
    }
    getData()
      .then((response) => {
        if (response.data.status) {
          dispatch({
            type: GET_DATA,
            payload: response.data,
          });
        }
      })
      .catch(() => dispatch({ type: LOADING_STOPS }));
  }, []);
  return {
    data,
    loading,
    pageCount,
    handlePageChange,
    status,
    onChange,
    searchStatus,
    currentPage,
    blockOrUnblockUser,
    blockingOrUnblocking,
    searchByEmail,
    onChangeSetByDetails,
    searchDetails
  };
};

export default useOpenAndBlockedUsers;
