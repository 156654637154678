import React from 'react';

// eslint-disable-next-line import/prefer-default-export

export const displayCountryDropdown = (o, change) => {
  if (o === 'name') {
    return (
      <div className="input-group mb-3 mr-3">
        <select name="country" onChange={change} className="form-control mr-auto empty mt-1">
          <option value="">Choose country to search from</option>
          <option value="Nigeria">Nigeria</option>
          <option value="Ghana">Ghana</option>
          <option value="cv">Cote d'Ivoire</option>
        </select>
      </div>
    );
  }
  return null;
};

export const displayIfTypeRouterTag = (o, r, change) => {
  if (o === 'routertag') {
    return (
      <div className="input-group mb-3 mr-3">
        <input
          className="form-control mr-auto empty pl-5"
          type="text"
          placeholder="router tag"
          name="router"
          value={r}
          onChange={change}
        />
        <span className="fa fa-search" />
      </div>
    );
  }

  return null;
};
