import React, { Component } from 'react';
import '../../../../../styles/style.css';
import '../../../../../styles/inventory.css';
import { Redirect, Link } from 'react-router-dom';
import axios from 'axios';
import AddInventory from '../../super-admin/inventories/AddInventory';
import InventoriesTable from '../../super-admin/inventories/InventoriesTable';
import { API_URL } from '../../../../../utils/config';
import AdminDeptLogo from '../../../../layouts/admin-department/AdminDeptLogo';
import Logout from '../../../shared/auth/Logout';
import AdminDepartmentSideBar from '../../../../layouts/admin-department/AdminDepartmentSideBar';

class AdminDeptInventories extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inventories: [],
      inventoryId: {},
      activePage: 1,
      itemsCountPerPage: 1,
      totalItemsCount: 1,
      move: false,
      user: {},
    };
  }

  componentWillMount() {
    this.fetchLoggedOnUser();
    this.getInventories();
  }

  getInventories() {
    this.setState({
      loading: true,
    });

    axios({
      method: 'get',
      url: `${API_URL}/inventory`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      }
    })
      .then((response) => {
        this.setState({
          inventories: response.data,
          isLoading: false,
          filtered: response.data,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          error,
          isLoading: false,
        });
      });
  }

  componentWillReceiveProps() {
    this.fetchLoggedOnUser();
  }

  fetchLoggedOnUser() {
    axios({
      method: 'get',
      url: 'http://34.207.108.32:5000/api/v1/employee/loggedOnEmployee',
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        this.setState({
          user: data.data,
        });

        // console.log(data.data);
      })
      .catch((err) => {});
  }

  render() {
    const { user, filtered } = this.state;
    const { firstName, lastName } = user;

    return (
      <div className="main-wrapper">
        <header className="topbar shade bg-white fixed-top">
          <nav className="navbar navbar-expand-lg navbar-light top-navbar fixed-top">
            <AdminDeptLogo />
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse mt-3" id="navbarText">
              <ul className="navbar-nav">
                <li className="nav-item active">
                  <Link className="nav-link" to="#">
                    <h3>
                      Inventory<span className="sr-only">(current)</span>
                    </h3>
                  </Link>
                </li>
              </ul>
              <span className="navbar-text">
                <h6>
                  <i>
                    {firstName} {lastName}{' '}
                  </i>
                  <Logout />
                </h6>
              </span>
            </div>
          </nav>
        </header>

        <div className="all-sides col-lg-12 col-md-12">
          <AdminDepartmentSideBar />

          <div className="page-wrapper col-lg-10 col-md-10">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 col-md-12 mt-3 mb-3 d-flex">
                  <form className="form-inline inner-addon left-addon" action="javascript:void(0)">
                    <input
                      className="form-control mr-sm-2 empty mt-1"
                      id="iconified"
                      type="text"
                      placeholder="Search"
                    />
                    <span className="fa fa-search" />
                  </form>

                  <div>
                    <button className="btn btn-green" type="submit" data-toggle="modal" data-target="#add-employee">
                      Add Inventory
                    </button>
                  </div>

                  <div
                    id="add-employee"
                    className="modal fade in"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    aria-hidden="true"
                    style={{ overflow: 'auto' }}
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title text-center" id="myModalLabel">
                            Add Inventory
                          </h4>
                          <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                            ×
                          </button>
                        </div>
                        <AddInventory />

                        <div className="modal-footer">
                          <Link to="#success2" data-toggle="modal" data-dismiss="modal" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  id="success2"
                  className="modal fade in"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="myModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-body text-center">
                        <span className="fa fa-check-circle" />
                        <p>
                          <b>Inventory Added Successfully!</b>
                        </p>
                        <Link to="#add-employee" data-toggle="modal" data-dismiss="modal">
                          <button
                            type="submit"
                            className="btn btn-green waves-effect"
                            data-toggle="modal"
                            data-target="#add-employee"
                          >
                            Add Another
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card x-panel">
                  <div className="table-responsive content col-lg-12 col-md-12 col-sm-12">
                    <table className="table col-lg-12 col-md-12 col-sm-12">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Manufacturer</th>
                          <th>Model</th>
                          <th>Vendor</th>
                          <th>Serial No</th>
                          <th>Warranty</th>
                          <th>Firmware</th>
                          <th>Date</th>
                          <th>Employee</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <InventoriesTable inventories={filtered} load={this.state.loading} />
                    </table>
                  </div>
                </div>

                {/* <AdminPaginate/> */}

                {/*                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={450}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange}
                        /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminDeptInventories;
