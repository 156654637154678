/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, SetStateAction, useContext } from 'react';
import edit from '../../../../../../assets/img/icons/edit.png';
import loader from '../../../../../../assets/img/icons/TizetiLoaderColored.gif';
import helpers from '../../../../../../utils/helpers';
import view from '../../../../../../assets/img/icons/view.png';
import ViewSnmp from './ViewSnmp';
import AppContext from '../../../../../../context/shared/AppContext';
// import { SnmpProps } from './interfaces/SnmpProps';

const SnmpTable = () => {
  const { loading: load, data } = useContext(AppContext);

  const [one, setOne] = useState({
    created_at: '',
    id: '',
    ip_address: '',
    device_name: '',
    ssid: '',
    cacti: '',
  });
  const onClick = (params) => {
    setOne(params);
  };
  const tableData =
    data && data.length
      ? data.map((row, i) => (
          <tr className="text-nowrap" key={row.id}>
            <td>{i + 1}</td>
            <td>{row.device_name}</td>
            <td>{row.ip_address}</td>
            <td>{row.ssid}</td>
            <td>{row.cacti}</td>

            <td>{helpers.formatDateAndTime(row.created_at)}</td>
            <td>
              <div className="text-center">
                <button
                  type="submit"
                  className="mr-2"
                  data-toggle="modal"
                  data-placement="right"
                  data-target="#view_access-point"
                  onClick={() => onClick(row)}
                >
                  <span className="view">
                    <img src={view} data-toggle="tooltip" alt="" data-placement="top" title="View" />
                  </span>
                </button>
                <button
                  type="submit"
                  className="mr-2"
                  data-toggle="modal"
                  data-placement="right"
                  data-target="#update_item"
                >
                  <span className="view">
                    <img src={edit} data-toggle="tooltip" alt="" data-placement="top" title="Edit" />
                  </span>
                </button>
              </div>
              <ViewSnmp view={one} />
              {/* <EditRetrievalItem OneItem={one} /> */}
            </td>
          </tr>
        ))
      : 'no snmp';
  return (
    <div className="card x-panel mt-2 px-0">
      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Device</th>
              <th className="text-nowrap">IP</th>
              <th>SSID</th>
              <th>Cacti</th>
              <th>Date Created</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {load ? (
              <tr>
                {/* <td colSpan="9" className="text-center"> */}
                <img src={loader} alt="loading gif" />
                {/* </td> */}
              </tr>
            ) : (
              tableData
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SnmpTable;
