import { API_URL } from '../../../../utils/config';

export const SEARCH_CUSTOMER_USAGE_WITH_EMAIL_URL = `${API_URL}/getUserInformation`;
export const SEARCH_CUSTOMER_USAGE_WITH_PHONE_URL = `${API_URL}/getUserInfoWithPhoneorMac`;
export const SEARCH_CUSTOMER_USAGE_WITH_NAME_URL = `${API_URL}/geniatech/getuserbyname`;
export const SEARCH_CUSTOMER_USAGE_WITH_MAC_URL = `${API_URL}/getUserauth`;
export const CHANGE_CUSTOMER_MAC_WITH_LEGACY_URL = `https://legacy.tizeti.com/geniatechapi/index.php`;
export const CLEAN_CUSTOMER_RADIO_URL = `https://legacy.tizeti.com/geniatechapi/index.php`;
export const GET_UNVERIFIED_CUSTOMERS_URL = `${API_URL}/users/unverified`;

export const GET_CUSTOMERS_USAGE_STATISTICS_URL = `https://legacy.tizeti.com/geniatechapi/index.php`;
