/* eslint-disable import/prefer-default-export */
import moment from 'moment';

export const downloadedFileName = (file) => {
  return `${file} ${moment(new Date()).format('DD-MMM-YYYY hh-mm A')}.csv`;
};
export const showContentForGivenRole = (role, given, param) => {
  if (role === given) {
    return param;
  }
  return null;
};
export const showDifferentContentForDepartments = (department, given, param, params) => {
  if (department === given) {
    return param;
  }
  return params;
};
