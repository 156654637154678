import React from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export default function VerticalBarChart({ getZones, ActiveInactiveData, ActiveData, InActiveData }) {
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    if (getZones.length > 0 && InActiveData.length && ActiveInactiveData.length > 0 && ActiveData.length > 0) {
      const refinedData = getZones.map((state, index) => ({
        name: state.name,
        'Active & Expired': ActiveInactiveData[index] && ActiveInactiveData[index],
        Active: typeof ActiveData[index] === 'number' ? ActiveData[index] : ActiveData[index].length,
        Expired: typeof InActiveData[index] === 'number' ? InActiveData[index] : InActiveData[index].length,
      }));

      if (refinedData.length > 0) {
        const filterNonZeroValues = refinedData.filter((val) => val.Active > 0);
        setData(filterNonZeroValues);
      }
    }
  }, [InActiveData.length]);

  function generateRandomWidth() {
    return Math.floor(Math.random() * (650 - 100 + 1)) + 100;
  }

  const numberOfSkeletons = 12; // Change this to the desired number of Skeletons

  const skeletonElements = Array.from({ length: numberOfSkeletons }, (_, index) => (
    <Skeleton key={index} variant="rectangular" width={generateRandomWidth()} height={18} animation="wave" />
  ));

  return (
    <Box sx={{}}>
      {data.length === 0 ? (
        <Box display="flex" flexDirection="column" gap={3} width={700} sx={{ mt: 3 }}>
          {skeletonElements}
        </Box>
      ) : (
        <Box sx={{ width: { xs: '90vw', md: 800 }, overflowX: 'auto' }}>
          <div style={{ width: '100%', overflowX: 'scroll' }}>
            <BarChart
              width={2200}
              height={500}
              data={data}
              margin={{
                top: 5,
                right: 10,
                left: 0,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="Active & Expired" fill="#413ea0" />
              <Bar dataKey="Active" fill="#84d8d4" />
              <Bar dataKey="Expired" fill="#ff7300" />
            </BarChart>
          </div>
        </Box>
      )}
    </Box>
  );
}
