/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import caution from '../../../../../assets/img/icons/Caution.png';
import { useCleanLTE } from '../../../../../hooks/field-support/clean-imsi/useCleanLTE';
import { TizetiGIFLogoTable } from '../../../../../utils/loader/loadingHelper';
import HandleCluster from '../../field-support/cluster-installation/HandleCluster';

const DeleteLTEModal = ({ lists }) => {
  const { cleanLTE, isLoading } = useCleanLTE();
  console.log(lists);

  const handleLte = (lists) => {
    const data = {
      imsi: lists.imsi,
    };
    cleanLTE(data);
  };

  //   if (lists === null) {
  //     return <TizetiGIFLogoTable></TizetiGIFLogoTable>;
  //   }
  return (
    <>
      {lists === null ? null : (
        <div id="deletelte" className="modal fade" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content mt-5 py-4">
              <div className="text-center">
                <img src={caution} alt="" width="60px" />
              </div>
              <div className="modal-body p-3">
                <form className="text-center">
                  <h5>
                    Are you sure you want to delete{' '}
                    <span className="text-uppercase">{lists.user.firstName + '' + lists.user.lastName}</span>?
                  </h5>
                  <div className="mt-3">
                    {isLoading ? (
                      <button type="submit" className="btn btn-danger" disabled>
                        Deleting
                      </button>
                    ) : (
                      <button type="submit" className="btn btn-danger" onClick={() => handleLte(lists)}>
                        Delete
                      </button>
                    )}
                  </div>
                  {/* {lists === null ? (
              <h5>This State Has No Inital Target</h5>
            ) : (
              <h5>
                Are you sure you want to delete{' '}
                <span className="text-uppercase">{lists.user.firstName + '' + lists.user.lastName}</span>?
              </h5>
            )}
            <div className="mt-3">
              <button type="submit" className="btn btn-danger" onClick={() => cleanLTE({ imsi: lists.imsi })}>
                Delete
              </button>
              {lists === null && (
                <button type="button" className="btn btn-secondary mr-2" data-dismiss="modal">
                  Cancel
                </button>
              )} */}
                  {/* </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

DeleteLTEModal.propTypes = {
  lists: PropTypes.objectOf.isRequired,
};

export default DeleteLTEModal;
