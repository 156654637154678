import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../../../../../styles/style.css';
import '../../../../../styles/sales_overview.css';
import axios from 'axios';
import toastr from 'toastr';
import AddServices from './AddServices';
import EditServices from './EditService';
import Mailblast from '../../../../../assets/img/icons/mailblast.svg';
import { API_URL, RESEARCH_AND_DEVELOPMENT } from '../../../../../utils/config';
import ResearchLogo from '../../../../layouts/research-and-development/ResearchLogo';
import Logout from '../../../shared/auth/Logout';
import ResearchAndDevelopmentSideBar from '../../../../layouts/research-and-development/ResearchAndDevelopmentSideBar';
import edit from '../../../../../assets/img/icons/edit.png';
import { SUPER_ADMIN } from '../../../../../utils/departments/helpers';

class ResearchScheduler extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      role: '',
    };
  }

  componentDidMount() {
    this.getUserDetails();
  }

  componentWillReceiveProps() {
    this.fetchLoggedOnUser();
  }

  componentWillMount() {
    this.fetchLoggedOnUser();
  }

  fetchLoggedOnUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employeeReg/logged`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        if (data.data) {
          this.setState({
            role: data.data.role_id,
          });
        }

        if (data.data.department_id !== `${RESEARCH_AND_DEVELOPMENT}` &&
          data.data.department_id !== `${SUPER_ADMIN}`) {
          this.props.history.push('/staff/login');

          toastr.info('You are not allowed to view this page');
        }
      })
      .catch((err) => {});
  }

  getUserDetails() {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };

    axios
      .get(`${API_URL}/employee/loggedOnEmployee`, config)
      .then((res) => {
        if (res.data) {
          this.setState({
            firstName: res.data.firstName,
          });
        }
      })
      .catch((err) => {});
  }

  render() {
    const { firstName, role } = this.state;

    return (
      <div className="main-wrapper">
        <header className="topbar shade bg-white fixed-top">
          <nav className="navbar navbar-expand-lg navbar-light top-navbar fixed-top">
            {/* <Link className="navbar-brand" to={"/research-and-development"}> */}
            {/* <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971047/Tizeti-Logo.png" */}
            {/* alt="homepage"/> */}
            {/* </Link> */}
            <ResearchLogo />
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse mt-3" id="navbarText">
              <ul className="navbar-nav" style={{ marginRight: 'auto' }}>
                <li className="nav-item active">
                  <Link className="nav-link" to="#">
                    <h3>
                      Scheduler options<span className="sr-only">(current)</span>
                    </h3>
                  </Link>
                </li>
              </ul>
              <span className="navbar-text">
                <div className="d-flex">
                  {role > '2' ? (
                    <Link
                      to="/research-and-development/mail-blast"
                      className="d-flex"
                      style={{ color: 'gray', textDecoration: 'none' }}
                    >
                      <img src={Mailblast} className="logout mr-2 mb-2" alt="" />
                      <span>
                        <h6 className="mr-2">
                          <strong>MailBlast</strong>
                        </h6>
                      </span>
                    </Link>
                  ) : null}
                  <h4 className="admin">
                    {firstName}
                    <Logout />
                  </h4>
                </div>
              </span>
            </div>
          </nav>
        </header>

        <div className="all-sides col-lg-12 col-md-12 col-sm-12">
          <ResearchAndDevelopmentSideBar />
          <div className="page-wrapper col-lg-10 col-md-10 col-sm-12">
            <div className="row">
              <div className="container-fluid col-lg-12">
                <div className="card-body col-lg-12" style={{ padding: '17px' }}>
                  <div className="form-inline" action="javascript:void(0)">
                    <div className="col-12 mb-3 p-0 d-flex">
                      <form className="col-8 form-inline d-flex inner-addon left-addon mr-auto">
                        <input className="form-control mr-sm-2 empty mt-1 w-100" type="text" placeholder="Search..." />
                        <span className="fa fa-search" />
                      </form>

                      <div>
                        <button className="btn btn-green m-4 p-2" data-target="#service" data-toggle="modal">
                          Add Service
                        </button>
                        <AddServices />
                      </div>
                    </div>

                    <div className="col-12 m-0 p-0">
                      <div className="card x-panel w-100 ml-0">
                        <div className="table-responsive content">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Service</th>
                                <th>Location</th>
                                <th className="text-center">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>1</td>
                                <td>Unlimited Internet</td>
                                <td>High Speed Internet Installation</td>
                                <td>
                                  <div className="text-center">
                                    <button
                                      type="submit"
                                      data-toggle="modal"
                                      data-target="#edit-service"
                                      style={{
                                        border: '0',
                                        backgroundColor: 'white',
                                      }}
                                    >
                                      <span className="view">
                                        <img
                                          src={edit}
                                          alt=""
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Edit"
                                        />
                                      </span>
                                    </button>
                                    <EditServices />
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td>2</td>
                                <td>Support-Callout Island (Lekki 1)</td>
                                <td>Tech Support Island 1</td>
                                <td>
                                  <div className="text-center">
                                    <button
                                      type="submit"
                                      data-toggle="modal"
                                      data-target="#updateRequest"
                                      style={{
                                        border: '0',
                                        backgroundColor: 'white',
                                      }}
                                    >
                                      <span className="view">
                                        <img
                                          src={edit}
                                          alt=""
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Edit"
                                        />
                                      </span>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResearchScheduler;
