/* eslint-disable import/prefer-default-export */
import { useReducer } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import qs from 'qs';
import { FILTER_LOGIN_CODE_URL } from '../../../../../services/api/wificall/CustomersUrl';
import * as types from '../../../../../constants/types/wificall/WificallTypes';
import * as load from '../../../../../constants/types/shared/LoadingTypes';
import { loginLogsReducer } from '../../../../../reducers/wificall/customers/loginLogsReducer';

export const useLoginLogs = () => {
  const [{ users, pages, count, isLoading }, dispatch] = useReducer(loginLogsReducer, {
    users: [],
    count: 1,
    pages: 1,
    isLoading: false,
  });

  const getLoginLogByDate = async (data, page) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(`${FILTER_LOGIN_CODE_URL}/${page}`, qs.stringify(data), {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_LOGIN_LOGS,
          payload: {
            users: response.data.users,
            pages: response.data.pages,
            count: response.data.count,
          },
        });
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const getLoginLogByPhone = async (data) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(`${FILTER_LOGIN_CODE_URL}`, qs.stringify(data), {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_LOGIN_LOGS,
          payload: {
            users: response.data.rows,
            count: response.data.count,
          },
        });
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  return {
    users,
    pages,
    count,
    isLoading,
    getLoginLogByDate,
    getLoginLogByPhone,
  };
};
