import React, {Component} from 'react';
import {ToastContainer} from "react-toastify";

class EditServices extends Component {
    render() {
        return (
            <div id="edit-service" className="modal fade in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                 aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title text-center" id="myModalLabel">Edit Service</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                        <div className="modal-body">
                            <form className="form-horizontal">
                                <div className="form-group">
                                    <div className="col-lg-12 d-flex mb-3">
                                        <div className="col-lg-6">
                                            <label className="col-lg-12 m-0 p-0 text-justify copy-font">Service</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Service name"
                                                name="service-name"
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                            <label className="col-lg-12 text-justify copy-font m-0 p-0 ">Location</label>
                                            <input
                                                type="text"
                                                min="0"
                                                className="form-control"
                                                placeholder="Service Location"
                                                name="location"
                                            />
                                        </div>
                                    </div>

                                    <div className="text-center mt-4 col-12">
                                        <button className="btn btn-second">Update</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>


                </div>
            </div>
        );
    }
}

export default EditServices;