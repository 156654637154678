import * as types from '../../../constants/types/shared/requests/RequestTypes';
import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';

// eslint-disable-next-line import/prefer-default-export,consistent-return
export const requestsReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.START_CREATE_REQUEST:
      return {
        ...state,
        isLoadingRequest: true,
      };

    case types.STOP_CREATE_REQUEST:
      return {
        ...state,
        isLoadingRequest: false,
      };

    case LOADING_STARTS:
      return {
        ...state,
        isLoading: true,
      };

    case LOADING_STOPS:
      return {
        ...state,
        isLoading: false,
      };

    case types.GET_REQUESTS:
      return {
        ...state,
        requests: payload,
        isLoading: false,
      };

    case types.CREATE_REQUEST:
      return {
        ...state,
        // requests: payload,
        requests: [payload, ...state.requests],
        isLoadingRequest: false,
      };
    // const r = [payload, ...state.requests]
    // console.log(payload);
    // console.log(...state.requests);
    // console.log(r)

    default:
      return state;
  }
};
