/* eslint-disable @typescript-eslint/camelcase */
import axios from 'axios';
import { useEffect, useReducer, useState } from 'react';
import { toast } from 'react-toastify';
import { GET_SUBZONES_URL } from '../../../services/api/shared/installations/SchedulerUrl';
import { useUser } from '../../shared/user/useUser';
import {
  REGISTER_FIELD_SERVICE_ZONE_URL,
  SEARCH_INSTALLATION_FIELD_SERVICE_ZONE_URL,
} from '../../../services/api/field-support/SchedulingUrl';
import {
  FETCHING_STARTS,
  FETCHING_STOPS,
  LOADING_STARTS,
  LOADING_STOPS,
} from '../../../constants/types/shared/LoadingTypes';
import { GET_SCHEDULED, REGISTERED_FIELD_SERVICE_ZONE } from '../../../constants/types/field-support/FieldSupportTypes';
import schedulingReducer from '../../../reducers/field-support/scheduling/schedulingReducer';

// eslint-disable-next-line import/prefer-default-export
export const useScheduling = () => {
  const { profile } = useUser();
  const { id } = profile;

  const initialState = {
    sub_zone_id: '',
    date: '',
  };

  const [zones, setZones] = useState([]);
  const [search, setSearch] = useState(initialState);
  const [register, setRegister] = useState('');
  const [clusters, setClusters] = useState([]);

  const [{ scheduled, fetching, loading }, dispatch] = useReducer(schedulingReducer, {
    scheduled: [],
    loading: false,
    fetching: false,
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setSearch({
      ...search,
      [name]: value,
    });
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setSearch({
      ...search,
      [name]: value,
    });
    if (value === '1') {
      setClusters(['lagos', 'ikeja', 'aba']);
    } else if (value === '2') {
      setClusters(['ogun', 'abeokuta']);
    } else if (value === '10') {
      setClusters(['fct', 'abuja']);
    } else if (value === '11') {
      setClusters(['imo', 'aba']);
    } else if (value === '14') {
      setClusters(['oyo', 'usa']);
    } else if (value === '16') {
      setClusters(['canada', 'toronto']);
      // }else if (value === '7') {

      // }else if (value === '8') {

      // }else if (value === '9') {

      // }elseif (value === '10') {

      // }elseif (value === '11') {

      // }elseif (value === '12') {
    } else {
      setClusters(['choose a cluster']);
    }
  };

  const getZones = async () => {
    try {
      const response = await axios.get(`${GET_SUBZONES_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        setZones(response.data);
      } else {
        toast.info('Unable to get zones');
      }
    } catch (e) {
      if (e) {
        toast.error('Something went wrong!');
      }
    }
  };

  const onSubmit = async (value) => {
    dispatch({
      type: FETCHING_STARTS,
    });
    try {
      const response = await axios.post(`${SEARCH_INSTALLATION_FIELD_SERVICE_ZONE_URL}`, value, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.rows) {
        dispatch({
          type: GET_SCHEDULED,
          payload: response.data.rows,
        });
      } else {
        dispatch({
          type: FETCHING_STOPS,
        });
      }
    } catch (e) {
      if (e) {
        if (e.response.data.message === "you don't belong to any subzone atm. please register with one") {
          dispatch({
            type: FETCHING_STOPS,
          });
          toast.info(e.response.data.message);
        } else {
          dispatch({
            type: FETCHING_STOPS,
          });
          toast.info('Something went wrong!');
        }
      }
    }
  };

  const registerChange = (e) => {
    const { value } = e.target;
    setRegister(value);
  };

  const registerFieldServiceZone = async (params) => {
    const data = {
      sub_zone_id: params,
      employee_id: id,
    };
    dispatch({
      type: LOADING_STARTS,
    });
    try {
      const response = await axios.post(`${REGISTER_FIELD_SERVICE_ZONE_URL}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.id) {
        dispatch({
          type: REGISTERED_FIELD_SERVICE_ZONE,
        });
        toast.success('User registered for field service zone successfully!');
      } else {
        dispatch({
          type: LOADING_STOPS,
        });
        toast.info('Unable to register for field service zone!');
      }
    } catch (e) {
      if (e) {
        toast.error('Something went wrong!');
        dispatch({
          type: LOADING_STOPS,
        });
      }
    }
  };

  useEffect(() => {
    getZones();
  }, []);

  return {
    zones,
    onChange,
    handleChange,
    search,
    clusters,
    onSubmit,
    fetching,
    loading,
    scheduled,
    registerFieldServiceZone,
    register,
    registerChange,
  };
};
