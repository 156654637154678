import React from 'react';
import useEnbSiteIpAllocation from '../hooks/useEnbSiteIpAllocation';
import { EnbSiteIpAllocationContext } from './EnbSiteIpAllocationContext';

const EnbSiteIpAllocationProvider = ({ children }) => {
  const { data, loading, paginateChange, loadingForm, onChange, formValue, onSubmit, pages } = useEnbSiteIpAllocation();
  return (
    <EnbSiteIpAllocationContext.Provider
      value={{
        data,
        loading,
        paginateChange,
        loadingForm,
        onChange,
        formValue,
        onSubmit,
        pages,
      }}
    >
      {children}
    </EnbSiteIpAllocationContext.Provider>
  );
};
export default EnbSiteIpAllocationProvider;
