export const calls = [
  {
    zone: 'oyo',
    night: '6',
    day: '2',
  },
  {
    zone: 'edo',
    night: '0',
    day: '2',
  },
  {
    zone: 'awolowo',
    night: '4',
    day: '8',
  },
  {
    zone: 'ikeja',
    night: '1',
    day: '2',
  },
  {
    zone: 'rivers',
    night: '11',
    day: '2',
  },
  {
    zone: 'ogun',
    night: '10',
    day: '2',
  },
];
