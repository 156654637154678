import React from 'react';
import { useWifiEvent } from '../../../../../hooks/sales/wifi-event/useWifiEvent';
import SalesWifiEventTable from './SalesWifiEventTable';

const SalesWifiEventBody = () => {
  const { getWifiEvent, list, isLoading } = useWifiEvent();
  const [query, setQuery] = React.useState('awaiting');

  React.useEffect(() => {
    const data = { status: query };
    getWifiEvent(data);
  }, [query]);
  return (
    <div className="mt-5 p-3">
      <div className="container-fluid pt-4">
        <div className="row">
          <div className="form-group inner-addon">
            <label htmlFor="status">Status</label>
            <select
              style={{ marginLeft: '1rem' }}
              name="status"
              className="form-control"
              onChange={(e) => setQuery(e.target.value)}
            >
              <option value="">Select Status</option>
              <option value="awaiting">Awaiting</option>
              <option value="confirmed">Confirmed</option>
              <option value="pending">Pending</option>
              <option value="approved">Approved</option>
              <option value="cancelled">Cancelled</option>
            </select>
          </div>
        <SalesWifiEventTable items={list.data} isLoading={isLoading} query={query} />
        </div>
      </div>
    </div>
  );
};

export default SalesWifiEventBody;
