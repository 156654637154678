import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import AvailableYears from './AvailableYears';
import Backdrop from '@mui/material/Backdrop';
import CountrySelect from '../FieldServiceKpiPerMonth/CountrySelect';
import { TableCellMonths, years, data, createData } from '../components/tempData';
import { get_total_bizdev_turbo_revenue } from '../../../../../../actions/coo-statistics/TurboConnectActions';
import ChartsContainer from './ChartsContainer';
import LinearProgressWithLabel from '../components/LinearProgress';
import Typography from '@mui/material/Typography';

export default function TurboConnectTable() {
   // Get the current year
   const currentYear = new Date().getFullYear();

  const [selectedYear, setSelectedYear] = React.useState(`${currentYear}`);
  const [isLoading, setIsLoading] = React.useState(false);
  const [fetchedBizDevData, setFetchedBizDevData] = React.useState([]);
  const [useFetchedData, setUseFetchedData] = React.useState([]);
  const [selectedCountry, setSelectedCountry] = React.useState('Nigeria');

  // Callback function to update the selected year this is an alternative for me to maintain modularity when trying to pass props
  const handleYearChange = (year) => {
    setSelectedYear(year);
  };

  const handleCountryChange = (country) => {
    setSelectedCountry(country);
  };

  React.useEffect(() => {
    // setSelectedYearData(data[getIndexOfYear]);

    setIsLoading(true);

    // console.log(selectedYear);

    async function fetchData() {
      try {
        const fetchedBizDevData = await get_total_bizdev_turbo_revenue(selectedYear, selectedCountry);
        setFetchedBizDevData(fetchedBizDevData.data); // Set the expired retrieved data in the state
        // console.log(fetchedBizDevData);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    }

    fetchData();
  }, [selectedYear]);

  React.useEffect(() => {
    if (isLoading === false && fetchedBizDevData.length > 0) {
      const getTurboConnect = fetchedBizDevData.map((data) => data.amount);

      const rows2023 = [createData('Total Turbo Installations Revenue', ...getTurboConnect)];

      // console.log(...getExpiration);
      setUseFetchedData(rows2023);

      // console.log(rows2023);
    }
  }, [isLoading, fetchedBizDevData]);

  return (
    <Box sx={{ ml: '2rem', mt: '2rem', mr: '2rem' }}>
      <Typography variant="h4">Turbo connect and turbo connect Pro Tracker (Nigeria)</Typography>

      {isLoading ? (
        <>
          <Skeleton width={300} height={100} />
          <Skeleton width={250} />
          <Skeleton width={250} />
        </>
      ) : (
        <Box sx={{ mb: 5 }}>
          <AvailableYears selectedYear={selectedYear} onYearChange={handleYearChange} />
        </Box>
      )}
      {/* {isLoading ? (
        <>
          <Skeleton width={300} height={100} />
          <Skeleton width={250} />
          <Skeleton width={250} />
        </>
      ) : (
        <Box sx={{ mb: 5 }}>
          <CountrySelect selectedCountry={selectedCountry} onCountryChange={handleCountryChange} />
        </Box>
      )} */}

      {isLoading ? (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
          <LinearProgressWithLabel />
        </Backdrop>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bolder' }}>Total Turbo Connect & Tubro Connect Pro Revenue</TableCell>
                {TableCellMonths.map((month, index) => (
                  <TableCell key={index} align="right" sx={{ fontWeight: 'bolder' }}>
                    {month}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
              </TableRow>
            </TableHead>

            <TableBody>
              {useFetchedData.map((row) => (
                <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  {row.values.map((value) => (
                    <TableCell align="right" key={value}>
                      {value}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <ChartsContainer data={useFetchedData} isLoading={isLoading} />
    </Box>
  );
}
