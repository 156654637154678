/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import moment from 'moment';
import view from '../../../../../assets/img/icons/view.png';
import IncidentDetailsNew from './IncidentDetailsNew';

const IncidentReportTableNew = ({ incident }) => {
  const [incidentId, setIncidentId] = useState({});

  const getIncident = (incident) => {
    setIncidentId(incident);
  };

  const incidentsList = incident ? (
    incident.map((incidents, i) => (
      <tr key={i + 1}>
        <td>{i + 1}</td>
        <td>{incidents.system_affected}</td>
        <td nowrap="no-wrap">{incidents.description}</td>
        <td nowrap="no-wrap">{incidents.impact}</td>
        <td nowrap="no-wrap">{incidents.duration_start_time}</td>
        <td nowrap="no-wrap">{incidents.duration_end_time}</td>
        <td nowrap="no-wrap">{moment(incidents.created_at).format('YYYY-MM-DD')}</td>
        <td>
          <div className="d-flex">
            <button
              type="submit"
              onClick={() => getIncident(incidents)}
              data-toggle="modal"
              data-placement="right"
              data-target="#incidentDetail"
              style={{
                border: '0',
                backgroundColor: 'white',
              }}
            >
              <span className="view1">
                <img src={view} alt="" data-toggle="tooltip" data-placement="right" title="View" />
              </span>
            </button>
            <IncidentDetailsNew incident={incidentId} />
          </div>
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td>
        <p>No incidents</p>
      </td>
    </tr>
  );

  return <tbody id="reduce-length">{incidentsList}</tbody>;
};

export default IncidentReportTableNew;
