/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import usePost from '../../../../hooks/shared/custom/data/geniatech/usePost';
import { GET_ROUTER_URL } from '../../../../services/api/shared/BasestationsUrl';
import CustomCsvDownload from '../CustomCsvDownload';
import { filename, assetTeamHeaders, assetTeamHeaders2 } from '../../../../utils/field-support/installations/helpers';
import DownloadActive from '../../departments/asset-team/active-country/DownloadActive';
import AssetTeamTable from '../asset-team/AssetTeamTable';
import SearchByDetails from '../../departments/asset-team/shared/SearchByDetails';

const ActivePerCountryBody = ({ profile }) => {
  const { data, loading, getData, getDataLegacy, fetchData } = usePost();

  const searchState = { country: 'Nigeria', recordsPerPage: 20, currentPage: 1 };
  const [search, setSearch] = useState(searchState);
  const { country, recordsPerPage, currentPage } = search;

  const params = {
    action: 'tizeti_asset_active_users_paginated_country',
    country,
    no_of_records_per_page: recordsPerPage,
    pageno: currentPage,
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch({ ...search, [name]: value });
  };

  const onSearchByCountry = (e) => {
    e.preventDefault();
    getDataLegacy(`${GET_ROUTER_URL}`, params);
  };

  useEffect(() => {
    getDataLegacy(`${GET_ROUTER_URL}`, params);
  }, []);

  return (
    <div className="mt-5 px-3">
      <div className="container-fluid pt-5">
        <div className="row">
          <div className="p-0 col-12 d-flex justify-content-between">
            <div className="d-flex">
              <form onSubmit={onSearchByCountry} className="form-inline ml-3">
                <div className="form-group mr-3">
                  <select defaultValue={country} name="country" onChange={handleChange} className="form-control">
                    <option value="">--Choose Country--</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="Ghana">Ghana</option>
                    <option value="civ">Côte d'Ivoire</option>
                  </select>
                </div>
                <div className="form-group mr-3">
                  <input
                    type="number"
                    min="1"
                    placeholder="Per Page"
                    className="form-control"
                    name="recordsPerPage"
                    value={recordsPerPage}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group mr-2">
                  <button className="btn btn-green" type="submit">
                    Search
                  </button>
                </div>
              </form>
              {(data.items && data.items.length) || data.length ? (
                <CustomCsvDownload
                  data={data.items || data}
                  headers={data.items ? assetTeamHeaders : assetTeamHeaders2}
                  filename={filename(`Active ${country}`)}
                  classname="btn btn-green shadow-md"
                  textcolor="white"
                  text="Download Data"
                />
              ) : null}
            </div>
            {(data.items && data.items.length) || data.length ? (
              <div className="pt-2 mx-3">
                Total:
                <span className="badge badge-secondary ml-1">{data.total_records || data.length}</span>
              </div>
            ) : null}
          </div>
          <div className="d-flex ml-3">
            <SearchByDetails getData={getData} fetchData={fetchData} getDataLegacy={getDataLegacy}/>
            <div style={{ paddingTop: '6px' }}>
              <DownloadActive country={country} type="Active" />
            </div>
          </div>
          <AssetTeamTable profile={profile} items={data.items || data} load={loading} />
        </div>
      </div>
    </div>
  );
};

export default ActivePerCountryBody;
