import React from 'react';
import PowerConsumptionTable from './PowerConsumptionTable';
import AddDevices from './AddDevices';
import AssignDevice from './AssignDevice';
import TotalConsumptionTable from './TotalConsumptionTable';

const PowerConsumption = () => {
  return (
    <div className="container state px-4">
      <div className="row mt-5">
        <div className="mt-5 col-lg-12 col-md-12 col-sm-12 d-flex flex-wrap">
          <div className="col-lg-6 col-md-6 col-sm-12 p-0">
            <div className="col-lg-12 mt-3 mb-3 d-flex inner-addon left-addon p-0">
              {/* <form className="form-inline w-100 inner-addon left-addon" action="#"> */}
              <input
                className="form-control mr-auto col-lg-11 mt-1"
                type="text"
                placeholder="Search using Device Name"
              />
              <span className="fa fa-search mt-1" />
              {/* </form> */}
            </div>
            <div className="main-content">
              <h5 className="mt-5">Devices</h5>
              <div className="card x-panel m-0 p-2">
                <div className="table-responsive content">
                  <table className="table copy-font">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Device name</th>
                        <th>Max consumption(Watt)</th>
                        <th>Max power supply (Volts)</th>
                      </tr>
                    </thead>

                    <PowerConsumptionTable />
                    {/* <tbody>
                                            {this.displayLogs()}
                                            </tbody> */}
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 p-0">
            <div className="col-lg-12 mt-3 mb-3 d-flex inner-addon left-addon p-0">
              {/* <form className="form-inline w-100 inner-addon left-addon" action="#"> */}
              <input
                className="form-control mr-auto col-lg-6 mt-1"
                type="text"
                placeholder="Search using Basestation Name"
              />
              <span className="fa fa-search mt-1" />
              {/* </form> */}
              <div className="button-set d-flex col-lg-6">
                <button className="btn btn-green ml-2 shadow-md" data-toggle="modal" data-target="#add-device">
                  {' '}
                  Add Device
                </button>
                <AddDevices />

                <button className="btn btn-success ml-2 shadow-md" data-toggle="modal" data-target="#assign-device">
                  {' '}
                  Assign Device
                </button>
                <AssignDevice />
              </div>
            </div>

            <div className="main-content">
              <h5 className="mt-5">Basestation total consumption</h5>
              <div className="card x-panel m-0 p-2">
                <div className="table-responsive content">
                  <table className="table copy-font">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Basestation name</th>
                        <th>Total consumption(Watt)</th>
                        <th>View Devices</th>
                      </tr>
                    </thead>

                    <TotalConsumptionTable />
                    {/* <tbody>
                                            {this.displayLogs()}
                                            </tbody> */}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PowerConsumption;
