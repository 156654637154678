import React from 'react';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import BusinessDevelopmentNavBar from '../../../../layouts/business-development/BusinessDevelopmentNavBar';
import BusinessDevelopmentBasestationStatusBody from './BusinessDevelopmentBasestationStatusBody';

const BusinessDevelopmentBasestationStatus = () => {
  const { profile } = useUser();
  const { firstName } = profile;
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <BusinessDevelopmentNavBar currentPage="Basestation Status" position={firstName} />
        <BusinessDevelopmentBasestationStatusBody />
      </main>
    </div>
  );
};

export default BusinessDevelopmentBasestationStatus;
