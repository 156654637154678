/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/require-default-props */
/* eslint-disable no-shadow */
/* eslint-disable no-case-declarations */
/* eslint-disable consistent-return */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import qs from 'qs';
import edit from '../../../../../assets/img/icons/edit.png';
import view from '../../../../../assets/img/icons/view.png';
import ExtendCustomer from '../extensions/ExtendCustomer';
import CustomerDetails from './CustomerDetails';
import ViewPassword from '../passwords/ViewPassword';
import { useCustomer } from '../../../../../hooks/shared/customers/useCustomers';
import PaymentsHistory from '../payments/PaymentsHistory';
import { usePayments } from '../../../../../hooks/shared/customers/usePayments';
import { displayCountryDropdown } from '../../../../../utils/geniatech/Geniatech';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import { showDepartment } from '../../../../../utils/lib';
import { OPERATIONS, SALES } from '../../../../../utils/departments/helpers';
import IMSITable from './IMSITable';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../../utils/loader/loadingHelper';
import EditDetails from '../../../departments/field-support/customers/EditDetails';
import useInstallations from '../../../../../hooks/shared/installations/useInstallations';

const SupportCustomersTable = () => {
  const role_id = sessionStorage.getItem('roleId');
  const {
    search,
    onSearchChange,
    searchForUserByEmail,
    searchByPhoneOrMac,
    searchByName,
    searchByIMSI,
    customers,
    isLoading,
    option,
    onOptionChange,
    getPassword,
    gettingPassword,
    password,
    getUserDetails,
    gettingDetails,
    details,
    address,
    itemprice,
    session,
    createdAt,
    username,
    country,
    onCountryChange,
    deleteCustomerAccount,
    deleting,
    installationDate,
    searchByReference,
  } = useCustomer();

  const { payments, gettingPayments, getCustomerPayments } = usePayments();
  const { getCalledStation, calledstation, radacct, isFetching } = useInstallations();
  const { profile, user } = useUser();
  const { officialEmail } = profile;

  const { department_id } = user;

  const [mac, setMac] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [person, setPerson] = useState({});
  const [isOpen, setIsOpen] = React.useState(false);
  const [email, setEmail] = useState('');
  const [customerRef, setCustomerRef] = useState('');

  const getCustomerDetails = (customer) => {
    setName(customer.name);
    setEmail(customer.mail);
    setCustomerRef(customer.customer_ref);
    getUserDetails(customer.username);
  };

  const showModal = (value) => {
    setIsOpen(true);
    setMac(value);
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  const searchForUser = (e) => {
    e.preventDefault();
    switch (option) {
      case 'email':
        const emailSearch = {
          email: search.trim(),
        };
        searchForUserByEmail(emailSearch);
        break;

      case 'name':
        const nameSearch = {
          name: search.trim(),
          country,
        };
        searchByName(nameSearch);
        break;

      case 'phone':
        const phoneSearch = {
          phoneNumber: search.trim(),
          macAddress: '12',
        };
        searchByPhoneOrMac(phoneSearch);
        break;

      case 'imsi':
        const imsiSearch = { imsi: search.trim() };
        searchByIMSI(imsiSearch);
        break;

      case 'macid':
        const macidSearch = {
          macAddress: search.trim(),
          phoneNumber: 'N/A',
        };
        searchByPhoneOrMac(macidSearch);
        break;

      case 'reference':
        const refernceNumberSearch = {
          reference: search.trim(),
          phoneNumber: 'N/A',
          macAddress: 'N/A',
          action: 'getUserInformationWithPhoneOrMac',
          category: 'reference',
        };
        searchByReference(refernceNumberSearch);
        break;

      default:
        return null;
    }
  };

  const getUserPassword = (customer) => {
    const data = {
      action: 'getCustomerPassword',
      username: customer.username,
    };
    getPassword(data);
  };

  const getUser = (customer) => {
    setMac(customer.username);
    setName(customer.name);
    setPerson(customer);
  };

  const getUsers = (customer) => {
    setMac(customer.username);
    setName(customer.name);
    setEmail(customer.mail);
    setPhone(customer.address);
    setCustomerRef(customer.customer_ref);

    getCalledStation(customer.username);
  };
  const getUserPayments = (customer) => {
    const data = {
      macid: customer.username,
    };
    getCustomerPayments(data);
  };

  const deleteCustomer = (params) => {
    // actiondeleteCustomerDevicef

    // departmentCustomer Support
    // role_id3
    // macaddress00:04:56:23:A6:1A

    const data = qs.stringify({
      action: 'deleteCustomerDevice',
      staffEmailAddress: 'ifeanyi.okonkwo@tizeti.com',
      department: showDepartment(department_id),
      role_id: Number(sessionStorage.getItem('roleId')),
      macaddress: params,
    });
    deleteCustomerAccount(data);
  };

  const checkDepartment = () =>
    department_id !== `${SALES && OPERATIONS}` ? (
      <td className="text-center">
        <button type="submit" data-target="#extend" data-toggle="modal" onClick={() => getUser(customer)}>
          <span className="view">
            <img src={view} data-toggle="tooltip" alt="Extend" title="Extend" />
          </span>
        </button>
      </td>
    ) : null;

  const displayData = () =>
    customers && customers.length ? (
      customers.map((customer, i) => (
        <tr>
          <td>{i + 1}</td>
          <td>{customer.username}</td>
          <td nowrap="no-wrap">{customer.name}</td>
          <td>{customer.mail}</td>
          <td className="ml-2">
            <button
              data-toggle="modal"
              data-target="#view-password"
              className="ml-3"
              onClick={() => getUserPassword(customer)}
            >
              <i className="view1 fa fa-ellipsis-v" style={{ fontSize: '20px' }} />
            </button>
            {/* {get ? <h1>loading</h1> : <PaymentHistory usersTransactions={this.state.transactions}/>} */}
            {/* <PaymentHistory usersTransactions={this.state.transactions} get={get}/> */}
            <ViewPassword password={password} load={gettingPassword} />
          </td>
          <td>{customer.address}</td>
          {department_id !== `${OPERATIONS}` && <td className="text-center">{customer.customer_ref}</td>}
          {checkDepartment()}

          <ExtendCustomer username={mac} name={name} />
          {/* <ExtendCustomer /> */}

          {/* <td>{moment(customer.Expiration).format('Do MMMM YYYY')}</td> */}
          <td nowrap="no-wrap">{customer.value}</td>
          {officialEmail !== 'ifeanyi.okonkwo@tizeti.com' ? null : (
            <td onClick={() => showModal(customer.username)}>
              <button>Delete Customer</button>
            </td>
          )}
          <Modal show={isOpen} onHide={hideModal} style={{ paddingTop: '11%' }}>
            {/* <Modal.Header> */}
            {/*  <Modal.Title>Hi</Modal.Title> */}
            {/* </Modal.Header> */}
            <Modal.Body style={{ textAlign: 'center' }}>Are you sure you want to delete this customer?</Modal.Body>
            <Modal.Footer>
              <button onClick={hideModal} style={{ backgroundColor: 'skyblue', padding: '1%', marginLeft: '4%' }}>
                Cancel
              </button>
              {deleting ? (
                <button
                  disabled={deleting}
                  onClick={() => deleteCustomer(mac)}
                  style={{ backgroundColor: 'red', padding: '1%', marginLeft: '32%' }}
                >
                  Deleting...
                </button>
              ) : (
                <button
                  onClick={() => deleteCustomer(mac)}
                  style={{ backgroundColor: 'red', padding: '1%', marginLeft: '32%' }}
                >
                  Yes, delete this customer
                </button>
              )}
            </Modal.Footer>
          </Modal>

          {/* <button
              data-toggle="modal"
              data-target="#payment-history"
              className="ml-3"
              onClick={() => getUserPayments(customer)}
            >
              <i className="view1 fa fa-ellipsis-v" style={{ fontSize: '20px' }} />
            </button>
    
            <PaymentsHistory load={gettingPayments} transactions={payments} /> */}
          {department_id == `${OPERATIONS}` && (
            <>
              {Number(role_id) > 1 && Number(role_id) !== 6 ? (
                <td
                  className="text-center img-pointer"
                  data-target="#edit-details"
                  onClick={() => getUsers(customer)}
                  data-toggle="modal"
                  style={{ width: '0%' }}
                >
                  <span className="view2">
                    <img src={edit} alt="" data-toggle="tooltip" data-placement="top" title="Edit" />
                  </span>
                </td>
              ) : null}
            </>
          )}
          <EditDetails
            username={mac}
            oldEmail={email}
            oldName={name}
            oldPhone={phone}
            role_id={role_id}
            calledstation={calledstation}
            radacct={radacct}
            isFetching={isFetching}
          />
          <td className="ml-2">
            <button
              type="submit"
              style={{
                border: '0',
                backgroundColor: 'white',
                width: '0%',
              }}
              data-toggle="tooltip"
              data-placement="left"
              title="View"
              onClick={() => {
                getCustomerDetails(customer);
              }}
            >
              <span className="view1 ml-4">
                <img src={view} alt="" data-toggle="modal" data-target="#cus-details" />
              </span>
            </button>
            <CustomerDetails
              user={customer}
              load={gettingDetails}
              address={address}
              details={details}
              session={session}
              createdDate={createdAt}
              username={username}
              installationDate={installationDate}
              name={name}
              mail={email}
              customerRef={customerRef}
              itemprice={itemprice}
            />
          </td>
        </tr>
      ))
    ) : (
      <NoItemRow colSpan="11" text={`no customer with this ${option}`} />
    );

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12 mt-5 mb-3">
          <form onSubmit={searchForUser} className="form-inline inner-addon d-flex">
            <div className="input-group mb-3 mr-3">
              <select name="keyword" onChange={onOptionChange} className="form-control mr-auto empty mt-1">
                <option value="">Choose fields to search from</option>
                <option value="email">Email</option>
                <option value="name">Name</option>
                <option value="phone">Phone</option>
                <option value="imsi">IMSI</option>
                <option value="macid">MAC ID</option>
                <option value="reference">Account Number</option>
              </select>
            </div>

            {displayCountryDropdown(option, onCountryChange)}

            <div className="input-group mb-3 mr-3">
              <input
                className="form-control mr-auto empty pl-5"
                type="text"
                placeholder="search for user"
                name="search"
                value={search}
                onChange={onSearchChange}
              />
              <span className="fa fa-search" />
            </div>

            <div className="mb-3">
              <button className="btn btn-green" style={{ height: '39px' }}>
                Search
              </button>
            </div>
          </form>
        </div>
        <div className="card x-panel">
          <div className="table-responsive content">
            {option === 'imsi' ? (
              <IMSITable customers={customers} option={option} isLoading={isLoading} />
            ) : (
              <table className="table copy-font">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>MAC ID</th>
                    <th nowrap="no-wrap">Name</th>
                    <th>Email</th>
                    <th>Show Password</th>
                    <th nowrap="no-wrap">Phone Number</th>
                    {department_id !== `${OPERATIONS}` && <th nowrap="no-wrap">Account Number</th>}
                    {department_id !== `${SALES && OPERATIONS}` ? <th className="text-center">Extend</th> : null}
                    <th nowrap="no-wrap">Expiry Date</th>
                    {/* eslint-disable-next-line @typescript-eslint/camelcase */}
                    {officialEmail !== 'ifeanyi.okonkwo@tizeti.com' ? null : <th nowrap="no-wrap">Delete Account</th>}
                    {/* <th className="text-center">Rollback</th> */}
                    {department_id == `${OPERATIONS}` && (
                      <>{Number(role_id) > 1 && Number(role_id) !== 6 ? <th className="text-center">Edit</th> : null}</>
                    )}
                    <th style={{ paddingLeft: '24px' }}>Actions</th>
                  </tr>
                </thead>
                <tbody>{isLoading ? <TizetiGIFLogoTable colSpan="11" /> : displayData()}</tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      {/* <RetentionScript/> */}
      <ToastContainer />
    </div>
  );
};

export default SupportCustomersTable;
