import React from 'react';
import useCheckTwoDepartments from '../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import { NOC, SUPER_ADMIN } from '../../../../../utils/departments/helpers';
import NocNavBar from '../../../../layouts/noc/NocNavBar';
import NetworkOperationBasestationStatusBody from './NetworkOperationBasestationStatusBody';

const NetworkOperationBasestationStatus = ({ history }) => {
  useCheckTwoDepartments(`${NOC}`, `${SUPER_ADMIN}`, history);
  const {
    profile: { firstName },
  } = useUser();

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <NocNavBar currentPage="Basestation Status" position={firstName} />
        <NetworkOperationBasestationStatusBody />
      </main>
    </div>
  );
};

export default NetworkOperationBasestationStatus;
