import React, { Component } from 'react';
import axios from 'axios';
import { API_URL } from '../../../../../utils/config';
import NetPromoter from '../../../shared/nps/net-promoter-score/NetPromoter';
import NocNavBar from '../../../../layouts/noc/NocNavBar';

class NocPromoterScore extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
    };
  }

  componentDidMount() {
    this.getUserDetails();
  }

  getUserDetails() {
    axios({
      method: 'get',
      url: `${API_URL}/employee/loggedOnEmployee`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    }).then((res) => {
      // console.log(res.data);
      this.setState({
        firstName: res.data.firstName,
      });
    });
  }

  render() {
    const { firstName } = this.state;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <NocNavBar currentPage="Net Promoter Score" position={firstName} />

          <div className="container px-4">
            <div className="row mt-5">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <NetPromoter />
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default NocPromoterScore;
