/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useSearchReference } from '../../../../../hooks/sales/saerch-reference/useSearchReference';

const SearchReference = ({ handleChange, invoiceDetails }) => {
  const { searchReference } = invoiceDetails;

  const { customerReference } = useSearchReference();
  const [disable, setDisable] = React.useState(false);

  return (
    <div className="card my-5 p-3">
      <label className="m-0 p-0 payslipfont col-3 text-left col-form-label" htmlFor="quantity">
        Search Reference
      </label>
      <form className="card-body" onSubmit={searchReference}>
        <div className="col-12 d-flex">
          <div className="col-7 d-flex">
            <label className="m-0 p-0 payslipfont col-form-label" htmlFor="reference">
              Reference No:
            </label>
            <div className="mb-2">
              <input
                type="text"
                className="form-control border border-info py-2"
                id="reference"
                name="customerReference"
                value={customerReference}
                onChange={handleChange}
              />
            </div>
            <div className="text-right">
              <button className="btn btn-green mx-1" type="submit" disabled={disable} onClick={() => setDisable(true)}>
                Search Reference
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default SearchReference;
