/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { ToastContainer } from 'react-toastify';
import { RouteComponentProps } from 'react-router-dom';
import { useUser } from '../../../../../../hooks/shared/user/useUser';
import useCheckDepartment from '../../../../../../hooks/shared/custom/check-department/field-support/useCheckDepartment';
import '../../../../shared/installations/leads-and-managers/index.css';
import FieldSupportNavBar from '../../../../../layouts/field-support/FieldSupportNavBar';
import InstallationsOverviewBody from '../../../../shared/installations/leads-and-managers/InstallationsOverviewBody';
import { OverviewProvider } from '../../../../../../context/shared/installations/overview/OverviewContext';

const InstallationOverview = ({ history }) => {
  const { profile } = useUser();
  const { firstName } = profile;
  useCheckDepartment(history);
  return (
    <div className="s-layout">
      <OverviewProvider>
        <main className="s-layout__content col-lg-10 p-0">
          <FieldSupportNavBar currentPage="Installation" position={firstName} />
          <InstallationsOverviewBody />
        </main>
      </OverviewProvider>
      <ToastContainer />
    </div>
  );
};
export default InstallationOverview;
