import React, { useContext } from 'react';
import useFetch from '../../../../../hooks/shared/custom/data/useFetch';
import { GET_ZONES_URL } from '../../../../../services/api/shared/installations/ZonesUrl';
import { OverviewContext } from '../../../../../context/shared/installations/reschedule/OverviewContext';
import CustomInput from '../../forms/CustomInput';
import { StatusData } from '../../../../../utils/field-support/installations/helpers';
import DownloadData from './table/DownloadData';


const Searched = () => {
  const { data } = useFetch(`${GET_ZONES_URL}`);
  const {
    onSearchChange,
    search,
    searchInstallations,
    searchForUser,
    searchUser,
    handleSearchUser,
    installations,
  } = useContext(OverviewContext);

  const currentPage = 1;

  const onSubmitSearch = (e) => {
    e.preventDefault();
    searchForUser(currentPage);
  };

  const handleSearchWithParams = () => searchInstallations(currentPage);

  const { from, to } = search;
  return (
    <div className="">
      <div className="mt-3 d-flex">
        <div className="col-5 d-none d-md-flex">
          <p className="fil copy-font d-flex mt-3 mr-3">
            <small className="text-nowrap mt-2 mr-2">Filter By:</small>
            <select className="form-control" onChange={onSearchChange} name="status">
              <option value="">--Status--</option>
              {StatusData.map(({ value, name }) => (
                <option value={value}>{name}</option>
              ))}
            </select>
          </p>
          <p className="fil d-flex mt-3 copy-font">
            <small className="text-nowrap mt-2 mr-2">Filter By:</small>
            <select className="form-control" onChange={onSearchChange} name="zone_id">
              <option value="">--Zones--</option>
              {data && data.length ? data.map((zon) => <option value={zon.id}>{zon.name}</option>) : null}
            </select>
          </p>
        </div>

        <div className="col-7 d-none d-md-flex">
          <p className="fil d-flex mt-3 mr-5 copy-font">
            <small className="mr-3">From:</small>
            <CustomInput
              placeholder=""
              readOnly={false}
              type="date"
              classname="form-control"
              name="from"
              value={from}
              onChange={onSearchChange}
            />
            <small className="mr-3 ml-3">To:</small>
            <CustomInput
              placeholder=""
              readOnly={false}
              type="date"
              classname="form-control"
              name="to"
              value={to}
              onChange={onSearchChange}
            />
          </p>
          <div>
            <button type="button" className="btn btn-green mt-3" onClick={handleSearchWithParams}>
              Search
            </button>
          </div>
        </div>
      </div>

      <div className="d-flex">
        <div className="col-9">
          <form onSubmit={onSubmitSearch} className="inner-addon d-flex">
            <div className="input-group mb-3 mr-3">
              <select name="option" onChange={handleSearchUser} className="form-control mr-auto empty mt-1">
                <option value="">Choose field to search from</option>
                <option value="email">Email</option>
                <option value="firstName">First Name</option>
                <option value="lastName">Last Name</option>
                <option value="phone">Phone</option>
              </select>
            </div>
            <div className="input-group mb-3 mr-3">
              <input
                className="form-control mr-auto empty pl-5"
                type="text"
                placeholder="search for user"
                name="keyword"
                value={searchUser.keyword}
                onChange={handleSearchUser}
              />
              <span className="fa fa-search" />
            </div>
            <div className="my-1">
              <button type="submit" className="btn btn-green">
                Search By User Details
              </button>
            </div>
          </form>
        </div>

        <div className="col-3 my-1">{installations.length > 0 && <DownloadData />}</div>
      </div>
    </div>
  );
};

export default Searched;
