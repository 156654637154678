/* eslint-disable react/require-default-props */
import React from 'react';
import { ToastContainer } from 'react-toastify';
import PropTypes from 'prop-types';
import Loader from '../../../../assets/img/icons/TizetiLoaderColored.gif';

const FailedInstallationsTable = ({ failed, load }) => {
  if (load) {
    return (
      <tbody>
        <tr>
          <td>
            <img src={Loader} alt="" />;
          </td>
        </tr>
      </tbody>
    );
  }

  const failedList = failed
    ? failed.map((fail, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <tr key={i + 1}>
          <td>{i + 1}</td>
          <td>{fail.account_alias ? fail.account_alias : 'N/A'}</td>
          <td>{fail.address ? fail.address : 'N/A'}</td>
          {/* <td>{fail.available_zone_time_id}</td> */}
          <td>{fail.country ? fail.country : 'N/A'}</td>
          <td>{fail.installation_comment ? fail.installation_comment : 'N/A'}</td>
          <td>{fail.payment_reference ? fail.payment_reference : 'N/A'}</td>
          <td>{fail.state ? fail.state : 'N/A'}</td>
          <td>{fail.status ? fail.status : 'N/A'}</td>
        </tr>
      ))
    : null;

  return (
    <tbody>
      {load ? <span>loading...</span> : failedList}
      <tr>
        <td>
          <ToastContainer />
        </td>
      </tr>
    </tbody>
  );
};

FailedInstallationsTable.propTypes = {
  load: PropTypes.bool,
  // failed: PropTypes.shape(),
  // eslint-disable-next-line react/forbid-prop-types
  failed: PropTypes.array,
};

export default FailedInstallationsTable;
