/* eslint-disable @typescript-eslint/camelcase */
import React, { useContext } from 'react';
import { stringify } from 'qs';
import AppContext from '../../../../../context/shared/AppContext';

const SearchOpenAndBlockedUsers = () => {
  const { onChange, status, searchStatus, currentPage, searchByEmail, onChangeSetByDetails, searchDetails } = useContext(AppContext);
  const submit = () => {
    searchStatus(
      stringify({
        action: 'wificall_users_blocked_status',
        no_of_records_per_page: '500',
        pageno: currentPage,
        status,
      }),
    );
  };



  const submitSearchByEmail = () => {
    searchByEmail(
      stringify({
        ...searchDetails,
        action: 'wificall_users_search_email',
      })
    )


  }
  return (
    <div className=''>
      <form className="form-inline mb-2 ml-3 col-12">
        <div className="form-group">
          <select name="status" onChange={onChange} className="form-control">
            <option value="">--Choose Status--</option>
            <option value="open">Open</option>
            <option value="blocked">Blocked</option>
          </select>
        </div>
        <div className="form-group ml-2">
          <button onClick={submit} type="button" className="btn btn-green">
            Search
          </button>
        </div>

      </form>

      <form className="form-inline mb-2 ml-3 col-12 mt-3">
        <div className="form-group ml-3">
          <input type="text"
            value={searchDetails.searchdetails}
            onChange={onChangeSetByDetails}
            name="searchdetails"
            id="searchdetails"
            className='p-2'
            placeholder='Search by email' />

        </div>
        <div className="form-group ml-3">
          <button onClick={submitSearchByEmail} type="button" className="btn btn-green">
            Search
          </button>
        </div>

      </form>
    </div>

  );
};

export default SearchOpenAndBlockedUsers;
