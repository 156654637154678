import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { API_URL } from '../../../../../utils/config';

class AddEmployee extends Component {
  constructor(props) {
    super();

    this.state = {
      departments: [],
      department: '',
      isLoading: true,
      address: '',
      alternateEmail: '',
      alternatePhoneNumber: '',
      birthday: '',
      date_of_resumption: '',
      departmentList: '',
      department_id: '',
      email: '',
      extension: '',
      firstName: '',
      gender: '',
      lastName: '',
      officialEmail: '',
      phoneNumber: '',
      role: '',
      show: false,
      employees: [],
      redirect: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  handleChange = (event) => {
    this.setState({
      ...this.state,
      gender: event.target.value,
    });
  };

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    const data = {
      address: this.state.address,
      alternateEmail: this.state.alternateEmail,
      alternatePhoneNumber: this.state.alternatePhoneNumber,
      birthday: this.state.birthday,
      date_of_resumption: this.state.date_of_resumption,
      department_id: this.state.department_id,
      email: this.state.email,
      extension: this.state.extension,
      firstName: this.state.firstName,
      gender: this.state.gender,
      lastName: this.state.lastName,
      officialEmail: this.state.officialEmail,
      phoneNumber: this.state.phoneNumber,
      role: this.state.role,
    };

    const header = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    };

    fetch(`${API_URL}/employee`, {
      method: 'post',
      headers: header,
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      }
    }).then((result) => {
      const responseJson = result;

      if (responseJson.status === 200) {
        sessionStorage.setItem('success', responseJson);

        this.setState({
          redirect: true,
          employees: {},
        });
      } else {
      }
    });
  }

  componentDidMount() {
    axios
      .get(`${API_URL}/department`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        }
      })
      .then((res) => {
        const departments = res.data.data.map((department) => department);
        this.setState({ departments });
      })
      .catch((err) => {});
  }

  render() {
    const { redirect } = this.state;

    if (redirect) {
      return (
        <div
          id="success2"
          className="modal fade in"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body text-center">
                <span className="fa fa-check-circle" />
                <p>
                  <b>Employee Added Successfully!</b>
                </p>
                {/* <Link to={"#add-employee"} data-toggle="modal" data-dismiss="modal">
                                  <button type="submit" className="btn btn-green waves-effect" data-toggle="modal"
                                          data-target="#add-employee">Add Another
                                  </button>
                              </a> */}
              </div>
            </div>
            {/* modal-content */}
          </div>
          {/* modal-dialog */}
        </div>
      );
    }

    return (
      <div
        id="add-employee"
        className="modal fade in"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
        style={{ overflow: 'auto' }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center" id="myModalLabel">
                Add Profile Details
              </h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
            </div>
            <div className="modal-body">
              <form className="form-horizontal" onSubmit={this.onSubmit}>
                <div className="form-group employe-body">
                  <div className="col-md-12 mb-1 d-flex">
                    <div className="col-lg-6">
                      <label className="col-lg-12 text-justify">First Name</label>
                      <input
                        type="text"
                        onChange={this.onChange}
                        name="firstName"
                        value={this.state.firstName}
                        className="form-control"
                        placeholder="First Name"
                      />
                    </div>

                    <div className="col-lg-6">
                      <label className="col-lg-12 text-justify">Last Name</label>
                      <input
                        type="text"
                        onChange={this.onChange}
                        name="lastName"
                        value={this.state.lastName}
                        className="form-control"
                        placeholder="Last Name"
                      />
                    </div>
                  </div>

                  <div className="col-md-12 mb-1 d-flex">
                    <div className="col-lg-6 one">
                      <input type="radio" name="gender" value="female" onChange={this.handleChange} /> Female &nbsp;
                      &nbsp;
                      <input type="radio" name="gender" value="male" onChange={this.handleChange} /> Male
                    </div>

                    <div className="col-lg-6">
                      <label className="col-lg-12 text-justify">Address</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={this.onChange}
                        name="address"
                        value={this.state.address}
                        placeholder="Permanent home address"
                      />
                    </div>
                  </div>

                  <div className="col-md-12 mb-1 d-flex">
                    <div className="col-lg-6">
                      <label className="col-lg-12 text-justify">Email address</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="personal email address"
                        name="email"
                        value={this.state.email}
                        onChange={this.onChange}
                      />
                    </div>

                    <div className="col-lg-6">
                      <label className="col-lg-12 text-justify">Alternate email address</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Alternate email address"
                        name="alternateEmail"
                        value={this.state.alternateEmail}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-12 mb-1 d-flex">
                    <div className="col-lg-6">
                      <label className="col-lg-12 text-justify">Offical Email address</label>
                      <input
                        type="email"
                        name="officialEmail"
                        value={this.state.officialEmail}
                        onChange={this.onChange}
                        className="form-control"
                        placeholder="Company domain email address"
                      />
                    </div>
                  </div>
                  <div className="col-md-12 mb-1 d-flex">
                    <div className="col-lg-6">
                      <label className="col-lg-12 text-justify">Phone number 1</label>
                      <input
                        type="text"
                        name="phoneNumber"
                        value={this.state.phoneNumber}
                        onChange={this.onChange}
                        className="form-control"
                        placeholder="Phone number 1"
                      />
                    </div>

                    <div className="col-lg-6">
                      <label className="col-lg-12 text-justify">Phone number 2</label>
                      <input
                        type="text"
                        className="form-control"
                        name="alternatePhoneNumber"
                        value={this.state.alternatePhoneNumber}
                        onChange={this.onChange}
                        placeholder="Phone number 2"
                      />
                    </div>
                  </div>

                  <div className="col-md-12 mb-1 d-flex">
                    <div className="col-lg-6">
                      <label className="col-lg-12 text-justify">Extension</label>
                      <input
                        type="text"
                        className="form-control"
                        name="extension"
                        value={this.state.extension}
                        onChange={this.onChange}
                        placeholder="Phone number extension"
                      />
                    </div>

                    <div className="col-lg-6">
                      <label className="col-lg-12 text-justify">Pick Dept</label>

                      <select onChange={this.onChange} name="department_id">
                        <option>Choose Department</option>

                        {this.state.departments.map((department) => {
                          return (
                            <option key={department.id} value={department.id}>
                              {department.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-12 mb-1 d-flex">
                    <div className="col-lg-6">
                      <label className="col-lg-12 text-justify">Role</label>
                      <input
                        type="text"
                        className="form-control"
                        name="role"
                        value={this.state.role}
                        onChange={this.onChange}
                        placeholder="Department Designation"
                      />
                    </div>

                    <div className="col-lg-6">
                      <label className="col-lg-12 text-justify">Date of resumption</label>
                      <input
                        type="date"
                        className="form-control"
                        name="date_of_resumption"
                        value={this.state.date_of_Resumption}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-12 mb-1 d-flex">
                    <div className="col-lg-6">
                      <label className="col-lg-12 text-justify">Birthday</label>
                      <input
                        type="date"
                        className="form-control"
                        name="birthday"
                        value={this.state.birthday}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="text-center">
                  <button type="submit" className="btn btn-green waves-effect">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AddEmployee;
