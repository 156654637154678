import React, { useContext } from 'react';
// import { useRequests } from '../../../../hooks/shared/requests/useRequests';
import RequestContext from '../../../../../context/shared/requests/non-admin/RequestContext';

const MakeRequest = () => {
  const value = useContext(RequestContext);
  const { categories, makeRequest, onChange, request, isLoadingRequest } = value;
  const { category, description, code } = request;

  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      categoryId: category,
      description,
      budgetCode: code,
    };
    makeRequest(data);
  };

  const submitForm = () => {
    return category.length > 0 && description.length > 0;
  };
  return (
    <div
      id="makeRequest"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Make Request
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>

          <div className="modal-body">
            <form className="form-horizontal" onSubmit={onSubmit}>
              <div className="form-group text-left">
                <div className="col-md-12 mb-3 d-flex p-0">
                  <div className="col-lg-12">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
                    <label className="copy-font p-0 m-0">Select Request Category</label>
                    <select className="form-control" name="category" onChange={onChange}>
                      {categories.length
                        ? categories.map((cat) => {
                            return (
                              <option key={cat.id} value={cat.id}>
                                {cat.category}
                              </option>
                            );
                          })
                        : null}
                    </select>
                  </div>
                </div>

                <div className="col-md-12 mb-3 d-flex">
                  <div>
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
                    <label className="copy-font p-0 mb-0">Budget Code(If Applicable)</label>
                    <input
                      className="form-control"
                      name="code"
                      value={code}
                      onChange={onChange}
                      placeholder="Budget Code(OPTIONAL)"
                    />
                  </div>
                </div>

                <div className="col-md-12 mb-3 d-flex">
                  <div>
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
                    <label className="copy-font p-0 mb-0">Description</label>
                    <textarea
                      className="form-control"
                      name="description"
                      value={description}
                      onChange={onChange}
                      rows="5"
                      cols="50"
                      placeholder="Request Description"
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 mt-5 text-center">
                {isLoadingRequest ? (
                  <button disabled={isLoadingRequest} className="btn btn-green" type="submit">
                    Requesting...
                  </button>
                ) : (
                  <button disabled={!submitForm()} className="btn btn-green" type="submit">
                    Request
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MakeRequest;
