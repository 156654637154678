import React from 'react';
import Moment from 'react-moment';
import PropTypes from 'prop-types';

const ViewDedicatedInvoice = ({
  invoice: {
    name,
    mac_id,
    address,
    email,
    unit,
    item_description,
    quantity,
    discount,
    price,
    sub_total,
    total,
    expiration_date,
    total_ex_vat,
    total_with_vat,
  },
}) => {
  return (
    <div id="view_invoice" className="modal fade in" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title my-2">Invoice Details</h4>
            <button type="button" className="close mr-2" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div className="modal-body detail p-0">
            <div className="row">
              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label1">
                  <label className="mt-1">Name</label>
                  <label>MAC ID</label>
                  <label>Email</label>
                  <label>Address</label>
                </div>
                <div className="col-lg-8 detail-result1 item-font">
                  <p className="mt-4 mb-2">{name}</p>
                  <p className="mb-2">{mac_id}</p>
                  <p className="mb-2">{email}</p>
                  <p className="mb-2">{address}</p>
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label2">
                  <label>Unit</label>
                  <label>Quantity</label>
                  <label>Item Description</label>
                </div>
                <div className="col-lg-8 detail-result2 item-font">
                  <p className="mt-4 mb-1">{unit}</p>
                  <p className="mb-1">{quantity}</p>
                  <p className="mb-1">{item_description}</p>
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label1">
                  <label className="mt-1">Discount</label>
                  <label className="mt-1">Price</label>
                  <label className="mt-1">Sub-Total</label>
                  <label className="">Total</label>
                </div>
                <div className="col-lg-8 detail-result2 item-font">
                  <p className="mt-4 mb-2">{discount}</p>
                  <p className="mb-2">{price}</p>
                  <p className="mb-2">{sub_total}</p>
                  <p className="mb-2">{total}</p>
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label3">
                  <label className="mt-1">Total Ex VAT</label>
                  <label className="mt-1">Total With VAT</label>
                  <label className="mt-1">Expiration Date</label>
                </div>
                <div className="col-lg-4 detail-result1 item-font">
                  <p className="mt-4 mb-2">{total_ex_vat}</p>
                  <p className="mb-2">{total_with_vat}</p>
                  <p className="mb-2">
                    <Moment format="DD-MM-YYYY">{expiration_date}</Moment>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ViewDedicatedInvoice.propTypes = {
  invoice: PropTypes.shape().isRequired,
};

export default ViewDedicatedInvoice;
