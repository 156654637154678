import React from 'react';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import ExpressWifiNavBar from '../../../../layouts/expresswifi/ExpressWifiNavBar';
import ExpressWifiCustmersSignupsBody from './ExpressWifiCustomerssignupsbody';

const ExpressWifiCustomersSignups = () => {
  const { profile } = useUser();
  const { firstName } = profile;
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <ExpressWifiNavBar currentPage="Customers signups" position={firstName} />
        <ExpressWifiCustmersSignupsBody />
      </main>
    </div>
  );
};

export default ExpressWifiCustomersSignups;
