import React from 'react';
import { Link } from 'react-router-dom';

const AdminNavBarItem = ({ url, name, src, target }) => {
  return (
    <li className="s-sidebar__nav-link">
      <Link to={url} target={target}>
        <span className="mr-2">
          <img src={src} alt="img" />
        </span>
        {name}
      </Link>
    </li>
  );
};

export default AdminNavBarItem;
