/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import ProcurementInstallationTable from './ProcurementInstallationTable';
import { useInstallations } from '../../../../../hooks/shared/installations/useInstallations';

const ProcurementInstallationBody = () => {
  const { installations, isLoading, searchInstallations, zones } = useInstallations();
  const [status, setStatus] = useState('');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');

  const filterByStatus = (e) => {
    const { value } = e.target;
    setStatus(value);
    const data = { status: value };
    searchInstallations(data);
  };

  const filterByZone = (e) => {
    const { value } = e.target;
    const data = { zone_id: value, status };
    searchInstallations(data);
  };

  const filterByDate = () => {
    const data = { from, to, status };
    searchInstallations(data);
  };

  return (
    <div className="container pt-5">
      <div className="row mt-5">
        <form className="form-inline mb-2 col-12">
          <div className="form-group d-flex mx-3">
            <small className="mr-1 text-nowrap"> Filter By:</small>
            <select className="form-control mr-2" name="status" value={status} onChange={filterByStatus}>
              <option>-- Status --</option>
              <option value="queued">Queued</option>
              <option value="on-going">On-going</option>
              <option value="completed">Completed</option>
              <option value="not completed">Not Completed</option>
              <option value="failed">Failed</option>
              <option value="awaiting_account_creation">Awaiting Account Creation</option>
            </select>
            <select className="form-control" onChange={filterByZone}>
              <option>-- Zone --</option>
              {zones
                ? zones.map((zone) => (
                    <option key={zone.id} value={zone.id}>
                      {zone.name}
                    </option>
                  ))
                : 'Loading Zones...'}
            </select>
          </div>
          <div className="form-group d-flex ml-3">
            <small className="mr-1">From: </small>
            <input
              type="date"
              className="form-control"
              name="from"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
            />
          </div>
          <div className="form-group d-flex ml-3">
            <small className="mr-1">To: </small>
            <input type="date" className="form-control" name="to" value={to} onChange={(e) => setTo(e.target.value)} />
          </div>
          <div className="form-group ml-3">
            <button type="button" className="btn btn-green" onClick={filterByDate}>
              Search
            </button>
          </div>
        </form>
        <ProcurementInstallationTable installations={installations} load={isLoading} />
      </div>
    </div>
  );
};

export default ProcurementInstallationBody;
