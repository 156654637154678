import React from 'react';

const TotalCustomers = () => {
  return (
    <div className="col-lg-3 col-md-3 col-sm-4">
      <div className="boxes border-box4">
        <div className="d-flex mt-3">
          <div className="mt-4 ml-3">
            <img
              src="https://res.cloudinary.com/teewhy/image/upload/v1547546147/hotspotadmincustomer.fw.png"
              alt="hotspot-customer icon"
              className="w-75 mt-3"
            />
          </div>
          <div className="mt-4">
            {/* <div className="count">{this.state.totalHotspotCustomers}</div> */}
            <div className="count">40</div>

            <h6 className="baby-fonts">Total Customers</h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalCustomers;
