import { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { stringify } from 'qs';
import { LTE_EXPIRED_CUSTOMERS } from '../../../services/api/asset-team/AssetTeamUrl';

const useCustomers = () => {
  const [loading, setLoading] = useState(false);
  const [perPage] = useState(25);
  const [currentExpiredLteCustomers, setCurrentExpiredLteCustomers] = useState([]);
  const [expiredLteCustomers, setExpiredLteCustomers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    async function getExpiredLteCustomers() {
      setLoading(true);
      try {
        return await axios.post(`${LTE_EXPIRED_CUSTOMERS}`, stringify({ action: 'expired_lte_customers' }));
      } catch (error) {
        return error;
      }
    }
    getExpiredLteCustomers()
      .then((response) => {
        if (response.data.status) {
          setExpiredLteCustomers(response.data.expired_lte_customers);
          setCurrentExpiredLteCustomers(response.data.expired_lte_customers.slice(0, perPage));
          setLoading(false);
        }
        setLoading(false);
        toast.error('Unable to get expired customers. Please try again later!');
      })
      .catch(() => {
        setLoading(false);
        toast.error('Something went wrong. Please try again later!');
      });
  }, [perPage]);
  const getCurrentExpiredLteCustomer = (param) => {
    if (currentExpiredLteCustomers.length >= expiredLteCustomers.length) {
      return;
    }
    setCurrentPage(param);

    setCurrentExpiredLteCustomers(
      currentExpiredLteCustomers.concat(expiredLteCustomers.slice(param * perPage - perPage, param * perPage)),
    );
  };
  return {
    loading,
    expiredLteCustomers,
    getCurrentExpiredLteCustomer,
    currentExpiredLteCustomers,
    currentPage,
  };
};
export default useCustomers;
