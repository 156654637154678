import axios from 'axios';
import toastr from 'toastr';

export const change_expired = async (initialState) => {
  const url = 'https://legacy.tizeti.com/geniatechapi/index.php';
  const formData = new URLSearchParams(initialState).toString();
  try {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    const res = await axios.post(url, formData, { headers: headers });
    if (res.data) {
      return res.data;
    }
  } catch (error) {
    toastr.error('Error fetching data');
  }
};
