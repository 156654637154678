import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CallUpdate from './CallUpdate';

const CallPromptModal = ({ onClose, show, callUser, profile, callId, calling, number }) => {
  const [open, setOpen] = useState(false);
  const makeCall = () => {
    callUser();
    onClose();
    setOpen(true);
  };
  const onCloseUpdate = () => {
    setOpen(false);
  };
  return (
    <>
      <Modal show={show} onHide={onClose} centered>
        <Modal.Header closeButton>
          <Modal.Title style={{ paddingLeft: '2rem' }}>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to call this customer?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => makeCall()}>
            Call
          </Button>
          <Button variant="primary" onClick={onClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <CallUpdate
        onClose={onCloseUpdate}
        show={open}
        profile={profile}
        callId={callId}
        calling={calling}
        number={number}
      />
    </>
  );
};
export default CallPromptModal;
