import React from "react";
import {
    accounts,
    ADMIN_DEPARTMENT,
    customer_support,
    NEW_PRODUCTS,
    NOC,
    operations,
    PROCUREMENT, RESEARCH_AND_DEVELOPMENT,
    SALES, TECHNICAL_SUPPORT
} from "../../../../../utils/config";
import moment from "moment";

const FeaturesDetail = ({feature}) => {

  return (
    <div id="features" className="modal fade in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
         aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title" id="myModalLabel" style={{ marginLeft: "20%" }}>Feature Request
                        Details</h4>
                    <button type="button" className="close custom-close" data-dismiss="modal" aria-hidden="true">×
                    </button>
                </div>
                <div className="row">
                    <div className="col-lg-12 d-flex">
                        <div className="col-lg-4 detail-label1">
                            <label id="fn">Department</label>
                            <label id="ln">Subject</label>
                        </div>

                        <div className="col-lg-8 detail-result1">
                            <p className="mt-4 mb-2">{feature.department_id === `${SALES}` ? <strong>SALES</strong> :
                              (feature.department_id === `${accounts}` ? <strong>ACCOUNTS</strong> :
                                (feature.department_id === `${customer_support}` ? <strong>CUSTOMER SUPPORT</strong> :
                                  (feature.department_id === `${ADMIN_DEPARTMENT}` ? <strong>ADMINISTRATION</strong> :
                                    (feature.department_id === `${NOC}` ? <strong>NOC</strong> :
                                      (feature.department_id === `${operations}` ? <strong>OPERATIONS</strong> :
                                        (feature.department_id === `${NEW_PRODUCTS}` ? <strong>NEW PRODUCTS</strong> :
                                          (feature.department_id === `${PROCUREMENT}` ? <strong>PROCUREMENT</strong> :
                                            (feature.department_id === `${RESEARCH_AND_DEVELOPMENT}` ? <strong>RESEARCH AND DEVELOPMENT</strong> :
                                              (feature.department_id === `${TECHNICAL_SUPPORT}` ? <strong>TECHNICAL SUPPORT</strong> : null
                                              )))))))))}</p>
                            <p className="mb-2">{feature.subject}</p>
                            {/*<p className="mb-2">Olawale Tolu</p>*/}
                        </div>
                    </div>
                    <hr/>
                    <div className="col-lg-12 d-flex">
                        <div className="col-lg-4 detail-label2">
                            <label id="detail">Details </label>
                        </div>

                        <div className="col-lg-8 detail-result2">
                            <p className="mt-4">
                                {feature.details}
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-12 d-flex">
                        <div className="col-lg-4 detail-label3">
                            <label id="status" style={{ display: 'block' }}>Date Reported</label>
                            {/*<label id="language" style={{ display: 'block' }}>Date Resolved</label>*/}
                        </div>

                        <div className="col-lg-8 detail-result3">
                            <p className="mt-4 mb-2">{moment(feature.created_at).format("DD-MM-YYYY")}</p>
                            {/*<p>N/A</p>*/}
                        </div>
                    </div>

                    <div className="col-lg-12 d-flex">
                        <div className="col-lg-4 detail-label4">
                            <label id="status" style={{ display: 'block' }}>Status</label>
                        </div>

                        <div className="col-lg-8 detail-result4">
                            <p className="mt-4">{feature.status}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
  );
};

export default FeaturesDetail;