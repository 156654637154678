/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import { CallLogContext } from '../../../../../../context/customer-support/CallLogContext';

const AddCallLog = () => {
  const { submitting, callLog, onChange, createCallLog } = useContext(CallLogContext);
  const { firstName, lastName, email, phoneNumber, note, resolution } = callLog;
  return (
    <div
      id="addCallLog"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Add Call Log
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body">
            <form className="form-horizontal" onSubmit={createCallLog}>
              <div className="form-group text-left">
                <div className="col-md-12 mb-3 d-flex">
                  <div>
                    <label className="copy-font p-0 mb-0">First Name</label>
                    <input
                      className="form-control"
                      name="firstName"
                      value={firstName}
                      onChange={onChange}
                      placeholder="Customer First Name"
                    />
                  </div>
                  <div>
                    <label className="copy-font p-0 mb-0">Last Name</label>
                    <input
                      className="form-control"
                      name="lastName"
                      value={lastName}
                      onChange={onChange}
                      placeholder="Customer Last Name"
                    />
                  </div>
                </div>
                <div className="col-md-12 mb-3 d-flex">
                  <div>
                    <label className="copy-font p-0 mb-0">Email</label>
                    <input
                      className="form-control"
                      name="email"
                      value={email}
                      onChange={onChange}
                      placeholder="Email"
                    />
                  </div>
                  <div>
                    <label className="copy-font p-0 mb-0">Phone Number</label>
                    <input
                      className="form-control"
                      name="phoneNumber"
                      type="text"
                      value={phoneNumber}
                      onChange={onChange}
                      placeholder="Phone Number"
                    />
                  </div>
                </div>
                <div className="col-md-12 mb-3 d-flex">
                  <div>
                    <label className="copy-font p-0 mb-0">Note</label>
                    <input className="form-control" name="note" value={note} onChange={onChange} placeholder="Note" />
                  </div>
                </div>

                <div className="col-md-12 mb-3 d-flex">
                  <div>
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
                    <label className="copy-font p-0 mb-0">Resolution</label>
                    <textarea
                      className="form-control"
                      name="resolution"
                      value={resolution}
                      onChange={onChange}
                      rows="5"
                      cols="50"
                      placeholder="Resolution"
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 mt-5 text-center">
                {submitting ? (
                  <button disabled={submitting} className="btn btn-green" type="submit">
                    Submitting...
                  </button>
                ) : (
                  <button className="btn btn-green" type="submit">
                    Submit
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddCallLog;
