/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import { stringify } from 'qs';
import { toast } from 'react-toastify';
import { LOADING_STARTS, LOADING_STOPS } from '../../../../../../../constants/types/shared/LoadingTypes';
import { GET_DATA } from '../../../../../../../constants/types/shared/CustomTypes';
import geniatechReducer from '../../../../../../../reducers/shared/geniatech/geniatechReducer';
import { LEGACY_GENIATECH_URL } from '../../../../../../../services/api/shared/installations/InstallationsUrl';

const useReboot = (url, params) => {
  const [{ data, loading, pages }, dispatch] = useReducer(geniatechReducer, {
    data: [],
    loading: false,
    pages: 0,
  });
  const [rebooting, setRebooting] = useState(false);
  const rebootAccessPoints = async (value) => {
    setRebooting(true);

    const response = await axios.post(`${LEGACY_GENIATECH_URL}`, value);
    if (response.data) {
      toast.success('Alert sent');
      setRebooting(false);
    } else {
      toast.error('Alert not sent');
      setRebooting(false);
    }
  };
  useEffect(() => {
    async function getData() {
      dispatch({ type: LOADING_STARTS });
      try {
        return await axios.post(url, stringify(params));
      } catch (e) {
        return e;
      }
    }
    getData()
      .then((response) => {
        if (response.data.status) {
          dispatch({ type: GET_DATA, payload: response.data.access_points });
        }
      })
      .catch(() => {
        dispatch({ type: LOADING_STOPS });
      });
  }, []);

  return { data, loading, pages, rebootAccessPoints, rebooting };
};

export default useReboot;
