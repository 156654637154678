import React from 'react';
import useStaticIpCustomers from '../hooks/useStaticIpCustomers';
import AppContext from '../../../../../../../context/shared/AppContext';
import { STATIC_IP_CUSTOMERS_URL } from '../api/StaticIpCustomersUrl';

const StaticIpCustomersProvider = ({ children }) => {
  const { data, loading, paginateChange, loadingForm, onChange, formValue, onSubmit, pages } = useStaticIpCustomers(
    `${STATIC_IP_CUSTOMERS_URL}`,
  );

  return (
    <AppContext.Provider
      value={{
        data,
        loading,
        paginateChange,
        loadingForm,
        onChange,
        formValue,
        onSubmit,
        pages,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export default StaticIpCustomersProvider;
