/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import NocNavBar from '../../../../layouts/noc/NocNavBar';
import { admin, API_URL, NOC } from '../../../../../utils/config';
import LearningPortal from '../../../shared/learning-portal/LearningPortal';

class NocLearningPortal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
    };
  }

  componentDidMount() {
    this.getUserDetails();
    this.fetchLoggedOnUser();
  }

  getUserDetails() {
    axios({
      method: 'get',
      url: `${API_URL}/employee/loggedOnEmployee`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    }).then((res) => {
      if (res.data) {
        this.setState({
          firstName: res.data.firstName,
        });
      }
    });
  }

  fetchLoggedOnUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employeeReg/logged`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((response) => {
        const { history } = this.props;
        if (response.data.id) {
          if (response.data.department_id !== `${NOC}` && response.data.department_id !== `${admin}`) {
            history.push('/staff/login');
            toastr.info('You are not allowed to view this page');
          }
        }
      })
      .catch(() => {});
  }

  render() {
    const { firstName } = this.state;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <NocNavBar position={firstName} currentPage="Learning Portal" />

          <div className="all-sides col-lg-12 col-md-12 col-sm-12">
            <div className="container">
              <div className="row mt-5">
                <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-4">
                  <LearningPortal department="NOC" />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default NocLearningPortal;
