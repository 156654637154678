/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import useXWFCustomers from '../../../../../hooks/express-wifi/customers/useXWFCustomers';

const UpdateXWFCustomers = ({ oneCustomer }) => {
  const { updateCustomerDetails } = useXWFCustomers();
  const { company_name, customer_name, retailer_name, phoneNumber } = oneCustomer;

  const [one, setOne] = useState({
    companyName: '',
    customerName: '',
    retailerName: '',
    phone: '',
  });

  const { companyName, customerName, retailerName, phone } = one;

  useEffect(() => {
    const result = {
      companyName: company_name,
      customerName: customer_name,
      retailerName: retailer_name,
      phone: phoneNumber,
    };
    setOne({ ...result });
  }, [oneCustomer]);

  const onFormSubmit = (e) => {
    e.preventDefault();
    const data = {
      company_name: companyName.trim(),
      customer_name: customerName.trim(),
      retailer_name: retailerName.trim(),
      phoneNumber: phone.trim(),
    };
    updateCustomerDetails(oneCustomer.id, data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOne({ ...one, [name]: value });
  };

  return (
    <div id="updateCustomer" className="modal fade mt-5" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header shadow">
            <h4 className="modal-title update_asset">Customer Details</h4>
            <button type="button" className="btn btn-outline-info btn-circle btn-close" data-dismiss="modal">
              x
            </button>
          </div>
          <div className="modal-body detail mb-4 p-0">
            <form className="col-md-12 p-0" onSubmit={onFormSubmit}>
              <div className="d-flex">
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="companyName">
                    Company Name
                  </label>
                  <input
                    type="text"
                    className="form-control border border-info pl-3 py-2"
                    id="companyName"
                    name="companyName"
                    value={companyName}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="customerName">
                    Customer Name
                  </label>
                  <input
                    type="text"
                    className="form-control border border-info pl-3 py-2"
                    id="customerName"
                    name="customerName"
                    value={customerName}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="d-flex">
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="retailerName">
                    Retailer Name
                  </label>
                  <input
                    type="text"
                    className="form-control border border-info pl-3 py-2"
                    id="retailerName"
                    name="retailerName"
                    value={retailerName}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="phone">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    className="form-control border border-info pl-3 py-2"
                    id="phone"
                    name="phone"
                    value={phone}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="text-center mt-3">
                <button type="submit" className="btn btn-green px-3">
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateXWFCustomers;
