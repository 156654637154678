/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';

class PowerConsumptionTable extends Component {
  render() {
    return (
      <tbody>
        <tr>
          <td>1</td>
          <td>CCR1036-12G-3S</td>
          <td className="text-center">69</td>
          <td nowrap="no-wrap" className="text-center">
            59
          </td>
        </tr>
      </tbody>
    );
  }
}

export default PowerConsumptionTable;
