import React from 'react';
import view from '../../../../../../assets/img/icons/view.png';

const CustomerSupportExpiredZonesTable = ({ items }) => {
  const tableData =
    items && items.length ? (
      items.map((item, i) => {
        // eslint-disable-next-line @typescript-eslint/camelcase
        const { zone, expired_count } = item;
        return (
          <tr className="text-nowrap">
            <td>{i + 1}</td>
            <td>{zone.toUpperCase()}</td>
            {/* eslint-disable-next-line @typescript-eslint/camelcase */}
            <td>{expired_count}</td>
            <td>
              <button type="submit" data-toggle="tooltip" data-placement="left" title="View">
                <span className="view1 ml-4">
                  <img src={view} alt="" data-toggle="modal" data-target="#viewExpiredDetails" />
                </span>
              </button>
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="3" className="text-center">
          No Expired Customers
        </td>
      </tr>
    );

  return tableData;
};

export default CustomerSupportExpiredZonesTable;
