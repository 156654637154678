import axios from 'axios';

export const get_all_downtime_hours = async (selectedDate, todayDate) => {
  //extra check to ensure the data is always correct
  function checkSelectedDate() {
    if (selectedDate.from !== undefined && selectedDate.to !== undefined) {
      return selectedDate;
    } else return { from: todayDate, to: todayDate };
  }

  const formData = new URLSearchParams(checkSelectedDate()).toString();

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
  };
  try {
    const res = await axios.post(`https://api.tizeti.com:8443/api/v1/downTime/getDowntime`, formData, {
      headers: headers,
    });
    if (res.data) {
      // console.log(res.data);
      return res.data;
    }
  } catch (error) {
    console.error(error);
  }
};

export const downtime_logger_action = async (data) => {
  const formData = new URLSearchParams(data).toString();

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
  };
  try {
    const res = await axios.post(`https://api.tizeti.com:8443/api/v1/downTime`, formData, {
      headers: headers,
    });
    if (res.data) {
      console.log(res.data);
      return res.data;
    }
  } catch (error) {
    console.error(error);
  }
};

export const get_all_down_hours_per_zone = async (selectedDate, todayDate) => {
  const now = new Date().toISOString().slice(0, 10);
  function checkSelectedDate() {
    if (selectedDate.from !== undefined && selectedDate.to !== undefined) {
      return selectedDate;
    } else return { from: todayDate || now, to: todayDate || now };
  }
  const formData = new URLSearchParams(checkSelectedDate()).toString();

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
  };

  try {
    const res = await axios.post(`https://api.tizeti.com:8443/api/v1/downTime/graphCount`, formData, {
      headers: headers,
    });
    if (res.data) {
      // console.log(res.data);
      return res.data;
    }
  } catch (error) {
    console.error('error fetching data');
  }
};

export const edit_downtime_logger = async (data, id) => {
  const formData = new URLSearchParams(data).toString();

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
  };
  try {
    const res = await axios.patch(`https://api.tizeti.com:8443/api/v1/downTime/${id}`, formData, {
      headers: headers,
    });
    console.log(res);
    if (res.status === 200) {
      return res.status;
    }
  } catch (error) {
    console.error(error);
  }
};
