import React from 'react';
import Box from '@mui/material/Box';
import NocNavBar from '../../../../layouts/noc/NocNavBar';
import DeviceLoggerBody from './DeviceLoggerBody';

function DeviceLoggerOverview() {
  const firstName = sessionStorage.getItem('firstName');
  const email = sessionStorage.getItem('officialEmail');
  const roleId = sessionStorage.getItem('roleId');

  return (
    <div>
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <NocNavBar currentPage="Device Data Logger" position={firstName} role_id={roleId} />

          <Box sx={{ mx: 2, mb: 3, mt: 17, maxWidth: '80vw' }} className="container">
            <DeviceLoggerBody />
          </Box>
        </main>
      </div>
    </div>
  );
}

export default DeviceLoggerOverview;
