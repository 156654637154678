/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import qs from 'qs';
import InvoiceForm from './InvoiceForm';
import erase from '../../../../../assets/img/icons/delete.png';
import { NoItemRow } from '../../../../../utils/loader/loadingHelper';
import * as url from '../../../../../services/api/shared/installations/InstallationsUrl';

const CustomerInvoiceBody = () => {
  const invoiceDetailsState = {
    customerName: '',
    customerAddress: '',
    customerReference: '',
    date: '',
    receiptNumber: '',
    receiptNo: '',
    receiptNum: '',
    reference: '',
    salesPerson: '',
    itemDescription: '',
    itemCode: '',
    price: '',
    quantity: '',
  };
  const [itemsTable, setItemsTable] = useState([]);
  const [invoiceDetails, setInvoiceDetails] = useState(invoiceDetailsState);
  const {
    customerName,
    customerAddress,
    date,
    receiptNumber,
    receiptNum,
    referenceNo,
    receiptNo,
    salesPerson,
    currency,
  } = invoiceDetails;

  const updateData = (data) => {
    setInvoiceDetails({
      ...data,
      ...invoiceDetails,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInvoiceDetails({ ...invoiceDetails, [name]: value });
  };

  const downloadInvoice = (e) => {
    e.preventDefault();
    const params = qs.stringify(
      {
        name: customerName,
        address: customerAddress,
        date,
        receiptno: receiptNumber,
        sales_person: salesPerson,
        currency,
        vat: '100%',
        items: itemsTable.map((cust) => {
          return `["${cust.itemCode}", "${cust.itemDescription}", "${cust.quantity}", "${cust.price}"]`;
        }),
      },
      { arrayFormat: 'brackets', encode: false },
    );
    window.location.href = `${url.DOWNLOAD_CUSTOMER_INVOICE_URL}?${params}`;
  };

  const searchInvoice = (e) => {
    e.preventDefault();
    const params = qs.stringify(
      {
        receiptno: receiptNo,
      },
      { arrayFormat: 'brackets', encode: false },
    );
    window.location.href = `${url.SEARCH_CUSTOMER_INVOICE_URL}?${params}`;
  };

  const generateInvoice = (e) => {
    e.preventDefault();
    const params = qs.stringify(
      {
        receiptno: receiptNum,
        reference: referenceNo,
      },
      { arrayFormat: 'brackets', encode: false },
    );
    window.location.href = `${url.GENERATE_CUSTOMER_INVOICE_URL}?${params}`;
  };

  const addToTable = (data) => {
    setItemsTable([...itemsTable, data]);
  };

  const tableData =
    itemsTable && itemsTable.length ? (
      itemsTable.map((item, i) => {
        return (
          <tr key={i}>
            <td>{item.itemCode}</td>
            <td>{item.itemDescription}</td>
            <td>{item.quantity}</td>
            <td>{item.quantity * item.price}</td>
            <td>
              <div className="ml-3">
                <button
                  type="button"
                  className="noBorder"
                  onClick={() => {
                    itemsTable.splice(i, 1);
                    setItemsTable([...itemsTable]);
                  }}
                >
                  <span className="view2">
                    <img src={erase} alt="" data-toggle="tooltip" data-placement="top" title="Delete" />
                  </span>
                </button>
              </div>
            </td>
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan="5" text="No Item" />
    );

  return (
    <div className="col-12 after-summary">
      <div className="x-panel">
        <InvoiceForm
          downloadInvoice={downloadInvoice}
          searchInvoice={searchInvoice}
          generateInvoice={generateInvoice}
          handleChange={handleChange}
          updateData={updateData}
          invoiceDetails={invoiceDetails}
          updateInvoiceDetials={setInvoiceDetails}
          tableData={tableData}
          addToTable={addToTable}
        />
      </div>
    </div>
  );
};

export default CustomerInvoiceBody;
