/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

const Input = ({ onChange, value, name, type }) => {
  return (
    <input
      style={{ borderTop: '1px solid' }}
      className="form-control col-md-6"
      name={name}
      value={value}
      onChange={onChange}
      type={type}
    />
  );
};
Input.propTypes = { value: PropTypes.string, name: PropTypes.string, type: PropTypes.string, onChange: PropTypes.func };
export default Input;
