import * as load from '../../../../../constants/types/shared/LoadingTypes';
import { GET_EMPLOYEES_ASSIGNED_KPIS, GET_KPIS } from '../KpiTypes';

const kpiReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case load.LOADING_STARTS:
      return {
        ...state,
        isLoading: true,
      };
    case GET_KPIS:
      return {
        ...state,
        kpis: { logs: payload.logs, count: payload.count, pages: payload.pages },
        isLoading: false,
      };
    case GET_EMPLOYEES_ASSIGNED_KPIS:
      return {
        ...state,
        kpis: { employeeAssignedKpis: payload.logs, count: payload.count, pages: payload.pages },
        isLoading: false,
      };
    case load.LOADING_STOPS:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default kpiReducer;
