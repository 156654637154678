/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
/* eslint-disable react/button-has-type */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import PropTypes from 'prop-types';
import logo from '../../../../../assets/img/logo/Tizeti-Logo.png';
import { resetPassword } from '../../../../../actions/auth/AuthActions';

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      confirm_password: '',
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  onSubmit = (e) => {
    e.preventDefault();

    const { token } = this.props.match.params;
    const { password, confirm_password } = this.state;
    const { history } = this.props;

    if (password !== confirm_password) {
      toast.info('passwords do not match');
    } else {
      const updatePassword = {
        password,
        confirm_password,
        token,
      };

      this.props.resetPassword(updatePassword, history);
    }
  };

  render() {
    const { password, confirm_password } = this.state;
    const { loading } = this.props;

    return (
      <div className="main-wrapper">
        <div className="col-lg-12 carrier d-flex">
          <div className="col-lg-6 col-md-6 explain d-none d-sm-none d-md-block d-lg-block">
            <div className="intro">
              <h3 style={{ color: 'white' }}>Welcome To Tizeti OS </h3>
              <p>
                We built Tizeti OS with the aim of building a better experience when performing your daily tasks. We are
                currently roling out more features that would further enhance your work.
                <br />
                <br />
                If you have any complaints or suggestions as to how this platform will be better, please send us an
                email to rd@tizeti.com
                <br />
                <br />
                Change your password here
                <br />
                <br />- Research and Development Team
              </p>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="login col-sm-12 col-lg-12 col-md-12">
              <div className="tizeti-logo">
                <img src={logo} alt="" />
              </div>

              <form onSubmit={this.onSubmit} className="form-horizontal login-form col-sm-12 col-lg-12 col-md-12">
                <div className="form-group col-lg-9 col-sm-12">
                  <div className="mb-3">
                    <label className="label2">Enter Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter password"
                      name="password"
                      value={password}
                      onChange={this.onChange}
                    />
                  </div>

                  <div className="mb-3">
                    <label className="label2">Confirm Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Confirm password"
                      name="confirm_password"
                      value={confirm_password}
                      onChange={this.onChange}
                    />
                  </div>

                  <br />
                  <div className="wrapper-button mb-3">
                    {loading ? (
                      <button
                        className="btn btn-green"
                        disabled={loading}
                        style={{
                          backgroundColor: '#036D61',
                        }}
                      >
                        Changing...
                      </button>
                    ) : (
                      <button className="btn btn-green">RESET</button>
                    )}
                  </div>
                  <div className="already-have">
                    <p className="text-center">
                      Already have an account? <Link to="/login">Login</Link>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.auth.reset,
});

ResetPassword.propTypes = {
  resetPassword: PropTypes.func,
  loading: PropTypes.bool,
  history: PropTypes.shape(),
};

export default connect(
  mapStateToProps,
  { resetPassword },
)(ResetPassword);
