/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import NetworkOperationsSideBar from '../../../../layouts/noc/NetworkOperationsSideBar';
import NocLogo from '../../../../layouts/noc/NocLogo';
import Logout from '../../../shared/auth/Logout';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import KpiTable from './KpiTable';
import useCheckTwoDepartments from '../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';

const Kpi = ({ history }) => {
  useCheckTwoDepartments(history);
  const { profile } = useUser();
  const { firstName } = profile;

  return (
    <div className="main-wrapper">
      <header className="topbar shade bg-white fixed-top">
        <nav className="navbar navbar-expand-lg navbar-light top-navbar fixed-top">
          <NocLogo />
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse mt-3" id="navbarText">
            <ul className="navbar-nav">
              <li className="nav-item active">
                <Link className="nav-link" to="#">
                  <h3>
                    Ticket
                    <span className="sr-only">(current)</span>
                  </h3>
                </Link>
              </li>
            </ul>
            <span className="navbar-text">
              <h6>
                <i>{firstName}</i>
                <Logout />
              </h6>
            </span>
          </div>
        </nav>
      </header>

      <div className="all-sides col-lg-12 col-md-12 col-sm-12">
        <NetworkOperationsSideBar />
        <KpiTable />
      </div>
    </div>
  );
};

Kpi.propTypes = {
  history: PropTypes.shape(),
};
export default Kpi;
