/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { LOGGED_IN_USER_URL } from '../../services/api/auth/AuthUrl';
import { GET_USER } from '../../constants/types/AuthTypes';

export const getLoggedInUser = () => async (dispatch) => {
  try {
    const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      dispatch({
        type: GET_USER,
        payload: response.data,
      });
    }
  } catch (e) {
    // eslint-disable-next-line no-empty
    if (e) {
    }
  }
};
