/* eslint-disable @typescript-eslint/camelcase */
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useUser } from '../../shared/user/useUser';
// eslint-disable-next-line import/no-unresolved
import { GET_USER_ASSIGNED_INSTALLATION } from '../../../services/api/shared/installations/InstallationsUrl';
import helpers from '../../../utils/helpers';

const useInstallations = () => {
  const { profile } = useUser();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [range, setRange] = useState({ from: '', to: '' });

  const searchInstallations = async (params) => {
    setLoading(true);
    try {
      const response = await axios.post(`${GET_USER_ASSIGNED_INSTALLATION}/${profile.id}`, params, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.data) {
        setData(response.data.data.rows);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
    }
  };
  const onSearchChange = (e) => {
    setRange({ ...range, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    async function getData() {
      const params = { date: new Date().toISOString().slice(0, 10), employee_id: profile.id };
      setLoading(true);
      try {
        return await axios.post(`${GET_USER_ASSIGNED_INSTALLATION}/1`, params, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });
      } catch (e) {
        return e;
      }
    }
    async function getAssignedInstallations() {
      setLoading(true);
      const params = {
        from: helpers.formattedCurrentDate(),
        to: helpers.formattedCurrentDate(),
      };

      try {
        return axios.post(`${GET_USER_ASSIGNED_INSTALLATION}/${profile.id}`, params, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });
      } catch (e) {
        return e;
      }
    }

    getData()
      .then((response) => {
        setData(response.data.data.rows);
        setLoading(false);
      })
      .catch(() => setLoading(false));
    getAssignedInstallations()
      .then((installs) => {
        if (installs.data.data) {
          setData(installs.data.data.rows);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, [profile.id]);
  return { data, loading, searchInstallations, onSearchChange, range };
};
export default useInstallations;
