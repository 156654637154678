/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React, { ReactNode } from 'react';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../utils/loader/loadingHelper';

const BulkCustomerDetailsTable = ({ file, load }) => {
  const tableData =
    file && file.length ? (
      file.map((item, i) => {
        const {
          username,
          name,
          mail,
          customer_ref,
          basestation,
          zone,
          agent,
          country,
          region,
          online_status,
          lastconnected,
          ipaddress,
          address,
          subscription_status,
          lastactivation_time,
          phone,
          expiration_date,
        } = item;

        return (
          <tr className="" key={i}>
            <td>{i + 1}</td>
            <td>{username}</td>
            <td>{name}</td>
            <td>{mail}</td>
            <td>{customer_ref}</td>
            <td>{basestation}</td>
            <td>{zone}</td>
            <td>{agent}</td>
            <td>{country}</td>
            <td>{region}</td>
            <td>{online_status}</td>
            <td>{lastconnected}</td>
            <td>{ipaddress}</td>
            <td>{address}</td>
            <td>{subscription_status}</td>
            <td>{lastactivation_time}</td>
            <td>{phone}</td>
            <td>{expiration_date}</td>
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan="20" text="There Are No Customers" />
    );
  return (
    <div className="table-responsive content col-12 px-1">
      <table className="table">
        <thead>
          <tr className="text-nowrap">
            <th>#</th>
            <th>MAC ID</th>
            <th>Name</th>
            <th>Mail</th>
            <th>Customer Reference</th>
            <th>Basestations</th>
            <th>Zone</th>
            <th> Agent</th>
            <th> Country</th>
            <th> Region</th>
            <th>Online Status</th>
            <th> Last Connected</th>
            <th> IP Address</th>
            <th> Address</th>
            <th> Subscription Status</th>
            <th> Last Activation Time</th>
            <th> Phone</th>
            <th>Expiration Date</th>
          </tr>
        </thead>

        <tbody>{load ? <TizetiGIFLogoTable colSpan="20" /> : tableData}</tbody>
      </table>
    </div>
  );
};

export default BulkCustomerDetailsTable;
