/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import PATHS from '../../../routes/urls';
import AdminNavBarItem from '../super-admin/AdminNavBarItem';

const span13 = (tabName) => (
  <span className="text-uppercase" style={{ fontSize: '14px' }}>
    {tabName}
  </span>
);
const groupIcon = 'https://res.cloudinary.com/tizeti/image/upload/v1542971013/Group-3.png';

const VpSalesSideBar = () => {
  return (
    <div className="s-layout__sidebar">
      <Link className="s-sidebar__trigger" to="#">
        <i className="fa fa-bars mr-3" />
      </Link>

      <nav className="s-sidebar__nav">
        <div className="">
          <Link className="navbar-brand-logo" to="/super-admin">
            <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971047/Tizeti-Logo.png" alt="homepage" />
          </Link>

          <ul className="">
            <div className="">
              <AdminNavBarItem url="/vp-sales" name={span13('Overview')} src={groupIcon} target="_self" />
              <AdminNavBarItem url={PATHS.SALES_OVERVIEW} name={span13('SALES')} src={groupIcon} target="_self" />
              <AdminNavBarItem
                url={PATHS.PARTNERSHIP_OVERVIEW}
                name={span13('PARTNERSHIP')}
                src={groupIcon}
                target="_self"
              />

              <AdminNavBarItem
                url={PATHS.BUSINESS_DEVELOPMENT_OVERVIEW}
                name={span13('BUSINESS DEVELOPMENT')}
                src={groupIcon}
                target="_self"
              />

              {/* <AdminNavBarItem
                url={PATHS.SUPER_ADMIN_REQUISITIONS}
                name={span13('Requisitions')}
                src={groupIcon}
                target="_self"
              /> */}
            </div>
          </ul>
        </div>
      </nav>
    </div>
  );
};
export default VpSalesSideBar;
