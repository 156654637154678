import {
  UPDATES_CHANGED,
  RESOLUTION_ACTION_CHANGED,
  RESOLUTION_TIME_CHANGED,
  STATUS_FILTER_CHANGE,
  EMPLOYEE_FILTER_CHANGE,
  EDIT_TICKET,
  ERROR_UPDATING_TICKET
} from '../../../constants/types/noc/NocTypes';

const INITIAL_STATE = {
  updates : '',
  resolution_action : '',
  resolution_time : '',
  ticketsList : [],
  status: '',
  error: '',
  status_change: '',
  personnel_change: '' 
}




export const EDIT_TICKET_REDUCER = (state = INITIAL_STATE, action) => {
  switch(action.type){
    case UPDATES_CHANGED:
      return {...state, updates : action.payload}
    
    case RESOLUTION_ACTION_CHANGED:
      return {...state, resolution_action : action.payload}

    case RESOLUTION_TIME_CHANGED:
      return {...state, resolution_time : action.payload}

    case STATUS_FILTER_CHANGE:
      return {...state, status_change : action.payload}

    case EMPLOYEE_FILTER_CHANGE:
      return {...state, personnel_change : action.payload}
    
    case EDIT_TICKET:
      return {...state, status: 1,  updates : '', resolution_action : '', resolution_time : ''}

    case ERROR_UPDATING_TICKET:
      return {...state, status: 0}
    
    default:
      return state;
  }
}




