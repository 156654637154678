import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AvailableYears from '../AvailableYears';
import CompareByMonthsChart from '../CompareByMonths/CompareByMonthsChart';
import { tizeti_asset_active_inactive_users } from '../../../../../../../actions/coo-statistics/ActiveExpiredTurboUsers';
import { initialActiveUsersPerAssetZone } from '../ActiveExpiredUsersByAssetZone';
import { toast } from 'react-toastify';
import CreatableSelect from 'react-select/creatable';
// import CompareByMonthsForActiveInactiveUsersTable from '../CompareByMonths/CompareByMonthsForActiveInactiveUsersTable';

const today = new Date();
const month = today.getMonth();
const year = today.getUTCFullYear();
const options = [
  { value: 0, label: 'Jan' },
  { value: 1, label: 'Feb' },
  { value: 2, label: 'Mar' },
  { value: 3, label: 'Apr' },
  { value: 4, label: 'May' },
  { value: 5, label: 'Jun' },
  { value: 6, label: 'Jul' },
  { value: 7, label: 'Aug' },
  { value: 8, label: 'Sep' },
  { value: 9, label: 'Oct' },
  { value: 10, label: 'Nov' },
  { value: 11, label: 'Dec' },
];

export default function CompareByMonthsForActiveInactiveUsers({
  getZones,
  state,
  sumOfActiveAndInactiveUsers,
  getAllActiveUsersCountValue,
  getAllInActiveUsersCountValue,
}) {
  const [selectedYear, setSelectedYear] = useState(year);
  const [selectedMonths, setSelectedMonths] = useState(options[month]);
  const [newValueDeps, setNewValueDeps] = useState([]);
  const [actionValueDeps, setActionValueDeps] = useState({});
  const [monthInstance, setMonthInstance] = useState([
    {
      monthValue: month,
      // sumOfActiveAndInactiveUsers,
      getAllActiveUsersCountValue,
      getAllInActiveUsersCountValue,
    },
  ]);
  const [isMonthClearable, setIsMonthClearable] = useState(true);
  const [newGetAllActiveUsersCountData, setNewGetAllActiveUsersCountData] = useState([]);
  const [newGetAllInActiveUsersCountData, setNewGetAllInActiveUsersCountData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const animatedComponents = makeAnimated();

  const handleYearChange = (year) => {
    setSelectedYear(year);
  };

  const styles = {
    multiValue: (base, state) => {
      return state.data.value === month ? { ...base, backgroundColor: 'gray' } : base;
    },
    multiValueLabel: (base, state) => {
      return state.data.value === month ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 } : base;
    },
    multiValueRemove: (base, state) => {
      return state.data.value === month ? { ...base, display: 'none' } : base;
    },
  };

  // run the effect to get the active and inactive data for the default particular month
  useEffect(() => {
    if (sumOfActiveAndInactiveUsers.length > 0) {
      setMonthInstance([
        {
          // ...monthInstance,
          // sumOfActiveAndInactiveUsers,
          getAllActiveUsersCountValue,
          getAllInActiveUsersCountValue,
          selectedYear: year,
          monthValue: month,
        },
      ]);
    }
  }, [sumOfActiveAndInactiveUsers, getAllActiveUsersCountValue, getAllInActiveUsersCountValue]);

  // this effect runs the query to get the values of the selected months this is simply fetching the data for the selected months
  useEffect(() => {
    if (selectedMonths.length > 1 && getZones.length > 0) {
      const lastMonthIndex = selectedMonths.length - 1;
      const lastMonthValue = selectedMonths[lastMonthIndex].value;
      const firstDayOfMonth = `${selectedYear}-${String(lastMonthValue + 1).padStart(2, '0')}-01`;
      const lastDayOfMonth = new Date(selectedYear, lastMonthValue + 1, 0).getDate();
      const fromDate = `${selectedYear}-${String(lastMonthValue + 1).padStart(2, '0')}-${lastDayOfMonth}`;

      const fetchData = async (type) => {
        try {
          setIsLoading(true);
          const allCustomers = await Promise.all(
            getZones.map(async (zone) => {
              const data = await tizeti_asset_active_inactive_users({
                ...initialActiveUsersPerAssetZone,
                from: fromDate,
                to: fromDate,
                zone: zone.name,
                type,
              });
              return { data };
            }),
          );

          if (getZones.length === allCustomers.length) {
            const allUsersCountData = allCustomers.map((data) =>
              data.data.status === true && data.data.data.length > 0
                ? data.data.data[data.data.data.length - 1].count
                : 0,
            );

            if (type === 'expiration') {
              setNewGetAllInActiveUsersCountData(allUsersCountData);
            } else {
              setNewGetAllActiveUsersCountData(allUsersCountData);
            }
          }
        } catch (error) {
          console.error(`Error fetching ${type} data:`, error);
          toast.error(`Error fetching ${type} data:`, error);
        } finally {
          setIsLoading(false);
        }
      };

      if (actionValueDeps.action === 'select-option') {
        fetchData('active');
        fetchData('expiration');
      }
    }
  }, [newValueDeps]);

  useEffect(() => {
    if (newGetAllActiveUsersCountData.length > 0 && newGetAllInActiveUsersCountData.length > 0) {
      setMonthInstance((prevMonthInstance) => [
        ...prevMonthInstance,
        {
          // ...prevMonthInstance[0],
          monthValue: selectedMonths[selectedMonths.length - 1].value,
          getAllActiveUsersCountValue: newGetAllActiveUsersCountData,
          getAllInActiveUsersCountValue: newGetAllInActiveUsersCountData,
          isLoading,
          selectedYear,
        },
      ]);
    }
  }, [newGetAllInActiveUsersCountData]);

  //get Active and inactive of default years
  const handleMonthChange = (newValue, actionValue) => {
    // console.log(newValue);
    setNewValueDeps(newValue);
    setActionValueDeps(actionValue);
    // console.log(actionValue);
    if (actionValue.action === 'select-option') {
      setSelectedMonths(newValue);
    }
    if (actionValue.action === 'remove-value' && actionValue.option !== options[month]) {
      setSelectedMonths(newValue);
      if (monthInstance.length > 1) {
        const removeClearedMonthInstace = monthInstance.filter(
          (val) => val.monthValue !== actionValue.removedValue.value,
        );
        setMonthInstance(removeClearedMonthInstace);
      }
    }
    if (actionValue.action === 'clear') {
      setSelectedMonths(options[month]);
      if (monthInstance.length > 1) {
        setMonthInstance(monthInstance.slice(0, 1));
      }
    }
  };

  // reset the month instance when the year changes
  // if (selectedYear !== year) {
  //   setSelectedMonths(options[month]);
  // }
  return (
    <div>
      <Typography variant="h4">Active and Expired users per Month by asset zone</Typography>

      <Box sx={{ mb: 5 }}>
        <AvailableYears selectedYear={selectedYear} onYearChange={handleYearChange} />
      </Box>

      <Box width={'32rem'} sx={{ m: 3 }}>
        {/* <Select
          styles={styles}
          components={animatedComponents}
          value={selectedMonths}
          isMulti
          options={options}
          isClearable={isMonthClearable}
          onChange={handleMonthChange}
        /> */}
        <CreatableSelect
          styles={styles}
          components={animatedComponents}
          value={selectedYear !== year ? { value: '', label: '' } : selectedMonths}
          isMulti
          options={options}
          isClearable={isMonthClearable}
          onChange={handleMonthChange}
        />
      </Box>

      {/* <CompareByMonthsForActiveInactiveUsersTable
        getZones={getZones}
        monthInstance={monthInstance}
        isLoading={isLoading}
      /> */}
      <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'} gap={2}>
        <Typography>Key</Typography>
        <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'} gap={0.5}>
          <Box width={10} height={10} sx={{ background: '#7eeb7e' }}></Box>
          <Typography>Active Users</Typography>
        </Box>
        <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'} gap={0.5}>
          <Box width={10} height={10} sx={{ background: '#eb7e8d' }}></Box>
          <Typography>Expired Users</Typography>
        </Box>
      </Box>

      <CompareByMonthsChart getZones={getZones} monthInstance={monthInstance} isLoading={isLoading} />
    </div>
  );
}
