import { useReducer, useEffect } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import retailersReducer from '../../../../../reducers/new-products/products/hotspot/retailers/retailersReducer';
import * as url from '../../../../../services/api/hotspot/HotspotUrls';
import * as types from '../../../../../constants/types/new-products/products/hotspot/HotspotTypes';
import { FETCHING_STARTS } from '../../../../../constants/types/shared/LoadingTypes';

const useRetailers = () => {
  const [{ loadingPending, loadingActivated, activated, pending, retailer, fetchingRetailer }, dispatch] = useReducer(
    retailersReducer,
    {
      loadingPending: false,
      loadingActivated: false,
      activated: [],
      pending: [],
      retailer: [],
      fetchingRetailer: false,
    },
  );

  const fetchActivated = async () => {
    dispatch({
      type: types.START_LOADING_ACTIVATED_RETAILERS,
    });

    try {
      const response = await axios.get(`${url.GET_ACTIVATED_RETAILERS_URL}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.data.status === true) {
        dispatch({
          type: types.GET_ACTIVATED_RETAILERS,
          payload: response.data.data,
        });
      }
    } catch (e) {
      toastr.info(`Something went wrong!`);
      dispatch({
        type: types.STOP_LOADING_ACTIVATED_RETAILERS,
      });
    }
  };

  const fetchPending = async () => {
    dispatch({
      type: types.START_LOADING_PENDING_RETAILERS,
    });

    try {
      const response = await axios.get(`${url.GET_PENDING_RETAILERS_URL}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response.data.status === true) {
        dispatch({
          type: types.GET_PENDING_RETAILERS,
          payload: response.data.data,
        });
      }
    } catch (error) {
      toastr.info(`Something went wrong!`);
      dispatch({
        type: types.START_LOADING_PENDING_RETAILERS,
      });
    }
  };

  const activateRetailer = async (id) => {
    const data = {
      accountid: id,
    };
    try {
      const response = await axios.post(`${url.ACTIVATE_RETAILER_URL}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response.data.status) {
        toastr.success('Retailer was successfully activated, Page will automatically refresh');

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toastr.error('Failed to update accounts');
      }
    } catch (error) {
      toastr.error('ERROR, Please check your internet connection');
    }
  };

  const getRetailer = async (id) => {
    dispatch({
      type: FETCHING_STARTS,
    });
    try {
      const response = await axios.get(`${url.GET_ONE_RETAILER_URL}${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response.data.status === true) {
        dispatch({
          type: types.GET_RETAILER,
          payload: response.data.data[0],
        });
      }
    } catch (e) {
      toastr.info('unabel to get retailers');
    }

    // data: Array(1)
    // 0: {businessName: "testbusiness", fullname: "test me", address: "sjsjssjs", lat: "null", lng: "null", …}
    // length: 1
    // __proto__: Array(0)
    // errors: []
    // status: true
    //   .then((response)=>{
    //     if(response.data.status){
    //
    //       this.setState({
    //
    //         profileBusinessName : response.data.data[0].businessName,
    //         profileEmail : response.data.data[0].email,
    //         profileFullName : response.data.data[0].fullname,
    //         profilePhone : response.data.data[0].phone,
    //         profileAddress: response.data.data[0].address,
    //
    //         // profileCreated : response.data.data[0].created,
    //         profileCreated : moment(response.data.data[0].created, 'YYYY-MM-DD HH:MI:SS.FF TZH:TZM').format('YYYY-MM-DD'),
    //         profileStatus : response.data.data[0].status,
    //         profileAccountID: response.data.data[0].accountid,
    //         profileBalance: response.data.data[0].balance,
    //
    //         profileLat: response.data.data[0].lat,
    //         profileLng: response.data.data[0].lng,
    //
    //
    //       })
    //     }
    //
    //   })
    //   .catch((error)=>{
    //     //   toastr.error("Please check your internet connection")
    //     toastr.error(error)
    //   })
    //
    // this.setState({
    //   selectedRetailer: retailerid
    // })
  };

  useEffect(() => {
    fetchActivated();
    fetchPending();
  }, []);

  return {
    loadingPending,
    loadingActivated,
    activated,
    pending,
    activateRetailer,
    getRetailer,
    retailer,
    fetchingRetailer,
  };
};

export default useRetailers;
