/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import cancel from '../../../../../assets/img/icons/cancel.png';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import { useCleanLTE } from '../../../../../hooks/field-support/clean-imsi/useCleanLTE';
import { useUserProfile } from '../../../../../hooks/shared/user/useUserProfile';
import { OPERATIONS } from '../../../../../utils/departments/helpers';
import { NoItemRow } from '../../../../../utils/loader/loadingHelper';

const CleanLTETable = ({ items, isLoading }) => {
  console.log(items);
  const { user, getUserProfile, getLoggedInUser } = useUserProfile();
  const { cleanLTE } = useCleanLTE();
  const { department_id } = user;
  useEffect(() => {
    getUserProfile();
    getLoggedInUser();
  }, []);
  const colSpan = '8';
  const [request, setRequest] = useState(null);

  const getRequest = (items) => {
    console.log(items.imsi);
    const data = {
      imsi: items.imsi,
    };
    cleanLTE(data);
  };

  const tableData = items ? (
    <tr className="text-nowrap">
      {/* <td>{i + 1}</td> */}
      <td>{items.imsi}</td>
      <td>
        {items.user.firstName} {items.user.lastName}
      </td>
      <td>{items.user.phoneNumber}</td>
      <td>{items.user.email}</td>
      <td>{items.user.address}</td>

      <td>
        <div className="d-flex">
          {department_id === `${OPERATIONS}` && Number(user.role_id) > 1 ? (
            <>
              <button
                type="submit"
                className="mx-1 noBorder"
                //   data-toggle="modal"
                //   data-placement="left"
                //   data-target="#deleteIMSI"
                onClick={() => getRequest(items)}
              >
                <span className="delete">
                  <img src={cancel} alt="" data-toggle="tooltip" data-placement="left" title="Delete" />
                </span>
              </button>
            </>
          ) : null}
        </div>
      </td>
    </tr>
  ) : (
    <NoItemRow colSpan={colSpan} text="There are no records" />
  );
  return (
    <div className="card x-panel mt-2 px-0">
      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <thead>
            <tr className="">
              <th>IMSI</th>
              <th>Name</th>
              <th>Phone</th>
              <th className="text-wrap">Email</th>
              <th>Address</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td className="text-center">
                  <img src={loader} alt="loading gif" />
                </td>
              </tr>
            ) : (
              tableData
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CleanLTETable;
