/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useLoginLogs } from '../../../../../hooks/new-products/products/wificall/customers/useLoginLogs';
import LogsTable from './LogsTable';

const LogsBody = () => {
  const { getLoginLogByDate, getLoginLogByPhone, isLoading, users, pages, count } = useLoginLogs();

  const [from, setFrom] = useState(
    `${new Date(new Date().getTime() - 14 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10)}`,
  );
  const [to, setTo] = useState(
    `${new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10)}`,
  );
  const [page] = useState(1);
  const [phone, setPhone] = useState('');

  const data = { from, to };

  useEffect(() => {
    getLoginLogByDate(data, page);
  }, []);

  const handlePageClick = async (e) => {
    const selectedPage = e.selected + 1;
    getLoginLogByDate(data, selectedPage);
  };

  const filterByDate = (e) => {
    e.preventDefault();
    getLoginLogByDate(data);
  };

  const searchByPhone = (e) => {
    e.preventDefault();
    const phoneSearch = {
      phoneNumber: phone,
    };
    getLoginLogByPhone(phoneSearch);
  };

  const validateForm = () => {
    return phone.length > 0 || (from.length > 0 && to.length > 0);
  };

  return (
    <div className="col-12 mt-5">
      <div className="mr-2 mb-2">
        <form onSubmit={searchByPhone} className="form-inline ml-1">
          <div className="form-group col-12">
            <input
              className="form-control w-100"
              value={phone}
              name="keyword"
              type="number"
              placeholder="Search using phone"
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
        </form>
      </div>
      <div className="d-flex mb-2">
        <form onSubmit={filterByDate} className="form-inline">
          <div className="form-group mx-3">
            <small className="mr-1">From: </small>
            <input
              type="date"
              className="form-control"
              name="from"
              onChange={(e) => setFrom(e.target.value)}
              value={from}
            />
          </div>
          <div className="form-group mr-3">
            <small className="mr-1">To: </small>
            <input type="date" className="form-control" name="to" onChange={(e) => setTo(e.target.value)} value={to} />
          </div>
          {isLoading ? (
            <button type="button" className="btn btn-green py-1" disabled>
              Fetching...
            </button>
          ) : (
            <button type="submit" className="btn btn-green ml-3" disabled={!validateForm()}>
              Submit
            </button>
          )}
        </form>
        {users.length ? (
          <div className="d-none d-md-flex mr-3">
            <small className="m-2 copy-font">Total:</small>{' '}
            <span className="badge badge-success mb-2 payslipfont">{count}</span>
          </div>
        ) : null}
      </div>
      <LogsTable users={users} load={isLoading} />

      {pages > 1 ? (
        <ReactPaginate
          previousLabel="previous"
          nextLabel="next"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={pages}
          marginPagesDisplayed={3}
          pageRangeDisplayed={4}
          onPageChange={handlePageClick}
          containerClassName="pagination"
          subContainerClassName="pages pagination"
          activeClassName="active"
        />
      ) : null}
    </div>
  );
};

export default LogsBody;
