import React from 'react';
import BarChart from '../../shared/components/BarChart';
import scheduledImage from './assets/scheduled.svg';
import totalImage from './assets/total.svg';
import failedImage from './assets/failed.svg';
import zoneImage from './assets/zones.svg';

const NewDesignOverviewBody = () => {
  const data = {
    labels: ['nov 16, 22', 'nov 17, 22', 'nov 18, 22', 'nov 19, 22', 'nov 20, 22', 'nov 21, 22'],
    datasets: [
      {
        label: '# of Installations',
        data: [120, 190, 30, 50, 20, 30],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="container py-4">
      <div className="row">
        <div className="col-12 col-md-6 col-lg-3">
          <div className="card mb-3" style={{ maxWwidth: '600px', height: '140px' }}>
            <div className="row g-0 px-lg-1">
              <div className="col-8">
                <div className="card-body">
                  <h6 className="card-title" style={{ fontSize: '14px' }}>
                    TOTAL INSTALLATIONS
                  </h6>
                  <h5 className="">0</h5>
                </div>
              </div>

              <div className="col-4 align-self-center">
                <img src={totalImage} className="img-fluid rounded-start" alt="..." />
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6 col-lg-3">
          <div className="card mb-3" style={{ maxWwidth: '600px', height: '140px' }}>
            <div className="row g-0 px-lg-1">
              <div className="col-8">
                <div className="card-body">
                  <h6 className="card-title" style={{ fontSize: '14px' }}>
                    SCHEDULED INSTALLATIONS
                  </h6>
                  <h5 className="">0</h5>
                </div>
              </div>

              <div className="col-4 align-self-center">
                <img src={scheduledImage} className="img-fluid rounded-start" alt="..." />
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6 col-lg-3">
          <div className="card mb-3" style={{ maxWwidth: '600px', height: '140px' }}>
            <div className="row g-0 px-lg-1">
              <div className="col-8">
                <div className="card-body">
                  <h6 className="card-title" style={{ fontSize: '14px' }}>
                    FAILED INSTALLATIONS
                  </h6>
                  <h5 className="">0</h5>
                </div>
              </div>

              <div className="col-4 align-self-center">
                <img src={failedImage} className="img-fluid rounded-start" alt="..." />
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6 col-lg-3">
          <div className="card mb-3" style={{ maxWwidth: '600px', height: '140px' }}>
            <div className="row g-0 px-lg-1">
              <div className="col-8">
                <div className="card-body">
                  <h6 className="card-title" style={{ fontSize: '14px' }}>
                    INSTALLATION ZONES
                  </h6>
                  <h5 className="">0</h5>
                </div>
              </div>

              <div className="col-4 align-self-center">
                <img src={zoneImage} className="img-fluid rounded-start" alt="..." />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-4 col-lg-2 col-md-3">
          <div className="">
            <select className="form-control">
              <option value="ice">Last 7 days</option>
              <option value="ice">4 weeks</option>
            </select>
          </div>
        </div>
      </div>

      <div className="row mt-4 border">
        <div className="col-12 col-lg-8">
          <BarChart
            data={data}
            options={{
              maintainAspectRatio: true,
              responsive: true,
              scales: {
                yAxes: [
                  {
                    scaleLabel: { display: true, labelString: 'COUNT', fontStyle: 'bold', fontSize: 17 },
                  },
                ],
                xAxes: [
                  {
                    ticks: { fontStyle: 'bold' },
                    scaleLabel: { display: true, labelString: 'Date', fontStyle: 'bold', fontSize: 7 },
                  },
                ],
              },
            }}
          />
        </div>

        <div className="col-12 col-lg-4 border">
          <div className="my-4">
            <h6>Installations today</h6>
            <h6>0</h6>
          </div>

          <div className="my-4">
            <h6>Total subzones</h6>
            <h6>0</h6>
          </div>

          <hr />

          <div className="my-4">
            <h6>No LOS</h6>
            <h6>0</h6>
          </div>

          <div className="my-4">
            <h6>Total Relocations</h6>
            <h6>0</h6>
          </div>

          <div className="my-1 fs-6" style={{ cursor: 'pointer' }}>
            <h5>See all Relocations</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewDesignOverviewBody;
