import qs from 'qs';
import React, { useEffect } from 'react';
import worldicon from '../../../../../assets/img/icons/world_icon.fw.png';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import useBusinessDevelopmentAnalytics from '../../../../../hooks/business-development/overview/useBusinessDevelopmentAnalytics';

const TotalActiveAnalytics = () => {
  const { totalActive, isLoading, getAnalytics } = useBusinessDevelopmentAnalytics();

  const data = qs.stringify({
    action: 'get_bizdev_analytics',
    country: `Nigeria`,
  });

  useEffect(() => {
    getAnalytics(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="col-lg col-md col-sm">
      <div className="boxes">
        <div className="text-center">
          {isLoading ? (
            <img src={loader} alt="loading gif" />
          ) : (
            <>
              <div className="text-center mt-3">
                <img src={worldicon} alt="" width="30px" height="25px" />
              </div>
              <div className="count">{totalActive}</div>
            </>
          )}
          <h3 className="color-two">Total Expired</h3>
        </div>
      </div>
    </div>
  );
};

export default TotalActiveAnalytics;
