import axios from 'axios';
import { toast } from 'react-toastify';
import { useReducer } from 'react';
import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';
import { GET_SCHEDULE_URL } from '../../../services/api/customer-support/schedule/ScheduleUrl';
import { GET_SCHEDULE } from '../../../constants/types/customer-support/CustomerSupportTypes';
import ScheduleReducer from '../../../reducers/customer-support/schedule/ScheduleReducer';

const useSchedule = () => {
  const [{ users, totalPages, currentPage, allPages, isLoading }, dispatch] = useReducer(ScheduleReducer, {
    users: [],
    totalPages: '',
    allPages: '',
    currentPage: '',
    isLoading: false,
  });

  const getSchedule = async () => {
    dispatch({ type: LOADING_STARTS });
    try {
      const response = await axios.get(`${GET_SCHEDULE_URL}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: GET_SCHEDULE,
          payload: {
            users: response.data.users,
            totalPages: response.data.total_records,
            allPages: response.data.total_pages,
            currentPage: response.data.current_page,
          },
        });
      }
    } catch (e) {
      toast.info('Something went wrong!');
      if (e.response) {
        dispatch({ type: LOADING_STOPS });
      }
    }
  };

  return { getSchedule, users, totalPages, allPages, currentPage, isLoading };
};

export default useSchedule;
