import React, { useState } from 'react';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';

const AssetTeamInstallationsTableBody = ({ data, loading }) => {
  if (loading)
    return (
      <tbody>
        <tr>
          <td>
            <img src={loader} alt="" />
          </td>
        </tr>
      </tbody>
    );
  const customerList = data ? (
    data.map((customer, i) => (
      <tr key={customer.id}>
        <td>{i + 1}</td>
        <td>{customer.user && `${customer.user.firstName} ${customer.user.lastName}`}</td>
        <td>{customer.user && customer.user.email}</td>
        <td>{customer.user && customer.user.phoneNumber}</td>
        <td>{customer.user && customer.user.address}</td>
        <td>{customer.address}</td>
        <td>{customer.payment_reference}</td>
        <td>{customer.status}</td>
        <td>{customer.nearest_basestation}</td>
      </tr>
    ))
  ) : (
    <tr>
      <td>
        <p>No Installations!</p>
      </td>
    </tr>
  );
  return <tbody>{customerList}</tbody>;
};

export default AssetTeamInstallationsTableBody;
