/* eslint-disable react/require-default-props */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from 'react';
import pic from '../../../../../assets/img/icons/pic.fw.png';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import RequisitionContext from '../../../../../context/shared/requisitions/RequsitionContext';

const MakeRequisition = () => {
  const { makeRequisition, onChangeRequisition, requisition, onFileChange, file, isLoading, percentage } = useContext(
    RequisitionContext,
  );

  const department_id = sessionStorage.getItem('departmentId');
  const firstName = sessionStorage.getItem('firstName');
  const lastName = sessionStorage.getItem('lastName');
  const { amount, currency, purpose, amountInWords, country } = requisition;

  const submitForm = () => {
    return amount.length > 0 && country.length > 0 && amountInWords.length > 0 && purpose.length > 0 && file !== null;
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const data = new FormData();
    // eslint-disable-next-line no-plusplus
    for (let x = 0; x < file.length; x++) {
      data.append('file', file[x]);
    }
    data.append('amount_in_digits', amount);
    data.append('currency', currency);
    data.append('amount_in_words', amountInWords);
    data.append('in_favour_of', `${firstName} ${lastName}`);
    data.append('file', file);
    data.append('purpose', purpose);
    data.append('department_id', department_id);
    data.append('country', country);
    makeRequisition(data);
  };

  const isEnabled = submitForm();

  return (
    <div className="col-lg-12 col-md-9 col-sm-12 mt-3 mb-3 d-flex callog">
      <div className="d-flex">
        <div>
          <button
            className="btn btn-green mt-1 ml-1 copy-font"
            type="submit"
            data-toggle="modal"
            data-target="#makerequisition"
          >
            Make Requisition
          </button>
        </div>

        <div
          id="makerequisition"
          className="modal fade in"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title text-center" id="myModalLabel">
                  Make Requisition
                </h4>
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                  ×
                </button>
              </div>
              <div className="modal-body p-0">
                <div className="container d-flex p-0">
                  <ul className="nav nav-pills nav-something col-md-3 d-block text-justify">
                    {/* <li className="nav-item"><a href="#tab_a" className="nav-link active" data-toggle="tab">Upload Scanned requisition</a></li> */}
                    <li className="nav-item copy-font">
                      <a href="#tab_b" className="nav-link active" data-toggle="tab">
                        Fill requisition form
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content col-md-9">
                    <div className="tab-pane fade show active" role="tabpanel" id="tab_b">
                      <div className="tab-pane" id="tab_b">
                        <form className="form-horizontal" onSubmit={onSubmit}>
                          <div className="form-group">
                            <div className="col-12">
                              <div className="mb-2 p-0">
                                <label className="copy-font mb-1 p-0">Amount</label>
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <select
                                      name="currency"
                                      defaultValue={currency}
                                      className="px-3 copy-font"
                                      onChange={onChangeRequisition}
                                    >
                                      <option value="NGN">NGN</option>
                                      <option value="USD">USD</option>
                                      <option value="GHC">CEDI</option>
                                      <option value="CFA">CFA</option>
                                    </select>
                                  </div>
                                  <input
                                    type="number"
                                    placeholder="Amount requested"
                                    name="amount"
                                    value={amount}
                                    data-testid="amount"
                                    onChange={onChangeRequisition}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 mb-3">
                              <label className="copy-font mb-1 p-0">Amount in Words</label>
                              <input
                                type="text"
                                placeholder="Write Amount In Words"
                                className="form-control"
                                name="amountInWords"
                                onChange={onChangeRequisition}
                                value={amountInWords}
                              />
                            </div>
                            <div className="col-12 mb-3">
                              <label className="copy-font mb-1 p-0">Country</label>
                              <div className="">
                                <select
                                  name="country"
                                  className="form-control"
                                  defaultValue={country}
                                  onChange={onChangeRequisition}
                                >
                                  <option>Pick country</option>
                                  <option value="ng">Nigeria</option>
                                  <option value="gh">Ghana</option>
                                  <option value="cv">Cote d'Ivoire</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-12">
                              <label className="copy-font mb-1 p-0">Purpose</label>
                              <div className="p-0">
                                <textarea
                                  rows="4"
                                  cols="50"
                                  placeholder="Purpose of requisition"
                                  name="purpose"
                                  value={purpose}
                                  onChange={onChangeRequisition}
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="col-12 upload-receipt mt-3">
                              <label className="copy-font mb-1 p-0">Upload Invoice</label>
                              <div className="form-control doc-upload p-5">
                                <div className="p-1">
                                  <div className="text-center mt-2">
                                    <img
                                      src={pic}
                                      alt="images"
                                      style={{
                                        cursor: 'pointer',
                                      }}
                                    />
                                  </div>
                                  <input
                                    type="file"
                                    name="file"
                                    accept="image/png, image/jpeg, application/pdf, image/jpg"
                                    onChange={onFileChange}
                                    required
                                    className="copy-font ml-2 text-center"
                                    multiple
                                  />
                                  {/* <p className="copy-font ml-2 text-center" style={{color: 'grey'}}>Click to browse or */}
                                  {/*    drag Image here</p> */}
                                </div>
                              </div>
                              {isLoading ? (
                                <div className="text-center mt-4">
                                  <button className="btn btn-green" disabled={isLoading}>
                                    <strong>Submitting...</strong>
                                  </button>
                                </div>
                              ) : (
                                <div className="text-center mt-4">
                                  <button className="btn btn-green" disabled={!isEnabled}>
                                    <strong>Submit</strong>
                                  </button>
                                </div>
                              )}
                            </div>
                            <div
                              className="progress-bar progress-bar-striped bg-success"
                              role="progressbar"
                              style={{
                                width: `${percentage}%`,
                              }}
                            >
                              {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                              {percentage}%
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MakeRequisition;
