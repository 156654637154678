/* eslint-disable import/prefer-default-export */
import { useState, useEffect } from 'react';
import toastr from 'toastr';
import { API_URL } from '../../../utils/config';
import axios from 'axios';

export const useFirsTransactions = () => {
  const [firsData, setFirsData] = useState({
    lists: [],
    listsCache: [],
    count: 0,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [ishotspotUploading, setIshotspotUploading] = useState(false);
  const [isEutelsatUploading, setIsEutelsatUploading] = useState(false);
  const [isReverseLoading, setIsReverseLoading] = useState(false);

  const handleCsvUpload = async (reload, hotspotFile) => {
    const form = new FormData();

    if (!hotspotFile) {
      toastr.info('No file uploaded');
      return;
    }

    setIshotspotUploading(true);

    try {
      form.append('form', hotspotFile);

      const res = await fetch(`${API_URL}/firs/bulkupload`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
        body: form,
      });

      if (res.status === 200) {
        toastr.success('File uploaded successfully');
        reload();
      }
    } catch (error) {
      toastr.error('Something went wrong...');
    } finally {
      setIshotspotUploading(false);
      // setFile('');
    }
  };
  const handleEutelsatCsvUpload = async (reload, eutelFile) => {
    const form = new FormData();

    if (!eutelFile) {
      toastr.info('No file uploaded');
      return;
    }

    setIsEutelsatUploading(true);

    try {
      form.append('form', eutelFile);

      const res = await fetch(`${API_URL}/firs/uploadEutelsat`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
        body: form,
      });

      if (res.status === 200) {
        toastr.success('File uploaded successfully');
        reload();
      }
    } catch (error) {
      toastr.error('Something went wrong...');
    } finally {
      setIsEutelsatUploading(false);
      // setEutelsatFile('');
    }
  };

  const getAllDedicatedInvoice = async (date) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API_URL}/firs/get_transactions?date=${date}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      const data = await response.json();
      setFirsData({
        ...firsData,
        count: data.length,
        lists: data,
        listsCache: data,
      });
    } catch (e) {
      toastr.info('Something went wrong!');
    }
    setIsLoading(false);
  };

  const reverseTransaction = async (id, reverseAmount, totalAmount) => {
    // if (Number(reverseAmount) > Number(totalAmount)) {
    //   toastr.error('Reverse Amount cannot be greater than Total Amount');
    //   return;
    // }

    setIsReverseLoading(true);
    try {
      const url = `${API_URL}/firs/reverseTransaction`;

      const response = await axios({
        method: 'POST',
        url,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
        data: {
          id,
          amount: reverseAmount,
        },
      });

      if (response.status === 200) {
        toastr.success('Transaction reversed successfully');
        getAllDedicatedInvoice();
      }
    } catch (error) {
      toastr.info('Something went wrong!');
    } finally {
      setIsReverseLoading(false);
    }
  };

  const searchFirsTable = (search) => {
    if (firsData.listsCache) {
      if (search === '') {
        setFirsData({
          ...firsData,
          lists: firsData.listsCache,
        });
        return;
      }

      const searchtoLowerCase = search.toLowerCase();
      const newItems = firsData.listsCache.filter(
        (item) =>
          item.name.toLowerCase().includes(searchtoLowerCase) ||
          item.email.toLowerCase().includes(searchtoLowerCase) ||
          item.companyProductID.toLowerCase().includes(searchtoLowerCase) ||
          item.companyProductName.toLowerCase().includes(searchtoLowerCase),
      );
      setFirsData({
        ...firsData,
        lists: newItems,
      });
    }
  };

  useEffect(() => {
    let date = new Date();
    date = date.toISOString().split('T')[0];
    getAllDedicatedInvoice(date);
  }, []);

  return {
    ...firsData,
    // file,
    // eutelsatFile,
    isLoading,
    ishotspotUploading,
    isEutelsatUploading,
    searchFirsTable,
    // setFile,
    // setEutelsatFile,
    handleCsvUpload,
    handleEutelsatCsvUpload,
    getAllDedicatedInvoice,
    isReverseLoading,
    reverseTransaction,
  };
};
