import axios from 'axios';
import { toast } from 'react-toastify';
import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';
import {
  EXECUTIVE_UPDATE_REQUSITION_STATUS_URL,
  FILTER_REQUISITION_URL,
  GET_BANKS_URL,
  GET_EXECUTIVE_REQUISITION_URL,
  GET_REQUISITIONS_BY_DEPARTMENT_URL,
  MAKE_REQUISITION_URL,
  MANAGER_UPDATE_REQUSITION_STATUS_URL,
  UPLOAD_SIGNATURE_URL,
} from '../../../services/api/shared/requisitions/RequisitionsUrl';
import {
  APPROVE_OR_DISAPPROVE_SUCCESS,
  FILTER_REQUISITIONS,
  GET_BANKS,
  GET_REQUISITIONS,
  LOADING_REQUISITIONS_STARTS,
  LOADING_REQUISITIONS_STOPS,
  REQUISITION_FILLED,
  START_APPROVE_OR_DISAPPROVE,
  STOP_APPROVE_OR_DISAPPROVE,
} from '../../../constants/types/shared/requisition/RequisitionTypes';
import { LOGGED_IN_USER_PROFILE_URL } from '../../../services/api/shared/UserUrl';
import {
  GET_ALL_REQUISITIONS,
  STOP_LOADING_ACCOUNTS_REQUSITION,
} from '../../../constants/types/accounts/RequisitionTypes';
import { LOGGED_IN_USER_URL } from '../../../services/api/auth/AuthUrl';

export const getRequsitionByDepartment = () => {
  return async (dispatch) => {
    dispatch({
      type: LOADING_REQUISITIONS_STARTS,
      payload: true,
    });

    try {
      const data = {
        status: 'queued',
      };

      if (`${sessionStorage.getItem('departmentId')}`) {
        try {
          const response = await axios.post(
            `${GET_REQUISITIONS_BY_DEPARTMENT_URL}/${sessionStorage.getItem('departmentId')}`,
            data,
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
              },
            },
          );

          if (response.data) {
            dispatch({
              type: GET_REQUISITIONS,
              payload: response.data,
            });
          }
        } catch (e) {
          dispatch({
            type: LOADING_REQUISITIONS_STOPS,
            payload: false,
          });
        }
      } else {
        // get the user dept
        try {
          const res = await axios.get(`${LOGGED_IN_USER_URL}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
          });

          if (res.data.department_id) {
            try {
              const response = await axios.post(
                `${GET_REQUISITIONS_BY_DEPARTMENT_URL}/${sessionStorage.getItem('departmentId')}`,
                data,
                {
                  headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                  },
                },
              );

              if (response.data) {
                dispatch({
                  type: GET_REQUISITIONS,
                  payload: response.data,
                });
              }
            } catch (e) {
              dispatch({
                type: LOADING_REQUISITIONS_STOPS,
                payload: false,
              });
            }
          }
        } catch (e) {
          toast.info('Unable to get requisitions');
        }
      }
    } catch (e) {}
  };
};

export const makeRequisition = (data) => async (dispatch) => {
  dispatch({
    type: LOADING_STARTS,
    payload: true,
  });

  try {
    const response = await axios.post(`${MAKE_REQUISITION_URL}`, data, {
      headers: {
        // eslint-disable-next-line no-undef
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    console.log(response.data);
    if (response.data.id) {
      toast.success('Requisition Filled!');
      dispatch(getRequsitionByDepartment());
      dispatch({
        type: REQUISITION_FILLED,
        payload: false,
      });
    }
  } catch (e) {
    console.log(e.response);
    if (e) {
      toast.info('Something went wrong!');
      dispatch({
        type: LOADING_STOPS,
        payload: false,
      });
    }
  }
};

export const uploadSignature = (data) => async (dispatch) => {
  dispatch({
    type: LOADING_STARTS,
    payload: true,
  });

  try {
    const response = await axios.post(`${UPLOAD_SIGNATURE_URL}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data.status === true) {
      toast.success(response.data.message);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      toast.info('Something went wrong! Please try ahgain');
      dispatch({
        type: LOADING_STOPS,
        payload: false,
      });
    }
  } catch (e) {
    if (e) {
      toast.info(e.response.data.message);
      dispatch({
        type: LOADING_STOPS,
        payload: false,
      });
    }
  }
};

export const getBanks = () => async (dispatch) => {
  try {
    const response = await axios.get(`${GET_BANKS_URL}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      dispatch({
        type: GET_BANKS,
        payload: response.data,
      });
    }
  } catch (e) {}
};

export const filterRequisitions = () => {
  return async (dispatch) => {
    dispatch({
      type: LOADING_REQUISITIONS_STARTS,
      payload: true,
    });

    try {
      const response = await axios.get(`${LOGGED_IN_USER_PROFILE_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.id) {
        const { email } = response.data;
        const data = {
          filterby: 'email',
          value: email,
        };

        const res = await axios.post(`${FILTER_REQUISITION_URL}`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });

        if (res.data) {
          dispatch({
            type: FILTER_REQUISITIONS,
            payload: res.data,
          });
        } else {
          toast.info('Unable to get requisitions');

          dispatch({
            type: LOADING_REQUISITIONS_STOPS,
            payload: false,
          });
        }
      } else {
        // toast.info("Something went wrong! Please try again later!");

        dispatch({
          type: LOADING_REQUISITIONS_STOPS,
          payload: false,
        });
      }
    } catch (e) {
      // toast.info("Something went wrong! Please try again later!");

      dispatch({
        type: LOADING_REQUISITIONS_STOPS,
        payload: false,
      });
    }
  };
};

export const updateStatus = (data, id) => {
  return async (dispatch) => {
    dispatch({
      type: START_APPROVE_OR_DISAPPROVE,
      payload: true,
    });

    try {
      const response = await axios.patch(`${MANAGER_UPDATE_REQUSITION_STATUS_URL}/${id}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        toast.success('Updated Successfully!');
        // dispatch({
        //   type: APPROVE_OR_DISAPPROVE_SUCCESS,
        //   payload: false,
        // });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (e) {
      if (e) {
        toast.info(e.response.data.message);
        dispatch({
          type: STOP_APPROVE_OR_DISAPPROVE,
          payload: false,
        });
      }
    }
  };
};

export const getExecutiveRequisition = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${GET_EXECUTIVE_REQUISITION_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data) {
        dispatch({
          type: GET_ALL_REQUISITIONS,
          payload: response.data,
        });
      }
    } catch (e) {
      if (e) {
        dispatch({
          type: STOP_LOADING_ACCOUNTS_REQUSITION,
          payload: false,
        });
      }
    }
  };
};

export const giveManagerApproval = (data, id) => {
  return async (dispatch) => {
    dispatch({
      type: START_APPROVE_OR_DISAPPROVE,
      payload: true,
    });
    try {
      const response = await axios.patch(`${MANAGER_UPDATE_REQUSITION_STATUS_URL}/${id}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        toast.success('Updated Successfully!');
        dispatch(getRequsitionByDepartment());
        dispatch({
          type: APPROVE_OR_DISAPPROVE_SUCCESS,
          payload: false,
        });
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000)
      }
    } catch (e) {
      if (e) {
        toast.info(e.response.data.message);
        dispatch({
          type: STOP_APPROVE_OR_DISAPPROVE,
          payload: false,
        });
      }
    }
  };
};

export const giveExecutiveApproval = (data, id) => {
  return async (dispatch) => {
    dispatch({
      type: START_APPROVE_OR_DISAPPROVE,
      payload: true,
    });
    try {
      const response = await axios.post(`${EXECUTIVE_UPDATE_REQUSITION_STATUS_URL}/${id}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch(getExecutiveRequisition());
        toast.success('Updated Successfully!');
        dispatch({
          type: APPROVE_OR_DISAPPROVE_SUCCESS,
          payload: false,
        });
        // setTimeout(() => {
        //   window.location.reload()
        // }, 1000)
      }
    } catch (e) {
      if (e) {
        toast.info(e.response.data.message);
        dispatch({
          type: STOP_APPROVE_OR_DISAPPROVE,
          payload: false,
        });
      }
    }
  };
};
