/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import Moment from 'react-moment';
import PropTypes from 'prop-types';
import edit from '../../../../assets/img/icons/edit.png';
import view from '../../../../assets/img/icons/view.png';
import ViewReleaseItem from './ViewReleaseItem';
// import UpdateReleaseItem from './UpdateReleaseItem';
import loader from '../../../../assets/img/icons/TizetiLoaderColored.gif';
import useReleaseReport from '../../../../hooks/procurement/release-report/useReleaseReport';
import UpdateReleaseItem from './UpdateReleaseItem';

const ReleaseReportTable = ({ lists, load }) => {
  const [one, setOne] = useState({});
  const getItem = (list) => setOne(list);

  const tableData =
    lists.length && lists ? (
      lists.map((list, i) => {
        const {
          item,
          request_by,
          released_to: { employees },
          approved_by,
          tag,
          created_at,
          quantity,
        } = list;
        return (
          <tr className="text-nowrap" key={i}>
            <td>{i + 1}</td>
            <td>{item}</td>
            <td>{list.resource_classification ? list.resource_classification.name : 'No Resource Classification'}</td>
            <td>{quantity}</td>
            <td>{request_by.name}</td>
            <td>
              {employees[0].firstName} {employees[0].lastName}
            </td>
            <td>
              {approved_by.employees[0].firstName} {approved_by.employees[0].lastName}
            </td>
            <td>{tag}</td>
            <td>
              <Moment format="DD-MM-YYYY">{created_at}</Moment>
            </td>
            <td>
              <div>
                <button
                  type="submit"
                  className="mx-2"
                  data-toggle="modal"
                  data-placement="right"
                  data-target="#item_detail"
                  style={{ border: '0', backgroundColor: 'white' }}
                  onClick={() => getItem(list)}
                >
                  <span className="view">
                    <img src={view} alt="" data-toggle="tooltip" data-placement="left" title="View" />
                  </span>
                </button>
                <button
                  type="submit"
                  className="mr-2"
                  data-toggle="modal"
                  data-placement="right"
                  data-target="#release_item"
                >
                  <span className="view">
                    <img src={edit} data-toggle="tooltip" alt="" data-placement="top" title="Edit" />
                  </span>
                </button>
              </div>
              <ViewReleaseItem OneItem={one} log={lists} />
              <UpdateReleaseItem />
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="10" className="text-center">
          There are no items
        </td>
      </tr>
    );

  return (
    <div className="card x-panel mt-2 px-0">
      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <thead>
            <tr className="text-nowrap">
              <th>#</th>
              <th>Item</th>
              <th>Class</th>
              <th>Released Quantity</th>
              <th>Requested By</th>
              <th>Released To</th>
              <th>Approved By</th>
              <th>Tag No.</th>
              <th>Release Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {load ? (
              <tr>
                <td colSpan="10" className="text-center">
                  <img src={loader} alt="loading gif" />
                </td>
              </tr>
            ) : (
              tableData
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

ReleaseReportTable.propTypes = {
  load: PropTypes.bool.isRequired,
  lists: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default ReleaseReportTable;
