import React, { useState } from 'react';
import useAddNewCutomer from '../../../../../hooks/express-wifi/useAddNewCustomer';

const ExpressWifiCustomerSignupsBulkUpload = () => {
  const { ExpressWifiCustomerSignupsBulkUpload } = useAddNewCutomer();
  const [Fileupload, setFileUpload] = useState();

  const onFormSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('action', 'customer_bulk_upload');
    data.append('file', Fileupload);
    ExpressWifiCustomerSignupsBulkUpload(data);
    console.log(data.get('file'));
  };

  return (
    <div>
      <div id="uploadCustomer" className="modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header shadow">
              <h3 className="modal-title">Upload Customer Details</h3>
              <button type="button" className="btn btn-outline-info btn-circle btn-close" data-dismiss="modal">
                x
              </button>
            </div>
            <div className="modal-body detail mb-4 p-0 pt-2">
              <form onSubmit={onFormSubmit} className="col-md-12">
                <div className="">
                  <div className="form-group col-md-12">
                    <label className="m-0 p-0 copy-font" />
                  </div>
                </div>

                <div className="">
                  <div className="form-group col-md-12">
                    <input
                      type="file"
                      className="form-control border border-info py-2"
                      id="file"
                      name="file"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={(e) => setFileUpload(e.target.files[0])}
                    />
                  </div>
                </div>

                <div className="text-center mt-3">
                  <button type="submit" className="btn btn-green py-2 px-5" disabled={!Fileupload}>
                    Upload File
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpressWifiCustomerSignupsBulkUpload;
