/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import load from '../../../../../assets/img/icons/TizetiLoaderColored.gif';

const ViewCustomer = ({ wallet, customer, number }) => {
  return (
    <div
      id="customer_detail"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Customer Details
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          {wallet ? (
            <div className="modal-body detail" style={{ padding: '0' }}>
              <div className="row">
                <div className="col-lg-12 d-flex">
                  <div className="col-lg-4 detail-label1">
                    <label id="fn">First Name</label>
                    <label id="ln">Last Name</label>
                    <label id="gen">Gender</label>
                    <label id="gen">Address</label>
                  </div>
                  <div className="col-lg-8 detail-result1">
                    <p className="mt-4 mb-1">{customer.firstName}</p>
                    <p className="mb-1">{customer.lastName}</p>
                    {customer.gender ? <p className="mb-1">{customer.gender}</p> : <p className="mb-1">N/A</p>}
                    <p className="mb-1">{customer.address}</p>
                  </div>
                </div>
                <hr />
                <div className="col-lg-12 d-flex">
                  <div className="col-lg-4 detail-label2">
                    <label id="email">Email</label>
                    <label id="alt-email">Alternate Email</label>
                    <label id="phone1">Phone number 1</label>
                    <label id="phone2">Phone number 2</label>
                  </div>
                  <div className="col-lg-8 detail-result2">
                    {customer.email ? <p className="mt-4 mb-1">{customer.email}</p> : <p className="mt-4 mb-1">N/A</p>}
                    {customer.alternateEmail ? (
                      <p className="mb-1">{customer.alternateEmail}</p>
                    ) : (
                      <p className="mb-1">N/A</p>
                    )}
                    <p className="mb-1">{customer.phoneNumber}</p>
                    {customer.alternatePhoneNumber ? (
                      <p className="mb-1">{customer.alternatePhoneNumber}</p>
                    ) : (
                      <p className="mb-1">N/A</p>
                    )}
                  </div>
                </div>

                <div className="col-lg-12 d-flex">
                  <div className="col-lg-4 detail-label3">
                    <label id="ref">Referral</label>
                    <label id="ref">Wificall Number</label>

                    <label id="user-bal">User Balance</label>
                    <label id="user-id">User ID</label>
                    <label id="user-id">Verification Status</label>
                  </div>
                  <div className="col-lg-8 detail-result3">
                    {customer.referral ? (
                      <p className="mt-4 mb-1">{customer.referral}</p>
                    ) : (
                      <p className="mt-4 mb-1">N/A</p>
                    )}
                    {number ? <p className="mb-1">{number}</p> : <p className="mb-1">N/A</p>}
                    <p className="mb-1">
                      {typeof wallet === 'string'
                        ? Number(wallet).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : null}
                    </p>
                    <p className="mb-1">{customer.id}</p>
                    <p className="mb-1">{customer.verification_status}</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <img src={load} alt="" />
          )}
        </div>
      </div>
    </div>
  );
};

ViewCustomer.propTypes = {
  wallet: PropTypes.string,
  customer: PropTypes.shape(),
  number: PropTypes.string,
};
export default ViewCustomer;
