import { API_URL } from '../../../utils/config';

export const XWF_CUSTOMERS_URL = `${API_URL}/expresswifiCustomer`;
export const XWF_RETAILERS_URL = `${API_URL}/expresswifiretailer`;
export const XWF_RETAILER_COMMENT_URL = `${API_URL}/expresswificomment`;

export const TERMII_SMS_URL = `https://api.ng.termii.com/api/sms/send`;
export const XWF_LEGACY_GENIATECH_URL = `https://legacy.tizeti.com/geniatechapi/index.php`;

export const TERMII_API_KEY = `TLnqKA1Xg9q9dHaeU4bRyhGDDuIeRRv9lUw5RJvwrFITfDLf7zx0ypA2grHFME`;
