import React, { useState } from "react";
import { useEffect } from "react"

import CustomCsvDownload from '../../../shared/CustomCsvDownload';
import ReactPaginate from 'react-paginate';
import { BUSINESS_DEVELOPMENT } from '../../../../../utils/config';
import useBizdevUsers from '../../../../../hooks/business-development/useBizdevUsers';
import BusinessDevelopmentUsersTable from '../../../shared/dedicated-customers/BusinessDevelopmentUsersTable';
import CustomButton from '../../../shared/forms/CustomButton';
import { get_all_basestation_zones, get_all_employees, get_all_basestations } from '../../../../../actions/shared/basestations/GetAllBasestationsZones';

const BusinessDevelopmentBizDevUsersBody = ({ }) => {
    const { getBizDevUsers, filterBizDevUsers, usersData, loading } = useBizdevUsers();

    const [filterBy, setFilterBy] = useState({ filter: "", filterTerm: "" })

    const [getZones, setGetZones] = React.useState([]);
    const [getBasestations, setBasestations] = React.useState([]);

    const [employees, setEmployees] = React.useState([]);

    useEffect(() => {
        getBizDevUsers()
    }, [])

    React.useEffect(() => {
        const fetchZones = async () => {
            setGetZones(await get_all_basestation_zones());
        };

        const fetchBasestations = async () => {
            setBasestations(await get_all_basestations());
        }

        const fetchEmployees = async () => {
            const data = await get_all_employees()
            setEmployees(data);
        }

        if (filterBy.filter === "zone_name") fetchZones();
        if (filterBy.filter === "base_station") fetchBasestations();
        if (filterBy.filter === "employee_name") fetchEmployees();

    }, [filterBy.filter]);

    const handleFilterBy = (e) => {
        setFilterBy({ ...filterBy, [e.target.name]: e.target.value })
    }

    const handlePageClick = () => {

    }





    const filterUi = (
        <div className="d-flex">
            <form className="form-inline ml-3" onSubmit={(e) => {
                e.preventDefault();
                filterBizDevUsers(filterBy)
            }}>
                <div className="form-group mr-2">
                    Search By
                    <select
                        style={{ marginLeft: '1rem' }}
                        name="filter"
                        value={filterBy.filter}
                        onChange={handleFilterBy}
                        className="form-control"
                    >
                        <option value="">--Choose Search Field--</option>
                        <option value="name">Name</option>
                        <option value="mail">Mail</option>
                        <option value="zone_name">Zone</option>
                        <option value="base_station">Basestation</option>
                        <option value="employee_name">Employee Name</option>
                        <option value="product">Product</option>

                    </select>
                </div>
                {
                    filterBy.filter === 'zone_name' ? (

                        <div className="form-group mr-2">
                            Zones
                            <select
                                id="zone"
                                className="form-control"
                                name="filterTerm"
                                value={filterBy.filterTerm}
                                onChange={handleFilterBy}
                            >
                                <option value="">Select an option</option>
                                {getZones.length === 0 && <option value="">loading...</option>}
                                {getZones.map(getZone => <option key={getZone.id} value={getZone.name}>{getZone.name}</option>)}

                            </select>
                        </div>

                    ) : filterBy.filter === 'product' ? (

                        <div className="form-group mr-2">
                            Products
                            <select
                                id="product"
                                className="form-control"
                                name="filterTerm"
                                value={filterBy.filterTerm}
                                onChange={handleFilterBy}
                            >
                                <option value="">Select an option</option>

                                <option value="turbo_connect_lite">turbo_connect_lite</option>
                                <option value="turbo_connect_pro">turbo_connect_pro</option>
                                <option value="turbo_connect">turbo_connect</option>
                                <option value="dedicated">dedicated</option>

                            </select>
                        </div>

                    ) : filterBy.filter === 'base_station' ? (
                        <div className="form-group mr-2">
                            Basestations
                            <select
                                id="base_station"
                                className="form-control"
                                name="filterTerm"
                                value={filterBy.filterTerm}
                                onChange={handleFilterBy}
                            >
                                <option value="">Select an option</option>
                                {getBasestations.length === 0 && <option value="">loading...</option>}
                                {getBasestations.map((station, i) => <option key={i} value={station}>{station}</option>)}

                            </select>
                        </div>
                    ) : filterBy.filter === 'employee_name' ? (
                        <div className="form-group mr-2">
                            Employees
                            <select
                                id="employees"
                                className="form-control"
                                name="filterTerm"
                                value={filterBy.filterTerm}
                                onChange={handleFilterBy}
                            >
                                <option value="">Select an option</option>
                                {employees.length === 0 && <option value="">loading...</option>}
                                {employees.filter(emp => emp.department_id === BUSINESS_DEVELOPMENT).map(emp => <option key={emp.id} value={`${emp.firstName + ' ' + emp.lastName}`}>{`${emp.firstName + ' ' + emp.lastName}`}</option>)}

                            </select>
                        </div>
                    ) : (
                        <div className="form-group mr-2">
                            Type Here:
                            <input
                                className="form-control mr-auto empty pl-5"
                                type="text"
                                placeholder="type search term"
                                name="filterTerm"
                                value={filterBy.filterTerm}
                                onChange={handleFilterBy}
                                readOnly={false}
                            />
                        </div>
                    )
                }

                <div className="form-group mx-2">
                    <CustomButton classname="btn btn-green" name="Search" disabled={false} />
                </div>
            </form>
        </div>
    )



    return (
        <div className="mt-5 p-3">
            <div className="container-fluid pt-4 ml-4">
                <div className="row">
                    {filterUi}
                    {/* <div className="col-12 d-flex mt-2">
                    <CustomCsvDownload
                        data={items || []}
                        headers={dedicatedCustomersHeaders}
                        filename={filename(`Call Card Customers`)}
                        classname="btn btn-green shadow-md"
                        textcolor="white"
                        text="Download Data"
                    />
                </div> */}
                    <div className="p-0 col-12 d-flex justify-content-between mt-5 ml-3">
                        {usersData.list.length > 0 ? (
                            <div>
                                Count: <span className="badge badge-secondary mr-4">{usersData.count}</span>
                            </div>
                        ) : null}
                    </div>
                    <BusinessDevelopmentUsersTable items={usersData.list} isLoading={loading} filteredBy={filterBy} />
                    <div className="px-3">
                        {usersData.pages > 1 ? (
                            <ReactPaginate
                                previousLabel="previous"
                                nextLabel="next"
                                breakLabel="..."
                                breakClassName="break-me"
                                pageCount={usersData.pages}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={4}
                                onPageChange={handlePageClick}
                                containerClassName="pagination"
                                // subContainerClassName="pages pagination"
                                activeClassName="active"
                            />
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    )

}

export default BusinessDevelopmentBizDevUsersBody