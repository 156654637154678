import React from 'react';
import useFiberPptp from '../hooks/useFiberPptp';
import FiberPptpContext from './FiberPptpContext';

const FiberPptpProvider = ({ children }) => {
  const { data, loading, paginateChange, loadingForm, onChange, formValue, onSubmit, pages } = useFiberPptp();
  return (
    <FiberPptpContext.Provider
      value={{
        data,
        loading,
        paginateChange,
        loadingForm,
        onChange,
        formValue,
        onSubmit,
        pages,
      }}
    >
      {children}
    </FiberPptpContext.Provider>
  );
};
export default FiberPptpProvider;
