/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import usePost from '../../../../hooks/shared/custom/data/geniatech/usePost';
import { GET_ROUTER_URL } from '../../../../services/api/shared/BasestationsUrl';
import CommentLogsTable from './CommentLogsTable';
import MessageLogsTable from './MessageLogsTable';
import SearchRetentionLogs from './SearchRetentionLogs';
import CustomCsvDownload from '../CustomCsvDownload';
import {
  filename,
  retentionLogsMessageHeader,
  retentionLogsCommentHeader,
} from '../../../../utils/field-support/installations/helpers';

const RetentionLogsBody = () => {
  const { data, loading, getData, filtered, filterData, getDataLegacy } = usePost();

  const logsState = {
    type: 'email',
    customerEmail: '',
    country: 'Nigeria',
    fromDate: `${new Date(new Date().getTime() - 24 * 60 * 60 * 1000).toISOString().slice(0, 10)}`,
    toDate: `${new Date(new Date().getTime()).toISOString().slice(0, 10)}`,
    filterBy: 'none',
    filterValue: '',
    pageNo: 1,
  };
  const [logs, setLogs] = useState(logsState);
  const [result, setResult] = useState([]);
  const [currentResult, setCurrentResult] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);

  const { type, customerEmail, country, fromDate, toDate, filterBy, filterValue, pageNo } = logs;

  useEffect(() => {
    if (data && data.history) setResult(data.history);
  }, [data]);

  const params = {
    action: 'fetch_conversation_logs',
    type,
    country,
    customer_email: customerEmail,
    from: fromDate,
    to: toDate,
    filterby: filterBy,
    filtervalue: filterValue,
    page_no: pageNo,
  };

  const handleNext = (e) => {
    const selectedPage = e.selected + 1;
    const params = {
      action: 'fetch_conversation_logs',
      type,
      country,
      customer_email: customerEmail,
      from: fromDate,
      to: toDate,
      filterby: filterBy,
      filtervalue: filterValue,
      page_no: selectedPage,
    };

    setSelectedPage(selectedPage);

    getDataLegacy(`${GET_ROUTER_URL}`, params);
  };

  useEffect(() => {
    const endOffset = selectedPage * 10;
    const startOffset = endOffset - 10;
    const paginatedData = result.slice(startOffset, endOffset);
    setCurrentResult(paginatedData);
  }, [selectedPage, result]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setResult([]);
    if (name === 'country') setLogs({ ...logs, filterBy: 'none', country: value });
    else {
      if (name === 'filterBy') {
        const filterParams = {
          action: 'tizeti_asset_comments_filter_values',
          country,
          filterby: value,
        };
        filterData(`${GET_ROUTER_URL}`, filterParams);
      }
      setLogs({ ...logs, [name]: value });
    }
  };

  const searchLogs = (e) => {
    e.preventDefault();
    getDataLegacy(`${GET_ROUTER_URL}`, params);
  };

  useEffect(() => {
    getDataLegacy(`${GET_ROUTER_URL}`, params);
  }, []);
  return (
    <div className="mt-5 px-3">
      <div className="container-fluid pt-5">
        <div className="row">
          <div className="p-0 col-12 d-flex justify-content-between">
            <div className="d-flex">
              <SearchRetentionLogs
                handleChange={handleChange}
                logs={logs}
                searchLogs={searchLogs}
                filtered={filtered}
              />
            </div>
            <div className="form-group mx-2">
              <CustomCsvDownload
                data={result}
                headers={type === 'comment' ? retentionLogsCommentHeader : retentionLogsMessageHeader}
                filename={filename(`Retention_logs`)}
                classname="btn btn-green"
                textcolor="white"
                text="Download Data"
              />
            </div>
            {result.length > 0 && (
              <div>
                Count: <span className="badge badge-secondary mr-4">{result.length}</span>
              </div>
            )}
          </div>

          {type === 'comment' ? (
            <CommentLogsTable data={currentResult.length > 0 ? currentResult : result} loading={loading} />
          ) : (
            <MessageLogsTable data={currentResult.length > 0 ? currentResult : result} loading={loading} />
          )}
          <ReactPaginate
            previousLabel="previous"
            nextLabel="next"
            breakLabel="..."
            breakClassName="break-me"
            pageCount={7}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handleNext}
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>
      </div>
    </div>
  );
};

export default RetentionLogsBody;
