import React from 'react';
import { Link } from 'react-router-dom';

const SheetAndCnMaestroSidebarItem = ({ url, name }) => {
  return (
    <li className="sidebar-nav-link-li">
      <Link to={url} target="_self" className="mt-1">
        <span className="mr-2">
          <img src="https://res.cloudinary.com/teewhy/image/upload/v1555319728/recruitment_1.svg" alt="img" />
        </span>
        {name}
      </Link>
    </li>
  );
};

export default SheetAndCnMaestroSidebarItem;
