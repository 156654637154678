/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import CreateTeam from './CreateTeam';
import AppContext from '../../../../../context/shared/AppContext';
import CustomButton from '../../../shared/forms/CustomButton';
import TeamTable from './TeamTable';
import { TizetiGIFLogoTable } from '../../../../../utils/loader/loadingHelper';

const TeamsBody = () => {
  const { openCreateModal, fetching, getTeams } = useContext(AppContext);

  useEffect(() => {
    getTeams()
  }, []);

  return (
    <div className="col-12 after-summary">
      <div className="col-lg-12 col-md-12 col-sm-12 mb-3 d-flex mt-3 flex-wrap actions">
        {/* <Search /> */}
        <div className="action">
          <div className="mt-3">
            <CustomButton onClick={openCreateModal} classname="btn btn-green" name="Create Team" disabled={false} />
          </div>
        </div>
        <CreateTeam />
      </div>
      <div className="card x-panel">
        <div className="table-responsive content">
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Date Created</th>
                <th>Action</th>
              </tr>
            </thead>
            {fetching ? <TizetiGIFLogoTable colSpan="4" /> : <TeamTable />}
          </table>
        </div>
      </div>
    </div>
  );
};

export default TeamsBody;
