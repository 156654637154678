import React from 'react';
import usePtp from '../hooks/usePtp';
import { PtpContext } from './PtpContext';

const PtpProvider = ({ children }) => {
  const { data, loading, paginateChange, loadingForm, onChange, formValue, onSubmit, pages } = usePtp();
  return (
    <PtpContext.Provider
      value={{
        data,
        loading,
        paginateChange,
        loadingForm,
        onChange,
        formValue,
        onSubmit,
        pages,
      }}
    >
      {children}
    </PtpContext.Provider>
  );
};
export default PtpProvider;
