import React from 'react';

export default {
  installationStatusLabels(status) {
    switch (status) {
      case 'completed':
        return <span className="badge badge-success">{status.toUpperCase()}</span>;

      case 'not completed':
        return <span className="badge badge-danger">{status.toUpperCase()}</span>;

      case 'on-going':
        return <span className="badge badge-secondary">{status.toUpperCase()}</span>;

      case 'queued':
        return <span className="badge badge-primary">{status.toUpperCase()}</span>;

      case 'awaiting_account_creation':
        return <span className="badge badge-warning">{status.toUpperCase()}</span>;

      case 'no-los':
        return <span className="badge badge-danger">{status.toUpperCase()}</span>;

      case 'failed':
        return <span className="badge badge-danger">{status.toUpperCase()}</span>;

      default:
        return 'no status';
    }
  },
};
