/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import useShared from '../../../departments/asset-team/shared/hooks/useShared';
import { RETENSION_SMS } from '../../../../../services/api/shared/installations/InstallationsUrl';
import Helpers from '../../customers/helpers/utils';

const SendBulkSms = ({ show, onClose, data, profile }) => {
  const { loading, onSubmit } = useShared(`${RETENSION_SMS}`, 'SMS Sent', 'Unable to send message');
  const [status, setStatus] = useState('');
  const onSubmitForm = (e) => {
    e.preventDefault();
    const newS = [];
    data.forEach((element) => {
      newS.push(Object.values(element));
    });

    //look into this asap
    onSubmit({
      action: 'retension_sms_helper',
      staff_email: profile.officialEmail,
      message: Helpers.messageToBeSent(status),
      customer_emails_phone: newS,
    });
  };
  const submitForm = () => {
    return status.length > 0;
  };

  const displayButton = () => {
    if (loading) {
      return (
        <button type="submit" className="btn btn-green" disabled={loading}>
          Sending SMS...
        </button>
      );
    }
    return (
      <button type="submit" className="btn btn-green" disabled={!submitForm()}>
        Send SMS
      </button>
    );
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ paddingLeft: '2rem' }}>Send Bulk SMS</Modal.Title>
      </Modal.Header>

      <form className="form-horizontal" onSubmit={onSubmitForm}>
        <div className="form-group">
          <div className="col-12 mb-3">
            <div className="col-12">
              <select
                id="status"
                name="status"
                onChange={(e) => setStatus(e.target.value)}
                className="form-control border border-info py-2"
              >
                <option value="">--Select Status--</option>
                <option value="NEW INSTALLS">NEW INSTALLS</option>
                <option value="ACTIVE CUSTOMERS">ACTIVE CUSTOMERS</option>
                <option value="EXPIRED CUSTOMERS">EXPIRED CUSTOMERS</option>
              </select>
            </div>
          </div>

          {status.length > 0 && (
            <div className="col-12 mb-3">
              <div className="col-12">
                <textarea className="form-control border border-info py-2" value={Helpers.messageToBeSent(status)} />
              </div>
            </div>
          )}
          <div className="col-12 mb-3 text-center">{displayButton()}</div>
        </div>
      </form>
    </Modal>
  );
};

export default SendBulkSms;
