import React from 'react';
import { ToastContainer } from 'react-toastify';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import SalesNavBar from '../../../../layouts/sales/SalesNavBar';
import InstallationsOverviewBody from '../../../shared/installations/leads-and-managers/InstallationsOverviewBody';
import { OverviewProvider } from '../../../../../context/shared/installations/overview/OverviewContext';

const SalesInstallations = () => {
  const { profile } = useUser();
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <SalesNavBar currentPage="Installations" position={profile.firstName} />
        <OverviewProvider>
          <InstallationsOverviewBody />{' '}
        </OverviewProvider>
        <ToastContainer />
      </main>
    </div>
  );
};

export default SalesInstallations;
