import React from 'react';
import DeviceRetrievalBody from '../../../shared/device-retrieval/DeviceRetrievalBody';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import DeviceRetrievalProvider from '../../../shared/device-retrieval/provider/DeviceRetrievalProvider';
import AssetTeamNavBar from '../../../../layouts/asset-team/AssetTeamNavBar';

const AssetDeviceRetrieval = () => {
  const { profile } = useUser();
  const { firstName } = profile;

  return (
    <div className="s-layout">
      <DeviceRetrievalProvider>
        <main className="s-layout__content col-lg-10 p-0">
          <AssetTeamNavBar currentPage="Device Retrieval" position={firstName} />
          <DeviceRetrievalBody />
        </main>
      </DeviceRetrievalProvider>
    </div>
  );
};

export default AssetDeviceRetrieval;
