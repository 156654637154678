import { API_URL } from '../../../utils/config';
import { BASE_URL } from '../url';

export const GET_BASESTATIONS_URL = `${API_URL}/baseStation`;
export const CREATE_BASESTATION_URL = `${API_URL}/baseStation`;
export const DELETE_BASESTATION_URL = `${API_URL}/basestation`;
export const GET_BASESTATION_ZONES_URL = `${API_URL}/zones`;
export const EDIT_BASESTATION_URL = `${API_URL}/basestation/`;
export const GET_CONNECTED_USERS_URL = `${API_URL}/basestation/count/`;
// export const SEARCH_FOR_LOS_WITH_DATE_URL = `${API_URL}/no-los/getlos/1`;
export const CHANGE_USER_BASESTATION_URL = `${API_URL}/account`;
export const GET_ALL_CALL_STATIONS = `${API_URL}/callstation`;
export const GET_ROUTER_URL = 'https://legacy.tizeti.com/geniatechapi/index.php';
export const SUB_BASE_STATION_URL = `${BASE_URL}/subbasestation`;
export const LIST_OF_BASESTATION_URL = `${BASE_URL}/basestationlist`;
export const DOWNLOAD_ACCOUNT_EXTENSION_SAMPLE = 'https://legacy.tizeti.com/sample_bulk_extend.php';
