import React from 'react';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../utils/loader/loadingHelper';
// import view from '../../../../assets/img/icons/view.png';
// import useKpiMonitor from './hooks/useKpiMonitor';

const KpiMonitorTable = ({ kpis, isLoading, completeKpi }) => {
  const showKpiStatus = () => {
    // {kpi.status < 100 ? (
    //     <span
    //         className="badge badge-danger"
    //         style={{
    //             backgroundColor: 'orange',
    //         }}
    //     >
    //                           Pending
    //                         </span>
    // ) : (
    //     <span
    //         className="badge badge-success"
    //         style={{
    //             backgroundColor: 'green',
    //         }}
    //     >
    //                           Completed
    //                         </span>
    // )}
  };
  // const showKpiStatus = () => {
  //     {kpi.status < 100 ? (
  //         <span
  //             className="badge badge-danger"
  //             style={{
  //                 backgroundColor: 'orange',
  //             }}
  //         >
  //                               Pending
  //                             </span>
  //     ) : (
  //         <span
  //             className="badge badge-success"
  //             style={{
  //                 backgroundColor: 'green',
  //             }}
  //         >
  //                               Completed
  //                             </span>
  //     )}
  // };

  const completeAKpi = (kpi) => {
    completeKpi(kpi);
  };
  const checkRoleForTableHead = () => {
    if (Number(sessionStorage.getItem('roleId')) === 3 && Number(sessionStorage.getItem('roleId')) !== 6) {
      return <th>Complete</th>;
    }
    return null;
  };
  const checkRoleForTableBody = (kpi) => {
    if (Number(sessionStorage.getItem('roleId')) === 3 && Number(sessionStorage.getItem('roleId')) !== 6) {
      return (
        <td id="label-switch">
          {Number(kpi.status) !== 0 && <select onClick={() => completeAKpi(kpi)}>1 2</select>}
          {/* {kpi.status < 100 ? ( */}
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}

          {/* ) : ( */}
          {/*  <img src={addimg} alt="" className="w-25 ml-3" /> */}
          {/* )} */}
        </td>
      );
    }
    return null;
  };
  const kpiData = () => {
    if (kpis && kpis.length > 0) {
      return kpis.map((kpi, i) => (
        <tr>
          <td>{i + 1}</td>
          <td>{kpi.name}</td>
          <td>{kpi.description}</td>
          <td>{moment(kpi.start_date).format('DD-MM-YYYY')}</td>
          <td>{moment(kpi.end_date).format('DD-MM-YYYY')}</td>
          <td>{kpi.status}</td>
          <td>{`${kpi.EmployeeAuth.employees[0].firstName} ${kpi.EmployeeAuth.employees[0].lastName}`}</td>
          {/* <td> */}
          {/*  <div className="d-flex"> */}
          {/*    <div className="view1 ml-2 img-pointer" data-target="#kpi-details" data-toggle="modal"> */}
          {/*      <img */}
          {/*        src={view} */}
          {/*        alt="" */}
          {/*        onClick={() => { */}
          {/*          this.getKpi(kpi); */}
          {/*        }} */}
          {/*      /> */}
          {/*    </div> */}
          {/*    /!* <KpiDetails kp={this.state.kpiId} /> *!/ */}
          {/*  </div> */}
          {/* </td> */}
          {checkRoleForTableBody(kpi)}
        </tr>
      ));
    }
    return <NoItemRow colSpan="6" text="No Kpis" />;
  };

  return (
    <>
      <div className="col-lg-12 p-0">
        <div className="card x-panel">
          <div className="table-responsive content">
            <table className="table copy-font">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Status</th>
                  <th>Name</th>
                  {/* <th>View</th> */}
                  {checkRoleForTableHead()}
                </tr>
              </thead>
              {isLoading ? <TizetiGIFLogoTable colSpan="8" /> : <tbody>{kpiData()}</tbody>}
            </table>
          </div>
        </div>
      </div>
      {!isLoading && (
        <ReactPaginate
          previousLabel="previous"
          nextLabel="next"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={kpis.pages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          // onPageChange={this.handlePageClick}
          containerClassName="pagination"
          // subContainerClassName="pages pagination"
          activeClassName="active"
        />
      )}
    </>
  );
};

export default KpiMonitorTable;
