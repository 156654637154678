import React from 'react';
import { get_terms_conditions } from '../../../../../actions/operations/terms-conditions/TCActions';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../../utils/loader/loadingHelper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const TCTable = () => {
  const [email, setEmail] = React.useState(null);
  const [tcdata, setTCData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const getTermsAndConditions = async () => {
    const terms_conditions = await get_terms_conditions();
    setTCData(terms_conditions);
    setIsLoading(false);
  };
  React.useEffect(() => {
    setIsLoading(true);
    getTermsAndConditions();
  }, []);

  const handleSearch = () => {
    setIsLoading(true);
    getTermsAndConditions(email);
  };
  return (
    <div>
      <div className="table-responsive content col-12" style={{ marginTop: '7rem' }}>
        <Box>
          <TextField
            id="filled-search"
            label="Enter email"
            placeholder="enter email"
            variant="filled"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <Button variant="contained" onClick={() => handleSearch()}>
            Search
          </Button>
        </Box>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>S/N</th>
              <th>Email</th>
              <th>Approval Status</th>
              <th>File</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <TizetiGIFLogoTable colSpan="8" />
            ) : (
              <>
                {tcdata ? (
                  tcdata.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.email}</td>
                        <td>{item.terms_approval}</td>
                        <td>
                          {item.file !== null ? (
                            <a href={item.file}>
                              <i className="fa fa-download" aria-hidden="true">
                                {' '}
                                {''} Download PDF
                              </i>
                            </a>
                          ) : (
                            'no file'
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <NoItemRow colSpan={8} text="There are no records" />
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      <div
        id="viewTCFile"
        className="modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl p-5">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center" id="myModalLabel">
                Terms & Conditions File
              </h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
            </div>
            <div className="modal-body modal-dialog-scrollable detail" style={{ padding: '0' }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TCTable;
