/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/button-has-type */
import React from 'react';
import moment from 'moment';
import usePayslip from '../../../../../hooks/shared/payslip/usePayslip';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import PayslipUrl from '../../../../../services/api/shared/payslip/Payslip';
import { showDepartment, showRole } from '../../../../../utils/lib';
import useGetPayslip from '../hook/useGetPayslip';

const Payslip = () => {
  const { user } = useUser();
  const { profile } = useUser();
  const { role_id } = user;
  // const { id } = profile;

  const data = {
    from: moment()
      .subtract(1, 'months')
      .format('YYYY-MM-DD'),
    to: moment()
      .add(1, 'day')
      .format('YYYY-MM-DD'),

    employee_id: profile.id,
  };

  const { response, loading } = useGetPayslip(`${PayslipUrl.SEARCH_PAYSLIP_URL}`, data);
  const {
    // search,
    creating,
    downloadUserPayslip,
  } = usePayslip();
  // const { from, to } = search;

  const { firstName, lastName, officialEmail, department_id } = profile;

  // const submit = () => from.length > 0 && to.length > 0 && id.length > 0;
  const showPayslip = () => {
    if (response.id) {
      return (
        <>
          <div className="form-group m-0 p-0 row">
            <label className="m-0 p-0 payslipfont col-sm-2 text-left col-form-label" htmlFor="email">
              Official Email:
            </label>
            <div className="col-sm-5 mb-2">
              <input type="text" readOnly className="form-control" id="email" value={officialEmail} />
            </div>
          </div>

          <div className="form-group m-0 p-0 row">
            <label className="m-0 p-0 payslipfont col-sm-2 text-left col-form-label" htmlFor="department">
              Department:
            </label>
            <div className="col-sm-5 mb-2">
              <input
                type="text"
                readOnly
                className="form-control"
                id="department"
                value={showDepartment(department_id)}
              />
            </div>
          </div>

          <div className="form-group m-0 p-0 row">
            <label className="m-0 p-0 col-sm-2 payslipfont text-left col-form-label" htmlFor="designation">
              Designation:
            </label>
            <div className="col-sm-5 mb-2">
              <input
                type="text"
                className="form-control border"
                id="designation"
                readOnly
                value={showRole(Number(role_id))}
              />
            </div>
          </div>

          <div className="form-group m-0 p-0 row">
            <label className="m-0 p-0 payslipfont col-sm-2 text-left col-form-label">Month/Year:</label>
            <div className="col-sm-5 mb-2">
              <input
                type="text"
                className="form-control border"
                id="designation"
                readOnly
                value={`${`${response.month}- ${response.year}`}`}
              />
            </div>
          </div>

          <h4 className="boldfont mt-4 mb-1">SALARY</h4>
          <span className="m-0 p-0 row col-12">
            <div className="form-group m-0 p-0 d-flex col-6">
              <label className="m-0 p-0 payslipfont text-left col-4 col-form-label" htmlFor="basic_salary">
                Basic Salary:
              </label>
              <div className="mb-2 ml-3">
                <input
                  type="number"
                  className="form-control border col-12"
                  id="basic_salary"
                  name="basic_salary"
                  readOnly
                  value={response.basic_salary}
                />
              </div>
            </div>
            <div className="form-group m-0 p-0 d-flex col-6">
              <label className="m-0 p-0 payslipfont text-left col-4 col-form-label" htmlFor="arrears">
                Arrears:
              </label>
              <div className="mb-2 ml-3">
                <input
                  type="number"
                  name="arrears"
                  value={response.arrears}
                  readOnly
                  className="form-control border col-12"
                  id="arrears"
                />
              </div>
            </div>
          </span>

          {/* <span className="m-0 p-0 row col-12"> */}
          {/*  <div className="form-group m-0 p-0 d-flex col-6"> */}
          {/*    <label className="m-0 p-0 payslipfont text-left col-4 col-form-label" htmlFor="basic_salary"> */}
          {/*      Year-End Bonus: */}
          {/*    </label> */}
          {/*    <div className="mb-2 ml-3"> */}
          {/*      <input type="text" className="form-control border col-12" id="basic_salary" value="-" /> */}
          {/*    </div> */}
          {/*  </div> */}
          {/* </span> */}

          <h4 className="boldfont mt-4 mb-1">ALLOWANCES</h4>
          <span className="m-0 p-0 row col-12">
            <div className="form-group m-0 p-0 d-flex col-6">
              <label className="m-0 p-0 payslipfont text-left col-4 col-form-label" htmlFor="housing">
                Housing Allowances:
              </label>
              <div className="mb-2 ml-3">
                <input
                  type="number"
                  className="form-control border col-12"
                  id="housing"
                  name="housing_allowances"
                  readOnly
                  value={response.housing_allowances}
                />
              </div>
            </div>
            <div className="form-group m-0 p-0 d-flex col-6">
              <label className="m-0 p-0 payslipfont text-left col-4 col-form-label" htmlFor="lunch">
                Lunch Subsidy:
              </label>
              <div className="mb-2 ml-3">
                <input
                  type="number"
                  className="form-control border col-12"
                  id="lunch"
                  name="lunch_subsidy"
                  readOnly
                  value={response.lunch_subsidy}
                />
              </div>
            </div>
          </span>

          <span className="m-0 p-0 row col-12">
            <div className="form-group m-0 p-0 d-flex col-6">
              <label className="m-0 p-0 payslipfont text-left col-4 col-form-label" htmlFor="medical">
                Medical Allowances:
              </label>
              <div className="mb-2 ml-3">
                <input
                  type="number"
                  className="form-control border col-12"
                  id="medical"
                  readOnly
                  name="medical_allowances"
                  value={response.medical_allowances}
                />
              </div>
            </div>
            <div className="form-group m-0 p-0 d-flex col-6">
              <label className="m-0 p-0 payslipfont text-left col-4 col-form-label" htmlFor="utility">
                Utility Allowances:
              </label>
              <div className="mb-2 ml-3">
                <input
                  type="number"
                  className="form-control border col-12"
                  id="utility"
                  readOnly
                  name="utilities_allowances"
                  value={response.utilities_allowances}
                />
              </div>
            </div>
          </span>

          <span className="m-0 p-0 row col-12">
            <div className="form-group m-0 p-0 d-flex col-6">
              <label className="m-0 p-0 payslipfont text-left col-4 col-form-label" htmlFor="dressing">
                Dressing Allowances:
              </label>
              <div className="mb-2 ml-3">
                <input
                  type="number"
                  className="form-control border col-12"
                  id="dressing"
                  name="dressing_allowance"
                  value={response.dressing_allowance}
                  readOnly
                />
              </div>
            </div>
            <div className="form-group m-0 p-0 d-flex col-6">
              <label className="m-0 p-0 payslipfont text-left col-4 col-form-label" htmlFor="transport">
                Transport Allowances:
              </label>
              <div className="mb-2 ml-3">
                <input
                  type="number"
                  className="form-control border col-12"
                  id="transport"
                  readOnly
                  name="transport_allowances"
                  value={response.transport_allowances}
                />
              </div>
            </div>

            <div className="form-group m-0 p-0 d-flex col-6">
              <label className="m-0 p-0 payslipfont text-left col-4 col-form-label" htmlFor="transport">
                Leave bonus:
              </label>
              <div className="mb-2 ml-3">
                <input
                  type="number"
                  className="form-control border col-12"
                  id="transport"
                  readOnly
                  name="allowance"
                  value={response.leave_bonus}
                />
              </div>
            </div>
          </span>

          {/* <div className="form-group m-0 p-0 row"> */}
          {/*  <label className="m-0 p-0 payslip-boldfont col-sm-2 text-left col-form-label" htmlFor="gross"> */}
          {/*    Gross Emolument: */}
          {/*  </label> */}
          {/*  <div className="col-sm-4 mb-2"> */}
          {/*    <input type="text" className="form-control border" id="gross" value="150,000.00" /> */}
          {/*  </div> */}
          {/* </div> */}

          <h4 className="boldfont mt-4 mb-1">DEDUCTION</h4>
          <span className="m-0 p-0 row col-12">
            <div className="form-group m-0 p-0 d-flex col-6">
              <label className="m-0 p-0 payslipfont text-left col-6 col-form-label" htmlFor="tax">
                Tax:
              </label>
              <div className="mb-2 ml-3">
                <input
                  type="number"
                  className="form-control border col-12"
                  id="tax"
                  name="tax"
                  value={response.tax}
                  readOnly
                />
              </div>
            </div>
            <div className="form-group m-0 p-0 d-flex col-6">
              <label className="payslipfont text-right pr-3 col-4 col-form-label" htmlFor="pension">
                Pension:
              </label>
              <div className="mb-2 ml-3">
                <input
                  name="pension"
                  value={response.pension}
                  readOnly
                  type="number"
                  className="form-control border col-12"
                  id="pension"
                />
              </div>
            </div>
          </span>

          <span className="m-0 p-0 row col-12">
            <div className="form-group m-0 p-0 d-flex col-6">
              <label className="m-0 p-0 payslipfont text-left col-6 col-form-label" htmlFor="nhf-fund">
                National Housing Fund (NHF):
              </label>
              <div className="mb-2 ml-3">
                <input
                  type="number"
                  name="national_housing_fund"
                  value={response.national_housing_fund}
                  readOnly
                  className="form-control border col-12"
                  id="nhf-fund"
                />
              </div>
            </div>
            <div className="form-group m-0 p-0 d-flex col-6">
              <label className="m-0 p-0 payslipfont text-right pr-4 col-4 col-form-label" htmlFor="loan">
                Loan:
              </label>
              <div className="mb-2 ml-3">
                <input
                  type="number"
                  name="loan"
                  value={response.loan}
                  readOnly
                  className="form-control border col-12"
                  id="loan"
                />
              </div>
            </div>
          </span>

          <span className="m-0 p-0 row col-12">
            <div className="form-group m-0 p-0 d-flex col-6">
              <label className="m-0 p-0 payslipfont text-left col-6 col-form-label" htmlFor="nhf-fund">
                Others:
              </label>
              <div className="mb-2 ml-3">
                <input
                  type="number"
                  name="others"
                  value={response.others}
                  readOnly
                  className="form-control border col-12"
                  id="nhf-fund"
                />
              </div>
            </div>
          </span>

          {/* <div className="form-group m-0 p-0 col-sm-12 row"> */}
          {/*  <label className="m-0 p-0 payslip-boldfont col-sm-3 text-left col-form-label" htmlFor="net_emolument"> */}
          {/*    Net Emolument: */}
          {/*  </label> */}
          {/*  <div className="col-sm-4 mb-2"> */}
          {/*    <input type="text" className="form-control border" id="net_emolument" value="130,764.68" /> */}
          {/*  </div> */}
          {/* </div> */}

          {/* deduction shit stops */}
          {/* <div className="mt-5"> */}
          {/*  <div className="form-group m-0 p-0 col-sm-12 row"> */}
          {/*    <label className="m-0 p-0 payslip-boldfont col-sm-3 text-left col-form-label" htmlFor="total_gross"> */}
          {/*      Total Gross Emolument: */}
          {/*    </label> */}
          {/*    <div className="col-sm-4 mb-2"> */}
          {/*      <input type="text" className="form-control border" id="total_gross" value="150,000.00" /> */}
          {/*    </div> */}
          {/*  </div> */}
          {/*  <div className="form-group m-0 p-0 col-sm-12 row"> */}
          {/*    <label className="m-0 p-0 payslip-boldfont col-sm-3 text-left col-form-label" htmlFor="total_deduction"> */}
          {/*      Total Deduction: */}
          {/*    </label> */}
          {/*    <div className="col-sm-4 mb-2"> */}
          {/*      <input type="text" className="form-control border" id="total_deduction" value="19,235.32" /> */}
          {/*    </div> */}
          {/*  </div> */}
          {/*  <div className="form-group m-0 p-0 col-sm-12 row"> */}
          {/*    <label className="m-0 p-0 payslip-boldfont col-sm-3 text-left col-form-label" htmlFor="total_net"> */}
          {/*      Total Net Emolument: */}
          {/*    </label> */}
          {/*    <div className="col-sm-4 mb-2"> */}
          {/*      <input type="text" className="form-control border" id="total_net" value="130,764.68" /> */}
          {/*    </div> */}
          {/*  </div> */}
          {/* </div> */}

          {/* deduction shit starts */}
          {/* eslint-disable-next-line react/button-has-type */}
          {creating ? (
            // eslint-disable-next-line react/button-has-type
            <button className="btn btn-green mx-1" disabled={creating}>
              submitting...
            </button>
          ) : (
            // <Pdf
            //   targetRef={ref}
            //   filename="code-example.pdf"
            //   options={options}
            //      // x={0.5} y={0.5}
            // >
            //   {({ toPdf }) => <button onClick={toPdf}>Generate Pdf</button>}
            // </Pdf>
            //   <Pdf targetRef={ref} filename="code-example.pdf">
            // {({ toPdf }) =>
            <button className="btn btn-green mx-1" type="button" onClick={() => downloadUserPayslip(response, profile)}>
              Payslip
            </button>
            //   </Pdf>
          )}
        </>
      );
    }
    return 'no payslip for user';
  };
  const payslipContent = () => {
    return (
      <div className="x-panel" id="payslip">
        <form className="m-0 p-0">
          <div className="form-group m-0 p-0 row">
            <label className="m-0 p-0 payslipfont text-left col-xl-2 col-md-2 col-sm-12 col-form-label" htmlFor="name">
              Name
              <small>(Surname first)</small>:
            </label>
            <div className="col-xl-5 col-md-5 col-sm-12 mb-2">
              <input type="text" readOnly className="form-control" id="name" value={`${firstName} ${lastName}`} />
            </div>
          </div>

          {showPayslip()}
        </form>
      </div>
    );
  };
  const checkIfUserHasProfile = () => {
    if (profile && 'id' in profile) {
      return payslipContent();
    }
    return <h1>user has no profile</h1>;
  };
  const ifLoadingPayslip = () => {
    if (loading) {
      return <h1>loading...</h1>;
    }
    return checkIfUserHasProfile();
  };
  return (
    <div className="col-12 after-summary">
      {/* <div className="row"> */}
      {/*  <div className="col-lg-12 col-md-12 col-sm-12 mt-3 mb-3 search d-flex"> */}
      {/*    <div className="d-flex employ inner-addon left-addon"> */}
      {/*      <p className="op copy-font d-flex mr-3"> */}
      {/*        <small className="mt-2 mr-2"> FilterByCountry:</small> */}
      {/*      </p> */}

      {/*      <p className="op copy-font d-flex mr-3"> */}
      {/*        <small className="mt-2 mr-2"> From: </small> */}
      {/*        <span> */}
      {/*          <input type="date" className="form-control" name="from" onChange={searchChange} value={from} /> */}
      {/*        </span> */}
      {/*      </p> */}

      {/*      <p className="op copy-font d-flex mr-3"> */}
      {/*        <small className="mt-2 mr-2"> To: </small> */}
      {/*        <span> */}
      {/*          <input type="date" className="form-control" name="to" onChange={searchChange} value={to} /> */}
      {/*        </span> */}
      {/*      </p> */}

      {/*      <div className="mr-auto"> */}
      {/*        <button className="btn btn-green" onClick={() => searchPayslip(search, id)} disabled={!submit()}> */}
      {/*          Search */}
      {/*        </button> */}
      {/*      </div> */}
      {/*    </div> */}
      {/*  </div> */}
      {/* </div> */}
      {ifLoadingPayslip()}
    </div>
  );
};

export default Payslip;
