/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import qs from 'qs';
import useExpressWifiAmbassadors from '../../../../../hooks/express-wifi/useExpressWifiAmbassadors';
import ExpressWifiAmbassadorsSignUpsTable from './ExpressWifiAmbassadorsSignUpsTable';

const ExpressWifiAmbassadorsSignUpsBody = ({ from_date, to_date }) => {
  const { getAmbassadorsSignUps, items, isLoading } = useExpressWifiAmbassadors();
  const [from, setFrom] = useState(from_date);
  const [to, setTo] = useState(to_date);

  const data = qs.stringify({
    action: 'get_count_of_ambassadors_signups',
    from: `${from}`,
    to: `${to}`,
  });

  useEffect(() => {
    getAmbassadorsSignUps(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    getAmbassadorsSignUps(data);
  };

  return (
    <div className="mt-5 p-3">
      <div className="container-fluid pt-4">
        <div className="row">
          <div className="col-lg-12 mt-3 mb-3">
            <form className="form-inline mt-4 inner-addon d-flex" onSubmit={onSubmit}>
              <div className="form-group mx-2">
                <label className="mt-2 p-0 copy-font">From: </label>
                <input
                  className="form-control mr-2"
                  name="from"
                  value={from}
                  readOnly={false}
                  onChange={(e) => setFrom(e.target.value)}
                  type="date"
                />

                <label className="mt-2 p-0 copy-font">To: </label>
                <input
                  className="form-control mr-2"
                  name="to"
                  value={to}
                  readOnly={false}
                  onChange={(e) => setTo(e.target.value)}
                  type="date"
                />
              </div>

              <div className="mb-3">
                <button className="btn btn-green" style={{ height: '39px' }}>
                  Search
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="row">
          <ExpressWifiAmbassadorsSignUpsTable items={items} isLoading={isLoading} />
        </div>
      </div>
    </div>
  );
};

export default ExpressWifiAmbassadorsSignUpsBody;
