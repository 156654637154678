import React from 'react';
import { Link } from 'react-router-dom';
import useCheckDepartment from '../../../../../../hooks/shared/custom/check-department/useCheckDepartment';
import { LOGGED_IN_USER_URL } from '../../../../../../services/api/auth/AuthUrl';
import { FIELD_SUPPORT, TECHNICAL_SUPPORT } from '../../../../../../utils/config';
import { useUser } from '../../../../../../hooks/shared/user/useUser';
import { useRequisition } from '../../../../../../hooks/shared/requisition/useRequisition';
import mailblast from '../../../../../../assets/img/icons/mailblast.svg';
import Logout from '../../../../shared/auth/Logout';
import { RequisitionProvider } from '../../../../../../context/shared/requisitions/RequsitionContext';
import GhanaRequsitionsFilters from './GhanaRequsitionsFilters';
import ManagerRequisition from '../../../../shared/requisition/shared/ManagerRequisition';
import MakeRequisition from '../../../../shared/requisition/shared/MakeRequisition';
import GhanaRequisitionsTable from './GhanaRequisitionsTable';
import FieldSupportSideBar from '../../../../../layouts/field-support/side-bar/FieldSupportSideBar';

const FieldSupportGhanaRequisitions = ({ history }) => {
  useCheckDepartment(`${LOGGED_IN_USER_URL}`, `${FIELD_SUPPORT}`, history);
  const {
    profile: { firstName },
  } = useUser();
  const {
    managerRequisitions,
    totalPages,
    isFetching,
    onChangeRequisition,
    requisition,
    onFileChange,
    file,
    handlePageClick,
    makeRequisition,
    isLoading,
    percentage,
    giveManagerApproval,
    giveManagerDisApproval,
    onChangeApproveOrDisapprove,
    status,
    isApprovingOrDisapproving,
    onChangeDisapprove,
    disapprove,
    onChangeApprove,
    approve,
    department,
    onChange,
    getFieldSupportRequisition,
    account,
    onChangeAccountStatus,
    getRequisitionByAccountStatus,
    filterRequisitionByCountry,
    getManagersRaisedRequisition,
    getRequsitionsByDepartment,
    getGhanaRequsition,
  } = useRequisition();
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <div className="d-flex col-lg-12 p-2 top-header">
          <div className="mt-3 d-flex col-lg-12 p-0">
            <FieldSupportSideBar />
            <h3 className="col-md-5 overview-part">Requisition</h3>
            <div className="ml-5 d-flex profile-mail">
              <Link
                to="/customer-support/mail-blast"
                className="d-none d-md-flex"
                style={{
                  color: 'gray',
                  textDecoration: 'none',
                }}
              >
                <img src={mailblast} alt="" className="logout mr-2" />
                <span>
                  <h6 className="m-2">
                    <strong>MailBlast</strong>
                  </h6>
                </span>
              </Link>
              <h6 className="mr-0 d-flex">
                <i className="small mr-2 d-none d-md-block">{firstName}</i>
                <Logout />
              </h6>
            </div>
          </div>
        </div>
        <div className="all-sides col-lg-12">
          <FieldSupportSideBar />
          <div className="container-fluid">
            <div className="row mt-3">
              <div className="col-lg-12 col-md-12 col-sm-12 mt-5">
                <RequisitionProvider
                  value={{
                    managerRequisitions,
                    totalPages,
                    handlePageClick,
                    isFetching,
                    onChangeApproveOrDisapprove,
                    status,
                    isApprovingOrDisapproving,
                    giveManagerApproval,
                    giveManagerDisApproval,
                    makeRequisition,
                    isLoading,
                    onChangeRequisition,
                    requisition,
                    onFileChange,
                    file,
                    percentage,
                    onChangeDisapprove,
                    disapprove,
                    onChangeApprove,
                    approve,
                    department,
                    onChange,
                    getFieldSupportRequisition,
                    account,
                    onChangeAccountStatus,
                    getRequisitionByAccountStatus,
                    filterRequisitionByCountry,
                    getManagersRaisedRequisition,
                    getRequsitionsByDepartment,
                    getGhanaRequsition,
                  }}
                >
                  <div className="row">
                    <span className="col-12 d-flex justify-content-between">
                      <GhanaRequsitionsFilters />
                      <div className="d-flex">
                        <ManagerRequisition />
                        <MakeRequisition />
                      </div>
                    </span>
                    <GhanaRequisitionsTable />
                  </div>
                </RequisitionProvider>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default FieldSupportGhanaRequisitions;
