import React from 'react';
import BusinessDevelopmentNavBar from '../../../../layouts/business-development/BusinessDevelopmentNavBar';
import BusinessDevelopmentDedicatedCustomersBody from '../../../shared/dedicated-customers/BusinessDevelopmentDedicatedCustomersBody';

const BusinessDevelopmentDedicatedCustomers = () => {
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <BusinessDevelopmentNavBar currentPage="Dedicated Customers" />
        <BusinessDevelopmentDedicatedCustomersBody />
      </main>
    </div>
  );
};

export default BusinessDevelopmentDedicatedCustomers;
