/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { months } from '../../../../utils/config';
import { useBudget } from '../../../../hooks/shared/budget/useBudget';
import Input from './form/Input';
import TextArea from './form/TextArea';

const Budget = () => {
  const { budget, onChange, createBudget, loading } = useBudget();
  const { itemType, itemDescription, typeDigit, quantity, month, total, comment } = budget;
  const submitForm = () => {
    return (
      itemType.length > 0 &&
      itemDescription.length > 0 &&
      typeDigit.length > 0 &&
      quantity.length > 0 &&
      month.length > 0 &&
      total.length > 0 &&
      comment.length > 0
    );
  };

  return (
    <div className="container-fluid col-lg-7">
      <form
        onSubmit={(e) => {
          createBudget(budget);
          e.preventDefault();
        }}
        className="form-horizontal mt-5"
      >
        <div className="form-group text-left">
          <div className="col-md-12 mb-5 d-flex p-0">
            <div className="col-md-12">
              <label className="copy-font p-0 m-0">Item Type</label>

              <Input name="itemType" value={itemType} onChange={onChange} />
            </div>
          </div>
          <div className="col-md-12 mb-5 d-flex p-0">
            <div className="col-md-12">
              <label className="copy-font p-0 m-0">Type Digit</label>
              <Input type="text" name="typeDigit" value={typeDigit} onChange={onChange} />
            </div>
          </div>
          <div className="col-md-12 mb-5 d-flex p-0">
            <div className="col-md-12">
              <label className="copy-font p-0 m-0">Quantity</label>
              <Input type="number" name="quantity" value={quantity} onChange={onChange} />
            </div>
          </div>

          <div className="col-md-12 mb-5 d-flex p-0">
            <div className="col-md-12">
              <label className="copy-font p-0 m-0">Total</label>

              <Input type="text" name="total" value={total} onChange={onChange} />
            </div>
          </div>
          <div className="col-md-12 mb-5 d-flex p-0">
            <div className="col-md-12">
              {/* {message ? (
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              ) : null} */}
              <label className="copy-font p-0 m-0">Currency</label>
              <select className="form-control col-md-6" name="currency" onChange={onChange}>
                <option>-- Currency --</option>
                <option value="dollars">Dollars</option>
                <option value="cedis">Cedis</option>
                <option value="naira">Naira</option>
              </select>
            </div>
          </div>
          <div className="col-md-12 mb-5 d-flex p-0">
            <div className="col-md-12">
              <label className="copy-font p-0 m-0">Month</label>
              <select className="form-control col-md-6" name="month" onChange={onChange}>
                <option>-- Month --</option>
                {months.map((mont) => (
                  <option key={mont} value={mont}>
                    {mont}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-md-12 mb-5 d-flex">
            <div>
              <label className="copy-font p-0 mb-0">Item Description</label>
              <TextArea
                name="itemDescription"
                value={itemDescription}
                onChange={onChange}
                placeholder="Enter Item Description"
              />
            </div>
          </div>
          <div className="col-md-12 mb-5 d-flex">
            <div>
              <label className="copy-font p-0 mb-0">Comment</label>

              <TextArea name="comment" value={comment} onChange={onChange} placeholder="enter a comment" />
            </div>
          </div>
        </div>

        <div className="col-12 mt-5">
          <button className="btn btn-green" type="submit" disabled={loading || !submitForm()}>
            {loading ? 'Submitting...' : 'Submit'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Budget;
