import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import WifiCallSidebar from '../../../../layouts/wificall-sidebar/WifiCallSidebar';
import Logout from '../../../shared/auth/Logout';
import PageName from '../../../shared/layouts/PageName';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import useFetch from '../../../../../hooks/shared/custom/data/useFetch';
import helpers from '../../../../../utils/helpers';
import { FREE_SIGNUPS_URL } from '../../../../../services/api/wificall/CustomersUrl';

const FreeSignUps = () => {
  const {
    profile: { firstName },
  } = useUser();
  const [currentPage, setCurrentPage] = useState(1);
  const { data, loading, getPaginatedData } = useFetch(`${FREE_SIGNUPS_URL}/${currentPage}`);
  const onPageChange = () => {
    setCurrentPage(currentPage + 1);
    getPaginatedData(`${FREE_SIGNUPS_URL}/${currentPage}`);
  };
  const signUpData = () =>
    data && data.result
      ? data.result.Ips.map((result, index) => (
          <tr>
            <td>{index + 1}</td>
            <td>{result.phoneNumber}</td>
            <td>{result.no_of_users}</td>
            <td>{result.name}</td>
            <td>{result.email}</td>
            <td>{result.businessName}</td>
            <td>{helpers.formatDateAndTime(result.created_at)}</td>
          </tr>
        ))
      : 'no results';
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <div className="d-flex col-lg-12 p-2 top-header">
          <div className="mt-3 d-flex col-lg-12 p-0">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link className="s-sidebar__trigger" to="#">
              <i className="fa fa-bars mr-3" />
            </Link>
            <WifiCallSidebar />
            <PageName name="Business Call" />
            <div className="d-flex">
              <h6 className="mr-0 mt-1">
                {firstName}
                <Logout />
              </h6>
            </div>
          </div>
        </div>
        <div className="col-12 after-summary">
          <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-3">
            <div className="row">
              <div className="card x-panel">
                <div className="table-responsive content">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Phone Number</th>
                        <th>Number of User</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Business Name</th>
                        <th>Created At</th>
                      </tr>
                    </thead>
                    {loading ? <small>loading</small> : <tbody>{signUpData()}</tbody>}
                  </table>
                </div>
              </div>
              {data && data.result ? (
                <ReactPaginate
                  previousLabel="Previous"
                  nextLabel="Next"
                  breakLabel="..."
                  breakClassName="break-me"
                  pageCount={data.result.pages}
                  marginPagesDisplayed={3}
                  pageRangeDisplayed={5}
                  onPageChange={onPageChange}
                  containerClassName="pagination"
                  activeClassName="active"
                />
              ) : null}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default FreeSignUps;
