import * as load from '../../../../constants/types/shared/LoadingTypes';
import * as types from '../../../../constants/types/operations/installations/InstallationsTypes';
import { FETCHED_CUSTOMER } from '../../../../constants/types/shared/customers/CustomerDetailsTypes';

const overviewReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case load.LOADING_STARTS:
      return {
        ...state,
        loading: true,
      };
    case load.LOADING_STOPS:
      return {
        ...state,
        installations: [],
        loading: false,
      };
    case types.GET_INSTALLATIONS: {
      return {
        ...state,
        loading: false,
        pages: payload.pages,
        installations: payload.list,
        completedInstallations: payload.list.filter((installation) => installation.status === 'completed'),
        LagosInstalls: payload.list.filter((installation) => installation.state === 'Lagos'),
        RiversInstalls: payload.list.filter((installation) => installation.state === 'Rivers'),
        OgunInstalls: payload.list.filter((installation) => installation.state === 'Ogun'),
        EdoInstalls: payload.list.filter((installation) => installation.state === 'Edo'),
      };
    }
    case FETCHED_CUSTOMER:
      return {
        ...state,
        pages: payload.pages,
        installations: payload.users,
        completedInstallations: payload.users.filter((installation) => {
          return installation.installations && installation.installations.length
            ? installation.installations[0].status === 'completed'
            : installation.installations.status === 'completed';
        }),
        loading: false,
      };
    case types.CLUSTERING_STARTS:
      return {
        ...state,
        clustering: true,
      };
    case types.CLUSTERING_STOPS:
      return {
        ...state,
        clustering: false,
      };
    case types.CLUSTER_INSTALLATION:
      return {
        ...state,
        clusteredInstalls: payload,
        clustering: false,
      };
    case load.ROUTING_STARTS:
      return {
        ...state,
        routing: true,
      };
    case load.ROUTING_STOPS:
      return {
        ...state,
        routing: false,
      };
    case types.ROUTE_INSTALLATION:
      return {
        ...state,
        routedInstalls: payload,
        routing: false,
      };
    case types.START_ASSIGN:
      return {
        ...state,
        assigning: true,
      };
    case types.STOP_ASSIGN:
      return {
        ...state,
        assigning: false,
      };
    case types.DELETE_INSTALLATION:
      return {
        ...state,
        installations: state.installations.filter((i) => i.installations[0].id !== payload),
      };
    case types.GET_INSTALLATION:
      return {
        ...state,
        anInstallation: payload,
      };
    case types.GET_ASSIGNED_STAFF:
      return {
        ...state,
        assignedStaffCount: payload.assignedStaffCount,
        assignedStaffRows: payload.assignedStaffRows,
      };
    default:
      return state;
  }
};
export default overviewReducer;
