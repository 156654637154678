/* eslint-disable import/prefer-default-export */
/**
 * used by field support
 * used by accounts
 */
import { useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { API_URL } from '../../../utils/config';
import { GET_NO_LOS_URL } from '../../../services/api/field-support/LosUrl';
import { noLosReducer } from '../../../reducers/shared/no-los/noLosReducer';
import { GET_NO_LOS } from '../../../constants/types/shared/no-los/NoLosTypes';
import { LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';

export const useNoLos = () => {
  const losState = {
    address: '',
    latitude: '',
    longitude: '',
    notes: '',
    loading: false,
    country: '',
    state: '',
  };
  const [los, setLos] = useState(losState);
  const [{ noLos, loading }, dispatch] = useReducer(noLosReducer, { noLos: [], loading: true });
  const onChange = (e) => {
    const { name, value } = e.target;
    setLos({
      ...los,
      [name]: value,
    });
  };
  const addLos = async (data) => {
    setLos({ loading: true });

    try {
      const response = await axios.post(`${API_URL}/los/addlos`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.status === 'failed') {
        toastr.error(response.data.message);
        setLos({ loading: false });
      }

      if (response.data.id) {
        setLos(losState);
        toastr.success('no-los added');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        setLos({
          loading: false,
        });
      }
    } catch (e) {
      setLos({ loading: false });
    }
  };
  const getNoLos = async () => {
    return axios.get(`${GET_NO_LOS_URL}/1?country=ng`, {
      headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
    });
    // console.log(response.data);
    // if(response.data) setNoLos(response.data);
    // return null;
  };

  useEffect(() => {
    getNoLos()
      .then((response) => {
        if (response.data) {
          dispatch({ type: GET_NO_LOS, payload: response.data });
        }
        dispatch({ type: LOADING_STOPS });
      })
      .catch(() => {
        dispatch({ type: LOADING_STOPS });
      });
  }, []);
  return { los, onChange, addLos, noLos, loading };
};
