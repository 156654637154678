import React, { Component } from 'react';

class DeviceDetails extends Component {
  render() {
    return (
      <div
        id="device-details"
        className="modal fade in"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title ml-5" id="myModalLabel">
                North Point Device Details
              </h4>
              <button type="button" className="close custom-close" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
            </div>
            <div className="modal-body detail" style={{ padding: '0', textAlign: 'justify' }}>
              <div className="row">
                <div className="col-lg-12 d-flex">
                  <div className="col-lg-6 detail-label1">
                    <label id="fn">CCR1036-12G-2S</label>
                    <label id="ln">CCR1036-8G-2S</label>
                    <label id="email">RB1100</label>
                    <label id="email">RB3011</label>
                    <label id="email">RB2011</label>
                  </div>
                  <div className="col-lg-6 detail-result1">
                    <p className="mt-4 mb-2">0</p>
                    <p className="mb-2">146</p>
                    <p className="mb-2">0</p>
                    <p className="mb-2">0</p>
                    <p className="mb-2">0</p>
                  </div>
                </div>

                <div className="col-lg-12 d-flex">
                  <div className="col-lg-6 detail-label2">
                    <label>AF24</label>
                    <label>AF5</label>
                    <label>AF5U</label>
                  </div>
                  <div className="col-lg-6 detail-result2">
                    <p className="mt-4 mb-2">0</p>
                    <p className="mb-2">0</p>
                    <p className="mb-2">0</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DeviceDetails;
