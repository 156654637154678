/* eslint-disable import/prefer-default-export */
import * as load from '../../../constants/types/shared/LoadingTypes';
import * as types from '../../../constants/types/sales/customers/RelocationTypes';
import * as typed from '../../../constants/types/sales/installations/InstallationsTypes';

export const relocationReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case load.LOADING_STARTS:
      return {
        ...state,
        isLoading: true,
        loader: true,
        loads: true,
      };

    case load.LOADING_STOPS:
      return {
        ...state,
        isLoading: false,
        loader: false,
        loads: false,
      };

    case types.GET_RELOCATION_REQUEST:
      return {
        ...state,
        lists: payload.lists,
        rows: payload.rows,
        pages: payload.pages,
        count: payload.count,
        monthlyCount: payload.monthlyCount,
        isLoading: false,
      };

    case typed.GET_ALL_INSTALLATIONS:
      return {
        ...state,

        items: payload.items,
        // rows:payload.rows,
        pages: payload.pages,
        count: payload.count,
        loader: false,
        // loads:false,
      };

      case typed.GET_COMPLETED_INSTALLATIONS:
        return {
          ...state,
          completedInstalls: payload.completedInstalls,
          completedCount: payload.completedCount,
          completedInstallPages: payload.completedInstallPages,
          isLoading: false,

        }

      case typed.GET_INSTALLATION_COUNTER:
        return {
          ...state,
          installationCount: payload.installationCount,
          isLoading: false,
        }


      case typed.GET_FAILED_INSTALLATIONS:
        return {
          ...state,
          rows:payload.rows,
          // items: payload.items,
          pages: payload.pages,
          count: payload.count,
          // loader: false,
          loads:false,
        };
  
    default:
      return state;
  }
};
