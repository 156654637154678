import axios from 'axios';
import { toast } from 'react-toastify';
import { useReducer } from 'react';
import { LOADING_STARTS, LOADING_STOPS } from '../../constants/types/shared/LoadingTypes';
import { GET_AMBASSADORS_URL } from '../../services/api/express-wifi/ambassadors/AmbassadorsUrl';
import { GET_ACTIVE_AMBASSADORS } from '../../constants/types/express-wifi/ExpressWifiTypes';
import AmbassadorsReducer from '../../reducers/express-wifi/ambassadors/AmbassadorsReducer';

const useExpressWifiAmbassadors = () => {
  const [{ items, totalPages, currentPage, allPages, isLoading }, dispatch] = useReducer(AmbassadorsReducer, {
    items: [],
    totalPages: '',
    allPages: '',
    currentPage: '',
    isLoading: false,
  });

  const getActiveAmbassadors = async (data) => {
    dispatch({ type: LOADING_STARTS });
    try {
      const response = await axios.post(`${GET_AMBASSADORS_URL}`, data);
      if (response.data) {
        dispatch({
          type: GET_ACTIVE_AMBASSADORS,
          payload: {
            items: response.data.items,
            totalPages: response.data.total_records,
            allPages: response.data.total_pages,
            currentPage: response.data.current_page,
          },
        });
      }
    } catch (e) {
      toast.info('Something went wrong!');
      if (e.response) {
        dispatch({ type: LOADING_STOPS });
      }
    }
  };

  const getAmbassadorsSignUps = async (data) => {
    dispatch({ type: LOADING_STARTS });
    try {
      const response = await axios.post(`${GET_AMBASSADORS_URL}`, data);
      // console.log(response.data);
      if (response.data) {
        dispatch({
          type: GET_ACTIVE_AMBASSADORS,
          payload: {
            items: response.data.items,
            totalPages: response.data.total_records,
            allPages: response.data.total_pages,
            currentPage: response.data.current_page,
          },
        });
      }
    } catch (e) {
      toast.info('Something went wrong!');
      if (e.response) {
        dispatch({ type: LOADING_STOPS });
      }
    }
  };
  const getListOfCustomers = async (data) => {
    dispatch({ type: LOADING_STARTS });
    try {
      const response = await axios.post(`${GET_AMBASSADORS_URL}`, data);
      console.log(response.data);
      if (response.data) {
        dispatch({
          type: GET_ACTIVE_AMBASSADORS,
          payload: {
            items: response.data.items,
            totalPages: response.data.total_records,
            allPages: response.data.total_pages,
            currentPage: response.data.current_page,
          },
        });
      }
    } catch (e) {
      toast.info('Something went wrong!');
      if (e.response) {
        dispatch({ type: LOADING_STOPS });
      }
    }
  };

  return {
    getActiveAmbassadors,
    getAmbassadorsSignUps,
    getListOfCustomers,
    items,
    totalPages,
    allPages,
    currentPage,
    isLoading,
  };
};

export default useExpressWifiAmbassadors;
