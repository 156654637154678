/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import Moment from 'react-moment';
import PropTypes from 'prop-types';

const ViewApprovedPO = ({
  order: {
    item,
    quantity,
    currency,
    amount,
    budget_code,
    comment,
    manager_approval,
    manager_approval_timestamp,
    manager_comment,
    procurement_approval,
    procurement_approval_timestamp,
    procurement_comment,
    account_approval,
    account_approval_timestamp,
    account_comment,
  },
}) => {
  return (
    <div id="viewPO" className="modal fade mt-5" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title my-2">Order Details</h4>
            <button type="button" className="mr-3 close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div className="modal-body detail p-0">
            <div className="row">
              <div className="col-lg-12 d-flex">
                <div className="col-lg-5 detail-label1">
                  <label className="mt-1">Order Name</label>
                  <label>Quantity</label>
                  <label>Amount</label>
                  <label>Budget Code</label>
                  <label>Comment</label>
                </div>
                <div className="col-lg-7 detail-result1 item-font">
                  <p className="mt-4 mb-2">{item}</p>
                  <p className="mb-2">{quantity}</p>
                  <p className="mb-2">{amount ? `${currency} ${amount}` : 'N/A'}</p>
                  <p className="mb-2">{budget_code || 'N/A'}</p>
                  <p className="mb-2">{comment || 'NO COMMENT'}</p>
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-5 detail-label2">
                  <label>Manager Approval</label>
                  <label>Manager Timestamp</label>
                  <label>Manager Comment</label>
                </div>
                <div className="col-lg-7 detail-result2 item-font">
                  <p className="mt-4 mb-1 text-capitalize">{manager_approval}</p>
                  <p className="mb-1">
                    {manager_approval_timestamp ? (
                      <Moment format="DD-MM-YYYY hh:mm" date={manager_approval_timestamp} />
                    ) : (
                      'N/A'
                    )}
                  </p>
                  <p className="mb-1">{manager_comment || 'NO COMMENT'}</p>
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-5 detail-label1 text-nowrap">
                  <label className="mt-1">Procurement Approval</label>
                  <label className="mt-1">Procurement Timestamp</label>
                  <label className="mt-1">Procurement Comment</label>
                </div>
                <div className="col-lg-7 detail-result2 item-font">
                  <p className="mt-4 mb-2 text-capitalize">{procurement_approval}</p>
                  <p className="mb-2">
                    {procurement_approval_timestamp ? (
                      <Moment format="DD-MM-YYYY hh:mm" date={procurement_approval_timestamp} />
                    ) : (
                      'N/A'
                    )}
                  </p>
                  <p className="mb-2">{procurement_comment || 'NO COMMENT'}</p>
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-5 detail-label3">
                  <label className="mt-1">CFO Approval</label>
                  <label className="mt-1">CFO Timestamp</label>
                  <label className="mt-1">CFO Comment</label>
                </div>
                <div className="col-lg-7 detail-result1 item-font">
                  <p className="mt-4 mb-2 text-capitalize">{account_approval}</p>
                  <p className="mb-2">
                    {account_approval_timestamp ? (
                      <Moment format="DD-MM-YYYY hh:mm" date={account_approval_timestamp} />
                    ) : (
                      'N/A'
                    )}
                  </p>
                  <p className="mb-2">{account_comment || 'NO COMMENT'}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ViewApprovedPO.propTypes = {
  order: PropTypes.objectOf(),
};

export default ViewApprovedPO;
