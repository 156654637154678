import { GET_CONNECTED_DEDICATED_CUSTOMERS } from '../../../constants/types/business-development/connected-dedicated-customers/ConnectedDedicatedCustomersTypes';
import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';

const initialState = {
  customers: [],
  totalPages: '',
  allPages: '',
  currentPage: '',
  isLoading: false,
};

const ConnectedDedicatedCustomersReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOADING_STARTS:
      return {
        ...state,
        isLoading: true,
      };

    case GET_CONNECTED_DEDICATED_CUSTOMERS:
      return {
        ...state,
        customers: payload.customers,
        totalPages: payload.totalPages,
        allPages: payload.allPages,
        currentPage: payload.currentPage,
        isLoading: false,
      };

    case LOADING_STOPS:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default ConnectedDedicatedCustomersReducer;
