/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';

const ExpressWifiAmbassadorsTable = ({ items, isLoading }) => {
  const tableData =
    items.length && items
      ? items.map((item, i) => {
          const { name, created_at, phone_number } = item;
          return (
            <tr className="text-nowrap" key={i}>
              <td>{i + 1}</td>
              <td>{name}</td>
              <td>{phone_number}</td>
              <td>{created_at}</td>
            </tr>
          );
        })
      : 'NO RECORD';
  return (
    <div className="card x-panel mt-2 px-0">
      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <thead>
            <tr className="">
              <th>#</th>
              <th>Name</th>
              <th>Phone Number</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="8" className="text-center">
                  <img src={loader} alt="loading gif" />
                </td>
              </tr>
            ) : (
              tableData
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

ExpressWifiAmbassadorsTable.propTypes = {
  items: PropTypes.arrayOf.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ExpressWifiAmbassadorsTable;
