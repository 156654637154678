/* eslint-disable import/prefer-default-export */
// mostluy using this for the hooks side of the app
// to get the count of verified users on the overview page for now
// export const customersReducer = ()
import * as types from '../../../constants/types/wificall/WificallTypes';
import * as load from '../../../constants/types/shared/LoadingTypes';

export const customerReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GET_WIFICALL_USERS:
      return {
        ...state,
        verified: payload.verifiedUsers,
        pages: payload.pages,
        verifiedCount: payload.count,
        isLoading: false,
      };

    case types.GET_FILTERED_WIFICALL_USERS:
      return {
        ...state,
        verified: payload.customers,
        unverified: payload.customers,
        pages: 1,
        filteredPages: payload.filteredPages,
        isLoading: false,
      };

    case types.SEARCH_WIFICALL_USERS:
      return {
        ...state,
        verified: payload.customers,
        unverified: payload.customers,
        pages: payload.pages,
        filteredPages: payload.filteredPages,
        isLoading: false,
      };

    case load.LOADING_STARTS:
      return {
        ...state,
        isLoading: true,
      };

    case load.LOADING_STOPS:
      return {
        ...state,
        isLoading: false,
      };

    case types.GET_UNVERIFIED_WIFICALL_USERS:
      return {
        ...state,
        unverified: payload.unverifiedUsers,
        pages: payload.pages,
        unverifiedCount: payload.count,
        isLoading: false,
      };

    case types.GET_CUSTOMER_PAYMENTS:
      return {
        ...state,
        logs: payload.logs,
        count: payload.count,
        paymentPages: payload.pages,
      };

    case types.GET_WIFICALL_PLANS:
      return {
        ...state,
        plans: payload,
      };

    default:
      return state;
  }
};
