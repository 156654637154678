import React from 'react';
import { ToastContainer } from 'react-toastify';
import ProfilesTable from './ProfilesTable';
import useProfile from '../../../../../hooks/shared/noc/lte/profiles/useProfile';
import { ProfileProvider } from '../../../../../context/shared/lte/profile/ProfileContext';
import CreateProfile from './CreateProfile';

const Profiles = () => {
  const { profile, onChange, onSubmit, creating, profiles, loading } = useProfile();
  return (
    <ProfileProvider value={{ profile, onChange, onSubmit, creating, profiles, loading }}>
      <div className="container">
        <div className="row mt-5">
          <div className="mt-5 col-lg-12 col-md-12 col-sm-12">
            <div className="d-flex employ inner-addon left-addon">
              <p className="op copy-font d-flex mr-auto">
                <small className="mt-2 mr-2"> Filter:</small>
                <span className="mr-2">
                  <select className="form-control">
                    <option>Nigeria</option>
                    <option>Lagos</option>
                    <option>Abeokuta</option>
                    <option>Port-Harcourt</option>
                  </select>
                </span>

                <span>
                  <select className="form-control">
                    <option>Ghana</option>
                  </select>
                </span>
              </p>

              <div>
                <button type="submit" className="btn btn-green" data-toggle="modal" data-target="#lteModal">
                  Create Profile
                </button>
                <CreateProfile />
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <ProfilesTable />
        </div>
      </div>
      <ToastContainer />
    </ProfileProvider>
  );
};

export default Profiles;
