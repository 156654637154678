/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import helpers from '../../../../../utils/helpers';
import edit from '../../../../../assets/img/icons/edit.png';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../../utils/loader/loadingHelper';
import EditPartnershipBandwidth from './EditPartnershipBandwidth';
import { setIn } from 'formik';

const PartnershipBandwidthTable = ({ lists, items, isLoading, query, getBandwidth }) => {
  const colSpan = '8';

  const [show, setShow] = useState(false);
  const [request, setRequest] = useState({
    id: '',
    rxspeed: '',
    rxtotalaverage: '',
    txspeed: '',
    txtotalaverage: '',
    ip: '',
    basestation: '',
    time: '',
  });
  const getRequest = (list, i) => {
    console.log(list);
    setShow(true);
    setRequest(list);
    setIn(i);
  };

  const tableData =
    lists && lists.length ? (
      lists
        .filter((list, _) => {
          if (query === '' || query === list.basestation) {
            return list;
          }
        })
        .map((list, i) => {
          return (
            <tr className="" key={i}>
              <td>{i + 1}</td>
              <td className="text-nowrap">{`${list.basestation}`}</td>
              <td>{list.ip || 'N/A'}</td>
              <td>{helpers.formatDateAndTimes(list.time)}</td>
              <td>{Number(list.txspeed).toFixed(6)}</td>
              <td>{Number(list.rxspeed).toFixed(6)}</td>
              <td>{Number(list.txtotalaverage).toFixed(6)}</td>
              <td>{Number(list.rxtotalaverage).toFixed(6)}</td>
              {/* <td>
                <div className="d-flex">
                  <button
                    type="submit"
                    className="mx-1 noBorder"
                    data-toggle="modal"
                    data-placement="left"
                    onClick={() => getRequest(list, i)}
                  >
                    <span className="view">
                      <img src={edit} alt="" data-toggle="tooltip" data-placement="left" title="Edit" />
                    </span>
                  </button>
                </div>
              </td> */}
            </tr>
          );
        })
    ) : (
      <NoItemRow colSpan={colSpan} text="There are no requests" />
    );

  return (
    <div className="table-responsive content col-12">
      <table className="table table-striped">
        <thead>
          <tr className="">
            <th> S/N</th>
            <th>Sites</th>
            <th className="text-nowrap">IP Address</th>
            <th className="text-nowrap">Time</th>
            <th className="text-nowrap"> Upload Speed</th>
            <th className="text-nowrap"> Download Speed</th>
            <th className="text-nowrap"> Upload Total Average</th>
            <th className="text-nowrap"> Download Total Average</th>
            {/* <th className="text-nowrap">Action</th> */}
          </tr>
        </thead>
        <tbody>{isLoading ? <TizetiGIFLogoTable colSpan={colSpan} /> : tableData}</tbody>
      </table>
      <EditPartnershipBandwidth
        request={request}
        show={show}
        setShow={setShow}
        getBandwidth={getBandwidth}
        lists={lists}
        items={items}
      />
    </div>
  );
};

export default PartnershipBandwidthTable;
