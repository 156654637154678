/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { useUser } from '../../../../../../hooks/shared/user/useUser';
import CustomerSupportExpiredGraph from './CustomerSupportExpiredGraph';
import CustomerSupportNavBar from '../../../../../layouts/customer-support/CustomerSupportNavBar';

const CustomerSupportExpiredCustomersByZones = () => {
  const { profile } = useUser();
  const { firstName } = profile;

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <CustomerSupportNavBar currentPage="Expired Customers by Zones" position={firstName} />
        <div className="mt-5">
          <CustomerSupportExpiredGraph />
        </div>
      </main>
    </div>
  );
};

export default CustomerSupportExpiredCustomersByZones;
