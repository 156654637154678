import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UploadFile from './UploadFile';
import ListFiles from './ListFiles';
import {
  ACCOUNTS,
  ADMIN_DEPARTMENT,
  CUSTOMER_SUPPORT,
  EXPRESS_WIFI,
  FIELD_SERVICES,
  WIFICALL,
  NOC,
  PROCUREMENT,
  RESEARCH_AND_DEVELOPMENT,
  SALES,
} from '../../../../utils/departments/googleDriveDepartments';

class LearningPortal extends Component {
  getDeptID = (department) => {
    switch (department) {
      case `ACCOUNTS`:
        return `${ACCOUNTS}`;

      case `ADMIN_DEPARTMENT`:
        return `${ADMIN_DEPARTMENT}`;

      case `CUSTOMER_SUPPORT`:
        return `${CUSTOMER_SUPPORT}`;

      case `EXPRESS_WIFI`:
        return `${EXPRESS_WIFI}`;

      case `FIELD_SERVICES`:
        return `${FIELD_SERVICES}`;

      case `WIFICALL`:
        return `${WIFICALL}`;

      case `NOC`:
        return `${NOC}`;

      case `PROCUREMENT`:
        return `${PROCUREMENT}`;

      case `RESEARCH_AND_DEVELOPMENT`:
        return `${RESEARCH_AND_DEVELOPMENT}`;

      case `SALES`:
        return `${SALES}`;

      default:
        return null;
    }
  };

  render() {
    const { department } = this.props;
    return (
      <div className="container mt-4">
        <UploadFile dept={this.getDeptID(department)} />
        <ListFiles dept={this.getDeptID(department)} />
      </div>
    );
  }
}

LearningPortal.propTypes = {
  department: PropTypes.string.isRequired,
};

export default LearningPortal;
