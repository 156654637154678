/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import SalesBBNTable from './SalesBBNTable';
import { useBBNSignups } from '../../../../../hooks/sales/bbn-signups/useBBNSignups';
import CustomCsvDownload from '../../../shared/CustomCsvDownload';
import { bbnLogHeader, filename } from '../../../../../utils/field-support/installations/helpers';

const SalesBBNBody = () => {
  const { getBBN, lists, isLoading } = useBBNSignups();
  console.log(lists);
  const [query, setQuery] = useState('');

  // const onSubmit = (e) => {
  //   e.preventDefault();
  //   searchLTE(data);
  // };

  useEffect(() => {
    getBBN();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mt-5 p-3">
      <div className="container-fluid pt-4 ">
        <div className="row">
        <form className="form-group inner-addon d-flex">
          
            <div className="input-group ml-5">
              Filter By
              <select
                style={{ marginLeft: '1rem' }}
                name="query"
                className="form-control"
                onChange={(e) => setQuery(e.target.value)}
              >
                <option value="">Select Country</option>
                <option value="ng">Nigeria</option>
                <option value="gh">Ghana</option>
                <option value="cv">Cote d'Ivoire</option>
              </select>
              
            </div>

           
          </form>
          <div className="pl-5 col-12 d-flex justify-content-between">
            {lists.length > 0 ? (
              <div>
                Count: <span className="badge badge-secondary mr-4">{lists.length}</span>
              </div>
            ) : null}

            <div className="col-12 d-flex mt-2">
              <CustomCsvDownload
                data={lists || []}
                headers={bbnLogHeader}
                filename={filename(`BBN SignUps`)}
                classname="btn btn-green shadow-md"
                textcolor="white"
                text="Download Data"
              />
            </div>
          </div>

          <SalesBBNTable items={lists} isLoading={isLoading} query={query} />
        </div>
      </div>
    </div>
  );
};

export default SalesBBNBody;
