/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-nested-ternary */
import React, { memo, useState } from 'react';

import helpers from '../../../../../utils/installations/helpers';

import RefundForm from '../../../departments/sales/refund/RefundForm';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../../utils/loader/loadingHelper';
import useRefund from '../../refund/hooks/useRefund';
import SendFeedback from '../../../departments/field-support/feedback/SendFeedback';

const CustomerSupportInstallationsTableBody = ({ load, allInstallations, setAllInstallations }) => {
  const { onChange, refund, show, handleClose, onSubmit, loadingForm } = useRefund();

  const [install, setInstall] = useState({});

  const colSpan = 18;

  const getOneInstallation = (i) => {
    setInstall(i);
  };

  const showFeedbackButton = (param) => {
    return (
      <td>
        <button
          className="text-center noBorder"
          type="button"
          onClick={() => {
            getOneInstallation(param);
          }}
          data-target="#sendFeedback"
          data-toggle="modal"
        >
          <img
            src="https://res.cloudinary.com/teewhy/image/upload/v1550751069/add-imgs.png"
            alt="Activate"
            data-toggle="tooltip"
            data-placement="left"
            title="sendFeedback"
          />
        </button>
        <SendFeedback account={install} />
      </td>
    );
  };

  const showInstallations = () =>
    allInstallations && allInstallations.length ? (
      allInstallations.map((installation, i) => { 
        const {
          user,
          status,
          firstName,
          lastName,
          userId,
          email,
          phoneNumber,
          payment_reference,
          address,
          userAddress,
          lat,
          lng,
          nearestBasestation,  
          sub_zone,
          zoneName,
          distance
        } = installation;
        return (
          <tr key={i + 1}>
            <td> {i + 1}</td>  
            <td>{user ? `${`${user.firstName} ${user.lastName}`}` : firstName ? `${firstName} ${lastName}` : 'N/A'}</td>
        
            <td>{payment_reference}</td>
            <td>{nearestBasestation || 'N/A'}</td>
            <td>{isNaN(distance) ? 'N/A' : `${distance / 1000} km`}</td>
            <td>{address || 'N/A'}</td>
            <td>{(lat + "/" + lng) || 'N/A'}</td>
            <td>{showFeedbackButton(installation)}</td>
            <td>{helpers.installationStatusLabels(status)}</td> 
            <td>{user ? user.email : email || 'N/A'}</td> 
            <td>{user ? user.phoneNumber : phoneNumber || 'N/A'}</td>
            <td>{userAddress}</td>
            <td>{sub_zone}</td>
            <td>{zoneName}</td> 
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan={colSpan} text="No Installations" />
    );
  const displayData = () => {
    if (load) return <TizetiGIFLogoTable colSpan={colSpan} />;
    return showInstallations();
  };
  return (
    <tbody>
      {displayData()}
      <RefundForm
        load={loadingForm}
        onSubmit={onSubmit}
        show={show}
        handleClose={handleClose}
        onChange={onChange}
        refund={refund}
        install={install}
      />
    </tbody>
  );
};

export default memo(CustomerSupportInstallationsTableBody);