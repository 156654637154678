import React from 'react';
import AppContext from '../../../../../../context/shared/AppContext';
import useCustomersTracker from '../hooks/useCustomersTracker';

const TrackerProvider = ({ children }) => {
  const { loading, onChange, onSubmit, params, validateSubmit, options, data } = useCustomersTracker();

  return (
    <AppContext.Provider
      value={{
        loading,
        onChange,
        onSubmit,
        params,
        validateSubmit,
        options,
        data,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export default TrackerProvider;
