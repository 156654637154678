/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import { DedicatedContext } from '../../../../../context/sales/DedicatedContext';

const AddDedicatedCustomer = () => {
  const { onChange, dedicated, addDedicated, submitting } = useContext(DedicatedContext);
  const {
    address,
    itemDescription,
    discount,
    email,
    expirationDate,
    itemCode,
    macId,
    name,
    price,
    quantity,
    subTotal,
    total,
    totalExVat,
    totalWithVat,
    unit,
  } = dedicated;

  return (
    <div
      id="addDedicated"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Add Dedicated
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body">
            <form className="form-horizontal" onSubmit={addDedicated}>
              <div className="form-group text-left">
                <div className="col-md-12 mb-3 d-flex">
                  <div>
                    <label className="copy-font p-0 mb-0">Customer Name</label>
                    <input
                      className="form-control"
                      name="name"
                      value={name}
                      onChange={onChange}
                      placeholder="Customer Name"
                    />
                  </div>
                  <div>
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
                    <label className="copy-font p-0 mb-0">Email</label>
                    <input
                      className="form-control"
                      name="email"
                      value={email}
                      onChange={onChange}
                      placeholder="Customer Email"
                    />
                  </div>
                </div>
                <div className="col-md-12 mb-3 d-flex">
                  <div>
                    <label className="copy-font p-0 mb-0">MAC ID</label>
                    <input
                      className="form-control"
                      name="macId"
                      value={macId}
                      onChange={onChange}
                      placeholder="Customer Mac Id"
                    />
                  </div>
                  <div>
                    <label className="copy-font p-0 mb-0">Expiration Date</label>
                    <input
                      className="form-control"
                      name="expirationDate"
                      type="date"
                      value={expirationDate}
                      onChange={onChange}
                      placeholder="Expiration Date"
                    />
                  </div>
                </div>
                <div className="col-md-12 mb-3 d-flex">
                  <div>
                    <label className="copy-font p-0 mb-0">Quantity</label>
                    <input
                      className="form-control"
                      name="quantity"
                      value={quantity}
                      onChange={onChange}
                      placeholder="quantity"
                    />
                  </div>
                  <div>
                    <label className="copy-font p-0 mb-0">Price</label>
                    <input
                      className="form-control"
                      name="price"
                      value={price}
                      onChange={onChange}
                      placeholder="Price"
                    />
                  </div>
                </div>
                <div className="col-md-12 mb-3 d-flex">
                  <div>
                    <label className="copy-font p-0 mb-0">Item Code</label>
                    <input
                      className="form-control"
                      name="itemCode"
                      value={itemCode}
                      onChange={onChange}
                      placeholder="Item Code"
                    />
                  </div>
                  <div>
                    <label className="copy-font p-0 mb-0">Discount</label>
                    <input
                      className="form-control"
                      name="discount"
                      value={discount}
                      onChange={onChange}
                      placeholder="Discount"
                    />
                  </div>
                </div>

                <div className="col-md-12 mb-3 d-flex">
                  <div>
                    <label className="copy-font p-0 mb-0">Total</label>
                    <input
                      className="form-control"
                      name="total"
                      value={total}
                      onChange={onChange}
                      placeholder="Total"
                    />
                  </div>
                  <div>
                    <label className="copy-font p-0 mb-0">Sub Total</label>
                    <input
                      className="form-control"
                      name="subTotal"
                      value={subTotal}
                      onChange={onChange}
                      placeholder="Sub Total"
                    />
                  </div>
                </div>
                <div className="col-md-12 mb-3 d-flex">
                  <div>
                    <label className="copy-font p-0 mb-0">Total Ex Vat</label>
                    <input
                      className="form-control"
                      name="totalExVat"
                      value={totalExVat}
                      onChange={onChange}
                      placeholder="Total Ex Vat"
                    />
                  </div>
                  <div>
                    <label className="copy-font p-0 mb-0">Unit</label>
                    <input className="form-control" name="unit" value={unit} onChange={onChange} placeholder="Unit" />
                  </div>
                </div>
                <div className="col-md-12 mb-3 d-flex">
                  <div>
                    <label className="copy-font p-0 mb-0">Total With Vat</label>
                    <input
                      className="form-control"
                      name="totalWithVat"
                      value={totalWithVat}
                      onChange={onChange}
                      placeholder="Total Ex Vat"
                    />
                  </div>
                  <div>
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
                    <label className="copy-font p-0 mb-0">Address</label>
                    <input
                      className="form-control"
                      name="address"
                      value={address}
                      onChange={onChange}
                      placeholder="Address"
                    />
                  </div>
                </div>
                <div className="col-md-12 mb-3 d-flex">
                  <div>
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
                    <label className="copy-font p-0 mb-0">Item Description</label>
                    <textarea
                      className="form-control"
                      name="itemDescription"
                      value={itemDescription}
                      onChange={onChange}
                      rows="5"
                      cols="50"
                      placeholder="Item Description"
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 mt-5 text-center">
                {submitting ? (
                  <button disabled={submitting} className="btn btn-green" type="submit">
                    Submitting...
                  </button>
                ) : (
                  <button className="btn btn-green" type="submit">
                    Submit
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddDedicatedCustomer;
