/* eslint-disable import/prefer-default-export */
import * as types from '../../constants/types/noc/NocTypes';
import * as load from '../../constants/types/shared/LoadingTypes';

export const ticketsReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GET_ALL_EMPLOYEES_IN_NOC:
      return {
        ...state,
        employees: payload,
      };

    case load.FETCHING_STARTS:
      return {
        ...state,
        isLoading: true,
        isCreating: false,
      };

    case load.FETCHING_STOPS:
      return {
        ...state,
        isLoading: false,
        isCreating: false,
      };

    case load.LOADING_STARTS:
      return {
        ...state,
        isCreating: true,
      };

    case load.LOADING_STOPS:
      return {
        ...state,
        isCreating: false,
      };

    case types.TICKET_CREATED:
      return {
        ...state,
        tickets: [payload, ...state.tickets],
        isCreating: false,
      };

    case types.FETCH_ALL_TICKETS:
      return {
        ...state,
        tickets: payload,
        isLoading: false,
      };

    default:
      return state;
  }
};
