import { HOTSPOT_API_URL } from '../../../utils/config';

export const GET_ALL_RETAILERS_URL = `${HOTSPOT_API_URL}/admin/fetch`;
export const GET_TOTAL_REVENUE_URL = `${HOTSPOT_API_URL}/admin/totalRevenue`;
export const GET_RETAILER_LOCATIONS_URL = `${HOTSPOT_API_URL}/admin/retailer/latlng`;
export const CREATE_RETAILER_URL = `https://api.tizeti.com:4000/auth/signup`;
export const GET_ACTIVATED_RETAILERS_URL = `${HOTSPOT_API_URL}/admin/activatedretailers`;
export const GET_PENDING_RETAILERS_URL = `${HOTSPOT_API_URL}/admin/pendingretailers`;
export const ACTIVATE_RETAILER_URL = `${HOTSPOT_API_URL}/admin/activate/retailer`;
export const GET_ONE_RETAILER_URL = `${HOTSPOT_API_URL}/admin/fetch/retailer?accountid=`;
