/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import SalesNavBar from '../../../../layouts/sales/SalesNavBar';
import { SALES } from '../../../../../utils/config';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import InstallationsPerBasestationBody from '../../../shared/installations/per-basestation/InstallationsPerBasestationBody';
import useCheckTwoDepartments from '../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';
import { SUPER_ADMIN } from '../../../../../utils/departments/helpers';

const SalesInstallationsPerBasestation = ({ history }) => {
  useCheckTwoDepartments(`${SALES}`, `${SUPER_ADMIN}`, history);
  const {
    profile: { firstName },
  } = useUser();

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <SalesNavBar currentPage="Installations Graph" position={firstName} />
        <InstallationsPerBasestationBody />
      </main>
    </div>
  );
};

export default SalesInstallationsPerBasestation;
