import * as types from '../../../constants/types/shared/crm/CrmTypes';

const initialState = {
  users: [],
  loading: false,
  searching: false,
};

export const CrmReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.START_GETTING_CRM_USERS:
      return {
        ...state,
        loading: true,
      };

    case types.STOP_GETTING_CRM_USERS:
      return {
        ...state,
        loading: false,
      };

    case types.GET_CRM_USERS:
      return {
        ...state,
        users: payload,
        loading: false,
        searching: false,
      };

    case types.START_SEARCHING_CRM_USERS:
      return {
        ...state,
        searching: true,
      };

    case types.STOP_SEARCHING_CRM_USERS:
      return {
        ...state,
        searching: false,
      };

    default:
      return state;
  }
};

export default CrmReducer;
