/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useContext } from 'react';
import CustomButton from '../../../../shared/forms/CustomButton';
import CustomInput from '../../../../shared/forms/CustomInput';
import FiberPptpContext from './context/FiberPptpContext';
import Label from '../../../../shared/forms/Label';

const AddFiberPptp = () => {
  const { loadingForm, onChange, formValue, onSubmit } = useContext(FiberPptpContext);
  const { basestation_name, longitude, pptp_ip, latitude, status } = formValue;
  const submitForm = () => {
    return (
      basestation_name.length > 0 &&
      longitude.length > 0 &&
      pptp_ip.length > 0 &&
      latitude.length > 0 &&
      status.length > 0
    );
  };
  return (
    <div id="add-access-point" className="modal fade mt-5" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header shadow">
            <h4 className="modal-title update_asset">Fiber Pptp</h4>
            <button type="button" className="btn btn-outline-info btn-circle btn-close" data-dismiss="modal">
              x
            </button>
          </div>
          <div className="modal-body detail mb-4 p-0">
            <form
              className="col-md-12 p-0"
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              <div className="d-flex">
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="Basestation Name" htmlfor="customerName" />
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="basestation_name"
                    value={basestation_name}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="Name of basestation anme"
                    type="text"
                    id="customerName"
                  />
                </div>
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="PPTP IP" htmlfor="customerEmail" />

                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="pptp_ip"
                    value={pptp_ip}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="154.113.89.138"
                    type="text"
                    id="customerEmail"
                  />
                </div>
              </div>

              <div className="d-flex">
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="Longitude" htmlfor="router_id" />

                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="longitude"
                    value={longitude}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="Enter Longitude"
                    type="text"
                    id="router_id"
                  />
                </div>
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="Latitude" htmlfor="radioMACID" />
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="latitude"
                    value={latitude}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="Latitude"
                    type="text"
                    id="radioMACID"
                  />
                </div>
              </div>

              <div className="d-flex">
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="Status" htmlfor="router_id" />
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="status"
                    value={status}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="E.g (Absent/Present)"
                    type="text"
                    id="router_id"
                  />
                </div>
              </div>

              <div className="text-center mt-3">
                {loadingForm ? (
                  <CustomButton classname="btn btn-green px-3" name="Adding" disabled={loadingForm} />
                ) : (
                  <CustomButton classname="btn btn-green px-3" name="Add" disabled={!submitForm()} />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFiberPptp;
