import React, { Component } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import moment from 'moment';
import { toast } from 'react-toastify';
import { API_URL } from '../../../../../utils/config';

class Rollback extends Component {
  constructor() {
    super();

    this.state = {
      date: '',
      macid: '',
      loading: false,
    };

    this.onChange = this.onChange.bind(this);
    this.rollback = this.rollback.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      macid: nextProps.username,
      name: nextProps.name,
    });
  }

  rollback = async (e) => {
    this.setState({
      loading: true,
    });

    e.preventDefault();

    const { macid, date, name } = this.state;

    const data = {
      // date: moment(this.state.date).format('Do MMMM YYYY'),
      date: moment.utc(date).format('D MMMM YYYY'),
      macid,
    };

    try {
      const response = await axios.post(`${API_URL}/geniatech/rollback`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.status === true) {
        const data = {
          macid,
          extending_days: date,
          comment: 'rollback comment',
          name,
          extension_type: 'rollback',
        };

        try {
          const res = await axios.post(`${API_URL}/geniatechlog/addlog`, data, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
          });

          if (res.data.id) {
            toast.success('Rollback Successful!');
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            toast.info('Something went wrong!');
            this.setState({
              loading: false,
            });
          }
        } catch (e) {
          if (e) {
            toast.info(e.response.data.message);
            this.setState({
              loading: false,
            });
          }
        }
      }
    } catch (e) {
      if (e) {
        toast.info('Something went wrong!');
        this.setState({
          loading: false,
        });
      }
    }
  };

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  render() {
    const { loading } = this.state;

    return (
      <div
        id="rollback"
        className="modal fade in"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center" id="myModalLabel">
                Roll Back
              </h4>
              <button type="button" className="close custom-close" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
            </div>

            <div className="modal-body">
              <form className="form-horizontal" onSubmit={this.rollback}>
                <div className="form-group">
                  <div className="col-12 d-flex">
                    <div className="col-6">
                      <label className="copy-font m-0 p-0">MAC ID</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="MAC ID"
                        name="macid"
                        onChange={this.onChange}
                        value={this.state.macid}
                        readOnly
                      />
                    </div>
                    <div className="col-6">
                      <label className="copy-font m-0 p-0">Date</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Date"
                        name="date"
                        onChange={this.onChange}
                        value={this.state.date}
                        required
                      />
                    </div>
                  </div>
                  {loading ? (
                    <div className="text-center mt-5">
                      <button className="btn btn-green" disabled>
                        {' '}
                        Submitting{' '}
                      </button>
                    </div>
                  ) : (
                    <div className="text-center mt-5">
                      <button className="btn btn-green"> Rollback </button>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Rollback;
