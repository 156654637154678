/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import RequisitionNotification from '../../staff/shared/layouts/sidebar/manager-and-executives/RequisitionNotification';
import PATHS from '../../../routes/urls';
import { AES } from 'crypto-js';
import {
  BudgetSideBar,
  PurchaseOrderTab,
} from '../../staff/shared/layouts/sidebar/manager-and-executives/BudgetSideBar';

const ResearchAndDevelopmentSideBar = () => {
  const departmentId = sessionStorage.getItem('departmentId');
  const officialEmail = sessionStorage.getItem('officialEmail');
  const role_id = sessionStorage.getItem('roleId');
  const detailsState = {
    name: 'React',
    messagetoken: sessionStorage.getItem('token'),
    messagerole: sessionStorage.getItem('roleId'),
    messagedepartment: sessionStorage.getItem('departmentId'),
    secret: 'Wifirocks123',
    cipher: '',
  };
  const [details, setDetails] = useState(detailsState);
  const { name, messagetoken, messagerole, messagedepartment, secret, cipher } = details;

  const obj = {
    login: messagetoken,
    pass: messagerole,
    department: messagedepartment,
  };

  const ciph = AES.encrypt(JSON.stringify(obj), secret).toString();

  return (
    <div className="s-layout__sidebar">
      <Link className="s-sidebar__trigger" to="#">
        <i className="fa fa-bars mr-3" />
      </Link>

      <nav className="s-sidebar__nav">
        <Link className="navbar-brand-logo" to="{/research-and-development}">
          <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971047/Tizeti-Logo.png" alt="homepage" />
        </Link>

        <ul>
          <span className="p-0 mt-3">
            <li className="mt-3 s-sidebar__nav-link">
              <Link to="/research-and-development" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971013/Group-3.png" alt="img" />
                </span>
                Overview
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
                {' '}
                <a href={`https://os.tizeti.com/main/#/overview?ciph=${ciph}`} target="_blank" className="make-bold">
                  <span>
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923169/configr_d.fw.png" alt="img" />
                  </span>
                  <small>New system (Beta v2.0)</small>
                </a>
              </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/research-and-development/projects" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923169/idontknow.fw.png" alt="img" />
                </span>
                Projects
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/research-and-development/feature-requests" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923169/configr_d.fw.png" alt="img" />
                </span>
                Feature Requests
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/research-and-development/issue-report" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923430/r_d-envelop.fw.png" alt="img" />
                </span>
                Issue Reports
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/research-and-development/promoter-score" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923430/r_d-envelop.fw.png" alt="img" />
                </span>
                Net Promoter Score
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/research-and-development/vehicle-tracking" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1553847314/vehicleTracking.png" alt="img" />
                </span>
                Vehicle Tracking
              </Link>
            </li>

            {/* {Number(`${sessionStorage.getItem('roleId')}`) > 2 ? ( */}
            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/research-and-development/customers" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923169/configr_d.fw.png" alt="img" />
                </span>
                Geniatech
              </Link>
            </li>
            {/* ) : null} */}
            {sessionStorage.getItem('roleId') > 2 ? <BudgetSideBar link={PATHS.RD_BUDGET} name="Budget" /> : null}

            {Number(`${sessionStorage.getItem('roleId')}`) > 2 ? (
              <li className="d-none d-md-block">
                {' '}
                <a href={PATHS.RESEARCH_AND_DEV_RECRUITMENT} rel="noopener noreferrer" target="_blank" className="">
                  <span>
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1555319728/recruitment_1.svg" alt="img" />
                  </span>{' '}
                  Recruitment
                </a>
              </li>
            ) : null}
          </span>

          <span className="p-0 mt-5 d-none d-md-block">
            <li className="s-sidebar__nav-link mt-5">
              {' '}
              <Link to="/research-and-development/learning-portal" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923169/idontknow.fw.png" alt="img" />
                </span>
                Learning Portal
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/research-and-development/kpi-monitor" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg" alt="img" />
                </span>
                KPI Monitor
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/research-and-development/admin-requests" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg" alt="img" />
                </span>
                Admin Request
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/research-and-development/requisition" target="_self" className="d-flex">
                <span className="mr-2">
                  <img
                    src="https://res.cloudinary.com/teewhy/image/upload/v1550844527/requisition.png"
                    alt="img"
                    className="w-100"
                  />
                </span>
                Requisition
                <RequisitionNotification />
              </Link>
            </li>

            <PurchaseOrderTab link="/research-and-development/purchase-order" name="Purchase Order" />

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/research-and-development/payslip" target="_self" className="">
                <span>
                  <img
                    src="https://res.cloudinary.com/teewhy/image/upload/v1550844527/requisition.png"
                    alt="img"
                    className="mr-2"
                  />
                </span>
                PaySlip
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/research-and-development/escalate" target="_self" className="text-danger make-bold">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1557141046/Schedule.png" alt="img" />
                </span>
                Escalate
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/research-and-development/issue" target="_self" className="text-danger make-bold">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923430/r_d-envelop.fw.png" alt="img" />
                </span>
                Report an Issue
              </Link>
            </li>

            {Number(`${sessionStorage.getItem('roleId')}`) > 2 ? (
              <li className="s-sidebar__nav-link">
                {' '}
                <Link to="/research-and-development/feature" target="_self">
                  <span className="mr-2">
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923169/configr_d.fw.png" alt="img" />
                  </span>
                  Request a Feature
                </Link>
              </li>
            ) : null}
          </span>
        </ul>
      </nav>
    </div>
  );
};

export default ResearchAndDevelopmentSideBar;
