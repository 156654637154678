/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { API_URL } from '../../../../../utils/config';

export default class AddFrequencyLog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ssid: '',
      address: '',
      oldfrequency: '',
      newfrequency: '',
      comment: '',
      basestations: [],
      basestation: '',
      loading: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount() {
    this.getBasestations();
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  onSubmit(e) {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    const data = {
      access_point_ssid: this.state.ssid,
      ip_address: this.state.address,
      frequency: this.state.oldfrequency,
      new_frequency: this.state.newfrequency,
      comments: this.state.comment,
      base_station_id: this.state.basestation,
    };
    axios({
      method: 'post',
      url: `${API_URL}/frequency/log`,
      data,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
      .then((response) => {
        if (response.data.status === 'success') {
          window.location.reload();
          toastr.success('Operation Successful!');
        } else {
          toastr.info('Something went wrong!');
          this.setState({
            loading: false,
          });
        }
      })
      .catch((error) => {
        if (error) {
          toastr.info('Something went wrong!');
          this.setState({
            loading: false,
          });
        }
      });
  }

  getBasestations() {
    axios({
      method: 'get',
      url: `${API_URL}/baseStation`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      }
    }).then((response) => {
      this.setState({
        basestations: response.data,
      });
    });
  }

  render() {
    const { loading } = this.state;
    const allFrequencies = [];
    for (let freq = 4995; freq < 6000; ) {
      allFrequencies.push((freq += 5));
    }
    const freqList = allFrequencies.map((freq) => {
      return <option key={freq}>{freq}</option>;
    });

    return (
      <div
        id="addlog"
        className="modal fade in"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center" id="myModalLabel">
                Add Frequency Log
              </h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
            </div>
            <div className="modal-body">
              <form className="form-horizontal" onSubmit={this.onSubmit}>
                <div className="form-group">
                  <div className="col-12 mb-3">
                    <label className="copy-font p-0 mb-0">Basestation Name</label>
                    {/* <input name="basestation" placeholder="Basestation Name" className="form-control copy-font"/> */}
                    <select onChange={this.onChange} name="basestation" className="form-control">
                      <option value={this.state.basestations} name="basestation">
                        Select Basestation
                      </option>

                      {this.state.basestations.map((basestation) => {
                        return (
                          <option key={basestation.id} value={basestation.id}>
                            {basestation.name.toUpperCase()}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="col-12 d-flex mb-3">
                    <div className="col-6 pl-0">
                      <label className="copy-font p-0 mb-0">AP SSID</label>
                      <input
                        type="text"
                        placeholder="AP SSID"
                        className="form-control"
                        name="ssid"
                        onChange={this.onChange}
                        value={this.state.ssid}
                      />
                    </div>

                    <div className="col-6 pr-0">
                      <label className="copy-font p-0 mb-0">IP ADDRESS</label>
                      <input
                        placeholder="IP ADDRESS"
                        className="form-control"
                        name="address"
                        onChange={this.onChange}
                        value={this.state.address}
                      />
                    </div>
                  </div>

                  <div className="col-12 d-flex mb-3">
                    <div className="col-6 pl-0">
                      <label className="copy-font p-0 mb-0">Old Frequency</label>
                      <input
                        placeholder="Old Frequency"
                        className="form-control"
                        name="oldfrequency"
                        list="frequencies"
                        onChange={this.onChange}
                        value={this.state.oldfrequency}
                      />
                      <datalist id="frequencies">
                        <select>{freqList}</select>
                      </datalist>
                    </div>

                    <div className="col-6 pr-0">
                      <label className="copy-font p-0 mb-0">New Frequency</label>
                      <input
                        placeholder="New Frequency"
                        className="form-control"
                        name="newfrequency"
                        list="frequencies"
                        onChange={this.onChange}
                        value={this.state.newfrequency}
                      />
                    </div>
                  </div>

                  <div className="col-12 mb-3">
                    <label className="copy-font p-0 mb-0">Comments</label>
                    <textarea
                      className="form-control"
                      name="comment"
                      onChange={this.onChange}
                      value={this.state.comment}
                      rows="5"
                      cols="200"
                    />
                  </div>
                  <div className="wrapper-button mb-3 mt-4">
                    {loading ? (
                      <button
                        className="btn btn-green copy-font"
                        id="login"
                        type="button"
                        style={{
                          backgroundColor: '#036D61',
                        }}
                      >
                        <i className="fa fa-circle-o-notch fa-spin" />
                      </button>
                    ) : (
                      <button
                        className="btn btn-green copy-font"
                        id="login"
                        type="submit"
                        style={{
                          backgroundColor: '#036D61',
                        }}
                      >
                        ADD
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
