import { useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import { toast } from 'react-toastify';
import moment from 'moment';
import retailerReducer from '../../../../../reducers/shared/express-wifi/retailerReducer';
import { LOADING_STARTS, LOADING_STOPS } from '../../../../../constants/types/shared/LoadingTypes';
import { SMS_URL } from '../../../../../services/api/express-wifi/sms/SmsUrls';
import { FETCH_RETAILER_SMS_LOGS } from '../../../../../constants/types/express-wifi/ExpressWifiTypes';

const useRetailer = () => {
  const [{ loading, sms }, dispatch] = useReducer(retailerReducer, {
    loading: false,
    sms: [],
  });
  const textState = {
    value: '',
  };

  const dateTimeState = {
    fromdate: '',
    fromtime: '',
    todate: '',
    totime: '',
    action: 'fetchAllRetailerSMSLogsDateRange',
  };
  const [text, setText] = useState(textState);
  const [dateTime, setDateTime] = useState(dateTimeState);
  const onChange = (e) => {
    const { name, value } = e.target;
    setText({
      ...text,
      [name]: value,
    });
  };

  const onDateTimeChange = (e) => {
    const { name, value } = e.target;
    setDateTime({
      ...dateTime,
      [name]: value,
    });
  };

  const getAllRetailerSMSLogs = async () => {
    dispatch({
      type: LOADING_STARTS,
    });
    const data = {
      action: 'fetchAllRetailerSMSLogs',
    };

    try {
      const response = await axios.post(`${SMS_URL}`, qs.stringify(data));
      if (response.data[0].status === true) {
        dispatch({
          type: FETCH_RETAILER_SMS_LOGS,
          payload: response.data.splice(1, response.data.length),
        });
      }
    } catch (e) {
      dispatch({
        type: LOADING_STOPS,
      });
    }
  };

  const searchWithPhone = async (data) => {
    dispatch({
      type: LOADING_STARTS,
    });
    const { value } = data;
    const post = qs.stringify({
      action: 'fetchSpecificRetailerSMSLogs',
      phoneNumber: value.trim(),
    });
    try {
      const response = await axios.post(`${SMS_URL}`, post);
      if (response.data[0].status === true) {
        dispatch({
          type: FETCH_RETAILER_SMS_LOGS,
          payload: response.data.splice(1, response.data.length),
        });
      } else {
        toast.info(response.data[0].message);
        dispatch({
          type: LOADING_STOPS,
        });
      }
    } catch (e) {
      if (e) {
        toast.info('Something went wrong');
        dispatch({
          type: LOADING_STOPS,
        });
      }
    }
  };

  const searchWithDateAndTimeRange = async (data) => {
    dispatch({
      type: LOADING_STARTS,
    });
    const { fromdate, fromtime, todate, totime, action } = data;
    const post = {
      fromdate,
      fromtime,
      todate: moment(todate)
        .add(1, 'day')
        .format('YYYY-MM-DD'),
      totime,
      action,
    };

    try {
      const response = await axios.post(`${SMS_URL}`, qs.stringify(post));
      if (response.data[0].status === true) {
        dispatch({
          type: FETCH_RETAILER_SMS_LOGS,
          payload: response.data.splice(1, response.data.length),
        });
      } else {
        toast.info(response.data[0].message);
        dispatch({
          type: LOADING_STOPS,
        });
      }
    } catch (e) {
      if (e) {
        toast.info('Something went wrong');
        dispatch({
          type: LOADING_STOPS,
        });
      }
    }
  };

  useEffect(() => {
    getAllRetailerSMSLogs();
  }, []);

  return {
    loading,
    sms,
    onChange,
    text,
    searchWithPhone,
    dateTime,
    onDateTimeChange,
    searchWithDateAndTimeRange,
  };
};
export default useRetailer;
