/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import '../../../../styles/style.css';
import '../../../../styles/sales_overview.css';
import axios from 'axios';
import toastr from 'toastr';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import Loader from '../../../../assets/img/icons/TizetiLoaderColored.gif';
import profile from '../../../../assets/img/icons/Profile.png';
import Leads from '../../../../assets/img/icons/leads.png';
import { getLeads } from '../../../../actions/shared/leads/LeadsActions';
import ViewUserProfile from '../../shared/staff/ViewUserProfile';
import { LOGGED_IN_USER_URL } from '../../../../services/api/auth/AuthUrl';
import SalesNavBar from '../../../layouts/sales/SalesNavBar';
import { SALES, SUPER_ADMIN, VICE_PRESIDENT_SALES } from '../../../../utils/departments/helpers';

class SalesOverview extends Component {
  // static propTypes = {
  //   getLeads: PropTypes.func.isRequired,
  // };

  componentDidMount() {
    this.props.getLeads();
    this.checkDepartment();
  }

  componentWillReceiveProps() {
    this.checkDepartment();
  }

  checkDepartment = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.department_id !== `${SALES}` && response.data.department_id !== `${SUPER_ADMIN}`  && response.data.department_id !== `${VICE_PRESIDENT_SALES}`) {
        // eslint-disable-next-line react/prop-types
        this.props.history.push('/');

        sessionStorage.setItem('token', '');
        sessionStorage.clear();

        toastr.info('You are not allowed to view this page');
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  render() {
    const { load } = this.props;
    const { count } = this.props.leads;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <SalesNavBar currentPage="Overview" profileImage={profile} />
          </div>

          <div className="col-12 after-summary">
            <ViewUserProfile />
            <div className="row">
              <div className="col-lg-12 p-0">
                <div className="col-lg-12 p-3">
                  <div className="col-lg-12 d-flex p-0">
                    <h6 className="col-lg-8 mr-auto">Sales Summary</h6>
                    <p className="ops-opt copy-font d-none d-md-flex ">
                      <small className="mr-2"> Filter By:</small>
                      <span>
                        <select className="form-control">
                          <option>Month</option>
                        </select>
                      </span>

                      <span>
                        <select className="form-control">
                          <option>Year</option>
                        </select>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 side d-flex">
                <div className="col-lg-2">
                  {/* <div className="col-lg-12">
                                        <div className="boxes" id="boxx">
                                            <div className="icon text-center">
                                                <img src="https://res.cloudinary.com/teewhy/image/upload/v1543828916/sales_calls.png"/>
                                            </div>
                                            <div className="count">35000 </div>
                                            <h3 style={{ color:'#f37705' }}>Sales Calls</h3>
                                        </div>
                                    </div> */}

                  <div className="col-lg-12">
                    <div className="boxes">
                      <div className="icon text-center">
                        <img src={Leads} alt="homepage" />
                      </div>
                      {load ? <img src={Loader} alt="loading gif" /> : <div className="count"> {count} </div>}
                      <h3 style={{ color: '#f1e781' }}>Leads</h3>
                    </div>
                  </div>

                  {/* <div className="col-lg-12">
                                        <div className="boxes">
                                            <div className="icon text-center">
                                                <img src="https://res.cloudinary.com/teewhy/image/upload/v1543566741/total_sales.png" style={{width:'20%'}}/>
                                            </div>
                                            <div className="count">20000 </div>
                                            <h3 style={{ color:'#5cc14b' }}>Total Sales</h3>
                                        </div>
                                    </div> */}
                </div>

                <div className="col-lg-10">
                  <div className="card-group mt-1">
                    <div className="card o-income">
                      <div className="card-body">
                        <div className="d-flex m-b-30 no-block ml-2">
                          <h4 className="card-title m-b-0 align-self-center">Total Sales</h4>
                        </div>

                        <div className="d-flex col-lg-12 graph">
                          <div className="col-lg-6">
                            <p className="copy-font">Sales for June, 2018</p>
                          </div>
                          <div className="colors d-flex col-lg-7">
                            <p className="color" />
                            <p className="copy-font">Unlimited Internet</p>
                            <p
                              className="color-dif"
                              style={{
                                backgroundColor: '#14B0B0',
                              }}
                            />
                            <p className="copy-font"> Dedicated Internet</p>
                          </div>
                        </div>

                        <div id="sales" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  leads: state.leads.leads,
  load: state.leads.load,
});

export default connect(
  mapStateToProps,
  { getLeads },
)(SalesOverview);
