/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useContext } from 'react';
import CustomButton from '../../../../shared/forms/CustomButton';
import CustomInput from '../../../../shared/forms/CustomInput';
import SectorAllocationContext from './context/SectorAllocationContext';
import Label from '../../../../shared/forms/Label';

const AddSectorAllocation = () => {
  const { loadingForm, onChange, formValue, onSubmit } = useContext(SectorAllocationContext);
  const { ap_ssid, ip_address, new_ip_address } = formValue;
  const submitForm = () => {
    return ap_ssid.length > 0 && ip_address.length > 0 && new_ip_address.length > 0;
  };
  return (
    <div id="add-access-point" className="modal fade mt-5" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header shadow">
            <h4 className="modal-title update_asset">Sector Allocation</h4>
            <button type="button" className="btn btn-outline-info btn-circle btn-close" data-dismiss="modal">
              x
            </button>
          </div>
          <div className="modal-body detail mb-4 p-0">
            <form
              className="col-md-12 p-0"
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              <div className="d-flex">
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="Tizeti Site Id" htmlfor="customerName" />
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="ap_ssid"
                    value={ap_ssid}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="ap_ssid"
                    type="text"
                    id="customerName"
                  />
                </div>
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="IP Address" htmlfor="customerEmail" />
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="ip_address"
                    value={ip_address}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="ip_address"
                    type="text"
                    id="customerEmail"
                  />
                </div>
              </div>

              <div className="d-flex">
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="New IP Address" htmlfor="router_id" />
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="new_ip_address"
                    value={new_ip_address}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="new ip address"
                    type="text"
                    id="router_id"
                  />
                </div>
              </div>

              <div className="text-center mt-3">
                {loadingForm ? (
                  <CustomButton classname="btn btn-green px-3" name="Adding" disabled={loadingForm} />
                ) : (
                  <CustomButton classname="btn btn-green px-3" name="Add" disabled={!submitForm()} />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSectorAllocation;
