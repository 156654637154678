import React from 'react';

const ExecutiveRequisitionTableHead = () => {
  return (
    <thead>
      <tr>
        <th>#</th>
        <th>Amount</th>
        <th>Amount In Words</th>
        <th>Account Approval Status </th>
        <th>Manager Approval Status </th>
        <th>C LEVEL Approval Status </th>
        <th>CEO/COO Approval Status </th>
        <th>In Favor Of</th>
        <th>Department</th>
        <th>Requires C Level Approval?</th>
        <th>Requires CEO/COO Approval?</th>
        <th>Approve</th>
        <th>Reject</th>
        <th>Created At</th>
        <th>Purpose</th>
        <th>View Image</th>
        <th>Details</th>
      </tr>
    </thead>
  );
};

export default ExecutiveRequisitionTableHead;
