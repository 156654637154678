/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import caution from '../../../../../../assets/img/icons/Caution.png';
import { showDepartmentName } from '../../../../../../utils/departments/helpers';
import { usePurchaseOrder } from '../../../../../../hooks/shared/purchase-order/usePurchaseOrder';

const ConfirmPurchaseOrder = ({ order: { item, quantity, department_id, id } = {} }) => {
  const {
    procurementPODecision,
    handlePODecision,
    handleInvoice,
    invoice,
    PODecision: { approvalStatus, approvalComment, amount, currency },
  } = usePurchaseOrder();

  const confirmOrder = (e) => {
    e.preventDefault();
    const data = new FormData();
    for (let x = 0; x < invoice.length; x++) {
      data.append('file', invoice[x]);
    }
    data.append('amount', amount);
    data.append('currency', currency);
    data.append('procurement_approval', approvalStatus);
    data.append('procurement_comment', approvalComment);
    procurementPODecision(id, data);
  };

  const validateForm = () => amount.length > 0 && invoice.length > 0;

  return (
    <div
      className="modal fade"
      id="confirmPO"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content mt-5 py-4">
          <div className="text-center">
            <img src={caution} alt="" width="60px" />
          </div>
          <div className="modal-body p-3">
            <div className="row">
              <div className="col">
                <form className="form-horizontal text-center">
                  <div className="col">
                    <p>
                      <h5>
                        Confirm order of <strong>{`${quantity} ${item}`}</strong> requested by
                        <br />
                        <strong>{showDepartmentName.get(department_id)}</strong>?
                      </h5>
                    </p>
                  </div>
                  <div className="justify-content-center">
                    <div className="" style={{ padding: '0px  170px' }}>
                      <select
                        className="form-control border border-secondary borderRadius0"
                        name="approvalStatus"
                        defaultValue={approvalStatus}
                        onChange={handlePODecision}
                      >
                        <option value="approved">Approve</option>
                        <option value="rejected">Reject</option>
                      </select>
                    </div>
                    <div className="my-2 input-group" style={{ padding: '0px  120px' }}>
                      <div className="input-group-prepend border border-secondary borderRadius0">
                        <select
                          name="currency"
                          defaultValue={currency}
                          className="px-3 copy-font"
                          onChange={handlePODecision}
                        >
                          <option value="NGN">NGN</option>
                          <option value="USD">USD</option>
                          <option value="GHC">CEDI</option>
                          <option value="CFA">CFA</option>
                        </select>
                      </div>
                      <input
                        type="number"
                        className="form-control border border-secondary borderRadius0"
                        placeholder="Amount"
                        name="amount"
                        value={amount}
                        onChange={handlePODecision}
                      />
                    </div>
                    <div className="my-2" style={{ padding: '0px  70px' }}>
                      <input
                        type="file"
                        required
                        multiple
                        name="invoice"
                        className="form-control border border-secondary shadow-sm borderRadius0"
                        accept="image/png, image/jpeg, image/jpg, application/pdf"
                        onChange={handleInvoice}
                      />
                    </div>
                    <div className="my-2 px-5">
                      <textarea
                        placeholder="Comment"
                        className="form-control border border-secondary shadow-sm borderRadius0"
                        name="approvalComment"
                        value={approvalComment}
                        onChange={handlePODecision}
                      />
                    </div>
                  </div>
                  <div className="mt-5">
                    <button type="button" className="btn btn-secondary mr-2" data-dismiss="modal">
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-green" onClick={confirmOrder} disabled={!validateForm()}>
                      Confirm
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ConfirmPurchaseOrder.propTypes = {
  order: PropTypes.objectOf.isRequired,
};

export default ConfirmPurchaseOrder;
