/* eslint-disable @typescript-eslint/camelcase */
import React, { useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import Label from '../../../shared/forms/Label';
import CustomInput from '../../../shared/forms/CustomInput';
import CustomButton from '../../../shared/forms/CustomButton';
import { GET_ZONES_URL } from '../../../../../services/api/shared/installations/ZonesUrl';
import useFetch from '../../../../../hooks/shared/custom/data/useFetch';
import { ZonesContext } from '../../../shared/installations/zones/provider/ZonesContext';

const tabIndex = -1;

const CreateSubZone = () => {
  const { loadingForm, onSubZoneChange, subZone, onSubmitSubZone } = useContext(ZonesContext);

  const { data } = useFetch(`${GET_ZONES_URL}`);
  const { name, zone_id } = subZone;
  const submitForm = () => {
    return name.length > 0 && zone_id.length > 0;
  };
  const submit = (e) => {
    e.preventDefault();
    onSubmitSubZone();
  };
  return (
    <div
      id="create-sub-zone"
      className="modal fade in"
      tabIndex={tabIndex}
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Add Sub Zone
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body">
            <form className="form-horizontal" onSubmit={submit}>
              <div className="form-group">
                <div className="col-md-12 mb-1 d-flex">
                  <div className="col-lg-12">
                    <Label classname="col-lg-12 text-justify copy-font m-0 p-0" name="Sub Zone" />
                    <CustomInput
                      value={name}
                      name="name"
                      onChange={onSubZoneChange}
                      type="text"
                      classname="form-control"
                      placeholder="Enter sub zone name"
                      readOnly={false}
                    />
                  </div>
                </div>
                <div className="col-md-12 mb-1 d-flex">
                  <div className="col-lg-12">
                    <Label classname="col-lg-12 text-justify copy-font m-0 p-0" name="Zone" />
                    <select className="form-control col-md-12" name="zone_id" onChange={onSubZoneChange}>
                      <option>-- Select Zone --</option>
                      {data && data.length ? data.map((zon) => <option value={zon.id}>{zon.name}</option>) : null}
                    </select>
                  </div>
                </div>
              </div>
              <div className="text-center">
                {loadingForm ? (
                  <CustomButton
                    disabled={loadingForm}
                    name="Adding Sub-Zone..."
                    classname="btn btn-green waves-effect"
                  />
                ) : (
                  <CustomButton disabled={!submitForm()} name="Add Sub-Zone" classname="btn btn-green waves-effect" />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CreateSubZone;
