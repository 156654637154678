/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable class-methods-use-this */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import '../../../../../styles/style.css';
import '../../../../../styles/inventory.css';
import axios from 'axios';
import toastr from 'toastr';
import { API_URL, PROCUREMENT } from '../../../../../utils/config';
import ProcurementNavBar from '../../../../layouts/procurement/ProcurementNavBar';
import Inventories from '../../../shared/inventories/Inventories';
import { SUPER_ADMIN } from '../../../../../utils/departments/helpers';

class ProcurementInventories extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      filtered: [],
    };
  }

  componentDidMount() {
    this.fetchLoggedOnUser();
    this.getUserDetails();
  }

  componentWillReceiveProps() {
    this.fetchLoggedOnUser();
  }

  getUserDetails() {
    axios({
      method: 'get',
      url: `${API_URL}/employee/loggedOnEmployee`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        this.setState({
          user: data.data,
        });
      })
      .catch(() => {});
  }

  fetchLoggedOnUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employeeReg/logged`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        if (data.data.department_id !== `${PROCUREMENT}` && data.data.department_id !== `${SUPER_ADMIN}`) {
          sessionStorage.setItem('data', '');
          sessionStorage.clear();

          toastr.info('You are not allowed to view this page');
        }
      })
      .catch(() => {});
  }

  render() {
    const { user } = this.state;
    const { firstName } = user;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <ProcurementNavBar currentPage="Inventory" position={firstName} />
          <Inventories />
        </main>
      </div>
    );
  }
}

export default ProcurementInventories;
