/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import { useClassification } from '../../../../hooks/procurement/classification/useClassification';
import useTracklist from './hooks/useTracklist';

const TracklistUpdateItem = ({ OneItem }) => {
  const { updateTracklist, isLoading } = useTracklist();
  const { classification } = useClassification();
  const {
    invoice_no,
    vendor,
    item,
    invoice_submission_status,
    available_quantity,
    cost,
    quantity,
    date,
    id,
    resource_classification_id,
    currency,
  } = OneItem;

  const [one, setOne] = useState({});

  const {
    itemName,
    seller,
    dateReceived,
    price,
    total,
    remainder,
    invoiceNumber,
    invoiceStatus,
    classificationID,
  } = one;

  useEffect(() => {
    const result = {
      dateReceived: date,
      itemName: item,
      seller: vendor,
      price: cost,
      remainder: available_quantity,
      total: quantity,
      invoiceNumber: invoice_no,
      invoiceStatus: invoice_submission_status,
      classificationID: resource_classification_id,
    };
    setOne({ ...result });
  }, [OneItem]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOne({ ...one, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (one.invoiceStatus.length === 0) return toastr.info('Enter device status');
    const data = {
      invoiceStatus: one.invoiceStatus,
      remainder: one.remainder,
    };
    updateTracklist(data, id);
  };

  return (
    <div id="update_item" className="modal fade" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header shadow">
            <h4 className="modal-title update_asset">Update Item</h4>
            <button type="button" className="btn btn-outline-info btn-circle btn-close" data-dismiss="modal">
              x
            </button>
          </div>
          <div className="modal-body detail mb-4 p-0">
            <form onSubmit={onSubmit} className="col-md-12">
              <div className="d-flex">
                <div className="p-2 pr-3 form-group col-md-6">
                  <label className="m-0 p-0 copy-font" htmlFor="item">
                    Item Name
                  </label>
                  <input
                    type="text"
                    className="form-control border border-info py-2"
                    id="item"
                    name="itemName"
                    disabled
                    value={itemName}
                    onChange={handleChange}
                  />
                </div>
                <div className="p-2 pl-3 form-group col-md-6">
                  <label className="m-0 p-0 copy-font" htmlFor="company">
                    Company / Vendor
                  </label>
                  <input
                    type="text"
                    className="form-control border border-info py-2"
                    id="company"
                    name="seller"
                    disabled
                    value={seller}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="d-flex">
                <div className="p-2 pr-3 form-group col-md-6">
                  <label className="m-0 p-0 copy-font" htmlFor="class">
                    Class
                  </label>
                  <select
                    className="form-control border border-info py-2"
                    id="class"
                    disabled
                    name="classificationID"
                    value={classificationID}
                    style={{ borderRadius: '0' }}
                    onChange={handleChange}
                  >
                    <option>--Class--</option>
                    {classification.map((category) => (
                      <option value={category.id}>{category.name}</option>
                    ))}
                  </select>
                </div>

                <div className="p-2 pl-3 form-group col-md-6">
                  <label className="m-0 p-0 copy-font" htmlFor="status">
                    Device Status
                  </label>
                  <select
                    className="form-control border border-info py-2"
                    style={{ borderRadius: '0' }}
                    id="status"
                    required
                    name="invoiceStatus"
                    value={invoiceStatus}
                    onChange={handleChange}
                  >
                    <option value="">--Device Status--</option>
                    <option value="new">New</option>
                    <option value="retrieved">Retrieved</option>
                    <option value="not-available">Not available</option>
                    <option value="bad">Bad</option>
                  </select>
                </div>
              </div>

              <div className="d-flex">
                <div className="p-2 pr-3 form-group col-md-6">
                  <label className="m-0 p-0 copy-font" htmlFor="item_cost">
                    Cost of Item
                  </label>
                  <div className="input-group">
                    <div className="input-group-prepend border border-info">
                      <select name="currency" value={currency} disabled>
                        <option value="NGN">NGN</option>
                        <option value="USD">USD</option>
                        <option value="GHV">GHC</option>
                      </select>
                    </div>
                    <input
                      type="number"
                      className="form-control border border-info py-2"
                      id="item_cost"
                      value={price}
                      name="price"
                      disabled
                    />
                  </div>
                </div>

                <div className="p-2 pl-3 form-group col-md-6">
                  <label className="m-0 p-0 copy-font" htmlFor="invoice">
                    Invoice No.
                  </label>
                  <input
                    type="text"
                    className="form-control border border-info py-2"
                    id="invoice"
                    disabled
                    name="invoiceNumber"
                    value={invoiceNumber}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="d-flex">
                <div className="p-2 pr-3 form-group col-md-6">
                  <label className="m-0 p-0 copy-font" htmlFor="quantity">
                    Quantity
                  </label>
                  <input
                    type="number"
                    className="form-control border border-info py-2"
                    id="quantity"
                    value={total}
                    name="total"
                    disabled
                  />
                </div>

                <div className="p-2 pl-3 form-group col-md-6">
                  <label className="m-0 p-0 copy-font" htmlFor="receipt">
                    Date of Receipt
                  </label>
                  <input
                    type="date"
                    className="form-control border border-info py-2"
                    id="receipt"
                    value={dateReceived}
                    name="dateReceived"
                    onChange={handleChange}
                    disabled
                  />
                </div>
              </div>

              <div className="p-2 pr-3 form-group col-md-6">
                <label className="m-0 p-0 copy-font" htmlFor="remainder">
                 Remainder
                </label>
                <input
                  type="number"
                  className="form-control border border-info py-2"
                  id="remainder"
                  required
                  value={remainder}
                  onChange={handleChange}
                  name="remainder"
                />
              </div>

              {/* <div className="d-flex">
                <div className="p-2 pr-3 form-group col-md-6">
                  <label className="m-0 p-0 copy-font" htmlFor="deliveryStatus">
                    Delivery Status
                  </label>
                  <select
                    className="form-control border border-info py-2"
                    id="class"
                    name="deliveryStatus"
                    value={deliveryStatus}
                    style={{ borderRadius: '0' }}
                    onChange={handleChange}
                  >
                    <option>--Delivery Status--</option>
                    <option value="in-transit">In Transit</option>
                    <option value="delivered">Delivered</option>
                  </select>
                </div>
                <div className="p-2 pl-3 form-group col-md-6">
                  <label className="m-0 p-0 copy-font" htmlFor="paymentStatus">
                    Payment Status
                  </label>
                  <select
                    className="form-control border border-info py-2 borderRadius0"
                    id="paymentStatus"
                    name="paymentStatus"
                    value={paymentStatus}
                    onChange={handleChange}
                  >
                    <option>--Payment Status--</option>
                    <option value="Paid">Paid</option>
                    <option value="Unpaid">Unpaid</option>
                  </select>
                </div>
              </div> */}

              <div className="text-center mt-3">
                {isLoading ? (
                  <button type="button" className="btn btn-green py-2 px-5" disabled>
                    Updating...
                  </button>
                ) : (
                  <button type="submit" className="btn btn-green py-2 px-5">
                    Update
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

TracklistUpdateItem.propTypes = {
  OneItem: PropTypes.objectOf.isRequired,
};

export default TracklistUpdateItem;
