/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

const RetentionScript = () => {
  return (
    <div id="slideout" className="d-none d-md-block">
      <img
        src="https://res.cloudinary.com/teewhy/image/upload/v1554905690/Retention_button.png"
        alt="Feedback"
        style={{ width: '15%' }}
      />
      <div id="slideout_inner">
        <div className="retention text-center">
          <h5 className="p-2">RETENTION CALL SCRIPT</h5>
        </div>
        <p className="copy-font p-2 text-justify">
          Good Morning/Afternoon/Evening
          <br />I am calling from Tizeti Network LTD
        </p>

        <p className="copy-font p-2 text-justify">
          <b>My name is</b> Firstname & Lastname
        </p>

        <p className="copy-font p-2 text-justify">
          Am I speaking with customer x
          <br />
          Will this be a good time to talk?
        </p>

        <div className="side-flow">
          <h6 className="mt-1">Scenario 1:</h6>
        </div>
        <p className="copy-font p-2 text-justify">
          Customer says: No
          <br />
          Agent:​Ok Sir/Ma when can I call you back
          <br />
          Customer: Later at 5pm
          <br />
          ………………………..Agent writes down the time and calls customer at that time
        </p>

        <div className="side-flow">
          <h6>Scenario 2:</h6>
        </div>

        <p className="copy-font p-2 text-justify">
          Yes: We noticed that your account has expired and you are yet to renew.
          <br />
          Customer provides reasons why
          <br />
          Agent listens to reasons and repeats reasons to ensure agents understand what the issues are.
          <br />
          <br />
          Agent apologizes and ensure issues are resolved as agent runs through 1st Level troubleshoot
        </p>

        <div>
          <img
            src="https://res.cloudinary.com/teewhy/image/upload/v1554913767/steps_retention.png"
            className="w-100 h-100"
          />
        </div>

        <div className="footer-sec">
          <p className="copy-font text-white text-justify p-2">
            Or customer is scheduled for a tech visit.
            <br />
            Agent: Kindly note that you can raise a support issue by sending us an email to internet@wifi.com.ng or call
            us on
          </p>

          <p className="copy-font text-white p-2">0908 947 3040 or 0908 947 3050</p>

          <p className="copy-font text-white text-justify p-2">
            You can make payment
            <br />
            <br />
            1. Transfer to our Zenith Bank Account 1013184381
            <br />
            <br />
            2. Pay via pay.tizeti.com
            <br />
            <br />
            3. Pay via tizeti.com/customer
            <br />
            <br />
            Do enjoy the rest of your day
            <br />
            <br />
            Wait for the customer to drop the phone
          </p>
        </div>
      </div>
    </div>
  );
};

export default RetentionScript;
