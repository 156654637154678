/* eslint-disable react/prop-types */
import React from 'react';
import useCheckDepartment from '../../../../../hooks/shared/custom/check-department/field-support/useCheckDepartment';
import { useUser } from '../../../../../hooks/shared/user/useUser'; 
import InstallationsCounterSummary from '../../../shared/installations-counter-summary/InstallationCounterSummary';
import AccountSideBar from '../../../../layouts/accounts/sidebar/AccountSideBar';
import AccountsNavBar from '../../../../layouts/accounts/AccountsNavBar';


const AccountsInstallationsCounter = ({ history }) => {
//   useCheckDepartment(history);
  const {
    profile: { firstName },
  } = useUser();

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <AccountsNavBar currentPage="Installations Summary" position={firstName} />
        <div className="container">
          <InstallationsCounterSummary />
        </div>
      </main>
    </div>
  );
};

export default AccountsInstallationsCounter;
