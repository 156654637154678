import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
  GET_CONFIRMED_EMPLOYEES_URL,
  GET_UNCONFIRMED_EMPLOYEES_URL,
} from '../../../../services/api/shared/employees/EmployeesUrl';
import axios from 'axios';
import { GET_DEPARTMENTS_URL } from '../../../../services/api/shared/departments/DepartmentsActions';
import View from '../../../../assets/img/icons/view.png';
import TextField from '@mui/material/TextField';
import { getLeaveDays, setLeaveDays as SetLeaveDays, editLeaveDays } from '../../../../actions/employees/leaveDays';
import TizetiLoader from '../../../../assets/img/icons/TizetiLoaderColored.gif';
import { toast } from 'react-toastify';

function createData(name, email, department, actions, employeeAuthId) {
  return { name, email, department, actions, employeeAuthId };
}

export default function LeaveTable() {
  const [confirmedEmployees, setConfirmedEmployees] = React.useState([]);
  const [unconfirmedEmployees, setUnconfirmedEmployees] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [departments, setDepartments] = React.useState([]);
  const [search, setSearch] = React.useState('');
  const [departmentsLoading, setDepartmentsLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [employee, setEmployee] = React.useState({});
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getConfirmedAndUnconfirmedEmployees = async (employeeType) => {
    try {
      setIsLoading(true);
      const response = await axios.get(employeeType, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data) {
        return response.data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const getDepartmentsAction = async () => {
    setDepartmentsLoading(true);
    try {
      const response = await axios.get(`${GET_DEPARTMENTS_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.data) {
        return response.data.data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDepartmentsLoading(false);
    }
  };

  React.useEffect(() => {
    const fetchEmployees = async () => {
      const confirmedEmployees = await getConfirmedAndUnconfirmedEmployees(GET_CONFIRMED_EMPLOYEES_URL);
      // console.log('🚀 ~ fetchEmployees ~ confirmedEmployees:', confirmedEmployees);

      if (search.length > 0) {
        const filteredEmployees = confirmedEmployees.filter((employee) => {
          return (
            employee.firstName.toLowerCase().includes(search.toLowerCase()) ||
            employee.lastName.toLowerCase().includes(search.toLowerCase()) ||
            employee.officialEmail.toLowerCase().includes(search.toLowerCase())
          );
        });
        setConfirmedEmployees(filteredEmployees);
      } else {
        setConfirmedEmployees(confirmedEmployees);
      }
      const unconfirmedEmployees = await getConfirmedAndUnconfirmedEmployees(GET_UNCONFIRMED_EMPLOYEES_URL);
      setUnconfirmedEmployees(unconfirmedEmployees);
    };
    const getDepartments = async () => {
      const departments = await getDepartmentsAction();
      // console.log('🚀 ~ getDepartments ~ departments:', departments);
      setDepartments(departments);
    };

    fetchEmployees();
    getDepartments();
  }, [search]);

  const filteredEmails = ['coo@tizeti.com'];

  const filteredConfirmedUsers = confirmedEmployees.filter((obj) => !filteredEmails.includes(obj.email));

  const findDeparment = (departmentId) => {
    if (departments.length) {
      return departments.find((department) => department.id === departmentId).name;
    }
  };
  // const convertDate = (date) => {
  //   if (date === null) return 'N/A';
  //   const dateObj = new Date(date);
  //   return dateObj.toDateString();
  // };

  const rows = filteredConfirmedUsers.map((employee) => {
    return createData(
      employee.firstName + ' ' + employee.lastName,
      employee.officialEmail,
      findDeparment(employee.department_id),
      // convertDate(employee.date_of_resumption),
      <button
        type="submit"
        data-toggle="modal"
        data-placement="right"
        data-target="#leave-details"
        data-id={employee.id}
        onClick={() => {
          setOpen(true);
          setEmployee(employee);
        }}
        style={{
          border: '0',
          backgroundColor: 'white',
        }}
      >
        <span className="view">
          <img src={View} data-toggle="tooltip" data-placement="left" title="View" alt="view" />
        </span>
      </button>,
      employee.employee_auth_id,
    );
  });

  return (
    <TableContainer component={Paper} sx={{ width: '80vw', ml: 2 }}>
      <Box>
        <TextField
          id="filled-search"
          label="Search field"
          // type="search"
          variant="filled"
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </Box>
      {isLoading ? (
        <tr>
          <figure>
            <img src={TizetiLoader} alt="loader" />
          </figure>
        </tr>
      ) : (
        <Table sx={{ minWidth: 650, mt: 4 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>S/N </TableCell>
              <TableCell>Name </TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">Department</TableCell>
              {/* <TableCell align="right">Date Of Resumption</TableCell> */}
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow key={row.employeeAuthId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {i + 1}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.email}</TableCell>
                <TableCell align="right">{row.department}</TableCell>
                {/* <TableCell align="right">{row.dateOfResumption}</TableCell> */}
                <TableCell align="right">{row.actions}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      <BasicModal open={open} handleClose={handleClose} employee={employee} />
    </TableContainer>
  );
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
function BasicModal({ open, handleClose, employee }) {
  const [leaveDays, setLeaveDays] = React.useState([]);
  const [leaveDaysValue, setLeaveDaysValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  // console.log('🚀 ~ BasicModal ~ leaveDays:', leaveDays);

  const getLeaveDaysAction = async (employeeId) => {
    try {
      const result = await getLeaveDays(employeeId);
      if (result) {
        setLeaveDays(result);
        if (result.count > 0) {
          setLeaveDaysValue(result.rows[0].days);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    if (employee.employee_auth_id !== undefined) {
      getLeaveDaysAction(employee.employee_auth_id);
    } else toast.error('An error occurred');
  }, [employee, open]);

  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          handleClose();
          setLeaveDaysValue(0);
          setLeaveDays([]);
        }}
        aria-labelledby="employee-modal-title"
        aria-describedby="employee-modal-description"
      >
        <Box sx={style}>
          <Typography id="employee-modal-title" variant="h6" component="h2">
            {employee.firstName + ' ' + employee.lastName}
          </Typography>
          <Typography id="employee-modal-title" component="div">
            {leaveDays.count === undefined
              ? 'Loading...'
              : leaveDays.count === 0
              ? 'No leave days'
              : leaveDays.rows[0].days + ' leave days'}
          </Typography>
          <Box id="employee-modal-description" sx={{ mt: 2 }}>
            <TextField
              fullWidth
              id="outlined-number"
              label="Number"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setLeaveDaysValue(e.target.value)}
              value={leaveDaysValue}
            />
          </Box>
          <Box sx={{ float: 'right', mt: 6 }}>
            <Button
              variant="contained"
              onClick={async () => {
                setLoading(true);
                if (leaveDays.count === 0 || leaveDays.count === undefined) {
                  SetLeaveDays(employee.employee_auth_id, leaveDaysValue, (result, error) => {
                    if (error) {
                      console.error('Error setting leave days:', error);
                    } else {
                      setLoading(false);
                      getLeaveDaysAction(employee.employee_auth_id);
                      setTimeout(() => {
                        setLoading(false);
                        handleClose();
                      }, 2000);
                    }
                  });
                } else {
                  await editLeaveDays(leaveDays.rows[0].id, leaveDaysValue, (result, error) => {
                    // console.log('🚀 ~ awaiteditLeaveDays ~ error:', error);
                    // console.log('🚀 ~ awaiteditLeaveDays ~ result:', result);
                    if (error) {
                      console.error('Error updating leave days:', error);
                    } else {
                      toast.success('Leave days updated successfully');
                      getLeaveDaysAction(employee.employee_auth_id);
                      setTimeout(() => {
                        setLoading(false);
                        handleClose();
                      }, 2000);
                    }
                  });
                }
              }}
              disabled={leaveDays.count === undefined || loading ? true : false}
            >
              {leaveDays.count === undefined && loading
                ? 'Loading...'
                : leaveDays.count === 0
                ? 'Set leave days'
                : 'Edit leave days'}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
