/* eslint-disable import/prefer-default-export */
export const deviceRetrievalStatus = [
  {
    name: 'uninstall',
    value: 'uninstall',
  },
  {
    name: 'visit',
    value: 'visit',
  },
  // {
  //   name: 'retrieve',
  //   value: 'retrieve',
  // },
  {
    name: 'unreachable',
    value: 'unreachable',
  },
  // {
  //   name: 'reachable',
  //   value: 'reachable',
  // },
];
