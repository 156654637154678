import React from 'react';
import useSheetDedicatedCustomer from '../hooks/useSheetDedicatedCustomer';
import SheetDedicatedCustomerContext from './SheetDedicatedCustomerContext';

const SheetDedicatedCustomerProvider = ({ children }) => {
  const {
    data,
    loading,
    paginateChange,
    loadingForm,
    onChange,
    formValue,
    onSubmit,
    pages,
  } = useSheetDedicatedCustomer();
  return (
    <SheetDedicatedCustomerContext.Provider
      value={{
        data,
        loading,
        paginateChange,
        loadingForm,
        onChange,
        formValue,
        onSubmit,
        pages,
      }}
    >
      {children}
    </SheetDedicatedCustomerContext.Provider>
  );
};
export default SheetDedicatedCustomerProvider;
