/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

const CustomerDetailsSharedModal = ({
  request: { user_kyc, contact_information_image, pole_image, sla, img_4, user },
}) => {
  const kyc = user.installations;
  // console.log(contact_information_image, 'KYC');

  return (
    <div id="viewcs" className="modal fade" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header shadow">
            <h4 className="modal-title text-center" id="myModalLabel">
              File Viewer
            </h4>
            <button type="button" className="close mr-2" data-dismiss="modal">
              x
            </button>
          </div>
          <div className="modal-body">
            <div className="col-12 mb-3 d-flex">
              {contact_information_image === null ? (
                'No Record'
              ) : (
                <div>
                  {contact_information_image.split('.').pop() === 'pdf' ? (
                    <a href={contact_information_image}>
                      {' '}
                      <i className="fa fa-download" aria-hidden="true">
                        {' '}
                        {''} Download Pdf
                      </i>{' '}
                    </a>
                  ) : (
                    <img src={contact_information_image} className="img-fluid" alt="test" />
                  )}
                </div>
              )}
            </div>

            <div className="col-12 mb-3 d-flex">
              {user_kyc === null ? (
                'No Record'
              ) : (
                <div>
                  {user_kyc.split('.').pop() === 'pdf' ? (
                    <a href={user_kyc}>
                      {' '}
                      <i className="fa fa-download" aria-hidden="true">
                        {' '}
                        {''} Download Pdf
                      </i>{' '}
                    </a>
                  ) : (
                    <img src={user_kyc} className="img-fluid" alt="test" />
                  )}
                </div>
              )}
            </div>

            <div className="col-12 mb-3 d-flex">
              {pole_image === null ? (
                'No Record'
              ) : (
                <div>
                  {pole_image.split('.').pop() === 'pdf' ? (
                    <a href={pole_image}>
                      {' '}
                      <i className="fa fa-download" aria-hidden="true">
                        {' '}
                        {''} Download Pdf
                      </i>{' '}
                    </a>
                  ) : (
                    <img src={pole_image} className="img-fluid" alt="test" />
                  )}
                </div>
              )}
            </div>
            <div className="col-12 mb-3 d-flex">
              {img_4 === null ? (
                'No Record'
              ) : (
                <div>
                  {img_4.split('.').pop() === 'pdf' ? (
                    <a href={img_4}>
                      {' '}
                      <i className="fa fa-download" aria-hidden="true">
                        {' '}
                        {''} Download Pdf
                      </i>{' '}
                    </a>
                  ) : (
                    <img src={img_4} className="img-fluid" alt="test" />
                  )}
                </div>
              )}
            </div>
            <div className="col-12 mb-3 d-flex">
              {sla === null ? (
                'No Record'
              ) : (
                <div>
                  {sla.split('.').pop() === 'pdf' ? (
                    <a href={sla}>
                      {' '}
                      <i className="fa fa-download" aria-hidden="true">
                        {' '}
                        {''} Download Pdf
                      </i>{' '}
                    </a>
                  ) : (
                    <img src={sla} className="img-fluid" alt="test" />
                  )}
                </div>
              )}
            </div>

            {kyc.map((data, index) => (
              <div key={index}>
                {data.installationUploads.map((kycInfo, index) => (
                  <div className="modal-body" key={index}>
                    <div className="col-12 mb-3 d-flex">
                      {kycInfo.user_kyc === null ? (
                        'No Record'
                      ) : (
                        <div>
                          {kycInfo.user_kyc.split('.').pop() === 'pdf' ? (
                            <a href={kycInfo.user_kyc}>
                              {' '}
                              <i className="fa fa-download" aria-hidden="true">
                                {' '}
                                {''} Download Pdf
                              </i>{' '}
                            </a>
                          ) : (
                            <img src={kycInfo.user_kyc} className="img-fluid" alt="test" />
                          )}
                        </div>
                      )}
                    </div>

                    <div className="col-12 mb-3 d-flex">
                      {kycInfo.contact_information_image === null ? (
                        'No Record'
                      ) : (
                        <div>
                          {kycInfo.contact_information_image.split('.').pop() === 'pdf' ? (
                            <a href={kycInfo.contact_information_image}>
                              {' '}
                              <i className="fa fa-download" aria-hidden="true">
                                {' '}
                                {''} Download Pdf
                              </i>{' '}
                            </a>
                          ) : (
                            <img src={kycInfo.contact_information_image} className="img-fluid" alt="test" />
                          )}
                        </div>
                      )}
                    </div>

                    <div className="col-12 mb-3 d-flex">
                      {kycInfo.img_4 === null ? (
                        'No Record'
                      ) : (
                        <div>
                          {kycInfo.img_4.split('.').pop() === 'pdf' ? (
                            <a href={kycInfo.img_4}>
                              {' '}
                              <i className="fa fa-download" aria-hidden="true">
                                {' '}
                                {''} Download Pdf
                              </i>{' '}
                            </a>
                          ) : (
                            <img src={kycInfo.img_4} className="img-fluid" alt="test" />
                          )}
                        </div>
                      )}
                    </div>

                    <div className="col-12 mb-3 d-flex">
                      {/* {pole_image.split('.').pop() === 'pdf' ? (
                  <iframe src={pole_image} width="100%" height="500px">
                  </iframe> */}
                      {kycInfo.pole_image === null ? (
                        'No Record'
                      ) : (
                        <div>
                          {kycInfo.pole_image.split('.').pop() === 'pdf' ? (
                            <a href={kycInfo.pole_image}>
                              {' '}
                              <i className="fa fa-download" aria-hidden="true">
                                {' '}
                                {''} Download Pdf
                              </i>{' '}
                            </a>
                          ) : (
                            <img src={kycInfo.pole_image} className="img-fluid" alt="test" />
                          )}
                        </div>
                      )}
                    </div>

                    <div className="col-12 mb-3 d-flex">
                      {kycInfo.sla === null ? (
                        ''
                      ) : (
                        <div>
                          {kycInfo.sla.split('.').pop() === 'pdf' ? (
                            <a href={kycInfo.sla}>
                              {' '}
                              <i className="fa fa-download" aria-hidden="true">
                                {' '}
                                {''} Download Pdf
                              </i>{' '}
                            </a>
                          ) : (
                            <img src={kycInfo.sla} className="img-fluid" alt="test" />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

CustomerDetailsSharedModal.propTypes = {
  request: PropTypes.objectOf.isRequired,
};

export default CustomerDetailsSharedModal;
