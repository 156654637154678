import React from 'react';
import SuperAdminNavBar from '../../../../layouts/super-admin/SuperAdminNavBar';
import RefundAnalyticsOverview from '../../../shared/refund-analytics/RefundAnalyticsOverview';
import DefaultPage from './DefaultPage';
import { ACCOUNTS, SUPER_ADMIN, SALES, OPERATIONS } from '../../../../../utils/departments/helpers';
import AccountsNavBar from '../../../../layouts/accounts/AccountsNavBar';
import SalesNavBar from '../../../../layouts/sales/SalesNavBar';
import FieldSupportNavBar from '../../../../layouts/field-support/FieldSupportNavBar';
import { toast } from 'react-toastify';

export default function RefundStatisticsOverview() {
  const firstName = sessionStorage.getItem('firstName');
  const email = sessionStorage.getItem('officialEmail');
  const roleId = sessionStorage.getItem('roleId');
  const departmentId = sessionStorage.getItem('departmentId');

  const throwUnauthorisedUserOut = () => {
    toast.error('You are not authorised to view this page');
    setTimeout(() => {
      sessionStorage.clear();
      window.location.href = '/';
    }, 2000);
  };

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        {departmentId === SUPER_ADMIN ? (
          <SuperAdminNavBar currentPage="Refunds Statistics" position={firstName} role_id={roleId} />
        ) : departmentId === ACCOUNTS ? (
          <AccountsNavBar currentPage="Refunds Statistics" position={firstName} role_id={roleId} />
        ) : departmentId === SALES ? (
          <SalesNavBar currentPage="Refunds Statistics" position={firstName} role_id={roleId} />
        ) : departmentId === OPERATIONS ? (
          <FieldSupportNavBar currentPage="Refunds Statistics" position={firstName} role_id={roleId} />
        ) : (
          throwUnauthorisedUserOut()
        )}

        <RefundAnalyticsOverview />
        <DefaultPage />
      </main>
    </div>
  );
}
