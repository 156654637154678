import React from 'react';
import useDeviceRetrieval from '../hooks/useDeviceRetrieval';
import AppContext from '../../../../../context/shared/AppContext';

const DeviceRetrievalProvider = ({ children }) => {
  const {
    data,
    loading,
    pages,
    onSubmit,
    count,
    getDeviceRetrieval,
    updateDeviceRetrieval,
    openUninstall,
    onOpenUninstall,
    onUninstallDevice,
    bulkUninstallDevice,
    loadingUninstallDevice,
    openDeviceConfirmation,
    onOpenDeviceConfirmation,
    submitDeviceConfirmation,
    loadingDeviceConfirmation,
    showMoveToProcurementModal,
    onOpenMoveToProcurement,
    addDeviceRetrieval,
    handleChange,
    retrieval,
    loadingForm,
    fseRetrievalToProcurement,
    details,
    handleDetails,
    searchForUser,
  } = useDeviceRetrieval();
  return (
    <AppContext.Provider
      value={{
        data,
        loading,
        pages,
        onSubmit,
        count,
        getDeviceRetrieval,
        updateDeviceRetrieval,
        openUninstall,
        onOpenUninstall,
        onUninstallDevice,
        bulkUninstallDevice,
        loadingUninstallDevice,
        openDeviceConfirmation,
        onOpenDeviceConfirmation,
        submitDeviceConfirmation,
        loadingDeviceConfirmation,
        showMoveToProcurementModal,
        onOpenMoveToProcurement,
        addDeviceRetrieval,
        handleChange,
        retrieval,
        loadingForm,
        fseRetrievalToProcurement,
        details,
        handleDetails,
        searchForUser,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export default DeviceRetrievalProvider;
