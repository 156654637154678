/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import useXWFRetailers from '../../../../../hooks/express-wifi/retailers/useXWFRetailers';
import SearchXWFRetailers from './SearchXWFRetailers';
import XWFRetailersTable from './XWFRetailersTable';

const XWFRetailersBody = () => {
  const {
    retailers,
    pages,
    isLoading,
    handlePageClick,
    getXWFRetailers,
    searchRetailers,
    details,
    onOptionChange,
  } = useXWFRetailers();

  const page = 1;

  useEffect(() => {
    getXWFRetailers(page);
  }, []);

  return (
    <div className="after-summary">
      <SearchXWFRetailers searchRetailers={searchRetailers} details={details} onOptionChange={onOptionChange} />
      <XWFRetailersTable retailers={retailers} isLoading={isLoading} pages={pages} handlePageClick={handlePageClick} />
    </div>
  );
};

export default XWFRetailersBody;
