import axios from 'axios';
const auth = sessionStorage.getItem('token');

const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${auth}`,
};
export const RefundAnalytics = async (from, to) => {
  const initialDateClicked = {
    from,
    to,
  };

  try {
    const response = await axios.post('https://api.tizeti.com/api/v1/refund/refundSummary', initialDateClicked, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      withCredentials: true,
    });
    console.log('🚀 ~ RefundAnalytics ~ response:', response);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
