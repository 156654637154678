import React, {  } from 'react';
import { Link } from 'react-router-dom';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import SalesSideBar from '../../../../layouts/sales/SalesSideBar';
import Logout from '../../../shared/auth/Logout';
import SalesLogs from './SalesLogs';

const SalesLogHeader = () => {
    const {
        profile: { firstName },
      } = useUser();
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <div className="d-flex col-lg-12 p-2 top-header">
          <div className="mt-3 d-flex col-lg-12 p-0">
            <Link className="s-sidebar__trigger" to="#">
              <i className="fa fa-bars mr-3" />
            </Link>
            <SalesSideBar/>

            <h3 className="col-lg-6 overview-part d-none d-md-block">Sales Logs</h3>

            <h3 className="col-lg-6 overview-part d-sm-block d-md-none">Logs</h3>

            <div className="d-flex profile-mail col-6">
              <h6>
                <i className="mr-auto">{firstName} </i>
                <Logout />
              </h6>
            </div>
          </div>
        </div>
        <SalesLogs />
      </main>
    </div>
  );
};
export default SalesLogHeader;
