import React, { useContext } from 'react';
// import SearchNocSheets from '../SearchNocSheets';
import BasestationConnectionTable from './BasestationConnectionTable';
import AddBasestationConnection from './AddBasestationConnection';
import PaginatePage from '../common/PaginatePage';
import BasestationConnectionContext from './context/BasestationConnectionContext';

const BasestationConnectionBody = () => {
  const { loading, pages, paginateChange } = useContext(BasestationConnectionContext);
  return (
    <div className="row mt-5">
      {/* <SearchCnMaestro /> */}
      <div className="form-group ml-2">
        <button className="btn btn-green" type="button" data-toggle="modal" data-target="#add-access-point">
          Add Basestation Connection
        </button>
      </div>
      <AddBasestationConnection />
      <BasestationConnectionTable />
      {!loading && <PaginatePage pages={pages} paginateChange={paginateChange} />}
    </div>
  );
};

export default BasestationConnectionBody;
