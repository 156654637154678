import React from 'react';
import CambiumLicenseKeysBody from './CambiumLicenseKeysBody';
import CambiumLicenseKeysProvider from './provider/CambiumLicenseKeysProvider';
import useCheckTwoDepartments from '../../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';
import { admin, NOC } from '../../../../../../utils/config';
import { useUser } from '../../../../../../hooks/shared/user/useUser';
import NocNavBar from '../../../../../layouts/noc/NocNavBar';

const CambiumLicenseKeys = ({ history }) => {
  const { profile } = useUser();
  const { firstName } = profile;
  useCheckTwoDepartments(`${NOC}`, `${admin}`, history);
  return (
    <div className="s-layout">
      <CambiumLicenseKeysProvider>
        <main className="s-layout__content col-lg-10 p-0">
          <NocNavBar currentPage="NOC Sheet - Cambium License Keys" position={firstName} />
          <CambiumLicenseKeysBody />
        </main>
      </CambiumLicenseKeysProvider>
    </div>
  );
};

export default CambiumLicenseKeys;
