import axios from 'axios';
import { toast } from 'react-toastify';
import { API_URL } from '../../../utils/config';
import { LEGACY_GENIATECH_URL } from '../../../services/api/shared/installations/InstallationsUrl';
import qs from 'qs';
export const get_all_basestation_zones = async () => {
  const res = await axios.get(`${API_URL}/zones`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
  });

  try {
    if (res.data) {
      return res.data;
    }
  } catch (error) {
    toast.error('error getting zones');
  }
};

export const get_all_employees = async () => {
  const res = await axios.get(`${API_URL}/employee`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
  });

  try {
    if (res.data) {
      return res.data;
    }
  } catch (error) {
    toast.error('error getting employees');
  }
};

export const get_all_basestations = async () => {

  try {
    const response = await axios.post(`${LEGACY_GENIATECH_URL}`, qs.stringify({ action: 'fetch_basestations_list' }));

    if (response.data) {
      return response.data.items
    }

  } catch (error) {
    toast.error('error getting basestations');
  }


}

export const get_comment = async (id) => {

  try {
    const response = await axios.get(`${API_URL}/busdevuser/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      }
    });

    if (response.status === 200) {
      if (!response.data.data) {
        return "N/A (Not a bizdev user)"
      }

      return response.data.data.comment ? response.data.data.comment : "No Comment Added yet"
    }

  } catch (error) {
    toast.error('Something went wrong', error);
  }


}
