import { GET_DATA } from '../../../constants/types/shared/CustomTypes';
import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';
import * as types from '../../../constants/types/shared/cnmaestro/CnMaestroTypes';

const geniatechReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_DATA:
      return {
        ...state,
        loading: false,
        data: payload,
        // pages: payload.pages,
      };
    case LOADING_STARTS:
      return {
        ...state,
        loading: true,
      };
    case LOADING_STOPS:
      return {
        ...state,
        loading: false,
      };
    case types.RESET_SEARCH_STATUS:
      return { ...state, searchStatus: false };
    case types.SET_SEARCH_STATUS:
      return { ...state, searchStatus: payload };
    case types.SET_SEARCH_AGAIN_STATUS:
      return { ...state, searchAgainStatus: payload };
    case types.FETCH_DATA:
      return { ...state, loading: false, fetched: payload };
    case types.PERFORMANCE_SEARCH_STATUS:
      return { ...state, performanceSearchStatus: payload };
    default:
      return state;
  }
};
export default geniatechReducer;
