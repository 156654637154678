/* eslint-disable react/require-default-props */
import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { useScheduling } from '../../hooks/field-support/scheduling/useScheduling';

export const SchedulingContext = createContext({});

export const SchedulingProvider = ({ children }) => {
  const {
    zones,
    onChange,
    handleChange,
    search,
    clusters,
    onSubmit,
    fetching,
    loading,
    scheduled,
    registerFieldServiceZone,
    register,
    registerChange,
  } = useScheduling();

  return (
    <SchedulingContext.Provider
      value={{
        zones,
        onChange,
        handleChange,
        search,
        clusters,
        onSubmit,
        fetching,
        loading,
        scheduled,
        registerFieldServiceZone,
        register,
        registerChange,
      }}
    >
      {children}
    </SchedulingContext.Provider>
  );
};

SchedulingProvider.propTypes = {
  children: PropTypes.shape(),
};
