import {
  FETCHING_STARTS,
  FETCHING_STOPS,
  GET_ALL_RETAILERS,
  GET_RETAILER_LOCATIONS
} from "../../../constants/types/hotspot/HotspotTypes";

const initialState = {

  retailers: [],
  load: false

};

export const RetailersReducer=(state=initialState, action)=> {

  const {type, payload} = action;

  switch (type) {

    case FETCHING_STARTS:
      return {

        ...state,
        load: payload

      };

    case FETCHING_STOPS:
      return {

        ...state,
        load: payload

      };

    case GET_ALL_RETAILERS:
      return {

        ...state,
        retailers: payload,
        load: false

      };

    case GET_RETAILER_LOCATIONS:
      return {

        ...state,
        locations: payload,
        loadLocations: false

      };

    default:
      return state;

  }

};

export default RetailersReducer;