import axios from "axios";
import {
  GET_ANDROID_COUNT_URL,
  GET_IOS_COUNT_URL,
  GET_WEB_COUNT_URL
} from "../../../services/api/wificall/LinkCountUrl";
import { GET_ANDROID_COUNT, GET_IOS_COUNT, GET_WEB_COUNT } from "../../../constants/types/link-count/LinkCountTypes";

export const getWebCount = () => async dispatch => {

  try {

    const response = await axios.get(`${GET_WEB_COUNT_URL}`, {

      headers: {

        'Authorization': `Bearer ${sessionStorage.getItem('token')}`

      }

    });

    if(response.data.status === "success"){

      dispatch({

        type: GET_WEB_COUNT,
        payload: response.data.data.count

      })

    }

  } catch (e) {

  }
};

export const getAndroidCount = () => async dispatch => {

  try {

    const response = await axios.get(`${GET_ANDROID_COUNT_URL}`, {

      headers: {

        'Authorization': `Bearer ${sessionStorage.getItem('token')}`

      }

    });

    if(response.data.status === "success"){

      dispatch({

        type: GET_ANDROID_COUNT,
        payload: response.data.data.count

      })

    }

  } catch (e) {

  }
};

export const getiOSCount = () => async dispatch => {

  try {

    const response = await axios.get(`${GET_IOS_COUNT_URL}`, {

      headers: {

        'Authorization': `Bearer ${sessionStorage.getItem('token')}`

      }

    });

    if(response.data.status === "success"){

      dispatch({

        type: GET_IOS_COUNT,
        payload: response.data.data.count

      })

    }

  } catch (e) {

  }
};