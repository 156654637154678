/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { ToastContainer } from 'react-toastify';
import Label from '../../../shared/forms/Label';
import CustomInput from '../../../shared/forms/CustomInput';
import CustomButton from '../../../shared/forms/CustomButton';
import useFetch from '../../../../../hooks/shared/custom/data/useFetch';
import { CREATE_SUB_ZONE_URL } from '../../../../../services/api/shared/installations/ZonesUrl';
import { AVAILABLE_INSTALLATION_TIMES_URL } from '../../../../../services/api/operations/installations/InstallationsUrl';
import useForm from '../../../../../hooks/shared/custom/form/no-reload/useForm';
// import { ZoneProps } from '../../../../../types/installation/installation.interface';

const tabIndex = -1;
const CreateAvailableTime = () => {
  const { data } = useFetch(`${CREATE_SUB_ZONE_URL}`);
  const { loadingForm, onChange, formValue, onSubmit } = useForm(
    `${AVAILABLE_INSTALLATION_TIMES_URL}`,
    { time: '', sub_zone_id: '' },
    'Available Installation Time',
  );
  const { time, sub_zone_id } = formValue;
  const submitForm = () => {
    return time.length > 0 && sub_zone_id.length > 0;
  };
  const submit = (e) => {
    e.preventDefault();
    onSubmit();
  };
  return (
    <div
      id="create-available-time"
      className="modal fade in"
      tabIndex={tabIndex}
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Add Available Time
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body">
            <form className="form-horizontal" onSubmit={submit}>
              <div className="form-group">
                <div className="col-md-12 mb-1 d-flex">
                  <div className="col-lg-12">
                    <Label classname="col-lg-12 text-justify copy-font m-0 p-0" name="Time" />
                    <CustomInput
                      value={time}
                      name="time"
                      onChange={onChange}
                      type="time"
                      classname="form-control"
                      placeholder="Enter time"
                      readOnly={false}
                    />
                  </div>
                </div>
                <div className="col-md-12 mb-1 d-flex">
                  <div className="col-lg-12">
                    <Label classname="col-lg-12 text-justify copy-font m-0 p-0" name="Zone" />
                    <select className="form-control col-md-12" name="sub_zone_id" onChange={onChange}>
                      <option>-- Select Zone --</option>
                      {data && data.length ? data.map((zon) => <option value={zon.id}>{zon.name}</option>) : null}
                    </select>
                  </div>
                </div>
              </div>
              <div className="text-center">
                {loadingForm ? (
                  <CustomButton
                    disabled={loadingForm}
                    name="Adding Available Time..."
                    classname="btn btn-green waves-effect"
                  />
                ) : (
                  <CustomButton
                    disabled={!submitForm()}
                    name="Add Available Time"
                    classname="btn btn-green waves-effect"
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CreateAvailableTime;
