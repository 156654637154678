/* eslint-disable react/require-default-props */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function CustomerSupportSideBarItem({ link, name }) {
  return (
    <li className="s-sidebar__nav-link d-none d-md-block">
      {' '}
      <Link to={link} target="_self">
        <span>
          <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923430/r_d-envelop.fw.png" alt="img" />
        </span>{' '}
        {name}
      </Link>
    </li>
  );
}
CustomerSupportSideBarItem.propTypes = {
  link: PropTypes.string,
  name: PropTypes.string,
};
