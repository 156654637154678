import React from 'react';
import PropTypes from 'prop-types';
import { ACCOUNTS, SUPER_ADMIN } from '../../../../../../utils/departments/helpers';
import { useUser } from '../../../../../../hooks/shared/user/useUser';
import AccountsNavBar from '../../../../../layouts/accounts/AccountsNavBar';
import TracklistBody from '../../../../shared/tracklist/TracklistBody';
import useCheckTwoDepartments from '../../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';

const CFOTracklist = ({ history }) => {
  useCheckTwoDepartments(`${ACCOUNTS}`, `${SUPER_ADMIN}`, history);
  const {
    profile: { firstName },
  } = useUser();

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <AccountsNavBar currentPage="TrackList" position={firstName} />
        {Number(sessionStorage.getItem('roleId')) === 9 && <TracklistBody />}
      </main>
    </div>
  );
};

CFOTracklist.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default CFOTracklist;
