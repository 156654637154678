/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable */

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useUserProfile } from '../../../hooks/shared/user/useUserProfile';
import NewDesignSideBarItem from './NewDesignSideBarItem';
import './newsidebar.css';

const NewDesignSideBar = ({ currentPage, position }) => {
  const { getUserProfile, profile } = useUserProfile();

  useEffect(() => {
    getUserProfile();
  }, []);

  // console.log(currentPage);
  // console.log(currentPage);
  return (
    <div className="s-layout__sidebar" style={{ position: 'relative', zIndex: 100 }}>
      <Link className="s-sidebar__trigger" style={{ backgroundColor: 'transparent' }} to="#">
        <i className="fa fa-bars mr-3" />
      </Link>
      <nav className="s-sidebar__nav new-sidebar">
        <Link className="navbar-brand-logo new-navbar-brand" to="/new-design/overview">
          <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971047/Tizeti-Logo.png" alt="homepage" />
        </Link>

        <ul>
          <li className="s-sidebar__nav-link list-nav">
            <h5> {currentPage} </h5>
          </li>

          <NewDesignSideBarItem url="/field-support" name="Old Module" type="back to old system" />
          <NewDesignSideBarItem url="/new-design/overview" name="Overview" />
          <NewDesignSideBarItem url="#" name="Teams" />
          <NewDesignSideBarItem url="#" name="Customers" />
          <NewDesignSideBarItem url="/new-design/installations" name="Installations" />
          <NewDesignSideBarItem url="/new-design/installation-zones" name="Installation Zones" />
          <NewDesignSideBarItem url="/new-design/installation-counter" name="Installation Counter" />
          <NewDesignSideBarItem url="#" name="Scheduling" />
          <NewDesignSideBarItem url="#" name="Basestations" />
          <NewDesignSideBarItem url="#" name="Relocations" />
        </ul>
      </nav>
    </div>
  );
};

export default NewDesignSideBar;
