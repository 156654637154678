/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
// /* eslint-disable react/no-array-index-key */
// /* eslint-disable react/destructuring-assignment */
// /* eslint-disable react/prop-types */
// /* eslint-disable react/prefer-stateless-function */
// import React, { Component } from 'react';
import React from 'react';
import PropTypes from 'prop-types';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import helpers from '../../../../../utils/helpers';

//
// class NocVoltageTable extends Component {
//   render() {
//     if (this.props.load) {
//       return (
//         <tbody>
//           <tr>
//             <td>
//               <img src={loader} alt="" />
//             </td>
//           </tr>
//         </tbody>
//       );
//     }
//     return (
//       <tbody>
//         {this.props.volt.map((v, i) => (
//           <tr key={i + 1}>
//             <td>{i + 1}</td>
//             {v.name ? <td>{v.name}</td> : <td>N/A</td>}
//             {v.ip_address ? <td>{v.ip_address}</td> : <td>N/A</td>}
//             {v.value ? <td className="text-center">{v.value}</td> : <td>N/A</td>}
//             {localStorage.getItem('valu') > v.value ? (
//               <td className="text-center">
//                 <span className="badge badge-warning">Critical</span>
//               </td>
//             ) : (
//               <td>
//                 <span className="badge badge-warning" />
//               </td>
//             )}
//           </tr>
//         ))}
//       </tbody>
//     );
//   }
// }
// export default NocVoltageTable;

const NocVoltageTable = ({ load, volt }) => {
  if (load) {
    return (
      <tbody>
        <tr>
          <td>
            <img src={loader} alt="" />
          </td>
        </tr>
      </tbody>
    );
  }
  return (
    <tbody>
      {volt.map((v, i) => (
        <tr key={i + 1}>
          <td>{i + 1}</td>
          {v.name ? <td>{v.name}</td> : <td>N/A</td>}
          {v.ip_address ? <td>{v.ip_address}</td> : <td>N/A</td>}
          {v.created_at ? <td>{helpers.formatDateAndTime(v.created_at)}</td> : <td>N/A</td>}
          <td>{v.created_at ? helpers.formatTime(v.created_at) : 'N/A'}</td>

          {v.value ? <td className="text-center">{v.value}</td> : <td>N/A</td>}
          {localStorage.getItem('valu') > v.value ? (
            <td className="text-center">
              <span className="badge badge-warning">Critical</span>
            </td>
          ) : (
            <td>
              <span className="badge badge-success">Not Critical</span>
            </td>
          )}
        </tr>
      ))}
    </tbody>
  );
};
NocVoltageTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  volt: PropTypes.array,
  load: PropTypes.bool,
};

export default NocVoltageTable;
