/* eslint-disable no-shadow */
/* eslint-disable react/require-default-props */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toastr } from 'toastr';
import { connect } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import Logout from '../../../shared/auth/Logout';
import MailBlast from '../../../../../assets/img/icons/mailblast.svg';
import TechLogo from '../../../../layouts/tech-support/TechLogo';
import { getLoggedInUser } from '../../../../../actions/user/UserActions';
import { getUser } from '../../../../../actions/shared/user/UserActions';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import FieldSupportSideBar from '../../../../layouts/field-support/side-bar/FieldSupportSideBar';
import CustomersTable from '../../../shared/crm/CustomersTable';
import { OPERATIONS, TECHNICAL_SUPPORT } from '../../../../../utils/departments/helpers';
import { admin } from '../../../../../utils/config';

class FieldSupportCrm extends Component {
  componentDidMount() {
    const { getUser } = this.props;
    this.checkDepartment();
    getUser();
  }

  componentWillReceiveProps() {
    this.checkDepartment();
  }

  // eslint-disable-next-line no-undef
  checkDepartment = async () => {
    const { history } = this.props;
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          // eslint-disable-next-line no-undef
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (
        response.data.department_id !== `${OPERATIONS}` &&
        response.data.department_id !== `${TECHNICAL_SUPPORT}` &&
        response.data.department_id !== `${admin}`
      ) {
        history.push('/');
        sessionStorage.setItem('token', '');
        sessionStorage.clear();
        toastr.info('You are not allowed to view this page');
      }
    } catch (e) {
      // console.log(e.response);
    }
  };

  render() {
    const { user } = this.props;
    const { firstName } = user;

    return (
      <div className="main-wrapper">
        <header className="topbar shade bg-white fixed-top">
          <nav className="navbar navbar-expand-lg navbar-light top-navbar fixed-top">
            <TechLogo />
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse mt-3" id="navbarText">
              <ul className="navbar-nav">
                <li className="nav-item active">
                  <Link className="nav-link" to="#">
                    <h3>
                      CRM
                      <span className="sr-only">(current)</span>
                    </h3>
                  </Link>
                </li>
              </ul>
              <span className="navbar-text">
                <div className="d-flex">
                  <Link
                    to="/field-support/mail-blast"
                    className="d-flex"
                    style={{
                      color: 'gray',
                      textDecoration: 'none',
                    }}
                  >
                    <img src={MailBlast} className="logout mr-2 mt-0 mb-2" alt="" />
                    <span>
                      <h6 className="mt-1 mr-3">
                        <strong>MailBlast</strong>
                      </h6>
                    </span>
                  </Link>
                  <h6 className="mr-0 mt-1">
                    {firstName}
                    <Logout />
                  </h6>
                </div>
              </span>
            </div>
          </nav>
        </header>

        <div className="all-sides col-lg-12">
          <FieldSupportSideBar />
          <div className="page-wrapper col-lg-10">
            <CustomersTable />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

FieldSupportCrm.propTypes = {
  history: PropTypes.shape(),
  user: PropTypes.shape(),
  getUser: PropTypes.func,
};

export default connect(
  mapStateToProps,
  { getLoggedInUser, getUser },
)(FieldSupportCrm);
