/* eslint-disable import/no-named-as-default */
import { combineReducers } from 'redux';
import RetentionReducer from './shared/retention/RetentionReducer';
import SignUpReducer from './auth/v1/sign-up/SignUpReducer';
import AuthReducer from './auth/v1/AuthReducer';
import JobsReducer from './shared/recruitment/JobsReducer';
import DirectoryReducer from './wificall/DirectoryReducer';
import BasestationsReducer from './shared/BasestationsReducer';
import ApplicationsReducer from './shared/recruitment/ApplicationsReducer';
import UserReducer from './user/v1/UserReducer';
import PlansReducer from './wificall/PlansReducer';
import EmployeesReducer from './shared/employees/EmployeesReducer';
import WificallCustomersReducer from './wificall/customers/WificallCustomersReducer';
import DepartmentsReducer from './shared/DepartmentsReducer';
import FeatureRequestReducer from './research-and-development/feature-requests/FeatureRequestsReducer';
import IncidentReportsReducer from './noc/incident-reports/IncidentReportsReducer';
import CustomerSupportReducer from './customer-support/customers/CustomerSupportCustomersReducer';
import DedicatedCustomersReducer from './noc/dedicated-customers/DedicatedCustomersReducer';
import LeadsReducer from './shared/leads/LeadsReducer';
import UsersReducer from './shared/users/UsersReducer';
import { AccessPointsReducer } from './noc/access-points/AccessPointsReducer';
import CallLogsReducer from './shared/call-logs/CallLogsReducer';
import LinkCountReducer from './wificall/link-count/LinkCountReducer';
import HotspotReducer from './hotspot/HotspotReducer';
import RetailersReducer from './hotspot/retailers/RetailersReducer';
import StatesReducer from './StatesReducer';
import LoadingReducer from './loading/LoadingReducer';
import InstallationsReducer from './sales/installations/InstallationsReducer';
import FibreLinksReducer from './noc/FibreLinksReducer';
import AccountsReducer from './operations/accounts/AccountsReducer';
import SchedulerReducer from './sales/scheduler/SchedulerReducer';
import ZonesReducer from './shared/zones/ZonesReducer';
import AgentsReducer from './customer-support/quality-assurance/AgentsReducer';
import SalesCustomersReducer from './sales/customers/SalesCustomersReducer';
import AccountsRequsitionReducer from './accounts/requistions/AccountsRequsitionReducer';
import RequsitionReducer from './shared/requistion/RequsitionReducer';
import LogsReducer from './shared/logs/LogsReducer';
import SalesLogsReducer from './sales/logs/SalesLogsReducer';
import { OperationsInstallationsReducer } from './operations/installations/OperationsInstallationReducer';
import { EDIT_TICKET_REDUCER } from './noc/ticket/TicketReducer';
import UsageHistoryReducer from './shared/customers/usage-history/UsageHistoryReducer';
import CustomerDetailsReducer from './customers/CustomerDetailsReducer';
import PaymentsReducer from './shared/customers/payments/PaymentsReducer';
import ExtensionsReducer from './shared/extensions/ExtensionsReducer';
import FrequencyUsageReducer from './shared/noc/frequency-usage/FrequencyUsageReducer';
import CrmReducer from './shared/crm/CrmReducer';
import RouterReducer from './procurement/RouterReducer';
import StaticIpReducer from './shared/noc/static-ip/StaticIpReducer';

const RootReducer = combineReducers({
  // auth
  retention: RetentionReducer,
  roles: SignUpReducer,
  departments: DepartmentsReducer,
  auth: AuthReducer,
  login: AuthReducer,
  jobs: JobsReducer,
  accountsjobs: JobsReducer,
  directorycount: DirectoryReducer,
  departmentjobs: JobsReducer,
  customersupportjobs: JobsReducer,
  nocjobs: JobsReducer,
  salesjobs: JobsReducer,
  opsjobs: JobsReducer,
  researchjobs: JobsReducer,
  procurementjobs: JobsReducer,
  techjobs: JobsReducer,
  newproductsjobs: JobsReducer,
  basestations: BasestationsReducer,
  applications: ApplicationsReducer,
  user: UserReducer,
  plans: PlansReducer,
  unconfirmedEmployees: EmployeesReducer,
  customersWithEmail: WificallCustomersReducer,
  userDepartment: UserReducer,
  loadApplications: ApplicationsReducer,
  latestBasestations: BasestationsReducer,
  features: FeatureRequestReducer,
  addFeature: FeatureRequestReducer,
  confirmedEmployees: EmployeesReducer,
  customerswithDate: WificallCustomersReducer,
  incidentReport: IncidentReportsReducer,
  incidents: IncidentReportsReducer,

  leads: LeadsReducer,
  activated: WificallCustomersReducer,
  activatedStatus: WificallCustomersReducer,
  users: UsersReducer,
  dedicatedCustomers: DedicatedCustomersReducer,
  accessPoints: AccessPointsReducer,
  callLogs: CallLogsReducer,
  loadCallLogs: CallLogsReducer,
  addCallLog: CallLogsReducer,
  errorAddingCallLog: CallLogsReducer,
  employeesByDepartment: EmployeesReducer,
  logs: LogsReducer,
  // wificall reducer
  wificallCustomer: WificallCustomersReducer,
  linkCountWeb: LinkCountReducer,
  linkCountAndroid: LinkCountReducer,
  linkCountIOS: LinkCountReducer,
  // hotspot reducer
  retailers: RetailersReducer,
  hotspotRevenue: HotspotReducer,
  loadHotspotRevenue: HotspotReducer,
  locations: HotspotReducer,
  loadLocations: HotspotReducer,
  // hotspot reducer
  states: StatesReducer,
  loading: LoadingReducer,
  fibres: FibreLinksReducer,
  basestation: BasestationsReducer,
  signUp: SignUpReducer,
  // create user for geniatech and frontaccount side
  account: AccountsReducer,
  report: OperationsInstallationsReducer,
  // ops ends
  profile: UserReducer,
  scheduler: SchedulerReducer,
  zones: ZonesReducer,
  qa: AgentsReducer,
  // accounts reducer
  accountsRequisitions: AccountsRequsitionReducer,
  // requisition
  requisitions: RequsitionReducer,
  // SALES
  salesLogs: SalesLogsReducer,
  customers: SalesCustomersReducer,
  installs: InstallationsReducer,
  // customer support
  monthlyExpired: CustomerSupportReducer,
  customerSupportCustomers: CustomerSupportReducer,

  // shared
  customerUsage: UsageHistoryReducer,
  customerDetail: CustomerDetailsReducer,
  payments: PaymentsReducer,
  extensions: ExtensionsReducer,
  frequency: FrequencyUsageReducer,

  // Noc
  edit_ticket: EDIT_TICKET_REDUCER,
  static: StaticIpReducer,
  dedicatedCustomersByAP: DedicatedCustomersReducer,
  dedicatedCustomersByName: DedicatedCustomersReducer,
  dedicated: DedicatedCustomersReducer,

  // CRM
  crm: CrmReducer,

  // procuremt
  routers: RouterReducer,
  wificallCustomers: WificallCustomersReducer,
});

export default RootReducer;
