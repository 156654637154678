/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { useUser } from '../../../../hooks/shared/user/useUser';
import { usePurchaseOrder } from '../../../../hooks/shared/purchase-order/usePurchaseOrder';

const tabIndex = -1;
const rows = 4;
const cols = 5;
const CreatePurchaseOrder = () => {
  const { purchaseOrder, handleChange, createPurchaseOrder, isLoading } = usePurchaseOrder();
  const { profile } = useUser();

  const { item, quantity, comment } = purchaseOrder;

  const validateForm = () => item.length > 0 && quantity.length > 0;

  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      item,
      quantity,
      comment,
      department_id: profile.department_id ? profile.department_id : sessionStorage.getItem('departmentId'),
    };
    createPurchaseOrder(data);
  };

  return (
    <div className="">
      <div className="">
        <button className="btn btn-green copy-font" type="submit" data-toggle="modal" data-target="#create_PO">
          Create Purchase Order
        </button>
      </div>

      <div
        id="create_PO"
        className="modal fade in"
        tabIndex={tabIndex}
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title ml-5" id="myModalLabel">
                Create Purchase Order
              </h4>
              <button type="button" className="close mr-2" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
            </div>
            <div className="modal-body p-0">
              <div className="container d-flex p-0">
                <div className="col-md-10 mx-auto">
                  <form className="form-horizontal" onSubmit={onSubmit}>
                    <div className="form-group">
                      <div className="pt-3 col-12">
                        <div className="mb-2 p-0 d-flex">
                          <div className="p-0 col-md-3">
                            <label className="copy-font mb-1 p-0">Quantity</label>
                            <div className="input-group">
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Quantity"
                                name="quantity"
                                value={quantity}
                                data-testid="quantity"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="p-0 col-md-9">
                            <label className="copy-font mb-1 p-0">Item</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Item Requested"
                                name="item"
                                value={item}
                                data-testid="item"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <label className="copy-font mb-1 p-0">Comment</label>
                        <div className="p-0">
                          <textarea
                            rows={rows}
                            cols={cols}
                            placeholder="Comment"
                            name="comment"
                            value={comment}
                            onChange={handleChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        {isLoading ? (
                          <div className="text-center mt-4">
                            <button className="btn btn-green" type="button" disabled>
                              <strong>Submitting...</strong>
                            </button>
                          </div>
                        ) : (
                          <div className="text-center mt-4">
                            <button className="btn btn-green" type="submit" disabled={!validateForm()}>
                              <strong>Submit</strong>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePurchaseOrder;
