/* eslint-disable react/prop-types */
import React from 'react';
import AssignInstallationToStaff from '../../sales/clustered-installs/AssignInstallationToStaff';

const AssignInstallation = ({ installationID }) => {
  return (
    <div
      id="assign-install"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Assign Installation
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body">
            <AssignInstallationToStaff installationID={installationID} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignInstallation;
