/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLTE } from '../../../../../hooks/sales/LTE/useLTE';

const CustomerSupportAddLTEuser = ({ request, request: { id } }) => {
  const { isLoading, addLTEUser } = useLTE();

  const [imsis, setImsis] = useState('');
  const [expired, setExpired] = useState('');
  const [month, setMonth] = useState(1);

  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      status: 'no',
      user_id: id,
      imsi: imsis,
      expired_at: expired,
      no_of_months: month,
    };
    addLTEUser(data);
  };

  //   const submitForm = () => {
  //     return imsis.length > 0;
  //     return expiredDate.length > 0;
  //     return month. > 0;
  //   };

  return (
    <div
      id="addLTE"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Add LTE User
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body">
            <form className="form-horizontal" onSubmit={onSubmit}>
              <div className="form-group">
                <div className="col-12 d-flex p-0 mb-3">
                  <div className="col-6">
                    <label className="copy-font m-0 p-0">Expiry Date</label>
                    <input
                      type="date"
                      name="expiredDate"
                      className="form-control"
                      value={expired}
                      onChange={(e) => setExpired(e.target.value)}
                    />
                  </div>
                  <div className="col-6">
                    {/* <label className="copy-font m-0 p-0">Month(s)</label> */}
                    <input
                      type="hidden"
                      name="expiredDate"
                      className="form-control"
                      value={month}
                      onChange={(e) => setMonth(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-12 d-flex p-0 mb-3">
                  <div className="col-12">
                    <label className="copy-font m-0 p-0">IMSI</label>
                    <input
                      type="text"
                      name="imsi"
                      className="form-control"
                      value={imsis}
                      onChange={(e) => setImsis(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="text-center mt-5">
                {isLoading ? (
                  <button type="button" className="btn btn-second" disabled>
                    Adding...
                  </button>
                ) : (
                  <button type="submit" className="btn btn-green">
                    Add User
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

CustomerSupportAddLTEuser.propTypes = {
  request: PropTypes.objectOf.isRequired,
};

export default CustomerSupportAddLTEuser;
