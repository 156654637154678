/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable import/prefer-default-export */
import { useReducer, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import toastr from 'toastr';
import { toast } from 'react-toastify';
import * as load from '../../../../../constants/types/shared/LoadingTypes';
import * as types from '../../../../../constants/types/asset-team/AssetTeamTypes';
import { GET_ROUTER_URL } from '../../../../../services/api/shared/BasestationsUrl';
import { assetTeamTableReducer } from '../../../../../reducers/shared/asset-team/assetTeamTableReducer';
import legacy from '../../../../../services/api/axios';

export const useAssetTeamTable = () => {
  const [{ address, isLoading }, dispatch] = useReducer(assetTeamTableReducer, {
    address: '',
    isLoading: false,
  });
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [addingComment, setAddingComment] = useState(false);
  const [comment, setComment] = useState({
    comment: '',
    details: '',
  });

  const getUserAddress = async (data) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const addressData = {
        action: 'getCustomerAddress',
        mac: data.username,
      };
      const response = await axios.post(`${GET_ROUTER_URL}`, qs.stringify(addressData));
      if (response.data) {
        dispatch({
          type: types.GET_ADDRESS,
          payload: response.data[0].address,
        });
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const commentModal = () => {
    setShowCommentModal(!showCommentModal);
  };
  const onCommentChange = (e) => {
    const { name, value } = e.target;
    setComment({ ...comment, [name]: value });
  };
  const addComment = async (params) => {
    setAddingComment(true);

    try {
      const response = await legacy.post('/index.php', params);
      if (response.data.status) {
        setAddingComment(false);
        setComment({ comment: '', details: '' });
        toastr.success(response.data.message);
      } else {
        toast.info('Unable to add comment');
        setAddingComment(false);
      }
    } catch (err) {
      setAddingComment(false);
      toast.info('Something went wrong');
    }
  };

  return {
    address,
    isLoading,
    getUserAddress,
    commentModal,
    showCommentModal,
    addingComment,
    comment,
    addComment,
    onCommentChange,
  };
};
