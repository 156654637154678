import axios from 'axios';
import { toast } from 'react-toastify';
import { DELETE_BASESTATION_URL } from '../../../services/api/shared/BasestationsUrl';

export const delete_basestation = async (id) => {
  const res = await axios.delete(`${DELETE_BASESTATION_URL}/${id}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
  });
  console.log(id);
  console.log(DELETE_BASESTATION_URL);
  try {
    if (res.data) {
      toast.success('Basestation successfully deleted');
      return res.data;
    }
  } catch (error) {
    toast.error(error.message);
    toast.error('error deleting Basestation');
  }
};
