import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import { ToastContainer } from 'react-toastify';
import { useBasestations } from '../../../../hooks/shared/basestations/useBasestation';
import BasestationDataTable from './BasestationDataTable';

const Body = () => {
  const {
    basestations,
    getUsersByMonth,
    getListOfUsersOnBasestation,
    getListOfExpiredUsersOnBasestation,
    basestationUsers,
    basestationCount,
    load,
  } = useBasestations();

  const [basestationid, setBasestationID] = useState('');
  const [basestationName, setBasestationName] = useState('');
  const [month, setMonth] = useState('');

  const headers = [
    { label: 'MAC', key: 'mac_id' },
    { label: 'First Name', key: 'user.firstName' },
    { label: 'Last Name', key: 'user.lastname' },
    { label: 'Phone', key: 'user.phoneNumber' },
    { label: 'Email', key: 'user.email' },
    { label: 'Address', key: 'user.address' },
  ];

  const onSubmit = (e) => {
    e.preventDefault();
    getListOfUsersOnBasestation(basestationid);
  };

  const expiredUsers = (e) => {
    e.preventDefault();
    getListOfExpiredUsersOnBasestation(basestationid);
  };

  const searchByMonth = (e) => {
    e.preventDefault();
    getUsersByMonth(basestationName, month);
  };

  const onChange = (e) => {
    setBasestationID(e.target.value);
    setBasestationName(e.target.selectedOptions[0].getAttribute('name'));
  };

  return (
    <div className="">
      <form className="form-horizontal" onSubmit={onSubmit}>
        <div className="d-flex form-group col-12 mb-3">
          <div className="col-4 mt-3 mr-3 d-flex">
            <select className="form-control" onChange={onChange} name="basestation">
              <option>Select Basestation</option>
              {basestations.map((basestation) => {
                return (
                  <option key={basestation.id} name={basestation.name} value={basestation.id}>
                    {basestation.name.toUpperCase()}
                  </option>
                );
              })}
            </select>
            {/* <select className="form-control mr-2" onChange={(e) => setMonth(e.target.value)} name="month" value={month}>
                  <option>Select Months</option>
                  <option value = '1'>1</option>
                  <option value = '2'>2</option>
                  <option value = '3'>3</option>
            </select> */}
          </div>
          <div className="mt-2 ml-3">
            {/* <button className="btn btn-green shadow-md" type="submit" onClick={searchByMonth}>
              Month
            </button> */}
            <button className="btn btn-green shadow-md" type="submit">
              Search All
            </button>
            <button className="btn btn-green shadow-md ml-3" type="submit" onClick={expiredUsers}>
              Search Expired
            </button>
          </div>

          {basestationUsers.length ? (
            <div className="mt-2 ml-3">
              <CSVLink
                data={basestationUsers}
                headers={headers}
                filename="Customers.csv"
                className="btn btn-green"
                style={{ color: 'white' }}
              >
                Download Data
              </CSVLink>
            </div>
          ) : null}
        </div>
      </form>

      <div className="ml-2">
        {basestationUsers.length ? (
          <div className="col-12 ml-3">
            Number of Users: <span className="badge badge-success payslipfont">{basestationCount}</span>
          </div>
        ) : null}
        <BasestationDataTable basestationUsers={basestationUsers} load={load} oldBasestation={basestationName} />
      </div>
      <ToastContainer />
    </div>
  );
};

export default Body;
