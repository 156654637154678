/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/button-has-type */
/* eslint-disable consistent-return */
import React from 'react';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { ToastContainer } from 'react-toastify';
import EditTicket from './EditTicket';
import { useTickets } from '../../../../../hooks/noc/tickets/useTickets';
import CreateTicket from './CreateTicket';

const TicketsBody = () => {
  const {
    handleStatusFilterChange,
    employees,
    handlePersonnelChange,
    tickets,
    isLoading,
    filterTickets,
    personnel,
    status,
    createTicket,
    ticket,
    onChange,
    isCreating,
  } = useTickets();

  const onCreate = (data) => {
    createTicket(data);
  };

  const renderTicketList = () => {
    if (tickets && ticket.length) {
      return tickets.map((tix) => {
        return (
          <tr key={tix.id}>
            <td>{tix.id}</td>
            <td>{tix.baseStation ? tix.baseStation.name : null}</td>
            <td>{tix.incident_type}</td>
            <td>{tix.severity}</td>
            <td>{tix.time_of_incident}</td>
            <td>{tix.time_of_escalation}</td>
            <td>
              {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
              {tix.mttr || 0.0} {'hrs'}
            </td>
            <td>{moment(tix.created_at).format('DD-MM-YYYY')}</td>
            <td>
              <img
                src="https://res.cloudinary.com/teewhy/image/upload/v1543579893/edit.png"
                width="15%"
                style={{ cursor: 'pointer' }}
                data-toggle="modal"
                alt=""
                className="w-0 ml-3"
                data-target="#editTicket"
              />
              <EditTicket id={tix.id} />
            </td>
          </tr>
        );
      });
    }
  };

  const renderPersonnelList = () => {
    if (employees) {
      return employees.map((staff) =>
        staff.employees.map((list) => {
          return (
            <option key={staff.id} value={staff.id}>
              {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
              {list.firstName} {list.lastName}
            </option>
          );
        }),
      );
    }
    return <option>unable to fetch personnel list</option>;
  };

  const onSubmit = () => {
    const data = {
      filterBy: 'status',
      value: status,
      ticket_owner_id: personnel,
    };
    filterTickets(data);
  };

  const submit = () => {
    return personnel.length > 0 && status.length > 0;
  };

  return (
    <div className="container m-0 p-0">
      <div className="row m-0 p-0 mt-4">
        <div className="m-0 p-0 col-lg-12 col-md-12 col-sm-12 mt-5 mb-3 d-flex flex-wrap justify-content-between">
          <div className="d-flex flex-wrap mt-2 col-lg-5 col-md-5 col-sm-12">
            <div className="d-flex mt-1 col-lg-5 col-md-5 col-sm-12">
              <small className="text-nowrap">Filter by:</small>
              <select className="form-control" onChange={handleStatusFilterChange} name="status">
                <option>Status</option>
                <option value="1">Completed</option>
                <option value="0">Pending</option>
              </select>
            </div>
            <div className="d-flex mt-1 col-lg-6 col-md-6 col-sm-12">
              <small className="text-nowrap">Filter by:</small>
              <select className="form-control" name="ticket_owner_id" onChange={handlePersonnelChange}>
                {renderPersonnelList()}
              </select>
            </div>
          </div>

          <div className="d-flex mt-3 m-0 p-0 col-lg-7 col-md-7 col-sm-12">
            <div className="m-0 p-0 ml-2 col-lg-2 col-md-2 col-sm-2">
              {isLoading ? (
                <button className="btn btn-green shadow-md" type="submit" disabled={isLoading} onClick={onSubmit}>
                  {' '}
                  Search
                </button>
              ) : (
                <button className="btn btn-green shadow-md" type="submit" disabled={!submit()} onClick={onSubmit}>
                  {' '}
                  Search
                </button>
              )}
            </div>

            <div className="m-0 p-0 mr-2 col-lg-3 col-md-3 col-sm-2">
              <button
                className="btn btn-green shadow-md"
                type="submit"
                data-toggle="modal"
                data-target="#create-ticket"
              >
                {' '}
                Create Ticket
              </button>
              <CreateTicket create={onCreate} ticket={ticket} onChange={onChange} isCreating={isCreating} />
            </div>

            <div className="m-0 p-0 mr-2 col-lg-3 col-md-3 col-sm-2">
              <CSVLink data={tickets} filename="NocTickets.csv">
                <button className="btn btn-green shadow-md">Download data</button>
              </CSVLink>
            </div>
          </div>
        </div>

        <div className="card x-panel">
          <div className="table-responsive content">
            <table className="table copy-font">
              <thead>
                <tr>
                  <th>#</th>
                  <th>BaseStation name</th>
                  <th>Incident Type</th>
                  <th>Impact</th>
                  <th>Time Of Incident</th>
                  <th>Time Escalated</th>
                  <th>MTTR</th>
                  <th>Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              {isLoading ? (
                <tbody>
                  <tr>
                    <td>
                      <small>loading...</small>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>{renderTicketList()}</tbody>
              )}
            </table>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default TicketsBody;
