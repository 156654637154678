import {
  FORGOT_PASSWORD,
  GET_USER,
  RESET_PASSWORD,
  START_FORGOT_PASSWORD,
  START_RESET_PASSWORD,
  STOP_FORGOT_PASSWORD,
  STOP_RESET_PASSWORD,
} from '../../../constants/types/AuthTypes';
import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';

const initialState = {
  auth: [],
  load: false,
  forgot: false,
  reset: false,
};

export const AuthReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case START_FORGOT_PASSWORD:
      return {
        ...state,
        forgot: payload,
      };

    case START_RESET_PASSWORD:
      return {
        ...state,
        reset: payload,
      };

    case STOP_RESET_PASSWORD:
      return {
        ...state,
        reset: payload,
      };

    case RESET_PASSWORD:
      return {
        ...state,
        reset: false,
      };

    case STOP_FORGOT_PASSWORD:
      return {
        ...state,
        forgot: payload,
      };

    case GET_USER:
      return {
        ...state,
        auth: payload,
        role: payload,
      };

    case LOADING_STARTS:
      return {
        ...state,
        load: payload,
      };

    case LOADING_STOPS:
      return {
        ...state,
        load: payload,
      };

    case FORGOT_PASSWORD:
      return {
        ...state,
        forgot: false,
      };

    default:
      return state;
  }
};

export default AuthReducer;
