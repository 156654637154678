/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';

const PaidRequisitionTable = ({ paidRequisitions }) => {
  const tableData =
    paidRequisitions.length && paidRequisitions ? (
      paidRequisitions.map((paidRequisition, i) => {
        const { currency, amount_in_words, amount_in_digits, in_favour_of, purpose } = paidRequisition;
        return (
          <tr key={i}>
            <td>{i + 1}</td>
            <td>{in_favour_of}</td>
            <td>{currency + amount_in_digits}</td>
            <td>{amount_in_words}</td>
            <td>{purpose}</td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="5" className="text-center">
          There are no paid requisitions
        </td>
      </tr>
    );

  return tableData;
};

export default PaidRequisitionTable;
