import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Box from '@mui/material/Box';

const AreaCharts = ({ data, isLoading }) => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flexStart">
      {!isLoading && (
        <ResponsiveContainer width="100%" height={500}>
          <AreaChart
            data={data}
            margin={{
              top: 5,
              right: 10,
              left: 0,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="state" />
            <YAxis />
            <Tooltip />
            <Legend />

            <Area type="monotone" dataKey="percentage" stroke="#82ca9d" fill="#8884d8" />
          </AreaChart>
        </ResponsiveContainer>
      )}
    </Box>
  );
};

export default AreaCharts;
