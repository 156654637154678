import React from 'react';
import nigeria from '../../../../../assets/img/icons/country/nigeria.png';
import useExpiredCustomers from '../../../../../hooks/shared/customers/useExpiredCustomers';

const ExpiredNigeria90days = () => {
  const {
    state: { nigeriaExpired90 },
  } = useExpiredCustomers();

  return (
    <div className="col-lg col-md col-sm">
      <div className="boxes">
        <div className="text-center">
          <div className="text-center mt-3">
            <img src={nigeria} alt="Nigeria" width="40px" height="30px" />
          </div>
          <div className="count">{nigeriaExpired90}</div>
          <h3 className="color-two">Nigeria Expired in 90 days</h3>
        </div>
      </div>
    </div>
  );
};

export default ExpiredNigeria90days;
