import React, { Component } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { Link } from 'react-router-dom';
import { API_URL } from '../../../../../utils/config';

class AddWifiDirectory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      zip: '',
      number: '',
      address: '',
      category: '',
      twitter: '',
      instagram: '',
      longitude: '',
      latitude: '',
      alternatePhoneNumber: '',
      loading: false,
      selectedFile: null,
      loaded: 0,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
  }

  handleSelectedFile = (event) => {
    this.setState(
      {
        selectedFile: event.target.files[0],
        loaded: 0,
      },
      () => {},
    );
  };

  handleUpload = (e) => {
    this.setState({
      loading: true,
    });

    const data = new FormData();
    data.append('form', this.state.selectedFile, this.state.selectedFile.name);
    axios
      .post(`${API_URL}/yellowpage/csv`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
        onUploadProgress: (ProgressEvent) => {
          this.setState({
            loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100,
          });
        },
      })
      .then((res) => {
        this.setState({
          loading: false,
        });
        console.log(res.data);
        if (res.data.status === 'success') {
          toastr.success('Operation Successful!');
          window.location.reload();
        }
      });
  };

  /* handleChange = (event) => {
        this.setState({
            csv: event.target.files[0]
        })
    }; */

  handleUDpload(ev) {
    ev.preventDefault();

    const form = new FormData();
    form.append('file', this.uploadInput.files[0]);
    //        data.append('filename', this.fileName.value);

    axios
      .post(`${API_URL}/yellowpage/csv`, form, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        }
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch(function(error) {});
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    this.setState({
      loading: true,
    });

    e.preventDefault();

    const data = {
      businessName: this.state.name,
      countryCode: this.state.zip,
      phoneNumber: this.state.number,
      alternatePhoneNumber: this.state.alternatePhoneNumber,
      address: this.state.address,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
      twitter: this.state.twitter,
      instagram: this.state.instagram,
    };

    axios({
      method: 'post',
      url: `${API_URL}/directory`,
      data,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      }
    })
      .then((res) => {
        if (res.data.id) {
          toastr.success('Operation Successful!');
          window.location.reload();

          this.setState({
            loading: false,
          });
        }
      })
      .catch((err) => {});
  }

  render() {
    const { loading } = this.state;

    return (
      <div
        className="modal fade"
        id="myModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title mt-2" style={{ color: '#1B3F43' }}>
                Add Directory
              </h4>
              <button type="button" className="close" data-dismiss="modal">
                ×
              </button>
            </div>

            <div className="modal-body">
              <nav id="wifi-nav-tabs">
                <div className="nav nav-tabs d-flex" role="tablist">
                  <a
                    className="nav-item nav-link active mr-5"
                    style={{ color: '#1B3F43' }}
                    id="nav-home-tab"
                    data-toggle="tab"
                    href="#nav-home"
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected="true"
                  >
                    Upload File
                  </a>
                  <a
                    className="nav-item nav-link mr-5"
                    style={{ color: '#1B3F43' }}
                    id="nav-contact-tab"
                    data-toggle="tab"
                    href="#nav-contact"
                    role="tab"
                    aria-controls="nav-contact"
                    aria-selected="false"
                  >
                    One by One
                  </a>
                </div>
              </nav>
              <div className="tab-content text-justify" id="wifiTab-content">
                <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                  <div className="contain-file text-center p-5">
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1545299320/file.fw.png" alt="images" />
                    <p className="copy-font mt-2" style={{ color: '#ABABA7' }}>
                      <b>Supported file formats: CSV, XLS, XLSX </b>

                      <br />
                      <span>Browse or drag file here to upload</span>
                    </p>
                    {/* <form onSubmit={this.handleUpload}> */}
                    <input
                      type="file"
                      name=""
                      className="ml-5"
                      onChange={this.handleSelectedFile}
                      /* ref={(ref) => { this.uploadInput = ref; }} */
                    />
                    {loading ? (
                      <button className="btn btn-success">
                        <i className="fa fa-circle-o-notch fa-spin" />
                      </button>
                    ) : (
                      <button className="btn btn-success" onClick={this.handleUpload} type>
                        Upload
                      </button>
                    )}
                    <div> {Math.round(this.state.loaded, 2)} %</div>
                    {/* </form> */}
                  </div>

                  {/* <div class="text-center">
                        <button type="button" class="btn btn-green" data-dismiss="modal">Add Directory</button>
                    </div> */}
                </div>

                <div className="tab-pane fade mt-3" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                  <form onSubmit={this.onSubmit} className="form-horizontal">
                    <div className="form-group" id="form-group">
                      <div className="col-md-12 mb-3 d-flex">
                        <div className="col-lg-6 mb-3">
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Business Name"
                              name="name"
                              onChange={this.onChange}
                              value={this.state.name}
                            />
                          </div>

                          <div className="mt-3">
                            <div className="zip mb-3 d-flex">
                              <input
                                type="number"
                                className="form-control text-center mr-2"
                                placeholder="Zip"
                                name="zip"
                                value={this.state.zip}
                                onChange={this.onChange}
                                style={{ width: '80%' }}
                              />

                              <input
                                type="number"
                                className="form-control"
                                placeholder="Phone Number"
                                name="number"
                                value={this.state.number}
                                onChange={this.onChange}
                              />
                            </div>

                            <div className="Phone-number">
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Alternative Phone Number"
                                name="alternatePhoneNumber"
                                value={this.state.alternatePhoneNumber}
                                onChange={this.onChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 mb-3">
                          <textarea
                            rows="6"
                            cols="200"
                            type="text"
                            className="form-control"
                            name="address"
                            value={this.state.address}
                            onChange={this.onChange}
                            placeholder="Address"
                          />
                        </div>
                      </div>

                      <div className="col-md-12 mb-3 d-flex">
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Add New Category"
                            name="category"
                            value={this.state.category}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>

                      <div className="col-md-12 mb-3 d-flex">
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Twitter"
                            name="twitter"
                            value={this.state.twitter}
                            onChange={this.onChange}
                          />
                        </div>

                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Instagram"
                            name="instagram"
                            value={this.state.instagram}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>

                      <div className="col-md-12 mb-3 d-flex">
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="latitude"
                            name="latitude"
                            value={this.state.latitude}
                            onChange={this.onChange}
                          />
                        </div>

                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="longitude"
                            name="longitude"
                            value={this.state.longitude}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>

                      <div className="text-center mt-5">
                        {loading ? (
                          <button className="btn btn-green" id="login">
                            <i className="fa fa-circle-o-notch fa-spin" />
                          </button>
                        ) : (
                          <button className="btn btn-green" id="login">
                            Add Directory
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddWifiDirectory;
