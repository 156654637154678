import * as types from '../../../../../constants/types/new-products/products/hotspot/HotspotTypes';
import { FETCHING_STARTS, FETCHING_STOPS } from '../../../../../constants/types/shared/LoadingTypes';

const retailersReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GET_PENDING_RETAILERS:
      return {
        ...state,
        pending: payload,
        loadingPending: false,
      };

    case types.GET_ACTIVATED_RETAILERS:
      return {
        ...state,
        activated: payload,
        loadingActivated: false,
      };

    case types.START_LOADING_PENDING_RETAILERS:
      return {
        ...state,
        loadingPending: true,
      };

    case types.START_LOADING_ACTIVATED_RETAILERS:
      return {
        ...state,
        loadingActivated: true,
      };

    case types.STOP_LOADING_PENDING_RETAILERS:
      return {
        ...state,
        loadingPending: false,
      };

    case types.STOP_LOADING_ACTIVATED_RETAILERS:
      return {
        ...state,
        loadingActivated: false,
      };

    case types.GET_RETAILER:
      return {
        ...state,
        retailer: payload,
        fetchingRetailer: false,
      };

    case FETCHING_STARTS:
      return {
        ...state,
        fetchingRetailer: true,
      };

    case FETCHING_STOPS:
      return {
        ...state,
        fetchingRetailer: false,
      };

    default:
      return state;
  }
};

export default retailersReducer;
