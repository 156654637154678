import React from 'react';
import FilterIcon from '../icons/FilterIcon';
import Popover from '@mui/material/Popover';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import CalenderModal from './CalenderModal';
import { get_all_downtime_hours } from '../../../../../../actions/coo-statistics/DowntimeLogger';

export function FormatDate(todayDate) {
  const formattedDate = new Date(todayDate);
  formattedDate.setMinutes(formattedDate.getMinutes() - formattedDate.getTimezoneOffset());
  const stringFormattedDate = formattedDate.toISOString().split('T')[0];
  return stringFormattedDate;
}
export default function FilterPopover({ todayDate, isLoading, setIsLoading, setZone, selectDate, setSelectDate }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState('one');
  // const [selectDate, setSelectDate] = React.useState(FormatDate(todayDate));
  const [calenderOpen, setCalenderOpen] = React.useState(false);
  const whoRef = React.useRef(null);
  const open = Boolean(anchorEl);
  const id = open ? 'filter-popover' : undefined;

  const nowDate = new Date().toISOString().slice(0, 10);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setCalenderOpen(false);
  };

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const zones = await get_all_downtime_hours(
        { from: FormatDate(selectDate), to: FormatDate(selectDate) },
        todayDate,
      );
      setZone(zones);
      setAnchorEl(null);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        sx={{ background: '#30BFAB', textTransform: 'capitalize' }}
        endIcon={<FilterIcon />}
        onClick={handleFilterClick}
      >
        Filter by
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab value="one" label="Today" sx={{ textTransform: 'capitalize' }} />
            {/* <Tab value="two" label="Last 7 days" sx={{ textTransform: 'capitalize' }} onClick={onSubmit} />
            <Tab value="three" label="30 days" sx={{ textTransform: 'capitalize' }} onClick={onSubmit} /> */}
          </Tabs>

          <div role="tabpanel" id={`horizontal-tabpanel`} aria-labelledby="horizontal-tab">
            <Box sx={{ p: 3 }}>
              <div>Customised Date</div>
              <ButtonGroup variant="outlined" aria-label="Calender button group">
                <Button
                  variant="outlined"
                  sx={{ textTransform: 'capitalize' }}
                  onClick={() => {
                    whoRef.current = 'from';
                    setCalenderOpen(true);
                    // handleDateChange(selectDate);
                  }}
                >
                  Date: {FormatDate(selectDate)}
                </Button>

                <Button
                  variant="contained"
                  sx={{ textTransform: 'capitalize' }}
                  disabled={FormatDate(selectDate) > nowDate || isLoading}
                  onClick={onSubmit}
                >
                  Submit
                </Button>
              </ButtonGroup>
            </Box>
          </div>
        </Box>
      </Popover>
      <CalenderModal
        todayDate={todayDate}
        calenderOpen={calenderOpen}
        setCalenderOpen={setCalenderOpen}
        setSelectDate={setSelectDate}
      />
    </div>
  );
}
