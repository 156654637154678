/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-plusplus */
import React from 'react';
import { useActiveCustomer } from '../../../../../hooks/shared/asset-team/active/useActiveCustomer';
import { AssetTeamHelperActive, FormatTime } from '../../../../../utils/asset-team/AssetTeamHelper';
import * as helpers from '../../../../../utils/asset-team/AssetTeamHelperBody';
import ActiveCustomersTable from './ActiveCustomersTable';

const ActiveCustomersGraph = () => {
  const { items, time, total, isLoading } = useActiveCustomer();
  const { data } = AssetTeamHelperActive(items);
  const { fetchTime } = FormatTime(time);

  const headers = [{ label: 'Basestation', key: 'basestationame' }, { label: 'Count', key: 'active_count' }];

  return (
    <div className="container">
      <div className="row my-5">
        <div className="col-12 pt-5">{helpers.AssetTeamHeader(items, time, total, fetchTime)}</div>
        <div className="col-12 mb-5 pr-5">{helpers.AssetTeamHelperGraph(data, time, total, fetchTime)}</div>
        <div className="col-12 pr-5">
          {helpers.AssetTeamGeneralButton(items, headers, 'Active Customers Per Basestation.csv')}
        </div>
        {helpers.AssetTeamHelperTable(isLoading, true, <ActiveCustomersTable items={items} />)}
        <div className="ml-3">{helpers.AssetTeamBottomTotal(total)}</div>
      </div>
    </div>
  );
};

export default ActiveCustomersGraph;
