/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import view from '../../../../../assets/img/icons/view.png';
import ViewInstallationDetails from './ViewInstallationDetails';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';

const ProcurementInstallationTable = ({ installations, load }) => {
  const [customer, setCustomer] = useState({});

  const getItem = (installation) => setCustomer(installation);

  const tableData =
    installations.length && installations ? (
      installations.map((installation, i) => {
        const {
          user: { firstName, lastName, phoneNumber },
          user,
          payment_reference,
          status,
          address,
        } = installation;
        return (
          <tr key={i}>
            <td>{i + 1}</td>
            <td>{user ? firstName : 'N/A'}</td>
            <td>{user ? lastName : 'N/A'}</td>
            <td>{user ? phoneNumber : 'N/A'}</td>
            <td>
              <div style={{ width: '400px' }}>{payment_reference}</div>
            </td>
            <td>
              <span
                className={`badge badge-${
                  status === 'completed'
                    ? 'success'
                    : status === 'not completed'
                    ? 'danger'
                    : status === 'on-going'
                    ? 'secondary'
                    : status === 'queued'
                    ? 'primary'
                    : status === 'awaiting_account_creation'
                    ? 'warning'
                    : null
                }`}
              >
                {status.toUpperCase()}
              </span>
            </td>
            <td>
              <div style={{ width: '200px' }}>{address}</div>
            </td>
            <td />
            <td>
              <div className="">
                <button
                  type="button"
                  className=""
                  data-toggle="modal"
                  data-placement="right"
                  data-target="#installation_details"
                  style={{ border: '0', backgroundColor: 'white' }}
                  onClick={() => getItem(installation)}
                >
                  <span className="view">
                    <img src={view} alt="" data-toggle="tooltip" data-placement="left" title="View" />
                  </span>
                </button>
              </div>
            </td>
            <ViewInstallationDetails customer={customer} />
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="9" className="text-center">
          There are no installations
        </td>
      </tr>
    );

  return (
    <div className="card x-panel mt-2 px-0">
      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <thead>
            <tr className="text-nowrap">
              <th>#</th>
              <th>First name</th>
              <th>Last name</th>
              <th>Phone</th>
              <th>Payment REF</th>
              <th>Status</th>
              <th>Address</th>
              <th>Team</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {load ? (
              <tr>
                <td colSpan="9" className="text-center">
                  <img src={loader} alt="loading gif" />
                </td>
              </tr>
            ) : (
              tableData
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

ProcurementInstallationTable.propTypes = {
  installations: PropTypes.arrayOf.isRequired,
  load: PropTypes.bool.isRequired,
};
export default ProcurementInstallationTable;
