/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import edit from '../../../../../assets/img/icons/edit.png';
import UpdateXWFCustomers from './UpdateXWFCustomers';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../../utils/loader/loadingHelper';
import { useUserProfile } from '../../../../../hooks/shared/user/useUserProfile';
import XWFSingleSMS from '../shared/sms/XWFSingleSMS';
import XWFBulkSMS from '../shared/sms/XWFBulkSMS';

const XWFCustomersTable = ({ customers, pages, isLoading, handlePageClick }) => {
  const { profile, getUserProfile } = useUserProfile();
  const colSpan = 11;
  const [oneCustomer, setOneCustomer] = useState({
    company_name: '',
    customer_name: '',
    retailer_name: '',
    last_data_pack_source: '',
    duration: '',
    exipiration_time: '',
    phoneNumber: '',
    value: '',
    id: '',
    isChecked: false,
  });

  const [newData, setNewData] = useState(customers);
  const [sendBulk, setSendBulk] = useState(false);

  useEffect(() => {
    getUserProfile();
  }, []);

  useEffect(() => {
    if (customers && customers.length) {
      setNewData(
        customers.map((item) => {
          const {
            company_name,
            customer_name,
            retailer_name,
            last_data_pack_source,
            duration,
            exipiration_time,
            phoneNumber,
            value,
            id,
          } = item;
          return {
            isChecked: false,
            company_name,
            customer_name,
            retailer_name,
            last_data_pack_source,
            duration,
            exipiration_time,
            phoneNumber,
            value,
            id,
          };
        }),
      );
    }
  }, [customers]);

  const getOneCustomer = (item) => setOneCustomer(item);

  const handleSelectAll = (e) => {
    const { checked } = e.target;
    setNewData(
      newData.map((dataSet) => {
        dataSet.isChecked = checked;
        return dataSet;
      }),
    );
    if (newData.filter((one) => one.isChecked).length > 0) {
      setSendBulk(true);
    } else {
      setSendBulk(false);
    }
  };

  const handleSelectOne = (e, id) => {
    setNewData(
      newData.map((dataSet) => {
        if (id === dataSet.id) {
          dataSet.isChecked = e.target.checked;
        }
        return dataSet;
      }),
    );
    if (newData.filter((one) => one.isChecked).length > 0) {
      setSendBulk(true);
    } else {
      setSendBulk(false);
    }
  };

  const customersTable =
    customers && customers.length && newData && newData.length ? (
      newData.map((customer, i) => {
        const {
          company_name,
          customer_name,
          retailer_name,
          last_data_pack_source,
          duration,
          exipiration_time,
          phoneNumber,
          value,
          id,
          isChecked,
        } = customer;
        return (
          <tr className="" key={i}>
            <td>{i + 1}</td>
            <td>
              <input type="checkbox" checked={isChecked} onChange={(e) => handleSelectOne(e, id)} />
            </td>
            <td className="text-nowrap">{company_name}</td>
            <td className="text-nowrap">{customer_name}</td>
            <td className="text-nowrap">{retailer_name}</td>
            <td>{last_data_pack_source}</td>
            <td>{duration}</td>
            <td className="text-nowrap">{moment(exipiration_time).format('DD-MMM-YYYY')}</td>
            <td>{phoneNumber}</td>
            <td>{value}</td>
            <td>
              <div className="text-center">
                <button
                  type="submit"
                  className="mr-2 noBorder"
                  data-toggle="modal"
                  data-placement="right"
                  data-target="#updateCustomer"
                  onClick={() => getOneCustomer(customer)}
                >
                  <span className="view">
                    <img src={edit} data-toggle="tooltip" alt="" data-placement="top" title="Edit" />
                  </span>
                </button>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#singleSMS"
                  className="mr-2 noBorder"
                  onClick={() => getOneCustomer(customer)}
                >
                  <span className="view1">
                    <i className="fa fa-comment-o" title="Send SMS" />
                  </span>
                </button>
              </div>
              <UpdateXWFCustomers oneCustomer={oneCustomer} />
              <XWFSingleSMS oneUser={oneCustomer} />
            </td>
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan={colSpan} text="No Customers" />
    );

  return (
    <div className="card x-panel p-0">
      {sendBulk && (
        <div className="form-group mx-2">
          <button type="button" className="btn btn-danger" data-toggle="modal" data-target="#bulkSMS">
            Send Bulk SMS
          </button>
        </div>
      )}
      <XWFBulkSMS users={newData} profile={profile} />

      <div className="table-responsive content col-12 px-0">
        <table className="table table-striped">
          <thead>
            <tr className="">
              <th>S/N</th>
              <th>
                <input type="checkbox" onChange={handleSelectAll} />
              </th>
              <th>Company Name</th>
              <th>Customer Name</th>
              <th>Retailer</th>
              <th>Last Data Pack Source</th>
              <th>Duration</th>
              <th>Expiry Date</th>
              <th>Phone</th>
              <th>Value</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{isLoading ? <TizetiGIFLogoTable colSpan={colSpan} /> : customersTable}</tbody>
        </table>
      </div>
      <div className="px-3">
        {pages > 1 && (
          <ReactPaginate
            previousLabel="previous"
            nextLabel="next"
            breakLabel="..."
            breakClassName="break-me"
            pageCount={pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            activeClassName="active"
          />
        )}
      </div>
    </div>
  );
};

export default XWFCustomersTable;
