import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { delete_basestation } from '../../../../actions/shared/basestations/DeleteBasestation';
import { toast } from 'react-toastify';

export default function DeleteDialogue({ openDeleteDialogue, setOpenDeleteDialogue, deleteAction }) {
  // const handleClickOpen = () => {
  //   setOpenDeleteDialogue(true);
  // };

  const handleClose = () => {
    setOpenDeleteDialogue(false);
  };

  const handleConfirmDelete = async () => {
    setOpenDeleteDialogue(false);

    try {
      const res = await delete_basestation(deleteAction.id);
      // console.log(res);
      window.location.reload();
      // toast.success('Basestation successfully deleted');
    } catch (error) {
      console.error(error);
      toast.error('error deleting Basestation');
    }
  };

  // console.log(deleteAction);
  return (
    <React.Fragment>
      <Dialog
        open={openDeleteDialogue}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Delete Base Station?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {deleteAction !== undefined &&
              'Are You sure you want to Delete this Basestation located in ' +
                deleteAction.zone +
                ' with longitude ' +
                deleteAction.longitude +
                ' and latitude ' +
                deleteAction.latitude}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirmDelete} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
