/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useUserProfile } from '../../../../../hooks/shared/user/useUserProfile';
import PurchaseOrderBody from '../../../shared/purchase-order/PurchaseOrderBody';
import FieldSupportNavBar from '../../../../layouts/field-support/FieldSupportNavBar';
import useCheckDepartment from '../../../../../hooks/shared/custom/check-department/field-support/useCheckDepartment';

const FieldSupportPurchaseOrder = ({ history }) => {
  useCheckDepartment(history);
  const {
    profile: { firstName, department_id },
    getUserProfile,
  } = useUserProfile();

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <FieldSupportNavBar currentPage="Purchase Order" position={firstName} />
        <div className="container">
          <PurchaseOrderBody deptID={department_id} />
        </div>
      </main>
    </div>
  );
};

FieldSupportPurchaseOrder.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default FieldSupportPurchaseOrder;
