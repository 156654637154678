/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

const ViewPtp = ({ view }) => {
  return (
    <div id="view_access-point" className="modal fade mt-5" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title my-2">Details</h4>
            <button type="button" className="mr-2 close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div className="modal-body detail p-0">
            <div className="row">
              <div className="col-lg-12 d-flex">
                <div className="col-lg-5 detail-label1">
                  <label className="mt-1">AP End</label>
                  <label className="mt-1">Station End</label>
                  <label className="mt-1">Device Name</label>
                </div>
                <div className="col-lg-7 detail-result1 item-font">
                  <p className="mt-4 mb-2">{view.ap_end}</p>
                  <p className="mb-2">{view.station_end}</p>
                  <p className="mb-2">{view.device_name}</p>
                </div>
              </div>
              <div className="col-lg-12 d-flex">
                <div className="col-lg-5 detail-label3">
                  <label>SSID</label>
                  <label className="mt-1">Device Type</label>
                  <label className="mt-1">Security</label>
                </div>
                <div className="col-lg-7 detail-result2 item-font">
                  <p className="mt-4 mb-2">{view.ssid}</p>
                  <p className="mb-2 text-uppercase">{view.device_type}</p>
                  <p className="mb-2">{view.security}</p>
                  {/* <p className="mb-2">{helpers.formatDateAndTime(retrieval_date)}</p> */}
                </div>
              </div>
              <div className="col-lg-12 d-flex">
                <div className="col-lg-5 detail-label2">
                  <label>Usage</label>
                  <label className="mt-1">Frequency</label>
                </div>
                <div className="col-lg-7 detail-result2 item-font">
                  <p className="mt-4 mb-2 text-capitalize">{view.usage}</p>
                  <p className="mb-3">{view.frequency}</p>
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-5 detail-label3">
                  <label>Link Distance</label>
                  <label className="mt-1">Capacity</label>
                </div>
                <div className="col-lg-7 detail-result2 item-font">
                  <p className="mt-4 mb-2 text-capitalize">{view.link_distance}</p>
                  <p className="mb-3">{view.capacity}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPtp;
