/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import toastr from 'toastr';
import Logout from '../../../shared/auth/Logout';
import ResearchAndDevelopmentSideBar from '../../../../layouts/research-and-development/ResearchAndDevelopmentSideBar';
// import ResearchLogo from '../../../../layouts/research-and-development/ResearchLogo';
import NetPromoter from '../../../shared/nps/net-promoter-score/NetPromoter';
import Mailblast from '../../../../../assets/img/icons/mailblast.svg';
import { API_URL, RESEARCH_AND_DEVELOPMENT } from '../../../../../utils/config';
import { SUPER_ADMIN } from '../../../../../utils/departments/helpers';

class ResearchNetPromoterScore extends Component {
  constructor() {
    super();
    this.state = {
      role: '',
      firstName: '',
    };
  }

  componentWillMount() {
    this.fetchLoggedOnUser();
  }

  componentWillReceiveProps() {
    this.fetchLoggedOnUser();
    this.getUser();
  }

  getUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employee/loggedOnEmployee`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        this.setState({
          // eslint-disable-next-line react/no-unused-state
          user: data.data,
        });
      })
      .catch(() => {});
  }

  fetchLoggedOnUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employeeReg/logged`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        if (data.data) {
          this.setState({
            role: data.data.role_id,
          });
        }

        if (data.data.department_id !== `${RESEARCH_AND_DEVELOPMENT}` &&
          data.data.department_id !== `${SUPER_ADMIN}`) {
          this.props.history.push('/staff/login');

          toastr.info('You are not allowed to view this page');
        }
      })
      .catch(() => {});
  }

  render() {
    const { role, firstName } = this.state;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <Link className="s-sidebar__trigger" to="#">
                <i className="fa fa-bars mr-3" />
              </Link>
              <ResearchAndDevelopmentSideBar role={role} />

              <h3 className="col-lg-6 overview-part">Net Promoter Score</h3>

              <div className="d-flex profile-mail">
                {role > '2' ? (
                  <Link
                    to="/research-and-development/mail-blast"
                    className="d-md-flex mr-4 d-none"
                    style={{
                      color: 'gray',
                      textDecoration: 'none',
                    }}
                  >
                    <img src={Mailblast} alt="mailblast" className="logout mr-2" />
                    <span>
                      <h6 className="mt-2">
                        <strong>MailBlast</strong>
                      </h6>
                    </span>
                  </Link>
                ) : null}
                <h6 className="admin mt-2">
                  <i>{firstName}</i>
                  <Logout />
                </h6>
              </div>
            </div>
          </div>

          <div className="after-summary col-12">
            <NetPromoter />
          </div>
        </main>
      </div>
    );
  }
}

export default ResearchNetPromoterScore;
