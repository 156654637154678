/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-empty */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Logout from '../../../shared/auth/Logout';
// import OperationsSideBar from "../../../layouts/operations/OperationsSideBar";
import { admin, API_URL, operations, TECHNICAL_SUPPORT } from '../../../../../utils/config';
import NetPromoter from '../../../shared/nps/net-promoter-score/NetPromoter';
import mailblast from '../../../../../assets/img/icons/mailblast.svg';
import FieldSupportSideBar from '../../../../layouts/field-support/side-bar/FieldSupportSideBar';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';

export default class FieldSupportNetPromoterScore extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
    };
  }

  componentDidMount() {
    this.getUserDetails();
    this.checkDepartment();
  }

  componentWillReceiveProps() {
    this.checkDepartment();
  }

  getUserDetails() {
    axios({
      method: 'get',
      url: `${API_URL}/employee/loggedOnEmployee`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    }).then((res) => {
      // console.log(res.data);
      this.setState({
        firstName: res.data.firstName,
      });
    });
  }

  checkDepartment = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      // console.log(response.data);

      if (
        response.data.department_id !== `${operations}` &&
        response.data.department_id !== `${TECHNICAL_SUPPORT}` &&
        response.data.department_id !== `${admin}`
      ) {
        sessionStorage.setItem('token', '');
        sessionStorage.clear();

        this.props.history.push('/');

        toast.info('You are not allowed to view this page');
      }
    } catch (e) {}
  };

  render() {
    const { firstName } = this.state;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <Link className="s-sidebar__trigger" to="#">
                <i className="fa fa-bars mr-3" />
              </Link>
              <FieldSupportSideBar />

              <h3 className="col-lg-6 overview-part">Net Promoter Score</h3>

              <div className="d-flex profile-mail">
                <Link
                  to="/operations/mail-blast"
                  className="d-none d-md-flex"
                  style={{
                    color: 'gray',
                    textDecoration: 'none',
                  }}
                >
                  <img src={mailblast} alt="" className="logout mr-2" />
                  <span>
                    <h6 className="mt-2 mr-3">
                      <strong>MailBlast</strong>
                    </h6>
                  </span>
                </Link>
                <Link to="#" data-toggle="modal" data-target="#add-employee" />
                <span className="navbar-text">
                  <h6>
                    <Logout />
                  </h6>
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 after-summary">
            <NetPromoter />
          </div>
        </main>
      </div>
    );
  }
}
