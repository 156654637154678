/* eslint-disable no-return-assign */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-nested-ternary */
import React, { useContext, useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import InstallationsTableBody from './InstallationsTableBody';
import { OverviewContext } from '../../../../../../context/shared/installations/overview/OverviewContext';
import { showContentForDepartment, showContentForGivenDepartment, showContentForTwoDepartment } from './utils/helpers';
import { SALES } from '../../../../../../utils/config';
import useInstallationZones from '../../zones/hooks/useInstallationZones';
import CustomDropdownOption from '../../../forms/CustomDropdownOption';
import { OPERATIONS } from '../../../../../../utils/departments/helpers';

const InstallationsOverviewTable = () => {
  const { loading, installations, pages, handlePageClick, searchInstallations } = useContext(OverviewContext);
  const { zones } = useInstallationZones();
  const [allInstallations, setAllInstallations] = useState([]);
  const [clusterState, setClusterState] = useState('');
  const roleId = Number(sessionStorage.getItem('roleId'));
  const departmentId = sessionStorage.getItem('departmentId');
  const [page] = useState(1);

  useEffect(() => {
    searchInstallations(page);
  }, []);
  const [allInstallationData, setAllInstallationData] = useState([]);
  useEffect(() => {
    if (installations && installations.length)
      return setAllInstallations(
        installations.map((cust) => {
          const { user, payment_reference, status } = cust;
          const {
            id,
            state,
            latitude,
            longitude,
            sub_zone = {},
            nearest_basestation,
            distance_calculation,
            installation_comment,
            created_at,
            date,
            team,
            team_id,
            device_type,
            zone,
            EmployeeAuth,
            EmployeeAuth: { employees } = {},
          } = cust.installations && cust.installations.length ? cust.installations[0] : [];

          return {
            installationId: cust.installations ? id || null : cust.id || null,
            select: false,
            userId: user ? user.id : cust.id,
            id: user ? cust.id : cust.installations && cust.installations.length ? cust.installations[0].id : null,
            firstName: user ? user.firstName : cust.firstName,
            lastName: user ? user.lastName : cust.lastName,
            email: user ? user.email : cust.email,
            phoneNumber: user ? user.phoneNumber : cust.phoneNumber,
            payment_reference: user
              ? payment_reference
              : cust.installations && cust.installations.length
              ? cust.installations[0].payment_reference
              : 'N/A',
            address: cust.address,
            userAddress: user ? user.address : cust.address,
            lat: cust.installations ? latitude || 'N/A' : cust.latitude || 'N/A',
            lng: cust.installations ? longitude || 'N/A' : cust.longitude || 'N/A',
            sub_zone: cust.installations ? sub_zone.name || 'N/A' : cust.sub_zone.name || 'no sub zone',
            state: cust.installations ? state || 'N/A' : cust.state || 'N/A',
            status: user
              ? status
              : cust.installations && cust.installations.length
              ? cust.installations[0].status
              : 'N/A',
            assignedEmployees: cust.employee,
            nearestBasestation: cust.installations ? nearest_basestation || 'N/A' : cust.nearest_basestation || 'N/A',
            distance: cust.installations ? distance_calculation || 'N/A' : cust.distance_calculation,
            installationComment: cust.installations ? installation_comment || 'N/A' : cust.installation_comment,
            zoneName:
              cust.installations && cust.installations.length ? zone.name || 'N/A' : cust.zone ? cust.zone.name : 'N/A',
            createdDate: cust.installations ? created_at || 'N/A' : cust.created_at,
            installDate: cust.installations ? date || 'N/A' : cust.date,
            team: cust.installations ? team || 'N/A' : cust.team,
            teamId: cust.installations ? team_id || 'N/A' : cust.team_id,
            deviceType: cust.installations ? device_type || 'N/A' : cust.device_type,
            salesAgent:
              cust.installations && cust.installations.length
                ? EmployeeAuth.employees.length
                  ? `${employees[0].firstName} ${employees[0].lastName}`
                  : 'N/A'
                : cust.EmployeeAuth && cust.EmployeeAuth.employees.length
                ? `${cust.EmployeeAuth.employees[0].firstName} ${cust.EmployeeAuth.employees[0].lastName}`
                : 'N/A',
          };
        }),
      );
  }, [installations]);
  useEffect(() => {
    if (installations && installations.length)
      return setAllInstallationData(
        installations.map((cust) => {
          const { user, payment_reference, status } = cust;
          const {
            id,
            state,
            latitude,
            longitude,
            sub_zone = {},
            nearest_basestation,
            distance_calculation,
            installation_comment,
            created_at,
            date,
            team,
            team_id,
            device_type,
            zone,
            EmployeeAuth,
            EmployeeAuth: { employees } = {},
          } = cust.installations && cust.installations.length ? cust.installations[0] : [];

          return {
            installationId: cust.installations ? id || null : cust.id || null,
            select: false,
            userId: user ? user.id : cust.id,
            id: user ? cust.id : cust.installations && cust.installations.length ? cust.installations[0].id : null,
            firstName: user ? user.firstName : cust.firstName,
            lastName: user ? user.lastName : cust.lastName,
            email: user ? user.email : cust.email,
            phoneNumber: user ? user.phoneNumber : cust.phoneNumber,
            payment_reference: user
              ? payment_reference
              : cust.installations && cust.installations.length
              ? cust.installations[0].payment_reference
              : 'N/A',
            address: cust.address,
            userAddress: user ? user.address : cust.address,
            lat: cust.installations ? latitude || 'N/A' : cust.latitude || 'N/A',
            lng: cust.installations ? longitude || 'N/A' : cust.longitude || 'N/A',
            sub_zone: cust.installations ? sub_zone.name || 'N/A' : cust.sub_zone.name || 'no sub zone',
            state: cust.installations ? state || 'N/A' : cust.state || 'N/A',
            status: user
              ? status
              : cust.installations && cust.installations.length
              ? cust.installations[0].status
              : 'N/A',
            assignedEmployees: cust.employee,
            nearestBasestation: cust.installations ? nearest_basestation || 'N/A' : cust.nearest_basestation || 'N/A',
            distance: cust.installations ? distance_calculation || 'N/A' : cust.distance_calculation,
            installationComment: cust.installations ? installation_comment || 'N/A' : cust.installation_comment,
            zoneName:
              cust.installations && cust.installations.length ? zone.name || 'N/A' : cust.zone ? cust.zone.name : 'N/A',
            createdDate: cust.installations ? created_at || 'N/A' : cust.created_at,
            installDate: cust.installations ? date || 'N/A' : cust.date,
            team: cust.installations ? team || 'N/A' : cust.team,
            teamId: cust.installations ? team_id || 'N/A' : cust.team_id,
            deviceType: cust.installations ? device_type || 'N/A' : cust.device_type,
            salesAgent:
              cust.installations && cust.installations.length
                ? EmployeeAuth.employees.length
                  ? `${employees[0].firstName} ${employees[0].lastName}`
                  : 'N/A'
                : cust.EmployeeAuth && cust.EmployeeAuth.employees.length
                ? `${cust.EmployeeAuth.employees[0].firstName} ${cust.EmployeeAuth.employees[0].lastName}`
                : 'N/A',
          };
        }),
      );
  }, [installations]);

  const isChecked = allInstallations.filter((cust) => {
    if (cust.select === true) return true;
    return null;
  });
  const getInstallationsThatMatchSelectedZone = (zone) => {
    const newInstallations = allInstallationData.filter((i) => i.zoneName === zone);
    const notSelectedInstallations = newInstallations.filter((data) => data.select === false);
    setAllInstallations(
      notSelectedInstallations.map((data) => {
        if (
          data.zoneName.toUpperCase() === zone.toUpperCase() &&
          data.status.toUpperCase() !== 'COMPLETED' &&
          data.lat !== 'N/A'
        )
          data.select = true;
        return data;
      }),
    );
  };

  const selectAll = () => {
    return (
      <th className="text-nowrap">
        <div>
          <select
            name="state"
            style={{ width: '100px' }}
            className="form-control"
            onChange={(e) => {
              const { value } = e.target;
              setClusterState(value);
              getInstallationsThatMatchSelectedZone(value);
              // setAllInstallations(allInstallations.map((data) => (data.select = false)));
              // setAllInstallations(
              //   allInstallations.map((data) => {
              //     if (
              //       data.state.toUpperCase() === value.toUpperCase() &&
              //       data.status.toUpperCase() !== 'COMPLETED' &&
              //       data.lat !== 'N/A'
              //     )
              //       data.select = true;
              //     return data;
              //   }),
              // );
            }}
          >
            <option value="all">-All Zones-</option>
            {zones.length && zones.map((zone) => <CustomDropdownOption value={zone.name} name={zone.name} />)}
          </select>
        </div>
      </th>
    );
  };

  const activateText = () => {
    return <th>Activate</th>;
  };

  const initiateRefund = () => {
    return <th>Initiate Refund</th>;
  };
  const viewCurrentRefunds = () => {
    return <th>Refund Request</th>;
  };
  const deleteInstallation = () => {
    return <th>Delete Installation</th>;
  };
  const checkRoleToDelete = () => {
    if (roleId > 2 || roleId !== 6) {
      return showContentForGivenDepartment(departmentId, `${SALES}`, deleteInstallation());
    }
    return null;
  };
  return (
    <div className="card x-panel">
      {roleId > 1 && (
        <div>
          {isChecked.length > 0 && (
            <Link
              to={{
                pathname: '/field-support/clustering',
                query: { allInstallations, clusterState },
              }}
            >
              <button type="button" className="btn btn-warning mr-1">
                CLUSTER
              </button>
            </Link>
          )}
        </div>
      )}
      <div className="table-responsive content">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              {roleId > 1 && showContentForDepartment(departmentId, selectAll())}
              <th>Name</th>
              <th>Nearest Basestation</th>
              <th>Distance</th>
              <th>Installed Address</th>
              <th>Latitude Longitude</th>
              {/* {showContentForDepartment(departmentId, activateText())} */}
              <th>Actions</th>
              {/* {showContentForGivenDepartment(departmentId, `${SALES}`, initiateRefund())} */}
              {showContentForTwoDepartment(departmentId, `${SALES}`, departmentId, `${OPERATIONS}`, initiateRefund())}
              {showContentForTwoDepartment(
                departmentId,
                `${SALES}`,
                departmentId,
                `${OPERATIONS}`,
                viewCurrentRefunds(),
              )}
              <th>Status</th>
              <th>Payment REF</th>
              <th>User Address</th>
       
              <th>Email</th>
              <th>Phone</th>
              <th>Sub Zone</th>
              <th>Asset Zone</th>
              {checkRoleToDelete()}
            </tr>
          </thead>
          <InstallationsTableBody
            load={loading}
            clusterState={clusterState}
            allInstallations={allInstallations}
            setAllInstallations={setAllInstallations}
          />
        </table>
        {pages > 1 ? (
          <ReactPaginate
            previousLabel="previous"
            nextLabel="next"
            breakLabel="..."
            breakClassName="break-me"
            pageCount={pages}
            marginPagesDisplayed={3}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            subContainerClassName="pages pagination"
            activeClassName="active"
          />
        ) : null}
      </div>
    </div>
  );
};

export default InstallationsOverviewTable;
