import React from 'react';
import { useRequisition } from '../../../../../hooks/admin/requisition/useRequisition';
import CeoAndCooRequisitionTableBody from './CeoAndCooRequisitionTableBody';

const CeoAndCooRequisitions = () => {
  const {
    ceoAndCooRequisitions,
    isLoading,
    fetchRequisitionByStatus,
    giveCeoCooApproval,
    giveCeoCooDisApproval,
  } = useRequisition();

  const onChange = (e) => {
    const { value } = e.target;
    if (value) {
      fetchRequisitionByStatus(value);
    }
  };

  const { list } = ceoAndCooRequisitions;

  return (
    <div className="card x-panel logs">
      <select onChange={onChange} id="">
        <option value="">Status</option>
        <option value="approved">Approved</option>
        <option value="rejected">Rejected</option>
      </select>
      <div className="table-responsive content">
        <table className="table copy-font">
          <thead>
            <tr>
              <th>#</th>
              <th>Amount</th>
              <th>Amount In Words</th>
              <th>User that uploaded It</th>
              <th>In favor of</th>
              <th>Purpose</th>
              <th>Department</th>
              <th>Created At</th>
              <th>View</th>
              <th>Approve</th>
              <th>Reject</th>
              <th style={{ paddingLeft: '5px' }}>Actions</th>
            </tr>
          </thead>
          {isLoading ? (
            <small>loading</small>
          ) : (
            <CeoAndCooRequisitionTableBody
              requist={list}
              giveCeoCooApproval={giveCeoCooApproval}
              giveCeoCooDisApproval={giveCeoCooDisApproval}
            />
          )}
        </table>
      </div>
    </div>
  );
};

export default CeoAndCooRequisitions;
