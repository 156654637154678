/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/require-default-props */
/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import { useCustomer } from '../../../../../hooks/shared/customers/useCustomers';

const ExtendCustomer = ({ username, name }) => {
  const { onExtendChange, extend, extendCustomer, extendingCustomer } = useCustomer();
  const { duration, reason } = extend;

  const onSubmit = (e) => {
    e.preventDefault();

    const data = {
      username,
      duration,
    };

    const extendLog = {
      macid: username,
      extending_days: duration,
      comment: reason,
      name,
      extension_type: 'extension',
    };

    extendCustomer(data, extendLog);
  };

  const submitForm = () => {
    return duration.length > 0 && reason.length > 0;
  };

  // const showMoreThanADay = () => {
  //   const today = new Date();
  //   const currentTime = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
  //   const givenTime = '18:00';
  //   const days = [];
  //   for (let day = 0; day < 2; ) {
  //     days.push((day += 1));
  //   }
  //   const dayList = days.map((day) => {
  //     return <option key={day}>{day}</option>;
  //   });

  //   if (currentTime < givenTime) {
  //     return (
  //       <input
  //         type="text"
  //         className="form-control"
  //         placeholder="Number of days to extend with"
  //         name="duration"
  //         value="1"
  //         onChange={onExtendChange}
  //         readOnly
  //       />
  //     );
  //   }
  //   return (
  //     <select name="duration" onChange={onExtendChange} className="form-control" required>
  //       <option value="">Select days to extend by</option>
  //       {dayList}
  //     </select>
  //   );
  // };

  const checkRoleForExtension = () => {
    const days = [];
    for (let day = 0; day < 14; ) {
      days.push((day += 1));
    }
    const dayList = days.map((day) => {
      return <option key={day}>{day}</option>;
    });

    const personnelDays = [];
    for (let day = 0; day < 2; ) {
      personnelDays.push((day += 1));
    }
    const personnelDayList = personnelDays.map((day) => {
      return <option key={day}>{day}</option>;
    });

    if ((Number(sessionStorage.getItem('roleId')) === 3 || Number(sessionStorage.getItem('roleId')) === 2) && Number(sessionStorage.getItem('roleId')) !== 6) {
      return (
        <div className="modal-body">
          <form className="form-horizontal" onSubmit={onSubmit}>
            <div className="form-group">
              <div className="col-12 mb-3 d-flex">
                <div className="col-6">
                  <select name="duration" onChange={onExtendChange} className="form-control">
                    <option value="">Select days to extend by</option>
                    {dayList}
                  </select>
                </div>
                <div className="col-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Reason for extending"
                    name="reason"
                    value={reason}
                    onChange={onExtendChange}
                  />
                </div>
              </div>

              {extendingCustomer ? (
                <div className="col-12 mb-3 text-center">
                  <button className="btn btn-green" disabled={extendingCustomer}>
                    Extending Customer...
                  </button>
                </div>
              ) : (
                <div className="col-12 mb-3 text-center">
                  <button className="btn btn-green" disabled={!submitForm()}>
                    Extend Customer
                  </button>
                </div>
              )}
            </div>
          </form>
        </div>
      );
      // eslint-disable-next-line no-else-return
    } else if (Number(sessionStorage.getItem('roleId')) === 9) {
      return (
        <div className="modal-body">
          <form className="form-horizontal" onSubmit={onSubmit}>
            <div className="form-group">
              <div className="col-12 mb-3 d-flex">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Number of days to extend with"
                  name="duration"
                  value={duration}
                  onChange={onExtendChange}
                />

                <input
                  type="text"
                  className="form-control"
                  placeholder="Reason for extension"
                  name="reason"
                  value={reason}
                  onChange={onExtendChange}
                />
              </div>

              {extendingCustomer ? (
                <div className="col-12 mb-3 text-center">
                  <button className="btn btn-green" disabled={extendingCustomer}>
                    Extend Customer
                  </button>
                </div>
              ) : (
                <div className="col-12 mb-3 text-center">
                  <button className="btn btn-green" disabled={!submitForm()}>
                    Extend Customer
                  </button>
                </div>
              )}
            </div>
          </form>
        </div>
      );
    }
    return (
      <div className="modal-body">
        <form className="form-horizontal" onSubmit={onSubmit}>
          <div className="form-group">
            <div className="col-12 mb-3 d-flex">
              <select name="duration" onChange={onExtendChange} className="form-control">
                <option value="">Select days to extend by</option>
                {personnelDayList}
              </select>

              <input
                type="text"
                className="form-control"
                placeholder="Reason for extension"
                name="reason"
                value={reason}
                onChange={onExtendChange}
              />
            </div>

            {extendingCustomer ? (
              <div className="col-12 mb-3 text-center">
                <button className="btn btn-green" disabled={extendingCustomer}>
                  Extend Customer
                </button>
              </div>
            ) : (
              <div className="col-12 mb-3 text-center">
                <button className="btn btn-green">Extend Customer</button>
              </div>
            )}
          </div>
        </form>
      </div>
    );
  };
  return (
    <div
      id="extend"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Extend Customer
            </h4>
            <button type="button" className="close custom-close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          {checkRoleForExtension()}
        </div>
      </div>
    </div>
  );
};

ExtendCustomer.propTypes = {
  name: PropTypes.string,
  username: PropTypes.string,
};
export default ExtendCustomer;
