/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

const SalesJacketModal = ({ request }) => {
  //   console.log(request);
  return (
    <div id="viewc" className="modal fade" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header shadow">
            <h4 className="modal-title text-center" id="myModalLabel">
              Quantity and Size
            </h4>
            <button type="button" className="close mr-2" data-dismiss="modal">
              x
            </button>
          </div>
          <div className="modal-body">
            <div className="col-12 mb-3 d-flex">
              <ul>
                <li>{request && request.join(',' + '' + '')}</li>
              </ul>
              {/* The size(s) :<p> {request} </p> */}

              {/* {request.map((mo) => {
                return <p>{mo}</p>;
              })} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SalesJacketModal.propTypes = {
  request: PropTypes.objectOf.isRequired,
};

export default SalesJacketModal;
