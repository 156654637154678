/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useContext, ReactNode, useEffect, useState, Key } from 'react';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import { stringify } from 'qs';
// import { OpenAndBlockedUsersProps } from './types/OpenAndBlockedUsersProps';
import helpers from '../../../../../utils/helpers';
import AppContext from '../../../../../context/shared/AppContext';
import CustomButton from '../../../shared/forms/CustomButton';
import { useUser } from '../../../../../hooks/shared/user/useUser';

const OpenAndBlockedUsersTable = () => {
  const { loading, data, status, blockOrUnblockUser, blockingOrUnblocking } = useContext(AppContext);
  const { profile } = useUser();
  const [newData, setNewData] = useState(data);
  const [bulk, setBulk] = useState(false);
  useEffect(() => {
    if (data) {
      setNewData(
        data.map((item, i) => {
          return {
            card_id: item.card_id,
            creationdate: item.creationdate,
            firstusedate: item.firstusedate,
            username: item.username,
            status: item.status,
            name: item.name,
            balance: item.balance,
            isChecked: false,
            id: i + 1,
          };
        }),
      );
    }
  }, [data]);
  const onSelectOne = (e, id) => {
    setNewData(
      newData.map((dat) => {
        if (id === dat.id) {
          dat.isChecked = e.target.checked;
        }
        return dat;
      }),
    );
    if (newData.filter((er) => er.isChecked).length > 0) {
      setBulk(true);
    } else {
      setBulk(false);
    }
  };
  const singleActionData = (param, c) => {
    return blockOrUnblockUser(
      stringify({
        action: param === 'blocked' ? 'unblock_wificall_user' : 'block_wificall_user',
        card_id: c,
      }),
    );
  };
  const bulkActionData = (param, c) => {
    return blockOrUnblockUser({
      action: 'bulk_block_unblock_wificall_accounts',
      operation: param === 'blocked' ? 'unblock' : 'block',
      staff_email: profile.email,
      card_ids: c,
    });
  };
  const bulkAction = (param) => {
    if (!profile.email) {
      toast('Kindly create a profile');
    } else {
      bulkActionData(
        param,
        newData.filter((da) => da.isChecked).map((user) => user.card_id),
      );
    }
  };
  const singleAction = (p, c) => {
    if (!profile.email) {
      toast('Kindly create a profile');
    } else {
      singleActionData(p, c);
    }
  };
  const showActionForStatus = (param, card) => {
    switch (param) {
      case 'blocked':
        return (
          <Button onClick={() => singleAction(param, card)} variant="success">
            Unblock User
          </Button>
        );
      case 'open':
        return (
          <Button onClick={() => singleAction(param, card)} variant="danger">
            Block User
          </Button>
        );
      default:
        return null;
    }
  };
  const tableData =
    newData && newData.length > 0
      ? newData.map((row, i) => (
        <tr className="text-nowrap" key={i + 1}>
          <td>{i + 1}</td>
          <td>
            <input type="checkbox" checked={row.isChecked} value={row.id} onChange={(e) => onSelectOne(e, row.id)} />
          </td>
          <td>{row.name}</td>
          <td>{row.username}</td>
          <td>{row.card_id}</td>
          <td>{row.status}</td>
          <td>{helpers.formatCurrency(row.balance)}</td>
          <td>{helpers.formatDateAndTime(row.creationdate)}</td>
          <td>{showActionForStatus(row.status, row.card_id)}</td>
        </tr>
      ))
      : 'no data';
  const handleAllChecked = (event) => {
    const { checked } = event.target;
    setNewData(
      newData.map((dat) => {
        dat.isChecked = checked;
        return dat;
      }),
    );
    if (newData.filter((er) => er.isChecked).length > 0) {
      setBulk(true);
    } else {
      setBulk(false);
    }
  };
  const showName = () => {
    if (blockingOrUnblocking) {
      return `${status}ing Users`;
    }
    return status === 'blocked' ? 'Unblock User' : 'Block User';
  };
  return (
    <div className="card x-panel mt-2 px-0">
      {bulk && (
        <div className="form-group mx-2">
          <CustomButton
            classname="btn btn-green"
            disabled={blockingOrUnblocking}
            onClick={() => bulkAction(status)}
            name={showName()}
          />
        </div>
      )}
      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>
                <input type="checkbox" onChange={handleAllChecked} value="checkedall" />
              </th>
              <th>Customer Name</th>
              <th className="text-nowrap">Customer Username</th>
              <th>Card ID</th>
              <th>Status</th>
              <th>Balance</th>
              <th>Creation Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <p>loading</p>
              </tr>
            ) : (
              tableData
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OpenAndBlockedUsersTable;
