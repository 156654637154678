/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import PurchaseOrderBody from './PurchaseOrderBody';
import ProcurementNavBar from '../../../../../layouts/procurement/ProcurementNavBar';
import { PROCUREMENT, SUPER_ADMIN } from '../../../../../../utils/departments/helpers';
import { useUser } from '../../../../../../hooks/shared/user/useUser';
import useCheckTwoDepartments from '../../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';

const DepartmentalPurchaseOrder = ({ history }) => {
  useCheckTwoDepartments(`${PROCUREMENT}`, `${SUPER_ADMIN}`, history);
  const {
    profile: { firstName },
  } = useUser();

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <span className="d-none d-md-block">
          <ProcurementNavBar currentPage="InterDepartmental Purchase Order" position={firstName} />
        </span>
        <span className="d-md-none d-block">
          <ProcurementNavBar currentPage="InterDepartmental PO" position={firstName} />
        </span>
        <PurchaseOrderBody />
      </main>
    </div>
  );
};

DepartmentalPurchaseOrder.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default DepartmentalPurchaseOrder;
