/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable radix */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import Progress from './Progress';
import { API_URL } from '../../../../utils/config';

const UploadFile = (props) => {
  const [selectedFile, setselectedFile] = useState('');
  const [filename, setFilename] = useState('Choose File');
  const [uploadedFile, setUploadedFile] = useState('');
  const [message, setMessage] = useState('');
  const [uploadPercent, setUploadPercent] = useState(0);

  const chooseFile = (e) => {
    setselectedFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
  };

  const handleUpload = async (selectedFile) => {
    const { dept } = props;
    const url = `${API_URL}/learning_portal/upload/${dept}`;
    const formData = new FormData();
    formData.append('file', selectedFile);
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        setUploadPercent(parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)));
        setTimeout(() => setUploadPercent(0), 5000);
      },
    };
    try {
      const result = await axios.post(url, formData, config);
      // return result;
      if (result) {
        const { name } = result.data;
        setUploadedFile(name);
        setMessage('File Uploaded');
        toastr.info('Uploaded Successfully!');
        window.location.reload();
      }
    } catch (err) {
      // setMessage('File Not Found');
      toastr.info('File Not Found');
    }
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    handleUpload(selectedFile);
  };

  return (
    <div>
      {uploadedFile ? (
        <div>
          <span>
            Uploaded Successfully: <strong>{uploadedFile}</strong>
          </span>
        </div>
      ) : (
        message
      )}
      <form onSubmit={onFormSubmit} className="d-flex">
        <div className="custom-file mb-3 col-md-7 mr-5">
          <input type="file" className="custom-file-input" name="selectedFile" id="ctrl" onChange={chooseFile} />
          <label className="custom-file-label" htmlFor="customFile">
            {filename}
          </label>
        </div>

        <div className="">
          <button type="submit" className="btn btn-green">
            Upload File
          </button>
        </div>
      </form>

      {uploadPercent ? <Progress percentage={uploadPercent} /> : null}
    </div>
  );
};

UploadFile.propTypes = {
  dept: PropTypes.string.isRequired,
};

export default UploadFile;
