/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getDirectoryCount } from '../../../../../actions/wificall/directory/DirectoryActions';
import { admin, NEW_PRODUCTS } from '../../../../../utils/config';
import WifiCallSidebar from '../../../../layouts/wificall-sidebar/WifiCallSidebar';
import Logout from '../../../shared/auth/Logout';
import { getLoggedInUser } from '../../../../../actions/user/UserActions';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import { getUser } from '../../../../../actions/shared/user/UserActions';
import { getWificallUsersCount } from '../../../../../actions/wificall/customers/CustomersActions';
import { getUnVerifiedCustomers } from '../../../../../actions/wificall/customers/UnverifiedCustomersActions';
import { getVerifiedCustomers } from '../../../../../actions/wificall/customers/VerifiedCustomersActions';
import TotalSignUps from './TotalSignUps';
import TotalRevenueGraph from './TotalRevenueGraph';
import VerifiedAndUnverifiedCount from './VerifiedAndUnverifiedCount';
import TotalUsers from './TotalUsers';
import TotalRevenue from './TotalRevenue';
import TotalDirectory from './TotalDirectory';

class WifiCallOverview extends Component {
  static propTypes = {
    directorycount: PropTypes.string.isRequired,
    count: PropTypes.string.isRequired,
    getWificallUsersCount: PropTypes.func.isRequired,
    getDirectoryCount: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    firstName: PropTypes.string.isRequired,
    getUnVerifiedCustomers: PropTypes.func.isRequired,
    getLoggedInUser: PropTypes.func.isRequired,
    getVerifiedCustomers: PropTypes.func.isRequired,
    verified: PropTypes.object.isRequired,
    unverified: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.checkDepartment();
    this.props.getWificallUsersCount();
    this.props.getDirectoryCount();
    this.props.getUser();
    this.props.getVerifiedCustomers();
  }

  componentWillReceiveProps() {
    this.checkDepartment();
  }

  checkDepartment = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.department_id !== `${admin}` && response.data.department_id !== `${NEW_PRODUCTS}`) {
        this.props.history.push('/');

        sessionStorage.setItem('token', '');
        sessionStorage.clear();

        toastr.info('You are not allowed to view this page');
      }
    } catch (e) {
      // console.log(e.response);
    }
  };

  render() {
    // const { directorycount, user, unverified, verified } = this.props;
    const { user } = this.props;

    const { firstName } = user;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <Link className="s-sidebar__trigger" to="#">
                <i className="fa fa-bars mr-3" />
              </Link>
              <WifiCallSidebar />

              <h3 className="col-lg-6 overview-part">Overview</h3>

              <div className="d-flex">
                <h6 className="mr-0 mt-1">
                  {firstName}
                  <Logout />
                </h6>
              </div>
            </div>
          </div>

          <div className="col-12 after-summary">
            <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
              {/* <div className="row"> */}
              {/*    <div className="col-lg-12 mb-3"> */}
              {/*        <h6>Showing alarm of the last 30 days</h6> */}
              {/*    </div> */}
              {/* </div> */}

              <div className="row">
                <div className="col-12 d-none d-md-flex">
                  <div className="col-md-6 d-flex p-0">
                    <div className="mr-2 mt-2">
                      <small>Filter By:</small>
                    </div>
                    <div className="tab-stuff col-10 p-0">
                      <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li className="nav-item mr-auto">
                          <a
                            className="nav-link active"
                            id="pills-home-tab"
                            data-toggle="pill"
                            href="#pills-home"
                            role="tab"
                            aria-controls="pills-home"
                            aria-selected="true"
                          >
                            All Time
                          </a>
                        </li>
                        {/* <li className="nav-item mr-auto"> */}
                        {/*    <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Monthly</a> */}
                        {/* </li> */}
                        {/* <li className="nav-item mr-auto"> */}
                        {/*    <a className="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-robo" role="tab" aria-controls="pills-contact" aria-selected="false">Yearly</a> */}
                        {/* </li> */}
                      </ul>
                    </div>
                  </div>

                  {/* <div className="col-6 d-flex p-0 justify-content-end"> */}
                  {/*    <div className="mr-2 mt-2"><small>Show Records From:</small></div> */}
                  {/*    <span> */}
                  {/*        <input type="date" className="form-control" /> */}
                  {/*    </span> */}

                  {/*    <div className="mr-2 mt-2"><small className="ml-2 mr-2">To</small></div> */}
                  {/*        <span> */}
                  {/*            <input type="date" className="form-control" /> */}
                  {/*        </span> */}
                  {/*    </div> */}
                </div>

                <div className="col-12 page-title mt-4 mb-3">
                  <h6>Statistic of All Time</h6>
                  <div className="col-12 d-flex p-0 flex-wrap">
                    <VerifiedAndUnverifiedCount />
                    <TotalUsers />

                    <TotalRevenue />

                    <TotalDirectory />
                  </div>
                </div>

                <div className="card-group mt-1 d-none d-md-flex col-12" id="group-card">
                  <TotalSignUps />
                  <TotalRevenueGraph />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  //     count: state.userscount.userscount,
  //     directorycount: state.directorycount.directorycount,
  //     auth: state.auth.auth,
  user: state.user.user,
  //     unverified: state.unverified.unverified,
  //   verified: state.verified.customers,
});

export default connect(
  mapStateToProps,
  {
    getWificallUsersCount,
    getDirectoryCount,
    getLoggedInUser,
    getUser,
    getUnVerifiedCustomers,
    getVerifiedCustomers,
  },
)(WifiCallOverview);
