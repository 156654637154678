import React from 'react';
import useStates from '../../../../../hooks/shared/locations/states/useStates';
import useForm from '../../../../../hooks/shared/custom/form/no-reload/useForm';
import { API_URL } from '../../../../../utils/config';
import { ghanaCities } from '../../../../../utils/states/ghana';
import Label from '../../../shared/forms/Label';
import CustomInput from '../../../shared/forms/CustomInput';
import CustomButton from '../../../shared/forms/CustomButton';
import TextArea from '../../../shared/forms/TextArea';

const CreateCustomerForm = () => {
  const { states } = useStates();

  const { loadingForm, onChange, formValue, onSubmit } = useForm(
    `${API_URL}/users`,
    {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      country: '',
      address: '',
      plan: 'home',
      status: 'user',
      comment: 'account creation by customer support',
    },
    'Customer Created!',
  );
  const { firstName, lastName, email, phoneNumber, country, address } = formValue;
  const displayNigerianStates = () => {
    if (states.length) {
      return states.map((st) => <option value={st.name}>{st.name}</option>);
    }
    return 'no states';
  };
  const displayGhanaRegions = () => {
    return ghanaCities.map((r) => <option value={r}>{r}</option>);
  };
  const displayOptions = () => {
    if (country === 'Ghana') {
      return (
        <div className="col-md-12">
          <Label name="Region" classname="copy-font p-0 m-0" />
          <select className="form-control col-md-6" name="state" onChange={onChange}>
            <option>-- Region --</option>
            {displayGhanaRegions()}
          </select>
        </div>
      );
    }

    return (
      <div className="col-md-12">
        <Label name="State" classname="copy-font p-0 m-0" />
        <select className="form-control col-md-6" name="state" onChange={onChange}>
          <option>-- State --</option>
          {displayNigerianStates()}
        </select>
      </div>
    );
  };
  const validateSubmit = () => {
    return firstName.length > 0 && lastName.length && email.length > 0 && phoneNumber.length > 0 && address.length > 0;
  };

  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-4">
          <div className="container-fluid col-lg-7">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
              className="form-horizontal mt-5"
              data-testid="submit-form"
              id="form"
            >
              <div className="form-group text-left">
                <div className="col-md-12 mb-5 d-flex p-0">
                  <div className="col-md-12">
                    <Label name="First Name" classname="copy-font p-0 m-0" />
                    <CustomInput
                      classname="form-control col-md-6 input"
                      name="firstName"
                      value={firstName}
                      onChange={onChange}
                      placeholder="Enter User First name"
                      readOnly={false}
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-md-12 mb-5 d-flex p-0">
                  <div className="col-md-12">
                    <Label name="Last Name" classname="copy-font p-0 m-0" />
                    <CustomInput
                      classname="form-control col-md-6 input"
                      name="lastName"
                      value={lastName}
                      onChange={onChange}
                      placeholder="Enter User Last name"
                      readOnly={false}
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-md-12 mb-5 d-flex p-0">
                  <div className="col-md-12">
                    <Label name="email" classname="copy-font p-0 m-0" />
                    <CustomInput
                      classname="form-control col-md-6 input"
                      name="email"
                      value={email}
                      onChange={onChange}
                      placeholder="Enter User Email"
                      readOnly={false}
                      type="email"
                    />
                  </div>
                </div>
                <div className="col-md-12 mb-5 d-flex p-0">
                  <div className="col-md-12">
                    <Label name="Phone Number" classname="copy-font p-0 m-0" />
                    <CustomInput
                      classname="form-control col-md-6 input"
                      name="phoneNumber"
                      value={phoneNumber}
                      onChange={onChange}
                      placeholder="Enter User Phone Number"
                      readOnly={false}
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-md-12 mb-5 d-flex p-0">
                  <div className="col-md-12">
                    <Label name="Country" classname="copy-font p-0 m-0" />
                    <select className="form-control col-md-6" name="country" onChange={onChange}>
                      <option>-- Country --</option>
                      <option value="Nigeria">Nigeria</option>
                      <option value="Ghana">Ghana</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-12 mb-5 d-flex p-0">{displayOptions()}</div>

                <div className="col-md-12 mb-5 d-flex p-0">
                  <div className="col-md-12">
                    <Label name="Address" classname="copy-font p-0 m-0" />
                    <TextArea
                      classname="form-control col-md-6 input"
                      name="address"
                      onChange={onChange}
                      value={address}
                      placeholder="address of customer"
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 mt-5">
                {loadingForm ? (
                  <CustomButton classname="btn btn-green" name="Creating..." disabled={loadingForm} />
                ) : (
                  <CustomButton classname="btn btn-green" name="Submit" disabled={!validateSubmit()} />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCustomerForm;
