import React from 'react';
import BusinessDevelopmentNavBar from '../../../../layouts/business-development/BusinessDevelopmentNavBar';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import Payslip from '../../../shared/payslip/shared/Payslip';

const BusinessDevelopmentPayslip = () => {
  const { profile } = useUser();
  const { firstName } = profile;
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <BusinessDevelopmentNavBar currentPage="Payslip" position={firstName} />
        <Payslip />
      </main>
    </div>
  );
};

export default BusinessDevelopmentPayslip;
