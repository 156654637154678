import React, { Component } from 'react';
import moment from 'moment';

class LeadsDetails extends Component {
  render() {
    const { lead } = this.props;

    return (
      <div
        id="leadsdetails"
        className="modal fade in"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center" id="myModalLabel">
                {lead.firstName}'s Details
              </h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
            </div>
            <div className="modal-body detail" style={{ padding: '0', textAlign: 'justify' }}>
              <div className="row">
                <div className="col-lg-12 d-flex">
                  <div className="col-lg-4 detail-label1">
                    <label id="fn">First Name</label>
                    <label id="ln">Last Name</label>
                    <label id="gender">Gender</label>
                  </div>

                  <div className="col-lg-8 detail-result1">
                    <p className="mt-4 mb-1">{lead.firstName}</p>
                    <p className="mb-1">{lead.lastName}</p>
                    {lead.gender ? <p className="mb-1">{lead.gender.toUpperCase()}</p> : <p className="mb-1">N/A</p>}
                  </div>
                </div>
                <hr />
                <div className="col-lg-12 d-flex">
                  <div className="col-lg-4 detail-label2">
                    <label id="phone1">Phone number </label>
                    {/*<label id="phone2">Phone number 2</label>*/}
                    <label id="email1">Email Address </label>
                    {/*<label id="email2">Email Address 2</label>*/}
                    <label id="address">Address</label>
                  </div>

                  <div className="col-lg-8 detail-result2">
                    <p className="mt-4 mb-1">{lead.phoneNumber}</p>
                    <p className="mb-1">{lead.email}</p>
                    {lead.address ? <p className="mb-1 mt-2">{lead.address}</p> : <p className="mb-1 mt-2">N/A</p>}
                  </div>
                </div>

                {/*<div className="col-lg-12 d-flex">
                  <div className="col-lg-4 detail-label3">
                    <label id="plan">Plan</label>
                    <label id="source">Source</label>
                    <label id="notes">Notes</label>
                  </div>

                  <div className="col-lg-8 detail-result3">
                    <p className="mt-4 mb-1">{log.plan}</p>
                    <p className="mb-1">{log.source}</p>
                    {log.note ? <p className="mb-1">{log.note}</p> : <p className="mb-1">N/A</p> }
                  </div>
                </div>*/}

                <div className="col-lg-12 d-flex">
                  <div className="col-lg-4 detail-label4">
                    {/*<label id="recorded">Recorded By</label>*/}
                    <label id="date">Date</label>
                  </div>

                  <div className="col-lg-8 detail-result4">
                    {/*<p className="mt-4 mb-1">Michael Ezeh</p>*/}
                    <p className="mt-4 mb-1">{moment(lead.created_at).format('YY-MM-DD')}</p>
                  </div>
                </div>

                {/*<div className="col-lg-12 text-center p-3">
                                    <button className="btn btn-second">Edit</button>
                                </div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LeadsDetails;
