import axios from 'axios';
import { toast } from 'react-toastify';
import { useReducer } from 'react';
import CustomerRevenueReducer from '../../../reducers/business-development/customer-revenue/CustomerRevenueReducer';
import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';
import { GET_CUSTOMER_REVENUE } from '../../../constants/types/business-development/customer-revenue/CustomerRevenueTypes';
import { GET_CUSTOMER_REVENUE_URL } from '../../../services/api/business-development/customer-revenue/CustomerRevenueUrl';

const useCustomerRevenue = () => {
  const [{ items, totalPages, total, currentPage, allPages, isLoading }, dispatch] = useReducer(
    CustomerRevenueReducer,
    {
      items: [],
      payment: [],
      total: '',
      totalPages: '',
      allPages: '',
      currentPage: '',
      isLoading: false,
    },
  );

  const getCustomerRevenue = async (data) => {
    dispatch({ type: LOADING_STARTS });
    try {
      const response = await axios.post(`${GET_CUSTOMER_REVENUE_URL}`, data);
      if (response.data) {
        console.log(response.data.total);
        dispatch({
          type: GET_CUSTOMER_REVENUE,
          payload: {
            total: response.data.total,
            items: response.data.items,
            totalPages: response.data.total_records,
            allPages: response.data.total_pages,
            currentPage: response.data.current_page,
          },
        });
      }
    } catch (e) {
      toast.info('Something went wrong!');
      if (e.response) {
        dispatch({ type: LOADING_STOPS });
      }
    }
  };

  return {
    getCustomerRevenue,
    items,
    total,
    totalPages,
    allPages,
    currentPage,
    isLoading,
  };
};

export default useCustomerRevenue;
