import { useState, useReducer, useEffect } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { toast } from 'react-toastify';
import { customersReducer } from '../../../reducers/field-support/customers/customersReducer';
import * as loadTypes from '../../../constants/types/shared/LoadingTypes';
import * as editURL from '../../../services/api/field-support/customers/CustomersUrl';
import * as load from '../../../constants/types/field-support/FieldSupportTypes';
import * as url from '../../../services/api/shared/customers/CustomersUrl';
import * as customerTypes from '../../../constants/types/shared/customers/CustomerTypes';
import { ACCOUNT_RETRIEVALS_DECISION_URL } from '../../../services/api/procurement/retrievals/RetrievalsURL';

// eslint-disable-next-line import/prefer-default-export
export const useCustomer = () => {
  const accountState = {
    type: '',
    first: '',
    last: '',
  };
  const [mac, setMac] = useState('');
  const [email, setEmail] = useState('');
  const [account, setAccount] = useState(accountState);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loader, setLoader] = useState(false);
  const [loaders, setLoaders] = useState(false);
  const [address, setAddress] = useState('');

  const [
    {
      isLoading,
      isLoadingEmail,
      isLoadingName,
      isLoadingPhone,
      isLoadingAddress,
      cleaning,
      unverified,
      fetching,
      pages,
    },
    dispatch,
  ] = useReducer(customersReducer, {
    isLoading: false,
    isLoadingEmail: false,
    isLoadingName: false,
    isLoadingPhone: false,
    isLoadingAddress: false,
    cleaning: false,
    unverified: [],
    pages: 0,
  });

  const editMacChange = (e) => {
    const { value } = e.target;
    setMac(value);
  };

  const editEmailChange = (e) => {
    const { value } = e.target;
    setEmail(value);
  };

  const editAddressChange = (e) => {
    const { value } = e.target;
    setAddress(value);
  };

  const editNameChange = (e) => {
    const { name, value } = e.target;

    setAccount({
      ...account,
      [name]: value,
    });
  };

  const editPhoneChange = (e) => {
    const { value } = e.target;
    setPhoneNumber(value);
  };

  const editMacDetails = async (edit) => {
    dispatch({
      type: loadTypes.LOADING_STARTS,
    });

    try {
      const response = await axios.patch(`${editURL.EDIT_MAC_ID_URL}`, edit, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      const { data } = response.data;
      if (data.crm === true && data.degt === true && data.status === 'done' && data.updateegt === true) {
        toast.success('User mac id updated!');
        dispatch({
          type: loadTypes.LOADING_STOPS,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error(
          `You are not authorized to perfrom this operation.\n Ensure the radio has been cleaned before changing MAC ID.`,
        );
        dispatch({
          type: loadTypes.LOADING_STOPS,
        });
      }

      // if()
    } catch (e) {
      toast.error(
        `You are not authorized to perform this operation.\n Ensure the radio has been cleaned before changing MAC ID.`,
      );
      // console.log(e.response.data.message);
      if (e) {
        dispatch({
          type: loadTypes.LOADING_STOPS,
        });
      }
    }
  };

  const editFibreMacDetails = async (edit) => {
    setLoaders(true);

    try {
      const response = await axios.patch(`${editURL.EDIT_FIBREMAC_ID_URL}`, edit, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      const { data } = response.data;
      if (data.crm === true && data.degt === true && data.status === 'done' && data.updateegt === true) {
        toast.success('User mac id updated!');
        setLoaders(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error(
          `You are not authorized to perfrom this operation.\n Ensure the radio has been cleaned before changing MAC ID.`,
        );
        setLoaders(false);
      }

      // if()
    } catch (e) {
      toast.error(
        `You are not authorized to perform this operation.\n Ensure the radio has been cleaned before changing MAC ID.`,
      );
      // console.log(e.response.data.message);
      if (e) {
        dispatch({
          type: loadTypes.LOADING_STOPS,
        });
      }
    }
  };
  const editRetrievedDetails = async (data) => {
    setLoader(true);

    try {
      const response = await axios.get(`${editURL.EDIT_RETRIEVED_URL}?mac=${data.mac}&email=${data.email}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data) {
        toast.success('User updated successfully!');
        setLoader(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }

      // if()
    } catch (e) {
      toast.error(e.response.data.message);
      // console.log(e.response.data.message);
      if (e) {
        setLoader(false);
      }
    }
  };

  const editEmailDetails = async (info) => {
    dispatch({
      type: load.LOADING_EMAIL_STARTS,
    });
    try {
      const response = await axios.patch(`${editURL.EDIT_EMAIL_URL}`, info, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      const { data } = response.data;

      if (data.updatedcrm === true && data.updatedfa === true && data.updatedgt === true) {
        toast.success(`User's email updated successfully!`);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        dispatch({
          type: load.LOADING_EMAIL_STOPS,
        });
      } else {
        toast.info(`Unable to change user's email!`);
        dispatch({
          type: load.LOADING_EMAIL_STOPS,
        });
      }
    } catch (e) {
      toast.info(e.response.data.errors[0].message);
      dispatch({
        type: load.LOADING_EMAIL_STOPS,
      });
    }
  };

  const editNameDetails = async (value) => {
    dispatch({
      type: load.LOADING_NAME_STARTS,
    });
    try {
      const response = await axios.patch(`${editURL.EDIT_NAME_URL}`, value, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      const { data } = response.data;
      if (data.updatedcrm === true && data.updatedfa === true && data.updatedfa2 === true && data.updatedgt === true) {
        toast.success(`User name updated successfully!`);
        console.log(data);
        dispatch({ type: load.LOADING_NAME_STOPS });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        dispatch({ type: load.LOADING_NAME_STOPS });
        toast.info(`Unable to change user's name`);
      }
    } catch (error) {
      if (error) {
        dispatch({
          type: load.LOADING_NAME_STOPS,
        });
        toast.info(error.response.data.errors[0].message);
      }
    }
  };

  const editPhoneDetails = async (value) => {
    dispatch({ type: load.LOADING_PHONE_STARTS });

    try {
      const response = await axios.patch(`${editURL.EDIT_PHONE_NUMBER_URL}`, value, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      const { data } = response.data;

      if (data.status === true) {
        toast.success(`User phone number updated successfully!`);
        dispatch({ type: load.LOADING_PHONE_STOPS });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        dispatch({ type: load.LOADING_PHONE_STOPS });
        toast.info(`Unable to change user's phone number`);
      }
    } catch (error) {
      dispatch({ type: load.LOADING_PHONE_STOPS });
      toast.info(error.response.data.errors[0].message);
    }
  };

  const editAddressDetails = async (value) => {
    dispatch({
      type: load.LOADING_ADDRESS_STARTS,
    });
    try {
      const response = await axios.patch(`${editURL.EDIT_ADDRESS_URL}`, value, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      const { data } = response.data;

      if (data.updatefa === true && data.updatefa2 === true) {
        toast.success('User address successfully changed!');
        dispatch({ type: load.LOADING_ADDRESS_STOPS });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (e) {
      if (e) {
        toast.error(`Unable to change user's address`);
        dispatch({ type: load.LOADING_ADDRESS_STOPS });
      }
    }
  };

  // ifferent endpoints , not micheals
  const editMacId = async (data) => {
    dispatch({
      type: loadTypes.LOADING_STARTS,
    });
    try {
      const response = await axios.post(`${url.CHANGE_CUSTOMER_MAC_WITH_LEGACY_URL}`, data, {
        'Content-Type': 'application/x-www-form-urlencoded',
      });
      if (response.data) {
        toast.info('success');
      }
    } catch (e) {
      if (e) {
        toast.error(e.response.data.message);
        dispatch({
          type: loadTypes.LOADING_STOPS,
        });
      }
    }
  };

  const cleanRadio = async (data, searchForUserReload, option, search, onClose) => {
    dispatch({
      type: customerTypes.CLEANING_STARTS,
    });
    try {
      const response = await axios.post(`${url.CLEAN_CUSTOMER_RADIO_URL}`, data);
      if (response.data.status === false) {
        toast.error(response.data.message);
        dispatch({
          type: customerTypes.CLEANING_STOPS,
        });
      } else {
        toast.success('Radio Cleaned Successfully!');
        dispatch({
          type: customerTypes.CLEANING_STOPS,
        });
        searchForUserReload(option, search);
        onClose();

        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000);
      }
    } catch (e) {
      if (e) {
        toast.info('Something went wrong!');
        dispatch({
          type: customerTypes.CLEANING_STOPS,
        });
      }
    }
  };

  const getUnverifiedCustomers = async () => {
    dispatch({
      type: loadTypes.FETCHING_STARTS,
    });
    try {
      const response = await axios.get(`${url.GET_UNVERIFIED_CUSTOMERS_URL}/1`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: customerTypes.GET_UNVERIFIED_CUSTOMERS,
          payload: {
            users: response.data.users,
            pages: response.data.pages,
          },
        });
      } else {
        dispatch({
          type: loadTypes.FETCHING_STOPS,
        });
      }
    } catch (err) {
      if (err) {
        dispatch({
          type: loadTypes.FETCHING_STOPS,
        });
      }
    }
  };

  const handlePageClick = async (data) => {
    const pageNumber = data.selected + 1;
    dispatch({
      type: loadTypes.FETCHING_STARTS,
    });
    try {
      const response = await axios.get(`${url.GET_UNVERIFIED_CUSTOMERS_URL}/${pageNumber}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data) {
        dispatch({
          type: customerTypes.GET_UNVERIFIED_CUSTOMERS,
          payload: {
            users: response.data.users,
            pages: response.data.pages,
          },
        });
      } else {
        dispatch({
          type: loadTypes.FETCHING_STOPS,
        });
      }
    } catch (e) {
      if (e) {
        dispatch({
          type: loadTypes.FETCHING_STOPS,
        });
      }
    }
  };

  const cleanRetrievedDevice = async (id, cleanRadioData, accountsDecisionData) => {
    dispatch({ type: customerTypes.CLEANING_STARTS });
    try {
      const cleanRadioResponse = await axios.post(`${url.CLEAN_CUSTOMER_RADIO_URL}`, cleanRadioData);
      if (cleanRadioResponse.data.result === true) {
        const accountsDecisionResponse = await axios.patch(
          `${ACCOUNT_RETRIEVALS_DECISION_URL}/${id}`,
          accountsDecisionData,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
          },
        );
        if (accountsDecisionResponse.data[0] === 1) {
          toastr.success('Radio Cleaned Successfully!');
          dispatch({ type: customerTypes.CLEANING_STOPS });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      } else {
        toastr.error(cleanRadioResponse.data.message);
        dispatch({ type: customerTypes.CLEANING_STOPS });
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: customerTypes.CLEANING_STOPS });
    }
  };

  useEffect(() => {
    getUnverifiedCustomers();
  }, []);

  return {
    editMacChange,
    mac,
    editMacDetails,
    editFibreMacDetails,
    editRetrievedDetails,
    loader,
    isLoading,
    editEmailDetails,
    email,
    editEmailChange,
    isLoadingEmail,
    editNameDetails,
    isLoadingName,
    editNameChange,
    account,
    editPhoneChange,
    phoneNumber,
    editPhoneDetails,
    isLoadingPhone,
    editAddressChange,
    address,
    isLoadingAddress,
    loaders,
    editAddressDetails,
    editMacId,
    cleanRadio,
    editRetrievedDetails,
    cleaning,
    unverified,
    fetching,
    pages,
    handlePageClick,
    cleanRetrievedDevice,
  };
};
