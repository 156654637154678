import { GET_UNCONFIRMED_EMPLOYEES } from '../../../constants/types/shared/EmployeesTypes';
import {
  GET_CONFIRMED_EMPLOYEES,
  GET_EMPLOYEES_BY_DEPARTMENT,
} from '../../../constants/types/shared/employees/EmployeesTypes';

const initialState = {
  unconfirmedEmployees: [],
  confirmed: [],
  employeesByDepartment: [],
};

export const EmployeesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_UNCONFIRMED_EMPLOYEES:
      return {
        ...state,
        unconfirmedEmployees: action.payload,
      };

    case GET_CONFIRMED_EMPLOYEES:
      return {
        ...state,
        confirmed: action.payload,
      };

    case GET_EMPLOYEES_BY_DEPARTMENT:
      return {
        ...state,
        employeesByDepartment: action.payload,
      };

    default:
      return state;
  }
};

export default EmployeesReducer;
