import React, { useContext } from 'react';
// import SearchNocSheets from '../SearchNocSheets';
import AccessPointConnectionTable from './AccessPointConnectionTable';
import AddAccessPointConnection from './AddAccessPointConnection';
import CustomPagination from '../../../../shared/CustomPagination';
import { AccessPointConnectionContext } from './context/AccessPointConnectionContext';

const AccessPointConnectionBody = () => {
  const { loading, data, paginateChange, pages } = useContext(AccessPointConnectionContext);
  const pageChange = (params) => {
    const { selected } = params;
    paginateChange(selected + 1);
  };
  return (
    <div className="container">
      <div className="row mt-5">
        {/* <SearchCnMaestro /> */}
        <div className="form-group ml-2">
          <button className="btn btn-green" type="button" data-toggle="modal" data-target="#add-access-point">
            Add Access Point Connection
          </button>
        </div>
        <AddAccessPointConnection />
        <AccessPointConnectionTable data={data} load={loading} />
        {!loading && (
          <CustomPagination
            pagesCount={pages}
            marginPagesDisplayed={5}
            pageRangeDisplayed={5}
            onPageChange={pageChange}
          />
        )}
      </div>
    </div>
  );
};

export default AccessPointConnectionBody;
