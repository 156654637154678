/* eslint-disable import/prefer-default-export */
import { API_URL } from '../../../../../utils/config';

export const DEVICE_RETRIEVAL_URL = `${API_URL}/retentionretrieval`;
export const SEARCH_DEVICE_RETRIEVAL_URL = `${API_URL}/retentionretrieval/searchbystatus`;
export const UNINSTALL_URL = `${API_URL}/retentionretrieval/uninstall`;
export const BULK_UNINSTALL_URL = `${API_URL}/retentionretrieval/bulkuninstall`;
export const DEVICE_CONFIRMATION_URL = `${API_URL}/retentionretrieval/confirm`;
export const DELETE_RETRIEVED_DEVICE_URL = `${API_URL}/retentionretrieval/retentiondelete`;
export const PROCUREMENT_DELETE_RETRIEVED_DEVICE_URL = `${API_URL}/retentionretrieval/delete`;
export const PROCUREMENT_RETRIEVAL_URL = `${API_URL}/retrievals`;
