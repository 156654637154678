/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AES } from 'crypto-js';
import RequisitionNotification from '../../staff/shared/layouts/sidebar/manager-and-executives/RequisitionNotification';

const AdminDepartmentSideBar = () => {
  const departmentId = sessionStorage.getItem('departmentId');
  const officialEmail = sessionStorage.getItem('officialEmail');
  const role_id = sessionStorage.getItem('roleId');
  const detailsState = {
    name: 'React',
    messagetoken: sessionStorage.getItem('token'),
    messagerole: sessionStorage.getItem('roleId'),
    messagedepartment: sessionStorage.getItem('departmentId'),
    secret: 'Wifirocks123',
    cipher: '',
  };
  const [details, setDetails] = useState(detailsState);
  const { name, messagetoken, messagerole, messagedepartment, secret, cipher } = details;

  const obj = {
    login: messagetoken,
    pass: messagerole,
    department: messagedepartment,
  };

  const ciph = AES.encrypt(JSON.stringify(obj), secret).toString();

  return (
    <div className="s-layout__sidebar">
      <Link className="s-sidebar__trigger" to="#">
        <i className="fa fa-bars mr-3" />
      </Link>

      <nav className="s-sidebar__nav">
        <Link className="navbar-brand-logo" to="/business-development">
          <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971047/Tizeti-Logo.png" alt="homepage" />
        </Link>

        <ul className="p-0 mt-3">
          <li className="s-sidebar__nav-link">
            <Link to="/administration" target="_self">
              <span className="mr-2">
                <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971013/Group-3.png" alt="img" />
              </span>
              <small>Overview</small>
            </Link>
          </li>

          <li className="s-sidebar__nav-link">
            {' '}
            <a href={`https://os.tizeti.com/main/#/overview?ciph=${ciph}`} target="_blank" className="make-bold">
              <span>
                <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923169/configr_d.fw.png" alt="img" />
              </span>
              <small>New system (Beta v2.0)</small>
            </a>
          </li>

          <li className="s-sidebar__nav-link">
            <Link to="/shared/employees" target="_self">
              <span>
                <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-6.png" alt="img" />
              </span>
              Employees 
            </Link>
          </li>

          <li className="s-sidebar__nav-link">
            <Link to="/administration/leave-days" target="_self">
              <span>
                <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-6.png" alt="img" />
              </span>
              <span style={{ marginLeft: 4 }}>Leave days</span>
            </Link>
          </li>

          {Number(role_id) > 2 && (
            <>
              {/* <span className="s-sidebar__nav-link d-none d-md-block" data-toggle="collapse" data-target="#active">
              <Link to="#" target="_self">
                  <span>
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1555319728/recruitment_1.svg" alt="img" />
                  </span>
                  RECRUIT
                </Link>
              </span> */}

              <span className="s-sidebar__nav-link d-none d-md-block" data-toggle="collapse" data-target="#active">
                <li className="s-sidebar__nav-link">
                  <Link to="#" target="_self">
                    <span className="mr-2">
                      <img
                        src="https://res.cloudinary.com/teewhy/image/upload/v1555319728/recruitment_1.svg"
                        alt="img"
                      />
                    </span>
                    RECRUIT
                  </Link>
                </li>
              </span>
              <span id="active" className="collapse base-li top-bottom">
                <li className="s-sidebar__nav-link">
                  <Link to="/administration/recruitment">Recruitment</Link>
                </li>

                <li className="s-sidebar__nav-link">
                  <Link to="/administration/all-applications">All Applications</Link>
                </li>

                <li className="s-sidebar__nav-link">
                  <Link to="/administration/shortlisted">Shortlisted</Link>
                </li>
              </span>
            </>
          )}

          <li className="s-sidebar__nav-link">
            <Link to="/administration/inventories" target="_self">
              <span>
                <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971015/Group-7.png" alt="img" />
              </span>
              Inventory
            </Link>
          </li>

          <li className="s-sidebar__nav-link">
            <Link to="/administration/vehicles" target="_self">
              <span>
                <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971011/Group.png" alt="img" />
              </span>
              Vehicles
            </Link>
          </li>

          <li className="s-sidebar__nav-link">
            <Link to="/administration/purchase-order" target="_self" className="make-bold">
              <span>
                <img src="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg" alt="img" />
              </span>
              <small>Purchase Order</small>
            </Link>
          </li>

          <li className="s-sidebar__nav-link">
            <Link to="/administration/requests" target="_self" className="make-bold">
              <span>
                <img src="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg" alt="img" />
              </span>
              <small>Requests</small>
            </Link>
          </li>

          <li className="s-sidebar__nav-link">
            <Link to="/administration/payslip" target="_self" className="make-bold">
              <span>
                <img src="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg" alt="img" />
              </span>
              <small>Payslip</small>
            </Link>
          </li>

          <li className="s-sidebar__nav-link">
            <Link to="/administration/kpi-monitor" target="_self" className="make-bold">
              <span>
                <img src="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg" alt="img" />
              </span>
              KPI Monitor
            </Link>
          </li>

          <li className="s-sidebar__nav-link">
            <Link to="/administration/requisitions" target="_self" className="d-flex">
              <span>
                <img src="https://res.cloudinary.com/teewhy/image/upload/v1550844527/requisition.png" alt="img" />
              </span>
              Requisition
              <RequisitionNotification />
            </Link>
          </li>

          <li className="s-sidebar__nav-link">
            <Link to="/administration/escalate" target="_self" className="text-danger make-bold">
              <span className="mr-2">
                <img src="https://res.cloudinary.com/teewhy/image/upload/v1557141046/Schedule.png" alt="img" />
              </span>
              Escalate
            </Link>
          </li>

          <li className="s-sidebar__nav-link">
            <Link to="/administration/report-issue" target="_self" className="text-danger make-bold">
              <span>
                <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923430/r_d-envelop.fw.png" alt="img" />
              </span>
              Report an Issue
            </Link>
          </li>

          <li className="s-sidebar__nav-link">
            <Link to="/administration/learning-portal" target="_self">
              <span className="mr-2">
                <img src="https://res.cloudinary.com/teewhy/image/upload/v1557141046/Schedule.png" alt="img" />
              </span>
              Learning Portal
            </Link>
          </li>

          <li className="s-sidebar__nav-link">
            {Number(role_id) > 2 ? (
              <Link to="/administration/request-feature" target="_self" className="make-bold">
                <span>
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923169/configr_d.fw.png" alt="img" />
                </span>
                Request a Feature
              </Link>
            ) : null}
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default AdminDepartmentSideBar;
