/* eslint-disable no-case-declarations */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useContext } from 'react';
import ReactPaginate from 'react-paginate';
import AppContext from '../../../../context/shared/AppContext';
import DeviceRetrievalTable from './DeviceRetrievalTable';
import SearchDeviceRetrieval from './SearchDeviceRetrieval';
import CreateDeviceRetrieval from './CreateDeviceRetrieval';
import PageDataCount from './PageDataCount';

const DeviceRetrievalBody = () => {
  const { pages, details, getDeviceRetrieval, searchForUser } = useContext(AppContext);

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    if (details.status) searchForUser(selectedPage);
    else getDeviceRetrieval(selectedPage);
  };

  return (
    <div className="mt-5 p-3">
      <div className="container-fluid pt-4">
        <div className="row">
          <div className="p-0 col-12 d-flex justify-content-between">
            <div className="d-flex">
              <SearchDeviceRetrieval />
              <CreateDeviceRetrieval />
            </div>
            <PageDataCount />
          </div>
          <DeviceRetrievalTable />
          {pages > 1 ? (
            <ReactPaginate
              previousLabel="previous"
              nextLabel="next"
              breakLabel="..."
              breakClassName="break-me"
              pageCount={pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName="pagination"
              activeClassName="active"
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default DeviceRetrievalBody;
