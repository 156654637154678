/* eslint-disable camelcase */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getLoggedInUser } from '../../../../actions/user/UserActions';
import { addFeatureRequest } from '../../../../actions/research-and-development/feature-requests/FeatureRequestsActions';

class AddRequestFeature extends Component {
  static propTypes = {
    department: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    getLoggedInUser: PropTypes.func.isRequired,
    addFeatureRequest: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      subject: '',
      details: '',
      load: false,
    };

    this.addRequest = this.addRequest.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.props.getLoggedInUser();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.load === 'success') {
      this.setState({
        load: false,
        subject: '',
        details: '',
      });
    }
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  addRequest = (e) => {
    this.setState({
      load: true,
    });

    e.preventDefault();
    const { department_id } = this.props.department;
    const { subject, details } = this.state;

    if (department_id) {
      const data = {
        subject,
        details,
        department_id,
      };

      this.props.addFeatureRequest(data);
    } else return null;
  };

  submitForm() {
    const { subject, details } = this.state;

    return subject.length > 0 && details.length > 0;
  }

  render() {
    const { subject, details, load } = this.state;

    const isEnabled = this.submitForm();

    return (
      <div className="row">
        <div className="container-fluid col-lg-7">
          <form onSubmit={this.addRequest} className="form-horizontal mt-5">
            <div className="form-group text-left">
              <div className="col-md-12 mb-5 d-flex p-0">
                <div className="col-lg-6">
                  <label className="copy-font p-0 m-0">Subject</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Subject"
                    name="subject"
                    value={subject}
                    onChange={this.onChange}
                  />
                </div>
              </div>

              <div className="col-md-12 mb-5 d-flex">
                <div>
                  <label className="copy-font p-0 mb-0">Details</label>
                  <textarea
                    className="form-control"
                    name="details"
                    value={details}
                    onChange={this.onChange}
                    rows="5"
                    cols="50"
                    placeholder="Details of the Feature"
                  />
                </div>
              </div>
            </div>

            {load ? (
              <div className="col-12 mt-5">
                <button className="btn btn-green" disabled type="button">
                  <i className="fa fa-spinner fa-spin" style={{ fontSize: '24px' }} />
                </button>
              </div>
            ) : (
              <div className="col-12 mt-5">
                <button className="btn btn-green" disabled={!isEnabled} type="button">
                  Submit
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  department: state.auth.auth,
  load: state.addFeature.addFeature,
});

export default connect(mapStateToProps, {
  getLoggedInUser,
  addFeatureRequest,
})(AddRequestFeature);
