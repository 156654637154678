import axios from 'axios';
import { toast } from 'react-toastify';
import {
  GET_AGENT_SCORE_URL,
  GET_EMPLOYEES_IN_CUSTOMER_SUPPORT_URL,
  SCORE_AGENT_URL,
} from '../../../services/api/customer-support/quality-assurance/QualityAssuranceUrl';
import { GET_EMPLOYEES_BY_DEPARTMENT } from '../../../constants/types/shared/employees/EmployeesTypes';
import { GET_USER_SCORE } from '../../../constants/types/customer-support/CustomerSupportTypes';

export const getEmployeesInCustomerSupport = () => async (dispatch) => {
  try {
    const response = await axios.get(`${GET_EMPLOYEES_IN_CUSTOMER_SUPPORT_URL}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data.status === 'success') {
      dispatch({
        type: GET_EMPLOYEES_BY_DEPARTMENT,
        payload: response.data.data,
      });
    }
  } catch (e) {
    // console.log(e.response);
  }
};

export const scoreAgent = (data) => async (dispatch) => {
  try {
    const response = await axios.post(`${SCORE_AGENT_URL}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data.id) {
      toast.success('Agent Scored');

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  } catch (e) {
    toast.info('Something went wrong');
  }
};

export const getAgentScore = (data, id) => async (dispatch) => {};

export const getScore = (id, name, data) => async (dispatch) => {
  // if(Object.entries(data).length === 0 && data.constructor === Object)

  try {
    const response = await axios.post(`${GET_AGENT_SCORE_URL}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      dispatch({
        type: GET_USER_SCORE,
        payload: response.data,
      });
    }
  } catch (e) {
    if (e) {
      toast.info(`Something went wrong while fetching ${name} score`);
    }
  }
};
