import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import toastr from 'toastr';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AdminDeptLogo from '../../../../layouts/admin-department/AdminDeptLogo';
import AdminSideBar from '../../../../layouts/admin-department/AdminDepartmentSideBar';
import DepartmentTable from './DepartmentTable';
import { ADMIN_DEPARTMENT } from '../../../../../utils/config';
import { getUser } from '../../../../../actions/shared/user/UserActions';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import Logout from '../../../shared/auth/Logout';

class Departments extends Component {
  static propTypes = {
    getUser: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.checkDepartment();
    this.props.getUser();
  }

  componentWillReceiveProps() {
    this.checkDepartment();
  }

  checkDepartment = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.department_id !== `${ADMIN_DEPARTMENT}`) {
        this.props.history.push('/');

        sessionStorage.setItem('token', '');
        sessionStorage.clear();

        toastr.info('You are not allowed to view this page');
      }
    } catch (e) {
      console.log(e.response);
    }
  };

  render() {
    const { user } = this.props;
    const { firstName } = user;

    return (
      <div className="main-wrapper">
        <header className="topbar shade bg-white fixed-top">
          <nav className="navbar navbar-expand-lg navbar-light top-navbar fixed-top">
            <AdminDeptLogo />
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse mt-3" id="navbarText">
              <ul className="navbar-nav">
                <li className="nav-item active">
                  <Link className="nav-link" to="#">
                    <h3>
                      Departments<span className="sr-only">(current)</span>
                    </h3>
                  </Link>
                </li>
              </ul>
              <span className="navbar-text">
                <h6>
                  <i>{firstName}</i>
                  <Logout />
                </h6>
              </span>
            </div>
          </nav>
        </header>

        <div className="all-sides col-lg-12 col-md-12">
          <AdminSideBar />

          <div className="page-wrapper col-lg-10 col-md-10">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 col-md-12 mt-3 mb-3 d-flex">
                  <form className="form-inline inner-addon left-addon" action="javascript:void(0)">
                    <input
                      className="form-control mr-sm-2 empty mt-1"
                      id="iconified"
                      type="text"
                      placeholder="Search"
                    />
                    <span className="fa fa-search" />
                  </form>

                  <div>
                    <button className="btn btn-green" type="submit" data-toggle="modal" data-target="#dept">
                      Add Department
                    </button>
                  </div>
                  {/* <AddDepartment/> */}
                </div>

                <div className="card x-panel">
                  <div className="table-responsive content col-lg-12 col-md-12 col-sm-12">
                    <table className="table col-lg-12 col-md-12 col-sm-12">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Department</th>
                          {/* <th>Manager</th> */}
                          {/* <th>Lead</th> */}
                          {/* <th>Email</th> */}
                          <th>Date Created</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <DepartmentTable />
                    </table>
                  </div>
                </div>

                {/* <AdminPaginate/> */}

                {/*                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={450}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange}
                        /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect(
  mapStateToProps,
  { getUser },
)(Departments);
