import React, { Component } from 'react';
import axios from 'axios';
import AssignVehicle from '../../vehicle-tracking/AssignVehicle';
import { API_URL } from '../../../../../utils/config';

class VehicleTracking extends Component {
  constructor(props) {
    super(props);

    this.state = {
      devices: [],
      id: '',
      deviceId: '',
      locations: [],
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      infoWindow: {},
    };

    this.getDevice = this.getDevice.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  getDevice = (e) => {
    e.preventDefault();

    const { id } = this.state;
    const data = {
      device: id,
    };

    // console.log(data);
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };

    const url = `${API_URL}/tracker/trackone/${id}?page=1`;

    axios.get(url, config).then((res) => {
      if (res.data.rows) {
        this.setState(
          {
            locations: res.data.rows,
          },
          this.renderMap(),
        );
      }
      //   console.log(res.data);
    });
  };

  getLocations() {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };

    axios.get(`${API_URL}/tracker/tracks/1`, config).then((res) => {
      //   console.log(res.data);

      this.setState(
        {
          locations: res.data,
        },
        this.renderMap(),
      );
    });
  }

  getDevices() {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };

    axios.get(`${API_URL}/tracked_device/trackingdevices/1`, config).then((res) => {
      //   console.log(res.data.rows);
      if (res.data.rows) {
        this.setState({
          devices: res.data.rows,
        });
      }
    });
  }

  renderMap = () => {
    loadScript(
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyD1DrDBUd6GNL2EIBCxK-K0OjkTny8kbuA&libraries=places&callback=initMap',
    );
    window.initMap = this.initMap;
  };

  initMap = () => {
    const map = new window.google.maps.Map(document.getElementById('map'), {
      zoom: 11,
      center: { lat: 6.548653, lng: 3.54882 },
      mapTypeId: window.google.maps.MapTypeId.TERRAIN,
    });

    const flightPlanCoordinatesss = [];
    this.state.locations.forEach((location) => {
      flightPlanCoordinatesss.push({
        lat: Number(location.latitude),
        lng: Number(location.longitude),
      });
    });

    const flightPath = new window.google.maps.Polyline({
      path: flightPlanCoordinatesss,
      geodesic: true,
      strokeColor: '#FF0000',
      strokeOpacity: 4.0,
      strokeWeight: 2,
    });

    flightPath.setMap(map);
  };

  showDevices() {
    if (this.state.devices) {
      return (
        <select className="form-control" onChange={this.onChange} name="id">
          <option value="">Select Device</option>
          {this.state.devices.map((device) => (
            <option key={device.id} value={device.id}>
              {device.phoneNumber}
            </option>
          ))}
        </select>
      );
    }
    return null;
  }

  componentDidMount() {
    this.getDevices();
    this.getLocations();
  }

  render() {
    return (
      <div className="col-12 after-summary">
        <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-3">
          <div className="col-12 mb-3 p-0 d-flex flex-wrap">
            <form className="col-lg-5 form-inline d-flex" onSubmit={this.getDevice}>
              <div className="mr-auto">
                <label className="copy-font m-0 p-0">Driver's Name</label>
                {this.showDevices()}
              </div>

              {/* <div className="mr-auto"> */}
              {/* <label className="copy-font m-0 p-0">Date of Trip</label> */}
              {/* <input type="date" className="form-control"/> */}
              {/* </div> */}

              <div className="mr-auto">
                <button className="btn btn-green p-2 mt-3" type="button">
                  Show route
                </button>
              </div>
            </form>

            <div className="tab-stuff col-md-7 col-12 p-0 mt-4 justify-content-end d-flex">
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item  mr-4 d-none d-md-flex">
                  <a
                    className="nav-link active"
                    id="pills-profile-tab"
                    data-toggle="pill"
                    href="#pills-profile"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="true"
                  >
                    View All Vehicles{' '}
                  </a>
                </li>

                <li className="nav-item  mr-4 d-sm-flex d-md-none">
                  <a
                    className="nav-link active"
                    id="pills-profile-tab"
                    data-toggle="pill"
                    href="#pills-profile"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="true"
                  >
                    Vehicles{' '}
                  </a>
                </li>

                <li className="nav-item" data-target="#assignvehicle" data-toggle="modal">
                  <a
                    className="nav-link"
                    id="pills-home-tab"
                    data-toggle="pill"
                    href="#"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="false"
                  >
                    Assign Vehicles
                  </a>
                </li>
                <AssignVehicle />
              </ul>
            </div>

            <div className="tab-content col-12 p-0" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div className="col-lg-12 m-0 p-0">
                  <div className="mapouter">
                    <div className="gmap_canvas">
                      <div id="map" style={{ height: '550px' }} />
                      {/* <iframe width="1145" height="600" id="gmap_canvas" src="https://maps.google.com/maps?q=Lagos&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe> */}
                    </div>
                  </div>

                  {/* <div className="col-lg-2 ml-2 p-0" style={{marginTop:"-44%"}}> */}
                  {/* <div className="card shadow border-0"> */}
                  {/* <div style={{backgroundColor:"#1a3f43"}} className="shadow-sm"> */}
                  {/* <h6 className="text-white pl-3 mt-2">Profile</h6> */}
                  {/* </div> */}
                  {/* <div className="p-3" style={{marginTop:"-7%"}}> */}
                  {/* <div> */}
                  {/* <label className="m-0 p-0 copy-font">Drivers Name</label> */}
                  {/* <p className="copy-font mb-1">Bassey Udoh</p> */}
                  {/* </div> */}
                  {/* <div> */}
                  {/* <label className="m-0 p-0 copy-font">Current Location</label> */}
                  {/* <p className="copy-font mb-1">Plot 24, Lekki Ajah expressway, Lagos.</p> */}
                  {/* </div> */}
                  {/* <div> */}
                  {/* <label className="m-0 p-0 copy-font">Vehicle Name</label> */}
                  {/* <p className="copy-font mb-1">Toyota Hiace</p> */}
                  {/* </div> */}
                  {/* <div> */}
                  {/* <label className="m-0 p-0 copy-font">Plate Number</label> */}
                  {/* <p className="copy-font mb-1">AZ 403 VTU</p> */}
                  {/* </div> */}
                  {/* </div> */}
                  {/* </div> */}

                  {/* <div className="card shadow border-0 mt-3"> */}
                  {/* <div style={{backgroundColor:"#1a3f43"}} className="shadow-sm"> */}
                  {/* <h6 className="text-white pl-3 mt-2">Route</h6> */}
                  {/* </div> */}
                  {/* <div className="p-3" style={{marginTop:"-7%"}}> */}
                  {/* <div> */}
                  {/* <label className="m-0 p-0 copy-font">From</label> */}
                  {/* <p className="copy-font mb-1">34, Mushin way, Isolo</p> */}
                  {/* </div> */}
                  {/* <div> */}
                  {/* <label className="m-0 p-0 copy-font">To</label> */}
                  {/* <p className="copy-font mb-1">23, Montegomery road, Yaba</p> */}
                  {/* </div> */}
                  {/* <div> */}
                  {/* <label className="m-0 p-0 copy-font">Date</label> */}
                  {/* <p className="copy-font mb-1">24th June,2018</p> */}
                  {/* </div> */}
                  {/* </div> */}
                  {/* </div> */}
                  {/* </div> */}
                </div>
              </div>
              {/* <div className="tab-pane fade" id="pills-home" role="tabpanel" aria-labelledby="pills-profile-tab">
                                              <AssignVehicle/>
                                          </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function loadScript(url) {
  const index = window.document.getElementsByTagName('script')[0];
  const script = window.document.createElement('script');
  script.src = url;
  script.async = true;
  script.defer = true;
  index.parentNode.insertBefore(script, index);
}

export default VehicleTracking;
