/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-restricted-globals */
/* eslint-disable consistent-return */
import React, { useEffect } from 'react';
import del from '../../../../../assets/img/icons/delete.png';
import { useInstallationsSummary } from '../../../../../hooks/field-support/installations-summary/useInstallationsSummary';
import { useRelocation } from '../../../../../hooks/sales/relocation/useRelocation';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import { ACCOUNTS } from '../../../../../utils/departments/helpers';
import { months } from '../../../../../utils/field-support/installations/helpers';
import AddInstallationTarget from './AddInstallationTarget';
import DeleteInstallationTarget from './DeleteInstallationTarget';

const InstallationsSummary = () => {
  const {
    user: { role_id },
  } = useUser();

  const deptId = sessionStorage.getItem('departmentId');
  const { searchUsingKeyword, monthlyCount } = useRelocation();
  const {
    details,
    lagosMonthlyTarget,
    abujaMonthlyTarget,
    ogunMonthlyTarget,
    enuguMonthlyTarget,
    deltaMonthlyTarget,
    riversMonthlyTarget,
    edoMonthlyTarget,
    oyoMonthlyTarget,
    kanoMonthlyTarget,
    kadunaMonthlyTarget,
    imoMonthlyTarget,
    abiaMonthlyTarget,
    anambraMonthlyTarget,
    crossRiverMonthlyTarget,
    lagosMonthlyCompleted,
    abujaMonthlyCompleted,
    ogunMonthlyCompleted,
    deltaMonthlyCompleted,
    enuguMonthlyCompleted,
    riversMonthlyCompleted,
    edoMonthlyCompleted,
    oyoMonthlyCompleted,
    kanoMonthlyCompleted,
    kadunaMonthlyCompleted,
    imoMonthlyCompleted,
    abiaMonthlyCompleted,
    anambraMonthlyCompleted,
    crossRiverMonthlyCompleted,
    lagosDailyCompleted,
    abujaDailyCompleted,
    ogunDailyCompleted,
    riversDailyCompleted,
    enuguDailyCompleted,
    deltaDailyCompleted,
    edoDailyCompleted,
    oyoDailyCompleted,
    kanoDailyCompleted,
    kadunaDailyCompleted,
    imoDailyCompleted,
    abiaDailyCompleted,
    anambraDailyCompleted,
    crossRiverDailyCompleted,
    monthlyByStatus,
    getDetails,
    getMonthlyInstallationByStatus,
  } = useInstallationsSummary();

  const relocation = () => {
    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    const data = {
      from: firstDay,
      to: today,
    };
    searchUsingKeyword(1, data);
  };

  const getNoLOS = () => {
    const data = { status: 'no_los' };
    getMonthlyInstallationByStatus(data);
  };

  useEffect(() => {
    relocation();
    getNoLOS();
  }, []);

  const lagosMonthlyTargetCount = () => (lagosMonthlyTarget.length ? lagosMonthlyTarget[0].state_target : 0);
  const abujaMonthlyTargetCount = () => (abujaMonthlyTarget.length ? abujaMonthlyTarget[0].state_target : 0);
  const ogunMonthlyTargetCount = () => (ogunMonthlyTarget.length ? ogunMonthlyTarget[0].state_target : 0);
  const riversMonthlyTargetCount = () => (riversMonthlyTarget.length ? riversMonthlyTarget[0].state_target : 0);
  const edoMonthlyTargetCount = () => (edoMonthlyTarget.length ? edoMonthlyTarget[0].state_target : 0);
  const oyoMonthlyTargetCount = () => (oyoMonthlyTarget.length ? oyoMonthlyTarget[0].state_target : 0);
  const deltaMonthlyTargetCount = () => (deltaMonthlyTarget.length ? deltaMonthlyTarget[0].state_target : 0);
  const enuguMonthlyTargetCount = () => (enuguMonthlyTarget.length ? enuguMonthlyTarget[0].state_target : 0);
  const kanoMonthlyTargetCount = () => (kanoMonthlyTarget.length ? kanoMonthlyTarget[0].state_target : 0);
  const kadunaMonthlyTargetCount = () => (kadunaMonthlyTarget.length ? kadunaMonthlyTarget[0].state_target : 0);
  const imoMonthlyTargetCount = () => (imoMonthlyTarget.length ? imoMonthlyTarget[0].state_target : 0);
  const abiaMonthlyTargetCount = () => (abiaMonthlyTarget.length ? abiaMonthlyTarget[0].state_target : 0);
  const anambraMonthlyTargetCount = () => (anambraMonthlyTarget.length ? anambraMonthlyTarget[0].state_target : 0);
  const crossRiverMonthlyTargetCount = () =>
    crossRiverMonthlyTarget.length ? crossRiverMonthlyTarget[0].state_target : 0;

  const lagosPercent = () => {
    const result = (lagosMonthlyCompleted / lagosMonthlyTargetCount()) * 100;
    if (isNaN(result) || result === Infinity) return 0;
    return Math.round(result);
  };

  const abujaPercent = () => {
    const result = (abujaMonthlyCompleted / abujaMonthlyTargetCount()) * 100;
    if (isNaN(result) || result === Infinity) return 0;
    return Math.round(result);
  };
  const ogunPercent = () => {
    const result = (ogunMonthlyCompleted / ogunMonthlyTargetCount()) * 100;
    if (isNaN(result) || result === Infinity) return 0;
    return Math.round(result);
  };
  const riversPercent = () => {
    const result = (riversMonthlyCompleted / riversMonthlyTargetCount()) * 100;
    if (isNaN(result) || result === Infinity) return 0;
    return Math.round(result);
  };
  const edoPercent = () => {
    const result = (edoMonthlyCompleted / edoMonthlyTargetCount()) * 100;
    if (isNaN(result) || result === Infinity) return 0;
    return Math.round(result);
  };
  const oyoPercent = () => {
    const result = (oyoMonthlyCompleted / oyoMonthlyTargetCount()) * 100;
    if (isNaN(result) || result === Infinity) return 0;
    return Math.round(result);
  };

  const deltaPercent = () => {
    const result = (deltaMonthlyCompleted / deltaMonthlyTargetCount()) * 100;
    if (isNaN(result) || result === Infinity) return 0;
    return Math.round(result);
  };
  const enuguPercent = () => {
    const result = (enuguMonthlyCompleted / enuguMonthlyTargetCount()) * 100;
    if (isNaN(result) || result === Infinity) return 0;
    return Math.round(result);
  };
  const kanoPercent = () => {
    const result = (kanoMonthlyCompleted / kanoMonthlyTargetCount()) * 100;
    if (isNaN(result) || result === Infinity) return 0;
    return Math.round(result);
  };
  const kadunaPercent = () => {
    const result = (kadunaMonthlyCompleted / kadunaMonthlyTargetCount()) * 100;
    if (isNaN(result) || result === Infinity) return 0;
    return Math.round(result);
  };
  const imoPercent = () => {
    const result = (imoMonthlyCompleted / imoMonthlyTargetCount()) * 100;
    if (isNaN(result) || result === Infinity) return 0;
    return Math.round(result);
  };
  const abiaPercent = () => {
    const result = (abiaMonthlyCompleted / abiaMonthlyTargetCount()) * 100;
    if (isNaN(result) || result === Infinity) return 0;
    return Math.round(result);
  };
  const anambraPercent = () => {
    const result = (anambraMonthlyCompleted / anambraMonthlyTargetCount()) * 100;
    if (isNaN(result) || result === Infinity) return 0;
    return Math.round(result);
  };
  const crossRiverPercent = () => {
    const result = (crossRiverMonthlyCompleted / crossRiverMonthlyTargetCount()) * 100;
    if (isNaN(result) || result === Infinity) return 0;
    return Math.round(result);
  };

  const dailyCompleted =
    lagosDailyCompleted +
    abujaDailyCompleted +
    ogunDailyCompleted +
    riversDailyCompleted +
    edoDailyCompleted +
    oyoDailyCompleted +
    deltaDailyCompleted +
    kanoDailyCompleted +
    kadunaDailyCompleted +
    imoDailyCompleted +
    abiaDailyCompleted +
    anambraDailyCompleted +
    crossRiverDailyCompleted +
    enuguDailyCompleted;
  const monthlyCompleted =
    lagosMonthlyCompleted +
    abujaMonthlyCompleted +
    ogunMonthlyCompleted +
    riversMonthlyCompleted +
    edoMonthlyCompleted +
    oyoMonthlyCompleted +
    deltaMonthlyCompleted +
    enuguMonthlyCompleted +
    kanoMonthlyCompleted +
    kadunaMonthlyCompleted +
    imoMonthlyCompleted +
    abiaMonthlyCompleted +
    anambraMonthlyCompleted +
    crossRiverMonthlyCompleted;
  const monthlyTarget =
    lagosMonthlyTargetCount() +
    abujaMonthlyTargetCount() +
    ogunMonthlyTargetCount() +
    riversMonthlyTargetCount() +
    edoMonthlyTargetCount() +
    oyoMonthlyTargetCount() +
    deltaMonthlyTargetCount() +
    enuguMonthlyTargetCount() +
    kanoMonthlyTargetCount() +
    kadunaMonthlyTargetCount() +
    imoMonthlyTargetCount() +
    abiaMonthlyTargetCount() +
    anambraMonthlyTargetCount() +
    crossRiverMonthlyTargetCount();

  const deleteButton = (deleteFunction) => {
    return (
      <div>
        <button
          type="submit"
          className="text-center"
          data-target="#deleteModal"
          onClick={deleteFunction}
          data-toggle="modal"
        >
          <span className="view2">
            <img src={del} alt="" data-toggle="tooltip" data-placement="top" title="Delete Target" />
          </span>
        </button>
        <DeleteInstallationTarget details={details} />
      </div>
    );
  };

  return (
    <div className="col-12 p-0 after-summary">
      <div className="row pt-3 mx-2">
        {deptId != ACCOUNTS && (
          <>
            {role_id > 1 && (
              <div className="col-12 mb-3">
                <div className="flex-end">
                  <button className="btn btn-green" type="button" data-toggle="modal" data-target="#add_target">
                    Add Target
                  </button>
                </div>
              </div>
            )}
          </>
        )}
        <AddInstallationTarget />

        <div className="py-3 col-12">
          <ul className="text-uppercase">
            <li>
              {months[new Date().getMonth()]} Target - {monthlyTarget}
            </li>
            <li>Successful Installation - {dailyCompleted}</li>
            <li>TOTAL NUMBER OF INSTALLATION FOR THE MONTH - {monthlyCompleted}</li>
            <li>NO LOS FOR THE MONTH - {monthlyByStatus}</li>
            {/* <li>RELOCATION - </li> */}
            {/* <li>TOTAL NUMBER OF RELOCATION FOR THE MONTH - {monthlyCount} </li> */}
          </ul>
        </div>
        <div className="pb-2 text-center">
          <div>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">State</th>
                  <th scope="col">Daily Completed</th>
                  <th scope="col">Actual Or Monthly Completed</th>
                  <th scope="col">Monthly Target</th>
                  <th scope="col">Percentage</th>
                  {role_id > 1 && <th scope="col">Action</th>}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Lagos</td>
                  <td>{lagosDailyCompleted}</td>
                  <td>{lagosMonthlyCompleted}</td>
                  <td>{lagosMonthlyTargetCount()}</td>
                  <td>{lagosPercent()}%</td>
                  {role_id > 1 && <td>{deleteButton(() => getDetails(lagosMonthlyTarget))}</td>}
                </tr>
                <tr>
                  <td>2</td>
                  <td>Abuja</td>
                  <td>{abujaDailyCompleted}</td>
                  <td>{abujaMonthlyCompleted}</td>
                  <td>{abujaMonthlyTargetCount()}</td>
                  <td>{abujaPercent()}%</td>
                  {role_id > 1 && <td>{deleteButton(() => getDetails(abujaMonthlyTarget))}</td>}
                </tr>
                <tr>
                  <td>3</td>
                  <td>Ogun</td>
                  <td>{ogunDailyCompleted}</td>
                  <td>{ogunMonthlyCompleted}</td>
                  <td>{ogunMonthlyTargetCount()}</td>
                  <td>{ogunPercent()}%</td>
                  {role_id > 1 && <td>{deleteButton(() => getDetails(ogunMonthlyTarget))}</td>}
                </tr>
                <tr>
                  <td>4</td>
                  <td>Rivers</td>
                  <td>{riversDailyCompleted}</td>
                  <td>{riversMonthlyCompleted}</td>
                  <td>{riversMonthlyTargetCount()}</td>
                  <td>{riversPercent()}%</td>
                  {role_id > 1 && <td>{deleteButton(() => getDetails(riversMonthlyTarget))}</td>}
                </tr>
                <tr>
                  <td>5</td>
                  <td>Edo</td>
                  <td>{edoDailyCompleted}</td>
                  <td>{edoMonthlyCompleted}</td>
                  <td>{edoMonthlyTargetCount()}</td>
                  <td>{edoPercent()}%</td>
                  {role_id > 1 && <td>{deleteButton(() => getDetails(edoMonthlyTarget))}</td>}
                </tr>
                <tr>
                  <td>6</td>
                  <td>Oyo</td>
                  <td>{oyoDailyCompleted}</td>
                  <td>{oyoMonthlyCompleted}</td>
                  <td>{oyoMonthlyTargetCount()}</td>
                  <td>{oyoPercent()}%</td>
                  {role_id > 1 && <td>{deleteButton(() => getDetails(oyoMonthlyTarget))}</td>}
                </tr>
                <tr>
                  <td>7</td>
                  <td>Delta</td>
                  <td>{deltaDailyCompleted}</td>
                  <td>{deltaMonthlyCompleted}</td>
                  <td>{deltaMonthlyTargetCount()}</td>
                  <td>{deltaPercent()}%</td>
                  {role_id > 1 && <td>{deleteButton(() => getDetails(deltaMonthlyTarget))}</td>}
                </tr>
                <tr>
                  <td>8</td>
                  <td>Enugu</td>
                  <td>{enuguDailyCompleted}</td>
                  <td>{enuguMonthlyCompleted}</td>
                  <td>{enuguMonthlyTargetCount()}</td>
                  <td>{enuguPercent()}%</td>
                  {role_id > 1 && <td>{deleteButton(() => getDetails(enuguMonthlyTarget))}</td>}
                </tr>
                <tr>
                  <td>9</td>
                  <td>Kano</td>
                  <td>{kanoDailyCompleted}</td>
                  <td>{kanoMonthlyCompleted}</td>
                  <td>{kanoMonthlyTargetCount()}</td>
                  <td>{kanoPercent()}%</td>
                  {role_id > 1 && <td>{deleteButton(() => getDetails(kanoMonthlyTarget))}</td>}
                </tr>
                <tr>
                  <td>10</td>
                  <td>Kaduna</td>
                  <td>{kadunaDailyCompleted}</td>
                  <td>{kadunaMonthlyCompleted}</td>
                  <td>{kadunaMonthlyTargetCount()}</td>
                  <td>{kadunaPercent()}%</td>
                  {role_id > 1 && <td>{deleteButton(() => getDetails(kadunaMonthlyTarget))}</td>}
                </tr>
                <tr>
                  <td>11</td>
                  <td>Imo</td>
                  <td>{imoDailyCompleted}</td>
                  <td>{imoMonthlyCompleted}</td>
                  <td>{imoMonthlyTargetCount()}</td>
                  <td>{imoPercent()}%</td>
                  {role_id > 1 && <td>{deleteButton(() => getDetails(imoMonthlyTarget))}</td>}
                </tr>
                <tr>
                  <td>12</td>
                  <td>Abia</td>
                  <td>{abiaDailyCompleted}</td>
                  <td>{abiaMonthlyCompleted}</td>
                  <td>{abiaMonthlyTargetCount()}</td>
                  <td>{abiaPercent()}%</td>
                  {role_id > 1 && <td>{deleteButton(() => getDetails(abiaMonthlyTarget))}</td>}
                </tr>
                <tr>
                  <td>13</td>
                  <td>Anambra</td>
                  <td>{anambraDailyCompleted}</td>
                  <td>{anambraMonthlyCompleted}</td>
                  <td>{anambraMonthlyTargetCount()}</td>
                  <td>{anambraPercent()}%</td>
                  {role_id > 1 && <td>{deleteButton(() => getDetails(anambraMonthlyTarget))}</td>}
                </tr>
                <tr>
                  <td>14</td>
                  <td>Cross River</td>
                  <td>{crossRiverDailyCompleted}</td>
                  <td>{crossRiverMonthlyCompleted}</td>
                  <td>{crossRiverMonthlyTargetCount()}</td>
                  <td>{crossRiverPercent()}%</td>
                  {role_id > 1 && <td>{deleteButton(() => getDetails(crossRiverMonthlyTarget))}</td>}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstallationsSummary;
