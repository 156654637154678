import axios from 'axios';
import toastr from 'toastr';

const terms_conditionEndpoint = 'https://api.tizeti.com/api/v1/account/terms_and_conditions/get_all';

export const get_terms_conditions = async (email) => {
  try {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    };
    if (email !== undefined) {
      const res = await axios.get(`${terms_conditionEndpoint}?email=${email}`, {
        headers,
      });
      if (res.data) {
        return res.data.data;
      }
    } else {
      const res = await axios.get(`${terms_conditionEndpoint}`, {
        headers,
      });
      if (res.data) {
        return res.data.data;
      }
    }
  } catch (error) {
    console.error(error);
    toastr.error('error fetching data');
  }
};
