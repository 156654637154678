import React from 'react';

const AccountsPersonnelTableHead = () => {
  return (
    <thead>
      <tr>
        <th>#</th>
        <th>Amount In Digits</th>
        <th>Amount In Words</th>
        <th>Accounts Approval Status </th>
        <th>Manager Approval Status </th>
        <th>C-LEVEL Approval Status </th>
        <th>CEO/COO LEVEL Approval Status </th>
        <th>In Favor Of</th>
        <th>Requires C Level Approval?</th>
        <th>Requires CEO/COO Level Approval?</th>
        <th>Created At</th>
        <th>Purpose</th>
        <th>View Uploaded File</th>
        <th>View Details</th>
      </tr>
    </thead>
  );
};

export default AccountsPersonnelTableHead;
