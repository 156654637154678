/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
import React from 'react';
// import PropTypes from 'prop-types';
import caution from '../../../../../assets/img/icons/Caution.png';
import { useInstallationsSummary } from '../../../../../hooks/field-support/installations-summary/useInstallationsSummary';

const DeleteInstallationTarget = ({ details }) => {
  const { deleteTarget } = useInstallationsSummary();

  return (
    <div
      className="modal fade"
      id="deleteModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content mt-5 py-4">
          <div className="text-center">
            <img src={caution} alt="" width="60px" />
          </div>
          <div className="modal-body p-3">
            <form className="text-center">
              {details.length > 0 ? (
                <h5>
                  Are you sure you want to delete <span className="text-uppercase">{details[0].state}</span>?
                </h5>
              ) : (
                <h5>This State Has No Inital Target</h5>
              )}
              <div className="mt-3">
                <button type="button" className="btn btn-secondary mr-2" data-dismiss="modal">
                  Cancel
                </button>
                {details.length > 0 && (
                  <button type="submit" className="btn btn-danger" onClick={() => deleteTarget(details[0].id)}>
                    Delete
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

// DeleteInstallationTarget.propTypes = {
//   details: PropTypes.arrayOf.isRequired,
// };

export default DeleteInstallationTarget;
