import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';

const formReducer = (state, action) => {
  const { type } = action;
  switch (type) {
    case LOADING_STARTS:
      return {
        ...state,
        loadingForm: true,
      };

    case LOADING_STOPS:
      return {
        ...state,
        loadingForm: false,
      };
    default:
      return state;
  }
};
export default formReducer;
