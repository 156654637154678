/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import { EDIT_LOG_URL } from '../../../../../services/api/sales/logs/LogsUrl';

class EditLog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      first: '',
      last: '',
      email: '',
      status: '',
      phone: '',
      note: '',
      source: '',
      plan: '',
      load: false,
      address: '',
      dateOfBirth: '',
    };

    this.onChange = this.onChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      first: nextProps.log.firstName,
      last: nextProps.log.lastName,
      email: nextProps.log.email,
      status: nextProps.log.status,
      phone: nextProps.log.phoneNumber,
      note: nextProps.log.comment,
      source: nextProps.log.about_us,
      plan: nextProps.log.plan,
      id: nextProps.log.id,
      address: nextProps.log.address,
      dateOfBirth: nextProps.log.dateOfBirth,
    });
  }

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      load: true,
    });

    const { id, first, last, email, status, phone, note, source, plan, address, dateOfBirth } = this.state;

    const data = {
      firstName: first,
      lastName: last,
      email,
      status,
      phoneNumber: phone,
      comment: note,
      about_us: source,
      plan,
      address,
      date_of_birth: dateOfBirth,
    };

    try {
      const response = await axios.patch(`${EDIT_LOG_URL}/${id}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.editStatus === 'success') {
        toast.success('User edited!');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.info('Something went wrong!');
        this.setState({
          load: false,
        });
      }
    } catch (e) {
      toast.info('Something went wrong!');
      if (e.response) {
        this.setState({
          load: false,
        });
      }
    }
  };

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleChange = (e) =>
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      ...this.state,
      gender: e.target.value,
      plan: e.target.value,
    });

  render() {
    const { load, first, last, address, email, phone, source, note, plan, dateOfBirth } = this.state;

    return (
      <div
        id="editcallog"
        className="modal fade in"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center" id="myModalLabel">
                Edit Call log
              </h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
            </div>
            <div className="modal-body">
              <form className="form-horizontal" onSubmit={this.onSubmit}>
                <div className="form-group add-log">
                  <div className="col-md-12 mb-1 d-flex">
                    <div className="col-lg-6 mb-3">
                      <label className="copy-font">First Name</label>
                      <input
                        type="text"
                        name="first"
                        className="form-control"
                        placeholder="First Name"
                        defaultValue={first}
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="col-lg-6 mb-3">
                      <label className="copy-font">Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="last"
                        placeholder="Last Name"
                        defaultValue={last}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-12 mb-3 d-flex">
                    <div className="col-lg-6">
                      <label className="copy-font">Phone number</label>
                      <input
                        type="text"
                        name="phone"
                        defaultValue={phone}
                        onChange={this.onChange}
                        className="form-control"
                        placeholder="Phone number"
                      />
                    </div>
                    <div className="col-lg-6">
                      <label className="copy-font">Date Of Birth</label>
                      <input type="date" name="dateOfBirth" className="form-control" value={dateOfBirth} required />
                    </div>
                  </div>

                  <div className="col-md-12 mb-3 d-flex">
                    <div className="col-lg-6">
                      <label className="copy-font">Status</label>
                      <select name="status" onChange={this.onChange} className="form-control">
                        <option value="">Select status</option>
                        <option value="lead">Paid </option>
                        <option value="expecting_payment">Expecting Payment</option>
                        <option value="revert_back">Revert</option>
                        <option value="no_coverage">No Coverage</option>
                        <option value="not_interested">Not Interested</option>
                        <option value="month_end">Month End</option>
                      </select>
                    </div>
                    <div className="col-lg-6">
                      <label className="copy-font">Source</label>
                      <select className="form-control" name="source" onChange={this.onChange}>
                        <option value="">Edit Source : {source}</option>
                        <option value="Google Ads">Google Ads</option>
                        <option value="Facebook">Facebook</option>
                        <option value="Flyer">Flyer</option>
                        <option value="Billboard">Billboard</option>
                        <option value="Mobile Advert">Mobile Advert</option>
                        <option value="Friend">Friend</option>
                        <option value="Online">Online</option>
                        <option value="Television">Television</option>
                        <option value="Radio">Radio</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-12 mb-3 d-flex">
                    <div className="col-lg-6">
                      <div className="mb-2">
                        <label className="copy-font">Plan</label>
                        <div className="d-flex">
                          <input
                            id="radio-button"
                            checked={plan === 'home'}
                            onChange={this.onChange}
                            name="plan"
                            defaultValue="home"
                            className="check"
                            type="radio"
                            style={{
                              marginTop: '5px',
                              verticalAlign: 'text-bottom',
                            }}
                          />
                          <span className="ml-1">Home</span>
                          <input
                            id="radio-button"
                            className="check"
                            onChange={this.onChange}
                            name="plan"
                            defaultValue="office"
                            checked={plan === 'office'}
                            type="radio"
                            style={{
                              marginTop: '5px',
                              marginLeft: '10%',
                              verticalAlign: 'text-bottom',
                            }}
                          />
                          <span className="ml-1">Office</span>
                          <input
                            id="radio-button"
                            className="check"
                            checked={plan === 'enterprise'}
                            onChange={this.onChange}
                            name="plan"
                            defaultValue="enterprise"
                            type="radio"
                            style={{
                              marginTop: '5px',
                              marginLeft: '10%',
                              verticalAlign: 'text-bottom',
                            }}
                          />
                          <span className="ml-1">Enterprise</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mb-3">
                    <div className="col-12">
                      <label className="copy-font">Email address</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email address"
                        name="email"
                        defaultValue={email}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-12 mb-3">
                    <div className="col-12">
                      <label className="copy-font">Address</label>
                      <textarea
                        rows="2"
                        cols="100"
                        className="form-control shadow-sm"
                        placeholder="Address"
                        name="address"
                        defaultValue={address}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-12 mb-3 d-flex">
                    <div className="col-12">
                      <label className="copy-font">Note</label>
                      <textarea
                        rows="4"
                        cols="200"
                        type="text"
                        defaultValue={note}
                        onChange={this.onChange}
                        className="form-control shadow-sm"
                        name="note"
                        placeholder="Note"
                      />
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  {load ? (
                    <button type="submit" disabled className="btn btn-second waves-effect">
                      Updating...
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-second waves-effect">
                      Update Call Log
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

export default EditLog;
