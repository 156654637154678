import { GET_FREQUENCY_USAGE } from '../../../../constants/types/shared/noc/FrequencyUsageTypes';

const initialState = {
  graphs: [],
};

export const FrequencyUsageReducer = (state = initialState, action) => {
  const { type, payload } = action;

  if (type === GET_FREQUENCY_USAGE) {
    return {
      ...state,
      graphs: payload,
    };
  }
  return state;
};

export default FrequencyUsageReducer;
