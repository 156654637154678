/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useContext } from 'react';
import edit from '../../../../../../assets/img/icons/edit.png';
import helpers from '../../../../../../utils/helpers';
import view from '../../../../../../assets/img/icons/view.png';
import ViewDummy from './ViewDummy';
import StaffPayslipContext from './context/StaffPayslipContext';
import EditPayslip from './EditPayslip';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../../../utils/loader/loadingHelper';

const StaffPayslipsTable = () => {
  const { payslips, gettingPayslip } = useContext(StaffPayslipContext);
  const [one, setOne] = useState({});
  const onClick = (params) => {
    setOne(params);
  };

  const tableData =
    payslips && payslips.length ? (
      payslips.map((row, i) => (
        <tr className="text-nowrap" key={row.id}>
          <td>{i + 1}</td>
          <td>{row.arrears}</td>
          <td>{row.basic_salary}</td>
          <td>{row.dressing_allowance}</td>
          <td>{row.housing_allowances}</td>
          <td>{row.loan}</td>
          <td>{row.lunch_subsidy}</td>
          <td>{row.medical_allowances}</td>
          <td>{row.month}</td>
          <td>{row.year}</td>
          <td>{row.others}</td>
          <td>{row.pension}</td>
          <td>{row.tax}</td>
          <td>{row.transport_allowances}</td>
          <td>{row.utilities_allowances}</td>
          <td>{helpers.formatDateAndTime(row.created_at)}</td>
          <td>{helpers.formatDateAndTime(row.updated_at)}</td>
          <td>
            <div className="text-center">
              {/* <button */}
              {/*  type="submit" */}
              {/*  className="mr-2" */}
              {/*  data-toggle="modal" */}
              {/*  data-placement="right" */}
              {/*  data-target="#view_access-point" */}
              {/*  onClick={() => onClick(row)} */}
              {/* > */}
              {/*  <span className="view"> */}
              {/*    <img src={view} data-toggle="tooltip" alt="" data-placement="top" title="View" /> */}
              {/*  </span> */}
              {/* </button> */}
              <button
                type="submit"
                className="mr-2"
                data-toggle="modal"
                data-placement="right"
                data-target="#editPayslip"
                onClick={() => onClick(row)}
              >
                <span className="view">
                  <img src={edit} data-toggle="tooltip" alt="" data-placement="top" title="Edit" />
                </span>
              </button>
            </div>
            <ViewDummy view={one} />
            <EditPayslip payslip={one} />
          </td>
        </tr>
      ))
    ) : (
      <NoItemRow colSpan="18" text="no data" />
    );
  return (
    <div className="card x-panel mt-2 px-0">
      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Arrears</th>
              <th className="text-nowrap">Basic Salary</th>
              <th>Dressing Allowance</th>
              <th>Housing Allowance</th>
              <th>LOan</th>
              <th>Lunch Subsidy</th>
              <th>Medical Allowance</th>
              <th>Month Of</th>
              <th>Year</th>
              <th>Others</th>
              <th>Pension</th>
              <th>Tax</th>
              <th>Transport Allowances</th>
              <th>Utility Allowance</th>
              <th>Date Created</th>
              <th>Date Updated</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{gettingPayslip ? <TizetiGIFLogoTable colSpan="18" /> : tableData}</tbody>
        </table>
      </div>
    </div>
  );
};

export default StaffPayslipsTable;
