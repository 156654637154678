/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { toast } from 'react-toastify';
import { GENIATECH_GET_PAYMENT_HISTORY_URL } from '../../../../services/api/shared/GeniatechUrl';
import {
  FETCHED_CUSTOMER_PAYMENTS,
  START_FETCHING_CUSTOMER_PAYMENTS,
  STOP_FETCHING_CUSTOMER_PAYMENTS,
} from '../../../../constants/types/shared/customers/payments/PaymentsTypes';

export const getPaymentHistory = (data) => {
  return async (dispatch) => {
    dispatch({
      type: START_FETCHING_CUSTOMER_PAYMENTS,
      payload: true,
    });

    try {
      const response = await axios.post(`${GENIATECH_GET_PAYMENT_HISTORY_URL}`, data);

      console.log(response.data);

      if (typeof response.data === 'string') {
        toast.info('Unable to fetch vustomers payments');

        dispatch({
          type: STOP_FETCHING_CUSTOMER_PAYMENTS,
          payload: false,
        });
      } else {
        dispatch({
          type: FETCHED_CUSTOMER_PAYMENTS,
          payload: response.data,
        });
      }
    } catch (e) {
      if (e) {
        dispatch({
          type: STOP_FETCHING_CUSTOMER_PAYMENTS,
          payload: false,
        });
      }
    }
  };
};
