/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useContext } from 'react';
import edit from '../../../../../../assets/img/icons/edit.png';
import helpers from '../../../../../../utils/helpers';
import view from '../../../../../../assets/img/icons/view.png';
import ViewHotspotServer from './ViewHotspotServer';
// import { HotspotServerProps } from './types/HotspotServerProps';
import { HotspotServerContext } from './context/HotspotServerContext';
import loader from '../../../../../../assets/img/icons/TizetiLoaderColored.gif';

const HotspotServerTable = () => {
  const { data, loading } = useContext(HotspotServerContext);

  const [one, setOne] = useState({
    base_station: '',
    created_at: '',
    deleted_at: '',
    id: '',
    ip_address: '',
    new_name: '',
    old_name: '',
    updated_at: '',
  });
  const onClick = (params) => {
    setOne(params);
  };
  const tableData =
    data && data.length
      ? data.map((row, i) => (
          <tr className="text-nowrap" key={row.id}>
            <td>{i + 1}</td>
            <td>{row.base_station}</td>
            <td>{row.ip_address}</td>
            <td>{row.old_name}</td>
            <td>{row.new_name}</td>
            <td>{helpers.formatDateAndTime(row.created_at)}</td>
            <td>
              <div className="text-center">
                <button
                  type="submit"
                  className="mr-2"
                  data-toggle="modal"
                  data-placement="right"
                  data-target="#view_access-point"
                  onClick={() => onClick(row)}
                >
                  <span className="view">
                    <img src={view} data-toggle="tooltip" alt="" data-placement="top" title="View" />
                  </span>
                </button>
                <button
                  type="submit"
                  className="mr-2"
                  data-toggle="modal"
                  data-placement="right"
                  data-target="#update_item"
                  // onClick={() => getItem(list)}
                >
                  <span className="view">
                    <img src={edit} data-toggle="tooltip" alt="" data-placement="top" title="Edit" />
                  </span>
                </button>
              </div>
              <ViewHotspotServer view={one} />
              {/* <EditRetrievalItem OneItem={one} /> */}
            </td>
          </tr>
        ))
      : 'no access point';
  return (
    <div className="card x-panel mt-2 px-0">
      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th className="text-nowrap">IP Address</th>
              <th>Device Type</th>
              <th>Number of Connection</th>
              <th>Date Created</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{loading ? <img src={loader} alt="gif" /> : tableData}</tbody>
        </table>
      </div>
    </div>
  );
};

export default HotspotServerTable;
