/* eslint-disable react/require-default-props */
/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-case-declarations */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import loader from '../../../../assets/img/icons/TizetiLoaderColored.gif';
import { getCrmUsers, searchCrmUser } from '../../../../actions/shared/crm/UsersActions';
import view from '../../../../assets/img/icons/view.png';
import { getUserDetails } from '../../../../actions/shared/customers/CustomersDetailsActions';
import CustomerDetails from './CustomerDetails';
import { getUser } from '../../../../actions/shared/user/UserActions';

class CustomersTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      keyword: '',
      search: '',
      user: {},
      mac: '',
    };
  }

  componentDidMount() {
    // eslint-disable-next-line no-shadow
    // eslint-disable-next-line react/destructuring-assignment
    this.props.getCrmUsers();
    this.props.getUser();
  }

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  // eslint-disable-next-line consistent-return
  onSubmit = (e) => {
    e.preventDefault();
    const { keyword, search } = this.state;
    // eslint-disable-next-line no-shadow
    const { searchCrmUser } = this.props;

    switch (keyword) {
      case 'email':
        const emailSearch = {
          action: 'searchCustomer',
          category: 'email',
          value: search.trim(),
        };
        searchCrmUser(emailSearch);
        break;
      case 'name':
        const nameSearch = {
          action: 'searchCustomer',
          category: 'name',
          value: search.trim(),
        };
        searchCrmUser(nameSearch);

        break;
      case 'phone':
        const phoneSearch = {
          action: 'searchCustomer',
          category: 'phone',
          value: search.trim(),
        };
        searchCrmUser(phoneSearch);
        break;
      case 'macaddress':
        const macSearch = {
          action: 'searchCustomer',
          category: 'macaddress',
          value: search.trim(),
        };
        searchCrmUser(macSearch);
        break;
      default:
        return null;
    }
  };

  getUserDetails = (customer) => {
    this.setState({
      user: customer.extra.slice(-1)[0].account_ref,
    });
  };

  submitFormToEditMacId = (newmac, oldmac) => {
    // const { profile } = this.props;
  };

  showAccountRef = (c) => {
    if (c.extra[0].account_ref) {
      return c.extra[0].account_ref;
    }
    return 'n/a';
  };

  showAccountPassword = (c) => {
    if (c.extra[0].password) {
      return c.extra[0].password;
    }
    return 'n/a';
  };

  showAccountStartDate = (c) => {
    if (c.extra[0].support_start_date) {
      return c.extra[0].support_start_date;
    }
    return 'n/a';
  };

  showAccountEndDate = (c) => {
    if (c.extra[0].support_end_date) {
      return c.extra[0].support_end_date;
    }
    return 'n/a';
  };

  render() {
    const { load, users, loading } = this.props;
    const { search, user, mac } = this.state;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 mt-3 mb-3">
            <form onSubmit={this.onSubmit} className="form-inline inner-addon d-flex">
              <select name="keyword" onChange={this.onChange} className="form-control mr-auto empty mt-1">
                <option value="">Choose fields to search from</option>
                <option value="email">Email</option>
                <option value="name">Name</option>
                <option value="phone">Phone</option>
                <option value="macaddress">MAC ID</option>
              </select>
              <input
                className="form-control col-6 mr-auto empty mt-1 pl-5"
                type="text"
                placeholder="Search with email"
                name="search"
                value={search}
                onChange={this.onChange}
              />
              <span className="fa fa-search right-addon" />

              <div className="d-flex">
                {loading ? (
                  <button className="btn btn-green mr-2" style={{ height: '39px' }} disabled={loading}>
                    Searching...{' '}
                  </button>
                ) : (
                  <button className="btn btn-green mr-2" style={{ height: '39px' }}>
                    Search{' '}
                  </button>
                )}
              </div>
            </form>
          </div>
          <div className="card x-panel">
            <div className="table-responsive content">
              <table className="table copy-font">
                {/* eslint-disable-next-line react/jsx-indent */}
                <thead>
                  <tr>
                    <th>#</th>
                    <th nowrap="no-wrap">Email</th>
                    <th>First Name</th>
                    <th nowrap="no-wrap">Last Name</th>
                    <th className="text-center">Mobile</th>
                    <th nowrap="no-wrap">Phone</th>
                    <th style={{ paddingLeft: '24px' }}>Actions</th>

                    {/* <th className="text-center">Rollback</th> */}
                    <th style={{ paddingLeft: '24px' }}>Secondary Email</th>
                    <th>Account Ref</th>
                    <th>Password</th>
                    <th>Support Start Date</th>
                    <th>Support End Date</th>
                  </tr>
                </thead>
                {load ? (
                  <img src={loader} alt="loading gif" />
                ) : (
                  <tbody>
                    {/* eslint-disable-next-line react/prop-types */}
                    {users.map((customer, i) => (
                      <tr key={i + 1}>
                        <td>{i + 1}</td>
                        <td>{customer.email}</td>
                        <td>{customer.firstname}</td>
                        <td>{customer.lastname}</td>
                        <td>{customer.mobile}</td>
                        <td>{customer.phone}</td>
                        <td className="ml-2">
                          <button
                            type="submit"
                            style={{
                              border: '0',
                              backgroundColor: 'white',
                              width: '0%',
                            }}
                            data-toggle="tooltip"
                            data-placement="left"
                            title="View"
                            onClick={() => this.getUserDetails(customer)}
                          >
                            <span className="view1 ml-4">
                              <img src={view} alt="" data-toggle="modal" data-target="#crm-details" />
                            </span>
                          </button>
                          <CustomerDetails
                            user={user}
                            onChange={this.onChange}
                            mac={mac}
                            editUserMac={this.submitFormToEditMacId}
                          />
                        </td>
                        <td>{customer.secondaryemail}</td>
                        <td>{customer.extra ? customer.extra.slice(-1)[0].account_ref : null}</td>
                        <td>{customer.extra ? customer.extra.slice(-1)[0].password : null}</td>
                        <td>{customer.extra ? customer.extra.slice(-1)[0].support_start_date : null}</td>
                        <td>{customer.extra ? customer.extra.slice(-1)[0].support_end_date : null}</td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
        {/* <RetentionScript/> */}
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  load: state.crm.loading,
  users: state.crm.users,
  loading: state.crm.searching,
  profile: state.user.user,
});

CustomersTable.propTypes = {
  getCrmUsers: PropTypes.func,
  searchCrmUser: PropTypes.func,
  load: PropTypes.bool,
  users: PropTypes.shape(),
  loading: PropTypes.bool,
  profile: PropTypes.shape(),
  getUser: PropTypes.func,
};

export default connect(
  mapStateToProps,
  { getCrmUsers, searchCrmUser, getUserDetails, getUser },
)(CustomersTable);
