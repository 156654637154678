/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/camelcase */
import { useReducer, useState } from 'react';
import qs from 'qs';
import axios from 'axios';
import toastr from 'toastr';
import { toast } from 'react-toastify';
import deviceRetrievalReducer from '../reducer/deviceRetrievalReducer';
import * as urls from '../api/DeviceRetrievalUrl';
import { LOADING_STARTS, LOADING_STOPS } from '../../../../../constants/types/shared/LoadingTypes';
import { GET_DATA } from '../../../../../constants/types/shared/CustomTypes';

const useDeviceRetrieval = () => {
  const [{ data, loading, pages, count }, dispatch] = useReducer(deviceRetrievalReducer, {
    loading: false,
    pages: 0,
    data: [],
    count: 0,
  });
  const retrievalState = {
    item: '',
    procurement_tracklist_id: '',
    radio_tag_no: '',
    comment: '',
    tracklist_category_id: '',
    router_mac_id: '',
    wirelessMAC: '',
    esnMAC: '',
    serialNumber: '',
    partNumber: '',
  };
  const detailsState = {
    status: '',
    option: '',
    optionValue: '',
  };
  const [details, setDetails] = useState(detailsState);
  const [retrieval, setRetrieval] = useState(retrievalState);
  const [openUninstall, setOpenUninstall] = useState(false);
  const [openDeviceConfirmation, setOpenDeviceConfirmation] = useState(false);
  const [loadingUninstallDevice, setLoadingUninstallDevice] = useState(false);
  const [loadingDeviceConfirmation, setLoadingDeviceConfirmation] = useState(false);
  const [showMoveToProcurementModal, setShowMoveToProcurementModal] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);

  const onOpenUninstall = () => setOpenUninstall(!openUninstall);
  const onOpenDeviceConfirmation = () => setOpenDeviceConfirmation(!openDeviceConfirmation);
  const onOpenMoveToProcurement = () => setShowMoveToProcurementModal(!showMoveToProcurementModal);

  const handleDetails = (e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRetrieval({ ...retrieval, [name]: value });
  };

  const onSubmit = async (params, currentPage) => {
    try {
      dispatch({ type: LOADING_STARTS });
      const response = await axios.post(`${urls.SEARCH_DEVICE_RETRIEVAL_URL}/${currentPage}`, qs.stringify(params), {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: GET_DATA,
          payload: response.data,
        });
      }
    } catch (e) {
      dispatch({ type: LOADING_STOPS });
      toast.info('Something went wrong!');
    }
  };
  const addDeviceRetrieval = async (param) => {
    setLoadingForm(true);
    try {
      const response = await axios.post(`${urls.DEVICE_RETRIEVAL_URL}`, param, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.id) {
        setLoadingForm(false);
        setShowMoveToProcurementModal(false);
        setRetrieval(retrievalState);
        toastr.success('Retrieval Created');
      }
    } catch (e) {
      setLoadingForm(false);
      toastr.info('Something went wrong');
    }
  };
  const getDeviceRetrieval = async (currentPage) => {
    dispatch({ type: LOADING_STARTS });
    try {
      const response = await axios.get(`${urls.DEVICE_RETRIEVAL_URL}/${currentPage}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: GET_DATA,
          payload: response.data,
        });
      } else dispatch({ type: LOADING_STOPS });
    } catch (e) {
      dispatch({ type: LOADING_STOPS });
    }
  };
  const updateDeviceRetrieval = async (id, params) => {
    dispatch({ type: LOADING_STARTS });
    try {
      const response = await axios.patch(`${urls.DEVICE_RETRIEVAL_URL}/${id}`, qs.stringify(params), {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        toastr.success('Update Successfull');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else dispatch({ type: LOADING_STOPS });
    } catch (e) {
      dispatch({ type: LOADING_STOPS });
    }
  };
  const onUninstallDevice = async (params) => {
    setLoadingUninstallDevice(true);
    try {
      const response = await axios.post(urls.UNINSTALL_URL, qs.stringify(params), {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.status === 'success') {
        toastr.success(response.data.message.toUpperCase());
        setOpenUninstall(false);
        setLoadingUninstallDevice(false);
      } else {
        toastr.success('Unable to carry out operation');
      }
      setLoadingUninstallDevice(false);
    } catch (e) {
      setLoadingUninstallDevice(false);
      if (e.response.status === 404) return toastr.success('MAIL SENT TO FSE');
      if (e.response.data.message) return toastr.error(e.response.data.message);
      return toastr.info('Something went wrong!');
    }
  };
  const bulkUninstallDevice = async (params) => {
    await axios({
      method: 'post',
      url: `${urls.BULK_UNINSTALL_URL}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      data: params,
    })
      .then((response) => {
        if (response.data) {
          toastr.success('MAIL SENT TO FSE');
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          toastr.error('Unable to carry out operation');
        }
      })
      .catch((error) => {
        if (error.response.data.message) toastr.error(error.response.data.message);
        else toastr.error('Unable to carry out operation');
      });
  };
  const submitDeviceConfirmation = async (params) => {
    setLoadingDeviceConfirmation(true);
    try {
      const response = await axios.post(urls.DEVICE_CONFIRMATION_URL, qs.stringify(params), {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        }
      });
      if (response.data.status === 'success') {
        toast.success('Customer device removed successfully');
        setOpenDeviceConfirmation(false);
        setLoadingDeviceConfirmation(false);
      } else {
        toast.success('Unable to carry out operation');
      }
      setLoadingDeviceConfirmation(false);
    } catch (e) {
      setLoadingDeviceConfirmation(false);
      if (e.response.data.message) {
        toast.error(e.response.data.message);
      } else {
        toast.info('Something went wrong!');
      }
    }
  };

  const fseRetrievalToProcurement = async (deviceConfirmationData, moveToProcurementData) => {
    setLoadingForm(true);
    try {
      const deviceConfirmationResponse = await axios.post(
        urls.DEVICE_CONFIRMATION_URL,
        qs.stringify(deviceConfirmationData),
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );
      if (deviceConfirmationResponse.data.status === 'success') {
        const moveToProcurementResponse = await axios.post(`${urls.PROCUREMENT_RETRIEVAL_URL}`, moveToProcurementData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });
        if (moveToProcurementResponse.data.id) {
          setLoadingForm(false);
          setShowMoveToProcurementModal(false);
          setRetrieval(retrievalState);
          toastr.success('Retrieval Confirmed and Moved To Procurement');
        }
      } else {
        toastr.success('Unable to carry out operation');
        setLoadingForm(false);
      }
    } catch (e) {
      setLoadingForm(false);
      toastr.info('Somethong went wrong');
    }
  };

  const searchForUser = (currentPage) => {
    const { status, option, optionValue } = details;
    switch (option) {
      case 'email':
        const emailSearch = { status, email: optionValue.trim() };
        return onSubmit(emailSearch, currentPage);

      case 'name':
        const nameSearch = { status, name: optionValue.trim() };
        return onSubmit(nameSearch, currentPage);

      case 'phone':
        const phoneSearch = { status, phone: optionValue.trim() };
        return onSubmit(phoneSearch, currentPage);

      case 'mac':
        const macSearch = { status, mac: optionValue.trim() };
        return onSubmit(macSearch, currentPage);

      default:
        return onSubmit({ status }, currentPage);
    }
  };
  return {
    data,
    loading,
    pages,
    count,
    onSubmit,
    getDeviceRetrieval,
    addDeviceRetrieval,
    updateDeviceRetrieval,
    openUninstall,
    onOpenUninstall,
    onUninstallDevice,
    bulkUninstallDevice,
    loadingUninstallDevice,
    openDeviceConfirmation,
    onOpenDeviceConfirmation,
    submitDeviceConfirmation,
    loadingDeviceConfirmation,
    showMoveToProcurementModal,
    onOpenMoveToProcurement,
    handleChange,
    retrieval,
    loadingForm,
    fseRetrievalToProcurement,
    details,
    handleDetails,
    searchForUser,
  };
};

export default useDeviceRetrieval;
