import { GET_ZONES } from '../../../../../constants/types/operations/installations/InstallationsTypes';
import { LOADING_STARTS, LOADING_STOPS } from '../../../../../constants/types/shared/LoadingTypes';

const initialState = {
  isLoading: false,
};

const departmentReducers = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOADING_STARTS:
      return {
        ...state,
        isLoading: true,
      };

    case GET_ZONES:
      return {
        ...state,
        list: payload.list,
        isLoading: false,
      };

    case LOADING_STOPS:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default departmentReducers;
