/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-plusplus */
import React, { useState, useCallback, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import ExpiredTable from './ExpiredTable';
import loader from '../../../../../../assets/img/icons/TizetiLoaderColored.gif';
import { useExpired } from '../../../../../../hooks/shared/asset-team/expired/useExpired';

const ExpiredBody = () => {
  const { items, time, total, isLoading } = useExpired();

  const [data, setData] = useState({});
  const [count] = useState([]);
  const [basestation] = useState([]);

  const headers = [{ label: 'Basestation', key: 'basestationame' }, { label: 'Count', key: 'expired_count' }];

  const fetchTime = () => {
    const newTime = moment(time)
      .add(1, 'hours')
      .format('DD-MMM-YYYY hh:mm A');
    return newTime;
  };

  const fetchData = useCallback(async () => {
    if (items.length > 0) {
      items.sort((a, b) => a.expired_count - b.expired_count);
      for (let i = 0; i < items.length; i++) {
        count.push(items[i].expired_count);
        basestation.push(items[i].basestationame);
      }
      setData({
        labels: basestation,
        datasets: [
          {
            label: 'count',
            data: count,
            backgroundColor: 'rgb(115, 255, 136)',
          },
        ],
      });
    }
  }, [basestation, count, items]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="container">
      <div className="row my-5">
        <div className="col-12 my-5">
          <p>
            {time ? (
              <p className="h5" style={{ fontFamily: 'Rubik' }}>
                <span>UPDATED AT: </span> {fetchTime()} <br />
                <span style={{ textDecoration: 'none' }}>
                  TOTAL: <span className="badge badge-success mx-2">{total}</span>
                </span>
              </p>
            ) : (
              'Fetching Time...'
            )}
          </p>
          <Bar
            data={data}
            options={{
              maintainAspectRatio: true,
              responsive: true,
              scales: {
                yAxes: [
                  {
                    scaleLabel: { display: true, labelString: 'COUNT', fontStyle: 'bold', fontSize: 17 },
                  },
                ],
                xAxes: [
                  {
                    ticks: { fontStyle: 'bold' },
                    scaleLabel: { display: true, labelString: 'BASESTATION NAME', fontStyle: 'bold', fontSize: 17 },
                  },
                ],
              },
            }}
          />
        </div>
        <div className="col-12">
          {items.length ? (
            <div className="d-flex justify-content-end mr-3 mb-2 ">
              <CSVLink
                data={items}
                headers={headers}
                filename="Expired Customers Seven Days Ago.csv"
                className="btn btn-green shadow-md"
                style={{ color: 'white' }}
              >
                Download Data
              </CSVLink>
            </div>
          ) : null}
        </div>
        <div className="card x-panel">
          <div className="table-responsive content col-12">
            <table className="table table-striped">
              <thead>
                <tr className="">
                  <th>S/N</th>
                  <th>Basestation</th>
                  <th>Count</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan="4" className="text-center">
                      <img src={loader} alt="loading gif" />
                    </td>
                  </tr>
                ) : (
                  <ExpiredTable items={items} />
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="ml-3">
          <button type="button" className="btn btn-dark">
            TOTAL:
            <span style={{ fontSize: '15px' }} className="badge badge-success mx-2">
              {total}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExpiredBody;
