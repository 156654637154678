/* eslint-disable import/prefer-default-export */
import * as load from '../../../constants/types/shared/LoadingTypes';
import * as types from '../../../constants/types/shared/customers/CustomerDetailsTypes';

export const customerReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case load.LOADING_STARTS:
      return {
        ...state,
        isLoading: true,
        customer: [],
      };

    case load.LOADING_STOPS:
      return {
        ...state,
        isLoading: false,
      };

    case types.FETCHED_CUSTOMER:
      return {
        ...state,
        customers: payload,
        isLoading: false,
        customer: payload,
      };

    case types.START_GETTING_PASSWORD:
      return {
        ...state,
        gettingPassword: true,
      };

    case types.STOP_GETTING_PASSWORD:
      return {
        ...state,
        gettingPassword: false,
      };

    case types.GET_PASSWORD:
      return {
        ...state,
        password: payload.teleportPassword,
        accounts: payload.accounts,
        gettingPassword: false,
      };

    case types.START_EXTENDING_CUSTOMER:
      return {
        ...state,
        extendingCustomer: true,
      };

    case types.STOP_EXTENDING_CUSTOMER:
      return {
        ...state,
        extendingCustomer: false,
      };

    case types.START_FETCHING_CUSTOMER_DETAILS:
      return {
        ...state,
        gettingDetails: true,
      };

    case types.FETCHED_CUSTOMER_DETAILS:
      return {
        ...state,
        details: payload.details,
        itemprice: payload.itemprice,
        address: payload.address,
        session: payload.session,
        createdAt: payload.createdAt,
        username: payload.username,
        gettingDetails: false,
        installationDate: payload.installationDate,
      };

    case types.STOP_FETCHING_CUSTOMER_DETAILS:
      return {
        ...state,
        gettingDetails: false,
      };

    case types.DELETING_ACCOUNT:
      return {
        ...state,
        deleting: true,
      };

    case types.DELETED_ACCOUNT:
      return {
        ...state,
        deleting: false,
      };

    case types.STOP_DELETING_ACCOUNT:
      return {
        ...state,
        deleting: false,
      };
    case types.GETTING_INSTALLATION_DATE:
      return { ...state, gettingDate: !state.gettingDate };
    case types.INSTALLATION_DATE:
      return { ...state, gettingDate: !state.gettingDate, installationDate: payload };

    default:
      return state;
  }
};
