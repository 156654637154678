/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import AccountsManagerRequisitions from '../../../../departments/accounts/requsitions/manager/AccountsManagerRequisitions';
import { useRequsitions } from '../../../../../../hooks/accounts/useRequisitions';
import { useDepartments } from '../../../../../../hooks/shared/departments/useDepartments';
import { useRequisition } from '../../../../../../hooks/shared/requisition/useRequisition';
import AccountsPersonnelRequistions from '../../../../departments/accounts/requsitions/personnel/AccountsPersonnelRequistions';
import ExecutivesRequsitions from '../../../../departments/accounts/requsitions/executives/ExecutivesRequsitions';
import MakeRequisition from '../MakeRequisition';
import { RequisitionProvider } from '../../../../../../context/shared/requisitions/RequsitionContext';
import AccountsLead from '../../../../departments/accounts/requsitions/leads/AccountsLead';
// import FilterByCountry from '../../../../shared/requisition/shared/filters/manager/FilterByCountry';
import DownloadRequisitions from '../../../../departments/accounts/requsitions/shared/download/DownloadRequisitions';
import CustomButton from '../../../forms/CustomButton';
import BulkApprovalOrRejection from '../../../../../../components/staff/departments/accounts/requsitions/shared/BulkApprovalOrRejection';

const AccountsHrRequisitions = () => {
  const {
    isFetching,
    requistions,
    getRequsitionByDepartment,
    giveManagerApproval,
    giveManagerDisApproval,
    giveExecutiveDisApproval,
    giveExecutiveApproval,
    leadRequistions,
    getCashRequisition,
    payUser,
    getAccountsRequsitionByStatus,
    totalPages,
    getLeadsRequisition,
    onApproveChange,
    approveRequisition,
    approving,
    showModal,
    onClickShowModal,
    searchByItem,
    searchByDateRange,
    getBetweenSevenAndThirtyDays,
    getThirtyDays,
    setCheckAllRequisition,
    setCheckOneOfAllRequisition,
    BulkApproveOrRejectRequisitions,
  } = useRequsitions();

  // console.log(requistions);

  const {
    makeRequisition,
    onChangeRequisition,
    requisition,
    onFileChange,
    file,
    percentage,
    onOpenModal,
    isLoading,
  } = useRequisition();
  const { departments } = useDepartments();
  const searchState = {
    status: '',
    country: '',
    category: '',
    department_id: '',
  };
  // const [department, setDepartment] = useState("");
  const [search, setSearch] = useState(searchState);
  const [page] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const onChange = (e) => {
    const { name, value } = e.target;
    setSearch({
      ...search,
      [name]: value,
    });
  };
  const { status, country, category, department_id } = search;
  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    if (department_id === 'all') {
      const datas = {
        status,
        country,
        category,
      };
      // console.log(data);
      getAccountsRequsitionByStatus(datas, selectedPage);
    } else {
      const datas = {
        status,
        country,
        category,
        department_id,
      };
      getAccountsRequsitionByStatus(datas, selectedPage);
    }
    // getAccountsRequsitionByStatus(search, selectedPage);
  };
  const [filteredCountry] = useState('');
  const filteredCountryChange = (e) => {
    const { value } = e.target;
    if (value) getLeadsRequisition(value);
    return null;
  };
  const submit = () => {
    return department_id.length > 0 && status.length > 0 && country.length > 0 && category.length > 0;
  };
  const handleSubmit = (search, page) => {
    if (department_id === 'all') {
      const data = {
        status,
        country,
        category,
      };
      // console.log(data);
      getAccountsRequsitionByStatus(data, page);
    } else {
      const data = {
        status,
        country,
        category,
        department_id,
      };
      getAccountsRequsitionByStatus(data, page);
    }
  };

  const reloadFunction = () => {
    if (search.category === '') {
      searchByDateRange({ from, to });
    } else if (from === '' && to === '') {
      handleSubmit(search, page);
    } else if (search.category === '' && from === '' && to === '') {
      getCashRequisition();
    }
  };
  const searchRequisitions = () => {
    return (
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
        <form
          className="form-inline mb-2 ml-3 col-12"
          onSubmit={(e) => {
            e.preventDefault();
            searchByItem(keyword);
          }}
        >
          <div className="form-group ml-2">
            <input
              className="form-control"
              type="text"
              placeholder="Input Search Term"
              style={{ width: '230px' }}
              name="keyword"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
          </div>
          <div className="form-group ml-2">
            <CustomButton classname="btn btn-green" name="Search" disabled={false} />
          </div>
        </form>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            searchByDateRange({ from, to });
          }}
          className="form-inline mb-2 ml-3 col-12"
        >
          <div className="form-group d-flex ml-2">
            <small className="mr-1">From: </small>
            <input
              type="date"
              className="form-control"
              name="from"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
            />
          </div>
          <div className="form-group d-flex ml-2">
            <small className="mr-1">To: </small>
            <input type="date" className="form-control" name="to" value={to} onChange={(e) => setTo(e.target.value)} />
          </div>
          <div className="form-group ml-2">
            <CustomButton classname="btn btn-green" name="Search By Date" disabled={false} />
          </div>
        </form>
        <form
          className="form-inline mb-2 ml-3 col-12"
          onSubmit={(event) => {
            event.preventDefault();
            getBetweenSevenAndThirtyDays();
          }}
        >
          <div className="form-group ml-2">
            <CustomButton
              classname="btn btn-green"
              name="Get All Requisitions Between 7 and 30 days"
              disabled={false}
            />
          </div>
        </form>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            getThirtyDays();
          }}
          className="form-inline mb-2 ml-3 col-12"
          style={{ justifyContent: 'end' }}
        >
          <div className="form-group ml-2">
            <CustomButton classname="btn btn-green" name="Get All Requisitions After 30 days" disabled={false} />
          </div>
        </form>
      </div>
    );
  };
  const checkRole = () => {
    switch (Number(`${sessionStorage.getItem('roleId')}`)) {
      case 2:
        return (
          <RequisitionProvider
            value={{
              makeRequisition,
              onChangeRequisition,
              requisition,
              onFileChange,
              file,
              percentage,
              isLoading,
            }}
          >
            <>
              <div className="col-lg-12 col-md-9 col-sm-12 mt-3 mb-3 d-flex">
                <div className="fliter copy-font d-flex mr-auto">
                  <small className="mt-2">Filter By:</small>
                  <span>
                    <select className="form-control" name="department_id" onChange={onChange}>
                      <option>Select By Departments</option>
                      {departments.map((dept, index) => {
                        return (
                          <option value={dept.id} key={index}>
                            {dept.name}
                          </option>
                        );
                      })}
                      <option value="all">All Departments</option>
                    </select>
                  </span>

                  <small className="mt-2">Filter By:</small>
                  <span>
                    <select className="form-control" name="status" onChange={onChange}>
                      <option>Select By Status</option>
                      <option value="rejected">Rejected</option>
                      <option value="approved">Approved</option>
                      <option value="queued">Queued</option>
                    </select>
                  </span>

                  {/* fillter by category */}
                  <small className="mt-2">Filter By:</small>
                  <span>
                    <select className="form-control" name="category" onChange={onChange}>
                      <option>Select By Category</option>
                      <option value="petty-cash">Petty Cash</option>
                      <option value="requisition">Requisition</option>
                    </select>
                  </span>

                  <small className="mt-2">Filter By:</small>
                  <span>
                    <select className="form-control" name="country" onChange={onChange}>
                      <option>Filter By Country</option>
                      <option value="ng">Nigeria</option>
                      <option value="gh">Ghana</option>
                      <option value="cv">Cote d'Ivoire</option>
                    </select>
                  </span>
                  <div className="mt-1 mb-3">
                    <button
                      onClick={() => handleSubmit(search, page)}
                      className="btn btn-green copy-font"
                      disabled={!submit()}
                    >
                      Search
                    </button>
                  </div>
                </div>
                <div>
                  <MakeRequisition />
                </div>
              </div>
              {searchRequisitions()}

              <AccountsLead load={isFetching} requistions={requistions} payUser={payUser} />
            </>
          </RequisitionProvider>
        );
      case 3:
        return (
          <RequisitionProvider
            value={{
              makeRequisition,
              onChangeRequisition,
              requisition,
              onFileChange,
              file,
              percentage,
              isLoading,
              onApproveChange,
              approveRequisition,
              giveManagerApproval,
              BulkApproveOrRejectRequisitions,
              approving,
            }}
          >
            <>
              <div className="col-lg-12 col-md-9 col-sm-12 mt-3 mb-3 d-flex">
                <div className="fliter copy-font d-flex mr-auto">
                  <small className="mt-2">Filter By:</small>
                  <span>
                    <select className="form-control" name="department_id" onChange={onChange}>
                      <option>Select By Departments</option>
                      {departments.map((dept, index) => {
                        return (
                          <option value={dept.id} key={index}>
                            {dept.name}
                          </option>
                        );
                      })}
                      <option value="all">All Departments</option>
                    </select>
                  </span>

                  <small className="mt-2">Filter By:</small>
                  <span>
                    <select className="form-control" name="status" onChange={onChange}>
                      <option>Select By Status</option>
                      <option value="rejected">Rejected</option>
                      <option value="approved">Approved</option>
                      <option value="queued">Queued</option>
                    </select>
                  </span>

                  {/* fillter by category */}
                  <small className="mt-2">Filter By:</small>
                  <span>
                    <select className="form-control" name="category" onChange={onChange}>
                      <option>Select By Category</option>
                      <option value="petty-cash">Petty Cash</option>
                      <option value="requisition">Requisition</option>
                    </select>
                  </span>

                  <small className="mt-2">Filter By:</small>
                  <span>
                    <select className="form-control" name="country" onChange={onChange}>
                      <option>Filter By Country</option>
                      <option value="ng">Nigeria</option>
                      <option value="gh">Ghana</option>
                      <option value="cv">Cote d'Ivoire</option>
                    </select>
                  </span>
                  <div className="mt-1 mb-3">
                    <button
                      onClick={() => handleSubmit(search, page)}
                      className="btn btn-green copy-font"
                      disabled={!submit()}
                    >
                      Search
                    </button>
                  </div>
                </div>
                <div>
                  <MakeRequisition />
                </div>
              </div>

              {searchRequisitions()}
              {requistions.length > 1 ? <DownloadRequisitions requistions={requistions} /> : null}
              <div>
                {requistions.filter((item) => item.isChecked).length > 0 && (
                  <button
                    className="btn btn-green mx-4 downloadButton"
                    name=""
                    data-toggle="modal"
                    data-target="#bulkApproveOrRejectRequisition"
                  >
                    Bulk Approval Or Rejection
                  </button>
                )}
              </div>
              <BulkApprovalOrRejection req={requistions} />
              <AccountsManagerRequisitions
                load={isFetching}
                status={status}
                requistions={requistions}
                totalPages={totalPages}
                onOpenModal={onOpenModal}
                reloadFunction={reloadFunction}
                handlePageClick={handlePageClick}
                setCheckAllRequisition={setCheckAllRequisition}
                setCheckOneOfAllRequisition={setCheckOneOfAllRequisition}
                // giveManagerApproval={giveManagerApproval}
                giveManagerDisApproval={giveManagerDisApproval}
              />
            </>
          </RequisitionProvider>
        );
      case 8:
        return (
          <RequisitionProvider
            value={{
              makeRequisition,
              onChangeRequisition,
              requisition,
              onFileChange,
              file,
              percentage,
              isLoading,
              onApproveChange,
              approveRequisition,
              giveManagerApproval,
              BulkApproveOrRejectRequisitions,
              approving,
            }}
          >
            <>
              <div className="col-lg-12 col-md-9 col-sm-12 mt-3 mb-3 d-flex">
                <div className="fliter copy-font d-flex mr-auto">
                  <small className="mt-2">Filter By:</small>
                  <span>
                    <select className="form-control" name="department_id" onChange={onChange}>
                      <option>Select By Departments</option>
                      {departments.map((dept) => {
                        return (
                          <option value={dept.id} key={dept.id}>
                            {dept.name}
                          </option>
                        );
                      })}
                      <option value="all">All Departments</option>
                    </select>
                  </span>

                  <small className="mt-2">Filter By:</small>
                  <span>
                    <select className="form-control" name="status" onChange={onChange}>
                      <option>Select By Status</option>
                      <option value="rejected">Rejected</option>
                      <option value="approved">Approved</option>
                      <option value="queued">Queued</option>
                    </select>
                  </span>
                  {/* fillter by category */}
                  <small className="mt-2">Filter By:</small>
                  <span>
                    <select className="form-control" name="category" onChange={onChange}>
                      <option>Select By Category</option>
                      <option value="petty-cash">Petty Cash</option>
                      <option value="requisition">Requisition</option>
                    </select>
                  </span>
                  <small className="mt-2">Filter By Country:</small>
                  <span>
                    <select className="form-control" name="country" onChange={onChange}>
                      <option>Select Country</option>
                      <option value="ng">Nigeria</option>
                      <option value="gh">Ghana</option>
                      <option value="cv">Cote d'Ivoire</option>
                    </select>
                  </span>
                  <div className="mt-1 mb-3">
                    <button
                      onClick={() => handleSubmit(search, page)}
                      className="btn btn-green copy-font"
                      disabled={!submit()}
                    >
                      Search
                    </button>
                  </div>
                </div>

                <div>
                  <MakeRequisition />
                </div>
              </div>

              {requistions.length > 1 ? <DownloadRequisitions requistions={requistions} /> : null}
              {searchRequisitions()}

              {
                // Uncomment if adding bulk approval or disapproval for executivs
                /* <div>
                {requistions.filter((item) => item.isChecked).length > 0 && (
                  <button
                    className="btn btn-green mx-4 downloadButton"
                    name=""
                    data-toggle="modal"
                    data-target="#bulkApproveOrRejectRequisition"
                  >
                    Bulk Approval Or Rejection
                  </button>
                )}
              </div>
              <BulkApprovalOrRejection req={requistions} /> */
              }
              <ExecutivesRequsitions
                load={isFetching}
                handlePageClick={handlePageClick}
                totalPages={totalPages}
                approving={approving}
                requistions={requistions}
                reloadFunction={reloadFunction}
                giveExecutiveApproval={giveExecutiveApproval}
                giveExecutiveDisApproval={giveExecutiveDisApproval}
                showModal={showModal}
                onClickShowModal={onClickShowModal}
                setCheckAllRequisition={setCheckAllRequisition}
                setCheckOneOfAllRequisition={setCheckOneOfAllRequisition}
              />
            </>
          </RequisitionProvider>
        );
      case 9:
        return (
          <RequisitionProvider
            value={{
              makeRequisition,
              onChangeRequisition,
              requisition,
              onFileChange,
              file,
              percentage,
              isLoading,
              onApproveChange,
              approveRequisition,
              giveManagerApproval,
              BulkApproveOrRejectRequisitions,
              approving,
            }}
          >
            <>
              <div className="col-lg-12 col-md-9 col-sm-12 mt-3 mb-3 d-flex">
                <div className="fliter copy-font d-flex mr-auto">
                  <small className="mt-2">Filter By:</small>
                  <span>
                    <select className="form-control" name="department_id" onChange={onChange}>
                      <option>Select By Departments</option>
                      {departments.map((dept) => {
                        return (
                          <option value={dept.id} key={dept.id}>
                            {dept.name}
                          </option>
                        );
                      })}
                      <option value="all">All Departments</option>
                    </select>
                  </span>

                  <small className="mt-2">Filter By:</small>
                  <span>
                    <select className="form-control" name="status" onChange={onChange}>
                      <option>Select By Status</option>
                      <option value="rejected">Rejected</option>
                      <option value="approved">Approved</option>
                      <option value="queued">Queued</option>
                    </select>
                  </span>
                  {/* fillter by category */}
                  <small className="mt-2">Filter By:</small>
                  <span>
                    <select className="form-control" name="category" onChange={onChange}>
                      <option>Select By Category</option>
                      <option value="petty-cash">Petty Cash</option>
                      <option value="requisition">Requisition</option>
                    </select>
                  </span>
                  <small className="mt-2">Filter By Country:</small>
                  <span>
                    <select className="form-control" name="country" onChange={onChange}>
                      <option>Select Country</option>
                      <option value="ng">Nigeria</option>
                      <option value="gh">Ghana</option>
                      <option value="cv">Cote d'Ivoire</option>
                    </select>
                  </span>
                  <div className="mt-1 mb-3">
                    <button
                      onClick={() => handleSubmit(search, page)}
                      className="btn btn-green copy-font"
                      disabled={!submit()}
                    >
                      Search
                    </button>
                  </div>
                </div>

                <div>
                  <MakeRequisition />
                </div>
              </div>

              {requistions.length > 1 ? <DownloadRequisitions requistions={requistions} /> : null}
              {searchRequisitions()}

              {
                // Uncomment if adding bulk approval or disapproval for executivs
                /* <div>
                {requistions.filter((item) => item.isChecked).length > 0 && (
                  <button
                    className="btn btn-green mx-4 downloadButton"
                    name=""
                    data-toggle="modal"
                    data-target="#bulkApproveOrRejectRequisition"
                  >
                    Bulk Approval Or Rejection
                  </button>
                )}
              </div>
              <BulkApprovalOrRejection req={requistions} /> */
              }
              <ExecutivesRequsitions
                load={isFetching}
                handlePageClick={handlePageClick}
                totalPages={totalPages}
                approving={approving}
                requistions={requistions}
                giveExecutiveApproval={giveExecutiveApproval}
                giveExecutiveDisApproval={giveExecutiveDisApproval}
                showModal={showModal}
                onClickShowModal={onClickShowModal}
                setCheckAllRequisition={setCheckAllRequisition}
                setCheckOneOfAllRequisition={setCheckOneOfAllRequisition}
              />
            </>
          </RequisitionProvider>
        );
      default:
        return (
          <RequisitionProvider
            value={{
              makeRequisition,
              onChangeRequisition,
              requisition,
              onFileChange,
              file,
              percentage,
              isLoading,
            }}
          >
            <>
              <div className="col-lg-12 col-md-9 col-sm-12 mt-3 mb-3 d-flex">
                <div className="fliter copy-font d-flex mr-auto">
                  <small className="mt-2">Filter By:</small>
                  <span>
                    <select className="form-control" name="department_id" onChange={onChange}>
                      <option>Select By Departments</option>
                      {departments.map((dept, index) => {
                        return (
                          <option value={dept.id} key={index}>
                            {dept.name}
                          </option>
                        );
                      })}
                    </select>
                  </span>

                  <small className="mt-2">Filter By:</small>
                  <span>
                    <select className="form-control" name="status" onChange={onChange}>
                      <option>Select By Status</option>
                      <option value="rejected">Rejected</option>
                      <option value="approved">Approved</option>
                      <option value="queued">Queued</option>
                    </select>
                  </span>

                  {/* fillter by category */}
                  <small className="mt-2">Filter By:</small>
                  <span>
                    <select className="form-control" name="category" onChange={onChange}>
                      <option>Select By Category</option>
                      <option value="petty-cash">Petty Cash</option>
                      <option value="requisition">Requisition</option>
                    </select>
                  </span>

                  <small className="mt-2">Filter By:</small>
                  <span>
                    <select className="form-control" name="country" onChange={onChange}>
                      <option>Filter By Country</option>
                      <option value="ng">Nigeria</option>
                      <option value="gh">Ghana</option>
                      <option value="cv">Cote d'Ivoire</option>
                    </select>
                  </span>
                  <div className="mt-1 mb-3">
                    <button
                      onClick={() => handleSubmit(search, page)}
                      className="btn btn-green copy-font"
                      disabled={!submit()}
                    >
                      Search
                    </button>
                  </div>
                </div>
                <div>
                  <MakeRequisition />
                </div>
              </div>

              <AccountsPersonnelRequistions load={isFetching} requistions={requistions} />
            </>
          </RequisitionProvider>
        );
    }
  };
  return (
    <div className="container">
      <div className="row mt-5">{checkRole()}</div>
      <ToastContainer />
    </div>
  );
};

export default AccountsHrRequisitions;
