/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useBandwidth } from '../../../../../hooks/partnership/bandwidth/useBandwidth';

const EditPartnershipBandwidth = ({
  request,
  show,
  setShow,
  getBandwidth,
  ink,
  request: { id, rxspeed, rxtotalaverage, txspeed, txtotalaverage, ip, basestation, time },
}) => {
  const { load, editBandwidth } = useBandwidth();

  const reload = () => {
    getBandwidth();
  };

  const [one, setOne] = useState({
    base: '',
    ip_address: '',
    rx_speed: '',
    tx_speed: '',
    tx_totalaverage: '',
    rx_totalaverage: '',
    times: '',
  });
  const { base, ip_address, rx_speed, tx_speed, tx_totalaverage, rx_totalaverage, times } = one;
  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    const result = {
      base: basestation,
      ip_address: ip,
      times: time,
      rx_speed: rxspeed,
      tx_speed: txspeed,
      rx_totalaverage: rxtotalaverage,
      tx_totalaverage: txtotalaverage,
    };
    setOne({ ...result });
  }, [request]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOne({ ...one, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      id: id,
      download_speed: rx_speed,
      download_total_average: rx_totalaverage,
    };
    editBandwidth(data, handleClose, reload, ink);
  };

  // const submitForm = () => {
  //   return rx_speed.length > 0 && rx_totalaverage.length > 0;
  // };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ paddingLeft: '2rem' }}>Edit Bandwidth</Modal.Title>
      </Modal.Header>
      <form onSubmit={onSubmit} className="col-md-12">
        <div className="pt-2 d-flex">
          <div className="pr-3 form-group col-md-6">
            <label className="m-0 p-0 copy-font" htmlFor="basestation">
              Basestation
            </label>
            <input
              type="text"
              className="form-control border border-info py-2"
              id="basestation"
              name="base"
              value={base}
              onChange={handleChange}
              readOnly
            />
          </div>
        </div>
        <div className="pt-2 d-flex">
          <div className="pl-3 form-group col-md-6">
            <label className="m-0 p-0 copy-font" htmlFor="ip">
              IP address
            </label>
            <input
              type="text"
              className="form-control border border-info py-2"
              id="ip"
              name="ip_address"
              value={ip_address}
              onChange={handleChange}
              readOnly
            />
          </div>
        </div>
        <div className="d-flex">
          <div className="pr-3 form-group col-md-12">
            <label className="m-0 p-0 copy-font" htmlFor="time">
              Time
            </label>
            <input
              type="text"
              className="form-control border border-info py-2"
              id="time"
              name="times"
              value={times}
              onChange={handleChange}
              readOnly
            />
          </div>
        </div>
        <div className="d-flex">
          <div className="pr-3 form-group col-md-12">
            <label className="m-0 p-0 copy-font" htmlFor="txspeed">
              Upload Speed
            </label>
            <input
              type="text"
              className="form-control border border-info py-2"
              id="txspeed"
              name="tx_speed"
              value={tx_speed}
              onChange={handleChange}
              readOnly
            />
          </div>
        </div>
        <div className="d-flex">
          <div className="pr-3 form-group col-md-12">
            <label className="m-0 p-0 copy-font" htmlFor="rxspeed">
              Download Speed
            </label>
            <input
              type="text"
              className="form-control border border-info py-2"
              id="rxspeed"
              name="rx_speed"
              value={rx_speed}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="d-flex">
          <div className="pr-3 form-group col-md-12">
            <label className="m-0 p-0 copy-font" htmlFor="txtotalaverage">
              Upload Total Average
            </label>
            <input
              type="text"
              className="form-control border border-info py-2"
              id="txtotalaverage"
              name="tx_totalaverage"
              value={tx_totalaverage}
              onChange={handleChange}
              readOnly
            />
          </div>
        </div>

        <div className="d-flex">
          <div className="pr-3 form-group col-md-12">
            <label className="m-0 p-0 copy-font" htmlFor="rxtotalaverage">
              Download Total Average
            </label>
            <input
              type="text"
              className="form-control border border-info py-2"
              id="rxtotalaverage"
              name="rx_totalaverage"
              value={rx_totalaverage}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="text-center mt-3">
          {load ? (
            <button type="button" className="btn btn-green py-2 px-5">
              Editing...
            </button>
          ) : (
            <button type="submit" className="btn btn-green py-2 px-5">
              Submit
            </button>
          )}
        </div>
      </form>
    </Modal>
  );
};

EditPartnershipBandwidth.propTypes = {
  request: PropTypes.objectOf.isRequired,
};

export default EditPartnershipBandwidth;
