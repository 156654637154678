import React from 'react';

const SearchKpi = () => {
  return (
    <div className="d-flex mr-auto">
      <small className="w-100 mr-3 mt-3">Filter By:</small>
      <input type="date" className="form-control w-100 h-25 p-4" />
    </div>
  );
};

export default SearchKpi;
