import * as React from 'react';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';

export default function CheckboxesGroup({ state, handleChange }) {
  const { active, inactive, activeInactive } = state;
  const error = [active, inactive, activeInactive].filter((v) => v).length === 0;

  return (
    <Box sx={{ display: 'flex' }}>
      <FormControl
        required
        error={error}
        component="fieldset"
        sx={{ m: 1, display: 'flex', flexDirection: 'row' }}
        variant="standard"
      >
        <FormLabel component="legend">Select at least One</FormLabel>
        <FormGroup sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
          <FormControlLabel
            control={<Checkbox checked={activeInactive} onChange={handleChange} name="activeInactive" />}
            label="Sum of Active & Inactive / Expired Users"
          />
          <FormControlLabel
            control={<Checkbox checked={active} onChange={handleChange} name="active" />}
            label="Active Users"
          />
          <FormControlLabel
            control={<Checkbox checked={inactive} onChange={handleChange} name="inactive" />}
            label="Inactive / Expired Users"
          />
        </FormGroup>
        {/* <FormHelperText>You must select at least one value</FormHelperText> */}
      </FormControl>
    </Box>
  );
}
