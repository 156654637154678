/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import toastr from 'toastr';
import { connect } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import Logout from '../../../shared/auth/Logout';
import mailblast from '../../../../../assets/img/icons/mailblast.svg';
import { getLoggedInUser } from '../../../../../actions/user/UserActions';
import { getUser } from '../../../../../actions/shared/user/UserActions';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import { admin, operations, TECHNICAL_SUPPORT } from '../../../../../utils/config';
import FieldSupportSideBar from '../../../../layouts/field-support/side-bar/FieldSupportSideBar';
import CustomersTable from './CustomersTable';

class FieldSupportCustomers extends Component {
  componentDidMount() {
    this.checkDepartment();
    // eslint-disable-next-line react/destructuring-assignment
    this.props.getUser();
  }

  componentWillReceiveProps() {
    this.checkDepartment();
  }

  checkDepartment = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (
        response.data.department_id !== `${operations}` &&
        response.data.department_id !== `${TECHNICAL_SUPPORT}` &&
        response.data.department_id !== `${admin}`
      ) {
        const { history } = this.props;
        sessionStorage.setItem('token', '');
        sessionStorage.clear();
        history.push('/');
        toastr.info('You are not allowed to view this page');
      }
    } catch (e) {
      if (e) {
        toastr.info('Something went wrong!');
      }
    }
  };

  render() {
    const { user } = this.props;
    const { firstName } = user;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <Link className="s-sidebar__trigger" to="#">
                <i className="fa fa-bars mr-3" />
              </Link>
              <FieldSupportSideBar />

              <h3 className="col-lg-6 overview-part">Customer</h3>

              <div className="d-flex profile-mail">
                <Link
                  to="/field-support/mail-blast"
                  className="d-none d-md-flex"
                  style={{ color: 'gray', textDecoration: 'none' }}
                >
                  <img src={mailblast} alt="" className="logout mr-2" />
                  <span>
                    <h6 className="mt-2 mr-3">
                      <strong>MailBlast</strong>
                    </h6>
                  </span>
                </Link>
                <h6 className="mr-0 mt-1">{firstName}</h6>
                <Logout />
              </div>
            </div>
          </div>
          <div className="col-12 mt-5">
            <CustomersTable />
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

FieldSupportCustomers.propTypes = {
  history: PropTypes.shape(),
  getUser: PropTypes.func,
  user: PropTypes.shape(),
};

export default connect(
  mapStateToProps,
  { getLoggedInUser, getUser },
)(FieldSupportCustomers);
