import { useState, useReducer } from 'react';
import axios from 'axios';
import { stringify } from 'qs';
import toastr from 'toastr';
import * as url from '../../services/api/customer-support/customers/archives/ArchivesUrl';
import { archivesReducer } from '../../reducers/customer-support/customers/archives/archivesReducer';
import * as types from '../../constants/types/customer-support/CustomerSupportTypes';

const useArchive = () => {
  const state = {
    mac: '',
    from: '',
    to: '',
  };

  const [mac, setMac] = useState('');
  const [search, setSearch] = useState(state);

  const onChangeForSession = (e) => {
    const { value } = e.target;
    setMac(value);
  };

  const onChangeForUsage = (e) => {
    const { value, name } = e.target;
    setSearch({
      ...search,
      [name]: value,
    });
  };

  const [{ fetching, loading, session, usage }, dispatch] = useReducer(archivesReducer, {
    fetching: false,
    loading: false,
    session: [],
    usage: [],
  });

  const getUserSession = async (data) => {
    dispatch({
      type: types.START_GETTING_CUSTOMER_SESSION_DETAILS,
    });
    try {
      const response = await axios.post(`${url.GET_CUSTOMER_SESSION_DETAILS_URL}`, stringify(data));
      if (response.data.status === false) {
        toastr.info(response.data.error);
        dispatch({
          type: types.STOP_GETTING_CUSTOMER_SESSION_DETAILS,
        });
      } else {
        dispatch({
          type: types.GET_CUSTOMER_SESSION_DETAILS,
          payload: response.data,
        });
      }
    } catch (e) {
      if (e) {
        dispatch({
          type: types.STOP_GETTING_CUSTOMER_SESSION_DETAILS,
        });
      }
    }
  };

  const searcUsageWithDate = async (data) => {
    dispatch({
      type: types.START_GETTING_CUSTOMER_USAGE_WITH_DATE,
    });
    try {
      const response = await axios.post(`${url.GET_CUSTOMER_USAGE_WITH_DATE_URL}`, stringify(data));
      if (response.data.status === true) {
        dispatch({
          type: types.GET_CUSTOMER_USAGE_WITH_DATE,
          payload: response.data.items,
        });
      } else {
        dispatch({
          type: types.STOP_GETTING_CUSTOMER_USAGE_WITH_DATE,
        });
        toastr.info('Unable to get this customer usage');
      }
    } catch (e) {
      if (e) {
        dispatch({
          type: types.STOP_GETTING_CUSTOMER_USAGE_WITH_DATE,
        });
      }
    }
  };

  return {
    mac,
    onChangeForSession,
    getUserSession,
    fetching,
    session,
    loading,
    onChangeForUsage,
    search,
    searcUsageWithDate,
    usage,
  };
};
export default useArchive;
