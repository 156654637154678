import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FailedInstallationsTable from '../../installations/FailedInstallationsTable';
import { getFailedInstallations } from '../../../../../actions/shared/installations/InstallationsActions';

class FailedInstallations extends Component {
  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment
    // eslint-disable-next-line react/destructuring-assignment
    this.props.getFailedInstallations();
  }

  render() {
    const { installations, load } = this.props;
    const { users } = installations;

    return (
      <div className="col-12 after-summary">
        <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-3">
          <div className="row">
            {/* <div className="row"> */}
            {/*  <div className="col-lg-12 col-md-9 col-sm-12 mt-3 mb-3 d-flex"> */}
            {/*    <form className="form-inline d-flex inner-addon left-addon w-100" */}
            {/*      // onSubmit={this.searchByParams} */}
            {/*          onSubmit={this.searchByParams}> */}
            {/*      <input */}
            {/*        onChange={this.onChange} */}
            {/*        required={true} */}
            {/*        // onChange={e => this.searchCallLog(e)} */}
            {/*        className="form-control mr-sm-2 empty mt-1" */}
            {/*        id="iconified1" */}
            {/*        type="text" */}
            {/*        name="params" */}
            {/*        placeholder="Search By Last Name, Email and Phone number"/> */}
            {/*    </form> */}
            {/*    <p className="fliter copy-font d-flex mr-auto"> */}
            {/*      <small className="mt-2 w-100">From:</small> */}
            {/*      <input type="date" */}

            {/*             name="fromParams" */}
            {/*             required={true} */}
            {/*             onChange={this.onChange} */}
            {/*             className="form-control" /> */}
            {/*      <small className="mt-2 ml-2 mr-2">to:</small> */}
            {/*      <input type="date" */}

            {/*             required={true} */}
            {/*             onChange={this.onChange} */}
            {/*             className="form-control" /> */}
            {/*    </p> */}

            {/*    <div className="d-flex ml-3 mt-3"> */}
            {/*      <div> */}
            {/*        <button className="btn btn-green" type="submit">Search</button> */}
            {/*      </div> */}
            {/*    </div> */}

            {/*  </div> */}
            {/* </div> */}

            <div className="card x-panel logs">
              <div className="table-responsive content">
                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Account Alias</th>
                      <th>Address</th>
                      <th>Country</th>
                      <th>Installation Comment address</th>
                      <th>Payment Reference</th>
                      <th>State</th>
                      {/* <th>Entered By</th> */}
                      {/* <th>Assign to</th> */}
                      <th>Status</th>
                    </tr>
                  </thead>
                  <FailedInstallationsTable failed={users} load={load} />
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  installations: state.installs.failed,
  load: state.installs.loading,
});

FailedInstallations.propTypes = {
  // user: PropTypes.shape(),
  getFailedInstallations: PropTypes.func,
  history: PropTypes.shape(),
  load: PropTypes.bool,
  // installations: PropTypes.shape(),
  installations: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};
export default connect(
  mapStateToProps,
  {
    getFailedInstallations,
  },
)(FailedInstallations);
