import React from "react"
import { useState } from "react"
import axios from "axios"
import { toast } from 'react-toastify';
import {
    GET_BIZDEV_USERS
} from '../../services/api/business-development/DedicatedCustomersUrl';


const useBizDevUsers = () => {

    const [loading, setLoading] = useState(false)
    const [usersData, setUsersData] = useState({
        list: [],
        count: 0,
        pages: 0
    })

    const getBizDevUsers = async () => {
        setLoading(true)
        try {
            const response = await axios.post(`${GET_BIZDEV_USERS}`, {}, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                },
            });
            if (response.status === 200) {
                setUsersData({
                    ...usersData,
                    list: response.data.result.list,
                    count: response.data.result.count,
                    pages: response.data.result.pages
                })
            }
        } catch (e) {
            toast.info('Something went wrong!');
        }

        setLoading(false)

    }

    const filterBizDevUsers = async (filters) => {
        setLoading(true)
        const data = {
            [filters.filter]: filters.filterTerm
        }
        try {
            const response = await axios.post(`${GET_BIZDEV_USERS}`, data, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                },
            });
            if (response.status === 200) {
                setUsersData({
                    ...usersData,
                    list: response.data.result.list,
                    count: response.data.result.count,
                    pages: response.data.result.pages
                })
            }
        } catch (e) {
            toast.info('Something went wrong!');
        }

        setLoading(false)
    }

    return {
        loading,
        usersData,
        getBizDevUsers,
        filterBizDevUsers
    }

}

export default useBizDevUsers