import React from 'react';
import AppContext from '../../../../../../context/shared/AppContext';
import useLTEInstallation from '../hooks/useLTEInstallation';
import { LTE_EXPIRED_CUSTOMERS } from '../../../../../../services/api/asset-team/AssetTeamUrl';

const LteInstallationsProvider = ({ children }) => {
  const {
    // LTEInstallationTrackerItemCount,
    // LTEInstallationDailyTracker,
    // LTEInstallationWeeklyTracker,
    // LTEInstallationMonthlyTracker,
    searchLteInstallation,
    searchType,
    loading,
    data,
    count,
  } = useLTEInstallation(`${LTE_EXPIRED_CUSTOMERS}`);
  return (
    <AppContext.Provider
      value={{
        // LTEInstallationTrackerItemCount,
        // LTEInstallationDailyTracker,
        // LTEInstallationWeeklyTracker,
        // LTEInstallationMonthlyTracker,
        searchLteInstallation,
        searchType,
        loading,
        data,
        count,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export default LteInstallationsProvider;
