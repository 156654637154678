/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/require-default-props */
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

const CallogDetails = ({ log }) => {
  return (
    <div
      id="callogdetails"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4
              className="modal-title text-center"
              id="myModalLabel"
            >
              Call log Details
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              ×
            </button>
          </div>
          <div
            className="modal-body detail"
            style={{ padding: '0', textAlign: 'justify' }}
          >
            <div className="row">
              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label1">
                  <label id="fn">First Name</label>
                  <label id="ln">Last Name</label>
                </div>

                <div className="col-lg-8 detail-result1">
                  <p className="mt-4 mb-1">
                    {log.firstName}
                  </p>
                  <p className="mb-1">{log.lastName}</p>
                </div>
              </div>
              <hr />
              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label2">
                  <label id="phone1">Phone number </label>
                  <label id="phone2">Phone number 2</label>
                  <label id="email1">Email Address </label>
                  <label id="email2">Email Address 2</label>
                  <label id="address">Address</label>
                </div>

                <div className="col-lg-8 detail-result2">
                  <p className="mt-4 mb-1">
                    {log.phoneNumber}
                  </p>
                  <p className="mb-1">
                    {log.alternatePhoneNumber
                      ? log.alternatePhoneNumber
                      : 'N/A'}
                  </p>
                  <p className="mb-1">{log.email}</p>
                  <p className="mb-1">
                    {log.alternateEmail
                      ? log.alternateEmail
                      : 'N/A'}
                  </p>
                  {log.address ? (
                    <p className="mb-1 mt-2">
                      {log.address}
                    </p>
                  ) : (
                    <p className="mb-1 mt-2">N/A</p>
                  )}
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label3">
                  <label id="plan">Plan</label>
                  <label id="source">Comment</label>
                </div>

                <div className="col-lg-8 detail-result3">
                  <p className="mt-4 mb-1">
                    {log.plan ? log.plan : 'N/A'}
                  </p>
                  <p className="mb-1">
                    {log.comment ? log.comment : 'N/A'}
                  </p>
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label4">
                  <label id="recorded">Status</label>
                  <label id="date">Date</label>
                </div>

                <div className="col-lg-8 detail-result4">
                  <p className="mt-4 mb-1">{log.status}</p>
                  <p className="mt-4 mb-1">
                    {moment(log.created_at).format(
                      'YY-MM-DD',
                    )}
                  </p>
                </div>
              </div>

              {/* <div className="col-lg-12 text-center p-3">
                                    <button className="btn btn-second">Edit</button>
                                </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CallogDetails.propTypes = {
  log: PropTypes.shape(),
};
export default CallogDetails;
