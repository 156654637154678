import React, { Component } from 'react';
import Ghana from '../../../departments/field-support/los/Ghana';
import Nigeria from '../../../departments/field-support/los/Nigeria';

class NoLos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  changeMap = () => {
    const { show } = this.state;
    this.setState({
      show: !show,
    });
  };

  render() {
    const { show } = this.state;

    return (
      <div className="col-12 after-summary">
        <div className="col-lg-2">
          <select name="" onChange={this.changeMap} className="form-control">
            {/* <option value="">Show Country</option> */}
            <option value="">Nigeria</option>
            <option value="">Ghana</option>
          </select>
        </div>
        {show ? <Ghana /> : <Nigeria />}
      </div>
    );
  }
}

export default NoLos;
