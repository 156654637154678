import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { PROCUREMENT, SUPER_ADMIN } from '../../../../../utils/departments/helpers';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import ProcurementNavBar from '../../../../layouts/procurement/ProcurementNavBar';
import CleanRadioBody from './CleanRadioBody';
import CleanRadioProvider from './provider/CleanRadioProvider';
import useCheckTwoDepartments from '../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';

const ProcurementCleanRadio = ({ history }) => {
  useCheckTwoDepartments(`${PROCUREMENT}`, `${SUPER_ADMIN}`, history);

  const { profile } = useUser();
  return (
    <div className="s">
      <main className="s-layout__content col-lg-10 p-0">
        <ProcurementNavBar currentPage="Clean Radio" position={profile.firstName} />
        <CleanRadioProvider>
          <CleanRadioBody />
        </CleanRadioProvider>
      </main>
    </div>
  );
};

export default ProcurementCleanRadio;
