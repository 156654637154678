import React from 'react';
import { Modal } from 'react-bootstrap';
import { TizetiGIFLogoTable } from '../../../../../utils/loader/loadingHelper';
import moment from 'moment';

// eslint-disable-next-line react/prop-types
const RefundList = ({ show, fetching, handleClose, items }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Refund List</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {fetching === true ? (
          <TizetiGIFLogoTable> </TizetiGIFLogoTable>
        ) : (
          <div className="modal-body modal-dialog-scrollable detail" style={{ padding: '0' }}>
            {items.length == 0 || items.length == 20
              ? 'NO REFUND REQUEST ON THIS INSTALLATION'
              : items.map((item, index) => {
                  return (
                    <div className="row">
                      <div className="col-lg-12 d-flex">
                        <div className="col-lg-4 detail-label4">
                          <label>Initiated by</label>
                          <label>Date Created</label>
                        </div>

                        <div className="col-lg-4 detail-result4 pt-4">
                          {' '}
                          <p className=" mb-1">
                            {(item.initiatedBy && item.initiatedBy.firstName + ' ' + item.initiatedBy.lastName) ||
                              'N/A'}
                          </p>
                          <p className=" mb-1">
                            {' '}
                            {item.created_at ? moment(item.created_at).format('DD-MM-YYYY') : 'N/A'}
                          </p>
                        </div>
                      </div>

                      <div className="col-lg-12 d-flex">
                        <div className="col-lg-4 detail-label2">
                          <label>FSE Status</label>
                          <label>Approved by</label>
                          <label>Date Updated</label>
                          <label>Comment</label>
                        </div>

                        <div className="col-lg-8 detail-result2 pt-4">
                          <p className=" mb-1">{item.fse_approval && item.fse_approval.toUpperCase()}</p>
                          <p className=" mb-1">
                            {(item.fseApprovalBy && item.fseApprovalBy.firstName + ' ' + item.fseApprovalBy.lastName) ||
                              'N/A'}
                          </p>
                          <p className=" mb-1">
                            {' '}
                            {item.fse_updated_at ? moment(item.fse_updated_at).format('DD-MM-YYYY') : 'N/A'}
                          </p>
                          <p className=" mb-1">{(item.fse_comment && item.fse_comment) || 'N/A'}</p>
                        </div>
                      </div>

                      <div className="col-lg-12 d-flex">
                        <div className="col-lg-4 detail-label3">
                          <label>SALES Status</label>
                          <label>Approved by</label>
                          <label>Date Updated</label>
                          <label>Comment</label>
                        </div>

                        <div className="col-lg-8 detail-result3 pt-4">
                          <p className="mb-1">{item.sales_approval && item.sales_approval.toUpperCase()}</p>
                          <p className=" mb-1">
                            {(item.salesApprovalBy &&
                              item.salesApprovalBy.firstName + ' ' + item.salesApprovalBy.lastName) ||
                              'N/A'}
                          </p>
                          <p className=" mb-1">
                            {' '}
                            {item.sales_updated_at ? moment(item.sales_updated_at).format('DD-MM-YYYY') : 'N/A'}
                          </p>
                          <p className=" mb-1">{(item.sales_comment && item.sales_comment) || 'N/A'}</p>
                        </div>
                      </div>

                      <div className="col-lg-12 d-flex">
                        <div className="col-lg-4 detail-label4">
                          <label>COO Status</label>
                          <label>Approved by</label>
                          <label>Date Updated</label>
                          <label>Comment</label>
                        </div>

                        <div className="col-lg-4 detail-result4 pt-4">
                          {' '}
                          <p className="mb-1">{item.coo_approval && item.coo_approval.toUpperCase()}</p>
                          <p className=" mb-1">
                            {(item.cooApprovalBy && item.cooApprovalBy.firstName + ' ' + item.cooApprovalBy.lastName) ||
                              'N/A'}
                          </p>
                          <p className=" mb-1">
                            {' '}
                            {item.coo_updated_at ? moment(item.coo_updated_at).format('DD-MM-YYYY') : 'N/A'}
                          </p>
                          <p className=" mb-1">{(item.coo_comment && item.coo_comment) || 'N/A'}</p>
                        </div>
                      </div>

                      <div className="col-lg-12 d-flex">
                        <div className="col-lg-4 detail-label2">
                          <label>ACCOUNT Status</label>
                          <label>Uploaded by</label>
                          <label>Date Uploaded</label>
                          <label>Paid by</label>
                          <label>Date Paid</label>
                          <label>Comment</label>
                        </div>
                        <div className="col-lg-8 detail-result2 pt-4">
                          {' '}
                          <p className="mb-1">{item.refund_status && item.refund_status.toUpperCase()}</p>
                          <p className=" mb-1">
                            {(item.uploadedBy && item.uploadedBy.firstName + ' ' + item.uploadedBy.lastName) || 'N/A'}
                          </p>
                          <p className=" mb-1">
                            {' '}
                            {item.uploaded_at ? moment(item.uploaded_at).format('DD-MM-YYYY') : 'N/A'}
                          </p>
                          <p className=" mb-1">
                            {(item.approvedBy && item.approvedBy.firstName + ' ' + item.approvedBy.lastName) || 'N/A'}
                          </p>
                          <p className=" mb-1">
                            {' '}
                            {item.approved_at ? moment(item.approved_at).format('DD-MM-YYYY') : 'N/A'}
                          </p>
                          <p className=" mb-1">{(item.account_comment && item.account_comment) || 'N/A'}</p>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="col-lg-12 bg-info">
                          <label>KYC IMAGES</label>
                        </div>
                        {item.installation.installationUploads.length > 0 && (
                          <>
                            {item.installation.installationUploads.map((img, index) => (
                              <div key={index}>
                                <div className="col-12 mb-3 d-flex">
                                  {img.contact_information_image === null ? (
                                    'No Record'
                                  ) : (
                                    <div>
                                      {img.contact_information_image.split('.').pop() === 'pdf' ? (
                                        <a href={img.contact_information_image}>
                                          {' '}
                                          <i className="fa fa-download" aria-hidden="true">
                                            {' '}
                                            {''} Download Pdf
                                          </i>{' '}
                                        </a>
                                      ) : (
                                        <img src={img.contact_information_image} className="img-fluid" alt="test" />
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="col-12 mb-3 d-flex">
                                  {img.user_kyc === null ? (
                                    'No Record'
                                  ) : (
                                    <div>
                                      {img.user_kyc.split('.').pop() === 'pdf' ? (
                                        <a href={img.user_kyc}>
                                          {' '}
                                          <i className="fa fa-download" aria-hidden="true">
                                            {' '}
                                            {''} Download Pdf
                                          </i>{' '}
                                        </a>
                                      ) : (
                                        <img src={img.user_kyc} className="img-fluid" alt="test" />
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="col-12 mb-3 d-flex">
                                  {img.pole_image === null ? (
                                    'No Record'
                                  ) : (
                                    <div>
                                      {img.pole_image.split('.').pop() === 'pdf' ? (
                                        <a href={img.pole_image}>
                                          {' '}
                                          <i className="fa fa-download" aria-hidden="true">
                                            {' '}
                                            {''} Download Pdf
                                          </i>{' '}
                                        </a>
                                      ) : (
                                        <img src={img.pole_image} className="img-fluid" alt="test" />
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="col-12 mb-3 d-flex">
                                  {img.img_4 === null ? (
                                    'No Record'
                                  ) : (
                                    <div>
                                      {img.img_4.split('.').pop() === 'pdf' ? (
                                        <a href={img.img_4}>
                                          {' '}
                                          <i className="fa fa-download" aria-hidden="true">
                                            {' '}
                                            {''} Download Pdf
                                          </i>{' '}
                                        </a>
                                      ) : (
                                        <img src={img.img_4} className="img-fluid" alt="test" />
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="col-12 mb-3 d-flex">
                                  {img.sla === null ? (
                                    ''
                                  ) : (
                                    <div>
                                      {img.sla.split('.').pop() === 'pdf' ? (
                                        <a href={img.sla}>
                                          {' '}
                                          <i className="fa fa-download" aria-hidden="true">
                                            {' '}
                                            {''} Download Pdf
                                          </i>{' '}
                                        </a>
                                      ) : (
                                        <img src={img.sla} className="img-fluid" alt="test" />
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            ))}
                          </>
                        )}
                        {item.installation.installationUploads.length < 0 && (
                          <div className="my-2 text-center"> No images available</div>
                        )}
                      </div>
                    </div>
                  );
                })}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default RefundList;
