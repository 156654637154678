/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import { NoItemRow } from '../../../../../utils/loader/loadingHelper'


const DeviceTypesTable = ({ items, isLoading }) => {
  

  const colSpan = '8';

 
  const tableData =
    items && items.length ? (
      items.map((item, i) => {
        return (
          <tr className="text-nowrap" key={i}>
            <td>{i + 1}</td>
            <td>
              {item.user.firstName} {item.user.lastName}
            </td>
           
            <td>{item.mac_id}</td>
            <td>{item.device_type}</td>
            <td>{item.user.state}</td>

         
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan={colSpan} text="There are no records" />
    );
  return (
    <div className="card x-panel mt-2 px-0">
      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <thead>
            <tr className="">
              <th>#</th>
              <th>Name</th>
              <th>Mac ID</th>
              <th>Device Type</th>
              <th>State</th>
              
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td className="text-center">
                  <img src={loader} alt="loading gif" />
                </td>
              </tr>
            ) : (
              tableData
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DeviceTypesTable;
