import * as load from '../../../constants/types/shared/LoadingTypes';
import * as types from '../../../constants/types/sales/installations/InstallationsTypes';

export const activationReducer = (state, action) => {
  const { payload, type } = action;
  switch (type) {
    case types.GET_BASESTATIONS:
      return {
        ...state,
        basestations: payload,
      };

    case types.GET_BILLING_ESTATES:
      return {
        ...state,
        estates: payload,
      };

    case load.LOADING_STARTS:
      return {
        ...state,
        isLoading: true,
      };

    case load.LOADING_STOPS:
      return {
        ...state,
        isLoading: false,
      };

    case load.FETCHING_STARTS:
      return {
        ...state,
        isFetching: true,
      };

    case load.FETCHING_STOPS:
      return {
        ...state,
        isFetching: false,
      };

    case load.FETCHING_DIMENSION_STARTS:
      return {
        ...state,
        fetching: true,
      };

    case load.FETCHING_DIMENSION_STOPS:
      return {
        ...state,
        fetching: false,
      };

    case load.FETCHING_DIMENSION:
      return {
        ...state,
        dimension2: 'EMPTY',
        fetching: false,
      };

    case types.GET_DIMENSION1:
      return {
        ...state,
        dimension1: payload,
      };

    case types.GET_DIMENSION2:
      return {
        ...state,
        dimension2: payload,
        fetching: false,
      };

    case load.CHECKING_MAC_STARTS:
      return {
        ...state,
        isChecking: true,
      };

    case load.CHECKING_MAC_STOPS:
      return {
        ...state,
        isChecking: false,
      };

    case types.CHECK_MAC_ID:
      return {
        ...state,
        isValid: payload.isValid,
        validMAC: payload.validMAC,
        isChecking: false,
      };

    case load.START_ACTIVATING_ACCOUNT:
      return {
        ...state,
        isActivating: true,
      };

    case load.STOP_ACTIVATING_ACCOUNT:
      return {
        ...state,
        isActivating: false,
      };

    case load.FETCH_SALES_TYPE_STARTS:
      return {
        ...state,
        fetchingSalesTypes: true,
      };

    case load.FETCH_SALES_TYPE_STOPS:
      return {
        ...state,
        fetchingSalesTypes: false,
      };

    case types.GET_SALES_TYPES:
      return {
        ...state,
        salesTypesArr: payload,
        fetchingSalesTypes: false,
      };

    default:
      return state;
  }
};

export default activationReducer;
