/* eslint-disable react/button-has-type */
import React from 'react';
import ReactPaginate from 'react-paginate';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import { useKpi } from '../../../../../hooks/noc/kpi/useKpi';
import { useBasestations } from '../../../../../hooks/shared/basestations/useBasestation';

const KpiTable = () => {
  const { basestations } = useBasestations();
  const { getKpi, kpis, isLoading, onChange, date } = useKpi();
  const { from, to, baseStation } = date;

  const kpiData = kpis ? (
    kpis.map((kpi, i) => (
      <tr>
        <td>{i + 1}</td>
        <td>{kpi.mttr === null ? 'no mttr' : kpi.mttr}</td>
      </tr>
    ))
  ) : (
    <small>no kpis</small>
  );

  const submitForm = () => {
    return baseStation.length > 0;
  };

  return (
    <div className="page-wrapper col-lg-10">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 mt-3 mb-3 d-flex inner-addon left-addon">
            <select name="baseStation" id="" onChange={onChange}>
              <option value="">Choose Basestation</option>
              {basestations.map((basestation) => {
                return (
                  <option key={basestation.id} value={basestation.id}>
                    {basestation.name}
                  </option>
                );
              })}
            </select>
            <span>
              From:
              <input type="date" onChange={onChange} name="from" value={from} />
            </span>
            <span>
              To:
              <input type="date" onChange={onChange} name="to" value={to} />
            </span>
            <button className="btn btn-green copy-font" onClick={() => getKpi(date)} disabled={!submitForm()}>
              Submit
            </button>
          </div>

          <div className="card x-panel">
            <div className="table-responsive content">
              <table className="table copy-font">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>MTR</th>
                  </tr>
                </thead>

                {isLoading ? (
                  <div>
                    <img src={loader} alt="loading gif" />
                  </div>
                ) : (
                  <tbody id="reduce-length">{kpiData}</tbody>
                )}
              </table>
            </div>
          </div>
          {kpis.length > 20 ? (
            <ReactPaginate
              previousLabel="Previous"
              nextLabel="Next"
              breakLabel="..."
              breakClassName="break-me"
              // pageCount={pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              // onPageChange={this.handlePageClick}
              containerClassName="pagination"
              subContainerClassName="pages pagination"
              activeClassName="active"
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default KpiTable;
