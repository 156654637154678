/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import toastr from 'toastr';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import RoutedInstallation from './RoutedInstallation';
import { OverviewContext } from '../../../../../context/shared/installations/overview/OverviewContext';
import usePost from '../../../../../hooks/shared/custom/data/geniatech/usePost';
import { BULK_ASSIGN_INSTALL_TEAM } from '../../../../../services/api/shared/installations/InstallationsUrl';
import AssignInstallation from './AssignInstallation';

const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  color: 'white',
  background: 'grey',
  // background: isDragging ? 'lightgreen' : 'grey',
  ...draggableStyle, // styles we need to apply on draggables
});
const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid,
  width: 800,
  margin: 12,
});

const DragAndDrop = ({ cluster, keys, teams }) => {
  const { postRoutedInstalls } = useContext(OverviewContext);
  const { getDataWithConfig } = usePost();
  const {
    profile: { firstName, lastName },
  } = useUser();

  const { zone, installation } = cluster;
  const [startPoint, setStartPoint] = useState('');
  const [to, setTo] = useState('');
  const [teamID, setTeamID] = useState('');

  const onSubmitRoute = (e) => {
    e.preventDefault();
    if (startPoint.length > 0) {
      const data = {
        zone,
        startPoint,
        name: `${firstName} ${lastName}`,
        selectedInstallations: installation,
      };
      postRoutedInstalls(data);
    } else toastr.error('Enter A Start Address');
  };

  const goTo = (params) => {
    setTo(params.installation_id);
  };

  const assignClusterToTeam = () => {
    const params = JSON.stringify(
      installation.map((install) => {
        return {
          id: install.installation_id,
          team_id: teamID,
        };
      }),
    );
    getDataWithConfig(`${BULK_ASSIGN_INSTALL_TEAM}`, params, 'Assigned To Team!', 'Error Assigning To Team');
  };

  return (
    <div className="py-3 mt-5">
      <form onSubmit={onSubmitRoute}>
        <div className="form-group">
          <p className="text-center text-uppercase h3 text-danger">Zone: {zone}</p>
          <input
            type="text"
            className="form-control border shadow"
            placeholder="Start Address"
            id="startPoint"
            name="startPoint"
            value={startPoint}
            onChange={(e) => setStartPoint(e.target.value)}
          />
        </div>

        <form>
          <div className="d-flex">
            <div className="col-6 mb-2">
              <label className="copy-font m-0 p-0">Assign To Team:</label>
              <select className="form-control" name="teamID" value={teamID} onChange={(e) => setTeamID(e.target.value)}>
                <option value="">--Select Team--</option>
                {teams.length ? (
                  teams.map((team) => (
                    <option key={team.id} value={team.id}>
                      {team.name.toUpperCase()}
                    </option>
                  ))
                ) : (
                  <option>Fetching Teams...</option>
                )}
              </select>
            </div>
            <div className="col-4 pt-4">
              <button
                type="button"
                className="btn btn-green"
                onClick={() => assignClusterToTeam()}
                disabled={installation.length <= 0}
              >
                Assign To Team
              </button>
            </div>
          </div>
        </form>

        <div className="form-group">
          <label htmlFor="text" className="p-0 col-form-label">
            Installations:
          </label>
          <div className="table-responsive content bg-info" style={{ background: '' }}>
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Address</th>
                  <th>Lat Lng</th>
                  <th>Distance</th>
                  <th>Assign</th>
                </tr>
              </thead>
              <Droppable droppableId={`droppable${keys}`}>
                {(provided, snapshot) => (
                  <tbody ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                    {cluster.installation.map((install, index) => {
                      const { latitude, longitude, address, name, distance, unit } = install;
                      return (
                        <Draggable key={index} draggableId={`draggable${install.name}`} index={index}>
                          {(provideds, snapshots) => (
                            <tr
                              ref={provideds.innerRef}
                              {...provideds.draggableProps}
                              {...provideds.dragHandleProps}
                              style={getItemStyle(snapshots.isDragging, provideds.draggableProps.style)}
                            >
                              <td>{index + 1}</td>
                              <td>{name}</td>
                              <td>{address}</td>
                              <td>
                                {latitude} {longitude}
                              </td>
                              <td>
                                {parseFloat(distance.toFixed(2))} {unit}
                              </td>
                              <td>
                                <button
                                  type="button"
                                  className="btn text-dark"
                                  data-toggle="modal"
                                  data-target="#assign-install"
                                  onClick={() => goTo(install)}
                                >
                                  Assign
                                </button>
                                <AssignInstallation installationID={to} />
                              </td>
                            </tr>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </table>
          </div>
        </div>
        {startPoint.length > 0 && <RoutedInstallation />}
        <div className="modal-footer">
          <button
            type="submit"
            className="btn btn-green"
            data-toggle="modal"
            data-target="#routedInstall"
            disabled={installation.length <= 0}
          >
            Route
          </button>
        </div>
      </form>
    </div>
  );
};

export default withRouter(DragAndDrop);
