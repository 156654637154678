/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import DeleteSvg from '../../../../assets/svg/delete-button.svg';
import { filename, referralHeaders, zoneHeaders } from '../../../../utils/field-support/installations/helpers';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../utils/loader/loadingHelper';
import CreateZone from '../../departments/field-support/zones/CreateZone';
import DeleteZoneModal from '../../departments/field-support/zones/DeleteZoneModal';
import CustomCsvDownload from '../../shared/CustomCsvDownload';
import { ZonesContext } from '../../shared/installations/zones/provider/ZonesContext';
import styles from '../installations/NewDesign.module.css';

const NewDesignInstallationZoneTable = () => {
  const { zones, loading, onShowModal } = useContext(ZonesContext);
  const [query, setQuery] = useState('');
  const [zone, setZone] = useState({
    name: '',
    id: '',
    no_of_installations: '',
    sub_zones: [{ name: '', id: '' }],
  });
  const getZone = (param) => {
    onShowModal();
    setZone(param);
  };

  const zonesTable =
    zones && zones.length ? (
      zones
        .filter((zon) => {
          if (query === '') {
            return zon;
          }
          if (zon.name.toLowerCase().includes(query.toLowerCase())) {
            return zon;
          }
        })

        .map((zon, i) => {
          return (
            <tr key={i + 1}>
              <td data-label="#"> {i + 1}</td>
              <td data-label="Zone">
                {' '}
                <Link
                  style={{ cursor: 'pointer', textDecoration: 'none', color: 'black' }}
                  to={{
                    pathname: `/zone-actions/${zon.id}`,
                    state: { detail: zon },
                  }}
                >
                  {zon.name || 'N/A'}
                </Link>
              </td>

              {/* <td data-label="Subzone(s)">{zon.sub_zones.map((sub: any) => sub.name).join(' , ' + ' ') || 'N/A'}</td> */}
              <td data-label="Daily Installation Slots">{zon.no_of_installations || 'N/A'}</td>
              <td data-label="Actions">
                <div style={{ paddingLeft: '10px' }}>
                  <img
                    onClick={() => getZone(zon)}
                    src={DeleteSvg}
                    alt="delete-button"
                    width="15px"
                    className="img-pointer"
                  />
                </div>
              </td>
            </tr>
          );
        })
    ) : (
      <NoItemRow colSpan="3" text="No Zones" />
    );

  return (
    <>
      <div className="search-bar row">
        <div className="col-xs-4 ">
          {' '}
          <div className="input-box">
            <input
              type="text"
              placeholder="Search"
              className="form-control"
              onChange={(event) => setQuery(event.target.value)}
            />
          </div>
        </div>

        <div className="col-xs-4 pl-3">
          <button className={styles.drop_green} type="button" data-toggle="modal" data-target="#create-zone">
            Create Zone
          </button>
          <CreateZone />
        </div>

        <div className="col-xs-4 pl-3 ">
          <CustomCsvDownload
            data={zones || zones.sub_zones}
            headers={zoneHeaders}
            filename={filename(`Installation Zones`)}
            classname={styles.drop}
            textcolor="white"
            text="Download"
          />
        </div>
      </div>
      <table className={`table table-sm ${styles.new_table} mt-3`}>
        <thead className={`thead-light ${styles.new_thead}`}>
          <tr>
            <th>#</th>
            <th>Zone</th>
            {/* <th>Subzone(s)</th> */}
            <th>Daily Install Slot</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{loading ? <TizetiGIFLogoTable colSpan="3" /> : zonesTable}</tbody>
      </table>
      <DeleteZoneModal zone={zone} type="zone" />
    </>
  );
};

export default NewDesignInstallationZoneTable;
