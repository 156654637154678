/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import view from '../../../../../assets/img/icons/view.png';
import edit from '../../../../../assets/img/icons/edit.png';
import staff from '../../../../../assets/img/icons/staff.fw.png';
import helpers from '../../../../../utils/installations/helpers';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../../utils/loader/loadingHelper';
import ViewClusteredInstallation from './ViewClusteredInstallation';
import EditInstallation from './EditInstallation';
import useTeams from '../../field-support/team/hooks/useTeams';
import AssignedEmployees from '../../../shared/installations/leads-and-managers/table/AssignedEmployees';
import useInstallationOverview from '../../../../../hooks/field-support/installation/leads/overview/useInstallationOverview';

const ClusteredInstallationsTable = ({ lists, data, isLoading }) => {
  const { getAssignedStaff, assignedStaff, assignedStaffRows } = useInstallationOverview();
  const { teams, getTeams } = useTeams();

  const [selectedItem, setSelectedItem] = useState({});

  const colSpan = 11;

  const getItem = (item) => setSelectedItem(item);

  useEffect(() => {
    getTeams();
  }, []);

  const tableData =
    lists && lists.length && data.items ? (
      lists.map((list, i) => {
        return (
          <tr className="" key={i}>
            <td>{i + 1}</td>
            <td className="text-nowrap">{list.customer_name}</td>
            <td className="text-wrap">{list.address}</td>
            <td>{helpers.installationStatusLabels(list.status)}</td>
            <td className="text-uppercase">{list.zone}</td>
            <td className="text-uppercase">{list.zone_name || 'No Zone'}</td>
            <td className="text-uppercase">{list.team_name || 'UNASSIGNED'}</td>
            <td className="text-uppercase">{list.deviceType || 'N/A'}</td>
            <td className="text-uppercase text-nowrap">{list.salesAgent || 'N/A'}</td>
            <td className="text-nowrap">
              <Moment format="DD-MMM-YYYY" date={list.date} />
            </td>
            <td>
              <div className="d-flex">
                <button
                  type="button"
                  className="mx-1 noBorder"
                  data-toggle="modal"
                  data-target="#viewCluster"
                  onClick={() => getItem(list)}
                >
                  <span className="view">
                    <img src={view} alt="view" data-toggle="tooltip" data-placement="left" title="View" />
                  </span>
                </button>
                <button
                  type="button"
                  className="mx-1 noBorder"
                  data-toggle="modal"
                  data-target="#edit-install"
                  onClick={() => getItem(list)}
                >
                  <span className="view">
                    <img src={edit} alt="edit" data-toggle="tooltip" data-placement="left" title="Edit" />
                  </span>
                </button>
                <button
                  type="button"
                  className="mx-1 noBorder"
                  data-toggle="modal"
                  data-target="#assigned-installation-details"
                  onClick={() => {
                    getAssignedStaff(list.installation_id);
                    getItem(list);
                  }}
                >
                  <span className="view">
                    <img src={staff} alt="staff" data-toggle="tooltip" data-placement="right" title="Staff" />
                  </span>
                </button>
              </div>
            </td>
            <EditInstallation item={selectedItem} teams={teams} />
            <ViewClusteredInstallation item={selectedItem} />
            <AssignedEmployees
              install={selectedItem}
              assignedStaffRows={assignedStaffRows}
              assignedStaff={assignedStaff}
            />
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan={colSpan} text="There Are No Clustered Installations" />
    );

  return (
    <div className="table-responsive content pt-4 col-12">
      <table className="table table-striped">
        <thead>
          <tr className="">
            <th>S/N</th>
            <th>Name</th>
            <th>Address</th>
            <th>Status</th>
            <th>Reference Point</th>
            <th>Zone</th>
            <th>Team</th>
            <th>Device Type</th>
            <th>Sales Agent</th>
            <th>Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{isLoading ? <TizetiGIFLogoTable colSpan={colSpan} /> : tableData}</tbody>
      </table>
    </div>
  );
};

export default ClusteredInstallationsTable;
