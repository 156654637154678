import React, { useContext } from 'react';
// import { useCategory } from '../../../../../hooks/administration/requests/category/useCategory';
import RequestContext from '../../../../../context/shared/requests/admin/RequestContext';

function CreateCategory() {
  // const { category, onChange, createCategory, isLoadingCategory } = useCategory();
  const value = useContext(RequestContext);
  const { category, onFormChange, createCategory, isLoadingCategory } = value;

  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      category,
    };
    createCategory(data);
  };

  const submitForm = () => {
    return category.length > 0;
  };
  return (
    <div
      id="createCategory"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Create Category
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>

          <div className="modal-body">
            <form className="form-horizontal" onSubmit={onSubmit}>
              <div className="form-group text-left">
                <div className="col-md-12 mb-3 d-flex p-0">
                  <div className="col-lg-12">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
                    <label className="copy-font p-0 m-0">Name of Category</label>
                    <input className="form-control" name="category" value={category} onChange={onFormChange} />
                  </div>
                </div>
              </div>
              <div className="col-12 mt-5 text-center">
                {isLoadingCategory ? (
                  <button className="btn btn-green" disabled={isLoadingCategory} type="submit">
                    Submitting...
                  </button>
                ) : (
                  <button className="btn btn-green" disabled={!submitForm()} type="submit">
                    Submit
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateCategory;
