/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import CustomerSupportSideBar from '../../../../layouts/customer-support/CustomerSupportSideBar';
import Logout from '../../../shared/auth/Logout';
import { API_URL } from '../../../../../utils/config';
import logo from '../../../../../assets/img/logo/Tizeti-Logo.png';
import mailblast from '../../../../../assets/img/icons/mailblast.svg';
import AddIssue from '../../../shared/issues/AddIssue';

export default class CustomerSupportReportIssue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      role: '',
    };
  }

  componentDidMount() {
    this.getUserDetails();
  }

  getUserDetails() {
    axios({
      method: 'get',
      url: `${API_URL}/employee/loggedOnEmployee`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    }).then((res) => {
      if (res.data) {
        this.setState({
          firstName: res.data.firstName,
        });
      }
    });
  }

  render() {
    const { firstName, role } = this.state;

    return (
      <div className="main-wrapper">
        <header className="topbar shade bg-white fixed-top">
          <nav className="navbar navbar-expand-lg navbar-light top-navbar fixed-top">
            <Link className="navbar-brand" to="#">
              <img src={logo} alt="homepage" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse mt-3" id="navbarText">
              <ul className="navbar-nav" style={{ marginRight: 'auto' }}>
                <li className="nav-item active">
                  <Link className="nav-link ml-5" to="#">
                    <h3>
                      Report Issue
                      <span className="sr-only">(current)</span>
                    </h3>
                  </Link>
                </li>
              </ul>
              <span className="navbar-text">
                <div className="d-flex">
                  {role > '2' ? (
                    <Link
                      to="/customer-support/mail-blast"
                      className="d-flex"
                      style={{
                        color: 'gray',
                        textDecoration: 'none',
                      }}
                    >
                      <img src={mailblast} alt="" className="logout mr-2 mt-0 mb-2" />
                      <span>
                        <h6 className="mt-1 mr-3">
                          <strong>MailBlast</strong>
                        </h6>
                      </span>
                    </Link>
                  ) : null}
                  <h6 className="mr-0 mt-1">
                    {firstName}
                    <Logout />
                  </h6>
                </div>
              </span>
            </div>
          </nav>
        </header>

        <div className="all-sides col-lg-12 col-md-12 col-sm-12">
          <CustomerSupportSideBar role={role} />

          <div className="page-wrapper col-lg-10 col-md-10 col-sm-12">
            <div className="row">
              <AddIssue />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
