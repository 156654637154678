/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import view from '../../../../../../assets/img/icons/view.png';
import { useExpiredCustomers } from '../../../../../../hooks/shared/asset-team/expired-customer/useExpiredCustomers';
import ExpiredCustomer from './ExpiredCustomer';

const ExpiredCustomerTable = ({ items }) => {
  const { getExpiredDetails, customers, isLoading } = useExpiredCustomers();

  const getDetails = (customer) => {
    const data = {
      action: 'tizeti_asset_expiration_users',
      calledstationid: customer.basestationame,
    };
    getExpiredDetails(data);
  };
  const tableData =
    items && items.length ? (
      items.map((item, i) => {
        const { basestationame, expired_count } = item;
        return (
          <tr className="text-nowrap" key={i}>
            <td>{i + 1}</td>
            <td>{basestationame.toUpperCase()}</td>
            <td>{expired_count}</td>
            <td>
              <button
                type="submit"
                data-toggle="tooltip"
                data-placement="left"
                title="View"
                onClick={() => getDetails(item)}
              >
                <span className="view1 ml-4">
                  <img src={view} alt="" data-toggle="modal" data-target="#viewExpiredDetails" />
                </span>
              </button>
              <ExpiredCustomer users={customers} isLoading={isLoading} />
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="4" className="text-center">
          No Expired Customers
        </td>
      </tr>
    );

  return tableData;
};

export default ExpiredCustomerTable;
