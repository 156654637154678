import React, { useState } from 'react';
import caution from '../../../../assets/img/icons/Caution.png';
import { usePurchaseOrder } from '../../../../hooks/shared/purchase-order/usePurchaseOrder';

const UpdatePO = ({ order: { item, id, quantity } }) => {
  const [approvalStatus, setApprovalStatus] = useState('approved');
  const [approvalComment, setApprovalComment] = useState('');
  const [budgetCode, setBudgetCode] = useState('');

  const { updatePurchaseOrder } = usePurchaseOrder();

  const confirmOrder = (e) => {
    e.preventDefault();
    const data = {
      budget_code: budgetCode,
      manager_approval: approvalStatus,
      manager_comment: approvalComment,
    };
    updatePurchaseOrder(id, data);
  };

  const validateForm = () => budgetCode.length > 0;

  return (
    <div
      className="modal fade"
      id="updatePO"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content mt-5 py-4">
          <div className="text-center">
            <img src={caution} alt="" width="60px" />
          </div>
          <div className="modal-body p-3">
            <div className="row">
              <div className="col">
                <form className="form-horizontal text-center">
                  <div className="col">
                    <h5>
                      Confirm order of <strong>{`${quantity} ${item}`}</strong>?
                    </h5>
                  </div>
                  <div className="justify-content-center py-3">
                    <div className="px-5">
                      <div className="px-5">
                        <div className="px-5">
                          <select
                            className="form-control border border-secondary borderRadius0"
                            name="approvalStatus"
                            value={approvalStatus}
                            onChange={(e) => setApprovalStatus(e.target.value)}
                          >
                            <option value="approved">Approve</option>
                            <option value="rejected">Reject</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="my-2 px-5">
                      <div className="px-5">
                        <div className="px-4 input-group">
                          <input
                            type="text"
                            placeholder="Budget Code"
                            className="form-control border border-secondary borderRadius0"
                            name="budgetCode"
                            value={budgetCode}
                            onChange={(e) => setBudgetCode(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="my-2 px-5">
                      <textarea
                        placeholder="Comment"
                        className="form-control border border-secondary borderRadius0"
                        name="approvalComment"
                        value={approvalComment}
                        onChange={(e) => setApprovalComment(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mt-3">
                    <button type="button" className="btn btn-secondary mr-2" data-dismiss="modal">
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-green" onClick={confirmOrder} disabled={!validateForm()}>
                      Confirm
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdatePO;
