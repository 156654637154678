/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-case-declarations */
import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import RetrievalsTable from './RetrievalsTable';
import { useRetrievals } from '../../../../../hooks/shared/retrievals/useRetrievals';

const RetrievalsBody = () => {
  const {
    isLoading,
    list,
    search,
    pages,
    getProcurementRetrievals,
    searchRetrievals,
    handlePageClick,
  } = useRetrievals();
  const [keyword, setKeyword] = useState('');
  const [option, setOption] = useState('');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [page] = useState(1);

  useEffect(() => {
    getProcurementRetrievals(page);
  }, []);

  const filterByDate = (e) => {
    e.preventDefault();
    const data = { from, to };
    searchRetrievals(data);
  };

  const filterUsingKeyword = (e) => {
    e.preventDefault();
    switch (option) {
      case 'itemName':
        const searchItemName = {
          item: keyword.trim(),
        };
        return searchRetrievals(searchItemName);

      case 'routerMACID':
        const searchRouterMACID = {
          router_mac_id: keyword.trim(),
        };
        return searchRetrievals(searchRouterMACID);

      case 'radioMACID':
        const searchRadioTag = {
          radio_mac_id: keyword.trim(),
        };
        return searchRetrievals(searchRadioTag);

      case 'customerName':
        const searchCustomerName = {
          customer_name: keyword.trim(),
        };
        return searchRetrievals(searchCustomerName);

      case 'customerEmail':
        const searchCustomerEmail = {
          customer_email: keyword.trim(),
        };
        return searchRetrievals(searchCustomerEmail);

      default:
        return null;
    }
  };

  return (
    <div className="container pt-5">
      <div className="row mt-5">
        <form onSubmit={filterUsingKeyword} className="form-inline mb-2 ml-3 col-12">
          <div className="form-group">
            <select name="option" onChange={(e) => setOption(e.target.value)} className="form-control">
              <option value="">--Choose Search Field--</option>
              <option value="itemName">Item Name</option>
              <option value="routerMACID">Router MAC ID</option>
              <option value="radioMACID">Radio MAC ID</option>
              <option value="customerName">Customer Name</option>
              <option value="customerEmail">Customer Email</option>
            </select>
          </div>
          <div className="form-group ml-2">
            <input
              className="form-control"
              type="text"
              placeholder="Input Search Term"
              style={{ width: '230px' }}
              name="keyword"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
          </div>
          <div className="form-group ml-2">
            <button type="submit" className="btn btn-green" disabled={!(option.length > 0 && keyword.length > 0)}>
              Search With Details
            </button>
          </div>
        </form>
        <form onSubmit={filterByDate} className="form-inline mb-2 ml-3 col-12">
          <div className="form-group d-flex ml-2">
            <small className="mr-1">From: </small>
            <input
              type="date"
              className="form-control"
              name="from"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
            />
          </div>
          <div className="form-group d-flex ml-2">
            <small className="mr-1">To: </small>
            <input type="date" className="form-control" name="to" value={to} onChange={(e) => setTo(e.target.value)} />
          </div>
          <div className="form-group ml-2">
            <button type="submit" className="btn btn-green" disabled={!(from.length > 0 && to.length > 0)}>
              Search With Date
            </button>
          </div>
        </form>
        <RetrievalsTable lists={list} search={search} load={isLoading} />

        {pages > 1 ? (
          <ReactPaginate
            previousLabel="previous"
            nextLabel="next"
            breakLabel="..."
            breakClassName="break-me"
            pageCount={pages}
            marginPagesDisplayed={3}
            pageRangeDisplayed={4}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            subContainerClassName="pages pagination"
            activeClassName="active"
          />
        ) : null}
      </div>
    </div>
  );
};

export default RetrievalsBody;
