/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import usePost from '../../../../hooks/shared/custom/data/geniatech/usePost';
import { GET_ROUTER_URL } from '../../../../services/api/shared/BasestationsUrl';
import CustomCsvDownload from '../CustomCsvDownload';
import { filename, assetTeamHeaders, assetTeamHeaders2 } from '../../../../utils/field-support/installations/helpers';
import DownloadExpired from './DownloadExpired';
import AssetTeamTable from '../asset-team/AssetTeamTable';
import SearchByDetails from '../../departments/asset-team/shared/SearchByDetails';

const ExpiredCustomersPerCountryBody = ({ profile }) => {
  const { data, loading, getData, getDataLegacy, fetchData } = usePost();

  const searchState = {
    days: '1',
    country: 'Nigeria',
    // filterBy: 'none',
    // filterValue: '',
    recordsPerPage: 20,
    currentPage: 1,
  };
  const [result, setResult] = useState([]);
  const [search, setSearch] = useState(searchState);
  const { days, country, recordsPerPage, currentPage } = search;

  const params = {
    action: 'tizeti_asset_expired_users_paginated_country',
    country,
    days_ago: days,
    // filterby: filterBy,
    // filtervalue: filterValue,
    no_of_records_per_page: recordsPerPage,
    pageno: currentPage,
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setResult([]);
    // if (name === 'filterBy') {
    //   const filterParams = {
    //     action: 'tizeti_asset_filter_values',
    //     country,
    //     filterby: value,
    //   };
    //   filterData(`${GET_ROUTER_URL}`, filterParams);
    // }
    setSearch({ ...search, [name]: value });
  };

  const onSearchByCountry = (e) => {
    e.preventDefault();
    getDataLegacy(`${GET_ROUTER_URL}`, params);
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    const pageParams = {
      action: 'tizeti_asset_expired_users_paginated_country',
      country,
      days_ago: days,
      no_of_records_per_page: recordsPerPage,
      pageno: selectedPage,
    };
    getDataLegacy(`${GET_ROUTER_URL}`, pageParams);
  };

  useEffect(() => {
    getDataLegacy(`${GET_ROUTER_URL}`, params);
  }, []);

  useEffect(() => {
    setResult(data.items || data);
  }, [data]);

  return (
    <div className="mt-5 px-3">
      <div className="container-fluid pt-5">
        <div className="row">
          <div className="p-0 col-12 d-flex justify-content-between">
            <div className="d-flex">
              <form onSubmit={onSearchByCountry} className="form-inline ml-3">
                <div className="form-group mr-1">
                  <select defaultValue={country} name="country" onChange={handleChange} className="form-control">
                    <option value="">--Choose Country--</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="Ghana">Ghana</option>
                  </select>
                </div>
                <div className="form-group mr-1">
                  <input
                    type="number"
                    min="1"
                    placeholder="Per Page"
                    className="form-control py-1"
                    name="recordsPerPage"
                    value={recordsPerPage}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <select defaultValue={days} name="days" onChange={handleChange} className="form-control">
                    <option>--Duration--</option>
                    <option value="1">Daily</option>
                    <option value="7">Weekly</option>
                    <option value="30">Monthly</option>
                    {/* <option value="">All</option> */}
                  </select>
                </div>
                {/* {days !== '' && (
                  <div className="form-group">
                    <select defaultValue={filterBy} name="filterBy" onChange={handleChange} className="form-control">
                      <option>--Filter By--</option>
                      <option value="agent">Agent</option>
                      <option value="basestation">Basestation</option>
                      <option value="zone">Zone</option>
                      <option value="none">All</option>
                    </select>
                  </div>
                )} */}
                {/* {filterBy !== 'none' && (
                  <div className="form-group">
                    <select
                      defaultValue={filterValue}
                      name="filterValue"
                      onChange={handleChange}
                      className="form-control"
                    >
                      <option>--Value--</option>
                      {filtered.items && filtered.items.length ? (
                        filtered.items.map((filter: React.Key | string[] | undefined | any) => (
                          <option key={filter} value={filter}>
                            {filter.toUpperCase()}
                          </option>
                        ))
                      ) : (
                        <option>Loading...</option>
                      )}
                    </select>
                  </div>
                )} */}
                <div className="form-group mx-2">
                  <button className="btn btn-green" type="submit">
                    Search
                  </button>
                </div>
              </form>
              {result && result.length ? (
                <CustomCsvDownload
                  data={result}
                  headers={data.items ? assetTeamHeaders : assetTeamHeaders2}
                  filename={filename(`Expired ${country}`)}
                  classname="btn btn-green shadow-md"
                  textcolor="white"
                  text="Download Data"
                />
              ) : null}
            </div>
            {/* {result && result.length ? (
              <div className="d-flex-end mr-3">
                Count:
                <span className="badge badge-secondary ml-1">{result.length}</span>
              </div>
            ) : null} */}
          </div>
          <div className="d-flex ml-3">
            <SearchByDetails getData={getData} fetchData={fetchData} getDataLegacy={getDataLegacy}/>
            <div style={{ paddingTop: '6px' }}>
              <DownloadExpired country={country} type="Expired" />
            </div>
          </div>
          {/* {days === '1' || days === '7' || days === '30' ? ( */}
          <AssetTeamTable profile={profile} items={data.items || data} load={loading} />
          {/* ) : (
            <div className="pt-5">
              {loading ? (
                <TizetiGIFLogoTable colSpan="8" />
              ) : (
                <p className="pt-5" style={{ fontSize: '30px' }}>
                  This Data Can Only Be Downloaded
                </p>
              )} 
            </div>
          )} */}
        </div>

        {data.total_pages > 1 ? (
          <ReactPaginate
            previousLabel="previous"
            nextLabel="next"
            breakLabel="..."
            breakClassName="break-me"
            pageCount={data.total_pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            // subContainerClassName="pages pagination"
            activeClassName="active"
          />
        ) : null}
      </div>
    </div>
  );
};

export default ExpiredCustomersPerCountryBody;
