/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import useCheckDepartment from '../../../../../hooks/shared/custom/check-department/field-support/useCheckDepartment';
import FieldSupportSideBar from '../../../../layouts/field-support/side-bar/FieldSupportSideBar';
import Logout from '../../../shared/auth/Logout';
import TeamsBody from './TeamsBody';
import TeamsProvider from './TeamsProvider';

const FieldSupportTeam = ({ history }) => {
  const { profile } = useUser();
  const { firstName } = profile;
  useCheckDepartment(history);
  return (
    <div className="s-layout">
      <TeamsProvider>
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <Link className="s-sidebar__trigger" to="#">
                <i className="fa fa-bars mr-3" />
              </Link>
              <FieldSupportSideBar />
              <h3 className="col-lg-6 overview-part d-none d-md-flex">Teams</h3>
              <h3 className="col-lg-6 overview-part d-sm-flex d-md-none">Zones</h3>
              <div className="d-flex profile-mail col-6">
                <h6>
                  <i className="mr-auto">{firstName} </i>
                  <Logout />
                </h6>
              </div>
            </div>
          </div>
          <TeamsBody />
        </main>
      </TeamsProvider>
      <ToastContainer />
    </div>
  );
};

export default FieldSupportTeam;
