import {
  FETCHING_STARTS,
  FETCHING_STOPS,
  LOADING_STARTS,
  LOADING_STOPS,
} from '../../../../../constants/types/shared/LoadingTypes';
import { GET_LTE_KYC_CUSTOMERS, UPLOAD_LTE_KYC_CUSTOMER } from '../../../../../constants/types/shared/noc/LteTypes';

const kycReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCHING_STARTS:
      return {
        ...state,
        fetching: true,
      };

    case FETCHING_STOPS:
      return {
        ...state,
        fetching: false,
      };

    case GET_LTE_KYC_CUSTOMERS:
      return {
        ...state,
        fetching: false,
        customers: payload.users,
        pages: payload.pages,
      };

    case LOADING_STARTS:
      return {
        ...state,
        loading: true,
      };

    case LOADING_STOPS:
      return {
        ...state,
        loading: false,
      };

    case UPLOAD_LTE_KYC_CUSTOMER:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default kycReducer;
