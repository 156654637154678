import React from 'react';
import AppContext from '../../../../../context/shared/AppContext';
import useMailBlast from './hooks/useMailBlast';

const MailBlastProvider = ({ children }) => {
  const {
    onChange,
    onSubmit,
    handleChange,
    customers,
    fetching,
    sendEmail,
    sendingEmail,
    editorState,
    subject,
    onSubjectChange,
    onEditorStateChange,
    submitForm,
    onTextChange,
    body,
  } = useMailBlast();
  return (
    <AppContext.Provider
      value={{
        onChange,
        onSubmit,
        handleChange,
        customers,
        fetching,
        sendEmail,
        sendingEmail,
        editorState,
        subject,
        onSubjectChange,
        onEditorStateChange,
        submitForm,
        onTextChange,
        body,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export default MailBlastProvider;
