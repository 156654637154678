import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import toastr from 'toastr';
import PropTypes from 'prop-types';
import Logout from '../../../shared/auth/Logout';
import WifiCallSidebar from '../../../../layouts/wificall-sidebar/WifiCallSidebar';
import VerificationDetails from './VerificationDetails';
import { admin, header, NEW_PRODUCTS } from '../../../../../utils/config';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import { getUser } from '../../../../../actions/shared/user/UserActions';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import {
  getCustomers,
  getPaginatedUsers,
  searchWithEmail,
  searchWithPhone,
} from '../../../../../actions/wificall/customers/CustomersActions';
import Helper from '../../../../../utils/helpers';
import { SUPER_ADMIN } from '../../../../../utils/departments/helpers';

class WifiCallVerify extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      one: {},
      search: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  static propTypes = {
    getUser: PropTypes.func.isRequired,
    firstName: PropTypes.string.isRequired,
    searchWithEmail: PropTypes.func.isRequired,
    searchWithPhone: PropTypes.func.isRequired,
    filteredCustomers: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.getUser();
    this.checkDepartment();
    this.props.getCustomers();
  }

  handleChange = (value) => {
    this.setState({
      search: value,
    });
  };

  componentWillReceiveProps(nextProps) {
    this.checkDepartment();
    if (nextProps.filteredCustomers.length !== 0) {
      this.setState({
        users: nextProps.filteredCustomers,
        pageCount: 0,
      });
    }
  }

  checkDepartment = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, header);

      if (response.data.department_id !== `${admin}` && response.data.department_id !== `${NEW_PRODUCTS}` && response.data.department_id !== `${SUPER_ADMIN}`) {
        this.props.history.push('/');

        sessionStorage.setItem('token', '');
        sessionStorage.clear();

        toastr.info('You are not allowed to view this page');
      }
    } catch (e) {
      console.log(e.response);
    }
  };

  getUser = (user) =>
    this.setState({
      one: user,
    });

  handlePageClick(pageNumber) {
    const selected = pageNumber.selected + 1;

    this.props.getPaginatedUsers(selected);
  }

  searchCustomers = (e) => {
    e.preventDefault();

    const { search } = this.state;

    if (Helper.emailIsValid(search)) {
      const data = {
        email: search,
      };

      this.props.searchWithEmail(data);
    } else {
      const data = {
        phone: search,
      };

      this.props.searchWithPhone(data);
    }
  };

  render() {
    const { one, search } = this.state;
    const { user } = this.props;
    const { firstName } = user;
    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <Link className="s-sidebar__trigger" to="#">
                <i className="fa fa-bars mr-3" />
              </Link>
              <WifiCallSidebar />

              <h3 className="col-lg-6 overview-part">Verify Users Upload</h3>

              <div className="d-flex">
                <h6 className="mr-0 mt-1">
                  {firstName}
                  <Logout />
                </h6>
              </div>
            </div>
          </div>

          <div className="col-12 after-summary">
            <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-3">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-3">
                  <div className="col-12 mb-3">
                    <form className="form-inline inner-addon left-addon d-flex" onSubmit={this.searchCustomers}>
                      <label className="copy-font p-0 mr-2 mt-2">Search By: </label>
                      <input
                        className="form-control empty col-5"
                        type="text"
                        placeholder="Phone number / Email"
                        name="search"
                        value={search}
                        onChange={(e) => this.handleChange(e.target.value)}
                      />

                      <input type="submit" className="btn btn-green col-1" name="search" />
                    </form>
                  </div>

                  <div className="card x-panel">
                    <div className="table-responsive content">
                      <table className="table">
                        <thead>
                          <tr className="copy-font">
                            <th scope="col">#</th>
                            <th scope="col">First Name</th>
                            <th scope="col">Last Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone Number</th>
                            <th scope="col">Address</th>
                            <th>Date Uploaded</th>
                            <th>View & Verify</th>
                          </tr>
                        </thead>

                        <tbody>
                          {!this.props.users.users ? (
                            <img alt="loading gif" src={loader} />
                          ) : (
                            this.props.users.users.map((user, i) => (
                              <tr key={user.id}>
                                <td>{i + 1}</td>
                                <td>{user.firstName}</td>
                                <td>{user.lastName}</td>
                                <td>{user.email}</td>
                                <td>{user.phoneNumber}</td>
                                {user.address ? <td>{user.address}</td> : <td>N/A</td>}
                                <td>{moment(user.created_at).format('DD/MM/YYYY')}</td>
                                <td>
                                  <div className="d-flex ml-4">
                                    <button
                                      type="submit"
                                      data-toggle="modal"
                                      data-placement="right"
                                      data-target="#verify-detail"
                                      onClick={() => this.getUser(user)}
                                      style={{
                                        border: '0',
                                        backgroundColor: 'white',
                                      }}
                                    >
                                      <span className="view1">
                                        <img
                                          src="https://res.cloudinary.com/tizeti/image/upload/v1542971020/view.png"
                                          data-toggle="tooltip"
                                          data-placement="right"
                                          title="View"
                                          alt="viewicon"
                                        />
                                      </span>
                                    </button>
                                    <VerificationDetails oneuser={one} />
                                  </div>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.props.users.pages > 0 ? (
              <ReactPaginate
                className="pagination no-margin pull-right"
                previousLabel="previous"
                nextLabel="next"
                breakLabel="..."
                breakClassName="break-me"
                pageCount={this.props.users.pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName="pagination no-margin pull-right"
                subContainerClassName="pages pagination"
                activeClassName="active"
              />
            ) : null}
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  filteredCustomers: state.customersWithEmail.customersWithEmail,
  users: state.wificallCustomers.wificallUsers || [],
});

export default connect(mapStateToProps, { getUser, searchWithEmail, searchWithPhone, getCustomers, getPaginatedUsers })(
  WifiCallVerify,
);
