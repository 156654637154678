/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import FieldSupportSideBar from '../../../../../../layouts/field-support/side-bar/FieldSupportSideBar';
import Logout from '../../../../../shared/auth/Logout';
import { useUser } from '../../../../../../../hooks/shared/user/useUser';
import useCheckDepartment from '../../../../../../../hooks/shared/custom/check-department/field-support/useCheckDepartment';
import AssignedInstallationsTable from './AssignedInstallationsTable';

const UserAssignedInstallation = ({ history }) => {
  const { profile } = useUser();
  const { firstName } = profile;
  useCheckDepartment(history);
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <div className="d-flex col-lg-12 p-2 top-header">
          <div className="mt-3 d-flex col-lg-12 p-0">
            <Link className="s-sidebar__trigger" to="#">
              <i className="fa fa-bars mr-3" />
            </Link>
            <FieldSupportSideBar />
            <h3 className="col-lg-6 overview-part">Assigned Installation</h3>
            <div className="d-flex profile-mail col-6">
              <h6>
                <i className="mr-auto">{firstName} </i>
                <Logout />
              </h6>
            </div>
          </div>
        </div>
        <div className="col-12 after-summary">
          <AssignedInstallationsTable />
        </div>
      </main>
      <ToastContainer />
    </div>
  );
};

export default UserAssignedInstallation;
