/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import helpers from '../../../../../utils/helpers';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../../utils/loader/loadingHelper';
import relocation_helpers from '../../../../../utils/relocation/relocation_helpers';

const RescheduledInstallationsTable = ({ lists, isLoading }) => {
  const colSpan = '20';
  const tableData =
    lists && lists.length ? (
      lists.map((list, i) => {
        const {
          id,
          user: { firstName, lastName, email, phoneNumber },
          date,
          address,
          status,
          payment_reference,
          nearest_basestation,
          installation_comment,
          state,
          latitude,
          longitude,
          created_at,
        } = list;

        return (
          <tr className="" key={i}>
            <td>{i + 1}</td>
            <td className="text-nowrap">{id}</td>
            <td className="text-nowrap">{`${firstName} ${lastName}`}</td>
            <td>{email}</td>
            <td>{phoneNumber}</td>
            <td className="text-nowrap">{date}</td>
            <td className="text-nowrap">{address}</td>
            <td>{relocation_helpers.relocationStatusLabels(status)}</td>
            <td className="text-wrap">{payment_reference || 'N/A'}</td>
            <td className="text-uppercase">{nearest_basestation}</td>
            <td className="text-uppercase">{installation_comment || 'N/A'}</td>
            <td className="text-wrap">{state || 'N/A'}</td>
            <td className="text-wrap">{latitude || 'N/A'}</td>
            <td className="text-wrap">{longitude || 'N/A'}</td>
            <td>{helpers.formatDateAndTime(created_at)}</td>
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan={colSpan} text="There are no requests for relocation" />
    );

  return (
    <div className="table-responsive content col-12">
      <table className="table table-striped">
        <thead>
          <tr className="">
            <th> S/N</th>
            <th> ID</th>
            <th>Names</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>Date</th>
            <th>Address</th>
            <th>Status</th>
            <th>Payment Reference</th>
            <th>Nearest Basestation</th>
            <th>Comment</th>
            <th>State</th>
            <th>Latitude</th>
            <th>Longitude</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>{isLoading ? <TizetiGIFLogoTable colSpan={colSpan} /> : tableData}</tbody>
      </table>
    </div>
  );
};

export default RescheduledInstallationsTable;
