/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import moment from 'moment';
import { Document, Page } from 'react-pdf';
import Delete from '../../../../../assets/img/icons/delete.png';
import view from '../../../../../assets/img/icons/view.png';

class ApplicantTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      numPages: null,
      pageNumber: 1,
    };
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({
      numPages,
    });
  };

  displayCv = (application) => {
    const { pageNumber, numPages } = this.state;

    if (application.cv) {
      return (
        <Document file={application.cv} onLoadSuccess={this.onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} />
        </Document>
      );
    }
  };

  render() {
    const { applications, load } = this.props;

    if (load) {
      return (
        <tbody>
          <tr>
            <td>
              <small>loading...</small>
            </td>
          </tr>
        </tbody>
      );
    }

    const applicationTable = applications.map((application, i) => (
      <tr>
        <td>{i + 1}</td>
        <td>{application.firstname}</td>
        <td>{application.lastname}</td>
        {/* <td>Accounts</td> */}
        <td>{application.job_posting.job_title}</td>
        <td>{application.job_posting.responsibilities}</td>
        <td>{moment(application.created_at).format('DD-MM-YYYY')}</td>
        <td>
          <a href={`${application.cv}`}>
            <img
              src="https://res.cloudinary.com/teewhy/image/upload/v1555327943/View_CV.png"
              alt=""
              style={{ width: '12%', cursor: 'pointer' }}
              className="ml-4"
            />
          </a>
        </td>
        <td>
          <div className="d-flex">
            <button
              type="submit"
              data-toggle="modal"
              data-placement="right"
              data-target="#"
              style={{
                border: '0',
                backgroundColor: 'white',
              }}
            >
              <span className="view">
                <img src={view} data-toggle="tooltip" data-placement="left" title="View" alt="view" />
              </span>
            </button>
            <button
              type="submit"
              data-toggle="modal"
              // data-target="#edit"
              style={{
                border: '0',
                backgroundColor: 'white',
              }}
            >
              <span className="view">
                <img
                  src="https://res.cloudinary.com/teewhy/image/upload/v1543579893/edit.png"
                  data-toggle="tooltip"
                  data-placement="top"
                  alt="edit"
                  title="Edit"
                />
              </span>
            </button>

            <button
              type="submit"
              data-toggle="modal"
              // data-target="#cancel"
              style={{
                border: '0',
                backgroundColor: 'white',
              }}
            >
              <span className="view">
                <img src={Delete} data-toggle="tooltip" data-placement="right" title="Delete" alt="delete" />
              </span>
            </button>
          </div>
        </td>
      </tr>
    ));

    return <tbody>{applicationTable}</tbody>;
  }
}
export default ApplicantTable;
