import React from 'react';

const PerformanceTableHead = () => {
  return (
    <thead>
      <tr>
        <th>#</th>
        <th>Mac</th>
        <th className="text-nowrap">Mode</th>
        <th>Managed Account</th>
        <th>Name</th>
        <th>Network</th>
        <th>Site</th>
        <th>Online Duration</th>
        <th>Uptime</th>
        <th>Date</th>
        <th>Type</th>
      </tr>
    </thead>
  );
};

export default PerformanceTableHead;
