/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
import { useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import { stringify } from 'qs';
import geniatechReducer from '../../../reducers/shared/geniatech/geniatechReducer';
// import { GeniatechProps } from '../../GeniatechProps';
import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';
import { GET_DATA } from '../../../constants/types/shared/CustomTypes';
import * as types from '../../../constants/types/shared/cnmaestro/CnMaestroTypes';

const useGeniatech = (params, url) => {
  const [{ data, loading, pages, searchStatus, fetched }, dispatch] = useReducer(geniatechReducer, {
    data: [],
    fetched: [],
    loading: false,
    pages: 0,
    searchStatus: false,
  });
  const [search, setSearch] = useState('');
  const onChange = (e) => {
    setSearch(e.target.value);
  };
  const searchData = async (searchParams) => {
    dispatch({ type: types.SET_SEARCH_STATUS, payload: true });
    dispatch({ type: LOADING_STARTS });
    try {
      const response = await axios.post(url, stringify(searchParams));
      if (response.data) {
        dispatch({ type: types.FETCH_DATA, payload: response.data.data });
      }
    } catch (e) {
      dispatch({ type: LOADING_STOPS });
    }
  };
  const reset = () => {
    dispatch({ type: 'RESET_SEARCH_STATUS' });
  };
  useEffect(() => {
    async function getData() {
      dispatch({ type: LOADING_STARTS });
      try {
        return await axios.post(url, stringify(params));
      } catch (e) {
        return e;
      }
    }
    getData()
      .then((response) => {
        if (response.data) {
          dispatch({ type: GET_DATA, payload: response.data.data });
        }
      })
      .catch(() => {
        dispatch({ type: LOADING_STOPS });
      });
  }, []);
  return { data, loading, pages, search, searchData, onChange, searchStatus, reset, fetched };
};

export default useGeniatech;
