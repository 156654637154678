/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import useKpiMonitor from './hooks/useKpiMonitor';

const UpdateKpiDetails = ({ request }) => {
  const { updateKpiStatus } = useKpiMonitor();
  const { percentage_completion } = request;

  const [one, setOne] = useState({
    percentage: '',
  });

  const { percentage } = one;

  useEffect(() => {
    const result = {
      percentage: percentage_completion,
    };
    setOne({ ...result });
  }, [request]);

  const onFormSubmit = (e) => {
    e.preventDefault();
    const data = {
      percentage_completion: percentage.trim(),
    };
    updateKpiStatus(request.id, data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOne({ ...one, [name]: value });
  };

  return (
    <div id="kpi-details" className="modal fade mt-5" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header shadow">
            <h4 className="modal-title update_asset">Kpi Completion</h4>
            <button type="button" className="btn btn-outline-info btn-circle btn-close" data-dismiss="modal">
              x
            </button>
          </div>
          <div className="modal-body detail mb-4 p-0">
            <form className="col-md-12 p-0" onSubmit={onFormSubmit}>
              <div className="d-flex">
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="businessName">
                    Percentage Completion
                  </label>
                  <select id="percentage" name="percentage" value={percentage} onChange={handleChange}>
                    <option value="">Select an option</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="50">50</option>
                    <option value="60">60</option>
                    <option value="70">70</option>
                    <option value="80">80</option>
                    <option value="90">90</option>
                    <option value="100">100</option>
                  </select>
                </div>
              </div>
              <div className="text-center mt-3">
                <button type="submit" className="btn btn-green px-3">
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateKpiDetails;
