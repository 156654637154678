import axios from 'axios';
import { toast } from 'react-toastify';
import * as url from '../../../services/api/shared/BasestationsUrl';
import {
  GET_BASESTATIONS,
  GET_COUNT_OF_USERS_CONNECTED,
} from '../../../constants/types/shared/basestations/BasestationsTypes';
import * as types from '../../../constants/types/noc/NocTypes';
import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';
import {
  GET_LATEST_BASESTATIONS_URL,
  GET_BASESTATIONS_BY_YEAR_URL,
} from '../../../services/api/noc/basestations/BasestationsUrl';

export const getBasestations = () => async (dispatch) => {
  const response = await axios.get(`${url.GET_BASESTATIONS_URL}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
  });

  if (response.data) {
    dispatch({
      type: GET_BASESTATIONS,
      payload: response.data,
    });
  } else {
    toast.info('Something went wrong!');
  }
};

export const getLatestBasestations = () => async (dispatch) => {
  try {
    const response = await axios.get(`${GET_LATEST_BASESTATIONS_URL}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      dispatch({
        type: types.GET_LATEST_BASESTATIONS,
        payload: response.data.count,
      });
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};
export const getBaseStationCountByYear = (year) => async (dispatch) => {
  dispatch({
    type: LOADING_STARTS,
    payload: true,
  });
  try {
    const data = {
      date_of_commissioning: year,
    };
    const response = await axios({
      method: 'POST',
      url: GET_BASESTATIONS_BY_YEAR_URL,
      data: data,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    console.log(response.data, data);
    if (response.data.length > 0) {
      dispatch({
        type: types.GET_BASESTATION_BY_YEAR,
        payload: response.data.length,
      });
    }
    dispatch({
      type: LOADING_STOPS,
      payload: false,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: LOADING_STOPS,
      payload: false,
    });
  }
};
export const createBasestation = (data) => async (dispatch) => {
  dispatch({
    type: LOADING_STARTS,
    payload: true,
  });

  try {
    const response = await axios.post(`${url.CREATE_BASESTATION_URL}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data.id) {
      toast.success(`${data.name} added successfully!`);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  } catch (e) {
    toast.info('Something went wrong. Please try again later!');

    dispatch({
      type: LOADING_STOPS,
      payload: false,
    });
  }

  // axios({
  //
  //   method: 'post',
  //   data: data,
  //   headers: {
  //     Authorization: `Bearer ${sessionStorage.getItem('token')}`
  //   },
  //   url: `${API_URL}/baseStation`
  //
  // }).then(res => {
  //
  //   if(res.data.status === "failed") {
  //     toastr.error(res.data.message);
  //   }
  //
  //   if(res.data.id){
  //
  //     this.state = {
  //
  //       address: '',
  //       date: '',
  //       latitude: '',
  //       longitude: '',
  //       name: '',
  //       installer: '',
  //       amount: ''
  //     };
  //
  //     toastr.success('Base station has been added');
  //     //window.location.reload();
  //
  //   }else{
  //     this.setState({
  //       loading: false
  //     })
  //
  //   }
  //
  //
  // })
};

export const deleteBasestation = (data) => async (dispatch) => {
  try {
    const response = await axios.delete(`${url.DELETE_BASESTATION_URL}/${data}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data.message === 'success') {
      toast.success('Basestation deleted succesfully!');
      dispatch({
        type: types.DELETE_BASESTATION,
        payload: data,
      });
    } else {
      toast.error('Basestation was not deleted');
    }
    // eslint-disable-next-line no-empty
  } catch (e) {
    toast.error('Something went wrong');
  }

  //
  // if(response.data) {
  //
  //   console.log(response.data);
  //
  // }
};

export const getConnectedUsers = (data, id, name) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${url.GET_CONNECTED_USERS_URL}${id}?from=${data.from}&to=${data.to}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      const { count } = response.data;

      dispatch({
        type: GET_COUNT_OF_USERS_CONNECTED,
        payload: { count, name },
      });
    } catch (error) {
      if (error) {
        toast.info('An error occurred!');
      }
    }
  };
};
