/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { stringify } from 'qs';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import { toast } from 'react-toastify';
import CustomInput from '../../forms/CustomInput';
import CustomButton from '../../forms/CustomButton';
import useMailBlast from './hooks/useMailBlast';
import Helpers from './utils';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { cannedOptions } from '../../../../../utils/lib';
import { customer_support } from '../../../../../utils/config';
import { SALES } from '../../../../../utils/departments/helpers';

const MailBlastForm = ({ type, subject, onSubjectChange, editorState, onEditorStateChange, data }) => {
  const { canned, onCannedChange, country, onCountryChange, sendEmail, sendingEmail } = useMailBlast();
  const { profile } = useUser();
  const submitForm = (e) => {
    e.preventDefault();
    if (!profile.email) {
      toast.info('Please add a profile');
    } else {
      switch (type) {
        case 'single':
          sendEmail({
              action: 'retension_email_helper_encoded',
              staff_email: profile.officialEmail,
              message:
                canned.length > 0
                  ? Helpers.encodeMessage(Helpers.emailToBeSent(canned))
                  : Helpers.encodeMessage(draftToHtml(convertToRaw(editorState.getCurrentContent()))),
              subject: canned.length > 0 ? Helpers.emailSubjectToBeSent(canned) : subject,
              customer_emails: [data],
              encoded: true,
              country,
            },
          );
          break;
        case 'bulk':
          sendEmail({
            action: 'retension_bulk_email',
            country,
            message:
              canned.length > 0
                ? Helpers.encodeMessage(Helpers.emailToBeSent(canned))
                : Helpers.encodeMessage(draftToHtml(convertToRaw(editorState.getCurrentContent()))),
            subject: canned.length > 0 ? Helpers.emailSubjectToBeSent(canned) : subject,
            staff_email: profile.officialEmail,
            customer_emails: data,
            // customer_emails: [
            //   'oluwabukolatina@gmail.com',
            //   'oluwabukola.ajishebiyawo@tizeti.com',
            //   // 'ehimen.iziengbe@tizeti.com',
            // ],
          });

          break;
        case 'sales':
          sendEmail({
            action: 'sales_bulk_email',
            country,
            message:
              canned.length > 0
                ? Helpers.encodeMessage(Helpers.emailToBeSent(canned))
                : Helpers.encodeMessage(draftToHtml(convertToRaw(editorState.getCurrentContent()))),
            subject: canned.length > 0 ? Helpers.emailSubjectToBeSent(canned) : subject,
            staff_email: profile.officialEmail,
            customer_emails: data,
          });

          break;

        default:
          break;
      }
    }
  };
  return (
    <form className="form-horizontal" onSubmit={submitForm}>
      <div className="form-group">
        <div className="col-12 mb-3">
          <div className="col-12">
            <select
              id="status"
              name="canned"
              onChange={onCannedChange}
              className="form-control border border-info py-2"
            >
              <option value="">--Select Canned Type--</option>
              <option value="UNREACHABLE CUSTOMERS">UNREACHABLE CUSTOMERS</option>
              {profile.department_id === `${SALES}` && (
                <option value="Unlimited Internet for your Home">Unlimited Internet for your Home</option>
              )}
              {profile.department_id === `${customer_support}` &&
                cannedOptions.map((op) => <option value={op.name}>{op.name}</option>)}
            </select>
          </div>
        </div>
        <div className="col-12 mb-3">
          <div className="col-12">
            {canned.length > 0 ? (
              <CustomInput
                readOnly
                classname="form-control shadow-sm borderRadius0"
                type="text"
                name="subject"
                onChange={onSubjectChange}
                value={Helpers.emailSubjectToBeSent(canned)}
                placeholder="Subject of Email"
              />
            ) : (
              <CustomInput
                readOnly={false}
                classname="form-control shadow-sm borderRadius0"
                type="text"
                name="subject"
                onChange={onSubjectChange}
                value={subject}
                placeholder=""
              />
            )}
          </div>
        </div>

        <div className="col-12 mb-3">
          <div className="col-12">
            <select className="form-control shadow-sm borderRadius0" name="country" onChange={onCountryChange}>
              <option value="">Select Country</option>
              <option value="Nigeria">Nigeria</option>
              <option value="Ghana">Ghana</option>
            </select>
          </div>
        </div>
        <div className="col-12 mb-3">
          <div className="col-12">
            {canned.length > 0 ? (
              <textarea
                style={{ height: '200px' }}
                className="form-control shadow-sm borderRadius0"
                value={Helpers.displayMessage(canned)}
              />
            ) : (
              <Editor
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor editor-textarea"
                onEditorStateChange={onEditorStateChange}
              />
            )}
          </div>
        </div>

        <div className="col-12 mb-3 text-center">
          {sendingEmail ? (
            <CustomButton classname="btn btn-green" name="Sending..." disabled={sendingEmail} />
          ) : (
            <CustomButton classname="btn btn-green" name="Send Email" disabled={false} />
          )}
        </div>
      </div>
    </form>
  );
};

export default MailBlastForm;
