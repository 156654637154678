import React from 'react';
import SubBasestationTable from './SubBasestationTable';
import AddSubBasestation from './AddSubBasestation';

const SubBasestationBody = () => {
  return (
    <div className="row mt-5">
      <div className="form-group ml-2">
        <button className="btn btn-green" type="button" data-toggle="modal" data-target="#add-access-point">
          Add Sub basestation
        </button>
      </div>
      <AddSubBasestation />
      <SubBasestationTable />
    </div>
  );
};

export default SubBasestationBody;
