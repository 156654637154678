/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

const UpdateReleaseItem = () => {
  return (
    <div id="release_item" className="modal fade mt-5" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header shadow">
            <h4 className="modal-title update_asset">Release Item</h4>
            <button type="button" className="btn btn-outline-info btn-circle btn-close" data-dismiss="modal">
              x
            </button>
          </div>
          <div className="modal-body detail mb-4 p-0">
            <form className="col-md-12 p-0">
              <div className="d-flex">
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="radio_id">
                    Item Name
                  </label>
                  <input
                    type="text"
                    className="form-control border border-info pl-3 py-2"
                    id="radio_id"
                    placeholder="Item Name"
                    disabled
                  />
                </div>
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="requested_by">
                    Requested By
                  </label>
                  <select className="form-control borderRadius0 border border-info p-2" id="requested_by" disabled>
                    <option>Individual or Department</option>
                  </select>
                </div>
              </div>

              <div className="d-flex">
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="class">
                    Class
                  </label>
                  <select className="form-control borderRadius0 border border-info p-2" id="class" disabled>
                    <option>Inventory</option>
                  </select>
                </div>
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="release_to">
                    Released To
                  </label>
                  <select className="form-control borderRadius0 border border-info py-2" id="release_to">
                    <option>Staff Name</option>
                  </select>
                </div>
              </div>

              <div className="d-flex">
                <div className="col-6 m-0 p-0 d-flex">
                  <div className="form-group pr-1 col-6">
                    <label className="m-0 p-0 copy-font" htmlFor="retrieval_date">
                      Quantity
                    </label>
                    <input
                      type="text"
                      className="form-control border border-info p-2"
                      id="retrieval_date"
                      placeholder="450"
                      disabled
                    />
                  </div>
                  <div className="form-group pl-1 col-6">
                    <label className="m-0 p-0 copy-font" htmlFor="retrieval_date">
                      Destination
                    </label>
                    <select className="form-control borderRadius0 border border-info pl-1 pr-0" id="release_to">
                      <option>Customers</option>
                    </select>
                  </div>
                </div>
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="tag_no">
                    Tag No.
                  </label>
                  <input
                    type="text"
                    className="form-control border border-info p-2"
                    placeholder="Tag No."
                    id="tag_no"
                    disabled
                  />
                </div>
              </div>

              <div className="d-flex">
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="approvedBy">
                    Approved By
                  </label>
                  <select className="form-control borderRadius0 border border-info py-2" id="approvedBy">
                    <option>Staff Name</option>
                  </select>
                </div>
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="date_released">
                    Date Released
                  </label>
                  <input type="date" className="form-control border border-info py-2 px-2" id="date_released" />
                </div>
              </div>

              <div className="text-center mt-3">
                <button type="submit" data-dismiss="modal" className="btn btn-green px-3">
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateReleaseItem;
