import React from 'react';
import AssignedInstallationsTableBody from './AssignedInstallationsTableBody';
import useInstallations from '../../../../../../../hooks/field-support/installation/useInstallations';
import CustomInput from '../../../../../shared/forms/CustomInput';
import loader from '../../../../../../../assets/img/icons/TizetiLoaderColored.gif';

const AssignedInstallationsTable = () => {
  const { data, loading, searchInstallations, range, onSearchChange } = useInstallations();
  const { from, to } = range;
  return (
    <>
      <div className="mt-3 d-flex">
        <div className="col-7 d-none d-md-flex">
          <p className="fil d-flex mt-3 mr-5 copy-font">
            <small className="mr-3 ml-3">From:</small>
            <CustomInput
              placeholder=""
              readOnly={false}
              type="date"
              classname="form-control"
              name="from"
              value={from}
              onChange={onSearchChange}
            />
          </p>

          <p className="fil d-flex mt-3 mr-5 copy-font">
            <small className="mr-3 ml-3">To:</small>
            <CustomInput
              placeholder=""
              readOnly={false}
              type="date"
              classname="form-control"
              name="to"
              value={to}
              onChange={onSearchChange}
            />
          </p>
          <div>
            <button
              disabled={loading}
              type="button"
              className="btn btn-green mt-3"
              onClick={() => searchInstallations({ from, to })}
            >
              Search
            </button>
          </div>
        </div>
      </div>
      <div className="card x-panel">
        <div className="table-responsive content">
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Date</th>
                <th>Address</th>
                <th>Country</th>
                <th>State</th>
                <th>Longitude</th>
                <th>Latitude</th>
                <th>Status</th>
                <th>Payment Ref</th>
                <th>Nearest Basestation</th>
                <th>Distance calculation</th>
              </tr>
            </thead>
            {loading ? <img alt="loading gif" src={loader} /> : <AssignedInstallationsTableBody installations={data} />}
          </table>
        </div>
      </div>
    </>
  );
};

export default AssignedInstallationsTable;
