/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from 'react';
import helpers from './helpers';
import useExpressWifiSMS from '../../../../../../hooks/express-wifi/useExpressWifiSMS';
import { TERMII_API_KEY } from '../../../../../../services/api/express-wifi/ExpressWifiURL';

const XWFSingleSMS = ({ oneUser }) => {
  const { sendSingleSMS } = useExpressWifiSMS();

  const [text, setText] = useState({
    ChurnedCustomers: helpers.ChurnedCustomers,
    ActiveCustomersAboutToChurn: helpers.ActiveCustomersAboutToChurn,
    NoCategory: helpers.NoCategory,
  });
  const [category, setCategory] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setText({ ...text, [name]: value });
  };

  const onSubmitSingle = (e) => {
    e.preventDefault();
    const data = {
      api_key: `${TERMII_API_KEY}`,
      from: 'XWF',
      type: 'plain',
      channel: 'generic',
      sms: `${helpers.XWFmessages(category, text)}`,
      to: oneUser.phoneNumber,
    };
    sendSingleSMS(data);
  };

  return (
    <div
      className="modal fade"
      id="singleSMS"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog mt-5" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title text-center" id="exampleModalLabel">
              Send SMS To `{oneUser.ContactName || oneUser.customer_name}`
            </h3>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form onSubmit={onSubmitSingle}>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="text" className="p-0 col-form-label">
                  Category:
                </label>
                <div className="col-12">
                  <select
                    name="category"
                    onChange={(e) => setCategory(e.target.value)}
                    className="form-control border border-info py-2"
                  >
                    <option value="">--Select Category--</option>
                    <option value="CHURNED CUSTOMERS">CHURNED CUSTOMERS</option>
                    <option value="ACTIVE CUSTOMERS ABOUT TO CHURN">ACTIVE CUSTOMERS ABOUT TO CHURN</option>
                  </select>
                </div>
              </div>
              {category.length > 0 && (
                <div className="form-group">
                  <label className="p-0 col-form-label">Message:</label>
                  <textarea
                    className="form-control"
                    name={helpers.XWFname(category)}
                    value={helpers.XWFmessages(category, text)}
                    rows={8}
                    onChange={handleChange}
                  />
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">
                Close
              </button>
              <button type="submit" className="btn btn-primary" disabled={!category.length}>
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default XWFSingleSMS;
