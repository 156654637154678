/* eslint-disable @typescript-eslint/camelcase */
import { useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import { toast } from 'react-toastify';
import {
  FETCHING_STARTS,
  FETCHING_STOPS,
  LOADING_STARTS,
  LOADING_STOPS,
  REFETCH,
} from '../../../../../../constants/types/shared/LoadingTypes';
import {
  CREATE_ZONE_URL,
  CREATE_SUB_ZONE_URL,
  DELETE_ZONE_URL,
  EDIT_ZONE_URL,
  GET_ZOHO_URL,
} from '../../../../../../services/api/shared/installations/ZonesUrl';
import installationZoneReducer from '../../../../../../reducers/field-support/installation-zone/installationZoneReducer';
import {
  GET_ZONES,
  GET_SUB_ZONES,
  DELETE_ZONE,
} from '../../../../../../constants/types/operations/installations/InstallationsTypes';
import { API } from '../../../../../../services/api/tizeti';

const useInstallationZones = () => {
  const [loader, setLoader] = useState(false);
  const [items, setItems] = useState('');
  const [{ loadingForm, zones, subZones, loading }, dispatch] = useReducer(installationZoneReducer, {
    loadingForm: false,
    zones: [],
    subZones: [],
    loading: false,
  });
  const zoneState = { name: '', sub_zones: [] };
  const subZoneState = { name: '', zone_id: '' };
  const [zone, setZone] = useState(zoneState);
  const [query, setQuery] = useState('');
  const [subZone, setSubZone] = useState(subZoneState);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletingZone, setDeletingZone] = useState(false);
  const onZoneChange = (e) => {
    setZone({ ...zone, [e.target.name]: e.target.value });
  };
  const onSubZoneChange = (e) => {
    setSubZone({ ...subZone, [e.target.name]: e.target.value });
  };
  const onShowModal = () => setShowDeleteModal(!showDeleteModal);
  const onSubmitZone = async () => {
    try {
      dispatch({ type: LOADING_STARTS });
      const response = await axios.post(`${CREATE_ZONE_URL}`, zone, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.id) {
        setZone(zoneState);
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
        toast.success(`zone added`);

        dispatch({ type: REFETCH, payload: response.data });
      } else {
        toast.info(`Unable to add ZONE`);
        dispatch({ type: LOADING_STOPS });
      }
    } catch (e) {
      dispatch({ type: LOADING_STOPS });
      toast.info('Something went wrong!');
    }
  };
  const onSubmitSubZone = async () => {
    try {
      dispatch({ type: LOADING_STARTS });
      const response = await axios.post(`${CREATE_SUB_ZONE_URL}`, subZone, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.id) {
        setSubZone(subZoneState);
        toast.success(`sub zone added`);
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
        dispatch({ type: REFETCH, payload: response.data });
      } else {
        toast.info(`Unable to add sub zone`);
        dispatch({ type: LOADING_STOPS });
      }
    } catch (e) {
      dispatch({ type: LOADING_STOPS });
      toast.info('Something went wrong!');
    }
  };
  const deleteZone = async (param) => {
    setDeletingZone(!deletingZone);
    try {
      const response = await API.delete(`${DELETE_ZONE_URL}/${param}`);
      if (response.data) {
        toast.success('Zone Deleted');
        dispatch({ type: DELETE_ZONE, payload: param });
        setDeletingZone(false);
        setShowDeleteModal(false);
      }
    } catch (e) {
      setDeletingZone(!deletingZone);
    }
  };

  const deleteSubZone = async (id) => {
    setDeletingZone(!deletingZone);
    try {
      const response = await API.delete(`${CREATE_SUB_ZONE_URL}/${id}`);
      if (response.data[0] === 1) {
        toast.success('Sub-Zone Deleted');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (e) {
      setDeletingZone(!deletingZone);
    }
  };

  const getZohoDepartment = async () => {
    setLoader(true);
    try {
      const response = await axios.get(`${GET_ZOHO_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        setLoader(false);
        setItems(response.data.data.data);
      }
    } catch (err) {
      if (err) {
        setLoader(false);
        toast.error(err.response.message);
      }
    }
  };

  useEffect(() => {
    async function getZones() {
      dispatch({
        type: FETCHING_STARTS,
      });
      try {
        return await axios.get(`${CREATE_ZONE_URL}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });
      } catch (e) {
        return e;
      }
    }

    getZones()
      .then((response) => {
        dispatch({
          type: GET_ZONES,
          payload: response.data,
        });
      })
      .catch(() => dispatch({ type: FETCHING_STOPS }));

    async function getSubZones() {
      dispatch({
        type: FETCHING_STARTS,
      });
      try {
        return await axios.get(`${CREATE_SUB_ZONE_URL}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });
      } catch (e) {
        return e;
      }
    }

    getSubZones()
      .then((response) => {
        dispatch({
          type: GET_SUB_ZONES,
          payload: response.data,
        });
      })
      .catch(() => dispatch({ type: FETCHING_STOPS }));
  }, []);

  return {
    loadingForm,
    onZoneChange,
    onSubZoneChange,
    zones,
    getZohoDepartment,
    loader,
    items,
    subZones,
    loading,
    zone,
    onSubmitZone,
    subZone,
    query,
    setQuery,
    onSubmitSubZone,
    onShowModal,
    showDeleteModal,
    deleteZone,
    deleteSubZone,
    deletingZone,
  };
};

export default useInstallationZones;
