/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState, useRef } from 'react';
import CustomDropdownOption from '../forms/CustomDropdownOption';
import helpers from '../../../../utils/helpers';
import ReactPaginate from 'react-paginate';
import useRefund from '../refund/hooks/useRefund';
import RefundFlowTable from './RefundFlowTable';
import CustomCsvDownload from '../CustomCsvDownload';
import { filename, refundHeaders } from '../../../../utils/field-support/installations/helpers';
import { ACCOUNTS, OPERATIONS, SALES, SUPER_ADMIN, VICE_PRESIDENT_SALES } from '../../../../utils/departments/helpers';

const RefundFlowBody = ({ data, deptId }) => {
  let searchOptions = ['email', 'refund_status', 'date'];
  const refundStatusList = ['rejected', 'approved', 'uploaded', 'awaiting'];
  const refundApprovalStatusList = ['approved', 'rejected', 'awaiting'];

  if (deptId === ACCOUNTS) {
    searchOptions.push('date and status');
  }

  if (deptId === SALES || deptId === VICE_PRESIDENT_SALES) {
    searchOptions[1] = 'sales_approval status';
  } else if (deptId === OPERATIONS) {
    searchOptions[1] = 'fse_approval status';
  } else if (deptId === ACCOUNTS) {
    searchOptions[1] = 'coo_approval status';
  }

  const header = [
    'First Name',
    'Last Name',
    'Email',
    'Phone Number',
    'Address',
    'State',
    'Bank Name',
    'Account Name',
    'Account Number',
    'Refund Status',
    'Note',
    'Payment Ref',
    'FSE Comment',
    'Sales Comment',
    'Coo Comment',
    'Account Comment',
  ];

  const departmentId = sessionStorage.getItem('departmentId');

  const {
    lists,
    searchUsingKeyword,
    page,
    fetching,
    refunding,
    count,
    option: value,
    editRefundModal,
    updateRefund,
    onUpdateRefund,
    openEditRefundModal,
  } = useRefund();

  const rows = lists.map((list) => ({
    firstName: list.firstName,
    lastName: list.lastName,
    email: list.email,
    phoneNumber: list.phoneNumber,
    address: list.address,
    state: list.state,
    bankName: list.bank_name,
    accountName: list.account_name,
    accountNumber: list.account_number,
    refundStatus: list.refund_status,
    note: list.note,
    paymentRef: list.payment_ref,
    fscComment: list.fse_comment,
    salesComment: list.sales_comment,
    cooComment: list.coo_comment,
    accountComment: list.account_comment,
  }));

  function handleDownloadExcel() {
    if (rows && window.XLSX) {
      const XLSX = window.XLSX;

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(rows);

      XLSX.utils.sheet_add_aoa(worksheet, [header], { origin: 'A1' });

      XLSX.utils.book_append_sheet(workbook, worksheet, 'Refund Data');

      XLSX.writeFile(workbook, 'Refund.xlsx', { compression: true });
    }
  }

  const [date, setDate] = useState({ from: '', to: '' });
  const [keyword, setKeyword] = useState('');
  const [option, setOption] = useState('');
  const [reloadPage, setReloadPage] = useState(page);
  const [reloadData, setReloadData] = useState(data);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDate({ ...date, [name]: value });
  };

  const filterUsingKeyword = () => {
    setReloadPage(page);
    switch (option) {
      case 'email':
        return searchUsingKeyword(page, { email: keyword.trim() });

      case 'fse_sendback':
        return searchUsingKeyword(page, {
          send_back_to_department: 'e1f5454d-660d-4a0e-8640-c90950dd97fc',
          fse_approval: 'awaiting',
        });
      case 'sales_sendback':
        return searchUsingKeyword(page, {
          send_back_to_department: 'aaf924bf-e16f-4ddc-843b-be5f91c8b0d2',
          fse_approval: 'approved',
          sales_approval: 'awaiting',
        });

      case 'refund_status':
        return searchUsingKeyword(page, {
          sales_approval: 'approved',
          fse_approval: 'approved',
          coo_approval: 'approved',
          refund_status: keyword.trim(),
        });

      case 'coo_approval status':
        return searchUsingKeyword(page, {
          sales_approval: 'approved',
          fse_approval: 'approved',
          coo_approval: keyword.trim(),
        });
      case 'fse_approval status':
        return searchUsingKeyword(page, {
          ...data,
          fse_approval: keyword.trim(),
        });
      case 'sales_approval status':
        return searchUsingKeyword(page, {
          ...data,
          sales_approval: keyword.trim(),
        });

      case 'coo_approval':
        return searchUsingKeyword(page, {
          ...data,
          coo_approval: keyword.trim(),
        });
      case 'fse_approval':
        return searchUsingKeyword(page, {
          ...data,
          fse_approval: keyword.trim(),
        });
      case 'sales_approval':
        return searchUsingKeyword(page, {
          ...data,
          sales_approval: keyword.trim(),
        });

      case 'date':
        return searchUsingKeyword(page, {
          from: date.from,
          to: date.to,
          ...data,
        });

      case 'date and status':
        return searchUsingKeyword(page, {
          from: date.from,
          to: date.to,
          sales_approval: 'approved',
          fse_approval: 'approved',
          coo_approval: 'approved',
          refund_status: keyword.trim(),
        });
      case 'date and coo status':
        return searchUsingKeyword(page, {
          from: date.from,
          to: date.to,
          sales_approval: 'approved',
          fse_approval: 'approved',
          coo_approval: keyword.trim(),
        });

      default:
        return null;
    }
  };
  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    setReloadPage(selectedPage);
    if (option === 'email') return searchUsingKeyword(selectedPage, { email: keyword });
    if (option === 'fse_sendback')
      return searchUsingKeyword(selectedPage, {
        send_back_to_department: 'e1f5454d-660d-4a0e-8640-c90950dd97fc',
        fse_approval: 'awaiting',
      });

    if (option === 'sales_sendback')
      return searchUsingKeyword(selectedPage, {
        fse_approval: 'approved',
        send_back_to_department: 'aaf924bf-e16f-4ddc-843b-be5f91c8b0d2',
        sales_approval: 'awaiting',
      });

    if (option === 'refund_status')
      return searchUsingKeyword(selectedPage, {
        sales_approval: 'approved',
        fse_approval: 'approved',
        coo_approval: 'approved',
        refund_status: keyword,
      });
    if (option === 'date')
      return searchUsingKeyword(selectedPage, {
        from: date.from,
        to: date.to,
      });

    if (option === 'date and status')
      return searchUsingKeyword(selectedPage, {
        sales_approval: 'approved',
        fse_approval: 'approved',
        coo_approval: 'approved',
        refund_status: keyword,
        from: date.from,
        to: date.to,
      });
    if (option === 'date and coo status')
      return searchUsingKeyword(selectedPage, {
        sales_approval: 'approved',
        fse_approval: 'approved',
        coo_approval: keyword,
        from: date.from,
        to: date.to,
      });
    else {
      return searchUsingKeyword(selectedPage, data);
    }
  };
  // let newList;
  // newList = lists.filter((item) => item.approved_by !== null && item.approved_at === null);
  // console.log(newList);
  useEffect(() => {
    searchUsingKeyword(page, data);
  }, []);

  const reload = (page, item, option) => {
    if (option === 'email') return searchUsingKeyword(page, { email: keyword });
    if (option === 'refund_status')
      return searchUsingKeyword(page, {
        sales_approval: 'approved',
        fse_approval: 'approved',
        coo_approval: 'approved',
        refund_status: keyword,
      });
    if (option === 'coo_approval status')
      return searchUsingKeyword(page, {
        sales_approval: 'approved',
        fse_approval: 'approved',
        coo_approval: keyword.trim(),
      });
    if (option === 'date and status')
      return searchUsingKeyword(page, {
        from: date.from,
        to: date.to,
        sales_approval: 'approved',
        fse_approval: 'approved',
        coo_approval: 'approved',
        refund_status: keyword.trim(),
      });
    if (option === 'date')
      return searchUsingKeyword(page, {
        from: date.from,
        to: date.to,
      });
    else {
      return searchUsingKeyword(page, item);
    }
  };

  const pageNo = Math.round(count / 20);

  const searchBody = () => {
    if (option === 'refund_status') {
      return (
        <div className="form-group">
          <select name="chooseField" onChange={(e) => setKeyword(e.target.value)} className="form-control">
            <option value="">--Choose Refund Option--</option>
            {refundStatusList.map((opt) => {
              return <CustomDropdownOption value={opt} name={helpers.capitalizeAllFirstLetter(opt)} />;
            })}
          </select>
        </div>
      );
    } else if (
      option === 'fse_approval status' ||
      option === 'sales_approval status' ||
      option === 'coo_approval status'
    ) {
      return (
        <div className="form-group">
          <select name="chooseField" onChange={(e) => setKeyword(e.target.value)} className="form-control">
            <option value="">--Choose Refund Option--</option>
            {refundApprovalStatusList.map((opt) => {
              return <CustomDropdownOption value={opt} name={helpers.capitalizeAllFirstLetters(opt)} />;
            })}
          </select>
        </div>
      );
    } else if (option === 'date') {
      return (
        <>
          <div className="form-group mx-4">
            <label className="mt-2 p-0 copy-font">From: </label>
            <input
              classname="form-control mr-2"
              name="from"
              value={date.from}
              onChange={handleChange}
              readOnly={false}
              type="date"
            />
          </div>

          <div className="form-group mx-4">
            <label className="mt-2 p-0 copy-font">To: </label>
            <input
              classname="form-control mr-2"
              name="to"
              value={date.to}
              onChange={handleChange}
              readOnly={false}
              type="date"
            />
          </div>
        </>
      );
    } else if (option === 'date and status') {
      return (
        <>
          <div className="form-group mx-4">
            <label className="mt-2 p-0 copy-font">From: </label>
            <input
              classname="form-control mr-2"
              name="from"
              value={date.from}
              onChange={handleChange}
              readOnly={false}
              type="date"
            />
          </div>

          <div className="form-group mx-4">
            <label className="mt-2 p-0 copy-font">To: </label>
            <input
              classname="form-control mr-2"
              name="to"
              value={date.to}
              onChange={handleChange}
              readOnly={false}
              type="date"
            />
          </div>

          <div className="form-group mx-4">
            <select name="chooseField" onChange={(e) => setKeyword(e.target.value)} className="form-control">
              <option value="">--Choose Refund Option--</option>
              {refundStatusList.map((opt) => {
                return <CustomDropdownOption value={opt} name={helpers.capitalizeAllFirstLetter(opt)} />;
              })}
            </select>
          </div>
        </>
      );
      return (
        <>
          <div className="form-group mx-4">
            <label className="mt-2 p-0 copy-font">From: </label>
            <input
              classname="form-control mr-2"
              name="from"
              value={date.from}
              onChange={handleChange}
              readOnly={false}
              type="date"
            />
          </div>

          <div className="form-group mx-4">
            <label className="mt-2 p-0 copy-font">To: </label>
            <input
              classname="form-control mr-2"
              name="to"
              value={date.to}
              onChange={handleChange}
              readOnly={false}
              type="date"
            />
          </div>

          <div className="form-group mx-4">
            <select name="chooseField" onChange={(e) => setKeyword(e.target.value)} className="form-control">
              <option value="">--Choose status--</option>
              {refundApprovalStatusList.map((opt) => {
                return <CustomDropdownOption value={opt} name={helpers.capitalizeAllFirstLetters(opt)} />;
              })}
            </select>
          </div>
        </>
      );
    }

    return (
      <div className="form-group ml-2">
        <input
          className="form-control"
          type="text"
          placeholder="Input Search Term"
          style={{ width: '230px' }}
          name="keyword"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
      </div>
    );
  };

  return (
    <div className="col-12 p-0 after-summary">
      <div className="row mx-2">
        <div className="mb-1">
          <div className="smallTop d-flex">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                filterUsingKeyword();
              }}
              className="form-inline ml-3"
            >
              <div className="form-group">
                <select name="chooseField" onChange={(e) => setOption(e.target.value)} className="form-control">
                  <option value="">--Choose Search Field--</option>
                  {searchOptions.map((opt, index) => {
                    return (
                      <CustomDropdownOption key={index} value={opt} name={helpers.capitalizeAllFirstLetters(opt)} />
                    );
                  })}
                </select>
              </div>

              {searchBody()}
              <div className="form-group ml-2  ">
                <button className="btn btn-green" type="submit">
                  Search
                </button>
              </div>
            </form>
          </div>
        </div>
        <br />
        <br />
        <div className="col-12 d-flex mt-2">
          <CustomCsvDownload
            data={lists || []}
            headers={refundHeaders}
            filename={filename(`Refund`)}
            classname="btn btn-green shadow-md"
            textcolor="white"
            text="Download Data"
          />
          {departmentId === SUPER_ADMIN && (
            <button className="btn btn-green shadow-md ml-5 " onClick={handleDownloadExcel}>
              Download Excel
            </button>
          )}
        </div>

        {deptId === OPERATIONS && (
          <div className="form-group ml-2 mt-3 ">
            <button
              className="btn btn-green"
              type="submit"
              onClick={() => {
                // e.preventDefault();
                setOption('fse_sendback');
                filterUsingKeyword();
              }}
            >
              FSE Send Back Bucket
            </button>
          </div>
        )}

        {(deptId === SALES || VICE_PRESIDENT_SALES) && (
          <div className="form-group ml-2 mt-3 ">
            <button
              className="btn btn-green"
              type="submit"
              onClick={() => {
                // e.preventDefault();
                setOption('sales_sendback');
                filterUsingKeyword();
              }}
            >
              Sales Send Back Bucket
            </button>
          </div>
        )}

        <div className="card x-panel p-0">
          <RefundFlowTable
            option={option}
            reload={reload}
            data={reloadData}
            page={reloadPage}
            lists={lists}
            keyword={keyword}
            isLoading={fetching}
            onUpdateRefund={onUpdateRefund}
            updateRefund={updateRefund}
            editRefundModal={editRefundModal}
            openEditRefundModal={openEditRefundModal}
            refunding={refunding}
          />
          <div className="px-3">
            {pageNo > 1 && (
              <ReactPaginate
                previousLabel="previous"
                nextLabel="next"
                breakLabel="..."
                breakClassName="break-me"
                pageCount={pageNo}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName="pagination"
                activeClassName="active"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefundFlowBody;
