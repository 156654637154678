import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ViewDirectory extends Component {
  render() {
    const { firstName, countryCode, phoneNumber, twitter, instagram, address, category, type } = this.props.directory;

    return (
      <div
        id="view"
        className="modal fade in"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center" id="myModalLabel">
                Contact Details
              </h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
            </div>
            <div className="modal-body detail" style={{ padding: 0 }}>
              <div className="row">
                <div className="col-lg-12 d-flex">
                  <div className="col-lg-4 detail-label1">
                    <label id="fn">Business Name</label>
                    <label id="ln">Country Code</label>
                    <label id="gen">Phone Number</label>
                  </div>

                  <div className="col-lg-8 detail-result1">
                    <p className="mt-4 mb-1">{firstName}</p>
                    <p className="mb-2">{countryCode}</p>
                    <p>{phoneNumber}</p>
                  </div>
                </div>
                <hr />

                <div className="col-lg-12 d-flex">
                  <div className="col-lg-4 detail-label2">
                    <label id="address">Address</label>
                    <label id="email" style={{ display: 'block' }}>
                      Category
                    </label>
                  </div>

                  <div className="col-lg-8 detail-result2">
                    <p className="mt-4 mb-2">{address}</p>
                    <p>{category}</p>
                  </div>
                </div>

                <div className="col-lg-12 d-flex">
                  <div className="col-lg-4 detail-label3">
                    <label id="dep">Twitter</label>
                    <label id="role">Instagram</label>
                    <label id="role">Type</label>
                  </div>

                  <div className="col-lg-8 detail-result3">
                    {twitter ? <p className="mt-4 mb-2">{twitter}</p> : <p className="mt-4 mb-2">N/A</p>}
                    {instagram ? <p>{instagram}</p> : <p>N/A</p>}
                    {type ? <p>{type}</p> : <p>N/A</p>}
                  </div>
                </div>

                <div className="col-lg-12 d-flex">
                  <div className="col-lg-4 detail-label4">
                    <label id="employee-id" style={{ display: 'block' }}>
                      Visibility
                    </label>
                  </div>

                  <div className="col-lg-8 detail-result4">
                    <p className="mt-4">Enabled</p>
                  </div>
                </div>
                <div className="modal-footer" style={{ marginLeft: '40%' }}>
                  <Link to="#edit" data-toggle="modal" data-dismiss="modal">
                    <button
                      style={{
                        backgroundColor: '#FFA800',
                      }}
                      type="button"
                      className="btn btn-green waves-effect"
                    >
                      Edit
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ViewDirectory;
