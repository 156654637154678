/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

const RequestDetails = ({ one }) => {
  const { budget_code, reason_for_rejection } = one;
  return (
    <div
      id="requestDetails"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Request Details
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body detail" style={{ padding: '0', textAlign: 'justify' }}>
            <div className="row">
              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label1">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
                  <label id="category">Category</label>
                </div>

                <div className="col-lg-8 detail-result1">
                  <p className="mt-4 mb-1">{one.request_category ? one.request_category.category : 'N/A'}</p>
                </div>
              </div>
              <hr />
              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label2">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
                  <label id="phone1">Description</label>
                </div>

                <div className="col-lg-8 detail-result2">
                  <p className="mt-4 mb-1">{one.description}</p>
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label3">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
                  <label id="plan">Date Requested</label>
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
                  <label id="source">Status</label>
                  {reason_for_rejection ? <label id="source">Reason for rejection</label> : null}
                </div>

                <div className="col-lg-8 detail-result3">
                  <p className="mt-4 mb-1">{one.created_at}</p>
                  <p className="mb-1">{one.status}</p>
                  {reason_for_rejection ? <p className="mb-1">{reason_for_rejection}</p> : null}
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label4">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
                  {/* <label id="recorded">Handled By</label> */}
                  <label id="date">Budget Code</label>
                </div>

                <div className="col-lg-8 detail-result4">
                  {/* <p className="mt-4 mb-1">Idowu Aruwajoye</p> */}
                  <p className="mt-4 mb-1">{budget_code || 'N/A'}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

RequestDetails.propTypes = {
  // eslint-disable-next-line react/require-default-props
  one: PropTypes.string,
};
export default RequestDetails;
