/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import edit from '../../../../assets/img/icons/edit.png';
import view from '../../../../assets/img/icons/view.png';
import UpdatePO from './UpdatePO';
import ViewPO from './ViewPO';

const PurchaseOrderTable = ({ lists, tracker, role }) => {
  const [order, setOrder] = useState({});
  const getOrder = (item) => setOrder(item);

  const tableData =
    lists && lists.length ? (
      lists.map((list, i) => {
        const {
          item,
          quantity,
          comment,
          manager_approval,
          procurement_approval,
          account_approval,
          EmployeeAuth,
        } = list;

        return (
          <tr className="" key={i}>
            <td>{i + 1}</td>
            <td className="text-nowrap">{item}</td>
            <td>{quantity}</td>
            <td>{comment || 'NO COMMENT'}</td>
            {role === 'manager' && (
              <td>
                {EmployeeAuth && EmployeeAuth.employees.length
                  ? `${EmployeeAuth.employees[0].firstName} ${EmployeeAuth.employees[0].lastName}`
                  : 'N/A'}
              </td>
            )}
            {tracker ? (
              <>
                <td className="text-center">
                  <div
                    className={`badge badge-${
                      manager_approval === 'approved' ? 'success' : manager_approval === 'rejected' ? 'danger' : 'info'
                    } text-uppercase`}
                    style={{ width: '100px' }}
                  >
                    {manager_approval}
                  </div>
                </td>
                <td className="text-center">
                  <div
                    className={`badge badge-${
                      procurement_approval === 'approved'
                        ? 'success'
                        : procurement_approval === 'rejected'
                        ? 'danger'
                        : 'info'
                    } text-uppercase`}
                    style={{ width: '100px' }}
                  >
                    {procurement_approval}
                  </div>
                </td>
                <td className="text-center">
                  <div
                    className={`badge badge-${
                      account_approval === 'approved' ? 'success' : account_approval === 'rejected' ? 'danger' : 'info'
                    } text-uppercase`}
                    style={{ width: '100px' }}
                  >
                    {account_approval}
                  </div>
                </td>
              </>
            ) : null}
            <td>
              <div className="">
                {tracker ? (
                  <button
                    type="submit"
                    className="mx-1"
                    data-toggle="modal"
                    data-placement="right"
                    data-target="#viewPO"
                    onClick={() => getOrder(list)}
                  >
                    <span className="view">
                      <img src={view} alt="" data-toggle="tooltip" data-placement="left" title="View" />
                    </span>
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="ml-3"
                    data-toggle="modal"
                    data-placement="right"
                    data-target="#updatePO"
                    onClick={() => getOrder(list)}
                  >
                    <span className="view">
                      <img src={edit} alt="" data-toggle="tooltip" data-placement="left" title="Edit" />
                    </span>
                  </button>
                )}
              </div>
              <UpdatePO order={order} />
              <ViewPO order={order} />
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="10" className="text-center">
          There are no orders
        </td>
      </tr>
    );

  return tableData;
};

export default PurchaseOrderTable;
