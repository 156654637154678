import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

export default function ActiveInactiveUsersByZoneTable({
  getZones,
  sumOfActiveAndInactiveUsers,
  getAllActiveUsersCountValue,
  getAllInActiveUsersCountValue,
}) {
  return (
    <Box sx={{ mr: 5, mb: 5 }}>
      <TableContainer component={Paper} width={'calc(100vw - 300px)'}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bolder' }}>Zones</TableCell>
              {getZones.map((row, index) => (
                <TableCell key={index}>{row.name}</TableCell>
              ))}
            </TableRow>
          </TableHead>

          {/* sx={{ '&:last-child td, &:last-child th': { border: 0 } }}> */}
          <TableBody>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell>Active & Inactive/Expired Users Count</TableCell>
              {getZones.map((row, index) => (
                <TableCell key={`${row.id}-${index}`}>
                  {sumOfActiveAndInactiveUsers.length !== getZones.length ? '...' : sumOfActiveAndInactiveUsers[index]}
                </TableCell>
              ))}
            </TableRow>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell>Active users Count</TableCell>
              {getZones.map((row, index) => (
                <TableCell key={`${row.id}-${index + 1000}`}>
                  {getAllInActiveUsersCountValue === undefined ? '...' : getAllActiveUsersCountValue[index]}
                </TableCell>
              ))}
            </TableRow>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell>InActive / Expired users Count</TableCell>
              {getZones.map((row, index) => (
                <TableCell key={`${row.id}-${index + 2000}`}>
                  {' '}
                  {getAllInActiveUsersCountValue === undefined || getAllInActiveUsersCountValue.length === 0
                    ? '...'
                    : getAllInActiveUsersCountValue[index]}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
