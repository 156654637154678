/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { API_URL } from '../../../../utils/config';

class AssignVehicle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      vehicles: [],
      imei: '',
      vehicle: '',
      phone: '',
      id: '',
      name: '',
    };

    this.onChange = this.onChange.bind(this);
    this.assignVehicle = this.assignVehicle.bind(this);
  }

  componentDidMount() {
    this.getVehicles();
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  getVehicles() {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };

    axios.get(`${API_URL}/vehicle`, config).then((res) => {
      this.setState({
        vehicles: res.data,
      });
    });
  }

  assignVehicle = (e) => {
    e.preventDefault();

    this.setState({
      loading: true,
    });

    const data = {
      imei: this.state.imei,
      vehicle_id: this.state.vehicle,
      phoneNumber: this.state.phone,
      name: this.state.name,
    };

    axios({
      method: 'post',
      url: `${API_URL}/tracked_device/tracking_devices`,
      data,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    }).then((res) => {
      if (res.data.id) {
        this.setState({
          loading: false,
          imei: '',
          vehicle: '',
          phone: '',
          name: '',
        });

        toastr.success('Assignment Successful!');
      } else {
        toastr.info('Something went wrong');
      }
    });
  };

  displayVehicles() {
    if (this.state.vehicles) {
      return (
        <select className="form-control" name="vehicle" onChange={this.onChange}>
          <option value="">Select Vehicle</option>
          {this.state.vehicles.map((vehicle) => (
            <option key={vehicle.id} value={vehicle.id}>
              {vehicle.model}
            </option>
          ))}
        </select>
      );
    }

    return null;
  }

  render() {
    const { loading } = this.state;

    return (
      <div
        id="assignvehicle"
        className="modal fade in"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center" id="myModalLabel">
                Assign Vehicle
              </h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
            </div>
            <div className="modal-body">
              <form className="form-horizontal col-lg-12 p-0" onSubmit={this.assignVehicle}>
                <div className="form-group col-lg-12 p-0">
                  <div className="col-lg-12 d-flex mb-2 p-0">
                    <div className="col-lg-6 mb-3 ">
                      <label className="copy-font m-0 p-0">Driver Name</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Wificall"
                        name="name"
                        onChange={this.onChange}
                        value={this.state.name}
                      />
                    </div>

                    <div className="col-lg-6 mb-3 ">
                      <label className="copy-font m-0 p-0">Vehicles</label>
                      {this.displayVehicles()}
                    </div>
                  </div>

                  <div className="col-lg-12 d-flex mb-2 p-0">
                    <div className="col-lg-6">
                      <label className="copy-font m-0 p-0">SIM card Phone Number</label>
                      <input
                        className="form-control"
                        type="number"
                        placeholder="SIM card Phone Number"
                        name="phone"
                        onChange={this.onChange}
                        value={this.state.phone}
                      />
                    </div>

                    <div className="col-lg-6">
                      <label className="copy-font m-0 p-0">IMEI Number</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Phone IMEI Number"
                        name="imei"
                        onChange={this.onChange}
                        value={this.state.imei}
                      />
                    </div>
                  </div>
                </div>
                {loading ? (
                  <div className="col-lg-12 mt-5 text-center">
                    <button className="btn btn-green" type="button" disabled>
                      Assign Vehicle
                    </button>
                  </div>
                ) : (
                  <div className="col-lg-12 mt-5 text-center">
                    <button className="btn btn-green" type="button">
                      Assign Vehicle
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AssignVehicle;
