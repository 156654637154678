import React, { Component } from 'react';

class PublicModal extends Component {
  render() {
    return (
      <div
        id="publicModal"
        className="modal fade in"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body">
              <div className="col-lg-12">
                <h1>
                  <strong>Please Tell us what you think</strong>
                </h1>
                <h5 className="mt-3">
                  On a scale of 0 to 10, how likely would you recommend this Product / Service to a friend?
                </h5>
              </div>

              <div className="col-lg-12 d-flex justify-content-between mt-4">
                <button type="button" className="btn btn-danger make-wide">
                  <strong>0</strong>
                </button>
                <button type="button" className="btn btn-danger make-wide">
                  <strong>1</strong>
                </button>
                <button type="button" className="btn btn-danger make-wide">
                  <strong>2</strong>
                </button>
                <button type="button" className="btn btn-danger make-wide">
                  <strong>3</strong>
                </button>
                <button type="button" className="btn btn-danger make-wide">
                  <strong>4</strong>
                </button>
                <button type="button" className="btn btn-danger make-wide">
                  <strong>5</strong>
                </button>
                <button type="button" className="btn btn-danger make-wide">
                  <strong>6</strong>
                </button>
                <button type="button" className="btn btn-warning make-wide text-white">
                  <strong>7</strong>
                </button>
                <button type="button" className="btn btn-warning make-wide text-white">
                  <strong>8</strong>
                </button>
                <button type="button" className="btn btn-warning make-wide text-white green-color">
                  <strong>9</strong>
                </button>
                <button type="button" className="btn btn-warning make-wide text-white green-color">
                  <strong>10</strong>
                </button>
              </div>

              <div className="col-lg-12 d-flex p-0 mt-4">
                <div className="col-lg-6 text-left">
                  <h6>
                    <strong>Not Likely</strong>
                  </h6>
                </div>

                <div className="col-lg-6 text-right">
                  <h6>
                    <strong>Very Likely</strong>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PublicModal;
