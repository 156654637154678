/* eslint-disable camelcase */
/* eslint-disable react/require-default-props */
/* eslint-disable no-undef */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import toastr from 'toastr';
import mail from '../../../../../assets/img/icons/mailblast.svg';
import Logout from '../../../shared/auth/Logout';
import NewProductSideBar from '../../../../layouts/new-products/NewProductsSideBar';
import { getUser } from '../../../../../actions/shared/user/UserActions';
import { getLoggedInUser } from '../../../../../actions/user/UserActions';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import { NEW_PRODUCTS } from '../../../../../utils/config';
import Requisitions from '../../../shared/requisition/shared/Requisitions';
import { SUPER_ADMIN } from '../../../../../utils/departments/helpers';

class NewProductsRequisition extends Component {
  componentDidMount() {
    this.checkDepartment();
    const { getLoggedInUser } = this.props;
    getLoggedInUser();
  }

  componentWillReceiveProps() {
    this.checkDepartment();
  }

  checkDepartment = async () => {
    const { history } = this.props;
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.department_id !== `${NEW_PRODUCTS}` && response.data.department_id !== `${SUPER_ADMIN}`) {
        history.push('/');

        sessionStorage.setItem('token', '');
        sessionStorage.setItem('email', '');
        sessionStorage.clear();

        toastr.info('You are not allowed to view this page');
      }
    } catch (e) {
      toastr.info('Something went wrong!');
    }
  };

  render() {
    const { user, role } = this.props;
    const { firstName } = user;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <Link className="s-sidebar__trigger" to="#">
                <i className="fa fa-bars mr-3" />
              </Link>
              <NewProductSideBar />
              <h3 className="col-lg-6 overview-part">Requisition</h3>
              <span className="navbar-text">
                <div className="d-flex">
                  {role > '2' ? (
                    <Link
                      to="/new-products/mail-blast"
                      className="d-flex"
                      style={{
                        color: 'gray',
                        textDecoration: 'none',
                      }}
                    >
                      <img src={mail} className="logout mr-2 mt-0 mb-2" alt="" />
                      <span>
                        <h6 className="mt-1 mr-3">
                          <strong>MailBlast</strong>
                        </h6>
                      </span>
                    </Link>
                  ) : null}
                  <h6 className="mr-0 mt-1">
                    {firstName}
                    <Logout />
                  </h6>
                </div>
              </span>
            </div>
          </div>

          <div className="after-summary">
            <Requisitions />
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  role: state.auth.auth,
});

NewProductsRequisition.propTypes = {
  history: PropTypes.shape(),
  getLoggedInUser: PropTypes.func,
  user: PropTypes.shape(),
  role: PropTypes.shape(),
};
export default connect(mapStateToProps, {
  getUser,
  getLoggedInUser,
})(NewProductsRequisition);
