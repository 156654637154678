import React from 'react';
import { ADMIN_DEPARTMENT, PROCUREMENT, SUPER_ADMIN } from '../../../../../utils/departments/helpers';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import useCheckTwoDepartments from '../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';
import AdminDeptNavBar from '../../../../layouts/admin-department/AdminDeptNavBar';
import AccountVehiclesBody from '../../accounts/vehicles/AccountVehiclesBody';

const AdminDepartmentVehicles = ({ history }) => {
  useCheckTwoDepartments(`${ADMIN_DEPARTMENT}`, `${SUPER_ADMIN}`, history);
  const {
    profile: { firstName },
  } = useUser();

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <AdminDeptNavBar currentPage="Vehicles" position={firstName} />
        <AccountVehiclesBody />
      </main>
    </div>
  );
};

export default AdminDepartmentVehicles;
