// import { GET_EMPLOYEES_BY_DEPARTMENT } from "../../../types/shared/staff/EmployeesTypes";
//
// const initialState = {
//
//   agents: []
//
// };
//
// export const AgentsReducer=(state=initialState, action) => {
//   if (action.type === GET_EMPLOYEES_BY_DEPARTMENT) {
//     return{
//
//       ...state,
//       agents: action.payload
//
//     };
//   } else {
//     return state;
//   }
//
// };
//
// export default AgentsReducer;

import { GET_USER_SCORE } from '../../../constants/types/customer-support/CustomerSupportTypes';

const initialState = {
  score: [],
};

export const AgentsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // a user t get his own score
    case GET_USER_SCORE:
      return {
        ...state,
        score: payload,
      };

    default:
      return state;
  }
};

export default AgentsReducer;
