/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable import/prefer-default-export */
import { useReducer, useEffect } from 'react';
import axios from 'axios';
import qs from 'qs';
import toastr from 'toastr';
import * as load from '../../../../constants/types/shared/LoadingTypes';
import * as types from '../../../../constants/types/asset-team/AssetTeamTypes';
import { activeZoneReducer } from '../../../../reducers/asset-team/active-zones/activeZoneReducer';
import { GET_ROUTER_URL } from '../../../../services/api/shared/BasestationsUrl';

export const useActiveZone = () => {
  const [{ items, time, total, isLoading }, dispatch] = useReducer(activeZoneReducer, {
    items: [],
    time: '',
    total: 0,
    isLoading: false,
  });

  const getActiveUsersPerZone = async () => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const params = { action: 'tizeti_asset_active_zone_count' };
      const response = await axios.post(`${GET_ROUTER_URL}`, qs.stringify(params));
      if (response.data.active_per_zone.items) {
        dispatch({
          type: types.GET_ACTIVE_ZONES,
          payload: {
            items: response.data.active_per_zone.items,
            time: response.data.readable_last_updated_at,
            total: response.data.total_active_customer,
          },
        });
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  useEffect(() => {
    getActiveUsersPerZone();
  }, []);

  return { time, items, total, isLoading };
};
