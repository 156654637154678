/* eslint-disable import/prefer-default-export */
import * as types from '../../constants/types/UserTypes';
import { LOADING_STARTS, LOADING_STOPS } from '../../constants/types/shared/LoadingTypes';

export const userReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GET_LOGGED_IN_USER_PROFILE:
      return {
        ...state,
        profile: payload,
        load: false,
      };

    case types.GET_LOGGED_IN_USER:
      return {
        ...state,
        user: payload,
      };

    case LOADING_STARTS:
      return {
        ...state,
        load: true,
      };

    case LOADING_STOPS:
      return {
        ...state,
        load: false,
      };

    default:
      return state;
  }
};
