/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import Logout from '../../../shared/auth/Logout';
import CustomerSupportSideBar from '../../../../layouts/customer-support/CustomerSupportSideBar';
import { API_URL, customer_support } from '../../../../../utils/config';
import NewPosting from '../../../shared/recruitment/managers/NewPosting';
import RecruitmentTable from '../../../shared/recruitment/RecruitmentTable';
import { getCustomerSupportJobs } from '../../../../../actions/shared/recruitment/RecruitmentActions';
import { getJobsApplications } from '../../../../../actions/shared/recruitment/ApplicationsActions';
import ApplicantTable from '../../../shared/recruitment/applications/ApplicantTable';
import ApplicationsTableHead from '../../../shared/recruitment/applications/ApplicationsTableHead';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';

class SupportRecruitment extends Component {
  static propTypes = {
    jobs: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    getCustomerSupportJobs: PropTypes.func.isRequired,
    applications: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    getLoggedInUser: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      pages: [],
      logs: [],
      department: '',
      loading: false,
      firstname: '',
    };
  }

  componentDidMount() {
    this.props.getCustomerSupportJobs();
    this.checkDepartment();
  }

  componentWillReceiveProps(nextProps) {
    this.checkDepartment();

    if (nextProps.jobs) {
      this.setState(
        {
          logs: nextProps.jobs.logs,
          pages: nextProps.jobs.pages,
          count: nextProps.jobs.count,
        },
        // eslint-disable-next-line no-console
        () => console.log(this.state.logs),
      );
    }
  }

  checkDepartment = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.department_id !== `${customer_support}`) {
        this.props.history.push('/login');

        sessionStorage.setItem('token', '');
        sessionStorage.clear();

        toastr.info('You are not allowed to view this page. sorry');
      }
    } catch (e) {
      //   console.log(e.response);
    }
  };

  handlePageChange = (pageNumber) => {
    const selected = pageNumber.selected + 1;

    const url = `${API_URL}/recruitment/listing/?page=${selected}`;

    axios({
      method: 'get',
      url,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      }
    }).then((response) => {
      if (response.data) {
        this.setState({
          logs: response.data.logs,
          pages: response.data.pages,
        });
      } else {
        toastr.info('No data!');
      }
    });
  };

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  getJobsByDate = (department) => {
    // console.log(department);
    if (department) {
      this.setState({
        loading: true,
      });

      const url = `${API_URL}/recruitment/listing/${department}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        })
        .then((response) => {
          this.setState({
            loading: false,
            logs: response.data.logs,
            pages: response.data.pages,
          });
        })
        .catch(() => {
          //   console.log(err.response);
        });
    }
  };

  // check departments todo

  getDepartmentApplications = (job) => {
    // console.log(job);

    this.props.getJobsApplications(job);
  };

  render() {
    const { loading, firstname } = this.state;
    const { logs } = this.props.jobs;
    const { applications } = this.props;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <CustomerSupportSideBar />

              <h3 className="col-lg-7 overview-part">Recruitment</h3>

              <h6 className="ml-5">
                <Logout />
              </h6>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 mt-5">
                <div className="mt-5">
                  <div className="col-6">
                    <div className="tab-stuff col-10 p-0">
                      <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li className="nav-item mr-auto mb-2">
                          <a
                            className="nav-link active text-nowrap"
                            id="pills-home-tab"
                            data-toggle="pill"
                            href="#pills-home"
                            role="tab"
                            aria-controls="pills-home"
                            aria-selected="true"
                          >
                            Job Postings
                          </a>
                        </li>
                        <li className="nav-item mr-auto mb-2">
                          <a
                            className="nav-link"
                            id="pills-profile-tab"
                            data-toggle="pill"
                            href="#pills-profile"
                            role="tab"
                            aria-controls="pills-profile"
                            aria-selected="false"
                          >
                            Applications
                          </a>
                        </li>
                        <button
                          className="btn btn-green mb-2"
                          data-target="#Newposting"
                          data-toggle="modal"
                          type="button"
                        >
                          New Posting
                        </button>

                        <NewPosting />
                      </ul>
                    </div>
                  </div>

                  <div className="tab-content col-12" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >
                      <div className="col-lg-12 p-0">
                        <div className="card x-panel w-100 ml-0 p-2 mt-4">
                          <div className="table-responsive content col-lg-12 col-md-12 col-sm-12 p-0">
                            <table className="table col-lg-12 col-md-12 col-sm-12 copy-font">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Job Title</th>
                                  <th>Department</th>
                                  {/* <th>Type</th> */}
                                  {/* <th>Posting Date</th> */}
                                  <th>Deadline</th>
                                  {/* <th>Number of Applicants</th> */}
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              {loading ? <small>loading</small> : <RecruitmentTable all={logs} />}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade show"
                      id="pills-profile"
                      role="tabpanel"
                      aria-labelledby="pills-profile-tab"
                    >
                      <div className="col-6 d-flex p-0">
                        <small>Filter By:</small>
                        <div className="d-flex">
                          <span className="ml-2 mr-3">
                            <select
                              className="form-control"
                              onChange={(e) => this.getDepartmentApplications(e.target.value)}
                              name="application"
                            >
                              <option>Jobs</option>
                              {logs
                                ? logs.map((job) => (
                                    <option key={job.id} value={job.id}>
                                      {job.job_title}
                                    </option>
                                  ))
                                : 'no jobs'}
                            </select>
                          </span>

                          <span className="mr-3">
                            <input type="date" className="form-control" />
                          </span>
                        </div>
                      </div>

                      <div className="col-lg-12 p-0">
                        <div className="card x-panel w-100 ml-0 p-2 mt-4">
                          <div className="table-responsive content col-lg-12 col-md-12 col-sm-12 p-0">
                            <table className="table col-lg-12 col-md-12 col-sm-12 copy-font">
                              <ApplicationsTableHead />
                              <ApplicantTable applications={applications} />
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {this.state.pages > 1 ? (
                    <ReactPaginate
                      previousLabel="previous"
                      nextLabel="next"
                      breakLabel="..."
                      breakClassName="break-me"
                      pageCount={this.state.pages}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={this.state.pageRangeDisplayed}
                      onPageChange={this.handlePageChange}
                      containerClassName="pagination"
                      subContainerClassName="pages pagination"
                      activeClassName="active"
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  jobs: state.customersupportjobs.customersupportjobs,
  applications: state.applications.applications,
});

export default connect(
  mapStateToProps,
  {
    getCustomerSupportJobs,
    getJobsApplications,
  },
)(SupportRecruitment);
