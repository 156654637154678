/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import useXWFRetailers from '../../../../../hooks/express-wifi/retailers/useXWFRetailers';

const UpdateXWFRetailers = ({ oneRetailer }) => {
  const { updateRetailerDetails } = useXWFRetailers();
  const { Business, ContactName, address, phoneNumber } = oneRetailer;

  const [one, setOne] = useState({
    businessName: '',
    contactName: '',
    retailerAddress: '',
    phone: '',
  });

  const { businessName, contactName, retailerAddress, phone } = one;

  useEffect(() => {
    const result = {
      businessName: Business,
      contactName: ContactName,
      retailerAddress: address,
      phone: phoneNumber,
    };
    setOne({ ...result });
  }, [oneRetailer]);

  const onFormSubmit = (e) => {
    e.preventDefault();
    const data = {
      Business: businessName.trim(),
      ContactName: contactName.trim(),
      address: retailerAddress.trim(),
      phoneNumber: phone.trim(),
    };
    updateRetailerDetails(oneRetailer.id, data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOne({ ...one, [name]: value });
  };

  return (
    <div id="updateRetailer" className="modal fade mt-5" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header shadow">
            <h4 className="modal-title update_asset">Retailer Details</h4>
            <button type="button" className="btn btn-outline-info btn-circle btn-close" data-dismiss="modal">
              x
            </button>
          </div>
          <div className="modal-body detail mb-4 p-0">
            <form className="col-md-12 p-0" onSubmit={onFormSubmit}>
              <div className="d-flex">
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="businessName">
                    Business Name
                  </label>
                  <input
                    type="text"
                    className="form-control border border-info pl-3 py-2"
                    id="businessName"
                    name="businessName"
                    value={businessName}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="contactName">
                    Contact Name
                  </label>
                  <input
                    type="text"
                    className="form-control border border-info pl-3 py-2"
                    id="contactName"
                    name="contactName"
                    value={contactName}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="d-flex">
                <div className="form-group col-12">
                  <label className="m-0 p-0 copy-font" htmlFor="retailerAddress">
                    Address
                  </label>
                  <input
                    type="text"
                    className="form-control border border-info pl-3 py-2"
                    id="retailerAddress"
                    name="retailerAddress"
                    value={retailerAddress}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="d-flex">
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="phone">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    className="form-control border border-info pl-3 py-2"
                    id="phone"
                    name="phone"
                    value={phone}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="text-center mt-3">
                <button type="submit" className="btn btn-green px-3">
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateXWFRetailers;
