import React, { Component } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { API_URL, ADMIN_DEPARTMENT } from '../../../../../utils/config';
import { API } from '../../../../../services/api/tizeti';
import add from '../../../../../assets/img/icons/Add.svg';
import view from '../../../../../assets/img/icons/view.png';
import edit from '../../../../../assets/img/icons/edit.png';
import cancel from '../../../../../assets/img/icons/cancel-img.png';
import mailblast from '../../../../../assets/img/icons/mailblast.svg';
import AdminDeptLogo from '../../../../layouts/admin-department/AdminDeptLogo';
import AdminDepartmentSideBar from '../../../../layouts/admin-department/AdminDepartmentSideBar';
import Logout from '../../../shared/auth/Logout';

class AdministrationMailBlast extends Component {
  constructor() {
    super();
    this.state = {
      role: '',
      campaigns: [],
      loading: false,
      load: false,
      name: '',
      subject: '',
      body: '',
      fromname: '',
      fromemail: '',
      list: '',
      lists: [],
      one: {},
      id: '',
      listName: '',
    };

    this.createCampaign = this.createCampaign.bind(this);
    this.onChange = this.onChange.bind(this);
    this.deleteOne = this.deleteOne.bind(this);
  }

  componentWillReceiveProps() {
    this.fetchLoggedOnUser();
  }

  componentWillMount() {
    this.fetchLoggedOnUser();
  }

  componentDidMount() {
    this.getCampaigns();
    this.getLists();
  }

  fetchLoggedOnUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employeeReg/logged`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        if (data.data) {
          this.setState({
            role: data.data.role_id,
          });
        }

        if (data.data.department_id !== `${ADMIN_DEPARTMENT}`) {
          this.props.history.push('/staff/login');

          toastr.info('You are not allowed to view this page');
        }
      })
      .catch((err) => {});
  }

  createCampaign(e) {
    e.preventDefault();

    const campaign = {
      name: this.state.name,
      subject: this.state.subject,
      body: this.state.body,
      from_name: this.state.fromname,
      from_email: this.state.fromemail,
      list_id: this.state.list,
    };

    console.log(campaign);
    API.post(`/mail/campaigns`, campaign).then((res) => {
      console.log(res.data);
      if (res.data.data.id) {
        this.setState({
          name: '',
          subject: '',
          body: '',
          fromname: '',
          fromemail: '',
          list: '',
        });

        toastr.success('Campaign created successfully!');
        this.getCampaigns();
      }
    });
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  async getLists() {
    this.setState({
      load: true,
    });

    const response = await API.get(`/mail/list`);
    if (response.data.data) {
      this.setState({
        lists: response.data.data,
        load: false,
      });
    }
  }

  async getCampaigns() {
    this.setState({
      loading: true,
    });

    const response = await API.get(`/mail/campaigns`);

    if (response.data.data) {
      this.setState({
        campaigns: response.data.data,
        loading: false,
      });
    }
  }

  getOne = (list) =>
    this.setState(
      {
        one: list.attributes,
        id: list.id,
        listName: list.attributes.name,
      },
      () => console.log(list.attributes),
    );

  deleteOne = (list) => {
    const data = {
      name: list.attributes.name,
    };
    console.log(data);

    const url = `https://api.tizeti.com/api/v1/mail/list/${list.id}`;
    console.log(url);

    axios({
      method: 'delete',
      url,
      data,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      }
    });

    toastr.success('Deleted!', 'List', {
      timeOut: 1000,
      fadeOut: 1000,
      onHidden() {
        window.location.reload();
      },
    });
  };

  render() {
    const { role } = this.state;
    const { campaigns, loading, lists, load, one, id, listName } = this.state;

    // const listBody = lists ? (
    //   lists.map((list, i) => (
    //     <tr>
    //       <td>{i + 1}</td>
    //       <td onClick={() => this.getOne(list)}>{list.attributes.name}</td>
    //       <td>{moment(list.attributes.created_at).format('DD-MM-YYYY')}</td>
    //       <td>
    //         <div className="d-flex ml-3 img-pointer">
    //           <div data-toggle="modal" data-target="#addSubscribers">
    //             <img src={add} alt="add" onClick={() => this.getOne(list)} />
    //           </div>
    //           <AddSubscribers id={id} />
    //           <div className="view1 ml-2 img-pointer">
    //             <img src={view} alt="" />
    //           </div>
    //         </div>
    //       </td>
    //       <td>
    //         <div className="d-flex ml-2">
    //           <div className="view1 img-pointer" data-target="#editList" data-toggle="modal">
    //             <img src={edit} alt="add" onClick={() => this.getOne(list)} />
    //           </div>
    //           <EditList one={one} id={id} />
    //           <div className="view1 ml-2 img-pointer">
    //             <img src={cancel} alt="cancel" onClick={() => this.deleteOne(list)} />
    //           </div>
    //         </div>
    //       </td>
    //     </tr>
    //   ))
    // ) : (
    //   <h1>No lists</h1>
    // );

    return (
      <div className="main-wrapper">
        <header className="topbar shade bg-white fixed-top">
          <nav className="navbar navbar-expand-lg navbar-light top-navbar fixed-top">
            {/* <Link className="navbar-brand" to={'/administraton'}> */}
            {/* <img */}
            {/* src="https://res.cloudinary.com/tizeti/image/upload/v1542971047/Tizeti-Logo.png" */}
            {/* alt="homepage" */}
            {/* /> */}
            {/* </Link> */}
            <AdminDeptLogo />
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse mt-3" id="navbarText">
              <ul className="navbar-nav">
                <li className="nav-item active">
                  <Link className="nav-link" to="#">
                    <h3>
                      Send Mail<span className="sr-only">(current)</span>
                    </h3>
                  </Link>
                </li>
              </ul>
              <span className="navbar-text d-flex">
                <Link
                  to="/administration/mail-blast"
                  className="d-flex mr-4"
                  style={{ color: 'gray', textDecoration: 'none' }}
                >
                  <img src={mailblast} alt="" className="logout mr-2" />
                  <span>
                    <h6 className="mt-2">
                      <strong>MailBlast</strong>
                    </h6>
                  </span>
                </Link>
                <h6 className="admin mt-2">
                  <i />
                  <Logout />
                </h6>
              </span>
            </div>
          </nav>
        </header>

        <div className="all-sides col-lg-12 col-md-12 col-sm-12">
          <AdminDepartmentSideBar />
          {/* <div className="page-wrapper col-lg-10">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="tab-stuff col-4 mt-4">
                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                      <li className="nav-item mr-auto">
                        <a
                          className="nav-link active"
                          id="pills-home-tab"
                          data-toggle="pill"
                          href="#pills-home"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                        >
                          Create Campaign
                        </a>
                      </li>
                      <li className="nav-item mr-auto">
                        <a
                          className="nav-link"
                          id="pills-profile-tab"
                          data-toggle="pill"
                          href="#pills-profile"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="false"
                        >
                          Lists
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="tab-content col-12 col-lg-12 p-0" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >
                      <div className="col-lg-12 p-0 page-title mt-4 mb-3 d-flex">
                        <div className="col-lg-6 p-0">
                          <div className="main-content col-lg-12 p-0">
                            <form className="form-horizontal col-lg-12 p-0" onSubmit={this.createCampaign}>
                              <div className="form-group col-lg-12 p-0">
                                <div className="col-lg-12 d-flex mb-3 p-0">
                                  <div className="col-lg-6">
                                    <div className="col-lg-12 mb-3 p-0">
                                      <label className="copy-font m-0 p-0">Campaign Name</label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        name="name"
                                        onChange={this.onChange}
                                        value={this.state.name}
                                        placeholder="Campaign Name"
                                      />
                                    </div>
                                    <div className="col-lg-12 mb-3 p-0">
                                      <label className="copy-font m-0 p-0">From Name</label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        name="fromname"
                                        onChange={this.onChange}
                                        value={this.state.fromname}
                                        placeholder="Sender Name"
                                      />
                                    </div>
                                  </div>

                                  <div className="col-lg-6">
                                    <label className="copy-font m-0 p-0">Body</label>
                                    <textarea
                                      className="form-control"
                                      name="body"
                                      onChange={this.onChange}
                                      value={this.state.body}
                                      rows="4"
                                      cols="80"
                                      type="text"
                                      placeholder="Message Body"
                                    />
                                  </div>
                                </div>

                                <div className="col-12 d-flex mb-3 p-0">
                                  <div className="col-lg-6">
                                    <label className="copy-font m-0 p-0">From Email</label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      placeholder="End Date"
                                      name="fromemail"
                                      onChange={this.onChange}
                                      value={this.state.fromemail}
                                    />
                                  </div>

                                  <div className="col-lg-6">
                                    <label className="copy-font m-0 p-0">Send to Lists</label>
                                    <select className="form-control" name="list" onChange={this.onChange}>
                                      <option>Select List</option>
                                      {lists.map((list) => (
                                        <option value={list.id}>{list.attributes.name}</option>
                                      ))}
                                    </select>
                                  </div>
                                </div>

                                <div className="col-12 d-flex mb-3 p-0">
                                  <div className="col-lg-6">
                                    <label className="copy-font m-0 p-0">Subject</label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      placeholder="Subject"
                                      name="subject"
                                      onChange={this.onChange}
                                      value={this.state.subject}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12 button-cat d-flex mt-5">
                                <button className="btn btn-green">Send Now</button>
                              </div>
                            </form>
                          </div>
                        </div>

                        <div className="col-6 p-0">
                          <div className="main-content col-12">
                            <h6 className="mb-1">
                              <strong>Campaigns</strong>
                            </h6>
                            <div className="card x-panel m-0 p-2">
                              <div className="table-responsive content">
                                <table className="table copy-font">
                                  <CampaignsTableHead />
                                  <CampaignsTableBody campaigns={campaigns} loading={loading} />
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="pills-profile"
                      role="tabpanel"
                      aria-labelledby="pills-profile-tab"
                    >
                      <div className="col-lg-12 text-right">
                        <button className="btn btn-green" data-target="#addList" data-toggle="modal">
                          {' '}
                          Create List
                        </button>
                        <CreateList />
                      </div>
                      <div className="col-lg-12 p-0 page-title mt-4 mb-3 d-flex">
                        <div className="col-lg-6 p-0">
                          <div className="main-content col-12">
                            <h6 className="mb-1">
                              <strong>Lists</strong>
                            </h6>
                            <div className="card x-panel m-0 p-2">
                              <div className="table-responsive content">
                                <table className="table copy-font">
                                  <ListsTableHead />
                                  <tbody>{listBody}</tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-6 p-0">
                          <SubscribersTable id={id} name={listName} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
       
        */}
        </div>
      </div>
    );
  }
}
export default AdministrationMailBlast;
