import axios from 'axios';
import toastr from 'toastr';
import { GET_DEPARTMENTS_URL } from '../../../services/api/shared/departments/DepartmentsActions';

export const getDepartmentsAdmin = async (dispatch) => {
  try {
    const res = await axios.get(`${GET_DEPARTMENTS_URL}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (res.data) {
      //   console.log(res.data);
      return res.data;
    }
  } catch (e) {
    toastr.info('Unable to get departments!');
  }
};
