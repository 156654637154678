/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import logo from '../../../../../assets/img/logo/Tizeti-Logo.png';
import '../../../../../styles/login.css';
import SignUp from '../container/SignUp';
import Login from '../container/Login';

const Auth = () => {
  return (
    <div className="main-wrapper">
      <div className="col-lg-12 carrier d-flex">
        <div className="col-lg-6 col-md-6 explain d-none d-sm-none d-md-block d-lg-block">
          <div className="intro">
            <h3 style={{ color: 'white' }}>Welcome To Tizeti OS </h3>
            <p className="copy-font">
              We built Tizeti OS with the aim of building a better experience when performing your daily tasks. We are
              currently rolling out more features that would further enhance your work.
              <br />
              <br />
              If you have any complaints or suggestions as to how this platform will be better, please send us an email
              to rd@tizeti.com
              <br />
              <br />- Research and Development Team
            </p>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="login col-sm-12 col-lg-12 col-md-12">
            <div className="tizeti-logo">
              <img src={logo} alt="" />
            </div>

            <div className="all">
              <input id="tab1" className="radio" type="radio" name="tabs" defaultChecked />
              <label htmlFor="tab1" className="signn copy-font">
                SignUp
              </label>

              <input id="tab2" className="radio" type="radio" name="tabs" />
              <label htmlFor="tab2" className="copy-font">
                Login
              </label>

              <SignUp />
              <Login />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
