export const GET_INSTALLATIONS = 'GET_INSTALLATIONS';
export const GET_BILLING_ESTATES = 'GET_BILLING_ESTATES';
export const GET_FAILED_INSTALLATIONS = 'GET_FAILED_INSTALLATIONS';
export const GET_ALL_INSTALLATIONS = 'GET_ALL_INSTALLATIONS';
export const GET_COMPLETED_INSTALLATIONS = 'GET_COMPLETED_INSTALLATIONS';
export const GET_INSTALLATION_COUNTER = 'GET_INSTALLATION_COUNTER';
export const GET_DIMENSION1 = 'GET_DIMENSION1';
export const GET_DIMENSION2 = 'GET_DIMENSION2';
export const GET_CALL_STATION = 'GET_CALL_STATION';
export const GET_ALL_CALL_STATIONS = 'GET_ALL_CALL_STATIONS';
export const CHECK_MAC_ID = 'CHECK_MAC_ID';
export const GET_BASESTATIONS = 'GET_BASESTATIONS';
export const GET_TARGETS = 'GET_TARGETS';
export const GET_DAILY_INSTALLED = 'GET_DAILY_INSTALLED';
export const GET_MONTHLY_INSTALLED = 'GET_MONTHLY_INSTALLED';
export const GET_MONTHLY_INSTALL_BY_STATUS = 'GET_MONTHLY_INSTALL_BY_STATUS';
export const GET_SALES_TYPES = 'GET_SALES_TYPES';
