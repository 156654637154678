/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-nested-ternary */
import React, { useContext, useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import helpers from '../../../../utils/installations/helpers';
import { OverviewContext } from '../../../../context/shared/installations/overview/OverviewContext';
import useInstallationZones from '../../shared/installations/zones/hooks/useInstallationZones';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../utils/loader/loadingHelper';
import styles from './NewDesign.module.css';
import NewDesignInstallationSearch from './NewDesignInstallationSearch';

const NewDesignInstallationBody = () => {
  const { loading, installations, pages, searchInstallations, handlePageClick } = useContext(OverviewContext);
  const { zones } = useInstallationZones();
  const [allInstallations, setAllInstallations] = useState([]);
  const [clusterState, setClusterState] = useState('');
  const [page] = useState(1);
  const [query, setQuery] = useState('');

  useEffect(() => {
    searchInstallations(page);
  }, []);

  // console.log(installations);
  const [allInstallationData, setAllInstallationData] = useState([]);
  useEffect(() => {
    if (installations && installations.length)
      return setAllInstallations(
        installations.map((cust) => {
          const { user, payment_reference, status } = cust;
          const {
            id,
            state,
            latitude,
            longitude,
            sub_zone = {},
            nearest_basestation,
            distance_calculation,
            installation_comment,
            installationUploads,
            created_at,
            date,
            team,
            team_id,
            device_type,
            zone,
            EmployeeAuth,
            EmployeeAuth: { employees } = {},
          } = cust.installations && cust.installations.length ? cust.installations[0] : [];

          // console.log(installationUploads);
          return {
            installationId: cust.installations ? id || null : cust.id || null,
            select: false,
            userId: user ? user.id : cust.id,
            id: user ? cust.id : cust.installations && cust.installations.length ? cust.installations[0].id : null,
            firstName: user ? user.firstName : cust.firstName,
            lastName: user ? user.lastName : cust.lastName,
            email: user ? user.email : cust.email,
            phoneNumber: user ? user.phoneNumber : cust.phoneNumber,
            installationUploads: cust.installations ? installationUploads : cust.installationUploads,
            payment_reference: user
              ? payment_reference
              : cust.installations && cust.installations.length
              ? cust.installations[0].payment_reference
              : 'N/A',
            address: cust.address,
            userAddress: user ? user.address : cust.address,
            lat: cust.installations ? latitude || 'N/A' : cust.latitude || 'N/A',
            lng: cust.installations ? longitude || 'N/A' : cust.longitude || 'N/A',
            sub_zone: cust.installations ? sub_zone.name || 'N/A' : cust.sub_zone.name || 'no sub zone',
            state: cust.installations ? state || 'N/A' : cust.state || 'N/A',
            status: user
              ? status
              : cust.installations && cust.installations.length
              ? cust.installations[0].status
              : 'N/A',
            assignedEmployees: cust.employee,
            nearestBasestation: cust.installations ? nearest_basestation || 'N/A' : cust.nearest_basestation || 'N/A',
            distance: cust.installations ? distance_calculation || 'N/A' : cust.distance_calculation,
            installationComment: cust.installations ? installation_comment || 'N/A' : cust.installation_comment,
            zoneName:
              cust.installations && cust.installations.length ? zone.name || 'N/A' : cust.zone ? cust.zone.name : 'N/A',
            createdDate: cust.installations ? created_at || 'N/A' : cust.created_at,
            installDate: cust.installations ? date || 'N/A' : cust.date,
            team: cust.installations ? team || 'N/A' : cust.team,
            teamId: cust.installations ? team_id || 'N/A' : cust.team_id,
            deviceType: cust.installations ? device_type || 'N/A' : cust.device_type,
            salesAgent:
              cust.installations && cust.installations.length
                ? EmployeeAuth.employees.length
                  ? `${employees[0].firstName} ${employees[0].lastName}`
                  : 'N/A'
                : cust.EmployeeAuth && cust.EmployeeAuth.employees.length
                ? `${cust.EmployeeAuth.employees[0].firstName} ${cust.EmployeeAuth.employees[0].lastName}`
                : 'N/A',
          };
        }),
      );
  }, [installations]);
  useEffect(() => {
    if (installations && installations.length)
      return setAllInstallationData(
        installations.map((cust) => {
          const { user, payment_reference, status } = cust;
          const {
            id,
            state,
            latitude,
            longitude,
            sub_zone = {},
            nearest_basestation,
            distance_calculation,
            installation_comment,
            installationUploads,
            created_at,
            date,
            team,
            team_id,
            device_type,
            zone,
            EmployeeAuth,
            EmployeeAuth: { employees } = {},
          } = cust.installations && cust.installations.length ? cust.installations[0] : [];

          return {
            installationId: cust.installations ? id || null : cust.id || null,
            select: false,
            userId: user ? user.id : cust.id,
            id: user ? cust.id : cust.installations && cust.installations.length ? cust.installations[0].id : null,
            firstName: user ? user.firstName : cust.firstName,
            lastName: user ? user.lastName : cust.lastName,
            email: user ? user.email : cust.email,
            phoneNumber: user ? user.phoneNumber : cust.phoneNumber,
            installationUploads: cust.installations ? installationUploads[0] : cust.installationUploads,

            payment_reference: user
              ? payment_reference
              : cust.installations && cust.installations.length
              ? cust.installations[0].payment_reference
              : 'N/A',
            address: cust.address,
            userAddress: user ? user.address : cust.address,
            lat: cust.installations ? latitude || 'N/A' : cust.latitude || 'N/A',
            lng: cust.installations ? longitude || 'N/A' : cust.longitude || 'N/A',
            sub_zone: cust.installations ? sub_zone.name || 'N/A' : cust.sub_zone.name || 'no sub zone',
            state: cust.installations ? state || 'N/A' : cust.state || 'N/A',
            status: user
              ? status
              : cust.installations && cust.installations.length
              ? cust.installations[0].status
              : 'N/A',
            assignedEmployees: cust.employee,
            nearestBasestation: cust.installations ? nearest_basestation || 'N/A' : cust.nearest_basestation || 'N/A',
            distance: cust.installations ? distance_calculation || 'N/A' : cust.distance_calculation,
            installationComment: cust.installations ? installation_comment || 'N/A' : cust.installation_comment,
            zoneName:
              cust.installations && cust.installations.length ? zone.name || 'N/A' : cust.zone ? cust.zone.name : 'N/A',
            createdDate: cust.installations ? created_at || 'N/A' : cust.created_at,
            installDate: cust.installations ? date || 'N/A' : cust.date,
            team: cust.installations ? team || 'N/A' : cust.team,
            teamId: cust.installations ? team_id || 'N/A' : cust.team_id,
            deviceType: cust.installations ? device_type || 'N/A' : cust.device_type,
            salesAgent:
              cust.installations && cust.installations.length
                ? EmployeeAuth.employees.length
                  ? `${employees[0].firstName} ${employees[0].lastName}`
                  : 'N/A'
                : cust.EmployeeAuth && cust.EmployeeAuth.employees.length
                ? `${cust.EmployeeAuth.employees[0].firstName} ${cust.EmployeeAuth.employees[0].lastName}`
                : 'N/A',
          };
        }),
      );
  }, [installations]);
  const colSpan = 18;

  const tableData = () =>
    allInstallations && allInstallations.length ? (
      allInstallations
        .filter((installation) => {
          if (query === '') {
            return installation;
          }
          if (
            installation.firstName.toLowerCase().includes(query.toLowerCase()) ||
            installation.email.toLowerCase().includes(query.toLowerCase()) ||
            installation.lastName.toLowerCase().includes(query.toLowerCase())
          ) {
            return installation;
          }
        })
        .map((installation, i) => {
          // console.log(installation);
          const { user, status, firstName, lastName, email, address, nearestBasestation } = installation;
          return (
            <tr>
              {/* {roleId > 1 && showContentForDepartment(departmentId, showInputs(installation))} */}
              <td data-label="#"> {i + 1}</td>

              <td data-label="Name">
                {' '}
                <Link
                  style={{ cursor: 'pointer', textDecoration: 'none', color: 'black' }}
                  to={{
                    pathname: `/actions/${installation.id}`,
                    state: { detail: installation },
                  }}
                >
                  {user ? `${`${user.firstName} ${user.lastName}`}` : firstName ? `${firstName} ${lastName}` : 'N/A'}
                </Link>
              </td>

              <td data-label="Email">{user ? user.email : email || 'N/A'}</td>
              <td data-label="Nearest Basestation" className="text-uppercase">
                {nearestBasestation}
              </td>

              <td data-label="User Address">{user ? user.address : address || 'N/A'}</td>

              <td data-label="Status">{helpers.installationStatusLabels(status)}</td>
            </tr>
          );
        })
    ) : (
      <NoItemRow colSpan={colSpan} text="No Installations" />
    );
  //   const displayData = () => {
  //     if (loading) return <TizetiGIFLogoTable colSpan={colSpan} />;
  //     return tableData();
  //   };
  return (
    <div className="container ">
      <div className={`${styles.search_bar} row`}>
        <div className="col-xs-4 ">
          {' '}
          <div className="input-box">
            <input
              type="text"
              placeholder="Search"
              className={`form-control ${styles.form_control}`}
              onChange={(event) => setQuery(event.target.value)}
            />
          </div>
        </div>

        <NewDesignInstallationSearch />
      </div>

      <table className={`table table-sm ${styles.new_table}`}>
        <thead className={`thead-light ${styles.new_thead}`}>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Email</th>
            <th>Nearest Basestation</th>
            <th>User address</th>
            <th>Status</th>
          </tr>
        </thead>

        <tbody>{loading ? <TizetiGIFLogoTable colSpan={colSpan} /> : tableData()}</tbody>
      </table>
      {pages > 1 ? (
        <ReactPaginate
          previousLabel="previous"
          nextLabel="next"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={pages}
          marginPagesDisplayed={3}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName="pagination"
          subContainerClassName="pages pagination"
          activeClassName="active"
        />
      ) : null}
    </div>
  );
};

export default NewDesignInstallationBody;
