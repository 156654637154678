/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import useMailBlast from '../mailblast/v2/hooks/useMailBlast';
import MailBlastForm from '../mailblast/v2/MailBlastForm';

const tabIndex = -1;
const SendEmail = ({ oneItem, onEditorStateChange, editorState }) => {
  const { subject, onSubjectChange } = useMailBlast();

  return (
    <div
      id="sendEmail"
      className="modal fade in mt-5"
      tabIndex={tabIndex}
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Send Email
            </h4>
            <button type="button" className="close custom-close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body">
            <div className="py-3">
              <MailBlastForm
                subject={subject}
                onSubjectChange={onSubjectChange}
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                type="single"
                data={oneItem}
              />

              {/* <form className="form-horizontal" onSubmit={onSubmit}> */}
              {/*  <div className="form-group"> */}
              {/*    <div className="col-12 mb-3"> */}
              {/*      <div className="col-12"> */}
              {/*        <CustomInput */}
              {/*          readOnly={false} */}
              {/*          classname="form-control shadow-sm borderRadius0" */}
              {/*          type="text" */}
              {/*          name={subject} */}
              {/*          onChange={(e: { target: { value: React.SetStateAction<string> } }) => */}
              {/*            setSubject(e.target.value) */}
              {/*          } */}
              {/*          value={subject} */}
              {/*          placeholder="Subject of Email" */}
              {/*        /> */}
              {/*      </div> */}
              {/*    </div> */}
              {/*    <div className="col-12 mb-3"> */}
              {/*      <div className="col-12 mb-3"> */}
              {/*        <div className="col-12"> */}
              {/*          <select */}
              {/*            className="form-control shadow-sm borderRadius0" */}
              {/*            name="country" */}
              {/*            onChange={onCountryChange} */}
              {/*          > */}
              {/*            <option value="">Select Country</option> */}
              {/*            <option value="Nigeria">Nigeria</option> */}
              {/*            <option value="Ghana">Ghana</option> */}
              {/*          </select> */}
              {/*        </div> */}
              {/*      </div> */}

              {/*      <div className="col-12"> */}
              {/*        <Editor */}
              {/*          editorState={editorState} */}
              {/*          wrapperClassName="demo-wrapper" */}
              {/*          editorClassName="demo-editor editor-textarea" */}
              {/*          onEditorStateChange={onEditorStateChange} */}
              {/*        /> */}
              {/*      </div> */}
              {/*    </div> */}
              {/*    <div className="col-12 mb-3 text-center"> */}
              {/*      {sendingEmail ? ( */}
              {/*        <CustomButton classname="btn btn-green" name="Sending..." disabled={sendingEmail} /> */}
              {/*      ) : ( */}
              {/*        <CustomButton classname="btn btn-green" name="Send Email" disabled={!submitForm()} /> */}
              {/*      )} */}
              {/*    </div> */}
              {/*  </div> */}
              {/* </form> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendEmail;
