import React from 'react';
import loader from '../../../../../../assets/img/icons/TizetiLoaderColored.gif';
import useFetch from '../../../../../../hooks/shared/custom/data/geniatech/useFetch';
import helpers from '../../../../../../utils/helpers';

const CustomersTable = () => {
  const { data, loading } = useFetch(`https://legacy.tizeti.com/geniatechapi/index.php`, {
    action: 'active_lte_customers',
  });
  if (loading)
    return (
      <tbody>
        <tr>
          <td>
            <img src={loader} alt="laoding gif" />
          </td>
        </tr>
      </tbody>
    );
  const customerList =
    data.active_lte_customers && data.active_lte_customers.length ? (
      data.active_lte_customers.map((customer, i) => (
        <tr>
          <td>{i + 1}</td>
          <td>{customer.customer_name}</td>
          <td>{customer.customer_email}</td>
          <td>{customer.customer_mobile}</td>
          <td>{customer.expired_date}</td>
          <td>{helpers.formatDateAndTime(customer.installation_date)}</td>
          <td>{customer.username}</td>
        </tr>
      ))
    ) : (
      <tr>
        <td>
          <p>No customers!</p>
        </td>
      </tr>
    );

  return <tbody>{customerList}</tbody>;
};
export default CustomersTable;
