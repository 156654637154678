import { useState, useEffect, useReducer } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { stringify } from 'qs';
import { LOADING_STARTS, LOADING_STOPS } from '../../../../../../constants/types/shared/LoadingTypes';
import { GET_DATA } from '../../../../../../constants/types/shared/CustomTypes';
import lteInstallationReducer from '../reducer/lteInstallationReducer';

const useLTEInstallation = (url) => {
  const [{ data, searchType, loading, count }, dispatch] = useReducer(lteInstallationReducer, {
    data: [],
    fetched: [],
    loading: false,
    pages: 0,
    searchStatus: false,
    searchType: 'monthly',
    count: [],
  });

  const [perPage] = useState(25);
  // eslint-disable-next-line consistent-return
  const searchLteInstallation = async (params) => {
    dispatch({ type: 'SEARCH_TYPE', payload: params });
    dispatch({ type: LOADING_STARTS });
    try {
      const response = await axios.post(`${url}`, stringify({ action: 'tizeti_asset_installed_lte_users' }));
      if (response.data.status) {
        switch (params) {
          case 'daily':
            dispatch({ type: GET_DATA, payload: response.data.daily_installed_users });
            break;
          case 'weekly':
            dispatch({ type: GET_DATA, payload: response.data.weekly_installed_users });
            break;

          case 'monthly':
            dispatch({ type: GET_DATA, payload: response.data.monthly_installed_users });
            break;

          default:
            return null;
        }
      } else {
        toast.error('Unable to get LTE installed  customers details. Please try again later!');
      }
    } catch (error) {
      toast.error('Something went wrong. Please try again later!');
    }
  };

  useEffect(() => {
    async function getLTEinstallations() {
      dispatch({ type: LOADING_STARTS });

      try {
        return await axios.post(`${url}`, stringify({ action: 'tizeti_asset_installed_lte_users' }));
      } catch (error) {
        return error;
      }
    }
    getLTEinstallations()
      .then((response) => {
        if (response.data.status) {
          dispatch({ type: GET_DATA, payload: response.data.monthly_installed_users });
          dispatch({ type: 'LTE_INSTALLATION_COUNT', payload: response.data.item_counts });
        } else {
          dispatch({ type: LOADING_STOPS });
        }
      })
      .catch(() => {
        dispatch({ type: LOADING_STOPS });

        toast.error('Something went wrong. Please try again later!');
      });
  }, [perPage, url]);
  return {
    loading,
    searchLteInstallation,
    searchType,
    data,
    count,
  };
};
export default useLTEInstallation;
