import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class AdminSideBar extends Component {
  render() {
    return (
      <aside className="left-sidebar col-lg-2">
        <div className="scroll-sidebar">
          <nav className="sidebar-nav">
            <ul>
              <li>
                <Link to="/hotspot-super-admin" target="_self">
                  <span>
                    <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971013/Group-3.png" alt="img" />
                  </span>
                  Overview
                </Link>
              </li>

              <li>
                {' '}
                <Link to="/hotspot-super-admin/retailers" target="_self">
                  <span>
                    <img
                      src="https://res.cloudinary.com/teewhy/image/upload/v1548859608/retailers_white.png"
                      alt="img"
                    />
                  </span>
                  Retailers
                </Link>
              </li>

              <li>
                {' '}
                <a href="http://www.tizeti.com/dist/index.html#/admin/overview" target="_self">
                  <span>
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1548859607/fund_wallet.png" alt="img" />
                  </span>
                  Hotspot Dashboard
                </a>
              </li>

              <li>
                {' '}
                <Link to="/hotspot-super-admin/fund-wallet" target="_self">
                  <span>
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1548859607/fund_wallet.png" alt="img" />
                  </span>
                  Fund Wallet
                </Link>
              </li>

              <li>
                {' '}
                <Link to="/hotspot-super-admin/transaction" target="_self">
                  <span>
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1548859608/transactions.png" alt="img" />
                  </span>
                  Transactions
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    );
  }
}

export default AdminSideBar;
