import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DowntimeTables from './DowntimeTable';
import NocNavBar from '../../../../layouts/noc/NocNavBar';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import { toast } from 'react-toastify';

export default function DowntimeLogger() {
  const { user, profile } = useUser();

  const todayDate = new Date().toISOString().slice(0, 10);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [changeDate, setChangeDate] = React.useState(todayDate);
  const disabledStateRef = React.useRef(false);

  const disableContinueCheck = (changeDate, todayDate) => {
    if (changeDate > todayDate) {
      toast.error('selected date cannot be greater than today');
      return (disabledStateRef.current = true);
    }
    return (disabledStateRef.current = false);
  };

  return (
    <div>
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <NocNavBar currentPage="Zone Downtime Logger" position={profile.firstName} role_id={user.role_id} />

          <Box sx={{ mx: 2, mb: 3, mt: 17 }} className="container">
            {currentPage === 1 && (
              <Box className="page-1">
                <Box
                  sx={{
                    mt: 5,
                  }}
                >
                  <Typography sx={{ mb: 2 }}>Select Date:</Typography>
                  <input
                    type="date"
                    id="select-date"
                    name="trip-start"
                    value={changeDate}
                    min="2020-01-01"
                    style={{ padding: '1rem 4rem 1rem 1rem' }}
                    onChange={(e) => {
                      disableContinueCheck(e.target.value, todayDate);
                      setChangeDate(e.target.value);
                    }}
                  />
                </Box>

                <Button
                  variant="contained"
                  sx={{ mt: 8, width: 172, height: 36, background: '#30BFAB', textTransform: 'capitalize' }}
                  onClick={() => {
                    setCurrentPage(2);
                  }}
                  disabled={disabledStateRef.current}
                >
                  Continue
                </Button>
              </Box>
            )}

            {currentPage === 2 && (
              <Box className="page-2" maxWidth={'80%'} sx={{ transition: 'all 0.3s ease-in-out' }}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setCurrentPage(1);
                  }}
                >
                  {' '}
                  ⟵ Go Back
                </Button>
                <DowntimeTables todayDate={changeDate} />
              </Box>
            )}
          </Box>
        </main>
      </div>
    </div>
  );
}
