/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useContext } from 'react';
import CustomInput from '../../../../shared/forms/CustomInput';
import Label from '../../../../shared/forms/Label';
import CustomButton from '../../../../shared/forms/CustomButton';
import useSubBasestation from './hooks/useSubBasestation';
import useFetch from '../../../../../../hooks/shared/custom/data/useFetch';
import { GET_BASESTATIONS_URL } from '../../../../../../services/api/shared/BasestationsUrl';
import AppContext from '../../../../../../context/shared/AppContext';

const AddSubBasestation = () => {
  const { loadingForm, onChange, formValue, onSubmit } = useContext(AppContext);
  const { data, loading } = useFetch(`${GET_BASESTATIONS_URL}`);
  const {
    name,
    latitude,
    longitude,
    address,
    installer,
    date_of_commissioning,
    setup_amount,
    no_of_subscribers,
    dimension,
    base_station_id,
  } = formValue;
  const submitForm = () => {
    return (
      base_station_id.length > 0 &&
      dimension.length > 0 &&
      no_of_subscribers.length > 0 &&
      setup_amount.length > 0 &&
      date_of_commissioning.length > 0 &&
      installer.length > 0 &&
      address.length > 0 &&
      longitude.length > 0 &&
      latitude.length > 0 &&
      name.length > 0
    );
  };
  return (
    <div id="add-access-point" className="modal fade mt-5" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header shadow">
            <h4 className="modal-title update_asset">Basestation</h4>
            <button type="button" className="btn btn-outline-info btn-circle btn-close" data-dismiss="modal">
              x
            </button>
          </div>
          <div className="modal-body detail mb-4 p-0">
            <form
              className="col-md-12 p-0"
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              <div className="d-flex">
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="Base station" htmlfor="customerName" />
                  <select name="base_station_id" onChange={onChange}>
                    <option value="">Choose Basestation</option>
                    {!loading &&
                      data.map((base) => {
                        return <option value={base.id}>{base.name}</option>;
                      })}
                  </select>
                </div>
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="Dimension" htmlfor="customerEmail" />
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="dimension"
                    value={dimension}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="Enter dimension"
                    type="text"
                    id="customerEmail"
                  />
                </div>
              </div>

              <div className="d-flex">
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="Name" htmlfor="Name" />
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="name"
                    value={name}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="e.g Rumuogba2"
                    type="text"
                    id="router_id"
                  />
                </div>
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="Latitude" htmlfor="" />

                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="latitude"
                    value={latitude}
                    onChange={onChange}
                    readOnly={false}
                    placeholder=""
                    type="text"
                    id="radioMACID"
                  />
                </div>
              </div>

              <div className="d-flex">
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="Longitude" htmlfor="customerName" />
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="longitude"
                    value={longitude}
                    onChange={onChange}
                    readOnly={false}
                    placeholder=""
                    type="text"
                    id="customerName"
                  />
                </div>
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="address" htmlfor="customerEmail" />

                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="address"
                    value={address}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="Porthacourt"
                    type="text"
                    id="customerEmail"
                  />
                </div>
              </div>

              <div className="d-flex">
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="installer" htmlfor="customerName" />
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="installer"
                    value={installer}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="e.g Porthacourt team"
                    type="text"
                    id="customerName"
                  />
                </div>
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="No of Subscribers" htmlfor="customerEmail" />
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="no_of_subscribers"
                    value={no_of_subscribers}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="0"
                    type="text"
                    id="customerEmail"
                  />
                </div>
              </div>

              <div className="d-flex">
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="Date Of Commission" htmlfor="customerName" />
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="date_of_commissioning"
                    value={date_of_commissioning}
                    onChange={onChange}
                    readOnly={false}
                    placeholder=""
                    type="date"
                    id="customerName"
                  />
                </div>
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="Set Up Amount" htmlfor="customerEmail" />
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="setup_amount"
                    value={setup_amount}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="600000000"
                    type="text"
                    id="customerEmail"
                  />
                </div>
              </div>

              <div className="text-center mt-3">
                {loadingForm ? (
                  <CustomButton classname="btn btn-green px-3" name="Adding" disabled={loadingForm} />
                ) : (
                  <CustomButton classname="btn btn-green px-3" name="Add" disabled={!submitForm()} />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSubBasestation;
