import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import toastr from 'toastr';
import Logout from '../../../../shared/auth/Logout';
import Requests from '../../../../shared/requests/admin/Requests';
import AccountSideBar from '../../../../../layouts/accounts/sidebar/AccountsSideBarClass';
import { LOGGED_IN_USER_URL } from '../../../../../../services/api/auth/AuthUrl';
import { ACCOUNTS, SUPER_ADMIN } from '../../../../../../utils/departments/helpers';
import AccountsNavBar from '../../../../../layouts/accounts/AccountsNavBar';

const AccountsRequests = ({ history }) => {
  const checkDepartment = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.department_id !== `${ACCOUNTS}` && response.data.department_id !== `${SUPER_ADMIN}`) {
        sessionStorage.clear();
        sessionStorage.setItem('token', '');
        sessionStorage.setItem('email', '');
        sessionStorage.setItem('departmentId', '');
        sessionStorage.setItem('roleId', '');
        history.push('/');

        toastr.info('You are not allowed to view this page');
      }
    } catch (e) {
      // console.log(e.response);
    }
  };

  useEffect(() => {
    checkDepartment();

    return () => {
      checkDepartment();
    };
  });

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <AccountsNavBar currentPage="Admin Request" position="" /> 
        <Requests />
      </main>
    </div>
  );
};
AccountsRequests.propTypes = {
  // eslint-disable-next-line react/require-default-props
  history: PropTypes.shape(),
};
export default AccountsRequests;
