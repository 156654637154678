/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import VpSalesNavBar from '../../../layouts/vp-sales/VpSalesNavBar';
import OverviewBody from '../super-admin/overview/overview/OverviewBody';

const VPSalesOverview = () => {
    const firstName = sessionStorage.getItem('firstName');


  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <VpSalesNavBar currentPage="Overview" position={firstName} />
        <OverviewBody />
      </main>
    </div>
  );
};

export default VPSalesOverview;
