/* eslint-disable react/require-default-props */
import React from 'react';
import { Link } from 'react-router-dom';

const AssetTeamSideBarItem = ({ url, name, image }) => {
  return (
    <li className="s-sidebar__nav-link">
      <Link to={url} target="_self">
        <span className="mr-2">
          <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923169/idontknow.fw.png" alt="img" />
        </span>
        {name}
      </Link>
    </li>
  );
};

export default AssetTeamSideBarItem;
