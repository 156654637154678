import { useReducer } from 'react';
import qs from 'qs';
import axios from 'axios';
import * as load from '../../constants/types/shared/LoadingTypes';
import { GET_USAGE } from '../../constants/types/partnership/PartnershipTypes';
import { usageHistoryReducer } from '../../reducers/partnership/usage-history/usageHistoryReducer';

export const useUsageHistory = () => {
  const [{ usage, isLoading }, dispatch] = useReducer(usageHistoryReducer, {
    isLoading: false,
  });

  const getUsAidUsage = async (from, to) => {
    const data = qs.stringify({
      startDate: from,
      endDate: to,
    });
    dispatch({
      type: load.LOADING_STARTS,
    });

    try {
      const response = await axios.post('https://api.tizeti.com/api/v1/usaidMetrics/getUsage', data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.status === 'success') {
        dispatch({
          type: GET_USAGE,
          payload: response.data.flattenedData,
        });
      } else {
        dispatch({
          type: load.LOADING_STOPS,
        });
      }
    } catch (e) {
      dispatch({
        type: load.LOADING_STOPS,
      });
    }
  };

  return { usage, isLoading, getUsAidUsage };
};
