/* eslint-disable no-empty */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-case-declarations */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import { accounts, admin } from '../../../../../utils/config';
import 'react-toastify/dist/ReactToastify.css';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import { getUser } from '../../../../../actions/shared/user/UserActions';
import {
  getUserDetails,
  searchByName,
  searchByPhoneOrMac,
  searchForUserByEmail,
} from '../../../../../actions/shared/customers/CustomersDetailsActions';
import { getPaymentHistory } from '../../../../../actions/shared/customers/payments/PaymentsActions';
import { getLoggedInUser } from '../../../../../actions/user/UserActions';
import AccountsGeniatechTable from './AccountsGeniatechTable';
import AccountsNavBar from '../../../../layouts/accounts/AccountsNavBar';

class AccountsGeniatechCustomers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.checkDepartment();
    this.props.getLoggedInUser();
    this.props.getUser();
  }

  componentWillReceiveProps() {
    this.checkDepartment();
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  onSubmit = async (e) => {
    e.preventDefault();

    const { keyword, search } = this.state;

    switch (keyword) {
      case 'email':
        const data = {
          email: search,
        };

        this.props.searchForUserByEmail(data);
        break;

      case 'phone':
        const phoneSearch = {
          phoneNumber: search,
          macAddress: '12',
        };

        this.props.searchByPhoneOrMac(phoneSearch);
        break;

      case 'name':
        const nameSearch = {
          name: search,
        };

        this.props.searchByName(nameSearch);
        break;

      case 'macid':
        const macid = {
          macAddress: search,
          phoneNumber: '090',
        };

        this.props.searchByPhoneOrMac(macid);
        break;

      default:
        return null;
    }
  };

  checkDepartment = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.department_id !== `${accounts}` && response.data.department_id !== `${admin}`) {
        this.props.history.push('/');
        sessionStorage.setItem('token', '');
        sessionStorage.clear();
        toastr.info('You are not allowed to view this page');
      }
    } catch (e) { }
  };

  getUserDetails = (customer) => {
    const data = {
      macid: customer,
    };
    this.props.getUserDetails(data);
  };

  getPayment = (user) => {
    const data = {
      macid: user.username,
    };
    this.props.getPaymentHistory(data);
  };

  render() {
    const { name, role } = this.props;
    const { firstName } = name;
    const { role_id } = role;
    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <AccountsNavBar currentPage="Customers" roleID={role_id} position={firstName} />
          <AccountsGeniatechTable />
        </main>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  name: state.user.user,
  user: state.customerDetail.customer,
  load: state.customerDetail.load,
  loading: state.customerDetail.loading,
  detail: state.customerDetail.details,
  session: state.customerDetail.session,
  role: state.auth.auth,
});

export default connect(
  mapStateToProps,
  {
    getUser,
    searchForUserByEmail,
    getPaymentHistory,
    getUserDetails,
    searchByPhoneOrMac,
    searchByName,
    getLoggedInUser,
  },
)(AccountsGeniatechCustomers);
