import React from 'react';
import PropTypes from 'prop-types';
import { SALES } from '../../../../../utils/config';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import SalesNavBar from '../../../../layouts/sales/SalesNavBar';
import { SUPER_ADMIN, VICE_PRESIDENT_SALES } from '../../../../../utils/departments/helpers';
import ExistingCustomerInvoiceBody from './ExistingCustomerInvoiceBody';
import useCheckFiveDepartments from '../../../../../hooks/shared/custom/check-department/useCheckFiveDepartments';

function SalesExistingInvoice({ history }) {
  useCheckFiveDepartments(`${SALES}`, `${SUPER_ADMIN}`, `${VICE_PRESIDENT_SALES}`, history);
  const { profile } = useUser();
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <SalesNavBar currentPage="Existing Customer Invoice" position={profile.firstName} />
        <ExistingCustomerInvoiceBody />
      </main>
    </div>
  );
}

SalesExistingInvoice.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default SalesExistingInvoice;
