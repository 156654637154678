/* eslint-disable no-plusplus */
import React, { useCallback, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { ToastContainer } from 'react-toastify';
import moment from 'moment';
import loader from '../../../../../../assets/img/icons/TizetiLoaderColored.gif';
import { useExpiredZones } from '../../../../../../hooks/asset-team/zones/expired-zones/useExpiredZones';
import ExpiredZonesTable from './CustomerSupportExpiredZonesTable';

const CustomerSupportExpiredGraph = () => {
  const { items, total, time, isLoading } = useExpiredZones();
  const [data, setData] = useState({});
  const [count] = useState([]);
  const [zone] = useState([]);

  const fetchData = useCallback(async () => {
    if (items.length > 0) {
      items.sort((a, b) => a.expired_count - b.expired_count);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < items.length; i++) {
        count.push(items[i].expired_count);
        zone.push(items[i].zone);
      }
      setData({
        labels: zone,
        datasets: [
          {
            label: 'count',
            data: count,
            backgroundColor: 'rgb(115, 255, 136)',
          },
        ],
      });
    }
  }, [count, items, zone]);

  const fetchTime = () => {
    return moment(time)
      .add(1, 'hours')
      .format('DD MMM YYYY hh:mm A');
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col-12 my-5">
          {time ? (
            <p className="h5" style={{ fontFamily: 'Rubik' }}>
              <span>UPDATED AT: </span> {fetchTime()} <br />
              <span style={{ textDecoration: 'none' }}>
                TOTAL EXPIRED CUSTOMERS: <span className="badge badge-success mx-2">{total}</span>
              </span>
            </p>
          ) : (
            'Fetching Time...'
          )}
          <Bar
            data={data}
            options={{
              maintainAspectRatio: true,
              responsive: true,
              scales: {
                yAxes: [
                  {
                    scaleLabel: { display: true, labelString: 'EXPIRED', fontStyle: 'bold', fontSize: 17 },
                  },
                ],
                xAxes: [
                  {
                    ticks: { fontStyle: 'bold' },
                    scaleLabel: { display: true, labelString: 'ZONE NAME', fontStyle: 'bold', fontSize: 17 },
                  },
                ],
              },
            }}
          />
        </div>
        <div className="card x-panel p-0">
          <div className="table-responsive content col-12 px-0">
            <table className="table table-striped">
              <thead>
                <tr className="">
                  <th>S/N</th>
                  <th>Zone</th>
                  <th>Expired Count</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan="10" className="text-center">
                      <img src={loader} alt="loading gif" />
                    </td>
                  </tr>
                ) : (
                  <ExpiredZonesTable items={items} />
                )}
              </tbody>
            </table>
          </div>
        </div>
        <ToastContainer />
        <div className="ml-3">
          <button type="button" className="btn btn-dark">
            TOTAL:
            <span style={{ fontSize: '15px' }} className="badge badge-success mx-2">
              {total}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomerSupportExpiredGraph;
