export const START_FETCHING_CUSTOMER_DETAILS = 'START_FETCHING_CUSTOMER_DETAILS';
export const STOP_FETCHING_CUSTOMER_DETAILS = 'STOP_FETCHING_CUSTOMER_DETAILS';
export const FETCHED_CUSTOMER_DETAILS = 'FETCHED_CUSTOMER_DETAILS';
export const START_FETCHING_CUSTOMER = 'START_FETCHING_CUSTOMER';
export const START_EXTENDING_CUSTOMER = 'START_EXTENDING_CUSTOMER';
export const STOP_EXTENDING_CUSTOMER = 'STOP_EXTENDING_CUSTOMER';
export const STOP_FETCHING_CUSTOMER = 'STOP_FETCHING_CUSTOMER';
export const FETCHED_CUSTOMER = 'FETCHED_CUSTOMER';
export const START_GETTING_PASSWORD = 'START_GETTING_PASSWORD';
export const GET_PASSWORD = 'GET_PASSWORD';
export const STOP_GETTING_PASSWORD = 'STOP_GETTING_PASSWORD';
export const DELETING_ACCOUNT = 'DELETING_ACCOUNT';
export const DELETED_ACCOUNT = 'DELETED_ACCOUNT';
export const STOP_DELETING_ACCOUNT = 'STOP_DELETING_ACCOUNT';
export const GETTING_INSTALLATION_DATE = 'GETTING_INSTALLATION_DATE';
export const INSTALLATION_DATE = 'INSTALLATION_DATE';
