import React from 'react';
import PropTypes from 'prop-types';
import DepartmentsPurchaseOrderBody from './DepartmentsPurchaseOrderBody';
import useCheckTwoDepartments from '../../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';
import { ACCOUNTS, SUPER_ADMIN } from '../../../../../../utils/departments/helpers';
import { useUser } from '../../../../../../hooks/shared/user/useUser';
import AccountsNavBar from '../../../../../layouts/accounts/AccountsNavBar';

const DepartmentsPurchaseOrder = ({ history }) => {
  useCheckTwoDepartments(`${ACCOUNTS}`, `${SUPER_ADMIN}`, history);
  const {
    profile: { firstName },
  } = useUser();

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <span className="d-none d-md-block">
          <AccountsNavBar currentPage="InterDepartment Purchase Order" position={firstName} />
        </span>
        <span className="d-md-none d-block">
          <AccountsNavBar currentPage="InterDepartment PO" position={firstName} />
        </span>
        <DepartmentsPurchaseOrderBody />
      </main>
    </div>
  );
};

DepartmentsPurchaseOrder.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default DepartmentsPurchaseOrder;
