import React from 'react';
import { useExpiredZones } from '../../../../../../hooks/asset-team/zones/expired-zones/useExpiredZones';
import { AssetTeamHelperExpired, FormatTime } from '../../../../../../utils/asset-team/AssetTeamHelper';
import * as helpers from '../../../../../../utils/asset-team/AssetTeamHelperBody';
import ExpiredZonesTable from './ExpiredZonesTable';

const ExpiredGraph = () => {
  const { items, total, time, isLoading } = useExpiredZones();
  const { data } = AssetTeamHelperExpired(items, 'zone');
  const { fetchTime } = FormatTime(time);

  const headers = [{ label: 'Zone', key: 'zone' }, { label: 'Count', key: 'expired_count' }];

  return (
    <div className="container">
      <div className="row my-5">
        <div className="col-12 pt-5">{helpers.AssetTeamHeader(items, time, total, fetchTime)}</div>
        <div className="col-12 mb-5 pr-5">{helpers.AssetTeamHelperGraph(data, time, total, fetchTime)}</div>
        <div className="col-12 pr-5">
          {helpers.AssetTeamGeneralButton(items, headers, 'Expired Customers Per Zone.csv')}
        </div>
        {helpers.AssetTeamHelperTable(isLoading, false, <ExpiredZonesTable items={items} />)}
        <div className="ml-3">{helpers.AssetTeamBottomTotal(total)}</div>
      </div>
    </div>
  );
};

export default ExpiredGraph;
