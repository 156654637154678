import React from 'react';
import { Box } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

export default function VerticalBarChart({
  getZones,
  state,
  sumOfActiveAndInactiveUsers,
  getAllActiveUsersCountValue,
  getAllInActiveUsersCountValue,
}) {
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    if (
      getZones.length > 0 &&
      sumOfActiveAndInactiveUsers.length &&
      getAllActiveUsersCountValue.length > 0 &&
      getAllInActiveUsersCountValue.length > 0
    ) {
      const refinedData = getZones.map((zone, index) => ({
        name: zone.name,
        'Active & Expired / Inactive Users': sumOfActiveAndInactiveUsers[index] && sumOfActiveAndInactiveUsers[index],
        Active: getAllActiveUsersCountValue[index] && getAllActiveUsersCountValue[index],
        'Expired / Inactive Users': getAllInActiveUsersCountValue[index] && getAllInActiveUsersCountValue[index],
      }));

      if (refinedData.length > 0) {
        const filterNonZeroValues = refinedData.filter((val) => val.Active > 0);
        setData(filterNonZeroValues);
      }
    }
  }, [sumOfActiveAndInactiveUsers.length]);

  return (
    <Box sx={{ mr: 5 }}>
      <Box sx={{ width: { xs: '90vw', md: 1000 }, overflowX: 'auto' }}>
        <Box sx={{ width: '100%', overflowX: 'scroll' }}>
          <BarChart
            width={4200}
            height={550}
            data={data}
            margin={{
              top: 5,
              right: 10,
              left: 0,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            {state.activeInactive && <Bar dataKey="Active & Expired / Inactive Users" fill="#413ea0" />}
            {state.active && <Bar dataKey="Active" fill="#84d8d4" />}
            {state.inactive && <Bar dataKey="Expired / Inactive Users" fill="#ff7300" />}
          </BarChart>
        </Box>
      </Box>
    </Box>
  );
}
