import React from 'react';
import AddRetailerComment from './AddRetailerComment';

const UpdateXWFRetailerComment = ({ retailerID, oneComment }) => {
  return (
    <div id="updateRetailerComment" className="modal fade mt-5" data-backdrop="static">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header shadow">
            <h4 className="modal-title">Update Comment</h4>
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body mb-4 p-0">
            <AddRetailerComment retailerID={retailerID} oneComment={oneComment} operation="update" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateXWFRetailerComment;
