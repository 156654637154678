/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import { useRelocation } from '../../../../hooks/sales/relocation/useRelocation';
import usePost from '../../../../hooks/shared/custom/data/geniatech/usePost';
import {
  installationCounterHeaders,
  filename,
  headers,
  failedHeaders,
  relocationHeaders,
} from '../../../../utils/field-support/installations/helpers';
import InstallationsSummary from '../../departments/field-support/installations-summary/InstallationsSummary';
import CustomCsvDownload from '../CustomCsvDownload';
import InstallationSummaryFailedTable from './InstallationSummaryFailedTable';
import InstallationSummaryRelocationTable from './InstallationSummaryRelocationTable';
import InstallationSummaryTable from './InstallationSummaryTable';
import InstallationTable from './InstallationTable';
import SearchInstallationSummary from './SearchInstallationSummary';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../utils/loader/loadingHelper';

const CountRow = (location, counter, key) => {
  return (
    <tr key={key}>
      <td>{location}</td>
      <td>{counter}</td>
    </tr>
  );
};

const InstallationCounterSummary = () => {
  const {
    lists,
    pages,
    count,
    isLoading,
    loads,
    rows,
    loader,
    completedInstalls,
    completedCount,
    installationCount,
    completedInstallPages,
    getRelocationRequest,
    searchUsingKeyword,
    searchfailedInstallations,
    searchCompletedInstallations,
    getInstallationCounter,
    items,
    searchInstallations,
  } = useRelocation();
  const [page, setPage] = useState(1);
  // console.log(items);

  const initialParams = {
    country: (countries === 'CIV' && 'cv') || (countries === 'GH' && 'gh') || (countries === 'NG' && 'ng'),
    // fromDate: `${new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10)}`,
    fromDate: `${new Date(new Date().getTime()).toISOString().slice(0, 10)}`,
    toDate: `${new Date(new Date().getTime()).toISOString().slice(0, 10)}`,
  };

  const [params, setParams] = useState(initialParams);

  const [date, setDate] = useState({ from: initialParams.fromDate, to: initialParams.toDate });
  const countries = sessionStorage.getItem('country');
  const completed = {
    status: 'completed',
    from: initialParams.fromDate,
    to: initialParams.toDate,
    zone_id: '',
    paginate: true,
    addZone: 'yes',
    country: (countries === 'CIV' && 'cv') || (countries === 'GH' && 'gh') || (countries === 'NG' && 'ng'),
  };

  const counter = {
    from: initialParams.fromDate,
    to: initialParams.toDate,
    status: 'completed',
    country:(countries === 'CIV' && 'cv') || (countries === 'GH' && 'gh') || (countries === 'NG' && 'ng'),
  };

  const reschedule = {
    status: '',
    from: initialParams.fromDate,
    to: initialParams.toDate,
    zone_id: '',
    paginate: true,
    addZone: 'yes',
    reschedule_status: true,
    country: (countries === 'CIV' && 'cv') || (countries === 'GH' && 'gh') || (countries === 'NG' && 'ng'),
  };

  const failed = {
    status: 'failed',
    from: initialParams.fromDate,
    to: initialParams.toDate,
    zone_id: '',
    paginate: true,
    addZone: 'yes',
    country: (countries === 'CIV' && 'cv') || (countries === 'GH' && 'gh') || (countries === 'NG' && 'ng'),
  };

  // const handleDate = (e) => {
  //   const { name, value } = e.target;
  //   setDate({ ...date, [name]: value });
  // };

  // const filterUsingKeyword = (e) => {
  //   e.preventDefault();
  //   searchUsingKeyword(page, date);
  // };

  useEffect(() => {
    // searchUsingKeyword(page, date);
    searchCompletedInstallations(page, completed);
    searchInstallations(reschedule);
    searchfailedInstallations(failed);
    getInstallationCounter(counter);
  }, []);

  const { country } = params;

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name) {
      setParams({ ...params, [name]: value });
    }
  };

  const counts = () => {
    return (
      <div className="mx-auto" style={{ width: '400px' }}>
        <table className="table">
          <thead>
            <tr>
              <th>Location</th>
              <th>Count</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <TizetiGIFLogoTable colSpan="4" />
            ) : installationCount.length > 0 ? (
              <>
                {installationCount.map((list, index) => {
                  return CountRow(list.state, list.count, index);
                })}
                {CountRow(
                  'TOTAL INSTALLATIONS',
                  installationCount.reduce((prev, next) => {
                    return prev + next.count;
                  }, 0),
                )}
              </>
            ) : (
              <NoItemRow colSpan="4" text="Counter empty" />
            )}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="col-12 p-0 after-summary">
      <InstallationsSummary />

      <hr className="mt-4" style={{ height: '10px', backgroundColor: 'gray', borderRadius: '20px' }} />
      <h3 className="mb-4">Installation Summary Report</h3>
      <div className="row mx-5">
        <div className="my-2">
          <SearchInstallationSummary
            params={params}
            loading={isLoading}
            page={page}
            // searchUsingKeyword={searchUsingKeyword}
            searchInstallations={searchInstallations}
            searchfailedInstallations={searchfailedInstallations}
            searchCompletedInstallations={searchCompletedInstallations}
            getInstallationCounter={getInstallationCounter}
            handleChange={handleChange}
          />
        </div>
        <div className="container pt-3 mt-2">
          <h4 className="col-lg-6 overview-part">Completed Installations</h4>
          {installationCount.length > 0 && (
            <CustomCsvDownload
              data={installationCount || []}
              filename={filename(`Installation Counter ${country}`)}
              classname="btn btn-green shadow-md mr-2 my-2"
              textcolor="white"
              text="Download Counter"
            />
          )}
          {completedInstalls.length > 0 && (
            <CustomCsvDownload
              data={
                completedInstalls.map((list) => {
                  const { firstName, lastName, email, phoneNumber, address } = list.user;
                  const { name } = list.zone;
                  const { status } = list;
                  const fullName = firstName + lastName;
                  return {
                    fullName,
                    email,
                    phoneNumber,
                    address,
                    status,
                    name,
                  };
                }) || []
              }
              headers={installationCounterHeaders}
              filename={filename(`Installation List all`)}
              classname="btn btn-green shadow-md my-2"
              textcolor="white"
              text="Download List"
            />
          )}
          {counts()}
          <InstallationSummaryTable
            loading={isLoading}
            installationList={completedInstalls}
            searchCompletedInstallations={searchCompletedInstallations}
            totalCount={completedCount}
            pages={completedInstallPages}
            page={page}
            setPage={setPage}
            params={params}
          />
        </div>
        {/* <div className="container pt-4">
          <h4 className="col-lg-6 overview-part">Relocations</h4>
          <CustomCsvDownload
              data={lists || []}
              headers={relocationHeaders}
              filename={filename('Relocations')}
              classname="btn btn-green shadow-md"
              textcolor="white"
              text="Download"
            />
          <InstallationSummaryRelocationTable lists={lists} isLoading={isLoading} />
        </div> */}
        <div className="container pt-4">
          <h4 className="col-lg-6 overview-part">Rescheduled Installations</h4>
          <CustomCsvDownload
            data={items || []}
            headers={failedHeaders}
            filename={filename('Rescheduled Installations')}
            classname="btn btn-green shadow-md"
            textcolor="white"
            text="Download"
          />
          <InstallationTable items={items} loader={loader} />
        </div>
        <div className="container pt-4">
          <h4 className="col-lg-6 overview-part">Failed Installations(NO LOS)</h4>
          <CustomCsvDownload
            data={rows || []}
            headers={failedHeaders}
            filename={filename('Failed Installations')}
            classname="btn btn-green shadow-md"
            textcolor="white"
            text="Download"
          />
          <InstallationSummaryFailedTable rows={rows} loads={loads} />
        </div>
      </div>
    </div>
  );
};

export default InstallationCounterSummary;
