import React from 'react';

const StatisticsTableHead = () => {
  return (
    <thead>
      <tr>
        <th>#</th>
        <th className="text-nowrap">config_version</th>
        <th className="text-nowrap">connected_sms</th>
        <th>gain</th>
        <th>gps_sync_state</th>
        <th>last_sync</th>
        <th>mac</th>
        <th>managed_account</th>
        <th>mode</th>
        <th>name</th>
        <th>string</th>
        <th>network</th>
        <th>reboots</th>
        <th>session drops</th>
        <th>status</th>
        <th>Status Time</th>
        <th>Tower</th>
        <th>Type</th>
        <th>Vlan</th>
        <th>Default Gateway</th>
        <th>IP DNS</th>
        <th>IP WAN</th>
        <th>Lan Mode status</th>
        <th>lan_status</th>
        <th>netmask</th>
        <th>radio auth mode</th>

        <th>radio auth_type</th>
        <th>radio channel widtht</th>
        <th>radio dfs status</th>
        <th>radio dl mcs</th>
        <th>radio dl pkts</th>
        <th>radio dl_retransmits</th>
        <th>radio dl_retransmits_pct</th>

        <th>radio dl_throughput</th>
        <th>radio frequency</th>
        <th>radio mac</th>
        <th>radio mode</th>
        <th>radio session dropped</th>
        <th>radio ssid</th>
        <th>radio tdd_ratio</th>
        <th>radio tx_power</th>
        <th>radio ul_mcs</th>
        <th>radio ul_pkts</th>
        <th>radio ul_snr</th>
        <th>radio ul_throughput</th>
        <th>radio wlan_status</th>
      </tr>
    </thead>
  );
};

export default StatisticsTableHead;
