/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import toastr from 'toastr';
// import ResearchLogo from '../../../../layouts/research-and-development/ResearchLogo';
import axios from 'axios';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import Logout from '../../../shared/auth/Logout';
import ResearchAndDevelopmentSideBar from '../../../../layouts/research-and-development/ResearchAndDevelopmentSideBar';
import { API_URL, RESEARCH_AND_DEVELOPMENT } from '../../../../../utils/config';
import RecruitmentTable from '../../../shared/recruitment/RecruitmentTable';
import NewPosting from '../../../shared/recruitment/managers/NewPosting';
import { getResearchJobs } from '../../../../../actions/shared/recruitment/RecruitmentActions';
import ApplicantTable from '../../../shared/recruitment/applications/ApplicantTable';
import ApplicationsTableHead from '../../../shared/recruitment/applications/ApplicationsTableHead';
import { getJobsApplications } from '../../../../../actions/shared/recruitment/ApplicationsActions';
import { SUPER_ADMIN } from '../../../../../utils/departments/helpers';

class ResearchRecruitment extends Component {
  static propTypes = {
    jobs: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    getResearchJobs: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      role: '',
      pages: [],
      logs: [],
      department: '',
      loading: false,
      firstname: '',
    };
  }

  componentDidMount() {
    this.fetchLoggedOnUser();
    this.props.getResearchJobs();
    this.getUser();
  }

  componentWillReceiveProps(nextProps) {
    this.fetchLoggedOnUser();

    if (nextProps.jobs) {
      this.setState({
        logs: nextProps.jobs.logs,
        pages: nextProps.jobs.pages,
        count: nextProps.jobs.count,
      });
    }
  }

  getUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employee/loggedOnEmployee`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    }).then((res) => {
      if (res.data) {
        this.setState({
          firstname: res.data.firstName,
        });
      }
    });
  }

  handlePageChange = (pageNumber) => {
    const selected = pageNumber.selected + 1;

    const url = `${API_URL}/recruitment/listing/?page=${selected}`;

    axios({
      method: 'get',
      url,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      }
    }).then((response) => {
      if (response.data) {
        this.setState({
          logs: response.data.logs,
          pages: response.data.pages,
        });
      } else {
        toastr.info('No data!');
      }
    });
  };

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  getJobsByDate = (department) => {
    if (department) {
      this.setState({
        loading: true,
      });

      const url = `${API_URL}/recruitment/listing/${department}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        })
        .then((response) => {
          this.setState({
            loading: false,
            logs: response.data.logs,
            pages: response.data.pages,
          });
        })
        .catch(() => {
          //   console.log(err.response);
        });
    }
  };

  getDepartmentApplications = (job) => {
    // console.log(job);

    this.props.getJobsApplications(job);
  };

  fetchLoggedOnUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employeeReg/logged`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        if (data.data) {
          this.setState({
            role: data.data.role_id,
          });
        }

        if (data.data.department_id !== `${RESEARCH_AND_DEVELOPMENT}` &&
          data.data.department_id !== `${SUPER_ADMIN}`) {
          this.props.history.push('/login');

          toastr.info('You are not allowed to view this page');
        }
      })
      .catch(() => {});
  }

  render() {
    const { role, loading, firstname } = this.state;
    const { logs } = this.props.jobs;
    const { applications } = this.props;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <Link className="s-sidebar__trigger" to="#">
                <i className="fa fa-bars mr-3" />
              </Link>
              <ResearchAndDevelopmentSideBar role={role} />

              <h3 className="col-lg-6 overview-part">Recruitment</h3>

              <span className="navbar-text">
                <h6>
                  {firstname}
                  <Logout />
                </h6>
              </span>
            </div>
          </div>

          <div className="after-summary col-12">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 mt-4">
                  <div className="col-6 d-flex">
                    <div className="tab-stuff col-10 p-0">
                      <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li className="nav-item mr-auto">
                          <a
                            className="nav-link active"
                            id="pills-home-tab"
                            data-toggle="pill"
                            href="#pills-home"
                            role="tab"
                            aria-controls="pills-home"
                            aria-selected="true"
                          >
                            Job Postings
                          </a>
                        </li>
                        <li className="nav-item mr-auto">
                          <a
                            className="nav-link"
                            id="pills-profile-tab"
                            data-toggle="pill"
                            href="#pills-profile"
                            role="tab"
                            aria-controls="pills-profile"
                            aria-selected="false"
                          >
                            Applications
                          </a>
                        </li>
                        <button className="btn btn-green" type="button" data-target="#Newposting" data-toggle="modal">
                          New Posting
                        </button>

                        <NewPosting />
                      </ul>
                    </div>
                  </div>

                  <div className="tab-content col-12" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >
                      <div className="col-lg-12 p-0">
                        <div className="card x-panel w-100 ml-0 p-2 mt-4">
                          <div className="table-responsive content col-lg-12 col-md-12 col-sm-12 p-0">
                            <table className="table col-lg-12 col-md-12 col-sm-12 copy-font">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Job Title</th>
                                  <th>Department</th>
                                  {/* <th>Type</th> */}
                                  {/* <th>Posting Date</th> */}
                                  <th>Deadline</th>
                                  {/* <th>Number of Applicants</th> */}
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              {loading ? <small>loading</small> : <RecruitmentTable all={logs} />}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade show"
                      id="pills-profile"
                      role="tabpanel"
                      aria-labelledby="pills-profile-tab"
                    >
                      <div className="col-6 d-flex p-0">
                        <small>Filter By:</small>
                        <div className="d-flex">
                          <span className="ml-2 mr-3">
                            <select
                              className="form-control"
                              onChange={(e) => this.getDepartmentApplications(e.target.value)}
                              name="application"
                            >
                              <option>Jobs</option>
                              {logs ? logs.map((job) => <option value={job.id}>{job.job_title}</option>) : 'no jobs'}
                            </select>
                          </span>

                          <span className="mr-3">
                            <input type="date" className="form-control" />
                          </span>
                        </div>
                      </div>

                      <div className="col-6 d-flex p-0 mt-3">
                        <small>Filter By:</small>
                        <div className="d-flex">
                          <span className="ml-2 mr-3">
                            <select
                              className="form-control"
                              onChange={(e) => this.getDepartmentApplications(e.target.value)}
                              name="application"
                            >
                              <option>Country</option>
                              <option value="gh">Ghana</option>
                              <option value="ng">Nigeria</option>
                            </select>
                          </span>
                        </div>
                      </div>

                      <div className="col-lg-12 p-0">
                        <div className="card x-panel w-100 ml-0 p-2 mt-4">
                          <div className="table-responsive content col-lg-12 col-md-12 col-sm-12 p-0">
                            <table className="table col-lg-12 col-md-12 col-sm-12 copy-font">
                              <ApplicationsTableHead />
                              <ApplicantTable applications={applications} />
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.pages > 1 ? (
                    <ReactPaginate
                      previousLabel="previous"
                      nextLabel="next"
                      breakLabel="..."
                      breakClassName="break-me"
                      pageCount={this.state.pages}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={this.state.pageRangeDisplayed}
                      onPageChange={this.handlePageChange}
                      containerClassName="pagination"
                      subContainerClassName="pages pagination"
                      activeClassName="active"
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  jobs: state.researchjobs.researchjobs,
  applications: state.applications.applications,
});

export default connect(
  mapStateToProps,
  {
    getResearchJobs,
    getJobsApplications,
  },
)(ResearchRecruitment);
