/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { useCustomers } from '../../../../../hooks/new-products/products/wificall/customers/useCustomers';

const PushNotification = ({ customer }) => {
  const { sendPushNotification } = useCustomers();
  const [text, setText] = useState('');
  const [title, setTitle] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    const data = qs.stringify({
      action: 'sendWificallPushNotification',
      card_id: `${customer.user_sip.cardid}`,
      title: `${title}`,
      message: `${text}`,
    });
    sendPushNotification(data);
  };

  return (
    <div
      className="modal fade"
      id="singlepush"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog mt-5" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="exampleModalLabel">
              Push Notification
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form onSubmit={onSubmit}>
            <div className="modal-body">
              <div className="form-group">
                <label className="p-0 col-form-label">Recipient:</label>
                <input
                  type="text"
                  className="form-control"
                  value={`${customer.firstName} ${customer.lastName}`}
                  readOnly
                />
              </div>
              <div className="form-group">
                <label className="copy-font p-0 col-form-label">Title:</label>
                <input
                  type="text"
                  className="form-control border shadow"
                  name="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label className="p-0 col-form-label">Message:</label>
                <textarea
                  className="form-control"
                  name="text"
                  value={text}
                  rows="12"
                  onChange={(e) => setText(e.target.value)}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">
                Close
              </button>
              <button type="submit" className="btn btn-primary">
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

PushNotification.propTypes = {
  customer: PropTypes.objectOf.isRequired,
};

export default PushNotification;
