/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import useCheckDepartment from '../../../../../hooks/shared/custom/check-department/useCheckDepartment';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import { NEW_PRODUCTS, SUPER_ADMIN } from '../../../../../utils/departments/helpers';
import WifiCallNavBar from '../../../../layouts/wificall/WifiCallNavBar';
import LogsBody from './LogsBody';
import useCheckTwoDepartments from '../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';

const WifiCallLoginLogs = ({ history }) => {
  useCheckTwoDepartments(`${NEW_PRODUCTS}`, `${SUPER_ADMIN}`, history);
  const { profile, user } = useUser();
  const { firstName } = profile;
  const { role_id } = user;

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <WifiCallNavBar currentPage="Login Logs" position={firstName} role_id={role_id} />
        <div className="container">
          <div className="row mt-5">
            <LogsBody />
          </div>
        </div>
      </main>
    </div>
  );
};

WifiCallLoginLogs.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default WifiCallLoginLogs;
