import React from 'react';
import BusinessDevelopmentNavBar from '../../../../layouts/business-development/BusinessDevelopmentNavBar';
import BusinessDevelopmentCallCardBody from './BusinessDevelopmentCallCardBody';

const BusinessDevelopmentDedicatedCustomers = () => {
    return (
        <div className="s-layout">
            <main className="s-layout__content col-lg-10 p-0">
                <BusinessDevelopmentNavBar currentPage="Call Card" />
                <BusinessDevelopmentCallCardBody />
            </main>
        </div>
    );
};

export default BusinessDevelopmentDedicatedCustomers;
