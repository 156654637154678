/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { ToastContainer } from 'react-toastify';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import '../leads-and-managers/index.css';
import { OverviewProvider } from '../../../../../context/shared/installations/reschedule/OverviewContext';
import { SALES } from '../../../../../utils/config';
import { OPERATIONS } from '../../../../../utils/departments/helpers';
import useCheckTwoDepartments from '../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';
import FieldSupportNavBar from '../../../../layouts/field-support/FieldSupportNavBar';
import RescheduleOverviewBody from '../leads-and-managers/RescheduleOverviewBody';


const FSERescheduleOverview = ({ history }) => {
  const { profile } = useUser();
  const { firstName } = profile;
  useCheckTwoDepartments(`${SALES}`, `${OPERATIONS}`, history);
  return (
    <div className="s-layout">
      <OverviewProvider>
        <main className="s-layout__content col-lg-10 p-0">
          <FieldSupportNavBar currentPage="Rescheduled Installations" position={firstName} />
          <RescheduleOverviewBody />
        </main>
      </OverviewProvider>
      <ToastContainer />
    </div>
  );
};
export default FSERescheduleOverview;
