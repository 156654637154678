import { FETCHING_STARTS, FETCHING_STOPS } from '../../constants/types/shared/LoadingTypes';
import { GET_FIBRE_LINKS } from '../../constants/types/noc/NocTypes';

const initialState = {
  fibre: [],
  load: false,
};

export const FibreLinksReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCHING_STARTS:
      return {
        ...state,
        load: payload,
      };

    case FETCHING_STOPS:
      return {
        ...state,
        load: payload,
      };

    case GET_FIBRE_LINKS:
      return {
        ...state,
        fibre: payload,
        load: false,
      };

    default:
      return state;
  }
};

export default FibreLinksReducer;
