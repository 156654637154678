/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { API_URL } from '../../../../../utils/config';

class AddDevice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      basestations: [],
      basestation: '',
      solar: '',
      gracia: '',
      ebn: '',
      load: false,
    };

    this.onChange = this.onChange.bind(this);
    this.addDevice = this.addDevice.bind(this);
  }

  componentDidMount() {
    this.getBasestations();
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  getBasestations() {
    axios
      .get(`${API_URL}/baseStation`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        if (res.data) {
          this.setState({
            basestations: res.data,
          });
        }
      });
  }

  submitButton() {
    if (this.state.load) {
      return (
        <div className="text-center">
          <button className="btn btn-green" type="button" disabled>
            Saving...
          </button>
        </div>
      );
    }
    return (
      <div className="text-center">
        <button className="btn btn-green">Save</button>
      </div>
    );
  }

  selectBasestations() {
    if (this.state.basestations) {
      return (
        <select className="form-control" name="basestation" onChange={this.onChange}>
          <option value="">Select Basestation</option>
          {this.state.basestations.map((basestation, i) => {
            return (
              <option key={basestation.id} value={basestation.id}>
                {basestation.name}
              </option>
            );
          })}
        </select>
      );
    }
  }

  async addDevice(e) {
    this.setState({
      load: true,
    });

    e.preventDefault();

    const data = {
      solar_panels: this.state.solar,
      gracia: this.state.gracia,
      ebn: this.state.ebn,
      base_station_id: this.state.basestation,
    };

    if (this.state.solar === '') {
      toastr.info('Please enter the solar panel');
      this.setState({
        load: false,
      });
    }

    if (this.state.gracia === '') {
      toastr.info('Please enter the name');
      this.setState({
        load: false,
      });
    }

    if (this.state.ebn === '') {
      toastr.info('Please enter the quantity');
      this.setState({
        load: false,
      });
    }

    if (this.state.basestation === '') {
      toastr.info('Please select a basestations');
      this.setState({
        load: false,
      });
    }

    try {
      const response = await axios.post(`${API_URL}/batteries/addbatteries`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      console.log(response.data);
      if (response.data.id) {
        this.setState(
          {
            basestations: [],
            basestation: '',
            solar: '',
            gracia: '',
            ebn: '',
            load: false,

            modalShow: !this.state.modalShow,
          },
          (props) => {
            // this.props.modalshow = this.state.modalShow;
            // console.log("hello", this.state.modalShow, this.props.modalshow(this.state.modalShow));
          },
        );
        this.props.get();

        toastr.success('Created successfully', {
          timeOut: 2000,
          fadeOut: 2000,
        });
      }
    } catch (err) {
      console.log(err.response);
      if (err) {
        toastr.info(`Something went wrong!`);
        this.setState({ load: false });
      }
    }
  }

  render() {
    return (
      <div className="modal-body">
        <div className="row">
          <form className="form-horizontal col-12" onSubmit={this.addDevice}>
            <div className="form-group">
              <div className="col-12 d-flex mb-3">
                <div className="col-6">
                  <label className="copy-font m-0 p-0">Select Basestation</label>
                  {this.selectBasestations()}
                </div>
                <div className="col-6">
                  <label className="copy-font m-0 p-0">EBN</label>
                  <input
                    type="number"
                    name="ebn"
                    onChange={this.onChange}
                    value={this.state.ebn}
                    className="form-control pl-2"
                    placeholder="Enter Ebn"
                  />
                </div>
              </div>

              <div className="col-12 d-flex mb-3">
                <div className="col-6">
                  <label className="copy-font m-0 p-0">Gracia</label>
                  <input
                    type="number"
                    name="gracia"
                    className="form-control pl-2"
                    onChange={this.onChange}
                    value={this.state.gracia}
                  />
                </div>
                <div className="col-6">
                  <label className="copy-font m-0 p-0">Solar Panel</label>
                  <input
                    type="number"
                    name="solar"
                    onChange={this.onChange}
                    className="form-control pl-2"
                    value={this.state.solar}
                  />
                </div>
              </div>

              {this.submitButton()}
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default AddDevice;
