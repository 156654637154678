import React from 'react';
import BusinessDevelopmentNavBar from '../../../../layouts/business-development/BusinessDevelopmentNavBar';
import BusinessDevelopmentCustomerRevenueBody from './BusinessDevelopmentCustomerRevenueBody';

const BusinessDevelopmentCustomerRevenue = () => {
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <BusinessDevelopmentNavBar currentPage="Customer Revenue" />
        <BusinessDevelopmentCustomerRevenueBody />
      </main>
    </div>
  );
};

export default BusinessDevelopmentCustomerRevenue;
