/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import SalesNavBar from '../../../../layouts/sales/SalesNavBar';
import SalesJacketBody from './SalesJacketBody';

const SalesJacket = () => {
  const { profile, user } = useUser();
  const { firstName } = profile;

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <SalesNavBar currentPage="Tizeti Jackets" roleID={user.role_id} position={firstName} />
        <SalesJacketBody />
      </main>
    </div>
  );
};

export default SalesJacket;
