/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/prop-types */
import React from 'react';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../../utils/loader/loadingHelper';

const IMSITable = ({ customers, option, isLoading }) => {
  const colSpan = 6;

  const displayIMSI = () => {
    if (customers && customers.imsi) {
      const { imsi, no_of_months, expired_at, user: { firstName, lastName, email, phoneNumber } = {} } = customers;
      return (
        <tr>
          <td>{`${firstName} ${lastName}`}</td>
          <td>{email}</td>
          <td>{imsi}</td>
          <td>{phoneNumber}</td>
          <td>{expired_at}</td>
          <td>{no_of_months}</td>
        </tr>
      );
    }
    return <NoItemRow colSpan={colSpan} text={`no customer with this ${option}`} />
  };

  return (
    <table className="table copy-font">
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>IMSI</th>
          <th>Phone Number</th>
          <th>Expiry Date</th>
          <th>Month(s)</th>
        </tr>
      </thead>
      <tbody>{isLoading ? <TizetiGIFLogoTable colSpan={colSpan} /> : displayIMSI()}</tbody>
    </table>
  );
};

export default IMSITable;
