/* eslint-disable import/prefer-default-export */
export const GET_REFUNDS = 'GET_REFUNDS';
export const LOADING_EMAIL_STARTS = 'LOADING_EMAIL_STARTS';
export const LOADING_EMAIL_STOPS = 'LOADING_EMAIL_STOPS';
export const LOADING_NAME_STARTS = 'LOADING_NAME_STARTS';
export const LOADING_NAME_STOPS = 'LOADING_NAME_STOPS';
export const LOADING_PHONE_STARTS = 'LOADING_PHONE_STARTS';
export const LOADING_PHONE_STOPS = 'LOADING_PHONE_STOPS';
export const LOADING_ADDRESS_STARTS = 'LOADING_ADDRESS_STARTS';
export const LOADING_ADDRESS_STOPS = 'LOADING_ADDRESS_STOPS';
export const LOADING_MAC_STARTS = 'LOADING_MAC_STARTS';
export const LOADING_MAC_STOPS = 'LOADING_MAC_STOPS';
export const GET_SCHEDULED = 'GET_SCHEDULED';
export const REGISTERED_FIELD_SERVICE_ZONE = 'REGISTERED_FIELD_SERVICE_ZONE';
