import React from 'react';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import helpers from '../../../../../utils/helpers';
import useFetch from '../../../../../hooks/shared/custom/data/useFetch';
import { COMPLETED_SITES_URL } from '../../../../../services/api/noc/CompletedSitesUrl';
// import { CompletedSitesProps } from './types/CompletedSitesProps';

const CompletedSitesTable = () => {
  const { data, loading } = useFetch(`${COMPLETED_SITES_URL}`);
  const tableData =
    data && data.rows
      ? data.rows.map((row, i) => (
          <tr className="text-nowrap" key={i}>
            <td>{i + 1}</td>
            <td>{row.name}</td>
            <td>{row.ip}</td>
            <td>{helpers.formatDateAndTime(row.created_at)}</td>
          </tr>
        ))
      : 'no access point';
  return (
    <div className="card x-panel mt-2 px-0">
      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th className="text-nowrap">IP</th>
              <th>Date Created</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                {/* <td colSpan="9" className="text-center"> */}
                <img src={loader} alt="loading gif" />
                {/* </td> */}
              </tr>
            ) : (
              tableData
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CompletedSitesTable;
