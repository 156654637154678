import React from 'react';
import { useBasestations } from '../../../../../hooks/shared/basestations/useBasestation';
import useLatencyGraph from '../../../../../hooks/noc/latency-graph/useLatencyGraph';

const LatencyGraphBody = () => {
  const { basestations } = useBasestations();
  const { showLatency } = useLatencyGraph();

  const selectBasestation = () => {
    if (basestations) {
      return (
        <form className="form-horizontal">
          <div className="form-group">
            <div className="col-3">
              <select className="form-control" onChange={showLatency} name="basestation">
                <option>Select Basestation</option>
                {basestations.map((basestation) => {
                  return (
                    <option key={basestation.id} value={basestation.id}>
                      {basestation.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </form>
      );
    }
    return null;
  };

  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col-12 my-5">
          {selectBasestation()}
          <div className="col-md col-sm">
            <div className="graph1 col-12 mb-5">
              <h6>Daily</h6>
              <div id="lekki" style={{ height: '300px' }} />
            </div>

            <div className="graph1 col-12 d-flex">
              <div className="col-6">
                <h6>Weekly</h6>
                <div id="yaba" style={{ height: '300px' }} />
              </div>

              <div className="col-6">
                <h6>Monthly</h6>
                <div id="ajah" style={{ height: '300px' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LatencyGraphBody;
