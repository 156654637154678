/* eslint-disable react/prop-types */
import React from 'react';
import { CSVLink } from 'react-csv';
import loader from '../../../../../../assets/img/icons/TizetiLoaderColored.gif';
import ViewExpiredUsersTable from './ViewExpiredUsersTable';

const ViewExpiredUsers = ({ zone, users, isLoading }) => {
  const headers = [
    { label: 'Name', key: 'Name' },
    { label: 'MAC ID', key: 'username' },
    { label: 'Expiry Date', key: 'Expiration_date' },
  ];
  return (
    <div className="modal fade" id="viewDetails" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-lg large-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" style={{ color: 'black' }}>
              {zone.toUpperCase()} Expired Customer Details
            </h4>
            <button type="button" className="mr-2" data-dismiss="modal" aria-hidden="true">
              x
            </button>
          </div>
          <div className="modal-body">
            <div className="col-12">
              {users.length ? (
                <div className="d-flex justify-content-end mr-3 mb-2 ">
                  <CSVLink
                    data={users}
                    headers={headers}
                    filename="Expired Customers Details Seven Days Ago.csv"
                    className="btn btn-green shadow-md"
                    style={{ color: 'white' }}
                  >
                    Download Data
                  </CSVLink>
                </div>
              ) : null}
            </div>
            <div className="table-responsive content col-12">
              <table className="table">
                <thead>
                  <tr className="">
                    <th>S/N</th>
                    <th>Name</th>
                    <th>MAC ID</th>
                    <th>Expiry Date</th>

                    <th>Mail</th>
                    <th>Number</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan="4" className="text-center">
                        <img src={loader} alt="loading gif" />
                      </td>
                    </tr>
                  ) : (
                    <ViewExpiredUsersTable users={users} />
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewExpiredUsers;
