import React from 'react';
import { ToastContainer } from 'react-toastify';
import MakeRequest from './MakeRequest';
import { useRequests } from '../../../../../hooks/shared/requests/useRequests';
import { RequestProvider } from '../../../../../context/shared/requests/non-admin/RequestContext';
import RequestsTableBody from './RequestsTableBody';

const Requests = () => {
  const { requests, isLoading, categories, makeRequest, onChange, request, isLoadingRequest } = useRequests();

  return (
    <div className="col-12 after-summary">
      <RequestProvider value={{ requests, isLoading, categories, makeRequest, onChange, request, isLoadingRequest }}>
        <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-3">
          <div className="text-right">
            <button
              className="btn btn-green mb-2"
              type="submit"
              style={{ cursor: 'pointer' }}
              data-toggle="modal"
              data-target="#makeRequest"
            >
              Make Request
            </button>
            <MakeRequest />
          </div>
          <div className="d-none d-md-flex">
            <form className="form-inline d-flex inner-addon left-addon w-100 col-md-12">
              {/* <input */}
              {/*  required */}
              {/*  className="form-control mr-auto empty mt-1 col-md-5" */}
              {/*  id="iconified1" */}
              {/*  type="text" */}
              {/*  placeholder="Search By Last Name, Email and Phone number" */}
              {/* /> */}

              {/* <div className="copy-font d-flex mr-auto"> */}
              {/*  <small>Category:</small> */}
              {/*  <span> */}
              {/*    <select className="form-control" name="status" required> */}
              {/*      <option value="">Select Category</option> */}
              {/*      <option value="furniture">Furniture</option> */}
              {/*      <option value="vehicle">Vehicle</option> */}
              {/*      <option value="Stationery">Stationery</option> */}
              {/*    </select> */}
              {/*  </span> */}
              {/* </div> */}

              {/* <div className="copy-font d-flex mr-auto">
                <small>FilterByCountry By:</small>
                <span>
                  <select className="form-control" name="option" onChange={onFilterChange}>
                    <option value="">Select Status</option>
                    <option value="rejected">Rejected</option>
                    <option value="pending">Pending</option>
                    <option value="resolved">Resolved</option>
                  </select>
                </span>
              </div> */}

              {/* <button className="btn btn-green">Search</button> */}
            </form>
          </div>
        </div>
        <div className="card x-panel logs">
          <div className="table-responsive content">
            <table className="table copy-font">
              <thead>
                <tr>
                  <th>#</th>
                  <th nowrap="no-wrap">Category</th>
                  <th nowrap="no-wrap">Description</th>
                  <th nowrap="no-wrap">Date Requested</th>
                  <th nowrap="no-wrap">Handled By</th>
                  {/* <th nowrap="no-wrap">Date Handled</th> */}
                  <th>Status</th>
                  <th style={{ paddingLeft: '5px' }}>Details</th>
                </tr>
              </thead>
              <RequestsTableBody />
            </table>
          </div>
        </div>
      </RequestProvider>
      <ToastContainer />
    </div>
  );
};

export default Requests;
