/* eslint-disable @typescript-eslint/camelcase */
import React, { useContext, useState } from 'react';
import AppContext from '../../../../../context/shared/AppContext';
import helpers from '../../../../../utils/helpers';
import erase from '../../../../../assets/img/icons/delete.png';
import ConfirmDelete from '../../../shared/confirmation-modal/ConfirmDelete';

const TeamTable = () => {
  const { teams, deleteTeam } = useContext(AppContext);

  const [one, setOne] = useState({ name: '', id: 0, created_at: '' });

  const getItem = (item) => setOne(item);

  const confirmDelete = (e) => {
    e.preventDefault();
    return deleteTeam(one.id);
  };

  return (
    <tbody>
      {teams.map((team, i) => (
        <tr key={team.id}>
          <td>{i + 1}</td>
          <td>{team.name}</td>
          <td>{helpers.formatDate(team.created_at)}</td>
          <td>
            <button
              type="button"
              className="ml-2 noBorder"
              data-target="#confirm_delete"
              onClick={() => getItem(team)}
              data-toggle="modal"
            >
              <span className="view">
                <img src={erase} alt="" data-toggle="tooltip" data-placement="top" title="Delete" />
              </span>
            </button>
          </td>
          <ConfirmDelete
            text={
              <h5>
                Confirm Delete of <strong>{`${one.name}`}</strong>?
              </h5>
            }
            confirmDelete={confirmDelete}
          />
        </tr>
      ))}
    </tbody>
  );
};

export default TeamTable;
