/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { CREATE_BUDGET_URL } from './BudgetUrls';

export const create = (data) => {
  try {
    return axios.post(`${CREATE_BUDGET_URL}`, data, {
      headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
    });
  } catch (e) {
    return e;
  }
};
