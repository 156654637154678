/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import toastr from 'toastr';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { CSVLink } from 'react-csv';
import loader from '../../../../assets/img/icons/TizetiLoaderColored.gif';
import { admin, NEW_PRODUCTS } from '../../../../utils/config';
import { LOGGED_IN_USER_URL } from '../../../../services/api/auth/AuthUrl';
import { getUser } from '../../../../actions/shared/user/UserActions';
import WifiCallSidebar from '../../../layouts/wificall-sidebar/WifiCallSidebar';
import Logout from '../../shared/auth/Logout';
import { getAndroidCount, getiOSCount, getWebCount } from '../../../../actions/wificall/link-tree/LinkCountActions';
import { SUPER_ADMIN } from '../../../../utils/departments/helpers';

class Count extends Component {
  static propTypes = {
    getUser: PropTypes.func.isRequired,
    firstName: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      datefrom: `${new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10)}`,
      dateto: `${new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10)}`,
      linksource: 'android',
      // ipAdd: '',
      log: [],
      show: false,
      pages: '',
      // all: [],
    };
  }

  componentDidMount() {
    this.checkDepartment();
    this.props.getWebCount();
    this.props.getUser();
    this.props.getAndroidCount();
    this.props.getiOSCount();
    this.getDataByRange();
  }

  componentWillReceiveProps() {
    this.checkDepartment();
  }

  checkDepartment = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (
        response.data.department_id !== `${admin}` &&
        response.data.department_id !== `${NEW_PRODUCTS}` &&
        response.data.department_id !== `${SUPER_ADMIN}`
      ) {
        this.props.history.push('/');

        sessionStorage.setItem('token', '');
        sessionStorage.clear();

        toastr.info('You are not allowed to view this page');
      }
    } catch (e) {
      // console.log(e.response);
      toastr.error('Something went wrong');
    }
  };

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  handlePageClick = (page) => {
    const selected = page.selected + 1;

    const { datefrom, dateto, linksource } = this.state;

    const data = {
      from: datefrom,
      to: dateto,
      source: linksource,
    };

    axios({
      method: 'post',
      url: `https://api.tizeti.com/api/v1/link-count/getLinkCountByDate/${selected}`,
      data,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    }).then((res) => {
      this.setState({
        // all: res.data,
        log: res.data.logs,
        loading: false,
        pages: res.data.pages,
      });

      if (this.state.pages < 2) {
        this.setState({
          show: false,
        });
      } else {
        this.setState({
          show: true,
        });
      }
    });
  };

  getDataByRange = () => {
    this.setState({
      loading: true,
      show: false,
    });
    const { datefrom, dateto, linksource } = this.state;
    const data = {
      from: datefrom,
      to: dateto,
      source: linksource,
    };
    axios({
      method: 'post',
      url: 'https://api.tizeti.com/api/v1/link-count/getLinkCountByDate/1',
      data,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    }).then((res) => {
      this.setState({
        // all: res.data,
        log: res.data.logs,
        loading: false,
        pages: res.data.pages,
      });

      if (this.state.pages < 2) {
        this.setState({
          show: false,
        });
      } else {
        this.setState({
          show: true,
        });
      }
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.getDataByRange();
  };

  render() {
    const { user, web, android, ios } = this.props;
    const { firstName } = user;
    const { datefrom, dateto, linksource, log, loading, show, pages } = this.state;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <Link className="s-sidebar__trigger" to="#">
                <i className="fa fa-bars mr-3" />
              </Link>
              <WifiCallSidebar />

              <h3 className="col-lg-6 overview-part">Click Count</h3>

              <div className="d-flex">
                <h6 className="mr-0 mt-1">
                  {firstName}
                  <Logout />
                </h6>
              </div>
            </div>
          </div>

          <div className="col-12 after-summary">
            <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-3">
              <div className="row">
                <div className="col-lg-12 col-md-9 col-sm-12 mt-3 mb-3 d-none d-md-flex callog">
                  <form className="d-flex mt-2 mr-auto" onSubmit={this.onSubmit}>
                    <small className="mr-2 ml-3">Show From</small>
                    <span>
                      <input
                        type="date"
                        className="form-control"
                        onChange={this.onChange}
                        name="datefrom"
                        value={datefrom}
                      />
                    </span>
                    <small className="ml-2 mr-2">To</small>
                    <span>
                      <input
                        type="date"
                        className="form-control"
                        onChange={this.onChange}
                        name="dateto"
                        value={dateto}
                      />
                    </span>
                    <small className="ml-3 mr-2">Source</small>
                    <span>
                      <select className="form-control mr-5 " name="linksource" onChange={this.onChange}>
                        <option value="android">Android</option>
                        <option value="ios">iOS</option>
                        <option value="web">Web</option>
                      </select>
                    </span>

                    <div>
                      <button type="submit" className="btn btn-secondary ml-5">
                        Search
                      </button>
                    </div>
                  </form>
                  {/* <div className="col-lg-4 ml-2 ml-auto">
                    <h4 className="navbar-text mt-1" style={{ color: 'black' }}>
                      Web: {web} Android: {android} iOS: {ios}
                    </h4> */}
                  {/* <div><button type="submit" className="btn btn-green" onClick={this.onClick()}>Count</button></div> */}
                  {/* </div> */}
                  {/* <div><button type="submit" className="btn btn-green ml-4 col-lg-10" onClick={this.onClick()}>Get Count</button></div> */}
                </div>
                <div className="ml-3 mb-2 col-12 d-flex justify-content-start">
                  <div className="mr-3">
                    Web: <span className="badge badge-secondary">{web}</span>
                  </div>
                  <div className="mr-3">
                    Android: <span className="badge badge-secondary">{android}</span>
                  </div>
                  <div className="mr-3">
                    iOS: <span className="badge badge-secondary">{ios}</span>
                  </div>
                </div>

                <div className="col-lg-4 d-md-block d-none">
                  <CSVLink
                    data={log}
                    filename={`${linksource + moment(datefrom).format('DD/MM/YY')} ${moment(dateto).format(
                      'DD/MM/YY',
                    )}.csv`}
                  >
                    <button type="button" className="btn btn-green ml-3">
                      Download data
                    </button>
                  </CSVLink>
                </div>

                <div className="card x-panel">
                  <div className="table-responsive content">
                    <table className="table">
                      <thead>
                        <tr className="copy-font text-center">
                          <th>#</th>
                          <th>Date</th>
                          <th scope="col">IP Address</th>
                          <th scope="col">Browser</th>
                          <th scope="col">OS</th>
                          <th scope="col">Region</th>
                        </tr>
                      </thead>

                      <tbody>
                        {loading ? (
                          <tr>
                            <td>
                              <img src={loader} alt="loading gif" />
                            </td>
                          </tr>
                        ) : log && log.length ? (
                          log.map((logs, index) => (
                            <tr className="text-center">
                              <td>{index + 1}</td>
                              <td>{moment(logs.created_at).format('DD/MM/YYYY')}</td>
                              <td>{logs.ipAddress}</td>
                              <td>{logs.browser_name === null ? 'N/A' : logs.browser_name}</td>
                              <td>{logs.browser_os === 'undefined undefined' ? 'N/A' : logs.browser_os}</td>
                              <td>
                                {logs.city} {logs.country}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <p>No User</p>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="page col-lg-12 col-md-12 col-sm-12 d-flex">
                  {show ? (
                    <ReactPaginate
                      previousLabel="Previous"
                      nextLabel="Next"
                      breakLabel="..."
                      breakClassName="break-me"
                      pageCount={pages}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName="pagination"
                      subContainerClassName="pages pagination"
                      activeClassName="active"
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  web: state.linkCountWeb.web,
  android: state.linkCountAndroid.android,
  ios: state.linkCountIOS.ios,
});

Count.propTypes = {
  getWebCount: PropTypes.func.isRequired,
  getAndroidCount: PropTypes.func.isRequired,
  getiOSCount: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { getUser, getWebCount, getAndroidCount, getiOSCount })(Count);
