/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { useBasestations } from '../../../../../hooks/shared/basestations/useBasestation';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import CustomCsvDownload from '../../CustomCsvDownload';
import { filename } from '../../../../../utils/field-support/installations/helpers';

const MonthlyUsers = () => {
  const { callstations, load, monthlyUsers, getUsersByMonth } = useBasestations();

  const headers = [
    { label: 'MAC ID', key: 'macid' },
    { label: 'Name', key: 'name' },
    { label: 'Phone', key: 'mobile' },
    { label: 'Email', key: 'email' },
    { label: 'Basestation', key: 'basestation' },
  ];

  const [basestationName, setBasestationName] = useState('');
  const [month, setMonth] = useState('');

  const searchByMonth = (e) => {
    e.preventDefault();
    getUsersByMonth(basestationName, month);
  };

  const onChange = (e) => setBasestationName(e.target.value);

  const tableData =
    monthlyUsers.length && monthlyUsers ? (
      monthlyUsers.map((basestationUser, i) => {
        return (
          <tr key={basestationUser.id}>
            <td>{i + 1}</td>
            <td>{basestationUser.macid}</td>
            <td>{basestationUser.name}</td>
            <td>{basestationUser.mobile}</td>
            <td>{basestationUser.email}</td>
            <td className="text-nowrap">{basestationUser.basestation}</td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td className="text-center" colSpan="6">
          Select Basestation to view Connected Users
        </td>
      </tr>
    );
  return (
    <div className="">
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <form className="form-horizontal" onSubmit={searchByMonth}>
            <div className="d-flex form-group col-12 mb-3">
              <div className="mt-3 mr-3 d-flex">
                <div className="mr-2">
                  <select className="form-control" onChange={onChange} name="basestation">
                    <option>--Select Basestation--</option>
                    {callstations && callstations.length ? (
                      callstations.map((callstation) => {
                        return (
                          <option key={callstation.id} value={callstation.name}>
                            {callstation.name.toUpperCase()}
                          </option>
                        );
                      })
                    ) : (
                      <option>Loading...</option>
                    )}
                  </select>
                </div>
                <div className="mr-2">
                  <select
                    className="form-control mr-2"
                    onChange={(e) => setMonth(e.target.value)}
                    name="month"
                    value={month}
                  >
                    <option>--Select Months--</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <div className="mt-3 ml-3">
                <button className="btn btn-green shadow-md" type="submit">
                  Search
                </button>
              </div>
            </div>
          </form>
          <div className="mt-3">
            {monthlyUsers.length ? (
              <CustomCsvDownload
                data={monthlyUsers}
                headers={headers}
                filename={filename(`Monthly_Users`)}
                classname="btn btn-green shadow-md"
                textcolor="white"
                text="Download Data"
              />
            ) : null}
          </div>
        </div>
        {monthlyUsers.length ? (
          <div className="mt-3">
            Number of Users: <span className="badge badge-success payslipfont">{monthlyUsers.length}</span>
          </div>
        ) : null}
      </div>

      <div className="ml-2">
        <div className="table-responsive content col-12">
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>MAC</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th className="text-nowrap">Basestation</th>
              </tr>
            </thead>

            <tbody>
              {load ? (
                <tr>
                  <td colSpan="10">
                    <img src={loader} alt="loading gif" />
                  </td>
                </tr>
              ) : (
                tableData
              )}
            </tbody>
          </table>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default MonthlyUsers;
