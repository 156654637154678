/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

const ViewSnmpCacti = ({ view }) => {
  return (
    <div id="view_access-point" className="modal fade mt-5" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title my-2">Details</h4>
            <button type="button" className="mr-2 close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div className="modal-body detail p-0">
            <div className="row">
              <div className="col-lg-12 d-flex">
                <div className="col-lg-5 detail-label1">
                  <label className="mt-1">basestation_name</label>
                  <label className="mt-1">router</label>
                  <label className="mt-1">customer_ip</label>
                </div>
                <div className="col-lg-7 detail-result1 item-font">
                  <p className="mt-4 mb-2">{view.basestation_name}</p>
                  <p className="mb-2">{view.router}</p>
                  <p className="mb-2">{view.customer_ip}</p>
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-5 detail-label3">
                  <label>L2TP</label>
                  <label>SNMP</label>
                  <label>Router Firmware</label>
                </div>
                <div className="col-lg-7 detail-result2 item-font">
                  <p className="mt-4 mb-2">{view.l2tp}</p>
                  <p className="mb-2">{view.snmp}</p>
                  <p className="mb-2">{view.router_firmware}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewSnmpCacti;
