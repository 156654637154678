import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import SalesSideBar from '../../../../layouts/sales/SalesSideBar';
import Logout from '../../../shared/auth/Logout';
import FailedInstallations from '../../../shared/sales/installations/FailedInstallations';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import useCheckTwoDepartments from '../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';
import { SALES, SUPER_ADMIN } from '../../../../../utils/departments/helpers';

const SalesFailedInstallations = ({ history }) => {
  const { profile } = useUser();
  const { firstName } = profile;
  useCheckTwoDepartments(`${SALES}`, `${SUPER_ADMIN}`, history);

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <div className="d-flex col-lg-12 p-2 top-header">
          <div className="mt-3 d-flex col-lg-12 p-0">
            <Link className="s-sidebar__trigger" to="#">
              <i className="fa fa-bars mr-3" />
            </Link>
            <SalesSideBar />

            <h3 className="col-lg-6 overview-part d-none d-md-block">Failed Installations</h3>

            <h3 className="col-lg-6 overview-part d-sm-block d-md-none">Failed</h3>

            <div className="d-flex profile-mail col-6">
              <h6>
                <i className="mr-auto">{firstName} </i>
                <Logout />
              </h6>
            </div>
          </div>
        </div>

        {/* <div className="all-sides col-lg-12">
              <SalesSideBar/> */}
        <FailedInstallations />
      </main>
    </div>
  );
};

SalesFailedInstallations.propTypes = {
  history: PropTypes.shape(),
};

export default SalesFailedInstallations;
