/* eslint-disable react/require-default-props */
/* eslint-disable no-shadow */
/* eslint-disable no-case-declarations */
/* eslint-disable consistent-return */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { useCustomer } from '../../../../../hooks/shared/customers/useCustomers';
import { usePayments } from '../../../../../hooks/shared/customers/usePayments';
import ViewPassword from '../../../shared/customers/passwords/ViewPassword';
import PaymentsHistory from '../../../shared/customers/payments/PaymentsHistory';
import CustomerDetails from '../../../shared/customers/shared/CustomerDetails';
import view from '../../../../../assets/img/icons/view.png';
import Extend from './Extend';
import { displayCountryDropdown } from '../../../../../utils/geniatech/Geniatech';

const NewProductsCustomersTable = () => {
  const {
    search,
    onSearchChange,
    searchForUserByEmail,
    searchByPhoneOrMac,
    searchByName,
    customers,
    isLoading,
    option,
    onOptionChange,
    getPassword,
    gettingPassword,
    password,
    getUserDetails,
    gettingDetails,
    details,
    address,
    session,

    country,
    onCountryChange,
  } = useCustomer();

  const { payments, gettingPayments, getCustomerPayments } = usePayments();

  const [mac, setMac] = useState('');
  const [name, setName] = useState('');

  const searchForUser = (e) => {
    e.preventDefault();
    switch (option) {
      case 'email':
        const emailSearch = {
          email: search.trim(),
        };
        searchForUserByEmail(emailSearch);
        break;

      case 'name':
        const nameSearch = {
          name: search.trim(),
          country,
        };
        searchByName(nameSearch);
        break;

      case 'phone':
        const phoneSearch = {
          phoneNumber: search.trim(),
          macAddress: '12',
        };
        searchByPhoneOrMac(phoneSearch);
        break;

      case 'macid':
        const macidSearch = {
          macAddress: search.trim(),
          phoneNumber: 'N/A',
        };
        searchByPhoneOrMac(macidSearch);
        break;
      default:
        return null;
    }
  };

  const getUserPassword = (customer) => {
    const data = {
      action: 'getCustomerPassword',
      username: customer.username,
    };
    getPassword(data);
  };

  const getUser = (customer) => {
    setMac(customer.username);
    setName(customer.name);
  };

  const getUserPayments = (customer) => {
    const data = {
      macid: customer.username,
    };
    getCustomerPayments(data);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-11 col-md-12 mt-5">
          <div className="mt-4">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 mt-3 mb-3">
                  <form onSubmit={searchForUser} className="form-inline inner-addon d-flex">
                    <div className="input-group mb-3 mr-3">
                      <select name="keyword" onChange={onOptionChange} className="form-control mr-auto empty mt-1">
                        <option value="">Choose fields to search from</option>
                        <option value="email">Email</option>
                        <option value="name">Name</option>
                        <option value="phone">Phone</option>
                        <option value="macid">MAC ID</option>
                      </select>
                    </div>

                    {displayCountryDropdown(option, onCountryChange)}

                    <div className="input-group mb-3 mr-3">
                      <input
                        className="form-control mr-auto empty pl-5"
                        type="text"
                        placeholder="search for user"
                        name="search"
                        value={search}
                        onChange={onSearchChange}
                      />
                      <span className="fa fa-search" />
                    </div>

                    <div className="mb-3">
                      <button className="btn btn-green" style={{ height: '39px' }}>
                        Search
                      </button>
                    </div>
                  </form>
                </div>
                <div className="card x-panel">
                  <div className="table-responsive content">
                    <table className="table copy-font">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>MAC ID</th>
                          <th nowrap="no-wrap">Name</th>
                          <th>Email</th>
                          <th>Show Password</th>
                          <th nowrap="no-wrap">Phone Number</th>
                          <th className="text-center">Extend</th>
                          <th nowrap="no-wrap">Expiry Date</th>
                          {/* <th className="text-center">Rollback</th> */}
                          <th style={{ paddingLeft: '24px' }}>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <small>loading...</small>
                        ) : (
                          customers.map((customer, i) => (
                            <tr>
                              <td>{i + 1}</td>
                              <td>{customer.username}</td>
                              <td nowrap="no-wrap">{customer.name}</td>
                              <td>{customer.mail}</td>
                              <td className="ml-2">
                                <button
                                  data-toggle="modal"
                                  data-target="#view-password"
                                  className="ml-3"
                                  onClick={() => getUserPassword(customer)}
                                >
                                  <i
                                    className="view1 fa fa-ellipsis-v"
                                    style={{
                                      fontSize: '20px',
                                    }}
                                  />
                                </button>
                                {/* {get ? <h1>loading</h1> : <PaymentHistory usersTransactions={this.state.transactions}/>} */}
                                {/* <PaymentHistory usersTransactions={this.state.transactions} get={get}/> */}
                                <ViewPassword password={password} load={gettingPassword} />
                              </td>
                              <td>{customer.address}</td>
                              <td
                                className="text-center img-pointer"
                                data-target="#extend"
                                onClick={() => getUser(customer)}
                                data-toggle="modal"
                                style={{ width: '40%' }}
                              />
                              <Extend username={mac} name={name} />
                              {/* <ExtendCustomer /> */}

                              {/* <td>{moment(customer.Expiration).format('Do MMMM YYYY')}</td> */}
                              <td>{customer.value}</td>
                              {/* {isNaN(Date.parse(customer.value)) ? <td>N/A</td> : <td>{customer.value}</td> } */}
                              <td className="ml-2">
                                <button
                                  data-toggle="modal"
                                  data-target="#payment-history"
                                  className="ml-3"
                                  onClick={() => getUserPayments(customer)}
                                >
                                  <i
                                    className="view1 fa fa-ellipsis-v"
                                    style={{
                                      fontSize: '20px',
                                    }}
                                  />
                                </button>
                                {/* <PaymentHistory /> */}
                                <PaymentsHistory load={gettingPayments} transactions={payments} />

                                <button
                                  type="submit"
                                  style={{
                                    border: '0',
                                    backgroundColor: 'white',
                                    width: '0%',
                                  }}
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  title="View"
                                  onClick={() => getUserDetails(customer.username)}
                                >
                                  <span className="view1 ml-4">
                                    <img src={view} alt="" data-toggle="modal" data-target="#cus-details" />
                                  </span>
                                </button>
                                <CustomerDetails
                                  load={gettingDetails}
                                  user={customer}
                                  address={address}
                                  details={details}
                                  session={session}
                                />
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <ToastContainer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewProductsCustomersTable;
