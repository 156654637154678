/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import GenerateInvoice from '../customers-invoice/GenerateInvoice';
import InvoiceParamsTable from '../customers-invoice/InvoiceParamsTable';
import SearchReference from './SearchReference';
import SearchInvoice from '../customers-invoice/SearchInvoice';

const borderStyle = 'form-control border border-info py-2';
const ExistingInvoiceForm = ({
  downloadInvoice,
  addToTable,
  searchInvoice,
  generateInvoice,
  handleChange,
  invoiceDetails,
  tableData,
}) => {
  const { customerName, customerAddress, customerReference, date, currency } = invoiceDetails;

  const { profile } = useUser();

  const salesPerson = `${profile.firstName} ${profile.lastName}`;

  return (
    <div>
      <form className="m-0 p-0" onSubmit={downloadInvoice}>
        <div className="d-flex">
          <label className="m-0 p-0 payslipfont text-left col-xl-2 col-md-2 col-sm-12 col-form-label" htmlFor="name">
            Customer Name
          </label>
          <div className="col-xl-5 col-md-5 col-sm-12 mb-2">
            <input
              type="text"
              className="form-control border border-info py-2"
              id="customerName"
              name="customerName"
              value={customerName}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="form-group m-0 p-0 row">
          <label className="m-0 p-0 payslipfont col-sm-2 text-left col-form-label" htmlFor="customerAddress">
            Address:
          </label>
          <div className="col-sm-5 mb-2">
            <input
              type="text"
              className={borderStyle}
              id="customerAddress"
              name="customerAddress"
              value={customerAddress}
              onChange={handleChange}
            />
          </div>
        </div>
        <SearchReference
          customerReference={customerReference}
          invoiceDetails={invoiceDetails}
          handleChange={handleChange}
        />
        {/* <div className="form-group m-0 p-0 row">
          <label className="m-0 p-0 payslipfont col-sm-2 text-left col-form-label" htmlFor="customerReference">
            Customer Reference:
          </label>
          <div className="col-sm-5 mb-2">
            <input
              type="text"
              className={borderStyle}
              id="customerReference"
              name="customerReference"
              value={customerReference}
              onChange={handleChange}
            />
          </div>
        </div> */}

        <div className="form-group m-0 p-0 row">
          <label className="m-0 p-0 col-sm-2 payslipfont text-left col-form-label" htmlFor="salesPerson">
            Sales Person:
          </label>
          <div className="col-sm-5 mb-2">
            <input
              type="text"
              className={borderStyle}
              id="salesPerson"
              name="salesPerson"
              value={salesPerson}
              onChange={handleChange}
            />
          </div>
        </div>

        <InvoiceParamsTable
          addToTable={addToTable}
          handleChange={handleChange}
          invoiceDetails={invoiceDetails}
          tableData={tableData}
        />

        <div className="col-12 d-flex justify-content-between">
          {/* <div className="d-flex">
            <label className="m-0 p-0 payslipfont col-form-label" htmlFor="receiptNumber">
              Receipt No:
            </label>
            <div className="mb-2">
              <input
                type="text"
                className={borderStyle}
                id="receiptNumber"
                name="receiptNumber"
                value={receiptNumber}
                onChange={handleChange}
              />
            </div>
          </div> */}

          <div className="d-flex">
            <label className="m-0 p-0 payslipfont col-form-label" htmlFor="currency">
              Currency:
            </label>
            <div className="mb-2">
              <div className="input-group">
                <select id="currency" name="currency" value={currency} onChange={handleChange} className={borderStyle}>
                  <option value="">--Select--</option>
                  <option value="NGN">NGN</option>
                  <option value="GHC">GHC</option>
                  <option value="USD">USD</option>
                </select>
              </div>
            </div>
          </div>

          <div className="d-flex">
            <label className="m-0 p-0 payslipfont col-form-label" htmlFor="date">
              Date:
            </label>
            <div className="mb-2">
              <input type="date" className={borderStyle} id="date" name="date" value={date} onChange={handleChange} />
            </div>
          </div>
        </div>

        <div className="text-right">
          <button className="btn btn-green mx-1" type="submit">
            Create Invoice
          </button>
        </div>
      </form>
      <SearchInvoice searchInvoice={searchInvoice} handleChange={handleChange} invoiceDetails={invoiceDetails} />
      <GenerateInvoice generateInvoice={generateInvoice} handleChange={handleChange} invoiceDetails={invoiceDetails} />
    </div>
  );
};

export default ExistingInvoiceForm;
