/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import TimePicker from 'react-time-picker';
import { Modal } from 'react-bootstrap';
import toastr from 'toastr';
import { useBandwidth } from '../../../../../hooks/partnership/bandwidth/useBandwidth';

const AddPartnershipBandwidth = ({ view, setView, addBandwidthSchedule }) => {
  const { load, getBandwidth, isLoading, getBandwidthBasestations, items, lists } = useBandwidth();
  const [one, setOne] = useState({
    base: '',
    ip_address: '',
    day: '',
    inst: '',
    time: '',
    // rx_speed: '',
    // tx_speed: '',
    // tx_totalaverage: '',
    // rx_totalaverage: '',
  });

  const { base, ip_address, inst, time, day } = one;

  const [schedule, setSchedule] = useState({});

  const install = [
    { name: 'Business', value: 'business' },
    { name: 'School', value: 'school' },
    { name: 'Event Center', value: 'event center' },
    { name: 'Youth Center', value: 'youth center' },
    { name: 'Church', value: 'church' },
    { name: 'Mosque', value: 'mosque' },
    { name: 'Pharmacy', value: 'pharmacy' },
  ];

  const days = [
    { name: 'Monday', value: 'monday' },
    { name: 'Tuesday', value: 'tuesday' },
    { name: 'Wednesday', value: 'wednesday' },
    { name: 'Thursday', value: 'thursday' },
    { name: 'Friday', value: 'friday' },
    { name: 'Saturday', value: 'saturday' },
    { name: 'Sunday', value: 'sunday' },
  ];

  const handleClose = () => {
    setView(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOne({ ...one, [name]: value });
  };

  const handleInstallChange = (e) => {
    const { name, value } = e.target;

    if (e.target.value === 'Church') {
      setOne({ ...one, time: '16:00', inst: value });
    } else if (e.target.value === 'Youth Center') {
      setOne({ ...one, time: '11:30', inst: value });
    } else if (e.target.value === 'Event Center') {
      setOne({ ...one, time: '11:30', inst: value });
    } else if (e.target.value === 'Business') {
      setOne({ ...one, time: '11:30', inst: value });
    } else if (e.target.value === 'School') {
      setOne({ ...one, time: '11:30', inst: value });
    } else if (e.target.value === 'Mosque') {
      setOne({ ...one, time: '16:00', inst: value });
    } else if (e.target.value === 'Pharmacy') {
      setOne({ ...one, time: '11:30', inst: value });
    }
  };
  const handleDayChange = (e) => {
    const { name, value } = e.target;

    if (e.target.value === 'sunday' && one.inst === 'Church') {
      setOne({ ...one, time: '13:00', day: value });
    } else if (e.target.value === 'friday' && one.inst === 'Mosque') {
      setOne({ ...one, time: '9:00', day: value });
    } else {
      setOne({ ...one, [name]: value });
    }
  };

  const handleBaseChange = (e) => {
    const { name, value } = e.target;
    setOne({ ...one, [name]: value });
    lists
      .filter((list, _) => {
        if (value === list.basestation) {
          return list;
        }
      })
      .map((list, i) => {
        setOne({ ...one, ip_address: list.ip, [name]: value });
      });
  };

  const handleAddSchedule = () => {
    if (!day || !time) {
      document.getElementById('schedule-errors').innerText = 'Day and Time are required';
      return;
    }
    setSchedule({ ...schedule, [day]: time });
    document.getElementById('schedule-errors').innerText = '';
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const data = {
      ...schedule,
      site_name: base,
      inst_type: inst,
      ip_address: ip_address,
    };

    if (!data.site_name || !data.ip_address || !(Object.entries(schedule).length > 0)) {
      toastr.error('Please select required fields');
      return;
    }

    await addBandwidthSchedule([data], setOne, setSchedule, handleClose);
  };

  useEffect(() => {
    getBandwidth();
    getBandwidthBasestations();
  }, []);

  const table = () => {
    return (
      <div className="table-responsive content col-12 my-4">
        <table className="table table-striped" style={{ fontSize: '.77rem' }}>
          <thead>
            <tr className="">
              <th className="text-nowrap">Monday</th>
              <th className="text-nowrap">Tuesday</th>
              <th className="text-nowrap"> Wednesday</th>
              <th className="text-nowrap"> Thursday</th>
              <th className="text-nowrap"> Friday</th>
              <th className="text-nowrap"> Saturday</th>
              <th className="text-nowrap">Sunday</th>
            </tr>
          </thead>
          <tbody>
            <tr className="">
              <td>{schedule['monday'] || 'N/A'}</td>
              <td>{schedule['tuesday'] || 'N/A'}</td>
              <td>{schedule['wednesday'] || 'N/A'}</td>
              <td>{schedule['thursday'] || 'N/A'}</td>
              <td>{schedule['friday'] || 'N/A'}</td>
              <td>{schedule['saturday'] || 'N/A'}</td>
              <td>{schedule['sunday'] || 'N/A'}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <Modal show={view} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ paddingLeft: '2rem' }}>Add Bandwidth</Modal.Title>
      </Modal.Header>
      {/* <div className="text-right mt-3 p-2">
        <button type="submit" className="btn btn-green py-2 px-5" onClick={shuffle}>
          Shuffle
        </button>
      </div> */}
      <form onSubmit={onSubmit} className="col-md-12">
        <div className="pt-2 d-flex">
          <div className="pr-3 form-group col-md-12">
            <label className="m-0 p-0 copy-font" htmlFor="basestation">
              Basestation
            </label>
            <select
              type="text"
              className="form-control border border-info py-2"
              id="basestation"
              name="base"
              value={base}
              onChange={handleBaseChange}
            >
              <option value=""> Select an Option </option>
              {items.length ? (
                items.map((base) => (
                  <option key={base.id} value={base.basestation}>
                    {base.basestation}
                  </option>
                ))
              ) : (
                <option>Loading</option>
              )}
            </select>
          </div>
        </div>
        <div className="pt-2 d-flex">
          <div className="pl-3 form-group col-md-12">
            <label className="m-0 p-0 copy-font" htmlFor="ip">
              IP address
            </label>
            <input
              type="text"
              className="form-control border border-info py-2"
              id="ip"
              name="ip_address"
              value={ip_address}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="pt-2 d-flex">
          <div className="pr-3 form-group col-md-12">
            <label className="m-0 p-0 copy-font" htmlFor="inst">
              Installation Type
            </label>
            <select
              type="text"
              className="form-control border border-info py-2"
              id="inst"
              name="inst"
              value={inst}
              onChange={handleInstallChange}
            >
              <option value=""> Select an Option </option>
              {install.length ? (
                install.map((ins, i) => (
                  <option value={ins.name} key={i}>
                    {ins.value}
                  </option>
                ))
              ) : (
                <option>Loading</option>
              )}
            </select>
          </div>
        </div>

        <div>
          <div className="pt-2 d-flex">
            {inst ? (
              <div className=" form-group col-6">
                <label className="m-0 p-0 copy-font" htmlFor="day">
                  Day
                </label>
                <select
                  type="text"
                  className="form-control border border-info py-2"
                  id="day"
                  name="day"
                  value={day}
                  onChange={handleDayChange}
                >
                  <option value=""> Select an Option </option>
                  {days.length ? (
                    days.map((day, i) => (
                      <option value={day.value} key={i}>
                        {day.name}
                      </option>
                    ))
                  ) : (
                    <option>Loading</option>
                  )}
                </select>
              </div>
            ) : (
              <p className="red"> Select Installation Type First</p>
            )}

            <div className=" form-group col-6">
              <label className="m-0 p-0 copy-font" htmlFor="day">
                Time
              </label>
              <input
                type="time"
                id="time"
                name="time"
                value={time}
                onChange={handleChange}
                className="form-control border border-info p-2"
              />
            </div>
          </div>

          <div className="text-center">
            <button type="button" className="btn btn-secondary py-1 px-2" onClick={handleAddSchedule}>
              Add Schedule
            </button>

            <p className="my-1" id="schedule-errors" style={{ color: 'red', fontSize: '1.1rem' }}></p>
          </div>
        </div>

        {table()}

        <div className="text-center my-3">
          {isLoading ? (
            <button type="button" className="btn btn-green py-2 px-5 p-28" disabled>
              Submitting...
            </button>
          ) : (
            <button type="submit" className="btn btn-green py-2 px-5 p-2">
              Submit
            </button>
          )}
        </div>
      </form>
    </Modal>
  );
};

export default AddPartnershipBandwidth;
