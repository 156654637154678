/* eslint-disable import/prefer-default-export */
import * as load from '../../../constants/types/shared/LoadingTypes';
import { GET_KPIS } from '../../../constants/types/noc/NocTypes';

export const kpiReducer = (state, action) => {
  const { payload, type } = action;
  switch (type) {
    case load.LOADING_STARTS:
      return {
        ...state,
        isLoading: true,
      };

    case load.LOADING_STOPS:
      return {
        ...state,
        isLoading: false,
      };

    case GET_KPIS:
      return {
        ...state,
        isLoading: false,
        kpis: payload,
      };

    default:
      return state;
  }
};
