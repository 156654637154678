/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable no-shadow */
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AddDedicated from './AddDedicated';
import DedicatedCustomerTable from './DedicatedCustomerTable';
import {
  getDedicatedCustomers,
  getDedicatedCustomersByAP,
  getDedicatedCustomersByName,
  paginateDedicatedCustomers,
} from '../../../../actions/noc/dedicated-customers/DedicatedCustomersActions';
import { NOC } from '../../../../utils/config';

class DedicatedCustomersBody extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalShow: false,
    };

    this.handlePageClick = this.handlePageClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
  }

  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.getDedicatedCustomers();
  }

  getModalStatus(i) {
    this.setState(
      {
        modalShow: !i,
      },
      () => {
        // eslint-disable-next-line react/destructuring-assignment
        if (this.state.modalShow === true) {
          // eslint-disable-next-line react/destructuring-assignment
          this.setState({
            modalShow: !this.state.modalShow,
          });
        }
      },
    );
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  onKeyPress = (e) => {
    const { getDedicatedCustomersByAP, getDedicatedCustomersByName } = this.props;

    if (e.which === 13) {
      if (
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
          e.target.value.trim(),
        )
      ) {
        const data = {
          ap: e.target.value.trim(),
        };

        getDedicatedCustomersByAP(data);
      } else {
        const data = {
          name: e.target.value.trim(),
        };

        getDedicatedCustomersByName(data);
      }
    }
  };

  handlePageClick = (pageNumber) => {
    const selected = pageNumber.selected + 1;

    this.props.paginateDedicatedCustomers(selected);
  };

  render() {
    const { modalShow } = this.state;
    const { loading, customers } = this.props;

    const { dedicated, pageCount } = customers;
    // console.log(typeof customers);

    return (
      // <div className="page-wrapper col-lg-10">
      <div className="container-fluid">
        <div className="row">
          {/* <div className="col-lg-12 mt-3 mb-3 d-flex inner-addon left-addon"> */}
          <div className="mt-2 d-flex flex-wrap col-lg-12 col-md-12 col-sm-12">
            {/* <form className="form-inline w-100 inner-addon left-addon" action="#"> */}
            <div className="mt-2 input-group col-lg-9 col-md-10 col-sm-12">
              <div className="input-group-prepend">
                <span className="input-group-text fa fa-search" />
              </div>
              <input
                className="form-control"
                type="text"
                value={this.state.word}
                onChange={this.onChange}
                onKeyPress={this.onKeyPress}
                placeholder="Search using AP or customer's name"
              />
            </div>

            {sessionStorage.getItem('departmentId') !== `${NOC}` ? null : (
              <div className="ml-3 mt-2">
                <button
                  className="btn btn-green"
                  type="submit"
                  onClick={() => {
                    this.setState(
                      {
                        modalShow: !this.state.modalShow,
                      },
                      () => {},
                    );
                  }}
                >
                  Add Dedicated Customer
                </button>
              </div>
            )}

            <div className={!modalShow ? 'nodisp' : ' modal-backdrop fade show'} />
            <div
              id="addDedicated"
              // className="modal fade in"
              className={modalShow ? 'modal fade in show d-block' : 'nodisp'}
              // tabIndex="-1"
              // role="dialog"
              // aria-labelledby="myModalLabel"
              // aria-hidden="true"
              // style={{ overflow: 'auto' }}
              data-backdrop="false"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title text-lg-left ml-5 pl-5" id="myModalLabel">
                      Add Dedicated Customer
                    </h4>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      onClick={() => {
                        // if(this.state.modalShow === false) {

                        this.setState(
                          {
                            modalShow: !this.state.modalShow,
                          },
                          () => {},
                        );
                      }}
                      aria-hidden="true"
                    >
                      ×
                    </button>
                  </div>

                  {/* eslint-disable-next-line react/jsx-no-bind */}
                  <AddDedicated modalshow={this.getModalStatus.bind(this)} />
                </div>
              </div>
            </div>
          </div>

          <div className="card x-panel mt-3">
            <div className="table-responsive content">
              <table className="table copy-font">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>AP</th>
                    <th>Device</th>
                    <th>Password</th>
                    {/* <th>Expiration Date</th>
                                                <th nowrap="no-wrap">Device</th> */}
                    <th>Actions</th>
                  </tr>
                </thead>

                <DedicatedCustomerTable customers={dedicated} load={loading} />
                {/* <tbody>
                                            {this.displayLogs()}
                                            </tbody> */}
              </table>
            </div>
          </div>
        </div>
        <div className="pagination pull-right">
          <ReactPaginate
            previousLabel="previous"
            nextLabel="next"
            breakLabel="..."
            breakClassName="break-me"
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={this.handlePageClick}
            containerClassName="pagination"
            subContainerClassName="pages pagination"
            activeClassName="active"
          />
        </div>
      </div>
      // {/* </div> */}
    );
  }
}

const mapStateToProps = (state) => ({
  customers: state.dedicatedCustomers.dedicated,
  loading: state.dedicatedCustomers.load,
});

DedicatedCustomersBody.propTypes = {
  getDedicatedCustomersByAP: PropTypes.func,
  getDedicatedCustomersByName: PropTypes.func,
  getDedicatedCustomers: PropTypes.func,
  loading: PropTypes.bool,
};
export default connect(
  mapStateToProps,
  {
    getDedicatedCustomersByAP,
    getDedicatedCustomersByName,
    getDedicatedCustomers,
    paginateDedicatedCustomers,
  },
)(DedicatedCustomersBody);
