/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect } from 'react';
import { useVehicles } from '../../../../../hooks/accounts/useVehicles';
import { ACCOUNTS, PROCUREMENT } from '../../../../../utils/departments/helpers';
import { filename, VehicleHeaders } from '../../../../../utils/field-support/installations/helpers';
import CustomCsvDownload from '../../../shared/CustomCsvDownload';
import AddVehicleForm from '../../../shared/vehicle/AddVehicleForm';
import AccountVehiclesTable from './AccountVehiclesTable';

const AccountVehiclesBody = () => {
  const { vehicles, isLoading, fetchVehicles, setVehicles } = useVehicles();

  const [keyword, setKeyword] = useState('');
  const [page] = useState(1);
  const departmentId = sessionStorage.getItem('departmentId');

  useEffect(() => {
    fetchVehicles();
  }, []);

  return (
    <div className="col-12 p-0 after-summary pt-5">
      <div className="row mx-2">
        <div className="d-flex justify-content-between align-items-center">
          <div className="input-group mb-3">
            <div className="input-group-text">
              <span className="fa fa-search" />
            </div>
            <input
              value={keyword}
              onChange={(event) => setKeyword(event.target.value)}
              className="form-control mr-sm-2 empty mt-1"
              // id="ic"
              type="text"
              placeholder="Search by plate number, engine number, model, chasis number and color"
            />
          </div>
          {departmentId === PROCUREMENT || departmentId === ACCOUNTS  &&  (
            <button
              type="button"
              data-toggle="modal"
              data-placement="left"
              className="btn btn-green mb-3"
              data-target="#addVehicle"
            >
              <span className="view">Add Vehicles</span>
            </button>
          )}
        </div>
        <div className="col-12 d-flex">
          <CustomCsvDownload
            data={vehicles || []}
            headers={VehicleHeaders}
            filename={filename(`Vehicles`)}
            classname="btn btn-green shadow-md"
            textcolor="white"
            text="Download Data"
          />
        </div>

        <div className="card x-panel p-0">
          <AccountVehiclesTable lists={vehicles} isLoading={isLoading} keyword={keyword} />

          {/* <div className="px-3">
            {pages > 1 ? (
              <ReactPaginate
                previousLabel="previous"
                nextLabel="next"
                breakLabel="..."
                breakClassName="break-me"
                pageCount={pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName="pagination"
                // subContainerClassName="pages pagination"
                activeClassName="active"
              />
            ) : null}
          </div> */}
        </div>
      </div>
      <AddVehicleForm />
    </div>
  );
};

export default AccountVehiclesBody;
