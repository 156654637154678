/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import helpers from '../../../../../utils/helpers';
import edit from '../../../../../assets/img/icons/edit.png';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../../utils/loader/loadingHelper';
import relocation_helpers from '../../../../../utils/relocation/relocation_helpers';
import EditReferral from './EditReferral';

const ReferralTable = ({ lists, isLoading }) => {
  const colSpan = '8';
  const [request, setRequest] = useState({
    email: '',
    name: '',
    address: '',
    phoneNumber: '',
    id: '',
    cin: '',
    created_at: '',
    EmployeeAuth: '',
    status: '',
    user: { firstName: '', lastName: '' },
  });
  const getRequest = (item) => setRequest(item);

  const tableData =
    lists && lists.length ? (
      lists.map((list, i) => {
        return (
          <tr className="" key={i}>
            <td>{i + 1}</td>
            <td className="text-nowrap">{`${list.name}`}</td>
            <td>{list.phoneNumber}</td>
            <td>{list.email}</td>
            <td className="text-nowrap">{list.address}</td>
            <td>{relocation_helpers.relocationStatusLabels(list.status)}</td>
            <td>{list.cin || 'N/A'}</td>
            <td>{helpers.formatDateAndTime(list.created_at)}</td>
            <td className="text-nowrap">
              {list.user.firstName} {list.user.lastName}
            </td>
            <td>
              {list.EmployeeAuth && list.EmployeeAuth.employees.length
                ? `${list.EmployeeAuth.employees[0].firstName} ${list.EmployeeAuth.employees[0].lastName}`
                : 'N/A'}
            </td>
            <td>
              <div className="d-flex">
                <button
                  type="submit"
                  className="mx-1 noBorder"
                  data-toggle="modal"
                  data-placement="left"
                  data-target="#editReferral"
                  onClick={() => getRequest(list)}
                >
                  <span className="view">
                    <img src={edit} alt="" data-toggle="tooltip" data-placement="left" title="Edit" />
                  </span>
                </button>
              </div>
            </td>
            <EditReferral request={request} />
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan={colSpan} text="There are no requests for referral" />
    );

  return (
    <div className="table-responsive content col-12">
      <table className="table table-striped">
        <thead>
          <tr className="">
            <th> S/N</th>
            <th>Name</th>
            <th className="text-nowrap">Phone Number</th>
            <th className="text-nowrap">Email</th>
            <th className="text-nowrap"> Address</th>
            <th className="text-nowrap"> Status</th>
            <th className="text-nowrap"> Customer Id No</th>
            <th className="text-nowrap">Created At</th>
            <th className="text-nowrap">Customers Name</th>
            <th className="text-nowrap">Closed By</th>
            <th className="text-nowrap">Action</th>
          </tr>
        </thead>
        <tbody>{isLoading ? <TizetiGIFLogoTable colSpan={colSpan} /> : tableData}</tbody>
      </table>
    </div>
  );
};

export default ReferralTable;
