/* eslint-disable import/prefer-default-export */
import * as types from '../../../constants/types/wificall/WificallTypes';
import * as load from '../../../constants/types/shared/LoadingTypes';

export const loginLogsReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case load.LOADING_STARTS:
      return {
        ...state,
        isLoading: true,
      };

    case load.LOADING_STOPS:
      return {
        ...state,
        isLoading: false,
      };

    case types.GET_LOGIN_LOGS:
      return {
        ...state,
        users: payload.users,
        pages: payload.pages,
        count: payload.count,
        isLoading: false,
      };

    default:
      return state;
  }
};
