import axios from 'axios';
import toastr from 'toastr';
import { toast } from 'react-toastify';
import {
  ADD_CALL_LOG_URL,
  GET_ALL_CALL_LOGS_URL,
  GET_CALL_LOGS_BY_PARAMS,
  GET_CALL_LOGS_BY_STAFF,
} from '../../../services/api/shared/call-logs/CallLogsUrl';
import { ERROR_ADDING_CALL_LOG, GET_CALL_LOGS } from '../../../constants/types/shared/call-logs/CallLogsTypes';
import { GET_EMPLOYEES_IN_SALES_URL } from '../../../services/api/sales/logs/LogsUrl';
import { GET_EMPLOYEES_BY_DEPARTMENT } from '../../../constants/types/shared/employees/EmployeesTypes';

export const getCallLogs = () => async (dispatch) => {
  try {
    const response = await axios.get(`${GET_ALL_CALL_LOGS_URL}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      dispatch({
        type: GET_CALL_LOGS,
        payload: {
          pages: response.data.pages,
          logs: response.data.logs,
        },
      });
    } else {
      toastr.info('Somethign went wrong! Please try again!');
    }
  } catch (e) {}
};

export const addCallLog = (data) => async (dispatch) => {
  try {
    const response = await axios.post(`${ADD_CALL_LOG_URL}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data.id) {
      toast.success('Call Log Added!');
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      dispatch({
        type: ERROR_ADDING_CALL_LOG,
        payload: {
          message: response.data.message,
          status: 'error',
        },
      });
    }
    // window.location.reload();
  } catch (e) {
    toastr.info('Something went wrong!');
  }
};

export const getEmployeesInSales = () => async (dispatch) => {
  try {
    const response = await axios.get(`${GET_EMPLOYEES_IN_SALES_URL}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (response.data.status === 'success') {
      dispatch({
        type: GET_EMPLOYEES_BY_DEPARTMENT,
        payload: response.data.data,
      });
    }
  } catch (e) {
    // console.log(e.response);
  }
};

export const getCallLogsByParams = (data, date) => async (dispatch) => {
  const { fromParams, toParams } = date;

  try {
    const response = await axios.post(`${GET_CALL_LOGS_BY_PARAMS}=${fromParams}&to=${toParams}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      dispatch({
        type: GET_CALL_LOGS,
        payload: {
          pages: response.data.pages,
          logs: response.data.logs,
        },
      });
    } else {
      toastr.info('No result!');
    }
  } catch (e) {}
};

export const getCallLogsByStaff = (data, date) => async (dispatch) => {
  const { staff } = data;

  const { fromStaff, toStaff } = date;

  try {
    const response = await axios.get(`${GET_CALL_LOGS_BY_STAFF}/${staff}?from=${fromStaff}&to=${toStaff}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      dispatch({
        type: GET_CALL_LOGS,
        payload: {
          // pages: response.data.pages,
          logs: response.data.rows,
        },
      });
    }
  } catch (e) {}
};
