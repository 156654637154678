import {
  GET_ROLES,
  SIGN_UP,
} from '../../../../constants/types/SignUpTypes';

const initialState = {
  isAuthenticated: false,
  user: {},
  roles: [],
};

const SignUpReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ROLES:
      return {
        ...state,
        roles: action.payload,
      };

    case SIGN_UP:
      // get the token
      return {
        ...state,
        user: action.payload,
      };

    default:
      return state;
  }
};

export default SignUpReducer;
