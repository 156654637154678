/* eslint-disable import/prefer-default-export */
import * as types from '../../../../constants/types/asset-team/AssetTeamTypes';
import * as load from '../../../../constants/types/shared/LoadingTypes';

export const expiredReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case load.LOADING_STARTS:
      return {
        ...state,
        isLoading: true,
        customers: [],
      };

    case load.LOADING_STOPS:
      return {
        ...state,
        isLoading: false,
        customers: [],
      };

    case types.GET_EXPIRED:
      return {
        ...state,
        time: payload.time,
        items: payload.items,
        total: payload.total,
        isLoading: false,
      };

    case types.GET_EXPIRED_USERS:
      return {
        ...state,
        customers: payload,
        isLoading: false,
      };

    default:
      return state;
  }
};
