import axios from 'axios';
import { toast } from 'react-toastify';
import {
  SEARCH_CUSTOMER_USAGE_WITH_EMAIL_URL,
  SEARCH_CUSTOMER_USAGE_WITH_MAC_URL,
  SEARCH_CUSTOMER_USAGE_WITH_NAME_URL,
  SEARCH_CUSTOMER_USAGE_WITH_PHONE_URL,
} from '../../../../services/api/shared/customers/CustomersUrl';
import { LOADING_STARTS, LOADING_STOPS } from '../../../../constants/types/shared/LoadingTypes';
import {
  GET_CUSTOMER_USAGE_BY_DATE,
  GET_USAGE_HISTORY,
  GETTING_BY_DATE_STARTS,
  GETTING_BY_DATE_STOPS,
} from '../../../../constants/types/shared/customers/UsageHistoryTypes';
import { GET_CUSTOMER_USAGE_URL } from '../../../../services/api/customer-support/customers/CustomersUrl';

export const searchCustomersUsageWithEmail = (data) => async (dispatch) => {
  dispatch({
    type: LOADING_STARTS,
    payload: true,
  });

  try {
    const response = await axios.post(`${SEARCH_CUSTOMER_USAGE_WITH_EMAIL_URL}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data === 'customer info could not be retreived') {
      dispatch({
        type: LOADING_STOPS,
        payload: false,
      });

      toast.info(response.data);
    } else {
      // var responseData = res.data;
      // var customData = []
      //
      // for(var i in responseData){
      //   customData.push({
      //     username: responseData[i].username,
      //     name : responseData[i].name,
      //     mail : responseData[i].mail,
      //     address : responseData[i].address,
      //     UserPassword : responseData[i].value,
      //     Expiration : responseData[i].value,
      //
      //   })
      //
      // }

      // this.setState({
      //
      //   customers: res.data,
      //   filteredCustomer : customData,
      //   loading: false
      //
      // });

      dispatch({
        type: GET_USAGE_HISTORY,
        payload: response.data,
      });
    }
  } catch (e) {
    dispatch({
      type: LOADING_STOPS,
      payload: false,
    });
  }
};

export const searchCustomersUsageWithPhone = (data) => async (dispatch) => {
  dispatch({
    type: LOADING_STARTS,
    payload: true,
  });

  try {
    const response = await axios.post(`${SEARCH_CUSTOMER_USAGE_WITH_PHONE_URL}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      }
    });

    if (response.data === 'customers info could not be retreived') {
      dispatch({
        type: LOADING_STOPS,
        payload: false,
      });

      toast.info(response.data);
    } else {
      // var responseData = res.data;
      // var customData = []
      //
      // for(var i in responseData){
      //   customData.push({
      //     username: responseData[i].username,
      //     name : responseData[i].name,
      //     mail : responseData[i].mail,
      //     address : responseData[i].address,
      //     UserPassword : responseData[i].value,
      //     Expiration : responseData[i].value,
      //
      //   })
      //
      // }
      //
      // this.setState({
      //   customers: res.data,
      //   filteredCustomer : customData,
      //   loading: false
      // })

      dispatch({
        type: GET_USAGE_HISTORY,
        payload: response.data,
      });
    }
  } catch (e) {
    dispatch({
      type: LOADING_STOPS,
      payload: false,
    });
  }
};

export const searchCustomersUsageWithName = (data) => async (dispatch) => {
  dispatch({
    type: LOADING_STARTS,
    payload: true,
  });

  try {
    const response = await axios.post(`${SEARCH_CUSTOMER_USAGE_WITH_NAME_URL}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data.status === false) {
      dispatch({
        type: LOADING_STOPS,
        payload: false,
      });

      toast.info(`${response.data.message} ` + `${data.name}`);
    } else {
      dispatch({
        type: GET_USAGE_HISTORY,
        payload: response.data,
      });
    }
  } catch (e) {
    dispatch({
      type: LOADING_STOPS,
      payload: false,
    });
  }
};

// customer support and super-admin
export const searchCustomerUsageWithMacID = (data) => {
  return async (dispatch) => {
    dispatch({
      type: LOADING_STARTS,
      payload: true,
    });

    try {
      const response = await axios.post(`${SEARCH_CUSTOMER_USAGE_WITH_MAC_URL}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data) {
        dispatch({
          type: GET_USAGE_HISTORY,
          payload: response.data,
        });
      } else {
        dispatch({
          type: LOADING_STOPS,
          payload: false,
        });
      }
    } catch (e) {
      if (e) {
        dispatch({
          type: LOADING_STOPS,
          payload: false,
        });
      }
    }
  };
};

export const getCustomerUsageHistoryWithDate = (data) => async (dispatch) => {
  dispatch({
    type: GETTING_BY_DATE_STARTS,
    payload: true,
  });
  console.log(data);

  try {
    const response = await axios.post(`${GET_CUSTOMER_USAGE_URL}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    console.log(response.data);

    if (response.data.status === true && typeof response.data === 'object') {
      dispatch({
        type: GET_CUSTOMER_USAGE_BY_DATE,
        payload: response.data.items,
      });
    } else {
      toast.info('Something went wrong!');
      dispatch({
        type: GETTING_BY_DATE_STOPS,
        payload: false,
      });
    }
  } catch (e) {
    dispatch({
      type: GETTING_BY_DATE_STOPS,
      payload: false,
    });
  }
};
