/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
// import { getStates } from '../../../../../actions/shared/states/StatesActions';
import { convertToUser } from '../../../../../actions/sales/logs/LogsActions';

class Convert extends Component {
  static propTypes = {
    // getStates: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    // states: PropTypes.array.isRequired,
    // states: PropTypes.object.isRequired,
    convertToUser: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      state: '',
      referral: '',
    };
  }

  // componentDidMount() {
  //   // eslint-disable-next-line react/destructuring-assignment
  //   this.props.getStates();
  // }

  convertToLead = (e) => {
    e.preventDefault();

    const { user } = this.props;

    const data = {
      status: 'lead',
    };

    // edit user
    this.props.convertToUser(user, data);
  };

  render() {
    const { user, load } = this.props;
    const { firstName, lastName, address, email, phoneNumber, alternate_phone_number } = user;

    return (
      <div
        id="convert"
        className="modal fade in"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center" id="myModalLabel">
                Convert to User
              </h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
            </div>

            <div className="modal-body">
              <form className="form-horizontal" onSubmit={this.convertToLead}>
                <div className="form-group add-log">
                  <div className="col-md-12 mb-1 d-flex">
                    <div className="col-lg-6 mb-3 text-left">
                      <label className="copy-font">First Name</label>
                      <input
                        type="text"
                        name="firstName"
                        defaultValue={firstName}
                        className="form-control"
                        placeholder="First Name"
                        readOnly
                      />
                    </div>

                    <div className="col-lg-6 mb-3 text-left">
                      <label className="copy-font m-0 ">Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="last"
                        placeholder="Last Name"
                        defaultValue={lastName}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="col-md-12 mb-3 d-flex">
                    <div className="col-lg-6 text-left">
                      <label className="copy-font">Email address</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email address"
                        name="email"
                        readOnly
                        defaultValue={email}
                      />
                    </div>

                    <div className="col-lg-6 text-left">
                      <label className="copy-font">Phone Number</label>
                      <input
                        type="text"
                        name="phone"
                        className="form-control"
                        placeholder="Phone Number"
                        defaultValue={phoneNumber}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="col-md-12 mb-3 d-flex">
                    <div className="col-lg-6 text-left">
                      <label className="copy-font">Address</label>
                      <input
                        type="text"
                        name="phone"
                        className="form-control"
                        placeholder="Address"
                        defaultValue={address}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  {load ? (
                    <button type="submit" disabled className="btn btn-second waves-effect">
                      Converting...
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-second waves-effect">
                      Convert
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // states: state.states.states,
  load: state.salesLogs.load,
});

export default connect(
  mapStateToProps,
  {
    // getStates,
    convertToUser,
  },
)(Convert);
