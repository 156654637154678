/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import AddFrequencyLog from './AddFrequencyLog';
import { API_URL } from '../../../../../utils/config';
import FrequencyLogTable from './FrequencyLogTable';
import NocNavBar from '../../../../layouts/noc/NocNavBar';

class NetworkOperationsFrequencyLog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      frequency: [],
      loading: false,
      user: '',
      filtered: [],
      offset: 0,
      role: '',
    };

    this.handlePageClick = this.handlePageClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
  }

  componentWillMount() {
    this.getFrequency();
    this.getUserDetails();
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  onKeyPress = (e) => {
    if (e.which === 13) {
      const data = {
        ip_address: e.target.value,
      };

      const url = `${API_URL}/frequency/ssid_list/1`;

      axios({
        method: 'post',
        url,
        data,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      }).then((res) => {
        this.setState({
          filtered: res.data.rows,
          pageCount: res.data.count,
        });
      });
    }
  };

  getUserDetails() {
    axios({
      method: 'get',
      url: `${API_URL}/employee/loggedOnEmployee`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
      .then((res) => {
        if (res.data) {
          this.setState({
            user: res.data.firstName,
          });
        }
      })
      .catch(() => {});
  }

  getFrequency() {
    this.setState({
      loading: true,
    });

    axios({
      method: 'get',
      url: `${API_URL}/frequency/logs/1`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
      .then((response) => {
        // console.log(response.data);
        if (response.data) {
          this.setState({
            frequency: response.data.logs,
            filtered: response.data.logs,
            loading: false,
            pageCount: response.data.pages,
          });
        }
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  }

  handlePageClick = (data) => {
    this.setState({
      loading: true,
    });

    const selected = data.selected + 1;
    const url = `${API_URL}/frequency/logs/${selected}`;

    axios({
      method: 'get',
      url,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    }).then((res) => {
      if (res.data) {
        this.setState({
          frequency: res.data.logs,
          filtered: res.data.logs,
          loading: false,
          pageCount: res.data.pages,
        });
      }
    });
  };

  searchFrequencyLog = (e) => {
    if (e.which === '13') {
      const word = e.target.value;
    }
    /* const word = e.target.value;

    if (word !== '') {
      const list = this.state.frequency.filter(log => {
        if (log.access_point_ssid.toLowerCase().indexOf(word) !== -1) {
          return log;
        }

        if (log.ip_address.indexOf(word) !== -1) {
          return log;
        }

        if (log.date.indexOf(word) !== -1) {
          return log;
        }
      });

      this.setState({
        filtered: list,
        word: e.target.value
      });
    } else {
      this.setState({
        filtered: this.state.frequency,
        word
      });
    } */
  };

  render() {
    const { user, loading, filtered } = this.state;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <NocNavBar currentPage="Frequency Logs" />

          <div className="container">
            <div className="row mt-5">
              <div className="mt-4 d-flex flex-wrap col-lg-12 col-md-12 col-sm-12">
                <div className="mt-3 input-group col-lg-9 col-md-10 col-sm-12">
                  <div className="input-group-prepend">
                    <span className="input-group-text fa fa-search" />
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    value={this.state.word}
                    onChange={this.onChange}
                    onKeyPress={this.onKeyPress}
                    placeholder="Search IP Address"
                  />
                </div>

                <div className="mt-3 ml-3">
                  <button className="btn btn-green" data-toggle="modal" data-target="#addlog" type="button">
                    {' '}
                    Add Frequency log
                  </button>
                  <AddFrequencyLog />
                </div>
              </div>

              <div className="card x-panel mt-3">
                <div className="table-responsive content">
                  <table className="table copy-font">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Access Point SSID</th>
                        <th>IP Address</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Frequency</th>
                        <th nowrap="no-wrap">New Frequency</th>
                        <th>Employee</th>
                        <th className="pl-2">Comments</th>
                      </tr>
                    </thead>
                    <FrequencyLogTable load={loading} frequent={filtered} />
                  </table>
                </div>
              </div>
            </div>
            <ReactPaginate
              previousLabel="Previous"
              nextLabel="Next"
              breakLabel="..."
              breakClassName="break-me"
              pageCount={this.state.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName="pagination"
              subContainerClassName="pages pagination"
              activeClassName="active"
            />
          </div>
        </main>
      </div>
    );
  }
}

export default NetworkOperationsFrequencyLog;
