import React from 'react';
import helpers from '../../../../../../utils/helpers';

const LteTableData = ({ data }) => {
  const displayData =
    data.length && data
      ? data.map((customer, i) => (
          <tr>
            <td>{i + 1}</td>
            <td>
              <div>{customer.customer_name}</div>
            </td>
            <td>
              <div>{customer.customer_mobile}</div>
            </td>
            <td>
              <div>{customer.customer_email}</div>
            </td>
            <td>
              <div>{customer.calledstation}</div>
            </td>
            <td>
              <div>{customer.country}</div>
            </td>
            <td>
              <div>{customer.zone}</div>
            </td>
            <td>
              <div>{customer.region}</div>
            </td>
            <td>
              <div>{customer.agent}</div>
            </td>
            <td>
              <div>{customer.expired_date}</div>
            </td>
            <td>{helpers.formatDateAndTime(customer.installation_date)}</td>
          </tr>
        ))
      : 'no expired customers';
  return <tbody id="reduce-length">{displayData}</tbody>;
};

export default LteTableData;
