/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-case-declarations */
import { useReducer, useState } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import * as load from '../../../constants/types/shared/LoadingTypes';
import * as types from '../../../constants/types/express-wifi/ExpressWifiTypes';
import { XWF_CUSTOMERS_URL } from '../../../services/api/express-wifi/ExpressWifiURL';
import { XWFCustomersReducer } from '../../../reducers/express-wifi/customers/XWFCustomersReducer';

const useXWFCustomers = () => {
  const [{ customers, pages, isLoading, searchCustomersToggle }, dispatch] = useReducer(XWFCustomersReducer, {
    customers: [],
    pages: 1,
    isLoading: false,
    searchCustomersToggle: false,
  });

  const detailsState = { option: '', optionValue: '', fromDate: '', toDate: '' };

  const [details, setDetails] = useState(detailsState);

  const { option, optionValue, fromDate, toDate } = details;

  const onOptionChange = (e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };

  const getXWFCustomers = async (page) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.get(`${XWF_CUSTOMERS_URL}/${page}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.status === 'success') {
        const { result } = response.data;
        dispatch({
          type: types.GET_EXPRESSWIFI_CUSTOMERS,
          payload: {
            customers: result.data,
            count: result.count,
            pages: result.pages,
          },
        });
      }
    } catch (e) {
      toastr.info('Error Fetching Customers!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const searchByCustomerDetails = async (page, params) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(`${XWF_CUSTOMERS_URL}/${page}`, params, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.data) {
        dispatch({
          type: types.SEARCH_EXPRESSWIFI_CUSTOMERS,
          payload: {
            customers: response.data.data,
            count: response.data.count,
            pages: response.data.pages,
          },
        });
      }
    } catch (e) {
      toastr.info('Error Searching Customers!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const searchCustomers = (page) => {
    switch (option) {
      case 'companyName':
        const companyNameSearch = { company_name: optionValue.trim() };
        return searchByCustomerDetails(page, companyNameSearch);
      case 'customerName':
        const customerNameSearch = { customer_name: optionValue.trim() };
        return searchByCustomerDetails(page, customerNameSearch);
      case 'retailerName':
        const retailerNameSearch = { retailer_name: optionValue.trim() };
        return searchByCustomerDetails(page, retailerNameSearch);
      case 'phoneNumber':
        const phoneNumberSearch = { phoneNumber: optionValue.trim() };
        return searchByCustomerDetails(page, phoneNumberSearch);
      case 'duration':
        const durationSearch = { duration: optionValue.trim() };
        return searchByCustomerDetails(page, durationSearch);
      case 'date':
        const dateSearch = { from: fromDate.trim(), to: toDate.trim() };
        return searchByCustomerDetails(page, dateSearch);
      default:
        return null;
    }
  };

  const updateCustomerDetails = async (id, params) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.put(`${XWF_CUSTOMERS_URL}/${id}`, params, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data[0] === 1) {
        toastr.success('Update Successful!');
        setTimeout(() => window.location.reload(), 1000);
      } else toastr.warn('Unable To Update');
    } catch (e) {
      toastr.info('Error Updating Customer...');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const uploadCustomerDetails = async (uploadedFile) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(`${XWF_CUSTOMERS_URL}/upload`, uploadedFile, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        toastr.success('Uploaded Successfully!');
        setTimeout(() => window.location.reload(), 1000);
      } else toastr.warn('Unable To Upload');
    } catch (e) {
      toastr.info('Error Uploading Customer Details...');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    if (searchCustomersToggle) return searchCustomers(selectedPage);
    return getXWFCustomers(selectedPage);
  };

  return {
    customers,
    pages,
    isLoading,
    getXWFCustomers,
    searchByCustomerDetails,
    searchCustomers,
    handlePageClick,
    details,
    onOptionChange,
    updateCustomerDetails,
    uploadCustomerDetails,
  };
};

export default useXWFCustomers;
