import React from 'react';

const DevicesTableHead = () => {
  return (
    <thead>
      <tr>
        <th>#</th>
        <th>name</th>
        <th>mac</th>
        <th>Config.variables.DISPLAY_NAME</th>
        <th>Config.variables.LATITUDE</th>
        <th>Config.variables.LONGITUDE</th>
        <th>Config.version</th>
        <th>Country</th>
        <th>Description</th>
        <th>Hardware Vversion</th>
        <th>ip</th>
        <th>location.gps</th>
        <th>location.coordinates</th>
        <th>location.type</th>
        <th>managed_account</th>
        <th>msn</th>
        <th>network</th>
        <th>network</th>
        <th>product</th>
        <th>registration_date</th>
        <th>.software_version</th>
        <th>inactive_software_version</th>
        <th>status</th>
        <th>status_time</th>
        <th>last_reboot_reason</th>
        <th>tower</th>
        <th>Type</th>
      </tr>
    </thead>
  );
};

export default DevicesTableHead;
