import {
  GET_ACCOUNTS_JOBS,
  GET_CUSTOMER_SUPPORT_JOBS,
  GET_JOBS,
  GET_JOBS_BY_DEPARTMENT,
  GET_NEW_PRODUCTS_JOBS,
  GET_NOC_JOBS,
  GET_OPS_JOBS,
  GET_PROCUREMENT_JOBS,
  GET_RESEARCH_JOBS,
  GET_SALES_JOBS,
  GET_TECHSUPPORT_JOBS,
} from '../../../constants/types/shared/recruitment/PostingsTypes';

const initialState = {
  jobs: [],
  accountjobs: [],
  departmentjobs: [],
  customersupportjobs: [],
  newproductsjobs: [],
  nocjobs: [],
  salesjobs: [],
  opsjobs: [],
  researchjobs: [],
  procurementjobs: [],
  techjobs: [],
};

export const JobsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_JOBS:
      return {
        ...state,
        jobs: action.payload,
      };

    case GET_ACCOUNTS_JOBS:
      return {
        ...state,
        accountjobs: action.payload,
      };

    case GET_CUSTOMER_SUPPORT_JOBS:
      return {
        ...state,
        customersupportjobs: action.payload,
      };

    case GET_NEW_PRODUCTS_JOBS:
      return {
        ...state,
        newproductsjobs: action.payload,
      };

    case GET_NOC_JOBS:
      return {
        ...state,
        nocjobs: action.payload,
      };

    case GET_SALES_JOBS:
      return {
        ...state,
        salesjobs: action.payload,
      };

    case GET_OPS_JOBS:
      return {
        ...state,
        opsjobs: action.payload,
      };

    case GET_RESEARCH_JOBS:
      return {
        ...state,
        researchjobs: action.payload,
      };

    case GET_PROCUREMENT_JOBS:
      return {
        ...state,
        procurementjobs: action.payload,
      };

    case GET_TECHSUPPORT_JOBS:
      return {
        ...state,
        techjobs: action.payload,
      };

    case GET_JOBS_BY_DEPARTMENT:
      return {
        ...state,
        departmentjobs: action.payload,
      };

    default:
      return state;
  }
};

export default JobsReducer;
