/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import RequisitionContext from '../../../../../../context/shared/requisitions/RequsitionContext';
import { useRequisition } from '../../../../../../hooks/shared/requisition/useRequisition';

const ApproveRequisition = ({ req, reloadFunction, showApproved, onCloseModal }) => {
  const { account_approval, amount_in_digits, amount_in_words, budget_code, manager_approval, purpose } = req;
  const { onApproveChange, approveRequisition, giveManagerApproval, approving } = useContext(RequisitionContext);

  const { paymentDate, comment } = approveRequisition;
  const onSubmit = (e) => {
    e.preventDefault();
    giveManagerApproval(req.id, approveRequisition, reloadFunction, onCloseModal);
  };
  return (
    <Modal show={showApproved} onHide={onCloseModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Approve Requisition</Modal.Title>
      </Modal.Header>
      <div className="modal-body">
        <form className="form-horizontal" onSubmit={onSubmit}>
          <div className="form-group add-log">
            <div className="col-md-12 mb-1 d-flex">
              <div className="col-lg-6 mb-3">
                <label className="copy-font">Payment Date</label>
                <input
                  type="date"
                  name="paymentDate"
                  value={paymentDate}
                  className="form-control"
                  placeholder="Payment Date"
                  onChange={onApproveChange}
                  required
                  disabled
                />
              </div>

              <div className="col-lg-6 mb-3">
                <label className="copy-font">Comment</label>
                <input
                  type="text"
                  className="form-control"
                  name="comment"
                  placeholder="Comment"
                  value={comment}
                  onChange={onApproveChange}
                  // defaultValue={last}
                  // onChange={this.onChange}
                />
              </div>
            </div>
            <div className="col-md-12 mb-1 d-flex">
              <div className="col-lg-6 mb-3">
                <label className="copy-font">Account Approval Status</label>
                <input type="text" className="form-control" placeholder={account_approval} readOnly />
              </div>

              <div className="col-lg-6 mb-3">
                <label className="copy-font">Manager Approval</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={manager_approval}
                  readOnly

                  // defaultValue={last}
                  // onChange={this.onChange}
                />
              </div>
            </div>
            <div className="col-md-12 mb-1 d-flex">
              <div className="col-lg-6 mb-3">
                <label className="copy-font">Amount In Digits</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={amount_in_digits}
                  readOnly

                  // defaultValue={first}
                  // onChange={this.onChange}
                />
              </div>

              <div className="col-lg-6 mb-3">
                <label className="copy-font">Amount In Words</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={amount_in_words}
                  readOnly

                  // defaultValue={last}
                  // onChange={this.onChange}
                />
              </div>
            </div>
            <div className="col-md-12 mb-1 d-flex">
              <div className="col-lg-6 mb-3">
                <label className="copy-font">Budget Code</label>
                {/* eslint-disable-next-line @typescript-eslint/camelcase */}
                <input type="text" className="form-control" placeholder={budget_code} readOnly />
              </div>

              <div className="col-lg-6 mb-3">
                <label className="copy-font">Purpose</label>
                <input type="text" className="form-control" placeholder={purpose} readOnly />
              </div>
            </div>

            {/* {deleting ? (
                  <div className="col-12 mb-3 text-center">
                    <button className="btn btn-green" disabled={deleting}>
                      Deleting...
                    </button>
                  </div>
                ) : ( */}
            <div className="col-12 mb-3 text-center">
              <button
                className="btn btn-green"
                //   disabled={!isEnabled()}
              >
                {approving ? 'Approving...' : 'Approve'}
              </button>
            </div>
            {/* )} */}
          </div>
        </form>
      </div>
    </Modal>
  );
};

ApproveRequisition.propTypes = {
  // eslint-disable-next-line react/require-default-props
  req: PropTypes.shape(),
};
export default ApproveRequisition;
