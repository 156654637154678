/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-named-as-default */
import { useEffect, useReducer } from 'react';
import axios from 'axios';
import { userReducer } from '../../../reducers/user/userReducer';

export const useSearchReference = () => {
  const [{ reference }, dispatch] = useReducer(userReducer, {
    reference: [],
  });

  const searchReference = async () => {
    try {
      const response = await axios.post(`https://legacy.tizeti.com/geniatechapi/index.php`, {
        action: 'getUserInformationWithPhoneOrMac',
        reference: '',
      });

      if (response.data) {
        console.log(response.data);
        dispatch({
          payload: response.data.items,
        });
      }
    } catch (e) {
      // eslint-disable-next-line no-empty
      if (e) {
      }
    }
  };

  useEffect(() => {
    searchReference();
  }, []);

  return { reference };
};
