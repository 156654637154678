import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import Calendar from 'react-calendar';
import Label from '../../../../shared/forms/Label';
import CustomInput from '../../../../shared/forms/CustomInput';
import CustomButton from '../../../../shared/forms/CustomButton';

const NewScheduleUser = ({ location }) => {
  const initialValue = { address: location.state ? location.state.detail.firstName : '', reference: '' };
  const [schedule, setSchedule] = useState(initialValue);
  const onChange = (e) => {
    const { name, value } = e.target;
    setSchedule({
      ...schedule,
      [name]: value,
    });
  };
  const { address, reference } = schedule;
  //   address: "My house address is 6B Babatunde tandoh street reservation "
  // created_at: "2020-12-29T11:43:33.000Z"
  // email: "nnenwakama@gmail.com"
  // firstName: "Anne "
  // id: "93cdb66e-f92b-460f-b751-f17389e25ffc"
  // lastName: "Nwakama\t"
  // phoneNumber: "08122345752\t"
  // status: "lead"
  return (
    <div className="modal-thing bg-white">
      <div>
        <div className="create-modal-body mt-3 col-12 p-0">
          <div className="modal-title">
            <h3 className="col-10 text-center">Scheduler</h3>
          </div>
          <div className="col-12">
            <form
              className="form-horizontal"
              // onSubmit={this.createSchedule}
            >
              <div className="form-group">
                <div className="col-md-12 mb-3 d-flex p-0">
                  {/* {state ? ( */}
                  <div className="col-lg-6 text-left">
                    <Label classname="copy-font m-0 p-0" name="First Name" />
                    <CustomInput
                      classname="form-control"
                      name="firstName"
                      value={location.state.detail.firstName}
                      onChange={null}
                      readOnly
                      placeholder="Last Name"
                      type="text"
                    />
                  </div>
                  {/* ) : null} */}

                  {/* {state ? ( */}
                  <div className="col-lg-6 text-left">
                    <Label classname="copy-font m-0 p-0" name="Last Name" />
                    <CustomInput
                      name="lastName"
                      classname="form-control"
                      placeholder="Last Name"
                      onChange={null}
                      value={location.state.detail.lastName}
                      readOnly
                      type="text"
                    />
                  </div>
                  {/* ) : null} */}
                </div>

                <div className="col-md-12 mb-3 d-flex p-0">
                  {/* {state ? ( */}
                  <div className="col-lg-6 text-left">
                    <Label classname="copy-font m-0 p-0" name="Email" />
                    <CustomInput
                      type="email"
                      name="email"
                      classname="form-control col-lg-11"
                      placeholder="Email Address"
                      readOnly
                      value={location.state.detail.email}
                      onChange={null}
                    />
                  </div>
                  {/* ) : null} */}
                </div>

                <div className="col-md-12 mb-3 d-flex p-0">
                  {/* {state ? ( */}
                  <div className="col-lg-6 text-left">
                    <Label classname="copy-font m-0 p-0" name="Phone number" />
                    <CustomInput
                      type="text"
                      name="phoneNumber"
                      classname="form-control"
                      value={location.state.detail.phoneNumber}
                      readOnly
                      onChange={null}
                      placeholder="Phone Number"
                    />
                  </div>
                  {/* ) : null} */}

                  <div className="col-lg-6 text-left">
                    <Label classname="copy-font m-0 p-0" name="Payment Reference" />
                    <CustomInput
                      type="text"
                      name="reference"
                      classname="form-control"
                      onChange={onChange}
                      value={reference}
                      placeholder="payment reference"
                      readOnly={false}
                    />
                  </div>
                </div>

                <div className="col-md-12 mb-3 d-flex p-0">
                  <div className="col-lg-6 text-left">
                    <Label classname="copy-font m-0 p-0" name="Zone" />
                    <select
                      className="form-control"
                      // onChange={this.getSubZone}
                      id="zoneId"
                      required
                      name="zone"
                    >
                      <option value="">Select Zone</option>
                      {/* {zones
                      ? zones.map((zone) => {
                        return ( */}
                      {/* <option value={zone.id} key={zone.id} id={zone.id}> */}
                      {/* {zone.name.toUpperCase()} */}
                      {/* </option> */}
                      {/* );
                      })
                      : null} */}
                    </select>
                  </div>

                  <div className="col-lg-6 text-left">
                    {/* {loadSub ? <small>loading subzones</small> : null} */}

                    {/* {subzones.length ? ( */}
                    <div className="col-lg-12 text-left p-0">
                      <Label classname="copy-font m-0 p-0" name="Sub zone" />
                      <select
                        className="form-control"
                        // onChange={this.subZoneChange}
                        id="subzoneId"
                        required
                        name="subzone"
                      >
                        <option value="">Select Subzone</option>
                        {/* {subzones */}
                        {/*  ? subzones.map((subzone) => { */}
                        {/*    return ( */}
                        {/*      <option value={subzone.id} key={subzone.id} id={subzone.id}> */}
                        {/*        {subzone.name.toUpperCase()} */}
                        {/*      </option> */}
                        {/*  ); */}
                        {/* }) */}
                        {/* : null} */}
                      </select>

                      {/* <p className="col-lg-12 text-left p-0">
                            <label className="copy-font m-0 p-0">Cluster</label>
                            <select className="form-control" name="cluster" required>
                              <option value="">Select Cluster</option>
                              <option>Agungi</option>
                              <option>Egbo Efon</option>
                              <option>Jakande</option>
                            </select>
                          </p> */}
                    </div>
                    {/* ) : null} */}
                  </div>
                </div>
                <div className="col-md-12 mb-3 d-flex p-0">
                  <div className="col-lg-6 text-left">
                    <Label classname="copy-font m-0 p-0" name="Category" />
                    <select
                      name="category"
                      className="form-control"
                      // onChange={this.onFormChange}
                      required
                    >
                      <option value="">Select Category</option>
                      {/* {categories */}
                      {/*  ? categories.map((category: { id: string | number | string[] | undefined; name: React.ReactNode; }, i: any) => <option value={category.id}>{category.name}</option>) */}
                      {/*  : null} */}
                    </select>
                  </div>

                  <div className="col-lg-6 text-left">
                    <Label classname="copy-font m-0 p-0" name="State" />
                    <select
                      className="form-control col-lg-11"
                      // onChange={this.onFormChange}
                      name="state"
                      required
                    >
                      <option>E.g Lagos</option>
                      {/* {states.map((state) => { */}
                      {/*  return <option value={state.name}>{state.name}</option>; */}
                      {/* })} */}
                    </select>
                  </div>
                </div>
                <div className="col-md-12 mb-3 d-flex p-0">
                  <div className="text-left col-lg-12">
                    <Label classname="copy-font m-0 p-0" name="Select Date" />
                    <div className="container mb-3 p-0">
                      <Calendar
                      // onChange={this.onDateChange} value={date}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-12 mb-3 d-flex p-0">
                  {/* {loadTime ? ( */}
                  <div className="col-lg-6 text-left">
                    <small>loading time</small>
                  </div>
                  {/* ) : available.length ? ( */}
                  <div className="col-lg-6 text-left">
                    <Label classname="copy-font-new m-0 p-0" name="Select Available Time" />
                    <select
                      name="available"
                      id="timeId"
                      // onChange={this.onFormChange}
                      required
                    >
                      <option value="">Select Time</option>
                      {/* {available.map((av: { id: string | number | string[] | undefined; time: React.ReactNode; }) => { */}
                      {/*  return <option value={av.id}>{av.time}</option>; */}
                      {/* })} */}
                    </select>
                  </div>
                  {/* ) : ( */}
                  {/* '' */}
                  {/* )} */}
                </div>

                <div className="col-md-12 mb-3 d-flex p-0">
                  {/* {state ? ( */}
                  <div className="col-lg-6 text-left">
                    <Label classname="copy-font m-0 p-0" name="Address" />
                    <CustomInput
                      type="text"
                      classname="form-control"
                      name="address"
                      placeholder="Address"
                      onChange={onChange}
                      value={address}
                      readOnly={false}
                    />
                  </div>
                  {/* ) : null} */}
                </div>
              </div>

              <div className="direction text-center pb-4">
                {/* {submit ? ( */}
                {/*  <button className="btn btn-green" disabled={submit}> */}
                {/*    Scheduling... */}
                {/*  </button> */}
                {/* ) : ( */}
                <CustomButton disabled={false} name="Schedule Installation" classname="btn btn-green" />
                {/* )} */}
              </div>
            </form>
          </div>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default NewScheduleUser;
