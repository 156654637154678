import React from 'react';
import Logout from '../../../shared/auth/Logout';
import NewProductsCustomersTable from '../../new-products/customers/NewProductsCustomersTable';
import AssetTeamNavBar from '../../../../layouts/asset-team/AssetTeamNavBar';
import profileImage from '../../../../../assets/img/icons/Profile.png';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import SupportCustomersTable from '../../../shared/customers/shared/SupportCustomersTable';

const AssetTeamGeniatech = () => {
  const { profile } = useUser();

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <div className="d-flex col-lg-12 p-2 top-header">
          <div className="mt-3 d-flex col-lg-12 p-0">
            <AssetTeamNavBar currentPage="Customers" profileImage={profileImage} position={profile.firstName} />
            {/* <h3 className="col-lg-6 overview-part">Customers</h3> */}

            <div className="d-flex profile-mail">
              <h6 className="mr-0 d-flex">
                <i className="small mr-2 d-none d-md-block">{profile.firstName}</i>
                <Logout />
              </h6>
            </div>
          </div>
        </div>
        <div className="pt-5 mt-5">
        <SupportCustomersTable />
        </div>
     
        {/* <NewProductsCustomersTable /> */}
      </main>
    </div>
  );
};

export default AssetTeamGeniatech;
