import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Box from '@mui/material/Box';
import { TableCellMonths } from '../components/tempData';

export default function LineCharts({ getZones, ActiveInactiveData, ActiveData, InActiveData }) {
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    if (getZones.length > 0 && InActiveData.length && ActiveInactiveData.length > 0 && ActiveData.length > 0) {
      const refinedData = getZones.map((state, index) => ({
        name: state.name,
        'Active & Expired': ActiveInactiveData[index] && ActiveInactiveData[index],
        Active: typeof ActiveData[index] === 'number' ? ActiveData[index] : ActiveData[index].length,
        Expired: typeof InActiveData[index] === 'number' ? InActiveData[index] : InActiveData[index].length,
      }));

      if (refinedData.length > 0) {
        const filterNonZeroValues = refinedData.filter((val) => val.Active > 0);
        setData(filterNonZeroValues);
      }
    }
  }, [InActiveData.length]);

  // console.log(refinedData);

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flexStart">
      <Box sx={{ width: { xs: '90vw', md: 800 }, overflowX: 'auto' }}>
        <div style={{ width: '100%', overflowX: 'scroll' }}>
          <ResponsiveContainer width={2200} height={500}>
            <LineChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" type="category" scale="band" padding={{ left: 10, right: 20 }} />
              <YAxis />
              <Tooltip />
              <Legend />

              <Line type="monotone" dataKey="Active & Expired" fill="#413ea0" stroke="#8884d8" strokeWidth={2} />
              <Line type="monotone" dataKey="Active" fill="#d8d284" stroke="#d584d8" strokeWidth={2} />
              <Line type="monotone" dataKey="Expired" fill="#ff7300" stroke="#92d884" strokeWidth={2} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </Box>
    </Box>
  );
}
