import axios from 'axios';
import { toast } from 'react-toastify';
import { useReducer } from 'react';
import DedicatedCustomersReducer from '../../reducers/business-development/DedicatedCustomersReducer';
import { LOADING_STARTS, LOADING_STOPS } from '../../constants/types/shared/LoadingTypes';
import {
    GET_DEDICATED_CUSTOMERS_URL,
    SEARCH_DEDICATED_CUSTOMERS_URL,
} from '../../services/api/business-development/DedicatedCustomersUrl';
import { GET_DEDICATED_CUSTOMERS } from '../../constants/types/business-development/DedicatedCustomersTypes';

const useCallCardCustomers = () => {
    const [{ items, totalPages, currentPage, allPages, isLoading, itemsCache }, dispatch] = useReducer(DedicatedCustomersReducer, {
        items: [],
        totalPages: '',
        allPages: '',
        currentPage: '',
        isLoading: false,
    });

    // const [key, setKey] = useState(``);
    // const [value, setValue] = useState(``);

    // const data = qs.stringify({
    //   action: 'search_dedicated_customer',
    //   key: `${key}`,
    //   value: `${value}`,
    // });


    const searchCallCardCustomers = async (value) => {
        if (itemsCache.length > 0) {
            dispatch({
                type: 'SEARCH_CALL_CARD_CUSTOMERS',
                payload: {
                    value
                }
            })
        }

    }

    const getCallCardDedicatedCustomers = async (data) => {
        dispatch({ type: LOADING_STARTS });
        try {
            const response = await axios.post(`${GET_DEDICATED_CUSTOMERS_URL}`, data);
            if (response.data) {
                dispatch({
                    type: GET_DEDICATED_CUSTOMERS,
                    payload: {
                        items: response.data.items,
                        totalPages: response.data.total_records,
                        allPages: response.data.total_pages,
                        currentPage: response.data.current_page,
                    },
                });
            }
        } catch (e) {
            toast.info('Something went wrong!');
            if (e.response) {
                dispatch({ type: LOADING_STOPS });
            }
        }
    };

    return {
        getCallCardDedicatedCustomers,
        searchCallCardCustomers,
        items,
        totalPages,
        allPages,
        currentPage,
        isLoading,
    };
};

export default useCallCardCustomers;
