import React from 'react';
import KpiPerMonthTable from './KpiPerMonthTable';

export default function KpiPerMonthOverview() {
  return (
    <div>
      <KpiPerMonthTable />
    </div>
  );
}
