import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';
import { FETCH_RETAILER_SMS_LOGS } from '../../../constants/types/express-wifi/ExpressWifiTypes';

const retailerReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOADING_STARTS:
      return {
        ...state,
        loading: true,
      };

    case FETCH_RETAILER_SMS_LOGS:
      return { ...state, sms: payload, loading: false };

    case LOADING_STOPS:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default retailerReducer;
