export const GET_WIFICALL_USERS_COUNT = 'GET_WIFICALL_USERS_COUNT';
export const GET_WIFICALL_DIRECTORY_COUNT = 'GET_WIFICALL_DIRECTORY_COUNT';
export const GET_WIFICALL_USERS = 'GET_WIFICALL_USERS';
export const GET_FILTERED_WIFICALL_USERS = 'GET_FILTERED_WIFICALL_USERS';
export const SEARCH_WIFICALL_USERS = 'SEARCH_WIFICALL_USERS';
export const GET_WIFICALL_PLANS = 'GET_WIFICALL_PLANS';
export const SEARCH_WIFICALL_CUSTOMERS_WITH_PARAMS = 'SEARCH_WIFICALL_CUSTOMERS_WITH_PARAMS';
export const SEARCH_WIFICALL_CUSTOMERS_WITH_DATE = 'SEARCH_WIFICALL_CUSTOMERS_WITH_DATE';
export const ACTIVATE_WIFICALL_USER = 'ACTIVATE_WIFICALL_USER';
export const GET_VERIFIED_WIFICALL_USERS = 'GET_VERIFIED_WIFICALL_USERS';
export const GET_UNVERIFIED_WIFICALL_USERS = 'GET_UNVERIFIED_WIFICALL_USERS';
export const GET_CUSTOMER_PAYMENTS = 'GET_CUSTOMER_PAYMENTS';
export const GET_LOGIN_LOGS = 'GET_LOGIN_LOGS';
