/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import { useState, useEffect, useReducer } from 'react';
import axios from 'axios';
import qs from 'qs';
import toastr from 'toastr';
import * as load from '../../../constants/types/shared/LoadingTypes';
import * as types from '../../../constants/types/sales/installations/InstallationsTypes';
import * as url from '../../../services/api/shared/installations/InstallationsUrl';
import { installationsSummaryReducer } from '../../../reducers/field-support/installations-summary/installationsSummaryReducer';

export const useInstallationsSummary = () => {
  const [
    {
      lagosMonthlyTarget,
      abujaMonthlyTarget,
      ogunMonthlyTarget,
      riversMonthlyTarget,
      edoMonthlyTarget,
      oyoMonthlyTarget,
      enuguMonthlyTarget,
      deltaMonthlyTarget,
      kanoMonthlyTarget,
      kadunaMonthlyTarget,
      imoMonthlyTarget,
      abiaMonthlyTarget,
      anambraMonthlyTarget,
      crossRiverMonthlyTarget,
      lagosMonthlyCompleted,
      abujaMonthlyCompleted,
      ogunMonthlyCompleted,
      riversMonthlyCompleted,
      edoMonthlyCompleted,
      deltaMonthlyCompleted,
      enuguMonthlyCompleted,
      oyoMonthlyCompleted,
      kanoMonthlyCompleted,
      kadunaMonthlyCompleted,
      imoMonthlyCompleted,
      abiaMonthlyCompleted,
      anambraMonthlyCompleted,
      crossRiverMonthlyCompleted,
      lagosDailyCompleted,
      abujaDailyCompleted,
      ogunDailyCompleted,
      riversDailyCompleted,
      edoDailyCompleted,
      deltaDailyCompleted,
      enuguDailyCompleted,
      oyoDailyCompleted,
      kanoDailyCompleted,
      kadunaDailyCompleted,
      imoDailyCompleted,
      abiaDailyCompleted,
      anambraDailyCompleted,
      crossRiverDailyCompleted,
      monthlyByStatus,
      isLoading,
    },
    dispatch,
  ] = useReducer(installationsSummaryReducer, {
    lagosMonthlyTarget: 0,
    abujaMonthlyTarget: 0,
    ogunMonthlyTarget: 0,
    enuguMonthlyTarget: 0,
    deltaMonthlyTarget: 0,
    riversMonthlyTarget: 0,
    edoMonthlyTarget: 0,
    oyoMonthlyTarget: 0,
    kanoMonthlyTarget: 0,
    kadunaMonthlyTarget: 0,
    imoMonthlyTarget: 0,
    abiaMonthlyTarget: 0,
    anambraMonthlyTarget: 0,
    crossRiverMonthlyTarget: 0,
    lagosMonthlyCompleted: 0,
    abujaMonthlyCompleted: 0,
    ogunMonthlyCompleted: 0,
    riversMonthlyCompleted: 0,
    deltaMonthlyCompleted: 0,
    enuguMonthlyCompleted: 0,
    edoMonthlyCompleted: 0,
    oyoMonthlyCompleted: 0,
    kanoMonthlyCompleted: 0,
    kadunaMonthlyCompleted: 0,
    imoMonthlyCompleted: 0,
    abiaMonthlyCompleted: 0,
    anambraMonthlyCompleted: 0,
    crossRiverMonthlyCompleted: 0,
    lagosDailyCompleted: 0,
    abujaDailyCompleted: 0,
    ogunDailyCompleted: 0,
    riversDailyCompleted: 0,
    edoDailyCompleted: 0,
    deltaDailyCompleted: 0,
    enuguDailyCompleted: 0,
    oyoDailyCompleted: 0,
    kanoDailyCompleted: 0,
    kadunaDailyCompleted: 0,
    imoDailyCompleted: 0,
    abiaDailyCompleted: 0,
    anambraDailyCompleted: 0,
    crossRiverDailyCompleted: 0,
    monthlyByStatus: 0,
    isLoading: false,
  });

  const thisMonth = new Date().getMonth();

  const [details, setDetails] = useState({});

  const getDetails = (state) => setDetails(state);

  const addTarget = async (data) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(`${url.GET_INSTALLATION_TARGET_URL}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.data === '1') {
        toastr.success('Target Added!');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const deleteTarget = async (id) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.delete(`${url.GET_INSTALLATION_TARGET_URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.data === 1) {
        toastr.info('Delete Successful!');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const getMonthlyInstallationTarget = async () => {
    dispatch({ type: load.FETCHING_STARTS });
    try {
      const lagosData = { month: thisMonth + 1, state: 'lagos' };
      const abujaData = { month: thisMonth + 1, state: 'abuja' };
      const ogunData = { month: thisMonth + 1, state: 'ogun' };
      const riversData = { month: thisMonth + 1, state: 'rivers' };
      const edoData = { month: thisMonth + 1, state: 'edo' };
      const oyoData = { month: thisMonth + 1, state: 'oyo' };
      const deltaData = { month: thisMonth + 1, state: 'delta' };
      const enuguData = { month: thisMonth + 1, state: 'enugu' };
      const kanoData = { month: thisMonth + 1, state: 'kano' };
      const kadunaData = { month: thisMonth + 1, state: 'kaduna' };
      const imoData = { month: thisMonth + 1, state: 'imo' };
      const abiaData = { month: thisMonth + 1, state: 'abia' };
      const anambraData = { month: thisMonth + 1, state: 'anambra' };
      const crossRiverData = { month: thisMonth + 1, state: 'cross-river' };

      await Promise.all([
        axios.post(`${url.GET_INSTALLATION_TARGET_URL}/state`, lagosData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.GET_INSTALLATION_TARGET_URL}/state`, abujaData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.GET_INSTALLATION_TARGET_URL}/state`, ogunData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.GET_INSTALLATION_TARGET_URL}/state`, riversData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.GET_INSTALLATION_TARGET_URL}/state`, edoData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.GET_INSTALLATION_TARGET_URL}/state`, oyoData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.GET_INSTALLATION_TARGET_URL}/state`, deltaData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.GET_INSTALLATION_TARGET_URL}/state`, enuguData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.GET_INSTALLATION_TARGET_URL}/state`, kanoData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.GET_INSTALLATION_TARGET_URL}/state`, kadunaData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.GET_INSTALLATION_TARGET_URL}/state`, imoData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.GET_INSTALLATION_TARGET_URL}/state`, abiaData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.GET_INSTALLATION_TARGET_URL}/state`, anambraData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.GET_INSTALLATION_TARGET_URL}/state`, crossRiverData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
      ]).then((responses) => {
        dispatch({
          type: types.GET_TARGETS,
          payload: {
            lagosMonthlyTarget: responses[0].data.data.rows,
            abujaMonthlyTarget: responses[1].data.data.rows,
            ogunMonthlyTarget: responses[2].data.data.rows,
            riversMonthlyTarget: responses[3].data.data.rows,
            edoMonthlyTarget: responses[4].data.data.rows,
            oyoMonthlyTarget: responses[5].data.data.rows,
            deltaMonthlyTarget: responses[6].data.data.rows,
            enuguMonthlyTarget: responses[7].data.data.rows,
            kanoMonthlyTarget: responses[8].data.data.rows,
            kadunaMonthlyTarget: responses[9].data.data.rows,
            imoMonthlyTarget: responses[10].data.data.rows,
            abiaMonthlyTarget: responses[11].data.data.rows,
            anambraMonthlyTarget: responses[12].data.data.rows,
            crossRiverMonthlyTarget: responses[13].data.data.rows,
          },
        });
      });
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.FETCHING_STOPS });
    }
  };

  const getStateMonthlyCompletedInstallation = async (monthStart, monthEnd) => {
    dispatch({ type: load.FETCHING_STARTS });
    try {
      const lagosData = { state: 'lagos', month_start: monthStart, month_end: monthEnd };
      const abujaData = { state: 'abuja', month_start: monthStart, month_end: monthEnd };
      const ogunData = { state: 'ogun', month_start: monthStart, month_end: monthEnd };
      const riversData = { state: 'rivers', month_start: monthStart, month_end: monthEnd };
      const edoData = { state: 'edo', month_start: monthStart, month_end: monthEnd };
      const oyoData = { state: 'oyo', month_start: monthStart, month_end: monthEnd };
      const deltaData = { state: 'delta', month_start: monthStart, month_end: monthEnd };
      const enuguData = { state: 'enugu', month_start: monthStart, month_end: monthEnd };
      const kanoData = { state: 'kano', month_start: monthStart, month_end: monthEnd };
      const kadunaData = { state: 'kaduna', month_start: monthStart, month_end: monthEnd };
      const imoData = { state: 'imo', month_start: monthStart, month_end: monthEnd };
      const abiaData = { state: 'abia', month_start: monthStart, month_end: monthEnd };
      const anambraData = { state: 'anambra', month_start: monthStart, month_end: monthEnd };
      const crossRiverData = { state: 'cross river', month_start: monthStart, month_end: monthEnd };

      await Promise.all([
        axios.post(`${url.GET_MONTHLY_INSTALLATION_URL}/state`, lagosData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.GET_MONTHLY_INSTALLATION_URL}/state`, abujaData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.GET_MONTHLY_INSTALLATION_URL}/state`, ogunData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.GET_MONTHLY_INSTALLATION_URL}/state`, riversData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.GET_MONTHLY_INSTALLATION_URL}/state`, edoData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.GET_MONTHLY_INSTALLATION_URL}/state`, oyoData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.GET_MONTHLY_INSTALLATION_URL}/state`, deltaData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.GET_MONTHLY_INSTALLATION_URL}/state`, enuguData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.GET_MONTHLY_INSTALLATION_URL}/state`, kanoData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.GET_MONTHLY_INSTALLATION_URL}/state`, kadunaData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.GET_MONTHLY_INSTALLATION_URL}/state`, imoData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.GET_MONTHLY_INSTALLATION_URL}/state`, abiaData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.GET_MONTHLY_INSTALLATION_URL}/state`, anambraData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.GET_MONTHLY_INSTALLATION_URL}/state`, crossRiverData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
      ]).then((responses) => {
        // console.log(responses[4].data.data);
        dispatch({
          type: types.GET_MONTHLY_INSTALLED,
          payload: {
            lagosMonthlyCompleted: responses[0].data.data,
            abujaMonthlyCompleted: responses[1].data.data,
            ogunMonthlyCompleted: responses[2].data.data,
            riversMonthlyCompleted: responses[3].data.data,
            edoMonthlyCompleted: responses[4].data.data,
            oyoMonthlyCompleted: responses[5].data.data,
            deltaMonthlyCompleted: responses[6].data.data,
            enuguMonthlyCompleted: responses[7].data.data,
            kanoMonthlyCompleted: responses[8].data.data,
            kadunaMonthlyCompleted: responses[9].data.data,
            imoMonthlyCompleted: responses[10].data.data,
            abiaMonthlyCompleted: responses[11].data.data,
            anambraMonthlyCompleted: responses[12].data.data,
            crossRiverMonthlyCompleted: responses[13].data.data,
          },
        });
      });
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.FETCHING_STOPS });
    }
  };

  const getMonthlyInstallationByStatus = async (data) => {
    dispatch({ type: load.FETCHING_STARTS });
    try {
      const config = {
        method: 'post',
        url: `${url.GET_MONTHLY_INSTALLATION_URL}`,
        data: qs.stringify(data),
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      };
      await axios(config).then((response) => {
        if (response.data.status === 'success') {
          dispatch({
            type: types.GET_MONTHLY_INSTALL_BY_STATUS,
            payload: response.data.data,
          });
        } else dispatch({ type: load.FETCHING_STOPS });
      });
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.FETCHING_STOPS });
    }
  };

  const getStateDailyCompletedInstallation = async () => {
    dispatch({ type: load.FETCHING_STARTS });
    try {
      const lagosData = { status: 'completed', state: 'lagos' };
      const abujaData = { status: 'completed', state: 'abuja' };
      const ogunData = { status: 'completed', state: 'ogun' };
      const riversData = { status: 'completed', state: 'rivers' };
      const edoData = { status: 'completed', state: 'edo' };
      const oyoData = { status: 'completed', state: 'oyo' };
      const deltaData = { status: 'completed', state: 'delta' };
      const enuguData = { status: 'completed', state: 'enugu' };
      const kanoData = { status: 'completed', state: 'kano' };
      const kadunaData = { status: 'completed', state: 'kaduna' };
      const imoData = { status: 'completed', state: 'imo' };
      const abiaData = { status: 'completed', state: 'abia' };
      const anambraData = { status: 'completed', state: 'anambra' };
      const crossRiverData = { status: 'completed', state: 'cross river' };

      await Promise.all([
        axios.post(`${url.DAILY_INSTALLATION_URL}`, lagosData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.DAILY_INSTALLATION_URL}`, abujaData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.DAILY_INSTALLATION_URL}`, ogunData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.DAILY_INSTALLATION_URL}`, riversData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.DAILY_INSTALLATION_URL}`, edoData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.DAILY_INSTALLATION_URL}`, oyoData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.DAILY_INSTALLATION_URL}`, deltaData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.DAILY_INSTALLATION_URL}`, enuguData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.DAILY_INSTALLATION_URL}`, kanoData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.DAILY_INSTALLATION_URL}`, kadunaData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.DAILY_INSTALLATION_URL}`, imoData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.DAILY_INSTALLATION_URL}`, abiaData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.DAILY_INSTALLATION_URL}`, anambraData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${url.DAILY_INSTALLATION_URL}`, crossRiverData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
      ]).then((responses) => {
        dispatch({
          type: types.GET_DAILY_INSTALLED,
          payload: {
            lagosDailyCompleted: responses[0].data.data,
            abujaDailyCompleted: responses[1].data.data,
            ogunDailyCompleted: responses[2].data.data,
            riversDailyCompleted: responses[3].data.data,
            edoDailyCompleted: responses[4].data.data,
            oyoDailyCompleted: responses[5].data.data,
            deltaDailyCompleted: responses[6].data.data,
            enuguDailyCompleted: responses[7].data.data,
            kanoDailyCompleted: responses[8].data.data,
            kadunaDailyCompleted: responses[9].data.data,
            imoDailyCompleted: responses[10].data.data,
            abiaDailyCompleted: responses[11].data.data,
            anambraDailyCompleted: responses[12].data.data,
            crossRiverDailyCompleted: responses[13].data.data,
          },
        });
      });
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.FETCHING_STOPS });
    }
  };

  useEffect(() => {
    // Create a new Date object for the current date
    var currentDate = new Date();

    // Get the current year and month
    var currentYear = currentDate.getFullYear();
    var currentMonth = currentDate.getMonth();

    // Set the date to the first day of the month
    var startDate = new Date(currentYear, currentMonth, 1);

    // Set the date to the last day of the month
    var endDate = new Date(currentYear, currentMonth + 1, 0);
    getMonthlyInstallationTarget();
    getStateMonthlyCompletedInstallation(startDate.toLocaleDateString(), endDate.toLocaleDateString());
    getStateDailyCompletedInstallation();
  }, []);

  return {
    details,
    lagosMonthlyTarget,
    abujaMonthlyTarget,
    ogunMonthlyTarget,
    enuguMonthlyTarget,
    deltaMonthlyTarget,
    riversMonthlyTarget,
    edoMonthlyTarget,
    oyoMonthlyTarget,
    kanoMonthlyTarget,
    kadunaMonthlyTarget,
    imoMonthlyTarget,
    abiaMonthlyTarget,
    anambraMonthlyTarget,
    crossRiverMonthlyTarget,
    lagosMonthlyCompleted,
    abujaMonthlyCompleted,
    ogunMonthlyCompleted,
    deltaMonthlyCompleted,
    enuguMonthlyCompleted,
    riversMonthlyCompleted,
    edoMonthlyCompleted,
    oyoMonthlyCompleted,
    kanoMonthlyCompleted,
    kadunaMonthlyCompleted,
    imoMonthlyCompleted,
    abiaMonthlyCompleted,
    anambraMonthlyCompleted,
    crossRiverMonthlyCompleted,
    lagosDailyCompleted,
    abujaDailyCompleted,
    ogunDailyCompleted,
    riversDailyCompleted,
    enuguDailyCompleted,
    deltaDailyCompleted,
    edoDailyCompleted,
    oyoDailyCompleted,
    kanoDailyCompleted,
    kadunaDailyCompleted,
    imoDailyCompleted,
    abiaDailyCompleted,
    anambraDailyCompleted,
    crossRiverDailyCompleted,
    monthlyByStatus,
    isLoading,
    addTarget,
    deleteTarget,
    getDetails,
    getMonthlyInstallationByStatus,
    getStateMonthlyCompletedInstallation,
  };
};

export default useInstallationsSummary;
