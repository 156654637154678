import axios from 'axios';
import toastr from 'toastr';

export const update_department_action = async (initialExpirationState) => {
  // const initialExpirationState = {
  //   department_id: departmentId,
  //   role: role,
  //   email,
  // };
  const formData = new URLSearchParams(initialExpirationState).toString();
  try {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    };
    const res = await axios.patch('https://api.tizeti.com:8443/api/v1/employee/updateDepartment', formData, {
      headers: headers,
    });
    if (res.data) {
      console.log(res.data);
      return res.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message);
  }
  // console.log(isLoading);
};
