import {
  ADD_CALL_LOG,
  ERROR_ADDING_CALL_LOG,
  GET_CALL_LOGS,
} from '../../../constants/types/shared/call-logs/CallLogsTypes';

const initialState = {
  logs: [],
  load: true,
  addLog: '',
  errorAddingLog: '',
};

export const CallLogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CALL_LOGS:
      return {
        ...state,
        logs: action.payload,
        load: false,
      };

    case ADD_CALL_LOG:
      return {
        ...state,
        addLog: action.payload,
      };

    case ERROR_ADDING_CALL_LOG:
      return {
        ...state,
        errorAddingLog: action.payload,
      };

    default:
      return state;
  }
};

export default CallLogsReducer;
