import React, { useState } from 'react';
import PropTypes from 'prop-types';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import ViewPassword from '../../../shared/customers/passwords/ViewPassword';
import EditCustomerDetails from './EditCustomerDetails';
import edit from '../../../../../assets/img/icons/edit.png';
import CleanRadio from './CleanRadio';
import PaymentsHistory from '../../../shared/customers/payments/PaymentsHistory';
import view from '../../../../../assets/img/icons/view.png';
import CustomerDetails from '../../../shared/customers/shared/CustomerDetails';
import { useCustomer } from '../../../../../hooks/shared/customers/useCustomers';
import { usePayments } from '../../../../../hooks/shared/customers/usePayments';

const CustomersRouterTagTableBody = ({ isLoading, customer }) => {
  const {
    getPassword,
    gettingPassword,
    password,
    getUserDetails,
    gettingDetails,
    details,
    address,
    session,
  } = useCustomer();

  const [mac, setMac] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const { payments, gettingPayments, getCustomerPayments } = usePayments();

  const getUserPassword = (param) => {
    const data = {
      action: 'getCustomerPassword',
      username: param.mac_id,
    };
    getPassword(data);
  };

  const getUserPayments = (param) => {
    const data = {
      macid: param.mac_id,
    };
    getCustomerPayments(data);
  };
  const getUser = (param) => {
    setMac(param.mac_id);
    setName(param.username);
    setEmail(param.user.email);
    setPhone(param.user.phoneNumber);
  };

  const displayCustomerTable = () => {
    if (customer.id) {
      return (
        <tr>
          <td>1</td>
          <td>{customer.mac_id}</td>
          <td nowrap="no-wrap">{customer.username}</td>
          <td>{customer.user ? customer.user.email : null}</td>
          <td className="ml-2">
            {/* eslint-disable-next-line react/button-has-type */}
            <button
              data-toggle="modal"
              data-target="#view-password"
              className="ml-3"
              onClick={() => getUserPassword(customer)}
            >
              <i className="view1 fa fa-ellipsis-v" style={{ fontSize: '20px' }} />
            </button>
            <ViewPassword password={password} load={gettingPassword} />
          </td>
          <td>{customer.user ? customer.user.phoneNumber : null}</td>
          <EditCustomerDetails username={mac} oldEmail={email} oldName={name} oldPhone={phone} />
          <>
            <td>{customer ? customer.radio_tag : null}</td>
            <td>{customer ? customer.router_tag : null}</td>
            {Number(sessionStorage.getItem('roleId')) > 1 ? (
              <td className="">
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#clean-radio"
                  className="ml-2"
                  onClick={() => getUser(customer)}
                >
                  <span className="view2">
                    <img src={edit} alt="" data-toggle="tooltip" data-placement="top" title="Edit" />
                  </span>
                </button>
              </td>
            ) : null}
          </>
          <CleanRadio username={mac} oldEmail={email} oldName={name} oldPhone={phone} />
          <td className="ml-2">
            <button
              type="button"
              data-toggle="modal"
              data-target="#payment-history"
              className="ml-3"
              onClick={() => getUserPayments(customer)}
            >
              <i className="view1 fa fa-ellipsis-v" style={{ fontSize: '20px' }} />
            </button>
            {/* <PaymentHistory /> */}
            <PaymentsHistory load={gettingPayments} transactions={payments} />

            <button
              type="submit"
              style={{
                border: '0',
                backgroundColor: 'white',
                width: '0%',
              }}
              data-toggle="tooltip"
              data-placement="left"
              title="View"
              onClick={() => getUserDetails(customer.mac_id)}
            >
              <span className="view1 ml-4">
                <img src={view} alt="" data-toggle="modal" data-target="#cus-details" />
              </span>
            </button>
            <CustomerDetails
              load={gettingDetails}
              user={customer}
              address={address}
              details={details}
              session={session}
            />
          </td>
        </tr>
      );
    }
    return null;
  };

  return <tbody>{isLoading ? <img src={loader} alt="loading gif" /> : displayCustomerTable()}</tbody>;
};

CustomersRouterTagTableBody.propTypes = {
  // eslint-disable-next-line react/require-default-props
  isLoading: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  customer: PropTypes.shape(),
};

export default CustomersRouterTagTableBody;
