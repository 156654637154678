import { useEffect, useReducer } from 'react';
import axios from 'axios';
import qs from 'qs';
import { customReducer } from '../../../../../reducers/shared/custom/CustomReducer';
import { LOADING_STARTS, LOADING_STOPS } from '../../../../../constants/types/shared/LoadingTypes';
import { GET_DATA } from '../../../../../constants/types/shared/CustomTypes';

const useFetch = (url, params) => {
  const [{ data, loading }, dispatch] = useReducer(customReducer, {
    data: [],
    loading: false,
  });
  const getData = async () => {
    dispatch({
      type: LOADING_STARTS,
    });
    try {
      const response = await axios.post(url, qs.stringify(params));
      if (response.data) {
        dispatch({
          type: GET_DATA,
          payload: response.data,
        });
      }
    } catch (e) {
      dispatch({ type: LOADING_STOPS });
    }
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { data, loading };
};
export default useFetch;
