import React, { useContext } from 'react';
import DummyTable from './DummyTable';
import AddDummy from './AddDummy';
import PaginatePage from '../../../../departments/noc/sheets/common/PaginatePage';
import DummyContext from './context/DummyContext';
import SearchDummy from './SearchDummy';

const DummyBody = () => {
  const { loading, pages, paginateChange } = useContext(DummyContext);
  return (
    <div className="row mt-5">
      <SearchDummy />
      <div className="form-group ml-2">
        <button className="btn btn-green" type="button" data-toggle="modal" data-target="#add-access-point">
          Add Facebook Site
        </button>
      </div>
      <AddDummy />
      <DummyTable />
      {!loading && <PaginatePage pages={pages} paginateChange={paginateChange} />}
    </div>
  );
};

export default DummyBody;
