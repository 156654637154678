/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useContext } from 'react';
import loader from '../../../../../../assets/img/icons/TizetiLoaderColored.gif';
import GeniatechContext from '../../../../../../context/GeniatechContext';

const NetworksTable = () => {
  const { loading, data, searchData } = useContext(GeniatechContext);
  const getInformation = (params) => {
    searchData({
      action: 'get_single_network_information',
      offset: '0',
      limit: '100',
      network_id: params.id,
    });
  };
  const tableData =
    data && data.length
      ? data.map((row, i) => (
          <tr className="text-nowrap" style={{ cursor: 'pointer' }} key={i + 1} onClick={() => getInformation(row)}>
            <td>{i + 1}</td>
            <td>{row.id}</td>
            <td>{row.name}</td>
            <td>{row.managed_account}</td>
          </tr>
        ))
      : 'no static-access-point';
  return (
    <div className="card x-panel mt-2 px-0">
      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>ID</th>
              <th className="text-nowrap">Name</th>
              <th>Managed Account</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <img src={loader} alt="loading gif" />
              </tr>
            ) : (
              tableData
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NetworksTable;
