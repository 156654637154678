import axios from 'axios';
import { toast } from 'react-toastify';
import qs from 'qs';
import toastr from 'toastr';
import { useReducer } from 'react';
import BasestationStatusReducer from '../../../reducers/noc/basestation-status/BasestationStatusReducer';
import { GET_BASESTATIONS_LIST_URL } from '../../../services/api/noc/basestation_status/BasestationStatusUrl';
import { GET_BASESTATIONS_LIST } from '../../../constants/types/noc/NocTypes';
import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';

const useBasestationStatus = () => {
  const [{ items, totalPages, currentPage, allPages, isLoading }, dispatch] = useReducer(BasestationStatusReducer, {
    items: [],
    totalPages: '',
    allPages: '',
    currentPage: '',
    isLoading: false,
  });

  const getBasestationList = async (data) => {
    dispatch({ type: LOADING_STARTS });
    try {
      const response = await axios.post(`${GET_BASESTATIONS_LIST_URL}`, data);
      if (response.data) {
        dispatch({
          type: GET_BASESTATIONS_LIST,
          payload: {
            items: response.data.items,
            totalPages: response.data.total_records,
            allPages: response.data.total_pages,
            currentPage: response.data.current_page,
          },
        });
      }
    } catch (e) {
      toast.info('Something went wrong!');
      if (e.response) {
        dispatch({ type: LOADING_STOPS });
      }
    }
  };

  const updateBasestationStatus = async (data) => {
    const config = {
      method: 'post',
      action: 'update_current_basestation_status',
      url: `${GET_BASESTATIONS_LIST_URL}`,
      data: qs.stringify(data),
    };

    await axios(config)
      .then((response) => {
        console.log(response.data);
        if (response.data) {
          // console.log('true');
          toastr.success('Update Successful');
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((error) => {
        if (error) {
          toastr.info('Something went wrong!');
          dispatch({ type: LOADING_STOPS });
        }
      });
  };

  return { getBasestationList, updateBasestationStatus, items, totalPages, allPages, currentPage, isLoading };
};

export default useBasestationStatus;
