/* eslint-disable no-case-declarations */
import React, { useContext } from 'react';
import AppContext from '../../../../context/shared/AppContext';
import { deviceRetrievalStatus } from '../../../../utils/asset-team/helper';

const SearchDeviceRetrieval = () => {
  const { details, searchForUser, handleDetails } = useContext(AppContext);
  const { status, option, optionValue } = details;

  const searchForUserDetails = (e) => {
    e.preventDefault();
    searchForUser(1);
  };

  const validateSubmit = () => status.length > 1;

  return (
    <div className="d-flex">
      <form className="form-inline ml-3" onSubmit={searchForUserDetails}>
        <div className="form-group mr-2">
          <select name="status" onChange={handleDetails} className="form-control">
            <option value="">--Select Status--</option>
            {deviceRetrievalStatus.map((deviceStatus) => (
              <option value={deviceStatus.value}>{deviceStatus.name.toUpperCase()}</option>
            ))}
          </select>
        </div>
        <div className="form-group mr-2">
          <select name="option" onChange={handleDetails} className="form-control">
            <option value="">--Choose Field To Search--</option>
            <option value="email">Email</option>
            <option value="name">Name</option>
            <option value="phone">Phone</option>
            <option value="mac">MAC ID</option>
          </select>
        </div>
        <div className="form-group mr-2">
          <input
            className="form-control py-2"
            type="text"
            placeholder={`input search ${option}`}
            name="optionValue"
            value={optionValue}
            onChange={handleDetails}
          />
        </div>
        <div className="form-group mx-2">
          <button className="btn btn-green" type="submit" disabled={!validateSubmit()}>
            Search
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchDeviceRetrieval;
