import axios from 'axios';
import { toast } from 'react-toastify';
import qs from 'qs';
import { useState } from 'react';

import { FETCH_DEVICE_TYPES_URL, GET_DEVICE_TYPES_URL } from './DeviceTypesUrl';

const useDeviceTypes = () => {


const [items, setItems] = useState([]);

const [isLoading, setIsLoading] = useState(false);

const [list, setList] = useState([]);

const [loader, setLoader] = useState(false)
  

  const getDeviceTypes = async (data) => {
    setIsLoading(true)
    try {

      const response = await axios.get(`${GET_DEVICE_TYPES_URL}?device_type=${data.deviceTypes}&from=${data.from}&to=${data.to}`, {
        headers: { 
          Authorization: `Bearer ${sessionStorage.getItem('token')}` 

        } 
      });

      if (response.data) {

      console.log(response.data)
      setItems(response.data)
      setIsLoading(false)

      }
    } catch (e) {
      toast.info('Something went wrong!');
      if (e.response) {
        setIsLoading(false)
      }
    }
  };

  const fetchDeviceTypes = async () => {
    setLoader(true)
    try {

      const response = await axios.get(`${FETCH_DEVICE_TYPES_URL}`,{

      
        headers: { 
          Authorization: `Bearer ${sessionStorage.getItem('token')}` 

        } 
      });

      if (response.data) {

      console.log(response.data)
      setList(response.data)
      setLoader(false)

      }
    } catch (e) {
      toast.info('Something went wrong!');
      if (e.response) {
        setLoader(false)
      }
    }
  };

  

  return {
    getDeviceTypes,
    items,
    isLoading,
    fetchDeviceTypes,
    list,
    loader,
  };
};

export default useDeviceTypes;
