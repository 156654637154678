import { GET_DATA } from '../../../../../../../constants/types/shared/CustomTypes';
import { LOADING_STARTS, LOADING_STOPS } from '../../../../../../../constants/types/shared/LoadingTypes';

const accessPointConnectionReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_DATA:
      return {
        ...state,
        loading: false,
        data: payload.rows,
        pages: payload.pages,
      };
    case LOADING_STARTS:
      return {
        ...state,
        loading: true,
      };
    case LOADING_STOPS:
      return {
        ...state,
        loading: false,
      };
    // case REFETCH:
    //   return {
    //     // ...state,
    //     data: [payload, ...state.data],

    //     loading: false,
    //     pages: state.pages,
    //   };
    default:
      return state;
  }
};
export default accessPointConnectionReducer;
