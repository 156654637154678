/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/prop-types */
import React from 'react';
import './index.css';
import helpers from '../../../../utils/helpers';

const InstallationDetails = ({ install }) => {
  const {
    firstName,
    lastName,
    phoneNumber,
    email,
    address,
    nearestBasestation,
    installationComment,
    status,
    created_at,
    createdDate,
  } = install;

  return (
    <div
      id="installation-details"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Installation Details
            </h4>
            <button type="button" className="close mr-3" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body detail install-details-modal">
            <div className="row">
              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label1">
                  <label id="fn">First Name</label>
                  <label id="ln">Last Name</label>
                  <label id="pn">Phone Number</label>
                  <label id="email">Email Address</label>
                </div>
                <div className="col-lg-8 detail-result1">
                  <p className="mt-4 mb-2">{install.user ? install.user.firstName : firstName || null}</p>
                  <p className="mb-1">{install.user ? install.user.lastName : lastName || null}</p>
                  <p className="mb-1">{install.user ? install.user.phoneNumber : phoneNumber || null}</p>
                  <p className="mb-1">{install.user ? install.user.email : email || null}</p>
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label2">
                  <label className="mb-2">Basestation</label>
                  <label id="phone1">Address</label>
                </div>
                <div className="col-lg-8 detail-result2">
                  <p className="mt-4 mb-2 text-uppercase">
                    {install.user
                      ? install.nearest_basestation
                      : install.installations && install.installations.length
                      ? install.installations[0].nearest_basestation
                      : nearestBasestation || 'N/A'}
                  </p>
                  <p className="mb-2">{address}</p>
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label3">
                  <label>Payment Date</label>
                  <label className="install-label">Installation Comment</label>
                </div>

                <div className="col-lg-8 detail-result3">
                  <p className="mt-4 mb-2">{helpers.formatDateAndTime(created_at || createdDate)}</p>
                  <p className="mt-4 mb-2">{installationComment || 'No Comment'}</p>
                </div>
              </div>

              <div className="col-lg-12 d-flex mb-3">
                <div className="col-lg-4 detail-label4">
                  <label>Statuss</label>
                </div>
                <div className="col-lg-8 detail-result4">
                  <p className="mt-4 text-uppercase">
                    {status ||
                      (install.installations && install.installations.length ? install.installations[0].status : 'N/A')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstallationDetails;
