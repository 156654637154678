import React from 'react';
import { useUser } from "../../../../../hooks/shared/user/useUser";
import CustomerSupportNavBar from "../../../../layouts/customer-support/CustomerSupportNavBar";
import SharedSupportCalls from "../../../shared/support-calls/SharedSupportCalls";

const SupportCallsCount = () => {
  const { profile, user } = useUser();
  const { firstName } = profile;
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <CustomerSupportNavBar currentPage="Support Calls" roleID={user.role_id} position={firstName} />
        <div className="mt-5 p-3">
          <SharedSupportCalls />
        </div>
      </main>
    </div>
  );
};

export default SupportCallsCount;
