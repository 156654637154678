import React from 'react';
import { useWifiEvent } from '../../../../../hooks/sales/wifi-event/useWifiEvent';

const SalesWifiEventEditModal = (id) => {
  const [updatedData, setUpdatedData] = React.useState({
    status: '',
    amount: '',
  });
  const { editEventStatus, isUpdateLoading } = useWifiEvent();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedData({
      ...updatedData,
      [name]: value,
    });
    console.log(updatedData);
  };

  const submitForm = () => {
    return updatedData.status.length > 0 && updatedData.amount.length > 0;
  };
  const onSubmit = (e) => {
    e.preventDefault();
    editEventStatus(id.id, updatedData);
  };
  return (
    <div id="editEventStatus" className="modal fade" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header shadow">
            <h4 className="modal-title text-center" id="myModalLabel">
              Update Event
            </h4>
            <button type="button" className="close mr-2" data-dismiss="modal">
              x
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={onSubmit} className="col-12">
              <div className="pt-2">
                <div className="form-group d-block mb-0 pb-0">
                  <label className="m-0 p-0 copy-font" htmlFor="status">
                    Status
                  </label>
                  <select
                    id="status"
                    name="status"
                    value={updatedData.status}
                    onChange={handleChange}
                    className="form-control"
                  >
                    <option value="">Select an option</option>
                    <option value="awaiting">awaiting</option>
                    <option value="confirmed">confirmed</option>
                    <option value="pending">pending</option>
                    <option value="approved">approved</option>
                    <option value="cancelled">cancelled</option>
                  </select>
                </div>
                <div className="form-group d-block mb-0 pb-0">
                  <label className="m-0 p-0 copy-font form-label" htmlFor="amount">
                    Amount
                  </label>
                  <input
                    type="number"
                    name="amount"
                    value={updatedData.amount}
                    onChange={handleChange}
                    className="form-control border"
                    placeholder="enter amount"
                  />
                </div>
              </div>

              <div className="text-center mt-3">
                <button type="submit" className="btn btn-green py-2 px-5" disabled={!submitForm() || isUpdateLoading}>
                  {isUpdateLoading ? 'Updating...' : 'Update'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesWifiEventEditModal;
