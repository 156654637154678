import axios from 'axios';
import toastr from 'toastr';
import { API_URL } from '../../../utils/config';
import { GET_JOBS_APPLICATIONS_URL } from '../../../services/api/shared/recruitment/ApplicationsUrl';
import { GET_JOBS_APPLICATIONS } from '../../../constants/types/shared/recruitment/ApplicationsTypes';

export const getJobsApplications = (id) => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
  };

  try {
    const response = await axios.get(`${API_URL}${GET_JOBS_APPLICATIONS_URL}/${id}`, config);

    if (response.data.length) {
      dispatch({
        type: GET_JOBS_APPLICATIONS,
        payload: response.data,
      });
    } else {
      toastr.info('No applications for this job posting');
      dispatch({
        type: GET_JOBS_APPLICATIONS,
        payload: response.data,
      });
    }
  } catch (e) {
    console.log(e.response);
  }
};
