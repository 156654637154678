import React from 'react';
import useIpAllocationPtp from '../hooks/useIpAllocationPtp';
import AppContext from '../../../../../../../context/shared/AppContext';
import { IP_ALLOCATION_PTP_URL } from '../../../../../../../services/api/noc/IpAllocationPtpUrl';

const IpAllocationPtpProvider = ({ children }) => {
  const { data, loading, paginateChange, loadingForm, onChange, formValue, onSubmit, pages } = useIpAllocationPtp(
    `${IP_ALLOCATION_PTP_URL}`,
  );

  return (
    <AppContext.Provider
      value={{
        data,
        loading,
        paginateChange,
        loadingForm,
        onChange,
        formValue,
        onSubmit,
        pages,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export default IpAllocationPtpProvider;
