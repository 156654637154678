/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useContext } from 'react';
import { AccessPointConnectionContext } from './context/AccessPointConnectionContext';
import CustomButton from '../../../../shared/forms/CustomButton';
import CustomInput from '../../../../shared/forms/CustomInput';

const AddAccessPointConnection = () => {
  const { loadingForm, onChange, formValue, onSubmit } = useContext(AccessPointConnectionContext);
  const { name, ip_address, device_type, number_of_connections } = formValue;
  const submitForm = () => {
    return name.length > 0 && ip_address.length > 0 && device_type.length > 0 && number_of_connections.length > 0;
  };
  return (
    <div id="add-access-point" className="modal fade mt-5" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header shadow">
            <h4 className="modal-title update_asset">Access Point Connection</h4>
            <button type="button" className="btn btn-outline-info btn-circle btn-close" data-dismiss="modal">
              x
            </button>
          </div>
          <div className="modal-body detail mb-4 p-0">
            <form
              className="col-md-12 p-0"
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              <div className="d-flex">
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="customerName">
                    Name
                  </label>
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="name"
                    value={name}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="name"
                    type="text"
                    id="customerName"
                  />
                </div>
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="customerEmail">
                    IP Address
                  </label>

                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="ip_address"
                    value={ip_address}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="IP ADDRESS"
                    type="text"
                    id="customerEmail"
                  />
                </div>
              </div>

              <div className="d-flex">
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="router_id">
                    Device Type
                  </label>

                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="device_type"
                    value={device_type}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="Device Type"
                    type="text"
                    id="router_id"
                  />
                </div>
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="radioMACID">
                    Number of Connections
                  </label>

                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="number_of_connections"
                    value={number_of_connections}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="Number of Connections"
                    type="text"
                    id="radioMACID"
                  />
                </div>
              </div>

              <div className="text-center mt-3">
                {loadingForm ? (
                  <CustomButton classname="btn btn-green px-3" name="Adding" disabled={loadingForm} />
                ) : (
                  <CustomButton classname="btn btn-green px-3" name="Add" disabled={!submitForm()} />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAccessPointConnection;
