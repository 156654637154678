/* eslint-disable react/require-default-props */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import AdminDepartmentSideBar from '../../../../layouts/admin-department/AdminDepartmentSideBar';
import Logout from '../../../shared/auth/Logout';
import Requisitions from '../../../shared/requisition/shared/Requisitions';
import useCheckDepartment from '../../../../../hooks/shared/custom/check-department/useCheckDepartment';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import { ADMIN_DEPARTMENT } from '../../../../../utils/departments/helpers';
import AccountsHrRequisitions from '../../../shared/requisition/shared/accounts-hr/AccountsHrRequisitions';
import { useUser } from '../../../../../hooks/shared/user/useUser';

const AdminDepartmentRequisition = ({ history }) => {
  useCheckDepartment(`${LOGGED_IN_USER_URL}`, `${ADMIN_DEPARTMENT}`, history);
  const { user, profile } = useUser();
  const showContent = () => {
    if (profile && profile.email === 'idowu.aruwajoye@tizeti.com') {
      return <AccountsHrRequisitions />;
    }
    return <Requisitions />;
  };

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <div className="d-flex col-lg-12 p-2 top-header">
          <div className="mt-3 d-flex col-lg-12 p-0">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link className="s-sidebar__trigger" to="#">
              <i className="fa fa-bars mr-3" />
            </Link>
            <AdminDepartmentSideBar />

            <h3 className="col-lg-6 overview-part">Requisition</h3>

            <span className="navbar-text d-flex">
              <h6 className="mt-2">
                Admin
                <Logout />
              </h6>
            </span>
          </div>
        </div>
        <div className="after-summary col-12">{showContent()}</div>
      </main>
    </div>
  );
};

AdminDepartmentRequisition.propTypes = {
  history: PropTypes.shape(),
};

export default AdminDepartmentRequisition;
