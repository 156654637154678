/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
import React, { Component } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ViewUserProfile from '../../shared/staff/ViewUserProfile';
import profile from '../../../../assets/img/icons/Profile.png';
import antenna1 from '../../../../assets/img/icons/Noc/antena1.fw.png';
import antenna2 from '../../../../assets/img/icons/Noc/antena2.fw.png';
import { getUser } from '../../../../actions/shared/user/UserActions';
import { NOC, SUPER_ADMIN } from '../../../../utils/departments/helpers';
import {
  getBasestations,
  getLatestBasestations,
  getBaseStationCountByYear,
} from '../../../../actions/shared/basestations/BasestationsActions';
import { LOGGED_IN_USER_URL } from '../../../../services/api/auth/AuthUrl';
import NocNavBar from '../../../layouts/noc/NocNavBar';
import moment from 'moment';
import qs from 'qs';

class NetworkOperationsOverview extends Component {
  // static propTypes = {
  //   user: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  //   getUser: PropTypes.func.isRequired,
  //   latest: PropTypes.oneOfType([PropTypes.array, PropTypes.number]).isRequired,
  //   basestations: PropTypes.array.isRequired,
  //   getLatestBasestations: PropTypes.func.isRequired,
  //   getBasestations: PropTypes.func.isRequired,
  // };

  // componentDidMount() {
  //   this.props.getBasestations();
  //   this.props.getUser();
  //   this.props.getLatestBasestations();
  //   this.props.getBaseStationCountByYear(new Date().getFullYear())
  //   this.checkDepartment();
  // }

  render() {
    // const { latest, basestations, getBaseStationCountByYear, basestationCountPerYear, loading } = this.props;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <NocNavBar currentPage="Overview" />

          <div className="container">
            <div className="row mt-4">
              <ViewUserProfile />
              <div className="mt-5 d-flex justify-content-between flex-wrap col-lg-12 col-md-12 col-sm-12">
                <div className="col-lg-5 col-md-5 col-sm-12 summary copy-font">
                  NOC Summary
                  <br />
                  <small>Showing statistics for the past quarter</small>
                </div>
                <div className="noc-opt col-lg-4 col-md-4 col-sm-12 d-flex copy-font mt-1">
                  <small className="copy-font mr-2">Filter By:</small>
                  {/* <span>
                    <select className="form-control">
                      <option>Quarter 1</option>
                    </select>
                  </span> */}

                  <span>
                    {/* <select className="form-control" onChange={e => {
                      getBaseStationCountByYear(e.target.value)
                    }}>
                      {Array.from({length: 4}, (_, i) => (
                        <option key={i}>{moment().subtract(i, 'year').format('YYYY')}</option>
                      ))}
                    </select> */}
                  </span>
                </div>
              </div>
              <div className="my-3 col-lg-12 col-md-12 col-sm-12">
                <a href="http://www.tizeti.com/analytics.php" className="ml-3 mb-5" target="_blank">
                  Basestation Connected Users
                </a>
              </div>

              <div className="my-1 col-lg-12 col-md-12 col-sm-12">
                <p className="ml-3 mb-2" style={{ fontSize: '19px' }}>
                  {/* {`${moment().subtract(2, 'M').format('YYYY/MM/DD')} --- ${moment().format('YYYY/MM/DD')}`} */}
                </p>
              </div>

              <div className="col-lg-12 side d-flex">
                <div className="col-lg-2">
                  <div className="col-lg-12">
                    <div className="boxes" id="boxx">
                      <div className="text-center mt-3">
                        <img src={antenna2} alt="" />
                      </div>
                      {/* {newbasestations ? <div className="count">{newbasestations}</div> : null} */}
                      {/* <div className="count">{latest}</div> */}
                      <h3 style={{ color: '#5cc14b' }}>New Basestations</h3>
                    </div>
                  </div>

                  <div className="col-lg-12 mt-3">
                    <div className="boxes">
                      <div className=" text-center mt-3">
                        <img src={antenna1} alt="homepage" />
                      </div>
                      {/* {basestations ? <div className="count">{basestations.length}</div> : null} */}
                      <h3 style={{ color: '#CF0A0A' }}>Basestations</h3>
                    </div>
                  </div>

                  <div className="col-lg-12 mt-3">
                    <div className="boxes">
                      <div className=" text-center mt-3">
                        <img src={antenna1} alt="homepage" />
                      </div>
                      {/* {basestationCountPerYear ? <div className="count">{loading ? '...': basestationCountPerYear}</div> : null} */}
                      <h3 style={{ color: '#000', fontSize: '12px' }}>Basestations By Year</h3>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-12 side d-flex">
                                    <div className="col-lg-2">
                                        <div className="col-lg-12">
                                        <div className="boxes" id="boxx">
                                                <div className="text-center mt-3">
                                                    <img src={antenna2} alt=""/>
                                                </div>

                                            {/*{newbasestations ? <div className="count">{newbasestations}</div> : null
                                            <div className="count">{latest}</div>
                                                <h3 style={{ color:'#5cc14b' }}>New Basestations</h3>
                                            </div>
                                        </div>
                                    </div>
                            </div> */}
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  latest: state.latestBasestations.latestBasestations,
  basestations: state.basestations.basestations,
  basestationCountPerYear: state.basestations.basestationCountByYear,
  loading: state.basestations.load,
});

export default connect(mapStateToProps, {
  getUser,
  getLatestBasestations,
  getBasestations,
  getBaseStationCountByYear,
})(NetworkOperationsOverview);
