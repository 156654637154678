/* eslint-disable react/button-has-type */
import React from 'react';
import CustomerUsageTable from './CustomerUsageTable';
import useArchive from '../../../../../../hooks/customer-support/useArchive';

const CustomerUsage = () => {
  const { onChangeForUsage, search, searcUsageWithDate, loading, usage } = useArchive();
  const { mac, from, to } = search;
  const getUsage = (e) => {
    e.preventDefault();
    const data = {
      action: 'getDetailedCustomerUsageArchive',
      startdate: from,
      enddate: to,
      mac,
    };

    searcUsageWithDate(data);
  };

  const searchButton = () => {
    return mac.length > 1 && from.length > 0 && to.length > 0;
  };
  return (
    <div className="row">
      <div className="col-lg-12 mt-3 mb-3">
        <form className="form-inline mt-4 inner-addon d-flex" onSubmit={getUsage}>
          <div className="input-group mb-3 mr-5 mt-2">
            <p className="d-flex mr-auto copy-font">
              <small className="col-lg-2 p-0">Show from </small>
              <input
                name="mac"
                value={mac}
                onChange={onChangeForUsage}
                type="text"
                className="form-control mr-2"
                placeholder="enter mac id to search with"
              />
              <input type="date" className="form-control mr-2" name="from" value={from} onChange={onChangeForUsage} />
              <small>to </small>
              <input type="date" className="form-control ml-2" name="to" value={to} onChange={onChangeForUsage} />
            </p>
          </div>

          <div className="mb-3 ml-3">
            <button className="btn btn-green" style={{ height: '39px' }} disabled={!searchButton()}>
              Search
            </button>
          </div>
        </form>
      </div>
      <div className="card x-panel">
        <div className="table-responsive content">
          <table className="table copy-font">
            <thead>
              <tr>
                <th>#</th>
                <th nowrap="no-wrap">Uploaded</th>
                <th> Downloaded </th>
                <th nowrap="no-wrap">From</th>
                <th nowrap="no-wrap"> To </th>
                <th nowrap="no-wrap"> total Duration </th>
                <th nowrap="no-wrap"> Connection Counts </th>
              </tr>
            </thead>
            {loading ? <small>loading...</small> : <CustomerUsageTable value={usage} />}
            {/* <UsageHistoryTable customers={customer} /> */}
          </table>
        </div>
      </div>
    </div>
  );
};

export default CustomerUsage;
