/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useRef, memo } from 'react';
import { useActivation } from '../../../../../hooks/shared/installations/useActivation';
import * as helpers from '../../../../../utils/validateMAC/helpers';
import NewDesignActivation from './NewDesignActivation';

const NewDesignValidateMACID = ({ account, assignedStaff, assignedStaffCount }) => {
  const btnRef = useRef();
  const { isValid, validMAC, isChecking, checkMACID } = useActivation();

  const [mac, setMAC] = useState('');
  const [MAC_type, setMAC_type] = useState('Non-LTE');

  const handleSpaceBar = (e) => {
    if (e.keyCode === 32) e.preventDefault();
  };

  const handleChange = (e) => {
    const { value } = e.target;
    if (MAC_type === 'Non-LTE') return setMAC(helpers.NonLTEInput(value, mac));
    return setMAC(helpers.LTEInput(value));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    checkMACID(mac);
  };

  const onBtnDisable = () => {
    if (btnRef.current) {
      btnRef.current.setAttribute('disabled', 'disabled');
    }
  };

  const onBtnEnable = () => {
    if (btnRef.current) {
      btnRef.current.removeAttribute('disabled');
    }
  };

  const submitForm = () => {
    if (MAC_type === 'Non-LTE') return helpers.cvLength >= 12;
    return mac.length === 15;
  };

  return (
    <>
      <div className="modal" id="validateMAC">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center" id="exampleModalLabel">
                Validate MAC ID
              </h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h4 className="text-center">
                Confirm MAC ID for &#34;
                {account ? `${account.firstName} ${account.lastName}` : 'N/A'}&#34;?
              </h4>
              <form onSubmit={onSubmit}>
                <div className="col-12 mb-3">
                  <div className="col-12">
                    <label className="copy-font m-0 p-0">Radio MAC ID Type</label>
                    <div className="col-12 d-flex">
                      <div className="form-check mr-2">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="MAC_type"
                          id="LTE"
                          value="LTE"
                          checked={MAC_type === 'LTE'}
                          onChange={(e) => {
                            setMAC_type(e.target.value);
                            setMAC('');
                          }}
                        />
                        <label className="form-check-label p-0" style={{ borderBottom: '0' }} htmlFor="LTE">
                          LTE
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="MAC_type"
                          id="Non-LTE"
                          value="Non-LTE"
                          checked={MAC_type === 'Non-LTE'}
                          onChange={(e) => {
                            setMAC_type(e.target.value);
                            setMAC('');
                          }}
                        />
                        <label className="form-check-label p-0" style={{ borderBottom: '0' }} htmlFor="Non-LTE">
                          Non-LTE
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 mb-3 d-flex">
                  <div className="col-12">
                    <label className="copy-font m-0 p-0">Radio MACID</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={MAC_type === 'Non-LTE' ? 'xx:xx:xx:xx:xx:xx' : 'xxxxxxxxxxxxxxx'}
                      name="mac"
                      value={mac}
                      onKeyDown={handleSpaceBar}
                      onChange={handleChange}
                      maxLength={MAC_type === 'Non-LTE' ? 17 : 15}
                      pattern={
                        MAC_type === 'Non-LTE'
                          ? '[0-9A-Z]{2}:[0-9A-Z]{2}:[0-9A-Z]{2}:[0-9A-Z]{2}:[0-9A-Z]{2}:[0-9A-Z]{2}'
                          : '[0-9]{15}'
                      }
                      title={
                        MAC_type === 'Non-LTE'
                          ? 'Please enter a valid Non-LTE MAC ID (XX:XX:XX:XX:XX:XX)'
                          : 'Please enter a valid 15-digit LTE MAC ID (XXXXXXXXXXXXXXX)'
                      }
                    />
                  </div>
                </div>
                <div className="col-12 mb-4">
                  <div className="text-center">
                    {isValid === '' ? null : isValid === true ? (
                      <button
                        type="button"
                        data-toggle="modal"
                        href="#create-acct"
                        className="btn btn-green"
                        ref={btnRef}
                        onClick={onBtnDisable}
                      >
                        Proceed To Activation
                      </button>
                    ) : (
                      <p className="text-danger h5">Kindly Clean Radio And Try Again</p>
                    )}
                  </div>
                </div>

                <div className="modal-footer">
                  {/* {assignedStaff ? (
                    'Checking If Instaalationation Is Assigned...'
                  ) : assignedStaffCount > 0 || account.team ? ( */}
                  {isChecking ? (
                    <button type="button" className="btn btn-secondary " disabled>
                      Checking...
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-primary" disabled={!submitForm()} onClick={onBtnEnable}>
                      Validate
                    </button>
                  )}
                  {/* ) : (
                    <span className="text-danger text-uppercase">Assign to Individual/Team First</span>
                  )} */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <NewDesignActivation account={account} validMAC={validMAC} />
      {/* <CreateAccount account={account} validMAC={validMAC} /> */}
    </>
  );
};

export default memo(NewDesignValidateMACID);
