import axios from 'axios';
import toastr from 'toastr';

const endpoint = 'https://legacy.tizeti.com/geniatechapi/index.php';

export const get_bizdev_active_inactive_by_zone = async (initialState) => {
  const formData = new URLSearchParams(initialState).toString();
  try {
    const res = await axios.post(endpoint, formData);
    if (res.data) {
      // console.log(res.data);
      return res.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error('error fetching data');
  }
};
