import React, { useContext } from 'react';
import AppContext from '../../../../../context/shared/AppContext';
import './index.css';

const LteInstallationSummary = () => {
  const { count } = useContext(AppContext);
  const total = Object.values(count);
  const daily = total[0];
  const weekly = total[1];
  const monthly = total[2];
  return (
    count && (
      <div className="summary">
        <h3> LTE installation Daily : {daily} </h3>
        <h3> LTE installation Weekly : {weekly} </h3>
        <h3> LTE installation Monthly : {monthly} </h3>
      </div>
    )
  );
};

export default LteInstallationSummary;
