import axios from 'axios';
import toastr from 'toastr';

const supportCalls = 'https://api.tizeti.com/api/v1/supportCall/graphCount';

export const get_support_calls = async (initialState) => {
  const formData = new URLSearchParams(initialState).toString();
  try {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    };
    const res = await axios.post(supportCalls, formData, {
      headers: headers,
    });
    if (res.data) {
      return res.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error('error fetching data');
  }
};
