/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { SetStateAction, useEffect, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import view from '../../../../assets/img/icons/view.png';
import LeadsDetails from '../../departments/sales/leads/LeadsDetails';
import EditLeads from '../../departments/sales/leads/EditLead';
import AddLTEUser from '../../departments/sales/leads/AddLTEUser';
import helpers from '../../../../utils/helpers';
import CustomButton from '../forms/CustomButton';
import SalesSendBulkSms from '../../departments/sales/leads/SalesSendBulkSms';
import usePaidLeads from '../../departments/sales/leads/hooks/usePaidLeads';
import { useUser } from '../../../../hooks/shared/user/useUser';
import { messageToBeSent } from '../../departments/sales/leads/utils/helpers';
import SendBulkEmails from '../../departments/sales/leads/SendBulkEmails';
import { showContentForDepartment } from './utils/helpers';
import { SALES } from '../../../../utils/config';

const PaidLeadsTable = ({ leads }) => {
  const { sendSms, bulkModal, onOpenBulkModal, sendingSms, onOpenBulkEmailModal, bulkEmailModal } = usePaidLeads();
  const { profile, user } = useUser();
  const [smsData, setSmsData] = useState([]);
  const [emailData, setEmailData] = useState([]);
  const [sendBulk, setSendBulk] = useState(false);
  const [sendBulkEmail, setSendBulkEmail] = useState(false);
  const [status, setStatus] = useState('');
  const [leadId, setLeadId] = useState({
    created_at: '',
    email: '',
    firstName: '',
    id: 0,
    lastName: '',
    phoneNumber: '',
    paidId: 0,
    isChecked: false,
    address: '',
  });

  const [newLeads, setNewLeads] = useState(leads);
  const getLead = (lead) => setLeadId(lead);
  useEffect(() => {
    if (leads && leads.length) {
      setNewLeads(
        leads.map((item, i) => {
          const { created_at, email, firstName, id, lastName, phoneNumber, address } = item;
          return {
            isChecked: false,
            created_at,
            email,
            firstName,
            id,
            lastName,
            phoneNumber,
            paidId: i + 1,
            address,
          };
        }),
      );
    }
  }, [leads]);
  const handleAllChecked = (event) => {
    const { checked } = event.target;
    setNewLeads(
      newLeads.map((dat) => {
        dat.isChecked = checked;
        return dat;
      }),
    );
    if (newLeads.filter((er) => er.isChecked).length > 0) {
      setSendBulk(true);
      setSendBulkEmail(true);
    } else {
      setSendBulk(false);
      setSendBulkEmail(false);
    }
  };
  const onSelectOne = (e, id) => {
    setNewLeads(
      newLeads.map((dat) => {
        if (id === dat.paidId) {
          dat.isChecked = e.target.checked;
        }
        return dat;
      }),
    );
    if (newLeads.filter((er) => er.isChecked).length > 0) {
      setSendBulk(true);
      setSendBulkEmail(true);
    } else {
      setSendBulk(false);
      setSendBulkEmail(false);
    }
  };
  const openBulkEmailModal = () => {
    if (!profile.email) {
      toast.info('Kindly create a profile');
    } else {
      setEmailData(
        newLeads
          .filter((da) => da.isChecked)
          .map((use) => ({
            email: use.email,
          }))
          .map((opt) => opt.email),
      );
      onOpenBulkEmailModal();
    }
  };
  const openBulkModal = () => {
    if (!profile.email) {
      toast.info('Kindly create a profile');
    } else {
      setSmsData(
        newLeads
          .filter((da) => da.isChecked)
          .map((use) => ({
            email: use.email,
            phone: Number(use.phoneNumber),
            name: `${use.firstName} ${use.lastName}`,
          })),
      );
      onOpenBulkModal();
    }
  };
  const sendMessages = () => {
    const newS = [];
    smsData.forEach((element) => {
      newS.push(Object.values(element));
    });
    sendSms({
      action: 'sales_sms_helper',
      staff_email: profile.officialEmail,
      message: messageToBeSent(status, profile.firstName),
      customer_emails_phone_name: newS,
    });
  };
  const showBulkButton = () => {
    return (
      <div className="form-group mx-2">
        <CustomButton classname="btn btn-green" disabled={false} onClick={openBulkModal} name="Send Bulk SMS" />
      </div>
    );
  };
  const showBulkEmailButton = () => {
    return (
      <div className="form-group mx-2">
        <CustomButton classname="btn btn-green" disabled={false} onClick={openBulkEmailModal} name="Send Bulk Email" />
      </div>
    );
  };
  const showInputHeader = () => {
    return (
      <th>
        <input type="checkbox" onChange={handleAllChecked} value="checkedall" />
      </th>
    );
  };
  const showInputs = (param) => {
    return (
      <td>
        <input
          type="checkbox"
          checked={param.isChecked}
          value={param.paidId}
          onChange={(e) => onSelectOne(e, param.paidId)}
        />
      </td>
    );
  };
  return (
    <div className="card x-panel logs">
      <div style={{ display: 'flex' }}>
        {sendBulk && showContentForDepartment(`${SALES}`, user.department_id, showBulkButton())}
        {sendBulkEmail && showContentForDepartment(`${SALES}`, user.department_id, showBulkEmailButton())}
        <SalesSendBulkSms
          status={status}
          setStatus={setStatus}
          onClose={openBulkModal}
          sendSms={sendMessages}
          show={bulkModal}
          loading={sendingSms}
        />
        <SendBulkEmails show={bulkEmailModal} onClose={openBulkEmailModal} data={emailData} />
      </div>
      <div className="table-responsive content">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              {showContentForDepartment(SALES, user.department_id, showInputHeader())}
              <th>First Name</th>
              <th>Last Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Date</th>
              {/* <th className="text-center">Schedule</th> */}
              <th className="text-center" style={{ paddingLeft: '5px' }}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody id="reduce-length">
            {newLeads &&
              newLeads.map((lead, i) => (
                <tr key={lead.id}>
                  <td>{i + 1}</td>
                  {showContentForDepartment(SALES, user.department_id, showInputs(lead))}
                  <td>
                    <div>{lead.firstName}</div>
                  </td>
                  <td>
                    <div>{lead.lastName}</div>
                  </td>
                  <td>
                    <div>{lead.phoneNumber}</div>
                  </td>
                  <td>
                    <div className="shorte">{lead.email}</div>
                  </td>
                  <td>{helpers.formatDateAndTime(lead.created_at)}</td>
                  {/* <td>
                    <Link
                      to={{
                        pathname: '/schedule',
                        state: { detail: lead },
                      }}
                    >
                      <img
                        src="https://res.cloudinary.com/teewhy/image/upload/v1545666961/Scheduler.png"
                        width="15%"
                        style={{ cursor: 'pointer' }}
                        data-toggle="modal"
                        alt=""
                        className="w-25 ml-5"
                      />
                    </Link>
                    <div />
                  </td> */}

                  <td>
                    <div>
                      <img
                        src={view}
                        width="15%"
                        style={{ cursor: 'pointer' }}
                        data-toggle="modal"
                        alt=""
                        className="w-0 ml-3"
                        onClick={() => getLead(lead)}
                        data-target="#leadsdetails"
                      />
                      <LeadsDetails lead={leadId} />
                      <img
                        src="https://res.cloudinary.com/teewhy/image/upload/v1543579893/edit.png"
                        width="15%"
                        style={{ cursor: 'pointer' }}
                        data-toggle="modal"
                        alt=""
                        className="w-0 ml-3"
                        onClick={() => getLead(lead)}
                        data-target="#editleads"
                      />
                      <EditLeads lead={leadId} />

                      <button
                        type="button"
                        data-toggle="modal"
                        data-target="#addLTE"
                        className="w-0 ml-3"
                        onClick={() => getLead(lead)}
                      >
                        <i className="fa fa-user-plus" />
                      </button>
                      <AddLTEUser id={leadId.id} />
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PaidLeadsTable;
