import { GET_USAGE_STATISTICS } from '../../../../constants/types/shared/customers/UsageHistoryTypes';
import { LOADING_STARTS, LOADING_STOPS } from '../../../../constants/types/shared/LoadingTypes';

const initialState = {
  items: [],
  totalPages: '',
  allPages: '',
  currentPage: '',
  isLoading: false,
};

const PaymentHistoryReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOADING_STARTS:
      return {
        ...state,
        isLoading: true,
      };

    case GET_USAGE_STATISTICS:
      return {
        ...state,
        items: payload.items,
        totalPages: payload.totalPages,
        allPages: payload.allPages,
        currentPage: payload.currentPage,
        isLoading: false,
      };

    case LOADING_STOPS:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default PaymentHistoryReducer;
