/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import TracklistCategoryTable from './TracklistCategoryTable';
import CreateTracklistCategory from './CreateTracklistCategory';
import { useTracklistCategory } from './hooks/useTracklistCategory';

const TracklistCategoryBody = () => {
  const { isLoading, lists, getTracklistCategory } = useTracklistCategory();

  useEffect(() => {
    getTracklistCategory();
  }, []);

  return (
    <div className="container pt-5">
      <div className="row mt-5">
        <div className="p-0 mb-2 col-12 d-flex">
          <div>
            <div className="form-group ml-3">
              <button className="btn btn-green" type="button" data-toggle="modal" data-target="#add_item">
                Create Item
              </button>
            </div>
          </div>
        </div>
        <CreateTracklistCategory />
        <TracklistCategoryTable lists={lists} load={isLoading} />
      </div>
    </div>
  );
};

export default TracklistCategoryBody;
