/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/camelcase */
import React, { Component } from 'react';
import toastr from 'toastr';
import axios from 'axios';
// import moment from 'moment';
import PropTypes from 'prop-types';
import { API } from '../../../../../services/api/tizeti';
import { accounts, admin, API_URL } from '../../../../../utils/config';
// import add from '../../../../../assets/img/icons/Add.svg';
// import view from '../../../../../assets/img/icons/view.png';
// import edit from '../../../../../assets/img/icons/edit.png';
// import cancel from '../../../../../assets/img/icons/cancel-img.png';
import AccountsNavBar from '../../../../layouts/accounts/AccountsNavBar';

class AccountsMailBlast extends Component {
  constructor(props) {
    super(props);

    this.state = {
      campaigns: [],
      loading: false,
      name: '',
      subject: '',
      body: '',
      fromname: '',
      fromemail: '',
      list: '',
      lists: [],
      one: {},
      id: '',
      listName: '',
    };

    this.createCampaign = this.createCampaign.bind(this);
    this.onChange = this.onChange.bind(this);
    this.deleteOne = this.deleteOne.bind(this);
  }

  componentWillMount() {
    this.fetchLoggedOnUser();
  }

  componentDidMount() {
    this.getCampaigns();
    this.getLists();
  }

  componentWillReceiveProps() {
    this.fetchLoggedOnUser();
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  async getLists() {
    const response = await API.get(`/mail/list`);
    if (response.data.data) {
      this.setState({
        lists: response.data.data,
      });
    }
  }

  async getCampaigns() {
    this.setState({
      loading: true,
    });

    const response = await API.get(`/mail/campaigns`);

    if (response.data.data) {
      this.setState({
        campaigns: response.data.data,
        loading: false,
      });
    }
  }

  getOne = (list) =>
    this.setState(
      {
        one: list.attributes,
        id: list.id,
        listName: list.attributes.name,
      },
      // eslint-disable-next-line no-console
      () => console.log(list.attributes),
    );

  deleteOne = (list) => {
    const data = { name: list.attributes.name };
    const url = `https://api.tizeti.com/api/v1/mail/list/${list.id}`;
    axios({
      method: 'delete',
      url,
      data,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      }
    });
    toastr.success('Deleted!', 'List', {
      timeOut: 1000,
      fadeOut: 1000,
      onHidden() {
        window.location.reload();
      },
    });
  };

  createCampaign(e) {
    e.preventDefault();
    const { name, subject, body, fromname, fromemail, list } = this.state;
    const campaign = {
      name,
      subject,
      body,
      from_name: fromname,
      from_email: fromemail,
      list_id: list,
    };

    API.post(`/mail/campaigns`, campaign).then((res) => {
      if (res.data.data.id) {
        this.setState({
          name: '',
          subject: '',
          body: '',
          fromname: '',
          fromemail: '',
          list: '',
        });
        toastr.success('Campaign created successfully!');
        this.getCampaigns();
      }
    });
  }

  fetchLoggedOnUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employeeReg/logged`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        if (data.data.department_id !== `${accounts}` && data.data.department_id !== `${admin}`) {
          // eslint-disable-next-line react/destructuring-assignment
          this.props.history.push('/staff/login');
          toastr.info('You are not allowed to view this page');
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
      .catch((err) => {});
  }

  render() {
    const { campaigns, loading, lists, one, id, listName, name, fromname, body, fromemail, subject } = this.state;

    // const listBody = lists ? (
    //   lists.map((list, i) => (
    //     <tr>
    //       <td>{i + 1}</td>
    //       <td onClick={() => this.getOne(list)}>{list.attributes.name}</td>
    //       <td>{moment(list.attributes.created_at).format('DD-MM-YYYY')}</td>
    //       <td>
    //         <div className="d-flex ml-3 img-pointer">
    //           <div data-toggle="modal" data-target="#addSubscribers">
    //             <img src={add} alt="add" onClick={() => this.getOne(list)} />
    //           </div>
    //           <AddSubscribers id={id} />
    //           <div className="view1 ml-2 img-pointer">
    //             <img src={view} alt="" />
    //           </div>
    //         </div>
    //       </td>
    //       <td>
    //         <div className="d-flex ml-2">
    //           <div className="view1 img-pointer" data-target="#editList" data-toggle="modal">
    //             <img src={edit} alt="add" onClick={() => this.getOne(list)} />
    //           </div>
    //           <EditList one={one} id={id} />
    //           <div className="view1 ml-2 img-pointer">
    //             <img src={cancel} alt="cancel" onClick={() => this.deleteOne(list)} />
    //           </div>
    //         </div>
    //       </td>
    //     </tr>
    //   ))
    // ) : (
    //   <h1>No lists</h1>
    // );

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <AccountsNavBar currentPage="Send Mail" position="" />
          <div className="all-sides col-lg-12 col-md-12 col-sm-12">
            {/* <div className="page-wrapper col-lg-10">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="tab-stuff col-4 mt-4">
                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                      <li className="nav-item mr-auto">
                        <a
                          className="nav-link active"
                          id="pills-home-tab"
                          data-toggle="pill"
                          href="#pills-home"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                        >
                          Create Campaign
                        </a>
                      </li>
                      <li className="nav-item mr-auto">
                        <a
                          className="nav-link"
                          id="pills-profile-tab"
                          data-toggle="pill"
                          href="#pills-profile"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="false"
                        >
                          Lists
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="tab-content col-12 col-lg-12 p-0" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >
                      <div className="col-lg-12 p-0 page-title mt-4 mb-3 d-flex">
                        <div className="col-lg-6 p-0">
                          <div className="main-content col-lg-12 p-0">
                            <form className="form-horizontal col-lg-12 p-0" onSubmit={this.createCampaign}>
                              <div className="form-group col-lg-12 p-0">
                                <div className="col-lg-12 d-flex mb-3 p-0">
                                  <div className="col-lg-6">
                                    <div className="col-lg-12 mb-3 p-0">
                                      <label className="copy-font m-0 p-0">Campaign Name</label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        name="name"
                                        onChange={this.onChange}
                                        value={name}
                                        placeholder="Campaign Name"
                                      />
                                    </div>
                                    <div className="col-lg-12 mb-3 p-0">
                                      <label className="copy-font m-0 p-0">From Name</label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        name="fromname"
                                        onChange={this.onChange}
                                        value={fromname}
                                        placeholder="Sender Name"
                                      />
                                    </div>
                                  </div>

                                  <div className="col-lg-6">
                                    <label className="copy-font m-0 p-0">Body</label>
                                    <textarea
                                      className="form-control"
                                      name="body"
                                      onChange={this.onChange}
                                      value={body}
                                      rows="4"
                                      cols="80"
                                      type="text"
                                      placeholder="Message Body"
                                    />
                                  </div>
                                </div>

                                <div className="col-12 d-flex mb-3 p-0">
                                  <div className="col-lg-6">
                                    <label className="copy-font m-0 p-0">From Email</label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      placeholder="End Date"
                                      name="fromemail"
                                      onChange={this.onChange}
                                      value={fromemail}
                                    />
                                  </div>

                                  <div className="col-lg-6">
                                    <label className="copy-font m-0 p-0">Send to Lists</label>
                                    <select className="form-control" name="list" onChange={this.onChange}>
                                      <option>Select List</option>
                                      {lists.map((list) => (
                                        <option value={list.id}>{list.attributes.name}</option>
                                      ))}
                                    </select>
                                  </div>
                                </div>

                                <div className="col-12 d-flex mb-3 p-0">
                                  <div className="col-lg-6">
                                    <label className="copy-font m-0 p-0">Subject</label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      placeholder="Subject"
                                      name="subject"
                                      onChange={this.onChange}
                                      value={subject}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12 button-cat d-flex mt-5">
                                <button className="btn btn-green">Send Now</button>
                              </div>
                            </form>
                          </div>
                        </div>

                        <div className="col-6 p-0">
                          <div className="main-content col-12">
                            <h6 className="mb-1">
                              <strong>Campaigns</strong>
                            </h6>
                            <div className="card x-panel m-0 p-2">
                              <div className="table-responsive content">
                                <table className="table copy-font">
                                  <CampaignsTableHead />
                                  <CampaignsTableBody campaigns={campaigns} loading={loading} />
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="pills-profile"
                      role="tabpanel"
                      aria-labelledby="pills-profile-tab"
                    >
                      <div className="col-lg-12 text-right">
                        <button className="btn btn-green" data-target="#addList" data-toggle="modal">
                          {' '}
                          Create List
                        </button>
                        <CreateList />
                      </div>
                      <div className="col-lg-12 p-0 page-title mt-4 mb-3 d-flex">
                        <div className="col-lg-6 p-0">
                          <div className="main-content col-12">
                            <h6 className="mb-1">
                              <strong>Lists</strong>
                            </h6>
                            <div className="card x-panel m-0 p-2">
                              <div className="table-responsive content">
                                <table className="table copy-font">
                                  <ListsTableHead />
                                  <tbody>{listBody}</tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-6 p-0">
                          <SubscribersTable id={id} name={listName} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        */}
          </div>
        </main>
      </div>
    );
  }
}
AccountsMailBlast.propTypes = {
  // eslint-disable-next-line react/require-default-props
  history: PropTypes.shape(),
};
export default AccountsMailBlast;
