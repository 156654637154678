import React, { useContext } from 'react';
import CustomInput from '../../../forms/CustomInput';
import CustomButton from '../../../forms/CustomButton';
import AppContext from '../../../../../../context/shared/AppContext';

const LteCustomersSearch = () => {
  const { onSearch, search, onChange } = useContext(AppContext);

  const getData = () => {
    onSearch();
  };
  return (
    <div className="form-inline ml-3">
      <div className="input-group mb-3 mr-3">
        <CustomInput
          classname="form-control py-2 mr-auto empty pl-5"
          name="imsi"
          value={search.imsi}
          onChange={onChange}
          readOnly={false}
          type=""
        />
      </div>
      <div className="mb-3">
        <CustomButton onClick={() => getData()} classname="btn btn-green" name="Search" disabled={false} />
      </div>
    </div>
  );
};

export default LteCustomersSearch;
