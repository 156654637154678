import React, { Component } from 'react';
import toastr from 'toastr';
import axios from 'axios';
import RetailerDetails from '../retailer/RetailerDetails';
import EditRetailer from '../retailer/EditRetailers';
import URLS from '../../../../../../../services/api/hotspot/HotspotAdminUrls';

import FundWallet from '../wallet/FundWallet';

class retailerFundRows extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileFullName: '',
      profileBusinessName: '',
      profilePhone: '',
      profileEmail: '',
      profileBalance: '',
      profileAccountid: '',
      retailerResponse: [],
    };
  }

  getRetailer(
    retailerid,
    fullname,
    profileBusinessName,
    balance,
    phone,
    email,
  ) {
    this.setState({
      profileAccountid: retailerid,
      profileBusinessName,
      profilePhone: phone,
      profileFullName: fullname,
      profileBalance: balance,
      profileEmail: email,
    });
  }

  render() {
    return this.props.retailerAccount.map((item, key) => (
      <tr className="copy-font" key={item.accountid}>
        <th scope="row">{key + 1}</th>
        <td>{item.fullname}</td>
        <td>{item.businessName}</td>
        <td>{item.phone}</td>
        <td>{item.email}</td>
        <td>
          {' '}
          <span className="amount">&#8358;</span>{' '}
          {item.balance}
        </td>
        <td>{item.accountid}</td>
        <td
          className="text-center activate-check"
          onClick={() =>
            this.getRetailer(
              item.accountid,
              item.fullname,
              item.businessName,
              item.balance,
              item.phone,
              item.email,
            )
          }
          data-toggle="modal"
          data-target="#fundwallet"
        >
          <img
            src="https://res.cloudinary.com/teewhy/image/upload/v1549379325/Add_funds.png"
            alt="checked sign"
          />
        </td>

        <FundWallet
          fullname={this.state.profileFullName}
          businessName={this.state.profileBusinessName}
          phone={this.state.profilePhone}
          email={this.state.profileEmail}
          balance={this.state.profileBalance}
          accountid={this.state.profileAccountid}
        />
      </tr>
    ));
  }
}
export default retailerFundRows;
