import React, { useContext } from 'react';
import CreateSubZone from './CreateSubZone';
// import Search from '../zones/Search';
import SubZonesTable from './SubZonesTable';
import { ZonesContext } from '../../../shared/installations/zones/provider/ZonesContext';
import { TizetiGIFLogoTable } from '../../../../../utils/loader/loadingHelper';

const InstallationSubZonesBody = () => {
  const { loading } = useContext(ZonesContext);
  return (
    <div>
      <div className="col-lg-12 col-md-12 col-sm-12 mb-3 d-flex mt-3 flex-wrap actions">
        {/* <Search /> */}
        <div className="action">
          <div className="mt-3">
            <button className="btn btn-green" type="button" data-toggle="modal" data-target="#create-sub-zone">
              Create Sub Zone
            </button>
          </div>
        </div>
        <CreateSubZone />
      </div>
      <div className="card x-panel">
        <div className="table-responsive content">
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{loading ? <TizetiGIFLogoTable colSpan="3" /> : <SubZonesTable />}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default InstallationSubZonesBody;
