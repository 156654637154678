/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import qs from 'qs';
import useCustomerRevenue from '../../../../../hooks/business-development/customer-revenue/useCustomerRevenue';
import BusinessDevelopmentCustomerRevenueTable from './BusinessDevelopmentCustomerRevenueTable';
import CustomCsvDownload from '../../../shared/CustomCsvDownload';
import { customerRevenueHeaders, filename } from '../../../../../utils/field-support/installations/helpers';

const BusinessDevelopmentCustomerRevenueBody = () => {
  const { getCustomerRevenue, items, total, isLoading } = useCustomerRevenue();
  const [fromDate, setFromDate] = useState('2021-12-01');
  const [toDate, setToDate] = useState('2021-12-31');

  const data = qs.stringify({
    action: 'dedicated_customer_revenue_list',
    from: fromDate,
    to: toDate,
  });

  const onSubmit = (e) => {
    e.preventDefault();
    getCustomerRevenue(data);
  };

  useEffect(() => {
    getCustomerRevenue(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mt-5 p-3">
      <div className="container-fluid pt-4">
        <div className="row">
          <form className="form-inline mt-4 inner-addon d-flex" onSubmit={onSubmit}>
            <div className="form-group mx-2">
              <label className="mt-2 p-0 copy-font">From: </label>
              <input
                className="form-control mr-2"
                name="from"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
                readOnly={false}
                type="date"
              />

              <label className="mt-2 p-0 copy-font">To: </label>
              <input
                className="form-control mr-2"
                name="to"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                readOnly={false}
                type="date"
              />
            </div>

            <div className="mb-3">
              <button className="btn btn-green" style={{ height: '39px' }}>
                Search
              </button>
            </div>
          </form>
          <div className="col-12 d-flex mt-2">
            <CustomCsvDownload
              data={items || []}
              headers={customerRevenueHeaders}
              filename={filename(`Customer_Revenue`)}
              classname="btn btn-green shadow-md"
              textcolor="white"
              text="Download Data"
            />
          </div>

          <div className="pt-3 mx-3">
            Total Customer Revenue:
            <span className="badge badge-secondary ml-1">{total}</span>
          </div>

          <BusinessDevelopmentCustomerRevenueTable items={items} isLoading={isLoading} />
        </div>
      </div>
    </div>
  );
};

export default BusinessDevelopmentCustomerRevenueBody;
