import React from 'react';
import NocNavBar from '../../../../../layouts/noc/NocNavBar';
import Profiles from '../../../../shared/lte/profiles/Profiles';

const NocProfiles = () => {
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <NocNavBar currentPage="LTE Profiles" />
        <Profiles />
      </main>
    </div>
  );
};

export default NocProfiles;
