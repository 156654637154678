/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import PropTypes from 'prop-types';
import useReleaseReport from '../../../../hooks/procurement/release-report/useReleaseReport';
import useTracklist from '../tracklist/hooks/useTracklist';

const ViewReleaseItem = ({
  OneItem,
  log,
  OneItem: {
    item,
    tag,
    available_quantity,
    quantity,
    destination,
    request_by,
    released_to,
    created_at,
    approved_by: { employees } = {},
    // resource_classification: { name } = {},
  },
}) => {
  const { list } = useTracklist();

  const [tracklistArr] = useState([]);

  const rows = list
    .filter((value) => {
      if (value.item === OneItem.item) {
        return value;
      }
    })
    .map((value) => {
      return value.quantity;
    });

  const sums = rows.reduce((acc, current) => acc + current, 0);

  const row = log
    .filter((values) => {
      if (values.item === OneItem.item) {
        return values;
      }
    })
    .map((values) => {
      return values.quantity;
    });

  const sum = row.reduce((acc, current) => acc + current, 0);

  return (
    <div id="item_detail" className="modal fade mt-5" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title my-2 update_asset">Item Details</h4>
            <button type="button" className="btn btn-outline-info btn-circle btn-close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div className="modal-body detail" style={{ padding: '0' }}>
            <div className="row">
              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label1">
                  <label className="mt-1">Item Name</label>
                  <label>Class</label>
                  <label>Tag No.</label>
                </div>
                <div className="col-lg-8 detail-result1 item-font">
                  <p className="mt-4 mb-2">{item}</p>
                  {/* <p className="mb-2">{name}</p> */}
                  <p className="mb-2">{tag}</p>
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label2">
                  <label>Quantity</label>
                  <label>Available Quantity</label>
                </div>
                <div className="col-lg-8 detail-result2 item-font">
                  <p className="mt-4 mb-1">{quantity}</p>
                  {sums - sum}
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label1">
                  <label className="mt-1">Requested By</label>
                  <label className="mt-1">Approved By</label>
                  <label className="mt-1">Released To</label>
                  <label className="">Destination</label>
                </div>
                <div className="col-lg-8 detail-result2 item-font">
                  <p className="mt-4 mb-2">{request_by ? request_by.name : 'N/A'}</p>
                  <p className="mb-2">{employees ? `${employees[0].firstName} ${employees[0].lastName}` : 'N/A'}</p>
                  <p className="mb-2">
                    {released_to ? `${released_to.employees[0].firstName} ${released_to.employees[0].lastName}` : 'N/A'}
                  </p>
                  <p className="mb-2">{destination}</p>
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label3">
                  <label>Release Date</label>
                </div>
                <div className="col-lg-4 detail-result1 item-font">
                  <p className="mt-4 mb-1">
                    <Moment format="DD-MM-YYYY">{created_at}</Moment>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ViewReleaseItem.propTypes = {
  OneItem: PropTypes.shape().isRequired,
};

export default ViewReleaseItem;
