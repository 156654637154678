import { useEffect } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { LOGGED_IN_USER_URL } from '../../../../services/api/auth/AuthUrl';

const useCheckTwoDepartments = (department1, department2, history) => {
  useEffect(() => {
    const checkTwoDepartments = async () => {
      try {
        const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });
        if (response.data.department_id !== `${department1}` && response.data.department_id !== `${department2}`) {
          sessionStorage.clear();
          sessionStorage.setItem('token', '');
          sessionStorage.setItem('email', '');
          sessionStorage.setItem('officialEmail', '');
          sessionStorage.setItem('firstName', '');
          sessionStorage.setItem('lastName', '');
          sessionStorage.setItem('departmentId', '');
          sessionStorage.setItem('roleId', '');

          sessionStorage.setItem('country', '');
          history.push('/login');
          toastr.info('You are not allowed to view this page');
        }
      } catch (e) {
        toastr.error('ta');
      }
    };

    checkTwoDepartments();
  }, [department1, department2, history]);
};

export const useCheckThreeDepartments = (department1, department2, department3, history) => {
  useEffect(() => {
    const checkTwoDepartments = async () => {
      try {
        const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });
        if (
          response.data.department_id !== `${department1}` &&
          response.data.department_id !== `${department2}` &&
          response.data.department_id !== `${department3}`
        ) {
          sessionStorage.clear();
          sessionStorage.setItem('token', '');
          sessionStorage.setItem('email', '');
          sessionStorage.setItem('roleId', '');
          sessionStorage.setItem('departmentId', '');
          history.push('/login');
          toastr.info('You are not allowed to view this page');
        }
      } catch (e) {
        toastr.error('Error Fetching Data');
      }
    };

    checkTwoDepartments();
  }, [department1, department2, department3, history]);
};

export default useCheckTwoDepartments;
