const url = {
  base_URL: 'https://api.tizeti.com:4000/',
  base_URL_LOCAL: 'https://api.tizeti.com:4000/',
  fetch_Retailers: 'super-admin/fetch',
  fetch_Retailer: 'super-admin/fetch/retailer?accountid=',

  fund_Retailer: 'super-admin/fund/retailer',
  get_Transactions: 'super-admin/fetch/transactions',
  get_Total_Revenue: 'super-admin/totalRevenue',
  get_Total_Customers: 'super-admin/totalHotspotCustomers',
  get_List_Activated: 'super-admin/activatedretailers',
  get_List_Pending: 'super-admin/pendingretailers',
  get_List_WalletActivations: 'super-admin/fetch/walletTransactions',
  updateRetailer: 'super-admin/edit/retailer',
  activateRetailer: 'super-admin/activate/retailer',
  createRetailer: 'sign-up/signup',
};

export default url;
