import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class AdminSideBar extends Component {
  render() {
    return (
      <aside className="left-sidebar col-lg-2">
        <div className="scroll-sidebar">
          <nav className="sidebar-nav">
            <ul>
              <li>
                <Link to="/hotspot-admin" target="_self">
                  <span>
                    <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971013/Group-3.png" />
                  </span>
                  Overview
                </Link>
              </li>

              <li>
                {' '}
                <Link
                  to="/hotspot-admin/retailers"
                  target="_self"
                >
                  <span>
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1548859608/retailers_white.png" />
                  </span>
                  Retailers
                </Link>
              </li>

              <li>
                {' '}
                <Link
                  to="/hotspot-admin/fund-wallet"
                  target="_self"
                >
                  <span>
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1548859607/fund_wallet.png" />
                  </span>
                  Fund Wallet
                </Link>
              </li>

              <li>
                {' '}
                <Link
                  to="/hotspot-admin/transaction"
                  target="_self"
                >
                  <span>
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1548859608/transactions.png" />
                  </span>
                  Transactions
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    );
  }
}

export default AdminSideBar;
