/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import qs from 'qs';
import ReactPaginate from 'react-paginate';
import usePayment from '../../../../../hooks/shared/custom/data/geniatech/usePayment';
import PaymentHistoryTable from './PaymentHistoryTable';

const PaymentHistoryBody = () => {
  const { getPayment, items, allPages, isLoading } = usePayment();

  const [macid, setMacId] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [page] = useState(1);

  const data = qs.stringify({
    action: 'getDebtorTransPaginated',
    macid: `${macid}`,
    from: `${from}`,
    to: `${to}`,
    page_no: `${page}`,
  });

  useEffect(() => {
    getPayment(data);
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    getPayment(data);
  };

  const handlePageClick = async (e) => {
    const selectedPage = e.selected + 1;
    const pageData = qs.stringify({
      action: 'getDebtorTransPaginated',
      macid: `${macid}`,
      from: `${from}`,
      to: `${to}`,
      page_no: `${selectedPage}`,
    });
    getPayment(pageData);
  };

  const headers = [
    { label: 'Transaction No', key: 'Trans_no' },
    { label: 'Transaction Date', key: 'Tran_date' },
    { label: 'Payment', key: 'Charge || Payment' },
    { label: 'Description', key: 'Description' },
    { label: 'Reference', key: 'Reference' },
  ];

  return (
    <div className="col-12 p-0 after-summary">
      <div className="row mx-2">
        <div className="mb-1">
          <form className="form-inline mt-4 inner-addon d-flex" onSubmit={onSubmit}>
            <div className="form-group mx-2">
              <input
                classname="form-control mr-auto empty pl-5"
                type="text"
                name="macid"
                id="macid"
                value={macid}
                onChange={(e) => setMacId(e.target.value)}
              />
            </div>
            <div className="form-group mx-2">
              <label className="mt-2 p-0 copy-font">From: </label>
              <input
                type="date"
                className="form-control px-1"
                name="from"
                onChange={(e) => setFrom(e.target.value)}
                value={from}
              />
            </div>
            <div className="form-group mx-2">
              <label className="mt-2 p-0 copy-font">From: </label>
              <input
                type="date"
                className="form-control px-1"
                name="to"
                onChange={(e) => setTo(e.target.value)}
                value={to}
              />
            </div>

            <div className="ml-3">
              <button className="btn btn-green shadow-md" type="submit">
                Search
              </button>
            </div>
          </form>
        </div>
      </div>

      <PaymentHistoryTable items={items} isLoading={isLoading} />
      {allPages > 1 ? (
        <ReactPaginate
          previousLabel="previous"
          nextLabel="next"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={allPages}
          marginPagesDisplayed={3}
          pageRangeDisplayed={4}
          onPageChange={handlePageClick}
          containerClassName="pagination"
          subContainerClassName="pages pagination"
          activeClassName="active"
        />
      ) : null}
    </div>
  );
};

export default PaymentHistoryBody;
