import { GET_SCHEDULE } from '../../../constants/types/customer-support/CustomerSupportTypes';
import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';

const initialState = {
  users: [],
  totalPages: '',
  allPages: '',
  currentPage: '',
  isLoading: false,
};

const ScheduleReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOADING_STARTS:
      return {
        ...state,
        isLoading: true,
      };

    case GET_SCHEDULE:
      return {
        ...state,
        users: payload.users,
        totalPages: payload.totalPages,
        allPages: payload.allPages,
        currentPage: payload.currentPage,
        isLoading: false,
      };

    case LOADING_STOPS:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default ScheduleReducer;
