/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useUser } from '../../../../../../hooks/shared/user/useUser';
import * as config from '../../../../../../utils/config';
import AccountsNavBar from '../../../../../layouts/accounts/AccountsNavBar';
import NocNavBar from '../../../../../layouts/noc/NocNavBar';
import ProcurementNavBar from '../../../../../layouts/procurement/ProcurementNavBar';
import FieldSupportNavBar from '../../../../../layouts/field-support/FieldSupportNavBar';
import CustomerSupportNavBar from '../../../../../layouts/customer-support/CustomerSupportNavBar';
import AdminDeptNavBar from '../../../../../layouts/admin-department/AdminDeptNavBar';
import RDNavBar from '../../../../../layouts/research-and-development/RDNavBar';
import Label from '../../../forms/Label';
import CustomInput from '../../../forms/CustomInput';
import { showDepartment } from '../../../../../../utils/lib';
import '../index.css';
import useViewRequsition from '../hooks/useViewRequsition';
import ManagerButtons from './layouts/ManagerButtons';
import ExecutiveButtons from './layouts/ExecutiveButtons';

const ViewOneRequisition = () => {
  const {
    data,
    loading,
    giveManagerDisApproval,
    giveManagerApproval,
    disapproving,
    approving,
    refetch,
    accountManagerApproval,
    accountManagerRejection,
    giveExecutiveDisApproval,
    giveExecutiveApproval,
  } = useViewRequsition();
  // const [value, setValue] = React.useState({ paymentDate: data.data.rows[0] });
  // console.log(value);
  const onChange = () => {};
  const approveRequisition = () => {
    giveManagerApproval({
      manager_approval: 'approved',
      budget_code: data.data.rows[0].budget_code,
    }).then(() => refetch());
  };

  const disApproveRequisition = () => {
    giveManagerDisApproval({
      manager_approval: 'rejected',
      comment: 'disapproved',
    }).then(() => refetch());
  };
  const accountManagerReject = () => {
    accountManagerRejection({
      account_approval: 'rejected',
    }).then(() => refetch());
  };
  const accountManagerApprov = () => {
    accountManagerApproval({
      account_approval: 'approved',
      payment_date: data.data.rows[0].payment_date,
      comment: data.data.rows[0].comment,
    }).then(() => refetch());
  };
  const giveExecutiveDisApprove = () => {
    giveExecutiveDisApproval({
      status: 'no',
    }).then(() => refetch());
  };
  const giveExecutiveApprove = () => {
    giveExecutiveApproval({
      status: 'yes',
      comment: 'approved',
    }).then(() => refetch());
  };

  const showButtons = () => {
    switch (sessionStorage.getItem('roleId')) {
      case '1':
        return null;
      case '2':
        return null;
      case '3':
        return (
          <ManagerButtons
            approveRequisition={approveRequisition}
            approving={approving}
            disApproveRequisition={disApproveRequisition}
            disapproving={disapproving}
            managerApprovalStatus={data.data.rows[0].manager_approval}
            accountManagerApproval={accountManagerApprov}
            accountApprovalStatus={data.data.rows[0].account_approval}
            accountManagerRejection={accountManagerReject}
          />
        );
      case '9':
        return (
          <ExecutiveButtons
            giveExecutiveDisApproval={giveExecutiveDisApprove}
            giveExecutiveApproval={giveExecutiveApprove}
            approving={approving}
            disapproving={disapproving}
            cLevelApproval={data.data.rows[0].c_level_approval}
          />
        );
      default:
        return null;
    }
  };
  const { profile } = useUser();
  const showNavBar = () => {
    switch (sessionStorage.getItem('departmentId')) {
      case config.accounts:
        return <AccountsNavBar />;
      case config.ADMIN_DEPARTMENT:
        return <AdminDeptNavBar />;
      case config.customer_support:
        return <CustomerSupportNavBar />;
      case config.FIELD_SUPPORT:
        return <FieldSupportNavBar />;
      case config.NOC:
        return <NocNavBar currentPage="Requisition" position={profile.firstName} />;
      case config.operations:
        return <FieldSupportNavBar />;
      case config.PROCUREMENT:
        return <ProcurementNavBar currentPage="Requisitton" position={profile.firstName} />;
      case config.RESEARCH_AND_DEVELOPMENT:
        return <RDNavBar />;
      default:
        return null;
    }
  };
  const showImage = (source) => {
    if (source.endsWith('.pdf')) {
      return <object width="100%" height="400" data={source} type="application/pdf" />;
    }
    return <img className="req-img" src={source} alt=" uploaded requisition" />;
  };
  return (
    <div className="s-layout" data-testid="create-customer">
      <main className="s-layout__content col-lg-10 p-0">
        {showNavBar()}
        <div className="container">
          <div className="row mt-5">
            <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-4">
              {loading ? (
                <p style={{ textAlign: 'center' }}>Loading</p>
              ) : (
                <div className="container-fluid col-lg-7">
                  {data && data.data ? (
                    <form onSubmit={(e) => e.preventDefault()} className="form-horizontal mt-5">
                      <div className="form-group text-left">
                        <div className="col-md-12 mb-5 d-flex p-0">
                          <div className="col-lg-6">
                            <Label classname="copy-font p-0 m-0" name="Amount In Words" />
                            <CustomInput
                              type="text"
                              classname="form-control"
                              placeholder=""
                              name=""
                              onChange=""
                              readOnly
                              value={data.data.rows[0].amount_in_words}
                            />
                          </div>
                          <div className="col-lg-6">
                            <Label classname="copy-font p-0 m-0" name="Amount In Digits" />
                            <CustomInput
                              type="text"
                              classname="form-control"
                              placeholder=""
                              name=""
                              onChange=""
                              readOnly
                              value={data.data.rows[0].currency + +data.data.rows[0].amount_in_digits}
                            />
                          </div>
                        </div>
                        <div className="col-md-12 mb-5 d-flex p-0">
                          <div className="col-lg-6">
                            <Label classname="copy-font p-0 m-0" name="In Favour Of" />
                            <CustomInput
                              type="text"
                              classname="form-control"
                              placeholder=""
                              name=""
                              onChange=""
                              readOnly
                              value={data.data.rows[0].in_favour_of}
                            />
                          </div>
                          <div className="col-lg-6">
                            <Label classname="copy-font p-0 m-0" name="Purpose" />
                            <CustomInput
                              type="text"
                              classname="form-control"
                              placeholder=""
                              name=""
                              onChange=""
                              readOnly
                              value={data.data.rows[0].purpose}
                            />
                          </div>
                        </div>
                        <div className="col-md-12 mb-5 d-flex p-0">
                          <div className="col-lg-6">
                            <Label classname="copy-font p-0 m-0" name="Department" />
                            <CustomInput
                              type="text"
                              classname="form-control"
                              placeholder=""
                              name=""
                              onChange=""
                              readOnly
                              value={showDepartment(data.data.rows[0].department_id)}
                            />
                          </div>
                          <div className="col-lg-6">
                            <Label classname="copy-font p-0 m-0" name="Manager Approval" />
                            <CustomInput
                              type="text"
                              classname="form-control"
                              placeholder=""
                              name=""
                              onChange=""
                              readOnly
                              value={data.data.rows[0].manager_approval}
                            />
                          </div>
                        </div>
                        <div className="col-md-12 mb-5 d-flex p-0">
                          <div className="col-lg-6">
                            <Label classname="copy-font p-0 m-0" name="Requires C Level Approval" />
                            <CustomInput
                              type="text"
                              classname="form-control"
                              placeholder=""
                              name=""
                              onChange=""
                              readOnly
                              value={!data.data.rows[0].requires_c_level_approval ? 'Not Required' : 'Required'}
                            />
                          </div>
                          <div className="col-lg-6">
                            <Label classname="copy-font p-0 m-0" name="C Level Approval" />
                            <CustomInput
                              type="text"
                              classname="form-control"
                              placeholder=""
                              name=""
                              onChange=""
                              readOnly
                              value={data.data.rows[0].c_level_approval ? 'Given' : 'Not Given'}
                            />
                          </div>
                        </div>
                        <div className="col-md-12 mb-5 d-flex p-0">
                          <div className="col-lg-6">
                            <Label classname="copy-font p-0 m-0" name="Accounts Approval" />
                            <CustomInput
                              type="text"
                              classname="form-control"
                              placeholder=""
                              name=""
                              onChange=""
                              readOnly
                              value={data.data.rows[0].account_approval}
                            />
                          </div>
                          <div className="col-lg-6">
                            <Label classname="copy-font p-0 m-0" name="Send to Procurement" />
                            <CustomInput
                              type="text"
                              classname="form-control"
                              placeholder=""
                              name=""
                              onChange=""
                              readOnly
                              value={data.data.rows[0].send_to_procurement === true ? 'Yes' : 'No'}
                            />
                          </div>
                        </div>
                        <div className="col-md-12 mb-5 d-flex p-0">
                          <div className="col-lg-6">
                            <Label classname="copy-font p-0 m-0" name="Send to procurement status" />
                            <CustomInput
                              type="text"
                              classname="form-control"
                              placeholder=""
                              name=""
                              onChange=""
                              readOnly
                              value={data.data.rows[0].send_to_procurement_status === true ? 'Sent' : 'Not Sent'}
                            />
                          </div>
                          <div className="col-lg-6">
                            <Label classname="copy-font p-0 m-0" name="Country" />
                            <CustomInput
                              type="text"
                              classname="form-control"
                              placeholder=""
                              name=""
                              onChange=""
                              readOnly
                              value={
                                data.data.rows[0].country === 'ng'
                                  ? 'Nigeria'
                                  : 'Ghana' && data.data.rows[0].country === 'cv' && "Côte d'Ivoire"
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-12 mb-5 d-flex p-0">
                          <div className="col-lg-6">
                            <Label classname="copy-font p-0 m-0" name="Accountt Payment Status" />
                            <CustomInput
                              type="text"
                              classname="form-control"
                              placeholder=""
                              name=""
                              onChange=""
                              readOnly
                              value={data.data.rows[0].account_payment_status ? 'Paid' : 'Not Paid'}
                            />
                          </div>
                          <div className="col-lg-6">
                            <Label classname="copy-font p-0 m-0" name="Requires CEO/COO Approval" />
                            <CustomInput
                              type="text"
                              classname="form-control"
                              placeholder=""
                              name=""
                              onChange=""
                              readOnly
                              value={data.data.rows[0].requires_ceo_or_coo_approval ? 'Yes' : 'No'}
                            />
                          </div>
                        </div>
                        <div className="col-md-12 mb-5 d-flex p-0">
                          <div className="col-lg-6">
                            <Label classname="copy-font p-0 m-0" name="CEO/COO Approval" />
                            <CustomInput
                              type="text"
                              classname="form-control"
                              placeholder=""
                              name=""
                              onChange=""
                              readOnly
                              value={data.data.rows[0].ceo_or_coo_approval}
                            />
                          </div>
                          <div className="col-lg-6">
                            <Label classname="copy-font p-0 m-0" name="Budget Code" />
                            <CustomInput
                              type="text"
                              classname="form-control"
                              placeholder=""
                              name=""
                              onChange=""
                              readOnly
                              value={data.data.rows[0].budget_code}
                            />
                          </div>
                        </div>

                        <div className="col-md-12 mb-5 d-flex p-0">
                          <div className="col-lg-6">
                            <Label classname="copy-font p-0 m-0" name="Payment Date" />
                            <CustomInput
                              type="text"
                              classname="form-control"
                              placeholder=""
                              name=""
                              onChange=""
                              readOnly
                              value={data.data.rows[0].payment_date}
                            />
                          </div>
                          <div className="col-lg-6">
                            <Label classname="copy-font p-0 mb-0" name="Details" />
                            <textarea
                              className="form-control"
                              value={data.data.rows[0].comment ? data.data.rows[0].comment : 'No details'}
                            />
                          </div>
                        </div>

                        <div className="col-md-12 mb-5 d-flex p-0">
                          <div className="col-lg-6">
                            <Label classname="copy-font p-0 m-0" name="Uploaded File" />
                            {showImage(data.data.rows[0].imageUpload)}
                          </div>
                        </div>
                        <div className="col-md-12 mb-5 d-flex p-0">
                          {data.data.rows[0].image2 && (
                            <div className="col-lg-6">
                              <Label classname="copy-font p-0 m-0" name="Uploaded File" />
                              {showImage(data.data.rows[0].image2)}
                            </div>
                          )}
                          {data.data.rows[0].image3 && (
                            <div className="col-lg-6">
                              <Label classname="copy-font p-0 m-0" name="Uploaded File" />
                              {showImage(data.data.rows[0].image3)}
                            </div>
                          )}
                        </div>
                        <div className="col-md-12 mb-5 d-flex p-0">
                          {data.data.rows[0].image3 && (
                            <div className="col-lg-6">
                              <Label classname="copy-font p-0 m-0" name="Uploaded File" />
                              {showImage(data.data.rows[0].image3)}
                            </div>
                          )}
                          {data.data.rows[0].image4 && (
                            <div className="col-lg-6">
                              <Label classname="copy-font p-0 m-0" name="Uploaded File" />
                              {showImage(data.data.rows[0].image4)}
                            </div>
                          )}
                        </div>
                        <div className="col-md-12 mb-5 d-flex p-0">
                          {data.data.rows[0].image5 && (
                            <div className="col-lg-6">
                              <Label classname="copy-font p-0 m-0" name="Uploaded File" />
                              {showImage(data.data.rows[0].image5)}
                            </div>
                          )}
                          {data.data.rows[0].image6 && (
                            <div className="col-lg-6">
                              <Label classname="copy-font p-0 m-0" name="Uploaded File" />
                              {showImage(data.data.rows[0].image6)}
                            </div>
                          )}
                        </div>
                      </div>
                      {showButtons()}
                    </form>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ViewOneRequisition;
