import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Skeleton from '@mui/material/Skeleton';
import { TableCellMonths, createData } from '../components/tempData';
import { get_active_expired_wifi_subscribers } from '../../../../../../actions/coo-statistics/ExpirationActions';
import { get_total_active_wifi_subscribers } from '../../../../../../actions/coo-statistics/ActiveActions';
import { get_total_installation_growth } from '../../../../../../actions/coo-statistics/TotalInstallationGrowthActions';
import AvailableYears from './AvailableYear';
import CountrySelect from './CountrySelect';
import ChartsContainer from './ChartsContainer';
import LinearProgressWithLabel from '../components/LinearProgress';
import Typography from '@mui/material/Typography';
import SimpleBackdrop from '../ActiveExpiredUsersByAssetZone/Backdrop';
let generateRandomCode = () => {
  const min = 100000; // minimum 6-digit number
  const max = 999999; // maximum 6-digit number

  return Math.floor(Math.random() * (max - min + 1) + min);
};

export default function KpiPerMonthTable() {
  // Get the current year
  const currentYear = new Date().getFullYear();
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedYear, setSelectedYear] = React.useState(`${currentYear}`);
  const [selectedCountry, setSelectedCountry] = React.useState('Nigeria');
  const [fetchedExpiredData, setFetchedExpiredData] = React.useState([]);
  const [fetchedActiveData, setFetchedActiveData] = React.useState([]);
  const [fetchedInstallationData, setFetchedInstallationData] = React.useState([]);
  const [useFetchedData, setUseFetchedData] = React.useState([]);

  // Callback function to update the selected year this is an alternative for me to maintain modularity when trying to pass props
  const handleYearChange = (year) => {
    setSelectedYear(year);
  };
  const handleCountryChange = (country) => {
    setSelectedCountry(country);
  };

  React.useEffect(() => {
    setIsLoading(true);

    async function fetchData() {
      try {
        const fetchedExpiredData = await get_active_expired_wifi_subscribers(selectedYear, selectedCountry);
        const fetchedActiveData = await get_total_active_wifi_subscribers(selectedYear, selectedCountry);

        const installationData = []; // Array to store installation data for each month

        for (let month = 1; month <= 12; month++) {
          const lastDay = new Date(selectedYear, month, 0).getDate(); //returns the last day of every month

          const fetchedInstallationData = await get_total_installation_growth(
            selectedYear,
            month,
            lastDay,
            selectedCountry,
          );

          // Push the fetched data for this month into the array
          installationData.push({ month, data: fetchedInstallationData });
        }

        // Now you have an array of installation data for all selected months
        // console.log(installationData);
        // console.log(fetchedExpiredData.data);

        setFetchedExpiredData(fetchedExpiredData.data);
        setFetchedActiveData(fetchedActiveData.data);
        setFetchedInstallationData(installationData);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    }

    fetchData();
  }, [selectedYear, selectedCountry]);

  React.useEffect(() => {
    if (isLoading === false && fetchedExpiredData.length > 0 && fetchedActiveData.length > 0) {
      const getExpiration = fetchedExpiredData.map((data) => data.count);
      const getActive = fetchedActiveData.map((data) => data.count);
      const getInstallationData = fetchedInstallationData.map((data) => data.data.count);

      // console.log(getInstallationData);
      const rows2023 = [
        createData('Total Active / Paying Subs', ...getActive),
        createData('Total Number Of New Installs During The Month + Install Growth %', ...getInstallationData),
        createData('Total Expired (actual)', ...getExpiration),
      ];

      // console.log(...getExpiration);
      setUseFetchedData(rows2023);

      // console.log(rows2023);
    }
  }, [isLoading, fetchedExpiredData, fetchedActiveData, fetchedInstallationData]);

  return (
    <Box sx={{ ml: '2rem', mt: '2rem', mr: '2rem' }}>
      <Typography variant="h4">Field Service Kpi Per Month</Typography>

      {isLoading ? (
        <>
          <Skeleton width={300} height={100} />
          <Skeleton width={250} />
          <Skeleton width={250} />
        </>
      ) : (
        <Box sx={{ mb: 5 }}>
          <AvailableYears selectedYear={selectedYear} onYearChange={handleYearChange} />
        </Box>
      )}
      {isLoading ? (
        <>
          <Skeleton width={300} height={100} />
          <Skeleton width={250} />
          <Skeleton width={250} />
        </>
      ) : (
        <Box sx={{ mb: 5 }}>
          <CountrySelect selectedCountry={selectedCountry} onCountryChange={handleCountryChange} />
        </Box>
      )}
      {isLoading ? (
        <SimpleBackdrop isLoading={isLoading} />
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bolder' }}>KPI</TableCell>
                {TableCellMonths.map((month, index) => (
                  <TableCell key={generateRandomCode()} align="right" sx={{ fontWeight: 'bolder' }}>
                    {month}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell></TableCell>
                {TableCellMonths.map((index) => (
                  <TableCell key={generateRandomCode()} align="right" sx={{ fontWeight: 'lighter', fontSize: 12 }}>
                    Actual
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {useFetchedData.map((row, index) => (
                <TableRow key={generateRandomCode()} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  {row.values.map((value) => (
                    <TableCell align="right" key={generateRandomCode()}>
                      {value}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <ChartsContainer data={useFetchedData} isLoading={isLoading} />
      {/* <LineCharts data={data} /> */}
    </Box>
  );
}
