/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import AssetTeamSideBar from '../../../../../layouts/asset-team/sidebar/AssetTeamSideBar';
import Logout from '../../../../shared/auth/Logout';
import { useUser } from '../../../../../../hooks/shared/user/useUser';
import PageName from '../../../../shared/layouts/PageName';
import LteInstallationsProvider from '../../../../shared/installations/lte/context/LteInstallationsProvider';
import LteInstallationsBody from '../../../../shared/installations/lte/LteInstallationsBody';

const AssetLTEInstallation = () => {
  const { profile } = useUser();
  return (
    <div className="s-layout">
      <LteInstallationsProvider>
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <Link className="s-sidebar__trigger" to="#">
                <i className="fa fa-bars mr-3" />
              </Link>
              <AssetTeamSideBar />

              <PageName name="LTE INSTALLATION TRACKER" />

              <div className="d-flex profile-mail col-6">
                <h6>
                  <i className="mr-auto">{profile.firstName} </i>
                  <Logout />
                </h6>
              </div>
            </div>
          </div>
          <div className="col-12 after-summary">
            <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-3">
              <div className="row">
                <LteInstallationsBody />
              </div>
            </div>
          </div>
        </main>
      </LteInstallationsProvider>
    </div>
  );
};

export default AssetLTEInstallation;
