import React from 'react';
import AddStatic from './AddStatic';
import view from '../../../../../assets/img/icons/view.png';
import edit from '../../../../../assets/img/icons/edit.png';
import EditStatic from './EditStatic';
import cancel from '../../../../../assets/img/icons/cancel.png';
import NocNavBar from '../../../../layouts/noc/NocNavBar';

function StaticIp() {
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <NocNavBar currentPage="Static IP" />

        <div className="container">
          <div className="row mt-5">
            <div className="mt-5 d-flex flex-wrap col-lg-12 col-md-12 col-sm-12">
              <div className="mt-2 input-group col-lg-10 col-md-11 col-sm-12">
                <div className="input-group-prepend">
                  <span className="input-group-text fa fa-search" />
                </div>
                <input className="form-control" type="text" placeholder="Search using AP" />
              </div>

              <div className="ml-3 mt-2">
                <button className="btn btn-green" type="submit" data-target="#AddStatic" data-toggle="modal">
                  Add Static IP
                </button>
                <AddStatic />
              </div>

              <div className="card x-panel mt-3">
                <div className="table-responsive content">
                  <table className="table copy-font">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Public Ip</th>
                        <th>Private</th>
                        <th>Actions</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>1</td>
                        <td />
                        <td />
                        <td />
                        <td>
                          <div className="d-flex">
                            <button
                              type="submit"
                              style={{
                                border: '0',
                                backgroundColor: 'white',
                              }}
                            >
                              <span className="view">
                                <img src={view} data-toggle="tooltip" data-placement="left" alt="" title="View" />
                              </span>
                            </button>

                            <button
                              type="submit"
                              data-toggle="modal"
                              data-target="#editStatic"
                              style={{
                                border: '0',
                                backgroundColor: 'white',
                              }}
                            >
                              <span className="view">
                                <img src={edit} data-toggle="tooltip" data-placement="left" alt="" title="Edit" />
                              </span>
                            </button>
                            <EditStatic />

                            <button
                              type="submit"
                              data-toggle="modal"
                              data-target="#cancel"
                              style={{
                                border: '0',
                                backgroundColor: 'white',
                              }}
                            >
                              <span className="view">
                                <img src={cancel} data-toggle="tooltip" data-placement="right" title="Delete" />
                              </span>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default StaticIp;
