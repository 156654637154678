/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import DeviceTypesBody from './DeviceTypesBody';
import { CUSTOMER_SUPPORT, OPERATIONS } from '../../../../../utils/departments/helpers';
import CustomerSupportNavBar from '../../../../layouts/customer-support/CustomerSupportNavBar';
import FieldSupportNavBar from '../../../../layouts/field-support/FieldSupportNavBar';


const DeviceTypes = () => {
const firstName = sessionStorage.getItem("firstName")
const departmentId = sessionStorage.getItem("departmentId")
  return (
    <div className="s-layout">
        {(departmentId === `${CUSTOMER_SUPPORT}`) && (
          <main className="s-layout__content col-lg-10 p-0">
            <CustomerSupportNavBar currentPage="Device Types" position={firstName} />
            <DeviceTypesBody />
          </main>
      
        )}

{(departmentId === `${OPERATIONS}`) && (
          <main className="s-layout__content col-lg-10 p-0">
            <FieldSupportNavBar currentPage="Device Types" position={firstName} />
            <DeviceTypesBody />
          </main>
      
        )}

    </div>
  );
};

export default DeviceTypes;
