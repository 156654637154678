/* eslint-disable no-empty */
/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-named-as-default */
import { useReducer } from 'react';
import axios from 'axios';
import { LOGGED_IN_USER_PROFILE_URL } from '../../../services/api/shared/UserUrl';
import * as types from '../../../constants/types/UserTypes';
import { userReducer } from '../../../reducers/user/userReducer';
import { LOGGED_IN_USER_URL } from '../../../services/api/auth/AuthUrl';

export const useUserProfile = () => {
  const [{ profile, user }, dispatch] = useReducer(userReducer, {
    profile: [],
    user: [],
  });

  const getUserProfile = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_PROFILE_URL}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response.data.id) {
      sessionStorage.setItem('email', response.data.email);
        sessionStorage.setItem('officialEmail', response.data.email);
        sessionStorage.setItem('firstName', response.data.firstName);
        sessionStorage.setItem('lastName', response.data.lastName);
      
        sessionStorage.setItem('country', response.data.country);
        dispatch({
          type: types.GET_LOGGED_IN_USER_PROFILE,
          payload: response.data,
        });
      }
    } catch (e) {}
  };

  const getLoggedInUser = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
          sessionStorage.setItem('departmentId', response.data.department_id);

          sessionStorage.setItem('roleId', response.data.role_id);
        dispatch({
          type: types.GET_LOGGED_IN_USER,
          payload: response.data,
        });
      }
    } catch (e) {}
  };

  return { profile, user, getUserProfile, getLoggedInUser };
};
