import React, { useEffect } from 'react';
import { get_all_basestation_zones } from '../../../../../../actions/shared/basestations/GetAllBasestationsZones';
import { change_expired } from '../../../../../../actions/coo-statistics/ChangedInExpired';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ChangeInExpiredContainer from './ChangeInExpiredContainer';

const ChangedInExpired = () => {
  const today = new Date();
  const formattedTodayDate = today.toISOString().slice(0, 10);
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 6);
  // Format the date as a string if needed
  const formattedYesterday = yesterday.toISOString().split('T')[0];
  const [loading, setLoading] = React.useState(false);
  const [getZones, setGetZones] = React.useState([]);
  const [selectedValue, setSelectedValue] = React.useState(formattedYesterday);
  const [selectedDate, setSelectedDate] = React.useState(formattedYesterday);
  const [userCurrentCounts, setUserCurrentCounts] = React.useState([]);
  const [userSelectedCounts, setUserSelectedCounts] = React.useState([]);
  const [getWidth, setGetWidth] = React.useState(window.innerWidth - 350);

  const intialChangeInExpiredValues = {
    action: 'get_zone_tracker',
    from: formattedTodayDate,
    to: formattedTodayDate,
    zone: 'kaduna',
  };

  React.useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const zones = await get_all_basestation_zones();

        setGetZones(zones);

        const fetchCount = async (zone, from = formattedTodayDate) => {
          const data = await change_expired({
            ...intialChangeInExpiredValues,
            zone: zone.name,
            from,
          });
          const res = await data.expired;
          return res;
        };

        const currentCountPromises = zones.map((zone) => fetchCount(zone));
        const selectedCountPromises = zones.map((zone) => fetchCount(zone, selectedDate));

        const [currentCounts, selectedCounts] = await Promise.allSettled([
          Promise.all(currentCountPromises),
          Promise.all(selectedCountPromises),
        ]);

        setUserCurrentCounts(currentCounts.value);
        setUserSelectedCounts(selectedCounts.value);
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedValue, selectedDate]);

  React.useEffect(() => {
    const handleResize = () => {
      setGetWidth(window.innerWidth - 300);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSelectChange = (event) => {
    const selectedOption = event.target.value;
    setSelectedValue(selectedOption);

    // Calculate the date based on the selected option
    const currentDate = new Date();
    let calculatedDate;

    switch (selectedOption) {
      case '1':
        calculatedDate = new Date(currentDate);
        calculatedDate.setDate(currentDate.getDate() - 1);
        break;
      case '7':
        calculatedDate = new Date(currentDate);
        calculatedDate.setDate(currentDate.getDate() - 7);
        break;
      case '30':
        calculatedDate = new Date(currentDate);
        calculatedDate.setDate(currentDate.getDate() - 30);
        break;
      default:
        break;
    }
    if (calculatedDate) {
      // Format the calculatedDate to YYYY-MMM-DD format
      const options = { year: 'numeric', month: 'short', day: '2-digit' };
      const formattedDate = calculatedDate.toLocaleDateString(undefined, options);
      setSelectedDate(formattedDate);
    }
  };

  const diff = [];
  if (loading === false) {
    for (let i = 0; i <= userSelectedCounts.length - 1; i++) {
      diff.push(userSelectedCounts[i] - userCurrentCounts[i]);
    }
  }

  const selectedDateFormatted = new Date(selectedValue);
  const timeDifference = today.getTime() - selectedDateFormatted.getTime();
  const dayDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
  return (
    <Box sx={{ ml: '2rem', mt: '2rem', mr: '2rem' }}>
      <Typography variant="h4">Change In Expired Customers</Typography>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          {loading ? (
            <>
              <Skeleton width={300} height={100} />
              <Skeleton width={250} />
              <Skeleton width={250} />
            </>
          ) : (
            <div>
              <input
                type="date"
                value={selectedValue}
                onChange={handleSelectChange}
                className="block w-full mt-2 mx-2 px-4 py-2 border rounded appearance-none focus:border-blue-500 focus:outline-none"
              />
              <Typography sx={{ mt: '1rem' }} variant="p">
                Select Date - {selectedValue} ( {dayDifference} days ago)
              </Typography>
            </div>
          )}
        </div>
      </Box>
      <Box sx={{ mr: 5, width: getWidth, mb: 5 }}>
        <TableContainer component={Paper} width={getWidth}>
          <Table sx={{ width: getWidth }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell sx={{ fontWeight: 'bolder' }}>Zones</TableCell>
                {!loading &&
                  getZones.map((zone, index) => (
                    <TableCell sx={{ fontWeight: 'bolder' }} key={index}>
                      {zone.name}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell sx={{ fontWeight: 'bolder' }}>
                  Difference <br /> ({dayDifference} days ago)
                </TableCell>
                {loading === false ? (
                  <>
                    {diff.map((x, index) => (
                      <TableCell sx={{ fontWeight: 'bolder' }} key={index}>
                        {x !== NaN && x}
                      </TableCell>
                    ))}
                  </>
                ) : (
                  <TableCell>CALCULATING...</TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box>
        <ChangeInExpiredContainer data={diff} isLoading={loading} zones={getZones} />
      </Box>
    </Box>
  );
};

export default ChangedInExpired;
