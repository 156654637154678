/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import qs from 'qs';
import ReactPaginate from 'react-paginate';
import { CSVLink } from 'react-csv';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import { useAccountReconciliation } from '../../../../../hooks/procurement/account-reconciliation.js/useAccountReconciliation';
import AccountReconciliationTable from './AccountReconciliationTable';

const AccountReconciliationBody = () => {
  const { lists, count, pages, isLoading, getInstallationReport, searchUsingKeyword } = useAccountReconciliation();

  const [keyword, setKeyword] = useState('');
  const [limits, setLimits] = useState('10');
  const [page] = useState(1);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const data = qs.stringify({
    mac_id: `${keyword}`,
    from: `${from}`,
    to: `${to}`,
    limits: `${limits}`,
  });
  // const dateState = { from: '', to: '' };
  // const [date, setDate] = useState(dateState);

  // const searchMacID = { mac_id: keyword.trim() };
  // const searchLimits = { limits: records.trim() };

  const headers = [
    { label: 'MAC', key: 'mac_id' },
    { label: 'First Name', key: 'user.firstName' },
    { label: 'Last Name', key: 'user.lastName' },
    { label: 'Email', key: 'user.email' },
    { label: 'Tag Number', key: 'tag_no' },
  ];

  useEffect(() => {
    getInstallationReport(page);
  }, []);

  // const handleDate = (e) => {
  //   const { name, value } = e.target;
  //   setDate({ ...date, [name]: value });
  // };

  // const handlePageClick = (e) => {
  //   const selectedPage = e.selected + 1;
  //   if (option === 'macID') return searchUsingKeyword(selectedPage, searchMacID, searchLimits);
  //   if (option === 'date') return searchUsingKeyword(selectedPage, date, searchLimits);
  //   return getInstallationReport(selectedPage);
  // };

  const handlePageClick = async (e) => {
    const selectedPage = e.selected + 1;
    const pageData = qs.stringify({
      from: `${from}`,
      to: `${to}`,
      limits: `${limits}`,
    });
    getInstallationReport(selectedPage, pageData);
  };

  const filterUsingKeyword = (e) => {
    e.preventDefault();
    searchUsingKeyword(data);
  };

  return (
    <div className="col-12 p-0 after-summary">
      <div className="row mx-2">
        <div className="mb-1">
          <div className="smallTop d-flex">
            <form onSubmit={filterUsingKeyword} className="form-inline ml-3">
              {/* <div className="form-group ml-2">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Input Search Term"
                  style={{ width: '230px' }}
                  name="keyword"
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                />
              </div> */}

              <div className="form-group d-flex ml-2">
                <small className="mr-1">From: </small>
                <input
                  type="date"
                  className="form-control"
                  name="from"
                  value={from}
                  onChange={(e) => setFrom(e.target.value)}
                />
              </div>
              <div className="form-group d-flex ml-2">
                <small className="mr-1">To: </small>
                <input
                  type="date"
                  className="form-control"
                  name="to"
                  value={to}
                  onChange={(e) => setTo(e.target.value)}
                />
              </div>

              <div className="form-group ml-2">
                Reco
                <input
                  className="form-control"
                  type="text"
                  placeholder="Input Search Term"
                  style={{ width: '230px' }}
                  name="records"
                  value={limits}
                  onChange={(e) => setLimits(e.target.value)}
                />
              </div>
              <div className="form-group ml-2">
                <button className="btn btn-green" type="submit">
                  Search
                </button>
              </div>
            </form>

            {lists.length ? (
              <div className="ml-3">
                <CSVLink
                  data={lists}
                  headers={headers}
                  filename="Account Reconciliation.csv"
                  className="btn btn-green"
                  style={{ color: 'white' }}
                >
                  Download Data
                </CSVLink>
              </div>
            ) : null}
          </div>
        </div>

        <div className="mt-2 mb-3 col-12">
          {lists.length ? (
            <div className="col-12 baby-fonts">
              Number of Accounts: <span className="badge badge-dark payslipfont">{count}</span>
            </div>
          ) : null}
        </div>

        <div className="card x-panel p-0">
          <div className="table-responsive content col-12">
            <table className="table table-striped">
              <thead>
                <tr className="">
                  <th>S/N</th>
                  <th>MAC ID</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Tag Number</th>
                  <th>Date</th>
                  <th>Confirmation</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan="8" className="text-center">
                      <img src={loader} alt="loading gif" />
                    </td>
                  </tr>
                ) : (
                  <AccountReconciliationTable lists={lists} />
                )}
              </tbody>
            </table>
          </div>

          <div className="px-3">
            {pages > 1 ? (
              <ReactPaginate
                previousLabel="previous"
                nextLabel="next"
                breakLabel="..."
                breakClassName="break-me"
                pageCount={pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName="pagination"
                subContainerClassName="pages pagination"
                activeClassName="active"
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountReconciliationBody;
