import React from 'react'
import BusinessDevelopmentNavBar from '../../../../layouts/business-development/BusinessDevelopmentNavBar';
import UsageHistoryBody from '../../customer-support/usage-history/UsageHistoryBody';

const BusinessDevelopmentUsageHistory = () => {
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <BusinessDevelopmentNavBar currentPage="Usage History" />
        <div className="mt-5 py-5">
          <UsageHistoryBody />
        </div>
      </main>
    </div>
  );
}

export default BusinessDevelopmentUsageHistory