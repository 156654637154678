import React, { useContext } from 'react';
import StaffPayslipContext from './context/StaffPayslipContext';

const SearchStaffPayslip = () => {
  const { staff, searchChange, search, searchForEmployeePayslip } = useContext(StaffPayslipContext);
  const { from, to, employee_id } = search;
  console.log(staff);
  const onSearch = async (e) => {
    const filter = staff.filter((s) => s.employee_auth_id === employee_id);
    e.preventDefault();
    searchForEmployeePayslip({ from, to }, { email: filter[0].email });
  };
  return (
    <form onSubmit={onSearch} className="form-inline mb-2 ml-3 col-12">
      <div className="form-group">
        <select name="employee_id" onChange={searchChange} className="form-control">
          <option value="">--Choose Employee--</option>
          {staff.map((staf) => {
            return (
              <option value={staf.employee_auth_id}>
                {staf.firstName} {staf.lastName}
              </option>
            );
          })}
        </select>
      </div>
      <div className="form-group d-flex ml-2">
        <small className="mr-1">From: </small>
        <input type="date" className="form-control" name="from" value={from} onChange={searchChange} />
      </div>
      <div className="form-group d-flex ml-2">
        <small className="mr-1">To: </small>
        <input type="date" className="form-control" name="to" value={to} onChange={searchChange} />
      </div>
      <div className="form-group ml-2">
        <button className="btn btn-green">Search</button>
      </div>
    </form>
  );
};

export default SearchStaffPayslip;
