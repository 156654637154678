import { GET_COO_STATISTICS_URL } from '../../services/api/coo-statistics/CooStatisticsApi';
import legacy from '../../services/api/axios';

export const get_total_bizdev_turbo_revenue = async (selectedYear, selectedCountry) => {
  const initialDateClicked = {
    from: `${selectedYear}-01-01`,
    to: `${selectedYear}-12-31`,
  };

  const initialExpirationState = {
    action: 'get_bizdev_turbo_revenue',
    ...initialDateClicked,
    // country: selectedCountry,
  };

  const formData = new URLSearchParams(initialExpirationState).toString();
  try {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    const res = await legacy.post(`${GET_COO_STATISTICS_URL}`, formData, { headers: headers });
    if (res.data) {
      // console.log(res);
      return res.data;
    }
  } catch (error) {
    console.error(error);
  }
  // console.log(isLoading);
};
