/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import PartnershipNavBar from '../../../../layouts/partnership/PartnershipNavBar';
import PartnershipBandwidthBody from './PartnershipBandwidthBody';

const PartnershipBandwidth = () => {
  const firstName = sessionStorage.getItem('firstName');
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <PartnershipNavBar currentPage="Bandwidth" position={firstName} />
        <PartnershipBandwidthBody />
      </main>
    </div>
  );
};

export default PartnershipBandwidth;
