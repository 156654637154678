import React, { Component } from 'react'

class IssueDetail extends Component {

    render() {
    return (
        <div id="issue" className="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel" style={{marginLeft:"20%"}}>Issue Report Details</h4>
                            <button type="button" className="close custom-close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                            <div className="row">
                                <div className="col-lg-12 d-flex">
                                    <div className="col-lg-4 detail-label1">
                                        <label id="fn">Department</label>
                                        <label id="ln">Subject</label>
                                        <label id="email">Submitted By</label>
                                    </div>

                                    <div className="col-lg-8 detail-result1">
                                        <p className="mt-4 mb-2">Operations</p>
                                        <p className="mb-2">Scheduler</p>
                                        <p className="mb-2">Olawale Tolu</p>
                                    </div>
                                </div>
                                <hr/>
                                <div className="col-lg-12 d-flex">
                                    <div className="col-lg-4 detail-label2">
                                        <label id="detail">Details </label>
                                    </div>

                                    <div className="col-lg-8 detail-result2">
                                        <p className="mt-4">Can we be able to get a feature where we can
                                            easily know the most recently scheduled customer
                                        </p>
                                    </div>
                                </div>

                                <div className="col-lg-12 d-flex">
                                    <div className="col-lg-4 detail-label3">
                                            <label id="status" style={{ display:'block' }}>Date Reported</label>
                                            <label id="language" style={{display:'block'}}>Date Resolved</label>
                                        </div>

                                        <div className="col-lg-8 detail-result3">
                                            <p className="mt-4 mb-2">June 12th, 2019</p>
                                            <p>N/A</p>
                                        </div>
                                </div>

                                <div className="col-lg-12 d-flex">
                                    <div className="col-lg-4 detail-label4">
                                        <label id="status" style={{ display:'block' }}>Status</label>
                                    </div>

                                    <div className="col-lg-8 detail-result4">
                                        <p className="mt-4">Resolved</p>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>


                </div>
      )
  
  }
}

export default IssueDetail;