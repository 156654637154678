import React from 'react';
import PropTypes from 'prop-types';
import caution from '../../../../../assets/img/icons/Caution.png';
import { useCustomers } from '../../../../../hooks/new-products/products/wificall/customers/useCustomers';

const ConfirmModal = ({ customer }) => {
  const { activateCustomer } = useCustomers();

  return (
    <div
      className="modal fade"
      id="confirmModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content mt-5 py-4">
          <div className="text-center">
            <img src={caution} alt="" width="60px" />
          </div>
          <div className="modal-body p-3">
            <form className="text-center">
              <h5>Are you sure you want to activate &#34;{`${customer.firstName} ${customer.lastName}`}&#34;?</h5>
              <div className="mt-3">
                <button type="button" className="btn btn-secondary mr-2" data-dismiss="modal">
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-green"
                  onClick={() => {
                    activateCustomer(customer);
                  }}
                >
                  Confirm
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

ConfirmModal.propTypes = {
  customer: PropTypes.objectOf.isRequired,
};

export default ConfirmModal;
