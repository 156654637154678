import React from 'react';
import PartnershipNavBar from '../../../../layouts/partnership/PartnershipNavBar';
import SignupHistoryBody from './SignupHistoryBody';

const SignupHistory = () => {
  const name = sessionStorage.getItem('firstName');

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <div className="d-flex col-lg-12 p-2 top-header">
          <PartnershipNavBar currentPage="US AID Signups" position={name} />
        </div>
        <div>US AID Signups</div>
        <div className="pt-5">
          <SignupHistoryBody />
        </div>
      </main>
    </div>
  );
};

export default SignupHistory;
