import { GET_WIFICALL_DIRECTORY_COUNT, GET_WIFICALL_USERS_COUNT } from "../../constants/types/wificall/WificallTypes";

const initialState = {

  userscount: '',
  directorycount: ''

};

export const DirectoryReducer = (state=initialState, action) => {

  switch (action.type) {
    case GET_WIFICALL_USERS_COUNT:
      return{

        ...state,
        userscount: action.payload

      };

    case GET_WIFICALL_DIRECTORY_COUNT:
      return {

        ...state,
        directorycount: action.payload

      };

    default:
      return state;
  }

};

export default DirectoryReducer;
