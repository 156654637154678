import React from 'react';
import BusinessDevelopmentDedicatedCustomersBody from '../../../shared/dedicated-customers/BusinessDevelopmentDedicatedCustomersBody';
import CustomerSupportNavBar from '../../../../layouts/customer-support/CustomerSupportNavBar';
import AssetTeamNavBar from '../../../../layouts/asset-team/AssetTeamNavBar';

const AssetTeamDedicatedCustomers = () => {
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        < AssetTeamNavBar currentPage="Dedicated Customers" />
        <BusinessDevelopmentDedicatedCustomersBody />
      </main>
    </div>
  );
};

export default AssetTeamDedicatedCustomers;