import React from 'react';
import RetailerSms from '../../../../shared/express-wifi/retailer-sms/RetailerSms';
import AccountsNavBar from '../../../../../layouts/accounts/AccountsNavBar';

const CfoExpressWifi = () => {
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <AccountsNavBar currentPage="Retailer SMS" />
        {/* <ExpressWifiNavBar currentPage="Sms" position={firstName} /> */}
        <RetailerSms />
      </main>
    </div>
  );
};

export default CfoExpressWifi;
