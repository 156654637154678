/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { displaySource, displayAccountType } from '../../../../../../utils/wificall/helpers';
import UnverifiedCustomersTable from './UnverifiedCustomersTable';

const UnverifiedCustomersBody = ({
  filterBySource,
  filterByAccountType,
  customers,
  isLoading,
  filteredPages,
  source,
  handleSource,
  handlePlanType,
  planType,
}) => {
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [category, setCategory] = useState('');
  const [allPages] = useState(1);

  const sourceTypeData = {
    from,
    to,
    isVerified: false,
  };
  const accountTypeData = {
    from,
    to,
    planType,
    isVerified: false,
  };

  const searchCustomers = (e) => {
    e.preventDefault();
    if (category === 'sourceType' && source !== 'all') return filterBySource(source, sourceTypeData, allPages);
    if (category === 'sourceType' && source === 'all') return filterByAccountType(sourceTypeData, allPages);
    return filterByAccountType(accountTypeData, allPages);
  };

  const filterPageClick = async (data) => {
    const selectedPage = data.selected + 1;
    if (category === 'sourceType' && source !== 'all') return filterBySource(source, sourceTypeData, selectedPage);
    if (category === 'sourceType' && source === 'all') return filterByAccountType(sourceTypeData, selectedPage);
    return filterByAccountType(accountTypeData, selectedPage);
  };

  const validateForm = () => {
    if (category === 'sourceType') return source.length !== 2 && from.length > 0 && to.length > 0;
    return planType.length > 2 && from.length > 0 && to.length > 0;
  };

  return (
    <>
      <div className="col-12 my-2">
        <form className="form-inline pl-4" onSubmit={searchCustomers}>
          <div className="form-group mr-2">
            <small className="ml-2 mr-1">Filter By: </small>
            <select
              className="form-control mr-2"
              defaultValue="DEFAULT"
              name="category"
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="DEFAULT" disabled>
                -- Category --
              </option>
              <option value="sourceType">Source</option>
              <option value="accountType">Account Type</option>
            </select>
          </div>
          <div className="form-group mr-2">
            {category === 'sourceType'
              ? displaySource(handleSource)
              : category === 'accountType'
              ? displayAccountType(handlePlanType)
              : null}
          </div>
          <div className="form-group mr-2">
            <small className="mr-1">From: </small>
            <input
              type="date"
              className="form-control"
              name="from"
              onChange={(e) => setFrom(e.target.value)}
              value={from}
            />
          </div>
          <div className="form-group mr-2">
            <small className="mr-1">To: </small>
            <input type="date" className="form-control" name="to" onChange={(e) => setTo(e.target.value)} value={to} />
          </div>
          {isLoading ? (
            <button type="button" className="btn btn-green ml-3" disabled>
              Fetching...
            </button>
          ) : (
            <button type="submit" className="btn btn-green ml-3" disabled={!validateForm()}>
              Submit
            </button>
          )}
        </form>

        <div className="card x-panel">
          <div className="table-responsive content">
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th className="text-nowrap">Account Type</th>
                  <th>Source</th>
                  {/* <th>Balance</th> */}
                  {/* <th>Account</th> */}
                  <th>Status</th>
                  <th>Actions</th>
                  {/* <th>Activate</th> */}
                </tr>
              </thead>
              <UnverifiedCustomersTable customers={customers} load={isLoading} />
            </table>
          </div>
        </div>

        <div className="px-3">
          {filteredPages > 1 ? (
            <ReactPaginate
              previousLabel="previous"
              nextLabel="next"
              breakLabel="..."
              breakClassName="break-me"
              pageCount={filteredPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={filterPageClick}
              containerClassName="pagination"
              subContainerClassName="pages pagination"
              activeClassName="active"
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

UnverifiedCustomersBody.propTypes = {
  filterBySource: PropTypes.func.isRequired,
  filterByAccountType: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  customers: PropTypes.arrayOf.isRequired,
  filteredPages: PropTypes.number.isRequired,
  source: PropTypes.string.isRequired,
  planType: PropTypes.string.isRequired,
  handleSource: PropTypes.func.isRequired,
  handlePlanType: PropTypes.func.isRequired,
};

export default UnverifiedCustomersBody;
