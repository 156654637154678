import { useReducer } from 'react';
import qs from 'qs';
import axios from 'axios';
import * as load from '../../constants/types/shared/LoadingTypes';
import { GET_SIGNUPS, GET_USAGE } from '../../constants/types/partnership/PartnershipTypes';
import { signupHistoryReducer } from '../../reducers/partnership/signup-history/signupHistoryReducer';

export const useSignupsHistory = () => {
  const [{ signups, isLoading }, dispatch] = useReducer(signupHistoryReducer, {
    isLoading: false,
  });

  const getSignups = async (from, to) => {
    const data = qs.stringify({
      startDate: from,
      endDate: to,
    });
    dispatch({
      type: load.LOADING_STARTS,
    });

    try {
      const response = await axios.post('https://api.tizeti.com/api/v1/usaidMetrics/getSignups', data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

     
      if (response.data.status === 'success') {
        dispatch({
          type: GET_SIGNUPS,
          payload: response.data.flattenedData,
        });
      } else {
        dispatch({
          type: load.LOADING_STOPS,
        });
      }
    } catch (e) {
      dispatch({
        type: load.LOADING_STOPS,
      });
    }
  };

  return { signups, isLoading, getSignups };
};
