import { LOADING_STARTS, LOADING_STOPS } from '../../../../constants/types/shared/LoadingTypes';
import {
  GET_CUSTOMER_USAGE_BY_DATE,
  GET_USAGE_HISTORY,
  GETTING_BY_DATE_STARTS,
  GETTING_BY_DATE_STOPS,
} from '../../../../constants/types/shared/customers/UsageHistoryTypes';

const initialState = {
  usage: [],
  load: false,
  date: false,
};

export const UsageHistoryReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GETTING_BY_DATE_STARTS:
      return {
        ...state,
        date: payload,
      };

    case GETTING_BY_DATE_STOPS:
      return {
        ...state,
        date: payload,
      };

    case LOADING_STARTS:
      return {
        ...state,
        load: payload,
      };

    case LOADING_STOPS:
      return {
        ...state,
        load: payload,
      };

    case GET_USAGE_HISTORY:
      return {
        ...state,
        usage: payload,
        load: false,
      };

    case GET_CUSTOMER_USAGE_BY_DATE:
      return {
        ...state,
        usageByDate: payload,
        date: false,
      };

    default:
      return state;
  }
};

export default UsageHistoryReducer;
