import React, { Component } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Logout from '../../../shared/auth/Logout';
import WifiCallSidebar from '../../../../layouts/wificall-sidebar/WifiCallSidebar';
import WifiCallLogo from '../../../../layouts/wificall-logo/WifiCallLogo';
import AddWifiDirectory from './AddWifiDirectory';
import { admin, API_URL, header, NEW_PRODUCTS } from '../../../../../utils/config';
import WifiCallDirectoryTable from './WifiCallDirectoryTable';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import { getUser } from '../../../../../actions/shared/user/UserActions';
import { SUPER_ADMIN } from '../../../../../utils/departments/helpers';

class WifiCallDirectory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      directories: [],
      keyword: '',
      filtered: [],
      loading: false,
      activePage: 1,
      search: '',
      load: false,
      offset: 0,
      show: false,
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  componentDidMount() {
    this.getDirectories();
    this.checkDepartment();
    this.props.getUser();
  }

  getDirectories() {
    this.setState({
      loading: true,
      show: false,
    });

    axios({
      method: 'get',
      url: `${API_URL}/yellowpage/csv/1`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    }).then((res) => {
      this.setState({
        filtered: res.data.data,
        /* loading: false, */
        pageCount: res.data.pages,
        limit: res.data.data.length,
        total: res.data['Total Count'],
        show: false,
        loading: false,
      });
    });
  }

  handlePageClick = (data) => {
    this.setState({
      loading: true,
    });

    const selected = data.selected + 1;
    // const url = `${API_URL}/requisition/upload/${selected}`;

    const url = `${API_URL}/yellowPage/cursor/${selected}`;

    const { search, keyword } = this.state;

    const get = {
      field: search,
      search: keyword,
    };

    axios({
      method: 'post',
      url,
      data: get,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    }).then((res) => {
      this.setState({
        filtered: res.data.data,
        loading: false,
        pageCount: res.data.pages,
      });
    });
  };

  handlePageChange = (pageNumber) => {
    const url = `${API_URL}/yellowPage/cursor/${pageNumber}`;

    this.setState({
      loading: true,
    });

    const data = {
      field: this.state.search,
      search: this.state.keyword,
    };

    axios({
      method: 'post',
      url,
      data,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    }).then((res) => {
      this.setState({
        filtered: res.data.data,
        loading: false,
        pageCount: res.data.pages,
        limit: res.data.data.length,
        total: res.data['Total Count'],
      });
    });
  };

  componentWillReceiveProps() {
    this.checkDepartment();
  }

  checkDepartment = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, header);

      if (
        response.data.department_id !== `${admin}` &&
        response.data.department_id !== `${NEW_PRODUCTS}` &&
        response.data.department_id !== `${SUPER_ADMIN}`
      ) {
        this.props.history.push('/');

        sessionStorage.setItem('token', '');
        sessionStorage.clear();

        toastr.info('You are not allowed to view this page');
      }
    } catch (e) {
      console.log(e.response);
    }
  };

  static propTypes = {
    getUser: PropTypes.func.isRequired,
    firstName: PropTypes.string.isRequired,
  };

  onSubmit(e) {
    e.preventDefault();

    this.setState({
      load: true,
      show: false,
    });

    if (this.state.keyword === '') {
      toastr.info('Please type something');
      this.setState({
        load: false,
      });
    }

    if (this.state.search === '') {
      toastr.info('Please Choose fields to search from');
      this.setState({
        load: false,
      });
    }

    const data = {
      field: this.state.search,
      search: this.state.keyword,
    };

    this.setState({
      load: true,
      show: false,
    });

    axios({
      method: 'post',
      url: `${API_URL}/yellowPage/cursor/1`,
      data,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
      .then((res) => {
        if (res.data.data.length !== 0) {
          this.setState({
            load: false,
            filtered: res.data.data,
            show: true,
          });
        } else {
          this.setState({
            filtered: res.data.data,
            show: false,
            load: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          show: false,
        });
      });
  }

  render() {
    const { user } = this.props;
    const { firstName } = user;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <Link className="s-sidebar__trigger" to="#">
                <i className="fa fa-bars mr-3" />
              </Link>
              <WifiCallSidebar />

              <h3 className="col-lg-6 overview-part">Directory</h3>

              <div className="d-flex">
                <h6 className="mr-0 mt-1">
                  {firstName}
                  <Logout />
                </h6>
              </div>
            </div>
          </div>

          <div className="col-12 after-summary">
            <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-3">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-3 d-md-flex d-none">
                  <form className="col-lg-8 d-flex mt-3" onSubmit={this.onSubmit.bind(this)}>
                    <small className="mr-2 copy-font" style={{ width: '25%' }}>
                      Filter by:
                    </small>
                    <input
                      type="text"
                      className="form-control mr-4"
                      value={this.state.keyword}
                      name="keyword"
                      onChange={this.onChange}
                      placeholder="Phone Number/First Name/Last Name"
                      style={{ height: '40px', width: '100%' }}
                    />

                    <select className="form-control mr-3" name="search" onChange={this.onChange}>
                      <option value="">Choose fields to search from</option>
                      <option value="firstName">First Name</option>
                      <option value="lastName">Last Name</option>
                      <option value="phoneNumber">Phone Number</option>
                    </select>

                    {this.state.load ? (
                      <button className="btn btn-green">
                        <i className="fa fa-circle-o-notch fa-spin" />
                      </button>
                    ) : (
                      <button className="btn btn-green" style={{ height: '39px' }}>
                        Search{' '}
                      </button>
                    )}
                  </form>

                  <div className="col-lg-4 mt-3 text-right">
                    <button className="btn btn-green" data-toggle="modal" data-target="#myModal">
                      Add Directory
                    </button>

                    <AddWifiDirectory />
                  </div>
                </div>

                <div className="card x-panel">
                  <div className="table-responsive content">
                    <table className="table">
                      <thead>
                        <tr className="copy-font">
                          <th scope="col">#</th>
                          <th scope="col">Business Name/First Name</th>
                          <th scope="col">Last Name</th>
                          <th scope="col">Country Code</th>
                          <th scope="col">Phone Number</th>
                          <th>Address</th>
                          <th scope="col">Category</th>
                          <th scope="col">Twitter</th>
                          <th scope="col">Instagram</th>
                          <th scope="col">Type</th>
                          <th scope="col">Enable/Disable</th>
                          <th scope="col" className="text-center">
                            Actions
                          </th>
                        </tr>
                      </thead>

                      {/*                                 <WifiCallDirectoryTable filtered={this.state.filtered}/> */}

                      {this.state.loading ? (
                        <img
                          src="https://res.cloudinary.com/teewhy/image/upload/v1546957729/TizetiLoaderColored.gif"
                          alt=""
                        />
                      ) : (
                        <WifiCallDirectoryTable filtered={this.state.filtered} func={this.handlePageChange} />
                      )}
                    </table>
                  </div>
                </div>
                {this.state.show ? (
                  <div className="page col-lg-12 col-md-12 col-sm-12 d-flex">
                    <ReactPaginate
                      previousLabel="Previous"
                      nextLabel="Next"
                      breakLabel="..."
                      breakClassName="break-me"
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName="pagination"
                      subContainerClassName="pages pagination"
                      activeClassName="active"
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </main>
        {/*            <AdminPaginate/> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect(mapStateToProps, { getUser })(WifiCallDirectory);
