/* eslint-disable radix */
/* eslint-disable import/no-named-as-default */
/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import { useState, useReducer, useEffect, useCallback } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import toastr from 'toastr';
import * as url from '../../../services/api/shared/requisitions/RequisitionsUrl';
import * as load from '../../../constants/types/shared/LoadingTypes';
import * as types from '../../../constants/types/shared/requisition/RequisitionTypes';
import { requisitionReducer } from '../../../reducers/shared/requistion/requisitionReducer';
import { OPERATIONS, TECHNICAL_SUPPORT } from '../../../utils/departments/helpers';
import { GET_ACCOUNTS_REQUSITION_BY_STATUS_URL } from '../../../services/api/accounts/requisition/RequisitionUrl';

export const useRequisition = () => {
  const userCountry = sessionStorage.getItem('country');
  const [showApproved, setShowApproved] = useState(false);
  const deptFilter = sessionStorage.getItem('deptFilter');
  const onOpenModal = () => {
    setShowApproved(true);
  };
  const onCloseModal = () => {
    setShowApproved(false);
  };
  const requisitionState = {
    amount: '',
    currency: (userCountry === 'CIV' && 'CFA') || (userCountry === 'GH' && 'GHC') || (userCountry === 'NG' && 'NGN'),
    purpose: '',
    amountInWords: '',
    country: (userCountry === 'CIV' && 'cv') || (userCountry === 'GH' && 'gh') || (userCountry === 'NG' && 'ng'),
  };
  const accountStatus = {
    department: '',
    status: '',
  };
  const [requisition, setRequisition] = useState(requisitionState);
  const [file, setFile] = useState(null);
  const [page, setPage] = useState(1);
  const [percentage, setPercentage] = useState(0);
  const [status, setStatus] = useState('');
  const [disapprove, setDisapprove] = useState('');
  const [approve, setApprove] = useState('');
  const [department, setDepartment] = useState('');
  const [account, setAccount] = useState(accountStatus);
  const [managerApproval, setManagerApproval] = useState(false);
  const [
    {
      isLoading,
      managerRequisitions,
      totalPages,
      employeesPages,
      isFetching,
      executiveRequisitions,
      isApprovingOrDisapproving,
      isFetchingExecRequisitions,
      employeesRequisitions,
    },
    dispatch,
  ] = useReducer(requisitionReducer, {
    isLoading: false,
    managerRequisitions: [],
    totalPages: 1,
    employeesPages: 1,
    isFetching: false,
    executiveRequisitions: [],
    isApprovingOrDisapproving: false,
    isFetchingExecRequisitions: [],
    employeesRequisitions: [],
  });
  const onChangeRequisition = (e) => {
    const { name, value } = e.target;
    setRequisition({
      ...requisition,
      [name]: value,
    });
  };
  const onChangeApproveOrDisapprove = (e) => {
    const { value } = e.target;
    setStatus(value);
  };
  const onChangeDisapprove = (e) => {
    const { value } = e.target;
    setDisapprove(value);
  };
  const onChangeApprove = (e) => {
    const { value } = e.target;
    setApprove(value);
  };
  const onFileChange = (e) => {
    const { files } = e.target;
    setFile(files[0]);
    if (files.length > 1) {
      setFile(files);
    } else {
      setFile(files[0]);
    }
  };
  const onChange = (e) => {
    const { value } = e.target;
    setDepartment(value);
  };
  const onChangeAccountStatus = (e) => {
    const { name, value } = e.target;
    setAccount({
      ...account,
      [name]: value,
    });
  };
  const getManagersRequisition = useCallback(async (selectedPage) => {
    const data = {
      status: 'queued',
      paginate: true,
      department_id:
        deptFilter === 'a4a0d87a-bc1f-41cb-ab97-fc9d6405b8f1' ? deptFilter : sessionStorage.getItem('departmentId'),
    };

    dispatch({
      type: load.FETCHING_STARTS,
    });
    try {
      const response = await axios.post(
        `${url.GET_REQUISITIONS_BY_DEPARTMENT_URL}/${data.department_id}?page=${selectedPage}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );
      if (response.data) {
        dispatch({
          type: types.GET_MANAGERS_REQUISITIONS,
          payload: {
            managerRequisitions: response.data.list,
            totalPages: response.data.pages,
          },
        });
      }
    } catch (e) {
      dispatch({
        type: load.FETCHING_STOPS,
      });
    }
  }, []);

  const getManagerApprovedRequsitionsByDepartment = useCallback(async (selectedPage) => {
    const data = {
      status: 'approved',
      paginate: true,
    };
    dispatch({
      type: load.FETCHING_STARTS,
    });
    try {
      const response = await axios.post(
        `${url.GET_REQUISITIONS_BY_DEPARTMENT_URL}/${sessionStorage.getItem('departmentId')}?page=${selectedPage}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );
      if (response.data) {
        dispatch({
          type: types.GET_MANAGERS_REQUISITIONS,
          payload: {
            managerRequisitions: response.data.list,
            totalPages: response.data.pages,
          },
        });
      }
    } catch (e) {
      dispatch({
        type: load.FETCHING_STOPS,
      });
    }
  }, []);
  const getExecutiveRequisition = async () => {
    try {
      dispatch({
        type: load.FETCHING_STARTS,
      });
      const response = await axios.get(`${url.GET_EXECUTIVE_REQUISITION_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_EXECUTIVES_REQUISITIONS,
          payload: response.data,
        });
      }
    } catch (e) {
      if (e) {
        dispatch({
          type: load.FETCHING_STOPS,
        });
      }
    }
  };
  const getEmployeesRequisition = useCallback(
    async (page) => {
      try {
        dispatch({
          type: load.FETCHING_STARTS,
        });
        const response = await axios.get(`${url.GET_EMPLOYEE_REQUISITION_URL}/${page}?paginate=true`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });
        if (response.data) {
          dispatch({
            type: types.GET_EMPLOYEES_REQUISITIONS,
            payload: {
              employeesRequisitions: response.data.list,
              employeesPages: response.data.pages,
            },
          });
        }
      } catch (e) {
        if (e) {
          dispatch({
            type: load.FETCHING_STOPS,
          });
        }
      }
    },
    [page],
  );

  const handlePageClick = async (data) => {
    const selectedPage = data.selected + 1;
    setPage(selectedPage);
    managerApproval
      ? await getManagerApprovedRequsitionsByDepartment(selectedPage)
      : await getManagersRequisition(selectedPage);
    await getEmployeesRequisition(selectedPage);
  };

  const handlePageClicks = (data) => {
    const selectedPage = data.selected + 1;
    setPage(selectedPage);
    getEmployeesRequisition(selectedPage);
  };
  // eslint-disable-next-line consistent-return
  const makeRequisition = async (data) => {
    dispatch({
      type: load.LOADING_STARTS,
    });

    try {
      const response = await axios.post(`${url.MAKE_REQUISITION_URL}`, data, {
        headers: {
          // eslint-disable-next-line no-undef
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
        onUploadProgress: (progressEvent) => {
          setPercentage(parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total)));
        },
      });
      if (response.data.id) {
        toastr.success('Requisition Filled!');
        setFile(null);
        setRequisition(requisitionState);
        setPercentage(0);

        dispatch({
          type: types.REQUISITION_FILLED,
          // payload: response.data,
        });

        if (Number(`${sessionStorage.getItem('roleId')}`) === 3) {
          dispatch(getManagersRequisition(page));
        } else if (Number(`${sessionStorage.getItem('roleId')}`) < 3) {
          dispatch(getEmployeesRequisition());
        } else if (Number(sessionStorage.getItem('roleId')) > 7) {
          dispatch(getExecutiveRequisition());
        }
        return null;
      }
    } catch (e) {
      if (e.response.data.message === 'Please fill your employee profile' || e.response.data.status === 'failed') {
        setPercentage(0);
        toastr.info('Please fill your profile on the overview page!');

        dispatch({
          type: load.LOADING_STOPS,
        });
      } else {
        setPercentage(0);
        toast.info('Something went wrong!');
        dispatch({
          type: load.LOADING_STOPS,
        });
      }
    }
  };
  // eslint-disable-next-line consistent-return
  const giveManagerApproval = async (id, data) => {
    dispatch({
      type: types.START_APPROVE_OR_DISAPPROVE,
    });
    try {
      const response = await axios.patch(`${url.MANAGER_UPDATE_REQUSITION_STATUS_URL}/${id}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        // dispatch({
        //   type: types.APPROVE_OR_DISAPPROVE_SUCCESS,
        //   // payload: id,
        // });
        toast.success('Requisition Approved');
        dispatch({
          type: types.STOP_APPROVE_OR_DISAPPROVE,
        });
        setApprove('');

        // setTimeout(() => {
        //   window.location.reload();
        // }, 3000);
        // if (Number(`${sessionStorage.getItem('roleId')}`) === 3) {
        //   dispatch(getManagersRequisition());
        // } else {
        //   return null;
        // }
      }
    } catch (e) {
      if (e) {
        if (e.response.data) {
          toast.info(e.response.data.message);
          dispatch({
            type: types.STOP_APPROVE_OR_DISAPPROVE,
          });
        }
      }
    }
  };
  const giveManagerDisApproval = async (data, id) => {
    dispatch({
      type: types.START_APPROVE_OR_DISAPPROVE,
    });
    try {
      const response = await axios.patch(`${url.MANAGER_UPDATE_REQUSITION_STATUS_URL}/${id}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.STOP_APPROVE_OR_DISAPPROVE,
        });
        toast.success('Requisition Rejected');

        // dispatch({
        //   type: types.APPROVE_OR_DISAPPROVE_SUCCESS,
        //   payload: id,
        // });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    } catch (e) {
      if (e) {
        toast.info(e.response.data.message);
        dispatch({
          type: types.STOP_APPROVE_OR_DISAPPROVE,
        });
      }
    }
  };
  const giveExecutiveApproval = async (id) => {
    const data = {
      status: 'yes',
    };
    dispatch({
      type: types.START_APPROVE_OR_DISAPPROVE,
    });
    try {
      const response = await axios.post(`${url.EXECUTIVE_UPDATE_REQUSITION_STATUS_URL}/${id}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        toast.success('Requsition Approved');

        setTimeout(() => {
          window.location.reload();
        }, 3000);

        // dispatch({
        //   type: types.APPROVE_OR_DISAPPROVE_SUCCESS,
        //   payload: id,
        // });
      }
    } catch (e) {
      if (e) {
        toast.info(e.response.data.message);
        dispatch({
          type: types.STOP_APPROVE_OR_DISAPPROVE,
        });
      }
    }
  };
  const giveExecutiveDisApproval = async (data, id) => {
    // const data = {
    //   status: 'no',
    // };
    dispatch({
      type: types.START_APPROVE_OR_DISAPPROVE,
    });
    try {
      const response = await axios.post(`${url.EXECUTIVE_UPDATE_REQUSITION_STATUS_URL}/${id}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        toast.success('Requisition Rejected');

        // dispatch({
        //   type: types.APPROVE_OR_DISAPPROVE_SUCCESS,
        //   payload: id,
        // });

        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    } catch (e) {
      if (e) {
        toast.info(e.response.data.message);
        dispatch({
          type: types.STOP_APPROVE_OR_DISAPPROVE,
        });
      }
    }
  };
  const getFieldSupportRequisition = async (params) => {
    const data = {
      status: 'queued',
      paginate: true,
    };

    dispatch({
      type: load.FETCHING_STARTS,
    });

    if (params === 'technical') {
      try {
        const response = await axios.post(`${url.GET_REQUISITIONS_BY_DEPARTMENT_URL}/${TECHNICAL_SUPPORT}`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });
        if (response.data) {
          dispatch({
            type: types.GET_MANAGER_REQUISITIONS,
            payload: response.data.list,
          });
        }
      } catch (e) {
        dispatch({
          type: load.FETCHING_STOPS,
        });
      }
    } else if (params === 'operations') {
      try {
        const response = await axios.post(`${url.GET_REQUISITIONS_BY_DEPARTMENT_URL}/${OPERATIONS}`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });
        if (response.data) {
          dispatch({
            type: types.GET_MANAGER_REQUISITIONS,
            payload: response.data.list,
          });
        }
      } catch (e) {
        dispatch({
          type: load.FETCHING_STOPS,
        });
      }
    } else {
      toast.info('Choose a department to search with');
    }
  };
  const getRequisitionByAccountStatus = async (params) => {
    const search = {
      status: params.status,
    };
    dispatch({
      type: load.FETCHING_STARTS,
    });

    try {
      const response = await axios.post(`${GET_ACCOUNTS_REQUSITION_BY_STATUS_URL}/${params.department}`, search, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_MANAGER_REQUISITIONS,
          payload: response.data.list.rows,
        });
      } else {
        dispatch({
          type: load.FETCHING_STOPS,
        });
      }
    } catch (e) {
      if (e) {
        dispatch({
          type: load.FETCHING_STOPS,
        });
      }
    }
  };
  const filterExecutiveRequisitionByCountry = async (query) => {
    try {
      dispatch({
        type: load.FETCHING_STARTS,
      });
      const response = await axios.get(`${url.GET_EXECUTIVE_REQUISITION_URL}?country=${query}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_EXECUTIVES_REQUISITIONS,
          payload: response.data,
        });
      }
    } catch (e) {
      if (e) {
        dispatch({
          type: load.FETCHING_STOPS,
        });
      }
    }
  };
  const filterRequisitionByCountry = async (query) => {
    const data = {
      status: 'queued',
      paginate: true,
      country: query,
    };
    dispatch({
      type: load.FETCHING_STARTS,
    });
    try {
      const response = await axios.post(
        `${url.GET_REQUISITIONS_BY_DEPARTMENT_URL}/${sessionStorage.getItem('departmentId')}?page=${page}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );
      if (response.data) {
        dispatch({
          type: types.GET_MANAGERS_REQUISITIONS,
          payload: {
            managerRequisitions: response.data.list,
            totalPages: response.data.pages,
          },
        });
      }
    } catch (e) {
      dispatch({
        type: load.FETCHING_STOPS,
      });
    }
  };
  const getManagersRaisedRequisition = useCallback(async () => {
    try {
      dispatch({ type: load.FETCHING_STARTS });
      const response = await axios.get(`${url.GET_EMPLOYEE_REQUISITION_URL}/${page}?paginate=true`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_MANAGERS_REQUISITIONS,
          payload: {
            managerRequisitions: response.data.list,
            totalPages: response.data.pages,
          },
        });
      }
    } catch (e) {
      dispatch({ type: load.FETCHING_STOPS });
    }
  }, [page]);
  const getRequsitionsByDepartment = async (params) => {
    const data = {
      status: 'queued',
    };
    dispatch({
      type: load.FETCHING_STARTS,
    });
    try {
      const response = await axios.post(`${url.GET_REQUISITIONS_BY_DEPARTMENT_URL}/${params}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_MANAGER_REQUISITIONS,
          payload: response.data.rows,
        });
      }
    } catch (e) {
      dispatch({
        type: load.FETCHING_STOPS,
      });
    }
  };

  const getGhanaRequsition = async (params) => {
    const data = {
      status: 'queued',
      country: 'gh',
    };

    dispatch({
      type: load.FETCHING_STARTS,
    });
    try {
      const response = await axios.post(`${url.GET_REQUISITIONS_BY_DEPARTMENT_URL}/${params}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_MANAGER_REQUISITIONS,
          payload: response.data.rows,
        });
      }
    } catch (e) {
      dispatch({
        type: load.FETCHING_STOPS,
      });
    }
  };

  useEffect(() => {
    getManagersRequisition(page);
    getExecutiveRequisition();
    getEmployeesRequisition();
  }, []);
  return {
    requisition,
    handlePageClick,
    handlePageClicks,
    totalPages,
    employeesPages,
    onChangeRequisition,
    onFileChange,
    file,
    makeRequisition,
    isLoading,
    percentage,
    managerRequisitions,
    isFetching,
    executiveRequisitions,
    onChangeApproveOrDisapprove,
    getManagerApprovedRequsitionsByDepartment,
    status,
    isApprovingOrDisapproving,
    giveManagerApproval,
    giveManagerDisApproval,
    isFetchingExecRequisitions,
    giveExecutiveApproval,
    giveExecutiveDisApproval,
    employeesRequisitions,
    onCloseModal,
    onOpenModal,
    showApproved,
    // disapproveRequisition,
    onChangeDisapprove,
    disapprove,
    onChangeApprove,
    approve,
    department,
    onChange,
    getFieldSupportRequisition,
    account,
    setManagerApproval,
    onChangeAccountStatus,
    getRequisitionByAccountStatus,
    filterRequisitionByCountry,
    filterExecutiveRequisitionByCountry,
    getManagersRaisedRequisition,
    getRequsitionsByDepartment,
    getGhanaRequsition,
    getManagersRequisition,
  };
};
