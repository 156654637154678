import React, { useContext } from 'react';
// import SearchNocSheets from '../SearchNocSheets';
import EnbSiteIpAllocationTable from './EnbSiteIpAllocationTable';
import AddEnbSiteIpAllocation from './AddEnbSiteIpAllocation';
import { EnbSiteIpAllocationContext } from './context/EnbSiteIpAllocationContext';
import PaginatePage from './PaginatePage';

const EnbSiteIpAllocationBody = () => {
  const { loading } = useContext(EnbSiteIpAllocationContext);
  return (
    <div className="container">
      <div className="row mt-5">
        {/* <SearchCnMaestro /> */}
        <div className="form-group ml-2">
          <button className="btn btn-green" type="button" data-toggle="modal" data-target="#add-access-point">
            Add Enb Site Ip Allocation
          </button>
        </div>
        <AddEnbSiteIpAllocation />
        <EnbSiteIpAllocationTable />
        {!loading && <PaginatePage />}
      </div>
    </div>
  );
};

export default EnbSiteIpAllocationBody;
