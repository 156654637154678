/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import CustomInput from '../forms/CustomInput';

const SearchInstallationSummary = ({
  params,
  page,
  handleChange,
  loading,
  searchInstallations,
  searchfailedInstallations,
  searchCompletedInstallations,
  getInstallationCounter
}) => {
  const { country, fromDate, toDate } = params;
  const [keywordPage] = useState(1);
  const date = {
    from: fromDate,
    to: toDate,
  };

  

  const completed = {
    status: 'completed',
    from: fromDate,
    to: toDate,
    zone_id: '',
    paginate: true,
    addZone: 'yes',
    country
  }

  const counter = {
    from: fromDate,
    to: toDate,
    status: "completed",
    country
  };

  const reschedule = {
    from: fromDate,
    to: toDate,
    zone_id: '',
    paginate: true,
    status: '',
    addZone: 'yes',
    reschedule_status: true,
    country
  };

  const failed = {
    status: 'failed',
    from: fromDate,
    to: toDate,
    zone_id: '',
    paginate: true,
    addZone: 'yes',
    country
  };

  const filterInstallations = (e) => {
    e.preventDefault();
    // getData(`${LEGACY_GENIATECH_URL}`, data);
    // searchUsingKeyword(keywordPage, date);
    searchInstallations(reschedule);
    searchfailedInstallations(failed);
    searchCompletedInstallations(page, completed)
    getInstallationCounter(counter);
  };

  return (
    <div className="smallTop d-flex">
      <form onSubmit={filterInstallations} className="form-inline ml-3">
        <div className="form-group">
          <select name="country" value={country} onChange={handleChange} className="form-control">
            <option value="">--Country--</option>
            <option value="ng">Nigeria</option>
            <option value="gh">Ghana</option>
            <option value="cv">Cote d'Ivoire</option>
          </select>
        </div>
        <div className="form-group">
          <small className="mr-1 ml-3 mt-2 copy-font">From:</small>
          <CustomInput
            placeholder=""
            readOnly={false}
            type="date"
            classname="form-control"
            name="fromDate"
            value={fromDate}
            onChange={handleChange}
          />
          <small className="mr-1 ml-3 mt-2 copy-font">To:</small>
          <CustomInput
            placeholder=""
            readOnly={false}
            type="date"
            classname="form-control"
            name="toDate"
            value={toDate}
            onChange={handleChange}
          />
        </div>

        <div className="form-group ml-2">
          <button className="btn btn-green" type="submit" disabled={!(country && fromDate && toDate)}>
            {loading ? 'Searching...' : 'Search'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchInstallationSummary;
