/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

const ActivatedTable = ({ load, activated }) => {
  const activatedList =
    activated && activated.length ? (
      activated.map((active, i) => (
        <tr className="copy-font">
          <th scope="row">{i + 1}</th>
          <td>{active.businessName}</td>
          <td>{active.email}</td>
          <td>{active.phone}</td>
          <td>{active.created}</td>
          <td>
            <span className="badge badge-success">
              Active
            </span>
          </td>
          {/* <td className="shift"> */}
          {/*  /!* <div className="d-flex ml-3"> */}
          {/*    <button type="submit" data-target="#hotspot-details" data-toggle="modal" data-placement="right"  ><span className="view1"> */}
          {/*    <img */}
          {/*        src={"https://res.cloudinary.com/tizeti/image/upload/v1542971020/view.png"} */}
          {/*        data-toggle="tooltip" data-placement="right" title="View"/> */}
          {/*         </span  > */}
          {/*    </button> */}

          {/* <RetailerDetails/> */}

          {/*    <button type="submit" data-toggle="modal" data-target="#edit-retailer" style={{ */}
          {/*        border: '0', */}
          {/*        backgroundColor: 'white' */}
          {/*        }}><span className="view"><img */}
          {/*        src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/edit.png" */}
          {/*        data-toggle="tooltip" data-placement="top" title="Edit"/></span> */}
          {/*    </button> */}

          {/*    <EditRetailer /> */}

          {/*    <button type="submit" data-toggle="modal" data-target="#cancel" style={{ */}
          {/*        border: '0', */}
          {/*        backgroundColor: 'white' */}
          {/*        }}><span className="view"><img */}
          {/*        src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/delete.png" */}
          {/*        data-toggle="tooltip" data-placement="right" title="Block"/></span> */}
          {/*    </button> */}
          {/* </div> *!/ */}

          {/*  <div> Not Permitted</div> */}
          {/* </td> */}
        </tr>
      ))
    ) : (
      <small>no activated retailers</small>
    );

  return (
    <div
      className="tab-pane fade show "
      id="pills-profile"
      role="tabpanel"
      aria-labelledby="pills-profile-tab"
    >
      <table className="table">
        <thead>
          <tr className="copy-font">
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Phone Number</th>
            <th scope="col">Dated Registered</th>
            <th scope="col">Status</th>
            {/* <th scope="col" className="text-center">Actions</th> */}
          </tr>
        </thead>

        <tbody>
          {load ? <small>loading...</small> : activatedList}
        </tbody>
      </table>
    </div>
  );
};

ActivatedTable.propTypes = {
  load: PropTypes.bool,
  activated: PropTypes.shape(),
};
export default ActivatedTable;
