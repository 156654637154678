/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import RelocationTable from './RelocationTable';
import { useRelocation } from '../../../../hooks/sales/relocation/useRelocation';
import AddRelocation from './AddRelocation';
import { CUSTOMER_SUPPORT, SALES, ASSET_TEAM } from '../../../../utils/departments/helpers';

const RelocationBody = () => {
  const { lists, pages, count, isLoading, getRelocationRequest, searchUsingKeyword } = useRelocation();

  const [keyword, setKeyword] = useState('');
  const [option, setOption] = useState('');
  const [page] = useState(1);
  const [date, setDate] = useState({ from: '', to: '' });

  const searchEmail = { email: keyword.trim() };
  const searchPhone = { phoneNumber: keyword.trim() };

  const handleDate = (e) => {
    const { name, value } = e.target;
    setDate({ ...date, [name]: value });
  };
  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    if (option === 'email') return searchUsingKeyword(selectedPage, searchEmail);
    if (option === 'phone') return searchUsingKeyword(selectedPage, searchPhone);
    if (option === 'date') return searchUsingKeyword(selectedPage, date);
    return getRelocationRequest(selectedPage);
  };

  const getDepartment = sessionStorage.getItem('departmentId');

  const filterUsingKeyword = (e) => {
    e.preventDefault();
    switch (option) {
      case 'email':
        return searchUsingKeyword(page, searchEmail);

      case 'phone':
        return searchUsingKeyword(page, searchPhone);

      case 'date':
        return searchUsingKeyword(page, date);

      default:
        return null;
    }
  };

  useEffect(() => {
    getRelocationRequest(page);
  }, []);

  return (
    <div className="col-12 p-0 after-summary">
      <div className="row mx-2">
        <div className="mb-1">
          <div className="smallTop d-flex">
            <form onSubmit={filterUsingKeyword} className="form-inline ml-3">
              <div className="form-group">
                <select name="chooseField" onChange={(e) => setOption(e.target.value)} className="form-control">
                  <option value="">--Choose Search Field--</option>
                  <option value="email">Email</option>
                  <option value="phone">Phone</option>
                  <option value="date">Date</option>
                </select>
              </div>
              {option !== 'date' ? (
                <div className="form-group ml-2">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Input Search Term"
                    style={{ width: '230px' }}
                    name="keyword"
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                  />
                </div>
              ) : (
                <>
                  <div className="form-group d-flex ml-2">
                    <small className="mr-1">From: </small>
                    <input type="date" className="form-control" name="from" onChange={handleDate} />
                  </div>
                  <div className="form-group d-flex ml-2">
                    <small className="mr-1">To: </small>
                    <input type="date" className="form-control" name="to" onChange={handleDate} />
                  </div>
                </>
              )}
              <div className="form-group ml-2">
                <button className="btn btn-green" type="submit">
                  Search
                </button>
              </div>
            </form>
            {getDepartment === SALES && (
              <div className="ml-2">
                <button className="btn btn-green" type="button" data-toggle="modal" data-target="#addRelocation">
                  Add
                </button>
              </div>
            )}
            <AddRelocation />
          </div>
        </div>

        <div className="mt-2 mb-3 col-12">
          {lists.length ? (
            <div className="col-12 baby-fonts">
              Number of Requests: <span className="badge badge-dark payslipfont">{count}</span>
            </div>
          ) : null}
        </div>
        <div className="ml-2">
          {getDepartment === SALES && (
            <Link to="/sales/relocation/pending-relocation" target="_self">
              <button type="button" className="btn btn-primary">
                Pending Relocation
              </button>
            </Link>
          )}
        </div>

        <div className="card x-panel p-0">
          <RelocationTable lists={lists} isLoading={isLoading} />

          <div className="px-3">
            {pages > 1 ? (
              <ReactPaginate
                previousLabel="previous"
                nextLabel="next"
                breakLabel="..."
                breakClassName="break-me"
                pageCount={pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName="pagination"
                // subContainerClassName="pages pagination"
                activeClassName="active"
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RelocationBody;
