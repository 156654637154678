import React from 'react';
import { useNoLos } from '../../../../../hooks/shared/no-los/useNoLos';
import NoLosRow from './NoLosRow';

const NoLosTable = () => {
  const { noLos, loading } = useNoLos();

  const displayData = () =>
    noLos && noLos.length ? (
      noLos.map((los, index) => <NoLosRow key={los.id} item={los} index={index} />)
    ) : (
      <small>no data</small>
    );
  return (
    <div className="table-responsive content col-lg-12 col-md-12 col-sm-12">
      <table className="table">
        <thead>
          <tr>
            {/* <th>#</th> */}
            {/* <th>Name</th> */}
            <th>Latitude</th>
            <th>Longitude</th>
            <th>Address</th>
            <th>Country</th>
            <th>Notes</th>
            {/* <th>Employee Name</th> */}
            <th>State</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>{loading ? <small>loading</small> : displayData()}</tbody>
      </table>
    </div>
  );
};

export default NoLosTable;
