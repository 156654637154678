/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useContext } from 'react';
import loader from '../../../../../../assets/img/icons/TizetiLoaderColored.gif';
import helpers from '../../../../../../utils/helpers';
// import { SitesProps } from './types/SitesProps';
import GeniatechContext from '../../../../../../context/GeniatechContext';

const SitesTable = () => {
  const { loading: load, data, searchData } = useContext(GeniatechContext);
  const getInformation = (row) => {
    searchData({
      action: 'site_information',
      offset: '0',
      limit: '100',
      network_id: row.network,
      site_id: row.name,
    });
  };

  const tableData =
    data && data.length
      ? data.map((row, i) => (
          <tr className="text-nowrap" key={i + 1} onClick={() => getInformation(row)} style={{ cursor: 'pointer' }}>
            <td>{i + 1}</td>
            <td>{row.name}</td>
            <td>{row.network}</td>
            <td>{row.location.type}</td>
            <td>{row.location.coordinates}</td>
            <td>{row.managed_account}</td>
            <td>{helpers.formatDateAndTime(row.created_at)}</td>
          </tr>
        ))
      : 'no sites';
  return (
    <div className="card x-panel mt-2 px-0">
      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th className="text-nowrap">Network</th>
              <th>Location Type</th>
              <th>Location Cordinates</th>
              <th>Managed Account</th>
              <th>Date Created</th>
            </tr>
          </thead>
          <tbody>
            {load ? (
              <tr>
                <img src={loader} alt="loading gif" />
              </tr>
            ) : (
              tableData
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SitesTable;
