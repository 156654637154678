import React, { useContext } from 'react';
import AppContext from '../../../../context/shared/AppContext';

const PageDataCount = () => {
  const { count } = useContext(AppContext);

  return (
    <div className="d-flex-end mr-3">
      {count && (
        <>
          Count:
          <span className="badge badge-secondary ml-1">{count}</span>
        </>
      )}
    </div>
  );
};

export default PageDataCount;
