import React from 'react';
import CustomPagination from '../../../../shared/CustomPagination';

const PaginatePage = ({ pages, paginateChange }) => {
  const pageChange = (params) => {
    const { selected } = params;
    paginateChange(selected + 1);
  };

  return (
    <CustomPagination pagesCount={pages} marginPagesDisplayed={5} pageRangeDisplayed={5} onPageChange={pageChange} />
  );
};

export default PaginatePage;
