/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import axios from 'axios';
import toastr from 'toastr';
import { API_URL, token } from '../../../../../utils/config';
import Surveys from '../surveys/Surveys';
// import ReactCopyButtonWrapper from 'react-copy-button-wrapper'

class NetPromoter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      description: '',
      loading: false,
      load: false,
      surveys: [],
      body: '',
      copied: false,
    };

    this.onChange = this.onChange.bind(this);
    this.createSurvey = this.createSurvey.bind(this);
  }

  componentDidMount() {
    this.getAllSurveys();
  }

  getAllSurveys() {
    axios.get(`${API_URL}/campaign/getList`, token).then((response) => {
      // console.log(response.data);
      if (response.data.rows) {
        this.setState({
          surveys: response.data.rows,
        });
      }
    });
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  createSurvey(e) {
    e.preventDefault();

    this.setState({
      loading: true,
      body: '',
      copied: false,
    });

    if (!this.state.name) {
      toastr.info('Please enter a name!');
      this.setState({
        loading: false,
      });
    }

    if (!this.state.description) {
      this.setState({
        loading: false,
      });
      toastr.info('Please enter a description!');
    }

    // form submission here👇🏽👇🏽
    const data = {
      name: this.state.name,
      description: this.state.description,
    };

    axios({
      method: 'post',
      data,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      url: `${API_URL}/campaign/add`,
    })
      .then((res) => {
        // console.log(typeof res.data);

        if (typeof res.data === 'string') {
          this.setState(
            {
              loading: false,
              name: '',
              description: '',
              body: res.data,
            },
            () => this.getAllSurveys(),
          );

          toastr.success('Survey Created Successfully!');
        } else {
          toastr.error('Something went wrong! Please try again!');
        }
      })
      .catch((err) => {
        if (err) {
          this.setState({
            loading: false,
          });

          toastr.error('Something went wrong! Please try again!');
        }
      });
  }

  render() {
    const { loading, surveys } = this.state;

    return (
      <div className="after-summary">
        <div className="container m-0">
          <div className="row">
            <div className="tab-stuff col-4 mt-4">
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item mr-auto mb-2">
                  <a
                    className="nav-link active text-nowrap"
                    id="pills-home-tab"
                    data-toggle="pill"
                    href="#pills-home"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Create Survey
                  </a>
                </li>
                <li className="nav-item mr-auto mb-2">
                  <a
                    className="nav-link"
                    id="pills-profile-tab"
                    data-toggle="pill"
                    href="#pills-profile"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    Surveys
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="row">
            <div className="tab-content col-12 col-lg-12 p-0" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div className="col-lg-12 p-0 page-title mt-4 mb-3 d-flex">
                  <div className="col-lg-6 p-0">
                    <div className="main-content col-lg-12 p-0 d-flex">
                      <form className="form-horizontal col-lg-12 p-0" onSubmit={this.createSurvey}>
                        <div className="form-group col-lg-12 p-0">
                          <div className="col-lg-12 d-flex flex-wrap mb-2 p-0">
                            <div className="col-lg-6">
                              <label className="copy-font m-0 p-0">Product or survey name</label>
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Wificall"
                                name="name"
                                onChange={this.onChange}
                                value={this.state.name}
                              />
                            </div>

                            <div className="col-lg-6 mb-3 ">
                              <label className="copy-font m-0 p-0">Description</label>
                              <textarea
                                className="form-control"
                                type="text"
                                placeholder="Thank you, can you tell us why?"
                                name="description"
                                onChange={this.onChange}
                                value={this.state.description}
                              />
                            </div>
                          </div>

                          {/* <div className="col-12 d-flex mb-3 p-0">
                <div className="col-lg-6">
                    <label className="copy-font m-0 p-0">Audience</label>
                    <input className="form-control" type="text" placeholder="A friend or co-worker"/>
                </div>
            </div>

            <div className="col-12 d-flex mb-3 p-0">
                <div className="col-lg-6">
                    <label className="copy-font m-0 p-0">Text Box Prompt</label>
                    <input type="text" className="form-control" placeholder="Help us by explaining your score"/>
                </div>
            </div> */}
                        </div>
                        {loading ? (
                          <div className="col-lg-12 button-cat d-flex mt-5">
                            <button className="btn btn-green" type="button" disabled>
                              Create Survey
                            </button>
                          </div>
                        ) : (
                          <div className="col-lg-12 button-cat d-flex mt-5">
                            <button className="btn btn-green" type="button">
                              Create Survey
                            </button>
                          </div>
                        )}
                      </form>
                      {this.state.body ? (
                        <div className="col-lg-12 mb-3 ">
                          <div className="d-flex">
                            <label className="copy-font m-0 p-0 col-6 text-justify">HTML Code</label>
                            <div className="col-6 text-right p-0 mb-3">
                              {this.state.copied ? <span style={{ color: 'red' }}>Copied.</span> : null}
                              <CopyToClipboard
                                text={this.state.body}
                                onCopy={() =>
                                  this.setState({
                                    copied: true,
                                  })
                                }
                              >
                                <button className="btn btn-success" type="button">
                                  Copy
                                </button>
                              </CopyToClipboard>
                            </div>
                            {/* <ReactCopyButtonWrapper text='test'> */}
                            {/* <button>Copy</button> */}
                            {/* </ReactCopyButtonWrapper> */}
                          </div>
                          <textarea
                            className="form-control"
                            rows="12"
                            cols="200"
                            type="text"
                            name="description"
                            readOnly
                          >
                            {this.state.body}
                          </textarea>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <Surveys all={surveys} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NetPromoter;
