/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable import/prefer-default-export */
import { useReducer, useState, useEffect } from 'react';
import axios from 'axios';
import qs from 'qs';
import toastr from 'toastr';
import * as load from '../../../constants/types/shared/LoadingTypes';
import * as types from '../../../constants/types/shared/requisition/RequisitionTypes';
import * as url from '../../../services/api/accounts/requisition/RequisitionUrl';
import { paidRequisitionReducer } from '../../../reducers/accounts/requistions/paidRequisitionReducer';

export const usePaidRequisition = () => {
  const [{ paidRequisitions, pages, isLoading }, dispatch] = useReducer(paidRequisitionReducer, {
    paidRequisitions: [],
    pages: 1,
    isLoading: false,
  });
  const [purpose, setPurpose] = useState('');
  const page = 1;

  const getPaidRequisitions = async (currentPage) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const data = { account_payment_status: 'paid' };
      const response = await axios.post(`${url.SEARCH_REQUISIITONS_URL}/${currentPage}`, qs.stringify(data), {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.status === 'success') {
        dispatch({
          type: types.GET_PAID_REQUISITION,
          payload: {
            paidRequisitions: response.data.result.list,
            pages: response.data.result.pages,
          },
        });
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const searchPaidRequisition = async (currentPage) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const searchPurpose = { item: purpose };
      const response = await axios.post(`${url.SEARCH_REQUISIITONS_URL}/${currentPage}`, qs.stringify(searchPurpose), {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.status === 'success') {
        const { result, data } = response.data;
        dispatch({
          type: types.GET_PAID_REQUISITION,
          payload: {
            paidRequisitions: result ? result.list : data.rows,
            pages: result ? result.pages : 1,
          },
        });
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    if (purpose) return searchPaidRequisition(selectedPage);
    return getPaidRequisitions(selectedPage);
  };

  useEffect(() => {
    getPaidRequisitions(page);
  }, []);

  return {
    paidRequisitions,
    purpose,
    pages,
    isLoading,
    searchPaidRequisition,
    setPurpose,
    handlePageClick,
  };
};
