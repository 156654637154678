import React, { useEffect, useState } from 'react';
import qs from 'qs';
import Select from 'react-select';
import { ToastContainer } from 'react-toastify';
import logo from '../../../../../assets/img/logo/Tizeti-Logo.png';
import useDepartment from './useDepartment';
import useDep from './useDep';

const ResetDepartment = () => {
  const { postDepartment, isLoading } = useDepartment();

  const { list, getDep } = useDep();

  const [DepartmentCategory, setDepartmentCategory] = useState();
  const [DepartmentEmail, setDepartmentEmail] = useState();

  const data = qs.stringify({
    department_id: DepartmentCategory,
    email: DepartmentEmail,
  });

  console.log(data);

  function onSubmit(e) {
    e.preventDefault();
    postDepartment(data);
  }

  const handleChange = (value) => {
    setDepartmentCategory(value);
  };

  useEffect(() => {
    getDep();
  }, []);

  //   let options = list.map(function (lis) {
  //     return { value: lis.id, label:  lis.name };
  //   })

  //   const options = [
  //     { value: '3a237e10-fc54-4c0c-bf02-821356840269', label: 'ACCOUNTS' },
  //     { value: 'c3caed52-dfd5-402f-80e4-31861da8554e', label: 'ADMIN' },
  //     { value: '5cc24f2e-696a-4dec-870c-b6e6aaf499f0', label: 'ADMIN_DEPARTMENT' },
  //     { value: '5fda6293-d2c0-4302-b071-aece18434c23', label: 'ASSET_TEAM' },
  //     { value: '874d238d-5019-4890-81a1-09ec83d36306', label: 'CUSTOMER_SUPPORT' },
  //     { value: 'a6d0ad8a-5c0a-4fe9-8adb-f9f2fe4b87d6', label: 'EXPRESS_WIFI' },
  //     { value: 'a4a0d87a-bc1f-41cb-ab97-fc9d6405b8f1', label: 'NEW_PRODUCTS' },
  //     { value: '6e072411-d6eb-413d-be6c-c4b5bc812320', label: 'NOC' },
  //     { value: 'e1f5454d-660d-4a0e-8640-c90950dd97fc', label: 'OPERATIONS' },
  //     { value: '558a7002-4933-4d11-8e21-5b68a8b8cc01', label: 'PROCUREMENT' },
  //     { value: '22dfe612-9f8b-4183-a75e-81a33595aa46', label: 'RESEARCH_AND_DEVELOPMENT' },
  //     { value: 'a521da84-8c18-4e22-9a49-c2aee8706e79', label: 'BUSINESS_DEVELOPMENT' },
  //     { value: 'aaf924bf-e16f-4ddc-843b-be5f91c8b0d2', label: 'SALES' },
  //     { value: 'c3caed52-dfd5-402f-80e4-31861da8554e', label: 'SUPER_ADMIN' },
  //     { value: 'fbdac94e-9500-4845-b260-5d6a912bda36', label: 'TECHNICAL_SUPPORT' },
  //   ]

  return (
    <body>
      <div className="main-wrapper">
        <div className="col-lg-12 carrier d-flex">
          <div className="col-lg-6 col-md-6 explain d-none d-sm-none d-md-block d-lg-block">
            <div className="intro">
              <h3 style={{ color: 'white' }}>Welcome To Tizeti OS </h3>
              <p>
                We built Tizeti OS with the aim of building a better experience when performing your daily tasks. We are
                currently roling out more features that would further enhance your work.
                <br />
                <br />
                If you have any complaints or suggestions as to how this platform will be better, please send us an
                email to rd@tizeti.com
                <br />
                <br />
                Reset your department here
                <br />
                <br />- Research and Development Team
              </p>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="login col-sm-12 col-lg-12 col-md-12">
              <div className="tizeti-logo">
                <img src={logo} alt="" />
              </div>
              <form onSubmit={onSubmit}>
                <div className="form-group col-lg-9 col-sm-12">
                  <div className=" mt-5">
                    <label className="label2">Enter Email</label>
                    <input
                      className="form-control"
                      type="email"
                      aria-label="Default select example"
                      name="DepartmentEmail"
                      value={DepartmentEmail}
                      placeholder="Enter your Tizeti email address"
                      onChange={(e) => setDepartmentEmail(e.target.value)}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label className="label2">Choose Department</label>
                    <select
                      className="form-control"
                      value={DepartmentCategory}
                      onChange={(e) => setDepartmentCategory(e.target.value)}
                      name="DepartmentCategory"
                    >
                      <option>Select Department</option>
                      {list.map((lis) => {
                        return (
                          <option value={lis.id} key={lis.id}>
                            {lis.name}
                          </option>
                        );
                      })}
                    </select>
                    {/* <><Select 
                       
                        class="form-select"
                        onChange={handleChange}
                        labelKey='name'
                        valueKey='name'
                        value={DepartmentCategory}
                        options={options} /></> */}
                  </div>

                  <div className="wrapper-button mt-4 mb-3">
                    {isLoading ? (
                      <button
                        className="btn btn-green"
                        disabled={isLoading}
                        style={{
                          backgroundColor: '#036D61',
                        }}
                      >
                        Submitting...
                      </button>
                    ) : (
                      <button className="btn btn-green">Submit</button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </body>
  );
};

export default ResetDepartment;
