import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getRetailerLocations } from '../../../../../../../actions/hotspot/HotspotActions';

class TotalLocations extends Component {
  componentDidMount() {
    this.props.getRetailerLocations();
  }

  render() {
    return (
      <div className="col-lg-3 col-md-3 col-sm-4">
        <div className="boxes border-box3">
          <div className="d-flex mt-3">
            <div className="mt-4 ml-3 mr-2">
              <img
                src="https://res.cloudinary.com/teewhy/image/upload/v1547546148/hotspotadminhotspot.fw.png"
                alt="hotspot-icon"
                className="w-75 mt-3"
              />
            </div>
            <div className="mt-4">
              <div className="count">N/A</div>

              <h6 className="baby-fonts">
                Total Hotspot Locations
              </h6>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// const mapStateToProps = state => ({
//
//   locations: state.locations.
// })

export default connect(null, { getRetailerLocations })(
  TotalLocations,
);
