/* eslint-disable import/prefer-default-export */
import { useReducer, useState } from 'react';
import qs from 'qs';
import axios from 'axios';
import toastr from 'toastr';
import CleanLTEReducer from '../../../reducers/field-support/clean-imsi/CleanLTEReducer';
import { CLEAN_LTE } from '../../../constants/types/field-support/CleanLTETypes';
import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';

export const useBBNSignups = () => {
  const [lists, setLists] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [isFetching, setIsFetching] = useState(false);


  const getBBN = async (data) => {
    // dispatch({ type: LOADING_STARTS });
    setIsLoading(true);

    const config = {
      method: 'get',
      url: `https://api.tizeti.com/api/v1/presignup`,
      data: data,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response.data) {
          setLists(response.data);
          setIsLoading(false);
          //   dispatch({
          //     type: CLEAN_LTE,
          //     payload: {
          //       lists: response.data.data,
          //     },
          //   });
        }
      })
      .catch(() => {
        toastr.info('Something went wrong!');
        // dispatch({ type: LOADING_STOPS });
        setIsLoading(false);
      });
  };


  const getJackets = async ( page) => {
    // dispatch({ type: LOADING_STARTS });
    setIsFetching(true);

    const config = {
      method: 'get',
      url: `https://api.tizeti.com/api/v1/jacket/getAllJackets/${page}`,
      // data: data,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response.data) {
          setRows(response.data.rows);
          setIsFetching(false);
          //   dispatch({
          //     type: CLEAN_LTE,
          //     payload: {
          //       lists: response.data.data,
          //     },
          //   });
        }
      })
      .catch(() => {
        toastr.info('Something went wrong!');
        // dispatch({ type: LOADING_STOPS });
        setIsFetching(false);
      });
  };

  return {
    lists,
    isFetching,
    rows,
    isLoading,
    getJackets,
    getBBN,
  };
};
