import { GET_DEDICATED_CONNECTED_USERS } from '../../../constants/types/business-development/dedicated-connected-users/DedicatedConnectedUsersTypes';
import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';

const initialState = {
  items: [],
  totalPages: '',
  allPages: '',
  currentPage: '',
  isLoading: false,
};

const DedicatedConnectedUsersReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOADING_STARTS:
      return {
        ...state,
        isLoading: true,
      };

    case GET_DEDICATED_CONNECTED_USERS:
      return {
        ...state,
        items: payload.items,
        totalPages: payload.totalPages,
        allPages: payload.allPages,
        currentPage: payload.currentPage,
        isLoading: false,
      };

    case LOADING_STOPS:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default DedicatedConnectedUsersReducer;
