import React from 'react';
import AddKpi from './AddKpi';

const AddKpiBody = () => {
  return (
    <>
      <div>
        <button className="btn btn-green p-2" data-target="#add-kpi" data-toggle="modal" type="button">
          Add KPI
        </button>
      </div>
      <AddKpi />
    </>
  );
};

export default AddKpiBody;
