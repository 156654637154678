/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { memo } from 'react';
import { useActivation } from '../../../../../hooks/shared/installations/useActivation';

const SendFeedback = ({ account }) => {
  const { sendMail, isChecking } = useActivation();



  const user = account.user;


  
  const onSubmit = (e) => {
    e.preventDefault();
    const installId = user.installations.length ? user.installations[0].id : "";
    sendMail(installId);
  };

  return (
    <>
      <div className="modal" id="sendFeedback">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center" id="exampleModalLabel">
                Send Feedback
              </h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h4 className="text-center">
                Confirm SendFeedback for &#34;
                {user ? `${user.firstName} ${user.lastName}` : 'N/A'}&#34;?
              </h4>
              <form onSubmit={onSubmit}>
                <div className="modal-footer">
                  {isChecking ? (
                    <button type="button" className="btn btn-secondary " disabled>
                      Submitting...
                    </button>
                  ) : (
                    <>
                      <h4>
                        <button type="submit" className="btn btn-primary">
                          Yes
                        </button>
                      </h4>
                      <h4 className="pl-3">
                        <button type="button" className=" btn btn-primary" data-dismiss="modal" aria-label="Close">
                          No
                        </button>
                      </h4>
                    </>
                  )}
                  {/* ) : (
                    <span className="text-danger text-uppercase">Assign to Individual/Team First</span>
                  )} */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(SendFeedback);
