import React from 'react';
import { ToastContainer } from 'react-toastify';
import { SubscriberProvider } from '../../../../../../context/lte/subscribers/SubscriberContext';
import CreateSubscriber from '../CreateSubscriber';
import useSubscriber from '../../../../../../hooks/shared/noc/lte/subscribers/useSubscribers';
import SubscribersTable from './SubscribersTable';

const Subscribers = () => {
  const { subscriber, onChange, onSubmit, subscribers } = useSubscriber();
  return (
    <div className="container">
      <SubscriberProvider value={{ subscriber, onChange, onSubmit }}>
        <div className="row mt-5">
          <div className="mt-5 col-lg-12 col-md-12 col-sm-12">
            <div className="d-flex employ inner-addon left-addon">
              <p className="op copy-font d-flex mr-auto">
                <small className="mt-2 mr-2"> Filter:</small>
                <span className="mr-2">
                  <select className="form-control">
                    {subscribers.map((subscrib) => (
                      <option value="">{subscrib.name}</option>
                    ))}
                  </select>
                </span>

                {/* <span> */}
                {/*  <select className="form-control"> */}
                {/*    <option>Ghana</option> */}
                {/*  </select> */}
                {/* </span> */}
              </p>
              <div>
                {/* <button type="submit" className="btn btn-green" data-toggle="modal" data-target="#lteModal"> */}
                {/*  Create Subscriber */}
                {/* </button> */}
                <CreateSubscriber />
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <SubscribersTable />
        </div>
      </SubscriberProvider>
      <ToastContainer />
    </div>
  );
};

export default Subscribers;
