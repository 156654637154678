/* eslint-disable react/no-array-index-key */
import React, { useContext } from 'react';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import { OverviewContext } from '../../../../../context/shared/installations/overview/OverviewContext';

const RoutedInstallation = () => {
  const { routedInstalls, routing } = useContext(OverviewContext);

  const displayRoutedInstalls =
    routedInstalls.route && routedInstalls.route.length ? (
      routedInstalls.route.map((installation, i) => {
        const { latitude, longitude, address, name, distance, unit } = installation;
        return (
          <tr key={i}>
            <td>{i + 1}</td>
            <td>{name}</td>
            <td>{address}</td>
            <td>
              {latitude} {longitude}
            </td>
            <td>
              {parseFloat(distance.toFixed(2))} {unit}
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="5" className="text-center">
          no route
        </td>
      </tr>
    );

  return (
    <div className="modal" id="routedInstall">
      <div className="modal-dialog modal-lg large-modal mt-5" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title">ROUTED INSTALLS</h2>
            <button type="button" className="close p-3" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div>
              <p>APPROXIMATE DISTANCE: {routedInstalls.Approximate_distance}</p>
              <div className="table-responsive content">
                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Address</th>
                      <th>Lat Lng</th>
                      <th>Distance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {routing ? (
                      <tr>
                        <td colSpan="5" className="text-center">
                          <img src={loader} alt="" />
                        </td>
                      </tr>
                    ) : (
                      displayRoutedInstalls
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoutedInstallation;
