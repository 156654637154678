/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import helpers from '../../../../../utils/requisition/helpers';

import {
  ACCOUNTS,
  ADMIN,
  ADMIN_DEPARTMENT,
  CUSTOMER_SUPPORT,
  EXPRESS_WIFI,
  NEW_PRODUCTS,
  NOC,
  OPERATIONS,
  PROCUREMENT,
  RESEARCH_AND_DEVELOPMENT,
  SALES,
  TECHNICAL_SUPPORT,
} from '../../../../../utils/departments/helpers';

const CommonRequistionDetails = ({ requisit }) => {
  const department = new Map([
    [`${ACCOUNTS}`, 'Accounts'],
    [`${ADMIN}`, 'ADMIN'],
    [`${ADMIN_DEPARTMENT}`, 'ADMIN_DEPARTMENT'],
    [`${CUSTOMER_SUPPORT}`, 'CUSTOMER SUPPORT'],
    [`${EXPRESS_WIFI}`, 'EXPRESS WIFI'],
    [`${NEW_PRODUCTS}`, 'NEW PRODUCTS'],
    [`${NOC}`, 'NOC'],
    [`${OPERATIONS}`, 'OPERATIONS'],
    [`${PROCUREMENT}`, 'PROCUREMENT'],
    [`${RESEARCH_AND_DEVELOPMENT}`, 'RESEARCH AND DEVELOPMENT'],
    [`${SALES}`, 'SALES'],
    [`${TECHNICAL_SUPPORT}`, 'TECHNICAL SUPPORT'],
  ]);

  const getDepartment = (type) => <strong>{department.get(type)}</strong> || null;

  return (
    <div
      id="requisition"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Requisition Details
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body detail" style={{ padding: '0', textAlign: 'justify' }}>
            <div className="row">
              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label1">
                  <label id="fn">In Favour Of</label>
                  <label id="ln">Purpose</label>
                  <label id="gender">Amount In Digits</label>
                  <label id="country">Country</label>
                  <label id="date">Date Created</label>
                </div>

                <div className="col-lg-8 detail-result1">
                  <p className="mt-4 mb-1">{requisit.in_favour_of}</p>
                  <p className="mb-1">{requisit.purpose}</p>
                  <p className="mb-1">{`${requisit.currency}${requisit.amount_in_digits}`}</p>
                  <p className="mb-1">{helpers.displayCountry(requisit.country)}</p>
                  <p className="mt-2 mb-1">{moment(requisit.created_at).format('DD-MM-YYYY')}</p>
                </div>
              </div>
              <hr />
              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label2">
                  <label id="phone1">Amount In Words </label>
                  <label id="phone2">Department</label>
                  {sessionStorage.getItem('departmentId') === `${ACCOUNTS}` ? (
                    <label id="plan">Budget Code</label>
                  ) : null}
                  <label id="email1">Account Approval</label>
                  <label id="account">Date Approved / Rejected</label>
                </div>

                <div className="col-lg-8 detail-result2">
                  <p className="mt-4 mb-1">{requisit.amount_in_words}</p>
                  <p className="mb-1">{getDepartment(requisit.department_id)}</p>
                  {sessionStorage.getItem('departmentId') === `${ACCOUNTS}` ? (
                    <p className="mb-1">{requisit.budget_code ? requisit.budget_code : 'No Code given'}</p>
                  ) : null}
                  <p className="mb-1" style={{ textTransform: 'capitalize' }}>
                    {requisit.account_approval}
                  </p>
                  <p className="mt-2 mb-1">
                    {requisit.accountApprovalTimeStamp === null
                      ? 'N/A'
                      : moment(requisit.accountApprovalTimeStamp).format('DD-MM-YYYY')}
                  </p>
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label3">
                  <label id="plan">Manager Approval</label>
                  <label id="manager">Date Approved / Rejected</label>
                </div>

                <div className="col-lg-8 detail-result3">
                  <p className="mt-4 mb-1" style={{ textTransform: 'capitalize' }}>
                    {requisit.manager_approval}
                  </p>
                  <p className="mb-1">
                    {requisit.managerApprovalTimeStamp === null
                      ? 'N/A'
                      : moment(requisit.managerApprovalTimeStamp).format('DD-MM-YYYY')}
                  </p>
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label4">
                  <label id="source">Requires Executive Approval</label>
                  <label id="email2">Date Approved / Rejected</label>
                </div>

                <div className="col-lg-8 detail-result2">
                  <p className="mt-4 mb-1">{requisit.requires_c_level_approval === true ? 'Yes' : 'No'}</p>
                  <p className="mt-4 mb-1">
                    {requisit.execApprovalTimeStamp === null
                      ? 'N/A'
                      : moment(requisit.execApprovalTimeStamp).format('DD-MM-YYYY')}
                  </p>
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label1">
                  <label id="address">Requires CEO/COO approval</label>
                  <label id="address">CEO/COO Approval Status</label>
                  <label id="address">Date Approved / Rejected</label>
                </div>

                <div className="col-lg-8 detail-result2">
                  <p className="mt-4 mb-1">{requisit.requires_ceo_or_coo_approval === true ? 'Yes' : 'No'}</p>
                  <p className="mt-4 mb-1" style={{ textTransform: 'capitalize' }}>
                    {requisit.requires_ceo_or_coo_approval === true ? requisit.ceo_or_coo_approval : 'N/A'}
                  </p>
                  <p className="mt-4 mb-1">
                    {requisit.ceoApprovalTimeStamp === null
                      ? 'N/A'
                      : moment(requisit.ceoApprovalTimeStamp).format('DD-MM-YYYY')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CommonRequistionDetails.propTypes = {
  requisit: PropTypes.shape(),
};

CommonRequistionDetails.defaultProps = {
  requisit: {},
};
export default CommonRequistionDetails;
