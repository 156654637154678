/* eslint-disable @typescript-eslint/camelcase */
import { useEffect, useReducer } from 'react';
import axios from 'axios';
import { LOADING_STARTS, LOADING_STOPS, REFETCH } from '../../../../../../../constants/types/shared/LoadingTypes';
import { GET_DATA } from '../../../../../../../constants/types/shared/CustomTypes';
import useForm from '../../../../../../../hooks/shared/custom/form/no-reload/useForm';
import subBasestationReducer from '../reducer/SubBasestationReducer';
import { SUB_BASE_STATION_URL } from '../../../../../../../services/api/shared/BasestationsUrl';

const useSubBasestation = () => {
  const [{ data, loading, pages }, dispatch] = useReducer(subBasestationReducer, {
    data: [],
    loading: false,
    pages: 0,
  });
  const { loadingForm, onChange, formValue, onSubmit, returned } = useForm(
    `${SUB_BASE_STATION_URL}`,
    {
      name: '',
      latitude: '',
      longitude: '',
      address: '',
      installer: '',
      date_of_commissioning: '',
      setup_amount: '',
      no_of_subscribers: '',
      dimension: '',
      base_station_id: '',
    },
    'Sub basestation added',
  );
  useEffect(() => {
    async function getData() {
      dispatch({
        type: LOADING_STARTS,
      });
      try {
        return await axios.get(`${SUB_BASE_STATION_URL}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });
      } catch (e) {
        return e;
      }
    }
    getData()
      .then((response) => {
        dispatch({
          type: GET_DATA,
          payload: response.data,
        });
      })
      .catch(() => dispatch({ type: LOADING_STOPS }));
    if (returned) {
      dispatch({ type: REFETCH, payload: returned });
    }
  }, [returned]);
  return { data, loading, loadingForm, onChange, formValue, onSubmit, pages };
};

export default useSubBasestation;
