/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-multi-assign */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import DragAndDrop from './DragAndDrop';
import { OverviewContext } from '../../../../../context/shared/installations/overview/OverviewContext';
import { LEGACY_GENIATECH_URL } from '../../../../../services/api/shared/installations/InstallationsUrl';
import usePost from '../../../../../hooks/shared/custom/data/geniatech/usePost';
import useTeams from '../team/hooks/useTeams';

const HandleCluster = ({ allInstallations, clusterState }) => {
  const { clustering, clusteredInstalls, clusterInstallation } = useContext(OverviewContext);
  const { teams, getTeams } = useTeams();
  const { loading, postDataWithoutStringify } = usePost();

  const [installs, setInstalls] = useState([]);
  console.log(installs);

  const insertClusters = () => {
    const params = JSON.stringify({
      action: 'insert_clustered_installations',
      clustered_installations: installs,
    });
    postDataWithoutStringify(`${LEGACY_GENIATECH_URL}`, params, 'Sent Successfully!', 'Failed!');
  };

  const id2List = () => {
    const colObjects = {};
    for (let i = 0; i < installs.length; i++) {
      colObjects[`droppable${i}`] = installs[`${i}`];
    }
    return colObjects;
  };

  const getList = (id) => id2List()[id];

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = source.installation;
    const destClone = destination.installation;
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
    return result;
  };

  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination || source.droppableId === destination.droppableId) return;
    move(getList(source.droppableId), getList(destination.droppableId), source, destination);
  };

  const dd = () => {
    return installs.map((cluster, i) => {
      if (cluster.installation.length > 0) {
        return <DragAndDrop cluster={cluster} keys={i} teams={teams} />;
      }
    });
  };

  useEffect(() => {
    const handleCluster = () => {
      const filteredInstalls = allInstallations.filter((cust) => {
        if (cust.select === true) return true;
        return null;
      });
      const data = JSON.stringify({
        state: clusterState,
        installations: filteredInstalls.map((cust) => {
          return [
            cust.lat,
            cust.lng,
            `${cust.address}`,
            `${cust.firstName} ${cust.lastName}`,
            cust.installationId,
            `${cust.status}`,
            cust.team,
            cust.teamId,
            `${cust.deviceType}`,
            `${cust.salesAgent}`,
            `${cust.zoneName}`,
          ];
        }),
      });
      clusterInstallation(data);
    };

    handleCluster();
  }, []);

  useEffect(() => {
    setInstalls(clusteredInstalls);
  }, [clusteredInstalls]);

  useEffect(() => {
    getTeams();
  }, []);

  return (
    <div className="col-12 p-0 after-summary">
      <div className="row mx-2">
        <div className="pt-4 mr-2">
          {installs.length > 0 && (
            <button type="button" className="btn btn-green mr-1" disabled={loading} onClick={() => insertClusters()}>
              {loading ? 'Sending..' : 'Send To Sales'}
            </button>
          )}
        </div>
        <div className="pt-3">
          {clustering ? (
            <div className="pt-5 text-center">
              <img src={loader} alt="" />
              <h4 className="text-danger">clustering...</h4>
            </div>
          ) : dd().length == 0 ? (
            <div className="pt-5 text-center">
              <h3 className="text-danger">No clusters</h3>
            </div>
          ) : (
            <DragDropContext onDragEnd={onDragEnd}>{dd()}</DragDropContext>
          )}
        </div>
      </div>
    </div>
  );
};

export default HandleCluster;
