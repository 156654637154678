import React, { Component } from 'react';
import toastr from 'toastr';
import {API_URL} from '../../../../../utils/config';
import axios from 'axios';
import loader from "../../../../../assets/img/icons/TizetiLoaderColored.gif";

class TotalInventories extends Component {

  constructor(props){
    super(props);

    this.state = {

      inventories: [],
      isLoading: false,
      error: false

    }

  }

  componentWillMount(){

    this.fetchInventories();

  }

  fetchInventories(){

      this.setState({

          isLoading: true

      });

      axios({

          method: 'get',
          url: `${API_URL}/inventory`,
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          }

      }).then(res => {

          this.setState({

              inventories: res.data.length,
              isLoading: false

          });

      }).catch(err => {

        this.setState({

          error: true

        })

      })

  }

  render() {

    const {isLoading, error, inventories} = this.state;

    if(isLoading) {

        return <img src={loader} alt="" />;

    }

    if(error){

      toastr.info("Something went wrong, Please try Again");

    }

    return (
              <div className="count">{inventories} </div>
    )
  }
}

export default TotalInventories;
