import { GET_NEW_STAFF, GET_STAFF, GET_TOTAL_STAFF } from '../../constants/types/administration/StaffTypes';

// eslint-disable-next-line import/prefer-default-export
export const staffReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_NEW_STAFF:
      return {
        ...state,
        newStaff: payload,
      };

    case GET_STAFF:
      return {
        ...state,
        staff: payload,
      };

    case GET_TOTAL_STAFF:
      return {
        ...state,
        total: payload,
      };

    default:
      return state;
  }
};
