import React from 'react';
import { Link } from 'react-router-dom';
import PageName from '../../../../../shared/layouts/PageName';
import Logout from '../../../../../shared/auth/Logout';
import AssetTeamSideBar from '../../../../../../layouts/asset-team/sidebar/AssetTeamSideBar';
import ActiveLteCustomersBody from '../../../../../shared/customers/lte/active/ActiveLteCustomersBody';

const AssetTeamActiveLteCustomers = () => {
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <nav className="d-flex col-lg-12 p-2 top-header">
          <div className="mt-3 d-flex col-lg-12 p-0">
            <Link className="s-sidebar__trigger" to="#">
              <i className="fa fa-bars mr-3" />
            </Link>
            <AssetTeamSideBar />
            <PageName name="Active Lte Customers" />
            <div className="d-flex profile-mail">
              <div>
                <Link
                  to="/customer-support/mail-blast"
                  className="d-none d-md-flex"
                  style={{ color: 'gray', textDecoration: 'none' }}
                >
                  <img src="" alt="" className="logout mb-2 mr-2" />
                  <h6 className="mt-2">
                    <strong>MailBlast</strong>
                  </h6>
                </Link>
              </div>
              <div>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link to="#" data-toggle="modal" data-target="#add-employee">
                  <img alt="" className="logout mr-3" />
                </Link>
              </div>

              <div className="mr-2 d-none d-md-block">{/* <h6 className="small">{position}</h6> */}</div>

              <div className="">
                <Logout />
              </div>
            </div>
          </div>
        </nav>
        <ActiveLteCustomersBody />
      </main>
    </div>
  );
};

export default AssetTeamActiveLteCustomers;
