import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { get_all_basestation_sub_zones } from '../../../../actions/shared/basestations/GetAllBasestationsSubZones';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { create_basestation } from '../../../../actions/shared/basestations/CreateBasestation';
import InputAdornment from '@mui/material/InputAdornment';
export default function CreateBasestation({
  openCreateBasestationDialogue,
  setOpenCreateBasestationDialogue,
  getZones,
}) {
  const [inputValue, setInputValue] = React.useState({});
  const [errorNotifierNOC, setErrorNotifierNOC] = React.useState({});
  const [errorNotifierOperations, setErrorNotifierOperations] = React.useState(true);
  const [getSubZones, setGetSubZones] = React.useState([]);

  const handleClose = () => {
    setOpenCreateBasestationDialogue(false);
    setInputValue({}); //reset the fields on close of the dialogue
  };

  const handleConfirmSubmit = async () => {
    try {
      // console.log('🚀 ~ handleConfirmSubmit ~ inputValue:', inputValue);
      await create_basestation(inputValue);

      // setOpenCreateBasestationDialogue(false);
      setInputValue({});
    } catch (e) {
      console.log(e.message);
    }
  };

  React.useEffect(() => {
    // console.log(inputValue);
    // console.log(errorNotifierNOC);

    if (Object.keys(inputValue).length === 12) {
      // if any of the validation fields throws an error also disable the create btn
      // console.log(Object.values(errorNotifierNOC));
      if (Object.values(errorNotifierNOC).every((val) => val === false)) {
        setErrorNotifierOperations(false);
      }
    }

    if (inputValue.zone_id) {
      async function getAllSubZones() {
        const subZones = await get_all_basestation_sub_zones(inputValue.zone_id);
        // console.log(subZones);
        setGetSubZones(subZones);
      }
      getAllSubZones();
    }

    // check some certain input

    const numbersRegex = /^[0-9]+(\.[0-9]+)?$/;
    if (
      inputValue.setup_amount ||
      inputValue.latitude ||
      inputValue.longitude ||
      inputValue.other_charges ||
      inputValue.distance
    ) {
      //if regex test doesnt comply
      !numbersRegex.test(inputValue.setup_amount) &&
        setErrorNotifierNOC((prev) => ({ ...prev, setupAmountError: true }));
      !numbersRegex.test(inputValue.other_charges) &&
        setErrorNotifierNOC((prev) => ({ ...prev, otherChargesError: true }));
      !numbersRegex.test(inputValue.longitude) && setErrorNotifierNOC((prev) => ({ ...prev, longitudeError: true }));
      !numbersRegex.test(inputValue.latitude) && setErrorNotifierNOC((prev) => ({ ...prev, latitudeError: true }));
      !numbersRegex.test(inputValue.distance) && setErrorNotifierNOC((prev) => ({ ...prev, distanceError: true }));
    }
  }, [inputValue]);

  const formatNumber = (value) => {
    return new Intl.NumberFormat().format(value);
  };

  return (
    <React.Fragment>
      <Dialog
        open={openCreateBasestationDialogue}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Create Base Station'}</DialogTitle>
        {getZones[0] !== undefined && (
          <DialogContent>
            {
              <Box
                component="form"
                sx={{
                  '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
              >
                <div>
                  <TextField
                    id="basestation-name"
                    label="Name"
                    variant="outlined"
                    value={inputValue.name === undefined ? '' : inputValue.name}
                    onChange={(e) => {
                      e.persist();
                      setInputValue((prev) => ({ ...prev, name: e.target.value }));
                      setErrorNotifierNOC((prev) => ({ ...prev, nameError: false }));
                    }}
                    error={errorNotifierNOC.nameError}
                    focused
                  />
                  <TextField
                    id="address"
                    label="Address"
                    variant="outlined"
                    value={inputValue.address === undefined ? '' : inputValue.address}
                    onChange={(e) => {
                      e.persist();
                      setInputValue((prev) => ({ ...prev, address: e.target.value }));
                      setErrorNotifierNOC((prev) => ({ ...prev, addressError: false }));
                    }}
                  />
                  <TextField
                    id="installer"
                    label="Installer"
                    variant="outlined"
                    value={inputValue.installer === undefined ? '' : inputValue.installer}
                    onChange={(e) => {
                      e.persist();
                      setInputValue((prev) => ({ ...prev, installer: e.target.value }));
                      setErrorNotifierNOC((prev) => ({ ...prev, installerError: false }));
                    }}
                  />

                  <TextField
                    id="latitude"
                    label="Latitude"
                    variant="outlined"
                    value={inputValue.latitude === undefined ? '' : inputValue.latitude}
                    helperText={errorNotifierNOC.latitudeError ? 'must contain only numbers' : ''}
                    onChange={(e) => {
                      e.persist();
                      setInputValue((prev) => ({ ...prev, latitude: e.target.value }));
                      setErrorNotifierNOC((prev) => ({ ...prev, latitudeError: false }));
                    }}
                    error={errorNotifierNOC.latitudeError}
                  />
                  <TextField
                    id="longitude"
                    label="Longitude"
                    variant="outlined"
                    value={inputValue.longitude === undefined ? '' : inputValue.longitude}
                    helperText={errorNotifierNOC.longitudeError ? 'must contain only numbers' : ''}
                    onChange={(e) => {
                      e.persist();
                      setInputValue((prev) => ({ ...prev, longitude: e.target.value }));
                      setErrorNotifierNOC((prev) => ({ ...prev, longitudeError: false }));
                    }}
                    error={errorNotifierNOC.longitudeError}
                  />
                  <TextField
                    id="distance"
                    label="Distance"
                    variant="outlined"
                    value={inputValue.distance === undefined ? '' : formatNumber(inputValue.distance)}
                    helperText={errorNotifierNOC.distanceError ? 'must contain only numbers' : ''}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">km</InputAdornment>,
                    }}
                    onChange={(e) => {
                      e.persist();
                      setInputValue((prev) => ({ ...prev, distance: e.target.value.replace(/,/g, '') }));
                      setErrorNotifierNOC((prev) => ({ ...prev, distanceError: false }));
                    }}
                    error={errorNotifierNOC.distanceError}
                  />
                  <TextField
                    id="rent-amount"
                    label="Rent-Amount"
                    variant="outlined"
                    value={inputValue.setup_amount === undefined ? '' : formatNumber(inputValue.setup_amount)}
                    helperText={errorNotifierNOC.setupAmountError ? 'must contain only numbers' : ''}
                    onChange={(e) => {
                      e.persist();
                      setInputValue((prev) => ({ ...prev, setup_amount: e.target.value.replace(/,/g, '') }));
                      setErrorNotifierNOC((prev) => ({ ...prev, setupAmountError: false }));
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">₦</InputAdornment>,
                    }}
                    error={errorNotifierNOC.setupAmountError}
                  />
                  <TextField
                    id="other-charges"
                    label="Other Charges"
                    variant="outlined"
                    value={inputValue.other_charges === undefined ? '' : formatNumber(inputValue.other_charges)}
                    helperText={errorNotifierNOC.otherChargesError ? 'must contain only numbers' : ''}
                    onChange={(e) => {
                      e.persist();
                      setInputValue((prev) => ({ ...prev, other_charges: e.target.value.replace(/,/g, '') }));
                      setErrorNotifierNOC((prev) => ({ ...prev, otherChargesError: false }));
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">₦</InputAdornment>,
                    }}
                    error={errorNotifierNOC.otherChargesError}
                  />

                  <TextField
                    type="date"
                    id="date_of_commissioning"
                    label="Date of Commissioning"
                    variant="outlined"
                    helperText={errorNotifierNOC.dateOfCommissioning ? 'required' : ''}
                    value={inputValue.date_of_commissioning === undefined ? '' : inputValue.date_of_commissioning}
                    onChange={(e) => {
                      e.persist();
                      setInputValue((prev) => ({ ...prev, date_of_commissioning: e.target.value }));
                      setErrorNotifierNOC((prev) => ({ ...prev, dateOfCommissioning: false }));
                    }}
                    error={errorNotifierNOC.dateOfCommissioning}
                    focused
                  />
                  <TextField
                    type="date"
                    id="rent-commencement"
                    label="Rent Commencement"
                    variant="outlined"
                    helperText={errorNotifierNOC.rentCommencementError ? 'required' : ''}
                    value={inputValue.rent_commencement === undefined ? '' : inputValue.rent_commencement}
                    onChange={(e) => {
                      e.persist();
                      setInputValue((prev) => ({ ...prev, rent_commencement: e.target.value }));
                      setErrorNotifierNOC((prev) => ({ ...prev, rentCommencementError: false }));
                    }}
                    error={errorNotifierNOC.rentCommencementError}
                    focused
                  />

                  <TextField
                    type="date"
                    id="rent-expiration"
                    label="Rent Expiration"
                    variant="outlined"
                    value={inputValue.rent_end_date === undefined ? '' : inputValue.rent_end_date}
                    onChange={(e) => {
                      e.persist();
                      setInputValue((prev) => ({ ...prev, rent_end_date: e.target.value }));
                      setErrorNotifierNOC((prev) => ({ ...prev, rentEndDateError: false }));
                    }}
                    error={errorNotifierNOC.rentEndDateError}
                    focused
                  />
                  {
                    <TextField
                      id="outlined-select-zones"
                      select
                      label="Zones"
                      value={inputValue.zone_id === undefined ? '' : inputValue.zone_id}
                      error={errorNotifierOperations.zoneIdError}
                      onChange={(e) => {
                        // e.persist();
                        setInputValue((prev) => ({ ...prev, zone_id: e.target.value }));
                        setErrorNotifierNOC((prev) => ({ ...prev, zoneIdError: false }));
                      }}
                    >
                      {getZones.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  }

                  <TextField
                    id="outlined-select-sub-zones"
                    select
                    label="Sub Zones"
                    value={inputValue.sub_zone_id === undefined ? '' : inputValue.sub_zone_id}
                    error={errorNotifierOperations.subZoneIdError}
                    onChange={(e) => {
                      //   e.persist();
                      setInputValue((prev) => ({ ...prev, sub_zone_id: e.target.value }));
                      setErrorNotifierNOC((prev) => ({ ...prev, subZoneIdError: false }));
                    }}
                    disabled={inputValue.zone_id === undefined ? true : false}
                  >
                    {getSubZones[0] !== undefined ? (
                      getSubZones.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>{''}</MenuItem>
                    )}
                  </TextField>
                </div>
              </Box>
            }
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirmSubmit} autoFocus disabled={errorNotifierOperations}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
