/* eslint-disable @typescript-eslint/camelcase */
import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import CustomInput from '../../../shared/forms/CustomInput';
import CustomButton from '../../../shared/forms/CustomButton';
import AppContext from '../../../../../context/shared/AppContext';
// import Helpers from '../../../../../utils/helpers';

const DeviceConfirmation = ({ show, onClose, customer }) => {
  const { submitDeviceConfirmation, loadingDeviceConfirmation } = useContext(AppContext);
  const displayButton = () => {
    if (loadingDeviceConfirmation) {
      return <CustomButton classname="btn btn-green" name="Confirming" disabled={loadingDeviceConfirmation} />;
    }
    return <CustomButton classname="btn btn-green" name="Confirm Device" disabled={false} />;
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const { mac, name, email, address, phone, comment } = customer;
    const data = {
      mac,
      address,
      customer_name: name,
      customer_email: email,
      phoneNumber: phone,
      comment,
    };
    submitDeviceConfirmation(data);
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Device Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="form-horizontal" onSubmit={onSubmit}>
          <div className="form-group">
            <div className="col-12 mb-3">
              <div className="col-12">
                <CustomInput
                  classname="form-control border border-info py-2"
                  name="mac"
                  value={customer.mac}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  onChange={() => {}}
                  readOnly
                  placeholder="customer macid"
                  type="text"
                />
              </div>
            </div>
            <div className="col-12 mb-3 text-center">{displayButton()}</div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeviceConfirmation;
