/* eslint-disable import/prefer-default-export */
import { useReducer, useState } from 'react';
import qs from 'qs';
import axios from 'axios';
import toastr from 'toastr';
import CleanLTEReducer from '../../../reducers/field-support/clean-imsi/CleanLTEReducer';
import { CLEAN_LTE } from '../../../constants/types/field-support/CleanLTETypes';
import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';

export const useCleanLTE = () => {
  const [{ lists, isLoading }, dispatch] = useReducer(CleanLTEReducer, {
    lists: '',

    isLoading: false,
  });

  const searchLTE = async (data) => {
    dispatch({ type: LOADING_STARTS });
    const config = {
      method: 'post',
      url: `https://api.tizeti.com/api/v1/open5gs/searchbyimsi`,
      data: data,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response.data) {
          // setLists(response.data.data);
          dispatch({
            type: CLEAN_LTE,
            payload: {
              lists: response.data.data,
            },
          });
        }
      })
      .catch(() => {
        toastr.info('Something went wrong!');
        dispatch({ type: LOADING_STOPS });
      });
  };

  const cleanLTE = async (data) => {
    dispatch({ type: LOADING_STARTS });

    const config = {
      method: 'delete',
      url: `https://api.tizeti.com/api/v1/open5gs/`,
      data: data,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response.data) {
          toastr.success('Successful');
          setTimeout(() => {
            window.location.reload();
          }, 1000);

          // setLists(response.data.data);
          // dispatch({
          //   type: CLEAN_LTE,
          // payload: {Successfuo
          //   lists: response.data.data,
          // },
          // });
          console.log('success');
        }
      })
      .catch(() => {
        toastr.info('Something went wrong!');
        dispatch({ type: LOADING_STOPS });
        // setIsLoading(false);
      });
  };

  return {
    lists,
    isLoading,
    cleanLTE,
    searchLTE,
  };
};
