/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-empty */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { admin, API_URL, operations, TECHNICAL_SUPPORT } from '../../../../../utils/config';
import OpsLogo from '../../../../layouts/operations/OpsLogo';
import AddRequestFeature from '../../../shared/features/AddRequestFeature';
import FieldSupportSideBar from '../../../../layouts/field-support/side-bar/FieldSupportSideBar';
import Logout from '../../../shared/auth/Logout';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';

export default class FieldSupportFeature extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
    };
  }

  componentDidMount() {
    this.getUserDetails();
    this.checkDepartment();
  }

  componentWillReceiveProps() {
    this.checkDepartment();
  }

  getUserDetails() {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };

    axios
      .get(`${API_URL}/employee/loggedOnEmployee`, config)
      .then((res) => {
        if (res.data) {
          this.setState({
            firstName: res.data.firstName,
          });
        }
      })
      .catch(() => {});
  }

  checkDepartment = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (
        response.data.department_id !== `${operations}` &&
        response.data.department_id !== `${TECHNICAL_SUPPORT}` &&
        response.data.department_id !== `${admin}`
      ) {
        sessionStorage.setItem('token', '');
        sessionStorage.clear();

        this.props.history.push('/');

        toast.info('You are not allowed to view this page');
      }
    } catch (e) {}
  };

  render() {
    const { firstName } = this.state;

    return (
      <div className="main-wrapper">
        <header className="topbar shade bg-white fixed-top">
          <nav className="navbar navbar-expand-lg navbar-light top-navbar fixed-top">
            {/* <Link className="navbar-brand" to={"#"}> */}
            {/* <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971047/Tizeti-Logo.png" alt="homepage" /> */}
            {/* </Link> */}
            <OpsLogo />
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse mt-3" id="navbarText">
              <ul className="navbar-nav" style={{ marginRight: 'auto' }}>
                <li className="nav-item active">
                  <Link className="nav-link" to="#">
                    <h3>
                      Request a Feature
                      <span className="sr-only">(current)</span>
                    </h3>
                  </Link>
                </li>
              </ul>
              <span className="navbar-text">
                <h4 className="admin">
                  {firstName}
                  <Logout />
                </h4>
              </span>
            </div>
          </nav>
        </header>

        <div className="all-sides col-lg-12 col-md-12 col-sm-12">
          <FieldSupportSideBar />
          <div className="page-wrapper col-lg-10 col-md-10 col-sm-12">
            <AddRequestFeature />
          </div>
        </div>
      </div>
    );
  }
}
