/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import { toast, ToastContainer } from 'react-toastify';
import { API_URL } from '../../../../utils/config';

class EditPosting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      departments: [],
      dept_id: {},
      title: '',
      responsibilities: '',
      qualifications: '',
      deadline: '',
      department_id: '',
      extras: '',
      id: '',
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.getDepartments();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      title: nextProps.one.job_title,
      responsibilities: nextProps.one.responsibilities,
      qualifications: nextProps.one.minimum_qualifications,
      deadline: nextProps.one.deadline,
      department: nextProps.one.department_id,
      extras: nextProps.one.extra,
      id: nextProps.one.id,
    });
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  getDepartments() {
    axios({
      method: 'get',
      url: `${API_URL}/department`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    }).then((res) => {
      this.setState({
        departments: res.data.data,
      });
    });
  }

  editPosting = (e) => {
    e.preventDefault();

    const { title, extras, department, deadline, qualifications, responsibilities } = this.state;

    const data = {
      job_title: title,
      responsibilities,
      minimum_qualifications: qualifications,
      deadline,
      department_id: department,
      extra: extras,
    };

    axios({
      method: 'put',
      url: `${API_URL}/recruitment/listing/${this.state.id}`,
      data,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    }).then(() => {
      toast.success('Posting updated!', {
        onClose() {
          window.location.reload();
        },
      });
    });
  };

  render() {
    const { departments } = this.state;

    return (
      <div
        id="Editposting"
        className="modal fade in"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <ToastContainer />
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center" id="myModalLabel">
                Edit Job Posting
              </h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
            </div>
            <div className="modal-body">
              <form className="form-horizontal" onSubmit={this.editPosting}>
                <div className="form-group">
                  <div className="col-12 d-flex p-0 mb-3">
                    <div className="col-6">
                      <label className="copy-font m-0 p-0">Job Title</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Job Title"
                        name="title"
                        onChange={this.onChange}
                        value={this.state.title}
                      />
                    </div>

                    <div className="col-6">
                      <label className="copy-font m-0 p-0">Department</label>
                      <select className="form-control" onChange={this.onChange} name="department">
                        <option value="">Select Department</option>
                        {departments.map((department) => {
                          return (
                            <option key={department.id} value={department.id}>
                              {department.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="col-12 mb-3">
                    <div>
                      <label className="copy-font m-0 p-0">Responsibilities</label>
                      <br />
                      <textarea
                        rows="5"
                        cols="45"
                        name="responsibilities"
                        value={this.state.responsibilities}
                        onChange={this.onChange}
                        placeholder="Job Responsibilities"
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-12 mb-3">
                    <label className="copy-font m-0 p-0">Minimum Qualifications</label>
                    <br />
                    <textarea
                      rows="5"
                      cols="45"
                      value={this.state.qualifications}
                      name="qualifications"
                      onChange={this.onChange}
                      placeholder="Minimum Qualifications"
                      className="form-control"
                    />
                  </div>

                  <div className="col-12 d-flex p-0 mb-3">
                    <div className="col-6">
                      <label className="copy-font m-0 p-0">Extras</label>
                      <textarea
                        rows="5"
                        cols="45"
                        name="extras"
                        value={this.state.extras}
                        placeholder="Extra Information"
                        onChange={this.onChange}
                        className="form-control"
                      />
                    </div>

                    <div className="col-6">
                      <label className="copy-font m-0 p-0">Application Deadline</label>
                      <input
                        type="date"
                        name="deadline"
                        value={moment(this.state.deadline).format('YYYY-MM-DD')}
                        onChange={this.onChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="text-center mt-5">
                  <button className="btn btn-second" type="button">
                    Edit Posting
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditPosting;
