import React from 'react';
import useSnmpCacti from '../hooks/useSnmpCacti';
import SnmpCactiContext from './SnmpCactiContext';

const SnmpCactiProvider = ({ children }) => {
  const { data, loading, paginateChange, loadingForm, onChange, formValue, onSubmit, pages } = useSnmpCacti();
  return (
    <SnmpCactiContext.Provider
      value={{
        data,
        loading,
        paginateChange,
        loadingForm,
        onChange,
        formValue,
        onSubmit,
        pages,
      }}
    >
      {children}
    </SnmpCactiContext.Provider>
  );
};
export default SnmpCactiProvider;
