/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import view from '../../../../../assets/img/icons/view.png';
import { showDepartmentName } from '../../../../../utils/departments/helpers';
import ViewInvoice from '../../../departments/accounts/purchase-order/departmentsPO/ViewInvoice';
import ViewApprovedPO from './ViewApprovedPO';

const ApprovedPurchaseOrderBodyTable = ({ lists }) => {
  const [order, setOrder] = useState({});
  const getOrder = (item) => setOrder(item);

  const tableData =
    lists.length && lists ? (
      lists.map((list, i) => {
        const { item, quantity, currency, amount, budget_code, department_id } = list;
        return (
          <tr key={i}>
            <td>{i + 1}</td>
            <td className="text-nowrap">{item}</td>
            <td>{quantity}</td>
            <td>{amount ? `${currency} ${amount}` : 'N/A'}</td>
            <td>{budget_code || 'N/A'}</td>
            <td>{showDepartmentName.get(department_id)}</td>
            <td>
              <div className="d-flex text-center">
                <button
                  type="submit"
                  className="mx-2"
                  data-toggle="modal"
                  data-target="#viewInvoice"
                  onClick={() => getOrder(list)}
                >
                  <span className="view1">
                    <i className="fa fa-file-o" data-toggle="tooltip" data-placement="left" title="View Invoice" />
                  </span>
                </button>

                <button
                  type="submit"
                  className="mx-2"
                  data-toggle="modal"
                  data-target="#viewPO"
                  onClick={() => getOrder(list)}
                >
                  <span className="view">
                    <img src={view} alt="" data-toggle="tooltip" data-placement="left" title="View Document" />
                  </span>
                </button>
              </div>
            </td>
            <ViewInvoice order={order} />
            <ViewApprovedPO order={order} />
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="8" className="text-center">
          There are no orders
        </td>
      </tr>
    );

  return tableData;
};

export default ApprovedPurchaseOrderBodyTable;
