/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import { ToastContainer } from 'react-toastify';
import { useUser } from '../../../../hooks/shared/user/useUser';
import { OverviewProvider } from '../../../../context/shared/installations/overview/OverviewContext';
import NewDesignSideBar from '../../../layouts/new-design/NewDesignSideBar';
import NewDesignInstallationZoneBody from './NewDesignInstallationZoneBody';
import { ZonesProvider } from '../../shared/installations/zones/provider/ZonesContext';

const NewDesignInstallationZone = () => {
  const { profile } = useUser();
  return (
    <div className="s-layout">
      <ZonesProvider>
        <main className="s-layout__content col-sm p-0">
          <NewDesignSideBar currentPage="Installation Zones" position={profile.firstName} />
          <div className="container-fluid ">
            <NewDesignInstallationZoneBody />
          </div>
        </main>
      </ZonesProvider>
      <ToastContainer />
    </div>
  );
};

export default NewDesignInstallationZone;
