// /* eslint-disable react/prop-types */
// /* eslint-disable react/destructuring-assignment */
// /* eslint-disable no-unused-vars */
// /* eslint-disable react/no-unused-state */
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Employee from './Employee';
import TizetiLoader from '../../../../assets/img/icons/TizetiLoaderColored.gif';
import View from '../../../../assets/img/icons/view.png';
import Edit from '../../../../assets/img/icons/edit.png';
import Delete from '../../../../assets/img/icons/delete.png';
import CustomButton from '../forms/CustomButton';
import useEmployee from './hook/useEmployee';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import { fetchAllBanksByCountry, getAccountDetails } from '../../../../actions/accounts/GetAllBanks';
import { getDepartmentsAdmin } from '../../../../actions/shared/departments/NewGetDepartmentsActions';
import { update_department_action } from '../../../../actions/shared/departments/updateDepartmentActions';
import toastr from 'toastr';
import { useProfile } from '../../../../hooks/shared/user/profile/useProfile';
import { getDepartments } from '../../../../actions/shared/departments/DepartmentsActions';
import axios from 'axios';
import { EMPLOYEES_BY_DEPARTMENT_URL } from '../../../../services/api/shared/employees/EmployeesUrl';
import { wrap } from 'module';
import { ACCOUNTS, ADMIN_DEPARTMENT, showDepartmentName } from '../../../../utils/departments/helpers';
import { toast } from 'react-toastify';
import { Typography } from '@mui/material';

const tabIndex = -1;
const ConfirmedEmployees = ({ confirmed, isLoading, fetchEmployees }) => {

  const reload = () => {
    fetchEmployees();
};

  const departmentId = sessionStorage.getItem('departmentId');

  const roleData = [
    {
      name: 'PERSONNEL',
      value: '1',
    },
    {
      name: 'LEAD',
      value: '2',
    },
    {
      name: 'MANAGER',
      value: '3',
    },
    {
      name: 'C00',
      value: '4',
    },
    {
      name: 'CEO',
      value: '5',
    },
    {
      name: 'INTERN',
      value: '6',
    },
    {
      name: 'CSO',
      value: '7',
    },
    {
      name: 'CTO',
      value: '8',
    },
    {
      name: 'CFO',
      value: '9',
    },
    {
      name: 'VP',
      value: '11',
    },
    {
      name: 'CIO',
      value: '12',
    },
  ];


  useEffect(() => {
    async function fetchData() {
      try {
        const fetchedDepartments = await getDepartmentsAdmin();
        setDepartments(fetchedDepartments.data); // Set the expired retrieved data in the state
      } catch (error) {
        toastr.error(error.message);
      }
    }

    fetchData();
  }, [getDepartments]);

  const {
    employeeId,
    deactivateConfirmationModal,
    deactivating,
    deletionConfirmationModal,
    openDeletionConfirmation,
    openDeactivateConfirmation,
    getEmployee,
    deactivateUser,
    deleteEmployee,
  } = useEmployee();

  const [employeeToEdit, setEmployeeToEdit] = useState({});
  const [open, setOpen] = useState(false);
  const [editRoleStatus, setEditRoleStatus] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [role, setRole] = useState();
  const [employeeColleague, setEmployeeColleague] = useState([]);
  const [departmentToUpdate, setDepartmentToUpdate] = useState('');
  const { userProfile, onChange, editProfile, handleChange, load } = useProfile();
  const { level, reporting_line } = userProfile;

  const handleClose = () => setOpen(false);
  const switchForm = () => setEditRoleStatus(!editRoleStatus);
  const handleClickOpen = () => setOpen(true);

  const editEmployeeProfile = (employee_id) => {
    const data = { level, reporting_line };
    editProfile(data, employee_id);
  };

  const getEmployeeToEdit = async (employee) => {
    setEmployeeToEdit(employee);
    // added Path variable for dynamic query
    const response = await axios.get(`${EMPLOYEES_BY_DEPARTMENT_URL}/${employee.department_id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    const data = response.data;
    const employeeColleague = [];

    data.map((value) =>
      value.employees.map((employee) => {
        employeeColleague.push(employee.firstName + ' ' + employee.lastName);
      }),
    );
    setEmployeeColleague(employeeColleague);
  };

  const handleUpdate = async () => {
    setOpen(false);

    try {
      if (departmentToUpdate && departmentToUpdate.length > 0 && role && role.length > 0) {
        const initialExpirationState = {
          department_id: departmentToUpdate,
          role_id: role,
          email: employeeToEdit.email,
        };
        await update_department_action(initialExpirationState);
        toastr.info(`user with email ${employeeToEdit.email} updated successfully`);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toastr.error(`Please fill all`);
      }
    } catch (error) {
      toastr.error(error.message);
    }
  };
  const filteredEmails = [
    'adminDept@tizeti.com',
    'admin@tizeti.com',
    'assetDept@tizeti.com',
    'customersupportDept@tizeti.com',
    'nocDept@tizeti.com',
    'express.wifi@tizeti.com',
    'expresswifi@tizeti.com',
    'financeDept@tizeti.com',
    'newproduct@tizeti.com',
    'procurementDept@tizeti.com',
    'nocmanager@tizeti.com',
    'operationsmanage@tizeti.com',
    'adminpersonnel@tizeti.com',
    'researchanddevelopment@tizeti.com',
    'sales.manager@tizeti.com',
    'ghana@tizeti.com',
    'operationsDept@tizeti.com',
    'coo@tizeti.com',
    'partnership@tizeti.com',
    'busDev.manager@tizeti.com',
  ];

  const filteredConfirmedUsers = confirmed.filter((obj) => !filteredEmails.includes(obj.email));
  const handleDepartmentChange = (e) => {
    e.persist();
    setDepartmentToUpdate(e.target.value);
    return e.target.value;
  };

  const [country, setCountry] = React.useState('nigeria');
  const [banksLoading, setBankLoading] = React.useState(true);
  const [banksList, setBanksList] = React.useState([]);
  const [accountPage, setAccountPage] = React.useState(1);

  const handleCountryChange = async (event) => {
    setCountry(event.target.value);
    setAccountDetails(accountDetails, { ...accountDetails, country: event.target.value });
  };

  const fetchAllBanksByCountryAction = async () => {
    try {
      setBankLoading(true);
      const res = await fetchAllBanksByCountry(country);
      if (res) {
        setBanksList(res);
      }
    } catch (error) {
      toast.error('error fetching banks list');
    } finally {
      setBankLoading(false);
    }
  };

  useEffect(() => {
    fetchAllBanksByCountryAction();
  }, [country]);

  //state to hold updating account details
  const [accountDetails, setAccountDetails] = useState({
    account_name: '',
    account_number: '',
    bank_name: '',
    bank_code: '',
    country: '',
  });

  const numberOnlyReg = /^[0-9]+$/;

  const getAccountDetailsAction = async (account_number, bank_code) => {
    setAccountPage(2);
    if (
      (country === 'nigeria' && account_number.length === 10 && numberOnlyReg.test(account_number)) ||
      (country === 'ghana' && account_number.length >= 11)
    ) {
      const res = await getAccountDetails(account_number, bank_code);

      if (res) {
        setAccountDetails({ ...accountDetails, account_name: res.data.account_name });

        setAccountPage(2);
      } else {
        toast.error('error fetching account details');
      }
    }
    // if (country === 'ghana' && account_number.length >= 11) {
    //   const res = await getAccountDetails(account_number, bank_code);
    //   if (res) {
    //     setAccountDetails({ ...accountDetails, account_name: res.data.account_name });
    //     setAccountPage(2);
    //   } else {
    //     toast.error('error fetching account details');
    //   }
    // }
    else if (country === "côte d'Ivoire" && account_number.length >= 28 && accountDetails.account_name.length > 0) {
      setAccountDetails({ ...accountDetails, account_name: accountDetails.account_name });
      setAccountPage(2);
    } else setAccountPage(1);
  };
  const roleId = sessionStorage.getItem('roleId');

  const updateEmployeeDetails = async (employee_id) => {
    //serialize the data to check which of them is not empty

    Object.entries(accountDetails).map(([key, value]) => {
      if (value === '') {
        delete accountDetails[key];
      }
    });
    editProfile(accountDetails, employee_id);
  };

  const tableData = isLoading ? (
    <tr>
      <figure>
        <img src={TizetiLoader} alt="loader" />
      </figure>
    </tr>
  ) : (
    filteredConfirmedUsers.map((employee, index) => (
      <tr key={employee.id}>
        <td>{index + 1}</td>
        <td>{employee.email}</td>
        {employee.date_of_resumption ? (
          <td>{moment(employee.date_of_resumption).format('MM/DD/YYYY')}</td>
        ) : (
          <td>{'N/A'}</td>
        )}
        <td>
          <div className="d-flex">
            <button
              type="submit"
              data-toggle="modal"
              data-placement="right"
              data-target="#customer_detail"
              data-id={employee.id}
              onClick={() => getEmployee(employee)}
              style={{
                border: '0',
                backgroundColor: 'white',
              }}
            >
              <span className="view">
                <img src={View} data-toggle="tooltip" data-placement="left" title="View" alt="view" />
              </span>
            </button>

            <button
              type="submit"
              data-toggle="modal"
              data-target="#"
              style={{
                padding: '2px',
                border: '0',
                backgroundColor: 'white',
              }}
              onClick={() => {
                getEmployeeToEdit(employee);
                handleClickOpen();
              }}
            >
              <span className="view">
                <img
                  src={Edit}
                  data-toggle="tooltip"
                  data-placement="right"
                  alt="Edit Employee"
                  title="Edit Employee"
                />
              </span>
            </button>
            {departmentId === ADMIN_DEPARTMENT || (departmentId === ACCOUNTS && roleId > 2) ? (
              <button
                type="submit"
                data-toggle="modal"
                data-target="#"
                style={{
                  padding: '2px',
                  backgroundColor: 'white',
                  border: '0',
                }}
                onClick={() => {
                  getEmployee(employee);
                  openDeletionConfirmation();
                }}
              >
                <span className="view">
                  <img
                    src={Delete}
                    data-toggle="tooltip"
                    data-placement="right"
                    alt="Deactivate Employee"
                    title="Deactivate Employee"
                  />
                </span>
              </button>
            ) : null}
          </div>
        </td>
      </tr>
    ))
  );
  return (
    <>
      <tbody>{tableData}</tbody>

      <Modal show={deactivateConfirmationModal} onHide={openDeactivateConfirmation} centered>
        <Modal.Header closeButton>
          <Modal.Title style={{ paddingLeft: '2rem' }}>
            Are you sure you want to deactivate {employeeId.firstName} {employeeId.lastName}?
          </Modal.Title>
        </Modal.Header>
        <form className="form-horizontal" onSubmit={deactivateUser}>
          <div className="col-12 mb-3 text-center">
            <CustomButton
              classname="btn btn-green"
              name={deactivating ? 'Deactivating' : 'Deactivate User'}
              disabled={deactivating}
            />
          </div>
        </form>
      </Modal>

      <tr>
        <Employee employee={employeeId} />
      </tr>

      <tr>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div>
            {/* this belongs to admin department only */}
            {Object.entries(employeeToEdit).length > 0 && departmentId === ADMIN_DEPARTMENT ? (
              <div>
                <div style={{ padding: '1rem' }}>
                  <DialogContentText id="alert-dialog-fullname">
                    Name: {employeeToEdit.firstName + employeeToEdit.lastName}
                  </DialogContentText>
                  <DialogContentText id="alert-dialog-email">Email: {employeeToEdit.email}</DialogContentText>
                </div>
                {!editRoleStatus ? (
                  <div style={{ padding: '3%' }}>
                    <DialogTitle id="alert-dialog-title">{'Update Employee Dept.'}</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {departments && departments.length > 0 && (
                          <span style={{ minWidth: '25rem', minHeight: '40vh' }}>
                            <label className="label2 copy-font">Department</label>
                            <select className="form-control" onChange={handleDepartmentChange} name="department">
                              <option>Select Department</option>
                              {departments.map((department) => {
                                return (
                                  <option value={department.id} key={department.id}>
                                    {department.name.toUpperCase()}
                                  </option>
                                );
                              })}
                            </select>
                          </span>
                        )}

                        <span style={{ minWidth: '25rem', minHeight: '40vh' }}>
                          <label className="label2 copy-font">Role</label>
                          <select className="form-control" onChange={(e) => setRole(e.target.value)} name="role">
                            <option>Select Role</option>
                            {roleData.map((department) => {
                              return (
                                <option value={department.value} key={department.value}>
                                  {department.name.toUpperCase()}
                                </option>
                              );
                            })}
                          </select>
                        </span>
                      </DialogContentText>
                    </DialogContent>

                    <DialogActions
                      style={{ marginTop: '1.5rem', gap: '1.5rem', flexWrap: 'wrap' }}
                      className="flex-col md:flex-row"
                    >
                      <button
                        className="btn bg-transparent outline"
                        style={{ textDecoration: 'underline', textUnderlineOffset: '.5rem', flex: '1 45%' }}
                        onClick={switchForm}
                      >
                        Update Status &darr;
                      </button>
                      <button onClick={handleUpdate} className="btn btn-green waves-effect" style={{ flex: '1 45%' }}>
                        Update
                      </button>
                    </DialogActions>
                  </div>
                ) : (
                  <div className="" style={{ padding: '3%' }}>
                    <DialogTitle id="alert-dialog-title">{'Edit Employee Role Status '}</DialogTitle>
                    <DialogContent>
                      <div className="">
                        <label className="label2 copy-font">Level</label>
                        <input
                          type="number"
                          className="form-control "
                          name="level"
                          value={level}
                          onChange={onChange}
                          placeholder="Level"
                        />
                      </div>

                      <div className="">
                        <label className="label2 copy-font">Reporting Line</label>
                        <select
                          className="form-control"
                          defaultValue={reporting_line}
                          onChange={onChange}
                          name="reporting_line"
                        >
                          <option>---Select Reporting Line</option>
                          {employeeColleague.map((colleague, index) => {
                            return (
                              <option value={colleague} key={index}>
                                {colleague}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      <DialogActions
                        style={{ marginTop: '1.5rem', gap: '1.5rem', flexWrap: 'wrap' }}
                        className="flex-col md:flex-row"
                      >
                        <button
                          className="btn bg-transparent outline"
                          style={{ textDecoration: 'underline', textUnderlineOffset: '1rem', flex: '1 45%' }}
                          onClick={switchForm}
                        >
                          Update Department &uarr;
                        </button>
                        <button
                          disabled={load}
                          onClick={() => editEmployeeProfile(employeeToEdit.id)}
                          className="btn btn-green waves-effect"
                          style={{ flex: '1 45%' }}
                        >
                          Edit Role
                        </button>
                      </DialogActions>
                    </DialogContent>
                  </div>
                )}
              </div>
            ) : Object.entries(employeeToEdit).length > 0 && departmentId === ACCOUNTS ? (
              <div>
                <div>
                  <DialogTitle id="alert-dialog-title">Edit Account Details</DialogTitle>

                  {accountPage === 1 && (
                    <div>
                      <div style={{ padding: '1rem' }}>
                        <DialogContentText
                          id="alert-dialog-email"
                          sx={{ borderBottom: '0.2px solid #868484', maxWidth: '22rem' }}
                        >
                          Account Name: {employeeToEdit.account_name === null ? 'N/A' : employeeToEdit.account_name}
                        </DialogContentText>
                        <DialogContentText
                          id="alert-dialog-email"
                          sx={{ borderBottom: '0.2px solid #868484', maxWidth: '22rem', mt: 1 }}
                        >
                          Account Number:{' '}
                          {employeeToEdit.account_number === null ? 'N/A' : employeeToEdit.account_number}
                        </DialogContentText>
                        <DialogContentText
                          id="alert-dialog-email"
                          sx={{ borderBottom: '0.2px solid #868484', maxWidth: '22rem', mt: 1 }}
                        >
                          Bank Name: {employeeToEdit.bank_name === null ? 'N/A' : employeeToEdit.bank_name}
                        </DialogContentText>
                      </div>
                      <DialogContent>
                        <Box
                          component="form"
                          sx={{
                            '& .MuiTextField-root': { m: 1, width: '25ch' },
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <div sx={{ width: '16rem', ml: 1 }}>
                            <FormControl sx={{ width: '16rem', ml: 1 }}>
                              <InputLabel id="demo-simple-select-label">Country</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={country}
                                label="Country"
                                onChange={handleCountryChange}
                              >
                                <MenuItem value={'nigeria'}>Nigeria</MenuItem>
                                <MenuItem value={'ghana'}>Ghana</MenuItem>
                                <MenuItem value={"côte d'Ivoire"}>Côte d'Ivoire</MenuItem>
                              </Select>
                            </FormControl>
                          </div>

                          <div>
                            <div>
                              {banksLoading ? (
                                <div>Loading...</div>
                              ) : (
                                <Autocomplete
                                  disablePortal
                                  id="combo-box-demo"
                                  options={banksList}
                                  getOptionLabel={(option) => option.name} // Set the label value to be option.name
                                  onChange={(e, val) => {
                                    setAccountDetails({ ...accountDetails, bank_code: val.code, bank_name: val.name });
                                  }}
                                  sx={{ width: 250 }}
                                  renderInput={(params) => <TextField {...params} label="Select Bank" />}
                                />
                              )}
                            </div>
                            <div>
                              <TextField
                                id="account-number"
                                label="Account Number"
                                placeholder="099849049"
                                onChange={(e) => {
                                  e.persist();
                                  setAccountDetails({ ...accountDetails, account_number: e.target.value });
                                }}
                                helperText={
                                  country === 'nigeria'
                                    ? 'Account number must be 10 digits'
                                    : country === 'ghana'
                                    ? 'Account number must be 14 digits'
                                    : country === "côte d'Ivoire" && 'Account number must be 28'
                                }
                              />
                            </div>
                            {country === "côte d'Ivoire" && (
                              <TextField
                                id="account-name"
                                label="Account Name"
                                placeholder="Tizeti Limited"
                                onChange={(e) => {
                                  e.persist();
                                  setAccountDetails({ ...accountDetails, account_name: e.target.value });
                                }}
                              />
                            )}
                            <DialogActions style={{ marginTop: '0.5rem' }} className="flex-col md:flex-row">
                              <button
                                onClick={() =>
                                  getAccountDetailsAction(accountDetails.account_number, accountDetails.bank_code)
                                }
                                className="btn btn-green waves-effect"
                                style={{ flex: '1 45%', width: '16.5rem' }}
                                disabled={accountDetails.account_number.length < 10 ? true : false}
                              >
                                Next
                              </button>
                            </DialogActions>
                          </div>
                        </Box>
                      </DialogContent>
                    </div>
                  )}
                  {accountPage === 2 && (
                    <div sx={{ mx: 3 }}>
                      <Typography sx={{ ml: 2 }}>Account Name: {accountDetails.account_name}</Typography>
                      <Typography sx={{ ml: 2 }}>Account Number: {accountDetails.account_number}</Typography>
                      <Typography sx={{ ml: 2 }}>Bank Name: {accountDetails.bank_name}</Typography>

                      <DialogActions style={{ marginTop: '0.5rem' }} className="flex-col md:flex-row">
                        <button
                          onClick={() => setAccountPage(1)}
                          className="btn btn-green waves-effect"
                          style={{ flex: '1 45%' }}
                        >
                          Go Back{' '}
                        </button>
                        <button
                          onClick={() => updateEmployeeDetails(employeeToEdit.id)}
                          className="btn btn-green waves-effect"
                          style={{ flex: '1 45%' }}
                        >
                          Confirm Account Details
                        </button>
                      </DialogActions>
                    </div>
                  )}
                </div>
              </div>
            ) : null}
          </div>
          <button
            onClick={handleClose}
            className="btn btn-red waves-effect"
            style={{ flex: '1 45%', background: 'red' }}
          >
            Close
          </button>
        </Dialog>
      </tr>

      {deleteEmployeeModal(
        deletionConfirmationModal,
        openDeletionConfirmation,
        deleteEmployee,
        deactivating,
        employeeId,
        reload,
      )}
    </>
  );
};



export default ConfirmedEmployees;

const deleteEmployeeModal = (
  
  deletionConfirmationModal,
  openDeletionConfirmation,
  deleteEmployee,
  deactivating,
  employeeId,
  reload,
) => {
  return (
    <Modal show={deletionConfirmationModal} onHide={openDeletionConfirmation} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ paddingLeft: '2rem' }}>
          Are you sure you want to delete employee: {employeeId.firstName} {employeeId.lastName}?
        </Modal.Title>
      </Modal.Header>
      <form className="form-horizontal" onSubmit={(e) => deleteEmployee(e, employeeId.id, reload)}>
        <div className="col-12 mb-3 text-center">
          <CustomButton
            classname="btn btn-green"
            name={deactivating ? 'Deleting...' : 'Delete Employee'}
            disabled={deactivating}
          />
        </div>
      </form>
    </Modal>
  );
};

