/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AddIncidentReport from './AddIncidentReport';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import IncidentReportTable from './IncidentReportTable';
import { getUser } from '../../../../../actions/shared/user/UserActions';
import {
  getIncidentReportByDate,
  getIncidentReports,
  getPaginatedIncidentReport,
} from '../../../../../actions/noc/incident-reports/IncidentReportActions';

class IncidentReportsBody extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalShow: false,
      from: '',
      to: '',
    };

    this.handlePageClick = this.handlePageClick.bind(this);
  }

  componentDidMount() {
    this.props.getIncidentReports();
  }

  getIncidentReportsByDate = (e) => {
    e.preventDefault();
    const { from, to } = this.state;

    const data = {
      from,
      to,
    };

    // eslint-disable-next-line react/destructuring-assignment
    this.props.getIncidentReportByDate(data);
  };

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  getModalStatus(i) {
    this.setState(
      {
        modalShow: !i,
      },
      () => {
        // eslint-disable-next-line react/destructuring-assignment
        if (this.state.modalShow === true) {
          this.setState({
            // eslint-disable-next-line react/destructuring-assignment
            modalShow: !this.state.modalShow,
          });
        }
      },
    );
  }

  handlePageClick = async (incident) => {
    const selected = incident.selected + 1;

    // eslint-disable-next-line react/destructuring-assignment
    this.props.getPaginatedIncidentReport(selected); // cant have two paginations going on #confused.
  };

  render() {
    const { user, load, incidents } = this.props;
    const { reports, pages } = incidents;
    const { firstName } = user;
    const { from, to } = this.state;

    return (
      <div className="container main-wrapper">
        <div className="row mt-5">
          <div className="mt-5 d-flex flex-wrap col-lg-12 col-md-12 col-sm-12">
            <form className="d-flex flex-wrap" onSubmit={this.getIncidentReportsByDate}>
              <div className="form-group d-flex">
                <small className="text-nowrap mr-1">Show from:</small>
                <input type="date" onChange={this.onChange} name="from" value={from} className="form-control" />
              </div>

              <div className="form-group d-flex ml-3 mr-2">
                <small className="mr-1">to:</small>
                <input type="date" onChange={this.onChange} name="to" value={to} className="form-control" />
              </div>

              <div className="mr-5">
                <button className="btn btn-info ml-2" type="button">
                  Search
                </button>
              </div>
            </form>

            <div className="">
              <button
                className="btn btn-green shadow-md"
                type="submit"
                // style={{ width: "15%", height: "70%" }}
                onClick={() => {
                  this.setState(
                    {
                      modalShow: !this.state.modalShow,
                    },
                    () => {},
                  );
                }}
              >
                {' '}
                Add Incident Report
              </button>
            </div>
            {/* </div> */}

            <div className={!this.state.modalShow ? 'nodisp' : ' modal-backdrop fade show'} />
            <div
              id="addIncident"
              /* className="modal fade in" */
              className={this.state.modalShow ? 'modal fade in show d-block' : 'nodisp'}
              /* tabIndex="-1" role="dialog"
              aria-labelledby="myModalLabel"
              aria-hidden="true" */
              data-backdrop="false"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title text-center" id="myModalLabel">
                      Add Incident report
                    </h4>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      onClick={() => {
                        // if(this.state.modalShow === false) {

                        this.setState(
                          {
                            // eslint-disable-next-line react/destructuring-assignment
                            modalShow: !this.state.modalShow,
                          },
                          () => {},
                        );
                      }}
                      aria-hidden="true"
                    >
                      ×
                    </button>
                  </div>
                  // eslint-disable-next-line react/jsx-no-bind
                  <AddIncidentReport modalshow={this.getModalStatus.bind(this)} />
                </div>
              </div>
            </div>
          </div>

          <div className="card x-panel">
            <div className="table-responsive content">
              <table className="table copy-font">
                <thead>
                  <tr>
                    <th>#</th>
                    {/* <th>BaseStation name</th> */}
                    <th>System affected</th>
                    <th>Impact</th>
                    <th>Start</th>
                    <th>End</th>
                    <th>Date</th>
                    {/* <th nowrap="no-wrap">Reported by</th> */}
                    <th>Action</th>
                  </tr>
                </thead>

                {load ? (
                  <tbody>
                    <tr>
                      <td>
                        <img src={loader} alt="loading gif" />
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <IncidentReportTable incident={reports} />
                )}
                {/* <tbody>
                                            {this.displayLogs()}
                                            </tbody> */}
              </table>
            </div>
          </div>
          {pages > 1 ? (
            <ReactPaginate
              previousLabel="Previous"
              nextLabel="Next"
              breakLabel="..."
              breakClassName="break-me"
              pageCount={pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName="pagination"
              subContainerClassName="pages pagination"
              activeClassName="active"
            />
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  incidents: state.incidents.incidents,
  load: state.incidents.load,
});

IncidentReportsBody.propTypes = {
  firstName: PropTypes.string,
  load: PropTypes.bool.isRequired,
  incidents: PropTypes.shape(),
  getIncidentReports: PropTypes.func.isRequired,
  // getIncidentReportsByDate: PropTypes.func,
  user: PropTypes.shape(),
};

export default connect(
  mapStateToProps,
  {
    getUser,
    getIncidentReports,
    getIncidentReportByDate,
    getPaginatedIncidentReport,
  },
)(IncidentReportsBody);
