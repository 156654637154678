/* eslint-disable import/prefer-default-export */
import { GET_ACCESS_POINTS } from '../../../constants/types/noc/NocTypes';
import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';

const intialState = {
  access: [],
};

export const AccessPointsReducer = (state = intialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_ACCESS_POINTS:
      return {
        ...state,
        access: payload,
      };

    case LOADING_STARTS:
      return {
        ...state,
        isLoading: true,
      };

    case LOADING_STOPS:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};
