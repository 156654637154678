import axios from "axios";
import {
  GET_ACCESS_POINTS_BY_IP_URL,
  GET_ACCESS_POINTS_BY_SSID_URL,
  GET_ACCESS_POINTS_URL
} from "../../../services/api/noc/access-points/AccessPointsUrl";
import { GET_ACCESS_POINTS } from "../../../constants/types/noc/NocTypes";
import {toast} from "react-toastify";

export const getAccessPoints = () => async dispatch => {

  try {

    const response = await axios.get(`${GET_ACCESS_POINTS_URL}/1`, {

      headers: {

        'Authorization': `Bearer ${sessionStorage.getItem('token')}`

      }

    });

    if(response.data){

      dispatch({

        type: GET_ACCESS_POINTS,
        payload: {

          access: response.data.aps,
          pageCount: response.data.pages

        }

      })

    }

  } catch (e) {

  }
};

export const getPaginatedAccessPoints = data => async dispatch => {

  try {

    const response = await axios.get(`${GET_ACCESS_POINTS_URL}/${data}`, {

      headers: {

        'Authorization': `Bearer ${sessionStorage.getItem('token')}`

      }

    });

    if(response.data){

      dispatch({

        type: GET_ACCESS_POINTS,
        payload: {

          access: response.data.aps,
          pageCount: response.data.pages

        }

      })

    }

  } catch (e) {

    toast.info("Something went wrong. Please try again!")

  }



};

export const getAccessPointsBySsid = data => async dispatch => {

  try {

    const response = await axios.post(`${GET_ACCESS_POINTS_BY_SSID_URL}`, data, {

      headers: {

        'Authorization': `Bearer ${sessionStorage.getItem('token')}`

      }

    });

    if(response.data){

      dispatch({

        type: GET_ACCESS_POINTS,
        payload: {

          access: response.data.aps,
          pageCount: response.data.pages

        }

      })

    }

  } catch (e) {

  }

};

export const getAccessPointsByIP = data => async dispatch => {

  try {

    const response = await axios.post(`${GET_ACCESS_POINTS_BY_IP_URL}`, data, {

      headers: {

        'Authorization': `Bearer ${sessionStorage.getItem('token')}`

      }

    });

    if(response.data){

      dispatch({

        type: GET_ACCESS_POINTS,
        payload: {

          access: response.data.aps,
          pageCount: response.data.pages

        }

      })

    }

  } catch (e) {

  }

};
