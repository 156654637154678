import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Box from '@mui/material/Box';

const LineCharts = ({ data, isLoading, zones }) => {
  const [difference, setDifference] = React.useState([]);
  React.useEffect(() => {
    if (data.length > 0) {
      const chartData = zones.map((x, index) => ({
        zone: x.name,
        difference: data[index],
      }));
      setDifference(chartData);
    }
  }, [data]);
  return (
    <Box width="100%">
      <Box sx={{ width: { xs: '90vw', md: 1000, lg: '100%' }, overflowX: 'auto' }}>
        <Box sx={{ width: '100%', overflowX: 'scroll', padding: 15 }}>
          {!isLoading && (
            <LineChart
              data={difference}
              margin={{
                top: 5,
                right: 10,
                left: 0,
                bottom: 5,
              }}
              width={4200}
              height={550}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="zone" />
              <YAxis />
              <Tooltip />
              <Legend />

              <Line type="monotone" dataKey="difference" fill="#8884d8" />
            </LineChart>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default LineCharts;
