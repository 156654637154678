import { useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import SUBSCRIBERS_URL from '../../../../../services/api/shared/noc/lte/subscribers/SubscribersUrl';
import * as load from '../../../../../constants/types/shared/LoadingTypes';
import subscriberReducer from '../../../../../reducers/shared/noc/lte/subscribers/subscriberReducer';

const useSubscriber = () => {
  const [{ subscribers, loading }, dispatch] = useReducer(subscriberReducer, {
    subscribers: [],
    loading: false,
  });
  const subscriberState = {
    imsi: '',
    id: '',
  };

  const [subscriber, setSubscriber] = useState(subscriberState);

  const onChange = (e) => {
    const { name, value } = e.target;
    setSubscriber({
      ...subscriber,
      [name]: value,
    });
  };

  const onSubmit = (data) => {
    // console.log(data)
  };

  const getSubscribers = async () => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.get(`${SUBSCRIBERS_URL.GET_SUBSCRIBERS}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        }
      });
      console.log(response.data);
    } catch (e) {
      if (e) {
        dispatch({
          type: load.LOADING_STOPS,
        });
      }
    }
  };

  useEffect(() => {
    getSubscribers();
  }, []);

  return { subscriber, onChange, onSubmit, loading, subscribers };
};

export default useSubscriber;
