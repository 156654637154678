import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useReducer } from 'react';
import departmentReducers from './departmentReducers';
import { LOADING_STARTS, LOADING_STOPS } from '../../../../../constants/types/shared/LoadingTypes';

// toast.configure()

function useDepartment() {
  const [{ isLoading }, dispatch] = useReducer(departmentReducers, {
    isLoading: false,
  });

  const postDepartment = async (data) => {
    dispatch({ type: LOADING_STARTS });
    try {
      const response = await axios.patch(`${'https://api.tizeti.com:8443/api/v1/employee/updateDepartment'}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        console.log(response);
        toast.info('Submitted successfully');
        // window.location.reload();
        dispatch({
          type: LOADING_STOPS,
        });
      } else {
        toast.error('oops');
      }
    } catch (e) {
      toast.info(e.response.data.message);
      if (e.response) {
        dispatch({ type: LOADING_STOPS });
      }
    }
  };

  return {
    postDepartment,
    isLoading,
  };
}

export default useDepartment;
