/* eslint-disable @typescript-eslint/camelcase */
import React, { useContext, useEffect } from 'react';
import { OverviewContext } from '../../../../context/shared/installations/overview/OverviewContext';
import { filename, headers } from '../../../../utils/field-support/installations/helpers';
import CustomCsvDownload from '../../shared/CustomCsvDownload';
import styles from './NewDesign.module.css';

const NewDesignDownload = () => {
  const [downloadInstallation, setDownloadInstallation] = React.useState([]);
  const { installations } = useContext(OverviewContext);

  useEffect(() => {
    const formatData = () => {
      if (installations && installations.length)
        return setDownloadInstallation(
          installations.map((install) => {
            const {
              user,
              payment_reference,
              state,
              status,
              baseStation,
              zone,
              sub_zone,
              firstName,
              lastName,
            } = install;
            return {
              firstName: user ? user.firstName : firstName,
              lastName: user ? user.lastName : lastName,
              payment_reference: payment_reference || 'No payment reference',
              status: status || 'No status',
              sub_zone: sub_zone ? sub_zone.name : 'No sub zone',
              baseStation: baseStation ? baseStation.name : 'No Basestation',
              state: state || 'No state',
              zone: zone || 'No Zone',
            };
          }),
        );
      return setDownloadInstallation;
    };
    formatData();
  }, [installations]);
  return (
    <CustomCsvDownload
      data={downloadInstallation}
      headers={headers}
      filename={filename('Installations')}
      classname={styles.drop_download}
      textcolor="white"
      text="Download"
    />
  );
};

export default NewDesignDownload;
