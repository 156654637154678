import React from 'react';

const ExecutivesTableHead = ({ setCheckAllRequisition }) => {
  return (
    <thead>
      <tr>
        <th>#</th>
        {
          // uncomment for bulk approval or dissaproval
          /* <th>
          <input type="checkbox" value="checkedall" className="my-0" onChange={setCheckAllRequisition} />
        </th> */
        }

        <th>Amount</th>
        <th className="text-nowrap">Amount In Words</th>
        <th className="text-nowrap">In Favor Of</th>
        <th>Purpose</th>
        <th>Account Approval Status </th>
        <th>Manager Approval Status </th>
        <th>C LEVEL Approval Status </th>
        <th>CEO/COO Approval Status </th>
        <th>Requires C LEVEL Approval </th>
        <th>Requires CEO/COO Approval </th>
        <th>Department</th>
        <th className="text-nowrap">Created At</th>
        <th>Approve</th>
        <th>Reject</th>
        <th>View</th>
        <th>Details</th>
      </tr>
    </thead>
  );
};

export default ExecutivesTableHead;
