/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../utils/loader/loadingHelper';
import ReactPaginate from 'react-paginate';

const InstallationSummaryTable = ({ loading, installationList, totalCount, pages, page, setPage, searchCompletedInstallations, params }) => {
  const colSpan = '8';

  const [clickLoading, setLoading] = useState(false)

  const [numbers, setNumbers] = useState(1)

  const completed = {
    status: 'completed',
    from: params.fromDate,
    to: params.toDate,
    zone_id: '',
    paginate: true,
    addZone: 'yes',
  }

  const handlePageClick = async (event) => {
        
       setNumbers(event.selected + 1)
       setLoading(true)
       await searchCompletedInstallations(event.selected + 1, completed)
       setLoading(false)
    
  }

  

  const installsTable = () => {
    if (installationList) {
      return (
        <div className="ml-4 table-responsive col-12">

          <h6 className='my-3'>
            Page: {numbers}
          </h6>

          <table className="table">
            <thead>
              <tr>
                <th>#</th>
          
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Status</th>
                <th>address</th>
                <th style={{whiteSpace: 'nowrap'}}>date</th>
                <th>Zone</th>
              </tr>
            </thead>
            <tbody>
              {loading || clickLoading ? (
                <TizetiGIFLogoTable colSpan={colSpan} />
              ) : installationList.length && Number(totalCount) > 0 ? (
                installationList.map((list, i) => {
                  const { firstName, lastName, email, phoneNumber, address } = list.user;
                  const { name } = list.zone;
    
                  return (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td className="text-nowrap">{firstName + ' ' + lastName}</td>
                      <td>{email}</td>
                      <td>{phoneNumber}</td>
                      <td>
                        <span
                          className={`text-uppercase w-100 badge badge-${
                            list.status === 'completed' ? 'success' : 'danger'
                          }`}
                        >
                          {list.status}
                        </span>
                      </td>
                      <td>{address}</td>
                      <td style={{whiteSpace: 'nowrap'}}>{list.date || 'N/A'}</td>
                      <td className="pr-5">{name}</td>
                    </tr>
                  );
                })
              ) : (
                <NoItemRow
                  colSpan={colSpan}
                  text={Number(totalCount) === 0 ? 'Zero Installations' : 'No Installations'}
                />
              )}
            </tbody>
          </table>
          {pages > 1 ? (
          <ReactPaginate
            previousLabel="previous"
            nextLabel="next"
            breakLabel="..."
            breakClassName="break-me"
            pageCount={pages}
            marginPagesDisplayed={3}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            subContainerClassName="pages pagination"
            activeClassName="active"
            role="button"
          />
        ) : null}
        </div>
      );
    }
    return <TizetiGIFLogoTable colSpan={colSpan} />;
  };

  return installsTable();
};

export default InstallationSummaryTable;
