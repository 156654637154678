/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import toastr from 'toastr';
import PropTypes from 'prop-types';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import { admin, NOC } from '../../../../../utils/config';
import { getUser } from '../../../../../actions/shared/user/UserActions';
import { getLoggedInUser } from '../../../../../actions/user/UserActions';
import Requisitions from '../../../shared/requisition/shared/Requisitions';
import NocNavBar from '../../../../layouts/noc/NocNavBar';

class NetworkOperationsRequisition extends Component {
  static propTypes = {
    filterRequisitions: PropTypes.func,
    requisitions: PropTypes.object,
    load: PropTypes.bool,
  };

  componentDidMount() {
    this.checkDepartment();
    this.props.getLoggedInUser();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.checkDepartment();
  }

  checkDepartment = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.department_id !== `${NOC}` && response.data.department_id !== `${admin}`) {
        this.props.history.push('/');
        sessionStorage.setItem('token', '');
        sessionStorage.setItem('email', '');
        sessionStorage.clear();

        toastr.info('You are not allowed to view this page');
      }
    } catch (e) {
      //   console.log(e.response);
    }
  };

  searchRequisitions = (e) => {
    const keyword = e.target.value;

    if (keyword !== '') {
      const list = this.state.requisitions.filter((requisition) => {
        if (requisition.name.toLowerCase().indexOf(keyword) !== -1) {
          return requisition;
        }
      });

      this.setState({
        filtered: list,
        keyword: e.target.value,
      });
    } else {
      this.setState({
        filtered: this.state.requisitions,
        keyword,
      });
    }
  };

  render() {
    const { user } = this.props;
    const { firstName } = user;
    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <NocNavBar currentPage="Requisition" position={firstName} />
          <Requisitions />
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  role: state.auth.auth,
});

export default connect(mapStateToProps, {
  getUser,
  getLoggedInUser,
})(NetworkOperationsRequisition);
