/* eslint-disable import/prefer-default-export */
import { useReducer, useEffect } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { API_URL } from '../../../utils/config';
import { staffReducer } from '../../../reducers/administration/staffReducer';
import { GET_STAFF, GET_NEW_STAFF, GET_TOTAL_STAFF } from '../../../constants/types/administration/StaffTypes';

export const useStaff = () => {
  const [{ newStaff, staff, total }, dispatch] = useReducer(staffReducer, {
    newStaff: [],
    staff: [],
    total: [],
  });
  const getNewStaff = async () => {
    try {
      const response = await axios.get(`${API_URL}/employeeReg`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        }
      });
      dispatch({
        type: GET_NEW_STAFF,
        payload: response.data,
      });
    } catch (e) {
      toastr.info('Unable to get new staff');
    }
  };

  const getStaff = async () => {
    try {
      const response = await axios.get(`${API_URL}/employee`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        }
      });
      dispatch({
        type: GET_STAFF,
        payload: response.data,
      });
    } catch (e) {
      toastr.info('Unable to get staff ');
    }
  };

  const getTotalStaff = async () => {
    try {
      const response = await Promise.all([axios.get(`${API_URL}/employee`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        }
      }), axios.get(`${API_URL}/employeeReg`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        }
      })]);

      if (response[1].data && response[0].data) {
        dispatch({
          type: GET_TOTAL_STAFF,
          payload: response[1].data.length + response[0].data.length,
        });
      } else {
        toastr.info('Unable to get the data');
      }
    } catch (error) {
      toastr.info('Something went wrong!');
    }
  };

  useEffect(() => {
    getNewStaff();
    getStaff();
    getTotalStaff();
  }, []);

  return { newStaff, staff, total };
};
