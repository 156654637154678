import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import LineCharts from './LineCharts';
import BarCharts from './BarCharts';
import AreaCharts from './AreaCharts';

const ChangeInExpiredContainer = ({ data, zones, isLoading }) => {
  const [chartType, setChartType] = React.useState('Bar-Chart');
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flexStart" sx={{ mt: 10 }}>
      {isLoading ? (
        <>
          <Skeleton width={300} height={100} />
        </>
      ) : (
        <TargetChartTypeSelect setChartType={setChartType} chartType={chartType} />
      )}

      {chartType === 'Line-Chart' && <LineCharts data={data} isLoading={isLoading} zones={zones} />}
      {chartType === 'Bar-Chart' && <BarCharts data={data} isLoading={isLoading} zones={zones} />}
      {chartType === 'Area-Chart' && <AreaCharts data={data} isLoading={isLoading} zones={zones} />}
    </Box>
  );
};

export default ChangeInExpiredContainer;

function TargetChartTypeSelect({ setChartType, chartType }) {
  const chartOptions = ['Line-Chart', 'Area-Chart', 'Bar-Chart'];

  const handleChartTypeSelected = (e) => {
    setChartType(e.target.value);
  };

  return (
    <div className="m-3">
      <div className="relative w-40">
        <select
          className="block w-full px-4 py-2 border rounded appearance-none focus:border-blue-500 focus:outline-none"
          value={chartType}
          onChange={handleChartTypeSelected}
        >
          <option value="" disabled>
            Select Chart Type
          </option>
          {chartOptions.map((chart) => (
            <option key={chart} value={chart}>
              {chart}
            </option>
          ))}
        </select>
        <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none"></div>
      </div>
      {chartType && <div className="my-2">Selected Chart: {chartType}</div>}
    </div>
  );
}
