import React from 'react';
import { ToastContainer } from 'react-toastify';
// import view from '../../../../../assets/img/icons/view.png';
// import edit from '../../../../../assets/img/icons/edit.png';
// import RequestDetails from '../../../shared/super-admin-requests/RequestDetails';
// import EditRequest from './EditRequest';
import CreateCategory from './CreateCategory';
import MakeRequest from './MakeRequest';
import { useAdminRequests } from '../../../../../hooks/administration/requests/useAdminRequests';
import RequestsTableHead from './RequestsTableHead';
import { useRequests } from '../../../../../hooks/shared/requests/useRequests';
import { RequestProvider } from '../../../../../context/shared/requests/admin/RequestContext';
import RequestsTableBody from './RequestsTableBody';
import { useCategory } from '../../../../../hooks/administration/requests/category/useCategory';

const Requests = () => {
  const {
    isLoading,
    requests,
    makeRequest,
    onChange,
    request,
    isLoadingRequest,
    filterStatus,
    onFilterChange,
    users,
    onUpdateChange,
    updateStatus,
    update,
    isUpdatingStatus,
    show,
    getToShow,
  } = useAdminRequests();
  const { categories } = useRequests();
  const { category, onFormChange, createCategory, isLoadingCategory } = useCategory();

  return (
    <div className="col-12 after-summary">
      <RequestProvider
        value={{
          isLoading,
          requests,
          makeRequest,
          onChange,
          request,
          isLoadingRequest,
          categories,
          users,
          onUpdateChange,
          updateStatus,
          update,
          isUpdatingStatus,
          category,
          onFormChange,
          createCategory,
          isLoadingCategory,
          show,
          getToShow,
        }}
      >
        <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-3">
          <div className="text-right">
            <button
              className="btn btn-green mb-2 mr-3"
              type="submit"
              style={{ cursor: 'pointer' }}
              data-toggle="modal"
              data-target="#makeRequest"
            >
              Make Request
            </button>

            <button
              className="btn btn-green mb-2"
              type="submit"
              style={{ cursor: 'pointer' }}
              data-toggle="modal"
              data-target="#createCategory"
            >
              Create Category
            </button>
            <CreateCategory />
            <MakeRequest />
          </div>
          <div className="d-none d-md-flex">
            <form onSubmit={filterStatus} className="form-inline d-flex inner-addon left-addon col-md-12">
              {/* <input */}
              {/*  required */}
              {/*  className="form-control mr-auto empty mt-1 col-md-5" */}
              {/*  id="iconified1" */}
              {/*  type="text" */}
              {/*  placeholder="Search By Last Name, Email and Phone number" */}
              {/* /> */}

              {/* <div className="copy-font d-flex mr-auto"> */}
              {/*  <small>Category:</small> */}
              {/*  <span> */}
              {/*    <select className="form-control" name="status" required> */}
              {/*      <option value="">Select Category</option> */}
              {/*      <option value="furniture">Furniture</option> */}
              {/*      <option value="vehicle">Vehicle</option> */}
              {/*      <option value="Stationery">Stationery</option> */}
              {/*    </select> */}
              {/*  </span> */}
              {/* </div> */}

              <div className="copy-font d-flex mr-3">
                <small>Filter By:</small>
                <span>
                  <select className="form-control" name="option" onChange={onFilterChange} required>
                    <option value="">Select Status</option>
                    <option value="rejected">Rejected</option>
                    <option value="pending">Pending</option>
                    <option value="approved">Resolved</option>
                  </select>
                </span>
              </div>

              <button className="btn btn-green" type="submit">
                Search
              </button>
            </form>
          </div>
        </div>
        <div className="card x-panel logs">
          <div className="table-responsive content">
            <table className="table copy-font">
              <RequestsTableHead />
              <tbody>{isLoading ? <small>loading...</small> : <RequestsTableBody />}</tbody>
            </table>
          </div>
        </div>
      </RequestProvider>
      <ToastContainer />
    </div>
  );
};

export default Requests;
