/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import usePost from '../../../../hooks/shared/custom/data/geniatech/usePost';
import { GET_ROUTER_URL } from '../../../../services/api/shared/BasestationsUrl';
import CustomCsvDownload from '../CustomCsvDownload';
import { filename, assetTeamHeaders } from '../../../../utils/field-support/installations/helpers';
import AssetTeamTable from '../asset-team/AssetTeamTable';

const InstalledCountryBody = ({ profile }) => {
  const { data, filtered, loading, getData, getDataLegacy, filterData } = usePost();

  const searchState = {
    fromDate: `${new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10)}`,
    toDate: `${new Date(new Date().getTime() - 0 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10)}`,
    country: 'Nigeria',
    filterBy: 'none',
    filterValue: '',
    status: 'expired',
    currentPage: 1,
    recordsPerPage: 10,
  };
  const [search, setSearch] = useState(searchState);
  const { fromDate, toDate, country, filterBy, filterValue, status, recordsPerPage, currentPage } = search;

  const params = {
    action: 'tizeti_asset_install_users_list_range_paginated',
    country,
    from: fromDate,
    to: toDate,
    filterby: filterBy,
    filtervalue: filterValue,
    subscription_status: status,
    no_of_records_per_page: recordsPerPage,
    pageno: currentPage,
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'filterBy') {
      const filterParams = {
        action: 'tizeti_asset_filter_values',
        country,
        filterby: value,
      };
      filterData(`${GET_ROUTER_URL}`, filterParams);
    }
    setSearch({ ...search, [name]: value });
  };

  const onSearchByCountry = (e) => {
    e.preventDefault();
    getDataLegacy(`${GET_ROUTER_URL}`, params);
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    const pageParams = {
      action: 'tizeti_asset_install_users_list_range_paginated',
      country,
      from: fromDate,
      to: toDate,
      filterby: filterBy,
      filtervalue: filterValue,
      subscription_status: status,
      no_of_records_per_page: recordsPerPage,
      pageno: selectedPage,
    };
    getDataLegacy(`${GET_ROUTER_URL}`, pageParams);
  };

  useEffect(() => {
    getDataLegacy(`${GET_ROUTER_URL}`, params);
  }, []);

  return (
    <div className="mt-5">
      <div className="container pt-5">
        <div className="row">
          <div className="p-0">
            <div className="col-12 d-flex">
              <form onSubmit={onSearchByCountry} className="form-inline ml-3">
                <div className="form-group">
                  <select defaultValue={country} name="country" onChange={handleChange} className="form-control">
                    <option value="">--Choose Country--</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="Ghana">Ghana</option>
                  </select>
                </div>
                <div className="form-group mx-2">
                  <label className="mt-2 p-0 copy-font">From: </label>
                  <input
                    type="date"
                    className="form-control mr-2"
                    name="fromDate"
                    value={fromDate}
                    onChange={handleChange}
                  />
                  <label className="mt-2 p-0 copy-font">To: </label>
                  <input type="date" className="form-control" name="toDate" value={toDate} onChange={handleChange} />
                </div>
                <div className="form-group">
                  <select defaultValue={filterBy} name="filterBy" onChange={handleChange} className="form-control">
                    <option value="">--Filter By--</option>
                    <option value="agent">Agent</option>
                    <option value="basestation">Basestation</option>
                    <option value="zone">Zone</option>
                    <option value="none">All</option>
                  </select>
                </div>
                {filterBy !== 'none' && (
                  <div className="form-group">
                    <select
                      defaultValue={filterValue}
                      name="filterValue"
                      onChange={handleChange}
                      className="form-control"
                    >
                      <option value="">--Value--</option>
                      {filtered.items && filtered.items.length ? (
                        filtered.items.map((filter) => (
                          <option key={filter} value={filter}>
                            {filter.toUpperCase()}
                          </option>
                        ))
                      ) : (
                        <option>Loading...</option>
                      )}
                    </select>
                  </div>
                )}
                <div className="form-group">
                  <select defaultValue={status} name="status" onChange={handleChange} className="form-control">
                    <option>--Status--</option>
                    <option value="expired">EXPIRED</option>
                    <option value="active">ACTIVE</option>
                    <option value="none">ALL</option>
                  </select>
                </div>
                <div className="form-group mr-3">
                  <input
                    type="number"
                    min="1"
                    placeholder="Per Page"
                    className="form-control"
                    name="recordsPerPage"
                    value={recordsPerPage}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group mx-2">
                  <button className="btn btn-green" type="submit">
                    Search
                  </button>
                </div>
              </form>
            </div>
            {data.items && data.items.length ? (
              <div className="col-12 d-flex mt-2">
                <CustomCsvDownload
                  data={data.items}
                  headers={assetTeamHeaders}
                  filename={filename(`Installed ${country}`)}
                  classname="btn btn-green shadow-md"
                  textcolor="white"
                  text="Download Data"
                />
                <div className="pt-2 mx-3">
                  Count:
                  <span className="badge badge-secondary ml-1">{data.total_records}</span>
                </div>
              </div>
            ) : null}
          </div>
          <AssetTeamTable profile={profile} items={data.items} load={loading} />
        </div>
        {data.total_pages > 1 ? (
          <ReactPaginate
            previousLabel="previous"
            nextLabel="next"
            breakLabel="..."
            breakClassName="break-me"
            pageCount={data.total_pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            activeClassName="active"
          />
        ) : null}
      </div>
    </div>
  );
};

export default InstalledCountryBody;
