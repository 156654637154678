/* eslint-disable react/no-array-index-key */
import React, { useContext } from 'react';
import loader from '../../../../../../../../assets/img/icons/TizetiLoaderColored.gif';
import helpers from '../../../../../../../../utils/helpers';
import AppContext from '../../../../../../../../context/shared/AppContext';

const SearchDevicesTable = () => {
  const { loading, fetched } = useContext(AppContext);
  const tableData =
    fetched && fetched.length
      ? fetched.map((row, i) => (
          <tr className="text-nowrap" style={{ cursor: 'pointer' }} key={i + 1}>
            <td>{i + 1}</td>
            <td>{row.name}</td>
            <td>{row.mac}</td>
            <td>{row.config.sync_reason}</td>
            <td>{row.config.sync_status}</td>
            <td>{row.config.variables.VLAN_1_MODE}</td>
            <td>{row.config.variables.VLAN_1_IP}</td>
            <td>{row.config.variables.VLAN_1_MASK}</td>
            <td>{row.config.variables.VLAN_4_MODE}</td>
            <td>{row.config.variables.DISPLAY_NAME}</td>
            <td>{row.config.variables.DNS_SERVER_2}</td>
            <td>{row.config.variables.DNS_SERVER_1}</td>
            <td>{row.config.variables.DEFAULT_GW}</td>
            <td>{row.config.variables.VLAN_4_IP}</td>
            <td>{row.config.variables.VLAN_4_MASK}</td>
            <td>{row.config.variables.vlan_id}</td>
            {/* <td>{row.config.variables.Guest-Access Redirect User-Page}</td> */}
            <td>{row.config.variables.POWER_5}</td>
            <td>{row.config.variables.POWER_24}</td>
            <td>{row.config.variables.CHANNEL_5}</td>
            <td>{row.config.variables.CHANNEL_24}</td>
            <td>{row.config.variables.POWER_5}</td>
            <td>{row.config.version}</td>
            <td>{row.country}</td>
            <td>{row.description}</td>
            <td>{row.hardware_version}</td>
            <td>{row.ip}</td>
            <td>{row.location.coordinates}</td>
            <td>{row.location.type}</td>
            <td>{row.managed_account}</td>
            <td>{row.msn}</td>
            <td>{row.network}</td>
            <td>{row.product}</td>
            <td>{row.software_version}</td>
            <td>{row.inactive_software_version}</td>
            <td>{helpers.formatDateAndTime(row.registration_date)}</td>
            <td>{row.site}</td>
            <td>{row.status}</td>
            <td>{row.status_time}</td>
            <td>{row.last_reboot_reason}</td>
            <td>{row.type}</td>
          </tr>
        ))
      : 'no device';
  return (
    <div className="card x-panel mt-2 px-0">
      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>name</th>
              <th>mac</th>
              <th>Config Sync Reason</th>
              <th>Config Sync Status</th>
              <th>Config.variables.VLAN_1_MODE</th>
              <th>Config.variables.VLAN_1_IP</th>
              <th>Config.variables.VLAN_1_MASK</th>

              <th>Config.variables.VLAN_4_MODE</th>
              <th>Config.variables.DISPLAY_NAME</th>
              <th>Config.variables.DNS_SERVER_2</th>
              <th>Config.variables.DNS_SERVER_1</th>
              <th>Config.variables.DEFAULT_GW</th>
              <th>Config.variables.VLAN_4_IP</th>
              <th>Config.variables.VLAN_4_MASK</th>
              <th>Config.variables.vlan_id</th>
              {/* <th>Config.variables.Guest-Access Redirect User-Page</th> */}

              <th>Config.variables.POWER_5</th>
              <th>Config.variables.POWER_24</th>
              <th>Config.variables.CHANNEL_5</th>
              <th>Config.variables.CHANNEL_24</th>
              <th>Config.variables.POWER_5</th>
              <th>Config Version</th>
              <th>Country</th>
              <th>Description</th>
              <th>Hardware Version</th>
              <th>Ip</th>
              <th>location.coordinates</th>
              <th>location.type</th>

              <th>managed_account</th>
              <th>msn</th>
              <th>network</th>
              <th>product</th>
              <th>Software Vrsion</th>
              <th>inactive_software_version</th>
              <th>registration_date</th>
              <th>Site</th>
              <th>status</th>
              <th>status_time</th>
              <th>last_reboot_reason</th>
              <th>Type</th>
            </tr>
          </thead>

          <tbody>
            {loading ? (
              <tr>
                <img src={loader} alt="loading gif" />
              </tr>
            ) : (
              tableData
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SearchDevicesTable;
