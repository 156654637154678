export const GET_CATEGORIES_FOR_INSTALLATIONS = "GET_CATEGORIES_FOR_INSTALLATIONS";
export const GET_ZONES = "GET_ZONES";
export const GET_SUB_ZONES = "GET_SUB_ZONES";
export const GET_AVAILABLE_TIMES = "GET_AVAILABLE_TIMES";
export const SCHEDULE_STARTS = "SCHEDULE_STARTS";
export const SCHEDULE_STOPS = "SCHEDULE_STOPS";
export const START_LOADING_SUBZONES = "START_LOADING_SUBZONES";
export const STOP_LOADING_SUBZONES = "STOP_LOADING_SUBZONES";
export const STOP_LOADING_TIME = "STOP_LOADING_TIME";
export const START_LOADING_TIME = "START_LOADING_TIME";
export const SCHEDULE_SUCCESS = "SCHEDULE_SUCCESS";
export const START_SCHEDULING = "START_SCHEDULING";
export const STOP_SCHEDULING = "STOP_SCHEDULING";

