/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect } from 'react';
import qs from 'qs';
import useBasestationStatus from '../../../../../hooks/noc/basestation-status/useBasestationStatus';
import BusinessDevelopmentBasestationStatusTable from './BusinessDevelopmentBasestationStatusTable';

const BusinessDevelopmentBasestationStatusBody = () => {
  const { getBasestationList, items, isLoading } = useBasestationStatus();

  const data = qs.stringify({
    action: 'list_of_basestation_dedicated',
  }); 

  useEffect(() => {
    getBasestationList(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mt-5 p-3">
      <div className="container-fluid pt-4">
        <div className="row">
          <BusinessDevelopmentBasestationStatusTable items={items} isLoading={isLoading} />
        </div>
      </div>
    </div>
  );
};

export default BusinessDevelopmentBasestationStatusBody;
