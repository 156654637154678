import React, { useContext } from 'react';
import CustomPagination from '../../../../shared/CustomPagination';
import { HotspotServerContext } from './context/HotspotServerContext';

const PaginatePage = () => {
  const { pages, paginateChange } = useContext(HotspotServerContext);
  const pageChange = (params) => {
    const { selected } = params;
    paginateChange(selected + 1);
  };

  return (
    <CustomPagination pagesCount={pages} marginPagesDisplayed={5} pageRangeDisplayed={5} onPageChange={pageChange} />
  );
};

export default PaginatePage;
