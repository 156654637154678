// /* eslint-disable no-unused-vars */
// /* eslint-disable react/destructuring-assignment */
// import React, { Component } from 'react';
// import '../../../../styles/style.css';
// import '../../../../styles/basestation.css';
// import axios from 'axios';
// import { API_URL } from '../../../../utils/config';
// import NocNavBar from '../../../layouts/noc/NocNavBar';
// import LatencyGraphBody from './latency-graph/LatencyGraphBody';
//
// class LatencyGraph extends Component {
//
//
//   render() {
//     return (
//     );
//   }
// }
//
// export default LatencyGraph;

import React from 'react';
import NocNavBar from '../../../layouts/noc/NocNavBar';
import LatencyGraphBody from './latency-graph/LatencyGraphBody';
import { useUser } from '../../../../hooks/shared/user/useUser';

const LatencyGraph = () => {
  const { profile } = useUser();
  const { firstName } = profile;
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <NocNavBar currentPage="Latency Graph" position={firstName} />
        <LatencyGraphBody />
      </main>
    </div>
  );
};

export default LatencyGraph;
