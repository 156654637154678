/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
import React, { Fragment, useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import PropTypes from 'prop-types';
import qs from 'qs';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';

// eslint-disable-next-line no-shadow
const StaticIP = ({ session, staticId, load, editIp, details, loading, addIp, adding, macid, deleteStaticIp }) => {
  const { staticIpaddress } = session;

  const [ip, setIp] = useState(staticId);
  const [add, setAdd] = useState('');
  useEffect(() => {
    setIp(staticId);
  }, [staticId]);

  const handleChange = (event) => {
    const { value } = event.target;
    setIp(value);
  };

  const onAdd = (e) => {
    const { value } = e.target;
    setAdd(value);
  };

  const deleteIP = () => {
    // eslint-disable-next-line react/destructuring-assignment
    const data = qs.stringify({
      action: 'deleteIPAddress',
      mac: macid,
    });
    // eslint-disable-next-line react/destructuring-assignment
    deleteStaticIp(data);
  };

  const submitForm = () => {
    return ip !== undefined;
  };

  const addForm = () => {
    return add.length > 0;
  };

  return (
    <div
      id="static"
      className="modal bd-example-modal-sm"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="mySmallModalLabel"
      aria-hidden="true"
    >
      {/* <ToastContainer/> */}
      <div className="modal-dialog modal-sm mt-5 w-75">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title pl-5 ml-5" id="exampleModalLabel">
              Static IP
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          {load ? (
            <img src={loader} alt="loading" />
          ) : (
            <Fragment>
              <div className="modal-body">
                {staticIpaddress ? (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      editIp(ip, details, macid);
                    }}
                  >
                    <div className="form-group">
                      <label htmlFor="recipient-name" className="col-form-label copy-font m-0 p-0 text-justify">
                        Static IP
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={handleChange}
                        value={ip}
                        name="ip"
                        id="static-ip"
                      />
                      {loading ? (
                        <div className="text-center">
                          <button className="btn btn-second" disabled={loading}>
                            Updating...
                          </button>
                        </div>
                      ) : (
                        <div className="text-center">
                          <button className="btn btn-second" disabled={!submitForm()}>
                            Update
                          </button>
                        </div>
                      )}
                    </div>
                  </form>
                ) : (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      addIp(add, details, macid);
                    }}
                  >
                    <div className="form-group">
                      <label htmlFor="recipient-name" className="col-form-label copy-font m-0 p-0 text-justify">
                        {' '}
                        Static IP
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={onAdd}
                        name="add"
                        value={add}
                        id="static-ip"
                        placeholder="add static ip address"
                      />
                      {adding ? (
                        <div className="text-center">
                          <button className="btn btn-second" disabled={adding}>
                            Adding...
                          </button>
                        </div>
                      ) : (
                        <div className="text-center">
                          <button className="btn btn-second" disabled={!addForm()}>
                            Add
                          </button>
                        </div>
                      )}
                    </div>
                  </form>
                )}
              </div>

              {staticIpaddress ? (
                <button className="col-form-label copy-font m-0 p-0 text-justify" onClick={() => deleteIP()}>
                  Delete Static Ip Address
                </button>
              ) : null}
            </Fragment>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

StaticIP.propTypes = {
  staticIpaddress: PropTypes.string,
  editIp: PropTypes.func,
  load: PropTypes.bool,
  session: PropTypes.shape(),
  staticId: PropTypes.string,
  details: PropTypes.shape(),
  loading: PropTypes.bool,
  addIp: PropTypes.func,
  adding: PropTypes.bool,
  macid: PropTypes.string,
  deleteStaticIp: PropTypes.func,
};
export default StaticIP;
