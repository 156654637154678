/* eslint-disable import/prefer-default-export */
import { useReducer } from 'react';
import qs from 'qs';
import axios from 'axios';
import toastr from 'toastr';
import AmbassadorsReducer from '../../reducers/express-wifi/ambassadors/AmbassadorsReducer';
import { GET_AMBASSADORS_URL } from '../../services/api/express-wifi/ambassadors/AmbassadorsUrl';
import { LOADING_STOPS } from '../../constants/types/shared/LoadingTypes';

export const useUpdateAmbassadorsStatus = () => {
  const [{ items, count, pages, loading }, dispatch] = useReducer(AmbassadorsReducer, {
    items: [],
    count: 1,
    pages: 1,
    monthlyCount: 0,
    isLoading: false,
    tracker: false,
  });

  // const [page] = useState(1);

  const updateAmbassadorsStatus = async (data) => {
    const config = {
      method: 'post',
      action: 'change_status_of_ambassador',
      url: `${GET_AMBASSADORS_URL}`,
      data: qs.stringify(data),
    };

    await axios(config)
      .then((response) => {
        // console.log(response.data);
        if (response.data) {
          // console.log('true');
          toastr.success('Update Successful');
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((error) => {
        if (error) {
          toastr.info('Something went wrong!');
          dispatch({ type: LOADING_STOPS });
        }
      });
  };

  return {
    items,
    pages,
    count,
    loading,
    updateAmbassadorsStatus,
  };
};
