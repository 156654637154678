import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import CustomInput from '../forms/CustomInput';
import CustomButton from '../forms/CustomButton';
import AppContext from '../../../../context/shared/AppContext';

export default function UninstallDevice({ show, onClose, customer }) {
  const { onUninstallDevice, loadingUninstallDevice } = useContext(AppContext);
  const displayButton = () => {
    if (loadingUninstallDevice) {
      return <CustomButton classname="btn btn-green" name="Uninstalling" disabled={loadingUninstallDevice} />;
    }
    return <CustomButton classname="btn btn-green" name="Uninstall Device" disabled={false} />;
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const { mac, name, address, email, phone, id } = customer;
    const data = {
      id,
      mac,
      name,
      address,
      email,
      phoneNumber: phone,
      // state: "lagos",
      // country: "Nigeria"
    };
    onUninstallDevice(data);
  };
  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Uninstall Device</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="form-horizontal" onSubmit={onSubmit}>
          <div className="form-group">
            <div className="col-12 mb-3">
              <div className="col-12">
                <CustomInput
                  classname="form-control border border-info py-2"
                  name="mac"
                  value={customer.mac}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  onChange={() => {}}
                  readOnly
                  placeholder="customer macid"
                  type="text"
                />
              </div>
            </div>
            <div className="col-12 mb-3 text-center">{displayButton()}</div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
