import React, { useContext } from 'react';
import RebootTable from './RebootTable';
import CustomPagination from '../../../../shared/CustomPagination';
import AppContext from '../../../../../../context/shared/AppContext';

const RebootBody = () => {
  const { loading, pages, paginateChange } = useContext(AppContext);
  return (
    <div className="row mt-5">
      <RebootTable />
      {!loading && (
        <CustomPagination
          pagesCount={pages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={paginateChange}
        />
      )}
    </div>
  );
};

export default RebootBody;
