import { useState } from 'react';
import { toast } from 'react-toastify';
import axios from '../../../../../../services/api/axios';

const usePaidLeads = () => {
  const [bulkModal, setBulkModal] = useState(false);
  const [sendingSms, setSendingSms] = useState(false);
  const [bulkEmailModal, setBulkEmailModal] = useState(false);
  const onOpenBulkModal = () => {
    setBulkModal(!bulkModal);
  };
  const onOpenBulkEmailModal = () => setBulkEmailModal(!bulkEmailModal);
  const sendSms = async (params) => {
    setSendingSms(!sendingSms);
    try {
      const response = await axios.post('/index.php', params);
      if (response.data.status) {
        setSendingSms(false);
        setBulkModal(!bulkModal);
        toast.success(response.data.message);
      } else {
        toast.info('Unable to send message. Please try again');

        setSendingSms(false);
      }
    } catch (e) {
      setSendingSms(!sendingSms);
      toast.info('Something went wrong!');
    }
  };
  return { bulkModal, sendSms, onOpenBulkModal, sendingSms, onOpenBulkEmailModal, bulkEmailModal };
};
export default usePaidLeads;
