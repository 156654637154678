/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { Link } from 'react-router-dom';
import PageName from '../../../../../shared/layouts/PageName';
import Logout from '../../../../../shared/auth/Logout';
import useCheckTwoDepartments from '../../../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';
import { admin, customer_support } from '../../../../../../../utils/config';
import { useUser } from '../../../../../../../hooks/shared/user/useUser';
import CustomerSupportSideBar from '../../../../../../layouts/customer-support/CustomerSupportSideBar';
import ExpiredLteCustomersBody from '../../../../../shared/lte/expired-lte-customers/ExpiredLteCustomersBody';

const CustomerSupportExpiredLteCustomers = ({ history }) => {
  useCheckTwoDepartments(`${customer_support}`, `${admin}`, history);
  const {
    profile: { firstName },
  } = useUser();

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <div className="d-flex col-lg-12 p-2 top-header">
          <div className="mt-3 d-flex col-lg-12 p-0">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link className="s-sidebar__trigger" to="#">
              <i className="fa fa-bars mr-3" />
            </Link>
            <CustomerSupportSideBar />
            <PageName name="EXPIRED LTE CUSTOMERS" />

            <div className="d-flex profile-mail col-6">
              <h6>
                <i className="mr-auto">{firstName} </i>
                <Logout />
              </h6>
            </div>
          </div>
        </div>
        <div className="col-12 after-summary">
          <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-3">
            <ExpiredLteCustomersBody />
          </div>
        </div>
      </main>
    </div>
  );
};

export default CustomerSupportExpiredLteCustomers;
