import axios from 'axios';
import toastr from 'toastr';
import { API_URL } from '../../../utils/config';
import { DELETE_DEPARTMENT, GET_DEPARTMENTS } from '../../../constants/types/shared/DepartmentsTypes';
import { GET_DEPARTMENTS_URL } from '../../../services/api/shared/departments/DepartmentsActions';

export const getDepartments = () => async (dispatch) => {
  try {
    const response = await axios.get(`${GET_DEPARTMENTS_URL}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
 

    dispatch({
      type: GET_DEPARTMENTS,
      payload: response.data.data,
    });
  } catch (e) {
    toastr.info('Unable to get departments!');
  }
};

// not using tho
export const deleteDepartment = (id) => (dispatch) => {
  const url = `${API_URL}/department/${id}`;
  axios.delete(url, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
  });
  dispatch({
    type: DELETE_DEPARTMENT,
    payload: id,
  });
};
