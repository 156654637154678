import axios from 'axios';

export const get_all_support_visits_query = async (from, to, key = 'issue_type', value = 'technical') => {
  const val = {
    from: from,
    to: to,
    [key]: value,
  };

  let formData = new URLSearchParams(val).toString();

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
  };
  try {
    const res = await axios.post(`https://api.tizeti.com:8443/api/v1/supportVisit/graphCount`, formData, {
      headers: headers,
    });
    if (res.data) {
      // console.log(res.data);
      return res.data;
    }
  } catch (error) {
    console.error(error);
  }
};
