import { GET_ACTIVE_AMBASSADORS } from '../../../constants/types/express-wifi/ExpressWifiTypes';
import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';

const initialState = {
  items: [],
  totalPages: '',
  allPages: '',
  currentPage: '',
  isLoading: false,
};

const AmbassadorsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOADING_STARTS:
      return {
        ...state,
        isLoading: true,
      };

    case GET_ACTIVE_AMBASSADORS:
      return {
        ...state,
        items: payload.items,
        totalPages: payload.totalPages,
        allPages: payload.allPages,
        currentPage: payload.currentPage,
        isLoading: false,
      };

    case LOADING_STOPS:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default AmbassadorsReducer;
