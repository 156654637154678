import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { TableCellMonths } from '../components/tempData';
import Box from '@mui/material/Box';

export default function AreaCharts({ getZones, ActiveInactiveData, ActiveData, InActiveData }) {
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    if (getZones.length > 0 && InActiveData.length && ActiveInactiveData.length > 0 && ActiveData.length > 0) {
      const refinedData = getZones.map((state, index) => ({
        name: state.name,
        'Active & Expired': ActiveInactiveData[index] && ActiveInactiveData[index],
        Active: typeof ActiveData[index] === 'number' ? ActiveData[index] : ActiveData[index].length,
        Expired: typeof InActiveData[index] === 'number' ? InActiveData[index] : InActiveData[index].length,
      }));

      if (refinedData.length > 0) {
        const filterNonZeroValues = refinedData.filter((val) => val.Active > 0);
        setData(filterNonZeroValues);
      }
    }
  }, [InActiveData.length]);

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flexStart" sx={{ mr: 5 }}>
      <Box sx={{ width: { xs: '90vw', md: 800 }, overflowX: 'auto' }}>
        <div style={{ width: '100%', overflowX: 'scroll' }}>
          <ResponsiveContainer width={2200} height={500}>
            <AreaChart width={2200} data={data} margin={{ top: 10, right: 10, left: 0, bottom: 0 }}>
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorLv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#ff7300" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#ff7300" stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />

              <Area type="monotone" dataKey="Active & Expired" fillOpacity={1} fill="url(#colorUv)" stroke="#8884d8" />
              <Area type="monotone" dataKey="Active" fillOpacity={1} fill="url(#colorPv)" stroke="#82ca9d" />
              <Area type="monotone" dataKey="Expired" fillOpacity={1} fill="url(#colorLv)" stroke="#ff7300" />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </Box>
    </Box>
  );
}
