/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import view from '../../../../../assets/img/icons/view.png';
import { useActiveCustomer } from '../../../../../hooks/shared/asset-team/active/useActiveCustomer';
import AssetTeamViewUsers from '../../../../../utils/asset-team/AssetTeamViewUsers';

const ActiveCustomersTable = ({ items }) => {
  const { getActiveDetails, customers, isLoading } = useActiveCustomer();
  const [basestation, setBasestation] = useState('');

  const headers = [
    { label: 'Name', key: 'Name' },
    { label: 'MAC ID', key: 'username' },
    { label: 'Expiry Date', key: 'Expiration_date' },
  ];

  const getDetails = (customer) => {
    setBasestation(customer.basestationame);
    const data = {
      action: 'tizeti_asset_active_users',
      calledstationid: customer.basestationame,
    };
    getActiveDetails(data);
  };

  const tableData =
    items && items.length ? (
      items.map((item, i) => {
        const { basestationame, active_count } = item;
        return (
          <tr className="text-nowrap" key={i}>
            <td>{i + 1}</td>
            <td>{basestationame.toUpperCase()}</td>
            <td>{active_count}</td>
            <td>
              <button
                type="submit"
                data-toggle="tooltip"
                data-placement="left"
                title="View"
                onClick={() => getDetails(item)}
              >
                <span className="view1 ml-4">
                  <img src={view} alt="" data-toggle="modal" data-target="#viewDetails" />
                </span>
              </button>
              <AssetTeamViewUsers
                zone={basestation}
                users={customers}
                headers={headers}
                isLoading={isLoading}
                NotFoundText="No Active Customer"
                fileName="Active Customers Details.csv"
              />
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="4" className="text-center">
          No Active Customers
        </td>
      </tr>
    );

  return tableData;
};

export default ActiveCustomersTable;
