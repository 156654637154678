/* eslint-disable react/require-default-props */
import moment from 'moment';

import React from 'react';
import PropTypes from 'prop-types';
// import view from '../../../../../assets/img/icons/view.png';
// import NoLosDetail from './NoLosDetail';

const NoLosRow = ({ item, index }) => {
  return (
    <tr>
      <td>{index + 1}</td>
      <td>{item.latitude}</td>
      <td>{item.longitude}</td>
      <td>{item.address}</td>
      <td>{item.country === 'ng' ? 'Nigeria' : 'Ghana'}</td>
      <td>{item.notes}</td>
      <td>{item.state}</td>
      <td>{moment(item.created_at).format('MM/DD/YYYY')}</td>
      {/* <td> */}
      {/*  <div className="d-flex"> */}
      {/*    <button */}
      {/*      type="submit" */}
      {/*      className="ml-2 mr-2" */}
      {/*      // data-id={vehicle.id} */}
      {/*      // onClick={() => { */}
      {/*      //   this.getVehicle(vehicle); */}
      {/*      // }} */}
      {/*      // onLoad={() => { */}
      {/*      //   this.getEmployeeName(vehicle.employee_id); */}
      {/*      // }} */}
      {/*      data-toggle="modal" */}
      {/*      data-placement="right" */}
      {/*      data-target="#noLosDetail" */}
      {/*      style={{ border: '0', backgroundColor: 'white' }} */}
      {/*    > */}
      {/*            <span className="view"> */}
      {/*              <img src={view} data-toggle="tooltip" data-placement="left" alt="" title="View" /> */}
      {/*            </span> */}
      {/*    </button> */}
      {/*    <NoLosDetail /> */}

      {/*    /!* <div id="edit" className="modal fade in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" */}
      {/*                           aria-hidden="true" style={{overflow: 'auto'}}> */}
      {/*                          <div className="modal-dialog"> */}
      {/*                              <div className="modal-content"> */}
      {/*                                  <div className="modal-header"> */}
      {/*                                      <h4 className="modal-title text-center" id="myModalLabel">Update Vehicles</h4> */}
      {/*                                      <button type="button" className="close" data-dismiss="modal" aria-hidden="true">× */}
      {/*                                      </button> */}
      {/*                                  </div> */}
      {/*                                  <div className="modal-body"> */}
      {/*                                      <form className="form-horizontal form-material"> */}
      {/*                                          <div className="form-group"> */}
      {/*                                              <div className="col-md-12 mb-3 d-flex"> */}
      {/*                                                  <div className="col-lg-6"> */}
      {/*                                                      <label>First Name</label> */}
      {/*                                                      <input type="text" className="form-control" placeholder="First Name" */}
      {/*                                                             required/> */}
      {/*                                                  </div> */}

      {/*                                                  <div className="col-lg-6"> */}
      {/*                                                      <label>Last Name</label> */}
      {/*                                                      <input type="text" className="form-control" placeholder="Last Name" */}
      {/*                                                             required/> */}
      {/*                                                  </div> */}
      {/*                                              </div> */}

      {/*                                              <div className="col-md-12 mb-3 d-flex"> */}
      {/*                                                  <div className="col-lg-6 one"> */}
      {/*                                                      <input type="radio" value="Female"/> Female */}
      {/*                                                      <input type="radio" value="Male"/> Male */}
      {/*                                                  </div> */}

      {/*                                                  <div className="col-lg-6"> */}
      {/*                                                      <label>Address</label> */}
      {/*                                                      <input type="text" className="form-control" */}
      {/*                                                             placeholder="Permanent home address"/> */}
      {/*                                                  </div> */}
      {/*                                              </div> */}

      {/*                                              <div className="col-md-12 mb-3 d-flex"> */}
      {/*                                                  <div className="col-lg-6"> */}
      {/*                                                      <label>Email address</label> */}
      {/*                                                      <input type="email" className="form-control" */}
      {/*                                                             placeholder="personal email address" required/> */}
      {/*                                                  </div> */}

      {/*                                                  <div className="col-lg-6"> */}
      {/*                                                      <label>Alternate email address</label> */}
      {/*                                                      <input type="email" className="form-control" */}
      {/*                                                             placeholder="Alternate email address" required/> */}
      {/*                                                  </div> */}
      {/*                                              </div> */}

      {/*                                              <div className="col-md-12 mb-3 d-flex"> */}
      {/*                                                  <div className="col-lg-6"> */}
      {/*                                                      <label>Offical Email address</label> */}
      {/*                                                      <input type="email" className="form-control" */}
      {/*                                                             placeholder="Company domain email address" required/> */}
      {/*                                                  </div> */}
      {/*                                              </div> */}
      {/*                                              <div className="col-md-12 mb-3 d-flex"> */}
      {/*                                                  <div className="col-lg-6"> */}
      {/*                                                      <label>Phone number 1</label> */}
      {/*                                                      <input type="tel" className="form-control" */}
      {/*                                                             placeholder="Phone number 1" required/> */}
      {/*                                                  </div> */}

      {/*                                                  <div className="col-lg-6"> */}
      {/*                                                      <label>Phone number 2</label> */}
      {/*                                                      <input type="tel" className="form-control" */}
      {/*                                                             placeholder="Phone number 2" required/> */}
      {/*                                                  </div> */}
      {/*                                              </div> */}

      {/*                                              <div className="col-md-12 mb-3 d-flex"> */}
      {/*                                                  <div className="col-lg-6"> */}
      {/*                                                      <label>Extension</label> */}
      {/*                                                      <input type="tel" className="form-control" */}
      {/*                                                             placeholder="Phone number extension" required/> */}
      {/*                                                  </div> */}

      {/*                                                  <div className="col-lg-6"> */}
      {/*                                                      <label>Department</label> */}
      {/*                                                      <input type="text" className="form-control" */}
      {/*                                                             placeholder="Company Department" required/> */}
      {/*                                                  </div> */}
      {/*                                              </div> */}

      {/*                                              <div className="col-md-12 mb-3 d-flex"> */}
      {/*                                                  <div className="col-lg-6"> */}
      {/*                                                      <label>Role</label> */}
      {/*                                                      <input type="tel" className="form-control" */}
      {/*                                                             placeholder="Department Designation" required/> */}
      {/*                                                  </div> */}

      {/*                                                  <div className="col-lg-6"> */}
      {/*                                                      <label>Date of resumption</label> */}
      {/*                                                      <input type="date" className="form-control" */}
      {/*                                                             placeholder="Phone number 2" required/> */}
      {/*                                                  </div> */}
      {/*                                              </div> */}

      {/*                                              <div className="col-md-12 mb-3 d-flex"> */}
      {/*                                                  <div className="col-lg-6"> */}
      {/*                                                      <label>Employee ID</label> */}
      {/*                                                      <input type="text" className="form-control" */}
      {/*                                                             placeholder="Staff ID number" required/> */}
      {/*                                                  </div> */}

      {/*                                                  <div className="col-lg-6 d-flex labels"> */}
      {/*                                                      <label>Admin Status</label> */}
      {/*                                                      <label className="switch" */}
      {/*                                                             style={{width: '26%', top: '10%', left: '10%'}}> */}
      {/*                                                          <input type="checkbox"/> */}
      {/*                                                          <span className="slider round"></span> */}
      {/*                                                      </label> */}
      {/*                                                  </div> */}
      {/*                                              </div> */}
      {/*                                          </div> */}
      {/*                                      </form> */}
      {/*                                  </div> */}
      {/*                                  <div className="modal-footer"> */}

      {/*                                  </div> */}
      {/*                              </div> */}
      {/*                              /* modal-content */}
      {/*                          </div> */}
      {/*                          /* modal-dialog */}
      {/*                      </div> *!/ */}

      {/*    <div */}
      {/*      id="success" */}
      {/*      className="modal fade in" */}
      {/*      tabIndex="-1" */}
      {/*      role="dialog" */}
      {/*      aria-labelledby="myModalLabel" */}
      {/*      aria-hidden="true" */}
      {/*    > */}
      {/*      <div className="modal-dialog"> */}
      {/*        <div className="modal-content"> */}
      {/*          <div className="modal-body text-center"> */}
      {/*            <span className="fa fa-check-circle" /> */}
      {/*            <p> */}
      {/*              <b>Employee Added Successfully!</b> */}
      {/*            </p> */}
      {/*            <button className="btn btn-green">Add Another</button> */}
      {/*          </div> */}
      {/*        </div> */}
      {/*      </div> */}
      {/*    </div> */}

      {/*    /!* <button type="submit" data-toggle="modal" data-target="#cancel" style={{ */}
      {/*                          border: '0', */}
      {/*                          backgroundColor: 'white' */}
      {/*                      }} */}
      {/*                      onClick={this.deleteVehicle.bind(this, vehicle.id)}><span className="view"><img src={deleteThis} data-toggle="tooltip" data-placement="right" */}
      {/*                                                     title="Delete"/></span></button> *!/ */}
      {/*    /!* <div id="cancel" className="modal fade in" tabIndex="-1" role="dialog" */}
      {/*                           aria-labelledby="myModalLabel" aria-hidden="true"> */}
      {/*                          <div className="modal-dialog"> */}
      {/*                              <div className="modal-content"> */}
      {/*                                  <div className="modal-body text-center"> */}
      {/*                                      <span className="fa fa-exclamation-circle"></span> */}
      {/*                                      <p><b>Are you sure you want to delete this vehicle?</b></p> */}
      {/*                                      <button type="submit" className="btn btn-green waves-effect" data-toggle="modal" */}
      {/*                                              data-target="#edit">Yes Delete */}
      {/*                                      </button> */}
      {/*                                      <button type="submit" className="btn btn-green waves-effect" data-toggle="modal" */}
      {/*                                              data-target="#edit">Cancel */}
      {/*                                      </button> */}
      {/*                                  </div> */}
      {/*                              </div> */}
      {/*                               modal-content */}
      {/*                          </div> */}
      {/*                          /* modal-dialog *!/ */}
      {/*  </div> */}
      {/* </td> */}
    </tr>
    // {/* ))} */}
  );
};

NoLosRow.propTypes = {
  item: PropTypes.shape(),
  index: PropTypes.number,
};
export default NoLosRow;
