/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

const ViewInstallationDetails = ({
  customer: { user, created_at, status, address, user: { firstName, lastName, email, phoneNumber } = {} },
}) => {
  return (
    <div id="installation_details" className="modal fade" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title update_asset">Installation Details</h4>
            <button type="button" className="btn btn-outline-info btn-circle btn-close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div className="modal-body detail" style={{ padding: '0' }}>
            <div className="row">
              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label1">
                  <label>First Name</label>
                  <label>Last Name</label>
                  <label>Phone Number</label>
                  <label>Email Address</label>
                </div>

                <div className="col-lg-8 detail-result1">
                  <p className="mt-4 mb-1">{user ? firstName : null}</p>
                  <p className="mb-1">{user ? lastName : null}</p>
                  <p className="mb-1">{user ? phoneNumber : null}</p>
                  <p className="mb-1">{user ? email : null}</p>
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label2">
                  <label>Payment Date</label>
                  <label>Address</label>
                </div>

                <div className="col-lg-8 detail-result2">
                  <p className="mt-4 mb-1">{moment(created_at).format('DD-MM-YYYY')}</p>
                  <p className="mb-1">{address}</p>
                </div>
              </div>

              {/* <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label3">
                  <label>Date To Be Installed</label>
                  <label>Team</label>
                </div>

                <div className="col-lg-8 detail-result2">
                  <p className="mt-4 mb-1">{moment(date).format('DD-MM-YYYY')}</p>
                  <p className="mb-1">OLA/KELVIN</p>
                </div>
              </div> */}

              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label4">
                  <label>Status</label>
                </div>

                <div className="col-lg-8 detail-result2">
                  <p className="mt-4 mb-1">{status}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ViewInstallationDetails.propTypes = {
  customer: PropTypes.objectOf.isRequired,
};

export default ViewInstallationDetails;
