import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Calendar from 'react-calendar';
import { TableCellMonths } from '../components/tempData';
import Select from 'react-select';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import BarCharts from './BarCharts';
import AlertDialog from './Dialog';
// generate all the months in a year with name and value
const months = [
  { value: 0, label: 'January' },
  { value: 1, label: 'February' },
  { value: 2, label: 'March' },
  { value: 3, label: 'April' },
  { value: 4, label: 'May' },
  { value: 5, label: 'June' },
  { value: 6, label: 'July' },
  { value: 7, label: 'August' },
  { value: 8, label: 'September' },
  { value: 9, label: 'October' },
  { value: 10, label: 'November' },
  { value: 11, label: 'December' },
];

// get the default month from js date
const defaultMonth = new Date().getMonth();

//default month comes as an index
const defaultMonthIndex = months.findIndex((month) => month.value === defaultMonth);

const todayDate = new Date().toISOString().slice(0, 10);

export const formatDate = (date) => {
  const formattedDate = new Date(date);
  formattedDate.setMinutes(formattedDate.getMinutes() - formattedDate.getTimezoneOffset());
  const stringFormattedDate = formattedDate.toISOString().split('T')[0];
  return stringFormattedDate;
};
export default function SupportVisitIndex() {
  const [selectedMonth, setSelectedMonth] = React.useState(months[defaultMonthIndex]);

  // get the first and last day of the month via the selected month
  const firstDay = new Date(new Date().getFullYear(), selectedMonth.value, 1);
  console.log('🚀 ~ SupportVisitIndex ~ firstDay:', firstDay);
  const lastDay = new Date(new Date().getFullYear(), selectedMonth.value + 1, 0);
  console.log('🚀 ~ SupportVisitIndex ~ lastDay:', lastDay);

  const [fromDate, setFromDate] = React.useState(firstDay);
  const [toDate, setToDate] = React.useState(lastDay);
  const [calenderOpen, setCalenderOpen] = React.useState(false);
  const [submitDetect, setSubmitDetect] = React.useState([false]);

  const dateRef = React.useRef(null);
  const handleMonthChange = (selectedMonth) => {
    console.log('🚀 ~ handleMonthChange ~ selectedMonth:', selectedMonth);
    setSelectedMonth(selectedMonth);
    setFromDate(new Date(new Date().getFullYear(), selectedMonth.value, 1));
    setToDate(new Date(new Date().getFullYear(), selectedMonth.value + 1, 0));
  };

  const handleCalendarDateChange = (date) => {
    if (dateRef.current === 'from') {
      setCalenderOpen(true);
      setFromDate(formatDate(date));
    } else if (dateRef.current === 'to') {
      setCalenderOpen(true);
      setToDate(formatDate(date));
    }
  };

  return (
    <Box>
      <Box
        sx={{
          mx: 2,
          my: 3,
          minHeight: '100vh',
          opacity: calenderOpen ? '0.3' : 1,
          pointerEvents: calenderOpen ? 'none' : 'auto',
        }}
      >
        <Box>
          <Typography variant="h4">Support Visit</Typography>
        </Box>

        {/* Create a drop down that maps the table cell months  */}
        <Box>
          <Box marginTop={'2rem'} display={'flex'} justifyContent={'flex-start'} alignItems={'center'} gap={5}>
            <Box width={'13rem'} marginTop={'2px'}>
              <div>Select By Month</div>
              <Select
                className="basic-select-month"
                classNamePrefix="select"
                defaultValue={selectedMonth}
                isSearchable={true}
                name="month"
                options={months}
                onChange={handleMonthChange}
              />
            </Box>

            <Box>
              <div>Custom Date</div>
              <ButtonGroup variant="outlined" aria-label="Calender button group">
                <Button
                  variant="outlined"
                  sx={{ textTransform: 'capitalize' }}
                  onClick={() => {
                    dateRef.current = 'from';
                    handleCalendarDateChange(fromDate);
                  }}
                >
                  From: {formatDate(fromDate)}
                </Button>
                <Button
                  variant="outlined"
                  sx={{ textTransform: 'capitalize' }}
                  onClick={() => {
                    dateRef.current = 'to';
                    handleCalendarDateChange(toDate);
                  }}
                >
                  To: {formatDate(toDate)}
                </Button>
                <Button
                  variant="contained"
                  sx={{ textTransform: 'capitalize' }}
                  disabled={fromDate > toDate || toDate > todayDate}
                  onClick={() => {
                    setSubmitDetect((prev) => [...prev, true]);
                  }}
                >
                  Submit
                </Button>
              </ButtonGroup>
            </Box>
          </Box>
        </Box>
        <Box marginTop={'2rem'}>
          <BarCharts fromDate={formatDate(fromDate)} toDate={formatDate(toDate)} submitDetect={submitDetect} />
        </Box>
        {/* calender modal */}
        {/* <Calendar maxDate={new Date()} onChange={handleCalendarDateChange} /> */}

        {/* Dialogs dont work here dunno why? */}
        {/* <AlertDialog /> */}
      </Box>

      {calenderOpen && (
        <Box position={'absolute'} sx={{ left: '25%', top: '25%', transformOrigin: '-25%,-25%' }}>
          <Button variant="contained" color="error" onClick={() => setCalenderOpen(false)} sx={{ float: 'right' }}>
            Close
          </Button>
          <Calendar onChange={handleCalendarDateChange} maxDate={new Date()} />
        </Box>
      )}
    </Box>
  );
}
