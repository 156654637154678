import axios from 'axios';
import { toast } from 'react-toastify';
import { EDIT_LEAD_URL, GET_LEADS_URL, SEARCH_LEAD_URL } from '../../../services/api/shared/leads/LeadsUrl';
import { GET_LEADS } from '../../../constants/types/shared/leads/LeadsTypes';
import { FETCHING_STARTS, LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';

export const getLeads = () => async (dispatch) => {
  dispatch({
    type: FETCHING_STARTS,
    payload: true,
  });

  try {
    const response = await axios.get(`${GET_LEADS_URL}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      dispatch({
        type: GET_LEADS,
        payload: response.data,
      });
    }
  } catch (e) {}
};

export const editLead = (data, id) => async (dispatch) => {
  try {
    dispatch({
      type: LOADING_STARTS,
      payload: true,
    });

    const response = await axios.patch(`${EDIT_LEAD_URL}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data.editStatus === 'success') {
      toast.info('you are the super intern, fix it already.');
      dispatch({
        type: LOADING_STOPS,
        payload: false,
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  } catch (e) {
    dispatch({
      type: LOADING_STOPS,
      payload: false,
    });
  }

  // axios.patch(`${API_URL}/users/${this.state.id}`,data,{
  //
  //
  //
  // }).then(res => {
  //
  //
  //
  // })
};

export const searchLead = (data) => async (dispatch) => {
  dispatch({
    type: FETCHING_STARTS,
    payload: true,
  });

  try {
    const response = await axios.post(`${SEARCH_LEAD_URL}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    console.log(response.data);

    if (response.data) {
      dispatch({
        type: GET_LEADS,
        payload: response.data,
      });
    }
  } catch (e) {
    dispatch({
      type: LOADING_STOPS,
      payload: false,
    });
  }
};
