/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { useUser } from '../../../../../../../hooks/shared/user/useUser';
import CustomerSupportNavBar from '../../../../../../layouts/customer-support/CustomerSupportNavBar';
import ExpiredBody from '../../../../../shared/customers/expired-customers/seven/ExpiredBody';

const CustomerSupportAssetTeamSevenDaysExpired = () => {
  const { profile } = useUser();
  const { firstName } = profile;
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <CustomerSupportNavBar currentPage="Expired 7 Days Ago" position={firstName} />
        <ExpiredBody />
      </main>
    </div>
  );
};

export default CustomerSupportAssetTeamSevenDaysExpired;
