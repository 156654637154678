import React from 'react';
import PropTypes from 'prop-types';
import loader from '../../../../assets/img/icons/TizetiLoaderColored.gif';

const RetentionTable = ({ load, customers }) => {
  const expiredList = customers ? (
    customers.map((customer, i) => (
      <tr key={customer.customer_username}>
        <td>{i + 1}</td>
        <td>{customer.customer_id}</td>
        <td>{customer.customer_username}</td>
        <td>{customer.customer_name}</td>
        <td>{customer.customer_email}</td>
        <td>{customer.mobile}</td>

        <td>{customer.expiration_date}</td>
        {/*                                                    <td>
                                                        <div className="d-flex">
                                                            <button data-toggle="tooltip" className="text-center"
                                                                    data-placement="right" title="View"><span
                                                                className="view1"><img
                                                                src="https://res.cloudinary.com/tizeti/image/upload/v1542971020/view.png"/></span>
                                                            </button>
                                                        </div>
                                                    </td> */}
      </tr>
    ))
  ) : (
    <p>No expired customers</p>
  );

  return <tbody>{load ? <img src={loader} alt="loading gif" /> : expiredList}</tbody>;
};

RetentionTable.propTypes = {
  // eslint-disable-next-line react/require-default-props
  load: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  customers: PropTypes.shape(),
};
export default RetentionTable;
