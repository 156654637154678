import React from 'react';
import { Box } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { get_all_down_hours_per_zone } from '../../../../../../actions/coo-statistics/DowntimeLogger';
import { TizetiGIFLogoTable } from '../../../../../../utils/loader/loadingHelper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Select from 'react-select';
import { FormatDate } from './DowntimeLogger.Index';

const options = [
  { value: 0, label: 'Today' },
  { value: 7, label: 'Last 7 days' },
  { value: 30, label: 'Last 30 days' },
];
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{ background: 'white', padding: '2rem', fontSize: '1.2rem', fontWeight: 700 }}
      >
        <p className="label">{`Product-Uptime(%) : ${payload[0].value}%`}</p>
      </div>
    );
  }
  return null;
};

export default function BarChartComponent({
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  todayDate,
  handleDateChange,
  whoRef,
}) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [value, setValue] = React.useState(0);

  const calculateDateDistance = (fromDate, toDate) => {
    const from = new Date(fromDate);
    const to = new Date(toDate);
    if (to - from === 0) {
      return 1;
    } else return (to - from) / (60 * 60 * 24 * 1000);
  };

  const fetchData = async (from, to) => {
    try {
      setIsLoading(true);
      const res = await get_all_down_hours_per_zone({ from, to }, todayDate);
      //   console.log(res);
      if (res) {
        //calculate uptime
        calculateDateDistance(from, to);
        const refine = res.map((item) => ({
          name: item.name,
          ['product-uptime']: Math.round(
            ((24 * calculateDateDistance(from, to) - item.totalDownTimeCount) /
              (24 * calculateDateDistance(from, to))) *
              100,
          ),
        }));

        setData(refine);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  function renderDateIfTabsValueChanges() {
    if (value === 0) {
      handleSelectChange(options[0], { action: 'select-option' });
      setFromDate(FormatDate(todayDate));
      setToDate(FormatDate(todayDate));
      fetchData(FormatDate(todayDate), FormatDate(todayDate));
    } else if (value === 7) {
      handleSelectChange(options[1], { action: 'select-option' });
      const last7Days = new Date(todayDate);
      last7Days.setDate(last7Days.getDate() - 7);
      setFromDate(FormatDate(last7Days));
      setToDate(FormatDate(todayDate));
      fetchData(FormatDate(last7Days), FormatDate(todayDate));
    } else {
      handleSelectChange(options[2], { action: 'select-option' });
      const last30Days = new Date(todayDate);
      last30Days.setDate(last30Days.getDate() - 30);
      setFromDate(FormatDate(last30Days));
      setToDate(FormatDate(todayDate));
      fetchData(FormatDate(last30Days), FormatDate(todayDate));
    }
  }
  React.useEffect(() => {
    renderDateIfTabsValueChanges();
  }, [value]);

  function handleSelectChange(newValue, actionMeta) {
    if (actionMeta.action === 'select-option' && newValue.value >= 0) {
      setValue(newValue.value);
      fetchData(fromDate, toDate);
    }
  }

  React.useEffect(() => {
    fetchData(fromDate, toDate);
  }, []);

  return (
    <>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} sx={{ mr: 3, mt: 3 }}>
        <Box>
          <div>Customised Date</div>
          <ButtonGroup variant="outlined" aria-label="Calender button group">
            <Button
              variant="outlined"
              sx={{ textTransform: 'capitalize' }}
              onClick={() => {
                whoRef.current = 'from';
                handleDateChange(fromDate);
              }}
            >
              From: {fromDate}
            </Button>
            <Button
              variant="outlined"
              sx={{ textTransform: 'capitalize' }}
              onClick={() => {
                whoRef.current = 'to';
                handleDateChange(toDate);
              }}
            >
              To: {toDate}
            </Button>
            <Button
              variant="contained"
              sx={{ textTransform: 'capitalize' }}
              disabled={fromDate > toDate || toDate > todayDate}
              onClick={() => {
                fetchData(fromDate, toDate);
              }}
            >
              Submit
            </Button>
          </ButtonGroup>
        </Box>
        <Box width={'50%'}>
          <div>Filter By</div>
          <Select
            className="basic-filter"
            classNamePrefix="select"
            defaultValue={options[0]}
            name="filter-days"
            options={options}
            width={'5rem'}
            onChange={handleSelectChange}
          />
        </Box>
      </Box>
      <Box sx={{ mr: 5, mt: 3 }}>
        {isLoading ? (
          <TizetiGIFLogoTable />
        ) : (
          <Box sx={{ width: { xs: '90vw', md: 1000 }, overflowX: 'auto' }}>
            <Typography fontWeight={600}>Y-axis: Product-Uptime(%)</Typography>
            <Box sx={{ width: '100%', overflowX: 'scroll' }}>
              <BarChart
                width={4200}
                height={550}
                data={data}
                margin={{
                  top: 5,
                  right: 10,
                  left: 0,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
                <Legend />
                <Bar dataKey="product-uptime" fill="#413ea0" />
              </BarChart>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}
