import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useReducer } from 'react';
import { LOADING_STARTS, LOADING_STOPS } from '../../../../../constants/types/shared/LoadingTypes';
import departmentReducers from './departmentReducers';
import { GET_ZONES } from '../../../../../constants/types/shared/zones/ZonesTypes';

// toast.configure()

function useDep() {
  const [{ list, isLoading }, dispatch] = useReducer(departmentReducers, {
    list: [],
    isLoading: false,
  });

  const getDep = async () => {
    dispatch({ type: LOADING_STARTS });
    try {
      const response = await axios.get(`${'https://api.tizeti.com/api/v1/department'}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        console.log(response.data.data);
        dispatch({
          type: GET_ZONES,
          payload: {
            list: response.data.data,
          },
        });
      }
    } catch (e) {
      toast.info('Could not fetch Names');
      if (e.response) {
        dispatch({ type: LOADING_STOPS });
      }
    }
  };

  return {
    getDep,
    list,
    isLoading,
  };
}

export default useDep;
