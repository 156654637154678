/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getLoggedInUser } from '../../../actions/user/UserActions';

class OperationsSideBar extends Component {
  static propTypes = {
    getLoggedInUser: PropTypes.func.isRequired,
    role: PropTypes.shape(),
  };

  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.getLoggedInUser();
  }

  render() {
    const { role } = this.props;
    const { role_id } = role;
    return (
      <div className="s-layout__sidebar">
        <Link className="s-sidebar__trigger" to="#">
          <i className="fa fa-bars mr-3" />
        </Link>

        <nav className="s-sidebar__nav">
          <Link className="navbar-brand-logo" to="/operations">
            <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971047/Tizeti-Logo.png" alt="homepage" />
          </Link>
          <ul>
            <li className="p-0 mt-3">
              <li className="s-sidebar__nav-link">
                <Link to="/operations" target="_self">
                  <span>
                    <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971013/Group-3.png" alt="img" />
                  </span>
                  <small>Overview</small>
                </Link>
              </li>

              <li className="s-sidebar__nav-link">
                <Link to="/operations/team" target="_self">
                  <span>
                    <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-6.png" alt="img" />
                  </span>
                  <small>Teams</small>
                </Link>
              </li>

              <li className="s-sidebar__nav-link">
                {' '}
                <Link to="/operations/customers" target="_self">
                  <span>
                    <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                  </span>
                  <small>Customers</small>
                </Link>
              </li>

              <li className="s-sidebar__nav-link">
                {' '}
                <Link to="/operations/inventories" target="_self">
                  <span>
                    <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971015/Group-7.png" alt="img" />
                  </span>
                  <small>Inventory</small>
                </Link>
              </li>

              <li className="s-sidebar__nav-link">
                <Link to="/operations/installations" target="_self">
                  <span>
                    <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-6.png" alt="img" />
                  </span>
                  <small>Installations</small>
                </Link>
              </li>

              <li className="s-sidebar__nav-link">
                {' '}
                <Link to="/operations/vehicles" target="_self">
                  <span>
                    <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971011/Group.png" alt="img" />
                  </span>
                  <small>Vehicles</small>
                </Link>
              </li>

              {Number(role_id) > 1 ? (
                <li className="s-sidebar__nav-link">
                  {' '}
                  <Link to="/operations/vehicle-tracking" target="_self">
                    <span>
                      <img
                        src="https://res.cloudinary.com/teewhy/image/upload/v1553847314/vehicleTracking.png"
                        alt="img"
                      />
                    </span>
                    <small>Vehicle Tracking</small>
                  </Link>
                </li>
              ) : (
                ''
              )}

              <li className="s-sidebar__nav-link">
                {' '}
                <Link to="/operations/zones" target="_self">
                  <span>
                    <img
                      src="https://res.cloudinary.com/teewhy/image/upload/v1549268125/Installationzone.fw.png"
                      alt="img"
                    />
                  </span>
                  <small>Installation Zones</small>
                </Link>
              </li>

              <li className="s-sidebar__nav-link">
                {' '}
                <Link to="/operations/basestations" target="_self">
                  <span>
                    <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971012/Group-1.png" alt="img" />
                  </span>
                  <small>BaseStations</small>
                </Link>
              </li>

              <li className="s-sidebar__nav-link">
                {' '}
                <Link to="/operations/no-no-los" target="_self">
                  <span>
                    <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971012/Group-1.png" alt="" />
                  </span>
                  <small>No LOS</small>
                </Link>
              </li>

              <li className="s-sidebar__nav-link d-none d-md-block">
                {' '}
                <Link to="/operations/promoter-score" target="_self">
                  <span>
                    <img
                      src="https://res.cloudinary.com/teewhy/image/upload/v1548923430/r_d-envelop.fw.png"
                      alt="img"
                    />
                  </span>
                  <small>Net Promoter Score</small>
                </Link>
              </li>

              {Number(role_id) > 2 ? (
                <li className="s-sidebar__nav-link d-none d-md-block">
                  {' '}
                  <Link to="/operations/recruitment" target="_self">
                    <span>
                      <img
                        src="https://res.cloudinary.com/teewhy/image/upload/v1555319728/recruitment_1.svg"
                        alt="img"
                      />
                    </span>
                    <small>Recruitment</small>
                  </Link>
                </li>
              ) : (
                ''
              )}
            </li>

            <li className="p-0 mt-5 d-none d-md-block">
              <li className="s-sidebar__nav-link mt-5">
                {' '}
                <Link to="/operations/kpi-monitor" target="_self" className="make-bold">
                  <span>
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg" alt="img" />
                  </span>
                  <small>KPI Monitor</small>
                </Link>
              </li>

              <li className="s-sidebar__nav-link">
                {' '}
                <Link to="/operations/requisition" target="_self" className="make-bold">
                  <span>
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1550844527/requisition.png" alt="img" />
                  </span>
                  <small>Requisition</small>
                </Link>
              </li>

              <li className="s-sidebar__nav-link">
                {' '}
                <Link to="/issue" target="_self" className="text-danger make-bold">
                  <span>
                    <img
                      src="https://res.cloudinary.com/teewhy/image/upload/v1548923430/r_d-envelop.fw.png"
                      alt="img"
                    />
                  </span>
                  <small>Report an Issue</small>
                </Link>
              </li>

              {Number(role_id) > 2 ? (
                <li className="s-sidebar__nav-link">
                  {' '}
                  <Link to="/operations/request-feature" target="_self" className="make-bold">
                    <span>
                      <img
                        src="https://res.cloudinary.com/teewhy/image/upload/v1548923169/configr_d.fw.png"
                        alt="img"
                      />
                    </span>
                    <small>Request a Feature</small>
                  </Link>
                </li>
              ) : null}
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  role: state.auth.auth,
});

export default connect(mapStateToProps, { getLoggedInUser })(OperationsSideBar);
