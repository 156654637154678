/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import { usePurchaseOrder } from '../../../../../hooks/shared/purchase-order/usePurchaseOrder';
import ApprovedPurchaseOrderTable from './ApprovedPurchaseOrderTable';

const ApprovedPurchaseOrderBody = () => {
  const { lists, isLoading, getAccountsApprovedPO } = usePurchaseOrder();

  useEffect(() => {
    getAccountsApprovedPO();
  }, []);

  return (
    <div className="col-12 mt-5">
      <div className="col-12 m-0 p-0 after-summary">
        <div className="row ml-3">
          <div className="card x-panel m-0 p-0">
            <div className="table-responsive content col-12 mt-5 px-0">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Item</th>
                    <th>Quantity</th>
                    <th>Amount</th>
                    <th>Budget Code</th>
                    <th>Department</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan="8" className="text-center">
                        <img src={loader} alt="loading gif" />
                      </td>
                    </tr>
                  ) : (
                    <ApprovedPurchaseOrderTable lists={lists} />
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApprovedPurchaseOrderBody;
