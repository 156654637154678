import { useEffect, useReducer } from 'react';
import axios from 'axios';
import { customReducer } from '../../../../../reducers/shared/custom/CustomReducer';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import { LOADING_STOPS } from '../../../../../constants/types/shared/LoadingTypes';
import { GET_DATA } from '../../../../../constants/types/shared/CustomTypes';

const useGetPayslip = (url, data) => {
  const [{ response, loading }, dispatch] = useReducer(customReducer, {
    response: [],
    loading: true,
  });

  const { profile } = useUser();

  useEffect(() => {
    const getUserPaySlip = async () => {
      if (profile && 'id' in profile) {
        try {
          const res = await axios.post(url, data, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
          });
          if (res.data.length) {
            dispatch({
              type: GET_DATA,
              payload: res.data[0],
            });
          } else {
            dispatch({
              type: LOADING_STOPS,
            });
          }
        } catch (e) {
          if (e) {
            dispatch({
              type: LOADING_STOPS,
            });
          }
        }
      } else {
        dispatch({
          type: LOADING_STOPS,
        });
      }
    };

    getUserPaySlip();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.id]);

  return { response, loading };
};

export default useGetPayslip;
