import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { TableCellMonths } from '../../components/tempData';
import SimpleBackdrop from '../Backdrop';

export default function CompareByMonthsChart({ getZones, monthInstance, isLoading }) {
  return (
    <div>
      {isLoading ? (
        <SimpleBackdrop isLoading />
      ) : (
        <Box sx={{ mt: 4 }}>
          <Box>
            <Box>
              {getZones.length !== undefined &&
                getZones.length > 0 &&
                getZones.map((zone, index) => (
                  <Box
                    key={zone.id}
                    display="flex"
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                    gap={'2rem'}
                    sx={{
                      mb: 1.5,
                      py: 2,
                      px: 1.5,
                      transition: 'all 0.25 ease-in-out',
                      '&:hover': { background: '#e0e0e0', borderRadius: 16 },
                    }}
                  >
                    <Box>
                      <Typography variant="h5" sx={{ fontWeight: 600 }}>
                        {zone.name}
                      </Typography>
                    </Box>
                    <Box>
                      {monthInstance.length !== undefined &&
                        monthInstance.length > 0 &&
                        monthInstance.map((instance, i) => (
                          <Box
                            key={i}
                            display="flex"
                            justifyContent={'flex-start'}
                            alignItems={'center'}
                            gap={'0.5rem'}
                            flexWrap={'noWrap'}
                          >
                            <Box>
                              <Typography>
                                {' '}
                                {TableCellMonths[instance.monthValue]} {instance.selectedYear}
                              </Typography>
                            </Box>
                            <Box
                              display={'flex'}
                              justifyContent={'flex-start'}
                              alignItems={'center'}
                              gap={'.5rem'}
                              flexWrap={'no-wrap'}
                            >
                              <Box
                                sx={{
                                  background: '#7eeb7e',
                                  width: `${+instance.getAllActiveUsersCountValue[index] / 8 + 16}px`,
                                  height: '1rem',
                                  transform: 'all 0.5s ease-in-out',
                                }}
                              ></Box>
                              <Typography>{instance.getAllActiveUsersCountValue[index]}</Typography>
                            </Box>
                          </Box>
                        ))}
                    </Box>
                    <Box>
                      {monthInstance.length !== undefined &&
                        monthInstance.length > 0 &&
                        monthInstance.map((instance, i) => (
                          <Box
                            key={i}
                            display="flex"
                            justifyContent={'flex-start'}
                            alignItems={'center'}
                            gap={'0.5rem'}
                          >
                            <Box
                              sx={{
                                background: '#eb7e8d',
                                width: `${+instance.getAllInActiveUsersCountValue[index] / 8 + 16}px`,
                                height: '1rem',
                              }}
                            ></Box>
                            <Typography>{instance.getAllInActiveUsersCountValue[index]}</Typography>
                          </Box>
                        ))}
                    </Box>
                  </Box>
                ))}
            </Box>
          </Box>
        </Box>
      )}
    </div>
  );
}
