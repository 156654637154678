import React, { useEffect, useState } from 'react';
import classes from './newRetailerSMS.module.css';
import toastr from 'toastr';

const NewRetailerSMS = () => {
  const sender = 'Tizeti';
  let data = [];
  let phoneNumberListArray = [];
  const [countryCode, setCountryCode] = useState('+233');
  const [messageType, setMessageType] = useState('duePayment');

  const [price, setPrice] = useState('');
  const [date, setDate] = useState('');
  const [phoneNumbers, SetPhoneNumbers] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const messageOption = {
    inActiveCustomers: `Good day from ${sender}, We noticed your device has been without use for a while now. We will like to know what the issue you are experiencing is. One of our personnel will reach out to you soon.`,
    downtime: `Good day from ${sender}, kindly be informed that we are currently experiencing a downtime at your location, our engineers are working to restore the internet. Regards `,
    duePayment: `Good day from ${sender}, kindly be informed that your account is due for a monthly recharge, kindly make a minimum payment of ${
      (countryCode === '+233' ? 'GH¢' : '₦') + price ? price : '-----'
    } to recharge your wallet.`,
    newInstallation: `Good day from ${sender}. thank you for choosing Tizeti, kindly be informed that your account has been credited and you can now purchase data. Kindly be informed that you are required to recharge your account monthly with a minimum of  ${(countryCode ==='+233' ? 'GH¢' : '₦') + price}`,
    decommissioning: `Good day from Tizeti, Kindly be informed that our engineers will be at your location on the ${
      date ? date : '--/--/--'
    } to decommission the device. Regards`,
    networkCheckup: `Good day from Tizeti, this is a quick check up to confirm that our network is okay at your end.
    Thank you for choosing Tizeti and be rest assured we are always available to serve you better.`,
    minimumPayment: `Good day from Tizeti, Kindly be informed that due to the current economic situation in the country, all Tizeti Hotspot retailers are to pay minimum of :${countryCode === '+233' ? 'GH¢' : '₦'} 10,000 monthly subscription.
    Kindly note that this takes effect immediately, hence our portal will no longer credit an account with payment below : ${ countryCode === '+233' ? 'GH¢' : '₦'}10,000.
    Thank you for choosing Tizeti Hotspot and we will be always be available to serve you better.`,
  };

  const message = messageOption[`${messageType}`];
  const updateCountryCode = (e) => setCountryCode(e.target.value);
  const updateMessageType = (e) => setMessageType(e.target.value);
  const updatePrice = (e) => setPrice(e.target.value);
  const dateHandler = (e) => setDate(String(e.target.value));

  const updatePhoneNumber = (e) => {
    SetPhoneNumbers(e.target.value);
    formatPhoneNumbers(phoneNumbers);
  };

  const formatPhoneNumbers = (value) => {
    const phoneNumberArray = value.split(',');
    const localPhoneNumberList = [];
    phoneNumberArray.forEach((value) => localPhoneNumberList.push(countryCode + value.trim().substring(1)));
    phoneNumberListArray = [...localPhoneNumberList];
    setDataPayload(phoneNumberListArray);
  };

  const setDataPayload = (array) => {
    const localData = [];
    array.forEach((value) => localData.push([`${value}`, `${message}`]));
    data = [...localData];
  };

  const onSubmit = () => {
    if (messageType === 'decommissioning') {
      return date.length && phoneNumbers.length;
    } else if (messageType === 'duePayment' || messageType === 'newInstallation') {
      return price > 0 && price.length && phoneNumbers.length;
    }
    return phoneNumbers.length;
  };

  const handleSendSMS = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    formatPhoneNumbers(phoneNumbers);

    try {
      const response = await fetch('https://legacy.tizeti.com/geniatechapi/index.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'sendBulkSmsExpressTermii',
          data: data,
        }),
      });
      if (response.ok) {
        console.log(response);
        toastr.success('SMS sent successfully');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toastr.error('Failed to send bulk SMS');
      }
    } catch (error) {
      alert('error:', error);
    }

    setIsSubmitting(false);
  };

  return (
    <div className={`${classes.container} `}>
      <form className={classes.form} onSubmit={handleSendSMS}>
        {/* <form className={classes.form}> */}
        <h2 className={classes['form__header']}>SMS Portal</h2>
        <div className={classes['form__container']}>
          <div className={classes['flex-row']}>
            <div className={classes['form-control']}>
              <label htmlFor="sender">Sender</label>
              <input type="text" id="sender" value="Tizeti" disabled placeholder="Tizeti" />
            </div>
            <div className={classes['form-control']}>
              <label htmlFor="countrycode">Country: {countryCode}</label>
              <select className="" value={countryCode} onChange={updateCountryCode} disabled={isSubmitting}>
                <option value="+233">Ghana</option>
                <option value="+234">Nigeria</option>
              </select>
            </div>
          </div>

          <div className={classes['flex-row']}>
            <div className={classes['form-control']}>
              <label htmlFor="countrycode">Message Type</label>
              <div className={classes['custom-select']}>
                <select className="" value={messageType} onChange={updateMessageType} disabled={isSubmitting}>
                  <option value="duePayment">Due Payment</option>
                  <option value="inActiveCustomers">Inactive customers</option>
                  <option value="decommissioning">For decommissioning</option>
                  <option value="downtime">Downtime message</option>
                  <option value="newInstallation">New installation</option>
                  <option value="networkCheckup">Network Checkup</option>
                  <option value="minimumPayment">Minimum Payment</option>
                </select>
              </div>
            </div>

            {messageType === 'duePayment' || messageType === 'newInstallation' ? (
              <div className={classes['form-control']}>
                <label htmlFor="countrycode">price</label>
                <input
                  placeholder="Price"
                  type="number"
                  defaultValue={price}
                  onChange={updatePrice}
                  disabled={isSubmitting}
                />
              </div>
            ) : (
              ''
            )}
            {messageType === 'decommissioning' ? (
              <div className={classes['form-control']}>
                <label htmlFor="countrycode">Date</label>
                <input type="date" name="bday" onChange={(value) => dateHandler(value)} />
              </div>
            ) : (
              ''
            )}
          </div>

          <label></label>
          <div className={classes['form-control']}>
            <label>Phone Numbers</label>
            <textarea
              name="phoneNUmber"
              id="phoneNumber"
              cols="30"
              rows="15"
              placeholder="Enter phone numbers separated by comma, "
              value={phoneNumbers}
              onChange={updatePhoneNumber}
              disabled={isSubmitting}
            ></textarea>
          </div>

          <div className={classes['form-control']}>
            <label>Message Preview</label>
            <textarea disabled name="phoneNUmber" id="phoneNumber" cols="10" rows="5" placeholder={message}></textarea>
          </div>

          <button disabled={!onSubmit()} className={onSubmit() ? classes.button : classes['invalid_button']}>
            {isSubmitting ? 'Sending...' : 'Proceed to send'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewRetailerSMS;
