import { CREATE_ISSUE_URL } from "../../../services/api/research-and-development/IssueRequestUrl";
import axios from "axios";

export const createIssue = data => async dispatch => {

  console.log(data);
  try {

    const response = await axios.post(`${CREATE_ISSUE_URL}`, data, {

      headers: {

        'Authorization': `Bearer ${sessionStorage.getItem('token')}`

      }

    });

    console.log(response.data);

  } catch (e) {

  }

};