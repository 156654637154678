import React, { useEffect, useState } from 'react';
import useSearch from '../../../../../hooks/shared/custom/data/geniatech/useSearch';
// import { GET_CUSTOMER_DATA_HISTORY_URL } from './api/UsageHistoryUrl';
import CustomCsvDownload from '../../../shared/CustomCsvDownload';
import { filename, partnershipSignupHistoryHeaders } from '../../../../../utils/field-support/installations/helpers';
import CustomInput from '../../../shared/forms/CustomInput';
import SignupHistoryTable from './SignupHistoryTable';
import { useSignupsHistory } from '../../../../../hooks/partnership/useSignupsHistory';

const SignupHistoryBody = () => {
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');

  const { signups, getSignups, isLoading: loadingForm } = useSignupsHistory();

  useEffect(() => {
    getSignups(from, to);
  }, []);
  const onFormSubmit = (e) => {
    e.preventDefault();
    getSignups(from, to);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12 mt-3 mb-3">
          <form className="form-inline mt-4 inner-addon d-flex" onSubmit={onFormSubmit}>
            <div className="form-group mx-2">
              <label className="mt-2 p-0 copy-font">From: </label>
              <CustomInput
                classname="form-control mr-2"
                name="from"
                value={from}
                onChange={(e) => setFrom(e.target.value)}
                readOnly={false}
                type="date"
              />

              <label className="mt-2 p-0 copy-font">To: </label>
              <CustomInput
                classname="form-control mr-2"
                name="to"
                value={to}
                onChange={(e) => setTo(e.target.value)}
                readOnly={false}
                type="date"
              />
            </div>

            <div className="mb-3">
              <button className="btn btn-green" style={{ height: '39px' }}>
                Search
              </button>
            </div>
          </form>
          <div className="col-12 d-flex mt-2">
            <CustomCsvDownload
              data={signups || []}
              headers={partnershipSignupHistoryHeaders}
              filename={filename(`Signup_History`)}
              classname="btn btn-green shadow-md"
              textcolor="white"
              text="Download Data"
            />
          </div>
        </div>
      </div>
      <SignupHistoryTable data={signups} load={loadingForm} />
    </div>
  );
};

export default SignupHistoryBody;
