/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { memo, useEffect } from 'react';
import { useActivation } from '../../../../../hooks/shared/installations/useActivation';

const Dimension = ({ country, details, setDetails }) => {
  const {
    dimension1,
    dimension2,
    fetching,
    salesTypesArr,
    fetchingSalesTypes,
    getDimension2,
    getSalesType,
  } = useActivation();

  useEffect(() => {
    if (country === 'gh') getSalesType('GH');
    else getSalesType('NGA');
  }, [country]);

  const handleChange = (e) => {
    const { name, value, selectedOptions } = e.target;
    if (name === 'estate') setDetails({ ...details, estate: value });
    if (name === 'dimension1') {
      setDetails({ ...details, dimension1: value, dimension2: '' });
      getDimension2(selectedOptions[0].getAttribute('name'));
    }
    if (name === 'dimension2')
      setDetails({ ...details, dimension2: value, callstation: selectedOptions[0].getAttribute('name') });
  };

  return (
    <>
      <div className="col-12 mb-3 d-flex">
        <div className="col-6">
          <label className="copy-font m-0 p-0">Sales Type</label>
          <select name="estate" className="form-control" onChange={handleChange}>
            <option value="">Select Sales Type</option>
            {fetchingSalesTypes ? (
              <option>Fetching...</option>
            ) : salesTypesArr && salesTypesArr.length ? (
              salesTypesArr.map((salesType, i) => {
                return (
                  <option key={i} value={salesType}>
                    {salesType}
                  </option>
                );
              })
            ) : (
              <option>None</option>
            )}
          </select>
        </div>
        <div className="col-6">
          <label className="copy-font m-0 p-0">Dimension 1</label>
          <select name="dimension1" className="form-control" onChange={handleChange}>
            <option value="">--Select Dimension1--</option>
            {dimension1.length ? (
              dimension1.map((item) => {
                return (
                  <option key={item.id} value={item.id} name={item.name}>
                    {item.name.toUpperCase()}
                  </option>
                );
              })
            ) : (
              <option value="">Fetching Dimension1...</option>
            )}
          </select>
        </div>
      </div>

      <div className="col-12 mb-3">
        <div className="col-12">
          <label className="copy-font m-0 p-0">Dimension 2</label>
          {fetching ? (
            <input type="text" className="form-control" value="LOADING..." disabled />
          ) : dimension2.length === 0 ? (
            <input type="text" className="form-control" value="SELECT DIMENSION 1 FIRST" disabled />
          ) : dimension2 === 'EMPTY' ? (
            <input type="text" className="form-control" value={dimension2} disabled />
          ) : (
            <select name="dimension2" className="form-control" onChange={handleChange}>
              <option value="">--Select Dimension2--</option>
              {dimension2.map((item) => {
                return (
                  <option key={item.id} value={item.id} name={item.name}>
                    {item.label.toUpperCase()}
                  </option>
                );
              })}
            </select>
          )}
        </div>
      </div>
    </>
  );
};

export default memo(Dimension);
