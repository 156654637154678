/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-shadow */
/* eslint-disable react/require-default-props */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/button-has-type */
/* eslint-disable no-case-declarations */
import React, { useState } from 'react';
import { LEGACY_GENIATECH_URL } from '../../../../../services/api/shared/installations/InstallationsUrl';
import useFetchBulkDetails from '../../../../../hooks/shared/bulk-customer-details/useFetchBulkDetails';
import { calls } from './callMocks';
import SupportCallsTable from './SupportCallsTable';
import CustomInput from '../../../shared/forms/CustomInput';
import useSupportCall from '../../../../../hooks/customer-support/call-logs/useSupportCall';

const SupportCallsBody = () => {
  const [tableData, setTableData] = useState(calls);
  const today = new Date();
  const formattedDate = today.toISOString().slice(0, 10);

  const [date, setDate] = useState(formattedDate);
  const [shift, setShift] = useState('');
  const { getSupportCalls, isLoading, callCount } = useSupportCall();

  const onSubmit = async (e) => {
    e.preventDefault();
    const data = {
      from: date,
      to: date,
      shift,
    };
    getSupportCalls(data);
  };

  const onSubmits = async (data) => {
    getSupportCalls(data);
  };

  const submitForm = () => {
    return shift.length > 0;
  };

  return (
    <div className="container-fluid pt-5">
      <form className="form-inline mt-4 inner-addon d-flex" onSubmit={onSubmit}>
        <div className="form-group mx-2">
          <label className="mt-2 p-0 copy-font">Date: </label>
          <CustomInput
            classname="form-control mr-2"
            name="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            readOnly={false}
            type="date"
          />
        </div>

        <div className="form-group mx-2">
          <label className="mt-2 p-0 copy-font">Shift: </label>
          <select className="form-control" name="shift" onChange={(e) => setShift(e.target.value)}>
            <option value="">Select a shift</option>
            <option value="day">Day</option>
            <option value="night">Night</option>
          </select>
        </div>

        <div className="mb-3">
          {isLoading ? (
            <button className="btn btn-green" style={{ height: '39px' }} disabled>
              Searching
            </button>
          ) : (
            <button className="btn btn-green" style={{ height: '39px' }} disabled={!submitForm()}>
              Search
            </button>
          )}
        </div>
      </form>
      {/* <div className="col-12 d-flex mt-5">
        <CustomCsvDownload
          data={tableData || []}
          headers={bulkCustomerDetailsHeaders}
          filename={filename(`Bulk_Customer_Details`)}
          classname="btn btn-green shadow-md"
          textcolor="white"
          text="Download Data"
        />
      </div> */}
      <SupportCallsTable date={date} shift={shift} isLoading={isLoading} file={callCount} onSubmit={onSubmits} />
    </div>
  );
};

export default SupportCallsBody;
