/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import axios from 'axios';
import { editLead } from '../../../../../actions/shared/leads/LeadsActions';
// import { getStates } from '../../../../../actions/shared/states/StatesActions';
import { EDIT_LEAD_URL } from '../../../../../services/api/shared/leads/LeadsUrl';

class EditLead extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      id: '',
      address: '',
      state: '',
      status: '',
      about: '',
      comment: '',
      plan: '',
    };

    this.onChange = this.onChange.bind(this);
    this.editLeads = this.editLeads.bind(this);
  }

  // static propType = {
  // getStates: PropTypes.func.isRequired,
  // };

  componentWillReceiveProps(nextProps) {
    this.setState({
      firstName: nextProps.lead.firstName,
      lastName: nextProps.lead.lastName,
      phone: nextProps.lead.phoneNumber,
      email: nextProps.lead.email,
      id: nextProps.lead.id,
      address: nextProps.lead.address,
      state: nextProps.lead.state,
      status: nextProps.lead.status,
      about: nextProps.lead.about_us,
      comment: nextProps.lead.comment,
      plan: nextProps.lead.plan,
    });
  }

  editLeads = async (e) => {
    e.preventDefault();

    this.setState({
      load: true,
    });

    const { firstName, lastName, phone, email, id, address, state, status, about, comment, plan } = this.state;

    const data = {
      firstName,
      lastName,
      phone,
      email,
      address,
      state,
      status,
      about,
      comment,
      plan,
    };

    try {
      const response = await axios.patch(`${EDIT_LEAD_URL}/${id}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.editStatus === 'success') {
        toast.info('Lead updated successfully1');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (e) {
      if (e.response) {
        this.setState({
          load: false,
        });
      }
    }

    // this.props.editLead(data, id);
  };

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  // componentDidMount() {
  //   this.props.getStates();
  // }

  render() {
    const { firstName, lastName, phone, email, address, comment, plan, load } = this.state;
    // const { states } = this.props;

    return (
      <div
        id="editleads"
        className="modal fade in"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center" id="myModalLabel">
                Edit Lead Details
              </h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
            </div>
            <div className="modal-body">
              <form className="form-horizontal" onSubmit={this.editLeads}>
                <div className="form-group">
                  <div className="col-12 d-flex p-0 mb-3">
                    <div className="col-6">
                      <label className="copy-font m-0 p-0">First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={firstName}
                        name="firstName"
                        onChange={this.onChange}
                      />
                    </div>

                    <div className="col-6">
                      <label className="copy-font m-0 p-0">Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={lastName}
                        name="lastName"
                        onChange={this.onChange}
                      />
                    </div>
                  </div>

                  <div className="col-12 d-flex p-0 mb-3 mr-3">
                    <div className="col-6">
                      <label className="copy-font m-0 p-0">Email</label>
                      <input type="text" className="form-control" value={email} name="email" onChange={this.onChange} />
                    </div>

                    <div className="col-6">
                      <label className="copy-font m-0 p-0">Phone Number</label>
                      <input type="text" className="form-control" value={phone} name="phone" onChange={this.onChange} />
                    </div>
                  </div>

                  <div className="col-12 d-flex p-0 mb-3 mr-3">
                    {/* <div className="col-6">
                      <label className="copy-font p-0">Date Of Birth</label>
                      <input type="date" name="dateOfBirth" className="form-control" value={dateOfBirth} required />
                    </div> */}
                    <div className="col-6">
                      <label className="copy-font m-0 p-0">State</label>
                      <select name="state" className="form-control" onChange={this.onChange}>
                        <option value="">Select State</option>
                        <option value="lagos">Lagos</option>
                        <option value="ogun">Ogun</option>
                        <option value="rivers">Rivers</option>
                        <option value="edo">Edo</option>
                        <option value="oyo">Oyo</option>

                        {/* {states ? states.map((state) => <option value={state.name}>{state.name}</option>) : null} */}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-12 mb-3 p-0 d-flex">
                    <div className="col-lg-6">
                      <label className="copy-font m-0 p-0">Plan</label>
                      <div className="d-flex">
                        <input
                          id="radio-button"
                          checked={plan === 'home'}
                          onChange={this.onChange}
                          name="plan"
                          value="home"
                          className="check"
                          type="radio"
                          style={{ marginTop: '5px', verticalAlign: 'text-bottom' }}
                        />
                        <span className="ml-1">Home</span>
                        <input
                          id="radio-button"
                          className="check"
                          onChange={this.onChange}
                          name="plan"
                          value="office"
                          checked={plan === 'office'}
                          type="radio"
                          style={{ marginTop: '5px', marginLeft: '10%', verticalAlign: 'text-bottom' }}
                        />
                        <span className="ml-1">Office</span>
                        <input
                          id="radio-button"
                          className="check"
                          checked={plan === 'enterprise'}
                          onChange={this.onChange}
                          name="plan"
                          value="enterprise"
                          type="radio"
                          style={{ marginTop: '5px', marginLeft: '10%', verticalAlign: 'text-bottom' }}
                        />
                        <span className="ml-1">Enterprise</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 mb-3 p-0">
                    <div className="col-12">
                      <label className="copy-font m-0 p-0">Address</label>
                      <textarea
                        rows="3"
                        cols="100"
                        name="address"
                        value={address}
                        className="form-control"
                        placeholder="Address"
                        onChange={this.onChange}
                      />
                    </div>
                  </div>

                  <div className="col-12 mb-3 p-0">
                    <div className="col-12">
                      <label className="copy-font m-0 p-0">Comment</label>
                      <textarea
                        rows="3"
                        cols="100"
                        name="comment"
                        value={comment}
                        className="form-control shadow-sm"
                        placeholder="Comment"
                        onChange={this.onChange}
                      />
                    </div>
                  </div>

                  {/* <div>
                                    <label className="copy-font">Assign To</label>
                                    <select
                                        className="form-control"
                                        onChange={this.onChange}
                                        name="department"
                                    >
                                        <option>Select Department</option>
                                        {/* { departments.map(department => {
                                            return <option key={department.id} value={department.id}>{department.name}</option>
                                        })}
                                    </select>
                                </div> */}
                  {/* </div> */}
                  {/* </div> */}
                </div>
                <div className="text-center mt-5">
                  {load ? (
                    <button type="button" className="btn btn-second" disabled>
                      Editing...
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-second">
                      Edit Lead
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

const mpaStateToProps = (state) => ({
  load: state.leads.loadForm,
  // states: state.states.states,
});

export default connect(mpaStateToProps, {
  editLead,
  // getStates
})(EditLead);
