import axios from 'axios';
import { toast } from 'react-toastify';
import { API_URL } from '../../../utils/config';
import {
  DELETE_JOB,
  GET_ACCOUNTS_JOBS,
  GET_CUSTOMER_SUPPORT_JOBS,
  GET_JOBS,
  GET_JOBS_BY_DEPARTMENT,
  GET_NEW_PRODUCTS_JOBS,
  GET_NOC_JOBS,
  GET_SALES_JOBS,
  GET_OPS_JOBS,
  GET_RESEARCH_JOBS,
  GET_PROCUREMENT_JOBS,
  GET_TECHSUPPORT_JOBS,
} from '../../../constants/types/shared/recruitment/PostingsTypes';
import {
  ACCOUNTS_JOB_URL,
  CUSTOMER_SUPPORT_JOB_URL,
  DELETE_JOB_URL,
  GET_JOB_BY_DEPARTMENT_URL,
  NEW_PRODUCTS_JOB_URL,
  NOC_JOB_URL,
  SALES_JOB_URL,
  OPS_JOB_URL,
  RESEARCH_JOB_URL,
  PROCUREMENT_JOB_URL,
  TECHSUPPORT_JOB_URL,
  ADD_POSTING_URL,
} from '../../../services/api/shared/recruitment/RecruitmentUrl';

export const getJobs = () => async (dispatch) => {
  try {
    const url = `${API_URL}/recruitment/listing`;
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    dispatch({
      type: GET_JOBS,
      payload: response.data,
    });
  } catch (e) {
    console.log(e.response);
  }
};

export const getJobsByDepartment = (departmentId) => async (dispatch) => {
  try {
    const response = await axios.get(`${GET_JOB_BY_DEPARTMENT_URL}/${departmentId}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    dispatch({
      type: GET_JOBS_BY_DEPARTMENT,
      payload: response.data,
    });
  } catch (e) {
    console.log(e.response);
  }
};

export const createPosting = (data) => async (dispatch) => {
  console.log(data);

  try {
    const response = await axios.post(`${ADD_POSTING_URL}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    console.log(response.data);

    if (response.data) {
      toast.success('Application Posted!');
      setTimeout(() => {
        window.location.reload();
      }, 2000);

      // toast.success("Application posted!", {
      //   onClose: function() {
      //     window.location.reload()
      //   },
      //   closeOnClick: function() {
      //     window.location.reload()
      //   }
      // })
    }
  } catch (e) {
    toast.info(e.response.data.message);
  }
};

export const getAccountsJobs = () => async (dispatch) => {
  try {
    const response = await axios.get(ACCOUNTS_JOB_URL, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data.logs) {
      dispatch({
        type: GET_ACCOUNTS_JOBS,
        payload: response.data,
      });
    }
  } catch (e) {}
};

export const getNocJobs = () => async (dispatch) => {
  try {
    const response = await axios.get(NOC_JOB_URL, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data.logs) {
      dispatch({
        type: GET_NOC_JOBS,
        payload: response.data,
      });
    }
  } catch (e) {}
};

export const getSalesJobs = () => async (dispatch) => {
  try {
    const response = await axios.get(SALES_JOB_URL, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    if (response.data.logs) {
      dispatch({
        type: GET_SALES_JOBS,
        payload: response.data,
      });
    }
  } catch (e) {
    console.log(e);
  }
};

export const getOpsJobs = () => async (dispatch) => {
  try {
    const response = await axios.get(OPS_JOB_URL, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    if (response.data.logs) {
      dispatch({
        type: GET_OPS_JOBS,
        payload: response.data,
      });
    }
  } catch (e) {}
};

export const getResearchJobs = () => async (dispatch) => {
  try {
    const response = await axios.get(RESEARCH_JOB_URL, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    if (response.data.logs) {
      dispatch({
        type: GET_RESEARCH_JOBS,
        payload: response.data,
      });
    }
  } catch (e) {}
};

export const getProcurementJobs = () => async (dispatch) => {
  try {
    const response = await axios.get(PROCUREMENT_JOB_URL, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data.logs) {
      dispatch({
        type: GET_PROCUREMENT_JOBS,
        payload: response.data,
      });
    }
  } catch (e) {}
};

export const getTechSupportJobs = () => async (dispatch) => {
  try {
    const response = await axios.get(TECHSUPPORT_JOB_URL, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    if (response.data.logs) {
      dispatch({
        type: GET_TECHSUPPORT_JOBS,
        payload: response.data,
      });
    }
  } catch (e) {}
};

export const getCustomerSupportJobs = () => async (dispatch) => {
  try {
    const response = await axios.get(`${CUSTOMER_SUPPORT_JOB_URL}/?page=1&location=gh`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data.logs) {
      dispatch({
        type: GET_CUSTOMER_SUPPORT_JOBS,
        payload: response.data,
      });
    }
  } catch (e) {}
};

export const getNewProductJobs = () => async (dispatch) => {
  try {
    const response = await axios.get(`${NEW_PRODUCTS_JOB_URL}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data.logs) {
      dispatch({
        type: GET_NEW_PRODUCTS_JOBS,
        payload: response.data,
      });
    }
  } catch (e) {}
};

export const deleteJob = (jobId) => async (dispatch) => {
  try {
    const response = await axios.delete(`${DELETE_JOB_URL}/${jobId}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      }
    });
  } catch (e) {}

  // dispatch({
  //
  //   type: DELETE_JOB
  //
  // })
};
