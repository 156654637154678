/* eslint-disable import/prefer-default-export */
import { useReducer } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import * as types from '../../../constants/types/shared/customers/payments/PaymentsTypes';
import { GENIATECH_GET_PAYMENT_HISTORY_URL } from '../../../services/api/shared/GeniatechUrl';
import { paymentsReducer } from '../../../reducers/shared/customers/paymentsReducer';

export const usePayments = () => {
  const [{ gettingPayments, payments }, dispatch] = useReducer(paymentsReducer, {
    payments: [],
    gettingPayments: false,
  });

  const getCustomerPayments = async (data) => {
    dispatch({
      type: types.START_FETCHING_CUSTOMER_PAYMENTS,
    });
    try {
      const response = await axios.post(`${GENIATECH_GET_PAYMENT_HISTORY_URL}`, data);
      if (response.data.items) {
        dispatch({
          type: types.FETCHED_CUSTOMER_PAYMENTS,
          payload: response.data.items,
        });
      } else {
        toast.info('Unable to get customer payments');

        dispatch({
          type: types.STOP_FETCHING_CUSTOMER_PAYMENTS,
        });
      }
    } catch (e) {
      if (e) {
        toast.error('Something went wrong while getting customer payments');
        dispatch({
          type: types.STOP_FETCHING_CUSTOMER_PAYMENTS,
        });
      }
    }
  };

  return {
    payments,
    getCustomerPayments,
    gettingPayments,
  };
};
