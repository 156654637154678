import React from 'react';
import { ToastContainer } from 'react-toastify';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import useCheckDepartment from '../../../../../hooks/shared/custom/check-department/field-support/useCheckDepartment';
import CreateAvailableTime from './CreateAvailableTime';
import FieldSupportNavBar from '../../../../layouts/field-support/FieldSupportNavBar';
import CreatedTimesBody from './CreatedTimesBody';

const AvailableInstallationTimes = ({ history }) => {
  const { profile } = useUser();
  const { firstName } = profile;
  useCheckDepartment(history);
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <FieldSupportNavBar currentPage="Available Installation Times" position={firstName} />
        <div className="col-12 after-summary">
          <div className="col-lg-12 col-md-12 col-sm-12 mb-3 d-flex mt-3 flex-wrap actions">
            <div className="action">
              <div className="mt-3">
                <button
                  className="btn btn-green"
                  type="button"
                  data-toggle="modal"
                  data-target="#create-available-time"
                >
                  Create Available Time
                </button>
              </div>
            </div>
            <CreateAvailableTime />
          </div>
          <CreatedTimesBody />
        </div>
      </main>
      <ToastContainer />
    </div>
  );
};

export default AvailableInstallationTimes;
