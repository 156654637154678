/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { ToastContainer } from 'react-toastify';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import '../../installations/leads-and-managers/index.css';
import AssetTeamNavBar from '../../../../layouts/asset-team/AssetTeamNavBar';
import { OverviewProvider } from '../../../../../context/shared/installations/overview/OverviewContext';
import CustomerSupportNavBar from '../../../../layouts/customer-support/CustomerSupportNavBar';
import { ASSET_TEAM, CUSTOMER_SUPPORT } from '../../../../../utils/departments/helpers';
import CustomerSupportInstallationOverviewBody from './CustomerSupportInstallationOverviewBody';

const CustomerSupportInstallationOverview = ({ history }) => {
  const departmentId = sessionStorage.getItem('departmentId');
  const { profile } = useUser();
  const { firstName } = profile;
  return (
    <div className="s-layout">
      {(departmentId === `${CUSTOMER_SUPPORT}` && (
        <>
          <OverviewProvider>
            <main className="s-layout__content col-lg-10 p-0">
              <CustomerSupportNavBar currentPage="Send Feedback" position={firstName} />
              <CustomerSupportInstallationOverviewBody />
            </main>
          </OverviewProvider>
          <ToastContainer />
        </>
      )) ||
        (departmentId === `${ASSET_TEAM}` && (
          <>
            <OverviewProvider>
              <main className="s-layout__content col-lg-10 p-0">
                <AssetTeamNavBar currentPage="Send Feedback" position={firstName} />
                <CustomerSupportInstallationOverviewBody />
              </main>
            </OverviewProvider>
            <ToastContainer />
          </>
        ))}
    </div>
  );
};
export default CustomerSupportInstallationOverview;
