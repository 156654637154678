/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useContext } from 'react';
import CustomButton from '../../../../../shared/forms/CustomButton';
import CustomInput from '../../../../../shared/forms/CustomInput';
import AppContext from '../../../../../../../context/shared/AppContext';
import Label from '../../../../../shared/forms/Label';

const AddCpuUtilization = () => {
  const { loadingForm, onChange, formValue, onSubmit } = useContext(AppContext);
  const { site, CPU } = formValue;
  const submitForm = () => {
    return site.length > 0 && CPU.length > 0;
  };
  return (
    <div id="add-access-point" className="modal fade mt-5" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header shadow">
            <h4 className="modal-title update_asset">CPU Utilization</h4>
            <button type="button" className="btn btn-outline-info btn-circle btn-close" data-dismiss="modal">
              x
            </button>
          </div>
          <div className="modal-body detail mb-4 p-0">
            <form
              className="col-md-12 p-0"
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              <div className="d-flex">
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="Site" />
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="site"
                    value={site}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="enter site"
                    type="text"
                    id="customerEmail"
                  />
                </div>
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="CPU" />
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="CPU"
                    value={CPU}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="e.g (PKI-006JFL)"
                    type="text"
                    id="customerEmail"
                  />
                </div>
              </div>

              <div className="text-center mt-3">
                {loadingForm ? (
                  <CustomButton classname="btn btn-green px-3" name="Adding..." disabled={loadingForm} />
                ) : (
                  <CustomButton classname="btn btn-green px-3" name="Add" disabled={!submitForm()} />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCpuUtilization;
