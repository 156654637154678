/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import useXWFRetailers from '../../../../../../hooks/express-wifi/retailers/useXWFRetailers';

const retailerCommentStatus = [
  'Banner',
  'Activation',
  'Slow internet',
  'Locked shop',
  'Promise to make payment',
  'Battery',
  'POE',
  'Inverter',
  'Radio Issues',
  'CNP Router',
  'Power issues',
  'High Account Balance(selling)',
  'High Account Balance(non-selling)',
  'Offline',
];

const AddRetailerComment = ({ retailerID, oneComment, operation }) => {
  const { addXWFRetailerComment, updateRetailerComment } = useXWFRetailers();

  const [one, setOne] = useState({ newComment: '', commentStatus: '' });

  const { newComment, commentStatus } = one;
  const { comment, comment_status } = oneComment;

  useEffect(() => {
    const result = {
      newComment: comment,
      commentStatus: comment_status,
    };
    setOne({ ...result });
  }, [comment, comment_status]);

  const onFormSubmit = (e) => {
    e.preventDefault();
    const data = {
      comment: newComment,
      comment_status: commentStatus,
    };
    if (operation === 'add') return addXWFRetailerComment(retailerID, data);
    return updateRetailerComment(retailerID, oneComment.id, data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOne({ ...one, [name]: value });
  };
  return (
    <div className="">
      <form className="p-0" onSubmit={onFormSubmit}>
        <div className="d-flex flex-wrap">
          <div className="form-group col-4">
            <label className="m-0 p-0 copy-font" htmlFor="commentStatus">
              Comment Status
            </label>
            <select
              id="commentStatus"
              className="form-control border border-info pl-3 py-2"
              name="commentStatus"
              value={commentStatus}
              onChange={handleChange}
            >
              <option value="">-- Select an option --</option>
              {retailerCommentStatus.map((oneStatus) => (
                <option value={oneStatus}>{oneStatus.toUpperCase()}</option>
              ))}
            </select>
          </div>
          <div className="form-group col-6">
            <label className="m-0 p-0 copy-font" htmlFor="newComment">
              Comment
            </label>
            <input
              type="text"
              className="form-control border border-info pl-3 py-2"
              id="newComment"
              name="newComment"
              value={newComment}
              onChange={handleChange}
            />
          </div>
          <div className="m-auto pt-2">
            <button type="submit" className="btn btn-green px-3" disabled={!(commentStatus && newComment)}>
              {operation === 'update' ? 'Update' : 'Add Comment'}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddRetailerComment;
