/* eslint-disable react/require-default-props */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { stringify } from 'qs';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';

const PaymentsHistory = ({ load, transactions, searchWithDate, customer }) => {
  const searchState = { from: '', to: '' };
  const [search, setSearch] = useState(searchState);
  const onChange = (e) => {
    const { name, value } = e.target;
    setSearch({ ...search, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    searchWithDate(
      stringify({
        macid: customer.username,
        action: 'getDebtorTransPaginated',
        totalRec: '10',
        page_no: '1',
        country: customer.country || 'Nigeria',
        from: search.from,
        to: search.to,
      }),
    );
  };
  const payments =
    transactions && transactions.length ? (
      transactions.map((transaction, i) => (
        <tr>
          <td>{i + 1}</td>
          <td>{transaction.Trans_no}</td>
          <td>{transaction.Tran_date}</td>
          <td>{transaction.Charge ? transaction.Charge : transaction.Payment}</td>
          <td>{transaction.Description}</td>
          <td>{transaction.Reference}</td>
        </tr>
      ))
    ) : (
      <p>No transaction</p>
    );

  return (
    <div
      id="payment-history"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog mt-3">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Payment History
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body">
            <form className="d-flex col-lg-12" onSubmit={onSubmit}>
              <div className="col-lg-5 d-flex p-0">
                <label className="mt-2 copy-font m-0 p-0">From:</label>
                <input name="from" value={search.from} onChange={onChange} type="date" className="form-control ml-2" />
              </div>

              <div className="col-lg-5 d-flex">
                <label className="mt-2 copy-font m-0 p-0">To:</label>
                <input name="to" value={search.to} onChange={onChange} type="date" className="form-control ml-2" />
              </div>

              <div className="col-lg-1">
                <button className="btn btn-success">View</button>
              </div>
            </form>
            <table className="col-lg-12 ml-2 mt-2">
              <tr>
                <th>#</th>
                <th>Transaction ID</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Description</th>
                <th>Reference</th>
              </tr>
              {load ? <img alt="gif" src={loader} /> : payments}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

PaymentsHistory.propTypes = {
  load: PropTypes.bool,
  transactions: PropTypes.shape(),
};

export default PaymentsHistory;
