import React from 'react';
import LteCustomersSearch from './LteCustomersSearch';
import LteCustomersTable from './LteCustomersTable';
import LteCustomerProvider from './provider/LteCustomerProvider';

const LteCustomersBody = () => {
  return (
    <LteCustomerProvider>
      <div className="mt-5 px-3">
        <div className="container-fluid pt-5">
          <div className="row">
            <div className="p-0">
              <div className="col-12 d-flex">
                <LteCustomersSearch />
              </div>
            </div>
            <div className="card x-panel mt-2 px-0">
              <LteCustomersTable />
            </div>
          </div>
        </div>
      </div>
    </LteCustomerProvider>
  );
};

export default LteCustomersBody;
