/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import toastr from 'toastr';
import ResearchAndDevelopmentSideBar from '../../../../layouts/research-and-development/ResearchAndDevelopmentSideBar';
import Logout from '../../../shared/auth/Logout';
// import Map from '../../../shared/vehicle-tracking/Map';
// import logo from '../../../../../assets/img/logo/Tizeti-Logo.png';
import { API_URL } from '../../../../../utils/config';
import AssignVehicle from '../../../shared/vehicle-tracking/AssignVehicle';
import Mailblast from '../../../../../assets/img/icons/mailblast.svg';

class VehicleTracking extends Component {
  constructor(props) {
    super(props);

    this.state = {
      devices: [],
      id: '',
      deviceId: '',
      locations: [],
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      infoWindow: {},
      user: {},
      role: '',
    };

    this.getDevice = this.getDevice.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {
    this.fetchLoggedOnUser();
  }

  componentDidMount() {
    this.getDevices();
    this.getLocations();
    this.getUser();
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  getDevice = (e) => {
    e.preventDefault();
    const data = {
      device: this.state.id,
    };

    // console.log(data);
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };

    const url = `${API_URL}/tracker/trackone/${this.state.id}?page=1`;
    // console.log(url);

    axios.get(url, config).then((res) => {
      if (res.data.rows) {
        this.setState(
          {
            locations: res.data.rows,
          },
          this.renderMap(),
        );
      }
      // console.log(res.data);
    });
  };

  getLocations() {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };

    axios.get(`${API_URL}/tracker/tracks/1`, config).then((res) => {
      // console.log(res.data);

      this.setState(
        {
          locations: res.data,
        },
        this.renderMap(),
      );
    });
  }

  getUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employee/loggedOnEmployee`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        this.setState({
          user: data.data,
        });
      })
      .catch((err) => {});
  }

  getDevices() {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };

    axios.get(`${API_URL}/tracked_device/trackingdevices/1`, config).then((res) => {
      // console.log(res.data.rows);
      if (res.data.rows) {
        this.setState({
          devices: res.data.rows,
        });
      }
    });
  }

  renderMap = () => {
    // eslint-disable-next-line no-use-before-define
    loadScript(
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyD1DrDBUd6GNL2EIBCxK-K0OjkTny8kbuA&libraries=places&callback=initMap',
    );
    window.initMap = this.initMap;
  };

  initMap7 = () => {
    const map = new window.google.maps.Map(document.getElementById('map'), {
      zoom: 3,
      center: { lat: 0, lng: -180 },
      mapTypeId: 'terrain',
    });

    const flightPlanCoordinates = [
      { lat: 37.772, lng: -122.214 },
      { lat: 21.291, lng: -157.821 },
      { lat: -18.142, lng: 178.431 },
      { lat: -27.467, lng: 153.027 },
    ];
    const flightPath = new window.google.maps.Polyline({
      path: flightPlanCoordinates,
      geodesic: true,
      strokeColor: '#FF0000',
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });

    flightPath.setMap(map);
  };

  initMap = () => {
    const map = new window.google.maps.Map(document.getElementById('map'), {
      zoom: 11,
      center: { lat: 6.548653, lng: 3.54882 },
      mapTypeId: window.google.maps.MapTypeId.TERRAIN,
    });

    // var flightPlanCoordinates = [
    //     this.state.locations.map(
    //       (location) => (
    //         {
    //             lat: Number(location.latitude),
    //             lng: Number(location.longitude)
    //         }
    //       )
    //     )
    // ];
    // console.log(flightPlanCoordinates);

    const flightPlanCoordinatesss = [];
    this.state.locations.forEach((location) => {
      flightPlanCoordinatesss.push({
        lat: Number(location.latitude),
        lng: Number(location.longitude),
      });
    });

    // var flightPlanCoordinatess = [
    //
    //   {lat: 6.51349, lng: 3.37044},
    //   {lat: 6.46486, lng: 3.29633}
    //
    // ];
    //
    // console.log(flightPlanCoordinatess);

    const flightPath = new window.google.maps.Polyline({
      path: flightPlanCoordinatesss,
      geodesic: true,
      strokeColor: '#FF0000',
      strokeOpacity: 4.0,
      strokeWeight: 2,
    });

    flightPath.setMap(map);
  };

  initMapp = () => {
    const map = new window.google.maps.Map(document.getElementById('map'), {
      center: { lat: 6.548653, lng: 3.54882 },
      zoom: 11,
      maxZoom: 0, // 18
      minZoom: 0, // 16
      zoomControlOptions: {
        style: window.google.maps.ZoomControlStyle.SMALL,
      },
      // mapTypeId: 'terrain',
      scaleControl: true, // enable scale control
      mapTypeId: window.google.maps.MapTypeId.HYBRID, // google map type
    });

    const infowindow = new window.google.maps.InfoWindow();

    // eslint-disable-next-line array-callback-return
    this.state.locations.map((basestation) => {
      // console.log(basestation);

      const contentString = basestation.id;

      // var planCords = [
      //
      //     { lat: Number(basestations.latitude),
      //         lng: Number(basestations.longitude)
      //     }
      //
      //     ];
      // console.log(planCords);
      //
      // var path = new window.google.maps.Polyline({
      //     path: planCords,
      //     geodesic: true,
      //     strokeColor: '#FF0000',
      //     strokeOpacity: 1.0,
      //     strokeWeight: 2,
      //     map: map
      // });

      const marker = new window.google.maps.Marker({
        position: {
          lat: Number(basestation.latitude),
          lng: Number(basestation.longitude),
        },
        map,
        // icon: 'https://res.cloudinary.com/tizeti/image/upload/v1542971012/Group-1.png',
        icon: 'http://www.my9ja.com/estatewifi/icons/pin_green.png',
        title: basestation.id,
        // animation: window.google.maps.Animation.BOUNCE
      });

      const circle = new window.google.maps.Circle({
        map,
        radius: 1500, // 1.5 kilometeres in metres

        fillColor: '#00FF00',
        // strokeColor: '#FF0000',
        strokeOpacity: 0.2,
        strokeWeight: 0.12,
        fillOpacity: 0.25,
      });

      circle.bindTo('center', marker, 'position');

      marker.addListener('click', () => {
        // chnage content
        infowindow.setContent(contentString);
        infowindow.open(map, marker);
      });

      // path.setMap(map);
    });
  };

  // initMap = () => {
  //
  //     var map = new window.google.maps.Map(document.getElementById('map'), {
  //         center: {
  //             lat: 6.548653,
  //             lng: 3.548820
  //         },
  //         zoom: 11,
  //         maxZoom: 0, // 18
  //         minZoom: 0, // 16
  //         mapTypeId: 'terrain'
  //
  //     });
  //
  //     for(let i = 0; i < 2; i++){
  //         console.log(this.state.locations[i]);
  //         const path = new window.google.maps.Polyline({
  //             path: this.state.locations[i],
  //             geodesic: true,
  //             strokeColor: '#FF0000',
  //             strokeOpacity: 1.0,
  //             strokeWeight: 4,
  //         });
  //         path.setMap(map);
  //
  //     }
  //
  // };

  showDevices() {
    if (this.state.devices) {
      return (
        <select className="form-control" onChange={this.onChange} name="id">
          <option value="">Select Device</option>
          {this.state.devices.map((device, i) => (
            <option key={device.id} value={device.id}>
              {device.phoneNumber}
            </option>
          ))}
        </select>
      );
    }
    return null;
  }

  fetchLoggedOnUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employeeReg/logged`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        this.setState({
          role: data.data.role_id,
        });

        if (data.data.department_id !== '22dfe612-9f8b-4183-a75e-81a33595aa46') {
          sessionStorage.setItem('data', '');
          sessionStorage.clear();
          this.setState({
            out: true,
          });

          toastr.info('You are not allowed to view this page');
        }
      })
      .catch((err) => {});
  }

  render() {
    const { role, user } = this.state;
    const { firstName } = user;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <Link className="s-sidebar__trigger" to="#">
                <i className="fa fa-bars mr-3" />
              </Link>
              <ResearchAndDevelopmentSideBar role={role} />

              <h3 className="col-lg-6 overview-part">Vehicle Tracking</h3>

              <div className="d-flex profile-mail">
                {role > '2' ? (
                  <Link
                    to="/research-and-development/mail-blast"
                    className="d-md-flex mr-4 d-none"
                    style={{
                      color: 'gray',
                      textDecoration: 'none',
                    }}
                  >
                    <img src={Mailblast} alt="" className="logout mr-2" />
                    <span>
                      <h6 className="mt-2">
                        <strong>MailBlast</strong>
                      </h6>
                    </span>
                  </Link>
                ) : null}
                <h6 className="admin mt-2">
                  <i>{firstName}</i>
                  <Logout />
                </h6>
              </div>
            </div>
          </div>
          <div className="after-summary col-12">
            <ResearchAndDevelopmentSideBar role={role} />
            <div className="row">
              <div className="container-fluid col-lg-12">
                <div className="col-lg-12" style={{ padding: '17px' }}>
                  <div className="form-inline" action="#">
                    <div className="col-12 mb-3 p-0 d-flex">
                      <form className="col-lg-5 form-inline d-flex" onSubmit={this.getDevice}>
                        <div className="mr-auto">
                          <label className="copy-font m-0 p-0">Driver's Name</label>
                          {this.showDevices()}
                        </div>

                        {/* <div className="mr-auto"> */}
                        {/* <label className="copy-font m-0 p-0">Date of Trip</label> */}
                        {/* <input type="date" className="form-control"/> */}
                        {/* </div> */}

                        <div className="mr-auto">
                          <button type="button" className="btn btn-green p-2 mt-3">
                            Show route
                          </button>
                        </div>
                      </form>

                      <div className="tab-stuff col-7 p-0 mt-4 justify-content-end d-flex">
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                          <li className="nav-item  mr-4">
                            <a
                              className="nav-link active"
                              id="pills-profile-tab"
                              data-toggle="pill"
                              href="#pills-profile"
                              role="tab"
                              aria-controls="pills-profile"
                              aria-selected="true"
                            >
                              View All Vehicles{' '}
                            </a>
                          </li>
                          <li className="nav-item" data-target="#assignvehicle" data-toggle="modal">
                            <a
                              className="nav-link"
                              id="pills-home-tab"
                              data-toggle="pill"
                              href="#"
                              role="tab"
                              aria-controls="pills-home"
                              aria-selected="false"
                            >
                              Assign Vehicles
                            </a>
                          </li>
                          <AssignVehicle />
                        </ul>
                      </div>
                    </div>

                    <div className="tab-content col-12" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                      >
                        <div className="col-lg-12 m-0 p-0">
                          <div className="mapouter">
                            <div className="gmap_canvas">
                              <div id="map" style={{ height: '550px' }} />
                              {/* <iframe width="1145" height="600" id="gmap_canvas" src="https://maps.google.com/maps?q=Lagos&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe> */}
                            </div>
                          </div>

                          {/* <div className="col-lg-2 ml-2 p-0" style={{marginTop:"-44%"}}> */}
                          {/* <div className="card shadow border-0"> */}
                          {/* <div style={{backgroundColor:"#1a3f43"}} className="shadow-sm"> */}
                          {/* <h6 className="text-white pl-3 mt-2">Profile</h6> */}
                          {/* </div> */}
                          {/* <div className="p-3" style={{marginTop:"-7%"}}> */}
                          {/* <div> */}
                          {/* <label className="m-0 p-0 copy-font">Drivers Name</label> */}
                          {/* <p className="copy-font mb-1">Bassey Udoh</p> */}
                          {/* </div> */}
                          {/* <div> */}
                          {/* <label className="m-0 p-0 copy-font">Current Location</label> */}
                          {/* <p className="copy-font mb-1">Plot 24, Lekki Ajah expressway, Lagos.</p> */}
                          {/* </div> */}
                          {/* <div> */}
                          {/* <label className="m-0 p-0 copy-font">Vehicle Name</label> */}
                          {/* <p className="copy-font mb-1">Toyota Hiace</p> */}
                          {/* </div> */}
                          {/* <div> */}
                          {/* <label className="m-0 p-0 copy-font">Plate Number</label> */}
                          {/* <p className="copy-font mb-1">AZ 403 VTU</p> */}
                          {/* </div> */}
                          {/* </div> */}
                          {/* </div> */}

                          {/* <div className="card shadow border-0 mt-3"> */}
                          {/* <div style={{backgroundColor:"#1a3f43"}} className="shadow-sm"> */}
                          {/* <h6 className="text-white pl-3 mt-2">Route</h6> */}
                          {/* </div> */}
                          {/* <div className="p-3" style={{marginTop:"-7%"}}> */}
                          {/* <div> */}
                          {/* <label className="m-0 p-0 copy-font">From</label> */}
                          {/* <p className="copy-font mb-1">34, Mushin way, Isolo</p> */}
                          {/* </div> */}
                          {/* <div> */}
                          {/* <label className="m-0 p-0 copy-font">To</label> */}
                          {/* <p className="copy-font mb-1">23, Montegomery road, Yaba</p> */}
                          {/* </div> */}
                          {/* <div> */}
                          {/* <label className="m-0 p-0 copy-font">Date</label> */}
                          {/* <p className="copy-font mb-1">24th June,2018</p> */}
                          {/* </div> */}
                          {/* </div> */}
                          {/* </div> */}
                          {/* </div> */}
                        </div>
                      </div>
                      {/* <div className="tab-pane fade" id="pills-home" role="tabpanel" aria-labelledby="pills-profile-tab">
                                              <AssignVehicle/>
                                          </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

function loadScript(url) {
  const index = window.document.getElementsByTagName('script')[0];
  const script = window.document.createElement('script');
  script.src = url;
  script.async = true;
  script.defer = true;
  index.parentNode.insertBefore(script, index);
}

export default VehicleTracking;
