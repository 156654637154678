/* eslint-disable react/require-default-props */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const CustomerUsageTable = ({ value }) => {
  return (
    <Fragment>
      {value === undefined
        ? null
        : value.map((val, i) => (
            <tr>
              <td>{i + 1}</td>
              <td>{val.uploaded}</td>
              <td>{val.downloaded}</td>
              <td>{val.from}</td>
              <td>{val.to}</td>
              <td>{val.total_duration}</td>
              <td>{val.connection_counts}</td>
            </tr>
          ))}
    </Fragment>
  );
};

CustomerUsageTable.propTypes = {
  value: PropTypes.shape(),
};
export default CustomerUsageTable;
