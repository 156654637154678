import React, { useContext } from 'react';
import CambiumLicenseKeysTable from './CambiumLicenseKeysTable';
import AddCambiumLicenseKeys from './AddCambiumLicenseKeys';
import CustomPagination from '../../../../shared/CustomPagination';
import AppContext from '../../../../../../context/shared/AppContext';

const CambiumLicenseKeysBody = () => {
  const { loading, paginateChange, pages } = useContext(AppContext);
  const pageChange = (params) => {
    const { selected } = params;
    paginateChange(selected + 1);
  };
  return (
    <div className="container">
      <div className="row mt-5">
        <div className="mt-4 col-lg-12 col-md-12 col-sm-12">
          <div className="container">
            <div className="row mt-5">
              {/* <SearchCnMaestro /> */}
              <div className="form-group ml-2">
                <button className="btn btn-green" type="button" data-toggle="modal" data-target="#add-access-point">
                  Add Cambium License Key
                </button>
              </div>
              <AddCambiumLicenseKeys />
              <CambiumLicenseKeysTable />
              {!loading && (
                <CustomPagination
                  pagesCount={pages}
                  marginPagesDisplayed={5}
                  pageRangeDisplayed={5}
                  onPageChange={pageChange}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CambiumLicenseKeysBody;
