import React from 'react';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import PartnershipNavBar from '../../../../layouts/partnership/PartnershipNavBar';
import SalesLogs from '../../sales/logs/SalesLogs';

const PartnershipLogs = () => {
  const { user, profile } = useUser();

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <div className="d-flex col-lg-12 p-2 top-header">
          <PartnershipNavBar currentPage="Logs" profileImage={profile.firstName} />
        </div>
        <SalesLogs />
      </main>
    </div>
  );
};

export default PartnershipLogs;
