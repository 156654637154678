/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
import React, { Component } from 'react';
import Calendar from 'react-calendar';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { toast, ToastContainer } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { SCHEDULE_USER_URL } from '../../../../services/api/shared/installations/SchedulerUrl';
import { getZones } from '../../../../actions/shared/zones/ZonesActions';
import {
  getAvailableTimes,
  getCategoryForInstallations,
  getSubZones,
  scheduleInstallation,
} from '../../../../actions/shared/installations/SchedulerActions';
import { ghanaCities } from '../../../../utils/states/ghana';

class ScheduleUser extends Component {
  static propTypes = {
    getZones: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      id: '',
      address: '',
      state: '',
      reference: '',
      zone: '',
      category: '',
      subzone: '',
      date: new Date(),
      // available: '',
      submit: false,
      country: '',
    };

    this.createSchedule = this.createSchedule.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
  }

  componentDidMount() {
    this.props.getCategoryForInstallations();
    this.props.getZones();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state) {
      if (nextProps.location.state.detail.address) {
        this.setState({
          address: nextProps.location.state.detail.address,
          id: nextProps.location.state.detail.id,
        });
      }
    }
  }

  onDateChange = (date) => {
    this.setState({ date });
    const { subzone } = this.state;
    if (subzone && date) {
      const data = {
        date: moment(date).format('YYYY-MM-DD'),
        sub_zone_id: subzone,
      };
      // this.props.getAvailableTimes(data);
    }
    return null;
  };

  createSchedule = async (e) => {
    e.preventDefault();
    this.setState({
      submit: true,
    });
    const { address, state, date, reference, id, category, zone, subzone, country } = this.state;
    const data = {
      address,
      state,
      date: moment(date).format('YYYY-MM-DD'),
      payment_reference: reference,
      user_id: id.length > 0 ? id : this.props.location.state.detail.id,
      zone_id: zone,
      category_id: category,
      sub_zone_id: subzone,
      country,
    };
    const { history } = this.props;

    try {
      const response = await axios.post(`${SCHEDULE_USER_URL}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.id) {
        toast.success('Scheduling Successful!');
        setTimeout(() => {
          history.goBack();
        }, 2000);
        // dispatch(getLeads)
      }

      if (response.data.status === 'failed') {
        toast.info(response.data.message);
        this.setState({ submit: false });
      }
    } catch (err) {
      if (err || err.response) {
        const { message, status } = err.response.data;
        toast.info(`${typeof message === 'object' ? `${status.toUpperCase()}!` : message}`);
        this.setState({ submit: false });
      }
    }
  };

  subZoneChange = (e) => {
    this.setState({
      subzone: e.target.value,
    });
    const { date } = this.state;
    if (e.target.value && date) {
      const data = {
        date: moment(date).format('YYYY-MM-DD'),
        sub_zone_id: e.target.value,
      };
      // this.props.getAvailableTimes(data);
    }
    return null;
  };

  onFormChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  onChange = (date) => this.setState({ date });

  getSubZone = (e) => {
    const { value, name } = e.target;
    if (e.target.value) {
      this.props.getSubZones(value);
      this.setState({
        [name]: value,
      });
    }
  };

  submitForm() {
    const { address, reference, zone, category, country, state } = this.state;
    return (
      address.length > 0 &&
      reference.length > 0 &&
      zone.length > 0 &&
      country.length > 0 &&
      state.length > 0 &&
      category.length > 0
    );
  }

  render() {
    const { zones, subzones, categories, loadSub, loadTime, location } = this.props;
    const { state } = location;
    const { reference, submit, date, address, country } = this.state;
    console.log(this.props.location);

    return (
      <div className="modal-thing bg-white">
        <div>
          <div className="mx-auto col-lg-6 col-md-9 mt-3 p-0">
            <div className="modal-title">
              <h3 className="col-10 text-center">Schedulers</h3>
              {/* <small onClick={closeModal}><strong className="mr-3">X</strong></small> */}
            </div>

            <div className="col-12">
              <form className="form-horizontal" onSubmit={this.createSchedule}>
                <div className="form-group">
                  {state ? (
                    <div className="col-md-12 mb-3 d-flex p-0">
                      <div className="col-lg-6 text-left">
                        <label className="copy-font m-0 p-0">First Name</label>
                        <input
                          type="text"
                          name="firstName"
                          className="form-control"
                          placeholder="First Name"
                          onChange={this.onFormChange}
                          value={state.detail.firstName}
                          readOnly
                        />
                      </div>
                      <div className="col-lg-6 text-left">
                        <label className="copy-font m-0 p-0">Last Name</label>
                        <input
                          type="text"
                          name="lastName"
                          className="form-control"
                          placeholder="Last Name"
                          onChange={this.onFormChange}
                          value={state.detail.lastName}
                          readOnly
                        />
                      </div>
                    </div>
                  ) : null}

                  {state ? (
                    <div className="col-md-12 mb-3 d-flex p-0">
                      <div className="col-lg-6 text-left">
                        <label className="copy-font m-0 p-0">Email</label>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder="Email Address"
                          onChange={this.onFormChange}
                          value={state.detail.email}
                          readOnly
                        />
                      </div>

                      <div className="col-lg-6 text-left">
                        <label className="copy-font m-0 p-0">Phone number</label>
                        <input
                          type="text"
                          name="phoneNumber"
                          className="form-control"
                          onChange={this.onFormChange}
                          value={state.detail.phoneNumber}
                          readOnly
                          required
                        />
                      </div>
                    </div>
                  ) : null}

                  <div className="col-md-12 mb-3 d-flex p-0">
                    {state ? (
                      <div className="col-lg-12 text-left">
                        <label className="copy-font m-0 p-0">Address</label>
                        <input
                          type="text"
                          className="form-control border"
                          name="address"
                          placeholder="Address"
                          onChange={this.onFormChange}
                          value={address}
                          required
                        />
                      </div>
                    ) : null}
                  </div>

                  <div className="col-md-12 mb-3 d-flex p-0">
                    <div className="col-lg-6 text-left">
                      <label className="copy-font m-0 p-0">Category</label>
                      <select name="category" className="form-control" onChange={this.onFormChange} required>
                        <option value="">Select Category</option>
                        {categories
                          ? categories.map((category) => <option value={category.id}>{category.name}</option>)
                          : null}
                      </select>
                    </div>

                    <div className="col-lg-6 text-left">
                      <label className="copy-font m-0 p-0">Payment Reference</label>
                      <input
                        type="text"
                        name="reference"
                        className="form-control border"
                        onChange={this.onFormChange}
                        required
                        value={reference}
                      />
                    </div>
                  </div>

                  <div className="col-md-12 mb-3 d-flex p-0">
                    <div className="col-lg-6 text-left">
                      <label className="copy-font m-0 p-0">Zone</label>
                      <select className="form-control" onChange={this.getSubZone} id="zoneId" required name="zone">
                        <option value="">--Select Zone--</option>
                        {zones
                          ? zones.map((zone) => {
                              return (
                                <option value={zone.id} key={zone.id} id={zone.id}>
                                  {zone.name.toUpperCase()}
                                </option>
                              );
                            })
                          : null}
                      </select>
                    </div>

                    <div className="col-lg-6 text-left">
                      <div className="col-lg-12 text-left p-0">
                        <label className="copy-font m-0 p-0">Sub zone</label>
                        <select
                          className="form-control"
                          onChange={this.subZoneChange}
                          id="subzoneId"
                          required
                          name="subzone"
                        >
                          <option value="">--Select Subzone--</option>
                          {loadSub ? (
                            <option>loading subzones...</option>
                          ) : (
                            subzones.map((subzone) => {
                              return (
                                <option value={subzone.id} key={subzone.id} id={subzone.id}>
                                  {subzone.name.toUpperCase()}
                                </option>
                              );
                            })
                          )}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mb-3 d-flex p-0">
                    <div className="col-lg-6 text-left">
                      <label className="copy-font m-0 p-0">Country</label>
                      <select className="form-control" name="country" onChange={this.onFormChange} required>
                        <option value="">--Select Country--</option>
                        <option value="ng">Nigeria</option>
                        <option value="gh">Ghana</option>
                      </select>
                    </div>

                    <div className="col-lg-6 text-left">
                      <label className="copy-font m-0 p-0">State</label>
                      <select className="form-control" onChange={this.onFormChange} name="state" required>
                        <option value="">--Select {country === 'gh' ? 'City' : 'State'}--</option>
                        {country === 'gh' ? (
                          ghanaCities.map((city) => (
                            <option key={city} value={city}>
                              {city}
                            </option>
                          ))
                        ) : (
                          // : states.map((stat) => {
                          //     return <option value={stat.name}>{stat.name}</option>;
                          //   })}
                          <>
                            <option value="lagos">Lagos</option>
                            <option value="ogun">Ogun</option>
                            <option value="rivers">Rivers</option>
                            <option value="edo">Edo</option>
                            <option value="oyo">Oyo</option>
                          </>
                        )}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-12 mb-3 d-flex p-0">
                    <div className="text-left col-lg-12">
                      <label className="copy-font m-0 p-0">Date</label>
                      <div className="mb-3 p-0">
                        <Calendar onChange={this.onDateChange} value={date} className="form-control reactcal" />
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-md-12 mb-3 d-flex p-0">
                    {loadTime ? (
                      <div className="col-lg-6 text-left">
                        <small>loading time</small>
                      </div>
                    ) : available.length ? (
                      <div className="col-lg-6 text-left">
                        <label className="copy-font m-0 p-0">Select Available Time</label>
                        <select
                          className="form-control"
                          name="available"
                          id="timeId"
                          onChange={this.onFormChange}
                          required
                        >
                          <option value="">--Select Time--</option>
                          {available.map((av) => {
                            return <option value={av.id}>{av.time}</option>;
                          })}
                        </select>
                      </div>
                    ) : (
                      ''
                    )}
                  </div> */}
                </div>

                <div className="direction text-center pb-4">
                  {submit ? (
                    <button className="btn btn-green" disabled={submit}>
                      Scheduling...
                    </button>
                  ) : (
                    <button className="btn btn-green" disabled={!this.submitForm()}>
                      Schedule Installation
                    </button>
                  )}
                </div>
              </form>
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  load: state.scheduler.load,
  zones: state.scheduler.zones,
  categories: state.scheduler.categories,
  subzones: state.scheduler.subzones,
  // available: state.scheduler.times,
  loadSub: state.scheduler.loadSub,
  loading: state.scheduler.loadSchedule,
  status: state.scheduler.message,
  // states: state.states.states,
  loadTime: state.scheduler.loadTime,
});

export default connect(mapStateToProps, {
  // getStates,
  scheduleInstallation,
  getCategoryForInstallations,
  getZones,
  getSubZones,
  // getAvailableTimes,
})(withRouter(ScheduleUser));
