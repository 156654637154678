import React from 'react';
import Tracker from './Tracker';
import TrackerProvider from './provider/TrackerProvider';

const CustomersTrackerBody = () => {
  return (
    <div className="container">
      <TrackerProvider>
        <Tracker />
      </TrackerProvider>

      {/* <h1>
        under construction
      </h1>
      */}
    </div>
  );
};

export default CustomersTrackerBody;
