import React from 'react';
import SharedSupportCalls from '../../../shared/support-calls/SharedSupportCalls';
import AssetTeamNavBar from '../../../../layouts/asset-team/AssetTeamNavBar';
import { useUser } from '../../../../../hooks/shared/user/useUser';

const AssetSupportCalls = () => {
  const { profile } = useUser();
  const { firstName } = profile;
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 ">
        <AssetTeamNavBar currentPage="Support Calls" position={firstName} />
        <div className="pt-5 mt-5">
          <SharedSupportCalls />
        </div>
      </main>
    </div>
  );
};

export default AssetSupportCalls;
