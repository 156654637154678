import React, { Component } from 'react';
import '../../../../styles/style.css';
import '../../../../styles/sales_overview.css';
import axios from 'axios';
import toastr from 'toastr';
import { Link } from 'react-router-dom';
import NewProductSideBar from '../../../layouts/new-products/NewProductsSideBar';
import tech from '../../../../assets/img/icons/tech-customer.fw.png';
import book from '../../../../assets/img/icons/book-tech.fw.png';
import gift from '../../../../assets/img/icons/gift-tech.fw.png';
import profile from '../../../../assets/img/icons/Profile.png';
import mailblast from '../../../../assets/img/icons/mailblast.svg';
import ViewUserProfile from '../../shared/staff/ViewUserProfile';
import Logout from '../../shared/auth/Logout';
import { API_URL } from '../../../../utils/config';
import { NEW_PRODUCTS, SUPER_ADMIN } from '../../../../utils/departments/helpers';
import CreateProfilePrompt from '../../shared/CreateProfilePrompt';

class NewProductOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      move: false,
      out: false,
      redirect: false,
      role: '',
    };
  }

  componentWillMount() {
    this.fetchLoggedOnUser();
    this.getUser();
  }

  componentWillReceiveProps() {
    this.fetchLoggedOnUser();
  }

  getUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employee/loggedOnEmployee`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        this.setState({
          user: data.data,
        });
      })
      .catch((err) => {});
  }

  fetchLoggedOnUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employeeReg/logged`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        this.setState({
          role: data.data.role_id,
        });

        if (data.data.department_id !== `${NEW_PRODUCTS}` && data.data.department_id !== `${SUPER_ADMIN}`) {
          sessionStorage.setItem('data', '');
          sessionStorage.clear();
          this.setState({
            out: true,
          });

          toastr.info('You are not allowed to view this page');
        }
      })
      .catch((err) => {});
  }

  render() {
    const { role } = this.state;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <Link className="s-sidebar__trigger" to="#">
                <i className="fa fa-bars mr-3" />
              </Link>
              <NewProductSideBar role={role} />

              <h3 className="col-lg-6 overview-part">Overview</h3>

              <div className="d-flex profile-mail">
                <Link
                  to="/new-products/mail-blast"
                  className="d-none d-md-flex"
                  style={{ color: 'gray', textDecoration: 'none' }}
                >
                  <img src={mailblast} alt="" className="logout mr-2" />
                  <span>
                    <h6 className="mt-2 mr-3">
                      <strong>MailBlast</strong>
                    </h6>
                  </span>
                </Link>
                <Link to="#" data-toggle="modal" data-target="#add-employee">
                  <img src={profile} alt="" className="logout mt-1 mr-3" />
                </Link>
                <Logout />
                <CreateProfilePrompt />
              </div>
            </div>
          </div>

          <div className="col-12 after-summary">
            <ViewUserProfile />
            <div className="row">
              <div className="col-lg-12 p-0">
                <div className="card-body col-lg-12">
                  <div className="form-inline" action="javascript:void(0)" style={{ width: '100%' }}>
                    <div className="col-lg-12 d-flex justify-content-end p-0">
                      <p className="sales-opt copy-font col-lg-4 d-flex justify-content-end p-0">
                        <small className="mt-2 mr-2"> Filter By:</small>
                        <span>
                          <select className="form-control">
                            <option>Month</option>
                          </select>
                        </span>

                        <span>
                          <select className="form-control">
                            <option>Year</option>
                          </select>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 side d-flex">
                <div className="col-lg-2">
                  <div className="col-lg-12">
                    <div className="boxes" id="boxx">
                      <div className="text-center mt-2">
                        <img src={book} alt="" width="25%" />
                      </div>
                      <div className="count">2 </div>
                      <h3 style={{ color: '#3BB427', fontWeight: 'bold' }}>Total Products</h3>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="boxes">
                      <div className="text-center mt-4">
                        <img src={gift} width="25%" alt="homepage" />
                      </div>
                      <div className="count">3</div>
                      <h3 style={{ color: '#F37705', fontWeight: 'bold' }}>New Products</h3>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="boxes">
                      <div className="text-center mt-4">
                        <img src={tech} alt="" width="25%" />
                      </div>
                      <div className="count">3</div>
                      <h3 style={{ color: '#673FBB' }}>
                        <b>Team Members</b>
                      </h3>
                    </div>
                  </div>
                </div>

                <div className="col-lg-10">
                  <div className="card-group mt-1">
                    <div className="card o-income">
                      <div className="card-body">
                        <div className="d-flex m-b-30 no-block ml-2">
                          <h4 className="card-title m-b-0 align-self-center">Revenue by product</h4>
                        </div>

                        <div className="d-flex col-lg-12 graph">
                          <div className="colors d-flex col-lg-12" style={{ justifyContent: 'flex-end' }}>
                            <p className="color" style={{ backgroundColor: '#99265D' }} />
                            <p className="copy-font">WifiCall</p>
                            <p className="color-dif" style={{ backgroundColor: '#14B0B0' }} />
                            <p className="copy-font"> Hotspot Retailer</p>
                          </div>
                        </div>

                        <div id="RD" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default NewProductOverview;
