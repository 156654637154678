import React from 'react';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import CustomerSupportSideBar from '../../../../layouts/customer-support/CustomerSupportSideBar';
import AssetTeamSideBar from '../../../../layouts/asset-team/AssetTeamNavBar';
import Logout from '../../../shared/auth/Logout';
import { Link } from 'react-router-dom';
import PageName from '../../../shared/layouts/PageName';
import CustomisationInstallationReportBody from './CustomerInstallationReportBody';
import { ASSET_TEAM, CUSTOMER_SUPPORT } from '../../../../../utils/departments/helpers';

const CustomisationInstallationReport = () => {
  const { profile, user } = useUser();
  const { firstName } = profile;
  const departmentId = sessionStorage.getItem('departmentId');
  return (
    <div>
      <div className="s-layout">
        {(departmentId === `${CUSTOMER_SUPPORT}` && (
          <main className="s-layout__content col-lg-10 p-0">
            <div className="d-flex col-lg-12 p-2 top-header">
              <div className="mt-3 d-flex col-lg-12 p-0">
                <Link className="s-sidebar__trigger" to="#">
                  <i className="fa fa-bars mr-3" />
                </Link>
                <CustomerSupportSideBar />

                <PageName name="Installations" />

                <div className="d-flex profile-mail">
                  <h6>
                    <i className="mr-3">{firstName}</i>
                    <Logout />
                  </h6>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row mt-5">
                <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-4">
                  <div className="">
                    <CustomisationInstallationReportBody />
                  </div>
                </div>
              </div>
            </div>
          </main>
        )) ||
          (departmentId === `${ASSET_TEAM}` && (
            <main className="s-layout__content col-lg-10 p-0">
              <div className="d-flex col-lg-12 p-2 top-header">
                <div className="mt-3 d-flex col-lg-12 p-0">
                  <Link className="s-sidebar__trigger" to="#">
                    <i className="fa fa-bars mr-3" />
                  </Link>
                  <AssetTeamSideBar currentPage="Installations" roleID={user.role_id} position={firstName} />

                  <PageName name="Installations" />

                  <div className="d-flex profile-mail">
                    <h6>
                      <i className="mr-3">{firstName}</i>
                      <Logout />
                    </h6>
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="row mt-5">
                  <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-4">
                    <div className="">
                      <CustomisationInstallationReportBody />
                    </div>
                  </div>
                </div>
              </div>
            </main>
          ))}
      </div>
    </div>
  );
};

export default CustomisationInstallationReport;
