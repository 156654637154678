import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import { initialActiveUsersPerAssetZone } from './ActiveExpiredUsersByAssetZone';
import { tizeti_asset_active_inactive_users } from '../../../../../../actions/coo-statistics/ActiveExpiredTurboUsers';
import { toast } from 'react-toastify';

const DemoPaper = styled(Paper)(({ theme }) => ({
  width: 170,
  height: 170,
  padding: theme.spacing(2),
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'space-evenly',
  justifyContent: 'center',
}));

export default function InsightsForActiveInactiveUser({
  getZones,
  getAllActiveUsersCountValue,
  getAllInActiveUsersCountValue,
}) {
  const [getTotalActiveUsersCount, setGetTotalActiveUsersCount] = useState(null);
  const [getTotalExpiredUsersCount, setGetTotalExpiredUsersCount] = useState(null);
  const [isTotalActiveLoading, setIsTotalActiveLoading] = useState(false);
  const [isTotalExpiredLoading, setIsTotalExpiredLoading] = useState(false);
  const [highestActiveUsers, setHighestActiveUsers] = useState(null);
  const [highestInactiveUsers, setHighestInactiveUsers] = useState(null);
  const [stateWithHighestActiveUsers, setStateWithHighestActiveUsers] = useState(null);
  const [stateWithHighestInactiveUsers, setStateWithHighestInactiveUsers] = useState(null);

  let getRemainingProperties = ({ zone, ...rest }) => rest;

  useEffect(() => {
    const fetchTotalActiveUsers = async () => {
      try {
        setIsTotalActiveLoading(true);
        const parameters = getRemainingProperties(initialActiveUsersPerAssetZone);
        const res = await tizeti_asset_active_inactive_users(parameters);
        // console.log(res);
        return res.data[0].count;
      } catch (error) {
        toast.error('unable to load active users count');
      } finally {
        setIsTotalActiveLoading(false);
      }
    };
    const fetchTotalExpiredUsers = async () => {
      try {
        setIsTotalExpiredLoading(true);
        const parameters = getRemainingProperties(initialActiveUsersPerAssetZone);
        const res = await tizeti_asset_active_inactive_users({ ...parameters, type: 'expiration' });
        // console.log(res);
        return res.data[0].count;
      } catch (error) {
        toast.error('unable to load Expired users count');
      } finally {
        setIsTotalExpiredLoading(false);
      }
    };

    const fetchData = async () => {
      setGetTotalActiveUsersCount(await fetchTotalActiveUsers());
      setGetTotalExpiredUsersCount(await fetchTotalExpiredUsers());
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (
      Array.isArray(getAllActiveUsersCountValue) &&
      Array.isArray(getAllInActiveUsersCountValue) &&
      getAllActiveUsersCountValue !== undefined &&
      getAllInActiveUsersCountValue !== undefined &&
      getAllActiveUsersCountValue.length !== undefined &&
      getAllInActiveUsersCountValue.length !== undefined &&
      getAllActiveUsersCountValue.length > 0 &&
      getAllInActiveUsersCountValue.length > 0
    ) {
      const HighestActiveUsers = Math.max(...getAllActiveUsersCountValue);
      const HighestInactiveUsers = Math.max(...getAllInActiveUsersCountValue);
      setHighestActiveUsers(HighestActiveUsers);
      setHighestInactiveUsers(HighestInactiveUsers);

      const findZoneWithHighestActiveUsers = async (type) => {
        try {
          if (getZones.length > 0) {
            if (type === 'active') {
              const res = await getAllActiveUsersCountValue.findIndex((val) => val === HighestActiveUsers);

              if (res !== undefined) {
                setStateWithHighestActiveUsers(getZones[res].name);
                return getZones[res].name;
              }
            } else if (type === 'expired') {
              const res = await getAllInActiveUsersCountValue.findIndex((val) => val === HighestInactiveUsers);
              if (res !== undefined) {
                setStateWithHighestInactiveUsers(getZones[res].name);
                return getZones[res].name;
              }
            }
          }
        } catch (error) {
          toast.error('unable to find highest active users');
        }
      };
      findZoneWithHighestActiveUsers('active');
      findZoneWithHighestActiveUsers('expired');
    }
  }, [getAllActiveUsersCountValue, getAllInActiveUsersCountValue]);

  return (
    <div>
      <Box display="flex" justifyContent="flex-start" gap={'1rem'} sx={{ mt: 1.5 }}>
        <DemoPaper square={false} sx={{ background: '#8884d8', color: 'white' }}>
          <Typography sx={{ fontWeight: 'bolder' }}>Total Active Users</Typography>
          <Divider flexItem />
          <Typography variant="h3" sx={{ color: 'white' }}>
            {isTotalActiveLoading ? '...' : getTotalActiveUsersCount}
          </Typography>
        </DemoPaper>
        <DemoPaper square={false} sx={{ background: '#aad884', color: 'white' }}>
          <Typography sx={{ fontWeight: 'bolder' }}>Total Expired Users</Typography>
          <Divider flexItem />
          <Typography variant="h3" sx={{ color: 'white' }}>
            {isTotalExpiredLoading ? '...' : getTotalExpiredUsersCount}
          </Typography>
        </DemoPaper>
        <DemoPaper square={false} sx={{ background: '#d89584', color: 'white' }}>
          <Typography sx={{ fontWeight: 'bolder' }}>Total Active & Expired Users</Typography>
          <Divider flexItem />
          <Typography variant="h3" sx={{ color: 'white' }}>
            {getTotalActiveUsersCount === null && getTotalExpiredUsersCount === null
              ? '...'
              : getTotalExpiredUsersCount + getTotalActiveUsersCount}
          </Typography>
        </DemoPaper>
        <DemoPaper square={false} sx={{ background: '#d8d784', color: 'white' }}>
          <Typography sx={{ fontWeight: 'bolder' }}>Zone with Highest Active users</Typography>
          <Divider flexItem />
          <Typography variant="h3" sx={{ color: 'white' }}>
            {highestActiveUsers === null ? '...' : highestActiveUsers}
          </Typography>
          <Typography variant="div" sx={{ color: 'white' }}>
            {stateWithHighestActiveUsers === null ? '...' : stateWithHighestActiveUsers}
          </Typography>
        </DemoPaper>
        <DemoPaper square={false} sx={{ background: '#8485d8', color: 'white' }}>
          <Typography sx={{ fontWeight: 'bolder' }}>Zone with Highest Expired users</Typography>
          <Divider flexItem />
          <Typography variant="h3" sx={{ color: 'white' }}>
            {highestInactiveUsers === null ? '...' : highestInactiveUsers}
          </Typography>
          <Typography variant="div" sx={{ color: 'white' }}>
            {stateWithHighestInactiveUsers === null ? '...' : stateWithHighestInactiveUsers}
          </Typography>
        </DemoPaper>
      </Box>
    </div>
  );
}
