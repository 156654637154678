import React, { useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import ManagersRequisitionBody from '../../../../shared/requisition/managers/ManagersRequisitionBody';
import RequisitionContext from '../../../../../../context/shared/requisitions/RequsitionContext';

const GhanaRequisitionsTable = () => {
  const { isFetching } = useContext(RequisitionContext);

  return (
    <div className="card x-panel logs">
      <div className="table-responsive content">
        <table className="table copy-font">
          <thead>
            <tr>
              <th>#</th>
              <th>Amount In Digits</th>
              <th className="text-nowrap">Amount In Words</th>
              <th>Accounts Approval Status </th>
              <th>Manager Approval Status </th>
              <th>CEO/COO LEVEL Approval Status </th>
              <th className="text-nowrap">In Favor Of</th>
              <th>Requires C Level Approval?</th>
              <th>Requires CEO/COO Level Approval?</th>
              <th className="text-nowrap">Created At</th>
              <th>Purpose</th>
              <th>Approve</th>
              <th>Reject</th>
              <th>View Uploaded File</th>
              <th>Status</th>
              <th>View Details</th>
            </tr>
          </thead>

          {isFetching ? (
            <tbody>
              <tr>
                <td>
                  <small>loading...</small>
                </td>
              </tr>
            </tbody>
          ) : (
            <ManagersRequisitionBody />
          )}
        </table>
      </div>
      <ToastContainer />
    </div>
  );
};

export default GhanaRequisitionsTable;
