import axios from 'axios';
export const get_total_installation_growth = async (selectedYear, monthIndex, lastDayOfMonth, selectedCountry) => {
  const initialDateClicked = {
    from: `${selectedYear}-${monthIndex}-01`,
    to: `${selectedYear}-${monthIndex}-${lastDayOfMonth}`,
  };

  const initialExpirationState = {
    status: 'completed',
    country: selectedCountry === 'Nigeria' ? 'ng' : 'gh',
    ...initialDateClicked,
  };
  const formData = new URLSearchParams(initialExpirationState).toString();
  try {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    };
    const res = await axios.post('https://api.tizeti.com:8443/api/v1/searchinstallation/1', formData, {
      headers: headers,
    });
    if (res.data) {
      // console.log(res.data);
      return res.data;
    }
  } catch (error) {
    console.error(error);
  }
  // console.log(isLoading);
};
