/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import usePost from '../../../../../../hooks/shared/custom/data/geniatech/usePost';
import { LEGACY_GENIATECH_URL } from '../../../../../../services/api/shared/installations/InstallationsUrl';
import CustomInput from '../../../forms/CustomInput';
import InstallationsPerDateRangeTable from './InstallationsPerDateRangeTable';

const InstallationsPerDateRange = () => {
  const { loading, data, getData, getDataLegacy } = usePost();

  const [installs, setInstalls] = useState({
    totalInstall: 0,
    lists: [],
  });
  const [search, setSearch] = useState({
    fromDate: `${new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10)}`,
    toDate: `${new Date(new Date().getTime()).toISOString().slice(0, 10)}`,
  });

  const { fromDate, toDate } = search;

  const params = {
    action: 'tizeti_asset_installed_days_range',
    from: fromDate,
    to: toDate,
  };

  useEffect(() => {
    getDataLegacy(`${LEGACY_GENIATECH_URL}`, params);
  }, []);

  useEffect(() => {
    if (data.installed_per_basestation_dynamic) {
      const {
        installed_per_basestation_dynamic: { items, total_installed },
      } = data;
      setInstalls({ totalInstall: total_installed, lists: items });
    }
  }, [data.installed_per_basestation_dynamic]);

  const handleDate = (e) => {
    const { name, value } = e.target;
    setSearch({ ...search, [name]: value });
  };

  const handleSearchWithDate = (e) => {
    e.preventDefault();
    getDataLegacy(`${LEGACY_GENIATECH_URL}`, params);
  };

  return (
    <div className="container pt-5">
      <div className="row mt-5">
        <div className="col-12">
          <form onSubmit={handleSearchWithDate} className="d-flex">
            <div className="d-flex mt-3 mr-5 copy-font">
              <small className="mr-1 mt-2 copy-font">From:</small>
              <CustomInput
                placeholder=""
                readOnly={false}
                type="date"
                classname="form-control"
                name="fromDate"
                value={fromDate}
                onChange={handleDate}
              />
              <small className="mr-1 ml-3 mt-2 copy-font">To:</small>
              <CustomInput
                placeholder=""
                readOnly={false}
                type="date"
                classname="form-control"
                name="toDate"
                value={toDate}
                onChange={handleDate}
              />
            </div>
            <div>
              <button type="submit" className="btn btn-green mt-3">
                Search
              </button>
            </div>
          </form>
        </div>
        <InstallationsPerDateRangeTable loading={loading} installs={installs} />
      </div>
    </div>
  );
};

export default InstallationsPerDateRange;
