/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import CustomerSupportNavBar from '../../../../layouts/customer-support/CustomerSupportNavBar';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import DelistedCustomersBody from './DelistedCustomersBody';

const CustomerSupportDelistedCustomers = () => {
  const { profile, user } = useUser();
  const { firstName } = profile;
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <CustomerSupportNavBar currentPage="Decommission DB" roleID={user.role_id} position={firstName} />
        <DelistedCustomersBody />
      </main>
    </div>
  );
};

export default CustomerSupportDelistedCustomers;
