/* eslint-disable import/prefer-default-export */
import {
  FETCHING_STARTS,
  FETCHING_STOPS,
  LOADING_STARTS,
  LOADING_STOPS,
} from '../../../constants/types/shared/LoadingTypes';
import {
  LOADING_ADDRESS_STARTS,
  LOADING_ADDRESS_STOPS,
  LOADING_EMAIL_STARTS,
  LOADING_EMAIL_STOPS,
  LOADING_NAME_STARTS,
  LOADING_NAME_STOPS,
  LOADING_PHONE_STARTS,
  LOADING_PHONE_STOPS,
} from '../../../constants/types/field-support/FieldSupportTypes';
import {
  CLEANING_STARTS,
  CLEANING_STOPS,
  GET_UNVERIFIED_CUSTOMERS,
} from '../../../constants/types/shared/customers/CustomerTypes';

export const customersReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOADING_STARTS:
      return {
        ...state,
        isLoading: true,
      };

    case LOADING_STOPS:
      return {
        ...state,
        isLoading: false,
      };

    case LOADING_EMAIL_STARTS:
      return {
        ...state,
        isLoadingEmail: true,
      };

    case LOADING_EMAIL_STOPS:
      return {
        ...state,
        isLoadingEmail: false,
      };

    case LOADING_NAME_STARTS:
      return {
        ...state,
        isLoadingName: true,
      };

    case LOADING_NAME_STOPS:
      return {
        ...state,
        isLoadingName: false,
      };

    case LOADING_PHONE_STARTS:
      return {
        ...state,
        isLoadingPhone: true,
      };

    case LOADING_PHONE_STOPS:
      return {
        ...state,
        isLoadingPhone: false,
      };

    case LOADING_ADDRESS_STARTS:
      return {
        ...state,
        isLoadingAddress: true,
      };

    case LOADING_ADDRESS_STOPS:
      return {
        ...state,
        isLoadingAddress: false,
      };

    case CLEANING_STARTS:
      return {
        ...state,
        cleaning: true,
      };

    case CLEANING_STOPS:
      return {
        ...state,
        cleaning: false,
      };

    case FETCHING_STARTS:
      return {
        ...state,
        fetching: true,
      };

    case FETCHING_STOPS:
      return {
        ...state,
        fetching: false,
      };

    case GET_UNVERIFIED_CUSTOMERS:
      return {
        ...state,
        unverified: payload.users,
        fetching: false,
        pages: payload.pages,
      };

    default:
      return state;
  }
};
