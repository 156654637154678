/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import PendingTableHead from './PendingTableHead';
import RetailerDetails from '../RetailerDetails';
import EditRetailer from '../../ui-components/EditRetailers';
import useRetailers from '../../../../../../../../hooks/new-products/products/hotspot/retailers/useRetailers';

const PendingTable = ({ pending, fetch }) => {
  const {
    activateRetailer,
    getRetailer,
    fetchingRetailer,
    retailer,
  } = useRetailers();
  const pendingList =
    pending && pending.length ? (
      pending.map((pend, i) => (
        <tr className="copy-font">
          <th scope="row">{i + 1}</th>
          <td>{pend.businessName}</td>
          <td>{pend.accountid}</td>
          <td>{pend.email}</td>
          <td>{pend.phone}</td>
          <td>{pend.created}</td>
          <td>
            <span
              className="badge badge-success"
              style={{ backgroundColor: '#F37705' }}
            >
              Pending
            </span>
          </td>
          <td className="text-center activate-check">
            <img
              src="https://res.cloudinary.com/teewhy/image/upload/v1548846540/check-admin.fw.png"
              alt="checked sign"
              onClick={() =>
                activateRetailer(pend.accountid)
              }
            />
          </td>
          <td className="shift">
            <div className="d-flex ml-3">
              <button
                type="submit"
                data-toggle="modal"
                data-target="#hotspot-details"
                onClick={() => getRetailer(pend.accountid)}
                style={{
                  border: '0',
                  backgroundColor: 'white',
                }}
              >
                <span className="view">
                  <img
                    src="https://res.cloudinary.com/tizeti/image/upload/v1542971020/view.png"
                    alt="view"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Edit"
                  />
                </span>
              </button>

              <RetailerDetails
                retailer={retailer}
                load={fetchingRetailer}
              />

              <button
                type="submit"
                data-toggle="modal"
                data-target="#edit-retailer"
                // onClick={()=>this.getRetailer(item.accountid) }
                style={{
                  border: '0',
                  backgroundColor: 'white',
                }}
              >
                <span className="view">
                  <img
                    src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/edit.png"
                    alt="edit"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Edit"
                  />
                </span>
              </button>

              <EditRetailer
                businessName=""
                email=""
                fullname=""
                phone=""
                address=""
                created=""
                status=""
                accountid=""
                balance=""
                lat=""
                lng=""
              />
            </div>
          </td>
        </tr>
      ))
    ) : (
      <small> there are no pending retailers...</small>
    );
  return (
    <div
      className="tab-pane fade show active"
      id="pills-home"
      role="tabpanel"
      aria-labelledby="pills-home-tab"
    >
      <table className="table">
        <PendingTableHead />
        <tbody>
          {fetch ? <small>loading...</small> : pendingList}
        </tbody>
      </table>
    </div>
  );
};

PendingTable.propTypes = {
  fetch: PropTypes.bool,
  pending: PropTypes.shape(),
};
export default PendingTable;
