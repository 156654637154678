/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { API_URL } from '../../../../../utils/config';

class UpdateAccessPoints extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ssid: '',
      ip: '',
      radio: '',
      frequency: '',
      https: '',
      country: '',
      username: '',
      password: '',
      id: '',
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      id: nextProps.one.id,
      ssid: nextProps.one.ap_ssid,
      ip: nextProps.one.ip_address,
      radio: nextProps.one.radio_type,
      frequency: nextProps.one.frequency,
      https: nextProps.one.https,
      country: nextProps.one.country,
      username: nextProps.one.username,
      password: nextProps.one.password,
    });
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const {
      ssid,
      ip,
      radio,
      frequency,
      https,
      country,
      username,
      password,
      id,
    } = this.state;

    const data = {
      ap_ssid: ssid,
      ip_address: ip,
      https,
      radio_type: radio,
      frequency,
      country,
      username,
      password,
    };
    const url = `${API_URL}/accessPoints/editPoints/${id}`;
    axios({
      method: 'patch',
      data,
      url,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem(
          'token',
        )}`,
      },
    }).then((res) => {
      if (res.data) {
        toast.success('Access Point updated!', {
          onClose() {
            window.location.reload();
          },
        });
      }
    });
  };

  render() {
    const {
      ssid,
      ip,
      radio,
      frequency,
      https,
      country,
      username,
      password,
    } = this.state;

    return (
      <div className="modal-body text-left">
        <form
          onSubmit={this.onSubmit}
          className="form-horizontal form-material"
        >
          <div className="form-group invent">
            <div className="col-md-12 mb-3 d-flex">
              <div className="col-lg-6">
                <label className="copy-font">AP SSID</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="AP SSID"
                  name="ssid"
                  value={ssid}
                  onChange={this.onChange}
                />
              </div>

              <div className="col-lg-6">
                <label className="copy-font">
                  IP Address
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Ip Address"
                  name="ip"
                  value={ip}
                  onChange={this.onChange}
                />
              </div>
            </div>

            <div className="col-md-12 mb-3 d-flex">
              <div className="col-lg-6">
                <label className="copy-font">
                  Radio Type
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Radio Type"
                  name="radio"
                  value={radio}
                  onChange={this.onChange}
                />
              </div>

              <div className="col-lg-6">
                <label className="copy-font">
                  Frequency
                </label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Frequency"
                  name="frequency"
                  value={frequency}
                  onChange={this.onChange}
                />
              </div>
            </div>

            <div className="col-md-12 mb-3 d-flex">
              <div className="col-lg-6">
                <label className="copy-font">Https</label>
                <div className="d-flex">
                  <input
                    className="mt-1"
                    type="radio"
                    name="https"
                    value={https}
                    onChange={this.onChange}
                  />
                  <p
                    className="copy-font ml-2 mr-2 mt-1"
                    style={{ fontSize: '14px' }}
                  >
                    Yes
                  </p>
                  <input
                    className="mt-1"
                    type="radio"
                    name="https"
                    value={https}
                    onChange={this.onChange}
                  />
                  <p
                    className="copy-font ml-2 mt-1"
                    style={{ fontSize: '14px' }}
                  >
                    No
                  </p>
                </div>
              </div>

              <div className="col-lg-6">
                <label className="copy-font">Country</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Country"
                  name="country"
                  value={country}
                  onChange={this.onChange}
                />
              </div>
            </div>

            <div className="col-md-12 mb-3 d-flex">
              <div className="col-lg-6">
                <label className="copy-font">
                  Username
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Username"
                  name="username"
                  value={username}
                  onChange={this.onChange}
                />
              </div>

              <div className="col-lg-6">
                <label className="copy-font">
                  Password
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Password"
                  name="password"
                  value={password}
                  onChange={this.onChange}
                />
              </div>
            </div>
          </div>
          <div className="text-center">
            <button className="btn btn-second">
              Update
            </button>
          </div>
        </form>
      </div>
    );
  }
}

UpdateAccessPoints.propTypes = {
  one: PropTypes.shape(),
};
export default UpdateAccessPoints;
