/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-shadow */
/* eslint-disable react/require-default-props */
/* eslint-disable no-undef */
/* eslint-disable react/button-has-type */
/* eslint-disable no-case-declarations */
import React from 'react';
import UsageHistoryTable from '../../../shared/usage-history/UsageHistoryTable';
import useSearch from '../../../../../hooks/shared/custom/data/geniatech/useSearch';
import { GET_CUSTOMER_DATA_HISTORY_URL } from './api/UsageHistoryUrl';
import CustomCsvDownload from '../../../shared/CustomCsvDownload';
import { filename, usageHistoryHeaders } from '../../../../../utils/field-support/installations/helpers';
import CustomInput from '../../../shared/forms/CustomInput';

const UsageHistoryBody = () => {
  const { loadingForm, onChange, formValue, onSubmit, data } = useSearch(
    `${GET_CUSTOMER_DATA_HISTORY_URL}`,
    {
      action: 'get_usage_history',
      mac_id: '',
      from: '',
      to: '',
    },
    'Fetched customer usage history',
    'Unable to get customer data',
  );
  const { from, to, mac_id } = formValue;
  const customer = data.usage_history;
  const onFormSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12 mt-3 mb-3">
          <form className="form-inline mt-4 inner-addon d-flex" onSubmit={onFormSubmit}>
            <div className="form-group mx-2">
              <CustomInput
                classname="form-control mr-auto empty pl-5"
                name="mac_id"
                value={mac_id}
                placeholder="Enter MAC ID"
                onChange={onChange}
                readOnly={false}
                type="text"
              />

              <span className="fa fa-search" />
            </div>
            <div className="form-group mx-2">
              <label className="mt-2 p-0 copy-font">From: </label>
              <CustomInput
                classname="form-control mr-2"
                name="from"
                value={from}
                onChange={onChange}
                readOnly={false}
                type="date"
              />

              <label className="mt-2 p-0 copy-font">To: </label>
              <CustomInput
                classname="form-control mr-2"
                name="to"
                value={to}
                onChange={onChange}
                readOnly={false}
                type="date"
              />
            </div>

            <div className="mb-3">
              <button className="btn btn-green" style={{ height: '39px' }}>
                Search
              </button>
            </div>
          </form>
          <div className="col-12 d-flex mt-2">
            <CustomCsvDownload
              data={customer || []}
              headers={usageHistoryHeaders}
              filename={filename(`Usage_History`)}
              classname="btn btn-green shadow-md"
              textcolor="white"
              text="Download Data"
            />
          </div>
        </div>
      </div>
      <UsageHistoryTable customer={customer} load={loadingForm} />
    </div>
  );
};

export default UsageHistoryBody;
