import {
  accounts,
  API_URL,
  customer_support,
  NEW_PRODUCTS,
  NOC,
  operations,
  PROCUREMENT,
  RESEARCH_AND_DEVELOPMENT,
  SALES,
  TECHNICAL_SUPPORT,
} from '../../../../utils/config';

export const DELETE_JOB_URL = `${API_URL}/recruitment/listing`;
export const GET_JOBS_URL = `${API_URL}/recruitment/listing`;
export const ACCOUNTS_JOB_URL = `${API_URL}/recruitment/listing/${accounts}`;
export const GET_JOB_BY_DEPARTMENT_URL = `${API_URL}/recruitment/listing`;
export const CUSTOMER_SUPPORT_JOB_URL = `${API_URL}/recruitment/listing/${customer_support}`;
export const NEW_PRODUCTS_JOB_URL = `${API_URL}/recruitment/listing/${NEW_PRODUCTS}`;
export const NOC_JOB_URL = `${API_URL}/recruitment/listing/${NOC}`;
export const SALES_JOB_URL = `${API_URL}/recruitment/listing/${SALES}`;
export const OPS_JOB_URL = `${API_URL}/recruitment/listing/${operations}`;
export const RESEARCH_JOB_URL = `${API_URL}/recruitment/listing/${RESEARCH_AND_DEVELOPMENT}`;
export const PROCUREMENT_JOB_URL = `${API_URL}/recruitment/listing/${PROCUREMENT}`;
export const TECHSUPPORT_JOB_URL = `${API_URL}/recruitment/listing/${TECHNICAL_SUPPORT}`;
export const ADD_POSTING_URL = `${API_URL}/recruitment/listing`;
