import React from 'react';
import AssetTeamNavBar from '../../../../../layouts/asset-team/AssetTeamNavBar';
import { useUser } from '../../../../../../hooks/shared/user/useUser';
import ExpiredCustomerGraph from '../../../../shared/customers/expired-customers/total/ExpiredCustomerGraph';

const AssetTeamExpiredCustomers = () => {
  const { profile } = useUser();
  const { firstName } = profile;
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <AssetTeamNavBar currentPage="Expired Customer" position={firstName} />
        <div className="mt-5">
          <ExpiredCustomerGraph />
        </div>
      </main>
    </div>
  );
};

export default AssetTeamExpiredCustomers;
