import React from 'react';
import BusinessDevelopmentNavBar from '../../../../layouts/business-development/BusinessDevelopmentNavBar';
import DelistedCustomersBody from '../../customer-support/delisted-customers/DelistedCustomersBody';

const BusinessDevelopmentDelistedCustomers = () => {
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <BusinessDevelopmentNavBar currentPage="Delisted Customers" />
        <DelistedCustomersBody />
      </main>
    </div>
  );
};

export default BusinessDevelopmentDelistedCustomers;
