/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import BusinessDevelopmentConnectedCustomersTable from './BusinessDevelopmentConnectedCustomersTable';
import useConnectedDedicatedCustomers from '../../../../../hooks/business-development/connected-dedicated-customers/useConnectedDedicatedCustomers';

const BusinessDevelopmentConnectedCustomers = ({ request, request: { basestation } }) => {
  const { getConnectedDedicatedCustomers, customers, isLoading } = useConnectedDedicatedCustomers();
  //   const [page] = useState(1);

  // const basestation = basestation_status;
  const [one, setOne] = useState({
    action: 'Connected_Dedicated_Customers',
    basestation_status: '',
  });
  const { basestation_status } = one;

  // const staffEmail = `${profile.email}`;

  useEffect(() => {
    const result = {
      basestation_status: basestation,
    };
    setOne({ ...result });
  }, [basestation, request]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOne({ ...one, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const data = qs.stringify({
      action: 'Connected_Dedicated_Customers',
      basestation: basestation_status,
    });
    getConnectedDedicatedCustomers(data);
  };

  return (
    <div id="updateDedicated" className="modal fade" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header shadow">
            <h4 className="modal-title" id="myModalLabel">
              {' '}
              Connected Dedicated Users
              <button type="button" className="close mr-2" data-dismiss="modal">
                x
              </button>
            </h4>
          </div>
          <div className="modal-body">
            <form onSubmit={onSubmit} className="form-horizontal form-material">
              <div className="form-group invent">
                <div className="col-md-12 mb-3 d-flex">
                  <div className="col-lg-6">
                    <label className="copy-font" htmlFor="basestation">
                      Basestation
                    </label>
                    <input
                      name="basestation_status"
                      className="form-control"
                      value={basestation_status}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="text-center">
                  {isLoading ? (
                    <button type="button" className="btn btn-green py-2 px-5" disabled>
                      Submitting...
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-green py-2 px-5">
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </form>
            <BusinessDevelopmentConnectedCustomersTable customers={customers} isLoading={isLoading} />
          </div>
        </div>
      </div>
    </div>
  );
};

BusinessDevelopmentConnectedCustomers.propTypes = {
  request: PropTypes.objectOf.isRequired,
};

export default BusinessDevelopmentConnectedCustomers;
