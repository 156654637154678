import { useReducer } from 'react';
import qs from 'qs';
import axios from 'axios';
import toastr from 'toastr';
import * as load from '../../constants/types/shared/LoadingTypes';
import { XWFRetailersReducer } from '../../reducers/express-wifi/retailers/XWFRetailersReducer';
import { XWF_LEGACY_GENIATECH_URL, TERMII_SMS_URL } from '../../services/api/express-wifi/ExpressWifiURL';

const useExpressWifiSMS = () => {
  const [{ isLoading }, dispatch] = useReducer(XWFRetailersReducer, { isLoading: false });

  const sendBulkSMS = async (data) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(`${XWF_LEGACY_GENIATECH_URL}`, JSON.stringify(data), {
        headers: { 'Content-Type': 'application/json' },
      });
      if (response.data.status === true) {
        toastr.success('Bulk SMS Sent!');
      } else toastr.info('Error Sending Bulk SMS...');
    } catch (e) {
      toastr.info('Error Sending Bulk SMS...');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const sendSingleSMS = async (data) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(`${TERMII_SMS_URL}`, qs.stringify(data), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Cookie: 'termii-sms=ITMC15HL9tCE4B9DexpZbbQUeBTBH2sZtvgMrQb7',
        },
      });
      if (response.data.status === true) {
        toastr.success('SMS Sent!');
      } else toastr.info('Error Sending SMS...');
    } catch (e) {
      if (e.response.data) toastr.info(e.response.data.message);
      else toastr.info('Error Sending SMS...');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  return { isLoading, sendBulkSMS, sendSingleSMS };
};

export default useExpressWifiSMS;
