/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import CustomerSupportNavBar from '../../../../layouts/customer-support/CustomerSupportNavBar';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import ActiveAndInactiveBody from '../../asset-team/active-inactive/ActiveAndInactiveBody';

const CustomerSupportActiveAndInactiveCustomers = () => {
  const { profile, user } = useUser();
  const { firstName } = profile;
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <CustomerSupportNavBar currentPage="Active And Inactive Customers" roleID={user.role_id} position={firstName} />
        <ActiveAndInactiveBody profile={profile} />
      </main>
    </div>
  );
};

export default CustomerSupportActiveAndInactiveCustomers;
