import { GET_DATA } from '../../../../../../../constants/types/shared/CustomTypes';
import { LOADING_STARTS, LOADING_STOPS, REFETCH } from '../../../../../../../constants/types/shared/LoadingTypes';

const subBasestationReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_DATA:
      return {
        ...state,
        loading: false,
        data: payload,
        // pages: payload.pages,
      };
    case LOADING_STARTS:
      return {
        ...state,
        loading: true,
      };
    case LOADING_STOPS:
      return {
        ...state,
        loading: false,
      };
    case REFETCH:
      return {
        ...state,
        data: [payload, ...state.data],

        loading: false,
        //     pages: state.pages,
      };
    default:
      return state;
  }
};
export default subBasestationReducer;
