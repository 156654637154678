import axios from 'axios';
import { toast } from 'react-toastify';
import qs from 'qs';
import * as types from '../../../constants/types/shared/customers/CustomerDetailsTypes';
import {
  GENIATECH_GET_USER_AUTH,
  GENIATECH_GET_USER_BY_NAME_URL,
  GENIATECH_GET_USER_SESSION_URL,
  GENIATECH_GET_USER_WITH_PHONE_OR_MAC_URL,
  GENIATECH_NEW_GET_USER_PASSWORD_URL,
  GENIATECH_USER_INFORMATION_URL,
  SEARCH_FOR_CUSTOMER_BY_REFRENCE_URL,
} from '../../../services/api/shared/GeniatechUrl';

export const searchForUserByEmail = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.START_FETCHING_CUSTOMER,
      payload: true,
    });

    try {
      const response = await axios.post(`${GENIATECH_USER_INFORMATION_URL}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data === 'customer info could not be retreived') {
        dispatch({
          type: types.STOP_FETCHING_CUSTOMER,
          payload: false,
        });

        toast.info('No user found!');
      } else {
        dispatch({
          type: types.FETCHED_CUSTOMER,
          payload: response.data,
        });
      }
    } catch (e) {
      if (e) {
        dispatch({
          type: types.STOP_FETCHING_CUSTOMER,
          payload: false,
        });
      }
    }
  };
};

export const searchByReference = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.START_FETCHING_CUSTOMER,
      payload: true,
    });

    try {
      const response = await axios.post(
        `${SEARCH_FOR_CUSTOMER_BY_REFRENCE_URL}`,
        qs.stringify(data),
        // ,
        //  {
        //   headers: {
        //     Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        //   },
        // }
      );

      if (response.data === 'customer info could not be retreived') {
        toast.info('No user found!');
        dispatch({
          type: types.STOP_FETCHING_CUSTOMER,
          payload: false,
        });
      } else {
        dispatch({
          type: types.FETCHED_CUSTOMER,
          payload: response.data,
        });
      }
    } catch (e) {
      toast.info('Something went wrong!');
      if (e) {
        dispatch({
          type: types.STOP_FETCHING_CUSTOMER,
          payload: false,
        });
      }
    }
  };
};
export const searchByPhoneOrMac = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.START_FETCHING_CUSTOMER,
      payload: true,
    });

    try {
      const response = await axios.post(`${GENIATECH_GET_USER_WITH_PHONE_OR_MAC_URL}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data === 'customer info could not be retreived') {
        toast.info('No user found!');
        dispatch({
          type: types.STOP_FETCHING_CUSTOMER,
          payload: false,
        });
      } else {
        dispatch({
          type: types.FETCHED_CUSTOMER,
          payload: response.data,
        });
      }
    } catch (e) {
      toast.info('Something went wrong!');
      if (e) {
        dispatch({
          type: types.STOP_FETCHING_CUSTOMER,
          payload: false,
        });
      }
    }
  };
};

export const searchByName = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.START_FETCHING_CUSTOMER,
      payload: true,
    });

    try {
      const response = await axios.post(`${GENIATECH_GET_USER_BY_NAME_URL}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.message === 'could not find any customer with this name') {
        dispatch({
          type: types.STOP_FETCHING_CUSTOMER,
          payload: false,
        });

        toast.info('No user found!');
      } else {
        dispatch({
          type: types.FETCHED_CUSTOMER,
          payload: response.data,
        });
      }
    } catch (e) {
      if (e) {
        dispatch({
          type: types.STOP_FETCHING_CUSTOMER,
          payload: false,
        });
      }
    }
  };
};

export const getUserDetails = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.START_FETCHING_CUSTOMER_DETAILS,
      payload: true,
    });

    try {
      const response = await axios.post(`${GENIATECH_GET_USER_AUTH}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.data) {
        try {
          const details = {
            macaddress: data.macid,
          };
          const res = await axios.post(`${GENIATECH_GET_USER_SESSION_URL}`, details, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
          });

          if (res.data) {
            console.log(res.data);
            dispatch({
              type: types.FETCHED_CUSTOMER_DETAILS,
              payload: {
                user: response.data,
                session: res.data,
              },
            });
          } else {
            toast.info('Unable to fetch the user session');
            dispatch({
              type: types.STOP_FETCHING_CUSTOMER_DETAILS,
              payload: false,
            });
          }
        } catch (e) {
          if (e) {
            toast.info('Unable to fetch the user session');
            dispatch({
              type: types.STOP_FETCHING_CUSTOMER_DETAILS,
              payload: false,
            });
          }
        }
      }
    } catch (e) {
      if (e) {
        toast.info('Unable to fetch the user session');
        dispatch({
          type: types.STOP_FETCHING_CUSTOMER_DETAILS,
          payload: false,
        });
      }
    }
  };
};

export const getPassword = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.START_GETTING_PASSWORD,
      payload: true,
    });
    try {
      const response = await axios.post(`${GENIATECH_NEW_GET_USER_PASSWORD_URL}`, qs.stringify(data));
      if (response.data) {
        dispatch({
          type: types.GET_PASSWORD,
          payload: response.data,
        });
      }
    } catch (e) {
      if (e.response) {
        dispatch({ type: types.STOP_GETTING_PASSWORD });
      }
    }
  };
};
