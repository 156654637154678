/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-nested-ternary */
import React, { memo, useContext, useState } from 'react';
import toastr from 'toastr';
import Button from 'react-bootstrap/Button';
import view from '../../../../../../assets/img/icons/view.png';
import InstallationsDetails from '../../InstallationsDetails';
import edit from '../../../../../../assets/img/icons/edit.png';
import EditInstallation from '../../EditInstallation';
import staff from '../../../../../../assets/img/icons/staff.fw.png';
import AssignedEmployees from './AssignedEmployees';
import ValidateMACID from '../../../../departments/field-support/accounts/ValidateMACID';
import helpers from '../../../../../../utils/installations/helpers';
import { showContentForDepartment, showContentForGivenDepartment, showContentForTwoDepartment } from './utils/helpers';
import { SALES } from '../../../../../../utils/config';
import useRefund from '../../../refund/hooks/useRefund';
import RefundForm from '../../../../departments/sales/refund/RefundForm';
import RefundList from '../../../../departments/sales/refund/RefundList';

import { NoItemRow, TizetiGIFLogoTable } from '../../../../../../utils/loader/loadingHelper';
import DeleteSvg from '../../../../../../assets/svg/delete-button.svg';
import DeleteInstallationModal from '../DeleteInstallationModal';
import { OverviewContext } from '../../../../../../context/shared/installations/overview/OverviewContext';
import SendFeedback from '../../../../departments/field-support/feedback/SendFeedback';
import { OPERATIONS } from '../../../../../../utils/departments/helpers';

const InstallationsTableBody = ({ load, clusterState, allInstallations, setAllInstallations }) => {
  const {
    onChange,
    refund,
    show,
    searchUsingKeyword,
    items,
    handleClose,
    handleShow,
    onSubmit,
    loadingForm,
    fetching,
    lists,
  } = useRefund();
  const [page] = useState(1);
  // console.log(items);
  const {
    onShowDeleteInstallationModal,
    getAssignedStaff,
    assignedStaff,
    assignedStaffCount,
    assignedStaffRows,
  } = useContext(OverviewContext);

  const [install, setInstall] = useState({});

  const colSpan = 18;
  const roleId = Number(sessionStorage.getItem('roleId'));
  const departmentId = sessionStorage.getItem('departmentId');
  const country = sessionStorage.getItem('country');

  const getOneInstallation = (i) => {
    setInstall(i);
  };
  const showInputs = (param) => {
    return (
      <td className="text-center">
        <input
          type="checkbox"
          checked={param.select}
          onChange={(e) => {
            const { checked } = e.target;
            setAllInstallations(
              allInstallations.map((data) => {
                if (param.id === data.id) {
                  if (
                    param.state.toUpperCase() === clusterState.toUpperCase() &&
                    param.status.toUpperCase() !== 'COMPLETED' &&
                    param.lat !== 'N/A'
                  ) {
                    data.select = checked;
                  } else toastr.error('Check Location, Status and Coordinates');
                }
                return data;
              }),
            );
          }}
        />
      </td>
    );
  };



  const showActivateButton = (param) => {
    return (
      <td>
        <button
          className="text-center noBorder"
          type="button"
          onClick={() => {
            // getAssignedStaff(param.installationId);
            getOneInstallation(param);
          }}
          data-target="#validateMAC"
          data-toggle="modal"
        >
          <img
            src="https://res.cloudinary.com/teewhy/image/upload/v1550751069/add-imgs.png"
            alt="Activate"
            data-toggle="tooltip"
            data-placement="left"
            title="Activate"
          />
        </button>
        <ValidateMACID account={install} assignedStaffCount={assignedStaffCount} assignedStaff={assignedStaff} />
      </td>
    );
  };
  const showInitiateContent = (installation) => {
    return (
      <td>
        <div className="d-flex">
          {installation && installation.installationId ? (
            <Button
              className="ml-1 mr-2 noBorder"
              variant="primary"
              onClick={() => {
                handleShow();
                getOneInstallation(installation);
                // console.log('initiate', installation);
              }}
            >
              <span className="view1" data-toggle="tooltip" data-placement="top" title="Edit">
                <img src={edit} alt="Edit" />
              </span>
            </Button>
          ) : (
            <div>You need to schedule an installation</div>
          )}
        </div>
      </td>
    );
  };
  const [showRefundList, setShowRefundList] = useState(false);
  const handleRefundListShow = () => setShowRefundList(false);
  const showRefundRequestContent = (installation) => {
    return (
      <td>
        <div className="d-flex">
          <Button
            className="ml-1 mr-2 noBorder"
            variant="primary"
            onClick={() => {
              setShowRefundList(true);
              // console.log(installation);
              const data = {
                installation_id: installation.installationId,
              };
              searchUsingKeyword(page, data);
              getOneInstallation(installation);
            }}
          >
            <span className="view1" data-toggle="tooltip" data-placement="top" title="Edit">
              <img src={view} alt="Edit" />
            </span>
          </Button>
        </div>
      </td>
    );
  };

  const showDeleteContent = (installation) => {
    return (
      <td>
        <div style={{ paddingLeft: '10px' }}>
          <img
            onClick={() => {
              onShowDeleteInstallationModal();
              getOneInstallation(installation);
            }}
            src={DeleteSvg}
            alt="delete-button"
            width="15px"
            className="img-pointer"
          />
        </div>
      </td>
    );
  };

  const checkRoleToDelete = (installation) => {
    if (roleId > 2 || roleId !== 6) {
      return showContentForGivenDepartment(departmentId, `${SALES}`, showDeleteContent(installation));
    }
    return null;
  };

  const showInstallations = () =>
    allInstallations && allInstallations.length ? (
      allInstallations.map((installation, i) => {
        // console.log(i);
        const {
          user,
          lat,
          lng,
          sub_zone,
          zoneName,
          status,
          payment_reference,
          firstName,
          lastName,
          email,
          phoneNumber,
          address,
          nearestBasestation,
          distance,
        } = installation;
        return (
          <tr key={i + 1}>
            <td> {i + 1}</td>
            {roleId > 1 && showContentForDepartment(departmentId, showInputs(installation))}
            <td>{user ? `${`${user.firstName} ${user.lastName}`}` : firstName ? `${firstName} ${lastName}` : 'N/A'}</td>
            <td className="text-uppercase">{nearestBasestation}</td>
            <td className="text-uppercase">{isNaN(distance) ? 'N/A' : `${distance / 1000} km`}</td>
            <td>{installation.address}</td>
            <td>
              {lat} / {lng}
            </td>
            {/* {showContentForDepartment(departmentId, showActivateButton(installation))} */}

            <td>
              <div className="d-flex">
                <button
                  data-toggle="modal"
                  data-target="#installation-details"
                  className="ml-1 mr-2 noBorder"
                  type="button"
                  onClick={() => getOneInstallation(installation)}
                >
                  <span className="view1" data-toggle="tooltip" data-placement="right" title="View">
                    <img src={view} alt="upload" />
                  </span>
                </button>
                <InstallationsDetails install={install} />
                <button
                  type="button"
                  className="ml-1 mr-2 noBorder"
                  data-toggle="modal"
                  data-target="#edit-acct"
                  onClick={() => {
                    getOneInstallation(installation);
                  }}
                >
                  <span className="view1" data-toggle="tooltip" data-placement="top" title="Edit">
                    <img src={edit} alt="Edit" />
                  </span>
                </button>
                <EditInstallation install={install} dept="FSE" />

                <button
                  data-toggle="modal"
                  data-target="#assigned-installation-details"
                  className="ml-1 mr-2 noBorder"
                  type="button"
                  onClick={() => {
                    getAssignedStaff(installation.installationId);
                    getOneInstallation(installation);
                  }}
                >
                  <span className="view1" data-toggle="tooltip" data-placement="right" title="Staff">
                    <img src={staff} alt="staff" />
                  </span>
                </button>
                <AssignedEmployees
                  install={install}
                  assignedStaffRows={assignedStaffRows}
                  assignedStaff={assignedStaff}
                />
              </div>
            </td>
            {showContentForTwoDepartment(
              departmentId,
              `${SALES}`,
              departmentId,
              `${OPERATIONS}`,
              showInitiateContent(installation),
            )}
            {showContentForTwoDepartment(
              departmentId,
              `${SALES}`,
              departmentId,
              `${OPERATIONS}`,
              showRefundRequestContent(installation),
            )}
            <td>{helpers.installationStatusLabels(status)}</td>

            <td>{payment_reference}</td>
            <td>{user ? user.address : address || 'N/A'}</td>
      
            <td>{user ? user.email : email || 'N/A'}</td>
            <td>{user ? user.phoneNumber : phoneNumber || 'N/A'}</td>
            <td className="text-nowrap">{sub_zone}</td>
            <td>{zoneName || 'No Zone'}</td>
            {checkRoleToDelete(installation)}
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan={colSpan} text="No Rescheduled Installations" />
    );
  const displayData = () => {
    if (load) return <TizetiGIFLogoTable colSpan={colSpan} />;
    return showInstallations();
  };

  return (
    <tbody>
      {displayData()}
      <RefundForm
        load={loadingForm}
        onSubmit={onSubmit}
        show={show}
        handleClose={handleClose}
        onChange={onChange}
        refund={refund}
        install={install}
      />
      <RefundList
        load={loadingForm}
        fetching={fetching}
        onSubmit={onSubmit}
        show={showRefundList}
        handleClose={handleRefundListShow}
        onChange={onChange}
        refund={refund}
        install={install}
        items={items}
        lists={lists}
      />
      <DeleteInstallationModal install={install} />
    </tbody>
  );
};

export default memo(InstallationsTableBody);
