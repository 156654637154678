/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import useCheckTwoDepartments from '../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';
import { NOC, SUPER_ADMIN } from '../../../../../utils/departments/helpers';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import PurchaseOrderBody from '../../../shared/purchase-order/PurchaseOrderBody';
import NocNavBar from '../../../../layouts/noc/NocNavBar';

const NocPurchaseOrder = ({ history }) => {
  useCheckTwoDepartments(`${NOC}`, `${SUPER_ADMIN}`, history);
  const {
    profile: { firstName, department_id },
  } = useUser();

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <NocNavBar currentPage="Purchase Order" position={firstName} />
        <div className="container">
          <PurchaseOrderBody deptID={department_id} />
        </div>
      </main>
    </div>
  );
};

export default NocPurchaseOrder;
