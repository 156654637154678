import React from 'react';
import { ToastContainer } from 'react-toastify';
import CpuUtilizationBody from './CpuUtilizationBody';
import CpuUtilizationProvider from './provider/CpuUtilizationProvider';
import { useUser } from '../../../../../../../hooks/shared/user/useUser';
import useCheckTwoDepartments from '../../../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';
import { admin, NOC } from '../../../../../../../utils/config';
import NocNavBar from '../../../../../../layouts/noc/NocNavBar';

const CpuUtilization = ({ history }) => {
  const { profile } = useUser();
  const { firstName } = profile;
  useCheckTwoDepartments(`${NOC}`, `${admin}`, history);
  return (
    <div className="s-layout">
      <CpuUtilizationProvider>
        <main className="s-layout__content col-lg-10 p-0">
          <NocNavBar currentPage="NOC Sheet - Fiber Site CPU Utilization" position={firstName} />
          <CpuUtilizationBody />
        </main>
        <ToastContainer />
      </CpuUtilizationProvider>
    </div>
  );
};

export default CpuUtilization;
