import axios from 'axios';
import {
  GET_RETAILER_LOCATIONS_URL,
  GET_TOTAL_REVENUE_URL,
} from '../../services/api/hotspot/HotspotUrls';
import {
  GET_RETAILER_LOCATIONS,
  GET_TOTAL_REVENUE,
} from '../../constants/types/hotspot/HotspotTypes';

export const getTotalRevenue = () => async (dispatch) => {
  const response = await axios.get(
    `${GET_TOTAL_REVENUE_URL}`,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      }
    }
  );

  if (response.data.status === true) {
    dispatch({
      type: GET_TOTAL_REVENUE,
      payload: response.data.data,
    });
  }
};

export const getRetailerLocations = () => async (
  dispatch,
) => {
  const response = await axios.get(
    `${GET_RETAILER_LOCATIONS_URL}`,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      }
    }
  );

  if (response.data.status === true) {
    dispatch({
      type: GET_RETAILER_LOCATIONS,
      payload: response.data.data,
    });
  }
};
