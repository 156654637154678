/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useUserProfile } from '../../../../../../hooks/shared/user/useUserProfile';
import SuperAdminNavBar from '../../../../../layouts/super-admin/SuperAdminNavBar';
import OverviewBody from './OverviewBody';

const SuperAdminOverview = () => {
  const { profile, getUserProfile } = useUserProfile();

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <SuperAdminNavBar currentPage="Overview" position={profile.firstName} />
        <OverviewBody />
      </main>
    </div>
  );
};

export default SuperAdminOverview;
