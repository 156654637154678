import React, { useContext } from 'react';
import RequisitionContext from '../../../../../context/shared/requisitions/RequsitionContext';

const ApprovedRequisition = () => {
  const { getManagerApprovedRequsitionsByDepartment, setManagerApproval } = useContext(RequisitionContext);

  return (
    <div className="my-3 mr-3">
            <button className="btn mt-1 copy-font" type="submit" onClick={async () => 
                {
                    setManagerApproval(true)
                    await getManagerApprovedRequsitionsByDepartment(1)
                }}>
            Approved Requistions
            </button>
    </div>
  );
};

export default ApprovedRequisition;