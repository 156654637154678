import React from 'react';
import PropTypes from 'prop-types';
import AccountsNavBar from '../../../../layouts/accounts/AccountsNavBar';
import AccountsRetrievalsBody from './AccountsRetrievalsBody';
import useCheckTwoDepartments from '../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';
import { ACCOUNTS, SUPER_ADMIN } from '../../../../../utils/departments/helpers';
import { useUser } from '../../../../../hooks/shared/user/useUser';

const AccountsRetrievals = ({ history }) => {
  useCheckTwoDepartments(`${ACCOUNTS}`, `${SUPER_ADMIN}`, history);
  const { profile } = useUser();
  const { firstName } = profile;

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <AccountsNavBar currentPage="Retrievals" position={firstName} />
        <AccountsRetrievalsBody />
      </main>
    </div>
  );
};

AccountsRetrievals.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default AccountsRetrievals;
