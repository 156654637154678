/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { ACCOUNTS, SUPER_ADMIN } from '../../../../../utils/departments/helpers';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import AccountsNavBar from '../../../../layouts/accounts/AccountsNavBar';
import PurchaseOrderBody from '../../../shared/purchase-order/PurchaseOrderBody';
import useCheckTwoDepartments from '../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';

const AccountsPurchaseOrder = ({ history }) => {
  useCheckTwoDepartments(`${ACCOUNTS}`, `${SUPER_ADMIN}`, history);
  const {
    profile: { firstName, department_id },
  } = useUser();

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <AccountsNavBar currentPage="Purchase Order" position={firstName} />
        <PurchaseOrderBody deptID={department_id} />
      </main>
    </div>
  );
};

AccountsPurchaseOrder.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default AccountsPurchaseOrder;
