import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import DedicatedInvoiceBodyTable from './DedicatedInvoiceBodyTable';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import { useDedicatedInvoice } from '../../../../../hooks/accounts/dedicated-invoice/useDedicatedInvoice';
import AddDedicatedInvoice from './AddDedicatedInvoice';

const AccountsDedicatedInvoiceBody = () => {
  const { lists, pages, isLoading, handlePageClick, getAllDedicatedInvoice } = useDedicatedInvoice();
  const [page] = useState(1);

  useEffect(() => {
    getAllDedicatedInvoice(page);
  }, []);

  return (
    <div className="container pt-5">
      <div className="row pl-2 mt-5">
        <form className="form-inline mb-2 col-12 justify-content-between">
          {/* <div className="d-flex">
            <div className="">
              <input
                className="form-control"
                type="text"
                placeholder="Search By Name"
                style={{ width: '400px' }}
                name="keyword"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
              />
            </div>

            <div className="form-group ml-2">
              <button type="button" className="btn btn-green py-1">
                Search
              </button>
            </div>
          </div> */}
          <div className="form-group mr-4">
            <button type="button" className="btn btn-green py-1" data-toggle="modal" data-target="#add_invoice">
              Create Invoice
            </button>
          </div>
        </form>
        <AddDedicatedInvoice />

        <div className="card x-panel m-0 p-0">
          <div className="table-responsive content col-12 mt-4 px-0">
            <table className="table">
              <thead>
                <tr className="text-nowrap">
                  <th>S/N</th>
                  <th>MAC ID</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Unit</th>
                  <th>Total Ex VAT (&#8358;)</th>
                  <th>Total With VAT (&#8358;)</th>
                  <th>Price (&#8358;)</th>
                  <th>Expiration</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan="10" className="text-center">
                      <img src={loader} alt="loading gif" />
                    </td>
                  </tr>
                ) : (
                  <DedicatedInvoiceBodyTable lists={lists} />
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {pages > 1 ? (
        <ReactPaginate
          previousLabel="previous"
          nextLabel="next"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={pages}
          marginPagesDisplayed={3}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName="pagination"
          subContainerClassName="pages pagination"
          activeClassName="active"
        />
      ) : null}
    </div>
  );
};

export default AccountsDedicatedInvoiceBody;
