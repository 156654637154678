import React from 'react';
import { years } from '../components/tempData';

function AvailableYears({ selectedYear, onYearChange }) {
  const handleSelectChange = (event) => {
    onYearChange(event.target.value);
  };

  return (
    <div className="container mx-auto ">
      <div className="relative w-40">
        <select
          className="block w-full px-4 py-2 border rounded appearance-none focus:border-blue-500 focus:outline-none"
          value={selectedYear}
          onChange={handleSelectChange}
        >
          <option value="" disabled>
            Select Year
          </option>
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
        <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none"></div>
      </div>
      {selectedYear && <div className="my-4">Selected Year: {selectedYear}</div>}
    </div>
  );
}

export default AvailableYears;
