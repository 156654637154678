import { API_URL } from "../../../utils/config";
import { toast } from "react-toastify";
import axios from "axios";
import { VERIFY_USER_URL } from "../../../services/api/wificall/CustomersUrl";
import toastr from "toastr";

export const disapproveUtilityUpload = (data, user) => async dispatch => {

  try {

    const response = await axios.put(`${VERIFY_USER_URL}/${user.id}`, data, {

      headers: {

        'Authorization': `Bearer ${sessionStorage.getItem('token')}`

      }

    });

    console.log(response.data);

    if(response.data[1]){

          toastr.success(`${user.firstName}'s utility upload has been disapproved!`);
          setTimeout(() => {

            window.location.reload();

            }, 2000);

        } else {

          toast.info("Something went wrong!")

        }

  } catch (e) {

  }

};

export const approveUtilityUpload = user => async dispatch => {

  try {

    axios.get(`${API_URL}/wificall/user/${user.id}`, {

      headers: {

        'Authorization': `Bearer ${sessionStorage.getItem('token')}`

      }

    }).then(response => {

      if(response.data.id_verification_status === "approved"){

        const data = {

          utility_bill_verification_status: "approved",
          verification_status: "verified"

        };

        axios.put(`${VERIFY_USER_URL}/${user.id}`, data, {

          headers: {

            'Authorization': `Bearer ${sessionStorage.getItem('token')}`

          }

        }).then(res => {

          console.log(res.data);

        })

      } else {

        //if the user identitiy verification status is not currently verified
        const data = {

          utility_bill_verification_status: "approved",
          verification_status: "unapproved"

        };

        console.log(data);

        axios.put(`${VERIFY_USER_URL}/${user.id}`, data, {

          headers: {

            'Authorization': `Bearer ${sessionStorage.getItem('token')}`

          }

        }).then(resp => {

          console.log(resp.data);

          if(resp.data[1]){

            toastr.success(`${user.firstName}'s utility upload has been approved`);
            setTimeout(() => {
              window.location.reload();
            }, 2000);

          }

        });

      }

    })

  } catch (e) {

  }
};

export const approveIdentityUpload = user => async dispatch => {

  try {

    axios.get(`${API_URL}/wificall/user/${user.id}`, {

      headers: {

        'Authorization': `Bearer ${sessionStorage.getItem('token')}`

      }

    }).then(res => {

      if(res.data.utility_bill_verification_status === "approved") {

        const data = {

          id_verification_status: "approved",
          verification_status: "verified"

        };

        axios.put(`${API_URL}/wificall/verification/${user.id}`, data, {

          headers: {

            'Authorization': `Bearer ${sessionStorage.getItem('token')}`

          }

        }).then(res => {

          if(res.data[1]){

            toastr.success(`${user.firstName}'s identity upload has been approved`);
            setTimeout(() => {

              window.location.reload();

              }, 2000);

          } else {

            toast.info("Something went wrong!")

          }
        })

      } else {

        const data = {

          verification_status: "unapproved",
          id_verification_status: "approved",

        };

        axios.put(`${API_URL}/wificall/verification/${user.id}`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
          }
        }).then(res => {

          if(res.data[1]){

            toastr.success(`${user.firstName}'s identity upload has been approved toastr`);
            setTimeout(() => {
              window.location.reload();
            }, 2000);

          } else {

            toastr.info("Something went wrong!")
          }
        })

      }

    });

  } catch (e) {

  }
};

export const disapproveIdentityUpload = user => async dispatch => {

  const data = {

    id_verification_status: "rejected",
    verification_status: "unapproved"

  };

  try {

    const response = await axios.put(`${API_URL}/wificall/verification/${user.id}`, data, {

      headers: {

        'Authorization': `Bearer ${sessionStorage.getItem('token')}`

      }

    });

    if(response.data[1]){

      toastr.success(`${user.firstName} Identity Disapproved!` );
      setTimeout(() => {

        window.location.reload();

      }, 2000);

    } else {

      toastr.info("Something went wrong!")

    }

  } catch (e) {

  }

};