import React from 'react';
import PropTypes from 'prop-types';
import useCheckTwoDepartments from '../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';
import { ACCOUNTS, SUPER_ADMIN } from '../../../../../utils/departments/helpers';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import AccountsNavBar from '../../../../layouts/accounts/AccountsNavBar';
import AccountsFirsTransactionsBody from './AccountsFirsTransactionsBody';

const DedicatedCustomers = ({ history }) => {
  useCheckTwoDepartments(`${ACCOUNTS}`, `${SUPER_ADMIN}`, history);
  const {
    profile: { firstName },
  } = useUser();

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <AccountsNavBar currentPage="Firs Transactions" position={firstName} />
        <AccountsFirsTransactionsBody />
      </main>
    </div>
  );
};

export default DedicatedCustomers;
