/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import helpers from '../../../../../utils/helpers';

const ViewDeviceRetrieval = ({ one }) => {
  const { address, details, agent, expiration_date, created_at, updated_at } = one;

  return (
    <div
      id="view_device_retrieval"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title ml-5" id="myModalLabel">
              Device Retrieval Details
            </h4>
            <button type="button" className="close custom-close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body detail" style={{ padding: '0', textAlign: 'justify' }}>
            <div className="row">
              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label1">
                  <label id="fn">Address</label>
                </div>
                <div className="col-lg-8 detail-result1">
                  {address ? <p className="mt-4 mb-2">{address}</p> : <p className="mt-4">N/A</p>}
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label3">
                  <label>Details</label>
                </div>
                <div className="col-lg-8 detail-result1">
                  {details ? <p className="mt-4 mb-2">{details}</p> : <p className="mt-4">N/A</p>}
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label2">
                  <label>Agent</label>
                  <label>Expiration Date</label>
                </div>
                <div className="col-lg-8 detail-result2">
                  {agent ? <p className="mt-4 mb-2">{agent}</p> : <p className="mt-4">N/A</p>}
                  {expiration_date ? <p className="mb-2">{expiration_date}</p> : <p className="mt-4">N/A</p>}
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label3">
                  <label>Created At</label>
                </div>
                <div className="col-lg-8 detail-result3">
                  {created_at ? (
                    <p className="mt-4">{helpers.formatDateAndTime(created_at)}</p>
                  ) : (
                    <p className="mt-4">N/A</p>
                  )}
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label1">
                  <label id="fn">Update At</label>
                </div>
                <div className="col-lg-8 detail-result1">
                  {updated_at ? (
                    <p className="mt-4 mb-2">{helpers.formatDateAndTime(updated_at)} </p>
                  ) : (
                    <p className="mt-4">N/A</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ViewDeviceRetrieval;
