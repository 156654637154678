import axios from 'axios';
import { toast } from 'react-toastify';
import { GET_BASESTATIONS_URL } from '../../../services/api/shared/installations/InstallationsUrl';

export const get_all_basestations_read_only = async () => {
  const res = await axios.get(`${GET_BASESTATIONS_URL}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
  });

  try {
    if (res.data) {
      return res.data;
    }
  } catch (error) {
    toast.error(error.message);
  }
};
