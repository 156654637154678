/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import loader from '../../../../../../assets/img/icons/TizetiLoaderColored.gif';
import { usePurchaseOrder } from '../../../../../../hooks/shared/purchase-order/usePurchaseOrder';
import PurchaseOrderTable from './PurchaseOrderTable';

const PurchaseOrderBody = () => {
  const { lists, pages, isLoading, handlePageClick, getAllPurchaseOrder } = usePurchaseOrder();

  useEffect(() => {
    getAllPurchaseOrder();
  }, []);

  return (
    <div className="col-12 mt-5">
      <div className="col-12 m-0 p-0 after-summary">
        <div className="row ml-3">
          <div className="card x-panel m-0 p-0 pt-3">
            <div className="table-responsive content col-12 mt-4 px-0">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Item</th>
                    <th>Quantity</th>
                    <th>Department</th>
                    <th>Manager Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan="8" className="text-center">
                        <img src={loader} alt="loading gif" />
                      </td>
                    </tr>
                  ) : (
                    <PurchaseOrderTable lists={lists} />
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {pages > 1 ? (
        <ReactPaginate
          previousLabel="previous"
          nextLabel="next"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={pages}
          marginPagesDisplayed={3}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName="pagination"
          subContainerClassName="pages pagination"
          activeClassName="active"
        />
      ) : null}
    </div>
  );
};

export default PurchaseOrderBody;
