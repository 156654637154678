/* eslint-disable @typescript-eslint/camelcase */
import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import Label from '../../../forms/Label';
import CustomInput from '../../../forms/CustomInput';
import CustomButton from '../../../forms/CustomButton';
import AppContext from '../../../../../../context/shared/AppContext';
import CustomDropdownOption from '../../../forms/CustomDropdownOption';
import helpers from '../../../../../../utils/helpers';
import { useUser } from '../../../../../../hooks/shared/user/useUser';

const typeOfExtension = ['days', 'months'];
const paymentTypes = [{ key: 'Transfer Payment' }, { key: 'Not Transfer Payment' }];

const ExtendLteCustomer = ({ customer }) => {
  const { profile } = useUser();
  const { email } = profile;
  const {
    showExtendModal,
    onOpenExtendModal,
    handleChange,
    extend,
    extending,
    onSubmit,
    onExtensionTypeChange,
    extensionType,
  } = useContext(AppContext);
  const { no_of_months, no_of_days, no_geniatech } = extend;

  const submitForm = () => {
    return no_of_days.length > 0;
  };

  const onExtend = (e) => {
    e.preventDefault();
    if (!profile && profile.email) {
      toast.error('Kindly add a profile');
    } else {
      onSubmit({ action: 'extend_lte_account', username: customer.imsi, staff_email: email, days: no_of_days });
    }
  };
  const imsiInput = () => {
    return (
      <div className="d-flex">
        <div className="pr-3 form-group col-md-12">
          <Label classname="m-0 p-0 copy-font" name="Item" />
          <CustomInput
            classname="form-control border border-info py-2"
            name="imsi"
            value={customer.imsi}
            onChange={handleChange}
            readOnly
            type="text"
          />
        </div>
      </div>
    );
  };
  const paymentTypeInput = () => {
    return (
      <div className="d-flex">
        <div className="pr-3 form-group col-md-12">
          <Label classname="m-0 p-0 copy-font" name="Payment Type" />
          <select
            value={no_geniatech}
            name="no_geniatech"
            onChange={handleChange}
            className="form-control border border-info py-2"
          >
            <CustomDropdownOption value="" name="Select Payment Type" />
            {paymentTypes.map((type) => (
              <CustomDropdownOption value={type.key} name={type.key} />
            ))}
          </select>
        </div>
      </div>
    );
  };
  const submitButton = () => {
    return (
      <div className="text-center mt-3">
        <CustomButton
          classname="btn btn-green py-2 px-5"
          name={extending ? 'Extending...' : 'Extend'}
          disabled={extending || !submitForm()}
        />
      </div>
    );
  };
  const checkRoleForExtension = () => {
    const personnelDayList = helpers.daysArray(2).map((day) => {
      return <CustomDropdownOption value={day} name={day} />;
    });
    const managerAndHigherDayList = helpers.daysArray(14).map((day) => {
      return <CustomDropdownOption value={day} name={day} />;
    });
    if (Number(sessionStorage.getItem('roleId')) > 2 && Number(sessionStorage.getItem('roleId')) !== 6) {
      return (
        <form className="col-md-12" onSubmit={onExtend}>
          {imsiInput()}
          {/* <div className="d-flex">
            <div className="pr-3 form-group col-md-12">
              <Label classname="m-0 p-0 copy-font" name="Extension Type" />
              <select
                value={extensionType}
                name="extensionType"
                onChange={onExtensionTypeChange}
                className="form-control border border-info py-2"
              >
                <CustomDropdownOption value="" name="Select Extend By (Day or Month)" />
                {typeOfExtension.map((type) => (
                  <CustomDropdownOption value={type} name={type} />
                ))}
              </select>
            </div>
          </div>
          {paymentTypeInput()} */}
          <div className="d-flex">
            <div className="pr-3 form-group col-md-12">
              <Label
                classname="m-0 p-0 copy-font"
                name={extensionType === 'months' ? 'Number of Months to extend by' : 'Number of Days to extend by'}
              />
              {/* <select
                name={extensionType === 'months' ? 'no_of_months' : 'no_of_days'}
                onChange={handleChange}
                className="form-control border border-info py-2"
              >
                <option value="">
                  {extensionType === 'months' ? 'Select Number of Months to extend by' : 'Number of Days to extend by'}
                </option>
                {managerAndHigherDayList}
              </select> */}
              <CustomInput
                classname="form-control border border-info py-2"
                name={extensionType === 'months' ? 'no_of_months' : 'no_of_days'}
                value={extensionType === 'months' ? no_of_months : no_of_days}
                onChange={handleChange}
                readOnly={false}
                type="number"
              />
            </div>
          </div>
          {submitButton()}
        </form>
      );
    }
    return (
      <form className="col-md-12" onSubmit={onExtend}>
        {imsiInput()}
        {paymentTypeInput()}
        <div className="d-flex">
          <div className="pr-3 form-group col-md-12">
            <Label classname="m-0 p-0 copy-font" name="Number of Days to extend by" />
            <select name="no_of_days" onChange={handleChange} className="form-control border border-info py-2">
              <option value="">Select days to extend by</option>
              {personnelDayList}
            </select>
          </div>
        </div>
        {submitButton()}
      </form>
    );
  };

  return (
    <Modal show={showExtendModal} onHide={onOpenExtendModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Extend Customer Account</Modal.Title>
      </Modal.Header>
      <div className="modal-body detail mb-4 p-0">{checkRoleForExtension()}</div>
    </Modal>
  );
};

export default ExtendLteCustomer;
