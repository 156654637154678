/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import ProcurementNavBar from '../../../../layouts/procurement/ProcurementNavBar';
import ProcurementCustomersTable from './ProcurementCustomersTable';
import { PROCUREMENT, SUPER_ADMIN } from '../../../../../utils/departments/helpers';
import useCheckTwoDepartments from '../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';

const ProcurementCustomers = ({ history }) => {
  useCheckTwoDepartments(`${PROCUREMENT}`, `${SUPER_ADMIN}`, history);

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <ProcurementNavBar currentPage="Customers" position="" />
        <ProcurementCustomersTable />
      </main>
    </div>
  );
};

ProcurementCustomers.propTypes = {
  history: PropTypes.shape(),
};

export default ProcurementCustomers;
