import React from 'react';
import useSectorAllocation from '../hooks/useSectorAllocation';
import SectorAllocationContext from './SectorAllocationContext';

const SectorAllocationProvider = ({ children }) => {
  const { data, loading, paginateChange, loadingForm, onChange, formValue, onSubmit, pages } = useSectorAllocation();
  return (
    <SectorAllocationContext.Provider
      value={{
        data,
        loading,
        paginateChange,
        loadingForm,
        onChange,
        formValue,
        onSubmit,
        pages,
      }}
    >
      {children}
    </SectorAllocationContext.Provider>
  );
};
export default SectorAllocationProvider;
