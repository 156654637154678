import * as load from '../../../constants/types/shared/LoadingTypes';
import {
  GET_ALL_REQUISITIONS,
  GET_LEADS_REQUISITIONS,
  GET_ALL_REQUISITION,
  SHOW_MODAL,
  SET_CHECK_ALL_REQUISTION,
  SET_CHECK_ONE_OF_ALL_REQUISTION,
} from '../../../constants/types/accounts/RequisitionTypes';
import * as types from '../../../constants/types/shared/requisition/RequisitionTypes';

export const requisitionReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case load.FETCHING_STARTS:
      return {
        ...state,
        isFetching: true,
      };

    case load.FETCHING_STOPS:
      return {
        ...state,
        isFetching: false,
      };

    case GET_ALL_REQUISITION:
      const requisitions = payload.requistions.map((item) => ({ ...item, isChecked: false }));
      return {
        ...state,
        requistions: requisitions,
        totalPages: payload.totalPages,
        isFetching: false,
      };

    case SET_CHECK_ALL_REQUISTION:
      const checkRequisitions = state.requistions.map((items) => ({ ...items, isChecked: payload }));
      return {
        ...state,
        requistions: checkRequisitions,
      };

    case SET_CHECK_ONE_OF_ALL_REQUISTION:
      const checkRequisition = state.requistions.map((items) => {
        return items.id == payload.id ? { ...items, isChecked: payload.checked } : items;
      });
      return {
        ...state,
        requistions: checkRequisition,
      };

    case GET_ALL_REQUISITIONS:
      return {
        ...state,
        requistions: payload,
        isFetching: false,
      };

    case types.START_APPROVE_OR_DISAPPROVE:
      return {
        ...state,
        approving: true,
      };

    case types.STOP_APPROVE_OR_DISAPPROVE:
      return {
        ...state,
      };

    case types.APPROVE_OR_DISAPPROVE_SUCCESS:
      return {
        ...state,
        isApprovingOrDisapproving: false,
        requistions: state.requistions.filter((req) => req.id !== payload),
        leadRequistions: state.leadRequistions.filter((req) => req.id !== payload),
        showModal: false,
        approving: false,
      };

    case GET_LEADS_REQUISITIONS:
      return {
        ...state,
        leadRequistions: payload,
        isFetching: false,
      };
    case SHOW_MODAL:
      return { ...state, showModal: !state.showModal };
    default:
      return state;
  }
};

export default requisitionReducer;
