import React from 'react';
import StaffPayslipsBody from './StaffPayslipsBody';
import useCheckTwoDepartments from '../../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';
import { useUser } from '../../../../../../hooks/shared/user/useUser';
import StaffPayslipProvider from './context/StaffPayslipProvider';
import AccountsNavBar from '../../../../../layouts/accounts/AccountsNavBar';
import { ACCOUNTS, SUPER_ADMIN } from '../../../../../../utils/departments/helpers';

const StaffPayslip = ({ history }) => {
  const { profile } = useUser();
  const { firstName } = profile;
  useCheckTwoDepartments(`${ACCOUNTS}`, `${SUPER_ADMIN}`, history);
  return (
    <div className="s-layout">
      <StaffPayslipProvider>
        <main className="s-layout__content col-lg-10 p-0">
          <AccountsNavBar currentPage="Staff Payslip" position={firstName} />
          <StaffPayslipsBody />
        </main>
      </StaffPayslipProvider>
    </div>
  );
};

export default StaffPayslip;
