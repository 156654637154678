import React, { useContext, useState } from 'react';
import RequisitionContext from '../../../../../../context/shared/requisitions/RequsitionContext';
export default function BulkApprovalOrRejection({ req }) {
  const { onApproveChange, approving, approveRequisition, BulkApproveOrRejectRequisitions } = useContext(
    RequisitionContext,
  );
  const { paymentDate, comment, accountapproval } = approveRequisition;
  const onSubmit = (e) => {
    e.preventDefault();
    const bulkIds = req.filter((item) => item.isChecked).map((item) => item.id);
    BulkApproveOrRejectRequisitions(approveRequisition, bulkIds);
  };

  const disableButton = () => paymentDate && comment && accountapproval;

  return (
    <div
      id="bulkApproveOrRejectRequisition"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Bulk Approval Or Rejection
            </h4>
            <button type="button" className="close custom-close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body">
            <form className="form-horizontal" onSubmit={onSubmit}>
              <div className="form-group add-log">
                <div className="col-md-12 mb-1 d-flex">
                  <div className="col-lg-6 mb-3">
                    <label className="copy-font">Payment Date</label>
                    <input
                      type="date"
                      name="paymentDate"
                      value={paymentDate}
                      className="form-control"
                      placeholder="Payment Date"
                      onChange={onApproveChange}
                      required
                      disabled
                    />
                  </div>

                  <div className="col-lg-6 mb-3">
                    <label className="copy-font">Comment</label>
                    <input
                      type="text"
                      className="form-control"
                      name="comment"
                      placeholder="Comment"
                      value={comment}
                      onChange={onApproveChange}
                      // defaultValue={last}
                      // onChange={this.onChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 mb-1 d-flex">
                  <div className="col-lg-6 mb-3">
                    <label className="copy-font">Account Approval Status</label>
                    <select
                      name="accountapproval"
                      id="accountapproval"
                      onChange={onApproveChange}
                      value={accountapproval}
                    >
                      <option value="">Select status</option>
                      <option value="approved">Approve All</option>
                      <option value="rejected">Reject All</option>
                    </select>
                  </div>

                  <div className="col-lg-6 mb-3">
                    <label className="copy-font text-capitalize">{accountapproval} In Favour Of</label>
                    <select name="in_favour_of" id="in_favour_of">
                      {req
                        .filter((item) => item.isChecked)
                        .map((item, index) =>
                          index === 0 ? (
                            <option value="" key={index}>
                              {item.in_favour_of}
                            </option>
                          ) : (
                            <option value="" key={index} disabled>
                              {item.in_favour_of}
                            </option>
                          ),
                        )}
                    </select>
                  </div>
                </div>
                <div className="col-12 mb-3 text-center">
                  <button
                    className={`btn ${
                      approveRequisition.accountapproval === 'approved'
                        ? 'btn-green'
                        : approveRequisition.accountapproval === 'rejected'
                        ? 'btn-danger'
                        : 'btn-info'
                    } `}
                    disabled={!disableButton()}
                  >
                    {approving
                      ? 'loading...'
                      : approveRequisition.accountapproval === 'approved'
                      ? 'Approve All'
                      : approveRequisition.accountapproval === 'rejected'
                      ? 'Reject All'
                      : 'Choose Status'}
                  </button>
                </div>
                {/* )} */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
