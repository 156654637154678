import React, { useState } from 'react';

const SearchTrackListWithDate = ({ searchWithDate }) => {
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const filterByDate = (e) => {
    e.preventDefault();
    searchWithDate({ from, to });
  };
  return (
    <div style={{ display: 'flex' }}>
      <div className="form-group d-flex ml-2">
        <small className="mr-1 search-date">From: </small>
        <input
          type="date"
          className="form-control"
          name="from"
          value={from}
          onChange={(e) => setFrom(e.target.value)}
        />
      </div>
      <div className="form-group d-flex ml-2">
        <small className="mr-1 search-date">To: </small>
        <input type="date" className="form-control" name="to" value={to} onChange={(e) => setTo(e.target.value)} />
      </div>
      <div className="form-group ml-2">
        <button className="btn btn-green" type="submit" onClick={filterByDate}>
          Search
        </button>
      </div>
    </div>
  );
};

export default SearchTrackListWithDate;
