/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react';
import moment from 'moment';
import { CallLogContext } from '../../../../../../context/customer-support/CallLogContext';
import loader from '../../../../../../assets/img/icons/TizetiLoaderColored.gif';
import view from '../../../../../../assets/img/icons/view.png';
import Details from './Details';

const CustomersTable = () => {
  const { callLogs, loading } = useContext(CallLogContext);
  const [one, setOne] = useState({});
  if (loading)
    return (
      <tbody>
        <tr>
          <td>
            <img src={loader} alt="" />
          </td>
        </tr>
      </tbody>
    );

  const customerList = callLogs ? (
    callLogs.map((customer) => (
      <tr key={customer.id}>
        <td>{customer.id}</td>
        <td>{customer.firstName}</td>
        <td>{customer.lastName}</td>
        <td>{customer.email}</td>
        <td>{customer.phoneNumber}</td>
        <td>{customer.note}</td>
        <td>{customer.resolution}</td>

        <td>
          <div className="d-flex">
            <button
              type="submit"
              data-toggle="modal"
              data-placement="right"
              onClick={() => {
                setOne(customer);
                console.log(customer);
              }}
              data-target="#dedicated-detail"
              style={{
                border: '0',
                backgroundColor: 'white',
              }}
            >
              <span className="view1">
                <img src={view} alt="" data-toggle="tooltip" data-placement="right" title="View" />
              </span>
            </button>
            <Details one={one} />
          </div>
        </td>
        <td>{moment(customer.created_at).format('MM/DD/YYYY')}</td>
      </tr>
    ))
  ) : (
    <tr>
      <td>
        <p>No customers!</p>
      </td>
    </tr>
  );

  return <tbody>{customerList}</tbody>;
};

export default CustomersTable;
