import React, { Component } from 'react'

export default class SendMail extends Component {
    render() {
        return (
            <div id="sendMail" className="modal fade in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title text-center" id="myModalLabel">Send Mail</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                        <div className="modal-body">
                            <div className="col-12">
                                <div className="d-flex">
                                    <label className="copy-font mt-0  mr-auto p-0">To:</label>
                                    <input type="email" className="form-control" />
                                </div>

                                <div className="d-flex">
                                    <label className="copy-font mt-0  mr-auto p-0">Subject:</label>
                                    <input type="text" className="form-control" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
