/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useContext } from 'react';
import { EnbSiteIpAllocationContext } from './context/EnbSiteIpAllocationContext';
import CustomButton from '../../../../shared/forms/CustomButton';
import CustomInput from '../../../../shared/forms/CustomInput';

const AddEnbSiteIpAllocation = () => {
  const { loadingForm, onChange, formValue, onSubmit } = useContext(EnbSiteIpAllocationContext);
  const { eNB_vendor, ip_address, site } = formValue;
  const submitForm = () => {
    return eNB_vendor.length > 0 && ip_address.length > 0 && site.length > 0;
  };
  return (
    <div id="add-access-point" className="modal fade mt-5" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header shadow">
            <h4 className="modal-title update_asset">Enb Site IP Allocations</h4>
            <button type="button" className="btn btn-outline-info btn-circle btn-close" data-dismiss="modal">
              x
            </button>
          </div>
          <div className="modal-body detail mb-4 p-0">
            <form
              className="col-md-12 p-0"
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              <div className="d-flex">
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="customerName">
                    Site
                  </label>
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="site"
                    value={site}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="Site"
                    type="text"
                    id="customerName"
                  />
                </div>
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="customerEmail">
                    IP Address
                  </label>
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="ip_address"
                    value={ip_address}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="IP ADDRESS"
                    type="text"
                    id="customerEmail"
                  />
                </div>
              </div>

              <div className="d-flex">
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="router_id">
                    ENB VENDOR
                  </label>
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="eNB_vendor"
                    value={eNB_vendor}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="ENB Vendor"
                    type="text"
                    id="router_id"
                  />
                </div>
              </div>

              <div className="text-center mt-3">
                {loadingForm ? (
                  <CustomButton classname="btn btn-green px-3" name="Adding" disabled={loadingForm} />
                ) : (
                  <CustomButton classname="btn btn-green px-3" name="Add" disabled={!submitForm()} />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEnbSiteIpAllocation;
