/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import AppContext from '../../../../../../../context/shared/AppContext';
import { LEGACY_GENIATECH_URL } from '../../../../../../../services/api/shared/installations/InstallationsUrl';
import useReboot from '../hooks/useReboot';

const RebootProvider = ({ children }) => {
  const { data, loading, pages, rebootAccessPoints, rebooting } = useReboot(`${LEGACY_GENIATECH_URL}`, {
    action: 'fetch_static_access_points',
    network_id: 'tz-xwf',
  });
  return (
    <AppContext.Provider
      value={{
        data,
        loading,
        pages,
        rebootAccessPoints,
        rebooting,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export default RebootProvider;
