/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import toastr from 'toastr';
import Logout from '../../../shared/auth/Logout';
import FieldSupportSideBar from '../../../../layouts/field-support/side-bar/FieldSupportSideBar';
import { admin, API_URL, operations, TECHNICAL_SUPPORT } from '../../../../../utils/config';
import VehicleTracking from '../../../shared/field-support/vehicle-tracking/VehicleTracking';

class FieldSupportVehicleTracking extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
    };
  }

  componentDidMount() {
    this.getUser();
    this.fetchLoggedOnUser();
  }

  getUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employee/loggedOnEmployee`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        this.setState({
          user: data.data,
        });
      })
      .catch(() => {});
  }

  fetchLoggedOnUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employeeReg/logged`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        if (
          data.data.department_id !== `${operations}` &&
          data.data.department_id !== `${TECHNICAL_SUPPORT}` &&
          data.data.department_id !== `${admin}`
        ) {
          this.props.history.push('/login');
          toastr.info('You are not allowed to view this page');
        }
      })
      .catch((err) => {});
  }

  render() {
    const { user } = this.state;
    const { firstName } = user;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <Link className="s-sidebar__trigger" to="#">
                <i className="fa fa-bars mr-3" />
              </Link>
              <FieldSupportSideBar />

              <h3 className="col-lg-6 overview-part d-none d-md-flex">Vehicle Tracking</h3>

              <h3 className="col-6 overview-part d-sm-block d-md-none mr-5">Vehicle T.</h3>

              <div className="d-flex profile-mail col-6">
                <h6>
                  <i className="mr-auto d-none d-md-inline">{firstName} </i>
                  <Logout />
                </h6>
              </div>
            </div>
          </div>
          <VehicleTracking />
        </main>
      </div>
    );
  }
}

export default FieldSupportVehicleTracking;
