/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import useCheckTwoDepartments from '../../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';
import { useUserProfile } from '../../../../../../hooks/shared/user/useUserProfile';
import { SALES, SUPER_ADMIN } from '../../../../../../utils/departments/helpers';
import SalesNavBar from '../../../../../layouts/sales/SalesNavBar';
import InstallationsPerDateRange from '../../../../shared/installations/per-basestation/date-range/InstallationsPerDateRange';

const SalesInstallationsPerDateRange = ({ history }) => {
  useCheckTwoDepartments(`${SALES}`, `${SUPER_ADMIN}`, history);

  const { profile, getUserProfile } = useUserProfile();

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <SalesNavBar currentPage="Installations Date Range" position={profile.firstName} />
        <InstallationsPerDateRange />
      </main>
    </div>
  );
};

export default SalesInstallationsPerDateRange;
