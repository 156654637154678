/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { ToastContainer } from 'react-toastify';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import '../leads-and-managers/index.css';
import { OverviewProvider } from '../../../../../context/shared/installations/reschedule/OverviewContext';
import RescheduleOverviewBody from '../leads-and-managers/RescheduleOverviewBody';
import { SALES, VICE_PRESIDENT_SALES } from '../../../../../utils/config';
import { OPERATIONS, SUPER_ADMIN } from '../../../../../utils/departments/helpers';
import useCheckTwoDepartments from '../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';
import SalesNavBar from '../../../../layouts/sales/SalesNavBar';
import useCheckFiveDepartments from '../../../../../hooks/shared/custom/check-department/useCheckFiveDepartments';

const RescheduledOverview = ({ history }) => {
  const { profile } = useUser();
  const { firstName } = profile;
  useCheckFiveDepartments(`${SALES}`, `${OPERATIONS}`, `${VICE_PRESIDENT_SALES}`, `${SUPER_ADMIN}`, history);
  return (
    <div className="s-layout">
      <OverviewProvider>
        <main className="s-layout__content col-lg-10 p-0">
          <SalesNavBar currentPage="Rescheduled Installations" position={firstName} />
          <RescheduleOverviewBody />
        </main>
      </OverviewProvider>
      <ToastContainer />
    </div>
  );
};
export default RescheduledOverview;
