import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label, LabelList } from 'recharts';
import Box from '@mui/material/Box';
import { TableCellState } from '../components/tempData';

const BarCharts = ({ data, isLoading, zones }) => {
  const [difference, setDifference] = React.useState([]);
  React.useEffect(() => {
    if (data.length > 0) {
      const chartData = zones.map((x, index) => ({
        zone: x.name,
        difference: data[index],
      }));
      setDifference(chartData);
    }
  }, [data]);
  return (
    <Box sx={{ mr: 5 }}>
      <Box sx={{ width: { xs: '90vw', md: 1000, lg: '100%' }, overflowX: 'auto' }}>
        <Box sx={{ width: '100%', overflowX: 'scroll' }}>
          <BarChart
            width={4200}
            height={550}
            data={difference}
            margin={{
              top: 5,
              right: 10,
              left: 0,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="zone" />
            <YAxis>
              <Label
                value="Difference in Expired"
                position="insideLeft"
                angle={-90}
                style={{ textAnchor: 'start' }}
                offset={20}
              />
            </YAxis>
            <Tooltip />
            <Legend />

            <Bar type="monotone" dataKey="difference" fill="#8884d8">
            <LabelList dataKey="difference" position="top" />
            </Bar>
          </BarChart>
        </Box>
      </Box>
    </Box>
  );
};

export default BarCharts;
