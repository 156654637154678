/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import { NoItemRow } from '../../../../../utils/loader/loadingHelper';
import helpers from '../../../../../utils/helpers';

const BusinessDevelopmentCustomerRevenueTable = ({ items, isLoading }) => {
  const tableData =
    items && items.length ? (
      items.map((item, i) => {
        const {
          debtor_no,
          name,
          macaddress,
          reference,
          counter,
          type_no,
          tran_date, 
          account,
          memo_,
          amount,
          zone,
          basestation,
        } = item;
        return (
          <tr className="text-nowrap" key={i}>
            <td>{i + 1}</td>
            <td>{debtor_no}</td>
            <td>{name}</td>
            <td>{macaddress}</td>
            <td>{reference}</td>
            <td>{counter}</td>
            <td>{type_no}</td>
            <td>{tran_date}</td>
            <td>{account}</td>
            <td>{memo_}</td>
            {/* <td>NGN {amount}</td> */}
            <td>NGN {helpers.addCommas(amount)}</td>
            <td>{zone}</td>
            <td>{basestation}</td>
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan="20" text="There Are No Customers" />
    );
  return (
    <div className="card x-panel mt-2 px-0">
      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <thead>
            <tr className="">
              <th>#</th>
              <th>Debtor Number</th>
              <th>Name</th>
              <th>Mac ID</th>
              <th>Reference</th>
              <th>Counter</th>
              <th>Type Number</th>
              <th>Transaction Date</th>
              <th>Account</th>
              <th>Memo</th>
              <th>Amount</th>
              <th>Zone</th>
              <th>Basestation</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td className="text-center">
                <img src={loader} alt="loading gif" />
                </td>
              </tr>
            ) : (
              tableData
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

BusinessDevelopmentCustomerRevenueTable.propTypes = {
  items: PropTypes.arrayOf.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default BusinessDevelopmentCustomerRevenueTable;
