/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React from 'react';
import AccountsNavBar from '../../../../layouts/accounts/AccountsNavBar';
import NoLosTable from './NoLosTable';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import useCheckTwoDepartments from '../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';
import { ACCOUNTS, SUPER_ADMIN } from '../../../../../utils/departments/helpers';

const AccountsNoLos = ({ history }) => {
  const { profile } = useUser();
  const { firstName } = profile;
  useCheckTwoDepartments(`${ACCOUNTS}`, `${SUPER_ADMIN}`, history);
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <AccountsNavBar currentPage="NO LOS" position={firstName} />

        <div className="container">
          <div className="row mt-5">
            <div className="col-lg-12 col-md-12 col-sm-12 mt-5">
              <div className="card x-panel">
                <NoLosTable />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
AccountsNoLos.propTypes = {
  history: PropTypes.shape(),
};

export default AccountsNoLos;
