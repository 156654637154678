import React, { useContext } from 'react';
import AlarmsTableHead from './shared/AlarmsTableHead';
import loader from '../../../../../../../assets/img/icons/TizetiLoaderColored.gif';
import GeniatechContext from '../../../../../../../context/GeniatechContext';
import helpers from '../../../../../../../utils/helpers';

const SearchResults = () => {
  const { loading, fetched } = useContext(GeniatechContext);
  const tableData =
    fetched && fetched.length
      ? fetched.map((row, i) => (
          <tr className="text-nowrap" style={{ cursor: 'pointer' }} key={row.id}>
            <td>{i + 1}</td>
            <td>{row.name}</td>
            <td>{row.acknowledged_by}</td>
            <td>{row.code}</td>
            <td>{row.duration}</td>
            <td>{row.mac}</td>
            <td>{row.message}</td>
            <td>{row.managed_account}</td>
            <td>{row.network}</td>
            <td>{row.severity}</td>
            <td>{row.source}</td>
            <td>{row.source_type}</td>
            <td>{row.status}</td>
            <td>{helpers.formatDateAndTime(row.time_raised)}</td>
            <td>{row.tower}</td>
            <td>{row.ip}</td>
          </tr>
        ))
      : 'no alarm';
  return (
    <div className="card x-panel mt-2 px-0">
      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <AlarmsTableHead />
          <tbody>
            {loading ? (
              <tr>
                <img src={loader} alt="loading gif" />
              </tr>
            ) : (
              tableData
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SearchResults;
