import React from 'react';
import AssetTeamNavBar from '../../../../layouts/asset-team/AssetTeamNavBar';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import * as helpers from '../../../../../utils/asset-team/AssetTeamHelperBody';
import { useActiveCustomer } from '../../../../../hooks/shared/asset-team/active/useActiveCustomer';
import { AssetTeamHelperActive, FormatTime } from '../../../../../utils/asset-team/AssetTeamHelper';

const AssetGraphs = () => {
  const { profile } = useUser();
  const { firstName } = profile;
  const { items, time, total } = useActiveCustomer();
  const { data } = AssetTeamHelperActive(items);
  const { fetchTime } = FormatTime(time);
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <AssetTeamNavBar currentPage="Active Customer" position={firstName} />
        <div className="mt-5">
          <div className="container">
            <div className="row my-5">
              <div className="col-12 pt-5">{helpers.AssetTeamHeader(items, time, total, fetchTime)}</div>
              <div className="col-12 mb-5 pr-5">{helpers.AssetTeamHelperGraph(data)}</div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AssetGraphs;
