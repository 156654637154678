/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import moment from 'moment';
import IncidentDetails from './IncidentDetails';
import view from '../../../../../assets/img/icons/view.png';

class IncidentReportTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      incidentId: {},
    };
  }

  getIncident(incident) {
    this.setState({
      incidentId: incident,
    });
  }

  render() {
    const { incident } = this.props;

    /* additional_action: "Actions"
        cause_of_incident: "Incident"
        changes_made: "Changes"
        confirmation_of_resolution: false
        created_at: "2019-03-13T09:18:53.000Z"
        date: "2019-03-13T00:00:00.000Z"
        deleted_at: null
        description: "Description"
        duration_end_time: "12:59:00"
        duration_start_time: "15:00:00"
        employee_auth_id: "010175b3-f631-4bd5-9451-b068755fed2a"
        fix_actions: "Fix"
        id: 2
        impact: "Impact"
        incidence_prevention: "Prevention"
        incident_notification_source: "Source"
        resolution_team: "Tolu and Toyosi"
        root_cause: "Incident"
        system_affected: "Affected"
        updated_at: "2019-03-13T09:18:53.000Z" */

    const incidentsList = incident ? (
      incident.map((incidents, i) => (
        <tr key={i + 1}>
          <td>{i + 1}</td>
          {/* <td>Agungi One</td> */}
          <td>{incidents.system_affected}</td>
          <td nowrap="no-wrap">{incidents.impact}</td>
          <td nowrap="no-wrap">{incidents.duration_start_time}</td>
          <td nowrap="no-wrap">{incidents.duration_end_time}</td>
          <td nowrap="no-wrap">{moment(incidents.created_at).format('YYYY-MM-DD')}</td>
          {/* <td>Ehimen</td> */}
          <td>
            <div className="d-flex">
              <button
                type="submit"
                onClick={() => this.getIncident(incidents)}
                data-toggle="modal"
                data-placement="right"
                data-target="#incidentDetail"
                style={{
                  border: '0',
                  backgroundColor: 'white',
                }}
              >
                <span className="view1">
                  <img src={view} alt="" data-toggle="tooltip" data-placement="right" title="View" />
                </span>
              </button>
              <IncidentDetails incident={this.state.incidentId} />
              {/* <button type="submit" data-toggle="modal" data-target="#editIncident" style={{
                                    border: '0',
                                    backgroundColor: 'white'
                                }}><span className="view"><img
                                    src={edit}
                                    alt=''
                                    data-toggle="tooltip" data-placement="top" title="Edit"/></span></button>
                                <EditIncidentReport/> */}
            </div>
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td>
          <p>No incidents</p>
        </td>
      </tr>
    );
    return <tbody id="reduce-length">{incidentsList}</tbody>;
  }
}

export default IncidentReportTable;
