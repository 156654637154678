/* eslint-disable react/prop-types */
import React from 'react';
import FieldSupportNavBar from '../../../../layouts/field-support/FieldSupportNavBar';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import RelocationBody from '../../../shared/relocation/RelocationBody';
import useCheckDepartment from '../../../../../hooks/shared/custom/check-department/field-support/useCheckDepartment';

const FieldSupportRelocation = ({ history }) => {
  useCheckDepartment(history);
  const {
    profile: { firstName },
  } = useUser();

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <FieldSupportNavBar currentPage="Relocation" position={firstName} />
        <RelocationBody />
      </main>
    </div>
  );
};
export default FieldSupportRelocation;
