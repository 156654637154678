import { BASE_URL } from '../../url';
// eslint-disable-next-line import/newline-after-import
import { API_URL } from '../../../../utils/config';
export const REQUSITION_URL = 'http://api.tizeti.com';
export const MAKE_REQUISITION_URL = `${API_URL}/requisition2`;
export const UPLOAD_SIGNATURE_URL = `${BASE_URL}/requisition/upload/signature/cloud`;
export const GET_BANKS_URL = `${BASE_URL}/requisition/fetch/banklist`;
export const FILTER_REQUISITION_URL = `${BASE_URL}/requisition/fetch/requisition/filter`;
export const GET_REQUISITIONS_BY_DEPARTMENT_URL = `${API_URL}/requisition2/department`;
export const MANAGER_UPDATE_REQUSITION_STATUS_URL = `${API_URL}/requisition2`;
export const EXECUTIVE_UPDATE_REQUSITION_STATUS_URL = `${API_URL}/requisition2/executiveapproval`;
export const GET_EXECUTIVE_REQUISITION_URL = `${API_URL}/requisition2/getExecutiveList`;
export const ACCOUNTS_UPDATE_REQUSITION_STATUS_URL = `${API_URL}/requisition2/accountApproval`;
export const GET_CEO_AND_COO_REQUISITION_URL = `${API_URL}/requisition2/getCeoList`;
export const CEO_COO_UPDATE_REQUISITIONS_STATUS = `${API_URL}/requisition2/ceo_approval`;
export const GET_EMPLOYEE_REQUISITION_URL = `${API_URL}/requisition2/staff`;
