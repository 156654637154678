/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect } from 'react';
import qs from 'qs';
import { CSVLink } from 'react-csv';
import ReactPaginate from 'react-paginate';
import useUsageStatistics from '../../../../../../../hooks/shared/customers/useUsageStatistics';
import ActiveAndInactiveTable from './ActiveAndInactiveTable';

const ActiveAndInactiveBody = () => {
  const { getCustomersUsage, items, allPages, isLoading } = useUsageStatistics();

  const [recordsPerPage, setRecordsPerPage] = useState(100);
  const [from, setFrom] = useState(
    `${new Date(new Date().getTime() - 60 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10)}`,
  );
  const [to, setTo] = useState(
    `${new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10)}`,
  );
  const [page] = useState(1);

  const data = qs.stringify({
    action: 'wificall_customer_usage_v2',
    from: `${from}`,
    to: `${to}`,
    page_no: `${page}`,
    no_of_records_per_page: `${recordsPerPage}`,
  });

  useEffect(() => {
    getCustomersUsage(data);
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    getCustomersUsage(data);
  };

  const handlePageClick = async (e) => {
    const selectedPage = e.selected + 1;
    const pageData = qs.stringify({
      action: 'wificall_customer_usage_v2',
      from: `${from}`,
      to: `${to}`,
      pageno: `${selectedPage}`,
      no_of_records_per_page: `${recordsPerPage}`,
    });
    getCustomersUsage(pageData);
  };

  const headers = [
    { label: 'Name', key: 'fullname' },
    { label: 'Phone', key: 'phone' },
    { label: 'Email', key: 'email' },
    { label: 'Balance', key: 'balance' },
    { label: 'First Used', key: 'firstusedate' },
    { label: 'Last Used', key: 'lastused' },
    { label: 'Date Created', key: 'created_date' },
  ];

  return (
    <div className="col-12 mt-5">
      <form className="form-horizontal d-flex mb-2" onSubmit={onSubmit}>
        <div className="col-lg-12 col-md-12 col-sm-12 d-flex flex-wrap">
          <div className="form-group col-lg-2 col-md-3 col-sm-12">
            <p className="d-flex m-0 p-0">
              <small className="text-nowrap copy-font mr-1" htmlFor="recordsPerPage">
                Records Per Page:
              </small>
              <input
                type="number"
                className="form-control"
                style={{ width: '50px' }}
                name="recordsPerPage"
                id="recordsPerPage"
                min="1"
                max="1000"
                value={recordsPerPage}
                onChange={(e) => setRecordsPerPage(e.target.value)}
              />
            </p>
          </div>
          <div className="form-group mr-4 col-lg-2 col-md-3 col-sm-12">
            <p className="d-flex m-0 p-0">
              <small className="text-nowrap copy-font mr-1">From: </small>
              <input
                type="date"
                className="form-control px-1"
                name="from"
                onChange={(e) => setFrom(e.target.value)}
                value={from}
              />
            </p>
          </div>
          <div className="form-group mr-3 col-lg-2 col-md-3 col-sm-12">
            <p className="d-flex m-0 p-0">
              <small className="text-nowrap copy-font mr-1">To: </small>
              <input
                type="date"
                className="form-control px-1"
                name="to"
                onChange={(e) => setTo(e.target.value)}
                value={to}
              />
            </p>
          </div>

          <div className="ml-3">
            <button className="btn btn-green shadow-md" type="submit">
              Search
            </button>
          </div>
          {items.length ? (
            <div className="ml-3">
              <CSVLink
                data={items}
                headers={headers}
                filename="Active_Inactive_Customers.csv"
                className="btn btn-green shadow-md"
                style={{ color: 'white' }}
              >
                Download Data
              </CSVLink>
            </div>
          ) : null}
        </div>
      </form>

      <ActiveAndInactiveTable items={items} isLoading={isLoading} />
      {allPages > 1 ? (
        <ReactPaginate
          previousLabel="previous"
          nextLabel="next"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={allPages}
          marginPagesDisplayed={3}
          pageRangeDisplayed={4}
          onPageChange={handlePageClick}
          containerClassName="pagination"
          subContainerClassName="pages pagination"
          activeClassName="active"
        />
      ) : null}
    </div>
  );
};

export default ActiveAndInactiveBody;
