import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { edit_basestation } from '../../../../actions/shared/basestations/EditBaseStation';
import { get_all_basestation_sub_zones } from '../../../../actions/shared/basestations/GetAllBasestationsSubZones';
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { ACCOUNTS, NOC, OPERATIONS } from '../../../../utils/departments/helpers';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';

export default function EditDialogue({
  openEditDialogue,
  setOpenEditDialogue,
  editAction,
  getDepartmentId,
  getZones,
  roleId,
}) {
  const [inputValue, setInputValue] = React.useState({});
  const [errorNotifierNOC, setErrorNotifierNOC] = React.useState(false);
  const [errorNotifierOperations, setErrorNotifierOperations] = React.useState(false);
  const [getSubZones, setGetSubZones] = React.useState([]);

  // console.log(editAction);
  const handleClose = () => {
    setOpenEditDialogue(false);
    setInputValue({}); //reset the fields on close of the dialogue
  };

  const handleConfirmEdit = async () => {
    try {
      await edit_basestation(editAction.id, inputValue);
      setOpenEditDialogue(false);
      // console.log(res);

      toast.success('Basestation successfully updated');
    } catch (error) {
      console.error(error);
      toast.error('Error updating basestation');
    }
  };

  React.useEffect(() => {
    // console.log(inputValue);
    // console.log(getSubZones);

    // disable update for operations if the fields are not filled
    if (getDepartmentId === OPERATIONS) {
      if (inputValue.zone_id === undefined || inputValue.sub_zone_id === undefined) {
        setErrorNotifierOperations(true);
      }

      if (inputValue.zone_id) {
        async function getAllSubZones() {
          const subZones = await get_all_basestation_sub_zones(inputValue.zone_id);
          // console.log(subZones);
          setGetSubZones(subZones);
        }
        getAllSubZones();
      }
    }
    if (getDepartmentId === NOC || getDepartmentId === ACCOUNTS) {
      if (Object.keys(inputValue).length === 0) {
        setErrorNotifierNOC(true);
      }
    }
  }, [inputValue]);

  const numbersRegex = /^[0-9]+(\.[0-9]+)?$/;

  const shouldEditBasestation = () => {
    return (
      roleId > 2 &&
      roleId !== 6 &&
      (getDepartmentId === `${NOC}` || getDepartmentId === `${OPERATIONS}` || getDepartmentId === `${ACCOUNTS}`)
    );
  };

  const formatNumber = (value) => {
    return new Intl.NumberFormat().format(value);
  };

  // console.log(getSubZones);
  return (
    <React.Fragment>
      <Dialog
        open={openEditDialogue}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Edit Base Station'}</DialogTitle>
        <DialogContent>
          {editAction !== undefined && shouldEditBasestation && (
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  id="basestation-name"
                  label="Name"
                  variant="outlined"
                  defaultValue={editAction.name}
                  disabled
                />
                <TextField id="address" label="Address" variant="outlined" defaultValue={editAction.address} disabled />
                {/* <TextField
                  id="installer"
                  label="Installer"
                  variant="outlined"
                  defaultValue={editAction.installer}
                  disabled
                /> */}

                <TextField
                  id="latitude"
                  label="Latitude"
                  variant="outlined"
                  helperText={getDepartmentId === `${NOC}` ? 'required' : ''}
                  value={inputValue.latitude === undefined ? editAction.latitude : inputValue.latitude}
                  onChange={(e) => {
                    e.persist();
                    setInputValue((prev) => ({ ...prev, latitude: e.target.value }));
                    setErrorNotifierNOC(false);
                  }}
                  error={errorNotifierNOC}
                  disabled={getDepartmentId === `${NOC}` ? false : true}
                />
                <TextField
                  id="longitude"
                  label="Longitude"
                  variant="outlined"
                  helperText={getDepartmentId === `${NOC}` ? 'required' : ''}
                  value={inputValue.longitude === undefined ? editAction.longitude : inputValue.longitude}
                  onChange={(e) => {
                    e.persist();
                    setInputValue((prev) => ({ ...prev, longitude: e.target.value }));
                    setErrorNotifierNOC(false);
                  }}
                  error={errorNotifierNOC}
                  disabled={getDepartmentId === `${NOC}` ? false : true}
                />
                <TextField
                  id="distance"
                  label="Distance"
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">km</InputAdornment>,
                  }}
                  helperText={getDepartmentId === `${NOC}` ? 'required' : ''}
                  value={inputValue.distance === undefined ? editAction.distance : formatNumber(inputValue.distance)}
                  onChange={(e) => {
                    e.persist();
                    setInputValue((prev) => ({ ...prev, distance: e.target.value.replace(/,/g, '') }));
                    setErrorNotifierNOC(false);
                  }}
                  error={errorNotifierNOC}
                  disabled={getDepartmentId === `${NOC}` ? false : true}
                />
                <TextField
                  id="rent-amount"
                  label="Rent-Amount"
                  variant="outlined"
                  helperText={errorNotifierNOC ? 'must contain only numbers' : ''}
                  value={
                    inputValue.setup_amount === undefined
                      ? editAction.setup_amount
                      : formatNumber(inputValue.setup_amount)
                  }
                  onChange={(e) => {
                    e.persist();
                    setInputValue((prev) => ({ ...prev, setup_amount: e.target.value.replace(/,/g, '') }));
                    inputValue.setup_amount && !numbersRegex.test(inputValue.setup_amount)
                      ? setErrorNotifierNOC(true)
                      : setErrorNotifierNOC(false);
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">₦</InputAdornment>,
                  }}
                  error={errorNotifierNOC}
                  disabled={getDepartmentId === `${NOC}` || getDepartmentId === `${ACCOUNTS}` ? false : true}
                />

                <TextField
                  id="other-charges"
                  label="Other Charges"
                  variant="outlined"
                  value={
                    inputValue.other_charges === undefined
                      ? editAction.other_charges
                      : formatNumber(inputValue.other_charges)
                  }
                  helperText={errorNotifierNOC ? 'must contain only numbers' : ''}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">₦</InputAdornment>,
                  }}
                  onChange={(e) => {
                    e.persist();
                    setInputValue((prev) => ({ ...prev, other_charges: e.target.value.replace(/,/g, '') }));
                    inputValue.other_charges && !numbersRegex.test(inputValue.other_charges)
                      ? setErrorNotifierNOC(true)
                      : setErrorNotifierNOC(false);
                  }}
                  error={errorNotifierNOC}
                />
                <TextField
                  type="date"
                  id="rent-commencement"
                  label="Rent Commencement"
                  variant="outlined"
                  helperText={getDepartmentId === `${NOC}` ? 'required' : ''}
                  value={
                    inputValue.rent_commencement === undefined
                      ? editAction.rent_commencement
                      : inputValue.rent_commencement
                  }
                  onChange={(e) => {
                    e.persist();
                    setInputValue((prev) => ({ ...prev, rent_commencement: e.target.value }));
                    setErrorNotifierNOC(false);
                  }}
                  error={errorNotifierNOC}
                  disabled={getDepartmentId === `${NOC}` || getDepartmentId === `${ACCOUNTS}` ? false : true}
                />

                <TextField
                  type="date"
                  id="rent-expiration"
                  label="Rent Expiration"
                  variant="outlined"
                  helperText={getDepartmentId === `${NOC}` ? 'required' : ''}
                  value={inputValue.rent_end_date === undefined ? editAction.rent_end_date : inputValue.rent_end_date}
                  onChange={(e) => {
                    e.persist();
                    setInputValue((prev) => ({ ...prev, rent_end_date: e.target.value }));
                    setErrorNotifierNOC(false);
                  }}
                  error={errorNotifierNOC}
                  disabled={getDepartmentId === `${NOC}` || getDepartmentId === `${ACCOUNTS}` ? false : true}
                />
                {
                  <TextField
                    id="outlined-select-zones"
                    select
                    label="Zones"
                    value={inputValue.zone_id === undefined ? '' : inputValue.zone_id}
                    helperText={getDepartmentId === `${OPERATIONS}` ? 'required' : ''}
                    error={errorNotifierOperations}
                    disabled={getDepartmentId === `${OPERATIONS}` ? false : true}
                    focused={getDepartmentId === `${OPERATIONS}` ? true : false}
                    onChange={(e) => {
                      setInputValue((prev) => ({ ...prev, zone_id: e.target.value }));
                      setErrorNotifierOperations(false);
                    }}
                  >
                    {getZones &&
                      getZones.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                  </TextField>
                }

                <TextField
                  id="outlined-select-sub-zones"
                  select
                  label="Sub Zones"
                  value={inputValue.sub_zone_id === undefined ? '' : inputValue.sub_zone_id}
                  helperText={getDepartmentId === `${OPERATIONS}` ? 'required' : ''}
                  error={errorNotifierOperations}
                  disabled={getDepartmentId === `${OPERATIONS}` && inputValue.zone_id !== undefined ? false : true}
                  onChange={(e) => {
                    setInputValue((prev) => ({ ...prev, sub_zone_id: e.target.value }));
                    setErrorNotifierOperations(false);
                  }}
                >
                  {getSubZones[0] !== undefined ? (
                    getSubZones.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>{editAction.sub_zone.name}</MenuItem>
                  )}
                </TextField>
              </div>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleConfirmEdit}
            autoFocus
            disabled={errorNotifierNOC || errorNotifierOperations ? true : false}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
