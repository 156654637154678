import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import TableRow from '@mui/material/TableRow';
import { RefundAnalytics } from '../../../../../actions/shared/refund-analytics/RefundAnalyticsActions';

const style = {
  padding: '10px',
  border: '1px solid #ccc',
  borderRadius: '4px',
  fontSize: '16px',
};
const today = new Date();
const sevenDaysAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
const oneMonthDaysAgo = new Date(today.getTime() - 31 * 24 * 60 * 60 * 1000);
const minDate = new Date('2018-01-01');

function formatDate(date) {
  const day = ('0' + date.getDate()).slice(-2);
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
}

export default function DefaultPage() {
  const [from, setFrom] = React.useState(formatDate(sevenDaysAgo));
  const [to, setTo] = React.useState(formatDate(today));
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);

  const columns = [
    { id: 'serial', label: 'S/N', minWidth: 50 },
    { id: 'state', label: 'State', minWidth: 170 },
    {
      id: 'coo_approval',
      label: 'Coo Approved',
      minWidth: 70,
      align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'fse_approval',
      label: 'Fse Approved',
      minWidth: 170,
      align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'refund_status',
      label: 'Account Approved',
      minWidth: 170,
      align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'sales_approval',
      label: 'Sales Approved',
      minWidth: 170,
      align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
  ];

  function createData(serial, state, coo_approval, fse_approval, refund_status, sales_approval) {
    return { serial, state, coo_approval, fse_approval, refund_status, sales_approval };
  }

  const Refund = async () => {
    setLoading(true);
    try {
      const res = await RefundAnalytics(from, to);
      console.log('🚀 ~ Refund ~ res:', res);
      if (res.data) {
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    Refund();
  }, []);

  const rows =
    data &&
    data.map((row, index) => {
      return createData(
        index + 1,
        row.state,
        row.coo_approval_count,
        row.fse_approval_count,
        row.refund_status_count,
        row.sales_approval_count,
      );
    });

  const handleSubmit = (e) => {
    e.preventDefault();
    Refund();
  };

  const handleSelectChange = (e) => {
    if (e.target.value === '1week') {
      setFrom(formatDate(sevenDaysAgo));
      setTo(formatDate(today));
    }
    if (e.target.value === '1month') {
      setFrom(formatDate(oneMonthDaysAgo));
      setTo(formatDate(today));
    }
  };

  return (
    <div>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        gap="18px"
        sx={{ width: '95%', mt: 10, ml: 4 }}
      >
        {/* A date filter with from and to */}
        <div>
          <div>From:</div>
          <input
            type="date"
            name="from"
            id="from-date"
            style={{ ...style }}
            value={from}
            min={formatDate(minDate)}
            max={formatDate(today)}
            onChange={(e) => setFrom(e.target.value)}
          />
        </div>
        <div>
          <div>To:</div>
          <input
            type="date"
            name="to"
            id="to-date"
            style={{ ...style }}
            value={to}
            min={formatDate(minDate)}
            max={formatDate(today)}
            onChange={(e) => setTo(e.target.value)}
          />
        </div>

        {/* filter by 1week or 1 month specifically */}
        <div>
          <div>Filter By</div>
          <div>
            <select name="filter" id="filter" style={{ ...style }} onChange={handleSelectChange}>
              <option value="1week">1 Week</option>
              <option value="1month">1 Month</option>
            </select>
          </div>
        </div>

        {/* submit the query */}
        <button style={{ ...style, background: '#27a834', color: 'white', marginTop: 25 }} onMouseDown={handleSubmit}>
          Submit
        </button>
      </Box>

      <Box sx={{ width: '95%', mt: 5, ml: 4 }}>
        {loading ? (
          <Typography variant="h6" sx={{ mb: 12 }}>
            loading...
          </Typography>
        ) : !data.length ? (
          <Typography sx={{ mb: 12 }} variant="h6">
            No content! Please select another date range
          </Typography>
        ) : (
          <Paper c>
            <TableContainer sx={{ maxHeight: 740 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!loading &&
                    rows.map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                          {columns.map((column) => {
                            const value = row[column.id] || '_';
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format && typeof value === 'number' ? column.format(value) : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          </Paper>
        )}
      </Box>
    </div>
  );
}
