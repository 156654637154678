/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-plusplus */
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import URLS from '../../../../../routes/urls';
import { useInstallationsPerBasestation } from './hooks/useInstallationsPerBasestation';
import AccountExtension from '../../account-extension/AccountExtension';
import { TizetiGIFLogoTable } from '../../../../../utils/loader/loadingHelper';
import BarChart from '../../components/BarChart';
import InstallationDuration from './InstallationDuration';
import InstallationsPerBasestationTable from './InstallationsPerBasestationTable';

const InstallationsPerBasestationBody = () => {
  const { items, time, total, isLoading, getInstallations } = useInstallationsPerBasestation();
  const [days, setDays] = useState(7);
  const [data, setData] = useState({});
  const [count, setCount] = useState([]);
  const [basestation, setBasestation] = useState([]);

  const fetchTime = () => {
    return moment(time)
      .add(1, 'hours')
      .format('DD-MMM-YYYY hh:mm A');
  };

  const fetchData = useCallback(async () => {
    if (items.length > 0) {
      items.sort((a, b) => a.installation_count - b.installation_count);
      if (count.length === 0) {
        for (let i = 0; i < items.length; i++) {
          count.push(items[i].installation_count);
          basestation.push(items[i].basestationame);
        }
      } else {
        setCount([]);
        setBasestation([]);
      }
      setData({
        labels: basestation,
        datasets: [
          {
            label: 'count',
            data: count,
            backgroundColor: 'rgb(115, 255, 136)',
          },
        ],
      });
    }
  }, [basestation, count, items]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="container">
      <div className="row my-5">
        <div className="col-12 my-5">
          <div className="d-flex justify-content-between">
            <p>
              {time ? (
                <p className="h5" style={{ fontFamily: 'Rubik' }}>
                  <span className="text-uppercase">Updated At: </span> {fetchTime()} <br />
                  <span className="text-uppercase" style={{ textDecoration: 'none' }}>
                    Total: <span className="badge badge-success mx-2">{total}</span>
                  </span>
                </p>
              ) : (
                'Fetching Time...'
              )}
            </p>
            <AccountExtension />
            <div className="ml-2">
              <Link to={URLS.SALES_INSTALLATIONS_DATE_RANGE} target="_self">
                <button type="button" className="btn btn-primary">
                  Installations Date Range
                </button>
              </Link>
            </div>
          </div>
          <InstallationDuration
            days={days}
            onChange={(e) => setDays(e.target.value)}
            isLoading={isLoading}
            getInstallations={getInstallations}
          />
          <BarChart
            data={data}
            options={{
              maintainAspectRatio: true,
              responsive: true,
              scales: {
                yAxes: [
                  {
                    scaleLabel: { display: true, labelString: 'COUNT', fontStyle: 'bold', fontSize: 17 },
                  },
                ],
                xAxes: [
                  {
                    ticks: { fontStyle: 'bold' },
                    scaleLabel: { display: true, labelString: 'BASESTATION NAME', fontStyle: 'bold', fontSize: 17 },
                  },
                ],
              },
            }}
          />
        </div>

        <div className="card x-panel">
          <div className="ml-3">
            <button type="button" className="btn btn-dark">
              TOTAL:
              <span style={{ fontSize: '15px' }} className="badge badge-success mx-2">
                {total}
              </span>
            </button>
          </div>

          <div className="table-responsive content col-12">
            <table className="table table-striped">
              <thead>
                <tr className="">
                  <th>S/N</th>
                  <th>Basestation</th>
                  <th>Zone</th>
                  <th>Location</th>
                  <th>Country</th>
                  <th>Count</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <TizetiGIFLogoTable colSpan="5" />
                ) : (
                  <InstallationsPerBasestationTable days={days} items={items} />
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstallationsPerBasestationBody;
