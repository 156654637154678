import React from 'react';

export default {
  ratingLabels(rating) {
    switch (rating) {
      case 1:
        return 'Terrible';
      case 2:
        return 'Bad';
      case 3:
        return 'Average';
      case 4:
        return 'Very good';
      case 5:
        return 'Excellent';
      default:
        return 'no rating';
    }
  },
};
