import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useUser } from '../../../../../hooks/shared/user/useUser';

const CfoSideBarItem = ({ url, name }) => {
  const { user } = useUser();
  return Number(user.role_id) === 9 ? (
    <li className="s-sidebar__nav-link">
      <Link to={url} target="_self">
        <span className="mr-2">
          <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971015/Group-7.png" alt="" />
        </span>
        {name}
      </Link>
    </li>
  ) : null;
};
CfoSideBarItem.propTypes = {
  // eslint-disable-next-line react/require-default-props
  url: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  name: PropTypes.string,
};
export default CfoSideBarItem;
