import React from 'react';

const Schedule = () => {
  return (
    <div className="container px-4">
      <div className="row mt-5">
        <div className="col-lg-12 mt-5 mb-3 d-flex flex-wrap inner-addon left-addon">
          <div className="d-flex flex-wrap copy-font">
            <p className="d-flex mb-2">
              <small className="text-nowrap">Show from:</small>
              <input type="date" className="form-control" />
            </p>

            <p className="d-flex mb-2">
              <small className="ml-2">to:</small>
              <input type="date" className="form-control" />
            </p>
          </div>

          <div className="d-flex m-0 p-0">
            <div className="mr-2 m-0 p-0">
              <button className="btn btn-success shadow-md" type="submit">
                {' '}
                View Schedule
              </button>
            </div>

            <div className="mr-2 m-0 p-0">
              <button className="btn btn-green shadow-md" type="submit">
                {' '}
                Create Schedule
              </button>
            </div>
          </div>
        </div>

        <div className="col-12">
          <p className="copy-font">Schedule for: Sunday 5th May 2019 to Sunday 12th May 2019</p>
        </div>

        <div className="card x-panel">
          <div className="table-responsive content">
            <table className="table copy-font text-center">
              <thead>
                <tr>
                  <th>Staff Name</th>
                  <th>Sunday</th>
                  <th>Monday</th>
                  <th>Tuesday</th>
                  <th>Wednesday</th>
                  <th>Thursday</th>
                  <th>Friday</th>
                  <th>Saturday</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Michael</td>
                  <td>D</td>
                  <td>N</td>
                  <td>N</td>
                  <td>N</td>
                  <td>OFF</td>
                  <td>OFF</td>
                  <td>N</td>
                </tr>

                <tr>
                  <td>Anderson</td>
                  <td>N</td>
                  <td>N</td>
                  <td>D</td>
                  <td>N</td>
                  <td>OFF</td>
                  <td>OFF</td>
                  <td>N</td>
                </tr>

                <tr>
                  <td>Jide</td>
                  <td>N</td>
                  <td>D</td>
                  <td>N</td>
                  <td>D</td>
                  <td>OFF</td>
                  <td>OFF</td>
                  <td>N</td>
                </tr>

                <tr>
                  <td>Owolabi</td>
                  <td>N</td>
                  <td>N</td>
                  <td>N</td>
                  <td>N</td>
                  <td>OFF</td>
                  <td>OFF</td>
                  <td>N</td>
                </tr>

                <tr>
                  <td>Charles</td>
                  <td>N</td>
                  <td>D</td>
                  <td>D</td>
                  <td>N</td>
                  <td>OFF</td>
                  <td>OFF</td>
                  <td>N</td>
                </tr>

                <tr>
                  <td>Demola</td>
                  <td>N</td>
                  <td>N</td>
                  <td>N</td>
                  <td>N</td>
                  <td>OFF</td>
                  <td>OFF</td>
                  <td>N</td>
                </tr>

                <tr>
                  <td>Sunday</td>
                  <td>N</td>
                  <td>N</td>
                  <td>N</td>
                  <td>D</td>
                  <td>OFF</td>
                  <td>OFF</td>
                  <td>N</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Schedule;
