import React from 'react';
import PropTypes from 'prop-types';
import { SALES } from '../../../../../utils/config';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import CustomerInvoiceBody from './CustomerInvoiceBody';
import SalesNavBar from '../../../../layouts/sales/SalesNavBar';
import { SUPER_ADMIN, VICE_PRESIDENT_SALES } from '../../../../../utils/departments/helpers';
import useCheckFiveDepartments from '../../../../../hooks/shared/custom/check-department/useCheckFiveDepartments';

function SalesCustomerInvoice({ history }) {
  useCheckFiveDepartments(`${SALES}`, `${SUPER_ADMIN}`, `${VICE_PRESIDENT_SALES}`, history);
  const { profile } = useUser();
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <SalesNavBar currentPage="New Customer Invoice" position={profile.firstName} />
        <CustomerInvoiceBody />
      </main>
    </div>
  );
}

SalesCustomerInvoice.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default SalesCustomerInvoice;
