/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import mailblast from '../../../assets/img/icons/mailblast.svg';
import Logout from '../../staff/shared/auth/Logout';
import AdminDepartmentSideBar from './AdminDepartmentSideBar';
import profileImage from '../../../assets/img/icons/Profile.png';
import ViewUserProfile from '../../staff/shared/staff/ViewUserProfile';
import CreateProfilePrompt from '../../staff/shared/CreateProfilePrompt';

const AdminDeptNavBar = ({ currentPage, position }) => {
  return (
    <>
      <nav className="d-flex col-lg-12 p-2 top-header">
        <div className="mt-3 d-flex col-lg-12 p-0">
          <Link className="s-sidebar__trigger" to="#">
            <i className="fa fa-bars mr-3" />
          </Link>
          <AdminDepartmentSideBar />
          <h4 className="col-lg-6 overview-part">
            {currentPage}
            <span className="sr-only">(current)</span>
          </h4>

          <div className="d-flex profile-mail">
            <div>
              <Link
                to="/business-development/mail-blast"
                className="d-none d-md-flex"
                style={{ color: 'gray', textDecoration: 'none' }}
              >
                <img src={mailblast} alt="" className="logout mb-2 mr-2" />

                <h6 className="mt-2">
                  <strong>MailBlast</strong>
                </h6>
              </Link>
            </div>

            <div>
              <Link to="#" data-toggle="modal" data-target="#add-employee">
                <img src={profileImage} alt="" className="logout ml-2 mr-3" />
              </Link>
            </div>

            <div className="mr-2 d-none d-md-block">
              <h6 className="small">{position}</h6>
            </div>

            <div className="">
              <Logout />
            </div>
            <CreateProfilePrompt />
          </div>
        </div>
      </nav>
      <ViewUserProfile />
    </>
  );
};

AdminDeptNavBar.propTypes = {
  currentPage: PropTypes.string,
  position: PropTypes.string,
};

export default AdminDeptNavBar;
