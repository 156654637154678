import React, { useState } from 'react';
import { useSalesQuotation } from '../../../../hooks/business-development/sales-quotation/useSalesQuotation';
import { quotation, mail as mailTemplate } from './salesQuotationTemplate';
import axios from 'axios';
import toastr from 'toastr';
import { API_URL } from '../../../../utils/config';

const SendSalesQuotation = ({ mac, expiry }) => {
    const { invoice, handleChange, setInvoice, handleChangePrice, handleDiscountChange, isLoading } = useSalesQuotation(mac);

    const [loading, setLoading] = useState(false)

    const onSubmit = async (e) => {
        e.preventDefault();

        setLoading(true)
        try {
            const res = await axios.post(`${API_URL}/busdevuser/sendSalesQuotation`, {
                ...invoice,
                mailTemplate: mailTemplate(expiry)
            }, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                },
            })

            if (res.status === 200) {

                toastr.success(`Invoice sent to ${invoice.email}`)
            }
        } catch (error) {

            toastr.error("Invoice not sent...")
        }
        setLoading(false)
    };



    const downloadInvoice = async () => {


        const link = document.createElement('a');
        link.download = "quotation.pdf";
        link.target = "_blank"
        link.href = `${API_URL}/busdevuser/previewQuotation?date=${invoice.date}
                        &name=${invoice.name}
                        &email=${invoice.email}
                        &address=${invoice.address}
                        &valid_until=${invoice.valid_until}
                        &item_code=${invoice.item_code}
                        &item_description=${invoice.item_description}
                        &discount=${invoice.discount}
                        &quantity=${invoice.quantity}
                        &units=${invoice.units}
                        &price=${invoice.price}
                        &total=${invoice.total}
                        &sub_total=${invoice.sub_total}
                        &total_ex_vat=${invoice.total_ex_vat}
                        &total_with_vat=${invoice.total_with_vat}
                        `;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    }

    return (
        <div id="add_invoice" className="modal fade in" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Create Quotation</h4>
                        <button type="button" className="close mr-2" data-dismiss="modal">
                            x
                        </button>
                    </div>
                    <div className="modal-body mt-2 mb-4 p-0">
                        <form className="col-md-12" onSubmit={onSubmit}>
                            <div className='d-flex'>
                                <div className='form-group col-12'>

                                    <label className="m-0 p-0 copy-font" htmlFor="customerName">
                                        Invoice Date
                                    </label>
                                    <input
                                        type="date"
                                        className="form-control border border-info p-2"
                                        id="date"
                                        name="date"
                                        value={invoice.date}
                                        onChange={handleChange}

                                    />

                                </div>
                            </div>
                            <div className="d-flex">

                                <div className="form-group col-6">
                                    <label className="m-0 p-0 copy-font" htmlFor="customerName">
                                        Name
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control border border-info p-2"
                                        id="name"
                                        name="name"
                                        placeholder='name'
                                        value={isLoading ? 'loading..' : invoice.name}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group col-6">
                                    <label className="m-0 p-0 copy-font" htmlFor="customerEmail">
                                        Email
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control border border-info p-2"
                                        id="email"
                                        name="email"
                                        placeholder='email'
                                        value={isLoading ? 'loading..' : invoice.email}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className="d-flex">
                                <div className="form-group col-6">
                                    <label className="m-0 p-0 copy-font" htmlFor="address">
                                        Address
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control border border-info p-2"
                                        id="address"
                                        name="address"
                                        placeholder='address'
                                        value={isLoading ? 'loading..' : invoice.address}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group col-6">
                                    <label className="m-0 p-0 copy-font" htmlFor="valid_until">
                                        Valid Until (Date)
                                    </label>
                                    <input
                                        type="date"
                                        className="form-control border border-info p-2"
                                        id="valid_until"
                                        name="valid_until"
                                        placeholder='valid until'
                                        value={invoice.valid_until}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className="d-flex">
                                <div className="form-group col-6">
                                    <label className="m-0 p-0 copy-font" htmlFor="quantity">
                                        Quantity
                                    </label>
                                    <input
                                        type="number"
                                        min="1"
                                        className="form-control border border-info p-2"
                                        id="quantity"
                                        name="quantity"
                                        placeholder='quantity'
                                        value={isLoading ? 'loading..' : invoice.quantity}
                                        onChange={handleChangePrice}
                                    />
                                </div>

                                <div className="form-group col-6">
                                    <label className="m-0 p-0 copy-font" htmlFor="unit">
                                        Unit
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control border border-info p-2"
                                        id="units"
                                        name="units"
                                        placeholder='units'
                                        value={isLoading ? 'loading..' : invoice.units}
                                        onChange={handleChange}
                                    />
                                </div>

                            </div>

                            <div className="d-flex">
                                <div className="form-group col-6">
                                    <label className="m-0 p-0 copy-font" htmlFor="expiryDate">
                                        Item Code
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control border border-info p-2"
                                        id="item_code"
                                        name="item_code"
                                        placeholder='item code'
                                        value={isLoading ? 'loading..' : invoice.item_code}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group col-6">
                                    <label className="m-0 p-0 copy-font" htmlFor="itemDescription">
                                        Item Description
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control border border-info p-2"
                                        id="item_description"
                                        name="item_description"
                                        placeholder='item description'
                                        value={isLoading ? 'loading..' : invoice.item_description}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className="d-flex">
                                <div className="form-group col-6">
                                    <label className="m-0 p-0 copy-font" htmlFor="discount">
                                        Discount (%)
                                    </label>
                                    <input
                                        type="number"
                                        min="0"
                                        className="form-control border border-info p-2"
                                        id="discount"
                                        name="discount"
                                        value={invoice.discount}
                                        onChange={handleDiscountChange}
                                    />
                                </div>
                                <div className="form-group col-6">
                                    <label className="m-0 p-0 copy-font" htmlFor="price">
                                        Price
                                    </label>
                                    <input
                                        type="number"
                                        min="1"
                                        className="form-control border border-info p-2"
                                        id="price"
                                        name="price"
                                        placeholder='price'
                                        value={isLoading ? 'loading..' : invoice.price}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className="d-flex">
                                <div className="form-group col-6">
                                    <label className="m-0 p-0 copy-font" htmlFor="total">
                                        Total
                                    </label>
                                    <input
                                        type="number"
                                        min="1"
                                        className="form-control border border-info p-2"
                                        id="total"
                                        name="total"
                                        placeholder='total'
                                        value={isLoading ? 'loading..' : invoice.total}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="form-group col-6">
                                    <label className="m-0 p-0 copy-font" htmlFor="subTotal">
                                        Sub-Total
                                    </label>
                                    <input
                                        type="number"
                                        min="1"
                                        className="form-control border border-info p-2"
                                        id="sub_total"
                                        name="sub_total"
                                        placeholder='sub-total'
                                        value={isLoading ? 'loading..' : invoice.sub_total}
                                        onChange={handleChange}

                                    />
                                </div>

                            </div>

                            <div className="d-flex">
                                <div className="form-group col-6">
                                    <label className="m-0 p-0 copy-font" htmlFor="totalExVAT">
                                        Total Order Ex VAT
                                    </label>
                                    <input
                                        type="number"
                                        min="1"
                                        className="form-control border border-info p-2"
                                        id="total_ex_vat"
                                        name="total_ex_vat"

                                        value={isLoading ? 'loading..' : invoice.total_ex_vat}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group col-6">
                                    <label className="m-0 p-0 copy-font" htmlFor="totalWithVAT">
                                        Total Order VAT Inclusive
                                    </label>
                                    <input
                                        type="number"
                                        min="1"
                                        className="form-control border border-info p-2"
                                        id="total_with_vat"
                                        name="total_with_vat"

                                        value={isLoading ? 'loading..' : invoice.total_with_vat}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className="d-flex mt-3">
                                <div className="form-group col-12">
                                    <button type="button" className="btn btn-info col-12" onClick={downloadInvoice}>
                                        Preview Quotation
                                    </button>
                                </div>

                            </div>


                            <div className="form-group col-12 mt-3 d-flex">

                                {loading ? (
                                    <button className="btn btn-green px-3 col-12" disabled>
                                        <i className="fa fa-spinner fa-spin" />
                                    </button>
                                ) : (
                                    <button type="submit" className="btn btn-green col-12" disabled={!invoice.email}>
                                        Send Email
                                    </button>
                                )}

                            </div>



                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SendSalesQuotation;
