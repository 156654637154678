/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import { NoItemRow } from '../../../../../utils/loader/loadingHelper';

const CustomerSupportScheduleTable = ({ users, isLoading }) => {
  const colSpan = '8';

  const tableData =
    users && users.length ? (
      users.map((item, i) => {
        return (
          <tr className="text-nowrap" key={i}>
            <td>{i + 1}</td>
            <td>{item.firstName}</td>
            <td>{item.lastName}</td>
            <td>{item.email}</td>
            <td>{item.phoneNumber}</td>
            <td> {item.address}</td>
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan={colSpan} text="There are no records" />
    );
  return (
    <div className="card x-panel mt-2 px-0">
      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <thead>
            <tr className="">
              <th>#</th>
              <th>FirstName</th>
              <th>LastName</th>
              <th>Email</th>
              <th>PhoneNumber</th>
              <th>Address</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td className="text-center">
                  <img src={loader} alt="loading gif" />
                </td>
              </tr>
            ) : (
              tableData
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CustomerSupportScheduleTable;
