/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

const Details = ({ one }) => {
  const { firstName, lastName, email, phoneNumber, note, resolution, id } = one;

  return (
    <div
      id="dedicated-detail"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title ml-5" id="myModalLabel">
              Log Details
            </h4>
            <button type="button" className="close custom-close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body detail" style={{ padding: '0', textAlign: 'justify' }}>
            <div className="row">
              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label1">
                  <label id="fn">First Name</label>
                  <label id="ln">Last Name</label>
                </div>
                <div className="col-lg-8 detail-result1">
                  {firstName ? <p className="mt-4 mb-2">{firstName}</p> : <p className="mt-4">N/A</p>}
                  {lastName ? <p className="mb-2">{lastName}</p> : <p className="mt-4">N/A</p>}
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label2">
                  <label>Email</label>
                  <label>Phone Number</label>
                  {/* <label >AF5U</label> */}
                </div>
                <div className="col-lg-8 detail-result2">
                  {email ? <p className="mt-4 mb-2">{email}</p> : <p className="mt-4">N/A</p>}
                  {phoneNumber ? <p className="mb-2">{phoneNumber}</p> : <p className="mt-4">N/A</p>}
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label3">
                  <label>Note</label>
                </div>
                <div className="col-lg-8 detail-result3">
                  {note ? <p className="mt-4">{note}</p> : <p className="mt-4">N/A</p>}
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label1">
                  <label id="fn">Item Description</label>
                  {/* <label id="email">Station</label> */}
                </div>
                <div className="col-lg-8 detail-result1">
                  {resolution ? <p className="mt-4 mb-2">{resolution}</p> : <p className="mt-4">N/A</p>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Details.propTypes = {
  one: PropTypes.shape(),
};
export default Details;
