import React from 'react';

const ExpiredCustomersBody = () => {
  return (
    <div className="container m-0 p-0">
      <div className="row m-0 p-0 mt-5">
        <div className="d-flex flex-wrap mt-2 col-lg-10 col-md-10 col-sm-12">
          <form className="form-inline">
            <div className="d-flex mt-5 col-lg-3 col-md-5 col-sm-12">
              <small className="text-nowrap">Filter by:</small>
              <select className="form-control w-100 ml-1" name="status">
                <option>-- Status --</option>
                <option value="name">Name</option>
                <option value="IMSI">IMSI</option>
                <option value="phone">Phone</option>
                <option value="location">Location</option>
              </select>
            </div>
            <div className="mt-5 col-lg-4 col-md-7 col-sm-12">
              <input className="form-control border w-100" placeholder="Search Term" />
            </div>
            <div className="m-0 p-0 mt-5 ml-3 mb-1 col-lg-2 col-md-2 col-sm-2">
              <button className="btn btn-green shadow-md" type="submit">
                Search
              </button>
            </div>
          </form>
        </div>

        <div className="card x-panel mt-4">
          <div className="table-responsive content">
            <table className="table copy-font">
              <thead>
                <tr>
                  <th>#</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Address</th>
                  <th>Location</th>
                  <th>Phone</th>
                  <th>IMSI</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>expired customer</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpiredCustomersBody;
