/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
/* eslint-disable react/require-default-props */
/* eslint-disable no-case-declarations */
/* eslint-disable react/button-has-type */
import React, { Component } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import qs from 'qs';
import PropTypes from 'prop-types';
import {
  getUserDetails,
  searchByName,
  searchByPhoneOrMac,
  searchForUserByEmail,
} from '../../../../../actions/shared/customers/CustomersDetailsActions';
import { getPaymentHistory } from '../../../../../actions/shared/customers/payments/PaymentsActions';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import { getUser } from '../../../../../actions/shared/user/UserActions';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import view from '../../../../../assets/img/icons/view.png';
import edit from '../../../../../assets/img/icons/edit.png';
import {
  addBandwidth,
  getUserBandwidth,
} from '../../../../../actions/noc/dedicated-customers/DedicatedCustomersActions';
import CustomerDetails from '../../../shared/customers/shared/CustomerDetails';
import EditStaticIP from '../../../shared/geniatech/presentational/StaticIP';
import { addStaticIp, editStaticIp } from '../../../../../actions/noc/static-ip/StaticIpActions';
import { displayCountryDropdown } from '../../../../../utils/geniatech/Geniatech';
import { NOC, RESEARCH_AND_DEVELOPMENT, SUPER_ADMIN } from '../../../../../utils/departments/helpers';
import RDNavBar from '../../../../layouts/research-and-development/RDNavBar';
import BandWidth from '../../noc/customers/BandWidth';

class RandDCustomers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
      cust: [],
      bandwidth: '',
      custome: {},
      country: '',
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.checkDepartment();
    // eslint-disable-next-line react/destructuring-assignment
    this.props.getUser();
  }

  componentWillReceiveProps() {
    this.checkDepartment();
  }

  checkDepartment = async () => {
    const { history } = this.props;
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.department_id !== `${RESEARCH_AND_DEVELOPMENT}` &&
        response.data.department_id !== `${SUPER_ADMIN}`) {
        history.push('/');

        sessionStorage.setItem('token', '');
        sessionStorage.clear();

        toastr.info('You are not allowed to view this page');
      }
    } catch (e) {
      toastr.info('Something went wrong!');
    }
  };

  getUser = (user) =>
    this.setState({
      cust: user,
    });

  getUserDetails = (customer) => {
    const data = {
      macid: customer,
    };

    // eslint-disable-next-line react/destructuring-assignment
    this.props.getUserDetails(data);
  };

  getUserToDisplay = (customer) => {
    this.setState({
      custome: customer,
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  onSubmit = async (e) => {
    e.preventDefault();

    const { keyword, search, country } = this.state;

    switch (keyword) {
      case 'email':
        const data = {
          email: search,
        };

        // eslint-disable-next-line react/destructuring-assignment
        this.props.searchForUserByEmail(data);
        break;

      case 'phone':
        const phoneSearch = {
          phoneNumber: search,
          macAddress: '12',
        };

        // eslint-disable-next-line react/destructuring-assignment
        this.props.searchByPhoneOrMac(phoneSearch);
        break;

      case 'name':
        const nameSearch = {
          name: search,
          country,
        };
        // eslint-disable-next-line react/destructuring-assignment
        this.props.searchByName(nameSearch);
        break;

      case 'macid':
        const macid = {
          macAddress: search,
          phoneNumber: '090',
        };

        // eslint-disable-next-line react/destructuring-assignment
        this.props.searchByPhoneOrMac(macid);
        break;

      default:
        return null;
    }
  };

  addBandwith = async (user, band) => {
    const data = qs.stringify({
      action: 'changeBandWidth',
      macaddress: user.username,
      bandWidth: band,
      staffemail: 'abiola.adeyemo@tizeti.com',
      category: 'dedicated_above10M',
    });

    // eslint-disable-next-line react/destructuring-assignment
    this.props.addBandwidth(data);
  };

  editIp = (ip, details, macid) => {
    // const { UserName } = details;

    const data = {
      action: 'editIPAddress',
      mac: macid,
      newIP: ip,
    };

    // eslint-disable-next-line react/destructuring-assignment
    this.props.editStaticIp(data);
  };

  addIp = (ip, details, macid) => {
    // const { UserName } = details;
    const data = {
      mac: macid,
      newIP: ip,
    };

    // eslint-disable-next-line react/destructuring-assignment
    this.props.addStaticIp(data);
  };

  getBandwidth = (customer) => {
    const data = qs.stringify({
      action: 'getCustomerBandWidthSpeed',
      macaddress: customer.username,
    });

    // eslint-disable-next-line react/destructuring-assignment
    this.props.getUserBandwidth(data);
  };

  onCountryChange = (e) => {
    const { value } = e.target;
    this.setState({ country: value });
  };

  render() {
    const { search, cust, bandwidth, custome, keyword } = this.state;

    const { user, load, loading, detail, session, person, update, adding } = this.props;
    const { firstName, email } = person;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <RDNavBar currentPage="Customers" position={firstName} />

          <div className="container">
            <div className="row mt-5">
              <div className="mt-3 col-lg-12 col-md-12 col-sm-12">
                <form onSubmit={this.onSubmit} className="form-inline mt-4 d-flex">
                  <select name="keyword" id="" onChange={this.onChange} className="form-control mt-2 mr-3">
                    <option value="">Choose fields to search from</option>
                    <option value="email">Email</option>
                    <option value="name">Name</option>
                    <option value="phone">Phone</option>
                    <option value="macid">MAC ID</option>
                  </select>

                  {displayCountryDropdown(keyword, this.onCountryChange)}
                  <div className="input-group mt-2 mr-2">
                    <div className="input-group-prepend">
                      <span className="input-group-text fa fa-search" />
                    </div>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search with email"
                      name="search"
                      value={search}
                      onChange={this.onChange}
                    />
                  </div>
                  <div className="d-flex mb-1 mt-2">
                    <button className="btn btn-green mr-2" style={{ height: '39px' }}>
                      Search
                    </button>
                  </div>
                </form>
              </div>

              <div className="card x-panel mt-3">
                <div className="table-responsive content">
                  <table className="table copy-font">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>MAC ID</th>
                        <th nowrap="no-wrap">Name</th>
                        <th>Email</th>
                        <th nowrap="no-wrap">Phone Number</th>
                        {email === 'abiola.adeyemo@tizeti.com' ? <th nowrap="no-wrap">Change Bandwidth</th> : null}
                        <th nowrap="no-wrap">Expiry Date</th>
                        {/* <th className="text-center">Rollback</th> */}
                        <th style={{ paddingLeft: '24px' }}>Actions</th>
                      </tr>
                    </thead>
                    {load ? (
                      <img src={loader} alt="loading gif" />
                    ) : (
                      <tbody>
                        {user && user.length ? (
                          user.map((customer, i) => (
                            <tr>
                              <td>{i + 1}</td>
                              <td>{customer.username}</td>
                              <td nowrap="no-wrap">{customer.name}</td>
                              <td>{customer.mail}</td>
                              <td>{customer.address}</td>
                              {/* <td>{moment(customer.Expiration).format('Do MMMM YYYY')}</td> */}
                              {email === 'abiola.adeyemo@tizeti.com' ? (
                                <td>
                                  <button
                                    type="submit"
                                    style={{
                                      border: '0',
                                      backgroundColor: 'white',
                                      width: '0%',
                                    }}
                                    data-toggle="tooltip"
                                    data-placement="left"
                                    title="View"
                                    onClick={() => this.getUser(customer)}
                                  >
                                    <span className="view1 ml-4">
                                      <img src={view} alt="" data-toggle="modal" data-target="#bandwidth-details" />
                                    </span>
                                  </button>
                                  <BandWidth
                                    customer={cust}
                                    addBandwith={this.addBandwith}
                                    onChnage={this.handleChange}
                                    bandwith={bandwidth}
                                  />
                                </td>
                              ) : null}
                              <td>{customer.value}</td>

                              {/* {isNaN(Date.parse(customer.value)) ? <td>N/A</td> : <td>{customer.value}</td> } */}
                              <td className="ml-2">
                                <button
                                  type="submit"
                                  style={{
                                    border: '0',
                                    backgroundColor: 'white',
                                    width: '0%',
                                  }}
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  title="View"
                                  onClick={() => {
                                    this.getUserDetails(customer.username);
                                    this.getUserToDisplay(customer);
                                  }}
                                >
                                  <span className="view1 ml-4">
                                    <img src={view} alt="" data-toggle="modal" data-target="#cus-details" />
                                  </span>
                                </button>
                                <CustomerDetails load={loading} details={detail} session={session} user={custome} />
                                {Number(sessionStorage.getItem('roleId')) > 1 &&
                                sessionStorage.getItem('departmentId') === `${NOC}` ? (
                                  <button
                                    type="submit"
                                    style={{
                                      border: '0',
                                      backgroundColor: 'white',
                                      width: '0%',
                                    }}
                                    data-toggle="tooltip"
                                    data-placement="left"
                                    title="View"
                                    className="ml-4"
                                    onClick={() => {
                                      this.getUserDetails(customer.username);
                                      this.getUserToDisplay(customer);
                                    }}
                                  >
                                    <span
                                      style={{
                                        cursor: 'pointer',
                                      }}
                                      className="view1 ml-4"
                                      data-toggle="modal"
                                      data-target="#static"
                                    >
                                      <img src={edit} alt="edit static ip" />
                                    </span>
                                  </button>
                                ) : null}
                                <EditStaticIP
                                  load={loading}
                                  details={detail}
                                  session={session}
                                  editIp={this.editIp}
                                  loading={update}
                                  addIp={this.addIp}
                                  macid={custome.username}
                                  staticId={session.staticIpaddress}
                                  adding={adding}
                                />
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td>
                              <small>no users</small>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.customerDetail.customer,
  load: state.customerDetail.load,
  loading: state.customerDetail.loading,
  detail: state.customerDetail.details,
  session: state.customerDetail.session,
  person: state.user.user,
  update: state.static.updating,
  adding: state.static.adding,
});

RandDCustomers.propTypes = {
  getUser: PropTypes.func,
  getUserDetails: PropTypes.func,
  history: PropTypes.shape(),
  editStaticIp: PropTypes.func,
  searchForUserByEmail: PropTypes.func,
  searchByPhoneOrMac: PropTypes.func,
  addBandwidth: PropTypes.func,
  searchByName: PropTypes.func,
  update: PropTypes.bool,
  user: PropTypes.array,
  person: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  load: PropTypes.bool,
  loading: PropTypes.bool,
  adding: PropTypes.bool,
  addStaticIp: PropTypes.func,
  detail: PropTypes.array,
  session: PropTypes.array,
  getUserBandwidth: PropTypes.func,
};
export default connect(
  mapStateToProps,
  {
    searchForUserByEmail,
    getPaymentHistory,
    getUserDetails,
    searchByPhoneOrMac,
    searchByName,
    getUser,
    addBandwidth,
    editStaticIp,
    addStaticIp,
    getUserBandwidth,
  },
)(RandDCustomers);
