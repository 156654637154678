import React, { Component } from 'react';
import '../../../../../styles/style.css';
import '../../../../../styles/basestation.css';
import axios from 'axios';
import { toastr } from 'toastr';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import { API_URL } from '../../../../../utils/config';
import NocNavBar from '../../../../layouts/noc/NocNavBar';
import { NOC, SUPER_ADMIN } from '../../../../../utils/departments/helpers';

class NetworkOperationsNoLos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      basestations: [],
      keyword: '',
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      filtered: [],
      isLoading: false,
      move: false,
      out: false,
    };

    this.onKeyPress = this.onKeyPress.bind(this);
  }

  getUserDetails() {
    axios({
      method: 'get',
      url: `${API_URL}/employee/loggedOnEmployee`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        this.setState({
          user: data.data,
        });
      })
      .catch((err) => {});
  }

  fetchLoggedOnUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employeeReg/logged`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        if (data.data.department_id !== `${NOC}` && data.data.department_id !== `${SUPER_ADMIN}`) {
          sessionStorage.setItem('data', '');
          sessionStorage.clear();
          this.setState({
            out: true,
          });

          toastr.info('You are not allowed to view this page');
        }
      })
      .catch((err) => {});
  }

  componentWillMount() {
    this.fetchBaseStations();
    this.fetchLoggedOnUser();
    this.getUserDetails();
    this.getLos();
  }

  getLos() {
    axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      url: `${API_URL}/los/getlos/1`,
    }).then((res) => {});
  }

  renderMap = () => {
    loadScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyD1DrDBUd6GNL2EIBCxK-K0OjkTny8kbuA&callback=initMap');
    window.initMap = this.initMap;
  };

  fetchBaseStations = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };

    axios
      .get(`${API_URL}/los/getlos/1`, config)
      .then((res) => {
        this.setState(
          {
            basestations: res.data.data,
          },
          this.renderMap(),
        );
      })
      .catch((err) => {});
  };

  componentWillReceiveProps() {
    this.fetchLoggedOnUser();
  }

  initMap = () => {
    const map = new window.google.maps.Map(document.getElementById('map'), {
      center: { lat: 6.548653, lng: 3.54882 },
      zoom: 11,
      maxZoom: 0, // 18
      minZoom: 0, // 16
      zoomControlOptions: {
        style: window.google.maps.ZoomControlStyle.SMALL,
      },
      scaleControl: true, // enable scale control
      mapTypeId: window.google.maps.MapTypeId.HYBRID, // google map type
    });

    const infowindow = new window.google.maps.InfoWindow();

    this.state.basestations.map((basestation) => {
      const contentString = `${basestation.address}`;

      const marker = new window.google.maps.Marker({
        position: { lat: basestation.latitude, lng: basestation.longitude },
        map,
        // icon: 'https://res.cloudinary.com/tizeti/image/upload/v1542971012/Group-1.png',
        icon: 'http://www.my9ja.com/estatewifi/icons/pin_green.png',
        title: basestation.name,
        // animation: window.google.maps.Animation.BOUNCE
      });

      // var input = document.getElementById('pac-input');
      // var searchBox = new window.google.maps.places.SearchBox(input);
      // map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);
      //           var searchBox = new window.google.maps.places.SearchBox(document.getElementById('pac-input'));

      // var searchBox = new window.google.maps.places.SearchBox(document.getElementById('pac-input'));

      const circle = new window.google.maps.Circle({
        map,
        radius: 2000, // 2 kilometeres in metres
        fillColor: '#00FF00',
        // strokeColor: '#FF0000',
        strokeOpacity: 0.2,
        strokeWeight: 0.12,
        fillOpacity: 0.25,
      });

      circle.bindTo('center', marker, 'position');

      marker.addListener('click', function() {
        // chnage content
        infowindow.setContent(contentString);
        infowindow.open(map, marker);
      });
    });
  };

  onKeyPress = (e) => {
    const keyword = e.target.value;

    if (e.which === 13) {
      if (keyword !== '') {
        this.setState({
          isLoading: true,
        });

        const base = this.state.basestations.filter((basestation) => {
          if (basestation.name.indexOf(keyword) !== -1) {
            return basestation;
          }
          if (basestation.address.indexOf(keyword) !== -1) {
            return basestation;
          }
          if (basestation.installer.indexOf(keyword) !== -1) {
            return basestation;
          }
        });

        /* this.geocodeAddress(base); */
        this.setState({
          filtered: base,
          isLoading: false,
        });
      } else {
        this.setState({
          filtered: this.state.basestations,
          keyword,
        });
      }
    }
  };

  render() {
    const { isLoading, user } = this.state;
    const { firstName } = user;

    if (isLoading) {
      return <img src={loader} alt="" />;
    }

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <NocNavBar currentPage="No Line Of Sight" position={firstName} />

          <div className="container state px-4">
            <div className="row mt-5">
              <div className="mt-5 col-lg-12 col-md-12 col-sm-12">
                <div id="map" />
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

function loadScript(url) {
  const index = window.document.getElementsByTagName('script')[0];
  const script = window.document.createElement('script');
  script.src = url;
  script.async = true;
  script.defer = true;
  index.parentNode.insertBefore(script, index);
}

export default NetworkOperationsNoLos;
