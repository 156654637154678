/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import toastr from 'toastr';
import { accounts, admin, API_URL } from '../../../../../utils/config';
import '../../../../../styles/employee.css';
import '../../../../../styles/style.css';
import AddEmployee from '../../super-admin/employees/AddEmployee';
import ConfirmedEmployees from '../../../shared/staff/ConfirmedEmployees';
import UnconfirmedEmployees from '../../../shared/staff/UnconfirmedEmployees';
import AccountsNavBar from '../../../../layouts/accounts/AccountsNavBar';

class AccountEmployees extends Component {
  constructor(props) {
    super(props);

    this.state = {
      employees: [],
      newemployees: [],
      departments: [],
      isLoading: false,
      address: '',
      alternateEmail: '',
      alternatePhoneNumber: '',
      birthday: '',
      date_of_resumption: '',
      departmentList: '',
      email: '',
      extension: '',
      firstName: '',
      gender: '',
      lastName: '',
      officialEmail: '',
      phoneNumber: '',
      role: '',
      show: false,
      employeeId: {},
      error: null,
      keyword: '',
      move: false,
      user: '',
      showComponent: false,
      value: 'untoggled',
      filtered: [],
      unconfirmed: [],
    };

    // this.onChange = this.onChange.bind(this);
    this.fetchConfirmedUsers = this.fetchConfirmedUsers.bind(this);
  }

  componentDidMount() {
    this.fetchConfirmedUsers();
    this.fetchEmployees();
    this.fetchUnconfirmedEmployees();
    this.getUserDetails();
    this.fetchLoggedOnUser();
  }

  onChange(e) {
    this.setState({
      keywords: e.target.value,
    });
  }

  getUserDetails() {
    axios({
      method: 'get',
      url: `${API_URL}/employee/loggedOnEmployee`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((res) => {
        this.setState({
          user: res.data.firstName,
        });
      })
      .catch(() => {});
  }

  searchCustomer = (e) => {
    const keyword = e.target.value;

    if (keyword !== '') {
      if (this.state.value === 'confirmed') {
        const list = this.state.employees.filter((customer) => {
          if (customer.officialEmail.indexOf(keyword) !== -1) {
            return customer;
          }
        });

        this.setState({
          filtered: list,
          keyword: e.target.value,
        });
      } else {
        this.setState({
          filtered: this.state.employees,
          keyword,
        });
      }

      if (this.state.value === 'unconfirmed') {
        const list = this.state.unconfirmed.filter((customer) => {
          if (customer.email.indexOf(keyword) !== -1) {
            return customer;
          }
        });

        this.setState({
          unconfirmed: list,
          keyword: e.target.value,
        });
      } else {
        this.setState({
          unconfirmed: this.state.newemployees,
          keyword,
        });
      }

      /*            const list = this.state.filtered.filter((customers) => {

                            if(customers.plate_number.indexOf(keyword) !== -1){
                                return customers;
                            }

                            if(customers.engine_no.indexOf(keyword) !== -1){
                                return customers;
                            }

                            if(customers.model.indexOf(keyword) !== -1){
                                return customers;
                            }

                            if(customers.chasis_no.indexOf(keyword) !== -1){
                                return customers;
                            }

                            if(customers.color.indexOf(keyword) !== -1){
                                return customers;
                            }

                        });
                        this.setState({

                            filtered: list,
                            keyword: e.target.value

                        });

                    } else {

                        this.setState({

                            filtered: this.state.vehicles,
                            keyword

                        }); */
    }
  };

  fetchUnconfirmedEmployees() {
    this.setState({
      isLoading: true,
    });

    axios({
      method: 'get',
      url: `${API_URL}/employeeReg`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      }
    })
      .then((response) => {
        this.setState({
          newemployees: response.data,
          unconfirmed: response.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          error,
          isLoading: false,
        });
      });
  }

  fetchEmployees() {
    this.setState({
      isLoading: true,
    });

    axios({
      method: 'get',
      url: `${API_URL}/employee`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      }
    })
      .then((response) => {
        this.setState({
          employees: response.data,
          filtered: response.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          error,
          isLoading: false,
        });
      });
  }

  fetchConfirmedUsers() {
    this.setState({
      showComponent: true,
      value: 'confirmed',
    });
  }

  // componentWillMount() {
  //     if(!sessionStorage.getItem('token')){
  //         this.setState({
  //             move: true
  //         })
  //     }
  //
  //     this.fetchLoggedOnUser();
  //
  // }

  fetchLoggedOnUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employeeReg/logged`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        if (data.data) {
          this.setState({
            role: data.data.role_id,
          });
        }

        if (data.data.department_id !== `${accounts}` && data.data.department_id !== `${admin}`) {
          this.props.history.push('/staff/login');

          toastr.info('You are not allowed to view this page');
        }
      })
      .catch(() => {});
  }

  /* searchCustomer = (e) => {

      const keyword = e.target.value;

      if(keyword !== ""){

        if(this.state.value === "confirmed"){

          const list = this.state.filtered.filter((employee) => {
              if (employee.lastName.indexOf(keyword) !== -1) {
                  return employee;
              }
              if (employee.firstName.indexOf(keyword) !== -1) {
                  return employee;
              }
              if (employee.email.indexOf(keyword) !== -1) {
                  return employee;
              }
          })
          this.setState({
              filtered: list,
              keyword: e.target.value
          });
        } else if(this.state.value === "unconfirmed"){
          const list = this.state.unfiltered.filter((employee) => {
              if (employee.email.indexOf(keyword) !== -1) {
                  return employee;
              }
          })
          this.setState({
              unfiltered: list,
              keyword: e.target.value
          });

        }

      }

    } */

  render() {
    const { move, user, filtered, unfiltered, role } = this.state;
    const { firstName, lastName } = user;
    // console.log(role);

    if (move) {
      return <Redirect to="/login" />;
    }

    // const dataFilter = employee => employee.firstName
    //                                     .toLowerCase().match(this.state.searchQuery.toLowerCase()) && true;
    // const filteredEmployees = this.state.staff.filter(dataFilter);

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <AccountsNavBar currentPage="Employees" position={firstName} role_id={role} />

          <div className="container">
            <div className="row mt-4">
              <div className="col-lg-12 col-md-12 col-sm-12 mt-5 mb-3 d-flex flex-wrap">
                {/* <div className="mt-2"> */}

                <div className="input-group col-lg-7 col-md-7 col-sm-12 mt-3 mr-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fa fa-search" />
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    value={this.state.keyword}
                    onChange={(e) => this.searchCustomer(e)}
                  />
                </div>

                <div className="input-group op copy-font col-lg-4 col-md-4 col-sm-12 mt-3 mr-3">
                  <span className="mt-2 mr-2 text-wrap">Filter By:</span>
                  <select
                    className="copy-font"
                    onChange={(e) =>
                      this.setState({
                        value: e.target.value,
                      })
                    }
                  >
                    {/* <option>--Choose--</option> */}
                    <option value="confirmed">Confirmed Employees</option>
                    <option value="unconfirmed">Unconfirmed Employees</option>
                  </select>
                </div>
                {/* </div> */}
              </div>
              <AddEmployee />

              <div className="card x-panel">
                <div className="table-responsive content">
                  <table className="table col-lg-12 col-md-12 col-sm-12">
                    <thead>
                      <tr>
                        <th>#</th>
                        {/* <th>First Name</th>
                                                <th>Last Name</th>
                                                <th>Department</th>
                                                <th>Designation</th> */}
                        <th>Email</th>
                        <th>Date of resumption</th>
                        <th>Confirm</th>
                        <th>Delete</th>
                      </tr>
                    </thead>

                    {this.state.value === 'confirmed' ? (
                      <ConfirmedEmployees confirmed={this.state.filtered} />
                    ) : (
                      <UnconfirmedEmployees unconfirmed={this.state.unconfirmed} />
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

/* const container = document.createElement("div");
document.body.appendChild(container);
ReactDOM.render(<Employees />, container) */

export default AccountEmployees;
