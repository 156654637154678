import React from 'react';
import useCpuUtilization from '../hooks/useCpuUtilization';
import { CPU_UTILIZATION_URL } from '../api/CpuUtilizationUrl';
import AppContext from '../../../../../../../../context/shared/AppContext';

const CpuUtilizationProvider = ({ children }) => {
  const { data, loading, paginateChange, loadingForm, onChange, formValue, onSubmit, pages } = useCpuUtilization(
    `${CPU_UTILIZATION_URL}`,
  );

  return (
    <AppContext.Provider
      value={{
        data,
        loading,
        paginateChange,
        loadingForm,
        onChange,
        formValue,
        onSubmit,
        pages,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export default CpuUtilizationProvider;
