/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AES } from 'crypto-js';
import RequisitionNotification from '../../staff/shared/layouts/sidebar/manager-and-executives/RequisitionNotification';
import PATHS from '../../../routes/urls';
import ExpressWifiNavBarItem from './ExpressWifiNavBarItem';

const ExpressWifiSideBar = () => {
  const officialEmail = sessionStorage.getItem('officialEmail');
  const detailsState = {
    name: 'React',
    messagetoken: sessionStorage.getItem('token'),
    messagerole: sessionStorage.getItem('roleId'),
    messagedepartment: sessionStorage.getItem('departmentId'),
    secret: 'Wifirocks123',
    cipher: '',
  };
  const [details, setDetails] = useState(detailsState);
  const { name, messagetoken, messagerole, messagedepartment, secret, cipher } = details;

  const obj = {
    login: messagetoken,
    pass: messagerole,
    department: messagedepartment,
  };

  const ciph = AES.encrypt(JSON.stringify(obj), secret).toString();

  return (
    <div className="s-layout__sidebar">
      <Link className="s-sidebar__trigger" to="#">
        <i className="fa fa-bars mr-3" />
      </Link>

      <nav className="s-sidebar__nav">
        <Link className="navbar-brand-logo" to="/express-wifi">
          <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971047/Tizeti-Logo.png" alt="homepage" />
        </Link>
        <ul className="pt-4">

        <li className="mt-3 s-sidebar__nav-link">
              <Link to="/express-wifi" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971013/Group-3.png" alt="img" />
                </span>
                <small>Overview</small>
              </Link>
            </li>
          <li className="s-sidebar__nav-link">
            {' '}
            <a href={`https://os.tizeti.com/main/#/overview?ciph=${ciph}`} target="_blank" className="make-bold">
              <span>
                <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923169/configr_d.fw.png" alt="img" />
              </span>
              <small>New system (Beta v2.0)</small>
            </a>
          </li>
          <span className="s-sidebar__nav-link d-none d-md-block" data-toggle="collapse" data-target="#ambassadors">
            <ExpressWifiNavBarItem
              url="#"
              name="AMBASSADORS"
              image="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-6.png"
            />
          </span>
          <span id="ambassadors" className="collapse base-li top-bottom">
            <ExpressWifiNavBarItem
              url="/express-wifi/ambassadors-signups"
              name="Ambassadors SignUps"
              image="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg"
            />
            <ExpressWifiNavBarItem
              url="/express-wifi/ambassadors"
              name="Active Ambassadors"
              image="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg"
            />
            <ExpressWifiNavBarItem
              url="/express-wifi/customers-signups"
              name="Customers Signup"
              image="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg"
            />
          </span>

          <ExpressWifiNavBarItem
            url="/express-wifi/escalate"
            name="Escalate"
            image="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg"
          />
          <ExpressWifiNavBarItem
            url={PATHS.EXPRESS_WIFI_KPI_MONITOR}
            name="KPI Monitor"
            image="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg"
          />

          <ExpressWifiNavBarItem
            url="/express-wifi/admin-requests"
            name="Admin Request"
            image="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg"
          />

          <ExpressWifiNavBarItem
            url={PATHS.EXPRESS_WIFI_CUSTOMERS}
            name="Customers"
            image="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg"
          />

          <ExpressWifiNavBarItem
            url={PATHS.EXPRESS_WIFI_RETAILERS}
            name="Retailers"
            image="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg"
          />

          <ExpressWifiNavBarItem
            url={PATHS.EXPRESS_WIFI_GENIATECH}
            name="Geniatech"
            image="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg"
          />

          <ExpressWifiNavBarItem
            url={PATHS.EXPRESS_WIFI_PAYSLIP}
            name="Payslip"
            image="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg"
          />

          <ExpressWifiNavBarItem
            url={PATHS.EXPRESS_WIFI_RETAILER_SMS}
            name="Retailer SMS"
            image="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg"
          />
          {(officialEmail === 'express.wifi@tizeti.com' || officialEmail === 'Isioma.oranyeli@tizeti.com') && (
            <ExpressWifiNavBarItem
              url="/express-wifi/hotspot-customers"
              name="Hotspot Customers"
              image="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg"
            />
          )}

          <ExpressWifiNavBarItem
            url="/express-wifi/learning-portal"
            name="Learning Portal"
            image="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg"
          />

          <ExpressWifiNavBarItem
            url="/express-wifi/purchase-order"
            name="Purchase Order"
            image="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg"
          />
          <ExpressWifiNavBarItem
            url="/express-wifi/new-express-portal"
            name="New Express Portal"
            image="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg"
          />

          <li className="s-sidebar__nav-link">
            <Link to="/express-wifi/requisitions" target="_self" className="d-flex">
              <span className="mr-2">
                <img src="https://res.cloudinary.com/teewhy/image/upload/v1550844527/requisition.png" alt="img" />
              </span>
              Requisition
              <RequisitionNotification />
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default ExpressWifiSideBar;
