import React, { useContext } from 'react';
// import SearchNocSheets from '../SearchNocSheets';
import PtpTable from './PtpTable';
import AddPtp from './AddPtp';
import { PtpContext } from './context/PtpContext';
import PaginatePage from './PaginatePage';

const PtpBody = () => {
  const { loading } = useContext(PtpContext);
  return (
    <div className="container">
      <div className="row mt-5">
        {/* <SearchCnMaestro /> */}
        <div className="form-group ml-2">
          <button className="btn btn-green" type="button" data-toggle="modal" data-target="#add-access-point">
            Add Ptp
          </button>
        </div>
        <AddPtp />
        <PtpTable />
        {!loading && <PaginatePage />}
      </div>
    </div>
  );
};

export default PtpBody;
