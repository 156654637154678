import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from './icons/CloseIcon';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import { downtime_logger_action, edit_downtime_logger } from '../../../../../actions/coo-statistics/DowntimeLogger';
import { toast } from 'react-toastify';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function AddDowntimeModal({
  setOpen,
  open,
  zoneClicked,
  todayDate,
  getAllZones,
  openEditDowntimeModal,
  setOpenEditDowntimeModal,
}) {
  const [options, setOptions] = React.useState({ down_hours: '', reason: '' });
  const [disabledState, setDisabledState] = React.useState(false);
  const { profile } = useUser();

  const data = {
    employee_name: profile.officialEmail || profile.email,
    zone_name: zoneClicked !== undefined && zoneClicked.name,
    zone_id: zoneClicked !== undefined && zoneClicked.id,
    date: todayDate,
    ...options,
  };

  // regex to test for numbers only with decimal point
  const numbersOnly = /^[0-9]+(\.[0-9]+)?$/;
  const testDownHours = numbersOnly.test(options.down_hours);
  const disableSave = () => {
    if (options.down_hours === '' || options.reason === '' || !testDownHours) {
      setDisabledState(true);
    } else setDisabledState(false);
  };
  React.useEffect(() => {
    disableSave();
  }, [options]);

  const ifEditModal = () => {
    if (openEditDowntimeModal) {
      if (zoneClicked !== undefined && zoneClicked.bstation_downtimes.length > 0) {
        return zoneClicked.bstation_downtimes[zoneClicked.bstation_downtimes.length - 1];
      }
    }
    return undefined;
  };

  const onSave = async () => {
    if (openEditDowntimeModal && zoneClicked.bstation_downtimes.length > 0) {
      try {
        const res = await edit_downtime_logger(
          { ...data, zone_id: data.zone_id },
          zoneClicked.bstation_downtimes[zoneClicked.bstation_downtimes.length - 1].id,
        );
        if (res) {
          setOpenEditDowntimeModal(false);
          getAllZones();
        }
        toast.success('Downtime edited successfully');
      } catch (error) {
        console.error('Error saving downtime:', error);
        toast.error(error.message);
      }
    } else {
      try {
        const res = await downtime_logger_action(data);
        if (res) {
          setOpen(false);
          getAllZones();
        }
        toast.success('Downtime saved successfully');
      } catch (error) {
        console.error('Error saving downtime:', error);
        toast.error(error.message);
      }
    }
  };
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={() => {
          setOpen(false) || setOpenEditDowntimeModal(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={open || openEditDowntimeModal}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <Typography>Downtime Comments</Typography>
          <Typography>and Hours</Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false) || setOpenEditDowntimeModal(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <TextField
                error={!testDownHours}
                id="downtime_hour"
                label="Downtime hour(s)"
                type="number"
                defaultValue={ifEditModal() !== undefined ? ifEditModal().down_hours : 0}
                helperText={!testDownHours ? 'inputs can only be in this format(1, 2, 1.3)' : ''}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  e.persist();
                  setOptions((prev) => ({ ...prev, down_hours: e.target.value }));
                }}
              />
            </div>
            <div>
              <TextField
                id="reason"
                label="Reason"
                multiline
                rows={4}
                defaultValue={ifEditModal() !== undefined ? ifEditModal().reason : ''}
                sx={{ boxShadow: 'none !important' }}
                onChange={(e) => {
                  e.persist();
                  setOptions((prev) => ({ ...prev, reason: e.target.value }));
                }}
              />
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false) || setOpenEditDowntimeModal(false)}>
            Cancel
          </Button>
          <Button autoFocus onClick={onSave} variant="contained" disabled={disabledState}>
            {ifEditModal() === undefined ? 'Save' : 'Edit'}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
