import React from 'react';
// import { RouteComponentProps } from 'react-router-dom';
import { CUSTOMER_SUPPORT, SUPER_ADMIN } from '../../../../../utils/departments/helpers';
import useCheckFiveDepartments from '../../../../../hooks/shared/custom/check-department/useCheckFiveDepartments';
import CustomerSupportNavBar from '../../../../layouts/customer-support/CustomerSupportNavBar';
import CustomerSupportCallLogsBody from './CustomerSupportCallLogsBody';

const CustomerSupportCallLogs = ({ history }) => {
  useCheckFiveDepartments(`${CUSTOMER_SUPPORT}`, `${SUPER_ADMIN}`, history);
  const firstName = sessionStorage.getItem('firstName', firstName);

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <CustomerSupportNavBar currentPage="Call Logs" position={firstName} />
        <CustomerSupportCallLogsBody />
      </main>
    </div>
  );
};

export default CustomerSupportCallLogs;
