/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/require-default-props */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import toastr from 'toastr';
import Budget from '../../../shared/budget/Budget';
import Logout from '../../../shared/auth/Logout';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import { admin, operations, TECHNICAL_SUPPORT } from '../../../../../utils/config';
import PageName from '../../../shared/layouts/PageName';
import FieldSupportSideBar from '../../../../layouts/field-support/side-bar/FieldSupportSideBar';

const FieldSupportBudget = ({ history }) => {
  const { profile } = useUser();
  const { firstName } = profile;

  useEffect(() => {
    async function checkDepartment() {
      try {
        const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });

        if (
          response.data.department_id !== `${operations}` &&
          response.data.department_id !== `${TECHNICAL_SUPPORT}` &&
          response.data.department_id !== `${admin}`
        ) {
          // eslint-disable-next-line react/destructuring-assignment
          history.push('/login');

          sessionStorage.setItem('token', '');
          sessionStorage.setItem('email', '');
          sessionStorage.clear();

          toastr.info('You are not allowed to view this page');
        }
      } catch (e) {
        toastr.info(`Something went wrong!`);
      }
    }
    checkDepartment();
  }, [history]);

  return (
    <div>
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <Link className="s-sidebar__trigger" to="#">
                <i className="fa fa-bars mr-3" />
              </Link>
              <FieldSupportSideBar />

              <PageName name="Budget" />

              <div className="d-flex profile-mail">
                <h6>
                  <i className="mr-3">{firstName}</i>
                  <Logout />
                </h6>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row mt-5">
              <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-4">
                <Budget />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

FieldSupportBudget.propTypes = {
  history: PropTypes.shape(),
};
export default FieldSupportBudget;
