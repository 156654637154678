/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react';
// import view from '../../../../../assets/img/icons/view.png';
// import edit from '../../../../../assets/img/icons/edit.png';
import DeleteSvg from '../../../../../assets/svg/delete-button.svg';
import DeleteZoneModal from './DeleteZoneModal';
import { ZonesContext } from '../../../shared/installations/zones/provider/ZonesContext';
import { NoItemRow } from '../../../../../utils/loader/loadingHelper';

const ZonesTable = () => {
  const { zones, onShowModal } = useContext(ZonesContext);
  const [zone, setZone] = useState({ name: '', id: '' });
  const getZone = (param) => {
    onShowModal();
    setZone(param);
  };

  const zonesTable =
    zones && zones.length ? (
      zones.map((zon, i) => (
        <tr key={i + 1}>
          <td>{i + 1}</td>
          <td>{zon.name}</td>
          <td>
            <div style={{ paddingLeft: '10px' }}>
              {/* <button data-toggle="tooltip" data-placement="right" title="View" type="button" className="ml-1 mr-2">
            <span className="view1">
              <img src={view} alt="view-button" />
            </span>
          </button>
          <button data-toggle="tooltip" data-placement="left" title="Edit" type="button" className="mr-2">
            <span className="view1">
              <img src={edit} alt="edit-button" />
            </span>
          </button> */}
              <img
                onClick={() => getZone(zon)}
                src={DeleteSvg}
                alt="delete-button"
                width="15px"
                className="img-pointer"
              />
            </div>
          </td>
        </tr>
      ))
    ) : (
      <NoItemRow colSpan="3" text="No Zones" />
    );

  return (
    <>
      {zonesTable}
      <DeleteZoneModal zone={zone} type="zone" />
    </>
  );
};

export default ZonesTable;
