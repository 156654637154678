import axios from 'axios';
import {
  ADD_INCIDENT_REPORT_URL,
  GET_INCIDENT_REPORTS_URL,
  GET_INCIDENT_REPORTS_BY_DATE_URL,
} from '../../../services/api/noc/incident-reports/IncidentReportUrl';
import { ADD_INCIDENT_REPORT, GET_INCIDENT_REPORTS } from '../../../constants/types/noc/NocTypes';
import { FETCHING_STARTS, FETCHING_STOPS } from '../../../constants/types/shared/LoadingTypes';
import { API_URL } from '../../../utils/config';

export const createIncident = (data) => async (dispatch) => {
  try {
    const response = await axios.post(`${ADD_INCIDENT_REPORT_URL}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data.id) {
      dispatch({
        type: ADD_INCIDENT_REPORT,
        payload: response.data,
      });
    }
  } catch (e) {}
};

export const getIncidentReports = (data) => async (dispatch) => {
  dispatch({
    type: FETCHING_STARTS,
    payload: true,
  });
  
  const params = new URLSearchParams();
  try {
    
    // Add parameters only if they are defined
    // // if (data.page) params.append('page', data.page);
    // if (data.impact) params.append('impact', data.impact);
    // if (data.cause) params.append('cause_of_incident', data.cause);
    if (data.description) params.append('description', data.description);

    const url = `${GET_INCIDENT_REPORTS_URL}/${data.page}?${params.toString()}`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      dispatch({
        type: GET_INCIDENT_REPORTS,
        payload: response.data,
      });
    }
  } catch (e) {
    dispatch({
      type: FETCHING_STOPS,
      payload: false,
    });
  }

  // count: 169
  // pages: 7
  // reports:
};

export const getPaginatedIncidentReport = (data) => async (dispatch) => {
  dispatch({
    type: FETCHING_STARTS,
    payload: true,
  });

  try {
    const response = await axios.get(`${GET_INCIDENT_REPORTS_URL}/${data}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      dispatch({
        type: GET_INCIDENT_REPORTS,
        payload: response.data,
      });
    }
  } catch (e) {
    dispatch({
      type: FETCHING_STOPS,
      payload: false,
    });
  }
  // count: 169
  // pages: 7
  // reports:
};

export const getIncidentReportByDate = (data) => async (dispatch) => {
  dispatch({
    type: FETCHING_STARTS,
    payload: true,
  });

  try {
    const response = await axios.post(`${API_URL}/incidentReport/getReportsByDate/1`, data, {
      // will fix this. what are you now waiting for??????

      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      dispatch({
        type: GET_INCIDENT_REPORTS,
        payload: response.data,
      });
    }
  } catch (e) {
    dispatch({
      type: FETCHING_STOPS,
      payload: false,
    });
  }
};
