/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable import/prefer-default-export */
import { useState, useReducer } from 'react';
import toastr from 'toastr';
import { create } from '../../../services/api/shared/budget/BudgetService';
import { budgetReducer } from '../../../reducers/shared/budget/BudgetReducer';
import * as types from '../../../constants/types/shared/budget/BudgetTypes';

export const useBudget = () => {
  const budgetState = {
    itemType: '',
    itemDescription: '',
    typeDigit: '',
    quantity: '',
    currency: '',
    month: '',
    total: '',
    comment: '',
  };
  const [budget, setBudget] = useState(budgetState);
  const [{ loading }, dispatch] = useReducer(budgetReducer, { loading: false });
  const onChange = (e) => {
    const { name, value } = e.target;
    setBudget({ ...budget, [name]: value });
  };
  const createBudget = async (data) => {
    dispatch({ type: types.CREATE_BUDGET });
    const { itemType, itemDescription, typeDigit, quantity, currency, month, total, comment } = data;
    const values = {
      department_id: `${sessionStorage.getItem('departmentId')}`,
      item_type: itemType,
      item_description: itemDescription,
      type_digit: typeDigit,
      quantity,
      currency: currency || 'NGN',
      month,
      total,
      comment,
    };
    try {
      const response = await create(values);
      if (response.data.id) {
        dispatch({ type: types.CREATED_BUDGET });
        toastr.success('Budget Created!');
        setBudget(budgetState);
      } else {
        dispatch({ type: types.CREATE_BUDGET_FAILED });
        toastr.info('Unable to create budget!');
      }
    } catch (e) {
      dispatch({ type: types.CREATE_BUDGET_FAILED });
      toastr.info('Something went wrong!');
    }
  };
  return { budget, onChange, createBudget, loading };
};
