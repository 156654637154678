import React, { useContext } from 'react';
import RequisitionContext from '../../../../../context/shared/requisitions/RequsitionContext';

const ManagerRequisition = () => {
  const { getManagersRaisedRequisition } = useContext(RequisitionContext);

  return (
    <div className="my-3">
      <button className="btn btn-info mt-1 copy-font" type="submit" onClick={() => getManagersRaisedRequisition()}>
        My Requisitions
      </button>
    </div>
  );
};

export default ManagerRequisition;
