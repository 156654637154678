import axios from 'axios';
import { toast } from 'react-toastify';
import { useReducer } from 'react';
import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';
import { GET_CONNECTED_DEDICATED_CUSTOMERS_URL } from '../../../services/api/business-development/connected-dedicated-customers/ConnectedDedicatedCustomers';
import { GET_CONNECTED_DEDICATED_CUSTOMERS } from '../../../constants/types/business-development/connected-dedicated-customers/ConnectedDedicatedCustomersTypes';
import ConnectedDedicatedCustomersReducer from '../../../reducers/business-development/connected-dedicated-customers/ConnectedDedicatedCustomersReducer';

const useConnectedDedicatedCustomers = () => {
  const [{ customers, totalPages, currentPage, allPages, isLoading }, dispatch] = useReducer(
    ConnectedDedicatedCustomersReducer,
    {
      customers: [],
      totalPages: '',
      allPages: '',
      currentPage: '',
      isLoading: false,
    },
  );

  const getConnectedDedicatedCustomers = async (data) => {
    dispatch({ type: LOADING_STARTS });
    try {
      const response = await axios.post(`${GET_CONNECTED_DEDICATED_CUSTOMERS_URL}`, data);
      if (response.status === 200) {
        // console.log(response.data.customers);
        dispatch({
          type: GET_CONNECTED_DEDICATED_CUSTOMERS,
          payload: {
            customers: response.data.customers,
            totalPages: response.data.total_records,
            allPages: response.data.total_pages,
            currentPage: response.data.current_page,
          },
        });
      }
    } catch (e) {
      toast.info('Something went wrong!');
      if (e.response) {
        dispatch({ type: LOADING_STOPS });
      }
    }
  };

  return {
    getConnectedDedicatedCustomers,
    customers,
    totalPages,
    allPages,
    currentPage,
    isLoading,
  };
};

export default useConnectedDedicatedCustomers;
