/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import NocNavBar from '../../../layouts/noc/NocNavBar';
import { NOC, OPERATIONS, PROCUREMENT, SUPER_ADMIN } from '../../../../utils/departments/helpers';
import FieldSupportNavBar from '../../../layouts/field-support/FieldSupportNavBar';
import SuperAdminNavBar from '../../../layouts/super-admin/SuperAdminNavBar';
import { useUser } from '../../../../hooks/shared/user/useUser';
import TracklistBody from './TracklistBody';
import ProcurementNavBar from '../../../layouts/procurement/ProcurementNavBar';

const Tracklist = ({ history }) => {
    const departmentId = sessionStorage.getItem('departmentId');
    const { profile, user } = useUser();
  const { firstName } = profile;
  return (
    <div>
      {(departmentId === `${PROCUREMENT}` && (
        <div className="s-layout">
          <main className="s-layout__content col-lg-10 p-0">
            <ProcurementNavBar currentPage="TrackList" position={firstName} />
            <TracklistBody />
          </main>
        </div>
      )) ||
        (departmentId === `${NOC}` && (
          <div className="s-layout">
            <main className="s-layout__content col-lg-10 p-0">
              <NocNavBar currentPage="Tracklist" roleID={user.role_id} position={firstName} />
              <TracklistBody />
            </main>
          </div>
        )) ||
        (departmentId === `${OPERATIONS}` && (
          <div className="s-layout">
            <main className="s-layout__content col-lg-10 p-0">
              <FieldSupportNavBar currentPage="Tracklist" roleID={user.role_id} position={firstName} />
              <TracklistBody />
            </main>
          </div>
        )) ||
        (departmentId === `${SUPER_ADMIN}` && (
          <div className="s-layout">
            <main className="s-layout__content col-lg-10 p-0">
              <SuperAdminNavBar currentPage="Tracklist" roleID={user.role_id} position={firstName} />
              <TracklistBody />
            </main>
          </div>
        ))}
    </div>
  );
};

export default Tracklist;
