/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { usePurchaseOrder } from '../../../../hooks/shared/purchase-order/usePurchaseOrder';
import CreatePurchaseOrder from './CreatePurchaseOrder';
import EmployeePurchaseOrderBody from './EmployeePurchaseOrderBody';
import ManagerPurchaseOrderBody from './ManagerPurchaseOrderBody';
import { OPERATIONS, TECHNICAL_SUPPORT } from '../../../../utils/departments/helpers';

const PurchaseOrderBody = ({ deptID }) => {
  const {
    getDeptPurchaseOrder,
    getFSEPurchaseOrder,
    trackPurchaseOrder,
    trackFSEPurchaseOrder,
    getStaffPurchaseOrder,
    tracker,
    lists,
    isLoading,
    combined,
    combinedFSE,
  } = usePurchaseOrder();

  const [page] = useState(1);

  useEffect(() => {
    if (deptID === OPERATIONS || deptID === TECHNICAL_SUPPORT) getFSEPurchaseOrder(OPERATIONS, TECHNICAL_SUPPORT);
    else getDeptPurchaseOrder(deptID);
  }, [deptID]);

  return (
    <div className="col-12 p-0 after-summary">
      <div className="row mx-2">
        <div className="col-12 my-2 callog">
          <div className="d-flex justify-content-between pt-4 mr-2">
            <CreatePurchaseOrder />
            <div>
              {Number(sessionStorage.getItem('roleId')) > 2 ? (
                lists.length || combinedFSE.length ? (
                  <>
                    <button
                      className="btn btn-info copy-font mr-2"
                      type="button"
                      onClick={() => getStaffPurchaseOrder(page)}
                    >
                      My Purchase Order
                    </button>
                    {tracker ? (
                      <button
                        className="btn btn-green copy-font"
                        type="button"
                        onClick={() =>
                          deptID === OPERATIONS || deptID === TECHNICAL_SUPPORT
                            ? getFSEPurchaseOrder(OPERATIONS, TECHNICAL_SUPPORT)
                            : getDeptPurchaseOrder(deptID)
                        }
                      >
                        Manager Approval
                      </button>
                    ) : (
                      <button
                        className="btn btn-green copy-font"
                        type="button"
                        onClick={() =>
                          deptID === OPERATIONS || deptID === TECHNICAL_SUPPORT
                            ? trackFSEPurchaseOrder(OPERATIONS, TECHNICAL_SUPPORT)
                            : trackPurchaseOrder(deptID)
                        }
                      >
                        All Purchase Order
                      </button>
                    )}
                  </>
                ) : (
                  <button
                    className="btn btn-green copy-font"
                    type="button"
                    onClick={() =>
                      deptID === OPERATIONS || deptID === TECHNICAL_SUPPORT
                        ? trackFSEPurchaseOrder(OPERATIONS, TECHNICAL_SUPPORT)
                        : trackPurchaseOrder(deptID)
                    }
                  >
                    All Purchase Order
                  </button>
                )
              ) : null}
            </div>
          </div>
        </div>

        {Number(sessionStorage.getItem('roleId')) > 2 ? (
          <ManagerPurchaseOrderBody
            tracker={tracker}
            lists={combined && (deptID === OPERATIONS || deptID === TECHNICAL_SUPPORT) ? combinedFSE : lists}
            isLoading={isLoading}
          />
        ) : (
          <EmployeePurchaseOrderBody />
        )}
      </div>
    </div>
  );
};

export default PurchaseOrderBody;
