/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import qs from 'qs';
import { useCleanLTE } from '../../../../../hooks/field-support/clean-imsi/useCleanLTE';
import CleanLTETable from './CleanLTETable';

const CleanLTEBody = () => {
  const { searchLTE, lists, isLoading } = useCleanLTE();
console.log(lists);
  const [imsi, setImsi] = useState('');

  const data = {
    imsi: imsi,
  };


  const onSubmit = (e) => {
    e.preventDefault();
    searchLTE(data);
  };

  // useEffect(() => {
  //   getCustomerDetails(data);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <div className="mt-5 p-3">
      <div className="container-fluid pt-4 ">
        <div className="row">
          <form className="form-inline mt-4 inner-addon d-flex" onSubmit={onSubmit}>
            <div className="form-group mx-2">
            
              <label className="mt-2 p-0 copy-font">Search by IMSI </label>
              <div className="input-group mb-3 mr-3">
                <input
                  className="form-control mr-auto empty pl-5"
                  type="text"
                  placeholder="search for user"
                  name="imsi"
                  value={imsi}
                  onChange={(e) => setImsi(e.target.value)}
                />
                <span className="fa fa-search" />
              </div>
            </div>

            <div className="mb-3">
              <button className="btn btn-green" style={{ height: '39px' }}>
                Search
              </button>
            </div>
          </form>
        
          <CleanLTETable items={lists} isLoading={isLoading} />
        
        </div>
      </div>
    </div>
  );
};

export default CleanLTEBody;
