import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../../../assets/img/logo/Tizeti-Logo.png';
import mailblast from '../../../../../assets/img/icons/mailblast.svg';
import Logout from '../../../shared/auth/Logout';
import KpiMonitorBody from '../../../shared/kpis/KpiMonitorBody';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import AdminDepartmentSideBar from '../../../../layouts/admin-department/AdminDepartmentSideBar';

const AdminDepartmentKpiMonitor = () => {
  const { profile, user } = useUser();
  const { firstName } = profile;
  const { role_id } = user;
  return (
    <div className="main-wrapper">
      <header className="topbar shade bg-white fixed-top">
        <nav className="navbar navbar-expand-lg navbar-light top-navbar fixed-top">
          <Link className="navbar-brand" to="/customer-support">
            <img src={logo} alt="homepage" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse mt-3" id="navbarText">
            <ul className="navbar-nav">
              <li className="nav-item active">
                <Link className="nav-link ml-5" to="#">
                  <h3>
                    KPI Monitor <span className="sr-only">(current)</span>
                  </h3>
                </Link>
              </li>
            </ul>
            <span className="navbar-text d-flex">
              {Number(role_id) > 2 ? (
                <Link
                  to="/customer-support/mail-blast"
                  className="d-flex mr-4"
                  style={{
                    color: 'gray',
                    textDecoration: 'none',
                  }}
                >
                  <img src={mailblast} className="logout mr-2" alt="" />
                  <span>
                    <h6 className="mt-2">
                      <strong>MailBlast</strong>
                    </h6>
                  </span>
                </Link>
              ) : null}
              <h6 className="admin mt-2">
                {firstName}
                <Logout />
              </h6>
            </span>
          </div>
        </nav>
      </header>

      <div className="all-sides col-lg-12 col-md-12 col-sm-12">
        <AdminDepartmentSideBar role={role_id} />
        <KpiMonitorBody />
      </div>
    </div>
  );
};

export default AdminDepartmentKpiMonitor;
