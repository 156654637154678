import React from 'react';
import { ToastContainer } from 'react-toastify';
import NocNavBar from '../../../../../layouts/noc/NocNavBar';
import LteEnbIpBody from './LteEnbIpBody';
import useCheckTwoDepartments from '../../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';
import { admin, NOC } from '../../../../../../utils/config';
import { useUser } from '../../../../../../hooks/shared/user/useUser';
import LteEnbIpProvider from './provider/LteEnbIpProvider';

const LteEnbIp = ({ history }) => {
  const { profile } = useUser();
  const { firstName } = profile;
  useCheckTwoDepartments(`${NOC}`, `${admin}`, history);
  return (
    <div className="s-layout">
      <LteEnbIpProvider>
        <main className="s-layout__content col-lg-10 p-0">
          <NocNavBar currentPage="NOC Sheet - LTE ENB IP" position={firstName} />
          <LteEnbIpBody />
        </main>
        <ToastContainer />
      </LteEnbIpProvider>
    </div>
  );
};

export default LteEnbIp;
