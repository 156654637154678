/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import CustomerSupportNavBar from '../../../../layouts/customer-support/CustomerSupportNavBar';
import InstallationsPerBasestationBody from '../../../shared/installations/per-basestation/InstallationsPerBasestationBody';

const CustomerSupportInstallationsPerBasestation = () => {
  const { profile } = useUser();
  const { firstName } = profile;
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <CustomerSupportNavBar currentPage="Installations 7 Days Ago" position={firstName} />
        <InstallationsPerBasestationBody />
      </main>
    </div>
  );
};
export default CustomerSupportInstallationsPerBasestation;
