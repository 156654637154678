import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { DEPARTMENT_LIST } from '../../../../utils/departments/helpers';
import { API_URL } from '../../../../utils/config';
import axios from 'axios';
import toastr from 'toastr';
import useFetch from '../../../../hooks/shared/custom/data/useFetch';
import { useProfile } from '../../../../hooks/shared/user/profile/useProfile';

const AddVehicleForm = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { userProfile, onChange } = useProfile();
  let states = [];

  const { data, loading } = useFetch(`https://api.tizeti.com/api/v1/legacy/get_list_of_regions`);

  const { country } = userProfile;
  // submit form
  const addVehicle = (data) => {
    setIsLoading(true);
    const config = {
      method: 'post',
      url: `${API_URL}/vehicle`,
      data: data,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };
    axios(config)
      .then(() => {
        toastr.success('Vehicle added successfully');
        setIsLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        if (error) {
          setIsLoading(false);
          toastr.error('Error adding vehicle');
        }
      });
  };

  const civ = {
    mo: "Cote d'Ivoire",
  };

  if (country === 'NG') {
    states = data && data.filter((country) => country.country === 'Nigeria').map((state) => state.state);
  } else if (country === 'GH') {
    states = data && data.filter((country) => country.country === 'Ghana').map((state) => state.state);
  } else if (country === 'CIV') {
    states = data && data.filter((country) => country.country === civ.mo).map((state) => state.state);
  }
  //  form submission
  const formik = useFormik({
    initialValues: {
      color: '',
      make: '',
      model: '',
      engine_no: '',
      chasis_no: '',
      department_id: '',
      plate_number: '',
      registration_status: '',
      transmission_type: '',
      cost: '',
      registration_number: '',
      location: '',
      handler: '',
      year_of_purchase: '',
      file: [],
    },
    validationSchema: VEHICLE_SCHEMMA,
    onSubmit: (values) => {
      const data = new FormData();
      for (let x = 0; x < values.file.length; x++) {
        data.append('file', values.file[x]);
      }
      data.append('color', values.color);
      data.append('model', values.model);
      data.append('make', values.make);
      data.append('engine_no', values.engine_no);
      data.append('chasis_no', values.chasis_no);
      data.append('department_id', values.department_id);
      data.append('plate_number', values.plate_number);
      data.append('registration_number', values.registration_number);
      data.append('transmission_type', values.transmission_type);
      data.append('cost', values.cost);
      data.append('location', values.location);
      data.append('registration_status', values.registration_status);
      data.append('handler', values.handler);
      data.append('year_of_purchase', values.year_of_purchase);

      addVehicle(data);
    },
  });
  return (
    <div id="addVehicle" className="modal fade p-5" role="dialog">
      <div className="modal-dialog modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Add Vehicle</h4>
            <button type="button" className="close mr-2" data-dismiss="modal">
              X
            </button>
          </div>
          <div className="modal-body p-3">
            <div className="container-fluid">
              <div className="row">
                <SelectField
                  id="make"
                  label="Make"
                  className="mb-3"
                  error={!!formik.touched.make && !!formik.errors.make}
                  inputProps={{
                    value: formik.values.make,
                    onChange: formik.handleChange('make'),
                    onBlur: formik.handleBlur('make'),
                  }}
                >
                  <option defaultValue={'car make'}>Choose car make</option>
                  {carMakes.map((car, index) => (
                    <option value={car} key={index}>
                      {car}
                    </option>
                  ))}
                </SelectField>

                <InputField
                  label="Model"
                  type="text"
                  name="model"
                  placeholder="car model"
                  inputProps={{
                    value: formik.values.model,
                    onChange: formik.handleChange('model'),
                    onBlur: formik.handleBlur('model'),
                  }}
                  error={!!formik.touched.model && !!formik.errors.model}
                  helperText={!!formik.touched.model && formik.errors.model}
                />
                <InputField
                  label="Color"
                  type="text"
                  name="color"
                  placeholder="car color"
                  inputProps={{
                    value: formik.values.color,
                    onChange: formik.handleChange('color'),
                    onBlur: formik.handleBlur('color'),
                  }}
                  error={!!formik.touched.color && !!formik.errors.color}
                  helperText={!!formik.touched.color && formik.errors.color}
                />
                <InputField
                  label="Engine Number"
                  type="text"
                  name="engine_no"
                  placeholder="engine number"
                  inputProps={{
                    value: formik.values.engine_no,
                    onChange: formik.handleChange('engine_no'),
                    onBlur: formik.handleBlur('engine_no'),
                  }}
                  error={!!formik.touched.engine_no && !!formik.errors.engine_no}
                  helperText={!!formik.touched.engine_no && formik.errors.engine_no}
                />
                <InputField
                  label="Chasis Number"
                  type="text"
                  name="chasis_no"
                  placeholder=" chasis number"
                  inputProps={{
                    value: formik.values.chasis_no,
                    onChange: formik.handleChange('chasis_no'),
                    onBlur: formik.handleBlur('chasis_no'),
                  }}
                  error={!!formik.touched.chasis_no && !!formik.errors.chasis_no}
                  helperText={!!formik.touched.chasis_no && formik.errors.chasis_no}
                />
                <InputField
                  label="Plate Number"
                  type="text"
                  name="plate_number"
                  placeholder="plate number"
                  inputProps={{
                    value: formik.values.plate_number,
                    onChange: formik.handleChange('plate_number'),
                    onBlur: formik.handleBlur('plate_number'),
                  }}
                  error={!!formik.touched.plate_number && !!formik.errors.plate_number}
                  helperText={!!formik.touched.plate_number && formik.errors.plate_number}
                />
                <InputField
                  label="Driver"
                  type="text"
                  name="handler"
                  placeholder="driver"
                  inputProps={{
                    value: formik.values.handler,
                    onChange: formik.handleChange('handler'),
                    onBlur: formik.handleBlur('handler'),
                  }}
                  error={!!formik.touched.handler && !!formik.errors.handler}
                  helperText={!!formik.touched.handler && formik.errors.handler}
                />
                <div className="col-md-12 my-0 px-3">
                  <p className="my-0 fs-5 copy-font">Registration Status</p>
                  <div className="d-flex justify-content-between align-items-center m-0">
                    <label
                      htmlFor="registration_status"
                      className="mr-5 d-flex justify-content-between align-items-center fs-4"
                    >
                      <input
                        className="mr-2"
                        type="radio"
                        name="registration_status"
                        value="1"
                        onChange={formik.handleChange}
                      />
                      Registered
                    </label>
                    <label
                      htmlFor="registration_status"
                      className="d-flex justify-content-between align-items-center fs-4"
                    >
                      <input
                        className="mr-2"
                        type="radio"
                        name="registration_status"
                        value="0"
                        onChange={formik.handleChange}
                      />
                      Unregistered
                    </label>
                  </div>
                </div>
                <SelectField
                  id="department_id"
                  label="Department"
                  className="mb-3"
                  error={!!formik.touched.department_id && !!formik.errors.department_id}
                  inputProps={{
                    value: formik.values.department_id,
                    onChange: formik.handleChange('department_id'),
                    onBlur: formik.handleBlur('department_id'),
                  }}
                >
                  <option defaultValue={'choose dept'}>Choose Department</option>
                  {DEPARTMENT_LIST.map((dept, index) => (
                    <option value={dept.department_id} key={index}>
                      {dept.name}
                    </option>
                  ))}
                </SelectField>
                <SelectField
                  id="transmission_type"
                  label="Transmission Type"
                  className="mb-3"
                  error={!!formik.touched.transmission_type && !!formik.errors.transmission_type}
                  inputProps={{
                    value: formik.values.transmission_type,
                    onChange: formik.handleChange('transmission_type'),
                    onBlur: formik.handleBlur('transmission_type'),
                  }}
                >
                  <option defaultValue={'transmission type'}>Choose transmission type</option>
                  <option value="Manual">Manual </option>
                  <option value="Automatic">Automatic</option>
                </SelectField>
                <InputField
                  label="Cost (₦)"
                  type="number"
                  name="cost"
                  placeholder="cost"
                  inputProps={{
                    value: formik.values.cost,
                    onChange: formik.handleChange('cost'),
                    onBlur: formik.handleBlur('cost'),
                  }}
                  error={!!formik.touched.cost && !!formik.errors.cost}
                  helperText={!!formik.touched.cost && formik.errors.cost}
                />
                <InputField
                  label="Registration Number"
                  type="text"
                  name="registration_number"
                  placeholder="registration number"
                  inputProps={{
                    value: formik.values.registration_number,
                    onChange: formik.handleChange('registration_number'),
                    onBlur: formik.handleBlur('registration_number'),
                  }}
                  error={!!formik.touched.registration_number && !!formik.errors.registration_number}
                  helperText={!!formik.touched.registration_number && formik.errors.registration_number}
                />

                <div className="col-md-6 mb-2">
                  <p className="fs-5 copy-font">Country</p>
                  <select
                    onChange={onChange}
                    required={true}
                    defaultValue={country}
                    name="country"
                    className="form-control"
                  >
                    <option>Country</option>
                    <option value="GH">Ghana</option>
                    <option value="NG">Nigeria</option>
                    <option value="CIV">Cote d'Ivoire</option>
                  </select>
                </div>

                <SelectField
                  id="location"
                  label="Location"
                  className="mb-3"
                  inputProps={{
                    value: formik.values.location,
                    onChange: formik.handleChange('location'),
                    onBlur: formik.handleBlur('location'),
                  }}
                  error={!!formik.touched.location && !!formik.errors.location}
                  helperText={!!formik.touched.location && formik.errors.location}
                >
                  <option>Choose location</option>
                  {states.map((state, index) => (
                    <option key={index} value={state}>
                      {state}
                    </option>
                  ))}
                </SelectField>
                <InputField
                  label="Date of Purchase"
                  type="date"
                  name="year_of_purchase"
                  inputProps={{
                    value: formik.values.year_of_purchase,
                    onChange: formik.handleChange('year_of_purchase'),
                    onBlur: formik.handleBlur('year_of_purchase'),
                  }}
                  error={!!formik.touched.year_of_purchase && !!formik.errors.year_of_purchase}
                  helperText={!!formik.touched.year_of_purchase && formik.errors.year_of_purchase}
                />

                <InputField
                  label="Vehicle/Insurance Document"
                  type="file"
                  name="file"
                  inputProps={{
                    onChange: (event) => {
                      formik.setFieldValue('file', event.currentTarget.files);
                    },
                  }}
                  multiple={true}
                  error={!!formik.touched.file && !!formik.errors.file}
                  helperText={!!formik.touched.file && formik.errors.file}
                />
              </div>
            </div>
            <div className="col-12 my-3 text-center">
              <button disabled={isLoading} type="submit" className="btn btn-green" onClick={formik.handleSubmit}>
                {isLoading ? 'Adding' : 'Add Vehicle'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddVehicleForm;

const InputField = (props) => {
  const { label, type, error, multiple, inputProps, helperText, placeholder } = props;
  return (
    <div className="col-md-6 mb-2">
      <p className="fs-5 copy-font">{label}</p>
      <input
        type={type}
        {...inputProps}
        multiple={multiple}
        placeholder={placeholder}
        className="form-control border "
      />
      {error && (
        <p className="mt-2 fs-6 text-danger" id="error">
          {helperText}
        </p>
      )}
    </div>
  );
};

const SelectField = (props) => {
  const { inputProps, label, id, error, helperText, children, name } = props;

  return (
    <div className="col-md-6 mb-2">
      <p className="fs-5 copy-font">{label}</p>
      <select name={name} id={id} className="form-control" {...inputProps}>
        {children}
      </select>
      {error && (
        <p className="mt-2 fs-6 text-danegr" id="error">
          {helperText}
        </p>
      )}
    </div>
  );
};
const VEHICLE_SCHEMMA = yup.object({
  color: yup.string().required('Color is required'),
  model: yup.string().required('Model is required'),
  make: yup.string().required('Make is required'),
  engine_no: yup.string().required('Engine Number is required'),
  chasis_no: yup.string().required('Chasis Number is required'),
  department_id: yup.string().required('Department is required'),
  plate_number: yup.string().required('Plate number is required'),
  registration_status: yup.string().required('Registration Number is required'),
  transmission_type: yup.string().required('Transmission type is required'),
  cost: yup
    .number()
    .required()
    .label('Cost is required'),
  registration_number: yup
    .string()
    .required()
    .label('Registration Number'),
  handler: yup.string().required('Handler is required'),
  year_of_purchase: yup.string().required('Year of purchase is required'),
});

const carMakes = [
  'Audi',
  'BMW',
  'Chevrolet',
  'Ford',
  'Honda',
  'Hyundai',
  'Jeep',
  'Kia',
  'Lexus',
  'Mazda',
  'Mercedes-Benz',
  'Nissan',
  'Toyota',
  'Volkswagen',
  'Volvo',
];
