import React from 'react';
import AdminDeptNavBar from '../../../../layouts/admin-department/AdminDeptNavBar';
import LeaveTable from '../../../shared/employee-leave-days/LeaveTable';
export default function EmployeeLeaveDaysOverview() {
  const firstName = sessionStorage.getItem('firstName');

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 px-0">
        <AdminDeptNavBar currentPage="Employee Leave Days Module" position={firstName} />
        <div style={{ marginTop: '8rem' }}></div>
        <LeaveTable />
      </main>
    </div>
  );
}
