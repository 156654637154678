import { SALES } from '../../../../../../../utils/config';

// eslint-disable-next-line import/prefer-default-export
export const showContentForDepartment = (department, param) => {
  if (department === `${SALES}`) {
    return null;
  }
  return param;
};
export const showContentForTwoDepartment = (dept1, dept2, given1, given2, param) => {
  if (dept1 === given1 || dept2 === given2) {
    return param;
  }
  return null;
};
export const showContentForGivenDepartment = (department, given, param) => {
  if (department === given) {
    return param;
  }
  return null;
};
