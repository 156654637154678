import { API_URL } from '../../../../utils/config';

// eslint-disable-next-line import/prefer-default-export
export const EDIT_MAC_ID_URL = `${API_URL}/account/changemacid`;
export const EDIT_FIBREMAC_ID_URL = `${API_URL}/account/edit_macid_and_salestype`;
export const EDIT_RETRIEVED_URL = `${API_URL}/legacy/getuserinfo`;
export const EDIT_EMAIL_URL = `${API_URL}/account/changeemail`;
export const EDIT_NAME_URL = `${API_URL}/account/changename`;
export const EDIT_PHONE_NUMBER_URL = `${API_URL}/account/changephonenumber`;
export const EDIT_ADDRESS_URL = `${API_URL}/account/changeaddress`;
