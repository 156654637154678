import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label, LabelList } from 'recharts';
import Box from '@mui/material/Box';


const BarCharts = ({ data, isLoading }) => {
  return (
    <Box sx={{ mr: 5 }}>
      <Box sx={{ width: { xs: '50vw', md: 1000, lg: '100%' }, overflowX: 'auto' }}>
        <Box sx={{ width: '100%', overflowX: 'scroll' }}>
          <BarChart
            width={4000}
            height={500}
            data={data}
            margin={{
              top: 5,
              right: 10,
              left: 30,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis>
              <Label value="Suppot Calls Count" position="insideLeft" angle={-90} />
            </YAxis>
            <Tooltip />
            <Legend />

            <Bar type="monotone" dataKey="totalCallCount" fill="#8884d8" >
            <LabelList dataKey="totalCallCount" position="top" />
            </Bar>
          </BarChart>
        </Box>
      </Box>
    </Box>
  );
};

export default BarCharts;
