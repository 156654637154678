import React from 'react';
import { Link } from 'react-router-dom';
import PATHS from '../../../../routes/urls';
import SheetAndCnMaestroSidebarItem from '../shared/SheetAndCnMaestroSidebarItem';

const NocSheetSideBar = () => {
  return (
    <>
      <span className="s-sidebar__nav-link d-none d-md-block" data-toggle="collapse" data-target="#noc-sheet">
        <li>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link to="#" target="_self" className="">
            <span className="mr-2">
              <img src="https://res.cloudinary.com/teewhy/image/upload/v1547725174/IP_Allocation.png" alt="img" />
            </span>
            NOC Sheets
          </Link>
        </li>
      </span>
      <span id="noc-sheet" className="collapse base-li top-bottom">
        <SheetAndCnMaestroSidebarItem url={PATHS.NOC_ACCESS_POINT_CONNECTION} name="Access Point Connection" />
        <SheetAndCnMaestroSidebarItem url={PATHS.NOC_HOTSPOT_SERVER} name="Hotspot Server" />
        <SheetAndCnMaestroSidebarItem url={PATHS.NOC_ENB_SITE_IP_ALLOCATION} name="eNB Site IP Allocation" />
        <SheetAndCnMaestroSidebarItem url={PATHS.NOC_PTP} name="PTP" />
        <SheetAndCnMaestroSidebarItem url={PATHS.NOC_FACEBOOK_SITES} name="Facebook Sites" />
        <SheetAndCnMaestroSidebarItem url={PATHS.NOC_ROUTER_IP_ADDRESS} name="Router IP Address" />
        <SheetAndCnMaestroSidebarItem url={PATHS.NOC_SECTOR_ALLOCATION} name="Sector Allocation" />
        <SheetAndCnMaestroSidebarItem url={PATHS.NOC_SNMP_CACTI} name="SNPM Cacti" />
        <SheetAndCnMaestroSidebarItem url={PATHS.NOC_FIBER_PPTP} name="Fiber PPTP" />
        <SheetAndCnMaestroSidebarItem url={PATHS.NOC_BASE_STATION_CONNECTION} name="Basestation Connection" />
        <SheetAndCnMaestroSidebarItem url={PATHS.NOC_PTP_VLAN_IP} name="PTP Vlan Ip" />
        <SheetAndCnMaestroSidebarItem url={PATHS.NOC_SNMP} name="SNMP" />

        <SheetAndCnMaestroSidebarItem
          url={PATHS.NOC_CPU_UTILIZATION_OF_FIBER_SITES}
          name="CPU Utilization of Fiber Sites"
        />
        <SheetAndCnMaestroSidebarItem url={PATHS.NOC_LTE_ENB_IP} name="LTE ENB IP" />
        <SheetAndCnMaestroSidebarItem url={PATHS.NOC_STATIC_IP_CUSTOMERS} name="Static Ip Customer" />
        <SheetAndCnMaestroSidebarItem url={PATHS.NOC_FIBER_SITES_PUBLIC_IP} name="Fiber Sites Public Ip" />
        <SheetAndCnMaestroSidebarItem url={PATHS.NOC_IP_ALLOCATION} name="Ip Allocation" />
        <SheetAndCnMaestroSidebarItem url={PATHS.NOC_ALLOCATION_PTP} name="IP Allocation PTP" />
        <SheetAndCnMaestroSidebarItem url={PATHS.NOC_PUBLIC_IP_ALLOCATION} name="Public Ip Allocation" />
        <SheetAndCnMaestroSidebarItem url={PATHS.NOC_LISTS_OF_BASESTATIONS} name="List of Basestations" />

        <SheetAndCnMaestroSidebarItem url={PATHS.NOC_CAMBIUM_LICENSE_KEYS} name="Cambium License Keys" />
      </span>
    </>
  );
};

export default NocSheetSideBar;
