/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import Moment from 'react-moment';
import PropTypes from 'prop-types';
import edit from '../../../../assets/img/icons/edit.png';
import UpdateTracklistItem from './UpdateTracklistItem';
import loader from '../../../../assets/img/icons/TizetiLoaderColored.gif';

const TracklistTable = ({ lists, load }) => {
  const [one, setOne] = useState({});

  const getItem = (list) => {
    setOne(list);
  };

  const tableData =
    lists.length && lists ? (
      lists.map((list, i) => {
        const {
          item,
          date,
          resource_classification_id,
          quantity,
          // location,
          vendor,
          invoice_submission_status,
          cost,
          invoice_no,
          currency,
          available_quantity,
          EmployeeAuth,
        } = list;
        return (
          <tr className="text-nowrap" key={i}>
            <td>{i + 1}</td>
            <td>{item}</td>
            <td>
              {resource_classification_id === 1 ? 'Inventory' : resource_classification_id === 2 ? 'Assets' : 'Expense'}
            </td>
            <td>{vendor}</td>
            <td className="text-center">{quantity}</td>
            {/* <td className="text-center">{location ? location.toUpperCase() : 'N/A'} </td> */}
            <td className="text-center">{available_quantity ? available_quantity : 'N/A'}</td>
            <td>{invoice_no}</td>
            <td>
              {currency === 'USD' && '$ '}
              {currency === 'NGN' && '₦ '}
              {currency === 'GHC' && 'GH₵ '}
              {cost}
            </td>
            <td>
              <Moment format="DD-MM-YYYY">{date}</Moment>
            </td>
            <td>
              {EmployeeAuth && EmployeeAuth.employees.length
                ? `${EmployeeAuth.employees[0].firstName} ${EmployeeAuth.employees[0].lastName}`
                : 'N/A'}
            </td>
            <td>
              <div
                className={`text-uppercase badge badge-${
                  invoice_submission_status === 'new'
                    ? 'success'
                    : invoice_submission_status === 'bad' || invoice_submission_status === 'not-available'
                    ? 'danger'
                    : 'secondary'
                }`}
                style={{ width: '100%', marginTop: '3%' }}
              >
                {invoice_submission_status}
              </div>
            </td>
            <td>
              <div className="text-center">
                <button
                  type="button"
                  data-toggle="modal"
                  data-placement="right"
                  data-target="#update_item"
                  onClick={() => getItem(list)}
                >
                  <span className="view">
                    <img src={edit} data-toggle="tooltip" alt="" data-placement="top" title="Edit" />
                  </span>
                </button>
              </div>
              <UpdateTracklistItem OneItem={one} />
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="6" className="text-center">
          There are no items
        </td>
      </tr>
    );

  return (
    <div className="card x-panel mt-2 px-0">
      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Item</th>
              <th>Class</th>
              <th>Company/Vendor</th>
              <th>Initial Quantity</th>
              {/* <th>Location</th> */}
              <th>Remainder</th>
              <th>Invoice No.</th>
              <th>Cost</th>
              <th>Date Received</th>
              <th>Added By</th>
              <th>Status</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {load ? (
              <tr>
                <td colSpan="12" className="text-center">
                  <img src={loader} alt="loading gif" />
                </td>
              </tr>
            ) : (
              tableData
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

TracklistTable.propTypes = {
  load: PropTypes.bool.isRequired,
  lists: PropTypes.arrayOf.isRequired,
};

export default TracklistTable;
