/* eslint-disable import/prefer-default-export */
import {
  FETCHED_CUSTOMER_PAYMENTS,
  START_FETCHING_CUSTOMER_PAYMENTS,
  STOP_FETCHING_CUSTOMER_PAYMENTS,
} from '../../../constants/types/shared/customers/payments/PaymentsTypes';

export const paymentsReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case START_FETCHING_CUSTOMER_PAYMENTS:
      return {
        ...state,
        gettingPayments: true,
      };

    case STOP_FETCHING_CUSTOMER_PAYMENTS:
      return {
        ...state,
        gettingPayments: false,
      };

    case FETCHED_CUSTOMER_PAYMENTS:
      return {
        ...state,
        payments: payload,
        gettingPayments: false,
      };

    default:
      return state;
  }
};
