export const GET_ALL_REQUISITION = 'GET_ALL_REQUISITION';
export const SET_CHECK_ALL_REQUISTION = 'SET_CHECKED_ALL_REQUISTION';
export const SET_CHECK_ONE_OF_ALL_REQUISTION = 'SET_CHECK_ONE_OF_ALL_REQUISTION';
export const GET_ALL_REQUISITIONS = 'GET_ALL_REQUISITIONS';
export const START_LOADING_ACCOUNTS_REQUSITION = 'START_LOADING_ACCOUNTS_REQUSITION';
export const STOP_LOADING_ACCOUNTS_REQUSITION = 'STOP_LOADING_ACCOUNTS_REQUSITION';
export const START_APPROVE_OR_DISAPPROVE = 'START_APPROVE_OR_DISAPPROVE';
export const APPROVE_OR_DISAPPROVE_SUCCESS = 'APPROVE_OR_DISAPPROVE_SUCCESS';
export const STOP_APPROVE_OR_DISAPPROVE = 'STOP_APPROVE_OR_DISAPPROVE';
export const GET_LEADS_REQUISITIONS = 'GET_LEADS_REQUISITIONS';
export const SHOW_MODAL = 'SHOW_MODAL';
