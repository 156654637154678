const Helpers = {
  messageToBeSent(param) {
    switch (param) {
      case 'NEW INSTALLS':
        return 'Dear Customer, We are unable to reach you on your number to get a feedback on your experience since joining Tizeti Network.Please send us your response via internet@wifi.com.ng. TIZETI';
      case 'ACTIVE CUSTOMERS':
        return 'Dear Customer, We are unable to reach you on your number to get a feedback on your experience with our internet service. Please send us your response via internet@wifi.com.ng. TIZETI';
      case 'EXPIRED CUSTOMERS':
        return 'Dear Customer, We are unable to reach you on your number to get a feedback on your experience with our internet service while your link was active Please send us your feedback via internet@wifi.com.ng TIZETI';

      case 'TEMPORARY SOLUTION':
        return 'Dear Customer, Sincere apologies for the delayed service disruption. The issue is still been addressed and you will be compensated duly. Regards';
      default:
        return 'Hello From Tizeti';
    }
  },
};
export default Helpers;
