import React, { useContext } from 'react';
import ReactPaginate from 'react-paginate';
import { KycContext } from '../../../../context/lte/kyc/KycContext';
import LteKycTableBody from './kyc/LteKycTableBody';

const LteTable = () => {
  const { customers, pages, fetching, handlePageClick } = useContext(KycContext);

  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th>#</th>
            <th>First name</th>
            <th>Last name</th>
            <th>Phone</th>
            <th>Address</th>
            <th>Activate</th>
          </tr>
        </thead>
        {fetching ? <small>loading</small> : <LteKycTableBody customers={customers} pages={pages} />}
      </table>
      {pages > 1 ? (
        <ReactPaginate
          previousLabel="previous"
          nextLabel="next"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={pages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName="pagination"
          subContainerClassName="pages pagination"
          activeClassName="active"
        />
      ) : null}
    </div>
  );
};

export default LteTable;
