/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

const Label = ({ classname, name, htmlfor }) => {
  return (
    <label className={classname} htmlFor={htmlfor}>
      {name}
    </label>
  );
};

export default Label;
