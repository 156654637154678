import React, { useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import CustomButton from '../../../shared/forms/CustomButton';
import Label from '../../../shared/forms/Label';
import CustomInput from '../../../shared/forms/CustomInput';
import { ZonesContext } from '../../../shared/installations/zones/provider/ZonesContext';
import styles from '../../../new-design/installations/NewDesign.module.css';

const tabIndex = -1;
const CreateZone = () => {
  const { loadingForm, onZoneChange, zone, onSubmitZone } = useContext(ZonesContext);
  const { name } = zone;
  const submitForm = () => {
    return name.length > 0;
  };
  const submit = (e) => {
    e.preventDefault();
    onSubmitZone();
  };
  return (
    <div
      id="create-zone"
      className="modal fade in"
      tabIndex={tabIndex}
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Add Zone
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body">
            <form className="form-horizontal" onSubmit={submit}>
              <div className="form-group">
                <div className="col-md-12 mb-1 d-flex">
                  <div className="col-lg-12">
                    <Label classname="col-lg-12 text-justify copy-font m-0 p-0" name="Zone" />
                    <CustomInput
                      value={name}
                      name="name"
                      onChange={onZoneChange}
                      type="text"
                      classname={styles.form_control}
                      placeholder="Enter zone name"
                      readOnly={false}
                    />
                  </div>
                </div>
              </div>
              <div className="text-center">
                {loadingForm ? (
                  <CustomButton disabled={loadingForm} name="Adding Zone..." classname="btn btn-green waves-effect" />
                ) : (
                  <CustomButton disabled={!submitForm()} name="Add Zone" classname="btn btn-green waves-effect" />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CreateZone;
