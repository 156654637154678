import React from 'react';
import { ToastContainer } from 'react-toastify';
import NocNavBar from '../../../../../layouts/noc/NocNavBar';
import RebootBody from './RebootBody';
import { admin, NOC } from '../../../../../../utils/config';
import { useUser } from '../../../../../../hooks/shared/user/useUser';
import RebootProvider from './provider/RebootProvider';
import useCheckTwoDepartments from '../../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';

const Reboot = ({ history }) => {
  const { profile } = useUser();
  const { firstName } = profile;
  useCheckTwoDepartments(`${NOC}`, `${admin}`, history);
  return (
    <div className="s-layout">
      <RebootProvider>
        <main className="s-layout__content col-lg-10 p-0">
          <NocNavBar currentPage="NOC Sheet - Reboot" position={firstName} />
          <div className="container">
            <div className="row mt-5">
              <div className="mt-4 col-lg-12 col-md-12 col-sm-12">
                <div className="container">
                  <RebootBody />
                </div>
              </div>
            </div>
          </div>
        </main>
        <ToastContainer />
      </RebootProvider>
    </div>
  );
};

export default Reboot;
