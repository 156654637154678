import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import toastr from 'toastr';
import Logout from '../../../shared/auth/Logout';
import AddPlans from './AddPlans';
import { getUser } from '../../../../../actions/shared/user/UserActions';
import WifiCallSidebar from '../../../../layouts/wificall-sidebar/WifiCallSidebar';
import { getWificallPlans } from '../../../../../actions/wificall/plans/PlansActions';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import { admin, header, NEW_PRODUCTS } from '../../../../../utils/config';

class WifiCallPlan extends Component {
  static propTypes = {
    plans: PropTypes.shape().isRequired,
    getWificallPlans: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    firstName: PropTypes.string.isRequired,
  };

  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.getUser();
    // eslint-disable-next-line react/destructuring-assignment
    this.props.getWificallPlans();
    this.checkDeepartment();
  }

  componentWillReceiveProps() {
    this.checkDeepartment();
  }

  checkDeepartment = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, header);

      if (response.data.department_id !== `${admin}` && response.data.department_id !== `${NEW_PRODUCTS}`) {
        // eslint-disable-next-line react/prop-types,react/destructuring-assignment
        this.props.history.push('/');
        sessionStorage.setItem('token', '');
        sessionStorage.clear();
        toastr.info('You are not allowed to view this page');
      }
    } catch (e) {
      console.log(e.response);
    }
  };

  render() {
    const { user, plans } = this.props;
    const { firstName } = user;

    const nf = new Intl.NumberFormat();

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link className="s-sidebar__trigger" to="#">
                <i className="fa fa-bars mr-3" />
              </Link>
              <WifiCallSidebar />

              <h3 className="col-lg-6 overview-part">Plans</h3>

              <div className="d-flex">
                <h6 className="mr-0 mt-1">
                  {firstName}
                  <Logout />
                </h6>
              </div>
            </div>
          </div>

          <div className="col-12 after-summary">
            <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-3">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-3 text-right">
                  <button className="btn btn-green" data-toggle="modal" data-target="#plan">
                    Add Plans
                  </button>
                  <AddPlans />
                </div>

                <div className="card x-panel">
                  <div className="table-responsive content">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Amount</th>
                          <th>Time Allocated</th>
                          <th>Max Users</th>
                          <th>Call Recording Duration</th>
                          <th>Duration</th>
                        </tr>
                      </thead>

                      <tbody>
                        {plans.map((plan, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{plan.name}</td>
                            <td>N {nf.format(plan.amount)}</td>
                            <td>{nf.format(plan.timeallocated)}</td>
                            <td className="text-center">{plan.maximumusers}</td>
                            <td>{plan.recordingduration}</td>
                            <td>{plan.planduration}</td>
                            {/* <td> */}
                            {/* <div className="ml-2"> */}
                            {/* <button type="submit" data-toggle="modal" data-target="#edit-plan" style={{ */}
                            {/* border: '0', */}
                            {/* backgroundColor: 'white' */}
                            {/* }}><span className="view"><img */}
                            {/* src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/edit.png" */}
                            {/* data-toggle="tooltip" data-placement="top" title="Edit" alt=""/></span></button> */}
                            {/* <EditPlan /> */}
                            {/* &nbsp; */}
                            {/* <button type="submit" data-toggle="modal" data-target="#cancel" style={{ */}
                            {/* border: '0', */}
                            {/* backgroundColor: 'white' */}
                            {/* }}><span className="view"><img */}
                            {/* src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/delete.png" */}
                            {/* data-toggle="tooltip" data-placement="right" title="Delete" alt="img"/></span> */}
                            {/* </button> */}
                            {/* </div> */}
                            {/* </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  plans: state.plans.plans,
  user: state.user.user,
});

export default connect(
  mapStateToProps,
  { getUser, getWificallPlans },
)(WifiCallPlan);
