import axios from 'axios';
import qs from 'qs';
import { toast } from 'react-toastify';
import * as types from '../../../constants/types/shared/crm/CrmTypes';
import { GET_USERS_FROM_CRM_URL } from '../../../services/api/shared/crm/CrmUrl';

// eslint-disable-next-line import/prefer-default-export
export const getCrmUsers = () => {
  return async (dispatch) => {
    dispatch({
      type: types.START_GETTING_CRM_USERS,
    });
    const data = {
      action: 'fetchallcontactdetails',
    };

    try {
      const response = await axios.post(`${GET_USERS_FROM_CRM_URL}`, qs.stringify(data), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      if (response.data.customer) {
        dispatch({
          type: types.GET_CRM_USERS,
          payload: response.data.customer.slice(10, 30),
        });
      }
    } catch (e) {
      if (e) {
        toast.info('Unable to fetch users from crm');
        dispatch({
          type: types.STOP_GETTING_CRM_USERS,
        });
      }
    }
  };
};

export const searchCrmUser = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.START_SEARCHING_CRM_USERS,
    });
    try {
      const response = await axios.post(`${GET_USERS_FROM_CRM_URL}`, qs.stringify(data), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      if (response.data.status === true) {
        dispatch({
          type: types.GET_CRM_USERS,
          payload: response.data.customer,
        });
      } else {
        dispatch({
          type: types.STOP_SEARCHING_CRM_USERS,
        });
      }
    } catch (e) {
      if (e) {
        dispatch({
          type: types.STOP_SEARCHING_CRM_USERS,
        });
      }
    }
  };
};
