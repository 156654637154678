/* eslint-disable @typescript-eslint/camelcase */
import axios from 'axios';
import { stringify } from 'qs';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import legacy from '../../../../../../services/api/axios';
import { FETCH_RADIOS_TO_BE_CLEANED_URL } from '../api/CleanRadioUrl';

const useCleanRadio = () => {
  const [cleanRadiosData, setCleanRadiosData] = useState([]);
  const [pages, setPages] = useState(0);
  const [fetching, setFetching] = useState(false);
  const [showCleanModal, setShowCleanModal] = useState(false);
  const [cleaning, setCleaning] = useState(false);
  const onOpenModal = () => {
    setShowCleanModal(!showCleanModal);
  };
  const cleanRadio = async (data) => {
    setCleaning(true);
    try {
      const response = await legacy.post('/index.php', stringify(data));
      if (response.data.result) {
        setCleaning(false);
        setShowCleanModal(false);
        toast.success('Cleaning succesful');
      }
    } catch (e) {
      setCleaning(false);
      setShowCleanModal(false);
      toast.info('Something went wrong!');
    }
  };
  const onPaginate = (page) => {};
  useEffect(() => {
    async function getRadiosToBeCleaned() {
      setFetching(true);
      return axios.post(
        `${FETCH_RADIOS_TO_BE_CLEANED_URL}`,
        {
          procurement_approval: 'approved',
          account_approval: 'approved',
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );
    }
    getRadiosToBeCleaned()
      .then((response) => {
        setCleanRadiosData(response.data.list);
        setPages(response.data.pages);
        setFetching(false);
      })
      .catch(() => {
        setFetching(false);
        toast.info('Sonething went wrong!');
      });
  }, []);
  return { cleanRadiosData, fetching, pages, onPaginate, onOpenModal, showCleanModal, cleanRadio, cleaning };
};
export default useCleanRadio;
