import React, { useState } from 'react';
import PropTypes from 'prop-types';
import edit from '../../../../assets/img/icons/edit.png';
import loader from '../../../../assets/img/icons/TizetiLoaderColored.gif';
import EditCustomerBasestation from './EditCustomerBasestation';

const BasestationDataTable = ({ basestationUsers, oldBasestation, load }) => {
  const [userID, setUserID] = useState('');
  const [macID, setMacID] = useState('');
  const [expiry, setExpiry] = useState('');

  const getUserBasestation = (basestationUser) => {
    setUserID(basestationUser.id);
    setMacID(basestationUser.mac_id);
    setExpiry(basestationUser.expiration[1]);
  };

  const tableData =
    basestationUsers.length && basestationUsers ? (
      basestationUsers.map((basestationUser, i) => {
        const { firstName, lastname, phoneNumber, email, address } = basestationUser.user;
        return (
          <tr key={basestationUser.id}>
            <td>{i + 1}</td>
            <td>{basestationUser.mac_id}</td>
            <td>{firstName}</td>
            <td>{lastname}</td>
            <td>{email}</td>
            <td className="text-nowrap">{basestationUser.expiration[1]}</td>
            <td>{phoneNumber}</td>
            <td>{address}</td>
            <td className="ml-2">
              <button
                type="button"
                data-toggle="modal"
                data-target="#edit-details"
                onClick={() => getUserBasestation(basestationUser)}
              >
                <img src={edit} alt="" width="50%" data-toggle="tooltip" data-placement="top" title="Details" />
              </button>
            </td>
            <EditCustomerBasestation
              oldBasestation={oldBasestation}
              expiryDate={expiry}
              userID={userID}
              macID={macID}
            />
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="10">Select Basestation to view Connected Users</td>
      </tr>
    );

  return (
    <div className="table-responsive content col-12">
      <table className="table">
        <thead>
          <tr>
            <th>#</th>
            <th>MAC</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th className="text-nowrap">Expiry Date</th>
            <th>Phone</th>
            <th>Address</th>
            <th>Details</th>
          </tr>
        </thead>

        <tbody>
          {load ? (
            <tr>
              <td colSpan="10">
                <img src={loader} alt="loading gif" />
              </td>
            </tr>
          ) : (
            tableData
          )}
        </tbody>
      </table>
    </div>
  );
};

BasestationDataTable.propTypes = {
  basestationUsers: PropTypes.arrayOf.isRequired,
  load: PropTypes.bool.isRequired,
  oldBasestation: PropTypes.string.isRequired,
};

export default BasestationDataTable;
