/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import '../../../../styles/style.css';
import axios from 'axios';
import toastr from 'toastr';
import Profile from '../../../../assets/img/icons/Profile.png';
import loader from '../../../../assets/img/icons/TizetiLoaderColored.gif';
import Group from '../../../../assets/img/icons/Group.png';
import Payroll from '../../../../assets/img/icons/Accounts/Payroll_icon.png';
import Payroll1 from '../../../../assets/img/icons/Accounts/Payroll_icon-1.png';
import Inventory from '../../../../assets/img/icons/Accounts/Inventory_icon.png';
import Group2 from '../../../../assets/img/icons/Accounts/Group_2.1.png';
import { API_URL } from '../../../../utils/config';
import { ACCOUNTS, SUPER_ADMIN } from '../../../../utils/departments/helpers';
import ViewUserProfile from '../../shared/staff/ViewUserProfile';
import AccountsNavBar from '../../../layouts/accounts/AccountsNavBar';

const AccountsOverview = () => {
  const role_id = sessionStorage.getItem('roleId');
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <AccountsNavBar currentPage="Overview" role_id={role_id} />

        <div className="container mt-5">
          <ViewUserProfile />
          <div className="row mt-5">
            <div className="p-1 mt-5 col-lg-12 col-md-12 col-sm-12">
              <h6 className="mb-3 ml-3">Showing statistics of the past 30 days</h6>
            </div>

            <div className="col-lg col-md col-sm">
              <div className="boxes">
                <div className="text-center">
                  <div className="text-center mt-3">
                    <img src={Group} alt="" />
                  </div>
               <div className="count">3444</div>
                  <h3 className="color-two">Total Staff</h3>
                </div>
              </div>
            </div>

            <div className="col-lg col-md col-sm">
              <div className="boxes">
                <div className="text-center mt-4">
                  <img src={Payroll} alt="payrollIcon" />
                </div>
                <div className="count">-</div>
                <h3 className="color-three">Total Salary</h3>
              </div>
            </div>

            <div className="col-lg col-md col-sm">
              <div className="boxes">
                <div className="text-center mt-4">
                  <img src={Payroll1} alt="" />
                </div>
                <div className="count">-</div>
                <h3 style={{ color: '#20A8F4' }}>Average Salary</h3>
              </div>
            </div>

            <div className="col-lg col-md col-sm">
              <div className="boxes">
                <div className="text-center mt-4">
                  <img src={Inventory} alt="" />
                </div>
 <div className="count">6789</div>
                <h3 className="color-six">Total Inventory</h3>
              </div>
            </div>

            <div className="col-lg col-md col-sm">
              <div className="boxes">
                <div className="text-center mt-4">
                  <img src={Group2} alt="" />
                </div>
                <div className="count">2500</div>
                <h3 className="color-one">Total Activated</h3>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card-group mt-1">
                <div className="card o-income">
                  <div className="card-body col-lg-12 d-flex">
                    <div className="col-lg-12">
                      <div className="d-flex">
                        <h6 style={{ color: '#27A8B4' }}>Total revenue by Department </h6>
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12" style={{ padding: '0px' }}>
                        <p className="copy-font col-lg-4">Showing records of the past 30days</p>
                        {/* <div className="col-lg-8 col-sm-6"> */}
                        <div className="col-12 graph-key">
                          <p
                            style={{
                              width: '23px',
                              height: '18px',
                              backgroundColor: '#673FBB',
                            }}
                          />
                          <p className="copy-font mr-2">Unlimited Internet</p>
                          <p
                            style={{
                              width: '23px',
                              height: '18px',
                              backgroundColor: '#F37705',
                            }}
                          />
                          <p className="copy-font mr-2">Dedicated</p>
                          <p
                            style={{
                              width: '23px',
                              height: '18px',
                              backgroundColor: '#14B0B0',
                            }}
                          />
                          <p className="copy-font mr-2">Hotspot</p>
                          <p
                            style={{
                              width: '23px',
                              height: '18px',
                              backgroundColor: '#7E184C',
                            }}
                          />
                          <p className="copy-font">WifiCall</p>
                        </div>
                        {/* </div> */}
                      </div>

                      <div id="account-graph" style={{ height: '300px' }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
export default AccountsOverview;
