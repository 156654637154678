/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable import/prefer-default-export */
import { useReducer, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import toastr from 'toastr';
import * as load from '../../../constants/types/shared/LoadingTypes';
import * as types from '../../../constants/types/shared/purchase-order/PurchaseOrderTypes';
import * as url from '../../../services/api/procurement/purchase-order/PurchaseOrderURL';
import { purchaseOrderReducer } from '../../../reducers/shared/purchase-order/purchaseOrderReducer';

export const usePurchaseOrder = () => {
  const [{ technicalPO, operationsPO, lists, count, pages, isLoading, tracker, combined }, dispatch] = useReducer(
    purchaseOrderReducer,
    {
      technicalPO: [],
      operationsPO: [],
      lists: [],
      count: 1,
      pages: 1,
      isLoading: false,
      tracker: false,
      combined: false,
    },
  );

  const PO_State = {
    item: '',
    quantity: '',
    comment: '',
  };

  const decisionState = {
    amount: '',
    currency: 'NGN',
    budgetCode: '',
    approvalStatus: 'approved',
    approvalComment: '',
  };

  const [purchaseOrder, setPurchaseOrder] = useState(PO_State);
  const [PODecision, setPODecision] = useState(decisionState);
  const [invoice, setInvoice] = useState('');

  const allFSE = { technicalPO, operationsPO };
  const combinedFSE = Object.values(allFSE).flatMap((arr) => arr);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPurchaseOrder({ ...purchaseOrder, [name]: value });
  };

  const handlePODecision = (e) => {
    const { name, value } = e.target;
    setPODecision({ ...PODecision, [name]: value });
  };

  const handleInvoice = (e) => {
    const { files } = e.target;
    setInvoice(files);
  };

  const createPurchaseOrder = async (data) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(`${url.CREATE_PURCHASE_ORDER_URL}`, qs.stringify(data), {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.id) {
        toastr.success('PO Successfully Created!');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const getDeptPurchaseOrder = async (department_id) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.get(`${url.GET_DEPT_PURCHASE_ORDER_URL}/${department_id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_PURCHASE_ORDER,
          payload: {
            list: response.data.list,
            count: response.data.count,
            pages: response.data.pages,
          },
        });
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const getFSEPurchaseOrder = async (operations, technical) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      await Promise.all([
        axios.get(`${url.GET_DEPT_PURCHASE_ORDER_URL}/${technical}`, {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
        }),
        axios.get(`${url.GET_DEPT_PURCHASE_ORDER_URL}/${operations}`, {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
        }),
      ]).then((responses) => {
        dispatch({
          type: types.GET_FSE_PURCHASE_ORDER,
          payload: {
            technicalPO: responses[0].data.list,
            operationsPO: responses[1].data.list,
            combined: true,
          },
        });
      });
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const updatePurchaseOrder = async (id, data) => {
    const config = {
      method: 'patch',
      url: `${url.UPDATE_PURCHASE_ORDER_URL}/${id}`,
      data: qs.stringify(data),
      headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
    };

    await axios(config)
      .then((response) => {
        if (response.data[0] === 1) {
          toastr.success('Update Successful');
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((error) => {
        if (error) {
          toastr.info('Something went wrong!');
          dispatch({ type: load.LOADING_STOPS });
        }
      });
  };

  const getAllPurchaseOrder = async () => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.get(`${url.CREATE_PURCHASE_ORDER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_PURCHASE_ORDER,
          payload: {
            list: response.data.list,
            count: response.data.count,
            pages: response.data.pages,
          },
        });
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const procurementPODecision = async (id, data) => {
    const config = {
      method: 'patch',
      url: `${url.PROCUREMENT_PURCHASE_ORDER_DECISION_URL}/${id}`,
      data,
      headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
    };

    await axios(config)
      .then((response) => {
        if (response.data[0] === 1) {
          toastr.success('Update Successful');
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((error) => {
        if (error) {
          toastr.info('Something went wrong!');
          dispatch({ type: load.LOADING_STOPS });
        }
      });
  };

  const getAccountsPurchaseOrder = async () => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.get(`${url.ACCOUNT_PURCHASE_ORDER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_PURCHASE_ORDER,
          payload: {
            list: response.data.list,
            count: response.data.count,
            pages: response.data.pages,
          },
        });
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const accountsPODecision = async (id, data) => {
    const config = {
      method: 'patch',
      url: `${url.ACCOUNT_PURCHASE_ORDER_URL}/${id}`,
      data: qs.stringify(data),
      headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
    };

    await axios(config)
      .then((response) => {
        if (response.data[0] === 1) {
          toastr.success('Update Successful');
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((error) => {
        if (error) {
          toastr.info('Something went wrong!');
          dispatch({ type: load.LOADING_STOPS });
        }
      });
  };

  const getAccountsApprovedPO = async () => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.get(`${url.ACCOUNTS_APPROVED_PURCHASE_ORDER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_PURCHASE_ORDER,
          payload: {
            list: response.data.list,
            count: response.data.count,
            pages: response.data.pages,
          },
        });
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const trackPurchaseOrder = async (department_id) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.get(`${url.TRACK_PURCHASE_ORDER_URL}/${department_id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_PURCHASE_ORDER,
          payload: {
            list: response.data.list,
            count: response.data.count,
            pages: response.data.pages,
            tracker: true,
          },
        });
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const trackFSEPurchaseOrder = async (operations, technical) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      await Promise.all([
        axios.get(`${url.TRACK_PURCHASE_ORDER_URL}/${technical}`, {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
        }),
        axios.get(`${url.TRACK_PURCHASE_ORDER_URL}/${operations}`, {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
        }),
      ]).then((responses) => {
        dispatch({
          type: types.GET_FSE_PURCHASE_ORDER,
          payload: {
            technicalPO: responses[0].data.list,
            operationsPO: responses[1].data.list,
            tracker: true,
            combined: true,
          },
        });
      });
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const getStaffPurchaseOrder = async (page) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.get(`${url.GET_STAFF_PURCHASE_ORDER_URL}/${page}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_PURCHASE_ORDER,
          payload: {
            list: response.data.list,
            count: response.data.count,
            pages: response.data.pages,
            tracker: true,
          },
        });
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const handlePageClick = () => {
    // const selectedPage = e.selected + 1;
  };

  return {
    lists,
    pages,
    count,
    tracker,
    invoice,
    isLoading,
    purchaseOrder,
    PODecision,
    combined,
    combinedFSE,
    getStaffPurchaseOrder,
    handleInvoice,
    handlePODecision,
    handleChange,
    getAllPurchaseOrder,
    getAccountsApprovedPO,
    getAccountsPurchaseOrder,
    getDeptPurchaseOrder,
    getFSEPurchaseOrder,
    procurementPODecision,
    accountsPODecision,
    updatePurchaseOrder,
    handlePageClick,
    createPurchaseOrder,
    trackPurchaseOrder,
    trackFSEPurchaseOrder,
  };
};
