/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect } from 'react';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../../utils/loader/loadingHelper';
import { useBandwidth } from '../../../../../hooks/partnership/bandwidth/useBandwidth';
import AddPartnershipBandwidth from './AddPartnershipBandwidth';

const PartnershipBandwidthScheduleTable = ({
  view,
  setView,
  deleteBandwidthSchedule,
  load,
  lists,
  getBandwidthSchedule,
  isLoading,
  addBandwidthSchedule,
}) => {
  const colSpan = '10';

  const tableData =
    lists && lists.length ? (
      lists.map((list, i) => {
        return (
          <tr className="" key={i}>
            <td>{i + 1}</td>
            <td className="text-nowrap">{`${list.site_name}`}</td>
            <td>{list.monday || 'N/A'}</td>
            <td>{list.tuesday || 'N/A'}</td>
            <td>{list.wednesday || 'N/A'}</td>
            <td>{list.thursday || 'N/A'}</td>
            <td>{list.friday || 'N/A'}</td>
            <td>{list.saturday || 'N/A'}</td>
            <td>{list.sunday || 'N/A'}</td>
            <td>
              <button
                disabled={load}
                className="view"
                onClick={async () => {
                  await deleteBandwidthSchedule({ id: list.id });
                }}
              >
                <img
                  src="https://res.cloudinary.com/teewhy/image/upload/v1543579893/delete.png"
                  data-toggle="tooltip"
                  data-placement="right"
                  alt="delete"
                  title="Delete"
                />
              </button>
            </td>
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan={colSpan} text="There are no requests" />
    );

  useEffect(() => {
    getBandwidthSchedule();
  }, []);

  return (
    <div className="table-responsive content col-12 mt-4">
      <table className="table table-striped">
        <thead>
          <tr className="">
            <th> S/N</th>
            <th>Site Name</th>
            <th className="text-nowrap">Monday</th>
            <th className="text-nowrap">Tuesday</th>
            <th className="text-nowrap"> Wednesday</th>
            <th className="text-nowrap"> Thursday</th>
            <th className="text-nowrap"> Friday</th>
            <th className="text-nowrap"> Saturday</th>
            <th className="text-nowrap">Sunday</th>
            <th className="text-nowrap"></th>
          </tr>
        </thead>
        <tbody>{isLoading ? <TizetiGIFLogoTable colSpan={colSpan} /> : tableData}</tbody>
      </table>
      <AddPartnershipBandwidth view={view} setView={setView} addBandwidthSchedule={addBandwidthSchedule} />
    </div>
  );
};

export default PartnershipBandwidthScheduleTable;
