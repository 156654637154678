import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { TableCellMonths } from '../components/tempData';
import Box from '@mui/material/Box';

export default function AreaCharts({ data, isLoading }) {
  const [getData, setGetData] = React.useState([]);

  React.useEffect(() => {
    if (data.length > 0) {
      const refinedData = TableCellMonths.map((months, index) => ({
        name: months,
        '2023-total-turbo-inst-rev': data[0].values[index],
      }));

      setGetData(refinedData);
    }
  }, [data]);

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flexStart">
      {!isLoading && (
        <ResponsiveContainer width="100%" height={500}>
          <AreaChart
            data={getData}
            margin={{
              top: 5,
              right: 10,
              left: 0,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />

            <Area type="monotone" dataKey="2023-total-turbo-inst-rev" stroke="#82ca9d" fill="#8884d8" />
          </AreaChart>
        </ResponsiveContainer>
      )}
    </Box>
  );
}
