import axios from 'axios';
import {
  FETCHED_EXTENSIONS,
  START_FETCHING_EXTENSIONS,
  STOP_FETCHING_EXTENSIONS,
} from '../../../../constants/types/shared/customers/ExtensionsTypes';
import { GET_EXTENSIONS_URL, PAGINATE_EXTENSIONS_URL } from '../../../../services/api/shared/extensions/Extensions';

export const getExtensions = () => {
  return async (dispatch) => {
    dispatch({
      type: START_FETCHING_EXTENSIONS,
      payload: true,
    });

    try {
      const response = await axios.get(`${GET_EXTENSIONS_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data) {
        dispatch({
          type: FETCHED_EXTENSIONS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: STOP_FETCHING_EXTENSIONS,
          payload: false,
        });
      }
    } catch (e) {
      dispatch({
        type: STOP_FETCHING_EXTENSIONS,
        payload: false,
      });
    }
  };
};

export const paginateExtensions = (data) => {
  return async (dispatch) => {
    dispatch({
      type: START_FETCHING_EXTENSIONS,
      payload: true,
    });

    try {
      const response = await axios.get(`${PAGINATE_EXTENSIONS_URL}/${data}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data) {
        dispatch({
          type: FETCHED_EXTENSIONS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: STOP_FETCHING_EXTENSIONS,
          payload: false,
        });
      }
    } catch (e) {
      if (e) {
        dispatch({
          type: STOP_FETCHING_EXTENSIONS,
          payload: false,
        });
      }
    }
  };
};
