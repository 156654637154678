import * as load from '../../../constants/types/shared/LoadingTypes';
import * as types from '../../../constants/types/sales/installations/InstallationsTypes';

export const installationsSummaryReducer = (state, action) => {
  const { payload, type } = action;
  switch (type) {
    case types.GET_TARGETS:
      return {
        ...state,
        lagosMonthlyTarget: payload.lagosMonthlyTarget,
        abujaMonthlyTarget: payload.abujaMonthlyTarget,
        ogunMonthlyTarget: payload.ogunMonthlyTarget,
        riversMonthlyTarget: payload.riversMonthlyTarget,
        edoMonthlyTarget: payload.edoMonthlyTarget,
        oyoMonthlyTarget: payload.oyoMonthlyTarget,
        deltaMonthlyTarget: payload.deltaMonthlyTarget,
        enuguMonthlyTarget: payload.enuguMonthlyTarget,
        kanoMonthlyTarget: payload.kanoMonthlyTarget,
        kadunaMonthlyTarget: payload.kadunaMonthlyTarget,
        imoMonthlyTarget: payload.imoMonthlyTarget,
        abiaMonthlyTarget: payload.abiaMonthlyTarget,
        anambraMonthlyTarget: payload.anambraMonthlyTarget,
        crossRiverMonthlyTarget: payload.crossRiverMonthlyTarget,
      };

    case types.GET_MONTHLY_INSTALLED:
      return {
        ...state,
        lagosMonthlyCompleted: payload.lagosMonthlyCompleted,
        abujaMonthlyCompleted: payload.abujaMonthlyCompleted,
        ogunMonthlyCompleted: payload.ogunMonthlyCompleted,
        riversMonthlyCompleted: payload.riversMonthlyCompleted,
        edoMonthlyCompleted: payload.edoMonthlyCompleted,
        oyoMonthlyCompleted: payload.oyoMonthlyCompleted,
        deltaMonthlyCompleted: payload.deltaMonthlyCompleted,
        enuguMonthlyCompleted: payload.enuguMonthlyCompleted,
        kanoMonthlyCompleted: payload.kanoMonthlyCompleted,
        kadunaMonthlyCompleted: payload.kadunaMonthlyCompleted,
        imoMonthlyCompleted: payload.imoMonthlyCompleted,
        abiaMonthlyCompleted: payload.abiaMonthlyCompleted,
        anambraMonthlyCompleted: payload.anambraMonthlyCompleted,
        crossRiverMonthlyCompleted: payload.crossRiverMonthlyCompleted,
      };

    case types.GET_DAILY_INSTALLED:
      return {
        ...state,
        lagosDailyCompleted: payload.lagosDailyCompleted,
        abujaDailyCompleted: payload.abujaDailyCompleted,
        ogunDailyCompleted: payload.ogunDailyCompleted,
        riversDailyCompleted: payload.riversDailyCompleted,
        edoDailyCompleted: payload.edoDailyCompleted,
        oyoDailyCompleted: payload.oyoDailyCompleted,
        deltaDailyCompleted: payload.deltaDailyCompleted,
        enuguDailyCompleted: payload.enuguDailyCompleted,
        kanoDailyCompleted: payload.kanoDailyCompleted,
        kadunaDailyCompleted: payload.kadunaDailyCompleted,
        imoDailyCompleted: payload.imoDailyCompleted,
        abiaDailyCompleted: payload.abiaDailyCompleted,
        anambraDailyCompleted: payload.anambraDailyCompleted,
        crossRiverDailyCompleted: payload.crossRiverDailyCompleted,
      };

    case types.GET_MONTHLY_INSTALL_BY_STATUS:
      return {
        ...state,
        monthlyByStatus: payload,
      };

    case load.LOADING_STARTS:
      return {
        ...state,
        isLoading: true,
      };

    case load.LOADING_STOPS:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default installationsSummaryReducer;
