/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import ProcurementNavBar from '../../../../../layouts/procurement/ProcurementNavBar';
import { PROCUREMENT, SUPER_ADMIN } from '../../../../../../utils/departments/helpers';
import { useUser } from '../../../../../../hooks/shared/user/useUser';
import TracklistCategoryBody from '../../../../shared/tracklist-category/TracklistCategoryBody';
import useCheckTwoDepartments from '../../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';

const ProcurementTracklistCategory = ({ history }) => {
  useCheckTwoDepartments(`${PROCUREMENT}`, `${SUPER_ADMIN}`, history);

  const { profile, user } = useUser();
  const { firstName } = profile;
  const { role_id } = user;

  return (
    <div className="s">
      <main className="s-layout__content col-lg-10 p-0">
        <ProcurementNavBar currentPage="Tracklist Item" position={firstName} role_id={role_id} />
        <TracklistCategoryBody />
      </main>
    </div>
  );
};

ProcurementTracklistCategory.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default ProcurementTracklistCategory;
