/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useReferral } from '../../../../../hooks/sales/referral/useReferral';

const EditReferral = ({ request, request: { id, status } }) => {
  const { isLoading, editReferralRequest } = useReferral();
  const [one, setOne] = useState({
    referral_status: '',
  });
  const { referral_status } = one;

  useEffect(() => {
    const result = {
      referral_status: status,
    };
    setOne({ ...result });
  }, [request]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOne({ ...one, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      status: referral_status,
    };
    editReferralRequest(id, data);
  };

  const submitForm = () => {
    return referral_status.length > 0;
  };

  return (
    <div id="editReferral" className="modal fade" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header shadow">
            <h4 className="modal-title update_asset">Update Referral Status</h4>
            <button type="button" className="close mr-2" data-dismiss="modal">
              x
            </button>
          </div>
          <div className="modal-body detail mb-4 p-0">
            <form onSubmit={onSubmit} className="col-md-12">
              <div className="pt-2 d-flex">
                <div className="form-group d-flex mb-0 pb-0">
                  <label className="m-0 p-0 copy-font" htmlFor="firstName">
                    Status
                  </label>
                  <select id="status" name="referral_status" value={referral_status || 'N/A'} onChange={handleChange}>
                    <option value="">Select an option</option>
                    <option value="completed">Completed</option>
                    <option value="paid">Paid</option>
                  </select>
                </div>
              </div>

              <div className="text-center mt-3">
                {isLoading ? (
                  <button type="button" className="btn btn-green py-2 px-5" disabled>
                    Updating...
                  </button>
                ) : (
                  <button type="submit" className="btn btn-green py-2 px-5" disabled={!submitForm()}>
                    Update
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

EditReferral.propTypes = {
  request: PropTypes.objectOf.isRequired,
};

export default EditReferral;
