/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import { useCustomers } from '../../../../../hooks/new-products/products/wificall/customers/useCustomers';

const PaymentsBody = () => {
  const { getCustomerPayments, logs, count, paymentPages } = useCustomers();
  const [page] = useState(1);

  useEffect(() => {
    getCustomerPayments(page);
  }, []);

  const handlePageClick = async (e) => {
    const selectedPage = e.selected + 1;
    getCustomerPayments(selectedPage);
  };

  const tableData =
    logs.length && logs ? (
      logs.map((item, i) => {
        const { ip_address, email, cardid, reference, channel, amount, source, created_at } = item;
        return (
          <tr className="text-nowrap" key={i}>
            <td>{i + 1}</td>
            <td>{ip_address}</td>
            <td>{email}</td>
            <td>{cardid}</td>
            <td>{reference}</td>
            <td>{channel}</td>
            <td>{amount}</td>
            <td>{source === null ? 'N/A' : source}</td>
            <td>{created_at.slice(0, 10)}</td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="9" className="text-center">
          <img src={loader} alt="loading gif" />
        </td>
      </tr>
    );

  return (
    <div className="col-12 mt-5">
      <div className="col-12 m-0 p-0 after-summary">
        <div className="row ml-3">
          {logs.length ? (
            <div className="mb-2">
              Total Payments: <span className="badge badge-success payslipfont">{count}</span>
            </div>
          ) : null}
          <div className="card x-panel m-0 p-0">
            <div className="table-responsive content col-12 m-0 p-0">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th className="text-nowrap">IP Address</th>
                    <th>Email</th>
                    <th className="text-nowrap">Card ID</th>
                    <th>Reference</th>
                    <th>Channel</th>
                    <th>Amount</th>
                    <th>Source</th>
                    <th>Created Date</th>
                  </tr>
                </thead>
                <tbody>{tableData}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {paymentPages > 1 ? (
        <ReactPaginate
          previousLabel="previous"
          nextLabel="next"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={paymentPages}
          marginPagesDisplayed={3}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName="pagination"
          subContainerClassName="pages pagination"
          activeClassName="active"
        />
      ) : null}
    </div>
  );
};

export default PaymentsBody;
