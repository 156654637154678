/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';

function BusinessDevelopmentConnectedCustomersTable({ customers, isLoading }) {
  const tableData =
    customers && customers.length
      ? customers.map((customer, i) => {
          const { email, username, name, status, expiration, basestation } = customer;
          return (
            <tr className="text-nowrap" key={i}>
              <td>{i + 1}</td>
              <td>{email}</td>
              <td>{username}</td>
              <td>{name}</td>
              <td>{status}</td>
              <td>{expiration}</td>
              <td>{basestation}</td>
            </tr>
          );
        })
      : 'NO RECORD';
  return (
    <div className="card x-panel mt-2 px-0">
      <div className="table-responsive content col-12">
        <table className="table table-striped">
          <thead>
            <tr className="">
              <th>#</th>
              <th>Email</th>
              <th>Mac ID</th>
              <th>Name</th>
              <th>Status</th>
              <th>Expiry Date</th>
              <th>Basestation</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="8" className="text-center">
                  <img src={loader} alt="loading gif" />
                </td>
              </tr>
            ) : (
              tableData
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

BusinessDevelopmentConnectedCustomersTable.propTypes = {
  customers: PropTypes.arrayOf.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default BusinessDevelopmentConnectedCustomersTable;
