/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

const RoutersTableBody = ({ router }) => {
  const showRouter = () => {
    if (router && router.user) {
      return (
        <tr>
          <td>{`${router.user.firstName} ${router.user.lastName}`}</td>
          <td>{router.user.address}</td>
          <td>{router.user.email}</td>
          <td>{router.user.phoneNumber}</td>
          <td>{router.mac_id}</td>
          <td>{router.radio_tag}</td>
          <td>{router.router}</td>
          <td>{router.router_tag}</td>
        </tr>
      );
    }
    return null;
  };

  return <tbody>{showRouter()}</tbody>;
};

RoutersTableBody.propTypes = {
  router: PropTypes.shape({
    user: PropTypes.shape,
  }),
};

export default RoutersTableBody;
