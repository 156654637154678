import React from 'react';
import FieldSupportNavBar from '../../../../layouts/field-support/FieldSupportNavBar';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import RetrievalBody from '../../../shared/retrievals/RetrievalBody';
import useCheckDepartment from '../../../../../hooks/shared/custom/check-department/field-support/useCheckDepartment';
import DeviceRetrievalProvider from '../../../shared/device-retrieval/provider/DeviceRetrievalProvider';

const FieldSupportRetrievals = ({ history }) => {
  useCheckDepartment(history);
  const {
    profile: { firstName },
  } = useUser();
  return (
    <div className="s-layout">
      <DeviceRetrievalProvider>
        <main className="s-layout__content col-lg-10 p-0">
          <FieldSupportNavBar currentPage="Device Retrievals" position={firstName} />
          <RetrievalBody />
        </main>
      </DeviceRetrievalProvider>
    </div>
  );
};
export default FieldSupportRetrievals;
