/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

const RetailerDetails = ({ retailer, load }) => {
  console.log(retailer);
  return (
    <div
      id="hotspot-details"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4
              className="modal-title text-center"
              id="myModalLabel"
            >
              Retailer Details
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              ×
            </button>
          </div>
          <div
            className="modal-body detail"
            style={{ padding: '0', textAlign: 'justify' }}
          >
            {load ? (
              <small>fetching retailer...</small>
            ) : (
              <div className="row">
                <div className="col-lg-12 d-flex">
                  <div className="col-lg-4 detail-label1">
                    <label id="fn">Full Name</label>
                    <label id="email">Business Name</label>
                  </div>

                  <div className="col-lg-8 detail-result1">
                    <p className="mt-4 mb-1" />
                    <p className="mb-1">
                      {retailer.fullname}
                    </p>
                    <p className="mb-1">
                      {retailer.businessName}
                    </p>
                  </div>
                </div>
                <hr />
                <div className="col-lg-12 d-flex">
                  <div className="col-lg-4 detail-label2">
                    <label id="bal">Email</label>
                    <label id="bal">Phone number</label>
                  </div>

                  <div className="col-lg-8 detail-result2">
                    <p className="mt-4 mb-1">
                      {retailer.email}
                    </p>
                    <p className="mb-1">{retailer.phone}</p>
                  </div>
                </div>

                <div className="col-lg-12 d-flex">
                  <div className="col-lg-4 detail-label3">
                    <label
                      id="status"
                      style={{ display: 'block' }}
                    >
                      Address
                    </label>
                    <label
                      id="language"
                      style={{ display: 'block' }}
                    >
                      Created
                    </label>
                    <label
                      id="language"
                      style={{ display: 'block' }}
                    >
                      Activation Status
                    </label>
                    <label
                      id="language"
                      style={{ display: 'block' }}
                    >
                      Account ID
                    </label>
                  </div>

                  <div className="col-lg-8 detail-result3">
                    <p className="mt-4 mb-1">
                      {retailer.address}
                    </p>
                    <p className="mb-1">
                      {retailer.created}
                    </p>
                    <p className="mb-1">
                      {retailer.status}
                    </p>
                    <p className="mb-1">
                      {retailer.accountid}
                    </p>
                  </div>
                </div>

                <div className="col-lg-12 d-flex">
                  <div className="col-lg-4 detail-label4">
                    <label
                      id="status"
                      style={{ display: 'block' }}
                    >
                      Balance
                    </label>
                    <label
                      id="language"
                      style={{ display: 'block' }}
                    >
                      Status
                    </label>
                  </div>

                  <div className="col-lg-8 detail-result4">
                    <p className="mt-4 mb-1">
                      <span className="amount">
                        &#8358;
                      </span>
                      {retailer.balance}
                    </p>
                    <p className="mb-1">
                      {retailer.status}
                    </p>
                  </div>
                </div>
              </div>
              // </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

RetailerDetails.propTypes = {
  load: PropTypes.bool,
  retailer: PropTypes.shape(),
};

export default RetailerDetails;
