/* eslint-disable import/prefer-default-export */
import { useEffect, useState } from 'react';
import qs from 'qs';
import axios from 'axios';
import toastr from 'toastr';

import { API_URL } from '../../../utils/config';

const GET_TRANSACTIONS_URL = (from, to) => {
  const toDate = `${to} 23:59:59`;
  return `${API_URL}/paystack/getFailedPaystackTrasactions?from=${from}&to=${toDate}`;
};

const EDIT_PAYSTACK_TRANSACTION = `${API_URL}/paystack/editFailedPaystackCalled`;

export const usePaystack = () => {
  const fromDate = new Date();

  fromDate.setDate(fromDate.getDate() - 1);

  const toDate = new Date();

  const [payload, setPayload] = useState({
    from: fromDate.toISOString().split('T')[0],
    to: toDate.toISOString().split('T')[0],
  });

  const [lists, setLists] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [loader, setLoader] = useState(false);

  const handlePayload = (e) => {
    setPayload({ ...payload, [e.target.name]: e.target.value });
  };

  const getTransactions = async (dates) => {
    setIsLoading(true);
    try {
      const config = {
        method: 'get',
        url: GET_TRANSACTIONS_URL(dates.from, dates.to),
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      };

      const data = await axios(config);

      if (data.status === 200) {
        setLists(data.data);
      }
    } catch (error) {
      toastr.error('Something went wrong...');
    } finally {
      setIsLoading(false);
    }
  };

  const reloadGetTransactions = async (dates) => {
    try {
      const config = {
        method: 'get',
        url: GET_TRANSACTIONS_URL(dates.from, dates.to),
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      };

      const data = await axios(config);

      if (data.status === 200) {
        setLists(data.data);
      }
    } catch (error) {}
  };

  const editPaystackTransactions = async (id, handleClose, reload, dates, comment) => {
    setLoader(true);
    try {
      const config = {
        method: 'patch',
        url: EDIT_PAYSTACK_TRANSACTION,
        data: { id, comment },
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      };

      const data = await axios(config);

      if (data.status === 200) {
        toastr.success('Update Successful');
        handleClose();
        reload(dates);
      }
    } catch (error) {
      toastr.error('Something went wrong...');
    } finally {
      setLoader(false);
    }
  };

  return {
    lists,
    isLoading,
    loader,
    payload,
    setPayload,
    handlePayload,
    getTransactions,
    editPaystackTransactions,
    reloadGetTransactions,
  };
};
