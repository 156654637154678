import React from 'react'
import OperationsSideBar from '../../../../layouts/operations/OperationsSideBar'
import TCTable from './TCTable'
import { Link } from 'react-router-dom'
import Logout from '../../../shared/auth/Logout'


const TCOverview = () => {
  return (
    <div className='s-layout'>
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <Link className="s-sidebar__trigger" to="#">
                <i className="fa fa-bars mr-3" />
              </Link>
              <OperationsSideBar />

              <h3 className="col-lg-6 overview-part">Terms and Conditions</h3>

              <div className="d-flex profile-mail col-6">
                <h6>
                  {/* <i className="mr-auto">{firstName} </i> */}
                  <Logout />
                </h6>
              </div>
            </div>
          </div>

          <div className='mt-10'>
          <TCTable />
          </div>
        </main>
    </div>
  )
}

export default TCOverview;