/* eslint-disable react/button-has-type */
import React, { useContext } from 'react';
import RequisitionContext from '../../../../../../context/shared/requisitions/RequsitionContext';
import { useDepartments } from '../../../../../../hooks/shared/departments/useDepartments';

const GhanaRequsitionsFilters = () => {
  const { departments } = useDepartments();
  const { department, onChange, getGhanaRequsition } = useContext(RequisitionContext);
  const submit = () => department.length > 0;

  return (
    <p className="fliter copy-font d-flex mr-auto">
      <small className="mt-2 text-nowrap" style={{ paddingLeft: '8%' }}>
        Filter By:
      </small>
      <span>
        <select className="form-control" name="department" onChange={onChange}>
          <option>Select Departments</option>
          {departments && departments.length
            ? departments.map((dept) => <option value={dept.id}>{dept.name}</option>)
            : null}
        </select>
      </span>
      <button
        onClick={() => getGhanaRequsition(department)}
        className="btn btn-green copy-font my-1"
        disabled={!submit()}
      >
        Search Requisition
      </button>
    </p>
  );
};

export default GhanaRequsitionsFilters;
