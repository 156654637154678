import * as types from '../../constants/types/shared/customers/CustomerDetailsTypes';

const initialState = {
  customer: [],
  load: false,
  loading: false,
  session: [],
  details: [],
  // for password
  fetch: false,
  password: [],
};

export const CustomerDetailsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.START_FETCHING_CUSTOMER:
      return {
        ...state,
        load: payload,
      };

    case types.STOP_FETCHING_CUSTOMER:
      return {
        ...state,
        load: payload,
      };

    case types.FETCHED_CUSTOMER:
      return {
        ...state,
        customer: payload,
        load: false,
      };

    case types.FETCHED_CUSTOMER_DETAILS:
      return {
        ...state,
        loading: false,
        details: payload.user,
        session: payload.session,
      };

    case types.START_FETCHING_CUSTOMER_DETAILS:
      return {
        ...state,
        loading: payload,
      };

    case types.STOP_FETCHING_CUSTOMER_DETAILS:
      return {
        ...state,
        loading: payload,
      };

    case types.START_GETTING_PASSWORD:
      return {
        ...state,
        fetch: true,
      };

    case types.GET_PASSWORD:
      return {
        ...state,
        password: payload,
        fetch: false,
      };

    case types.STOP_GETTING_PASSWORD:
      return {
        ...state,
        fetch: false,
      };

    default:
      return state;
  }
};

export default CustomerDetailsReducer;
