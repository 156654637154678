import React from 'react';
import { accounts } from '../../../../../../../utils/config';
import CustomButton from '../../../../forms/CustomButton';
import '../../index.css';

const ManagerButtons = (
  {
    approving,
    approveRequisition,
    disapproving,
    disApproveRequisition,
    managerApprovalStatus,
    accountManagerApproval,
    accountApprovalStatus,
    accountManagerRejection,
  }, // accountManagerDisapproval,
) => {
  const showButton = () => {
    switch (managerApprovalStatus) {
      case 'approved':
        return (
          <CustomButton
            classname="btn btn-green btn-size"
            name={disapproving ? 'Disapproving...' : 'Disapprove'}
            disabled={disapproving}
            onClick={disApproveRequisition}
          />
        );
      case 'rejected':
        return (
          <CustomButton
            classname="btn btn-green btn-size"
            name={approving ? 'Approving...' : 'Approve'}
            disabled={approving}
            onClick={approveRequisition}
          />
        );
      default:
        return (
          <>
            <CustomButton
              classname="btn btn-green"
              name={disapproving ? 'Disapproving...' : 'Disapprove'}
              disabled={disapproving}
              onClick={disApproveRequisition}
            />
            <CustomButton
              classname="btn btn-green"
              name={approving ? 'Approving...' : 'Approve'}
              disabled={approving}
              onClick={approveRequisition}
            />
          </>
        );
    }
  };
  const showAccountButton = () => {
    switch (accountApprovalStatus) {
      case 'approved':
        return (
          <CustomButton
            classname="btn btn-green btn-size"
            name={disapproving ? 'Disapproving...' : 'Disapprove'}
            disabled={disapproving}
            onClick={accountManagerRejection}
          />
        );

      case 'rejected':
        return (
          <CustomButton
            classname="btn btn-green btn-size"
            name={approving ? 'Approving...' : 'Approve'}
            disabled={approving}
            onClick={accountManagerApproval}
          />
        );

      default:
        return (
          <>
            <CustomButton
              classname="btn btn-green"
              name={approving ? 'Approving...' : 'Approve'}
              disabled={approving}
              onClick={accountManagerApproval}
            />
            <CustomButton
              classname="btn btn-green"
              name={disapproving ? 'Disapproving...' : 'Disapprove'}
              disabled={disapproving}
              onClick={disApproveRequisition}
            />
          </>
        );
    }
  };
  return (
    <div className="col-12 mt-5">
      {localStorage.getItem('departmentId') === accounts ? showAccountButton() : showButton()}
    </div>
  );
};

export default ManagerButtons;
