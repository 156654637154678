/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-case-declarations */
import { useReducer, useState } from 'react';
import qs from 'qs';
import axios from 'axios';
import toastr from 'toastr';
import * as load from '../../../constants/types/shared/LoadingTypes';
import * as types from '../../../constants/types/express-wifi/ExpressWifiTypes';
import { XWF_RETAILERS_URL, XWF_RETAILER_COMMENT_URL } from '../../../services/api/express-wifi/ExpressWifiURL';
import { XWFRetailersReducer } from '../../../reducers/express-wifi/retailers/XWFRetailersReducer';

const useXWFRetailers = () => {
  const [{ retailers, pages, isLoading, searchRetailersToggle, retailerComments, isFetching }, dispatch] = useReducer(
    XWFRetailersReducer,
    {
      retailers: [],
      comments: [],
      pages: 1,
      isLoading: false,
      searchRetailersToggle: false,
      retailerComments: [],
      isFetching: false,
    },
  );

  const detailsState = { option: '', optionValue: '', fromDate: '', toDate: '' };

  const [details, setDetails] = useState(detailsState);

  const { option, optionValue, fromDate, toDate } = details;

  const onOptionChange = (e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };

  const getXWFRetailers = async (page) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.get(`${XWF_RETAILERS_URL}/${page}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.status === 'success') {
        const { result } = response.data;
        dispatch({
          type: types.GET_EXPRESSWIFI_RETAILERS,
          payload: {
            retailers: result.data,
            count: result.count,
            pages: result.pages,
          },
        });
      }
    } catch (e) {
      toastr.info('Error Fetching Retailers!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const getXWFRetailerComment = async (id) => {
    dispatch({ type: load.FETCHING_STARTS });
    try {
      const response = await axios.get(`${XWF_RETAILER_COMMENT_URL}/${id}/comments`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.status === 'success') {
        const { result } = response.data;
        dispatch({
          type: types.GET_XWF_RETAILER_COMMENTS,
          payload: {
            retailerComments: result.data,
            count: result.count,
            pages: result.pages,
          },
        });
      }
    } catch (e) {
      toastr.info('Error Fetching Retailer Comments!');
      dispatch({ type: load.FETCHING_STOPS });
    }
  };

  const addXWFRetailerComment = async (id, params) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(`${XWF_RETAILER_COMMENT_URL}/${id}/comments`, params, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.status === 200) {
        toastr.success('Comment Added!');
        setTimeout(() => window.location.reload(), 1000);
      } else toastr.warn('Unable To Add Comment');
    } catch (e) {
      toastr.info('Error Adding Comment...');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const searchByRetailerDetails = async (page, params) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(`${XWF_RETAILERS_URL}/${page}`, params, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.data) {
        dispatch({
          type: types.SEARCH_EXPRESSWIFI_RETAILERS,
          payload: {
            retailers: response.data.data,
            count: response.data.count,
            pages: response.data.pages,
          },
        });
      }
    } catch (e) {
      toastr.info('Error Searching Retailers!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const searchRetailers = (page) => {
    switch (option) {
      case 'businessName':
        const businessNameSearch = { Business: optionValue.trim() };
        return searchByRetailerDetails(page, businessNameSearch);
      case 'contactName':
        const contactNameSearch = { ContactName: optionValue.trim() };
        return searchByRetailerDetails(page, contactNameSearch);
      case 'phoneNumber':
        const phoneNumberSearch = { phoneNumber: optionValue.trim() };
        return searchByRetailerDetails(page, phoneNumberSearch);
      case 'status':
        const statusSearch = { status: optionValue.trim() };
        return searchByRetailerDetails(page, statusSearch);
      case 'zone':
        const zoneSearch = { zone: optionValue.trim() };
        return searchByRetailerDetails(page, zoneSearch);
      case 'date':
        const dateSearch = { from: fromDate.trim(), to: toDate.trim() };
        return searchByRetailerDetails(page, dateSearch);
      default:
        return null;
    }
  };

  const updateRetailerDetails = async (id, params) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.put(`${XWF_RETAILERS_URL}/${id}`, params, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data[0] === 1) {
        toastr.success('Update Successful!');
        setTimeout(() => window.location.reload(), 1000);
      } else toastr.warn('Unable To Update');
    } catch (e) {
      toastr.info('Error Updating Retailer...');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const updateRetailerComment = async (id, comment_id, params) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.put(
        `${XWF_RETAILER_COMMENT_URL}/${id}/comments/${comment_id}`,
        qs.stringify(params),
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );
      if (response.data[0] === 1) {
        toastr.success('Successfully Updated!');
        setTimeout(() => window.location.reload(), 1000);
      } else toastr.warn('Unable To Update Comment');
    } catch (e) {
      toastr.info('Error Updating Retailer Comment');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const uploadRetailerDetails = async (uploadedFile) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(`${XWF_RETAILERS_URL}/upload`, uploadedFile, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        toastr.success('Uploaded Successfully!');
        setTimeout(() => window.location.reload(), 1000);
      } else toastr.warn('Unable To Upload');
    } catch (e) {
      toastr.info('Error Uploading Retailer Details...');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    if (searchRetailersToggle) return searchRetailers(selectedPage);
    return getXWFRetailers(selectedPage);
  };

  return {
    retailers,
    pages,
    isLoading,
    getXWFRetailers,
    searchByRetailerDetails,
    searchRetailers,
    handlePageClick,
    details,
    onOptionChange,
    updateRetailerDetails,
    addXWFRetailerComment,
    updateRetailerComment,
    getXWFRetailerComment,
    uploadRetailerDetails,
    retailerComments,
    isFetching,
  };
};

export default useXWFRetailers;
