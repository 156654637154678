const Helpers = {
  encodeMessage(param) {
    return btoa(param);
  },
  emailSubjectToBeSent(param) {
    switch (param) {
      case 'UNREACHABLE CUSTOMERS':
        return 'GREETINGS FROM TIZETI NETWORK';
      case 'Unlimited Internet for your Home':
        return 'Unlimited Internet for your Home';
      default:
        return 'Hello From Tizeti';
    }
  },
  unFormattedEmail(param) {
    switch (param) {
      case 'Unlimited Internet for your Home':
        return (
          'Helloooo, \n' +
          '\n' +
          'I trust this mail meets you well. \n' +
          '\n' +
          'Thanks for your interest in our service. We have excellent coverage at your location\n' +
          '\n' +
          'We offer unlimited internet with no data cap, no hidden charge, and fair usage policies.\n' +
          '\n' +
          "It's a fixed wireless internet service that involves the installation of an outdoor device (radio) and an indoor device (router).\n" +
          '\n' +
          'We provide (2-5) Mbps on a shared network.\n' +
          '\n' +
          'The installation cost for the home unlimited plan is as stated below :\n' +
          '\n' +
          'Set up - 37,000\n' +
          '\n' +
          'Monthly subscription - 12,500 \n' +
          '\n' +
          'Total: 49,500\n' +
          '\n' +
          'However, additional charges may apply for the setup depending on the nature of the building.\n' +
          '\n' +
          'HOW TO MAKE PAYMENT\n' +
          'Kindly visit our online payment portal www.pay.tizeti.com to make payment. \n' +
          'Alternatively,\n' +
          '\n' +
          'you can pay into our bank account below:\n' +
          '\n' +
          'Account Name: Tizeti Network Limited\n' +
          '\n' +
          'Account No:1013184381\n' +
          '\n' +
          'Bank. Zenith\n' +
          '\n' +
          'Regards,'
        );
      default:
        return '';
    }
  },
  emailToBeSent(param) {
    switch (param) {
      case 'UNREACHABLE CUSTOMERS':
        return (
          `<p> Dear Esteemed Customer,</p>
          <p>At Tizeti we pride ourselves in providing the best in class service. We may have fallen short of this due to a number of issues experienced on our network. </p> 
          <p>We appreciate your loyalty to our brand and would look toward getting you back on our network. We recently optimized our network infrastructure to guarantee an improved service experience and are pleased to engage again in bringing you back. </p>
           <p> We apologize for any inconvenience we may have caused you in the past.  Kindly respond to this email and a representative will reach out to you ensuring that you are warmly welcomed back.</p>
           <p>We look forward to reading from you</p> 
          <p>For requests, complaints, or inquiries, kindly contact us via any of our official communication channels as per below </p> 
           <ul>
                <li>Email: internet@wifi.com.ng</li> <br/>
              <li> 012291000, 09087493040</li> <br/>
               <li>Twitter handle: @wifisupport1</li> <br/>
            </ul> 
              <p>Thank you for choosing Tizeti Network Limited ( wifi.com.ng)</p>
        <p>If you do not want to continue with the service, we are also looking forward to reading from you and a representative will reach out to discuss a convenient date and time for a technical visit for device retrieval. Find our policy, https://wifi.com.ng/terms/</p>`
        );
      case 'Unlimited Internet for your Home':
        return "<p>Hello, <br><br>I trust this mail meets you well. <br><br>Thanks for your interest in our service. We have excellent coverage at <br>your location<br><br>We offer unlimited internet with no data cap, no hidden charge, and fair<br> usage policies.<br><br>It's a fixed wireless internet service that involves the installation of<br> an outdoor device (radio) and an indoor device (router).<br><br>We provide (2-5) Mbps on a shared network.<br><br>The installation cost for the home unlimited plan is as stated below :<br><br>Set up - 37,000<br><br>Monthly subscription - 12,500 <br><br>Total: 49,500<br><br>However, additional charges may apply for the setup depending on the <br>nature of the building.<br><br>HOW TO MAKE PAYMENT<br>Kindly visit our online payment portal <a href='http://www.pay.tizeti.com' target='blank'>www.pay.tizeti.com</a> to make payment. <br>Alternatively,<br><br>you can pay into our bank account below:<br><br>Account Name: Tizeti Network Limited<br><br>Account No:1013184381<br><br>Bank. Zenith<br><br>Regards,</p>";
      case 'NEW INSTALLS':
        return '<p>Hello, <br><br>Dear Customer, We are unable to reach you on your number to get a feedback on your experience since joining Tizeti Network.Please send us your response via internet@wifi.com.ng. TIZETI</p>';
      case 'ACTIVE CUSTOMERS':
        return '<p>Dear Customer, We are unable to reach you on your number to get a feedback on your experience with our internet service. Please send us your response via internet@wifi.com.ng. TIZETI</p>';
      case 'EXPIRED CUSTOMERS':
        return '<p>Dear Customer, We are unable to reach you on your number to get a feedback on your experience with our internet service while your link was active Please send us your feedback via internet@wifi.com.ng TIZETI</p>';

      default:
        return 'Hello From Tizeti';
    }
  },
  displayMessage(param) {
    switch (param) {
      case 'UNREACHABLE CUSTOMERS':
        return 'Dear Esteemed Customer, At Tizeti we pride ourselves in providing the best in class service. We may have fallen short of this due to a number of issues experienced on our network. We appreciate your loyalty to our brand and would look toward getting you back on our network. We recently optimized our network infrastructure to guarantee an improved service experience and are pleased to engage again in bringing you back. We apologize for any inconvenience we may have caused you in the past. Kindly respond to this email and a representative will reach out to you ensuring that you are warmly welcomed back. We look forward to reading from you. For requests, complaints or enquiries, kindly contact us via any of our official communication channels as per below – Email: internet@wifi.com.ng, 012291000, 09087493040. Twitter handle: @wifisupport1 Thank you for choosing Tizeti Network Limited ( wifi.com.ng) If you do not want to continue with the service, we are also looking forward to reading from you and a representative will reach out to discuss a convenient date and time for a technical visit for device retrieval. Find our policy, https://wifi.com.ng/terms/';
      case 'Unlimited Internet for your Home':
        return 'Hello, I trust this mail meets you well. Thanks for your interest in our service. We have excellent coverage at your location. We offer unlimited internet with no data cap, no hidden charge, and fair usage policies. It is a fixed wireless internet service that involves the installation of an outdoor device (radio) and an indoor device (router). We provide (2-5) Mbps on a shared network. The installation cost for the home unlimited plan is as stated below: Set up - 37,000, Monthly subscription - 12,500. Total: 49,500. However, additional charges may apply for the setup depending on the nature of the building. HOW TO MAKE PAYMENT. Kindly visit our online payment portal    www.pay.tizeti.com  to make payment. Alternatively, you can pay into our bank account below: Account Name: Tizeti Network Limited. Account No:1013184381 Bank. Zenith. Regards,';
      case 'EXPIRED CUSTOMERS':
        return 'Dear Customer, We are unable to reach you on your number to get a feedback on your experience with our internet service while your link was active Please send us your feedback via internet@wifi.com.ng TIZETI';
      case 'NEW INSTALLS':
        return 'Hello, Dear Customer, We are unable to reach you on your number to get a feedback on your experience since joining Tizeti Network.Please send us your response via internet@wifi.com.ng. TIZETI';
      case 'ACTIVE CUSTOMERS':
        return 'Dear Customer, We are unable to reach you on your number to get a feedback on your experience with our internet service. Please send us your response via internet@wifi.com.ng. TIZETI';

      default:
        return '';
    }
  },
};
export default Helpers;
