import axios from 'axios';
import { toast } from 'react-toastify';
import toastr from 'toastr';
import {
  GET_UNVERIFIED_CUSTOMER_URL,
  SEARCH_UNVERIFIED_WIFICALL_CUSTOMERS_WITH_DATE_URL,
  SEARCH_WIFICALL_CUSTOMERS_WITH_EMAIL_URL,
  SEARCH_WIFICALL_CUSTOMERS_WITH_PHONE_URL,
} from '../../../services/api/wificall/CustomersUrl';
import { GET_UNVERIFIED_WIFICALL_USERS } from '../../../constants/types/wificall/WificallTypes';
import { API_URL } from '../../../utils/config';
import { VERIFY_WIFICALL_USER_BY_USER_ID } from '../../../services/api/wificall/UsersUrl';
import { FETCHING_STARTS } from '../../../constants/types/shared/LoadingTypes';

export const getUnVerifiedCustomers = () => async (dispatch) => {
  dispatch({
    type: FETCHING_STARTS,
    payload: true,
  });

  try {
    const response = await axios.get(`${GET_UNVERIFIED_CUSTOMER_URL}/1`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      dispatch({
        type: GET_UNVERIFIED_WIFICALL_USERS,
        payload: response.data,
      });
    }
  } catch (e) {
    toastr.info('Something went wrong!');
  }
};

export const searchUnverifiedWithEmail = (data) => async (dispatch) => {
  dispatch({
    type: FETCHING_STARTS,
    payload: true,
  });

  try {
    const response = await axios.post(`${SEARCH_WIFICALL_CUSTOMERS_WITH_EMAIL_URL}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      dispatch({
        type: GET_UNVERIFIED_WIFICALL_USERS,
        payload: {
          users: response.data,
          pages: 0,
        },
      });
    }

    if (response.data.length < 1) {
      toastr.info('No user with that email exists');
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const searchUnverifiedWithPhone = (data) => async (dispatch) => {
  dispatch({
    type: FETCHING_STARTS,
    payload: true,
  });
  try {
    const response = await axios.post(`${SEARCH_WIFICALL_CUSTOMERS_WITH_PHONE_URL}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      dispatch({
        type: GET_UNVERIFIED_WIFICALL_USERS,
        payload: {
          users: response.data,
          pages: 0,
        },
      });
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const getPaginatedUnverifiedUsers = (data) => async (dispatch) => {
  dispatch({
    type: FETCHING_STARTS,
    payload: true,
  });

  try {
    const response = await axios.get(`${GET_UNVERIFIED_CUSTOMER_URL}/${data}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      dispatch({
        type: GET_UNVERIFIED_WIFICALL_USERS,
        payload: response.data,
      });
    }
  } catch (e) {
    toastr.info('Something went wrong!');
  }

  // axios.get(`${API_URL}/wificall/users/${selected}`, {
  //
  //   headers: {
  //
  //     'Authorization': `Bearer ${sessionStorage.getItem('token')}`
  //
  //   }
  //
  // }).then(response => {
  //
  //   this.setState({
  //
  //     users: response.data.users,
  //     loading: false,
  //     pageCount: response.data.pages
  //
  //   })
  //
  // })
};

export const getUnVerifiedCustomersWithDate = (data) => async (dispatch) => {
  dispatch({
    type: FETCHING_STARTS,
    payload: true,
  });

  try {
    const response = await axios.post(`${SEARCH_UNVERIFIED_WIFICALL_CUSTOMERS_WITH_DATE_URL}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      dispatch({
        type: GET_UNVERIFIED_WIFICALL_USERS,
        payload: response.data,
      });
    }
  } catch (e) {
    toastr.info('Something went wrong!');
  }
};

export const activateCustomer = (data) => async () => {
  try {
    const response = await axios.get(`${API_URL}/${VERIFY_WIFICALL_USER_BY_USER_ID}/${data.id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (typeof response.data === 'object' && response.data) {
      toastr.success(`${data.firstName} has been activated`);
      toast.success('User has been activated');
      setTimeout(() => {
        window.location.reload();
      }, 2000);

      // dispatch({
      //
      //   type: ACTIVATE_WIFICALL_USER,
      //   payload: {
      //     status: "activated",
      //     activated: data.id
      //   }
      //
      // })
    }
  } catch (e) {
    toast.info('Something went wrong!');
  }
};
