import React from 'react';
import useFacebookSite from '../hooks/useFacebookSite';
import FacebookSiteContext from './FacebookSiteContext';

const FacebookSiteProvider = ({ children }) => {
  const { data, loading, paginateChange, loadingForm, onChange, formValue, onSubmit, pages } = useFacebookSite();
  return (
    <FacebookSiteContext.Provider
      value={{
        data,
        loading,
        paginateChange,
        loadingForm,
        onChange,
        formValue,
        onSubmit,
        pages,
      }}
    >
      {children}
    </FacebookSiteContext.Provider>
  );
};
export default FacebookSiteProvider;
