import React, { createContext } from 'react';
import useInstallationOverview from '../../../../hooks/field-support/installation/leads/overview/useInstallationOverview';

export const OverviewContext = createContext({});

export const OverviewProvider = ({ children }) => {
  const {
    pages,
    loading,
    onSearchChange,
    search,
    searchInstallations,
    searchRescheduledInstallations,
    installations,
    completedInstallations,
    searchForUser,
    searchUser,
    handleSearchUser,
    routing,
    clustering,
    clusterInstallation,
    routedInstalls,
    clusteredInstalls,
    postRoutedInstalls,
    LagosInstalls,
    RiversInstalls,
    OgunInstalls,
    EdoInstalls,
    assign,
    setAssign,
    assignForm,
    setAssignForm,
    onAssignChange,
    assigning,
    oneInstallation,
    setOneInstallation,
    getOneInstallation,
    handlePageClick,
    editInstallation,
    onShowDeleteInstallationModal,
    showDeleteInstallationModal,
    deleteInstallation,
    deletingInstallation,
    getAssignedStaff,
    assignedStaff,
    assignedStaffCount,
    assignedStaffRows,
  } = useInstallationOverview();
  return (
    <OverviewContext.Provider
      value={{
        pages,
        loading,
        onSearchChange,
        search,
        searchInstallations,
        searchRescheduledInstallations,
        installations,
        completedInstallations,
        searchForUser,
        searchUser,
        handleSearchUser,
        routing,
        clustering,
        clusterInstallation,
        routedInstalls,
        clusteredInstalls,
        postRoutedInstalls,
        LagosInstalls,
        RiversInstalls,
        OgunInstalls,
        EdoInstalls,
        assign,
        setAssign,
        assignForm,
        setAssignForm,
        onAssignChange,
        assigning,
        oneInstallation,
        setOneInstallation,
        getOneInstallation,
        handlePageClick,
        editInstallation,
        onShowDeleteInstallationModal,
        showDeleteInstallationModal,
        deleteInstallation,
        deletingInstallation,
        getAssignedStaff,
        assignedStaff,
        assignedStaffCount,
        assignedStaffRows,
      }}
    >
      {children}
    </OverviewContext.Provider>
  );
};
