import React from 'react';
import { Link } from 'react-router-dom';

const AdminDeptLogo = () => {
  return (
    <Link className="navbar-brand" to={'/administration'}>
      <img
        src="https://res.cloudinary.com/tizeti/image/upload/v1542971047/Tizeti-Logo.png"
        alt="homepage"
        style={{ width: '10rem', height: 'auto' }}
      />
    </Link>
  );
};

export default AdminDeptLogo;
