import { GET_USERS_BY_PARAMS } from '../../../constants/types/shared/users/UsersTypes';

const initialState = {
  users: [],
};

export const UsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS_BY_PARAMS:
      console.log(action.type);
      return {
        ...state,
        users: action.payload,
      };

    default:
      return state;
  }
};

export default UsersReducer;
