/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import '../../../../../../styles/basestation.css';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import mail from '../../../../../../assets/img/icons/mailblast.svg';
import CustomerServiceScript from '../../../../shared/CustomerServiceScript';
import RetentionScript from '../../../../shared/customer-support/RetentionScript';
import CustomerSupportSideBar from '../../../../../layouts/customer-support/CustomerSupportSideBar';
import Logout from '../../../../shared/auth/Logout';
import BasestationDataBody from '../../../../shared/basestation-data/BasestationDataBody';
import { useUser } from '../../../../../../hooks/shared/user/useUser';

const CustomerSupportBaseStationData = () => {
  const { profile } = useUser();
  const { firstName } = profile;
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <div className="d-flex col-lg-12 p-2 top-header">
          <div className="mt-3 d-flex col-lg-12 p-0">
            <Link className="s-sidebar__trigger" to="#">
              <i className="fa fa-bars mr-3" />
            </Link>
            <CustomerSupportSideBar />

            <h3 className="col-lg-5 overview-part">BaseStation Users</h3>

            <div className="ml-5 d-flex profile-mail pr-5">
              <Link
                to="/customer-support/mail-blast"
                className="d-none d-md-flex"
                style={{ color: 'gray', textDecoration: 'none' }}
              >
                <img src={mail} alt="" className="logout mr-2" />
                <span>
                  <h6 className="m-2">
                    <strong>MailBlast</strong>
                  </h6>
                </span>
              </Link>

              <h6 className="mr-0 d-flex">
                <i className="small mr-2 d-none d-md-block">{firstName}</i>
                <Logout />
              </h6>
            </div>
          </div>
        </div>

        <div className="after-summary">
          <div className="container m-0">
            <BasestationDataBody />

            <span className="d-none d-md-block">
              <RetentionScript />
              <CustomerServiceScript />
            </span>
          </div>
        </div>
      </main>
    </div>
  );
};

CustomerSupportBaseStationData.propTypes = {
  history: PropTypes.shape(),
};
export default CustomerSupportBaseStationData;
