/* eslint-disable @typescript-eslint/camelcase */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import RequisitionContext from '../../../../../context/shared/requisitions/RequsitionContext';

const RejectRequisition = ({ requisit }) => {
  const value = useContext(RequisitionContext);
  const {
    giveManagerDisApproval,
    onChangeDisapprove,
    giveExecutiveDisApproval,
    disapprove,
    isApprovingOrDisapproving,
  } = value;

  // eslint-disable-next-line consistent-return
  const submitRejection = () => {
    const { id } = requisit;
    if (Number(`${sessionStorage.getItem('roleId')}`) === 3 || +`${sessionStorage.getItem('roleId')}` === 11) {
      // dispatch(getManagersRequisition());
      const data = {
        manager_approval: 'rejected',
        comment: disapprove,
      };
      giveManagerDisApproval(data, id);
    } else if (Number(sessionStorage.getItem('roleId')) >= 7) {
      // dispatch(getExecutiveRequisition());
      const data = {
        status: 'no',
        comment: disapprove,
      };
      giveExecutiveDisApproval(data, id);
    } else return null;
  };

  const submitForm = () => {
    return disapprove.length > 0;
  };
  return (
    <div
      id="reject"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Reject Requisition
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body p-0">
            <div className="m-3">
              <textarea
                className="form-control"
                placeholder="reason for disapproval"
                rows="4"
                cols="50"
                name="disapprove"
                onChange={onChangeDisapprove}
                value={disapprove}
              />
              {/* {disapprove}
              </textarea> */}
            </div>
            <div className="text-center my-2">
              {/* eslint-disable-next-line react/button-has-type */}
              {isApprovingOrDisapproving ? (
                <button
                  type="submit"
                  className="btn btn-info"
                  onClick={submitRejection}
                  disabled={isApprovingOrDisapproving}
                >
                  Submitting...
                </button>
              ) : (
                <button type="submit" className="btn btn-info" onClick={submitRejection} disabled={!submitForm()}>
                  Submit
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

RejectRequisition.propTypes = {
  // eslint-disable-next-line react/require-default-props
  requisit: PropTypes.shape(),
};
export default RejectRequisition;
