import axios from 'axios';
import { toast } from 'react-toastify';
import { FORGOT_PASSWORD_URL, RESET_PASSWORD_URL } from '../../services/api/auth/AuthUrl';
import {
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  START_FORGOT_PASSWORD,
  START_RESET_PASSWORD,
  STOP_FORGOT_PASSWORD,
  STOP_RESET_PASSWORD,
} from '../../constants/types/AuthTypes';

export const forgotPassword = (data, history) => {
  return async (dispatch) => {
    dispatch({
      type: START_FORGOT_PASSWORD,
      payload: true,
    });

    try {
      const response = await axios.post(`${FORGOT_PASSWORD_URL}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.message === 'Password check your email for your password reset link') {
        dispatch({
          type: FORGOT_PASSWORD,
          payload: false,
        });

        toast.success('Password changed successfully. Please log in');

        setTimeout(() => {
          history.push('/login');
        }, 1000);
      } else if (response.data.message === 'user not found') {
        toast.info(response.data.message);
        dispatch({
          type: STOP_FORGOT_PASSWORD,
          payload: false,
        });
      } else {
        toast.info('Unable to send reset password link');
        dispatch({
          type: STOP_FORGOT_PASSWORD,
          payload: false,
        });
      }
    } catch (e) {
      dispatch({
        type: STOP_FORGOT_PASSWORD,
        payload: false,
      });
    }
  };
};

export const resetPassword = (data, history) => {
  return async (dispatch) => {
    dispatch({
      type: START_RESET_PASSWORD,
      payload: true,
    });

    try {
      const response = await axios.post(`${RESET_PASSWORD_URL}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.message === 'Password reset successful, please login') {
        toast.success(response.data.message);
        dispatch({
          type: STOP_RESET_PASSWORD,
          payload: false,
        });

        setTimeout(() => {
          history.push('/login');
        }, 2000);
      } else {
        toast.info('Unable to reset password');
        dispatch({
          type: STOP_RESET_PASSWORD,
          payload: false,
        });
      }
    } catch (e) {
      if (e) {
        toast.info('Unable to reset password');
        dispatch({
          type: STOP_RESET_PASSWORD,
          payload: false,
        });
      }
    }
  };
};
