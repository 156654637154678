/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useContext, useEffect, useState } from 'react';
import toastr from 'toastr';
import AppContext from '../../../../context/shared/AppContext';
import { useUserProfile } from '../../../../hooks/shared/user/useUserProfile';
import helpers from '../../../../utils/helpers';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../utils/loader/loadingHelper';
import MoveToBucket from '../MoveToBucket';
import CustomButton from '../forms/CustomButton';
import UninstallDevice from './UninstallDevice';
import { useUser } from '../../../../hooks/shared/user/useUser';
import { ASSET_TEAM, customer_support } from '../../../../utils/config';
import DeviceConfirmation from '../../departments/field-support/device-retrieval/DeviceConfirmation';
import EditIcon from '../../../../assets/img/icons/edit.png';
import erase from '../../../../assets/img/icons/delete.png';
import MoveToProcurement from './MoveToProcurement';
import ConfirmDeleteRetrieval from './ConfirmDeleteRetrieval';
import BulkUninstall from './BulkUninstall';

const EscalatedBucketsTable = () => {
  const {
    data,
    loading,
    getDeviceRetrieval,
    openUninstall,
    onOpenUninstall,
    bulkUninstallDevice,
    openDeviceConfirmation,
    onOpenDeviceConfirmation,
    onOpenMoveToProcurement,
  } = useContext(AppContext);

  const { profile, getUserProfile } = useUserProfile();
  const { user } = useUser();

  const currentPage = 1;
  const [one, setOne] = useState({});
  const [location, setLocation] = useState('');
  const [allItems, setAllItems] = useState([]);

  useEffect(() => {
    getUserProfile();
    getDeviceRetrieval(currentPage);
  }, []);

  useEffect(() => {
    if (data && data.length) {
      setAllItems(
        data.map((item) => {
          const {
            id,
            name,
            mac,
            email,
            phone,
            customer_number,
            status,
            address,
            expiration_date,
            details,
            agent,
            created_at,
            updated_at,
          } = item;
          return {
            select: false,
            id,
            name,
            mac,
            email,
            phone,
            customer_number,
            status,
            address,
            expiration_date,
            details,
            agent,
            created_at,
            updated_at,
          };
        }),
      );
    } else setAllItems([]);
  }, [data]);

  const checkedItems = allItems.filter((item) => {
    if (item.select === true) return true;
    return null;
  });

  const handleBulkUninstall = () => {
    const params = JSON.stringify(
      checkedItems.map((checkedItem) => {
        return {
          id: checkedItem.id,
          mac: checkedItem.mac,
          name: checkedItem.name,
          address: checkedItem.address,
          email: checkedItem.email,
          phoneNumber: checkedItem.phone,
        };
      }),
    );
    bulkUninstallDevice(params);
  };

  const getItem = (params) => {
    setOne(params);
    setLocation(params.address);
  };
  const showProcurementAction = (dat) => {
    if (user.department_id === `${customer_support}` || user.department_id === `${ASSET_TEAM}`) {
      return null;
    }
    return (
      <img
        onClick={() => {
          getItem(dat);
          onOpenMoveToProcurement();
        }}
        src={EditIcon}
        alt="move to procurement"
      />
    );
  };
  const showIfUserLeadOrHigher = (dat) => {
    if (Number(sessionStorage.getItem('roleId')) > 2) {
      return (
        <div className="d-flex">
          <CustomButton
            classname="btn btn-green mr-2"
            disabled={false}
            onClick={() => {
              getItem(dat);
              onOpenUninstall();
            }}
            name="Uninstall"
          />
          <button
            type="button"
            className="mr-2 noBorder"
            data-target="#confirm_delete"
            onClick={() => getItem(dat)}
            data-toggle="modal"
          >
            <span className="view">
              <img src={erase} alt="" data-toggle="tooltip" data-placement="top" title="Delete" />
            </span>
          </button>
        </div>
      );
    }
    return null;
  };
  const showActions = (dat) => {
    if (user.department_id === `${customer_support}` || user.department_id === `${ASSET_TEAM}`) {
      return (
        <div className="d-flex justify-content-around">
          <div className="">
            <button
              type="button"
              data-toggle="modal"
              data-placement="right"
              data-target="#moveToBucket"
              onClick={() => getItem(dat)}
              className="mx-2 mt-2 noBorder"
            >
              <span className="view">
                <i
                  className="fa fa-arrow-circle-right"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Move To Bucket"
                />
              </span>
            </button>
          </div>
          {showIfUserLeadOrHigher(dat)}
        </div>
      );
    }
    return (
      <div className="text-center">
        <CustomButton
          classname="btn btn-green"
          disabled={false}
          onClick={() => {
            getItem(dat);
            onOpenDeviceConfirmation();
          }}
          name="Device Confirmation"
        />
      </div>
    );
  };
  const selectAll = () => {
    return (
      <th className="text-nowrap">
        <input
          type="checkbox"
          className="mr-1"
          onChange={(e) => {
            const { checked } = e.target;
            setAllItems(
              allItems.map((item) => {
                if (item.status.toLowerCase() === 'uninstall') item.select = checked;
                return item;
              }),
            );
          }}
        />
        Select All
      </th>
    );
  };

  const selectOne = (selectedItem) => {
    return (
      <td className="text-center">
        <input
          type="checkbox"
          checked={selectedItem.select}
          onChange={(e) => {
            const { checked } = e.target;
            setAllItems(
              allItems.map((item) => {
                if (selectedItem.id === item.id) {
                  if (selectedItem.status.toLowerCase() === 'uninstall') {
                    item.select = checked;
                  } else toastr.error('Check Retrieval Status');
                }
                return item;
              }),
            );
          }}
        />
      </td>
    );
  };
  const tableData =
    allItems && allItems.length ? (
      allItems.map((dat, i) => (
        <tr className="">
          <td>{i + 1}</td>
          {Number(sessionStorage.getItem('roleId')) > 2 && selectOne(dat)}
          <td>{dat.name}</td>
          <td>{dat.mac}</td>
          <td className="text-wrap">{dat.email}</td>
          <td>{dat.phone}</td>
          <td className="text-nowrap">{dat.customer_number || 'No Number'}</td>
          <td>{dat.status}</td>
          <td>{dat.address}</td>
          <td className="text-nowrap">{dat.expiration_date}</td>
          <td className="text-wrap">{dat.details || '--'}</td>
          <td className="text-nowrap">{dat.agent}</td>
          <td className="text-nowrap">{helpers.formatDateAndTime(dat.created_at)}</td>
          <td className="text-nowrap">{helpers.formatDateAndTime(dat.updated_at)}</td>
          <td>
            {showActions(dat)}
            <MoveToBucket oneItem={one} address={location} profile={profile} operation="update" />
          </td>
          <td>{showProcurementAction(dat)}</td>
        </tr>
      ))
    ) : (
      <NoItemRow colSpan="13" text="No Customer" />
    );

  return (
    <div className="card x-panel mt-2 px-0">
      {checkedItems.length >= 2 && (
        <button type="button" className="btn btn-danger" data-target="#bulk_uninstall" data-toggle="modal">
          Bulk Uninstall
        </button>
      )}
      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <thead>
            <tr className="">
              <th>#</th>
              {Number(sessionStorage.getItem('roleId')) > 2 && selectAll()}
              <th>Name</th>
              <th>MAC ID</th>
              <th className="text-wrap">Email</th>
              <th>Phone</th>
              <th className="text-nowrap">Customer Number</th>
              <th>Status</th>
              <th>Address</th>
              <th className="text-nowrap">Expiry Date</th>
              <th className="text-wrap">Details</th>
              <th className="text-nowrap">Agent</th>
              <th className="text-nowrap">Created At</th>
              <th className="text-nowrap">Updated At</th>
              <th>Actions</th>
              {user.department_id === `${customer_support}` || user.department_id === `${ASSET_TEAM} ` ? null : (
                <th>Move To Procurement</th>
              )}
            </tr>
          </thead>
          <tbody>{loading ? <TizetiGIFLogoTable colSpan="14" /> : tableData}</tbody>
        </table>
      </div>
      <UninstallDevice show={openUninstall} onClose={onOpenUninstall} customer={one} />
      <DeviceConfirmation show={openDeviceConfirmation} onClose={onOpenDeviceConfirmation} customer={one} />
      <MoveToProcurement one={one} />
      <ConfirmDeleteRetrieval one={one} />
      <BulkUninstall handleBulkUninstall={handleBulkUninstall} />
    </div>
  );
};

export default EscalatedBucketsTable;
