/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useUser } from '../../../../hooks/shared/user/useUser';
import { deviceRetrievalStatus } from '../../../../utils/asset-team/helper';
import useDeviceRetrieval from './hooks/useDeviceRetrieval';

const CreateDeviceRetrieval = () => {
  const {
    profile: { firstName, lastName },
  } = useUser();
  const { addDeviceRetrieval } = useDeviceRetrieval();
  const detailsState = {
    customerName: '',
    phone: '',
    address: '',
    mac: '',
    email: '',
    agent: '',
    status: 'uninstall',
    comment: '',
    expirationDate: '',
  };
  const [details, setDetails] = useState(detailsState);
  const { customerName, phone, address, mac, email, expirationDate, comment, status } = details;

  const handleSpaceBar = (e) => {
    if (e.keyCode === 32) e.preventDefault();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phone') {
      if (isNaN(Number(value))) return;
      setDetails({ ...details, [name]: value });
    } else setDetails({ ...details, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      name: customerName,
      phone,
      address,
      mac,
      email,
      agent: `${firstName} ${lastName}`,
      expiration_date: expirationDate,
      status,
      comment,
    };
    addDeviceRetrieval(data);
  };

  const submitForm = () => {
    return (
      customerName.length > 0 &&
      phone.length >= 10 &&
      address.length > 0 &&
      mac.length > 0 &&
      email.length > 0 &&
      status.length > 0 &&
      expirationDate.length > 0
    );
  };

  return (
    <div>
      <div className="">
        <button type="button" className="btn btn-green" data-toggle="modal" data-target="#create_device">
          Add
        </button>
      </div>
      <div id="create_device" className="modal fade" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header shadow">
              <h4 className="modal-title update_asset">Add Customer</h4>
              <button type="button" className="btn btn-outline-info btn-circle btn-close" data-dismiss="modal">
                x
              </button>
            </div>
            <div className="modal-body detail mb-4 p-0">
              <form onSubmit={onSubmit} className="col-md-12">
                <div className="d-flex">
                  <div className="pr-3 form-group col-md-6">
                    <label className="m-0 p-0 copy-font" htmlFor="customerName">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control border border-info py-2"
                      id="customerName"
                      name="customerName"
                      value={customerName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="pl-3 form-group col-md-6">
                    <label className="m-0 p-0 copy-font" htmlFor="mac">
                      MAC ID
                    </label>
                    <input
                      type="text"
                      className="form-control border border-info py-2"
                      id="mac"
                      name="mac"
                      value={mac}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="d-flex">
                  <div className="pr-3 form-group col-md-6">
                    <label className="m-0 p-0 copy-font" htmlFor="phone">
                      Phone
                    </label>
                    <input
                      type="tel"
                      className="form-control border border-info py-2"
                      id="phone"
                      name="phone"
                      value={phone}
                      maxLength="11"
                      minLength="10"
                      onKeyDown={handleSpaceBar}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="pl-3 form-group col-md-6">
                    <label className="m-0 p-0 copy-font" htmlFor="email">
                      Email
                    </label>
                    <input
                      type="text"
                      className="form-control border border-info py-2"
                      id="email"
                      name="email"
                      value={email}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="d-flex">
                  <div className="pr-3 form-group col-md-6">
                    <label className="m-0 p-0 copy-font" htmlFor="expirationDate">
                      Expiration Date
                    </label>
                    <div className="input-group">
                      <input
                        type="date"
                        className="form-control border border-info py-2"
                        id="expirationDate"
                        value={expirationDate}
                        name="expirationDate"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="pl-3 form-group col-md-6">
                    <label className="m-0 p-0 copy-font" htmlFor="status">
                      Status
                    </label>
                    <div className="input-group">
                      <select
                        id="status"
                        name="status"
                        defaultValue={status}
                        onChange={handleChange}
                        className="form-control border border-info py-2"
                      >
                        <option value="">--Select Status--</option>
                        {deviceRetrievalStatus.map((deviceStatus) => (
                          <option key={deviceStatus.value} value={deviceStatus.value}>
                            {deviceStatus.name.toUpperCase()}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="">
                  <div className="form-group col-md-12">
                    <label className="m-0 p-0 copy-font">Address</label>
                    <textarea
                      className="form-control border border-info"
                      rows="3"
                      cols="80"
                      name="address"
                      value={address}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="">
                  <div className="form-group col-md-12">
                    <label className="m-0 p-0 copy-font">Comment</label>
                    <textarea
                      className="form-control border border-info"
                      rows="3"
                      cols="80"
                      name="comment"
                      value={comment}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="text-center mt-3">
                  <button type="submit" className="btn btn-green py-2 px-5" disabled={!submitForm()}>
                    Add
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateDeviceRetrieval;
