import React from 'react';
import AssetTeamNavBar from '../../../../layouts/asset-team/AssetTeamNavBar';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import ExpiredCustomersPerCountryBody from '../../../shared/customers/ExpiredCustomersPerCountryBody';

const AssetTeamExpiredCountry = () => {
  const { profile } = useUser();
  const { firstName } = profile;

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <AssetTeamNavBar currentPage="Expired Customers Per Country" position={firstName} />
        <ExpiredCustomersPerCountryBody profile={profile} />
      </main>
    </div>
  );
};

export default AssetTeamExpiredCountry;
