import React from 'react';
import worldicon from '../../../../../assets/img/icons/world_icon.fw.png';
import useExpiredCustomers from '../../../../../hooks/shared/customers/useExpiredCustomers';

const TotalExpired90days = () => {
  const {
    state: { totalExpired90 },
  } = useExpiredCustomers();

  return (
    <div className="col-lg col-md col-sm">
      <div className="boxes">
        <div className="text-center">
          <div className="text-center mt-3">
            <img src={worldicon} alt="" />
          </div>
          <div className="count">{totalExpired90}</div>
          <h3 className="color-four">Total Expired in 90 days</h3>
        </div>
      </div>
    </div>
  );
};

export default TotalExpired90days;
