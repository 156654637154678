/* eslint-disable @typescript-eslint/camelcase */
import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import CustomInput from '../../../shared/forms/CustomInput';
import Label from '../../../shared/forms/Label';
import AppContext from '../../../../../context/shared/AppContext';
import { CleanMacProps } from './CleanRadioTable';
import CustomButton from '../../../shared/forms/CustomButton';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import { showDepartmentName } from '../../../../../utils/departments/helpers';

export default function CleanRadioModal({ details }) {
  const { onOpenModal, showCleanModal, cleanRadio, cleaning } = useContext(AppContext);
  const { profile, user } = useUser();
  const submit = (e) => {
    e.preventDefault();
    cleanRadio({
      action: 'cleanCustomerDevice',
      staffEmailAddress: profile ? profile.email : 'user has no profile',
      department: showDepartmentName.get(`${user.department_id}`),
      role_id: `${sessionStorage.getItem('roleId')}`,
      macaddress: profile.email,
      delisted: 'yes',
    });
  };
  return (
    <Modal show={showCleanModal} onHide={onOpenModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Clean Mac Id</Modal.Title>
      </Modal.Header>
      <div className="modal-body detail mb-4 p-0">
        <form onSubmit={submit} className="col-md-12">
          <div className="d-flex">
            <div className="pr-3 form-group col-md-12">
              <Label classname="m-0 p-0 copy-font" name="Item" />
              <CustomInput
                classname="form-control border border-info py-2"
                name="radio_mac_id"
                value={details.radio_mac_id}
                onChange={null}
                readOnly
                type="text"
              />
            </div>
          </div>

          <div className="text-center mt-3">
            <CustomButton
              classname="btn btn-green py-2 px-5"
              name={cleaning ? 'Cleaning...' : 'Clean'}
              disabled={!!cleaning}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
}
