/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const BandWidth = ({ customer, addBandwith, bandwith, onChnage, loading }) => {
  const [user, setUser] = useState(customer);

  useEffect(() => {
    setUser(customer);
  }, [customer]);

  const onChange = (event) => {
    const { name, value } = event.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const submitForm = () => {
    return bandwith.length > 0;
  };

  return (
    <div
      id="bandwidth-details"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" style={{ width: '600px' }}>
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Edit Bandwidth Details
            </h4>
            <button type="button" className="close" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body">
            <form
              className="form-horizontal"
              onSubmit={(e) => {
                e.preventDefault();
                addBandwith(user, bandwith);
              }}
            >
              <div className="form-group add-log">
                <div className="col-md-12 mb-1 d-flex">
                  <div className="col-lg-6 mb-3">
                    <label className="copy-font">Account Name</label>
                    <input
                      type="text"
                      onChange={onChange}
                      name="name"
                      value={user.name}
                      className="form-control"
                      placeholder="First Name"
                      readOnly
                    />
                  </div>

                  <div className="col-lg-6 mb-3">
                    <label className="copy-font">Email</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={onChange}
                      name="name"
                      value={user.mail}
                      placeholder="Last Name"
                      readOnly
                    />
                  </div>
                </div>

                <div className="col-md-12 mb-3 d-flex">
                  <div className="col-lg-6">
                    <label className="copy-font">Mac Id</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Zion Court"
                      onChange={onChange}
                      name="username"
                      value={user.username}
                      readOnly
                    />
                  </div>

                  <div className="col-lg-6">
                    <label className="copy-font">Phone</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={onChange}
                      name="phone"
                      value={user.address}
                      readOnly
                    />
                  </div>
                </div>

                <div className="col-md-12 mb-3 d-flex">
                  <div className="col-lg-6">
                    <label className="copy-font">BandWidth</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Bandwidth"
                      name="bandwidth"
                      onChange={onChnage}
                      value={bandwith}
                    />
                  </div>
                </div>
              </div>
              <div className="text-center">
                {loading ? (
                  <button type="submit" disabled={loading} className="btn btn-second waves-effect">
                    Updating...
                  </button>
                ) : (
                  <button type="submit" className="btn btn-second waves-effect" disabled={!submitForm()}>
                    Update
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

BandWidth.propTypes = {
  onChnage: PropTypes.func,
  addBandwith: PropTypes.func,
  bandwith: PropTypes.string,
  customer: PropTypes.shape(),
  loading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  loading: state.dedicated.isLoading,
});

export default connect(mapStateToProps, null)(BandWidth);
