/* eslint-disable @typescript-eslint/camelcase */
export const API_URL = 'https://api.tizeti.com/api/v1';
export const API_URL_8443 = 'https://api.tizeti.com:8443/api/v1';
export const HOTSPOT_API_URL = 'https://api.tizeti.com:4000';
export const API_KEY = 'AIzaSyAyesbQMyKVVbBgKVi2g6VX7mop2z96jBo';
export const operations = 'e1f5454d-660d-4a0e-8640-c90950dd97fc';
export const accounts = '3a237e10-fc54-4c0c-bf02-821356840269';
export const admin = 'c3caed52-dfd5-402f-80e4-31861da8554e';
export const customer_support = '874d238d-5019-4890-81a1-09ec83d36306';
export const PAYSTACK_TEST_KEY = 'pk_test_1f0c1875220671c87400060c5d87c2dbbc043a17';
export const STATES_URL = 'https://locationsng-api.herokuapp.com/api/v1/states';
export const COUNTRIES_URL = 'https://restcountries.eu/rest/v2/all';
export const ADMIN_DEPARTMENT = '5cc24f2e-696a-4dec-870c-b6e6aaf499f0';
export const VICE_PRESIDENT_SALES = '19364ad2-acdb-4f29-94f4-ee583cfdf154';
export const NOC = '6e072411-d6eb-413d-be6c-c4b5bc812320';
export const NEW_PRODUCTS = 'a4a0d87a-bc1f-41cb-ab97-fc9d6405b8f1';
export const PROCUREMENT = '558a7002-4933-4d11-8e21-5b68a8b8cc01';
export const PARTNERSHIP = '779983cf-c14a-4eb5-ab35-487e0278abd2';
export const RESEARCH_AND_DEVELOPMENT = '22dfe612-9f8b-4183-a75e-81a33595aa46';
export const BUSINESS_DEVELOPMENT = 'a521da84-8c18-4e22-9a49-c2aee8706e79';
export const EXPRESS_WIFI = 'a6d0ad8a-5c0a-4fe9-8adb-f9f2fe4b87d6';
export const SALES = 'aaf924bf-e16f-4ddc-843b-be5f91c8b0d2';
export const TECHNICAL_SUPPORT = 'fbdac94e-9500-4845-b260-5d6a912bda36';
export const ASSET_TEAM = '5fda6293-d2c0-4302-b071-aece18434c23';
export const FIELD_SUPPORT = 'e1f5454d-660d-4a0e-8640-c90950dd97fc';
export const BASEURL = 'https://api.tizeti.com/api/v1';
export const token = {
  headers: {
    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
  },
};

export const header = {
  headers: {
    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
  },
};

export const months = Array.from({ length: 12 }, (e, i) => {
  return new Date(null, i + 1, null).toLocaleDateString('en', { month: 'long' });
});
