/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import AppContext from '../../../../context/shared/AppContext';
import CustomInput from '../forms/CustomInput';
import Label from '../forms/Label';
// import useTracklist from '../tracklist/hooks/useTracklist';
import helpers from '../../../../utils/helpers';
import { useUser } from '../../../../hooks/shared/user/useUser';
import CustomButton from '../forms/CustomButton';
// import useFetch from '../../../../hooks/shared/custom/data/useFetch';
// import { API_URL } from '../../../../utils/config';

const MoveToProcurement = ({ one }) => {
  const {
    fseRetrievalToProcurement,
    showMoveToProcurementModal,
    onOpenMoveToProcurement,
    handleChange,
    retrieval,
    loadingForm,
  } = useContext(AppContext);
  // const { list } = useTracklist();
  // const { data: lists } = useFetch(`${API_URL}/tracklistcategory`);

  const { item, comment, router_mac_id, wirelessMAC, esnMAC, serialNumber, partNumber } = retrieval;
  const { user } = useUser();

  const onSubmit = (e) => {
    e.preventDefault();
    const { id, mac, name, email, address, phone, customer_number, modified_mac } = one;
    const deviceConfirmationData = {
      mac,
      address,
      customer_name: name,
      customer_email: email,
      phoneNumber: phone,
      comment: one.comment,
    };
    const moveToProcurementData = {
      item,
      router_mac_id,
      customer_name: name,
      customer_email: email,
      retrieval_date: helpers.formattedCurrentDate(),
      comment,
      staff_id: user.id,
      customer_number,
      radio_mac_id: mac,
      retention_retrieval_id: id,
      wireless_mac_address: wirelessMAC,
      esn_mac_address: esnMAC,
      serial_number: serialNumber,
      part_number: partNumber,
      modified_mac,
    };
    fseRetrievalToProcurement(deviceConfirmationData, moveToProcurementData);
  };

  const submitForm = () => {
    return (
      item.length > 0 &&
      comment.length > 0 &&
      (comment === 'bad'
        ? wirelessMAC.length > 0 && esnMAC.length > 0 && serialNumber.length > 0 && partNumber.length > 0
        : true)
    );
  };

  return (
    <Modal show={showMoveToProcurementModal} onHide={onOpenMoveToProcurement} centered>
      <Modal.Header closeButton>
        <Modal.Title>Move To Procurement</Modal.Title>
      </Modal.Header>
      <div className="modal-body detail mb-4 p-0">
        <form onSubmit={onSubmit} className="col-md-12">
          <div>
            <div className="pr-3 form-group col-md-12">
              <Label classname="m-0 p-0 copy-font" name="Name" />
              <CustomInput
                classname="form-control border border-info py-2"
                name=""
                value={one.name}
                onChange={handleChange}
                readOnly
                type=""
              />
            </div>
          </div>
          <div>
            <div className="pl-3 form-group col-md-12">
              <label className="m-0 p-0 copy-font" htmlFor="email">
                Email
              </label>
              <CustomInput
                id="email"
                classname="form-control border border-info py-2"
                name="customer_email"
                value={one.email}
                onChange={handleChange}
                readOnly
                type="text"
              />
            </div>
          </div>

          <div className="d-flex">
            <div className="pr-3 form-group col-md-12">
              <Label classname="m-0 p-0 copy-font" name="Device Name" />
              <CustomInput
                classname="form-control border border-info py-2"
                name="item"
                value={item}
                onChange={handleChange}
                readOnly={false}
                type="text"
              />
            </div>
            {/* <div className="pl-3 form-group col-md-6">
              <Label classname="m-0 p-0 copy-font" name="TrackList" />
              <select
                className="form-control border border-info py-2"
                onChange={handleChange}
                name="procurement_tracklist_id"
                id=""
              >
                <option value="">Select One</option>

                {list.map((li: { id: number; item: string }) => (
                  <option value={li.id}>{li.item}</option>
                ))}
              </select>
            </div> */}
          </div>

          <div className="d-flex">
            <div className="pl-3 form-group col-md-6">
              <label className="m-0 p-0 copy-font" htmlFor="mac">
                MAC ID
              </label>
              <CustomInput
                id="mac"
                classname="form-control border border-info py-2"
                name="radio_mac_id"
                value={one.mac}
                onChange={handleChange}
                readOnly
                type="text"
              />
            </div>
            <div className="pl-3 form-group col-md-6">
              <label className="m-0 p-0 copy-font" htmlFor="email">
                Router MAC ID
              </label>
              <CustomInput
                id=""
                classname="form-control border border-info py-2"
                name="router_mac_id"
                value={router_mac_id}
                onChange={handleChange}
                readOnly={false}
                type="text"
              />
            </div>
          </div>

          {/* <div className="d-flex">
            <div className="pl-3 form-group col-md-6">
              <Label classname="m-0 p-0 copy-font" name="TrackList Category" />
              <select
                className="form-control border border-info py-2"
                onChange={handleChange}
                name="tracklist_category_id"
                id=""
              >
                <option value="">Select One</option>

                {lists.map((li: { id: number; name: string }) => (
                  <option value={li.id}>{li.name}</option>
                ))}
              </select>
            </div>
          </div> */}
          <div className="">
            <div className="form-group col-md-12">
              <label className="m-0 p-0 copy-font">Condition</label>
              <select className="form-control border border-info" name="comment" onChange={handleChange}>
                <option value="">--Select Condition--</option>
                <option value="good">Good</option>
                <option value="bad">Bad</option>
              </select>
            </div>
          </div>

          {comment === 'bad' && (
            <>
              <div className="d-flex">
                <div className="pl-3 form-group col-md-6">
                  <label className="m-0 p-0 copy-font" htmlFor="wirelessMAC">
                    Wireless MAC Address
                  </label>
                  <CustomInput
                    id="wirelessMAC"
                    classname="form-control border border-info py-2"
                    name="wirelessMAC"
                    value={one.wirelessMAC}
                    onChange={handleChange}
                    readOnly={false}
                    type="text"
                  />
                </div>
                <div className="pl-3 form-group col-md-6">
                  <label className="m-0 p-0 copy-font" htmlFor="esnMAC">
                    ESN MAC Address
                  </label>
                  <CustomInput
                    id="esnMAC"
                    classname="form-control border border-info py-2"
                    name="esnMAC"
                    value={one.esnMAC}
                    onChange={handleChange}
                    readOnly={false}
                    type="text"
                  />
                </div>
              </div>
              <div className="d-flex">
                <div className="pl-3 form-group col-md-6">
                  <label className="m-0 p-0 copy-font" htmlFor="serialNumber">
                    Serial Number
                  </label>
                  <CustomInput
                    id="serialNumber"
                    classname="form-control border border-info py-2"
                    name="serialNumber"
                    value={one.serialNumber}
                    onChange={handleChange}
                    readOnly={false}
                    type="text"
                  />
                </div>
                <div className="pl-3 form-group col-md-6">
                  <label className="m-0 p-0 copy-font" htmlFor="partNumber">
                    Part Number
                  </label>
                  <CustomInput
                    id="partNumber"
                    classname="form-control border border-info py-2"
                    name="partNumber"
                    value={one.partNumber}
                    onChange={handleChange}
                    readOnly={false}
                    type="text"
                  />
                </div>
              </div>
            </>
          )}
          <div className="text-center mt-3">
            <CustomButton
              classname="btn btn-green py-2 px-5"
              name={loadingForm ? 'Moving' : 'Move'}
              disabled={loadingForm || !submitForm()}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default MoveToProcurement;
