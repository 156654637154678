/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import helpers from '../../../../../../../utils/installations/helpers';

const AssignedInstallationsTableBody = ({ installations }) => {
  const showInstallations = () =>
    installations && installations.length
      ? installations.map((installation, i) => {
          return (
            <tr key={installation.id}>
              <td> {i + 1}</td>
              <td>{installation.date}</td>
              <td>{installation.installation.address}</td>
              <td>{installation.installation.country === 'ng' ? 'Nigeria' : 'Ghana'}</td>
              <td>{installation.installation.state}</td>
              <td>{installation.installation.longitude}</td>
              <td>{installation.installation.latitude}</td>
              <td>{helpers.installationStatusLabels(installation.installation.status)}</td>
              <td>{installation.installation.payment_reference}</td>
              <td>{installation.installation.nearest_basestation}</td>
              <td>{installation.installation.distance_calculation}</td>
            </tr>
          );
        })
      : '';
  return <tbody>{showInstallations()}</tbody>;
};

export default AssignedInstallationsTableBody;
