/* eslint-disable no-empty */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-shadow */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import '../../../../../../styles/customer.css';
import '../../../../../../styles/style.css';
import axios from 'axios';
import toastr from 'toastr';
import ReactPaginate from 'react-paginate';
import { ToastContainer } from 'react-toastify';
import { LOGGED_IN_USER_URL } from '../../../../../../services/api/auth/AuthUrl';
import Helper from '../../../../../../utils/helpers';
import { ADMIN, NEW_PRODUCTS, SUPER_ADMIN } from '../../../../../../utils/departments/helpers';
import { useCustomers } from '../../../../../../hooks/new-products/products/wificall/customers/useCustomers';
import { useUser } from '../../../../../../hooks/shared/user/useUser';
import WifiCallNavBar from '../../../../../layouts/wificall/WifiCallNavBar';
import VerifiedCustomersBody from './VerifiedCustomersBody';

const VerifiedCustomers = ({ history }) => {
  const { profile } = useUser();
  const { firstName } = profile;
  const {
    verified,
    pages,
    filteredPages,
    isLoading,
    source,
    planType,
    handleSource,
    handlePlanType,
    handlePageClick,
    filterBySource,
    filterByAccountType,
    searchWithEmail,
    searchWithPhone,
  } = useCustomers();
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    const checkDepartment = async () => {
      try {
        const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });
        if (
          response.data.department_id !== `${ADMIN}` &&
          response.data.department_id !== `${NEW_PRODUCTS}` &&
          response.data.department_id !== `${SUPER_ADMIN}`
        ) {
          sessionStorage.clear();
          sessionStorage.setItem('token', '');
          sessionStorage.setItem('email', '');
          sessionStorage.setItem('departmentId', '');
          sessionStorage.setItem('roleId', '');
          history.push('/login');
          toastr.info('You are not allowed to view this page');
        }
      } catch (e) {
        // console.log('error', e.response);
      }
    };
    checkDepartment();
  }, [history]);

  const searchWithParams = (e) => {
    e.preventDefault();
    if (Helper.emailIsValid(keyword)) {
      const data = {
        email: keyword,
      };
      searchWithEmail(data);
    } else {
      const data = {
        phone: keyword,
      };
      searchWithPhone(data);
    }
  };

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <WifiCallNavBar currentPage="Customers" position={firstName} />

        <div className="row mt-5">
          <div className="col-12 pt-5 my-3">
            <form onSubmit={searchWithParams} className="ml-3 form-inline inner-addon left-addon d-flex">
              <input
                className="form-control mr-sm-2 empty mt-1"
                value={keyword}
                name="keyword"
                id="iconified"
                type="text"
                placeholder="Search using email and phone"
                onChange={(e) => setKeyword(e.target.value)}
              />
            </form>
          </div>
          <VerifiedCustomersBody
            customers={verified}
            isLoading={isLoading}
            filteredPages={filteredPages}
            filterBySource={filterBySource}
            filterByAccountType={filterByAccountType}
            source={source}
            handleSource={handleSource}
            planType={planType}
            handlePlanType={handlePlanType}
          />

          {pages > 1 ? (
            <ReactPaginate
              previousLabel="previous"
              nextLabel="next"
              breakLabel="..."
              breakClassName="break-me"
              pageCount={pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName="pagination"
              subContainerClassName="pages pagination"
              activeClassName="active"
            />
          ) : null}
        </div>
      </main>
      <ToastContainer />
    </div>
  );
};

export default VerifiedCustomers;
