import React from 'react';
import Modal from 'react-bootstrap/Modal';
import MailBlastForm from '../../../shared/mailblast/v2/MailBlastForm';
import useMailBlast from '../../../shared/mailblast/v2/hooks/useMailBlast';

const SendBulkEmails = ({ show, onClose, data }) => {
  const { editorState, subject, onSubjectChange, onEditorStateChange } = useMailBlast();
  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ paddingLeft: '2rem' }}>Send Bulk Emails</Modal.Title>
      </Modal.Header>
      <MailBlastForm
        subject={subject}
        onSubjectChange={onSubjectChange}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        type="sales"
        data={data}
      />
    </Modal>
  );
};

export default SendBulkEmails;
