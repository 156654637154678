/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../utils/loader/loadingHelper';
import edit from '../../../../assets/img/icons/edit.png';
import EditRefund from '../refunds/EditRefund';
import view from '../../../../assets/img/icons/view.png';
import { Button } from 'react-bootstrap';
import RefundList from '../../departments/sales/refund/RefundList';

import refundHelpers from '../../../../utils/field-support/installations/refundHelpers';
import useRefund from '../refund/hooks/useRefund';
import { ACCOUNTS, OPERATIONS, SALES, SUPER_ADMIN, VICE_PRESIDENT_SALES } from '../../../../utils/departments/helpers';

const RefundTable = ({
  lists,
  reload,
  isLoading,
  editRefundModal,
  openEditRefundModal,
  updateRefund,
  refunding,
  onUpdateRefund,
  openConfirmationModal,
  confirmationModal,
  data,
  page,
  option,
  type,
}) => {
  const colSpan = '15';
  const [refund, setRefund] = useState({
    email: '',
    firstName: '',
    lastName: '',
    fse_approval: '',
    bank_name: '',
    account_name: '',
    installation_id: '',
    account_number: '',
    phoneNumber: '',
    id: '',
    note: '',
    created_at: '',
    payment_ref: '',
    installation: {
      zone_id: '',
    },
    address: '',
    state: '',
    country: '',
    refund_status: '',
    updated_at: '',
  });

  const userCountry = sessionStorage.getItem('country');
  const [pages] = useState(1);
  const [show, setShow] = useState(false);
  const [showRefund, setShowRefund] = useState(false);
  const [client, setClient] = useState('');
  const [comment, setCommentShow] = useState(false);
  const [install, setInstall] = useState({});
  const [installId, setInstallId] = useState('');

  const {
    onChange,
    refund: refundModal,
    searchUsingKeyword,
    handleClose,
    items,
    keyword,
    handleShow,
    onSubmit,
    fetching,
    loadingForm,
    lists: refundList,
  } = useRefund();

  const handleRefundListShow = () => setShowRefund(false);

  const getOneInstallation = (i) => {
    setInstall(i);
  };

  const getRequest = (params) => {
    setRefund(params);
    openEditRefundModal();
  };

  const userRole = sessionStorage.getItem('roleId');
  const deptId = sessionStorage.getItem('departmentId');

  const editRefund = (list) => {
    switch (deptId) {
      case OPERATIONS:
        if ((list && list.fse_approval === 'approved') || list.fse_approval === 'rejected') {
          return <span className="view">No Action Required</span>;
        } else {
          return (
            <button
              type="submit"
              className="mx-1 noBorder"
              data-toggle="modal"
              data-placement="right"
              data-target="#editRelocation"
              onClick={() => {
                // setClient(list);
                getRequest(list);
              }}
            >
              <span className="view">
                <img src={edit} alt="" data-toggle="tooltip" data-placement="left" title="Edit" />
              </span>
            </button>
          );
        }
      case SALES || VICE_PRESIDENT_SALES:
        if (
          (list && list.sales_approval === 'approved') ||
          list.sales_approval === 'rejected' ||
          list.fse_approval === 'rejected' ||
          list.fse_approval === 'awaiting'
        ) {
          return <span className="view">No Action Required</span>;
        } else {
          return (
            <button
              type="submit"
              className="mx-1 noBorder"
              data-toggle="modal"
              data-placement="right"
              data-target="#editRelocation"
              onClick={() => {
                // setClient(list);
                getRequest(list);
              }}
            >
              <span className="view">
                <img src={edit} alt="" data-toggle="tooltip" data-placement="left" title="Edit" />
              </span>
            </button>
          );
        }

      case SUPER_ADMIN:
        if (
          (list && list.coo_approval === 'approved') ||
          list.coo_approval === 'rejected' ||
          list.fse_approval === 'rejected' ||
          list.fse_approval === 'awaiting' ||
          list.sales_approval === 'rejected' ||
          list.sales_approval === 'awaiting'
        ) {
          return <span className="view">No Action Required</span>;
        } else {
          return (
            <button
              type="submit"
              className="mx-1 noBorder"
              data-toggle="modal"
              data-placement="right"
              data-target="#editRelocation"
              onClick={() => {
                // setClient(list);
                getRequest(list);
              }}
            >
              <span className="view">
                <img src={edit} alt="" data-toggle="tooltip" data-placement="left" title="Edit" />
              </span>
            </button>
          );
        }

      case ACCOUNTS:
        if (
          (list && list.refund_status === 'approved') ||
          list.refund_status === 'rejected' ||
          list.coo_approval === 'rejected' ||
          list.coo_approval === 'awaiting' ||
          list.fse_approval === 'rejected' ||
          list.fse_approval === 'awaiting' ||
          list.sales_approval === 'rejected' ||
          list.sales_approval === 'awaiting'
        ) {
          return <span className="view">No Action Required</span>;
        } else {
          return (
            <button
              type="submit"
              className="mx-1 noBorder"
              data-toggle="modal"
              data-placement="right"
              data-target="#editRelocation"
              onClick={() => {
                // setClient(list);
                getRequest(list);
              }}
            >
              <span className="view">
                <img src={edit} alt="" data-toggle="tooltip" data-placement="left" title="Edit" />
              </span>
            </button>
          );
        }

      default:
        return null;
    }
  };

  const tableData =
    lists && lists.length ? (
      lists
        .filter((list, _) => {
          if (userCountry === '') {
            return list;
          }
          if ((userCountry === 'CIV' && 'cv') || (userCountry === 'GH' && 'gh') || (userCountry === 'NG' && 'ng')) {
            return list;
          }
        })
        .map((list, i) => {
          return (
            <tr key={list.id}>
              <td>{i + 1}</td>
              <td className="text-nowrap">{`${list.firstName} ${list.lastName}`}</td>
              <td>{list.email || 'N/A'}</td>
              <td>{list.phoneNumber || 'N/A'}</td>
              <td className="text-nowrap">{list.address || 'N/A'}</td>
              <td className="text-nowrap">{list.state || 'N/A'}</td>
              <td className="text-nowrap">{list.bank_name || 'N/A'}</td>
              <td className="text-nowrap">{list.account_name || 'N/A'}</td>
              <td className="text-nowrap">{list.account_number || 'N/A'}</td>
              <td>{refundHelpers.referralStatusLabels(list.fse_approval) || 'N/A'}</td>
              <td>{refundHelpers.referralStatusLabels(list.sales_approval) || 'N/A'}</td>
              <td>{refundHelpers.referralStatusLabels(list.coo_approval) || 'N/A'}</td>
              <td>{refundHelpers.referralStatusLabels(list.refund_status) || 'N/A'}</td>
              <td className="text-uppercase">{list.note || 'N/A'}</td>
              <td className="text-wrap">{list.payment_ref || 'N/A'}</td>

              <td className="text-wrap">{list.created_at.split('T')[0] || 'N/A'}</td>

              <td>
                {' '}
                <div className="d-flex">{Number(userRole) > 2 && editRefund(list)} </div>
              </td>
              <td>
                <Button
                  className="ml-1 mr-2 noBorder"
                  variant="primary"
                  onClick={() => {
                    setShowRefund(true);
                    setInstallId(list.installation_id);
                    const datas = {
                      installation_id: list.installation_id,
                    };
                    //use the default page 1 for all the search functionality
                    searchUsingKeyword(pages, datas);
                    getOneInstallation(list);
                  }}
                >
                  <span className="view" data-toggle="tooltip" data-placement="top" title="Edit">
                    <img src={view} alt="Edit" />
                  </span>
                </Button>
              </td>
            </tr>
          );
        })
    ) : (
      <NoItemRow colSpan={colSpan} text="There are no requests for refund" />
    );

  const showTableHead = () => {
    if (Number(userRole) > 2 && Number(userRole) != 6) {
      return 'Action';
    }
    return null;
  };

  return (
    <div className="table-responsive content col-12">
      <EditRefund
        item={data}
        page={page}
        reload={reload}
        option={option}
        updateRefund={updateRefund}
        refunding={refunding}
        show={editRefundModal}
        onClose={openEditRefundModal}
        refund={refund}
        openConfirmationModal={openConfirmationModal}
        confirmationModal={confirmationModal}
        onUpdateRefund={onUpdateRefund}
        client={client}
      />
      <RefundList
        load={loadingForm}
        onSubmit={onSubmit}
        show={showRefund}
        handleClose={handleRefundListShow}
        onChange={onChange}
        refund={refundModal}
        install={install}
        lists={refundList}
        items={items}
        fetching={fetching}
        installId={installId}
      />

      <table className="table table-striped">
        <thead>
          <tr className="">
            <th>S/N</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>Address</th>
            <th>State</th>
            <th>Bank</th>
            <th>Acc Name</th>
            <th>Acc number</th>
            <th>FSE Approval Status</th>
            <th>Sales Approval Status</th>
            <th>COO Approval Status</th>
            <th>Refund Status</th>
            <th>Note</th>
            <th>Payment REF</th>
            <th>Date Initiated </th>
            <th>{showTableHead()}</th>
            <th>View More </th>
          </tr>
        </thead>
        <tbody>{isLoading ? <TizetiGIFLogoTable colSpan={colSpan} /> : tableData}</tbody>
      </table>
    </div>
  );
};

export default RefundTable;
