import React from 'react';

const walletdata = (props) => {
  return props.wallettransactions.map((item, key) => (
    <tr className="copy-font" key={item.reference}>
      <th scope="row">{key + 1}</th>
      <td>{item.reference}</td>
      <td>{item.custaccount}</td>
      <td>{item.plan}</td>
      <td>{item.accountid}</td>
      <td>{item.businessName}</td>
      <td className="text-center">
        <span className="amount">&#8358; </span>{' '}
        {item.amount}
      </td>
      <td>{item.time}</td>
    </tr>
  ));

  // <div>
  //     <p> this is the wallet transaction size {props.size}</p>
  // </div>
};

export default walletdata;
