import React from 'react';
import useOpenAndBlockedUsers from '../hooks/useOpenAndBlockedUsers';
import AppContext from '../../../../../../context/shared/AppContext';

const OpenAndBlockedUsersProvider = ({ children }) => {
  const {
    data,
    loading,
    pageCount,
    handlePageChange,
    status,
    onChange,
    searchStatus,
    currentPage,
    blockOrUnblockUser,
    blockingOrUnblocking,
    searchByEmail,
    onChangeSetByDetails,
    searchDetails
  } = useOpenAndBlockedUsers();
  return (
    <AppContext.Provider
      value={{
        data,
        loading,
        pageCount,
        handlePageChange,
        status,
        onChange,
        searchStatus,
        currentPage,
        blockOrUnblockUser,
        blockingOrUnblocking,
        searchByEmail,
        onChangeSetByDetails,
        searchDetails
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export default OpenAndBlockedUsersProvider;
