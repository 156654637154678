import React from 'react';

const AlarmsTableHead = () => {
  return (
    <thead>
      <tr>
        <th>#</th>
        <th className="text-nowrap">Name</th>
        <th className="text-nowrap">Acknowledged By</th>
        <th>Code</th>
        <th>Duration</th>
        <th>Mac</th>
        <th>Message</th>
        <th>Managed Account</th>
        <th>Network</th>
        <th>Severity</th>
        <th>Source</th>
        <th>Source Type</th>
        <th>Status</th>
        <th>Date Raised</th>
        <th>Tower</th>
        <th>Ip</th>
      </tr>
    </thead>
  );
};

export default AlarmsTableHead;
