import React from 'react';

const TotalDirectory = () => {
  const directorycount = 0;
  return (
    <div className="col mb-3">
      <div className="boxes">
        <div className="d-flex mt-3">
          <div className="mt-4 pl-2">
            <img src="https://res.cloudinary.com/teewhy/image/upload/v1545151448/book1.fw.png" alt="" />
          </div>
          <div className="mt-3 p-2">
            {directorycount ? <div className="count">{directorycount.toLocaleString()}</div> : null}
            <h3 className="copy-font">Total Contacts</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalDirectory;
