/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import mailblast from '../../../../../assets/img/icons/mailblast.svg';
import Logout from '../../../shared/auth/Logout';
import ResearchAndDevelopmentSideBar from '../../../../layouts/research-and-development/ResearchAndDevelopmentSideBar';
import { getUser } from '../../../../../actions/shared/user/UserActions';
import { getLoggedInUser } from '../../../../../actions/user/UserActions';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import { RESEARCH_AND_DEVELOPMENT } from '../../../../../utils/config';
import Requisitions from '../../../shared/requisition/shared/Requisitions';
import { SUPER_ADMIN } from '../../../../../utils/departments/helpers';

class Requisition extends Component {
  componentDidMount() {
    this.checkDepartment();
    // eslint-disable-next-line react/destructuring-assignment
    this.props.getLoggedInUser();
  }

  componentWillReceiveProps() {
    this.checkDepartment();
  }

  checkDepartment = async () => {
    const { history } = this.props;
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (
        response.data.department_id !== `${RESEARCH_AND_DEVELOPMENT}` &&
        response.data.department_id !== `${SUPER_ADMIN}`
      ) {
        history.push('/');

        sessionStorage.setItem('token', '');
        sessionStorage.setItem('email', '');
        sessionStorage.clear();

        toastr.info('You are not allowed to view this page');
      }
    } catch (e) {
      toastr.info('Something went wrong!');
    }
  };

  render() {
    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <Link className="s-sidebar__trigger" to="#">
                <i className="fa fa-bars mr-3" />
              </Link>
              <ResearchAndDevelopmentSideBar />

              <h3 className="col-lg-6 overview-part">Requisition</h3>

              <span className="navbar-text d-flex">
                <Link
                  to="/research-and-development/mail-blast"
                  className="d-flex"
                  style={{
                    color: 'gray',
                    textDecoration: 'none',
                  }}
                >
                  <img src={mailblast} className="logout mr-2" alt="" />
                  <span>
                    <h6 className="mt-2 mr-2">
                      <strong>MailBlast</strong>
                    </h6>
                  </span>
                </Link>

                <h6 className="mt-2">
                  {/* {firstName} */}
                  <Logout />
                </h6>
              </span>
            </div>
          </div>
          <div className="after-summary col-12">
            <Requisitions />
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  role: state.auth.auth,
});

Requisition.propTypes = {
  history: PropTypes.shape(),
  // user: PropTypes.shape({
  //   firstName: PropTypes.string,
  // }),
  user: PropTypes.array,
  getLoggedInUser: PropTypes.func,
};

export default connect(mapStateToProps, {
  getUser,
  getLoggedInUser,
})(Requisition);
