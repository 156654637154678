import React from 'react';
import PropTypes from 'prop-types';
import { PROCUREMENT, SUPER_ADMIN } from '../../../../../../utils/departments/helpers';
import { useUser } from '../../../../../../hooks/shared/user/useUser';
import ProcurementNavBar from '../../../../../layouts/procurement/ProcurementNavBar';
import ApprovedPurchaseOrderBody from '../../../../shared/purchase-order/approved-po/ApprovedPurchaseOrderBody';
import useCheckTwoDepartments from '../../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';

const ProcurementApprovedPO = ({ history }) => {
  useCheckTwoDepartments(`${PROCUREMENT}`, `${SUPER_ADMIN}`, history);
  const {
    profile: { firstName },
  } = useUser();

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <span className="d-none d-md-block">
          <ProcurementNavBar currentPage="Approved Purchase Order" position={firstName} />
        </span>
        <span className="d-md-none d-block">
          <ProcurementNavBar currentPage="Approved PO" position={firstName} />
        </span>
        <ApprovedPurchaseOrderBody />
      </main>
    </div>
  );
};

ProcurementApprovedPO.propTypes = {
  history: PropTypes.shape().isRequired,
};
export default ProcurementApprovedPO;
