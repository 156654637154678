import React from 'react';
import useLteEnbIp from '../hooks/useLteEnbIp';
import AppContext from '../../../../../../../context/shared/AppContext';
import { LTE_ENB_IP_URL } from '../api/LteEnbIpUrl';

const LteEnbIpProvider = ({ children }) => {
  const { data, loading, paginateChange, loadingForm, onChange, formValue, onSubmit, pages } = useLteEnbIp(
    `${LTE_ENB_IP_URL}`,
  );

  return (
    <AppContext.Provider
      value={{
        data,
        loading,
        paginateChange,
        loadingForm,
        onChange,
        formValue,
        onSubmit,
        pages,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export default LteEnbIpProvider;
