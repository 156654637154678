/* eslint-disable react/button-has-type */
/* eslint-disable react/require-default-props */
import React from 'react';

const Approve = ({ onClickShowModal, approving, giveCfoApproval, reloadFunction, requisit }) => {
  return (
    <div className="modal-part modal">
      <div className="dialog-part">
        <div className="modal-header" style={{ background: '#fff', boxShadow: 'none' }}>
          <h4 className="modal-title text-center" id="myModalLabel">
            Approve Requisition
          </h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-hidden="true"
            onClick={() => onClickShowModal()}
          >
            ×
          </button>
        </div>
        <div className="content-part">
          {/* <div className="header-part mt-1 mb-1" style={{ borderBottom: '1px solid gray' }}>
            <p style={{ fontSize: '1rem', fontWeight: 'bolder', color: 'red' }} onClick={() => onClickShowModal()}>
              X
            </p>
            <h4 className="title text-center" id="myModalLabel">
              Approve Requisition
            </h4>
          </div>
 */}
          <div className="body p-0">
            <div className="text-center my-2">
              <button
                disabled={approving}
                style={{
                  background: '#3BB427',
                  width: '30%',
                  margin: ' auto',
                  padding: '0.5em',
                  color: 'white',
                }}
                onClick={() => giveCfoApproval(requisit.id, reloadFunction)}
              >
                {approving ? 'Approving' : 'Click to Approve'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Approve;
