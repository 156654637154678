import axios from 'axios';
import toastr from 'toastr';
import { toast } from 'react-toastify';
import {
  GET_DEDICATED_CUSTOMER_BY_AP_URL,
  GET_DEDICATED_CUSTOMER_BY_NAME_URL,
  GET_DEDICATED_CUSTOMERS_URL,
  GET_PAGINATED_DEDICATED_CUSTOMERS_URL,
} from '../../../services/api/noc/dedicated-customers/DedicatedCustomersUrl';
import {
  GET_BANDWIDTH,
  GET_DEDICATED_CUSTOMERS,
  START_LOADING_BANDWIDTH,
  START_LOADING_DEDICATED_CUSTOMERS,
  STOP_LOADING_BANDWIDTH,
  STOP_LOADING_DEDICATED_CUSTOMERS,
} from '../../../constants/types/noc/NocTypes';
import {
  CHANGE_BANDWIDTH_URL,
  GET_BANDWIDTH_URL,
} from '../../../services/api/noc/dedicated-customers/bandwidth/BandWidthUrl';
import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';

export const getDedicatedCustomers = () => async (dispatch) => {
  dispatch({
    type: START_LOADING_DEDICATED_CUSTOMERS,
    payload: true,
  });

  try {
    const response = await axios.get(`${GET_DEDICATED_CUSTOMERS_URL}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    // so i just dicovered emojis on vscode 💃🏽🤪
    // been there, done that
    // allow me shine plis dear😡😕

    if (response.data) {
      dispatch({
        type: GET_DEDICATED_CUSTOMERS,
        payload: {
          dedicated: response.data.list,
          loading: false,
          pageCount: response.data.pages,
          // paginate: true
        },
      });
    } else {
      dispatch({
        type: STOP_LOADING_DEDICATED_CUSTOMERS,
        payload: false,
      });
    }
  } catch (e) {
    toastr.info('could not fetch dedicated customers. please try again');
    dispatch({
      type: STOP_LOADING_DEDICATED_CUSTOMERS,
      payload: false,
    });
  }
};

export const paginateDedicatedCustomers = (data) => async (dispatch) => {
  dispatch({
    type: START_LOADING_DEDICATED_CUSTOMERS,
    payload: true,
  });

  try {
    const response = await axios.get(`${GET_PAGINATED_DEDICATED_CUSTOMERS_URL}/${data}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      dispatch({
        type: GET_DEDICATED_CUSTOMERS,
        payload: {
          dedicated: response.data.list,
          loading: false,
          pageCount: response.data.pages,
        },
      });
    } else {
      dispatch({
        type: STOP_LOADING_DEDICATED_CUSTOMERS,
        payload: false,
      });
    }
  } catch (e) {
    dispatch({
      type: STOP_LOADING_DEDICATED_CUSTOMERS,
      payload: false,
    });
  }
};

export const getDedicatedCustomersByAP = (data) => async (dispatch) => {
  dispatch({
    type: START_LOADING_DEDICATED_CUSTOMERS,
    payload: true,
  });

  try {
    const response = await axios.post(`${GET_DEDICATED_CUSTOMER_BY_AP_URL}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      dispatch({
        type: GET_DEDICATED_CUSTOMERS,
        payload: {
          dedicated: response.data.aps,
          pageCount: response.data.pages,
        },
      });
    } else {
      dispatch({
        type: STOP_LOADING_DEDICATED_CUSTOMERS,
        payload: false,
      });
    }
  } catch (e) {
    dispatch({
      type: STOP_LOADING_DEDICATED_CUSTOMERS,
      payload: false,
    });
  }
};

export const getDedicatedCustomersByName = (data) => async (dispatch) => {
  dispatch({
    type: START_LOADING_DEDICATED_CUSTOMERS,
    payload: true,
  });

  try {
    const response = await axios.post(`${GET_DEDICATED_CUSTOMER_BY_NAME_URL}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      dispatch({
        type: GET_DEDICATED_CUSTOMERS,
        payload: {
          dedicated: response.data,
          pageCount: 0,
        },
      });
    } else {
      dispatch({
        type: STOP_LOADING_DEDICATED_CUSTOMERS,
        payload: false,
      });
    }
  } catch (e) {
    if (e) {
      dispatch({
        type: STOP_LOADING_DEDICATED_CUSTOMERS,
        payload: false,
      });
    }
  }
};

export const addBandwidth = (data) => {
  return async (dispatch) => {
    dispatch({
      type: LOADING_STARTS,
    });
    try {
      const response = await axios.post(`${CHANGE_BANDWIDTH_URL}`, data);
      if (response.data.status === true) {
        toast.success('Successfully added bandwidth');
        dispatch({
          type: LOADING_STOPS,
        });
      } else {
        toast.info('Unable to add bandwidth!');
        dispatch({
          type: LOADING_STOPS,
        });
      }
    } catch (e) {
      toast.info('Something went wrong!');
      dispatch({
        type: LOADING_STOPS,
      });
    }
  };
};

export const getUserBandwidth = (data) => {
  return async (dispatch) => {
    dispatch({
      type: START_LOADING_BANDWIDTH,
    });
    try {
      const response = await axios.post(`${GET_BANDWIDTH_URL}`, data);

      if (response.data) {
        dispatch({ type: GET_BANDWIDTH, payload: response.data });
      } else {
        dispatch({
          type: STOP_LOADING_BANDWIDTH,
        });
        toast.info('Unable to get user bandwidth');
      }
    } catch (e) {
      if (e) {
        dispatch({
          type: STOP_LOADING_BANDWIDTH,
        });
        toast.info('Something went wrong. Unable to get user bandwidth');
      }
    }
  };
};
