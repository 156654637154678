import React from 'react';
import wifi from '../../../../../assets/img/icons/wifi.png';
import useInstallations from '../../../../../hooks/shared/installations/useInstallations';

const TotalInstallations = ({monthlyCompleted}) => {
  const { count } = useInstallations();
  return (
    <div className="col-12 col-lg-12">
      <div className="boxes">
        <div className="opsview text-center">
          <img src={wifi} alt="" />
        </div>
        <div className="count">{monthlyCompleted}</div>
        {/* {role > 1 ? <h1>super-admin</h1> : null } */}
        <h3 style={{ color: '#673fbb' }}>Total Monthly Installations</h3>
      </div>
    </div>
  );
};

export default TotalInstallations;
