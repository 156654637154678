/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import CustomerSupportNavBar from '../../../../layouts/customer-support/CustomerSupportNavBar';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import RetentionLogsBody from '../../../shared/retention-logs/RetentionLogsBody';
import RetentionUnderConstruction from '../../../shared/retention-logs/RetentionUnderConstruction';

const CustomerSupportRetentionLogs = () => {
  const { profile, user } = useUser();
  const { firstName } = profile;
  const retentionLogsEmail = [
    'nneka.ifeabunike@tizeti.com',
    'gbenga.diya@tizeti.com',
    'ijem.okolugbo@tizeti.com',
    'customersupportDept@tizeti.com',
  ];
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <CustomerSupportNavBar currentPage="Retention Logs" roleID={user.role_id} position={firstName} />
        {profile.officialEmail == null ? (
          'Loading...'
        ) : profile.officialEmail && retentionLogsEmail.includes(profile.officialEmail) ? (
          <RetentionLogsBody />
        ) : (
          <RetentionUnderConstruction />
        )}
      </main>
    </div>
  );
};

export default CustomerSupportRetentionLogs;
