import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import toastr from 'toastr';
import { Link } from 'react-router-dom';
import { API_URL } from '../../../../../utils/config';
import Inventory from './Inventory';
import EditInventory from './EditInventory';
import TizetiLoader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import view from '../../../../../assets/img/icons/view.png';
import edit from '../../../../../assets/img/icons/edit.png';
import Delete from '../../../../../assets/img/icons/delete.png';

class InventoriesTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inventories: [],
      isLoading: false,
      inventoryId: {},
      deleting: false,
    };
  }

  getInventory(inventory) {
    this.setState({
      inventoryId: inventory,
    });
  }

  delete(id) {
    // const { history } = this.props;
    this.setState({
      deleting: true,
    });

    const inventoryID = id;

    axios({
      method: 'delete',
      url: `${API_URL}/inventory/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
      .then((response) => {
        this.setState({
          deleting: false,
        });

        /*
            const newFiltered = this.state.filtered.filter(fil => {

                return fil !== id

            });

            this.setState({
                filtered: newFiltered

            });
*/

        window.location.reload();
        //             history.push('/inventories')
      })
      .catch((err) => {});
  }

  render() {
    const { error, deleting } = this.state;

    if (deleting) {
      toastr.info('Deleting...');
    }

    if (this.props.load) {
      /* return <img src="https://i.stack.imgur.com/ATB3o.gif" />; */
      return <img src={TizetiLoader} alt="" />;
    }

    return (
      <tbody>
        {this.props.inventories.map((inventory, index) => (
          <tr key={inventory.id}>
            <td>{(((this.props.page - 1) * 20) + index) + 1}</td>
            <td>{inventory.name}</td>
            <td>{inventory.manufacturer}</td>
            <td>{inventory.model}</td>
            <td>{inventory.vendor}</td>
            <td>{inventory.serialNumber}</td>
            <td>{inventory.warranty}</td>
            <td>{inventory.firmware}</td>
            <td>{moment(inventory.date_of_purchase).format('MM/DD/YYYY')}</td>
            {/* <td>{inventory.employee_id}</td> */}
            <td>
              <div className="d-flex">
                <button
                  data-id={inventory.id}
                  onClick={() => this.getInventory(inventory)}
                  type="submit"
                  data-toggle="modal"
                  data-placement="right"
                  data-target="#customer_detail"
                  style={{ border: '0', backgroundColor: 'white' }}
                >
                  <span className="view">
                    <img src={view} data-toggle="tooltip" data-placement="left" title="View" />
                  </span>
                </button>

                <Inventory inventory={this.state.inventoryId} />
                <button
                  type="submit"
                  data-toggle="modal"
                  data-target="#edit"
                  onClick={() => this.getInventory(inventory)}
                  style={{ border: '0', backgroundColor: 'white' }}
                >
                  <span className="view">
                    <img src={edit} data-toggle="tooltip" data-placement="top" title="Edit" />
                  </span>
                </button>
                <div
                  id="edit"
                  className="modal fade in"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="myModalLabel"
                  aria-hidden="true"
                  style={{ overflow: 'auto' }}
                >
                  <EditInventory inventory={this.state.inventoryId} />
                </div>

                <div
                  id="success1"
                  className="modal fade in"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="myModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-body text-center">
                        <span className="fa fa-check-circle" />
                        <p>
                          <b>Inventory Udpated Successfully!</b>
                        </p>
                        <Link to="#edit" data-toggle="modal" data-dismiss="modal">
                          <button
                            type="submit"
                            className="btn btn-green waves-effect"
                            data-toggle="modal"
                            data-target="#edit"
                          >
                            Add Another
                          </button>
                        </Link>{' '}
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  data-toggle="modal"
                  data-target="#cancel"
                  style={{ border: '0', backgroundColor: 'white' }}
                  onClick={(id) => this.delete(inventory.id)}
                >
                  <span className="view">
                    <img
                      src={Delete}
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Delete"
                      onClick={(id) => this.delete(inventory.id)}
                    />
                  </span>
                </button>
                {/* <div id="cancel" className="modal fade in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                                                        <div className="modal-dialog">
                                                            <div className="modal-content">
                                                                <div className="modal-body text-center">
                                                                    <span className="fa fa-exclamation-circle"></span>
                                                                    <p><b>Are you sure you want to delete this inventory?</b></p>
                                                                    <button type="submit" className="btn btn-green waves-effect"data-toggle="modal" data-target="#edit">Yes Delete</button>
                                                                    <button type="submit" className="btn btn-green waves-effect"data-toggle="modal" data-target="#edit">Cancel</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    );
  }
}

export default InventoriesTable;
