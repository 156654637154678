import React from 'react';
import PartnershipNavBar from '../../../../layouts/partnership/PartnershipNavBar';
import UsageHistoryBody from './UsageHistoryBody';

const UsageHistory = () => {
  const name = sessionStorage.getItem('firstName');

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <div className="d-flex col-lg-12 p-2 top-header">
          <PartnershipNavBar currentPage="US AID Usage" position={name} />
        </div>
        <div>US AID Usage</div>
        <div className="pt-5">
          <UsageHistoryBody />
        </div>
      </main>
    </div>
  );
};

export default UsageHistory;
