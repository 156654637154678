/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React from 'react';
import helpers from '../../../../utils/helpers';

const ViewRetrieval = ({
  oneItem: {
    item,
    customer_name,
    customer_email,
    comment,
    procurement_approval,
    procurement_comment,
    account_approval,
    account_comment,
    EmployeeAuth,
    retrieval_date,
  },
}) => {
  return (
    <div id="view_item" className="modal fade mt-5" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title my-2">Retrieval Details</h4>
            <button type="button" className="mr-2 close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div className="modal-body detail p-0">
            <div className="row">
              <div className="col-lg-12 d-flex">
                <div className="col-lg-5 detail-label1">
                  <label className="mt-1">Item</label>
                  <label className="mt-1">Customer Name</label>
                  <label className="mt-1">Email</label>
                </div>
                <div className="col-lg-7 detail-result1 item-font">
                  <p className="mt-4 mb-2">{item}</p>
                  <p className="mb-2">{customer_name}</p>
                  <p className="mb-2">{customer_email}</p>
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-5 detail-label3">
                  <label>Retrieved By</label>
                  <label className="mt-1">Condition</label>
                  <label className="mt-1">Date</label>
                </div>
                <div className="col-lg-7 detail-result2 item-font">
                  <p className="mt-4 mb-2">
                    {EmployeeAuth && EmployeeAuth.employees.length
                      ? `${EmployeeAuth.employees[0].firstName} ${EmployeeAuth.employees[0].lastName}`
                      : 'N/A'}
                  </p>
                  <p className="mb-2 text-uppercase">{comment}</p>
                  <p className="mb-2">{helpers.formatDateAndTime(retrieval_date)}</p>
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-5 detail-label2">
                  <label>Procurement Approval</label>
                  <label className="mt-1">Procurement Comment</label>
                </div>
                <div className="col-lg-7 detail-result2 item-font">
                  <p className="mt-4 mb-2 text-capitalize">{procurement_approval}</p>
                  <p className="mb-3">{procurement_comment || 'NO COMMENT'}</p>
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-5 detail-label3">
                  <label>Accounts Approval</label>
                  <label className="mt-1">Accounts Comment</label>
                </div>
                <div className="col-lg-7 detail-result2 item-font">
                  <p className="mt-4 mb-2 text-capitalize">{account_approval}</p>
                  <p className="mb-3">{account_comment || 'NO COMMENT'}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewRetrieval;
