import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AES } from 'crypto-js';
import PATHS from '../../../../routes/urls';
import CfoSideBarItem from './cfo/CfoSideBarItem';
import AccountsBarItem from '../AccountsBarItem';

const span13 = (name) => <span style={{ fontSize: '13px' }}>{name}</span>;

const AccountsSideBar = () => {
  const role_id = Number(sessionStorage.getItem('roleId'));
  const email = sessionStorage.getItem('officialEmail');

  const detailsState = {
    name: 'React',
    messagetoken: sessionStorage.getItem('token'),
    messagerole: sessionStorage.getItem('roleId'),
    messagedepartment: sessionStorage.getItem('departmentId'),
    secret: 'Wifirocks123',
    cipher: '',
  };
  const [details, setDetails] = useState(detailsState);
  const { name, messagetoken, messagerole, messagedepartment, secret, cipher } = details;

  const obj = {
    login: messagetoken,
    pass: messagerole,
    department: messagedepartment,
  };

  const ciph = AES.encrypt(JSON.stringify(obj), secret).toString();

  return (
    <div className="s-layout__sidebar">
      <Link className="s-sidebar__trigger" to="#">
        <i className="fa fa-bars mr-3" />
      </Link>

      <nav className="s-sidebar__nav">
        <Link className="navbar-brand-logo" to="/accounts">
          <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971047/Tizeti-Logo.png" alt="homepage" />
        </Link>

        <ul>
          <span className="p-0 mt-4">
            <li className="s-sidebar__nav-link">
              <Link to="/accounts" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971013/Group-3.png" alt="img" />
                </span>
                Overview
              </Link>
            </li>
            <li className="s-sidebar__nav-link">
              {' '}
              <a href={`https://os.tizeti.com/main/#/overview?ciph=${ciph}`} target="_blank" className="make-bold">
                <span>
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923169/configr_d.fw.png" alt="img" />
                </span>
                <small>New system (Beta v2.0)</small>
              </a>
            </li>
            {Number(role_id) > 1 && Number(role_id) != 6 && (
              <li className="s-sidebar__nav-link">
                <Link to="/shared/employees" target="_self">
                  <span>
                    <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-6.png" alt="img" />
                  </span>
                  Employees
                </Link>
              </li>
            )}{' '}
            <li className="s-sidebar__nav-link">
              <Link to="/accounts/inventories" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971015/Group-7.png" alt="" />
                </span>
                Inventory
              </Link>
            </li>
            <li className="s-sidebar__nav-link">
              <Link to="/accounts/vehicles" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971011/Group.png" alt="" />
                </span>
                Vehicles
              </Link>
            </li>
            {/* <li className="s-sidebar__nav-link">
                
                <Link to="/accounts/basestation" target="_self">
                  <span className="mr-2">x
                    <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971012/Group-1.png" alt="" />
                  </span>
                  BaseStations
                </Link>
              </li> */}
            <li className="s-sidebar__nav-link">
              <Link to="/shared/all-basestations" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971012/Group-1.png" alt="" />
                </span>
                All BaseStations
              </Link>
            </li>
            {/* {Number(role_id) > 1 ? ( */}
            <li className="s-sidebar__nav-link d-none d-md-block">
              <Link to="/accounts/add-lte" target="_self">
                <span>
                  <img
                    src="https://res.cloudinary.com/teewhy/image/upload/v1556618815/QualityAssurance.svg"
                    alt="img"
                  />
                </span>
                Add LTE User
              </Link>
            </li>
            {/* ) : null} */}
            <li className="s-sidebar__nav-link">
              <Link to="/accounts/installations-counter" target="_self" className="">
                <span className="mr-2">
                  <img alt="" src="https://res.cloudinary.com/teewhy/image/upload/v1550844527/requisition.png" />
                </span>
                Installations Summary
              </Link>
            </li>
            {Number(role_id) > 2 ? (
              <li className="s-sidebar__nav-link">
                <Link to="/accounts/lte" target="_self">
                  <span>
                    <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                  </span>
                  Clean LTE
                </Link>
              </li>
            ) : null}
            <li className="s-sidebar__nav-link">
              <Link to="/accounts/customers-extensions" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1547724068/frequency_graphs.png" alt="" />
                </span>
                <span className="text-wrap">Customer Extension</span>
              </Link>
            </li>
            <li className="s-sidebar__nav-link">
              <Link to="/accounts/geniatech" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971012/Group-1.png" alt="" />
                </span>
                Geniatech
              </Link>
            </li>
            <li className="s-sidebar__nav-link">
              <Link to={PATHS.ACCOUNTS_NO_LOS} target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971012/Group-1.png" alt="" />
                </span>
                NO LOS
              </Link>
            </li>
            <li className="s-sidebar__nav-link">
              <Link to="/accounts/dedicated-invoice" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971015/Group-7.png" alt="" />
                </span>
                Dedicated Invoice
              </Link>
            </li>
            <li className="s-sidebar__nav-link">
              <Link to="/accounts/dedicated-customers" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971015/Group-7.png" alt="" />
                </span>
                Dedicated Customers
              </Link>
            </li>
            <li className="s-sidebar__nav-link">
              <Link to="/accounts/retrievals" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                </span>
                Retrievals
              </Link>
            </li>
            <CfoSideBarItem url={PATHS.ACCOUNTS_ADMIN_REQUESTS} name="Admin Department Requests" />
            <CfoSideBarItem url={PATHS.CFO_EXPRESS_WIFI_RETAILER_SMS} name="Express Wifi Retailer Sms" />
            {Number(sessionStorage.getItem('roleId')) > 2 && Number(sessionStorage.getItem('roleId')) !== 6 ? (
              <>
                <span className="s-sidebar__nav-link" data-toggle="collapse" data-target="#procurement">
                  <li className="s-sidebar__nav-link">
                    <Link to={PATHS.ACCOUNTS_STAFF_PAYSLIPS} target="_self">
                      <span className="mr-2">
                        <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971015/Group-7.png" alt="" />
                      </span>
                      Staff Payslips
                    </Link>
                  </li>
                </span>

                <span id="procurement" className="collapse base-li top-bottom pl-2">
                  <li className="s-sidebar__nav-link">
                    <Link to="/accounts/cfo/retrievals" target="_self" className="text-nowrap">
                      <span className="mr-2">
                        <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                      </span>
                      <span className="font13">Retrievals</span>
                    </Link>
                  </li>

                  <li className="s-sidebar__nav-link">
                    <Link to="/accounts/cfo/account-reconciliation" target="_self" className="text-nowrap">
                      <span className="mr-2">
                        <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                      </span>
                      <span className="font13">Account Reconciliation</span>
                    </Link>
                  </li>

                  <li className="s-sidebar__nav-link">
                    <Link to="/accounts/cfo/purchase-order" target="_self" className="text-nowrap">
                      <span className="mr-2">
                        <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                      </span>
                      <span className="font13">Procurement Purchase Order</span>
                    </Link>
                  </li>

                  <li className="s-sidebar__nav-link">
                    <Link to="/accounts/cfo/interdepartmental-purchase-order" target="_self" className="text-nowrap">
                      <span className="mr-2">
                        <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                      </span>
                      <span className="font13">InterDepartment Purchase Order</span>
                    </Link>
                  </li>
                </span>
              </>
            ) : null}
            {Number(role_id) === 9 ? (
              <>
                <span className="s-sidebar__nav-link" data-toggle="collapse" data-target="#procurement">
                  <li>
                    <Link to="#" target="_self" className="">
                      <span className="mr-2">
                        <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                      </span>
                      PROCUREMENT
                    </Link>
                  </li>
                </span>

                <span id="procurement" className="collapse base-li top-bottom pl-2">
                  <li className="s-sidebar__nav-link">
                    <Link to="/shared/tracklist" target="_self" className="text-nowrap">
                      <span className="mr-2">
                        <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                      </span>
                      <span className="font13">Tracklist</span>
                    </Link>
                  </li>

                  <li className="s-sidebar__nav-link">
                    <Link to="/shared/release-report" target="_self" className="text-nowrap">
                      <span className="mr-2">
                        <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                      </span>
                      <span className="font13">Release Report</span>
                    </Link>
                  </li>

                  <li className="s-sidebar__nav-link">
                    <Link to="/accounts/cfo/retrievals" target="_self" className="text-nowrap">
                      <span className="mr-2">
                        <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                      </span>
                      <span className="font13">Retrievals</span>
                    </Link>
                  </li>

                  <li className="s-sidebar__nav-link">
                    <Link to="/accounts/cfo/account-reconciliation" target="_self" className="text-nowrap">
                      <span className="mr-2">
                        <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                      </span>
                      <span className="font13">Account Reconciliation</span>
                    </Link>
                  </li>

                  <li className="s-sidebar__nav-link">
                    <Link to="/accounts/cfo/purchase-order" target="_self" className="text-nowrap">
                      <span className="mr-2">
                        <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                      </span>
                      <span className="font13">Procurement Purchase Order</span>
                    </Link>
                  </li>

                  <li className="s-sidebar__nav-link">
                    <Link to="/accounts/cfo/interdepartmental-purchase-order" target="_self" className="text-nowrap">
                      <span className="mr-2">
                        <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                      </span>
                      <span className="font13">InterDepartment Purchase Order</span>
                    </Link>
                  </li>
                </span>
              </>
            ) : null}
            {Number(role_id) > 2 ? (
              <li className="s-sidebar__nav-link d-none d-md-block">
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a href={PATHS.ACCOUNTS_RECRUITMENT} target="_blank" className="">
                  <span className="mr-2">
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1555319728/recruitment_1.svg" alt="img" />
                  </span>
                  Recruitment
                </a>
              </li>
            ) : (
              ''
            )}
            <li className="s-sidebar__nav-link">
              <Link to="/accounts/firs-transactions" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971015/Group-7.png" alt="" />
                </span>
                Firs transactions
              </Link>
            </li>
          </span>

          <span className="p-0 mt-4 d-none d-md-block">
            {Number(sessionStorage.getItem('roleId')) === 9 ? (
              <>
                <span
                  className="s-sidebar__nav-link d-none d-md-block"
                  data-toggle="collapse"
                  data-target="#po_dropdown"
                >
                  <li>
                    <Link to="#" target="_self" className="">
                      <span className="mr-2">
                        <img alt="" src="https://res.cloudinary.com/teewhy/image/upload/v1550844527/requisition.png" />
                      </span>
                      Purchase Order
                    </Link>
                  </li>
                </span>

                <span id="po_dropdown" className="collapse base-li top-bottom pl-2">
                  <li className="s-sidebar__nav-link">
                    <Link to="/accounts/purchase-order" target="_self" className="text-nowrap">
                      <span className="mr-2">
                        <img alt="" src="https://res.cloudinary.com/teewhy/image/upload/v1550844527/requisition.png" />
                      </span>
                      <span className="font13">Accounts Purchase Order</span>
                    </Link>
                  </li>

                  <li className="s-sidebar__nav-link">
                    <Link to="/accounts/departments-purchase-order" target="_self" className="text-nowrap">
                      <span className="mr-2">
                        <img alt="" src="https://res.cloudinary.com/teewhy/image/upload/v1550844527/requisition.png" />
                      </span>
                      <span className="font13">InterDepartment Purchase Order</span>
                    </Link>
                  </li>

                  <li className="s-sidebar__nav-link">
                    <Link to="/accounts/approved-purchase-order" target="_self" className="text-nowrap">
                      <span className="mr-2">
                        <img alt="" src="https://res.cloudinary.com/teewhy/image/upload/v1550844527/requisition.png" />
                      </span>
                      <span className="font13">Approved Purchase Order</span>
                    </Link>
                  </li>
                </span>
              </>
            ) : null}

            <li className="s-sidebar__nav-link">
              <Link to="/accounts/requisition" target="_self" className="">
                <span className="mr-2">
                  <img alt="" src="https://res.cloudinary.com/teewhy/image/upload/v1550844527/requisition.png" />
                </span>
                Requisition
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              <Link to={PATHS.ACCOUNTS_ALL_APPROVED_REQUISITIONS} target="_self" className="">
                <span className="mr-2">
                  <img alt="" src="https://res.cloudinary.com/teewhy/image/upload/v1550844527/requisition.png" />
                </span>
                All Approved Requisition
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              <Link to={PATHS.ACCOUNTS_PAID_REQUISITION} target="_self" className="">
                <span className="mr-2">
                  <img alt="" src="https://res.cloudinary.com/teewhy/image/upload/v1550844527/requisition.png" />
                </span>
                Paid Requisition
              </Link>
            </li>

            <AccountsBarItem
              url={PATHS.ACCOUNTS_REFUNDS}
              name={span13('Refunds')}
              image="https://res.cloudinary.com/teewhy/image/upload/v1557141046/Schedule.png"
            />
            <AccountsBarItem
              url={PATHS.ACCOUNTS_REFUNDS_ANALYTICS}
              name={span13('Refunds Analytics')}
              image="https://res.cloudinary.com/teewhy/image/upload/v1557141046/Schedule.png"
            />

            <span className="s-sidebar__nav-link d-none d-md-block" data-toggle="collapse" data-target="#general">
              <AccountsBarItem
                url="#"
                name="GENERAL"
                image="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-6.png"
              />
            </span>
            <span id="general" className="collapse base-li top-bottom">
              <AccountsBarItem
                url="/accounts/kpi-monitor"
                name={span13('KPI Monitor')}
                image="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg"
              />
              <AccountsBarItem
                url="/accounts/payslip"
                name={span13('PaySlip')}
                image="https://res.cloudinary.com/tizeti/image/upload/v1542971015/Group-7.png"
              />
              {Number(sessionStorage.getItem('roleId')) !== 9 && (
                <AccountsBarItem
                  url="/accounts/purchase-order"
                  name={span13('Purchase Order')}
                  image="https://res.cloudinary.com/teewhy/image/upload/v1550844527/requisition.png"
                />
              )}
              <AccountsBarItem
                url="/accounts/escalate"
                name={span13('Escalate')}
                image="https://res.cloudinary.com/teewhy/image/upload/v1557141046/Schedule.png"
              />
              <AccountsBarItem
                url="/accounts/report-issue"
                name={span13('Report an Issue')}
                image="https://res.cloudinary.com/teewhy/image/upload/v1548923430/r_d-envelop.fw.png"
              />
              <AccountsBarItem
                url="/accounts/learning-portal"
                name={span13('Learning Portal')}
                image="https://res.cloudinary.com/teewhy/image/upload/v1548923430/r_d-envelop.fw.png"
              />
              {Number(role_id) > 2 && (
                <AccountsBarItem
                  url="/accounts/request-feature"
                  name={span13('Request a Feature')}
                  image="https://res.cloudinary.com/teewhy/image/upload/v1548923169/configr_d.fw.png"
                />
              )}
              {Number(role_id) > 8 ? (
                <AccountsBarItem
                  url="/accounts/requests"
                  name={span13('Requests')}
                  image="https://res.cloudinary.com/teewhy/image/upload/v1548923169/configr_d.fw.png"
                />
              ) : (
                <AccountsBarItem
                  url="/accounts/admin-requests"
                  name={span13('Requests')}
                  image="https://res.cloudinary.com/teewhy/image/upload/v1548923169/configr_d.fw.png"
                />
              )}
            </span>
          </span>
        </ul>
      </nav>
    </div>
  );
};

export default AccountsSideBar;
