/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import qs from 'qs';
import LowDataUsageTable from './LowDataUsageTable';
import useUsageStatistics from '../../../../../hooks/shared/customers/useUsageStatistics';

const LowDataUsage = () => {
  const { getCustomersUsage, items, totalPages, isLoading } = useUsageStatistics();

  const [toDate, setToDate] = useState(`${new Date().toISOString().slice(0, 10)}`);
  const [fromDate, setFromDate] = useState(
    `${new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10)}`,
  );
  const [order, setOrder] = useState('asc');
  const [filterby, setFilterBy] = useState('download');
  const [recordsPerPage, setRecordsPerPage] = useState(500);
  const [page] = useState(1);

  const data = qs.stringify({
    action: 'getTopUsersCustomerUsage',
    order: `${order}`,
    pageno: `${page}`,
    no_of_records_per_page: `${recordsPerPage}`,
    startdate: `${fromDate}`,
    enddate: `${toDate}`,
    filterby: `${filterby}`,
  });

  useEffect(() => {
    getCustomersUsage(data);
  }, [data]);

  const onSubmit = (e) => {
    e.preventDefault();
    getCustomersUsage(data);
  };

  const handlePageClick = async (e) => {
    const selectedPage = e.selected + 1;
    const pageData = qs.stringify({
      action: 'getTopUsersCustomerUsage',
      order: `${order}`,
      pageno: `${selectedPage}`,
      no_of_records_per_page: `${recordsPerPage}`,
      startdate: `${fromDate}`,
      enddate: `${toDate}`,
      filterby: `${filterby}`,
    });
    getCustomersUsage(pageData);
  };

  return (
    <div className="pt-3">
      <form className="form-horizontal" onSubmit={onSubmit}>
        <div className="form-group col-12 mb-3">
          <div className="d-flex col-4 mr-3">
            <select
              className="form-control"
              defaultValue={order}
              onChange={(e) => setOrder(e.target.value)}
              name="order"
            >
              <option>Select Order:</option>
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </select>
            <select
              className="form-control ml-4"
              defaultValue={filterby}
              onChange={(e) => setFilterBy(e.target.value)}
              name="filterby"
            >
              <option>Filter By:</option>
              <option value="upload">Upload</option>
              <option value="download">Download</option>
            </select>
          </div>
          <div className="from-group d-flex col-12 mt-3 mr-3">
            <p className="d-flex">
              <small className="text-nowrap copy-font">Show From: </small>
              <input
                type="date"
                className="form-control ml-2"
                name="fromDate"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
            </p>

            <p className="d-flex">
              <small className="ml-4 copy-font">To: </small>
              <input
                type="date"
                className="form-control ml-2"
                name="toDate"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
            </p>

            <p className="d-flex">
              <small className="text-nowrap ml-4 copy-font" htmlFor="recordsPerPage">
                Records Per Page:
              </small>
              <input
                type="number"
                className="form-control ml-2"
                name="recordsPerPage"
                id="recordsPerPage"
                value={recordsPerPage}
                onChange={(e) => setRecordsPerPage(e.target.value)}
              />
            </p>
          </div>

          <div className="ml-3">
            <button className="btn btn-green shadow-md" type="submit">
              Search
            </button>
          </div>
        </div>
      </form>

      <LowDataUsageTable items={items} isLoading={isLoading} />
      {totalPages > 1 ? (
        <ReactPaginate
          previousLabel="previous"
          nextLabel="next"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={totalPages}
          marginPagesDisplayed={3}
          pageRangeDisplayed={4}
          onPageChange={handlePageClick}
          containerClassName="pagination"
          subContainerClassName="pages pagination"
          activeClassName="active"
        />
      ) : null}
    </div>
  );
};

export default LowDataUsage;
