/* eslint-disable @typescript-eslint/camelcase */
import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { CustomerProps } from './MailBlastBodyTable';
import AppContext from '../../../../../context/shared/AppContext';
import MailBlastForm from './MailBlastForm';

const SendBulkEmail = ({ show, onClose, data }) => {
  const { editorState, subject, onSubjectChange, onEditorStateChange } = useContext(AppContext);
  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ paddingLeft: '2rem' }}>Send Bulk Emails</Modal.Title>
      </Modal.Header>
      <MailBlastForm
        subject={subject}
        onSubjectChange={onSubjectChange}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        type="bulk"
        data={data}
      />
    </Modal>
  );
};

export default SendBulkEmail;
