import React, { Component } from 'react';
import '../../../../../styles/style.css';
import '../../../../../styles/basestation.css';
import axios from 'axios';
import toastr from 'toastr';
import { Link } from 'react-router-dom';
import Loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import { API_URL, SALES } from '../../../../../utils/config';
import SalesSideBar from '../../../../layouts/sales/SalesSideBar';
import Logout from '../../../shared/auth/Logout';
import { SUPER_ADMIN, VICE_PRESIDENT_SALES } from '../../../../../utils/departments/helpers';

class SalesBaseStations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      basestations: [],
      basestation: '',
      filtered: [],
      isLoading: false,
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      out: false,
      move: false,
    };

    this.onKeyPress = this.onKeyPress.bind(this);
  }

  onKeyPress = (e) => {
    if (e.which === 13) {
      const base = this.state.basestations.filter((basestation) => {
        if (basestation.name.indexOf(e.target.value) !== -1) {
          return basestation;
        }
        if (basestation.address.indexOf(basestation) !== -1) {
          return basestation;
        }
      });

      this.setState({
        filtered: base,
      });
    }
  };

  getUserDetails() {
    const token = sessionStorage.getItem('token');

    axios({
      method: 'get',
      url: `${API_URL}/employee/loggedOnEmployee`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        this.setState({
          user: data.data,
        });
      })
      .catch((err) => {});
  }

  componentWillMount() {
    this.fetchBaseStations();
    this.getUserDetails();
    this.fetchLoggedOnUser();
  }

  renderMap = () => {
    // https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&libraries=places&callback=initMa

    loadScript(
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyD1DrDBUd6GNL2EIBCxK-K0OjkTny8kbuA&libraries=places&callback=initMap',
    );
    window.initMap = this.initMap;
  };

  fetchBaseStations() {
    axios({
      method: 'get',
      url: `${API_URL}/baseStation`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    }).then((res) => {
      this.setState(
        {
          basestations: res.data,
        },
        this.renderMap(),
      );
    });
  }

  componentWillReceiveProps() {
    this.fetchLoggedOnUser();
  }

  fetchLoggedOnUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employeeReg/logged`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        if (
          data.data.department_id !== `${SALES}` &&
          data.data.department_id !== `${SUPER_ADMIN}` &&
          data.data.department_id !== `${VICE_PRESIDENT_SALES}`
        ) {
          sessionStorage.setItem('data', '');
          sessionStorage.clear();
          this.setState({
            out: true,
          });

          toastr.info('You are not allowed to view this page');
        }
      })
      .catch((err) => {});
  }

  initMap = () => {
    const map = new window.google.maps.Map(document.getElementById('map'), {
      center: { lat: 6.548653, lng: 3.54882 },
      zoom: 11,
      maxZoom: 0, // 18
      minZoom: 0, // 16
      zoomControlOptions: {
        style: window.google.maps.ZoomControlStyle.SMALL,
      },
      scaleControl: true, // enable scale control
      mapTypeId: window.google.maps.MapTypeId.HYBRID, // google map type
    });

    // var searchBox = new window.google.maps.places.SearchBox(input);
    // var searchBox = new window.google.maps.places.SearchBox(input);

    const infowindow = new window.google.maps.InfoWindow();

    this.state.basestations.map((basestation) => {
      const contentString = `${basestation.id}`;

      const marker = new window.google.maps.Marker({
        position: { lat: basestation.latitude, lng: basestation.longitude },
        map,
        // icon: 'https://res.cloudinary.com/tizeti/image/upload/v1542971012/Group-1.png',
        icon: 'http://www.my9ja.com/estatewifi/icons/pin_green.png',
        title: basestation.name,
        // animation: window.google.maps.Animation.BOUNCE
      });

      const circle = new window.google.maps.Circle({
        map,
        radius: 1500, // 1.5 kilometeres in metres

        fillColor: '#00FF00',
        // strokeColor: '#FF0000',
        strokeOpacity: 0.2,
        strokeWeight: 0.12,
        fillOpacity: 0.25,
      });

      circle.bindTo('center', marker, 'position');

      marker.addListener('click', function() {
        // chnage content
        infowindow.setContent(contentString);
        infowindow.open(map, marker);
      });
    });
  };

  render() {
    const { user, filtered, isLoading } = this.state;

    const { firstName } = user;

    if (isLoading) {
      return <img src={Loader} alt="" />;
    }

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <Link className="s-sidebar__trigger" to="#">
                <i className="fa fa-bars mr-3" />
              </Link>
              <SalesSideBar />

              <h3 className="col-lg-6 overview-part">Basestations</h3>

              <div className="d-flex profile-mail col-6">
                <h6>
                  <i className="mr-auto">{firstName} </i>
                  <Logout />
                </h6>
              </div>
            </div>
          </div>

          <div className="col-12 after-summary">
            <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-3">
              <div className="row">
                <div className="container-fluid stated">
                  {/* <input id="pac-input" className="controls" type="text" placeholder="Search Box"/> */}
                  <div id="map" />

                  <div className="frame">
                    <div className="containing col-lg-12 col-md-12 col-sm-12">
                      <input
                        className="form-control mr-sm-2 empty"
                        id="iconified"
                        type="text"
                        placeholder="Search by name address and installer"
                        onKeyPress={this.onKeyPress}
                      />

                      {filtered.map((basestation) => (
                        <div>
                          <div className="info bg-white mt-3 p-3">
                            <label className="copy-font">BaseStation name</label>
                            <p className="copy-font">{basestation.name}</p>

                            <label className="copy-font">Address</label>
                            <p className="copy-font">{basestation.address}</p>

                            <label className="copy-font">Installer</label>
                            <p className="copy-font">{basestation.installer}</p>
                            <label className="copy-font">Date of commissioning</label>
                            <p className="copy-font">{basestation.created_at}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

function loadScript(url) {
  const index = window.document.getElementsByTagName('script')[0];
  const script = window.document.createElement('script');
  script.src = url;
  script.async = true;
  script.defer = true;
  index.parentNode.insertBefore(script, index);
}

export default SalesBaseStations;
