import React from 'react';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import BusinessDevelopmentNavBar from '../../../../layouts/business-development/BusinessDevelopmentNavBar';
import InProgress from './InProgress';

const BusinessDevelopmentOverview = () => {
  const { profile } = useUser();
  const { firstName } = profile;
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <BusinessDevelopmentNavBar currentPage="List of Invoices" position={firstName} />
        {/* <BusinessDevelopmentInvoiceBody /> */}
        <InProgress />
      </main>
    </div>
  );
};

export default BusinessDevelopmentOverview;
