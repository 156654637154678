import React, { useContext } from 'react';
import GeniatechContext from '../../../../../../../context/GeniatechContext';
import helpers from '../../../../../../../utils/helpers';
import loader from '../../../../../../../assets/img/icons/TizetiLoaderColored.gif';

const SiteTable = () => {
  const { loading, fetched } = useContext(GeniatechContext);
  const tableData =
    fetched && fetched.length
      ? fetched.map((row, i) => (
          <tr className="text-nowrap" key={row.id}>
            <td>{i + 1}</td>
            <td>{row.name}</td>
            <td>{row.network}</td>
            <td>{row.location.type}</td>
            <td>{row.location.coordinates}</td>
            <td>{row.managed_account}</td>
            <td>{helpers.formatDateAndTime(row.created_at)}</td>
          </tr>
        ))
      : 'no site';
  return (
    <div className="card x-panel mt-2 px-0">
      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th className="text-nowrap">Network</th>
              <th>Location Type</th>
              <th>Location Cordinates</th>
              <th>Managed Account</th>
              <th>Date Created</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <img src={loader} alt="loading gif" />
              </tr>
            ) : (
              tableData
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SiteTable;
