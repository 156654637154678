/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import toastr from 'toastr';
import { getUser } from '../../../../../actions/shared/user/UserActions';
import DedicatedCustomersBody from '../../../shared/dedicated-customers/DedicatedCustomersBody';
import axios from '../../field-support/usage-history/FieldSupportUsageHistory';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import { NOC } from '../../../../../utils/config';
import NocNavBar from '../../../../layouts/noc/NocNavBar';
import { SUPER_ADMIN } from '../../../../../utils/departments/helpers';

class DedicatedCustomers extends Component {
  componentDidMount() {
    this.props.getUser();
    this.checkDepartment();
  }

  componentWillReceiveProps(nextProps) {
    this.checkDepartment();
  }

  checkDepartment = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.department_id !== `${NOC}` && response.data.department_id !== `${SUPER_ADMIN}`) {
        this.props.history.push('/');
        sessionStorage.setItem('token', '');
        sessionStorage.clear();
        toastr.info('You are not allowed to view this page');
      }
    } catch (e) {
      // console.log(e.response);
    }
  };

  render() {
    const { user } = this.props;
    const { firstName } = user;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <NocNavBar currentPage="Dedicated Customers" position={firstName} />

          <div className="container">
            <div className="row mt-5">
              <div className="mt-4 col-lg-12 col-md-12 col-sm-12">
                <DedicatedCustomersBody />
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect(mapStateToProps, { getUser })(DedicatedCustomers);
