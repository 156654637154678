import React from 'react';
import useIpAllocation from '../hooks/useIpAllocation';
import AppContext from '../../../../../../../context/shared/AppContext';
import { IP_ALLOCATION_URL } from '../api/IpAllocationUrl';

const IpAllocationProvider = ({ children }) => {
  const { data, loading, paginateChange, loadingForm, onChange, formValue, onSubmit, pages } = useIpAllocation(
    `${IP_ALLOCATION_URL}`,
  );

  return (
    <AppContext.Provider
      value={{
        data,
        loading,
        paginateChange,
        loadingForm,
        onChange,
        formValue,
        onSubmit,
        pages,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export default IpAllocationProvider;
