import { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const useStates = () => {
  const [states, setStates] = useState([]);
  console.log({ newstates: states });
  useEffect(() => {
    async function getStates() {
      try {
        return await axios.get(`https://legacy.tizeti.com/tools/index.php`);
      } catch (e) {
        return e;
      }
    }
    getStates()
      .then((res) => {
        if (res.data) {
          console.log(res.data);
          setStates(res.data);
        }
      })
      .catch(() => {
        toast.info('Something went wrong while getting states');
      });
  }, []);
  return { states };
};
export default useStates;
