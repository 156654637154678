import React from 'react';
import KpiPercentage from './KpiPercentage';
import SearchKpi from './SearchKpi';
import AddKpiBody from './AddKpiBody';

const SearchAndAddKpi = () => {
  return (
    <div className="col-lg-12 col-12 d-flex mt-3 mb-5">
      <KpiPercentage />
      <div className="col-lg-4 p-0 d-flex justify-content-end mt-4">
        <SearchKpi />
        <AddKpiBody />
      </div>
    </div>
  );
};

export default SearchAndAddKpi;
