/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

const NewDesignViewKyc = ({
  request: {
    installationUploads: [{ user_kyc, contact_information_image, pole_image, img_4, sla }],
  },
}) => {
  return (
    <div id="viewcs" className="modal fade" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header shadow">
            <h4 className="modal-title text-center" id="myModalLabel">
              KYC Viewer
            </h4>
            <button type="button" className="close mr-2" data-dismiss="modal">
              x
            </button>
          </div>
          <div className="modal-body">
            <div className="col-12 mb-3 d-flex">
              {user_kyc === null ? (
                'No Record'
              ) : (
                <div>
                  <img src={user_kyc} className="img-fluid" alt="test" />
                </div>
              )}
            </div>

            <div className="col-12 mb-3 d-flex">
              {contact_information_image === null ? (
                'No Record'
              ) : (
                <div>
                  <img src={contact_information_image} className="img-fluid" alt="test" />
                </div>
              )}
            </div>

            <div className="col-12 mb-3 d-flex">
              {img_4 === null ? (
                'No Record'
              ) : (
                <div>
                  <img src={img_4} className="img-fluid" alt="test" />
                </div>
              )}
            </div>

            <div className="col-12 mb-3 d-flex">
              {/* {pole_image.split('.').pop() === 'pdf' ? (
                  <iframe src={pole_image} width="100%" height="500px">
                  </iframe> */}
              {pole_image === null ? (
                'No Record'
              ) : (
                <div>
                  <img src={pole_image} className="img-fluid" alt="test" />
                </div>
              )}
            </div>

            <div className="col-12 mb-3 d-flex">
              {sla === null ? (
                ''
              ) : (
                <div>
                  <img src={sla} className="img-fluid" alt="test" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

NewDesignViewKyc.propTypes = {
  request: PropTypes.objectOf.isRequired,
};

export default NewDesignViewKyc;
