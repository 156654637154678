/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import React, { useContext } from 'react';
import loader from '../../../../../../../assets/img/icons/TizetiLoaderColored.gif';
import GeniatechContext from '../../../../../../../context/GeniatechContext';
import { LEGACY_GENIATECH_URL } from '../../../../../../../services/api/shared/installations/InstallationsUrl';
// import { StaticAccessPointsProps } from '../types/StaticAccessPointsProps';
import './index.css';

const StaticAccessPointTable = () => {
  const { fetching, fetched, searchPerformance } = useContext(GeniatechContext);
  const getInformation = (params) => {
    searchPerformance(
      {
        action: 'device_statistics',
        macaddress: params.mac,
      },
      `${LEGACY_GENIATECH_URL}`,
    );
  };
  const tableData =
    fetched && fetched.access_points && fetched.access_points.length > 0
      ? fetched.access_points.map((row, i) => (
          <tr className="text-nowrap" key={i + 1}>
            <td>{i + 1}</td>
            <td>{row.id}</td>
            <td>{row.name}</td>
            <td>{row.mac}</td>
            <td onClick={() => getInformation(row)} className="performance">
              Show Performance
            </td>
          </tr>
        ))
      : 'no static access point';
  return (
    <div className="card x-panel mt-2 px-0">
      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>

              <th>ID</th>
              <th className="text-nowrap">Name</th>
              <th>Mac</th>
              <th>Show Performance</th>
            </tr>
          </thead>
          <tbody>
            {fetching ? (
              <tr>
                <img src={loader} alt="loading gif" />
              </tr>
            ) : (
              tableData
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StaticAccessPointTable;
