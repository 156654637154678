import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  LabelList,
} from 'recharts';
import Box from '@mui/material/Box';
import { TableCellMonths } from '../components/tempData';
export default function BarCharts({ data, isLoading }) {
  const [getData, setGetData] = React.useState([]);

  React.useEffect(() => {
    if (data.length > 0) {
      const refinedData = TableCellMonths.map((months, index) => ({
        months: months,
        '2023-total-turbo-inst-rev': data[0].values[index],
      }));

      setGetData(refinedData);
    }
  }, [data]);

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flexStart" sx={{ pl: 5 }}>
      {!isLoading && (
        <ResponsiveContainer width="100%" height={600}>
          <BarChart
            data={getData}
            margin={{
              top: 5,
              right: 10,
              left: 60,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="months" label="Months" />
            <YAxis>
              <Label
                value="Turbo Connect Installations Revenue"
                position="insideLeft"
                angle={-90}
                style={{ textAnchor: 'start' }}
                offset={-40}
              />
            </YAxis>
            <Tooltip />
            <Legend />

            <Bar type="monotone" dataKey="2023-total-turbo-inst-rev" fill="#8884d8">
              <LabelList dataKey="2023-total-turbo-inst-rev" position="top" />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
    </Box>
  );
}
