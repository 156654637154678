import axios from 'axios';
import { toast } from 'react-toastify';
import { useReducer } from 'react';
import { GET_ANALYTICS_URL } from '../../../services/api/business-development/overview/AnalyticsUrl';
import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';
import { GET_ANALYTICS } from '../../../constants/types/business-development/overview/AnalyticsTypes';
import AnalyticsReducer from '../../../reducers/business-development/overview/AnalyticsReducer';

const useBusinessDevelopmentAnalytics = () => {
  const [{ totalActive, totalExpired, isLoading }, dispatch] = useReducer(AnalyticsReducer, {
    totalActive: '',
    totalExpired: '',
    isLoading: false,
  });

  const getAnalytics = async (data) => {
    dispatch({ type: LOADING_STARTS });
    try {
      const response = await axios.post(`${GET_ANALYTICS_URL}`, data);
      if (response.data) {
        // console.log(response.data.total_expired_customers);
        // console.log(response.data.total_active_customers);
        dispatch({
          type: GET_ANALYTICS,
          payload: {
            totalExpired: response.data.total_expired_customers,
            totalActive: response.data.total_active_customers,
          },
        });
      }
    } catch (e) {
      toast.info('Something went wrong!');
      if (e.response) {
        dispatch({ type: LOADING_STOPS });
      }
    }
  };

  return {
    getAnalytics,
    totalExpired,
    totalActive,
    isLoading,
  };
};

export default useBusinessDevelopmentAnalytics;
