/* eslint-disable react/prop-types */
import React from 'react';

const SearchPaidRequisition = ({ searchPaidRequisition, setPurpose }) => {
  const page = 1;

  const onSubmitSearch = (e) => {
    e.preventDefault();
    searchPaidRequisition(page);
  };

  return (
    <div className="col-9 pt-5">
      <form onSubmit={onSubmitSearch} className="inner-addon d-flex">
        <div className="input-group mb-3 mr-3">
          <input
            className="form-control mr-auto empty pl-5"
            type="text"
            placeholder="search by purpose"
            name="keyword"
            onChange={(e) => setPurpose(e.target.value)}
          />
          <span className="fa fa-search" />
        </div>
        <div className="">
          <button type="submit" className="btn btn-green">
            Search
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchPaidRequisition;
