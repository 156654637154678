/* eslint-disable @typescript-eslint/camelcase */
import { useState, useEffect, useReducer } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import qs from 'qs';
import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';
import {
  START_ADD_CALL_LOG,
  GET_CALL_LOGS,
  STOP_ADD_CALL_LOG,
  REFETCH_CALL_LOGS,
} from '../../../constants/types/customer-support/CustomerSupportTypes';
import { callLogReducer } from '../../../reducers/customer-support/call-logs/callLogReducer';
import { CREATE_CALL_LOG_URL, GET_CALL_LOGS_URL } from '../../../services/api/customer-support/call-logs/CallLogUrl';

const useCallLogs = () => {
  const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    note: '',
    resolution: '',
  };
  const [callLog, setCallLog] = useState(initialState);
  const [pageNumber, setPageNumber] = useState(1);
  const [{ submitting, callLogs, loading }, dispatch] = useReducer(callLogReducer, {
    submitting: false,
    callLogs: [],
    loading: false,
  });
  const onChange = (e) => {
    const { name, value } = e.target;
    setCallLog({ ...callLog, [name]: value });
  };
  const createCallLog = async (e) => {
    dispatch({ type: START_ADD_CALL_LOG });
    e.preventDefault();

    try {
      const response = await axios.post(`${CREATE_CALL_LOG_URL}`, callLog, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.id) {
        toast.success(`${callLog.firstName} ${callLog.lastName} has been added to the logs!`);
        dispatch({ type: REFETCH_CALL_LOGS, payload: [response.data, ...callLogs] });
        setCallLog(initialState);
      }
    } catch (error) {
      dispatch({ type: STOP_ADD_CALL_LOG });
    }
  };
  useEffect(() => {
    async function getDedicatedCustomers() {
      dispatch({ type: LOADING_STARTS });
      try {
        return await axios.post(
          `${GET_CALL_LOGS_URL}${pageNumber}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
          },
        );
      } catch (error) {
        return error;
      }
    }
    getDedicatedCustomers()
      .then((response) => {
        if (response.data.list) {
          dispatch({ type: GET_CALL_LOGS, payload: response.data });
        }
        dispatch({ type: LOADING_STOPS });
      })
      .catch((error) => dispatch({ type: LOADING_STOPS }));
  }, [pageNumber]);
  return { submitting, callLogs, loading, callLog, onChange, createCallLog };
};
export default useCallLogs;
