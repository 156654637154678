/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-empty */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import '../../../../../styles/style.css';
import '../../../../../styles/basestation.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';
import PropTypes from 'prop-types';
import FieldSupportSideBar from '../../../../layouts/field-support/side-bar/FieldSupportSideBar';
import Logout from '../../../shared/auth/Logout';
import { getUser } from '../../../../../actions/shared/user/UserActions';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import { admin, operations, TECHNICAL_SUPPORT } from '../../../../../utils/config';
import NoLos from '../../../shared/field-support/no-los/NoLos';

class FieldSupportNoLos extends Component {
  componentDidMount() {
    this.checkDepartment();
    // eslint-disable-next-line react/destructuring-assignment
    this.props.getUser();
  }

  componentWillReceiveProps() {
    this.checkDepartment();
  }

  // eslint-disable-next-line no-undef
  checkDepartment = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (
        response.data.department_id !== `${operations}` &&
        response.data.department_id !== `${TECHNICAL_SUPPORT}` &&
        response.data.department_id !== `${admin}`
      ) {
        sessionStorage.setItem('token', '');
        sessionStorage.clear();

        this.props.history.push('/');

        toast.info('You are not allowed to view this page');
      }
    } catch (e) {}
  };

  render() {
    const { user } = this.props;
    const { firstName } = user;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <Link className="s-sidebar__trigger" to="#">
                <i className="fa fa-bars mr-3" />
              </Link>
              <FieldSupportSideBar />

              <h3 className="col-lg-6 overview-part">No LOS</h3>

              <div className="d-flex profile-mail col-6">
                <h6>
                  {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                  <i className="mr-auto ">{firstName} </i>
                  <Logout />
                </h6>
              </div>
            </div>
          </div>

          <NoLos />
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

FieldSupportNoLos.propTypes = {
  user: PropTypes.shape(),
  getUser: PropTypes.func,
};

export default connect(
  mapStateToProps,
  { getUser },
)(FieldSupportNoLos);
