/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import usePost from '../../../../../hooks/shared/custom/data/geniatech/usePost';
import { GET_ROUTER_URL } from '../../../../../services/api/shared/BasestationsUrl';
import DelistedCustomersTable from './DelistedCustomersTable';
import SearchDelistedCustomers from './SearchDelistedCustomers';
import ReactPaginate from 'react-paginate';

const DelistedCustomersBody = () => {
  const { data, loading, getDataLegacy } = usePost();

  const searchState = {
    searchValue: '',
    fromDate: '',
    toDate: '',
  };
  const [search, setSearch] = useState(searchState);
  const [result, setResult] = useState([]);
  const [pageno, setPageno] = useState(1);

  const { searchValue, fromDate, toDate } = search;

  useEffect(() => {
    const action = {
      action: 'fetch_delisted_customers',
    };
    getDataLegacy(`${GET_ROUTER_URL}`, action);
  }, []);

  useEffect(() => {
    if (data && data.items) setResult(data.items);
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch({ ...search, [name]: value });
  };

  const handlePageClick = (e) => {
    const pageno = e.selected + 1;
    setPageno(pageno);
    const action = {
      action: 'fetch_delisted_customers',
      pageno,
    };
    getDataLegacy(`${GET_ROUTER_URL}`, action);
  };

  const filterDelisted = (e) => {
    e.preventDefault();
    setPageno(1);
    const params = {
      action: 'search_delisted_customers',
      search_value: searchValue,
      from: fromDate,
      to: toDate,
    };
    getDataLegacy(`${GET_ROUTER_URL}`, params);
  };

  return (
    <div className="mt-5 px-3">
      <div className="container-fluid pt-5">
        <div className="row">
          <SearchDelistedCustomers handleChange={handleChange} search={search} filterDelisted={filterDelisted} />
          <DelistedCustomersTable lists={result} loading={loading} pageno={pageno} />
          <div className="px-3">
            {data.pages > 1 && (
              <ReactPaginate
                previousLabel="previous"
                nextLabel="next"
                breakLabel="..."
                breakClassName="break-me"
                pageCount={data.pages}
                marginPagesDisplayed={3}
                pageRangeDisplayed={4}
                onPageChange={handlePageClick}
                containerClassName="pagination"
                // subContainerClassName="pages pagination"
                activeClassName="active"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DelistedCustomersBody;
