/* eslint-disable import/prefer-default-export */
import { useReducer, useEffect } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { GET_CLASSIFICATION_URL } from '../../../services/api/procurement/TracklistURL';
import * as types from '../../../constants/types/procurement/ProcurementTypes';
import * as load from '../../../constants/types/shared/LoadingTypes';
import { classificationReducer } from '../../../reducers/procurement/classification/classificationReducer';

export const useClassification = () => {
  const [{ classification, isLoading }, dispatch] = useReducer(classificationReducer, {
    classification: [],
    isLoading: false,
  });

  const getClassification = async () => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.get(`${GET_CLASSIFICATION_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_CLASSIFICATION,
          payload: response.data,
        });
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  useEffect(() => {
    getClassification();
  }, []);

  return {
    classification,
    isLoading,
    getClassification,
  };
};
