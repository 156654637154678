import React from 'react';

const TotalSignUps = () => {
  return (
    <div className="col-6">
      <h6>
        <b>Total Sign ups</b>
      </h6>
      <small>Showing statistic of all time</small>
      <div className="card o-income card-body">
        <div id="call-frequency" />
      </div>
    </div>
  );
};

export default TotalSignUps;
