/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../utils/loader/loadingHelper';


const InstallationSummaryFailedTable = ({ loads, rows }) => {
  console.log(rows, 'this is', loads);
  const colSpan = '8';
  const installsTable = () => {
    if (rows) {
      return (
        <div className="ml-4 table-responsive col-12">
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Payment REF</th>
                <th>User Address</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Zone</th>
              </tr>
            </thead>
            <tbody>
              {loads ? (
                <TizetiGIFLogoTable colSpan={colSpan} />
              ) : rows && rows.length > 0 ? (
                rows.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{item.user.firstName + ' ' + item.user.lastName}</td>
                      <td>{item.payment_reference}</td>
                      <td>{item.address}</td>
                      <td>{item.user.email}</td>
                      <td>{item.user.phoneNumber}</td>
                      <td>{item.zone.name}</td>
                    </tr>
                  );
                })
              ) : (
                <NoItemRow colSpan={colSpan} text="No Failed Installations" />
              )}
            </tbody>
          </table>
        </div>
      );
    }
    return <TizetiGIFLogoTable colSpan={colSpan} />;
  };

  return installsTable();
};

export default InstallationSummaryFailedTable;
