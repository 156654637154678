/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';

const ExpiredZonesTable = ({ items }) => {
  const tableData =
    items && items.length ? (
      items.map((item, i) => {
        const { zone, expired_count } = item;
        return (
          <tr className="text-nowrap" key={i}>
            <td>{i + 1}</td>
            <td>{zone.toUpperCase()}</td>
            <td>{expired_count}</td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="3" className="text-center">
          No Expired Customers
        </td>
      </tr>
    );

  return tableData;
};

export default ExpiredZonesTable;
