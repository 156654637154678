import React from 'react';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../../../utils/loader/loadingHelper';

const tabIndex = -1;
const colSpan = '4';
const AssignedEmployees = ({ install, assignedStaffRows, assignedStaff }) => {
  const tableData =
    assignedStaffRows && assignedStaffRows.length ? (
      assignedStaffRows.map((person, id) => (
        <tr key={person.employee.id}>
          <td>{id + 1}</td>
          <td>
            {person.employee.firstName} {person.employee.lastName}
          </td>
          <td>{person.employee.email}</td>
          <td>{person.employee.phoneNumber}</td>
        </tr>
      ))
    ) : (
      <NoItemRow colSpan={colSpan} text="Not Assigned Yet" />
    );

  return (
    <div
      id="assigned-installation-details"
      className="modal fade in"
      tabIndex={tabIndex}
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog mt-3">
        <div className="modal-content" style={{ width: '115%' }}>
          <div className="modal-header">
            <h4 className="modal-title text-center" style={{ marginLeft: '11%' }} id="myModalLabel">
              {`Assigned Employees for ${install.firstName || install.customer_name}`}
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body">
            <table className="col-lg-12 ml-2 mt-2">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone number</th>
                </tr>
              </thead>
              <tbody>{assignedStaff ? <TizetiGIFLogoTable colSpan={colSpan} /> : tableData}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignedEmployees;
