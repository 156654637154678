import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import format from 'date-fns/format';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Skeleton from '@mui/material/Skeleton';
import useInstallationsSummary from '../../../../../../hooks/field-support/installations-summary/useInstallationsSummary';
import TargetChartContainer from './TargetChartContainer';

export default function InstallationTargetByState() {
  const [month, setMonth] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);
  const [monthStartDate, setMonthStartDate] = React.useState();
  const [monthEndDate, setMonthEndDate] = React.useState();

  const {
    lagosDailyCompleted,
    abujaDailyCompleted,
    ogunDailyCompleted,
    deltaDailyCompleted,
    enuguDailyCompleted,
    riversDailyCompleted,
    edoDailyCompleted,
    oyoDailyCompleted,
    kanoDailyCompleted,
    kadunaDailyCompleted,
    imoDailyCompleted,
    abiaDailyCompleted,
    anambraDailyCompleted,
    lagosMonthlyCompleted,
    abujaMonthlyCompleted,
    ogunMonthlyCompleted,
    deltaMonthlyCompleted,
    enuguMonthlyCompleted,
    riversMonthlyCompleted,
    edoMonthlyCompleted,
    oyoMonthlyCompleted,
    kanoMonthlyCompleted,
    kadunaMonthlyCompleted,
    imoMonthlyCompleted,
    abiaMonthlyCompleted,
    anambraMonthlyCompleted,
    lagosMonthlyTarget,
    abujaMonthlyTarget,
    ogunMonthlyTarget,
    enuguMonthlyTarget,
    deltaMonthlyTarget,
    riversMonthlyTarget,
    edoMonthlyTarget,
    oyoMonthlyTarget,
    kanoMonthlyTarget,
    kadunaMonthlyTarget,
    imoMonthlyTarget,
    abiaMonthlyTarget,
    anambraMonthlyTarget,
    getStateMonthlyCompletedInstallation,
  } = useInstallationsSummary();

  const lagosMonthlyTargetCount = () => (lagosMonthlyTarget.length ? lagosMonthlyTarget[0].state_target : 0);
  const abujaMonthlyTargetCount = () => (abujaMonthlyTarget.length ? abujaMonthlyTarget[0].state_target : 0);
  const ogunMonthlyTargetCount = () => (ogunMonthlyTarget.length ? ogunMonthlyTarget[0].state_target : 0);
  const riversMonthlyTargetCount = () => (riversMonthlyTarget.length ? riversMonthlyTarget[0].state_target : 0);
  const edoMonthlyTargetCount = () => (edoMonthlyTarget.length ? edoMonthlyTarget[0].state_target : 0);
  const oyoMonthlyTargetCount = () => (oyoMonthlyTarget.length ? oyoMonthlyTarget[0].state_target : 0);
  const deltaMonthlyTargetCount = () => (deltaMonthlyTarget.length ? deltaMonthlyTarget[0].state_target : 0);
  const enuguMonthlyTargetCount = () => (enuguMonthlyTarget.length ? enuguMonthlyTarget[0].state_target : 0);
  const kanoMonthlyTargetCount = () => (kanoMonthlyTarget.length ? kanoMonthlyTarget[0].state_target : 0);
  const kadunaMonthlyTargetCount = () => (kadunaMonthlyTarget.length ? kadunaMonthlyTarget[0].state_target : 0);
  const imoMonthlyTargetCount = () => (imoMonthlyTarget.length ? imoMonthlyTarget[0].state_target : 0);
  const abiaMonthlyTargetCount = () => (abiaMonthlyTarget.length ? abiaMonthlyTarget[0].state_target : 0);
  const anambraMonthlyTargetCount = () => (anambraMonthlyTarget.length ? anambraMonthlyTarget[0].state_target : 0);
  const counts = {
    dailyInstallation: [
      lagosDailyCompleted,
      abujaDailyCompleted,
      ogunDailyCompleted,
      riversDailyCompleted,
      edoDailyCompleted,
      oyoDailyCompleted,
      deltaDailyCompleted,
      enuguDailyCompleted,
      kanoDailyCompleted,
      kadunaDailyCompleted,
      imoDailyCompleted,
      abiaDailyCompleted,
      anambraDailyCompleted,
    ],
    monthlyTargetCount: [
      lagosMonthlyTargetCount(),
      abujaMonthlyTargetCount(),
      ogunMonthlyTargetCount(),
      riversMonthlyTargetCount(),
      edoMonthlyTargetCount(),
      oyoMonthlyTargetCount(),
      deltaMonthlyTargetCount(),
      enuguMonthlyTargetCount(),
      kanoMonthlyTargetCount(),
      kadunaMonthlyTargetCount(),
      imoMonthlyTargetCount(),
      abiaMonthlyTargetCount(),
      anambraMonthlyTargetCount(),
    ],
    monthlyCountCompleted: [
      lagosMonthlyCompleted,
      abujaMonthlyCompleted,
      ogunMonthlyCompleted,
      riversMonthlyCompleted,
      edoMonthlyCompleted,
      oyoMonthlyCompleted,
      deltaMonthlyCompleted,
      enuguMonthlyCompleted,
      kanoMonthlyCompleted,
      kadunaMonthlyCompleted,
      imoMonthlyCompleted,
      abiaMonthlyCompleted,
      anambraMonthlyCompleted,
    ],
  };
  const lagosPercent = () => {
    const result = (lagosMonthlyCompleted / lagosMonthlyTargetCount()) * 100;
    if (isNaN(result) || result === Infinity) return 0;
    return Math.round(result);
  };

  const abujaPercent = () => {
    const result = (abujaMonthlyCompleted / abujaMonthlyTargetCount()) * 100;
    if (isNaN(result) || result === Infinity) return 0;
    return Math.round(result);
  };
  const ogunPercent = () => {
    const result = (ogunMonthlyCompleted / ogunMonthlyTargetCount()) * 100;
    if (isNaN(result) || result === Infinity) return 0;
    return Math.round(result);
  };
  const riversPercent = () => {
    const result = (riversMonthlyCompleted / riversMonthlyTargetCount()) * 100;
    if (isNaN(result) || result === Infinity) return 0;
    return Math.round(result);
  };
  const edoPercent = () => {
    const result = (edoMonthlyCompleted / edoMonthlyTargetCount()) * 100;
    if (isNaN(result) || result === Infinity) return 0;
    return Math.round(result);
  };
  const oyoPercent = () => {
    const result = (oyoMonthlyCompleted / oyoMonthlyTargetCount()) * 100;
    if (isNaN(result) || result === Infinity) return 0;
    return Math.round(result);
  };

  const deltaPercent = () => {
    const result = (deltaMonthlyCompleted / deltaMonthlyTargetCount()) * 100;
    if (isNaN(result) || result === Infinity) return 0;
    return Math.round(result);
  };
  const enuguPercent = () => {
    const result = (enuguMonthlyCompleted / enuguMonthlyTargetCount()) * 100;
    if (isNaN(result) || result === Infinity) return 0;
    return Math.round(result);
  };
  const kanoPercent = () => {
    const result = (kanoMonthlyCompleted / kanoMonthlyTargetCount()) * 100;
    if (isNaN(result) || result === Infinity) return 0;
    return Math.round(result);
  };
  const kadunaPercent = () => {
    const result = (kadunaMonthlyCompleted / kadunaMonthlyTargetCount()) * 100;
    if (isNaN(result) || result === Infinity) return 0;
    return Math.round(result);
  };
  const imoPercent = () => {
    const result = (imoMonthlyCompleted / imoMonthlyTargetCount()) * 100;
    if (isNaN(result) || result === Infinity) return 0;
    return Math.round(result);
  };
  const abiaPercent = () => {
    const result = (abiaMonthlyCompleted / abiaMonthlyTargetCount()) * 100;
    if (isNaN(result) || result === Infinity) return 0;
    return Math.round(result);
  };
  const anambraPercent = () => {
    const result = (anambraMonthlyCompleted / anambraMonthlyTargetCount()) * 100;
    if (isNaN(result) || result === Infinity) return 0;
    return Math.round(result);
  };

  // create an array of Month percentage against state
  const installationTargetData = [
    { state: 'Lagos', percentage: lagosPercent() },
    { state: 'Abuja', percentage: abujaPercent() },
    { state: 'Ogun', percentage: ogunPercent() },
    { state: 'Rivers', percentage: riversPercent() },
    { state: 'Edo', percentage: edoPercent() },
    { state: 'Oyo', percentage: oyoPercent() },
    { state: 'Delta', percentage: deltaPercent() },
    { state: 'Enugu', percentage: enuguPercent() },
    { state: 'Kano', percentage: kanoPercent() },
    { state: 'Kaduna', percentage: kadunaPercent() },
    { state: 'Imo', percentage: imoPercent() },
    { state: 'Abia', percentage: abiaPercent() },
    { state: 'Anambra', percentage: anambraPercent() },
  ];

  const dailyCompleted =
    lagosDailyCompleted +
    abujaDailyCompleted +
    ogunDailyCompleted +
    riversDailyCompleted +
    edoDailyCompleted +
    oyoDailyCompleted +
    deltaDailyCompleted +
    kanoDailyCompleted +
    kadunaDailyCompleted +
    imoDailyCompleted +
    abiaDailyCompleted +
    anambraDailyCompleted +
    // crossRiverDailyCompleted +
    enuguDailyCompleted;
  const monthlyCompleted =
    lagosMonthlyCompleted +
    abujaMonthlyCompleted +
    ogunMonthlyCompleted +
    riversMonthlyCompleted +
    edoMonthlyCompleted +
    oyoMonthlyCompleted +
    deltaMonthlyCompleted +
    enuguMonthlyCompleted +
    kanoMonthlyCompleted +
    kadunaMonthlyCompleted +
    imoMonthlyCompleted +
    abiaMonthlyCompleted +
    anambraMonthlyCompleted;
  // crossRiverMonthlyCompleted;
  const monthlyTarget =
    lagosMonthlyTargetCount() +
    abujaMonthlyTargetCount() +
    ogunMonthlyTargetCount() +
    riversMonthlyTargetCount() +
    edoMonthlyTargetCount() +
    oyoMonthlyTargetCount() +
    deltaMonthlyTargetCount() +
    enuguMonthlyTargetCount() +
    kanoMonthlyTargetCount() +
    kadunaMonthlyTargetCount() +
    imoMonthlyTargetCount() +
    abiaMonthlyTargetCount() +
    anambraMonthlyTargetCount();
  // crossRiverMonthlyTargetCount();.

  // Create a new Date object
  const currentDate = new Date();

  // Get the current year
  var currentYear = currentDate.getFullYear();

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const monthIndex = currentDate.getMonth();
  const monthInWords = months[monthIndex];

  const spliDate = (originalString) => {
    const splitArray = originalString.split('/');
    const monthStart = splitArray[0];
    const monthEnd = splitArray[1];
    setMonthStartDate(monthStart);
    setMonthEndDate(monthEnd);
    getStateMonthlyCompletedInstallation(monthStart, monthEnd);
  };

  React.useEffect(() => {
    // Set a timer when the component mounts (on page load)
    setTimeout(() => {
      // Update the state to change the object's state to false
      setIsLoading(false);
    }, 4000);
  }, []);
  return (
    <Box sx={{ ml: '2rem', mt: '2rem', mr: '2rem' }}>
      <Typography variant="h4">Installation target by state</Typography>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          {isLoading ? (
            <>
              <Skeleton width={300} height={100} />
              <Skeleton width={250} />
              <Skeleton width={250} />
            </>
          ) : (
            <div className="">
              <select
                name="month"
                id="month"
                onChange={(e) => spliDate(e.target.value)}
                className="block w-full mt-2 mx-2 px-4 py-2 border rounded appearance-none focus:border-blue-500 focus:outline-none"
              >
                <option value="select month">select month</option>
                <option value={`${currentYear}-01-01/${currentYear}-01-31`}>January</option>
                <option value={`${currentYear}-02-01/${currentYear}-02-28`}>February</option>
                <option value={`${currentYear}-03-01/${currentYear}-03-31`}>March</option>
                <option value={`${currentYear}-04-01/${currentYear}-04-30`}>April</option>
                <option value={`${currentYear}-05-01/${currentYear}-05-31`}>May</option>
                <option value={`${currentYear}-06-01/${currentYear}-06-30`}>June</option>
                <option value={`${currentYear}-07-01/${currentYear}-07-31`}>July</option>
                <option value={`${currentYear}-08-01/${currentYear}-08-31`}>August</option>
                <option value={`${currentYear}-09-01/${currentYear}-09-30`}>September</option>
                <option value={`${currentYear}-10-01/${currentYear}-01-31`}>October</option>
                <option value={`${currentYear}-11-01/${currentYear}-11-30`}>November</option>
                <option value={`${currentYear}-12-01/${currentYear}-12-31`}>December</option>
              </select>
              <Typography sx={{ mt: '1rem' }} variant="p">
                Current Month - {monthInWords.toUpperCase()}
              </Typography>
            </div>
          )}
        </div>
      </Box>
      <Box sx={{ mt: 5 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bolder' }}>State</TableCell>
                {installationTargetData.map((target, index) => (
                  <TableCell sx={{ fontWeight: 'bolder' }} key={index}>
                    {target.state}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bolder' }}>Daily Count</TableCell>
                {counts.dailyInstallation.map((target, index) => (
                  <TableCell sx={{ fontWeight: 'bolder' }} key={index}>
                    {target}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bolder' }}>Monthly Target</TableCell>
                {counts.monthlyTargetCount.map((target, index) => (
                  <TableCell sx={{ fontWeight: 'bolder' }} key={index}>
                    {target}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bolder' }}>Monthly Target Completed</TableCell>
                {counts.monthlyCountCompleted.map((target, index) => (
                  <TableCell sx={{ fontWeight: 'bolder' }} key={index}>
                    {target}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bolder' }}>Target Achieved (per month)</TableCell>
                {installationTargetData.map((target, index) => (
                  <TableCell sx={{ fontWeight: 'bolder' }} key={index}>
                    {target.percentage}%
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <div className="py-3 col-12">
          <ul className="text-uppercase">
            <li>
              {months[new Date().getMonth()]} Target - {monthlyTarget}
            </li>
            <li>Total Daily Installation - {dailyCompleted}</li>
            <li>TOTAL NUMBER OF INSTALLATION FOR THE MONTH - {monthlyCompleted}</li>
          </ul>
        </div>
      </Box>
      <Box>
        <TargetChartContainer data={installationTargetData} isLoading={isLoading} />
      </Box>
    </Box>
  );
}
