import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import { getUser } from '../../../../../actions/shared/user/UserActions';
import {
  getIncidentReportByDate,
  getIncidentReports,
  getPaginatedIncidentReport,
} from '../../../../../actions/noc/incident-reports/IncidentReportActions';
import IncidentReportTableNew from './IncidentReportTableNew';
import AddIncidentReportNew from './AddIncidentReportNew';

const IncidentReportsBodyNew = ({
  user,
  load,
  incidents,
  getIncidentReports,
  getIncidentReportByDate,
  getPaginatedIncidentReport,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [option, setOption] = useState('');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [page] = useState(1);

  useEffect(() => {
    getPaginatedIncidentReport(page);
  }, [getPaginatedIncidentReport]);

  const handlePageClick = async (incident) => {
    const selected = incident.selected + 1;
    if (option.option === 'description') {
      let data = {
        page: selected,

        description: keyword.description,
      };

      getIncidentReports(data);
    } else if (option.option === 'date') {
      const data = { from, to, page: selected };
      getIncidentReportByDate(data);
    } else {
      getPaginatedIncidentReport(selected);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setKeyword({ ...keyword, [name]: value });
  };

  const handleChangeOption = (e) => {
    const { name, value } = e.target;
    setOption({ ...option, [name]: value });
  };

  const onSubmitSearch = (e) => {
    let queryString = new URLSearchParams();
    e.preventDefault();
    let data = {
      page: page,

      description: keyword.description,
    };

    getIncidentReports(data);
  };

  const getIncidentReportsByDate = (e) => {
    e.preventDefault();
    const data = { from, to, page: page };
    getIncidentReportByDate(data);
  };

  return (
    <div className="container main-wrapper">
      <div className="row mt-5">
        <div className="mt-5 d-flex flex-wrap col-lg-12 col-md-12 col-sm-12">
          <form onSubmit={onSubmitSearch} className="inner-addon d-flex">
            <div className="input-group mb-3 mr-3 mt-2">
              <select name="option" onChange={handleChangeOption} className="form-control mr-auto empty mt-1">
                <option value="">Choose field to search from</option>
                <option value="date">Date</option>

                <option value="description">Description</option>
              </select>
            </div>
            {/* {option.option === "cause" && (
            <div className="input-group mb-3 mr-3">
          <input
            className="form-control mr-auto empty pl-5"
            type="text"
            placeholder="search for user"
            name="cause"
            value={keyword.cause}
            onChange={handleChange}
          />
          <span className="fa fa-search" />
        </div>
        )} */}

            {/* {option.option === "impact" && (
            <div className="input-group mb-3 mr-3">
          <input
            className="form-control mr-auto empty pl-5"
            type="text"
            placeholder="search for user"
            name="impact"
            value={keyword.impact}
            onChange={handleChange}
          />
          <span className="fa fa-search" />
        </div>
        )} */}

            {option.option === 'description' && (
              <>
                <div className="input-group mb-3 mr-3">
                  <input
                    className="form-control mr-auto empty pl-5"
                    type="text"
                    placeholder="search for user"
                    name="description"
                    value={keyword.description}
                    onChange={handleChange}
                  />
                  <span className="fa fa-search" />
                </div>
                <div className="my-1 mt-2">
                  <button type="submit" className="btn btn-green mr-3">
                    Search
                  </button>
                </div>
              </>
            )}
          </form>
          {/* <form className="d-flex flex-wrap ml-4" onSubmit={getIncidentReportsByDate}>
            <div className="form-group d-flex flex-column">
              <small className="text-nowrap">From:</small>
              <input
                type="date"
                onChange={(e) => setFrom(e.target.value)}
                name="from"
                value={from}
                min="2018-01-01"
                className="form-control"
              />
            </div>

            <div className="form-group d-flex flex-column ml-2">
              <small className="mr-1">To:</small>
              <input
                type="date"
                onChange={(e) => setTo(e.target.value)}
                name="to"
                value={to}
                max={new Date().toISOString().split('T')[0]}
                className="form-control"
              />
            </div>

            <div className="mr-5 mt-3">
              <button className="btn btn-info ml-2" type="submit" disabled={!from || !to ? true : false}>
                Search
              </button>
            </div>
          </form> */}
          {option.option === 'date' && (
            <form className="d-flex flex-wrap" onSubmit={getIncidentReportsByDate}>
              <div className="form-group d-flex flex-column">
                <small className="text-nowrap mr-1">Show from:</small>
                <input
                  type="date"
                  onChange={(e) => setFrom(e.target.value)}
                  name="from"
                  value={from}
                  className="form-control"
                />
              </div>

              <div className="form-group d-flex flex-column ml-3">
                <small className="mr-1">to:</small>
                <input
                  type="date"
                  onChange={(e) => setTo(e.target.value)}
                  name="to"
                  value={to}
                  className="form-control"
                />
              </div>

              <div className="mr-3 mt-3">
                <button className="btn btn-info ml-2" type="submit">
                  Search
                </button>
              </div>
            </form>
          )}

          <div className="mt-2">
            <button className="btn btn-green shadow-md" type="button" onClick={() => setModalShow(!modalShow)}>
              Add Incident Report
            </button>
          </div>

          <div className={!modalShow ? 'nodisp' : ' modal-backdrop fade show'} />
          <div id="addIncident" className={modalShow ? 'modal fade in show d-block' : 'nodisp'} data-backdrop="false">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title text-center" id="myModalLabel">
                    Add Incident report
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={() => setModalShow(!modalShow)}
                    aria-hidden="true"
                  >
                    ×
                  </button>
                </div>
                <AddIncidentReportNew modalshow={() => setModalShow(!modalShow)} />
              </div>
            </div>
          </div>
        </div>

        <div className="card x-panel">
          <div className="table-responsive content">
            <table className="table copy-font">
              <thead>
                <tr>
                  <th>#</th>
                  <th>System affected</th>
                  <th>Description</th>
                  <th>Impact</th>
                  <th>Start</th>
                  <th>End</th>
                  <th>Date</th>
                  <th>Action</th>
                </tr>
              </thead>

              {load ? (
                <tbody>
                  <tr>
                    <td>
                      <img src={loader} alt="loading gif" />
                    </td>
                  </tr>
                </tbody>
              ) : (
                <IncidentReportTableNew incident={incidents.reports} />
              )}
            </table>
          </div>
        </div>
        {incidents.pages > 1 && (
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            breakLabel="..."
            breakClassName="break-me"
            pageCount={incidents.pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            subContainerClassName="pages pagination"
            activeClassName="active"
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  incidents: state.incidents.incidents,
  load: state.incidents.load,
});

IncidentReportsBodyNew.propTypes = {
  user: PropTypes.shape().isRequired,
  load: PropTypes.bool.isRequired,
  incidents: PropTypes.shape().isRequired,
  getIncidentReports: PropTypes.func.isRequired,
  getIncidentReportByDate: PropTypes.func.isRequired,
  getPaginatedIncidentReport: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  getUser,
  getIncidentReports,
  getIncidentReportByDate,
  getPaginatedIncidentReport,
})(IncidentReportsBodyNew);
