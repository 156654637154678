import React from 'react';
import { Link } from 'react-router-dom';
// import './newsidebar.css';

const NewDesignSideBarItem = ({ url, name, type }) => {
  if (!!type) {
    return (
      <li className="s-sidebar__nav-link list-nav d-flex justify-content-start align-items-start">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          style={{ width: '30px', height: '35px', marginTop: '3px', marginRight: '2px' }}
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
        </svg>
        <Link to={url} target="_self">
          <h6> {name} </h6>
        </Link>
      </li>
    );
  }
  return (
    <li className="s-sidebar__nav-link list-nav">
      <Link to={url} target="_self">
        <h6> {name} </h6>
      </Link>
    </li>
  );
};

export default NewDesignSideBarItem;
