/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useClassification } from '../../../../hooks/procurement/classification/useClassification';
import useTracklist from './hooks/useTracklist';
import { useTracklistCategory } from '../tracklist-category/hooks/useTracklistCategory';

const AddTracklistItem = () => {
  const { commodity, selectedOption, isLoading, AddTracklist, handleChange, handleSelect } = useTracklist();
  const { lists, getTracklistCategory } = useTracklistCategory();
  const { classification } = useClassification();
  const { tracklistCategory } = selectedOption;

  const [tracklistCategoryArr] = useState([]);

  useEffect(() => {
    getTracklistCategory();
  }, []);
  useEffect(() => {
    lists.map((list) =>
      tracklistCategoryArr.push({
        value: list.id,
        label: list.name,
      }),
    );
  }, [lists, tracklistCategoryArr]);
  const onSubmit = (e) => {
    e.preventDefault();
    const {
      dateReceived,
      quantity,
      location,
      cost,
      currency,
      vendor,
      invoiceNumber,
      invoiceStatus,
      classificationID,
    } = commodity;
    const data = {
      date: dateReceived,
      item: tracklistCategory.label,
      resource_classification_id: classificationID,
      quantity,
      location,
      vendor,
      invoice_no: invoiceNumber,
      cost,
      currency,
      delivery_status: 'delivered',
      invoice_submission_status: invoiceStatus,
      payment_status: 'paid',
      tracklist_category_id: tracklistCategory.value,
    };
    AddTracklist(data);
  };

  const submitForm = () => {
    return (
      commodity.dateReceived.length > 0 &&
      commodity.quantity.length > 0 &&
      commodity.location.length > 0 &&
      commodity.cost.length > 0 &&
      commodity.vendor.length > 0 &&
      commodity.invoiceNumber.length > 0 &&
      commodity.invoiceStatus.length > 0
    );
  };

  return (
    <div id="add_item" className="modal fade" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header shadow">
            <h4 className="modal-title add_item">Add Item</h4>
            <button type="button" className="btn btn-outline-info btn-circle btn-close" data-dismiss="modal">
              x
            </button>
          </div>
          <div className="modal-body detail mb-4 p-0">
            <form onSubmit={onSubmit} className="col-md-12">
              <div className="d-flex">
                <div className="p-2 pr-3 form-group col-md-6">
                  <label className="m-0 p-0 copy-font" htmlFor="item">
                    Item Name
                  </label>
                  <Select
                  
                    value={selectedOption.tracklistCategory}
                    onChange={handleSelect}
                    name="tracklistCategory"
                    options={tracklistCategoryArr}
                    className="border border-info"
                  />
                </div>
                <div className="p-2 pl-3 form-group col-md-6">
                  <label className="m-0 p-0 copy-font" htmlFor="vendor">
                    Company / Vendor
                  </label>
                  <input
                    type="text"
                    className="form-control border border-info py-2"
                    id="vendor"
                    name="vendor"
                    placeholder="Company / Vendor"
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="d-flex">
                <div className="p-2 pr-3 form-group col-md-6">
                  <label className="m-0 p-0 copy-font" htmlFor="class">
                    Class
                  </label>
                  <select
                    className="form-control border border-info py-2"
                    id="class"
                    style={{ borderRadius: '0' }}
                    name="classificationID"
                    onChange={handleChange}
                  >
                    <option>--Class--</option>
                    {classification.map((category) => (
                      <option value={category.id}>{category.name}</option>
                    ))}
                  </select>
                </div>

                <div className="p-2 pl-3 form-group col-md-6">
                  <label className="m-0 p-0 copy-font" htmlFor="status">
                    Device Status
                  </label>
                  <select
                    className="form-control border border-info py-2 borderRadius0"
                    id="status"
                    name="invoiceStatus"
                    onChange={handleChange}
                  >
                    <option>--Device Status--</option>
                    <option value="new">New</option>
                    <option value="retrieved">Retrieved</option>
                    <option value="not-available">Not available</option>
                    <option value="bad">Bad</option>
                  </select>
                </div>
              </div>

              <div className="d-flex">
                <div className="p-2 pr-3 form-group col-md-6">
                  <label className="m-0 p-0 copy-font" htmlFor="item_cost">
                    Cost of Item
                  </label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <select name="currency" onChange={handleChange}>
                          <option value="NGN">NGN</option>
                          <option value="USD">USD</option>
                          <option value="GHC">GHC</option>
                        </select>
                      </div>
                    </div>
                    <input
                      type="number"
                      className="form-control border border-info py-2"
                      id="item_cost"
                      name="cost"
                      placeholder="Cost of Item"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="p-2 pl-3 form-group col-md-6">
                  <label className="m-0 p-0 copy-font" htmlFor="invoice">
                    Invoice No.
                  </label>
                  <input
                    type="text"
                    className="form-control border border-info py-2"
                    id="invoice"
                    name="invoiceNumber"
                    placeholder="Invoice Number"
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="d-flex">
                <div className="p-2 pr-3 form-group col-md-6">
                  <label className="m-0 p-0 copy-font" htmlFor="quantity">
                    Quantity
                  </label>
                  <input
                    type="number"
                    className="form-control border border-info py-2"
                    id="quantity"
                    name="quantity"
                    placeholder="Quantity in figures"
                    onChange={handleChange}
                  />
                </div>

                <div className="p-2 pl-3 form-group col-md-6">
                  <label className="m-0 p-0 copy-font" htmlFor="receipt">
                    Date of Receipt
                  </label>
                  <input
                    type="date"
                    className="form-control border border-info py-2"
                    id="receipt"
                    name="dateReceived"
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="p-2 pl-3 form-group col-md-6">
                <label className="m-0 p-0 copy-font" htmlFor="location">
                  Location
                </label>
                <input
                  type="text"
                  className="form-control border border-info py-2"
                  id="location"
                  name="location"
                  placeholder="Location"
                  onChange={handleChange}
                />
              </div>

              {/* <div className="d-flex">
                <div className="p-2 pr-3 form-group col-md-6">
                  <label className="m-0 p-0 copy-font" htmlFor="class">
                    Delivery Status
                  </label>
                  <select
                    className="form-control border border-info py-2"
                    id="class"
                    name="deliveryStatus"
                    style={{ borderRadius: '0' }}
                    onChange={handleChange}
                  >
                    <option>--Delivery Status--</option>
                    <option value="in-transit">In Transit</option>
                    <option value="delivered">Delivered</option>
                  </select>
                </div>
                <div className="p-2 pl-3 form-group col-md-6">
                  <label className="m-0 p-0 copy-font" htmlFor="paymentStatus">
                    Payment Status
                  </label>
                  <select
                    className="form-control border border-info py-2 borderRadius0"
                    id="paymentStatus"
                    name="paymentStatus"
                    onChange={handleChange}
                  >
                    <option>--Payment Status--</option>
                    <option value="Paid">Paid</option>
                    <option value="Unpaid">Unpaid</option>
                  </select>
                </div>
              </div> */}

              <div className="text-center mt-3">
                {isLoading ? (
                  <button type="button" className="btn btn-green py-2 px-5" disabled>
                    Adding...
                  </button>
                ) : (
                  <button type="submit" className="btn btn-green py-2 px-5" disabled={!submitForm()}>
                    Add
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTracklistItem;
