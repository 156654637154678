/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useState, Fragment } from 'react';
import view from '../../../../../assets/img/icons/view.png';
import edit from '../../../../../assets/img/icons/edit.png';
import RequestDetails from '../shared/RequestDetails';
// import EditRequest from './EditRequest';
// import EditRequests from './EditRequests';
import RequestContext from '../../../../../context/shared/requests/admin/RequestContext';
import helpers from '../../../../../utils/requests/helpers';
import Show from './Show';

const RequestsTableBody = () => {
  const value = useContext(RequestContext);
  const [request, setRequest] = useState('');

  const { requests, show, getToShow } = value;
  const getRequest = (data) => {
    setRequest(data);
  };

  const requestsList = requests.length ? (
    requests.map((req, i) => {
      return (
        <tr>
          <td>{i + 1}</td>
          <td>{req.request_category ? req.request_category.category : ' N / A'}</td>
          <td>{req.description}</td>
          <td>{req.date_requested}</td>
          <td>
            {req.assigned_to
              ? req.assigned_to.employees.map((emp) => {
                  return <small>{`${`${emp.firstName} ${emp.lastName}`}`}</small>;
                })
              : 'Yet to be assigned'}
          </td>
          <td>
            {req.requested_by
              ? req.requested_by.employees.map((emp) => {
                  return <small>{`${`${emp.firstName} ${emp.lastName}`}`}</small>;
                })
              : 'This user has no profile'}
          </td>
          {/* <td>May 8th,2019 09:34 AM</td> */}
          <td>{helpers.showRequestApprovalStatus(req.status)}</td>
          <td>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <span
              onClick={() => getRequest(req)}
              className="view2 mr-2"
              data-target="#requestDetails"
              data-toggle="modal"
            >
              <img
                src={view}
                alt=""
                data-toggle="tooltip"
                data-placement="left"
                title="View"
                style={{ cursor: 'pointer' }}
              />
            </span>

            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <span
              // onClick={() => {
              //   getRequest(req);
              // }}
              className="view2"
              onClick={() => {
                getToShow();
                getRequest(req);
              }}
            >
              <img src={edit} alt="" data-toggle="tooltip" data-placement="top" title="Edit" />
            </span>

            {/* onClick={() => getToShow()} */}
          </td>
          <RequestDetails one={request} />
          {/* <Show one={request} /> */}
          {/* {show ? <Show /> : null} */}

          {/* <EditRequests one={request}/> */}
        </tr>
      );
    })
  ) : (
    <small>no lists...</small>
  );
  return (
    <Fragment>
      {show ? <Show one={request} /> : null}
      {requestsList}
    </Fragment>
  );
  // // <div>
  // // {/* <p onClick={() => getToShow()}>clic</p> */}
  // // {/* {show ? <Show /> : null} */}
  // {
  //   ;
  // }
  // // </div>
};

export default RequestsTableBody;
