/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable import/prefer-default-export */
import { useReducer, useEffect } from 'react';
import axios from 'axios';
import qs from 'qs';
import toastr from 'toastr';
import * as load from '../../../../constants/types/shared/LoadingTypes';
import * as types from '../../../../constants/types/asset-team/AssetTeamTypes';
import { expiredReducer } from '../../../../reducers/shared/asset-team/expired/expiredReducer';
import { GET_ROUTER_URL } from '../../../../services/api/shared/BasestationsUrl';

export const useExpired = () => {
  const [{ items, time, customers, total, isLoading }, dispatch] = useReducer(expiredReducer, {
    items: [],
    customers: [],
    time: '',
    total: 0,
    isLoading: false,
  });

  const getExpired = async () => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const params = { action: 'tizeti_asset_expiration_days_count_get_cron' };
      const response = await axios.post(`${GET_ROUTER_URL}`, qs.stringify(params));
      if (response.data.expired_per_basestation_dynamic.items) {
        dispatch({
          type: types.GET_EXPIRED,
          payload: {
            items: response.data.expired_per_basestation_dynamic.items,
            time: response.data.readable_last_updated_at,
            total: response.data.total_expired,
          },
        });
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const getExpired7DaysAgo = async (data) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(`${GET_ROUTER_URL}`, qs.stringify(data));
      if (response.data) {
        dispatch({
          type: types.GET_EXPIRED_USERS,
          payload: response.data.items,
        });
      } else dispatch({ type: load.LOADING_STOPS });
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  useEffect(() => {
    getExpired();
  }, []);

  return {
    time,
    items,
    total,
    isLoading,
    customers,
    getExpired7DaysAgo,
  };
};
