import React, { Component } from 'react'

export default class UpdateIssue extends Component {
  render() {
    return (
        <div id="updateIssue" className="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title text-center" id="myModalLabel">Update Issue Report</h4>
                        <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                            <form className="form-horizontal">
                                <div className="form-group">
                                    <div className="col-12 d-flex mb-3 p-0">
                                        <div className="col-6">
                                            <label className="copy-font m-0 p-0">Department</label>
                                            <input type="text" placeholder="Operations" className="form-control" readOnly />
                                        </div>

                                        <div className="col-6">
                                            <label className="copy-font m-0 p-0">Subject</label>
                                            <input type="text" placeholder="Scheduler" className="form-control" readOnly/>
                                        </div>
                                    </div>

                                    <div className="col-12 mb-3 d-flex p-0">
                                        <div className="col-6 p-0">
                                            <div className="col-12 mb-4">
                                                <label className="copy-font m-0 p-0">Submitted By</label>
                                                <input type="text" className="form-control" placeholder="Olawale Tolu" readOnly/>
                                            </div>
                                            <div className="col-12">
                                                <label className="copy-font m-0 p-0">Status</label>
                                                <select className="form-control w-100">
                                                    <option>In Progress</option>
                                                    <option>Done</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <label className="copy-font m-0 p-0">Details</label>
                                            {/* <input type="text" placeholder="IP Address" className="form-control" /> */}
                                            <textarea rows="5" cols="20" name="description" className="form-control" placeholder="Can we be able to get the feature to move faster" readOnly>
                                            </textarea>
                                        </div>
                                    </div>

                                    <div className="col-12 text-center mt-3">
                                        <button className="btn btn-green" style={{backgroundColor:"#FFA800"}}>Update</button>
                                    </div>
                                </div>
                            </form>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}
