/* eslint-disable import/prefer-default-export */
import { GET_LEAD_REQUEST } from './LeadTypes';
import { LOADING_STARTS, LOADING_STOPS } from '../../../../../constants/types/shared/LoadingTypes';

export const LeadsReducers = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOADING_STARTS:
      return {
        ...state,
        isLoading: true,
      };

    case LOADING_STOPS:
      return {
        ...state,
        isLoading: false,
      };

    case GET_LEAD_REQUEST:
      return {
        ...state,
        lists: payload.lists,
        isLoading: false,
      };

    default:
      return state;
  }
};
