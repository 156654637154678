import { toast } from 'react-toastify';
import axios from 'axios';
import qs from 'qs';
import * as url from '../../../services/api/noc/static-ip/StaticIpUrl';
import { FETCHING_STARTS, FETCHING_STOPS } from '../../../constants/types/shared/LoadingTypes';
import * as types from '../../../constants/types/noc/NocTypes';

export const deleteStaticIpAddress = (data) => async () => {
  const response = await axios.post(`${url.DELETE_STATIC_IP_URL}`, data);
  if (response.data[0].status === true) {
    toast.success(response.data[0].error);
  }
};

export const editStaticIp = (data) => {
  return async (dispatch) => {
    dispatch({
      type: FETCHING_STARTS,
    });
    try {
      const response = await axios.post(`${url.EDIT_STATIC_IP_URL}`, qs.stringify(data));

      if (response.data[0].status === true) {
        toast.success(response.data[0].error);
        dispatch({
          type: types.UPDATE_STATIC_IP_ADDRESS,
        });
      } else if (response.data[0].status === false) {
        toast.success(response.data[0].error);
        dispatch({
          type: types.UPDATE_STATIC_IP_ADDRESS,
        });
      } else {
        toast.success('Unable to edit static ip');
        dispatch({
          type: types.UPDATE_STATIC_IP_ADDRESS,
        });
      }
    } catch (error) {
      if (error) {
        toast.info(`something went wrong! Try again`);
        dispatch({
          type: FETCHING_STOPS,
        });
      }
    }
  };
};

export const addStaticIp = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.START_ADDING_STATIC_IP,
    });
    try {
      const response = await axios.post(`${url.ADD_STATIC_IP_URL}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data[0].status === false) {
        dispatch({
          type: types.STOP_ADDING_STATIC_IP,
        });
        toast.info(response.data[0].error);
      } else if (response.data[0].status === true) {
        toast.success(response.data[0].error);
        dispatch({
          type: types.STOP_ADDING_STATIC_IP,
        });
      } else {
        toast.info('Unable to change the ip');

        dispatch({
          type: types.ADDED_STATIC_IP,
        });
      }
    } catch (e) {
      if (e) {
        toast.info('Unable to change the ip');
        dispatch({
          type: types.STOP_ADDING_STATIC_IP,
        });
      }
    }
  };
};
