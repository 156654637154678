/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import helpers from '../../../../utils/helpers';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../utils/loader/loadingHelper';
import feedback_helpers from '../../../../utils/feedback/feedback_helpers';

const FeedbackTable = ({ lists, isLoading }) => {
  const colSpan = '8';
  const tableData =
    lists && lists.length ? (
      lists.map((list, i) => {
        const {
          installation: {
            user: { firstName, lastName, email },
          },
          rating,
          experience,
          created_at,
        } = list;

        return (
          <tr key={i}>
            <td>{i + 1}</td>
            <td>{`${firstName} ${lastName}`}</td>
            <td>{email}</td>
            <td>{feedback_helpers.ratingLabels(rating)}</td>
            <td>{experience}</td>
            <td className="text-nowrap">{helpers.formatDateAndTime(created_at)}</td>
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan={colSpan} text="There are no requests" />
    );

  return (
    <div className="table-responsive content col-12">
      <table className="table">
        <thead>
          <tr className="text-nowrap">
            <th>#</th>

            <th>Customer's Name</th>
            <th>Customer's Email</th>
            <th>Rating</th>
            <th>Experience</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>{isLoading ? <TizetiGIFLogoTable colSpan={colSpan} /> : tableData}</tbody>
      </table>
    </div>
  );
};

export default FeedbackTable;
