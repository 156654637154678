import React from 'react';
import { ToastContainer } from 'react-toastify';
import NocNavBar from '../../../../../layouts/noc/NocNavBar';
import AlarmsBody from './AlarmsBody';
import { admin, NOC } from '../../../../../../utils/config';
import { useUser } from '../../../../../../hooks/shared/user/useUser';
import AlarmsProvider from './context/AlarmsProvider';
import useCheckTwoDepartments from '../../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';

const Alarms = ({ history }) => {
  const { profile } = useUser();
  const { firstName } = profile;
  useCheckTwoDepartments(`${NOC}`, `${admin}`, history);

  return (
    <div className="s-layout">
      <AlarmsProvider>
        <main className="s-layout__content col-lg-10 p-0">
          <NocNavBar currentPage="CNMAESTRO - Alarms" position={firstName} />
          <AlarmsBody />
        </main>
        <ToastContainer />
      </AlarmsProvider>
    </div>
  );
};

export default Alarms;
