import {
  FETCHED_EXTENSIONS,
  START_FETCHING_EXTENSIONS,
  STOP_FETCHING_EXTENSIONS,
} from '../../../constants/types/shared/customers/ExtensionsTypes';

const intialState = {
  loading: false,
  extensions: [],
};

export const ExtensionsReducer = (state = intialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case START_FETCHING_EXTENSIONS:
      return {
        ...state,
        loading: payload,
      };

    case STOP_FETCHING_EXTENSIONS:
      return {
        ...state,
        loading: payload,
      };

    case FETCHED_EXTENSIONS:
      return {
        ...state,
        extensions: payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default ExtensionsReducer;
