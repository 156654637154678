/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useCallLog } from '../../../../../hooks/customer-support/call-logs/useCallLog';
import useInstallationZones from '../../../shared/installations/zones/hooks/useInstallationZones';
import { Modal } from 'react-bootstrap';

const AddCallLog = ({ setShow, show, cin, oldEmail, oldName, oldZone, oldPhone }) => {
  const { isLoading, addCallLog } = useCallLog();
  const { loading, zones, loader, getZohoDepartment, items } = useInstallationZones();

  console.log(items, 'this is zones', zones);
  const [request, setRequest] = useState({
    issue: '',
    resolution: '',
    note: '',
    zoho: '',
    zoned: oldZone,
    phone: oldPhone,
  });
  const { issue, resolution, note, zoho, phone, zoned } = request;

  const handleClose = () => {
    setShow(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setRequest({ ...request, [name]: value });
  };

  useEffect(() => {
    // console.log(oldPhone);
    // if (oldPhone.length > 0) {
    const result = {
      phone: oldPhone,
      zoned: oldZone,
    };
    setRequest({ ...result });
    // }
    getZohoDepartment();
  }, [oldPhone, oldZone]);

  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      name: oldName,
      email: oldEmail,
      issue: issue,
      phoneNumber: phone,
      resolution: resolution,
      note: note,
      zone: zoned,
      cin: cin,
      zohoDepartmentId: zoho,
    };
    addCallLog(data, handleClose, setRequest, oldZone, oldPhone);
  };

  // const submitForm = () => {
  //   return issue.length > 0 && resolution.length > 0 && note.length > 0;
  // };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ paddingLeft: '2rem' }}>Add Call Log </Modal.Title>
      </Modal.Header>
      <form onSubmit={onSubmit} className="col-md-12">
        <div className="pt-2 d-flex">
          <div className="pr-3 form-group col-md-6">
            <label className="m-0 p-0 copy-font" htmlFor="firstName">
              Name
            </label>
            <input
              type="text"
              className="form-control border border-info py-2"
              id="firstName"
              name="firstName"
              value={oldName}
              disabled
            />
          </div>
          <div className="pl-3 form-group col-md-6">
            <label className="m-0 p-0 copy-font" htmlFor="lastName">
              Email
            </label>
            <input
              type="text"
              className="form-control border border-info py-2"
              id="lastName"
              name="lastName"
              value={oldEmail}
              disabled
            />
          </div>
        </div>

        <div className="d-flex">
          <div className="pr-3 form-group col-md-12">
            <label className="m-0 p-0 copy-font" htmlFor="phone">
              Phone
            </label>
            <input
              type="tel"
              className="form-control border border-info py-2"
              id="phone"
              onChange={handleChange}
              name="phone"
              value={phone}
            />
          </div>
        </div>
        <div className="d-flex">
          <div className="pr-3 form-group col-md-12">
            <label className="m-0 p-0 copy-font" htmlFor="cin">
              CIN
            </label>
            <input
              type="text"
              className="form-control border border-info py-2"
              id="cin"
              name="cin"
              value={cin}
              disabled
            />
          </div>
        </div>

        <div className="d-flex">
          <div className="pr-3 form-group col-md-12">
            <label className="m-0 p-0 copy-font" htmlFor="zone">
              Zone
            </label>
            <select
              className="form-control border border-info py-2"
              id="zone"
              name="zoned"
              value={zoned}
              onChange={handleChange}
            >
              <option value=""> Select an Option </option>
              {!loading ? (
                zones.map((zone) => (
                  <option key={zone.id} value={zone.name}>
                    {zone.name}
                  </option>
                ))
              ) : (
                <option>Loading</option>
              )}
            </select>
          </div>
        </div>
        <div className="d-flex">
          <div className="pr-3 form-group col-md-12">
            <label className="m-0 p-0 copy-font" htmlFor="zoho">
              Zoho Department
            </label>
            <select
              className="form-control border border-info py-2"
              id="zoho"
              name="zoho"
              value={zoho}
              onChange={handleChange}
            >
              <option value=""> Select an Option </option>
              {items.length ? (
                items.map((zoho) => (
                  <option key={zoho.id} value={zoho.id}>
                    {zoho.name}
                  </option>
                ))
              ) : (
                <option>Loading</option>
              )}
            </select>
          </div>
        </div>
        <div className="d-flex">
          <div className="pr-3 form-group col-md-12">
            <label className="m-0 p-0 copy-font" htmlFor="issue">
              Issue
            </label>
            <input
              type="text"
              className="form-control border border-info py-2"
              id="issue"
              name="issue"
              value={issue}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="d-flex">
          <div className="pr-3 form-group col-md-12">
            <label className="m-0 p-0 copy-font" htmlFor="resolution">
              Resolution
            </label>
            <select
              className="form-control border border-info py-2"
              id="resolution"
              name="resolution"
              value={resolution}
              onChange={handleChange}
            >
              <option value=""> Select an Option </option>
              <option value="technical_visit"> Technical Visit </option>
              <option value="assisted"> Assisted</option>
              <option value="expired"> Expired </option>
              <option value="escalated_to_account">Escalated to Account</option>
              <option value="downtime">Downtime</option>
              <option value="dropped">Dropped </option>
              <option value="escalated_to_retension"> Escalated to Retension </option>
              <option value="escalated_to_sales"> Escalated to Sales </option>
              <option value="advised_to_send_an_email">Advised to Send Email </option>
              <option value="escalated_to_NOC">Escalated to NOC </option>
              <option value="troubleshoot">Troubleshoot </option>
              <option value="call_back">Call Back </option>
              <option value="unable_to_get_account">Unable to Get Account </option>
            </select>
          </div>
        </div>

        <div className="d-flex">
          <div className="pr-3 form-group col-md-12">
            <label className="m-0 p-0 copy-font" htmlFor="note">
              Note
            </label>
            <textarea
              className="form-control border border-info py-2"
              id="note"
              name="note"
              value={note}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="text-center mt-3">
          {isLoading ? (
            <button type="button" className="btn btn-green py-2 px-5" disabled>
              Adding...
            </button>
          ) : (
            // <button type="submit" className="btn btn-green py-2 px-5" disabled={!submitForm()}>
            //   Add
            // </button>
            <button type="submit" className="btn btn-green py-2 px-5">
              Add
            </button>
          )}
        </div>
      </form>
    </Modal>
  );
};

AddCallLog.propTypes = {
  request: PropTypes.objectOf.isRequired,
};

export default AddCallLog;
