import React from 'react';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';

const ExpressWifiCustomersSignupsTable = ({ items, isLoading }) => {
  const tableData =
    items.length && items
      ? items.map((item, i) => {
          const { customer_name, customer_phone, ambassador_name, ambassador_email, ambassador_phone, date } = item;
          return (
            <tr className="text-nowrap" key={i}>
              <td>{i + 1}</td>
              <td>{customer_name}</td>
              <td>{customer_phone}</td>
              <td>{ambassador_name}</td>
              <td>{ambassador_email}</td>
              <td>{ambassador_phone}</td>
              <td>{date}</td>
            </tr>
          );
        })
      : 'NO RECORD';
  return (
    <div className="card x-panel mt-2 px-0">
      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <thead>
            <tr className="">
              <th>#</th>
              <th>Name</th>
              <th>Phone Number</th>
              <th>Ambassador Name</th>
              <th>Ambassador Email</th>
              <th>Ambassador Phone Number</th>
              <th>Date Created</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="8" className="text-center">
                  <img src={loader} alt="loading gif" />
                </td>
              </tr>
            ) : (
              tableData
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ExpressWifiCustomersSignupsTable;
