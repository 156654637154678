import React from 'react';
import { ToastContainer } from 'react-toastify';
import SchedulingBody from './SchedulingBody';
import { SchedulingProvider } from '../../../../../context/field-support/SchedulingContext';

const Scheduling = () => {
  return (
    <SchedulingProvider>
      <SchedulingBody />
      <ToastContainer />
    </SchedulingProvider>
  );
};

export default Scheduling;
