import React, { Component } from 'react';
import { connect } from 'react-redux';
// import * as actions from '../../../../../actions/noc/tickets/TicketsActions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class EditTicket extends Component {
  
  componentWillMount(){

  }

  componentDidMount(){
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.status !== this.props.status){
      if(this.props.status == 1){
        toast.success('TICKET SUCCESSFULLY CLOSED');
      }else {
        toast.error('UNABLE TO CLOSE TICKET');
      }
    }

  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.EditTicket(this.props);
    

  };

  handleUpdateInput(e){
    this.props.update_input(e.target.value);

  };

   handleResolutionActionInput(e){
    this.props.resolution_action_input(e.target.value);

  }

  handleResolutionTimeInput(e){
    this.props.resolution_time_input(e.target.value);

  }
    render() {
    return (
      <div
        id="editTicket"
        className="modal fade in"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center" id="myModalLabel">
                Edit Ticket
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                ×
              </button>
            </div>
            <div className="modal-body">
              <form className="form-horizontal" onSubmit={this.handleSubmit}>
                <div className="form-group employe-body">
                  <div className="col-md-12 mb-3 d-flex"></div>

                  <div className="col-md-12 mb-3 d-flex">

                     <div className="col-lg-6">
                      <label className="col-lg-12 text-justify copy-font m-0">
                        Updates
                      </label>

                      <input
                        type="text"
                        name="updates"
                        className="form-control p-2"
                        placeholder="Incident Type"
                        onChange={this.handleUpdateInput.bind(this)}
                        value={this.props.updates}
                        required
                       
                      />
                    </div>
                  </div>

                  <div className="col-md-12 mb-3 d-flex">
                    <div className="col-lg-6">
                      <label className="col-lg-12 text-justify copy-font m-0">
                        Resolution Time
                      </label>
                      <input
                        type="time"
                        name="resolution_time"
                        className="form-control p-2"
                        placeholder="1.00 pm"
                        onChange={this.handleResolutionTimeInput.bind(this)}
                        value={this.props.resolution_time}
                       
                      />
                    </div>

                      <div className="col-lg-6">
                      <label className="col-lg-12 text-justify copy-font m-0">
                        Resolution Action
                      </label>
                      <input
                        type="text"
                        name="resolution_action"
                        className="form-control p-2"
                        onChange={this.handleResolutionActionInput.bind(this)}
                        value={this.props.resolution_action}
                        required
                        
                      />
                    </div>
                  </div>
                </div>

                <div className="text-center">
                  <button type="submit" className="btn btn-second waves-effect">
                    Edit Ticket
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {updates, resolution_action, resolution_time, status} = state.edit_ticket
  return {
    ticketStatus: state.edit_ticket,
    updates, resolution_action, resolution_time, status
  };
}

export default connect(
  mapStateToProps,
  null
)(EditTicket);
