import React from 'react';

const chartOptions = ['Line-Chart', 'Area-Chart', 'Bar-Chart'];

export default function ChartTypeSelect({ setChartType, chartType }) {
  const handleChartTypeSelected = (e) => {
    setChartType(e.target.value);
  };

  // console.log(chartType);
  return (
    <div className="container mx-auto mt-3">
      <div className="relative w-40">
        <select
          className="block w-full px-4 py-2 border rounded appearance-none focus:border-blue-500 focus:outline-none"
          value={chartType}
          onChange={handleChartTypeSelected}
        >
          <option value="" disabled>
            Select Chart Type
          </option>
          {chartOptions.map((chart) => (
            <option key={chart} value={chart}>
              {chart}
            </option>
          ))}
        </select>
        <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none"></div>
      </div>
      {chartType && <div className="my-2">Selected Chart: {chartType}</div>}
    </div>
  );
}
