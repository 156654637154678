/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

const SmsDetails = ({ data }) => {
  return (
    <div
      id="sms-details"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title ml-5" id="myModalLabel">
              Sms Details
            </h4>
            <button type="button" className="close custom-close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body detail" style={{ padding: '0', textAlign: 'justify' }}>
            <div className="row">
              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label1">
                  <label id="fn">Phone Number</label>
                  {/* <label id="ln">AP</label> */}
                  {/* <label id="email">Station</label> */}
                </div>
                <div className="col-lg-8 detail-result1">
                  <p className="mt-4 mb-2">{data ? data.to : null}</p>
                  {/* <p className="mb-2">192.128.83.70</p> */}
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label2">
                  <label>Message</label>
                  {/* <label >AF5U</label> */}
                </div>
                <div className="col-lg-8 detail-result2">
                  <p className="mt-4 mb-2">{data ? data.message : null}</p>
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label3">
                  {/* <label >Expiration Date</label> */}
                  <label>Time</label>
                </div>
                <div className="col-lg-8 detail-result3">
                  <p className="mt-4">{data ? data.timestamp : null}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SmsDetails.propTypes = {
  data: PropTypes.shape(),
};

export default SmsDetails;
