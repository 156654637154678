import axios from 'axios';
import { toast } from 'react-toastify';
import { API_URL } from '../../../utils/config';

export const create_basestation = async (data) => {
  const res = await axios.post(`${API_URL}/basestation`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
  });

  try {
    if (res.data) {
      toast.success('Basestation successfully created');
      setTimeout(() => {
        window.location.reload();
      }, 3000);
      return res.data;
    }
  } catch (error) {
    toast.error('error getting sub zones');
  }
};
