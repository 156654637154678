import React from 'react';
import NocNavBar from '../../../../layouts/noc/NocNavBar';
import { admin, NOC } from '../../../../../utils/config';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import CompletedSitesTable from './CompletedSitesTable';
import useCheckTwoDepartments from '../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';

const CompletedSites = ({ history }) => {
  const { profile } = useUser();
  const { firstName } = profile;
  useCheckTwoDepartments(`${NOC}`, `${admin}`, history);
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <NocNavBar currentPage="Completed Sites" position={firstName} />
        <div className="container">
          <div className="row mt-5">
            <div className="mt-4 col-lg-12 col-md-12 col-sm-12">
              <div className="container">
                <div className="row mt-5">
                  <CompletedSitesTable />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CompletedSites;
