/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import edit from '../../../../../assets/img/icons/edit.png';
import view from '../../../../../assets/img/icons/view.png';
import UpdateXWFRetailer from './UpdateXWFRetailer';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../../utils/loader/loadingHelper';
import XWFRetailerComment from './comments/XWFRetailerComment';
import { useUserProfile } from '../../../../../hooks/shared/user/useUserProfile';
import XWFBulkSMS from '../shared/sms/XWFBulkSMS';
import XWFSingleSMS from '../shared/sms/XWFSingleSMS';
import useXWFRetailers from '../../../../../hooks/express-wifi/retailers/useXWFRetailers';
import helpers from '../../../../../utils/helpers';

const XWFRetailersTable = ({ retailers, pages, isLoading, handlePageClick }) => {
  const { profile, getUserProfile } = useUserProfile();
  const { getXWFRetailerComment, retailerComments, isFetching } = useXWFRetailers();

  const colSpan = 11;
  const [oneRetailer, setOneRetailer] = useState({
    Business: '',
    ContactName: '',
    balance: '',
    address: '',
    status: '',
    activation_date: '',
    phoneNumber: '',
    zone: '',
    id: '',
    isChecked: false,
  });

  const [newData, setNewData] = useState(retailers);
  const [sendBulk, setSendBulk] = useState(false);

  useEffect(() => {
    getUserProfile();
  }, []);

  useEffect(() => {
    if (retailers && retailers.length) {
      setNewData(
        retailers.map((item) => {
          const { Business, ContactName, balance, address, status, activation_date, phoneNumber, zone, id } = item;
          return {
            isChecked: false,
            Business,
            ContactName,
            balance,
            address,
            status,
            activation_date,
            phoneNumber,
            zone,
            id,
          };
        }),
      );
    }
  }, [retailers]);

  const getOneRetailer = (item) => setOneRetailer(item);

  const handleSelectAll = (e) => {
    const { checked } = e.target;
    setNewData(
      newData.map((dataSet) => {
        dataSet.isChecked = checked;
        return dataSet;
      }),
    );
    if (newData.filter((one) => one.isChecked).length > 1) setSendBulk(true);
    else setSendBulk(false);
  };

  const handleSelectOne = (e, id) => {
    setNewData(
      newData.map((dataSet) => {
        if (id === dataSet.id) {
          dataSet.isChecked = e.target.checked;
        }
        return dataSet;
      }),
    );
    if (newData.filter((one) => one.isChecked).length > 1) setSendBulk(true);
    else setSendBulk(false);
  };

  const handleStatusLabel = (statusLabel) => {
    if (statusLabel === 'active') return <span className="badge badge-success w-100">{statusLabel.toUpperCase()}</span>;
    return <span className="badge badge-danger w-100">{statusLabel.toUpperCase()}</span>;
  };

  const retailersTable =
    retailers && retailers.length && newData && newData.length ? (
      newData.map((retailer, i) => {
        const {
          Business,
          ContactName,
          balance,
          address,
          status,
          activation_date,
          phoneNumber,
          zone,
          id,
          isChecked,
        } = retailer;
        return (
          <tr className="" key={i}>
            <td>{i + 1}</td>
            <td>
              <input type="checkbox" checked={isChecked} onChange={(e) => handleSelectOne(e, id)} />
            </td>
            <td className="text-nowrap">{Business}</td>
            <td className="text-nowrap">{ContactName}</td>
            <td className="text-nowrap">{balance}</td>
            <td>{address}</td>
            <td>{handleStatusLabel(status)}</td>
            <td>{helpers.formatDate(activation_date)}</td>
            <td>{phoneNumber}</td>
            <td>{zone.toUpperCase()}</td>
            <td>
              <div className="d-flex text-center">
                <button
                  type="button"
                  className="mr-2 noBorder"
                  data-toggle="modal"
                  data-target="#updateRetailer"
                  onClick={() => getOneRetailer(retailer)}
                >
                  <span className="view">
                    <img src={edit} data-toggle="tooltip" alt="" data-placement="top" title="Edit" />
                  </span>
                </button>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#singleSMS"
                  className="mr-2 noBorder"
                  onClick={() => getOneRetailer(retailer)}
                >
                  <span className="view1">
                    <i className="fa fa-comment-o" title="Send SMS" />
                  </span>
                </button>
                <button
                  type="button"
                  className="mr-2 noBorder"
                  data-toggle="modal"
                  data-target="#getRetailerComment"
                  onClick={() => {
                    getXWFRetailerComment(id);
                    getOneRetailer(retailer);
                  }}
                >
                  <span className="view">
                    <img src={view} data-toggle="tooltip" alt="" data-placement="top" title="Comment" />
                  </span>
                </button>
              </div>
              <UpdateXWFRetailer oneRetailer={oneRetailer} />
              <XWFSingleSMS oneUser={oneRetailer} />
              <XWFRetailerComment
                oneRetailer={oneRetailer}
                retailerComments={retailerComments}
                isFetching={isFetching}
              />
            </td>
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan={colSpan} text="No Retailers" />
    );

  return (
    <div className="card x-panel p-0">
      {sendBulk && (
        <div className="form-group mx-2">
          <button type="button" className="btn btn-danger" data-toggle="modal" data-target="#bulkSMS">
            Send Bulk SMS
          </button>
        </div>
      )}
      <XWFBulkSMS users={newData} profile={profile} />
      <div className="table-responsive content col-12 px-0">
        <table className="table table-striped">
          <thead>
            <tr className="text-nowrap">
              <th>S/N</th>
              <th>
                <input type="checkbox" onChange={handleSelectAll} />
              </th>
              <th>Business Name</th>
              <th>Contact Name</th>
              <th>Balance (₦)</th>
              <th>Address</th>
              <th>Status</th>
              <th>Activation Date</th>
              <th>Phone</th>
              <th>Zone</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{isLoading ? <TizetiGIFLogoTable colSpan={colSpan} /> : retailersTable}</tbody>
        </table>
      </div>
      <div className="px-3">
        {pages > 1 && (
          <ReactPaginate
            previousLabel="previous"
            nextLabel="next"
            breakLabel="..."
            breakClassName="break-me"
            pageCount={pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            activeClassName="active"
          />
        )}
      </div>
    </div>
  );
};

export default XWFRetailersTable;
