/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import helpers from '../../../../../../utils/helpers';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../../../utils/loader/loadingHelper';
import relocation_helpers from '../../../../../../utils/relocation/relocation_helpers';

const PendingRelocationTable = ({ lists, isLoading }) => {
  const colSpan = '20';
  const tableData =
    lists && lists.length ? (
      lists.map((list, i) => {
        const {
          firstName,
          lastName,
          email,
          phoneNumber,
          old_address,
          new_address,
          relocation_status,
          note,
          payment_ref,
          created_at,
        } = list;

        return (
          <tr className="" key={i}>
            <td>{i + 1}</td>
            <td className="text-nowrap">{`${firstName} ${lastName}`}</td>
            <td>{email}</td>
            <td>{phoneNumber}</td>
            <td className="text-nowrap">{old_address}</td>
            <td className="text-nowrap">{new_address}</td>
            <td>{relocation_helpers.relocationStatusLabels(relocation_status)}</td>
            <td className="text-uppercase">{note}</td>
            <td className="text-wrap">{payment_ref || 'N/A'}</td>
            <td>{helpers.formatDateAndTime(created_at)}</td>
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan={colSpan} text="There are no requests for relocation" />
    );

  return (
    <div className="table-responsive content col-12">
      <table className="table table-striped">
        <thead>
          <tr className="">
            <th> S/N</th>
            <th>Names</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>Old Address</th>
            <th>New Address</th>
            <th>Relocation Status</th>
            <th>Note</th>
            <th>Payment REF</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>{isLoading ? <TizetiGIFLogoTable colSpan={colSpan} /> : tableData}</tbody>
      </table>
    </div>
  );
};

export default PendingRelocationTable;
