/* eslint-disable react/button-has-type */
import React from 'react';
import CustomerSessionTable from './CustomerSessionTable';
import useArchive from '../../../../../../hooks/customer-support/useArchive';

const CustomerSessionDetails = () => {
  const { mac, onChangeForSession, getUserSession, fetching, session } = useArchive();
  const submit = (e) => {
    e.preventDefault();
    const data = {
      action: 'getUserSessionArchive',
      macaddress: mac.trim(),
    };

    getUserSession(data);
  };
  return (
    <div className="row">
      <div className="col-lg-12 mt-3 mb-3">
        <form onSubmit={submit} className="form-inline mt-4 inner-addon d-flex">
          <div className="input-group mb-3 mr-3 w-25">
            <input
              className="form-control mr-auto empty pl-2"
              type="text"
              placeholder="Enter Mac ID to get user session"
              name="search"
              value={mac}
              onChange={onChangeForSession}
            />
          </div>

          <div className="mb-3 ml-3 mr-auto">
            <button className="btn btn-green" style={{ height: '39px' }}>
              Search
            </button>
          </div>
        </form>
      </div>
      <div className="card x-panel">
        <div className="table-responsive content">
          <table className="table copy-font">
            <thead>
              <tr>
                <th nowrap="no-wrap">IP Address</th>
                <th> Online Status </th>
                <th nowrap="no-wrap">Last Connected</th>
                <th nowrap="no-wrap"> Static IP </th>
              </tr>
            </thead>
            {fetching ? <small>loading...</small> : <CustomerSessionTable value={session} />}
          </table>
        </div>
      </div>
    </div>
  );
};

export default CustomerSessionDetails;
