import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import CloseIcon from '../icons/CloseIcon';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ openFullScreenDialog, setOpenFullScreenDialog, zoneClicked }) {
  const handleClose = () => {
    setOpenFullScreenDialog(false);
  };

  const convertDate = (date) => {
    const dateObj = new Date(date).toDateString();

    return dateObj;
  };

  return (
    <React.Fragment>
      <Dialog fullScreen open={openFullScreenDialog} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative', background: '#30BFAB' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {zoneClicked !== undefined && zoneClicked.name}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ mx: 9 }}>
          <Typography variant="h6" sx={{ my: 3 }}>
            Zone Details
          </Typography>

          <Divider width={'50%'} />

          <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'} gap={'2rem'} marginTop={2}>
            <Box>
              <Typography fontWeight={400}>Date reported</Typography>
              <Box>
                {zoneClicked !== undefined && zoneClicked.bstation_downtimes.length > 0 ? (
                  zoneClicked.bstation_downtimes.map((val) => (
                    <Typography fontWeight={600} key={val.id}>
                      {convertDate(val.date)}
                    </Typography>
                  ))
                ) : (
                  <Typography fontWeight={600}>no downtimes reported</Typography>
                )}
              </Box>
            </Box>
            <Box>
              <Typography fontWeight={400}>Employee name</Typography>
              <Box>
                {zoneClicked !== undefined && zoneClicked.bstation_downtimes.length > 0 ? (
                  zoneClicked.bstation_downtimes.map((val) => (
                    <Typography fontWeight={600}>{val.employee_name}</Typography>
                  ))
                ) : (
                  <Typography fontWeight={600}>no downtimes reported</Typography>
                )}
              </Box>
            </Box>
            <Box>
              <Typography fontWeight={400}>Down hours</Typography>
              <Box>
                {zoneClicked !== undefined && zoneClicked.bstation_downtimes.length > 0 ? (
                  zoneClicked.bstation_downtimes.map((val) => (
                    <Typography fontWeight={600}>{val.down_hours} hours</Typography>
                  ))
                ) : (
                  <Typography fontWeight={600}>no downtimes reported</Typography>
                )}
              </Box>
            </Box>
            <Box>
              <Typography fontWeight={400}>Reason</Typography>
              <Box>
                {zoneClicked !== undefined && zoneClicked.bstation_downtimes.length > 0 ? (
                  zoneClicked.bstation_downtimes.map((val) => (
                    <Typography fontWeight={600}>{val.reason} hours</Typography>
                  ))
                ) : (
                  <Typography fontWeight={600}>no downtimes reported</Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
