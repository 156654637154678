/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { API_URL } from '../../../../../utils/config';
import 'react-toastify/dist/ReactToastify.css';

class AddBasestation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      address: '',
      loading: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();

    this.setState({
      loading: true,
    });

    const { name, address } = this.state;
    const data = {
      ip: address,
      name,
    };

    axios({
      method: 'post',
      url: `${API_URL}/devicesList`,
      data,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      }
    }).then((response) => {
      if (response.data.id) {
        window.location.reload();
        toast.success('Base station added successfully!');
      } else {
        toast.info('Something went wrong!');
        this.setState({
          loading: false,
        });
      }
    });
  }

  render() {
    const { loading, name, address } = this.state;

    return (
      <div
        id="noc-base"
        className="modal fade in"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center" id="myModalLabel">
                Add Basestation
              </h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
            </div>
            <div className="modal-body">
              <form className="form-horizontal" onSubmit={this.onSubmit}>
                <div className="form-group">
                  <div className="col-12 mb-3">
                    <label className="copy-font m-0 p-0">Basestation Name</label>
                    <input
                      type="text"
                      placeholder="Basestation Name"
                      className="form-control"
                      name="name"
                      onChange={this.onChange}
                      value={name}
                    />
                  </div>

                  <div className="col-12 mb-3">
                    <label className="copy-font m-0 p-0">IP Address</label>
                    <input
                      type="text"
                      placeholder="IP Address"
                      className="form-control"
                      name="address"
                      onChange={this.onChange}
                      value={address}
                    />
                  </div>

                  <div className="col-12 text-center">
                    {loading ? (
                      <button className="btn btn-green">
                        <i className="fa fa-circle-o-notch fa-spin" />
                      </button>
                    ) : (
                      <button className="btn btn-green">Add</button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}
export default AddBasestation;
