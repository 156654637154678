/* eslint-disable import/prefer-default-export */
import { GET_DEPARTMENTS } from '../../../constants/types/shared/DepartmentsTypes';
import { ADMIN } from '../../../utils/departments/helpers';

export const departmentsReducer = (state, action) => {
  const { type, payload } = action;
  if (type === GET_DEPARTMENTS) {
    return {
      ...state,
      departments: payload.filter((department) => department.id !== `${ADMIN}`),
    };
  }
  return state;
};
