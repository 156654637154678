import React, { useContext } from 'react';
import AppContext from '../../../../../context/shared/AppContext';

const SearchLteInstallation = () => {
  const { searchLteInstallation } = useContext(AppContext);
  return (
    <p className="fliter copy-font d-none d-md-flex">
      <small className="mt-2">Filter By:</small>
      <span>
        <select onChange={(e) => searchLteInstallation(e.target.value)} className="form-control">
          <option>Choose Options</option>
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
        </select>
      </span>
    </p>
  );
};

export default SearchLteInstallation;
