/* eslint-disable import/prefer-default-export */
import { useState } from 'react';
import qs from 'qs';
import axios from 'axios';
import toastr from 'toastr';
import * as url from '../../../services/api/customer-support/call-log/CustomerCallLogUrl';

export const useCallLog = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [loaders, setLoaders] = useState(false);
  const [list, setList] = useState('');
  const [logs, setLogs] = useState([]);
  const [analytics, setAnalytics] = useState('');
  const [pages, setPages] = useState('');
  const [page, setPage] = useState(1);
  const [items, setItems] = useState('');
  const [count, setCount] = useState(false);

  const addCallLog = async (data, handleClose, setRequest, oldZone, oldPhone) => {
    setIsLoading(true);
    const config = {
      method: 'post',
      url: `${url.ADD_CALL_LOG_URL}`,
      data: qs.stringify(data),
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response.data.data.id) {
          toastr.success('Added Successfully');
          setIsLoading(false);
          handleClose();
          const result = {
            issue: '',
            resolution: '',
            note: '',
            zoho: '',
            zoned: oldZone,
            phone: oldPhone,
          };
          setRequest(result);
        }
      })
      .catch(() => {
        toastr.info('Something went wrong!');
        setIsLoading(false);
      });
  };

  const getEmployeeLogs = async (data, page) => {
    setIsLoading(true);
    const config = {
      method: 'post',
      url: `${url.GET_EMPLOYEE_LOGS}/${page}`,
      data: qs.stringify(data),
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response.data.list) {
          // toastr.success('Added Successfully');
          setIsLoading(false);
          setLogs(response.data.list);
          setPages(response.data.pages);
        }
      })
      .catch(() => {
        toastr.info('Something went wrong!');
        setIsLoading(false);
      });
  };

  const getCallLog = async (selectedPage) => {
    setLoad(true);
    const config = {
      method: 'get',
      url: `${url.FETCH_CALL_LOGS_URL}/?page=${selectedPage}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response.data) {
          setList(response.data.list);
          setCount(response.data.count);
          setPages(response.data.pages);
        }
        setLoad(false);
      })
      .catch(() => {
        toastr.info('Something went wrong!');
        setLoad(false);
      });
  };

  const getCallLogAnalytics = async (data) => {
    setLoaders(true);
    const config = {
      method: 'post',

      url: `${url.FETCH_CALL_LOG_ANALYTICS}`,
      data: data,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response.data.status === 'success') {
          setAnalytics(response.data.data);
        }
        setLoaders(false);
      })
      .catch((e) => {
        toastr.info(e.response.data.message);
        setLoaders(false);
      });
  };

  const searchUsingKeyword = async (selectedPage, cin) => {
    setLoad(true);
    const config = {
      method: 'get',
      url: `${url.FETCH_CALL_LOGS_URL}/customerLogs/${cin}/?page=${selectedPage}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response.data) {
          setList(response.data.list);
          setCount(response.data.count);
          setPages(response.data.pages);
        }
        setLoad(false);
      })
      .catch(() => {
        toastr.info('Something went wrong!');
        setLoad(false);
      });
  };

  const editCallLog = async (id, data, getCallLog, page, close) => {
    setIsLoading(true);
    const config = {
      method: 'patch',
      url: `${url.UPDATE_CALL_LOGS_URL}/${id}`,
      data: qs.stringify(data),
      headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
    };

    await axios(config)
      .then((response) => {
        if (response.data.message) {
          toastr.success('Update Successful');
          setIsLoading(false);
          close();
          getCallLog(page);
        }
      })
      .catch((error) => {
        if (error) {
          //   toastr.info('Something went wrong mo!');
          setIsLoading(false);
        }
      });
  };

  return {
    list,
    items,
    pages,
    page,
    load,
    count,
    getCallLog,
    isLoading,
    searchUsingKeyword,
    editCallLog,
    logs,
    getEmployeeLogs,
    addCallLog,
    loaders,
    analytics,
    getCallLogAnalytics,
  };
};
