import React from 'react';

export default {
  relocationStatusLabels(status) {
    switch (status) {
      case 'completed':
        return <span className="badge badge-success">{status.toUpperCase()}</span>;

        case 'confirmed':
          return <span className="badge badge-success">{status.toUpperCase()}</span>;
  
      case 'done':
        return <span className="badge badge-success">{status.toUpperCase()}</span>;

      case 'active':
        return <span className="badge badge-success">{status.toUpperCase()}</span>;

      case 'paid':
        return <span className="badge badge-primary">{status.toUpperCase()}</span>;
      case 'rejected':
        return <span className="badge badge-danger">{status.toUpperCase()}</span>;

      case 'not completed':
        return <span className="badge badge-danger">{status.toUpperCase}</span>;

      case 'inactive':
        return <span className="badge badge-danger">{status.toUpperCase}</span>;

      case 'on-going':
        return <span className="badge badge-secondary">{status.toUpperCase}</span>;

      case 'queued':
        return <span className="badge badge-primary">{status.toUpperCase()}</span>;

      case 'pending':
        return <span className="badge badge-warning">{status.toUpperCase()}</span>;

      case 'expired':
        return <span className="badge badge-warning">{status.toUpperCase()}</span>;

      case 'awaiting':
        return <span className="badge badge-warning">{status.toUpperCase()}</span>;

      default:
        return 'no status';
    }
  },
};
