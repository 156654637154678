/* eslint-disable import/prefer-default-export */
export const showContentForDepartment = (department, userDepartment, param) => {
  if (userDepartment === department) {
    return param;
  }
  return null;
};
export const showContentForRole = (role, given, param) => {
  console.log(role);
  console.log(given);
  if (role === given) {
    return param;
  }
  return null;
};
