import React from 'react';
import InstallationZonesBody from './InstallationZonesBody';
import { ZonesProvider } from '../../../shared/installations/zones/provider/ZonesContext';

const InstallationZones = () => {
  return (
    <ZonesProvider>
      <div className="col-12 after-summary">
        <InstallationZonesBody />
      </div>
    </ZonesProvider>
  );
};

export default InstallationZones;
