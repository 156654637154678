/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect } from 'react';
import { useUserProfile } from '../../../../../hooks/shared/user/useUserProfile';
import SalesNavBar from '../../../../layouts/sales/SalesNavBar';
import InstallationsCounterSummary from '../../../shared/installations-counter-summary/InstallationCounterSummary';

const SalesInstallationsCounterSummary = () => {
  const { profile, getUserProfile } = useUserProfile();

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <SalesNavBar currentPage="Installation Counter" position={profile.firstName} />
        <InstallationsCounterSummary />
      </main>
    </div>
  );
};

export default SalesInstallationsCounterSummary;
