import React from 'react';
import StaffPayslipContext from './StaffPayslipContext';
import { useStaff } from '../../../../../../../hooks/shared/staff/useStaff';
import usePayslip from '../../../../../../../hooks/shared/payslip/usePayslip';

const StaffPayslipProvider = ({ children }) => {
  const { staff } = useStaff();
  const { searchChange, searchPayslip, search, searchForEmployeePayslip, payslips, gettingPayslip } = usePayslip();

  return (
    <StaffPayslipContext.Provider
      value={{
        staff,
        searchChange,
        searchPayslip,
        search,
        searchForEmployeePayslip,
        payslips,
        gettingPayslip,
      }}
    >
      {children}
    </StaffPayslipContext.Provider>
  );
};
export default StaffPayslipProvider;
