/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import AddIP from './AddIP';
import { API_URL } from '../../../../../utils/config';
import NocPublicIpTable from './NOCPublicIPTable';
import GetSubscribers from './GetSubscribers';
import { getLoggedInUser } from '../../../../../actions/user/UserActions';
import NocNavBar from '../../../../layouts/noc/NocNavBar';
import { NOC, SUPER_ADMIN } from '../../../../../utils/departments/helpers';

class NetworkPublicIP extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ips: [],
      user: {},
      move: false,
      out: false,
      keyword: '',
      redirect: false,
      filtered: [],
      activePage: 1,
      itemsCountPerPage: 1,
      totalItemsCount: 1,
      pageRangeDisplayed: 3,
      paginate: true,
      pageCount: 0,
      offset: 0,
      role: '',
    };

    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentWillMount() {
    if (!sessionStorage.getItem('token')) {
      this.setState({
        move: true,
      });
    }

    this.fetchLoggedOnUser();
    this.getUserDetails();
  }

  componentDidMount() {
    this.getIps();
    this.props.getLoggedInUser();
    this.checkDepartment();
  }

  componentWillReceiveProps() {
    this.fetchLoggedOnUser();
  }

  getUserDetails() {
    axios({
      method: 'get',
      url: `${API_URL}/employee/loggedOnEmployee`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        this.setState({
          user: data.data,
        });
      })
      .catch(() => {});
  }

  getIps() {
    axios({
      method: 'get',
      url: `${API_URL}/ip/1`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then((response) => {
      if (response.data) {
        this.setState({
          ips: response.data.Ips,
          filtered: response.data.Ips,
          pageCount: response.data.pages,
        });
      } else {
        toastr.info('No Data!');
      }
    });
  }

  handlePageChange = (pageNumber) => {
    const selected = pageNumber.selected + 1;

    axios({
      method: 'get',
      url: `${API_URL}/ip/${selected}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      }
    }).then((response) => {
      if (response.data) {
        this.setState({
          ips: response.data.Ips,
          filtered: response.data.Ips,
          pageCount: response.data.pages,
        });
      } else {
        toastr.info('No data!');
      }
    });
  };

  searchIP = (e) => {
    const keyword = e.target.value;

    const { ips } = this.state;

    if (keyword !== '') {
      const list = ips.filter((ip) => {
        if (ip.ip_Address.indexOf(keyword) !== -1) {
          return ips;
        }

        if (ip.private_Ip1.indexOf(keyword) !== -1) {
          return ip;
        }

        if (ip.baseStation.name.toLowerCase().indexOf(keyword) !== -1) {
          return ip;
        }
      });
      this.setState({
        filtered: list,
        keyword: e.target.value,
      });
    } else {
      this.setState({
        filtered: ips,
        keyword,
      });
    }
  };

  fetchLoggedOnUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employeeReg/logged`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        this.setState({
          role: data.data.role_id,
        });

        if (data.data.department_id !== `${NOC}` && data.data.department_id !== `${SUPER_ADMIN}`) {
          sessionStorage.setItem('data', '');
          sessionStorage.clear();
          this.setState({
            out: true,
          });

          toastr.info('You are not allowed to view this page');
        }
      })
      .catch(() => {});
  }

  checkDepartment() {
    // console.log(this.props);
  }

  render() {
    const { user } = this.state;
    const { firstName } = user;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <NocNavBar currentPage="IP Allocation" position={firstName} />

          <div className="container">
            <div className="row mt-5">
              <div className="mt-3 d-flex flex-wrap col-lg-12 col-md-12 col-sm-12">
                <div className="mt-3 mr-3 input-group col-lg-7 col-md-6 col-sm-12">
                  <div className="input-group-prepend">
                    <span className="input-group-text fa fa-search" />
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    value={this.state.keyword}
                    onChange={(e) => this.searchIP(e)}
                    placeholder="Search with AP SSID, Public IP Address"
                  />
                </div>

                <div className="mt-3 mx-3">
                  <button className="btn btn-green" data-toggle="modal" data-target="#AddIP" type="button">
                    {' '}
                    Add IP Address
                  </button>
                  <AddIP />
                </div>

                <div className="mt-3">
                  <button className="btn btn-green" data-toggle="modal" data-target="#getSubscribers" type="button">
                    {' '}
                    Get Subscribers
                  </button>
                  <GetSubscribers />
                </div>
              </div>

              <div className="card x-panel mt-3">
                <div className="table-responsive content">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Basestation Name</th>
                        <th>Public IP Address</th>
                        <th>Private IP 1 Address</th>
                        <th>Private IP 2 Address</th>
                        <th>Private IP 2 Address</th>
                      </tr>
                    </thead>
                    <NocPublicIpTable ip={this.state.filtered} />
                  </table>
                </div>
              </div>
              {/* <AdminPaginate/> */}
              <div className="page col-lg-12 col-md-12 col-sm-12 d-flex">
                {/* paginate */}
                <ReactPaginate
                  previousLabel="previous"
                  nextLabel="next"
                  breakLabel="..."
                  breakClassName="break-me"
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={this.state.pageRangeDisplayed}
                  onPageChange={this.handlePageChange}
                  containerClassName="pagination"
                  subContainerClassName="pages pagination"
                  activeClassName="active"
                />
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.auth,
});

export default connect(mapStateToProps, {
  getLoggedInUser,
})(NetworkPublicIP);
