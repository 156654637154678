import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

export default function AssetZoneTableData({ getZones, assetZoneData }) {
  // console.log(isLoading);
  return (
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{ width: 250 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bolder' }}>Zones</TableCell>
              <TableCell sx={{ fontWeight: 'bolder' }}>Count</TableCell>
            </TableRow>
          </TableHead>

          {/* sx={{ '&:last-child td, &:last-child th': { border: 0 } }}> */}
          <TableBody>
            {getZones.map((row, index) => (
              <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                {<TableCell> {!assetZoneData[index] ? '...' : assetZoneData[index].count}</TableCell>}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
