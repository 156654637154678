import { useEffect, useReducer } from 'react';
import axios from 'axios';
import { customReducer } from '../../../../reducers/shared/custom/CustomReducer';
import { LOADING_STARTS, LOADING_STOPS } from '../../../../constants/types/shared/LoadingTypes';
import { GET_DATA } from '../../../../constants/types/shared/CustomTypes';

const useFetch = (url) => {
  const [{ data, loading }, dispatch] = useReducer(customReducer, {
    data: [],
    loading: false,
  });

  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  const getPaginatedData = (pageUrl) => {};
  useEffect(() => {
    async function getData() {
      dispatch({
        type: LOADING_STARTS,
      });
      try {
        return await axios.get(url, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });
      } catch (e) {
        return e;
      }
    }
    getData()
      .then((response) => {
        dispatch({
          type: GET_DATA,
          payload: response.data,
        });
      })
      .catch(() => dispatch({ type: LOADING_STOPS }));
  }, [url]);
  return { data, loading, getPaginatedData };
};

export default useFetch;
