/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Moment from 'react-moment';

import { useCallLog } from '../../../../../hooks/customer-support/call-logs/useCallLog';
import { TizetiGIFLogoTable } from '../../../../../utils/loader/loadingHelper';
import CallLogsTable from './CallLogsTable';
import ReactPaginate from 'react-paginate';
import CustomCsvDownload from '../../../shared/CustomCsvDownload';
import { filename } from '../../../../../utils/field-support/installations/helpers';
import { IconButton } from '@mui/material';
import CloseIcon from '../../noc/downtime-logger/icons/CloseIcon';

const ViewCallLog = ({ setShow, show, pages, list, load, handlePageClick }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '180vh',
    height: '100vh',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    // boxShadow: 24,
    p: 10,
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          View Call Log
          <IconButton className="float-right" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Typography>

        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <CallLogsTable lists={list} load={load} />

          <div className="px-3">
            {pages > 1 ? (
              <ReactPaginate
                previousLabel="previous"
                nextLabel="next"
                breakLabel="..."
                breakClassName="break-me"
                pageCount={pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName="pagination"
                // subContainerClassName="pages pagination"
                activeClassName="active"
              />
            ) : null}
          </div>
        </Typography>
      </Box>
    </Modal>
  );
};

export default ViewCallLog;
