import React, { Component } from 'react';
import ViewDirectory from './ViewDirectory';
import Warning from '../Warning';
import AdminPaginate from '../../../../layouts/super-admin/AdminPaginate';

class WifiCallDirectoryTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      directoryId: {},
    };
  }

  getDirectory(directory) {
    this.setState({
      directoryId: directory,
    });
  }

  render() {
    const click = this.props.pass;

    return (
      <tbody>
        {this.props.filtered.map((directory, index) => (
          <tr key={index} className="copy-font">
            <td scope="col">{index + 1}</td>
            <td>
              {directory.businessName} {directory.firstName}
            </td>
            <td>{directory.lastName}</td>
            <td>{directory.countryCode}</td>
            <td>{directory.phoneNumber}</td>
            <td>{directory.address}</td>
            <td>{directory.category}</td>
            <td>{directory.twitter}</td>
            <td>{directory.Instagram}</td>
            <td>{directory.type}</td>
            <td>
              <div className="toogle-thing">
                <label className="switch">
                  <input type="checkbox" className="toogle" />
                  <span className="slider round" />
                </label>
              </div>
            </td>
            <td>
              <div className="ml-2">
                <button
                  data-id={directory.id}
                  onClick={() => this.getDirectory(directory)}
                  data-toggle="modal"
                  data-target="#view"
                  type="submit"
                  style={{
                    border: '0',
                    backgroundColor: 'white',
                  }}
                >
                  <span className="view1">
                    <img
                      alt="viewicon"
                      src="https://res.cloudinary.com/tizeti/image/upload/v1542971020/view.png"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="View"
                    />
                  </span>
                </button>
                <ViewDirectory directory={this.state.directoryId} />
                <button
                  type="submit"
                  data-toggle="modal"
                  data-target="#edit"
                  style={{
                    border: '0',
                    backgroundColor: 'white',
                  }}
                >
                  <span className="view">
                    <img
                      alt="editicon"
                      src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/edit.png"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Edit"
                    />
                  </span>
                </button>
                &nbsp;
                <button
                  type="submit"
                  data-toggle="modal"
                  data-target="#cancel"
                  style={{
                    border: '0',
                    backgroundColor: 'white',
                  }}
                >
                  <span className="view">
                    <img
                      alt="deleteicon"
                      src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/delete.png"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Delete"
                    />
                  </span>
                </button>
                <Warning />
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    );
  }
}

export default WifiCallDirectoryTable;
