import React from 'react';

const tabledata = ({ transactions }) => {
  return transactions.map((item, key) => (
    <tr className="copy-font" key={item.reference}>
      <th scope="row">{key + 1}</th>
      <td>{item.reference}</td>
      <td>{item.accountid}</td>
      <td>{item.businessName}</td>
      <td>{item.email}</td>
      <td>{item.phone}</td>
      <td className="text-center">
        <span className="amount">&#8358; </span>{' '}
        {item.amount}
      </td>
      <td>{item.time}</td>
    </tr>
  ));

  // <div>
  //     <p>this is the size of the array{props.size}</p>
  // </div>
};

export default tabledata;
