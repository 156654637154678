/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

const Details = ({ one }) => {
  const {
    name,
    unit,
    total_with_vat,
    total_ex_vat,
    total,
    sub_total,
    quantity,
    price,
    mac_id,
    item_description,
    item_code,
  } = one;

  return (
    <div
      id="dedicated-detail"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title ml-5" id="myModalLabel">
              Dedicated Customer Details
            </h4>
            <button type="button" className="close custom-close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body detail" style={{ padding: '0', textAlign: 'justify' }}>
            <div className="row">
              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label1">
                  <label id="fn">Name</label>
                  <label id="ln">Unit</label>
                </div>
                <div className="col-lg-8 detail-result1">
                  {name ? <p className="mt-4 mb-2">{name}</p> : <p className="mt-4">N/A</p>}
                  {unit ? <p className="mb-2">{unit}</p> : <p className="mt-4">N/A</p>}
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label2">
                  <label>Total With Vat</label>
                  <label>Total W/O VAT</label>
                  {/* <label >AF5U</label> */}
                </div>
                <div className="col-lg-8 detail-result2">
                  {total_with_vat ? <p className="mt-4 mb-2">{total_with_vat}</p> : <p className="mt-4">N/A</p>}
                  {total_ex_vat ? <p className="mb-2">{total_ex_vat}</p> : <p className="mt-4">N/A</p>}
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label3">
                  <label>Total</label>
                </div>
                <div className="col-lg-8 detail-result3">
                  {total ? <p className="mt-4">{total}</p> : <p className="mt-4">N/A</p>}
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label1">
                  <label id="fn">Sub Total</label>
                  <label id="ln">Quantity</label>
                  {/* <label id="email">Station</label> */}
                </div>
                <div className="col-lg-8 detail-result1">
                  {sub_total ? <p className="mt-4 mb-2">{sub_total}</p> : <p className="mt-4">N/A</p>}
                  {quantity ? <p className="mb-2">{quantity}</p> : <p className="mt-4">N/A</p>}
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label2">
                  <label>Price</label>
                  <label>MAC ID</label>
                  {/* <label >AF5U</label> */}
                </div>
                <div className="col-lg-8 detail-result2">
                  {price ? <p className="mt-4 mb-2">{price}</p> : <p className="mt-4">N/A</p>}
                  {mac_id ? <p className="mb-2">{mac_id}</p> : <p className="mt-4">N/A</p>}
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label1">
                  <label id="fn">Item Description</label>
                  <label id="ln">Item Desctiption</label>
                  {/* <label id="email">Station</label> */}
                </div>
                <div className="col-lg-8 detail-result1">
                  {item_description ? <p className="mt-4 mb-2">{item_description}</p> : <p className="mt-4">N/A</p>}
                  {item_code ? <p className="mb-2">{item_code}</p> : <p className="mt-4">N/A</p>}
                  {/* <p className="mb-2">192.128.83.70</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Details.propTypes = {
  one: PropTypes.shape(),
};
export default Details;
