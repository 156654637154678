import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import useKyc from '../../../hooks/shared/noc/lte/kyc/useKyc';

export const KycContext = createContext({});

export const KycProvider = ({ children }) => {
  const { customers, pages, fetching, handlePageClick } = useKyc();

  return <KycContext.Provider value={{ customers, pages, fetching, handlePageClick }}>{children}</KycContext.Provider>;
};

KycProvider.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.shape(),
};
