import React from 'react';
import useFiberSitesPublicIp from '../hooks/useFiberSitesPublicIp';
import AppContext from '../../../../../../../../context/shared/AppContext';
import { FIBER_SITES_PUBLIC_IP_URL } from '../api/FiberSitesPublicIpUrl';

const FiberSitesPublicIpProvider = ({ children }) => {
  const { data, loading, paginateChange, loadingForm, onChange, formValue, onSubmit, pages } = useFiberSitesPublicIp(
    `${FIBER_SITES_PUBLIC_IP_URL}`,
  );

  return (
    <AppContext.Provider
      value={{
        data,
        loading,
        paginateChange,
        loadingForm,
        onChange,
        formValue,
        onSubmit,
        pages,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export default FiberSitesPublicIpProvider;
