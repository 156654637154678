/* eslint-disable no-irregular-whitespace */
/* eslint-disable import/prefer-default-export */
export const messageToBeSent = (type, staffName) => {
  switch (type) {
    case 'inform':
      return (
        'Hello,' +
        'We are glad to inform you that Wifi.com.ng is Live in Arepo today and you can start enjoying our unlimited internet service Starting from Now. ' +
        'Kindly respond by sending a mail to sales@tizeti.com or call 0908 749 3050 to get connected. ' +
        'Thank you'
      );
    case 'promo':
      return `Hello , This is ${staffName} from Tizeti, We had a discussion about our unlimited internet plan. I wish to update you about our ongoing promo that allows you to get an additional one-month free. Kindly let me know when is convenient for you. You can reach me on 0908 749 3050. Thank you.`;
    case 'follow-up':
      return `Hello,
This is ${staffName} from Tizeti, I wish to follow up on our last discussion on setting up unlimited internet at your office. 
We have a new development I know will be an added advantage for you, kindly let me know when is convenient to enable us to discuss more. 
Thank you. `;
    default:
      return '';
  }
};
