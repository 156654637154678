import React from 'react';
import NocNavBar from '../../../../layouts/noc/NocNavBar';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import BandwidthGraphBody from './BandwidthGraphBody';

const BandwidthGraph = () => {
  const { profile } = useUser();
  const { firstName } = profile;
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <NocNavBar currentPage="Bandwidth Graph" position={firstName} />
        <div className="mt-5">
          <BandwidthGraphBody />
        </div>
      </main>
    </div>
  );
};

export default BandwidthGraph;
