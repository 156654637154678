/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import toastr from 'toastr';
import { getUser } from '../../../../../actions/shared/user/UserActions';
import { getLoggedInUser } from '../../../../../actions/user/UserActions';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import { PROCUREMENT } from '../../../../../utils/config';
import Requisitions from '../../../shared/requisition/shared/Requisitions';
import ProcurementNavBar from '../../../../layouts/procurement/ProcurementNavBar';
import { SUPER_ADMIN } from '../../../../../utils/departments/helpers';

class ProcurementRequisition extends Component {
  componentDidMount() {
    this.checkDepartment();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.checkDepartment();
  }

  checkDepartment = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.department_id !== `${PROCUREMENT}` && response.data.department_id !== `${SUPER_ADMIN}`) {
        this.props.history.push('/');

        sessionStorage.setItem('token', '');
        sessionStorage.setItem('email', '');
        sessionStorage.clear();

        toastr.info('You are not allowed to view this page');
      }
    } catch (e) {
      //   console.log(e.response);
    }
  };

  render() {
    const { user } = this.props;
    const { firstName } = user;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <ProcurementNavBar currentPage="Requisition" position={firstName} />

          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-4">
                <Requisitions />
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect(
  mapStateToProps,
  {
    getUser,
    getLoggedInUser,
  },
)(ProcurementRequisition);
