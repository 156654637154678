/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import SheetAndCnMaestroSidebarItem from '../shared/SheetAndCnMaestroSidebarItem';
import PATHS from '../../../../routes/urls';

const CnMaestroSidebar = () => {
  return (
    <>
      <span className="s-sidebar__nav-link d-none d-md-block" data-toggle="collapse" data-target="#noc-cnmaestro">
        <li>
          <Link to="#" target="_self" className="">
            <span className="mr-2">
              <img src="https://res.cloudinary.com/teewhy/image/upload/v1547725174/IP_Allocation.png" alt="img" />
            </span>
            CNMAESTRO
          </Link>
        </li>
      </span>
      <span id="noc-cnmaestro" className="collapse base-li top-bottom">
        <SheetAndCnMaestroSidebarItem url={PATHS.NOC_CNMAESTRO_TOWERS} name="Towers" />
        <SheetAndCnMaestroSidebarItem url={PATHS.NOC_CNMAESTRO_STATISTICS} name="Statistics" />
        <SheetAndCnMaestroSidebarItem url={PATHS.NOC_CNMAESTRO_SITES} name="Sites" />
        <SheetAndCnMaestroSidebarItem url={PATHS.NOC_CNMAESTRO_PERFORMANCE} name="Performance" />
        <SheetAndCnMaestroSidebarItem url={PATHS.NOC_CNMAESTRO_NETWORKS} name="Networks" />
        <SheetAndCnMaestroSidebarItem url={PATHS.NOC_CNMAESTRO_ALARMS} name="Alarms" />
        <SheetAndCnMaestroSidebarItem url={PATHS.NOC_CNMAESTRO_DEVICES} name="Devices" />
        <SheetAndCnMaestroSidebarItem url={PATHS.NOC_STATIC_ACCESS_POINTS} name="Static Access Points" />
        <SheetAndCnMaestroSidebarItem url={PATHS.NOC_REBOOT} name="Reboot" />
      </span>
    </>
  );
};

export default CnMaestroSidebar;
