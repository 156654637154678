/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import helpers from '../../../../utils/helpers';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../utils/loader/loadingHelper';
// import { CommentMessageLogsProps } from './types';

const MessageLogsTable = ({ data, loading }) => {
  const colSpan = '8';
  const tableData =
    data && data.length ? (
      data.map((cust, i) => {
        const { customer_email, name, username, basestation, agent, message, time, phone } = cust;
        return (
          <tr key={i}>
            <td>{i + 1}</td>
            <td>{username}</td>
            <td>{name}</td>
            <td>{customer_email}</td>
            <td>{phone}</td>
            <td>{basestation.toUpperCase()}</td>
            <td>{message}</td>
            <td>{agent}</td>
            <td className="text-nowrap">{helpers.formatDateAndTime(time)}</td>
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan={colSpan} text="No Log" />
    );

  return (
    <div className="card x-panel mt-2 px-2">
      <div className="table-responsive content col-12">
        <table className="table">
          <thead>
            <tr className="text-nowrap">
              <th>#</th>
              <th>MAC ID</th>
              <th>Name</th>
              <th>Customer Email</th>
              <th>Customer Phone</th>
              <th>Basestation</th>
              <th>Message</th>
              <th>Staff</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>{loading ? <TizetiGIFLogoTable colSpan={colSpan} /> : tableData}</tbody>
        </table>
      </div>
    </div>
  );
};

export default MessageLogsTable;
