import { API_URL } from '../../../../utils/config';

export const CREATE_ACCOUNT_URL = `${API_URL}/account`;
export const NEW_CREATE_ACCOUNT_URL = `${API_URL}/account/newAccount`;
export const GET_INSTALLATIONS_URL = `${API_URL}/installations`;
export const GET_RESCHEDULED_INSTALLATIONS_URL = `${API_URL}/RescheduledInstallations`;
export const GET_BILLING_ESTATES_URL = `${API_URL}/billing/getList`;
export const EDIT_INSTALLATION_STATUS_URL = `${API_URL}/installation`;
export const SEARCH_INSTALLATION_URL = `${API_URL}/searchinstallation`;
export const GET_INSTALLATION_COUNTER = `${API_URL}/installationSummary`;
export const SEARCH_INSTALLATIONS_URL = `${API_URL}/searchinstallation/1`;
export const GET_DIMENSIONS_URL = `${API_URL}/account/dimension/get`;
export const GET_CALL_STATION_URL = `${API_URL}/account/callStation`;
export const SEARCH_FOR_CUSTOMER_URL = `${API_URL}/users/searchuser`;
export const GET_BASESTATIONS_URL = `${API_URL}/baseStation`;

export const GET_INSTALLATION_TARGET_URL = `${API_URL}/installation/target`;
export const GET_MONTHLY_INSTALLATION_URL = `${API_URL}/monthlyinstallations`;
export const DAILY_INSTALLATION_URL = `${API_URL}/dailyinstallations`;
export const LEGACY_FRONTACCOUNT_URL = `https://legacy.tizeti.com/frontaccountapi/account.php`;
export const LEGACY_GENIATECH_URL = `https://legacy.tizeti.com/geniatechapi/index.php`;
export const RETENSION_SMS = `https://api.tizeti.com/api/v1/notification/sendSMS`;
export const CLUSTER_URL = `https://legacy.tizeti.com/cluster/index.php`;
export const SEARCHIMSI = `${API_URL}/open5gs/searchByimsi`;
export const CLUSTER_ROUTING_URL = `https://legacy.tizeti.com/cluster/routing.php`;
export const DOWNLOAD_CUSTOMER_INVOICE_URL = `https://legacy.tizeti.com/operations_api/newinvoice.php`;
export const SEARCH_CUSTOMER_INVOICE_URL = `https://legacy.tizeti.com/operations_api/download_invoice.php`;
export const GENERATE_CUSTOMER_INVOICE_URL = `https://legacy.tizeti.com/operations_api/receipt.php`;
export const ASSIGN_INSTALLATION_URL = `${API_URL}/assign`;
export const GET_USER_ASSIGNED_INSTALLATION = `${API_URL}/assign/employees`;

export const BULK_ASSIGN_INSTALL_TEAM = `${API_URL}/bulkassigninstallationteam`;
export const DELETE_INSTALLATION_URL = `/installation`;

export const QA_INSTALLATIONS_REPORTS = `${API_URL}/account/get_qa_installation_report`