/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useContext } from 'react';
import { PtpContext } from './context/PtpContext';
import CustomButton from '../../../../shared/forms/CustomButton';
import CustomInput from '../../../../shared/forms/CustomInput';
import Label from '../../../../shared/forms/Label';

const AddPtp = () => {
  const { loadingForm, onChange, formValue, onSubmit } = useContext(PtpContext);
  const {
    ap_end,
    station_end,
    device_name,
    ssid,
    device_type,
    security,
    frequency,
    link_distance,
    capacity,
    usage,
  } = formValue;
  const submitForm = () => {
    return (
      usage.length > 0 &&
      ap_end.length > 0 &&
      device_type.length > 0 &&
      station_end.length > 0 &&
      security.length > 0 &&
      frequency.length > 0 &&
      link_distance.length > 0 &&
      device_name.length > 0 &&
      ssid.length > 0 &&
      capacity.length > 0
    );
  };
  return (
    <div id="add-access-point" className="modal fade mt-5" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header shadow">
            <h4 className="modal-title update_asset">PTP</h4>
            <button type="button" className="btn btn-outline-info btn-circle btn-close" data-dismiss="modal">
              x
            </button>
          </div>
          <div className="modal-body detail mb-4 p-0">
            <form
              className="col-md-12 p-0"
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              <div className="d-flex">
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="customerName">
                    AP END
                  </label>
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="ap_end"
                    value={ap_end}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="AP END"
                    type="text"
                    id="customerName"
                  />
                </div>
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="customerEmail">
                    STATION END
                  </label>
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="station_end"
                    value={station_end}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="Station End"
                    type="text"
                    id="customerEmail"
                  />
                </div>
              </div>

              <div className="d-flex">
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="router_id">
                    Device Name
                  </label>
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="device_name"
                    value={device_name}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="Device Name"
                    type="text"
                    id="router_id"
                  />
                </div>
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="router_id">
                    DEVICE TYPE
                  </label>
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="device_type"
                    value={device_type}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="Device Type"
                    type="text"
                    id="router_id"
                  />
                </div>
              </div>

              <div className="d-flex">
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="customerName">
                    Security
                  </label>
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="security"
                    value={security}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="Security"
                    type="text"
                    id="customerName"
                  />
                </div>
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="customerEmail">
                    Frequency
                  </label>
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="frequency"
                    value={frequency}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="Frequency"
                    type="text"
                    id="customerEmail"
                  />
                </div>
              </div>
              <div className="d-flex">
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="router_id">
                    Link Distance
                  </label>
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="link_distance"
                    value={link_distance}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="Link Distance"
                    type="text"
                    id="router_id"
                  />
                </div>
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="Capacity" htmlfor="router_id" />

                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="capacity"
                    value={capacity}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="Capacity"
                    type="number"
                    id="router_id"
                  />
                </div>
              </div>
              <div className="d-flex">
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="Usage" htmlfor="customerName" />
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="usage"
                    value={usage}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="Usage"
                    type="number"
                    id="customerName"
                  />
                </div>

                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="SSID" htmlfor="customerName" />
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="ssid"
                    value={ssid}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="SSID"
                    type="text"
                    id="customerName"
                  />
                </div>
              </div>
              <div className="text-center mt-3">
                {loadingForm ? (
                  <CustomButton classname="btn btn-green px-3" name="Adding" disabled={loadingForm} />
                ) : (
                  <CustomButton classname="btn btn-green px-3" name="Add" disabled={!submitForm()} />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPtp;
