/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
// import Logout from "../super-admin/Logout";
import axios from 'axios';
import toastr from 'toastr';
import { Link } from 'react-router-dom';
import ReactToExcel from 'react-html-table-to-excel';
import HotspotAdminLogo from '../../../../../../layouts/HotspotAdminLogo/HotspotAdminLogo';
import AdminSideBar from '../../../../../../layouts/HotspotAdminSideBar/AdminSideBar';
import urls from '../../../../../../../services/api/hotspot/HotspotAdminUrls';
import Logout from '../../../../../shared/auth/Logout';
import TransactionTable from '../ui-components/TransactionTable';
import WalletActivationsTable from '../ui-components/WalletActivationsTable';

class HotSpotAdminTransactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      TransactionList: [],
      ActivationsList: [],
      items: [],
      keyword: '',
      filtered: [],
      transactionList: [],
    };
  }

  componentDidMount() {
    this.GetAllTransactions();
    this.GetListWalletActivations();
  }

  filterList(e) {
    const keyword = e.target.value.toLowerCase();

    if (keyword !== '') {
      const list = this.state.TransactionList.filter((customer) => {
        if (customer.accountid.toLowerCase().indexOf(keyword) !== -1) {
          return customer;
        }
        if (customer.reference.toLowerCase().indexOf(keyword) !== -1) {
          return customer;
        }
        if (customer.businessName.toLowerCase().indexOf(keyword) !== -1) {
          return customer;
        }
        if (customer.custaccount.toLowerCase().indexOf(keyword) !== -1) {
          return customer;
        }
        /* if(customer.phone.toLowerCase().indexOf(keyword)!== -1) {
                    return customer;
                }
                if(customer.email.toLowerCase().indexOf(keyword)!== -1) {
                    return customer;
                }
                if(customer.businessName.toLowerCase().indexOf(keyword)!== -1) {
                    return customer;
                }
                if(customer.accountid.toLowerCase().indexOf(keyword)!== -1) {
                    return customer;
                } */
      });

      const listData = this.state.ActivationsList.filter((activation) => {
        if (activation.accountid.toLowerCase().indexOf(keyword) !== -1) {
          return activation;
        }
        // if(activation.reference.toLowerCase().indexOf(keyword)!== -1){
        //     return activation;
        // }
        // if(activation.businessName.toLowerCase().indexOf(keyword)!== -1)  {
        //     return activation;
        // }
        // if(activation.custaccount.toLowerCase().indexOf(keyword)!== -1) {
        //     return activation;
        // }
      });

      this.setState({
        filtered: list,
        transactionList: listData,
        keyword: e.target.value,
      });
    } else {
      this.setState({
        filtered: this.state.TransactionList,
        transactionList: this.state.ActivationsList,
        keyword,
      });
    }
  }

  GetAllTransactions() {
    axios({
      method: 'get',
      url: urls.base_URL + urls.get_Transactions,
    })
      .then((response) => {
        // console.log(response.data.status);
        if (response.data.status) {
          this.setState(
            {
              ActivationsList: response.data.data,
              transactionList: response.data.data,
            },
            () => this.state.ActivationsList,
          );
        }
      })
      .catch((error) => {
        toastr.error('Failed to fetch Transactions, Please check your internet connection');
      });
  }

  GetListWalletActivations() {
    axios({
      method: 'get',
      url: urls.base_URL + urls.get_List_WalletActivations,
    })
      .then((response) => {
        // console.log(response.data.status);
        // console.log(
        //   `GetListWalletActivations success : ${response.data.data}`,
        // );
        if (response.data.status) {
          this.setState(
            {
              TransactionList: response.data.data,
              filtered: response.data.data,
            },
            () => console.log(this.state.TransactionList),
          );
        }
      })
      .catch((error) => {
        toastr.error('Failed to Wallet Transactions, Please check your internet connection');
      });
  }

  render() {
    //  const { filtered , transactionList} = this.state;
    return (
      <div className="container-fluid">
        <header className="topbar shade bg-white fixed-top">
          <nav className="navbar navbar-expand-lg navbar-light top-navbar fixed-top" id="nav">
            <HotspotAdminLogo />
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse mt-3 wifii" id="navbarText">
              <ul className="navbar-nav">
                <li className="nav-item active">
                  <Link className="nav-link" to="#">
                    <h3>
                      Transactions
                      <span className="sr-only">(current)</span>
                    </h3>
                  </Link>
                </li>
              </ul>
              <span className="navbar-text">
                <h6 className="admin">
                  Admin
                  <Logout />
                </h6>
              </span>
            </div>
          </nav>
        </header>

        <div className="all-sides col-lg-12 col-md-12 col-sm-12">
          <AdminSideBar />
          <div className="page-wrapper col-lg-10 col-md-9 col-sm-9">
            <div className="container-fluid">
              <div className="col-12 d-flex p-0" style={{ marginTop: '9%' }}>
                <div className="col-6 p-0 mr-auto">
                  <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li className="nav-item shadow-sm mr-4">
                      <a
                        className="nav-link active"
                        id="pills-home-tab"
                        data-toggle="pill"
                        href="#pills-home"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        Activations
                      </a>
                    </li>
                    <li className="nav-item  shadow-sm">
                      <a
                        className="nav-link"
                        id="pills-profile-tab"
                        data-toggle="pill"
                        href="#pills-profile"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        Wallet Fund
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="col-6 d-flex transaction-page">
                  <small className="mr-2">Search By:</small>
                  <span>
                    <input
                      type="text"
                      className="form-control p-2"
                      placeholder="Retailer ID"
                      value={this.state.keyword}
                      onChange={(e) => this.filterList(e)}
                    />
                  </span>
                </div>
              </div>

              <div className="col-12 tab-content shadow-sm p-0" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <ul
                    className="nav nav-pills"
                    id="pills-tab"
                    // role="tablist"
                  >
                    <li className="nav-item shadow-sm">
                      <a className="nav-link">
                        <ReactToExcel
                          table="tab-xls"
                          filename="Transactions"
                          sheet="sheet 1"
                          buttonText="Export Activations"
                        />
                      </a>
                    </li>
                  </ul>
                  <table className="table" id="tab-xls">
                    <thead>
                      <tr className="copy-font">
                        <th scope="col">#</th>
                        <th scope="col">Transaction ID</th>
                        <th scope="col">Account Name (Customer)</th>
                        <th scope="col">Plan</th>
                        <th scope="col">RetailerID</th>
                        <th scope="col">BusinessName</th>
                        <th scope="col" className="text-center">
                          Amount Deducted
                        </th>
                        <th scope="col">Date</th>
                      </tr>
                    </thead>

                    <tbody>
                      <WalletActivationsTable
                        wallettransactions={this.state.filtered}
                        size={this.state.filtered.length}
                      />
                    </tbody>
                  </table>
                </div>

                <div
                  className="tab-pane fade show "
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <table className="table">
                    <thead>
                      <tr className="copy-font">
                        <th scope="col">#</th>
                        <th scope="col">Transaction ID</th>
                        <th scope="col">Account ID</th>
                        <th scope="col">Business Name </th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone Number</th>
                        <th scope="col" className="text-center">
                          Amount Paid
                        </th>
                        <th scope="col">Date</th>
                      </tr>
                    </thead>

                    <tbody>
                      <TransactionTable
                        transactions={this.state.transactionList}
                        size={this.state.transactionList.length}
                      />
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HotSpotAdminTransactions;
