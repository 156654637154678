/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import DeviceTypesTable from './DeviceTypesTable';
import useDeviceTypes from './useDeviceTypes';


const DeviceTypesBody = () => {
  const { getDeviceTypes, items, isLoading, fetchDeviceTypes, loader, list } = useDeviceTypes();

  const today = new Date();
  const formattedTodayDate = today.toISOString().slice(0, 10);
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 6);
  // Format the date as a string if needed
  const formattedYesterday = yesterday.toISOString().split('T')[0];
  const [from, setFrom] = useState(formattedYesterday);
  const [to, setTo] = useState(formattedTodayDate)

  console.log(list)

  const [deviceTypes, setDeviceTypes, ] = useState("cambium")

  const onSubmit = (e) => {
    e.preventDefault();

    const data = {
      from: from,
      to: to,
      deviceTypes: deviceTypes,
    }

    getDeviceTypes(data);
  };

  useEffect(() => {
    const data = {
      from: from,
      to: to,
      deviceTypes: deviceTypes,
    }

    fetchDeviceTypes();
    getDeviceTypes(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mt-5 p-3">
      <div className="container-fluid pt-4 ">
        <div className="row">
          <form className="form-inline mt-4 inner-addon d-flex" onSubmit={onSubmit}>
            <div className="form-group mx-2">
              <label className="mt-2 p-0 copy-font">From: </label>
              <input
                className="form-control mr-2"
                name="from"
                value={from}
                onChange={(e) => setFrom(e.target.value)}
                readOnly={false}
                type="date"
              />

              <label className="mt-2 p-0 copy-font">To: </label>
              <input
                className="form-control mr-2"
                name="to"
                value={to}
                onChange={(e) => setTo(e.target.value)}
                readOnly={false}
                type="date"
              />

              
<label className="mt-2 p-0 copy-font">Device Type: </label>

                <select name="chooseField" onChange={(e) => setDeviceTypes(e.target.value)} value={deviceTypes} className="form-control">
                  <option value="">--Choose Device Type--</option>
                 {list && list.map((lis, i) =>(
                  <option key={i} value={lis.device_type}> {lis.device_type}  </option>
                 ))}
                
                </select>
              </div>

   

            <div className="mb-3">
              <button className="btn btn-green" style={{ height: '39px' }}>
                Search
              </button>
            </div>
          </form>

          <DeviceTypesTable items={items} isLoading={isLoading} />
        </div>
      </div>
    </div>
  );
};

export default DeviceTypesBody;
