import React from 'react';
import PropTypes from 'prop-types';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';

const BasestationDataTable = ({ items, isLoading }) => {
  const tableData =
    items.length && items
      ? items.map((item) => {
          return (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.username}</td>
              <td>{item.datauser}</td>
              <td>{item.connection_counts}</td>
              <td>{item.total_duration}</td>
              <td>{item.uploaded}</td>
              <td>{item.downloaded}</td>
            </tr>
          );
        })
      : 'Usage statistics';

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>#</th>
            <th>MAC</th>
            <th>User</th>
            <th>Connections Number</th>
            <th>Duration</th>
            <th>Upload</th>
            <th>Download</th>
          </tr>
        </thead>

        <tbody>
          {isLoading ? (
            <tr>
              <td>
                <img src={loader} alt="loading gif" />
              </td>
            </tr>
          ) : (
            tableData
          )}
        </tbody>
      </table>
    </>
  );
};

BasestationDataTable.propTypes = {
  items: PropTypes.arrayOf.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default BasestationDataTable;
