/* eslint-disable no-empty */
/* eslint-disable no-undef */
/* eslint-disable no-shadow */
/* eslint-disable react/require-default-props */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CustomerSupportSideBar from '../../../layouts/customer-support/CustomerSupportSideBar';
import Logout from '../../shared/auth/Logout';
import mailblast from '../../../../assets/img/icons/mailblast.svg';
import RetentionScript from '../../shared/customer-support/RetentionScript';
import CustomerServiceScript from '../../shared/CustomerServiceScript';
import { getLoggedInUser } from '../../../../actions/user/UserActions';
import { getUser } from '../../../../actions/shared/user/UserActions';
import Expired from '../../shared/expired/Expired';

class CustomerSupportExpired extends Component {
  componentDidMount() {
    const { getUser, getLoggedInUser } = this.props;
    getUser();
    getLoggedInUser();
  }

  render() {
    const { user, role } = this.props;
    const { role_id } = role;
    const { firstName } = user;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <CustomerSupportSideBar />

              <h3 className="col-md-5 overview-part">Expired Customers</h3>

              <div className="ml-5 d-flex profile-mail">
                {Number(role_id) > 2 ? (
                  <Link
                    to="/customer-support/mail-blast"
                    className="d-none d-md-flex"
                    style={{
                      color: 'gray',
                      textDecoration: 'none',
                    }}
                  >
                    <img src={mailblast} alt="" className="logout mr-2" />
                    <span>
                      <h6 className="m-2">
                        <strong>MailBlast</strong>
                      </h6>
                    </span>
                  </Link>
                ) : null}

                <h6 className="mr-0 d-flex">
                  <i className="small mr-2 d-none d-md-block">{firstName}</i>
                  <Logout />
                </h6>
              </div>
            </div>
          </div>

          <div className="container">
            <Expired />

            <span className="d-none d-md-block">
              <RetentionScript />
              <CustomerServiceScript />
            </span>
          </div>
        </main>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user.user,
  role: state.auth.auth,
});
CustomerSupportExpired.propTypes = {
  getLoggedInUser: PropTypes.func,
  getUser: PropTypes.func,
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  // user: PropTypes.shape({
  //   firstName: PropTypes.string,
  // }),
  role: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};
export default connect(
  mapStateToProps,
  {
    getUser,
    getLoggedInUser,
  },
)(CustomerSupportExpired);
