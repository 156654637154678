/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../../utils/loader/loadingHelper';
import helpers from '../../../../../utils/helpers';

const DelistedCustomersTable = ({ lists, loading, pageno }) => {
  const colSpan = '7';
  const recordsPerPage = 100;
  const tableData =
    lists && lists.length ? (
      lists.map((list, i) => {
        const { old_macaddress, name, email, expiration_date, staff_email, time } = list;
        return (
          <tr>
            <td>{(pageno - 1) * recordsPerPage + i + 1}</td>
            <td>{old_macaddress}</td>
            <td className="text-nowrap">{name || 'NO NAME'}</td>
            <td>{email || 'NO EMAIL'}</td>
            <td className="text-nowrap">{helpers.formatDateAndTime(expiration_date)}</td>
            <td>{staff_email}</td>
            <td className="text-nowrap">{helpers.formatDateAndTime(time)}</td>
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan={colSpan} text="No Customers" />
    );

  return (
    <div className="card x-panel mt-2 px-2">
      <div className="table-responsive content col-12">
        <table className="table">
          <thead>
            <tr className="text-nowrap">
              <th>#</th>
              <th>MAC ID</th>
              <th>Name</th>
              <th>Customer Email</th>
              <th>Expiry Date</th>
              <th>Staff Email</th>
              <th>Delisted Date</th>
            </tr>
          </thead>
          <tbody>{loading ? <TizetiGIFLogoTable colSpan={colSpan} /> : tableData}</tbody>
        </table>
      </div>
    </div>
  );
};

export default DelistedCustomersTable;
