/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import qs from 'qs';
import ReactPaginate from 'react-paginate';
import useDedicatedConnectedUsers from '../../../../../hooks/business-development/dedicated-connected-users/useDedicatedConnectedUsers';
import BusinessDevelopmentDedicatedConnectedUsersTable from './BusinessDevelopmentDedicatedConnectedUsersTable';

const BusinessDevelopmentDedicatedConnectedUsersBody = () => {
  const { getDedicatedConnectedUsers, items, allPages, isLoading } = useDedicatedConnectedUsers();
  const [country, setCountry] = useState(`Nigeria`);

  const data = qs.stringify({
    action: 'dedicated_connected_users',
    country: `${country}`,
  });

  useEffect(() => {
    getDedicatedConnectedUsers(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    getDedicatedConnectedUsers(data);
  };

  const handlePageClick = async (e) => {
    const selectedPage = e.selected + 1;
    const pageData = qs.stringify({
      action: 'get_all_dedicated_customers',
      pageno: `${selectedPage}`,
      country: `${country}`,
      no_of_records_per_page: '10',
    });
    getDedicatedConnectedUsers(pageData);
  };
  return (
    <div className="mt-5 p-3">
      <div className="container-fluid pt-4">
        <div className="row">
          <form className="form-inline ml-3" onSubmit={onSubmit}>
            <div className="d-flex">
              <div className="form-group mr-2">
                Country
                <select
                  className="form-control"
                  id="country"
                  name="country"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                >
                  <option value="">Select an option</option>
                  <option value="Nigeria">Nigeria</option>
                  <option value="Ghana">Ghana</option>
                </select>
              </div>
            </div>
            <div className="text-center mt-3">
              <button type="submit" className="btn btn-green py-2 px-5">
                Search
              </button>
            </div>
          </form>
          <BusinessDevelopmentDedicatedConnectedUsersTable items={items} isLoading={isLoading} />
          <div className="px-3">
            {allPages > 1 ? (
              <ReactPaginate
                previousLabel="previous"
                nextLabel="next"
                breakLabel="..."
                breakClassName="break-me"
                pageCount={allPages}
                marginPagesDisplayed={3}
                pageRangeDisplayed={4}
                onPageChange={handlePageClick}
                containerClassName="pagination"
                // subContainerClassName="pages pagination"
                activeClassName="active"
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessDevelopmentDedicatedConnectedUsersBody;
