/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useContext } from 'react';
import CustomButton from '../../../../../shared/forms/CustomButton';
import CustomInput from '../../../../../shared/forms/CustomInput';
import AppContext from '../../../../../../../context/shared/AppContext';
import Label from '../../../../../shared/forms/Label';

const AddFiberSitesPublicIp = () => {
  const { loadingForm, onChange, formValue, onSubmit } = useContext(AppContext);
  const { name, ip_address, latitude, longitude } = formValue;
  const submitForm = () => {
    return ip_address.length > 0 && name.length > 0 && latitude.length > 0 && longitude.length > 0;
  };
  return (
    <div id="add-access-point" className="modal fade mt-5" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header shadow">
            <h4 className="modal-title update_asset">Fiber Site Public Ip</h4>
            <button type="button" className="btn btn-outline-info btn-circle btn-close" data-dismiss="modal">
              x
            </button>
          </div>
          <div className="modal-body detail mb-4 p-0">
            <form
              className="col-md-12 p-0"
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              {/* <div className="d-flex"> */}
              {/*  <div className="form-group col-6"> */}
              {/*    <Label classname="m-0 p-0 copy-font" name="Site Name" /> */}
              {/*    <select */}
              {/*      className="form-control border border-info p-2" */}
              {/*      id="class" */}
              {/*      name="basestation_name" */}
              {/*      style={{ borderRadius: '0' }} */}
              {/*      onChange={onChange} */}
              {/*    > */}
              {/*      <option>--Basestation--</option> */}
              {/*      {data.map((basestation: { name: string }) => { */}
              {/*        return <CustomDropdownOption value={basestation.name} name={basestation.name} />; */}
              {/*      })} */}
              {/*    </select> */}
              {/*  </div> */}
              {/*  <div className="form-group col-6"> */}
              {/*    <Label classname="m-0 p-0 copy-font" name="IP Address" /> */}

              {/*    <CustomInput */}
              {/*      classname="form-control border border-info p-2" */}
              {/*      name="ip_address" */}
              {/*      value={ip_address} */}
              {/*      onChange={onChange} */}
              {/*      readOnly={false} */}
              {/*      placeholder="eg (10.0.2.1)" */}
              {/*      type="text" */}
              {/*      id="customerEmail" */}
              {/*    /> */}
              {/*  </div> */}
              {/* </div> */}

              <div className="d-flex">
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="IP Address" />

                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="ip_address"
                    value={ip_address}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="eg (10.0.2.1)"
                    type="text"
                    id="customerEmail"
                  />
                </div>
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="Name" />
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="name"
                    value={name}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="enter name"
                    type="text"
                    id="customerEmail"
                  />
                </div>
              </div>
              <div className="d-flex">
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="latitude" />

                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="latitude"
                    value={latitude}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="enter latitude"
                    type="text"
                    id="customerEmail"
                  />
                </div>
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="longitude" />
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="longitude"
                    value={longitude}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="enter longitude"
                    type="text"
                    id="customerEmail"
                  />
                </div>
              </div>

              <div className="text-center mt-3">
                {loadingForm ? (
                  <CustomButton classname="btn btn-green px-3" name="Adding..." disabled={loadingForm} />
                ) : (
                  <CustomButton classname="btn btn-green px-3" name="Add" disabled={!submitForm()} />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFiberSitesPublicIp;
