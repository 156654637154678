import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import toastr from 'toastr';
import ResearchAndDevelopmentSideBar from '../../../layouts/research-and-development/ResearchAndDevelopmentSideBar';
import ResearchLogo from '../../../layouts/research-and-development/ResearchLogo';
import Logout from '../../shared/auth/Logout';
import { API_URL, RESEARCH_AND_DEVELOPMENT } from '../../../../utils/config';
import Mailblast from '../../../../assets/img/icons/mailblast.svg';
import { SUPER_ADMIN } from '../../../../utils/departments/helpers';

export default class RandDReportIssue extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      role: '',
    };
  }

  componentDidMount() {
    this.getUserDetails();
  }

  componentWillReceiveProps() {
    this.fetchLoggedOnUser();
  }

  componentWillMount() {
    this.fetchLoggedOnUser();
  }

  fetchLoggedOnUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employeeReg/logged`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        if (data.data) {
          this.setState({
            role: data.data.role_id,
          });
        }

        if (data.data.department_id !== `${RESEARCH_AND_DEVELOPMENT}` &&
          data.data.department_id !== `${SUPER_ADMIN}`) {
          this.props.history.push('/');

          toastr.info('You are not allowed to view this page');
        }
      })
      .catch((err) => {});
  }

  getUserDetails() {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };

    axios
      .get(`${API_URL}/employee/loggedOnEmployee`, config)
      .then((res) => {
        if (res.data) {
          this.setState({
            firstName: res.data.firstName,
          });
        }
      })
      .catch((err) => {});
  }

  render() {
    const { firstName, role } = this.state;
    return (
      <div className="main-wrapper">
        <header className="topbar shade bg-white fixed-top">
          <nav className="navbar navbar-expand-lg navbar-light top-navbar fixed-top">
            {/* <Link className="navbar-brand" to={"#"}> */}
            {/* <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971047/Tizeti-Logo.png" alt="homepage" /> */}
            {/* </Link> */}
            <ResearchLogo />
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse mt-3" id="navbarText">
              <ul className="navbar-nav" style={{ marginRight: 'auto' }}>
                <li className="nav-item active">
                  <Link className="nav-link" to="#">
                    <h3>
                      Report Issue<span className="sr-only">(current)</span>
                    </h3>
                  </Link>
                </li>
              </ul>
              <span className="navbar-text">
                <div className="d-flex">
                  {role > '2' ? (
                    <Link
                      to="/research-and-development/mail-blast"
                      className="d-flex"
                      style={{ color: 'gray', textDecoration: 'none' }}
                    >
                      <img src={Mailblast} className="logout mr-2" alt="" />
                      <span>
                        <h6 className="mt-2 mr-2">
                          <strong>MailBlast</strong>
                        </h6>
                      </span>
                    </Link>
                  ) : null}
                  <h4 className="mt-2">
                    {firstName}
                    <Logout />
                  </h4>
                </div>
              </span>
            </div>
          </nav>
        </header>

        <div className="all-sides col-lg-12 col-md-12 col-sm-12">
          <ResearchAndDevelopmentSideBar role={role} />

          <div className="page-wrapper col-lg-10 col-md-10 col-sm-12">
            <div className="row">
              <div className="container-fluid col-lg-7">
                <form action="javascript:void(0)" className="form-horizontal mt-5">
                  <div className="form-group text-left">
                    <div className="col-md-12 mb-5 d-flex p-0">
                      <div className="col-lg-6">
                        <label className="copy-font p-0 m-0">Subject</label>
                        <input type="text" className="form-control" placeholder="Subject" />
                      </div>
                    </div>

                    <div className="col-md-12 mb-5 d-flex">
                      <div>
                        <label className="copy-font p-0 mb-0">Details</label>
                        <textarea
                          className="form-control"
                          name="detail"
                          rows="5"
                          cols="50"
                          placeholder="Details of the Issue"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-12 mt-5">
                    <button type="button" className="btn btn-green" data-dismiss="modal">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
