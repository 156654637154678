/* eslint-disable @typescript-eslint/camelcase */
import { useState, useEffect, useReducer } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import qs from 'qs';
import { API_URL } from '../../../utils/config';
import { dedicatedReducer } from '../../../reducers/sales/dedicated/dedicatedReducer';
import { ADD_DEDICATED_CUSTOMER_URL } from '../../../services/api/sales/customers/DedicatedCustomersUrl';
import {
  GET_DEDICATED_CUSTOMERS,
  START_ADD_DEDICATED,
  STOP_ADD_DEDICATED,
} from '../../../constants/types/sales/customers/DedicatedTypes';
import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';

const useDedicated = () => {
  const dedicatedState = {
    address: '',
    name: '',
    email: '',
    macId: '',
    expirationDate: '',
    quantity: '',
    price: '',
    itemCode: '',
    itemDescription: '',
    discount: '',
    total: '',
    subTotal: '',
    totalExVat: '',
    totalWithVat: '',
    unit: '',
  };
  const [page] = useState(1);
  const [dedicated, setDedicated] = useState(dedicatedState);
  const [{ submitting, dedicatedCustomers, loading }, dispatch] = useReducer(dedicatedReducer, {
    submitting: false,
    dedicatedCustomers: [],
    loading: false,
  });
  const onChange = (e) => {
    const { name, value } = e.target;
    setDedicated({ ...dedicated, [name]: value });
  };
  const addDedicated = async (e) => {
    dispatch({ type: START_ADD_DEDICATED });
    e.preventDefault();
    const {
      address,
      itemDescription,
      discount,
      email,
      expirationDate,
      itemCode,
      macId,
      name,
      price,
      quantity,
      subTotal,
      total,
      totalExVat,
      totalWithVat,
      unit,
    } = dedicated;
    const data = {
      address,
      item_description: itemDescription,
      discount,
      email,
      expiration_date: expirationDate,
      item_code: itemCode,
      mac_id: macId,
      name,
      price,
      quantity,
      sub_total: subTotal,
      total,
      total_ex_vat: totalExVat,
      total_with_vat: totalWithVat,
      unit,
    };
    try {
      const response = await axios.post(`${ADD_DEDICATED_CUSTOMER_URL}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.id) {
        toast.success(`${name} added as a dedicated customer!`);
        dispatch({ type: GET_DEDICATED_CUSTOMERS, payload: [response.data, ...dedicatedCustomers] });
        setDedicated(dedicatedState);
      }
    } catch (error) {
      dispatch({ type: STOP_ADD_DEDICATED });
    }
  };
  console.log(loading);
  useEffect(() => {
    async function getDedicatedCustomers() {
      dispatch({ type: LOADING_STARTS });
      try {
        const response = await axios.post(
          `https://legacy.tizeti.com/geniatechapi/index.php`,
          qs.stringify({
            action: 'dedicated_customer_list',
          }),
        );
        console.log(response);
        if (response.data.status === true) {
          dispatch({ type: GET_DEDICATED_CUSTOMERS, payload: response.data.items });
        }
        dispatch({ type: LOADING_STOPS });
      } catch (error) {
        dispatch({ type: LOADING_STOPS });
      }
    }
    getDedicatedCustomers();
  }, [page]);
  return { dedicatedCustomers, loading, onChange, dedicated, addDedicated, submitting };
};
export default useDedicated;
