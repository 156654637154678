import React from 'react';
import ReactPaginate from 'react-paginate';
import './index.css';

const CustomPagination = ({ pagesCount, marginPagesDisplayed, pageRangeDisplayed, onPageChange }) => {
  return pagesCount > 1 ? (
    <ReactPaginate
      // previousLabel="previous"
      // nextLabel="next"
      breakLabel="..."
      breakClassName="break-me"
      pageCount={pagesCount}
      marginPagesDisplayed={marginPagesDisplayed}
      pageRangeDisplayed={pageRangeDisplayed}
      onPageChange={onPageChange}
      containerClassName="pagination"
      activeClassName="active pagination-active"
    />
  ) : null;
};

export default CustomPagination;
