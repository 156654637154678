/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import { API_URL } from '../../../../../utils/config';

class GetSubscribers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      basestation: '',
      start: '',
      end: '',
      loading: false,
      subscribers: [],
      basestations: [],
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.getBasestations();
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  onSubmit(e) {
    e.preventDefault();
    this.setState({
      loading: true,
    });

    const data = {
      name: this.state.basestation,
      to: this.state.end,
      from: this.state.start,
    };

    axios({
      method: 'post',
      url: `${API_URL}/basestationsubscribers/getsubscribers`,
      data,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    }).then((res) => {
      if (res.data) {
        this.setState({
          subscribers: res.data.average,
          loading: false,
        });
      }
    });
  }

  getBasestations() {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };

    axios.get(`${API_URL}/basestationsubscribers/getBasestationList`, config).then((res) => {
      if (res.data) {
        this.setState({
          basestations: res.data,
        });
      }
    });
  }

  render() {
    const { subscribers } = this.state;

    return (
      <div
        id="getSubscribers"
        className="modal fade in"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center" id="myModalLabel">
                Get Subscribers
              </h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
            </div>
            <div className="modal-body">
              <form className="form-horizontal" onSubmit={this.onSubmit}>
                <div className="form-group">
                  <div className="col-lg-12 d-flex mb-3">
                    <div className="col-lg-6">
                      <label className="col-lg-12 text-justify copy-font m-0 p-0">Basestation Name</label>
                      <select name="basestation" onChange={this.onChange}>
                        <option value="">Choose Basestation</option>
                        {this.state.basestations.map((station) => {
                          return (
                            <option key={station.DISTINCT} value={station.DISTINCT}>
                              {station.DISTINCT}
                            </option>
                          );
                        })}
                      </select>
                      {/* <input type="text"/>
                                            <datalist>
                                                {this.state.basestations.map(
                                                    (station) => {
                                                        return <option value={station.id}></option>
                                                    }
                                                )}
                                            </datalist> */}
                      {/* <input list="basestation" placeholder="Select Basestation"/>
                                                <datalist id="basestation" onChange={this.onChange}>
                                                    {this.state.basestations.map(
                                                        (station) => {
                                                            return <option name="basestation" value={station.DISTINCT}/>
                                                        }
                                                    )}

                                                </datalist> */}
                    </div>

                    <div className="col-lg-6">
                      <label className="col-lg-12 text-justify copy-font m-0 p-0">Start</label>
                      <input
                        type="date"
                        className="form-control"
                        name="start"
                        required
                        value={this.state.start}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 d-flex">
                    <div className="col-lg-6">
                      <label className="col-lg-12 text-justify copy-font m-0 p-0">End</label>
                      <input
                        type="date"
                        className="form-control"
                        required
                        name="end"
                        onChange={this.onChange}
                        value={this.state.end}
                      />
                    </div>

                    {subscribers ? (
                      <div className="col-lg-6">
                        <label className="col-lg-12 text-justify copy-font">Average Subscribers</label>
                        <small>{Math.round(subscribers)}</small>
                      </div>
                    ) : null}
                  </div>
                </div>

                {this.state.loading ? (
                  <div className="text-center mt-4">
                    <button className="btn btn-green" type="button">
                      <i className="fa fa-circle-o-notch fa-spin" />
                    </button>
                  </div>
                ) : (
                  <div className="text-center mt-4">
                    <button className="btn btn-green" type="button">
                      Get
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

export default GetSubscribers;
