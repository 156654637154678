/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import qs from 'qs';
import useCustomerRevenue from '../../../../../hooks/business-development/customer-revenue/useCustomerRevenue';
import BusinessDevelopmentLogsTable from './BusinessDevelopmentLogsTable';
import CustomCsvDownload from '../../../shared/CustomCsvDownload';
import { BizdevlogHeaders, filename } from '../../../../../utils/field-support/installations/helpers';
import { useLogs } from '../../../../../hooks/business-development/logs/useLogs';
import ReactPaginate from 'react-paginate';

const BusinessDevelopmentLogsBody = () => {
  const { lists, pages, count, isLoading, getLogs } = useLogs();
  const [page] = useState(1);

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    return getLogs(selectedPage);
  };

  useEffect(() => {
    getLogs(page);
  }, []);

  return (
    <>
      <div className="col-12 d-flex mt-2">
        <CustomCsvDownload
          data={lists || []}
          headers={BizdevlogHeaders}
          filename={filename(`Business Development Logs`)}
          classname="btn btn-green shadow-md"
          textcolor="white"
          text="Download Data"
        />
      </div>
      <div className="p-0 col-12 d-flex justify-content-between">
        {count > 0 ? (
          <div>
            Count: <span className="badge badge-secondary mr-4">{count}</span>
          </div>
        ) : null}
      </div>
      <div className="card x-panel p-0">
        <BusinessDevelopmentLogsTable lists={lists} isLoading={isLoading} />
        <div className="px-3">
          {30 > 1 ? (
            <ReactPaginate
              previousLabel="previous"
              nextLabel="next"
              breakLabel="..."
              breakClassName="break-me"
              pageCount={pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName="pagination"
              // subContainerClassName="pages pagination"
              activeClassName="active"
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default BusinessDevelopmentLogsBody;
