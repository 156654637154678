/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import { Link } from 'react-router-dom';
import { useUser } from '../../../../hooks/shared/user/useUser';
import mailblast from '../../../../assets/img/icons/mailblast.svg';
import NewDesignSideBar from '../../../layouts/new-design/NewDesignSideBar';
import NewDesignOverviewBody from './NewDesignOverviewBody';

const NewDesignOverview = () => {
  const { profile } = useUser();
  return (
    <div className="s-layout">
      <main className="s-layout__content col-sm">
        <NewDesignSideBar currentPage="Overview" position={profile.firstName} />
        <div className="">
          <header className="d-flex justify-content-between p-5">
            <div className="">
              <select className="form-control">
                <option value="ice">NGN</option>
                <option value="ice">GHC</option>
              </select>
            </div>

            <div />
          </header>

          <NewDesignOverviewBody />
        </div>
      </main>
    </div>
  );
};

export default NewDesignOverview;
