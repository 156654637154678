/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import CustomerSupportNavBar from '../../../../layouts/customer-support/CustomerSupportNavBar';
import MailBlastBody from '../../../shared/mailblast/v2/MailBlastBody';

const CustomerSupportMailBlast = () => {
  const { profile, user } = useUser();
  const { firstName } = profile;
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <CustomerSupportNavBar currentPage="Mailblast" roleID={user.role_id} position={firstName} />
        <MailBlastBody />
      </main>
    </div>
  );
};

export default CustomerSupportMailBlast;
