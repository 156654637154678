/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import qs from 'qs';
import CustomButton from '../forms/CustomButton';

const SearchDedicatedCustomer = ({ searchUsingKeyword }) => {
  const [key, setKey] = useState(``);
  const [value, setValue] = useState(``);

  const data = qs.stringify({
    action: 'search_dedicated_customer',
    key: `${key}`,
    value: `${value}`,
  });

  // const onSubmit = (e: { preventDefault: () => void }) => {
  //   e.preventDefault();
  //   searchUsingKeyword(data);
  // };

  const searchForUser = (e) => {
    e.preventDefault();
    searchUsingKeyword(data);
  };

  // const handlePageClick = async (e: { selected: number }) => {
  //   const selectedPage = e.selected + 1;
  //   const pageData = qs.stringify({
  //     action: 'search_dedicated_customers',
  //     key: `${key}`,
  //     value: `${value}`,
  //   });
  //   searchUsingKeyword(pageData);
  // };

  return (
    <div className="d-flex">
      <form className="form-inline ml-3" onSubmit={searchForUser}>
        <div className="form-group mr-2">
          Search By
          <select
            style={{ marginLeft: '1rem' }}
            name="chooseField"
            value={key}
            onChange={(e) => setKey(e.target.value)}
            className="form-control"
          >
            <option value="">--Choose Search Field--</option>
            <option value="UserName">Mac ID</option>
            <option value="Name">Name</option>
            <option value="Mail">Email</option>
            <option value="Phone">Phone</option>
            <option value="Customer_Number">Customer Number</option>
            {/* <option value="Zone_Field">Zone Field</option>  */}
          </select>
        </div>
        <div className="form-group mr-2">
          Type Here:
          <input
            className="form-control mr-auto empty pl-5"
            type="text"
            placeholder="type search term"
            name="optionValue"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          // readOnly={false}
          />
        </div>
        <div className="form-group mx-2">
          <CustomButton classname="btn btn-green" name="Search" disabled={false} />
        </div>
      </form>
    </div>
  );
};

export default SearchDedicatedCustomer;
