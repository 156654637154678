/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import SalesSideBar from '../../../../layouts/sales/SalesSideBar';
import mailblast from '../../../../../assets/img/icons/mailblast.svg';
import Logout from '../../../shared/auth/Logout';
import { SALES } from '../../../../../utils/config';
import PageName from '../../../shared/layouts/PageName';
import AssetTeamInstallationsBody from './AssetTeamInstallationsBody';
import useCheckTwoDepartments from '../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';
import { SUPER_ADMIN, VICE_PRESIDENT_SALES } from '../../../../../utils/departments/helpers';
import useCheckFiveDepartments from '../../../../../hooks/shared/custom/check-department/useCheckFiveDepartments';

const SalesAssetTeamInstallations = ({ history }) => {
  useCheckFiveDepartments(`${SALES}`, `${SUPER_ADMIN}`, `${VICE_PRESIDENT_SALES}`, history);
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <nav className="d-flex col-lg-12 p-2 top-header">
          <div className="mt-3 d-flex col-lg-12 p-0">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link className="s-sidebar__trigger" to="#">
              <i className="fa fa-bars mr-3" />
            </Link>
            <SalesSideBar />
            <PageName name="Asset Team Zone Installations" />
            <div className="d-flex profile-mail">
              <div>
                <Link
                  to="/sales/mail-blast"
                  className="d-none d-md-flex"
                  style={{ color: 'gray', textDecoration: 'none' }}
                >
                  <img src={mailblast} alt="" className="logout mb-2 mr-2" />

                  <h6 className="mt-2">
                    <strong>MailBlast</strong>
                  </h6>
                </Link>
              </div>

              <div>
                <Link to="#" data-toggle="modal" data-target="#add-employee">
                  <img alt="" className="logout mr-3" />
                </Link>
              </div>
              <div className="">
                <Logout />
              </div>
            </div>
          </div>
        </nav>
        <AssetTeamInstallationsBody />
      </main>
    </div>
  );
};

export default SalesAssetTeamInstallations;
