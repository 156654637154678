/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import toastr from 'toastr';
import useInstallationOverview from '../../../../../hooks/field-support/installation/leads/overview/useInstallationOverview';
import { SALES } from '../../../../../utils/departments/helpers';
import AssignInstallationToStaff from './AssignInstallationToStaff';

const EditInstallation = ({ item, teams }) => {
  const { loading, editInstallation } = useInstallationOverview();

  const [details, setDetails] = useState({});

  const departmentId = sessionStorage.getItem('departmentId');

  const { installation_id, address, status, date, team, team_id } = item;
  const { installAddress, installStatus, installDate, installTeam } = details;

  useEffect(() => {
    const result = {
      installAddress: address,
      installStatus: status,
      installDate: date,
      installTeam: team_id || '',
    };
    setDetails({ ...result });
  }, [item]);

  const editInstall = (e) => {
    e.preventDefault();
    const data = {
      status: installStatus,
      // payment_reference: reference,
      address: installAddress,
      // date: installDate,
      team_id: installTeam,
    };
    editInstallation(installation_id, data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'installStatus' && value === 'completed' && !(team || team_id))
      toastr.warning('Assign To Individual/Team First!');
    else setDetails({ ...details, [name]: value });
  };

  return (
    <div
      id="edit-install"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Edit Installation
            </h4>
            <button type="button" className="close custom-close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body">
            <form className="form-horizontal" onSubmit={editInstall}>
              <div className="form-group">
                {departmentId !== `${SALES}` && (
                  <div className="col-12 mb-3 d-flex">
                    <div className="col-6 mb-2">
                      <label className="copy-font m-0 p-0">Assign To Team</label>
                      <select className="form-control" name="installTeam" value={installTeam} onChange={handleChange}>
                        <option value="">Select Team</option>
                        {teams.map((oneTeam) => (
                          <option value={oneTeam.id}>{oneTeam.name.toUpperCase()}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-6">
                      <label className="copy-font m-0 p-0">Installation Status</label>
                      <select
                        className="form-control"
                        name="installStatus"
                        value={installStatus}
                        onChange={handleChange}
                      >
                        <option value="">Status</option>
                        <option value="queued">Queued</option>
                        <option value="on-going">On Going</option>
                        <option value="completed">Completed</option>
                        <option value="not completed">Not Completed</option>
                        <option value="failed">Failed</option>
                        <option value="awaiting_account_creation">Awaiting Account Creation</option>
                      </select>
                    </div>
                  </div>
                )}
                <div className="col-12 mb-3 d-flex">
                  <div className="col-12">
                    <label className="copy-font m-0 p-0">Address</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      name="installAddress"
                      value={installAddress}
                    />
                  </div>
                </div>
                {/* {sessionStorage.getItem('departmentId') !== SALES && (
                  <div className="col-12 mb-3 d-flex">
                    <div className="col-6">
                      <label className="copy-font m-0 p-0">Date</label>
                      <input
                        className="form-control"
                        type="date"
                        name="installDate"
                        value={installDate}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                )} */}

                <div className="col-12 mb-3 text-center">
                  {loading ? (
                    <button type="button" className="btn btn-info" disabled>
                      Updating...
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-info"
                      disabled={installStatus === 'completed' && !(team || team_id)}
                    >
                      Update
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>

          {departmentId !== `${SALES}` && (
            <div className="pt-3">
              <div className="modal-header">
                <h4 className="modal-title text-center">Assign Installation</h4>
              </div>
              <div className="modal-body">
                <AssignInstallationToStaff installationID={installation_id} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditInstallation;
