import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Toast as toastr } from 'react-toastify';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Logout from '../../../shared/auth/Logout';
import CustomerSupportSideBar from '../../../../layouts/customer-support/CustomerSupportSideBar';
import CustomerSupportLogo from '../../../../layouts/customer-support/CustomerSupportLogo';
// import CustomerSupportRetentionTable from "./CustomerSupportRetentionTable";
import { customer_support } from '../../../../../utils/config';
import mailblast from '../../../../../assets/img/icons/mailblast.svg';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import { getUser } from '../../../../../actions/shared/user/UserActions';
import { getLoggedInUser } from '../../../../../actions/user/UserActions';
import Retention from '../../../shared/retentions/Retention';

class CustomerRetention extends Component {
  componentDidMount() {
    this.props.getUser();
    this.props.getLoggedInUser();
    this.checkDepartment();
  }

  checkDepartment = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.department_id !== `${customer_support}`) {
        this.props.history.push('/');

        sessionStorage.setItem('token', '');
        sessionStorage.clear();

        toastr.info('You are not allowed to view this page');
      }
    } catch (e) {}
  };

  static propTypes = {
    getLoggedInUser: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
  };

  onKeyPress = (e) => {
    if (e.which === 13) {
      const data = {
        days: e.target.value,
      };
      console.log(data);

      this.props.getAboutToExpire(data);
    }
  };

  render() {
    const { expired, user, role, loading } = this.props;
    const { role_id } = role;
    const { firstName } = user;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <CustomerSupportSideBar />

              <h3 className="col-lg-5 overview-part text-wrap">Retention</h3>

              <div className="ml-5 d-flex profile-mail">
                <Link
                  to="/customer-support/mail-blast"
                  className="d-none d-md-flex"
                  style={{
                    color: 'gray',
                    textDecoration: 'none',
                  }}
                >
                  <img src={mailblast} alt="" className="logout mr-2" />
                  <span>
                    <h6 className="m-2">
                      <strong>MailBlast</strong>
                    </h6>
                  </span>
                </Link>

                <h6 className="mr-0 d-flex">
                  <i className="small mr-2 d-none d-md-block">{firstName}</i>
                  <Logout />
                </h6>
              </div>
            </div>
          </div>
          <Retention />
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  role: state.auth.auth,
});

export default connect(
  mapStateToProps,
  { getUser, getLoggedInUser },
)(CustomerRetention);
