/* eslint-disable consistent-return */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { pdfjs } from 'react-pdf';
import PropTypes from 'prop-types';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ViewReceipt = ({ requisit }) => {
  const showFileOne = () => {
    if (requisit) {
      if (requisit.imageUpload) {
        if (requisit.imageUpload.split('.').pop() === 'pdf') {
          return <object width="100%" height="200" data={requisit.imageUpload} type="application/pdf" />;
        }
        return <img alt="receipt" className="copy-font w-100" src={requisit ? requisit.imageUpload : null} />;
      }

      return null;
    }
  };
  const showFileTwo = () => {
    if (requisit) {
      if (requisit.image2) {
        if (requisit.image2.split('.').pop() === 'pdf') {
          return <object width="100%" height="200" data={requisit.image2} type="application/pdf" />;
        }
        return <img alt="receipt" className="copy-font w-100" src={requisit ? requisit.image2 : null} />;
      }

      return null;
    }
  };
  const showFileThree = () => {
    if (requisit) {
      if (requisit.image3) {
        if (requisit.image3.split('.').pop() === 'pdf') {
          return <object width="100%" height="200" data={requisit.image3} type="application/pdf" />;
        }
        return <img alt="receipt" className="copy-font w-100" src={requisit ? requisit.image3 : null} />;
      }

      return null;
    }
  };
  const showFileFour = () => {
    if (requisit) {
      if (requisit.image4) {
        if (requisit.image4.split('.').pop() === 'pdf') {
          return <object width="100%" height="200" data={requisit.image4} type="application/pdf" />;
        }
        return <img alt="receipt" className="copy-font w-100" src={requisit ? requisit.image4 : null} />;
      }

      return null;
    }
  };
  const showFileFive = () => {
    if (requisit) {
      if (requisit.image5) {
        if (requisit.image5.split('.').pop() === 'pdf') {
          return <object width="100%" height="200" data={requisit.image5} type="application/pdf" />;
        }
        return <img alt="receipt" className="copy-font w-100" src={requisit ? requisit.image5 : null} />;
      }

      return null;
    }
  };
  const showFileSix = () => {
    if (requisit) {
      if (requisit.image6) {
        if (requisit.image6.split('.').pop() === 'pdf') {
          return <object width="100%" height="200" data={requisit.image6} type="application/pdf" />;
        }
        return <img alt="receipt" className="copy-font w-100" src={requisit ? requisit.image6 : null} />;
      }

      return null;
    }
  };

  return (
    <div
      id="viewReceipt"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Requisition Receipt
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body detail" style={{ padding: '0', textAlign: 'justify' }}>
            <div className="row">
              <div className="col-12 p-0 d-flex">
                <div className="col-12">
                  <div className="p-3">{showFileOne()}</div>
                  <div className="p-3">{showFileTwo()}</div>
                  <div className="p-3">{showFileThree()}</div>
                  <div className="p-3">{showFileFour()}</div>
                  <div className="p-3">{showFileFive()}</div>
                  <div className="p-3">{showFileSix()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ViewReceipt.propTypes = {
  requisit: PropTypes.shape(),
};

export default ViewReceipt;
