import React from 'react';
import { useDedicatedInvoice } from '../../../../../hooks/accounts/dedicated-invoice/useDedicatedInvoice';

const AddDedicatedInvoice = () => {
  const { invoice, isLoading, handleChange, createDedicatedInvoice } = useDedicatedInvoice();

  const {
    customerName,
    customerEmail,
    address,
    macID,
    subTotal,
    total,
    totalExVAT,
    totalWithVAT,
    price,
    itemDescription,
    quantity,
    unit,
    discount,
    expiryDate,
  } = invoice;

  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      name: customerName,
      email: customerEmail,
      address,
      mac_id: macID,
      expiration_date: expiryDate,
      unit,
      quantity,
      price,
      item_code: 'INTERNET',
      item_description: itemDescription,
      discount,
      sub_total: subTotal,
      total,
      total_ex_vat: totalExVAT,
      total_with_vat: totalWithVAT,
    };
    createDedicatedInvoice(data);
  };

  const validate = () => {
    return (
      customerName.length > 0 &&
      customerEmail.length > 0 &&
      address.length > 0 &&
      macID.length > 0 &&
      subTotal.length > 0 &&
      total.length > 0 &&
      totalExVAT.length > 0 &&
      totalWithVAT.length > 0 &&
      price.length > 0 &&
      itemDescription.length > 0 &&
      quantity.length > 0 &&
      expiryDate.length > 0
    );
  };

  return (
    <div id="add_invoice" className="modal fade in" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Create Invoice</h4>
            <button type="button" className="close mr-2" data-dismiss="modal">
              x
            </button>
          </div>
          <div className="modal-body mt-2 mb-4 p-0">
            <form onSubmit={onSubmit} className="col-md-12">
              <div className="d-flex">
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="customerName">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control border border-info p-2"
                    id="customerName"
                    name="customerName"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="customerEmail">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control border border-info p-2"
                    id="customerEmail"
                    name="customerEmail"
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="d-flex">
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="address">
                    Address
                  </label>
                  <input
                    type="text"
                    className="form-control border border-info p-2"
                    id="address"
                    name="address"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="macID">
                    MAC ID
                  </label>
                  <input
                    type="text"
                    className="form-control border border-info p-2"
                    id="macID"
                    name="macID"
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="d-flex">
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="unit">
                    Unit
                  </label>
                  <input
                    type="number"
                    min="1"
                    className="form-control border border-info p-2"
                    id="unit"
                    name="unit"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="quantity">
                    Quantity
                  </label>
                  <input
                    type="number"
                    min="1"
                    className="form-control border border-info p-2"
                    id="quantity"
                    name="quantity"
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="d-flex">
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="expiryDate">
                    Expiry Date
                  </label>
                  <input
                    type="date"
                    className="form-control border border-info p-2"
                    id="expiryDate"
                    name="expiryDate"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="itemDescription">
                    Item Description
                  </label>
                  <input
                    type="text"
                    className="form-control border border-info p-2"
                    id="itemDescription"
                    name="itemDescription"
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="d-flex">
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="discount">
                    Discount
                  </label>
                  <input
                    type="number"
                    min="0"
                    className="form-control border border-info p-2"
                    id="discount"
                    name="discount"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="price">
                    Price
                  </label>
                  <input
                    type="number"
                    min="1"
                    className="form-control border border-info p-2"
                    id="price"
                    name="price"
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="d-flex">
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="subTotal">
                    Sub-Total
                  </label>
                  <input
                    type="number"
                    min="1"
                    className="form-control border border-info p-2"
                    id="subTotal"
                    name="subTotal"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="total">
                    Total
                  </label>
                  <input
                    type="number"
                    min="1"
                    className="form-control border border-info p-2"
                    id="total"
                    name="total"
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="d-flex">
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="totalExVAT">
                    Total Order Ex VAT
                  </label>
                  <input
                    type="number"
                    min="1"
                    className="form-control border border-info p-2"
                    id="totalExVAT"
                    name="totalExVAT"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col-6">
                  <label className="m-0 p-0 copy-font" htmlFor="totalWithVAT">
                    Total Order VAT Inclusive
                  </label>
                  <input
                    type="number"
                    min="1"
                    className="form-control border border-info p-2"
                    id="totalWithVAT"
                    name="totalWithVAT"
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="text-center mt-3">
                {isLoading ? (
                  <button className="btn btn-green px-3" disabled>
                    <i className="fa fa-spinner fa-spin" />
                  </button>
                ) : (
                  <button type="submit" className="btn btn-green" disabled={!validate()}>
                    Create
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDedicatedInvoice;
