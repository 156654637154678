/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { Link } from 'react-router-dom';
import { operations, TECHNICAL_SUPPORT, API_URL, admin } from '../../../../../utils/config';
import Escalate from '../../../shared/escalate/Escalate';
import Logout from '../../../shared/auth/Logout';
import FieldSupportSideBar from '../../../../layouts/field-support/side-bar/FieldSupportSideBar';

export default class FieldSupportEscalate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      role: '',
    };
  }

  componentDidMount() {
    this.getUserDetails();
    this.fetchLoggedOnUser();
  }

  getUserDetails() {
    axios({
      method: 'get',
      url: `${API_URL}/employee/loggedOnEmployee`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    }).then((res) => {
      if (res.data) {
        this.setState({
          firstName: res.data.firstName,
        });
      }
    });
  }

  fetchLoggedOnUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employeeReg/logged`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((response) => {
        this.setState({
          role: response.data.role_id,
        });

        if (response.data.id) {
          if (
            response.data.department_id !== `${operations}` &&
            response.data.department_id !== `${TECHNICAL_SUPPORT}` &&
            response.data.department_id !== `${admin}`
          ) {
            this.props.history.push('/staff/login');
            toastr.info('You are not allowed to view this page');
          }
        }
      })
      .catch((err) => {});
  }

  render() {
    const { firstName, role } = this.state;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <Link className="s-sidebar__trigger" to="#">
                <i className="fa fa-bars mr-3" />
              </Link>
              <FieldSupportSideBar />

              <h3 className="col-lg-6 overview-part">Escalate</h3>
              <span className="navbar-text">
                <h6 className="admin">
                  {firstName}
                  <Logout />
                </h6>
              </span>
            </div>
          </div>

          <div className="container">
            <div className="row mt-5">
              <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-4">
                <Escalate dept={firstName} />
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
