/* eslint-disable no-plusplus */
import React, { useState } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { toast, ToastContainer } from 'react-toastify';
import { useBasestations } from '../../../../../hooks/shared/basestations/useBasestation';
import { GET_ROUTER_URL } from '../../../../../services/api/shared/BasestationsUrl';

const BandwidthGraphBody = () => {
  const { routers } = useBasestations();
  const [data, setData] = useState({});
  const [toDate, setToDate] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [basestation, setBasestation] = useState('');
  const [txc, settxc] = useState([]);
  const [rxc, setrxc] = useState([]);
  const [txta, settxta] = useState([]);
  const [rxta, setrxta] = useState([]);
  const [timestamp, settimestamp] = useState([]);

  const onSubmit = async (e) => {
    e.preventDefault();
    settxc([]);
    setrxc([]);
    settxta([]);
    setrxta([]);
    settimestamp([]);
    try {
      const params = new URLSearchParams();
      params.append('action', 'getRouterPerformance');
      params.append('name', basestation);
      params.append('from', fromDate);
      params.append('to', toDate);
      const response = await axios.post(`${GET_ROUTER_URL}`, params);

      if (response.data.status) {
        for (let i = 0; i < response.data.records.length; i++) {
          txc.push(response.data.records[i].txc);
          rxc.push(response.data.records[i].rxc);
          txta.push(response.data.records[i].txta);
          rxta.push(response.data.records[i].rxta);
          timestamp.push(response.data.records[i].timestamp);
        }
      } else {
        toast.info('No Records Available');
      }

      setData({
        labels: timestamp,
        datasets: [
          {
            label: 'txc',
            data: txc,
            backgroundColor: 'rgb(255, 120, 115)',
          },
          {
            label: 'rxc',
            data: rxc,
            backgroundColor: 'rgb(115, 255, 136)',
          },
          {
            label: 'txta',
            data: txta,
            backgroundColor: 'rgb(124, 115, 255)',
          },
          {
            label: 'rxta',
            data: rxta,
            backgroundColor: 'rgb(255, 190, 115)',
          },
        ],
      });
    } catch (err) {
      toast.info('Something went wrong!');
    }
  };

  const displayForm = () => {
    if (routers) {
      return (
        <form className="form-horizontal mb-5" onSubmit={onSubmit}>
          <div className="d-flex form-group col-12 mt-4 mb-5">
            <div className="col-4 mr-3">
              <select className="form-control" onChange={(e) => setBasestation(e.target.value)} name="basestation">
                <option>Select Basestation</option>
                {routers.map((router) => {
                  return (
                    <option key={router.name} value={router.name}>
                      {router.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="d-flex copy-font">
              <p className="d-flex mb-2">
                <small className="text-nowrap">Show From: </small>
                <input
                  type="date"
                  className="form-control"
                  name="fromDate"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </p>

              <p className="d-flex mb-2">
                <small className="ml-2">To: </small>
                <input
                  type="date"
                  className="form-control"
                  name="toDate"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </p>
            </div>

            <div className="ml-3">
              <button className="btn btn-green shadow-md" type="submit">
                Search
              </button>
            </div>
          </div>
        </form>
      );
    }
    return null;
  };

  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col-12 my-5">
          {displayForm()}

          <Bar
            data={data}
            options={{
              maintainAspectRatio: true,
              responsive: true,
              scales: {
                yAxes: [
                  {
                    scaleLabel: { display: true, labelString: 'BANDWIDTH', fontStyle: 'bold', fontSize: 17 },
                  },
                ],
                xAxes: [
                  {
                    ticks: { fontStyle: 'bold' },
                    scaleLabel: { display: true, labelString: 'TIMESTAMP', fontStyle: 'bold', fontSize: 17 },
                  },
                ],
              },
            }}
          />
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default BandwidthGraphBody;
