import React, { useContext } from 'react';
// import SearchNocSheets from '../SearchNocSheets';
import HotspotServerTable from './HotspotServerTable';
import AddHotspotServer from './AddHotspotServer';
import { HotspotServerContext } from './context/HotspotServerContext';
import PaginatePage from './PaginatePage';

const HotspotServerBody = () => {
  const { loading } = useContext(HotspotServerContext);
  return (
    <div className="container">
      <div className="row mt-5">
        {/* <SearchCnMaestro /> */}
        <div className="form-group ml-2">
          <button className="btn btn-green" type="button" data-toggle="modal" data-target="#add-access-point">
            Add Hotspot Server
          </button>
        </div>
        <AddHotspotServer />
        <HotspotServerTable />
        {!loading && <PaginatePage />}
      </div>
    </div>
  );
};

export default HotspotServerBody;
