export const GET_BANKS = 'GET_BANKS';
export const FILTER_REQUISITIONS = 'FILTER_REQUISITIONS';
export const LOADING_REQUISITIONS_STARTS = 'LOADING_REQUISITIONS_STARTS';
export const START_APPROVE_OR_DISAPPROVE = 'START_APPROVE_OR_DISAPPROVE';
export const STOP_APPROVE_OR_DISAPPROVE = 'STOP_APPROVE_OR_DISAPPROVE';
export const APPROVE_SUCCESS = 'APPROVE_SUCCESS';

export const APPROVE_OR_DISAPPROVE_SUCCESS = 'APPROVE_OR_DISAPPROVE_SUCCESS';
export const LOADING_REQUISITIONS_STOPS = 'LOADING_REQUISITIONS_STOPS';
export const REQUISITION_FILLED = 'REQUISITION_FILLED';
export const GET_REQUISITIONS = 'GET_REQUISITIONS';
export const PERCENTAGE_STARTS = 'PERCENTAGE_STARTS';
export const GET_MANAGER_REQUISITIONS = 'GET_MANAGER_REQUISITIONS';
export const GET_MANAGERS_REQUISITIONS = 'GET_MANAGERS_REQUISITIONS';
export const LOADING_MANAGER_REQUSITIONS_STARTS = 'LOADING_MANAGER_REQUSITIONS_STARTS';
export const LOADING_MANAGER_REQUSITIONS_STOPS = 'LOADING_MANAGER_REQUSITIONS_STOPS';
export const GET_EXECUTIVES_REQUISITIONS = 'GET_EXECUTIVES_REQUISITIONS';
export const GET_EMPLOYEES_REQUISITIONS = 'GET_EMPLOYEES_REQUISITIONS';
export const GET_APPROVED_REQUISITIONS = 'GET_APPROVED_REQUISITIONS';
export const LOADING_APPROVED_REQUISITIONS = 'LOADING_APPROVED_REQUISITIONS';
export const LOADING_APPROVED_REQUISITIONS_STOPS = 'LOADING_APPROVED_REQUISITIONS_STOPS';
export const GET_PAID_REQUISITION = 'GET_PAID_REQUISITION';
