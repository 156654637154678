import { useState } from 'react';
import { stringify } from 'qs';
import { toast } from 'react-toastify';
import legacy from '../../../../../../services/api/axios';

const useCustomersTracker = () => {
  const [options, setOptions] = useState({});
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const [params, setParams] = useState({
    action: 'tizeti_asset_retension_daily_chart',
    country: '',
    type: '',
    date: '',
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setParams({ ...params, [name]: value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await legacy.post('/index.php', stringify(params));
      if (response.data.status) {
        setLoading(false);

        const option = {
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        };

        const dates = [];
        response.data.items.forEach((singleElement) => {
          dates.push(singleElement.label);
        });
        const expired = [];
        response.data.items.forEach((singleElement) => {
          expired.push(singleElement.expiration_count);
        });
        const active = [];
        response.data.items.forEach((singleElement) => {
          active.push(singleElement.active_count);
        });

        const subs = [];
        response.data.items.forEach((singleElement) => {
          subs.push(singleElement.subscription_count);
        });

        setOptions(option);
        setData({
          labels: dates,
          datasets: [
            {
              label: params.type === 'Expiration' ? 'Total Expired users' : 'Total Active Users',
              data: params.type === 'Expiration' ? expired : active,
              backgroundColor: params.type === 'Active' ? 'rgb(75, 192, 192)' : 'rgb(255, 99, 132)',
            },
            {
              label: 'Daily Subscription',
              data: subs,
              backgroundColor: 'rgb(54, 162, 235)',
            },
          ],
        });
      }
    } catch (err) {
      setLoading(false);
      toast.error('Something went wrong');
    }
  };
  const validateSubmit = () => {
    return params.country.length > 0 && params.type.length > 0;
  };
  return { options, data, loading, onChange, onSubmit, params, validateSubmit };
};
export default useCustomersTracker;
