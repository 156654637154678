/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-shadow */
/* eslint-disable react/require-default-props */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/button-has-type */
/* eslint-disable no-case-declarations */
import React, { useState } from 'react';
import BulkCustomerDetailsTable from '../../../shared/bulk-customer-details/BulkCustomerDetailsTable';
import CustomCsvDownload from '../../../shared/CustomCsvDownload';
import { filename, bulkCustomerDetailsHeaders } from '../../../../../utils/field-support/installations/helpers';
import { LEGACY_GENIATECH_URL } from '../../../../../services/api/shared/installations/InstallationsUrl';
import useFetchBulkDetails from '../../../../../hooks/shared/bulk-customer-details/useFetchBulkDetails';
import CustomInput from '../../../shared/forms/CustomInput';

const BulkCustomerDetailsBody = () => {
  const { fetchDetails, loading } = useFetchBulkDetails();
  const [file, setFile] = useState('');

  const [tableData, setTableData] = useState([]);

  const onSubmit = async (e) => {
    e.preventDefault();
    const params = new FormData();
    params.append('action', 'bulk_fetch_customer_details');
    params.append('file', file);

    const response = await fetchDetails(`${LEGACY_GENIATECH_URL}`, params);
    setTableData(response.items);
  };

  const submitForm = () => {
    return file && file.name.length > 0;
  };

  return (
    <div className="container-fluid">
      <form onSubmit={onSubmit} className="form-inline mt-4 inner-addon d-flex">
        <div className="form-group col-md-12">
          <CustomInput
            type="file"
            className="form-control border border-info py-2"
            id="file"
            name="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={(e) => setFile(e.target.files[0])}
          />
        </div>

        <div className="col-12 d-flex mt-2">
          {loading ? (
            <button type="submit" className="btn btn-green py-2 px-5" disabled>
              Submitting{' '}
            </button>
          ) : (
            <button type="submit" className="btn btn-green py-2 px-5" disabled={!submitForm()}>
              Submit{' '}
            </button>
          )}
        </div>
      </form>
      <div className="col-12 d-flex mt-2">
        <CustomCsvDownload
          data={tableData || []}
          headers={bulkCustomerDetailsHeaders}
          filename={filename(`Bulk_Customer_Details`)}
          classname="btn btn-green shadow-md"
          textcolor="white"
          text="Download Data"
        />
      </div>
      <BulkCustomerDetailsTable file={tableData} />
    </div>
  );
};

export default BulkCustomerDetailsBody;
