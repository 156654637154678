import axios from 'axios';
import { toast } from 'react-toastify';
import * as load from '../../../constants/types/shared/LoadingTypes';
import * as url from '../../../services/api/customer-support/customers/CustomersUrl';
import * as types from '../../../constants/types/customer-support/CustomerSupportTypes';

export const fetchPastExpired = (data) => async (dispatch) => {
  dispatch({
    type: load.LOADING_STARTS,
    payload: true,
  });

  try {
    const response = await axios.post(`${url.SEARCH_FOR_EXPIRED_CUSTOMERS_BY_DAYS_URL}`, data);
    if (response.data) {
      dispatch({
        type: types.FETCH_PAST_EXPIRED,
        payload: response.data.splice(1, response.data.length),
      });
    } else {
      dispatch({
        type: load.LOADING_STOPS,
        payload: false,
      });
    }
  } catch (e) {
    if (e) {
      dispatch({
        type: load.LOADING_STOPS,
        payload: false,
      });
    }
  }
};

export const getAboutToExpireeeee = (data) => async (dispatch) => {
  dispatch({
    type: load.LOADING_STARTS,
    payload: true,
  });

  try {
    const response = await axios.post(`${url.SEARCH_FOR_ABOUT_TO_EXPIRE_CUSTOMERS_BY_DAYS_URL}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      }
    });

    if (response.data.length) {
      dispatch({
        type: types.GET_ABOUT_TO_EXPIRE,
        payload: response.data.splice(1, response.data.length),
      });
    } else if (response.data[0].status === false) {
      toast.info(response.data[0].error);
      dispatch({
        type: load.LOADING_STOPS,
        payload: false,
      });
    } else {
      toast.info('Something went wrong! Please try again later');
      dispatch({
        type: load.LOADING_STOPS,
        payload: false,
      });
    }
  } catch (e) {
    if (e) {
      dispatch({
        type: load.LOADING_STOPS,
        payload: false,
      });
    }
  }
};

export const getAboutToExpire = (data) => async (dispatch) => {
  dispatch({
    type: load.LOADING_STARTS,
    payload: true,
  });

  try {
    const response = await axios.post(`${url.SEARCH_FOR_ABOUT_TO_EXPIRE_CUSTOMERS_BY_DAYS_URL}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      }
    });

    if (response.data.length) {
      dispatch({
        type: types.GET_ABOUT_TO_EXPIRE,
        payload: response.data,
      });
    } else {
      toast.info('Unable to get customers');
      dispatch({
        type: load.LOADING_STOPS,
        payload: false,
      });
    }
  } catch (e) {
    if (e) {
      toast.info('Something went wrong! Please try again later');

      dispatch({
        type: load.LOADING_STOPS,
        payload: false,
      });
    }
  }
};
