import React from 'react';
import NocNavBar from '../../../../layouts/noc/NocNavBar';
import PowerConsumption from '../../../shared/noc/power-consumption/PowerConsumption';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import useCheckTwoDepartments from '../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';
import { NOC, SUPER_ADMIN } from '../../../../../utils/departments/helpers';

const NocPowerConsumption = ({ history }) => {
  const { profile } = useUser();
  const { firstName } = profile;
  useCheckTwoDepartments(`${NOC}`, `${SUPER_ADMIN}`, history);

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <NocNavBar currentPage="Basestation Power Consumption" position={firstName} />
        <PowerConsumption />
      </main>
    </div>
  );
};

export default NocPowerConsumption;
