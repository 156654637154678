/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable import/prefer-default-export */
import { useReducer, useState } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import qs from 'qs';
import * as url from '../../../services/api/procurement/retrievals/RetrievalsURL';
import * as types from '../../../constants/types/procurement/ProcurementTypes';
import * as load from '../../../constants/types/shared/LoadingTypes';
import { retrievalsReducer } from '../../../reducers/procurement/retrievals/retrievalsReducer';
import { DELETE_RETRIEVED_DEVICE_URL, PROCUREMENT_DELETE_RETRIEVED_DEVICE_URL } from '../../../components/staff/shared/device-retrieval/api/DeviceRetrievalUrl';

export const useRetrievals = () => {
  const [{ list, pages, count, search, isLoading }, dispatch] = useReducer(retrievalsReducer, {
    list: [],
    count: 1,
    pages: 1,
    search: false,
    isLoading: false,
  });

  const itemState = {
    routerMACID: '',
    radioMACID: '',
    customerName: '',
    customerEmail: '',
    radioTag: '',
    dateRetrieved: '',
    comment: '',
    itemName: '',
  };

  const selectState = {
    selectedTracklist: '',
    retrievedBy: '',
  };

  const decisionState = {
    approvalStatus: 'approved',
    approvalComment: '',
  };

  const [retrievalDecision, setRetrievalDecision] = useState(decisionState);
  const [retrieval, setRetrieval] = useState(itemState);
  const [page] = useState(1);
  const [selectedOption, setSelectedOption] = useState(selectState);
  const [one, setOne] = useState({});

  const handleSelect = (selected, action) => setSelectedOption({ ...selectedOption, [action.name]: selected });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRetrieval({ ...retrieval, [name]: value });
  };

  const handleUpdate = (e) => {
    const { name, value } = e.target;
    setOne({ ...one, [name]: value });
  };

  const handleDecision = (e) => {
    const { name, value } = e.target;
    setRetrievalDecision({ ...retrievalDecision, [name]: value });
  };

  const createRetrieval = async (data) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(`${url.CREATE_RETRIEVALS_URL}`, qs.stringify(data), {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        toastr.success('Item Added!');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const getRetrievals = async (currentPage) => {
    dispatch({ type: load.LOADING_STARTS });
    const config = {
      method: 'post',
      url: `${url.CREATE_RETRIEVALS_URL}/search/${currentPage}`,
      headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
    };

    await axios(config)
      .then((response) => {
        if (response.data) {
          dispatch({
            type: types.GET_RETRIEVALS,
            payload: {
              list: response.data.list,
              count: response.data.count,
              pages: response.data.pages,
            },
          });
        }
      })
      .catch((error) => {
        if (error) {
          toastr.info('Something went wrong!');
          dispatch({ type: load.LOADING_STOPS });
        }
      });
  };

  const updateRetrieval = async (data, id) => {
    try {
      const response = await axios.patch(`${url.CREATE_RETRIEVALS_URL}/${id}`, qs.stringify(data), {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data[0] === 1) {
        toastr.success('Update Successful');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };
  const searchRetrievals = async (data) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(`${url.CREATE_RETRIEVALS_URL}/search/${page}`, qs.stringify(data), {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_RETRIEVALS,
          payload: {
            list: response.data.list,
            count: response.data.count,
            pages: response.data.pages,
            search: true,
          },
        });
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const getMyRetrievals = async () => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.get(`${url.GET_MY_RETRIEVALS_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_RETRIEVALS,
          payload: {
            list: response.data.list,
            count: response.data.count,
            pages: response.data.pages,
          },
        });
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const getProcurementRetrievals = async (currentPage) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.get(`${url.PROCUREMENT_RETRIEVALS_URL}/${currentPage}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_RETRIEVALS,
          payload: {
            list: response.data.list,
            count: response.data.count,
            pages: response.data.pages,
          },
        });
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const procurementDecision = async (id, data) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.patch(`${url.PROCUREMENT_RETRIEVALS_DECISION_URL}/${id}`, qs.stringify(data), {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data[0] === 1) {
        toastr.success('Update Successful');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const getAccountsRetrievals = async (currentPage) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.get(`${url.ACCOUNT_RETRIEVALS_URL}/${currentPage}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_RETRIEVALS,
          payload: {
            list: response.data.list,
            count: response.data.count,
            pages: response.data.pages,
          },
        });
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const accountsDecision = async (id, data) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.patch(`${url.ACCOUNT_RETRIEVALS_DECISION_URL}/${id}`, qs.stringify(data), {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data[0] === 1) {
        toastr.success('Update Successful');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const deleteRetrieval = async (id) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.delete(`${DELETE_RETRIEVED_DEVICE_URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data[0] === 1) {
        toastr.success('Delete Successful');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const deleteProcurementRetrieval = async (id) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.delete(`${PROCUREMENT_DELETE_RETRIEVED_DEVICE_URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.message) {
        toastr.success('Delete Successful');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    getRetrievals(selectedPage);
  };

  return {
    list,
    pages,
    count,
    search,
    isLoading,
    createRetrieval,
    updateRetrieval,
    retrieval,
    handleChange,
    handleSelect,
    selectedOption,
    handleUpdate,
    setOne,
    one,
    retrievalDecision,
    handleDecision,
    deleteRetrieval,
    searchRetrievals,
    handlePageClick,
    getRetrievals,
    accountsDecision,
    getMyRetrievals,
    procurementDecision,
    getAccountsRetrievals,
    getProcurementRetrievals,
    deleteProcurementRetrieval,
  };
};
