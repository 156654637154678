import React, { Component } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import toastr from 'toastr';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createRetailer } from '../../../../../../../actions/hotspot/RetailersActions';
import URLS from '../../../../../../../services/api/hotspot/HotspotAdminUrls';

class CreateRetailer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      address: '',
      phone: '',
      email: '',
      password: '',
      business: '',
    };

    this.createRetailer = this.createRetailer.bind(this);
  }

  static propTypes = {
    createRetailer: PropTypes.func.isRequired,
  };

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  createRetailer = (e) => {
    e.preventDefault();

    const {
      name,
      address,
      phone,
      email,
      password,
      business,
    } = this.state;

    const retailer = {
      name,
      address,
      phone,
      email,
      password,
      businessName: business,
    };

    this.props.createRetailer(retailer);
  };

  submitForm() {
    const {
      name,
      address,
      phone,
      email,
      password,
      business,
    } = this.state;

    return (
      name.length > 0 &&
      address.length > 0 &&
      phone.length > 0 &&
      email.length > 0 &&
      password.length > 0 &&
      business.length > 0
    );
  }

  render() {
    const isEnabled = this.submitForm();
    const {
      name,
      address,
      phone,
      email,
      password,
      business,
    } = this.state;
    const { load } = this.props;

    return (
      <div
        id="create-retailer"
        className="modal fade in"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
        style={{ overflow: 'auto' }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4
                className="modal-title text-center"
                id="myModalLabel"
              >
                Create Retailer
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                ×
              </button>
            </div>
            <div className="modal-body">
              <form
                className="form-horizontal"
                onSubmit={this.createRetailer}
              >
                <div className="form-group">
                  <div className="col-12 d-flex mb-3">
                    <div className="col-6">
                      <label className="copy-font p-0 m-0">
                        Fullname
                      </label>
                      <input
                        type="text"
                        placeholder="Full name"
                        onChange={this.onChange}
                        value={name}
                        name="name"
                        className="form-control"
                      />
                    </div>

                    <div className="col-6">
                      <label className="copy-font p-0 m-0">
                        Business name
                      </label>
                      <input
                        type="text"
                        placeholder="Business name"
                        onChange={this.onChange}
                        value={business}
                        name="business"
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-12 d-flex mb-3">
                    <div className="col-6">
                      <div className="mb-2">
                        <label className="copy-font p-0 m-0">
                          Password
                        </label>
                        <input
                          type="password"
                          placeholder="Password"
                          onChange={this.onChange}
                          value={password}
                          name="password"
                          className="form-control"
                        />
                      </div>

                      <div>
                        <label className="copy-font p-0 m-0">
                          Phone number
                        </label>
                        <input
                          type="tel"
                          placeholder="Phone number"
                          onChange={this.onChange}
                          value={phone}
                          name="phone"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-6">
                      <label className="copy-font p-0 m-0">
                        Address
                      </label>
                      <textarea
                        rows="4"
                        cols="50"
                        placeholder="Business Address"
                        onChange={this.onChange}
                        value={address}
                        name="address"
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-12 d-flex mb-3">
                    <div className="col-12">
                      <label className="copy-font p-0 m-0">
                        Email Address
                      </label>
                      <input
                        type="email"
                        onChange={this.onChange}
                        value={email}
                        name="email"
                        placeholder="Email Address"
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-12 mb-3 text-center">
                    {load ? (
                      <button
                        className="btn btn-green"
                        disabled
                      >
                        Creating...
                      </button>
                    ) : (
                      <button
                        className="btn btn-green"
                        disabled={!isEnabled}
                      >
                        Create Retailer
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  load: state.retailers.load,
});

export default connect(mapStateToProps, { createRetailer })(
  CreateRetailer,
);
