/* eslint-disable import/prefer-default-export */
import * as load from '../../../constants/types/shared/LoadingTypes';
import { GET_REFERRAL_REQUEST } from '../../../constants/types/sales/customers/ReferralTypes';

export const referralReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case load.LOADING_STARTS:
      return {
        ...state,
        isLoading: true,
      };

    case load.LOADING_STOPS:
      return {
        ...state,
        isLoading: false,
      };

    case GET_REFERRAL_REQUEST:
      return {
        ...state,
        lists: payload.lists,
        pages: payload.pages,
        count: payload.count,
        monthlyCount: payload.monthlyCount,
        isLoading: false,
      };

    default:
      return state;
  }
};
