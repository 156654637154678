/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { accounts, admin, API_URL } from '../../../../../utils/config';
import ReactPaginate from '../../admin-department/recruitment/Recruitment';
import { getAccountsJobs } from '../../../../../actions/shared/recruitment/RecruitmentActions';
import { getJobsApplications } from '../../../../../actions/shared/recruitment/ApplicationsActions';
import NewPosting from '../../../shared/recruitment/admin/NewPosting';
import RecruitmentTable from '../../../shared/recruitment/RecruitmentTable';
import ApplicantTable from '../../../shared/recruitment/applications/ApplicantTable';
import AccountsNavBar from '../../../../layouts/accounts/AccountsNavBar';

class AccountsRecruitment extends Component {
  static propTypes = {
    // object: PropTypes.object.isRequired,
    getAccountsJobs: PropTypes.func.isRequired,
    // getJobsApplications: PropTypes.func.isRequired,
    applications: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      pages: [],
      logs: [],
      department: '',
      loading: false,
      firstname: '',
      application: '',
    };
  }

  componentDidMount() {
    this.fetchLoggedOnUser();
    this.props.getAccountsJobs();
  }

  componentWillReceiveProps(nextProps) {
    this.fetchLoggedOnUser();
    if (nextProps.jobs) {
      this.setState({
        logs: nextProps.jobs.logs,
        pages: nextProps.jobs.pages,
        count: nextProps.jobs.count,
      });
    }

    if (nextProps.jobs) {
      this.setState({
        logs: nextProps.jobs.logs,
        pages: nextProps.jobs.pages,
        count: nextProps.jobs.count,
      });
    }
  }

  getUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employee/loggedOnEmployee`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    }).then((res) => {
      if (res.data) {
        this.setState({
          firstname: res.data.firstName,
        });
      }
    });
  }

  handlePageChange = (pageNumber) => {
    const selected = pageNumber.selected + 1;

    const url = `${API_URL}/recruitment/listing/?page=${selected}`;

    axios({
      method: 'get',
      url,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      }
    }).then((response) => {
      if (response.data) {
        this.setState({
          logs: response.data.logs,
          pages: response.data.pages,
        });
      } else {
        toastr.info('No data!');
      }
    });
  };

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  getDepartmentApplications = (job) => {
    this.props.getJobsApplications(job);
  };

  getJobsByDate = (department) => {
    if (department) {
      this.setState({
        loading: true,
      });

      const url = `${API_URL}/recruitment/listing/${department}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        })
        .then((response) => {
          this.setState({
            loading: false,
            logs: response.data.logs,
            pages: response.data.pages,
          });
        })
        .catch(() => {
          // console.log(err.response);
        });
    }
  };

  fetchLoggedOnUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employeeReg/logged`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        if (data.data) {
          this.setState({
            role: data.data.role_id,
          });
        }

        if (data.data.department_id !== `${accounts}` && data.data.department_id !== `${admin}`) {
          this.props.history.push('/login');

          toastr.info('You are not allowed to view this page');
        }
      })
      .catch(() => {});
  }

  render() {
    const { loading, firstname } = this.state;
    const { logs } = this.props.jobs;
    const { applications, load } = this.props;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <AccountsNavBar currentPage="Recruitment" position={firstname} />

          <div className="container-fluid">
            <div className="row">
              <div className="col-12 mt-4">
                <div className="col-6 d-flex">
                  <div className="tab-stuff col-10 p-0">
                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                      <li className="nav-item mr-auto">
                        <a
                          className="nav-link active"
                          id="pills-home-tab"
                          data-toggle="pill"
                          href="#pills-home"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                        >
                          Job Postings
                        </a>
                      </li>
                      <li className="nav-item mr-auto">
                        <a
                          className="nav-link"
                          id="pills-profile-tab"
                          data-toggle="pill"
                          href="#pills-profile"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="false"
                        >
                          Applications
                        </a>
                      </li>
                      <button className="btn btn-green" data-target="#Newposting" data-toggle="modal" type="button">
                        New Posting
                      </button>

                      <NewPosting />
                    </ul>
                  </div>
                </div>

                <div className="tab-content col-12" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="col-6 d-flex p-0">
                      {/* <small>FilterByCountry By:</small> */}
                      <div className="d-flex">
                        {/* <span className="ml-2 mr-3"> */}
                        {/* <select className="form-control" onChange={(e) => this.getJobsByDate(e.target.value)} name="department"> */}
                        {/* <option value="">Department</option> */}
                        {/* {departments.map( */}
                        {/* (department) => { */}
                        {/* return <option value={department.id}>{department.name}</option> */}
                        {/* } */}
                        {/* )} */}
                        {/* </select> */}
                        {/* </span> */}

                        {/* <span className="mr-3"> */}
                        {/* <input type="date" className="form-control"></input> */}
                        {/* </span> */}
                      </div>
                    </div>

                    <div className="col-lg-12 p-0">
                      <div className="card x-panel w-100 ml-0 p-2 mt-4">
                        <div className="table-responsive content col-lg-12 col-md-12 col-sm-12 p-0">
                          <table className="table col-lg-12 col-md-12 col-sm-12 copy-font">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Job Title</th>
                                <th>Department</th>
                                {/* <th>Type</th> */}
                                {/* <th>Posting Date</th> */}
                                <th>Deadline</th>
                                {/* <th>Number of Applicants</th> */}
                                <th>Actions</th>
                              </tr>
                            </thead>

                            {loading ? <small>loading</small> : <RecruitmentTable all={logs} />}
                          </table>
                        </div>
                      </div>
                    </div>
                    {this.state.pages > 1 ? (
                      <ReactPaginate
                        previousLabel="previous"
                        nextLabel="next"
                        breakLabel="..."
                        breakClassName="break-me"
                        pageCount={this.state.pages}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={this.state.pageRangeDisplayed}
                        onPageChange={this.handlePageChange}
                        containerClassName="pagination"
                        subContainerClassName="pages pagination"
                        activeClassName="active"
                      />
                    ) : null}
                  </div>
                  <div
                    className="tab-pane fade show"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <div className="col-6 d-flex p-0">
                      <small>Filter By:</small>
                      <div className="d-flex">
                        <span className="ml-2 mr-3">
                          <select
                            className="form-control"
                            onChange={(e) => this.getDepartmentApplications(e.target.value)}
                            name="application"
                          >
                            <option>Jobs</option>
                            {logs ? logs.map((job) => <option value={job.id}>{job.job_title}</option>) : 'no jobs'}
                          </select>
                        </span>

                        <span className="mr-3">
                          <input type="date" className="form-control" />
                        </span>
                      </div>
                    </div>

                    <div className="col-6 d-flex p-0 mt-3">
                      <small>Filter By:</small>
                      <div className="d-flex">
                        <span className="ml-2 mr-3">
                          <select
                            className="form-control"
                            onChange={(e) => this.getDepartmentApplications(e.target.value)}
                            name="application"
                          >
                            <option>Country</option>
                            <option value="gh">Ghana</option>
                            <option value="ng">Nigeria</option>
                          </select>
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-12 p-0">
                      <div className="card x-panel w-100 ml-0 p-2 mt-4">
                        <div className="table-responsive content col-lg-12 col-md-12 col-sm-12 p-0">
                          <table className="table col-lg-12 col-md-12 col-sm-12 copy-font">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                {/* <th>Department</th> */}
                                {/* <th>Job Description</th> */}
                                <th>Title</th>
                                <th>Responsibilities</th>
                                <th>Application Date</th>
                                <th>Download CV</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            {/* <ApplicationsTableHead/> */}
                            <ApplicantTable applications={applications} load={load} />
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  jobs: state.accountsjobs.accountjobs,
  applications: state.applications.applications,
  load: state.loadApplications.load,
});

export default connect(
  mapStateToProps,
  {
    getAccountsJobs,
    getJobsApplications,
  },
)(AccountsRecruitment);
