/* eslint-disable no-nested-ternary */
import React from 'react';
import UploadXWFRetailers from './UploadXWFRetailers';

const SearchXWFRetailers = ({ searchRetailers, details, onOptionChange }) => {
  const page = 1;

  const { option, optionValue, fromDate, toDate } = details;

  const searchForUser = (e) => {
    e.preventDefault();
    searchRetailers(page);
  };

  return (
    <div className="col-lg-12 mt-5 mb-3 d-flex">
      <div>
        <form onSubmit={searchForUser} className="form-inline inner-addon d-flex">
          <div className="input-group mb-3 mr-3">
            <select name="option" onChange={onOptionChange} className="form-control mr-auto empty mt-1">
              <option value="">Choose fields to search from</option>
              <option value="businessName">Business Name</option>
              <option value="contactName">Contact Name</option>
              <option value="phoneNumber">Phone</option>
              <option value="status">Status</option>
              <option value="zone">Zone</option>
              <option value="date">Date</option>
            </select>
          </div>
          {option === 'date' ? (
            <div className="input-group mb-3 mr-3">
              <small className="pt-2">From:</small>
              <input className="form-control" type="date" name="fromDate" value={fromDate} onChange={onOptionChange} />
              <small className="pt-2">To:</small>
              <input className="form-control" type="date" name="toDate" value={toDate} onChange={onOptionChange} />
            </div>
          ) : (
            <div className="input-group mb-3 mr-3">
              <input
                className="form-control mr-auto empty pl-5"
                type="text"
                placeholder="type search term"
                name="optionValue"
                value={optionValue}
                onChange={onOptionChange}
              />
              <span className="fa fa-search" />
            </div>
          )}
          <div className="mb-3">
            <button type="submit" className="btn btn-green">
              Search
            </button>
          </div>
        </form>
      </div>

      <div className="ml-2" style={{ marginTop: '1px' }}>
        <UploadXWFRetailers />
      </div>
    </div>
  );
};

export default SearchXWFRetailers;
