import React, { Component } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { accounts, admin, API_URL } from '../../../../../utils/config';
import AddRequestFeature from '../../../shared/features/AddRequestFeature';
import AccountsNavBar from '../../../../layouts/accounts/AccountsNavBar';

export default class RequestFeature extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      role: '',
    };
  }

  componentDidMount() {
    this.getUserDetails();
    this.fetchLoggedOnUser();
  }

  getUserDetails() {
    axios({
      method: 'get',
      url: `${API_URL}/employee/loggedOnEmployee`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    }).then((res) => {
      if (res.data) {
        this.setState({
          firstName: res.data.firstName,
        });
      }
    });
  }

  fetchLoggedOnUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employeeReg/logged`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((response) => {
        this.setState({
          role: response.data.role_id,
        });

        if (response.data.id) {
          if (response.data.department_id !== `${accounts}` && response.data.department_id !== `${admin}`) {
            // eslint-disable-next-line react/prop-types,react/destructuring-assignment
            this.props.history.push('/staff/login');
            toastr.info('You are not allowed to view this page');
          }
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
      .catch((err) => {});
  }

  render() {
    const { firstName, role } = this.state;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <AccountsNavBar currentPage="Request A Feature" position={firstName} role_id={role} />

          <div className="container">
            <div className="row mt-5">
              <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-4">
                <AddRequestFeature />
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
