/* eslint-disable import/prefer-default-export */
import * as types from '../../../../constants/types/customer-support/CustomerSupportTypes';

export const archivesReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.START_GETTING_CUSTOMER_SESSION_DETAILS:
      return {
        fetching: true,
      };

    case types.START_GETTING_CUSTOMER_USAGE_WITH_DATE:
      return {
        loading: true,
      };

    case types.STOP_GETTING_CUSTOMER_SESSION_DETAILS:
      return {
        fetching: false,
      };

    case types.STOP_GETTING_CUSTOMER_USAGE_WITH_DATE:
      return {
        loading: false,
      };

    case types.GET_CUSTOMER_SESSION_DETAILS:
      return {
        ...state,
        session: payload,
        fetching: false,
      };

    case types.GET_CUSTOMER_USAGE_WITH_DATE:
      return {
        ...state,
        usage: payload,
        loading: false,
      };

    default:
      return state;
  }
};
