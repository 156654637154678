import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ACCOUNTS, SUPER_ADMIN } from '../../../../../utils/departments/helpers';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import AccountsNavBar from '../../../../layouts/accounts/AccountsNavBar';
import TracklistCategoryBody from '../../../shared/tracklist-category/TracklistCategoryBody';
import useCheckTwoDepartments from '../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';

const AccountTracklistCategory = ({ history }) => {
  useCheckTwoDepartments(`${ACCOUNTS}`, `${SUPER_ADMIN}`, history);
  const { profile } = useUser();
  const { firstName } = profile;
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <AccountsNavBar currentPage="TrackList" position={firstName} />
        <TracklistCategoryBody />
      </main>
    </div>
  );
};

export default AccountTracklistCategory;
