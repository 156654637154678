/* eslint-disable @typescript-eslint/camelcase */
import React, { useContext, useEffect, useState, ReactNode } from 'react';
import { TizetiGIFLogoTable } from '../../../../../utils/loader/loadingHelper';
import AppContext from '../../../../../context/shared/AppContext';
import CustomButton from '../../forms/CustomButton';
import SendBulkEmail from './SendBulkEmail';
import CustomCsvDownload from '../../CustomCsvDownload';
import { filename, mailBlastHeaders } from '../../../../../utils/field-support/installations/helpers';

const MailBlastBodyTable = () => {
  const { customers, fetching } = useContext(AppContext);
  const [newCustomers, setNewCustomers] = useState(customers);
  const [bulkModal, setBulkModal] = useState(false);
  const [emailData, setEmailData] = useState([]);
  useEffect(() => {
    if (customers && customers.length) {
      setNewCustomers(
        customers.map((item, i) => {
          const {
            username,
            Expiration_date,
            Name,
            Mail,
            Mobile,
            Customer_Number,
            created_at,
            subscription_status,
          } = item;
          return {
            isChecked: false,

            username,
            Expiration_date,
            Name,
            Mail,
            Mobile,
            Customer_Number,
            created_at,
            subscription_status,
            id: i + 1,
          };
        }),
      );
    }
  }, [customers]);

  const [sendBulk, setSendBulk] = useState(false);

  const onSelectOne = (e, id) => {
    setNewCustomers(
      newCustomers.map((dat) => {
        if (id === dat.id) {
          dat.isChecked = e.target.checked;
        }
        return dat;
      }),
    );
    if (newCustomers.filter((er) => er.isChecked).length > 0) {
      setSendBulk(true);
    } else {
      setSendBulk(false);
    }
  };
  const tableData =
    customers && customers.length && newCustomers && newCustomers.length
      ? newCustomers.map((item, i) => {
          const {
            username,
            Expiration_date,
            Name,
            Mail,
            Mobile,
            Customer_Number,
            created_at,
            subscription_status,
            id,
            isChecked,
          } = item;
          return (
            <tr className="" key={i}>
              <td>{i + 1}</td>
              <td>
                <input type="checkbox" checked={isChecked} value={id} onChange={(e) => onSelectOne(e, id)} />
              </td>
              <td className="text-nowrap">{Name}</td>

              <td>{username}</td>
              <td>{Mail}</td>
              <td>{Mobile}</td>
              <td>{Customer_Number}</td>
              <td>
                <div
                  className={`badge badge-${
                    subscription_status === 'expired' ? 'danger' : 'success'
                  } text-uppercase w-100`}
                >
                  {subscription_status || 'N/A'}
                </div>
              </td>

              <td className="text-nowrap">{Expiration_date}</td>
              <td className="text-nowrap">{created_at}</td>
            </tr>
          );
        })
      : null;

  const handleAllChecked = (event) => {
    const { checked } = event.target;
    setNewCustomers(
      newCustomers.map((dat) => {
        dat.isChecked = checked;
        return dat;
      }),
    );
    if (newCustomers.filter((er) => er.isChecked).length > 0) {
      setSendBulk(true);
    } else {
      setSendBulk(false);
    }
  };

  const openBulkModal = () => {
    setEmailData(
      newCustomers
        .filter((da) => da.isChecked)
        .map((user) => ({
          email: user.Mail,
        }))
        .map((opt) => opt.email),
    );
    setBulkModal(!bulkModal);
  };

  return (
    <div className="card x-panel mt-2 px-0">
      <div className="col-12 d-flex mt-2">
        <CustomCsvDownload
          data={customers}
          headers={mailBlastHeaders}
          filename={filename(`Mail Blast`)}
          classname="btn btn-green shadow-md"
          textcolor="white"
          text="Download Data"
        />
      </div>
      {sendBulk && (
        <div className="form-group mx-2">
          <CustomButton classname="btn btn-green" disabled={false} onClick={openBulkModal} name="Send Bulk Email" />
        </div>
      )}
      <SendBulkEmail data={emailData} show={bulkModal} onClose={openBulkModal} />
      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <thead>
            <tr className="text-nowrap">
              <th>#</th>
              <th>
                <input type="checkbox" value="checkedall" onChange={handleAllChecked} />
              </th>

              <th>Name</th>
              <th>MAC ID</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Customer Number</th>
              <th>Status</th>
              <th>Expiration Date</th>
              <th>Created At</th>
            </tr>
          </thead>

          <tbody>{fetching ? <TizetiGIFLogoTable colSpan="12" /> : tableData}</tbody>
        </table>
      </div>
    </div>
  );
};

export default MailBlastBodyTable;
