import * as departments from './config';

// eslint-disable-next-line import/prefer-default-export
export const showDepartment = (dept) => {
  switch (dept) {
    case `${departments.accounts}`:
      return 'ACCOUNTS';

    case `${departments.ADMIN_DEPARTMENT}`:
      return 'ADMIN DEPARTMENT';

    case `${departments.customer_support}`:
      return 'CUSTOMER SUPPORT';

    case `${departments.EXPRESS_WIFI}`:
      return 'EXPRESS WIFI';

    case `${departments.NEW_PRODUCTS}`:
      return 'NEW PRODUCTS';

    case `${departments.NOC}`:
      return 'NOC';

    case `${departments.operations}`:
      return 'Field Support';

    case `${departments.PROCUREMENT}`:
      return 'PROCUREMENT';

    case `${departments.RESEARCH_AND_DEVELOPMENT}`:
      return 'RESEARCH AND DEVELOPMENT';

    case `${departments.SALES}`:
      return 'SALES';

    case `${departments.TECHNICAL_SUPPORT}`:
      return 'FIELD SUPPORT';

    case `${departments.admin}`:
      return 'SUPER ADMIN';
    case `${departments.ASSET_TEAM}`:
      return 'ASSET TEAM';

    default:
      return null;
  }
};

export const showRole = (id) => {
  switch (id) {
    case 1:
      return 'Personnel';
    case 2:
      return 'Lead';
    case 3:
      return 'Manager';
    case 6:
      return 'Intern';
    case 11:
      return 'VP';
    case 8:
      return 'CTO';
    case 7:
      return 'CSO';
    case 4:
      return 'COO';
    case 9:
      return 'CFO';
    case 5:
      return 'CEO';

    default:
      return 'N/A';
  }
};
export const cannedOptions = [{ name: 'NEW INSTALLS' }, { name: 'ACTIVE CUSTOMERS' }, { name: 'EXPIRED CUSTOMERS' }];
