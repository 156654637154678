/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import toastr from 'toastr';
import { getLoggedInUser } from '../../../../actions/user/UserActions';
import { API_URL } from '../../../../utils/config';

class Escalate extends Component {
  static propTypes = {
    getLoggedInUser: PropTypes.func.isRequired,
    department: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      department: '',
      details: '',
      load: false,
      email: '',
      message: '',
    };
  }

  componentDidMount() {
    this.props.getLoggedInUser();
    this.getUserDetails();
  }

  getUserDetails() {
    axios({
      method: 'get',
      url: `${API_URL}/employee/loggedOnEmployee`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
      .then((res) => {
        if (res.data) {
          this.setState({
            email: res.data.email,
          });
        }
      })
      .catch((e) => {
        this.setState({ message: 'Please Ensure You Fill Employee Profile Form On The Overview Page' });
        toastr.info('Escalate Error');
      });
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  submitForm = (e) => {
    e.preventDefault();

    const { department, details, email } = this.state;

    const params = new URLSearchParams();
    params.append('action', 'escalate');
    params.append('from', email);
    params.append('department', department);
    params.append('reason', details);

    if (details.length > 0 && department.length > 0 && email.length > 0)
      return axios
        .post('https://legacy.tizeti.com/operations_api/modified_operations_api.php', params)
        .then((response) => {
          toastr.info('Successful!');
        })
        .catch((error) => toastr.info('Successfully Escalated!'));

    return toastr.info('Blank Form');
  };

  validateSubmit = () => {
    const { details, department, email } = this.state;
    return details.length > 0 && department.length > 0 && email.length > 0;
  };

  render() {
    const { department, details, load, message } = this.state;
    // const isEnabled = this.submitForm();

    return (
      <div className="container-fluid col-lg-7">
        <form onSubmit={this.submitForm} className="form-horizontal mt-5">
          <div className="form-group text-left">
            <div className="col-md-12 mb-5 d-flex p-0">
              <div className="col-md-12">
                {message ? (
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                ) : null}
                <label className="copy-font p-0 m-0">Department</label>
                <select className="form-control col-md-6" name="department" value={department} onChange={this.onChange}>
                  <option>-- Escalating To --</option>
                  <option value="Sales">Sales</option>
                  <option value="WifiCall">WifiCall</option>
                  <option value="Accounts">Accounts</option>
                  <option value="Customer Support">Customer Support</option>
                  <option value="Field Services">Field Services</option>
                  <option value="Procurement">Procurement</option>
                  <option value="Business Development">Business Development</option>
                  <option value="Administration">Administration</option>
                  <option value="Network Operations Centre">Network Operations Centre</option>
                  <option value="Research & Development">Research & Development</option>
                  <option value="ExpressWifi">ExpressWifi</option>
                </select>
              </div>
            </div>

            <div className="col-md-12 mb-5 d-flex">
              <div>
                <label className="copy-font p-0 mb-0">Details</label>
                <textarea
                  className="form-control"
                  name="details"
                  value={details}
                  onChange={this.onChange}
                  rows="5"
                  cols="50"
                  placeholder="Details of the Issue"
                />
              </div>
            </div>
          </div>

          {load ? (
            <div className="col-12 mt-5">
              <button type="button" className="btn btn-green" disabled>
                Submit
                {/* <i className="fa fa-spinner fa-spin" style={{ fontSize: '24px' }} /> */}
              </button>
            </div>
          ) : (
            <div className="col-12 mt-5">
              {/* <button className="btn btn-green" type="submit" disabled={!isEnabled}> */}
              <button className="btn btn-green" type="submit" disabled={!this.validateSubmit()}>
                Submit
              </button>
            </div>
          )}
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  department: state.auth.auth,
});

export default connect(
  mapStateToProps,
  {
    getLoggedInUser,
  },
)(Escalate);
