import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import useFetch from '../../../../../hooks/shared/custom/data/geniatech/useFetch';
import { LEGACY_GENIATECH_URL } from '../../../../../services/api/shared/installations/InstallationsUrl';
import AppContext from '../../../../../context/shared/AppContext';

const MailBlastBasestationCustomersFilter = () => {
  const { onChange, onSubmit, handleChange } = useContext(AppContext);
  const { data, loading } = useFetch(`${LEGACY_GENIATECH_URL}`, { action: 'fetch_basestations_list' });
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (data && data.items && data.items.length) {
      setOptions(
        data.items.map((el) => ({
          value: el,
          label: el,
        })),
      );
    }
  }, [data]);

  return (
    <div className="p-0">
      <div className="col-12 d-flex">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
          className="form-inline ml-3"
        >
          <div className="form-group">
            {loading ? (
              <Button variant="primary" disabled>
                <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                Fetching Basestations...
              </Button>
            ) : (
              <>
                <p className="select-p">Select Basestation</p>

                <Select
                  onChange={handleChange}
                  isMulti
                  name="basestations"
                  options={options}
                  className="basic-multi-select select-options"
                />
              </>
            )}
          </div>
          <div className="form-group">
            <select name="type" onChange={onChange} className="form-control">
              <option value="">--Filter By--</option>
              <option value="active">Active Customers</option>
              <option value="inactive">Inactive Customers</option>
            </select>
          </div>
          <div className="form-group mx-2">
            <button className="btn btn-green" type="submit">
              Search
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MailBlastBasestationCustomersFilter;
