/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable import/prefer-default-export */
import { useReducer } from 'react';
import axios from 'axios';
import qs from 'qs';
import toastr from 'toastr';
import * as load from '../../../../../../constants/types/shared/LoadingTypes';
import * as types from '../../../../../../constants/types/asset-team/AssetTeamTypes';
import { installationsReducer } from '../../../../../../reducers/shared/asset-team/installations/installationsReducer';
import { GET_ROUTER_URL } from '../../../../../../services/api/shared/BasestationsUrl';

export const useInstallationsPerBasestation = () => {
  const [{ items, time, customers, total, isLoading }, dispatch] = useReducer(installationsReducer, {
    customers: [],
    items: [],
    time: '',
    total: 0,
    isLoading: false,
  });

  const getInstallations = async (data) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(`${GET_ROUTER_URL}`, qs.stringify(data));
      if (response.data.installed_per_basestation_dynamic.items) {
        dispatch({
          type: types.GET_INSTALLATIONS,
          payload: {
            items: response.data.installed_per_basestation_dynamic.items,
            time: response.data.readable_last_updated_at,
            total: response.data.total_installed,
          },
        });
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const getInstalled7DaysAgo = async (data) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(`${GET_ROUTER_URL}`, qs.stringify(data));
      if (response.data) {
        dispatch({
          type: types.GET_INSTALLED_USERS,
          payload: response.data.items,
        });
      } else dispatch({ type: load.LOADING_STOPS });
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  return {
    time,
    items,
    total,
    isLoading,
    customers,
    getInstallations,
    getInstalled7DaysAgo,
  };
};
