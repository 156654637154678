/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/button-has-type */
import React, { useContext, useState } from 'react';
import PerformanceTable from './table/PerformanceTable';
import GeniatechContext from '../../../../../../context/GeniatechContext';
import CustomInput from '../../../../shared/forms/CustomInput';
import SearchPerformanceResults from './table/SearchPerformanceResults';

const PerformanceBody = () => {
  const { searchData, searchStatus } = useContext(GeniatechContext);
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const submit = (e) => {
    e.preventDefault();
    searchData({
      action: 'get_devices_performance',
      offset: '0',
      limit: '100',
      start_time: from,
      stop_time: to,
    });
  };
  return (
    <div className="container">
      <div className="row mt-5">
        <div className="mt-4 col-lg-12 col-md-12 col-sm-12">
          <div className="container">
            <div className="row mt-5">
              <form onSubmit={submit} className="form-inline mb-2 ml-3 col-12">
                <div className="form-group d-flex ml-2">
                  <small className="mr-1">From: </small>
                  <CustomInput
                    classname="form-control"
                    name="from"
                    value={from}
                    onChange={(e) => setFrom(e.target.value)}
                    readOnly={false}
                    placeholder=""
                    type="date"
                  />
                </div>
                <div className="form-group d-flex ml-2">
                  <small className="mr-1">To: </small>
                  <CustomInput
                    classname="form-control"
                    name="from"
                    value={to}
                    onChange={(e) => setTo(e.target.value)}
                    readOnly={false}
                    placeholder=""
                    type="date"
                  />
                </div>

                <div className="form-group ml-2">
                  <button className="btn btn-green">Search With Date</button>
                </div>
              </form>
              {searchStatus ? <SearchPerformanceResults /> : <PerformanceTable />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerformanceBody;
