/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-is-valid */
import '../../../../../styles/style.css';
import '../../../../../styles/callog.css';
import { Link } from 'react-router-dom';
import React from 'react';
import Logout from '../../../shared/auth/Logout';
import SalesSideBar from '../../../../layouts/sales/SalesSideBar';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import { SALES } from '../../../../../utils/config';
import PaidLeadsBody from '../../../shared/leads/PaidLeadsBody';
import useCheckTwoDepartments from '../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';
import { CUSTOMER_SUPPORT, SUPER_ADMIN, VICE_PRESIDENT_SALES } from '../../../../../utils/departments/helpers';
import useCheckFiveDepartments from '../../../../../hooks/shared/custom/check-department/useCheckFiveDepartments';

const SalesLeads = ({ history }) => {
  const { profile } = useUser();
  useCheckFiveDepartments(`${SALES}`, `${VICE_PRESIDENT_SALES}`, `${SUPER_ADMIN}`, `${CUSTOMER_SUPPORT}`, history);

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <div className="d-flex col-lg-12 p-2 top-header">
          <div className="mt-3 d-flex col-lg-12 p-0">
            <Link className="s-sidebar__trigger" to="#">
              <i className="fa fa-bars mr-3" />
            </Link>
            <SalesSideBar />

            <h3 className="col-lg-6 overview-part">Leads</h3>

            <div className="d-flex profile-mail col-6">
              <h6>
                <i className="mr-auto">{profile.firstName} </i>
                <Logout />
              </h6>
            </div>
          </div>
        </div>
        <PaidLeadsBody />
      </main>
    </div>
  );
};

export default SalesLeads;
