import React from 'react';
import PropTypes from 'prop-types';
import Requests from '../../../shared/requests/non-admin/Requests';
import useCheckDepartment from '../../../../../hooks/shared/custom/check-department/field-support/useCheckDepartment';
import FieldSupportNavBar from '../../../../layouts/field-support/FieldSupportNavBar';

const FieldSupportRequests = ({ history }) => {
  useCheckDepartment(history);

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <FieldSupportNavBar currentPage="Admin Request" />
        <Requests />
      </main>
    </div>
  );
};

FieldSupportRequests.propTypes = {
  // eslint-disable-next-line react/require-default-props
  history: PropTypes.shape(),
};

export default FieldSupportRequests;
