/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import Moment from 'react-moment';
import PropTypes from 'prop-types';
import ConfirmRetrievalItem from './ConfirmRetrievalItem';
import edit from '../../../../../assets/img/icons/edit.png';
import view from '../../../../../assets/img/icons/view.png';
import erase from '../../../../../assets/img/icons/delete.png';
import ViewRetrieval from '../../../shared/retrievals/ViewRetrieval';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../../utils/loader/loadingHelper';
import ConfirmDeleteRetrieval from '../../../shared/device-retrieval/ConfirmDeleteRetrieval';

const RetrievalsTable = ({ lists, search, load }) => {
  const [one, setOne] = useState({});
  const getItem = (list) => setOne(list);
  const colSpan = 10;

  const tableData =
    lists.length && lists ? (
      lists.map((list, i) => {
        const {
          item,
          radio_mac_id,
          customer_name,
          customer_email,
          retrieval_date,
          comment,
          EmployeeAuth,
          customer_number,
        } = list;
        return (
          <tr className="text-nowrap" key={i}>
            <td>{i + 1}</td>
            <td>{item}</td>
            <td>{radio_mac_id}</td>
            <td>{customer_name}</td>
            <td>{customer_number || 'No Number'}</td>
            <td>{customer_email}</td>
            <td>
              <div
                className={`badge badge-${
                  comment && comment.toLowerCase() === 'good' ? 'success' : 'danger'
                } w-100 text-uppercase`}
              >
                {comment}
              </div>
            </td>
            <td>
              <Moment format="DD-MM-YYYY">{retrieval_date}</Moment>
            </td>
            <td>
              {EmployeeAuth.employees[0]
                ? `${EmployeeAuth.employees[0].firstName} ${EmployeeAuth.employees[0].lastName}`
                : 'N/A'}
            </td>
            <td>
              <div className="text-center">
                <button
                  type="submit"
                  className="mr-2 noBorder"
                  data-toggle="modal"
                  data-placement="right"
                  data-target="#view_item"
                  onClick={() => getItem(list)}
                >
                  <span className="view">
                    <img src={view} data-toggle="tooltip" alt="" data-placement="top" title="View" />
                  </span>
                </button>
                {search !== true ? (
                  <button
                    type="submit"
                    className="mr-2 noBorder"
                    data-toggle="modal"
                    data-placement="right"
                    data-target="#confirm_item"
                    onClick={() => getItem(list)}
                  >
                    <span className="view">
                      <img src={edit} data-toggle="tooltip" alt="" data-placement="top" title="Confirm" />
                    </span>
                  </button>
                ) : null}
                {Number(sessionStorage.getItem('roleId')) > 2 && (
                  <button
                    type="button"
                    className="mr-2 noBorder"
                    data-target="#confirm_delete"
                    onClick={() => getItem(list)}
                    data-toggle="modal"
                  >
                    <span className="view">
                      <img src={erase} alt="" data-toggle="tooltip" data-placement="top" title="Delete" />
                    </span>
                  </button>
                )}
              </div>
              <ViewRetrieval oneItem={one} />
              <ConfirmRetrievalItem oneItem={one} />
              <ConfirmDeleteRetrieval one={one} />
            </td>
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan={colSpan} text="There are no Retrievals" />
    );

  return (
    <div className="card x-panel mt-2 px-0">
      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Item Name</th>
              <th className="text-nowrap">Radio Mac ID</th>
              <th>Customer Name</th>
              <th>Customer Number</th>
              <th>Email</th>
              <th>Condition</th>
              <th>Date Retrieved</th>
              <th>Retrieved By</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{load ? <TizetiGIFLogoTable colSpan={colSpan} /> : tableData}</tbody>
        </table>
      </div>
    </div>
  );
};

RetrievalsTable.propTypes = {
  load: PropTypes.bool.isRequired,
  search: PropTypes.bool.isRequired,
  lists: PropTypes.objectOf.isRequired,
};
export default RetrievalsTable;
