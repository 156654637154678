import React from 'react';
import CustomerSupportNavBar from '../../../layouts/customer-support/CustomerSupportNavBar';
import { useUser } from '../../../../hooks/shared/user/useUser';
import FieldSupportNavBar from '../../../layouts/field-support/FieldSupportNavBar';
import AssetTeamNavBar from '../../../layouts/asset-team/AssetTeamNavBar';
import { OPERATIONS, ASSET_TEAM, CUSTOMER_SUPPORT } from '../../../../utils/departments/helpers';
import FeedbackBody from './FeedbackBody';

const CustomerSupportInstallationFeedback = () => {
  const departmentId = sessionStorage.getItem('departmentId');
  const { profile, user } = useUser();
  const { firstName } = profile;
  return (
    <div className="s-layout">
      {(departmentId === `${OPERATIONS}` && (
        <main className="s-layout__content col-lg-10 p-0">
          <FieldSupportNavBar currentPage="Installation Feedback" position={firstName} />
          <div className="mt-5 pt-5 px-3">
            <FeedbackBody />
          </div>
        </main>
      )) ||
        (departmentId === `${CUSTOMER_SUPPORT}` && (
          <main className="s-layout__content col-lg-10 p-0">
            <CustomerSupportNavBar currentPage="Installation Feedback" roleID={user.role_id} position={firstName} />
            <div className="mt-5 pt-5 px-3">
              <FeedbackBody />
            </div>
          </main>
        )) ||
        (departmentId === `${ASSET_TEAM}` && (
          <main className="s-layout__content col-lg-10 p-0">
            <AssetTeamNavBar currentPage="Installation Feedback" position={firstName} />
            <div className="mt-5 pt-5 px-3">
              <FeedbackBody />
            </div>
          </main>
        ))}
    </div>
  );
};

export default CustomerSupportInstallationFeedback;
