/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import DeviceDetails from './DeviceDetails';
import view from '../../../../../assets/img/icons/view.png';

class TotalConsumptionTable extends Component {
  render() {
    return (
      <tbody>
        <tr>
          <td>1</td>
          <td>Agungi One</td>
          <td className="text-center">270</td>
          <td nowrap="no-wrap" className="text-center">
            <button
              type="submit"
              data-toggle="modal"
              data-placement="right"
              data-target="#device-details"
              style={{
                border: '0',
                backgroundColor: 'white',
              }}
            >
              {' '}
              <span className="view1">
                <img src={view} alt="" data-toggle="tooltip" data-placement="right" title="View" />
              </span>
            </button>
            <DeviceDetails />
          </td>
        </tr>
      </tbody>
    );
  }
}

export default TotalConsumptionTable;
