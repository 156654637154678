/* eslint-disable import/prefer-default-export */
import { useState, useCallback, useEffect, useReducer } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import toastr from 'toastr';
import qs from 'qs';
import { ADD_WIFICALL_USER_URL } from '../../../../../services/api/wificall/UsersUrl';
import * as url from '../../../../../services/api/wificall/CustomersUrl';
import * as types from '../../../../../constants/types/wificall/WificallTypes';
import * as load from '../../../../../constants/types/shared/LoadingTypes';
import { customerReducer } from '../../../../../reducers/wificall/customers/customerReducer';

export const useCustomers = () => {
  const [
    {
      verified,
      unverified,
      logs,
      verifiedCount,
      unverifiedCount,
      pages,
      plans,
      isLoading,
      filteredPages,
      paymentPages,
    },
    dispatch,
  ] = useReducer(customerReducer, {
    verified: 0,
    unverified: 0,
    logs: [],
    verifiedCount: 1,
    unverifiedCount: 1,
    pages: 1,
    plans: [],
    isLoading: false,
    filteredPages: 1,
    paymentPages: 1,
  });
  const customerState = {
    firstName: '',
    lastName: '',
    address: '',
    email: '',
    phone: '',
    plan: '',
    password: '',
    gender: '',
    referral: '',
    about: '',
    source: '',
    load: false,
  };
  const [customer, setCustomer] = useState(customerState);
  const [currentPage, setCurrentPage] = useState(1);
  const [source, setSource] = useState('');
  const [planType, setPlanType] = useState('');

  const onChange = (e) => {
    const { name, value } = e.target;
    setCustomer({
      ...customer,
      [name]: value,
    });
  };

  const handleSource = (e) => setSource(e.target.value);

  const handlePlanType = (e) => setPlanType(e.target.value);

  const onSubmit = async (data) => {
    try {
      const response = await axios.post(`${ADD_WIFICALL_USER_URL}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.status === 'success') {
        setCustomer(customerState);
        toast.success('Customer added successfully!');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (e) {
      toast.info('Something went wrong!');
    }
  };

  const getVerifiedUsers = useCallback(async () => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.get(`${url.GET_VERIFIED_CUSTOMER_URL}/${currentPage}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_WIFICALL_USERS,
          payload: {
            verifiedUsers: response.data.users,
            pages: response.data.pages,
            count: response.data.count,
          },
        });
      }
    } catch (e) {
      toast.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  }, [currentPage]);

  const getUnverifiedUsers = useCallback(async () => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.get(`${url.GET_UNVERIFIED_CUSTOMER_URL}/${currentPage}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_UNVERIFIED_WIFICALL_USERS,
          payload: {
            unverifiedUsers: response.data.users,
            pages: response.data.pages,
            count: response.data.count,
          },
        });
      }
    } catch (e) {
      toast.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  }, [currentPage]);

  const getCustomerPayments = async (page) => {
    try {
      const response = await axios.get(`${url.GET_CUSTOMER_PAYMENTS_URL}?page=${page}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_CUSTOMER_PAYMENTS,
          payload: {
            logs: response.data.logs,
            count: response.data.count,
            pages: response.data.pages,
          },
        });
      }
    } catch (e) {
      toast.info('Something went wrong!');
    }
  };

  const getWificallPlans = async () => {
    try {
      const response = await axios.get(`${url.WIFICALL_PLANS_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_WIFICALL_PLANS,
          payload: response.data,
        });
      }
    } catch (e) {
      toastr.info('Something went wrong!');
    }
  };

  const sendPushNotification = async (data) => {
    try {
      const response = await axios.post(`${url.SEND_PUSH_NOTIFICATION_URL}`, data);
      if (response.data.status === true) return toastr.success('Push Notification Sent!');
      return toastr.error('Something went wrong!');
    } catch (e) {
      return toastr.error('Something went wrong!');
    }
  };

  const activateCustomer = async (data) => {
    try {
      const response = await axios.get(`${url.VERIFY_WIFICALL_USER_BY_USER_ID}/${data.id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (typeof response.data === 'object' && response.data) {
        toastr.success(`${data.firstName} has been activated`);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (e) {
      toastr.info('Something went wrong!');
    }
  };

  const searchWithEmail = async (data) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(`${url.SEARCH_WIFICALL_CUSTOMERS_WITH_EMAIL_URL}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.SEARCH_WIFICALL_USERS,
          payload: {
            customers: response.data,
            pages: 0,
            filteredPages: 0,
          },
        });
      }
      if (response.data.length < 1) {
        toastr.info('No user with that email exists');
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const searchWithPhone = async (data) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(`${url.SEARCH_WIFICALL_CUSTOMERS_WITH_PHONE_URL}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.SEARCH_WIFICALL_USERS,
          payload: {
            customers: response.data,
            pages: 0,
          },
        });
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const filterBySource = async (selectedSource, data, page, recordsPerPage) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(
        `${url.FILTER_UNVERIFIED_USERS_URL}/${page}?source=${selectedSource}&limit=${recordsPerPage}`,
        qs.stringify(data),
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      );
      if (response.data) {
        dispatch({
          type: types.GET_FILTERED_WIFICALL_USERS,
          payload: {
            customers: response.data.users,
            filteredPages: response.data.pages,
          },
        });
      }
    } catch (e) {
      toast.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const filterByAccountType = async (data, page, recordsPerPage) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(
        `${url.FILTER_UNVERIFIED_USERS_URL}/${page}?limit=${recordsPerPage}`,
        qs.stringify(data),
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      );
      if (response.data) {
        dispatch({
          type: types.GET_FILTERED_WIFICALL_USERS,
          payload: {
            customers: response.data.users,
            filteredPages: response.data.pages,
          },
        });
      }
    } catch (e) {
      toast.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    getVerifiedUsers();
    getUnverifiedUsers();
    getWificallPlans();
  }, [getVerifiedUsers, getUnverifiedUsers]);
  return {
    customer,
    onChange,
    onSubmit,
    verified,
    unverified,
    getUnverifiedUsers,
    getCustomerPayments,
    logs,
    verifiedCount,
    unverifiedCount,
    pages,
    sendPushNotification,
    plans,
    activateCustomer,
    filterBySource,
    filterByAccountType,
    searchWithEmail,
    searchWithPhone,
    isLoading,
    handlePageClick,
    filteredPages,
    source,
    handleSource,
    planType,
    handlePlanType,
    paymentPages,
  };
};
