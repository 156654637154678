import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { GET_ONE_EMPLOYEE_URL } from '../../../../../../services/api/shared/employees/EmployeesUrl';
import { GET_AGENT_SCORE_URL } from '../../../../../../services/api/customer-support/quality-assurance/QualityAssuranceUrl';
import { getUser } from '../../../../../../actions/shared/user/UserActions';

class PersonnelSendReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      from: '',
      to: '',
      employee: [],
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  static propTypes = {
    getEmployeesInCustomerSupport: PropTypes.func.isRequired,
    employees: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.getUser();
  }

  sendReport = async () => {
    const doc = new jsPDF();

    const { from, to, employee } = this.state;

    const data = {
      from,
      to,
    };

    if (from && to && employee) {
      // get employee deets
      try {
        const res = await axios.get(`${GET_ONE_EMPLOYEE_URL}/${employee}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });

        if (res.data) {
          this.setState({
            employee: '',
          });
          const response = await axios.post(`${GET_AGENT_SCORE_URL}/${employee}`, data, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
          });

          try {
            const response = await axios.post(`${GET_AGENT_SCORE_URL}/${employee}`, data, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
              },
            });

            const columns = [
              { title: 'Date Added', dataKey: 'created_at' },
              { title: 'Extension', dataKey: 'extension' },
              { title: 'Knowledge', dataKey: 'knowledge' },
              // {title: "Closing", dataKey: "closing"},
              // {title: "Listening", dataKey: "listening"},
              { title: 'Resolution', dataKey: 'resolution' },
              { title: 'Greetings', dataKey: 'greetings' },
              { title: 'Feedback', dataKey: 'feedback' },
            ];

            const rows = response.data;

            doc.autoTable(columns, rows, {
              // styles: {fillColor: [100, 255, 255]},
              // columnStyles: {
              //   id: {fillColor: 255}
              // },
              margin: { top: 20 },
              addPageContent(data) {
                doc.text(`${res.data.firstName} ${res.data.lastName}`, 20, 10);
              },
            });
            doc.save(`${res.data.firstName}`);
          } catch (e) {
            console.log(e);
          }
        }
      } catch (e) {}
    } else {
      toastr.info('Please select the employee, from and to date of the report you want');
    }
  };

  render() {
    const { from, to } = this.state;
    const { profile } = this.props;
    const { firstName, lastName, extension, id } = profile;

    return (
      <div
        id="send-report"
        className="modal fade in"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center" id="myModalLabel">
                Send Report
              </h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
            </div>
            <div className="modal-body">
              <div className="col-12 d-flex mb-4 p-0">
                <div className="mr-5">
                  <label className="copy-font m-0 p-0">From</label>
                  <input type="date" className="form-control" name="from" value={from} onChange={this.onChange} />
                </div>

                <div className="ml-auto">
                  <label className="copy-font m-0 p-0">to</label>
                  <input type="date" className="form-control" name="to" value={to} onChange={this.onChange} />
                </div>
              </div>
              <table className="table score-agent-table copy-font">
                <thead>
                  <tr>
                    <th nowrap="no-wrap">Select Agent</th>
                    <th className="text-right">Extension</th>
                  </tr>
                </thead>
                {id ? (
                  <tbody>
                    <tr>
                      <td nowrap="no-wrap">
                        <div className="d-flex">
                          <input
                            type="checkbox"
                            className="mt-1 mr-5"
                            name="employee"
                            value={id}
                            onChange={this.onChange}
                          />
                          <h6>
                            {firstName} {lastName}
                          </h6>
                        </div>
                      </td>
                      <td className="text-right">
                        <h6>{extension}</h6>
                      </td>
                    </tr>

                    <tr>
                      <td className="text-right">
                        <button className="btn btn-green" type="submit" onClick={this.sendReport}>
                          Send Report
                        </button>
                      </td>
                    </tr>
                  </tbody>
                ) : null}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.user.user,
});

export default connect(mapStateToProps, { getUser })(PersonnelSendReport);
