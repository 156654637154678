/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import toastr from 'toastr';
import CustomButton from '../forms/CustomButton';
// import { RefundProps } from './type/RefundTypes';
import { ACCOUNTS, OPERATIONS, SALES, SUPER_ADMIN, VICE_PRESIDENT_SALES } from '../../../../utils/departments/helpers';
import { showDifferentContentForDepartments } from '../utils/shared-utils';
import useInstallations from '../../../../hooks/shared/installations/useInstallations';

const EditRefund = ({ show, onClose, updateRefund, refunding, refund, page, item, reload, option }) => {
  const [comment, setComment] = useState();
  const [options, setOptions] = useState('');

  const installId = refund.installation_id;

  const { editInstallationStatus } = useInstallations();

  const onUpdateRefunds = (e) => {
    setOptions(e.target.value);
  };
  const deptId = sessionStorage.getItem('departmentId');

  const onConfirm = (e) => {
    e.preventDefault();
    //check sales
    if (deptId == SALES || deptId == VICE_PRESIDENT_SALES) {
      if (!comment) return toastr.info('Comment is required');
      if (options === 'rejected') {
        //rejected flow
        const dat = {
          status: 'queued',
        };
        editInstallationStatus(installId, dat);
        const data = {
          sales_approval: options,
          sales_comment: comment,
        };
        updateRefund(data, refund.id, reload, page, item, option);
      } else {
        //approval
        const data = {
          sales_approval: options,
          sales_comment: comment,
        };
        updateRefund(data, refund.id, reload, page, item, option);
      }
    }
    //check if fse
    if (deptId === OPERATIONS) {
      if (!comment) return toastr.info('Comment is required');
      if (options === 'rejected') {
        //rejected flow
        const dat = {
          status: 'queued',
        };
        editInstallationStatus(installId, dat);
        const data = {
          fse_approval: options,
          fse_comment: comment,
        };
        updateRefund(data, refund.id, reload, page, item, option);
      } else {
        //approval
        const data = {
          fse_approval: options,
          fse_comment: comment,
        };
        updateRefund(data, refund.id, reload, page, item, option);
      }
    }

    //check if coo
    if (deptId === SUPER_ADMIN) {
      if (!comment) return toastr.info('Comment is required');
      //fse
      if (options === 'e1f5454d-660d-4a0e-8640-c90950dd97fc') {
        const data = {
          send_back_to_department: options,
          coo_comment: comment,
        };
        updateRefund(data, refund.id, reload, page, item, option);
        //sales
      } else if (options === 'aaf924bf-e16f-4ddc-843b-be5f91c8b0d2') {
        const data = {
          send_back_to_department: options,
          coo_comment: comment,
        };
        updateRefund(data, refund.id, reload, page, item, option);
      } else {
        //approval/rejection
        const data = {
          coo_approval: options,
          coo_comment: comment,
        };

        updateRefund(data, refund.id, reload, page, item, option);
      }
    }
    //check Accounts department
    if (deptId === ACCOUNTS) {
      if (options === 'rejected') {
        if (!comment) return toastr.info('Comment is required');
        const data = { refund_status: options, account_comment: comment };
        updateRefund(data, refund.id, reload, page, item, option);
      } else {
        const datay = { refund_status: options };
        updateRefund(datay, refund.id, reload, page, item, option);
      }
    }
  };

  const showConfirmationDialog = () => {
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title style={{ paddingLeft: '2rem' }}>Update Refund </Modal.Title>
        </Modal.Header>
        <form className="form-horizontal">
          <div className="form-group">
            <div className="col-12 mb-3">
              <div className="col-12">
                {deptId === ACCOUNTS && (
                  <>
                    <select
                      id="status"
                      name="canned"
                      onChange={onUpdateRefunds}
                      className="form-control border border-info py-2"
                      required
                    >
                      <option value="">--Select Option--</option>
                      <option value={'uploaded'}>Uploaded</option>
                      <option value={'approved'}>Paid</option>
                      <option value={'rejected'}>Rejected</option>
                    </select>
                    <br />
                    {options === 'rejected' ? (
                      <textarea
                        className="form-control border border-secondary shadow-sm borderRadius0"
                        placeholder="Enter your comment here. If you are Rejecting this request please say why here."
                        required
                        onChange={(e) => setComment(e.target.value)}
                        name="comment"
                      ></textarea>
                    ) : null}
                  </>
                )}
                {(deptId === OPERATIONS || deptId === SALES || deptId === VICE_PRESIDENT_SALES) && (
                  <div className="">
                    <select
                      id="status"
                      name="canned"
                      onChange={onUpdateRefunds}
                      className="form-control border border-info py-2"
                      required
                    >
                      <option value="">--Select Option--</option>
                      <option value={'approved'}>Approved</option>
                      <option value={'rejected'}>Rejected</option>
                    </select>
                    <br />
                    <textarea
                      className="form-control border border-secondary shadow-sm borderRadius0"
                      placeholder="Enter your comment here. If you are Rejecting this request please say why here."
                      required
                      onChange={(e) => setComment(e.target.value)}
                      name="comment"
                    ></textarea>
                  </div>
                )}
                {deptId === SUPER_ADMIN && (
                  <div className="">
                    <select
                      id="status"
                      name="canned"
                      onChange={onUpdateRefunds}
                      className="form-control border border-info py-2"
                      required
                    >
                      <option value="">--Select Option--</option>
                      <option value={'approved'}>Approved</option>
                      <option value={'rejected'}>Rejected</option>
                      <option value={'e1f5454d-660d-4a0e-8640-c90950dd97fc'}>Send Back to FSE </option>
                      <option value={'aaf924bf-e16f-4ddc-843b-be5f91c8b0d2'}>Send Back to SALES </option>
                    </select>
                    <br />
                    <textarea
                      className="form-control border border-secondary shadow-sm borderRadius0"
                      placeholder="Enter your comment here. If you are Rejecting this request please say why here."
                      required
                      onChange={(e) => setComment(e.target.value)}
                      name="comment"
                    ></textarea>
                  </div>
                )}
              </div>
            </div>
            <div className="col-12 mb-3 text-center">
              {refunding ? (
                <CustomButton classname="btn btn-green" name="Updating" disabled />
              ) : (
                <CustomButton classname="btn btn-green" name="Update Status" disabled={false} onClick={onConfirm} />
              )}{' '}
            </div>
          </div>
        </form>
      </>
    );
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      {showConfirmationDialog()}
    </Modal>
  );
};

export default EditRefund;
