import React from 'react';

const PendingTableHead = () => {
  return (
    <thead>
      <tr className="copy-font">
        <th scope="col">#</th>
        <th scope="col">Name</th>
        <th scope="col">Account-ID</th>
        <th scope="col">Email</th>
        <th scope="col">Phone Number</th>
        <th scope="col">Date Registered</th>
        <th scope="col">Status</th>
        <th scope="col" className="text-center">
          Activate
        </th>
        <th scope="col" className="text-center">
          Actions
        </th>
      </tr>
    </thead>
  );
};

export default PendingTableHead;
