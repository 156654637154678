import React from 'react';
import useLteCustomers from '../hooks/useLteCustomers';
import AppContext from '../../../../../../../context/shared/AppContext';

const LteCustomerProvider = ({ children }) => {
  const {
    loadingForm,
    onChange,
    search,
    onSearch,
    data,
    showExtendModal,
    onOpenExtendModal,
    handleChange,
    extend,
    extending,
    onSubmit,
    onExtensionTypeChange,
    extensionType,
  } = useLteCustomers();
  return (
    <AppContext.Provider
      value={{
        loadingForm,
        onChange,
        search,
        onSearch,
        data,
        showExtendModal,
        onOpenExtendModal,
        handleChange,
        extend,
        extending,
        onSubmit,
        onExtensionTypeChange,
        extensionType,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export default LteCustomerProvider;
