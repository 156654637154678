/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import '../../../../styles/style.css';
import '../../../../styles/sales_overview.css';
import axios from 'axios';
import toastr from 'toastr';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Mailblast from '../../../../assets/img/icons/mailblast.svg';
import Profile from '../../../../assets/img/icons/Profile.png';
import booktech from '../../../../assets/img/icons/book-tech.fw.png';
import gifttech from '../../../../assets/img/icons/gift-tech.fw.png';
import techcustomer from '../../../../assets/img/icons/tech-customer.fw.png';
import { LOGGED_IN_USER_URL } from '../../../../services/api/auth/AuthUrl';
import { RESEARCH_AND_DEVELOPMENT } from '../../../../utils/config';
// import ResearchLogo from '../../../layouts/research-and-development/ResearchLogo';
import Logout from '../../shared/auth/Logout';
import ResearchAndDevelopmentSideBar from '../../../layouts/research-and-development/ResearchAndDevelopmentSideBar';
import ViewUserProfile from '../../shared/staff/ViewUserProfile';
import { getLoggedInUser } from '../../../../actions/user/UserActions';
import CreateProfilePrompt from '../../shared/CreateProfilePrompt';
import { SUPER_ADMIN } from '../../../../utils/departments/helpers';

class ResearchOverview extends Component {
  componentWillMount() {
    this.props.getLoggedInUser();
    this.checkDepartment();
  }

  componentWillReceiveProps() {
    this.checkDepartment();
  }

  checkDepartment = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (
        response.data.department_id !== `${RESEARCH_AND_DEVELOPMENT}` &&
        response.data.department_id !== `${SUPER_ADMIN}`
      ) {
        this.props.history.push('/');

        sessionStorage.setItem('token', '');
        sessionStorage.clear();

        toastr.info('You are not allowed to view this page');
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  render() {
    // eslint-disable-next-line camelcase
    const { role_id } = this.props.role;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <Link className="s-sidebar__trigger" to="#">
                <i className="fa fa-bars mr-3" />
              </Link>

              <ResearchAndDevelopmentSideBar />

              <h3 className="col-lg-6 overview-part">Overview</h3>

              <div className="d-flex profile-mail">
                {Number(role_id) > 2 ? (
                  <Link
                    to="/research-and-development/mail-blast"
                    className="d-flex"
                    style={{
                      color: 'gray',
                      textDecoration: 'none',
                    }}
                  >
                    <img src={Mailblast} className="logout mr-2" alt="" />
                    <span>
                      <h6 className="mt-2">
                        <strong>MailBlast</strong>
                      </h6>
                    </span>
                  </Link>
                ) : null}
                <Link to="#" data-toggle="modal" data-target="#add-employee">
                  <img src={Profile} className="logout mr-2 ml-2 mt-1" alt="" />
                </Link>
                <Logout />
                <CreateProfilePrompt />
              </div>
            </div>
          </div>

          <div className="col-12 after-summary">
            <ViewUserProfile />
            <div className="row">
              <div className="container-fluid col-lg-11" style={{ marginLeft: '0' }}>
                <div className="card-body col-lg-12" style={{ padding: '17px' }}>
                  <div
                    className="form-inline"
                    // action="javascript:void(0)"
                    style={{ width: '100%' }}
                  >
                    <div className="col-lg-12 d-md-flex sales-summary d-none">
                      <p className="col-lg-8 summary copy-font">Research and Development </p>
                      <p className="sales-opt copy-font col-lg-4 d-flex">
                        <small className="mt-2"> Filter By:</small>
                        <span>
                          <select className="form-control">
                            <option>Month</option>
                          </select>
                        </span>

                        <span>
                          <select className="form-control">
                            <option>Year</option>
                          </select>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 side d-flex">
                <div className="col-lg-2">
                  <div className="col-lg-12">
                    <div className="boxes" id="boxx">
                      <div className="text-center mt-2">
                        <img src={booktech} alt="" width="25%" />
                      </div>
                      <div className="count">12</div>
                      <h3
                        style={{
                          color: '#3BB427',
                          fontWeight: 'bold',
                        }}
                      >
                        Projects Completed
                      </h3>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="boxes">
                      <div className="text-center mt-4">
                        <img src={gifttech} width="25%" alt="homepage" />
                      </div>
                      <div className="count">3</div>
                      <h3
                        style={{
                          color: '#F37705',
                          fontWeight: 'bold',
                        }}
                      >
                        New Products
                      </h3>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="boxes">
                      <div className="text-center mt-4">
                        <img src={techcustomer} alt="" width="25%" />
                      </div>
                      <div className="count">9 </div>
                      <h3 style={{ color: '#673FBB' }}>
                        <b>Team Members</b>
                      </h3>
                    </div>
                  </div>
                </div>

                <div className="col-lg-10">
                  <div className="card-group mt-1">
                    <div className="card o-income">
                      <div className="card-body">
                        <div className="d-flex m-b-30 no-block ml-2">
                          <h4 className="card-title m-b-0 align-self-center">Revenue by product</h4>
                        </div>

                        <div className="d-flex col-lg-12 graph">
                          <div
                            className="colors d-flex col-lg-12"
                            style={{
                              justifyContent: 'flex-end',
                            }}
                          >
                            <p
                              className="color"
                              style={{
                                backgroundColor: '#99265D',
                              }}
                            />
                            <p className="copy-font">WifiCall</p>
                            <p
                              className="color-dif"
                              style={{
                                backgroundColor: '#14B0B0',
                              }}
                            />
                            <p className="copy-font"> Hotspot Retailer</p>
                          </div>
                        </div>

                        <div id="RD" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  role: state.auth.auth,
});

export default connect(mapStateToProps, {
  getLoggedInUser,
})(ResearchOverview);
