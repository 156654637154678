import React from 'react';

const RetentionUnderConstruction = () => {
  return (
    <div className="mt-5 px-3">
      <div className="d-flex justify-content-center mt-5">
        <h2 className="text-dark fw-bold mt-5">Cannot access page...</h2>
      </div>
    </div>
  );
};

export default RetentionUnderConstruction;
