import React, { Component } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { Redirect, Link } from 'react-router-dom';
import Logout from '../../shared/auth/Logout';
import WifiCallSidebar from '../../../layouts/wificall-sidebar/WifiCallSidebar';
import WifiCallLogo from '../../../layouts/wificall-logo/WifiCallLogo';
import { API_URL } from '../../../../utils/config';
import { SUPER_ADMIN } from '../../../../utils/departments/helpers';

export default class WalletRefill extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      redirect: false,
      move: false,
      plans: [],
    };
  }

  componentWillMount() {
    if (!sessionStorage.getItem('token')) {
      this.setState({
        move: true,
      });
    }

    if (sessionStorage.getItem('data')) {
    } else {
      this.setState({
        redirect: true,
      });
    }

    this.fetchLoggedOnUser();
  }

  componentWillReceiveProps() {
    this.fetchLoggedOnUser();
  }

  fetchLoggedOnUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employee/loggedOnEmployee`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((data) => {
        // console.log(data.data.department_id);

        if (
          data.data.department_id !== 'c3caed52-dfd5-402f-80e4-31861da8554e' &&
          data.data.department_id !== 'a4a0d87a-bc1f-41cb-ab97-fc9d6405b8f1' &&
          data.data.department_id !== `${SUPER_ADMIN}`
        ) {
          sessionStorage.setItem('data', '');
          sessionStorage.clear();
          this.setState({
            out: true,
          });

          toastr.info('You are not allowed to view this page');
        }
      })
      .catch((err) => {});
  }

  render() {
    const { user, move } = this.state;

    const { firstName } = user;

    if (move) {
      return <Redirect to="/staff/login" />;
    }

    return (
      <div className="main-wrapper" id="Wallet-refil">
        <header className="topbar shade bg-white fixed-top">
          <nav className="navbar navbar-expand-lg navbar-light top-navbar fixed-top">
            <WifiCallLogo />
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse mt-3 wifii" id="navbarText">
              <ul className="navbar-nav">
                <li className="nav-item active" id="control-wifi">
                  <div className="d-flex">
                    <Link className="nav-link" to="#">
                      <img
                        alt="pay"
                        src="https://res.cloudinary.com/teewhy/image/upload/v1545323776/payyy.fw.png"
                        data-toggle="collapse"
                        data-target="#demo"
                      />
                    </Link>
                    <h6 className="mt-3"> Wallet Refill</h6>
                  </div>
                  <ul id="demo" className="collapse">
                    <li className="copy-font">
                      <p className="copy-font" style={{ fontSize: '15px' }}>
                        <Link to="/wifi-call/payments">Plan Subscription</Link>
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <span className="navbar-text">
                <h6 className="admin">
                  <i>{firstName}</i>
                  <Logout />
                </h6>
              </span>
            </div>
          </nav>
        </header>

        <div className="all-sides col-lg-12">
          <WifiCallSidebar />

          <div className="page-wrapper col-lg-10">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-3">
                  <form className="form-inline inner-addon left-addon d-flex">
                    <input
                      className="form-control mr-sm-2 empty col-lg-6 mt-1"
                      id="iconified"
                      type="text"
                      placeholder="Search by first or last name, email, phone"
                    />
                    <span className="fa fa-search" />
                  </form>
                </div>

                <div className="card x-panel">
                  <div className="table-responsive content">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Transaction ID</th>
                          <th>Account ID</th>
                          <th>Refill Date</th>
                          <th>Amount Paid</th>
                          <th>Status</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>WFC28199</td>
                          <td>BASU29109</td>
                          <td>Sept 8th, 2018 10:32:30 </td>
                          <td>N 30,000 </td>
                          <td>
                            <span className="badge badge-success">Successful</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* <AdminPaginate/> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
