import React from 'react';
import PaystackTransactionsTable from './PaystackTransactionTable';
import { usePaystack } from '../../../../hooks/customer-support/paystack-transactions/usePaystack';

const PaystackTransactionsBody = () => {
  const { payload, handlePayload, getTransactions, lists, isLoading, reloadGetTransactions } = usePaystack();
  return (
    <div className="col-12 p-0 after-summary">
      <div className="row mx-2">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            getTransactions(payload);
          }}
          className="form-inline ml-3"
        >
          <div className="form-group mx-2">
            <label className="mt-2 p-0 copy-font">From: </label>
            <input
              type="date"
              className="form-control mr-2"
              name="from"
              value={payload.from}
              onChange={handlePayload}
            />
            <label className="mt-2 p-0 copy-font">To: </label>
            <input type="date" className="form-control" name="to" value={payload.to} onChange={handlePayload} />
          </div>

          <div className="form-group mx-2">
            <button className="btn btn-green" type="submit">
              Search
            </button>
          </div>
        </form>

        <div className="card x-panel p-0">
          <PaystackTransactionsTable
            lists={lists}
            isLoading={isLoading}
            getTransactions={getTransactions}
            reloadGetTransactions={reloadGetTransactions}
          />
        </div>
      </div>
    </div>
  );
};

export default PaystackTransactionsBody;
