import React from 'react';
import { Link } from 'react-router-dom';

const ExpressWifiNavBarItem = ({ url, name, image }) => {
  return (
    <li className="s-sidebar__nav-link">
      <Link to={url} target="_self" className="make-bold">
        <span>
          <img src={image} alt="department bar" />
        </span>
        <small>{name}</small>
      </Link>
    </li>
  );
};

export default ExpressWifiNavBarItem;
