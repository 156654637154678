/* eslint-disable react/require-default-props */
import React, { useRef, useState } from 'react';
import { stringify } from 'qs';
import { CSVLink } from 'react-csv';
import PropTypes from 'prop-types';
import legacy from '../../../../../services/api/axios';
import CustomButton from '../../../shared/forms/CustomButton';

const DownloadActive = ({ country, type }) => {
  const [downloading, setDownloading] = useState(false);
  const [activeData, setActiveData] = useState([]);
  const csvLink = useRef();
  const today = () => {
    return `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-${String(
      new Date().getDate(),
    ).padStart(2, '0')}`;
  };
  const downloadData = async () => {
    setDownloading(true);
    const params = {
      country,
      type,
      date: today(),
      action: 'tizeti_asset_retension_daily_chart_download',
    };
    await legacy.post('/index.php', stringify(params)).then((response) => {
      setActiveData(response.data.Active_users);
      setDownloading(false);
    });

    csvLink.current.link.click();
  };
  return (
    <div className="form-group mx-2">
      <CustomButton
        classname="btn btn-green"
        disabled={downloading}
        onClick={() => downloadData()}
        name={downloading ? `Downloading ${type} Customers...` : `Download ${type} Customers`}
      />
      <CSVLink data={activeData} filename={`${country} ${type}.csv`} className="hidden" ref={csvLink} target="_blank" />
    </div>
  );
};

DownloadActive.propTypes = {
  country: PropTypes.string,
  type: PropTypes.string,
};

export default DownloadActive;
