// eslint-disable-next-line import/prefer-default-export
import * as types from '../../../constants/types/administration/RequestTypes';
import * as load from '../../../constants/types/shared/LoadingTypes';

// eslint-disable-next-line import/prefer-default-export
export const requestsReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.START_CREATE_CATEGORY:
      return {
        ...state,
        isLoadingCategory: true,
      };

    case types.STOP_CREATE_CATEGORY:
      return {
        ...state,
        isLoadingCategory: false,
      };

    case types.START_CREATE_REQUEST:
      return {
        ...state,
        isLoadingRequest: true,
      };

    case types.STOP_CREATE_REQUEST:
      return {
        ...state,
        isLoadingRequest: false,
      };

    case types.GET_REQUESTS:
      return {
        ...state,
        requests: payload,
        isLoading: false,
      };

    case types.STOP_UPDATING_STATUS:
      return {
        ...state,
        // requests: payload,
        isUpdatingStatus: false,
      };

    case types.START_UPDATING_STATUS:
      return {
        ...state,
        // requests: payload,
        isUpdatingStatus: true,
      };

    case types.STATUS_UPDATED:
      return {
        ...state,
        // requests:
        requests: state.requests.filter((req) => req.id !== payload),
        isUpdatingStatus: false,
      };

    case types.CREATE_REQUEST:
      return {
        ...state,
        // requests: payload,
        requests: [payload, ...state.requests],

        isLoadingRequest: false,
      };

    case load.LOADING_STARTS:
      return {
        ...state,
        isLoading: true,
      };

    case load.LOADING_STOPS:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};
