import React, { useContext } from 'react';
import { OverviewContext } from '../../../../../../context/shared/installations/reschedule/OverviewContext';

const RescheduledPercentageCompleted = () => {
  const { search, searchUser, installations, completedInstallations } = useContext(OverviewContext);
  const percent = () => parseFloat(((completedInstallations.length / installations.length) * 100).toFixed(2));
  return search.status === '' && searchUser.option === '' && percent() ? (
    <div className="p-1 mr-3">
      Percentage Completed:
      <span> {percent()}%</span>
    </div>
  ) : null;
};

export default RescheduledPercentageCompleted;
