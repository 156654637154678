import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import AddInventory from '../../departments/super-admin/inventories/AddInventory';
import InventoriesTable from '../../departments/procurement/inventories/InventoriesTable';
import { API_URL } from '../../../../utils/config';

class Inventories extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inventories: [],
      keyword: '',
      filtered: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.fetchInventories();
  }

  searchCustomer = (e) => {
    const keyword = e.target.value;
    const { filtered } = this.state;
    if (keyword !== '') {
      const list = filtered.filter((customer) => {
        if (customer.name.indexOf(keyword) !== -1) {
          return customer;
        }

        if (customer.model.indexOf(keyword) !== -1) {
          return customer;
        }

        if (customer.manufacturer.indexOf(keyword) !== -1) {
          return customer;
        }

        if (customer.vendor.indexOf(keyword) !== -1) {
          return customer;
        }

        if (customer.serialNumber.indexOf(keyword) !== -1) {
          return customer;
        }
      });
      this.setState({
        filtered: list,
        keyword: e.target.value,
      });
    } else {
      const { inventories } = this.state;
      this.setState({
        filtered: inventories,
        keyword,
      });
    }
  };

  fetchInventories() {
    this.setState({
      isLoading: true,
    });

    axios({
      method: 'get',
      url: `${API_URL}/inventory`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      }
    }).then((res) => {
      this.setState({
        inventories: res.data,
        isLoading: false,
        filtered: res.data,
      });
    });
  }

  render() {
    const { isLoading, keyword, filtered } = this.state;
    return (
      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-4 d-flex flex-wrap">
            <div className="col-lg-10 col-md-10 col-sm-12 mt-2">
              <form className="form-inline inner-addon left-addon" action="#">
                <input
                  value={keyword}
                  onChange={(e) => this.searchCustomer(e)}
                  className="form-control mr-sm-2 empty mt-1"
                  id="iconified"
                  type="text"
                  placeholder="Search"
                />
                <span className="fa fa-search" />
              </form>
            </div>

            <div className="col-lg-2 col-md-2 col-sm-12 mt-2">
              <button className="btn btn-green" type="submit" data-toggle="modal" data-target="#add-employee">
                Add Inventory
              </button>
            </div>

            <div
              id="add-employee"
              className="modal fade in"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="myModalLabel"
              aria-hidden="true"
              style={{ overflow: 'auto' }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title text-center" id="myModalLabel">
                      Add Inventory
                    </h4>
                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                      ×
                    </button>
                  </div>
                  <AddInventory />

                  <div className="modal-footer">
                    <Link to="#success2" data-toggle="modal" data-dismiss="modal" />
                  </div>
                </div>
              </div>
            </div>

            <div
              id="success2"
              className="modal fade in"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="myModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body text-center">
                    <span className="fa fa-check-circle" />
                    <p>
                      <b>Inventory Added Successfully!</b>
                    </p>
                    <Link to="#add-employee" data-toggle="modal" data-dismiss="modal">
                      <button
                        type="submit"
                        className="btn btn-green waves-effect"
                        data-toggle="modal"
                        data-target="#add-employee"
                      >
                        Add Another
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card x-panel">
            <div className="table-responsive content col-lg-12 col-md-12 col-sm-12">
              <table className="table col-lg-12 col-md-12 col-sm-12">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Manufacturer</th>
                    <th>Model</th>
                    <th>Vendor</th>
                    <th>Serial No</th>
                    <th>Warranty</th>
                    <th>Firmware</th>
                    <th>Date</th>
                    {/* <th>Employee</th> */}
                    {/* <th>Assign To </th> */}
                    <th>Actions</th>
                  </tr>
                </thead>

                <InventoriesTable inventories={filtered} load={isLoading} />
              </table>
            </div>
          </div>

          {/* <AdminPaginate/> */}

          {/*                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={450}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange}
                        /> */}
        </div>
      </div>
    );
  }
}

export default Inventories;
