/* eslint-disable import/prefer-default-export */
// import axios from 'axios';
//
// export default axios.create({
//
//   baseURL: 'http://api.tizeti.com:5000/api/v1',
//   headers: {
//
//     'Authorization': `Bearer ${sessionStorage.getItem('token')}`
//
//   }
//
// });

import axios from 'axios';

const API = axios.create({
  baseURL: 'https://api.tizeti.com/api/v1',
  headers: {
    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
  },
});

export { API };
