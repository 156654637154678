export const GET_LATEST_BASESTATIONS = 'GET_LATEST_BASESTATIONS';
export const GET_BASESTATION_BY_YEAR = 'GET_BASESTATION_BY_YEAR';
export const ADD_INCIDENT_REPORT = 'ADD_INCIDENT_REPORT';
export const DELETE_BASESTATION = 'DELETE_BASESTATION';
export const GET_INCIDENT_REPORTS = 'GET_INCIDENT_REPORTS';
export const GET_DEDICATED_CUSTOMERS = 'GET_DEDICATED_CUSTOMERS';
export const GET_ACCESS_POINTS = 'GET_ACCESS_POINTS';
export const GET_FIBRE_LINKS = 'GET_FIBRE_LINKS';
export const START_LOADING_DEDICATED_CUSTOMERS = 'START_LOADING_DEDICATED_CUSTOMERS';
export const STOP_LOADING_DEDICATED_CUSTOMERS = 'STOP_LOADING_DEDICATED_CUSTOMERS';
export const GET_ALL_EMPLOYEES_IN_NOC = 'GET_ALL_EMPLOYEES_IN_NOC';
export const UPDATES_CHANGED = 'UPDATES_CHANGED';
export const RESOLUTION_ACTION_CHANGED = 'RESOLUTION_ACTION_CHANGED';
export const RESOLUTION_TIME_CHANGED = 'RESOLUTION_TIME_CHANGED';
export const FETCH_ONE_TICKET = 'FETCH_ONE_TICKET';
export const EDIT_TICKET = 'EDIT_TICKET';
export const ERROR_UPDATING_TICKET = 'ERROR_UPDATING_TICKET';
export const STATUS_FILTER_CHANGE = 'STATUS_FILTER_CHANGE';
export const EMPLOYEE_FILTER_CHANGE = 'EMPLOYEE_FILTER_CHANGE';
export const TICKET_CREATED = 'TICKET_CREATED';
export const FETCH_ALL_TICKETS = 'FETCH_ALL_TICKETS';
export const GET_KPIS = 'GET_KPIS';
export const GET_BASESTATIONS_LIST = 'GET_BASESTATIONS_LIST';
export const UPDATE_STATIC_IP_ADDRESS = 'UPDATE_STATIC_IP_ADDRESS';
export const START_ADDING_STATIC_IP = 'START_ADDING_STATIC_IP';
export const ADDED_STATIC_IP = 'ADDED_STATIC_IP';
export const STOP_ADDING_STATIC_IP = 'STOP_ADDING_STATIC_IP';
export const START_LOADING_BANDWIDTH = 'START_LOADING_BANDWIDTH';
export const STOP_LOADING_BANDWIDTH = 'STOP_LOADING_BANDWIDTH';
export const GET_BANDWIDTH = 'GET_BANDWIDTH';
