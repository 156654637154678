import { GET_ZONES } from '../../../constants/types/shared/zones/ZonesTypes';
import { FETCHING_STARTS } from '../../../constants/types/shared/LoadingTypes';

const initialState = {
  zones: [],
  load: false,
};

export const ZonesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ZONES:
      return {
        ...state,
        zones: payload,
        load: false,
      };

    case FETCHING_STARTS:
      return {
        ...state,
        load: payload,
      };

    default:
      return state;
  }
};

export default ZonesReducer;
