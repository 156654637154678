/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import helpers from '../../../../../utils/helpers';
import { NoItemRow, TizetiGIFLogoTable } from '../../../../../utils/loader/loadingHelper';
import feedback_helpers from '../../../../../utils/feedback/feedback_helpers';

const BusinessDevelopmentLogsTable = ({ lists, isLoading }) => {
  const colSpan = '10';
  const tableData =
    lists && lists.length ? (
      lists.map((list, i) => {
        const {
        //   installation: {
        //     user: { firstName, lastName, email },
        //   },
          firstName,
          lastName,
          email,
          phoneNumber,
          address,
          status,
          city,
          created_at,
        } = list;

        return (
          <tr key={i}>
            <td>{i + 1}</td>
            <td>{`${firstName} ${lastName}`}</td>
            <td>{email}</td>
            <td>{phoneNumber}</td>
            <td>{address}</td>
            <td> <span className="badge badge-success" style={{ backgroundColor: 'red' }}>
              {status}
            </span></td>
           {city ? <td>{city } </td> : <td>'N/A'</td>}
            <td className="text-nowrap">{helpers.formatDateAndTime(created_at)}</td>
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan={colSpan} text="There are no requests" />
    );

  return (
    <div className="table-responsive content col-12">
      <table className="table">
        <thead>
          <tr className="text-nowrap">
            <th>#</th>

            <th>Name</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>Address</th>
            <th>Status</th>
            <th>City</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>{isLoading ? <TizetiGIFLogoTable colSpan={colSpan} /> : tableData}</tbody>
      </table>
    </div>
  );
};

export default BusinessDevelopmentLogsTable;
