/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/button-has-type */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { OverviewContext } from '../../../../../context/shared/installations/overview/OverviewContext';
import helpers from '../../../../../utils/helpers';
import SendFeedback from '../../../departments/field-support/feedback/SendFeedback';
import { showContentForDepartment } from '../../../shared/installations/leads-and-managers/table/utils/helpers';
import UpdateUser from './UpdateUser';
import styles from '../NewDesign.module.css';
import NewDesignKycUpload from '../../kyc-upload/NewDesignKycUpload';
import NewDesignValidateMACID from '../account/NewDesignValidateMACID';
import NewDesignViewKyc from '../../kyc-upload/NewDesignViewKyc';
import EditInstallation from './EditInstallation';

const NewDesignInstallationAction = () => {
  const location = useLocation();
  const { id } = useParams();
  // console.log(location.state.detail, 'line 19');

  let initialDetailState = null;

  if (location.state) {
    initialDetailState = {
      item: location.state.detail || null,
      payment_reference: location.state.detail.payment_reference || null,
      firstName: location.state.detail.firstName || null,
      lastName: location.state.detail.lastName || null,
      email: location.state.detail.email || null,
      phone: location.state.detail.phoneNumber || null,
      lng: location.state.detail.lng || null,
      lat: location.state.detail.lat || null,
      nearestBasestation: location.state.detail.nearestBasestation || null,
      address: location.state.detail.address || null,
      userAddress: location.state.detail.userAddress || null,
      date: location.state.detail.createdDate || null,
      sub_zone: location.state.detail.sub_zone || null,
      zoneName: location.state.detail.zoneName || null,
      installationUploads: location.state.detail.installationUploads || null,
      status: location.state.detail.status || null,
      userId: location.state.detail.userId || null,
    };
    localStorage.setItem(`InstallationAction-${id}`, JSON.stringify(initialDetailState));
  } else {
    initialDetailState = JSON.parse(localStorage.getItem(`InstallationAction-${id}`));
  }

  const [details, setDetails] = useState(initialDetailState);

  const { assignedStaff, assignedStaffCount } = useContext(OverviewContext);

  // console.log(details.userId, 'line 42');
  useEffect(() => {
    console.log(location, 'line 44'); // result: 'some_value'
  }, [location]);

  const getOneInstallation = (i) => {
    setDetails(i);
  };

  const roleId = Number(sessionStorage.getItem('roleId'));
  const departmentId = sessionStorage.getItem('departmentId');

  const showFeedbackButton = (param) => {
    return (
      <div className="col-xs-4 pl-2">
        <button
          className={styles.drop}
          onClick={() => {
            getOneInstallation(param);
          }}
          data-target="#sendFeedback"
          data-toggle="modal"
        >
          Send Feedback{' '}
        </button>
        <SendFeedback account={details} />
      </div>
    );
  };

  const showActivateButton = (param) => {
    return (
      <>
        <div className="col-xs-4 pl-2">
          <button
            className={styles.drop}
            type="button"
            onClick={() => {
              getOneInstallation(param);
            }}
            data-target="#validateMAC"
            data-toggle="modal"
          >
            Activate
          </button>
        </div>
        <NewDesignValidateMACID
          account={details}
          assignedStaffCount={assignedStaffCount}
          assignedStaff={assignedStaff}
        />
      </>
    );
  };

  const showKycUpload = (param) => {
    return (
      <>
        <div className="col-xs-4 mt-1">
          <button
            className={styles.drop}
            type="button"
            onClick={() => {
              getOneInstallation(param);
            }}
            data-target="#uploadKyc"
            data-toggle="modal"
          >
            Upload KYC
          </button>
        </div>
        <NewDesignKycUpload request={details} />
      </>
    );
  };

  const viewKycUpload = (param) => {
    return (
      <>
        <div className="col-xs-4 ">
          <button
            className={`ml-2 ${styles.drop}`}
            type="button"
            onClick={() => {
              getOneInstallation(param);
            }}
            data-target="#viewcs"
            data-toggle="modal"
          >
            View KYC
          </button>
        </div>
        <NewDesignViewKyc request={details} />
      </>
    );
  };
  const showUpdateUserButton = (param) => {
    return (
      <>
        <div>
          <h1
            className="link"
            type="button"
            onClick={() => {
              getOneInstallation(param);
            }}
            data-target="#update-acct"
            data-toggle="modal"
          >
            Update User
          </h1>
          <UpdateUser install={details} dept="FSE" />
        </div>
      </>
    );
  };

  const showEditButton = (param) => {
    return (
      <>
        <div>
          <h1
            className="links"
            type="button"
            onClick={() => {
              getOneInstallation(param);
            }}
            data-target="#edit-acct"
            data-toggle="modal"
          >
            Edit Installation
          </h1>
          <EditInstallation install={details} dept="FSE" />
        </div>
      </>
    );
  };

  return (
    <div className="container">
      <>
        <div className="row no-gutters">
          <div className={`${styles.action_title} col-12 col-sm-6 col-md-8`}>
            <h4>
              {details.firstName} {details.lastName}
            </h4>
            <div style={{ display: 'flex' }}>
              <h6>Sign up from {details.email}</h6>
              <h6 className={`${styles.dates} pl-3`}> {helpers.formatDateAndTimes(details.date)}</h6>
            </div>
          </div>

          {details.status === 'awaiting_account_creation' ? (
            <div className={`${styles.action_button} row`}>
              {showContentForDepartment(departmentId, showActivateButton(details))}
              {showContentForDepartment(departmentId, viewKycUpload(details))}
            </div>
          ) : null}

          {details.status === 'queued' ? (
            <div className={`${styles.action_button} row`}>
              <div className={`${styles.action_button} row`}>
                {showContentForDepartment(departmentId, showKycUpload(details))}
              </div>
            </div>
          ) : null}

          {/* {roleId > 1 && showContentForDepartment(departmentId, showFeedbackButton(location.state.detail))} */}
        </div>
        <hr />
        <div className="row">
          <div className="col-md-6 ">
            <h4> Installation Information</h4>
            <hr />
            <div className={`${styles.action_details} mt-3`}>
              <div className={styles.section}>
                <h6 className="text-nowrap">Longtitude and Latitude</h6>
                <h6 className="text-nowrap">Installation status</h6>
                <h6 className="text-nowrap">Asset Zone</h6>
                <h6 className="text-nowrap">Subzone</h6>
                <h6 className="text-nowrap">Nearest Base Station</h6>

                <h6 className="text-nowrap">Installed address</h6>
                {showContentForDepartment(departmentId, showEditButton(details.item))}
              </div>

              <div className={styles.asides}>
                <h6 className="text-nowrap">
                  {details.lng} / {details.lat}
                </h6>
                <h6 className="text-nowrap">{details.status}</h6>
                <h6 className="text-nowrap">{details.zoneName}</h6>
                <h6 className="text-nowrap">{details.sub_zone}</h6>
                <h6 className="text-nowrap">{details.nearestBasestation}</h6>
                <h6 className="text-wrap"> {details.address}</h6>
              </div>
            </div>
          </div>

          <div className={`col-md-6 col-md-offset-2 ${styles.asidepad}`}>
            <div className="card " style={{ width: '100%' }}>
              <div className="card-body">
                <h4 className="card-title">Transaction timeline</h4>
                <div className={`${styles.action_details} mt-3`}>
                  <div className={styles.section}>
                    <h5 className=" transaction text-nowrap">N/A</h5>
                    <h6 className="text-nowrap">Selected Payment plan</h6>
                  </div>

                  <div className={styles.asidespay}>
                    <h5 className={`${styles.transaction} text-nowrap`}>N/A</h5>
                    <h6 className="text-nowrap">Amount paid</h6>
                  </div>
                </div>
                <hr />

                <div className={`${styles.action_details} mt-3`}>
                  <div className={styles.sectionpay}>
                    <h6 className="text-nowrap">Payment refrence</h6>
                    <h6>{details.payment_reference}</h6>
                  </div>

                  <div className={styles.asidespay}>
                    <h6 className="text-nowrap">Payment stamp</h6>
                    <h6 className="text-nowrap">N/A</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mt-4">
            <h4> Customer Information</h4>
            <hr />
            <div className={`${styles.action_details} mt-3`}>
              <div className={styles.section}>
                <h6 className="text-nowrap">First name</h6>
                <h6 className="text-nowrap">Last name</h6>
                <h6 className="text-nowrap">Email address</h6>
                <h6 className="text-nowrap">Phone</h6>
                <h6 className="text-nowrap">Physical address</h6>

                {showContentForDepartment(departmentId, showUpdateUserButton(details.item))}
              </div>

              <div className={styles.asides}>
                <h6 className="text-nowrap">{details.firstName}</h6>
                <h6 className="text-nowrap">{details.lastName}</h6>
                <h6 className="text-nowrap">{details.email}</h6>
                <h6 className="text-nowrap">{details.phone}</h6>
                <h6 className="text-wrap">{details.userAddress}</h6>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default NewDesignInstallationAction;
