import { GET_DEDICATED_CUSTOMERS } from '../../constants/types/business-development/DedicatedCustomersTypes';
import { LOADING_STARTS, LOADING_STOPS } from '../../constants/types/shared/LoadingTypes';

const initialState = {
  items: [],
  lists: [],
  itemsCache: [],
  totalPages: '',
  allPages: '',
  currentPage: '',
  isLoading: false,
};

const DedicatedCustomersReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOADING_STARTS:
      return {
        ...state,
        isLoading: true,
      };

    case GET_DEDICATED_CUSTOMERS:
      return {
        ...state,
        itemsCache: payload.items,
        items: payload.items,
        lists: payload.lists,
        totalPages: payload.totalPages,
        allPages: payload.allPages,
        currentPage: payload.currentPage,
        isLoading: false,
      };

    case "SEARCH_CALL_CARD_CUSTOMERS":

      let newItems

      if (payload.value === '') {
        newItems = state.itemsCache
      } else {
        const search = payload.value.toLowerCase()
        newItems = state.itemsCache.filter((item) =>
        (
          item.customer_number.toLowerCase().includes(search)
          || item.username.toLowerCase().includes(search)
          || item.name.toLowerCase().includes(search)
          || item.email.toLowerCase().includes(search)
          || item.mobile.toLowerCase().includes(search)
        ))
      }

      return {
        ...state,
        items: newItems
      }

    case LOADING_STOPS:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default DedicatedCustomersReducer;
