/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import ViewUserProfile from '../../../shared/staff/ViewUserProfile';
import profileImage from '../../../../../assets/img/icons/Profile.png';
import PaidLeadsBody from '../../../shared/leads/PaidLeadsBody';
import AssetTeamNavBar from '../../../../layouts/asset-team/AssetTeamNavBar';

const AssetTeamClosedLeads = () => {
  const { profile } = useUser();
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <div className="d-flex col-lg-12 p-2 top-header">
          <AssetTeamNavBar currentPage="Leads" profileImage={profileImage} position={profile.firstName} />
        </div>
        <ViewUserProfile />
        <PaidLeadsBody />
      </main>
    </div>
  );
};

export default AssetTeamClosedLeads;
