/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { NoItemRow } from '../../../../../utils/loader/loadingHelper';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';

const SalesBBNTable = ({ items, isLoading, query }) => {
  //   const { user, getUserProfile, getLoggedInUser } = useUserProfile();
  //   const { department_id } = user;
  //   useEffect(() => {
  //     getUserProfile();
  //     getLoggedInUser();
  //   }, []);
  const colSpan = '8';

  const tableData =
    items && items.length ? (
      items
        .filter((item, _) => {
          if (query === '') {
            return item;
          }
          if (item.country.toLowerCase().includes(query.toLowerCase())) {
            return item;
          }
        })
        .map((item, i) => {
          return (
            <tr className="text-nowrap" key={i}>
              <td>{i + 1}</td>
              <td>
                {item.firstName} {item.lastName}
              </td>
              <td>{item.phoneNumber}</td>
              <td>{item.email}</td>
              <td>{item.address}</td>
              <td>{item.city ? item.city : 'N/A'}</td>
              <td>
                <span className="badge" style={{ backgroundColor: item.status === 'log' ? 'red' : item.status === 'lead' ? 'green' : 'inherit', color: 'white' }}>
                  {item.status}
                </span>

              </td>
              <td>{item.paymentreference ? item.paymentreference : 'N/A'}</td>

              {item.country === 'cv' ? (
                <td>Cote</td>
              ) : null || item.country === 'gh' ? (
                <td>Ghana</td>
              ) : null || item.country === 'ng' ? (
                <td>Nigeria</td>
              ) : null}
            </tr>
          );
        })
    ) : (
      <NoItemRow colSpan={colSpan} text="There are no records" />
    );
  return (
    <div className="card x-panel mt-2 px-0">
      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <thead>
            <tr className="">
              <th>#</th>
              <th>Name</th>
              <th>Phone</th>
              <th className="text-wrap">Email</th>
              <th>Address</th>
              <th>City</th>
              <th>Status</th>
              <th>Payment Ref</th>
              <th>Country</th>

            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td className="text-center">
                  <img src={loader} alt="loading gif" />
                </td>
              </tr>
            ) : (
              tableData
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SalesBBNTable;
