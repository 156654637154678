/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/require-default-props */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { useAuth } from '../../../../../hooks/auth/useAuth';

const Login = () => {
  const { login, onChange, loginUser, isLoading } = useAuth();
  const { email, password } = login;
  useEffect(() => {
    if (sessionStorage.getItem('token')) {
      window.history.back();
    }
  }, []);
  const submitForm = () => {
    return email.length > 0 && password.length > 0;
  };
  return (
    <section id="content2">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          loginUser(login);
        }}
        className="form-horizontal login-form col-sm-12 col-lg-12 col-md-12"
      >
        <div className="form-group col-lg-9 col-sm-12">
          <div>
            <label className="label2 copy-font">Switch from English to your preferred language (optional)</label>
            <div className="" id="google_translate_element"></div>
          </div>

          <div>
            <label className="label2 copy-font">Email Address</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter your Tizeti email address"
              onChange={onChange}
              value={email}
              name="email"
              required
            />
          </div>
          <br />

          <div>
            <label className="label2 copy-font">Password</label>
            <input
              type="password"
              className="form-control"
              placeholder="Enter password"
              onChange={onChange}
              value={password}
              name="password"
            />
          </div>
          <div className="d-flex remember col-sm-12 col-lg-12" style={{ width: '100%' }}>
            {/* <p className="col-sm-6 col-lg-6 copy-font" style={{ marginTop: '0%' }}>
              <Link to="/resetDepartment">Can't login? Reset Department here</Link>
            </p> */}
            {/* <div className="col-sm-6 col-lg-6">
              <input type="radio" style={{ verticalAlign: 'text-bottom' }} /> &nbsp;
              <span className="copy-font">Remember me</span>
            </div> */}
            <p className="col-sm-6 col-lg-6 copy-font" style={{ marginTop: '0%' }}>
              <Link to="/forgot-password">Forgot Password?</Link>
            </p>
          </div>
          <ToastContainer />
        </div>
        <br />
        <br />
        <div className="wrapper-button mb-3">
          {isLoading ? (
            <button
              className="btn btn-green copy-font"
              id="login"
              style={{ backgroundColor: '#036D61' }}
              disabled={isLoading}
            >
              Submitting...
            </button>
          ) : (
            <button
              className="btn btn-green copy-font"
              id="login"
              style={{ backgroundColor: '#036D61' }}
              disabled={!submitForm()}
            >
              LOGIN
            </button>
          )}
        </div>

        <div className="dont-have">
          <p className="text-center copy-font">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Don't have an account? <Link to="#">Sign Up</Link>
          </p>
        </div>
      </form>
      <ToastContainer />
    </section>
  );
};

export default withRouter(Login);
