/* eslint-disable no-underscore-dangle */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const ProfilesTableBody = ({ profiles }) => {
  return (
    <tbody>
      {profiles.map((profile, i) => (
        <tr key={profile._id}>
          <td>{i + 1}</td>
          <td>{profile.baicells}</td>
          <td>
            <small>
              SECURITY-K::<b>{profile.security.k}</b>
            </small>
            <hr />

            <small>
              SECURITY-AMF::<b>{profile.security.k}</b>
            </small>
            <hr />

            <small>
              SECURITY-OP::<b>{profile.security.k}</b>
            </small>
            <hr />

            <small>
              SECURITY-OPC::<b>{profile.security.k}</b>
            </small>
            <hr />
          </td>
          <td>
            <small>
              AMBR-Downlink::<b>{profile.ambr.downlink}</b>
            </small>
            <hr />

            <small>
              SECURITY-Uplink::<b>{profile.ambr.uplink}</b>
            </small>
            <hr />
          </td>
          <td>
            {profile.pdn
              ? profile.pdn.map((pd) => (
                  <Fragment>
                    <small>pre_emption_capability::{pd.qos.pre_emption_capability}</small>
                    <br />
                    <hr />
                    <small>pre_emption_vulnerability::{pd.qos.vulnerability}</small>
                    <small>pre_emption_priority_level::{pd.qos.priority_level}</small>
                  </Fragment>
                ))
              : null}
          </td>
          <td>{profile.qci}</td>
          <td>{profile.type}</td>
          <td>{profile.pcc_rule}</td>
          <td>{profile.apn}</td>
          {/* <td>{profile.apn}</td> */}

          {/* <td> */}
          {/*  /!* eslint-disable-next-line react/button-has-type *!/ */}
          {/*  <button className="text-left"> */}
          {/*    <img */}
          {/*      src="https://res.cloudinary.com/teewhy/image/upload/v1545642324/checked.png" */}
          {/*      alt="" */}
          {/*      className="w-25" */}
          {/*    /> */}
          {/*  </button> */}
          {/* </td> */}
        </tr>
      ))}
    </tbody>
  );
};

ProfilesTableBody.propTypes = {
  // eslint-disable-next-line react/require-default-props
  profiles: PropTypes.shape(),
};
export default ProfilesTableBody;
