/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable import/prefer-default-export */
import { useReducer } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import * as load from '../../../constants/types/shared/LoadingTypes';
import * as url from '../../../services/api/procurement/account-reconciliation/AccountReconciliationURL';
import { GET_INSTALLATIONS } from '../../../constants/types/procurement/ProcurementTypes';
import { accountReconciliationReducer } from '../../../reducers/procurement/account-reconciliation/accountReconciliationReducer';

export const useAccountReconciliation = () => {
  const [{ lists, count, pages, isLoading }, dispatch] = useReducer(accountReconciliationReducer, {
    lists: [],
    count: 1,
    pages: 1,
    isLoading: false,
  });

  const getInstallationReport = async (page) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.get(`${url.INSTALLATION_REPORT_URL}?page=${page}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: GET_INSTALLATIONS,
          payload: {
            list: response.data.list,
            count: response.data.count,
            pages: response.data.pages,
          },
        });
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const searchUsingKeyword = async (data) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(`${url.INSTALLATION_REPORT_URL}/search`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: GET_INSTALLATIONS,
          payload: {
            list: response.data.list,
            count: response.data.count,
            pages: response.data.pages,
          },
        });
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  return {
    lists,
    pages,
    count,
    isLoading,
    getInstallationReport,
    searchUsingKeyword,
  };
};
