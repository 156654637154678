import {
  ADD_INCIDENT_REPORT,
  GET_INCIDENT_REPORTS,
  GET_INCIDENT_REPORTS_BY_DATE,
} from '../../../constants/types/noc/NocTypes';
import { FETCHING_STARTS, FETCHING_STOPS } from '../../../constants/types/shared/LoadingTypes';

const initialState = {
  incident: [],
  incidents: [],
  load: false,
};

export const IncidentReportsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_INCIDENT_REPORT:
      return {
        ...state,
        incident: action.payload,
      };

    case FETCHING_STARTS:
      return {
        ...state,
        load: payload,
      };

    case FETCHING_STOPS:
      return {
        ...state,
        load: payload,
      };

    case GET_INCIDENT_REPORTS:
      return {
        ...state,
        incidents: payload,
        load: false,
      };

    default:
      return state;
  }
};

export default IncidentReportsReducer;
