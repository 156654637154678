import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { getDeviceData } from '../../../../../actions/device-data-logger/DeviceLogger';
import Skeleton from '@mui/material/Skeleton';
import DeviceLoggerTable from './DeviceLoggerTable';
import { CSVLink } from 'react-csv';

export default function DeviceLoggerBody() {
  const [search, setSearch] = React.useState('');
  const [zoneDataLogger, setZoneDataLogger] = React.useState('Eleganza DataLogger');
  const [zoneDataLoggerOptions, setZoneDataLoggerOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [rowsData, setRowsData] = React.useState([]);
  const handleChange = (event) => {
    setZoneDataLogger(event.target.value);
  };

  React.useEffect(() => {
    setLoading(true);
    getDeviceData()
      .then((data) => {
        if (data) {
          setZoneDataLoggerOptions(data);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  console.log('🚀 ~ DeviceLoggerBody ~ zoneDataLogger:', zoneDataLogger);

  return (
    <Box>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        {/* <TextField
          id="filled-search"
          label="Search field"
          // type="search"
          variant="filled"
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        /> */}
        <CSVLink
          data={rowsData}
          filename={`Device monitor data ${new Date().toLocaleDateString()}.csv`}
          className="hidden"
          style={{ color: 'white', backgroundColor: 'green', padding: '10px', borderRadius: '5px' }}
          target="_blank"
        >
          Download CSV
        </CSVLink>
        {loading ? (
          <Skeleton variant="rectangular" width={360} height={64} />
        ) : (
          <Box sx={{ minWidth: 360 }}>
            <FormControl fullWidth>
              <InputLabel id="zone-logger">Zones</InputLabel>
              <Select
                labelId="zone-logger"
                id="zone-logger-select"
                value={zoneDataLogger}
                label="ZoneDataLogger"
                onChange={handleChange}
              >
                {zoneDataLoggerOptions &&
                  zoneDataLoggerOptions.map((option, i) => (
                    <MenuItem value={option.name} key={i}>
                      {option.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        )}
      </Box>
      <Box sx={{ mt: 5 }}>
        <DeviceLoggerTable zoneDataLogger={zoneDataLogger} rowsData={rowsData} setRowsData={setRowsData} />{' '}
      </Box>
    </Box>
  );
}
