import { useReducer, useEffect, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import toastr from 'toastr';
import {
  GET_EXPIRED_CUSTOMERS_MONTHLY_URL,
  SEARCH_FOR_EXPIRED_CUSTOMERS_BY_DAYS_URL,
} from '../../../services/api/customer-support/customers/CustomersUrl';
import { GET_EXPIRED_CUSTOMERS } from '../../../constants/types/customer-support/CustomerSupportTypes';
import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';
import { expiredReducer } from '../../../reducers/shared/customers/expiredReducer';

export const useExpiredCustomers = () => {
  const [state, dispatch] = useReducer(expiredReducer, {
    nigeriaExpired: 0,
    ghanaExpired: 0,
    LTEExpired: 0,
    nigeriaExpired90: 0,
    ghanaExpired90: 0,
    totalExpired90: 0,
    totalExpired: 0,
    isLoading: false,
  });

  const [monthlyExpired, setMonthlyExpired] = useState(0);

  const getExpiredCustomersMonthly = async () => {
    const data = {
      days: '30',
    };
    try {
      const response = await axios.post(`${GET_EXPIRED_CUSTOMERS_MONTHLY_URL}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        setMonthlyExpired(response.data.length);
      }
    } catch (e) {
      if (e) {
        toastr.info(`Unable to get Expired Customers`);
      }
    }
  };

  const getExpiredCustomersCount = async () => {
    dispatch({ type: LOADING_STARTS });
    const data = {
      action: 'tizeti_expiration_count_blocked',
    };
    try {
      const response = await axios.post(`${SEARCH_FOR_EXPIRED_CUSTOMERS_BY_DAYS_URL}`, qs.stringify(data));
      // console.log(response.data);
      if (response.data) {
        dispatch({
          type: GET_EXPIRED_CUSTOMERS,
          payload: {
            nigeriaExpired90: response.data.total_expired_Nigeria_90_days,
            ghanaExpired90: response.data.total_expired_Ghana_90_days,
            totalExpired90: response.data.total_expired_sum_90_days,
            nigeriaExpired: response.data.expired_nigerian_customers,
            ghanaExpired: response.data.expired_ghana_customers,
            LTEExpired: response.data.expired_lte_customers,
            totalExpired: response.data.total_expired_accounts,
          },
        });
      }
    } catch (e) {
      toastr.info(`Unable to get Expired Customers`);
      dispatch({ type: LOADING_STOPS });
    }
  };


  useEffect(() => {
    getExpiredCustomersMonthly();
    getExpiredCustomersCount();
  }, [])

  useEffect(() => { 
    const id = setInterval(() => {
      getExpiredCustomersCount();
    }, 300000);

    return () => clearInterval(id);

  }, []);

  return { monthlyExpired, state };
};
export default useExpiredCustomers;
