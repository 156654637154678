import {
  FETCHING_STARTS,
  FETCHING_STOPS,
  LOADING_STARTS,
  LOADING_STOPS,
  REFETCH,
} from '../../../constants/types/shared/LoadingTypes';
import * as types from '../../../constants/types/operations/installations/InstallationsTypes';

const installationZoneReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOADING_STARTS:
      return {
        ...state,
        loadingForm: true,
      };
    case LOADING_STOPS:
      return {
        ...state,
        loadingForm: false,
      };
    case FETCHING_STARTS:
      return {
        ...state,
        loading: true,
      };
    case FETCHING_STOPS:
      return {
        ...state,
        loading: false,
      };
    case types.GET_ZONES: {
      return {
        ...state,
        loading: false,
        zones: payload,
      };
    }
    case types.GET_SUB_ZONES: {
      return {
        ...state,
        loading: false,
        subZones: payload,
      };
    }
    case REFETCH: {
      return {
        ...state,
        loadingForm: false,
        zones: [payload, ...state.zones],
        subZones: [payload, ...state.subZones],
      };
    }
    case types.DELETE_ZONE:
      return { ...state, zones: state.zones.filter((zone) => zone.id !== payload) };
    default:
      return state;
  }
};
export default installationZoneReducer;
