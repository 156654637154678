/* eslint-disable camelcase */
import React from 'react';
import ManagerCount from '../../../requisition/managers/ManagerCount';
import ExecutiveCount from '../../../requisition/executives/v2/ExecutiveCount';

const RequisitionNotification = () => {
  const checkRequisitionCount = () => {
    if (Number(`${sessionStorage.getItem('roleId')}`) > 2) {
      return <ManagerCount />;
    }
    if (Number(`${sessionStorage.getItem('roleId')}`) > 3 && Number(`${sessionStorage.getItem('roleId')}`) !== 6) {
      return <ExecutiveCount />;
    }
    return null;
  };

  return <div className="ml-1">{checkRequisitionCount()}</div>;
};

export default RequisitionNotification;
