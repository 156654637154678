import React, { Component } from 'react';
import moment from 'moment';
import { DEPARTMENT_LIST } from '../../../../../utils/departments/helpers';

const OperationVehicle = ({
  request,
  request: {
    engine_no,
    chasis_no,
    plate_number,
    registration_status,
    registration_number,
    department_id,
    transmission_type,
    EmployeeAuth,
    handler,
    employee,
    cost,
    document1,
    document2,
    document3,
    document4,
    document5,
    document6,
    document7,
  },
}) => {
  const deptName = DEPARTMENT_LIST.filter((dept) => dept.department_id.includes(department_id));

  return (
    <div
      id="viewVehicles"
      className="modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl p-5">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Vehicle Details
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body modal-dialog-scrollable detail" style={{ padding: '0' }}>
            <div className="row">
              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label2">
                  <label>Engine number</label>
                  <label style={{ display: 'block' }}>Chasis number</label>
                  <label>Plate number</label>
                  <label>Transmission Type</label>
                </div>

                <div className="col-lg-8 detail-result2">
                  <p className="mt-4 mb-1">{engine_no}</p>
                  <p className="mb-1">{chasis_no}</p>
                  <p className="mb-1">{plate_number}</p>
                  <p className="mb-1">{transmission_type}</p>
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label3">
                  <label id="dep"> Status</label>
                  <label id="reg"> Reg Number</label>
                  <label id="role">Cost</label>
                  <label id="driver">Driver</label>
                </div>

                <div className="col-lg-8 detail-result3">
                  <p className="mt-3 mb-1">{registration_status == 1 ? 'Registered' : 'Not Registered'}</p>
                  <p className=" mb-1">{registration_status === 1 ? registration_number : 'N/A'}</p>
                  <p className="mb-1">₦{cost}</p>
                  <p className="mb-1">{handler}</p>
                </div>
              </div>

              <div className="col-lg-12 d-flex">
                <div className="col-lg-4 detail-label4">
                  <label>Employee name</label>
                  <label>Department</label>
                </div>

                <div className="col-lg-8 detail-result4 pt-3">
                  {EmployeeAuth.employees ? (
                    <p className="mb-1">
                      {EmployeeAuth.employees[0].firstName} {EmployeeAuth.employees[0].lastName}
                    </p>
                  ) : (
                    <p className="mb-1">N/A</p>
                  )}
                  <p className="mb-1">{deptName[0].name}</p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="col-lg-12 bg-warning">
                  <label>Insurance Document</label>
                </div>
                <div className="col-lg-12">
                  {document1 != null ? (
                    <>
                      {document1.split('.').pop() === 'pdf' ? (
                        <a href={document1}>
                          <i className="fa fa-download" aria-hidden="true">
                            {' '}
                            {''} Download PDF
                          </i>
                        </a>
                      ) : (
                        <img src={document1} alt="insurance" className="img-fluid mb-3" />
                      )}
                    </>
                  ) : null}
                  {document2 != null ? (
                    <>
                      {document2.split('.').pop() === 'pdf' ? (
                        <a href={document2}>
                          <i className="fa fa-download" aria-hidden="true">
                            {' '}
                            {''} Download PDF
                          </i>
                        </a>
                      ) : (
                        <img src={document2} alt="insurance" className="img-fluid mb-3" />
                      )}
                    </>
                  ) : null}
                  {document3 != null ? (
                    <>
                      {document3.split('.').pop() === 'pdf' ? (
                        <a href={document3}>
                          <i className="fa fa-download" aria-hidden="true">
                            {' '}
                            {''} Download PDF
                          </i>
                        </a>
                      ) : (
                        <img src={document3} alt="insurance" className="img-fluid mb-3" />
                      )}
                    </>
                  ) : null}
                  {document4 != null ? (
                    <>
                      {document4.split('.').pop() === 'pdf' ? (
                        <a href={document4}>
                          <i className="fa fa-download" aria-hidden="true">
                            {' '}
                            {''} Download PDF
                          </i>
                        </a>
                      ) : (
                        <img src={document4} alt="insurance" className="img-fluid mb-3" />
                      )}
                    </>
                  ) : null}
                  {document5 != null ? (
                    <>
                      {document5.split('.').pop() === 'pdf' ? (
                        <a href={document5}>
                          <i className="fa fa-download" aria-hidden="true">
                            {' '}
                            {''} Download PDF
                          </i>
                        </a>
                      ) : (
                        <img src={document5} alt="insurance" className="img-fluid mb-3" />
                      )}
                    </>
                  ) : null}
                  {document6 != null ? (
                    <>
                      {document6.split('.').pop() === 'pdf' ? (
                        <a href={document6}>
                          <i className="fa fa-download" aria-hidden="true">
                            {' '}
                            {''} Download PDF
                          </i>
                        </a>
                      ) : (
                        <img src={document6} alt="insurance" className="img-fluid mb-3" />
                      )}
                    </>
                  ) : null}
                  {document7 != null ? (
                    <>
                      {document7.split('.').pop() === 'pdf' ? (
                        <a href={document7}>
                          <i className="fa fa-download" aria-hidden="true">
                            {' '}
                            {''} Download PDF
                          </i>
                        </a>
                      ) : (
                        <img src={document7} alt="insurance" className="img-fluid mb-3" />
                      )}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*modal-content */}
      </div>
      {/*modal-dialog*/}
    </div>
  );
};

export default OperationVehicle;
