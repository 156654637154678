/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import CustomerSupportNavBar from '../../../layouts/customer-support/CustomerSupportNavBar';
import { useUser } from '../../../../hooks/shared/user/useUser';
import CustomerDetailsSharedBody from './CustomerDetailsSharedBody';
import AssetTeamNavBar from '../../../layouts/asset-team/AssetTeamNavBar';
import { ASSET_TEAM, CUSTOMER_SUPPORT } from '../../../../utils/departments/helpers';

const CustomerDetailsShared = () => {
  const departmentId = sessionStorage.getItem('departmentId');
  const { profile, user } = useUser();
  const { firstName } = profile;
  return (
    <div className="s-layout">
      {(departmentId === `${CUSTOMER_SUPPORT}` && (
        <div className="s-layout">
          <main className="s-layout__content col-lg-10 p-0">
            <CustomerSupportNavBar currentPage="Customer Details" roleID={user.role_id} position={firstName} />
            <CustomerDetailsSharedBody />
          </main>
        </div>
      )) ||
        (departmentId === `${ASSET_TEAM}` && (
          <div className="s-layout">
            <main className="s-layout__content col-lg-10 p-0">
              <AssetTeamNavBar currentPage="Customer Details" roleID={user.role_id} position={firstName} />
              <CustomerDetailsSharedBody />
            </main>
          </div>
        ))}
    </div>
  );
};

export default CustomerDetailsShared;
