import { GET_DATA, FILTER_DATA } from '../../../constants/types/shared/CustomTypes';
import * as load from '../../../constants/types/shared/LoadingTypes';

// eslint-disable-next-line import/prefer-default-export
export const customReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_DATA:
      return {
        ...state,
        response: payload,
        loading: false,
        data: payload,
      };

    case load.LOADING_STARTS:
      return {
        ...state,
        loading: true,
      };

    case load.LOADING_STOPS:
      return {
        ...state,
        data: [],
        loading: false,
      };

    case FILTER_DATA:
      return {
        ...state,
        filtered: payload,
        loading: false,
      };

    case load.FETCHING_STARTS:
      return {
        ...state,
        fetching: true,
      };

    case load.FETCHING_STOPS:
      return {
        ...state,
        data: [],
        fetching: false,
      };

    default:
      return state;
  }
};
