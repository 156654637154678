/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getLoggedInUser } from '../../../../actions/user/UserActions';
import { createIssue } from '../../../../actions/research-and-development/issue/IssueActions';

class AddIssue extends Component {
  static propTypes = {
    getLoggedInUser: PropTypes.func.isRequired,
    department: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    createIssue: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      subject: '',
      details: '',
      load: false,
    };

    this.createIssue = this.createIssue.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.props.getLoggedInUser();
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  createIssue = (e) => {
    e.preventDefault();

    this.setState({
      load: true,
    });

    const { subject, details } = this.state;
    const { department_id } = this.props.department;

    const issue = {
      subject,
      details,
      department_id,
    };

    // console.log(issue);
    this.props.createIssue(issue);
  };

  submitForm() {
    const { subject, details } = this.state;

    return subject.length > 0 && details.length > 0;
  }

  render() {
    const { subject, details, load } = this.state;
    const isEnabled = this.submitForm();
    // console.log(this.props);

    return (
      <div className="container-fluid col-lg-7">
        <form onSubmit={this.createIssue} className="form-horizontal mt-5">
          <div className="form-group text-left">
            <div className="col-md-12 mb-5 d-flex p-0">
              <div className="col-lg-6">
                <label className="copy-font p-0 m-0">Subject</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Subject"
                  name="subject"
                  value={subject}
                  onChange={this.onChange}
                />
              </div>
            </div>

            <div className="col-md-12 mb-5 d-flex">
              <div>
                <label className="copy-font p-0 mb-0">Details</label>
                <textarea
                  className="form-control"
                  name="details"
                  value={details}
                  onChange={this.onChange}
                  rows="5"
                  cols="50"
                  placeholder="Details of the Issue"
                />
              </div>
            </div>
          </div>

          {load ? (
            <div className="col-12 mt-5">
              <button type="button" className="btn btn-green" disabled>
                <i className="fa fa-spinner fa-spin" style={{ fontSize: '24px' }} />
              </button>
            </div>
          ) : (
            <div className="col-12 mt-5">
              <button className="btn btn-green" type="submit" disabled={!isEnabled}>
                Submit
              </button>
            </div>
          )}
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  department: state.auth.auth,
});

export default connect(mapStateToProps, {
  getLoggedInUser,
  createIssue,
})(AddIssue);
