/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import EditDedicated from './EditDedicated';
import DedicatedDetails from './DedicatedDetails';
import loader from '../../../../assets/img/icons/TizetiLoaderColored.gif';
import view from '../../../../assets/img/icons/view.png';
import { NOC } from '../../../../utils/config';

class DedicatedCustomerTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customerId: {},
      edit: {},
    };
  }

  getOneCustomer(customer) {
    this.setState({
      customerId: customer,
    });
  }

  editCustomer = (customer) => {
    this.setState({
      edit: customer,
    });
  };

  render() {
    if (this.props.load) {
      return (
        <tbody>
          <tr>
            <td>
              <img src={loader} alt="" />
            </td>
          </tr>
        </tbody>
      );
    }

    const { customers } = this.props;
    const { customerId, edit } = this.state;

    const customerList = customers ? (
      customers.map((customer, i) => (
        <tr key={i + 1}>
          <td>{i + 1}</td>
          {customer.name ? <td>{customer.name}</td> : <td>N/A</td>}
          {customer.ap ? <td>{customer.ap}</td> : <td>N/A</td>}
          {/* <td nowrap="no-wrap">192.128.83.70</td> */}
          {customer.device ? <td nowrap="no-wrap">{customer.device}</td> : <td nowrap="no-wrap">N/A</td>}
          {customer.password ? <td>{customer.password}</td> : <td>N/A</td>}
          {/* <td>01/03/2019</td>
                <td>Nanostation M5</td> */}
          <td>
            <div className="d-flex">
              <button
                type="submit"
                data-toggle="modal"
                data-placement="right"
                onClick={() => this.getOneCustomer(customer)}
                data-target="#dedicated-details"
                style={{
                  border: '0',
                  backgroundColor: 'white',
                }}
              >
                <span className="view1">
                  <img src={view} alt="" data-toggle="tooltip" data-placement="right" title="View" />
                </span>
              </button>
              <DedicatedDetails one={customerId} />
              {sessionStorage.getItem('departmentId') !== `${NOC}` ? null : (
                <button
                  type="submit"
                  data-toggle="modal"
                  data-target="#editDedicated"
                  style={{
                    border: '0',
                    backgroundColor: 'white',
                  }}
                >
                  <span className="view" onClick={() => this.editCustomer(customer)}>
                    <img
                      src="https://res.cloudinary.com/teewhy/image/upload/v1543579893/edit.png"
                      alt=""
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Edit"
                    />
                  </span>
                </button>
              )}
              <EditDedicated user={edit} />
            </div>
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td>
          <p>No customers!</p>
        </td>
      </tr>
    );

    return <tbody>{customerList}</tbody>;
  }
}

export default DedicatedCustomerTable;
