/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, memo } from 'react';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import { useActivation } from '../../../../../hooks/shared/installations/useActivation';
import Dimension from '../../../departments/field-support/accounts/Dimension';

const NewDesignActivation = ({ account, validMAC }) => {
  const { profile } = useUser();
  const { estates, basestations, isActivating, activateAccount } = useActivation();
  const [user, setUser] = useState({});
  const { firstname, lastname, email, phone, userid, address } = user;

  const detailsState = {
    alias: '',
    signal: '',
    basestation: '',
    fax: '',
    fee: '',
    subscription: '',
    router: '',
    start: '',
    end: '',
    estate: '',
    username: '',
    password: '',
    routerTag: '',
    radioTag: '',
    dimension1: '',
    dimension2: '',
    callstation: '',
    country: 'ng',
    deviceType: '',
    longitude: '',
    latitude: '',
  };
  const [details, setDetails] = useState(detailsState);
  const {
    alias,
    signal,
    basestation,
    fax,
    fee,
    subscription,
    router,
    start,
    end,
    estate,
    username,
    password,
    routerTag,
    radioTag,
    dimension1,
    dimension2,
    callstation,
    country,
    deviceType,
    longitude,
    latitude,
  } = details;

  const handleUser = (e) => {
    const { value, name } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handleDetails = (e) => {
    const { value, name } = e.target;
    setDetails({ ...details, [name]: value });
  };

  const handleSpaceBar = (e) => {
    if (e.keyCode === 32) e.preventDefault();
  };

  const handleNumber = (e) => {
    const { name, value } = e.target;
    if (isNaN(Number(value))) return;
    setUser({ ...user, [name]: value });
  };

  useEffect(() => {
    const result = {
      address: account.address,
      userid: account.userId,
      email: account.email,
      firstname: account.firstName,
      lastname: account.lastName,
      phone: account.phone,
    };
    setUser({ ...result });
  }, [account]);

  console.log(userid, account.userId);

  const submitAccount = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('alias', alias);
    data.append('signal_strength', signal);
    data.append('base_station_id', basestation);
    data.append('ssid', username);
    data.append('setup', fee);
    data.append('monthly_subscription', subscription);
    data.append('user_id', userid);
    data.append('mac_id', validMAC.trim());
    data.append('router', router);
    data.append('employee_id', profile.id);
    data.append('account_status', 1);
    data.append('support_start_date', start);
    data.append('support_end_date', end);
    data.append('fax', fax);
    data.append('sales_type', estate);
    data.append('username', username);
    data.append('password', password);
    data.append('router_tag', routerTag);
    data.append('radio_tag', radioTag);
    data.append('country', country);
    data.append('dimension1_id', dimension1);
    data.append('dimension2_id', dimension2);
    data.append('callstation', callstation);
    data.append('version', 'v2');
    data.append('device_type', deviceType);
    data.append('longitude', longitude);
    data.append('latitude', latitude);
    activateAccount(data, validMAC.trim(), password);
  };

  const submitForm = () => {
    return (
      alias.length > 0 &&
      signal.length > 0 &&
      basestation.length > 0 &&
      fee.length > 0 &&
      subscription.length > 0 &&
      router.length > 0 &&
      start.length > 0 &&
      end.length > 0 &&
      latitude.length > 0 &&
      longitude.length > 0 &&
      fax.length > 0 &&
      estate.length > 0 &&
      country.length > 0 &&
      username.length > 0 &&
      deviceType.length > 0 &&
      password.length > 0
    );
  };

  const devices = [
    { name: 'CAMBIUM', value: 'cambium' },
    { name: 'UBIQUITI', value: 'ubiquiti' },
    { name: 'TERRAGRAPH', value: 'terragraph' },
    { name: 'LTE', value: 'LTE' },
    { name: 'FIBRE', value: 'fibre' },
    { name: 'Wave Nano', value: 'Wave Nano' },
  ];
  return (
    <div id="create-acct" className="modal" data-backdrop="static">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Account Activation
            </h4>
            <button type="button" className="close custom-close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body">
            <form className="form-horizontal" onSubmit={submitAccount}>
              <div className="form-group">
                <div className="col-12 mb-3 d-flex">
                  <div className="col-6">
                    <label className="copy-font m-0 p-0">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First name"
                      value={firstname}
                      name="firstname"
                      onChange={handleUser}
                    />
                  </div>
                  <div className="col-6">
                    <label className="copy-font m-0 p-0">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last name"
                      value={lastname}
                      name="lastname"
                      onChange={handleUser}
                    />
                  </div>
                </div>

                <div className="col-12 mb-3 d-flex">
                  <div className="col-6">
                    <label className="copy-font m-0 p-0">Email Address</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email Address"
                      value={email}
                      name="email"
                      onChange={handleUser}
                    />
                  </div>

                  <div className="col-6">
                    <label className="copy-font m-0 p-0">Phone Number</label>
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="Phone number"
                      value={phone}
                      name="phone"
                      maxLength="11"
                      minLength="10"
                      onKeyDown={handleSpaceBar}
                      onChange={handleNumber}
                      pattern={country === 'gh' ? '0[2-9]{1}[0-9]{8}' : '0[789]{1}[01]{1}[0-9]{8}'}
                      title={
                        country === 'gh'
                          ? 'Please enter a valid Ghanaian phone number without country code (0....)'
                          : 'Please enter a valid Nigerian phone number without country code (0....)'
                      }
                    />
                  </div>
                </div>

                <div className="col-12 mb-3 d-flex">
                  <div className="col-6">
                    <label className="copy-font m-0 p-0">Address</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Customer Address"
                      value={address}
                      name="address"
                      onChange={handleUser}
                    />
                  </div>
                  <div className="col-6">
                    <label className="copy-font m-0 p-0">Alias</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="account name"
                      value={alias}
                      name="alias"
                      onChange={handleDetails}
                    />
                  </div>
                </div>

                <div className="col-12 mb-3 d-flex">
                  <div className="col-6">
                    <label className="copy-font m-0 p-0">MACID</label>
                    <input type="text" className="form-control" value={validMAC} disabled />
                  </div>

                  <div className="col-6">
                    <label className="copy-font m-0 p-0">Router</label>
                    <input
                      type="text"
                      className="form-control"
                      name="router"
                      value={router}
                      onChange={handleDetails}
                      placeholder="router id"
                    />
                  </div>
                </div>

                <div className="col-12 mb-3 d-flex">
                  <div className="col-6">
                    <label className="copy-font m-0 p-0">Set Up Fee</label>
                    <input
                      type="number"
                      className="form-control"
                      name="fee"
                      value={fee}
                      onChange={handleDetails}
                      placeholder="set up fee"
                    />
                  </div>

                  <div className="col-6">
                    <label className="copy-font m-0 p-0">Fax</label>
                    <select className="form-control" name="fax" onChange={handleDetails}>
                      <option value="">--Select Fax--</option>
                      {estates
                        ? estates.map((estat, i) => {
                            return (
                              <option value={estat.name} key={i}>
                                {estat.name}
                              </option>
                            );
                          })
                        : null}
                    </select>
                  </div>
                </div>

                <div className="col-12 mb-3 d-flex">
                  <div className="col-6">
                    <label className="copy-font m-0 p-0">Monthly Subscribtion</label>
                    <input
                      type="number"
                      className="form-control"
                      name="subscription"
                      value={subscription}
                      onChange={handleDetails}
                      placeholder="subscription fee"
                    />
                  </div>

                  <div className="col-6">
                    <label className="copy-font m-0 p-0">Signal Strength</label>
                    <input
                      type="text"
                      className="form-control"
                      name="signal"
                      value={signal}
                      onChange={handleDetails}
                      placeholder="Signal Strength"
                    />
                  </div>
                </div>

                <div className="col-12 mb-3 d-flex">
                  <div className="col-6">
                    <label className="copy-font m-0 p-0">Select Basestation</label>
                    <select className="form-control" onChange={handleDetails} name="basestation">
                      <option value="">--Select Basestation--</option>
                      {!basestations
                        ? null
                        : basestations.map((station) => (
                            <option key={station.id} value={station.id}>
                              {station.name}
                            </option>
                          ))}
                    </select>
                  </div>

                  <div className="col-6">
                    <label className="copy-font m-0 p-0">Country</label>
                    <select
                      className="form-control"
                      defaultValue={country}
                      onChange={(e) =>
                        setDetails({ ...details, country: e.target.value, dimension1: '', dimension2: '', estate: '' })
                      }
                      name="country"
                    >
                      <option value="">--Select Country--</option>
                      <option value="ng">Nigeria</option>
                      <option value="gh">Ghana</option>
                    </select>
                  </div>
                </div>

                <div className="col-12 mb-3 d-flex">
                  <div className="col-6">
                    <label className="copy-font m-0 p-0">Support Start Date</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Support Start Date"
                      value={start}
                      onChange={handleDetails}
                      name="start"
                    />
                  </div>

                  <div className="col-6">
                    <label className="copy-font m-0 p-0">Support End Date</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Support End Date"
                      value={end}
                      onChange={handleDetails}
                      name="end"
                    />
                  </div>
                </div>

                <div className="col-12 mb-3 d-flex">
                  <div className="col-6">
                    <label className="copy-font m-0 p-0">Router Tag</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Router Tag"
                      name="routerTag"
                      value={routerTag}
                      onChange={handleDetails}
                    />
                  </div>

                  <div className="col-6">
                    <label className="copy-font m-0 p-0">Radio tag</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="radio tag"
                      name="radioTag"
                      value={radioTag}
                      onChange={handleDetails}
                    />
                  </div>
                </div>

                <div className="col-12 mb-3 d-flex">
                  <div className="col-6">
                    <label className="copy-font m-0 p-0">Username</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Username"
                      name="username"
                      value={username}
                      onChange={handleDetails}
                    />
                  </div>

                  <div className="col-6">
                    <label className="copy-font m-0 p-0">Password</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Password"
                      name="password"
                      value={password}
                      onChange={handleDetails}
                    />
                  </div>
                </div>

                <div className="col-12 mb-3 d-flex">
                  <div className="col-6">
                    <label className="copy-font m-0 p-0">Longitude</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Longitude"
                      value={longitude}
                      name="longitude"
                      onChange={handleDetails}
                    />
                  </div>
                  <div className="col-6">
                    <label className="copy-font m-0 p-0">Latitude</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Latitude"
                      value={latitude}
                      name="latitude"
                      onChange={handleDetails}
                    />
                  </div>
                </div>
                <Dimension country={country} details={details} setDetails={setDetails} />

                <div className="col-12 mb-3 d-flex">
                  <div className="col-6">
                    <label className="copy-font m-0 p-0">Device Type</label>
                    <select name="deviceType" className="form-control" onChange={handleDetails}>
                      <option value="">--Select Device Type--</option>
                      {devices.map((device) => (
                        <option value={device.value}>{device.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-12 mb-3 text-center">
                  {isActivating ? (
                    <button className="btn btn-green" type="button" disabled={isActivating}>
                      Activating...
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-green" disabled={!submitForm()}>
                      Activate Account
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(NewDesignActivation);
