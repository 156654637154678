/* eslint-disable import/prefer-default-export */
import * as load from '../../../constants/types/shared/LoadingTypes';
import { GET_USAGE } from '../../../constants/types/partnership/PartnershipTypes';

export const usageHistoryReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case load.LOADING_STARTS:
      return {
        ...state,
        isLoading: true,
      };

    case load.LOADING_STOPS:
      return {
        ...state,
        isLoading: false,
      };

    case GET_USAGE:
      return {
        ...state,
        usage: payload,
        isLoading: false,
      };

    default:
      return state;
  }
};
