import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import { get_installation_count_by_asset_zone } from '../../../../../../actions/coo-statistics/InstallationByAssetZoneActions';
import { get_all_basestation_zones } from '../../../../../../actions/shared/basestations/GetAllBasestationsZones';
import { TableCellMonths } from '../components/tempData';
import AssetZoneTableData from './AssetZoneTableData';
import PieChartDisplay from './PieChartDisplay';
import AvailableYears from './AvailableYears';
import CircularProgress from '@mui/material/CircularProgress';

const today = new Date();
const month = today.getMonth();
const year = today.getUTCFullYear();
// const today = new Date(new Date().getTime() - 86400000); // Subtract one day in milliseconds
const formattedDate = today.toISOString().slice(0, 10);
// console.log(formattedDate);
const DemoPaper = styled(Paper)(({ theme }) => ({
  width: 200,
  height: 200,
  padding: theme.spacing(2),
  // background: 'red',
  // color: 'white',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'space-evenly',
  justifyContent: 'center',
}));

export default function InstallationAssetByZone() {
  const [assetZoneData, setAssetZoneData] = useState([]);
  const [getZones, setGetZones] = useState([]);
  const [getMonth, setGetMonth] = useState(TableCellMonths[month]);
  const [installationCount, setInstallationCount] = useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [installMaxMinData, setInstallMaxMinData] = React.useState({
    max: {
      zone: '',
      value: 0,
    },
    min: {
      zone: '',
      value: null,
    },
  });
  const [selectedYear, setSelectedYear] = React.useState(year);

  const nextMonthFirstDay = new Date(selectedYear, month + 1, 1);
  const lastDay = new Date(nextMonthFirstDay - 1).getDate(); //returns the last day of every month

  const totalNumberDailyInstalls = {
    status: 'completed',
    from: `${selectedYear}-${month + 1}-01`,
    to: `${selectedYear}-${month + 1}-${lastDay}`,
    zone_id: '014cd7ee-a811-4d48-9fd7-52ca25219de2',
    paginate: true,
    addZone: 'yes',
    country: 'ng',
  };

  const handleYearChange = (year) => {
    setSelectedYear(year);
  };
  const emptyBox = [];

  useEffect(() => {
    const findIndexOfMonth = TableCellMonths.findIndex((val) => val === getMonth);

    const fetchInstallationCount = async () => {
      try {
        const val = getZones.map((data) => data.id);
        const totalInstallationPerMonth = await get_installation_count_by_asset_zone({
          ...totalNumberDailyInstalls,
          from: `${selectedYear}-${findIndexOfMonth + 1}-01`,
          to: `${selectedYear}-${findIndexOfMonth + 1}-${lastDay}`,
          zone_id: '',
        });
        setInstallationCount(totalInstallationPerMonth);
        for (let index = 0; index < val.length; index++) {
          setIsLoading(true);

          const data = await get_installation_count_by_asset_zone({
            ...totalNumberDailyInstalls,
            from: `${selectedYear}-${findIndexOfMonth + 1}-01`,
            to: `${selectedYear}-${findIndexOfMonth + 1}-${lastDay}`,
            zone_id: val[index],
          });
          emptyBox.push(data);
          setAssetZoneData(emptyBox);
          const getCountValue = emptyBox.map((getCount) => getCount.count);
          if (getCountValue.length === getZones.length) {
            const maxInstalls = Math.max(...getCountValue);
            const lowestInstalls = Math.min(...getCountValue);
            const findMaxIndex = emptyBox.findIndex((val) => val.count === maxInstalls);
            const findMinIndex = emptyBox.findIndex((val) => val.count === lowestInstalls);

            setInstallMaxMinData({
              max: {
                zone: getZones[findMaxIndex],
                value: maxInstalls,
              },
              min: {
                zone: getZones[findMinIndex],
                value: lowestInstalls,
              },
            });
          }
        }
      } catch (error) {
        console.error('Error fetching installation count:', error);
      } finally {
        setIsLoading(false);
      }
    };
    const fetchZones = async () => {
      setGetZones(await get_all_basestation_zones());
    };

    fetchInstallationCount();
    fetchZones();
  }, [getZones.length, getMonth]);

  return (
    <Box sx={{ ml: '2rem', mt: '2rem', mr: '2rem' }} display="flex" justifyContent="space-between">
      <Box>
        <Typography variant="h4">Installation by asset zone</Typography>
        {/* <Box sx={{ mb: 5 }}>
          <AvailableYears selectedYear={selectedYear} onYearChange={handleYearChange} />
        </Box> */}
        <div>
          <select
            name="select-zone"
            id="select-zone"
            className="block w-full mt-2 mx-2 px-4 py-2 border rounded appearance-none focus:border-blue-500 focus:outline-none "
            style={{ height: '3rem' }}
            value={getMonth}
            onChange={(e) => {
              setGetMonth(e.target.value);
            }}
          >
            {TableCellMonths.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>

        {/* <Button variant="outlined" onClick={handleSubmitQuery}>
          Run Query
        </Button> */}
        <Box display="flex" width="100%" justifyContent="space-evenly" alignItems="center">
          <Box sx={{ mt: 5 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" gap={3} sx={{ mb: 4 }}>
              <DemoPaper square={false} sx={{ background: '#8884d8', color: 'white' }}>
                <Typography sx={{ fontWeight: 'bolder' }}>Total Installation for {`${getMonth}`}</Typography>
                <Divider flexItem />
                <Typography variant="h3" sx={{ color: 'white' }}>
                  {installationCount === null ? <>....</> : installationCount.count}
                </Typography>
              </DemoPaper>
              <DemoPaper square={false} sx={{ background: '#82ca9d', color: 'white' }}>
                <Typography sx={{ fontWeight: 'bolder' }}>Highest Installations</Typography>
                <Divider flexItem />
                <Typography variant="h3" sx={{ color: 'white' }}>
                  {installMaxMinData.max.value === 0 ? '....' : +installMaxMinData.max.value}
                </Typography>
                <Typography sx={{ color: 'white' }}>
                  {installMaxMinData.max.zone === '' ? '....' : installMaxMinData.max.zone.name}
                </Typography>
              </DemoPaper>

              <DemoPaper square={false} sx={{ background: '#d88484', color: 'white' }}>
                <Typography sx={{ fontWeight: 'bolder' }}>Lowest Installations</Typography>
                <Divider flexItem />
                <Typography variant="h3" sx={{ color: 'white' }}>
                  {installMaxMinData.min.value === null ? '....' : +installMaxMinData.min.value}
                </Typography>
                <Typography sx={{ color: 'white' }}>
                  {installMaxMinData.min.zone === '' ? '....' : installMaxMinData.min.zone.name}
                </Typography>
              </DemoPaper>
            </Box>
            <Divider>
              <Chip label="Count" />
            </Divider>

            <PieChartDisplay getZones={getZones} isLoading={isLoading} assetZoneData={assetZoneData} />
          </Box>
        </Box>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box>
        {getZones.length < 1 ? (
          <CircularProgress color="success" />
        ) : (
          <AssetZoneTableData getZones={getZones} isLoading={isLoading} assetZoneData={assetZoneData} />
        )}
      </Box>
    </Box>
  );
}

// const handleSubmitQuery = async (e) => {
//   setIsLoading(true);
//   setZone(zone);
//   const monthIndex = TableCellMonths.findIndex((month) => month === getMonth);
//   const newValue = {
//     ...totalNumberDailyInstalls,
//     from: `${selectedYear}-${monthIndex + 1}-01`,
//     to: `${selectedYear}-${monthIndex + 1}-${lastDay}`,
//     zone_id: zone,
//   };
//   try {
//     const data = await get_installation_count_by_asset_zone(newValue);
//     setAssetZoneData(data);
//     setIsLoading(false);
//     // console.log(assetZoneData);
//   } catch (error) {
//     console.error('Error fetching installation count:', error);
//     setIsLoading(false);
//   }
//   // console.log(newValue);
// };

{
  /* <div>
{zone.length < 1 ? (
  <Skeleton width={170} height={60} sx={{ display: 'inline-block' }} />
) : (
  <select
    name="select-zone"
    id="select-zone"
    className="block w-full mt-2 mx-2 px-4 py-2 border rounded appearance-none focus:border-blue-500 focus:outline-none "
    style={{ height: '3rem' }}
    value={zone}
    onChange={(e) => {
      setZone(e.target.value);
    }}
  >
    {getZones.map((option) => (
      <option key={option.id} value={option.id}>
        {option.name}
      </option>
    ))}
  </select>
)}
</div> */
}
{
  /* <TextField
            type="date"
            id="start-date"
            label="Start Date"
            variant="outlined"
            value={initialState.from}
            focused
            onChange={(e) => {
              e.persist();
              setInitialState((prev) => ({ ...prev, from: e.target.value }));
            }}
          />

          <TextField
            type="date"
            id="End-date"
            label="End Date"
            variant="outlined"
            focused
            value={initialState.to}
            onChange={(e) => {
              e.persist();
              setInitialState((prev) => ({ ...prev, to: e.target.value }));
            }}
          /> */
}

// useEffect(() => {
//   if (assetZoneData.list !== undefined) {
//     setIsLoading(true);
//     const getAllStates = assetZoneData.list.map((state) => state.state);
//     for (let index = 0; index < states.length; index++) {
//       const elements = filterItems(getAllStates, states[index]);
//       initialEmptyArr.push(elements.length);
//       setInstallationCount(initialEmptyArr);
//       setIsLoading(false);
//       console.log(elements);
//       // console.log(`Filtered elements for ${states[index]}:`, elements.length);
//     }
//   }
// }, [assetZoneData, states]);

// function filterItems(arr, query) {
//   console.log(arr);
//   return arr.filter((el) => el.toLowerCase().includes(query.toLowerCase()));
// }

//   return (
//     <Box sx={{ ml: '2rem', mt: '2rem', mr: '2rem' }}>
//       <Typography variant="h4">Installation by asset zone</Typography>

//       <Box
//         component="form"
//         sx={{
//           '& .MuiTextField-root': { m: 1, width: '25ch' },
//         }}
//         noValidate
//         autoComplete="off"
//       >
//         <div>
//           <TextField
//             type="date"
//             id="start-date"
//             label="Start Date"
//             variant="outlined"
//             value={initialState.from}
//             focused
//             onChange={(e) => {
//               e.persist();
//               setInitialState((prev) => ({ ...prev, from: e.target.value }));
//             }}
//           />

//           <TextField
//             type="date"
//             id="End-date"
//             label="End Date"
//             variant="outlined"
//             focused
//             value={initialState.to}
//             onChange={(e) => {
//               e.persist();
//               setInitialState((prev) => ({ ...prev, to: e.target.value }));
//             }}
//           />

//           {getZones.length < 1 ? (
//             <Skeleton width={170} height={60} sx={{ display: 'inline-block' }} />
//           ) : (
//             <select
//               name="select-zone"
//               id="select-zone"
//               className="block w-full mt-2 mx-2 px-4 py-2 border rounded appearance-none focus:border-blue-500 focus:outline-none "
//               style={{ height: '3rem' }}
//               value={zone}
//               onChange={(e) => {
//                 setZone(e.target.value);
//               }}
//             >
//               <option value="">select zone</option>
//               {getZones.map((option) => (
//                 <option key={option.id} value={option.id}>
//                   {option.name}
//                 </option>
//               ))}
//             </select>
//           )}

//           <Button variant="outlined">Run Query</Button>
//         </div>
//       </Box>

//       <Box display="flex" width="100%" justifyContent="space-evenly" alignItems="center">
//         <Box>
//           <DemoPaper square={false}>
//             <Typography>Installation Count </Typography>
//             <Typography variant="h3">{assetZoneData === null ? <>....</> : assetZoneData.count}</Typography>
//           </DemoPaper>
//           <Divider>
//             <Chip label="CHIP" />
//           </Divider>
//           <PieChartDisplay installationCount={installationCount} isLoading={isLoading} />
//         </Box>
//         <Divider orientation="vertical" flexItem>
//           VERTICAL
//         </Divider>
//         <AssetZoneTableData installationCount={installationCount} isLoading={isLoading} />
//       </Box>
//     </Box>
//   );
// }
