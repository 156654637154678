import React, { Component } from 'react';
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Logout from '../../shared/auth/Logout';
import WifiCallSidebar from '../../../layouts/wificall-sidebar/WifiCallSidebar';
import loader from '../../../../assets/img/icons/TizetiLoaderColored.gif';

class UsageHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      from: '',
      to: '',
      history: [],
      loading: false,
    };
  }

  componentDidMount() {}

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  searchWithDate = (e) => {
    e.preventDefault();

    const { from, to } = this.state;

    const data = {
      action: 'wificall_call_stats',
      from,
      to,
    };

    this.setState({
      loading: true,
    });

    console.log(data);

    axios({
      method: 'post',
      url: 'https://legacy.tizeti.com/geniatechapi/index.php',
      data: qs.stringify(data),
    })
      .then((response) => {
        console.log(response);
        this.setState({
          history: response.data,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  submitForm() {
    const { from, to } = this.state;
    return from.length > 0 && to.length > 0;
  }

  render() {
    const isEnabled = this.submitForm();
    const { from, to, history, loading } = this.state;
    const { items } = history;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <Link className="s-sidebar__trigger" to="#">
                <i className="fa fa-bars mr-3" />
              </Link>
              <WifiCallSidebar />

              <h3 className="col-lg-6 overview-part">Users Frequency Upload</h3>

              <div className="d-flex">
                <h6 className="mr-0 mt-1">
                  {/* {firstName} */}
                  <Logout />
                </h6>
              </div>
            </div>
          </div>

          <div className="col-12 after-summary">
            <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-3">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-3">
                  <div className="col-12 mb-3">
                    <form className="form-inline inner-addon left-addon d-flex" onSubmit={this.searchWithDate}>
                      <label className="copy-font p-0 mr-3 mt-2">From </label>
                      <input
                        className="form-control empty w-25"
                        type="date"
                        placeholder="Phone number / Email"
                        name="from"
                        value={from}
                        onChange={this.onChange}
                      />
                      <label className="copy-font p-0 ml-4 mr-3 mt-2">To </label>
                      <input
                        className="form-control empty w-25"
                        type="date"
                        placeholder="Phone number / Email"
                        name="to"
                        value={to}
                        onChange={this.onChange}
                      />
                      <div className="mr-5">
                        {/* <button className="btn btn-primary"> */}
                        {/* {loading ? */}
                        {/*  <button className="btn btn-green ml-5" disabled={true}>Fetching...</button> */}
                        <button className="btn btn-green ml-5" disabled={!isEnabled}>
                          Search
                        </button>
                        {/* } */}
                      </div>
                    </form>
                  </div>

                  <div className="card x-panel">
                    <div className="table-responsive content">
                      <table className="table">
                        <thead>
                          <tr className="copy-font">
                            <th scope="col">#</th>
                            <th scope="col">First Name</th>
                            <th scope="col">Last Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Last Use</th>
                            <th scope="col">Frequency</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            <tr>
                              <td>
                                <img src={loader} alt="loading gif" />
                              </td>
                            </tr>
                          ) : items && items.length ? (
                            items.map((item, index) => (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{item.firstname}</td>
                                <td>{item.lastname}</td>
                                <td>{item.email}</td>
                                <td>{moment(item.lastuse).format('DD/MM/YYYY')}</td>
                                <td>{item.freq}</td>
                              </tr>
                            ))
                          ) : (
                            ''
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default UsageHistory;
