import React from 'react';

const SubscribersTable = () => {
  return (
    <div className="card x-panel logs">
      <div className="table-responsive content">
        <table className="table copy-font">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Phone No.</th>
              <th>Address</th>
              <th>IMSI</th>
              <th>State</th>
              <th>Country</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Expired 1</td>
              <td>Tina Williams</td>
              <td>08112314842</td>
              <td>27, Chemist Road</td>
              <td>123456789123456</td>
              <td>Lagos</td>
              <td>Nigeria</td>
              <td>
                {/* eslint-disable-next-line react/button-has-type */}
                <button className="text-left">
                  <img
                    src="https://res.cloudinary.com/teewhy/image/upload/v1545642324/checked.png"
                    alt=""
                    className="w-25"
                  />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SubscribersTable;
