import React from 'react';
import ManagerAndAboveKpiBody from './ManagerAndAboveKpiBody';
import PersonnelAssignedKpis from './PersonnelAssignedKpis';

const KpiMonitorBody = () => {
  const checkRole = () => {
    if (Number(sessionStorage.getItem('roleId')) === 3 && Number(sessionStorage.getItem('roleId')) !== 6) {
      return <ManagerAndAboveKpiBody />;
    }
    return <PersonnelAssignedKpis />;
  };
  return (
    <div className="page-wrapper col-lg-10">
      <div className="container-fluid">{checkRole()}</div>
    </div>
  );
};

export default KpiMonitorBody;
