/* eslint-disable consistent-return */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { pdfjs } from 'react-pdf';
import PropTypes from 'prop-types';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ViewReceipt = ({ requisit }) => {
  const showFileOne = () => {
    if (requisit.imageUpload) {
      // if (requisit.imageUpload) {
      if (requisit.imageUpload.split('.').pop() === 'document' || requisit.imageUpload.split('.').pop() === 'pdf') {
        // window.open(requisit.imageUpload, '_blank');
        return (
          <iframe
            src={requisit.imageUpload}
            className="copy-font w-100"
            width="120%"
            height="700px"
            frameborder="0"
          ></iframe>
        );
      } else {
        return <img alt="receipt" className="copy-font w-100" src={requisit ? requisit.imageUpload : null} />;
      }
    }
  };
  const showFileTwo = () => {
    if (requisit.image2) {
      // if (requisit.image2) {
      if (requisit.image2.split('.').pop() === 'document' || requisit.image2.split('.').pop() === 'pdf') {
        // window.open(requisit.image2, '_blank');
        return (
          <iframe
            src={requisit.image2}
            className="copy-font w-100"
            width="120%"
            height="700px"
            frameborder="0"
          ></iframe>
        );
      } else {
        return <img alt="receipt" className="copy-font w-100" src={requisit ? requisit.image2 : null} />;
      }
    }
  };
  const showFileThree = () => {
    if (requisit.image3) {
      // if (requisit.image3) {
      if (requisit.image3.split('.').pop() === 'document' || requisit.image3.split('.').pop() === 'pdf') {
        // window.open(requisit.image3, '_blank');
        return (
          <iframe
            src={requisit.image3}
            className="copy-font w-100"
            width="120%"
            height="700px"
            frameborder="0"
          ></iframe>
        );
      } else {
        return <img alt="receipt" className="copy-font w-100" src={requisit ? requisit.image3 : null} />;
      }
    }
  };
  const showFileFour = () => {
    if (requisit.image4) {
      // if (requisit.image4) {
      if (requisit.image4.split('.').pop() === 'document' || requisit.image4.split('.').pop() === 'pdf') {
        // window.open(requisit.image4, '_blank');
        return (
          <iframe
            src={requisit.image4}
            className="copy-font w-100"
            width="120%"
            height="700px"
            frameborder="0"
          ></iframe>
        );
      } else {
        return <img alt="receipt" className="copy-font w-100" src={requisit ? requisit.image4 : null} />;
      }
    }
  };
  const showFileFive = () => {
    if (requisit.image5) {
      // if (requisit.image5) {
      if (requisit.image5.split('.').pop() === 'document' || requisit.image5.split('.').pop() === 'pdf') {
        // window.open(requisit.image5, '_blank');
        return (
          <iframe
            src={requisit.image5}
            className="copy-font w-100"
            width="120%"
            height="700px"
            frameborder="0"
          ></iframe>
        );
      } else {
        return <img alt="receipt" className="copy-font w-100" src={requisit ? requisit.image5 : null} />;
      }
    }
  };
  const showFileSix = () => {
    if (requisit.image6) {
      // if (requisit.image6) {
      if (requisit.image6.split('.').pop() === 'document' || requisit.image6.split('.').pop() === 'pdf') {
        // window.open(requisit.image6, '_blank');
        return (
          <iframe
            src={requisit.image6}
            className="copy-font w-100"
            width="120%"
            height="700px"
            frameborder="0"
          ></iframe>
        );
      } else {
        return <img alt="receipt" className="copy-font w-100" src={requisit ? requisit.image6 : null} />;
      }
    }
  };
  return (
    <div
      id="viewReceipt"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Requisition Receipt
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body">
            <div className="col-12 p-0">
              <div className="p-3">{showFileOne()}</div>
              <div className="p-3">{showFileTwo()}</div>
              <div className="p-3">{showFileThree()}</div>
              <div className="p-3">{showFileFour()}</div>
              <div className="p-3">{showFileFive()}</div>
              <div className="p-3">{showFileSix()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ViewReceipt.propTypes = {
  requisit: PropTypes.shape(),
};

export default ViewReceipt;
