/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/require-default-props */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CustomerSupportSideBar from '../../../../../layouts/customer-support/CustomerSupportSideBar';
import mailblast from '../../../../../../assets/img/icons/mailblast.svg';
import { getUser } from '../../../../../../actions/shared/user/UserActions';
import { getLoggedInUser } from '../../../../../../actions/user/UserActions';
import Logout from '../../../../shared/auth/Logout';
import Requisitions from '../../../../shared/requisition/shared/Requisitions';

class CustomerSupportRequisition extends Component {
  componentDidMount() {
    this.props.getUser();
    this.props.getLoggedInUser();
  }

  render() {
    const { user, role } = this.props;
    const { firstName } = user;
    const { role_id } = role;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <CustomerSupportSideBar />

              <h3 className="col-md-5 overview-part">Requisition</h3>

              <div className="ml-5 d-flex profile-mail">
                {Number(role_id) > 2 ? (
                  <Link
                    to="/customer-support/mail-blast"
                    className="d-none d-md-flex"
                    style={{
                      color: 'gray',
                      textDecoration: 'none',
                    }}
                  >
                    <img src={mailblast} alt="" className="logout mr-2" />
                    <span>
                      <h6 className="m-2">
                        <strong>MailBlast</strong>
                      </h6>
                    </span>
                  </Link>
                ) : null}

                <h6 className="mr-0 d-flex">
                  <i className="small mr-2 d-none d-md-block">{firstName}</i>
                  <Logout />
                </h6>
              </div>
            </div>
          </div>

          <div className="all-sides col-lg-12">
            <CustomerSupportSideBar />
            <Requisitions />
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  role: state.auth.auth,
  user: state.user.user,
});

CustomerSupportRequisition.propTypes = {
  getLoggedInUser: PropTypes.func,
  getUser: PropTypes.func,
  user: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  role: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
export default connect(
  mapStateToProps,
  {
    getUser,
    getLoggedInUser,
  },
)(CustomerSupportRequisition);
