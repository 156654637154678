import React from 'react';

const ApplicationsTableHead = () => {
  return (
    <thead>
      <tr>
        <th>#</th>
        <th>First Name</th>
        <th>Last Name</th>
        {/* <th>Department</th> */}
        {/* <th>Job Description</th> */}
        <th>Title</th>
        <th>Responsibilities</th>
        <th>Application Date</th>
        <th>Download CV</th>
        <th>Actions</th>
      </tr>
    </thead>
  );
};

export default ApplicationsTableHead;
