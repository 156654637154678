import * as types from '../../../constants/types/wificall/WificallTypes';
import * as load from '../../../constants/types/shared/LoadingTypes';

const initialState = {
  customersWithEmail: [],
  customerswithDate: [],
  status: '',
  activated: {},
  userscount: '',
  users: [],
  load: true,
  unverified: [],
  loadUnverified: true,
  customers: [],
  verified: [],

  // load: false,
  loading: false,
  pages: 0,
  wificallUsers: [],
};

export const WificallCustomersReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case load.FETCHING_STARTS:
      return {
        ...state,
        load: true,
      };

    case types.GET_WIFICALL_USERS:
      return {
        ...state,
        wificallUsers: payload.data,
        verified: payload.customers,
        load: false,
        pages: payload.pages,
      };

    case types.SEARCH_WIFICALL_CUSTOMERS_WITH_PARAMS:
      return {
        ...state,
        customersWithEmail: payload,
      };

    case types.SEARCH_WIFICALL_CUSTOMERS_WITH_DATE:
      return {
        ...state,
        customerswithDate: payload,
      };

    case types.ACTIVATE_WIFICALL_USER:
      return {
        ...state,
        activated: action.payload.activated,
        status: action.payload.status,
      };

    case types.GET_WIFICALL_USERS_COUNT:
      return {
        ...state,
        userscount: action.payload,
        load: false,
      };

    case types.GET_VERIFIED_WIFICALL_USERS:
      return {
        ...state,
        load: false,
        customers: payload,
        loading: false,
      };

    case load.LOADING_STARTS:
      return {
        ...state,
        loading: payload,
      };

    case types.GET_UNVERIFIED_WIFICALL_USERS:
      return {
        ...state,
        unverified: payload,
        load: false,
        loading: false,
      };

    default:
      return state;
  }
};

export default WificallCustomersReducer;
