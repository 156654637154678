/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { useUser } from '../../../../../../../hooks/shared/user/useUser';
import CustomerSupportNavBar from '../../../../../../layouts/customer-support/CustomerSupportNavBar';
import ExpiredCustomerGraph from '../../../../../shared/customers/expired-customers/total/ExpiredCustomerGraph';

const CustomerSupportAssetTeamExpiredCustomers = () => {
  const { profile } = useUser();
  const { firstName } = profile;

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <CustomerSupportNavBar currentPage="Total Expired Customers" position={firstName} />
        <div className="mt-5">
          <ExpiredCustomerGraph />
        </div>
      </main>
    </div>
  );
};

export default CustomerSupportAssetTeamExpiredCustomers;
