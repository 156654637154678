/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useContext } from 'react';
import CustomButton from '../../../../shared/forms/CustomButton';
import CustomInput from '../../../../shared/forms/CustomInput';
import BasestationConnectionContext from './context/BasestationConnectionContext';
import Label from '../../../../shared/forms/Label';

const AddBasestationConnection = () => {
  const { loadingForm, onChange, formValue, onSubmit } = useContext(BasestationConnectionContext);
  const {
    site_name,
    stations,
    host,
    public_ip,
    number_of_public_ip,
    ssid,
    product_name,
    network,
    frequency,
    active,
    ip_address,
  } = formValue;
  const submitForm = () => {
    return (
      site_name.length > 0 &&
      stations.length > 0 &&
      host.length > 0 &&
      public_ip.length > 0 &&
      number_of_public_ip.length > 0 &&
      ssid.length > 0 &&
      product_name.length > 0 &&
      network.length > 0 &&
      frequency.length > 0 &&
      active.length > 0 &&
      ip_address.length > 0
    );
  };
  return (
    <div id="add-access-point" className="modal fade mt-5" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header shadow">
            <h4 className="modal-title update_asset">Basestation Connection</h4>
            <button type="button" className="btn btn-outline-info btn-circle btn-close" data-dismiss="modal">
              x
            </button>
          </div>
          <div className="modal-body detail mb-4 p-0">
            <form
              className="col-md-12 p-0"
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              <div className="d-flex">
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="Site Name" htmlfor="customerName" />
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="site_name"
                    value={site_name}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="Enter name of the site"
                    type="text"
                    id="customerName"
                  />
                </div>
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="Stations" htmlfor="customerEmail" />
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="stations"
                    value={stations}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="200"
                    type="text"
                    id="customerEmail"
                  />
                </div>
              </div>
              <div className="d-flex">
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="Host" htmlfor="router_id" />
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="host"
                    value={host}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="Enter Host"
                    type="text"
                    id="router_id"
                  />
                </div>
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="Public IP" htmlfor="radioMACID" />

                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="public_ip"
                    value={public_ip}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="Public IP"
                    type="text"
                    id="radioMACID"
                  />
                </div>
              </div>
              <div className="d-flex">
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="Number of Public IP" htmlfor="router_id" />
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="number_of_public_ip"
                    value={number_of_public_ip}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="e.g (2)"
                    type="text"
                    id="router_id"
                  />
                </div>
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="SSID" htmlfor="radioMACID" />

                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="ssid"
                    value={ssid}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="Enter SSID"
                    type="text"
                    id="radioMACID"
                  />
                </div>
              </div>{' '}
              <div className="d-flex">
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="Product Name" htmlfor="router_id" />
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="product_name"
                    value={product_name}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="E.g (Microtik)"
                    type="text"
                    id="router_id"
                  />
                </div>
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="Network" htmlfor="radioMACID" />
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="network"
                    value={network}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="eNTER Network"
                    type="text"
                    id="radioMACID"
                  />
                </div>
              </div>{' '}
              <div className="d-flex">
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="Frequency" htmlfor="router_id" />
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="frequency"
                    value={frequency}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="Enter Frequency"
                    type="text"
                    id="router_id"
                  />
                </div>
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="Active" htmlfor="radioMACID" />
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="active"
                    value={active}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="Enter Active"
                    type="text"
                    id="radioMACID"
                  />
                </div>
              </div>
              <div className="d-flex">
                <div className="form-group col-6">
                  <Label classname="m-0 p-0 copy-font" name="IP Address" htmlfor="router_id" />
                  <CustomInput
                    classname="form-control border border-info p-2"
                    name="ip_address"
                    value={ip_address}
                    onChange={onChange}
                    readOnly={false}
                    placeholder="E.g (10.2.2)"
                    type="text"
                    id="router_id"
                  />
                </div>
              </div>
              <div className="text-center mt-3">
                {loadingForm ? (
                  <CustomButton classname="btn btn-green px-3" name="Adding" disabled={loadingForm} />
                ) : (
                  <CustomButton classname="btn btn-green px-3" name="Add" disabled={!submitForm()} />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBasestationConnection;
