/* eslint-disable react/prop-types */
import React from 'react';
import { TizetiGIFLogoTable } from '../../../../../utils/loader/loadingHelper';
import CustomCsvDownload from '../../CustomCsvDownload';
import { downloadedFileName } from '../../utils/shared-utils';
import InstalledUsersTable from './InstalledUsersTable';

const InstalledUsers = ({ zone, users, isLoading }) => {
  const InstalledUsersHeaders = [
    { label: 'Created On', key: 'created_at' },
    { label: 'Name', key: 'Name' },
    { label: 'Phone Number', key: 'Customer_Number' },
    { label: 'Expiration Date', key: 'Expiration_date' },
    { label: 'Username', key: 'username' },
    { label: 'Mobile', key: 'Mobile' },
    { label: 'Email', key: 'Mail' },
  ];

  return (
    <div className="modal fade" id="viewDetails" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-lg large-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" style={{ color: 'black' }}>
              {zone.toUpperCase()} Installed Customer Details
            </h4>
            <button type="button" className="mr-2 noBorder" data-dismiss="modal" aria-hidden="true">
              x
            </button>
          </div>
          <div className="modal-body">
            {users.length > 0 && (
              <span className="mt-2">
                <CustomCsvDownload
                  data={users || []}
                  headers={InstalledUsersHeaders}
                  filename={downloadedFileName(`Installed Users at ${zone.toUpperCase()}`)}
                  classname="btn btn-green shadow-md"
                  textcolor="white"
                  text="Download Installed Users"
                />
              </span>
            )}
            <div className="table-responsive content col-12">
              <table className="table">
                <thead className="">
                  <tr className="bg-white">
                    <th>S/N</th>
                    <th>Name</th>
                    <th>MAC ID</th>
                    <th>Expiry Date</th>
                  </tr>
                </thead>
                <tbody>{isLoading ? <TizetiGIFLogoTable colSpan="4" /> : <InstalledUsersTable users={users} />}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstalledUsers;
