import {  GET_RETAILER_LOCATIONS, GET_TOTAL_REVENUE } from "../../constants/types/hotspot/HotspotTypes";

const initialState = {

  revenue: [],
  loadHotspotRevenue: true,
  locations: [],
  loadLocations: true

};

export const HotspotReducer=(state=initialState, action)=> {

  const {type, payload} = action;

  switch (type) {

    case GET_TOTAL_REVENUE:
      return {

        ...state,
        revenue: payload,
        loadHotspotRevenue: false

      };

    case GET_RETAILER_LOCATIONS:
      return {

        ...state,
        locations: payload,
        loadLocations: false

      };

    default:
      return state;

  }

};

export default HotspotReducer;