import axios from 'axios';
import { useState } from 'react';
import toastr from 'toastr';


export const useWifiEvent = () => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateLoading, setUpdateIsLoading] = useState(false);

  const getWifiEvent = async (data) => {
    setIsLoading(true);
    try {
      const response = await axios.post(`https://api.tizeti.com/api/v1/eventwifi/getEvents/1`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data) {
        //put your set state here
        setList(response.data.result);
        setIsLoading(false);
      }
    } catch (e) {
      toastr.info('Something went wrong!');
    }
  };

  const editEventStatus = async (id, data) => {
    setUpdateIsLoading(true)
    try {
      const response = await axios.put(`https://api.tizeti.com/api/v1/eventwifi/event/${id}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data) {
        toastr.success('Updated Successfully!');
        setUpdateIsLoading(false)
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (e) {
      toastr.info('Something went wrong!');
    }
  };
  return {
    list,
    isLoading,
    isUpdateLoading,
    getWifiEvent,
    editEventStatus
  };
};
