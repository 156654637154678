import { SEARCH_FOR_ROUTER } from '../../constants/types/procurement/ProcurementTypes';

const initialState = {
  router: [],
};
export const RouterReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === SEARCH_FOR_ROUTER) {
    return {
      ...state,
      router: payload,
    };
  }
  return state;
};

export default RouterReducer;
