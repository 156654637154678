import React, { useState } from 'react';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { useRequisition } from '../../../../../../hooks/admin/requisition/useRequisition';
import view from '../../../../../../assets/img/icons/view.png';
// import ViewReceipt from '../../../../shared/requisition/shared/ViewReceipt';
import CommonRequisitionDetail from '../../../../shared/requisition/shared/CommonRequistionDetails';
import ViewReceipt from '../../../../shared/requisition/executives/approved/ViewReceipt';

const ApprovedTable = () => {
  const { approved, approvedLoading, approvedPages, paginateApprovedRequisition } = useRequisition();
  const { rows } = approved;
  const [requisit, setRequisit] = useState({});
  const [viewFile, setViewFile] = useState({});

  const getRequsition = (req) => {
    setRequisit(req);
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const checkFile = (req) => {
    if (req.imageUpload.split('.').pop() === 'pdf') {
      return window.open(req.imageUpload, '_blank');
    }
    return <ViewReceipt requisit={viewFile} />;
  };

  const getFileRequsition = (req) => {
    setViewFile(req);
    if (req.imageUpload.split('.').pop() === 'pdf') {
      window.open(req.imageUpload, '_blank');
    }
  };
  const requsitionList =
    rows && rows.length ? (
      rows.map((requsition, i) => {
        return (
          <tr key={requsition.id}>
            <td>{i + 1}</td>
            {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
            <td>N{requsition.amount_in_digits}</td>
            <td>{requsition.amount_in_words}</td>

            <td>{requsition.account_approval}</td>
            <td>{requsition.manager_approval}</td>
            <td>{requsition.ceo_or_coo_approval}</td>
            <td>{requsition.in_favour_of}</td>
            <td>{requsition.requires_c_level_approval === false ? 'False' : 'True'}</td>
            <td>{requsition.requires_ceo_or_coo_approval === false ? 'False' : 'True'}</td>
            {requsition.created_at ? <td>{moment(requsition.created_at).format('MM/DD/YYYY')}</td> : 'N/A'}

            <td>{requsition.purpose}</td>
            {/* <td> */}
            {/*  /!* eslint-disable-next-line jsx-a11y/click-events-have-key-events *!/ */}
            {/*  <small> */}
            {/*    /!* eslint-disable-next-line react/button-has-type *!/ */}
            {/*    <button className="btn btn-primary">Pay</button> */}
            {/*  </small> */}
            {/* </td> */}
            <td>
              {requsition.account_payment_status === 'awaiting_payment' ? (
                <span className="badge badge-info" style={{ backgroundColor: 'blue' }}>
                  Awaiting Payment
                </span>
              ) : (
                <span className="badge badge-success" style={{ backgroundColor: 'green' }}>
                  Paid
                </span>
              )}
            </td>

            <td>
              <div className="mr-2 ml-2">
                <button
                  data-target="#viewReceipt"
                  data-toggle="modal"
                  type="submit"
                  onClick={() => getFileRequsition(requsition)}
                  style={{
                    border: '0',
                    backgroundColor: 'white',
                  }}
                >
                  {/* {checkFile(requsition)} */}
                  <span className="view2">
                    <img src={view} alt="view" data-toggle="tooltip" data-placement="left" title="View" />
                  </span>
                </button>

                {/* {checkFile(requsition)} */}
                <ViewReceipt requisit={viewFile} />
              </div>
            </td>

            <td>
              <div className="mr-2 ml-2 d-flex">
                <button
                  data-target="#requisition"
                  data-toggle="modal"
                  type="submit"
                  onClick={() => getRequsition(requsition)}
                  style={{
                    border: '0',
                    backgroundColor: 'white',
                  }}
                >
                  <span className="view2">
                    <img src={view} alt="view" data-toggle="tooltip" data-placement="left" title="View" />
                  </span>
                </button>
                <CommonRequisitionDetail requisit={requisit} />
              </div>
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td>
          <p>No requisitions</p>
        </td>
      </tr>
    );
  return (
    <div className="card x-panel logs">
      <div className="table-responsive content">
        <table className="table copy-font">
          <thead>
            <tr>
              <th>#</th>
              <th>Amount In Digits</th>
              <th>Amount In Words</th>
              <th>Accounts Approval Status </th>
              <th>Manager Approval Status </th>
              <th>CEO/COO LEVEL Approval Status </th>
              <th>In Favor Of</th>
              <th>Requires C Level Approval?</th>
              <th>Requires CEO/COO Level Approval?</th>
              <th>Created At</th>
              <th>Purpose</th>
              {/* <th>Pay</th> */}
              <th>Payment Status</th>
              <th>View Uploaded File</th>
              <th>View Details</th>
            </tr>
          </thead>
          {approvedLoading ? (
            <small>loading</small>
          ) : (
            <tbody>{requsitionList}</tbody>
            // <ApprovedTableBody
            //   requistions={rows}
            //   loading={approvedLoading}
            //   // giveCeoCooDisApproval={giveCeoCooDisApproval}
            // />
          )}
        </table>
      </div>
      {approvedPages > 1 ? (
        <ReactPaginate
          previousLabel="previous"
          nextLabel="next"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={approvedPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={paginateApprovedRequisition}
          containerClassName="pagination"
          subContainerClassName="pages pagination"
          activeClassName="active"
        />
      ) : null}
    </div>
  );
};

export default ApprovedTable;
