/* eslint-disable no-empty */
/* eslint-disable react/require-default-props */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import '../../../../../styles/style.css';
import '../../../../../styles/inventory.css';
import axios from 'axios';
import toastr from 'toastr';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getLoggedInUser } from '../../../../../actions/user/UserActions';
import RoutersTable from './RoutersTable';
import { LOGGED_IN_USER_URL } from '../../../../../services/api/auth/AuthUrl';
import { PROCUREMENT } from '../../../../../utils/config';
import ProcurementNavBar from '../../../../layouts/procurement/ProcurementNavBar';

class Routers extends Component {
  componentDidMount() {
    // eslint-disable-next-line no-shadow
    const { getLoggedInUser } = this.props;

    getLoggedInUser();
    this.checkDepartment();
  }

  componentWillReceiveProps() {
    this.checkDepartment();
  }

  checkDepartment = async () => {
    const { history } = this.props;
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.department_id !== `${PROCUREMENT}`) {
        history.push('/');

        sessionStorage.setItem('token', '');
        sessionStorage.clear();

        toastr.info('You are not allowed to view this page');
      }
    } catch (e) {}
  };

  render() {
    const { user } = this.props;
    const { firstName } = user;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <ProcurementNavBar currentPage="Routers" position={firstName} />

          <RoutersTable />
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

Routers.propTypes = {
  getLoggedInUser: PropTypes.func,
  history: PropTypes.shape,
  user: PropTypes.shape,
};

export default connect(mapStateToProps, {
  getLoggedInUser,
})(Routers);
