import {
  FETCHING_STARTS,
  FETCHING_STOPS,
  LOADING_STARTS,
  LOADING_STOPS,
} from '../../../constants/types/shared/LoadingTypes';
import {
  GET_ALL_REQUISITIONS,
  START_LOADING_ACCOUNTS_REQUSITION,
  STOP_LOADING_ACCOUNTS_REQUSITION,
} from '../../../constants/types/accounts/RequisitionTypes';

const intialState = {
  requisitions: [],
  load: false,
  loadForm: false,
  fetch: false,
};

export const AccountsRequsitionReducer = (state = intialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOADING_STARTS:
      return {
        ...state,
        loadForm: payload,
      };

    case LOADING_STOPS:
      return {
        ...state,
        loadForm: payload,
      };

    case FETCHING_STARTS:
      return {
        ...state,
        load: payload,
      };

    case FETCHING_STOPS:
      return {
        ...state,
        load: payload,
      };

    case START_LOADING_ACCOUNTS_REQUSITION:
      return {
        fetch: payload,
        ...state,
      };

    case STOP_LOADING_ACCOUNTS_REQUSITION:
      return {
        fetch: payload,
        ...state,
      };

    case GET_ALL_REQUISITIONS:
      return {
        ...state,
        load: false,
        requisitions: payload,
        fetch: false,
      };

    default:
      return state;
  }
};

export default AccountsRequsitionReducer;
