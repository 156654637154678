/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React from 'react';

const ScoreDetails = ({ details }) => {
  return (
    <div
      id="score-details"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Score Details
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          <div className="modal-body">
            <div className="col-6 d-flex mb-4">
              <div>
                <label label className="copy-font m-0 p-0">
                  Extension
                </label>
                <input type="number" className="form-control" placeholder="200" value={details.extension} readOnly />
              </div>
            </div>
            <table className="table score-agent-table">
              <thead>
                <tr>
                  <th>Section</th>
                  <th>Description</th>
                  <th nowrap="no-wrap">Score (100%)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th nowrap="no-wrap">Section 1</th>
                  <th>Welcome</th>
                </tr>
                <tr>
                  <td />
                  {/* <td>Did the agent greet- Good Morning, Afternoon, Evening</td> */}
                  <td>Did the agent say Welcome to Tizeti(Company's Name) and end the call properly?</td>
                  <th>{details.greetings}%</th>
                </tr>
                {/* <tr> */}
                {/* <td></td> */}
                {/* <td>Did the agent say the Welcome to Tizeti(Company's Name)</td> */}
                {/* <th>09</th> */}
                {/* </tr> */}
                {/* <tr> */}
                {/* <td></td> */}
                {/* <td>Did the agent says his/her full name?</td> */}
                {/* <th>15</th> */}
                {/* </tr> */}
                {/* <tr> */}
                {/* <td></td> */}
                {/* <td>Did the agent confirm the customers details: Account name or Email</td> */}
                {/* <th>14</th> */}
                {/* </tr> */}
                {/* listening */}
                {/* <tr> */}
                {/* <th nowrap="no-wrap">Section 2</th> */}
                {/* <th>Listening</th> */}
                {/* </tr> */}
                {/* <tr> */}
                {/* <td></td> */}
                {/* <td>Did the agent ask if this was a convenient time to talk?</td> */}
                {/* <th>10</th> */}
                {/* </tr> */}
                {/* <tr> */}
                {/* <td></td> */}
                {/* <td>Did the agent re-confirm what the customers issue?</td> */}
                {/* <th>06</th> */}
                {/* </tr> */}
                {/* <tr> */}
                {/* <td></td> */}
                {/* <td>Did the agent demonstrate active or effective listening quotes like (I am still with you, I am still */}
                {/* here ,mmm, Ok)? */}
                {/* </td> */}
                {/* <th>{(details.listening )}%</th> */}
                {/* </tr> */}
                {/* <tr> */}
                {/* <td></td> */}
                {/* <td>Did the agent display knowledge of previous call</td> */}
                {/* <th>13</th> */}
                {/* </tr> */}
                {/* Empathy */}
                <tr>
                  <th nowrap="no-wrap">Section 2</th>
                  <th>Knowledge</th>
                </tr>
                {/* <tr> */}
                {/* <td></td> */}
                {/* <td>Did the agent offer assistance?</td> */}
                {/* <th>05</th> */}
                {/* </tr> */}
                {/* <tr> */}
                {/* <td></td> */}
                {/* <td>Did the agent go the extra mile for the customer?</td> */}
                {/* <th>12</th> */}
                {/* </tr> */}
                <tr>
                  <td />
                  <td>Did the agent have sufficient knowledge of the products and services the company offers?</td>
                  <th>{details.knowledge}%</th>
                </tr>
                {/* <tr> */}
                {/* <td></td> */}
                {/* <td>Did the agent bring up small talk?</td> */}
                {/* <th>14</th> */}
                {/* </tr> */}
                {/* Resolution */}
                <tr>
                  <th nowrap="no-wrap">Section 3</th>
                  <th>Resolution</th>
                </tr>
                {/* <tr> */}
                {/* <td></td> */}
                {/* <td>Did the agent understand the issue?</td> */}
                {/* <th>10</th> */}
                {/* </tr> */}
                {/* <tr> */}
                {/* <td></td> */}
                {/* <td>Did the agent follow the correct procedure putting the customer on hold</td> */}
                {/* <th>14</th> */}
                {/* </tr> */}
                {/* <tr> */}
                {/* <td></td> */}
                {/* <td>Did the agent use effective questions skill to get to the root of the issue?</td> */}
                {/* <th>10</th> */}
                {/* </tr> */}
                <tr>
                  <td />
                  <td>
                    Did the agent build relationship with the customer and show commitment to resolving the issue?
                  </td>
                  <th>{details.resolution}%</th>
                </tr>
                {/* Closing */}
                {/* <tr>
                <th nowrap="no-wrap">Section 5</th>
                <th>Closing</th>
              </tr> */}
                {/* <tr> */}
                {/* <td></td> */}
                {/* <td>Did the agent upsell or cross sell -Example payment option ; Promo pay 6 months/1 Year get 1 month */}
                {/* /2months respectively? */}
                {/* </td> */}
                {/* <th>10</th> */}
                {/* </tr> */}
                {/* <tr>
                <td></td>
                <td>Did the agent ask if there is anything else I can help you with?</td>
                <th>{(details.closing )}%</th>
              </tr> */}
                {/* <tr> */}
                {/* <td></td> */}
                {/* <td>Did the agent close the call with enjoy the rest of your day?</td> */}
                {/* <th>10</th> */}
                {/* </tr> */}
                {/* <tr> */}
                {/* <td></td> */}
                {/* <td>Did the customer end the call?</td> */}
                {/* <th>10</th> */}
                {/* </tr> */}
                {/* Total */}
                <tr>
                  <td />
                  <td>
                    <b>Total</b>
                  </td>
                  <td>
                    <b>{details.knowledge + details.resolution + details.greetings}%</b>
                  </td>
                </tr>
                <tr>
                  <th>Feedback</th>
                  <td>
                    <textarea
                      readOnly="readonly"
                      rows="8"
                      cols="50"
                      name="score"
                      placeholder={details.feedback}
                      className="form-control"
                    >
                      {details.feedback}
                    </textarea>
                  </td>
                </tr>
                {/* Submit */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScoreDetails;
