import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { Box } from '@mui/material';
import { get_all_support_visits_query } from '../../../../../../actions/coo-statistics/SupportVisit';
import { FormatDate } from '../downtime-logger/DowntimeLogger.Index';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import loader from '../../../../../../assets/img/icons/TizetiLoaderColored.gif';
import { globalAgent } from 'http';
function SimpleBackdrop({ isLoading }) {
  return (
    <div>
      {/* <Button onClick={handleOpen}>Show backdrop</Button> */}
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        {/* <CircularProgress color="inherit" /> */}
        <img src={loader} alt="Loading..." />
      </Backdrop>
    </div>
  );
}

SimpleBackdrop.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default function BarCharts({ fromDate, toDate, submitDetect }) {
  const [loading, setLoading] = React.useState(false);
  const [checkedIssueType, setCheckedIssueType] = React.useState(true);
  const [checkedNeedsRadio, setCheckedNeedsRadio] = React.useState(false);
  const [checkedNeedsPole, setCheckedNeedsPole] = React.useState(false);
  const [checkedNeedsLadder, setCheckedNeedsLadder] = React.useState(false);
  const [data, setData] = React.useState([]);
  // console.log('🚀 ~ BarCharts ~ data:', data);
  const zoneRef = React.useRef();
  const dataRef = React.useRef();

  const handleSwitchToggleIssueType = (event) => {
    setCheckedIssueType(event.target.checked);
    if (event.target.checked) {
      const existingData = globalDataStore.current.find((val) => val.issueType !== undefined);
      if (existingData) {
        // Use the existing data
        return globalDataStore.current;
      } //i do not need the redundancy since the data is already in the store
    }
  };
  const handleSwitchToggleNeedsRadio = (event) => {
    setCheckedNeedsRadio(event.target.checked);
    if (event.target.checked) {
      const existingData = globalDataStore.current.find((val) => val.needsRadio !== undefined);
      if (existingData) {
        // Use the existing data
        return globalDataStore.current;
      } else {
        setLoading(true);
        fetchData(fromDate, toDate, 'needs_radio', true)
          .then((data) => {
            globalDataStore.current = [...globalDataStore.current, { needsRadio: data }];
            if (data.length) {
              const refineNewData = data.map((val, i) => {
                const lastObject = globalDataStore.current[globalDataStore.current.length - 1];
                const key = Object.keys(lastObject)[0];
                return {
                  ...val,
                  [key]: lastObject[key][i].supportVisitCount,
                };
              });
              setData((prev) =>
                prev.map((data, i) => {
                  return {
                    ...data,
                    ['Needs Radio']: refineNewData[i].needsRadio,
                  };
                }),
              );
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };
  const handleSwitchToggleNeedsLadder = (event) => {
    setCheckedNeedsLadder(event.target.checked);
    if (event.target.checked) {
      const existingData = globalDataStore.current.find((val) => val.needsLadder !== undefined);
      if (existingData) {
        // Use the existing data
        return globalDataStore.current;
      } else {
        setLoading(true);
        fetchData(fromDate, toDate, 'needs_ladder', true)
          .then((data) => {
            globalDataStore.current = [...globalDataStore.current, { needsLadder: data }];
            if (data.length) {
              const refineNewData = data.map((val, i) => {
                const lastObject = globalDataStore.current[globalDataStore.current.length - 1];
                const key = Object.keys(lastObject)[0];
                return {
                  ...val,
                  [key]: lastObject[key][i].supportVisitCount,
                };
              });
              setData((prev) =>
                prev.map((data, i) => {
                  return {
                    ...data,
                    ['Needs Ladder']: refineNewData[i].needsLadder,
                  };
                }),
              );
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };
  const handleSwitchToggleNeedsPole = (event) => {
    setCheckedNeedsPole(event.target.checked);
    if (event.target.checked) {
      const existingData = globalDataStore.current.find((val) => val.needs_Pole !== undefined);
      if (existingData) {
        // Use the existing data
        return globalDataStore.current;
      } else {
        setLoading(true);
        fetchData(fromDate, toDate, 'needs_pole', true)
          .then((data) => {
            globalDataStore.current = [...globalDataStore.current, { needsPole: data }];
            if (data.length) {
              const refineNewData = data.map((val, i) => {
                const lastObject = globalDataStore.current[globalDataStore.current.length - 1];
                const key = Object.keys(lastObject)[0];
                return {
                  ...val,
                  [key]: lastObject[key][i].supportVisitCount,
                };
              });
              setData((prev) =>
                prev.map((data, i) => {
                  return {
                    ...data,
                    ['Needs Pole']: refineNewData[i].needsPole,
                  };
                }),
              );
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  // create a store to hold data that doesnt lose its value after re-render
  const globalDataStore = React.useRef([]);
  console.log('🚀 ~ BarCharts ~ globalDataStore:', globalDataStore);

  async function fetchData(from, to, key, value) {
    try {
      setLoading(true);
      const res = await get_all_support_visits_query(from, to, key, value);
      if (res) {
        return res;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    fetchData(fromDate, toDate).then((data) => {
      // console.log('🚀 ~ fetchData ~ data:', data);

      // get Zonename and set it here
      const getZoneName = data.map((zone) => zone.name);
      // console.log('🚀 ~ fetchData ~ getZoneName:', getZoneName);
      zoneRef.current = getZoneName;
      // console.log('🚀 ~ fetchData ~ zoneRef.current:', zoneRef.current);

      // globalDataStore.current.push(data);
      //instead of mutating the state directly why not use a spead
      globalDataStore.current = [...globalDataStore.current, { issueType: data }];
      console.log('🚀 ~ fetchData ~ globalDataStore.current:', globalDataStore.current);

      const refinedData = zoneRef.current.map((item, i) => {
        return {
          name: item,
          ['Technical Issue']: globalDataStore.current[0].issueType[i].supportVisitCount,
        };
      });
      setData(refinedData);
      dataRef.current = refinedData;
      // console.log('🚀 ~ refinedData ~ refinedData:', refinedData);
    });
  }, []);

  React.useEffect(() => {
    // clear the globalData
    globalDataStore.current = [];

    // also set all the checks to false
    // setCheckedIssueType(false);
    setCheckedNeedsRadio(false);
    setCheckedNeedsLadder(false);
    setCheckedNeedsPole(false);
    fetchData(fromDate, toDate).then((data) => {
      globalDataStore.current = [...globalDataStore.current, { issueType: data }];
      console.log('🚀 ~ fetchData ~ globalDataStore.current:', globalDataStore.current);

      const refinedData = zoneRef.current.map((item, i) => {
        return {
          name: item,
          ['Technical Issue']: globalDataStore.current[0].issueType[i].supportVisitCount,
        };
      });
      setData(refinedData);
      dataRef.current = refinedData;
      // console.log('🚀 ~ refinedData ~ refinedData:', refinedData);
    });
  }, [submitDetect]);

  return (
    <div>
      <div>
        <FormGroup sx={{ display: 'flex' }}>
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked
                  checked={checkedIssueType}
                  onChange={handleSwitchToggleIssueType}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Technical / non-Technical"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedNeedsRadio}
                  onChange={handleSwitchToggleNeedsRadio}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Needs Radio"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedNeedsLadder}
                  onChange={handleSwitchToggleNeedsLadder}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Needs Ladder"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedNeedsPole}
                  onChange={handleSwitchToggleNeedsPole}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Needs Pole"
            />
          </Box>
        </FormGroup>
      </div>
      <Box sx={{ mr: 5, mt: 3 }}>
        {loading ? (
          <SimpleBackdrop isLoading={loading} />
        ) : (
          <Box sx={{ width: { xs: '90vw', md: 1000 }, overflowX: 'auto' }}>
            <Typography fontWeight={600}>Y-axis: Type</Typography>
            <Box sx={{ width: '100%', overflowX: 'scroll' }}>
              <BarChart
                width={4200}
                height={550}
                data={data}
                margin={{
                  top: 5,
                  right: 10,
                  left: 0,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />

                {checkedIssueType && <Bar dataKey="Technical Issue" fill="#413ea0" />}

                {checkedNeedsRadio && <Bar dataKey="Needs Radio" fill="#99a03e" />}

                {checkedNeedsLadder && <Bar dataKey="Needs Ladder" fill="#a03e6c" />}

                {checkedNeedsPole && <Bar dataKey="Needs Pole" fill="#46a03e" />}
              </BarChart>
            </Box>
          </Box>
        )}
      </Box>
    </div>
  );
}
