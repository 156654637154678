/* eslint-disable import/prefer-default-export */
import * as types from '../../../constants/types/shared/purchase-order/PurchaseOrderTypes';
import * as load from '../../../constants/types/shared/LoadingTypes';

export const purchaseOrderReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case load.LOADING_STARTS:
      return {
        ...state,
        isLoading: true,
      };

    case load.LOADING_STOPS:
      return {
        ...state,
        isLoading: false,
      };

    case types.GET_PURCHASE_ORDER:
      return {
        ...state,
        lists: payload.list,
        pages: payload.pages,
        count: payload.count,
        tracker: payload.tracker,
        combined: payload.combined,
        isLoading: false,
      };

    case types.GET_FSE_PURCHASE_ORDER:
      return {
        ...state,
        technicalPO: payload.technicalPO,
        operationsPO: payload.operationsPO,
        tracker: payload.tracker,
        combined: payload.combined,
        isLoading: false,
      };

    default:
      return state;
  }
};
