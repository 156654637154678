import React from 'react';
import { useActiveZone } from '../../../../../../hooks/asset-team/zones/active-zones/useActiveZone';
import { AssetTeamHelperActive, FormatTime } from '../../../../../../utils/asset-team/AssetTeamHelper';
import * as helpers from '../../../../../../utils/asset-team/AssetTeamHelperBody';
import ActiveCustomersPerZoneTable from './ActiveCustomersPerZoneTable';

const ActiveCustomersPerZoneBody = () => {
  const { items, time, total, isLoading } = useActiveZone();
  const { data } = AssetTeamHelperActive(items, 'zone');
  const { fetchTime } = FormatTime(time);
  const showView = false;

  const headers = [{ label: 'Zone', key: 'zone' }, { label: 'Count', key: 'active_count' }];

  return (
    <div className="container">
      <div className="row my-5">
        <div className="col-12 pt-5">{helpers.AssetTeamHeader(items, time, total, fetchTime)}</div>
        <div className="col-12 mb-5 pr-5">{helpers.AssetTeamHelperGraph(data, time, total, fetchTime)}</div>
        <div className="col-12 pr-5">
          {helpers.AssetTeamGeneralButton(items, headers, 'Active Customers Per Zone.csv')}
        </div>
        {helpers.AssetTeamHelperTable(isLoading, showView, <ActiveCustomersPerZoneTable items={items} />)}
        <div className="ml-3">{helpers.AssetTeamBottomTotal(total)}</div>
      </div>
    </div>
  );
};

export default ActiveCustomersPerZoneBody;
