/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';

class NocPublicIpTable extends Component {
  render() {
    return (
      <tbody>
        {this.props.ip.map((ips, i) => (
          <tr key={i + 1}>
            <td>{i + 1}</td>
            <td>{ips.baseStation.name}</td>
            <td>{ips.ip_Address}</td>
            <td>{ips.private_Ip1}</td>
            {ips.private_Ip2 ? <td>{ips.private_Ip2}</td> : <td>N/A</td>}
            {ips.private_Ip3 ? <td>{ips.private_Ip3}</td> : <td>N/A</td>}
          </tr>
        ))}
      </tbody>
    );
  }
}

export default NocPublicIpTable;
