import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { TableCellMonths } from '../components/tempData';
import Box from '@mui/material/Box';

export default function AreaCharts({ data, isLoading, isTotalActiveSubsChecked, isTotalExpiredChecked }) {
  const [getData, setGetData] = React.useState([]);

  React.useEffect(() => {
    if (data.length > 0) {
      const refinedData = TableCellMonths.map((months, index) => ({
        name: months,
        'Total active / paying subs': data[0].values[index],
        'Total number of new Installs': data[1].values[index],
        'Total Expired (actual)': data[2].values[index],
      }));

      setGetData(refinedData);
    }
  }, [data]);

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flexStart">
      {!isLoading && (
        <ResponsiveContainer width="100%" height={500}>
          <AreaChart
            data={getData}
            margin={{
              top: 5,
              right: 10,
              left: 0,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />

            <Area type="monotone" dataKey="Total number of new Installs" stroke="#82ca9d" fill="#8884d8" />

            {isTotalActiveSubsChecked && (
              <Area type="monotone" dataKey="Total active / paying subs" fill="#d88492" stroke="#82ca9d" />
            )}

            {isTotalExpiredChecked && (
              <Area type="monotone" dataKey="Total Expired (actual)" fill="#b3864c" stroke="#82ca9d" />
            )}
          </AreaChart>
        </ResponsiveContainer>
      )}
    </Box>
  );
}
