import React from 'react';
import AssetTeamNavBar from '../../../../layouts/asset-team/AssetTeamNavBar';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import InstallationsPerBasestationBody from '../../../shared/installations/per-basestation/InstallationsPerBasestationBody';

const AssetTeamInstallationsPerBasestation = () => {
  const {
    profile: { firstName },
  } = useUser();

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <AssetTeamNavBar currentPage="Installations Per Basestations" position={firstName} />
        <InstallationsPerBasestationBody />
      </main>
    </div>
  );
};
export default AssetTeamInstallationsPerBasestation;
