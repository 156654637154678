import React, { useContext, useState } from 'react';
import RequisitionContext from '../../../../../../../context/shared/requisitions/RequsitionContext';

const FilterByCountry = () => {
  const { filterRequisitionByCountry, country } = useContext(RequisitionContext);

  // const [country] = useState('');
  const onChange = (e) => {
    if (e.target.value) filterRequisitionByCountry(e.target.value);
    return null;
  };
  return (
    <div className="my-3 col-4">
      <select name="country" className="form-control col-7" defaultValue={country} onChange={onChange}>
        <option value="">Filter By Country</option>
        <option value="ng">Nigeria</option>
        <option value="gh">Ghana</option>
        <option value="cv">Cote d'Ivoire</option>
      </select>
    </div>
  );
};

export default FilterByCountry;
