/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useUserProfile } from '../../../../../hooks/shared/user/useUserProfile';
import BusinessDevelopmentNavBar from '../../../../layouts/business-development/BusinessDevelopmentNavBar';
import Requisitions from '../../../shared/requisition/shared/Requisitions';

const BusinessDevelopmentRequisitions = () => {
  const { getUserProfile, profile } = useUserProfile();
  const { firstName } = profile;
  sessionStorage.setItem('departmentId', 'a521da84-8c18-4e22-9a49-c2aee8706e79');

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <BusinessDevelopmentNavBar currentPage="Requisition" position={firstName} />
        <div className="col-lg-12">
          <Requisitions />
        </div>
      </main>
    </div>
  );
};

export default BusinessDevelopmentRequisitions;
