import React, { Component } from 'react';
import '../../../../styles/style.css';
import '../../../../styles/sales_overview.css';
import { Link } from 'react-router-dom';
import profile from '../../../../assets/img/icons/Profile.png';
import mailblast from '../../../../assets/img/icons/mailblast.svg';
import Logout from '../../shared/auth/Logout';
import NewProductSideBar from '../../../layouts/new-products/NewProductsSideBar';
import ViewUserProfile from '../../shared/staff/ViewUserProfile';

class ProductModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      move: false,
      out: false,
      redirect: false,
      role: '',
    };
  }

  render() {
    return (
      <div className="s-layout" id="product">
        <main className="s-layout__content col-lg-10 p-0">
          <div className="d-flex col-lg-12 p-2 top-header">
            <div className="mt-3 d-flex col-lg-12 p-0">
              <Link className="s-sidebar__trigger" to="#">
                <i className="fa fa-bars mr-3" />
              </Link>
              <NewProductSideBar />

              <h3 className="col-lg-6 overview-part">Products</h3>

              <div className="d-flex profile-mail">
                <Link
                  to="/new-products/mail-blast"
                  className="d-none d-md-flex"
                  style={{ color: 'gray', textDecoration: 'none' }}
                >
                  <img src={mailblast} alt="" className="logout mr-2" />
                  <span>
                    <h6 className="mt-2 mr-3">
                      <strong>MailBlast</strong>
                    </h6>
                  </span>
                </Link>

                <Link to="#" data-toggle="modal" data-target="#add-employee">
                  <img src={profile} className="logout" alt="" />
                </Link>

                <Logout />
              </div>
            </div>
          </div>

          <div className="col-12 after-summary">
            <ViewUserProfile />
            <div className="row">
              <div className="container-fluid col-lg-12">
                <div className="col-md-8 col-10 shadow-lg p-5 ml-auto mr-auto d-flex flex-wrap product-imgs">
                  <div className="mb-5">
                    <Link to="/hotspot-admin">
                      <img src="https://res.cloudinary.com/teewhy/image/upload/v1549033391/product1.png" alt="" />
                    </Link>
                  </div>

                  <div className="mb-5">
                    <Link to="/wifi-call">
                      <img src="https://res.cloudinary.com/teewhy/image/upload/v1549033391/product3.png" alt="" />
                    </Link>
                  </div>

                  <div className="mb-5">
                    <Link to="http://shop.wifi.com.ng/">
                      <img src="https://res.cloudinary.com/teewhy/image/upload/v1549033391/product2.png" alt="" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default ProductModal;
