/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { connect } from 'react-redux';
import AddLead from '../../departments/sales/leads/AddLead';
import SalesLeadsTable from './PaidLeadsTable';
import Loader from '../../../../assets/img/icons/TizetiLoaderColored.gif';
import { API_URL, SALES } from '../../../../utils/config';
import { LOGGED_IN_USER_URL } from '../../../../services/api/auth/AuthUrl';
import Helper from '../../../../utils/helpers';
import { getUser } from '../../../../actions/shared/user/UserActions';
import { getLeads, searchLead } from '../../../../actions/shared/leads/LeadsActions';
import '../../../../styles/style.css';
import '../../../../styles/callog.css';
import CustomPagination from '../CustomPagination';

class PaidLeadsBody extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filtered: [],
      keyword: '',
      modalShow: false,
      pageCount: 0,
      paginate: false,
    };

    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.checkDepartment();
    this.props.getLeads();
    this.props.getUser();
  }

  componentWillReceiveProps() {
    this.checkDepartment();
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  getModalStatus(i) {
    this.setState(
      {
        modalShow: !i,
      },
      () => {
        if (this.state.modalShow === true) {
          this.setState({
            modalShow: !this.state.modalShow,
          });
        }
      },
    );
  }

  checkDepartment = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.department_id !== `${SALES}`) {
        this.props.history.push('/');

        sessionStorage.setItem('token', '');
        sessionStorage.clear();

        toastr.info('You are not allowed to view this page');
      }
    } catch (e) {
      if (e.response) {
        console.log(e.response);
      }
    }
  };

  searchCustomer = async (e) => {
    e.preventDefault();
    const { keyword } = this.state;

    if (Helper.emailIsValid(keyword)) {
      const data = {
        email: keyword,
      };

      this.props.searchLead(data);
    } else if (Helper.phoneIsValid(keyword)) {
      const data = {
        phoneNumber: keyword,
      };

      this.props.searchLead(data);
    } else {
      const data = {
        firstName: keyword,
      };

      this.props.searchLead(data);
    }

    // emailmichgboxy@gmail.com
    // phoneNumber08123434345
  };

  searchCustome = (e) => {
    const keyword = e.target.value;

    if (keyword !== '') {
      const list = this.state.filtered.filter((customer) => {
        if (customer.lastName.indexOf(keyword) !== -1) {
          return customer;
        }
        if (customer.firstName.indexOf(keyword) !== -1) {
          return customer;
        }
        if (customer.email.indexOf(keyword) !== -1) {
          return customer;
        }
        if (customer.phoneNumber.indexOf(keyword) !== -1) {
          return customer;
        }
        if (customer.address.toLowerCase().indexOf(keyword) !== -1) {
          return customer;
        }
      });

      this.setState({
        filtered: list,
        keyword: e.target.value,
      });
    } else {
      this.setState({
        filtered: this.state.leads,
        keyword,
      });
    }
  };

  handlePageChange(pageNumber) {
    const selected = pageNumber.selected + 1;

    axios({
      method: 'get',
      url: `${API_URL}/users/leads/${selected}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then((response) => {
      if (response.data) {
        this.setState({
          leads: response.data.users,
          filtered: response.data.users,
          pageCount: response.data.pages,
        });
      } else {
        toastr.info('No data');
      }
    });
  }

  render() {
    const { leads } = this.props;
    const { users } = leads;
    const { keyword, paginate, pageCount, modalShow } = this.state;

    return (
      <div className="col-12 after-summary">
        <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-3">
          <div className="row">
            <div className="col-lg-12 col-md-9 col-sm-12 mt-3 mb-3 d-flex">
              <form className="form-inline d-flex inner-addon left-addon w-75" onSubmit={this.searchCustomer}>
                <input
                  value={keyword}
                  onChange={this.onChange}
                  name="keyword"
                  className="form-control mr-sm-2 empty mt-1"
                  id="iconified1"
                  type="text"
                  placeholder=" search by First Name, Email, Phone Number"
                />
                <span className="fa fa-search" />
              </form>

              <p className="fliter copy-font d-none d-md-flex">
                <small className="mt-2">Filter By:</small>
                <span>
                  <select className="form-control">
                    <option>Location</option>
                  </select>
                </span>
              </p>

              <div className={!modalShow ? 'nodisp' : ' modal-backdrop fade show'}> </div>
              <div id="addLead" className={modalShow ? 'modal fade in show d-block' : 'nodisp'} data-backdrop="false">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title text-center" id="myModalLabel">
                        Create Lead
                      </h4>
                      <button
                        type="button"
                        className="close custom-close"
                        /* data-dismiss="modal"
                                                  aria-hidden="true" */
                        onClick={() => {
                          // if(this.state.modalShow === false) {

                          this.setState(
                            {
                              // eslint-disable-next-line react/destructuring-assignment
                              modalShow: !this.state.modalShow,
                            },
                            () => {},
                          );
                        }}
                      >
                        ×
                      </button>
                    </div>
                    <AddLead
                      // eslint-disable-next-line react/jsx-no-bind
                      modalshow={this.getModalStatus.bind(this)}
                    />
                  </div>
                </div>
              </div>
            </div>

            {users ? <SalesLeadsTable leads={users} /> : <img src={Loader} alt="" />}

            <div className="page col-lg-12 col-md-12 col-sm-12 d-flex">
              <CustomPagination
                pagesCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={this.handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  leads: state.leads.leads,
  load: state.leads.load,
});

export default connect(mapStateToProps, {
  getUser,
  searchLead,
  getLeads,
})(PaidLeadsBody);
