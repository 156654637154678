/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import loader from '../../../../../../../assets/img/icons/TizetiLoaderColored.gif';

const AssignedAndUnassignedTable = ({ items, isLoading }) => {
  const tableData =
    items.length && items
      ? items.map((item, i) => {
          const { fullname, phone, email, balance, firstusedate, lastused, created_date } = item;
          return (
            <tr className="text-nowrap" key={i}>
              <td>{i + 1}</td>
              <td>{fullname}</td>
              <td>{phone}</td>
              <td>{email}</td>
              <td>{balance}</td>
              <td>{firstusedate}</td>
              <td>{lastused}</td>
              <td>{created_date}</td>
            </tr>
          );
        })
      : 'Filter for Assigned or Unassigned Customers';

  return (
    <div className="col-12 m-0 p-0 after-summary">
      <div className="row">
        <div className="card x-panel m-0 p-0">
          <div className="table-responsive content col-12">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>S/N</th>
                  <th>Name</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>Balance</th>
                  <th>First Used</th>
                  <th>Last Used</th>
                  <th>Date Created</th>
                </tr>
              </thead>

              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan="8" className="text-center">
                      <img src={loader} alt="loading gif" />
                    </td>
                  </tr>
                ) : (
                  tableData
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

AssignedAndUnassignedTable.propTypes = {
  items: PropTypes.arrayOf.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default AssignedAndUnassignedTable;
