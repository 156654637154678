import {
  FETCHING_STARTS,
  FETCHING_STOPS,
  LOADING_STARTS,
  LOADING_STOPS,
} from '../../../constants/types/shared/LoadingTypes';
import { GET_SCHEDULED, REGISTERED_FIELD_SERVICE_ZONE } from '../../../constants/types/field-support/FieldSupportTypes';

const schedulingReducer = (state, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_SCHEDULED:
      return {
        ...state,
        scheduled: payload,
        fetching: false,
      };

    case FETCHING_STARTS:
      return {
        ...state,
        fetching: true,
      };

    case FETCHING_STOPS:
      return {
        ...state,
        fetching: false,
      };

    case LOADING_STARTS:
      return {
        ...state,
        loading: true,
      };

    case LOADING_STOPS:
      return {
        ...state,
        loading: false,
      };

    case REGISTERED_FIELD_SERVICE_ZONE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default schedulingReducer;
