import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Box from '@mui/material/Box';

const AreaCharts = ({ data, isLoading, zones }) => {
  const [difference, setDifference] = React.useState([]);
  React.useEffect(() => {
    if (data.length > 0) {
      const chartData = zones.map((x, index) => ({
        zone: x.name,
        "difference": data[index]
      }));
      setDifference(chartData);
    }
  }, [data]);
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flexStart">
      <Box sx={{ width: { xs: '90vw', md: 1000, lg: "100%"}, overflowX: 'auto' }}>
        <Box sx={{ width: '100%', overflowX: 'scroll' }}>
      {!isLoading && (
          <AreaChart
            data={difference}
            margin={{
              top: 5,
              right: 10,
              left: 0,
              bottom: 5,
            }}
            width={4200} height={550}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="zone" />
            <YAxis />
            <Tooltip />
            <Legend />

            <Area type="monotone" dataKey="difference" stroke="#82ca9d" fill="#8884d8" />
          </AreaChart>
      )}
        </Box>
      </Box>
    </Box>
  );
};

export default AreaCharts;
