/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { useEffect, useReducer } from 'react';
import { GET_DEPARTMENTS_URL } from '../../../services/api/shared/departments/DepartmentsActions';
import { GET_DEPARTMENTS } from '../../../constants/types/shared/DepartmentsTypes';
import { departmentsReducer } from '../../../reducers/shared/departments/departmentsReducer';

export const useDepartments = () => {
  const [{ departments }, dispatch] = useReducer(departmentsReducer, {
    departments: [],
  });

  const getDepartments = async () => {
    try {
      const response = await axios.get(`${GET_DEPARTMENTS_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      dispatch({
        type: GET_DEPARTMENTS,
        payload: response.data.data,
      });
    } catch (e) {}
  };

  useEffect(() => {
    getDepartments();
  }, []);

  return { departments };
};
