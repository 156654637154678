/* eslint-disable default-case */
import { LOADING_STARTS, LOADING_STOPS } from '../../../../constants/types/shared/LoadingTypes';
import { GET_VOLTAGES, GET_LOW_VOLTAGES } from '../../../../constants/types/shared/noc/VoltageMonitorTypes';

const voltageMonitorReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOADING_STARTS:
      return {
        ...state,
        loading: true,
      };

    case LOADING_STOPS:
      return {
        ...state,
        loading: false,
      };

    case GET_VOLTAGES:
      return {
        ...state,
        voltages: payload,
        loading: false,
        filtered: payload,
      };

    case GET_LOW_VOLTAGES:
      return {
        ...state,
        lowVoltage: payload,
        loading: false,
      };
    default:
      return state;
  }
};
export default voltageMonitorReducer;
