import React from 'react';
import useBasestationConnection from '../hooks/useBasestationConnection';
import BasestationConnectionContext from './BasestationConnectionContext';

const BasestationConnectionProvider = ({ children }) => {
  const {
    data,
    loading,
    paginateChange,
    loadingForm,
    onChange,
    formValue,
    onSubmit,
    pages,
  } = useBasestationConnection();
  return (
    <BasestationConnectionContext.Provider
      value={{
        data,
        loading,
        paginateChange,
        loadingForm,
        onChange,
        formValue,
        onSubmit,
        pages,
      }}
    >
      {children}
    </BasestationConnectionContext.Provider>
  );
};
export default BasestationConnectionProvider;
