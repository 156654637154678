/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { toast } from 'react-toastify';
import { stringify } from 'qs';
import Modal from 'react-bootstrap/Modal';
import useShared from '../../departments/asset-team/shared/hooks/useShared';
import { LEGACY_GENIATECH_URL } from '../../../../services/api/shared/installations/InstallationsUrl';
import { commentOptions } from './AddComment';
import { deviceRetrievalStatus } from '../../../../utils/asset-team/helper';

const callOptions = [
  {
    name: 'Unsuccessful Call - (Unable to reach customer)',
    value: 'Unsuccessful',
  },
  {
    name: 'Move to Uninstall',
    value: 'Uninstall',
  },
  {
    name: 'Visit Customer',
    value: 'Visit',
  },
  {
    name: 'No Action - (All good with customer)',
    value: 'No Action',
  },
];

const CallUpdate = ({ profile, callId, calling, number, show, onClose }) => {
  const { onSubmit: submit, loading, details, setDetails, status, setStatus } = useShared(
    `${LEGACY_GENIATECH_URL}`,
    'Call Status created',
    'Unable to update call status',
  );

  const onSubmit = (e) => {
    e.preventDefault();
    if (!profile.officialEmail) {
      toast.info('Please create a profile');
    } else {
      submit(
        stringify({
          action: 'retension_call_update',
          staff_email: profile.officialEmail,
          call_id: callId,
          // notes,
          status,
          token: `${sessionStorage.getItem('token')}`,
          customer_cin: number,
          details,
        }),
      );
    }
  };
  const submitForm = () => {
    return status.length > 0 && details.length > 0;
  };
  const displayButton = () => {
    if (loading) {
      return (
        <button type="submit" className="btn btn-green" disabled={loading}>
          Updating Call Status...
        </button>
      );
    }
    return (
      <button type="submit" className="btn btn-green" disabled={!submitForm()}>
        Update Call Status
      </button>
    );
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ paddingLeft: '2rem' }}>Call Update</Modal.Title>
      </Modal.Header>

      <form className="form-horizontal" onSubmit={onSubmit}>
        <div className="form-group">
          <div className="col-12 mb-3">
            <div className="col-12">
              <select
                defaultValue="No Action"
                name="status"
                onChange={(e) => setStatus(e.target.value)}
                id=""
                className="form-control shadow-sm borderRadius0"
              >
                <option value="">--Select Status--</option>
                {deviceRetrievalStatus.map((deviceStatus) => (
                  <option key={deviceStatus.value} value={deviceStatus.value}>
                    {deviceStatus.name.toUpperCase()}
                  </option>
                ))}
                {commentOptions.map((opt) => (
                  <option value={opt.name}>{opt.name.toUpperCase()}</option>
                ))}
              </select>
            </div>
          </div>
          {/* <div className="col-12 mb-3">
            <div className="col-12">
              <textarea
                placeholder="Notes"
                className="form-control shadow-sm borderRadius0"
                name="notes"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </div>
          </div> */}
          <div className="col-12 mb-3">
            <div className="col-12">
              <textarea
                placeholder="Details"
                className="form-control shadow-sm borderRadius0"
                name="details"
                value={details}
                onChange={(e) => setDetails(e.target.value)}
              />
            </div>
          </div>
          <div className="col-12 mb-3 text-center">
            {calling ? (
              <button type="submit" className="btn btn-green" disabled={calling}>
                Initiating Call...
              </button>
            ) : (
              displayButton()
            )}
          </div>
        </div>
      </form>
    </Modal>
  );
};
export default CallUpdate;
