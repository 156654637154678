/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import useCheckTwoDepartments from '../../../../../../hooks/shared/custom/check-department/useCheckTwoDepartments';
import { useUser } from '../../../../../../hooks/shared/user/useUser';
import { customer_support } from '../../../../../../utils/config';
import { SUPER_ADMIN } from '../../../../../../utils/departments/helpers';
import CustomerSupportNavBar from '../../../../../layouts/customer-support/CustomerSupportNavBar';
import CallLogsBody from './CallLogsBody';

const CallLogs = ({ history }) => {
  const { profile } = useUser();
  const { firstName } = profile;
  useCheckTwoDepartments(`${customer_support}`, `${SUPER_ADMIN}`, history);
  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <CustomerSupportNavBar currentPage="Call Logs" position={firstName} />
        <CallLogsBody />
      </main>
    </div>
  );
};
export default CallLogs;
